import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import { formatNumber } from 'utils/helpers/info-helper';
/* components */
import Tendency from 'components/tendency/tendency';

export default class BunkerPricesTooltipContent extends React.PureComponent {
    toggleBunkerPort = portId => () => {
        this.props.toggleUserSetting('BunkerPricesSelectedPorts', portId);
    };

    render() {
        const { bunkerPort } = this.props;
        const bunkerPricesRows = bunkerPort.LatestBunkerPrices
            ? bunkerPort.LatestBunkerPrices.map((bunkerPrice, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="row" key={index}>
                    <div className="col-7 sten-panel__label">
                        {bunkerPrice.FuelName}
                    </div>
                    <div className="col-9 sten-panel__label sten-panel__label--value">
                        {bunkerPrice.Value
                            ? `${formatNumber(bunkerPrice.Value)} ${bunkerPrice.Unit}`
                            : translate('GLOBAL.NOT_AVAILABLE')}
                    </div>
                    <div className="col-8">
                        <Tendency value={bunkerPrice.Difference} />
                    </div>
                </div>
            ))
            : [];

        return (
            <React.Fragment>
                <div className="sten-trading-route-tooltip__header">
                    <div className="flex-row flex-center">
                        <h3 className="flex-grow sten-bunker-prices-tooltip__title">
                            {bunkerPort.Name.toUpperCase()}
                        </h3>
                        <div className="flex-shrink">
                            <button
                                className="btn-link icon icon-close"
                                onClick={this.toggleBunkerPort(bunkerPort.Id)}
                            />
                        </div>
                    </div>
                </div>
                <div className="sten-bunker-prices-tooltip__body">
                    {bunkerPricesRows.length ? bunkerPricesRows : translate('BUNKER_PRICE_TOOLTIP.NO_DATA')}
                </div>
            </React.Fragment>
        );
    }
}

BunkerPricesTooltipContent.propTypes = {
    bunkerPort: PropTypes.objectOf(PropTypes.any).isRequired,
    toggleUserSetting: PropTypes.func.isRequired
};
