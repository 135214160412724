export const ActionTypes = {
    VESSEL_INFO_GET_RANGE_OPTIONS: 'VESSEL_INFO_GET_RANGE_OPTIONS',
    VESSEL_INFO_SET_RANGE_OPTIONS: 'VESSEL_INFO_SET_RANGE_OPTIONS',
    VESSEL_INFO_SET_ACTIVE_TAB: 'VESSEL_INFO_SET_ACTIVE_TAB',
    VESSEL_INFO_CARGO_GENERATE_XLSX: 'VESSEL_INFO_CARGO_GENERATE_XLSX',
    VESSEL_INFO_CARGO_GENERATE_PDF: 'VESSEL_INFO_CARGO_GENERATE_PDF'
};

export function getRangeOptions(dispatch) {
    dispatch({
        type: ActionTypes.VESSEL_INFO_GET_RANGE_OPTIONS
    });
}

export function setActiveTab(dispatch, activeTabId) {
    dispatch({
        type: ActionTypes.VESSEL_INFO_SET_ACTIVE_TAB,
        activeTabId
    });
}

export function generateCargoXLSX(dispatch) {
    dispatch({
        type: ActionTypes.VESSEL_INFO_CARGO_GENERATE_XLSX
    });
}

export function generateCargoPDF(dispatch) {
    dispatch({
        type: ActionTypes.VESSEL_INFO_CARGO_GENERATE_PDF
    });
}
