/* common */
import { t, tu, commonFields, commonData } from '../vessel-report-common-fields';

const isTankCleaningDisabled = (report) => !report.TankCleaning;

export const fieldMap = {

    // Basic Info

    Vessel: commonFields.Vessel(),
    CallSign: commonFields.CallSign(),
    VoyageNumber: commonFields.VoyageNumber(),
    CharterpartyDate: commonFields.CharterpartyDate(),
    ReportDate: commonFields.ReportDate(),
    Position: commonFields.Position(),
    CargoStatus: commonFields.CargoStatus(),
    VesselStatus: commonFields.VesselStatus(),

    // Since last event

    TimeElapsedSinceLastEvent: commonFields.String('TimeElapsedSinceLastEvent', t('TIME_SINCE_LAST_EVENT')),
    TimeAtAnchorSinceLastEvent: commonFields.String('TimeAtAnchorSinceLastEvent', t('TIME_AT_ANCHOR_SINCE_LAST_EVENT')),
    HoursSteamedSinceLastEvent: commonFields.String('HoursSteamedSinceLastEvent', t('HOURS_STEAMED_SINCE_LAST_EVENT')),
    DistanceSinceLastEvent: commonFields.Number(
        'DistanceSinceLastEvent',
        t('DISTANCE_SINCE_LAST_EVENT'),
        2,
        tu('DISTANCE')
    ),
    WindForceSinceLastEvent: commonFields.WindForce('WindForceSinceLastEvent', t('WIND_FORCE_SINCE_LAST_EVENT')),
    BunkerDeliveryNoteNumber: commonFields.String('BunkerDeliveryNoteNumber', t('BUNKER_DELIVERY_NOTE_NUMBER')),
    BunkerDeliveryDate: commonFields.DateTime(
        'BunkerDeliveryDate',
        'BunkerDeliveryDateOffset',
        t('BUNKER_DELIVERY_NOTE_DATE')
    ),

    // Departure

    PointOfDeparture: commonFields.Point('PointOfDeparture', t('PORT_OF_DEPARTURE')),
    Terminal: commonFields.String('Terminal', t('BERTH_TERMINAL_ANCHORAGE')),
    CompletionOfCargoOps: commonFields.DateTime(
        'CompletionOfCargoOps',
        'CompletionOfCargoOpsOffset',
        t('COMPLETION_CARGO_OPS')
    ),
    LeaveBerthTerminal: commonFields.DateTime(
        'LeaveBerthTerminal',
        'LeaveBerthTerminalOffset',
        t('LEAVE_BERT_TERMINAL')
    ),
    DroppingOutwardPilot: commonFields.DateTime(
        'DroppingOutwardPilot',
        'DroppingOutwardPilotOffset',
        t('DROPPING_OUTWARD_PILOT')
    ),
    DepartureDate: commonFields.DateTime('DepartureDate', 'DepartureDateOffset', t('DEPARTURE_DATE')),
    CargoLoadedDischarged: commonFields.Number(
        'CargoLoadedDischarged',
        t('CARGO_LOADED_DISCHARGED_THIS_PORT'),
        0,
        tu('CARGO')
    ),
    CargoOps: commonFields.Number('CargoOps', t('CARGO_OPS'), 0, tu('HOUR')),
    DraftFwd: commonFields.Draft('DraftFwd', t('DRAFT_FWD')),
    DraftMid: commonFields.Draft('DraftMid', t('DRAFT_MID')),
    DraftAft: commonFields.Draft('DraftAft', t('DRAFT_AFT')),
    TankCleaning: commonFields.Boolean('TankCleaning', t('TANK_CLEANING')),
    TankCleaningStarted: commonFields.DateTime(
        'TankCleaningStarted',
        'TankCleaningStartedOffset',
        t('TANK_CLEANING_STARTED'),
        '',
        isTankCleaningDisabled
    ),
    TankCleaningFinished: commonFields.DateTime(
        'TankCleaningFinished',
        'TankCleaningFinishedOffset',
        t('TANK_CLEANING_FINISHED'),
        '',
        isTankCleaningDisabled
    ),
    WaterUsedForTankCleaning: commonFields.Number(
        'WaterUsedForTankCleaning',
        t('WATER_USED_FOR_TANK_CLEANING'),
        2,
        tu('CUBIC_CAPACITY'),
        isTankCleaningDisabled
    ),
    NumberOfTugsUsed: commonFields.Number('NumberOfTugsUsed', t('NUMBER_OF_TUGS_USED'), 0),
    TugReason: commonFields.Select(
        'TugReason',
        t('REASON_FOR_USING_TUGS'),
        'editingOptions.tugReasons',
        'Id',
        'Name',
        (params, data) => ({
            disabled: !data.NumberOfTugsUsed || parseInt(data.NumberOfTugsUsed, 10) === 0,
            placeholder: t('REASON_FOR_USING_TUGS')
        })
    ),

    // Remarks

    Remarks: commonFields.Remarks(),

    // Deadweight

    DWTCargo: commonFields.DWT('DWTCargo', t('TOTAL_CARGO_ONBOARD')),
    DWTBallast: commonFields.DWT('DWTBallast', t('BALLAST')),
    DWTSlops: commonFields.DWT('DWTSlops', t('SLOPS')),
    DWTTotalBunkers: commonFields.DWT('DWTTotalBunkers', t('TOTAL_BUNKERS')),
    DWTFreshWater: commonFields.DWT('DWTFreshWater', t('FRESH_WATER')),
    DWTStores: commonFields.DWT('DWTStores', t('STORES')),

    // Cleaning

    LastPropellerCleaning: commonFields.DateTime(
        'LastPropellerCleaning',
        'LastPropellerCleaningOffset',
        t('LAST_PROPELLER_CLEANING')
    ),
    PortOfLastPropellerCleaning:
        commonFields.Point('PortOfLastPropellerCleaning', t('PORT_OF_LAST_PROPELLER_CLEANING')),
    LastHullCleaning: commonFields.DateTime('LastHullCleaning', 'LastHullCleaningOffset', t('LAST_HULL_CLEANING')),
    PortOfLastHullCleaning: commonFields.Point('PortOfLastHullCleaning', t('PORT_OF_LAST_HULL_CLEANING')),

    // Next port

    NextPoint: commonFields.Point('NextPoint', t('NEXT_PORT')),
    EtaDate: commonFields.DateTime('EtaDate', 'EtaDateOffset', t('ETA_DATE')),
    InstructedSpeed: commonFields.Speed('InstructedSpeed', t('INSTRUCTED_SPEED')),
    Displacement: commonFields.Displacement(),
    DistanceToGo: commonFields.Number('DistanceToGo', t('DISTANCE_TO_GO'), 2, tu('DISTANCE')),

    // Cargo

    'AuroraReportData.CargoUsed.ForceUsed':
        commonFields.ViewOnly('AuroraReportData.CargoUsed.ForceUsed', t('FORCER_USED')),
    'AuroraReportData.CargoUsed.Hours':
        commonFields.ViewOnly('AuroraReportData.CargoUsed.Hours', t('FORCER_HOURS'), 0),

    // Other info

    'AuroraReportData.MainEngineMiles':
        commonFields.ViewOnly('AuroraReportData.MainEngineMiles', t('ENGINE_DISTANCE'), 1, tu('DISTANCE')),
    'AuroraReportData.DistanceThroughIce':
        commonFields.ViewOnly('AuroraReportData.DistanceThroughIce', t('DISTANCE_THROUGH_ICE'), 1, tu('DISTANCE')),
    'AuroraReportData.CpWarrantedConsumption': commonFields.ViewOnly(
        'AuroraReportData.CpWarrantedConsumption',
        t('WARRANTED_CONSUMPTION'),
        1,
        tu('CONSUMPTION')
    ),
    'AuroraReportData.Trim': commonFields.ViewOnly('AuroraReportData.Trim', t('TRIM'), 1, tu('TRIM')),
    'AuroraReportData.TrimType': commonFields.ViewOnly('AuroraReportData.TrimType', t('TRIM_TYPE')),
    'AuroraReportData.ElectricalLoad':
        commonFields.ViewOnly('AuroraReportData.ElectricalLoad', t('ELECTRICAL_LOAD'), 0, tu('ELECTRICAL_LOAD'))
};

export const dataMap = {
    ConsumptionsSinceLastEvent: commonData.ConsumptionFuelData('ConsumptionsSinceLastEvent', 6),
    BunkerData: commonData.BunkerFuelData('BunkerData'),
    Consumptions: commonData.ConsumptionFuelData('Consumptions', 1),
    'AuroraReportData.ExtractedEquipmentItems.Boiler': commonData.AuroraDataTable(
        'AuroraReportData.ExtractedEquipmentItems.Boiler', 'Boiler', t('BOILER')
    ),
    'AuroraReportData.ExtractedEquipmentItems.MainTurbine': commonData.AuroraDataTable(
        'AuroraReportData.ExtractedEquipmentItems.MainTurbine', 'MainTurbine', t('SIDE'), t('TURBINE')
    ),
    'AuroraReportData.ExtractedEquipmentItems.PropulsionMotor': commonData.AuroraDataTable(
        'AuroraReportData.ExtractedEquipmentItems.PropulsionMotor', 'PropulsionMotor', t('SIDE'), t('PROPULSION_MOTORS')
    ),
    'AuroraReportData.ExtractedEquipmentItems.MainEngine': commonData.AuroraDataTable(
        'AuroraReportData.ExtractedEquipmentItems.MainEngine', 'MainEnginePower', t('ENGINE')
    ),
    'AuroraReportData.Water': commonData.AuroraDataTable('AuroraReportData.Water', 'Water', t('WATER_TYPE')),
    'AuroraReportData.Temperatures': commonData.AuroraDataTable(
        'AuroraReportData.Temperatures', 'Temperatures', t('TANK')
    ),
    'AuroraReportData.BarrierSpaceConditions': commonData.AuroraDataTable(
        'AuroraReportData.BarrierSpaceConditions', 'BarrierSpaceConditions', t('TANK'), t('BARRIER_SPACE_CONDITIONS')
    ),
    'AuroraReportData.CofferdamTemps': commonData.AuroraDataTable(
        'AuroraReportData.CofferdamTemps', 'CofferdamTemps', '#', t('COFFERDAM_LOWEST_TEMPERATURES'), { minColWidth: 0 }
    )
};

export default { fieldMap, dataMap };
