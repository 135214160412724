import CoreApi from './core-api';
import { splitArrayByProp } from 'utils/helpers/array-helper';

const importanceLevels = [15, 13, 11, 9, 7, 5, 3, 1];

class PortService {
    constructor() {
        this.resourceUrl = 'Ports';
        this.portsCache = {};
    }

    get(params = {}) {
        if (this.portsCache[params.ImportanceLevel]) {
            return Promise.resolve(this.portsCache[params.ImportanceLevel]);
        }

        return CoreApi.get(this.resourceUrl, null, true)
            .then(response => {
                const portsByImportanceLevel = splitArrayByProp(response.data, 'ImportanceLevel');
                this.portsCache = {};
                importanceLevels.forEach(il => {
                    if (!this.portsCache[il]) {
                        this.portsCache[il] = [];
                    }
                    for (let i = il; i <= 15; i++) {
                        if (portsByImportanceLevel[i]) {
                            this.portsCache[il] = [...this.portsCache[il], ...portsByImportanceLevel[i]];
                        }
                    }
                });
                return this.portsCache[params.ImportanceLevel];
            });
    }

    getAll() {
        return CoreApi.get(`${this.resourceUrl}/All`)
            .then(response => response.data, () => null);
    }

    getById(portId) {
        return CoreApi.get(`${this.resourceUrl}/${portId}`)
            .then(response => response.data, () => null);
    }

    /* search */
    searchByCriteria(searchCriteria, limit, pointTypes) {
        return CoreApi.get(`${this.resourceUrl}/Search`, {
            Query: searchCriteria,
            limit,
            pointTypes
        })
            .then(response => response.data);
    }

    searchByCriteriaForAll(searchCriteria, limit) {
        return CoreApi.get(`${this.resourceUrl}/Search/All`, {
            Query: searchCriteria,
            limit
        })
            .then(response => response.data);
    }

    searchById(id) {
        return CoreApi.get(`${this.resourceUrl}/Search`, {
            PortId: id
        })
            .then(response => response.data);
    }

    /* available activities */
    getAvailablePortActivities() {
        return CoreApi.get(`${this.resourceUrl}/AvailablePortActivities`)
            .then(response => response.data, () => null);
    }

    /* cargo activities */
    getCargoActivities() {
        return CoreApi.get(`${this.resourceUrl}/CargoActivities`)
            .then(response => response.data, () => null);
    }

    getCargoActivityTypes() {
        return CoreApi.get(`${this.resourceUrl}/ActivityTypes`)
            .then(response => response.data, () => null);
    }

    /* calls */
    getCalls(params) {
        return CoreApi.get(`${this.resourceUrl}/PortCalls`, params)
            .then(response => response.data, () => null);
    }

    /* activities */
    getActivities(portId) {
        return CoreApi.get(`${this.resourceUrl}/${portId}/PortActivity`)
            .then(response => response.data, () => null);
    }

    /* bunker prices */
    getBunkerPrices(portId) {
        return CoreApi.get(`${this.resourceUrl}/${portId}/BunkerPrices`)
            .then(response => response.data, () => null);
    }

    /* bunkering */
    getBunkeringTypes() {
        return CoreApi.get(`${this.resourceUrl}/BunkeringTypes`)
            .then(response => response.data, () => null);
    }

    getPortCallsAnalyticsData(params) {
        return CoreApi.post(`${this.resourceUrl}/PortCallAnalytics`, params)
            .then(response => response.data, (error) => {
                return error && !error.response ? false : null;
            });
    }
}

export default new PortService();
