import React from 'react';
import PropTypes from 'prop-types';
/* types */
import { TCIIRating } from 'components/footprint-table/footprint-table-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { formatNumber } from 'utils/helpers/info-helper';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import TrendIcon from 'components/trend-icon/trend-icon';
import CiiRating from 'components/cii-rating/cii-rating';
/* styles */
import './vessel-info-cards.scss';

const estimatedNetDailyThreshold = 500;

export class VesselInfoCards extends React.PureComponent {
    formatValue = (value, decimals, unit, fallback = null) => {
        const formattedValue = formatNumber(value, decimals);
        return formattedValue ? `${unit ? `${unit} ` : ''}${formattedValue}` : fallback;
    };

    render() {
        const { earnings, ciiRating, ciiRatingWithCorrection, vmsCiiRating } = this.props;
        const netDaily = this.formatValue(earnings?.NetDaily, 0, '$');
        const estimatedNetDaily = this.formatValue(earnings?.EstimatedNetDaily, 0, '$', '-');
        const energyEfficiency = this.formatValue(earnings?.EnergyEfficiency, 2);
        const trendIconBreakpoints = earnings && [
            earnings.EstimatedNetDaily - estimatedNetDailyThreshold,
            earnings.EstimatedNetDaily + estimatedNetDailyThreshold
        ];

        return (
            <div className="sten-content__section">
                <div className="flex flex--wrap sten-vessel-info-card__wrapper">
                    {earnings && netDaily && (
                        <div className="sten-vessel-info-card">
                            <h5 className="sten-vessel-info-card__header">
                                {t('VESSEL_INFO.CARDS.NET_DAILY')}
                            </h5>
                            <div className="sten-vessel-info-card__body">
                                <div className="sten-vessel-info-card__title">{netDaily}</div>
                                <div className="sten-vessel-info-card__trend">
                                    <TrendIcon value={earnings.NetDaily} breakpoints={trendIconBreakpoints} />
                                </div>
                            </div>
                            <p className="sten-vessel-info-card__footer">
                                <span className="text-secondary text-uppercase">
                                    {t('VESSEL_INFO.CARDS.ESTIMATED')}
                                </span>
                                {` ${estimatedNetDaily}`}
                            </p>
                        </div>
                    )}
                    {!ConfigService.hiddenFeatures.mapVesselInfoCiiRating && ciiRating && (
                        <div className="sten-vessel-info-card">
                            <h5 className="sten-vessel-info-card__header">
                                {t('VESSEL_INFO.CARDS.CII_RATING')}
                                <span
                                    className="icon sten-icon-info-tooltip"
                                    title={ciiRating
                                        ? t('VESSEL_INFO.CARDS.CII_RATING_TOOLTIP')
                                        : t('FOOTPRINT_TABLE.VALUE_CANT_BE_CALCULATED_TOOLTIP')}
                                />
                            </h5>
                            <div className="sten-vessel-info-card__body">
                                <CiiRating value={ciiRating} />
                            </div>
                            <p className="sten-vessel-info-card__footer">
                                <span className="text-secondary text-uppercase">
                                    {t('VESSEL_INFO.CARDS.WITH_CORRECTIONS')}
                                </span>
                                <CiiRating isEstimated value={ciiRatingWithCorrection} />
                            </p>
                        </div>
                    )}
                    {!ConfigService.hiddenFeatures.mapVesselInfoVmsCiiRating && vmsCiiRating && (
                        <div className="sten-vessel-info-card">
                            <h5 className="sten-vessel-info-card__header">
                                {t('VESSEL_INFO.CARDS.CII_RATING')}
                                <span className="sten-badge sten-badge--inline sten-badge--success">VMS</span>
                            </h5>
                            <div className="sten-vessel-info-card__body">
                                <CiiRating value={vmsCiiRating} />
                            </div>
                        </div>
                    )}
                    {earnings && energyEfficiency && (
                        <div className="sten-vessel-info-card">
                            <h5 className="sten-vessel-info-card__header">
                                {t('VESSEL_INFO.CARDS.EEOI')}
                            </h5>
                            <div className="sten-vessel-info-card__body">
                                <div className="sten-vessel-info-card__title text-primary">
                                    {energyEfficiency}
                                </div>
                            </div>
                            <p className="sten-vessel-info-card__footer">
                                <span className="text-secondary">{t('UNITS.ENERGY_EFFICIENCY')}</span>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

VesselInfoCards.propTypes = {
    ciiRating: TCIIRating,
    ciiRatingWithCorrection: TCIIRating,
    earnings: PropTypes.shape({
        NetDaily: PropTypes.number,
        EstimatedNetDaily: PropTypes.number,
        EnergyEfficiency: PropTypes.number
    }),
    isRange: PropTypes.bool.isRequired,
    vmsCiiRating: PropTypes.string
};

VesselInfoCards.defaultProps = {
    ciiRating: null,
    ciiRatingWithCorrection: null,
    earnings: null,
    vmsCiiRating: ''
};

export default VesselInfoCards;
