export const loremWords = [
    'lorem', 'ipsum', 'dolor', 'sit', 'amet', 'consectetur', 'adipiscing', 'elit', 'nullam', 'viverra', 'odio', 'eu',
    'turpis', 'facilisis', 'iaculis', 'curabitur', 'felis', 'dui', 'tempor', 'non', 'euismod', 'pretium', 'imperdiet'
];

export const names = [
    'Oliver', 'Jack', 'Harry', 'Jacob', 'Charlie', 'Thomas', 'George', 'Oscar', 'James', 'William', 'Bruce', 'Connor',
    'Callum', 'John', 'Michael', 'Amelia', 'Mary', 'Jessica', 'Sophie', 'Victoria', 'Megan', 'Emma', 'Sarah', 'Emily'
];

export const lastNames = [
    'Smith', 'Jones', 'Williams', 'Brown', 'Taylor', 'Davies', 'Wilson', 'Evans', 'Thomas', 'Roberts', 'Murphy',
    'Miller', 'White', 'Anderson', 'Adams', 'Cook', 'Fisher', 'Harrison', 'Dickinson', 'Hughes', 'Moore', 'Walker'
];

export const characters = {
    lowerCaseLetters: 'abcdefghijklmnopqrstuvwxyz',
    upperCaseLetters: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
    digits: '0123456789',
    special: '`~!@#$%^&*()_-+={}[]\\|:;"\',<.>/?'
};

export const fuelTypes = [
    { Id: 2, Name: 'HSFO', Sequence: 1 },
    { Id: 4, Name: 'LSMGO', Sequence: 2 },
    { Id: 7, Name: 'VLSFO', Sequence: 3 },
    { Id: 8, Name: 'HSGO', Sequence: 5 },
    { Id: 9, Name: 'LSFO', Sequence: 7 },
    { Id: 10, Name: 'BIOFO', Sequence: 10 }
];

export const vessels = [
    {
        DWT: 158491.0,
        Imo: 5000005,
        ImoClass: null,
        Title: 'Canal',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false
    },
    {
        DWT: 159158.0,
        Imo: 3000001,
        ImoClass: 'N/A',
        Title: 'Bowery',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false
    },
    {
        DWT: 49718.0,
        Imo: 2000004,
        ImoClass: '2',
        Title: 'Ludlow',
        TotalCubicCapacity: null,
        VesselTypeId: 22,
        VesselTypeName: 'MR',
        IsCompetition: false
    },
    {
        DWT: 159034.0,
        Imo: 4000008,
        ImoClass: 'N/A',
        Title: 'Spring',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false
    },
    {
        DWT: 49750.0,
        Imo: 1000007,
        ImoClass: '2',
        Title: 'Bedford',
        TotalCubicCapacity: null,
        VesselTypeId: 22,
        VesselTypeName: 'MR',
        IsCompetition: false
    },
    {
        DWT: 49719.0,
        Imo: 9667473,
        ImoClass: '2',
        Title: 'Stena Image DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 22,
        VesselTypeName: 'MR',
        IsCompetition: false
    },
    {
        DWT: 159158.0,
        Imo: 9579042,
        ImoClass: 'N/A',
        Title: 'Stena Suede DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false
    },
    {
        DWT: 159039.0,
        Imo: 9585900,
        ImoClass: 'N/A',
        Title: 'Ast Sunshine DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false
    },
    {
        DWT: 49682.0,
        Imo: 9797400,
        ImoClass: '2',
        Title: 'Stena Impero DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 22,
        VesselTypeName: 'MR',
        IsCompetition: false
    },
    {
        DWT: 47288.0,
        Imo: 9258595,
        ImoClass: '2,3',
        Title: 'Stena Concert DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 22,
        VesselTypeName: 'MR',
        IsCompetition: false
    },
    {
        DWT: 159034.0,
        Imo: 9592214,
        ImoClass: 'N/A',
        Title: 'Stena Sunrise DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false
    },
    {
        DWT: 159031.0,
        Imo: 9585895,
        ImoClass: null,
        Title: 'Stena Supreme DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false
    },
    {
        DWT: 157872.0,
        Imo: 9575565,
        ImoClass: 'N/A',
        Title: 'Sonangol Huila DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false
    },
    {
        DWT: 49647.0,
        Imo: 9685475,
        ImoClass: '2',
        Title: 'Stena Immortal DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 22,
        VesselTypeName: 'MR',
        IsCompetition: false
    },
    {
        DWT: 49750.0,
        Imo: 9667485,
        ImoClass: '2',
        Title: 'Stena Imperial DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 22,
        VesselTypeName: 'MR',
        IsCompetition: false
    },
    {
        DWT: 49718.0,
        Imo: 9797395,
        ImoClass: '2',
        Title: 'Stena Imprimis DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 22,
        VesselTypeName: 'MR',
        IsCompetition: false
    },
    {
        DWT: 159236.0,
        Imo: 9579030,
        ImoClass: null,
        Title: 'Stena Superior DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false
    },
    {
        DWT: 158491.0,
        Imo: 9533995,
        ImoClass: null,
        Title: 'Stena Surprise DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false
    },
    {
        DWT: 157756.0,
        Imo: 9575541,
        ImoClass: 'N/A',
        Title: 'Sonangol Rangel DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false
    },
    {
        DWT: 49731.0,
        Imo: 9667497,
        ImoClass: '2',
        Title: 'Stena Important DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 22,
        VesselTypeName: 'MR',
        IsCompetition: false
    },
    {
        DWT: 156899.0,
        Imo: 9766310,
        ImoClass: 'N/A',
        Title: 'Sonangol Cazenga DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false
    },
    {
        DWT: 159165.0,
        Imo: 9203772,
        ImoClass: 'N/A',
        Title: 'Sonangol Kizomba DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false
    },
    {
        DWT: 157639.0,
        Imo: 9575577,
        ImoClass: 'N/A',
        Title: 'Sonangol Porto Amboim DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false
    }
];
