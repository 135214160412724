/* eslint-disable react/no-find-dom-node */
import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import Portal from '../portal/portal';
import './popover.scss';

export default class Popover extends React.PureComponent {
    componentDidMount() {
        this.targetRef = ReactDOM.findDOMNode(this);
        window.addEventListener('resize', this.handlePositionUpdate);
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.isVisible && this.props.isVisible) {
            document.addEventListener('click', this.handleClickOutside, true);
            if (this.props.onShow) {
                this.props.onShow(this);
            }
        }
        if (prevProps.isVisible && !this.props.isVisible) {
            document.removeEventListener('click', this.handleClickOutside, true);
            if (this.props.onHide) {
                this.props.onHide(this);
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
        window.removeEventListener('resize', this.handlePositionUpdate);
        if (this.props.onUnmount) {
            this.props.onUnmount(this);
        }
    }

    handlePositionUpdate = () => {
        window.requestAnimationFrame(() => {
            if (this) {
                this.forceUpdate();
            }
        });
    };

    getAutoAlignment = (rect, placement) => {
        let alignment = 'center';
        if (placement === 'left' || placement === 'right') {
            if (rect.bottom < window.innerHeight / 3) { // top third
                alignment = 'start';
            } else if (rect.top > (2 / 3) * window.innerHeight) { // bottom third
                alignment = 'end';
            }
        } else if (rect.right < window.innerWidth / 3) { // left third
            alignment = 'start';
        } else if (rect.left > (2 / 3) * window.innerWidth) { // right third
            alignment = 'end';
        }
        return alignment;
    };

    placementFit = {
        top: (rect, size, offset) => rect.top - offset - size.height >= 0,
        bottom: (rect, size, offset) => rect.bottom + offset + size.height <= window.innerHeight,
        left: (rect, size, offset) => rect.left - offset - size.width >= 0,
        right: (rect, size, offset) => rect.right + offset + size.width <= window.innerWidth
    };

    getAutoPlacement = (rect) => {
        let placement = this.props.placement;
        const { autoPlacementSize, offset } = this.props;
        if (!this.placementFit[placement](rect, autoPlacementSize, offset)) {
            for (let i = 0; i < this.props.autoPlacementPriority.length; i++) {
                if (this.placementFit[this.props.autoPlacementPriority[i]](rect, autoPlacementSize, offset)) {
                    placement = this.props.autoPlacementPriority[i];
                    break;
                }
            }
        }
        return {
            placement,
            alignment: this.getAutoAlignment(rect, placement)
        };
    };

    renderPopoverContent() {
        const targetBoundingRect = this.targetRef.getBoundingClientRect();
        const autoPlacement = this.props.autoPlacement && this.getAutoPlacement(targetBoundingRect);
        const placement = autoPlacement && autoPlacement.placement ? autoPlacement.placement : this.props.placement;
        const alignment = autoPlacement && autoPlacement.alignment ? autoPlacement.alignment : this.props.alignment;
        const alignmentStyle = Popover.calculateAlignmentStyle(targetBoundingRect, placement, alignment);
        const placementStyle = this.calculatePlacementStyle(targetBoundingRect, placement);
        const finalStyle = { ...alignmentStyle, ...placementStyle };
        const arrowStyle = Popover.calculateArrowStyle(targetBoundingRect, placement, alignment);
        let className = `htec-popover htec-popover--${placement} htec-popover--${alignment}`;
        if (this.props.withArrow) {
            className += ' htec-popover--with-arrow';
        }
        if (this.props.contentClassName) {
            className += ` ${this.props.contentClassName}`;
        }

        return (
            <Portal>
                <div
                    className={className}
                    ref={this.saveOuterDivRef}
                    style={finalStyle}
                >
                    {this.props.content}
                    {this.props.withArrow && (
                        <div className="htec-popover__arrow" style={arrowStyle} />
                    )}
                </div>
            </Portal>
        );
    }

    saveOuterDivRef = (c) => { this.containerDivRef = c; };

    static calculateArrowStyle(targetBoundingRect, placement, alignment) {
        const isPlacementVertical = placement === 'top' || placement === 'bottom';
        const style = { };
        if (isPlacementVertical) {
            style.width = targetBoundingRect.width;
            if (alignment === 'center') {
                style.left = '50%';
                style.transform = 'translateX(-50%)';
            } else if (alignment === 'start') {
                style.left = 0;
            } else {
                style.right = 0;
            }
        } else {
            style.height = targetBoundingRect.height;
            if (alignment === 'center') {
                style.top = '50%';
                style.transform = 'translateY(-50%)';
            } else if (alignment === 'start') {
                style.top = 0;
            } else {
                style.bottom = 0;
            }
        }

        return style;
    }

    calculatePlacementStyle(targetBoundingRect, placement) {
        const style = { position: 'fixed' };
        if (placement === 'bottom') {
            style.top = targetBoundingRect.bottom + this.props.offset;
        } else if (placement === 'top') {
            style.bottom = `calc(100% - ${targetBoundingRect.top - this.props.offset}px)`;
        } else if (placement === 'left') {
            style.right = `calc(100% - ${targetBoundingRect.left - this.props.offset}px)`;
        } else if (placement === 'right') {
            style.left = targetBoundingRect.right + this.props.offset;
        }

        return style;
    }

    static calculateAlignmentStyle(targetBoundingRect, placement, alignment) {
        const isPlacementVertical = placement === 'top' || placement === 'bottom';
        const startProperty = isPlacementVertical ? 'left' : 'top';
        const endProperty = isPlacementVertical ? 'right' : 'bottom';
        const size = isPlacementVertical ? targetBoundingRect.width : targetBoundingRect.height;
        const style = {};

        if (alignment === 'center') {
            style[startProperty] = targetBoundingRect[startProperty] + (size / 2);
            style.transform = isPlacementVertical ? 'translateX(-50%)' : 'translateY(-50%)';
        } else if (alignment === 'start') {
            style[startProperty] = targetBoundingRect[startProperty];
        } else if (alignment === 'end') {
            style[endProperty] = `calc(100% - ${targetBoundingRect[endProperty]}px)`;
        }

        return style;
    }

    handleClickOutside = e => {
        if (this.props.onClickOutside && (!this.containerDivRef || !this.containerDivRef.contains(e.target))) {
            this.props.onClickOutside(e);
        }
    };

    render() {
        return (
            <React.Fragment>
                {this.props.children}
                {this.props.isVisible && this.renderPopoverContent()}
            </React.Fragment>
        );
    }
}

Popover.propTypes = {
    alignment: PropTypes.oneOf(['start', 'center', 'end']),
    autoPlacement: PropTypes.bool,
    autoPlacementPriority: PropTypes.arrayOf(PropTypes.oneOf(['top', 'bottom', 'left', 'right'])),
    autoPlacementSize: PropTypes.shape({ width: PropTypes.number, height: PropTypes.number }),
    children: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired,
    contentClassName: PropTypes.string,
    isVisible: PropTypes.bool.isRequired,
    offset: PropTypes.number,
    onClickOutside: PropTypes.func.isRequired,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
    onUnmount: PropTypes.func,
    placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    withArrow: PropTypes.bool
};

Popover.defaultProps = {
    alignment: 'center',
    autoPlacement: false,
    autoPlacementPriority: ['top', 'bottom', 'left', 'right'],
    autoPlacementSize: null,
    contentClassName: '',
    offset: 0,
    onHide: undefined,
    onShow: undefined,
    onUnmount: undefined,
    placement: 'bottom',
    withArrow: true
};
