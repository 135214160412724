import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import fastDeepEqual from 'fast-deep-equal';
/* router */
import { TRouter, withRouter } from 'app-router';
/* types */
import { TGsmQueryParams } from '../gsm-types';
/* Models */
import GsmMapModels from './gsm-map-models';
/* Actions */
import {
    getAddresses,
    getDrills,
    getCities,
    getPorts,
    getTankers,
    saveMapViewProps,
    getFerries,
    getFerryRoutes,
    restoreResetAppUiState
} from '../gsm-actions';
import {
    gsmMapRightSideBarCollapse,
    removeAllEntities
} from '../gsm-right-side-bar/gsm-right-side-bar-actions';
import {
    setVesselDetailsTooltipPosition
} from './gsm-vessel-details-tooltip/gsm-vessel-details-tooltip-actions';
/* Components */
import Ol from 'components/ol/ol';
/* Helpers */
import {
    wktParse,
    calculateMultipolygonCoordinates,
    extendExtent,
    isExtentWithinCached,
    getImportanceByRelativeZoomLevel,
    mapCurrentAisLocationLonLat,
    mapCurrentAisLonLat,
    mapLocationLonLat,
    mapLonLat,
    getLonLatMapper
} from 'components/ol/ol-helpers';
import { wktMaker } from 'utils/helpers/info-helper';
import GsmVesselDetailsTooltip from './gsm-vessel-details-tooltip/gsm-vessel-details-tooltip';
/* Styles */
import './gsm-map.scss';
/* Config */
import config from './gsm-map-config';

/* Other */
import { mapImagesMap } from '../gsm-map-view-switch/gsm-map-view-switch';

export class GsmMap extends React.PureComponent {
    constructor(props) {
        super(props);
        this.cachedViewData = {
            ports: {
                importanceLevel: 10,
                extent: null,
                BBox: ''
            },
            cities: {
                importanceLevel: 10,
                extent: null,
                BBox: ''
            },
            addresses: {
                importanceLevel: 10,
                extent: null,
                BBox: ''
            }
        };
    }

    // Lifecycles
    componentDidMount() {
        this.props.getDrills();
        this.props.getTankers();
        this.getAddresses();
        this.props.getFerries();
        this.props.getFerryRoutes();
    }

    componentDidUpdate(prevProps) {
        // Zoom and center map handling
        if (this.props.selectedTanker && this.props.selectedTanker !== prevProps.selectedTanker) {
            if (this.props.selectedTanker.CurrentAis) {
                this.map.setCenter(mapCurrentAisLonLat(this.props.selectedTanker));
            }
        } else if (this.props.selectedCity && this.props.selectedCity !== prevProps.selectedCity) {
            if (this.props.selectedCity.Location) {
                this.map.setCenter(mapLocationLonLat(this.props.selectedCity), 9);
            }
        } else if (this.props.selectedFerry && this.props.selectedFerry !== prevProps.selectedFerry) {
            if (this.props.selectedFerry.CurrentAis) {
                this.map.setCenter(mapCurrentAisLocationLonLat(this.props.selectedFerry));
            }
        } else if (this.props.selectedDrill && this.props.selectedDrill !== prevProps.selectedDrill) {
            if (this.props.selectedDrill.CurrentAis && this.props.selectedDrill.CurrentAis.Location) {
                this.map.setCenter(mapCurrentAisLocationLonLat(this.props.selectedDrill));
            }
        }

        // Reset app ui on inactivity
        if (this.props.resetAppUiState !== prevProps.resetAppUiState) {
            const newQuery = { ...this.props.router.query };
            if (Object.keys(newQuery).length) {
                this.props.router.clearQueryParams();
            }
            this.map.resetZoom();

            this.props.restoreResetAppUiState();
            this.props.removeAllEntities();
        }
    }

    componentWillUnmount() {
        const mapViewProps = {
            zoom: this.map.getZoom(),
            center: this.map.getCenter()
        };
        this.props.saveMapViewProps(mapViewProps);
    }

    // Map
    onMapClick = (isFeatureClicked) => {
        if (!isFeatureClicked) {
            this.props.closeRightSidebar();
            this.props.removeAllEntities();
        }
    };

    onMapPostRender = () => {
        if (this.map) {
            this.getCities();
            this.getPorts();
            this.setVesselDetailsTooltipPosition();
        }
    };

    onTankerClick = (vesselFeature) => {
        if (vesselFeature && this.props.queryParams.tanker === vesselFeature.data.IMO) {
            return;
        }
        const newQuery = this.query ? { ...this.query } : { ...this.props.router.query };
        delete newQuery.tanker;
        if (vesselFeature?.data?.IMO) {
            newQuery.tanker = vesselFeature.data.IMO.toString();
        }
        this.handleRouteChange(newQuery);
    };

    onCityClick = (officeFeature) => {
        if (officeFeature && this.props.queryParams.city === officeFeature.data.CityId) {
            return;
        }
        const newQuery = this.query ? { ...this.query } : { ...this.props.router.query };
        delete newQuery.city;
        if (officeFeature?.data?.CityId) {
            newQuery.city = officeFeature.data.CityId;
        }
        this.handleRouteChange(newQuery);
    };

    onFerryClick = (ferryFeature) => {
        const newQuery = this.query ? { ...this.query } : { ...this.props.router.query };
        delete newQuery.ferry;
        if (ferryFeature?.data?.IMO) {
            newQuery.ferry = ferryFeature.data.IMO;
        }
        this.handleRouteChange(newQuery);
    };

    onDrillClick = (drillFeature) => {
        const newQuery = this.query ? { ...this.query } : { ...this.props.router.query };
        delete newQuery.drill;
        if (drillFeature?.data?.IMO) {
            newQuery.drill = drillFeature.data.IMO;
        }
        this.handleRouteChange(newQuery);
    };

    // Details tooltip
    setVesselDetailsTooltipPosition = () => {
        if (this.props.selectedVessel) {
            const position = this.props.selectedVessel.CurrentAis;

            const pixels = this.map.getPixelFromCoordinate([position.Longitude, position.Latitude]);
            const vesselPositionDetails = {
                pixels,
                zoomLevel: this.map.getZoom()
            };

            if (!fastDeepEqual(vesselPositionDetails, this.lastVesselPositionDetails)) {
                this.props.setVesselDetailsTooltipPosition(vesselPositionDetails);
            }

            this.lastVesselPositionDetails = vesselPositionDetails;
        }
    };

    // Ports
    getCities = (forceGetCities = false) => {
        if (forceGetCities) {
            this.forceGetCities = true;
        }
        if (this.debounceCities) {
            clearTimeout(this.debounceCities);
        }
        this.debounceCities = setTimeout(() => {
            if (!this.map) {
                return;
            }
            const relativeZoomLevel = this.map.getRelativeZoom();
            const importanceLevel = getImportanceByRelativeZoomLevel(relativeZoomLevel);
            const extent = this.map.getExtent();

            if (this.cachedViewData.cities.extent
                && isExtentWithinCached(extent, this.cachedViewData.cities.extent)
                && this.cachedViewData.cities.importanceLevel === importanceLevel && !this.forceGetCities) {
                return;
            }

            this.cachedViewData.cities.importanceLevel = importanceLevel;
            const e = extendExtent(extent);
            this.cachedViewData.cities.extent = e;
            const multipolygonCoords = calculateMultipolygonCoordinates(e[0], e[2], e[1], e[3]);
            const BBox = wktMaker('multipolygon', multipolygonCoords);
            if (BBox === this.cachedViewData.cities.BBox && !this.forceGetCities) {
                return;
            }
            this.cachedViewData.cities.BBox = BBox;
            this.props.getCities({
                ImportanceLevel: importanceLevel,
                BBox
            });
            this.forceGetCities = false;
        }, 100);
    };

    getAddresses = (forceGetAddresses = false) => {
        if (forceGetAddresses) {
            this.forceGetAddresses = true;
        }
        if (this.debounceAddresses) {
            clearTimeout(this.debounceAddresses);
        }
        this.debounceAddresses = setTimeout(() => {
            if (!this.map) {
                return;
            }
            const relativeZoomLevel = this.map.getRelativeZoom();
            const importanceLevel = getImportanceByRelativeZoomLevel(relativeZoomLevel);
            const extent = this.map.getExtent();

            if (this.cachedViewData.addresses.extent
                && isExtentWithinCached(extent, this.cachedViewData.addresses.extent)
                && this.cachedViewData.addresses.importanceLevel === importanceLevel && !this.forceGetAddresses) {
                return;
            }

            this.cachedViewData.addresses.importanceLevel = importanceLevel;
            const e = extendExtent(extent);
            this.cachedViewData.addresses.extent = e;
            const multipolygonCoords = calculateMultipolygonCoordinates(e[0], e[2], e[1], e[3]);
            const BBox = wktMaker('multipolygon', multipolygonCoords);
            if (BBox === this.cachedViewData.addresses.BBox && !this.forceGetAddresses) {
                return;
            }
            this.cachedViewData.addresses.BBox = BBox;
            this.props.getAddresses({
                ImportanceLevel: importanceLevel,
                BBox
            });
            this.forceGetAddresses = false;
        }, 100);
    };

    getPorts = (forceGetPorts = false) => {
        if (forceGetPorts) {
            this.forceGetPorts = true;
        }
        if (this.debouncePorts) {
            clearTimeout(this.debouncePorts);
        }
        this.debouncePorts = setTimeout(() => {
            if (!this.map) {
                return;
            }
            const relativeZoomLevel = this.map.getRelativeZoom();
            const importanceLevel = getImportanceByRelativeZoomLevel(relativeZoomLevel);
            const extent = this.map.getExtent();

            if (this.cachedViewData.ports.extent
                && isExtentWithinCached(extent, this.cachedViewData.ports.extent)
                && this.cachedViewData.ports.importanceLevel === importanceLevel && !this.forceGetPorts) {
                return;
            }

            this.cachedViewData.ports.importanceLevel = importanceLevel;
            const e = extendExtent(extent);
            this.cachedViewData.ports.extent = e;
            const multipolygonCoords = calculateMultipolygonCoordinates(e[0], e[2], e[1], e[3]);
            const BBox = wktMaker('multipolygon', multipolygonCoords);
            if (BBox === this.cachedViewData.ports.BBox && !this.forceGetPorts) {
                return;
            }
            this.cachedViewData.ports.BBox = BBox;
            this.props.getPorts({
                ImportanceLevel: importanceLevel,
                BBox
            });
            this.forceGetPorts = false;
        }, 100);
    };

    getDrillOpacity = (drill, isHovered) => {
        if (isHovered || this.props.selectedDrillId === drill.IMO) {
            return 1;
        }
        if (this.props.selectedCityId
            || this.props.selectedTankerId
            || this.props.selectedFerryId
            || this.props.selectedDrillId) {
            return 0.4;
        }
        return 0.8;
    };

    getTankerOpacity = (tanker, isHovered) => {
        if (isHovered || this.props.selectedTankerId === tanker.IMO) {
            return 1;
        }
        if (this.props.selectedCityId
            || this.props.selectedDrillId
            || this.props.selectedFerryId
            || this.props.selectedTankerId) {
            return 0.3;
        }
        return 0.8;
    };

    getCityOpacity = (city, isHovered) => {
        if (isHovered || this.props.selectedCityId === city.CityId) {
            return 1;
        }
        if (this.props.selectedTankerId
            || this.props.selectedDrillId
            || this.props.selectedFerryId
            || this.props.selectedCityId) {
            return 0.3;
        }
        return 0.8;
    };

    getPortOpacity = () => {
        if (this.props.selectedTankerId
            || this.props.selectedDrillId
            || this.props.selectedFerryId
            || this.props.selectedCityId) {
            return 0.3;
        }
        return 0.8;
    };

    getFerryOpacity = (ferry, isHovered) => {
        if (isHovered || this.props.selectedFerryId === ferry.IMO) {
            return 1;
        }
        if (this.props.selectedTankerId
            || this.props.selectedDrillId
            || this.props.selectedCityId
            || this.props.selectedFerryId) {
            return 0.3;
        }
        return 0.8;
    };

    handleRouteChange(newQuery) {
        this.query = newQuery;
        if (this.queryParamsUpdateTimeout) {
            clearTimeout(this.queryParamsUpdateTimeout);
        }
        this.queryParamsUpdateTimeout = setTimeout(() => {
            if (!fastDeepEqual(this.query, this.props.router.query)) {
                this.props.router.navigate({ query: newQuery });
                this.query = null;
            }
        }, 200);
    }

    portsLayerCoordinates(port) {
        return wktParse(port.Location);
    }

    // Tankers

    tankersLayerIsActive = tanker => tanker.IMO === this.props.selectedTankerId;

    tankersLayerHideLabel = (vessel, isHovered, zoomLevel) =>
        this.tankersLayerIsActive(vessel) || (config.tanker.labelMinZoomLevel > zoomLevel && !isHovered);

    // Drills
    drillsLayerIsActive = drill => drill.IMO === this.props.selectedDrillId;

    drillsLayerHideLabel = (drill, isHovered, zoomLevel) =>
        this.drillsLayerIsActive(drill) || (config.drill.labelMinZoomLevel > zoomLevel && !isHovered);

    // Cities layer
    citiesLayerIsActive = city => city.CityId === this.props.selectedCityId;

    citiesLayerColor = city =>
        (this.props.selectedCityId === city.CityId ? config.city.primaryColor : config.city.secondaryColor);

    citiesLayerHideLabel = (city, isHovered, zoomLevel) =>
        this.props.selectedCityId !== city.CityId && !isHovered && config.city.labelMinZoomLevel > zoomLevel;

    // Offices layer
    officesLayerLabel(location) {
        return location.OfficeTitle
            || (location.Address.AddressLines && location.Address.AddressLines[0])
            || location.OfficeName || '';
    }

    // Ferries layer
    ferriesLayerIsActive = ferry => ferry.IMO === this.props.selectedFerryId;

    ferriesLayerHideLabel = (vessel, isHovered, zoomLevel) =>
        this.ferriesLayerIsActive(vessel) || (config.ferry.labelMinZoomLevel > zoomLevel && !isHovered);

    tankersElementProps = {
        type: 'vessel',
        primaryColor: config.tanker.primaryColor,
        coordinates: mapCurrentAisLonLat,
        isActive: this.tankersLayerIsActive,
        label: vessel => vessel.Title.toUpperCase(),
        fill: true,
        hideLabel: this.tankersLayerHideLabel,
        opacity: this.getTankerOpacity,
        rotation: 'CurrentAis.Heading',
        textStroke: true,
        textOffset: 24
    };

    drillsElementProps = {
        type: 'drill',
        primaryColor: config.drill.primaryColor,
        coordinates: mapCurrentAisLonLat,
        isActive: this.drillsLayerIsActive,
        label: drill => drill.Title.toUpperCase(),
        hideLabel: this.drillsLayerHideLabel,
        opacity: this.getDrillOpacity,
        textStroke: true,
        textOffset: 24
    };

    portsElementProps = {
        type: 'port',
        primaryColor: config.port.primaryColor,
        coordinates: this.portsLayerCoordinates,
        opacity: this.getPortOpacity,
        label: 'Name',
        hideLabel: (city, isHovered, zoomLevel) =>
            !isHovered && config.port.labelMinZoomLevel > zoomLevel,
        textOnTop: true,
        textStroke: true
    };

    citiesElementProps = {
        type: 'port',
        primaryColor: this.citiesLayerColor,
        secondaryColor: this.citiesLayerColor,
        coordinates: mapLonLat,
        isActive: this.citiesLayerIsActive,
        opacity: this.getCityOpacity,
        label: 'Name',
        hideLabel: this.citiesLayerHideLabel,
        textOnTop: true,
        textStroke: true
    };

    officesElementProps = {
        type: 'pin',
        primaryColor: config.office.primaryColor,
        secondaryColor: config.office.secondaryColor,
        opacity: 1,
        coordinates: getLonLatMapper('Address.Longitude', 'Address.Latitude'),
        label: this.officesLayerLabel,
        textOnTop: true,
        textStroke: true
    };

    ferriesElementProps = {
        type: 'vessel',
        primaryColor: config.ferry.primaryColor,
        coordinates: mapCurrentAisLonLat,
        isActive: this.ferriesLayerIsActive,
        fill: true,
        opacity: this.getFerryOpacity,
        label: vessel => vessel.Title.toUpperCase(),
        hideLabel: this.ferriesLayerHideLabel,
        textStroke: true,
        textOffset: 24,
        rotation: 'CurrentAis.Heading'
    };

    ferryRoutesElementProps = {
        coordinates: 'RoutePoints',
        color: config.ferryRoute.color,
        width: config.ferryRoute.width,
        lineDash: config.ferryRoute.lineDash,
        label: '',
        labelColor: config.ferryRoute.labelColor
    };

    // Render
    render() {
        const mapZoomControlElement = document.getElementById('stenMapZoomControls');
        return (
            <div className="gsm-map">
                <GsmVesselDetailsTooltip />
                <Ol.Map
                    ref={(c) => { this.map = c; }}
                    mapTypeId={mapImagesMap[this.props.mapType].id}
                    mapViewProps={this.props.mapViewProps}
                    countryBordersNamesEnabled
                    zoomControlElement={mapZoomControlElement}
                    onPostRender={this.onMapPostRender}
                    onClick={this.onMapClick}
                    showCoordinates={false}
                >
                    {this.props.tankersLayerVisibility && (
                        <Ol.Layer
                            name="tankers"
                            type="point"
                            elements={this.props.tankers}
                            elementProps={this.tankersElementProps}
                            observes={[
                                this.props.selectedCityId,
                                this.props.selectedDrillId,
                                this.props.selectedFerryId,
                                this.props.selectedTankerId
                            ]}
                            zIndex={config.tanker.zIndex}
                            onClick={this.onTankerClick}
                            onClickAway={this.onTankerClick}
                        />
                    )}
                    {this.props.drillsLayerVisibility && (
                        <Ol.Layer
                            name="drills"
                            type="point"
                            elements={this.props.drills}
                            elementProps={this.drillsElementProps}
                            observes={[
                                this.props.selectedCityId,
                                this.props.selectedDrillId,
                                this.props.selectedFerryId,
                                this.props.selectedTankerId
                            ]}
                            zIndex={config.drill.zIndex}
                            onClick={this.onDrillClick}
                            onClickAway={this.onDrillClick}
                        />
                    )}
                    <Ol.Layer
                        name="ports"
                        type="point"
                        elements={this.props.ports}
                        elementProps={this.portsElementProps}
                        observes={[
                            this.props.selectedCityId,
                            this.props.selectedDrillId,
                            this.props.selectedFerryId,
                            this.props.selectedTankerId
                        ]}
                        zIndex={config.port.zIndex}
                    />
                    {this.props.officesLayerVisibility && (
                        <Ol.Layer
                            name="cities"
                            type="point"
                            elements={this.props.cities}
                            elementProps={this.citiesElementProps}
                            observes={[
                                this.props.selectedCityId,
                                this.props.selectedDrillId,
                                this.props.selectedFerryId,
                                this.props.selectedTankerId
                            ]}
                            zIndex={config.city.zIndex}
                            onClick={this.onCityClick}
                            onClickAway={this.onCityClick}
                        />
                    )}
                    {this.props.officesLayerVisibility && (
                        <Ol.Layer
                            name="offices"
                            type="point"
                            elements={this.props.addresses}
                            elementProps={this.officesElementProps}
                            observes={[
                                this.props.selectedCityId,
                                this.props.selectedDrillId,
                                this.props.selectedFerryId,
                                this.props.selectedTankerId
                            ]}
                            zIndex={config.office.zIndex}
                        />
                    )}
                    {this.props.ferriesLayerVisibility && (
                        <Ol.Layer
                            name="ferries"
                            type="point"
                            elements={this.props.ferries}
                            elementProps={this.ferriesElementProps}
                            observes={[
                                this.props.selectedCityId,
                                this.props.selectedDrillId,
                                this.props.selectedFerryId,
                                this.props.selectedTankerId
                            ]}
                            zIndex={config.ferry.zIndex}
                            onClick={this.onFerryClick}
                            onClickAway={this.onFerryClick}
                        />
                    )}
                    {this.props.ferryRoutesLayerVisibility && (
                        <Ol.Layer
                            name="ferryRoutes"
                            type="multiLine"
                            elements={this.props.ferryRoutes}
                            elementProps={this.ferryRoutesElementProps}
                            observes={[this.props.ferryRoutes]}
                            zIndex={config.ferryRoute.zIndex}
                        />
                    )}
                </Ol.Map>
            </div>
        );
    }
}

GsmMap.propTypes = {
    addresses: PropTypes.arrayOf(GsmMapModels.Addresses).isRequired,
    cities: PropTypes.arrayOf(GsmMapModels.Cities).isRequired,
    closeRightSidebar: PropTypes.func.isRequired,
    drills: PropTypes.arrayOf(GsmMapModels.Drills).isRequired,
    drillsLayerVisibility: PropTypes.bool.isRequired,
    ferries: PropTypes.arrayOf(GsmMapModels.Ferries).isRequired,
    ferriesLayerVisibility: PropTypes.bool.isRequired,
    ferryRoutes: PropTypes.arrayOf(GsmMapModels.FerryRoutes).isRequired,
    ferryRoutesLayerVisibility: PropTypes.bool.isRequired,
    getAddresses: PropTypes.func.isRequired,
    getCities: PropTypes.func.isRequired,
    getDrills: PropTypes.func.isRequired,
    getFerries: PropTypes.func.isRequired,
    getFerryRoutes: PropTypes.func.isRequired,
    getPorts: PropTypes.func.isRequired,
    getTankers: PropTypes.func.isRequired,
    mapType: PropTypes.string.isRequired,
    mapViewProps: PropTypes.objectOf(PropTypes.any),
    officesLayerVisibility: PropTypes.bool.isRequired,
    ports: PropTypes.arrayOf(GsmMapModels.Ports).isRequired,
    queryParams: TGsmQueryParams.isRequired,
    removeAllEntities: PropTypes.func.isRequired,
    resetAppUiState: PropTypes.bool.isRequired,
    restoreResetAppUiState: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    saveMapViewProps: PropTypes.func.isRequired,
    selectedCity: GsmMapModels.City,
    selectedCityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedDrill: GsmMapModels.City,
    selectedDrillId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedFerry: PropTypes.objectOf(PropTypes.any),
    selectedFerryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedTanker: GsmMapModels.Tanker,
    selectedTankerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedVessel: PropTypes.objectOf(PropTypes.any),
    setVesselDetailsTooltipPosition: PropTypes.func.isRequired,
    tankers: PropTypes.arrayOf(GsmMapModels.Tankers).isRequired,
    tankersLayerVisibility: PropTypes.bool.isRequired
};

GsmMap.defaultProps = {
    mapViewProps: null,
    selectedCity: null,
    selectedCityId: null,
    selectedDrill: null,
    selectedDrillId: null,
    selectedFerry: null,
    selectedFerryId: null,
    selectedTanker: null,
    selectedTankerId: null,
    selectedVessel: null
};

function mapStateToProps(state) {
    return {
        addresses: state.gsmReducer.addresses,
        cities: state.gsmReducer.cities,
        drills: state.gsmReducer.drills,
        drillsLayerVisibility: state.gsmMapLegendReducer.drillsLayerVisibility,
        ferries: state.gsmReducer.ferries,
        ferriesLayerVisibility: state.gsmMapLegendReducer.ferriesLayerVisibility,
        ferryRoutes: state.gsmReducer.ferryRoutes,
        ferryRoutesLayerVisibility: state.gsmMapLegendReducer.ferryRoutesLayerVisibility,
        mapType: state.gsmMapViewSwitchReducer.mapType,
        mapViewProps: state.gsmReducer.mapViewProps,
        officesLayerVisibility: state.gsmMapLegendReducer.officesLayerVisibility,
        ports: state.gsmReducer.ports,
        resetAppUiState: state.gsmReducer.resetAppUiState,
        selectedCity: state.gsmReducer.selectedCity,
        selectedCityId: state.gsmReducer.selectedCityId,
        selectedDrill: state.gsmReducer.selectedDrill,
        selectedDrillId: state.gsmReducer.selectedDrillId,
        selectedFerry: state.gsmReducer.selectedFerry,
        selectedFerryId: state.gsmReducer.selectedFerryId,
        selectedTanker: state.gsmReducer.selectedTanker,
        selectedTankerId: state.gsmReducer.selectedTankerId,
        selectedVessel: state.gsmReducer.selectedVessel,
        tankers: state.gsmReducer.tankers,
        tankersLayerVisibility: state.gsmMapLegendReducer.tankersLayerVisibility
    };
}

function mapDispatchToProps(dispatch) {
    return {
        closeRightSidebar: () => gsmMapRightSideBarCollapse(dispatch, false),
        getAddresses: params => getAddresses(dispatch, params),
        getCities: params => getCities(dispatch, params),
        getDrills: () => getDrills(dispatch),
        getFerries: () => getFerries(dispatch),
        getFerryRoutes: () => getFerryRoutes(dispatch),
        getPorts: params => getPorts(dispatch, params),
        getTankers: () => getTankers(dispatch),
        removeAllEntities: () => removeAllEntities(dispatch),
        restoreResetAppUiState: () => restoreResetAppUiState(dispatch),
        saveMapViewProps: mapViewProps => saveMapViewProps(dispatch, mapViewProps),
        setVesselDetailsTooltipPosition: position => setVesselDetailsTooltipPosition(dispatch, position)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GsmMap));
