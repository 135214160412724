import Excel from 'exceljs';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { formatXLSXCellValue, generateXLSXHeaderLogos } from '../../../reports-helpers';
import {
    tableHeaderStyle,
    tableCellStyle,
    headingTitleStyle
} from 'utils/helpers/xlsx-styles';
import { addWorksheet, triggerXLSXDownload } from 'utils/helpers/xlsx-helper';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.${key}`);

const speedFormat = { type: 'number', numFmt: '0.0 "kn"' };
const numberFormat = { type: 'number', numFmt: '0.0' };
const stringFormat = { type: 'string' };

const formatTableCell = (value, format, props = null, colSpan = 1, rowSpan = 1) => ({
    value: formatXLSXCellValue(value, format),
    colSpan,
    rowSpan,
    props: { ...tableCellStyle, ...props, numFmt: format.numFmt || null }
});

const generateTotalAverageSpeeds = (ballast, laden) => ([
    { values: [{ value: t('PREVIEW.FLEET_AVERAGE_SPEED_REPORT.TOTAL_AVERAGE_SPEED'), props: headingTitleStyle }] },
    {
        values: [
            { value: translate('GLOBAL.BALLAST'), colSpan: 2, props: tableHeaderStyle },
            null,
            { value: translate('GLOBAL.LADEN'), colSpan: 2, props: tableHeaderStyle }
        ]
    },
    {
        values: [
            formatTableCell(ballast, speedFormat, null, 2),
            null,
            formatTableCell(laden, speedFormat, null, 2)
        ]
    }
]);

const generateAverageSpeedsByVessel = (vessels) => {
    if (!vessels || vessels.length === 0) {
        return [];
    }
    const rows = new Array(vessels.length + 1);
    rows[0] = {
        values: [
            { value: t('PREVIEW.VESSEL_NAME'), colSpan: 2, props: tableHeaderStyle },
            null,
            { value: t('PREVIEW.AVERAGE_SPEED_BALLAST'), colSpan: 2, props: tableHeaderStyle },
            null,
            { value: t('PREVIEW.AVERAGE_SPEED_LADEN'), colSpan: 2, props: tableHeaderStyle },
            null
        ]
    };
    vessels.forEach((vessel, index) => {
        rows[index + 1] = {
            values: [
                formatTableCell(vessel.VesselTitle, stringFormat, null, 2),
                null,
                formatTableCell(vessel.AvgSpeedBallast, numberFormat, null, 2),
                null,
                formatTableCell(vessel.AvgSpeedLaden, numberFormat, null, 2),
                null
            ]
        };
    });
    return rows;
};

export default function* generateFleetSpeedReportXLSX({
    TotalAvgSpeedBallast,
    TotalAvgSpeedLaden,
    VesselSpeedData,
    fileName,
    companyLogo,
    rangeStart,
    rangeEnd
}) {
    const workbook = new Excel.Workbook();

    const sheetConfig = {
        name: 'Fleet Average Speed Report',
        props: {
            properties: { showGridLines: false },
            views: [{ showGridLines: false }],
            pageSetup: {
                paperSize: 9,
                orientation: 'portrait',
                margins: { left: 0.4, right: 0.4, top: 0.2, bottom: 0.2, header: 0, footer: 0 }
            }
        },
        columnWidth: 15,
        rows: [
            ...generateXLSXHeaderLogos(companyLogo, null, 4),
            {
                values: [{ value: t('FLEET_AVERAGE_SPEED_REPORT'), props: headingTitleStyle }]
            },
            null,
            {
                values: [{ value: `${t('PREVIEW.HEADER.DATE_RANGE')} ${rangeStart} - ${rangeEnd}` }]
            },
            null,
            ...generateTotalAverageSpeeds(TotalAvgSpeedBallast, TotalAvgSpeedLaden),
            null,
            {
                values: [
                    { value: t('PREVIEW.FLEET_AVERAGE_SPEED_REPORT.TOTAL_AVERAGE_SPEED'), props: headingTitleStyle }
                ]
            },
            ...generateAverageSpeedsByVessel(VesselSpeedData)
        ]
    };

    addWorksheet(workbook, sheetConfig);

    const xls64 = yield workbook.xlsx.writeBuffer({ base64: true });
    return triggerXLSXDownload(fileName)(xls64);
}
