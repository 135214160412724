export const ActionTypes = {
    FLEET_DASHBOARD_GET_VOYAGE_OPTIONS: 'FLEET_DASHBOARD_GET_VOYAGE_OPTIONS',
    FLEET_DASHBOARD_SET_VOYAGE_OPTIONS: 'FLEET_DASHBOARD_SET_VOYAGE_OPTIONS',
    FLEET_DASHBOARD_GET_VESSELS: 'FLEET_DASHBOARD_GET_VESSELS',
    FLEET_DASHBOARD_SET_VESSELS: 'FLEET_DASHBOARD_SET_VESSELS',
    FLEET_DASHBOARD_GET_NOTIFICATIONS: 'FLEET_DASHBOARD_GET_NOTIFICATIONS',
    FLEET_DASHBOARD_SET_NOTIFICATIONS: 'FLEET_DASHBOARD_SET_NOTIFICATIONS',
    FLEET_DASHBOARD_GET_VESSEL_POSITIONS: 'FLEET_DASHBOARD_GET_VESSEL_POSITIONS',
    FLEET_DASHBOARD_SET_VESSEL_POSITIONS: 'FLEET_DASHBOARD_SET_VESSEL_POSITIONS',
    FLEET_DASHBOARD_GET_EARNINGS: 'FLEET_DASHBOARD_GET_EARNINGS',
    FLEET_DASHBOARD_SET_EARNINGS: 'FLEET_DASHBOARD_SET_EARNINGS',
    FLEET_DASHBOARD_GET_INITIAL_DATA: 'FLEET_DASHBOARD_GET_INITIAL_DATA',
    FLEET_DASHBOARD_GET_FRESH_DATA: 'FLEET_DASHBOARD_GET_FRESH_DATA',
    FLEET_DASHBOARD_GET_PORTS: 'FLEET_DASHBOARD_GET_PORTS',
    FLEET_DASHBOARD_SET_PORTS: 'FLEET_DASHBOARD_SET_PORTS',
    FLEET_DASHBOARD_GET_MODELS: 'FLEET_DASHBOARD_GET_MODELS',
    FLEET_DASHBOARD_SET_MODELS: 'FLEET_DASHBOARD_SET_MODELS',
    FLEET_DASHBOARD_GET_LATEST_PREDICTION: 'FLEET_DASHBOARD_GET_LATEST_PREDICTION',
    FLEET_DASHBOARD_SET_LATEST_PREDICTION: 'FLEET_DASHBOARD_SET_LATEST_PREDICTION',
    FLEET_DASHBOARD_RESET_ALL: 'FLEET_DASHBOARD_RESET_ALL',
    FLEET_DASHBOARD_GET_FLEETS: 'FLEET_DASHBOARD_GET_FLEETS',
    FLEET_DASHBOARD_SET_FLEETS: 'FLEET_DASHBOARD_SET_FLEETS'
};

export function resetAll(dispatch) {
    dispatch({ type: ActionTypes.FLEET_DASHBOARD_RESET_ALL });
}

export function getVessels(dispatch, voyageOptionId, fleetId) {
    dispatch({ type: ActionTypes.FLEET_DASHBOARD_GET_VESSELS, voyageOptionId, fleetId });
}

export function getNotifications(dispatch, fleetId) {
    dispatch({ type: ActionTypes.FLEET_DASHBOARD_GET_NOTIFICATIONS, fleetId });
}

export function getLatestPrediction(dispatch, model) {
    dispatch({ type: ActionTypes.FLEET_DASHBOARD_GET_LATEST_PREDICTION, model });
}

export function getInitialData(dispatch, voyageOptionId, fleetId) {
    dispatch({ type: ActionTypes.FLEET_DASHBOARD_GET_INITIAL_DATA, voyageOptionId, fleetId });
}

export function getFreshData(dispatch, voyageOptionId, fleetId) {
    dispatch({ type: ActionTypes.FLEET_DASHBOARD_GET_FRESH_DATA, voyageOptionId, fleetId });
}

export function getPorts(dispatch, params) {
    dispatch({ type: ActionTypes.FLEET_DASHBOARD_GET_PORTS, params });
}
