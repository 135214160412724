import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* actions */
import { toggleUserSetting } from '../../../../user-actions';
/* utils */
import createByIdSelector from 'utils/helpers/selector-helper';
/* components */
import Positioner from 'components/positioner/positioner';
import TradingRouteTooltipContent from './trading-route-tooltip-content';
/* styles */
import './trading-route-tooltip.scss';

export class TradingRouteTooltip extends React.PureComponent {
    renderSingleTradingRoute = (tradingRoute) => {
        const tradingRoutePosition = this.props.tradingRoutesPositions[tradingRoute.Id];
        if (tradingRoutePosition) {
            return (
                <Positioner
                    className="sten-trading-route-tooltip"
                    top={tradingRoutePosition.top}
                    left={tradingRoutePosition.left}
                    key={tradingRoute.Id}
                >
                    <TradingRouteTooltipContent
                        tradingRoute={tradingRoute}
                        toggleUserSetting={this.props.toggleUserSetting}
                    />
                </Positioner>
            );
        }
        return null;
    };

    render() {
        if (this.props.tradingRoutes.length) {
            return (
                <div className="sten-trading-routes-tooltips">
                    {this.props.tradingRoutes.map(this.renderSingleTradingRoute)}
                </div>
            );
        }
        return null;
    }
}

TradingRouteTooltip.propTypes = {
    toggleUserSetting: PropTypes.func.isRequired,
    tradingRoutes: PropTypes.arrayOf(PropTypes.any).isRequired,
    tradingRoutesPositions: PropTypes.objectOf(PropTypes.any).isRequired
};

const tradingRoutePositionsById = createByIdSelector(
    (state) => state.tradingRouteTooltipReducer.tradingRoutesPositions,
    'Id'
);

function mapStateToProps(state) {
    return {
        tradingRoutes: state.marketIntelReducer.tradingRoutes,
        tradingRoutesPositions: tradingRoutePositionsById(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleUserSetting: (settingName, value) => toggleUserSetting(dispatch, settingName, value)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TradingRouteTooltip);
