import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* helpers */
import { formatNumber, getLinkToSedna } from 'utils/helpers/info-helper';
/* actions */
import {
    getCurrentVoyage,
    getImprovisedVoyage,
    getSelectedVesselBase,
    getVoyageActivity,
    setShouldCenterActiveElement
} from '../../home-actions';
import { updateUserSettings } from 'pages/user-pages/user-actions';
import { setActiveTab } from './vessel-info-actions';
import { addToMyFleet, removeFromMyFleet } from '../../../fleet-management/fleet-management-actions';
import { getConfigs } from 'components/vessel-report/vessel-report-actions';
/* selectors */
import { getSelectedPredefinedRange } from './vessel-info-selectors';
import { getAllVoyageReportTypes } from '../../home-selectors';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import Select from 'components/select/select';
import FlipSwitch from 'components/flip-switch/flip-switch';
import TabSelector from 'components/tab-selector/tab-selector';
import VesselInfoOverview from './vessel-info-overview/vessel-info-overview';
import VesselInfoVessel from './vessel-info-vessel/vessel-info-vessel';
import VesselInfoItinerary from './vessel-info-itinerary/vessel-info-itinerary';
import VesselInfoCargo from './vessel-info-cargo/vessel-info-cargo';
import VesselInfoEnergy from './vessel-info-energy/vessel-info-energy';
import Carousel from 'components/carousel/carousel';
import Menu, { MenuItem } from 'components/menu/menu';
/* styles */
import './vessel-info.scss';

export class VesselInfo extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeTabId: props.activeTabId,
            activeTabProps: null,
            rangeSelectorActive: false,
            selectedVesselId: null,
            selectedVoyage: null,
            improvisedVoyage: null
        };
    }

    static getDerivedStateFromProps(props, state) {
        let newState = null;
        if (props.selectedVesselId !== state.selectedVesselId
            || (state.activeTabId === 2 && props.selectedVoyageItinerary.length === 0)
            || (state.activeTabId === 3
                && props.selectedVoyageFixtures.length === 0
                && props.selectedVoyageTankLayout.length === 0)
            || (state.activeTabId === 4 && (!props.permissions.GetReports || !props.selectedVoyage))) {
            newState = { ...newState, selectedVesselId: props.selectedVesselId, activeTabId: 0, activeTabProps: null };
        }
        if (props.selectedVoyage !== state.selectedVoyage || props.improvisedVoyage !== state.improvisedVoyage) {
            newState = { ...newState, selectedVoyage: props.selectedVoyage, improvisedVoyage: props.improvisedVoyage };
            if (props.permissions.ShowVesselRanges) {
                newState = { ...newState, rangeSelectorActive: !props.selectedVoyage };
            }
        }
        return newState;
    }

    componentDidMount() {
        this.props.getConfigs();
    }

    componentDidUpdate(prevProps) {
        if (this.props.resourceUpdated !== prevProps.resourceUpdated && this.props.resourceUpdated.name === 'fleets') {
            this.props.getSelectedVesselBase();
        }
    }

    componentWillUnmount() {
        this.props.setActiveTab(this.state.activeTabId);
    }

    onCloseClick = () => {
        this.props.router.updateQueryParams({
            remove: [
                'vessel',
                'reportId',
                'reportEdit',
                'reportType',
                'questionnaireId',
                'questionnaireEdit',
                'voyage'
            ]
        });
    };

    getTabs = () => {
        const canSeeReports = this.props.permissions && this.props.permissions.GetReports;
        const currentVoyages = this.getCurrentVoyages();
        const selectedVoyage = this.getSelectedVoyage(currentVoyages);
        const voyagePortInfo = selectedVoyage ? selectedVoyage.VoyagePortInfo : '';
        const {
            improvisedVoyage,
            getVoyageActivity,
            notifications,
            selectedVoyageItinerary,
            selectedVoyageFixtures,
            selectedVoyageTankLayout
        } = this.props;

        const tabs = [{
            id: 0,
            title: t('VESSEL_INFO.OVERVIEW.TITLE'),
            icon: 'icon-overview',
            content: (
                <VesselInfoOverview
                    showEarningsInfo={!improvisedVoyage}
                    isRange={this.state.rangeSelectorActive}
                    getVoyageActivity={getVoyageActivity}
                    notifications={notifications}
                />
            )
        }, {
            id: 1,
            title: t('VESSEL_INFO.VESSEL.TITLE'),
            icon: 'icon-laden',
            content: <VesselInfoVessel getLinkTo={this.props.router.getLinkTo} />
        }, {
            id: 2,
            title: t('VESSEL_INFO.ITINERARY.TITLE'),
            icon: 'icon-itinerary',
            disabled: selectedVoyageItinerary.length === 0,
            content: <VesselInfoItinerary voyagePortInfo={voyagePortInfo} />
        }, {
            id: 3,
            title: t('VESSEL_INFO.CARGO.TITLE'),
            icon: 'icon-cargo',
            disabled: selectedVoyageFixtures.length === 0 && selectedVoyageTankLayout.length === 0,
            content: <VesselInfoCargo showVoyageInfo={!improvisedVoyage} />
        }];
        if (canSeeReports) {
            tabs.push({
                id: 4,
                title: t('VESSEL_INFO.ENERGY.TITLE'),
                icon: 'icon-gauge',
                disabled: !selectedVoyage,
                content: (this.state.activeTabProps?.showMoreReports
                    ? (
                        <VesselInfoEnergy
                            reportTypeOptions={this.props.reportTypeOptions}
                            showMoreReports={this.state.activeTabProps.showMoreReports}
                            notifications={notifications}
                        />
                    )
                    : (
                        <VesselInfoEnergy
                            notifications={notifications}
                            reportTypeOptions={this.props.reportTypeOptions}
                        />
                    )
                )
            });
        }
        return tabs;
    };

    static getVoyageOption(voyage) {
        return (
            <span>
                <span className="text-semibold">{voyage.VoyageNumber}</span>
                <span className="text-secondary">
                    {` ${voyage.VoyagePortInfo}`}
                </span>
            </span>
        );
    }

    setActiveTab = (tabId, tabProps) => {
        const tabs = this.getTabs();
        const selectedTab = tabs.find(tab => tab.id === tabId);
        if (!selectedTab.disabled) {
            this.setState({
                activeTabId: tabId,
                activeTabProps: tabProps || null
            });
        }
    };

    toggleActiveSelector = () => {
        const rangeSelectorActive = !this.state.rangeSelectorActive;
        this.setState({ rangeSelectorActive }, () => {
            if (!rangeSelectorActive) {
                this.props.getCurrentVoyage();
            } else if (this.props.selectedRange) {
                this.props.router.updateQueryParams({ remove: ['voyage'] });
                this.props.getImprovisedVoyage(this.props.selectedRange);
            }
        });
    };

    selectVoyage = voyage => {
        if (voyage.VoyageId) {
            this.props.router.updateQueryParams({ add: { voyage: voyage.VoyageId } });
        }
    };

    updateSelectedRange = range => {
        this.props.getImprovisedVoyage(range);
        this.props.updateUserSettings({ VesselRangeId: range.Id });
    };

    getCurrentVoyages = () => {
        let currentVoyages = [];
        if (this.props.selectedVesselVoyages.length > 0 && this.props.startDate) {
            currentVoyages = this.props.selectedVesselVoyages.filter(voyage =>
                !voyage.EndDate || moment.utc(voyage.EndDate).isAfter(this.props.startDate));
        }
        return currentVoyages;
    };

    getSelectedVoyage = (currentVoyages) => {
        let selectedVoyage = null;
        if (this.props.selectedVoyage) {
            selectedVoyage = currentVoyages.find(voyage => voyage.VoyageId === this.props.selectedVoyage.VoyageId);
        }
        return selectedVoyage;
    };

    locateVesselOnMap = () => {
        this.props.setShouldCenterActiveElement(true);
    };

    editVessel = () => ({
        pathname: `${appRoutes.Admin.VesselManagement}/edit/${this.props.selectedVesselId}`
    });

    linkToCharterpartyInfo = () => this.props.router.getLinkTo({
        add: { cpVoyage: this.props.selectedVoyage.VoyageId }
    });

    linkToSedna = () => {
        const { selectedVesselBase, selectedVoyage } = this.props;
        return getLinkToSedna(selectedVesselBase.Title, selectedVoyage && selectedVoyage.VoyageNumber);
    };

    calculateDistance = () => this.props.router.getLinkTo({
        add: { vessel: this.props.selectedVesselId },
        pathname: appRoutes.Routing.DistanceCalculator
    });

    estimateEmissions = () => this.props.router.getLinkTo({
        add: { vessel: this.props.selectedVesselId },
        pathname: appRoutes.EmissionEstimator
    });

    openInTab = () => {
        const { pathname, search } = this.linkToSedna();
        window.open(`${pathname}?${search}`, '_blank');
    }

    addToMyFleet = () => this.props.addToMyFleet(this.props.selectedVesselId);

    removeFromMyFleet = () => this.props.removeFromMyFleet(this.props.selectedVesselId);

    renderContextMenu = () => {
        const { permissions, selectedVoyage, selectedVesselBase } = this.props;
        return (
            <Menu>
                {permissions?.SednaIntegrationEnabled && ConfigService.featureToggles.sednaIntegrationURL && (
                    <MenuItem
                        onClick={this.openInTab}
                        icon="icon-sedna"
                    >
                        {t('VESSEL_INFO.MENU.EXPLORE_IN_SEDNA')}
                        <span className="icon btn-link__outbound-icon" />
                    </MenuItem>
                )}
                <MenuItem onClick={this.locateVesselOnMap} icon="icon-position">
                    {t('VESSEL_INFO.MENU.LOCATE_ON_MAP')}
                </MenuItem>
                {permissions?.MenuVesselsManagement && selectedVesselBase.IsInMyFleet ? (
                    <MenuItem onClick={this.removeFromMyFleet} icon="icon-fleet-remove">
                        {t('VESSEL_INFO.MENU.REMOVE_FROM_MY_FLEET')}
                    </MenuItem>
                ) : (
                    <MenuItem onClick={this.addToMyFleet} icon="icon-fleet-add">
                        {t('VESSEL_INFO.MENU.ADD_TO_MY_FLEET')}
                    </MenuItem>
                )}
                {permissions?.ShowDistanceCalc && ConfigService.featureToggles.showDistanceCalculator && (
                    <MenuItem to={this.calculateDistance()} icon="icon-distance-calculator">
                        {t('VESSEL_INFO.MENU.CALCULATE_DISTANCE')}
                    </MenuItem>
                )}
                {permissions?.ShowEmissionEstimator && ConfigService.featureToggles.showEmissionEstimator && (
                    <MenuItem to={this.estimateEmissions()} icon="icon-distance-calculator">
                        {t('VESSEL_INFO.MENU.ESTIMATE_EMISSIONS')}
                    </MenuItem>
                )}
                {permissions?.AssignVesselToFleet && (
                    <MenuItem to={this.editVessel()} icon="icon-edit">
                        {t('VESSEL_INFO.MENU.EDIT_VESSEL')}
                    </MenuItem>
                )}
                {ConfigService.featureToggles.showCharterpartyInfo
                    && selectedVoyage
                    && permissions.GetCharterparties && (
                    <MenuItem to={this.linkToCharterpartyInfo()} icon="icon-tenant">
                        {t('VESSEL_INFO.MENU.SHOW_CHARTERPARTY_INFORMATION')}
                    </MenuItem>
                )}
            </Menu>
        );
    };

    render() {
        const vesselBase = this.props.selectedVesselBase;
        if (!vesselBase) {
            return (
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <div className="flex-grow" />
                        <div className="flex-shrink">
                            <button className="btn-link icon icon-close" onClick={this.onCloseClick} />
                        </div>
                    </div>
                    <div className="sten-content__body" />
                </div>
            );
        }

        const subtitleInfoArray = [];
        if (vesselBase.VesselTypeName) {
            subtitleInfoArray.push(vesselBase.VesselTypeName);
        }
        if (vesselBase.VesselTypeName === 'LNG' && vesselBase.TotalCubicCapacity) {
            subtitleInfoArray.push(
                `${t('VESSEL_INFO.CAPACITY')} `
                + `${formatNumber(vesselBase.TotalCubicCapacity, 0)} ${t('UNITS.CUBIC_CAPACITY')}`
            );
        } else if (vesselBase.VesselTypeName !== 'LNG' && vesselBase.DWT) {
            subtitleInfoArray.push(
                `${t('VESSEL_INFO.DWT')} ${formatNumber(vesselBase.DWT, 0)} ${t('UNITS.DWT')}`
            );
        }
        if (vesselBase.IMOClass) {
            subtitleInfoArray.push(`${t('VESSEL_INFO.IMO_CLASS')} ${vesselBase.IMOClass}`);
        }
        const currentVoyages = this.getCurrentVoyages();
        const selectedVoyage = this.getSelectedVoyage(currentVoyages);
        const tabs = this.getTabs();
        const activeTab = tabs.find(tab => tab.id === this.state.activeTabId);
        const images = this.props.selectedVesselImages;
        const showImagesCarousel = this.props.permissions.ShowVesselImages && images && images.length > 0;
        let panelClass = 'sten-content sten-vessel-info';
        if (showImagesCarousel) {
            panelClass += ' sten-vessel-info--with-carousel';
        }
        return (
            <div className={panelClass}>
                <div className="sten-content__header flex-row">
                    <div className="flex-grow">
                        <h1 className="sten-content__title">{vesselBase.Title}</h1>
                        <p className="sten-content__subtitle">{subtitleInfoArray.join(', ')}</p>
                    </div>
                    <div className="flex-shrink">
                        {this.renderContextMenu()}
                    </div>
                    <div className="sten-content__vertical-separator" />
                    <div className="flex-shrink">
                        <button className="btn-link icon icon-close" onClick={this.onCloseClick} />
                    </div>
                </div>
                {showImagesCarousel && <Carousel images={images} />}
                <div className="sten-content__body sten-vessel-info__body-fixed">
                    <div className="sten-content__section">
                        <div className="flex-row">
                            <div className="flex-grow">
                                {!this.state.rangeSelectorActive
                                    ? (
                                        <Select
                                            className="sten-vessel-info__select"
                                            disabled={currentVoyages.length === 0}
                                            valueKey="VoyageId"
                                            labelKey="VoyageNumber"
                                            options={currentVoyages}
                                            optionRenderer={VesselInfo.getVoyageOption}
                                            placeholder={t('VESSEL_INFO.VOYAGE_PLACEHOLDER')}
                                            menuShownIcon="icon-chevron-down"
                                            value={selectedVoyage}
                                            onChange={this.selectVoyage}
                                        />
                                    )
                                    : (
                                        <Select
                                            className="sten-vessel-info__select sten-vessel-info--range-selector"
                                            placeholder={t('VESSEL_INFO.OVERVIEW.TIME_RANGE')}
                                            options={this.props.rangeOptions}
                                            value={this.props.selectedRange}
                                            valueKey="Id"
                                            labelKey="DisplayName"
                                            onChange={this.updateSelectedRange}
                                        />
                                    )
                                }

                            </div>
                            {this.props.permissions.ShowVesselRanges
                                && (
                                    <div className="flex-shrink">
                                        <FlipSwitch
                                            doubleOn
                                            disabled={this.props.selectedVesselVoyages.length === 0}
                                            value={this.state.rangeSelectorActive}
                                            onChange={this.toggleActiveSelector}
                                            activeLabel="Range"
                                            activeTitle="Switch to voyage selector"
                                            inactiveLabel="Voyage"
                                            inactiveTitle="Switch to range selector"
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <TabSelector
                        tabItems={tabs}
                        activeTabId={this.state.activeTabId}
                        onChange={this.setActiveTab}
                    />
                </div>
                <div className="sten-content__body sten-vessel-info__body-content">
                    {activeTab.content}
                </div>
            </div>
        );
    }
}

VesselInfo.propTypes = {
    activeTabId: PropTypes.number.isRequired,
    addToMyFleet: PropTypes.func.isRequired,
    getConfigs: PropTypes.func.isRequired,
    getCurrentVoyage: PropTypes.func.isRequired,
    getImprovisedVoyage: PropTypes.func.isRequired,
    getSelectedVesselBase: PropTypes.func.isRequired,
    getVoyageActivity: PropTypes.func.isRequired,
    improvisedVoyage: PropTypes.objectOf(PropTypes.any),
    notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    rangeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    removeFromMyFleet: PropTypes.func.isRequired,
    reportTypeOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
    resourceUpdated: PropTypes.objectOf(PropTypes.any).isRequired,
    router: TRouter.isRequired,
    selectedRange: PropTypes.objectOf(PropTypes.any),
    selectedVesselBase: PropTypes.objectOf(PropTypes.any),
    selectedVesselId: PropTypes.number,
    selectedVesselImages: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedVesselVoyages: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedVoyage: PropTypes.objectOf(PropTypes.any),
    selectedVoyageFixtures: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedVoyageItinerary: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedVoyageTankLayout: PropTypes.arrayOf(PropTypes.object).isRequired,
    setActiveTab: PropTypes.func.isRequired,
    setShouldCenterActiveElement: PropTypes.func.isRequired,
    startDate: PropTypes.objectOf(PropTypes.any).isRequired,
    updateUserSettings: PropTypes.func.isRequired
};

VesselInfo.defaultProps = {
    improvisedVoyage: null,
    selectedRange: null,
    selectedVesselBase: null,
    selectedVesselId: null,
    selectedVoyage: null
};

function mapStateToProps(state) {
    return {
        activeTabId: state.vesselInfoReducer.activeTabId,
        imagesByImo: state.vesselInfoReducer.images,
        improvisedVoyage: state.homeReducer.improvisedVoyage,
        notifications: state.homeReducer.selectedVesselNotifications,
        permissions: state.userReducer.permissions,
        rangeOptions: state.vesselInfoReducer.rangeOptions,
        resourceUpdated: state.userReducer.resourceUpdated,
        reportTypeOptions: getAllVoyageReportTypes(state),
        selectedRange: getSelectedPredefinedRange(state),
        selectedVesselBase: state.homeReducer.selectedVesselBase,
        selectedVesselId: state.homeReducer.selectedVesselId,
        selectedVesselVoyages: state.homeReducer.selectedVesselVoyages,
        selectedVesselImages: state.homeReducer.selectedVesselImages,
        selectedVoyage: state.homeReducer.selectedVoyage,
        selectedVoyageTankLayout: state.homeReducer.selectedVoyageTankLayout,
        selectedVoyageFixtures: state.homeReducer.selectedVoyageFixtures,
        selectedVoyageItinerary: state.homeReducer.selectedVoyageItinerary,
        startDate: state.mapDatePickerReducer.startDate
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addToMyFleet: imo => addToMyFleet(dispatch, imo),
        getCurrentVoyage: () => getCurrentVoyage(dispatch),
        getImprovisedVoyage: range => getImprovisedVoyage(dispatch, range),
        getSelectedVesselBase: () => getSelectedVesselBase(dispatch),
        getConfigs: () => getConfigs(dispatch),
        getVoyageActivity: () => getVoyageActivity(dispatch),
        removeFromMyFleet: imo => removeFromMyFleet(dispatch, imo),
        setActiveTab: activeTabId => setActiveTab(dispatch, activeTabId),
        setShouldCenterActiveElement: shouldCenter => setShouldCenterActiveElement(dispatch, shouldCenter),
        updateUserSettings: settings => updateUserSettings(dispatch, settings)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VesselInfo));
