import * as colors from 'utils/helpers/pdf-styles/colors';

export default {
    '.sten-icon-trend-strong-up:before': {
        color: colors.colorTurquoise,
        content: '"⇑"'
    },
    '.sten-icon-trend-moderate-up:before': {
        color: colors.colorForestGreen,
        content: '"⇗"'
    },
    '.sten-icon-trend-flat:before': {
        color: colors.colorYellow,
        content: '"⇒"'
    },
    '.sten-icon-trend-moderate-down:before': {
        color: colors.colorOrange,
        content: '"⇘"'
    },
    '.sten-icon-trend-strong-down:before': {
        content: '"⇓"',
        color: colors.colorMilanoRed
    }
};
