import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { formatNumber, getSeverity, getClassName } from 'utils/helpers/info-helper';
/* components */
import InfoCard from '../info-card/info-card';
/* styles */
import './consumption-cargo-panel.scss';

const t = (key) => translate(`ENERGY_MANAGEMENT.KPI.INFO_GRAPH_SECTION.${key}`);

export default class EnergyManagementKPIConsumptionCargoPanel extends React.PureComponent {
    getConsumptionSeverityClass = (value) => {
        if (!value) {
            return '';
        }
        return getSeverity({ 'text-danger': '[0,Infinity)' }, value);
    };

    getFormattedValue = (value, unit, decimals = 1, showSign = false) => {
        return typeof value === 'number' ? `${formatNumber(value, decimals, showSign)}${unit || ''}` : '-';
    };

    render() {
        const {
            ballastConsumption,
            ladenConsumption,
            averageCargo,
            rangeEndDate,
            rangeOption
        } = this.props;
        const className = getClassName(
            'sten-panel sten-energy-management-kpi-consumption-cargo-panel',
            this.props.className
        );
        return (
            <div className={className}>
                <div className="sten-panel__header">
                    <h4 className="text-uppercase">{t('CONSUMPTION_CARGO')}</h4>
                </div>
                <div className="sten-panel__body flex-grow flex-row--sm">
                    <div className="col-sm-8">
                        <InfoCard
                            title={t('BALLAST_CONSUMPTION')}
                            value={this.getFormattedValue(ballastConsumption.total, '%', 1, true)}
                            valueClassName={this.getConsumptionSeverityClass(ballastConsumption.total)}
                            footerValue1={this.getFormattedValue(ballastConsumption.year1, ' mt/kNm')}
                            footerValue2={this.getFormattedValue(ballastConsumption.year2, ' mt/kNm')}
                            iconClassName="icon-oil-ballast sten-energy-management-kpi-info-graph-section__icon"
                            rangeEndDate={rangeEndDate}
                            rangeOption={rangeOption}
                        />
                    </div>
                    <div className="col-sm-8 sten-panel__separator">
                        <InfoCard
                            title={t('LADEN_CONSUMPTION')}
                            value={this.getFormattedValue(ladenConsumption.total, '%', 1, true)}
                            valueClassName={this.getConsumptionSeverityClass(ladenConsumption.total)}
                            footerValue1={this.getFormattedValue(ladenConsumption.year1, ' mt/kNm')}
                            footerValue2={this.getFormattedValue(ladenConsumption.year2, ' mt/kNm')}
                            iconClassName="icon-oil-laden sten-energy-management-kpi-info-graph-section__icon"
                            rangeEndDate={rangeEndDate}
                            rangeOption={rangeOption}
                        />
                    </div>
                    <div className="col-sm-8 sten-panel__separator">
                        <InfoCard
                            title={t('AVERAGE_CARGO')}
                            value={this.getFormattedValue(averageCargo.total, '%', 1, true)}
                            footerValue1={this.getFormattedValue(averageCargo.year1, ' mt')}
                            footerValue2={this.getFormattedValue(averageCargo.year2, ' mt')}
                            iconClassName="icon-cargo sten-energy-management-kpi-info-graph-section__icon"
                            rangeEndDate={rangeEndDate}
                            rangeOption={rangeOption}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const InfoCardValue = PropTypes.shape({
    total: PropTypes.number,
    year1: PropTypes.number,
    year2: PropTypes.number
});

EnergyManagementKPIConsumptionCargoPanel.propTypes = {
    averageCargo: InfoCardValue.isRequired,
    ballastConsumption: InfoCardValue.isRequired,
    className: PropTypes.string,
    ladenConsumption: InfoCardValue.isRequired,
    rangeEndDate: PropTypes.objectOf(PropTypes.any).isRequired,
    rangeOption: PropTypes.objectOf(PropTypes.any).isRequired
};

EnergyManagementKPIConsumptionCargoPanel.defaultProps = {
    className: ''
};
