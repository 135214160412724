import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import memoize from 'memoize-one';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { getClassName } from 'utils/helpers/info-helper';
/* components */
import VoyageReportTooltip from './voyage-report-tooltip';
/* styles */
import './voyage-reports.scss';

class VoyageReport extends React.Component {
    shouldComponentUpdate(nextProps) {
        const shouldRerender = this.isRerenderNeeded(nextProps);

        if (!shouldRerender) {
            const nr = nextProps.reportPosition;
            const r = this.props.reportPosition;

            if (nr.left !== r.left || nr.top !== r.top) {
                // update position directly
                this.containerRef.style.transform = `translate(${nr.left}px, ${nr.top}px)`;
            }
        }

        return shouldRerender;
    }

    isRerenderNeeded(nextProps) {
        for (const propName in nextProps) {
            if (propName !== 'reportPosition' && nextProps[propName] !== this.props[propName]) {
                return true;
            }
        }

        return false;
    }

    getContainerStyle = memoize((reportPosition) => {
        return {
            willChange: 'transform',
            transform: `translate(${reportPosition.left}px, ${reportPosition.top}px)`
        };
    });

    getVesselReportOverviewLink = () => {
        const { router, report } = this.props;
        const add = {
            reportId: report.ReportId.toString(),
            reportType: report.ReportType.toString()
        };
        if (router.query.reportId !== add.reportId) {
            return router.getLinkTo({ add });
        }
        return router.location;
    }

    static getDotStyle = memoize((dotSize) => ({
        width: `${dotSize}px`,
        height: `${dotSize}px`,
        marginLeft: `${-0.5 * dotSize}px`,
        marginTop: `${-0.5 * dotSize}px`
    }));

    saveContainerRef = (r) => { this.containerRef = r; };

    render() {
        const { report, zoomMultiplier, fontSizeMultiplier, reportPosition, isActive } = this.props;
        const dotSize = parseInt(0.45 * zoomMultiplier * fontSizeMultiplier, 10);
        const className = getClassName('sten-voyage-report', {
            'sten-voyage-report--active': isActive,
            'sten-voyage-report--laden': !report.CargoStatusName || report.CargoStatusName === 'Laden'
        });
        return (
            <Link
                ref={this.saveContainerRef}
                className={className}
                style={this.getContainerStyle(reportPosition)}
                key={report.ReportId}
                to={this.getVesselReportOverviewLink()}
            >
                <div className="sten-voyage-report__marker">
                    <div className="sten-voyage-report__marker-dot" style={VoyageReport.getDotStyle(dotSize)} />
                </div>
                <VoyageReportTooltip
                    report={report}
                    isActive={isActive}
                />
            </Link>
        );
    }
}

VoyageReport.propTypes = {
    fontSizeMultiplier: PropTypes.number.isRequired,
    isActive: PropTypes.bool.isRequired,
    report: PropTypes.objectOf(PropTypes.any).isRequired,
    reportPosition: PropTypes.objectOf(PropTypes.any).isRequired,
    router: TRouter.isRequired,
    zoomMultiplier: PropTypes.number.isRequired
};

export default withRouter(VoyageReport);
