import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
import Input from 'components/input/input';
import Textarea from 'components/textarea/textarea';
import Validation from 'components/validation/validation';
import FileUpload from 'components/file-upload/file-upload';
/* actions */
import { navMenuExpand } from '../left-side-bar-actions';
/* services */
import InquiryService from 'services/core-api/inquiry-service';
/* styles */
import './contact-inquiry.scss';

class ContactInquiry extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            subject: '',
            message: '',
            messageSent: false,
            image: null,
            imageURL: null
        };
    }

    allowedFileTypes = [
        'image/jpeg',
        'image/png',
        'image/bmp',
        'image/gif'
    ];

    handleSubjectChange = subject => this.setState({ subject });

    handleMessageChange = message => this.setState({ message });

    getMetadata = () => {
        const { height, width } = window.screen;

        const data = {
            userAgent: navigator.userAgent,
            url: window.location.href,
            appVersion: process.env.APP_VERSION,
            resolution: `${width}x${height}`,
            windowSize: `${window.innerWidth}x${window.innerHeight}`
        };
        /**
         * Because metadata is parsed in email as html,
         * we need to replace \n with <br/> for formatting metadata
         */
        return `<br/>System: ${data.userAgent}<br/>`
        + `App version: ${data.appVersion}<br/>`
        + `Url: ${data.url}<br/>`
        + `Screen resolution: ${data.resolution}<br/>`
        + `Window size: ${data.windowSize}`;
    };

    handleSubmit = () => {
        const { subject, message, image } = this.state;
        const metadata = this.getMetadata(message);
        const data = {
            subject,
            body: message,
            metadata,
            formFiles: image
        };
        const formData = new FormData();
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });

        InquiryService.sendContactInquiryData(formData)
            .then(messageSent =>
                this.setState({
                    messageSent
                }))
            .catch(() =>
                this.setState({
                    messageSent: false
                }));
    };

    handleImageUpload = (files) => {
        if (files && files[0]) {
            this.setState({ image: files[0], imageURL: URL.createObjectURL(files[0]) });
        } else {
            this.setState({
                image: null,
                imageURL: null
            });
        }
    };

    render() {
        return (
            <div className="sten-left-sidebar-panel">
                <Validation.Form
                    onSubmit={this.handleSubmit}
                    className="sten-validation-form sten-content sten-content--has-footer"
                >
                    <div className="sten-content__header">
                        <h1 className="text-uppercase">
                            {translate('CONTACT_INQUIRY.CONTACT_US')}
                        </h1>
                    </div>
                    <div className="sten-content__body">
                        <ScrollArea>
                            {this.state.messageSent ? (
                                <React.Fragment>
                                    <div className="sten-content__section text-center">
                                        <h2 className="text-success">
                                            {translate(
                                                'CONTACT_INQUIRY.SUCCESSFULLY_SENT_TITLE'
                                            )}
                                        </h2>
                                    </div>
                                    <div className="sten-content__section text-center">
                                        <p>
                                            {translate(
                                                'CONTACT_INQUIRY.SUCCESSFULLY_SENT_TEXT'
                                            )}
                                        </p>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <div className="sten-content__section">
                                        <label className="label">
                                            {translate(
                                                'CONTACT_INQUIRY.SUBJECT'
                                            )}
                                        </label>
                                        <Validation.Wrapper
                                            validations={{
                                                required: true,
                                                maxLength: 130
                                            }}
                                        >
                                            <Input
                                                name="subject"
                                                type="text"
                                                value={this.state.subject}
                                                onChange={this.handleSubjectChange}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                    <div className="sten-content__section">
                                        <label className="label">
                                            {translate(
                                                'CONTACT_INQUIRY.MESSAGE'
                                            )}
                                        </label>
                                        <Validation.Wrapper
                                            validations={{
                                                required: true,
                                                maxLength: 5000
                                            }}
                                        >
                                            <Textarea
                                                name="message"
                                                value={this.state.message}
                                                onChange={this.handleMessageChange}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                </React.Fragment>
                            )}
                            {!this.state.messageSent && (
                                <FileUpload
                                    isDeletable
                                    isImageUpload
                                    icon="icon-image"
                                    onChange={this.handleImageUpload}
                                    allowedFileTypes={this.allowedFileTypes}
                                    imageURI={this.state.imageURL}
                                    className="sten-content__section sten-contact-form__image-upload"
                                />
                            )}
                        </ScrollArea>
                    </div>
                    <div className="sten-content__footer">
                        {this.state.messageSent ? (
                            <button
                                type="button"
                                className="btn btn--primary flex-grow"
                                onClick={this.props.navMenuClose}
                            >
                                {translate('GLOBAL.CLOSE')}
                            </button>
                        ) : (
                            <Validation.Button className="btn btn--primary flex-grow">
                                {translate('CONTACT_INQUIRY.SEND')}
                            </Validation.Button>
                        )}
                    </div>
                </Validation.Form>
            </div>
        );
    }
}

ContactInquiry.propTypes = {
    navMenuClose: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
    return {
        navMenuClose: () => navMenuExpand(dispatch, false)
    };
}

export default connect(null, mapDispatchToProps)(ContactInquiry);
