import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { getClassName } from 'utils/helpers/info-helper';
/* styles */
import './badge-toggle.scss';

class BadgeToggle extends React.PureComponent {
    handleClick = e => {
        e.stopPropagation();
        if (!this.props.isDisabled && this.props.onClick) {
            this.props.onClick();
        }
    };

    render() {
        const { isActive, isDisabled, className, title } = this.props;
        const badgeToggleClass = getClassName('sten-badge-toggle', className, {
            'sten-badge-toggle--clickable': !isDisabled && !!this.props.onClick,
            'sten-badge-toggle--inactive': !isActive,
            'sten-badge-toggle--disabled': isDisabled
        });
        const iconClass = `sten-badge-toggle__icon icon ${isActive ? this.props.icon : this.props.inactiveIcon}`;
        return (
            <div className={badgeToggleClass} onClick={this.handleClick} title={title}>
                <span className="sten-badge-toggle__label">{this.props.label}</span>
                <span className={iconClass} />
            </div>
        );
    }
}

BadgeToggle.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string,
    inactiveIcon: PropTypes.string,
    isActive: PropTypes.bool,
    isDisabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    title: PropTypes.string
};

BadgeToggle.defaultProps = {
    className: '',
    icon: 'icon-eye',
    inactiveIcon: 'icon-eye-closed',
    isActive: false,
    isDisabled: false,
    onClick: undefined,
    title: ''
};

export default BadgeToggle;
