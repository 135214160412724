import Excel from 'exceljs';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import {
    tableHeaderStyle as basicTableHeaderStyle,
    tableCellStyle,
    headingTitleStyle
} from 'utils/helpers/xlsx-styles';
import { addWorksheet, triggerXLSXDownload, formatXLSXCellValue } from 'utils/helpers/xlsx-helper';
import { generateXLSXHeaderLogos } from '../../../reports-helpers';
/* constants */
import {
    cargoProps,
    emissionProps,
    otherProps,
    colWidths,
    fuelColWidth
} from './fleet-performance-report-type-b-constants';

const tr = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.${key}`);
const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.FLEET_PERFORMANCE_REPORT.${key}`);

const customColors = {
    segmentHeader: 'FF9ED2FA',
    fleetHeader: 'FFD5ECFD',
    contractHeader: 'FFF1F9FE'
};

const tableHeaderStyle = {
    ...basicTableHeaderStyle,
    fill: {
        ...basicTableHeaderStyle.fill,
        fgColor: { argb: customColors.fleetHeader }
    }
};

const centeredTableHeaderStyle = {
    ...tableHeaderStyle,
    alignment: {
        horizontal: 'center',
        wrapText: true,
        vertical: 'middle'
    }
};

const rightAlignedTableHeaderStyle = {
    ...tableHeaderStyle,
    alignment: {
        horizontal: 'right',
        wrapText: true,
        vertical: 'middle'
    }
};

const rightAlignedTableCellStyle = {
    ...tableCellStyle,
    alignment: {
        horizontal: 'right',
        wrapText: true,
        vertical: 'middle'
    }
};

const summaryCellStyle = {
    ...tableCellStyle,
    font: {
        ...tableCellStyle.font,
        bold: true
    }
};

const rightAlignedSummaryCellStyle = {
    ...rightAlignedTableCellStyle,
    font: {
        ...rightAlignedTableCellStyle.font,
        bold: true
    }
};

const segmentHeaderStyle = {
    ...tableHeaderStyle,
    fill: {
        ...tableHeaderStyle.fill,
        fgColor: { argb: customColors.segmentHeader }
    }
};

const fleetHeaderStyle = {
    ...tableHeaderStyle,
    fill: {
        ...tableHeaderStyle.fill,
        fgColor: { argb: customColors.fleetHeader }
    }
};

const contractHeaderStyle = {
    ...tableHeaderStyle,
    fill: {
        ...tableHeaderStyle.fill,
        fgColor: { argb: customColors.contractHeader }
    }
};

const formatTableCell = (val, props = {}, style = null, colSpan = 1) => {
    const { value, numFmt } = formatXLSXCellValue(val, props);
    return {
        value,
        props: { ...rightAlignedTableCellStyle, ...style, numFmt },
        colSpan
    };
};

const renderDataProps = (data, props, style, isAvg = false) => (
    props.map(prop => formatTableCell(
        data[prop.key],
        { ...prop, numFmt: isAvg ? prop.avgNumFmt : prop.numFmt },
        style
    ))
);

const renderFuelData = (data, fuelTypes, style) => (
    fuelTypes.map(fuelType => (
        formatTableCell(data[fuelType.Id] ? data[fuelType.Id].Quantity : null, otherProps.fuel, style)
    ))
);

/* eslint-disable max-len */
const fuelDataSections = [
    { prop: 'ConsumptionsAtSeaBallast', fuelProp: 'seaBallast', avgProp: 'AvgConsumptionAtSeaBallast', totalProp: 'TotalConsumptionAtSeaBallast' },
    { prop: 'ConsumptionsAtSeaLaden', fuelProp: 'seaLaden', avgProp: 'AvgConsumptionAtSeaLaden', totalProp: 'TotalConsumptionAtSeaLaden' },
    { prop: 'ConsumptionsAtSea', fuelProp: 'sea', avgProp: 'AvgConsumptionAtSea', totalProp: 'TotalConsumptionAtSea' },
    { prop: 'ConsumptionsInCanal', fuelProp: 'canal', avgProp: 'AvgConsumptionInCanal', totalProp: 'TotalConsumptionInCanal' },
    { prop: 'ConsumptionsAnchoredDrifting', fuelProp: 'anchoredDrifting', avgProp: 'AvgConsumptionAnchoredDrifting', totalProp: 'TotalConsumptionAnchoredDrifting' },
    { prop: 'ConsumptionsCargoLoad', fuelProp: 'cargoLoad', avgProp: 'AvgConsumptionCargoLoad', totalProp: 'TotalConsumptionCargoLoad' },
    { prop: 'ConsumptionsCargoDischarge', fuelProp: 'cargoDischarge', avgProp: 'AvgConsumptionCargoDischarge', totalProp: 'TotalConsumptionCargoDischarge' },
    { prop: 'ConsumptionsInPort', fuelProp: 'port', avgProp: 'AvgConsumptionInPort', totalProp: 'TotalConsumptionInPort' }
];
/* eslint-enable max-len */

const renderFuelDataSection = (config, data, fuelTypes, style = null) => ([
    ...renderFuelData(data[config.prop], fuelTypes[config.fuelProp], style),
    formatTableCell(data[config.avgProp], otherProps.AvgConsumption, style),
    formatTableCell(data[config.totalProp], otherProps.TotalConsumption, style)
]);

const renderFuelHeaderSection = (config, fuelTypes) => ([
    ...fuelTypes[config.fuelProp].map(fuelType => ({
        value: `${fuelType.Name} (${translate('UNITS.CONSUMPTION')})`,
        props: rightAlignedTableHeaderStyle
    })),
    { value: t('AVG'), props: rightAlignedTableHeaderStyle },
    { value: t('TOTAL'), props: rightAlignedTableHeaderStyle }
]);

const getReportTableHeader = (fuelTypes, seaConsCols, portConsCols) => ([
    {
        values: [
            { value: t('VALUE'), rowSpan: 3, props: tableHeaderStyle },
            { value: t('AT_SEA'), rowSpan: 2, colSpan: 8, props: centeredTableHeaderStyle },
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            { value: t('CANAL'), rowSpan: 2, colSpan: 2, props: centeredTableHeaderStyle },
            null,
            { value: t('IN_PORT'), rowSpan: 2, colSpan: 5, props: centeredTableHeaderStyle },
            null,
            null,
            null,
            null,
            { value: t('CONSUMPTION_AT_SEA'), colSpan: seaConsCols + 6, props: centeredTableHeaderStyle },
            ...new Array(seaConsCols + 5).fill(null),
            {
                value: t('CONSUMPTION_IN_CANAL'),
                rowSpan: 2,
                colSpan: fuelTypes.canal.length + 2,
                props: centeredTableHeaderStyle
            },
            ...new Array(fuelTypes.canal.length + 1).fill(null),
            { value: t('CONSUMPTION_IN_PORT'), colSpan: portConsCols + 8, props: centeredTableHeaderStyle },
            ...new Array(portConsCols + 7).fill(null),
            { value: t('EMISSIONS'), rowSpan: 2, colSpan: 4, props: centeredTableHeaderStyle },
            null,
            null,
            null
        ]
    },
    {
        values: [
            ...new Array(16).fill(null),
            { value: t('BALLAST'), colSpan: fuelTypes.seaBallast.length + 2, props: centeredTableHeaderStyle },
            ...new Array(fuelTypes.seaBallast.length + 1).fill(null),
            { value: t('LADEN'), colSpan: fuelTypes.seaLaden.length + 2, props: centeredTableHeaderStyle },
            ...new Array(fuelTypes.seaLaden.length + 1).fill(null),
            { value: t('TOTALS'), colSpan: fuelTypes.sea.length + 2, props: centeredTableHeaderStyle },
            ...new Array(fuelTypes.sea.length + fuelTypes.canal.length + 3).fill(null),
            {
                value: t('ANCHORED_DRIFTING'),
                colSpan: fuelTypes.anchoredDrifting.length + 2,
                props: centeredTableHeaderStyle
            },
            ...new Array(fuelTypes.anchoredDrifting.length + 1).fill(null),
            {
                value: t('CARGO_LOAD_OPERATIONS'),
                colSpan: fuelTypes.cargoLoad.length + 2,
                props: centeredTableHeaderStyle
            },
            ...new Array(fuelTypes.cargoLoad.length + 1).fill(null),
            {
                value: t('CARGO_DISCHARGE_OPERATIONS'),
                colSpan: fuelTypes.cargoDischarge.length + 2,
                props: centeredTableHeaderStyle
            },
            ...new Array(fuelTypes.cargoDischarge.length + 1).fill(null),
            { value: t('TOTALS'), colSpan: fuelTypes.port.length + 2, props: centeredTableHeaderStyle },
            ...new Array(fuelTypes.port.length + 5).fill(null)
        ]
    },
    {
        values: [
            null,
            { value: t('TOTAL_CARGO_CARRIED'), props: rightAlignedTableHeaderStyle },
            { value: t('DAYS_LADEN'), props: rightAlignedTableHeaderStyle },
            { value: t('DISTANCE_LADEN'), props: rightAlignedTableHeaderStyle },
            { value: t('DISTANCE_LADEN_PERCENTAGE'), props: rightAlignedTableHeaderStyle },
            { value: t('DAYS_BALLAST'), props: rightAlignedTableHeaderStyle },
            { value: t('DISTANCE_BALLAST'), props: rightAlignedTableHeaderStyle },
            { value: t('DISTANCE_BALLAST_PERCENTAGE'), props: rightAlignedTableHeaderStyle },
            { value: t('DAYS_AT_SEA'), props: rightAlignedTableHeaderStyle },
            { value: t('DAYS_IN_CANAL'), props: rightAlignedTableHeaderStyle },
            { value: t('DAYS_IN_CANAL_PERCENTAGE'), props: rightAlignedTableHeaderStyle },
            { value: t('DAYS_ANCHORED_DRIFTING'), props: rightAlignedTableHeaderStyle },
            { value: t('DAYS_CARGO_LOAD'), props: rightAlignedTableHeaderStyle },
            { value: t('DAYS_CARGO_DISCHARGE'), props: rightAlignedTableHeaderStyle },
            { value: t('DAYS_IN_PORT'), props: rightAlignedTableHeaderStyle },
            { value: t('DAYS_IN_PORT_PERCENTAGE'), props: rightAlignedTableHeaderStyle },
            ...fuelDataSections.flatMap(section => renderFuelHeaderSection(section, fuelTypes)),
            ...emissionProps.map(emissionProp => ({
                value: `${emissionProp.label} (${translate('UNITS.EMISSIONS')})`,
                props: rightAlignedTableHeaderStyle
            }))
        ]
    }
]);

const renderSummary = (
    label,
    summary,
    fuelTypes,
    fuelBeforeColSpan,
    fuelAfterColSpan,
    emissionColSpan
) => ([
    {
        values: [
            { value: label, props: { ...tableCellStyle, ...summaryCellStyle } },
            ...renderDataProps(summary.Total, cargoProps, rightAlignedSummaryCellStyle),
            ...fuelDataSections.flatMap(section =>
                renderFuelDataSection(section, summary.Total, fuelTypes, rightAlignedSummaryCellStyle)),
            ...renderDataProps(summary.Total.Emissions, emissionProps, rightAlignedSummaryCellStyle)
        ]
    },
    {
        values: [
            { value: t('FUEL_PER_DISTANCE'), props: summaryCellStyle },
            { colSpan: fuelBeforeColSpan, props: rightAlignedSummaryCellStyle },
            ...new Array(fuelBeforeColSpan - 1).fill(null),
            formatTableCell(summary.FuelPerDistance, otherProps.FuelPerDistance, rightAlignedSummaryCellStyle),
            { colSpan: fuelAfterColSpan, props: rightAlignedSummaryCellStyle },
            ...new Array(fuelAfterColSpan - 1).fill(null)
        ]
    },
    {
        values: [
            { value: t('FUEL_PER_CARGO'), props: summaryCellStyle },
            { colSpan: fuelBeforeColSpan, props: rightAlignedSummaryCellStyle },
            ...new Array(fuelBeforeColSpan - 1).fill(null),
            formatTableCell(summary.FuelPerCargo, otherProps.FuelPerCargo, rightAlignedSummaryCellStyle),
            { colSpan: fuelAfterColSpan, props: rightAlignedSummaryCellStyle },
            ...new Array(fuelAfterColSpan - 1).fill(null)
        ]
    },
    {
        values: [
            { value: t('EMISSIONS_PER_CARGO'), props: summaryCellStyle },
            { colSpan: emissionColSpan, props: rightAlignedSummaryCellStyle },
            ...new Array(emissionColSpan - 1).fill(null),
            ...renderDataProps(summary.EmissionsPerCargo, emissionProps, rightAlignedSummaryCellStyle, true)
        ]
    }
]);

const renderVesselList = (
    label,
    vesselList,
    fuelTypes,
    headerColSpan,
    fuelBeforeColSpan,
    fuelAfterColSpan,
    emissionColSpan,
    style
) => ([
    {
        values: [
            { value: label, props: style, colSpan: headerColSpan + 1 },
            ...new Array(headerColSpan).fill(null)
        ],
        props: { height: 20 }
    },
    ...vesselList.Vessels.map(vessel => ({
        values: [
            { value: vessel.Name, props: tableCellStyle },
            ...renderDataProps(vessel, cargoProps),
            ...fuelDataSections.flatMap(section => renderFuelDataSection(section, vessel, fuelTypes)),
            ...renderDataProps(vessel.Emissions, emissionProps)
        ]
    })),
    ...renderSummary(
        `${t('TOTAL_FOR')} ${label}`,
        vesselList.Summary,
        fuelTypes,
        fuelBeforeColSpan,
        fuelAfterColSpan,
        emissionColSpan
    )
]);

const renderFleet = (
    fleet,
    fuelTypes,
    headerColSpan,
    fuelBeforeColSpan,
    fuelAfterColSpan,
    emissionColSpan
) => ([
    {
        values: [
            { value: fleet.Name, props: fleetHeaderStyle, colSpan: headerColSpan + 1 },
            ...new Array(headerColSpan).fill(null)
        ],
        props: { height: 20 }
    },
    ...renderSummary(
        t('TOTAL_FOR_COMPANY_FLEET'),
        fleet.Summary,
        fuelTypes,
        fuelBeforeColSpan,
        fuelAfterColSpan,
        emissionColSpan
    ),
    ...fleet.Contracts.reduce((res, contract) => res.concat(renderVesselList(
        contract.Name,
        contract,
        fuelTypes,
        headerColSpan,
        fuelBeforeColSpan,
        fuelAfterColSpan,
        emissionColSpan,
        contractHeaderStyle
    )), [])
]);

const renderSegment = (
    segment,
    fuelTypes,
    headerColSpan,
    fuelBeforeColSpan,
    fuelAfterColSpan,
    emissionColSpan
) => ([
    {
        values: [
            { value: segment.Name, props: segmentHeaderStyle, colSpan: headerColSpan + 1 },
            ...new Array(headerColSpan).fill(null)
        ],
        props: { height: 28 }
    },
    ...renderSummary(
        t('TOTAL_FOR_SEGMENT'),
        segment.Summary,
        fuelTypes,
        fuelBeforeColSpan,
        fuelAfterColSpan,
        emissionColSpan
    ),
    ...segment.Fleets.reduce((res, fleet) => res.concat(renderFleet(
        fleet,
        fuelTypes,
        headerColSpan,
        fuelBeforeColSpan,
        fuelAfterColSpan,
        emissionColSpan
    )), []),
    ...segment.OtherVessels
        ? renderVesselList(
            t('OTHER_VESSELS'),
            segment.OtherVessels,
            fuelTypes,
            headerColSpan,
            fuelBeforeColSpan,
            fuelAfterColSpan,
            emissionColSpan,
            fleetHeaderStyle
        )
        : []
]);

export default function* generateFleetPerformanceReportTypeBXLSX(data) {
    const { tableData, fileName, rangeStart, rangeEnd } = data;
    const { parsedData, fuelTypes } = tableData;
    const workbook = new Excel.Workbook();

    const cargoCols = cargoProps.length;

    const seaBallastCols = fuelTypes.seaBallast.length;
    const seaLadenCols = fuelTypes.seaLaden.length;
    const seaTotalCols = fuelTypes.sea.length;
    const seaConsCols = seaBallastCols + seaLadenCols + seaTotalCols;

    const canalConsCols = fuelTypes.canal.length;

    const portAnchoredCols = fuelTypes.anchoredDrifting.length;
    const portCargoLoadCols = fuelTypes.cargoLoad.length;
    const portCargoDischargeCols = fuelTypes.cargoDischarge.length;
    const portTotalCols = fuelTypes.port.length;
    const portConsCols = portAnchoredCols + portCargoLoadCols + portCargoDischargeCols + portTotalCols;

    const emissionCols = emissionProps.length;

    const fuelBeforeColSpan = cargoCols + seaConsCols + 5;
    const fuelAfterColSpan = canalConsCols + portConsCols + emissionCols + 10;
    const emissionColSpan = cargoCols + seaConsCols + canalConsCols + portConsCols + 16;
    const headerColSpan = cargoCols + seaConsCols + canalConsCols + portConsCols + 16 + emissionCols;

    const columns = [
        { width: colWidths.Value.xls },
        { width: colWidths.TotalCargoCarried.xls },
        { width: colWidths.DaysLaden.xls },
        { width: colWidths.DistanceLaden.xls },
        { width: colWidths.DistanceLadenPercentage.xls },
        { width: colWidths.DaysBallast.xls },
        { width: colWidths.DistanceBallast.xls },
        { width: colWidths.DistanceBallastPercentage.xls },
        { width: colWidths.DaysAtSea.xls },
        { width: colWidths.DaysInCanal.xls },
        { width: colWidths.DaysInCanalPercentage.xls },
        { width: colWidths.DaysAnchoredDrifting.xls },
        { width: colWidths.DaysCargoLoad.xls },
        { width: colWidths.DaysCargoDischarge.xls },
        { width: colWidths.DaysInPort.xls },
        { width: colWidths.DaysInPortPercentage.xls },
        ...new Array(seaBallastCols).fill({ width: fuelColWidth.xls }),
        { width: colWidths.AvgConsumptionAtSeaBallast.xls },
        { width: colWidths.TotalConsumptionAtSeaBallast.xls },
        ...new Array(seaLadenCols).fill({ width: fuelColWidth.xls }),
        { width: colWidths.AvgConsumptionAtSeaLaden.xls },
        { width: colWidths.TotalConsumptionAtSeaLaden.xls },
        ...new Array(seaConsCols).fill({ width: fuelColWidth.xls }),
        { width: colWidths.AvgConsumptionAtSea.xls },
        { width: colWidths.TotalConsumptionAtSea.xls },
        ...new Array(canalConsCols).fill({ width: fuelColWidth.xls }),
        { width: colWidths.AvgConsumptionInCanal.xls },
        { width: colWidths.TotalConsumptionInCanal.xls },
        ...new Array(portAnchoredCols).fill({ width: fuelColWidth.xls }),
        { width: colWidths.AvgConsumptionAnchoredDrifting.xls },
        { width: colWidths.TotalConsumptionAnchoredDrifting.xls },
        ...new Array(portCargoLoadCols).fill({ width: fuelColWidth.xls }),
        { width: colWidths.AvgConsumptionCargoLoad.xls },
        { width: colWidths.TotalConsumptionCargoLoad.xls },
        ...new Array(portCargoDischargeCols).fill({ width: fuelColWidth.xls }),
        { width: colWidths.AvgConsumptionCargoDischarge.xls },
        { width: colWidths.TotalConsumptionCargoDischarge.xls },
        ...new Array(portTotalCols).fill({ width: fuelColWidth.xls }),
        { width: colWidths.AvgConsumptionInPort.xls },
        { width: colWidths.TotalConsumptionInPort.xls },
        { width: colWidths.CO2.xls },
        { width: colWidths.SOx.xls },
        { width: colWidths.NOx.xls },
        { width: colWidths.PM.xls }
    ];

    const sheetConfig = {
        name: 'Fleet Performance Report',
        props: {
            properties: { showGridLines: false },
            views: [{ showGridLines: false }],
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                margins: { left: 0.4, right: 0.4, top: 0.2, bottom: 0.2, header: 0, footer: 0 }
            }
        },
        columns,
        rows: [
            ...generateXLSXHeaderLogos(null, null, 0.0001),
            {
                values: [{
                    value: tr('FLEET_PERFORMANCE_REPORT'),
                    props: headingTitleStyle
                }]
            },
            null,
            {
                values: [{
                    value: `${tr('PREVIEW.HEADER.DATE_RANGE')} ${rangeStart} - ${rangeEnd}`
                }]
            },
            null,
            ...getReportTableHeader(fuelTypes, seaConsCols, portConsCols),
            ...renderSummary(
                t('GRAND_TOTAL'),
                parsedData.Summary,
                fuelTypes,
                fuelBeforeColSpan,
                fuelAfterColSpan,
                emissionColSpan
            ),
            ...parsedData.Segments.reduce((res, segment) => res.concat(renderSegment(
                segment,
                fuelTypes,
                headerColSpan,
                fuelBeforeColSpan,
                fuelAfterColSpan,
                emissionColSpan
            )), [])
        ]
    };

    addWorksheet(workbook, sheetConfig);

    const xls64 = yield workbook.xlsx.writeBuffer({ base64: true });
    return triggerXLSXDownload(fileName)(xls64);
}
