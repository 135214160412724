import en from './en';

const languages = {
    en
};

class I18n {
    constructor() {
        this.language = 'en';
    }

    setLanguage = newLanguage => {
        this.language = newLanguage || 'en';
    };

    getString(property) {
        let string = languages[this.language];
        const stringPaths = property.split('.');
        stringPaths.forEach((path) => {
            string = string && string[path];
        });
        return string;
    }

    pluralizeRegexes = {
        fullMatch: /#{pluralize\[[\w\d]*]: ?\[[\w\d ,]*]}/g,
        singleMatch: /#{pluralize\[([\w\d]*)]: ?\[([\w\d ,]*)]}/
    };

    translate = (property, values, pluralize) => {
        let string = this.getString(property);
        if (values) {
            Object.keys(values).forEach((key) => {
                string = string.replace(`#{${key}}`, values[key]);
            });
        }
        if (pluralize) {
            const pluralizeMatches = string.match(this.pluralizeRegexes.fullMatch);
            pluralizeMatches.forEach((pluralizeMatch) => {
                const pluralizeParams = pluralizeMatch.match(this.pluralizeRegexes.singleMatch);
                const pluralizeValue = pluralizeParams[1];
                const pluralizeTexts = pluralizeParams[2].split(',');
                if (values[pluralizeValue] === 0 || values[pluralizeValue] > 1) {
                    string = string.replace(pluralizeMatch, pluralizeTexts[1]);
                } else {
                    string = string.replace(pluralizeMatch, pluralizeTexts[0]);
                }
            });
        }
        return string || `--S-T-R-I-N-G---N-O-T---L-O-C-A-L-I-Z-E-D--${property}`;
    };
}

const i18n = new I18n();
const { translate, setLanguage } = i18n;

export default i18n;
export { setLanguage, translate, translate as t };
