export const ActionTypes = {
    POSITION_LIST_COMPOSE_SET_VESSELS: 'POSITION_LIST_COMPOSE_SET_VESSELS',
    POSITION_LIST_COMPOSE_SET_EMAIL_VALUE: 'POSITION_LIST_COMPOSE_SET_EMAIL_VALUE',
    POSITION_LIST_COMPOSE_SEND_EMAIL: 'POSITION_LIST_COMPOSE_SEND_EMAIL',
    POSITION_LIST_COMPOSE_TOGGLE_MODAL: 'POSITION_LIST_COMPOSE_TOGGLE_MODAL',
    POSITION_LIST_COMPOSE_GET_RECENT_RECIPIENTS: 'POSITION_LIST_COMPOSE_GET_RECENT_RECIPIENTS',
    POSITION_LIST_COMPOSE_SET_RECENT_RECIPIENTS: 'POSITION_LIST_COMPOSE_SET_RECENT_RECIPIENTS',
    POSITION_LIST_COMPOSE_UPDATE_VESSEL_COLUMN: 'POSITION_LIST_COMPOSE_UPDATE_VESSEL_COLUMN',
    POSITION_LIST_COMPOSE_GET_UPDATED_VESSELS: 'POSITION_LIST_COMPOSE_GET_UPDATED_VESSELS',
    POSITION_LIST_COMPOSE_SET_UPDATED_VESSELS: 'POSITION_LIST_COMPOSE_SET_UPDATED_VESSELS',
    POSITION_LIST_COMPOSE_RESET_UPDATED_VESSELS: 'POSITION_LIST_COMPOSE_RESET_UPDATED_VESSELS',
    POSITION_LIST_COMPOSE_GET_SIGNATURE_COMPANIES: 'POSITION_LIST_COMPOSE_GET_SIGNATURE_COMPANIES',
    POSITION_LIST_COMPOSE_SET_SIGNATURE_COMPANIES: 'POSITION_LIST_COMPOSE_SET_SIGNATURE_COMPANIES',
    POSITION_LIST_COMPOSE_RESET_ALL: 'POSITION_LIST_COMPOSE_RESET_ALL'
};

export function getUpdatedVessels(dispatch) {
    dispatch({
        type: ActionTypes.POSITION_LIST_COMPOSE_GET_UPDATED_VESSELS
    });
}

export function updateVesselColumn(dispatch, imo, columnId, value) {
    dispatch({
        type: ActionTypes.POSITION_LIST_COMPOSE_UPDATE_VESSEL_COLUMN,
        imo,
        columnId,
        value
    });
}

export function setValue(dispatch, field, value) {
    dispatch({
        type: ActionTypes.POSITION_LIST_COMPOSE_SET_EMAIL_VALUE,
        field,
        value
    });
}

export function toggleModal(dispatch, flag) {
    dispatch({
        type: ActionTypes.POSITION_LIST_COMPOSE_TOGGLE_MODAL,
        flag
    });
}

export function sendPositionList(dispatch, content, attachments) {
    dispatch({
        type: ActionTypes.POSITION_LIST_COMPOSE_SEND_EMAIL,
        content,
        attachments
    });
}

export function getRecentRecipients(dispatch) {
    dispatch({
        type: ActionTypes.POSITION_LIST_COMPOSE_GET_RECENT_RECIPIENTS
    });
}

export function resetUpdatedVessels(dispatch, imo) {
    dispatch({
        type: ActionTypes.POSITION_LIST_COMPOSE_RESET_UPDATED_VESSELS,
        imo
    });
}

export function getSignatureCompanies(dispatch) {
    dispatch({
        type: ActionTypes.POSITION_LIST_COMPOSE_GET_SIGNATURE_COMPANIES
    });
}

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.POSITION_LIST_COMPOSE_RESET_ALL
    });
}
