import { ActionTypes } from './admin-vessel-management-form-actions';

const emptyArray = [];

const initialState = {
    isAddingManually: false,
    iceClassLevels: emptyArray,
    hadSearchedForVessels: false,
    foundVessels: emptyArray,
    commercialOperators: emptyArray,
    headOwners: emptyArray,
    technicalManagers: emptyArray,
    editingVessel: null,
    newCompany: null,
    validationErrors: null
};

export default function adminVesselManagementFormReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_FOUND_VESSELS: {
        return {
            ...state,
            foundVessels: action.foundVessels || emptyArray,
            hadSearchedForVessels: true
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_COMMERCIAL_OPERATORS: {
        return {
            ...state,
            commercialOperators: action.commercialOperators
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_ICE_CLASS_LEVELS: {
        return {
            ...state,
            iceClassLevels: action.IceClassLevels
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_HEAD_OWNERS: {
        return {
            ...state,
            headOwners: action.headOwners
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_TECHNICAL_MANAGERS: {
        return {
            ...state,
            technicalManagers: action.technicalManagers
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_TOGGLE_MANUAL_ADDING: {
        return {
            ...state,
            isAddingManually: action.isAddingManually
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_EDITING_VESSEL: {
        const { OperationsEmail, OwnerEmail, TechnicalManagementEmail, VesselEmail } = action.vessel;
        return {
            ...state,
            editingVessel: {
                ...action.vessel,
                OperationsEmail: OperationsEmail ? OperationsEmail.split(';') : [],
                OwnerEmail: OwnerEmail ? OwnerEmail.split(';') : [],
                TechnicalManagementEmail: TechnicalManagementEmail ? TechnicalManagementEmail.split(';') : [],
                VesselEmail: VesselEmail ? VesselEmail.split(';') : []
            }
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_VALIDATION_ERRORS: {
        return {
            ...state,
            validationErrors: action.validationErrors
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_NEW_COMPANY: {
        return {
            ...state,
            newCompany: {
                company: action.newCompany,
                roleName: action.roleName
            }
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_RESET_ALL: {
        return {
            ...initialState,
            commercialOperators: state.commercialOperators,
            headOwners: state.headOwners,
            technicalManagers: state.technicalManagers,
            iceClassLevels: state.iceClassLevels
        };
    }
    default:
        return state;
    }
}
