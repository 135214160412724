import { createSelector } from 'reselect';
import { swedishCompare } from 'utils/helpers/info-helper';

const emptyArray = [];

export const getTradingPorts = createSelector(
    state => state.marketIntelReducer.tradingRoutes,

    (tradingRoutes) => {
        const tradingPorts = [];
        tradingRoutes.forEach((tradingRoute) => {
            if (tradingPorts.findIndex(tradingPort => tradingPort.Id === tradingRoute.StartPort.Id)) {
                tradingPorts.push(tradingRoute.StartPort);
            }
            if (tradingPorts.findIndex(tradingPort => tradingPort.Id === tradingRoute.EndPort.Id)) {
                tradingPorts.push(tradingRoute.EndPort);
            }
        });
        return tradingPorts;
    }

);

export const getSelectedTradingRoutes = createSelector(
    state => state.marketIntelReducer.tradingRoutes,
    state => state.userReducer.settings,

    (tradingRoutes, settings) => {
        if (settings && settings.TradingRouteIds) {
            return tradingRoutes.filter(tradingRoute => settings.TradingRouteIds.indexOf(tradingRoute.Id) > -1);
        }
        return emptyArray;
    }
);

export const getSelectedSegments = createSelector(
    state => state.marketIntelReducer.options,
    state => state.userReducer.settings,

    (options, settings) => {
        if (options && options.vesselSegments && settings && settings.VesselDistributionSelectedSegments) {
            return options.vesselSegments.filter(segment =>
                settings.VesselDistributionSelectedSegments.indexOf(segment.VesselTypeId) > -1);
        }
        return emptyArray;
    }
);

export const getSelectedCargoStatuses = createSelector(
    state => state.marketIntelReducer.options,
    state => state.userReducer.settings,

    (options, settings) => {
        if (options && options.cargoStatuses && settings && settings.VesselDistributionCargoStatuses) {
            return options.cargoStatuses.filter(cargoStatus =>
                settings.VesselDistributionCargoStatuses.indexOf(cargoStatus.Id) > -1);
        }
        return emptyArray;
    }
);

const tradingRouteCodeRegex = /^(\w+)(\d+)$/;

export const getTradingRoutesSortedAlphabetically = createSelector(
    state => state.marketIntelReducer.tradingRoutes,

    (tradingRoutes) => {
        const tradingRoutesClone = tradingRoutes.slice(0);

        tradingRoutesClone.sort((a, b) => {
            const aParts = a.RouteCode.match(tradingRouteCodeRegex);
            const bParts = b.RouteCode.match(tradingRouteCodeRegex);

            const prefixComparison = swedishCompare(aParts[1], bParts[1]);

            if (prefixComparison !== 0) {
                return prefixComparison;
            }

            const aNumber = parseInt(aParts[2], 10);
            const bNumber = parseInt(bParts[2], 10);

            if (aNumber > bNumber) {
                return 1;
            }
            if (aNumber < bNumber) {
                return -1;
            }

            return 0;
        });

        return tradingRoutesClone;
    }
);
