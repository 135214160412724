import ApiHelper from './gsm-api';

class GsmTankerService {
    constructor() {
        this.resourceUrlSufix = 'Tankers';
    }

    getAll(params) {
        return ApiHelper.get(`${this.resourceUrlSufix}`, params)
            .then(response => response.data, () => null);
    }

    getById(id, params = {}) {
        return ApiHelper.get(`${id}/${this.resourceUrlSufix}`, params)
            .then(response => response.data, () => null);
    }
}

export default new GsmTankerService();
