import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import { getVesselTechnicalDetails } from '../../../shared/get-vessel-technical-details';
import TimeHelper from 'utils/helpers/time-helper';
/* components */
import Select from 'components/select/select';
import TextCrud from 'components/text-crud/text-crud';
import Input from 'components/input/input';
/* styles */
import './position-list-compose-table-row.scss';

const t = (key) => translate(`POSITION_LIST.${key}`);

export class PositionListComposeTableRow extends React.PureComponent {
    setFixtureStatus = (columnId, fixtureStatus) => {
        this.props.updateColumnField(this.props.vessel.IMO, columnId, fixtureStatus.FixtureStatusId);
    };

    clearDateValue = columnId => {
        this.props.updateColumnField(this.props.vessel.IMO, columnId, '');
    };

    updateNextOpen = (columnId, value) => {
        this.props.updateColumnField(this.props.vessel.IMO, columnId, value);
    };

    updateItinerary = (columnId, value) => {
        this.props.updateColumnField(this.props.vessel.IMO, columnId, value);
    };

    updateCargoes = (columnId, value) => {
        this.props.updateColumnField(this.props.vessel.IMO, columnId, value);
    };

    updateNote = (columnId, value) => {
        this.props.updateColumnField(this.props.vessel.IMO, columnId, value);
    };

    updateCoating = (columnId, value) => {
        this.props.updateColumnField(this.props.vessel.IMO, columnId, value);
    };

    render() {
        const vessel = this.props.vessel;
        const activeForm = this.props.activeForm;
        let rowClass = 'sten-position-list-table-row';
        if (vessel.IsVesselInUserScope) {
            rowClass += ' sten-position-list-table-row--primary';
        }
        const vesselTechnicalDetails = getVesselTechnicalDetails(
            vessel,
            this.props.selectedExportVesselInfoConfig,
            this.props.vesselInfoConfigurableProperties
        );

        return (
            <tr className={rowClass}>
                {this.props.tableColumns.map((column) => {
                    switch (column.ColumnId) {
                    case 1: {
                        return (
                            <td
                                key={column.ColumnId}
                                style={{ width: `${column.width}rem` }}
                            >
                                <div className="flex-row flex-center">
                                    <div className="col-24">
                                        <h3 className="text-uppercase">
                                            {vessel.VesselName}
                                        </h3>
                                        <p className="text-secondary">
                                            {vesselTechnicalDetails.join(', ')}
                                        </p>
                                        {vessel.Updated && (
                                            <div className="sten-position-list-table-row__update-info">
                                                <span className="text-secondary">
                                                    {t('COMPOSE.LAST_UPDATED_BY')}
                                                </span>
                                                <span>{vessel.Updated.lastModifiedUser}</span>
                                                <span className="text-secondary">
                                                    {t('COMPOSE.ON')}
                                                </span>
                                                <span>
                                                    {TimeHelper.getFormatted(vessel.Updated.lastModifiedTime,
                                                        { time: true })
                                                    }
                                                </span>
                                                <button
                                                    onClick={this.props.onRowReset.bind(this, vessel.IMO)}
                                                    className="btn btn--secondary btn--xs"
                                                >
                                                    {translate('GLOBAL.RESET')}
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </td>
                        );
                    }
                    case 9: {
                        const isHighlighted = vessel.Updated
                            && vessel.Updated.highlightedFields.includes(column.ColumnId);
                        return (
                            <td
                                key={column.ColumnId}
                                style={{ width: `${column.width}rem` }}
                                className={isHighlighted ? 'sten-position-list-compose-table-cell--highlighted' : ''}
                            >
                                <Select
                                    valueKey="FixtureStatusId"
                                    labelKey="FixtureStatusDisplayName"
                                    options={this.props.fixtureStatuses}
                                    value={vessel.FixtureStatus}
                                    onChange={this.setFixtureStatus.bind(this, column.ColumnId)}
                                />
                            </td>
                        );
                    }
                    case 2: {
                        const isHighlighted = vessel.Updated
                            && vessel.Updated.highlightedFields.includes(column.ColumnId);
                        return (
                            <td
                                className={isHighlighted ? 'sten-position-list-compose-table-cell--highlighted' : ''}
                                key={column.ColumnId}
                                style={{ width: `${column.width}rem` }}
                            >
                                <Input
                                    readOnly
                                    clearable
                                    value={vessel.OpenDate
                                        ? TimeHelper.getFormatted(vessel.OpenDate, { utc: true })
                                        : ''}
                                    onClick={this.props.setActiveForm.bind(
                                        this, vessel.IMO,
                                        'OpenDate',
                                        column.ColumnId
                                    )}
                                    onChange={this.clearDateValue.bind(this, column.ColumnId)}
                                />
                            </td>
                        );
                    }
                    case 3: {
                        const isHighlighted = vessel.Updated
                            && vessel.Updated.highlightedFields.includes(column.ColumnId);
                        let cellClassName = 'sten-table__cell--no-padding';
                        if (isHighlighted) {
                            cellClassName += ' sten-position-list-compose-table-cell--highlighted';
                        }
                        return (
                            <td
                                key={column.ColumnId}
                                className={cellClassName}
                                style={{ width: `${column.width}rem` }}
                            >
                                <TextCrud
                                    isHighlighted
                                    isActive={vessel.IMO === activeForm.imo && activeForm.name === 'NextOpen'}
                                    onActivate={this.props.setActiveForm.bind(this, vessel.IMO, 'NextOpen')}
                                    onDeactivate={this.props.setActiveForm.bind(this, null)}
                                    onUpdate={this.updateNextOpen.bind(this, column.ColumnId)}
                                    value={vessel.NextOpen}
                                />
                            </td>
                        );
                    }
                    case 4: {
                        const isHighlighted = vessel.Updated
                            && vessel.Updated.highlightedFields.includes(column.ColumnId);
                        return (
                            <td
                                key={column.ColumnId}
                                style={{ width: `${column.width}rem` }}
                                className={isHighlighted ? 'sten-position-list-compose-table-cell--highlighted' : ''}
                            >
                                <Input
                                    readOnly
                                    clearable
                                    value={vessel.EtaDate ? TimeHelper.getFormatted(vessel.EtaDate, { utc: true }) : ''}
                                    onClick={this.props.setActiveForm.bind(
                                        this, vessel.IMO,
                                        'EtaDate',
                                        column.ColumnId
                                    )}
                                    onChange={this.clearDateValue.bind(this, column.ColumnId)}
                                />
                            </td>
                        );
                    }
                    case 10: {
                        const isHighlighted = vessel.Updated
                            && vessel.Updated.highlightedFields.includes(column.ColumnId);
                        let cellClassName = 'sten-table__cell--no-padding';
                        if (isHighlighted) {
                            cellClassName += ' sten-position-list-compose-table-cell--highlighted';
                        }
                        return (
                            <td
                                key={column.ColumnId}
                                className={cellClassName}
                                style={{ width: `${column.width}rem` }}
                            >
                                <TextCrud
                                    isHighlighted
                                    isActive={vessel.IMO === activeForm.imo && activeForm.name === 'Coating'}
                                    onActivate={this.props.setActiveForm.bind(this, vessel.IMO, 'Coating')}
                                    onDeactivate={this.props.setActiveForm.bind(this, null)}
                                    onUpdate={this.updateCoating.bind(this, column.ColumnId)}
                                    value={vessel.Coating}
                                />
                            </td>
                        );
                    }
                    case 5: {
                        const isHighlighted = vessel.Updated
                            && vessel.Updated.highlightedFields.includes(column.ColumnId);
                        let cellClassName = 'sten-table__cell--no-padding';
                        if (isHighlighted) {
                            cellClassName += ' sten-position-list-compose-table-cell--highlighted';
                        }
                        return (
                            <td
                                key={column.ColumnId}
                                className={cellClassName}
                                style={{ width: `${column.width}rem` }}
                            >
                                <TextCrud
                                    isActive={vessel.IMO === activeForm.imo && activeForm.name === 'RemainingItinerary'}
                                    onActivate={this.props.setActiveForm.bind(this, vessel.IMO, 'RemainingItinerary')}
                                    onDeactivate={this.props.setActiveForm.bind(this, null)}
                                    onUpdate={this.updateItinerary.bind(this, column.ColumnId)}
                                    value={vessel.RemainingItinerary}
                                    type="textarea"
                                >
                                    <ul className="sten-ul">
                                        {vessel.RemainingItinerary.split(/\n/).map(
                                            (row, itineraryIndex) =>
                                                // eslint-disable-next-line react/no-array-index-key
                                                <li key={itineraryIndex}>{row}</li>
                                        )}
                                    </ul>
                                </TextCrud>
                            </td>
                        );
                    }
                    case 6: {
                        const isHighlighted = vessel.Updated
                            && vessel.Updated.highlightedFields.includes(column.ColumnId);
                        return (
                            <td
                                key={column.ColumnId}
                                style={{ width: `${column.width}rem` }}
                                className={isHighlighted ? 'sten-position-list-compose-table-cell--highlighted' : ''}
                            >
                                <Input
                                    readOnly
                                    clearable
                                    value={vessel.LastSireDate
                                        ? TimeHelper.getFormatted(vessel.LastSireDate, { utc: true })
                                        : ''}
                                    onClick={this.props.setActiveForm.bind(
                                        this, vessel.IMO,
                                        'LastSireDate',
                                        column.ColumnId
                                    )}
                                    onChange={this.clearDateValue.bind(this, column.ColumnId)}
                                />
                            </td>
                        );
                    }
                    case 7: {
                        const isHighlighted = vessel.Updated
                            && vessel.Updated.highlightedFields.includes(column.ColumnId);
                        let cellClassName = 'sten-table__cell--no-padding';
                        if (isHighlighted) {
                            cellClassName += ' sten-position-list-compose-table-cell--highlighted';
                        }
                        return (
                            <td
                                key={column.ColumnId}
                                className={cellClassName}
                                style={{ width: `${column.width}rem` }}
                            >
                                <TextCrud
                                    isActive={vessel.IMO === activeForm.imo && activeForm.name === 'LastCargoes'}
                                    onActivate={this.props.setActiveForm.bind(this, vessel.IMO, 'LastCargoes')}
                                    onDeactivate={this.props.setActiveForm.bind(this, null)}
                                    onUpdate={this.updateCargoes.bind(this, column.ColumnId)}
                                    value={vessel.LastCargoes}
                                    type="textarea"
                                >
                                    <ul className="sten-ul">
                                        {vessel.LastCargoes.split(/\n/).map(
                                            (row, cargoIndex) =>
                                                // eslint-disable-next-line react/no-array-index-key
                                                <li key={cargoIndex}>{row}</li>
                                        )}
                                    </ul>
                                </TextCrud>
                            </td>
                        );
                    }
                    case 8: {
                        const isHighlighted = vessel.Updated
                            && vessel.Updated.highlightedFields.includes(column.ColumnId);
                        let cellClassName = 'sten-table__cell--no-padding';
                        if (isHighlighted) {
                            cellClassName += ' sten-position-list-compose-table-cell--highlighted';
                        }
                        return (
                            <td
                                key={column.ColumnId}
                                className={cellClassName}
                                style={{ width: `${column.width}rem` }}
                            >
                                <TextCrud
                                    isActive={vessel.IMO === activeForm.imo && activeForm.name === 'Notes'}
                                    onActivate={this.props.setActiveForm.bind(this, vessel.IMO, 'Notes')}
                                    onDeactivate={this.props.setActiveForm.bind(this, null)}
                                    onUpdate={this.updateNote.bind(this, column.ColumnId)}
                                    value={vessel.Note}
                                    type="textarea"
                                />
                            </td>
                        );
                    }
                    case 12: {
                        const isHighlighted = vessel.Updated
                            && vessel.Updated.highlightedFields.includes(column.ColumnId);
                        return (
                            <td
                                key={column.ColumnId}
                                style={{ width: `${column.width}rem` }}
                                className={isHighlighted ? 'sten-position-list-compose-table-cell--highlighted' : ''}
                            >
                                <Input
                                    readOnly
                                    clearable
                                    value={vessel.LoadReadyDate
                                        ? TimeHelper.getFormatted(vessel.LoadReadyDate, { utc: true })
                                        : ''}
                                    onClick={this.props.setActiveForm.bind(
                                        this, vessel.IMO,
                                        'LoadReadyDate',
                                        column.ColumnId
                                    )}
                                    onChange={this.clearDateValue.bind(this, column.ColumnId)}
                                />
                            </td>
                        );
                    }
                    default:
                        return null;
                    }
                })}
            </tr>
        );
    }
}

PositionListComposeTableRow.propTypes = {
    activeForm: PropTypes.objectOf(PropTypes.any).isRequired,
    fixtureStatuses: PropTypes.arrayOf(PropTypes.object).isRequired,
    onRowReset: PropTypes.func.isRequired,
    selectedExportVesselInfoConfig: PropTypes.arrayOf(PropTypes.any).isRequired,
    setActiveForm: PropTypes.func.isRequired,
    tableColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateColumnField: PropTypes.func.isRequired,
    vessel: PropTypes.objectOf(PropTypes.any).isRequired,
    vesselInfoConfigurableProperties: PropTypes.objectOf(PropTypes.any).isRequired
};

export default PositionListComposeTableRow;
