import React from 'react';
import PropTypes from 'prop-types';
import ScrollArea from 'components/scroll-area/scroll-area';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import ModalDefault from './modal-default';

const ModalConfirm = props => (
    <ModalDefault
        className="sten-modal--with-footer"
        modalSize="xs"
        isOpen={props.isOpen}
        onRequestClose={props.onCancel}
    >
        <ScrollArea className="sten-modal__body flex flex-center">
            {props.title && (
                <div className="sten-modal__header flex flex-center text-uppercase">
                    <div className="sten-modal__title flex-grow">
                        {props.title}
                    </div>
                    <div className="flex-shrink">
                        <button
                            type="button"
                            className="btn-link icon icon-close"
                            onClick={props.onCancel}
                        />
                    </div>
                </div>
            )}
            <div className="sten-content__section">
                {props.children}
            </div>
        </ScrollArea>
        <footer className="sten-modal__footer">
            <div className="flex-grow flex-row flex-center">
                <div className="flex-grow text-right">
                    <button onClick={props.onCancel} className="btn btn--secondary">
                        {translate('GLOBAL.CANCEL')}
                    </button>
                </div>
                <div className="flex-shrink">
                    <button onClick={props.onConfirm} className="btn btn--primary">
                        {props.confirmationButtonText ? props.confirmationButtonText : translate('GLOBAL.CONFIRM')}
                    </button>
                </div>
            </div>
        </footer>
    </ModalDefault>
);

ModalConfirm.propTypes = {
    children: PropTypes.node,
    confirmationButtonText: PropTypes.string,
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func,
    title: PropTypes.string
};

ModalConfirm.defaultProps = {
    children: null,
    confirmationButtonText: null,
    isOpen: false,
    onConfirm: () => {},
    title: null
};

export default ModalConfirm;
