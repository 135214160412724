export const ActionTypes = {
    VESSEL_DISTRIBUTION_AREAS_SET_POSITIONS: 'VESSEL_DISTRIBUTION_AREAS_SET_POSITIONS'
};

export function setVesselDistributionAreaPositions(dispatch, positions) {
    dispatch({
        type: ActionTypes.VESSEL_DISTRIBUTION_AREAS_SET_POSITIONS,
        positions
    });
}
