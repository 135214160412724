import React from 'react';
import PropTypes from 'prop-types';
/* helpers */
import { isBetween } from 'utils/helpers/info-helper';
/* styles */
import './progress-circle.scss';

class ProgressCircle extends React.PureComponent {
    getColorClass(value) {
        if (isBetween(value, 0, 20)) {
            return 'sten-progress-circle__container--0-20';
        }
        if (isBetween(value, 20, 40)) {
            return 'sten-progress-circle__container--20-40';
        }
        if (isBetween(value, 40, 60)) {
            return 'sten-progress-circle__container--40-60';
        }
        if (isBetween(value, 60, 80)) {
            return 'sten-progress-circle__container--60-80';
        }
        return 'sten-progress-circle__container--80-100';
    }

    render() {
        const progressCircleMovement = {
            transform: `rotate(${this.props.value * 3.6}deg)`
        };
        let classNames = 'sten-progress-circle';
        if (this.props.className) {
            classNames += ` ${this.props.className}`;
        }
        let containerClassNames = `sten-progress-circle__container ${this.getColorClass(this.props.value)}`;
        if (this.props.value > 50) {
            containerClassNames += ' sten-progress-circle__container--fifty-plus';
        }
        let innerClassName = 'sten-progress-circle__inner';
        if (this.props.innerClassName) {
            innerClassName += ` ${this.props.innerClassName}`;
        }
        return (
            <div className={classNames}>
                <div className={containerClassNames}>
                    <div className={innerClassName}>{this.props.children}</div>
                    <div
                        className="sten-progress-circle__movement"
                        style={progressCircleMovement}
                    />
                </div>
            </div>
        );
    }
}

ProgressCircle.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    innerClassName: PropTypes.string,
    value: PropTypes.number.isRequired
};

ProgressCircle.defaultProps = {
    children: null,
    className: '',
    innerClassName: ''
};

export default ProgressCircle;
