import { ActionTypes } from './admin-company-management-actions';

const emptyArray = [];

const initialState = {
    companies: emptyArray,
    platformStatistics: null
};

export default function adminCompanyManagementReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ADMIN_COMPANY_MANAGEMENT_COMPANIES_SET: {
        return { ...state, companies: action.companies };
    }
    case ActionTypes.ADMIN_COMPANY_MANAGEMENT_PLATFORM_STATISTICS_SET: {
        return { ...state, platformStatistics: action.platformStatistics };
    }
    default:
        return state;
    }
}
