import CoreApi from './core-api';

class PoolService {
    constructor() {
        this.resourceUrl = 'Pool';
    }

    getVoyageOptions(params) {
        return CoreApi.get(`${this.resourceUrl}/DashboardOptions`, params)
            .then(response => response.data, () => null);
    }

    getFleets(params) {
        return CoreApi.get(`${this.resourceUrl}/Fleets`, params)
            .then(response => response.data, () => null);
    }

    getVessels(params) {
        return CoreApi.get(`${this.resourceUrl}/Vessels`, params)
            .then(response => response.data, () => null);
    }

    getVesselPositions(params) {
        return CoreApi.post(`${this.resourceUrl}/Vessels/Historical`, params)
            .then(response => response.data, () => null);
    }

    getEarnings(params) {
        return CoreApi.get(`${this.resourceUrl}/Earnings`, params)
            .then(response => response.data, () => null);
    }

    getMarketPrediction(params) {
        return CoreApi.get(`${this.resourceUrl}/MarketPrediction`, params)
            .then(response => response.data, () => null);
    }
}

export default new PoolService();
