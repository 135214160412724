import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import TimeHelper from 'utils/helpers/time-helper';
import { formatNumber, getClassName } from 'utils/helpers/info-helper';
import { formatEuEtsPrice, formatEuEtsTooltip } from 'components/euets/euets-helpers';
/* services */
import ConfigService from 'services/config-api/config-service';
import EuetsService from 'services/mt-api/euets-service';
/* components */
import CiiRating from 'components/cii-rating/cii-rating';
import ShowMoreButton from 'components/show-more-button/show-more-button';
import Modal from 'components/modal/modal';

const severityClassMap = {
    Alert: 'text-danger',
    Warning: 'text-warning'
};
const DISTANCE = t('UNITS.DISTANCE');
const CONSUMPTION = t('UNITS.CONSUMPTION');
const VALUE_CANT_BE_CALCULATED_TOOLTIP = t('FOOTPRINT_TABLE.VALUE_CANT_BE_CALCULATED_TOOLTIP');
export class EstimatorSummary extends React.PureComponent {
    state = {
        isModalShown: false
    };

    toggleModal = () => this.setState({ isModalShown: !this.state.isModalShown });

    closeModal = () => this.setState({ isModalShown: false });

    getTime = (value) => {
        const { isDifference } = this.props;
        const timeClass = getClassName({
            'text-success': isDifference && value < -1000,
            'text-danger': isDifference && value > 1000
        });
        const time = value && (value < -1000 || value > 1000)
            ? TimeHelper.getStringFromDuration(value, ['d', 'h'], isDifference)
            : '-';
        return <span className={timeClass}>{time}</span>;
    };

    formatEmissionSummary = (val, dec, diff, unit) => {
        if (typeof val === 'number') {
            const num = formatNumber(val, dec, diff);
            return <span className={diff ? 'text-danger' : ''}>{`${num} ${unit}`}</span>;
        }
        return '-';
    }

    renderConsumption = (summaryData, isDifference) => {
        if (typeof summaryData.Consumption === 'number') {
            const severityClass = summaryData.ConsumptionSeverity
                ? severityClassMap[summaryData.ConsumptionSeverity]
                : '';
            const value = `${formatNumber(summaryData.Consumption, 1, isDifference)} ${CONSUMPTION}`;
            return severityClass ? (
                <span title={t('EMISSION_ESTIMATOR.SUMMARY.CONSUMPTION_TOOLTIP')} className={severityClass}>
                    {value}
                </span>
            ) : value;
        }
        return '';
    };

    renderRow = (value, isDifference, unit = DISTANCE) => (
        <div className="sten-estimator-summary__row">
            {typeof value === 'number'
                ? `${formatNumber(value, 1, isDifference)} ${unit}`
                : '-'}
        </div>
    );

    renderModal = (data) => (
        <Modal.Default modalSize="auto" isOpen={this.state.isModalShown} onRequestClose={this.closeModal}>
            <div className="sten-modal__header flex flex-center text-uppercase">
                <div className="sten-modal__title flex-grow">{t('FOOTPRINT_TABLE.EU_ETS_CHART')}</div>
                <div className="flex-shrink">
                    <button type="button" className="btn-link icon icon-close" onClick={this.closeModal} />
                </div>
            </div>
            <div className="sten-modal__body text-center">
                <img src={EuetsService.getEtsChartLink(data?.EuEtsPrice?.currency)} alt="modal-img" />
            </div>
        </Modal.Default>
    );

    render() {
        const { isDifference, data } = this.props;
        let summaryData = {};
        if (data) {
            summaryData = isDifference ? data.DeviationSummary : data.CalculationValues;
        }
        const lastPointETA = this.props.lastPointETA
            ? TimeHelper.getFormatted(this.props.lastPointETA, { utc: true, time: true })
            : '-';
        const euEtsData = {
            euEtsPrice: data?.EuEtsPrice,
            voyageEuEts: data?.EuEts
        };
        const euEtsTooltip = formatEuEtsTooltip(euEtsData);

        return (
            <tfoot className="sten-estimator-summary">
                <tr className={this.props.toggleCollapsed ? 'cursor-pointer' : ''} onClick={this.props.toggleCollapsed}>
                    <th colSpan="3">
                        <div className="flex-row flex-center">
                            <div className={`flex-shrink${!this.props.toggleCollapsed ? ' text-secondary' : ''}`}>
                                {t('EMISSION_ESTIMATOR.SUMMARY.TITLE')}
                            </div>
                            <div className="flex-grow">
                                {this.props.toggleCollapsed && (
                                    <ShowMoreButton isMoreShown={!this.props.isCollapsed} invertArrow />
                                )}
                            </div>
                            <div className="flex-shrink text-secondary">
                                {isDifference
                                    ? `${t('EMISSION_ESTIMATOR.SUMMARY.DIFFERENCE')}:`
                                    : `${t('EMISSION_ESTIMATOR.SUMMARY.TOTAL')}:`}
                            </div>
                        </div>
                    </th>
                    <th>{this.getTime(summaryData.SteamingTime)}</th>
                    <th>
                        {typeof summaryData.Distance === 'number'
                            ? `${formatNumber(summaryData.Distance, 1, isDifference)} ${DISTANCE}`
                            : '-'
                        }
                    </th>
                    <th>-</th>
                    {this.props.showConsumption && (
                        <th>{this.renderConsumption(summaryData, isDifference)}</th>
                    )}
                    <th>{this.getTime(summaryData.WaitingTime)}</th>
                    <th>{this.getTime(summaryData.TimeInPort)}</th>
                    <th colSpan="2">
                        <span className="text-secondary">
                            {`${t('EMISSION_ESTIMATOR.SUMMARY.ETA')} ${this.props.lastPointName
                                ? `${this.props.lastPointName}: `
                                : '-'}`}
                        </span>
                        {this.props.lastPointName ? lastPointETA : ''}
                    </th>
                </tr>
                {!this.props.isCollapsed && (
                    <tr>
                        <td colSpan="3">
                            {this.props.data?.BunkerROBDate && (
                                <h4 className="sten-estimator-summary__bunker-rob-title">
                                    {`${t('EMISSION_ESTIMATOR.SUMMARY.BUNKER_ROB')} - ${TimeHelper.getFormatted(
                                        this.props.data.BunkerROBDate, { utc: true }
                                    )}`}
                                </h4>
                            )}
                            <div className="flex">
                                <div className="flex-grow">
                                    {this.props.data?.BunkerROB && (
                                        <div className="sten-estimator-summary__bunker-rob row">
                                            {this.props.data.BunkerROB.map((fuelType) => (
                                                <div key={fuelType.Id} className="sten-estimator-summary__row col-12">
                                                    <span className="text-secondary">{`${fuelType.Name}: `}</span>
                                                    {`${formatNumber(fuelType.Quantity, 0)} ${t('UNITS.ROB')}`}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="flex-shrink flex-col flex-justify-end">
                                    <div className="sten-estimator-summary__row text-right text-secondary">
                                        {`${t('EMISSION_ESTIMATOR.SUMMARY.ECA')}:`}
                                    </div>
                                    <div className="sten-estimator-summary__row text-right text-secondary">
                                        {`${t('EMISSION_ESTIMATOR.SUMMARY.NON_ECA')}:`}
                                    </div>
                                    <div className="sten-estimator-summary__row text-right text-secondary">
                                        {`${t('EMISSION_ESTIMATOR.SUMMARY.AVERAGE_PER_DAY')}:`}
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="sten-estimator-summary__row">-</div>
                            <div className="sten-estimator-summary__row">-</div>
                            <div className="sten-estimator-summary__row">-</div>
                        </td>
                        <td>
                            {this.renderRow(summaryData.SecaDistance, isDifference)}
                            {this.renderRow(summaryData.NonSecaDistance, isDifference)}
                            {this.renderRow(summaryData.AverageDistance, isDifference)}
                        </td>
                        <td>
                            <div className="sten-estimator-summary__row">-</div>
                            <div className="sten-estimator-summary__row">-</div>
                            <div className="sten-estimator-summary__row">-</div>
                        </td>
                        {this.props.showConsumption && (
                            <td>
                                {this.renderRow(summaryData.ConsumptionLsgo, isDifference, CONSUMPTION)}
                                {this.renderRow(summaryData.ConsumptionHsfo, isDifference, CONSUMPTION)}
                                {this.renderRow(summaryData.AverageConsumption, isDifference, CONSUMPTION)}
                            </td>
                        )}
                        <td>
                            <div className="sten-estimator-summary__row">-</div>
                            <div className="sten-estimator-summary__row">-</div>
                            <div className="sten-estimator-summary__row">-</div>
                        </td>
                        <td>
                            <div className="sten-estimator-summary__row">-</div>
                            <div className="sten-estimator-summary__row">-</div>
                            <div className="sten-estimator-summary__row">-</div>
                        </td>
                        <td colSpan="2" className="sten-estimator-summary__total-voyage-time">
                            <div className="flex">
                                <div className="flex-grow">
                                    <div className="sten-estimator-summary__row">
                                        <span className="text-secondary">
                                            {`${t('EMISSION_ESTIMATOR.SUMMARY.VOYAGE_TIME')}: `}
                                        </span>
                                        {this.getTime(summaryData.Time)}
                                    </div>
                                    {ConfigService.featureToggles.showEuEts && (
                                        <React.Fragment>
                                            {this.renderModal(data)}
                                            <div className="sten-estimator-summary__row">
                                                <span className="text-secondary">
                                                    {`${t('FOOTPRINT_TABLE.EU_ETS_PRICE')}: `}
                                                </span>
                                                {this.formatEmissionSummary(
                                                    formatEuEtsPrice(euEtsData, false),
                                                    1,
                                                    false,
                                                    data?.EuEtsPrice?.currency
                                                )}
                                                {euEtsTooltip && (
                                                    <span
                                                        className="icon sten-icon-info-tooltip"
                                                        title={euEtsTooltip}
                                                    />
                                                )}
                                            </div>
                                            <div className="sten-estimator-summary__row">
                                                <span className="text-secondary">
                                                    {`${t('FOOTPRINT_TABLE.CO2_PRICE')}: `}
                                                </span>
                                                {data?.EuEtsPrice
                                                    ? `${formatNumber(data.EuEtsPrice.price, 2)}`
                                                        + ` ${data.EuEtsPrice.currency}`
                                                        + ` (${TimeHelper.getFormatted(data.EuEtsPrice.date)})`
                                                    : '-'}
                                                <button
                                                    type="button"
                                                    className="btn-link icon sten-icon-info-tooltip"
                                                    title={t('FOOTPRINT_TABLE.SHOW_EU_ETS_CHART')}
                                                    onClick={this.toggleModal}
                                                />
                                            </div>
                                        </React.Fragment>
                                    )}
                                    <div className="sten-estimator-summary__row">
                                        <span className="text-secondary">
                                            {`${t('EMISSION_ESTIMATOR.SUMMARY.CII_GRADE')}: `}
                                        </span>
                                        <CiiRating
                                            isEstimated
                                            value={data?.CalculationValues.CarbonIntensityIndicatorGrade}
                                        />
                                        <span
                                            className="icon sten-icon-info-tooltip"
                                            title={data?.CalculationValues.CarbonIntensityIndicatorGrade
                                                ? t('EMISSION_ESTIMATOR.SUMMARY.CII_RATING_TOOLTIP')
                                                : VALUE_CANT_BE_CALCULATED_TOOLTIP}
                                        />
                                    </div>
                                    <div className="sten-estimator-summary__row">
                                        <span className="text-secondary">
                                            {`${t('EMISSION_ESTIMATOR.SUMMARY.AER')}: `}
                                        </span>
                                        {this.formatEmissionSummary(
                                            data?.CalculationValues?.Aer,
                                            2,
                                            false,
                                            t('UNITS.ENERGY_EFFICIENCY')
                                        )}
                                        <span
                                            className="icon sten-icon-info-tooltip"
                                            title={data?.CalculationValues?.Aer
                                                ? t('COMMON.AER_INFO_TOOLTIP')
                                                : VALUE_CANT_BE_CALCULATED_TOOLTIP}
                                        />
                                    </div>
                                </div>
                                {this.props.showConsumption && (
                                    <div className="flex-grow">
                                        {this.props.showConsumption && (
                                            <div className="sten-estimator-summary__row">
                                                <span className="text-secondary">
                                                    {`${t('EMISSION_ESTIMATOR.SUMMARY.CO2')}: `}
                                                </span>
                                                {this.formatEmissionSummary(
                                                    data?.CalculationValues?.Emissions?.CO2 || null,
                                                    2,
                                                    false,
                                                    CONSUMPTION
                                                )}
                                            </div>
                                        )}
                                        <div className="sten-estimator-summary__row">
                                            <span className="text-secondary">
                                                {`${t('EMISSION_ESTIMATOR.SUMMARY.CH4')}: `}
                                            </span>
                                            {this.formatEmissionSummary(
                                                data?.CalculationValues?.Emissions?.CH4 || null,
                                                2,
                                                false,
                                                t('UNITS.EMISSIONS_KG')
                                            )}
                                        </div>
                                        <div className="sten-estimator-summary__row">
                                            <span className="text-secondary">
                                                {`${t('EMISSION_ESTIMATOR.SUMMARY.CO')}: `}
                                            </span>
                                            {this.formatEmissionSummary(
                                                data?.CalculationValues?.Emissions?.CO || null,
                                                2,
                                                false,
                                                t('UNITS.EMISSIONS_KG')
                                            )}
                                        </div>
                                        <div className="sten-estimator-summary__row">
                                            <span className="text-secondary">
                                                {`${t('EMISSION_ESTIMATOR.SUMMARY.N2O')}: `}
                                            </span>
                                            {this.formatEmissionSummary(
                                                data?.CalculationValues?.Emissions?.N2O || null,
                                                2,
                                                false,
                                                t('UNITS.EMISSIONS_KG')
                                            )}
                                        </div>
                                        <div className="sten-estimator-summary__row">
                                            <span className="text-secondary">
                                                {`${t('EMISSION_ESTIMATOR.SUMMARY.NMVOC')}: `}
                                            </span>
                                            {this.formatEmissionSummary(
                                                data?.CalculationValues?.Emissions?.NMVOC || null,
                                                2,
                                                false,
                                                t('UNITS.EMISSIONS_KG')
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </td>
                    </tr>
                )}
            </tfoot>
        );
    }
}

EstimatorSummary.propTypes = {
    data: PropTypes.objectOf(PropTypes.any),
    isCollapsed: PropTypes.bool,
    isDifference: PropTypes.bool,
    lastPointName: PropTypes.string,
    lastPointETA: PropTypes.string,
    showConsumption: PropTypes.bool,
    toggleCollapsed: PropTypes.func
};

EstimatorSummary.defaultProps = {
    data: null,
    isCollapsed: false,
    isDifference: false,
    lastPointName: '',
    lastPointETA: '',
    showConsumption: false,
    toggleCollapsed: undefined
};

export default EstimatorSummary;
