import PropTypes from 'prop-types';

const TEmission = PropTypes.shape({
    CH4: PropTypes.number,
    CO: PropTypes.number,
    CO2: PropTypes.number,
    Co2Emission: PropTypes.number,
    N2O: PropTypes.number,
    NMVOC: PropTypes.number,
    CarbonIntensity: PropTypes.number
});

export const TEmissions = PropTypes.shape({
    AER: PropTypes.number,
    AvailableFuelTypes: PropTypes.arrayOf(
        PropTypes.shape({
            Id: PropTypes.number,
            Name: PropTypes.string,
            Sequence: PropTypes.number
        })
    ),
    AverageEmissions: TEmission.isRequired,
    Consumptions: PropTypes.arrayOf(
        PropTypes.shape({
            Id: PropTypes.number,
            Name: PropTypes.string,
            Sequence: PropTypes.number,
            Quantity: PropTypes.number
        })
    ),
    EEOI: PropTypes.number,
    Footprint: TEmission.isRequired,
    IsOnGoingVoyage: PropTypes.bool,
    SuspiciousFields: PropTypes.objectOf(PropTypes.any),
    TotalEmissions: TEmission.isRequired
});

export const TCIIRating = PropTypes.oneOf(['A', 'B', 'C', 'D', 'E']);

export const TFootprint = PropTypes.shape({
    SuspiciousFields: PropTypes.objectOf(PropTypes.any),
    AvailableFuelTypes: PropTypes.arrayOf(
        PropTypes.shape({
            Id: PropTypes.number,
            Name: PropTypes.string,
            Sequence: PropTypes.number
        })
    ),
    Consumptions: PropTypes.arrayOf(
        PropTypes.shape({
            Id: PropTypes.number,
            Name: PropTypes.string,
            Sequence: PropTypes.number,
            Quantity: PropTypes.number
        })
    ),
    EEOI: PropTypes.number,
    AER: PropTypes.number,
    AERWithCorrections: PropTypes.number,
    AverageEmissions: TEmission.isRequired,
    TotalEmissions: TEmission.isRequired,
    BallastMiles: PropTypes.number,
    LadenMiles: PropTypes.number,
    CargoOnBoard: PropTypes.number,
    CII: TCIIRating,
    CIIWithCorrections: TCIIRating,
    AERWithCorrectionsParameters: PropTypes.shape({
        TotalCO2: PropTypes.number,
        VesselFactor: PropTypes.number,
        VesselDwt: PropTypes.number,
        TraveledDistance: PropTypes.number
    })
});

export const TCiiRating = PropTypes.shape({
    CarbonIntensityIndicatorGrade: PropTypes.string
});

export const TCiiRatingWithCorrection = PropTypes.shape({
    AERWithCorrectionFactor: PropTypes.number,
    CarbonIntensityIndicatorGrade: PropTypes.string
});

export const TVmsFootprint = PropTypes.shape({
    AER: PropTypes.number,
    CII: PropTypes.string,
    CO2: PropTypes.number,
    EEOI: PropTypes.number,
    NOxMt: PropTypes.number,
    NOxPercent: PropTypes.number,
    SOxMt: PropTypes.number,
    SOxPercent: PropTypes.number
});

export const TEuEts = PropTypes.shape({
    voyageCoefficient: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number
    })),
    summaryCoefficient: PropTypes.number,
    year: PropTypes.shape({ Name: PropTypes.string }),
    co2Emissions: PropTypes.number
});

export const TYearlyRating = PropTypes.objectOf(TCiiRating);

export const TYearlyRatingWithCorrection = PropTypes.objectOf(TCiiRatingWithCorrection);
