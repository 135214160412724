export const ActionTypes = {
    PORT_INFO_SET_COMMENTS: 'PORT_INFO_SET_COMMENTS',
    PORT_INFO_GET_RESOURCES: 'PORT_INFO_GET_RESOURCES',
    PORT_INFO_SET_RESOURCES: 'PORT_INFO_SET_RESOURCES',
    PORT_INFO_SET_TERMINALS: 'PORT_INFO_SET_TERMINALS',
    PORT_INFO_UPDATE: 'PORT_INFO_UPDATE',
    PORT_INFO_RESET: 'PORT_INFO_RESET'
};

export function getResources(dispatch, portId) {
    dispatch({ type: ActionTypes.PORT_INFO_GET_RESOURCES, portId });
}

export function updatePort(dispatch, port) {
    dispatch({ type: ActionTypes.PORT_INFO_UPDATE, port });
}

export function reset(dispatch) {
    dispatch({ type: ActionTypes.PORT_INFO_RESET });
}
