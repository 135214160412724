import PropTypes from 'prop-types';

const TCanalOption = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    prop: PropTypes.string
});

const TBunkerOption = PropTypes.shape({
    Alias: PropTypes.string,
    Code: PropTypes.string,
    Coefficient: PropTypes.string,
    Default: PropTypes.bool,
    ECA: PropTypes.bool,
    Id: PropTypes.number,
    Name: PropTypes.string,
    Sequence: PropTypes.number,
    Unit: PropTypes.string,
    Visible: PropTypes.bool
});

const TStartinigPostionOption = PropTypes.shape({
    disabled: PropTypes.bool,
    disabledTitle: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.number
});

export const TOptions = PropTypes.shape({
    areaOptions: PropTypes.arrayOf(TCanalOption).isRequired,
    canalOptions: PropTypes.arrayOf(TCanalOption).isRequired,
    bunkerOptions: PropTypes.arrayOf(TBunkerOption).isRequired,
    startingPositionOptions: PropTypes.arrayOf(TStartinigPostionOption).isRequired
});

export const TFilters = PropTypes.shape({
    calculationVessel: PropTypes.objectOf(PropTypes.any),
    calculationVesselStartingPosition: TStartinigPostionOption,
    defaultSpeed: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    defaultWeatherFactor: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    selectedAreas: PropTypes.arrayOf(TCanalOption),
    selectedCanals: PropTypes.arrayOf(TCanalOption),
    selectedEcaBunker: TBunkerOption,
    selectedNonEcaBunker: TBunkerOption
});
