export const headerCellBg = 'rgba(125, 162, 179, 0.2)';
export const tableHeaderBg = headerCellBg;
export const tableHighlightRowBg = 'rgba(125, 162, 179, 0.3)';
export const colorBlue = '#0f3646';
export const colorDarkBlue = '#0f2e39';
export const colorBlueShade = '#122830';
export const colorLightBlue = '#0e5370';
export const colorTurquoise = '#28ffca';
export const colorRed = '#f44336';

export const colorForestGreen = '#4caf50';
export const colorYellow = '#ffeb3b';
export const colorOrange = '#ff9800';
export const colorMilanoRed = '#bd2626';

export const colorMainBg = colorBlue;
export const colorMainBgDark = colorDarkBlue;
export const colorMainBgDarker = colorBlueShade;

export const colorSecondary = colorLightBlue;
export const colorPrimary = colorTurquoise;

export const colorDanger = colorRed;
