import React from 'react';
import PropTypes from 'prop-types';
/* components */
import VesselSelect from './vessel-select';
/* Services */
import SearchService from 'services/core-api/search-service';

class VesselSelectInScope extends React.PureComponent {
    handleSearch = (searchCriteria, mapVessel, resolve) => {
        const params = {
            Limit: 20,
            SearchTerm: searchCriteria,
            FilterLNGVessels: this.props.filterLNGVessels
        };
        SearchService.getVesselsByScope(params).then((res) => {
            resolve(res && res.length
                ? res.map(vessel => mapVessel({ ...vessel, IsPrimary: !vessel.IsCompetition }))
                : null);
        });
    };

    render() {
        return (
            <VesselSelect
                {...this.props}
                onSearch={this.handleSearch}
            />
        );
    }
}

VesselSelectInScope.propTypes = {
    ...VesselSelect.PropTypes,
    filterLNGVessels: PropTypes.bool
};

VesselSelectInScope.defaultProps = {
    filterLNGVessels: false
};

export default VesselSelectInScope;
