import React from 'react';
/* utils */
import { commonFields, renderLabel } from 'components/form-fields/form-fields-helper';
import { t } from 'utils/i18n/i18n-model';

const validations = {
    Comment: { maxLength: 2000 },
    Emails: { required: true },
    InstructedSpeed: { required: true, numeric: { min: 0, max: 30, div: 0.5, divHint: 'whole or half' } },
    VesselCondition: { required: true }
};

export const fieldMap = {
    Vessel: {
        label: renderLabel(t('COUNTER_PVAR.COUNTER_PVAR_FORM.VESSEL')),
        edit: data => {
            return (
                <label className="label label--value">{data && data.Vessel
                    ? data.Vessel.Title
                    : '-'}
                </label>
            );
        }
    },
    Voyage: {
        label: renderLabel(t('COUNTER_PVAR.COUNTER_PVAR_FORM.VOYAGE')),
        edit: data => {
            return (
                <label className="label label--value">{data && data.Voyage
                    ? `${data.Voyage.VoyageNumber} ${data.Voyage.VoyagePortInfo}`
                    : '-'}
                </label>
            );
        }
    },
    VesselCondition: commonFields.Select(
        t('COUNTER_PVAR.COUNTER_PVAR_FORM.VESSEL_CONDITION'),
        'VesselCondition',
        'editingOptions.cargoStatuses',
        validations.VesselCondition,
        { valueKey: 'Id', labelKey: 'Name' }
    ),
    InstructedSpeed: commonFields.Number(
        t('COUNTER_PVAR.COUNTER_PVAR_FORM.INSTRUCTED_SPEED'),
        'InstructedSpeed',
        1,
        validations.InstructedSpeed,
        t('UNITS.SPEED')
    ),
    Emails: commonFields.MultiValueSelect(
        t('COUNTER_PVAR.COUNTER_PVAR_FORM.EMAILS'),
        'Emails',
        validations.Emails
    ),
    Comment: commonFields.Textarea(t('COUNTER_PVAR.COUNTER_PVAR_FORM.COMMENT'), 'Comment', validations.Comment)
};

export const sectionsAndFields = [
    {
        fields: ['Vessel', 'Voyage']
    },
    {
        fields: ['VesselCondition', 'InstructedSpeed', 'Emails'],
        className: 'sten-content__separator sten-content__separator--inner'
    },
    {
        fields: ['Comment'],
        inline: false,
        className: 'sten-content__separator sten-content__separator--inner'
    }
];
