import ApiHelper from './gsm-api';

class GsmFerryService {
    constructor() {
        this.resourceUrlSufix = 'Ferries';
    }

    getAll() {
        return ApiHelper.get(`${this.resourceUrlSufix}`)
            .then(response => response.data, () => null);
    }

    getById(id) {
        return ApiHelper.get(`${this.resourceUrlSufix}/${id}`)
            .then(response => response.data, () => null);
    }
}

export default new GsmFerryService();
