import { takeEvery, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
/* router */
import { navigate, appRoutes } from 'app-router';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { convertStringToNumber } from 'utils/helpers/info-helper';
/* actions */
import { ActionTypes } from './admin-vessel-management-form-actions';
import { ActionTypes as VesselManagementActions } from '../admin-vessel-management-actions';
/* Services */
import VesselService from 'services/core-api/vessel-service';
import CompanyService from 'services/core-api/company-service';

function* getAllIceLevels() {
    const currentIceClassLevels = yield select(state => state.adminVesselManagementFormReducer.iceClassLevels);
    if (!currentIceClassLevels.length) {
        const IceClassLevels = yield VesselService.getBureauVeritasIceClassLevels();
        if (IceClassLevels) {
            yield put({ type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_ICE_CLASS_LEVELS, IceClassLevels });
        }
    }
}

function* getVessel(action) {
    const vessel = yield VesselService.getByImo(action.imo);
    if (vessel) {
        yield put({ type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_EDITING_VESSEL, vessel });
    }
}

function* searchVessels(action) {
    const foundVessels = yield VesselService.searchVessels({
        query: action.searchCriteria
    });
    if (foundVessels && foundVessels.length) {
        yield put({ type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_FOUND_VESSELS, foundVessels });
    } else {
        yield put({ type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_FOUND_VESSELS, foundVessels: null });
    }
}

function* importVessel(action) {
    const importedVessel = yield VesselService.importVessel(action.imo);
    const activeFleetId = yield select(state => state.adminVesselManagementReducer.activeFleetId);
    if (importedVessel && importedVessel.Title) {
        toast(translate('ADMIN.VESSEL_MANAGEMENT.FORM.SUCCESSFULLY_IMPORTED_VESSEL'), { type: toast.TYPE.SUCCESS });
        yield navigate(appRoutes.Admin.VesselManagement);
        if (!activeFleetId) {
            yield put({
                type: VesselManagementActions.ADMIN_VESSEL_MANAGEMENT_TABLE_ADD_VESSEL,
                importedVessel
            });
        }
    }
}

function* submitData(action) {
    let vesselResponse;
    const activeVesselImo = yield select(state => state.adminVesselManagementReducer.activeVesselImo);
    const vessel = action.vessel;
    const parsedVesselData = {
        BlockCoefficientCb: vessel.BlockCoefficientCb || null,
        CubicCapacityCorrectionFactorFc: vessel.CubicCapacityCorrectionFactorFc || null,
        Class: vessel.Class || null,
        Comment: vessel.Comment,
        CommercialOperatorId: vessel.CommercialOperator?.Id || null,
        DWT: convertStringToNumber(vessel.DWT),
        Draft: convertStringToNumber(vessel.Draft),
        GT: vessel.GT || null,
        HasScrubber: vessel.HasScrubber,
        IMOClass: vessel.IMOClass || null,
        IceClassBureauVeritasLevel: vessel.IceClassBureauVeritasLevel || null,
        KielCanal: vessel.KielCanal,
        Loa: convertStringToNumber(vessel.Loa),
        NoxAuxEngine: vessel.NoxAuxEngine || null,
        NoxMainEngine: vessel.NoxMainEngine || null,
        OperationsEmail: vessel.OperationsEmail.join(';') || null,
        OwnerId: vessel.VesselOwner ? vessel.VesselOwner.Id : null,
        OwnerEmail: vessel.OwnerEmail.join(';') || null,
        PanamaCanal: vessel.PanamaCanal,
        ScrubberDataSourceId: vessel.ScrubberDataSourceId,
        ScrubberInstallationDate: vessel.HasScrubber && vessel.ScrubberInstallationDate
            ? vessel.ScrubberInstallationDate : null,
        SfcAuxEngine: vessel.SfcAuxEngine || null,
        SfcMainEngine: vessel.SfcMainEngine || null,
        ShowOnStenaBulk: vessel.ShowOnStenaBulk,
        SortOrder: convertStringToNumber(vessel.SortOrder, true),
        SuezCanal: vessel.SuezCanal,
        TechnicalManagementEmail: vessel.TechnicalManagementEmail.join(';') || null,
        TechnicalManagerId: vessel.TechnicalManager ? vessel.TechnicalManager.Id : null,
        Title: vessel.Title || null,
        TotalCubicCapacity: vessel.TotalCubicCapacity || null,
        TradingAreaId: vessel.TradingArea ? vessel.TradingArea.Id : null,
        VesselEmail: vessel.VesselEmail.join(';') || null,
        VesselSegmentId: vessel.VesselSegment ? vessel.VesselSegment.VesselTypeId : null,
        VesselType: vessel.VesselType,
        YearBuilt: vessel.YearBuilt || null,
        VoluntaryStructuralEnhancement: vessel.VoluntaryStructuralEnhancement || null,
        BudgetConsumptionDataSourceId: vessel.BudgetConsumptionDataSourceId || null,
        BudgetConsumption: vessel.BudgetConsumption || [],
        ShouldSyncReportsToVeracity: vessel.ShouldSyncReportsToVeracity,
        Mmsi: convertStringToNumber(vessel.Mmsi),
        Beam: convertStringToNumber(vessel.Beam),
        BallastOptimumTrim: convertStringToNumber(vessel.BallastOptimumTrim),
        LadenOptimumTrim: convertStringToNumber(vessel.LadenOptimumTrim)
    };
    if (activeVesselImo) {
        vesselResponse = yield VesselService.update(activeVesselImo, parsedVesselData);
    } else {
        parsedVesselData.Imo = convertStringToNumber(vessel.Imo, true);
        vesselResponse = yield VesselService.add(parsedVesselData);
    }
    if (vesselResponse === true) {
        if (activeVesselImo) {
            toast(translate('ADMIN.VESSEL_MANAGEMENT.FORM.SUCCESSFULLY_UPDATED_VESSEL'), { type: toast.TYPE.SUCCESS });
        } else {
            toast(translate('ADMIN.VESSEL_MANAGEMENT.FORM.SUCCESSFULLY_ADDED_VESSEL'), { type: toast.TYPE.SUCCESS });
        }
        yield navigate(appRoutes.Admin.VesselManagement);
        yield put({
            type: VesselManagementActions.ADMIN_VESSEL_MANAGEMENT_TABLE_GET_VESSELS,
            overrideCurrent: true
        });
        yield put({ type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_VALIDATION_ERRORS, validationErrors: null });
    } else {
        yield put({
            type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_VALIDATION_ERRORS,
            validationErrors: vesselResponse
        });
    }
    yield put({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_RESET_ALL
    });
}

function* getCommercialOperators(action) {
    const params = {};
    if (action.imo) {
        params.imo = action.imo;
    }
    const commercialOperators = yield VesselService.getCommercialOperators(params);
    if (commercialOperators) {
        yield put({
            type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_COMMERCIAL_OPERATORS,
            commercialOperators
        });
    }
}

function* getHeadOwners(action) {
    const params = {};
    if (action.imo) {
        params.imo = action.imo;
    }
    const headOwners = yield VesselService.getHeadOwners(params);
    if (headOwners) {
        yield put({
            type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_HEAD_OWNERS,
            headOwners
        });
    }
}

function* getTechnicalManagers(action) {
    const params = {};
    if (action.imo) {
        params.imo = action.imo;
    }
    const technicalManagers = yield VesselService.getTechnicalManagers(params);
    if (technicalManagers) {
        yield put({
            type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_TECHNICAL_MANAGERS,
            technicalManagers
        });
    }
}

function* addNewCompany(action) {
    const activeVesselImo = yield select(state => state.adminVesselManagementReducer.activeVesselImo);
    const roleName = action.key === 'TechnicalManagerId' ? 'TechnicalManager' : 'VesselOwner';
    const companyData = {
        CompanyAlias: action.companyName,
        RoleNames: [roleName.toUpperCase()]
    };
    const response = yield CompanyService.addPerRole(companyData);
    if (response) {
        if (roleName === 'TechnicalManager') {
            yield getTechnicalManagers({ imo: activeVesselImo });
        } else {
            yield getHeadOwners({ imo: activeVesselImo });
        }
        yield put({
            type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_NEW_COMPANY,
            companyId: response.Id,
            roleName,
            newCompany: response
        });
        toast(translate('ADMIN.VESSEL_MANAGEMENT.FORM.SUCCESSFULLY_ADDED_NEW_COMPANY'), { type: toast.TYPE.SUCCESS });
    }
}

export default function* adminVesselManagementFormSaga() {
    yield takeEvery(ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_GET_ALL_CLASS_LEVELS, getAllIceLevels);
    yield takeEvery(ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SEARCH_VESSELS, searchVessels);
    yield takeEvery(ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SUBMIT, submitData);
    yield takeEvery(ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_AS_NEW_COMPANY, addNewCompany);
    yield takeEvery(ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_IMPORT_VESSEL, importVessel);
    yield takeEvery(ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_GET_COMMERCIAL_OPERATORS, getCommercialOperators);
    yield takeEvery(ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_GET_HEAD_OWNERS, getHeadOwners);
    yield takeEvery(ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_GET_TECHNICAL_MANAGERS, getTechnicalManagers);
    yield takeEvery(ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_GET_VESSEL_BY_IMO, getVessel);
}
