import React from 'react';
import PropTypes from 'prop-types';
/* router */
import { TRouter, withRouter } from 'app-router';
/* components */
import NavButton from 'components/nav-button/nav-button';

export class AdminVesselManagementFleets extends React.PureComponent {
    handleFleetClick = (fleetId) => () => {
        this.props.router.updateQueryParams({ add: { fleet: fleetId } });
    };

    render() {
        return (
            <React.Fragment>
                {this.props.navFleets.length > 0 && this.props.navFleets.map(fleet => (
                    <NavButton
                        className="text-uppercase"
                        key={fleet.Id}
                        isActive={this.props.queryParams.fleet === fleet.Id}
                        onClick={this.handleFleetClick(fleet.Id)}
                    >
                        <h3>
                            {fleet.Name}
                            {typeof fleet.VesselsCount === 'number' && ` (${fleet.VesselsCount})`}
                        </h3>
                    </NavButton>
                ))}
            </React.Fragment>
        );
    }
}

AdminVesselManagementFleets.propTypes = {
    navFleets: PropTypes.arrayOf(PropTypes.object).isRequired,
    queryParams: PropTypes.objectOf(PropTypes.any).isRequired,
    router: TRouter.isRequired
};

export default withRouter(AdminVesselManagementFleets);
