import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import memoize from 'memoize-one';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { objLength, upperCaseMatch, getVesselTechnicalDetails } from 'utils/helpers/info-helper';
import { splitSegments } from 'components/segment-select/segment-select-helper';
/* actions */
import { toggleSidebar, updateFilters } from '../../kpi-actions';
/* selectors */
import { getVesselsBySegment, getSegmentsById } from '../../kpi-selectors';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
import Accordion from 'components/accordion/accordion';
import Select from 'components/select/select';
import Validation from 'components/validation/validation';
import Input from 'components/input/input';
import CheckableListItem from 'components/checkable-list-item/checkable-list-item';
import MonthPopup from 'components/month-popup/month-popup';
import TextHighlight from 'components/text-highlight/text-highlight';
import AfterInitialMount from 'components/after-initial-mount/after-initial-mount';
import RightSideBar from 'components/right-side-bar/right-side-bar';
/* styles */
import './sidebar.scss';

const t = (key) => translate(`ENERGY_MANAGEMENT.KPI.SIDEBAR.${key}`);
const allVesselsItem = {
    Title: translate('GLOBAL.SELECT_ALL'),
    Imo: -1
};
const emptyArr = [];

class EnergyManagementKpiSidebar extends React.PureComponent {
    state = {
        searchCriteria: '',
        selectedVesselsBySegment: {},
        selectedRangeOption: null,
        selectedRangeEnd: null,
        wasSidebarVisible: false
    };

    static getDerivedStateFromProps(props, state) {
        if (props.isSidebarVisible !== state.wasSidebarVisible) {
            if (props.isSidebarVisible) {
                return {
                    ...props.filters,
                    wasSidebarVisible: props.isSidebarVisible
                };
            }
            return { wasSidebarVisible: props.isSidebarVisible };
        }
        return null;
    }

    getAllVessels = () => {
        return this.props.segmentId
            ? (this.props.vesselsBySegment && this.props.vesselsBySegment[this.props.segmentId]) || emptyArr
            : this.props.vessels;
    };

    getVisibleVesselsMemoized = memoize((vessels, searchCriteria) => {
        const sc = searchCriteria.toUpperCase();

        return vessels.filter(v => upperCaseMatch(v.Title, sc)
            || upperCaseMatch(v.Imo, sc)
            || upperCaseMatch(v.VesselTypeName, sc)
            || upperCaseMatch(v.Owner, sc));
    });

    getVisibleVessels = () => {
        const vessels = this.getAllVessels();
        return this.getVisibleVesselsMemoized(vessels, this.state.searchCriteria);
    };

    getSelectedSegments = () => {
        const selectedSegments = [];
        for (const segmentId in this.state.selectedVesselsBySegment) {
            if (!this.props.vesselsBySegment[segmentId]
                || objLength(this.state.selectedVesselsBySegment[segmentId])
                    === this.props.vesselsBySegment[segmentId].length) {
                selectedSegments.push(this.props.segmentsById[segmentId]);
            }
        }
        return selectedSegments;
    };

    getSelectedVesselsCount = () => {
        let count = 0;
        if (this.props.segmentId) {
            if (this.state.selectedVesselsBySegment[this.props.segmentId]) {
                count = objLength(this.state.selectedVesselsBySegment[this.props.segmentId]);
            }
        } else {
            for (const segmentId in this.state.selectedVesselsBySegment) {
                count += objLength(this.state.selectedVesselsBySegment[segmentId]);
            }
        }
        return count;
    };

    handleSearchCriteriaChange = (searchCriteria) => this.setState({ searchCriteria });

    handleVesselSelect = (selectedVessel) => {
        const selectedVesselsBySegment = { ...this.state.selectedVesselsBySegment };
        if (!selectedVesselsBySegment[selectedVessel.VesselTypeId]) {
            selectedVesselsBySegment[selectedVessel.VesselTypeId] = {};
        }
        const vesselsInSegment = { ...selectedVesselsBySegment[selectedVessel.VesselTypeId] };
        if (vesselsInSegment[selectedVessel.Imo]) {
            delete vesselsInSegment[selectedVessel.Imo];
        } else {
            vesselsInSegment[selectedVessel.Imo] = true;
        }
        selectedVesselsBySegment[selectedVessel.VesselTypeId] = vesselsInSegment;
        if (objLength(selectedVesselsBySegment[selectedVessel.VesselTypeId]) === 0) {
            delete selectedVesselsBySegment[selectedVessel.VesselTypeId];
        }
        this.setState({ selectedVesselsBySegment });
    };

    handleSegmentSelect = (segment) => {
        const selectedVesselsBySegment = { ...this.state.selectedVesselsBySegment };
        if (!selectedVesselsBySegment[segment.VesselTypeId]) {
            selectedVesselsBySegment[segment.VesselTypeId] = {};
        }
        const vesselsInSegment = { ...selectedVesselsBySegment[segment.VesselTypeId] };
        if (this.props.vesselsBySegment[segment.VesselTypeId]) {
            this.props.vesselsBySegment[segment.VesselTypeId].forEach((vessel) => {
                vesselsInSegment[vessel.Imo] = true;
            });
        }
        selectedVesselsBySegment[segment.VesselTypeId] = vesselsInSegment;
        this.setState({ selectedVesselsBySegment });
    };

    handleSegmentDeselect = (segment) => {
        const selectedVesselsBySegment = { ...this.state.selectedVesselsBySegment };
        if (selectedVesselsBySegment[segment.VesselTypeId]) {
            delete selectedVesselsBySegment[segment.VesselTypeId];
        }
        this.setState({ selectedVesselsBySegment });
    };

    handleSegmentClear = (segments) => {
        if (segments.length === 0) {
            this.setState({ selectedVesselsBySegment: {} });
        }
    };

    handleMonthSelect = (selectedRangeEnd) => {
        this.setState({ selectedRangeEnd: { ...selectedRangeEnd } || null });
    };

    handleRangeOptionSelect = (selectedRangeOption) => this.setState({ selectedRangeOption });

    handleResetClick = () => {
        this.setState({
            ...this.props.defaultFilters,
            selectedRangeEnd: this.props.defaultFilters.selectedRangeEnd || moment().startOf('d'),
            searchCriteria: ''
        });
    };

    handleFormSubmit = () => {
        const filters = {
            selectedVesselsBySegment: this.state.selectedVesselsBySegment,
            selectedRangeOption: this.state.selectedRangeOption,
            selectedRangeEnd: this.state.selectedRangeEnd
        };
        this.props.updateFilters(filters);
        this.props.toggleSidebar(false);
        if (this.props.onSubmit) {
            this.props.onSubmit(filters);
        }
    };

    vesselCountValidation = {
        rule: (value) => value > 0,
        hint: () => t('NO_VESSELS_SELECTED')
    };

    areAllVesselsSelectedMemoized = memoize((vessels, selectedVesselsBySegment) => {
        return vessels.every(v => selectedVesselsBySegment[v.VesselTypeId]
            && selectedVesselsBySegment[v.VesselTypeId][v.Imo] === true);
    });

    areAllVisibleVesselsSelected = () => {
        const vessels = this.getVisibleVessels();
        return this.areAllVesselsSelectedMemoized(
            vessels,
            this.state.selectedVesselsBySegment
        );
    };

    toggleSelectAllVessels = () => {
        const value = !this.areAllVisibleVesselsSelected();
        const vessels = this.getVisibleVessels();
        const selectedVesselsBySegment = { ...this.state.selectedVesselsBySegment };

        vessels.forEach(vessel => {
            if (!selectedVesselsBySegment[vessel.VesselTypeId]) {
                selectedVesselsBySegment[vessel.VesselTypeId] = {};
            }
            if (value) {
                selectedVesselsBySegment[vessel.VesselTypeId][vessel.Imo] = value;
            } else {
                delete selectedVesselsBySegment[vessel.VesselTypeId][vessel.Imo];
            }
        });

        this.setState({ selectedVesselsBySegment });
    };

    renderIncludedVesselsSection() {
        const vessels = this.getVisibleVessels();
        const selectedVesselsCount = this.getSelectedVesselsCount();
        const accordionHeader = (
            <h4 className="text-uppercase">{`${t('INCLUDED_VESSELS')} (${selectedVesselsCount})`}</h4>
        );
        let includedVesselsClass = 'sten-energy-management-kpi-sidebar__vessels';
        if (this.props.segmentId) {
            includedVesselsClass += ' sten-energy-management-kpi-sidebar__vessels--extended';
        }
        return (
            <Accordion className={includedVesselsClass} header={accordionHeader} isCollapsedBodyRendered>
                <div className="sten-content__section">
                    {!this.props.vesselId && !this.props.segmentId && (
                        <div className="form-row">
                            <label className="label">{t('SEGMENT')}</label>
                            <Select
                                name="selectedSegments"
                                searchable
                                multiple
                                clearable
                                valueKey="VesselTypeId"
                                labelKey="VesselTypeName"
                                options={this.props.segments}
                                optionRenderer={this.getSegmentOption}
                                placeholder="Select Segments"
                                value={this.getSelectedSegments()}
                                onItemSelect={this.handleSegmentSelect}
                                onItemDeselect={this.handleSegmentDeselect}
                                onChange={this.handleSegmentClear}
                                splitOptionsIntoSections={splitSegments}
                            />
                        </div>
                    )}
                    <div className="form-row">
                        <label className="label">{t('VESSELS')}</label>
                        <Input
                            value={this.state.searchCriteria}
                            onChange={this.handleSearchCriteriaChange}
                            placeholder={t('SEARCH_BY_PLACEHOLDER')}
                            title={t('SEARCH_BY_TITLE')}
                        />
                    </div>
                </div>
                <ScrollArea
                    shouldScrollToActiveElement
                    className="sten-content__separator sten-energy-management-kpi-sidebar__vessels-list"
                >
                    <Validation.Wrapper
                        className="sten-energy-management-kpi-sidebar__vessels-validation"
                        validations={{ custom: this.vesselCountValidation }}
                    >
                        <Validation.Value value={selectedVesselsCount} name="vesselCount" />
                    </Validation.Wrapper>
                    <CheckableListItem
                        isChecked={this.areAllVisibleVesselsSelected()}
                        item={allVesselsItem}
                        title={allVesselsItem.Title}
                        id={allVesselsItem.Imo}
                        onClick={this.toggleSelectAllVessels}
                        searchCriteria=""
                        titleClassName="text-primary"
                    />
                    {vessels.map((vessel) => (
                        <CheckableListItem
                            isChecked={this.state.selectedVesselsBySegment[vessel.VesselTypeId]
                            && this.state.selectedVesselsBySegment[vessel.VesselTypeId][vessel.Imo] === true}
                            key={vessel.Imo}
                            id={vessel.Imo}
                            title={vessel.Title}
                            subtitle={getVesselTechnicalDetails(vessel)}
                            titleClassName={vessel.IsCompetition ? '' : 'text-primary'}
                            item={vessel}
                            searchCriteria={this.state.searchCriteria}
                            onClick={this.handleVesselSelect}
                        />
                    ), this)}
                </ScrollArea>
            </Accordion>
        );
    }

    getSegmentOption = (segment, searchCriteria) => {
        const vesselsInSegment = (this.props.vesselsBySegment[segment.VesselTypeId]
            && this.props.vesselsBySegment[segment.VesselTypeId].length) || 0;
        const title = `${segment.VesselTypeName} (${vesselsInSegment})`;
        return <TextHighlight input={title} highlight={searchCriteria} />;
    };

    render() {
        return (
            <RightSideBar isCollapsed={!this.props.isSidebarVisible}>
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <h1 className="sten-content__title flex-grow">
                            {t('FILTERS')}
                        </h1>
                        <div className="flex-shrink">
                            <button onClick={this.props.toggleSidebar} className="btn-link icon icon-close" />
                        </div>
                    </div>
                    <Validation.Form
                        className="sten-energy-management-kpi-sidebar__form"
                        onSubmit={this.handleFormSubmit}
                    >
                        <div className="sten-content__body">
                            <div className="sten-content__section">
                                <div className="row form-row">
                                    <div className="col-16">
                                        <label className="label">{t('DATA_RANGE')}</label>
                                        <Validation.Wrapper validations={{ required: true }}>
                                            <Select
                                                name="rangeOption"
                                                valueKey="Id"
                                                labelKey="Description"
                                                options={this.props.rangeOptions}
                                                value={this.state.selectedRangeOption}
                                                onChange={this.handleRangeOptionSelect}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                    <div className="col-8">
                                        <label className="label">{t('END_DATE')}</label>
                                        <Validation.Wrapper validations={{ required: true }}>
                                            <MonthPopup
                                                name="rangeEnd"
                                                value={this.state.selectedRangeEnd}
                                                onChange={this.handleMonthSelect}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                </div>
                            </div>
                            {!this.props.vesselId && this.renderIncludedVesselsSection()}
                        </div>
                        <footer className="sten-content__footer flex-row">
                            <div className="col-12">
                                <button
                                    onClick={this.handleResetClick}
                                    className="btn btn--secondary col-24"
                                    type="button"
                                >
                                    {translate('GLOBAL.RESET')}
                                </button>
                            </div>
                            <div className="col-12">
                                <Validation.Button className="btn btn--primary col-24">
                                    {translate('GLOBAL.APPLY')}
                                </Validation.Button>
                            </div>
                        </footer>
                    </Validation.Form>
                </div>
            </RightSideBar>
        );
    }
}

EnergyManagementKpiSidebar.propTypes = {
    defaultFilters: PropTypes.objectOf(PropTypes.any).isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    isSidebarVisible: PropTypes.bool.isRequired,
    onSubmit: PropTypes.func,
    rangeOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
    segmentId: PropTypes.string,
    segments: PropTypes.arrayOf(PropTypes.any).isRequired,
    segmentsById: PropTypes.objectOf(PropTypes.any).isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    vesselId: PropTypes.string,
    vessels: PropTypes.arrayOf(PropTypes.any).isRequired,
    vesselsBySegment: PropTypes.objectOf(PropTypes.any).isRequired
};

EnergyManagementKpiSidebar.defaultProps = {
    onSubmit: undefined,
    segmentId: '',
    vesselId: ''
};

const mapStateToProps = (state) => ({
    defaultFilters: state.energyManagementKPIReducer.defaultFilters,
    filters: state.energyManagementKPIReducer.filters,
    isSidebarVisible: state.energyManagementKPIReducer.isSidebarVisible,
    rangeOptions: state.energyManagementKPIReducer.rangeOptions,
    segments: state.energyManagementKPIReducer.segments,
    segmentsById: getSegmentsById(state),
    vessels: state.energyManagementKPIReducer.vessels,
    vesselsBySegment: getVesselsBySegment(state)
});

function mapDispatchToProps(dispatch) {
    return {
        toggleSidebar: () => toggleSidebar(dispatch),
        updateFilters: (newFilters) => updateFilters(dispatch, newFilters)
    };
}

const EnergyManagementKpiSidebarConnected = connect(mapStateToProps, mapDispatchToProps)(EnergyManagementKpiSidebar);

export default (props) => (
    <AfterInitialMount>
        <EnergyManagementKpiSidebarConnected {...props} />
        <Outlet />
    </AfterInitialMount>
);
