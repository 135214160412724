import PropTypes from 'prop-types';

export const TWeatherRoutingQueryParams = PropTypes.shape({
    cpEdit: PropTypes.bool,
    cpVoyage: PropTypes.number,
    requestId: PropTypes.number,
    requestEdit: PropTypes.bool,
    showCounterPvar: PropTypes.bool,
    vessel: PropTypes.number,
    voyage: PropTypes.number
});
