import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { mapObjectKeys } from 'utils/helpers/info-helper';
import { mapArrayByValue } from 'utils/helpers/array-helper';
import ScrollArea from 'components/scroll-area/scroll-area';
/* components */
import Validation from 'components/validation/validation';
import Checkbox from 'components/checkbox/checkbox';

const t = (key) => translate(`PORT_MANAGEMENT.SETTINGS.${key}`);

class PortManagementSettings extends React.PureComponent {
    state = {
        columnSelectedMap: mapArrayByValue(this.props.settings)
    };

    handleColumnToggle = (columnId) => () => {
        const val = this.state.columnSelectedMap[columnId];
        this.setState({
            columnSelectedMap: {
                ...this.state.columnSelectedMap,
                [columnId]: !val
            }
        });
    };

    handleSubmit = () => {
        const { columnSelectedMap } = this.state;
        const selectedColumnIds = mapObjectKeys(columnSelectedMap, val => parseInt(val, 10));

        this.props.onSubmit(selectedColumnIds);
    };

    renderColumn = (column) => (
        <div className="form-row" key={column.Id}>
            <Checkbox
                onChange={this.handleColumnToggle(column.Id)}
                isChecked={this.state.columnSelectedMap[column.Id]}
                name="test-checkbox"
            >
                {column.DisplayName}
            </Checkbox>
        </div>
    );

    render() {
        return (
            <Validation.Form
                onSubmit={this.handleSubmit}
                className="sten-content sten-content--has-footer"
            >
                <div className="sten-content__header">
                    <h1 className="sten-content__title flex-grow">{t('TITLE')}</h1>
                    <div className="flex-shrink">
                        <button
                            type="button"
                            className="btn-link icon icon-close"
                            onClick={this.props.onClose}
                        />
                    </div>
                </div>
                <ScrollArea className="sten-content__body" shouldScrollToActiveElement>
                    <div className="sten-content__section">{this.props.columns.map(this.renderColumn)}</div>
                </ScrollArea>
                <footer className="sten-content__footer flex-row">
                    <div className="col-12">
                        <button
                            className="btn btn--secondary col-24"
                            type="button"
                            onClick={this.props.onClose}
                        >
                            {translate('GLOBAL.CANCEL')}
                        </button>
                    </div>
                    <div className="col-12">
                        <Validation.Button className="btn btn--primary col-24">
                            {translate('GLOBAL.APPLY')}
                        </Validation.Button>
                    </div>
                </footer>
            </Validation.Form>
        );
    }
}

PortManagementSettings.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.any),
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    settings: PropTypes.arrayOf(PropTypes.any)
};

PortManagementSettings.defaultProps = {
    columns: [],
    settings: []
};

export default PortManagementSettings;
