import { ActionTypes } from './fleet-performance-report-actions';

const initialState = {
    report: null,
    performancePerVoyage: {
        voyages: [],
        vesselName: '',
        vesselImo: null
    },
    isPerformancePerVoyageModalOpen: false
};

export default function energyManagementReportsFleetPerformanceReportReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_SET: {
        return {
            ...state,
            report: action.report
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_SET_PER_VOYAGE: {
        return {
            ...state,
            performancePerVoyage: {
                voyages: action.performancePerVoyage,
                vesselName: action.vesselName,
                vesselImo: action.vesselImo
            },
            isPerformancePerVoyageModalOpen: true
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_PER_VOYAGE_MODAL_CLOSE: {
        return {
            ...state,
            performancePerVoyage: {
                voyages: [],
                vesselName: '',
                vesselImo: null
            },
            isPerformancePerVoyageModalOpen: false
        };
    }
    default:
        return state;
    }
}
