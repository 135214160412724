import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { getVesselTechnicalDetails } from 'utils/helpers/info-helper';
/* actions */
import {
    getVessels,
    removeVesselFromFleet,
    resetAllTable,
    setSortProp,
    filterUpdateLoaded
} from '../admin-vessel-management-actions';
import { openAssignToFleetModal } from 'components/assign-to-fleet-modal/assign-to-fleet-modal-actions';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import Menu, { MenuItem, SubMenu } from 'components/menu/menu';
import EmptyContent from 'components/empty-content/empty-content';
import TableHeadCell from 'components/table-head-cell/table-head-cell';
import TextHighlight from 'components/text-highlight/text-highlight';
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
/* styles */
import './admin-vessel-management-table.scss';

const getTableHeadCells = () => {
    const tableHeadCells = [
        { title: t('ADMIN.VESSEL_MANAGEMENT.TABLE.VESSEL_NAME'), sortProp: 'Title', sortable: true },
        { title: t('ADMIN.VESSEL_MANAGEMENT.TABLE.OWNER'), sortProp: 'Owner', sortable: true },
        { title: t('ADMIN.VESSEL_MANAGEMENT.TABLE.TECH_MANAGER'), sortProp: 'TechnicalManager', sortable: true },
        { title: '', sortable: false }
    ];
    if (ConfigService.featureToggles.showVesselManagementSortOrder) {
        tableHeadCells.splice(3, 0, {
            title: t('ADMIN.VESSEL_MANAGEMENT.TABLE.SORT_ORDER'),
            sortProp: 'StenaBulkOrder',
            sortable: true
        });
    }
    return tableHeadCells;
};

export class AdminVesselManagementTable extends React.PureComponent {
    componentDidMount() {
        this.props.getVessels(this.props.queryParams.fleet, true);
    }

    componentDidUpdate(prevProps) {
        const shouldScrollToTop = this.props.filterUpdated
            || prevProps.queryParams.fleet !== this.props.queryParams.fleet
            || this.props.offset === this.props.limit;

        if (shouldScrollToTop && this.scrollArea && this.scrollArea.state.topPosition > 0) {
            this.scrollArea.scrollArea.scrollTop();
        }
        if (prevProps.queryParams.fleet !== this.props.queryParams.fleet || this.props.filterUpdated) {
            this.props.getVessels(this.props.queryParams.fleet, true);
        }
        if (this.props.filterUpdated) {
            this.props.filterUpdateLoaded();
        }
    }

    componentWillUnmount() {
        this.props.resetAll();
    }

    scrollHandler = value => {
        if (value.realHeight - value.topPosition < value.containerHeight + 300) {
            if (!this.props.isFetchingData && !this.props.vesselsAllFetched) {
                this.props.getVessels(this.props.queryParams.fleet, false);
            }
        }
    };

    saveRef = c => { this.scrollArea = c ? c.scrollArea : null; };

    setSortProp = sortProp => {
        this.props.setSortProp(sortProp);
        this.props.getVessels(this.props.queryParams.fleet, true);
        if (this.scrollArea) {
            this.scrollArea.scrollArea.scrollTop();
        }
    };

    handleRemoveVesselClick = (IMO) => () => this.props.removeVesselFromFleet(IMO, this.props.queryParams.fleet);

    renderContextMenu = (vessel) => (
        <Menu className="sten-admin-vessel-management-table__menu">
            <MenuItem to={`${appRoutes.Map}?vessel=${vessel.IMO}`}>
                {t('ADMIN.VESSEL_MANAGEMENT.TABLE.LOCATE_ON_MAP')}
            </MenuItem>
            <MenuItem to={this.props.router.getLinkTo({ pathname: `edit/${vessel.IMO}` })}>
                {t('ADMIN.VESSEL_MANAGEMENT.TABLE.EDIT_VESSEL')}
            </MenuItem>
            {this.props.queryParams.fleet && (
                <MenuItem onClick={this.handleRemoveVesselClick(vessel.IMO)}>
                    {t('ADMIN.VESSEL_MANAGEMENT.TABLE.REMOVE_FROM_FLEET')}
                </MenuItem>
            )}
            <SubMenu text={t('ADMIN.VESSEL_MANAGEMENT.TABLE.ASSIGN_TO_FLEET')}>
                {this.props.fleets.map((fleet) => (
                    <MenuItem
                        onClick={this.props.openAssignToFleetModal.bind(this, vessel, fleet)}
                        key={fleet.Id}
                    >
                        {fleet.Name}
                    </MenuItem>
                ))}
            </SubMenu>
        </Menu>
    );

    getVesselRowClassName = isHighlighted => {
        if (isHighlighted) {
            return 'sten-table__row--highlighted';
        }
        return null;
    };

    renderTableRow = vessel => {
        let vesselNameClass = 'sten-h3 sten-h--link text-uppercase cursor-pointer';
        if (vessel.IsVesselInUserScope) {
            vesselNameClass += ' text-primary';
        }
        return (
            <tr key={vessel.IMO} className={this.getVesselRowClassName(vessel.isHighlighted)}>
                <td>
                    <Link
                        className={vesselNameClass}
                        to={this.props.router.getLinkTo({ pathname: `edit/${vessel.IMO}` })}
                    >
                        <TextHighlight
                            input={vessel.Title || ''}
                            highlight={this.props.searchCriteria}
                        />
                    </Link>
                    <p className="text-secondary">
                        {getVesselTechnicalDetails(vessel)}
                    </p>
                </td>
                <td>
                    <h3 className="text-uppercase text-ellipsis">
                        {vessel.Owner
                            ? (
                                <TextHighlight
                                    input={vessel.Owner || ''}
                                    highlight={this.props.searchCriteria}
                                />
                            )
                            : '/'
                        }
                    </h3>
                </td>
                <td>
                    <h3 className="text-uppercase text-ellipsis">
                        {vessel.TechnicalManager
                            ? (
                                <TextHighlight
                                    input={vessel.TechnicalManager || ''}
                                    highlight={this.props.searchCriteria}
                                />
                            )
                            : '/'
                        }
                    </h3>
                </td>
                {ConfigService.featureToggles.showVesselManagementSortOrder && (
                    <td>
                        <h3 className="text-uppercase text-ellipsis">
                            {typeof vessel.StenaBulkSortOrder === 'number' ? vessel.StenaBulkSortOrder : '/'}
                        </h3>
                    </td>
                )}
                <td className="text-center">
                    {this.renderContextMenu(vessel)}
                </td>
            </tr>
        );
    };

    renderTableHeaderCell = (cell, index) => (
        <TableHeadCell
            sortOrder={this.props.sortProp === cell.sortProp ? this.props.sortOrder : ''}
            sortable={cell.sortable}
            key={index}
            onClick={this.setSortProp.bind(this, cell.sortProp)}
        >
            {cell.title}
        </TableHeadCell>
    );

    render() {
        let tableClass = 'sten-admin-vessel-management-table';
        if (this.props.className) {
            tableClass += ` ${this.props.className}`;
        }
        if (this.props.areFiltersVisible) {
            tableClass += ' sten-admin-vessel-management-table--filters-visible';
        }
        return (
            <div className={tableClass}>
                {!this.props.vessels.length && <EmptyContent />}
                {this.props.vessels && this.props.vessels.length > 0 && (
                    <FixedHeaderTable
                        customFirstChild={TableHeadCell}
                        ref={this.saveRef}
                        contentClassName="sten-admin-vessel-management-table__content"
                        onScroll={this.scrollHandler}
                    >
                        <table className="sten-table">
                            <thead>
                                <tr>
                                    {getTableHeadCells().map(this.renderTableHeaderCell)}
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.vessels.map(this.renderTableRow)}
                            </tbody>
                        </table>
                    </FixedHeaderTable>
                )}
            </div>
        );
    }
}

AdminVesselManagementTable.propTypes = {
    areFiltersVisible: PropTypes.bool.isRequired,
    className: PropTypes.string,
    filterUpdated: PropTypes.bool.isRequired,
    filterUpdateLoaded: PropTypes.func.isRequired,
    fleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    getVessels: PropTypes.func.isRequired,
    isFetchingData: PropTypes.bool.isRequired,
    limit: PropTypes.number.isRequired,
    offset: PropTypes.number.isRequired,
    openAssignToFleetModal: PropTypes.func.isRequired,
    queryParams: PropTypes.objectOf(PropTypes.any).isRequired,
    removeVesselFromFleet: PropTypes.func.isRequired,
    resetAll: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    searchCriteria: PropTypes.string.isRequired,
    setSortProp: PropTypes.func.isRequired,
    sortOrder: PropTypes.string.isRequired,
    sortProp: PropTypes.string.isRequired,
    vessels: PropTypes.arrayOf(PropTypes.any).isRequired,
    vesselsAllFetched: PropTypes.bool.isRequired
};

AdminVesselManagementTable.defaultProps = {
    className: ''
};

function mapStateToProps(state) {
    return {
        areFiltersVisible: state.adminVesselManagementReducer.areFiltersVisible,
        filterUpdated: state.adminVesselManagementReducer.filterUpdated,
        fleets: state.adminVesselManagementReducer.fleets,
        isFetchingData: state.adminVesselManagementReducer.isFetchingData,
        limit: state.adminVesselManagementReducer.limit,
        offset: state.adminVesselManagementReducer.offset,
        searchCriteria: state.adminVesselManagementReducer.searchCriteria,
        sortOrder: state.adminVesselManagementReducer.sortOrder,
        sortProp: state.adminVesselManagementReducer.sortProp,
        vessels: state.adminVesselManagementReducer.vessels,
        vesselsAllFetched: state.adminVesselManagementReducer.vesselsAllFetched
    };
}

function mapDispatchToProps(dispatch) {
    return {
        filterUpdateLoaded: () => filterUpdateLoaded(dispatch),
        getVessels: (fleetId, overrideCurrent) => getVessels(dispatch, fleetId, overrideCurrent),
        openAssignToFleetModal: (vessel, fleet) => openAssignToFleetModal(dispatch, vessel, fleet),
        removeVesselFromFleet: (imo, fleetId) => removeVesselFromFleet(dispatch, imo, fleetId),
        resetAll: () => resetAllTable(dispatch),
        setSortProp: sortProp => setSortProp(dispatch, sortProp)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminVesselManagementTable));
