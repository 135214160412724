import CoreApi from './core-api';

class CargoService {
    getFixtures(params) {
        return CoreApi.get('Fixtures', params)
            .then(response => response.data, () => null);
    }

    getCargoesByRange(vesselImo, params) {
        return CoreApi.get(`Cargoes/Vessels/${vesselImo}`, params)
            .then(response => response.data, () => []);
    }

    getCargoes(params) {
        return CoreApi.get('Cargoes', params)
            .then(response => response.data, () => []);
    }

    getCargoStatuses(params) {
        return CoreApi.get('Cargoes/CargoTypes', params)
            .then(response => response.data, () => []);
    }

    getTankLayout(voyageId) {
        return CoreApi.get(`Cargoes/CargoSummary/${voyageId}`)
            .then(response => response.data, () => []);
    }
}

export default new CargoService();
