export const ActionTypes = {
    TERMINAL_INFO_SET_COMMENTS: 'TERMINAL_INFO_SET_COMMENTS',
    TERMINAL_INFO_GET_RESOURCES: 'TERMINAL_INFO_GET_RESOURCES',
    TERMINAL_INFO_SET_RESOURCES: 'TERMINAL_INFO_SET_RESOURCES',
    TERMINAL_INFO_SET_BERTHS: 'TERMINAL_INFO_SET_BERTHS',
    TERMINAL_INFO_UPDATED: 'TERMINAL_INFO_UPDATED',
    TERMINAL_INFO_RESET: 'TERMINAL_INFO_RESET'
};

export function getResources(dispatch, terminalId) {
    dispatch({ type: ActionTypes.TERMINAL_INFO_GET_RESOURCES, terminalId });
}

export function terminalUpdated(dispatch, id, terminal) {
    dispatch({ type: ActionTypes.TERMINAL_INFO_UPDATED, id, terminal });
}

export function reset(dispatch) {
    dispatch({ type: ActionTypes.TERMINAL_INFO_RESET });
}
