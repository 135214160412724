export const ActionTypes = {
    EMPLOYED_VESSELS_PANEL_GET_EMPLOYED_VESSELS: 'EMPLOYED_VESSELS_PANEL_GET_EMPLOYED_VESSELS',
    EMPLOYED_VESSELS_PANEL_SET_EMPLOYED_VESSELS: 'EMPLOYED_VESSELS_PANEL_SET_EMPLOYED_VESSELS',
    EMPLOYED_VESSELS_PANEL_GET_ACTIVE_CARGOES: 'EMPLOYED_VESSELS_PANEL_GET_ACTIVE_CARGOES',
    EMPLOYED_VESSELS_PANEL_SET_ACTIVE_CARGOES: 'EMPLOYED_VESSELS_PANEL_SET_ACTIVE_CARGOES',
    EMPLOYED_VESSELS_PANEL_GET_HISTORICAL_CARGOES: 'EMPLOYED_VESSELS_PANEL_GET_HISTORICAL_CARGOES',
    EMPLOYED_VESSELS_PANEL_SET_HISTORICAL_CARGOES: 'EMPLOYED_VESSELS_PANEL_SET_HISTORICAL_CARGOES'
};

export function getEmployedVessels(dispatch) {
    dispatch({
        type: ActionTypes.EMPLOYED_VESSELS_PANEL_GET_EMPLOYED_VESSELS
    });
}

export function getActiveCargoes(dispatch) {
    dispatch({
        type: ActionTypes.EMPLOYED_VESSELS_PANEL_GET_ACTIVE_CARGOES
    });
}

export function getHistoricalCargoes(dispatch, overrideCurrent) {
    dispatch({
        type: ActionTypes.EMPLOYED_VESSELS_PANEL_GET_HISTORICAL_CARGOES,
        overrideCurrent
    });
}
