import { takeEvery, put } from 'redux-saga/effects';
import VesselService from 'services/core-api/vessel-service';
import { ActionTypes } from './fleet-activity-actions';
import moment from 'moment/moment';
import { formatNumber } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';

const getPointName = {
    ByDay: date => TimeHelper.getFormatted(date),
    ByWeek: date => `Week ${moment(date).format('W, YYYY')}`,
    ByMonth: date => moment(date).format('MMM YYYY')
};

const params = {
    IncludeCompetition: true
};

function* getFleetActivity(action) {
    if (action.from && action.to) {
        params.StartDate = action.from;
        params.EndDate = action.to;
    }
    const response = yield VesselService.getFleetActivity(params);
    if (response && response.response && response.response.status === 413) {
        yield put({
            type: ActionTypes.FLEET_ACTIVITY_SET,
            stats: null,
            chartData: null
        });
    } else {
        const { SpeedChartData } = response;
        const chartData = {
            groupingMode: SpeedChartData.GroupingMode,
            numberOfItems: SpeedChartData.NumberOfItems,
            categories: [],
            series: {
                ballast: [],
                laden: []
            }
        };
        SpeedChartData.Items.forEach((item) => {
            const pointName = getPointName[SpeedChartData.GroupingMode](item.StartDate);
            chartData.categories.push(new Date(item.StartDate));
            chartData.series.ballast.push({
                y: item.AverageBallastSpeed,
                title: formatNumber(item.AverageBallastSpeed),
                name: pointName
            });
            chartData.series.laden.push({
                y: item.AverageLadenSpeed,
                title: formatNumber(item.AverageLadenSpeed),
                name: pointName
            });
        });
        yield put({
            type: ActionTypes.FLEET_ACTIVITY_SET,
            stats: response,
            chartData
        });
    }
}

export default function* fleetActivitySaga() {
    yield takeEvery(ActionTypes.FLEET_ACTIVITY_GET, getFleetActivity);
}
