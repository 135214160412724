import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* actions */
import { updateUserSettings } from 'pages/user-pages/user-actions';
/* components */
import Checkbox from 'components/checkbox/checkbox';
/* styles */
import './areas-of-interest.scss';

export class AreasOfInterest extends React.PureComponent {
    toggleAreaType = areaTypeId => {
        const selectedAreaTypes = this.props.selectedAreaTypes.slice();
        const selectedAreaTypeIndex = selectedAreaTypes.indexOf(areaTypeId);
        if (selectedAreaTypes.indexOf(areaTypeId) < 0) {
            selectedAreaTypes.push(areaTypeId);
        } else {
            selectedAreaTypes.splice(selectedAreaTypeIndex, 1);
        }
        this.props.updateUserSettings({
            MapSelectedAreasOfInterest: selectedAreaTypes
        });
    };

    render() {
        const areaClassMap = {
            1: 'sten-areas-of-interest__row--load',
            2: 'sten-areas-of-interest__row--risk',
            3: 'sten-areas-of-interest__row--trading',
            4: 'sten-areas-of-interest__row--eca'
        };

        return (
            <div className="sten-areas-of-interest sten-content__section">
                {this.props.areaTypes && this.props.areaTypes.map(areaType => (
                    <div
                        className={`sten-areas-of-interest__row form-row ${areaClassMap[areaType.AreaTypeId]}`}
                        key={areaType.AreaTypeId}
                    >
                        <Checkbox
                            isChecked={this.props.selectedAreaTypes.indexOf(areaType.AreaTypeId) >= 0}
                            onChange={this.toggleAreaType.bind(this, areaType.AreaTypeId)}
                        >
                            {areaType.Name}
                        </Checkbox>
                    </div>
                ))}
            </div>
        );
    }
}

AreasOfInterest.propTypes = {
    areaTypes: PropTypes.arrayOf(PropTypes.object),
    selectedAreaTypes: PropTypes.arrayOf(PropTypes.number).isRequired,
    updateUserSettings: PropTypes.func.isRequired
};

AreasOfInterest.defaultProps = {
    areaTypes: null
};

const emptyArray = [];

function mapStateToProps(state) {
    return {
        areaTypes: state.mapOptionsReducer.areaTypes,
        selectedAreaTypes: state.userReducer.settings && state.userReducer.settings.MapSelectedAreasOfInterest
            ? state.userReducer.settings.MapSelectedAreasOfInterest
            : emptyArray
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateUserSettings: settings => updateUserSettings(dispatch, settings, 'mapOptions')
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AreasOfInterest);
