export const ActionTypes = {
    VESSEL_REPORT_GET: 'VESSEL_REPORT_GET',
    VESSEL_REPORT_SET: 'VESSEL_REPORT_SET',
    VESSEL_REPORT_GET_EDITING_OPTIONS: 'VESSEL_REPORT_GET_EDITING_OPTIONS',
    VESSEL_REPORT_SET_EDITING_OPTIONS: 'VESSEL_REPORT_SET_EDITING_OPTIONS',
    VESSEL_REPORT_GET_FUEL_TYPES: 'VESSEL_REPORT_GET_FUEL_TYPES',
    VESSEL_REPORT_SET_FUEL_TYPES: 'VESSEL_REPORT_SET_FUEL_TYPES',
    VESSEL_REPORT_DELETE: 'VESSEL_REPORT_DELETE',
    VESSEL_REPORT_UPDATE: 'VESSEL_REPORT_UPDATE',
    VESSEL_REPORT_SUCCESSFULLY_UPDATED: 'VESSEL_REPORT_SUCCESSFULLY_UPDATED',
    VESSEL_REPORT_SUCCESSFULLY_DELETED: 'VESSEL_REPORT_SUCCESSFULLY_DELETED',
    VESSEL_REPORT_SEND_REMINDER: 'VESSEL_REPORT_SEND_REMINDER',
    VESSEL_REPORT_REMINDER_SENT: 'VESSEL_REPORT_REMINDER_SENT',
    VESSEL_REPORT_GET_CONSUMPTION_TYPES: 'VESSEL_REPORT_GET_CONSUMPTION_TYPES',
    VESSEL_REPORT_SET_CONSUMPTION_TYPES: 'VESSEL_REPORT_SET_CONSUMPTION_TYPES',
    VESSEL_REPORT_SET_BUNKER_TYPES: 'VESSEL_REPORT_SET_BUNKER_TYPES',
    VESSEL_REPORT_GET_CONFIGS: 'VESSEL_REPORT_GET_CONFIGS',
    VESSEL_REPORT_SET_CONFIGS: 'VESSEL_REPORT_SET_CONFIGS',
    VESSEL_REPORT_RESET_ALL: 'VESSEL_REPORT_RESET_ALL'
};

export function getReport(dispatch, reportId, reportType, shouldShowSuggestions = false) {
    dispatch({
        type: ActionTypes.VESSEL_REPORT_GET,
        reportId,
        reportType,
        shouldShowSuggestions
    });
}

export function getEditingOptions(dispatch) {
    dispatch({
        type: ActionTypes.VESSEL_REPORT_GET_EDITING_OPTIONS
    });
}

export function getFuelTypes(dispatch) {
    dispatch({
        type: ActionTypes.VESSEL_REPORT_GET_FUEL_TYPES
    });
}

export function getConsumptionTypes(dispatch, reportTypeId) {
    dispatch({
        type: ActionTypes.VESSEL_REPORT_GET_CONSUMPTION_TYPES,
        reportTypeId
    });
}

export function deleteReport(dispatch, reportId, shouldReload) {
    dispatch({
        type: ActionTypes.VESSEL_REPORT_DELETE,
        reportId,
        shouldReload
    });
}

export function updateReport(dispatch, report, shouldReload, shouldClose) {
    dispatch({
        type: ActionTypes.VESSEL_REPORT_UPDATE,
        report,
        shouldReload,
        shouldClose
    });
}

export function sendReminder(dispatch, missingReportId) {
    dispatch({
        type: ActionTypes.VESSEL_REPORT_SEND_REMINDER,
        missingReportId
    });
}

export function getConfigs(dispatch) {
    dispatch({
        type: ActionTypes.VESSEL_REPORT_GET_CONFIGS
    });
}

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.VESSEL_REPORT_RESET_ALL
    });
}
