import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
/* helpers */
import { translate } from 'utils/i18n/i18n-model';
import TimeHelper from 'utils/helpers/time-helper';
import { getClassName } from 'utils/helpers/info-helper';
/* components */
import Validation from 'components/validation/validation';
import Textarea from 'components/textarea/textarea';
/* services */
import CommentService from 'services/core-api/comment-service';
/* actions */
import { commentUpdated } from '../comment-actions';
/* styles */
import './comment.scss';

class Comment extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            comment: this.props.comment && this.props.comment.Text ? this.props.comment.Text : '',
            mode: ''
        };
    }

    handleInputChange = comment => this.setState({ comment });

    handleSubmit = () => {
        CommentService.update(this.props.resource, this.props.resourceId, this.state.comment, this.props.comment.Id)
            .then(res => {
                if (res) {
                    toast(translate('COMMENTS.COMMENT_SUCCESSFULLY_UPDATED'), { type: toast.TYPE.SUCCESS });
                    this.props.onUpdate(
                        this.props.resource, this.props.resourceId, this.props.comment.Id, this.state.comment
                    );
                    this.handleCancel(this.state.comment);
                }
            });
    };

    handleRemoveConfirm = () => {
        CommentService.remove(this.props.resource, this.props.comment.Id)
            .then(res => {
                if (res) {
                    toast(translate('COMMENTS.COMMENT_SUCCESSFULLY_DELETED'), { type: toast.TYPE.SUCCESS });
                    this.props.onUpdate(this.props.resource, this.props.resourceId, this.props.comment.Id);
                }
            });
    }

    handleCancel = (comment) => {
        this.setState({ mode: '', comment: typeof comment === 'string' ? comment : this.props.comment.Text });
        this.props.onActiveCommentIdUpdate(null);
        this.form.resetValidation();
    };

    setActiveMode = (mode) => {
        this.setState({ mode, comment: this.props.comment.Text });
        this.props.onActiveCommentIdUpdate(this.props.comment.Id);
    }

    handleEdit = () => this.setActiveMode('edit');

    handleRemove = () => this.setActiveMode('remove');

    saveFormRef = (c) => { this.form = c; }

    render() {
        const isFormActive = this.props.comment.Id === this.props.activeCommentId;
        const isEditable = (this.props.canUpdateComments && this.props.comment.UserId === this.props.userId)
            || this.props.canUpdateAllComments;
        const commentConfirmClass = getClassName('sten-comment__confirm flex', {
            'sten-comment__confirm--active': isFormActive && this.state.mode && this.state.mode === 'remove'
        });
        const commentEditFormClass = getClassName('sten-comment__edit flex', {
            'sten-comment__edit--active': isFormActive && this.state.mode && this.state.mode === 'edit'
        });
        let commentInfoRow;
        if (this.props.comment && this.props.comment.Author) {
            const commentModifiedDate = TimeHelper.getFormatted(this.props.comment.DateModified, { time: true });
            commentInfoRow = (
                <h6 className="text-secondary flex-row">
                    <div className="flex-grow">{this.props.comment.Author}</div>
                    <div className="flex-shrink text-nowrap">{commentModifiedDate}</div>
                </h6>
            );
        }
        return (
            <div className="sten-comment flex">
                <Validation.Form className={commentEditFormClass} onSubmit={this.handleSubmit} ref={this.saveFormRef}>
                    <div className="flex-grow flex-row flex-center">
                        <div className="flex-grow">
                            <Validation.Wrapper
                                hintsOnHover
                                validations={{
                                    required: true,
                                    maxLength: 1024,
                                    differsFrom: {
                                        value: this.props.comment.Text,
                                        label: 'old comment'
                                    }
                                }}
                            >
                                <Textarea
                                    rows={1}
                                    name="comment"
                                    autoComplete="off"
                                    value={this.state.comment}
                                    onChange={this.handleInputChange}
                                />
                            </Validation.Wrapper>
                        </div>
                        <div className="flex-shrink">
                            <Validation.Button className="btn-link icon icon-check" />
                        </div>
                        <div className="flex-shrink">
                            <button
                                type="button"
                                onClick={this.handleCancel}
                                className="btn-link icon icon-close"
                            />
                        </div>
                    </div>
                </Validation.Form>
                <div className="sten-comment__value">
                    <div className="flex-row flex-center">
                        <div className="flex-grow">
                            {commentInfoRow}
                            <div className="text-ellipsis">{this.props.comment.Text}</div>
                        </div>
                        {isEditable && (
                            <React.Fragment>
                                <div className="flex-shrink flex flex-center">
                                    <button className="btn-link icon icon-edit" onClick={this.handleEdit} />
                                </div>
                                <div className="flex-shrink flex flex-center">
                                    <button className="btn-link icon icon-delete" onClick={this.handleRemove} />
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <div className={commentConfirmClass}>
                    <div className="flex-row flex-center flex-grow">
                        <div className="flex-grow">{translate('COMMENTS.REMOVE_COMMENT')}</div>
                        <div className="flex-shrink">
                            <button className="btn-link icon icon-check" onClick={this.handleRemoveConfirm} />
                        </div>
                        <div className="flex-shrink">
                            <button className="btn-link icon icon-close" onClick={this.handleCancel} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Comment.propTypes = {
    activeCommentId: PropTypes.number,
    canUpdateComments: PropTypes.bool.isRequired,
    canUpdateAllComments: PropTypes.bool.isRequired,
    comment: PropTypes.objectOf(PropTypes.any),
    resource: PropTypes.string.isRequired,
    resourceId: PropTypes.number.isRequired,
    onActiveCommentIdUpdate: PropTypes.func,
    onUpdate: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired
};

Comment.defaultProps = {
    activeCommentId: null,
    comment: '',
    onActiveCommentIdUpdate: undefined
};

function mapStateToProps(state) {
    return {
        userId: state.userReducer.user.UserId
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdate: (resource, resourceId, commentId, comment) =>
            commentUpdated(dispatch, resource, resourceId, commentId, comment)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Comment);
