import React from 'react';
import PropTypes from 'prop-types';
/* styles */
import './tab-selector.scss';

class TabSelector extends React.PureComponent {
    render() {
        let tabs;
        let hasIcons = false;
        if (this.props.tabItems) {
            tabs = this.props.tabItems.map((tabItem) => {
                let tabClasses = 'sten-tab-selector__tab';
                let iconClasses = 'sten-tab-selector__icon icon';
                if (tabItem.disabled) {
                    tabClasses += ' sten-tab-selector__tab--disabled';
                }
                if (tabItem.id === this.props.activeTabId) {
                    tabClasses += ' sten-tab-selector__tab--active';
                }
                if (tabItem.icon) {
                    hasIcons = true;
                    tabClasses += ' sten-tab-selector__tab--icon';
                    iconClasses += ` ${tabItem.icon}`;
                }
                const clickHandler = !tabItem.disabled && this.props.onChange
                    ? this.props.onChange.bind(this, tabItem.id)
                    : undefined;
                return (
                    <button
                        key={tabItem.id}
                        onClick={clickHandler}
                        className={tabClasses}
                        title={tabItem.hoverLabel || ''}
                    >
                        {tabItem.icon && (
                            <span className={iconClasses} />
                        )}
                        <span className="sten-tab-selector__title">{tabItem.title}</span>
                    </button>
                );
            });
        }
        let classNames = 'sten-tab-selector';
        if (hasIcons) {
            classNames += ' sten-tab-selector--with-icons';
        }
        if (this.props.className) {
            classNames += ` ${this.props.className}`;
        }
        return (
            <div className={classNames}>
                {tabs}
            </div>
        );
    }
}

TabSelector.propTypes = {
    activeTabId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    tabItems: PropTypes.arrayOf(PropTypes.shape({
        disabled: PropTypes.bool,
        hoverLabel: PropTypes.string,
        icon: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        title: PropTypes.string.isRequired
    })).isRequired
};

TabSelector.defaultProps = {
    className: ''
};

export default TabSelector;
