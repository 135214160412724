import React from 'react';
import PropTypes from 'prop-types';
/* components */
import TrendIcon from 'components/trend-icon/trend-icon';
/* styles */
import './info-card.scss';

export default class FleetDashboardInfoCard extends React.PureComponent {
    getFooterContent = () => {
        if (this.props.footerContent && this.props.footerContent.length) {
            return this.props.footerContent.map((content, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <p key={index} className="sten-fleet-dashboard-info-card__label">
                    {content.label || ''}
                    {content.value && <span>{` ${content.value}`}</span>}
                </p>
            ));
        }
        return null;
    };

    render() {
        return (
            <div className="sten-fleet-dashboard-info-card">
                <h3 className="sten-fleet-dashboard-info-card__header">{this.props.title}</h3>
                <div className="sten-fleet-dashboard-info-card__body flex-row flex-center">
                    {typeof this.props.trendIconValue === 'number' && (
                        <div className="flex-shrink">
                            <div
                                title={this.props.trendIconDescription}
                                className="sten-fleet-dashboard-info-card__trend"
                            >
                                <TrendIcon
                                    value={this.props.trendIconValue}
                                    breakpoints={this.props.trendIconBreakpoints}
                                />
                            </div>
                        </div>
                    )}
                    <div className="sten-fleet-dashboard-info-card__value flex-grow">{this.props.value}</div>
                </div>
                <div className="sten-fleet-dashboard-info-card__footer">
                    {this.getFooterContent()}
                </div>
            </div>
        );
    }
}

FleetDashboardInfoCard.propTypes = {
    footerContent: PropTypes.arrayOf(PropTypes.object).isRequired,
    title: PropTypes.string,
    trendIconBreakpoints: PropTypes.arrayOf(PropTypes.number),
    trendIconDescription: PropTypes.string,
    trendIconValue: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

FleetDashboardInfoCard.defaultProps = {
    trendIconBreakpoints: undefined,
    trendIconDescription: '',
    trendIconValue: null,
    title: ''
};
