import { takeEvery, put, select, all } from 'redux-saga/effects';
/* utils */
import { sortByProp } from 'utils/helpers/array-helper';
/* actions */
import { ActionTypes } from './terminal-info-actions';
import { ActionTypes as CommentActionTypes } from 'components/comments/comment-actions';
import { ActionTypes as BerthActionTypes } from '../berth/berth-info-actions';
/* services */
import CommentService from 'services/core-api/comment-service';
import TerminalsService from 'services/core-api/terminals-service';

function* getComments(action, saveState = true) {
    const permissions = yield select(state => state.userReducer.permissions);
    if (!permissions || !permissions.GetAllPortComments) {
        return null;
    }
    const comments = yield CommentService.get('terminal', action.terminalId);
    if (saveState) {
        yield put({ type: ActionTypes.TERMINAL_INFO_SET_COMMENTS, comments });
    }
    return comments;
}

function* updateComments(action) {
    const isMounted = yield select(state => state.terminalInfoReducer.isMounted);
    if (isMounted && action.resource === 'terminal') {
        yield getComments({ terminalId: action.resourceId });
    }
}

function* getBerths(terminalId) {
    const terminals = yield TerminalsService.getBerths(terminalId);
    if (terminals && terminals.length) {
        terminals.sort(sortByProp('TerminalName'));
    }
    return terminals;
}

function* getResources(action) {
    const res = yield all({
        terminal: TerminalsService.getTerminalById(action.terminalId),
        comments: getComments(action, false),
        berths: getBerths(action.terminalId)
    });
    yield put({ ...res, type: ActionTypes.TERMINAL_INFO_SET_RESOURCES });
}

function* handleBerthUpdate(action) {
    const isMounted = yield select(state => state.terminalInfoReducer.isMounted);
    if (isMounted && action.berth && action.berth.TerminalId) {
        const berths = yield getBerths(action.berth.TerminalId);
        yield put({ type: ActionTypes.TERMINAL_INFO_SET_BERTHS, berths });
    }
}

export default function* terminalInfoSaga() {
    yield takeEvery(ActionTypes.TERMINAL_INFO_GET_RESOURCES, getResources);
    yield takeEvery(CommentActionTypes.COMMENT_UPDATED, updateComments);
    yield takeEvery(BerthActionTypes.BERTH_INFO_UPDATED, handleBerthUpdate);
}
