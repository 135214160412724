import { takeEvery, put, select } from 'redux-saga/effects';
/* actions */
import { ActionTypes } from './segment-actions';
/* Services */
import ConfigService from 'services/config-api/config-service';
import LocalStorageService from 'services/local-storage-service';
import EnergyManagementService from 'services/core-api/energy-management-service';
import { omitObjectKeys } from 'utils/helpers/info-helper';
/* Mocks */
import { mockedKpiBasicSegment, mockedKpiAdvancedSegment } from '../mocks';

function* getBasicData(action) {
    let filters = action.filters;
    if (!filters) {
        filters = yield select(state => state.energyManagementKPIReducer.filters);
    }
    const selectedRangeEnd = new Date(Date.UTC(filters.selectedRangeEnd.year, filters.selectedRangeEnd.month, 1) - 1);
    const params = {
        DateRangeOption: filters.selectedRangeOption.Id,
        EndDate: selectedRangeEnd.toISOString(),
        Imos: []
    };
    if (filters.selectedVesselsBySegment[action.segmentId]) {
        Object.keys(filters.selectedVesselsBySegment[action.segmentId]).forEach(imo => {
            params.Imos.push(imo);
        });
    }
    const basicSegmentData = LocalStorageService.isDebugModeActive()
        || ConfigService.featureToggles.showKpiWithMockedData
        ? mockedKpiBasicSegment
        : yield EnergyManagementService.KPI.Segment.getBasicData(params);

    const keysToOmit = ['Segments'];
    if (basicSegmentData?.EnergyEfficiencyDataList) {
        const EEListFormatted = basicSegmentData.EnergyEfficiencyDataList.map(data =>
            omitObjectKeys(data, keysToOmit));
        yield put({
            type: ActionTypes.ENERGY_MANAGEMENT_KPI_SEGMENT_SET_BASIC_DATA,
            basicSegmentData: {
                ...basicSegmentData,
                EnergyEfficiencyDataList: EEListFormatted
            }
        });
    } else {
        yield put({
            type: ActionTypes.ENERGY_MANAGEMENT_KPI_SEGMENT_SET_BASIC_DATA,
            basicSegmentData
        });
    }
}

function* getAdvancedData(action) {
    let filters = action.filters;
    if (!filters) {
        filters = yield select(state => state.energyManagementKPIReducer.filters);
    }
    const selectedRangeEnd = new Date(Date.UTC(filters.selectedRangeEnd.year, filters.selectedRangeEnd.month, 1) - 1);
    const params = {
        DateRangeOption: filters.selectedRangeOption.Id,
        EndDate: selectedRangeEnd.toISOString(),
        Imos: []
    };
    if (filters.selectedVesselsBySegment[action.segmentId]) {
        params.Imos = Object.keys(filters.selectedVesselsBySegment[action.segmentId]);
    }
    const advancedSegmentData = LocalStorageService.isDebugModeActive()
        || ConfigService.featureToggles.showKpiWithMockedData
        ? mockedKpiAdvancedSegment
        : yield EnergyManagementService.KPI.Segment.getAdvancedData(params);
    yield put({
        type: ActionTypes.ENERGY_MANAGEMENT_KPI_SEGMENT_SET_ADVANCED_DATA,
        advancedSegmentData
    });
}

export default function* energyManagementKPISegmentSaga() {
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_KPI_SEGMENT_GET_BASIC_DATA, getBasicData);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_KPI_SEGMENT_GET_ADVANCED_DATA, getAdvancedData);
}
