export const ActionTypes = {
    ADMIN_USER_MANAGEMENT_SET_COMPANIES: 'ADMIN_USER_MANAGEMENT_SET_COMPANIES',
    ADMIN_USER_MANAGEMENT_GET_COMPANIES: 'ADMIN_USER_MANAGEMENT_GET_COMPANIES',
    ADMIN_USER_MANAGEMENT_GET_USERS: 'ADMIN_USER_MANAGEMENT_GET_USERS',
    ADMIN_USER_MANAGEMENT_SET_USERS: 'ADMIN_USER_MANAGEMENT_SET_USERS',
    ADMIN_USER_MANAGEMENT_GET_DEPARTMENTS: 'ADMIN_USER_MANAGEMENT_GET_DEPARTMENTS',
    ADMIN_USER_MANAGEMENT_SET_DEPARTMENTS: 'ADMIN_USER_MANAGEMENT_SET_DEPARTMENTS',
    ADMIN_USER_MANAGEMENT_GET_OPTIONS: 'ADMIN_USER_MANAGEMENT_GET_OPTIONS',
    ADMIN_USER_MANAGEMENT_SET_OPTIONS: 'ADMIN_USER_MANAGEMENT_SET_OPTIONS',
    ADMIN_USER_MANAGEMENT_SET_NEW_DEPARTMENT: 'ADMIN_USER_MANAGEMENT_SET_NEW_DEPARTMENT',
    ADMIN_USER_MANAGEMENT_TOGGLE_UPDATE_MODE: 'ADMIN_USER_MANAGEMENT_TOGGLE_UPDATE_MODE',
    ADMIN_USER_MANAGEMENT_SET_FETCHING_PROGRESS: 'ADMIN_USER_MANAGEMENT_SET_FETCHING_PROGRESS',
    ADMIN_USER_MANAGEMENT_RESET: 'ADMIN_USER_MANAGEMENT_RESET',
    ADMIN_USER_MANAGEMENT_REFRESH_DATA: 'ADMIN_USER_MANAGEMENT_REFRESH_DATA'
};


export function refreshAdminUserManagementData(dispatch) {
    dispatch({
        type: ActionTypes.ADMIN_USER_MANAGEMENT_REFRESH_DATA
    });
}

export function getCompanies(dispatch, searchCriteria) {
    dispatch({
        type: ActionTypes.ADMIN_USER_MANAGEMENT_GET_COMPANIES,
        searchCriteria
    });
}

export function getOptions(dispatch) {
    dispatch({
        type: ActionTypes.ADMIN_USER_MANAGEMENT_GET_OPTIONS
    });
}

export function getUsers(dispatch, appliedOptions, overrideCurrent) {
    dispatch({
        type: ActionTypes.ADMIN_USER_MANAGEMENT_GET_USERS,
        appliedOptions,
        overrideCurrent
    });
}

export function setAsNewDepartment(dispatch, departmentName) {
    dispatch({
        type: ActionTypes.ADMIN_USER_MANAGEMENT_SET_NEW_DEPARTMENT,
        departmentName
    });
}

export function toggleUpdateMode(dispatch, userId, isActive) {
    dispatch({
        type: ActionTypes.ADMIN_USER_MANAGEMENT_TOGGLE_UPDATE_MODE,
        userId,
        isActive
    });
}

export function reset(dispatch) {
    dispatch({
        type: ActionTypes.ADMIN_USER_MANAGEMENT_RESET
    });
}
