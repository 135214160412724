import xml2js from 'xml2js';
import { toast } from 'react-toastify';
import CoreApi from './core-api';
/* utils */
import { t } from 'utils/i18n/i18n-model';

let nauticalErrorToastId = null;

const handleNauticalChartError = () => {
    if (!toast.isActive(nauticalErrorToastId)) {
        nauticalErrorToastId = toast(t('ERROR.NAVIGATIONAL_CHARTS_UNAVAILABLE'), { type: toast.TYPE.ERROR });
    }
};

class NauticalChartsService {
    constructor() {
        this.resourceUrl = 'NauticalCharts';
    }

    getCapabilities() {
        return CoreApi.get(
            `${this.resourceUrl}?SERVICE=WMS&VERSION=1.3.0&REQUEST=GetCapabilities`,
            null,
            false,
            { preventErrorHandling: true }
        ).then(
            res => xml2js.parseStringPromise(res.data, { explicitArray: false, attrkey: 'Attr' }),
            handleNauticalChartError
        );
    }

    getMap(params) {
        return CoreApi.get(`${this.resourceUrl}${params}`, null, true, {
            responseType: 'arraybuffer',
            preventErrorHandling: true
        }).then(res => {
            const arrayBufferView = new Uint8Array(res.data);
            const blob = new Blob([arrayBufferView], { type: 'image/png' });
            const urlCreator = window.URL || window.webkitURL;
            return urlCreator.createObjectURL(blob);
        }, handleNauticalChartError);
    }
}

export default new NauticalChartsService();
