import { ActionTypes } from './nautical-charts-actions';

const emptyArray = [];

const initialState = {
    availableLayers: emptyArray
};

export default function nauticalChartsReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.NAUTICAL_CHARTS_SET_AVAILABLE_LAYERS: {
        return {
            ...state,
            availableLayers: action.payload || emptyArray
        };
    }
    default:
        return state;
    }
}
