import React from 'react';
import PropTypes from 'prop-types';

class ValidationValue extends React.PureComponent {
    render() {
        return this.props.children;
    }
}

ValidationValue.propTypes = {
    children: PropTypes.node,
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object, PropTypes.array, PropTypes.bool])
};

ValidationValue.defaultProps = {
    children: null,
    value: null
};

export default ValidationValue;
