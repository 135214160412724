import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { getObjectProp } from 'utils/helpers/info-helper';
import { fleetOptionRenderer } from 'utils/helpers/fleet-helper';
/* actions */
import { deleteLeg, getVoyages, setFiltersAndUpdate } from '../weather-routing-actions';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import Checkbox from 'components/checkbox/checkbox';
import EmptyContent from 'components/empty-content/empty-content';
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
import Menu, { MenuItem } from 'components/menu/menu';
import Modal from 'components/modal/modal';
import Select from 'components/select/select';
import ScrollArea from 'components/scroll-area/scroll-area';
import VesselSelect from 'components/vessel-select/vessel-select';
/* constants */
import { legActionColumn, legColumns, voyageFields } from '../weather-routing-constants';
/* styles */
import './weather-routing-overview.scss';

const scrollEndThreshold = 100;

const minTableWidth = legActionColumn.width + legColumns.reduce((res, col) => res + col.width, 0);

export class WeatherRoutingOverview extends React.PureComponent {
    state = {
        availableForRouting: false,
        legDeleteModalData: null,
        selectedVessel: null,
        selectedCompanyFleets: [],
        selectedCustomFleets: [],
        prevFilters: null
    };

    static getDerivedStateFromProps(props, state) {
        if (props.filters !== state.prevFilters) {
            return {
                ...state,
                prevFilters: props.filters,
                availableForRouting: props.filters.AvailableForRouting,
                selectedVessel: props.filters.Vessel,
                selectedCompanyFleets: props.filters.CompanyFleets,
                selectedCustomFleets: props.filters.CustomFleets
            };
        }
        return null;
    }

    saveRef = c => { this.scrollArea = c; };

    scrollHandler = value => {
        if (value.realHeight - value.topPosition < value.containerHeight + scrollEndThreshold) {
            this.props.getVoyages(true);
        }
    };

    handleCompanyFleetChange = selectedCompanyFleets => {
        this.setState({ selectedCompanyFleets });
    }

    handleCustomFleetChange = selectedCustomFleets => {
        this.setState({ selectedCustomFleets });
    }

    handleVesselChange = selectedVessel => {
        this.setState({ selectedVessel });
    }

    toggleAvailableForRouting = () => {
        this.setState((prevState) => ({ availableForRouting: !prevState.availableForRouting }));
    };

    isArrayOfObjectsDifferent = (arrA, arrB) =>
        !(arrA.length === arrB.length && arrA.every(a => arrB.find(b => b === a)));

    areFiltersChanged = () => {
        if (this.isArrayOfObjectsDifferent(this.state.selectedCompanyFleets, this.props.filters.CompanyFleets)) {
            return true;
        }
        if (this.isArrayOfObjectsDifferent(this.state.selectedCustomFleets, this.props.filters.CustomFleets)) {
            return true;
        }
        return this.state.availableForRouting !== this.props.filters.AvailableForRouting
            || this.state.selectedVessel !== this.props.filters.Vessel;
    };

    applyFilters = () => {
        this.props.setFiltersAndUpdate({
            AvailableForRouting: this.state.availableForRouting,
            CompanyFleets: this.state.selectedCompanyFleets,
            CustomFleets: this.state.selectedCustomFleets,
            Vessel: this.state.selectedVessel
        });
        if (this.scrollArea) {
            this.scrollArea.scrollArea.scrollTop();
        }
    }

    linkToLeg = (leg) => ({ pathname: `${leg.Id}` });

    linkToNewRoutingRequest = (voyage) => this.props.router.getLinkTo({
        query: { voyage: voyage.Id, vessel: voyage.Vessel.Imo, requestEdit: true }
    });

    linkToWeatherPerformance = (voyage) => this.props.router.getLinkTo({
        pathname: appRoutes.EnergyManagement.Reports,
        query: {
            emReportType: 13,
            emVesselImo: voyage.Vessel.Imo,
            emVoyageId: voyage.Id
        }
    });

    linkToCounterPvar = (voyage) => this.props.router.getLinkTo({
        query: {
            voyage: voyage.Id,
            vessel: voyage.Vessel.Imo,
            showCounterPvar: true
        }
    });

    linkToViewRequest = (requestId) => this.props.router.getLinkTo({ query: { requestId } });

    linkToViewVoyageOnMap = (voyage) => this.props.router.getLinkTo({
        pathname: appRoutes.Map,
        query: { vessel: voyage.Vessel.Imo, voyage: voyage.Id }
    });

    renderValue = (item, params) => {
        if (params.renderValue) {
            return params.renderValue(item);
        }

        let value;
        if (params.formatValue) {
            value = params.formatValue(item);
        } else {
            value = getObjectProp(item, params.prop);
        }
        return value || '-';
    };

    showDeleteModal = (legId) => () => this.setState({ legDeleteModalData: legId });

    handleLegDeleteModalClose = () => this.setState({ legDeleteModalData: null });

    handleLegDeleteModalConfirm = () => {
        this.props.deleteLeg(this.state.legDeleteModalData);
        this.setState({ legDeleteModalData: null });
    };

    renderLegDeleteModal = () => {
        const { legDeleteModalData } = this.state;
        return (
            <Modal.Delete
                isOpen={!!legDeleteModalData}
                onCancel={this.handleLegDeleteModalClose}
                onDelete={this.handleLegDeleteModalConfirm}
                title={t('WEATHER_ROUTING.OVERVIEW.LEG_DELETE_TITLE')}
            >
                {t('WEATHER_ROUTING.OVERVIEW.LEG_DELETE_CONTENT')}
            </Modal.Delete>
        );
    };

    renderContextMenu = (leg) => {
        const isDeleteDisabled = !!leg.LatestSposRoute || (leg.Request && leg.Request.Intention.Name !== 'NotIntended');
        const deleteButtonTitle = isDeleteDisabled ? t('WEATHER_ROUTING.OVERVIEW.DELETE_LEG_DISABLED_TITLE') : '';
        return (
            <Menu stopPropagation>
                {leg.Request && (
                    <MenuItem icon="icon-next-leg-report" to={this.linkToViewRequest(leg.Request.Id)}>
                        {t('WEATHER_ROUTING.SHOW_ORIGINAL_REQUEST')}
                    </MenuItem>
                )}
                <MenuItem
                    icon="icon-list"
                    to={this.linkToLeg(leg)}
                >
                    {t('WEATHER_ROUTING.OVERVIEW.MANAGE_REQUESTS')}
                </MenuItem>
                <MenuItem
                    icon="icon-delete"
                    isDisabled={isDeleteDisabled}
                    onClick={this.showDeleteModal(leg.Id)}
                    title={deleteButtonTitle}
                >
                    {t('WEATHER_ROUTING.OVERVIEW.DELETE_LEG')}
                </MenuItem>
            </Menu>
        );
    };

    renderLegs = (voyage) => {
        return (voyage.Legs.length && (
            <FixedHeaderTable
                className="sten-weather-routing-overview__table"
                contentStyle={{ minWidth: `${minTableWidth}rem` }}
                withHeaderColumn
                withHeaderColumnSeparator
            >
                <table className="sten-table sten-table--sm">
                    <thead>
                        <tr>
                            <th style={{ width: `${legActionColumn.width}rem` }}>{legActionColumn.label}</th>
                            {legColumns.map((col, index) =>
                                /* eslint-disable-next-line react/no-array-index-key */
                                <th key={index} style={{ minWidth: `${col.width}rem` }}>{col.label}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {voyage.Legs.map(leg => (
                            /* eslint-disable-next-line react/no-array-index-key */
                            <tr key={leg.Id}>
                                <td className="sten-weather-routing-overview__link-cell">
                                    <Link
                                        className="flex-row flex--wrap flex-center"
                                        to={this.linkToLeg(leg)}
                                        title={t('WEATHER_ROUTING.OVERVIEW.MANAGE_REQUESTS')}
                                    >
                                        {this.renderValue(leg, legActionColumn)}
                                        <div className="sten-content__vertical-separator" />
                                        {this.renderContextMenu(leg)}
                                    </Link>
                                </td>
                                {legColumns.map((col, index) => (
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    <td key={index}>{this.renderValue(leg, col)}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </FixedHeaderTable>
        ));
    };

    renderVoyageMenu = (voyage) => {
        return (
            <Menu stopPropagation>
                <MenuItem icon="icon-request-routing" to={this.linkToNewRoutingRequest(voyage)}>
                    {t('WEATHER_ROUTING.OVERVIEW.REQUEST_ROUTING')}
                </MenuItem>
                <MenuItem icon="icon-position" to={this.linkToViewVoyageOnMap(voyage)}>
                    {t('WEATHER_ROUTING.OVERVIEW.SHOW_VOYAGE_ON_MAP')}
                </MenuItem>
                {ConfigService.featureToggles.showWeatherRoutingWeatherPerformance && (
                    <MenuItem icon="icon-voyage-performance" to={this.linkToWeatherPerformance(voyage)}>
                        {t('WEATHER_ROUTING.OVERVIEW.WEATHER_PERFORMANCE')}
                    </MenuItem>
                )}
                {ConfigService.featureToggles.showCounterPvar && (
                    <MenuItem icon="icon-counter-pvar" to={this.linkToCounterPvar(voyage)}>
                        {t('WEATHER_ROUTING.OVERVIEW.COUNTER_PVAR')}
                    </MenuItem>
                )}
            </Menu>
        );
    };

    renderVoyageParams = (voyage) => (
        <div className="sten-weather-routing-overview__voyage-header">
            <div className="flex-grow flex-row flex--wrap">
                {voyageFields.map((field, index) => (
                    <div
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={index}
                        className="form-section-inline"
                        style={{ minWidth: `${field.width}rem` }}
                    >
                        {field.label && <label className="label">{field.label}</label>}
                        <label className="label label--value">{this.renderValue(voyage, field)}</label>
                    </div>
                ))}
            </div>
            <div className="flex-shrink flex flex-center">
                {voyage.IsAvailableForRouting && (
                    <React.Fragment>
                        <div className="sten-badge sten-badge--warning text-nowrap">
                            {t('WEATHER_ROUTING.OVERVIEW.AVAILABLE_FOR_ROUTING')}
                        </div>
                        <div className="sten-content__vertical-separator" />
                    </React.Fragment>
                )}
                {this.renderVoyageMenu(voyage)}
            </div>
        </div>
    );

    linkToRequestRouting = () => this.props.router.getLinkTo({ query: { requestEdit: true } });

    render() {
        return (
            <React.Fragment>
                <div className="sten-content sten-weather-routing-overview">
                    {this.renderLegDeleteModal()}
                    <div className="sten-content__header flex-row">
                        <h1 className="flex-grow text-uppercase">{t('WEATHER_ROUTING.TITLE')}</h1>
                        <div className="flex-shrink">
                            <Link
                                className="btn btn--link btn--primary text-nowrap"
                                to={this.linkToRequestRouting()}
                                title={t('WEATHER_ROUTING.REQUEST_BUTTON_TITLE')}
                            >
                                <span className="btn__icon icon icon-request-routing" />
                                {t('WEATHER_ROUTING.REQUEST_ROUTING')}
                            </Link>
                        </div>
                    </div>
                    <div className="sten-weather-routing-overview__content-top">
                        <div className="sten-content__section flex-row flex-row--md flex-end">
                            <div className="col-sm-24">
                                <div className="flex-row flex--wrap flex-end">
                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                        <label className="label">{t('WEATHER_ROUTING.FILTERS.COMPANY_FLEET')}</label>
                                        <Select
                                            name="selectedCompanyFleets"
                                            searchable
                                            multiple
                                            clearable
                                            valueKey="Id"
                                            labelKey="Name"
                                            options={this.props.companyFleets}
                                            placeholder={t('WEATHER_ROUTING.FILTERS.COMPANY_FLEET_PLACEHOLDER')}
                                            value={this.state.selectedCompanyFleets}
                                            onChange={this.handleCompanyFleetChange}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                        <label className="label">{t('WEATHER_ROUTING.FILTERS.CUSTOM_FLEET')}</label>
                                        <Select
                                            name="selectedCustomFleets"
                                            searchable
                                            multiple
                                            clearable
                                            valueKey="VesselGroupId"
                                            labelKey="VesselGroupName"
                                            options={this.props.customFleets}
                                            optionRenderer={fleetOptionRenderer}
                                            placeholder={t('WEATHER_ROUTING.FILTERS.CUSTOM_FLEET_PLACEHOLDER')}
                                            value={this.state.selectedCustomFleets}
                                            onChange={this.handleCustomFleetChange}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                        <label className="label">{t('WEATHER_ROUTING.FILTERS.VESSEL')}</label>
                                        <VesselSelect
                                            clearable
                                            value={this.state.selectedVessel}
                                            onChange={this.handleVesselChange}
                                        />
                                    </div>
                                    <div className="col-md-6 col-sm-12 col-xs-12">
                                        <Checkbox
                                            className="sten-checkbox--form sten-checkbox--align"
                                            isChecked={this.state.availableForRouting}
                                            onChange={this.toggleAvailableForRouting}
                                        >
                                            {t('WEATHER_ROUTING.FILTERS.VOYAGES_AVAILABLE_FOR_ROUTING')}
                                        </Checkbox>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-24 col-md-4 col-lg-3  col-pad--sm">
                                <button
                                    className="btn btn--primary col-24"
                                    disabled={!this.areFiltersChanged()}
                                    onClick={this.applyFilters}
                                >
                                    {t('GLOBAL.APPLY')}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="sten-weather-routing-overview__content-bottom">
                        {!this.props.voyages.length
                            ? <EmptyContent>{t('EMPTY_CONTENT.NO_DATA_MESSAGE')}</EmptyContent>
                            : (
                                <ScrollArea
                                    onScroll={this.scrollHandler}
                                    className="sten-weather-routing-overview__voyages"
                                    ref={this.saveRef}
                                >
                                    {this.props.voyages.map(voyage => (
                                    /* eslint-disable-next-line react/no-array-index-key */
                                        <div key={voyage.Id} className="sten-weather-routing-overview__voyage">
                                            {this.renderVoyageParams(voyage)}
                                            {voyage.Legs.length > 0 && this.renderLegs(voyage)}
                                        </div>
                                    ))}
                                </ScrollArea>
                            )
                        }
                    </div>
                </div>
                <Outlet />
            </React.Fragment>
        );
    }
}

WeatherRoutingOverview.propTypes = {
    companyFleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    customFleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    deleteLeg: PropTypes.func.isRequired,
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    getVoyages: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    setFiltersAndUpdate: PropTypes.func.isRequired,
    voyages: PropTypes.arrayOf(PropTypes.object).isRequired
};

function mapStateToProps(state) {
    return {
        companyFleets: state.weatherRoutingReducer.companyFleets,
        customFleets: state.weatherRoutingReducer.customFleets,
        filters: state.weatherRoutingReducer.filters,
        voyages: state.weatherRoutingReducer.voyages
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deleteLeg: (legId) => deleteLeg(dispatch, legId),
        getVoyages: (shouldFetchMore) => getVoyages(dispatch, shouldFetchMore),
        setFiltersAndUpdate: (filters) => setFiltersAndUpdate(dispatch, filters)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WeatherRoutingOverview));
