import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* types */
import { TWeatherRoutingQueryParams } from 'pages/user-pages/weather-routing/weather-routing-types';
/* utils */
import { getClassName } from 'utils/helpers/info-helper';
import { t } from 'utils/i18n/i18n-model';
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import {
    getCounterPvarList,
    getCounterPvarValidationData,
    getSelectedVessel,
    getSelectedVoyage
} from './counter-pvar-actions';
import { toggleActivePanel } from 'components/right-side-bar/right-side-bar-actions';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
/* styles */
import './counter-pvar.scss';

export class CounterPvar extends React.PureComponent {
    componentDidMount() {
        const { vessel, voyage } = this.props.queryParams;
        if (vessel && voyage) {
            this.props.getSelectedVessel(vessel);
            this.props.getSelectedVoyage(voyage, vessel);
            this.props.getCounterPvarList(voyage);
            this.props.getCounterPvarValidationData(voyage);
        }
    }

    componentDidUpdate(prevProps) {
        const { vessel, voyage } = this.props.queryParams;
        if ((prevProps.queryParams.vessel !== vessel && vessel)
            || (prevProps.queryParams.voyage !== voyage && voyage)) {
            if (prevProps.queryParams.vessel !== vessel) {
                this.props.getSelectedVessel(vessel);
                this.props.getSelectedVoyage(voyage, vessel);
            } else {
                this.props.getSelectedVoyage(voyage);
            }
            this.props.getCounterPvarList(voyage);
            this.props.getCounterPvarValidationData(voyage);
        }
        if (prevProps.counterPvarList && prevProps.counterPvarList !== this.props.counterPvarList) {
            this.props.toggleActivePanel('counter-pvar-form', false);
        }
    }

    linkToEditVessel = () => ({
        pathname: `${appRoutes.Admin.VesselManagement}/edit/${this.props.selectedVessel.IMO}`,
        search: ''
    });

    linkToCharterParty = () => this.props.router.getLinkTo({
        add: { cpEdit: true, cpVoyage: this.props.queryParams.voyage }
    });

    handleOpenCounterPvarForm = () => {
        this.props.toggleActivePanel('counter-pvar-form', true);
    }

    handleClose = () => {
        this.props.router.updateQueryParams({ remove: ['showCounterPvar', 'vessel', 'voyage'] });
    };

    renderCounterPvarList = (counterPvarList) => {
        return counterPvarList.map((cp, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="sten-counter-pvar__list-item" key={index}>
                <span className="icon icon-counter-pvar" />
                <div>
                    <label className="label label--value">
                        {`${t('COUNTER_PVAR.LIST_ITEM.VESSEL_CONDITION')}${cp.VesselCondition.Name}`}
                    </label>
                    <p className="text-secondary">
                        {t('COUNTER_PVAR.LIST_ITEM.REQUESTED_BY', {
                            name: cp.CreatedBy,
                            date: TimeHelper.getFormatted(cp.CreatedAt)
                        })}
                    </p>
                </div>
            </div>
        ));
    };

    renderContent = () => {
        const { counterPvarList, validationData } = this.props;
        const requestCounterPvarClassName = getClassName('btn btn--link btn--primary', {
            'btn--disabled': validationData
            && (validationData.VesselParticularsMissingInformation.length || !validationData.VoyageHasCharterparty)
        });
        if (!validationData.VoyageHasReports) {
            return (
                <div className="sten-content__section">
                    <div className="sten-counter-pvar__row sten-counter-pvar__validation-box">
                        {t('COUNTER_PVAR.MISSING_REPORTS')}
                    </div>
                </div>
            );
        }
        return (
            <React.Fragment>
                <div className="sten-content__section">
                    <div className="sten-counter-pvar__row">
                        <button
                            className={requestCounterPvarClassName}
                            onClick={this.handleOpenCounterPvarForm}
                        >
                            <span className="btn__icon icon icon-plus" />
                            {t('COUNTER_PVAR.REQUEST_COUNTER_PVAR')}
                        </button>
                    </div>
                    {validationData.VesselParticularsMissingInformation.length === 0
                    && !validationData.VoyageHasCharterparty
                    && (
                        <div className="sten-counter-pvar__row">
                            {t('COUNTER_PVAR.VALIDATION_FAILED_MESSAGE')}
                        </div>
                    )}
                    {validationData.VesselParticularsMissingInformation.length !== 0 && (
                        <div className="sten-counter-pvar__row
                        sten-counter-pvar__validation-box-double"
                        >
                            <div>
                                {t('COUNTER_PVAR.MISSING_VESSEL_PARTICULARS')}
                                {validationData.VesselParticularsMissingInformation.map((field, index) =>
                                // eslint-disable-next-line react/no-array-index-key
                                    <p key={index}>- {field}</p>)
                                }
                            </div>
                            <div>
                                <Link
                                    className="sten-link"
                                    to={this.linkToEditVessel()}
                                >
                                    {t('COUNTER_PVAR.LINK_TO_EDIT_VESSEL')}
                                </Link>
                            </div>
                        </div>
                    )}
                    {!validationData.VoyageHasCharterparty && (
                        <div className="sten-counter-pvar__row
                        sten-counter-pvar__validation-box-double"
                        >
                            <div>{t('COUNTER_PVAR.MISSING_CHARTERPARTY')}</div>
                            <div>
                                <Link
                                    className="sten-link"
                                    to={this.linkToCharterParty()}
                                >
                                    {t('COUNTER_PVAR.LINK_TO_CHARTER_PARTY')}
                                </Link>
                            </div>
                        </div>
                    )}
                </div>
                {counterPvarList && counterPvarList.length > 0 && this.renderCounterPvarList(counterPvarList)}
            </React.Fragment>
        );
    };

    render() {
        const { selectedVessel, selectedVoyage, validationData } = this.props;
        return (
            <div className="sten-content">
                <header className="sten-content__header">
                    <div className="flex-grow">
                        <h1 className="sten-content__title">{t('COUNTER_PVAR.COUNTER_PVAR_TITLE')}</h1>
                        {selectedVessel && selectedVoyage && (
                            <p className="sten-content__subtitle text-ellipsis text-nowrap">
                                {`${selectedVessel.Title},
                                ${selectedVoyage.VoyageNumber} ${selectedVoyage.VoyagePortInfo}`}
                            </p>
                        )}
                    </div>
                    <div className="flex-shrink">
                        <button type="button" className="btn-link icon icon-close" onClick={this.handleClose} />
                    </div>
                </header>
                <ScrollArea className="sten-content__body">
                    {selectedVessel && selectedVoyage && validationData && this.renderContent()}
                </ScrollArea>
            </div>
        );
    }
}

CounterPvar.propTypes = {
    counterPvarList: PropTypes.arrayOf(PropTypes.any),
    getCounterPvarList: PropTypes.func.isRequired,
    getCounterPvarValidationData: PropTypes.func.isRequired,
    getSelectedVessel: PropTypes.func.isRequired,
    getSelectedVoyage: PropTypes.func.isRequired,
    queryParams: TWeatherRoutingQueryParams.isRequired,
    router: TRouter.isRequired,
    selectedVessel: PropTypes.objectOf(PropTypes.any),
    selectedVoyage: PropTypes.objectOf(PropTypes.any),
    toggleActivePanel: PropTypes.func.isRequired,
    validationData: PropTypes.objectOf(PropTypes.any)
};

CounterPvar.defaultProps = {
    counterPvarList: null,
    selectedVessel: null,
    selectedVoyage: null,
    validationData: null
};

function mapStateToProps(state) {
    return {
        counterPvarList: state.counterPvarReducer.counterPvarList,
        selectedVessel: state.counterPvarReducer.selectedVessel,
        selectedVoyage: state.counterPvarReducer.selectedVoyage,
        validationData: state.counterPvarReducer.validationData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCounterPvarList: (voyageId) => getCounterPvarList(dispatch, voyageId),
        getCounterPvarValidationData: (voyageId) => getCounterPvarValidationData(dispatch, voyageId),
        getSelectedVessel: vesselImo => getSelectedVessel(dispatch, vesselImo),
        getSelectedVoyage: (voyageId, vesselImo) => getSelectedVoyage(dispatch, voyageId, vesselImo),
        toggleActivePanel: (panelName, enable) => toggleActivePanel(dispatch, panelName, enable)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CounterPvar));
