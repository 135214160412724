import { translate } from 'utils/i18n/i18n-model';

const t = (key) => translate(`OPERATORS_DIARY.FIXTURES.${key}`);

export const rowConfigs = [
    {
        id: 0,
        label: t('COMMODITY'),
        prop: 'Commodity'
    },
    {
        id: 1,
        label: t('CHARTERER'),
        prop: 'ChartererName'
    },
    {
        id: 2,
        label: t('BROKER'),
        prop: 'BrokerName'

    },
    {
        id: 3,
        label: t('LAYCAN'),
        prop: 'LayCan'
    }
];
