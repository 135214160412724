import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { getClassName } from 'utils/helpers/info-helper';

class ShowMoreButton extends React.PureComponent {
    render() {
        const { className, disabled, isMoreShown, invertArrow, onClick } = this.props;
        const btnClassName = getClassName('sten-link', className, { 'sten-link--disabled': disabled });
        const isCaretUp = invertArrow ? !isMoreShown : isMoreShown;
        const iconClass = `icon ${isCaretUp ? 'icon-caret-up' : 'icon-caret-down'}`;
        return (
            <button
                type="button"
                className={btnClassName}
                onClick={onClick}
            >
                {isMoreShown ? translate('GLOBAL.SHOW_LESS') : translate('GLOBAL.SHOW_MORE')}
                <span className={iconClass} />
            </button>
        );
    }
}

ShowMoreButton.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    invertArrow: PropTypes.bool,
    isMoreShown: PropTypes.bool,
    onClick: PropTypes.func
};

ShowMoreButton.defaultProps = {
    className: '',
    disabled: false,
    invertArrow: false,
    isMoreShown: false,
    onClick: undefined
};

export default ShowMoreButton;
