import React from 'react';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { commonFields, getNameWithSuffix } from 'components/form-fields/form-fields-helper';
import { formatNumber, hasValue } from 'utils/helpers/info-helper';
/* components */
import FuelRestrictionsTable from './charterparty-form-fuel-restrictions-table';
import Input from 'components/input/input';
import Validation from 'components/validation/validation';

export const emptyCharterparty = {
    Id: null,
    VoyageId: null,
    DateEffective: null,
    Speed: null,
    SpeedAllowance: null,
    WindSpeed: null,
    WaveHeight: null,
    CurrentType: null,
    AllowanceType: null,
    FuelRestrictions: []
};

export const sections = [
    {
        fields: [
            'DateEffective',
            'Speed',
            'WindSpeed',
            'WaveHeight',
            'CurrentType'
        ]
    }, {
        data: 'FuelRestrictions'
    }
];

// const atLeastOneConsumptionRequired = {
//     rule: (value, params, components, name) => {
//         const nameSlice = name.slice(0, name.lastIndexOf('.'));
//         return Object.keys(components)
//             .filter(key => key.indexOf(nameSlice) === 0)
//             .some(key => components[key] && components[key].state && components[key].state.value);
//     },
//     hint: () => 'At least one consumption is required'
// };

const isConsumptionRequired = (value, components, name) => {
    const splitName = name.split('.');
    const key = `${splitName[0]}.${splitName[1]}.Allowance.${splitName[3]}`;
    const relValue = components[key] && components[key].state && components[key].state.value;
    return hasValue(relValue);
};

const isAllowanceRequired = (value, components, name) => {
    const splitName = name.split('.');
    const key = `${splitName[0]}.${splitName[1]}.Consumption.${splitName[3]}`;
    const relValue = components[key] && components[key].state && components[key].state.value;
    return hasValue(relValue);
};

export const validations = {
    DateEffective: { required: true },
    DateEffectiveOffset: { required: true, timeSpan: { min: -13 * 60, max: 13 * 60 } },
    Speed: { required: true, numeric: { min: 0, max: 30, div: 0.5, divHint: 'whole or half' } },
    SpeedAllowance: { required: true, numeric: { min: 0, max: 5, div: 0.5, divHint: 'whole or half' } },
    WindSpeed: { required: true, numeric: { min: 0, max: 71 } },
    WaveHeight: { required: true, numeric: { min: 0, max: 14 } },
    CurrentType: { required: true },
    AllowanceType: { required: true },
    FuelRestrictions: {
        Allowance: {
            required: isAllowanceRequired,
            numeric: { min: 0, max: 100 }
        },
        Consumption: {
            required: isConsumptionRequired,
            numeric: { min: 0, max: 100 }
        }
    }
};

export const fieldMap = {
    DateEffective: commonFields.DateTime(
        t('CHARTERPARTY_FORM.DATE_EFFECTIVE'),
        'DateEffective',
        'DateEffectiveOffset',
        validations.DateEffective,
        validations.DateEffectiveOffset
    ),
    Speed: {
        label: <label className="label">{t('CHARTERPARTY_FORM.SPEED')}</label>,
        view: data => {
            return (
                <label className="label label--value">
                    {`${formatNumber(data.Speed, 1)} (± ${formatNumber(data.SpeedAllowance, 1)}) ${t('UNITS.SPEED')}`}
                </label>
            );
        },
        edit: (data, handleChange, params) => {
            return (
                <div className="row">
                    <div className="col-12">
                        <Validation.Wrapper hintsOnHover validations={validations.Speed}>
                            <Input
                                name={getNameWithSuffix('Speed', params)}
                                value={data.Speed}
                                onChange={handleChange('Speed')}
                                suffix={t('UNITS.SPEED')}
                            />
                        </Validation.Wrapper>
                    </div>
                    <div className="col-12">
                        <Validation.Wrapper hintsOnHover validations={validations.SpeedAllowance}>
                            <Input
                                prefix="±"
                                name={getNameWithSuffix('SpeedAllowance', params)}
                                value={data.SpeedAllowance}
                                onChange={handleChange('SpeedAllowance')}
                                suffix={t('UNITS.SPEED')}
                            />
                        </Validation.Wrapper>
                    </div>
                </div>
            );
        }
    },
    WindSpeed: commonFields.Number(
        t('CHARTERPARTY_FORM.WIND_SPEED'),
        'WindSpeed',
        1,
        validations.WindSpeed,
        t('UNITS.WIND_SPEED')
    ),
    WaveHeight: commonFields.Number(
        t('CHARTERPARTY_FORM.WAVE_HEIGHT'),
        'WaveHeight',
        1,
        validations.WaveHeight,
        t('UNITS.WAVE_HEIGHT')
    ),
    CurrentType: commonFields.Select(
        t('CHARTERPARTY_FORM.CURRENTS'),
        'CurrentType',
        'editingOptions.currentTypes',
        validations.CurrentType,
        { valueKey: 'Id', labelKey: 'DisplayName', clearable: true }
    )
};

export const dataMap = {
    FuelRestrictions: (params, index) => (
        <FuelRestrictionsTable
            key={index}
            data={params.data}
            onAllowanceTypeChange={params.onChange('AllowanceType')}
            onRestrictionsChange={params.onChange('FuelRestrictions')}
            isEditing={params.isEditing}
            nameSuffix={params.nameSuffix}
            allowanceTypes={params.editingOptions.allowanceTypes}
            validationRules={validations.FuelRestrictions}
        />
    )
};
