import { takeEvery, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* services */
import NotificationService from 'services/notification-api/notification-service';
/* actions */
import { ActionTypes } from './notifications-actions';
import { ActionTypes as HomeActionTypes } from '../home/home-actions';
import { ActionTypes as ReportActionTypes } from 'components/vessel-report/vessel-report-actions';

function* getActiveNotifications(action) {
    const showNotifications = yield select(state =>
        state.userReducer.permissions && state.userReducer.permissions.MenuNotifications);
    if (showNotifications) {
        const params = {
            Limit: 5000, // action.params ? action.params.Limit : 20,
            Offset: 0, // action.params ? action.params.Offset : 0,
            Dismissed: false,
            Severity: 'Alert'
        };
        const response = yield NotificationService.getNotifications(params);
        if (response && response.Notifications) {
            yield put({
                type: ActionTypes.NOTIFICATIONS_SET_ACTIVE,
                notifications: response,
                addAction: action.addAction
            });
        }
    }
}

function* getDismissedNotifications(action) {
    const showNotifications = yield select(state =>
        state.userReducer.permissions && state.userReducer.permissions.MenuNotifications);
    if (showNotifications) {
        const params = {
            Limit: action.params ? action.params.Limit : 20,
            Offset: action.params ? action.params.Offset : 0,
            Dismissed: true,
            Severity: 'Alert'
        };
        const dismissedNotifications = yield NotificationService.getNotifications(params);
        if (dismissedNotifications) {
            yield put({
                type: ActionTypes.NOTIFICATIONS_SET_DISMISSED,
                dismissedNotifications,
                addAction: action.addAction
            });
        }
    }
}

function* dismissNotifications(action) {
    const response = yield NotificationService.dismissNotifications(action.notificationIds);
    if (response) {
        toast(translate('NOTIFICATIONS.DISMISS_SUCCESS'), { type: toast.TYPE.SUCCESS });
        yield put({
            type: ActionTypes.NOTIFICATIONS_REMOVE_FROM_ACTIVE,
            notificationIds: action.notificationIds
        });
        yield put({
            type: HomeActionTypes.HOME_REMOVE_SELECTED_VESSEL_NOTIFICATIONS,
            notificationIds: action.notificationIds
        });
    }
}

export default function* notificationsSaga() {
    yield takeEvery(ActionTypes.NOTIFICATIONS_GET_ACTIVE, getActiveNotifications);
    yield takeEvery(ActionTypes.NOTIFICATIONS_GET_DISMISSED, getDismissedNotifications);
    yield takeEvery(ActionTypes.NOTIFICATIONS_DISMISS, dismissNotifications);
    yield takeEvery(ReportActionTypes.VESSEL_REPORT_REMINDER_SENT, getActiveNotifications);
}
