import React from 'react';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { commonFields } from 'components/form-fields/form-fields-helper';
/* components */
import EditableList from 'components/editable-list/editable-list';
import Input from 'components/input/input';
import Select from 'components/select/select';
import Validation from 'components/validation/validation';

const t = (key, values) => translate(`PORT_FORM.${key}`, values);
const tr = (key) => translate(`RESTRICTIONS.${key}`);
const tu = (key) => translate(`UNITS.${key}`);

export class Port {
    constructor(item) {
        this.Id = item && item.Id;
        this.Name = item ? item.Name : '';
        this.Aliases = item ? [...item.Aliases] : [];
        this.Country = item ? item.Country : null;
        this.IspsCompliance = item ? item.IspsCompliance : null;
        this.LiquidFacilities = item ? item.LiquidFacilities : null;
        this.Code = item ? item.Code : null;
        this.Position = item ? item.Position : null;
        this.Restrictions = item ? { ...item.Restrictions } : {
            MaxDraft: '',
            MaxLOA: '',
            MaxBeam: '',
            MaxDisplacement: '',
            MaxDWT: ''
        };
        this.Vendors = item ? { ...item.Vendors } : {
            PreferredAgent: '',
            TowingContract: ''
        };
        this.Services = {};
        if (item) {
            Object.keys(item.Services).forEach(key => {
                this.Services[key] = { ...item.Services[key] };
            });
        }
        this.Bunkering = {
            Types: item ? [...item.Bunkering.Types] : [],
            Info: item ? item.Bunkering.Info : ''
        };
        this.UpdatedBy = item ? item.UpdatedBy : '';
        this.UpdatedAt = item ? item.UpdatedAt : '';
    }
}

export const commonValidations = {
    Required: { required: true },
    Name: { required: true, maxLength: 50 },
    Number: { numeric: { min: 0, max: 9999999 } },
    Integer: { numeric: { min: 0, max: 9999999, decimals: 0 } },
    UNLOCode: { required: true, UNLOCode: true },
    Vendors: { maxLength: 100 },
    Aliases: { maxLength: 50 },
    AdditionalService: {
        Value: { numeric: { min: 0, max: 9999999, decimals: 0 } },
        Comment: { maxLength: 50 }
    },
    BunkeringInfo: { maxLength: 200 },
    MaxBeam: { numeric: { min: 0, max: 100 } },
    MaxDisplacement: { numeric: { min: 1, max: 600000 } },
    MaxDraft: { numeric: { min: 1, max: 60 } },
    MaxDWT: { numeric: { min: 100, max: 600000, decimals: 0 } },
    MaxLOA: { numeric: { min: 1, max: 600 } }
};

export const portFields = {
    Name: {
        label: <label className="label">{t('PORT_NAME')}</label>,
        view: port => <label className="label label--value">{port.Name}</label>,
        edit: (port, setValue) => (
            <Validation.Wrapper hintsOnHover validations={commonValidations.Name}>
                <Input
                    disabled={!!port.Id}
                    value={port.Name}
                    onChange={setValue('Name')}
                    name="Name"
                />
            </Validation.Wrapper>
        )
    },
    Aliases: {
        label: <label className="label">{t('PORT_ALIASES')}</label>,
        view: port => <label className="label label--value">{port.Aliases.join(', ')}</label>,
        edit: (port, setValue, options) => (
            <EditableList
                validations={commonValidations.Aliases}
                name="Aliases"
                readOnlyLength={options.initialAliases ? options.initialAliases.length : 0}
                addBtnLabel={t('ADD_NEW_ALIAS')}
                onChange={setValue('Aliases')}
                value={port.Aliases}
                labelInline
            />
        )
    },
    Country: {
        label: <label className="label">{t('COUNTRY')}</label>,
        view: port => <label className="label label--value">{port.Country.Name}</label>,
        edit: (port, setValue, options) => (
            <Validation.Wrapper hintsOnHover validations={commonValidations.Required}>
                <Select
                    searchable
                    name="Country"
                    value={port.Country}
                    labelKey="Name"
                    valueKey="Id"
                    options={options.countries}
                    onChange={setValue('Country')}
                />
            </Validation.Wrapper>
        )
    },
    Code: {
        label: <label className="label">{t('UNLO_CODE')}</label>,
        view: port => <label className="label label--value">{port.Code}</label>,
        edit: (port, setValue) => (
            <Validation.Wrapper hintsOnHover validations={commonValidations.UNLOCode}>
                <Input
                    disabled={!!port.Id}
                    value={port.Code}
                    name="Code"
                    onChange={setValue('Code')}
                />
            </Validation.Wrapper>
        )
    },
    Position: commonFields.Position(t('POSITION'), 'Position', commonValidations.Required),
    IspsCompliance: commonFields.Boolean(t('ISPS_COMPLIANT'), 'IspsCompliance'),
    LiquidFacilities: commonFields.Boolean(t('LIQUID_FACILITIES'), 'LiquidFacilities'),
    'Restrictions.MaxDraft': commonFields.Number(
        tr('MAX_DRAFT'), 'Restrictions.MaxDraft', 1, commonValidations.MaxDraft, tu('DRAFT')
    ),
    'Restrictions.MaxLOA': commonFields.Number(
        tr('MAX_LOA'), 'Restrictions.MaxLOA', 2, commonValidations.MaxLOA, tu('LOA')
    ),
    'Restrictions.MaxBeam': commonFields.Number(
        tr('MAX_BEAM'), 'Restrictions.MaxBeam', 2, commonValidations.MaxBeam, tu('BEAM')
    ),
    'Restrictions.MaxDisplacement': commonFields.Number(
        tr('MAX_DISPLACEMENT'),
        'Restrictions.MaxDisplacement',
        2,
        commonValidations.MaxDisplacement,
        tu('MAX_DISPLACEMENT')
    ),
    'Restrictions.MaxDWT': commonFields.Number(
        tr('MAX_DWT'), 'Restrictions.MaxDWT', 2, commonValidations.MaxDWT, tu('DWT')
    ),
    'Vendors.PreferredAgent': commonFields.String(
        t('PREFERRED_AGENT'), 'Vendors.PreferredAgent', '', commonValidations.Vendors
    ),
    'Vendors.TowingContract': commonFields.String(
        t('TOWING_CONTRACT'), 'Vendors.TowingContract', '', commonValidations.Vendors
    ),
    AdditionalService: {
        view: (service) => (
            <div key={service.Id} className="flex-row flex-center form-row">
                <div className="col-8"><label className="label">{service.DisplayName}</label></div>
                <div className="col-8"><label className="label label--value">{service.Value || '-'}</label></div>
                <div className="col-8"><label className="label">{service.Comment || ''}</label></div>
            </div>
        ),
        edit: (service, setValue) => (
            <div key={service.Id} className="flex-row flex-center form-row">
                <div className="col-8"><label className="label">{service.DisplayName}</label></div>
                <div className="col-8">
                    <Validation.Wrapper hintsOnHover validations={commonValidations.AdditionalService.Value}>
                        <Input
                            name={`Services.${service.Id}.Value`}
                            value={service.Value || ''}
                            onChange={setValue(`Services.${service.Id}.Value`)}
                        />
                    </Validation.Wrapper>
                </div>
                <div className="col-8">
                    <Validation.Wrapper hintsOnHover validations={commonValidations.AdditionalService.Comment}>
                        <Input
                            name={`Services.${service.Id}.Comment`}
                            placeholder={t('ADD_NOTE')}
                            value={service.Comment || ''}
                            onChange={setValue(`Services.${service.Id}.Comment`)}
                        />
                    </Validation.Wrapper>
                </div>
            </div>
        )
    },
    'Bunkering.Types': {
        label: <label className="label">{t('TYPE')}</label>,
        view: port => (
            <label className="label label--value">
                {port.Bunkering.Types.map(type => type.BunkeringType).join(', ') || '-'}
            </label>
        ),
        edit: (port, setValue, options) => (
            <Select
                name="Bunkering.Types"
                multiple
                clearable
                valueKey="Id"
                labelKey="BunkeringType"
                options={options.bunkeringTypes}
                value={port.Bunkering.Types}
                onChange={setValue('Bunkering.Types')}
            />
        )
    },
    'Bunkering.Info': commonFields.Textarea(t('OTHER_INFO'), 'Bunkering.Info', commonValidations.BunkeringInfo)
};
