import { ActionTypes } from './voyage-reports-actions';

const initialState = {
    reportPositions: [],
    zoomLevel: 2.4
};

export default function voyageReportsReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.SET_REPORTS_POSITIONS: {
        return {
            ...state,
            reportPositions: action.reportPositions,
            zoomLevel: action.zoomLevel
        };
    }
    default:
        return state;
    }
}
