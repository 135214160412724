import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { splitSegments } from 'components/segment-select/segment-select-helper';
/* components */
import Input from 'components/input/input';
import Select from 'components/select/select';
/* actions */
import { setSelectedSegments, updateSearchCriteria, resetAllFilters } from '../admin-vessel-management-actions';
/* styles */
import './admin-vessel-management-filters.scss';

export class AdminVesselManagementFilters extends React.PureComponent {
    state = {
        searchCriteria: this.props.searchCriteria ? this.props.searchCriteria : '',
        prevSearchCriteria: this.props.searchCriteria
    };

    static getDerivedStateFromProps(props, state) {
        if (state.prevSearchCriteria !== props.searchCriteria) {
            return {
                searchCriteria: props.searchCriteria,
                prevSearchCriteria: props.searchCriteria
            };
        }
        return null;
    }

    componentWillUnmount() {
        this.props.resetAll();
    }

    handleSearch = searchCriteria => {
        this.setState({ searchCriteria });
        if (this.props.searchCriteria !== searchCriteria.trim()) {
            const trimmedValue = searchCriteria.trim();
            if (trimmedValue.length >= 3 || !trimmedValue) {
                this.props.updateSearchCriteria(trimmedValue);
            }
        }
    };

    resetFilters = () => {
        this.props.resetAll();
    };

    render() {
        let filtersClass = 'sten-admin-vessel-management-filters';
        if (this.props.areFiltersVisible) {
            filtersClass += ' sten-admin-vessel-management-filters--visible';
        }
        return (
            <div className={filtersClass}>
                <div className="sten-content__section flex-row flex-end">
                    <div className="col-16 col-lg-10">
                        <div className="row">
                            <div className="col-12">
                                <div className="label">
                                    {translate('ADMIN.VESSEL_MANAGEMENT.FILTERS.VESSELS')}
                                </div>
                                <Input
                                    value={this.state.searchCriteria}
                                    onChange={this.handleSearch}
                                    changeDebounce={500}
                                    placeholder={
                                        translate('ADMIN.VESSEL_MANAGEMENT.FILTERS.SEARCH_VESSELS_PLACEHOLDER')
                                    }
                                    title={translate('ADMIN.VESSEL_MANAGEMENT.FILTERS.SEARCH_VESSELS_TITLE')}
                                />
                            </div>
                            <div className="col-12">
                                <div className="label">{translate('ADMIN.VESSEL_MANAGEMENT.FILTERS.SEGMENT')}</div>
                                <Select
                                    name="selectedSegments"
                                    searchable
                                    multiple
                                    clearable
                                    valueKey="VesselTypeId"
                                    labelKey="VesselTypeName"
                                    options={this.props.segments}
                                    placeholder="Select Segments"
                                    value={this.props.selectedSegments}
                                    onChange={this.props.setSelectedSegments}
                                    splitOptionsIntoSections={splitSegments}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <button
                            disabled={this.props.searchCriteria === '' && this.props.selectedSegments.length === 0}
                            type="button"
                            className="btn btn--secondary col-24"
                            onClick={this.resetFilters}
                        >
                            {translate('ADMIN.VESSEL_MANAGEMENT.FILTERS.RESET')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

AdminVesselManagementFilters.propTypes = {
    areFiltersVisible: PropTypes.bool.isRequired,
    resetAll: PropTypes.func.isRequired,
    searchCriteria: PropTypes.string.isRequired,
    segments: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedSegments: PropTypes.arrayOf(PropTypes.any).isRequired,
    setSelectedSegments: PropTypes.func.isRequired,
    updateSearchCriteria: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        areFiltersVisible: state.adminVesselManagementReducer.areFiltersVisible,
        segments: state.adminVesselManagementReducer.segments,
        selectedSegments: state.adminVesselManagementReducer.selectedSegments,
        searchCriteria: state.adminVesselManagementReducer.searchCriteria
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateSearchCriteria: searchCriteria => updateSearchCriteria(dispatch, searchCriteria),
        setSelectedSegments: selectedSegments => setSelectedSegments(dispatch, selectedSegments),
        resetAll: () => resetAllFilters(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminVesselManagementFilters);
