export const ActionTypes = {
    POSITION_LIST_GET_TABLE_COLUMNS: 'POSITION_LIST_GET_TABLE_COLUMNS',
    POSITION_LIST_SET_TABLE_COLUMNS: 'POSITION_LIST_SET_TABLE_COLUMNS',
    POSITION_LIST_GET_GROUPINGS: 'POSITION_LIST_GET_GROUPINGS',
    POSITION_LIST_SET_GROUPINGS: 'POSITION_LIST_SET_GROUPINGS',
    POSITION_LIST_GET_VESSELS: 'POSITION_LIST_GET_VESSELS',
    POSITION_LIST_SET_VESSELS: 'POSITION_LIST_SET_VESSELS',
    POSITION_LIST_SET_SELECTED_VESSELS: 'POSITION_LIST_SET_SELECTED_VESSELS',
    POSITION_LIST_SUBMIT_VESSEL_NOTE: 'POSITION_LIST_SUBMIT_VESSEL_NOTE',
    POSITION_LIST_REMOVE_VESSEL_NOTE: 'POSITION_LIST_REMOVE_VESSEL_NOTE',
    POSITION_LIST_UPDATE_VESSEL_NOTE: 'POSITION_LIST_UPDATE_VESSEL_NOTE',
    POSITION_LIST_SET_VESSEL_FIXTURE_STATUS: 'POSITION_LIST_SET_VESSEL_FIXTURE_STATUS',
    POSITION_LIST_TOGGLE_COMPOSE: 'POSITION_LIST_TOGGLE_COMPOSE',
    POSITION_LIST_GET_FIXTURE_STATUSES: 'POSITION_LIST_GET_FIXTURE_STATUSES',
    POSITION_LIST_SET_FIXTURE_STATUSES: 'POSITION_LIST_SET_FIXTURE_STATUSES',
    POSITION_LIST_DELETE_LOAD_READY_DATE: 'POSITION_LIST_DELETE_LOAD_READY_DATE',
    POSITION_LIST_UPDATE_LOAD_READY_DATE: 'POSITION_LIST_UPDATE_LOAD_READY_DATE',
    POSITION_LIST_GET_VESSEL_INFO_CONFIGURABLE_PROPERTIES: 'POSITION_LIST_GET_VESSEL_INFO_CONFIGURABLE_PROPERTIES',
    POSITION_LIST_SET_VESSEL_INFO_CONFIGURABLE_PROPERTIES: 'POSITION_LIST_SET_VESSEL_INFO_CONFIGURABLE_PROPERTIES',
    POSITION_LIST_TOGGLE_SETTINGS: 'POSITION_LIST_TOGGLE_SETTINGS',
    POSITION_LIST_RESET: 'POSITION_LIST_RESET'
};

export function deleteLoadReadyDate(dispatch, IMO) {
    dispatch({ type: ActionTypes.POSITION_LIST_DELETE_LOAD_READY_DATE, IMO });
}

export function updateLoadReadyDate(dispatch, IMO, LoadReadyDate) {
    dispatch({ type: ActionTypes.POSITION_LIST_UPDATE_LOAD_READY_DATE, IMO, LoadReadyDate });
}

export function getTableColumns(dispatch) {
    dispatch({ type: ActionTypes.POSITION_LIST_GET_TABLE_COLUMNS });
}

export function getVesselConfigurableProperties(dispatch) {
    dispatch({ type: ActionTypes.POSITION_LIST_GET_VESSEL_INFO_CONFIGURABLE_PROPERTIES });
}

export function getGroupings(dispatch) {
    dispatch({ type: ActionTypes.POSITION_LIST_GET_GROUPINGS });
}

export function getVessels(dispatch) {
    dispatch({ type: ActionTypes.POSITION_LIST_GET_VESSELS });
}

export function setVessels(dispatch, vessels) {
    dispatch({ type: ActionTypes.POSITION_LIST_SET_VESSELS, vessels });
}

export function setSelectedVessels(dispatch, selectedVessels) {
    dispatch({ type: ActionTypes.POSITION_LIST_SET_SELECTED_VESSELS, selectedVessels });
}

export function submitNote(dispatch, imo, note, id, isInternal) {
    dispatch({
        type: ActionTypes.POSITION_LIST_SUBMIT_VESSEL_NOTE,
        imo,
        note,
        id,
        isInternal
    });
}

export function removeNote(dispatch, imo, id, isInternal) {
    dispatch({
        type: ActionTypes.POSITION_LIST_REMOVE_VESSEL_NOTE,
        imo,
        id,
        isInternal
    });
}

export function toggleCompose(dispatch, flag) {
    dispatch({ type: ActionTypes.POSITION_LIST_TOGGLE_COMPOSE, flag });
}

export function getFixtureStatuses(dispatch) {
    dispatch({ type: ActionTypes.POSITION_LIST_GET_FIXTURE_STATUSES });
}

export function setFixtureStatus(dispatch, vesselId, fixtureStatus) {
    dispatch({
        type: ActionTypes.POSITION_LIST_SET_VESSEL_FIXTURE_STATUS,
        vesselId,
        fixtureStatus
    });
}

export function toggleSettings(dispatch, bool) {
    dispatch({ type: ActionTypes.POSITION_LIST_TOGGLE_SETTINGS, areSettingsVisible: bool });
}

export function reset(dispatch) {
    dispatch({ type: ActionTypes.POSITION_LIST_RESET });
}
