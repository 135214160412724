export const ActionTypes = {
    MAP_OPTIONS_GET_OPTIONS: 'MAP_OPTIONS_GET_OPTIONS',
    MAP_OPTIONS_SET_OPTIONS: 'MAP_OPTIONS_SET_OPTIONS',
    MAP_OPTIONS_GET_SELECTED_AREAS: 'MAP_OPTIONS_GET_SELECTED_AREAS',
    MAP_OPTIONS_SET_SELECTED_AREAS: 'MAP_OPTIONS_SET_SELECTED_AREAS'
};

export function getMapOptions(dispatch) {
    dispatch({
        type: ActionTypes.MAP_OPTIONS_GET_OPTIONS
    });
}

export function getSelectedAreas(dispatch) {
    dispatch({
        type: ActionTypes.MAP_OPTIONS_GET_SELECTED_AREAS
    });
}
