import getFontSize from 'utils/helpers/cached-font-size';
import getTitle from './title-config';
/* services */
import ConfigService from 'services/config-api/config-service';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* constants */
import { chartConfigBaseColors, formatter } from './chart-config-constants';

const chartColors = { AverageSpeed: '#0c9c12', SensorAverageSpeed: '#C7FF85' };
const colorChartPlotBg = 'rgba(255, 63, 63, 0.2)';

const getDailyAverageSpeedConfig = () => {
    const rem = getFontSize();
    const fsm = rem / 16;
    const config = {
        chart: {
            backgroundColor: chartConfigBaseColors.colorChartBg,
            plotBackgroundColor: colorChartPlotBg,
            spacing: [2.5 * rem, rem, rem, 2.5 * rem]
        },
        xAxis: {
            gridLineColor: chartConfigBaseColors.colorXAxisGridLine,
            gridZIndex: 0,
            tickPixelInterval: 400,
            tickWidth: 0,
            labels: {
                enabled: false
            },
            startOnTick: true
        },
        yAxis: {
            minTickInterval: 2,
            tickAmount: 3,
            labels: {
                reserveSpace: false
            },
            title: {
                ...getTitle(fsm),
                text: t('VOYAGE_PERFORMANCE.CHARTS.DAILY_AVEGARE_SPEED_KN')
            }
        },
        tooltip: {
            formatter
        },
        series: [
            {
                type: 'areasplinerange',
                lineWidth: 0,
                data: [],
                zIndex: 1,
                color: chartConfigBaseColors.colorAreaWarningFill,
                fillColor: chartConfigBaseColors.colorAreaWarningFill,
                showInLegend: false,
                states: { hover: { enabled: false }, inactive: { enabled: false } },
                marker: {
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                connectNulls: true,
                enableMouseTracking: false
            },
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.INSTRUCTED_SPEED'),
                type: 'areasplinerange',
                lineWidth: 0,
                data: [],
                zIndex: 2,
                color: chartConfigBaseColors.colorAreaSuccess,
                fillColor: chartConfigBaseColors.colorAreaSuccessFill,
                states: { hover: { enabled: false }, inactive: { enabled: false } },
                marker: {
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                connectNulls: true,
                enableMouseTracking: false,
                events: {
                    legendItemClick: () => false
                }
            },
            {
                type: 'areasplinerange',
                lineWidth: 0,
                data: [],
                zIndex: 3,
                color: chartConfigBaseColors.colorAreaWarningFill,
                fillColor: chartConfigBaseColors.colorAreaWarningFill,
                showInLegend: false,
                states: { hover: { enabled: false }, inactive: { enabled: false } },
                marker: {
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                connectNulls: true,
                enableMouseTracking: false
            },
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.INSTRUCTED_SPEED'),
                type: 'line',
                color: chartConfigBaseColors.colorAreaSuccess,
                lineWidth: 0,
                data: [],
                zIndex: 0,
                showInLegend: false,
                states: { hover: { enabled: false }, inactive: { enabled: false } },
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.AVERAGE_SPEED'),
                type: 'area',
                color: chartColors.AverageSpeed,
                lineWidth: 2 * fsm,
                data: [],
                zIndex: 4,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            }
        ]
    };
    if (ConfigService.featureToggles.showSensorData) {
        config.series.push(
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.SENSOR_AVERAGE_SPEED'),
                type: 'area',
                color: chartColors.SensorAverageSpeed,
                lineWidth: 2 * fsm,
                data: [],
                zIndex: 5,
                visible: false,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            }
        );
    }
    return config;
};

export default getDailyAverageSpeedConfig;
