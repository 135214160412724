import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import { getPanelRows } from 'utils/helpers/template-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
import Accordion from 'components/accordion/accordion';
import OperatorsDiaryFixture from '../../operators-diary-fixture/operators-diary-fixture';

const t = (key) => translate(`VOYAGE_INFO.OVERVIEW.${key}`);

class VoyageInfoOverview extends PureComponent {
    render() {
        const { voyage } = this.props;
        const columnClasses = ['col-8', 'col-16'];
        const columnValues = [];
        columnValues.push([
            <label className="sten-panel__label">{t('VOYAGE_START')}</label>,
            <label className="sten-panel__label sten-panel__label--value">
                {voyage.StartDate ? TimeHelper.getFormatted(voyage.StartDate, { time: true }) : '-'}
            </label>
        ]);
        columnValues.push([
            <label className="sten-panel__label">{t('VOYAGE_END')}</label>,
            <label className="sten-panel__label sten-panel__label--value">
                {voyage.EndDate ? TimeHelper.getFormatted(voyage.EndDate, { time: true }) : '-'}
            </label>
        ]);

        return (
            <ScrollArea className="sten-content__body">
                <div className="sten-content__section">
                    {getPanelRows(columnClasses, columnValues)}
                </div>
                {voyage.Fixtures && voyage.Fixtures.length > 0 && (
                    <Accordion header={<h4 className="text-uppercase">Fixtures</h4>}>
                        <div className="sten-content__section">
                            {voyage.Fixtures.map((fixture) => (
                                <OperatorsDiaryFixture key={fixture.FixtureId} fixture={fixture} />
                            ))}
                        </div>
                    </Accordion>
                )}
            </ScrollArea>
        );
    }
}

VoyageInfoOverview.propTypes = {
    voyage: PropTypes.objectOf(PropTypes.any).isRequired
};

export default VoyageInfoOverview;
