import { roundNumber } from 'utils/helpers/info-helper';
import { mapLonLat } from 'components/ol/ol-helpers';

const emptyArray = [];

export const internalConditionalAreas = {
    ForbidHighRiskAreas: { Id: 'internal-0', Name: 'High Risk Areas', Key: 'ForbidHighRiskAreas', Type: 'Internal' },
    ForbidEcaZones: { Id: 'internal-1', Name: 'ECA Zones', Key: 'ForbidEcaZones', Type: 'Internal' }
};

export const extractConditionalAreasFromArray = (data) => {
    const res = {
        ForbidHighRiskAreas: false,
        ForbidEcaZones: false,
        EnabledExternalAreas: []
    };
    data.forEach(area => {
        if (area.Key) {
            res[area.Key] = true;
        } else {
            res.EnabledExternalAreas.push(area.Id);
        }
    });
    return res;
};

export const extractConditionalAreasFromObject = (data) => {
    const res = [...data.EnabledExternalAreas];
    if (data.ForbidEcaZones) {
        res.unshift(internalConditionalAreas.ForbidEcaZones);
    }
    if (data.ForbidHighRiskAreas) {
        res.unshift(internalConditionalAreas.ForbidHighRiskAreas);
    }
    return res;
};

export const extractPortInfo = (data) => {
    const fromPort = data.PortOfDeparture ? data.PortOfDeparture.Name : '';
    const toPort = data.PortOfDestination ? data.PortOfDestination.Name : '';
    return fromPort || toPort ? `${fromPort} - ${toPort}` : '';
};

export const extractLegDateRange = (leg, legRequests) => {
    const range = {};
    if (legRequests) {
        legRequests.forEach(request => {
            request.ExternalRequests.forEach(er => {
                if (er.RouteAdvice) {
                    if (!range.startTime || er.RouteAdvice.Etd < range.startTime) {
                        range.startTime = er.RouteAdvice.Etd;
                    }
                    if (!range.endTime || er.RouteAdvice.AdvisedEta > range.endTime) {
                        range.endTime = er.RouteAdvice.AdvisedEta;
                    }
                }
            });
            if (!range.startTime || request.Etd < range.startTime) {
                range.startTime = request.Etd;
            }
            if (!range.endTime || request.Eta > range.endTime) {
                range.endTime = request.Eta;
            }
        });
    }
    if (!range.startTime) {
        range.startTime = leg.Voyage.StartDate;
    }
    if (!range.endTime) {
        range.endTime = leg.Voyage.EndDate;
    }
    return range;
};

export const repackWaypoints = (waypoints) => {
    if (!waypoints || !waypoints.length) return waypoints;

    return waypoints.map(waypoint => {
        if (waypoint.Point.IsCustomPoint) {
            return {
                Position: `POINT(${waypoint.Point.Coordinates[0]} ${waypoint.Point.Coordinates[1]})`
            };
        }

        return {
            PortId: waypoint.Point.Id || waypoint.Point.PortId,
            Position: waypoint.Point.Location || waypoint.Point.Position,
            PortCode: waypoint.Point.PortCode,
            PortName: waypoint.Point.Name
        };
    });
};

export const extractConfigurationRelations = (configurationRelations) => {
    const res = {
        sourceVsOptimizationType: {},
        optimizationTypeVsCalculationType: {},
        sourceVsCalculationType: {}
    };
    configurationRelations.forEach(cr => {
        if (!res.sourceVsOptimizationType[cr.RequestSourceId]) {
            res.sourceVsOptimizationType[cr.RequestSourceId] = {};
        }
        if (!res.sourceVsCalculationType[cr.RequestSourceId]) {
            res.sourceVsCalculationType[cr.RequestSourceId] = {};
        }
        if (!res.optimizationTypeVsCalculationType[cr.OptimizationTypeId]) {
            res.optimizationTypeVsCalculationType[cr.OptimizationTypeId] = {};
        }
        res.sourceVsOptimizationType[cr.RequestSourceId][cr.OptimizationTypeId] = true;
        res.sourceVsCalculationType[cr.RequestSourceId][cr.RouteCalculationTypeId] = true;
        res.optimizationTypeVsCalculationType[cr.OptimizationTypeId][cr.RouteCalculationTypeId] = true;
    });
    return res;
};

export const calculateCourse = (pointA, pointB, extractLatLon = false) => {
    const a = extractLatLon ? mapLonLat(pointA) : pointA;
    const b = extractLatLon ? mapLonLat(pointB) : pointB;
    const angle = Math.atan2(b[1] - a[1], b[0] - a[0]) * (180 / Math.PI);
    return roundNumber(angle <= 90 ? 90 - angle : 450 - angle, 2);
};

export const filterObjectsByIds = (options, ids, idKey) => {
    return options.length > 0 && ids.length > 0
        ? options.filter(option => ids.indexOf(option[idKey]) > -1)
        : emptyArray;
};
