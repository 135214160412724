import React from 'react';
import TextHighlight from 'components/text-highlight/text-highlight';
import PropTypes from 'prop-types';
import './global-search.scss';

export default class GlobalSearchOptionTerminal extends React.PureComponent {
    render() {
        const { searchCriteria } = this.props;

        return (
            <div
                key={this.props.Id}
                className="sten-global-search-results__item flex flex-center"
            >
                <div className="col-2">
                    <span className="sten-global-search-results__item-icon icon icon-terminal-alt" />
                </div>
                <div className="col-22">
                    <h3 className="sten-global-search-results__item-title">
                        <TextHighlight input={this.props.Name} highlight={searchCriteria} />
                    </h3>
                    <h5 className="sten-global-search-results__item-subtitle">
                        {this.props.Subtitle}
                    </h5>
                </div>
            </div>
        );
    }
}

GlobalSearchOptionTerminal.propTypes = {
    Id: PropTypes.string.isRequired,
    Name: PropTypes.string.isRequired,
    searchCriteria: PropTypes.string.isRequired,
    Subtitle: PropTypes.string
};

GlobalSearchOptionTerminal.defaultProps = {
    Subtitle: ''
};
