export const customDateValidation = {
    rule: (value) => value !== 'Invalid Date',
    hint: () => 'Date is invalid'
};

export const commonValidations = {
    Required: { required: true },
    EmailList: { emailList: true }
};

export const validations = {
    Title: commonValidations.Required,
    Imo: { required: true, imo: true },
    VesselType: commonValidations.Required,
    VesselSegment: {
        custom: {
            rule: (value, params, components) =>
                (components.DWT && components.DWT.state && components.DWT.state.value) || value,
            hint: () => 'DWT or segment must be defined'
        }
    },
    DWT: {
        custom: {
            rule: (value, params, components) =>
                (components.VesselSegment
                    && components.VesselSegment.state
                    && components.VesselSegment.state.value) || value,
            hint: () => 'DWT or segment must be defined'
        },
        numeric: { min: 1, max: 10000000 }
    },
    TotalCubicCapacity: { required: true, numeric: { min: 1, max: 10000000 } },
    BlockCoefficientCb: { numeric: { decimals: 2, min: 0, max: 10000000 } },
    CubicCapacityCorrectionFactorFc: { numeric: { decimals: 2, min: 0, max: 10000000 } },
    VoluntaryStructuralEnhancement: { numeric: { decimals: 2, min: 0, max: 10000000 } },
    GT: { numeric: { min: 0, max: 10000000 } },
    Draft: { numeric: { min: 0, max: 100 } },
    NoxMainEngine: { numeric: { min: 0, max: 20 } },
    NoxAuxEngine: { numeric: { min: 0, max: 20 } },
    SfcMainEngine: { numeric: { min: 0, max: 250 } },
    SfcAuxEngine: { numeric: { min: 0, max: 250 } },
    Loa: { required: true, numeric: { min: 0 } },
    MMSI: { numeric: { decimals: 0 }, minLength: 9, maxLength: 9 },
    Beam: { numeric: { decimals: 2, min: 0, max: 10000 } },
    BallastOptimumTrim: { numeric: { decimals: 1, min: 0, max: 50 } },
    LadenOptimumTrim: { numeric: { decimals: 1, min: 0, max: 50 } },
    SortOrder: { numeric: { min: 0, max: 10000 } },
    TechnicalManagementEmail: commonValidations.EmailList,
    VesselEmail: commonValidations.EmailList,
    OperationsEmail: commonValidations.EmailList,
    OwnerEmail: commonValidations.EmailList,
    Comment: { maxLength: 250 }
};
