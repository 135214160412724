import React from 'react';
import PropTypes from 'prop-types';
/* styles */
import './date-picker.scss';

export default class DatePickerPanel extends React.PureComponent {
    render = () => {
        let titleClass = 'htec-dp-date-picker-panel__title';
        if (this.props.onPanelTitleClick) {
            titleClass += ' htec-dp-date-picker-panel__title--clickable';
        }
        let className = 'htec-dp-date-picker-panel';
        if (this.props.className) {
            className += ` ${this.props.className}`;
        }
        return (
            <div className={className}>
                <h3
                    onClick={this.props.onPanelTitleClick}
                    className={titleClass}
                >
                    {this.props.title}
                </h3>
                {this.props.children}
            </div>
        );
    }
}

DatePickerPanel.propTypes = {
    className: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    children: PropTypes.node,
    onPanelTitleClick: PropTypes.func
};

DatePickerPanel.defaultProps = {
    className: '',
    children: null,
    onPanelTitleClick: undefined
};
