export const ActionTypes = {
    TRADING_ROUTE_DETAILS_GET_DATA: 'TRADING_ROUTE_DETAILS_GET_DATA',
    TRADING_ROUTE_DETAILS_SET_DATA: 'TRADING_ROUTE_DETAILS_SET_DATA',
    TRADING_ROUTE_DETAILS_RESET_ALL: 'TRADING_ROUTE_DETAILS_RESET_ALL',
    TRADING_ROUTE_DETAILS_GET_MODELS: 'TRADING_ROUTE_DETAILS_GET_MODELS',
    TRADING_ROUTE_DETAILS_SET_MODELS: 'TRADING_ROUTE_DETAILS_SET_MODELS',
    TRADING_ROUTE_DETAILS_SET_LATEST_PREDICTION: 'TRADING_ROUTE_DETAILS_SET_LATEST_PREDICTION',
    TRADING_ROUTE_DETAILS_GET_RANGES: 'TRADING_ROUTE_DETAILS_GET_RANGES',
    TRADING_ROUTE_DETAILS_SET_RANGES: 'TRADING_ROUTE_DETAILS_SET_RANGES'
};

export function getTradingRouteDetailsData(dispatch, routeCode, model, range) {
    dispatch({
        type: ActionTypes.TRADING_ROUTE_DETAILS_GET_DATA,
        routeCode,
        model,
        range
    });
}

export function tradingRouteDetailsResetAll(dispatch) {
    dispatch({
        type: ActionTypes.TRADING_ROUTE_DETAILS_RESET_ALL
    });
}

export function getTradingRouteModels(dispatch) {
    dispatch({
        type: ActionTypes.TRADING_ROUTE_DETAILS_GET_MODELS
    });
}

export function getTradingRouteRanges(dispatch) {
    dispatch({
        type: ActionTypes.TRADING_ROUTE_DETAILS_GET_RANGES
    });
}
