import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* actions */
import { updateUserSettings } from 'pages/user-pages/user-actions';
/* components */
import RadioButton from 'components/radio-button/radio-button';

export class WeatherConditions extends React.PureComponent {
    toggleWeatherLayer = weatherType => {
        this.props.updateUserSettings({
            WeatherLayerType: weatherType.Id
        });
    };

    render() {
        return (
            <div className="sten-map-options__weather sten-content__section">
                {this.props.weatherLayerTypes.map(weatherType => (
                    <div className="form-row" key={weatherType.Id}>
                        <RadioButton
                            key={weatherType.Id}
                            className="text-uppercase"
                            onChange={this.toggleWeatherLayer.bind(this, weatherType)}
                            isChecked={weatherType.Id === this.props.weatherLayerType}
                        >
                            {weatherType.Title}
                        </RadioButton>
                    </div>
                ))}
            </div>
        );
    }
}

WeatherConditions.propTypes = {
    updateUserSettings: PropTypes.func.isRequired,
    weatherLayerType: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    weatherLayerTypes: PropTypes.arrayOf(PropTypes.object).isRequired
};

function mapStateToProps(state) {
    return {
        weatherLayerType: state.userReducer.settings && state.userReducer.settings.WeatherLayerType,
        weatherLayerTypes: state.wniWeatherReducer.weatherLayerTypes
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateUserSettings: settings => updateUserSettings(dispatch, settings, 'mapOptions')
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WeatherConditions);
