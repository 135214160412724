import CoreApi from './core-api';

class TerminalsService {
    constructor() {
        this.resourceUrl = 'Terminals';
        this.berthResourceUrl = 'Berths';
    }

    getTerminalsByPortId(portId) {
        return CoreApi.get(`${this.resourceUrl}`, { portId })
            .then(response => response.data, () => null);
    }

    getTerminalById(terminalId) {
        return CoreApi.get(`${this.resourceUrl}/${terminalId}`)
            .then(response => response.data, () => null);
    }

    updateTerminal(terminal) {
        return CoreApi.post(`${this.resourceUrl}`, terminal)
            .then(response => response.data, () => null);
    }

    getBerths(terminalId) {
        return CoreApi.get(`${this.berthResourceUrl}`, { terminalId })
            .then(response => response.data, () => null);
    }

    getBerthById(berthId) {
        return CoreApi.get(`${this.berthResourceUrl}/${berthId}`)
            .then(response => response.data, () => null);
    }

    updateBerth(berth) {
        return CoreApi.post(`${this.berthResourceUrl}`, berth)
            .then(response => response.data, () => null);
    }
}

export default new TerminalsService();
