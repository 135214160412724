import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* selectors */
import { getTableData } from './fleet-list-report-selectors';
/* actions */
import { generateXLSX, generatePDF } from './fleet-list-report-actions';
/* components */
import EmptyContent from 'components/empty-content/empty-content';
import ContentHeader from '../../shared/content-header';
import FleetListReportTable from './fleet-list-report-table';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.${key}`);

class FleetListReport extends React.PureComponent {
    render() {
        const { tableData, generateXLSX, generatePDF } = this.props;
        return (
            <div className="sten-content sten-content--has-footer">
                <ContentHeader />
                {!tableData ? <EmptyContent>{t('EMPTY_REPORT')}</EmptyContent> : (
                    <React.Fragment>
                        <div className="sten-energy-management-reports-preview sten-content__body">
                            <FleetListReportTable data={tableData} />
                        </div>
                        <footer className="sten-content__footer flex-row">
                            <div className="flex-grow hide-xs" />
                            <div className="flex-shrink">
                                <button onClick={generatePDF} className="btn btn--primary">
                                    {t('EXPORT_PDF')}
                                </button>
                            </div>
                            <div className="flex-shrink">
                                <button onClick={generateXLSX} className="btn btn--primary">
                                    {t('EXPORT_XLSX')}
                                </button>
                            </div>
                        </footer>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

FleetListReport.propTypes = {
    tableData: PropTypes.shape({
        columns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        parsedData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
    }),
    generatePDF: PropTypes.func.isRequired,
    generateXLSX: PropTypes.func.isRequired
};

FleetListReport.defaultProps = {
    tableData: null
};

function mapStateToProps(state) {
    return {
        tableData: getTableData(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        generatePDF: () => generatePDF(dispatch),
        generateXLSX: () => generateXLSX(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FleetListReport);
