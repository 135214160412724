import { ActionTypes } from './vessel-actions';

const initialState = {
    auxiliaryUtilization: null,
    averageSpeed: null,
    boilerUtilization: null,
    // goodTrim: null,
    keyAverageFigures: null,
    keyTotalFigures: null,
    rpmAnalysis: null,
    topGraph: null
};

export default function energyManagementKPIVesselReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ENERGY_MANAGEMENT_KPI_VESSEL_SET_DATA: {
        return {
            ...state,
            auxiliaryUtilization: action.auxiliaryUtilization,
            averageSpeed: action.averageSpeed,
            boilerUtilization: action.boilerUtilization,
            // goodTrim: action.goodTrim,
            keyAverageFigures: action.keyAverageFigures,
            keyTotalFigures: action.keyTotalFigures,
            rpmAnalysis: action.rpmAnalysis,
            topGraph: action.topGraph
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_KPI_VESSEL_RESET_ALL: {
        return initialState;
    }
    default:
        return state;
    }
}
