import { createSelector } from 'reselect';
import { createByIdSelector } from 'utils/helpers/selector-helper';

const getCompanyFleetsById = createByIdSelector(state => state.notificationSettingsReducer.companyFleets, 'Id');
const getCustomFleetsById = (
    createByIdSelector(state => state.notificationSettingsReducer.customFleets, 'VesselGroupId'));

export const getSettingsWithFleets = createSelector(
    state => state.notificationSettingsReducer.settings,
    getCompanyFleetsById,
    getCustomFleetsById,
    (settings, companyFleetsById, customFleetsById) => {
        return settings.map(setting => ({
            ...setting,
            CompanyFleets: setting.CompanyFleetIds.reduce((res, id) => {
                if (companyFleetsById[id]) {
                    return [...res, companyFleetsById[id]];
                }
                return res;
            }, []),
            CustomFleets: setting.CustomFleetIds.reduce((res, id) => {
                if (customFleetsById[id]) {
                    return [...res, customFleetsById[id]];
                }
                return res;
            }, [])
        }));
    }
);
