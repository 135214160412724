import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { getClassName } from 'utils/helpers/info-helper';
/* styles */
import './accordion.scss';

class Accordion extends React.PureComponent {
    state = {
        isCollapsed: this.props.isCollapsed
    };

    static getDerivedStateFromProps(props, state) {
        if (props.isCollapsed !== state.isCollapsed) {
            const isCollapsed = props.onToggleCollapse ? props.isCollapsed : state.isCollapsed;
            return { isCollapsed };
        }
        return null;
    }

    toggleCollapse = () => {
        if (!this.props.isDisabled) {
            if (this.props.onToggleCollapse) {
                this.props.onToggleCollapse();
            } else {
                this.setState({ isCollapsed: !this.state.isCollapsed });
                if (this.props.onChange) {
                    this.props.onChange(!this.state.isCollapsed);
                }
            }
        }
    };

    render() {
        const accordionClass = getClassName('sten-accordion', this.props.className, {
            'sten-accordion--collapsed': this.state.isCollapsed,
            'sten-accordion--disabled': this.props.isDisabled
        });
        let accordionHeaderClass = 'sten-accordion__header flex flex-center';
        if (this.props.chevronRight) {
            accordionHeaderClass += ' sten-accordion__header--chevron-right';
        }
        return (
            <div className={accordionClass}>
                <header className={accordionHeaderClass} onClick={this.toggleCollapse}>
                    {this.props.headerBefore}
                    <span className="icon icon-chevron-down sten-accordion__chevron" />
                    {this.props.header}
                </header>
                {(this.props.isCollapsedBodyRendered || !this.state.isCollapsed) && (
                    <section className="sten-accordion__body">
                        {this.props.children}
                    </section>
                )}
            </div>
        );
    }
}

Accordion.propTypes = {
    chevronRight: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    header: PropTypes.node,
    headerBefore: PropTypes.node,
    isCollapsed: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isCollapsedBodyRendered: PropTypes.bool,
    onChange: PropTypes.func,
    onToggleCollapse: PropTypes.func
};

Accordion.defaultProps = {
    chevronRight: false,
    children: null,
    className: '',
    header: null,
    headerBefore: null,
    isCollapsedBodyRendered: false,
    isCollapsed: false,
    isDisabled: false,
    onChange: undefined,
    onToggleCollapse: undefined
};

export default Accordion;
