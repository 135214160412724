import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* components */
import TrendIcon from 'components/trend-icon/trend-icon';
/* localization */
import { translate } from 'utils/i18n/i18n-model';
/* styles */
import './total-vessel-distribution.scss';

export class TotalVesselDistribution extends React.PureComponent {
    render() {
        if (!this.props.distribution) {
            return null;
        }
        let className = 'sten-total-vessel-distribution';
        if (this.props.fullWidth) {
            className += ' sten-total-vessel-distribution--full-width';
        }
        return (
            <div className={className}>
                <div className="sten-total-vessel-distribution__wrapper">
                    <div className="sten-total-vessel-distribution__header">
                        <h4 className="text-center text-uppercase flex-grow">
                            {translate('MARKET_INTEL.TOTAL_VESSEL_DISTRIBUTION.TITLE')}
                        </h4>
                    </div>
                    <div className="sten-total-vessel-distribution__body flex">
                        {this.props.distribution.WestAreaDistribution && (
                            <div className="flex col-12 sten-content__separator sten-content__separator--right">
                                <div className="sten-content__section flex-grow flex flex-center">
                                    <h5 className="flex-grow text-secondary sten-total-vessel-distribution__label">
                                        {translate('MARKET_INTEL.TOTAL_VESSEL_DISTRIBUTION.WEST')}
                                    </h5>
                                    <h5 className="flex-grow sten-total-vessel-distribution__label">
                                        {this.props.distribution.WestAreaDistribution.VesselCount}
                                    </h5>
                                    <div className="flex-shrink sten-total-vessel-distribution__trend">
                                        <TrendIcon
                                            value={this.props.distribution.WestAreaDistribution.HistoricalDifference}
                                            breakpoints={[0]}
                                        />
                                        {this.props.distribution.WestAreaDistribution.HistoricalDifference}
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.props.distribution.EastAreaDistribution && (
                            <div className="flex col-12">
                                <div className="sten-content__section flex-grow flex flex-center">
                                    <h5 className="flex-grow text-secondary sten-total-vessel-distribution__label">
                                        {translate('MARKET_INTEL.TOTAL_VESSEL_DISTRIBUTION.EAST')}
                                    </h5>
                                    <h5 className="flex-grow sten-total-vessel-distribution__label">
                                        {this.props.distribution.EastAreaDistribution.VesselCount}
                                    </h5>
                                    <div className="flex-shrink sten-total-vessel-distribution__trend">
                                        <TrendIcon
                                            value={this.props.distribution.EastAreaDistribution.HistoricalDifference}
                                            breakpoints={[0]}
                                        />
                                        {this.props.distribution.EastAreaDistribution.HistoricalDifference}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

TotalVesselDistribution.propTypes = {
    distribution: PropTypes.objectOf(PropTypes.any),
    fullWidth: PropTypes.bool
};

TotalVesselDistribution.defaultProps = {
    distribution: null,
    fullWidth: false
};

function mapStateToProps(state) {
    return {
        distribution: state.marketIntelReducer.totalVesselDistribution
    };
}

export default connect(mapStateToProps)(TotalVesselDistribution);
