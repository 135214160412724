import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
/* styles */
import './modal.scss';

const sizeMap = {
    xs: ' sten-modal--xs',
    sm: ' sten-modal--sm',
    md: ' sten-modal--md',
    lg: ' sten-modal--lg',
    auto: ' sten-modal--auto',
    'full-screen': ' sten-modal--full-screen'
};

const modalClassName = {
    base: 'sten-modal__content',
    afterOpen: 'sten-modal__content--open',
    beforeClose: 'sten-modal-content--closed'
};

const modalOverlayClassName = {
    base: 'sten-modal__overlay flex flex-center',
    afterOpen: 'sten-modal__overlay--open',
    beforeClose: 'sten-modal__overlay--closed'
};

export class ModalDefault extends React.PureComponent {
    render() {
        let portalClassName = 'sten-modal';
        if (this.props.className) {
            portalClassName += ` ${this.props.className}`;
        }
        portalClassName += sizeMap[this.props.modalSize];
        return this.props.isOpen && (
            <ReactModal
                portalClassName={portalClassName}
                bodyOpenClassName="sten-modal--open"
                className={modalClassName}
                overlayClassName={modalOverlayClassName}
                closeTimeoutMS={this.props.closeTimeoutMS}
                contentLabel={this.props.contentLabel}
                isOpen={this.props.isOpen}
                onAfterOpen={this.props.onAfterOpen}
                onRequestClose={this.props.onRequestClose}
            >
                {this.props.children}
            </ReactModal>
        );
    }
}

ModalDefault.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    closeTimeoutMS: PropTypes.number,
    contentLabel: PropTypes.string,
    isOpen: PropTypes.bool,
    modalSize: PropTypes.string,
    onAfterOpen: PropTypes.func,
    onRequestClose: PropTypes.func
};

ModalDefault.defaultProps = {
    children: null,
    className: '',
    closeTimeoutMS: null,
    contentLabel: '',
    isOpen: false,
    modalSize: 'md',
    onAfterOpen: undefined,
    onRequestClose: undefined
};

export default ModalDefault;
