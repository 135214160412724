import moment from 'moment';
import { ActionTypes } from './map-date-picker-actions';

const initialState = {
    isNowSelected: true,
    selectedDate: '',
    startDate: moment('2012-01-01T00:00:00.000Z'),
    isMounted: false,
    isDisabled: true
};

export default function mapDatePickerReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.MAP_DATE_PICKER_SET_DATE: {
        return {
            ...state,
            selectedDate: action.selectedDate,
            isNowSelected: action.isNowSelected
        };
    }
    case ActionTypes.MAP_DATE_PICKER_ENABLE: {
        return {
            ...state,
            isDisabled: !action.isEnabled
        };
    }
    case ActionTypes.MAP_DATE_PICKER_SET_MOUNTED: {
        return {
            ...state,
            isMounted: action.isMounted
        };
    }
    default:
        return state;
    }
}
