/* configs */
import { dailyReportFieldsAndSections } from './daily/vessel-daily-report-fields-and-sections';
import { departureReportFieldsAndSections } from './departure/vessel-departure-report-fields-and-sections';
import { arrivalReportFieldsAndSections } from './arrival/vessel-arrival-report-fields-and-sections';
import { cargoReportFieldsAndSections } from './cargo/vessel-cargo-report-fields-and-sections';
import { eventReportFieldsAndSections } from './event/vessel-event-report-fields-and-sections';
import { nextLegReportFieldsAndSections } from './next-leg/vessel-next-leg-report-fields-and-sections';
import { sofReportFieldsAndSections } from './sof/vessel-sof-report-fields-and-sections';

export const reportFieldsAndSectionsByType = {
    1: dailyReportFieldsAndSections,
    2: departureReportFieldsAndSections,
    3: arrivalReportFieldsAndSections,
    4: cargoReportFieldsAndSections,
    5: eventReportFieldsAndSections,
    6: nextLegReportFieldsAndSections,
    7: sofReportFieldsAndSections
};
