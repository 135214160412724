import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* helpers */
import { renderPanels } from 'components/right-side-bar/right-side-bar-helper';
/* actions */
import { emptyActivePanels, toggleActivePanel } from 'components/right-side-bar/right-side-bar-actions';
/* components */
import VoyageInfo from '../voyage-info/voyage-info';
import VoyageForm from '../voyage-form/voyage-form';

class OperatorsDiaryRightSideBar extends PureComponent {
    componentDidMount() {
        this.updateActivePanel();
    }

    componentWillUnmount() {
        this.props.emptyActivePanels();
    }

    componentDidUpdate(prevProps) {
        this.updateActivePanel(prevProps);
    }

    updateActivePanel(prevProps) {
        if (!prevProps || this.props.router.location.pathname !== prevProps.router.location.pathname) {
            const id = this.props.router.params.id;
            this.props.toggleActivePanel('voyage-info', this.props.router.location.pathname.endsWith(id));
            this.props.toggleActivePanel('voyage-form', this.props.router.location.pathname.endsWith(`edit/${id}`));
        }
    }

    handleCloseClick = () => this.props.router.navigate(appRoutes.Vessel.OperatorsDiary)

    bottomPanelContainer = {
        panelMap: {
            'voyage-info': () => (
                <VoyageInfo
                    key="voyage-info"
                    activeTabId={Number(this.props.router.query.tabId) || null}
                    voyageId={Number(this.props.router.params.id) || null}
                    onCloseClick={this.handleCloseClick}
                />
            ),
            'voyage-form': () => (
                <VoyageForm
                    key="voyage-form"
                    voyageId={Number(this.props.router.params.id) || null}
                    onCloseClick={this.handleCloseClick}
                    tags={this.props.tags}
                    etbStatuses={this.props.etbStatuses}
                />
            )
        }
    };

    render() {
        return renderPanels(this.props.activePanels, this.bottomPanelContainer);
    }
}

OperatorsDiaryRightSideBar.propTypes = {
    activePanels: PropTypes.arrayOf(PropTypes.string).isRequired,
    emptyActivePanels: PropTypes.func.isRequired,
    etbStatuses: PropTypes.arrayOf(PropTypes.object).isRequired,
    router: TRouter.isRequired,
    tags: PropTypes.shape({
        leg: PropTypes.arrayOf(PropTypes.object),
        fixture: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    toggleActivePanel: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        activePanels: state.rightSideBarReducer.activePanels,
        etbStatuses: state.operatorsDiaryReducer.etbStatuses,
        tags: state.operatorsDiaryReducer.tags
    };
}

function mapDispatchToProps(dispatch) {
    return {
        emptyActivePanels: () => emptyActivePanels(dispatch),
        toggleActivePanel: (panelName, enable) => toggleActivePanel(dispatch, panelName, enable)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OperatorsDiaryRightSideBar));
