import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AutoSizer from 'react-virtualized-auto-sizer';
import memoize from 'memoize-one';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { sidebars } from './constants';
import { formatCoordinates, getVesselTechnicalDetails } from 'utils/helpers/info-helper';
import { wktParse } from 'components/ol/ol-helpers';
import getFontSize from 'utils/helpers/cached-font-size';
/* components */
import DateRangePopup from 'components/date-range-popup/date-range-popup';
import FixedSizeList from 'components/fixed-size-list/fixed-size-list';
import EmptyContent from 'components/empty-content/empty-content';
import VesselsSidebar from './sidebar/vessels-sidebar';
import PortsSidebar from './sidebar/ports-sidebar';
import RightSideBar from 'components/right-side-bar/right-side-bar';
import Validation from 'components/validation/validation';
import AfterInitialMount from 'components/after-initial-mount/after-initial-mount';
import ScrollArea from 'components/scroll-area/scroll-area';
/* actions */
import {
    resetAll,
    updateDateFilter,
    setSidebar,
    calculateCallsAnalytics,
    generateXLSX
} from './port-calls-analytics-actions';
/* selectors */
import { getCalculatedPortCallsAnalytics } from './port-calls-analytics-selectors';
/* styles */
import './port-calls-analytics.scss';

const t = (key, props) => translate(`PORT_CALLS_ANALYTICS.${key}`, props);
const listItemHeight = 4.3;

export class PortAnalyticsPage extends React.PureComponent {
    state = {
        fontSize: getFontSize()
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        this.props.resetAll();
    }

    handleResize = () => {
        if (this.state.fontSize !== getFontSize()) {
            this.setState({
                fontSize: getFontSize()
            });
        }
    };

    handleDateRangeChange = ({ rangeStart, rangeEnd }) => {
        this.props.updateDateFilter(rangeStart, rangeEnd);
    };

    openPortsSidebar = () => {
        this.props.setSidebar(sidebars.PORTS);
    };

    openVesselsSidebar = () => {
        this.props.setSidebar(sidebars.VESSELS);
    };

    closeSidebar = () => {
        this.props.setSidebar(sidebars.NONE);
    };

    getPortId = (index) => this.props.addedPorts[index].Id;

    getVesselId = (index) => this.props.addedVessels[index].IMO;

    formatLocation = (point) => {
        const coords = wktParse(point);
        return formatCoordinates(coords[0], coords[1]);
    };

    renderPortRow = ({ data, index, style }) => {
        const port = data[index];
        const portSubtitle = port.Country ? port.Country.CountryName : this.formatLocation(port.Location);
        return (
            <div style={style}>
                <div className="sten-panel__section">
                    <h3 className="text-uppercase">{port.Name}</h3>
                    <h6 className="text-secondary">{portSubtitle}</h6>
                </div>
                <div className="sten-panel__separator" />
            </div>
        );
    };

    renderVesselRow = ({ data, index, style }) => {
        const vessel = data[index];
        const vesselTitle = vessel.Title;
        let vesselClassName = 'text-uppercase';
        if (!vessel.IsCompetition) {
            vesselClassName += ' text-primary';
        }
        return (
            <div style={style}>
                <div className="sten-panel__section">
                    <h3 className={vesselClassName}>{vesselTitle}</h3>
                    <h6 className="text-secondary">{getVesselTechnicalDetails(vessel)}</h6>
                </div>
                <div className="sten-panel__separator" />
            </div>
        );
    };

    getSelectedRange = memoize((selectedRange) => ({
        rangeStart: selectedRange.from,
        rangeEnd: selectedRange.to
    }));

    autoSizerStyle = { width: '100%', height: '100%' };

    render() {
        let noteMessageText = t('NOTE_MESSAGE_CALCULATE');
        if (this.props.isPortCallsAnalyticsCalculated) {
            noteMessageText = t('NOTE_MESSAGE_EXPORT', {
                count: this.props.portCallsAnalytics.stopsCount,
                vesselsCount: this.props.addedVessels.length
            });
        }
        return (
            <div className="sten-port-calls-analytics-page">
                <div className="sten-content">
                    <Validation.Form onSubmit={this.props.calculateCallsAnalytics}>
                        <div className="sten-content__header flex-row">
                            <div className="flex-shrink">
                                <h1 className="sten-content__title">
                                    {t('TITLE')}
                                </h1>
                            </div>
                            <div className="flex-sm-grow flex-shrink">
                                <Validation.Wrapper validations={{ dateRange: true }} hintsOnHover>
                                    <DateRangePopup
                                        inputClassName="sten-port-calls-analytics-page__datepicker-input"
                                        isUTC
                                        alignment="center"
                                        shouldRenderDefaultPresets
                                        onRangeSelect={this.handleDateRangeChange}
                                        name="dataRange"
                                        value={this.getSelectedRange(this.props.selectedRange)}
                                    />
                                </Validation.Wrapper>
                            </div>
                            <div className="flex-shrink hide-sm">
                                <p className="text-secondary">{noteMessageText}</p>
                            </div>
                            <div className="flex-shrink">
                                {this.props.isPortCallsAnalyticsCalculated ? (
                                    <button
                                        type="button"
                                        className="btn btn--primary"
                                        onClick={this.props.generateXLSX}
                                    >
                                        {t('EXPORT_XLSX')}
                                    </button>
                                ) : (
                                    <Validation.Button className="btn btn--primary">
                                        {t('CALCULATE')}
                                    </Validation.Button>
                                )}
                            </div>
                        </div>
                    </Validation.Form>
                    <ScrollArea
                        className="sten-content__body"
                        contentClassName="spread-v--md"
                    >
                        <div className="sten-content__section spread-v--md">
                            <div className="flex-row flex-row--md spread-v--md">
                                <div className="col-md-12 col-24 col-pad--sm">
                                    <div className="sten-panel spread-v--md">
                                        <div className="sten-panel__header">
                                            <div className="flex-grow">
                                                <div className="flex-row flex-center">
                                                    <div className="flex-grow">
                                                        <h3 className="sten-panel__title">
                                                            {t('PORTS')}
                                                        </h3>
                                                    </div>
                                                    <div className="flex-shrink">
                                                        <button
                                                            className="btn-link text-uppercase"
                                                            onClick={this.openPortsSidebar}
                                                        >
                                                            <span className="btn__icon icon icon-edit" />
                                                            {t('EDIT_PORTS')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sten-panel__body sten-port-calls-analytics-page__items-content">
                                            {this.props.addedPorts && this.props.addedPorts.length > 0
                                                ? (
                                                    <AutoSizer style={this.autoSizerStyle}>
                                                        {({ height, width }) => (
                                                            <FixedSizeList
                                                                itemData={this.props.addedPorts}
                                                                totalHeight={height}
                                                                itemCount={this.props.addedPorts.length}
                                                                totalWidth={width}
                                                                itemSize={this.state.fontSize * listItemHeight}
                                                                itemKey={this.getPortId}
                                                                ItemComponent={this.renderPortRow}
                                                            />
                                                        )}
                                                    </AutoSizer>
                                                )
                                                : (<EmptyContent>{t('ADD_PORTS_MESSAGE')}</EmptyContent>)
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-24 col-pad--sm">
                                    <div className="sten-panel spread-v--md">
                                        <div className="sten-panel__header">
                                            <div className="flex-grow">
                                                <div className="flex-row flex-center">
                                                    <div className="flex-grow">
                                                        <h3 className="sten-panel__title">
                                                            {t('VESSELS')}
                                                        </h3>
                                                    </div>
                                                    <div className="flex-shrink">
                                                        <button
                                                            className="btn-link text-uppercase"
                                                            onClick={this.openVesselsSidebar}
                                                        >
                                                            <span className="btn__icon icon icon-edit" />
                                                            {t('EDIT_VESSELS')}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sten-panel__body sten-port-calls-analytics-page__items-content">
                                            {this.props.addedVessels && this.props.addedVessels.length > 0
                                                ? (
                                                    <AutoSizer style={this.autoSizerStyle}>
                                                        {({ height, width }) => (
                                                            <FixedSizeList
                                                                itemData={this.props.addedVessels}
                                                                totalHeight={height}
                                                                itemCount={this.props.addedVessels.length}
                                                                totalWidth={width}
                                                                itemSize={this.state.fontSize * listItemHeight}
                                                                itemKey={this.getVesselId}
                                                                ItemComponent={this.renderVesselRow}
                                                            />
                                                        )}
                                                    </AutoSizer>
                                                )
                                                : (<EmptyContent>{t('ADD_VESSELS_MESSAGE')}</EmptyContent>)
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ScrollArea>
                </div>
                <AfterInitialMount>
                    <RightSideBar isCollapsed={this.props.openSidebar === sidebars.NONE}>
                        {this.props.openSidebar === sidebars.PORTS && (
                            <PortsSidebar onClose={this.closeSidebar} />
                        )}
                        {this.props.openSidebar === sidebars.VESSELS && (
                            <VesselsSidebar onClose={this.closeSidebar} />
                        )}
                    </RightSideBar>
                </AfterInitialMount>
            </div>
        );
    }
}

PortAnalyticsPage.propTypes = {
    addedPorts: PropTypes.arrayOf(PropTypes.object),
    addedVessels: PropTypes.arrayOf(PropTypes.object),
    calculateCallsAnalytics: PropTypes.func.isRequired,
    generateXLSX: PropTypes.func.isRequired,
    openSidebar: PropTypes.string.isRequired,
    portCallsAnalytics: PropTypes.objectOf(PropTypes.any),
    resetAll: PropTypes.func.isRequired,
    selectedRange: PropTypes.objectOf(PropTypes.any).isRequired,
    setSidebar: PropTypes.func.isRequired,
    isPortCallsAnalyticsCalculated: PropTypes.bool.isRequired,
    updateDateFilter: PropTypes.func.isRequired
};

PortAnalyticsPage.defaultProps = {
    addedPorts: [],
    addedVessels: [],
    portCallsAnalytics: null
};

function mapStateToProps(state) {
    return {
        addedPorts: state.portCallsAnalyticsReducer.addedPorts,
        addedVessels: state.portCallsAnalyticsReducer.addedVessels,
        openSidebar: state.portCallsAnalyticsReducer.openSidebar,
        portCallsAnalytics: getCalculatedPortCallsAnalytics(state),
        selectedRange: state.portCallsAnalyticsReducer.selectedRange,
        isPortCallsAnalyticsCalculated: state.portCallsAnalyticsReducer.isPortCallsAnalyticsCalculated
    };
}

function mapDispatchToProps(dispatch) {
    return {
        calculateCallsAnalytics: () => calculateCallsAnalytics(dispatch),
        generateXLSX: () => generateXLSX(dispatch),
        resetAll: () => resetAll(dispatch),
        setSidebar: sidebar => setSidebar(dispatch, sidebar),
        updateDateFilter: (rangeStart, rangeEnd) => updateDateFilter(dispatch, rangeStart, rangeEnd)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PortAnalyticsPage);
