import React from 'react';
/* components */
import ContentArray from 'components/content-array/content-array';
/* common */
import { t, commonFields } from '../vessel-report-common-fields';

export const fieldMap = {

    // Basic info

    Vessel: commonFields.Vessel(),
    CallSign: commonFields.CallSign(),
    VoyageNumber: commonFields.VoyageNumber(),
    ReportDate: commonFields.ReportDate(),

    // Port information

    Point: commonFields.Point('Point', t('PORT_OF_DEPARTURE')),
    Charterer: commonFields.String('Charterer', t('CHARTERER')),
    EndOfSeaPassage: commonFields.DateTime('EndOfSeaPassage', 'EndOfSeaPassageOffset', t('END_OF_SEA_PASSAGE')),
    AnchoredCommencedDrifting: commonFields.DateTime(
        'AnchoredCommencedDrifting',
        'AnchoredCommencedDriftingOffset',
        t('ANCHORED_COMMENCED_DRIFTING')
    ),
    AnchorAweighStoppedDrifting: commonFields.DateTime(
        'AnchorAweighStoppedDrifting',
        'AnchorAweighStoppedDriftingOffset',
        t('ANCHOR_AWEIGH_STOPPED_DRIFTING')
    ),
    NoticeOfReadiness: commonFields.DateTime('NoticeOfReadiness', 'NoticeOfReadinessOffset', t('NOR_TENDERED')),
    FreePractiqueGranted: commonFields.DateTime(
        'FreePractiqueGranted',
        'FreePractiqueGrantedOffset',
        t('FREE_PRATIQUE_GRANTED')
    ),
    PilotOnboard: commonFields.DateTime('PilotOnboard', 'PilotOnboardOffset', t('PILOT_ONBOARD')),
    AllFast: commonFields.DateTime('AllFast', 'AllFastOffset', t('ALL_FAST')),
    HosesConnected: commonFields.DateTime('HosesConnected', 'HosesConnectedOffset', t('HOSES_CONNECTED')),

    // Remarks

    Remarks: commonFields.Remarks(),

    // Cargo Operations

    CargoOperations: {
        CommencedDate: (index) => commonFields.DateTime(
            'CommencedDate',
            'CommencedDateOffset',
            t('COMMENCED_DATE'),
            index
        ),
        CompletedDate: (index) => commonFields.DateTime(
            'CompletedDate',
            'CompletedDateOffset',
            t('COMPLETED_DATE'),
            index
        )
    },

    // Other info

    HosesDisconnected: commonFields.DateTime('HosesDisconnected', 'HosesDisconnectedOffset', t('HOSES_DISCONNECTED')),
    CargoDocumentsOnboard: commonFields.DateTime(
        'CargoDocumentsOnboard',
        'CargoDocumentsOnboardOffset',
        t('CARGO_DOCUMENTS_ONBOARD')
    ),
    Unmoored: commonFields.DateTime('Unmoored', 'UnmooredOffset', t('UNMOORED'))
};

const cargoOperationsProps = [
    'CommencedDate',
    'CompletedDate'
];

const emptyCargoOperation = {
    CommencedDate: '',
    CommencedDateOffset: '',
    CompletedDate: '',
    CompletedDateOffset: ''
};

const getCargoOperationsChangeHandler = (handleChange) => (value) => handleChange('CargoOperations', value);

export const dataMap = {
    CargoOperations: (params) => (
        <ContentArray
            fieldMap={fieldMap.CargoOperations}
            onChange={getCargoOperationsChangeHandler(params.onChange)}
            data={params.data.CargoOperations}
            isEditing={params.isEditing}
            editingOptions={params.editingOptions}
            fields={cargoOperationsProps}
            newItem={emptyCargoOperation}
            itemTitle={t('CARGO_ITEM_TITLE')}
            minLength={1}
            validationRules={params.validationRules.CargoOperations}
        />
    )
};

export default { fieldMap, dataMap };
