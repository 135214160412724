import React from 'react';
import { Outlet } from 'react-router';
/* router */
import { TRouter, withRouter } from 'app-router';
/* services */
import AuthService from 'services/auth-service';
import ConfigService from 'services/config-api/config-service';
import LocalStorageService from 'services/local-storage-service';

export class AuthWrapper extends React.PureComponent {
    state = {
        isAuthenticated: false
    };

    pageShowEventListener = (event) => {
        if (event.persisted && this.state.isAuthenticated) {
            AuthService.userManager.getUser().then(user => {
                if (!user) {
                    window.location.reload();
                }
            });
        }
    };

    componentDidMount() {
        LocalStorageService.setLastVisitedLocation(this.props.router.location);

        AuthService.userManager.getUser().then(user => {
            if (user && user.access_token) {
                ConfigService.setTenantId(user.profile.tenant_id).then(() => {
                    window.addEventListener('pageshow', this.pageShowEventListener);
                    this.setState({ isAuthenticated: true });
                });
            } else {
                this.setState({ isAuthenticated: false });
                AuthService.login(true);
            }
        });
    }

    componentWillUnmount() {
        window.removeEventListener('pageshow', this.pageShowEventListener);
    }

    render() {
        if (this.state.isAuthenticated) {
            return <Outlet />;
        }
        return null;
    }
}

AuthWrapper.propTypes = {
    router: TRouter.isRequired
};

export default withRouter(AuthWrapper);
