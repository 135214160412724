import { takeEvery, put, select, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import moment from 'moment';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { wktMaker, formatCoordinates } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
import RouteHelper from 'utils/helpers/route-helper';
import { extractBunkerOptionsAndValues } from './estimator-reducer-helpers';
import { getEuEtsVoyagePrice } from 'components/euets/euets-saga';
/* actions */
import { ActionTypes } from './estimator-actions';
import { ActionTypes as UserActionTypes } from 'pages/user-pages/user-actions';
/* services */
import VoyageService from 'services/core-api/voyage-service';
import VesselService from 'services/core-api/vessel-service';
import NotificationService from 'services/notification-api/notification-service';
import TimeService from 'services/core-api/time-service';
import PortService from 'services/core-api/port-service';
import RoutingService from 'services/core-api/route-service';
/* constants */
import { areaOptions, canalOptions } from './estimator-constants';

const emptyArray = [];

function* getCalculationWithEmissionsBySections(itinerary) {
    const sectionParams = [];
    let previousPoint = null;
    let currentPoint = null;
    itinerary.ItineraryPorts.forEach(point => {
        currentPoint = { ...point, TimeInPort: point.TimeInPort / 2 };
        if (previousPoint) {
            const ItineraryPorts = [previousPoint, currentPoint];
            sectionParams.push({
                ...itinerary,
                OriginalRemainingItinerary: [],
                ItineraryPorts
            });
        }
        previousPoint = currentPoint;
    });

    const { sectionCalculations, calculation } = yield all({
        sectionCalculations: all(sectionParams.map(VoyageService.getVoyageEstimatorCalculation)),
        calculation: VoyageService.getVoyageEstimatorCalculation(itinerary)
    });

    if (!calculation) {
        return null;
    }
    return {
        ...calculation,
        OrbitRoutes: {
            ...calculation.OrbitRoutes,
            Sections: calculation.OrbitRoutes.Sections.map((section, index) => ({
                ...section,
                Emissions: sectionCalculations[index]?.Calculations?.CalculationValues?.Emissions
            }))
        }
    };
}

function* getCalculation() {
    const {
        maxFuturePoints,
        calculationPoints,
        calculationVoyageItinerary,
        calculationVoyageRoute,
        filters: {
            selectedCanals,
            selectedAreas,
            selectedEcaBunker,
            selectedNonEcaBunker,
            selectedEuEtsVesselType,
            defaultSpeed,
            calculationVessel,
            calculationVesselStartingPosition
        }
    } = yield select(state => state.estimatorReducer);
    const futurePoints = calculationPoints.filter(point => !point.Ata);
    if (futurePoints.length > maxFuturePoints) {
        toast(t('CALCULATION_MESSAGE.MAXIMUM_FUTURE_POINTS', { max: maxFuturePoints }), { type: toast.TYPE.WARNING });
    }
    let startingPoint = yield select(state => state.estimatorReducer.startingPoint);
    const itinerary = {
        ItineraryPorts: [],
        OriginalRemainingItinerary: [],
        EcaBunker: selectedEcaBunker,
        NonEcaBunker: selectedNonEcaBunker
    };
    areaOptions.forEach((option) => {
        itinerary[option.prop] = false;
    });
    canalOptions.forEach((option) => {
        itinerary[option.prop] = false;
    });
    selectedCanals.forEach(selectedCanal => {
        itinerary[selectedCanal.prop] = true;
    });
    selectedAreas.forEach(area => {
        itinerary[area.prop] = true;
    });
    if (calculationVessel && calculationVesselStartingPosition && calculationVesselStartingPosition.value === 3) {
        startingPoint = {
            IsBallast: !calculationVessel.Vessel.IsLaden,
            Point: { Location: calculationVessel.Location, IsCustomPoint: true }
        };
        itinerary.OriginalRemainingItinerary.push(startingPoint);
    } else if (startingPoint) {
        startingPoint = {
            ...startingPoint,
            TimeInPort: startingPoint.TimeInPort
                ? TimeHelper.getDurationFromString(startingPoint.TimeInPort).asMilliseconds()
                : null
        };
    }
    if (calculationVessel?.Vessel?.Imo) {
        itinerary.Imo = calculationVessel.Vessel.Imo;
    }
    if (calculationVoyageItinerary?.length) {
        calculationVoyageItinerary.forEach((itineraryPoint) => {
            if (moment(itineraryPoint.Eta).isAfter(moment.utc())) {
                itinerary.OriginalRemainingItinerary.push(itineraryPoint);
            }
        });
    }
    if (calculationVoyageRoute) {
        itinerary.OriginalRoutes = calculationVoyageRoute;
    }
    if (calculationPoints?.length) {
        const historyPorts = [];
        calculationPoints.forEach((point, index) => {
            if (index + 1 !== calculationPoints.length || point.Point) {
                const TimeInPort = TimeHelper.getDurationFromString(point.TimeInPort).asMilliseconds();
                if (!point.Atd) {
                    if (point.Ata) {
                        startingPoint = {
                            ...startingPoint,
                            TimeInPort,
                            Eta: point.Eta
                        };
                        historyPorts.push({
                            ...point,
                            TimeInPort
                        });
                    } else if (point.Point?.Location) {
                        itinerary.ItineraryPorts.push({
                            Point: point.Point,
                            Speed: point.IsSpeedSelected ? defaultSpeed : null,
                            WeatherFactor: point.WeatherFactor,
                            IsSpeedSelected: point.IsSpeedSelected,
                            Eta: point.IsSpeedSelected ? null : point.Eta,
                            IsBallast: point.IsBallast,
                            TimeInPort
                        });
                    }
                } else {
                    historyPorts.push({
                        ...point,
                        TimeInPort
                    });
                }
            }
        });
        let startingPointAdded = false;
        if (startingPoint?.Point?.Location) {
            startingPointAdded = true;
            itinerary.ItineraryPorts.unshift(startingPoint);
        }
        if (itinerary.ItineraryPorts.length > 1) {
            const response = yield getCalculationWithEmissionsBySections(itinerary);
            if (response) {
                yield getCalculationEuEts({
                    itineraryPorts: response.ItineraryPorts,
                    vesselType: selectedEuEtsVesselType,
                    sections: response.OrbitRoutes?.Sections,
                    imo: itinerary.Imo,
                    fuel: selectedNonEcaBunker?.Code,
                    fuel_eca: selectedEcaBunker?.Code
                });
                let itineraryPorts = startingPointAdded ? response.ItineraryPorts.slice(1) : response.ItineraryPorts;
                if (historyPorts.length) {
                    itineraryPorts = historyPorts.concat(itineraryPorts);
                }
                yield put({
                    type: ActionTypes.ESTIMATOR_SET_POINTS,
                    points: itineraryPorts,
                    isCalculated: true
                });

                let routePoints = [];
                if (!response.OrbitRoutes) {
                    toast(t('CALCULATION_MESSAGE.ORBIT_ROUTING_ERROR'), { type: toast.TYPE.WARNING });
                } else if (response.OrbitRoutes.Sections && response.OrbitRoutes.Sections.length) {
                    response.OrbitRoutes.Sections.forEach((section) => {
                        routePoints = routePoints.concat(section.Waypoints);
                    });
                }
                yield put({ type: ActionTypes.ESTIMATOR_SET_ROUTE_POINTS, routePoints });
                yield put({ type: ActionTypes.ESTIMATOR_SET_CALCULATIONS, response });
                toast(t('CALCULATION_MESSAGE.CALCULATION_SUCCESS'), { type: toast.TYPE.SUCCESS });
            }
        } else {
            toast(t('CALCULATION_MESSAGE.ONE_PORT_MINIMUM'), { type: toast.TYPE.WARNING });
        }
    } else {
        toast(t('CALCULATION_MESSAGE.ONE_PORT_MINIMUM'), { type: toast.TYPE.WARNING });
    }
}

let calculationParams = null;

function* getCalculationEuEts(params) {
    if (params) {
        calculationParams = params;
    }
    const calculationEuEts = yield getEuEtsVoyagePrice(calculationParams);
    if (calculationEuEts) {
        yield put({ type: ActionTypes.ESTIMATOR_SET_CALCULATION_EU_ETS, calculationEuEts });
    }
}

function* getBunkerOptions() {
    const allFuelTypes = yield VesselService.getTenantFuelTypes();
    const {
        bunkerOptions,
        selectedEcaBunker,
        selectedNonEcaBunker
    } = extractBunkerOptionsAndValues(allFuelTypes);
    yield put({
        type: ActionTypes.ESTIMATOR_SET_BUNKER_OPTIONS_AND_INITIAL_VALUES,
        options: {
            bunkerOptions
        },
        values: {
            selectedEcaBunker,
            selectedNonEcaBunker
        }
    });
}

function* getVoyageItinerary(voyage) {
    const permissions = yield select(state => state.userReducer.permissions);
    let voyageItinerary = null;
    if (permissions?.GetVoyageItinerary && voyage?.VoyageId) {
        const response = yield VoyageService.getVoyageItinerary({
            VoyageId: voyage.VoyageId
        });
        const now = moment.utc();

        voyageItinerary = response.map((point) => {
            let Eta = point.Eta;
            let WaitingTime = null;
            let TimeInPort = point.TimeInPort;
            if (point.Activities && point.Activities[0] && point.Activities[0].Code === 'W') {
                WaitingTime = new Date(point.Activities[0].Etd) - new Date(point.Activities[0].Eta);
                TimeInPort -= WaitingTime;
                if (point.Activities[1]) {
                    Eta = point.Activities[1].Eta;
                }
            }
            let utcEta;
            let utcEtd;
            if (point.Point?.TimeZoneId) {
                utcEta = Eta ? moment.tz(Eta, point.Point.TimeZoneId).utc() : null;
                utcEtd = point.Etd ? moment.tz(point.Etd, point.Point.TimeZoneId).utc() : null;
            } else {
                utcEta = Eta ? moment.utc(Eta) : null;
                utcEtd = point.Etd ? moment.utc(point.Etd) : null;
            }
            return {
                ...point,
                Eta,
                Ata: utcEta && utcEta.isBefore(now) ? Eta : null,
                Atd: utcEtd && utcEtd.isBefore(now) ? point.Etd : null,
                TimeInPort,
                WaitingTime
            };
        });
    }
    return voyageItinerary || emptyArray;
}

function* getVoyageAisPoints(action) {
    let voyage = yield select(state => state.estimatorReducer.calculationVoyage);
    if (action.voyage) {
        voyage = action.voyage;
    }
    const aisResponse = yield VesselService.getHistoricalById(voyage.IMO, {
        startTime: voyage.StartDate,
        endTime: moment.utc(voyage.EndDate).add(1, 'h').toISOString()
    });
    let voyageAisPoints = null;
    if (aisResponse && aisResponse.Entries && aisResponse.Entries.length && aisResponse.Entries[0]) {
        voyageAisPoints = aisResponse.Entries[0].Entries;
    }
    yield put({ type: ActionTypes.ESTIMATOR_SET_VOYAGE_AIS_POINTS, voyageAisPoints });
}

function* getVoyageRoutePoints(action) {
    const { calculationVoyage, filters } = yield select(state => state.estimatorReducer);
    const vessel = action.vessel || filters.calculationVessel;
    const voyage = action.voyage || calculationVoyage;
    const itinerary = yield getVoyageItinerary(voyage);
    const RoutingPoints = [{ Location: vessel.Location, IsCustomPoint: true }];
    const calculationPoints = [];
    let noFuturePoints = true;
    if (itinerary && itinerary.length > 0) {
        itinerary.forEach((point) => {
            calculationPoints.push({
                ...point,
                Point: { ...point.Point, IsCustomPoint: false }
            });
            if (!point.Ata && point.Point?.Location) {
                noFuturePoints = false;
                RoutingPoints.push({ Location: point.Point.Location, IsCustomPoint: false });
            }
        });
    }
    if (noFuturePoints && vessel?.NextOpenPort?.Location) {
        RoutingPoints.push({ Location: vessel.NextOpenPort.Location, IsCustomPoint: false });
    }
    let voyageRoutePoints = null;
    let voyageRoute = null;
    if (RoutingPoints.length > 1) {
        const routeResponse = yield RoutingService.get({ IMO: voyage.IMO, RoutingPoints });
        const extractedVoyageRoute = RouteHelper.extractRoutePoints(routeResponse);
        voyageRoutePoints = extractedVoyageRoute.routePoints;
        voyageRoute = extractedVoyageRoute.route;
    }
    yield all([
        put({ type: ActionTypes.ESTIMATOR_SET_VOYAGE_ITINERARY, voyageItinerary: itinerary }),
        put({ type: ActionTypes.ESTIMATOR_SET_POINTS, points: calculationPoints }),
        put({ type: ActionTypes.ESTIMATOR_SET_VOYAGE_ROUTE, voyageRoute, voyageRoutePoints })
    ]);
}

function* getCalculationVoyage(action) {
    if (action.startingPosition.value === 3) {
        yield all([getVoyageAisPoints(action), getVoyageRoutePoints(action)]);
    } else {
        yield put({ type: ActionTypes.ESTIMATOR_RESET_CALCULATION_VOYAGE });
    }
}

function* getVesselNotifications(action) {
    const permissions = yield select(state => state.userReducer.permissions);
    let notifications = emptyArray;
    if (permissions.GetNotificationsByImo && action.vessel?.Vessel?.Imo) {
        const response = yield NotificationService.getNotificationsByImo(action.vessel.Vessel.Imo, {
            Limit: 5000,
            Offset: 0,
            Dismissed: false
        });
        notifications = response && response.Notifications ? response.Notifications : null;
    }
    return notifications;
}

function* getVesselCurrentVoyage(action) {
    const permissions = yield select(state => state.userReducer.permissions);
    let currentVoyage = null;
    if (permissions.GetVoyage && action.vessel?.Vessel?.Imo) {
        const currentDate = moment.utc().startOf('h').toISOString();
        const response = yield VesselService.getAllVesselVoyages(action.vessel.Vessel.Imo, { timestamp: currentDate });
        if (response && response.length > 0 && response[0]) {
            currentVoyage = {
                ...response[0],
                EndDate: response[0].EndDate || moment().add(1, 'h').toISOString()
            };
        }
    }
    return currentVoyage;
}

function* getVesselCurrentPosition(action) {
    let vesselPosition = null;
    if (action.vessel?.Vessel?.Imo) {
        const startTime = moment().startOf('h').toISOString();
        const endTime = moment(startTime).add(1, 'h').toISOString();
        const response = yield VesselService.getHistoricalById(action.vessel.Vessel.Imo, {
            startTime, endTime, Latest: true
        });
        if (response?.Entries?.length > 0) {
            vesselPosition = {
                ...response.Entries[0],
                IMO: response.Entries[0].IMO,
                Title: response.Entries[0].VesselName.trim(),
                CurrentAis: {
                    Longitude: response.Entries[0].Entries[0].Location.Longitude,
                    Latitude: response.Entries[0].Entries[0].Location.Latitude,
                    Heading: response.Entries[0].Entries[0].Heading
                },
                Draught: response.Entries[0].Entries[0].Draught,
                SpeedOverGround: response.Entries[0].Entries[0].SpeedOverGround,
                Destination: response.Entries[0].Entries[0].Destination,
                PositionTime: response.Entries[0].Entries[0].PositionTime,
                LocationTime: response.Entries[0].Entries[0].LocationTime,
                ETA: response.Entries[0].Entries[0].ETA,
                IsLaden: response.Entries[0].Entries[0].IsLaden
            };
        }
    }
    return vesselPosition;
}

function* setVessel(action) {
    const { position, notifications, voyage } = yield all({
        position: getVesselCurrentPosition(action),
        notifications: getVesselNotifications(action),
        voyage: getVesselCurrentVoyage(action)
    });
    yield put({
        type: ActionTypes.ESTIMATOR_SET_CALCULATION_VESSEL,
        vessel: action.vessel,
        position,
        notifications,
        voyage
    });
    if (action.isDeviation && voyage) {
        const { startingPositionOptions } = yield select(state => state.estimatorReducer.options);
        yield put({
            type: ActionTypes.ESTIMATOR_SET_VESSEL_STARTING_POSITION,
            startingPosition: startingPositionOptions[3],
            voyage,
            vessel: action.vessel
        });
    }
}

function* getVessel(action) {
    const vessels = yield VesselService.searchByCriteria({ searchCriteria: action.vesselImo, limit: 10 });
    if (vessels && vessels[0]) {
        yield put({
            type: ActionTypes.ESTIMATOR_SET_VESSEL,
            vessel: {
                ...vessels[0],
                Id: vessels[0].Vessel.Imo.toString(),
                IsPrimary: vessels[0].Vessel.IsVesselInUserScope,
                Title: vessels[0].Vessel.Title
            },
            isDeviation: action.isDeviation
        });
    }
}

function* getTimeZoneOffsetId(location) {
    return yield TimeService.getTimeZoneOffsetId(location);
}

function* setCustomCalculationPoint(action) {
    let index = action.index;
    const { activePinPointId, calculationPoints, maxFuturePoints } = yield select(state => state.estimatorReducer);
    if (index === undefined) {
        if (activePinPointId === 'S') {
            index = activePinPointId;
        } else {
            index = calculationPoints.findIndex((point) => point.CalculationPointId === activePinPointId);
        }
    }
    if (index !== null) {
        const coordinates = action.coordinates;
        const timeZoneInfo = yield getTimeZoneOffsetId({ Location: wktMaker('point', coordinates) });
        const point = {
            Point: {
                Location: wktMaker('point', coordinates),
                Name: formatCoordinates(coordinates[0], coordinates[1]),
                Type: 3,
                TimeZoneId: timeZoneInfo ? timeZoneInfo.TimeZoneId : null,
                Coordinates: coordinates,
                IsCustomPoint: true
            }
        };
        if (index === 'S') {
            yield put({ type: ActionTypes.ESTIMATOR_UPDATE_STARTING_POINT, point });
            if (calculationPoints.length === 0) {
                yield put({ type: ActionTypes.ESTIMATOR_ADD_POINT, index: 0 });
            }
        } else {
            yield put({ type: ActionTypes.ESTIMATOR_UPDATE_POINT, point, index });
            const futurePoints = calculationPoints.filter(point => !point.Ata);
            if (futurePoints.length < maxFuturePoints && index === calculationPoints.length - 1) {
                yield put({ type: ActionTypes.ESTIMATOR_ADD_POINT, index: index + 1 });
            }
        }
    }
}

function* sendRequest(action) {
    const { selectedVoyage } = yield select(state => state.homeReducer);
    const { calculationVessel, defaultSpeed } = yield select(state => state.estimatorReducer.filters);
    const calculationPoints = yield select(state => state.estimatorReducer.calculationPoints);
    const calculations = yield select(state => state.estimatorReducer.calculations);
    const itinerary = [];
    calculationPoints.forEach((point, index) => {
        if (index + 1 !== calculationPoints.length || point.Point) {
            itinerary.push({
                ...point,
                Speed: point.IsSpeedSelected ? defaultSpeed : point.Speed,
                Time: point.Time ? TimeHelper.getDurationFromString(point.Time).asMilliseconds() : null,
                TimeInPort: point.TimeInPort
                    ? TimeHelper.getDurationFromString(point.TimeInPort).asMilliseconds()
                    : null
            });
        }
    });
    const startingPoint = yield select(state => state.estimatorReducer.startingPoint);
    if (startingPoint?.Point?.Location) {
        itinerary.unshift(startingPoint);
    }
    const request = {
        IMO: calculationVessel?.Vessel?.Imo || null,
        VoyageId: selectedVoyage ? selectedVoyage.VoyageId : null,
        Comment: action.comment,
        ItineraryPorts: itinerary,
        Calculations: calculations || null
    };
    const response = yield VoyageService.sendDeviationRequest(request);
    if (response) {
        yield put({ type: ActionTypes.ESTIMATOR_REQUEST_SENT });
    }
}

function* getClickedPoint(action) {
    let point = null;
    const { calculationPoints, startingPoint, maxFuturePoints } = yield select(state => state.estimatorReducer);
    const shouldAddStartingPoint = !calculationPoints.length && startingPoint && !startingPoint.Point;
    if (action.portId) {
        yield PortService.searchById(action.portId).then((res) => {
            if (res) {
                point = res && res.length > 0 ? res[0] : null;
                point.IsCustomPoint = false;
            }
        });
        if (point) {
            if (shouldAddStartingPoint) {
                yield put({
                    type: ActionTypes.ESTIMATOR_UPDATE_STARTING_POINT,
                    point: { ...startingPoint, Point: point }
                });
                yield put({
                    type: ActionTypes.ESTIMATOR_ADD_POINT,
                    index: 0
                });
            } else {
                const futurePoints = calculationPoints.filter(point => !point.Ata);
                if (futurePoints.length < maxFuturePoints) {
                    yield put({
                        type: ActionTypes.ESTIMATOR_ADD_POINT,
                        index: calculationPoints.length
                    });
                }
                let addTwoPoints = true;
                let updateIndex = calculationPoints.length;
                if (calculationPoints[calculationPoints.length - 1]
                    && !calculationPoints[calculationPoints.length - 1].Point) {
                    updateIndex = calculationPoints.length - 1;
                    addTwoPoints = false;
                }
                if (futurePoints.length < maxFuturePoints
                    || (futurePoints.length >= maxFuturePoints && !addTwoPoints)) {
                    yield put({
                        type: ActionTypes.ESTIMATOR_UPDATE_POINT,
                        point: { ...calculationPoints[calculationPoints.length], Point: point },
                        index: updateIndex
                    });
                } else {
                    toast(
                        t('CALCULATION_MESSAGE.MAXIMUM_FUTURE_POINTS', { max: maxFuturePoints }),
                        { type: toast.TYPE.WARNING }
                    );
                }
                if (addTwoPoints && futurePoints.length < maxFuturePoints - 1) {
                    yield put({
                        type: ActionTypes.ESTIMATOR_ADD_POINT,
                        index: calculationPoints.length + 1
                    });
                }
            }
        }
    }
}

function* handleUserSettingUpdate({ newSettings }) {
    if (newSettings?.Currency) {
        const { calculationEuEts } = yield select(state => state.estimatorReducer);
        if (calculationEuEts) {
            yield getCalculationEuEts();
        }
    }
}

export default function* estimatorSaga() {
    yield takeEvery(ActionTypes.ESTIMATOR_GET_CALCULATION, getCalculation);
    yield takeEvery(ActionTypes.ESTIMATOR_GET_VESSEL, getVessel);
    yield takeEvery(ActionTypes.ESTIMATOR_SET_VESSEL, setVessel);
    yield takeEvery(ActionTypes.ESTIMATOR_SET_VESSEL_STARTING_POSITION, getCalculationVoyage);
    yield takeEvery(ActionTypes.ESTIMATOR_SET_CUSTOM_POINT, setCustomCalculationPoint);
    yield takeEvery(ActionTypes.ESTIMATOR_SEND_REQUEST, sendRequest);
    yield takeEvery(ActionTypes.ESTIMATOR_SET_CLICKED_POINT_DATA, getClickedPoint);
    yield takeEvery(ActionTypes.ESTIMATOR_GET_BUNKER_OPTIONS, getBunkerOptions);
    yield takeEvery(UserActionTypes.USER_SETTINGS_SET, handleUserSettingUpdate);
}
