export const ActionTypes = {
    COUNTER_PVAR_GET_COUNTER_PVAR_LIST: 'COUNTER_PVAR_GET_COUNTER_PVAR_LIST',
    COUNTER_PVAR_GET_COUNTER_PVAR_VALIDATION_DATA: 'COUNTER_PVAR_GET_COUNTER_PVAR_VALIDATION_DATA',
    COUNTER_PVAR_GET_SELECTED_VESSEL: 'COUNTER_PVAR_GET_SELECTED_VESSEL',
    COUNTER_PVAR_GET_SELECTED_VOYAGE: 'COUNTER_PVAR_GET_SELECTED_VOYAGE',
    COUNTER_PVAR_POST_COUNTER_PVAR_REQUEST: 'COUNTER_PVAR_POST_COUNTER_PVAR_REQUEST',
    COUNTER_PVAR_SET_COUNTER_PVAR_LIST: 'COUNTER_PVAR_SET_COUNTER_PVAR_LIST',
    COUNTER_PVAR_SET_VALIDATION_DATA: 'COUNTER_PVAR_SET_VALIDATION_DATA',
    COUNTER_PVAR_SET_SELECTED_VESSEL: 'COUNTER_PVAR_SET_SELECTED_VESSEL',
    COUNTER_PVAR_SET_SELECTED_VOYAGE: 'COUNTER_PVAR_SET_SELECTED_VOYAGE'
};

export const getCounterPvarList = (dispatch, voyageId) => {
    dispatch({
        type: ActionTypes.COUNTER_PVAR_GET_COUNTER_PVAR_LIST,
        voyageId
    });
};

export const getCounterPvarValidationData = (dispatch, voyageId) => {
    dispatch({
        type: ActionTypes.COUNTER_PVAR_GET_COUNTER_PVAR_VALIDATION_DATA,
        voyageId
    });
};

export const getSelectedVessel = (dispatch, vesselImo) => {
    dispatch({
        type: ActionTypes.COUNTER_PVAR_GET_SELECTED_VESSEL,
        vesselImo
    });
};

export const getSelectedVoyage = (dispatch, voyageId, vesselImo) => {
    dispatch({
        type: ActionTypes.COUNTER_PVAR_GET_SELECTED_VOYAGE,
        voyageId,
        vesselImo
    });
};

export const postCounterPvarRequest = (dispatch, counterPvarRequest) => {
    dispatch({
        type: ActionTypes.COUNTER_PVAR_POST_COUNTER_PVAR_REQUEST,
        counterPvarRequest
    });
};
