import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* helpers */
import { wktParse, getImportanceByRelativeZoomLevel, mapLonLat } from 'components/ol/ol-helpers';
/* services */
import ConfigService from 'services/config-api/config-service';
/* actions */
import { getSelectedAreas } from 'components/map-options/map-options-actions';
import { getPorts } from '../../weather-routing-actions';
import { emptyActivePanels } from 'components/right-side-bar/right-side-bar-actions';
/* selectors */
import {
    getCombinedPorts,
    getSelectedLegAisPoints,
    getSelectedLegAisRoute
} from '../../weather-routing-selectors';
/* components */
import DtnWeather from 'components/dtn-weather/dtn-weather';
import NauticalCharts from 'components/nautical-charts/nautical-charts';
import Ol from 'components/ol/ol';
import WeatherRoutingLegTooltip from '../tooltip/weather-routing-leg-tooltip';
import WniWeather from 'components/wni-weather/wni-weather';
/* config */
import config from './weather-routing-leg-map-config';

const animationLength = 600;

const mapViewProps = {
    zoom: 3
};

class WeatherRoutingLegMap extends React.PureComponent {
    state = {
        mapZoomControlElement: null
    };

    vesselOverlays = {};

    animationStartTime = null;

    componentDidMount() {
        this.props.getSelectedAreas();
        this.setState({ mapZoomControlElement: document.getElementById('stenMapZoomControls') });
        this.fitElementsOnMap();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.arePortsInvisible === true && this.props.arePortsInvisible === false) {
            this.getPorts(true);
        }
        if (prevProps.selectedRoutes !== this.props.selectedRoutes
            || prevProps.selectedLegAisPoints !== this.props.selectedLegAisPoints) {
            this.fitElementsOnMap();
        }
    }

    fitElementsOnMap = () => {
        let lineString = [];
        if (this.props.selectedRoutes.length > 0) {
            this.props.selectedRoutes.forEach(route => {
                lineString = [
                    ...lineString,
                    ...route.Points.map(mapLonLat)
                ];
                if (route.PortOfDeparture) {
                    lineString.push(wktParse(route.PortOfDeparture.Position));
                }
                if (route.PortOfDestination) {
                    lineString.push(wktParse(route.PortOfDestination.Position));
                }
            });
        }
        if (this.props.selectedLegAisPoints.length > 0) {
            lineString = [...lineString, ...this.props.selectedLegAisPoints];
        }
        if (lineString.length === 1) {
            this.map.setCenter(lineString[0]);
        } else if (lineString.length > 1) {
            this.map.fitElements(lineString, { padding: [40, 80, 40, 80], duration: 500 });
        }
    };

    updateSizeOnFrame = (timestamp) => {
        if (!this.animationStartTime) {
            this.animationStartTime = timestamp;
        }
        if (timestamp - this.animationStartTime < animationLength) {
            this.map.updateSize();
            window.requestAnimationFrame(this.updateSizeOnFrame);
        } else {
            this.animationStartTime = null;
        }
    };

    handleMapClick = (event, clickedFeature) => {
        if (!clickedFeature) {
            this.props.closeRightSidebar();
            return false;
        }
        return true;
    };

    handleMapPostRender = () => {
        if (this.map) {
            this.getPorts();
        }
    };

    handleMapHover = (event, hoveredFeature) => {
        if (this.wniWeatherTooltipRef) {
            this.wniWeatherTooltipRef.update(event, hoveredFeature);
        }
        if (this.tooltipRef && this.map) {
            this.tooltipRef.update(event, hoveredFeature, this.map);
        }
    };

    /* ports */
    getPorts = (forceGetPorts = false) => {
        if (forceGetPorts) {
            this.forceGetPorts = true;
        }
        if (!this.map) {
            return;
        }
        const relativeZoom = this.map.getRelativeZoom();
        const relativeZoomLevel = relativeZoom < 2 ? 1 : relativeZoom - 1;
        const importanceLevel = getImportanceByRelativeZoomLevel(relativeZoomLevel);

        if (this.forceGetPorts || importanceLevel !== this.currentImportanceLevel) {
            this.currentImportanceLevel = importanceLevel;
            this.props.getPorts({
                ImportanceLevel: importanceLevel,
                BBox: 'MULTIPOLYGON(((-180 -90,180 -90,180 90,-180 90,-180 -90)))'
            });
            this.forceGetPorts = false;
        }
    }

    getPortPrimaryColor = port => {
        if (port.selectedInVoyage && !!port.Atd) {
            return config.port.primaryColor;
        }
        return config.port.secondaryColor;
    };

    getPortSecondaryColor = (port, isHovered) => {
        if (isHovered || port.selectedInVoyage) {
            return config.port.secondaryColor;
        }
        return null;
    };

    getPortHidden = port => {
        return this.props.arePortsInvisible && !port.selectedInVoyage;
    };

    getPortLabel = port => {
        let label = '';
        if (port) {
            label = port.Name;
            if (port.selectedInVoyage && port.Activities && port.Activities.length > 0) {
                label += ` (${port.Activities.map(activity => activity.Code).join(',')})`;
            }
        }
        return label;
    };

    getPortHideLabel = (port, isHovered, zoomLevel) => {
        return !port.selectedInVoyage && !isHovered && config.port.labelMinZoomLevel > zoomLevel;
    };

    getPortOpacity = (port, isHovered) => {
        if (isHovered) {
            return 1;
        }
        if (port.selectedInVoyage) {
            return 0.8;
        }
        return 0.3;
    };

    getPortZIndex = (port, isHovered) => {
        const zIndex = port.ImportanceLevel || 0;
        if (isHovered) {
            return zIndex + 21;
        }
        if (port.selectedInVoyage) {
            return zIndex + 20;
        }
        return zIndex;
    };

    /* /ports */

    areasOfInterestElementProps = {
        lineColor: area => config.areaOfInterest[area.AreaTypeId]?.lineColor || config.areaOfInterest.lineColorDefault,
        lineWidth: config.areaOfInterest.lineWidth,
        label: 'Description',
        wkt: 'AreaDefinition'
    };

    historicalLadenApproxRouteElementProps = {
        color: config.route.ladenApprox.color,
        width: config.route.ladenApprox.width,
        lineDash: config.route.ladenApprox.lineDash,
        lineDashMaxZoom: config.route.ladenApprox.lineDashMaxZoom,
        zoomColor: config.route.ladenApprox.zoomColor,
        zoomWidth: config.route.ladenApprox.zoomWidth
    };

    historicalBallastApproxRouteElementProps = {
        color: config.route.ballastApprox.color,
        width: config.route.ballastApprox.width,
        lineDash: config.route.ballastApprox.lineDash,
        lineDashMaxZoom: config.route.ballastApprox.lineDashMaxZoom,
        zoomColor: config.route.ballastApprox.zoomColor,
        zoomWidth: config.route.ballastApprox.zoomWidth
    };

    historicalBallastRouteElementProps = {
        color: config.route.ballast.color,
        width: config.route.ballast.width
    };

    historicalLadenRouteElementProps = {
        color: config.route.laden.color,
        width: config.route.laden.width
    };

    portsElementProps = {
        type: (port) => config.pointTypesMap[port.Type] || config.pointTypesMap[2],
        primaryColor: this.getPortPrimaryColor,
        secondaryColor: this.getPortSecondaryColor,
        coordinates: port => wktParse(port.Location),
        hidden: this.getPortHidden,
        isActive: port => port.selectedInVoyage,
        opacity: this.getPortOpacity,
        label: this.getPortLabel,
        hideLabel: this.getPortHideLabel,
        textStroke: true,
        textOnTop: true,
        zIndex: this.getPortZIndex
    };

    vesselsElementProps = {
        ...config.vessel,
        type: 'vessel',
        primaryColor: (data) => data.Color || config.vessel.primaryColor,
        coordinates: mapLonLat,
        fill: (vessel) => vessel.IsLaden,
        rotation: 'Course',
        isActive: false,
        hideLabel: true,
        opacity: 1
    };

    dotElementProps = {
        type: 'dot',
        coordinates: mapLonLat,
        isActive: false,
        hideLabel: true,
        opacity: (dot, isHovered) => (isHovered ? 1 : 0.8),
        size: config.weatherRouteDots.size
    };

    dotSourceProps = {
        distance: 10
    };

    saveTooltipRef = (c) => { this.tooltipRef = c; };

    saveWniWeatherTooltipRef = (c) => { this.wniWeatherTooltipRef = c; };

    render() {
        const {
            arePortsInvisible,
            countryBordersNamesEnabled,
            mapTypeId,
            ports,
            selectedAreas,
            selectedRoutes,
            selectedVesselAisPosition,
            selectedVesselPositions,
            selectedLegAisRoute
        } = this.props;
        const isNauticalMapSelected = mapTypeId === 3;
        const {
            mapZoomControlElement
        } = this.state;
        return (
            <div className="sten-map">
                <WeatherRoutingLegTooltip ref={this.saveTooltipRef} />
                <Ol.Map
                    ref={(c) => { this.map = c; }}
                    mapTypeId={mapTypeId}
                    mapViewProps={mapViewProps}
                    countryBordersNamesEnabled={countryBordersNamesEnabled}
                    zoomControlElement={mapZoomControlElement}
                    onHover={this.handleMapHover}
                    onPostRender={this.handleMapPostRender}
                    onClick={this.handleMapClick}
                    shouldResetToInitial
                >
                    {!isNauticalMapSelected && ConfigService.featureToggles.showWNIWeather && (
                        <WniWeather zIndex={config.weather.zIndex} tooltipRef={this.saveWniWeatherTooltipRef} />
                    )}
                    {!isNauticalMapSelected && ConfigService.featureToggles.showDTNWeather && (
                        <DtnWeather zIndex={config.weather.zIndex} />
                    )}
                    {ConfigService.featureToggles.showNauticalCharts
                        && !ConfigService.hiddenFeatures.weatherRoutingNauticalCharts && (
                        <NauticalCharts zIndex={config.nautical.zIndex} showBaseMap={isNauticalMapSelected} />
                    )}
                    {selectedAreas && (
                        <Ol.Layer
                            name="areasOfInterest"
                            type="area"
                            elements={selectedAreas}
                            elementProps={this.areasOfInterestElementProps}
                            zIndex={config.areaOfInterest.zIndex}
                        />
                    )}
                    {selectedLegAisRoute.ballast.length > 0 && (
                        <Ol.Layer
                            name="historicalBallastRoute"
                            type="multiLine"
                            elements={selectedLegAisRoute.ballast}
                            elementProps={this.historicalBallastRouteElementProps}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    {selectedLegAisRoute.ballastApprox.length > 0 && (
                        <Ol.Layer
                            name="historicalBallastApproxRoute"
                            type="multiLine"
                            elements={selectedLegAisRoute.ballastApprox}
                            elementProps={this.historicalBallastApproxRouteElementProps}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    {selectedLegAisRoute.laden.length > 0 && (
                        <Ol.Layer
                            name="historicalLadenRoute"
                            type="multiLine"
                            elements={selectedLegAisRoute.laden}
                            elementProps={this.historicalLadenRouteElementProps}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    {selectedLegAisRoute.ladenApprox.length > 0 && (
                        <Ol.Layer
                            name="historicalLadenApproxRoute"
                            type="multiLine"
                            elements={selectedLegAisRoute.ladenApprox}
                            elementProps={this.historicalLadenApproxRouteElementProps}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    {selectedRoutes.map((route, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <React.Fragment key={`${route.Id}.${index}`}>
                            <Ol.Layer
                                name={`selectedRoute.${route.Id}`}
                                type="multiLine"
                                elements={route.Route}
                                elementProps={{ ...config.weatherRoute, color: route.Color }}
                                zIndex={config.weatherRoute.zIndex}
                            />
                            <Ol.Layer
                                name={`selectedRouteDots.${route.Id}`}
                                type="cluster"
                                elements={route.Points}
                                elementProps={{ ...this.dotElementProps, primaryColor: route.Color }}
                                sourceProps={this.dotSourceProps}
                                zIndex={config.weatherRouteDots.zIndex}
                            />
                        </React.Fragment>
                    ))}
                    <Ol.Layer
                        name="ports"
                        type="point"
                        elements={ports}
                        elementProps={this.portsElementProps}
                        observes={[arePortsInvisible]}
                        zIndex={config.port.zIndex}
                    />
                    {selectedVesselAisPosition && (
                        <Ol.Layer
                            name="selectedVesselAisPosition"
                            type="point"
                            elements={[selectedVesselAisPosition]}
                            elementProps={this.vesselsElementProps}
                            zIndex={config.vessel.zIndex}
                        />
                    )}
                    {selectedVesselPositions.map((vessel) => (
                        <Ol.Layer
                            // eslint-disable-next-line react/no-array-index-key
                            key={vessel.Id}
                            name={`selectedVessel.${vessel.Id}`}
                            type="point"
                            elements={[vessel]}
                            elementProps={this.vesselsElementProps}
                            zIndex={config.vessel.zIndex}
                        />
                    ))}
                </Ol.Map>
            </div>
        );
    }
}

WeatherRoutingLegMap.propTypes = {
    arePortsInvisible: PropTypes.bool.isRequired,
    closeRightSidebar: PropTypes.func.isRequired,
    countryBordersNamesEnabled: PropTypes.bool.isRequired,
    getPorts: PropTypes.func.isRequired,
    getSelectedAreas: PropTypes.func.isRequired,
    mapTypeId: PropTypes.number.isRequired,
    ports: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedAreas: PropTypes.objectOf(PropTypes.any),
    selectedLegAisPoints: PropTypes.arrayOf(PropTypes.array).isRequired,
    selectedLegAisRoute: PropTypes.shape({
        ballast: PropTypes.arrayOf(PropTypes.any),
        laden: PropTypes.arrayOf(PropTypes.any),
        ballastApprox: PropTypes.arrayOf(PropTypes.any),
        ladenApprox: PropTypes.arrayOf(PropTypes.any)
    }).isRequired,
    selectedRoutes: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedVesselAisPosition: PropTypes.objectOf(PropTypes.any),
    selectedVesselPositions: PropTypes.arrayOf(PropTypes.object).isRequired,
    settingUpdated: PropTypes.objectOf(PropTypes.any)
};

WeatherRoutingLegMap.defaultProps = {
    selectedAreas: null,
    selectedVesselAisPosition: null,
    settingUpdated: null
};

function mapStateToProps(state, ownProps) {
    return {
        arePortsInvisible: state.userReducer?.settings?.ArePortsInvisible,
        countryBordersNamesEnabled: state.userReducer?.settings?.MapOptionsCountryBordersNamesEnabled,
        mapTypeId: ConfigService.hiddenFeatures.weatherRoutingNauticalCharts
            ? state.userReducer?.settings?.MarketIntelMapType
            : state.userReducer?.settings?.MapOptionsMapType,
        ports: getCombinedPorts(state, ownProps),
        selectedAreas: state.mapOptionsReducer.selectedAreas,
        selectedLegAisPoints: getSelectedLegAisPoints(state),
        selectedLegAisRoute: getSelectedLegAisRoute(state),
        settingUpdated: state.userReducer.settingUpdated
    };
}

function mapDispatchToProps(dispatch) {
    return {
        closeRightSidebar: () => emptyActivePanels(dispatch),
        getPorts: params => getPorts(dispatch, params),
        getSelectedAreas: () => getSelectedAreas(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(WeatherRoutingLegMap);
