import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { takeEvery, put, select, delay } from 'redux-saga/effects';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import { ActionTypes } from './fleet-performance-report-actions';
/* selectors */
import { getTableData } from './fleet-performance-report-selectors';
/* services */
import EnergyManagementService from 'services/core-api/energy-management-service';
import ConfigService from 'services/config-api/config-service';
import PdfGeneratorService from 'services/core-api/pdf-generator-service';
import LocalStorageService from 'services/local-storage-service';
/* exports */
import generateFleetPerformanceReportTypeAXLSX from './type-a/fleet-performance-report-type-a-xlsx';
import generateFleetPerformanceReportTypeBXLSX from './type-b/fleet-performance-report-type-b-xlsx';
import FleetPerformanceReportsTypeAPDF from './type-a/fleet-performance-report-type-a-pdf';
import FleetPerformanceReportsTypeBPDF from './type-b/fleet-performance-report-type-b-pdf';

function* prepareReportDataForExport() {
    const { selectedRange } = yield select(state => state.energyManagementReportsReducer);
    const rangeStart = selectedRange && TimeHelper.getFormatted(selectedRange.rangeStart);
    const rangeEnd = selectedRange && TimeHelper.getFormatted(selectedRange.rangeEnd);
    const fileName = `Fleet_Performance_Report_from_${rangeStart}_to_${rangeEnd}`;
    const tableData = yield select(state => getTableData(state));
    return { tableData, fileName, rangeStart, rangeEnd };
}

const typeVariables = {
    A: {
        getReport: EnergyManagementService.Reports.FleetPerformanceReport.getTypeAReport,
        generateXLSX: generateFleetPerformanceReportTypeAXLSX,
        getPDFReport: (data) => <FleetPerformanceReportsTypeAPDF {...data} />
    },
    B: {
        getReport: EnergyManagementService.Reports.FleetPerformanceReport.getTypeBReport,
        generateXLSX: generateFleetPerformanceReportTypeBXLSX,
        getPDFReport: (data) => <FleetPerformanceReportsTypeBPDF {...data} />
    }
};

function* generateXLSX() {
    yield put({ type: 'TOGGLE_LOADER', payload: true });
    yield delay(0);
    const reportData = yield prepareReportDataForExport();
    yield typeVariables[ConfigService.featureToggles.fleetPerformanceReportType].generateXLSX(reportData);
    yield put({ type: 'TOGGLE_LOADER', payload: false });
}

function* generatePDF() {
    const data = yield prepareReportDataForExport();
    const htmlString = ReactDOMServer.renderToStaticMarkup(
        typeVariables[ConfigService.featureToggles.fleetPerformanceReportType].getPDFReport(data)
    );

    PdfGeneratorService.convert({ html: htmlString, viewport: { width: 1920, height: 1080 } }, data.fileName);
}

function* getFleetPerformanceReportPerVoyage(action) {
    const { selectedRange } = yield select(state => state.energyManagementReportsReducer);
    const { vesselName, vesselImo } = action;
    const params = {
        StartDate: selectedRange && selectedRange.rangeStart,
        EndDate: selectedRange && selectedRange.rangeEnd,
        IncludedVessels: [vesselImo]
    };
    const performancePerVoyage = yield EnergyManagementService.Reports
        .FleetPerformanceReport.getReportPerVoyage(params);

    yield put({
        type: ActionTypes.ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_SET_PER_VOYAGE,
        performancePerVoyage,
        vesselName,
        vesselImo
    });
}

function* getFleetPerformanceReport() {
    const { filters, selectedRange } = yield select(state => state.energyManagementReportsReducer);
    const params = {
        StartDate: selectedRange && selectedRange.rangeStart,
        EndDate: selectedRange && selectedRange.rangeEnd,
        IncludedVessels: Object.keys(filters.selectedVessels).map(imo => parseInt(imo, 10))
    };
    if (!LocalStorageService.isDebugModeActive()) {
        const report = yield typeVariables[ConfigService.featureToggles.fleetPerformanceReportType].getReport(params);
        yield put({ type: ActionTypes.ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_SET, report });
    }
}

export default function* energyManagementReportsFleetPerformanceReportSaga() {
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_GENERATE, getFleetPerformanceReport);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_GET_PER_VOYAGE,
        getFleetPerformanceReportPerVoyage);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_GENERATE_XLSX, generateXLSX);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_GENERATE_PDF, generatePDF);
}
