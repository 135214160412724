import React from 'react';
/* types */
import { TAisPosition } from './vessel-details-tooltip-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { formatCoordinates, formatNumber } from 'utils/helpers/info-helper';
/* styles */
import './vessel-details-tooltip.scss';

export default class VesselDetailsTooltipContent extends React.PureComponent {
    render() {
        const { data } = this.props;
        let coordinates;
        if (data.CurrentAis) {
            coordinates = formatCoordinates(data.CurrentAis.Longitude, data.CurrentAis.Latitude);
        }
        let vesselNameClass = 'sten-vessel-details-tooltip__vessel-name';
        const isDedicatedVessel = data.IsDedicatedVessel;
        if (data.RelationshipType === 'TimeCharter') {
            vesselNameClass += ' sten-vessel-details-tooltip__vessel-name--quaternary';
        } else if (data.RelationshipType === 'CargoOwner' || isDedicatedVessel) {
            vesselNameClass += ' sten-vessel-details-tooltip__vessel-name--tertiary';
        } else if (data.IsVesselInUserScope) {
            vesselNameClass += ' sten-vessel-details-tooltip__vessel-name--primary';
        }
        if (data.LatestAlert) {
            vesselNameClass += ' sten-vessel-details-tooltip__vessel-name--alert';
        }

        return (
            <div className="sten-vessel-details-tooltip__wrapper">
                <div className={vesselNameClass}>{data.Title}</div>
                <table className="sten-vessel-details-tooltip__table">
                    <tbody>
                        {coordinates && (
                            <tr>
                                <td>{t('VESSEL_DETAILS_TOOLTIP.COORDINATES')}</td>
                                <td>{coordinates}</td>
                            </tr>
                        )}
                        {!isNaN(parseFloat(data.CurrentAis.Heading)) && (
                            <tr>
                                <td>{t('VESSEL_DETAILS_TOOLTIP.COURSE')}</td>
                                <td>{`${data.CurrentAis.Heading}${t('UNITS.COURSE')}`}</td>
                            </tr>
                        )}
                        {!isNaN(parseFloat(data.SpeedOverGround)) && (
                            <tr>
                                <td>{t('VESSEL_DETAILS_TOOLTIP.SPEED')}</td>
                                <td>
                                    {`${formatNumber(data.SpeedOverGround)} ${t('UNITS.SPEED')}`}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

VesselDetailsTooltipContent.propTypes = {
    data: TAisPosition.isRequired
};
