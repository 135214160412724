import CoreApi from './core-api';

class PortManagementService {
    constructor() {
        this.resourceUrl = 'PortManagement';
    }

    get(params) {
        return CoreApi.get(`${this.resourceUrl}/Ports`, params)
            .then(response => response.data, () => null);
    }

    getPort(id) {
        return CoreApi.get(`${this.resourceUrl}/Ports/${id}`)
            .then(response => response.data, () => null);
    }

    addPort(params) {
        return CoreApi.post(`${this.resourceUrl}/Ports`, params)
            .then(response => response.data, () => null);
    }

    updatePort(id, port) {
        return CoreApi.put(`${this.resourceUrl}/Ports/${id}`, port)
            .then(response => response.data, () => null);
    }

    getCountries() {
        return CoreApi.get('Countries')
            .then(response => response.data, () => null);
    }

    getServices() {
        return CoreApi.get(`${this.resourceUrl}/ServiceTypes`)
            .then(response => response.data, () => null);
    }

    getColumns() {
        return CoreApi.get(`${this.resourceUrl}/Columns`)
            .then(response => response.data, () => null);
    }

    getPinnedItems(params) {
        return CoreApi.post(`${this.resourceUrl}/PinnedItems`, params)
            .then(response => response.data, () => null);
    }
}

export default new PortManagementService();
