import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { roundNumber, formatNumber } from 'utils/helpers/info-helper';
import keyCodes from 'utils/key-codes';
/* styles */
import './number-input.scss';

class NumberInput extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            decreaseDisabled: false,
            increaseDisabled: false,
            step: 1,
            decimals: 0,
            disabled: true,
            max: props.max,
            min: props.min,
            value: props.value
        };
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
        let stateChanged = false;
        if (state.value !== props.value
            || state.min !== props.min || state.max !== props.max
            || state.disabled !== props.disabled) {
            newState.value = props.value;
            newState.min = props.min;
            newState.min = props.max;
            newState.disabled = props.disabled;
            const decreaseDisabled = props.disabled || (typeof props.min === 'number' && props.min >= props.value);
            const increaseDisabled = props.disabled || (typeof props.max === 'number' && props.max <= props.value);
            if (state.decreaseDisabled !== decreaseDisabled || state.increaseDisabled !== increaseDisabled) {
                newState.decreaseDisabled = decreaseDisabled;
                newState.increaseDisabled = increaseDisabled;
                stateChanged = true;
            }
        }
        let step;
        if (state.step !== props.step) {
            step = props.step;
        }
        let decimals;
        if (typeof props.decimals === 'number' && (state.decimals !== props.decimals)) {
            decimals = props.decimals;
        } else if (step) {
            const stepSplit = step.toString().split('.');
            decimals = stepSplit && stepSplit[1] ? stepSplit[1].length : 0;
        }
        if (step && step !== state.step) {
            newState.step = step;
            stateChanged = true;
        }
        if (decimals && decimals !== state.decimals) {
            newState.decimals = decimals;
            stateChanged = true;
        }
        if (stateChanged) {
            return newState;
        }
        return null;
    }

    componentWillUnmount() {
        this.clearIntervalsAndTimeouts();
    }

    clearIntervalsAndTimeouts() {
        if (this.mouseHoldTimeout) {
            clearTimeout(this.mouseHoldTimeout);
        }
        if (this.mouseHoldInterval) {
            clearInterval(this.mouseHoldInterval);
        }
    }

    getValue = () => {
        if (typeof this.props.value !== 'number') {
            return null;
        }
        let value;
        if (this.state.decimals) {
            value = formatNumber(this.props.value, this.state.decimals);
        } else {
            value = this.props.value.toString();
        }
        if (this.props.unit) {
            value += ` ${this.props.unit}`;
        }
        return value;
    };

    updateValue = direction => {
        if (this.props.onChange && direction) {
            let newValue;
            if (direction > 0 && !this.state.increaseDisabled) {
                newValue = this.props.value + this.state.step;
                if (typeof this.props.max !== 'number' || newValue <= this.props.max) {
                    this.props.onChange(roundNumber(newValue, this.state.decimals));
                    if (newValue === this.props.max) {
                        this.clearIntervalsAndTimeouts();
                    }
                }
            } else if (direction < 0 && !this.state.decreaseDisabled) {
                newValue = this.props.value - this.state.step;
                if (typeof this.props.min !== 'number' || newValue >= this.props.min) {
                    this.props.onChange(roundNumber(newValue, this.state.decimals));
                    if (newValue === this.props.min) {
                        this.clearIntervalsAndTimeouts();
                    }
                }
            }
        }
    };

    handleKeyDown = e => {
        switch (e.keyCode) {
        case keyCodes.rightArrow:
            e.stopPropagation();
            this.updateValue(1);
            break;
        case keyCodes.leftArrow:
            e.stopPropagation();
            this.updateValue(-1);
            break;
        default:
        }
    };

    handleBtnMouseDown = (e, direction) => {
        if (e && e.button !== 0) {
            return;
        }
        this.updateValue(direction);
        this.clearIntervalsAndTimeouts();
        this.mouseHoldTimeout = setTimeout(() => {
            if (this.mouseHoldInterval) {
                clearInterval(this.mouseHoldInterval);
            }
            this.mouseHoldInterval = setInterval(() => {
                this.updateValue(direction);
            }, this.props.mouseHoldInterval);
        }, this.props.mouseHoldTimeout);
    };

    handleBtnMouseUp = () => {
        this.clearIntervalsAndTimeouts();
    };

    handleDecreaseBtnMouseDown = (e) => this.handleBtnMouseDown(e, -1);

    handleIncreaseBtnMouseDown = (e) => this.handleBtnMouseDown(e, 1);

    render() {
        let className = 'sten-number-input';
        if (this.props.className) {
            className += ` ${this.props.className}`;
        }
        if (this.props.disabled) {
            className += ' sten-number-input--disabled';
        }
        if (this.props.invalid) {
            if (this.props.warning) {
                className += ' sten-number-input--warning';
            } else {
                className += ' sten-number-input--invalid';
            }
        }

        return (
            <div
                tabIndex={0}
                className={className}
                onKeyDown={this.handleKeyDown}
                title={this.props.title}
            >
                <div className="sten-number-input__value">
                    {this.getValue()}
                </div>
                <div className="sten-number-input__controls">
                    <button
                        type="button"
                        tabIndex={-1}
                        disabled={this.state.decreaseDisabled}
                        className="sten-number-input__btn icon icon-minus"
                        onMouseDown={this.handleDecreaseBtnMouseDown}
                        onMouseUp={this.handleBtnMouseUp}
                        onMouseLeave={this.handleBtnMouseUp}
                    />
                    <button
                        tabIndex={-1}
                        type="button"
                        disabled={this.state.increaseDisabled}
                        className="sten-number-input__btn icon icon-plus"
                        onMouseDown={this.handleIncreaseBtnMouseDown}
                        onMouseUp={this.handleBtnMouseUp}
                        onMouseLeave={this.handleBtnMouseUp}
                    />
                </div>
            </div>
        );
    }
}

NumberInput.propTypes = {
    className: PropTypes.string,
    decimals: PropTypes.number.isRequired,
    disabled: PropTypes.bool,
    id: PropTypes.string,
    invalid: PropTypes.bool,
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    mouseHoldInterval: PropTypes.number,
    mouseHoldTimeout: PropTypes.number,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    step: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    warning: PropTypes.bool,
    title: PropTypes.string
};

NumberInput.defaultProps = {
    className: '',
    disabled: false,
    id: '',
    invalid: false,
    mouseHoldInterval: 30,
    mouseHoldTimeout: 500,
    name: '',
    readOnly: false,
    warning: false,
    title: ''
};

export default NumberInput;
