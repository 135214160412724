import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* actions */
import { updateUserSettings } from 'pages/user-pages/user-actions';
import { getAvailableWeatherLayers } from '../dtn-weather-actions';
/* selectors */
import {
    getSelectedLayerOptionsMap,
    getAvailableLayersByType,
    getSelectedLayerOptions
} from '../dtn-weather-selectors';
/* components */
import Checkbox from 'components/checkbox/checkbox';
/* constants */
import { LAYER_VALUE_TYPES } from '../dtn-weather-constants';

class DtnWeatherMapOptions extends React.PureComponent {
    componentDidMount() {
        this.props.getAvailableWeatherLayers();
    }

    getLayerToggleHandler = (layer) => () => {
        const newSelectedLayers = this.props.selectedLayers.filter(sl => sl.id !== layer.id);
        if (newSelectedLayers.length === this.props.selectedLayers.length) {
            newSelectedLayers.push(layer);
        }
        this.props.updateUserSettings({
            MapWeatherLayerTileIdsV2: newSelectedLayers.map(layer => layer.id)
        });
    };

    render() {
        const { selectedLayersMap, availableLayersByType } = this.props;

        return (
            <div className="sten-content__section sten-dtn-weather-map-options">
                <h4>{t('MAP_WEATHER_LAYERS.LAYER')}</h4>
                <div className="sten-content__section">
                    {availableLayersByType[LAYER_VALUE_TYPES.VECTOR]?.map(option => (
                        <div className="form-row" key={option.id}>
                            <Checkbox
                                key={option.id}
                                className="text-uppercase"
                                onChange={this.getLayerToggleHandler(option)}
                                isChecked={!!selectedLayersMap[option.id]}
                            >
                                {option.label}
                            </Checkbox>
                        </div>
                    ))}
                </div>
                <h4>{t('MAP_WEATHER_LAYERS.VALUE')}</h4>
                <div className="sten-content__section">
                    {availableLayersByType[LAYER_VALUE_TYPES.OTHER]?.map(option => (
                        <div className="form-row" key={option.id}>
                            <Checkbox
                                key={option.id}
                                className="text-uppercase"
                                onChange={this.getLayerToggleHandler(option)}
                                isChecked={!!selectedLayersMap[option.id]}
                            >
                                {option.label}
                            </Checkbox>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

DtnWeatherMapOptions.propTypes = {
    availableLayersByType: PropTypes.shape({
        [LAYER_VALUE_TYPES.VECTOR]: PropTypes.arrayOf(PropTypes.object),
        [LAYER_VALUE_TYPES.OTHER]: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    getAvailableWeatherLayers: PropTypes.func.isRequired,
    selectedLayers: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedLayersMap: PropTypes.objectOf(PropTypes.any).isRequired,
    updateUserSettings: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        availableLayersByType: getAvailableLayersByType(state),
        selectedLayers: getSelectedLayerOptions(state),
        selectedLayersMap: getSelectedLayerOptionsMap(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAvailableWeatherLayers: () => getAvailableWeatherLayers(dispatch),
        updateUserSettings: settings => updateUserSettings(dispatch, settings, 'MapWeatherLayerTileIdsV2')
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DtnWeatherMapOptions);
