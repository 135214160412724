import { ActionTypes } from './gsm-actions';

const emptyArray = [];

const initialState = {
    selectedVessel: null,
    mapViewProps: null,

    tankers: emptyArray,
    selectedTanker: null,
    selectedTankerId: null,

    ports: emptyArray,

    cities: emptyArray,
    selectedCity: null,
    selectedCityId: null,

    addresses: emptyArray,
    addressesCount: 0,

    ferries: emptyArray,
    selectedFerry: null,
    selectedFerryId: null,

    ferryRoutes: emptyArray,

    drills: emptyArray,
    selectedDrill: null,
    selectedDrillId: null,

    resetAppUiState: false
};

export default function gsmMapReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.GSM_SAVE_VIEW_PROPS: {
        return {
            ...state,
            mapViewProps: action.mapViewProps
        };
    }

    // Tanker
    case ActionTypes.GSM_SET_TANKERS: {
        return {
            ...state,
            tankers: action.tankers
        };
    }

    case ActionTypes.GSM_SET_SELECTED_TANKER: {
        return {
            ...state,
            selectedTanker: action.tanker,
            selectedTankerId: action.tanker ? action.tanker.IMO : null
        };
    }

    case ActionTypes.GSM_REMOVE_SELECTED_TANKER: {
        return {
            ...state,
            selectedTanker: null,
            selectedTankerId: null
        };
    }

    // Vessel
    case ActionTypes.GSM_SET_SELECTED_VESSEL: {
        return {
            ...state,
            selectedVessel: action.vessel
        };
    }


    case ActionTypes.GSM_REMOVE_SELECTED_VESSEL: {
        return {
            ...state,
            selectedVessel: null
        };
    }

    // Ports
    case ActionTypes.GSM_SET_PORTS: {
        return {
            ...state,
            ports: action.ports
        };
    }

    // Cities
    case ActionTypes.GSM_SET_CITIES: {
        return {
            ...state,
            cities: action.cities
        };
    }

    case ActionTypes.GSM_SET_SELECTED_CITY: {
        return {
            ...state,
            selectedCity: action.city,
            selectedCityId: action.city ? action.city.CityId : null
        };
    }

    case ActionTypes.GSM_REMOVE_SELECTED_CITY: {
        return {
            ...state,
            selectedCity: null,
            selectedCityId: null
        };
    }


    // Address
    case ActionTypes.GSM_SET_VISIBLE_ADDRESSES: {
        return {
            ...state,
            addresses: action.addresses
        };
    }
    case ActionTypes.GSM_REMOVE_VISIBLE_ADDRESSES: {
        return {
            ...state,
            addresses: emptyArray
        };
    }

    case ActionTypes.GSM_SET_ADDRESSES_COUNT: {
        return {
            ...state,
            addressesCount: action.addressesCount
        };
    }

    // Ferries
    case ActionTypes.GSM_SET_FERRIES: {
        return {
            ...state,
            ferries: action.ferries
        };
    }

    case ActionTypes.GSM_SET_SELECTED_FERRY: {
        return {
            ...state,
            selectedFerry: action.ferry,
            selectedFerryId: action.ferry ? action.ferry.IMO : null
        };
    }

    case ActionTypes.GSM_REMOVE_SELECTED_FERRY: {
        return {
            ...state,
            selectedFerry: null,
            selectedFerryId: null
        };
    }

    // Ferry routes
    case ActionTypes.GSM_SET_FERRY_ROUTES: {
        return {
            ...state,
            ferryRoutes: action.ferryRoutes
        };
    }

    // Drills
    case ActionTypes.GSM_SET_DRILLS: {
        return {
            ...state,
            drills: action.drills
        };
    }

    case ActionTypes.GSM_SET_SELECTED_DRILL: {
        return {
            ...state,
            selectedDrill: action.drill,
            selectedDrillId: action.drill ? action.drill.IMO : null
        };
    }

    case ActionTypes.GSM_REMOVE_SELECTED_DRILL: {
        return {
            ...state,
            selectedDrill: null,
            selectedDrillId: null
        };
    }

    // Set app ui state
    case ActionTypes.GSM_SET_RESET_APP_UI_STATE: {
        return {
            ...state,
            resetAppUiState: true
        };
    }

    case ActionTypes.GSM_RESTORE_RESET_APP_UI_STATE: {
        return {
            ...state,
            resetAppUiState: false
        };
    }

    default:
        return state;
    }
}
