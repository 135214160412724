export const ActionTypes = {
    DTN_WEATHER_INITIALIZE: 'DTN_WEATHER_INITIALIZE',
    DTN_WEATHER_GET_AVAILABLE_LAYERS: 'DTN_WEATHER_GET_AVAILABLE_LAYERS',
    DTN_WEATHER_SET_AVAILABLE_LAYERS: 'DTN_WEATHER_SET_AVAILABLE_LAYERS',
    DTN_WEATHER_SET_LAYER_DATA: 'DTN_WEATHER_SET_LAYER_DATA',
    DTN_WEATHER_SET_LAYER_TIMESTAMPS: 'DTN_WEATHER_SET_LAYER_TIMESTAMPS',
    DTN_WEATHER_GET_DATA_FOR_TIMESTAMP: 'DTN_WEATHER_GET_DATA_FOR_TIMESTAMP'
};

export function initializeWeatherData(dispatch) {
    dispatch({
        type: ActionTypes.DTN_WEATHER_INITIALIZE
    });
}

export function getAvailableWeatherLayers(dispatch) {
    dispatch({
        type: ActionTypes.DTN_WEATHER_GET_AVAILABLE_LAYERS
    });
}

export function getWeatherDataForTimestamp(dispatch, timestamp) {
    dispatch({
        type: ActionTypes.DTN_WEATHER_GET_DATA_FOR_TIMESTAMP,
        timestamp
    });
}
