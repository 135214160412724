/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import ReportHelper from 'utils/helpers/report-helper';
import stenTableStyles, { border } from 'utils/helpers/pdf-styles/sten-table';
import { getLogoBase64 } from 'utils/base64images';
/* constants */
import { colWidths } from '../fleet-list-report-constants';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.FLEET_LIST_REPORT.${key}`);

const customColors = {
    segmentHeader: '#9ED2FA',
    fleetHeader: '#D5ECFD',
    contractHeader: '#F1F9FE'
};

/* eslint-disable max-len */
const styles = `
html {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
}
html, body {
    margin: 0;
    padding: 0;
}
table {
    border-right: ${border};
    border-left: ${border};
}
.header-title {
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
    text-transform: uppercase;
}
.header-style {
    display: flex;
    justify-content: space-between;
    height: 5rem;
    text-align: center;
    padding: 1rem 2rem;
}
.logo-style {
    min-width: 16rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
}
.page {
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    page-break-inside: avoid;
    page-break-after: always;
}
${stenTableStyles}
.fleet-list-table tbody {
    color: #333333;
}
.fleet-list-table thead {
    background-color: ${customColors.fleetHeader};
}
.fleet-list-table th {
    font-size: 0.875rem;
    font-weight: bold;
    height: 1rem;
    text-transform: initial;
    white-space: normal;
}
.fleet-list-table thead th,
.fleet-list-table tbody td,
.fleet-list-table tbody th {
    padding: 0.5rem 1rem;
    text-align: center;
}
`;

function breakRowsJS() {
    const mmToPxRatio = document.documentElement.offsetWidth / 277;
    const pageHeight = Math.round(190 * mmToPxRatio);
    const tableHeightLimit = pageHeight * (297 / 277);
    const bodyClone = document.body.cloneNode(false);
    const tBodies = document.getElementsByTagName('tbody');
    const pageClone = document.body.getElementsByClassName('page')[0].cloneNode(false);
    const tableClone = document.body.getElementsByTagName('table')[0].cloneNode(false);
    const tableHeadClone = document.body.getElementsByTagName('thead')[0].cloneNode(true);
    const tableHeadHeight = document.body.getElementsByTagName('thead')[0].getBoundingClientRect().height;
    tableClone.appendChild(tableHeadClone);
    const headerClone = document.getElementsByTagName('header')[0].cloneNode(true);
    const headerHeight = document.getElementsByTagName('header')[0].getBoundingClientRect().height;
    let currentTbodyHeight;
    let heightSoFar = headerHeight + tableHeadHeight;
    let newTable = tableClone.cloneNode(true);
    let newPage;
    let isHeaderAdded = false;
    const createNewPage = () => {
        newPage = pageClone.cloneNode(false);
        if (!isHeaderAdded) {
            newPage.appendChild(headerClone);
            isHeaderAdded = true;
        }
        newPage.appendChild(newTable);
        bodyClone.appendChild(newPage);
        newTable = tableClone.cloneNode(true);
        heightSoFar = tableHeadHeight;
    };
    for (let i = 0; i < tBodies.length; i++) {
        currentTbodyHeight = tBodies[i].getBoundingClientRect().height;
        heightSoFar += currentTbodyHeight;
        if (heightSoFar > tableHeightLimit) {
            createNewPage();
            heightSoFar += currentTbodyHeight;
        }
        newTable.appendChild(tBodies[i].cloneNode(true));
    }
    newPage = pageClone.cloneNode(false);
    if (!isHeaderAdded) {
        newPage.appendChild(headerClone);
        isHeaderAdded = true;
    }
    newPage.appendChild(newTable);
    bodyClone.appendChild(newPage);
    document.body.innerHTML = bodyClone.innerHTML;
}

export default class FleetListReportPDF extends React.PureComponent {
    renderVessel = (vessel, columns) => (
        <tbody key={`${vessel.Imo}`}>
            <tr>
                <th>
                    {vessel.VesselName}
                </th>
                {columns.map(col => (
                    <td key={`${col.key}`}>
                        {ReportHelper.formatValue(vessel, col)}
                    </td>
                ))}
            </tr>
        </tbody>
    );


    render() {
        const { parsedData, columns } = this.props.tableData;
        return (
            <html lang="en">
                <head>
                    <style>{styles}</style>
                </head>
                <body>
                    <div className="page">
                        <header className="header-style">
                            <div className="logo-style" />
                            <div>
                                <div className="header-title">{t('FLEET_LIST_REPORT')}</div>
                            </div>
                            <div
                                className="logo-style"
                                style={{
                                    backgroundImage: `url(${getLogoBase64().dark})`,
                                    backgroundPositionX: 'right',
                                    width: '16rem',
                                    height: '3rem'
                                }}
                            />
                        </header>
                        <table className="sten-table fleet-list-table">
                            <thead>
                                <tr>
                                    <th style={{ minWidth: `${colWidths.VesselName.pdf}rem` }}>{t('VESSEL')}</th>
                                    <th style={{ minWidth: `${colWidths.Imo.pdf}rem` }}>{t('IMO_NUMBER')}</th>
                                    <th style={{ minWidth: `${colWidths.DWT.pdf}rem` }}>{t('DWT')}</th>
                                    <th style={{ minWidth: `${colWidths.YearBuilt.pdf}rem` }}>{t('YEAR_BUILT')}</th>
                                    <th style={{ minWidth: `${colWidths.LOA.pdf}rem` }}>{t('LOA')}</th>
                                    <th style={{ minWidth: `${colWidths.Beam.pdf}rem` }}>{t('BEAM')}</th>
                                    <th style={{ minWidth: `${colWidths.Draft.pdf}rem` }}>{t('DRAFT')}</th>
                                    <th style={{ minWidth: `${colWidths.NumberOfTanks.pdf}rem` }}>{t('NUMBER_OF_TANKS')}</th>
                                    <th style={{ minWidth: `${colWidths.HasScrubber.pdf}rem` }}>{t('SCRUBBER')}</th>
                                    <th style={{ minWidth: `${colWidths.Coating.pdf}rem` }}>{t('COATING')}</th>
                                    <th style={{ minWidth: `${colWidths.IceClass.pdf}rem` }}>{t('ICE_CLASS')}</th>
                                    <th style={{ minWidth: `${colWidths.Flag.pdf}rem` }}>{t('FLAG')}</th>
                                </tr>
                            </thead>
                            {parsedData.map(vessel => this.renderVessel(vessel, columns))}
                        </table>
                    </div>
                    <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{ __html: `(${breakRowsJS.toString()})();` }}
                    />
                </body>
            </html>
        );
    }
}

FleetListReportPDF.propTypes = {
    tableData: PropTypes.objectOf(PropTypes.any)
};

FleetListReportPDF.defaultProps = {
    tableData: null
};
