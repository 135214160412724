import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import VoyageTimeline from 'components/voyage-timeline/voyage-timeline';

class VoyagePerformanceTimelinePanel extends React.PureComponent {
    onReportClick = (reportId, reportType) => {
        this.props.updateQueryParams({ add: { reportId, reportType } });
    };

    render() {
        return (
            <div className="sten-panel">
                <div className="sten-panel__header">
                    <h4 className="sten-panel__title">{translate('VOYAGE_PERFORMANCE.TIMELINE_TITLE')}</h4>
                </div>
                <VoyageTimeline
                    activeReportId={this.props.activeReportId}
                    onReportClick={this.onReportClick}
                    reports={this.props.reports}
                    onReminderSend={this.props.onReminderSend}
                    permissions={this.props.permissions}
                />
            </div>
        );
    }
}

VoyagePerformanceTimelinePanel.propTypes = {
    activeReportId: PropTypes.number,
    onReminderSend: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    reports: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateQueryParams: PropTypes.func.isRequired
};

VoyagePerformanceTimelinePanel.defaultProps = {
    activeReportId: null
};

export default VoyagePerformanceTimelinePanel;
