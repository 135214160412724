import { createDefaultItemSelector, createByIdSelector } from 'utils/helpers/selector-helper';

export const statusesSelector = (state) => state.assignToFleetModalReducer.statuses;
export const responsibleOfficesSelector = (state) => state.assignToFleetModalReducer.responsibleOffices;
export const commercialContractsSelector = (state) => state.assignToFleetModalReducer.commercialContracts;
export const fleetsSelector = (state) => state.assignToFleetModalReducer.fleets;

export const defaultStatus = createDefaultItemSelector(statusesSelector);
export const defaultResponsibleOffice = createDefaultItemSelector(responsibleOfficesSelector);
export const defaultCommercialContract = createDefaultItemSelector(commercialContractsSelector);

export const statusesById = createByIdSelector(statusesSelector, 'Id');
export const responsibleOfficesById = createByIdSelector(responsibleOfficesSelector, 'Id');
export const commercialContractsById = createByIdSelector(commercialContractsSelector, 'Id');

export const fleetsById = createByIdSelector(fleetsSelector, 'Id');

export default null;
