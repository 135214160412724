/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { wktParse } from 'components/ol/ol-helpers';
/* components */
import { commonFields } from 'components/form-fields/form-fields-helper';

const tt = (key, values) => translate(`TERMINAL.${key}`, values);
const tb = (key, values) => translate(`BERTH.${key}`, values);
const tr = (key) => translate(`RESTRICTIONS.${key}`);
const tu = (key) => translate(`UNITS.${key}`);

export const Restrictions = {
    AvgLoadingRate: '',
    DistanceToShoreTanks: '',
    Manifold: '',
    MaxBeam: '',
    MaxDWT: '',
    MaxDisplacement: '',
    MaxLOA: '',
    MaximumDraft: '',
    Side: '',
    UKC: '',
    WaterDensity: ''
};

export class Berth {
    constructor(item) {
        this.BerthId = item ? item.BerthId : null;
        this.TerminalId = item ? item.TerminalId : null;
        this.BerthName = item ? item.BerthName : null;
        this.Restrictions = item && item.Restrictions ? { ...item.Restrictions } : { ...Restrictions };
        this.UpdatedBy = item ? item.UpdatedBy : '';
        this.UpdatedAt = item ? item.UpdatedAt : '';
    }
}

export class Terminal {
    constructor(item) {
        this.TerminalId = item ? item.TerminalId : null;
        this.PortId = item ? item.PortId : null;
        this.TerminalName = item ? item.TerminalName : null;
        this.FacilityType = item ? item.FacilityType : '';
        this.TerminalOperator = item ? item.TerminalOperator : '';
        this.Position = item && item.Position ? wktParse(item.Position) : null;
        this.Restrictions = item && item.Restrictions ? { ...item.Restrictions } : { ...Restrictions };
        this.UpdatedBy = item ? item.UpdatedBy : '';
        this.UpdatedAt = item ? item.UpdatedAt : '';
    }
}

export const commonValidations = {
    Title: { maxLength: 50, required: true },
    String: { maxLength: 50 },
    Number: { numeric: { min: 0, max: 9999999 } },
    Integer: { numeric: { min: 0, max: 9999999, decimals: 0 } },
    Position: { required: true },
    AvgLoadingRate: { numeric: { min: 100, max: 20000 } },
    DistanceToShoreTanks: { numeric: { min: 200, max: 25000, decimals: 0 } },
    Manifold: { numeric: { min: 4, max: 30 } },
    MaxBeam: { numeric: { min: 0, max: 100 } },
    MaxDisplacement: { numeric: { min: 1, max: 600000 } },
    MaxDraft: { numeric: { min: 1, max: 60 } },
    MaxDWT: { numeric: { min: 100, max: 600000, decimals: 0 } },
    MaxLOA: { numeric: { min: 1, max: 600 } },
    WaterDensity: { numeric: { min: 995, max: 1025, decimals: 0 } }
};

export const restrictionFields = {
    'Restrictions.MaximumDraft': commonFields.Number(
        tr('MAX_DRAFT'), 'Restrictions.MaximumDraft', 1, commonValidations.MaxDraft, tu('DRAFT')
    ),
    'Restrictions.MaxLOA': commonFields.Number(
        tr('MAX_LOA'), 'Restrictions.MaxLOA', 1, commonValidations.MaxLOA, tu('LOA')
    ),
    'Restrictions.MaxBeam': commonFields.Number(
        tr('MAX_BEAM'), 'Restrictions.MaxBeam', 1, commonValidations.MaxBeam, tu('BEAM')
    ),
    'Restrictions.MaxDisplacement': commonFields.Number(
        tr('MAX_DISPLACEMENT'),
        'Restrictions.MaxDisplacement',
        1,
        commonValidations.MaxDisplacement,
        tu('MAX_DISPLACEMENT')
    ),
    'Restrictions.MaxDWT': commonFields.Number(
        tr('MAX_DWT'), 'Restrictions.MaxDWT', 1, commonValidations.MaxDWT, tu('DWT')
    ),
    'Restrictions.UKC': commonFields.Number(
        tr('UKC'), 'Restrictions.UKC', 1, commonValidations.Number, tu('UKC')
    ),
    'Restrictions.Side': commonFields.String(tr('SIDE'), 'Restrictions.Side', '', commonValidations.String),
    'Restrictions.Manifold': commonFields.Number(
        tr('MANIFOLD'), 'Restrictions.Manifold', 1, commonValidations.Manifold, tu('MANIFOLD')
    ),
    'Restrictions.DistanceToShoreTanks': commonFields.Number(
        tr('DISTANCE_TO_SHORE_TANKS'),
        'Restrictions.DistanceToShoreTanks',
        1,
        commonValidations.DistanceToShoreTanks,
        tu('DISTANCE_SHORE_TANKS')
    ),
    'Restrictions.AvgLoadingRate': commonFields.Number(
        tr('AVG_LOADING_RATE'),
        'Restrictions.AvgLoadingRate',
        1,
        commonValidations.AvgLoadingRate,
        tu('AVG_LOADING_RATE')
    ),
    'Restrictions.WaterDensity': commonFields.Number(
        tr('WATER_DENSITY'), 'Restrictions.WaterDensity', 0, commonValidations.WaterDensity, tu('WATER_DENSITY')
    )
};

export const terminalFieldNames = {
    BasicInfo: ['TerminalName', 'TerminalOperator', 'FacilityType', 'Position'],
    Restrictions: [
        'Restrictions.MaximumDraft',
        'Restrictions.MaxLOA',
        'Restrictions.MaxBeam',
        'Restrictions.MaxDisplacement',
        'Restrictions.MaxDWT',
        'Restrictions.UKC',
        'Restrictions.Side',
        'Restrictions.Manifold',
        'Restrictions.DistanceToShoreTanks',
        'Restrictions.AvgLoadingRate'
    ]
};

export const terminalFields = {
    TerminalName: commonFields.String(tt('TERMINAL_NAME'), 'TerminalName', '', commonValidations.Title),
    FacilityType: commonFields.String(tt('FACILITY_TYPE'), 'FacilityType', '', commonValidations.String),
    TerminalOperator: commonFields.String(
        tt('TERMINAL_OPERATOR'), 'TerminalOperator', '', commonValidations.String
    ),
    Position: commonFields.Position(tt('POSITION'), 'Position', commonValidations.Position),
    ...restrictionFields
};

export const berthFieldNames = {
    BasicInfo: ['BerthName'],
    Restrictions: [
        'Restrictions.MaximumDraft',
        'Restrictions.MaxLOA',
        'Restrictions.MaxBeam',
        'Restrictions.MaxDisplacement',
        'Restrictions.MaxDWT',
        'Restrictions.UKC',
        'Restrictions.Side',
        'Restrictions.Manifold',
        'Restrictions.DistanceToShoreTanks',
        'Restrictions.AvgLoadingRate',
        'Restrictions.WaterDensity'
    ]
};

export const berthFields = {
    BerthName: commonFields.String(tb('BERTH_NAME'), 'BerthName', '', commonValidations.Title),
    ...restrictionFields
};
