let cache;

function clearCache() {
    cache = undefined;
}

export default function getFontSize() {
    if (typeof cache === 'undefined') {
        const styles = getComputedStyle(document.documentElement);
        cache = parseFloat(styles.fontSize);
    }
    return cache;
}

window.addEventListener('resize', clearCache, true);
window.addEventListener('load', clearCache);
