import ReactGA from 'react-ga4';
/* helpers */
import ApiHelper from 'utils/helpers/api-helper';
import { getObjectProp, setObjectProp } from 'utils/helpers/info-helper';
/* api */
import ConfigApi from './config-api';

const defaultFeatureToggles = {
    aisAlwaysSendLatest: false,
    aisMaxTimeDiff: 48,
    allowCompanyEdit: false,
    filterUserRoles: false,
    fleetPerformanceReportType: 'B',
    preselectedPerformanceRequestType: null,
    rawReportsSortOrder: 'ASC',
    sednaIntegrationURL: '',
    showAllMarketIntelFeatures: false,
    showCharterpartyInfo: false,
    showCounterPvar: false,
    showContactForm: false,
    showDistanceCalculator: false,
    showDTNWeather: false,
    showDTNWeatherStormIconRotation: false,
    showDTNWeatherStormTracks: false,
    showDynamicFleets: false,
    showEmissionEstimator: false,
    showEnergyManagement: false,
    showEuEts: false,
    showFleetDashboard: false,
    showFleetManagement: false,
    showFootprint: false,
    showInspections: false,
    showKpiEnergyEfficiencyLayout: false,
    showKpiWithMockedData: false,
    showLastUpdateMessage: false,
    showNauticalCharts: true,
    showNauticalLayers: false,
    showNetDailyAndCO2PerDollar: false,
    showOperatorsDiary: false,
    showPortCallAnalytics: false,
    showPortManagement: false,
    showPositionList: false,
    showPositionListSignature: false,
    showQ88VesselDetails: false,
    showQuestionnaire: false,
    showSendBudgetEmail: false,
    showSensorData: false,
    showSyncVesselWithVeracityToggle: false,
    showVesselManagementSortOrder: false,
    showVoyagePerformance: false,
    showVmsFootprint: false,
    showWeatherRouting: false,
    showWeatherRoutingRequestPerformance: false,
    showWeatherRoutingWeatherPerformance: false,
    showWNIWeather: false,
    useReportConfigService: false,
    weatherRoutingLegCheckInterval: 0
};

const defaultHiddenFeatures = {
    distanceCalculatorCiiRating: false,
    distanceCalculatorNauticalCharts: false,
    emissionEstimatorNauticalCharts: true,
    mapVesselInfoCiiRating: false,
    mapVesselInfoFootprintFuelConsumptions: true,
    mapVesselInfoVesselCiiRating: false,
    mapVesselInfoVmsCiiRating: false,
    mapVesselInfoVmsVoyageEmissions: false,
    mapVesselInfoVoyageEmissions: false,
    mapVesselInfoVoyageEnergyEfficiency: false,
    voyagePerformanceFootprintFuelConsumptions: false,
    voyagePerformanceFootprintPdfExport: true,
    voyagePerformancePdfExport: false,
    voyagePerformanceVesselCiiRating: false,
    voyagePerformanceVmsVoyageEmissions: false,
    voyagePerformanceVoyageEmissions: false,
    voyagePerformanceVoyageEnergyEfficiency: false,
    voyageReportFootprintFuelConsumptions: false,
    voyageReportRangeSelector: false,
    voyageReportVesselCiiRating: false,
    voyageReportVmsVoyageEmissions: false,
    voyageReportVoyageEmissions: false,
    voyageReportVoyageEnergyEfficiency: false,
    weatherRoutingNauticalCharts: true,
    weatherRoutingSpeedRangeCalculationType: false,
    weatherRoutingIntendWhenVoyageEnded: false
};

const emptyObject = {};

class ConfigService {
    constructor() {
        this.authority = null;
        this.cache = null;
        this.tenantId = null;
        this.tenantConfig = null;
        this.featureToggles = defaultFeatureToggles;
        this.hiddenFeatures = defaultHiddenFeatures;
    }

    clearCache() {
        this.cache = null;
        this.tenantId = null;
        this.tenantConfig = null;
        this.featureToggles = defaultFeatureToggles;
        this.hiddenFeatures = defaultHiddenFeatures;
        ApiHelper.baseUrl = null;
    }

    getAuthorityConfig() {
        if (this.authority) {
            return Promise.resolve(this.authority);
        }
        return ConfigApi.get('authority.json').then(res => {
            this.authority = res.data;
            return this.authority;
        }, () => null);
    }

    getTenantConfig() {
        return ConfigApi.get(`${this.tenantId}/config.json`).then(res => {
            this.tenantConfig = res.data;
            if (process.env.NODE_ENV === 'production' && res.data.googleAnalyticsMeasurementId) {
                ReactGA.initialize(res.data.googleAnalyticsMeasurementId, { debug: false });
            }
            if (process.env.REACT_APP_LOCAL_API !== 'true') {
                ApiHelper.baseUrl = res.data.baseUrl;
            }
            return this.tenantConfig;
        }, () => null);
    }

    extractTestConfigs(prefix) {
        const testQueryParams = {};
        if (window.location.search) {
            window.location.search.slice(1).split('&').forEach(qp => {
                if (qp.startsWith(prefix)) {
                    const splitQP = qp.split('=');
                    testQueryParams[splitQP[0].slice(3)] = splitQP[1] === 'true';
                }
            });
        }
        return testQueryParams;
    }

    getFeatureToggles() {
        return ConfigApi.get(`${this.tenantId}/featureToggles.json`).then(res => {
            this.featureToggles = {
                ...defaultFeatureToggles,
                ...res.data,
                ...this.extractTestConfigs('ft-')
            };
            return this.featureToggles;
        }, () => null);
    }

    getHiddenFeatures() {
        return ConfigApi.get(`${this.tenantId}/hiddenFeatures.json`).then(res => {
            this.hiddenFeatures = {
                ...defaultHiddenFeatures,
                ...res.data,
                ...this.extractTestConfigs('hf-')
            };
            return this.hiddenFeatures;
        }, () => null);
    }

    setTenantId(tenantId) {
        if (this.tenantId !== tenantId) {
            this.clearCache();
            this.tenantId = tenantId;
            return Promise.all([
                this.getTenantConfig(tenantId),
                this.getFeatureToggles(tenantId),
                this.getHiddenFeatures(tenantId)
            ]);
        }
        return Promise.resolve([this.tenantConfig, this.featureToggles, this.hiddenFeatures]);
    }

    getFromCache(resource) {
        if (this.cache && this.cache[resource]) {
            return this.cache[resource];
        }
        return emptyObject;
    }

    getCached(resource, callback) {
        if (!this.tenantId) {
            return Promise.reject();
        }
        if (!this.cache) {
            this.cache = {};
        }
        if (!getObjectProp(this.cache, resource)) {
            return callback(this.tenantId).then(response => {
                setObjectProp(this.cache, resource, response.data);
                return response.data;
            }, () => null);
        }
        return Promise.resolve(this.cache[resource]);
    }

    VesselReport = {
        getEditable: () => {
            return this.getCached(
                'vesselReport.editable',
                tenantId => ConfigApi.get(`${tenantId}/vesselReport/editable.json`)
            );
        },
        getHiddenFields: () => {
            return this.getCached(
                'vesselReport.hiddenFields',
                tenantId => ConfigApi.get(`${tenantId}/vesselReport/hiddenFields.json`)
            );
        },
        getValidations: () => {
            return this.getCached(
                'vesselReport.validations',
                tenantId => ConfigApi.get(`${tenantId}/vesselReport/validations.json`)
            );
        },
        getConsumptionTypes: () => {
            return this.getCached(
                'vesselReport.consumptionTypes',
                tenantId => ConfigApi.get(`${tenantId}/vesselReport/consumptionTypes.json`)
            );
        },
        getBunkerTypes: () => {
            return this.getCached(
                'vesselReport.bunkerTypes',
                tenantId => ConfigApi.get(`${tenantId}/vesselReport/bunkerTypes.json`)
            );
        }
    }
}

export default new ConfigService();
