import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
/* router */
import { appRoutes } from 'app-router';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import PortManagementFilters from './filters/port-management-filters';
import PortManagementTable from './table/port-management-table';
import PortManagementRightSideBar from './right-side-bar/port-management-right-side-bar';
import IconButton from 'components/icon-button/icon-button';
/* actions */
import {
    getOptions,
    getPinnedPorts,
    getPorts,
    handlePortUpdate,
    reset,
    resetPorts
} from './port-management-actions';
import { toggleActivePanel } from 'components/right-side-bar/right-side-bar-actions';
import { toggleUserSetting } from 'pages/user-pages/user-actions';
/* selectors */
import {
    getConvertedPorts,
    getSortedPinnedPorts,
    getVisibleColumns,
    getTableContentStyle
} from './port-management-selectors';
/* styles */
import './port-management.scss';

const t = (key) => translate(`PORT_MANAGEMENT.${key}`);

export class PortManagement extends React.PureComponent {
    state = {
        areFiltersVisible: true
    };

    componentDidMount() {
        this.props.getOptions();
        this.props.getPinnedPorts();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userSettingUpdated !== this.props.userSettingUpdated
            && this.props.userSettingUpdated.name === 'PortManagementPinnedItems') {
            this.props.getPinnedPorts();
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

    handleFiltersToggle = () => {
        this.setState({ areFiltersVisible: !this.state.areFiltersVisible });
    };

    handleSettingsToggle = () => this.props.toggleActivePanel('settings');

    handleFiltersUpdate = (filters) => {
        this.props.getPorts({ ...filters, Offset: 0 });
        if (this.portTableRef && this.portTableRef.tableRef) {
            this.portTableRef.tableRef.scrollArea.scrollTop();
        }
    };

    handleFiltersReset = () => {
        this.props.resetPorts();
        if (this.portTableRef && this.portTableRef.tableRef) {
            this.portTableRef.tableRef.scrollArea.scrollTop();
        }
    };

    saveTableRef = c => { this.portTableRef = c; };

    render() {
        return (
            <div className="sten-port-management">
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <div className="flex-grow">
                            <h1 className="sten-content__title">{t('TITLE')}</h1>
                        </div>
                        <div className="flex-shrink">
                            <IconButton
                                isLink
                                isActive={this.state.areFiltersVisible}
                                icon="icon-filter-outline"
                                onClick={this.handleFiltersToggle}
                            />
                        </div>
                        <div className="flex-shrink">
                            <IconButton
                                isLink
                                isActive={this.state.areSettingsVisible}
                                icon="icon-cog"
                                onClick={this.handleSettingsToggle}
                            />
                        </div>
                        {this.props.permissions && this.props.permissions.PortManagementAddPort && (
                            <React.Fragment>
                                <div className="flex-shrink">
                                    <div className="sten-content__vertical-separator" />
                                </div>
                                <div className="flex-shrink">
                                    <Link
                                        className="btn btn--link btn--primary"
                                        to={`${appRoutes.PortManagement}/new`}
                                        disabled={this.state.isUpdateModeActive || this.state.areSettingsVisible}
                                    >
                                        <span className="btn__icon icon icon-plus" />
                                        {t('CREATE_NEW_PORT')}
                                    </Link>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                    <div className="sten-content__body">
                        {this.state.areFiltersVisible && (
                            <div className="sten-port-management__content-top">
                                <PortManagementFilters
                                    onReset={this.handleFiltersReset}
                                    onSubmit={this.handleFiltersUpdate}
                                />
                            </div>
                        )}
                        <div className="sten-port-management__content-bottom">
                            <PortManagementTable
                                columns={this.props.visibleColumns}
                                contentStyle={this.props.tableContentStyle}
                                onParamsChange={this.props.getPorts}
                                permissions={this.props.permissions}
                                pinnedPorts={this.props.pinnedPorts}
                                ports={this.props.ports}
                                portParams={this.props.portParams}
                                setInnerRef={this.saveTableRef}
                                toggleUserSetting={this.props.toggleUserSetting}
                                userSettings={this.props.userSettings}
                                userSettingUpdated={this.props.userSettingUpdated}
                            />
                        </div>
                    </div>
                </div>
                <PortManagementRightSideBar
                    onPortUpdate={this.props.handlePortUpdate}
                />
            </div>
        );
    }
}

PortManagement.propTypes = {
    getOptions: PropTypes.func.isRequired,
    getPinnedPorts: PropTypes.func.isRequired,
    getPorts: PropTypes.func.isRequired,
    handlePortUpdate: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    pinnedPorts: PropTypes.arrayOf(PropTypes.object).isRequired,
    portParams: PropTypes.objectOf(PropTypes.any).isRequired,
    ports: PropTypes.objectOf(PropTypes.any),
    reset: PropTypes.func.isRequired,
    resetPorts: PropTypes.func.isRequired,
    tableContentStyle: PropTypes.objectOf(PropTypes.any),
    toggleUserSetting: PropTypes.func.isRequired,
    toggleActivePanel: PropTypes.func.isRequired,
    userSettings: PropTypes.objectOf(PropTypes.any),
    userSettingUpdated: PropTypes.objectOf(PropTypes.any).isRequired,
    visibleColumns: PropTypes.arrayOf(PropTypes.object).isRequired
};

PortManagement.defaultProps = {
    ports: null,
    tableContentStyle: null,
    userSettings: null
};

function mapStateToProps(state) {
    return {
        permissions: state.userReducer.permissions,
        pinnedPorts: getSortedPinnedPorts(state),
        ports: getConvertedPorts(state),
        portParams: state.portManagementReducer.portParams,
        tableContentStyle: getTableContentStyle(state),
        userSettings: state.userReducer.settings,
        userSettingUpdated: state.userReducer.settingUpdated,
        visibleColumns: getVisibleColumns(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getOptions: () => getOptions(dispatch),
        getPinnedPorts: () => getPinnedPorts(dispatch),
        getPorts: (params) => getPorts(dispatch, params),
        handlePortUpdate: (port, id) => handlePortUpdate(dispatch, port, id),
        reset: () => reset(dispatch),
        resetPorts: () => resetPorts(dispatch),
        toggleUserSetting: (settingName, value, settingUpdated) =>
            toggleUserSetting(dispatch, settingName, value, settingUpdated),
        toggleActivePanel: (panelName, enable) => toggleActivePanel(dispatch, panelName, enable)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PortManagement);
