import React from 'react';
import PropTypes from 'prop-types';
import Cell from '../shared/cell';
import PanelSlider from 'components/htec-panel-slider/panel-slider';
import DatePickerPanel from './date-picker-panel';
import { YearShape } from '../types';
/* styles */
import './date-picker.scss';

export default class DatePickerModeYears extends React.PureComponent {
    constructor(props) {
        super(props);
        const rangeStartYear = Math.floor(this.props.currentYear / 12) * 12;
        this.state = {
            rangeStartYear
        };
    }

    getYearProps = args => {
        let isSelected = false;
        let isToday;
        if (this.props.selectedDate) {
            isSelected = args.year === this.props.selectedDate.year;
        }
        const isDisabled = (this.props.minDate && args.year < this.props.minDate.year)
            || (this.props.maxDate && args.year > this.props.maxDate.year);
        if (this.props.today) {
            isToday = args.year === this.props.today.year;
        }
        return {
            isSelected,
            isDisabled,
            isToday
        };
    };

    handleYearChange = diff => {
        this.setState({
            rangeStartYear: this.state.rangeStartYear + (diff * 12)
        });
    };

    renderYear = (args) => {
        return this.props.renderYear({
            ...args,
            ...this.getYearProps(args),
            onClick: this.props.onCellClick
        });
    };

    renderYears = (rangeStartYear) => {
        const rows = [];
        let cells = [];
        const totalCells = 12;
        for (let i = 0; i < totalCells; i++) {
            cells.push(
                <td key={rangeStartYear + i}>{this.renderYear({ year: rangeStartYear + i })}</td>
            );
            if ((i + 1) % 3 === 0) {
                rows.push(
                    <tr key={i}>
                        {cells}
                    </tr>
                );
                cells = [];
            }
        }
        return rows;
    };

    renderYearsPanel = (rangeStartYear) => {
        return (
            <DatePickerPanel
                key={rangeStartYear}
                title={`${rangeStartYear} - ${rangeStartYear + 11}`}
            >
                <table className="htec-dp-date-picker-mode">
                    <tbody>
                        {this.renderYears(rangeStartYear)}
                    </tbody>
                </table>
            </DatePickerPanel>
        );
    };

    prevBtnDisabled = (diff) => {
        if (!this.props.disableYearsOutOfRange || !this.props.minDate) {
            return false;
        }
        return this.state.rangeStartYear + (diff - 1) < this.props.minDate.year;
    };

    nextBtnDisabled = (diff) => {
        if (!this.props.disableYearsOutOfRange || !this.props.maxDate) {
            return false;
        }
        return this.state.rangeStartYear + (diff + 12) > this.props.maxDate.year;
    };

    render = () => {
        const nextRangeStartYear = this.state.rangeStartYear + 12;
        const prevRangeStartYear = this.state.rangeStartYear - 12;
        return (
            <PanelSlider
                panels={[this.renderYearsPanel(this.state.rangeStartYear)]}
                panelsClass="htec-dp-date-picker-mode"
                onPanelChange={this.handleYearChange}
                nextPanel={this.renderYearsPanel(nextRangeStartYear)}
                prevPanel={this.renderYearsPanel(prevRangeStartYear)}
                nextBtn={this.props.nextBtn}
                prevBtn={this.props.prevBtn}
                nextBtnDisabled={this.nextBtnDisabled}
                prevBtnDisabled={this.prevBtnDisabled}
            />
        );
    }
}

DatePickerModeYears.propTypes = {
    currentYear: PropTypes.number.isRequired,
    disableYearsOutOfRange: PropTypes.bool,
    maxDate: PropTypes.shape(YearShape),
    minDate: PropTypes.shape(YearShape),
    nextBtn: PropTypes.node,
    onCellClick: PropTypes.func.isRequired,
    prevBtn: PropTypes.node,
    renderYear: PropTypes.func,
    selectedDate: PropTypes.shape(YearShape),
    today: PropTypes.shape(YearShape).isRequired
};

DatePickerModeYears.defaultProps = {
    disableYearsOutOfRange: false,
    maxDate: null,
    minDate: null,
    nextBtn: '►',
    prevBtn: '◄',
    renderYear: (args) => {
        return <Cell {...args} title={args.year} />;
    },
    selectedDate: null
};
