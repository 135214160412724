import React from 'react';
/* components */
import Select from 'components/select/select';
/* Services */
import VesselService from 'services/core-api/vessel-service';

class CommercialOperatorSelect extends React.PureComponent {
    state = {
        options: []
    };

    getOptions = searchTerm => {
        if (searchTerm) {
            VesselService.getCommercialOperatorsBySearchTerm(searchTerm).then((res) => {
                this.setState({ options: res });
            });
        }
    };

    render() {
        return (
            <Select
                {...this.props}
                searchable
                onSearch={this.getOptions}
                valueKey="Id"
                labelKey="Name"
                options={this.state.options}
            />
        );
    }
}

CommercialOperatorSelect.propTypes = Select.propTypes;
CommercialOperatorSelect.defaultProps = Select.defaultProps;

export default CommercialOperatorSelect;
