import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* components */
import Map from './map/market-intel-map';
import RightSideBar from './right-side-bar/right-side-bar';
import TradingRouteDetails from './trading-route-details/trading-route-details';
import TotalVesselDistribution from './total-vessel-distribution/total-vessel-distribution';
/* actions */
import {
    resetAll,
    setSelectedRouteCode,
    getBunkerPorts,
    getRoutes,
    getOptions,
    getDistributionHeatMap,
    getTotalVesselDistribution
} from './market-intel-actions';
/* services */
import ConfigService from 'services/config-api/config-service';
/* styles */
import './market-intel.scss';

export class MarketIntelPage extends React.PureComponent {
    componentDidMount() {
        this.updateReducer();
        this.props.getOptions();
        this.props.getBunkerPorts();
        this.props.getRoutes();
    }

    componentWillUnmount() {
        this.props.resetAll();
    }

    componentDidUpdate(prevProps) {
        this.updateReducer(prevProps);
    }

    updateReducer(props) {
        /* trading route query */
        if (this.props.queryParams.routeCode) {
            this.props.setSelectedRouteCode(this.props.queryParams.routeCode);
        } else if (props && props.queryParams.routeCode !== this.props.queryParams.routeCode) {
            this.props.setSelectedRouteCode('');
        }
        /* vessel distribution props */
        if ((!props || (!props.settingUpdated !== this.props.settingUpdated
                && this.props.settingUpdated.name === 'MarketIntel'))
            && this.props.selectedLayerId === 3) {
            if (this.props.selectedDistributionType === 1) {
                this.props.getDistributionHeatMap();
            } else {
                this.props.getTotalVesselDistribution();
            }
        }
    }

    render() {
        return (
            <div className="sten-market-intel-page">
                {this.props.children}
                {this.props.selectedLayerId === 3 && this.props.selectedDistributionType === 2 && (
                    <TotalVesselDistribution fullWidth={this.props.isRightSideBarCollapsed} />
                )}
                <Map isRightSideBarCollapsed={this.props.isRightSideBarCollapsed} />
                {this.props.selectedRouteCode && (
                    <TradingRouteDetails />
                )}
                <RightSideBar />
            </div>
        );
    }
}

MarketIntelPage.propTypes = {
    children: PropTypes.node,
    getBunkerPorts: PropTypes.func.isRequired,
    getDistributionHeatMap: PropTypes.func.isRequired,
    getOptions: PropTypes.func.isRequired,
    getRoutes: PropTypes.func.isRequired,
    getTotalVesselDistribution: PropTypes.func.isRequired,
    isRightSideBarCollapsed: PropTypes.bool,
    queryParams: PropTypes.shape({
        routeCode: PropTypes.string
    }).isRequired,
    resetAll: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    selectedDistributionType: PropTypes.number,
    selectedLayerId: PropTypes.number,
    selectedRouteCode: PropTypes.string,
    setSelectedRouteCode: PropTypes.func.isRequired,
    settingUpdated: PropTypes.objectOf(PropTypes.any).isRequired
};

MarketIntelPage.defaultProps = {
    children: null,
    isRightSideBarCollapsed: false,
    selectedDistributionType: null,
    selectedLayerId: null,
    selectedRouteCode: null
};

function mapStateToProps(state) {
    return {
        isRightSideBarCollapsed: state.rightSideBarReducer.isCollapsed,
        selectedDistributionType: state.userReducer.settings && state.userReducer.settings.VesselDistributionType,
        selectedLayerId: ConfigService.featureToggles.showAllMarketIntelFeatures
            ? state.userReducer.settings && state.userReducer.settings.MarketIntelLayerType
            : 3,
        selectedRouteCode: state.marketIntelReducer.selectedRouteCode,
        settingUpdated: state.userReducer.settingUpdated
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getBunkerPorts: () => getBunkerPorts(dispatch),
        getDistributionHeatMap: () => getDistributionHeatMap(dispatch),
        getOptions: () => getOptions(dispatch),
        getRoutes: () => getRoutes(dispatch),
        getTotalVesselDistribution: () => getTotalVesselDistribution(dispatch),
        resetAll: () => resetAll(dispatch),
        setSelectedRouteCode: routeCode => setSelectedRouteCode(dispatch, routeCode)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MarketIntelPage));
