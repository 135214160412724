import getFontSize from 'utils/helpers/cached-font-size';
import { getKpiChartConfig, getYAxis } from '../kpi-chart-config';

const getChartConfigs = () => {
    const rem = getFontSize();
    const fsm = rem / 16;

    const kpiChartConfig = getKpiChartConfig();
    const yAxis = getYAxis(fsm);

    return {
        averageSpeed: {
            ...kpiChartConfig,
            yAxis: {
                ...yAxis,
                minTickInterval: 1,
                allowDecimals: false,
                title: {
                    ...yAxis.title,
                    text: 'Speed (kn)',
                    textAlign: 'left'
                }
            },
            series: [
                {
                    name: 'Ballast speed',
                    color: '#ffffff',
                    type: 'line',
                    lineWidth: 2,
                    data: []
                },
                {
                    name: 'Laden speed',
                    color: '#28ffca',
                    type: 'line',
                    lineWidth: 2,
                    data: []
                }
            ]
        },
        ballastRPMAnalysis: {
            ...kpiChartConfig,
            yAxis: [
                {
                    ...yAxis,
                    minTickInterval: 1,
                    allowDecimals: false,
                    id: 'speed',
                    title: {
                        ...yAxis.title,
                        text: 'Speed (kn)',
                        textAlign: 'right'
                    },
                    opposite: true
                },
                {
                    ...yAxis,
                    id: 'rpm',
                    minTickInterval: 1,
                    allowDecimals: false,
                    title: {
                        ...yAxis.title,
                        text: 'RPM',
                        textAlign: 'left'
                    }
                }
            ],
            series: [
                {
                    name: 'RPM',
                    color: '#48a6f3',
                    type: 'line',
                    lineWidth: 2,
                    yAxis: 'rpm',
                    data: []
                },
                {
                    name: 'Speed',
                    color: '#ffffff',
                    type: 'line',
                    lineWidth: 2,
                    yAxis: 'speed',
                    data: []
                }
            ]
        },
        auxiliaryUtilization: {
            ...kpiChartConfig,
            chart: {
                ...kpiChartConfig.chart,
                dynamicExtremes: false
            },
            yAxis: {
                ...yAxis,
                min: 0,
                title: {
                    ...yAxis.title,
                    text: 'Hours (h)',
                    textAlign: 'left'
                }
            },
            series: [
                {
                    name: 'Baseline',
                    color: '#f44336',
                    type: 'line',
                    lineWidth: 2,
                    data: [],
                    marker: {
                        enabled: false,
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    },
                    enableMouseTracking: false
                },
                {
                    name: 'Hours',
                    color: '#48a6f3',
                    type: 'line',
                    lineWidth: 2,
                    data: []
                }
            ]
        },
        boilerUtilization: {
            ...kpiChartConfig,
            chart: {
                ...kpiChartConfig.chart,
                dynamicExtremes: false
            },
            yAxis: {
                ...yAxis,
                min: 0,
                title: {
                    ...yAxis.title,
                    text: 'Hours (h)',
                    textAlign: 'left'
                }
            },
            series: [
                {
                    name: 'Baseline',
                    color: '#f44336',
                    type: 'line',
                    lineWidth: 2,
                    data: [],
                    marker: {
                        enabled: false,
                        states: {
                            hover: {
                                enabled: false
                            }
                        }
                    },
                    enableMouseTracking: false
                },
                {
                    name: 'Hours',
                    color: '#48a6f3',
                    type: 'line',
                    lineWidth: 2,
                    data: []
                }
            ]
        },
        ladenRPMAnalysis: {
            ...kpiChartConfig,
            yAxis: [
                {
                    ...yAxis,
                    minTickInterval: 1,
                    allowDecimals: false,
                    id: 'speed',
                    title: {
                        ...yAxis.title,
                        text: 'Speed (kn)',
                        textAlign: 'right'
                    },
                    opposite: true
                },
                {
                    ...yAxis,
                    id: 'rpm',
                    minTickInterval: 1,
                    allowDecimals: false,
                    title: {
                        ...yAxis.title,
                        text: 'RPM',
                        textAlign: 'left'
                    }
                }
            ],
            series: [
                {
                    name: 'RPM',
                    color: '#48a6f3',
                    type: 'line',
                    lineWidth: 2,
                    yAxis: 'rpm',
                    data: []
                },
                {
                    name: 'Speed',
                    color: '#28ffca',
                    type: 'line',
                    lineWidth: 2,
                    yAxis: 'speed',
                    data: []
                }
            ]
        }
    };
};

export default getChartConfigs;
