import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* Actions */
import { setMapType } from './gsm-map-view-switch-actions';
/* Styles */
import './gsm-map-view-switch.scss';

import imageButtonClassicSrc from 'assets/images/map-options/btn_classic_map.png';
import imageButtonSatelliteSrc from 'assets/images/map-options/btn_satelite_map.png';

import { translate } from 'utils/i18n/i18n-model';

export const mapImagesMap = {
    classic: {
        id: 1,
        image: imageButtonSatelliteSrc,
        text: 'GSM.MAP_TYPE_CHANGE.CHANGE_TO_SATELITE_VIEW'
    },
    satelite: {
        id: 2,
        image: imageButtonClassicSrc,
        text: 'GSM.MAP_TYPE_CHANGE.CHANGE_TO_CLASSIC_VIEW'
    }
};

export class GsmMapViewSwitch extends React.PureComponent {
    constructor(props) {
        super(props);

        this.currentMapTypeImage = mapImagesMap[this.props.mapType];
    }

    componentDidUpdate(prevProps) {
        if (prevProps.mapType !== this.props.mapType) {
            const changedMapType = this.props.mapType === 'classic' ? 'satelite' : 'classic';
            this.currentMapTypeImage = mapImagesMap[changedMapType];
            this.props.setMapType(this.props.mapType);
        }
    }

    switchMapType = () => {
        const changedMapType = this.props.mapType === 'classic' ? 'satelite' : 'classic';
        this.currentMapTypeImage = mapImagesMap[changedMapType];
        this.props.setMapType(changedMapType);
    };

    render() {
        const style = {
            backgroundImage: `url(${this.currentMapTypeImage.image})`
        };

        return (
            <div
                className="gsm-map-view-switch"
                style={style}
                onClick={this.switchMapType}
            >
                <span className="gsm-map-view-switch__text">{translate(this.currentMapTypeImage.text)}</span>
            </div>
        );
    }
}

GsmMapViewSwitch.propTypes = {
    mapType: PropTypes.string.isRequired,
    setMapType: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        mapType: state.gsmMapViewSwitchReducer.mapType
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setMapType: mapType => setMapType(dispatch, mapType)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GsmMapViewSwitch);
