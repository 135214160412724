import { ActionTypes } from './employed-vessels-panel-actions';

const initialState = {
    employedVessels: [],
    activeCargoes: [],
    historicalCargoes: [],
    cargoLimit: 20,
    cargoOffset: 0
};

export default function employedVesselsPanelReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.EMPLOYED_VESSELS_PANEL_SET_EMPLOYED_VESSELS: {
        return {
            ...state,
            employedVessels: action.employedVessels
        };
    }
    case ActionTypes.EMPLOYED_VESSELS_PANEL_SET_ACTIVE_CARGOES: {
        return {
            ...state,
            activeCargoes: action.cargoes
        };
    }
    case ActionTypes.EMPLOYED_VESSELS_PANEL_SET_HISTORICAL_CARGOES: {
        let historicalCargoes;
        if (action.overrideCurrent) {
            historicalCargoes = action.cargoes;
        } else {
            historicalCargoes = state.historicalCargoes.slice().concat(action.cargoes);
        }
        return {
            ...state,
            historicalCargoes,
            cargoOffset: action.offset
        };
    }
    default:
        return state;
    }
}
