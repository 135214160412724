import React from 'react';
import PropTypes from 'prop-types';
import ScrollArea from 'components/scroll-area/scroll-area';
/* router */
import { TRouter, withRouter } from 'app-router';
/* components */
import Accordion from 'components/accordion/accordion';
/* Styles */
import './gsm-main-panel-offices.scss';

export class GsmMainPanelOffices extends React.PureComponent {
    onRowClick = cityId => (e) => {
        e.stopPropagation();
        this.props.router.navigate({ query: { city: cityId } });
    };

    getCityAccordionHeader = city => [
        <h4 key="title" className="flex-grow text-uppercase">
            {city.CityName}
        </h4>,
        <div key="button" className="flex-shrink text-right">
            <button
                className="btn-link icon icon-position"
                onClick={this.onRowClick(city.CityId)}
            />
        </div>
    ];

    getItemsOrGroup = (data) => {
        if (data) {
            return data.map((city) => {
                const header = this.getCityAccordionHeader(city);
                return (
                    <Accordion className="gsm-main-panel-offices__row" key={city.CityId} header={header}>
                        {city.Companies.map(company => (
                            <div className="sten-content__section sten-content__separator" key={company.CompanyId}>
                                {
                                    /*
                                        <div
                                            className="gsm-main-panel-offices__office-row__icon flex flex-center"
                                            style={{ ...style }}
                                        >
                                        <span className={iconClass} />
                                    </div> */
                                }
                                <p className="text-uppercase">
                                    {company.CompanyName}
                                </p>
                            </div>
                        ))}
                    </Accordion>
                );
            });
        }
        return null;
    };

    render() {
        return (
            <ScrollArea>
                {this.getItemsOrGroup(this.props.data)}
            </ScrollArea>
        );
    }
}

GsmMainPanelOffices.propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    router: TRouter.isRequired
};

export default withRouter(GsmMainPanelOffices);
