/* eslint react/no-multi-comp: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import Highstock from 'highcharts/highstock';
import HighchartsMore from 'highcharts/highcharts-more';
import deepMerge from 'deepmerge';
/* utils */
import TimeHelper from 'utils/helpers/time-helper';
import createSharedStateContext from 'utils/shared-state';
/* styles */
import './chart.scss';
/* config */
import getConfig from './chart-config';

Highstock.setOptions({
    lang: {
        resetZoom: 'Reset',
        resetZoomTitle: 'Reset to initial range'
    }
});

export default class Chart extends React.Component {
    componentDidMount() {
        let config = deepMerge(getConfig(), {
            tooltip: {
                dateTimeLabelFormats: {
                    day: TimeHelper.getChartTimeFormat(),
                    week: TimeHelper.getChartTimeFormat(),
                    month: TimeHelper.getChartTimeFormat(),
                    year: TimeHelper.getChartTimeFormat()
                },
                xDateFormat: TimeHelper.getChartTimeFormat()
            }
        });
        config = deepMerge(config, this.props.config, {
            arrayMerge: (destination, source) => source
        });
        if (this.props.onExtremeChange) {
            const afterSetExtremes = config.xAxis.events.afterSetExtremes;
            config.xAxis.events.afterSetExtremes = (data) => {
                if (afterSetExtremes) {
                    afterSetExtremes(data);
                }
                this.props.onExtremeChange(data);
                if (this.props.manageResetZoom) {
                    if (this.zoomedOutMax - this.zoomedOutMin === data.max - data.min) {
                        this.hideZoomButton();
                    } else {
                        this.showZoomButton();
                    }
                }
            };
        }
        if (this.props.highchartsMore) {
            HighchartsMore(Highstock);
        }
        if (this.props.isChart) {
            this.highchart = Highstock.chart(this.chart, config);
        } else {
            this.highchart = Highstock.stockChart(this.chart, config);
        }
        if (this.props.options) {
            this.highchart.update(this.props.options);
        }
        this.updateSeries(this.props);
    }

    hideZoomButton = () => setTimeout(() =>
        this.highchart.resetZoomButton && this.highchart.resetZoomButton.hide(), 10);

    showZoomButton = () => setTimeout(() =>
        this.highchart.resetZoomButton && this.highchart.resetZoomButton.show(), 10);

    shouldComponentUpdate(nextProps) {
        if (nextProps.series !== this.props.series
            || (nextProps.categories !== this.props.categories && nextProps.categories.length)) {
            this.updateSeries(nextProps);
        }
        if (nextProps.options !== this.props.options && nextProps.options) {
            this.highchart.update(nextProps.options);
        }
        if (nextProps.min !== this.props.min || nextProps.max !== this.props.max) {
            if (this.props.manageResetZoom) {
                if (typeof this.zoomedOutMin === 'undefined' && typeof this.zoomedOutMax === 'undefined') {
                    this.zoomedOutMin = nextProps.min;
                    this.zoomedOutMax = nextProps.max;
                    this.hideZoomButton();
                }
            }
            this.highchart.xAxis[0].setExtremes(
                nextProps.min,
                nextProps.max,
                true,
                false
            );
        }

        return false;
    }

    updateSeries({ series, categories }) {
        if (series && this.highchart.series && this.highchart.series.length) {
            const shouldUpdateCategories = categories && categories.length
                && this.highchart.options.xAxis[0].type === 'category';
            if (shouldUpdateCategories) {
                this.highchart.xAxis[0].update({ categories });
            }
            Object.keys(series).forEach((itemKey, index) => {
                this.highchart.series[index].update({
                    data: series[itemKey],
                    pointInterval: shouldUpdateCategories ? null : 24 * 3600 * 1000,
                    pointStart: categories && categories[0] && categories[0].getTime ? categories[0].getTime() : 0
                });
            });
        }
    }

    saveRef = (c) => { this.chart = c; };

    render() {
        let chartClass = 'sten-chart';
        if (this.props.className) {
            chartClass += ` ${this.props.className}`;
        }
        return (
            <div className={chartClass} ref={this.saveRef} />
        );
    }
}

Chart.propTypes = {
    categories: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]).isRequired,
    className: PropTypes.string,
    config: PropTypes.objectOf(PropTypes.any).isRequired,
    highchartsMore: PropTypes.bool,
    isChart: PropTypes.bool,
    manageResetZoom: PropTypes.bool,
    max: PropTypes.number,
    min: PropTypes.number,
    onExtremeChange: PropTypes.func,
    options: PropTypes.objectOf(PropTypes.any),
    series: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

Chart.defaultProps = {
    className: '',
    highchartsMore: false,
    isChart: false,
    manageResetZoom: false,
    max: null,
    min: null,
    onExtremeChange: undefined,
    options: null,
    series: null
};

export function createSynchronizedCharts() {
    const { withSharedState, SharedStateProvider } = createSharedStateContext({});

    class SynchronizedChart extends React.Component {
        handleExtremeChange = (data) => {
            if (this.props.min !== data.min || this.props.max !== data.max) {
                this.props.setSharedState({
                    min: data.min,
                    max: data.max
                });
            }
        };

        render = () => (
            <Chart {...this.props} onExtremeChange={this.handleExtremeChange} />
        );
    }

    SynchronizedChart.propTypes = {
        max: PropTypes.number,
        min: PropTypes.number,
        setSharedState: PropTypes.func
    };

    SynchronizedChart.defaultProps = {
        max: null,
        min: null,
        setSharedState: undefined
    };

    return { SharedStateProvider, SynchronizedChart: withSharedState(SynchronizedChart) };
}
