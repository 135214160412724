/* eslint-disable max-len */
import React from 'react';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
import { formatCoordinates, formatValue } from 'utils/helpers/info-helper';

const formAllowedConsumptionValues = (data, config) => {
    if (!data) {
        return '-';
    }
    return config.map(row => (
        <p key={row.key}>
            <span className="text-secondary">{`${row.label} :`}</span> {formatValue(data, row)}
        </p>
    ));
};

const getCellClass = (data, key) => {
    if (data.hasOwnProperty(key) && typeof data[key] === 'boolean') {
        return data[key]
            ? 'sten-weather-performance-report-panel--optimal-value'
            : 'sten-weather-performance-report-panel--non-optimal-value';
    }
    return '';
};

export const currentSituationConfig = {
    rows: [
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CURRENT_SITUATION_PANEL.SPEED_ORDER'),
            key: 'SpeedOrder',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.SPEED')
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CURRENT_SITUATION_PANEL.SAILED_DISTANCE'),
            key: 'SailedDistance',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.DISTANCE')
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CURRENT_SITUATION_PANEL.TOTAL_DISTANCE'),
            key: 'TotalDistance',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.DISTANCE')
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CURRENT_SITUATION_PANEL.SOSP_ATD'),
            key: 'Sosp',
            renderValue: (data, row) => {
                return data[row.key] ? TimeHelper.getFormatted(data[row.key], { utc: true, time: true }) : '-';
            }
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CURRENT_SITUATION_PANEL.REPORTED_ETA'),
            key: 'ReportedEta',
            renderValue: (data, row) => {
                return data[row.key] ? TimeHelper.getFormatted(data[row.key], { utc: true, time: true }) : '-';
            }
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CURRENT_SITUATION_PANEL.CALCULATED_ETA_ADVICE'),
            key: 'CalculatedEta',
            renderValue: (data, row) => {
                return data[row.key] ? TimeHelper.getFormatted(data[row.key], { utc: true, time: true }) : '-';
            }
        }
    ],
    columns: []
};

export const lastReportedSituationConfig = {
    rows: [
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.REPORTED_SITUATION_PANEL.DATE_TIME'),
            key: 'Date',
            renderValue: (data, row) => {
                return data[row.key] ? TimeHelper.getFormatted(data[row.key], { utc: true, time: true }) : '-';
            }
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.REPORTED_SITUATION_PANEL.LATITUDE'),
            key: 'Latitude',
            renderValue: (data, row) => {
                return formatCoordinates(data.Longitude, data.Latitude, 4)[row.key];
            }
        }, {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.REPORTED_SITUATION_PANEL.LONGITUDE'),
            key: 'Longitude',
            renderValue: (data, row) => {
                return formatCoordinates(data.Longitude, data.Latitude, 4)[row.key];
            }
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.REPORTED_SITUATION_PANEL.WIND_SPEED_LAST_24'),
            key: 'WindSpeedKnots',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.WIND_SPEED'),
            renderValue: (data, row) => {
                return `${formatValue(data, row)} / ${formatValue(data, {
                    key: 'WindSpeedBeaufort',
                    unit: t('UNITS.WIND_FORCE')
                })}`;
            }
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.REPORTED_SITUATION_PANEL.WAVES_AND_SWELL_LAST_24'),
            key: 'WaveHeightMeter',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.SWELL_HEIGHT'),
            renderValue: (data, row) => {
                return `${formatValue(data, row)} / ${formatValue(data, {
                    key: 'WaveHeightDouglas',
                    type: 'number',
                    decimals: 1,
                    unit: t('UNITS.DOUGLAS')
                })}`;
            }
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.REPORTED_SITUATION_PANEL.AVERAGE_SPEED_LAST_24'),
            key: 'AverageSpeed',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.SPEED')
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.REPORTED_SITUATION_PANEL.HFO_CONSUMPTION_LAST_24'),
            key: 'HfoConsumption',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.CONSUMPTION')
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.REPORTED_SITUATION_PANEL.MDO_CONSUMPTION_LAST_24'),
            key: 'MdoConsumption',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.CONSUMPTION')
        }
        // {
        //     label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.REPORTED_SITUATION_PANEL.LNG_CONSUMPTION_LAST_24'),
        //     key: 'LngConsumption',
        //     type: 'number',
        //     decimals: 1,
        //     unit: t('UNITS.CONSUMPTION')
        // }
    ],
    columns: []
};

export const speedAnalysisTableConfig = {
    rows: [
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.SPEED_ANALYSIS_PANEL.DISTANCE'),
            key: 'Distance',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.DISTANCE')
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.SPEED_ANALYSIS_PANEL.TIME'),
            key: 'Time',
            type: 'number',
            decimals: 0,
            unit: t('UNITS.HOUR')
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.SPEED_ANALYSIS_PANEL.AVERAGE_SPEED'),
            key: 'AverageSpeed',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.SPEED')
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.SPEED_ANALYSIS_PANEL.CURRENT_FACTOR'),
            key: 'CurrentFactor',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.SPEED')
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.SPEED_ANALYSIS_PANEL.WEATHER_FACTOR'),
            key: 'WeatherFactor',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.WIND_SPEED')
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.SPEED_ANALYSIS_PANEL.PERFORMANCE_INSTRUCTED_SPEED'),
            key: 'PerformanceSpeed',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.SPEED'),
            renderValue: (data, row) => {
                return `${formatValue(data, row)} / ${formatValue(data, row, 'InstructedSpeed')}`;
            },
            getCellClass: (data, col) => getCellClass(data[col], 'IsSpeedPerformanceOptimal')
        }
    ],
    columns: ['AllWeather', 'GoodWeather'],
    headerLabels: [
        t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.SPEED_ANALYSIS_PANEL.PARAMETERS'),
        t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.SPEED_ANALYSIS_PANEL.ALL_WEATHER'),
        t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.SPEED_ANALYSIS_PANEL.GOOD_WEATHER')
    ]
};
const bunkerAllowedConsumptionConfig = [
    {
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.BUNKER_ANALYSIS_PANEL.MINIMUM_ALLOWED'),
        key: 'MinimumAllowed',
        type: 'number',
        decimals: 2,
        unit: t('UNITS.CONSUMPTION')
    },
    {
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.BUNKER_ANALYSIS_PANEL.WITHOUT_ALLOWANCE'),
        key: 'WithoutAllowance',
        type: 'number',
        decimals: 2,
        unit: t('UNITS.CONSUMPTION')
    },
    {
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.BUNKER_ANALYSIS_PANEL.MAXIMUM_ALLOWED'),
        key: 'MaximumAllowed',
        type: 'number',
        decimals: 2,
        unit: t('UNITS.CONSUMPTION')
    }
];

export const bunkerTableConfig = {
    rows: [
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.BUNKER_ANALYSIS_PANEL.TOTAL_CONSUMPTION'),
            key: 'TotalConsumption',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.CONSUMPTION')
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.BUNKER_ANALYSIS_PANEL.TOTAL_CO2_EMISSION'),
            key: 'TotalCo2Emission',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.CONSUMPTION')
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.BUNKER_ANALYSIS_PANEL.AVERAGE_CONSUMPTION'),
            key: 'AverageConsumption',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.CONSUMPTION')
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.BUNKER_ANALYSIS_PANEL.ALLOWED_CONSUMPTION'),
            key: 'GoodWeatherAllowedConsumption',
            renderValue: (data, row) => {
                return formAllowedConsumptionValues(data[row.key], bunkerAllowedConsumptionConfig);
            }
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.BUNKER_ANALYSIS_PANEL.ALLOWED_CONSUMPTION_EXTRAPOLATED'),
            key: 'GoodWeatherAllowedConsumptionExtrapolated',
            renderValue: (data, row) => {
                return formAllowedConsumptionValues(data[row.key], bunkerAllowedConsumptionConfig);
            }
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.BUNKER_ANALYSIS_PANEL.GOOD_WEATHER_CONSUMPTION_EXTRAPOLATED_VOYAGE'),
            key: 'GoodWeatherConsumptionExtrapolatedVoyage',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.CONSUMPTION')
        },
        {
            key: 'ConsumptionDelta',
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.BUNKER_ANALYSIS_PANEL.CONSUMPTION_DELTA'),
            type: 'number',
            decimals: 1,
            unit: t('UNITS.CONSUMPTION'),
            getCellClass: (data, col) => getCellClass(data[col], 'IsConsumptionDeltaOptimal')
        }
    ],
    columns: ['AllWeather', 'GoodWeather'],
    headerLabels: [
        t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.BUNKER_ANALYSIS_PANEL.PARAMETERS'),
        t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.BUNKER_ANALYSIS_PANEL.ALL_WEATHER'),
        t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.BUNKER_ANALYSIS_PANEL.GOOD_WEATHER')
    ]
};

const timeAnalysisColumnConfigSpeed = {
    key: 'Speed',
    type: 'number',
    decimals: 1,
    unit: t('UNITS.SPEED')
};

export const timeAnalysisTableConfig = {
    rows: [
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.TIME_ANALYSIS_PANEL.CP_TIME'),
            key: 'CharterPartyTime'
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.TIME_ANALYSIS_PANEL.MAXIMUM_WARRANTED_TIME'),
            key: 'MaximumWarrantedTime'
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.TIME_ANALYSIS_PANEL.GWT_EXTRAPOLATED_VOYAGE'),
            key: 'GoodWeatherExtrapolatedTime'
        },
        {
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.TIME_ANALYSIS_PANEL.TIME_LOST'),
            key: 'TimeLost',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.HOUR')
        }
    ],
    columns: [
        {
            key: 'Distance',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.DISTANCE'),
            renderValue: (data, col, row) => {
                if (row.key === 'TimeLost') {
                    return '';
                }
                return `${formatValue(data, col, `${row.key}.${col.key}`)} /
                        ${formatValue(data, timeAnalysisColumnConfigSpeed, `${row.key}.${timeAnalysisColumnConfigSpeed.key}`)}`;
            }
        },
        {
            key: 'Result',
            type: 'number',
            decimals: 1,
            unit: t('UNITS.HOUR'),
            renderValue: (data, col, row) => {
                if (row.key === 'TimeLost') {
                    return formatValue(data, row);
                }
                return formatValue(data, col, `${row.key}.${col.key}`);
            },
            getCellClass: (data, row) => {
                if (row.key === 'TimeLost') {
                    return getCellClass(data, 'IsTimeLostOptimal');
                }
                return '';
            }
        }
    ],
    headerLabels: [
        t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.TIME_ANALYSIS_PANEL.PARAMETERS'),
        t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.TIME_ANALYSIS_PANEL.CALCULATION'),
        t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.TIME_ANALYSIS_PANEL.RESULT')
    ]
};
