import React from 'react';
import PropTypes from 'prop-types';
/* components */
import TankLayoutVessel from './vessel/tank-layout-vessel';
import TankLayoutCards from './cards/tank-layout-cards';
/* utils */
import { formatNumber } from 'utils/helpers/info-helper';
import { translate } from 'utils/i18n/i18n-model';
/* style */
import './tank-layout.scss';

const t = (key) => translate(`TANK_LAYOUT.${key}`);

class TankLayout extends React.PureComponent {
    formatCharters = () => {
        const charterers = [];
        this.props.cargoSummary.Charterers.forEach(charter => {
            if (charter) {
                charterers.push(charter);
            }
        });
        return [...new Set(charterers)];
    };

    render() {
        const { className, showLegend, showPreviousCommodities, isCollapsible, wings, cargoSummary } = this.props;
        const totalCargoQuantity = cargoSummary && typeof cargoSummary.TotalCargoQuantity === 'number'
            ? `${formatNumber(cargoSummary.TotalCargoQuantity, 0)} ${translate('UNITS.CUBIC_CAPACITY')}` : '-';
        const totalWeight = cargoSummary && typeof cargoSummary.TotalWeight === 'number'
            ? `${formatNumber(cargoSummary.TotalWeight, 0)} ${translate('UNITS.CARGO')}` : '-';
        const vesselUtilization = cargoSummary && typeof cargoSummary.VesselUtilization === 'number'
            ? `${formatNumber(cargoSummary.VesselUtilization, 0)} %` : '-';
        const charterers = cargoSummary && this.formatCharters();
        const coating = cargoSummary && cargoSummary.Coating ? cargoSummary.Coating : '-';
        return (
            <div className={`sten-tank-layout${className ? ` ${className}` : ''}`}>
                {cargoSummary && (
                    <div className="sten-content__section">
                        <div className="flex-row flex-center sten-panel__row">
                            <div className="col-10">
                                <p className="text-secondary">{t('SUMMARY.TOTAL_CARGO_QUANTITY')}</p>
                            </div>
                            <div className="col-14">
                                <p>{totalCargoQuantity}</p>
                            </div>
                        </div>
                        <div className="flex-row flex-center sten-panel__row">
                            <div className="col-10">
                                <p className="text-secondary">{t('SUMMARY.TOTAL_WEIGHT')}</p>
                            </div>
                            <div className="col-14">
                                <p>{totalWeight}</p>
                            </div>
                        </div>
                        <div className="flex-row flex-center sten-panel__row">
                            <div className="col-10">
                                <p className="text-secondary">{t('SUMMARY.VESSEL_UTILIZATION')}</p>
                            </div>
                            <div className="col-14">
                                <p>{vesselUtilization}</p>
                            </div>
                        </div>
                        <div className="flex-row flex-center sten-panel__row">
                            <div className="col-10">
                                <p className="text-secondary">{t('SUMMARY.CHARTERERS')}</p>
                            </div>
                            <div className="col-14">
                                <p>{charterers.length > 0 ? charterers.join(', ') : '-'}</p>
                            </div>
                        </div>
                        <div className="flex-row flex-center sten-panel__row">
                            <div className="col-10">
                                <p className="text-secondary">{t('SUMMARY.COATING')}</p>
                            </div>
                            <div className="col-14">
                                <p>{coating}</p>
                            </div>
                        </div>
                    </div>
                )}
                <div className="sten-tank-layout__vessel">
                    <TankLayoutVessel wings={wings} showLegend={showLegend} />
                </div>
                <TankLayoutCards
                    isCollapsible={isCollapsible}
                    wings={wings}
                    showPreviousCommodities={showPreviousCommodities}
                />
            </div>
        );
    }
}

TankLayout.propTypes = {
    cargoSummary: PropTypes.objectOf(PropTypes.any),
    className: PropTypes.string,
    isCollapsible: PropTypes.bool,
    showLegend: PropTypes.bool,
    showPreviousCommodities: PropTypes.bool,
    wings: PropTypes.arrayOf(PropTypes.object)
};

TankLayout.defaultProps = {
    cargoSummary: null,
    className: '',
    isCollapsible: false,
    showLegend: true,
    showPreviousCommodities: false,
    wings: []
};

export default TankLayout;
