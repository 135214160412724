import { ActionTypes } from './raw-reports-table-actions';
import { ActionTypes as ReportActionTypes } from 'components/vessel-report/vessel-report-actions';

const emptyArray = [];

const initialState = {
    isMounted: false,
    reportType: null,
    reports: null,
    visibility: {
        included: true,
        excluded: true,
        missing: true,
        suspicious: false,
        veracitySyncError: false
    },
    reportDataTypes: null,
    deletedReports: emptyArray,
    updatedReports: emptyArray
};

export default function rawReportsTableReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.RAW_REPORTS_TABLE_SET_MOUNTED: {
        return { ...state, isMounted: true };
    }
    case ActionTypes.RAW_REPORTS_TABLE_SET_REPORTS: {
        return {
            ...state,
            reports: action.reports || state.reports,
            visibility: initialState.visibility
        };
    }
    case ActionTypes.RAW_REPORTS_TABLE_SET_VISIBILITY: {
        return {
            ...state,
            visibility: { ...state.visibility, ...action.visibility }
        };
    }
    case ActionTypes.RAW_REPORTS_TABLE_SET_REPORT_DATA_TYPES: {
        return {
            ...state,
            reportType: action.reportType,
            reportDataTypes: action.reportDataTypes
        };
    }
    case ReportActionTypes.VESSEL_REPORT_REMINDER_SENT: {
        if (state.reports && state.reports.Reports) {
            const Reports = state.reports.Reports.slice();
            const foundIndex = Reports.findIndex(report => report.MissingReportId === action.report.MissingReportId);
            if (typeof foundIndex === 'number') {
                Reports[foundIndex] = {
                    ...Reports[foundIndex],
                    UserName: action.report.UserName,
                    EmailTo: action.report.EmailTo,
                    ReminderDate: action.report.ReminderDate,
                    IsSendEnabled: action.report.IsSendEnabled
                };
            }
            return {
                ...state,
                reports: { ...state.reports, Reports }
            };
        }
        return state;
    }
    case ReportActionTypes.VESSEL_REPORT_SUCCESSFULLY_DELETED: {
        if (state.isMounted) {
            const deletedReports = state.deletedReports.slice();
            deletedReports.push(action.reportId);
            return {
                ...state,
                deletedReports
            };
        }
        return state;
    }
    case ReportActionTypes.VESSEL_REPORT_SUCCESSFULLY_UPDATED: {
        if (state.isMounted) {
            const updatedReports = state.updatedReports.slice();
            updatedReports.push(action.reportId);
            return {
                ...state,
                updatedReports
            };
        }
        return state;
    }
    case ActionTypes.RAW_REPORTS_TABLE_RESET_UPDATED_REPORTS: {
        return {
            ...state,
            deletedReports: emptyArray,
            updatedReports: emptyArray
        };
    }
    case ActionTypes.RAW_REPORTS_TABLE_RESET_ALL: {
        return initialState;
    }
    default:
        return state;
    }
}
