/* utils */
import { tileLoadFunction } from './nautical-charts-helpers';

export const NAUTICAL_CHART_LAYERS_MAP = {
    PROFESSIONAL: 'ENC',
    OVERVIEW: 'THE_CHART',
    CIO_NAV_STAY_AWAY: 'CIO',
    CIO_ENVIRONMENTAL: 'ECO',
    GIS_TOPOGRAPHY: 'GIS-ENC-TOPO',
    GIS_NAVIGATIONAL_AIDS: 'GIS-ENC-NAVAIDS',
    GIS_DEPTHS: 'GIS-ENC-DEPTHS',
    GIS_NAV_AREAS: 'GIS-ENC-NAVAREAS',
    GIS_PIPES_CABLES: 'GIS-ENC-CBLPIP',
    GIS_FISHING: 'GIS-ENC-FISHING'
};

export const requestParams = {
    REQUEST: 'GetMap',
    LAYERS: 'ENC',
    TRANSPARENT: 'true'
};

export const nauticalChartBaseMapSourceProps = {
    params: { ...requestParams, LAYERS: NAUTICAL_CHART_LAYERS_MAP.PROFESSIONAL },
    url: '/',
    tileLoadFunction
};

export const nauticalChartBaseMapLayerProps = {
    preload: 1
};

export const nauticalChartLayersConfigs = {
    [NAUTICAL_CHART_LAYERS_MAP.CIO_NAV_STAY_AWAY]: {
        opacity: 1,
        order: 2
    }
    // [NAUTICAL_CHART_LAYERS_MAP.CIO_ENVIRONMENTAL]: {
    //     opacity: 1,
    //     order: 1
    // },
    // [NAUTICAL_CHART_LAYERS_MAP.GIS_NAVIGATIONAL_AIDS]: {
    //     opacity: 1,
    //     order: 2
    // },
    // [NAUTICAL_CHART_LAYERS_MAP.GIS_DEPTHS]: {
    //     opacity: 1,
    //     order: 0
    // },
    // [NAUTICAL_CHART_LAYERS_MAP.GIS_NAV_AREAS]: {
    //     opacity: 1,
    //     order: 2
    // },
    // [NAUTICAL_CHART_LAYERS_MAP.GIS_PIPES_CABLES]: {
    //     opacity: 1,
    //     order: 2
    // }
};
