export default {
    vessel: {
        zIndex: 100,
        opacity: 0.8,
        primaryColor: 'rgb(40,255,202)'
    },
    port: {
        primaryColor: 'rgb(40,255,202)',
        secondaryColor: 'rgb(255,255,255)',
        backgroundColor: 'rgba(15,46,57,0.3)',
        labelMinZoomLevel: 4,
        zoomOnSelect: 9,
        zIndex: 60
    },
    route: {
        ballast: {
            color: 'rgba(255,255,255,0.8)',
            width: 2
        },
        ballastApprox: {
            color: 'rgba(40,255,202,0.5)',
            width: 2,
            lineDash: [0, 12],
            lineDashMaxZoom: 12,
            zoomColor: 'rgba(40,255,202,0.2)',
            zoomWidth: 1

        },
        laden: {
            color: 'rgba(40,255,202,0.8)',
            width: 2
        },
        ladenApprox: {
            color: 'rgba(40,255,202,0.5)',
            width: 2,
            lineDash: [0, 12],
            lineDashMaxZoom: 12,
            zoomColor: 'rgba(40,255,202,0.2)',
            zoomWidth: 1
        },
        future: {
            color: '#78a8bc',
            width: 2,
            lineDash: [4, 8],
            lineDashMaxZoom: 12
        },
        zIndex: 40
    },
    weatherRoute: {
        width: 2,
        zIndex: 40
    },
    weatherRouteDots: {
        zIndex: 50
    },
    areaOfInterest: {
        lineColorDefault: '#fff',
        lineWidth: 1,
        zIndex: 30,
        // Blue Area Color (Load/Discharge Areas)
        1: {
            lineColor: '#3498db'
        },
        // Red Area Color (High Risk Areas)
        2: {
            lineColor: '#ff3f3f'
        },
        // Orange Area Color (Conditional Trading)
        3: {
            lineColor: '#ffc334'
        },
        // Green Area Color (ECA)
        4: {
            lineColor: '#0c9c12'
        },
        // Blue Area Color (Vessel Distribution Areas)
        5: {
            lineColor: '#01579b'
        },
        // Orange Area Color (DTN No-go zone)
        6: {
            lineColor: '#ff9800'
        },
        // Turquoise Area Color (DTN ECA zone)
        7: {
            lineColor: '#28ffca'
        }
    },
    weather: {
        zIndex: 20
    },
    nautical: {
        zIndex: 10
    },
    pointTypesMap: {
        1: 'point',
        2: 'port',
        3: 'pin'
    }
};
