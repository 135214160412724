import { ActionTypes } from './home-actions';

const emptyArray = [];

const initialState = {
    isMounted: false,
    vessels: emptyArray,
    vesselsOnMapCount: null,
    ports: emptyArray,
    mapViewProps: null,
    selectedVesselId: null,
    selectedVesselBase: null,
    selectedVesselCii: null,
    selectedVesselDetails: null,
    selectedVesselImages: emptyArray,
    selectedVesselRelativeDetails: null,
    selectedVesselBudgetedConsumption: emptyArray,
    selectedVesselPosition: null,
    selectedVesselNotifications: emptyArray,
    selectedVesselVoyages: emptyArray,
    selectedVesselNextPoint: null,
    selectedVesselSensorData: null,
    improvisedVoyage: null,
    selectedVoyage: null,
    selectedVoyageAisPoints: null,
    selectedVoyageReports: emptyArray,
    selectedVoyageRoutePoints: null,
    selectedVoyageTankLayout: emptyArray,
    selectedVoyageCargoSummary: null,
    selectedVoyageFixtures: emptyArray,
    selectedVoyageItinerary: emptyArray,
    selectedVoyageActivity: null,
    selectedVoyageCharterer: null,
    selectedVoyageEuEts: null,
    selectedVoyageEarnings: null,
    selectedVoyageFootprint: null,
    selectedVoyageVmsFootprint: null,
    latestVoyageReport: null,
    shouldCenterActiveElement: false,
    questionnaireList: emptyArray
};

export default function homeReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.HOME_SET_MOUNTED:
        return {
            ...state,
            isMounted: action.isMounted
        };
    case ActionTypes.HOME_RESET_ALL:
        return {
            ...initialState,
            mapViewProps: state.mapViewProps
        };
    case ActionTypes.HOME_SET_VESSELS: {
        return {
            ...state,
            vessels: action.vessels,
            vesselsOnMapCount: action.vesselsOnMapCount
        };
    }
    case ActionTypes.HOME_SET_PORTS: {
        return {
            ...state,
            ports: action.ports
        };
    }
    case ActionTypes.HOME_SET_MAP_VIEW_PROPS: {
        return {
            ...state,
            mapViewProps: action.mapViewProps
        };
    }
    case ActionTypes.HOME_SET_SELECTED_VESSEL_BASE: {
        return {
            ...state,
            selectedVesselBase: action.vesselBase,
            selectedVesselId: action.vesselBase ? action.vesselBase.IMO : null
        };
    }
    case ActionTypes.HOME_SET_SELECTED_VESSEL_DETAILS: {
        return {
            ...state,
            selectedVesselDetails: action.vesselDetails
        };
    }
    case ActionTypes.HOME_SET_SELECTED_VESSEL_IMAGES: {
        return {
            ...state,
            selectedVesselImages: action.images
        };
    }
    case ActionTypes.HOME_SET_SELECTED_VESSEL_BUDGETED_CONSUMPTION: {
        return {
            ...state,
            selectedVesselBudgetedConsumption: action.budgetedConsumption || emptyArray
        };
    }
    case ActionTypes.HOME_SET_SELECTED_VESSEL_RELATIVE_DETAILS: {
        return {
            ...state,
            selectedVesselRelativeDetails: action.relativeDetails
        };
    }
    case ActionTypes.HOME_SET_SELECTED_VESSEL_POSITION: {
        return {
            ...state,
            selectedVesselPosition: action.vesselPosition || null
        };
    }
    case ActionTypes.HOME_SET_SELECTED_VESSEL_VOYAGES: {
        return {
            ...state,
            selectedVesselVoyages: action.voyages || emptyArray
        };
    }
    case ActionTypes.HOME_SET_SELECTED_VESSEL_NOTIFICATIONS: {
        return {
            ...state,
            selectedVesselNotifications: action.notifications || emptyArray
        };
    }
    case ActionTypes.HOME_SET_SELECTED_VESSEL_NEXT_POINT: {
        return {
            ...state,
            selectedVesselNextPoint: action.point || null
        };
    }
    case ActionTypes.HOME_REMOVE_SELECTED_VESSEL_NOTIFICATIONS: {
        const selectedVesselNotifications = state.selectedVesselNotifications.filter(notification =>
            action.notificationIds.indexOf(notification.NotificationId) < 0);
        return {
            ...state,
            selectedVesselNotifications
        };
    }
    case ActionTypes.HOME_SET_SELECTED_VESSEL_SENSOR_DATA: {
        return {
            ...state,
            selectedVesselSensorData: action.selectedVesselSensorData || null
        };
    }
    case ActionTypes.HOME_REMOVE_SELECTED_VESSEL: {
        return {
            ...state,
            selectedVesselId: null,
            selectedVesselBase: null,
            selectedVesselCii: null,
            selectedVesselDetails: null,
            selectedVesselImages: emptyArray,
            selectedVesselRelativeDetails: null,
            selectedVesselBudgetedConsumption: emptyArray,
            selectedVesselPosition: null,
            selectedVesselNotifications: emptyArray,
            selectedVesselVoyages: emptyArray,
            selectedVesselNextPoint: null,
            selectedVoyage: null,
            selectedVoyageReports: emptyArray,
            selectedVoyageAisPoints: null,
            selectedVoyageRoutePoints: null,
            selectedVoyageFixtures: emptyArray,
            selectedVoyageItinerary: emptyArray,
            selectedVoyageActivity: null,
            selectedVoyageEarnings: null,
            selectedVoyageFootprint: null,
            improvisedVoyage: null,
            latestVoyageReport: null,
            selectedVesselSensorData: null
        };
    }
    case ActionTypes.HOME_SET_SELECTED_VOYAGE: {
        return {
            ...state,
            selectedVoyage: action.voyage
        };
    }
    case ActionTypes.HOME_SET_IMPROVISED_VOYAGE: {
        return {
            ...state,
            improvisedVoyage: action.voyage
        };
    }
    case ActionTypes.HOME_SET_SELECTED_VOYAGE_ROUTE: {
        return {
            ...state,
            selectedVoyageRoutePoints: action.voyageRoutePoints || null
        };
    }
    case ActionTypes.HOME_SET_SELECTED_VOYAGE_AIS_POINTS: {
        return {
            ...state,
            selectedVoyageAisPoints: action.voyageAisPoints || null
        };
    }
    case ActionTypes.HOME_SET_SELECTED_VOYAGE_REPORTS: {
        return {
            ...state,
            selectedVoyageReports: action.selectedVoyageReports || emptyArray
        };
    }
    case ActionTypes.HOME_SET_LATEST_VOYAGE_REPORT: {
        return {
            ...state,
            latestVoyageReport: action.latestVoyageReport
        };
    }
    case ActionTypes.HOME_SET_VOYAGE_CARGO: {
        return {
            ...state,
            selectedVoyageFixtures: action.fixtures || emptyArray,
            selectedVoyageTankLayout:
                action.tankLayout && action.tankLayout.CargoWings ? action.tankLayout.CargoWings : emptyArray,
            selectedVoyageCargoSummary:
                action.tankLayout && action.tankLayout.CargoSummary ? action.tankLayout.CargoSummary : null
        };
    }
    case ActionTypes.HOME_SET_VOYAGE_ITINERARY: {
        return {
            ...state,
            selectedVoyageItinerary: action.voyageItinerary || emptyArray
        };
    }
    case ActionTypes.HOME_SET_VOYAGE_ACTIVITY: {
        return {
            ...state,
            selectedVoyageActivity: action.voyageActivity
        };
    }
    case ActionTypes.HOME_SET_VOYAGE_FOOTPRINT: {
        return {
            ...state,
            selectedVoyageFootprint: action.voyageFootprint
        };
    }
    case ActionTypes.HOME_SET_VOYAGE_VMS_FOOTPRINT: {
        return {
            ...state,
            selectedVoyageVmsFootprint: action.voyageVmsFootprint
        };
    }
    case ActionTypes.HOME_SET_VOYAGE_EU_ETS: {
        return {
            ...state,
            selectedVoyageEuEts: action.voyageEuEts || null
        };
    }
    case ActionTypes.HOME_SET_VOYAGE_EARNINGS: {
        return {
            ...state,
            selectedVoyageEarnings: action.voyageEarnings
        };
    }
    case ActionTypes.HOME_SET_VESSEL_CII_YEARLY_RATING: {
        return {
            ...state,
            selectedVesselCii: action.ciiYearlyRatingWithCorrection
        };
    }
    case ActionTypes.HOME_SET_VOYAGE_CHARTERER: {
        return {
            ...state,
            selectedVoyageCharterer: action.selectedVoyageCharterer
        };
    }
    case ActionTypes.HOME_SET_SHOULD_CENTER_ACTIVE_ELEMENT: {
        return {
            ...state,
            shouldCenterActiveElement: action.shouldCenterActiveElement
        };
    }
    case ActionTypes.HOME_SET_SELECTED_VESSEL_QUESTIONNAIRE_LIST: {
        return {
            ...state,
            questionnaireList: action.questionnaireList
        };
    }
    default:
        return state;
    }
}
