import { createSelector } from 'reselect';
import { getVesselsById } from '../kpi-selectors';
/* helpers */
import { extractChartData } from '../kpi-helpers';
import { getObjectProp } from 'utils/helpers/info-helper';

const emptyArray = [];

function getSortedData(sData, vesselsById, sortProp, sortOrder) {
    const sortOrdering = sortOrder === 'DESC' ? -1 : 1;
    const parsedData = sData.SegmentList.map(data => (
        {
            ...data,
            Vessel: vesselsById[data.Imo]
        }
    ));
    const sortedData = parsedData.slice();
    sortedData.sort((a, b) => {
        const valueA = getObjectProp(a, sortProp);
        const valueB = getObjectProp(b, sortProp);
        let result = 1;
        if (valueA === valueB) {
            result = 0;
        } else if (valueA === null || (valueA < valueB && valueB !== null)) {
            result = -1;
        }
        return result * sortOrdering;
    });
    return sortedData;
}

export const getTotalData = createSelector(
    state => state.energyManagementKPISegmentReducer.basicSegmentData,
    (basicData) => ({
        consumptionChartData:
            basicData && extractChartData(basicData.MonthlyDataList, ['BallastKPI', 'LadenKPI', 'Cargo']),
        energyEfficiencyChartData:
            basicData && basicData.EnergyEfficiencyDataList && extractChartData(
                basicData.EnergyEfficiencyDataList,
                ['Efficiency', 'Suezmax', 'P-MAX', 'Intermediate', 'Medium Range', 'AER'],
                {
                    xAxisYear: true
                }
            ),
        data: basicData && basicData.TotalData
    })
);

export const getSegmentVessels = createSelector(
    state => state.energyManagementKPISegmentReducer.basicSegmentData,
    state => getVesselsById(state),
    state => state.energyManagementKPISegmentReducer.sortProp,
    state => state.energyManagementKPISegmentReducer.sortOrder,
    (sData, vesselsById, sortProp, sortOrder) => {
        if (!sData || !sData.SegmentList) {
            return emptyArray;
        }
        return getSortedData(sData, vesselsById, sortProp, sortOrder);
    }
);
