export const ActionTypes = {
    NAVMENU_EXPAND: 'NAVMENU_EXPAND',
    NAVMENU_SET_ACTIVE_LINK: 'NAVMENU_SET_ACTIVE_LINK'
};

export function navMenuExpand(dispatch, expand) {
    dispatch({
        type: ActionTypes.NAVMENU_EXPAND,
        expand
    });
}

export function navMenuSetActiveLink(dispatch, linkName) {
    dispatch({
        type: ActionTypes.NAVMENU_SET_ACTIVE_LINK,
        name: linkName
    });
}
