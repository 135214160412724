import ApiHelper from './gsm-api';

class GsmFerryRouteService {
    constructor() {
        this.resourceUrl = 'FerryRoutes';
    }

    getAll(params = {}) {
        return ApiHelper.get(`${this.resourceUrl}`, params, true)
            .then(response => response.data, () => null);
    }
}

export default new GsmFerryRouteService();
