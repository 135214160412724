export const ActionTypes = {
    ENERGY_MANAGEMENT_KPI_SEGMENT_GET_BASIC_DATA: 'ENERGY_MANAGEMENT_KPI_SEGMENT_GET_BASIC_DATA',
    ENERGY_MANAGEMENT_KPI_SEGMENT_GET_ADVANCED_DATA: 'ENERGY_MANAGEMENT_KPI_SEGMENT_GET_ADVANCED_DATA',
    ENERGY_MANAGEMENT_KPI_SEGMENT_SET_BASIC_DATA: 'ENERGY_MANAGEMENT_KPI_SEGMENT_SET_BASIC_DATA',
    ENERGY_MANAGEMENT_KPI_SEGMENT_SET_ADVANCED_DATA: 'ENERGY_MANAGEMENT_KPI_SEGMENT_SET_ADVANCED_DATA',
    ENERGY_MANAGEMENT_KPI_BASIC_SEGMENT_SET_SORT_PROP: 'ENERGY_MANAGEMENT_KPI_BASIC_SEGMENT_SET_SORT_PROP',
    ENERGY_MANAGEMENT_KPI_SEGMENT_RESET_ALL: 'ENERGY_MANAGEMENT_KPI_SEGMENT_RESET_ALL'
};

export function getBasicSegmentData(dispatch, segmentId, filters) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_KPI_SEGMENT_GET_BASIC_DATA,
        segmentId,
        filters
    });
}

export function getAdvancedSegmentData(dispatch, segmentId, filters) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_KPI_SEGMENT_GET_ADVANCED_DATA,
        segmentId,
        filters
    });
}


export function setSortProp(dispatch, sortProp) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_KPI_BASIC_SEGMENT_SET_SORT_PROP,
        sortProp
    });
}

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_KPI_SEGMENT_RESET_ALL
    });
}
