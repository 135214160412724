import { takeEvery, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
/* router */
import { navigate, appRoutes } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { setArrayToFormData } from 'utils/helpers/info-helper';
/* actions */
import { ActionTypes } from './admin-company-management-actions';
/* services */
import CompanyService from 'services/core-api/company-service';
import CompanyManagementService from 'services/core-api/company-management-service';

function* getCompanies() {
    const companies = yield CompanyService.Internal.getAll();
    if (companies) {
        yield put({ type: ActionTypes.ADMIN_COMPANY_MANAGEMENT_COMPANIES_SET, companies });
    }
}

const logoKeys = ['LogoLight', 'LogoDark'];

function* updateCompany(action) {
    const { companies } = yield select(state => state.adminCompanyManagementReducer);
    let response;
    let newCompanies;
    logoKeys.forEach((logoKey) => {
        if (action.company[logoKey]) {
            action.formData.set(logoKey, action.company[logoKey][0]);
        } else {
            action.formData.delete(logoKey);
        }
    });
    setArrayToFormData(action.formData, 'FilesForDelete', action.company.FilesForDelete);

    if (action.company.Id) {
        response = yield CompanyService.Internal.update(action.company.Id, action.formData);
        if (response) {
            newCompanies = companies.map(company => (company.Id === response.Id ? response : company));
            toast(t('ADMIN.COMPANY_MANAGEMENT.COMPANY.SUCCESSFULLY_UPDATED'), { type: toast.TYPE.SUCCESS });
        }
    } else {
        response = yield CompanyService.Internal.add(action.formData);
        if (response) {
            newCompanies = [...companies, response];
            toast(t('ADMIN.COMPANY_MANAGEMENT.COMPANY.SUCCESSFULLY_ADDED'), { type: toast.TYPE.SUCCESS });
        }
    }
    if (response) {
        yield put({ type: ActionTypes.ADMIN_COMPANY_MANAGEMENT_COMPANIES_SET, companies: newCompanies });
        yield navigate(appRoutes.Admin.CompanyManagement);
    }
}

function* getPlatformStatistics() {
    const platformStatistics = yield CompanyManagementService.PlatformStatistics.get();
    if (platformStatistics) {
        yield put({ type: ActionTypes.ADMIN_COMPANY_MANAGEMENT_PLATFORM_STATISTICS_SET, platformStatistics });
    }
}

export default function* adminCompanyManagementSaga() {
    yield takeEvery(ActionTypes.ADMIN_COMPANY_MANAGEMENT_COMPANIES_GET, getCompanies);
    yield takeEvery(ActionTypes.ADMIN_COMPANY_MANAGEMENT_COMPANIES_UPDATE, updateCompany);
    yield takeEvery(ActionTypes.ADMIN_COMPANY_MANAGEMENT_PLATFORM_STATISTICS_GET, getPlatformStatistics);
}
