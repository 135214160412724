import React from 'react';
import PropTypes from 'prop-types';
/* styles */
import './tooltip-content.scss';

const placementMap = {
    top: ' sten-tooltip-content--top',
    bottom: ' sten-tooltip-content--bottom',
    right: ' sten-tooltip-content--right',
    left: ' sten-tooltip-content--left'
};

export class TooltipContent extends React.PureComponent {
    render() {
        let className = 'sten-tooltip-content';
        if (placementMap[this.props.placement]) {
            className += placementMap[this.props.placement];
        } else {
            className += placementMap.top;
        }
        if (this.props.className) {
            className += ` ${this.props.className}`;
        }
        if (!this.props.isDisabled && this.props.isVisible) {
            className += ' sten-tooltip-content--visible';
        }
        return (
            <div className={className} style={this.props.style}>
                {this.props.children}
            </div>
        );
    }
}

TooltipContent.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    isDisabled: PropTypes.bool,
    isVisible: PropTypes.bool,
    placement: PropTypes.string,
    style: PropTypes.objectOf(PropTypes.any)
};

TooltipContent.defaultProps = {
    children: null,
    className: '',
    isDisabled: false,
    isVisible: false,
    placement: 'top',
    style: null
};

export default TooltipContent;
