import { all, takeEvery, put, select } from 'redux-saga/effects';
/* actions */
import { ActionTypes } from './map-options-actions';
import { ActionTypes as UserActionTypes } from 'pages/user-pages/user-actions';
/* services */
import MapOptionsService from 'services/core-api/map-options-service';
import AreaService from 'services/core-api/area-service';
import ConfigService from '../../services/config-api/config-service';

let mapOptionsFetched = false;

function* getMapOptions() {
    if (mapOptionsFetched) return;

    const mapOptions = yield all({
        areaTypes: AreaService.getTypes(),
        mapTypes: MapOptionsService.getMapTypes()
    });
    if (mapOptions.areaTypes && mapOptions.mapTypes) {
        mapOptionsFetched = true;
        if (!ConfigService.featureToggles.showNauticalCharts) {
            mapOptions.mapTypes = mapOptions.mapTypes.filter(mapType => mapType.Id !== 3);
        }
        yield put({ type: ActionTypes.MAP_OPTIONS_SET_OPTIONS, ...mapOptions });
    }
}

let prevAreaSettings = null;

function* getSelectedAreas(action) {
    let {
        MapSelectedAreasOfInterest,
        MapOptionsAreasOfInterestEnabled
    } = yield select(state => state.userReducer.settings);
    if (action
        && action.settings
        && (action.settings.MapOptionsAreasOfInterestEnabled !== MapOptionsAreasOfInterestEnabled
            || action.settings.MapSelectedAreasOfInterest !== MapSelectedAreasOfInterest)) {
        MapSelectedAreasOfInterest = action.settings.MapSelectedAreasOfInterest;
        MapOptionsAreasOfInterestEnabled = action.settings.MapOptionsAreasOfInterestEnabled;
    }
    if (!prevAreaSettings
        || prevAreaSettings.MapOptionsAreasOfInterestEnabled !== MapOptionsAreasOfInterestEnabled
        || prevAreaSettings.MapSelectedAreasOfInterest !== MapSelectedAreasOfInterest) {
        prevAreaSettings = { MapOptionsAreasOfInterestEnabled, MapSelectedAreasOfInterest };
        let selectedAreas = null;
        if (MapOptionsAreasOfInterestEnabled && MapSelectedAreasOfInterest.length > 0) {
            const areasResponse = yield AreaService.getAreasByTypeId(MapSelectedAreasOfInterest);
            if (areasResponse && areasResponse.length > 0) {
                selectedAreas = {};
                areasResponse.forEach((area) => {
                    if (!selectedAreas[area.AreaTypeId]) {
                        selectedAreas[area.AreaTypeId] = [];
                    }
                    selectedAreas[area.AreaTypeId].push(area);
                });
            }
        }
        yield put({ type: ActionTypes.MAP_OPTIONS_SET_SELECTED_AREAS, selectedAreas });
    }
}

function* handleUserSettingsUpdate(action) {
    const { settingUpdated } = action;
    if (settingUpdated === 'mapOptions') {
        yield getSelectedAreas(action);
    }
}

export default function* mapOptionsSaga() {
    yield takeEvery(ActionTypes.MAP_OPTIONS_GET_OPTIONS, getMapOptions);
    yield takeEvery(ActionTypes.MAP_OPTIONS_GET_SELECTED_AREAS, getSelectedAreas);
    yield takeEvery(UserActionTypes.USER_SETTINGS_SET, handleUserSettingsUpdate);
}
