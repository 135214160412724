import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import {
    renderDataProps,
    renderFuelData,
    formatValue,
    fleetPerformanceReportTableHeaderTypeB
} from '../fleet-performance-report-helpers';
/* components */
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
/* constants */
import {
    cargoProps,
    emissionProps,
    otherProps,
    colWidths,
    fuelColWidth
} from './fleet-performance-report-type-b-constants';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.FLEET_PERFORMANCE_REPORT.${key}`);

class FleetPerformanceReportTypeBTable extends React.PureComponent {
    state = {
        collapsedAccordions: {},
        expandedSummaries: {}
    };

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.fixedHeaderTable.scrollArea.scrollLeft();
            this.fixedHeaderTable.scrollArea.refresh();
        }
    }

    toggleAccordion = (key, id) => () => {
        const collapsedAccordions = {
            ...this.state[key],
            [id]: !this.state[key][id]
        };
        this.setState({ [key]: collapsedAccordions });
    };

    renderSummary = (
        key,
        label,
        summary,
        fuelTypes,
        fuelBeforeColSpan,
        fuelAfterColSpan,
        emissionColSpan,
        className = ''
    ) => {
        const summaryClassName = `sten-energy-management-reports-preview__fleet-performance-table-summary ${className}${
            this.state.expandedSummaries[key]
                ? ' sten-energy-management-reports-preview__fleet-performance-table-summary--expanded' : ''}`;
        return (
            <tbody key={key} className={summaryClassName}>
                <tr onClick={this.toggleAccordion('expandedSummaries', key)}>
                    <td>{label}</td>
                    {renderDataProps(summary.Total, cargoProps)}
                    {renderFuelData(summary.Total.ConsumptionsAtSeaBallast, fuelTypes.seaBallast, otherProps)}
                    <td>{formatValue(summary.Total.AvgConsumptionAtSeaBallast, otherProps.AvgConsumption.decimals)}</td>
                    <td>
                        {formatValue(summary.Total.TotalConsumptionAtSeaBallast, otherProps.TotalConsumption.decimals)}
                    </td>
                    {renderFuelData(summary.Total.ConsumptionsAtSeaLaden, fuelTypes.seaLaden, otherProps)}
                    <td>{formatValue(summary.Total.AvgConsumptionAtSeaLaden, otherProps.AvgConsumption.decimals)}</td>
                    <td>
                        {formatValue(summary.Total.TotalConsumptionAtSeaLaden, otherProps.TotalConsumption.decimals)}
                    </td>
                    {renderFuelData(summary.Total.ConsumptionsAtSea, fuelTypes.sea, otherProps)}
                    <td>{formatValue(summary.Total.AvgConsumptionAtSea, otherProps.AvgConsumption.decimals)}</td>
                    <td>{formatValue(summary.Total.TotalConsumptionAtSea, otherProps.TotalConsumption.decimals)}</td>
                    {renderFuelData(summary.Total.ConsumptionsInCanal, fuelTypes.canal, otherProps)}
                    <td>{formatValue(summary.Total.AvgConsumptionInCanal, otherProps.AvgConsumption.decimals)}</td>
                    <td>{formatValue(summary.Total.TotalConsumptionInCanal, otherProps.TotalConsumption.decimals)}</td>
                    {renderFuelData(summary.Total.ConsumptionsAnchoredDrifting, fuelTypes.anchoredDrifting, otherProps)}
                    <td>
                        {formatValue(summary.Total.AvgConsumptionAnchoredDrifting, otherProps.AvgConsumption.decimals)}
                    </td>
                    <td>
                        {formatValue(
                            summary.Total.TotalConsumptionAnchoredDrifting,
                            otherProps.TotalConsumption.decimals
                        )}
                    </td>
                    {renderFuelData(summary.Total.ConsumptionsCargoLoad, fuelTypes.cargoLoad, otherProps)}
                    <td>{formatValue(summary.Total.AvgConsumptionCargoLoad, otherProps.AvgConsumption.decimals)}</td>
                    <td>
                        {formatValue(summary.Total.TotalConsumptionCargoLoad, otherProps.TotalConsumption.decimals)}
                    </td>
                    {renderFuelData(summary.Total.ConsumptionsCargoDischarge, fuelTypes.cargoDischarge, otherProps)}
                    <td>
                        {formatValue(summary.Total.AvgConsumptionCargoDischarge, otherProps.AvgConsumption.decimals)}
                    </td>
                    <td>
                        {formatValue(
                            summary.Total.TotalConsumptionCargoDischarge,
                            otherProps.TotalConsumption.decimals
                        )}
                    </td>
                    {renderFuelData(summary.Total.ConsumptionsInPort, fuelTypes.port, otherProps)}
                    <td>{formatValue(summary.Total.AvgConsumptionInPort, otherProps.AvgConsumption.decimals)}</td>
                    <td>{formatValue(summary.Total.TotalConsumptionInPort, otherProps.TotalConsumption.decimals)}</td>
                    {renderDataProps(summary.Total.Emissions, emissionProps)}
                </tr>
                {this.state.expandedSummaries[key] && (
                    <React.Fragment>
                        <tr>
                            <td>{t('FUEL_PER_DISTANCE')}</td>
                            <td colSpan={fuelBeforeColSpan} />
                            <td>{formatValue(summary.FuelPerDistance, otherProps.FuelPerDistance.decimals)}</td>
                            <td colSpan={fuelAfterColSpan} />
                        </tr>
                        <tr>
                            <td>{t('FUEL_PER_CARGO')}</td>
                            <td colSpan={fuelBeforeColSpan} />
                            <td>{formatValue(summary.FuelPerCargo, otherProps.FuelPerCargo.decimals)}</td>
                            <td colSpan={fuelAfterColSpan} />
                        </tr>
                        <tr>
                            <td>{t('EMISSIONS_PER_CARGO')}</td>
                            <td colSpan={emissionColSpan} />
                            {renderDataProps(summary.EmissionsPerCargo, emissionProps, 1, true)}
                        </tr>
                    </React.Fragment>
                )}
            </tbody>
        );
    };

    renderVesselList = (
        key,
        label,
        vesselList,
        fuelTypes,
        headerColSpan,
        fuelBeforeColSpan,
        fuelAfterColSpan,
        emissionColSpan,
        className = ''
    ) => (
        <React.Fragment key={key}>
            <tbody className={`sten-energy-management-reports-preview__fleet-performance-table-vessels ${className}`}>
                <tr>
                    <td>{label}</td>
                    <td colSpan={headerColSpan} />
                </tr>
            </tbody>
            <tbody className={className}>
                {vesselList.Vessels.map(vessel => (
                    <tr key={`vessel.${vessel.Name}`}>
                        <td
                            className="sten-table__cell--clickable"
                            onClick={this.props.getPerformancePerVoyage.bind(this, vessel.Name, vessel.Imo)}
                        >
                            {vessel.Name}
                        </td>
                        {renderDataProps(vessel, cargoProps)}
                        {renderFuelData(vessel.ConsumptionsAtSeaBallast, fuelTypes.seaBallast, otherProps)}
                        <td>{formatValue(vessel.AvgConsumptionAtSeaBallast, otherProps.AvgConsumption.decimals)}</td>
                        <td>
                            {formatValue(vessel.TotalConsumptionAtSeaBallast, otherProps.TotalConsumption.decimals)}
                        </td>
                        {renderFuelData(vessel.ConsumptionsAtSeaLaden, fuelTypes.seaLaden, otherProps)}
                        <td>{formatValue(vessel.AvgConsumptionAtSeaLaden, otherProps.AvgConsumption.decimals)}</td>
                        <td>
                            {formatValue(vessel.TotalConsumptionAtSeaLaden, otherProps.TotalConsumption.decimals)}
                        </td>
                        {renderFuelData(vessel.ConsumptionsAtSea, fuelTypes.sea, otherProps)}
                        <td>{formatValue(vessel.AvgConsumptionAtSea, otherProps.AvgConsumption.decimals)}</td>
                        <td>{formatValue(vessel.TotalConsumptionAtSea, otherProps.TotalConsumption.decimals)}</td>
                        {renderFuelData(vessel.ConsumptionsInCanal, fuelTypes.canal, otherProps)}
                        <td>{formatValue(vessel.AvgConsumptionInCanal, otherProps.AvgConsumption.decimals)}</td>
                        <td>
                            {formatValue(vessel.TotalConsumptionInCanal, otherProps.TotalConsumption.decimals)}
                        </td>
                        {renderFuelData(vessel.ConsumptionsAnchoredDrifting, fuelTypes.anchoredDrifting, otherProps)}
                        <td>
                            {formatValue(vessel.AvgConsumptionAnchoredDrifting, otherProps.AvgConsumption.decimals)}
                        </td>
                        <td>
                            {formatValue(vessel.TotalConsumptionAnchoredDrifting, otherProps.TotalConsumption.decimals)}
                        </td>
                        {renderFuelData(vessel.ConsumptionsCargoLoad, fuelTypes.cargoLoad, otherProps)}
                        <td>{formatValue(vessel.AvgConsumptionCargoLoad, otherProps.AvgConsumption.decimals)}</td>
                        <td>{formatValue(vessel.TotalConsumptionCargoLoad, otherProps.TotalConsumption.decimals)}</td>
                        {renderFuelData(vessel.ConsumptionsCargoDischarge, fuelTypes.cargoDischarge, otherProps)}
                        <td>{formatValue(vessel.AvgConsumptionCargoDischarge, otherProps.AvgConsumption.decimals)}</td>
                        <td>
                            {formatValue(vessel.TotalConsumptionCargoDischarge, otherProps.TotalConsumption.decimals)}
                        </td>
                        {renderFuelData(vessel.ConsumptionsInPort, fuelTypes.port, otherProps)}
                        <td>{formatValue(vessel.AvgConsumptionInPort, otherProps.AvgConsumption.decimals)}</td>
                        <td>
                            {formatValue(vessel.TotalConsumptionInPort, otherProps.TotalConsumption.decimals)}
                        </td>
                        {renderDataProps(vessel.Emissions, emissionProps)}
                    </tr>
                ))}
            </tbody>
            {this.renderSummary(
                key,
                `${t('TOTAL_FOR')} ${label}`,
                vesselList.Summary,
                fuelTypes,
                fuelBeforeColSpan,
                fuelAfterColSpan,
                emissionColSpan,
                className
            )}
        </React.Fragment>
    );

    renderFleet = (
        segment,
        fleet,
        fuelTypes,
        headerColSpan,
        fuelBeforeColSpan,
        fuelAfterColSpan,
        emissionColSpan
    ) => (
        <React.Fragment key={`segment.${segment.Name}.fleet.${fleet.Name}`}>
            <tbody className="sten-energy-management-reports-preview__fleet-performance-table-fleet">
                <tr>
                    <td>{fleet.Name}</td>
                    <td colSpan={headerColSpan} />
                </tr>
            </tbody>
            {this.renderSummary(
                `segment.${segment.Name}.fleet.${fleet.Name}.total`,
                t('TOTAL_FOR_COMPANY_FLEET'),
                fleet.Summary,
                fuelTypes,
                fuelBeforeColSpan,
                fuelAfterColSpan,
                emissionColSpan,
                'sten-energy-management-reports-preview__fleet-performance-table-summary--fleet'
            )}
            {fleet.Contracts.map(contract => (
                this.renderVesselList(
                    `segment.${segment.Name}.fleet.${fleet.Name}.contract.${contract.Name}.total`,
                    contract.Name,
                    contract,
                    fuelTypes,
                    headerColSpan,
                    fuelBeforeColSpan,
                    fuelAfterColSpan,
                    emissionColSpan,
                    'sten-energy-management-reports-preview__fleet-performance-table-vessels--fleet'
                )
            ))}
        </React.Fragment>
    );

    renderTableAccordionHeader = (key, label, colSpan) => {
        const className = `sten-table__accordion${
            this.state.collapsedAccordions[key] ? ' sten-table__accordion--collapsed' : ''}`;

        return (
            <tbody key={key} onClick={this.toggleAccordion('collapsedAccordions', key)} className={className}>
                <tr>
                    <td>{label}</td>
                    <td colSpan={colSpan} />
                </tr>
            </tbody>
        );
    };

    renderSegment = (
        segment,
        fuelTypes,
        headerColSpan,
        fuelBeforeColSpan,
        fuelAfterColSpan,
        emissionColSpan
    ) => (
        <React.Fragment key={`segment.${segment.Name}`}>
            {this.renderTableAccordionHeader(`segment.${segment.Name}`, segment.Name, headerColSpan)}
            {!this.state.collapsedAccordions[`segment.${segment.Name}`] && (
                <React.Fragment>
                    {this.renderSummary(
                        `segment.${segment.Name}.total`,
                        t('TOTAL_FOR_SEGMENT'),
                        segment.Summary,
                        fuelTypes,
                        fuelBeforeColSpan,
                        fuelAfterColSpan,
                        emissionColSpan,
                        'sten-energy-management-reports-preview__fleet-performance-table-summary--segment'
                    )}
                    {segment.Fleets.map(fleet => this.renderFleet(
                        segment,
                        fleet,
                        fuelTypes,
                        headerColSpan,
                        fuelBeforeColSpan,
                        fuelAfterColSpan,
                        emissionColSpan
                    ))}
                    {segment.OtherVessels && this.renderVesselList(
                        `segment.${segment.Name}.OtherVessels`,
                        t('OTHER_VESSELS'),
                        segment.OtherVessels,
                        fuelTypes,
                        headerColSpan,
                        fuelBeforeColSpan,
                        fuelAfterColSpan,
                        emissionColSpan,
                        'sten-energy-management-reports-preview__fleet-performance-table-vessels--other'
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );

    saveRef = c => { this.fixedHeaderTable = c; };

    render() {
        const { parsedData, fuelTypes } = this.props.data;
        const cargoCols = cargoProps.length;

        const seaBallastCols = fuelTypes.seaBallast.length;
        const seaLadenCols = fuelTypes.seaLaden.length;
        const seaTotalCols = fuelTypes.sea.length;
        const seaConsCols = seaBallastCols + seaLadenCols + seaTotalCols;

        const canalConsCols = fuelTypes.canal.length;

        const portAnchoredCols = fuelTypes.anchoredDrifting.length;
        const portCargoLoadCols = fuelTypes.cargoLoad.length;
        const portCargoDischargeCols = fuelTypes.cargoDischarge.length;
        const portTotalCols = fuelTypes.port.length;
        const portConsCols = portAnchoredCols + portCargoLoadCols + portCargoDischargeCols + portTotalCols;

        const emissionCols = emissionProps.length;

        const fuelBeforeColSpan = cargoCols + seaConsCols + 5;
        const fuelAfterColSpan = canalConsCols + portConsCols + emissionCols + 10;
        const emissionColSpan = cargoCols + seaConsCols + canalConsCols + portConsCols + 16;
        const headerColSpan = cargoCols + seaConsCols + canalConsCols + portConsCols + 16 + emissionCols;
        const fuelColsWidth = ((seaConsCols * fuelColWidth.web) || 4)
            + ((canalConsCols * fuelColWidth.web) || 4)
            + ((portConsCols * fuelColWidth.web) || 4);
        const contentMinWidth = parseInt(
            Object.keys(colWidths).reduce((sum, key) => sum + colWidths[key].web, 0) + fuelColsWidth, 10
        );

        return (
            <FixedHeaderTable
                className="sten-energy-management-reports-preview__fleet-performance-table"
                contentStyle={{ minWidth: `${contentMinWidth}rem` }}
                updateTimeout={200}
                withHeaderColumn
                useCSS3Translate={false}
                ref={this.saveRef}
            >
                <table className="sten-table sten-table--xs">
                    {fleetPerformanceReportTableHeaderTypeB(
                        fuelTypes, fuelColWidth, emissionProps, colWidths, t('VALUE')
                    )}
                    {this.renderSummary(
                        'grandTotal',
                        t('GRAND_TOTAL'),
                        parsedData.Summary,
                        fuelTypes,
                        fuelBeforeColSpan,
                        fuelAfterColSpan,
                        emissionColSpan
                    )}
                    {parsedData.Segments.map(segment => this.renderSegment(
                        segment,
                        fuelTypes,
                        headerColSpan,
                        fuelBeforeColSpan,
                        fuelAfterColSpan,
                        emissionColSpan
                    ))}
                </table>
            </FixedHeaderTable>
        );
    }
}

FleetPerformanceReportTypeBTable.propTypes = {
    data: PropTypes.shape({
        parsedData: PropTypes.objectOf(PropTypes.any),
        fuelTypes: PropTypes.objectOf(PropTypes.array)
    }),
    getPerformancePerVoyage: PropTypes.func.isRequired
};

FleetPerformanceReportTypeBTable.defaultProps = {
    data: null
};

export default FleetPerformanceReportTypeBTable;
