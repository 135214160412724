import { createCategoryChartDataSelector } from 'utils/helpers/selector-helper';

const seriesKeys = ['Total', 'Administrator', 'Customer', 'Godmother', 'PoolPartner', 'PrivilegedCustomer',
    'RestrictedUser', 'SuperAdministrator', 'TechnicalManager', 'User', 'VesselOwner'];

export const getPlatformStatisticsChartData = createCategoryChartDataSelector(
    state => (state.adminCompanyManagementReducer.platformStatistics
        ? state.adminCompanyManagementReducer.platformStatistics.Graph
        : null),
    seriesKeys
);
