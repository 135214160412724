import { createSelector } from 'reselect';
/* utils */
import {
    convertConfigSelector,
    areConditionsMet,
    extractConsumptionTypesPerGroup,
    extractBunkerTypes
} from './vessel-report-helpers';
import { mapArrayByProp } from 'utils/helpers/array-helper';
/* services */
import ConfigService from 'services/config-api/config-service';
/* constants */
import { reportFieldsAndSectionsByType } from './vessel-report-sections-and-fields';

const emptyArray = [];

export const getReportFuelTypes = createSelector(
    state => state.vesselReportReducer.fuelTypes,
    (state, report) => report,
    (fuelTypes, report) => {
        let list = emptyArray;
        const map = {};
        if (!fuelTypes) {
            return { list, map };
        }
        if (report && report.DataSourceId) {
            list = fuelTypes[report.DataSourceId] || emptyArray;
        } else {
            list = fuelTypes[7].filter(fuelType => fuelType.Visible);
        }
        list.forEach(item => {
            map[item.Id] = item;
        });
        return { list, map };
    }
);

export const getConsumptionTypesBySource = createSelector(
    state => state.vesselReportReducer.consumptionTypes,
    state => state.vesselReportReducer.report,
    (consumptionTypes, report) => {
        let consumptionTypesBySource = null;
        if (consumptionTypes && report && report.DataSourceId) {
            consumptionTypes.forEach(ds => {
                if (ds.DataSourceId === report.DataSourceId) {
                    consumptionTypesBySource = mapArrayByProp(
                        ds.ConsumptionGroupTypes,
                        'ConsumptionGroupTypeId',
                        item => item.ConsumptionTypes
                    );
                }
            });
        }
        return consumptionTypesBySource;
    }
);

export const getBunkerTypesBySource = createSelector(
    state => state.vesselReportReducer.bunkerTypes,
    state => state.vesselReportReducer.report,
    (bunkerTypes, report) => {
        let bunkerTypesBySource = [];
        if (report && report.DataSourceId) {
            const bunkerTypeSource = bunkerTypes.find(type => type.DataSourceId === report.DataSourceId);
            if (bunkerTypeSource) {
                bunkerTypesBySource = bunkerTypeSource.BunkerDataTypes;
            }
        }
        return bunkerTypesBySource;
    }
);

export const getHiddenFieldsByTypeDSST = createSelector(
    state => state.vesselReportReducer.visibleTypesAndFields,
    (types) => {
        const hiddenFieldsByType = {};
        types.forEach((type) => {
            hiddenFieldsByType[type.Id] = {};
            if (type.HiddenFields) {
                Object.keys(type.HiddenFields).forEach(field => {
                    hiddenFieldsByType[type.Id][field] = convertConfigSelector(type.HiddenFields[field], field);
                });
            }
        });
        return hiddenFieldsByType;
    }
);

const isFieldVisible = (field, hiddenFields, report) => {
    const fieldRoot = field.split('.')[0];
    return !areConditionsMet(hiddenFields[field], report)
        && !areConditionsMet(hiddenFields[fieldRoot], report);
};

const filterSectionsByHiddenFields = (sections, hiddenFields, report) => {
    const filteredSections = [];
    sections.forEach(section => {
        if (Array.isArray(section)) {
            filteredSections.push(section.filter(field => isFieldVisible(field, hiddenFields, report)));
        }
        const parsedSection = { ...section };
        if (parsedSection.fields) {
            parsedSection.fields = parsedSection.fields.filter(field => isFieldVisible(field, hiddenFields, report));
            if (parsedSection.fields.length === 0) {
                delete parsedSection.fields;
            }
        }
        if (parsedSection.data) {
            if (!isFieldVisible(parsedSection.data, hiddenFields, report)) {
                delete parsedSection.data;
            } else if (parsedSection.dataFields) {
                parsedSection.dataFields = parsedSection.dataFields.filter(field =>
                    isFieldVisible(`${parsedSection.data}.${field}`, hiddenFields, report));
                if (parsedSection.dataFields.length === 0) {
                    delete parsedSection.dataFields;
                    delete parsedSection.data;
                }
            }
        }
        if (parsedSection.sections) {
            parsedSection.sections = filterSectionsByHiddenFields(parsedSection.sections, hiddenFields, report);
            if (parsedSection.sections.length === 0) {
                delete parsedSection.sections;
            }
        }
        if (parsedSection.fields || parsedSection.data || parsedSection.sections) {
            filteredSections.push(parsedSection);
        }
    });
    return filteredSections;
};

export const getVisibleFieldsAndSections = createSelector(
    getHiddenFieldsByTypeDSST,
    state => state.vesselReportReducer.report,
    (hiddenFieldsByType, report) => {
        if (!report) {
            return emptyArray;
        }
        const reportType = report.ReportType || report.ReportTypeId;
        const hiddenFields = hiddenFieldsByType[reportType] || null;
        if (!hiddenFields) {
            return reportFieldsAndSectionsByType[reportType];
        }
        return filterSectionsByHiddenFields(reportFieldsAndSectionsByType[reportType], hiddenFields, report);
    }
);

export const getEditableReports = createSelector(
    state => state.vesselReportReducer.editableReports,
    convertConfigSelector
);

export const getReportEditable = createSelector(
    getEditableReports,
    state => state.vesselReportReducer.report,
    areConditionsMet
);

export const getConvertedEditingOptions = createSelector(
    state => state.vesselReportReducer.report,
    state => state.vesselReportReducer.editingOptions,
    getConsumptionTypesBySource,
    getBunkerTypesBySource,
    (report, editingOptions, consumptionTypes, bunkerTypes) => {
        return {
            ...editingOptions,
            consumptionTypes: ConfigService.featureToggles.useReportConfigService
                ? extractConsumptionTypesPerGroup(report, editingOptions)
                : consumptionTypes,
            bunkerTypes: ConfigService.featureToggles.useReportConfigService
                ? extractBunkerTypes(report, editingOptions)
                : bunkerTypes
        };
    }
);
