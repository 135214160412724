import CoreApi from './core-api';

class VoyageService {
    constructor() {
        this.resourceUrl = 'Voyages';
    }

    getAll(params) {
        return CoreApi.get(this.resourceUrl, params)
            .then(response => response.data, () => null);
    }

    getById(id, params = {}) {
        return CoreApi.get(`${this.resourceUrl}/${id}`, params)
            .then(response => response.data, () => null);
    }

    getReportsByVoyageId(voyageId) {
        return CoreApi.get(`${this.resourceUrl}/${voyageId}/Reports`)
            .then(response => response.data, () => null);
    }

    getVoyageItinerary(params) {
        return CoreApi.get('VoyageItineraries', params)
            .then(response => response.data, () => null);
    }

    getVoyageDeviationCalculation(data) {
        return CoreApi.post('VoyageItineraries/Calculate', data)
            .then(response => response.data, () => null);
    }

    getVoyageEstimatorCalculation(data) {
        return CoreApi.post('Estimator/Calculate', data)
            .then(response => response.data, () => null);
    }

    getVoyagePerformanceById(voyageId, params) {
        return CoreApi.get(`${this.resourceUrl}/${voyageId}/Performance`, params)
            .then(response => response.data, () => null);
    }

    getVoyageGraphsById(voyageId) {
        return CoreApi.get(`${this.resourceUrl}/${voyageId}/Graphs`)
            .then(response => response.data, () => null);
    }

    getVoyageConsumptionById(voyageId, params) {
        return CoreApi.get(`${this.resourceUrl}/${voyageId}/Consumption`, params)
            .then(response => response.data, () => null);
    }

    getVoyageCharterer(params) {
        return CoreApi.get(`${this.resourceUrl}/Charterer`, params)
            .then(response => response.data, () => null);
    }

    sendDeviationRequest(data) {
        return CoreApi.post('VoyageItineraries/SendCalculation', data)
            .then(response => response.data, () => null);
    }

    getEarnings(voyageId) {
        return CoreApi.get(`${this.resourceUrl}/${voyageId}/Earnings`)
            .then(response => response.data, () => null);
    }

    getFootprint(voyageId) {
        return CoreApi.get(`${this.resourceUrl}/${voyageId}/Footprint`)
            .then(response => response.data, () => null);
    }

    getVmsFootprint(voyageId) {
        return CoreApi.get(`${this.resourceUrl}/${voyageId}/VmsFootprint`)
            .then(response => response.data, () => null);
    }

    sendBudgetConsumptionEmail(voyageId) {
        return CoreApi.get(`${this.resourceUrl}/${voyageId}/SendMailToCaptain`)
            .then(response => response.data, () => null);
    }
}

export default new VoyageService();
