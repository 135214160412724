import { ActionTypes } from './map-location-tooltip-actions';

const emptyArray = [];

const initialState = {
    coordinates: emptyArray,
    pixels: emptyArray
};

export default function mapLocationTooltipReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.MAP_LOCATION_TOOLTIP_SET_COORDINATES: {
        return {
            ...state,
            coordinates: action.coordinates,
            pixels: action.pixels
        };
    }
    case ActionTypes.MAP_LOCATION_TOOLTIP_RESET: {
        return {
            ...state,
            ...initialState
        };
    }
    default:
        return state;
    }
}
