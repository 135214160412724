import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* Utils */
import { translate } from 'utils/i18n/i18n-model';
/* Components */
import TabSelector from 'components/tab-selector/tab-selector';
import GsmMainPanelVesselList from './gsm-main-panel-vessel-list/gsm-main-panel-vessel-list';
import GsmMainPanelOffices from './gsm-main-panel-offices/gsm-main-panel-offices';
/* Actions */
import {
    gsmMapRightSideBarCollapse,
    removeAllEntities
} from '../gsm-right-side-bar-actions';
/* Models */
import GsmRightSideBarModels from '../gsm-right-side-bar-models';
/* Styles */
import './gsm-main-panel.scss';

export class GsmMainPanel extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            activeTabId: 0
        };
    }

    onCloseClick = () => {
        this.props.closeRightSidebar();
        this.props.removeAllEntities();
    }

    setActiveTab = (tabId) => {
        this.setState({
            activeTabId: tabId
        });
    }

    getTabs() {
        const tabs = [{
            id: 0,
            title: translate('GSM.MAIN_PANEL.TAB_TITLE_TANKERS'),
            icon: 'icon-laden',
            content: (<GsmMainPanelVesselList data={this.props.tankers} icon="icon-laden" />)
        }, {
            id: 1,
            title: translate('GSM.MAIN_PANEL.TAB_TITLE_FERRIES'),
            icon: 'icon-laden',
            content: (<GsmMainPanelVesselList data={this.props.ferries} icon="icon-laden" />)
        }, {
            id: 2,
            title: translate('GSM.MAIN_PANEL.TAB_TITLE_RIGS_DRILLS'),
            icon: 'icon-oil-rig',
            content: (<GsmMainPanelVesselList data={this.props.drills} icon="icon-oil-rig" />)
        }, {
            id: 3,
            title: translate('GSM.MAIN_PANEL.TAB_TITLE_FERRY_ROUTES'),
            icon: 'icon-route',
            content: (<GsmMainPanelVesselList data={this.props.ferryRoutes} icon="icon-route" />)
        }, {
            id: 4,
            title: translate('GSM.MAIN_PANEL.TAB_TITLE_OFFICES'),
            icon: 'icon-circle',
            content: (<GsmMainPanelOffices data={this.props.offices} />)
        }];
        return tabs;
    }

    render() {
        const tabs = this.getTabs();
        if (!tabs) {
            return (
                <div className="sten-content">
                    <div className="sten-content__header" />
                    <div className="sten-content__body" />
                </div>
            );
        }
        const activeTab = tabs.find(tab => tab.id === this.state.activeTabId);

        return (
            <div className="sten-content">
                <div className="sten-content__header flex-row">
                    <h1 className="sten-content__title flex-grow text-uppercase">
                        {translate('GSM.MAIN_PANEL.HEADER')}
                    </h1>
                    <div className="flex-shrink">
                        <button
                            className="btn-link icon icon-close"
                            onClick={this.onCloseClick}
                        />
                    </div>
                </div>
                <div className="sten-content__body gsm-main-panel__body-fixed">
                    <TabSelector
                        tabItems={tabs}
                        activeTabId={this.state.activeTabId}
                        onChange={this.setActiveTab}
                    />
                </div>
                <div className="sten-content__body gsm-main-panel__body-content">
                    {activeTab.content}
                </div>
            </div>
        );
    }
}

GsmMainPanel.propTypes = {
    closeRightSidebar: PropTypes.func.isRequired,
    drills: PropTypes.arrayOf(GsmRightSideBarModels.ListElement).isRequired,
    ferries: PropTypes.arrayOf(GsmRightSideBarModels.ListElement).isRequired,
    ferryRoutes: PropTypes.arrayOf(GsmRightSideBarModels.ListElement).isRequired,
    offices: PropTypes.arrayOf(PropTypes.any).isRequired,
    removeAllEntities: PropTypes.func.isRequired,
    tankers: PropTypes.arrayOf(GsmRightSideBarModels.ListElement).isRequired
};

function mapStateToProps(state) {
    return {
        drills: state.gsmRightSideBarReducer.drills,
        ferries: state.gsmRightSideBarReducer.ferries,
        ferryRoutes: state.gsmRightSideBarReducer.ferryRoutes,
        offices: state.gsmRightSideBarReducer.offices,
        tankers: state.gsmRightSideBarReducer.tankers
    };
}

function mapDispatchToProps(dispatch) {
    return {
        closeRightSidebar: () => gsmMapRightSideBarCollapse(dispatch),
        removeAllEntities: () => removeAllEntities(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GsmMainPanel);
