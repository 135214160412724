import React from 'react';
import icons from 'assets/fonts/sten-icons/selection';
import './test-icon-list.scss';

const stenIconClasses = [
    'sten-icon-ballast',
    'sten-icon-ballast-competition',
    'sten-icon-ballast-danger',
    'sten-icon-ballast-route',
    'sten-icon-future-route',
    'sten-icon-group',
    'sten-icon-group-competition',
    'sten-icon-group-danger',
    'sten-icon-laden',
    'sten-icon-laden-competition',
    'sten-icon-laden-danger',
    'sten-icon-laden-route',
    'sten-icon-no-position-route',
    'sten-icon-trend-flat',
    'sten-icon-trend-moderate-down',
    'sten-icon-trend-moderate-up',
    'sten-icon-trend-strong-down',
    'sten-icon-trend-strong-up',
    'sten-icon-unequal'
];

class TestIconList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.iconClasses = icons.icons.map(icon => `icon-${icon.properties.name}`);
    }

    render() {
        return (
            <div className="sten-icon-list">
                <div className="sten-icon-list__title">Base icons</div>
                {this.iconClasses.map(iconClass => (
                    <div className="sten-icon-list__icon col-lg-4 col-md-6 col-sm-8 col-xs-24" key={iconClass}>
                        <div className="flex flex-center">
                            <div className="sten-icon-list__icon-container">
                                <span className={`icon ${iconClass}`} />
                            </div>
                            {iconClass}
                        </div>
                    </div>
                ))}
                <div className="sten-icon-list__title">Extended icons</div>
                {stenIconClasses.map(stenIconClass => (
                    <div className="sten-icon-list__icon col-lg-4 col-md-6 col-sm-8 col-xs-24" key={stenIconClass}>
                        <div className="flex flex-center">
                            <div className="sten-icon-list__icon-container">
                                <span className={`icon ${stenIconClass}`} />
                            </div>
                            {stenIconClass}
                        </div>
                    </div>
                ))}
            </div>
        );
    }
}

export default TestIconList;
