import Excel from 'exceljs';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* styles */
import {
    tableHeaderStyle as basicTableHeaderStyle,
    tableCellStyle,
    headingTitleStyle
} from 'utils/helpers/xlsx-styles';
/* helpers */
import { addWorksheet, triggerXLSXDownload, formatXLSXCellValue } from 'utils/helpers/xlsx-helper';
import { generateXLSXHeaderLogos } from '../../../reports-helpers';
import { getObjectProp } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* constants */
import { colWidths } from '../fleet-list-report-constants';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.FLEET_LIST_REPORT.${key}`);

const customColors = {
    periodHeader: 'FFD5ECFD'
};

const tableHeaderStyle = {
    ...basicTableHeaderStyle,
    fill: {
        ...basicTableHeaderStyle.fill,
        fgColor: { argb: customColors.periodHeader }
    }
};

const centeredTableHeaderStyle = {
    ...tableHeaderStyle,
    alignment: {
        horizontal: 'center',
        wrapText: true,
        vertical: 'middle'
    }
};

const rightAlignedTableCellStyle = {
    ...tableCellStyle,
    alignment: {
        horizontal: 'right',
        wrapText: true,
        vertical: 'middle'
    }
};

const summaryCellStyle = {
    ...tableCellStyle,
    alignment: {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center'
    },
    font: {
        ...tableCellStyle.font,
        bold: true
    }
};

const formatTableCell = (val, props = {}, style = null, colSpan = 1) => {
    const { value, numFmt } = formatXLSXCellValue(val, props);
    return {
        value: props.dateOptions ? TimeHelper.getFormatted(val, props.key) : value,
        props: {
            ...rightAlignedTableCellStyle,
            ...style,
            numFmt
        },
        colSpan
    };
};

const renderDataRow = (data, label, columns) => {
    return ({
        values: [
            { value: label, props: centeredTableHeaderStyle },
            ...columns.map(col => {
                const val = getObjectProp(data, col.key);
                return formatTableCell(val, col, summaryCellStyle);
            })
        ]
    });
};

const getReportTableHeader = () => ([
    {
        values: [
            { value: t('VESSEL'), rowSpan: 2, props: centeredTableHeaderStyle },
            { value: t('IMO_NUMBER'), rowSpan: 2, props: centeredTableHeaderStyle },
            { value: t('DWT'), rowSpan: 2, props: centeredTableHeaderStyle },
            { value: t('YEAR_BUILT'), rowSpan: 2, props: centeredTableHeaderStyle },
            { value: t('LOA'), rowSpan: 2, props: centeredTableHeaderStyle },
            { value: t('BEAM'), rowSpan: 2, props: centeredTableHeaderStyle },
            { value: t('DRAFT'), rowSpan: 2, props: centeredTableHeaderStyle },
            { value: t('NUMBER_OF_TANKS'), rowSpan: 2, props: centeredTableHeaderStyle },
            { value: t('SCRUBBER'), rowSpan: 2, props: centeredTableHeaderStyle },
            { value: t('COATING'), rowSpan: 2, props: centeredTableHeaderStyle },
            { value: t('ICE_CLASS'), rowSpan: 2, props: centeredTableHeaderStyle },
            { value: t('FLAG'), rowSpan: 2, props: centeredTableHeaderStyle }
        ]
    }
]);

export default function* generateFleetListXLSX(data) {
    const { tableData, fileName } = data;
    const { parsedData, columns: tableDataColumns } = tableData;

    const workbook = new Excel.Workbook();

    const columns = [
        { width: colWidths.VesselName.xls },
        { width: colWidths.Imo.xls },
        { width: colWidths.DWT.xls },
        { width: colWidths.YearBuilt.xls },
        { width: colWidths.LOA.xls },
        { width: colWidths.Beam.xls },
        { width: colWidths.Draft.xls },
        { width: colWidths.NumberOfTanks.xls },
        { width: colWidths.HasScrubber.xls },
        { width: colWidths.Coating.xls },
        { width: colWidths.IceClass.xls },
        { width: colWidths.Flag.xls }
    ];

    const sheetConfig = {
        name: 'Fleet List Report',
        props: {
            properties: { showGridLines: false },
            views: [{ showGridLines: false }],
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                margins: { left: 0.4, right: 0.4, top: 0.2, bottom: 0.2, header: 0, footer: 0 }
            }
        },
        columns,
        rows: [
            ...generateXLSXHeaderLogos(null, null, 0.0001),
            {
                values: [{
                    value: t('FLEET_LIST_REPORT'),
                    props: headingTitleStyle
                }]
            },
            null,
            ...getReportTableHeader(),
            null,
            ...parsedData.map(vessel => renderDataRow(
                vessel, vessel.VesselName, tableDataColumns
            ))
        ]
    };

    addWorksheet(workbook, sheetConfig);
    const xls64 = yield workbook.xlsx.writeBuffer({ base64: true });
    return triggerXLSXDownload(fileName)(xls64);
}
