/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import validator from 'validator';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import { getDiary } from 'pages/user-pages/operators-diary/operators-diary-actions';
import { setFuelQuantity } from './test-actions';
/* selectors */
import { getSelectedTest } from './test-selectors';
import { getConvertedDiary } from 'pages/user-pages/operators-diary/operators-diary-selectors';
import { convertFixture } from 'pages/user-pages/operators-diary/operators-diary-helpers';
/* components */
import BadgeToggle from 'components/badge-toggle/badge-toggle';
import Checkbox from 'components/checkbox/checkbox';
import CoordinatesInput from 'components/coordinates-input/coordinates-input';
import DatePicker from 'components/htec-date-picker/date-picker/date-picker';
import DateRangePopup from 'components/date-range-popup/date-range-popup';
import DateTimePopup from 'components/date-time-popup/date-time-popup';
import FlipSwitch from 'components/flip-switch/flip-switch';
import Gauge from 'components/gauge/gauge';
import Input from 'components/input/input';
import Itinerary from 'components/itinerary/itinerary';
import Menu, { MenuItem, SubMenu } from 'components/menu/menu';
import MonthPickerPopup from 'components/month-popup/month-popup';
import MultiStringSelect from 'components/multi-string-select/multi-string-select';
import NumberInput from 'components/number-input/number-input';
import OperatorsDiaryFixture from 'pages/user-pages/operators-diary/operators-diary-fixture/operators-diary-fixture';
import PortSelect from 'components/port-select/port-select';
import ProgressCircle from 'components/progress-circle/progress-circle';
import QuarterPicker from 'pages/user-pages/energy-management/reports/shared/quarter-picker/quarter-picker';
import RadioButton from 'components/radio-button/radio-button';
import ReorderableItemsList from 'components/reorderable-items-list/reorderable-items-list';
import ScrollArea from 'components/scroll-area/scroll-area';
import Select from 'components/select/select';
import TagSelect from 'components/tag-select/tag-select';
import TestIconList from './test-icon-list';
import Textarea from 'components/textarea/textarea';
import TimePicker from 'components/htec-date-picker/time-picker/time-picker';
import TrendIcon from 'components/trend-icon/trend-icon';
/* mocks */
import fakeCompanies from './fake-companies.json';
import { generateFixtures, legTags } from 'pages/user-pages/operators-diary/operators-diary-utils';
/* styles */
import './test.scss';

const fakeStatuses = [
    {
        title: 'Active',
        isActive: true
    },
    {
        title: 'Disabled',
        isActive: false
    }
];

class TestPage extends React.PureComponent {
    constructor(props) {
        const currentDate = new Date();
        super(props);
        this.state = {
            value: 5,
            isSwitchActive: true,
            selectedTags: [],
            selectedQuarter: {
                from: '2021-07-01T00:00:00.000Z',
                to: '2021-09-30T23:59:59.999Z'
            },
            selectedPorts: [],
            selectedPort: null,
            selectedCompanies: [],
            selectedSpeed: '12.5',
            isChecked: false,
            selectedRadioOption: 0,
            selectedStatus: null,
            asyncCompanies: [],
            asyncSelectedCompany: null,
            selectedPlacement: { value: 'bottom' },
            selectedAlignment: { value: 'center' },
            selectedDate: {
                day: 5,
                month: 11,
                year: currentDate.getFullYear()
            },
            selectedTime: {
                hour: 14,
                minute: 15,
                second: 29
            },
            selectedRange: null,
            selectedMonth: null,
            selectedDateTime: null,
            // selectedDateTime: {
            //     day: 5,
            //     month: 11,
            //     year: currentDate.getFullYear(),
            //     hour: 14,
            //     minute: 15,
            //     second: 29
            // },
            customCoordinates: [0, 1],
            areMissingReportsShown: false,
            multiStringValue: ['gpantic@yahoo.com', 'asd@asd.com'],
            dragItems: (new Array(45)).fill(0, 0, 45).map((v, i) => i + 1),
            textInput: ''
        };
    }

    componentDidMount() {
        this.props.getDiary();
    }

    handleRangeChange = (selectedRange) => {
        this.setState({ selectedRange });
    };

    handleSelectedQuarter = val => {
        this.setState({ selectedQuarter: val });
    }

    handleDateTimeChange = (selectedDateTime) => {
        this.setState({
            selectedDateTime
        });
    };

    setValue = value => {
        this.setState({ value });
    };

    setSelectedPorts = selectedPorts => {
        this.setState({ selectedPorts });
    };

    setSelectedPort = selectedPort => {
        this.setState({ selectedPort });
    };

    toggleSwitch = () => {
        this.setState({
            isSwitchActive: !this.state.isSwitchActive
        });
    };

    openModal() {
        // It will return arg that is added in function
    }

    handleCompanyChange = (companies) => {
        this.setState({
            selectedCompanies: companies
        });
    };

    handleAsyncCompanyChange = (company) => {
        this.setState({
            asyncSelectedCompany: company
        });
    };

    handleSpeedChange = (speed) => {
        this.setState({
            selectedSpeed: speed
        });
    };

    handleCheckboxClick = () => {
        this.setState({
            isChecked: !this.state.isChecked
        });
    };

    handleRadioButtonClick = (option) => {
        this.setState({
            selectedRadioOption: option
        });
    };

    handleStatusChange = (status) => {
        this.setState({
            selectedStatus: status
        });
    };

    getCompaniesAsync = () => {
        setTimeout(() => this.setState({
            asyncCompanies: fakeCompanies
        }));
    };

    placements = [
        { value: 'top' },
        { value: 'bottom' },
        { value: 'left' },
        { value: 'right' }
    ];

    alignments = [
        { value: 'start' },
        { value: 'center' },
        { value: 'end' }
    ];

    setDatePickerDate = params => {
        this.setState({
            selectedDate: params
        });
    };

    onTimeChange = args => {
        this.setState({
            selectedTime: args
        });
    };

    handleCoordinatesChange = value => {
        this.setState({
            customCoordinates: value ? value.Coordinates : null
        });
    };

    toggleMissingBadge = () => {
        this.setState({
            areMissingReportsShown: !this.state.areMissingReportsShown
        });
    };

    handleMultiStringChange = (value) => {
        this.setState({
            multiStringValue: value
        });
    };

    validateEmailInput = (value) => validator.isEmail(value);

    splitPortsIntoCountries = (ports) => {
        const countryMap = {};
        let key;
        ports.forEach(port => {
            key = port.Country || 'Other';
            if (!countryMap[key]) {
                countryMap[key] = { options: [], name: 'Other' };
                if (port.Country) {
                    countryMap[key].name = `${port.Country} (${port.CountryCode})`;
                }
            }
            countryMap[key].options.push(port);
        });
        let options = [];
        const sections = Object.keys(countryMap).sort().map(key => {
            options = [...options, ...countryMap[key].options];
            return countryMap[key];
        });
        return { sections, options };
    };

    dragItemStyle = { padding: '5px', backgroundColor: 'teal' };

    renderItem = (onDragHandleMouseDown, item) => {
        return (
            <div
                key={`drag-item-#${item}`}
                style={this.dragItemStyle}
            >
                <span
                    className="icon icon-menu cursor-pointer"
                    onMouseDown={onDragHandleMouseDown}
                />
                &nbsp;{`Drag me #${item}`}
            </div>
        );
    };

    reorderItems = (indexOfItemBeingDragged, hoveredDropZoneIndex) => {
        if (indexOfItemBeingDragged === hoveredDropZoneIndex || indexOfItemBeingDragged + 1 === hoveredDropZoneIndex) {
            // do nothing, nothing changed
            return;
        }

        const itemsReordered = [];

        if (hoveredDropZoneIndex === 0) {
            itemsReordered.push(this.state.dragItems[indexOfItemBeingDragged]);
        }

        this.state.dragItems.forEach((item, index, items) => {
            if (index !== indexOfItemBeingDragged) {
                itemsReordered.push(item);
            }
            if (index + 1 === hoveredDropZoneIndex) {
                itemsReordered.push(items[indexOfItemBeingDragged]);
            }
        }, this);

        this.setState({ dragItems: itemsReordered });
    };

    handleTextChange = (value) => {
        this.setState({
            textInput: value
        });
    }

    handleTagChange = (value) => {
        this.setState({
            selectedTags: value
        });
    };

    handleMonthChange = (selectedMonth) => {
        this.setState({ selectedMonth });
    };

    render() {
        const fixtures = generateFixtures('2021-04-01T00:00:00Z', '2021-06-01T00:00:00Z', '2103')
            .map(convertFixture);
        return (
            <div className="sten-content sten-test-page">
                <div className="sten-content__header">
                    <div className="col-24">
                        <h1 className="sten-content__title">Tests</h1>
                        <p>{`App version: ${process.env.APP_VERSION}`}</p>
                    </div>
                </div>
                <ScrollArea className="sten-content__body" stopScrollPropagation={false}>
                    <div className="sten-content__section flex-row">
                        <div className="col-6">
                            <TagSelect
                                options={legTags}
                                value={this.state.selectedTags}
                                onChange={this.handleTagChange}
                            />
                        </div>
                        <div className="col-6">
                            <QuarterPicker
                                name="quarter"
                                value={this.state.selectedQuarter}
                                onChange={this.handleSelectedQuarter}
                            />
                        </div>
                        <div className="col-6">
                            <MonthPickerPopup
                                value={this.state.selectedMonth}
                                onChange={this.handleMonthChange}
                            />
                        </div>
                    </div>
                    <div className="sten-content__section flex-row">
                        <div className="col-6">
                            <Select
                                placeholder="Pick vessel"
                                onChange={(vessel) => this.setState({ selectedVessel: vessel, selectedVoyage: null })}
                                options={this.props.diary || []}
                                labelKey="VesselName"
                                value={this.state.selectedVessel}
                                valueKey="Imo"
                            />
                        </div>
                        <div className="col-6">
                            <Select
                                placeholder="Pick voyage"
                                disabled={!this.state.selectedVessel}
                                onChange={(voyage) => this.setState({ selectedVoyage: voyage })}
                                options={this.state.selectedVessel ? this.state.selectedVessel.Voyages : []}
                                labelKey="VoyageId"
                                value={this.state.selectedVoyage}
                                valueKey="VoyageId"
                            />
                        </div>
                    </div>
                    {this.state.selectedVoyage && (
                        <div className="sten-content__section">
                            <Itinerary
                                itinerary={this.state.selectedVoyage.Itinerary}
                            />
                        </div>
                    )}
                    <div className="sten-content__section">
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-10 col-xs-24">
                                <OperatorsDiaryFixture fixture={fixtures[0]} />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-8 col-xs-24">
                                <PortSelect
                                    searchLimit={30}
                                    splitOptionsIntoSections={this.splitPortsIntoCountries}
                                    value={this.state.selectedPorts}
                                    onChange={this.setSelectedPorts}
                                    multiple
                                    clearable
                                    placeholder="Select ports"
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-8 col-xs-24">
                                <DateTimePopup
                                    alwaysShow6Weeks
                                    alignment="center"
                                    autoPlacement
                                    isClearable
                                    dateFormat={TimeHelper.getFormat()}
                                    disableMonthsOutOfRange
                                    // withTime={false}
                                    invalid={this.state.selectedDateTime
                                    && (this.state.selectedDateTime.isDateInvalid
                                        || this.state.selectedDateTime.isTimeInvalid)}
                                    dateType="object"
                                    onDateSelect={this.handleDateTimeChange}
                                    value={this.state.selectedDateTime}
                                    minDate={{
                                        day: 4,
                                        month: 3,
                                        year: 2018,
                                        hour: 4,
                                        minute: 43,
                                        second: 29
                                    }}
                                    maxDate={{
                                        day: 25,
                                        month: 10,
                                        year: 2030,
                                        hour: 20,
                                        minute: 17,
                                        second: 15
                                    }}
                                />
                            </div>
                            <div className="col-4">
                                <Textarea
                                    value={this.state.textInput}
                                    onChange={this.handleTextChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sten-content__section">
                        <div className="row">
                            <div className="col-2">
                                <FlipSwitch
                                    size="xs"
                                    activeLabel="Laden"
                                    inactiveLabel="Ballast"
                                    value={this.state.isSwitchActive}
                                    onChange={this.toggleSwitch}
                                />
                            </div>
                            <div className="col-10">
                                <ReorderableItemsList
                                    items={this.state.dragItems}
                                    renderItem={this.renderItem}
                                    onDragComplete={this.reorderItems}
                                    scrollAreaProps={{ style: { height: '15rem' } }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sten-content__section">
                        <div className="row">
                            <div className="col-6 text-center">
                                <div
                                    className="sten-panel__header"
                                    style={{ marginRight: '1rem', overflow: 'hidden' }}
                                >
                                    <div className="text-uppercase">
                                        Ballast
                                    </div>
                                    <PortSelect
                                        value={this.state.selectedPorts}
                                        onChange={this.setSelectedPorts}
                                        multiple
                                    />
                                    <BadgeToggle
                                        className="sten-badge-toggle--danger"
                                        isInactive={this.state.areMissingReportsShown}
                                        label="1 deleted"
                                        onClick={this.toggleMissingBadge}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sten-content__section">
                        <div className="row">
                            <div className="col-6">
                                <MultiStringSelect
                                    name="recipient"
                                    placeholder={translate('POSITION_LIST.COMPOSE.MODAL.TO')}
                                    onChange={this.handleMultiStringChange}
                                    value={this.state.multiStringValue}
                                    validateInput={this.validateEmailInput}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sten-content__section">
                        <div className="row">
                            <div className="col-2 text-center">
                                <BadgeToggle
                                    className="sten-badge-toggle--warning"
                                    isInactive={this.state.areMissingReportsShown}
                                    label="3 Missing"
                                    onClick={this.toggleMissingBadge}
                                />
                            </div>
                            <div className="col-2 text-center">
                                <BadgeToggle
                                    className="sten-badge-toggle--success"
                                    isInactive={this.state.areMissingReportsShown}
                                    label="3 Missing"
                                    onClick={this.toggleMissingBadge}
                                />
                            </div>
                            <div className="col-2 text-center">
                                <BadgeToggle
                                    className="sten-badge-toggle--danger"
                                    isInactive={this.state.areMissingReportsShown}
                                    label="1 Deleted"
                                    onClick={this.toggleMissingBadge}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sten-content__section">
                        <div className="row">
                            <div className="col-4">
                                <CoordinatesInput
                                    coordinates={this.state.customCoordinates}
                                    onChange={this.handleCoordinatesChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sten-content__section">
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-12 col-xs-24">
                                <DateRangePopup
                                    isClearable
                                    dateType="object"
                                    disableMonthsOutOfRange
                                    // presets={this.generateDateRangePresets()}
                                    shouldRenderDefaultPresets
                                    onRangeSelect={this.handleRangeChange}
                                    value={this.state.selectedRange}
                                    // minDate={{ day: 4, month: 1, year: 2018 }}
                                    // maxDate={{ day: 25, month: 12, year: 2018 }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sten-content__section">
                        <div className="sten-icon-list__title">HTEC Range Date Picker</div>
                        <div className="row">
                            <div className="col-md-3">
                                <Select
                                    name="placement"
                                    valueKey="value"
                                    labelKey="value"
                                    options={this.alignments}
                                    placeholder="Alignment"
                                    value={this.state.selectedAlignment}
                                    onChange={(selectedAlignment) => this.setState({ selectedAlignment })}
                                />
                            </div>
                            <div className="col-md-3">
                                <Select
                                    name="alignment"
                                    valueKey="value"
                                    labelKey="value"
                                    options={this.placements}
                                    placeholder="Placement"
                                    value={this.state.selectedPlacement}
                                    onChange={(selectedPlacement) => this.setState({ selectedPlacement })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sten-content__section">
                        <div className="row">
                            <div className="col-5">
                                <div className="sten-icon-list__title">Datepicker with selected date</div>
                                <DatePicker
                                    minDate={{ day: 1, month: 2, year: 2016 }}
                                    maxDate={{ day: 2, month: 6, year: 2022 }}
                                    onDateSelect={this.setDatePickerDate}
                                    selectedDate={this.state.selectedDate}
                                />
                            </div>
                            <div className="col-5">
                                <div className="sten-icon-list__title">Time Picker</div>
                                <TimePicker
                                    className="test-timepicker"
                                    selectedTime={this.state.selectedTime}
                                    onTimeChange={this.onTimeChange}
                                    minTime={{ hour: 2, minute: 5, second: 2 }}
                                    maxTime={{ hour: 23, minute: 48, second: 32 }}
                                    config={{ second: { hidden: true }, minute: { disabled: true } }}
                                    showUnits
                                />
                            </div>
                            <div className="col-4">
                                <Textarea
                                    value={this.state.textInput}
                                    onChange={this.handleTextChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sten-content__section">
                        <div className="sten-icon-list__title">Inputs</div>
                        <div className="row">
                            <div className="col-md-4 col-pad--sm">
                                <Select
                                    name="selectedCompanies"
                                    searchable
                                    multiple
                                    clearable
                                    valueKey="Id"
                                    labelKey="Name"
                                    options={fakeCompanies}
                                    placeholder={translate('POSITION_LIST.FILTERS.SELECT_COMPANY_FLEETS')}
                                    value={this.state.selectedCompanies}
                                    onChange={this.handleCompanyChange}
                                />
                            </div>
                            <div className="col-md-4 col-pad--sm">
                                <Input
                                    title={translate('POSITION_LIST.FILTERS.ETA_COLUMN_DISABLED')}
                                    name="selectedSpeed"
                                    placeholder={translate('POSITION_LIST.FILTERS.SPEED')}
                                    onChange={this.handleSpeedChange}
                                    value={this.state.selectedSpeed}
                                    prefix="min"
                                    // clearable
                                    suffix="kn"
                                />
                            </div>
                            <div className="col-md-2 col-pad--sm">
                                <Checkbox
                                    className="text-uppercase"
                                    onChange={this.handleCheckboxClick}
                                    isChecked={this.state.isChecked}
                                    id={47}
                                    name="test-checkbox"
                                >
                                    Click me
                                </Checkbox>
                            </div>
                            <div className="col-md-2 col-pad--sm">
                                <RadioButton
                                    key={1}
                                    className="text-uppercase"
                                    onChange={this.handleRadioButtonClick.bind(this, 0)}
                                    isChecked={this.state.selectedRadioOption === 0}
                                >
                                    Option 1
                                </RadioButton>
                            </div>
                            <div className="col-md-2 col-pad--sm">
                                <RadioButton
                                    key={2}
                                    className="text-uppercase"
                                    onChange={this.handleRadioButtonClick.bind(this, 1)}
                                    isChecked={this.state.selectedRadioOption === 1}
                                >
                                    Option 2
                                </RadioButton>
                            </div>
                            <div className="col-md-4 col-pad--sm">
                                <Select
                                    clearable
                                    valueKey="isActive"
                                    labelKey="title"
                                    options={fakeStatuses}
                                    placeholder="Single select"
                                    value={this.state.selectedStatus}
                                    onChange={this.handleStatusChange}
                                />
                            </div>
                            <div className="col-md-4 col-pad--sm">
                                <Select
                                    onSearch={this.getCompaniesAsync}
                                    searchable
                                    clearable
                                    valueKey="Id"
                                    labelKey="Name"
                                    options={this.state.asyncCompanies}
                                    placeholder="Async search by company"
                                    value={this.state.asyncSelectedCompany}
                                    onChange={this.handleAsyncCompanyChange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sten-content__section">
                        <div className="row">
                            <div className="col-md-4 col-pad--sm">
                                <NumberInput
                                    disabled={!this.state.isSwitchActive}
                                    value={this.state.value}
                                    unit="kn"
                                    step={0.1}
                                    decimals={2}
                                    min={4}
                                    max={10}
                                    onChange={this.setValue}
                                />
                            </div>
                            <div className="col-md-2 col-pad--sm">
                                <FlipSwitch
                                    value={this.state.isSwitchActive}
                                    onChange={this.toggleSwitch}
                                />
                            </div>
                            <div className="col-md-4 col-pad--sm">
                                <PortSelect
                                    value={this.state.selectedPort}
                                    onChange={this.setSelectedPort}
                                />
                            </div>
                            <div className="col-md-2 col-pad--sm">
                                <Menu>
                                    <MenuItem
                                        icon="icon-edit"
                                        onClick={this.openModal.bind(this, 'some id', 3)}
                                        preventMenuClose
                                    >
                                        Edit terminal details
                                    </MenuItem>
                                    <MenuItem icon="icon-delete">Delete terminal with berths</MenuItem>
                                    <MenuItem icon="icon-position">Locate on map</MenuItem>
                                    <MenuItem
                                        icon="icon-fleet-management"
                                        isDisabled
                                    >
                                        Disabled Menu Option
                                    </MenuItem>
                                    <MenuItem>No Icon Option Long very long text</MenuItem>
                                    <SubMenu text="Sub menu">
                                        <MenuItem icon="icon-delete">Menu item 1</MenuItem>
                                        <MenuItem icon="icon-position">Menu item 2</MenuItem>
                                    </SubMenu>
                                </Menu>
                            </div>
                            <div className="col-md-12">
                                <TrendIcon value={-2} breakpoints={[0]} />
                                <TrendIcon value={5} breakpoints={[0]} />
                                <TrendIcon value={-7} breakpoints={[-5, 5]} />
                                <TrendIcon value={-2} breakpoints={[-5, 5]} />
                                <TrendIcon value={10} breakpoints={[-5, 5]} />
                                <TrendIcon value={-12} />
                                <TrendIcon value={-3} />
                                <TrendIcon value={1} />
                                <TrendIcon value={4} />
                                <TrendIcon value={17} />
                            </div>
                        </div>
                        <div className="sten-icon-list__title">Progress circles</div>
                        <div className="row test-progress-row">
                            <div className="col-4">
                                <ProgressCircle value={10} />
                            </div>
                            <div className="col-4">
                                <ProgressCircle value={29} />
                            </div>
                            <div className="col-4">
                                <ProgressCircle value={55} />
                            </div>
                            <div className="col-4">
                                <ProgressCircle value={75} />
                            </div>
                            <div className="col-4">
                                <ProgressCircle value={100} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <Gauge value={5} max={26} valueUnit="DTR(nm)" />
                            </div>
                            <div className="col-4">
                                <Gauge value={24} valueUnit="DTR(nm)" />
                            </div>
                            <div className="col-4">
                                <Gauge value={44} valueUnit="DTR(nm)" />
                            </div>
                            <div className="col-4">
                                <Gauge value={78} valueUnit="DTR(nm)" />
                            </div>
                            <div className="col-4">
                                <Gauge value={100} valueUnit="DTR(nm)" />
                            </div>
                        </div>
                        <div className="sten-content__section text-center text-secondary">
                            <h1>With breakpoints</h1>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <Gauge value={7} valueUnit="days" breakpoints={[8, 13]} max={21} />
                            </div>
                            <div className="col-4">
                                <Gauge value={3} valueUnit="day" breakpoints={[8, 13]} max={21} />
                            </div>
                            <div className="col-4">
                                <Gauge value={11} valueUnit="day" breakpoints={[8, 13]} max={21} />
                            </div>
                            <div className="col-4">
                                <Gauge value={12} valueUnit="days" breakpoints={[8, 13]} max={21} />
                            </div>
                            <div className="col-4">
                                <Gauge value={19} valueUnit="days" breakpoints={[8, 13]} max={21} />
                            </div>
                        </div>
                    </div>
                    <div className="sten-content__section">
                        <TestIconList />
                    </div>
                </ScrollArea>
            </div>
        );
    }
}

TestPage.propTypes = {
    diary: PropTypes.arrayOf(PropTypes.any),
    fuelData: PropTypes.shape({
        parsedBunkerData: PropTypes.arrayOf(PropTypes.any),
        fuelTypes: PropTypes.arrayOf(PropTypes.any)
    }),
    getDiary: PropTypes.func.isRequired,
    rangeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    setFuelQuantity: PropTypes.func.isRequired,
    test: PropTypes.string
};

TestPage.defaultProps = {
    diary: null,
    fuelData: null,
    test: ''
};

function mapStateToProps(state) {
    return {
        diary: getConvertedDiary(state),
        fuelData: state.testReducer.fuelData,
        rangeOptions: state.vesselInfoReducer.rangeOptions,
        test: getSelectedTest(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getDiary: (params) => getDiary(dispatch, params),
        setFuelQuantity: (itemIndex, fuel, value) => setFuelQuantity(dispatch, itemIndex, fuel, value)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TestPage);
