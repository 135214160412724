import React from 'react';
import PropTypes from 'prop-types';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { getObjectProp, getClassName, leastCommonMultiple } from 'utils/helpers/info-helper';
import { translate } from 'utils/i18n/i18n-model';
/* components */
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
import EmptyContent from 'components/empty-content/empty-content';
/* styles */
import './operators-diary-table.scss';
/* constants */
import { tableColumns, tableContentStyle, getHeaderRows, getRowGroups } from '../operators-diary-constants';

const t = (key) => translate(`OPERATORS_DIARY.TABLE.${key}`);

class OperatorsDiaryTable extends React.PureComponent {
    state = {
        collapsedAccordions: {}
    };

    toggleAccordion = (key) => () => {
        this.setState({
            collapsedAccordions: {
                ...this.state.collapsedAccordions,
                [key]: !this.state.collapsedAccordions[key]
            }
        });
    };

    getAccordionClass = (isCollapsed, isDisabled) => getClassName('sten-table__accordion', {
        'sten-table__accordion--collapsed': isCollapsed,
        'sten-table__accordion--disabled': isDisabled
    });

    calculateRowSpans = (voyage) => {
        const activities = voyage.Itinerary.reduce((res, port) => res + port.Activities.length, 0);
        const fixtures = voyage.Fixtures.length;
        const voyageRowSpan = leastCommonMultiple(activities, fixtures) || 1;
        return {
            voyage: voyageRowSpan,
            activity: voyageRowSpan / (activities || 1),
            fixture: voyageRowSpan / (fixtures || 1)
        };
    };

    calculateVoyageCellConfigs = (voyage) => {
        const rowSpans = this.calculateRowSpans(voyage);
        const rowGroups = getRowGroups();
        let port;
        let portRowIndex = 0;
        let portIndex = 0;
        let portRowSpan;
        let activity;
        let activityRowIndex = 0;
        let activityIndex = 0;
        let fixture;
        let fixtureRowIndex = 0;
        let fixtureIndex = 0;
        let cells;

        return new Array(rowSpans.voyage).fill(null).map((item, rowIndex) => {
            cells = [];
            if (rowIndex === 0 && rowGroups.Voyage && rowGroups.Voyage[0]) {
                cells.push({
                    ...rowGroups.Voyage[0],
                    rowSpan: rowSpans.voyage,
                    data: voyage,
                    className: 'sten-operators-diary-table__cell--last-row'
                });
            }
            if (rowIndex === portRowIndex && voyage.Itinerary.length === 0) {
                cells.push({
                    id: 'no-itinerary-cell',
                    className: 'text-secondary'
                        + ' sten-table__cell--group-start'
                        + ' sten-operators-diary-table__cell--last-row',
                    colSpan: rowGroups.Port.length + rowGroups.Activity.length,
                    rowSpan: rowSpans.activity,
                    render: () => t('NO_ITINERARY')
                });
                portRowIndex += rowSpans.activity;
            } else {
                if (rowIndex === portRowIndex && rowGroups.Port && rowGroups.Port[0]) {
                    port = voyage.Itinerary[portIndex];
                    portIndex++;
                    activityIndex = 0;
                    portRowSpan = port.Activities.length * rowSpans.activity;
                    portRowIndex += portRowSpan;
                    cells.push({
                        ...rowGroups.Port[0],
                        rowSpan: portRowSpan,
                        data: port,
                        className: getClassName('sten-table__cell--group-start', {
                            'sten-operators-diary-table__cell--last-row': portRowIndex === rowSpans.voyage
                        })
                    });
                }
                if (rowIndex === activityRowIndex && rowGroups.Activity) {
                    activity = port.Activities[activityIndex];
                    activityRowIndex += rowSpans.activity;
                    activityIndex++;
                    cells = [...cells, ...rowGroups.Activity.map((col, index) => ({
                        ...col,
                        rowSpan: rowSpans.activity,
                        data: activity,
                        className: getClassName({
                            'text-center': index === 0,
                            'sten-operators-diary-table__cell--last-row': activityRowIndex === rowSpans.voyage
                        })
                    }))];
                }
            }
            if (rowIndex === fixtureRowIndex) {
                fixtureRowIndex += rowSpans.fixture;
                if (voyage.Fixtures.length === 0) {
                    cells.push({
                        id: 'no-fixtures-cell',
                        className: 'text-secondary'
                            + ' sten-table__cell--group-start'
                            + ' sten-operators-diary-table__cell--last-row',
                        colSpan: rowGroups.Fixture.length,
                        rowSpan: rowSpans.fixture,
                        render: () => t('NO_FIXTURES')
                    });
                } else if (rowGroups.Fixture) {
                    fixture = voyage.Fixtures[fixtureIndex];
                    fixtureIndex++;
                    cells = [...cells, ...rowGroups.Fixture.map((col, index) => ({
                        ...col,
                        rowSpan: rowSpans.fixture,
                        data: fixture,
                        className: getClassName({
                            'sten-table__cell--group-start': index === 0,
                            'sten-operators-diary-table__cell--last-row': fixtureRowIndex === rowSpans.voyage
                        })
                    }))];
                }
            }
            if (rowIndex === 0 && rowGroups.Comment && rowGroups.Comment[0]) {
                cells.push({
                    ...rowGroups.Comment[0],
                    rowSpan: rowSpans.voyage,
                    data: voyage,
                    className: 'sten-table__cell--group-start sten-operators-diary-table__cell--last-row'
                });
            }
            return cells;
        });
    };

    renderCell = (cell) => {
        const val = getObjectProp(cell.data, cell.prop);
        return val || '-';
    }

    getVoyageClickHandler = (voyageId) => () =>
        this.props.router.navigate(`${appRoutes.Vessel.OperatorsDiary}/${voyageId}`);

    renderVoyage = (voyage, index) => {
        const voyageClass = getClassName('sten-operators-diary-table__voyage', {
            'sten-table__row--odd': index % 2 !== 0,
            'sten-operators-diary-table__voyage--subs': voyage.IsOnSubs,
            'sten-operators-diary-table__voyage--selected': this.props.selectedVoyageId === voyage.VoyageId
        });
        const rows = this.calculateVoyageCellConfigs(voyage);
        return (
            <tbody className={voyageClass} key={voyage.VoyageId} onClick={this.getVoyageClickHandler(voyage.VoyageId)}>
                {rows.map((row, rowIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <tr key={rowIndex}>
                        {row.map(cell => (
                            <td
                                key={cell.id}
                                rowSpan={cell.rowSpan || 1}
                                colSpan={cell.colSpan || 1}
                                className={cell.className}
                            >
                                {cell.render ? cell.render(cell.data) : this.renderCell(cell)}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        );
    };

    renderVessel = (vessel) => {
        const isCollapsed = this.state.collapsedAccordions[vessel.Imo];
        const isDisabled = vessel.Voyages.length === 0;
        const className = this.getAccordionClass(isCollapsed, isDisabled);
        const colSpan = tableColumns.length;

        return (
            <React.Fragment key={vessel.Imo}>
                <tbody>
                    <tr onClick={!isDisabled ? this.toggleAccordion(vessel.Imo) : undefined} className={className}>
                        <td className="text-uppercase" colSpan={colSpan}>{vessel.VesselName}</td>
                    </tr>
                    {isDisabled && (
                        <tr className="sten-operators-diary-table__no-results">
                            <td colSpan={colSpan}>{t('NO_VOYAGES')}</td>
                        </tr>
                    )}
                </tbody>
                {!isDisabled && !isCollapsed && vessel.Voyages.map(this.renderVoyage)}
            </React.Fragment>
        );
    };

    saveTableRef = c => {
        this.tableRef = c;
        this.props.setInnerRef(this.tableRef);
    };

    render() {
        const { diary } = this.props;

        if (!diary || !diary.length) {
            return <EmptyContent />;
        }

        const headerRows = getHeaderRows();

        return (
            <FixedHeaderTable
                className="sten-operators-diary-table"
                ref={this.saveTableRef}
                contentStyle={tableContentStyle}
                contentClassName="sten-operators-diary-table__scroll-content"
            >
                <table className="sten-table sten-table--sm">
                    <thead>
                        {headerRows.map((hr, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <tr key={index}>
                                {hr.map(hc => (
                                    <th
                                        key={hc.id}
                                        colSpan={hc.colSpan || 1}
                                        rowSpan={hc.rowSpan || 1}
                                        style={hc.style || null}
                                        className={hc.className}
                                    >
                                        {hc.title}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    {diary.map(vessel => this.renderVessel(vessel))}
                </table>
            </FixedHeaderTable>
        );
    }
}

OperatorsDiaryTable.propTypes = {
    className: PropTypes.string,
    diary: PropTypes.arrayOf(PropTypes.object).isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    router: TRouter.isRequired,
    selectedVoyageId: PropTypes.number,
    setInnerRef: PropTypes.func.isRequired,
    toggleUserSetting: PropTypes.func.isRequired,
    userSettings: PropTypes.objectOf(PropTypes.any).isRequired,
    userSettingUpdated: PropTypes.objectOf(PropTypes.any).isRequired
};

OperatorsDiaryTable.defaultProps = {
    className: '',
    selectedVoyageId: null
};

export default withRouter(OperatorsDiaryTable);
