export const ActionTypes = {
    POSITION_LIST_TABLE_TOGGLE_GROUP_COLLAPSE: 'POSITION_LIST_TOGGLE_GROUP_COLLAPSE',
    POSITION_LIST_TABLE_SET_SORT_PROP: 'POSITION_LIST_TABLE_SET_SORT_PROP'
};

export function toggleGroupCollapse(dispatch, group) {
    dispatch({
        type: ActionTypes.POSITION_LIST_TABLE_TOGGLE_GROUP_COLLAPSE,
        group
    });
}

export function setSortProp(dispatch, sortProp) {
    dispatch({
        type: ActionTypes.POSITION_LIST_TABLE_SET_SORT_PROP,
        sortProp
    });
}
