import { takeEvery, put, select } from 'redux-saga/effects';
/* utils */
import { currentYear, defaultVesselType, defaultCurrency } from './euets-helpers';
import { wktParse } from 'components/ol/ol-helpers';
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import { ActionTypes } from './euets-actions';
import { ActionTypes as UserActionTypes } from 'pages/user-pages/user-actions';
/* services */
import EuEtsService from 'services/mt-api/euets-service';
import ConfigService from 'services/config-api/config-service';

function* getEuEtsPrice({ currency = defaultCurrency }) {
    if (ConfigService.featureToggles.showEuEts) {
        const res = yield EuEtsService.getEtsPrice(currency?.Ticker);
        if (res) {
            yield put({ type: ActionTypes.EU_ETS_SET_PRICE, price: res });
        }
    }
}

export function* getEuEts({ coordinates, year, vesselType, emissions }) {
    if (ConfigService.featureToggles.showEuEts) {
        const selectedYear = year || currentYear;
        if (coordinates?.length > 1) {
            const startPoint = coordinates[coordinates.length - 1].Location;
            const endPoint = coordinates[0].Location;
            const selectedVesselType = vesselType || defaultVesselType;
            const params = {
                year: { year: selectedYear?.Name },
                ship_type: { type: selectedVesselType?.Name?.toLowerCase() },
                start: {
                    longitude: startPoint.Longitude,
                    latitude: startPoint.Latitude
                },
                end: {
                    longitude: endPoint.Longitude,
                    latitude: endPoint.Latitude
                }
            };
            const res = yield EuEtsService.getVoyageCoefficient(params);
            return {
                voyageCoefficient: res?.coefficients || [],
                summaryCoefficient: res?.coefficients
                    ? res?.coefficients.reduce((acc, curr) => acc * curr.value, 1)
                    : 1,
                year: selectedYear,
                co2Emissions: emissions?.CO2 || emissions?.Co2Emission || null
            };
        }
        return {
            year: selectedYear
        };
    }
    return null;
}

export function* getEuEtsVoyagePrice({ itineraryPorts, vesselType, sections, imo, fuel, fuel_eca }) {
    if (ConfigService.featureToggles.showEuEts && imo) {
        const currency = yield select((state) => state.userReducer.settings.Currency.Ticker);
        const selectedVesselType = vesselType || defaultVesselType;
        const segments = [];
        let previousPoint = null;
        let currentPoint = null;
        let previousEtd = null;
        itineraryPorts.forEach((point, index) => {
            const parsedLocation = wktParse(point.Point.Location);
            currentPoint = { longitude: parsedLocation[0], latitude: parsedLocation[1] };
            if (previousPoint) {
                segments.push({
                    id: index,
                    start: previousPoint,
                    end: currentPoint,
                    start_date: previousEtd,
                    end_date: TimeHelper.joinDateAndOffset(point.Eta, null, point.Point.TimeZoneOffset),
                    co2: sections?.[index - 1]?.Emissions?.CO2?.toFixed(2) || 0,
                    fuel,
                    fuel_eca
                });
            }
            previousPoint = currentPoint;
            previousEtd = TimeHelper.joinDateAndOffset(
                TimeHelper.getDateFromDiff(point.Eta, point.TimeInPort),
                null,
                point.Point.TimeZoneOffset
            );
        });

        const res = yield EuEtsService.getVoyagePrice({
            ship_type: { type: selectedVesselType?.Name?.toLowerCase() },
            currency: { ticker: currency },
            imo,
            segments
        });

        return res?.results;
    }
    return null;
}

export default function* euEtsSaga() {
    yield takeEvery(ActionTypes.EU_ETS_GET_PRICE, getEuEtsPrice);
    yield takeEvery(
        UserActionTypes.USER_SETTINGS_SET,
        (action) => {
            if (action.newSettings.Currency) {
                return getEuEtsPrice({ currency: action.newSettings.Currency });
            }
            return null;
        }
    );
}
