import { takeEvery, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
/* router */
import { updateQueryParams } from 'app-router';
/* helpers */
import {
    convertFuelData,
    parseFuelData
} from 'components/fuel-data-table/fuel-data-table-helpers';
import { convertSpeedInformations } from './questionnaire-helpers';
import TimeHelper from 'utils/helpers/time-helper';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { getObjectProp, setObjectProp } from 'utils/helpers/info-helper';
import { sortByProp } from 'utils/helpers/array-helper';
/* actions */
import { ActionTypes } from './questionnaire-actions';
/* services */
import LocalStorageService from 'services/local-storage-service';
import QuestionnaireService from 'services/core-api/questionnaire-service';
/* selectors */
import { getReportFuelTypes } from 'components/vessel-report/vessel-report-selectors';
/* mocked data */
import { mockQuestionnaireData } from './questionnaire-test-utils';

const dateOffsetKeys = [
    'LastPropellerPolishOffset', 'LastUwCleaningOffset', 'LastUwInspectionOffset', 'ReportDateOffset'
];

const fuelDataKeys = ['BunkerData'];

function* getEditingOptions() {
    const { areEditingOptionsFetched } = yield select(state => state.questionnaireReducer);
    if (areEditingOptionsFetched) {
        return;
    }
    const editingOptions = yield all({
        bunkerTypes: QuestionnaireService.getBunkerTypes()
    });
    yield put({ type: ActionTypes.QUESTIONNAIRE_SET_EDITING_OPTIONS, editingOptions });
}

function* getQuestionnaire(action) {
    let qn;
    // mocked data
    if (LocalStorageService.isDebugModeActive()) {
        qn = yield mockQuestionnaireData();
    } else {
        qn = yield QuestionnaireService.getById(action.id);
    }
    if (qn) {
        qn = {
            ...qn,
            Vessel: qn.Vessel || { Imo: qn.Imo, Title: qn.VesselName }
        };
        const fuelTypes = yield select(state => getReportFuelTypes(state, qn));
        fuelDataKeys.forEach(key => {
            if (qn[key] !== undefined) {
                qn[key] = convertFuelData(qn[key], fuelTypes.map);
            }
        });
        dateOffsetKeys.forEach(dateOffsetKey => {
            const value = getObjectProp(qn, dateOffsetKey);
            if (value !== undefined && value !== null) {
                setObjectProp(qn, dateOffsetKey, TimeHelper.getUTCOffsetString(value, false), true);
            }
        });
        qn.SpeedInformations = convertSpeedInformations(qn.SpeedInformations);
        qn.SpeedDetails = qn.SpeedDetails.sort(sortByProp('VesselCondition.Name'));
        qn.PortVisits = qn.PortVisits.sort(sortByProp('Sequence'));
        yield put({ type: ActionTypes.QUESTIONNAIRE_SET, questionnaire: qn });
    }
}

function* updateQuestionnaire(action) {
    const qn = action.data;
    const parsedQn = {
        ...qn,
        VesselName: qn.Vessel.Title,
        Imo: qn.Vessel.Imo
    };
    const fuelTypes = yield select(state => getReportFuelTypes(state, qn));
    fuelDataKeys.forEach(key => {
        if (qn[key] !== undefined) {
            parsedQn[key] = parseFuelData(qn[key], fuelTypes.map);
        }
    });
    dateOffsetKeys.forEach(dateOffsetKey => {
        const value = getObjectProp(qn, dateOffsetKey);
        if (value !== null && value !== undefined) {
            setObjectProp(parsedQn, dateOffsetKey, TimeHelper.getMinutesFromTimeSpan(value, false), true);
        }
    });
    parsedQn.SpeedInformations = qn.SpeedInformations.filter(si => si.Speed);
    /* clear tug reason value based on number of tugs */
    if (parsedQn.Vessel !== undefined) {
        delete parsedQn.Vessel;
    }
    if (!parsedQn.ProlongedPortStays) {
        parsedQn.PortOfProlongedPortStay = null;
    }
    parsedQn.PortVisits = parsedQn.PortVisits.map((pv, i) => ({ ...pv, Sequence: i }));
    const res = yield QuestionnaireService.update(qn.Id, parsedQn);

    if (res) {
        toast(t('QUESTIONNAIRE.MESSAGES.QUESTIONNAIRE_SUCCESSFULLY_UPDATED'), { type: toast.TYPE.SUCCESS });
        yield put({
            type: ActionTypes.QUESTIONNAIRE_UPDATED,
            id: qn.Id,
            questionnaire: res
        });
        yield updateQueryParams({
            remove: ['questionnaireEdit', ...action.shouldClose ? ['questionnaireId'] : []]
        });
        if (action.shouldReload) {
            window.location.reload();
        } else if (!action.shouldClose) {
            yield getQuestionnaire({ id: parsedQn.Id, shouldShowSuggestions: false });
        }
    }
}

function* deleteQuestionnaire(action) {
    const response = yield QuestionnaireService.remove(action.id);
    if (response) {
        toast(t('QUESTIONNAIRE.MESSAGES.QUESTIONNAIRE_SUCCESSFULLY_DELETED'), { type: toast.TYPE.SUCCESS });
        yield put({ type: ActionTypes.QUESTIONNAIRE_DELETED, id: action.id });
        yield updateQueryParams({ remove: ['questionnaireEdit', 'questionnaireId'] });
    }
}

export default function* questionnaireSaga() {
    yield takeEvery(ActionTypes.QUESTIONNAIRE_GET, getQuestionnaire);
    yield takeEvery(ActionTypes.QUESTIONNAIRE_GET_EDITING_OPTIONS, getEditingOptions);
    yield takeEvery(ActionTypes.QUESTIONNAIRE_UPDATE, updateQuestionnaire);
    yield takeEvery(ActionTypes.QUESTIONNAIRE_DELETE, deleteQuestionnaire);
}
