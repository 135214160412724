import React from 'react';
/* components */
import ContentArray from 'components/content-array/content-array';
import Input from 'components/input/input';
import Select from 'components/select/select';
import Validation from 'components/validation/validation';
/* common */
import { t, tu, commonFields } from '../vessel-report-common-fields';

export const fieldMap = {

    // Basic info

    Vessel: commonFields.Vessel(),
    VoyageNumber: commonFields.VoyageNumber(),
    CallSign: commonFields.CallSign(),
    ReportDate: commonFields.ReportDate(t('REPORT_DATE')),

    // -----

    MastersName: commonFields.String('MastersName', t('MASTERS_NAME')),
    Charterer: commonFields.String('Charterer', t('CHARTERER')),
    CharterPartyDate: commonFields.DateTime('CharterPartyDate', 'CharterPartyDateOffset', t('CHARTERPARTY_DATE')),
    LayDaysFromDate: commonFields.DateTime('LayDaysFromDate', 'LayDaysFromDateOffset', t('LAY_DAYS_FROM_DATE')),
    LayDaysToDate: commonFields.DateTime('LayDaysToDate', 'LayDaysToDateOffset', t('LAY_DAYS_TO_DATE')),
    CharterPartySpeed: commonFields.Speed('CharterPartySpeed', t('CHARTERPARTY_SPEED')),

    // Voyage Data

    CargoStatus: commonFields.CargoStatus(),
    DeparturePort: commonFields.Point('DeparturePort', t('DEPARTURE_PORT')),
    ExpectedDepartureDraft: commonFields.Draft('ExpectedDepartureDraft', t('EXPECTED_DEPARTURE_DRAFT')),
    EstimatedTimeOfDepartureFromBerthDate: commonFields.DateTime(
        'EstimatedTimeOfDepartureFromBerthDate',
        'EstimatedTimeOfDepartureFromBerthDateOffset',
        t('ETD_FROM_BERTH_ANCHORAGE')
    ),
    MastersIntendedRpmSettingThisVoyage: commonFields.Number(
        'MastersIntendedRpmSettingThisVoyage',
        t('MASTERS_INTENDED_RPM_SETTING_THIS_VOYAGE'),
        0
    ),
    RPMRange: {
        label: <label className="label">{t('RPM_RANGE')}</label>,
        view: report => {
            let label = '-';
            if (typeof report.RPMMin === 'number' && typeof report.RPMMax === 'number') {
                label = `${report.RPMMin} - ${report.RPMMax}`;
            }
            return (
                <label className="label label--value">{label}</label>
            );
        },
        edit: (report, setValue, { validationRules }) => {
            return (
                <div className="row">
                    <div className="col-12">
                        <Validation.Wrapper hintsOnHover validations={validationRules.RPMMin}>
                            <Input
                                name="RPMMin"
                                value={report.RPMMin}
                                onChange={setValue.bind(this, 'RPMMin')}
                                prefix="min"
                            />
                        </Validation.Wrapper>
                    </div>
                    <div className="col-12">
                        <Validation.Wrapper hintsOnHover validations={validationRules.RPMMax}>
                            <Input
                                name="RPMMax"
                                value={report.RPMMax}
                                onChange={setValue.bind(this, 'RPMMax')}
                                prefix="max"
                            />
                        </Validation.Wrapper>
                    </div>
                </div>
            );
        }
    },
    Gm: commonFields.Number('Gm', t('GM'), 2, tu('GM')),
    RollPeriod: commonFields.Number('RollPeriod', t('ROLL_PERIOD'), 2, tu('SECOND')),
    CargoGrade: commonFields.String('CargoGrade', t('CARGO_GRADE')),
    CargoQuantity: commonFields.Number('CargoQuantity', t('CARGO_QUANTITY'), 1),
    InstructedSpeed: commonFields.Speed('InstructedSpeed', t('INSTRUCTED_SPEED')),
    DestinationPort: commonFields.Point('DestinationPort', t('DESTINATION_PORT')),
    MastersEtaAtPilotStationDate: commonFields.DateTime(
        'MastersEtaAtPilotStationDate',
        'MastersEtaAtPilotStationDateOffset',
        t('MASTERS_ETA_AT_PILOT_STATION')
    ),

    // Waypoints

    Waypoints: {
        Position: (nameSuffix) => commonFields.Position('Position', nameSuffix),
        NavigationType: (nameSuffix) => ({
            label: <label className="label label--value">{t('NAVIGATION_TYPE')}</label>,
            view: report => {
                return (
                    <label className="label label--value">{report.NavigationType.DisplayName}</label>
                );
            },
            edit: (report, setValue, options) => {
                return (
                    <Validation.Wrapper hintsOnHover validations={options.validationRules.NavigationType}>
                        <Select
                            name={`NavigationType.${nameSuffix}`}
                            options={options.navigationTypes}
                            value={report.NavigationType}
                            valueKey="Id"
                            labelKey="DisplayName"
                            onChange={setValue.bind(this, 'NavigationType')}
                        />
                    </Validation.Wrapper>
                );
            }
        })
    },

    // Remarks

    Remarks: commonFields.Remarks()
};

const waypointProps = [
    'NavigationType',
    'Position'
];

const emptyWaypoint = {
    Position: null,
    NavigationType: null
};

const getWaypointsChangeHandler = (onChange) => (data) => {
    const convertedWayPoints = data.map(waypoint => ({
        ...waypoint,
        Position: waypoint.Position && waypoint.Position.Coordinates
            ? { Longitude: waypoint.Position.Coordinates[0], Latitude: waypoint.Position.Coordinates[1] }
            : waypoint.Position
    }));
    onChange('Waypoints', convertedWayPoints);
};

export const dataMap = {
    Waypoints: (params) => (
        <ContentArray
            fieldMap={fieldMap.Waypoints}
            onChange={getWaypointsChangeHandler(params.onChange)}
            data={params.data.Waypoints}
            isEditing={params.isEditing}
            editingOptions={params.editingOptions}
            fields={waypointProps}
            newItem={emptyWaypoint}
            itemTitle={t('WAYPOINT_ITEM_TITLE')}
            maxLength={20}
            validationRules={params.validationRules.Waypoints}
        />
    )
};

export default { fieldMap, dataMap };
