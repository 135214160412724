import Oidc, { UserManager } from 'oidc-client';
/* helpers */
import { getAppUrl } from 'utils/helpers/api-helper';
/* services */
import LocalStorageService from './local-storage-service';

class AuthService {
    userManager = null;

    config = null;

    init(authority) {
        const appUrl = getAppUrl();
        this.userManager = new UserManager({
            client_id: 'orbitmi-web',
            redirect_uri: `${appUrl}/authorization/signin-redirect`,
            silent_redirect_uri: `${appUrl}/authorization/silent-redirect`,
            post_logout_redirect_uri: `${appUrl}/authorization/post-logout`,
            response_type: 'code',
            scope: 'openid email profile',
            accessTokenExpiringNotificationTime: 60,
            automaticSilentRenew: true,
            userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
            authority
        });
        this.userManager.events.addAccessTokenExpired(() => {
            this.login(true);
        });
        this.userManager.events.addUserSignedOut(() => {
            this.login(true);
        });
    }

    login(shouldSaveLastVisitedLocation = false) {
        if (shouldSaveLastVisitedLocation) {
            if (window.location.pathname.indexOf('/authorization/') !== 0) {
                LocalStorageService.setLastVisitedLocation(window.location);
            }
        }
        this.userManager.removeUser().then(() => {
            this.userManager.signinRedirect();
        });
    }

    logout() {
        LocalStorageService.clearAll();
        this.userManager.signoutRedirect();
    }
}

export default new AuthService();
