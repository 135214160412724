import React from 'react';
import PropTypes from 'prop-types';
/* components */
import Checkbox from 'components/checkbox/checkbox';
/* styles */
import './gsm-map-legend-row.scss';

const rowIconsClassMap = {
    office: 'icon-port',
    tanker: 'icon-laden',
    ferry: 'icon-laden',
    drill: 'icon-oil-rig',
    route: 'icon-route'
};

export class GsmMapLegendRow extends React.PureComponent {
    changeOptionState = () => {
        const newOptionState = !this.props.checkedState;
        this.props.onChange(newOptionState);
    };

    static generateRowIcon(className, color) {
        const style = {
            color
        };

        return (
            <div className="flex flex-grow gsm-map-legend__row-icon">
                <span className={`icon ${className}`} style={style} />
            </div>
        );
    }

    render() {
        return (
            <div className="row sten-panel__row gsm-map-legend__row flex-row">
                <div className="flex-shrink">
                    <Checkbox
                        className="sten-checkbox"
                        isChecked={this.props.checkedState}
                        onChange={this.changeOptionState}
                    />
                </div>
                <div
                    className="flex flex-shrink cursor-pointer"
                    onClick={this.changeOptionState}
                >
                    {GsmMapLegendRow.generateRowIcon(
                        rowIconsClassMap[this.props.elementType],
                        this.props.elementIconColor
                    )}
                </div>
                <div
                    className="flex-grow cursor-pointer"
                    onClick={this.changeOptionState}
                >
                    {this.props.elementTitle}
                </div>
                <div
                    className="flex-shrink text-right text-secondary cursor-pointer"
                    onClick={this.changeOptionState}
                >
                    {this.props.elementCount}
                </div>
            </div>
        );
    }
}

GsmMapLegendRow.propTypes = {
    checkedState: PropTypes.bool,
    elementCount: PropTypes.number.isRequired,
    elementIconColor: PropTypes.string,
    elementTitle: PropTypes.string.isRequired,
    elementType: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired
};

GsmMapLegendRow.defaultProps = {
    checkedState: false,
    elementIconColor: ''
};

export default GsmMapLegendRow;
