import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { formatNumber } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* components */
import FixedHeaderTable from '../../fixed-header-table/fixed-header-table';
/* styles */
import './vessel-report-data-table.scss';

class VesselReportDataTable extends React.Component {
    renderValue = (data, col, row, id) => {
        let val;
        if (typeof id === 'number' && data[col].FuelTypeQuantities) {
            val = data[col].FuelTypeQuantities[id] ? data[col].FuelTypeQuantities[id][row.prop] : null;
        } else {
            val = data[col] ? data[col][row.prop] : null;
        }
        if (row.multiplier) {
            val *= row.multiplier;
        }
        if (row.type === 'timeSpan') {
            return TimeHelper.getTimeSpanFromMinutes(val) || '-';
        }
        return formatNumber(val, row.decimals) || '-';
    };

    render() {
        const {
            columns,
            columnsOrdered,
            data,
            fuelProp,
            fuelTypes,
            headerMap,
            headerTitle,
            minColWidth,
            rows,
            title
        } = this.props;
        const colWidth = `calc(${100 / columns.length}% - ${12 / columns.length}rem)`;
        const contentStyle = minColWidth ? { minWidth: `${(columns.length * minColWidth) + 12}rem` } : null;
        let cols = columns;
        if (columnsOrdered) {
            cols = columnsOrdered.filter(col => columns.indexOf(col) > -1);
            columns.forEach(col => {
                if (columnsOrdered.indexOf(col) < 0) {
                    cols.push(col);
                }
            });
        }
        return (
            <div className="sten-vessel-report-data-table">
                {title && (
                    <table className="sten-vessel-report-data-table__title sten-table sten-table--xs">
                        <thead><tr><th colSpan={columns.length + 1}>{title}</th></tr></thead>
                    </table>
                )}
                <FixedHeaderTable
                    contentStyle={contentStyle}
                    contentClassName="sten-vessel-report-data-table__content"
                    withHeaderColumn
                    updateTimeout={300}
                    withHeaderRow={false}
                >
                    <table className="sten-table sten-table--xs">
                        <thead>
                            <tr>
                                <th className="text-secondary">{headerTitle}</th>
                                {cols.map(col => (
                                    <th key={col} className="text-secondary" style={{ width: colWidth }}>
                                        {headerMap && headerMap[col] ? headerMap[col] : col}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {rows && rows.map(row => (
                                <tr key={row.prop}>
                                    <th className="text-secondary">{row.title}</th>
                                    {cols.map(col => <td key={col}>{this.renderValue(data, col, row)}</td>)}
                                </tr>
                            ))}
                            {fuelProp && fuelTypes && fuelTypes.map(ft => (
                                <tr key={ft.Id}>
                                    <th className="text-secondary">{`${ft.Name}${fuelProp.suffix || ''}`}</th>
                                    {cols.map(col => (
                                        <td key={col}>{this.renderValue(data, col, fuelProp, ft.Id)}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </FixedHeaderTable>
            </div>
        );
    }
}

VesselReportDataTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    columnsOrdered: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.objectOf(PropTypes.any),
    fuelProp: PropTypes.shape({
        prop: PropTypes.string.isRequired,
        decimals: PropTypes.number,
        suffix: PropTypes.string
    }),
    fuelTypes: PropTypes.arrayOf(PropTypes.object),
    headerMap: PropTypes.objectOf(PropTypes.any),
    headerTitle: PropTypes.string,
    minColWidth: PropTypes.number,
    rows: PropTypes.arrayOf(PropTypes.shape({
        prop: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        decimals: PropTypes.number
    })),
    title: PropTypes.string
};

VesselReportDataTable.defaultProps = {
    columnsOrdered: null,
    data: null,
    fuelProp: null,
    fuelTypes: null,
    headerMap: null,
    headerTitle: '',
    minColWidth: 5,
    rows: null,
    title: ''
};

export default VesselReportDataTable;
