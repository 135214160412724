/* eslint-disable max-len */
export const ActionTypes = {
    ENERGY_MANAGEMENT_VOYAGE_REPORT_SET_MOUNTED: 'ENERGY_MANAGEMENT_VOYAGE_REPORT_SET_MOUNTED',
    ENERGY_MANAGEMENT_VOYAGE_REPORT_SET: 'ENERGY_MANAGEMENT_VOYAGE_REPORT_SET',
    ENERGY_MANAGEMENT_VOYAGE_REPORT_GENERATE: 'ENERGY_MANAGEMENT_VOYAGE_REPORT_GENERATE',
    ENERGY_MANAGEMENT_VOYAGE_REPORT_GENERATE_PDF: 'ENERGY_MANAGEMENT_VOYAGE_REPORT_GENERATE_PDF',
    ENERGY_MANAGEMENT_VOYAGE_REPORT_GENERATE_XLSX: 'ENERGY_MANAGEMENT_VOYAGE_REPORT_GENERATE_XLSX',
    ENERGY_MANAGEMENT_VOYAGE_REPORT_TOGGLE_FULL_SCREEN: 'ENERGY_MANAGEMENT_VOYAGE_REPORT_TOGGLE_FULL_SCREEN',
    ENERGY_MANAGEMENT_VOYAGE_REPORT_RESET_ALL: 'ENERGY_MANAGEMENT_VOYAGE_REPORT_RESET_ALL'
};

export function setMounted(dispatch) {
    dispatch({ type: ActionTypes.ENERGY_MANAGEMENT_VOYAGE_REPORT_SET_MOUNTED });
}

export function generatePDF(dispatch) {
    dispatch({ type: ActionTypes.ENERGY_MANAGEMENT_VOYAGE_REPORT_GENERATE_PDF });
}

export function generateXLSX(dispatch) {
    dispatch({ type: ActionTypes.ENERGY_MANAGEMENT_VOYAGE_REPORT_GENERATE_XLSX });
}

export function toggleFullScreen(dispatch, reportType) {
    dispatch({ type: ActionTypes.ENERGY_MANAGEMENT_VOYAGE_REPORT_TOGGLE_FULL_SCREEN, reportType });
}

export function resetAll(dispatch) {
    dispatch({ type: ActionTypes.ENERGY_MANAGEMENT_VOYAGE_REPORT_RESET_ALL });
}
