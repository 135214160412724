import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import ReportHelper from 'utils/helpers/report-helper';
/* components */
import TableCell from '../../../shared/table-cell';

const emphasizedClass = 'sten-energy-management-reports-preview__mutivoyage-table--emphasized-column';

export default class EnergyManagementReportsPreviewMultiVoyageReportPdfTableRow extends React.PureComponent {
    handleHover = () => {
        if (!this.props.isHighlighted) {
            this.props.onHover(this.props.voyageNumber);
        }
    };

    handleClick = () => this.props.onClick(this.props.voyageNumber);

    render() {
        const ballast = this.props.report.MultivoyageReportDataBallast;
        const laden = this.props.report.MultivoyageReportDataLaden;
        let className = this.props.isHighlighted || this.props.isSelected
            ? 'sten-table__row--highlighted'
            : undefined;
        if (this.props.onClick) {
            className += ' cursor-pointer';
        }

        return (
            <tr
                onMouseMove={this.handleHover}
                onClick={this.handleClick}
                key={this.props.voyageNumber}
                className={className}
            >
                <TableCell id={this.props.voyageNumber}>{this.props.voyageNumber}</TableCell>
                {ballast.ExclusionReason ? (
                    <TableCell
                        id={this.props.voyageNumber}
                        onHover={this.handleHover}
                        colSpan={7}
                    >
                        {ReportHelper.formatCellValue(ballast.ExclusionReason)}
                    </TableCell>
                ) : (
                    <React.Fragment>
                        <TableCell id={this.props.voyageNumber}>
                            {ReportHelper.formatCellValue(ballast.InstructedSpeeds)}
                        </TableCell>
                        <TableCell id={this.props.voyageNumber}>
                            {ReportHelper.formatCellValue(ballast.TotalTime)}
                        </TableCell>
                        <TableCell id={this.props.voyageNumber}>
                            {ReportHelper.formatCellValue(ballast.TotalDistance)}
                        </TableCell>
                        <TableCell id={this.props.voyageNumber}>
                            {ReportHelper.formatCellValue(ballast.TotalMECons)}
                        </TableCell>
                        <TableCell id={this.props.voyageNumber}>
                            {ReportHelper.formatCellValue(ballast.AvgMEConsPerDay)}
                        </TableCell>
                        <TableCell id={this.props.voyageNumber}>
                            {ReportHelper.formatCellValue(ballast.AvgSpeed)}
                        </TableCell>
                        <TableCell id={this.props.voyageNumber}>
                            {ReportHelper.formatCellValue(ballast.TotalAuxCons)}
                        </TableCell>
                    </React.Fragment>
                )}
                {this.props.report.MultivoyageReportDataLoad.ExclusionReason ? (
                    <TableCell
                        id={this.props.voyageNumber}
                        className={emphasizedClass}
                    >
                        {ReportHelper.formatCellValue(this.props.report.MultivoyageReportDataLoad.ExclusionReason)}
                    </TableCell>
                ) : (
                    <TableCell
                        id={this.props.voyageNumber}
                        className={emphasizedClass}
                    >
                        {ReportHelper.formatCellValue(this.props.report.MultivoyageReportDataLoad.TotalCons)}
                    </TableCell>
                )}
                {laden.ExclusionReason ? (
                    <TableCell
                        id={this.props.voyageNumber}
                        colSpan={9}
                    >
                        {ReportHelper.formatCellValue(laden.ExclusionReason)}
                    </TableCell>
                ) : (
                    <React.Fragment>
                        <TableCell id={this.props.voyageNumber}>
                            {ReportHelper.formatCellValue(laden.CargoOnboard)}
                        </TableCell>
                        <TableCell id={this.props.voyageNumber}>
                            {ReportHelper.formatCellValue(laden.CargoHeated)}
                        </TableCell>
                        <TableCell id={this.props.voyageNumber}>
                            {ReportHelper.formatCellValue(laden.InstructedSpeeds)}
                        </TableCell>
                        <TableCell id={this.props.voyageNumber}>
                            {ReportHelper.formatCellValue(laden.TotalTime)}
                        </TableCell>
                        <TableCell id={this.props.voyageNumber}>
                            {ReportHelper.formatCellValue(laden.TotalDistance)}
                        </TableCell>
                        <TableCell id={this.props.voyageNumber}>
                            {ReportHelper.formatCellValue(laden.TotalMECons)}
                        </TableCell>
                        <TableCell id={this.props.voyageNumber}>
                            {ReportHelper.formatCellValue(laden.AvgMEConsPerDay)}
                        </TableCell>
                        <TableCell id={this.props.voyageNumber}>
                            {ReportHelper.formatCellValue(laden.AvgSpeed)}
                        </TableCell>
                        <TableCell id={this.props.voyageNumber}>
                            {ReportHelper.formatCellValue(laden.TotalAuxCons)}
                        </TableCell>
                    </React.Fragment>
                )}
                {this.props.report.MultivoyageReportDataDischarge.ExclusionReason ? (
                    <TableCell id={this.props.voyageNumber} className={emphasizedClass}>
                        {ReportHelper.formatCellValue(this.props.report.MultivoyageReportDataDischarge.ExclusionReason)}
                    </TableCell>
                ) : (
                    <TableCell id={this.props.voyageNumber} className={emphasizedClass}>
                        {ReportHelper.formatCellValue(this.props.report.MultivoyageReportDataDischarge.TotalCons)}
                    </TableCell>
                )}
            </tr>
        );
    }
}

EnergyManagementReportsPreviewMultiVoyageReportPdfTableRow.propTypes = {
    isHighlighted: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    onHover: PropTypes.func.isRequired,
    report: PropTypes.objectOf(PropTypes.any).isRequired,
    voyageNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};
