import { ActionTypes } from './position-list-filters-actions';

const initialState = {
    selectedPort: null,
    companies: [],
    regions: [],
    fleets: [],
    filterUpdated: false
};

export default function positionListFiltersReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.POSITION_LIST_FILTERS_SET_COMPANIES: {
        return {
            ...state,
            companies: action.companies
        };
    }
    case ActionTypes.POSITION_LIST_FILTERS_SET_REGIONS: {
        return {
            ...state,
            regions: action.regions
        };
    }
    case ActionTypes.POSITION_LIST_FILTERS_SET_FLEETS: {
        return {
            ...state,
            fleets: action.fleets
        };
    }
    case ActionTypes.POSITION_LIST_FILTERS_SET_SELECTED_PORT: {
        return {
            ...state,
            selectedPort: action.selectedPort
        };
    }
    case ActionTypes.POSITION_LIST_FILTERS_UPDATED: {
        return {
            ...state,
            filterUpdated: true
        };
    }
    case ActionTypes.POSITION_LIST_FILTERS_UPDATE_LOADED: {
        return {
            ...state,
            filterUpdated: false
        };
    }
    default:
        return state;
    }
}
