import React from 'react';
/* utils */
import ReportHelper from 'utils/helpers/report-helper';
/* components */
import { TankLayout, TankLayoutForm } from 'components/tank-layout';
/* common */
import { t, tu, commonFields, commonData } from '../vessel-report-common-fields';

const emptyArray = [];

export const fieldMap = {

    // Basic info

    Vessel: commonFields.Vessel(),
    CallSign: commonFields.CallSign(),
    VoyageNumber: commonFields.VoyageNumber(),
    CharterpartyDate: commonFields.CharterpartyDate(),
    ReportDate: commonFields.ReportDate(),
    Position: commonFields.Position(),

    // Since last event

    TimeElapsedSinceLastEvent: commonFields.String('TimeElapsedSinceLastEvent', t('TIME_SINCE_LAST_EVENT')),
    TimeAtAnchorSinceLastEvent: commonFields.String('TimeAtAnchorSinceLastEvent', t('TIME_AT_ANCHOR_SINCE_LAST_EVENT')),
    HoursSteamedSinceLastEvent: commonFields.String('HoursSteamedSinceLastEvent', t('HOURS_STEAMED_SINCE_LAST_EVENT')),
    DistanceSinceLastEvent: commonFields.Number(
        'DistanceSinceLastEvent',
        t('DISTANCE_SINCE_LAST_EVENT'),
        2,
        tu('DISTANCE')
    ),
    WindForceSinceLastEvent: commonFields.WindForce('WindForceSinceLastEvent', t('WIND_FORCE_SINCE_LAST_EVENT')),
    TotalCargoSinceLastEvent: commonFields.Number(
        'TotalCargoSinceLastEvent',
        t('TOTAL_CARGO_ONBOARD'),
        0,
        tu('CARGO')
    ),

    // Port information

    PortActivityType: commonFields.Select(
        'PortActivityType',
        t('PORT_ACTIVITY_TYPE'),
        (params, data) => (params.editingOptions.cargoActivityTypes
            ? params.editingOptions.cargoActivityTypes[data.DataSourceId]
            : emptyArray)
    ),
    ShipToShipCargoTransfer: commonFields.Boolean('ShipToShipCargoTransfer', t('SHIP_TO_SHIP_CARGO_TRANSFER')),
    Point: commonFields.Point('Point', t('PORT_OF_DEPARTURE')),
    NextPort: commonFields.Point('NextPort', t('NEXT_PORT')),
    Terminal: commonFields.String('Terminal', t('BERTH_TERMINAL_ANCHORAGE')),
    IsUsingMainEngine: commonFields.Boolean('IsUsingMainEngine', t('USING_MAIN_ENGINE')),
    IsHeated: commonFields.Boolean('IsHeated', t('HEATED')),
    CommencedCargoDate: commonFields.DateTime('CommencedCargoDate', 'CommencedCargoDateOffset', t('COMMENCED_DATE')),
    CompletedCargoDate: commonFields.DateTime('CompletedCargoDate', 'CompletedCargoDateOffset', t('COMPLETED_DATE')),
    QtyLoadedDischarged: commonFields.Number(
        'QtyLoadedDischarged',
        t('QTY_LOADED_DISCHARGED'),
        0,
        tu('CARGO')
    ),
    CalculatedAverageLoadDischargeRate: commonFields.ReadOnly(
        ReportHelper.getAverageLoadDischargeRate,
        t('AVERAGE_LOAD_DISCHARGE_RATE'),
        2,
        tu('LOAD_DISCHARGE_RATE'),
        t('AVERAGE_LOAD_DISCHARGE_RATE_TITLE')
    ),
    LoadDischargeRateMaxFromTerminal: commonFields.Number(
        'LoadDischargeRateMaxFromTerminal',
        t('LOAD_DISCHARGE_RATE_MAX'),
        0,
        tu('CUBIC_CAPACITY')
    ),
    AverageLoadDischargeRate: commonFields.Number(
        'AverageLoadDischargeRate',
        t('LOAD_DISCHARGE_RATE_AVG'),
        0,
        tu('CUBIC_CAPACITY')
    ),
    PumpPressureMaxFromTerminal: commonFields.Number(
        'PumpPressureMaxFromTerminal',
        t('PUMP_PRESSURE_MAX'),
        0,
        tu('PRESSURE')
    ),
    PumpPressureReached: commonFields.Number(
        'PumpPressureReached',
        t('PUMP_PRESSURE_REACHED'),
        0,
        tu('PRESSURE')
    ),

    // Remarks

    Remarks: commonFields.Remarks()
};

export const dataMap = {
    ConsumptionsSinceLastEvent: commonData.ConsumptionFuelData('ConsumptionsSinceLastEvent', 6),
    BunkerData: commonData.BunkerFuelData('BunkerData'),
    Consumptions: commonData.ConsumptionFuelData('Consumptions', 1),
    LoadConsumptions: commonData.ConsumptionFuelData('Consumptions', 1),
    DischargeOperations: commonData.ConsumptionFuelData('DischargeOperations', 7),
    Tanks: (params) => {
        if (params.isEditing) {
            return (
                <TankLayoutForm
                    noOfWings={params.data.NoOfWings}
                    noOfWingsOptions={params.editingOptions.noOfWingsOptions}
                    cargoTankStateOptions={params.editingOptions.cargoTankStateOptions}
                    slopTankStateOptions={params.editingOptions.slopTankStateOptions}
                    wings={params.data.Tanks}
                    onChange={params.onChange}
                    validationRules={params.validationRules}
                />
            );
        }
        return <TankLayout wings={params.data.Tanks} isCollapsible />;
    }
};

export default { fieldMap, dataMap };
