import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* helpers */
import { formatNumber } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* components */
import TextHighlight from 'components/text-highlight/text-highlight';
import Gauge from 'components/gauge/gauge';

export class FleetRangeVessel extends React.PureComponent {
    handleVesselTo = () => {
        const { vessel, router } = this.props;
        if (vessel.IMO && (!router.query.vessel || router.query.vessel !== vessel.IMO.toString())) {
            return router.getLinkTo({
                remove: [
                    'port',
                    'reportId',
                    'reportType',
                    'reportEdit',
                    'questionnaireId',
                    'questionnaireEdit',
                    'voyage',
                    'terminal',
                    'berth',
                    'terminalUpdate',
                    'berthUpdate',
                    'terminalsShown'
                ],
                add: { vessel: vessel.IMO }
            });
        }
        return router.location;
    }

    render() {
        const { vessel } = this.props;
        return (
            <Link className="sten-fleet-range-vessel btn-section flex" to={this.handleVesselTo()}>
                <div className="flex-grow flex-row flex-center">
                    <div className="flex-shrink">
                        <Gauge
                            value={vessel.ROBFuelRangeDays}
                            breakpoints={[8, 13]}
                            max={21}
                            valueUnit={vessel.ROBFuelRangeDays === 1 ? 'day' : 'days'}
                            tooltipTitle={vessel.ROBFuelRangeDays === 1
                                ? t('FLEET_RANGE.HSFO_DAY_LEFT')
                                : t('FLEET_RANGE.HSFO_DAYS_LEFT', { days: vessel.ROBFuelRangeDays })
                            }
                        />
                    </div>
                    <div className="col-15">
                        <h3 className="text-primary text-uppercase">
                            <TextHighlight input={vessel.Title} highlight={this.props.searchValue} />
                        </h3>
                        <h6 className="text-secondary">
                            {t('FLEET_RANGE.REPORTED_ON')}
                            {vessel.ReportDate ? TimeHelper.getFormatted(vessel.ReportDate) : '-'}
                        </h6>
                        {vessel.MissingDataMessage && (
                            <h6 key="message2" className="sten-fleet-range-vessel__message">
                                {vessel.MissingDataMessage}
                            </h6>
                        )}
                    </div>
                    <div className="col-9">
                        <label className="sten-panel__label text-nowrap">
                            {`${t('FLEET_RANGE.FO_ROB')}: `}
                            <span className="sten-panel__label--value">
                                {formatNumber(vessel.BunkerROBFuel, 2) || '-'}
                            </span>
                        </label>
                        <label className="sten-panel__label text-nowrap">
                            {`${t('FLEET_RANGE.GO_ROB')}: `}
                            <span className="sten-panel__label--value">
                                {formatNumber(vessel.BunkerROBGas, 2) || '-'}
                            </span>
                        </label>
                        <label className="sten-panel__label text-nowrap">
                            {`${t('FLEET_RANGE.LG_ROB')}: `}
                            <span className="sten-panel__label--value">
                                {formatNumber(vessel.BunkerROBLiquidGas, 2) || '-'}
                            </span>
                        </label>
                    </div>
                </div>
            </Link>
        );
    }
}

FleetRangeVessel.propTypes = {
    router: TRouter.isRequired,
    searchValue: PropTypes.string,
    vessel: PropTypes.objectOf(PropTypes.any).isRequired
};

FleetRangeVessel.defaultProps = {
    searchValue: ' '
};

export default withRouter(FleetRangeVessel);
