import { ActionTypes } from './dtn-weather-actions';

const emptyArray = [];

const initialState = {
    availableLayers: emptyArray,
    layerTimestamps: null,
    layerData: null,
    selectedTimestamp: null,
    roundedTimestamp: null
};

export default function dtnWeatherReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.DTN_WEATHER_SET_AVAILABLE_LAYERS: {
        return {
            ...state,
            availableLayers: action.payload || emptyArray
        };
    }
    case ActionTypes.DTN_WEATHER_SET_LAYER_DATA: {
        return {
            ...state,
            layerData: action.payload || null
        };
    }
    case ActionTypes.DTN_WEATHER_SET_LAYER_TIMESTAMPS: {
        return {
            ...state,
            layerTimestamps: action.timestamps || state.layerTimestamps,
            selectedTimestamp: action.selectedTimestamp,
            roundedTimestamp: action.roundedTimestamp
        };
    }
    default:
        return state;
    }
}
