import React from 'react';
import PropTypes from 'prop-types';

export default class AfterInitialMount extends React.PureComponent {
    state = { isInitialMount: true };

    componentDidMount() {
        window.requestAnimationFrame(() => this.setState({ isInitialMount: false }));
    }

    componentWillUnmount() {
        this.setState = () => {};
    }

    render() {
        return this.state.isInitialMount ? null : this.props.children;
    }
}

AfterInitialMount.propTypes = {
    children: PropTypes.node.isRequired
};
