import React from 'react';
import PropTypes from 'prop-types';
/* styles */
import './input.scss';

export default class HtecDPInputButton extends React.Component {
    renderPlaceholder = (text) => (
        <div className="htec-dp-input__value htec-dp-input__placeholder">{text}</div>
    );

    renderDateField = (text) => (
        <div className="htec-dp-input__value">{text}</div>
    );

    render() {
        const p = this.props;

        let className = 'htec-dp-input';
        if (p.className) {
            className += ` ${p.className}`;
        }
        if (p.isActive) {
            className += ' htec-dp-input--active';
        }
        if (p.isDisabled) {
            className += ' htec-dp-input--disabled';
        }
        if (p.isInvalid) {
            className += ' htec-dp-input--invalid';
        }

        return (
            <div className={className} onClick={!p.isDisabled && p.onClick}>
                {(p.date && this.renderDateField(p.date)) || this.renderPlaceholder(p.placeholder)}
                <div className="htec-dp-input__icon">{p.icon}</div>
            </div>
        );
    }
}

HtecDPInputButton.propTypes = {
    className: PropTypes.string,
    date: PropTypes.string,
    icon: PropTypes.node,
    isActive: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isInvalid: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    placeholder: PropTypes.string
};

HtecDPInputButton.defaultProps = {
    className: '',
    date: '',
    icon: null,
    isActive: false,
    isDisabled: false,
    isInvalid: false,
    placeholder: ''
};
