import { ActionTypes } from './global-search-actions';

const initialState = {
    searchResults: null,
    isFocused: false,
    isDisabled: true
};

export default function globalSearchReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.GLOBAL_SEARCH_SET_RESULTS: {
        return {
            ...state,
            searchResults: action.searchResults
        };
    }
    case ActionTypes.GLOBAL_SEARCH_TOGGLE_FOCUS: {
        if (state.isDisabled) {
            return state;
        }
        return {
            ...state,
            isFocused: typeof (action.isFocused) === 'boolean' ? action.isFocused : !state.isFocused
        };
    }
    case ActionTypes.GLOBAL_SEARCH_SET_DISABLED: {
        return {
            ...state,
            isDisabled: action.isDisabled
        };
    }
    default:
        return state;
    }
}
