import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import {
    renderDataProps,
    renderFuelData,
    formatValue,
    fleetPerformanceReportTableHeaderTypeA
} from '../fleet-performance-report-helpers';
/* components */
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
/* constants */
import {
    cargoProps,
    emissionProps,
    otherProps,
    colWidths,
    fuelColWidth
} from './fleet-performance-report-type-a-constants';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.FLEET_PERFORMANCE_REPORT.${key}`);

class FleetPerformanceReportTypeATable extends React.PureComponent {
    render() {
        const { performancePerVoyage: { fuelTypes, voyages, vesselName, vesselImo } } = this.props;
        const seaConsCols = fuelTypes.sea.length;
        const portConsCols = fuelTypes.port.length;
        const fuelColsWidth = ((seaConsCols * fuelColWidth.web) || 4) + ((portConsCols * fuelColWidth.web) || 4);
        const contentMinWidth = parseInt(
            Object.keys(colWidths).reduce((sum, key) => sum + colWidths[key].web, 0) + fuelColsWidth, 10
        );

        return (
            <FixedHeaderTable
                className="sten-energy-management-reports-preview__fleet-performance-per-voyage-table"
                contentStyle={{ minWidth: `${contentMinWidth}rem` }}
                updateTimeout={200}
                withHeaderColumn
                useCSS3Translate={false}
            >
                <table className="sten-table sten-table--xs">
                    {fleetPerformanceReportTableHeaderTypeA(
                        fuelTypes, colWidths, fuelColWidth, emissionProps, t('VOYAGE_NUMBER'), 'modal'
                    )}
                    <tbody key={`voyage.${vesselName}${vesselImo}`}>
                        {voyages.map(voyage => (
                            <tr key={`voyage.${voyage.VoyageNumber}`}>
                                <td>{voyage.VoyageNumber}</td>
                                {renderDataProps(voyage.Total, cargoProps)}
                                {renderFuelData(voyage.Total.ConsumptionsAtSea, fuelTypes.sea, otherProps)}
                                <td>{formatValue(voyage.Total.TotalConsumptionAtSea,
                                    otherProps.TotalConsumptionAtSea.decimals)}
                                </td>
                                {renderFuelData(voyage.Total.ConsumptionsInPort, fuelTypes.port, otherProps)}
                                <td>{formatValue(voyage.Total.TotalConsumptionInPort,
                                    otherProps.TotalConsumptionInPort.decimals)}
                                </td>
                                {renderDataProps(voyage.Total.Emissions, emissionProps)}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </FixedHeaderTable>
        );
    }
}

FleetPerformanceReportTypeATable.propTypes = {
    performancePerVoyage: PropTypes.shape({
        fuelTypes: PropTypes.objectOf(PropTypes.any),
        voyages: PropTypes.arrayOf(PropTypes.any),
        vesselName: PropTypes.string,
        vesselImo: PropTypes.number
    })
};

FleetPerformanceReportTypeATable.defaultProps = {
    performancePerVoyage: null
};

export default FleetPerformanceReportTypeATable;
