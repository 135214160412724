import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { formatCoordinates, parseCoordinates } from 'utils/helpers/info-helper';
import { wktParse } from 'components/ol/ol-helpers';
/* components */
import Select from 'components/select/select';
import TextHighlight from 'components/text-highlight/text-highlight';
/* Services */
import PortService from 'services/core-api/port-service';

const emptyArray = [];

class PortSelect extends React.PureComponent {
    state = {
        points: emptyArray
    };

    onFocus() {
        this.select.onOuterDivFocus();
    }

    static getCustomPoint(searchCriteria) {
        const coordinates = parseCoordinates(searchCriteria);
        if (coordinates === null) {
            return null;
        }
        return {
            Name: formatCoordinates(coordinates[0], coordinates[1]),
            Coordinates: coordinates,
            Id: 'CustomPoint',
            Type: 3,
            IsCustomPoint: true,
            Subtitles: formatCoordinates(coordinates[0], coordinates[1], 2)
        };
    }

    parseAndSetPoints = (res, searchCriteria) => {
        let points = emptyArray;
        let customPoint = null;
        if (this.props.allowCustomPoint) {
            customPoint = PortSelect.getCustomPoint(searchCriteria);
        }
        if (res && res.length) {
            points = res.map((point) => {
                const subtitleArray = [];
                if (point.Country) {
                    subtitleArray.push(point.Country);
                }
                if (point.Location) {
                    const pointLocation = wktParse(point.Location);
                    subtitleArray.push(formatCoordinates(pointLocation[0], pointLocation[1]));
                }
                return {
                    ...point,
                    Id: point.Id.toString(),
                    Subtitles: subtitleArray.join(', ')
                };
            });
        }
        if (customPoint) {
            this.setState({ points: [customPoint, ...points] });
        } else {
            this.setState({ points });
        }
    };

    getPorts = searchCriteria => {
        if (searchCriteria) {
            let pointTypes = [];
            if (this.props.excludePoints) {
                pointTypes = [2];
            }
            if (this.props.searchForAll) {
                PortService.searchByCriteriaForAll(searchCriteria, this.props.searchLimit)
                    .then((res) => this.parseAndSetPoints(res, searchCriteria));
            } else {
                PortService.searchByCriteria(searchCriteria, this.props.searchLimit, pointTypes)
                    .then((res) => this.parseAndSetPoints(res, searchCriteria));
            }
        }
    };

    getPortOption = (point, searchCriteria) => {
        return (
            <React.Fragment>
                <h3 className="sten-select__option-title">
                    {searchCriteria
                        ? <TextHighlight input={point.Name} highlight={searchCriteria} />
                        : point.Name
                    }
                </h3>
                <p className="sten-select__option-subtitle">
                    {point.Subtitles}
                </p>
                {point && point.Aliases && point.Aliases.length > 0 && (
                    <p className="sten-select__option-subtitle text-nowrap text-ellipsis">
                        {searchCriteria
                            ? <TextHighlight input={point.Aliases.join(', ')} highlight={searchCriteria} />
                            : point.Aliases.join(', ')
                        }
                    </p>
                )}
            </React.Fragment>
        );
    };

    render() {
        return (
            <Select
                ref={(c) => { this.select = c; }}
                {...this.props}
                searchable
                setSearchCriteriaOnFocus={!!(this.props.value && this.props.value.Type === 3)}
                onSearch={this.getPorts}
                optionRenderer={this.getPortOption}
                valueKey="Id"
                labelKey="Name"
                options={this.state.points}
            />
        );
    }
}

PortSelect.propTypes = {
    ...Select.propTypes,
    allowCustomPoint: PropTypes.bool,
    className: PropTypes.string,
    clearable: PropTypes.bool,
    collapseDown: PropTypes.bool,
    disabled: PropTypes.bool,
    excludePoints: PropTypes.bool,
    fixedListWidth: PropTypes.bool,
    includeSearchInResults: PropTypes.bool,
    invalid: PropTypes.bool,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    searchForAll: PropTypes.bool,
    searchLimit: PropTypes.number,
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)])
};

PortSelect.defaultProps = {
    ...Select.defaultProps,
    allowCustomPoint: false,
    className: '',
    clearable: false,
    collapseDown: null,
    disabled: false,
    excludePoints: false,
    fixedListWidth: false,
    includeSearchInResults: false,
    invalid: false,
    multiple: false,
    name: '',
    placeholder: '',
    searchForAll: false,
    searchLimit: 10,
    title: '',
    value: null
};

export default PortSelect;
