import moment from 'moment';
import { toast } from 'react-toastify';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { roundNumber } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
import {
    getVesselStartingPointByType,
    getStartingPositionOptionsForVessel,
    getNewPoint
} from './estimator-reducer-helpers';
import { defaultVesselType } from 'components/euets/euets-helpers';
/* actions */
import { ActionTypes } from './estimator-actions';
import { areaOptions, canalOptions, startingPositionOptions } from './estimator-constants';

const estimationPropsReset = {
    Distance: null,
    DistanceNonSeca: null,
    DistanceSeca: null,
    EstimatedConsumption: null,
    EstimatedConsumptionHsfo: null,
    EstimatedConsumptionLsgo: null,
    SteamingTime: null,
    Time: null,
    WaitingTime: null
};

const emptyArray = [];

const initialState = {
    maxFuturePoints: 10,
    activePinPointId: null,
    calculationVesselPosition: null,
    calculationVesselNotifications: emptyArray,
    calculationPointsChanged: false,
    calculationPoints: emptyArray,
    calculationVoyage: null,
    calculationVoyageAisPoints: null,
    calculationVoyageRoute: null,
    calculationVoyageRoutePoints: null,
    calculationVoyageItinerary: emptyArray,
    calculationRoutePoints: null,
    filters: {
        calculationVessel: null,
        calculationVesselStartingPosition: null,
        selectedCanals: canalOptions,
        selectedAreas: emptyArray,
        defaultSpeed: 12.5,
        selectedEcaBunker: null,
        selectedNonEcaBunker: null,
        defaultWeatherFactor: 0,
        selectedEuEtsVesselType: defaultVesselType
    },
    options: {
        canalOptions,
        startingPositionOptions,
        areaOptions,
        bunkerOptions: emptyArray
    },
    startingPoint: {
        IsBallast: true,
        IsExpanded: true,
        Point: null,
        IsChanged: false,
        TimeInPort: '',
        EtaUpdated: false,
        Speed: null,
        SpeedUpdated: false,
        WeatherFactor: null,
        Eta: null,
        Atd: null,
        Etd: null,
        CalculationPointId: 'S'
    },
    isCalculated: false,
    isRequestSent: false,
    currentCount: 0,
    calculations: null,
    calculationEuEts: null,
    bunkerROB: emptyArray,
    bunkerROBDate: null
};

export default function estimatorReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ESTIMATOR_SET_POINTS: {
        if (action.points) {
            const calculationPoints = [];
            let index = 0;
            let futurePoints = 0;
            action.points.forEach((point) => {
                const timeInPort = typeof point.TimeInPort === 'number'
                    ? point.TimeInPort
                    : moment(point.Etd).diff(moment(point.Eta));
                const speed = point.Speed ? roundNumber(point.Speed, 1) : null;
                let weatherFactor = null;
                if (!point.Ata) {
                    weatherFactor = typeof point.WeatherFactor === 'number'
                        ? point.WeatherFactor
                        : state.filters.defaultWeatherFactor;
                }
                if (!point.Atd) {
                    futurePoints++;
                }
                calculationPoints.push({
                    ...point,
                    EtaUpdated: false,
                    SpeedUpdated: false,
                    Speed: speed,
                    WeatherFactor: weatherFactor,
                    ConsumptionSpeed: speed || state.filters.defaultSpeed,
                    TimeInPort: TimeHelper.getStringFromDuration(timeInPort, ['d', 'h', 'm']),
                    Time: point.Time
                        ? TimeHelper.getStringFromDuration(point.Time, ['d', 'h'])
                        : null,
                    IsSpeedSelected: point.IsSpeedSelected || false,
                    IsChanged: false,
                    IsBallast: point.IsBallast || false,
                    CalculationPointId: index
                });
                index++;
            });
            if (futurePoints < initialState.maxFuturePoints) {
                calculationPoints.push(getNewPoint(
                    index,
                    state.filters.defaultSpeed,
                    state.filters.defaultWeatherFactor
                ));
            }
            return {
                ...state,
                startingPoint: { ...state.startingPoint, IsChanged: false },
                calculationPoints,
                calculationPointsChanged: false,
                isCalculated: action.isCalculated || false,
                currentCount: index + 1
            };
        }
        return state;
    }
    case ActionTypes.ESTIMATOR_SET_BUNKER_OPTIONS_AND_INITIAL_VALUES: {
        return {
            ...state,
            options: {
                ...state.options,
                bunkerOptions: action.options.bunkerOptions
            },
            filters: {
                ...state.filters,
                selectedEcaBunker: action.values.selectedEcaBunker,
                selectedNonEcaBunker: action.values.selectedNonEcaBunker
            }
        };
    }
    case ActionTypes.ESTIMATOR_SET_CALCULATION_VESSEL: {
        const {
            startingPositionOptions,
            startingPoint,
            calculationVesselStartingPosition
        } = getStartingPositionOptionsForVessel(
            initialState.options.startingPositionOptions, action.vessel, action.voyage, state.startingPoint
        );
        let calculationPoints = [];
        let currentCount = state.currentCount;
        if (state.calculationPoints
            && state.calculationPoints.length > 0
            && calculationVesselStartingPosition
            && calculationVesselStartingPosition.value !== 3) {
            state.calculationPoints.forEach((point) => {
                if (!point.Ata) {
                    calculationPoints.push({
                        ...point,
                        estimationPropsReset
                    });
                }
            });
        } else {
            calculationPoints = [
                getNewPoint(state.currentCount, state.filters.defaultSpeed, state.filters.defaultWeatherFactor)
            ];
            currentCount++;
        }
        let vesselProps = {
            defaultSpeed: initialState.filters.defaultSpeed,
            selectedCanals: initialState.options.canalOptions
        };
        if (action.vessel) {
            vesselProps = {
                defaultSpeed: action.vessel.Vessel.CalculationSpeed,
                selectedCanals: state.options.canalOptions.filter((canal) => action.vessel.Vessel[canal.prop])
            };
        }

        return {
            ...state,
            filters: {
                ...state.filters,
                calculationVessel: action.vessel,
                calculationVesselStartingPosition,
                defaultSpeed: vesselProps.defaultSpeed,
                selectedCanals: vesselProps.selectedCanals
            },
            calculationVesselPosition: action.position,
            calculationVesselNotifications: action.notifications,
            calculationVoyage: action.voyage,
            calculationVoyageItinerary: initialState.calculationVoyageItinerary,
            calculationVoyageAisPoints: initialState.calculationVoyageAisPoints,
            calculationVoyageRoutePoints: initialState.calculationVoyageRoutePoints,
            calculationVoyageRoute: initialState.calculationVoyageRoute,
            options: {
                ...state.options,
                startingPositionOptions
            },
            startingPoint,
            calculationPoints,
            calculationPointsChanged: true,
            calculationRoutePoints: null,
            calculations: null,
            calculationEuEts: null,
            currentCount
        };
    }
    case ActionTypes.ESTIMATOR_ADD_POINT: {
        const futurePoints = state.calculationPoints.filter(point => !point.Ata);
        if (futurePoints.length >= state.maxFuturePoints) {
            toast(
                translate('CALCULATION_MESSAGE.MAXIMUM_FUTURE_POINTS', { max: state.maxFuturePoints }),
                { type: toast.TYPE.WARNING }
            );
            return state;
        }
        const calculationPoints = state.calculationPoints.slice();
        calculationPoints.splice(
            action.index,
            0,
            getNewPoint(state.currentCount, state.filters.defaultSpeed, state.filters.defaultWeatherFactor)
        );
        if (calculationPoints[action.index + 1]) {
            calculationPoints.splice(action.index + 1, 1, {
                ...calculationPoints[action.index + 1],
                ...estimationPropsReset
            });
        }
        return {
            ...state,
            calculationPoints,
            calculationPointsChanged: true,
            calculationRoutePoints: null,
            calculations: null,
            calculationEuEts: null,
            currentCount: state.currentCount + 1
        };
    }
    case ActionTypes.ESTIMATOR_MOVE_POINT: {
        if (action.toIndex !== action.fromIndex) {
            const calculationPoints = [];
            state.calculationPoints.forEach((point, index) => {
                if (action.toIndex === index && action.toIndex < action.fromIndex) {
                    calculationPoints.push({
                        ...state.calculationPoints[action.fromIndex],
                        ...estimationPropsReset
                    });
                }
                if (action.fromIndex !== index) {
                    if (index > action.fromIndex || index > action.toIndex) {
                        calculationPoints.push({
                            ...point,
                            ...estimationPropsReset
                        });
                    } else {
                        calculationPoints.push(point);
                    }
                }
                if (action.toIndex === index && action.toIndex > action.fromIndex) {
                    calculationPoints.push({
                        ...state.calculationPoints[action.fromIndex],
                        ...estimationPropsReset
                    });
                }
            });
            return {
                ...state,
                calculationPoints,
                calculationPointsChanged: true,
                calculationRoutePoints: null,
                calculations: null,
                calculationEuEts: null,
                currentCount: state.currentCount + 1
            };
        }
        return state;
    }
    case ActionTypes.ESTIMATOR_REMOVE_POINT: {
        const calculationPoints = state.calculationPoints.slice();
        let activePinPointId = state.activePinPointId;
        if (calculationPoints[action.index].CalculationPointId === state.activePinPointId) {
            activePinPointId = null;
        }
        if (calculationPoints[action.index + 1]) {
            calculationPoints.splice(action.index, 2, {
                ...calculationPoints[action.index + 1],
                ...estimationPropsReset
            });
        } else {
            calculationPoints.splice(action.index, 1);
        }
        return {
            ...state,
            calculationPoints,
            calculationRoutePoints: null,
            calculationPointsChanged: true,
            calculations: null,
            calculationEuEts: null,
            activePinPointId
        };
    }
    case ActionTypes.ESTIMATOR_UPDATE_POINT: {
        const calculationPoints = state.calculationPoints.slice();
        const foundPoint = calculationPoints[action.index];
        calculationPoints.splice(action.index, 1, { ...foundPoint, ...action.point, IsChanged: true });
        if (action.point.Point && calculationPoints[action.index + 1]) {
            calculationPoints.splice(action.index + 1, 1, {
                ...calculationPoints[action.index + 1],
                ...estimationPropsReset
            });
            return {
                ...state,
                calculationPoints,
                calculationRoutePoints: null,
                activePinPointId: null,
                calculationPointsChanged: true,
                calculations: null,
                calculationEuEts: null
            };
        }
        return {
            ...state,
            calculationPoints,
            activePinPointId: null,
            calculationPointsChanged: true,
            calculations: null,
            calculationEuEts: null
        };
    }
    case ActionTypes.ESTIMATOR_UPDATE_STARTING_POINT: {
        const startingPoint = { ...state.startingPoint, ...action.point, IsChanged: true };
        if (action.point.Eta === '' && state.calculationPoints && state.calculationPoints.length > 0) {
            const calculationPoints = state.calculationPoints.map(point => (
                {
                    ...point,
                    IsSpeedSelected: true,
                    IsChanged: !point.IsSpeedSelected
                }
            ));
            return {
                ...state,
                calculationPoints,
                startingPoint,
                activePinPointId: null,
                calculationPointsChanged: true,
                calculations: null,
                calculationEuEts: null
            };
        }
        if (action.point.Point && state.calculationPoints && state.calculationPoints.length > 0) {
            const calculationPoints = state.calculationPoints.slice();
            const firstFuturePointIndex = calculationPoints.findIndex(point => !point.Ata);
            calculationPoints.splice(firstFuturePointIndex, 1, {
                ...calculationPoints[firstFuturePointIndex],
                ...estimationPropsReset
            });
            return {
                ...state,
                startingPoint,
                activePinPointId: null,
                calculationRoutePoints: null,
                calculationPoints,
                calculationPointsChanged: true,
                calculations: null,
                calculationEuEts: null
            };
        }
        return {
            ...state,
            startingPoint,
            activePinPointId: null,
            calculationPointsChanged: true,
            calculations: null,
            calculationEuEts: null
        };
    }
    case ActionTypes.ESTIMATOR_SET_ROUTE_POINTS: {
        return {
            ...state,
            calculationRoutePoints: action.routePoints
        };
    }
    case ActionTypes.ESTIMATOR_SET_VESSEL_STARTING_POSITION: {
        let startingPoint = { ...initialState.startingPoint, IsChanged: true };
        if (state.filters.calculationVessel) {
            if (action.startingPosition.value === 2 && state.filters.calculationVessel.NextOpenPort) {
                startingPoint = getVesselStartingPointByType(state.filters.calculationVessel, state.startingPoint, 2);
            } else if (action.startingPosition.value === 1 && state.filters.calculationVessel.Location) {
                startingPoint = getVesselStartingPointByType(state.filters.calculationVessel, state.startingPoint, 1);
            }
        }
        if ((!state.filters.calculationVesselStartingPosition
                || action.startingPosition.value !== state.filters.calculationVesselStartingPosition.value)
                && state.calculationPoints && state.calculationPoints.length > 0) {
            let calculationPoints;
            let currentCount = state.currentCount;
            if (state.filters.calculationVesselStartingPosition.value === 3) {
                calculationPoints = [getNewPoint(
                    currentCount,
                    state.filters.defaultSpeed,
                    state.filters.defaultWeatherFactor
                )];
                currentCount++;
            } else {
                calculationPoints = state.calculationPoints.slice();
                const firstFuturePointIndex = calculationPoints.findIndex(point => !point.Ata);
                calculationPoints.splice(firstFuturePointIndex, 1, {
                    ...calculationPoints[firstFuturePointIndex],
                    ...estimationPropsReset
                });
            }
            return {
                ...state,
                filters: {
                    ...state.filters,
                    calculationVesselStartingPosition: action.startingPosition
                },
                startingPoint,
                calculationPoints,
                calculationRoutePoints: null,
                calculationPointsChanged: true,
                calculations: null,
                calculationEuEts: null,
                currentCount
            };
        }
        return {
            ...state,
            filters: {
                ...state.filters,
                calculationVesselStartingPosition: action.startingPosition
            },
            startingPoint,
            calculationPointsChanged: true,
            calculations: null,
            calculationEuEts: null
        };
    }
    case ActionTypes.ESTIMATOR_REQUEST_SENT: {
        return {
            ...state,
            isRequestSent: true
        };
    }
    case ActionTypes.ESTIMATOR_SET_CALCULATIONS: {
        return {
            ...state,
            calculations: action.response.Calculations,
            bunkerROB: action.response.BunkerROB,
            bunkerROBDate: action.response.BunkerROBDate
        };
    }
    case ActionTypes.ESTIMATOR_SET_CALCULATION_EU_ETS: {
        return {
            ...state,
            calculationEuEts: action.calculationEuEts || null
        };
    }
    case ActionTypes.ESTIMATOR_SET_ACTIVE_PIN_POINT_ID: {
        return {
            ...state,
            activePinPointId: state.activePinPointId === action.activePinPointId
                ? null
                : action.activePinPointId
        };
    }
    case ActionTypes.ESTIMATOR_SET_VOYAGE_ITINERARY: {
        return { ...state, calculationVoyageItinerary: action.voyageItinerary };
    }
    case ActionTypes.ESTIMATOR_SET_VOYAGE_AIS_POINTS: {
        return { ...state, calculationVoyageAisPoints: action.voyageAisPoints };
    }
    case ActionTypes.ESTIMATOR_SET_VOYAGE_ROUTE: {
        return {
            ...state,
            calculationVoyageRoutePoints: action.voyageRoutePoints,
            calculationVoyageRoute: action.voyageRoute
        };
    }
    case ActionTypes.ESTIMATOR_RESET_CALCULATION_VOYAGE: {
        return {
            ...state,
            calculationVoyageItinerary: initialState.calculationVoyageItinerary,
            calculationVoyageAisPoints: initialState.calculationVoyageAisPoints,
            calculationVoyageRoutePoints: initialState.calculationVoyageRoutePoints,
            calculationVoyageRoute: initialState.calculationVoyageRoute
        };
    }
    case ActionTypes.ESTIMATOR_RESET: {
        const selectedEca = state.options.bunkerOptions.find(opt => opt.Default && opt.ECA);
        const selectedNonEca = state.options.bunkerOptions.find(opt => opt.Default && !opt.ECA);
        return {
            ...initialState,
            options: { ...state.options },
            filters: {
                ...initialState.filters,
                selectedEcaBunker: selectedEca || null,
                selectedNonEcaBunker: selectedNonEca || null
            }
        };
    }
    case ActionTypes.ESTIMATOR_SET_FILTERS: {
        const sharedStateProps = {
            ...state,
            calculationPointsChanged: true,
            calculations: null,
            calculationEuEts: null
        };

        if (action.name === 'defaultSpeed') {
            const calculationPoints = state.calculationPoints.map((calculationPoint) => {
                if (calculationPoint.IsSpeedSelected) {
                    return { ...calculationPoint, Eta: null };
                }
                return calculationPoint;
            });
            return {
                ...sharedStateProps,
                calculationPoints,
                filters: {
                    ...state.filters,
                    defaultSpeed: action.value
                }
            };
        }

        if (action.name === 'defaultWeatherFactor') {
            const calculationPoints = state.calculationPoints.map((calculationPoint) => {
                return { ...calculationPoint, WeatherFactor: action.value };
            });
            return {
                ...sharedStateProps,
                calculationPoints,
                filters: {
                    ...state.filters,
                    defaultWeatherFactor: action.value
                }
            };
        }
        return {
            ...sharedStateProps,
            calculationRoutePoints: null,
            filters: {
                ...state.filters,
                [action.name]: action.value
            }
        };
    }
    default:
        return state;
    }
}
