export const ActionTypes = {
    MAP_DATE_PICKER_UPDATE_DATE: 'MAP_DATE_PICKER_UPDATE_DATE',
    MAP_DATE_PICKER_ADD_HOURS: 'MAP_DATE_PICKER_ADD_HOURS',
    MAP_DATE_PICKER_SET_DATE: 'MAP_DATE_PICKER_SET_DATE',
    MAP_DATE_PICKER_ENABLE: 'MAP_DATE_PICKER_ENABLE',
    MAP_DATE_PICKER_SET_MOUNTED: 'MAP_DATE_PICKER_SET_MOUNTED',
    MAP_DATE_PICKER_RESET: 'MAP_DATE_PICKER_RESET'
};

export function updateDate(dispatch, date, forceUpdate) {
    dispatch({
        type: ActionTypes.MAP_DATE_PICKER_UPDATE_DATE,
        date,
        forceUpdate
    });
}

export function addHours(dispatch, hours) {
    dispatch({
        type: ActionTypes.MAP_DATE_PICKER_ADD_HOURS,
        hours
    });
}

export function resetDate(dispatch) {
    dispatch({
        type: ActionTypes.MAP_DATE_PICKER_RESET
    });
}

export function enable(dispatch, isEnabled) {
    dispatch({
        type: ActionTypes.MAP_DATE_PICKER_ENABLE,
        isEnabled
    });
}

export function setMounted(dispatch, isMounted) {
    dispatch({
        type: ActionTypes.MAP_DATE_PICKER_SET_MOUNTED,
        isMounted
    });
}
