import React from 'react';
import PropTypes from 'prop-types';
/* styles */
import './map-zoom-controls.scss';

class MapZoomControls extends React.PureComponent {
    render() {
        const { className } = this.props;
        return (
            <div
                id="stenMapZoomControls"
                className={`sten-map-zoom-controls-wrapper${className ? ` ${className}` : ''}`}
            />
        );
    }
}

MapZoomControls.defaultProps = {
    className: ''
};

MapZoomControls.propTypes = {
    className: PropTypes.string
};

export default MapZoomControls;
