import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { getSpeedSeverity, getConsumptionSeverity, formatValue } from 'utils/helpers/info-helper';
/* components */
import Accordion from 'components/accordion/accordion';

const config = {
    AvgSpeed: {
        label: t('VESSEL_INFO.ENERGY.REPORTER'),
        key: 'report.AvgSpeed',
        type: 'number',
        unit: t('UNITS.SPEED'),
        getSeverity: getSpeedSeverity,
        severityKey: 'InstructedSpeed'
    },
    InstructedSpeed: {
        label: t('VESSEL_INFO.ENERGY.INSTRUCTED'),
        key: 'report.InstructedSpeed',
        type: 'number',
        unit: t('UNITS.SPEED')
    },
    SensorSpeed: {
        label: t('VESSEL_INFO.ENERGY.SENSOR'),
        key: 'sensorData.AverageSpeed',
        type: 'number',
        unit: t('UNITS.SPEED'),
        getSeverity: getSpeedSeverity,
        severityKey: 'InstructedSpeed'
    },
    TotalConsumption: {
        label: t('VESSEL_INFO.ENERGY.REPORTER'),
        key: 'report.TotalConsumption',
        type: 'number',
        unit: t('UNITS.AVG_CONSUMPTION'),
        getSeverity: getConsumptionSeverity,
        severityKey: 'Budget'
    },
    Budget: {
        label: t('VESSEL_INFO.ENERGY.BUDGET'),
        key: 'report.Budget',
        type: 'number',
        unit: t('UNITS.AVG_BUDGET')
    },
    SensorConsumption: {
        label: t('VESSEL_INFO.ENERGY.SENSOR'),
        key: 'sensorData.Consumption',
        type: 'number',
        unit: t('UNITS.AVG_CONSUMPTION'),
        getSeverity: getConsumptionSeverity,
        severityKey: 'Budget'
    },
    Wind: {
        label: t('VESSEL_INFO.ENERGY.REPORTER'),
        key: 'report.WindForce',
        type: 'number',
        unit: t('UNITS.WIND_FORCE')
    },
    WindExternal: {
        label: t('VESSEL_INFO.ENERGY.EXTERNAL'),
        key: 'report.ExternalWindForce',
        type: 'number',
        unit: t('UNITS.WIND_FORCE') },
    WindSensor: {
        label: t('VESSEL_INFO.ENERGY.SENSOR'),
        key: 'sensorData.WindForce',
        type: 'number',
        unit: t('UNITS.WIND_FORCE')
    }
};

export class VesselInfoEnergyPerformanceWithSensors extends React.PureComponent {
    renderRow = (title, value) => (
        <tr>
            <td className="text-secondary">
                {title}
            </td>
            <td>{value}</td>
        </tr>
    );

    render() {
        const { latestVoyageReport, sensorData } = this.props;
        const {
            AvgSpeed,
            InstructedSpeed,
            Budget,
            TotalConsumption,
            SensorSpeed,
            SensorConsumption,
            Wind, WindExternal,
            WindSensor
        } = config;
        const vrWithSensorData = {
            report: latestVoyageReport,
            sensorData
        };
        return (
            <Accordion header={this.props.children}>
                <div className="sten-panel__body">
                    <table className="sten-table sten-table--sm">
                        <thead>
                            <tr>
                                <th>{t('VESSEL_INFO.ENERGY.SPEED')}</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRow(AvgSpeed.label, formatValue(vrWithSensorData, AvgSpeed))}
                            {this.renderRow(InstructedSpeed.label, formatValue(vrWithSensorData, InstructedSpeed))}
                            {this.renderRow(SensorSpeed.label, formatValue(vrWithSensorData, SensorSpeed))}
                        </tbody>
                        <thead>
                            <tr>
                                <th>{t('VESSEL_INFO.ENERGY.CONSUMPTION')}</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRow(TotalConsumption.label, formatValue(vrWithSensorData, TotalConsumption))}
                            {this.renderRow(Budget.label, formatValue(vrWithSensorData, Budget))}
                            {this.renderRow(SensorConsumption.label, formatValue(vrWithSensorData, SensorConsumption))}
                        </tbody>
                        <thead>
                            <tr>
                                <th>{t('VESSEL_INFO.ENERGY.WIND_FORCE')}</th>
                                <th />
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderRow(Wind.label, formatValue(vrWithSensorData, Wind))}
                            {this.renderRow(WindExternal.label, formatValue(vrWithSensorData, WindExternal))}
                            {this.renderRow(WindSensor.label, formatValue(vrWithSensorData, WindSensor))}
                        </tbody>
                    </table>
                </div>
            </Accordion>
        );
    }
}

VesselInfoEnergyPerformanceWithSensors.propTypes = {
    children: PropTypes.node,
    latestVoyageReport: PropTypes.objectOf(PropTypes.any),
    sensorData: PropTypes.objectOf(PropTypes.any).isRequired
};

VesselInfoEnergyPerformanceWithSensors.defaultProps = {
    children: null,
    latestVoyageReport: null
};

export default VesselInfoEnergyPerformanceWithSensors;
