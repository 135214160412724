import React from 'react';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { commonFields, renderLabel } from 'components/form-fields/form-fields-helper';
import { getObjectProp, formatNumber } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
import { extractPortInfo } from '../weather-routing-helpers';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import ConsumptionBudgetTable from './consumption-budget-table/consumption-budget-table';
import Input from 'components/input/input';
import Select from 'components/select/select';
import Validation from 'components/validation/validation';
import ItineraryPanel from './weather-routing-request-itinerary-panel';
import VesselSelectInScope from 'components/vessel-select/vessel-select-in-scope';
import VoyageSelect from 'components/voyage-select/voyage-select';
/* constants */
import { validations } from './weather-routing-request-validations';

export const emptyWaypoint = {
    Point: null
};

const renderTemplateOption = (option) => {
    const Etd = option.Etd ? `${TimeHelper.getFormatted(option.Etd, { utc: true })} ` : '';
    const Eta = option.Eta ? ` ${TimeHelper.getFormatted(option.Eta, { utc: true })}` : '';
    const portInfo = extractPortInfo(option);
    return (
        <React.Fragment>
            {(Etd || Eta) && <h4 className="sten-select__option-title">{`${Etd}-${Eta}`}</h4>}
            {portInfo && <p className="sten-select__option-subtitle">{portInfo}</p>}
            {!portInfo && !Etd && !Eta && t('GLOBAL.UNKNOWN')}
        </React.Fragment>
    );
};

const splitTemplateOptions = (data) => {
    const typeMap = {};
    data.forEach((item, index) => {
        if (!typeMap[item.Template.TemplateType]) {
            typeMap[item.Template.TemplateType] = {
                options: [],
                name: item.Template.DisplayName
            };
        }
        typeMap[item.Template.TemplateType].options.push({ ...item, Key: index });
    });
    let options = [];
    const sections = Object.keys(typeMap).map(key => {
        options = [...options, ...typeMap[key].options];
        return typeMap[key];
    });
    return { sections, options };
};

export const fieldMap = {
    TemplateData: {
        ...commonFields.Select(
            t('WEATHER_ROUTING.REQUEST.PREPOPULATE_DATA'),
            'TemplateData',
            'editingOptions.templateOptions',
            null,
            {
                valueKey: 'Key',
                clearable: true,
                optionRenderer: renderTemplateOption,
                placeholder: t('WEATHER_ROUTING.REQUEST.SELECT_AN_ITEM_TO_POPULATE_REQUEST'),
                disabledPlaceholder: t('WEATHER_ROUTING.REQUEST.PREPOPULATE_DATA_MISSING'),
                splitOptionsIntoSections: splitTemplateOptions
            },
            (params) => {
                return params.editingOptions.templateOptions.length === 0;
            }
        ),
        view: data => {
            const value = getObjectProp(data, 'TemplateData');
            if (!value) {
                return <label className="label label--value">-</label>;
            }
            return <label className="label label--value">{renderTemplateOption(value)}</label>;
        }
    },
    Vessel: {
        label: renderLabel(t('WEATHER_ROUTING.REQUEST.VESSEL')),
        view: item => (
            <label className="label label--value">
                {item?.Vessel ? `${item.Vessel.VesselName} (${item.Vessel.Imo})` : '-'}
            </label>
        ),
        edit: (item, handleChange, params) => {
            return (
                <Validation.Wrapper hintsOnHover validations={validations.Vessel}>
                    <VesselSelectInScope
                        fixedListWidth
                        name="Vessel"
                        clearable
                        value={item.Vessel}
                        onChange={handleChange('Vessel')}
                        disabled={!!params.disabledButtons.Vessel}
                    />
                </Validation.Wrapper>
            );
        }
    },
    Voyage: {
        label: <label className="label">{t('WEATHER_ROUTING.REQUEST.VOYAGE_NUMBER')}</label>,
        view: data => (
            <label className="label label--value">
                {data?.Voyage ? data.Voyage.VoyageNumber : '-'}
            </label>
        ),
        edit: (data, handleChange, params) => {
            const vesselId = data.Vessel && (data.Vessel.IMO || data.Vessel.Imo);
            return (
                <Validation.Wrapper hintsOnHover validations={validations.Voyage}>
                    <VoyageSelect
                        params={{ tcOut: true }}
                        fixedListWidth
                        name="Voyage"
                        vesselId={params.disabledButtons.Voyage ? null : vesselId}
                        value={data.Voyage}
                        onChange={handleChange('Voyage')}
                    />
                </Validation.Wrapper>
            );
        }
    },
    Source: commonFields.Select(
        t('WEATHER_ROUTING.REQUEST.ROUTE_SOURCE'),
        'Source',
        'editingOptions.requestSources',
        validations.Source,
        { valueKey: 'Id', labelKey: 'DisplayName' }
    ),
    VesselCondition: {
        ...commonFields.Select(
            t('WEATHER_ROUTING.REQUEST.VESSEL_CONDITION'),
            'VesselCondition'
        ),
        edit: (data, handleChange, params) => {
            const options = getObjectProp(params, 'editingOptions.vesselConditions');
            return (
                <Validation.Wrapper hintsOnHover validations={validations.VesselCondition}>
                    <Select
                        fixedListWidth
                        name="VesselCondition"
                        value={getObjectProp(data, 'VesselCondition')}
                        options={options}
                        onChange={handleChange('VesselCondition')}
                        valueKey="Id"
                        labelKey="Name"
                        disabled={!!params.disabledButtons.VesselCondition}
                    />
                </Validation.Wrapper>
            );
        }
    },
    OptimizationType: commonFields.Select(
        t('WEATHER_ROUTING.REQUEST.OPTIMIZATION_TYPE'),
        'OptimizationType',
        'editingOptions.optimizationTypes',
        validations.OptimizationType,
        { valueKey: 'Id', labelKey: 'DisplayName' }
    ),
    VesselDailyCosts: commonFields.Number(
        t('WEATHER_ROUTING.REQUEST.VESSEL_COSTS'),
        'Cost.VesselDailyCosts',
        1,
        validations.VesselDailyCosts,
        t('UNITS.COST')
    ),
    BunkerCostHFO: commonFields.Number(
        t('WEATHER_ROUTING.REQUEST.BUNKER_COST_HFO'),
        'Cost.BunkerCostHFO',
        1,
        validations.BunkerCostHFO,
        t('UNITS.COST')
    ),
    BunkerCostHFOLS: commonFields.Number(
        t('WEATHER_ROUTING.REQUEST.BUNKER_COST_HFOLS'),
        'Cost.BunkerCostHFOLS',
        1,
        validations.BunkerCostHFOLS,
        t('UNITS.COST')
    ),
    RouteCalculationType: commonFields.Select(
        t('WEATHER_ROUTING.REQUEST.ROUTE_CALCULATION_TYPE'),
        'RouteCalculationType',
        (params, data) => params.editingOptions.routeCalculationTypes.filter(type => {
            const {
                optimizationTypeVsCalculationType,
                sourceVsCalculationType
            } = params.editingOptions.configurationRelations;
            // hide speed range calculation type
            if (type.Id === 2 && ConfigService.hiddenFeatures.weatherRoutingSpeedRangeCalculationType) {
                return false;
            }
            if (data.Source && !sourceVsCalculationType?.[data.Source.Id]?.[type.Id]) {
                return false;
            }
            if (data.OptimizationType && !optimizationTypeVsCalculationType[data.OptimizationType.Id][type.Id]) {
                return false;
            }
            return true;
        }),
        validations.RouteCalculationType,
        { valueKey: 'Id', labelKey: 'DisplayName' }
    ),
    InstructedSpeed: {
        condition: (data) => data.RouteCalculationType?.Name === 'InstructedSpeed',
        ...commonFields.MultiValueSelect(
            t('WEATHER_ROUTING.REQUEST.INSTRUCTED_SPEED'),
            'Speed.InstructedSpeed',
            validations.InstructedSpeed,
            (value) => `${formatNumber(Number(value), 2)} ${t('UNITS.SPEED')}`
        )
    },
    InstructedSpeedRange: {
        condition: (data) => data.RouteCalculationType?.Name === 'SpeedRange',
        label: renderLabel(t('WEATHER_ROUTING.REQUEST.INSTRUCTED_SPEED')),
        view: (data) => {
            const valueMin = getObjectProp(data, 'Speed.MinimumSpeed');
            const valueMax = getObjectProp(data, 'Speed.MaximumSpeed');
            return (
                <label className="label label--value">
                    {typeof valueMin === 'number' && typeof valueMax === 'number'
                        ? `${formatNumber(valueMin, 2)} - ${formatNumber(valueMax, 2)} ${t('UNITS.SPEED')}`
                        : '-'}
                </label>
            );
        },
        edit: (data, handleChange) => {
            return (
                <div className="flex-row flex-center">
                    <div className="col-4">
                        <label className="label">{t('WEATHER_ROUTING.REQUEST.FROM')}</label>
                    </div>
                    <div className="col-8">
                        <Validation.Wrapper validations={validations.MinimumSpeed} hintsOnHover>
                            <Input
                                name="Speed.MinimumSpeed"
                                value={data.Speed.MinimumSpeed || ''}
                                onChange={handleChange('Speed.MinimumSpeed')}
                            />
                        </Validation.Wrapper>
                    </div>
                    <div className="col-4">
                        <label className="label">{t('WEATHER_ROUTING.REQUEST.TO')}</label>
                    </div>
                    <div className="col-8">
                        <Validation.Wrapper validations={validations.MaximumSpeed} hintsOnHover>
                            <Input
                                name="Speed.MaximumSpeed"
                                value={data.Speed.MaximumSpeed || ''}
                                onChange={handleChange('Speed.MaximumSpeed')}
                            />
                        </Validation.Wrapper>
                    </div>
                </div>
            );
        }
    },
    Draft: commonFields.Number(
        t('WEATHER_ROUTING.REQUEST.DRAFT'),
        'Vessel.Draft',
        2,
        validations.Draft,
        t('UNITS.DRAFT')
    ),
    ConditionalAreas: {
        condition: (data) => data.Source?.Name !== 'ConsultantWeatherApi',
        ...commonFields.Select(
            t('WEATHER_ROUTING.REQUEST.RESTRICTED_AREAS'),
            'ConditionalAreas',
            'editingOptions.conditionalAreas',
            null,
            {
                multiple: true,
                valueKey: 'Id',
                labelKey: 'Name'
            }
        )
    },

    // IcingRisk: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.ICING_RISK'),
    //     'IcingRisk',
    //     1,
    //     validations.IcingRisk,
    //     t('UNITS.PERCENTAGE')
    // ),
    // Visibility: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.VISIBILITY'),
    //     'Visibility',
    //     1,
    //     validations.IcingRisk,
    //     t('UNITS.METER'),
    //     'min'
    // ),
    // WaveHeight: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.WAVE_HEIGHT'),
    //     'WaveHeight',
    //     1,
    //     validations.WaveHeight,
    //     t('UNITS.SEA_HEIGHT'),
    //     'max'
    // ),
    // BeamWavesHeight: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.BEAM_WAVES_HEIGHT'),
    //     'BeamWavesHeight',
    //     1,
    //     validations.BeamWaveHeight,
    //     t('UNITS.SEA_HEIGHT'),
    //     'max'
    // ),
    // FollowingWavesHeight: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.FOLLOWING_WAVES_HEIGHT'),
    //     'FollowingWavesHeight',
    //     1,
    //     validations.FollowingWavesHeight,
    //     t('UNITS.SEA_HEIGHT'),
    //     'max'
    // ),
    // HeadWavesHeight: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.HEAD_WAVES_HEIGHT'),
    //     'HeadWavesHeight',
    //     1,
    //     validations.HeadWavesHeight,
    //     t('UNITS.SEA_HEIGHT'),
    //     'max'
    // ),
    // RiskWaveHeight: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.RISK_WAVE_HEIGHT'),
    //     'RiskWaveHeight',
    //     1,
    //     validations.RiskWaveHeight,
    //     t('UNITS.SEA_HEIGHT'),
    //     'max'
    // ),
    // WindSpeed10m: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.WIND_SPEED_10M'),
    //     'WindSpeed10m',
    //     1,
    //     validations.WindSpeed10m,
    //     t('UNITS.SPEED'),
    //     'max'
    // ),
    // WindSpeed50m: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.WIND_SPEED_50M'),
    //     'WindSpeed50m',
    //     1,
    //     validations.WindSpeed50m,
    //     t('UNITS.SPEED'),
    //     'max'
    // ),
    // WindGusts10m: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.WIND_GUSTS_10M'),
    //     'WindGusts10m',
    //     1,
    //     validations.WindGusts10m,
    //     t('UNITS.SPEED')
    // ),
    // WindGusts50m: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.WIND_GUSTS_50M'),
    //     'WindGusts50m',
    //     1,
    //     validations.WindGusts50m,
    //     t('UNITS.SPEED')
    // ),
    // HeadWindSpeed: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.HEAD_WIND_SPEED'),
    //     'HeadWindSpeed',
    //     1,
    //     validations.HeadWindSpeed,
    //     t('UNITS.SPEED'),
    //     'max'
    // ),
    // BeamWindSpeed: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.BEAM_WIND_SPEED'),
    //     'BeamWindSpeed',
    //     1,
    //     validations.BeamWindSpeed,
    //     t('UNITS.SPEED'),
    //     'max'
    // ),
    // FollowingWindSpeed: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.FOLLOWING_WIND_SPEED'),
    //     'FollowingWindSpeed',
    //     1,
    //     validations.FollowingWindSpeed,
    //     t('UNITS.SPEED'),
    //     'max'
    // ),
    // RiskWindSpeed: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.RISK_WIND_SPEED'),
    //     'RiskWindSpeed',
    //     1,
    //     validations.RiskWindSpeed,
    //     t('UNITS.SPEED'),
    //     'max'
    // ),
    // IceConcentration: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.ICE_CONCENTRATION'),
    //     'IceConcentration',
    //     1,
    //     validations.IceConcentration,
    //     t('UNITS.PERCENTAGE'),
    //     'max'
    // ),
    // HurricaneDistance: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.HURRICANE_DISTANCE'),
    //     'HurricaneDistance',
    //     1,
    //     validations.HurricaneDistance,
    //     t('UNITS.WEATHER_ADVENT_DISTANCE'),
    //     'min'
    // ),
    // TropicalStormDistance: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.TROPICAL_STORM_DISTANCE'),
    //     'TropicalStormDistance',
    //     1,
    //     validations.TropicalStormDistance,
    //     t('UNITS.WEATHER_ADVENT_DISTANCE'),
    //     'min'
    // ),
    // TropicalDepressionDistance: commonFields.Number(
    //     t('WEATHER_ROUTING.REQUEST.TROPICAL_DEPRESSION_DISTANCE'),
    //     'TropicalDepressionDistance',
    //     1,
    //     validations.TropicalDepressionDistance,
    //     t('UNITS.WEATHER_ADVENT_DISTANCE'),
    //     'min'
    // ),
    // BeamWavesPeriod: {
    //     label: renderLabel(t('WEATHER_ROUTING.REQUEST.BEAM_WAVES_PERIOD')),
    //     view: (data) => {
    //         const valueMin = getObjectProp(data, 'BeamWavesPeriodMin');
    //         const valueMax = getObjectProp(data, 'BeamWavesPeriodMax');
    //         return (
    //             <label className="label label--value">
    //                 {typeof valueMin === 'number' && typeof valueMax === 'number'
    //                     ? `${formatNumber(valueMin, 0)} - ${formatNumber(valueMax, 0)} ${t('UNITS.SPEED')}`
    //                     : '-'}
    //             </label>
    //         );
    //     },
    //     edit: (data, handleChange) => {
    //         return (
    //             <div className="flex-row flex-center">
    //                 <div className="col-12">
    //                     <Validation.Wrapper validations={validations.BeamWavesPeriodMin} hintsOnHover>
    //                         <Input
    //                             prefix="min"
    //                             name="BeamWavesPeriodMin"
    //                             value={data.BeamWavesPeriodMin || ''}
    //                             onChange={handleChange('BeamWavesPeriodMin')}
    //                             suffix={t('UNITS.SECOND')}
    //                         />
    //                     </Validation.Wrapper>
    //                 </div>
    //                 <div className="col-12">
    //                     <Validation.Wrapper validations={validations.BeamWavesPeriodMax} hintsOnHover>
    //                         <Input
    //                             prefix="max"
    //                             name="BeamWavesPeriodMax"
    //                             value={data.BeamWavesPeriodMax || ''}
    //                             onChange={handleChange('BeamWavesPeriodMax')}
    //                             suffix={t('UNITS.SECOND')}
    //                         />
    //                     </Validation.Wrapper>
    //                 </div>
    //             </div>
    //         );
    //     }
    // },
    // FollowingWavesPeriod: {
    //     label: renderLabel(t('WEATHER_ROUTING.REQUEST.FOLLOWING_WAVES_PERIOD')),
    //     view: (data) => {
    //         const valueMin = getObjectProp(data, 'FollowingWavesPeriodMin');
    //         const valueMax = getObjectProp(data, 'FollowingWavesPeriodMax');
    //         return (
    //             <label className="label label--value">
    //                 {typeof valueMin === 'number' && typeof valueMax === 'number'
    //                     ? `${formatNumber(valueMin, 0)} - ${formatNumber(valueMax, 0)} ${t('UNITS.SPEED')}`
    //                     : '-'}
    //             </label>
    //         );
    //     },
    //     edit: (data, handleChange) => {
    //         return (
    //             <div className="flex-row flex-center">
    //                 <div className="col-12">
    //                     <Validation.Wrapper validations={validations.FollowingWavesPeriodMin} hintsOnHover>
    //                         <Input
    //                             prefix="min"
    //                             name="FollowingWavesPeriodMin"
    //                             value={data.FollowingWavesPeriodMin || ''}
    //                             onChange={handleChange('FollowingWavesPeriodMin')}
    //                             suffix={t('UNITS.SECOND')}
    //                         />
    //                     </Validation.Wrapper>
    //                 </div>
    //                 <div className="col-12">
    //                     <Validation.Wrapper validations={validations.FollowingWavesPeriodMax} hintsOnHover>
    //                         <Input
    //                             prefix="max"
    //                             name="FollowingWavesPeriodMax"
    //                             value={data.FollowingWavesPeriodMax || ''}
    //                             onChange={handleChange('FollowingWavesPeriodMax')}
    //                             suffix={t('UNITS.SECOND')}
    //                         />
    //                     </Validation.Wrapper>
    //                 </div>
    //             </div>
    //         );
    //     }
    // },
    // HeadWavesPeriod: {
    //     label: renderLabel(t('WEATHER_ROUTING.REQUEST.HEAD_WAVES_PERIOD')),
    //     view: (data) => {
    //         const valueMin = getObjectProp(data, 'HeadWavesPeriodMin');
    //         const valueMax = getObjectProp(data, 'HeadWavesPeriodMax');
    //         return (
    //             <label className="label label--value">
    //                 {typeof valueMin === 'number' && typeof valueMax === 'number'
    //                     ? `${formatNumber(valueMin, 0)} - ${formatNumber(valueMax, 0)} ${t('UNITS.SPEED')}`
    //                     : '-'}
    //             </label>
    //         );
    //     },
    //     edit: (data, handleChange) => {
    //         return (
    //             <div className="flex-row flex-center">
    //                 <div className="col-12">
    //                     <Validation.Wrapper validations={validations.HeadWavesPeriodMin} hintsOnHover>
    //                         <Input
    //                             prefix="min"
    //                             name="HeadWavesPeriodMin"
    //                             value={data.HeadWavesPeriodMin || ''}
    //                             onChange={handleChange('HeadWavesPeriodMin')}
    //                             suffix={t('UNITS.SECOND')}
    //                         />
    //                     </Validation.Wrapper>
    //                 </div>
    //                 <div className="col-12">
    //                     <Validation.Wrapper validations={validations.HeadWavesPeriodMax} hintsOnHover>
    //                         <Input
    //                             prefix="max"
    //                             name="HeadWavesPeriodMax"
    //                             value={data.HeadWavesPeriodMax || ''}
    //                             onChange={handleChange('HeadWavesPeriodMax')}
    //                             suffix={t('UNITS.SECOND')}
    //                         />
    //                     </Validation.Wrapper>
    //                 </div>
    //             </div>
    //         );
    //     }
    // },
    RequestType: commonFields.Select(
        t('WEATHER_ROUTING.REQUEST.PERFORMANCE_REQUEST'),
        'RequestType',
        'editingOptions.requestTypes',
        validations.RequestType,
        { valueKey: 'Id', labelKey: 'DisplayName' }
    ),
    Etd: commonFields.DateTime(
        t('WEATHER_ROUTING.REQUEST.ETD_DATE'),
        'Etd',
        'EtdOffset',
        validations.Etd,
        validations.EtdOffset
    ),
    Eta: {
        condition: (data) => data.RouteCalculationType?.Name === 'SpeedRange',
        ...commonFields.DateTime(
            t('WEATHER_ROUTING.REQUEST.ETA_DATE'),
            'Eta',
            'EtaOffset',
            validations.Eta,
            validations.EtaOffset
        )
    },
    PortOfDeparture: commonFields.Point(
        t('WEATHER_ROUTING.REQUEST.PORT'),
        'PortOfDeparture',
        validations.PortOfDeparture
    ),
    PortOfDestination: commonFields.Point(
        t('WEATHER_ROUTING.REQUEST.PORT'),
        'PortOfDestination',
        validations.PortOfDestination
    ),
    Waypoints: {
        Point: commonFields.Point(
            t('WEATHER_ROUTING.REQUEST.POINT'),
            'Point',
            validations.Waypoints.Point,
            false,
            { allowCustomPoint: true }
        )
    },
    RoutingEmailsVessel: commonFields.MultiValueSelect(
        t('WEATHER_ROUTING.REQUEST.ROUTING_EMAILS_VESSEL'),
        'RoutingEmailsVessel',
        validations.RoutingEmailsVessel
    ),
    RoutingEmailsOperations: commonFields.MultiValueSelect(
        t('WEATHER_ROUTING.REQUEST.ROUTING_EMAILS_OPERATIONS'),
        'RoutingEmailsOperations',
        validations.RoutingEmailsOperations
    ),
    RoutingEmailsOther: commonFields.MultiValueSelect(
        t('WEATHER_ROUTING.REQUEST.ROUTING_EMAILS_OTHER'),
        'RoutingEmailsOther',
        validations.RoutingEmailsOther
    ),
    PerformanceEmails: {
        condition: (data) => ['RoutingAndPerformance', 'RoutingPerformanceAndPvar'].includes(data.RequestType?.Name),
        ...commonFields.MultiValueSelect(
            t('WEATHER_ROUTING.REQUEST.PERFORMANCE_EMAILS'),
            'PerformanceEmails',
            validations.PerformanceEmails
        )
    },
    PvarEmails: {
        condition: (data) => data.RequestType?.Name === 'RoutingPerformanceAndPvar',
        ...commonFields.MultiValueSelect(
            t('WEATHER_ROUTING.REQUEST.PVAR_EMAILS'),
            'PvarEmails',
            validations.PvarEmails
        )
    },
    // Remarks
    Remarks: {
        condition: (data) => !data.Source || data.Source.Name !== 'AutomaticWeatherApi',
        ...commonFields.Textarea(t('WEATHER_ROUTING.REQUEST.REMARKS'), 'Remarks', validations.Remarks)
    }
};

export const dataMap = {
    Itinerary: (params, index) => (
        <ItineraryPanel
            key={index}
            data={params.data}
            fieldMap={fieldMap}
            isEditing={params.isEditing}
            editingOptions={params.editingOptions}
            validationRules={validations}
            onChange={params.onChange}
            newWaypointItem={emptyWaypoint}
        />
    ),
    ConsumptionBudget: (params, index) => (
        <ConsumptionBudgetTable
            key={index}
            editable={params.isEditing}
            onChange={params.onChange('ConsumptionBudget')}
            sortAscending={params.sortAscending}
            data={params.data.ConsumptionBudget || []}
        />
    )
};
