import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { renderFields } from 'components/form-fields/form-fields-helper';
/* components */
import ContentArray from 'components/content-array/content-array';

export class ItineraryPanel extends React.Component {
    renderRow = (fields) => {
        const { data, editingOptions, fieldMap, validationRules, isEditing, onChange } = this.props;

        return renderFields({
            data,
            fieldNames: fields,
            fieldMap,
            isEditing,
            onChange,
            isInline: true,
            editingOptions,
            validationRules
        });
    };

    render() {
        const { data } = this.props;
        return (
            <React.Fragment>
                <div className="sten-content__section">
                    <div className="sten-content__panel-wrapper">
                        <div className="sten-panel">
                            <div className="sten-panel__header">
                                <h4 className="flex-grow">
                                    {t('WEATHER_ROUTING.REQUEST.PORT_OF_DEPARTURE')}
                                </h4>
                            </div>
                            <div className="sten-panel__section">
                                {this.renderRow(['PortOfDeparture', 'Etd'])}
                            </div>
                        </div>
                    </div>
                </div>
                <ContentArray
                    hasFieldsWithHandlerCreators
                    fieldMap={this.props.fieldMap.Waypoints}
                    onChange={this.props.onChange('Waypoints')}
                    data={data.Waypoints}
                    isEditing={this.props.isEditing}
                    editingOptions={this.props.editingOptions}
                    fields={['Point']}
                    newItem={this.props.newWaypointItem}
                    itemTitle={t('WEATHER_ROUTING.REQUEST.WAYPOINT')}
                    maxLength={20}
                    validationRules={this.props.validationRules.Waypoints}
                />
                <div className="sten-content__section">
                    <div className="sten-content__panel-wrapper">
                        <div className="sten-panel">
                            <div className="sten-panel__header">
                                <h4 className="flex-grow">
                                    {t('WEATHER_ROUTING.REQUEST.PORT_OF_DESTINATION')}
                                </h4>
                            </div>
                            <div className="sten-panel__section">
                                {this.renderRow(['PortOfDestination', 'Eta'])}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

ItineraryPanel.propTypes = {
    data: PropTypes.objectOf(PropTypes.any),
    editingOptions: PropTypes.objectOf(PropTypes.any).isRequired,
    fieldMap: PropTypes.objectOf(PropTypes.any).isRequired,
    isEditing: PropTypes.bool,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    newWaypointItem: PropTypes.objectOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired,
    validationRules: PropTypes.objectOf(PropTypes.any)
};

ItineraryPanel.defaultProps = {
    data: null,
    isEditing: false,
    maxLength: 50,
    minLength: 0,
    validationRules: null
};

export default ItineraryPanel;
