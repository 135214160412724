import { takeEvery, put, select } from 'redux-saga/effects';
/* actions */
import { ActionTypes } from './admin-vessel-management-actions';
import { ActionTypes as ATFMActionTypes } from 'components/assign-to-fleet-modal/assign-to-fleet-modal-actions';
/* Services */
import VesselService from 'services/core-api/vessel-service';


function* getSegments() {
    const segments = yield VesselService.getSegments();
    if (segments) {
        yield put({ type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_SET_SEGMENTS, segments });
    }
}

function* getTradingAreas() {
    const tradingAreas = yield VesselService.getTradingAreas();
    if (tradingAreas) {
        yield put({
            type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_SET_TRADING_AREAS,
            tradingAreas
        });
    }
}

function* getVesselTypes() {
    const vesselTypes = yield VesselService.getTypes();
    if (vesselTypes) {
        yield put({
            type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_SET_VESSEL_TYPES,
            vesselTypes
        });
    }
}

function* getFleets() {
    const fleets = yield VesselService.getCompanies();
    if (fleets) {
        const navFleets = fleets.slice();
        if (fleets) {
            navFleets.unshift({
                Name: 'All vessels',
                Id: null
            });
            yield put({ type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_SET_FLEETS, navFleets, fleets });
        }
    }
}

function* updateFleets() {
    const { isMounted } = yield select(state => state.adminVesselManagementReducer);
    if (isMounted) {
        yield getFleets();
    }
}

function* removeVesselFromFleet(action) {
    const relationships = yield VesselService.getRelationships(action.imo);
    const updatedRelationships = relationships.filter((r) => r.CompanyId !== action.fleetId);
    yield VesselService.setRelationships(action.imo, updatedRelationships);
    yield put({ type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_GET_FLEETS });
    yield put({ type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_TABLE_REMOVE_VESSEL, imo: action.imo });
}


function* getVessels(action) {
    const {
        searchCriteria,
        selectedSegments,
        offset,
        limit,
        sortProp,
        sortOrder
    } = yield select(state => state.adminVesselManagementReducer);
    const params = {
        Offset: action.overrideCurrent ? 0 : offset,
        Limit: limit,
        SortBy: sortProp,
        SortOrder: sortOrder
    };
    if (action.resetFilters) {
        yield put({ type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FILTERS_RESET_ALL });
    } else {
        if (searchCriteria) {
            params.SearchTerm = searchCriteria;
        }

        if (selectedSegments) {
            const selectedSegmentsIds = [];
            selectedSegments.forEach((segment, index) => {
                if (selectedSegmentsIds.indexOf(segment.VesselTypeId > -1)) {
                    selectedSegmentsIds.push(segment.VesselTypeId);
                } else {
                    selectedSegmentsIds.splice(index, 1);
                }
            });
            params.SegmentId = selectedSegmentsIds;
        }
    }

    if (action.fleetId) {
        params.FleetId = action.fleetId;
    }

    yield put({ type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_TABLE_SET_FETCHING_DATA, isFetchingData: true });
    const vesselsResponse = yield VesselService.getFleetVessels(params);
    if (vesselsResponse) {
        const currentPage = (vesselsResponse.Offset / vesselsResponse.Limit) + 1;
        const allFetched = currentPage >= vesselsResponse.TotalPagesCount;
        yield put({
            type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_TABLE_ADD_VESSELS,
            vessels: vesselsResponse.PageItems,
            overrideCurrent: action.overrideCurrent,
            allFetched
        });
    } else {
        yield put({ type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_TABLE_SET_FETCHING_DATA, isFetchingData: false });
    }
}

function* getAndOverrideVessels() {
    const { isMounted } = yield select(state => state.adminVesselManagementReducer);
    if (isMounted) {
        yield getVessels({ overrideCurrent: true });
    }
}

export default function* adminVesselManagementSaga() {
    yield takeEvery(ActionTypes.ADMIN_VESSEL_MANAGEMENT_GET_SEGMENTS, getSegments);
    yield takeEvery(ActionTypes.ADMIN_VESSEL_MANAGEMENT_GET_TRADING_AREAS, getTradingAreas);
    yield takeEvery(ActionTypes.ADMIN_VESSEL_MANAGEMENT_GET_VESSEL_TYPES, getVesselTypes);
    yield takeEvery(ActionTypes.ADMIN_VESSEL_MANAGEMENT_GET_FLEETS, getFleets);
    yield takeEvery(ActionTypes.ADMIN_VESSEL_MANAGEMENT_REMOVE_VESSEL_FROM_FLEET, removeVesselFromFleet);
    yield takeEvery(ATFMActionTypes.ASSIGN_TO_FLEET_MODAL_RELATIONSHIPS_SAVED, updateFleets);
    yield takeEvery(ActionTypes.ADMIN_VESSEL_MANAGEMENT_TABLE_GET_VESSELS, getVessels);
    yield takeEvery(ATFMActionTypes.ASSIGN_TO_FLEET_MODAL_RELATIONSHIPS_SAVED, getAndOverrideVessels);
}
