import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ScrollArea from 'components/scroll-area/scroll-area';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import AdminVesselManagementFilters from './admin-vessel-management-filters/admin-vessel-management-filters';
import AdminVesselManagementFleets from './admin-vessel-management-fleets/admin-vessel-management-fleets';
import AdminVesselManagementForm from './admin-vessel-management-form/admin-vessel-management-form';
import AdminVesselManagementTable from './admin-vessel-management-table/admin-vessel-management-table';
import AssignToFleetModal from 'components/assign-to-fleet-modal/assign-to-fleet-modal';
import IconButton from 'components/icon-button/icon-button';
import RightSideBar from 'components/right-side-bar/right-side-bar';
/* action */
import {
    getFleets,
    getSegments,
    getTradingAreas,
    getVesselTypes,
    setMounted,
    toggleFilters,
    toggleUpdateMode,
    resetAll
} from './admin-vessel-management-actions';
/* styles */
import './admin-vessel-management.scss';

export class AdminVesselManagement extends React.PureComponent {
    componentDidMount() {
        this.props.setMounted(true);
        this.handleRouteChange();
        this.props.getSegments();
        this.props.getTradingAreas();
        this.props.getVesselTypes();
        this.props.getFleets();
    }

    componentDidUpdate(prevProps) {
        this.handleRouteChange(prevProps);
    }

    componentWillUnmount() {
        this.props.setMounted(false);
        this.props.resetAll();
    }

    handleRouteChange = (prevProps) => {
        const { router } = this.props;
        if ((prevProps && prevProps.router.location.pathname !== this.props.router.location.pathname)
        && this.props.isUpdateModeActive) {
            this.props.toggleUpdateMode('', false);
        } else if (this.props.router.location.pathname.endsWith('new')) {
            this.props.toggleUpdateMode('', true);
        } else if (router.params && router.params.id && router.params.id !== this.props.activeVesselImo) {
            this.props.toggleUpdateMode(router.params.id, true);
        }
    };

    getAddLink = () => this.props.router.getLinkTo({ pathname: `${appRoutes.Admin.VesselManagement}/new` });

    getFleetName = () => {
        const activeFleet = this.props.navFleets.find(fleet => fleet.Id === this.props.queryParams.fleet);
        return activeFleet ? activeFleet.Name : '';
    };

    render() {
        return (
            <div className="sten-content sten-admin-vessel-management">
                <AssignToFleetModal />
                <div className="sten-admin-vessel-management__content-left">
                    <div className="sten-content__header">
                        <h1 className="sten-content__title">{translate('ADMIN.VESSEL_MANAGEMENT.TITLE')}</h1>
                    </div>
                    <div className="sten-admin-vessel-management__body">
                        <ScrollArea>
                            <AdminVesselManagementFleets
                                navFleets={this.props.navFleets}
                                queryParams={this.props.queryParams}
                            />
                        </ScrollArea>
                    </div>
                </div>
                <div className="sten-admin-vessel-management__content-right">
                    <div className="sten-content__header flex-row">
                        <h1 className="sten-content__title flex-grow">{this.getFleetName()}</h1>
                        <div className="flex-shrink">
                            <IconButton
                                isLink
                                changeIndicator={
                                    !this.props.areFiltersVisible
                                    && (this.props.searchCriteria || this.props.selectedSegments.length > 0)
                                }
                                isActive={this.props.areFiltersVisible}
                                icon="icon-filter-outline"
                                onClick={this.props.toggleFilters}
                            />
                        </div>
                        <div className="flex-shrink">
                            <Link className="btn btn--link btn--primary col-24" to={this.getAddLink()}>
                                <span className="btn__icon icon icon-plus" />
                                <span className="hide-sm">
                                    {translate('ADMIN.VESSEL_MANAGEMENT.ADD_NEW_VESSEL')}
                                </span>
                            </Link>
                        </div>
                    </div>
                    <div className="sten-admin-vessel-management__body">
                        <AdminVesselManagementFilters />
                        <AdminVesselManagementTable queryParams={this.props.queryParams} />
                    </div>
                    <RightSideBar isCollapsed={!this.props.isUpdateModeActive}>
                        <AdminVesselManagementForm />
                    </RightSideBar>
                </div>
            </div>
        );
    }
}

AdminVesselManagement.propTypes = {
    activeFleetName: PropTypes.string,
    activeVesselImo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    areFiltersVisible: PropTypes.bool,
    fleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    getFleets: PropTypes.func.isRequired,
    getSegments: PropTypes.func.isRequired,
    getTradingAreas: PropTypes.func.isRequired,
    getVesselTypes: PropTypes.func.isRequired,
    isUpdateModeActive: PropTypes.bool,
    navFleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    queryParams: PropTypes.objectOf(PropTypes.any).isRequired,
    resetAll: PropTypes.func,
    router: TRouter.isRequired,
    searchCriteria: PropTypes.string,
    selectedSegments: PropTypes.arrayOf(PropTypes.any),
    setMounted: PropTypes.func,
    toggleFilters: PropTypes.func,
    toggleUpdateMode: PropTypes.func
};

AdminVesselManagement.defaultProps = {
    activeFleetName: null,
    activeVesselImo: null,
    areFiltersVisible: true,
    isUpdateModeActive: false,
    resetAll: undefined,
    searchCriteria: null,
    selectedSegments: null,
    setMounted: undefined,
    toggleFilters: undefined,
    toggleUpdateMode: undefined
};

function mapStateToProps(state) {
    return {
        activeVesselImo: state.adminVesselManagementReducer.activeVesselImo,
        areFiltersVisible: state.adminVesselManagementReducer.areFiltersVisible,
        fleets: state.adminVesselManagementReducer.fleets,
        isUpdateModeActive: state.adminVesselManagementReducer.isUpdateModeActive,
        navFleets: state.adminVesselManagementReducer.navFleets,
        searchCriteria: state.adminVesselManagementReducer.searchCriteria,
        selectedSegments: state.adminVesselManagementReducer.selectedSegments
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getFleets: () => getFleets(dispatch),
        getSegments: () => getSegments(dispatch),
        getTradingAreas: () => getTradingAreas(dispatch),
        getVesselTypes: () => getVesselTypes(dispatch),
        resetAll: () => resetAll(dispatch),
        setMounted: isMounted => setMounted(dispatch, isMounted),
        toggleFilters: () => toggleFilters(dispatch),
        toggleUpdateMode: (vesselImo, isActive) => toggleUpdateMode(dispatch, vesselImo, isActive)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminVesselManagement));
