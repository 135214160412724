import getFontSize from 'utils/helpers/cached-font-size';

const colorLine = '#02A0E7';
const colorTooltipText = '#ffffff';
const colorTooltipBg = '#0D2731';
const colorXAxisLabel = '#ffffff';

const getLine = (name) => ({
    name,
    type: 'line',
    lineWidth: 0,
    data: [],
    color: colorTooltipText,
    states: { hover: { lineWidthPlus: 0 } },
    marker: {
        enabled: false,
        states: {
            hover: {
                enabled: false
            }
        }
    }
});

export const getChartConfig = () => {
    const rem = getFontSize();
    const fsm = rem / 16;

    return {
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                connectNulls: true
            }
        },
        chart: {
            backgroundColor: 'transparent',
            plotBackgroundColor: 'transparent',
            zoomType: false,
            panning: false,
            spacing: [rem, rem, rem, 2 * rem]
        },
        scrollbar: {
            enabled: true
        },
        xAxis: {
            minRange: 1,
            type: 'category',
            tickInterval: null,
            tickPositioner: null,
            startOnTick: true,
            endOnTick: true,
            labels: {
                autoRotation: 0,
                enabled: true,
                formatter: undefined,
                style: { color: colorXAxisLabel }
            }
        },
        yAxis: {
            min: 0
        },
        series: [
            {
                name: 'Total',
                type: 'line',
                lineWidth: 2 * fsm,
                zIndex: 2,
                data: [],
                color: colorLine
            },
            getLine('Administrators'),
            getLine('Customers'),
            getLine('Godmothers'),
            getLine('Pool Partners'),
            getLine('Privileged Customers'),
            getLine('Restricted Users'),
            getLine('Super Administrators'),
            getLine('Technical Managers'),
            getLine('Users'),
            getLine('Vessel Owners')
        ],
        tooltip: {
            backgroundColor: colorTooltipBg
        }
    };
};
