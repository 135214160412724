export default {
    tanker: {
        primaryColor: 'rgb(40,255,202)',
        labelMinZoomLevel: 5,
        zIndex: 60
    },
    ferry: {
        primaryColor: 'rgb(255,212,82)',
        labelMinZoomLevel: 5,
        zIndex: 60
    },
    drill: {
        primaryColor: 'rgb(228,150,251)',
        labelMinZoomLevel: 5,
        zIndex: 60
    },
    port: {
        primaryColor: 'rgb(255,255,255)',
        labelMinZoomLevel: 5,
        zIndex: 50
    },
    city: {
        primaryColor: 'rgb(40,255,202)',
        secondaryColor: 'rgb(255,255,255)',
        labelMinZoomLevel: 5,
        zIndex: 55
    },
    office: {
        primaryColor: 'rgb(255,255,255)',
        secondaryColor: 'rgb(0,0,0)',
        zoomOnSelect: 10,
        zIndex: 60
    },
    ferryRoute: {
        color: '#78a8bc',
        width: 2,
        lineDash: [4, 8],
        labelColor: '#fff',
        zIndex: 40
    }
};
