import React from 'react';
import PropTypes from 'prop-types';
import ScrollArea from 'components/scroll-area/scroll-area';
import { connect } from 'react-redux';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import Accordion from 'components/accordion/accordion';
import TableHeadCell from 'components/table-head-cell/table-head-cell';
import Checkbox from 'components/checkbox/checkbox';
import PositionListTableRow from './position-list-table-row/position-list-table-row';
/* actions */
import { setSortProp, toggleGroupCollapse } from './position-list-table-actions';
import {
    setSelectedVessels,
    setFixtureStatus,
    submitNote,
    removeNote,
    deleteLoadReadyDate
} from '../position-list-actions';
/* selectors */
import {
    getVisibleColumns,
    getGroupedVessels,
    getSelectedGrouping,
    getVesselInfoConfigMap
} from '../position-list-selectors';
/* styles */
import './position-list-table.scss';

class PositionListTable extends React.PureComponent {
    state = {
        activeInputId: null
    };

    setSortProp = sortProp => {
        this.scrollArea.scrollArea.scrollTop();
        this.props.setSortProp(sortProp);
    };

    setActiveInputId = id => {
        this.setState({
            activeInputId: id
        });
    };

    getAccordionsByGroups() {
        const accordions = new Array(this.props.groupedVessels.length);

        if (this.props.groupedVessels) {
            const canComposeAndEdit = this.props.permissions && this.props.permissions.ComposeAndEditPositionList;
            const isStatusChangeable = this.props.permissions && this.props.permissions.SetFixtureStatus;

            for (const key in this.props.groupedVessels) {
                const group = this.props.groupedVessels[key];
                const selectedByGroup = this.getSelectedByGroup(group);
                const header = (
                    <React.Fragment>
                        <h4>{key}</h4>
                        <div className="sten-badge">
                            {`${translate('POSITION_LIST.SELECTED')} ${selectedByGroup}/${group.length}`}
                        </div>
                    </React.Fragment>
                );
                const headerCheckbox = (
                    <Checkbox
                        className="sten-position-list-table__group-checkbox"
                        isChecked={selectedByGroup === group.length}
                        onChange={this.toggleSelectedVesselsByGroup.bind(this, group)}
                    />
                );

                accordions.push(
                    <Accordion
                        className="sten-position-list-table__group"
                        key={key}
                        header={header}
                        headerBefore={canComposeAndEdit ? headerCheckbox : null}
                        isCollapsed={this.props.collapsedGroups.indexOf(key) > -1}
                        onToggleCollapse={this.props.toggleGroupCollapse.bind(this, key)}
                    >
                        <table className="sten-table sten-table--no-border-top">
                            <tbody>
                                {group.map(vessel => (
                                    <PositionListTableRow
                                        key={vessel.IMO}
                                        canUpdateComments={this.props.permissions.UpdateVesselComment}
                                        vessel={vessel}
                                        isSelected={this.props.selectedVessels.indexOf(vessel.IMO) >= 0}
                                        selectedPort={this.props.selectedPort}
                                        onChange={canComposeAndEdit ? this.toggleSelectedVessel : undefined}
                                        tableColumns={this.props.visibleColumns}
                                        fixtureStatuses={this.props.fixtureStatuses}
                                        setFixtureStatus={isStatusChangeable ? this.props.setFixtureStatus : undefined}
                                        setActiveInputId={this.setActiveInputId}
                                        activeInputId={this.state.activeInputId}
                                        submitNote={this.props.submitNote}
                                        removeNote={this.props.removeNote}
                                        onEditLoadReadyDate={this.props.onEditLoadReadyDate}
                                        deleteLoadReadyDate={this.props.deleteLoadReadyDate}
                                        permissions={this.props.permissions}
                                        vesselInfoConfigurableProperties={this.props.vesselInfoConfigurableProperties}
                                        selectedVesselInfoConfig={this.props.selectedVesselInfoConfig}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </Accordion>
                );
            }
        }
        return accordions;
    }

    getSelectedByGroup = group => {
        return group.reduce((count, vessel) => {
            if (this.props.selectedVessels && this.props.selectedVessels.length
                && this.props.selectedVessels.indexOf(vessel.IMO) >= 0) {
                return count + 1;
            }
            return count;
        }, 0);
    };

    toggleSelectedVessel = vesselId => {
        const selectedVessels = this.props.selectedVessels.slice();
        const vesselIndex = selectedVessels.indexOf(vesselId);
        if (vesselIndex >= 0) {
            selectedVessels.splice(vesselIndex, 1);
        } else {
            selectedVessels.push(vesselId);
        }
        this.props.setSelectedVessels(selectedVessels);
    };

    toggleSelectedVesselsByGroup = group => {
        const selectedVessels = this.props.selectedVessels.slice();
        const selectedByGroup = this.getSelectedByGroup(group);
        if (selectedByGroup === group.length) {
            group.forEach((vessel) => {
                const vesselIndex = selectedVessels.indexOf(vessel.IMO);
                if (vesselIndex >= 0) {
                    selectedVessels.splice(vesselIndex, 1);
                }
            });
        } else {
            group.forEach((vessel) => {
                const vesselIndex = selectedVessels.indexOf(vessel.IMO);
                if (vesselIndex < 0) {
                    selectedVessels.push(vessel.IMO);
                }
            });
        }
        this.props.setSelectedVessels(selectedVessels);
    };

    toggleAllSelectedVessels = () => {
        if (this.props.selectedVessels.length === this.props.vessels.length) {
            this.props.setSelectedVessels([]);
        } else {
            this.props.setSelectedVessels(this.props.vessels.map(vessel => vessel.IMO));
        }
    };

    render() {
        const canComposeAndEdit = this.props.permissions && this.props.permissions.ComposeAndEditPositionList;
        let tableClassName = 'sten-position-list-table';
        if (!this.props.areFiltersVisible) {
            tableClassName += ' sten-position-list-table--without-filters';
        }
        const tableWrapperWidth = this.props.visibleColumns.reduce((total, col) => total + col.width, 0);

        return (
            <div className={tableClassName}>
                <ScrollArea
                    horizontal
                    vertical={false}
                    contentStyle={{ minWidth: `${tableWrapperWidth + 3.125}rem` }}
                    contentClassName="sten-position-list-table__scroll-content"
                >
                    <table
                        className="sten-position-list-table__head sten-table"
                    >
                        <thead>
                            <tr>
                                {canComposeAndEdit && (
                                    <th className="sten-position-list-table__checkbox-cell">
                                        <Checkbox
                                            isChecked={this.props.selectedVessels.length === this.props.vessels.length}
                                            onChange={this.toggleAllSelectedVessels}
                                        />
                                    </th>
                                )}
                                {this.props.visibleColumns.map((cell) => (
                                    <TableHeadCell
                                        style={{ width: `${cell.width}rem` }}
                                        sortOrder={this.props.sortProp === cell.sortProp ? this.props.sortOrder : ''}
                                        sortable={cell.sortable}
                                        key={cell.ColumnId}
                                        onClick={this.setSortProp.bind(this, cell.sortProp)}
                                    >
                                        {cell.ColumnDisplayName}
                                    </TableHeadCell>
                                ))}
                            </tr>
                        </thead>
                    </table>
                    <ScrollArea ref={r => { this.scrollArea = r; }} className="sten-position-list-table__body">
                        {this.getAccordionsByGroups()}
                    </ScrollArea>
                </ScrollArea>
            </div>
        );
    }
}

PositionListTable.propTypes = {
    areFiltersVisible: PropTypes.bool.isRequired,
    collapsedGroups: PropTypes.arrayOf(PropTypes.any).isRequired,
    deleteLoadReadyDate: PropTypes.func.isRequired,
    fixtureStatuses: PropTypes.arrayOf(PropTypes.object).isRequired,
    groupedVessels: PropTypes.objectOf(PropTypes.any).isRequired,
    onEditLoadReadyDate: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    removeNote: PropTypes.func.isRequired,
    selectedGrouping: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedPort: PropTypes.objectOf(PropTypes.any),
    selectedVesselInfoConfig: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedVessels: PropTypes.arrayOf(PropTypes.any).isRequired,
    setFixtureStatus: PropTypes.func.isRequired,
    setSelectedVessels: PropTypes.func.isRequired,
    setSortProp: PropTypes.func.isRequired,
    sortOrder: PropTypes.string.isRequired,
    sortProp: PropTypes.string.isRequired,
    submitNote: PropTypes.func.isRequired,
    toggleGroupCollapse: PropTypes.func.isRequired,
    vesselInfoConfigurableProperties: PropTypes.objectOf(PropTypes.any).isRequired,
    vessels: PropTypes.arrayOf(PropTypes.object).isRequired,
    visibleColumns: PropTypes.arrayOf(PropTypes.object).isRequired
};

PositionListTable.defaultProps = {
    selectedPort: null
};

function mapStateToProps(state) {
    return {
        collapsedGroups: state.positionListTableReducer.collapsedGroups,
        fixtureStatuses: state.positionListReducer.fixtureStatuses,
        groupedVessels: getGroupedVessels(state),
        permissions: state.userReducer.permissions,
        selectedGrouping: getSelectedGrouping(state),
        selectedPort: state.positionListFiltersReducer.selectedPort,
        selectedVesselInfoConfig: state.userReducer.settings.PositionListVesselProperties,
        selectedVessels: state.positionListReducer.selectedVessels,
        sortOrder: state.positionListTableReducer.sortOrder,
        sortProp: state.positionListTableReducer.sortProp,
        vesselInfoConfigurableProperties: getVesselInfoConfigMap(state),
        vessels: state.positionListReducer.vessels,
        visibleColumns: getVisibleColumns(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deleteLoadReadyDate: deleteLoadReadyDate.bind(null, dispatch),
        removeNote: (imo, id, isInternal) => removeNote(dispatch, imo, id, isInternal),
        setFixtureStatus: (vesselId, fixtureStatus) => setFixtureStatus(dispatch, vesselId, fixtureStatus),
        setSelectedVessels: selectedVessels => setSelectedVessels(dispatch, selectedVessels),
        setSortProp: sortProp => setSortProp(dispatch, sortProp),
        submitNote: (imo, note, id, isInternal) => submitNote(dispatch, imo, note, id, isInternal),
        toggleGroupCollapse: toggledGroup => toggleGroupCollapse(dispatch, toggledGroup)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PositionListTable);
