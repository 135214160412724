export const ActionTypes = {
    VESSEL_FILTER_GET_OPTIONS: 'VESSEL_FILTER_GET_OPTIONS',
    VESSEL_FILTER_SET_OPTIONS: 'VESSEL_FILTER_SET_OPTIONS',
    VESSEL_FILTER_GET_COMPANIES: 'VESSEL_FILTER_GET_COMPANIES',
    VESSEL_FILTER_SET_COMPANIES: 'VESSEL_FILTER_SET_COMPANIES',
    VESSEL_FILTER_RESET: 'VESSEL_FILTER_RESET'
};

export function getVesselFilters(dispatch) {
    dispatch({ type: ActionTypes.VESSEL_FILTER_GET_OPTIONS });
}

export function getVesselFilterCompanies(dispatch) {
    dispatch({ type: ActionTypes.VESSEL_FILTER_GET_COMPANIES });
}
