import getFontSize from 'utils/helpers/cached-font-size';
import getTitle from './title-config';
/* services */
import ConfigService from 'services/config-api/config-service';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* constants */
import { chartConfigBaseColors, formatter } from './chart-config-constants';

const chartColors = {
    ReportedWidnSpeed: '#1a86f7',
    ExternalWindSpeed: '#25d7af',
    SensorWindSpeed: '#88DAFF'
};
const colorAreaSuccess = '#0c9c12';

const getWeatherConfig = () => {
    const rem = getFontSize();
    const fsm = rem / 16;
    const config = {
        chart: {
            backgroundColor: chartConfigBaseColors.colorChartBg,
            plotBackgroundColor: chartConfigBaseColors.colorChartPlotBg,
            dynamicExtremes: false,
            spacing: [2 * rem, rem, rem, 2.5 * rem],
            resetZoomButton: { theme: { display: 'none' } }
        },
        scrollbar: {
            enabled: true
        },
        xAxis: {
            gridLineColor: chartConfigBaseColors.colorXAxisGridLine,
            gridZIndex: 1,
            tickPixelInterval: 400,
            startOnTick: true,
            labels: {
                rotation: -60
            }
        },
        yAxis: {
            minTickInterval: 5,
            tickAmount: 3,
            labels: {
                reserveSpace: false
            },
            title: {
                ...getTitle(fsm),
                text: t('VOYAGE_PERFORMANCE.CHARTS.WEATHER_BF')
            }
        },
        tooltip: {
            formatter
        },
        series: [
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.BOUNDARY'),
                type: 'area',
                lineWidth: 0,
                data: [],
                marker: {
                    enabled: false
                },
                dataLabels: {
                    enabled: false
                },
                states: { hover: { enabled: false }, inactive: { enabled: false } },
                enableMouseTracking: false,
                color: colorAreaSuccess,
                fillColor: chartConfigBaseColors.colorAreaSuccessFill,
                showInLegend: false,
                connectNulls: true
            },
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.REPORTED_WIND_SPEED'),
                type: 'area',
                color: chartColors.ReportedWidnSpeed,
                lineWidth: 2 * fsm,
                data: [],
                zIndex: 2,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            },
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.EXTERNAL_WIND_SPEED'),
                type: 'area',
                color: chartColors.ExternalWindSpeed,
                lineWidth: 2 * fsm,
                data: [],
                zIndex: 2,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            }
        ]
    };
    if (ConfigService.featureToggles.showSensorData) {
        config.series.splice(2, 0, {
            name: t('VOYAGE_PERFORMANCE.CHARTS.SENSOR_WIND_SPEED'),
            type: 'area',
            color: chartColors.SensorWindSpeed,
            lineWidth: 2 * fsm,
            data: [],
            zIndex: 2,
            visible: false,
            fillColor: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                    [0, chartConfigBaseColors.colorSeriesGradient],
                    [1, 'transparent']
                ]
            }
        });
    }
    return config;
};

export default getWeatherConfig;
