/* helpers */
import MockHelper from 'utils/helpers/mock-helper';
import { sortByProp } from 'utils/helpers/array-helper';
/* services */
import PortService from 'services/core-api/port-service';

const AuroraEquipmentItemTypes = [
    {
        Id: 1,
        TypeName: 'HoursConsumption',
        ValueNameTypes: [
            { Name: null, Types: [1], Max: { 1: 100 }, Decimals: { 1: 0 } },
            { Name: 'Boiler', Types: [1], Max: { 1: 100 }, Decimals: { 1: 0 } },
            { Name: 'GCU', Types: [1], Max: { 1: 25 }, Decimals: { 1: 0 } },
            { Name: 'IGG', Types: [1], Max: { 1: 20 }, Decimals: { 1: 1 } },
            { Name: 'Main Engine', Types: [1], Max: { 1: 100 }, Decimals: { 1: 0 } },
            { Name: 'Main Generator Engine No.01', Types: [2], Max: { 2: 50000 }, Decimals: { 2: 0 } },
            { Name: 'Main Generator Engine No.02', Types: [2], Max: { 2: 50000 }, Decimals: { 2: 0 } },
            { Name: 'Main Generator Engine No.03', Types: [2], Max: { 2: 50000 }, Decimals: { 2: 0 } },
            { Name: 'Main Generator Engine No.04', Types: [2], Max: { 2: 50000 }, Decimals: { 2: 0 } },
            { Name: 'Main Generator Engine No.05', Types: [2], Max: { 2: 50000 }, Decimals: { 2: 0 } },
            { Name: 'Gas Combustion Unit (GCU)', Types: [2], Max: { 2: 500 }, Decimals: { 2: 2 } }
        ],
        AllValueNameTypes: false,
        Max: { 1: 100 },
        Decimals: { 1: 0 },
        FuelType: true
    },
    {
        Id: 2,
        TypeName: 'QuantityConsumption',
        ValueNameTypes: [
            { Name: null, Types: [2], Max: { 2: 500 }, Decimals: { 2: 1 } },
            { Name: 'Boiler', Types: [2], Max: { 2: 500 }, Decimals: { 2: 0 } },
            { Name: 'GCU', Types: [2], Max: { 2: 500 }, Decimals: { 2: 2 } },
            { Name: 'IGG', Types: [2], Max: { 2: 20 }, Decimals: { 2: 1 } },
            { Name: 'Main Engine', Types: [2], Max: { 2: 50000 }, Decimals: { 2: 0 } },
            { Name: 'Main Generator Engine No.01', Types: [2], Max: { 2: 50000 }, Decimals: { 2: 0 } },
            { Name: 'Main Generator Engine No.02', Types: [2], Max: { 2: 50000 }, Decimals: { 2: 0 } },
            { Name: 'Main Generator Engine No.03', Types: [2], Max: { 2: 50000 }, Decimals: { 2: 0 } },
            { Name: 'Main Generator Engine No.04', Types: [2], Max: { 2: 50000 }, Decimals: { 2: 0 } },
            { Name: 'Main Generator Engine No.05', Types: [2], Max: { 2: 50000 }, Decimals: { 2: 0 } },
            { Name: 'Gas Combustion Unit (GCU)', Types: [2], Max: { 2: 500 }, Decimals: { 2: 2 } }
        ],
        AllValueNameTypes: false,
        Max: { 2: 100 },
        Decimals: { 2: 0 },
        FuelType: true
    },
    { Id: 3, TypeName: 'EventConsumption', ValueNameTypes: [] },
    {
        Id: 4,
        TypeName: 'RunningHours',
        ValueNameTypes: [
            { Name: 'Auxiliary Boiler No.01 Stbd', Types: [3] },
            { Name: 'Auxiliary Boiler No.02 Port', Types: [3] },
            { Name: 'Electric Propulsion Motor No.01 - Stbd', Types: [3] },
            { Name: 'Electric Propulsion Motor No.02 - Port', Types: [3] },
            { Name: 'Main Boiler No.01 Stbd', Types: [3] },
            { Name: 'Main Boiler No.02 Port', Types: [3] },
            { Name: 'Main Generator Engine No.01', Types: [3] },
            { Name: 'Main Generator Engine No.02', Types: [3] },
            { Name: 'Main Generator Engine No.03', Types: [3] },
            { Name: 'Main Generator Engine No.04', Types: [3] }
        ],
        Max: { 3: 50 },
        Decimals: { 3: 0 }
    },
    {
        Id: 5,
        TypeName: 'AverageRpm',
        ValueNameTypes: [
            { Name: 'Electric Propulsion Motor No.01 - Stbd', Types: [4] },
            { Name: 'Electric Propulsion Motor No.02 - Port', Types: [4] }
        ],
        Max: { 4: 10000000 },
        Decimals: { 4: 2 }
    },
    { Id: 6, TypeName: 'Mcr', ValueNameTypes: [] },
    {
        Id: 7,
        TypeName: 'PropulsionMotor',
        ValueNameTypes: [
            { Name: 'Electric Propulsion Motor No.01 - Stbd', Types: [6, 7, 8] },
            { Name: 'Electric Propulsion Motor No.02 - Port', Types: [6, 7, 8] }
        ],
        Max: { 6: 3000000, 7: 20000, 8: 20000 },
        Decimals: { 6: 0, 7: 0, 8: 0 }
    },
    {
        Id: 8,
        TypeName: 'MainEngine',
        ValueNameTypes: [
            { Name: 'Main Generator Engine No.01', Types: [9, 10, 16] },
            { Name: 'Main Generator Engine No.02', Types: [9, 10, 16] },
            { Name: 'Main Generator Engine No.03', Types: [9, 10, 16] },
            { Name: 'Main Generator Engine No.04', Types: [9, 10, 16] }
        ],
        Max: { 9: 100000, 10: 500, 16: 20 },
        Decimals: { 9: 0, 10: 0, 16: 0 }
    },
    { Id: 9, TypeName: 'TurboAlternator', ValueNameTypes: [] },
    {
        Id: 10,
        TypeName: 'AuxiliaryEngine',
        ValueNameTypes: [
            { Name: 'Auxiliary Engine', Types: [10, 12] }
        ],
        Max: { 10: 5000, 12: 10 },
        Decimals: { 10: 0, 12: 0 }
    },
    {
        Id: 11,
        TypeName: 'MainTurbine',
        ValueNameTypes: [
            { Name: 'Main Propulsion Turbine', Types: [8, 13, 14] }
        ],
        Max: { 8: 30000, 13: 100000000, 14: 1000 },
        Decimals: { 8: 0, 13: 0, 14: 0 }
    },
    {
        Id: 12,
        TypeName: 'Boiler',
        ValueNameTypes: [
            { Name: 'Auxiliary Boiler No.01 Stbd', Types: [15] },
            { Name: 'Auxiliary Boiler No.02 Port', Types: [15] },
            { Name: 'Main Boiler No.01 Stbd', Types: [15] },
            { Name: 'Main Boiler No.02 Port', Types: [15] }
        ],
        Max: { 15: 100 },
        Decimals: { 15: 0 }
    }
];

const AuroraEquipmentItemValueTypes = [
    { Id: 1, Name: 'Hours' },
    { Id: 2, Name: 'Quantity' },
    { Id: 3, Name: 'RunningHours' },
    { Id: 4, Name: 'AverageRpm' },
    { Id: 5, Name: 'Mcr' },
    { Id: 6, Name: 'PropellerRevs' },
    { Id: 7, Name: 'MotorLoad' },
    { Id: 8, Name: 'ShaftPower' },
    { Id: 9, Name: 'Power' },
    { Id: 10, Name: 'SystemOilCons' },
    { Id: 11, Name: 'Load' },
    { Id: 12, Name: 'LoadKw' },
    { Id: 13, Name: 'Revs' },
    { Id: 14, Name: 'CondenserVacuum' },
    { Id: 15, Name: 'SteamFlow' },
    { Id: 16, Name: 'CylinderOilCons' }
];

const valueTypeMap = AuroraEquipmentItemValueTypes.reduce((res, item) => {
    res[item.Id] = item;
    return res;
}, {});

const trimTypes = ['Even Keel', 'Positive', 'Negative'];
const cargoTankNames = ['Cargo Tank No.01', 'Cargo Tank No.02', 'Cargo Tank No.03', 'Cargo Tank No.04'];
const cofferdamNames = ['Cofferdam No.01', 'Cofferdam No.02', 'Cofferdam No.03', 'Cofferdam No.04', 'Cofferdam No.05'];

const getEquipmentItem = (aeit, vnt, max, decimals, fuelType = null) => ({
    Id: MockHelper.getUniqueId('EquipmentItem'),
    Name: vnt.Name,
    Type: { Id: aeit.Id, Name: aeit.TypeName },
    Values: vnt.Types.map(vt => ({
        Id: MockHelper.getUniqueId('EquipmentItemValue'),
        Value: MockHelper.getRandomNumber(0, max[vt], decimals[vt], 0.1),
        ValueType: valueTypeMap[vt]
    })),
    FuelType: fuelType,
    EventType: null
});

export const mockAuroraReportData = () => {
    const FreshWaterProduced = MockHelper.getRandomNumber(5, 30);
    const DistilledWaterProduced = MockHelper.getRandomNumber(5, 30);
    const EquipmentItems = [];
    const fuelTypes = MockHelper.getRandomFuelTypes(2, 4);
    let max;
    let decimals;
    let valueNameTypes;
    AuroraEquipmentItemTypes.forEach(aeit => {
        valueNameTypes = aeit.AllValueNameTypes ? aeit.ValueNameTypes : MockHelper.getArraySlice(aeit.ValueNameTypes);
        valueNameTypes.forEach(vnt => {
            max = vnt.Max || aeit.Max;
            decimals = vnt.Decimals || aeit.Decimals;
            if (aeit.FuelType) {
                MockHelper.getArraySlice(fuelTypes, 1, 2).forEach(ft => {
                    EquipmentItems.push(getEquipmentItem(aeit, vnt, max, decimals, ft));
                });
            } else {
                EquipmentItems.push(getEquipmentItem(aeit, vnt, max, decimals));
            }
        });
    });
    return PortService.get({ ImportanceLevel: 15 }).then(ports => ({
        StartPort: MockHelper.getRandomArrayItem(ports, 0.01),
        VoyageBoilOff: MockHelper.getRandomNumber(0, 100, 1, 0.01),
        MainEngineMiles: MockHelper.getRandomNumber(0, 5000, 1, 0.01),
        DistanceThroughIce: MockHelper.getRandomNumber(0, 5000, 1, 0.01),
        CpWarrantedConsumption: MockHelper.getRandomNumber(0, 20, 1, 0.01),
        Trim: MockHelper.getRandomNumber(1, 20, 1, 0.01),
        TrimType: MockHelper.getRandomArrayItem(trimTypes, 0.01),
        ElectricalLoad: MockHelper.getRandomNumber(5000, 25000, 1, 0.01),
        CargoUsed: {
            ForceUsed: MockHelper.getRandomBoolean(),
            Hours: MockHelper.getRandomNumber(0, 100),
            CargoRob: MockHelper.getRandomNumber(0, 5000, 2)
        },
        Water: {
            FreshWaterConsumed: MockHelper.getRandomNumber(0, FreshWaterProduced),
            FreshWaterProduced,
            DistilledWaterConsumed: MockHelper.getRandomNumber(0, DistilledWaterProduced),
            DistilledWaterProduced
        },
        Temperatures: MockHelper.getArraySlice(cargoTankNames, 1, 4).map(tankName => ({
            Id: MockHelper.getUniqueId('Temperatures'),
            CargoTankName: tankName,
            Liquid: MockHelper.getRandomNumber(-160, -150, 2, 0.1),
            Vapour: MockHelper.getRandomNumber(-160, -150, 2, 0.1),
            Pressure: MockHelper.getRandomNumber(150, 170, 1, 0.1)
        })).sort(sortByProp('CargoTankName')),
        BarrierSpaceConditions: MockHelper.getArraySlice(cargoTankNames, 1, 4).map(tankName => ({
            Id: MockHelper.getUniqueId('BarrierSpaceConditions'),
            CargoTankName: tankName,
            SecondaryBarrierAvgTemp: MockHelper.getRandomNumber(-50, -30, 2, 0.1),
            PrimaryInsulationSpacePress: MockHelper.getRandomNumber(0, 8, 2, 0.1),
            SecondaryInsulationSpacePress: MockHelper.getRandomNumber(0, 8, 2, 0.1)
        })).sort(sortByProp('CargoTankName')),
        CofferdamTemps: MockHelper.getArraySlice(cofferdamNames, 5, 5).map(coffName => ({
            Id: MockHelper.getUniqueId('CofferdamTemps'),
            CofferdamName: coffName,
            TopTemp: MockHelper.getRandomNumber(5, 30, 2, 0.1),
            MiddleTemp: MockHelper.getRandomNumber(5, 30, 2, 0.1),
            BottomTemp: MockHelper.getRandomNumber(5, 30, 2, 0.1)
        })).sort(sortByProp('CofferdamName')),
        EquipmentItems
    }));
};
