/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import ScrollArea from 'components/scroll-area/scroll-area';
import './reorderable-items-list.scss';

export default class ReorderableItemsList extends React.Component {
    static FIRST_DROPZONE_KEY = 'FIRST_DROPZONE_KEY';

    static NOT_EXIST = -1;

    static DRAG_START_TIMEOUT = 200;

    state = {
        isDragging: false,
        indexOfItemBeingDragged: ReorderableItemsList.NOT_EXIST,
        highlightedDropZoneIndex: ReorderableItemsList.NOT_EXIST
    };

    dragItemWrapperRefs = {};

    handleMouseDown = (index, e) => {
        const domEvent = e.nativeEvent;
        window.addEventListener('mouseup', this.handleMouseUp);
        this.dragstartTimeoutHandle = setTimeout(() => {
            this.clearTextSelection();
            this.createGhostElement(index, domEvent);
            this.updateGhostElementPosition(domEvent);
            window.addEventListener('mousemove', this.updateGhostElementPosition);
            this.setState({
                isDragging: true,
                indexOfItemBeingDragged: index
            });
        }, ReorderableItemsList.DRAG_START_TIMEOUT);
    };

    updateGhostElementPosition = (e) => {
        this.ghostElement.style.transform = `translate(${e.pageX}px, ${e.pageY}px)`;
    };

    createGhostElement = (index, e) => {
        const itemDomNode = this.dragItemWrapperRefs[index];
        const dimensions = itemDomNode.getBoundingClientRect();
        const wrapper = document.createElement('div');
        wrapper.style.width = `${dimensions.width}px`;
        wrapper.style.height = `${dimensions.height}px`;
        wrapper.style.position = 'fixed';
        wrapper.style.top = `${dimensions.top - e.clientY}px`;
        wrapper.style.left = `${dimensions.left - e.clientX}px`;
        wrapper.style.pointerEvents = 'none';
        wrapper.style.willChange = 'transform, translate';
        wrapper.setAttribute(
            'class',
            `${wrapper.getAttribute('class')} reorderable-items-list__item`
        );
        wrapper.appendChild(itemDomNode.cloneNode(true));
        this.ghostElement = wrapper;
        document.body.appendChild(this.ghostElement);
    };

    killGhostElement = () => {
        if (this.ghostElement) {
            document.body.removeChild(this.ghostElement);
            this.ghostElement = undefined;
        }
    };

    componentWillUnmount() {
        this.killGhostElement();
    }

    handleMouseUp = () => {
        clearTimeout(this.dragstartTimeoutHandle);
        if (this.state.isDragging) {
            window.removeEventListener('mousemove', this.updateGhostElementPosition);
            window.removeEventListener('mouseup', this.handleMouseUp);
            this.killGhostElement();

            const { highlightedDropZoneIndex, indexOfItemBeingDragged } = this.state;
            const shouldReorderItems = highlightedDropZoneIndex !== ReorderableItemsList.NOT_EXIST
                || (highlightedDropZoneIndex + 1 !== indexOfItemBeingDragged,
                highlightedDropZoneIndex !== indexOfItemBeingDragged);

            if (shouldReorderItems) {
                this.props.onDragComplete(
                    indexOfItemBeingDragged,
                    highlightedDropZoneIndex
                );
            }

            this.setState({
                isDragging: false,
                indexOfItemBeingDragged: ReorderableItemsList.NOT_EXIST
            });
        }
    };

    saveDragItemWrapperRef = (index, ref) => {
        this.dragItemWrapperRefs[index] = ref;
    };

    renderItem = (item, index) => {
        const content = this.props.renderItem(
            this.handleMouseDown.bind(this, index),
            item,
            index
        );
        return (
            <div
                key={content.key}
                ref={this.saveDragItemWrapperRef.bind(this, index)}
                onMouseMove={this.determineWhichDropZoneShouldBeHighlighted.bind(this, index)}
            >
                {content}
            </div>
        );
    };

    determineWhichDropZoneShouldBeHighlighted = (index, e) => {
        if (this.state.isDragging) {
            const rect = e.currentTarget.getBoundingClientRect();
            const yPositionWithinElement = e.clientY - rect.top;
            if (yPositionWithinElement > rect.height / 2) { // lower half
                if (this.state.highlightedDropZoneIndex !== index + 1) {
                    this.highlightDropZone(index + 1);
                }
            } else if (this.state.highlightedDropZoneIndex !== index) { // upper half
                this.highlightDropZone(index);
            }
        }
    };

    highlightDropZone = (dropZoneIndex) => {
        if (dropZoneIndex !== this.state.highlightedDropZoneIndex) {
            this.setState({
                highlightedDropZoneIndex: dropZoneIndex
            });
        }
    };

    getDropZoneKey = (itemReactEl) => `dropzone-${itemReactEl.key}`;

    renderItemsWithDropZones() {
        const { highlightedDropZoneIndex, indexOfItemBeingDragged } = this.state;
        const { renderDropZone, items } = this.props;
        let dropZoneIndex;
        let dropZone;

        const rows = [];
        let itemReactEl;
        items.forEach((item, index) => {
            if (index !== indexOfItemBeingDragged) { // skip dragged item
                itemReactEl = this.renderItem(item, index);
                rows.push(itemReactEl);
                dropZoneIndex = index + 1;
                dropZone = renderDropZone({
                    isHovered: dropZoneIndex === highlightedDropZoneIndex,
                    onMouseMove: this.highlightDropZone.bind(this, dropZoneIndex),
                    key: this.getDropZoneKey(itemReactEl)
                });
                rows.push(dropZone);
            }
        }, this);

        const firstDropZoneIndex = 0;
        rows.unshift(renderDropZone({
            isHovered: highlightedDropZoneIndex === firstDropZoneIndex,
            onMouseMove: this.highlightDropZone.bind(this, firstDropZoneIndex),
            key: ReorderableItemsList.FIRST_DROPZONE_KEY
        }));

        return rows;
    }

    clearTextSelection = () => {
        if (window.getSelection) {
            if (window.getSelection().empty) { // Chrome
                window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) { // Firefox
                window.getSelection().removeAllRanges();
            }
        } else if (document.selection) { // IE?
            document.selection.empty();
        }
    };

    renderItemsWithDropZonePlaceholders() {
        const { renderDropZonePlaceholder } = this.props;
        const rows = [];
        let itemReactEl;
        this.props.items.forEach((item, index) => {
            itemReactEl = this.renderItem(item, index);
            rows.push(itemReactEl);
            rows.push(renderDropZonePlaceholder(this.getDropZoneKey(itemReactEl)));
        }, this);

        rows.unshift(renderDropZonePlaceholder(ReorderableItemsList.FIRST_DROPZONE_KEY));

        return rows;
    }

    renderScrollArea = (content) => {
        return (
            <ScrollArea
                {...this.props.scrollAreaProps}
                dragMode={this.state.isDragging}
            >
                {content}
            </ScrollArea>
        );
    };

    render() {
        const content = this.state.isDragging
            ? this.renderItemsWithDropZones()
            : this.renderItemsWithDropZonePlaceholders();

        return this.props.scrollAreaProps
            ? this.renderScrollArea(content)
            : content;
    }
}

ReorderableItemsList.propTypes = {
    items: PropTypes.arrayOf(PropTypes.any).isRequired,
    renderDropZone: PropTypes.func,
    renderDropZonePlaceholder: PropTypes.func, // should be same height as drop zone
    onDragComplete: PropTypes.func.isRequired,
    scrollAreaProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    renderItem: PropTypes.func.isRequired
};

ReorderableItemsList.defaultProps = {
    renderDropZone: ({ isHovered, onMouseMove, key }) => (
        <div
            key={key}
            onMouseMove={onMouseMove}
            style={{ height: '4px', backgroundColor: isHovered ? 'yellow' : 'cyan' }}
        />
    ),
    renderDropZonePlaceholder: (key) => (
        <div key={key} style={{ height: '4px' }} />
    ),
    scrollAreaProps: undefined
};
