import CoreApi from './core-api';

class MapOptionsService {
    constructor() {
        this.resourceUrl = 'Map';
    }

    getMapTypes() {
        return CoreApi.get(`${this.resourceUrl}/MapTypes`)
            .then(response => response.data, () => []);
    }

    getLayerTypes() {
        return CoreApi.get(`${this.resourceUrl}/LayerTypes`)
            .then(response => response.data, () => []);
    }

    getMarketIntelLayerTypes() {
        return CoreApi.get(`${this.resourceUrl}/MarketIntelLayerTypes`)
            .then(response => response.data, () => []);
    }

    getWeatherLayerTypes() {
        return CoreApi.get(`${this.resourceUrl}/WeatherLayerTypes`)
            .then(response => response.data, () => []);
    }

    getWeatherLayer(params) {
        return CoreApi.get(`${this.resourceUrl}/WeatherLayer`, params)
            .then(response => response.data, () => null);
    }
}

export default new MapOptionsService();
