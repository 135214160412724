import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { findTotalTopOffset } from 'utils/helpers/html-helper';
/* components */
import Accordion from 'components/accordion/accordion';
import DateRangePopup from 'components/date-range-popup/date-range-popup';
import FlipSwitch from 'components/flip-switch/flip-switch';
import IncludedVesselsSection from '../included-vessels-section';
import Input from 'components/input/input';
import ScrollArea from 'components/scroll-area/scroll-area';
import Select from 'components/select/select';
import Validation from 'components/validation/validation';
/* actions */
import { updateFilters } from '../../reports-actions';

const emptyObj = {};

const labelHeight = 25;

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.SIDEBAR.${key}`);

class FleetAverageSpeedReportInclusions extends React.PureComponent {
    handleFormSubmit = () => {
        const filters = {
            selectedVessels: this.includedVesselsSectionRef.state.selectedVessels
        };

        this.props.updateFilters(filters);

        if (this.props.onSubmit) {
            this.props.onSubmit(filters);
        }
    }

    scrollToInvalidField = () => {
        if (this.scrollAreaRef && this.scrollAreaRef.contentWrapperRef) {
            const scrollAreaDiv = this.scrollAreaRef.contentWrapperRef;
            const elements = scrollAreaDiv.getElementsByClassName(
                'sten-validation-wrapper--invalid'
            );

            if (elements && elements[0]) {
                const totalOffsetTop = findTotalTopOffset(elements[0], scrollAreaDiv);
                this.scrollAreaRef.scrollYTo(totalOffsetTop - labelHeight);
            }
        }
    };

    renderFooter = () => (
        <footer className="sten-content__footer flex-row">
            <div className="col-12">
                <button
                    onClick={this.props.resetInclusions}
                    className="btn btn--secondary col-24"
                    type="button"
                >
                    {t('RESET_PARAMETERS')}
                </button>
            </div>
            <div className="col-12">
                <Validation.Button className="btn btn--primary col-24">
                    {t('CALCULATE')}
                </Validation.Button>
            </div>
        </footer>
    );

    render() {
        const inclusions = this.props.inclusions || emptyObj;
        const { setInclusionValue, toggleInclusionValue, selectedRange } = this.props;
        const hasInclusions = typeof inclusions === 'object' && Object.keys(inclusions).length > 0;
        return (
            <Validation.Form
                className="sten-energy-management-reports-sidebar-inclusions"
                onSubmit={this.handleFormSubmit}
                onInvalid={this.scrollToInvalidField}
            >
                <ScrollArea
                    className="sten-content__body"
                    ref={r => { this.scrollAreaRef = r; }}
                    shouldScrollToActiveElement
                >
                    <div className="sten-content__section">
                        <div className="form-row">
                            <label className="label">{t('DATA_RANGE')}</label>
                            <Validation.Wrapper validations={{ dateRange: true }}>
                                <DateRangePopup
                                    isUTC
                                    alignment="end"
                                    shouldRenderDefaultPresets
                                    onRangeSelect={this.props.setSelectedRange}
                                    name="dateRange"
                                    value={selectedRange}
                                />
                            </Validation.Wrapper>
                        </div>
                    </div>
                    <IncludedVesselsSection
                        ref={(r) => { this.includedVesselsSectionRef = r; }}
                        segments={this.props.segments}
                        vessels={this.props.vessels}
                        vesselsBySegment={this.props.vesselsBySegment}
                        initialSelectedVessels={this.props.selectedVessels}
                    />
                    <Accordion
                        header={<h4 className="text-uppercase">{t('PARAMETERS')}</h4>}
                        isCollapsedBodyRendered
                    >
                        <div className="sten-content__section">
                            <div className="form-row">
                                <label className="label">{t('PRESET')}</label>
                                <Validation.Wrapper validations={{ required: true }}>
                                    <Select
                                        name="fleet"
                                        options={this.props.inclusionPresets}
                                        labelKey="Name"
                                        valueKey="Id"
                                        value={this.props.selectedInclusionPreset}
                                        onChange={this.props.setSelectedInclusionPreset}
                                        placeholder={t('SELECT_PRESET_PLACEHOLDER')}
                                    />
                                </Validation.Wrapper>
                            </div>
                        </div>
                        <div className="sten-content__section">
                            <div className="form-row">
                                <div className="flex-row flex-center">
                                    <div className="col-10">
                                        <label className="label">{t('INSTRUCTED_SPEED_BALLAST')}</label>
                                    </div>
                                    <div className="col-7">
                                        <Validation.Wrapper
                                            hintsOnHover
                                            validations={{
                                                required: !!this.props.selectedInclusionPreset && hasInclusions,
                                                numeric: { min: 0, max: 99, maxProp: 'InstructedSpeedBallastMax' }
                                            }}
                                        >
                                            <Input
                                                prefix="min"
                                                name="InstructedSpeedBallastMin"
                                                value={inclusions.InstructedSpeedBallastMin}
                                                onChange={setInclusionValue('InstructedSpeedBallastMin')}
                                                disabled={!this.props.selectedInclusionPreset}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                    <div className="col-7">
                                        <Validation.Wrapper
                                            hintsOnHover
                                            validations={{
                                                required: !!this.props.selectedInclusionPreset && hasInclusions,
                                                numeric: { min: 0, max: 99, minProp: 'InstructedSpeedBallastMin' }
                                            }}
                                        >
                                            <Input
                                                prefix="max"
                                                name="InstructedSpeedBallastMax"
                                                value={inclusions.InstructedSpeedBallastMax}
                                                onChange={setInclusionValue('InstructedSpeedBallastMax')}
                                                disabled={!this.props.selectedInclusionPreset}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">{t('INSTRUCTED_SPEED_LADEN')}</label>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset && hasInclusions,
                                            numeric: { min: 0, max: 99, maxProp: 'InstructedSpeedLadenMax' }
                                        }}
                                    >
                                        <Input
                                            prefix="min"
                                            name="InstructedSpeedLadenMin"
                                            value={inclusions.InstructedSpeedLadenMin}
                                            onChange={setInclusionValue('InstructedSpeedLadenMin')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset && hasInclusions,
                                            numeric: { min: 0, max: 99, minProp: 'InstructedSpeedLadenMin' }
                                        }}
                                    >
                                        <Input
                                            prefix="max"
                                            name="InstructedSpeedLadenMax"
                                            value={inclusions.InstructedSpeedLadenMax}
                                            onChange={setInclusionValue('InstructedSpeedLadenMax')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="flex-row flex-center">
                                    <div className="col-10">
                                        <label className="label">{t('ACTUAL_SPEED_BALLAST')}</label>
                                    </div>
                                    <div className="col-7">
                                        <Validation.Wrapper
                                            hintsOnHover
                                            validations={{
                                                required: !!this.props.selectedInclusionPreset && hasInclusions,
                                                numeric: { min: 0, max: 99, maxProp: 'ActualSpeedBallastMax' }
                                            }}
                                        >
                                            <Input
                                                prefix="min"
                                                name="ActualSpeedBallastMin"
                                                value={inclusions.ActualSpeedBallastMin}
                                                onChange={setInclusionValue('ActualSpeedBallastMin')}
                                                disabled={!this.props.selectedInclusionPreset}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                    <div className="col-7">
                                        <Validation.Wrapper
                                            hintsOnHover
                                            validations={{
                                                required: !!this.props.selectedInclusionPreset && hasInclusions,
                                                numeric: { min: 0, max: 99, minProp: 'ActualSpeedBallastMin' }
                                            }}
                                        >
                                            <Input
                                                prefix="max"
                                                name="ActualSpeedBallastMax"
                                                value={inclusions.ActualSpeedBallastMax}
                                                onChange={setInclusionValue('ActualSpeedBallastMax')}
                                                disabled={!this.props.selectedInclusionPreset}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">{t('ACTUAL_SPEED_LADEN')}</label>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset && hasInclusions,
                                            numeric: { min: 0, max: 99, maxProp: 'ActualSpeedLadenMax' }
                                        }}
                                    >
                                        <Input
                                            prefix="min"
                                            name="ActualSpeedLadenMin"
                                            value={inclusions.ActualSpeedLadenMin}
                                            onChange={setInclusionValue('ActualSpeedLadenMin')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset && hasInclusions,
                                            numeric: { min: 0, max: 99, minProp: 'ActualSpeedLadenMin' }
                                        }}
                                    >
                                        <Input
                                            prefix="max"
                                            name="ActualSpeedLadenMax"
                                            value={inclusions.ActualSpeedLadenMax}
                                            onChange={setInclusionValue('ActualSpeedLadenMax')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                        </div>
                        <div className="sten-content__section sten-content__separator sten-content__separator--inner">
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">{t('CARGO_CARRIED')}</label>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset && hasInclusions,
                                            numeric: { min: 0, max: 999999, maxProp: 'CargoCarriedMax' }
                                        }}
                                    >
                                        <Input
                                            prefix="min"
                                            name="CargoCarriedMin"
                                            value={inclusions.CargoCarriedMin}
                                            onChange={setInclusionValue('CargoCarriedMin')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset && hasInclusions,
                                            numeric: { min: 0, max: 999999, minProp: 'CargoCarriedMin' }
                                        }}
                                    >
                                        <Input
                                            prefix="max"
                                            name="CargoCarriedMax"
                                            value={inclusions.CargoCarriedMax}
                                            onChange={setInclusionValue('CargoCarriedMax')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="flex-grow">
                                    <label className="label">{t('INCLUDE_HEAT')}</label>
                                </div>
                                <div className="flex-shrink">
                                    <FlipSwitch
                                        value={inclusions.IncludeHeat || false}
                                        activeLabel={translate('GLOBAL.YES')}
                                        inactiveLabel={translate('GLOBAL.NO')}
                                        disabled={!this.props.selectedInclusionPreset}
                                        onChange={toggleInclusionValue('IncludeHeat')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sten-content__section sten-content__separator sten-content__separator--inner">
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">{t('SEA_PASSAGE')}</label>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset && hasInclusions,
                                            numeric: { min: 0, max: 99999, maxProp: 'SeaPassageMax' }
                                        }}
                                    >
                                        <Input
                                            prefix="min"
                                            name="SeaPassageMin"
                                            value={inclusions.SeaPassageMin}
                                            onChange={setInclusionValue('SeaPassageMin')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset && hasInclusions,
                                            numeric: { min: 0, max: 99999, minProp: 'SeaPassageMin' }
                                        }}
                                    >
                                        <Input
                                            prefix="max"
                                            name="SeaPassageMax"
                                            value={inclusions.SeaPassageMax}
                                            onChange={setInclusionValue('SeaPassageMax')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">{t('HOURS_STEAMED')}</label>
                                </div>
                                <div className="col-14">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset,
                                            numeric: { min: 0, max: 25, decimals: 0 }
                                        }}
                                    >
                                        <Input
                                            prefix="min"
                                            name="HoursSteamedMin"
                                            value={inclusions.HoursSteamedMin}
                                            onChange={setInclusionValue('HoursSteamedMin')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">{t('WIND_FORCE')}</label>
                                </div>
                                <div className="col-14">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset && hasInclusions,
                                            numeric: { min: 0, max: 12, decimals: 0 }
                                        }}
                                    >
                                        <Input
                                            prefix="max"
                                            name="WindForceMax"
                                            value={inclusions.WindForceMax}
                                            onChange={setInclusionValue('WindForceMax')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">{t('TOTAL_AUX_CONSUMPTION')}</label>
                                </div>
                                <div className="col-14">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset && hasInclusions,
                                            numeric: { min: 0, max: 99999, decimals: 0 }
                                        }}
                                    >
                                        <Input
                                            prefix="max"
                                            name="TotalAuxConsumptionMax"
                                            value={inclusions.TotalAuxConsumptionMax}
                                            onChange={setInclusionValue('TotalAuxConsumptionMax')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="row">
                                    <div className="col-7 col-offset-10">
                                        <label className="label text-center">{t('BALLAST')}</label>
                                    </div>
                                    <div className="col-7">
                                        <label className="label text-center">{t('LADEN')}</label>
                                    </div>
                                </div>
                                <div className="flex-row flex-center">
                                    <div className="col-10">
                                        <label className="label">{t('DAYS')}</label>
                                    </div>
                                    <div className="col-7">
                                        <Validation.Wrapper
                                            hintsOnHover
                                            validations={{
                                                required: !!this.props.selectedInclusionPreset && hasInclusions,
                                                numeric: { min: 0, max: 99999, decimals: 0 }
                                            }}
                                        >
                                            <Input
                                                prefix="min"
                                                name="BallastDaysMax"
                                                value={inclusions.BallastDaysMax}
                                                onChange={setInclusionValue('BallastDaysMax')}
                                                disabled={!this.props.selectedInclusionPreset}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                    <div className="col-7">
                                        <Validation.Wrapper
                                            hintsOnHover
                                            validations={{
                                                required: !!this.props.selectedInclusionPreset && hasInclusions,
                                                numeric: { min: 0, max: 99999, decimals: 0 }
                                            }}
                                        >
                                            <Input
                                                prefix="min"
                                                name="LadenDaysMax"
                                                value={inclusions.LadenDaysMax}
                                                onChange={setInclusionValue('LadenDaysMax')}
                                                disabled={!this.props.selectedInclusionPreset}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="flex-grow">
                                    <label className="label">{t('INCLUDE_TC_VOYAGES')}</label>
                                </div>
                                <div className="flex-shrink">
                                    <FlipSwitch
                                        value={inclusions.IncludeTc || false}
                                        activeLabel={translate('GLOBAL.YES')}
                                        inactiveLabel={translate('GLOBAL.NO')}
                                        disabled={!this.props.selectedInclusionPreset}
                                        onChange={toggleInclusionValue('IncludeTc')}
                                    />
                                </div>
                            </div>
                        </div>
                    </Accordion>
                </ScrollArea>
                {this.renderFooter()}
            </Validation.Form>
        );
    }
}

FleetAverageSpeedReportInclusions.propTypes = {
    inclusionPresets: PropTypes.arrayOf(PropTypes.object).isRequired,
    inclusions: PropTypes.objectOf(PropTypes.any),
    onSubmit: PropTypes.func.isRequired,
    resetInclusions: PropTypes.func.isRequired,
    segments: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedInclusionPreset: PropTypes.objectOf(PropTypes.any),
    selectedRange: PropTypes.objectOf(PropTypes.any),
    selectedVessels: PropTypes.objectOf(PropTypes.any).isRequired,
    setInclusionValue: PropTypes.func.isRequired,
    setSelectedInclusionPreset: PropTypes.func.isRequired,
    setSelectedRange: PropTypes.func.isRequired,
    toggleInclusionValue: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    vessels: PropTypes.arrayOf(PropTypes.any).isRequired,
    vesselsBySegment: PropTypes.objectOf(PropTypes.any).isRequired
};

FleetAverageSpeedReportInclusions.defaultProps = {
    inclusions: null,
    selectedInclusionPreset: null,
    selectedRange: null
};

function mapStateToProps(state) {
    return {
        segments: state.energyManagementReportsReducer.segments,
        selectedVessels: state.energyManagementReportsReducer.filters.selectedVessels,
        vessels: state.energyManagementReportsReducer.vessels
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateFilters: filters => updateFilters(dispatch, filters)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FleetAverageSpeedReportInclusions);
