import React from 'react';
import { takeEvery, put, select, delay } from 'redux-saga/effects';
import ReactDOMServer from 'react-dom/server';
/* utils */
import { getConvertedObject } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
import { getLogoBase64 } from 'utils/base64images';
/* actions */
import { ActionTypes } from './performance-summary-actions';
/* services */
import EnergyManagementService from 'services/core-api/energy-management-service';
import PdfGeneratorService from 'services/core-api/pdf-generator-service';
/* exports */
import generatePerformanceSummaryReportXLSX from './export/performance-summary-report-xlsx';
import PerformanceSummaryReportPdf from './export/performance-summary-report-pdf';
/* prop types */
import inclusionPropTypes from '../../sidebar/inclusions/inclusion-prop-types';

function* prepareReportDataForExport() {
    const { selectedRange } = yield select(state => state.energyManagementReportsReducer);
    const reportData = yield select(
        state => state.energyManagementReportsPerformanceSummaryReportReducer.performanceSummaryReport
    );
    const rangeStart = selectedRange && TimeHelper.getFormatted(selectedRange.rangeStart);
    const rangeEnd = selectedRange && TimeHelper.getFormatted(selectedRange.rangeEnd);
    const fileName = `Performance_Summary_Report_${rangeStart}_${rangeEnd}`;
    return {
        reportData,
        fileName,
        rangeStart,
        rangeEnd,
        orbitLogo: getLogoBase64().dark
    };
}

function* generatePDF() {
    const { selectedRange, inclusions } = yield select(state => state.energyManagementReportsReducer);
    const { performanceSummaryReport } = yield select(state =>
        state.energyManagementReportsPerformanceSummaryReportReducer);
    const rangeStart = selectedRange && TimeHelper.getFormatted(selectedRange.rangeStart);
    const rangeEnd = selectedRange && TimeHelper.getFormatted(selectedRange.rangeEnd);
    const fileName = `Performance_Summary_Report_${rangeStart}_${rangeEnd}`;
    const htmlString = ReactDOMServer.renderToStaticMarkup(
        <PerformanceSummaryReportPdf
            data={performanceSummaryReport}
            selectedRange={selectedRange}
            inclusions={inclusions}
        />
    );

    PdfGeneratorService.convert({ html: htmlString, waitFor: 100 }, fileName);
}

function* getPerformanceSummaryReport() {
    const { filters, inclusions, selectedRange, CPSpeed } = yield select(state => state.energyManagementReportsReducer);
    let IncludedVessels = [];
    IncludedVessels = IncludedVessels.concat(Object.keys(filters.selectedVessels));
    const parsedCPSpeed = {
        AuxCons: parseFloat(CPSpeed.AuxCons),
        Speeds: CPSpeed.Speeds.map((speed, index) => (
            getConvertedObject(CPSpeed.Speeds[index], inclusionPropTypes)
        ))
    };
    const convertedInclusions = getConvertedObject(inclusions, inclusionPropTypes);
    const params = {
        Inclusions: convertedInclusions,
        StartDate: selectedRange && selectedRange.rangeStart,
        EndDate: selectedRange && selectedRange.rangeEnd,
        IncludedVessels,
        CPSpeed: parsedCPSpeed
    };
    const performanceSummaryReport = yield EnergyManagementService.Reports.PerformanceSummary.getReport(params);
    yield put({ type: ActionTypes.ENERGY_MANAGEMENT_PERFORMANCE_SUMMARY_REPORT_SET, performanceSummaryReport });
}

function* generateXLSX() {
    yield put({ type: 'TOGGLE_LOADER', payload: true });
    yield delay(0);
    const reportData = yield prepareReportDataForExport();
    yield generatePerformanceSummaryReportXLSX(reportData);
    yield put({ type: 'TOGGLE_LOADER', payload: false });
}

export default function* energyManagementReportsPerformanceSummaryReportSaga() {
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_PERFORMANCE_SUMMARY_REPORT_GENERATE, getPerformanceSummaryReport);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_PERFORMANCE_SUMMARY_REPORT_GENERATE_XLSX, generateXLSX);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_PERFORMANCE_SUMMARY_REPORT_GENERATE_PDF, generatePDF);
}
