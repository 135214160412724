import createByIdSelector from 'utils/helpers/selector-helper';
import { createSelector } from 'reselect';

export const commercialOperatorsById = createByIdSelector(
    state => state.adminVesselManagementFormReducer.commercialOperators,
    'Id'
);
export const headOwnersById = createByIdSelector(state => state.adminVesselManagementFormReducer.headOwners, 'Id');
export const technicalManagersById = createByIdSelector(
    state => state.adminVesselManagementFormReducer.technicalManagers,
    'Id'
);
export const tradingAreasById = createByIdSelector(state => state.adminVesselManagementReducer.tradingAreas, 'Id');
export const segmentsById = createByIdSelector(state => state.adminVesselManagementReducer.segments, 'VesselTypeId');

export const getFormData = createSelector(
    state => state.adminVesselManagementFormReducer.editingVessel,
    commercialOperatorsById,
    headOwnersById,
    technicalManagersById,
    tradingAreasById,
    segmentsById,

    (vessel, co, ho, tm, ta, s) => {
        const lockedFields = {};
        const valueFields = {};
        if (!vessel) {
            return { editingVessel: null, lockedFields };
        }
        for (const key of Object.keys(vessel)) {
            if (vessel[key]) {
                if (vessel[key].Locked) {
                    lockedFields[key] = true;
                }
                if (vessel[key].Value !== undefined) {
                    valueFields[key] = vessel[key].Value;
                }
            }
        }

        return {
            lockedFields,
            editingVessel: {
                ...vessel,
                ...valueFields,
                CommercialOperator: vessel && co[vessel.CommercialOperatorId]
                    ? co[vessel.CommercialOperatorId] : null,
                VesselOwner: vessel && ho[vessel.OwnerId] ? ho[vessel.OwnerId] : null,
                TechnicalManager: vessel && tm[vessel.TechnicalManagerId]
                    ? tm[vessel.TechnicalManagerId] : null,
                TradingArea: vessel && ta[vessel.TradingAreaId] ? ta[vessel.TradingAreaId] : null,
                VesselSegment: vessel && s[vessel.VesselSegmentId] ? s[vessel.VesselSegmentId] : null
            }
        };
    }
);
