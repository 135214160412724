export const ActionTypes = {
    VOYAGE_INFO_GET_RESOURCES: 'VOYAGE_INFO_GET_RESOURCES',
    VOYAGE_INFO_SET_RESOURCES: 'VOYAGE_INFO_SET_RESOURCES',
    VOYAGE_INFO_CARGO_GENERATE_XLSX: 'VOYAGE_INFO_CARGO_GENERATE_XLSX',
    VOYAGE_INFO_CARGO_GENERATE_PDF: 'VOYAGE_INFO_CARGO_GENERATE_PDF',
    VOYAGE_INFO_RESET: 'VOYAGE_INFO_RESET',
    VOYAGE_INFO_SET_COMMENTS: 'VOYAGE_INFO_SET_COMMENTS'
};

export function getResources(dispatch, voyageId) {
    dispatch({ type: ActionTypes.VOYAGE_INFO_GET_RESOURCES, voyageId });
}

export function generateCargoXLSX(dispatch, cargoSummary, tankLayout, vesselName) {
    dispatch({
        type: ActionTypes.VOYAGE_INFO_CARGO_GENERATE_XLSX,
        cargoSummary,
        tankLayout,
        vesselName
    });
}

export function generateCargoPDF(dispatch, cargoSummary, tankLayout, vesselName) {
    dispatch({
        type: ActionTypes.VOYAGE_INFO_CARGO_GENERATE_PDF,
        cargoSummary,
        tankLayout,
        vesselName
    });
}

export function reset(dispatch) {
    dispatch({ type: ActionTypes.VOYAGE_INFO_RESET });
}
