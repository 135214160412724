import React from 'react';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import ContactListFilters from './contact-list-filters/contact-list-filters';
import ContactListTable from './contact-list-table/contact-list-table';
/* styles */
import './contact-list.scss';

class ContactList extends React.PureComponent {
    render() {
        return (
            <div className="sten-contact-list-page sten-content">
                <div className="sten-content__header">
                    <h1 className="sten-content__title">{translate('CONTACT_LIST.TITLE')}</h1>
                </div>
                <div className="sten-content__body">
                    <div className="sten-contact-list-content__top">
                        <ContactListFilters />
                    </div>
                    <div className="sten-contact-list-content__bottom">
                        <ContactListTable />
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactList;
