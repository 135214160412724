import { createSelector } from 'reselect';
/* services */
import LocalStorageService from 'services/local-storage-service';
/* mocks */
import { generateMockedFleetListReport } from './fleet-list-report-test-utils';
/* constants */
import { colProps } from './fleet-list-report-constants';

export const getTableData = createSelector(
    state => state.energyManagementReportsFleetListReportReducer.report,
    (report) => {
        const data = LocalStorageService.isDebugModeActive()
            ? generateMockedFleetListReport()
            : report;
        if (!data) {
            return null;
        }

        return { parsedData: data, columns: colProps };
    }
);
