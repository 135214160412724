import { ActionTypes } from './inspections-form-actions';

const emptyArray = [];

const initialState = {
    technicalManagers: emptyArray,
    headOwners: emptyArray,
    inspectionCompanies: emptyArray,
    editingInspection: {
        Vessel: null,
        InspectionDate: null,
        Port: null,
        NumberOfObservations: '',
        Comment: '',
        InspectionActivityId: null,
        TechnicalManagerId: null,
        HeadOwnerId: null,
        InspectionTypeId: null,
        InspectionCompanyId: null,
        Files: emptyArray,
        FileIdsForDelete: emptyArray
    }
};

export default function inspectionsFormReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.INSPECTIONS_FORM_SET_VALUE: {
        if (action.field) {
            const editingInspection = { ...state.editingInspection };
            editingInspection[action.field] = action.value;
            return {
                ...state,
                editingInspection
            };
        }
        return state;
    }
    case ActionTypes.INSPECTIONS_FORM_SET_UPLOADED_FILES: {
        return {
            ...state,
            editingInspection: {
                ...state.editingInspection,
                Files: action.files
            }
        };
    }
    case ActionTypes.INSPECTIONS_FORM_REMOVE_FILE: {
        const files = state.editingInspection.Files.slice();
        const fileIdsForDelete = state.editingInspection.FileIdsForDelete
            ? state.editingInspection.FileIdsForDelete.slice()
            : [];
        fileIdsForDelete.push(state.editingInspection.Files[action.index].id);
        files.splice(action.index, 1);
        return {
            ...state,
            editingInspection: {
                ...state.editingInspection,
                Files: files,
                FileIdsForDelete: fileIdsForDelete
            }
        };
    }
    case ActionTypes.INSPECTIONS_FORM_SET_NEW_INSPECTION_COMPANY: {
        return {
            ...state,
            editingInspection: {
                ...state.editingInspection,
                InspectionCompanyId: action.newInspectionCompanyId
            }
        };
    }
    case ActionTypes.INSPECTIONS_FORM_SET_EDITING_INSPECTION: {
        return {
            ...state,
            editingInspection: action.editingInspection
        };
    }
    case ActionTypes.INSPECTIONS_FORM_SET_HEAD_OWNERS: {
        return {
            ...state,
            headOwners: action.headOwners
        };
    }
    case ActionTypes.INSPECTIONS_FORM_SET_TECHNICAL_MANAGERS: {
        return {
            ...state,
            technicalManagers: action.technicalManagers
        };
    }
    case ActionTypes.INSPECTIONS_FORM_SET_INSPECTION_COMPANIES: {
        return {
            ...state,
            inspectionCompanies: action.inspectionCompanies
        };
    }
    case ActionTypes.INSPECTIONS_FORM_RESET_ALL: {
        return {
            ...initialState,
            inspectionCompanies: state.inspectionCompanies
        };
    }
    default:
        return state;
    }
}
