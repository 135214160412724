import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ScrollArea from 'components/scroll-area/scroll-area';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { renderBadges } from 'pages/user-pages/fleet-management/fleet-management-utils';
import { formatNumber } from 'utils/helpers/info-helper';
/* actions */
import { getVesselFilters } from './vessel-filter-actions';
import { updateUserSettings } from 'pages/user-pages/user-actions';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import Accordion from 'components/accordion/accordion';
import VesselFilterRow from './vessel-filter-row/vessel-filter-row';

const segmentTypeNameMap = {
    Tanker: t('VESSEL_FILTER.TANKER_VESSELS'),
    DryBulk: t('VESSEL_FILTER.DRY_BULK_VESSELS')
};

export class VesselFilter extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            selectedCompanies: props.selectedCompanies,
            selectedCompetition: props.selectedCompetition,
            selectedFleets: props.selectedFleets,
            selectedSegments: props.selectedSegments,
            areFiltersChanged: false
        };
    }

    componentDidMount() {
        if (this.props.getVesselFilters) {
            this.props.getVesselFilters();
        }
    }

    toggleItemInList = (item, list) => {
        const newList = list.slice();
        const foundItemIndex = newList.indexOf(item);
        if (foundItemIndex < 0) {
            newList.push(item);
        } else {
            newList.splice(foundItemIndex, 1);
        }
        return newList;
    };

    handleFleetChange = (value) => {
        const selectedFleets = this.toggleItemInList(value, this.state.selectedFleets);
        const newState = { selectedFleets, areFiltersChanged: true };
        if (this.state.selectedFleets.length === 0 && selectedFleets.length > 0) {
            newState.selectedCompetition = true;
            if (this.state.selectedCompanies.length === 0) {
                newState.MapFilterSelectedCompanies = this.props.companies.map(company => company.Id);
            }
        } else if (selectedFleets.length === 0) {
            newState.selectedCompetition = false;
        }
        this.setState(newState);
    };

    handleSegmentChange = (value) => {
        const selectedSegments = this.toggleItemInList(value, this.state.selectedSegments);
        this.setState({ selectedSegments, areFiltersChanged: true });
    };

    handleCompanyChange = (value) => {
        const selectedCompanies = this.toggleItemInList(value, this.state.selectedCompanies);
        this.setState({ selectedCompanies, areFiltersChanged: true });
    };

    handleCompetitionChange = () => {
        this.setState({ selectedCompetition: !this.state.selectedCompetition, areFiltersChanged: true });
    };

    handleResetClick = () => {
        this.setState({
            selectedCompanies: this.props.companies.map(company => company.Id),
            selectedCompetition: false,
            selectedFleets: [],
            selectedSegments: [],
            areFiltersChanged: true
        });
    };

    updateVesselFilters = (e, state = this.state) => {
        this.props.router.updateQueryParams({
            remove: [
                'vessel',
                'reportId',
                'reportType',
                'reportEdit',
                'questionnaireId',
                'questionnaireEdit',
                'voyage'
            ]
        });
        this.props.updateUserSettings({
            MapFilterSelectedGroups: state.selectedFleets,
            MapFilterSelectedSegments: state.selectedSegments,
            MapFilterSelectedCompanies: state.selectedCompanies,
            MapFilterSelectedCompetition: state.selectedCompetition
        }, 'mapFilters');
        this.setState({
            areFiltersChanged: false
        });
    };

    renderSegmentRow = (type) => (
        <VesselFilterRow
            key={type.VesselTypeId}
            value={type.VesselTypeId}
            onChange={this.handleSegmentChange}
            isChecked={this.state.selectedSegments.indexOf(type.VesselTypeId) >= 0}
            checkboxId={`typeSelect${type.VesselTypeId}`}
            count={type.VesselsCount}
        >
            {type.VesselTypeName}
        </VesselFilterRow>
    );

    renderFleetRow = (fleet) => (
        <VesselFilterRow
            key={fleet.VesselGroupId}
            value={fleet.VesselGroupId}
            onChange={this.handleFleetChange}
            isChecked={this.state.selectedFleets.indexOf(fleet.VesselGroupId) >= 0}
            checkboxId={`fleetSelect${fleet.VesselGroupId}`}
            count={fleet.IsDynamic ? null : fleet.VesselsCount}
            badges={renderBadges(fleet, fleet.IsPublic || false)}
        >
            {fleet.VesselGroupName}
        </VesselFilterRow>
    );

    renderCompanyRow = (company) => (
        <VesselFilterRow
            key={company.Id}
            value={company.Id}
            onChange={this.handleCompanyChange}
            isChecked={this.state.selectedCompanies.indexOf(company.Id) >= 0}
            checkboxId={`companySelect${company.Id}`}
            count={company.VesselsCount}
        >
            {company.Name}
        </VesselFilterRow>
    );

    render() {
        const { privateFleets, publicFleets, segmentsByType, companies, permissions, vesselsOnMapCount } = this.props;
        const sectionClassNames = 'sten-content__section sten-content__separator sten-content__separator--inner';
        return (
            <div className="sten-vessel-filter sten-content sten-content--has-footer">
                <div className="sten-content__header">
                    <div className="flex-grow">
                        <h1 className="sten-content__title">
                            {t('VESSEL_FILTER.TITLE')}
                        </h1>
                        <p className="sten-content__subtitle">
                            {t('VESSEL_FILTER.VESSELS_ON_MAP', { vessels: formatNumber(vesselsOnMapCount, 0) })}
                        </p>
                    </div>
                    <div className="flex-shrink">
                        {this.props.onCloseClick && (
                            <button className="btn-link icon icon-close" onClick={this.props.onCloseClick} />
                        )}
                    </div>
                </div>
                <ScrollArea className="sten-content__body">
                    {permissions && permissions.MapFiltersGroupsShow && privateFleets.length > 0 && (
                        <Accordion header={<h4 className="text-uppercase">{t('VESSEL_FILTER.MY_FLEETS')}</h4>}>
                            <div className="sten-content__section">{privateFleets.map(this.renderFleetRow)}</div>
                            {ConfigService.featureToggles.showFleetManagement && (
                                <div className={`${sectionClassNames} text-right`}>
                                    <Link
                                        to={appRoutes.Vessel.FleetManagement}
                                        className="sten-link text-uppercase"
                                    >
                                        {t('VESSEL_FILTER.MANAGE_FLEETS')}
                                    </Link>
                                </div>
                            )}
                        </Accordion>
                    )}
                    {permissions && permissions.MapFiltersGroupsShow && publicFleets.length > 0 && (
                        <Accordion
                            header={<h4 className="text-uppercase">{t('VESSEL_FILTER.SHARED_FLEETS')}</h4>}
                        >
                            <div className="sten-content__section">{publicFleets.map(this.renderFleetRow)}</div>
                        </Accordion>
                    )}
                    {permissions && permissions.MapFiltersSegmentsShow && segmentsByType && (
                        <Accordion header={<h4 className="text-uppercase">{t('VESSEL_FILTER.SEGMENTS')}</h4>}>
                            {Object.keys(segmentsByType)
                                .sort((a, b) => (b === 'Other' ? -1 : 0))
                                .map((key, index) => (
                                    <React.Fragment key={key}>
                                        {segmentTypeNameMap[key] && (
                                            <div className={index === 0 ? 'sten-content__section' : sectionClassNames}>
                                                <h5 className="text-secondary">{segmentTypeNameMap[key]}</h5>
                                            </div>
                                        )}
                                        <div className={segmentTypeNameMap[key]
                                            ? 'sten-content__section'
                                            : sectionClassNames
                                        }
                                        >
                                            {segmentsByType[key].map(this.renderSegmentRow)}
                                        </div>
                                    </React.Fragment>
                                ))
                            }
                        </Accordion>
                    )}
                    {permissions && permissions.MapFiltersCompaniesShow && (
                        <Accordion
                            header={<h4 className="text-uppercase">{t('VESSEL_FILTER.COMPANY_FLEETS')}</h4>}
                        >
                            <div className="sten-content__section">
                                {companies.map(this.renderCompanyRow)}
                                {permissions && permissions.ShowCompetitionVessels && (
                                    <VesselFilterRow
                                        onChange={this.handleCompetitionChange}
                                        isChecked={this.state.selectedCompetition}
                                        checkboxId="competition"
                                    >
                                        {t('VESSEL_FILTER.OTHER')}
                                    </VesselFilterRow>
                                )}
                            </div>
                            {permissions && permissions.UpdateFleet && (
                                <div className={`${sectionClassNames} text-right`}>
                                    <Link to={appRoutes.Admin.CompanyManagement} className="sten-link text-uppercase">
                                        {t('VESSEL_FILTER.MANAGE_COMPANY_FLEETS')}
                                    </Link>
                                </div>
                            )}
                        </Accordion>
                    )}
                </ScrollArea>
                <footer className="sten-content__footer flex-row">
                    <div className="col-12">
                        <button className="btn btn--secondary text-uppercase col-24" onClick={this.handleResetClick}>
                            {t('GLOBAL.RESET')}
                        </button>
                    </div>
                    <div className="col-12">
                        <button
                            disabled={!this.state.areFiltersChanged}
                            className="btn btn--primary text-uppercase col-24"
                            onClick={this.updateVesselFilters}
                        >
                            {t('GLOBAL.APPLY')}
                        </button>
                    </div>
                </footer>
            </div>
        );
    }
}

VesselFilter.propTypes = {
    companies: PropTypes.arrayOf(PropTypes.object).isRequired,
    getVesselFilters: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    privateFleets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    publicFleets: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    router: TRouter.isRequired,
    segmentsByType: PropTypes.objectOf(PropTypes.any),
    selectedCompanies: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedCompetition: PropTypes.bool.isRequired,
    selectedFleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedSegments: PropTypes.arrayOf(PropTypes.any).isRequired,
    updateUserSettings: PropTypes.func.isRequired,
    vesselsOnMapCount: PropTypes.number.isRequired
};

VesselFilter.defaultProps = {
    segmentsByType: null
};

const emptyArray = [];

function mapStateToProps(state) {
    return {
        companies: state.vesselFilterReducer.companies,
        permissions: state.userReducer.permissions,
        privateFleets: state.vesselFilterReducer.privateFleets,
        publicFleets: state.vesselFilterReducer.publicFleets,
        segmentsByType: state.vesselFilterReducer.segmentsByType,
        selectedCompanies: state.userReducer.settings && state.userReducer.settings.MapFilterSelectedCompanies
            ? state.userReducer.settings.MapFilterSelectedCompanies
            : emptyArray,
        selectedCompetition: state.userReducer.settings && state.userReducer.settings.MapFilterSelectedCompetition
            ? state.userReducer.settings.MapFilterSelectedCompetition
            : false,
        selectedFleets: state.userReducer.settings && state.userReducer.settings.MapFilterSelectedGroups
            ? state.userReducer.settings.MapFilterSelectedGroups
            : emptyArray,
        selectedSegments: state.userReducer.settings && state.userReducer.settings.MapFilterSelectedSegments
            ? state.userReducer.settings.MapFilterSelectedSegments
            : emptyArray,
        vesselsOnMapCount: state.homeReducer.vesselsOnMapCount
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getVesselFilters: () => { getVesselFilters(dispatch); },
        updateUserSettings: (settings, settingUpdated) => updateUserSettings(dispatch, settings, settingUpdated)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VesselFilter));
