import React, { Component } from 'react';
import PropTypes from 'prop-types';
/* utils */
import { getClassName } from 'utils/helpers/info-helper';
/* Components */
import Select from 'components/select/select';

const emptyArray = [];

class FleetMultiSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedFleets: emptyArray,
            isEditing: false,
            prevSelectedFleets: emptyArray
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedFleets !== state.prevSelectedFleets) {
            return {
                selectedFleets: props.selectedFleets,
                prevSelectedFleets: props.selectedFleets,
                isEditing: false
            };
        }
        return null;
    }

    confirm = () => {
        this.setState({ isEditing: false });
        this.props.onChange(this.state.selectedFleets);
    };

    discard = () => {
        this.setState({
            isEditing: false,
            selectedFleets: this.props.selectedFleets
        });
    };

    edit = () => {
        this.setState({ isEditing: true });
    };

    handleChange = (selectedFleets) => {
        this.setState({ selectedFleets });
    };

    render() {
        return (
            <div className="flex flex-row">
                <div className="flex-grow">
                    <Select
                        {...this.props}
                        clearable
                        disabled={this.props.isDisabled}
                        readOnly={!this.state.isEditing}
                        multiple
                        options={this.props.allFleets}
                        value={this.state.selectedFleets}
                        onChange={this.handleChange}
                    />
                </div>
                <div className="flex-row flex-center">
                    {!this.state.isEditing
                        ? (
                            <div className="flex-shrink">
                                <button
                                    type="button"
                                    className={getClassName('btn-link icon icon-edit', {
                                        'text-secondary': this.props.isDisabled
                                    })}
                                    disabled={this.props.isDisabled}
                                    onClick={this.edit}
                                />
                            </div>
                        )
                        : (
                            <React.Fragment>
                                <div className="flex-shrink">
                                    <button
                                        type="button"
                                        className="btn-link icon icon-check"
                                        onClick={this.confirm}
                                    />
                                </div>
                                <div className="flex-shrink">
                                    <button
                                        type="button"
                                        className="btn-link icon icon-close"
                                        onClick={this.discard}
                                    />
                                </div>
                            </React.Fragment>
                        )
                    }
                </div>
            </div>
        );
    }
}

FleetMultiSelector.propTypes = {
    allFleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired,
    selectedFleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    valueKey: PropTypes.string,
    labelKey: PropTypes.string,
    fixedListWidth: PropTypes.bool,
    isDisabled: PropTypes.bool
};

FleetMultiSelector.defaultProps = {
    valueKey: 'Id',
    labelKey: '',
    fixedListWidth: false,
    isDisabled: false
};

export default FleetMultiSelector;
