import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ScrollArea from 'components/scroll-area/scroll-area';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* helpers */
import { formatNumber } from 'utils/helpers/info-helper';
import { getVesselStatus } from 'utils/helpers/template-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import { getVessels, resetVesselList } from './vessel-list-actions';
/* components */
import Input from 'components/input/input';
import TextHighlight from 'components/text-highlight/text-highlight';
import EmptyContent from 'components/empty-content/empty-content';
/* styles */
import './vessel-list.scss';

const initialSearchParams = {
    searchValue: '',
    offset: 0,
    limit: 20
};

export class VesselList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            vesselsSearchParams: {
                ...initialSearchParams
            },
            prevVessels: [],
            isGettingData: true,
            prevSettingUpdated: props.settingUpdated,
            prevResourceUpdated: props.resourceUpdated
        };
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
        let stateChanged = false;
        if (state.prevVessels !== props.vessels) {
            newState.isGettingData = false;
            newState.prevVessels = props.vessels;
            stateChanged = true;
        }
        if ((props.settingUpdated !== state.prevSettingUpdated && props.settingUpdated.name === 'mapFilters')
            || (props.resourceUpdated !== state.prevResourceUpdated && props.resourceUpdated.name === 'fleets')) {
            newState.vesselsSearchParams = { ...initialSearchParams };
            newState.prevSettingUpdated = props.settingUpdated;
            newState.prevResourceUpdated = props.resourceUpdated;
            props.getVessels(initialSearchParams, true);
            props.resetVesselList();
            stateChanged = true;
        }
        return stateChanged ? newState : null;
    }

    componentDidMount() {
        const searchParams = {
            Offset: this.state.vesselsSearchParams.offset,
            Limit: this.state.vesselsSearchParams.limit
        };
        if (this.props.getVessels) {
            this.props.getVessels(searchParams, true);
        }
    }

    getVesselLink = (vessel) => {
        if (vessel.IMO) {
            return this.props.router.getLinkTo({
                remove: [
                    'port',
                    'reportId',
                    'reportType',
                    'reportEdit',
                    'questionnaireId',
                    'questionnaireEdit',
                    'voyage',
                    'terminal',
                    'berth',
                    'terminalUpdate',
                    'berthUpdate',
                    'terminalsShown'
                ],
                add: { vessel: vessel.IMO }
            });
        }
        return this.props.router.location;
    }

    getVesselList = () => {
        return this.props.vessels.map((vessel) => {
            const subtitleArray = [];
            if (vessel.VesselTypeName) {
                subtitleArray.push(vessel.VesselTypeName);
            }
            if (vessel.VesselTypeName === 'LNG' && vessel.TotalCubicCapacity) {
                subtitleArray.push(
                    `${t('VESSEL_LIST.CAPACITY')} `
                    + `${formatNumber(vessel.TotalCubicCapacity, 0)} ${t('UNITS.CUBIC_CAPACITY')}`
                );
            } else if (vessel.DWT) {
                subtitleArray.push(
                    `${t('VESSEL_LIST.DWT')} ${formatNumber(vessel.DWT, 0)} ${t('UNITS.DWT')}`
                );
            }
            if (vessel.IMOClass) {
                subtitleArray.push(`${t('VESSEL_LIST.IMO_CLASS')} ${vessel.IMOClass}`);
            }
            let titleClassName = 'text-uppercase';
            if (vessel.IsVesselInUserScope) {
                titleClassName += ' text-primary';
            }
            const navigationStatus = vessel.NavStatusAis;
            const isLaden = vessel.IsLaden;
            const ladenSource = vessel.BallastLadenSource;
            const vesselStatus = getVesselStatus(navigationStatus, isLaden, ladenSource, true);
            return (
                <Link
                    className="btn-section flex"
                    to={this.getVesselLink(vessel)}
                    key={vessel.IMO}
                >
                    <div className="flex-grow flex-row flex-center">
                        <div className="col-12">
                            <h3 className={titleClassName}>
                                <TextHighlight
                                    input={vessel.Title}
                                    highlight={this.state.vesselsSearchParams.searchValue}
                                />
                            </h3>
                            <h6 className="text-secondary">
                                {subtitleArray.join(', ')}
                            </h6>
                        </div>
                        <div className="col-12">
                            <ul className="sten-ul">
                                <li>
                                    <span className="text-secondary">{`${t('VESSEL_LIST.STATUS')} `}</span>
                                    {vesselStatus}
                                </li>
                                <li>
                                    <span className="text-secondary">{`${t('VESSEL_LIST.ETA')} `}</span>
                                    {vessel.ETA
                                        ? TimeHelper.getFormatted(vessel.ETA, { time: true, day: true })
                                        : t('GLOBAL.UNKNOWN')
                                    }
                                </li>
                                <li>
                                    <span className="text-secondary">{`${t('VESSEL_LIST.DESTINATION')} `}</span>
                                    {vessel.Destination ? vessel.Destination : t('GLOBAL.UNKNOWN')}
                                </li>
                            </ul>
                        </div>
                    </div>
                </Link>
            );
        });
    };

    searchHandler = value => {
        this.setState({
            vesselsSearchParams: {
                ...initialSearchParams,
                searchValue: value
            }
        });
        if (this.trimmedValue !== value.trim()) {
            this.trimmedValue = value.trim();
            if (this.trimmedValue.length >= 3 || !this.trimmedValue) {
                if (this.scrollArea) {
                    this.scrollArea.scrollTop();
                }
                if (this.debounce) {
                    clearTimeout(this.debounce);
                }
                this.debounce = setTimeout(() => {
                    const searchParams = {
                        Limit: initialSearchParams.limit,
                        Offset: initialSearchParams.offset,
                        SearchTerm: this.trimmedValue
                    };
                    this.props.getVessels(searchParams, true);
                }, 500);
            }
        }
    };

    scrollHandler = value => {
        if (value.realHeight - value.topPosition < value.containerHeight + 100) {
            if (!this.state.isGettingData && !this.props.vesselsAllFetched) {
                this.setState({
                    isGettingData: true
                });
                let newProps;
                const newOffset = this.state.vesselsSearchParams.offset + this.state.vesselsSearchParams.limit;
                this.setState({
                    vesselsSearchParams: {
                        ...this.state.vesselsSearchParams,
                        offset: newOffset
                    }
                });
                if (this.state.vesselsSearchParams.searchValue) {
                    newProps = {
                        SearchTerm: this.state.vesselsSearchParams.searchValue,
                        Limit: this.state.vesselsSearchParams.limit,
                        Offset: newOffset
                    };
                } else {
                    newProps = {
                        Limit: this.state.vesselsSearchParams.limit,
                        Offset: newOffset
                    };
                }
                this.props.getVessels(newProps);
            }
        }
    };

    saveRef = c => { this.scrollArea = c; };

    render() {
        return (
            <div className="sten-vessel-list">
                <div className="sten-content__body sten-vessel-list__filters">
                    <div className="sten-content__section">
                        <Input
                            suffixIcon="icon-search"
                            onChange={this.searchHandler}
                            placeholder={t('VESSEL_LIST.SEARCH_VESSELS_PLACEHOLDER')}
                            title={t('VESSEL_LIST.SEARCH_VESSELS_TITLE')}
                            value={this.state.vesselsSearchParams.searchValue}
                        />
                    </div>
                </div>
                {this.props.vessels.length ? (
                    <ScrollArea
                        className="sten-content__body sten-vessel-list__list sten-content__separator"
                        onScroll={this.scrollHandler}
                        ref={this.saveRef}
                    >
                        {this.getVesselList()}
                    </ScrollArea>
                )
                    : <EmptyContent />}
            </div>
        );
    }
}

VesselList.propTypes = {
    getVessels: PropTypes.func.isRequired,
    resetVesselList: PropTypes.func.isRequired,
    resourceUpdated: PropTypes.objectOf(PropTypes.any).isRequired,
    router: TRouter.isRequired,
    settingUpdated: PropTypes.objectOf(PropTypes.any).isRequired,
    vessels: PropTypes.arrayOf(PropTypes.object).isRequired,
    vesselsAllFetched: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        resourceUpdated: state.userReducer.resourceUpdated,
        settingUpdated: state.userReducer.settingUpdated,
        vessels: state.vesselListReducer.vessels,
        vesselsAllFetched: state.vesselListReducer.allFetched
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getVessels: (params, overrideCurrent) => getVessels(dispatch, params, overrideCurrent),
        resetVesselList: () => resetVesselList(dispatch)
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VesselList));
