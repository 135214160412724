import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import TimeHelper from 'utils/helpers/time-helper';
import { convertPortForUpdate, convertPortForEdit } from './port-form-helper';
import { mapArrayByProp } from 'utils/helpers/array-helper';
import { setObjectProp } from 'utils/helpers/info-helper';
import { t } from 'utils/i18n/i18n-model';
import { renderFormFields } from 'components/form-fields/form-fields-helper';
/* components */
import Accordion from 'components/accordion/accordion';
import ScrollArea from 'components/scroll-area/scroll-area';
import Validation from 'components/validation/validation';
/* constants */
import { Port, portFields } from './port-form-constants';
/* services */
import PortManagementService from 'services/core-api/port-management-service';

const formFields = {
    BasicInfoTop: ['Name', 'Aliases'],
    BasicInfoBottom: ['Country', 'Code', 'Position', 'IspsCompliance', 'LiquidFacilities'],
    Restrictions: [
        'Restrictions.MaxDraft',
        'Restrictions.MaxLOA',
        'Restrictions.MaxBeam',
        'Restrictions.MaxDisplacement',
        'Restrictions.MaxDWT'
    ],
    Vendors: ['Vendors.PreferredAgent', 'Vendors.TowingContract'],
    Bunkering: ['Bunkering.Types', 'Bunkering.Info']
};

class PortForm extends React.PureComponent {
    state = {
        initialPort: null,
        port: null
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.isActive && prevProps.id !== this.props.id) {
            this.loadData();
        }
    }

    closeForm = () => this.props.router.navigate(appRoutes.PortManagement);

    loadData = () => {
        if (this.props.id) {
            PortManagementService.getPort(this.props.id).then((port) => {
                const formattedPort = convertPortForEdit(port, this.props.services, this.props.bunkeringTypes);
                this.setState({ initialPort: port, port: formattedPort || new Port() });
            });
        } else {
            this.setState({
                initialPort: null,
                port: {
                    ...new Port(),
                    Services: mapArrayByProp(this.props.services, 'Id', (value) => ({ ...value }))
                }
            });
        }
    };

    handleSubmit = () => {
        const convertedPort = convertPortForUpdate(this.state.port);

        if (this.props.id) {
            PortManagementService.updatePort(this.props.id, convertedPort).then((res) => {
                if (res) {
                    toast(t('PORT_FORM.SUCCESSFULLY_UPDATED'), { type: toast.TYPE.SUCCESS });
                    this.props.onSubmit(res, parseInt(this.props.id, 10));
                    this.closeForm();
                }
            });
        } else {
            PortManagementService.addPort(convertedPort).then((res) => {
                if (res) {
                    toast(t('PORT_FORM.SUCCESSFULLY_ADDED'), { type: toast.TYPE.SUCCESS });
                    this.props.onSubmit(res);
                    this.closeForm();
                }
            });
        }
    };

    handleValueChange = (key, prop) => (value) => {
        const port = { ...this.state.port };
        setObjectProp(port, key, prop ? value[prop] : value);
        this.setState({ port });
    }

    renderContent = (fieldNames, inline = false) => {
        const options = {
            bunkeringTypes: this.props.bunkeringTypes,
            countries: this.props.countries,
            initialAliases: this.state.initialPort ? this.state.initialPort.Aliases : null
        };
        return renderFormFields(this.state.port, portFields, fieldNames, true, this.handleValueChange, inline, options);
    };

    renderAccordionHeader = (text) => (<h4 className="text-uppercase">{text}</h4>);

    render() {
        const { port } = this.state;
        if (!port) {
            return null;
        }
        const isEditing = !!(this.props.id || this.state.initialPort);

        return (
            <Validation.Form onSubmit={this.handleSubmit} className="sten-content sten-content--has-footer">
                <div className="sten-content__header">
                    <div className="flex-grow">
                        <h1 className="sten-content__title text-ellipsis">
                            {isEditing ? this.state.port.Name : t('PORT_FORM.NEW_PORT')}
                        </h1>
                        {port.UpdatedBy && port.UpdatedAt && (
                            <p className="sten-content__subtitle text-ellipsis text-nowrap">
                                {t('PORT_FORM.UPDATED_BY_USER', {
                                    date: TimeHelper.getFormatted(port.UpdatedAt, { time: true }),
                                    user: `${port.UpdatedBy}`
                                })}
                            </p>
                        )}
                    </div>
                    <div className="flex-shrink">
                        <button type="button" className="btn-link icon icon-close" onClick={this.closeForm} />
                    </div>
                </div>
                <ScrollArea className="sten-content__body" shouldScrollToActiveElement>
                    <Accordion header={this.renderAccordionHeader(t('PORT_FORM.BASIC_INFO'))}>
                        <div className="sten-content__section">
                            {this.renderContent(formFields.BasicInfoTop)}
                        </div>
                        <div className="sten-content__section sten-content__separator sten-content__separator--inner">
                            {this.renderContent(formFields.BasicInfoBottom)}
                        </div>
                    </Accordion>
                    <Accordion header={this.renderAccordionHeader(t('PORT_FORM.RESTRICTIONS'))}>
                        <div className="sten-content__section">
                            {this.renderContent(formFields.Restrictions, true)}
                        </div>
                    </Accordion>
                    <Accordion header={this.renderAccordionHeader(t('PORT_FORM.VENDORS'))} isCollapsed>
                        <div className="sten-content__section">
                            {this.renderContent(formFields.Vendors, true)}
                        </div>
                    </Accordion>
                    <Accordion header={this.renderAccordionHeader(t('PORT_FORM.ADDITIONAL_SERVICES'))} isCollapsed>
                        <div className="sten-content__section">
                            {this.state.port.Services && Object.values(this.state.port.Services).map(
                                service => portFields.AdditionalService.edit(service, this.handleValueChange)
                            )}
                        </div>
                    </Accordion>
                    <Accordion header={this.renderAccordionHeader(t('PORT_FORM.BUNKERING'))} isCollapsed>
                        <div className="sten-content__section">
                            {this.renderContent(formFields.Bunkering, true)}
                        </div>
                    </Accordion>
                </ScrollArea>
                <footer className="sten-content__footer flex-row">
                    <div className="col-12">
                        <button
                            className="btn btn--secondary col-24"
                            type="button"
                            onClick={this.closeForm}
                        >
                            {t('GLOBAL.CANCEL')}
                        </button>
                    </div>
                    <div className="col-12">
                        <Validation.Button
                            disabled={this.props.id && !this.state.initialPort}
                            className="btn btn--primary col-24"
                        >
                            {isEditing ? t('GLOBAL.SAVE') : t('GLOBAL.CREATE')}
                        </Validation.Button>
                    </div>
                </footer>
            </Validation.Form>
        );
    }
}

PortForm.propTypes = {
    bunkeringTypes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    countries: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    id: PropTypes.string,
    isActive: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    services: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired
};

PortForm.defaultProps = {
    id: null,
    isActive: false
};

export default withRouter(PortForm);
