import { createSelector } from 'reselect';
import moment from 'moment';

export const createByIdSelector = (originalArraySelector, idKeyName, saveFirst = false) => createSelector(
    originalArraySelector,

    (arr) => {
        const map = {};

        if (arr && Array.isArray(arr)) {
            arr.forEach(item => {
                if (!map[item[idKeyName]] || !saveFirst) {
                    map[item[idKeyName]] = item;
                }
            });
        }

        return map;
    }
);

export const createDefaultItemSelector = (itemsSelector) => createSelector(
    itemsSelector,
    (items) => items.find((item) => item.Default === true)
);

export const createCategoryChartDataSelector = (dataSelector, seriesKeys) => createSelector(
    dataSelector,
    (data) => {
        const chartData = {
            series: {},
            categories: []
        };
        let category;
        if (data && data.length > 0) {
            data.forEach((point) => {
                category = moment({ year: point.Year, month: point.Month - 1 }).format('MMM YYYY');
                chartData.categories.push(category);
                seriesKeys.forEach((key) => {
                    if (!chartData.series[key]) {
                        chartData.series[key] = [];
                    }
                    chartData.series[key].push([category, point[key]]);
                });
            });
        }
        return chartData;
    }
);

export default createByIdSelector;
