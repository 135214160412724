/* utils */
import TimeHelper from 'utils/helpers/time-helper';
import { t } from 'utils/i18n/i18n-model';

export const chartConfigBaseColors = {
    colorAreaSuccess: '#1e7d73',
    colorChartBg: '#0f2e39',
    colorChartPlotBg: '#3f313a',
    colorAreaSuccessFill: 'rgba(10, 87, 74, 0.5)',
    colorAreaWarningFill: 'rgba(50, 100, 25, 0.5)',
    colorXAxisGridLine: 'rgba(255, 255, 255, 0.1)',
    colorSeriesGradient: 'rgba(0, 0, 0, 0.3)'
};

export function formatter(tooltip) {
    const data = this.points[0].point.options;
    if (data.isDeleted || data.isEdited) {
        const title = `<div class="sten-chart-tooltip__header">${
            TimeHelper.getFormatted(this.points[0].key)
        }</div>`;
        const content = `<br/><span class="sten-chart-tooltip__point" style="color: ${data.color}">${
            data.isDeleted ? t('RAW_REPORTS_TABLE.DELETED') : t('RAW_REPORTS_TABLE.UPDATED')
        }</span>`;
        return `${title}${content}`;
    }
    return tooltip.defaultFormatter.call(this, tooltip);
}
