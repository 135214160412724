import PropTypes from 'prop-types';

const ListElement = PropTypes.shape({
    clickQueryParam: PropTypes.oneOf(['tanker', 'ferry', 'drill']),
    iconColor: PropTypes.string,
    id: PropTypes.number,
    subtitle: PropTypes.string,
    title: PropTypes.string
});

const Vessel = PropTypes.shape({
    CargoType: PropTypes.string,
    IMO: PropTypes.number,
    ShipName: PropTypes.string
});

const FerryRoute = PropTypes.shape({
    Id: PropTypes.number,
    Name: PropTypes.string
});

const Company = PropTypes.shape({
    CompanyId: PropTypes.number,
    CompanyName: PropTypes.string
});

const Office = PropTypes.shape({
    CityId: PropTypes.number,
    CityName: PropTypes.string,
    Companies: PropTypes.arrayOf(Company)
});

const AllEntities = PropTypes.shape({
    Drills: PropTypes.arrayOf(Vessel),
    FerryRoutes: PropTypes.arrayOf(FerryRoute),
    Offices: PropTypes.arrayOf(Office),
    Vessels: PropTypes.shape({
        Ferries: PropTypes.arrayOf(Vessel),
        Tankers: PropTypes.arrayOf(Vessel)
    })
});

export default {
    AllEntities,
    ListElement
};
