import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* types */
import { THomeQueryParams } from './home-types';
/* helpers */
import { isObjPropDifferent } from 'utils/helpers/info-helper';
/* actions */
import {
    getVessels,
    getSelectedVessel,
    getVoyageById,
    removeSelectedVessel,
    resetAll,
    setMounted
} from './home-actions';
import { toggleFocus } from './global-search/global-search-actions';
import { getVesselFilterCompanies } from './right-side-bar/vessel-filter/vessel-filter-actions';
/* components */
import GlobalSearch from './global-search/global-search';
import Map from './map/map';
import MapDatePicker from './map-date-picker/map-date-picker';
import NotificationSettings from './notification-settings/notification-settings';
import RightSideBar from './right-side-bar/right-side-bar';
/* styles */
import './home.scss';

export class HomePage extends React.PureComponent {
    componentDidMount() {
        this.props.setMounted(true);
        this.props.getVessels();
        this.updateHomeReducer();
    }

    componentDidUpdate(prevProps) {
        this.updateHomeReducer(prevProps);
    }

    componentWillUnmount() {
        this.props.setMounted(false);
        this.props.resetAll();
    }

    updateHomeReducer(prevProps) {
        /* vessel and voyage query */
        if (this.props.queryParams.vessel) {
            let vesselId = null;
            let voyageId = null;
            if (isObjPropDifferent(prevProps, this.props, 'queryParams.vessel')) {
                vesselId = this.props.queryParams.vessel;
            }
            if (isObjPropDifferent(prevProps, this.props, 'queryParams.voyage')) {
                voyageId = this.props.queryParams.voyage;
            }
            if (vesselId) {
                if (voyageId) {
                    this.props.getSelectedVessel(vesselId, voyageId);
                } else {
                    this.props.getSelectedVessel(vesselId);
                }
            } else if (voyageId) {
                this.props.getVoyageById(voyageId);
            }
        } else if (prevProps && prevProps.queryParams.vessel) {
            this.props.removeSelectedVessel();
        }
        if (!prevProps) {
            this.props.getVesselFilterCompanies();
        }
    }

    toggleFocusIfNeeded = () => {
        if (this.props.isGlobalSearchFocused) {
            this.props.toggleGlobalSearchFocus(false);
        }
    };

    render() {
        const { queryParams, isRightSideBarCollapsed } = this.props;
        return (
            <div className="sten-home-page" onClick={this.toggleFocusIfNeeded}>
                <GlobalSearch fullWidth={isRightSideBarCollapsed} />
                <MapDatePicker fullWidth={isRightSideBarCollapsed} />
                <Map queryParams={queryParams} />
                {queryParams.notificationSettings && <NotificationSettings />}
                <RightSideBar queryParams={queryParams} />
            </div>
        );
    }
}

HomePage.propTypes = {
    getSelectedVessel: PropTypes.func.isRequired,
    getVesselFilterCompanies: PropTypes.func.isRequired,
    getVessels: PropTypes.func.isRequired,
    getVoyageById: PropTypes.func.isRequired,
    isGlobalSearchFocused: PropTypes.bool.isRequired,
    isRightSideBarCollapsed: PropTypes.bool.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    queryParams: THomeQueryParams.isRequired,
    removeSelectedVessel: PropTypes.func.isRequired,
    resetAll: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    selectedVoyage: PropTypes.objectOf(PropTypes.any),
    setMounted: PropTypes.func.isRequired,
    toggleGlobalSearchFocus: PropTypes.func.isRequired
};

HomePage.defaultProps = {
    selectedVoyage: null
};

function mapStateToProps(state) {
    return {
        isGlobalSearchFocused: state.globalSearchReducer.isFocused,
        isRightSideBarCollapsed: state.rightSideBarReducer.isCollapsed,
        permissions: state.userReducer.permissions,
        selectedVoyage: state.homeReducer.selectedVoyage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getSelectedVessel: (vesselId, voyageId) => getSelectedVessel(dispatch, vesselId, voyageId),
        getVesselFilterCompanies: () => getVesselFilterCompanies(dispatch),
        getVessels: () => getVessels(dispatch),
        getVoyageById: voyageId => getVoyageById(dispatch, voyageId),
        removeSelectedVessel: () => removeSelectedVessel(dispatch),
        resetAll: () => resetAll(dispatch),
        setMounted: isMounted => setMounted(dispatch, isMounted),
        toggleGlobalSearchFocus: isFocused => toggleFocus(dispatch, isFocused)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomePage));
