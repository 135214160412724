import React from 'react';
/* router */
import { TRouter, withRouter } from 'app-router';
/* types */
import { TRouteRendererQueryParams } from './route-renderer-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import RouteHelper from 'utils/helpers/route-helper';
import { generateMiddlePoints, splitRoute180, mapLonLat } from 'components/ol/ol-helpers';
/* services */
import TestService from 'services/core-api/test-service';
/* components */
import Map from './map/route-renderer-map';
import ScrollArea from 'components/scroll-area/scroll-area';
import Textarea from 'components/textarea/textarea';
import Validation from 'components/validation/validation';
/* styles */
import './route-renderer.scss';

const isJson = {
    rule: (value) => {
        try {
            JSON.parse(value);
            return true;
        } catch (e) {
            return false;
        }
    },
    hint: () => 'Must be a valid JSON'
};

const textAreaPlaceholder = `{
    "Vessel": {
        "Imo": 9533995,
        "Title": "Stena Surprise",
        "SegmentId": 2,
        "SegmentName": "Suezmax",
        "Dwt": 158491.0,
        "TotalCubicCapacity": null,
        "Draft": 8.48,
        "CurrentPosition": {
            "Longitude": -2.7077633333,
            "Latitude": -0.9178033333,
            "IsLaden": true,
            "LocationTime": "2023-03-08T14:17:15Z",
            "LocationOffsetMinutes": 0.0,
            "TimeZoneId": "UTC",
            "SpeedOverGround": 12.5,
            "Heading": 292.0
        }
    },
    "Ports": [
        {
            "Id": 92295,
            "Name": "DONGES",
            "PortCode": "FRDON",
            "Type": 2,
            "Location": "POINT(-2.06667 47.3)",
            "Activities": [
                {
                    "Name": "Loading",
                    "Code": "L"
                }
            ],
            "Eta": "2023-02-17T10:00:00Z",
            "Etd": "2023-02-17T11:00:00Z"
        }
    ],
    "HistoricalRoute": [
        {
            "IsLaden": true,
            "PositionTime": "2023-03-08T14:17:15Z",
            "Longitude": -2.7077633333,
            "Latitude": -0.9178033333
        }
    ],
    "FutureRoute": [
        {
            "Longitude": -2.7077633333,
            "Latitude": -0.9178033333
        }
    ]
}`;

export class RouteRendererPage extends React.PureComponent {
    state = {
        input: '',
        data: null
    };

    componentDidMount() {
        if (this.props.queryParams.voyageId) {
            TestService.getGeneratedMapData(this.props.queryParams.voyageId).then(res => {
                if (res) {
                    this.setState({ input: JSON.stringify(res, null, 4) });
                }
            });
        }
    }

    handleInputChange = (value) => {
        this.setState({ input: value });
    };

    handleSubmit = () => {
        const parsedData = JSON.parse(this.state.input);
        const data = {};
        if (parsedData.HistoricalRoute) {
            data.HistoricalRouteRaw = parsedData.HistoricalRoute.map(mapLonLat);
            data.HistoricalRoute = RouteHelper.extractRoutesFromAisPoints(
                parsedData.HistoricalRoute, 'Longitude', 'Latitude'
            );
        }
        if (parsedData.FutureRoute) {
            const futureRoute = parsedData.FutureRoute.map(mapLonLat);
            data.FutureRouteRaw = futureRoute;
            data.FutureRoute = splitRoute180(generateMiddlePoints(futureRoute));
        }
        if (parsedData.Vessel) {
            data.Vessel = {
                ...parsedData.Vessel,
                CurrentAis: parsedData.Vessel.CurrentPosition,
                SpeedOverGround: parsedData.Vessel.CurrentPosition.SpeedOverGround,
                IsVesselInUserScope: true
            };
        }
        if (parsedData.Ports) {
            const now = new Date().toISOString();
            data.Ports = parsedData.Ports.filter(port => port.Name && port.Location).map(port => ({
                ...port,
                Activities: port.Activities.filter(activity => activity.Name && activity.Code),
                IsHistorical: port.Etd && now > port.Etd
            }));
        }
        this.setState({ data });
    };

    render() {
        const { data, input } = this.state;
        const { queryParams } = this.props;
        return (
            <div className="sten-route-renderer-page">
                {data ? (
                    <Map
                        queryParams={queryParams}
                        data={data}
                    />
                ) : (
                    <ScrollArea>
                        <Validation.Form onSubmit={this.handleSubmit}>
                            <div className="sten-content__section sten-h5">
                                Available query params:
                                <ul>
                                    <li>
                                        <em>mapTypeId</em>
                                        <ul>
                                            <li><b>1</b> for <b>classic</b> view</li>
                                            <li><b>2</b> for <b>satellite</b> view</li>
                                            <li><b>3</b> for <b>nautical</b> view</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <em>countryBordersNamesEnabled</em>
                                        <ul>
                                            <li><b>true</b> to show country names and borders</li>
                                            <li><b>false</b> to hide them</li>
                                        </ul>
                                    </li>
                                    <li>
                                        <em>voyageId</em> - works only when logged in and fetches data for voyage
                                    </li>
                                </ul>
                            </div>
                            <div className="sten-content__section">
                                <Validation.Wrapper validations={{ isJson }}>
                                    <Textarea
                                        name="input"
                                        value={input}
                                        onChange={this.handleInputChange}
                                        placeholder={textAreaPlaceholder}
                                    />
                                </Validation.Wrapper>
                            </div>
                            <div className="sten-content__section">
                                <Validation.Button className="btn btn--primary">
                                    {t('GLOBAL.SUBMIT')}
                                </Validation.Button>
                            </div>
                        </Validation.Form>
                    </ScrollArea>
                )}
            </div>
        );
    }
}

RouteRendererPage.propTypes = {
    queryParams: TRouteRendererQueryParams.isRequired,
    router: TRouter.isRequired
};

export default withRouter(RouteRendererPage);
