import { ActionTypes } from './admin-user-management-actions';

const emptyArray = [];

const initialState = {
    activeUserId: '',
    isUpdateModeActive: false,
    users: emptyArray,
    isFetchingData: true,
    options: {
        areFetched: false,
        roles: emptyArray,
        companies: emptyArray,
        internalCompanies: emptyArray,
        departments: emptyArray,
        accountTypes: [
            { id: 1, title: 'Enabled', isLocalLoginEnabled: true },
            { id: 0, title: 'Disabled', isLocalLoginEnabled: false }
        ],
        statuses: emptyArray,
        statusesById: null,
        visibilities: [
            { id: 1, title: 'Visible', isVisible: true },
            { id: 0, title: 'Hidden', isVisible: false }
        ]
    },
    usersAllFetched: false,
    shouldRefreshData: false
};

export default function adminUserManagementReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ADMIN_USER_MANAGEMENT_SET_OPTIONS: {
        return {
            ...state,
            options: { ...state.options, ...action.options, areFetched: true }
        };
    }
    case ActionTypes.ADMIN_USER_MANAGEMENT_SET_DEPARTMENTS: {
        return {
            ...state,
            options: { ...state.options, departments: action.departments }
        };
    }
    case ActionTypes.ADMIN_USER_MANAGEMENT_SET_COMPANIES: {
        return {
            ...state,
            options: { ...state.options, companies: action.companies }
        };
    }
    case ActionTypes.ADMIN_USER_MANAGEMENT_SET_USERS: {
        let users;
        if (action.overrideCurrent) {
            users = action.users;
        } else {
            users = state.users.slice().concat(action.users);
        }
        return {
            ...state,
            users,
            usersAllFetched: action.usersAllFetched,
            isFetchingData: false,
            shouldRefreshData: false
        };
    }
    case ActionTypes.ADMIN_USER_MANAGEMENT_SET_FETCHING_PROGRESS: {
        return {
            ...state,
            isFetchingData: action.isFetchingData
        };
    }
    case ActionTypes.ADMIN_USER_MANAGEMENT_TOGGLE_UPDATE_MODE: {
        return {
            ...state,
            activeUserId: action.userId,
            isUpdateModeActive: action.isActive
        };
    }
    case ActionTypes.ADMIN_USER_MANAGEMENT_REFRESH_DATA: {
        return {
            ...state,
            shouldRefreshData: true
        };
    }
    case ActionTypes.ADMIN_USER_MANAGEMENT_RESET: {
        return initialState;
    }
    default:
        return state;
    }
}
