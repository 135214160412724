import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { getClassName } from 'utils/helpers/info-helper';
/* styles */
import './map-legend-table.scss';

class MapLegendTable extends React.PureComponent {
    render() {
        const className = getClassName('sten-map-legend-table', this.props.className);
        return (
            <table className={className}>
                <tbody>
                    {this.props.rows && this.props.rows.map((row, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <tr className={row.className} key={index}>
                            <td><span className={`icon ${row.iconClass}`} /></td>
                            <td>{row.label}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
}

MapLegendTable.propTypes = {
    className: PropTypes.string,
    rows: PropTypes.arrayOf(PropTypes.shape({
        className: PropTypes.string,
        iconClass: PropTypes.string.isRequired,
        label: PropTypes.node.isRequired
    })).isRequired
};

MapLegendTable.defaultProps = {
    className: ''
};

export default MapLegendTable;
