import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import TimeHelper from 'utils/helpers/time-helper';
import { formatNumber } from 'utils/helpers/info-helper';
/* components */
import Input from 'components/input/input';
import PortSelect from 'components/port-select/port-select';
import Modal from 'components/modal/modal';
import FlipSwitch from 'components/flip-switch/flip-switch';
import Validation from 'components/validation/validation';
import Menu, { MenuItem } from 'components/menu/menu';
/* style */
import './calculator-point.scss';

export class CalculatorPoint extends React.PureComponent {
    state = {
        isArrivalDateTimePickerOpen: false
    };

    isNewlyAdded = true;

    focusChangeTimeout = null;

    componentDidMount() {
        if (this.props.focusOnMount) {
            this.focusChangeTimeout = setTimeout(() => {
                if (this.portSelect) {
                    this.portSelect.onFocus();
                }
                if (this.props.scrollToPoint) {
                    this.props.scrollToPoint();
                }
            }, 500);
        }
        this.isNewlyAdded = false;
    }

    componentWillUnmount() {
        if (this.focusChangeTimeout) {
            clearTimeout(this.focusChangeTimeout);
        }
    }

    setEtaDate = value => {
        const calculationPoint = {
            Eta: typeof value === 'string' ? value : value.toISOString(),
            EtaUpdated: true,
            IsSpeedSelected: !value,
            Speed: null
        };
        if (this.props.calculationPoint.ValidationErrors && this.props.calculationPoint.ValidationErrors.EtaError) {
            this.props.form.hideError(`arrivalDate${this.props.id}`);
            calculationPoint.ValidationErrors = { ...this.props.calculationPoint.ValidationErrors, EtaError: null };
        }
        this.handlePointChanged(calculationPoint);
        this.toggleArrivalDateTimePicker(false);
    };

    setPointValue = point => {
        if (point.IsCustomPoint) {
            this.props.setCustomCalculationPoint(point.Coordinates, this.props.index);
        } else {
            const calculationPoint = {
                Point: { ...point, IsCustomPoint: false },
                Time: null,
                Distance: null
            };
            this.handlePointChanged(calculationPoint);
            if (this.props.isLastPoint && this.props.isAddEnabled) {
                this.props.addPoint(this.props.index + 1);
            }
        }
    };

    setCurrentConsumptionValue = value => {
        const calculationPoint = {};
        if (this.props.calculationPoint.ValidationErrors
        && this.props.calculationPoint.ValidationErrors.ConsumptionError) {
            this.props.form.hideError(`currentConsumption${this.props.id}`);
            calculationPoint.ValidationErrors = {
                ...this.props.calculationPoint.ValidationErrors, ConsumptionError: null
            };
        }
        calculationPoint.CurrentConsumption = value;
        this.handlePointChanged(calculationPoint);
    };

    setValue = (prop, value) => {
        const calculationPoint = {};
        calculationPoint[prop] = value;
        this.handlePointChanged(calculationPoint);
    };

    handleDelete = () => {
        this.props.onDelete(this.props.index);
    };

    toggleArrivalDateTimePicker = flag => {
        this.setState({
            isArrivalDateTimePickerOpen: typeof flag === 'boolean' ? flag : !this.state.isArrivalDateTimePickerOpen
        });
    };

    onFocusChange = (isFocused) => {
        if (isFocused) {
            this.props.setFocusedPointIndex(this.props.index);
        } else {
            this.props.setFocusedPointIndex(null);
        }
    };

    onFocus = () => {
        this.onFocusChange(true);
    };

    onBlur = () => {
        this.onFocusChange(false);
    };

    handlePointChanged = (point) => {
        this.props.onChange(point, this.props.index);
    };

    addPointBefore = () => { this.props.addPoint(this.props.index); };

    addPointAfter = () => { this.props.addPoint(this.props.index + 1); };

    movePointUp = () => { this.props.movePoint(this.props.index, this.props.index - 1); };

    movePointDown = () => { this.props.movePoint(this.props.index, this.props.index + 1); };

    renderContextMenu = () => {
        const menuItems = [];
        if (this.props.onDelete) {
            menuItems.push(
                <MenuItem key="delete" onClick={this.props.onDelete.bind(this, this.props.index)}>
                    {`${t('GLOBAL.DELETE')} (F6)`}
                </MenuItem>
            );
        }
        if (this.props.isAddEnabled) {
            if (!this.props.isStarting) {
                menuItems.push(
                    <MenuItem key="insertBefore" onClick={this.addPointBefore}>
                        {`${t('DISTANCE_CALCULATOR.POINT.INSERT_BEFORE')} (F7)`}
                    </MenuItem>
                );
            }
            menuItems.push(
                <MenuItem key="insertAfter" onClick={this.addPointAfter}>
                    {`${t('DISTANCE_CALCULATOR.POINT.INSERT_AFTER')} (F8)`}
                </MenuItem>
            );
        }
        if (this.props.movePoint && !this.props.isStarting) {
            if (this.props.index > this.props.earliestChangeableIndex) {
                menuItems.push(
                    <MenuItem key="moveUp" onClick={this.movePointUp}>
                        {`${t('DISTANCE_CALCULATOR.POINT.MOVE_UP')} (PgUp)`}
                    </MenuItem>
                );
            }
            if (!this.props.isLastPoint) {
                menuItems.push(
                    <MenuItem key="moveDown" onClick={this.movePointDown}>
                        {`${t('DISTANCE_CALCULATOR.POINT.MOVE_DOWN')} (PgDn)`}
                    </MenuItem>
                );
            }
        }
        return <Menu isDisabled={menuItems.length === 0}>{menuItems}</Menu>;
    };

    handlePinLocationClick = () => {
        this.props.onPinLocationClick(this.props.calculationPoint.CalculationPointId);
    };

    renderDestinationCell = () => {
        let mapLocationButtonClass = 'sten-calculator-point__pin-button icon icon-pin';
        if (this.props.isPinActive) {
            mapLocationButtonClass += ' btn--active';
        }
        let fixedPoint = '';
        if (this.props.startingPointFixed || this.props.calculationPoint.Ata) {
            if (this.props.calculationPoint
                && this.props.calculationPoint.Point
                && this.props.calculationPoint.Point.Name) {
                fixedPoint = this.props.calculationPoint.Point.Name;
            } else {
                fixedPoint = '-';
            }
        }
        return (
            <div className="flex-row flex-center sten-calculator-point__cell">
                {fixedPoint
                    ? (
                        <div className="flex-grow">{fixedPoint}</div>
                    ) : (
                        <div className="flex flex-grow">
                            <div className="flex-grow">
                                <Validation.Wrapper
                                    hintsOnHover
                                    validate={!this.props.isLastPoint}
                                    validations={{
                                        required: true,
                                        custom: {
                                            rule: value => !value || !!value.Location,
                                            hint: () => t('DISTANCE_CALCULATOR.POINT.PORT_WITHOUT_LOCATION')
                                        }
                                    }}
                                >
                                    <PortSelect
                                        ref={(c) => { this.portSelect = c; }}
                                        className="sten-select--sm"
                                        allowCustomPoint
                                        fixedListWidth
                                        name={`portName${this.props.id}`}
                                        value={this.props.calculationPoint.Point}
                                        onChange={this.setPointValue}
                                        placeholder={this.props.isPinActive
                                            ? t('DISTANCE_CALCULATOR.POINT.COORDINATES_SELECT_PLACEHOLDER')
                                            : t('DISTANCE_CALCULATOR.POINT.PORT_SELECT_PLACEHOLDER')
                                        }
                                    />
                                </Validation.Wrapper>
                            </div>
                            <div className="flex-shrink">
                                <button
                                    type="button"
                                    onClick={this.handlePinLocationClick}
                                    className={mapLocationButtonClass}
                                />
                            </div>
                        </div>
                    )
                }
                <div className="flex-shrink">
                    {this.props.isStarting || this.props.calculationPoint.Ata
                        ? (<span className="icon icon--md icon-locked text-secondary" />)
                        : this.renderContextMenu()}
                </div>
            </div>
        );
    };

    handleStatusChange = (isLaden) => {
        this.setValue('IsBallast', !isLaden);
    };

    renderStatusCell = () => {
        if (!this.props.calculationPoint.Point) {
            return null;
        }
        if (this.props.isStarting) {
            return '-';
        }
        return (
            <FlipSwitch
                size="xs"
                allowArrowKeys
                disabled={!!this.props.calculationPoint.Ata}
                activeLabel={t('GLOBAL.LADEN')}
                inactiveLabel={t('GLOBAL.BALLAST')}
                value={!this.props.calculationPoint.IsBallast}
                onChange={this.handleStatusChange}
            />
        );
    };

    renderSteamingCell = () => {
        if (!this.props.calculationPoint.Point) {
            return null;
        }
        return typeof this.props.calculationPoint.SteamingTime === 'number'
            ? TimeHelper.getStringFromDuration(this.props.calculationPoint.SteamingTime, ['d', 'h'])
            : '-';
    };

    renderDistanceCell = () => {
        if (!this.props.calculationPoint.Point) {
            return null;
        }
        return typeof this.props.calculationPoint.Distance === 'number'
            ? `${formatNumber(this.props.calculationPoint.Distance, 0)} ${t('UNITS.DISTANCE')}`
            : '-';
    };

    renderSpeedCell = () => {
        const { calculationPoint, defaultSpeed } = this.props;
        if (!calculationPoint.Point) {
            return null;
        }
        let speed = null;
        if (calculationPoint.IsSpeedSelected) {
            speed = defaultSpeed;
        } else if (typeof calculationPoint.Speed === 'number') {
            speed = calculationPoint.Speed;
        }
        return speed ? `${formatNumber(speed, 1)} ${t('UNITS.SPEED')}` : '-';
    };

    renderConsumptionCell = () => {
        const { calculationPoint } = this.props;
        if (!calculationPoint.Point) {
            return null;
        }
        if (this.props.isStarting || this.props.startingPointFixed) {
            return '-';
        }

        const consumptionValue = calculationPoint.CurrentConsumption === null
        && typeof calculationPoint.EstimatedConsumption !== 'number'
            ? null
            : formatNumber(calculationPoint.CurrentConsumption === null
                ? calculationPoint.EstimatedConsumption
                : calculationPoint.CurrentConsumption,
            1, false, '.', '');
        const consumptionTitle = typeof calculationPoint.DailyConsumptionPerSpeed === 'number'
            ? t('DISTANCE_CALCULATOR.POINT.CONSUMPTION_PER_DAY_FOR_SPEED', {
                consumption: formatNumber(calculationPoint.DailyConsumptionPerSpeed, 1),
                speed: formatNumber(calculationPoint.ConsumptionSpeed, 1)
            })
            : '';
        return calculationPoint.Ata
            ? consumptionValue || '-'
            : (
                <Validation.Wrapper
                    hintsOnHover
                    validations={{ numeric: { min: 0 } }}
                >
                    <Input
                        className="sten-calculator-point__current-consumption-input sten-input--sm"
                        name={`currentConsumption${this.props.id}`}
                        value={consumptionValue}
                        onChange={this.setCurrentConsumptionValue}
                        title={consumptionTitle}
                        suffix={t('UNITS.CONSUMPTION')}
                    />
                </Validation.Wrapper>
            );
    };

    renderWaitingCell = () => {
        if (!this.props.calculationPoint.Point) {
            return null;
        }
        return typeof this.props.calculationPoint.WaitingTime === 'number'
            ? TimeHelper.getStringFromDuration(this.props.calculationPoint.WaitingTime, ['d', 'h'])
            : '-';
    };

    renderArrivalDateCell = () => {
        if (!this.props.calculationPoint.Point) {
            return null;
        }
        if (!this.props.isStarting && !this.props.earliestEta && !this.props.calculationPoint.Eta) {
            return '-';
        }
        const isDisabled = !this.props.calculationPoint.Point;
        let inputValue = '';
        let inputDisplayValue = '-';
        if (this.props.calculationPoint.Eta) {
            const eta = moment.utc(this.props.calculationPoint.Eta);
            inputValue = eta.toISOString();
            inputDisplayValue = TimeHelper.getFormatted(eta, { time: true });
        }

        let calculationPointOffset = 0;
        if (this.props.calculationPoint.Point) {
            if (this.props.calculationPoint.Point.TimeZoneId) {
                calculationPointOffset = moment.tz(this.props.calculationPoint.Point.TimeZoneId).utcOffset();
            }
        }
        const currentLocalTime = moment.utc().startOf('h').add(calculationPointOffset + 60, 'm');

        let minEta = null;
        if (this.props.earliestEta) {
            minEta = moment.utc(this.props.earliestEta).startOf('h').add(1, 'h');
            minEta.add(calculationPointOffset, 'm');
        }

        let datePickerValue = null;
        if (this.props.calculationPoint.Eta) {
            datePickerValue = moment.utc(this.props.calculationPoint.Eta).valueOf();
        } else if (minEta) {
            datePickerValue = minEta.valueOf();
        } else {
            datePickerValue = currentLocalTime.valueOf();
        }

        const isFirstPoint = this.props.isStarting || this.props.earliestChangeableIndex === this.props.index;

        return (
            <div className="flex">
                <Modal.DatetimePicker
                    utc
                    isOpen={this.state.isArrivalDateTimePickerOpen}
                    minDate={!this.props.calculationPoint.Ata && minEta ? minEta.valueOf() : null}
                    onCancel={this.toggleArrivalDateTimePicker}
                    onSelect={this.setEtaDate}
                    timestamp={datePickerValue}
                />
                <div className="flex-grow">
                    {!isFirstPoint && (this.props.calculationPoint.Ata || !minEta)
                        ? inputDisplayValue
                        : (
                            <Validation.Wrapper
                                hintsOnHover
                                shouldTrackValidations
                                validate={!this.props.calculationPoint.IsSpeedSelected}
                                validations={{
                                    required: !!minEta,
                                    dateLimits: {
                                        min: minEta,
                                        utc: true
                                    }
                                }}
                            >
                                <Input
                                    name={`arrivalDate${this.props.id}`}
                                    className="sten-input--sm"
                                    disabled={isDisabled}
                                    readOnly
                                    clearable={!this.props.calculationPoint.IsSpeedSelected}
                                    suffixIcon="icon-calendar"
                                    value={inputValue}
                                    type="button"
                                    displayValue={inputDisplayValue}
                                    onClick={this.toggleArrivalDateTimePicker}
                                    onSuffixClick={this.toggleArrivalDateTimePicker}
                                    onChange={this.setEtaDate}
                                />
                            </Validation.Wrapper>
                        )
                    }
                </div>
            </div>
        );
    };

    renderTimeInPortCell = () => {
        if (!this.props.calculationPoint.Point) {
            return null;
        }
        if (this.props.isStarting || this.props.startingPointFixed) {
            return '-';
        }

        return this.props.calculationPoint.Atd
            ? this.props.calculationPoint.TimeInPort || '-'
            : (
                <Validation.Wrapper
                    hintsOnHover
                    validations={{
                        duration: {
                            max: 3 * 365 * 24 * 60 * 60 * 1000, // 3 years in milliseconds
                            min: 0
                        }
                    }}
                >
                    <Input
                        className="sten-calculator-point__time-in-port-input sten-input--sm"
                        name={`timeInPort${this.props.id}`}
                        placeholder={t('DISTANCE_CALCULATOR.POINT.DEPARTURE_PLACEHOLDER')}
                        value={this.props.calculationPoint.TimeInPort}
                        onChange={this.setValue.bind(this, 'TimeInPort')}
                    />
                </Validation.Wrapper>
            );
    };

    renderDepartureDateCell = () => {
        if (!this.props.calculationPoint.Point) {
            return null;
        }
        let etd = '-';
        if (this.props.calculationPoint.Eta) {
            const eta = moment.utc(this.props.calculationPoint.Eta);
            if (this.props.calculationPoint.TimeInPort) {
                const timeInPort = TimeHelper
                    .getDurationFromString(this.props.calculationPoint.TimeInPort).asMinutes();
                eta.add(timeInPort, 'm');
            }
            etd = TimeHelper.getFormatted(eta, { time: true });
        }

        return etd;
    };

    render() {
        if (!this.props.calculationPoint) {
            return null;
        }
        let classNames = 'sten-calculator-point';
        if (this.props.calculationPoint.Atd) {
            classNames += ' sten-calculator-point--historical';
        }
        if (this.props.isFocused) {
            classNames += ' sten-calculator-point--focused';
        }
        const index = typeof this.props.index === 'number' ? this.props.index + 1 : 'Start';
        const indexTitle = this.props.isStarting ? t('DISTANCE_CALCULATOR.POINT.START_POINT') : '';
        return (
            <tr
                tabIndex="-1"
                className={classNames}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onMouseEnter={this.props.handleMouseEnter}
                ref={this.props.saveRef}
            >
                <td title={indexTitle}>
                    <div className="flex flex-center">
                        {this.props.dragHandleMouseDown && (
                            <span
                                className="flex-shrink icon icon-drag icon--md sten-calculator-point__drag-icon"
                                onMouseDown={this.props.dragHandleMouseDown}
                            />
                        )}
                        {this.props.isStarting
                            ? (
                                <span
                                    className="flex-grow text-center icon icon-flag-checkered icon--md text-secondary"
                                />
                            ) : <span className="flex-grow text-center">{index}</span>
                        }
                    </div>
                </td>
                <td>
                    {this.renderDestinationCell()}
                </td>
                <td>{this.renderStatusCell()}</td>
                <td>{this.renderSteamingCell()}</td>
                <td>{this.renderDistanceCell()}</td>
                <td>{this.renderSpeedCell()}</td>
                {this.props.showConsumption && <td>{this.renderConsumptionCell()}</td>}
                <td>{this.renderWaitingCell()}</td>
                <td>{this.renderTimeInPortCell()}</td>
                <td>{this.props.isStarting ? '-' : this.renderArrivalDateCell()}</td>
                <td>{this.props.isStarting ? this.renderArrivalDateCell() : this.renderDepartureDateCell()}</td>
            </tr>
        );
    }
}

CalculatorPoint.propTypes = {
    addPoint: PropTypes.func.isRequired,
    calculationPoint: PropTypes.objectOf(PropTypes.any).isRequired,
    defaultSpeed: PropTypes.number.isRequired,
    dragHandleMouseDown: PropTypes.func,
    earliestEta: PropTypes.string,
    earliestChangeableIndex: PropTypes.number.isRequired,
    focusOnMount: PropTypes.bool,
    form: PropTypes.objectOf(PropTypes.any),
    handleMouseEnter: PropTypes.func,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    isAddEnabled: PropTypes.bool,
    isCalculated: PropTypes.bool,
    isFocused: PropTypes.bool,
    isLastPoint: PropTypes.bool,
    isPinActive: PropTypes.bool,
    isStarting: PropTypes.bool,
    movePoint: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    onPinLocationClick: PropTypes.func.isRequired,
    saveRef: PropTypes.func,
    scrollToPoint: PropTypes.func,
    setCustomCalculationPoint: PropTypes.func.isRequired,
    setFocusedPointIndex: PropTypes.func.isRequired,
    showConsumption: PropTypes.bool,
    startingPointFixed: PropTypes.bool
};

CalculatorPoint.defaultProps = {
    dragHandleMouseDown: undefined,
    earliestEta: '',
    focusOnMount: true,
    form: null,
    handleMouseEnter: undefined,
    isAddEnabled: true,
    isCalculated: false,
    isFocused: false,
    isLastPoint: false,
    isPinActive: false,
    isStarting: false,
    movePoint: undefined,
    onDelete: undefined,
    saveRef: undefined,
    scrollToPoint: undefined,
    showConsumption: false,
    startingPointFixed: false
};

export default props => (
    <Validation.Context.Consumer>
        {context => <CalculatorPoint {...props} context={context} />}
    </Validation.Context.Consumer>
);
