import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* types */
import { TWeatherRoutingQueryParams } from './weather-routing-types';
/* actions */
import { resetAll, getInitialData } from './weather-routing-actions';
/* components */
import RightSideBar from './right-side-bar/weather-routing-right-side-bar';
import { Outlet } from 'react-router';
/* styles */
import './weather-routing.scss';

export class WeatherRoutingPage extends React.PureComponent {
    componentDidMount() {
        this.props.getInitialData();
    }

    componentWillUnmount() {
        this.props.resetAll();
    }

    render() {
        const { isMapShown, router, queryParams } = this.props;
        return (
            <div className="sten-weather-routing-page">
                <Outlet />
                <RightSideBar
                    showButtons={isMapShown && !!router.params.legId}
                    legId={router.params.legId}
                    queryParams={queryParams}
                />
            </div>
        );
    }
}

WeatherRoutingPage.propTypes = {
    getInitialData: PropTypes.func.isRequired,
    isMapShown: PropTypes.bool.isRequired,
    queryParams: TWeatherRoutingQueryParams.isRequired,
    router: TRouter.isRequired,
    resetAll: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
    return {
        getInitialData: () => getInitialData(dispatch),
        resetAll: () => resetAll(dispatch)
    };
}

function mapStateToProps(state) {
    return {
        isMapShown: state.weatherRoutingReducer.isMapShown
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WeatherRoutingPage));
