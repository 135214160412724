export const ActionTypes = {
    ENERGY_MANAGEMENT_WEATHER_PERFORMANCE_REPORT_GENERATE: 'ENERGY_MANAGEMENT_WEATHER_PERFORMANCE_REPORT_GENERATE',
    ENERGY_MANAGEMENT_WEATHER_PERFORMANCE_REPORT_SET: 'ENERGY_MANAGEMENT_WEATHER_PERFORMANCE_REPORT_SET',
    ENERGY_MANAGEMENT_WEATHER_PERFORMANCE_REPORT_GENERATE_PDF:
        'ENERGY_MANAGEMENT_WEATHER_PERFORMANCE_REPORT_GENERATE_PDF'
};

export function generatePDF(dispatch) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_WEATHER_PERFORMANCE_REPORT_GENERATE_PDF
    });
}

export function resetAll(dispatch) {
    dispatch({ type: ActionTypes.ENERGY_MANAGEMENT_WEATHER_PERFORMANCE_REPORT_SET });
}
