import React from 'react';
import PropTypes from 'prop-types';
/* components */
import Comment from './comment/comment';

export class CommentList extends React.PureComponent {
    state = {
        activeCommentId: null
    };

    handleActiveCommentIdUpdate = (activeCommentId) => this.setState({ activeCommentId });

    render() {
        if (!this.props.comments || !this.props.comments.length) {
            return null;
        }

        return this.props.comments.map(comment => (
            <Comment
                activeCommentId={this.state.activeCommentId}
                canUpdateAllComments={this.props.canUpdateAllComments}
                canUpdateComments={this.props.canUpdateComments}
                comment={comment}
                key={comment.Id}
                onActiveCommentIdUpdate={this.handleActiveCommentIdUpdate}
                resource={this.props.resource}
                resourceId={this.props.resourceId}
            />
        ));
    }
}

CommentList.propTypes = {
    canUpdateComments: PropTypes.bool.isRequired,
    canUpdateAllComments: PropTypes.bool.isRequired,
    comments: PropTypes.arrayOf(PropTypes.object).isRequired,
    resource: PropTypes.string.isRequired,
    resourceId: PropTypes.number.isRequired
};

export default CommentList;
