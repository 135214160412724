import React from 'react';
import PropTypes from 'prop-types';
import './cell.scss';

export default class Cell extends React.PureComponent {
    clickHandler = (e) => {
        if (!this.props.isDisabled && this.props.onClick) {
            const { day, month, year } = this.props;
            this.props.onClick({ day, month, year }, e);
        }
    };

    mouseEnterHandler = (e) => {
        if (!this.props.isDisabled && this.props.onMouseEnter) {
            const { day, month, year } = this.props;
            this.props.onMouseEnter({ day, month, year }, e);
        }
    };

    render() {
        let className = 'htec-dp-cell';
        if (this.props.className) {
            className += ` ${this.props.className}`;
        }
        if (this.props.isHovered) {
            className += ' htec-dp-cell--hovered';
        }
        if (this.props.isSelected) {
            className += ' htec-dp-cell--selected';
        }
        if (this.props.isRangeStart) {
            className += ' htec-dp-cell--range-start';
        }
        if (this.props.isRangeEnd) {
            className += ' htec-dp-cell--range-end';
        }
        if (this.props.isHighlighted) {
            className += ' htec-dp-cell--highlighted';
        }
        if (this.props.isDisabled) {
            className += ' htec-dp-cell--disabled';
        }
        if (this.props.isOutsideOfCurrentMonth) {
            className += ' htec-dp-cell--outside';
        }
        if (this.props.isToday) {
            className += ' htec-dp-cell--today';
        }
        return (
            <div
                className={className}
                onClick={this.clickHandler}
                onMouseEnter={this.mouseEnterHandler}
            >
                {this.props.title}
            </div>
        );
    }
}

Cell.propTypes = {
    className: PropTypes.string,
    day: PropTypes.number,
    isDisabled: PropTypes.bool,
    isHighlighted: PropTypes.bool,
    isHovered: PropTypes.bool,
    isOutsideOfCurrentMonth: PropTypes.bool,
    isRangeEnd: PropTypes.bool,
    isRangeStart: PropTypes.bool,
    isSelected: PropTypes.bool,
    isToday: PropTypes.bool,
    month: PropTypes.number,
    onClick: PropTypes.func.isRequired,
    onMouseEnter: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    weekDay: PropTypes.number,
    year: PropTypes.number.isRequired
};

Cell.defaultProps = {
    className: '',
    day: null,
    isDisabled: false,
    isHighlighted: false,
    isHovered: false,
    isOutsideOfCurrentMonth: false,
    isRangeEnd: false,
    isRangeStart: false,
    isSelected: false,
    isToday: false,
    month: null,
    onMouseEnter: undefined,
    weekDay: null
};
