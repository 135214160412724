import React from 'react';
import { Navigate } from 'react-router';
/* router */
import { appRoutes } from 'app-router';
/* services */
import AuthService from 'services/auth-service';
import LocalStorageService from 'services/local-storage-service';
import UserService from 'services/core-api/user-service';
import ConfigService from 'services/config-api/config-service';

const homePageMap = {
    FleetDashboard: appRoutes.FleetDashboard,
    Map: appRoutes.Map
};

export class AuthSignInRedirect extends React.PureComponent {
    state = {
        location: null
    };

    componentDidMount() {
        AuthService.userManager.signinRedirectCallback()
            .then(user => {
                if (user) {
                    ConfigService.setTenantId(user.profile.tenant_id).then(() => {
                        UserService.getHomePage().then(homePageKey => {
                            const lastVisitedLocation = LocalStorageService.getLastVisitedLocation();
                            const homePage = homePageMap[homePageKey] || homePageMap.Map;
                            LocalStorageService.setHomePage(homePage);
                            this.setState({ location: lastVisitedLocation || homePage });
                        });
                    });
                }
            })
            .catch(() => {
                AuthService.login(false);
            });
    }

    render() {
        if (this.state.location) {
            return <Navigate to={this.state.location} replace />;
        }
        return (
            <div className="sten-auth-page" />
        );
    }
}

export default AuthSignInRedirect;
