import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* constants */
import defaultHotkeys from './default-hotkeys';
import hotkeysActionsMap from './hotkeys-actions-map';

export class HotkeysManager extends React.Component {
    constructor(props) {
        super(props);
        this.preventTouchPinchZooming();
    }

    componentDidMount() {
        document.onkeydown = this.handleKeyUp;
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.children !== this.props.children;
    }

    componentWillUnmount() {
        document.onkeydown = null;
        if ('onwheel' in document) {
            document.onwheel = null;
        } else {
            document.onmousewheel = null;
        }
        document.removeEventListener('touchmove', this.handleTouchPinch);
    }

    preventTouchPinchZooming() {
        document.addEventListener('touchmove', this.handleTouchPinch, { passive: false });
    }

    handleTouchPinch(event) {
        event.preventDefault();
    }

    handleHotkeyActions = actions => {
        const self = this;
        function triggerAction(action) {
            const actionObject = hotkeysActionsMap[action];
            if (actionObject
                && ((actionObject.permission && self.props.permissions[actionObject.permission])
                || !actionObject.permission)) {
                if (typeof (actionObject) === 'function') {
                    if (action === 'deselect-element') {
                        actionObject(this.props.router.location, this.props.router.query, this.props.router.navigate);
                    } else {
                        actionObject();
                    }
                } else {
                    self.props.fireShortcutAction(actionObject.action, actionObject.payload);
                }
            }
        }

        if (typeof actions === 'string') {
            triggerAction(actions);
        } else {
            actions.forEach(action => triggerAction(action));
        }
    };

    handleHotkeys = (event, hotkeys) => {
        const { key, ctrlKey, altKey, shiftKey } = event;
        let pressedKeyCombination = '';
        if (ctrlKey) {
            pressedKeyCombination = 'ctrl+';
        }
        if (altKey) {
            pressedKeyCombination += 'alt+';
        }
        if (shiftKey) {
            pressedKeyCombination += 'shift+';
        }
        pressedKeyCombination += key;
        if (hotkeys.preventDefault && hotkeys.preventDefault.indexOf(pressedKeyCombination) >= 0) {
            event.preventDefault();
        }
        if (hotkeys[pressedKeyCombination]
            && document.activeElement.tagName !== 'INPUT'
            && document.activeElement.tagName !== 'TEXTAREA'
            && !this.props.isLoaderShown) {
            this.handleHotkeyActions(hotkeys[pressedKeyCombination]);
        }
    };

    handleKeyUp = event => {
        if (defaultHotkeys.global) {
            this.handleHotkeys(event, defaultHotkeys.global);
        }
        if (defaultHotkeys[this.props.pathName]) {
            this.handleHotkeys(event, defaultHotkeys[this.props.pathName]);
        }
    };

    render() {
        return this.props.children || null;
    }
}

HotkeysManager.propTypes = {
    children: PropTypes.node,
    fireShortcutAction: PropTypes.func,
    isLoaderShown: PropTypes.bool,
    pathName: PropTypes.string,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    router: TRouter.isRequired
};

HotkeysManager.defaultProps = {
    children: null,
    fireShortcutAction: undefined,
    isLoaderShown: false,
    pathName: ''
};

function mapStateToProps(state) {
    return {
        isLoaderShown: state.loaderReducer.isLoaderShown,
        permissions: state.userReducer.permissions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        fireShortcutAction: (action, payload) => action(dispatch, payload)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HotkeysManager));
