export const ActionTypes = {
    GSM_GET_TANKERS: 'GSM_GET_TANKERS',
    GSM_SET_TANKERS: 'GSM_SET_TANKERS',
    GSM_GET_SELECTED_TANKER: 'GSM_GET_SELECTED_TANKER',
    GSM_SET_SELECTED_TANKER: 'GSM_SET_SELECTED_TANKER',
    GSM_REMOVE_SELECTED_TANKER: 'GSM_REMOVE_SELECTED_TANKER',
    GSM_SET_SELECTED_VESSEL: 'GSM_SET_SELECTED_VESSEL',
    GSM_REMOVE_SELECTED_VESSEL: 'GSM_REMOVE_SELECTED_VESSEL',

    GSM_GET_PORTS: 'GSM_GET_PORTS',
    GSM_SET_PORTS: 'GSM_SET_PORTS',

    GSM_GET_CITIES: 'GSM_GET_CITIES',
    GSM_SET_CITIES: 'GSM_SET_CITIES',
    GSM_GET_SELECTED_CITY: 'GSM_GET_SELECTED_CITY',
    GSM_SET_SELECTED_CITY: 'GSM_SET_SELECTED_CITY',
    GSM_REMOVE_SELECTED_CITY: 'GSM_REMOVE_SELECTED_CITY',

    GSM_GET_ADDRESSES: 'GSM_GET_ADDRESSES',
    GSM_SET_ADDRESSES_COUNT: 'GSM_SET_ADDRESSES_COUNT',
    GSM_SET_VISIBLE_ADDRESSES: 'GSM_SET_VISIBLE_ADDRESSES',
    GSM_REMOVE_VISIBLE_ADDRESSES: 'GSM_REMOVE_VISIBLE_ADDRESSES',

    GSM_GET_FERRIES: 'GSM_GET_FERRIES',
    GSM_SET_FERRIES: 'GSM_SET_FERRIES',
    GSM_GET_SELECTED_FERRY: 'GSM_GET_SELECTED_FERRY',
    GSM_SET_SELECTED_FERRY: 'GSM_SET_SELECTED_FERRY',
    GSM_REMOVE_SELECTED_FERRY: 'GSM_REMOVE_SELECTED_FERRY',

    GSM_GET_FERRY_ROUTES: 'GSM_GET_FERRY_ROUTES',
    GSM_SET_FERRY_ROUTES: 'GSM_SET_FERRY_ROUTES',

    GSM_GET_DRILLS: 'GSM_GET_DRILLS',
    GSM_SET_DRILLS: 'GSM_SET_DRILLS',
    GSM_GET_SELECTED_DRILL: 'GSM_GET_SELECTED_DRILL',
    GSM_SET_SELECTED_DRILL: 'GSM_SET_SELECTED_DRILL',
    GSM_REMOVE_SELECTED_DRILL: 'GSM_REMOVE_SELECTED_DRILL',

    GSM_SAVE_VIEW_PROPS: 'GSM_SAVE_VIEW_PROPS',

    GSM_RESET_APP_UI: 'GSM_RESET_APP_UI',
    GSM_SET_RESET_APP_UI_STATE: 'GSM_SET_RESET_APP_UI_STATE',
    GSM_RESTORE_RESET_APP_UI_STATE: 'GSM_RESTORE_RESET_APP_UI_STATE'
};

export function saveMapViewProps(dispatch, mapViewProps) {
    dispatch({
        type: ActionTypes.GSM_SAVE_VIEW_PROPS,
        mapViewProps
    });
}

// Tankers
export function getTankers(dispatch) {
    dispatch({
        type: ActionTypes.GSM_GET_TANKERS
    });
}

export function getSelectedTanker(dispatch, tankerId) {
    dispatch({
        type: ActionTypes.GSM_GET_SELECTED_TANKER,
        tankerId
    });
}

export function removeSelectedTanker(dispatch) {
    dispatch({
        type: ActionTypes.GSM_REMOVE_SELECTED_TANKER
    });
}

export function setSelectedTankerPosition(dispatch) {
    dispatch({
        type: ActionTypes.GSM_GET_SELECTED_TANKER_POSITION
    });
}

// Vessel
export function removeSelectedVessel(dispatch) {
    dispatch({
        type: ActionTypes.GSM_REMOVE_SELECTED_VESSEL
    });
}

// Port
export function getPorts(dispatch, params) {
    dispatch({
        type: ActionTypes.GSM_GET_PORTS,
        params
    });
}

// Cities
export function getCities(dispatch, params) {
    dispatch({
        type: ActionTypes.GSM_GET_CITIES,
        params
    });
}

export function getSelectedCity(dispatch, cityId) {
    dispatch({
        type: ActionTypes.GSM_GET_SELECTED_CITY,
        cityId
    });
}

export function removeSelectedCity(dispatch) {
    dispatch({
        type: ActionTypes.GSM_REMOVE_SELECTED_CITY
    });
}

// Addresses
export function getAddresses(dispatch, params) {
    dispatch({
        type: ActionTypes.GSM_GET_ADDRESSES,
        params
    });
}

export function setVisibleAddresses(dispatch, addresses) {
    dispatch({
        type: ActionTypes.GSM_SET_VISIBLE_ADDRESSES,
        addresses
    });
}

export function removeVisibleAddresses(dispatch) {
    dispatch({
        type: ActionTypes.GSM_REMOVE_VISIBLE_ADDRESSES
    });
}

// Ferries
export function getFerries(dispatch) {
    dispatch({
        type: ActionTypes.GSM_GET_FERRIES
    });
}

export function getSelectedFerry(dispatch, ferryId) {
    dispatch({
        type: ActionTypes.GSM_GET_SELECTED_FERRY,
        ferryId
    });
}

export function removeSelectedFerry(dispatch) {
    dispatch({
        type: ActionTypes.GSM_REMOVE_SELECTED_FERRY
    });
}

// Ferry routes
export function getFerryRoutes(dispatch) {
    dispatch({
        type: ActionTypes.GSM_GET_FERRY_ROUTES
    });
}

// Drills
export function getDrills(dispatch) {
    dispatch({
        type: ActionTypes.GSM_GET_DRILLS
    });
}

export function getSelectedDrill(dispatch, drillId) {
    dispatch({
        type: ActionTypes.GSM_GET_SELECTED_DRILL,
        drillId
    });
}

export function removeSelectedDrill(dispatch) {
    dispatch({
        type: ActionTypes.GSM_REMOVE_SELECTED_DRILL
    });
}

// Reset app ui
export function resetAppUi(dispatch) {
    dispatch({
        type: ActionTypes.GSM_RESET_APP_UI
    });
}

export function restoreResetAppUiState(dispatch) {
    dispatch({
        type: ActionTypes.GSM_RESTORE_RESET_APP_UI_STATE
    });
}
