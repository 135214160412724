import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* helpers */
import { t } from 'utils/i18n/i18n-model';
/* components */
import EmptyContent from 'components/empty-content/empty-content';
import NotificationSettingsPanel from './notification-settings-panel/notification-settings-panel';
import ScrollArea from 'components/scroll-area/scroll-area';
/* actions */
import { getSettings, getFleets, getCustomFleets, updateSetting } from './notification-settings-actions';
/* selectors */
import { getSettingsWithFleets } from './notification-settings-selectors';
/* styles */
import './notification-settings.scss';

class NotificationSettings extends React.PureComponent {
    componentDidMount() {
        this.props.getSettings();
        this.props.getFleets();
        this.props.getCustomFleets();
    }

    handleChange = (updatedRule) => {
        this.props.updateSetting(updatedRule.Id, updatedRule);
    };

    closeNotificationSettings = () => this.props.router.updateQueryParams({ remove: ['notificationSettings'] });

    render() {
        const settings = this.props.settings;
        return (
            <div className="sten-notification-settings sten-content">
                <div className="sten-content__header flex-row">
                    <h1 className="sten-content__title flex-grow">{t('NOTIFICATION_SETTINGS.TITLE')}</h1>
                    <div className="flex-shrink">
                        <button
                            className="btn-link icon icon-close"
                            onClick={this.closeNotificationSettings}
                        />
                    </div>
                </div>
                {settings && settings.length > 0 ? (
                    <ScrollArea className="sten-content__body">
                        <div className="sten-content__section">
                            <div className="row row--fluid">
                                {settings.map((rule) => (
                                    <NotificationSettingsPanel
                                        key={rule.Id}
                                        onChange={this.handleChange}
                                        companyFleets={this.props.companyFleets}
                                        customFleets={this.props.customFleets}
                                        rule={rule}
                                    />
                                ))}
                            </div>
                        </div>
                    </ScrollArea>
                ) : <EmptyContent className="sten-content__body" />}
            </div>
        );
    }
}

NotificationSettings.propTypes = {
    companyFleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    customFleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    getFleets: PropTypes.func.isRequired,
    getCustomFleets: PropTypes.func.isRequired,
    getSettings: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    settings: PropTypes.arrayOf(PropTypes.any).isRequired,
    updateSetting: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        companyFleets: state.notificationSettingsReducer.companyFleets,
        customFleets: state.notificationSettingsReducer.customFleets,
        settings: getSettingsWithFleets(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getFleets: () => getFleets(dispatch),
        getCustomFleets: () => getCustomFleets(dispatch),
        getSettings: () => getSettings(dispatch),
        updateSetting: (id, data) => updateSetting(dispatch, id, data)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationSettings));
