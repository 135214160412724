import React from 'react';
import PropTypes from 'prop-types';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import Chart from 'components/chart/chart';
import ConsumptionPanel from '../consumption-cargo-panel/consumption-cargo-panel';
import FootprintPanel from '../footprint-panel/footprint-panel';
import EmptyContent from 'components/empty-content/empty-content';
/* styles */
import './info-graph-section.scss';
/* config */
import { getConsumptionCargoChartConfig, getEnergyEfficiencyChartConfig } from '../../kpi-chart-config';

export default class EnergyManagementKPIInfoGraphSection extends React.PureComponent {
    render() {
        const {
            aer,
            ballastConsumption,
            ladenConsumption,
            averageCargo,
            energyEfficiency,
            rangeEndDate,
            rangeOption,
            consumptionChartData,
            energyEfficiencyChartData
        } = this.props;
        return (
            <React.Fragment>
                {ConfigService.featureToggles.showKpiEnergyEfficiencyLayout ? (
                    <React.Fragment>
                        <div className="sten-content__section">
                            <div className="flex-row flex-row--lg spread-v--lg">
                                <div className="col-lg-12 col-md-24">
                                    <ConsumptionPanel
                                        averageCargo={averageCargo}
                                        ballastConsumption={ballastConsumption}
                                        ladenConsumption={ladenConsumption}
                                        rangeEndDate={rangeEndDate}
                                        rangeOption={rangeOption}
                                    />
                                </div>
                                <div className="col-lg-12 col-md-24 col-pad--md">
                                    <FootprintPanel
                                        aer={aer}
                                        energyEfficiency={energyEfficiency}
                                        rangeEndDate={rangeEndDate}
                                        rangeOption={rangeOption}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sten-content__section">
                            <div className="row row--fluid">
                                <div className="col-md-12 col-xs-24">
                                    <div className="sten-panel spread-v">
                                        {consumptionChartData ? (
                                            <Chart
                                                className="sten-energy-management-kpi-info-graph-section__chart"
                                                series={consumptionChartData.series}
                                                categories={consumptionChartData.categories}
                                                config={getConsumptionCargoChartConfig()}
                                                isChart
                                            />
                                        ) : <EmptyContent />}
                                    </div>
                                </div>
                                <div className="col-md-12 col-xs-24">
                                    <div className="sten-panel spread-v">
                                        {energyEfficiencyChartData ? (
                                            <Chart
                                                className="sten-energy-management-kpi-info-graph-section__chart"
                                                series={energyEfficiencyChartData.series}
                                                categories={energyEfficiencyChartData.categories}
                                                config={getEnergyEfficiencyChartConfig()}
                                                isChart
                                            />
                                        ) : <EmptyContent />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                ) : (
                    <div className="sten-content__section sten-energy-management-kpi-info-graph-section">
                        <div className="flex-row flex-row--lg">
                            <div className="col-lg-12">
                                <ConsumptionPanel
                                    className="spread-v--lg"
                                    averageCargo={averageCargo}
                                    ballastConsumption={ballastConsumption}
                                    ladenConsumption={ladenConsumption}
                                    rangeEndDate={rangeEndDate}
                                    rangeOption={rangeOption}
                                />
                            </div>
                            <div className="col-lg-12 col-pad--md">
                                <div className="sten-panel spread-v--lg">
                                    {consumptionChartData && (
                                        <Chart
                                            className="sten-energy-management-kpi-info-graph-section__chart"
                                            series={consumptionChartData.series}
                                            categories={consumptionChartData.categories}
                                            config={getConsumptionCargoChartConfig()}
                                            isChart
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const InfoCardValue = PropTypes.shape({
    total: PropTypes.number,
    year1: PropTypes.number,
    year2: PropTypes.number
});

EnergyManagementKPIInfoGraphSection.propTypes = {
    aer: InfoCardValue.isRequired,
    averageCargo: InfoCardValue.isRequired,
    energyEfficiency: InfoCardValue.isRequired,
    ballastConsumption: InfoCardValue.isRequired,
    consumptionChartData: PropTypes.objectOf(PropTypes.any).isRequired,
    energyEfficiencyChartData: PropTypes.objectOf(PropTypes.any),
    ladenConsumption: InfoCardValue.isRequired,
    graphData: PropTypes.objectOf(PropTypes.any),
    rangeEndDate: PropTypes.objectOf(PropTypes.any).isRequired,
    rangeOption: PropTypes.objectOf(PropTypes.any).isRequired
};

EnergyManagementKPIInfoGraphSection.defaultProps = {
    energyEfficiencyChartData: null,
    graphData: null
};
