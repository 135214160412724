import { createSelector } from 'reselect';
/* helpers */
import { mapArrayByProp } from 'utils/helpers/array-helper';
import {
    areConditionsMet,
    extractReportFuelData,
    extractUtilization,
    extractAllExclusionFields
} from 'components/vessel-report/vessel-report-helpers';
import ReportHelper from 'utils/helpers/report-helper';
/* constants */
import { reportProps, reportTypeMap, rawReportTypes } from './raw-reports-table-constants';
/* selectors */
import { getHiddenFieldsByTypeDSST } from 'components/vessel-report/vessel-report-selectors';

const tankCleaningFields = [
    'TankCleaningStarted', 'TankCleaningFinished', 'WaterUsedForTankCleaning',
    'TankCleaningStartedOffset', 'TankCleaningFinishedOffset'
];

const dataTypesMap = {
    BunkerDataTypes: 'BunkerData',
    ConsumptionTypes: 'Consumptions',
    ConsumptionSinceLastEventTypes: 'ConsumptionsSinceLastEvent',
    ConsumptionFromNoonToEventTypes: 'ConsumptionFromNoonToEvent',
    ConsumptionDuringStoppageTypes: 'ConsumptionDuringStoppage',
    DischargeOperationTypes: 'DischargeOperations'
};

const emptyArray = [];

export const convertReports = (reports, reportDataTypes) => {
    if (reports && reportDataTypes) {
        return reports.map((r) => {
            const extractedFuelData = extractReportFuelData(r, reportDataTypes.AvailableFuelTypes);
            return {
                ...r,
                ...extractedFuelData.parsedData,
                FuelTypes: extractedFuelData.fuelTypes,
                Utilization: extractUtilization(r),
                ExclusionFields: extractAllExclusionFields(r),
                TankCleaning: tankCleaningFields.some(fieldName => !!r[fieldName]),
                CalculatedAverageLoadDischargeRate: ReportHelper.getAverageLoadDischargeRate(r),
                IsSuspicious: Object.keys(r.WrongReportsFields).length > 0,
                ...r.DailyReportAtPort
                    ? {
                        DailyReportAtPort: {
                            ...r.DailyReportAtPort,
                            AverageLoadDischargeRate: ReportHelper.getAverageLoadDischargeRate(r)
                        }
                    } : null
            };
        });
    }
    return emptyArray;
};

export const getConvertedReports = createSelector(
    state => state.rawReportsTableReducer.reports,
    state => state.rawReportsTableReducer.reportDataTypes,
    convertReports
);

export const getFilteredReports = createSelector(
    getConvertedReports,
    state => state.rawReportsTableReducer.visibility,
    (reports, visibility) => {
        const { included, excluded, missing, suspicious, veracitySyncError } = visibility;
        if (included && excluded && missing && !suspicious && !veracitySyncError) {
            return reports;
        }
        return reports.filter(report => (
            (!suspicious || report.IsSuspicious)
            && (!veracitySyncError || report.VeracityAbstractErrors?.length || report.VeracityBunkerErrors?.length)
            && (included || (report.IsExcluded || report.IsMissing))
            && (excluded || (!report.IsExcluded || report.IsMissing))
            && (missing || !report.IsMissing)
        ));
    }
);

// Returns combined hidden fields - only fields that are hidden on ALL subtypes and data types
const getCombinedHiddenFields = (hiddenFieldsByType, reportTypeId, reportDataTypes) => {
    let combinedHiddenFields = null;
    if (hiddenFieldsByType && hiddenFieldsByType[reportTypeId] && reportDataTypes) {
        Object.keys(hiddenFieldsByType[reportTypeId]).forEach(field => {
            const isFieldHiddenForAllCombinations = reportDataTypes.AvailableReportVariations?.every(report => {
                const fieldRoot = field.split('.')[0];
                return areConditionsMet(hiddenFieldsByType[reportTypeId][field], report)
                    || areConditionsMet(hiddenFieldsByType[reportTypeId][fieldRoot], report);
            });
            if (isFieldHiddenForAllCombinations) {
                if (!combinedHiddenFields) {
                    combinedHiddenFields = {};
                }
                combinedHiddenFields[field] = true;
            }
        });
    }
    return combinedHiddenFields;
};

const isKeyHidden = (key, hiddenFields) => {
    const keyParts = key.split('.');
    let partialKey = keyParts[0];
    for (let i = 0; i < keyParts.length; i++) {
        if (hiddenFields?.[partialKey]) {
            return true;
        }
        partialKey = `${partialKey}.${keyParts[i + 1]}`;
    }
    return false;
};

export const convertRowKeys = (
    reportType,
    reportDataTypes,
    hiddenFieldsByType,
    hasInclusions = false
) => {
    if (reportType && reportProps[reportType]) {
        const reportTypeId = reportTypeMap[reportType];
        const hiddenFields = getCombinedHiddenFields(hiddenFieldsByType, reportTypeId, reportDataTypes);
        if (reportDataTypes) {
            const rows = [];
            let key;

            reportProps[reportType].rows.forEach(row => {
                if (row.keys) {
                    const someKeyVisible = !hiddenFields || row.keys.some(k => (
                        !hiddenFields[k.split('.')[0]] && !hiddenFields[k]
                    ));
                    if (someKeyVisible) {
                        rows.push(row);
                    }
                } else if (row.key) {
                    key = row.key.split('.')[0];
                    const isAltKeyHidden = row.altKey ? !isKeyHidden(row.altKey, hiddenFields) : false;
                    if ((row.type !== 'fuelTypes' || (reportDataTypes.Types[key] && reportDataTypes.Types[key].length))
                        && (hasInclusions || !row.hiddenWithoutInclusions)
                        && (!hiddenFields || !isKeyHidden(row.key, hiddenFields) || isAltKeyHidden)) {
                        rows.push(row);
                    }
                } else if (dataTypesMap[row] && reportDataTypes.Types[dataTypesMap[row]]) {
                    reportDataTypes.Types[dataTypesMap[row]].forEach(type => {
                        rows.push({
                            key: `${dataTypesMap[row]}.${type.Id}`,
                            type: 'fuelTypeQuantities',
                            numFmt: '0.00',
                            label: type.Name,
                            dataGroup: dataTypesMap[row]
                        });
                    });
                }
            });
            return rows;
        }
    }
    return emptyArray;
};

export const getRowKeys = createSelector(
    state => state.rawReportsTableReducer.reportType,
    state => state.rawReportsTableReducer.reportDataTypes,
    getHiddenFieldsByTypeDSST,
    (state, ownProps) => !!ownProps.inclusions,
    convertRowKeys
);

export const getAvailableRawReportTypes = createSelector(
    state => state.vesselReportReducer.visibleTypesAndFields,
    (visibleTypesAndFields) => {
        const visibleTypeMap = mapArrayByProp(visibleTypesAndFields, 'Id');
        return rawReportTypes.filter(rrt => visibleTypeMap[rrt.id]);
    }
);
