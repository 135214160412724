import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ScrollArea from 'components/scroll-area/scroll-area';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* helpers */
import { formatNumber, getClassName, setObjectProp } from 'utils/helpers/info-helper';
import { renderFormContent } from 'components/form-fields/form-fields-helper';
/* actions */
import {
    getVessel,
    searchVessels,
    toggleManualAdding,
    setAsNewCompany,
    submitForm,
    importVessel,
    getHeadOwners,
    getIceClassLevels,
    getCommercialOperators,
    getTechnicalManagers,
    resetAll
} from './admin-vessel-management-form-actions';
/* selectors */
import { getFormData } from './admin-vessel-management-form-selectors';
/* components */
import Input from 'components/input/input';
import Validation from 'components/validation/validation';
/* constants */
import { fieldMap, dataMap } from './admin-vessel-management-form-filed-map';
import { sectionsAndFields } from './admin-vessel-management-form-sections-and-fields';
/* styles */
import './admin-vessel-management-form.scss';

const emptyArray = [];

const initialState = {
    searchCriteria: '',
    previousNewCompany: null,
    isAddingManually: false,
    editingVessel: {
        BlockCoefficientCb: null,
        CubicCapacityCorrectionFactorFc: null,
        BudgetConsumption: emptyArray,
        BudgetConsumptionDataSourceId: null,
        SegmentMinSpeed: null,
        SegmentMaxSpeed: null,
        Comment: '',
        CommercialOperator: null,
        IceClassBureauVeritasLevel: null,
        DWT: '',
        Draft: '',
        IMOClass: '',
        Imo: '',
        Loa: '',
        NoxMainEngine: null,
        NoxAuxEngine: null,
        OperationsEmail: emptyArray,
        VesselOwner: null,
        OwnerEmail: emptyArray,
        PanamaCanal: false,
        ScrubberDataSourceId: null,
        KielCanal: false,
        SuezCanal: true,
        SfcMainEngine: null,
        SfcAuxEngine: null,
        ShowOnStenaBulk: false,
        SortOrder: '',
        TechnicalManagementEmail: emptyArray,
        TechnicalManager: null,
        Title: '',
        TradingArea: null,
        VesselEmail: emptyArray,
        VesselSegment: null,
        VesselType: null,
        VoluntaryStructuralEnhancement: null,
        TotalCubicCapacity: null,
        YearBuilt: '',
        HasScrubber: null,
        Class: '',
        GT: null,
        ShouldSyncReportsToVeracity: false,
        ScrubberInstallationDate: null,
        ScrubberInstallationDateOffset: null,
        Mmsi: null,
        Beam: null
    }
};

export class AdminVesselManagementForm extends React.PureComponent {
    state = {
        ...initialState
    }

    static getDerivedStateFromProps(props, state) {
        if (props.formData.editingVessel && props.formData.editingVessel.Imo !== state.editingVessel.Imo) {
            return {
                editingVessel: { ...props.formData.editingVessel }
            };
        }
        if (!props.formData.editingVessel && !props.isAddingManually && state.editingVessel.Imo) {
            return {
                ...initialState
            };
        }
        if (props.newCompany && state.previousNewCompany !== props.newCompany) {
            return {
                previousCompany: props.newCompany,
                editingVessel: {
                    ...state.editingVessel,
                    [props.newCompany.roleName]: props.newCompany.company
                }
            };
        }
        if (state.isAddingManually !== props.isAddingManually && !props.isAddingManually) {
            return {
                ...initialState
            };
        }
        return null;
    }

    componentDidMount() {
        this.getInitialData(this.props.activeVesselImo);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activeVesselImo !== this.props.activeVesselImo && this.props.isUpdateModeActive) {
            this.getInitialData(this.props.activeVesselImo);
        }
        if (prevProps.isAddingManually !== this.props.isAddingManually && !this.props.isAddingManually) {
            this.props.resetAll();
        }
        if (this.props.validationErrors && prevProps.validationErrors !== this.props.validationErrors) {
            Object.keys(this.props.validationErrors).forEach((key) => {
                this.form.showError(key, () => this.props.validationErrors[key].join(';'));
            });
        }
    }

    componentWillUnmount() {
        this.resetAll();
        if (this.discardTimeout) {
            clearTimeout(this.discardTimeout);
        }
    }

    getInitialData = (imo = null) => {
        this.props.getIceClassLevels();
        if (imo) {
            this.props.getVessel(imo);
        } else {
            this.setState({ ...initialState });
        }
        this.props.getCommercialOperators(imo);
        this.props.getHeadOwners(imo);
        this.props.getTechnicalManagers(imo);
    };

    handleSearchCriteriaUpdate = searchCriteria => {
        this.setState({ searchCriteria });
    };

    searchVessels = () => {
        this.props.searchVessels(this.state.searchCriteria);
    };

    closeAndDiscardForm = () => {
        this.props.router.navigate(appRoutes.Admin.VesselManagement);
        this.discardTimeout = setTimeout(this.resetAll, 500);
    };

    getVesselDetails = vessel => {
        const vesselTechnicalDetails = [];
        if (vessel?.Imo) {
            vesselTechnicalDetails.push(vessel.Imo);
        }
        if (vessel?.VesselType) {
            vesselTechnicalDetails.push(vessel.VesselType);
        }
        if (vessel?.DWT) {
            vesselTechnicalDetails.push(
                `${t('ADMIN.VESSEL_MANAGEMENT.FORM.DWT')} ${formatNumber(vessel.DWT, 0)} ${t('UNITS.DWT')}`
            );
        }
        if (vessel?.TotalCubicCapacity) {
            vesselTechnicalDetails.push(
                `${t('ADMIN.VESSEL_MANAGEMENT.FORM.CAPACITY')} ${formatNumber(vessel.TotalCubicCapacity, 0)}`
                + ` ${t('UNITS.CUBIC_CAPACITY')}`
            );
        }
        if (vessel?.IMOClass) {
            vesselTechnicalDetails.push(
                `${t('ADMIN.VESSEL_MANAGEMENT.FORM.IMO_CLASS')} `
                + `${vessel.IMOClass && vessel.IMOClass.split(',').join('/')}`
            );
        }
        return vesselTechnicalDetails.join(', ');
    };

    toggleValue = (key) => {
        return () => {
            if (key) {
                const editingVessel = { ...this.state.editingVessel };
                editingVessel[key] = !editingVessel[key];
                this.setState({ editingVessel });
            }
        };
    };

    getVesselSource = vessel => {
        const textClass = getClassName('sten-vessels-management-form-search__result-source', {
            'text-success': vessel.VesselManagementSearchSource === 'Orbit',
            'text-warning': vessel.HasMultipleSources
        });
        return vessel.HasMultipleSources
            ? <span className={textClass}>{t('ADMIN.VESSEL_MANAGEMENT.FORM.FOUND_IN_VESSEL_PROVIDER')}</span>
            : <span className={textClass}>{`Source: ${vessel.VesselManagementSearchSource}`}</span>;
    };

    handleCompanyAdd = (role) => (value) => {
        this.props.setAsNewCompany(value, role);
    };

    handleSubmitForm = () => {
        this.props.submitForm(this.state.editingVessel);
    }

    toggleFormElements() {
        this.setState({ isAddingManually: true });
        this.props.toggleFormElements(true);
    }

    resetAll = () => {
        this.props.resetAll();
        this.setState({ ...initialState });
    }

    saveRef = c => {
        this.form = c;
    }

    handleValueChange = (prop) => (value) => {
        this.setState((prevState) => {
            let editingVessel = { ...prevState.editingVessel };
            if (prop === 'VesselType' && prevState.editingVessel[prop] !== value) {
                editingVessel.VesselSegment = null;
            }
            editingVessel = setObjectProp(editingVessel, prop, value, true);
            return { editingVessel };
        });
    };

    renderContent = () => {
        const { editingVessel } = this.state;
        let segmentOptions = [];
        if (editingVessel && editingVessel.VesselType) {
            segmentOptions = this.props.segments.filter(seg => {
                return seg.VesselSegmentsType === editingVessel.VesselType.Name;
            });
        }
        const params = {
            data: this.state.editingVessel,
            onChange: this.handleValueChange,
            toggleValue: this.toggleValue,
            handleCompanyAdd: this.handleCompanyAdd,
            isEditing: this.props.activeVesselImo || this.state.isAddingManually,
            editingOptions: {
                commercialOperators: this.props.commercialOperators,
                headOwners: this.props.headOwners,
                vesselTypes: this.props.vesselTypes,
                vesselSegments: segmentOptions,
                tradingAreas: this.props.tradingAreas,
                technicalManagers: this.props.technicalManagers,
                iceClassLevels: this.props.iceClassLevels
            },
            disabledFields: {
                ...this.props.formData.lockedFields,
                VesselType: !!this.props.activeVesselImo,
                VesselSegment: !editingVessel.VesselType,
                ScrubberInstallationDate: !editingVessel.HasScrubber
            }
        };
        return renderFormContent({
            sections: sectionsAndFields,
            fieldMap,
            dataMap,
            params
        });
    };

    getEditForm = (isEditing) => {
        const buttonText = isEditing
            ? t('ADMIN.VESSEL_MANAGEMENT.FORM.UPDATE_VESSEL')
            : t('ADMIN.VESSEL_MANAGEMENT.FORM.ADD_VESSEL');

        return (
            <Validation.Form
                onSubmit={this.handleSubmitForm}
                ref={this.saveRef}
                clearErrorsOnSubmit
            >
                <ScrollArea className="sten-content__body" shouldScrollToActiveElement>
                    {this.renderContent()}
                </ScrollArea>
                <footer className="sten-content__footer flex-row">
                    <div className="col-12">
                        <button
                            className="btn btn--secondary col-24"
                            type="button"
                            onClick={this.closeAndDiscardForm}
                        >
                            {t('ADMIN.VESSEL_MANAGEMENT.FORM.DISCARD')}
                        </button>
                    </div>
                    <div className="col-12">
                        <Validation.Button className="btn btn--primary col-24">{buttonText}</Validation.Button>
                    </div>
                </footer>
            </Validation.Form>
        );
    };

    render() {
        const { activeVesselImo: isEditing, foundVessels, hadSearchedForVessels, importVessel } = this.props;
        const { isAddingManually, searchCriteria } = this.state;
        const formTitle = isEditing
            ? t('ADMIN.VESSEL_MANAGEMENT.FORM.EDIT_VESSEL')
            : t('ADMIN.VESSEL_MANAGEMENT.FORM.ADD_VESSEL');
        const searchResultsVesselIconClass = getClassName(`sten-vessels-management-form-search__result-icon
        flex-shrink flex flex-center`, {
            'text-primary': foundVessels && foundVessels.length
        });
        const sidebarFormClass = getClassName('sten-vessels-management-form sten-content', {
            'sten-content--has-footer': isEditing || isAddingManually
        });

        return (
            <div className={sidebarFormClass}>
                <div className="sten-content__header">
                    <h1 className="sten-content__title flex-grow">
                        {formTitle}
                    </h1>
                    <div className="flex-shrink">
                        <button onClick={this.closeAndDiscardForm} className="btn-link icon icon-close" />
                    </div>
                </div>
                {!isEditing && !isAddingManually && (
                    <React.Fragment>
                        <div className="sten-content__section">
                            <Validation.Form onSubmit={this.searchVessels} clearErrorsOnSubmit>
                                <div className="flex-row flex-end">
                                    <div className="flex-grow">
                                        <label className="label">
                                            {t('ADMIN.VESSEL_MANAGEMENT.FORM.VESSELS')}
                                        </label>
                                        <Validation.Wrapper
                                            hintsOnHover
                                            validations={{ required: true, minLength: 3 }}
                                        >
                                            <Input
                                                name="vesselSearch"
                                                placeholder={
                                                    t('ADMIN.VESSEL_MANAGEMENT.FORM.SEARCH_BY_PLACEHOLDER')
                                                }
                                                suffixIcon="icon icon-search"
                                                value={searchCriteria}
                                                onChange={this.handleSearchCriteriaUpdate}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                    <div className="flex-shrink">
                                        <Validation.Button className="btn btn--primary">
                                            {t('GLOBAL.SEARCH')}
                                        </Validation.Button>
                                    </div>
                                </div>
                            </Validation.Form>
                        </div>
                        <div className="sten-content__separator" />
                        {hadSearchedForVessels && foundVessels.length === 0 && (
                            <div className="sten-vessels-management-form-search__result">
                                <div className="flex-row flex-center">
                                    <div className="flex-shrink">
                                        <div className={searchResultsVesselIconClass}>
                                            <span className="icon icon-laden" />
                                        </div>
                                    </div>
                                    <div className="flex-grow">
                                        <h4>{t('ADMIN.VESSEL_MANAGEMENT.FORM.NO_VESSEL_FOUND_TITLE')}</h4>
                                        <p className="text-secondary">
                                            {t('ADMIN.VESSEL_MANAGEMENT.FORM.NO_VESSEL_FOUND_TEXT')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                        {hadSearchedForVessels
                            && foundVessels.length > 0
                            && foundVessels.map(vessel => (
                                <div
                                    className="sten-vessels-management-form-search__result flex"
                                    key={vessel.Imo}
                                >
                                    <div className={searchResultsVesselIconClass}>
                                        <span className="icon icon-laden" />
                                    </div>
                                    <div
                                        className={
                                            'sten-vessels-management-form-search__result-content '
                                            + 'flex-row flex-grow flex-center'
                                        }
                                    >
                                        <div className="col-18">
                                            <h3 className="text-uppercase">
                                                {vessel.Title}
                                            </h3>
                                            <p className="text-secondary">
                                                {this.getVesselDetails(vessel)}
                                            </p>
                                            {this.getVesselSource(vessel)}
                                        </div>
                                        <div className="col-6">
                                            {vessel.VesselManagementSearchSource === 'Orbit' && (
                                                <Link
                                                    className="btn btn--link btn--secondary btn--xs col-24"
                                                    to={`${appRoutes.Admin.VesselManagement}/edit/${vessel.Imo}`}
                                                >
                                                    {t('ADMIN.VESSEL_MANAGEMENT.FORM.EDIT')}
                                                </Link>
                                            )}
                                            {vessel.VesselManagementSearchSource !== 'Orbit' && (
                                                <button
                                                    className="btn btn--primary btn--xs col-24"
                                                    onClick={importVessel.bind(this, vessel.Imo)}
                                                >
                                                    {t('ADMIN.VESSEL_MANAGEMENT.FORM.IMPORT')}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {foundVessels.length === 0 && (
                            <div className="sten-content__section">
                                <div className="flex-row">
                                    <div className="col-24">
                                        <button
                                            className="btn btn--secondary col-24"
                                            onClick={this.toggleFormElements.bind(this)}
                                        >
                                            {t('ADMIN.VESSEL_MANAGEMENT.FORM.ADD_MANUALLY')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                )}
                {(isEditing || isAddingManually) && this.getEditForm(isEditing)}
            </div>
        );
    }
}

AdminVesselManagementForm.propTypes = {
    activeVesselImo: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    commercialOperators: PropTypes.arrayOf(PropTypes.any).isRequired,
    fieldToUpdate: PropTypes.objectOf(PropTypes.any),
    formData: PropTypes.objectOf(PropTypes.any).isRequired,
    foundVessels: PropTypes.arrayOf(PropTypes.any).isRequired,
    getCommercialOperators: PropTypes.func.isRequired,
    getHeadOwners: PropTypes.func.isRequired,
    getIceClassLevels: PropTypes.func.isRequired,
    getTechnicalManagers: PropTypes.func.isRequired,
    getVessel: PropTypes.func.isRequired,
    hadSearchedForVessels: PropTypes.bool.isRequired,
    headOwners: PropTypes.arrayOf(PropTypes.any).isRequired,
    importVessel: PropTypes.func.isRequired,
    iceClassLevels: PropTypes.arrayOf(PropTypes.any).isRequired,
    isAddingManually: PropTypes.bool.isRequired,
    isUpdateModeActive: PropTypes.bool.isRequired,
    newCompany: PropTypes.objectOf(PropTypes.any),
    resetAll: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    searchVessels: PropTypes.func.isRequired,
    segments: PropTypes.arrayOf(PropTypes.any).isRequired,
    setAsNewCompany: PropTypes.func.isRequired,
    submitForm: PropTypes.func.isRequired,
    technicalManagers: PropTypes.arrayOf(PropTypes.any).isRequired,
    toggleFormElements: PropTypes.func.isRequired,
    tradingAreas: PropTypes.arrayOf(PropTypes.any).isRequired,
    vesselTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
    validationErrors: PropTypes.objectOf(PropTypes.any)
};

AdminVesselManagementForm.defaultProps = {
    activeVesselImo: null,
    fieldToUpdate: null,
    newCompany: null,
    validationErrors: null
};

function mapStateToProps(state) {
    return {
        activeVesselImo: state.adminVesselManagementReducer.activeVesselImo,
        commercialOperators: state.adminVesselManagementFormReducer.commercialOperators,
        fieldToUpdate: state.adminVesselManagementFormReducer.fieldToUpdate,
        formData: getFormData(state),
        foundVessels: state.adminVesselManagementFormReducer.foundVessels,
        hadSearchedForVessels: state.adminVesselManagementFormReducer.hadSearchedForVessels,
        headOwners: state.adminVesselManagementFormReducer.headOwners,
        iceClassLevels: state.adminVesselManagementFormReducer.iceClassLevels,
        isAddingManually: state.adminVesselManagementFormReducer.isAddingManually,
        isUpdateModeActive: state.adminVesselManagementReducer.isUpdateModeActive,
        newCompany: state.adminVesselManagementFormReducer.newCompany,
        segments: state.adminVesselManagementReducer.segments,
        technicalManagers: state.adminVesselManagementFormReducer.technicalManagers,
        tradingAreas: state.adminVesselManagementReducer.tradingAreas,
        vesselTypes: state.adminVesselManagementReducer.vesselTypes,
        validationErrors: state.adminVesselManagementFormReducer.validationErrors
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getIceClassLevels: () => getIceClassLevels(dispatch),
        getCommercialOperators: imo => getCommercialOperators(dispatch, imo),
        getHeadOwners: imo => getHeadOwners(dispatch, imo),
        getTechnicalManagers: imo => getTechnicalManagers(dispatch, imo),
        getVessel: imo => getVessel(dispatch, imo),
        importVessel: imo => importVessel(dispatch, imo),
        resetAll: () => resetAll(dispatch),
        searchVessels: searchCriteria => searchVessels(dispatch, searchCriteria),
        setAsNewCompany: (companyName, key) => setAsNewCompany(dispatch, companyName, key),
        submitForm: vessel => submitForm(dispatch, vessel),
        toggleFormElements: isAddingManually => toggleManualAdding(dispatch, isAddingManually)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminVesselManagementForm));
