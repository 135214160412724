import React from 'react';
import PropTypes from 'prop-types';
/* components */
import Select from '../select/select';
/* constants */
import { boolOptions, boolOptionsMap } from '../form-fields/form-fields-helper';
/* style */
import './bool-select.scss';

class BoolSelect extends React.PureComponent {
    handleChange = (selectedOption) => {
        const val = selectedOption ? selectedOption.value : null;
        this.props.onChange(val);
    };

    render() {
        const { disabled, name, value } = this.props;
        return (
            <Select
                className="sten-bool-select"
                clearable
                name={name}
                disabled={disabled}
                labelKey="label"
                valueKey="value"
                options={boolOptions}
                onChange={this.handleChange}
                value={typeof value === 'boolean' ? boolOptionsMap[value] : null}
            />
        );
    }
}

BoolSelect.propTypes = {
    disabled: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool
};

BoolSelect.defaultProps = {
    disabled: false,
    name: '',
    value: null
};

export default BoolSelect;
