import { t } from 'utils/i18n/i18n-model';

export const sectionsAndFields = [
    {
        fields: ['Vessel', 'CallSign', 'ReportDate']
    },
    {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('QUESTIONNAIRE.SECTIONS.BUNKER_ROB'),
        sections: [
            { data: 'BunkerData' },
            {
                fields: ['CleaningEquipmentOnBoard'],
                inline: false
            },
            {
                title: t('QUESTIONNAIRE.LABELS.LAST_UW_INSPECTION'),
                fields: ['LastUwInspection', 'PortOfLastUwInspection']
            },
            {
                title: t('QUESTIONNAIRE.LABELS.LAST_UW_CLEANING'),
                fields: ['LastUwCleaning', 'PortOfLastUwCleaning']
            },
            {
                title: t('QUESTIONNAIRE.LABELS.LAST_PROPELLER_POLISH'),
                fields: ['LastPropellerPolish', 'PortOfLastPropellerPolish']
            },
            {
                title: t('QUESTIONNAIRE.LABELS.PROLONGED_PORT_STAYS'),
                fields: ['ProlongedPortStays', 'PortOfProlongedPortStay']
            }
        ]
    },
    {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('QUESTIONNAIRE.SECTIONS.CP_SPEED_INFORMATION'),
        sections: [{ data: 'SpeedInformations' }]
    },
    {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('QUESTIONNAIRE.SECTIONS.MINIMUM_SPEED_DETAILS'),
        sections: [{ data: 'SpeedDetails' }]
    },
    {
        type: 'accordion',
        title: t('QUESTIONNAIRE.SECTIONS.PROPELLER_CHARACTERISTICS'),
        sections: [
            {
                fields: [
                    'PitchOfPropeller',
                    'PropellerDiameter',
                    'PropellerNumberOfBlades',
                    'PropellerExpandedArea',
                    'PropellerAverageSlip'
                ]
            },
            { inline: false, fields: ['PropellerEnergySavingDevices'] }
        ]
    },
    {
        type: 'accordion',
        title: t('QUESTIONNAIRE.SECTIONS.OPERATIONAL_ENGINE_LIMITS'),
        sections: [
            { fields: ['OelTorque', 'OelPower', 'OelRpm'] }
        ]
    },
    {
        type: 'accordion',
        title: t('QUESTIONNAIRE.SECTIONS.PORT_VISITS'),
        data: 'PortVisits'
    },
    {
        type: 'accordion',
        title: t('QUESTIONNAIRE.SECTIONS.RPM_RANGE'),
        sections: [
            { fields: ['RpmMinMax'] },
            { inline: false, fields: ['SpeedAndConsumptionDetails'] }
        ]
    },
    {
        type: 'accordion',
        title: t('QUESTIONNAIRE.SECTIONS.REMARKS'),
        sections: [{ fields: ['Remarks'], inline: false }]
    }
];
