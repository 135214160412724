import React from 'react';
import PropTypes from 'prop-types';
/* helpers */
import { formatCoordinates, parseCoordinates } from 'utils/helpers/info-helper';
/* components */
import Select from 'components/select/select';
import TextHighlight from 'components/text-highlight/text-highlight';

export class CoordinatesInput extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            options: []
        };
    }

    static getCustomPoint(coordinates) {
        return {
            Name: formatCoordinates(coordinates[0], coordinates[1]),
            Coordinates: coordinates,
            Id: 'CustomPoint',
            IsCustomPoint: true,
            Subtitles: formatCoordinates(coordinates[0], coordinates[1], 2)
        };
    }

    onCoordinatesChange = value => {
        this.props.onChange(value);
    };

    handleSearch = value => {
        const coordinates = parseCoordinates(value);
        if (coordinates) {
            this.setState({ options: [CoordinatesInput.getCustomPoint(coordinates)] });
        }
    };

    renderOption = (point, searchCriteria) => {
        return (
            <React.Fragment>
                <h3 className="sten-select__option-title">
                    {searchCriteria
                        ? <TextHighlight input={point.Name} highlight={searchCriteria} />
                        : point.Name
                    }
                </h3>
                <p className="sten-select__option-subtitle">
                    {point.Subtitles}
                </p>
            </React.Fragment>
        );
    };

    render() {
        let className = 'sten-coordinates-input';
        if (this.props.className) {
            className += ` ${this.props.className}`;
        }
        let value = null;
        if (this.props.value) {
            value = CoordinatesInput.getCustomPoint(this.props.value);
        }
        return (
            <div className={className}>
                <Select
                    {...this.props}
                    searchable
                    onSearch={this.handleSearch}
                    valueKey="Id"
                    labelKey="Name"
                    setSearchCriteriaOnFocus
                    className={className}
                    onChange={this.onCoordinatesChange}
                    value={value}
                    options={this.state.options}
                    optionRenderer={this.renderOption}
                    placeholder={this.props.placeholder}
                />
            </div>
        );
    }
}

CoordinatesInput.propTypes = {
    className: PropTypes.string,
    hasWarning: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.number)
};

CoordinatesInput.defaultProps = {
    className: '',
    hasWarning: false,
    placeholder: '(eg. 44 48 48 N 020 22 18 E)',
    value: null
};

export default CoordinatesInput;
