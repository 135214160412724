import { ActionTypes } from './market-intel-actions';

const emptyArray = [];

const initialState = {
    bunkerPorts: emptyArray,
    tradingRoutes: emptyArray,
    selectedRouteCode: '',
    options: {
        mapTypes: null,
        layerTypes: null,
        cargoStatuses: null,
        vesselSegments: null,
        vesselDistributionTypes: null,
        vesselDistributionAreas: null
    },
    vesselDistributionHeatMap: emptyArray,
    totalVesselDistribution: null
};

export default function marketIntelReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.MARKET_INTEL_RESET_ALL:
        return initialState;
    case ActionTypes.MARKET_INTEL_SET_BUNKER_PORTS: {
        return { ...state, bunkerPorts: action.bunkerPorts || emptyArray };
    }
    case ActionTypes.MARKET_INTEL_SET_ROUTES: {
        return { ...state, tradingRoutes: action.tradingRoutes || emptyArray };
    }
    case ActionTypes.MARKET_INTEL_SET_OPTIONS: {
        return { ...state, options: action.options };
    }
    case ActionTypes.MARKET_INTEL_SET_DISTRIBUTION_HEATMAP: {
        return { ...state, vesselDistributionHeatMap: action.heatMap };
    }
    case ActionTypes.MARKET_INTEL_SET_TOTAL_VESSEL_DISTRIBUTION: {
        return { ...state, totalVesselDistribution: action.distribution };
    }
    case ActionTypes.MARKET_INTEL_SET_SELECTED_ROUTE_CODE: {
        return { ...state, selectedRouteCode: action.routeCode };
    }
    default:
        return state;
    }
}
