import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { joinArrayObjProp } from 'utils/helpers/info-helper';
import { translate } from 'utils/i18n/i18n-model';
/* components */
import TableHeadCell from 'components/table-head-cell/table-head-cell';
import TextHighlight from 'components/text-highlight/text-highlight';
import Avatar from 'components/avatar/avatar';
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
import EmptyContent from 'components/empty-content/empty-content';
/* styles */
import './admin-user-management-table.scss';

const t = (key) => translate(`ADMIN.USER_MANAGEMENT.TABLE.${key}`);

const tableHeadCells = [
    { title: t('USER'), sortProp: 'Username', sortable: true },
    { title: t('COMPANY'), sortProp: 'Company', sortable: true },
    { title: t('ROLE'), sortProp: 'Role', sortable: true },
    { title: t('DEPARTMENTS'), sortProp: 'DepartmentName', sortable: true },
    { title: t('STATUS'), sortProp: 'Status', sortable: true },
    { title: t('LOCAL_LOGIN'), sortProp: 'LocalLogin', sortable: true, sortOrderInvert: true },
    { title: t('CONTACT_LIST_VISIBILITY'), sortProp: 'Visibility', sortable: true }
];

const animationLength = 600;

class AdminUserManagementTable extends React.PureComponent {
    animationStartTime = null;

    componentDidUpdate(prevProps) {
        if (this.props.isUpdateModeActive !== prevProps.isUpdateModeActive) {
            window.requestAnimationFrame(this.updateSizeOnFrame);
        }
    }

    updateSizeOnFrame = (timestamp) => {
        if (!this.animationStartTime) {
            this.animationStartTime = timestamp;
        }
        if (timestamp - this.animationStartTime < animationLength) {
            if (this.fixedHeaderTable) {
                this.fixedHeaderTable.updateHeaderSizes();
            }
            window.requestAnimationFrame(this.updateSizeOnFrame);
        } else {
            this.animationStartTime = null;
        }
    };

    saveRef = c => { this.fixedHeaderTable = c; };

    render() {
        let usersTable = 'sten-admin-user-management-table';
        if (this.props.className) {
            usersTable += ` ${this.props.className}`;
        }
        if (!this.props.users.length) {
            return <EmptyContent />;
        }

        return (
            <FixedHeaderTable
                className={usersTable}
                customFirstChild={TableHeadCell}
                ref={this.saveRef}
                contentClassName="sten-admin-user-management-table__scroll-content"
                onScroll={this.props.handleScroll}
            >
                <table className="sten-table">
                    <thead>
                        <tr>
                            {tableHeadCells.map((cell, index) => (
                                <TableHeadCell
                                    className={cell.className ? cell.className : ''}
                                    sortOrder={this.props.sortProp === cell.sortProp ? this.props.sortOrder : ''}
                                    sortable={cell.sortable}
                                    sortOrderInvert={cell.sortOrderInvert}
                                    key={index} // eslint-disable-line react/no-array-index-key
                                    onClick={this.props.onSortChange.bind(this, 'sortProp', cell.sortProp)}
                                >
                                    {cell.title}
                                </TableHeadCell>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.users && this.props.users.length > 0 && this.props.users.map(user => (
                            <tr key={user.UserId}>
                                <td>
                                    <div
                                        className="flex-row flex-center"
                                        onClick={this.props.onRowClick.bind(this, user.UserId)}
                                    >
                                        <div
                                            className="flex-shrink cursor-pointer"
                                            onClick={this.props.onRowClick.bind(this, user.UserId)}
                                        >
                                            <Avatar profileImageUrl={user.ProfileImageUrl} />
                                        </div>
                                        <div
                                            className="
                                                flex-grow text-ellipsis sten-admin-user-management-table__name-cell"
                                        >
                                            <h3 className="sten-title-link">{`${user.FirstName} ${user.LastName}`}</h3>
                                            <a
                                                className="sten-link text-ellipsis"
                                                href={`mailto: ${user.Email}`}
                                                title={user.Email}
                                            >
                                                <TextHighlight
                                                    input={user.Email}
                                                    highlight={this.props.searchCriteria}
                                                />
                                            </a>
                                        </div>
                                    </div>
                                </td>
                                <td className="text-uppercase text-ellipsis">
                                    {joinArrayObjProp(user.UserCompanies, 'Name')}
                                </td>
                                <td className="text-uppercase">
                                    {user.Role}
                                </td>
                                <td className="text-uppercase text-ellipsis">
                                    {joinArrayObjProp(user.Departments, 'Name')}
                                </td>
                                <td className="text-uppercase">
                                    {this.props.statusesById && this.props.statusesById[user.Status]
                                        ? this.props.statusesById[user.Status].Title
                                        : ''}
                                </td>
                                <td className="text-uppercase">
                                    {user.LocalLoginEnabled ? 'Enabled' : 'Disabled'}
                                </td>
                                <td className="text-uppercase">
                                    {user.IsVisibleInContactList ? 'Visible' : 'Hidden'}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </FixedHeaderTable>
        );
    }
}

AdminUserManagementTable.propTypes = {
    className: PropTypes.string,
    isUpdateModeActive: PropTypes.bool.isRequired,
    onRowClick: PropTypes.func.isRequired,
    onSortChange: PropTypes.func.isRequired,
    searchCriteria: PropTypes.string.isRequired,
    sortOrder: PropTypes.string.isRequired,
    sortProp: PropTypes.string.isRequired,
    handleScroll: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    statusesById: PropTypes.objectOf(PropTypes.any)
};

AdminUserManagementTable.defaultProps = {
    className: '',
    statusesById: null
};

export default AdminUserManagementTable;
