import * as colors from 'utils/helpers/pdf-styles/colors';

export default {
    '.sten-tank-layout-vessel': {
        display: 'inline-block'
    },
    '.sten-tank-layout-vessel__wrapper': {
        height: '144px',
        maxWidth: '720px',
        width: '100%',
        position: 'relative'
    },
    '.sten-tank-layout-vessel__wings': {
        position: 'absolute',
        top: '15px',
        left: '36px',
        bottom: '15px',
        right: '70px'
    },
    '.sten-tank-layout-vessel__wing': {
        border: '1px solid #ccc',
        textAlign: 'center'
    },
    '.sten-tank-layout-vessel__wing:first-child': {
        'border-radius': '48px 0 0 48px'
    },
    '.sten-tank-layout-vessel__wing + .sten-tank-layout-vessel__wing': {
        borderLeft: 'none'
    },
    '.sten-tank-layout-vessel__tank': {
        height: '50%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        fontSize: '18px',
        paddingTop: '12px',
        color: '#000'
    },
    '.sten-tank-layout-vessel__tank + .sten-tank-layout-vessel__tank': {
        borderTop: '1px solid #ccc',
        paddingTop: 0,
        paddingBottom: '12px'
    },
    '.sten-tank-layout-vessel__tank .sten-tank-layout-bar': {
        margin: '1px',
        width: ' calc(100% - 2px)'
    },
    '.sten-tank-layout-vessel__tank.sten-tank-layout-vessel__tank--slop': {
        fontWeight: 700
    },
    '.sten-tank-layout-bar': {
        position: 'relative',
        width: '100%',
        height: '6px',
        backgroundColor: '#fff'
    },
    '.sten-tank-layout-bar__level': {
        position: 'absolute',
        height: '100%',
        top: 0,
        backgroundColor: '#bbb'
    },
    '.sten-tank-layout-bar--invalid .sten-tank-layout-bar__level': {
        backgroundColor: `rgba(${colors.colorDanger}, 0.5)`
    },
    '.sten-tank-layout-bar--slop .sten-tank-layout-bar__level': {
        backgroundColor: '#555'
    },
    '.sten-tank-layout-vessel__legend': {
        display: 'flex',
        marginTop: '1rem'
    },
    '.sten-tank-layout-vessel__legend-item': {
        padding: '0 1rem',
        width: '50%',
        fontSize: '18px',
        textAlign: 'right'
    },
    '.sten-tank-layout-vessel__legend-item:before': {
        content: '""',
        display: 'inline-block',
        height: '0.25rem',
        width: '1rem',
        marginRight: '0.5rem',
        verticalAlign: 'middle',
        backgroundColor: '#bbb'
    },
    '.sten-tank-layout-vessel__legend-item + .sten-tank-layout-vessel__legend-item': {
        textAlign: 'left'
    },
    '.sten-tank-layout-vessel__legend-item + .sten-tank-layout-vessel__legend-item:before': {
        backgroundColor: '#555'
    },
    '.sten-tank-layout-vessel-svg': {
        height: '100%'
    },
    '.sten-tank-layout-vessel-svg path': {
        stroke: 'none'
    },
    '.sten-tank-layout-vessel-svg__head': {
        height: '100%',
        minWidth: '120px',
        overflow: 'hidden'
    },
    '.sten-tank-layout-vessel-svg__tail': {
        height: '100%',
        overflow: 'hidden'
    },
    '.sten-tank-layout-vessel-svg__bg': {
        fill: '#eee'
    },
    '.sten-tank-layout-vessel-svg__path-1 ': {
        fill: '#ddd'
    },
    '.sten-tank-layout-vessel-svg__path-2 ': {
        fill: '#ccc'
    },
    '.sten-tank-layout-vessel-svg__path-3': {
        fill: '#bbb'
    }
};
