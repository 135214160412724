import React from 'react';
import PropTypes from 'prop-types';
/* components */
import VesselList from './vessel-list/vessel-list';
import FleetRange from './fleet-range/fleet-range';
import FleetActivity from './fleet-activity/fleet-activity';
import TabSelector from 'components/tab-selector/tab-selector';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* styles */
import './fleet-overview.scss';

class FleetOverview extends React.PureComponent {
    constructor() {
        super();
        this.state = {
            activeTabId: 0
        };
    }

    setActiveTab = tabId => {
        this.setState({
            activeTabId: tabId
        });
    };

    getTabs() {
        const tabs = [{
            id: 0,
            title: translate('FLEET_OVERVIEW.VESSELS'),
            icon: 'icon-laden'
        }, {
            id: 1,
            title: translate('FLEET_OVERVIEW.ACTIVITY'),
            icon: 'icon-cross-list'
        }];
        if (this.props.permissions && this.props.permissions.GetBunkerNeedsList) {
            tabs.push({
                id: 2,
                title: translate('FLEET_OVERVIEW.RANGE'),
                icon: 'icon-oil-laden'
            });
        }
        return tabs;
    }

    getComponent() {
        switch (this.state.activeTabId) {
        case 0:
            return <VesselList />;
        case 1:
            return <FleetActivity />;
        default:
            return <FleetRange />;
        }
    }

    render() {
        const className = 'sten-fleet-overview sten-content';
        return (
            <div className={className}>
                <div className="sten-content__header">
                    <h1 className="sten-content__title flex-grow">
                        {translate('FLEET_OVERVIEW.TITLE')}
                    </h1>
                    <div className="flex-shrink">
                        <button
                            className="btn-link icon icon-close"
                            onClick={this.props.onCloseClick}
                        />
                    </div>
                </div>
                <div className="sten-content__body sten-fleet-overview__body-fixed">
                    <TabSelector
                        tabItems={this.getTabs()}
                        activeTabId={this.state.activeTabId}
                        onChange={this.setActiveTab}
                    />
                </div>
                {this.getComponent()}
            </div>
        );
    }
}

FleetOverview.propTypes = {
    onCloseClick: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired
};

export default FleetOverview;
