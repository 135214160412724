import getTitle from './title-config';
import getFontSize from 'utils/helpers/cached-font-size';
/* services */
import ConfigService from 'services/config-api/config-service';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* constants */
import { chartConfigBaseColors, formatter } from './chart-config-constants';


const chartColors = {
    PropultionPlusAux: '#aa46ba',
    ReportedPropultion: '#4c46ba',
    Aux: '#d63b61',
    CargoHeating: '#e7ff2d',
    TankCleaning: '#ff7e4b',
    IG: '#917882',
    SensorPropultion: '#9575CD'
};

const getConsumptionConfig = () => {
    const rem = getFontSize();
    const fsm = rem / 16;
    const config = {
        chart: {
            backgroundColor: chartConfigBaseColors.colorChartBg,
            plotBackgroundColor: chartConfigBaseColors.colorChartPlotBg,
            spacing: [2 * rem, rem, rem, 2.5 * rem],
            resetZoomButton: { theme: { display: 'none' } }
        },
        xAxis: {
            gridLineColor: chartConfigBaseColors.colorXAxisGridLine,
            gridZIndex: 1,
            tickPixelInterval: 400,
            tickWidth: 0,
            labels: {
                enabled: false
            },
            startOnTick: true
        },
        yAxis: {
            minTickInterval: 2,
            tickAmount: 3,
            labels: {
                reserveSpace: false
            },
            title: {
                ...getTitle(fsm),
                text: t('VOYAGE_PERFORMANCE.CHARTS.CONSUMPTION_MT')
            }
        },
        legend: {
            itemMarginTop: 10 * fsm
        },
        tooltip: {
            formatter
        },
        series: [
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.BUDGET'),
                type: 'areasplinerange',
                lineWidth: 0,
                data: [],
                zIndex: 1,
                color: chartConfigBaseColors.colorAreaSuccess,
                fillColor: chartConfigBaseColors.colorAreaSuccessFill,
                states: { hover: { enabled: false }, inactive: { enabled: false } },
                marker: {
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                excludeFromExtremes: true,
                enableMouseTracking: false,
                connectNulls: true,
                events: {
                    legendItemClick: () => false
                }
            },
            {
                type: 'areasplinerange',
                lineWidth: 0,
                data: [],
                zIndex: 2,
                color: chartConfigBaseColors.colorAreaWarningFill,
                fillColor: chartConfigBaseColors.colorAreaWarningFill,
                showInLegend: false,
                states: { hover: { enabled: false }, inactive: { enabled: false } },
                marker: {
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                enableMouseTracking: false,
                connectNulls: true
            },
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.BUDGET'),
                type: 'line',
                color: chartConfigBaseColors.colorAreaSuccess,
                lineWidth: 0,
                data: [],
                zIndex: 0,
                showInLegend: false,
                states: { hover: { enabled: false }, inactive: { enabled: false } },
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.PROPULTION_PLUS_AUX'),
                type: 'area',
                color: chartColors.PropultionPlusAux,
                lineWidth: 2 * fsm,
                data: [],
                zIndex: 5,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            },
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.REPORTED_PROPULTION'),
                type: 'area',
                color: chartColors.ReportedPropultion,
                lineWidth: 2 * fsm,
                data: [],
                zIndex: 6,
                visible: false,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            },
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.AUX'),
                type: 'area',
                color: chartColors.Aux,
                lineWidth: 2 * fsm,
                data: [],
                zIndex: 8,
                visible: false,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            },
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.CARGO_HEATING'),
                type: 'area',
                color: chartColors.CargoHeating,
                lineWidth: 2 * fsm,
                zIndex: 9,
                visible: false,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            },
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.TANK_CLEANING'),
                type: 'area',
                color: chartColors.TankCleaning,
                lineWidth: 2 * fsm,
                zIndex: 10,
                visible: false,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            },
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.IG'),
                type: 'area',
                color: chartColors.IG,
                lineWidth: 2 * fsm,
                zIndex: 11,
                visible: false,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            }
        ]
    };
    if (ConfigService.featureToggles.showSensorData) {
        config.series.splice(5, 0,
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.SENSOR_PROPULTION'),
                type: 'area',
                color: chartColors.SensorPropultion,
                lineWidth: 2 * fsm,
                zIndex: 7,
                visible: false,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            });
    }
    return config;
};

export default getConsumptionConfig;
