import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { takeEvery, put, select, delay } from 'redux-saga/effects';
/* utils */
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import { ActionTypes } from './vessel-info-actions';
/* services */
import VesselService from 'services/core-api/vessel-service';
import PdfGeneratorService from 'services/core-api/pdf-generator-service';
/* exports */
import CargoPDF from 'components/cargo-export/cargo-pdf';
import generateVesselInfoCargoXLSX from 'components/cargo-export/cargo-xlsx';

function* getRangeOptions() {
    const rangeOptions = yield VesselService.getRangeOptions();
    if (rangeOptions) {
        yield put({
            type: ActionTypes.VESSEL_INFO_SET_RANGE_OPTIONS,
            rangeOptions
        });
    }
}

function* generateCargoXLSX() {
    yield put({ type: 'TOGGLE_LOADER', payload: true });
    yield delay(0);
    const voyage = yield select(state => state.homeReducer.selectedVoyage);
    const vesselName = yield select(state => state.homeReducer.selectedVesselBase.Title);
    const wings = yield select(state => state.homeReducer.selectedVoyageTankLayout);
    const { Coating } = yield select(state => state.homeReducer.selectedVoyageCargoSummary);
    yield generateVesselInfoCargoXLSX({
        date: TimeHelper.getFormatted(voyage.EndDate, { utc: true }),
        vesselName,
        wings,
        coating: Coating
    });
    yield put({ type: 'TOGGLE_LOADER', payload: false });
}

function* generateCargoPDF() {
    const vesselName = yield select(state => state.homeReducer.selectedVesselBase.Title);
    const voyage = yield select(state => state.homeReducer.selectedVoyage);
    const vesselInfoCargoData = yield select(state => state.homeReducer.selectedVoyageTankLayout);
    const selectedVoyageCargoSummary = yield select(state => state.homeReducer.selectedVoyageCargoSummary);
    const date = yield select(state => state.mapDatePickerReducer.selectedDate);
    const htmlString = ReactDOMServer.renderToStaticMarkup(
        <CargoPDF
            cargoSummary={selectedVoyageCargoSummary}
            data={vesselInfoCargoData}
            vesselName={vesselName}
            date={TimeHelper.getFormatted(voyage.EndDate, { utc: true })}
        />
    );

    PdfGeneratorService.convert(
        {
            html: htmlString,
            viewport: { width: 1080, height: 1920 },
            pdf: { format: 'A4', landscape: false }
        },
        `Last 5 cargoes per tank - ${vesselName} - ${TimeHelper.getFormatted(date, { utc: true })}`
    );
}

export default function* vesselInfoSaga() {
    yield takeEvery(ActionTypes.VESSEL_INFO_GET_RANGE_OPTIONS, getRangeOptions);
    yield takeEvery(ActionTypes.VESSEL_INFO_CARGO_GENERATE_XLSX, generateCargoXLSX);
    yield takeEvery(ActionTypes.VESSEL_INFO_CARGO_GENERATE_PDF, generateCargoPDF);
}
