import CoreApi from './core-api';

class VesselService {
    constructor() {
        this.resourceUrl = 'Vessels';
    }

    add(data) {
        return CoreApi.post(this.resourceUrl, data)
            .then(() => true, error => error.response.data);
    }

    getActivityById(id, params = {}) {
        return CoreApi.get(`${this.resourceUrl}/${id}/Activity`, params)
            .then(response => response.data, () => null);
    }

    getAll = (params) => {
        return CoreApi.post(`${this.resourceUrl}/Filtered`, params)
            .then(response => response.data, () => null);
    };

    getAllVesselVoyages(imo, params) {
        return CoreApi.get(`${this.resourceUrl}/${imo}/Voyages`, params)
            .then(response => response.data, () => null);
    }

    getAllWithoutPagination(params) {
        return CoreApi.get(`${this.resourceUrl}/All`, params)
            .then(response => response.data, () => null);
    }

    getBaseById(id, params = {}) {
        return CoreApi.get(`${this.resourceUrl}/${id}/Base`, params)
            .then(response => response.data, () => null);
    }

    getBudgetedConsumption(id) {
        return CoreApi.get(`${this.resourceUrl}/${id}/Consumption`)
            .then(response => response.data, () => []);
    }

    getByImo(imo) {
        return CoreApi.get(`${this.resourceUrl}/${imo}`)
            .then(response => response.data, () => null);
    }

    getBureauVeritasIceClassLevels() {
        return CoreApi.get(`${this.resourceUrl}/BureauVeritasIceClassLevels`)
            .then(response => response.data, () => null);
    }

    getCommercialContracts() {
        return CoreApi.get(`${this.resourceUrl}/Relationship/CommercialContracts`)
            .then(response => response.data, () => null);
    }

    getCommercialOperators(params) {
        return CoreApi.get(`${this.resourceUrl}/CommercialOperators`, params)
            .then(response => response.data, () => null);
    }

    getCommercialOperatorsBySearchTerm(searchTerm) {
        return CoreApi.get(`${this.resourceUrl}/CommercialOperators/Search`, { searchTerm })
            .then(response => response.data, () => null);
    }

    getCompanies() {
        return CoreApi.get(`${this.resourceUrl}/Companies`)
            .then(response => response.data, () => null);
    }

    getDetailsById(imo, params = {}) {
        return CoreApi.get(`${this.resourceUrl}/${imo}/Details`, params)
            .then(response => response.data, () => null);
    }

    getDistribution(props) {
        return CoreApi.get(`${this.resourceUrl}/Distribution`, props)
            .then(response => response.data, () => null);
    }

    getDistributionTypes() {
        return CoreApi.get(`${this.resourceUrl}/DistributionTypes`)
            .then(response => response.data, () => null);
    }

    getFleetActivity(params) {
        return CoreApi.get(`${this.resourceUrl}/FleetListActivity`, params)
            .then(response => response.data, (error) => error);
    }

    getFleetRange(params) {
        /* Api Endpoint to be changed to FleetRange */
        return CoreApi.get(`${this.resourceUrl}/VesselsBunkerNeeds`, params)
            .then(response => response.data, () => null);
    }

    getFleetVessels(params) {
        return CoreApi.get(`${this.resourceUrl}/Overview`, params)
            .then(response => response.data, () => null);
    }

    getFootprint(imo, params) {
        return CoreApi.get(`${this.resourceUrl}/${imo}/Footprint`, params)
            .then(response => response.data, () => null);
    }

    getHeadOwners(params) {
        return CoreApi.get(`${this.resourceUrl}/VesselOwners`, params)
            .then(response => response.data, () => null);
    }

    getHeatMap(props) {
        return CoreApi.get(`${this.resourceUrl}/Heatmap`, props)
            .then(response => response.data, () => null);
    }

    getHistorical(params) {
        /*
        * StartTime         dateTime
        * EndTime           dateTime
        * Latest            boolean
        * */
        return CoreApi.post(`${this.resourceUrl}/Historical`, params)
            .then(response => response.data, () => null);
    }

    getHistoricalById(id, params, preventLoader) {
        return CoreApi.get(`${this.resourceUrl}/Historical/${id}`, params, preventLoader)
            .then(response => response.data, () => null);
    }

    getImages = (imo) => CoreApi.get(`${this.resourceUrl}/${imo}/Images`)
        .then(response => response.data, () => []);

    getRangeOptions() {
        return CoreApi.get(`${this.resourceUrl}/VesselRanges`)
            .then(response => response.data, () => null);
    }

    getRelationships(imo) {
        return CoreApi.get(`${this.resourceUrl}/${imo}/Relationships`)
            .then(response => response.data, () => null);
    }

    getRelativeDetailsById(id, params = {}) {
        return CoreApi.get(`${this.resourceUrl}/${id}/RelativeDetails`, params)
            .then(response => response.data, () => null);
    }

    getResponsibleOffices() {
        return CoreApi.get(`${this.resourceUrl}/Relationship/ResponsibleOffices`)
            .then(response => response.data, () => null);
    }

    getSegments() {
        return CoreApi.get(`${this.resourceUrl}/VesselTypes`)
            .then(response => response.data, () => null);
    }

    getSegmentsByScope() {
        return CoreApi.get(`${this.resourceUrl}/Segments`)
            .then(response => response.data, () => null);
    }

    getStatuses() {
        return CoreApi.get(`${this.resourceUrl}/Relationship/Statuses`)
            .then(response => response.data, () => null);
    }

    getSensorData(params) {
        return CoreApi.get(`${this.resourceUrl}/SensorsData`, params)
            .then(response => response.data, () => null);
    }

    getTCVessels() {
        return CoreApi.get(`${this.resourceUrl}/TimeCharter`)
            .then(response => response.data, () => null);
    }

    getTechnicalManagers(params) {
        return CoreApi.get(`${this.resourceUrl}/TechManagers`, params)
            .then(response => response.data, () => null);
    }

    getTradingAreas() {
        return CoreApi.get(`${this.resourceUrl}/TradingAreas`)
            .then(response => response.data, () => null);
    }

    getTypes() {
        return CoreApi.get(`${this.resourceUrl}/TenantVesselTypes`)
            .then(response => response.data, () => null);
    }

    getList(params) {
        return CoreApi.get(`${this.resourceUrl}/VesselsList`, params)
            .then(response => response.data, () => null);
    }

    getVoyagesPreviewById(id, params) {
        return CoreApi.get(`${this.resourceUrl}/${id}/VoyagesPreview`, params, true)
            .then(response => response.data, () => null);
    }

    importVessel(imo) {
        return CoreApi.put(`${this.resourceUrl}/${imo}/Import`)
            .then(response => response.data, () => null);
    }

    /* search */
    searchByCriteria({ searchCriteria, limit, filterLNGVessels = false }) {
        return CoreApi.get(`${this.resourceUrl}/SearchForDeviations`, {
            Query: searchCriteria,
            limit,
            filterLNGVessels
        }).then(response => response.data);
    }

    searchVessels(params) {
        return CoreApi.get(`${this.resourceUrl}/Search`, params)
            .then(response => response.data, () => null);
    }

    setRelationships(imo, relationships) {
        return CoreApi.post(`${this.resourceUrl}/${imo}/Relationships`, { Relationships: relationships })
            .then(() => true, () => false);
    }

    update(id, data) {
        return CoreApi.put(`${this.resourceUrl}/${id}`, data)
            .then(() => true, error => error.response.data);
    }

    getStatusTypes() {
        return CoreApi.get(`${this.resourceUrl}/VesselStatusTypes`)
            .then(response => response.data, () => null);
    }

    getCiiGradePerYears(params) {
        return CoreApi.get(`${this.resourceUrl}/CIIAERDataPerYears`, params)
            .then(response => response.data, () => null);
    }

    getDailySensorData(params) {
        return CoreApi.get(`${this.resourceUrl}/DailySensorsData`, params)
            .then(response => response.data, () => null);
    }

    getTenantFuelTypes() {
        return CoreApi.get(`${this.resourceUrl}/TenantFuelTypes`).then(response => response.data, () => null);
    }
}

export default new VesselService();
