import stenTableStyles, { border } from 'utils/helpers/pdf-styles/sten-table';
import headings from 'utils/helpers/pdf-styles/headings';
import layout from 'utils/helpers/pdf-styles/layout';
import { headerCellBg, tableHighlightRowBg } from 'utils/helpers/pdf-styles/colors';
import { convertJSObjectToCssString } from 'utils/helpers/pdf-styles/style-helpers';

const logoStyle = {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'center',
    width: '16rem'
};

const css = {
    html: {
        fontSize: '23px',
        fontFamily: 'Arial, Helvetica, sans-serif'
    },
    'html, body': {
        margin: 0,
        padding: 0
    },
    ...layout,
    table: {
        borderRight: border,
        borderLeft: border
    },
    '.inline + .inline': {
        marginLeft: '0.5rem'
    },
    '.sten-cii-rating': {
        borderRadius: '0.2rem',
        backgroundColor: tableHighlightRowBg,
        padding: '0 0.25rem'
    },
    '.summary-vessel-name-text': headings.h2,
    '.summary-voyage-number-text': headings.h3,
    '.summary-header': {
        display: 'flex',
        justifyContent: 'space-between',
        height: '8rem',
        alignItems: 'center',
        backgroundColor: '#eee',
        borderBottom: '2px solid #dadada',
        textAlign: 'center',
        marginBottom: '2rem',
        padding: '1rem 2rem'
    },
    '.summary-header-company-logo': {
        ...logoStyle,
        backgroundPositionX: 'left',
        height: '5rem'
    },
    '.summary-header-orbit-logo': {
        ...logoStyle,
        backgroundPositionX: 'right',
        height: '3rem'
    },
    '.summary-disclaimer': {
        position: 'absolute',
        bottom: 0,
        left: 0,
        color: '#999'
    },
    '.header-title': {
        ...headings.h3,
        paddingBottom: '0.5rem',
        textAlign: 'center',
        textTransform: 'uppercase'
    },
    '.page': {
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        pageBreakInside: 'avoid',
        pageBreakAfter: 'always'
    },
    '.fuel-row td': {
        backgroundColor: headerCellBg
    },
    '.excluded-cell': {
        backgroundColor: '#f7f7f7',
        color: '#999'
    },
    '.excluded-cell-emphasized': {
        backgroundColor: '#f7f7f7',
        color: '#0f2e39',
        fontWeight: 'bold'
    },
    '.report-title': {
        ...headings.h1,
        paddingBottom: '0.5rem',
        paddingTop: '1rem'
    },
    '.sten-table--sm td': {
        width: '21%',
        padding: '0.25rem 0.5rem'
    },
    '.sten-table td:first-child': {
        width: '16%',
        backgroundColor: headerCellBg,
        fontWeight: 'bold'
    },
    '.cell-style': {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    '.sten-table': {
        tableLayout: 'fixed'
    },
    '.label-value-table-header-row': {
        visibility: 'collapse'
    },
    '.label-value-table-header-row td:first-child': {
        width: '17rem'
    },
    '.sten-table.sten-raw-reports-table-row__cargo-operations th': {
        fontWeight: 'normal',
        whiteSpace: 'normal'
    },
    '.sten-table.sten-raw-reports-table-row__cargo-operations td': {
        fontWeight: 'normal'
    },
    '.sten-table.sten-raw-reports-table-row__cargo-operations': {
        border: 'none'
    },
    '.sten-table.sten-raw-reports-table-row__cargo-operations thead tr:first-child': {
        borderTop: 'none'
    },
    '.sten-table.sten-raw-reports-table-row__cargo-operations th:first-child': {
        width: '0.5rem'
    },
    '.sten-table.sten-raw-reports-table-row__cargo-operations td:last-child': {
        borderRight: 'none'
    },
    '.sten-table.sten-raw-reports-table-row__cargo-operations .col-12': {
        float: 'none'
    }
};


export default `
${stenTableStyles}
${convertJSObjectToCssString(css)}
`;
