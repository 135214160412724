import React from 'react';
import PropTypes from 'prop-types';
/* router */
import { TRouter, withRouter } from 'app-router';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
/* styles */
import './gsm-main-panel-vessel-list.scss';

export class GsmMainPanelVesselList extends React.PureComponent {
    onRowClick = (queryParam, id) => (e) => {
        e.stopPropagation();
        if (queryParam) {
            this.props.router.navigate({ query: { [queryParam]: id } });
        }
    };

    getVesselRow = rows =>
        rows.map((row) => {
            const style = {
                color: row.iconColor || '#fff'
            };

            const iconClass = `flex-grow icon ${this.props.icon}`;
            const hasHover = row.clickQueryParam ? ' gsm-main-panel-vessel-list__row--hover' : '';
            const rowClass = `flex flex-center gsm-main-panel-vessel-list__row${hasHover}`;
            return (
                <div
                    className={rowClass}
                    key={row.id}
                    onClick={this.onRowClick(row.clickQueryParam, row.id)}
                >
                    <div className="gsm-main-panel-vessel-list__row__icon flex flex-center" style={{ ...style }}>
                        <span className={iconClass} />
                    </div>
                    <div className="gsm-main-panel-vessel-list__row__content flex-grow">
                        <h3 className="text-ellipsis text-uppercase">
                            {row.title}
                        </h3>
                        {row.subtitle && (
                            <h6 className="text-secondary text-ellipsis">
                                {row.subtitle}
                            </h6>
                        )}
                    </div>
                </div>
            );
        });

    render() {
        return (
            <ScrollArea>
                {this.getVesselRow(this.props.data)}
            </ScrollArea>
        );
    }
}

GsmMainPanelVesselList.propTypes = {
    data: PropTypes.arrayOf(PropTypes.any).isRequired,
    icon: PropTypes.string.isRequired,
    router: TRouter.isRequired
};

export default withRouter(GsmMainPanelVesselList);
