import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* actions */
import { getInitialData, updateFleets, resetAll } from './fleet-management-actions';
/* components */
import AfterInitialMount from 'components/after-initial-mount/after-initial-mount';
import AssignToFleetModal from 'components/assign-to-fleet-modal/assign-to-fleet-modal';
import FleetList from './fleet-list/fleet-management-fleet-list';
import Fleet from './fleet/fleet-management-fleet';
/* styles */
import './fleet-management.scss';

class FleetManagement extends React.PureComponent {
    componentDidMount() {
        this.props.getInitialData();
    }

    render() {
        const { permissions, router } = this.props;
        const activeFleetId = Number(router.params.id) || null;
        const isNewFleet = router.location.pathname.includes('new');
        const isEditing = router.location.pathname.includes(`edit/${activeFleetId}`) || isNewFleet;
        return (
            <div className="sten-content sten-fleet-management flex">
                {permissions?.ShowVesselManagement && (
                    <AfterInitialMount><AssignToFleetModal /></AfterInitialMount>
                )}
                <div className="sten-fleet-management__content-left col-lg-6 col-md-8 col-sm-10 col-xs-12">
                    <div className="sten-content__header flex-row">
                        <div className="flex-grow">
                            <h1 className="sten-content__title">{t('FLEET_MANAGEMENT.TITLE')}</h1>
                        </div>
                        <div className="flex-shrink">
                            {!isNewFleet && (
                                <Link
                                    className="btn btn--link btn--primary"
                                    to={`${appRoutes.Vessel.FleetManagement}/new`}
                                    title={t('FLEET_MANAGEMENT.NEW_FLEET')}
                                >
                                    <span className="btn__icon icon icon-plus" />
                                    <span className="hide-md">{t('FLEET_MANAGEMENT.NEW_FLEET')}</span>
                                </Link>
                            )}
                        </div>
                    </div>
                    <div className="sten-content__body">
                        <FleetList
                            activeFleetId={activeFleetId}
                            privateFleets={this.props.privateFleets}
                            publicFleets={this.props.publicFleets}
                        />
                    </div>
                </div>
                <div className="sten-fleet-management__content-right col-lg-18 col-md-16 col-sm-14 col-xs-12">
                    <Fleet
                        isEditing={isEditing}
                        fleetId={activeFleetId}
                        onChange={this.props.updateFleets}
                        options={this.props.options}
                        permissions={permissions}
                        resourceUpdated={this.props.resourceUpdated}
                        user={this.props.user}
                    />
                </div>
            </div>
        );
    }
}

FleetManagement.propTypes = {
    getInitialData: PropTypes.func.isRequired,
    options: PropTypes.shape({
        companyFleets: PropTypes.arrayOf(PropTypes.object),
        segments: PropTypes.arrayOf(PropTypes.object),
        technicalManagers: PropTypes.arrayOf(PropTypes.object),
        vesselOwners: PropTypes.arrayOf(PropTypes.object),
        mainAttributes: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    privateFleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    publicFleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    resetAll: PropTypes.func.isRequired,
    resourceUpdated: PropTypes.objectOf(PropTypes.any).isRequired,
    router: TRouter.isRequired,
    updateFleets: PropTypes.func.isRequired,
    user: PropTypes.objectOf(PropTypes.any).isRequired
};

function mapStateToProps(state) {
    return {
        options: state.fleetManagementReducer.options,
        privateFleets: state.fleetManagementReducer.privateFleets,
        publicFleets: state.fleetManagementReducer.publicFleets,
        permissions: state.userReducer.permissions,
        resourceUpdated: state.userReducer.resourceUpdated,
        user: state.userReducer.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getInitialData: () => getInitialData(dispatch),
        updateFleets: (method, fleet) => updateFleets(dispatch, method, fleet),
        resetAll: () => resetAll(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FleetManagement));
