import { takeEvery, all, put, select } from 'redux-saga/effects';
import { ActionTypes } from './vessel-filter-actions';
/* services */
import ConfigService from 'services/config-api/config-service';
import VesselFleetsService from 'services/core-api/fleet-management-service';
import VesselService from 'services/core-api/vessel-service';

function* getOptions() {
    const permissions = yield select(state => state.userReducer.permissions);
    const userId = yield select(state => state.userReducer.user.UserId);
    const canSeePrivateGroups = permissions.MapFiltersGroupsShow && permissions.ShowFleetManagementPrivateGroups;
    const canSeePublicGroups = permissions.MapFiltersGroupsShow && permissions.ShowFleetManagementPublicGroups;

    const res = yield all({
        privateFleets: canSeePrivateGroups ? VesselFleetsService.getPrivate() : null,
        publicFleets: canSeePublicGroups ? VesselFleetsService.getPublic() : null,
        segments: permissions.MapFiltersSegmentsShow ? VesselService.getSegments() : null
    });
    const data = {
        segmentsByType: res.segments && res.segments.reduce((res, segment) => {
            if (!res[segment.VesselSegmentsType]) {
                res[segment.VesselSegmentsType] = [];
            }
            res[segment.VesselSegmentsType].push(segment);
            return res;
        }, {}),
        privateFleets: res.privateFleets && ConfigService.featureToggles.showDynamicFleets
            ? res.privateFleets
            : res.privateFleets.filter(fl => !fl.IsDynamic),
        publicFleets: res.publicFleets
            ? res.publicFleets.filter(fl =>
                fl.UserId !== userId && (ConfigService.featureToggles.showDynamicFleets || !fl.IsDynamic))
            : res.publicFleets
    };
    yield put({ type: ActionTypes.VESSEL_FILTER_SET_OPTIONS, ...data });
}

function* getCompanies() {
    const permissions = yield select(state => state.userReducer.permissions);
    const companies = permissions.MapFiltersCompaniesShow ? yield VesselService.getCompanies() : null;
    if (companies) {
        yield put({ type: ActionTypes.VESSEL_FILTER_SET_COMPANIES, companies });
    }
}

export default function* vesselFilterSaga() {
    yield takeEvery(ActionTypes.VESSEL_FILTER_GET_OPTIONS, getOptions);
    yield takeEvery(ActionTypes.VESSEL_FILTER_GET_COMPANIES, getCompanies);
}
