import React from 'react';
import PropTypes from 'prop-types';
/* styles */
import './carousel.scss';

import noImagePlaceholder from '../../assets/images/gsm/carousel_images/carousel_image_placeholder.png';

class Carousel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedItem: 0
        };
    }

    getTranslateValue = () => {
        const moveStep = !document.querySelector('.sten-carousel-image')
            || document.querySelector('.sten-carousel-image').clientWidth;
        return this.state.selectedItem * moveStep;
    };

    allowContentToShow = (contentType, props) => {
        if (contentType === 'controls') {
            return props.images && props.images.length > 1;
        }
        if (contentType === 'placeholder') {
            return props.images && !props.images.length;
        }

        return false;
    };

    generateImageElements = (images, props) => {
        const carouselImages = [...images];

        if (this.allowContentToShow('placeholder', props)) {
            carouselImages.push(this.props.noImagesPlaceholder || noImagePlaceholder);
        }

        return carouselImages.map((image, index) => {
            const style = {
                backgroundImage: `url(${image})`
            };
            return (
                // eslint-disable-next-line react/no-array-index-key
                <div className="sten-carousel-image" key={index} style={style} />
            );
        });
    };

    slideImages = (direction) => {
        let newSelectedItem = this.state.selectedItem;
        if (direction === 'right') {
            if (this.state.selectedItem === this.props.images.length - 1) {
                newSelectedItem = 0;
            } else {
                newSelectedItem += 1;
            }
        } else if (direction === 'left') {
            if (this.state.selectedItem === 0) {
                newSelectedItem = this.props.images.length - 1;
            } else {
                newSelectedItem -= 1;
            }
        }

        this.setState({
            selectedItem: newSelectedItem
        });
    };

    render() {
        const moveTranslateValue = this.getTranslateValue();

        const style = {
            transform: `translateX(-${moveTranslateValue}px)`
        };

        return (
            <div className="sten-carousel">
                <div className="sten-carousel-image__wrapper" style={style}>
                    {this.generateImageElements(this.props.images, this.props)}
                </div>

                {this.allowContentToShow('controls', this.props) && (
                    <div
                        className="sten-carousel-control sten-carousel-control__left icon icon-angle-left"
                        onClick={this.slideImages.bind(this, 'left')}
                    />
                )}
                {this.allowContentToShow('controls', this.props) && (
                    <div
                        className="sten-carousel-control sten-carousel-control__right icon icon-angle-right"
                        onClick={this.slideImages.bind(this, 'right')}
                    />
                )}
            </div>
        );
    }
}

Carousel.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string),
    noImagesPlaceholder: PropTypes.string
};

Carousel.defaultProps = {
    images: null,
    noImagesPlaceholder: ''
};

export default Carousel;
