import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { getClassName } from 'utils/helpers/info-helper';
/* components */
import EmptyContent from 'components/empty-content/empty-content';
/* styles */
import './reports-panel.scss';

const badges = [
    {
        key: 'missing',
        className: 'sten-badge sten-badge--danger',
        label: t('VOYAGE_PERFORMANCE.REPORTS.MISSING')
    },
    {
        key: 'suspicious',
        dataMissingKey: 'suspiciousLimitReached',
        className: 'sten-badge sten-badge--warning',
        label: t('VOYAGE_PERFORMANCE.REPORTS.SUSPICIOUS')
    },
    {
        key: 'veracitySyncError',
        className: 'sten-badge sten-badge--error',
        label: t('VOYAGE_PERFORMANCE.REPORTS.VERACITY_SYNC_ERROR')
    }
];

class VoyagePerformanceReportsPanel extends React.PureComponent {
    clickHandlers = {};

    renderBadges = (reportProps) => {
        return (
            <React.Fragment>
                {badges.reduce((res, badge) => {
                    if (badge.dataMissingKey && reportProps[badge.dataMissingKey]) {
                        res.push(
                            <div key={badge.key} className="flex-shrink">
                                <span
                                    className={badge.className}
                                    title={t('VOYAGE_PERFORMANCE.REPORTS.LIMIT_REACHED', { type: badge.label })}
                                >
                                    {`${t('GLOBAL.NOT_AVAILABLE')} ${badge.label}`}
                                </span>
                            </div>
                        );
                    } else if (reportProps[badge.key] > 0) {
                        res.push(
                            <div key={badge.key} className="flex-shrink">
                                <span className={badge.className}>{`${reportProps[badge.key]} ${badge.label}`}</span>
                            </div>
                        );
                    }
                    return res;
                }, [])}
            </React.Fragment>
        );
    };

    getClickHandler = (type) => {
        if (!this.clickHandlers[type]) {
            this.clickHandlers[type] = () => this.props.onTypeClick(type);
        }
        return this.clickHandlers[type];
    };

    renderButton = (reportProps) => {
        let titleText = reportProps.title;
        if (typeof reportProps.total === 'number') {
            titleText += ` (${reportProps.total})`;
        }
        return (
            <div key={reportProps.key} className="sten-panel__section sten-panel__separator">
                <div className="flex-row flex-center">
                    <div className="flex-shrink">
                        <h4>{titleText}</h4>
                    </div>
                    {this.renderBadges(reportProps)}
                    <div className="flex-grow text-right">
                        {(reportProps.total > 0 || reportProps.total === null) && (
                            <button
                                className="btn-link icon icon-full-screen"
                                onClick={this.getClickHandler(reportProps.type)}
                                title={t('VOYAGE_PERFORMANCE.REPORTS.SHOW_REPORTS')}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const mappedProps = this.props.reportProps
            ? Object.keys(this.props.reportProps).map(key => this.props.reportProps[key])
            : [];
        return (
            <div className={getClassName('sten-voyage-performance-reports-panel sten-panel', this.props.className)}>
                <div className="sten-panel__header">
                    <h4 className="text-uppercase">{t('VOYAGE_PERFORMANCE.REPORTS.TITLE')}</h4>
                </div>
                {this.props.reportProps ? (
                    <div className="sten-panel__body">
                        {mappedProps.map(this.renderButton)}
                    </div>
                ) : <EmptyContent className="flex-grow" />}
            </div>
        );
    }
}

VoyagePerformanceReportsPanel.propTypes = {
    className: PropTypes.string,
    onTypeClick: PropTypes.func.isRequired,
    reportProps: PropTypes.objectOf(PropTypes.any)
};

VoyagePerformanceReportsPanel.defaultProps = {
    className: '',
    reportProps: null
};

export default VoyagePerformanceReportsPanel;
