import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
/* components */
import WeatherPerformanceReportCharterpartyTable from './weather-performance-report-charterparty-table';
import EmptyContent from 'components/empty-content/empty-content';
/* styles */
import './weather-performance-report-charterparty-panel.scss';
/* helpers */
import { formatValue } from 'utils/helpers/info-helper';
/* constants */
import { charterpartyRows } from './weather-performance-report-charterparty-panel-constants';

const allowancePrefixMap = {
    1: '+',
    2: '±',
    3: '−'
};

class WeatherPerformanceReportCharterpartyPanel extends React.PureComponent {
    getRows = (data, row) => (
        <div className="flex-row col-12" key={row.key}>
            <label className="sten-panel__label col-8">{row.label}</label>
            <label className="sten-panel__label sten-panel__label--value col-16">
                {row.renderValue
                    ? row.renderValue(data, row)
                    : formatValue(data, row)}
            </label>
        </div>
    );

    render() {
        const { router } = this.props;
        return (
            <div className="sten-panel">
                <div className="sten-panel__header">
                    <h4 className="flex-grow text-uppercase">
                        {t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTERPARTY_PANEL.TITLE')}
                    </h4>
                    <div className="flex-shrink">
                        <Link
                            className="btn-link icon icon-edit"
                            to={router.getLinkTo({ add: { cpVoyage: this.props.voyageId, cpEdit: true } })}
                            title={t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT'
                                + '.CHARTERPARTY_PANEL.LINK_TITLE')}
                        />
                    </div>
                </div>
                <div className="sten-weather-performance-report-charterparty-panel">
                    {!this.props.data || !this.props.data.length
                        ? (
                            // eslint-disable-next-line max-len
                            <EmptyContent className="flex-grow sten-weather-performance-report-charterparty-panel--no-content">
                                <div className="sten-panel__section">
                                    <label className="sten-panel__label">
                                        {/* eslint-disable-next-line max-len */}
                                        {t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTERPARTY_PANEL.CHARTERPARTY_PLACEHOLDER')}
                                    </label>
                                </div>
                            </EmptyContent>
                        )
                        : this.props.data.map(cp => {
                            return (
                                <React.Fragment key={cp.Id}>
                                    <div className="sten-panel__separator" />
                                    <div className="sten-panel__section-header
                                        sten-weather-performance-report-charterparty-panel__section-header"
                                    >
                                        <label className="sten-panel__label">
                                            {/* eslint-disable-next-line max-len */}
                                            {`${t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTERPARTY_PANEL.FROM')}: `}
                                            <span className="sten-panel__label--value">
                                                {TimeHelper.getLocalFormattedWithOffset(cp.DateEffective)}
                                            </span>
                                        </label>
                                    </div>
                                    <div className="sten-panel__separator" />
                                    <div className="sten-panel__section flex flex--wrap flex-row">
                                        {charterpartyRows.map(row => (this.getRows(cp, row)))}
                                    </div>
                                    <WeatherPerformanceReportCharterpartyTable
                                        data={cp.FuelRestrictions}
                                        allowanceType={allowancePrefixMap[cp.AllowanceType.Id]}
                                    />
                                </React.Fragment>
                            );
                        })}
                </div>
            </div>
        );
    }
}

WeatherPerformanceReportCharterpartyPanel.propTypes = {
    data: PropTypes.arrayOf(PropTypes.any),
    router: TRouter.isRequired,
    voyageId: PropTypes.number.isRequired
};

WeatherPerformanceReportCharterpartyPanel.defaultProps = {
    data: null
};

export default withRouter(WeatherPerformanceReportCharterpartyPanel);
