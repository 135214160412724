import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { splitSegments } from 'components/segment-select/segment-select-helper';
/* helpers */
import { getVesselTechnicalDetails, objLength } from 'utils/helpers/info-helper';
import { fleetOptionRenderer } from 'utils/helpers/fleet-helper';
/* components */
import Accordion from 'components/accordion/accordion';
import Input from 'components/input/input';
import Select from 'components/select/select';
import Validation from 'components/validation/validation';
import CheckableListItem from 'components/checkable-list-item/checkable-list-item';
import TextHighlight from 'components/text-highlight/text-highlight';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.SIDEBAR.${key}`);

const emptyArray = [];

const allVesselsItem = {
    Title: translate('GLOBAL.SELECT_ALL'),
    Imo: -1
};

const upperCaseMatch = (string, searchTerm) => {
    const s = Number.isInteger(string) ? string.toString() : string;

    if (s && s.toUpperCase) {
        return s.toUpperCase().indexOf(searchTerm) !== -1;
    }

    return false;
};

class IncludedVesselsSection extends React.PureComponent {
    constructor(props) {
        super(props);
        let fleetFilters = {};
        if (props.showAdditionalVesselFilters) {
            fleetFilters = {
                selectedFleets: this.getSelectedItems('fleets', 'vesselsByFleet', 'Id', props.initialSelectedVessels),
                selectedCustomFleets: this.getSelectedItems('customFleets', 'vesselsByCustomFleet', 'VesselGroupId',
                    props.initialSelectedVessels)
            };
        }
        this.state = {
            searchCriteria: '',
            selectedVessels: props.initialSelectedVessels,
            selectedSegments:
                this.getSelectedItems('segments', 'vesselsBySegment', 'VesselTypeId', props.initialSelectedVessels),
            ...fleetFilters
        };
    }

    getVisibleVesselsMemoized = (vessels, searchCriteria) => {
        if (searchCriteria.length === 0) return this.props.vessels;
        const sc = searchCriteria.toUpperCase();
        return vessels.filter((v) => upperCaseMatch(v.Title, sc)
            || upperCaseMatch(v.Imo, sc)
            || upperCaseMatch(v.VesselTypeName, sc)
            || upperCaseMatch(v.Owner, sc));
    };

    getVisibleVessels = () => this.getVisibleVesselsMemoized(
        this.props.vessels,
        this.state.searchCriteria
    );

    handleSearchCriteriaChange = (searchCriteria) => this.setState({ searchCriteria });

    updateSelectedVessels = (vesselImos, isSelected) => {
        const selectedVessels = { ...this.state.selectedVessels };
        vesselImos.forEach((imo) => {
            if (isSelected) {
                selectedVessels[imo] = true;
            } else {
                delete selectedVessels[imo];
            }
        });
        this.updateState(selectedVessels);
    };

    updateState = (selectedVessels) => {
        let newState = {
            selectedVessels,
            selectedSegments: this.getSelectedItems('segments', 'vesselsBySegment', 'VesselTypeId', selectedVessels)
        };
        if (this.props.showAdditionalVesselFilters) {
            newState = {
                ...newState,
                selectedFleets: this.getSelectedItems('fleets', 'vesselsByFleet', 'Id', selectedVessels),
                selectedCustomFleets: this.getSelectedItems('customFleets', 'vesselsByCustomFleet',
                    'VesselGroupId', selectedVessels)
            };
        }
        this.setState(newState);
    };

    handleItemChange = (item, prop, key, isSelected) =>
        this.updateSelectedVessels(this.props[prop][item[key]], isSelected);

    handleVesselClick = (vessel) => this.updateSelectedVessels([vessel.Imo], !this.state.selectedVessels[vessel.Imo]);

    handleSegmentSelect = (segment) => this.handleItemChange(segment, 'vesselsBySegment', 'VesselTypeId', true);

    handleSegmentDeselect = (segment) => this.handleItemChange(segment, 'vesselsBySegment', 'VesselTypeId', false);

    handleFleetSelect = (fleet) => this.handleItemChange(fleet, 'vesselsByFleet', 'Id', true);

    handleFleetDeselect = (fleet) => this.handleItemChange(fleet, 'vesselsByFleet', 'Id', false);

    handleCustomFleetSelect = (fleet) => this.handleItemChange(fleet, 'vesselsByCustomFleet', 'VesselGroupId', true);

    handleCustomFleetDeselect = (fleet) => this.handleItemChange(fleet, 'vesselsByCustomFleet', 'VesselGroupId', false);

    handleClear = (elements) => {
        if (elements.length === 0) {
            this.updateState({});
        }
    };

    getVesselCount = (item, prop, key) => (this.props[prop][item[key]] && this.props[prop][item[key]].length) || 0;

    renderOption = (item, searchCriteria, prop, key, nameKey) => {
        const vesselCount = this.getVesselCount(item, prop, key);
        const title = `${item[nameKey]} (${vesselCount})`;
        return <TextHighlight input={title} highlight={searchCriteria} />;
    };

    renderSegmentOption = (segment, searchCriteria) => this.renderOption(segment, searchCriteria,
        'vesselsBySegment', 'VesselTypeId', 'VesselTypeName');

    renderCompanyFleetOption = (fleet, searchCriteria) => this.renderOption(fleet, searchCriteria,
        'vesselsByFleet', 'Id', 'Name');

    renderCustomFleetOption = (fleet, searchCriteria) => {
        const vesselCount = this.getVesselCount(fleet, 'vesselsByCustomFleet', 'VesselGroupId');
        return fleetOptionRenderer(fleet, searchCriteria, vesselCount);
    };

    isOptionDisabled = (item, prop, key) => !this.props[prop][item[key]] || this.props[prop][item[key]].length === 0;

    isSegmentDisabled = (segment) => this.isOptionDisabled(segment, 'vesselsBySegment', 'VesselTypeId');

    isFleetDisabled = (fleet) => this.isOptionDisabled(fleet, 'vesselsByFleet', 'Id');

    isCustomFleetDisabled = (segment) => this.isOptionDisabled(segment, 'vesselsByCustomFleet', 'VesselGroupId');

    vesselCountValidation = {
        custom: {
            rule: (value) => value > 0,
            hint: () => t('NO_VESSELS_SELECTED')
        }
    };

    toggleSelectAllVessels = () => {
        const isSelected = !this.areAllVisibleVesselsSelected();
        const vesselImos = this.getVisibleVessels().map(({ Imo }) => Imo);
        this.updateSelectedVessels(vesselImos, isSelected);
    };

    areAllVisibleVesselsSelected = () => {
        const visibleVessels = this.getVisibleVessels();
        return visibleVessels.every((vessel) => this.state.selectedVessels[vessel.Imo]);
    };

    getSelectedItems = (itemsProp, prop, key, vesselImos) =>
        this.props[itemsProp].filter((item) => this.props[prop][item[key]]
            && this.props[prop][item[key]].every((imo) => vesselImos[imo]));

    render() {
        const { searchCriteria } = this.state;
        const visibleVessels = this.getVisibleVessels();
        const accordionHeader = (
            <h4 className="text-uppercase">
                {`${t('INCLUDED_VESSELS')} (${objLength(this.state.selectedVessels)})`}
            </h4>
        );

        return (
            <Accordion
                isCollapsedBodyRendered
                className="sten-energy-management-kpi-sidebar__vessels"
                header={accordionHeader}
            >
                <div className="sten-content__section">
                    <div className="form-row">
                        <label className="label">{t('SEGMENT')}</label>
                        <Select
                            name="selectedSegments"
                            searchable
                            multiple
                            clearable
                            valueKey="VesselTypeId"
                            labelKey="VesselTypeName"
                            options={this.props.segments}
                            optionRenderer={this.renderSegmentOption}
                            placeholder={t('SELECT_SEGMENTS')}
                            value={this.state.selectedSegments}
                            onItemSelect={this.handleSegmentSelect}
                            onItemDeselect={this.handleSegmentDeselect}
                            onChange={this.handleClear}
                            optionDisabledCallback={this.isSegmentDisabled}
                            splitOptionsIntoSections={splitSegments}
                        />
                    </div>
                </div>
                {this.props.showAdditionalVesselFilters && (
                    <div className="sten-content__section">
                        <div className="form-row">
                            <label className="label">{t('COMPANY_FLEET')}</label>
                            <Select
                                name="selectedFleets"
                                searchable
                                multiple
                                clearable
                                valueKey="Id"
                                labelKey="Name"
                                options={this.props.fleets}
                                optionRenderer={this.renderCompanyFleetOption}
                                placeholder={t('SELECT_COMPANY_FLEETS')}
                                value={this.state.selectedFleets}
                                onItemSelect={this.handleFleetSelect}
                                onItemDeselect={this.handleFleetDeselect}
                                onChange={this.handleClear}
                                optionDisabledCallback={this.isFleetDisabled}
                            />
                        </div>
                    </div>
                )}
                {this.props.showAdditionalVesselFilters && (
                    <div className="sten-content__section">
                        <div className="form-row">
                            <label className="label">{t('CUSTOM_FLEET')}</label>
                            <Select
                                name="selectedCustomFleets"
                                searchable
                                multiple
                                clearable
                                valueKey="VesselGroupId"
                                labelKey="VesselGroupName"
                                options={this.props.customFleets}
                                optionRenderer={this.renderCustomFleetOption}
                                placeholder={t('SELECT_CUSTOM_FLEETS')}
                                value={this.state.selectedCustomFleets}
                                onItemSelect={this.handleCustomFleetSelect}
                                onItemDeselect={this.handleCustomFleetDeselect}
                                onChange={this.handleClear}
                                optionDisabledCallback={this.isCustomFleetDisabled}
                            />
                        </div>
                    </div>
                )}
                <div className="sten-content__section">
                    <div className="form-row">
                        <label className="label">{t('VESSELS')}</label>
                        <Input
                            value={searchCriteria}
                            onChange={this.handleSearchCriteriaChange}
                            placeholder={t('SEARCH_VESSELS')}
                            title={t('SEARCH_VESSELS_TITLE')}
                        />
                    </div>
                </div>
                <Validation.Wrapper
                    className="sten-energy-management-kpi-sidebar__vessels-validation"
                    validations={this.vesselCountValidation}
                >
                    <Validation.Value value={objLength(this.state.selectedVessels)} name="vesselCount" />
                </Validation.Wrapper>
                <CheckableListItem
                    item={allVesselsItem}
                    title={allVesselsItem.Title}
                    searchCriteria=""
                    titleClassName="text-primary"
                    isChecked={this.areAllVisibleVesselsSelected()}
                    onClick={this.toggleSelectAllVessels}
                />
                {visibleVessels.map((vessel) => (
                    <CheckableListItem
                        key={vessel.Imo}
                        title={vessel.Title}
                        subtitle={getVesselTechnicalDetails(vessel)}
                        item={vessel}
                        titleClassName={vessel.IsCompetition ? '' : 'text-primary'}
                        searchCriteria={searchCriteria}
                        isChecked={this.state.selectedVessels[vessel.Imo]}
                        onClick={this.handleVesselClick}
                    />
                ), this)}
            </Accordion>
        );
    }
}

IncludedVesselsSection.propTypes = {
    customFleets: PropTypes.arrayOf(PropTypes.any),
    fleets: PropTypes.arrayOf(PropTypes.any),
    initialSelectedVessels: PropTypes.objectOf(PropTypes.any).isRequired,
    segments: PropTypes.arrayOf(PropTypes.any).isRequired,
    showAdditionalVesselFilters: PropTypes.bool,
    vessels: PropTypes.arrayOf(PropTypes.any).isRequired,
    vesselsByCustomFleet: PropTypes.objectOf(PropTypes.any),
    vesselsByFleet: PropTypes.objectOf(PropTypes.any),
    vesselsBySegment: PropTypes.objectOf(PropTypes.any).isRequired
};

IncludedVesselsSection.defaultProps = {
    customFleets: emptyArray,
    fleets: emptyArray,
    showAdditionalVesselFilters: false,
    vesselsByCustomFleet: null,
    vesselsByFleet: null
};

export default IncludedVesselsSection;
