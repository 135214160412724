import PropTypes from 'prop-types';

export const TRouteRendererQueryParams = PropTypes.shape({
    countryBordersNamesEnabled: PropTypes.bool,
    mapTypeId: PropTypes.number,
    voyageId: PropTypes.number
});

export const TRouteRendererMapData = PropTypes.shape({
    Vessel: PropTypes.shape({
        Imo: PropTypes.number,
        Title: PropTypes.string.isRequired,
        SegmentId: PropTypes.number,
        SegmentName: PropTypes.string,
        Dwt: PropTypes.number,
        TotalCubicCapacity: PropTypes.number,
        Draft: PropTypes.number,
        CurrentAis: PropTypes.shape({
            Longitude: PropTypes.number.isRequired,
            Latitude: PropTypes.number.isRequired,
            IsLaden: PropTypes.bool.isRequired,
            LocationTime: PropTypes.string,
            LocationOffsetMinutes: PropTypes.number,
            TimeZoneId: PropTypes.string,
            SpeedOverGround: PropTypes.number,
            Heading: PropTypes.number
        }),
        SpeedOverGround: PropTypes.number
    }),
    Ports: PropTypes.arrayOf(PropTypes.shape({
        Id: PropTypes.number,
        Name: PropTypes.string.isRequired,
        PortCode: PropTypes.string,
        Type: PropTypes.number,
        Location: PropTypes.string.isRequired,
        Activities: PropTypes.arrayOf(PropTypes.shape({
            Name: PropTypes.string.isRequired,
            Code: PropTypes.string.isRequired
        })),
        Eta: PropTypes.string,
        Etd: PropTypes.string
    })),
    HistoricalRoute: PropTypes.objectOf(PropTypes.array),
    HistoricalRouteRaw: PropTypes.arrayOf(PropTypes.array),
    FutureRoute: PropTypes.arrayOf(PropTypes.array),
    FutureRouteRaw: PropTypes.arrayOf(PropTypes.array)
});
