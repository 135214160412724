import React from 'react';
/* constants */
import { legendInfo } from './weather-performance-report-legend-constants';
/* utils */
import { getClassName } from 'utils/helpers/info-helper';
/* styles */
import './weather-performance-report-legend.scss';

class WeatherPerformanceReportLegend extends React.PureComponent {
    render() {
        return (
            <div className="sten-weather-performance-report-legend">
                {legendInfo.map(item => (
                    <div key={item.id} className="sten-weather-performance-report-legend__item">
                        <div className={getClassName('sten-weather-performance-report-legend__item--indicator', null, {
                            'sten-weather-performance-report-legend__item--indicator-optimal': item.type === 'optimal',
                            'sten-weather-performance-report-legend__item--indicator-non-optimal':
                                    item.type === 'non-optimal',
                            'sten-weather-performance-report-legend__item--indicator-no-data': item.type === 'no-data'
                        })}
                        />
                        <p className="sten-weather-performance-report-legend__item--value">
                            {item.label}
                        </p>
                    </div>
                ))}
            </div>
        );
    }
}

export default WeatherPerformanceReportLegend;
