import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* selectors */
import { getAvailableLayerOptions } from './nautical-charts-selectors';
/* actions */
import { getAvailableLayers } from './nautical-charts-actions';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import Ol from 'components/ol/ol';
/* constants */
import { nauticalChartBaseMapSourceProps, nauticalChartBaseMapLayerProps } from './nautical-charts-constants';

class NauticalCharts extends React.Component {
    componentDidMount() {
        this.props.getAvailableLayers();
    }

    render() {
        const { areLayersEnabled, selectedLayers, showBaseMap, zIndex } = this.props;
        return (
            <React.Fragment>
                {showBaseMap && (
                    <Ol.Layer
                        name="nauticalCharts.baseMap"
                        type="wmsTile"
                        zIndex={zIndex}
                        sourceProps={nauticalChartBaseMapSourceProps}
                        layerProps={nauticalChartBaseMapLayerProps}
                    />
                )}
                {ConfigService.featureToggles.showNauticalLayers
                    && !showBaseMap
                    && areLayersEnabled
                    && selectedLayers.map(layer => (
                        <Ol.Layer
                            key={layer.Name}
                            name={`nauticalCharts.${layer.Name}`}
                            type="wmsTile"
                            zIndex={zIndex + layer.order}
                            sourceProps={layer.sourceProps}
                            opacity={layer.opacity}
                        />
                    ))
                }
            </React.Fragment>
        );
    }
}

NauticalCharts.propTypes = {
    areLayersEnabled: PropTypes.bool,
    getAvailableLayers: PropTypes.func.isRequired,
    selectedLayers: PropTypes.arrayOf(PropTypes.any).isRequired,
    showBaseMap: PropTypes.bool,
    zIndex: PropTypes.number.isRequired
};

NauticalCharts.defaultProps = {
    areLayersEnabled: false,
    showBaseMap: false
};

function mapStateToProps(state) {
    return {
        areLayersEnabled: state.userReducer.settings?.MapOptionsNauticalLayersEnabled,
        selectedLayers: getAvailableLayerOptions(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAvailableLayers: () => getAvailableLayers(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NauticalCharts);
