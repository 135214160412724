import { takeEvery, put, select } from 'redux-saga/effects';
/* services */
import VesselService from 'services/core-api/vessel-service';
import CargoService from 'services/core-api/cargo-service';
/* actions */
import { ActionTypes } from './employed-vessels-panel-actions';

let allCargoesFetched = false;
let isFetchingData = false;

function* getActiveCargoes() {
    const cargoes = yield CargoService.getCargoes({
        Active: true,
        Offset: 0,
        Limit: 1000
    });
    if (cargoes) {
        yield put({
            type: ActionTypes.EMPLOYED_VESSELS_PANEL_SET_ACTIVE_CARGOES,
            cargoes: cargoes.Cargoes,
            allCargoesFetched: cargoes.Offset + cargoes.PageItemCount >= cargoes.TotalCount
        });
    }
}

function* getHistoricalCargoes(action) {
    const limit = yield select(state => state.employedVesselsPanelReducer.cargoLimit);
    let offset = yield select(state => state.employedVesselsPanelReducer.cargoOffset);
    if (action.overrideCurrent) {
        allCargoesFetched = false;
        offset = 0;
    } else {
        offset += limit;
    }
    if (!allCargoesFetched && !isFetchingData) {
        isFetchingData = true;
        const cargoes = yield CargoService.getCargoes({
            Offset: offset,
            Limit: limit,
            Active: false
        });
        if (cargoes) {
            yield put({
                type: ActionTypes.EMPLOYED_VESSELS_PANEL_SET_HISTORICAL_CARGOES,
                cargoes: cargoes.Cargoes,
                overrideCurrent: action.overrideCurrent,
                offset
            });
            allCargoesFetched = cargoes.Offset + cargoes.PageItemCount >= cargoes.TotalCount;
        }
        isFetchingData = false;
    }
}

function* getEmployedVessels() {
    const employedVessels = yield VesselService.getTCVessels();
    if (employedVessels) {
        yield put({
            type: ActionTypes.EMPLOYED_VESSELS_PANEL_SET_EMPLOYED_VESSELS,
            employedVessels
        });
    }
}

export default function* employedVesselsPanelSaga() {
    yield takeEvery(ActionTypes.EMPLOYED_VESSELS_PANEL_GET_ACTIVE_CARGOES, getActiveCargoes);
    yield takeEvery(ActionTypes.EMPLOYED_VESSELS_PANEL_GET_HISTORICAL_CARGOES, getHistoricalCargoes);
    yield takeEvery(ActionTypes.EMPLOYED_VESSELS_PANEL_GET_EMPLOYED_VESSELS, getEmployedVessels);
}
