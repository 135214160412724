export const ActionTypes = {
    CHARTERPARTY_FORM_GET_DATA: 'CHARTERPARTY_FORM_GET_DATA',
    CHARTERPARTY_FORM_SET_DATA: 'CHARTERPARTY_FORM_SET_DATA',
    CHARTERPARTY_FORM_SET_OPTIONS: 'CHARTERPARTY_FORM_SET_OPTIONS',
    CHARTERPARTY_FORM_VOYAGE_UPDATE: 'CHARTERPARTY_FORM_VOYAGE_UPDATE',
    CHARTERPARTY_FORM_VOYAGE_UPDATED: 'CHARTERPARTY_FORM_VOYAGE_UPDATED'
};

export function getData(dispatch, voyageId) {
    dispatch({
        type: ActionTypes.CHARTERPARTY_FORM_GET_DATA,
        voyageId
    });
}

export function updateData(dispatch, voyageId, data, closeOnSubmit) {
    dispatch({
        type: ActionTypes.CHARTERPARTY_FORM_VOYAGE_UPDATE,
        voyageId,
        data,
        closeOnSubmit
    });
}

export function resetData(dispatch) {
    dispatch({ type: ActionTypes.CHARTERPARTY_FORM_SET_DATA });
}
