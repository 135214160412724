import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { formatNumber, getSeverity } from 'utils/helpers/info-helper';
/* components */
import InfoCard from '../info-card/info-card';
/* styles */
import './footprint-panel.scss';

const t = (key) => translate(`ENERGY_MANAGEMENT.KPI.INFO_GRAPH_SECTION.${key}`);
const tc = (key) => translate(`COMMON.${key}`);

export default class EnergyManagementKPIFootprintPanel extends React.PureComponent {
    getSeverityClass = (value) => {
        if (!value) {
            return '';
        }
        return getSeverity({ 'text-danger': '[0,Infinity)' }, value);
    };

    getFormattedValue = (value, unit, decimals = 1, showSign = false) => {
        return typeof value === 'number' ? `${formatNumber(value, decimals, showSign)}${unit || ''}` : '-';
    };

    render() {
        const { aer, energyEfficiency, rangeEndDate, rangeOption } = this.props;
        return (
            <div className="sten-panel sten-energy-management-kpi-consumption-cargo-panel">
                <div className="sten-panel__header">
                    <h4 className="text-uppercase">{t('ENERGY_EFFICIENCY')}</h4>
                </div>
                <div className="flex-row--sm">
                    <div className="col-sm-12 col-xs-24">
                        <InfoCard
                            title={t('EEOI')}
                            infoTooltip={tc('EEOI_INFO_TOOLTIP')}
                            value={this.getFormattedValue(energyEfficiency.total, '%', 1, true)}
                            valueClassName={this.getSeverityClass(energyEfficiency.total)}
                            footerValue1={this.getFormattedValue(energyEfficiency.year1, ' gCO₂/tnm', 2)}
                            footerValue2={this.getFormattedValue(energyEfficiency.year2, ' gCO₂/tnm', 2)}
                            iconClassName="icon-leaf sten-energy-management-kpi-info-graph-section__icon"
                            rangeEndDate={rangeEndDate}
                            rangeOption={rangeOption}
                        />
                    </div>
                    <div className="col-sm-12 col-xs-24 sten-panel__separator">
                        <InfoCard
                            title={t('AER')}
                            infoTooltip={tc('AER_INFO_TOOLTIP')}
                            value={this.getFormattedValue(aer.total, '%', 1, true)}
                            valueClassName={this.getSeverityClass(aer.total)}
                            footerValue1={this.getFormattedValue(aer.year1, ' gCO₂/tnm', 2)}
                            footerValue2={this.getFormattedValue(aer.year2, ' gCO₂/tnm', 2)}
                            iconClassName="icon-aer sten-energy-management-kpi-info-graph-section__icon"
                            rangeEndDate={rangeEndDate}
                            rangeOption={rangeOption}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const InfoCardValue = PropTypes.shape({
    total: PropTypes.number,
    year1: PropTypes.number,
    year2: PropTypes.number
});

EnergyManagementKPIFootprintPanel.propTypes = {
    aer: InfoCardValue.isRequired,
    energyEfficiency: InfoCardValue.isRequired,
    rangeEndDate: PropTypes.objectOf(PropTypes.any).isRequired,
    rangeOption: PropTypes.objectOf(PropTypes.any).isRequired
};
