import React from 'react';
import moment from 'moment';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { formatWktPosition, formatNumber } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';

const t = (key) => translate(`PORT_MANAGEMENT.TABLE.${key}`);
const tr = (key) => translate(`RESTRICTIONS.${key}`);
const tg = (key) => translate(`GLOBAL.${key}`);

const formatNumValue = (decimals) => (val) => formatNumber(val, decimals);

const formatYesNoValue = (val) => {
    if (typeof val === 'boolean') {
        return val ? tg('YES') : tg('NO');
    }
    return '';
};

export const fixedColumn = {
    id: 0,
    width: 28,
    style: { width: '28rem' },
    title: t('NAME'),
    prop: 'Name',
    sortable: true
};

export const tableColumns = [
    {
        id: 1,
        width: 24,
        title: t('LATEST_COMMENT'),
        prop: 'LatestComment.DateModified',
        render: (item, type, component) => {
            const btnClass = `btn-link btn-link--secondary icon icon-message${item.LatestComment ? '' : '-add'}`;
            return (
                <div className="flex-row flex-center">
                    <div className="flex-grow">
                        {item.LatestComment && (
                            <React.Fragment>
                                <h6 className="text-secondary flex-row">
                                    <div className="flex-grow">{item.LatestComment.Author}</div>
                                    <div className="flex-shrink text-nowrap">
                                        {TimeHelper.getFormatted(
                                            item.LatestComment.DateModified, { time: true }
                                        )}
                                    </div>
                                </h6>
                                <p className="text-ellipsis">{item.LatestComment.Text}</p>
                            </React.Fragment>
                        )}
                    </div>
                    {(item.LatestComment || component.props.permissions.CreatePortComment) && (
                        <div className="flex-shrink">
                            <button className={btnClass} onClick={component.getTypeInfo[type](item, 4)} />
                        </div>
                    )}
                </div>
            );
        }
    },
    {
        id: 2,
        width: 13,
        title: t('POSITION'),
        prop: 'Position',
        sortValue: value => (value ? formatWktPosition(value, 3) : ''),
        sortable: true,
        formatValue: value => (value ? formatWktPosition(value) : '')
    },
    {
        id: 3,
        width: 16,
        title: t('LOCAL_TIME'),
        prop: 'TimeZoneOffset',
        sortable: true,
        render: (item) => {
            if (typeof item.TimeZoneOffset === 'number') {
                const offsetString = TimeHelper.getUTCOffsetString(item.TimeZoneOffset);
                const localTime = moment().utcOffset(item.TimeZoneOffset);
                return `${TimeHelper.getFormatted(localTime, { time: true })} ${offsetString}`;
            }
            return null;
        }
    },
    {
        id: 4,
        width: 9,
        title: tr('MAX_DRAFT'),
        prop: 'Restrictions.MaxDraft',
        sortable: true,
        formatValue: formatNumValue(1)
    },
    {
        id: 5,
        width: 9,
        title: tr('MAX_LOA'),
        prop: 'Restrictions.MaxLOA',
        sortable: true,
        formatValue: formatNumValue(2)
    },
    {
        id: 6,
        width: 9,
        title: tr('MAX_BEAM'),
        prop: 'Restrictions.MaxBeam',
        sortable: true,
        formatValue: formatNumValue(2)
    },
    {
        id: 7,
        width: 12,
        title: tr('MAX_DISPLACEMENT'),
        prop: 'Restrictions.MaxDisplacement',
        sortable: true,
        formatValue: formatNumValue(2)
    },
    {
        id: 8,
        width: 9,
        title: tr('MAX_DWT'),
        prop: 'Restrictions.MaxDWT',
        sortable: true,
        formatValue: formatNumValue(0)
    },
    {
        id: 9,
        width: 10,
        title: tr('WATER_DENSITY'),
        prop: 'Restrictions.WaterDensity',
        sortable: true,
        formatValue: formatNumValue(0)
    },
    { id: 10, width: 8, title: tr('UKC'), prop: 'Restrictions.UKC', sortable: true },
    { id: 11, width: 8, title: tr('SIDE'), prop: 'Restrictions.Side', sortable: true },
    { id: 12, width: 8, title: tr('MANIFOLD'), prop: 'Restrictions.Manifold', sortable: true },
    {
        id: 13,
        width: 12,
        title: tr('DIST_TO_SHORE_TANKS'),
        prop: 'Restrictions.DistanceToShoreTanks',
        sortable: true
    },
    { id: 14, width: 12, title: tr('AVG_LOADING_RATE'), prop: 'Restrictions.AvgLoadingRate', sortable: true },
    {
        id: 15,
        width: 11,
        title: t('ISPS_COMPLIANT'),
        prop: 'IspsCompliance',
        sortable: true,
        formatValue: formatYesNoValue
    },
    {
        id: 16,
        width: 11,
        title: t('LIQUID_FACILITIES'),
        prop: 'LiquidFacilities',
        sortable: true,
        formatValue: formatYesNoValue
    },
    {
        id: 17,
        width: 14,
        title: t('LAST_UPDATED'),
        prop: 'UpdatedAt',
        sortable: true,
        render: (item) => (
            <React.Fragment>
                {item.UpdatedBy && <p>{item.UpdatedBy}</p>}
                <span className="text-secondary">
                    {`${tg('AT')} ${TimeHelper.getFormatted(item.UpdatedAt, { time: true })}`}
                </span>
            </React.Fragment>
        )
    },
    { id: 18, width: 12, title: t('PREFERRED_AGENT'), prop: 'Vendors.PreferredAgent', sortable: true },
    { id: 19, width: 12, title: t('TOWING_CONTRACT'), prop: 'Vendors.TowingContract', sortable: true },
    {
        id: 20,
        width: 8,
        title: t('AWRP'),
        prop: 'Services.AWRP.Value',
        sortable: true,
        render: item => (item.Services && item.Services.AWRP && item.Services.AWRP.Value
            ? `${formatNumber(item.Services.AWRP.Value, 0)} ${item.Services.AWRP.Comment || ''}`
            : '')
    },
    {
        id: 21,
        width: 10,
        title: t('HULL_CLEANING'),
        prop: 'Services.HullCleaning.Value',
        sortable: true,
        render: item => (item.Services && item.Services.HullCleaning && item.Services.HullCleaning.Value
            ? `${formatNumber(item.Services.HullCleaning.Value, 0)} ${item.Services.HullCleaning.Comment || ''}`
            : '')
    },
    {
        id: 22,
        width: 8,
        title: t('SLOPS'),
        prop: 'Services.Slops.Value',
        sortable: true,
        render: item => (item.Services && item.Services.Slops && item.Services.Slops.Value
            ? `${formatNumber(item.Services.Slops.Value, 0)} ${item.Services.Slops.Comment || ''}`
            : '')
    },
    {
        id: 23,
        width: 12,
        title: t('BUNKERING'),
        prop: 'Bunkering.Info',
        render: (item) => {
            if (!item.Bunkering) {
                return null;
            }
            return (
                <React.Fragment>
                    {item.Bunkering.Info && (
                        <h6>{item.Bunkering.Info}</h6>
                    )}
                    {item.Bunkering.Types.length > 0 && (
                        <ul className="sten-ul">
                            {item.Bunkering.Types.map(type => (
                                <li key={type.Id}>{type.BunkeringType}</li>
                            ))}
                        </ul>
                    )}
                </React.Fragment>
            );
        }
    }
];
