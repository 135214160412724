import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
/* components */
import Accordion from 'components/accordion/accordion';
/* constants */
import { rowConfigs } from './itinerary-constants';
/* helpers */
import getPanelRows from 'utils/helpers/template-helper';
import { getClassName, getObjectProp } from 'utils/helpers/info-helper';
import { isActivityCurrent, isActivityHistoric } from 'components/itinerary/helpers';
/* style */
import './itinerary.scss';

export class Itinerary extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            collapsedPoints: []
        };
        this.columnClasses = ['col-12', 'col-12'];
    }

    static getDerivedStateFromProps(props, state) {
        if (props.itinerary && props.itinerary !== state.itinerary) {
            return {
                collapsedPoints: Itinerary.getInitiallyCollapsedPoints(props)
            };
        }

        return null;
    }

    static getInitiallyCollapsedPoints = (props) => {
        const collapsedPoints = Array(props.itinerary.length).fill(true);

        // Searching for current or first future itinerary point
        const foundFutureOrCurrent = props.itinerary.some((point, index) => {
            return point.Activities.some((activity) => {
                if (!isActivityHistoric(activity)) {
                    collapsedPoints[index] = false;
                    return true;
                }
                return false;
            });
        });

        // Not current or future itinerary point
        // So we open last itinerary point
        if (!foundFutureOrCurrent) {
            collapsedPoints[props.itinerary.length - 1] = false;
        }

        return collapsedPoints;
    };


    render() {
        return this.props.itinerary.map((itineraryPoint, pointIndex) => {
            const { Point, Activities } = itineraryPoint;
            const { collapsedPoints } = this.state;

            // Note: Check if this is correct way of getting is point Active or Historic
            const isPointHistoric = Activities.every(isActivityHistoric);

            const pointClass = getClassName('sten-itinerary__point sten-panel', {
                'sten-itinerary__point--historical': isPointHistoric
            });
            const iconClass = getClassName('icon sten-itinerary__icon', {
                'icon-itinerary-point': Point && Point.Type === 1,
                'icon-port': !Point || Point.Type !== 1
            });
            const header = (
                <Fragment>
                    <span key="icon" className={iconClass} />
                    <h4 className="sten-itinerary__point-title">{Point ? Point.Name : '-'}</h4>
                    {Activities.filter(activity => activity.Code).map(activity => (
                        <div key={activity.Id} className="sten-itinerary__badge">
                            <span
                                className={getClassName('sten-badge', {
                                    'sten-badge--success': isActivityCurrent(activity)
                                })}
                                title={activity.Activity}
                            >
                                {activity.Code}
                            </span>
                        </div>
                    ))}
                </Fragment>
            );

            return (
                <Accordion
                    // eslint-disable-next-line react/no-array-index-key
                    key={pointIndex}
                    chevronRight
                    className={pointClass}
                    header={header}
                    isCollapsed={collapsedPoints[pointIndex]}
                >
                    {
                        Activities.map((activity) => {
                            const rows = rowConfigs.map(config => {
                                let valueCell;
                                if (config.render) {
                                    valueCell = config.render(activity);
                                } else {
                                    const value = getObjectProp(activity, config.prop);
                                    valueCell = (
                                        <label className="sten-panel__label sten-panel__label--value">
                                            {value ? `${value}${config.unit ? config.unit : ''}` : '-'}
                                        </label>
                                    );
                                }
                                return [
                                    <label className="sten-panel__label">{config.label}</label>,
                                    valueCell
                                ];
                            });
                            return (
                                <div key={activity.Id} className="sten-panel__section sten-panel__separator">
                                    {getPanelRows(this.columnClasses, rows)}
                                </div>
                            );
                        })
                    }
                </Accordion>
            );
        });
    }
}

Itinerary.propTypes = {
    itinerary: PropTypes.arrayOf(PropTypes.any)
};

Itinerary.defaultProps = {
    itinerary: null
};

export default Itinerary;
