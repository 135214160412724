import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { renderFormFields } from 'components/form-fields/form-fields-helper';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
/* components */
import CommentList from 'components/comments/comment-list';
import ScrollArea from 'components/scroll-area/scroll-area';
/* constants */
import { terminalFields, terminalFieldNames } from '../terminal-and-berth-constants';

const tc = (key) => translate(`COMMENTS.${key}`);

export class TerminalInfoOverview extends React.PureComponent {
    renderContent = (item, fields, fieldNames) => renderFormFields(item, fields, fieldNames, false, undefined, true);

    renderUpdatedInfo = (item) => (
        <div className="sten-content__section sten-content__separator">
            <p className="text-secondary">
                {translate('GLOBAL.UPDATED_BY_USER', {
                    date: TimeHelper.getFormatted(item.UpdatedAt, { time: true }),
                    user: item.UpdatedBy
                })}
            </p>
        </div>
    );

    render() {
        return (
            <ScrollArea>
                <div className="sten-content__section">
                    {this.renderContent(this.props.terminal, terminalFields, terminalFieldNames.BasicInfo)}
                </div>
                <div className="sten-content__section sten-content__separator">
                    {this.renderContent(this.props.terminal, terminalFields, terminalFieldNames.Restrictions)}
                </div>
                {this.renderUpdatedInfo(this.props.terminal)}
                <div className="sten-content__section-header text-uppercase">
                    <h4>{tc('RECENT_COMMENTS')}</h4>
                </div>
                {this.props.terminal && this.props.comments.length > 0
                    ? (
                        <CommentList
                            canUpdateComments={this.props.permissions.UpdatePortComment}
                            canUpdateAllComments={this.props.permissions.UpdateAllPortComments}
                            comments={this.props.comments.slice(0, 3)}
                            resource="terminal"
                            resourceId={this.props.terminal.TerminalId}
                        />
                    ) : (
                        <div className="sten-content__section text-secondary"><p>{tc('NO_RECENT_COMMENTS')}</p></div>
                    )}
                {(this.props.comments.length > 3 || this.props.permissions.CreatePortComment) && (
                    <div className="sten-content__section sten-content__separator text-center">
                        <button className="sten-link" onClick={this.props.onShowMoreClick}>
                            {this.props.comments.length > 3 || !this.props.permissions.CreatePortComment
                                ? tc('VIEW_ALL')
                                : tc('ADD_NEW_COMMENT')}
                        </button>
                    </div>
                )}
            </ScrollArea>
        );
    }
}

TerminalInfoOverview.propTypes = {
    comments: PropTypes.arrayOf(PropTypes.object).isRequired,
    onShowMoreClick: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    terminal: PropTypes.objectOf(PropTypes.any).isRequired
};

export default TerminalInfoOverview;
