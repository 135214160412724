import React from 'react';
/* helpers */
import { getClassName } from './info-helper';

export const LABEL_CELL = 'label';
export const VALUE_CELL = 'value';
export const CUSTOM_CELL = 'custom';

// eslint-disable-next-line react/prop-types
const renderInfoCell = ({ value, className, type }) => {
    if (type === CUSTOM_CELL) {
        return value;
    }
    const cn = getClassName('sten-panel__label', className, {
        'sten-panel__label--value': type === VALUE_CELL
    });
    return (
        <label className={cn}>{value}</label>
    );
};

export const renderInfoRows = (rowConfigs, columnClasses, rowClasses) =>
    rowConfigs.map((rowConfig, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={`flex-row flex-center sten-panel__row ${rowClasses ? rowClasses[index] : ''}`} key={index}>
            {rowConfig.map((cellConfig, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className={columnClasses[index]} key={index}>
                    {renderInfoCell(cellConfig)}
                </div>
            ))}
        </div>
    ));
