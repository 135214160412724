/* utils */
import { t } from 'utils/i18n/i18n-model';

export const eventReportFieldsAndSections = [
    {
        fields: [
            'Vessel',
            'CallSign',
            'VoyageNumber',
            'CargoStatus',
            'VesselStatus'
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.SINCE_LAST_EVENT'),
        sections: [
            {
                fields: [
                    'TimeElapsedSinceLastEvent',
                    'TimeAtAnchorSinceLastEvent',
                    'HoursSteamedSinceLastEvent',
                    'DistanceSinceLastEvent',
                    'WindForceSinceLastEvent',
                    'TotalCargoSinceLastEvent'
                ]
            }
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.PORT_INFORMATION'),
        sections: [
            {
                fields: [
                    'PreviousPort',
                    'NextPort'
                ]
            }
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.EVENT'),
        sections: [
            {
                fields: [
                    'ReportDate',
                    'EventType',
                    'SpecialArea',
                    'Position',
                    'InstructedSpeed',
                    'TotalDistanceSteamedInArea',
                    'TotalTimeSpentInArea'
                ]
            }
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.REMARKS'),
        sections: [
            {
                inline: false,
                fields: ['Remarks']
            }
        ]
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.BUNKER_DATA'),
        condition: (p) => ((p.isEditing && p.editingOptions.bunkerTypes.length)
            || (p.data.BunkerData && p.data.BunkerData.rows.length)),
        data: 'BunkerData'
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.CONSUMPTIONS_FROM_DAILY_TO_EVENT'),
        condition: (p) => ((p.isEditing && p.editingOptions.consumptionTypes)
            || (p.data.ConsumptionFromNoonToEvent && p.data.ConsumptionFromNoonToEvent.rows.length)),
        data: 'ConsumptionFromNoonToEvent'
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.CONSUMPTIONS_DURING_STOPPAGE'),
        condition: (p) => ((p.isEditing && p.editingOptions.consumptionTypes)
            || (p.data.ConsumptionDuringStoppage && p.data.ConsumptionDuringStoppage.rows.length)),
        data: 'ConsumptionDuringStoppage'
    }
];
