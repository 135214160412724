import React from 'react';
import PropTypes from 'prop-types';
/* style */
import './select-value.scss';

class SelectValue extends React.PureComponent {
    render() {
        let className = 'sten-select__value';
        let value = this.props.label ? `${this.props.label} ` : '';
        let showRenderedValue = false;
        if (this.props.multiple && this.props.value && this.props.value.length) {
            value += this.props.valueRenderer
                ? this.props.valueRenderer(this.props.value)
                : `${this.props.value.length} selected`;
        } else if (!this.props.multiple && this.props.value) {
            if (!this.props.optionRenderer) {
                value += this.props.valueRenderer
                    ? this.props.valueRenderer(this.props.value)
                    : this.props.value[this.props.labelKey];
            } else {
                showRenderedValue = true;
            }
        } else {
            className += ' sten-select__value--placeholder';
            value = this.props.placeholder;
        }
        return (
            <div
                className={className}
                onClick={this.props.onClick}
                onFocus={this.props.onFocus}
                onBlur={this.props.onBlur}
            >
                {value}
                {showRenderedValue && this.props.optionRenderer(this.props.value)}
            </div>
        );
    }
}

SelectValue.propTypes = {
    label: PropTypes.string,
    labelKey: PropTypes.string.isRequired,
    multiple: PropTypes.bool,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    optionRenderer: PropTypes.func,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
    valueRenderer: PropTypes.func
};

SelectValue.defaultProps = {
    label: '',
    multiple: false,
    onBlur: undefined,
    onClick: undefined,
    onFocus: undefined,
    optionRenderer: undefined,
    placeholder: '',
    value: null,
    valueRenderer: undefined
};

export default SelectValue;
