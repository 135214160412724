import { takeEvery, put } from 'redux-saga/effects';
import VesselService from 'services/core-api/vessel-service';
import { ActionTypes } from './vessel-list-actions';

function* getVessels(action) {
    const vesselsResponse = yield VesselService.getList(action.params);
    if (vesselsResponse) {
        const vessels = vesselsResponse.PageItems;
        const currentPage = (vesselsResponse.Offset / vesselsResponse.Limit) + 1;
        const allFetched = currentPage >= vesselsResponse.TotalPagesCount;
        yield put({
            type: ActionTypes.VESSEL_LIST_ADD_VESSELS,
            vessels,
            overrideCurrent: action.overrideCurrent,
            allFetched
        });
    }
}

export default function* vesselListSaga() {
    yield takeEvery(ActionTypes.VESSEL_LIST_GET_VESSELS, getVessels);
}
