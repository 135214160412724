import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
import Input from 'components/input/input';
import Modal from 'components/modal/modal';
import PortSelect from 'components/port-select/port-select';
import Textarea from 'components/textarea/textarea';
import Validation from 'components/validation/validation';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import {
    setPort,
    setDate,
    setCargoInformation,
    sendInquiryData,
    closeInquiryPanel
} from './cargo-inquiry-actions';
import { navMenuExpand } from '../left-side-bar-actions';
/* styles */
import './cargo-inquiry.scss';

class CargoInquiry extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            dateTimePortType: null
        };
    }

    componentWillUnmount() {
        this.props.closeInquiryPanel();
    }

    onPortDateSelect = (newDate, e) => {
        e.stopPropagation();
        const portType = this.state.dateTimePortType;
        this.props.setDate(portType, newDate);
        this.setState({
            dateTimePortType: null
        });
    };

    getDatePickerModal = () => {
        const now = moment().startOf('day');
        if (this.state.dateTimePortType === 'loading') {
            return (
                <Modal.DatetimePicker
                    hideTimePicker
                    isOpen={this.state.dateTimePortType === 'loading'}
                    minDate={now.valueOf()}
                    onCancel={this.toggleDatePickerComponent}
                    onSelect={this.onPortDateSelect}
                    timestamp={this.props.loadingDate ? moment(this.props.loadingDate).valueOf() : null}
                />
            );
        }
        if (this.state.dateTimePortType === 'discharging') {
            return (
                <Modal.DatetimePicker
                    hideTimePicker
                    isOpen={this.state.dateTimePortType === 'discharging'}
                    minDate={this.props.loadingDate ? moment(this.props.loadingDate).valueOf() : now.valueOf()}
                    onCancel={this.toggleDatePickerComponent}
                    onSelect={this.onPortDateSelect}
                    timestamp={this.props.dischargingDate ? moment(this.props.dischargingDate).valueOf() : null}
                />
            );
        }
        return null;
    };

    toggleDatePickerComponent = (e, portType) => {
        e.stopPropagation();
        if (portType) {
            this.setState({
                dateTimePortType: portType
            });
        } else {
            this.setState({
                dateTimePortType: null
            });
        }
    };

    setActiveDatePicker = type => (e) => this.toggleDatePickerComponent(e, type);

    render() {
        const loadingDate = this.props.loadingDate
            ? TimeHelper.getFormatted(this.props.loadingDate, { time: true, day: true })
            : '';
        const dischargingDate = this.props.dischargingDate
            ? TimeHelper.getFormatted(this.props.dischargingDate, { time: true, day: true })
            : '';
        return (
            <div className="sten-left-sidebar-panel sten-cargo-inquiry" key="cargo-inquiry-content">
                {this.getDatePickerModal()}
                <div className="sten-content__header text-uppercase">
                    <h1>{translate('CARGO_INQUIRY.CARGO_INQUIRY')}</h1>
                </div>
                <div className="sten-content__body sten-inquiry__body">
                    <ScrollArea>
                        {this.props.messageSent
                            ? (
                                <React.Fragment>
                                    <div className="sten-content__section text-center">
                                        <h2 className="text-success">
                                            {translate('CONTACT_INQUIRY.SUCCESSFULLY_SENT_TITLE')}
                                        </h2>
                                    </div>
                                    <div className="sten-content__section text-center">
                                        <p>{translate('CONTACT_INQUIRY.SUCCESSFULLY_SENT_TEXT')}</p>
                                    </div>
                                    <div className="sten-content__section sten-content__separator">
                                        <div className="row">
                                            <div className="col-12 col-offset-12">
                                                <button
                                                    className="btn btn--primary col-24"
                                                    onClick={this.props.navMenuClose}
                                                >
                                                    {translate('CARGO_INQUIRY.CLOSE_INQUIRY_FORM')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                            : (
                                <Validation.Form onSubmit={this.props.sendInquiryData}>
                                    <div className="sten-content__section">
                                        <p>{translate('CARGO_INQUIRY.WELCOME_TEXT')}</p>
                                        <p>{translate('CARGO_INQUIRY.ADDITIONAL_WELCOME_TEXT')}</p>
                                    </div>
                                    <div className="sten-content__section sten-content__separator">
                                        <label className="label">
                                            {translate('CARGO_INQUIRY.CARGO_INFORMATION')}
                                        </label>
                                        <Validation.Wrapper validations={{ required: true, maxLength: 5000 }}>
                                            <Textarea
                                                name="information"
                                                value={this.props.cargoInformation}
                                                onChange={this.props.setCargoInformation}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                    <div className="sten-content__section">
                                        <label className="label">
                                            {translate('CARGO_INQUIRY.LOADING_PORT')}
                                        </label>
                                        <div className="row">
                                            <div className="col-12">
                                                <PortSelect
                                                    className="sten-cargo-inquiry__port-select"
                                                    clearable
                                                    includeSearchInResults
                                                    value={this.props.loadingPort}
                                                    onChange={this.props.setPort.bind(this, 'loading')}
                                                    placeholder={translate('CARGO_INQUIRY.SELECT_PORT')}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <Input
                                                    readOnly
                                                    suffixIcon="icon-calendar"
                                                    value={loadingDate}
                                                    placeholder={translate('CARGO_INQUIRY.LOADING_DATE')}
                                                    onClick={this.setActiveDatePicker('loading')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sten-content__section">
                                        <label className="label">
                                            {translate('CARGO_INQUIRY.DISCHARGING_PORT')}
                                        </label>
                                        <div className="row">
                                            <div className="col-12">
                                                <PortSelect
                                                    className="sten-cargo-inquiry__port-select"
                                                    clearable
                                                    includeSearchInResults
                                                    value={this.props.dischargingPort}
                                                    onChange={this.props.setPort.bind(this, 'discharging')}
                                                    placeholder={translate('CARGO_INQUIRY.SELECT_PORT')}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <Input
                                                    readOnly
                                                    suffixIcon="icon-calendar"
                                                    value={dischargingDate}
                                                    placeholder={translate('CARGO_INQUIRY.DISCHARGING_DATE')}
                                                    onClick={this.setActiveDatePicker('discharging')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sten-content__section sten-content__separator">
                                        <div className="row">
                                            <div className="col-12 col-offset-12">
                                                <Validation.Button className="btn btn--primary col-24">
                                                    {translate('CARGO_INQUIRY.SEND_INQUIRY')}
                                                </Validation.Button>
                                            </div>
                                        </div>
                                    </div>
                                </Validation.Form>
                            )
                        }
                    </ScrollArea>
                </div>
            </div>
        );
    }
}

CargoInquiry.propTypes = {
    cargoInformation: PropTypes.string.isRequired,
    closeInquiryPanel: PropTypes.func.isRequired,
    dischargingDate: PropTypes.string,
    dischargingPort: PropTypes.objectOf(PropTypes.any),
    loadingDate: PropTypes.string,
    loadingPort: PropTypes.objectOf(PropTypes.any),
    messageSent: PropTypes.bool.isRequired,
    navMenuClose: PropTypes.func.isRequired,
    sendInquiryData: PropTypes.func.isRequired,
    setCargoInformation: PropTypes.func.isRequired,
    setDate: PropTypes.func.isRequired,
    setPort: PropTypes.func.isRequired
};

CargoInquiry.defaultProps = {
    dischargingDate: null,
    dischargingPort: null,
    loadingDate: null,
    loadingPort: null
};

function mapStateToProps(state) {
    return {
        cargoInformation: state.cargoInquiryReducer.cargoInformation,
        dischargingDate: state.cargoInquiryReducer.dischargingDate,
        dischargingPort: state.cargoInquiryReducer.dischargingPort,
        loadingDate: state.cargoInquiryReducer.loadingDate,
        loadingPort: state.cargoInquiryReducer.loadingPort,
        messageSent: state.cargoInquiryReducer.messageSent
    };
}

function mapDispatchToProps(dispatch) {
    return {
        closeInquiryPanel: () => closeInquiryPanel(dispatch),
        navMenuClose: () => navMenuExpand(dispatch, false),
        sendInquiryData: () => sendInquiryData(dispatch),
        setCargoInformation: cargoInformation => setCargoInformation(dispatch, cargoInformation),
        setDate: (portType, date) => setDate(dispatch, portType, date),
        setPort: (portType, port) => setPort(dispatch, portType, port)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CargoInquiry);
