/* eslint-disable */

const getSignatureImage = (signature, contentId) => `
    <table cellpadding="0" cellspacing="0" border="0" style="background:none;border-width:0px;border:0px;margin:0;padding:0;">
        <tr>
            <td colspan="2" style="padding-bottom:5px;">
                <img src="${contentId ? `cid:${contentId}` : `data:image/png;base64,${signature.image}`}" alt="${signature.title}" style="max-height: 80px;" />
            </td>
        </tr>
    </table>
`;

const stenaBulkCrudeAndFuel  = (signature, contentId) => `
    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
        <tbody class="mcnTextBlockOuter">
        <tr>
            <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                <!--[if mso]>
                <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                    <tr>
                        <td valign="top" width="598" style="width:598px;">
                <![endif]-->
                <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%;min-width:100%;" width="100%" class="mcnTextContentContainer">
                    <tbody>
                    <tr>
                        <td valign="top" class="mcnTextContent" style="padding-top:0;padding-right:8px;padding-bottom:9px;padding-left:8px;">
                            ${signature.image ? getSignatureImage(signature, contentId) : ''}
                            <table style="margin-top:10px;font-size:12px;">
                                <tr style="vertical-align:top;">
                                    <td style="border-right:1px solid #ececec;padding-right:5px;padding-left:5px;">
                                        <div><strong>Houston</strong></div>
                                        <div>2727 Allen ParkwaySuite 760</div>
                                        <div>Houston TX 77019, USA</div>
                                        <div>Tel:<a href="tel:+1207132087420592060">+1 713 874 59 60</a></div>
                                        <div><a style="color:#004c93;" href="mailto:charteringclean@stenabulk.com">charteringclean@stenabulk.com</a></div>
                                        <table style="margin-top:10px;font-size:12px;">
                                            <tr>
                                                <td>Michael J Bradbury</td>
                                                <td><a href="tel:+12083220398205300">+1 832 398 5300</a></td>
                                            </tr>
                                            <tr>
                                                <td>Fredrik Eriksson</td>
                                                <td><a href="tel:+12083220228203526">+1 832 228 3526</a></td>
                                            </tr>
                                            <tr>
                                                <td>Manuel Fdez. de Mosteyrin</td>
                                                <td><a href="tel:+12083220301201971">+1 832 301 1971</a></td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td style="border-right:1px solid #ececec;padding-right:5px;padding-left:5px;">
                                        <div><strong>Copenhagen</strong></div>
                                        <div>Tuborg Boulevard 12, 2nd Floor</div>
                                        <div>2900 Hellerup</div>
                                        <div>Tel:<a href="tel:+4588938600">+45 88 93 86 00</a></div>
                                        <div><a style="color:#004c93;" href="mailto:charteringclean@stenabulk.com">charteringclean@stenabulk.com</a></div>
                                        <table style="margin-top:10px;font-size:12px;">
                                            <tr>
                                                <td>Aayush Giri</td>
                                                <td><a href="tel:+4560215869">+45 60 21 58 69</a></td>
                                            </tr>
                                            <tr>
                                                <td>Frederik Binne Larsen</td>
                                                <td><a href="tel:+4550590405">+45 5059 0405</a></td>
                                            </tr>
                                            <tr>
                                                <td>Frederick Drenck</td>
                                                <td><a href="tel:+4588938612">+45 88 93 86 12</a></td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td style="border-right:1px solid #ececec;padding-right:5px;padding-left:5px;">
                                        <div><strong>Dubai</strong></div>
                                        <div>Almas Tower, Floor 22</div>
                                        <div>Jumeirah Lake Towers UAE Dubai</div>
                                        <div>Tel:<a href="tel:+97144431901">+971 444 31 901</a></div>
                                        <div><a style="color:#004c93;" href="mailto:charteringclean@stenabulk.com">charteringclean@stenabulk.com</a></div>
                                        <table style="margin-top:10px;font-size:12px;">
                                            <tr>
                                                <td>Emil Calizaya</td>
                                                <td><a href="tel:+971557592024">+971 55 759 2024</a></td>
                                            </tr>
                                            <tr>
                                                <td>Abdullah Alkhayyat</td>
                                                <td><a href="tel:+971521965588">+971 52 196 5588</a></td>
                                            </tr>
                                            <tr>
                                                <td>William Stranne</td>
                                                <td><a href="tel:+97145687947">+971 45 68 79 47</a></td>
                                            </tr>
                                            <tr>
                                                <td>Ida Fredriksson</td>
                                                <td><a href="tel:+97145687946">+971 45 68 79 46</a></td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td style="padding-right:5px;padding-left:5px;">
                                        <div><strong>Singapore</strong></div>
                                        <div>#44-01 Suntec Tower 46 Temasek</div>
                                        <div>Boulevard Singapore 038 986</div>
                                        <div>Tel:<a href="tel:+65206336205953">+65 63 36 59 53</a></div>
                                        <div><a style="color:#004c93;" href="mailto:charteringclean@stenabulk.com">charteringclean@stenabulk.com</a></div>
                                        <table style="margin-top:10px;font-size:12px;">
                                            <tr>
                                                <td>Ellen Biörk</td>
                                                <td><a href="tel:+46704855088">+46 704 85 50 88</a></td>
                                            </tr>
                                            <tr>
                                                <td>Jermaine Tjua</td>
                                                <td><a href="tel:+6592342652">+65 9234 2652</a></td>
                                            </tr>
                                            <tr>
                                                <td>Johan Zander</td>
                                                <td><a href="tel:+6563361367">+65 63 36 13 67</a></td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <!--[if mso]>
                </td>
                <![endif]-->
                <!--[if mso]>
                </tr>
                </table>
                <![endif]-->
            </td>
        </tr>
        </tbody>
    </table>
`;

const stenaBulkProductsAndChemicals = (signature, contentId) => `
<table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
    <tbody class="mcnTextBlockOuter">
    <tr>
        <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
            <!--[if mso]>
            <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                <tr>
                    <td valign="top" width="598" style="width:598px;">
            <![endif]-->
            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%;min-width:100%;" width="100%" class="mcnTextContentContainer">
                <tbody>
                <tr>
                    <td valign="top" class="mcnTextContent" style="padding-top:0;padding-right:8px;padding-bottom:9px;padding-left:8px;">
                        ${signature.image ? getSignatureImage(signature, contentId) : ''}
                        <table style="margin-top:10px;font-size:12px;">
                            <tr style="vertical-align:top;">
                                <td style="padding-right:5px;padding-left:5px;">
                                    <div><strong>Houston</strong></div>
                                    <div>2727 Allen Parkway, Suite 760</div>
                                    <div>Houston TX 77019, USA</div>
                                    <div>Tel:<a href="tel:+17138745960">+1 713 874 5960</a></div>
                                    <div><a style="color:#004c93;" href="mailto:operationsclean@stenabulk.com">operationsclean@stenabulk.com</a></div>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                </tbody>
            </table>
            <!--[if mso]>
            </td>
            <![endif]-->
            <!--[if mso]>
            </tr>
            </table>
            <![endif]-->
        </td>
    </tr>
    </tbody>
</table>
`;

const stenaSonangol = (signature, contentId) => `
    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
        <tbody class="mcnTextBlockOuter">
        <tr>
            <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                <!--[if mso]>
                <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                  <tr>
                    <![endif]-->
                <!--[if mso]>
                <td valign="top" width="598" style="width:598px;">
                  <![endif]-->
                <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%;min-width:100%;" width="100%" class="mcnTextContentContainer">
                    <tbody>
                    <tr>
                        <td valign="top" class="mcnTextContent" style="padding-top:0;padding-right:8px;padding-bottom:9px;padding-left:8px;">
                            ${signature.image ? getSignatureImage(signature, contentId) : ''}
                            <table style="margin-top:10px;font-size:12px;">
                                <tr style="vertical-align:top;">
                                    <td style="border-right:1px solid #ececec;padding-right:5px;padding-left:5px;">
                                        <div><strong>Houston</strong></div>
                                        <div>2727 Allen ParkwaySuite 760</div>
                                        <div>Houston TX 77019, USA</div>
                                        <div><a href="tel:+1207132087420592060">Tel: +1 713 874 59 60</a></div>
                                        <div><a style="color:#004c93;" href="mailto:charteringUS@stenabulk.com%20">charteringUS@stenabulk.com </a></div>
                                        <table style="margin-top:10px;font-size:12px;">
                                            <tr>
                                                <td>Michael J Bradbury</td>
                                                <td><a href="tel:+12071320874205961">+1 713 874 59 61</a></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><a href="tel:+18323985300">+1 832 398 53 00</a></td>
                                            </tr>
                                            <tr>
                                                <td>JR Gardner</td>
                                                <td><a href="tel:+17138745971">+1 713 874 5971</a></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><a href="tel:+18322441069">+1 832 244 1069</a></td>
                                            </tr>
                                            <tr>
                                                <td>Jose Ngunza</td>
                                                <td><a href="tel:+17138745971">+1 713 874 5972</a></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><a href="tel:+18322441069">+1 713 213 9924</a></td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td style="border-right:1px solid #ececec;padding-right:5px;padding-left:5px;">
                                        <div><strong>Gothenburg</strong></div>
                                        <div>Masthuggskajen</div>
                                        <div>SE-405 19 Gothenburg, Sweden</div>
                                        <div><a href="tel:+4631855000">Tel: +46 31 85 50 00</a></div>
                                        <div><a style="color:#004c93;" href="mailto:charteringSE@stenabulk.com">charteringSE@stenabulk.com</a></div>
                                        <table style="margin-top:10px;font-size:12px;">
                                            <tr>
                                                <td>Oscar Eriksson</td>
                                                <td><a href="tel:+4631855008">+46 31 85 50 08</a></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><a href="tel:+46704855008">+46 704 85 50 08</a></td>
                                            </tr>
                                            <tr>
                                                <td>Gustav Rauston</td>
                                                <td><a href="tel:+4631855028">+46 31 85 50 28</a></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td><a href="tel:+46704855028">+46 704 85 50 28</a></td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td style="padding-right:5px;padding-left:5px;">
                                        <div><strong>Singapore</strong></div>
                                        <div>#44-01 Suntec Tower 46 Temasek</div>
                                        <div>Boulevard Singapore 038 986</div>
                                        <div></div>
                                        <div></div>
                                        <table style="margin-top:18px;font-size:12px;">
                                            <tr>
                                                <td>PLEASE CONTACT GOTHENBURG</td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <!--[if mso]>
                </td>
                <![endif]-->
                <!--[if mso]>
                </tr>
                </table>
                <![endif]-->
            </td>
        </tr>
        </tbody>
    </table>
`;

const gsbTankers = (signature, contentId) => `
    <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width:100%;">
        <tbody class="mcnTextBlockOuter">
        <tr>
            <td valign="top" class="mcnTextBlockInner" style="padding-top:9px;">
                <!--[if mso]>
                <table align="left" border="0" cellspacing="0" cellpadding="0" width="100%" style="width:100%;">
                  <tr>
                    <![endif]-->
                <!--[if mso]>
                <td valign="top" width="598" style="width:598px;">
                  <![endif]-->
                <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width:100%;min-width:100%;" width="100%" class="mcnTextContentContainer">
                    <tbody>
                    <tr>
                        <td valign="top" class="mcnTextContent" style="padding-top:0;padding-right:8px;padding-bottom:9px;padding-left:8px;">
                            ${signature.image ? getSignatureImage(signature, contentId) : ''}
                            <table style="margin-top:10px;font-size:12px;">
                                <tr style="vertical-align:top;">
                                    <td style="padding-right:5px;padding-left:5px;">
                                        <div><strong>Singapore</strong></div>
                                        <div>#04-15 Golden Agri Plaza, 108 Pasir Panjang Road</div>
                                        <div>Singapore 118535</div>
                                        <div>Tel:<a href="tel:+65206634206223">+65 66 34 62 23</a></div>
                                        <div><a style="color:#004c93;" href="mailto:chartering@goldenstenaweco.com">chartering@goldenstenaweco.com</a></div>
                                        <div><a style="color:#004c93;" href="mailto:chartering.gsw@gmail.com">chartering.gsw@gmail.com</a></div>
                                    </td>
                                </tr>
                            </table>
                            <table style="margin-top:10px;font-size:12px;">
                                <tr>
                                    <td style="padding-right:15px;border-right:1px solid #ececec;">Jacob Enersen</td>
                                    <td style="padding-right:15px;padding-left:15px;border-right:1px solid #ececec;">William Stranne</td>
                                    <td style="padding-right:15px;padding-left:15px;border-right:1px solid #ececec;">Darrell Chia</td>
                                    <td style="padding-right:15px;padding-left:15px;border-right:1px solid #ececec;">Kuan Yau Lai</td>
                                    <td style="padding-right:15px;padding-left:15px;border-right:1px solid #ececec;">Esther Lee</td>
                                    <td style="padding-right:15px;padding-left:15px;">Aayush Giri</td>
                                </tr>
                                <tr>
                                    <td style="padding-right:15px;border-right:1px solid #ececec;"><a href="tel:+65206634206227">+65 66 34 62 27</a></td>
                                    <td style="padding-right:15px;padding-left:15px;border-right:1px solid #ececec;"><a href="tel:+9712052201030820">+971 52 10 30 820</a></td>
                                    <td style="padding-right:15px;padding-left:15px;border-right:1px solid #ececec;"><a href="tel:+65206634206228">+65 66 34 62 28</a></td>
                                    <td style="padding-right:15px;padding-left:15px;border-right:1px solid #ececec;"><a href="tel:+65206634206250">+65 66 34 62 50</a></td>
                                    <td style="padding-right:15px;padding-left:15px;border-right:1px solid #ececec;"><a href="tel:+65206634206226">+65 66 34 62 26</a></td>
                                    <td style="padding-right:15px;padding-left:15px;"><a href="tel:+9712052201030810">+971 52 10 30 810</a></td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <!--[if mso]>
                </td>
                <![endif]-->
                <!--[if mso]>
                </tr>
                </table>
                <![endif]-->
            </td>
        </tr>
        </tbody>
    </table>
`;

export function getSignature(signature, contentId) {
    const signatureMap = {
        1: stenaBulkCrudeAndFuel (signature, contentId),
        2: stenaBulkProductsAndChemicals (signature, contentId),
        4: gsbTankers(signature, contentId),
        7: stenaSonangol(signature, contentId)
    };
    if (signature && signature.id) {
        return signatureMap[signature.id];
    }
    return '<p style="margin: 0;padding-top: 10px;padding-right: 8px;padding-bottom: 9px;padding-left: 8px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #0f3646;font-size: 14px;line-height: 100%;text-align: left;">Please select a signature</p>';
}
/* eslint-enable */

export default getSignature;
