import moment from 'moment';

export function extractChartData(data, keys, options) {
    const chartData = {
        series: {},
        categories: []
    };
    let category;
    const format = options && options.xAxisYear ? 'YYYY' : 'MMM YYYY';
    if (data && data.length > 0) {
        const seriesKeys = keys || Object.keys(data[0]).filter(key => key !== 'Date');
        data.forEach((point) => {
            category = moment(point.Date).format(format);
            chartData.categories.push(category);
            seriesKeys.forEach((key) => {
                if (!chartData.series[key]) {
                    chartData.series[key] = [];
                }
                chartData.series[key].push([category, point[key]]);
            });
        });
    }
    return chartData;
}
