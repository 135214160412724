export const keyToFormatMap = {
    VesselName: { type: 'string' },
    Imo: { type: 'string' },
    CountryName: { type: 'string' },
    Activity: { type: 'string' },
    Eta: { type: 'string' },
    Etd: { type: 'string' },
    PortName: { type: 'string' },
    DraftBefore: { type: 'number', numFmt: '0.0' },
    DraftAfter: { type: 'number', numFmt: '0.0' },
    TimeInPort: { type: 'number', numFmt: '0.0' },
    Probability: { type: 'number', numFmt: '0.0' }
};
