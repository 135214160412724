export const ActionTypes = {
    POSITION_LIST_FILTERS_SET_SELECTED_PORT: 'POSITION_LIST_FILTERS_SET_SELECTED_PORT',
    POSITION_LIST_FILTERS_GET_COMPANIES: 'POSITION_LIST_FILTERS_GET_COMPANIES',
    POSITION_LIST_FILTERS_SET_COMPANIES: 'POSITION_LIST_FILTERS_SET_COMPANIES',
    POSITION_LIST_FILTERS_GET_REGIONS: 'POSITION_LIST_FILTERS_GET_REGIONS',
    POSITION_LIST_FILTERS_SET_REGIONS: 'POSITION_LIST_FILTERS_SET_REGIONS',
    POSITION_LIST_FILTERS_GET_FLEETS: 'POSITION_LIST_FILTERS_GET_FLEETS',
    POSITION_LIST_FILTERS_SET_FLEETS: 'POSITION_LIST_FILTERS_SET_FLEETS',
    POSITION_LIST_FILTERS_GET_GROUPINGS: 'POSITION_LIST_FILTERS_GET_GROUPINGS',
    POSITION_LIST_FILTERS_SET_GROUPINGS: 'POSITION_LIST_FILTERS_SET_GROUPINGS',
    POSITION_LIST_FILTERS_UPDATED: 'POSITION_LIST_FILTERS_UPDATED',
    POSITION_LIST_FILTERS_UPDATE_LOADED: 'POSITION_LIST_FILTERS_UPDATE_LOADED',
    POSITION_LIST_FILTERS_UPDATE: 'POSITION_LIST_FILTERS_UPDATE'
};


export function getCompanies(dispatch) {
    dispatch({
        type: ActionTypes.POSITION_LIST_FILTERS_GET_COMPANIES
    });
}

export function getRegions(dispatch) {
    dispatch({
        type: ActionTypes.POSITION_LIST_FILTERS_GET_REGIONS
    });
}

export function getFleets(dispatch) {
    dispatch({
        type: ActionTypes.POSITION_LIST_FILTERS_GET_FLEETS
    });
}

export function getGroupings(dispatch) {
    dispatch({
        type: ActionTypes.POSITION_LIST_FILTERS_GET_GROUPINGS
    });
}

export function setSelectedPort(dispatch, selectedPort) {
    dispatch({
        type: ActionTypes.POSITION_LIST_FILTERS_SET_SELECTED_PORT,
        selectedPort
    });
}

export function filterUpdated(dispatch) {
    dispatch({
        type: ActionTypes.POSITION_LIST_FILTERS_UPDATED
    });
}

export function filterUpdateLoaded(dispatch) {
    dispatch({
        type: ActionTypes.POSITION_LIST_FILTERS_UPDATE_LOADED
    });
}
