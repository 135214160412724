/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { formatNumber } from 'utils/helpers/info-helper';
import stenTableStyles, { border } from 'utils/helpers/pdf-styles/sten-table';
import { getLogoBase64 } from 'utils/base64images';
/* constants */
import { cargoProps, emissionProps, otherProps, colWidths } from './fleet-performance-report-type-a-constants';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.FLEET_PERFORMANCE_REPORT.${key}`);

const customColors = {
    segmentHeader: '#9ED2FA',
    fleetHeader: '#D5ECFD',
    contractHeader: '#F1F9FE'
};

/* eslint-disable max-len */
const styles = `
html {
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
}
html, body {
    margin: 0;
    padding: 0;
}
table {
    border-right: ${border};
    border-left: ${border};
}
.summary-table.sten-table--no-border-bottom {
    border-top: ${border};
    border-bottom: ${border};
}
.vessel-name-text {
    font-size: 2.5rem;
    font-weight: bold;
    font-kerning: normal;
    letter-spacing: -0.03em;
}
.header-title {
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
    text-transform: uppercase;
}
.header-style {
    display: flex;
    justify-content: space-between;
    height: 5rem;
    text-align: center;
    padding: 1rem 2rem;
}
.logo-style {
    min-width: 16rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
}
.page {
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    page-break-inside: avoid;
    page-break-after: always;
}
.text-center {
    text-align: center !important;
}
${stenTableStyles}
.fleet-performance-table tbody {
    color: #333333;
}
.fleet-performance-table thead {
    background-color: ${customColors.fleetHeader};
}
.fleet-performance-table th {
    font-size: 0.875rem;
    font-weight: bold;
    height: 1rem;
    text-transform: initial;
}
.fleet-performance-table thead th,
.fleet-performance-table tbody td,
.fleet-performance-table tbody th {
    padding: 0.5rem 1rem;
    text-align: right;
}
.fleet-performance-table thead tr:first-child th:first-child,
.fleet-performance-table tbody td:first-child {
    text-align: left;
}
.fleet-performance-table__segment {
    background-color: ${customColors.segmentHeader};
}
.fleet-performance-table__segment tr td:first-child {
    font-weight: bold;
    color: #000000;
}
.fleet-performance-table .fleet-performance-table__summary {
    font-weight: bold;
    color: #000000;
}
.fleet-performance-table__summary tr td:first-child {
    padding-left: 2rem;
}
.fleet-performance-table__summary tr:first-child td:first-child {
    padding-left: 1rem;
}
.fleet-performance-table__summary.fleet-performance-table__summary--segment tr td:first-child {
    padding-left: 3rem;
}
.fleet-performance-table__summary.fleet-performance-table__summary--segment tr:first-child td:first-child {
    padding-left: 2rem;
}
.fleet-performance-table__summary.fleet-performance-table__summary--fleet tr td:first-child,
.fleet-performance-table__summary.fleet-performance-table__vessels--other tr td:first-child {
    padding-left: 4rem;
}
.fleet-performance-table__summary.fleet-performance-table__summary--fleet tr:first-child td:first-child,
.fleet-performance-table__summary.fleet-performance-table__vessels--other tr:first-child td:first-child {
    padding-left: 3rem;
}
.fleet-performance-table__summary.fleet-performance-table__vessels--fleet tr td:first-child {
    padding-left: 5rem;
}
.fleet-performance-table__summary.fleet-performance-table__vessels--fleet tr:first-child td:first-child {
    padding-left: 4rem;
}
.fleet-performance-table__fleet {
    background-color: ${customColors.fleetHeader};
}
.fleet-performance-table__fleet td {
    padding: 0.5rem 1rem;
}
.fleet-performance-table__fleet td:first-child {
    padding-left: 2rem;
    font-weight: bold;
    color: #000000;
}
.fleet-performance-table__vessels--fleet td:first-child {
    padding-left: 4rem;
}
.fleet-performance-table__vessels--other td:first-child {
    padding-left: 3rem;
}
.fleet-performance-table__vessels {
    background-color: ${customColors.contractHeader};
}
.fleet-performance-table__vessels td {
    padding: 0.5rem 1rem;
}
.fleet-performance-table__vessels.fleet-performance-table__vessels--fleet td:first-child {
    padding-left: 3rem;
    font-weight: bold;
    color: #000000;
}
.fleet-performance-table__vessels.fleet-performance-table__vessels--other {
    background-color: ${customColors.fleetHeader};
}
.fleet-performance-table__vessels.fleet-performance-table__vessels--other td:first-child {
    padding-left: 2rem;
    font-weight: bold;
}
`;

function breakRowsJS() {
    const mmToPxRatio = document.documentElement.offsetWidth / 277;
    const pageHeight = Math.round(190 * mmToPxRatio);
    const tableHeightLimit = pageHeight * (297 / 277);
    const bodyClone = document.body.cloneNode(false);
    const tBodies = document.getElementsByTagName('tbody');
    const pageClone = document.body.getElementsByClassName('page')[0].cloneNode(false);
    const tableClone = document.body.getElementsByTagName('table')[0].cloneNode(false);
    const tableHeadClone = document.body.getElementsByTagName('thead')[0].cloneNode(true);
    const tableHeadHeight = document.body.getElementsByTagName('thead')[0].getBoundingClientRect().height;
    tableClone.appendChild(tableHeadClone);
    const headerClone = document.getElementsByTagName('header')[0].cloneNode(true);
    const headerHeight = document.getElementsByTagName('header')[0].getBoundingClientRect().height;
    let currentTbodyHeight;
    let currentRowHeight;
    let heightSoFar = headerHeight + tableHeadHeight;
    let newTable = tableClone.cloneNode(true);
    let newPage;
    let rows;
    let dataGroup;
    let dataGroupHeaderIndex = null;
    let dataGroupBodyIndex = null;
    let dataGroupBody;
    let dataGroupHeaderHeight = 0;
    let dataGroupHeight = 0;
    let isHeaderAdded = false;
    const summaryHeight = tBodies[0].getBoundingClientRect().height;
    const createNewPage = () => {
        newPage = pageClone.cloneNode(false);
        if (!isHeaderAdded) {
            newPage.appendChild(headerClone);
            isHeaderAdded = true;
        }
        newPage.appendChild(newTable);
        bodyClone.appendChild(newPage);
        newTable = tableClone.cloneNode(true);
        heightSoFar = tableHeadHeight;
    };
    for (let i = 0; i < tBodies.length; i++) {
        dataGroup = tBodies[i].getAttribute('data-group');
        currentTbodyHeight = tBodies[i].getBoundingClientRect().height;
        if (dataGroup) {
            if (dataGroup.indexOf('header') > -1) {
                dataGroupHeight = currentTbodyHeight;
                dataGroupHeaderHeight = currentTbodyHeight;
                dataGroupHeaderIndex = i;
            } else if (dataGroup.indexOf('body') > -1) {
                if (heightSoFar + dataGroupHeight + currentTbodyHeight + summaryHeight > tableHeightLimit
                    && currentTbodyHeight > dataGroupHeaderHeight) {
                    if (heightSoFar + dataGroupHeight + currentTbodyHeight > tableHeightLimit) {
                        rows = tBodies[i].getElementsByTagName('tr');
                        dataGroupBody = tBodies[i].cloneNode(false);
                        for (let k = 0; k < rows.length; k++) {
                            currentRowHeight = rows[k].getBoundingClientRect().height;
                            if (heightSoFar + dataGroupHeight + currentRowHeight > tableHeightLimit) {
                                if (k === 0) {
                                    createNewPage();
                                } else {
                                    if (dataGroupHeaderIndex !== null) {
                                        newTable.appendChild(tBodies[dataGroupHeaderIndex].cloneNode(true));
                                    }
                                    newTable.appendChild(dataGroupBody);
                                    createNewPage();
                                    dataGroupBody = tBodies[i].cloneNode(false);
                                    dataGroupHeight = dataGroupHeaderHeight;
                                }
                            }
                            dataGroupHeight += currentRowHeight;
                            dataGroupBody.appendChild(rows[k].cloneNode(true));
                        }
                    } else {
                        if (dataGroupHeaderIndex !== null) {
                            newTable.appendChild(tBodies[dataGroupHeaderIndex].cloneNode(true));
                        }
                        newTable.appendChild(tBodies[i].cloneNode(true));
                        createNewPage();
                        dataGroupHeight = dataGroupHeaderHeight;
                    }
                } else {
                    dataGroupHeight += currentTbodyHeight;
                    dataGroupBodyIndex = i;
                }
            } else if (dataGroup.indexOf('summary') > -1) {
                dataGroupHeight += currentTbodyHeight;
                if (heightSoFar + dataGroupHeight > tableHeightLimit) {
                    createNewPage();
                }
                heightSoFar += dataGroupHeight;
                if (dataGroupHeaderIndex !== null) {
                    newTable.appendChild(tBodies[dataGroupHeaderIndex].cloneNode(true));
                }
                if (dataGroupBodyIndex !== null) {
                    newTable.appendChild(tBodies[dataGroupBodyIndex].cloneNode(true));
                }
                if (dataGroupBody) {
                    newTable.appendChild(dataGroupBody);
                }
                newTable.appendChild(tBodies[i].cloneNode(true));
                dataGroupBodyIndex = null;
                dataGroupHeaderIndex = null;
                dataGroupBody = null;
                dataGroupHeight = 0;
            }
        } else {
            heightSoFar += currentTbodyHeight;
            if (heightSoFar > tableHeightLimit) {
                createNewPage();
                heightSoFar += currentTbodyHeight;
            }
            newTable.appendChild(tBodies[i].cloneNode(true));
        }
    }
    newPage = pageClone.cloneNode(false);
    if (!isHeaderAdded) {
        newPage.appendChild(headerClone);
        isHeaderAdded = true;
    }
    newPage.appendChild(newTable);
    bodyClone.appendChild(newPage);
    document.body.innerHTML = bodyClone.innerHTML;
}

const formatValue = (value, decimals) => (value !== null ? formatNumber(value, decimals) : '-');

export default class FleetPerformanceReportTypeAPDF extends React.PureComponent {
    renderDataProps = (data, props, firstColSpan = 1, isAvg = false) => (
        props.map((prop, index) => (
            <td key={prop.key} colSpan={index === 0 ? firstColSpan : 1}>
                {data[prop.key] !== null
                    ? `${formatNumber(data[prop.key], isAvg ? prop.avgDecimals : prop.decimals)}${prop.unit || ''}`
                    : '-'}
            </td>
        ))
    );

    renderSummary = (
        key,
        label,
        summary,
        fuelBeforeColSpan,
        fuelAfterColSpan,
        emissionColSpan,
        className = '',
        dataGroup = ''
    ) => {
        const summaryClassName = `fleet-performance-table__summary ${className}`;
        return (
            <tbody key={key} className={summaryClassName} data-group={dataGroup}>
                <tr>
                    <td>{label}</td>
                    {this.renderDataProps(summary.Total, cargoProps)}
                    <td>
                        {formatValue(summary.Total.TotalConsumptionAtSea, otherProps.TotalConsumptionAtSea.decimals)}
                    </td>
                    <td>
                        {formatValue(summary.Total.TotalConsumptionInPort, otherProps.TotalConsumptionInPort.decimals)}
                    </td>
                    {this.renderDataProps(summary.Total.Emissions, emissionProps)}
                </tr>
                <tr>
                    <td>{t('FUEL_PER_DISTANCE')}</td>
                    <td colSpan={fuelBeforeColSpan} />
                    <td>{formatValue(summary.FuelPerDistance, otherProps.FuelPerDistance.decimals)}</td>
                    <td colSpan={fuelAfterColSpan} />
                </tr>
                <tr>
                    <td>{t('FUEL_PER_CARGO')}</td>
                    <td colSpan={fuelBeforeColSpan} />
                    <td>{formatValue(summary.FuelPerCargo, otherProps.FuelPerCargo.decimals)}</td>
                    <td colSpan={fuelAfterColSpan} />
                </tr>
                <tr>
                    <td>{t('EMISSIONS_PER_CARGO')}</td>
                    <td colSpan={emissionColSpan} />
                    {this.renderDataProps(summary.EmissionsPerCargo, emissionProps, 1, true)}
                </tr>
            </tbody>
        );
    };

    renderVesselList = (
        key,
        label,
        vesselList,
        headerColSpan,
        fuelBeforeColSpan,
        fuelAfterColSpan,
        emissionColSpan,
        className = ''
    ) => (
        <React.Fragment key={key}>
            <tbody className={`fleet-performance-table__vessels ${className}`} data-group="vessels.header">
                <tr>
                    <td>{label}</td>
                    <td colSpan={headerColSpan} />
                </tr>
            </tbody>
            <tbody className={className} data-group="vessels.body">
                {vesselList.Vessels.map(vessel => (
                    <tr key={`vessel.${vessel.Name}`}>
                        <td>{vessel.Name}</td>
                        {this.renderDataProps(vessel, cargoProps)}
                        <td>{formatValue(vessel.TotalConsumptionAtSea, otherProps.TotalConsumptionAtSea.decimals)}</td>
                        <td>
                            {formatValue(vessel.TotalConsumptionInPort, otherProps.TotalConsumptionInPort.decimals)}
                        </td>
                        {this.renderDataProps(vessel.Emissions, emissionProps)}
                    </tr>
                ))}
            </tbody>
            {this.renderSummary(
                key,
                `${t('TOTAL_FOR')} ${label}`,
                vesselList.Summary,
                fuelBeforeColSpan,
                fuelAfterColSpan,
                emissionColSpan,
                className,
                'vessels.summary'
            )}
        </React.Fragment>
    );

    renderFleet = (
        segment,
        fleet,
        headerColSpan,
        fuelBeforeColSpan,
        fuelAfterColSpan,
        emissionColSpan
    ) => (
        <React.Fragment key={`segment.${segment.Name}.fleet.${fleet.Name}`}>
            <tbody className="fleet-performance-table__fleet" data-group="fleet.header">
                <tr>
                    <td>{fleet.Name}</td>
                    <td colSpan={headerColSpan} />
                </tr>
            </tbody>
            {this.renderSummary(
                `segment.${segment.Name}.fleet.${fleet.Name}.total`,
                t('TOTAL_FOR_COMPANY_FLEET'),
                fleet.Summary,
                fuelBeforeColSpan,
                fuelAfterColSpan,
                emissionColSpan,
                'fleet-performance-table__summary--fleet',
                'fleet.summary'
            )}
            {fleet.Contracts.map(contract => (
                this.renderVesselList(
                    `segment.${segment.Name}.fleet.${fleet.Name}.contract.${contract.Name}.total`,
                    contract.Name,
                    contract,
                    headerColSpan,
                    fuelBeforeColSpan,
                    fuelAfterColSpan,
                    emissionColSpan,
                    'fleet-performance-table__vessels--fleet'
                )
            ))}
        </React.Fragment>
    );

    renderTableAccordionHeader = (key, label, colSpan) => {
        return (
            <tbody key={key} className="fleet-performance-table__segment" data-group="segment.header">
                <tr>
                    <td>{label}</td>
                    <td colSpan={colSpan} />
                </tr>
            </tbody>
        );
    };

    renderSegment = (
        segment,
        headerColSpan,
        fuelBeforeColSpan,
        fuelAfterColSpan,
        emissionColSpan
    ) => (
        <React.Fragment key={`segment.${segment.Name}`}>
            {this.renderTableAccordionHeader(`segment.${segment.Name}`, segment.Name, headerColSpan)}
            {this.renderSummary(
                `segment.${segment.Name}.total`,
                t('TOTAL_FOR_SEGMENT'),
                segment.Summary,
                fuelBeforeColSpan,
                fuelAfterColSpan,
                emissionColSpan,
                'fleet-performance-table__summary--segment',
                'segment.summary'
            )}
            {segment.Fleets.map(fleet => this.renderFleet(
                segment,
                fleet,
                headerColSpan,
                fuelBeforeColSpan,
                fuelAfterColSpan,
                emissionColSpan
            ))}
            {segment.OtherVessels && this.renderVesselList(
                `segment.${segment.Name}.OtherVessels`,
                t('OTHER_VESSELS'),
                segment.OtherVessels,
                headerColSpan,
                fuelBeforeColSpan,
                fuelAfterColSpan,
                emissionColSpan,
                'fleet-performance-table__vessels--other'
            )}
        </React.Fragment>
    );

    render() {
        const { parsedData } = this.props.tableData;
        const { rangeStart, rangeEnd } = this.props;
        const cargoCols = cargoProps.length;
        const emissionCols = emissionProps.length;
        const fuelBeforeColSpan = cargoCols;
        const fuelAfterColSpan = parseInt(emissionCols + 1, 10);
        const emissionColSpan = parseInt(cargoCols + 2, 10);
        const headerColSpan = parseInt(cargoCols + 2 + emissionCols, 10);

        return (
            <html lang="en">
                <head>
                    <style>{styles}</style>
                </head>
                <body>
                    <div className="page">
                        <header className="header-style">
                            <div className="logo-style" />
                            <div>
                                <div className="header-title">{t('TITLE')}</div>
                                <div className="voyage-number-text">
                                    {`${t('DATE_RANGE')} ${`${rangeStart} - ${rangeEnd}`}`}
                                </div>
                            </div>
                            <div
                                className="logo-style"
                                style={{
                                    backgroundImage: `url(${getLogoBase64().dark})`,
                                    backgroundPositionX: 'right',
                                    width: '16rem',
                                    height: '3rem'
                                }}
                            />
                        </header>
                        <table className="sten-table fleet-performance-table">
                            <thead>
                                <tr>
                                    <th rowSpan="2" style={{ minWidth: `${colWidths.Value.pdf}rem` }}>{t('VALUE')}</th>
                                    <th colSpan="5" className="text-center">{t('AT_SEA')}</th>
                                    <th colSpan="2" className="text-center">{t('IN_PORT')}</th>
                                    <th className="text-center">{t('CONSUMPTION_AT_SEA')}</th>
                                    <th className="text-center">{t('CONSUMPTION_IN_PORT')}</th>
                                    <th colSpan="4" className="text-center">{t('EMISSIONS')}</th>
                                </tr>
                                <tr>
                                    <th style={{ minWidth: `${colWidths.TotalCargoCarried.pdf}rem` }}>
                                        {t('TOTAL_CARGO_CARRIED')}
                                    </th>
                                    <th style={{ minWidth: `${colWidths.DistanceLaden.pdf}rem` }}>{t('DISTANCE_LADEN')}</th>
                                    <th style={{ minWidth: `${colWidths.DistanceLadenPercentage.pdf}rem` }}>
                                        {t('DISTANCE_LADEN_PERCENTAGE')}
                                    </th>
                                    <th style={{ minWidth: `${colWidths.DistanceBallast.pdf}rem` }}>
                                        {t('DISTANCE_BALLAST')}
                                    </th>
                                    <th style={{ minWidth: `${colWidths.DistanceBallastPercentage.pdf}rem` }}>
                                        {t('DISTANCE_BALLAST_PERCENTAGE')}
                                    </th>
                                    <th style={{ minWidth: `${colWidths.DaysInPort.pdf}rem` }}>{t('DAYS_IN_PORT')}</th>
                                    <th style={{ minWidth: `${colWidths.DaysInPortPercentage.pdf}rem` }}>
                                        {t('DAYS_IN_PORT_PERCENTAGE')}
                                    </th>
                                    <th style={{ minWidth: `${colWidths.TotalConsumptionAtSea.pdf}rem` }}>{t('TOTAL')}</th>
                                    <th style={{ minWidth: `${colWidths.TotalConsumptionInPort.pdf}rem` }}>{t('TOTAL')}</th>
                                    {emissionProps.map(emissionProp => (
                                        <th
                                            key={`${emissionProp.key}.cons`}
                                            style={{ minWidth: `${colWidths[emissionProp.key].pdf}rem` }}
                                        >
                                            {`${emissionProp.label} (${translate('UNITS.EMISSIONS')})`}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            {this.renderSummary(
                                'grandTotal',
                                t('GRAND_TOTAL'),
                                parsedData.Summary,
                                fuelBeforeColSpan,
                                fuelAfterColSpan,
                                emissionColSpan
                            )}
                            {parsedData.Segments.map(segment => this.renderSegment(
                                segment,
                                headerColSpan,
                                fuelBeforeColSpan,
                                fuelAfterColSpan,
                                emissionColSpan
                            ))}
                        </table>
                    </div>
                    <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{ __html: `(${breakRowsJS.toString()})();` }}
                    />
                </body>
            </html>
        );
    }
}

FleetPerformanceReportTypeAPDF.propTypes = {
    rangeEnd: PropTypes.string,
    rangeStart: PropTypes.string,
    tableData: PropTypes.objectOf(PropTypes.any)
};

FleetPerformanceReportTypeAPDF.defaultProps = {
    rangeEnd: '',
    rangeStart: '',
    tableData: null
};
