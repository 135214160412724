import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* components */
import FlipSwitch from 'components/flip-switch/flip-switch';
import FleetMultiselector from 'components/fleet-multiselector/fleet-multiselector';
/* helpers */
import { fleetOptionRenderer } from 'utils/helpers/fleet-helper';
/* styles */
import './notification-settings-panel.scss';

class NotificationSettingsPanel extends React.PureComponent {
    handleChange = (key, value) => {
        this.props.onChange({ ...this.props.rule, [key]: value });
    };

    handleEnabledToggle = (value) => this.handleChange('Enabled', value);

    handleCompanyFleetsChange = (
        (companyFleets) => this.handleChange('CompanyFleetIds', companyFleets.map(fleet => fleet.Id)));

    handleCustomFleetsChange = (
        (customFleets) =>
            this.handleChange('CustomFleetIds', customFleets.map(customFleet => customFleet.VesselGroupId)));


    handlePushNotificationsToggle = this.handleChange.bind(this, 'PushNotificationsEnabled');

    getCompanyFleetsValueLabel = (value) => {
        return t('NOTIFICATION_SETTINGS.COMPANY_FLEETS.SELECTED_FLEETS',
            { fleetsNumber: value.length }, true);
    };

    getCustomFleetsValueLabel = (value) => {
        return t('NOTIFICATION_SETTINGS.CUSTOM_FLEETS.SELECTED_FLEETS',
            { fleetsNumber: value.length }, true);
    };

    render() {
        const { rule } = this.props;
        return (
            <div className="sten-notification-settings-panel">
                <div className="sten-panel">
                    <div className="sten-panel__header">
                        <div className="flex flex-grow flex-row flex-center">
                            <div className="flex-grow">
                                <h4 className="sten-panel__title">
                                    {rule.NotificationType}
                                </h4>
                            </div>
                            <div className="flex-shrink">
                                <FlipSwitch
                                    size="xs"
                                    value={rule.Enabled}
                                    onChange={this.handleEnabledToggle}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sten-panel__body">
                        <div className="sten-panel__section sten-notification-settings-panel__description">
                            <p className="text-secondary">{t('NOTIFICATION_SETTINGS.DESCRIPTION')}</p>
                            <p>{rule.Description}</p>
                        </div>
                        <div className="sten-panel__section">
                            <FleetMultiselector
                                labelKey="VesselGroupName"
                                valueKey="VesselGroupId"
                                isDisabled={!rule.Enabled}
                                valueRenderer={this.getCustomFleetsValueLabel}
                                allFleets={this.props.customFleets}
                                selectedFleets={rule.CustomFleets}
                                optionRenderer={fleetOptionRenderer}
                                placeholder={t('NOTIFICATION_SETTINGS.CUSTOM_FLEETS.NO_FLEETS_SELECTED')}
                                onChange={this.handleCustomFleetsChange}
                            />
                        </div>
                        <div className="sten-panel__section">
                            <FleetMultiselector
                                labelKey="Name"
                                valueKey="Id"
                                isDisabled={!rule.Enabled}
                                valueRenderer={this.getCompanyFleetsValueLabel}
                                allFleets={this.props.companyFleets}
                                selectedFleets={rule.CompanyFleets}
                                placeholder={t('NOTIFICATION_SETTINGS.COMPANY_FLEETS.NO_FLEETS_SELECTED')}
                                onChange={this.handleCompanyFleetsChange}
                            />
                        </div>
                        <div className="sten-panel__section sten-panel__separator sten-panel__separator--inner">
                            <div className="flex flex-center">
                                <div className="flex-grow">
                                    <p>{t('NOTIFICATION_SETTINGS.PUSH_NOTIFICATIONS')}</p>
                                </div>
                                <div className="flex-shrink">
                                    <FlipSwitch
                                        disabled={!rule.Enabled}
                                        size="xs"
                                        value={rule.PushNotificationsEnabled}
                                        onChange={this.handlePushNotificationsToggle}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

NotificationSettingsPanel.propTypes = {
    companyFleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    customFleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired,
    rule: PropTypes.objectOf(PropTypes.any).isRequired
};

export default NotificationSettingsPanel;
