import moment from 'moment';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { hasValue } from 'utils/helpers/info-helper';

const estimationsRequired = {
    rule: (value, params, components) => Object.keys(components)
        .filter(key => key.indexOf('BunkerData.7.') === 0)
        .some(key => components[key] && components[key].state && components[key].state.value),
    hint: () => t('QUESTIONNAIRE.MESSAGES.BUNKER_ESTIMATIONS_MUST_BE_DEFINED')
};

const estimationTypeRequired = {
    rule: (value) => {
        return !!value && value.findIndex(item => item.Id === 7) > -1;
    },
    hint: () => t('QUESTIONNAIRE.MESSAGES.BUNKER_ESTIMATIONS_MUST_BE_DEFINED')
};

export const customDateValidation = {
    rule: (value) => value !== 'Invalid Date',
    hint: () => 'Date is invalid'
};

const speedRequiredHints = {
    atLeastOne: 'At least one speed required',
    pairRequired: 'Speed pair required',
    conditionPairHigher: 'FULL speeds must be higher than ECO',
    required: 'Required'
};

const speedRequiredHintMap = {};

const speedRequired = {
    rule: (value, params, components, name) => {
        speedRequiredHintMap[name] = '';
        const splitName = name.split('.');
        const index = Number(splitName[1]);
        if (hasValue(value)) {
            const conditionPairIndex = index < 2 ? index + 2 : index - 2;
            const conditionPairValue = components[`SpeedInformation.${conditionPairIndex}.Speed`]?.state.value;
            if (hasValue(conditionPairValue)
                && ((index > conditionPairIndex && value <= conditionPairValue)
                    || (index < conditionPairIndex && value >= conditionPairValue))) {
                speedRequiredHintMap[name] = 'conditionPairHigher';
                return false;
            }
            return true;
        }
        const pairIndex = index % 2 === 0 ? index + 1 : index - 1;
        const pairValue = hasValue(components[`SpeedInformation.${pairIndex}.Speed`]?.state.value);
        if (pairValue) {
            speedRequiredHintMap[name] = 'pairRequired';
            return false;
        }
        const speedInformationComponents = Object.keys(components)
            .filter(componentName => componentName.includes('SpeedInformation'));
        const required = speedInformationComponents.some(componentName =>
            componentName.includes(splitName[1]) && hasValue(components[componentName]?.state.value));
        if (required) {
            speedRequiredHintMap[name] = 'required';
            return false;
        }
        const atLeastOne = speedInformationComponents.some(componentName => (
            componentName.includes('.Speed') && hasValue(components[componentName]?.state.value)
        ));
        if (!atLeastOne) {
            speedRequiredHintMap[name] = 'atLeastOne';
            return false;
        }
        return true;
    },
    hint: (value, params, components, name) => (
        speedRequiredHintMap[name] && speedRequiredHints[speedRequiredHintMap[name]]
    )
};

const consumptionRequired = (value, components, name) => {
    const index = name.split('.')[1];
    return !!components[`SpeedInformation.${index}.Speed`]?.state.value;
};

export const commonValidations = {
    DateTime: { custom: customDateValidation },
    DateTimeRequired: {
        required: true,
        custom: customDateValidation,
        dateLimits: {
            min: moment.utc('1990-01-01T00:00:00'),
            max: moment.utc().add(2, 'd'),
            maxProp: 'ReportDate',
            utc: true
        }
    },
    DateTimeLimitedRequired: {
        required: true,
        custom: customDateValidation,
        dateLimits: {
            min: moment.utc('1990-01-01T00:00:00'),
            max: moment.utc().add(2, 'd'),
            utc: true
        }
    },
    DateOffset: { timeSpan: { min: -13 * 60, max: 13 * 60 } },
    DateOffsetRequired: { required: true, timeSpan: { min: -13 * 60, max: 13 * 60 } },
    Speed: { numeric: { min: 0, max: 30, decimals: 1 } },
    SpeedRequired: { required: true, numeric: { min: 0, max: 30, decimals: 1 } },
    Rpm: { numeric: { decimals: 0, min: 0, max: 200 } },
    RpmRequired: { required: true, numeric: { decimals: 0, min: 0, max: 200 } }
};

export const validations = {
    Vessel: { required: true },
    CallSign: { required: true },
    ReportDate: commonValidations.DateTimeLimitedRequired,
    ReportDateOffset: commonValidations.DateOffsetRequired,
    RpmMin: { required: true, numeric: { decimals: 0, min: 0, max: 200, maxProp: 'RpmMax' } },
    RpmMax: { required: true, numeric: { decimals: 0, min: 0, max: 200, minProp: 'RpmMin' } },
    CleaningEquipmentOnBoard: { required: true, maxLength: 200 },
    LastUwInspection: commonValidations.DateTimeRequired,
    LastUwInspectionOffset: commonValidations.DateOffsetRequired,
    PortOfLastUwInspection: { required: true },
    LastUwCleaning: commonValidations.DateTimeRequired,
    LastUwCleaningOffset: commonValidations.DateOffsetRequired,
    PortOfLastUwCleaning: { required: true },
    LastPropellerPolish: commonValidations.DateTimeRequired,
    LastPropellerPolishOffset: commonValidations.DateOffsetRequired,
    PortOfLastPropellerPolish: { required: true },
    ProlongedPortStays: { required: true },
    PortOfProlongedPortStay: { required: true },
    PortVisits: {
        ReportPort: { required: true },
        Cargo: { required: true },
        Charterer: { required: true }
    },
    PitchOfPropeller: { required: true, numeric: { min: 0, max: 100, decimals: 2 } },
    PropellerDiameter: { required: true, numeric: { min: 0, max: 100, decimals: 2 } },
    PropellerNumberOfBlades: { required: true, numeric: { min: 0, max: 20, decimals: 0 } },
    PropellerExpandedArea: { required: true, numeric: { min: 0, max: 100, decimals: 2 } },
    PropellerAverageSlip: { required: true, numeric: { min: -100, max: 100, decimals: 2 } },
    PropellerEnergySavingDevices: { maxLength: 200 },
    OelTorque: { required: true, numeric: { min: 0, max: 10000, decimals: 2 } },
    OelPower: { required: true, numeric: { min: 0, max: 100, decimals: 2 } },
    OelRpm: commonValidations.RpmRequired,
    SpeedAndConsumptionDetails: { required: true, maxLength: 200 },
    Remarks: { maxLength: 200 },
    BunkerData: {
        Fields: {
            7: { numeric: { min: 0, max: 5000 }, estimationsRequired }
        },
        BunkerTypes: { estimationTypeRequired },
        FuelTypes: { required: true }
    },
    SpeedDetails: {
        MinimumSpeed: commonValidations.Speed,
        RpmAtMinimumSpeed: commonValidations.Rpm
    },
    SpeedInformations: {
        Speed: { ...commonValidations.Speed, speedRequired },
        TotalConsumption: { numeric: { min: 0, max: 100, decimals: 2 }, required: consumptionRequired },
        NormalRpm: commonValidations.Rpm
    }
};
