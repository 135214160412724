import { takeEvery, put, all } from 'redux-saga/effects';
/* actions */
import { ActionTypes } from './fleet-dashboard-actions';
import { ActionTypes as USActionTypes } from '../user-actions';
/* services */
import PoolService from 'services/core-api/pool-service';
import PortService from 'services/core-api/port-service';
import NotificationService from 'services/notification-api/notification-service';
import MarketIntelService from 'services/core-api/market-intel-service';

const emptyArray = [];

function* getVoyageOptions() {
    const voyageOptions = yield PoolService.getVoyageOptions();
    yield put({ type: ActionTypes.FLEET_DASHBOARD_SET_VOYAGE_OPTIONS, voyageOptions });
}

function* getNotifications(action) {
    const notifications = yield NotificationService.getNotifications({
        Severity: 'Alert',
        Limit: 5000,
        Offset: 0,
        Dismissed: false,
        FleetId: action.fleetId
    });
    yield put({ type: ActionTypes.FLEET_DASHBOARD_SET_NOTIFICATIONS, notifications });
}

function* getVessels(action) {
    const vessels = yield PoolService.getVessels({
        PoolDashboardOption: action.voyageOptionId,
        PoolDashboardFleetId: action.fleetId
    });
    yield put({ type: ActionTypes.FLEET_DASHBOARD_SET_VESSELS, vessels });
}

function* getVesselPositions(action) {
    const now = new Date();
    const allVessels = yield PoolService.getVesselPositions({
        Latest: true,
        StartTime: now.toISOString(),
        EndTime: now.toISOString(),
        PoolDashboardFleetId: action.fleetId
    });
    let vesselPositions;
    if (allVessels && allVessels.Entries) {
        vesselPositions = allVessels.Entries.map(vessel => (
            ({
                ...vessel,
                IMO: vessel.IMO,
                Title: vessel.VesselName ? vessel.VesselName.trim() : '',

                CurrentAis: {
                    Longitude: vessel.Entries[0].Location.Longitude,
                    Latitude: vessel.Entries[0].Location.Latitude,
                    Heading: vessel.Entries[0].Heading
                },

                Draught: vessel.Entries[0].Draught,
                SpeedOverGround: vessel.Entries[0].SpeedOverGround,
                Destination: vessel.Entries[0].Destination,
                PositionTime: vessel.Entries[0].PositionTime,
                ETA: vessel.Entries[0].ETA,
                IsLaden: vessel.Entries[0].IsLaden
            })
        ));
    } else {
        vesselPositions = emptyArray;
    }
    yield put({ type: ActionTypes.FLEET_DASHBOARD_SET_VESSEL_POSITIONS, vesselPositions });
}

function* getEarnings(action) {
    const earnings = yield PoolService.getEarnings({
        PoolDashboardFleetId: action.fleetId
    });
    yield put({ type: ActionTypes.FLEET_DASHBOARD_SET_EARNINGS, earnings });
}

function* getRouteModels() {
    const models = yield MarketIntelService.getModels();
    yield put({
        type: ActionTypes.FLEET_DASHBOARD_SET_MODELS,
        models
    });
}

function* getLatestPrediction(action) {
    const latestPrediction = yield PoolService.getMarketPrediction({
        ModelName: action.model,
        Route: 'TD20'
    });
    yield put({
        type: ActionTypes.FLEET_DASHBOARD_SET_LATEST_PREDICTION,
        latestPrediction
    });
}

function* getFreshData(action) {
    yield all([
        getVessels(action),
        getVesselPositions(action),
        getNotifications(action),
        getEarnings(action)
    ]);
}

function* resolveActionFleetId(action, fleets) {
    if (fleets && typeof action.fleetId === 'number' && fleets.some(fleet => fleet.Id === action.fleetId)) {
        yield getFreshData(action);
    } else {
        yield put({
            type: USActionTypes.USER_SETTINGS_UPDATE,
            settings: { PoolDashboardFleetId: fleets && fleets[0] ? fleets[0].Id : null }
        });
    }
}

function* getFleets(action) {
    const fleets = yield PoolService.getFleets();
    if (fleets) {
        yield put({ type: ActionTypes.FLEET_DASHBOARD_SET_FLEETS, fleets });
    }
    yield resolveActionFleetId(action, fleets);
}

function* getInitialData(action) {
    yield all([
        getVoyageOptions(),
        getRouteModels(),
        getFleets(action)
    ]);
}

function* getPorts(action) {
    const ports = yield PortService.get(action.params);
    if (ports) {
        yield put({ type: ActionTypes.FLEET_DASHBOARD_SET_PORTS, ports });
    }
}

export default function* fleetDashboardSaga() {
    yield takeEvery(ActionTypes.FLEET_DASHBOARD_GET_VOYAGE_OPTIONS, getVoyageOptions);
    yield takeEvery(ActionTypes.FLEET_DASHBOARD_GET_VESSELS, getVessels);
    yield takeEvery(ActionTypes.FLEET_DASHBOARD_GET_NOTIFICATIONS, getNotifications);
    yield takeEvery(ActionTypes.FLEET_DASHBOARD_GET_VESSEL_POSITIONS, getVesselPositions);
    yield takeEvery(ActionTypes.FLEET_DASHBOARD_GET_EARNINGS, getEarnings);
    yield takeEvery(ActionTypes.FLEET_DASHBOARD_GET_MODELS, getRouteModels);
    yield takeEvery(ActionTypes.FLEET_DASHBOARD_GET_LATEST_PREDICTION, getLatestPrediction);
    yield takeEvery(ActionTypes.FLEET_DASHBOARD_GET_INITIAL_DATA, getInitialData);
    yield takeEvery(ActionTypes.FLEET_DASHBOARD_GET_FRESH_DATA, getFreshData);
    yield takeEvery(ActionTypes.FLEET_DASHBOARD_GET_PORTS, getPorts);
}
