import moment from 'moment';
import { mapArrayByProp } from './array-helper';

const dateMap = {
    YYYY: '%Y',
    YY: '%Y',
    yy: '%Y',
    M: '%m',
    MM: '%m',
    D: '%e',
    DD: '%d'
};
const numberPattern = /^(\d+)[.,]?\d*$/;
const secondsPattern = /(\d+([.,]\d+)?)s/;
const minutesPattern = /(\d+([.,]\d+)?)m/;
const hoursPattern = /(\d+([.,]\d+)?)h/;
const daysPattern = /(\d+([.,]\d+)?)d/;
const weeksPattern = /(\d+([.,]\d+)?)w/;
const monthsPattern = /(\d+([.,]\d+)?)M/;
const yearsPattern = /(\d+([.,]\d+)?)y/;

const chartDateTimePattern = /((D+)|(M+)|(Y+)|(y+)|([. /-]+))/g;

const timeLimits = { Start: 'T00:00:00.000Z', End: 'T23:59:59.999Z' };

export const quarters = [
    { Id: 0, Name: 'Q1', Start: '01-01', End: '03-31', Range: '01-01T00:00:00.000Z03-31T23:59:59.999Z' },
    { Id: 1, Name: 'Q2', Start: '04-01', End: '06-30', Range: '04-01T00:00:00.000Z06-30T23:59:59.999Z' },
    { Id: 2, Name: 'Q3', Start: '07-01', End: '09-30', Range: '07-01T00:00:00.000Z09-30T23:59:59.999Z' },
    { Id: 3, Name: 'Q4', Start: '10-01', End: '12-31', Range: '10-01T00:00:00.000Z12-31T23:59:59.999Z' }
];

const unitMap = {
    year: 'getFullYear',
    month: 'getMonth',
    day: 'getDate',
    hour: 'getHours',
    minute: 'getMinutes',
    second: 'getSeconds',
    millisecond: 'getMilliseconds'
};

const dateUnits = Object.keys(unitMap);

const quartersByRange = mapArrayByProp(quarters, 'Range');
const quartersByName = mapArrayByProp(quarters, 'Name');

const defaultOptions = { time: true, utc: true };

class TimeHelper {
    constructor() {
        this.userPreferredFormat = 'YYYY-MM-DD';
    }

    units = {
        second: 1000,
        minute: 60 * 1000,
        hour: 3600 * 1000,
        day: 24 * 3600 * 1000,
        week: 7 * 24 * 3600 * 1000,
        month: 30 * 24 * 3600 * 1000,
        year: 365 * 24 * 3600 * 1000
    };

    setFormat(format) {
        this.userPreferredFormat = format;
    }

    getUTC(date, toISO = true) {
        const cDate = date ? new Date(date) : new Date();
        const utcDate = new Date(Date.UTC(
            cDate.getFullYear(), cDate.getMonth(), cDate.getDate(),
            cDate.getHours(), cDate.getMinutes(), cDate.getSeconds(), cDate.getMilliseconds()
        ));
        return toISO ? utcDate.toISOString() : utcDate;
    }

    getFormat(options) {
        let timeFormat = this.userPreferredFormat;
        if (options) {
            if (options.time) {
                timeFormat += ' - HH:mm';
            }
            if (options.hour) {
                timeFormat += ' - Ha';
            }
            if (options.day) {
                timeFormat += ' ddd';
            }
        }
        return timeFormat;
    }

    getFormatted(date, options) {
        const format = this.getFormat(options);
        if (date) {
            if (date instanceof moment) {
                return date.format(format);
            }
            const convertedDate = options && options.convert ? this.getUTC(date) : date;
            return options && options.utc
                ? moment.utc(convertedDate).format(format)
                : moment(convertedDate).format(format);
        }
        return options && options.utc ? moment.utc().format(format) : moment().format(format);
    }

    getWeatherFormatted(isoDate) {
        const date = isoDate.slice(8, 10);
        const hours = isoDate.slice(11, 13);
        const minutes = isoDate.slice(14, 16);
        return `${date}/${hours}${minutes}UTC`;
    }

    getFormattedWithOffset(date, dateOffset, options = defaultOptions, showUTC = true) {
        return `${this.getFormatted(date, options)} ${this.getUTCOffsetString(dateOffset, showUTC)}`;
    }

    getDateTime(date) {
        return date ? `${date.slice(0, -6)}Z` : null;
    }

    getDateOffset(date) {
        return date ? date.slice(-6) : null;
    }

    getLocalFormattedWithOffset(date, options = defaultOptions, showUTC = true) {
        const dateTime = this.getDateTime(date);
        const offset = this.getDateOffset(date);
        return `${this.getFormatted(dateTime, options)} ${showUTC ? `(UTC ${offset})` : offset}`;
    }

    getFormattedISO(date, options) {
        const dateTime = date.slice(0, -6);
        const offset = this.getDateOffset(date);
        return `${this.getFormatted(dateTime, options)} (UTC ${offset})`;
    }

    getChartTimeFormat() {
        const format = this.getFormat();
        const matches = format.match(chartDateTimePattern);
        let result = '';
        matches.forEach((matchRes) => {
            if (dateMap[matchRes]) {
                result += dateMap[matchRes];
            } else {
                result += matchRes;
            }
        });
        return result;
    }

    getNormalizedTimeSpan = (timeSpan) => (timeSpan.length === 6 ? timeSpan : `+${timeSpan}`);

    getTimeSpanFromMinutes(value, showSign = false) {
        if (value === null) {
            return '';
        }
        let sign = showSign ? '+' : '';
        const absValue = Math.abs(value);
        if (value < 0) {
            sign = '-';
        }
        let hourString = Math.floor(absValue / 60);
        hourString = hourString > 9 ? hourString : `0${hourString}`;

        let minuteString = absValue % 60;
        minuteString = minuteString > 9 ? minuteString : `0${minuteString}`;

        return `${sign}${hourString}:${minuteString}`;
    }

    getMinutesFromTimeSpan(timeSpan) {
        if (!timeSpan) {
            return null;
        }
        const splitString = timeSpan.split(':');
        if (splitString.length === 2) {
            const hours = parseInt(splitString[0], 10);
            const minutes = parseInt(splitString[1], 10);
            if (isNaN(hours) || isNaN(minutes) || minutes > 59) {
                return timeSpan;
            }
            const sign = timeSpan.charAt(0) === '-' ? -1 : 1;
            const offset = sign * ((Math.abs(hours) * 60) + minutes);
            if (!isNaN(offset)) {
                return offset;
            }
        }
        return timeSpan;
    }

    getUTCOffsetString(offset, showUTC = true) {
        if (offset === null) {
            return '';
        }
        const offsetValue = this.getTimeSpanFromMinutes(offset, true);
        return showUTC ? `(UTC ${offsetValue})` : offsetValue;
    }

    joinDateAndOffset(date, dateOffsetTimeSpan, dateOffsetMinutes) {
        const offset = dateOffsetTimeSpan
            ? this.getNormalizedTimeSpan(dateOffsetTimeSpan)
            : this.getTimeSpanFromMinutes(dateOffsetMinutes, true);
        return `${date.slice(0, -1)}${offset}`;
    }

    getDurationFromString(string, numberUnit = 'd') {
        const durationString = string.trim();
        if (durationString.match(numberPattern)) {
            const value = parseFloat(durationString);
            return moment.duration(value, numberUnit);
        }

        const matches = {
            seconds: durationString.match(secondsPattern),
            minutes: durationString.match(minutesPattern),
            hours: durationString.match(hoursPattern),
            days: durationString.match(daysPattern),
            weeks: durationString.match(weeksPattern),
            months: durationString.match(monthsPattern),
            years: durationString.match(yearsPattern)
        };

        return moment.duration({
            seconds: matches.seconds && matches.seconds[1] ? matches.seconds[1] : 0,
            minutes: matches.minutes && matches.minutes[1] ? matches.minutes[1] : 0,
            hours: matches.hours && matches.hours[1] ? matches.hours[1] : 0,
            days: matches.days && matches.days[1] ? matches.days[1] : 0,
            weeks: matches.weeks && matches.weeks[1] ? matches.weeks[1] : 0,
            months: matches.months && matches.months[1] ? matches.months[1] : 0,
            years: matches.years && matches.years[1] ? matches.years[1] : 0
        });
    }

    formatDurationResult(duration, format) {
        if (duration === 0) {
            return '0';
        }
        const absDur = Math.abs(duration);
        const period = {
            y: 365 * 24 * 60 * 60 * 1000, // 1 year = 365 days
            M: 30 * 24 * 60 * 60 * 1000, // 1 month = 30 days
            d: 24 * 60 * 60 * 1000, // 1 day = 24 hours
            h: 60 * 60 * 1000, // 1 hour = 60 minutes
            m: 60 * 1000, // 1 minute = 60 seconds
            s: 1000
        };
        let remainder = absDur;
        let value;
        const resultMap = {};
        let prevKey = null;
        format.forEach((key, index) => {
            if (index + 1 < format.length) {
                value = Math.floor(remainder / period[key]);
            } else {
                value = Math.round(remainder / period[key]);
            }
            if (prevKey && value >= period[prevKey] / period[key]) {
                resultMap[prevKey] += 1;
            } else {
                resultMap[key] = value;
            }
            remainder %= period[key];
            prevKey = key;
        });

        const result = format.reduce((sum, key) => {
            return resultMap[key] ? `${sum} ${resultMap[key]}${key}` : sum;
        }, '');
        return result.trim();
    }

    getStringFromDuration(duration, format, showSign = false) {
        if (typeof duration !== 'number') {
            return '';
        }
        let sign = '';
        if (duration < 0) {
            sign = '- ';
        } else if (showSign) {
            sign = '+ ';
        }
        const initialFormat = ['y', 'M', 'd', 'h', 'm', 's'];
        const formatting = format || initialFormat;
        let result = this.formatDurationResult(duration, formatting);
        if (!result) {
            result = this.formatDurationResult(duration, initialFormat);
        }
        return result ? `${sign}${result}` : '';
    }

    isSameDay(a, b) {
        if (!moment.isMoment(a) || !moment.isMoment(b)) return false;
        // Compare least significant, most likely to change units first
        // Moment's isSame clones moment inputs and is a tad slow
        return a.date() === b.date()
            && a.month() === b.month()
            && a.year() === b.year();
    }

    getStartOf(unit, date = null, isUTC = true, diff = 0) {
        const initDate = date ? new Date(date) : new Date();
        const units = dateUnits
            .slice(0, dateUnits.indexOf(unit) + 1)
            .map((du) => {
                if (diff && du === unit) {
                    return initDate[unitMap[du]]() + diff;
                }
                return initDate[unitMap[du]]();
            });
        return isUTC
            ? new Date(Date.UTC(...units))
            : new Date(...units);
    }

    getDateDiff(a, b) {
        const aDate = a ? new Date(a) : new Date();
        const bDate = b ? new Date(b) : new Date();
        return Math.abs(aDate.valueOf() - bDate.valueOf());
    }

    getDateFromDiff(date, diff = 0) {
        const newDate = date ? new Date(date) : new Date();
        return new Date(newDate.valueOf() + diff).toISOString();
    }

    isValidDate(date) {
        return !isNaN(new Date(date).valueOf());
    }

    getCurrentQuarter() {
        const currentDate = this.getUTC(null, false);
        const month = currentDate.getMonth();
        let quarterName = 'Q1';
        if (month > 8) {
            quarterName = 'Q4';
        } else if (month > 5) {
            quarterName = 'Q3';
        } else if (month > 2) {
            quarterName = 'Q2';
        }
        return { quarter: quartersByName[quarterName], year: currentDate.getFullYear() };
    }

    getQuarterFromRange(start, end) {
        const startDate = new Date(start);
        const endDate = new Date(end);
        if (isNaN(startDate.valueOf())
            || isNaN(endDate.valueOf())
            || startDate.getUTCFullYear() !== endDate.getUTCFullYear()) {
            return null;
        }
        const range = `${startDate.toISOString().substring(5)}${endDate.toISOString().substring(5)}`;
        return quartersByRange[range]
            ? { quarter: quartersByRange[range], year: startDate.getFullYear() }
            : null;
    }

    getRangeFromQuarterAndYear(quarterName, year) {
        const quarter = quartersByName[quarterName];
        return {
            rangeStart: `${year}-${quarter.Start}${timeLimits.Start}`,
            rangeEnd: `${year}-${quarter.End}${timeLimits.End}`
        };
    }

    convertISOString(isoString) {
        return `${isoString.slice(0, 19)}Z`;
    }
}

export default new TimeHelper();
