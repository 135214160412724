import validator from 'validator';
import moment from 'moment';
/* utils */
import { getStringStrength, hasValue } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';

const numberPattern = /^(\d+)[.,]?\d*$/;
const durationPattern = /^(\d+([.,]\d+)?y)? ?(\d+([.,]\d+)?M)? ?(\d+([.,]\d+)?w)? ?(\d+([.,]\d+)?d)? ?(\d+([.,]\d+)?h)? ?(\d+([.,]\d+)?m)? ?(\d+([.,]\d+)?s)?$/; // eslint-disable-line max-len
const phonePattern = /^\(?\+?((\s|-|\/|\(|\))*\d){8,30}$/i;
const UNLOPattern = /^[a-zA-Z2-9]{5}$/;

const getNumericPropMinMax = (params, components, name) => {
    const res = { ...params };
    if (typeof params.min === 'function') {
        res.min = params.min(components, name);
    }
    if (typeof params.max === 'function') {
        res.max = params.max(components, name);
    }
    if (params.minProp || params.maxProp) {
        const minProp = typeof params.minProp === 'function' ? params.minProp(name) : params.minProp;
        const maxProp = typeof params.maxProp === 'function' ? params.maxProp(name) : params.maxProp;
        const calcMin = minProp && components[minProp] ? parseFloat(components[minProp].state.value) : res.min;
        const calcMax = maxProp && components[maxProp] ? parseFloat(components[maxProp].state.value) : res.max;
        if (!isNaN(calcMin)
            && (typeof res.min !== 'number' || calcMin >= res.min)
            && (typeof res.max !== 'number' || calcMin <= res.max)) {
            res.min = calcMin;
        }
        if (!isNaN(calcMax)
            && (typeof res.min !== 'number' || calcMax >= res.min)
            && (typeof res.max !== 'number' || calcMax <= res.max)) {
            res.max = calcMax;
        }
    }
    return res;
};

const getPropMinMax = (params, components, name) => {
    if (params.minProp || params.maxProp) {
        const minProp = typeof params.minProp === 'function' ? params.minProp(name) : params.minProp;
        const maxProp = typeof params.maxProp === 'function' ? params.maxProp(name) : params.maxProp;
        const min = minProp && components[minProp] ? components[minProp].state.value : params.min;
        const max = maxProp && components[maxProp] ? components[maxProp].state.value : params.max;
        const propMinMax = { ...params, min, max };
        if (!min || min < params.min || min > params.max) {
            propMinMax.min = params.min;
        }
        if (!max || max > params.max || max < params.min) {
            propMinMax.max = params.max;
        }
        return propMinMax;
    }
    return params;
};

const convertDateParams = (params) => {
    if (params.min || params.max) {
        const convertedParams = { ...params };
        if (params.min && !moment.isMoment(params.min)) {
            convertedParams.min = params.utc ? moment.utc(params.min) : moment(params.min);
        }
        if (params.max && !moment.isMoment(params.max)) {
            convertedParams.max = params.utc ? moment.utc(params.max) : moment(params.max);
        }
        return convertedParams;
    }
    return params;
};

const isLocalhost = (value, params) =>
    value.indexOf('localhost') > -1 && validator.isURL(value, { require_tld: false, ...params });

const isOAuthRedirect = (value, params) =>
    value.substr(-15) === ':/oauthredirect'
    && validator.isURL(value.substr(0, value.length - 15), { require_tld: false, ...params });

const isDivisibleBy = (value, div) => {
    const numValue = typeof value === 'number' ? value : parseFloat(value);
    return isNaN(numValue) || numValue % div === 0;
};

const numeric = {
    rule: (value, params, components, name) => {
        if (typeof value !== 'number' && !value) {
            return true;
        }
        const strValue = typeof value === 'number' ? value.toString() : value;
        const propParams = getNumericPropMinMax(params, components, name);
        if (params.div && !isDivisibleBy(value, params.div)) {
            return false;
        }
        return (
            params.decimals === undefined
            || validator.isDecimal(strValue, { decimal_digits: `0,${params.decimals}` })
        ) && validator.isFloat(strValue, propParams);
    },
    hint: (value, params, components, name) => {
        const propParams = getNumericPropMinMax(params, components, name);
        let additionalMessage = '';
        if (propParams.min !== undefined && propParams.max !== undefined) {
            additionalMessage = ` between ${propParams.min} and ${propParams.max}`;
        } else if (propParams.min !== undefined) {
            additionalMessage = ` higher than ${propParams.min}`;
        } else if (propParams.max !== undefined) {
            additionalMessage = ` lower than ${propParams.max}`;
        }
        if (params.div && !params.divHint) {
            additionalMessage += ` divisible by ${params.div}`;
        }
        if (params.decimals) {
            additionalMessage += ` with up to ${params.decimals} decimals`;
        }
        let divHint = '';
        if (params.div && params.divHint) {
            divHint = `${params.divHint} `;
        } else if (params.decimals === 0) {
            divHint = 'whole ';
        }
        return `Must be a ${divHint}number${additionalMessage}`;
    }
};

export default {
    apiOldPasswordMismatch: {
        hint: () => 'Password is incorrect'
    },
    containsCharTypes: {
        rule: (value, params) => {
            const strength = getStringStrength(value);
            let validLength = 0;
            params.types.forEach((type) => {
                if (strength[type] > 0) {
                    validLength++;
                }
            });

            return validLength >= params.length;
        },
        hint: (value, params) => {
            const atLeast = [];
            const strength = getStringStrength(value);
            let testsPassed = params.types.length;

            if (params.types.indexOf('digits') >= 0 && !strength.digits) {
                atLeast.push('one digit');
                testsPassed--;
            }
            if (params.types.indexOf('special') >= 0 && !strength.special) {
                atLeast.push('one special character');
                testsPassed--;
            }
            if (params.types.indexOf('lowercase') >= 0 && !strength.lowercase) {
                atLeast.push('one lowercase letter');
                testsPassed--;
            }
            if (params.types.indexOf('uppercase') >= 0 && !strength.uppercase) {
                atLeast.push('one uppercase letter');
                testsPassed--;
            }

            if (atLeast.length === 1) {
                return `Must contain at least ${atLeast[0]}`;
            }
            return `Must contain at least ${params.length - testsPassed} of the following: ${atLeast.join(', ')}`;
        }
    },
    custom: {
        rule: (value, validation, components, name) =>
            !validation || validation.rule(value, validation, components, name),
        hint: (value, validation, components, name) =>
            (validation ? validation.hint(value, validation, components, name) : '')
    },
    dateLimits: {
        rule: (value, params, components, name) => {
            if (!value || isNaN(Date.parse(value))) {
                return true;
            }
            const propParams = convertDateParams(getPropMinMax(params, components, name));
            if (propParams.min
                && ((propParams.utc && moment.utc(value).isBefore(propParams.min))
                    || moment(value).isBefore(propParams.min))) {
                return false;
            }
            if (propParams.max
                && ((propParams.utc && moment.utc(value).isAfter(propParams.max))
                    || moment(value).isAfter(propParams.max))) {
                return false;
            }
            return true;
        },
        hint: (value, params, components, name) => {
            if (params.hint) {
                return params.hint;
            }
            let additionalMessage = '';
            const propParams = convertDateParams(getPropMinMax(params, components, name));
            const timeFormat = propParams.timeFormat || TimeHelper.getFormat({ time: true });
            const minLabel = propParams.min ? propParams.min.format(timeFormat) : '';
            const maxLabel = propParams.max ? propParams.max.format(timeFormat) : '';
            if (propParams.min && propParams.max) {
                additionalMessage = ` between ${minLabel} and ${maxLabel}`;
            } else if (propParams.min) {
                additionalMessage = ` after ${minLabel}`;
            } else if (propParams.max) {
                additionalMessage = ` before ${maxLabel}`;
            }
            return `Must be a date${additionalMessage}`;
        }
    },
    dateRange: {
        rule: (value) => value && value.rangeStart && value.rangeEnd
            && value.rangeStart !== 'Invalid Date' && value.rangeEnd !== 'Invalid Date'
            && value.rangeStart < value.rangeEnd,
        hint: () => 'Date range is invalid'
    },
    dateRangeMax: {
        rule: (value, params) => !value?.rangeStart || !value?.rangeEnd
            || value.rangeStart === 'Invalid Date' || value.rangeEnd === 'Invalid Date'
            || TimeHelper.getDateDiff(value.rangeStart, value.rangeEnd) <= params.value,
        hint: (value, params) => `Date range must be less than ${params.message}`
    },
    dateRangeMin: {
        rule: (value, params) => !value?.rangeStart || !value?.rangeEnd
            || value.rangeStart === 'Invalid Date' || value.rangeEnd === 'Invalid Date'
            || TimeHelper.getDateDiff(value.rangeStart, value.rangeEnd) >= params.value,
        hint: (value, params) => `Date range must be greater than ${params.message}`
    },
    date: {
        rule: (value) => value !== 'Invalid Date',
        hint: () => 'Date is invalid'
    },
    differsFrom: {
        rule: (value, params) => !value || value !== params.value,
        hint: (value, params) => {
            if (params.hint) {
                if (typeof params.hint === 'function') {
                    return params.hint(value, params);
                }
                return params.hint;
            }
            return `Shouldn't be same as ${params.label}`;
        }
    },
    duration: {
        rule: (value, params) => {
            if (!value || typeof value !== 'string') {
                return true;
            }
            const trimmedValue = value.trim();

            if (validator.matches(trimmedValue, numberPattern) || validator.matches(trimmedValue, durationPattern)) {
                if (typeof params === 'boolean') {
                    return true;
                }

                const durationInMs = TimeHelper.getDurationFromString(value).asMilliseconds();
                const invalidMax = params.max && durationInMs > params.max;
                const invalidMin = params.min && durationInMs < params.min;

                return !(invalidMax || invalidMin);
            }

            return false;
        },
        hint: (value, params) => {
            const trimmedValue = value.trim();

            if (validator.matches(trimmedValue, numberPattern) || validator.matches(trimmedValue, durationPattern)) {
                if (typeof params === 'boolean') {
                    return 'Must be in correct form (e.g. 5d 10h)';
                }

                const durationInMs = TimeHelper.getDurationFromString(value).asMilliseconds();

                if (value && params.min && durationInMs < params.min) {
                    return `Must be more or equal to ${TimeHelper.getStringFromDuration(params.min, ['d', 'h'])}`;
                }

                if (value && params.max && durationInMs > params.max) {
                    return `Must be less or equal to ${TimeHelper.getStringFromDuration(params.max, ['d', 'h'])}`;
                }
            }

            return 'Must be in correct form (e.g. 5d 10h)';
        }
    },
    email: {
        rule: value => !value || validator.isEmail(value),
        hint: value => `${value} is not a valid Email`
    },
    emailList: {
        rule: (value) => {
            return value.every(email => validator.isEmail(email));
        },
        hint: (value) => {
            if (value) {
                const hints = [];
                value.forEach((email) => {
                    if (!validator.isEmail(email)) {
                        hints.push(email);
                    }
                });
                return `Invalid emails: ${hints.join(', ')}`;
            }
            return 'Invalid email';
        }
    },
    equalAs: {
        rule: (value, param, components) => {
            const field = components[param.prop] ? components[param.prop].state : null;

            return !field || value === field.value;
        },
        hint: (value, param) => `Should be equal as '${param.label}'`
    },
    imo: {
        rule: (value) => {
            if (!value) {
                return true;
            }
            const num = parseInt(value, 10);
            if (typeof num !== 'number' || num < 1000000 || num > 9999999) {
                return false;
            }

            const digits = value.split('').map(a => parseInt(a, 10));
            const checkDigit = digits.pop();
            let sum = 0;

            for (let i = 0; i < digits.length; i++) {
                sum += digits[i] * (7 - i);
            }

            return checkDigit === sum % 10;
        },
        hint: () => 'Invalid IMO number'
    },
    maxLength: {
        rule: (value, param, components) => {
            if (!value) {
                return true;
            }
            const length = typeof param === 'function' ? param(components) : param;
            if (Array.isArray(value)) {
                return value.length <= length;
            }
            return value.toString().trim().length <= length;
        },
        hint: (value, param, components) => {
            const length = typeof param === 'function' ? param(components) : param;
            return `Maximum length exceeded (${value.length}/${length})`;
        }
    },
    minLength: {
        rule: (value, param, components) => {
            if (!value) {
                return true;
            }
            const length = typeof param === 'function' ? param(components) : param;
            if (Array.isArray(value)) {
                return value.length >= length;
            }
            return value.toString().trim().length >= length;
        },
        hint: (value, param, components) => {
            const length = typeof param === 'function' ? param(components) : param;
            return `Minimum length not met (${value.toString().trim().length}/${length})`;
        }
    },
    numeric,
    phone: {
        rule: value => validator.matches(value, phonePattern),
        hint: () => 'Invalid phone number - it must contain at least 8 numbers and it can start with \'+\','
            + ' have spaces, brackets, \'-\' or \'/\''
    },
    numberList: {
        rule: (values, param, components, name) => values.every(val => numeric.rule(val, param, components, name)),
        hint: (values, param, components, name) => {
            let errorIndex = -1;
            values.some((value, index) => {
                const error = !numeric.rule(value, param, components, name);
                if (error) {
                    errorIndex = index;
                }
                return error;
            });
            return numeric.hint(values[errorIndex], param, components, name) || '';
        }
    },
    required: {
        rule: (value, param, components, name) => {
            let flag = param;
            if (typeof param === 'function') {
                flag = param(value, components, name);
            }
            if (!flag) {
                return true;
            }
            return hasValue(value);
        },
        hint: () => 'Required'
    },
    similarity: {
        rule: (value, params) => {
            const val = value.toLowerCase();
            const test = params.testValue.toLowerCase();
            const length = params.length;
            let similar = false;
            for (let i = 0; i <= val.length - length; i++) {
                if (test.indexOf(val.substring(i, i + length)) >= 0) {
                    similar = true;
                    break;
                }
            }
            return !similar;
        },
        hint: (value, params) => `Too similar to ${params.testLabel}`
    },
    strength: {
        rule: (value, conditions) => {
            const strength = getStringStrength(value);
            let valid = true;
            Object.keys(conditions).forEach((key) => {
                if (strength[key] < conditions[key]) {
                    valid = false;
                }
            });

            return valid;
        },
        hint: (value, conditions) => {
            const hints = [];
            const atLeast = [];
            const strength = getStringStrength(value);
            const alphanumeric = strength.lowercase + strength.uppercase + strength.digits;

            if (conditions.totalScore && strength.totalScore < conditions.totalScore) {
                hints.push('Too weak');
            }
            if (conditions.alphanumeric && alphanumeric < conditions.alphanumeric) {
                atLeast.push(
                    `${conditions.alphanumeric} alphanumeric character${conditions.alphanumeric > 1 ? 's' : ''}`
                );
            }
            if (conditions.diffChars && strength.diffChars < conditions.diffChars) {
                atLeast.push(`${conditions.diffChars} different characters`);
            }
            if (conditions.digits && strength.digits < conditions.digits) {
                atLeast.push(`${conditions.digits} digit${conditions.digits > 1 ? 's' : ''}`);
            }
            if (conditions.special && strength.special < conditions.special) {
                atLeast.push(`${conditions.special} special character${conditions.special > 1 ? 's' : ''}`);
            }
            if (conditions.lowercase && strength.lowercase < conditions.lowercase) {
                atLeast.push(`${conditions.lowercase} lowercase letter${conditions.lowercase > 1 ? 's' : ''}`);
            }
            if (conditions.uppercase && strength.uppercase < conditions.uppercase) {
                atLeast.push(`${conditions.uppercase} uppercase letter${conditions.uppercase > 1 ? 's' : ''}`);
            }
            if (atLeast.length > 0) {
                hints.push(`Must contain at least: ${atLeast.join(', ')}`);
            }

            return hints.join('; ');
        }
    },
    timeSpan: {
        rule: (value, params) => {
            if (!value) {
                return true;
            }
            const minutes = TimeHelper.getMinutesFromTimeSpan(value);
            return typeof minutes === 'number'
                && minutes >= params.min
                && minutes <= params.max;
        },
        hint: (value, params) => {
            const minutes = TimeHelper.getMinutesFromTimeSpan(value);
            if (typeof minutes !== 'number') {
                return 'Invalid time format (\'±HH:mm\')';
            }
            if (minutes < params.min) {
                const convertedMin = TimeHelper.getTimeSpanFromMinutes(params.min);
                return `Must be higher than ${convertedMin}`;
            }
            const convertedMax = TimeHelper.getTimeSpanFromMinutes(params.max);
            return `Must be lower than ${convertedMax}`;
        }
    },
    url: {
        rule: (value, params) => {
            const validatorParams = typeof params === 'boolean' ? undefined : params;
            return !value
                || validator.isURL(value, validatorParams)
                || isLocalhost(value, validatorParams)
                || isOAuthRedirect(value, validatorParams);
        },
        hint: value => `${value} is not a valid URL`
    },
    UNLOCode: {
        rule: (value) => value && validator.matches(value, UNLOPattern),
        hint: value => (!value || value.length !== 5
            ? 'Must have 5 characters'
            : 'Can only contain letters and numbers from 2 to 9')
    }
};
