import { ActionTypes } from './wni-weather-actions';

const initialState = {
    weatherLayerTypes: [{
        Id: 1,
        Title: 'Swell wave height',
        Element: 'pswh'
    }, {
        Id: 2,
        Title: 'Ice pack',
        Element: 'icep'
    }],
    weatherData: null
};

export default function wniWeatherReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.WNI_WEATHER_SET_DATA: {
        return {
            ...state,
            weatherData: action.weatherData
        };
    }
    default:
        return state;
    }
}
