/* utils */
import { t } from 'utils/i18n/i18n-model';

export const legendInfo = [
    {
        id: 1,
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.LEGEND.SUCCESS_LABEL'),
        type: 'optimal'
    },
    {
        id: 2,
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.LEGEND.DANGER_LABEL'),
        type: 'non-optimal'
    },
    {
        id: 3,
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.LEGEND.WARNING_LABEL'),
        type: 'no-data'
    }
];
