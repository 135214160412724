import React from 'react';
import PropTypes from 'prop-types';
/* components */
import Range from '../shared/range-logic';
import Month from '../shared/month';
import PanelSlider from 'components/htec-panel-slider/panel-slider';
/* helpers */
import { compareDates, getNthFromMonth, compareMonths } from '../helpers';
/* variables */
import variables from '../variables';
/* types */
import { DateShape } from '../types';
/* styles */
import './date-range-picker.scss';

export default class DateRangePicker extends React.PureComponent {
    constructor(props) {
        super(props);
        const currentDate = new Date();
        let currentMonth;
        let currentYear;
        if (props.initialMonth) {
            currentMonth = props.initialMonth.month;
            currentYear = props.initialMonth.year;
        } else if (props.rangeEnd) {
            currentMonth = props.rangeEnd.month;
            currentYear = props.rangeEnd.year;
        } else if (props.maxDate) {
            currentMonth = props.maxDate.month;
            currentYear = props.maxDate.year;
        } else {
            currentMonth = currentDate.getMonth() + 1;
            currentYear = currentDate.getFullYear();
        }
        let monthsToShow = this.props.monthsToShow;
        if (monthsToShow > 6) {
            monthsToShow = 6;
        } else if (monthsToShow < 1) {
            monthsToShow = 1;
        }
        const startIndex = (Math.ceil(monthsToShow / 2) - monthsToShow);
        const endIndex = startIndex + monthsToShow;
        this.state = {
            today: props.today || {
                day: currentDate.getDate(),
                month: currentDate.getMonth() + 1,
                year: currentDate.getFullYear()
            },
            currentMonth,
            currentYear,
            monthsToShow,
            startIndex,
            endIndex
        };
    }

    updateCurrentMonth = (currentMonth) => {
        this.setState({
            currentMonth: currentMonth.month,
            currentYear: currentMonth.year
        });
    };

    renderDay = (args) => {
        return this.props.renderDay({
            ...args,
            isToday: compareDates(args, this.state.today) === 0
        });
    };

    handleMonthChange = (diff) => {
        const nthMonth = getNthFromMonth({ month: this.state.currentMonth, year: this.state.currentYear }, diff);
        this.setState({
            currentMonth: nthMonth.month,
            currentYear: nthMonth.year
        });
    };

    getMonth = (month, args) => (
        <div
            className="htec-dp-range__month"
            key={`${month.month}${month.year}`}
        >
            <h3 className="htec-dp-range__month-title">
                {`${variables.monthNames.long[month.month]} / ${month.year}`}
            </h3>
            <Month
                alwaysShow6Weeks={this.props.alwaysShow6Weeks}
                hideOutsideDays={this.props.hideOutsideDays}
                renderDay={args.renderDay}
                renderWeekDays={this.props.renderWeekDays}
                weekStart={this.props.weekStart}
                month={month.month}
                year={month.year}
            />
        </div>
    );

    prevBtnDisabled = (diff) => {
        if (!this.props.disableMonthsOutOfRange || !this.props.minDate) {
            return false;
        }
        const current = { month: this.state.currentMonth, year: this.state.currentYear };
        return compareMonths(getNthFromMonth(current, (this.state.startIndex - 1) + diff), this.props.minDate) === -1;
    };

    nextBtnDisabled = (diff) => {
        if (!this.props.disableMonthsOutOfRange || !this.props.maxDate) {
            return false;
        }
        const current = { month: this.state.currentMonth, year: this.state.currentYear };
        return compareMonths(getNthFromMonth(current, this.state.endIndex + diff), this.props.maxDate) === 1;
    };

    renderRange = (args) => {
        const { startIndex, endIndex, monthsToShow } = this.state;
        const months = [];
        const current = { month: this.state.currentMonth, year: this.state.currentYear };
        for (let i = startIndex; i < endIndex; i++) {
            months.push(getNthFromMonth(current, i));
        }
        const prevMonth = getNthFromMonth(current, startIndex - 1);
        const nextMonth = getNthFromMonth(current, endIndex);

        const monthsClass = `htec-dp-range__months--${monthsToShow}-shown`;

        let className = 'htec-dp-range';
        if (this.props.className) {
            className += ` ${this.props.className}`;
        }

        return (
            <PanelSlider
                panels={months.map((month) => this.getMonth(month, args))}
                className={className}
                panelsClass={monthsClass}
                onPanelChange={this.handleMonthChange}
                nextPanel={this.getMonth(nextMonth, args)}
                prevPanel={this.getMonth(prevMonth, args)}
                nextBtn={this.props.nextBtn}
                prevBtn={this.props.prevBtn}
                prevBtnDisabled={this.prevBtnDisabled}
                nextBtnDisabled={this.nextBtnDisabled}
                mouseHoldTimeout={this.props.mouseHoldTimeout}
                mouseHoldInterval={this.props.mouseHoldInterval}
            />
        );
    };

    render() {
        return (
            <Range
                hoveredDate={this.props.hoveredDate}
                hoverTracking={this.props.hoverTracking}
                maxDate={this.props.maxDate}
                minDate={this.props.minDate}
                minRangeDays={this.props.minRangeDays}
                maxRangeDays={this.props.maxRangeDays}
                onRangeSelect={this.props.onRangeSelect}
                onRangeStartSelect={this.props.onRangeStartSelect}
                rangeEnd={this.props.rangeEnd}
                rangeStart={this.props.rangeStart}
                renderRange={this.renderRange}
                renderDay={this.renderDay}
            />
        );
    }
}

DateRangePicker.propTypes = {
    alwaysShow6Weeks: PropTypes.bool,
    className: PropTypes.string,
    disableMonthsOutOfRange: PropTypes.bool,
    hideOutsideDays: PropTypes.bool,
    hoveredDate: PropTypes.shape(DateShape),
    hoverTracking: PropTypes.oneOf(['default', 'active', 'disabled']),
    initialMonth: PropTypes.shape({ month: PropTypes.number, year: PropTypes.number }),
    maxDate: PropTypes.shape(DateShape),
    maxRangeDays: PropTypes.number,
    minDate: PropTypes.shape(DateShape),
    minRangeDays: PropTypes.number,
    monthsToShow: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
    mouseHoldInterval: PropTypes.number,
    mouseHoldTimeout: PropTypes.number,
    nextBtn: PropTypes.node,
    onRangeSelect: PropTypes.func.isRequired,
    onRangeStartSelect: PropTypes.func,
    prevBtn: PropTypes.node,
    rangeEnd: PropTypes.shape(DateShape),
    rangeStart: PropTypes.shape(DateShape),
    renderDay: PropTypes.func,
    renderWeekDays: PropTypes.func,
    today: PropTypes.shape(DateShape),
    weekStart: PropTypes.oneOf(['Monday', 'Sunday'])
};

DateRangePicker.defaultProps = {
    alwaysShow6Weeks: Month.defaultProps.alwaysShow6Weeks,
    className: '',
    disableMonthsOutOfRange: false,
    hideOutsideDays: true,
    hoveredDate: null,
    hoverTracking: undefined,
    initialMonth: null,
    maxDate: null,
    maxRangeDays: 0,
    minDate: null,
    minRangeDays: undefined,
    monthsToShow: 2,
    mouseHoldInterval: undefined,
    mouseHoldTimeout: undefined,
    nextBtn: '►',
    onRangeStartSelect: undefined,
    prevBtn: '◄',
    rangeEnd: null,
    rangeStart: null,
    renderDay: Month.defaultProps.renderDay,
    renderWeekDays: Month.defaultProps.renderWeekDays,
    today: undefined,
    weekStart: Month.defaultProps.weekStart
};
