export const ActionTypes = {
    GSM_VESSEL_DETAILS_TOOLTIP_SET_POSITION: 'GSM_VESSEL_DETAILS_TOOLTIP_SET_POSITION'
};

export function setVesselDetailsTooltipPosition(dispatch, payload) {
    dispatch({
        type: ActionTypes.GSM_VESSEL_DETAILS_TOOLTIP_SET_POSITION,
        payload
    });
}
