import CoreApi from './core-api';

class ContactListService {
    constructor() {
        this.resourceUrl = 'User/Contacts';
    }

    get(params) {
        return CoreApi.get(this.resourceUrl, params)
            .then(response => response.data, () => null);
    }

    getCompanies() {
        return CoreApi.get(`${this.resourceUrl}/Companies/Basic`)
            .then(response => response.data, () => null);
    }

    getDepartments() {
        return CoreApi.get(`${this.resourceUrl}/Departments`)
            .then(response => response.data, () => null);
    }
}

export default new ContactListService();
