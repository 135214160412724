import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import keyCodes from 'utils/key-codes';
import { getClassName } from 'utils/helpers/info-helper';
/* styles */
import './flip-switch.scss';

const sizeMap = {
    xs: ' sten-flip-switch--xs'
};

class FlipSwitch extends React.PureComponent {
    state = {
        isFocused: false
    };

    handleToggle = (e) => {
        if (!this.props.disabled) {
            this.props.onChange(!this.props.value, e);
        }
    };

    onFocus = () => {
        if (!this.props.disabled) {
            this.setState({ isFocused: true });
        }
    };

    onBlur = () => { this.setState({ isFocused: false }); };

    handleKeyDown = e => {
        if (this.state.isFocused) {
            switch (e.keyCode) {
            case keyCodes.space:
                e.stopPropagation();
                this.handleToggle(e);
                break;
            case keyCodes.rightArrow:
                if (this.props.allowArrowKeys && !this.props.value) {
                    e.stopPropagation();
                    this.handleToggle(e);
                }
                break;
            case keyCodes.leftArrow:
                if (this.props.allowArrowKeys && this.props.value) {
                    e.stopPropagation();
                    this.handleToggle(e);
                }
                break;
            default:
            }
        }
    };

    render() {
        const valueLabels = {
            active: this.props.activeLabel,
            inactive: this.props.inactiveLabel,
            undefined: this.props.undefinedLabel,
            activeTitle: this.props.activeTitle || this.props.activeLabel,
            inactiveTitle: this.props.inactiveTitle || this.props.inactiveLabel,
            undefinedTitle: this.props.undefinedTitle || this.props.undefinedLabel
        };
        let value = valueLabels.inactive;
        let title = valueLabels.inactiveTitle;
        const classNames = getClassName('sten-flip-switch', this.props.className, {
            'sten-flip-switch--undefined': typeof this.props.value !== 'boolean',
            'sten-flip-switch--active': this.props.value === true,
            'sten-flip-switch--disabled': this.props.disabled,
            'sten-flip-switch--double-on': this.props.doubleOn,
            [sizeMap[this.props.size]]: !!this.props.size,
            'sten-flip-switch--focused': this.state.isFocused
        });
        if (typeof this.props.value !== 'boolean') {
            value = valueLabels.undefined;
            title = valueLabels.undefinedTitle;
        } else if (this.props.value) {
            value = valueLabels.active;
            title = valueLabels.activeTitle;
        }
        return (
            <div
                className={classNames}
                tabIndex={this.props.tabIndex}
                onClick={this.handleToggle}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onKeyDown={this.handleKeyDown}
                title={title}
            >
                <div className="sten-flip-switch__value">{value}</div>
                <div className="sten-flip-switch__btn" />
                <div className="sten-flip-switch__values-sizing">{valueLabels.active}</div>
                <div className="sten-flip-switch__values-sizing">{valueLabels.inactive}</div>
                <div className="sten-flip-switch__values-sizing">{valueLabels.undefined}</div>
            </div>
        );
    }
}

FlipSwitch.propTypes = {
    activeLabel: PropTypes.string,
    activeTitle: PropTypes.string,
    allowArrowKeys: PropTypes.bool,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    doubleOn: PropTypes.bool,
    inactiveLabel: PropTypes.string,
    inactiveTitle: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    size: PropTypes.oneOf(['xs', '']),
    tabIndex: PropTypes.number,
    undefinedLabel: PropTypes.string,
    undefinedTitle: PropTypes.string,
    value: PropTypes.bool
};

FlipSwitch.defaultProps = {
    activeLabel: translate('GLOBAL.ON'),
    activeTitle: '',
    allowArrowKeys: false,
    className: '',
    disabled: false,
    doubleOn: false,
    inactiveLabel: translate('GLOBAL.OFF'),
    inactiveTitle: '',
    name: '',
    size: '',
    tabIndex: 0,
    undefinedLabel: '-',
    undefinedTitle: translate('GLOBAL.UNDEFINED'),
    value: null
};

export default FlipSwitch;
