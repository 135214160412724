import moment from 'moment';
import { createSelector } from 'reselect';
/* utils */
import { roundNumber } from 'utils/helpers/info-helper';
/* selectors */
import { getAvailableRawReportTypes } from 'components/raw-reports-table/raw-reports-table-selectors';
/* services */
import ConfigService from 'services/config-api/config-service';

export const getReportProps = createSelector(
    state => state.voyagePerformanceReducer.counts,
    getAvailableRawReportTypes,
    (counts, availableRawReportTypes) => {
        const reportProps = {};
        availableRawReportTypes.forEach((rt) => {
            reportProps[rt.type] = {
                ...rt,
                total: (counts?.[rt.key]) || 0,
                included: (counts?.[`Included${rt.key}`]) || 0,
                excluded: (counts?.[`Excluded${rt.key}`]) || 0,
                missing: (counts?.[`Missing${rt.key}`]) || 0,
                suspicious: (counts?.[`Suspicious${rt.key}`]) || 0,
                suspiciousLimitReached: counts?.SuspiciousValuesReportLimitReached || false,
                veracitySyncError: (ConfigService.featureToggles.showSyncVesselWithVeracityToggle
                    && counts?.[`VeracityError${rt.key}`]) || 0
            };
        });
        return reportProps;
    }
);

const getDeltaValue = (value, delta, decimals) => {
    if (value === null) {
        return null;
    }
    const deltaMultiplier = value > 10 ? value : 10;
    let deltaValue = value + (deltaMultiplier * delta);
    deltaValue = deltaValue > 0 ? deltaValue : 0;
    return typeof decimals === 'number' ? roundNumber(deltaValue, decimals) : deltaValue;
};

const formatSensorDataItem = (data, key, datakey) => {
    return {
        [key]: data && data[datakey] && data[datakey].length
            ? data[datakey].map(item => {
                return {
                    Value: typeof item.Value === 'number' ? item.Value : null,
                    Date: item.Date
                };
            })
            : []
    };
};

const pointColors = {
    warning: '#ffeb3b',
    danger: '#f44336'
};

const extractChartData = (data, keys, editedReportIds = null, deletedReportIds = null) => {
    const res = {
        reportIdData: {},
        series: {},
        categories: []
    };
    let fillCategoryData = true;
    let dataKey;
    let chartKey;

    keys.forEach(key => {
        dataKey = key;
        chartKey = key;
        if (typeof key !== 'string') {
            dataKey = key.dataKey;
            chartKey = key.chartKey || key.dataKey;
        }
        res.series[chartKey] = [];
        if (data && data[dataKey]) {
            data[dataKey].forEach(item => {
                const utcDate = moment.utc(item.Date).startOf('day').valueOf();
                const point = { x: utcDate };
                if (editedReportIds?.[item.ReportId]) {
                    point.color = pointColors.warning;
                    point.isEdited = true;
                }
                if (deletedReportIds?.[item.ReportId]) {
                    point.color = pointColors.danger;
                    point.hover = null;
                    point.events = null;
                    point.isDeleted = true;
                }
                if (fillCategoryData) {
                    res.categories.push(utcDate);
                }
                if (item.ReportId && !res.reportIdData[utcDate]) {
                    res.reportIdData[utcDate] = item.ReportId;
                }
                if (key.getSeriesData) {
                    res.series[chartKey].push(key.getSeriesData(utcDate, item.Value));
                } else if (typeof key.decimals === 'number') {
                    point.y = typeof item.Value === 'number' ? roundNumber(item.Value, key.decimals) : null;
                    res.series[chartKey].push(point);
                } else {
                    point.y = item.Value;
                    res.series[chartKey].push(point);
                }
            });
            fillCategoryData = false;
        }
    });

    return res;
};

export const getVoyagePerformanceCharts = createSelector(
    state => state.voyagePerformanceReducer.graphs,
    state => state.voyagePerformanceReducer.selectedVesselSensorData,
    state => state.voyagePerformanceReducer.editedReportIds,
    state => state.voyagePerformanceReducer.deletedReportIds,
    (graphs, selectedVesselSensorData, editedReportIds, deletedReportIds) => {
        if (!graphs) {
            return null;
        }
        const chartsData = {
            weatherChart: {
                reportIdData: {},
                series: {
                    Boundary: [],
                    Reporter: [],
                    External: []
                },
                categories: []
            }
        };
        const sensorAverageVsInstructed = formatSensorDataItem(
            selectedVesselSensorData,
            'SensorAverageSpeedList',
            'AverageSpeedList'
        );
        const sensorConsumptionVsBudget = formatSensorDataItem(
            selectedVesselSensorData,
            'SensorConsumptionList',
            'ConsumptionList'
        );
        const sensorReportedVsExternalReportedWindSpeed = formatSensorDataItem(
            selectedVesselSensorData,
            'SensorWindForceList',
            'WindForceList'
        );
        const boundaryWindForce = (graphs.ReportedVsExternalReportedWindSpeed
            && graphs.ReportedVsExternalReportedWindSpeed.BoundaryWindForce) || null;
        const dailyAverageSpeedChartSeries = [
            {
                dataKey: 'InstructedSpeedList',
                chartKey: 'SpeedWarningBottom',
                getSeriesData: (utcDate, value) =>
                    [utcDate, getDeltaValue(value, -0.1), getDeltaValue(value, -0.05)]
            },
            {
                dataKey: 'InstructedSpeedList',
                chartKey: 'SpeedSuccess',
                getSeriesData: (utcDate, value) =>
                    [utcDate, getDeltaValue(value, -0.05), getDeltaValue(value, 0.05)]
            },
            {
                dataKey: 'InstructedSpeedList',
                chartKey: 'SpeedWarningTop',
                getSeriesData: (utcDate, value) =>
                    [utcDate, getDeltaValue(value, 0.05), getDeltaValue(value, 0.1)]
            },
            { dataKey: 'InstructedSpeedList', chartKey: 'InstructedSpeed' },
            { dataKey: 'AverageSpeedList', chartKey: 'AverageSpeed' }
        ];
        const consumptionChartSeries = [
            {
                dataKey: 'BudgetList',
                chartKey: 'BudgetSuccess',
                getSeriesData: (utcDate, value) => [utcDate, 0, getDeltaValue(value, 0.05, 1)]
            },
            {
                dataKey: 'BudgetList',
                chartKey: 'BudgetWarning',
                getSeriesData: (utcDate, value) =>
                    [utcDate, getDeltaValue(value, 0.05, 1), getDeltaValue(value, 0.1, 1)]
            },
            { dataKey: 'BudgetList', chartKey: 'Budget', decimals: 1 },
            { dataKey: 'ConsumptionList', chartKey: 'PropulsionAndAux', decimals: 1 },
            { dataKey: 'PropulsionList', chartKey: 'Propulsion', decimals: 1 },
            { dataKey: 'AuxList', chartKey: 'Aux', decimals: 1 },
            { dataKey: 'CargoHeatingList', chartKey: 'CargoHeating', decimals: 1 },
            { dataKey: 'TankCleaningList', chartKey: 'TankCleaning', decimals: 1 },
            { dataKey: 'IGList', chartKey: 'IG', decimals: 1 }
        ];
        const weatherChartSeries = [
            {
                dataKey: 'WindForceList',
                chartKey: 'Boundary',
                getSeriesData: (utcDate) => [utcDate, boundaryWindForce]
            },
            { dataKey: 'WindForceList', chartKey: 'Reporter', decimals: 1 },
            { dataKey: 'ExternalWindForceList', chartKey: 'External', decimals: 1 }
        ];

        if (ConfigService.featureToggles.showSensorData) {
            dailyAverageSpeedChartSeries.push({
                dataKey: 'SensorAverageSpeedList',
                chartKey: 'SensorAverageSpeed',
                decimals: 1
            });
            consumptionChartSeries.splice(5, 0, {
                dataKey: 'SensorConsumptionList',
                chartKey: 'SensorConsumption',
                decimals: 1
            });
            weatherChartSeries.splice(2, 0, {
                dataKey: 'SensorWindForceList',
                chartKey: 'SensorWindForce',
                decimals: 1
            });
        }

        chartsData.emissionChart = extractChartData(
            graphs.Emissions,
            ['Co2', 'Ch4', 'Co', 'N2o', 'Nmvoc'],
            editedReportIds,
            deletedReportIds
        );
        chartsData.dailyAverageSpeedChart = extractChartData(
            { ...graphs.AverageVsInstructed, ...sensorAverageVsInstructed },
            dailyAverageSpeedChartSeries,
            editedReportIds,
            deletedReportIds
        );

        chartsData.consumptionChart = extractChartData(
            { ...graphs.ConsumptionVsBudget, ...sensorConsumptionVsBudget },
            consumptionChartSeries,
            editedReportIds,
            deletedReportIds
        );

        chartsData.weatherChart = extractChartData(
            { ...graphs.ReportedVsExternalReportedWindSpeed, ...sensorReportedVsExternalReportedWindSpeed },
            weatherChartSeries,
            editedReportIds,
            deletedReportIds
        );
        return chartsData;
    }
);

export const getReversedVoyageReports = createSelector(
    state => state.voyagePerformanceReducer.selectedVoyageReports,
    (reports) => {
        return reports.slice().reverse();
    }
);
