import { ActionTypes } from './gsm-right-side-bar-actions';

const initialState = {
    isExpanded: false,
    activePanelName: '',

    // Vessel list
    allEntities: null,
    tankers: [],
    ferries: [],
    drills: [],
    ferryRoutes: [],
    offices: [],
    carouselImages: []
};

export default function gsmRightSideBarReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.GSM_RIGHT_SIDE_BAR_SET_ALL_ENTITIES: {
        return {
            ...state,
            allEntities: action.allEntities
        };
    }
    case ActionTypes.GSM_RIGHT_SIDE_BAR_COLLAPSE: {
        return {
            ...state,
            isExpanded: false,
            activePanelName: ''
        };
    }
    case ActionTypes.GSM_RIGHT_SIDE_BAR_SET_ACTIVE_PANEL: {
        return {
            ...state,
            activePanelName: action.panelName,
            isExpanded: action.expand
        };
    }

    case ActionTypes.GSM_RIGHT_SIDE_BAR_SET_TANKERS: {
        return {
            ...state,
            tankers: action.tankers
        };
    }

    case ActionTypes.GSM_RIGHT_SIDE_BAR_SET_FERRIES: {
        return {
            ...state,
            ferries: action.ferries
        };
    }

    case ActionTypes.GSM_RIGHT_SIDE_BAR_SET_DRILLS: {
        return {
            ...state,
            drills: action.drills
        };
    }

    case ActionTypes.GSM_RIGHT_SIDE_BAR_SET_FERRY_ROUTES: {
        return {
            ...state,
            ferryRoutes: action.ferryRoutes
        };
    }

    case ActionTypes.GSM_RIGHT_SIDE_BAR_SET_OFFICES: {
        return {
            ...state,
            offices: action.offices
        };
    }

    case ActionTypes.GSM_RIGHT_SIDE_BAR_REMOVE_ALL_ENTITIES: {
        return {
            ...state,
            tankers: [],
            ferries: [],
            offices: [],
            drills: [],
            ferryRoutes: [],
            allEntities: null
        };
    }

    case ActionTypes.GSM_RIGHT_SIDE_BAR_SET_CAROUSEL_IMAGES: {
        return {
            ...state,
            carouselImages: action.carouselImages
        };
    }

    case ActionTypes.GSM_RIGHT_SIDE_BAR_REMOVE_CAROUSEL_IMAGES: {
        return {
            ...state,
            carouselImages: []
        };
    }
    default:
        return state;
    }
}
