import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* services */
import ConfigService from 'services/config-api/config-service';
/* actions */
import { toggleActivePanel, emptyActivePanels } from 'components/right-side-bar/right-side-bar-actions';
/* components */
import IconButton from 'components/icon-button/icon-button';
import MapZoomControls from 'components/map-zoom-controls/map-zoom-controls';
import MapOptions from 'components/map-options/map-options';
import RightSideBar from 'components/right-side-bar/right-side-bar';

export class DCRightSideBar extends React.PureComponent {
    componentWillUnmount() {
        this.props.emptyActivePanels();
    }

    getButtons = () => {
        const { userSettings } = this.props;
        const mapTypeSettingName = ConfigService.hiddenFeatures.distanceCalculatorNauticalCharts
            ? 'MarketIntelMapType'
            : 'MapOptionsMapType';
        const areMapOptionsChanged = userSettings
            && (userSettings[mapTypeSettingName] > 1
                || userSettings.ShowVesselNames
                || userSettings.ArePortsInvisible
                || userSettings.MapOptionsAreasOfInterestEnabled
                || userSettings.MapOptionsWeatherLayerEnabled
                || userSettings.MapOptionsCountryBordersNamesEnabled);
        return (
            <React.Fragment>
                <IconButton
                    changeIndicator={areMapOptionsChanged}
                    icon="icon-layers"
                    isActive={this.props.activePanels.indexOf('map-options') > -1}
                    onClick={this.props.toggleActivePanel.bind(this, 'map-options')}
                    tooltipTitle={translate('MAP_OPTIONS.TITLE')}
                />
                <MapZoomControls />
            </React.Fragment>
        );
    };

    render() {
        return (
            <RightSideBar buttonRenderer={this.getButtons} isCollapsed={this.props.activePanels.length === 0}>
                {this.props.activePanels.map((panelName) => {
                    switch (panelName) {
                    case 'map-options':
                        return (
                            <MapOptions
                                key="map-options"
                                areNauticalChartsHidden={ConfigService.hiddenFeatures.distanceCalculatorNauticalCharts}
                                mapTypeSettingName={ConfigService.hiddenFeatures.distanceCalculatorNauticalCharts
                                    ? 'MarketIntelMapType'
                                    : 'MapOptionsMapType'
                                }
                                onCloseClick={this.props.toggleActivePanel.bind(this, 'map-options')}
                            />
                        );
                    default:
                        return '';
                    }
                })}
            </RightSideBar>
        );
    }
}

DCRightSideBar.propTypes = {
    activePanels: PropTypes.arrayOf(PropTypes.string).isRequired,
    emptyActivePanels: PropTypes.func.isRequired,
    toggleActivePanel: PropTypes.func.isRequired,
    userSettings: PropTypes.objectOf(PropTypes.any).isRequired
};

function mapStateToProps(state) {
    return {
        activePanels: state.rightSideBarReducer.activePanels,
        userSettings: state.userReducer.settings
    };
}

function mapDispatchToProps(dispatch) {
    return {
        emptyActivePanels: () => emptyActivePanels(dispatch),
        toggleActivePanel: (panelName, enable) => toggleActivePanel(dispatch, panelName, enable)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DCRightSideBar);
