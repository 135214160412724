import Excel from 'exceljs';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { addWorksheet, triggerXLSXDownload } from 'utils/helpers/xlsx-helper';
import {
    borderWithDarkerRightSideStyle,
    columnWidth,
    darkCellStyle,
    darkHeaderRowStyle,
    tableCellStyle,
    thinBorderWithoutLeftSideStyle,
    xlsxColors
} from 'utils/helpers/xlsx-styles';
import { getOrdinal } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
import { getLogoBase64 } from 'utils/base64images';

const getAlignment = (vertical = 'top', horizontal = 'left') => ({
    alignment: {
        wrapText: true,
        vertical,
        horizontal
    }
});

const getFont = (fontSize, color) => ({
    font: {
        color: { argb: color },
        name: 'Arial',
        size: fontSize
    }
});

const formatTableCell = (value, props = null, colSpan = 1, format = {}) => ({
    value,
    colSpan,
    props: { ...tableCellStyle, ...props, numFmt: format.numFmt || null }
});

const generateVesselInfoHeaderRows = (date, vesselName, coating) => {
    return ([
        {
            props: { height: 20 },
            values: [
                {
                    value: vesselName.toUpperCase(),
                    colSpan: 8,
                    props: {
                        ...darkHeaderRowStyle,
                        ...getFont(11, xlsxColors.white),
                        ...getAlignment('bottom')
                    }
                },
                ...new Array(7).fill(null),
                {
                    image: { base64: getLogoBase64().light, extension: 'png' },
                    imageProps: {
                        tl: { col: 5.9, row: 0.75 },
                        ext: { width: getLogoBase64().width, height: getLogoBase64().height }
                    }
                }
            ]
        },
        {
            values: [
                {
                    value: `${translate('CARGO_EXPORT.COATING')} ${coating || '-'}`,
                    colSpan: 8,
                    props: {
                        ...darkHeaderRowStyle,
                        ...getFont(10, xlsxColors.ash),
                        ...getAlignment('bottom')
                    }
                }
            ]
        },
        {
            props: { height: 20 },
            values: [
                {
                    value: translate(
                        'CARGO_EXPORT.LAST_5_CARGOES_PER_TANK',
                        { date: TimeHelper.getFormatted(date, { utc: true }) }
                    ),
                    colSpan: 8,
                    props: {
                        ...darkHeaderRowStyle,
                        ...getFont(10, xlsxColors.ash),
                        ...getAlignment('top')
                    }
                }
            ]
        }
    ]);
};

const getCommodityHeaderRow = (wing) => ({
    values: [
        formatTableCell(`${wing.IsSlop ? 'S' : wing.Index + 1}P`, darkCellStyle, 4),
        null,
        null,
        null,
        formatTableCell(`${wing.IsSlop ? 'S' : wing.Index + 1}S`, darkCellStyle, 4)
    ]
});

const getCommodityRow = (wing, i) => {
    const emptyCommodity = {
        Commodity: '-',
        LoadPercentage: null,
        ReportDate: null,
        ReportId: null,
        VoyageId: null
    };

    const portCommodity = wing.Port.PreviousCommodities[i] ? wing.Port.PreviousCommodities[i] : emptyCommodity;
    const starboardCommodity = wing.Starboard.PreviousCommodities[i]
        ? wing.Starboard.PreviousCommodities[i] : emptyCommodity;

    const indexLabel = translate('CARGO_EXPORT.LAST_ORDINAL', { ordinal: getOrdinal(i + 1) });
    const percentageFormat = { type: 'number', numFmt: '0"%"' };
    const rightCellAlignment = getAlignment('top', 'right');

    return ({
        values: [
            formatTableCell(indexLabel, rightCellAlignment),
            formatTableCell(portCommodity.Commodity ? portCommodity.Commodity : '-'),
            formatTableCell(portCommodity.VoyageNumber),
            formatTableCell(
                portCommodity.LoadPercentage,
                { ...rightCellAlignment, ...borderWithDarkerRightSideStyle },
                1,
                percentageFormat
            ),
            formatTableCell(indexLabel, { ...rightCellAlignment, ...thinBorderWithoutLeftSideStyle }),
            formatTableCell(starboardCommodity.Commodity ? starboardCommodity.Commodity : '-'),
            formatTableCell(starboardCommodity.VoyageNumber),
            formatTableCell(starboardCommodity.LoadPercentage, rightCellAlignment, 1, percentageFormat)
        ]
    });
};

const generateVesselInfoCargoRows = wings => {
    const vesselInfoCargoRows = [];
    if (wings && wings.length && wings.length > 0) {
        wings.forEach((wing) => {
            vesselInfoCargoRows.push(getCommodityHeaderRow(wing));
            for (let i = 0; i < 5; i++) {
                vesselInfoCargoRows.push(getCommodityRow(wing, i));
            }
        });
    }
    return vesselInfoCargoRows;
};

export default function* generateVesselInfoCargoXLSX({
    date,
    vesselName,
    wings,
    coating
}) {
    const workbook = new Excel.Workbook();
    const sheetConfig = {
        name: 'Vessel Info Cargo',
        props: {
            properties: { showGridLines: false },
            views: [{ showGridLines: false }],
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                margins: { left: 0.4, right: 0.4, top: 0.2, bottom: 0.2, header: 0, footer: 0 }
            }
        },
        columnWidth,
        columns: [
            { width: 8 }, // Port: Ordinal
            { width: 25 }, // Port: Commodity
            { width: 8 }, // Port: VoyageNumber
            { width: 6 }, // Port: Percentage
            { width: 8 }, // Starboard: Ordinal
            { width: 25 }, // Starboard: Commodity
            { width: 8 }, // Starboard: VoyageNumber
            { width: 6 } // Starboard: Percentage
        ],
        rows: [
            ...generateVesselInfoHeaderRows(date, vesselName, coating),
            ...generateVesselInfoCargoRows(wings)
        ]
    };

    addWorksheet(workbook, sheetConfig);

    const xls64 = yield workbook.xlsx.writeBuffer({ base64: true });
    return triggerXLSXDownload(`Last 5 cargoes per tank - ${vesselName} - ${date}`)(xls64);
}
