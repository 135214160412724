import React from 'react';
import PropTypes from 'prop-types';
import ScrollArea from 'components/scroll-area/scroll-area';
import AfterInitialMount from 'components/after-initial-mount/after-initial-mount';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import ModalDefault from './modal-default';

const ModalDelete = props => (
    <ModalDefault
        className="sten-modal--with-footer"
        modalSize="xs"
        isOpen={props.isOpen}
        onRequestClose={props.onCancel}
    >
        <ScrollArea className="sten-modal__body flex flex-center">
            <div className="sten-content__section">
                {props.children}
            </div>
        </ScrollArea>
        <footer className="sten-modal__footer">
            <div className="flex-grow flex-row flex-center">
                <div className="flex-grow text-right">
                    <button onClick={props.onCancel} className="btn btn--secondary">
                        {translate('GLOBAL.CANCEL')}
                    </button>
                </div>
                <div className="flex-shrink">
                    <button onClick={props.onDelete} className="btn btn--danger">
                        {translate('GLOBAL.DELETE')}
                    </button>
                </div>
            </div>
        </footer>
    </ModalDefault>
);

ModalDelete.propTypes = {
    children: PropTypes.node,
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

ModalDelete.defaultProps = {
    children: null,
    isOpen: false
};

export default (props) => (
    <AfterInitialMount>
        <ModalDelete {...props} />
    </AfterInitialMount>
);
