import CoreApi from './core-api';

class CommentService {
    constructor() {
        this.resourceUrl = 'Comments';
        this.resourceIdMap = {
            voyage: 'VoyageId',
            port: 'PortId',
            terminal: 'TerminalId',
            berth: 'BerthId'
        };
    }

    getResourceData = (resource, resourceId, comment) => ({
        Comment: comment,
        [this.resourceIdMap[resource]]: resourceId
    });

    get = (resource, resourceId) =>
        CoreApi.get(`${this.resourceUrl}/${resource}/${resourceId}`)
            .then(response => response.data, () => null);

    submit = (resource, resourceId, comment) => {
        const data = this.getResourceData(resource, resourceId, comment);
        return CoreApi.post(`${this.resourceUrl}/${resource}`, data).then(() => true, () => false);
    }

    update = (resource, resourceId, comment, commentId) => {
        const data = this.getResourceData(resource, resourceId, comment);
        return CoreApi.put(`${this.resourceUrl}/${resource}/${commentId}`, data).then(() => true, () => false);
    }

    remove = (resource, commentId) =>
        CoreApi.remove(`${this.resourceUrl}/${resource}/${commentId}`).then(() => true, () => false);
}

export default new CommentService();
