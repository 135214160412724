import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* services */
import WeatherService from 'services/core-api/weather-service';
/* selectors */
import { getSelectedLayers } from './dtn-weather-selectors';
/* actions */
import { initializeWeatherData } from './dtn-weather-actions';
/* components */
import Ol from 'components/ol/ol';

class DtnWeather extends React.Component {
    componentDidMount() {
        this.props.initializeWeatherData();
    }

    sourceProps = {
        tileLoadFunction: (tile, url) => {
            tile.setLoader((extent, resolution, projection) => {
                WeatherService.getLayerTiles(url)
                    .then(res => {
                        if (res) {
                            const format = tile.getFormat();
                            const features = format.readFeatures(res, {
                                extent,
                                featureProjection: projection
                            });
                            tile.setFeatures(features);
                        } else {
                            tile.setFeatures([]);
                        }
                    });
            });
        }
    }

    render() {
        const { selectedLayers, selectedTimestamp, zIndex } = this.props;
        return (
            <React.Fragment>
                {selectedLayers.map((layer) => (
                    <Ol.Layer
                        key={layer.id}
                        name={layer.id}
                        type="vectorTile"
                        elementProps={layer.elementProps}
                        elements={layer}
                        zIndex={zIndex + layer.order}
                        layerProps={layer.layerProps}
                        sourceProps={this.sourceProps}
                        observes={[selectedTimestamp]}
                        opacity={layer.opacity}
                        onPostRender={layer.onPostRender}
                    />
                ))}
            </React.Fragment>
        );
    }
}

DtnWeather.propTypes = {
    initializeWeatherData: PropTypes.func.isRequired,
    selectedLayers: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedTimestamp: PropTypes.string,
    zIndex: PropTypes.number.isRequired
};

DtnWeather.defaultProps = {
    selectedTimestamp: ''
};

function mapStateToProps(state) {
    return {
        selectedLayers: getSelectedLayers(state),
        selectedTimestamp: state.dtnWeatherReducer.selectedTimestamp
    };
}

function mapDispatchToProps(dispatch) {
    return {
        initializeWeatherData: () => initializeWeatherData(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DtnWeather);
