import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import ConsumptionTable from './consumption-table/voyage-performance-consumption-table';
import IconButton from 'components/icon-button/icon-button';
import Modal from './voyage-performance-panel-modal';
import SubPanel from './sub-panel/voyage-performance-sub-panel';
/* styles */
import './voyage-performance-panel.scss';

const t = (key, params) => translate(`VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.${key}`, params);

class PerformancePanel extends React.PureComponent {
    state = {
        isModalShown: false
    };

    toggleModal = () => this.setState({ isModalShown: !this.state.isModalShown });

    handleSubmit = (params) => {
        this.toggleModal();
        if (params !== this.props.params) {
            this.props.onParamsChange(params);
        }
    };

    render() {
        const { consumption, params, performance } = this.props;
        return (
            <div className="sten-panel sten-voyage-performance-panel">
                <Modal
                    params={params}
                    isModalShown={this.state.isModalShown}
                    onModalClose={this.toggleModal}
                    onSubmit={this.handleSubmit}
                />
                <div className="sten-panel__header">
                    <h4 className="flex-grow text-uppercase">{t('TITLE')}</h4>
                    <div className="flex-shrink">
                        <IconButton
                            isLink
                            changeIndicator={!!params}
                            icon="icon-cog"
                            isActive={this.state.isModalShown}
                            onClick={this.toggleModal}
                            tooltipTitle={t('SET_PARAMETERS')}
                        />
                    </div>
                </div>
                <div className="sten-panel__section">
                    <div className="row">
                        <div className="col-8">
                            <SubPanel title={t('TOTAL')} data={performance ? performance.TotalVoyage : null} />
                        </div>
                        <div className="col-8">
                            <SubPanel title={t('BALLAST')} data={performance ? performance.Ballast : null} />
                        </div>
                        <div className="col-8">
                            <SubPanel title={t('LADEN')} data={performance ? performance.Laden : null} />
                        </div>
                    </div>
                    <ConsumptionTable consumption={consumption} />
                </div>
            </div>
        );
    }
}

PerformancePanel.propTypes = {
    consumption: PropTypes.objectOf(PropTypes.any),
    onParamsChange: PropTypes.func.isRequired,
    params: PropTypes.shape({
        windForceMax: PropTypes.string,
        hoursSteamedMin: PropTypes.string,
        avgSpeedMin: PropTypes.string,
        instructedSpeedMin: PropTypes.string
    }),
    performance: PropTypes.objectOf(PropTypes.any)
};

PerformancePanel.defaultProps = {
    consumption: null,
    params: null,
    performance: null
};

export default PerformancePanel;
