import { t } from 'utils/i18n/i18n-model';

export const areaOptions = [{
    id: 0,
    name: t('CALCULATOR_FILTERS.HRA_ZONES'),
    prop: 'AvoidHighRiskAreas'
}, {
    id: 1,
    name: t('CALCULATOR_FILTERS.ECA_ZONES'),
    prop: 'AvoidEcaZoneAreas'
}];

export const canalOptions = [{
    id: 0,
    name: t('CALCULATOR_FILTERS.PANAMA'),
    prop: 'PanamaCanal'
}, {
    id: 1,
    name: t('CALCULATOR_FILTERS.SUEZ'),
    prop: 'SuezCanal'
}, {
    id: 2,
    name: t('CALCULATOR_FILTERS.KIEL'),
    prop: 'KielCanal'
}];

export const startingPositionOptions = [{
    label: t('CALCULATOR_FILTERS.STARTING_POSITION.CUSTOM_PORT'),
    value: 0
}, {
    label: t('CALCULATOR_FILTERS.STARTING_POSITION.CURRENT_POSITION'),
    value: 1,
    disabled: true,
    disabledTitle: t('CALCULATOR_FILTERS.STARTING_POSITION.CURRENT_POSITION_MISSING')
}, {
    label: t('CALCULATOR_FILTERS.STARTING_POSITION.NEXT_OPEN_PORT'),
    value: 2,
    disabled: true,
    disabledTitle: t('CALCULATOR_FILTERS.STARTING_POSITION.NEXT_OPEN_PORT_MISSING')
}, {
    label: t('CALCULATOR_FILTERS.STARTING_POSITION.DEVIATE_CURRENT_VOYAGE'),
    value: 3,
    disabled: true,
    disabledTitle: t('CALCULATOR_FILTERS.STARTING_POSITION.CURRENT_VOYAGE_MISSING')
}];
