import { takeEvery, put, delay } from 'redux-saga/effects';
import VesselService from 'services/core-api/vessel-service';
import { ActionTypes } from './fleet-range-actions';
import generateFleetRangeXLSX from './export/fleet-range-xlsx';

function* getFleetRange(action) {
    const fleetRangeResponse = yield VesselService.getFleetRange(action.params);
    if (fleetRangeResponse) {
        const vessels = fleetRangeResponse.PageItems;
        const currentPage = (fleetRangeResponse.Offset / fleetRangeResponse.Limit) + 1;
        const allFetched = currentPage >= fleetRangeResponse.TotalPagesCount;
        yield put({
            type: ActionTypes.FLEET_RANGE_ADD_VESSELS,
            vessels,
            overrideCurrent: action.overrideCurrent,
            allFetched
        });
    }
}

function* generateXLSX() {
    yield put({ type: 'TOGGLE_LOADER', payload: true });
    yield delay(0);
    const fleetRangeResponse = yield VesselService.getFleetRange({ limit: 200, offset: 0 });
    yield generateFleetRangeXLSX(fleetRangeResponse.PageItems);
    yield put({ type: 'TOGGLE_LOADER', payload: false });
}


export default function* fleetRangeSaga() {
    yield takeEvery(ActionTypes.FLEET_RANGE_GET_VESSELS, getFleetRange);
    yield takeEvery(ActionTypes.FLEET_RANGE_GENERATE_XLSX, generateXLSX);
}
