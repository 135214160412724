import { takeEvery, put, select, all } from 'redux-saga/effects';
/* utils */
import { omitObjectKeys } from 'utils/helpers/info-helper';
/* actions */
import { ActionTypes } from './vessel-actions';
/* services */
import ConfigService from 'services/config-api/config-service';
import EnergyManagementService from 'services/core-api/energy-management-service';
import LocalStorageService from 'services/local-storage-service';
/* mocks */
import {
    mockedKpiVesselAuxUtilization,
    mockedKpiVesselAvgSpeed,
    mockedKpiVesselBasicData,
    mockedKpiVesselBoilerUtilization,
    mockedKpiVesselKeyAvgFigures,
    mockedKpiVesselKeyTotalFigures,
    mockedKpiVesselRPMAnalysis
} from '../mocks';

function* getData(action) {
    let filters = action.filters;
    if (!filters) {
        filters = yield select(state => state.energyManagementKPIReducer.filters);
    }
    const selectedRangeEnd = new Date(Date.UTC(filters.selectedRangeEnd.year, filters.selectedRangeEnd.month, 1) - 1);
    const params = {
        DateRangeOption: filters.selectedRangeOption.Id,
        EndDate: selectedRangeEnd.toISOString()
    };
    const {
        auxiliaryUtilization,
        averageSpeed,
        boilerUtilization,
        keyAverageFigures,
        keyTotalFigures,
        rpmAnalysis,
        basicData
    } = LocalStorageService.isDebugModeActive()
        || ConfigService.featureToggles.showKpiWithMockedData
        ? {
            auxiliaryUtilization: mockedKpiVesselAuxUtilization,
            averageSpeed: mockedKpiVesselAvgSpeed,
            boilerUtilization: mockedKpiVesselBoilerUtilization,
            keyAverageFigures: mockedKpiVesselKeyAvgFigures,
            keyTotalFigures: mockedKpiVesselKeyTotalFigures,
            rpmAnalysis: mockedKpiVesselRPMAnalysis,
            basicData: mockedKpiVesselBasicData
        }
        : yield all({
            auxiliaryUtilization: EnergyManagementService.KPI.Vessel.getAuxiliaryUtilization(action.imo, params),
            averageSpeed: EnergyManagementService.KPI.Vessel.getAverageSpeed(action.imo, params),
            boilerUtilization: EnergyManagementService.KPI.Vessel.getBoilerUtilization(action.imo, params),
            // goodTrim: EnergyManagementService.KPI.Vessel.getGoodTrim(action.imo, params),
            keyAverageFigures: EnergyManagementService.KPI.Vessel.getKeyAverageFigures(action.imo, params),
            keyTotalFigures: EnergyManagementService.KPI.Vessel.getKeyTotalFigures(action.imo, params),
            rpmAnalysis: EnergyManagementService.KPI.Vessel.getRPMAnalysis(action.imo, params),
            basicData: EnergyManagementService.KPI.Vessel.getBasicData(action.imo, params)
        });

    let EEListFormatted = [];
    const keysToOmit = ['Segments'];
    if (basicData.EnergyEfficiencyDataList) {
        EEListFormatted = basicData.EnergyEfficiencyDataList.map(data =>
            omitObjectKeys(data, keysToOmit));
    }

    yield put({
        type: ActionTypes.ENERGY_MANAGEMENT_KPI_VESSEL_SET_DATA,
        auxiliaryUtilization,
        averageSpeed,
        boilerUtilization,
        // goodTrim,
        keyAverageFigures,
        keyTotalFigures,
        rpmAnalysis,
        topGraph: {
            ...basicData,
            EnergyEfficiencyDataList: EEListFormatted
        }
    });
}

export default function* energyManagementKPIVesselSaga() {
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_KPI_VESSEL_GET_DATA, getData);
}
