import { ActionTypes } from './gsm-map-legend-actions';

const initialState = {
    tankersLayerVisibility: false,
    officesLayerVisibility: false,
    ferriesLayerVisibility: false,
    ferryRoutesLayerVisibility: false,
    drillsLayerVisibility: false
};

export default function gsmMapLegendReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.GSM_MAP_LEGEND_SET_TANKERS_LAYER_VISIBILITY: {
        return {
            ...state,
            tankersLayerVisibility: action.visibilityState
        };
    }
    case ActionTypes.GSM_MAP_LEGEND_SET_OFFICES_LAYER_VISIBILITY: {
        return {
            ...state,
            officesLayerVisibility: action.visibilityState
        };
    }
    case ActionTypes.GSM_MAP_LEGEND_SET_FERRIES_LAYER_VISIBILITY: {
        return {
            ...state,
            ferriesLayerVisibility: action.visibilityState
        };
    }
    case ActionTypes.GSM_MAP_LEGEND_SET_FERRY_ROUTES_LAYER_VISIBILITY: {
        return {
            ...state,
            ferryRoutesLayerVisibility: action.visibilityState
        };
    }
    case ActionTypes.GSM_MAP_LEGEND_SET_DRILLS_LAYER_VISIBILITY: {
        return {
            ...state,
            drillsLayerVisibility: action.visibilityState
        };
    }
    default:
        return state;
    }
}
