import NotificationApi from './notification-api';

class NotificationService {
    constructor() {
        this.resourceUrl = 'Notifications';
    }

    getNotifications(params) {
        return NotificationApi.get(this.resourceUrl, params, true)
            .then(response => response.data, () => false);
    }

    getNotificationsByImo(imo, params) {
        return NotificationApi.get(`${this.resourceUrl}/${imo}`, params, true)
            .then(response => response.data, () => false);
    }

    dismissNotifications(ids) {
        return NotificationApi.put(this.resourceUrl, { Status: 'Dismissed', NotificationIds: ids })
            .then(() => true, () => false);
    }
}

export default new NotificationService();
