import React from 'react';
import PropTypes from 'prop-types';
/* styles */
import './bar.scss';

class Bar extends React.PureComponent {
    render() {
        const isNegative = this.props.value < 0;
        let width = ((Math.abs(this.props.value) / this.props.maxValue) / 2) * 100;
        if (width > 50) {
            width = 50;
        }
        const barInnerStyle = {
            width: `${width}%`
        };
        let barInnerClass = 'sten-bar__inner';
        if (isNegative) {
            barInnerClass += ' sten-bar__inner--negative';
        }
        return (
            <div className="sten-bar">
                <div className={barInnerClass} style={barInnerStyle} />
            </div>
        );
    }
}

Bar.propTypes = {
    maxValue: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

export default Bar;
