import moment from 'moment';
/* utils */
import TimeHelper from 'utils/helpers/time-helper';
import { joinArrayByProp } from 'utils/helpers/array-helper';

const getLayCanDays = (data) => {
    const layDate = data.LayCanFrom ? moment.utc(data.LayCanFrom).date() : null;
    const canDate = data.LayCanTo ? moment.utc(data.LayCanTo).date() : null;
    return layDate || canDate ? `${layDate || ''}-${canDate || ''}` : '-';
};

export const isFixtureOnSubs = (fixture) => !!fixture.Tags.find(tag => tag.Id === 1);

export const convertFixture = (fixture) => ({
    ...fixture,
    IsOnSubs: isFixtureOnSubs(fixture),
    Commodity: fixture.Cargoes && fixture.Cargoes.length > 0
        ? joinArrayByProp(fixture.Cargoes, 'Commodity', ', ', '-')
        : '-',
    LayCan: getLayCanDays(fixture)
});

export const getTimeStatusValue = (data, prop, statusProp) => {
    if (!data[prop] && !data[statusProp]) {
        return null;
    }
    const date = data[prop] ? TimeHelper.getFormatted(data[prop], { utc: true, time: true }) : null;
    const status = data[statusProp] ? data[statusProp].ShortName : null;
    return `${status || ''}${date ? ` ${date}` : ''}`;
};

export const convertVoyageItinerary = (itinerary) => {
    if (!itinerary || !itinerary.length) {
        return itinerary;
    }
    const convertedItinerary = [];
    let currentPoint = null;
    itinerary.forEach(activity => {
        if (currentPoint && (
            (activity.Point && currentPoint.Point && activity.Point.Id !== currentPoint.Point.Id)
            || activity.Point === currentPoint.Point)) {
            convertedItinerary.push(currentPoint);
            currentPoint = null;
        }
        if (!currentPoint) {
            currentPoint = { Point: activity.Point, Activities: [] };
        }
        currentPoint.Activities.push({
            ...activity,
            EtaState: getTimeStatusValue(activity, 'Eta', 'EtaStatus'),
            EtbState: getTimeStatusValue(activity, 'Etb', 'EtbStatus'),
            EtdState: getTimeStatusValue(activity, 'Etd', 'EtdStatus')
        });
    });
    convertedItinerary.push(currentPoint);
    return convertedItinerary;
};
