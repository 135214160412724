import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { takeEvery, put, select, all, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
/* router */
import { navigate, appRoutes } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import generateInspectionsXLSX from './export/inspections-xlsx';
/* actions */
import { ActionTypes } from './inspections-actions';
/* selectors */
import {
    getFilteredInspections,
    areFilteredInspectionsSelected,
    calcObservationAverages,
    getSelectedInspections
} from './inspections-selectors';
/* services */
import VesselService from 'services/core-api/vessel-service';
import InspectionsService from 'services/core-api/inspections-service';
import PdfGeneratorService from 'services/core-api/pdf-generator-service';
/* exports */
import InspectionsPDF from './export/inspections-pdf';

function* getOptions() {
    const { companies, segments, inspectionTypes, inspectionActivities, headOwners, technicalManagers } = yield all({
        companies: VesselService.getCompanies(),
        segments: VesselService.getSegments(),
        inspectionTypes: InspectionsService.getInspectionTypes(),
        inspectionActivities: InspectionsService.getInspectionActivities(),
        headOwners: VesselService.getHeadOwners(),
        technicalManagers: VesselService.getTechnicalManagers()
    });
    yield put({
        type: ActionTypes.INSPECTIONS_SET_OPTIONS,
        companies,
        segments,
        inspectionTypes,
        inspectionActivities,
        headOwners,
        technicalManagers
    });
}

function* deleteInspection(action) {
    const success = yield InspectionsService.deleteInspection(action.inspectionId);
    if (success) {
        yield put({ type: ActionTypes.INSPECTIONS_GET_INSPECTIONS });
        yield navigate(appRoutes.Vessel.Inspections);
        toast(t('INSPECTIONS.DELETE_SUCCESS'), { type: toast.TYPE.SUCCESS });
    }
}

function* exportToXlsx() {
    yield put({ type: 'TOGGLE_LOADER', payload: true });
    yield delay(0);
    const selectedInspections = yield select(getSelectedInspections);
    const averages = yield select(calcObservationAverages);
    yield generateInspectionsXLSX(selectedInspections, averages);
    yield put({ type: 'TOGGLE_LOADER', payload: false });
}

function* exportToPdf() {
    const selectedInspections = yield select(getSelectedInspections);
    const averages = yield select(calcObservationAverages);
    const htmlString = ReactDOMServer.renderToStaticMarkup(
        <InspectionsPDF selectedInspections={selectedInspections} averages={averages} />
    );

    PdfGeneratorService.convert(
        { html: htmlString, waitFor: 100, viewport: { width: 1920, height: 1080 } },
        t('INSPECTIONS.TITLE')
    );
}

function* getInspections() {
    const response = yield InspectionsService.getInspections();
    if (response) {
        yield put({
            type: ActionTypes.INSPECTIONS_SET_INSPECTIONS,
            inspections: response
        });
    }
}

function* toggleFilteredInspections() {
    const allSelected = yield select(areFilteredInspectionsSelected);
    const filteredInspections = yield select(getFilteredInspections);
    const currentlySelectedInspections = yield select(state => state.inspectionsReducer.selectedInspections);
    const selectedInspections = { ...currentlySelectedInspections };
    filteredInspections.forEach((inspection) => {
        if (allSelected) {
            delete selectedInspections[inspection.Id];
        } else {
            selectedInspections[inspection.Id] = true;
        }
    });
    yield put({ type: ActionTypes.INSPECTIONS_SET_SELECTED_INSPECTIONS, selectedInspections });
}

export default function* inspectionsSaga() {
    yield takeEvery(ActionTypes.INSPECTIONS_GET_OPTIONS, getOptions);
    yield takeEvery(ActionTypes.INSPECTIONS_GET_INSPECTIONS, getInspections);
    yield takeEvery(ActionTypes.INSPECTIONS_TOGGLE_FILTERED_INSPECTIONS, toggleFilteredInspections);
    yield takeEvery(ActionTypes.INSPECTIONS_DELETE_INSPECTION, deleteInspection);
    yield takeEvery(ActionTypes.INSPECTIONS_EXPORT_TO_XLSX, exportToXlsx);
    yield takeEvery(ActionTypes.INSPECTIONS_EXPORT_TO_PDF, exportToPdf);
}
