import { takeEvery, put, all } from 'redux-saga/effects';
/* helper */
import { wktParse } from 'components/ol/ol-helpers';
/* actions */
import { ActionTypes } from './market-intel-actions';
/* services */
import MarketIntelService from 'services/core-api/market-intel-service';
import MapOptionsService from 'services/core-api/map-options-service';
import CargoService from 'services/core-api/cargo-service';
import VesselService from 'services/core-api/vessel-service';
import AreaService from 'services/core-api/area-service';

function* getBunkerPorts() {
    const bunkerPorts = yield MarketIntelService.getBunkerPrices();
    yield put({ type: ActionTypes.MARKET_INTEL_SET_BUNKER_PORTS, bunkerPorts });
}

function* getRoutes() {
    const routes = yield MarketIntelService.getRoutes();
    let tradingRoutes = null;
    if (routes && routes.Routes && routes.Routes.length) {
        tradingRoutes = routes.Routes.map(route => ({
            ...route,
            Route: wktParse(route.Route)
        }));
    }
    yield put({ type: ActionTypes.MARKET_INTEL_SET_ROUTES, tradingRoutes });
}

function* getOptions() {
    const options = yield all({
        mapTypes: MapOptionsService.getMapTypes(),
        layerTypes: MapOptionsService.getMarketIntelLayerTypes(),
        cargoStatuses: CargoService.getCargoStatuses(),
        vesselSegments: VesselService.getSegments(),
        vesselDistributionTypes: VesselService.getDistributionTypes(),
        vesselDistributionAreas: AreaService.getVesselDistributionAreas()
    });
    if (options) {
        options.mapTypes = options.mapTypes.filter(mapType => mapType.Id !== 3);
        yield put({ type: ActionTypes.MARKET_INTEL_SET_OPTIONS, options });
    }
}

function* getDistributionHeatMap() {
    const heatMap = yield VesselService.getHeatMap();
    if (heatMap) {
        yield put({ type: ActionTypes.MARKET_INTEL_SET_DISTRIBUTION_HEATMAP, heatMap });
    }
}

function* getTotalVesselDistribution() {
    const vesselDistribution = yield VesselService.getDistribution();
    if (vesselDistribution) {
        const AreaDistributions = {};
        let MaxCount = 0;
        vesselDistribution.AreaDistributions.forEach((area) => {
            AreaDistributions[area.Area.AreaId] = area;
            if (area.VesselCount > MaxCount) {
                MaxCount = area.VesselCount;
            }
        });
        const distribution = { ...vesselDistribution, AreaDistributions, MaxCount };
        yield put({ type: ActionTypes.MARKET_INTEL_SET_TOTAL_VESSEL_DISTRIBUTION, distribution });
    }
}

export default function* marketIntelSaga() {
    yield takeEvery(ActionTypes.MARKET_INTEL_GET_BUNKER_PORTS, getBunkerPorts);
    yield takeEvery(ActionTypes.MARKET_INTEL_GET_ROUTES, getRoutes);
    yield takeEvery(ActionTypes.MARKET_INTEL_GET_OPTIONS, getOptions);
    yield takeEvery(ActionTypes.MARKET_INTEL_GET_DISTRIBUTION_HEATMAP, getDistributionHeatMap);
    yield takeEvery(ActionTypes.MARKET_INTEL_GET_TOTAL_VESSEL_DISTRIBUTION, getTotalVesselDistribution);
}
