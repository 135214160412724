/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
/* helpers */
import { formatNumber, getOrdinal } from 'utils/helpers/info-helper';
import { translate } from 'utils/i18n/i18n-model';
/* components */
import stenTableStyles, { border } from 'utils/helpers/pdf-styles/sten-table';
import headings from 'utils/helpers/pdf-styles/headings';
import { getLogoBase64 } from 'utils/base64images';

const styles = `
html {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
}
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
}
table {
    border-right: ${border};
    border-left: ${border};
}
${stenTableStyles}
.sten-table th {
    white-space: normal;
}
.sten-table--sm td, .sten-table--sm th {
    padding: 6px;
    overflow-wrap: break-word;
    min-width: 100px;
}
.page {
    page-break-inside: avoid;
    page-break-after: always;
}
.sten-vessel-info-cargo-pdf__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}
.sten-vessel-info-cargo-pdf__header-info--cargo-info {
    display: flex;
    flex-direction: row;
}
.sten-vessel-info-cargo-pdf__header-info--cargo-info div:nth-child(2) {
    padding-left: 8px;
}
`;

function breakRowsJS() {
    const bodyClone = document.body.cloneNode(true);
    const mmToPxRatio = document.documentElement.offsetWidth / 190;
    const zoomRatio = 277 / 297;
    const pageHeightInPx = Math.round(277 * mmToPxRatio);
    const headerHeight = 100 * zoomRatio;
    const pageLimit = 1.3 * pageHeightInPx;
    const tables = document.getElementsByClassName('sten-vessel-info-cargo-pdf__table');
    const tableClones = Array.prototype.slice.call(
        bodyClone.getElementsByClassName('sten-vessel-info-cargo-pdf__table')
    );
    let tableHeight;
    let heightSoFar = headerHeight;
    let newPage = document.createElement('div');
    newPage.classList.add('page');
    newPage.appendChild(bodyClone.getElementsByClassName('sten-vessel-info-cargo-pdf__header')[0]);
    for (let i = 0; i < tables.length; i++) {
        tableHeight = tables[i].getBoundingClientRect().height * zoomRatio;
        heightSoFar += tableHeight;
        if (heightSoFar > pageLimit) {
            bodyClone.appendChild(newPage);
            newPage = document.createElement('div');
            newPage.classList.add('page');
            newPage.appendChild(tableClones[i]);
            heightSoFar = tableHeight;
        } else {
            newPage.appendChild(tableClones[i]);
        }
    }
    bodyClone.appendChild(newPage);
    document.body.innerHTML = bodyClone.innerHTML;
}

export default class CargoPDF extends React.PureComponent {
    getHeader = () => {
        const coating = this.props.cargoSummary
            && this.props.cargoSummary.Coating ? this.props.cargoSummary.Coating : '-';
        return (
            <div className="sten-vessel-info-cargo-pdf__header">
                <div className="sten-vessel-info-cargo-pdf__header-info">
                    <h3 style={{ margin: 0, textTransform: 'uppercase', ...headings.h3 }}>{this.props.vesselName}</h3>
                    <p style={{ margin: 0 }}>{translate('CARGO_EXPORT.COATING')} {coating}</p>
                    <div className="sten-vessel-info-cargo-pdf__header-info--cargo-info">
                        <div>{translate('CARGO_EXPORT.LAST_5_CARGOES_PER_TANK', { date: this.props.date })}</div>
                    </div>
                </div>
                <img style={{ height: '5rem' }} src={getLogoBase64().dark} alt="OrbitMI Logo" />
            </div>
        );
    };

    getCommodityHeaderRow = (wing) => (
        <tr key={`${wing.Index}/head`}>
            <td
                colSpan="4"
                style={{
                    backgroundColor: '#0e5370',
                    borderRight: '1px solid #0f2e39',
                    color: '#fff'
                }}
            >
                {`${wing.IsSlop ? 'S' : wing.Index + 1}P`}
            </td>
            <td colSpan="4" style={{ backgroundColor: '#0e5370', color: '#fff' }}>
                {`${wing.IsSlop ? 'S' : wing.Index + 1}S`}
            </td>
        </tr>
    );

    getCommodityRow = (wing, i) => {
        const emptyCommodity = {
            Commodity: null,
            LoadPercentage: null,
            ReportDate: null,
            ReportId: null,
            VoyageId: null
        };
        const portCommodity = wing.Port.PreviousCommodities[i] ? wing.Port.PreviousCommodities[i] : emptyCommodity;
        const starboardCommodity = wing.Starboard.PreviousCommodities[i]
            ? wing.Starboard.PreviousCommodities[i] : emptyCommodity;
        return (
            <tr key={`${i}/body`}>
                <td>{`${getOrdinal(i + 1)} last`}</td>
                <td style={{ width: '50%' }}>{portCommodity.Commodity ? portCommodity.Commodity : '-'}</td>
                <td>{portCommodity.VoyageNumber ? portCommodity.VoyageNumber : '-'}</td>
                <td style={{ borderRight: '1px solid #0f2e39' }}>
                    {typeof portCommodity.LoadPercentage === 'number'
                        ? `${formatNumber(portCommodity.LoadPercentage, 0)}%`
                        : '-'
                    }
                </td>
                <td>{`${getOrdinal(i + 1)} last`}</td>
                <td style={{ width: '50%' }}>{starboardCommodity.Commodity ? starboardCommodity.Commodity : '-'}</td>
                <td>{starboardCommodity.VoyageNumber ? starboardCommodity.VoyageNumber : '-'}</td>
                <td>
                    {typeof starboardCommodity.LoadPercentage === 'number'
                        ? `${formatNumber(starboardCommodity.LoadPercentage, 0)}%`
                        : '-'
                    }
                </td>
            </tr>
        );
    };

    getCommodityRows = (wing) => {
        const rows = [];
        for (let i = 0; i < 5; i++) {
            rows.push(this.getCommodityRow(wing, i));
        }
        return rows;
    };

    getTables = () => {
        const wings = this.props.data;
        if (wings && wings.length && wings.length > 0) {
            return (
                <React.Fragment>
                    {wings.map((wing) => {
                        return (
                            <table
                                className="sten-table sten-table--sm sten-vessel-info-cargo-pdf__table"
                                key={`${wing.Index}/table`}
                            >
                                <thead>{this.getCommodityHeaderRow(wing)}</thead>
                                <tbody>{this.getCommodityRows(wing)}</tbody>
                            </table>
                        );
                    })}
                </React.Fragment>
            );
        }
        return null;
    };

    render() {
        return (
            <html lang="en">
                <head><style>{styles}</style></head>
                <body>
                    {this.getHeader()}
                    {this.getTables()}
                    <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{ __html: `(${breakRowsJS.toString()})();` }}
                    />
                </body>
            </html>
        );
    }
}

CargoPDF.propTypes = {
    cargoSummary: PropTypes.objectOf(PropTypes.any).isRequired,
    vesselName: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired
};
