import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import TimeHelper from 'utils/helpers/time-helper';
import { renderFormContent } from 'components/form-fields/form-fields-helper';
import { setObjectProp } from 'utils/helpers/info-helper';
/* actions */
import {
    deleteReport,
    getConfigs,
    getConsumptionTypes,
    getEditingOptions,
    getReport,
    resetAll,
    updateReport
} from './vessel-report-actions';
/* services */
import ConfigService from 'services/config-api/config-service';
/* selectors */
import {
    getReportEditable,
    getReportFuelTypes,
    getVisibleFieldsAndSections,
    getConvertedEditingOptions
} from './vessel-report-selectors';
/* components */
import Menu, { MenuItem } from 'components/menu/menu';
import ScrollArea from 'components/scroll-area/scroll-area';
import Validation from 'components/validation/validation';
/* configs */
import { commonSuggestions } from './vessel-report-common-fields';
import dailyReportFields from './daily/vessel-daily-report-field-map';
import arrivalReportFields from './arrival/vessel-arrival-report-field-map';
import departureReportFields from './departure/vessel-departure-report-field-map';
import cargoReportFields from './cargo/vessel-cargo-report-field-map';
import eventReportFields from './event/vessel-event-report-field-map';
import nextLegReportFields from './next-leg/vessel-next-leg-report-field-map';
import sofReportFields from './sof/vessel-sof-report-field-map';
/* styles */
import './vessel-report.scss';

const reportTypePropsMap = {
    1: { ...dailyReportFields, title: t('REPORT_LABELS.DAILY_REPORT') },
    2: { ...departureReportFields, title: t('REPORT_LABELS.DEPARTURE_REPORT') },
    3: { ...arrivalReportFields, title: t('REPORT_LABELS.ARRIVAL_REPORT') },
    4: { ...cargoReportFields, title: t('REPORT_LABELS.CARGO_REPORT') },
    5: { ...eventReportFields, title: t('REPORT_LABELS.EVENT_REPORT') },
    6: { ...nextLegReportFields, title: t('REPORT_LABELS.NEXT_LEG_REPORT') },
    7: { ...sofReportFields, title: t('REPORT_LABELS.SOF_REPORT') }
};

export class VesselReport extends React.PureComponent {
    state = {
        editingReport: null
    };

    static getDerivedStateFromProps(props, state) {
        if (props.isEditing
            && props.report
            && props.permissions.UpdateReport
            && (!state.editingReport || state.editingReport.ReportId !== props.report.ReportId)) {
            return { editingReport: props.report };
        }
        if (!props.isEditing && state.editingReport && props.activeReportId) {
            return { editingReport: null };
        }
        return null;
    }

    componentDidMount() {
        this.props.getConfigs();
        if (this.props.activeReportId) {
            this.props.getReport(this.props.activeReportId, this.props.activeReportType, this.props.isEditing);
            if (this.props.isEditing && !ConfigService.featureToggles.useReportConfigService) {
                this.props.getConsumptionTypes(this.props.activeReportType);
            }
        }
        this.props.getEditingOptions();
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeReportId
            && (prevProps.activeReportId !== this.props.activeReportId || (
                this.props.isEditing && this.props.isEditing !== prevProps.isEditing))) {
            this.props.getReport(this.props.activeReportId, this.props.activeReportType, this.props.isEditing);
            if (this.props.isEditing && !ConfigService.featureToggles.useReportConfigService) {
                this.props.getConsumptionTypes(this.props.activeReportType);
            }
        }
    }

    componentWillUnmount() {
        this.props.resetAll();
    }

    handleValueChange = (field, value) => {
        if (field) {
            this.setState((prevState) => {
                const editingReport = { ...prevState.editingReport };
                setObjectProp(editingReport, field, value, true);
                return { editingReport };
            });
        }
    };

    handleEdit = () => this.props.router.updateQueryParams({ add: { reportEdit: true } });

    handleClose = () => this.props.router.updateQueryParams({ remove: ['reportId', 'reportType', 'reportEdit'] });

    handleCancel = () => {
        if (this.props.closeOnCancel) {
            this.handleClose();
        } else {
            this.props.router.updateQueryParams({ remove: ['reportEdit'] });
        }
    };

    handleDelete = () => {
        this.props.deleteReport(this.props.activeReportId, this.props.reloadWhenFinished);
    };

    submitForm = () => {
        this.props.updateReport(
            this.state.editingReport,
            this.props.reloadWhenFinished,
            this.props.closeOnSubmit,
            this.props.router
        );
    };

    renderSuggestions = (report, fieldConfig, prop, setValue, options, isEditing) => {
        if (!isEditing || !report.Suggestions[prop]) {
            return null;
        }
        return report.Suggestions[prop].map((suggestion, index) => {
            if (fieldConfig.suggestions && fieldConfig.suggestions[suggestion.Type]) {
                return fieldConfig.suggestions[suggestion.Type](suggestion, index, report, prop, setValue, options);
            }
            return commonSuggestions[suggestion.Type]
                ? commonSuggestions[suggestion.Type](suggestion, index, report, prop, setValue, options)
                : null;
        });
    };

    renderContent = () => {
        const reportType = this.props.report.ReportType || this.props.report.ReportTypeId;
        if (!reportType) {
            return null;
        }
        const { fieldMap, dataMap } = reportTypePropsMap[reportType];
        const params = {
            data: this.state.editingReport || this.props.report,
            onChange: this.handleValueChange,
            isEditing: !!this.state.editingReport,
            editingOptions: {
                ...this.props.editingOptions,
                fuelTypes: this.props.fuelTypes.list,
                noOfWingsOptions: this.props.noOfWingsOptions,
                cargoTankStateOptions: this.props.cargoTankStateOptions,
                slopTankStateOptions: this.props.slopTankStateOptions
            },
            validationRules: this.props.validationRules[reportType],
            renderValueInfo: this.renderSuggestions
        };
        return renderFormContent({
            sections: this.props.visibleFields,
            fieldMap,
            dataMap,
            params
        });
    };

    render() {
        const { report, permissions, isEditable } = this.props;
        if (!report) {
            return (
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <div className="flex-grow" />
                        <div className="flex-shrink">
                            <button className="btn-link icon icon-close" onClick={this.handleClose} />
                        </div>
                    </div>
                    <div className="sten-content__body" />
                </div>
            );
        }
        const reportType = report.ReportType || report.ReportTypeId;
        let sidebarContentClass = 'sten-vessel-report sten-content';
        let reportDateFormatted = '';
        if (this.state.editingReport) {
            sidebarContentClass += ' sten-content--has-footer';
        } else {
            let reportDate = '';
            let reportDateOffset = '';
            if (report.Date) {
                reportDate = report.Date;
                reportDateOffset = report.DateZD ? ` ${TimeHelper.getUTCOffsetString(report.DateZD * 60)}` : '';
            } else if (report.ReportDate) {
                reportDate = report.ReportDate;
                reportDateOffset = report.ReportDateOffset ? ` (UTC ${report.ReportDateOffset})` : '';
            } else if (report.CommencedCargoDate) {
                reportDate = report.CommencedCargoDate;
                reportDateOffset = report.CommencedCargoDateOffset ? ` (UTC ${report.CommencedCargoDateOffset})` : '';
            }
            reportDateFormatted = moment(reportDate).isValid()
                ? `${TimeHelper.getFormatted(reportDate, { utc: true, time: true })}${reportDateOffset}`
                : '';
        }
        const title = report.ReportSubtype && report.ReportSubtype.Id !== 1
            ? `${reportTypePropsMap[reportType].title} - ${report.ReportSubtype.DisplayName}`
            : reportTypePropsMap[reportType].title;

        return (
            <div className={sidebarContentClass}>
                <div className="sten-content__header flex-row">
                    <div className="flex-grow">
                        <h1 className="sten-content__title">{title}</h1>
                        {reportDateFormatted && <p className="sten-content__subtitle">{reportDateFormatted}</p>}
                    </div>
                    {!this.state.editingReport && permissions.UpdateReport && isEditable && (
                        <div className="flex-shrink">
                            <Menu>
                                <MenuItem onClick={this.handleEdit} icon="icon-edit">{t('GLOBAL.EDIT')}</MenuItem>
                                <MenuItem onClick={this.handleDelete} icon="icon-delete">{t('GLOBAL.DELETE')}</MenuItem>
                            </Menu>
                        </div>
                    )}
                    <div className="flex-shrink">
                        <button className="btn-link icon icon-close" onClick={this.handleClose} />
                    </div>
                </div>
                <Validation.Form className="sten-vessel-report__content" onSubmit={this.submitForm}>
                    <ScrollArea className="sten-content__body" shouldScrollToActiveElement isImpure>
                        {this.renderContent()}
                    </ScrollArea>
                    {this.state.editingReport && (
                        <footer className="sten-content__footer flex-row">
                            <div className="col-12">
                                <button
                                    className="btn btn--secondary col-24"
                                    type="button"
                                    onClick={this.handleCancel}
                                >
                                    {t('GLOBAL.CANCEL')}
                                </button>
                            </div>
                            <div className="col-12">
                                <Validation.Button className="btn btn--primary col-24">
                                    {t('GLOBAL.SAVE_CHANGES')}
                                </Validation.Button>
                            </div>
                        </footer>
                    )}
                </Validation.Form>
            </div>
        );
    }
}

VesselReport.propTypes = {
    activeReportId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    activeReportType: PropTypes.number,
    areBunkerTypeUnitsShown: PropTypes.bool,
    cargoTankStateOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    closeOnCancel: PropTypes.bool,
    closeOnSubmit: PropTypes.bool,
    deleteReport: PropTypes.func.isRequired,
    editingOptions: PropTypes.objectOf(PropTypes.any).isRequired,
    fuelTypes: PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.object),
        map: PropTypes.objectOf(PropTypes.any)
    }).isRequired,
    getConfigs: PropTypes.func.isRequired,
    getConsumptionTypes: PropTypes.func.isRequired,
    getEditingOptions: PropTypes.func.isRequired,
    getReport: PropTypes.func.isRequired,
    isEditable: PropTypes.bool.isRequired,
    isEditing: PropTypes.bool,
    noOfWingsOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    permissions: PropTypes.objectOf(PropTypes.any),
    reloadWhenFinished: PropTypes.bool,
    report: PropTypes.objectOf(PropTypes.any),
    resetAll: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    slopTankStateOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateReport: PropTypes.func.isRequired,
    validationRules: PropTypes.objectOf(PropTypes.any).isRequired,
    visibleFields: PropTypes.arrayOf(PropTypes.any).isRequired
};

VesselReport.defaultProps = {
    activeReportId: null,
    activeReportType: null,
    areBunkerTypeUnitsShown: true,
    closeOnCancel: false,
    closeOnSubmit: false,
    isEditing: false,
    permissions: null,
    reloadWhenFinished: true,
    report: null
};

function mapStateToProps(state) {
    return {
        cargoTankStateOptions: state.vesselReportReducer.cargoTankStateOptions,
        editingOptions: getConvertedEditingOptions(state),
        fuelTypes: getReportFuelTypes(state, state.vesselReportReducer.report),
        isEditable: getReportEditable(state),
        noOfWingsOptions: state.vesselReportReducer.noOfWingsOptions,
        permissions: state.userReducer.permissions,
        report: state.vesselReportReducer.report,
        slopTankStateOptions: state.vesselReportReducer.slopTankStateOptions,
        validationRules: state.vesselReportReducer.validationRules,
        visibleFields: getVisibleFieldsAndSections(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getConfigs: () => getConfigs(dispatch),
        getConsumptionTypes: reportTypeId => getConsumptionTypes(dispatch, reportTypeId),
        getEditingOptions: () => getEditingOptions(dispatch),
        getReport: (reportId, reportType, shouldShowSuggestions) =>
            getReport(dispatch, reportId, reportType, shouldShowSuggestions),
        resetAll: () => resetAll(dispatch),
        updateReport: (report, shouldReload, shouldClose) =>
            updateReport(dispatch, report, shouldReload, shouldClose),
        deleteReport: (reportId, shouldReload) => deleteReport(dispatch, reportId, shouldReload)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VesselReport));
