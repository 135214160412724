export default [
    {
        Id: 1,
        Name: 'Rolling12Months',
        Description: 'Rolling 12 months'
    },
    {
        Id: 2,
        Name: 'YearToDate',
        Description: 'Year to date'
    }
];
