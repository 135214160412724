import ApiHelper from './gsm-api';

class GsmCityService {
    constructor() {
        this.resourceUrl = 'Cities';
    }

    get(params) {
        return ApiHelper.get(`${this.resourceUrl}`, params, true)
            .then(response => response.data, () => null);
    }

    getById(cityId) {
        return ApiHelper.get(`${this.resourceUrl}/${cityId}/Companies`)
            .then(response => response.data, () => null);
    }
}

export default new GsmCityService();
