import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
import FileUpload from 'components/file-upload/file-upload';
import Validation from 'components/validation/validation';
import Input from 'components/input/input';
import Office from './office/admin-company-management-office';
/* services */
import CompanyService from 'services/core-api/company-service';
/* actions */
import { updateCompany } from '../admin-company-management-actions';
/* styles */
import './admin-company-management-form.scss';
import { activeStatusIds } from '../../admin-user-management/admin-user-management-constants';

const allowedFileTypes = [
    // 'image/jpeg',
    // 'image/pjpeg',
    'image/png'
];

const emptyArray = [];

const newCompany = {
    Id: null,
    Name: '',
    UsersCount: null,
    Files: emptyArray,
    FilesForDelete: emptyArray,
    FilesByTypeName: null,
    Offices: emptyArray,
    LogoLightURI: '',
    LogoDarkURI: '',
    LogoLight: emptyArray,
    LogoDark: emptyArray
};

const newOffice = {
    City: '',
    Address: '',
    Phones: emptyArray,
    Emails: emptyArray
};

const maxImageSize = 5 * 1024 * 1024;

export class AdminCompanyManagementCompanyForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            ...newCompany
        };
    }

    componentDidMount() {
        if (this.props.id) {
            this.getCompanyById(this.props.id);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.id !== prevProps.id) {
            this.getCompanyById(this.props.id);
        }
        if (this.state.Id !== prevState.Id) {
            this.state.Files.forEach((file) => {
                CompanyService.Internal.getLogoByIdAndType(this.props.id, { typeId: file.TypeId }).then((res) => {
                    this.setState({ [`${file.TypeName}URI`]: res });
                });
            });
        }
    }

    componentWillUnmount() {
        this.setState({ ...newCompany });
    }

    getCompanyById(id) {
        if (id) {
            CompanyService.Internal.getById(id).then((company) => {
                if (company) {
                    const FilesByTypeName = {};
                    company.Files.forEach((file) => {
                        FilesByTypeName[file.TypeName] = file;
                    });
                    this.setState({
                        ...company,
                        FilesByTypeName,
                        FilesForDelete: emptyArray
                    });
                }
            });
        } else {
            this.setState(newCompany);
        }
    }

    handleCloseClick = () => this.props.router.navigate(appRoutes.Admin.CompanyManagement);

    handleLogoChange = (type, files, e) => {
        if (e) { e.stopPropagation(); }
        const FilesForDelete = [...this.state.FilesForDelete];
        if (this.state.FilesByTypeName
            && this.state.FilesByTypeName[type]
            && !FilesForDelete.includes(this.state.FilesByTypeName[type])) {
            FilesForDelete.push(this.state.FilesByTypeName[type]);
        }
        this.setState({
            [`${type}URI`]: files && files[0] ? URL.createObjectURL(files[0]) : null,
            [type]: files,
            FilesForDelete
        });
    };

    handleUsersClick = () => this.props.router.navigate({
        pathname: appRoutes.Admin.UserManagement,
        query: {
            company: this.state.Id,
            statuses: activeStatusIds
        }
    });

    handleOfficeChange = (index, office) => {
        const Offices = [...this.state.Offices];
        Offices[index] = office;
        this.setState({ Offices });
    };

    handleOfficeDelete = (index) => {
        this.setState({ Offices: this.state.Offices.filter((office, officeIndex) => officeIndex !== index) });
    };

    handleOfficeAdd = () => {
        this.setState({ Offices: [...this.state.Offices, { ...newOffice }] });
    };

    handleFormSubmit = (e) => {
        const formData = new FormData(e.target);
        this.props.updateCompany(this.state, formData);
    };

    setValue = (key, value) => {
        this.setState({
            [key]: value
        });
    };

    render() {
        return (
            <Validation.Form className="sten-admin-company-management-form" onSubmit={this.handleFormSubmit}>
                <div className="sten-content sten-content--has-footer">
                    <div className="sten-content__header">
                        <h1 className="sten-content__title flex-grow">
                            {this.props.id
                                ? t('ADMIN.COMPANY_MANAGEMENT.COMPANY.EDIT')
                                : t('ADMIN.COMPANY_MANAGEMENT.COMPANY.ADD')}
                        </h1>
                        <div className="flex-shrink">
                            <button
                                type="button"
                                className="btn-link icon icon-close"
                                onClick={this.handleCloseClick}
                            />
                        </div>
                    </div>
                    <ScrollArea className="sten-content__body">
                        <div className="sten-content__section">
                            <div className="form-row">
                                <label className="label">
                                    <span>{t('ADMIN.COMPANY_MANAGEMENT.COMPANY.NAME')}</span>
                                </label>
                                <Validation.Wrapper validations={{ required: true, minLength: 3, maxLength: 50 }}>
                                    <Input
                                        name="Name"
                                        value={this.state.Name}
                                        onChange={this.setValue.bind(this, 'Name')}
                                    />
                                </Validation.Wrapper>
                            </div>
                        </div>
                        <div className="sten-content__section">
                            <label className="label" title={t('ADMIN.COMPANY_MANAGEMENT.COMPANY.LOGO_LIGHT_TOOLTIP')}>
                                {t('ADMIN.COMPANY_MANAGEMENT.COMPANY.LOGO_LIGHT_BG')}
                                <span className="icon icon-info-circle sten-admin-company-management-form__info" />
                            </label>
                            <FileUpload
                                isDeletable
                                isImageUpload
                                icon="icon-image"
                                onChange={this.handleLogoChange.bind(this, 'LogoLight')}
                                allowedFileTypes={allowedFileTypes}
                                maxSize={maxImageSize}
                                imageURI={this.state.LogoLightURI}
                                name="LogoLight"
                                className="sten-admin-company-management-form__image-upload
                                               sten-admin-company-management-form__image-upload--light"
                                additionalDropText={t('FILE_UPLOAD.ADDITIONAL_DROP_TEXT')}
                            />
                        </div>
                        <div className="sten-content__section">
                            <label className="label" title={t('ADMIN.COMPANY_MANAGEMENT.COMPANY.LOGO_DARK_TOOLTIP')}>
                                {t('ADMIN.COMPANY_MANAGEMENT.COMPANY.LOGO_DARK_BG')}
                                <span className="icon icon-info-circle sten-admin-company-management-form__info" />
                            </label>
                            <FileUpload
                                isDeletable
                                isImageUpload
                                icon="icon-image"
                                onChange={this.handleLogoChange.bind(this, 'LogoDark')}
                                allowedFileTypes={allowedFileTypes}
                                maxSize={maxImageSize}
                                imageURI={this.state.LogoDarkURI}
                                name="LogoDark"
                                className="sten-admin-company-management-form__image-upload
                                    sten-admin-company-management-form__image-upload--dark"
                                additionalDropText={t('FILE_UPLOAD.ADDITIONAL_DROP_TEXT')}
                            />
                        </div>
                        {this.state.Id && (
                            <div className="sten-content__section">
                                <div
                                    className="sten-admin-company-management-form__user-box"
                                    onClick={this.handleUsersClick}
                                >
                                    <div className="sten-admin-company-management-form__user-box-item">
                                        <span className="icon icon--md icon-user" />
                                    </div>
                                    <div className="sten-admin-company-management-form__user-box-item flex-grow">
                                        <h3>
                                            {t('ADMIN.COMPANY_MANAGEMENT.COMPANY.USERS_COUNT', {
                                                count: this.state.UsersCount
                                            })}
                                        </h3>
                                        <p className="text-secondary">
                                            {t('ADMIN.COMPANY_MANAGEMENT.COMPANY.MORE_OPTIONS')}
                                        </p>
                                    </div>
                                    <div className="sten-admin-company-management-form__user-box-item">
                                        <span className="icon icon--md icon-expand" />
                                    </div>
                                </div>
                            </div>
                        )}
                        {this.state.Offices.map((office, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div className="sten-content__section" key={index}>
                                <Office
                                    index={index}
                                    office={office}
                                    onChange={this.handleOfficeChange}
                                    onDelete={this.handleOfficeDelete}
                                />
                            </div>
                        ))}
                        <div className="sten-content__section">
                            <button
                                type="button"
                                className="btn-link btn-link--inverted text-uppercase"
                                onClick={this.handleOfficeAdd}
                            >
                                <span className="btn__icon icon icon-plus" />
                                {t('ADMIN.COMPANY_MANAGEMENT.COMPANY.ADD_OFFICE')}
                            </button>
                        </div>
                    </ScrollArea>
                    <footer className="sten-content__footer flex-row">
                        <div className="col-12">
                            <button type="button" className="btn btn--secondary col-24" onClick={this.handleCloseClick}>
                                {t('GLOBAL.CANCEL')}
                            </button>
                        </div>
                        <div className="col-12">
                            <Validation.Button className="btn btn--primary col-24">
                                {t('GLOBAL.SAVE')}
                            </Validation.Button>
                        </div>
                    </footer>
                </div>
            </Validation.Form>
        );
    }
}

AdminCompanyManagementCompanyForm.propTypes = {
    id: PropTypes.number,
    router: TRouter.isRequired,
    updateCompany: PropTypes.func.isRequired
};

AdminCompanyManagementCompanyForm.defaultProps = {
    id: null
};

function mapDispatchToProps(dispatch) {
    return {
        updateCompany: (company, formData) => updateCompany(dispatch, company, formData)
    };
}

export default connect(null, mapDispatchToProps)(withRouter(AdminCompanyManagementCompanyForm));
