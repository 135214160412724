import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { takeEvery, put, select, delay } from 'redux-saga/effects';
/* utils */
import { getConvertedObject } from 'utils/helpers/info-helper';
import { getLogoBase64 } from 'utils/base64images';
import { getLogoForCompany } from '../../reports-helpers';
import { mapArrayByProp } from 'utils/helpers/array-helper';
import { t } from 'utils/i18n/i18n-model';
/* actions */
import { ActionTypes } from './voyage-report-actions';
import { ActionTypes as VRActionTypes } from 'components/vessel-report/vessel-report-actions';
/* selectors */
import { getSummary } from './voyage-report-selectors';
import { convertReports, convertRowKeys } from 'components/raw-reports-table/raw-reports-table-selectors';
import { getSelectedPresetCompanyId } from '../../reports-selectors';
import { getAllReportsAndRows } from 'components/raw-reports-table/raw-reports-table-saga';
import { getHiddenFieldsByTypeDSST } from 'components/vessel-report/vessel-report-selectors';
/* services */
import ConfigService from 'services/config-api/config-service';
import EnergyManagementService from 'services/core-api/energy-management-service';
import LocalStorageService from 'services/local-storage-service';
import PdfGeneratorService from 'services/core-api/pdf-generator-service';
import VesselService from 'services/core-api/vessel-service';
import VoyageService from 'services/core-api/voyage-service';
/* exports */
import VoyageReportPDF from './export/voyage-report-pdf';
import generateVoyageReportsXLSX from './export/voyage-report-xlsx';
/* prop types */
import inclusionPropTypes from '../../sidebar/inclusions/inclusion-prop-types';
/* mocked data */
import { getMockedVoyageFootprint } from 'components/footprint-table/footprint-table-test-utils';

const reportTypes = ['daily', 'arrival', 'departure', 'cargo', 'event', 'sof'];

function* prepareDataForReports() {
    const {
        selectedVoyage,
        selectedRange,
        rangeSelectorActive,
        selectedVessel,
        inclusions
    } = yield select(state => state.energyManagementReportsReducer.generatedReportSettings);
    const { vesselCii, voyageVmsFootprint } = yield select(state => state.energyManagementReportsVoyageReportReducer);
    const reportData = yield getAllReportsAndRows({
        Imo: selectedVessel.Imo,
        Inclusions: inclusions,
        ...rangeSelectorActive
            ? { DateFrom: selectedRange?.rangeStart, DateTo: selectedRange?.rangeEnd }
            : { VoyageId: selectedVoyage?.VoyageId }
    });
    const voyageRangeParams = rangeSelectorActive
        ? { range: selectedRange }
        : { voyageNumber: selectedVoyage?.VoyageNumber };
    const hiddenFieldsByType = yield select(getHiddenFieldsByTypeDSST);
    const state = yield select(s => s);
    const companyId = yield select(getSelectedPresetCompanyId);
    const companyLogo = yield getLogoForCompany(companyId);
    const vesselName = selectedVessel.Title;
    const rowConfigs = {};
    reportTypes.forEach(rt => {
        rowConfigs[`${rt}Reports`] = convertRowKeys(
            rt, reportData.typesAndGrades[rt], hiddenFieldsByType, true
        );
    });

    return {
        vesselCii,
        voyageVmsFootprint,
        summaryReport: getSummary(state),
        dailyReports: convertReports(reportData.reports.daily, reportData.typesAndGrades.daily),
        arrivalReports: convertReports(reportData.reports.arrival, reportData.typesAndGrades.arrival),
        departureReports: convertReports(reportData.reports.departure, reportData.typesAndGrades.departure),
        cargoReports: convertReports(reportData.reports.cargo, reportData.typesAndGrades.cargo),
        eventReports: convertReports(reportData.reports.event, reportData.typesAndGrades.event),
        sofReports: convertReports(reportData.reports.sof, reportData.typesAndGrades.sof),
        vesselName,
        ...voyageRangeParams,
        orbitLogo: getLogoBase64().dark,
        companyLogo,
        rowConfigs
    };
}

function* generateXLSX() {
    yield put({ type: 'TOGGLE_LOADER', payload: true });
    yield delay(0);
    const reportData = yield prepareDataForReports();
    yield generateVoyageReportsXLSX(reportData);
    yield put({ type: 'TOGGLE_LOADER', payload: false });
}

function* generatePDF() {
    const reportData = yield prepareDataForReports();
    const htmlString = ReactDOMServer.renderToStaticMarkup(
        <VoyageReportPDF {...reportData} />
    );

    PdfGeneratorService.convert(
        { html: htmlString, viewport: { width: 1920, height: 1080 } },
        `${t('ENERGY_MANAGEMENT.REPORTS.PERFORMANCE_SUMMARY')}_${reportData.vesselName}-${reportData.voyageNumber}`
    );
}

function* getCalculatedVoyageReport(action) {
    yield put({ type: VRActionTypes.VESSEL_REPORT_GET_CONFIGS });
    const {
        selectedVoyage,
        selectedRange,
        rangeSelectorActive,
        selectedVessel,
        inclusions
    } = yield select(state => state.energyManagementReportsReducer);
    const params = {
        Inclusions: {
            ...getConvertedObject(inclusions, inclusionPropTypes),
            VesselImo: selectedVessel.Imo
        },
        AreMissingReportsVisible: true,
        AreExcludedReportsVisible: true,
        AreIncludedReportsVisible: true,
        AreOnlySuspiciousReportsVisible: false
    };
    if (rangeSelectorActive) {
        params.DateFrom = selectedRange?.rangeStart;
        params.DateTo = selectedRange?.rangeEnd;
    } else {
        params.VoyageId = selectedVoyage?.VoyageId || null;
    }
    const summaryReport = yield EnergyManagementService.Reports.VoyageReport.getStatistics(params);
    const permissions = yield select(state => state.userReducer.permissions);
    let vesselCii = null;
    let voyageVmsFootprint = null;
    if (permissions?.VesselFootprint) {
        if (ConfigService.featureToggles.showFootprint
            && !ConfigService.hiddenFeatures.voyageReportVesselCiiRating
            && selectedVessel.Imo) {
            vesselCii = yield VesselService.getCiiGradePerYears({
                IMO: selectedVessel.Imo,
                endYear: new Date().getFullYear()
            });
            vesselCii = mapArrayByProp(vesselCii, 'Year');
        }
        if (ConfigService.featureToggles.showVmsFootprint
            && !ConfigService.hiddenFeatures.voyageReportVmsVoyageEmissions
            && params.VoyageId) {
            voyageVmsFootprint = yield VoyageService.getVmsFootprint(params.VoyageId);
        }
    }
    yield put({
        type: ActionTypes.ENERGY_MANAGEMENT_VOYAGE_REPORT_SET,
        vesselCii,
        voyageVmsFootprint,
        summaryReport: LocalStorageService.isDebugModeActive()
            ? {
                ...summaryReport,
                Footprint: yield getMockedVoyageFootprint(summaryReport.AvailableFuelTypes)
            }
            : summaryReport,
        isRecalculate: action.isRecalculate
    });
}

export default function* energyManagementReportsVoyageReportSaga() {
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_VOYAGE_REPORT_GENERATE, getCalculatedVoyageReport);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_VOYAGE_REPORT_GENERATE_PDF, generatePDF);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_VOYAGE_REPORT_GENERATE_XLSX, generateXLSX);
}
