import React from 'react';
import { formatNumber } from '../../utils/helpers/info-helper';

export default function withNumberFormat(Component) {
    return class extends React.Component {
        formatValue(value, formatProps) {
            if (!value) {
                return '-';
            }

            if (!formatProps) {
                return formatNumber(value);
            }

            return formatNumber(
                value,
                formatProps.decimals,
                formatProps.showPlus,
                formatProps.commaSeparator,
                formatProps.thousandsSeparator
            );
        }

        render() {
            return (
                <Component {...this.props} formatValue={this.formatValue} />
            );
        }
    };
}
