import { ActionTypes } from './notification-settings-actions';

const emptyArray = [];

const initialState = {
    settings: emptyArray,
    companyFleets: emptyArray,
    customFleets: emptyArray
};

export default function notificationSettingsReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.NOTIFICATION_SETTINGS_SET:
        return {
            ...state,
            settings: action.settings
        };
    case ActionTypes.NOTIFICATION_SETTINGS_SET_FLEETS:
        return {
            ...state,
            companyFleets: action.companyFleets
        };
    case ActionTypes.NOTIFICATION_SETTINGS_SET_CUSTOM_FLEETS:
        return {
            ...state,
            customFleets: action.customFleets
        };
    default:
        return state;
    }
}
