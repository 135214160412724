import CoreApi from './core-api';

class ReportService {
    constructor() {
        this.resourceUrl = 'Reports';
        this.reportTypeMap = {
            1: '/Daily',
            2: '/Departure',
            3: '/Arrival',
            4: '/Cargo',
            5: '/Event',
            6: '/NextVoyage',
            7: '/Sof'
        };
    }

    get(params) {
        return CoreApi.get(this.resourceUrl, params).then(response => response.data, () => null);
    }

    getById(id, reportTypeId, params) {
        return CoreApi.get(`${this.resourceUrl}${this.reportTypeMap[reportTypeId]}/${id}`, params)
            .then(response => response.data, () => null);
    }

    getFuelTypes() {
        return CoreApi.get(`${this.resourceUrl}/FuelTypes `)
            .then(response => response.data, () => null);
    }

    sendReminder(MissingReportId) {
        return CoreApi.post(`${this.resourceUrl}/SendReminder`, { MissingReportId })
            .then(response => response.data, () => null);
    }

    update(id, reportTypeId, data) {
        return CoreApi.put(`${this.resourceUrl}${this.reportTypeMap[reportTypeId]}/${id}`, data)
            .then(response => response.data, () => null);
    }

    remove(reportId) {
        return CoreApi.remove(`${this.resourceUrl}/${reportId}`).then(() => true, () => false);
    }

    getCardinalDirections() {
        return CoreApi.get(`${this.resourceUrl}/CardinalDirections`)
            .then(response => response.data, () => null);
    }

    getBeaufortScale() {
        return CoreApi.get(`${this.resourceUrl}/BeaufortScale`)
            .then(response => response.data, () => null);
    }

    getConsumptionTypesAndGroups() {
        return CoreApi.get(`${this.resourceUrl}/ConsumptionTypesAndGroups`)
            .then(response => response.data, () => null);
    }

    getConsumptionTypesPerGroup(reportTypeId, reportSubtypeId) {
        const params = { ReportTypeId: reportTypeId, ReportSubtypeId: reportSubtypeId };
        return CoreApi.get(`${this.resourceUrl}/ConsumptionTypesPerGroup`, params)
            .then(response => response.data, () => null);
    }

    getBunkerTypesOld(reportTypeId, reportSubtypeId) {
        const params = { ReportTypeId: reportTypeId, ReportSubtypeId: reportSubtypeId };
        return CoreApi.get(`${this.resourceUrl}/BunkerTypes`, params)
            .then(response => response.data, () => null);
    }

    getBunkerTypes() {
        return CoreApi.get(`${this.resourceUrl}/BunkerTypesV2`)
            .then(response => response.data, () => null);
    }

    getEventTypes() {
        return CoreApi.get(`${this.resourceUrl}/EventTypes`)
            .then(response => response.data, () => null);
    }

    getNavigationTypes() {
        return CoreApi.get(`${this.resourceUrl}/NavigationTypes`)
            .then(response => response.data, () => null);
    }

    getTugReasons() {
        return CoreApi.get(`${this.resourceUrl}/Departure/TugReasons`)
            .then(response => response.data, () => null);
    }

    getSpeedInstructionTypes() {
        return CoreApi.get(`${this.resourceUrl}/SpeedInstructionTypes`)
            .then(response => response.data, () => null);
    }

    getNextLegReports(voyageId) {
        return CoreApi.get(`${this.resourceUrl}/NextVoyageReports/${voyageId}`)
            .then(response => response.data, () => null);
    }

    getSpecialAreas() {
        return CoreApi.get(`${this.resourceUrl}/SpecialAreas`)
            .then(response => response.data, () => null);
    }
}

export default new ReportService();
