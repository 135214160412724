import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { sortByProp } from 'utils/helpers/array-helper';
import { formatNumber } from 'utils/helpers/info-helper';
/* components */
import Input from 'components/input/input';
import Validation from 'components/validation/validation';
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
import TableHeadCell from 'components/table-head-cell/table-head-cell';
/* styles */
import './consumption-budget-table.scss';

const consumptionEmptyRow = {
    FuelConsumption: null,
    Speed: null
};

const consumptionSpeedValidation = {
    required: true,
    numeric: { min: 0, max: 30, decimals: 2 },
    custom: {
        rule: (value, params, components, name) => !Object.keys(components)
            .some(key => key.indexOf('ConsumptionSpeed') === 0 && key !== name
                && parseFloat(components[key].state.value) === parseFloat(value)),
        hint: () => 'Must be a unique value in list'
    }
};

let cachedConsumption = {
    data: null,
    hints: {}
};
const fuelConsumptionValidation = {
    required: true,
    numeric: { min: 0, max: 100, decimals: 2 },
    consumption: {
        rule: (value, params, components, name) => {
            if (name.indexOf('FuelConsumption.0') === 0) {
                cachedConsumption = {
                    data: null,
                    hints: {}
                };
            }
            const data = cachedConsumption.data || Object.keys(components)
                .reduce((arr, key) => {
                    if (key.indexOf('FuelConsumption') === 0) {
                        const index = Number(key.split('.')[1]);
                        const ConsumptionSpeedIndex = `ConsumptionSpeed.${index}`;
                        arr.push({
                            Speed: components[ConsumptionSpeedIndex]
                                    && parseFloat(components[ConsumptionSpeedIndex].state.value),
                            FuelConsumption: components[key] && parseFloat(components[key].state.value),
                            key
                        });
                    }
                    return arr;
                }, [])
                .sort(sortByProp('Speed'));
            if (!cachedConsumption.data) {
                cachedConsumption.data = data;
            }
            const current = data.find(d => d.key === name);
            const currentIndex = data.indexOf(current);
            const previous = data[currentIndex - 1];
            if (currentIndex !== 0 && data.length && current.FuelConsumption < previous.FuelConsumption) {
                cachedConsumption.hints[name] = {
                    previousSpeed: previous.Speed,
                    currentSpeed: current.Speed
                };
                return false;
            }
            return true;
        },
        hint: (value, params, components, name) => {
            if (cachedConsumption.hints && cachedConsumption.hints[name]) {
                const { previousSpeed, currentSpeed } = cachedConsumption.hints[name];
                return `Fuel consumption for speed ${
                    currentSpeed} must be higher then fuel consumption for speed ${previousSpeed}`;
            }
            return 'Must be higher than previous Fuel Consumption';
        }
    }
};

const consumptionBudgetValidation = {
    custom: {
        rule: (value, params, components) => {
            if (components.OptimizationType
                && components.OptimizationType.state.value
                && components.OptimizationType.state.value.Name !== 'Time'
                && !components['FuelConsumption.1']) {
                return false;
            }
            if (components['FuelConsumption.0'] && !components['FuelConsumption.1']) {
                return false;
            }
            return true;
        },
        hint: () => 'Budget consumption must have at least two values set'
    }
};

class ConsumptionBudgetTable extends React.Component {
    validations = {
        ConsumptionBudget: { ...consumptionBudgetValidation },
        ConsumptionSpeed: { ...consumptionSpeedValidation },
        FuelConsumption: { ...fuelConsumptionValidation }
    }

    handleChange = (type, index, value) => {
        const budget = [...this.props.data];
        budget[index][type] = value && value.trim().length !== 0 && !isNaN(value) ? parseFloat(value) : value;
        this.props.onChange(budget);
    }

    addRow = () => {
        const budget = [...this.props.data];
        budget.push({ ...consumptionEmptyRow });
        this.props.onChange(budget);
    }

    removeRow = (index) => {
        const budget = [...this.props.data];
        budget.splice(index, 1);
        this.props.onChange(budget);
    }

    render() {
        const { editable, data, sortAscending } = this.props;
        return (
            <React.Fragment>
                <FixedHeaderTable
                    className="consumption-budget-table"
                    contentClassName="consumption-budget-table__content"
                >
                    <table className="sten-table sten-table--xs">
                        <thead>
                            <tr>
                                <TableHeadCell
                                    className="text-secondary"
                                    sortable={editable}
                                    sortOrder="ASC"
                                    onClick={editable ? sortAscending : undefined}
                                >
                                    {t('WEATHER_ROUTING.REQUEST.CONSUMPTION_SPEED')}
                                </TableHeadCell>
                                <th className="text-secondary">
                                    {t('WEATHER_ROUTING.REQUEST.FUEL_CONSUMPTION')}
                                </th>
                                {editable && <th className="text-secondary" />}
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.length !== 0 && data.map((val, index) => (
                                /* eslint-disable react/no-array-index-key */
                                <tr key={`tr${index}`}>
                                    <td className={editable ? 'sten-table__td--has-input' : ''}>
                                        {editable ? (
                                            <Validation.Wrapper
                                                hintsOnHover
                                                hintsOnTop
                                                validations={this.validations.ConsumptionSpeed}
                                            >
                                                <Input
                                                    className="sten-input--sm"
                                                    value={val.Speed}
                                                    name={`ConsumptionSpeed.${index}`}
                                                    onChange={this.handleChange.bind(this, 'Speed', index)}
                                                />
                                            </Validation.Wrapper>
                                        ) : formatNumber(val.Speed, 2)}
                                    </td>
                                    <td className={editable ? 'sten-table__td--has-input' : ''}>
                                        {editable
                                            ? (
                                                <Validation.Wrapper
                                                    hintsOnHover
                                                    hintsOnTop
                                                    validations={this.validations.FuelConsumption}
                                                >
                                                    <Input
                                                        className="sten-input--sm"
                                                        value={val.FuelConsumption}
                                                        name={`FuelConsumption.${index}`}
                                                        onChange={this.handleChange.bind(
                                                            this, 'FuelConsumption', index
                                                        )}
                                                    />
                                                </Validation.Wrapper>
                                            ) : formatNumber(val.FuelConsumption, 2)}
                                    </td>
                                    {editable && (
                                        <td className="consumption-budget-table__delete">
                                            <div className="flex-shrink">
                                                <button
                                                    onClick={this.removeRow.bind(this, index)}
                                                    className="icon icon-delete btn-link"
                                                    type="button"
                                                />
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </FixedHeaderTable>
                {editable && (
                    <Validation.Wrapper
                        className="sten-content__section"
                        validations={this.validations.ConsumptionBudget}
                        hideWhileValid
                    >
                        <Validation.Value value={data} name="ConsumptionBudget" />
                    </Validation.Wrapper>
                )}
                {editable && (
                    <div className="sten-content__section">
                        <button
                            type="button"
                            className="btn-link btn-link--inverted text-uppercase"
                            onClick={this.addRow.bind(this)}
                        >
                            <span className="btn__icon icon icon-plus" />{t('WEATHER_ROUTING.REQUEST.ADD_VALUES')}
                        </button>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

ConsumptionBudgetTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.any),
    onChange: PropTypes.func,
    sortAscending: PropTypes.func,
    editable: PropTypes.bool
};

ConsumptionBudgetTable.defaultProps = {
    data: null,
    onChange: undefined,
    sortAscending: undefined,
    editable: false
};

export default ConsumptionBudgetTable;
