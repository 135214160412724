import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import ReportHelper from 'utils/helpers/report-helper';
import { translate } from 'utils/i18n/i18n-model';
/* components */
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
/* constants */
import { colWidths } from './fleet-list-report-constants';
/* styles */
import './fleet-list-report.scss';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.FLEET_LIST_REPORT.${key}`);

class FleetListReportTable extends React.PureComponent {
    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.fixedHeaderTable.scrollArea.scrollLeft();
            this.fixedHeaderTable.scrollArea.refresh();
        }
    }

    renderDataRow = (data, key, label, columns) => (
        <tr key={key}>
            <th className="text-center">{label}</th>
            {columns.map(col => {
                return (
                    <td className="text-center" key={col.key}>
                        {ReportHelper.formatValue(data, col)}
                    </td>
                );
            })}
        </tr>
    );

    saveRef = c => { this.fixedHeaderTable = c; };

    render() {
        const { parsedData, columns } = this.props.data;

        const contentMinWidth = parseInt(
            Object.keys(colWidths).reduce((sum, key) => sum + colWidths[key].web, 0), 10
        );

        return (
            <FixedHeaderTable
                className="sten-energy-management-reports-fleet-list-report-table"
                contentStyle={{ minWidth: `${contentMinWidth}rem` }}
                updateTimeout={200}
                withHeaderColumn
                useCSS3Translate={false}
                ref={this.saveRef}
            >
                <table className="sten-table sten-table--xs">
                    <thead>
                        <tr>
                            <th className="text-center" style={{ minWidth: `${colWidths.VesselName.web}rem` }}>
                                {t('VESSEL')}
                            </th>
                            <th className="text-center" style={{ minWidth: `${colWidths.Imo.web}rem` }}>{
                                t('IMO_NUMBER')}
                            </th>
                            <th className="text-center" style={{ minWidth: `${colWidths.DWT.web}rem` }}>
                                {t('DWT')}
                            </th>
                            <th className="text-center" style={{ minWidth: `${colWidths.YearBuilt.web}rem` }}>
                                {t('YEAR_BUILT')}
                            </th>
                            <th className="text-center" style={{ minWidth: `${colWidths.LOA.web}rem` }}>
                                {t('LOA')}
                            </th>
                            <th className="text-center" style={{ minWidth: `${colWidths.Beam.web}rem` }}>
                                {t('BEAM')}
                            </th>
                            <th className="text-center" style={{ minWidth: `${colWidths.Draft.web}rem` }}>
                                {t('DRAFT')}
                            </th>
                            <th className="text-center" style={{ minWidth: `${colWidths.NumberOfTanks.web}rem` }}>
                                {t('NUMBER_OF_TANKS')}
                            </th>
                            <th className="text-center" style={{ minWidth: `${colWidths.HasScrubber.web}rem` }}>
                                {t('SCRUBBER')}
                            </th>
                            <th className="text-center" style={{ minWidth: `${colWidths.Coating.web}rem` }}>
                                {t('COATING')}
                            </th>
                            <th className="text-center" style={{ minWidth: `${colWidths.IceClass.web}rem` }}>
                                {t('ICE_CLASS')}
                            </th>
                            <th className="text-center" style={{ minWidth: `${colWidths.Flag.web}rem` }}>
                                {t('FLAG')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {parsedData.map(vessel => this.renderDataRow(
                            vessel, vessel.Imo, vessel.VesselName, columns
                        ))}
                    </tbody>
                </table>
            </FixedHeaderTable>
        );
    }
}

FleetListReportTable.propTypes = {
    data: PropTypes.shape({
        columns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        parsedData: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
    })
};

FleetListReportTable.defaultProps = {
    data: null
};

export default FleetListReportTable;
