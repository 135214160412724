import { ActionTypes } from './voyage-info-actions';

const emptyArray = [];

const initialState = {
    isMounted: false,
    voyage: null,
    itinerary: emptyArray,
    cargo: null,
    comments: emptyArray
};

export default function voyageInfoReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.VOYAGE_INFO_SET_RESOURCES: {
        return {
            ...state,
            isMounted: true,
            voyage: action.voyage,
            itinerary: action.itinerary || emptyArray,
            cargo: action.cargo,
            comments: action.comments || emptyArray
        };
    }
    case ActionTypes.VOYAGE_INFO_SET_COMMENTS: {
        return {
            ...state,
            comments: action.comments
        };
    }
    case ActionTypes.VOYAGE_INFO_RESET: {
        return initialState;
    }
    default:
        return state;
    }
}
