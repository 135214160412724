/* utils */
import MockHelper from 'utils/helpers/mock-helper';
import TimeHelper from 'utils/helpers/time-helper';
import { mapLonLat, wktParse } from 'components/ol/ol-helpers';
import { sortAsNumber, sortByProp } from 'utils/helpers/array-helper';
import { roundNumber, waitForCondition } from 'utils/helpers/info-helper';
import { calculateCourse, extractConfigurationRelations } from './weather-routing-helpers';
/* services */
import PortService from 'services/core-api/port-service';

const tU = TimeHelper.units;

export const optimizationTypes = [
    { Id: 1, Name: 'Time', DisplayName: 'Time', Sequence: 1 },
    { Id: 3, Name: 'Fuel', DisplayName: 'Fuel', Sequence: 2 },
    { Id: 2, Name: 'Cost', DisplayName: 'Cost', Sequence: 3 }
];

export const requestTypes = [
    { Id: 3, Name: 'None', DisplayName: 'None' },
    { Id: 1, Name: 'RoutingAndPerformance', DisplayName: 'Daily performance' },
    { Id: 2, Name: 'RoutingPerformanceAndPvar', DisplayName: 'DTN PVAR + Daily performance' }
];

export const routeCalculationTypes = [
    { Id: 1, Name: 'InstructedSpeed', DisplayName: 'Voyage Speed' },
    { Id: 2, Name: 'SpeedRange', DisplayName: 'Speed Range' }
];

export const vesselConditions = [
    { Id: 1, Name: 'Ballast' },
    { Id: 2, Name: 'Laden' }
];

export const conditionalAreas = [
    { Id: 234, Name: 'Suez Canal' },
    { Id: 235, Name: 'Kiel Canal' },
    { Id: 236, Name: 'Panama Canal' }
];

const requestStatuses = {
    Requested: { Id: 1, Name: 'Requested', DisplayName: 'Route Requested' },
    Ongoing: { Id: 2, Name: 'Ongoing', DisplayName: 'Active Routing' },
    Completed: { Id: 3, Name: 'Completed', DisplayName: 'Completed Routing' },
    Suspended: { Id: 4, Name: 'Suspended', DisplayName: 'Suspended' },
    // Deleted: { Id: 5, Name: 'Deleted', DisplayName: 'Deleted' },
    Error: { Id: 6, Name: 'Error', DisplayName: 'Error' },
    RouteReady: { Id: 7, Name: 'RouteReady', DisplayName: 'Route Ready' },
    Stopped: { Id: 8, Name: 'Stopped', DisplayName: 'Stopped' }
};

const requestSources = [
    { Id: 1, Name: 'AutomaticWeatherApi', DisplayName: 'Automatic' },
    { Id: 2, Name: 'ConsultantWeatherApi', DisplayName: 'Consultant' }
];

const intentionTypes = [
    { Id: 1, Name: 'NotIntended', DisplayName: 'Not Intended' },
    { Id: 2, Name: 'Intended', DisplayName: 'Intended' },
    { Id: 3, Name: 'HistoricalIntended', DisplayName: 'Historical Intended' }
];

const OptimizationCalculationRelations = [{
    OptimizationTypeId: 1,
    OptimizationTypeName: 'Time',
    RouteCalculationTypeId: 1,
    RouteCalculationTypeName: 'InstructedSpeed',
    RequestSourceId: 2
}, {
    OptimizationTypeId: 3,
    OptimizationTypeName: 'Fuel',
    RouteCalculationTypeId: 1,
    RouteCalculationTypeName: 'InstructedSpeed',
    RequestSourceId: 2
}, {
    OptimizationTypeId: 2,
    OptimizationTypeName: 'Cost',
    RouteCalculationTypeId: 1,
    RouteCalculationTypeName: 'InstructedSpeed',
    RequestSourceId: 2
}, {
    OptimizationTypeId: 3,
    OptimizationTypeName: 'Fuel',
    RouteCalculationTypeId: 2,
    RouteCalculationTypeName: 'SpeedRange',
    RequestSourceId: 2
}, {
    OptimizationTypeId: 2,
    OptimizationTypeName: 'Cost',
    RouteCalculationTypeId: 2,
    RouteCalculationTypeName: 'SpeedRange',
    RequestSourceId: 2
}, {
    OptimizationTypeId: 1,
    OptimizationTypeName: 'Time',
    RouteCalculationTypeId: 1,
    RouteCalculationTypeName: 'InstructedSpeed',
    RequestSourceId: 1
}, {
    OptimizationTypeId: 2,
    OptimizationTypeName: 'Cost',
    RouteCalculationTypeId: 1,
    RouteCalculationTypeName: 'InstructedSpeed',
    RequestSourceId: 1
}, {
    OptimizationTypeId: 3,
    OptimizationTypeName: 'Fuel',
    RouteCalculationTypeId: 1,
    RouteCalculationTypeName: 'InstructedSpeed',
    RequestSourceId: 1
}, {
    OptimizationTypeId: 2,
    OptimizationTypeName: 'Cost',
    RouteCalculationTypeId: 2,
    RouteCalculationTypeName: 'SpeedRange',
    RequestSourceId: 1
}, {
    OptimizationTypeId: 3,
    OptimizationTypeName: 'Fuel',
    RouteCalculationTypeId: 2,
    RouteCalculationTypeName: 'SpeedRange',
    RequestSourceId: 1
}];

const mockConsumptionBudget = () => {
    let fuelConsumption = 0;
    return MockHelper.getRandomArrayOfItems(4, 4, null, (index) => {
        const percentage = MockHelper.getRandomNumber(95, 98) / 100;
        fuelConsumption = index === 0
            ? MockHelper.getRandomNumber(30, 80)
            : Math.round(fuelConsumption * percentage);
        return {
            Id: MockHelper.getUniqueId('ConsumptionBudgetId'),
            FuelConsumption: fuelConsumption,
            Speed: 14 - (index / 2)
        };
    });
};

const otherStatusKeys = ['Requested', 'RouteReady', 'Error'];

const mockRequestStatus = (intention, source, createdAt, eta, currentDate) => {
    if (intention.Name === 'Intended') {
        if (eta > currentDate) {
            return requestStatuses.Ongoing;
        }
        return requestStatuses.Completed;
    }
    if (intention.Name === 'HistoricalIntended') {
        if (source.Name === 'ConsultantWeatherApi') {
            return requestStatuses.Suspended;
        }
        return requestStatuses.Stopped;
    }
    const filteredStatuses = TimeHelper.getDateDiff(createdAt, currentDate) > 2 * tU.day
        ? otherStatusKeys.slice(1) // can't be Requested if older than a day
        : otherStatusKeys;

    return requestStatuses[MockHelper.getRandomArrayItem(filteredStatuses, 0, 0)];
};

const getWaypointLimits = (p1, p2) => {
    const xLim = [p1.Coordinates[0], p2.Coordinates[0]].sort(sortAsNumber());
    const yLim = [p1.Coordinates[1], p2.Coordinates[1]].sort(sortAsNumber());
    return {
        xLimit: [xLim[0] - 10, xLim[1] + 10],
        yLimit: [yLim[0] - 5, yLim[1] + 5]
    };
};

const remapPort = (port) => ({
    Name: port.Name,
    PortId: port.Id,
    PortCode: port.PortCode,
    Position: port.Location,
    ImportanceLevel: port.ImportanceLevel,
    Type: port.Type,
    Coordinates: wktParse(port.Location)
});

export const mockedEditingOptions = {
    vesselConditions,
    configurationRelations: extractConfigurationRelations(OptimizationCalculationRelations),
    optimizationTypes,
    routeCalculationTypes,
    requestTypes,
    requestSources
};

const mockedData = {
    CurrentDate: null,
    Voyages: [],
    VoyagesById: {},
    VoyagesByVesselImo: {},
    AisPointsByVoyageId: {},
    LegsByVoyageId: {},
    LegsById: {},
    RequestsByLegId: {},
    RequestsById: {},
    SposByLegId: {}
};

let isDataMocked = false;

const mockVoyages = (currentDate, ports) => {
    return MockHelper.getRandomArrayOfItems(100, 200, null, () => {
        const maxEndDate = TimeHelper.getDateFromDiff(currentDate, 2 * tU.week);
        const minEndDate = TimeHelper.getDateFromDiff(currentDate, -3 * tU.month);
        const EndDate = MockHelper.getRandomDate(minEndDate, maxEndDate, 0, 0.75);
        const maxStartDate = TimeHelper.getDateFromDiff(EndDate, -tU.week);
        const minStartDate = TimeHelper.getDateFromDiff(EndDate, -3 * tU.week);
        const StartDate = MockHelper.getRandomDate(minStartDate, maxStartDate);
        const randomVessel = MockHelper.getRandomVessel();
        const portOfDeparture = MockHelper.getRandomArrayItem(ports);
        const portOfDestination = MockHelper.getRandomArrayItem(ports);
        return {
            Voyage: {
                StartDate,
                EndDate,
                PortOfDeparture: remapPort(portOfDeparture),
                PortOfDestination: remapPort(portOfDestination)
            },
            Vessel: {
                Imo: randomVessel.Imo,
                VesselName: randomVessel.Title,
                Deadweight: randomVessel.DWT,
                LengthOverall: MockHelper.getRandomNumber(100, 600),
                Beam: MockHelper.getRandomNumber(10, 100, 1),
                Mmsi: MockHelper.getUniqueId('vesselMmsi', 100000000),
                VesselSegment: {
                    Id: randomVessel.VesselTypeId,
                    Name: randomVessel.VesselTypeName,
                    DisplayName: randomVessel.VesselTypeName
                }
            }
        };
    }).sort(sortByProp('Voyage.StartDate')).map(voyage => {
        const VoyageNumber = MockHelper.getUniqueId(`VoyageNumber${voyage.Vessel.Imo}`, 2201);
        const Id = MockHelper.getUniqueId('VoyageId');
        mockedData.VoyagesById[Id] = {
            ...voyage,
            Voyage: { ...voyage.Voyage, Id, VoyageNumber }
        };
        if (!mockedData.VoyagesByVesselImo[voyage.Vessel.Imo]) {
            mockedData.VoyagesByVesselImo[voyage.Vessel.Imo] = [];
        }
        mockedData.VoyagesByVesselImo[voyage.Vessel.Imo].push(mockedData.VoyagesById[Id]);
        return mockedData.VoyagesById[Id];
    }).sort(sortByProp('Voyage.EndDate', false));
};

const mockVoyageLegs = (voyage, currentDate, ports) => {
    let Eta;
    let EtaOffset;
    let Etd;
    let EtdOffset;
    const legs = MockHelper.getRandomArrayOfItems(0, 5, 0.4, (index, length, prevItem) => {
        Etd = Eta || voyage.Voyage.StartDate;
        EtdOffset = EtaOffset
            || TimeHelper.getUTCOffsetString(MockHelper.getRandomNumber(-12, 12) * 60, false);
        Eta = MockHelper.getRandomDate(Etd, voyage.Voyage.EndDate, 0, 1 / (length - index));
        EtaOffset = TimeHelper.getUTCOffsetString(MockHelper.getRandomNumber(-12, 12) * 60, false);
        const PortOfDeparture = prevItem ? prevItem.PortOfDestination : voyage.Voyage.PortOfDeparture;
        const PortOfDestination = index + 1 < length
            ? remapPort(MockHelper.getRandomArrayItem(ports))
            : voyage.Voyage.PortOfDestination;
        const Id = MockHelper.getUniqueId('LegId');
        mockedData.LegsById[Id] = {
            Id,
            VesselCondition: prevItem && prevItem.VesselCondition.Name === 'Ballast'
                ? vesselConditions[1]
                : vesselConditions[0],
            ...voyage,
            Etd,
            EtdOffset,
            Eta,
            EtaOffset,
            PortOfDeparture,
            PortOfDestination
        };
        return mockedData.LegsById[Id];
    });
    let legSlice = legs.length;
    if (voyage.Voyage.StartDate > currentDate) {
        // future legs will probably have only one or no legs routed
        legSlice = MockHelper.getRandomNumber(0, legSlice, 0, 0, 0);
    } else if (voyage.Voyage.EndDate > currentDate) {
        // current requests will most likely not have all legs routed
        legSlice = MockHelper.getRandomNumber(0, legSlice, 0, 0, 0.8);
    }
    return legs.slice(0, legSlice).reverse();
};

const intentionOrderMap = {
    Intended: 1,
    HistoricalIntended: 2,
    NotIntended: 3
};

const sortByIntentionAndCreatedAt = (a, b) => {
    if (intentionOrderMap[a.Intention.Name] < intentionOrderMap[b.Intention.Name]) {
        return -1;
    }
    if (intentionOrderMap[a.Intention.Name] > intentionOrderMap[b.Intention.Name]) {
        return 1;
    }
    if (a.CreatedAt > b.CreatedAt) {
        return -1;
    }
    if (a.CreatedAt < b.CreatedAt) {
        return 1;
    }
    return 0;
};

const mockSposRoutes = (leg) => {
    const minSposRoutes = mockedData.RequestsByLegId[leg.Id].length > 0 ? 0 : 1;
    return MockHelper.getRandomArrayOfItems(minSposRoutes, 3, 0, () => {
        const DepartureDate = TimeHelper.getDateFromDiff(leg.Etd, MockHelper.getRandomNumber(-tU.day, tU.day));
        const DestinationDate = TimeHelper.getDateFromDiff(leg.Eta, MockHelper.getRandomNumber(-tU.day, tU.day));
        const Points = MockHelper.getRandomRouteBetweenTwoPoints(
            leg.PortOfDeparture.Coordinates,
            leg.PortOfDestination.Coordinates,
            2,
            0.01
        );
        const dateDiff = Math.floor(TimeHelper.getDateDiff(DepartureDate, DestinationDate) / (tU.day));
        const totalDistance = MockHelper.getRandomNumber(dateDiff * 200, dateDiff * 300);
        const sectionDuration = Math.floor(
            TimeHelper.getDateDiff(DepartureDate, DestinationDate) / (Points.length - 1)
        );
        const sectionDistances = MockHelper.getRandomDivisions(totalDistance, Points.length - 1, 0.1, 1);
        let Time;
        return {
            Id: MockHelper.getUniqueId('sposRoute'),
            VoyageId: leg.Voyage.VoyageId,
            VoyageNumber: leg.Voyage.VoyageNumber,
            Imo: leg.Vessel.Imo,
            VesselCondition: leg.VesselCondition,
            From: leg.PortOfDeparture.Name,
            Destination: leg.PortOfDestination.Name,
            DepartureDate,
            DestinationDate,
            VesselPosition: null,
            VesselPositionTime: MockHelper.getRandomDate(leg.Etd, leg.Eta),
            ReportedAt: MockHelper.getRandomDate(leg.Etd, leg.Eta),
            Waypoints: Points.map((point, index) => {
                Time = index > 0
                    ? TimeHelper.getDateFromDiff(Time, sectionDuration)
                    : DepartureDate;
                return {
                    Sequence: index,
                    Position: { Latitude: point[1], Longitude: point[0] },
                    Time,
                    SpeedOverGround: index > 0
                        ? roundNumber(sectionDistances[index - 1] / (sectionDuration / tU.hour), 1)
                        : 0,
                    SpeedThroughWater: MockHelper.getRandomNumber(11, 14, 1),
                    Weather: null
                };
            })
        };
    }).sort(sortByProp('VesselPositionTime', false));
};

const mockLegRequests = (leg, currentDate, ports) => {
    // in order to have 'HistoricalIntended' requests, it needs to have one "Intended"
    let filteredIntentionTypes = intentionTypes.filter(it => it.Name !== 'HistoricalIntended');
    let prevIntendedEtd;
    return MockHelper.getRandomArrayOfItems(0, 10, null, () => {
        const minSpeedDouble = MockHelper.getRandomNumber(20, 30, 0, 0, 0.2);
        const maxSpeed = MockHelper.getRandomNumber(minSpeedDouble, 30) / 2;
        const minSpeed = minSpeedDouble / 2;
        const Waypoints = MockHelper.getRandomArrayOfItems(0, 2, null, (i, l, prev) => {
            const Port = MockHelper.getRandomArrayItem(ports, 0.8);
            const Eta = MockHelper.getRandomDate(prev ? prev.Etd : leg.Etd, leg.Eta, null, 1 / (l - i + 1));
            return {
                Eta,
                Etd: TimeHelper.getDateFromDiff(Eta, MockHelper.getRandomNumber(tU.hour, tU.day)),
                Position: !Port
                    ? MockHelper.getRandomWktPoint(getWaypointLimits(leg.PortOfDeparture, leg.PortOfDestination))
                    : Port.Location,
                PortId: Port ? Port.Id : null
            };
        });
        const OptimizationType = MockHelper.getRandomArrayItem(optimizationTypes);
        const shouldSetCost = OptimizationType.Name === 'Cost' || MockHelper.getRandomBoolean();
        // routeCalculationType can be InstructedSpeed only if optimizationType is fuel
        const RouteCalculationType = OptimizationType.Name === 'Fuel'
            ? routeCalculationTypes[0] // InstructedSpeed
            : MockHelper.getRandomArrayItem(routeCalculationTypes);
        const latestDate = leg.Eta < currentDate ? leg.Eta : currentDate;
        const earliestDate = leg.Etd < latestDate ? leg.Etd : TimeHelper.getDateFromDiff(latestDate, -5 * tU.day);
        let CreatedAt = MockHelper.getRandomDate(earliestDate, latestDate, 0, 0);
        const Source = MockHelper.getRandomArrayItem(requestSources, 0, 0);
        const Intention = MockHelper.getRandomArrayItem(filteredIntentionTypes, 0, 0);
        if (Intention.Name === 'Intended') {
            // there can only be one 'Intended', and after it, there can be multiple "HistoricalIntended"
            filteredIntentionTypes = intentionTypes.filter(it => it.Name !== 'Intended');
            prevIntendedEtd = CreatedAt;
        } else if (Intention.Name === 'HistoricalIntended') {
            CreatedAt = MockHelper.getRandomDate(earliestDate, prevIntendedEtd);
            prevIntendedEtd = CreatedAt;
        }
        const Status = mockRequestStatus(Intention, Source, CreatedAt, leg.Eta, currentDate);
        let dateDiff = Math.floor(TimeHelper.getDateDiff(CreatedAt, latestDate) / (tU.day));
        if (Intention.Name === 'NotIntended') {
            dateDiff = Status.Name === 'RouteReady' ? 1 : 0;
        } else if (Intention.Name === 'Intended' && !dateDiff) {
            dateDiff = 1;
        }
        const Id = MockHelper.getUniqueId('routingRequest');
        let Timestamp;
        let routeEta = leg.Eta;
        let DistanceToGo;
        const templateType = MockHelper.getRandomNumber(0, 2);
        mockedData.RequestsById[Id] = {
            Id,
            LegId: leg.Id,
            Voyage: leg.Voyage,
            Vessel: leg.Vessel,
            VesselCondition: leg.VesselCondition,
            OptimizationType,
            RouteCalculationType,
            Source,
            Intention,
            Status,
            CreatedAt,
            ConditionalAreas: Source.Name === 'ConsultantWeatherApi'
                ? null
                : {
                    ForbidHighRiskAreas: MockHelper.getRandomBoolean(0, 0.1),
                    ForbidEcaZones: MockHelper.getRandomBoolean(0, 0.1),
                    EnabledExternalAreas: MockHelper.getArraySlice(conditionalAreas, 0, 3, false, 0)
                },
            Speed: {
                InstructedSpeed: RouteCalculationType.Name === 'InstructedSpeed' ? minSpeed : null,
                MinimumSpeed: RouteCalculationType.Name === 'SpeedRange' ? minSpeed : null,
                MaximumSpeed: RouteCalculationType.Name === 'SpeedRange' ? maxSpeed : null
            },
            Template: templateType ? {
                TemplateType: templateType,
                TemplateNextVoyageReportId: templateType === 1
                    ? MockHelper.getUniqueId('TemplateNextVoyageReportId')
                    : null
            } : null,
            PortOfDeparture: leg.PortOfDeparture,
            Etd: `${leg.Etd.slice(0, -1)}${leg.EtdOffset}`,
            Waypoints,
            PortOfDestination: leg.PortOfDestination,
            Eta: `${leg.Eta.slice(0, -1)}${leg.EtaOffset}`,
            Remarks: MockHelper.getRandomText(10, 5, 0.2) || '',
            RoutingEmailsVessel: MockHelper.getRandomArrayOfItems(1, 3, null, () => MockHelper.getRandomEmail()),
            RoutingEmailsOperations: MockHelper.getRandomArrayOfItems(1, 3, null, () => MockHelper.getRandomEmail()),
            RoutingEmailsOther: MockHelper.getRandomArrayOfItems(0, 3, null, () => MockHelper.getRandomEmail()),
            ConsumptionBudget: mockConsumptionBudget(),
            Cost: {
                VesselDailyCosts: shouldSetCost ? MockHelper.getRandomNumber(0, 100) : null,
                BunkerCostHFO: shouldSetCost ? MockHelper.getRandomNumber(0, 100) : null,
                BunkerCostHFOLS: shouldSetCost ? MockHelper.getRandomNumber(0, 100) : null
            },
            RequestType: MockHelper.getRandomArrayItem(requestTypes),
            PvarEmails: MockHelper.getRandomArrayOfItems(1, 3, null, () => MockHelper.getRandomEmail()),
            ExternalRequests: MockHelper.getRandomArrayOfItems(dateDiff, dateDiff, null, (i, l, prevItem) => {
                if (MockHelper.getRandomBoolean(0, 0.3)) {
                    const diff = MockHelper.getRandomNumber(-5 * tU.day, 5 * tU.day, 0, 0, 0.5);
                    routeEta = TimeHelper.getDateFromDiff(routeEta, diff).slice(0, -1);
                }
                Timestamp = !Timestamp
                    ? TimeHelper.getStartOf(
                        'day',
                        TimeHelper.getDateFromDiff(latestDate, -1 * (dateDiff - 1) * tU.day)
                    ).toISOString()
                    : TimeHelper.getDateFromDiff(Timestamp, tU.day);
                const IsSuccess = Source.Name === 'ConsultantWeatherApi' ? true : Status.Name !== 'Error';
                const Warnings = Source.Name === 'ConsultantWeatherApi'
                    ? []
                    : MockHelper.getRandomArrayOfItems(0, 3, 0, () => MockHelper.getRandomText());
                const Errors = Source.Name === 'ConsultantWeatherApi' || IsSuccess
                    ? []
                    : MockHelper.getRandomArrayOfItems(0, 3, null, () => MockHelper.getRandomText());
                DistanceToGo = !DistanceToGo
                    ? MockHelper.getRandomNumber(dateDiff * 200, dateDiff * 300)
                    : MockHelper.getRandomNumber(DistanceToGo - 200, DistanceToGo, 0, 0, 0.1);
                const relativePosition = prevItem
                    ? MockHelper.getRandomArrayItem(prevItem.RouteAdvice.Points, 0, 1 / dateDiff)
                    : null;
                let Points = MockHelper.getRandomRouteBetweenTwoPoints(
                    relativePosition ? mapLonLat(relativePosition) : leg.PortOfDeparture.Coordinates,
                    leg.PortOfDestination.Coordinates,
                    2,
                    0.02
                );
                const ecaConsumption = MockHelper.getRandomNumber(0, 200, 3);
                const nonEcaConsumption = MockHelper.getRandomNumber(0, 200, 3);
                const totalConsumption = ecaConsumption + nonEcaConsumption;
                let Course;
                const sectionDuration = TimeHelper.getDateDiff(Timestamp, routeEta) / (Points.length - 1);
                const sectionDistances = MockHelper.getRandomDivisions(DistanceToGo, Points.length - 1, 0.1);
                Points = Points.map((point, index) => {
                    if (Points[index - 1]) {
                        Course = calculateCourse(Points[index - 1], point);
                    } else {
                        Course = calculateCourse(point, Points[index + 1]);
                    }
                    return {
                        Sequence: index,
                        Latitude: point[1],
                        Longitude: point[0],
                        Course,
                        Distance: index > 0 ? sectionDistances[index - 1] : 0,
                        SpeedOverGround: index > 0
                            ? roundNumber(sectionDistances[index - 1] / (sectionDuration / tU.hour), 3)
                            : 0,
                        SpeedInCalmWater: index > 0 ? MockHelper.getRandomNumber(11, 14, 1) : 0
                    };
                });
                const RouteAdvice = {
                    Id: MockHelper.getUniqueId('AdviceId'),
                    DateOfAdvice: Timestamp,
                    Etd: leg.Etd,
                    AdvisedEta: `${routeEta.slice(0, -1)}${leg.EtaOffset}`,
                    DistanceToGo,
                    TotalCost: MockHelper.getRandomNumber(1000, 99999),
                    Consumption: {
                        Eca: ecaConsumption,
                        NonEca: nonEcaConsumption,
                        Total: totalConsumption
                    },
                    Comment: MockHelper.getRandomText(40, 10, 0.5) || '',
                    Synopsis: MockHelper.getRandomText(80, 10, 0.1) || '',
                    Points
                };
                return {
                    CreatedAt: Timestamp,
                    Warnings,
                    Errors,
                    IsSuccess,
                    RouteAdvice
                };
            })
        };
        return mockedData.RequestsById[Id];
    }).sort(sortByIntentionAndCreatedAt);
};

let isMockingData = false;

export const getMockedData = () => {
    const currentDate = new Date().toISOString();
    if (isMockingData || (isDataMocked && TimeHelper.getDateDiff(mockedData.CurrentDate, currentDate) < tU.hour)) {
        return waitForCondition(() => isDataMocked).then(() => mockedData);
    }
    mockedData.CurrentDate = currentDate;
    isMockingData = true;
    isDataMocked = false;
    return PortService.get({ ImportanceLevel: 9 }).then(ports => {
        mockedData.Voyages = mockVoyages(currentDate, ports);
        mockedData.Voyages.forEach(voyage => {
            mockedData.LegsByVoyageId[voyage.Voyage.Id] = mockVoyageLegs(voyage, currentDate, ports);
            mockedData.LegsByVoyageId[voyage.Voyage.Id].forEach(leg => {
                mockedData.RequestsByLegId[leg.Id] = mockLegRequests(leg, currentDate, ports);
                mockedData.SposByLegId[leg.Id] = mockSposRoutes(leg);
            });
        });
        isDataMocked = true;
        isMockingData = false;
        // eslint-disable-next-line
        console.log('Weather routing mocked data: ', mockedData);
        return mockedData;
    });
};

export const getMockedVoyages = ({
    AvailableForRouting,
    Imo,
    Offset,
    Limit
}) => {
    return getMockedData().then(data => {
        let res = data.Voyages.map(voyage => {
            const Legs = data.LegsByVoyageId[voyage.Voyage.Id].map(leg => {
                // find intended request or latest
                const Request = data.RequestsByLegId[leg.Id]
                    ? data.RequestsByLegId[leg.Id][0]
                    : null;
                const LatestSposRoute = data.SposByLegId[leg.Id]
                    ? data.SposByLegId[leg.Id][0]
                    : null;
                return {
                    Id: leg.Id,
                    VesselCondition: leg.VesselCondition,
                    Request: !Request ? null : {
                        Id: Request.Id,
                        OptimizationType: Request.OptimizationType,
                        RouteCalculationType: Request.RouteCalculationType,
                        Source: Request.Source,
                        Intention: Request.Intention,
                        Status: Request.Status,
                        Speed: Request.Speed,
                        PortOfDeparture: Request.PortOfDeparture,
                        Etd: Request.Etd,
                        PortOfDestination: Request.PortOfDestination,
                        Eta: Request.Eta,
                        LatestExternalRequest: Request.ExternalRequests[0] || null
                    },
                    LatestSposRoute: !LatestSposRoute ? null : {
                        Id: LatestSposRoute.Id,
                        From: LatestSposRoute.From,
                        Destination: LatestSposRoute.Destination,
                        VesselCondition: LatestSposRoute.VesselCondition,
                        DepartureDate: LatestSposRoute.DepartureDate,
                        DestinationDate: LatestSposRoute.DestinationDate
                    }
                };
            });
            const latestLeg = Legs[Legs.length - 1];
            const IsAvailableForRouting = voyage.Voyage.EndDate > data.CurrentDate
                && (!latestLeg
                    // latest leg has no request
                    || !latestLeg.Request
                    // latest leg is not intended
                    || latestLeg.Request.Intention.Name !== 'Intended'
                    // latest leg doesn't have the advice
                    || !latestLeg.Request.LatestExternalRequest
                    // latest advice eta is too close to voyage EndDate
                    || TimeHelper.getDateDiff(
                        latestLeg.Request.LatestExternalRequest.RouteAdvice.AdvisedEta, voyage.Voyage.EndDate
                    ) < 2 * tU.day);
            return {
                ...voyage.Voyage,
                IsAvailableForRouting,
                Vessel: {
                    Imo: voyage.Vessel.Imo,
                    Title: voyage.Vessel.VesselName
                },
                Legs
            };
        });
        if (AvailableForRouting || Imo) {
            res = res.filter(voyage => (
                (!AvailableForRouting || voyage.IsAvailableForRouting)
                && (!Imo || voyage.Vessel.Imo === Imo)
            ));
        }
        const PageItems = res.slice(Offset, Offset + Limit);
        res = {
            Limit,
            Offset,
            PageItemsCount: PageItems.length,
            PageItems,
            TotalPagesCount: Math.floor(res.length / Limit)
        };
        // eslint-disable-next-line
        console.log('getMockedVoyages: ', res);
        return res;
    });
};

export const getMockedVoyagesForImo = (vesselImo) => {
    return getMockedData().then(data => {
        const res = data.VoyagesByVesselImo[vesselImo].map(voyage => ({
            EndDate: voyage.Voyage.EndDate,
            StartDate: voyage.Voyage.StartDate,
            VoyageId: voyage.Voyage.Id,
            VoyageNumber: voyage.Voyage.VoyageNumber,
            VoyagePortInfo: `( ${voyage.Voyage.PortOfDeparture.Name} - ${voyage.Voyage.PortOfDestination.Name} )`
        }));
        // eslint-disable-next-line
        console.log('getMockedVoyagesForImo: ', res);
        return res;
    });
};

export const getMockedLeg = (legId) => {
    return getMockedData().then(data => {
        const leg = data.LegsById[legId];
        if (!leg) {
            // eslint-disable-next-line
            console.log('getMockedLeg: ', null);
            return null;
        }
        const res = {
            Id: leg.Id,
            VesselCondition: leg.VesselCondition,
            Voyage: leg.Voyage,
            Vessel: {
                Imo: leg.Vessel.Imo,
                Title: leg.Vessel.VesselName
            }
        };
        // eslint-disable-next-line
        console.log('getMockedLeg: ', res);
        return res;
    });
};

export const getMockedLegRequests = (legId) => {
    return getMockedData().then(data => {
        if (!data.RequestsByLegId[legId]) {
            // eslint-disable-next-line
            console.log('getMockedLegRequests: ', null);
            return null;
        }
        const res = data.RequestsByLegId[legId].map(request => ({
            Id: request.Id,
            CreatedAt: request.CreatedAt,
            LegId: request.LegId,
            VoyageId: request.Voyage.Id,
            Imo: request.Vessel.Imo,
            Intention: request.Intention,
            Status: request.Status,
            OptimizationType: request.OptimizationType,
            VesselCondition: request.VesselCondition,
            RouteCalculationType: request.RouteCalculationType,
            Source: request.Source,
            Etd: request.Etd,
            Eta: request.Eta,
            Speed: request.Speed,
            PortOfDeparture: request.PortOfDeparture,
            PortOfDestination: request.PortOfDestination,
            Remarks: request.Remarks,
            ExternalRequests: request.ExternalRequests
        }));
        // eslint-disable-next-line
        console.log('getMockedLegRequests: ', res);
        return res;
    });
};

export const getMockedLegSposRoutes = (legId) => {
    return getMockedData().then(data => {
        if (!data.SposByLegId[legId]) {
            // eslint-disable-next-line
            console.log('getMockedLegSposRoutes: ', null);
            return null;
        }
        const res = data.SposByLegId[legId];
        // eslint-disable-next-line
        console.log('getMockedLegSposRoutes: ', res);
        return res;
    });
};

export const getMockedRequest = (requestId) => {
    return getMockedData().then(data => {
        const res = data.RequestsById[requestId] || null;
        // eslint-disable-next-line
        console.log('getMockedLegRequests: ', res);
        return res;
    });
};

export const setMockedIntendedRequest = (requestId) => {
    return getMockedData().then(data => {
        const newIntendedRequest = data.RequestsById[requestId];
        if (newIntendedRequest) {
            newIntendedRequest.Intention = intentionTypes[1]; // Intended
            newIntendedRequest.Status = requestStatuses.Ongoing;
            const prevIntendedRequest = data.RequestsByLegId[newIntendedRequest.LegId]
                .find(request => request.Intention.Name === 'Intended');
            if (prevIntendedRequest) {
                prevIntendedRequest.Intention = intentionTypes[2]; // HistoricalIntended
                prevIntendedRequest.Status = prevIntendedRequest.Source.Name === 'ConsultantWeatherApi'
                    ? requestStatuses.Suspended
                    : requestStatuses.Stopped;
            }
            mockedData.RequestsByLegId[newIntendedRequest.LegId] = data.RequestsByLegId[newIntendedRequest.LegId]
                .sort(sortByIntentionAndCreatedAt);
            return newIntendedRequest;
        }
        return null;
    });
};

export const deleteMockedRequest = (requestId) => {
    return getMockedData().then(data => {
        const deletedRequest = { ...data.RequestsById[requestId] };
        if (deletedRequest) {
            mockedData.RequestsByLegId[deletedRequest.LegId] = mockedData.RequestsByLegId[deletedRequest.LegId]
                .filter(request => request.Id !== requestId);
            // if (mockedData.RequestsByLegId[deletedRequest.LegId].length === 0) {
            //     delete mockedData.LegsById[deletedRequest.LegId];
            //     mockedData.LegsByVoyageId[deletedRequest.Voyage.Id] = mockedData
            //         .LegsByVoyageId[deletedRequest.Voyage.Id].filter(leg => leg.Id !== deletedRequest.LegId);
            // }
            delete mockedData.RequestsById[requestId];
            return deletedRequest;
        }
        return null;
    });
};

export const stopMockedRequest = (requestId) => {
    return getMockedData().then(data => {
        const stoppedRequest = data.RequestsById[requestId];
        if (stoppedRequest) {
            stoppedRequest.Intention = intentionTypes[2]; // HistoricalIntended
            stoppedRequest.Status = requestStatuses.Stopped;
            return stoppedRequest;
        }
        return null;
    });
};

export const refreshMockedRequest = (requestId) => {
    return getMockedData().then(data => {
        const refreshRequest = data.RequestsById[requestId];
        if (refreshRequest) {
            refreshRequest.Status = requestStatuses.Requested;
            return refreshRequest;
        }
        return null;
    });
};

export const deleteMockedLeg = (legId) => {
    return getMockedData().then(data => {
        const deletedLeg = { ...data.LegsById[legId] };
        if (deletedLeg) {
            mockedData.LegsByVoyageId[deletedLeg.Voyage.Id] = mockedData.LegsByVoyageId[deletedLeg.Voyage.Id]
                .filter(leg => leg.Id !== deletedLeg.Id);
            delete mockedData.RequestsByLegId[legId];
            delete mockedData.LegsById[legId];
            return deletedLeg;
        }
        return null;
    });
};

export const getMockedBudgetedConsumption = () => {
    let BallastConsumption = 0;
    let LadenConsumption = 0;
    const mockedConsumption = MockHelper.getRandomArrayOfItems(10, 16, null, (index, length, prevItem) => {
        const ballastPercentage = MockHelper.getRandomNumber(90, 95) / 100;
        BallastConsumption = index === 0
            ? MockHelper.getRandomNumber(0, 100)
            : Number((prevItem.BallastConsumption * ballastPercentage).toFixed(2));
        const ladenPercentage = MockHelper.getRandomNumber(95, 98) / 100;
        LadenConsumption = index === 0
            ? MockHelper.getRandomNumber(0, 100)
            : Number((prevItem.LadenConsumption * ladenPercentage).toFixed(2));
        return {
            Speed: 16 - (index / 2),
            BallastConsumption,
            LadenConsumption
        };
    });
        // eslint-disable-next-line
        console.log('getMockedBudgetedConsumption', mockedConsumption);
    return mockedConsumption;
};

export const getMockedTemplateOptions = (voyageId, vesselConditionId, request) => {
    return PortService.get({ ImportanceLevel: 9 }).then(ports => {
        let itineraryCount = MockHelper.getRandomNumber(0, vesselConditionId ? 1 : 2);
        const hasNextLegReports = MockHelper.getRandomBoolean();
        if (!itineraryCount && !hasNextLegReports) {
            return [];
        }

        const currentDate = new Date().toISOString();
        const maxEndDate = TimeHelper.getDateFromDiff(currentDate, tU.month);
        const minEndDate = TimeHelper.getDateFromDiff(currentDate, -3 * tU.month);
        const EndDate = MockHelper.getRandomDate(minEndDate, maxEndDate, 0, 0.75);
        const maxStartDate = TimeHelper.getDateFromDiff(EndDate, -tU.week);
        const minStartDate = TimeHelper.getDateFromDiff(EndDate, -3 * tU.week);
        const StartDate = MockHelper.getRandomDate(minStartDate, maxStartDate);

        const Vessel = MockHelper.getVessels().find(vessel => vessel.Imo === request.Vessel.Imo);

        return MockHelper.getRandomArrayOfItems(0, 8, 0.4, (index, length, prevItem) => {
            let PortOfDeparture;
            let PortOfDestination;
            let VesselCondition;
            let Template;
            const Etd = MockHelper.getRandomDate(StartDate, EndDate);
            const Eta = MockHelper.getRandomDate(Etd, EndDate);

            const EtaOffset = TimeHelper.getUTCOffsetString(MockHelper.getRandomNumber(-12, 12) * 60, false);
            const EtdOffset = EtaOffset
                || TimeHelper.getUTCOffsetString(MockHelper.getRandomNumber(-12, 12) * 60, false);

            const Waypoints = MockHelper.getRandomArrayOfItems(0, 2, null, () => {
                const Port = MockHelper.getRandomArrayItem(ports);
                return {
                    Name: Port.Name || null,
                    Position: !Port
                        ? MockHelper.getRandomWktPoint(getWaypointLimits(PortOfDeparture, PortOfDestination))
                        : Port.Location,
                    PortId: Port ? Port.Id : null
                };
            });

            if (itineraryCount) {
                PortOfDeparture = prevItem
                    ? prevItem.PortOfDestination : remapPort(MockHelper.getRandomArrayItem(ports));
                PortOfDestination = remapPort(MockHelper.getRandomArrayItem(ports));
                // VesselCondition
                if (vesselConditionId) {
                    VesselCondition = vesselConditions.find(vc => vc.Id === vesselConditionId);
                } else {
                    VesselCondition = prevItem && prevItem.VesselCondition.Name === 'Ballast'
                        ? vesselConditions.find(vc => vc.Name === 'Laden')
                        : vesselConditions.find(vc => vc.Name === 'Ballast');
                }
                // Template
                Template = {
                    DisplayName: 'Itinerary',
                    TemplateType: 2,
                    TemplateNextVoyageReportId: null
                };
                itineraryCount--;
            } else {
                PortOfDeparture = MockHelper.getRandomArrayItem(ports);
                PortOfDestination = MockHelper.getRandomArrayItem(ports);
                // VesselCondition
                VesselCondition = vesselConditionId
                    ? vesselConditions.find(vc => vc.Id === vesselConditionId)
                    : MockHelper.getRandomArrayItem(vesselConditions);
                // Template
                Template = {
                    DisplayName: 'Next leg reports',
                    TemplateType: 1,
                    TemplateNextVoyageReportId: request.Id && mockedData.RequestsById[request.Id].Template
                        ? mockedData.RequestsById[request.Id].Template.TemplateNextVoyageReportId
                        : MockHelper.getUniqueId('TemplateNextVoyageReportId')
                };
            }

            return {
                Eta: `${Eta.slice(0, -1)}${EtaOffset}`,
                Etd: `${Etd.slice(0, -1)}${EtdOffset}`,
                PortOfDeparture,
                PortOfDestination,
                Vessel,
                VesselCondition,
                VesselEmails: MockHelper.getRandomArrayOfItems(0, 4, 0.5, () => MockHelper.getRandomEmail()),
                VoyageId: voyageId,
                Template,
                Waypoints
            };
        });
    });
};
