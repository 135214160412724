import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import Select from 'components/select/select';
/* Services */
import VesselService from 'services/core-api/vessel-service';
/* styles */
import './voyage-select.scss';

const emptyArray = [];

class VoyageSelect extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            voyages: emptyArray
        };
        this.componentMounted = false;
    }

    componentDidMount() {
        this.componentMounted = true;
        if (this.props.vesselId) {
            this.getVoyages(this.props.vesselId, this.props.params);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.vesselId !== prevProps.vesselId) {
            this.getVoyages(this.props.vesselId, this.props.params);
        }
    }

    componentWillUnmount() {
        this.componentMounted = false;
    }

    getVoyages = (vesselId, params) => {
        VesselService.getVoyagesPreviewById(vesselId, params).then((res) => {
            let voyages = emptyArray;
            let selectedVoyage = null;
            if (res && res.length) {
                voyages = res;
                selectedVoyage = res[0];
            }
            if (this.componentMounted) {
                this.setState({ voyages });
            }
            const isSelectedVoyageValid = !!this.props.value
                && voyages.some(voyage => voyage.VoyageId === this.props.value.VoyageId);
            if (this.props.shouldSelectFirst && !isSelectedVoyageValid) {
                this.props.onChange(selectedVoyage);
            }
        });
    };

    getVoyageOption =(voyage) => {
        return (
            <React.Fragment>
                <span className="text-semibold sten-voyage-select__voyage-number">{voyage.VoyageNumber}</span>
                {voyage.VoyagePortInfo && <span>{` ${voyage.VoyagePortInfo}`}</span>}
            </React.Fragment>
        );
    };

    render() {
        const voyages = this.props.vesselId ? this.state.voyages : this.props.options;
        const placeholder = voyages.length === 0
            ? translate('VESSEL_INFO.NO_VOYAGES')
            : translate('VESSEL_INFO.VOYAGE_PLACEHOLDER');
        return (
            <Select
                {...this.props}
                className={`sten-voyage-select${this.props.className ? ` ${this.props.className}` : ''}`}
                valueKey="VoyageId"
                labelKey="VoyageNumber"
                optionRenderer={this.getVoyageOption}
                placeholder={this.props.placeholder || placeholder}
                menuShownIcon="icon-chevron-down"
                options={voyages}
                disabled={voyages.length === 0}
            />
        );
    }
}

VoyageSelect.propTypes = {
    ...Select.propTypes,
    params: PropTypes.shape({
        tcOut: PropTypes.bool,
        filterFutureVoyages: PropTypes.bool
    }),
    shouldSelectFirst: PropTypes.bool,
    vesselId: PropTypes.number
};

VoyageSelect.defaultProps = {
    ...Select.defaultProps,
    params: null,
    shouldSelectFirst: false,
    vesselId: null
};

export default VoyageSelect;
