export const ActionTypes = {
    FLEET_ACTIVITY_GET: 'FLEET_ACTIVITY_GET',
    FLEET_ACTIVITY_SET: 'FLEET_ACTIVITY_SET',
    FLEET_ACTIVITY_SET_SELECTED_RANGE: 'FLEET_ACTIVITY_SET_SELECTED_RANGE'
};

export function getFleetActivity(dispatch, from, to) {
    dispatch({
        type: ActionTypes.FLEET_ACTIVITY_GET,
        from,
        to
    });
}
