/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
/* components */
import MultiVoyageReportPdfTable from './multi-voyage-report-pdf-table';
import SummaryTable from '../../../shared/summary-table';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import stenTableStyles, { border } from 'utils/helpers/pdf-styles/sten-table';
import { tableHeaderBg } from 'utils/helpers/pdf-styles/colors';
import { transformSummaryToLabelValueArray } from '../multi-voyage-report';
import {
    ladenSummaryKeys,
    ballastSummaryKeys
} from '../constants';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.${key}`);

const styles = `
html {
    font-size: 22px;
    font-family: Arial, Helvetica, sans-serif;
}
html, body {
    margin: 0;
    padding: 0;
}
table {
    border-right: ${border};
    border-left: ${border};
}
.sten-energy-management-reports-preview__summary-table.sten-table--no-border-bottom {
    border-top: ${border};
    border-bottom: ${border};
}
.vessel-name-text {
    font-size: 2.5rem;
    font-weight: bold;
    font-kerning: normal;
    letter-spacing: -0.03em;
}
.header-title {
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
    text-align: center;
    text-transform: uppercase;
}
.header-style {
    display: flex;
    justify-content: space-between;
    height: 8rem;
    align-items: center;
    background-color: #eee;
    border-bottom: 2px solid #dadada;
    text-align: center;
    margin-bottom: 2rem;
    padding: 1rem 2rem;
}
.voyage-number-text {
    font-size: 1.3rem;
    font-kerning: normal;
}
.page {
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    page-break-inside: avoid;
    page-break-after: always;
}
.logo-style {
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
}
.col-12 {
    position: relative;
    min-height: 1px;
    width: 50%;
}
.sten-panel {
    display: flex;
    flex-direction: column;
}
.flex-start {
    -ms-flex-align: start;
    align-items: flex-start;
}
.flex-row {
    display: -ms-flexbox;
    display: flex;
    margin: 0;
}
.flex {
    display: -ms-flexbox;
    display: flex;
}
.sten-panel__header {
    font-size: 2.7rem;
    font-weight: 100;
    font-kerning: normal;
    letter-spacing: -0.03em;
    padding: 1rem 0 0.5rem;
}
.sten-energy-management-reports-preview-header {
    padding-bottom: 1rem;
}
.sten-energy-management-reports-preview-header h1,
.sten-energy-management-reports-preview-header p {
    padding: 0;
    margin: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}
.sten-energy-management-reports-preview__mutivoyage-table th.sten-energy-management-reports-preview__mutivoyage-table--emphasized-column,
.sten-energy-management-reports-preview__mutivoyage-table td.sten-energy-management-reports-preview__mutivoyage-table--emphasized-column {
    background-color: rgba(0, 0, 0, 0.03);
}
${stenTableStyles}
.sten-energy-management-reports-preview__mutivoyage-table.sten-table td, .sten-energy-management-reports-preview__mutivoyage-table.sten-table th {
    text-align: center;
    vertical-align: middle;
    white-space: initial;
    width: auto;
}
.sten-energy-management-reports-preview__mutivoyage-table tbody tr td:first-child,
.sten-energy-management-reports-preview__mutivoyage-table tbody tr:first-child,
.sten-energy-management-reports-preview__mutivoyage-table tbody tr:nth-child(2),
.sten-energy-management-reports-preview__mutivoyage-table__header-column.sten-table thead tr:first-child {
    background-color: ${tableHeaderBg};
    font-weight: bold;
}
.sten-energy-management-reports-preview__summary-table.sten-table--no-border-bottom tr td:first-child {
    background-color: ${tableHeaderBg};
    font-weight: bold;
    border-right: ${border};
}
.sten-energy-management-reports-preview__mutivoyage-table__header-column.sten-table {
    border-right: ${border};
    width: auto;
}
.sten-energy-management-reports-preview__mutivoyage-table.sten-table th {
    font-weight: bold;
}
`;


export default class EnergyManagementReportsPreviewMultiVoyageReport extends React.PureComponent {
    renderBallastLadenSummary() {
        const ballastRows = transformSummaryToLabelValueArray(this.props.summaryReport.Ballast, ballastSummaryKeys);
        const ladenRows = transformSummaryToLabelValueArray(this.props.summaryReport.Laden, ladenSummaryKeys);
        return (
            <div className="sten-content__body">
                <div className="flex flex-row flex-start">
                    {this.renderSummaryTable(translate('GLOBAL.BALLAST'), ballastRows)}
                    <div style={{ width: '2rem' }} />
                    {this.renderSummaryTable(translate('GLOBAL.LADEN'), ladenRows)}
                </div>
            </div>
        );
    }

    renderSummaryTable = (title, rows) => (
        <div className="col-12">
            <div className="sten-panel">
                <div className="sten-panel__header text-uppercase">{title}</div>
                <div className="sten-panel__body">
                    <SummaryTable rows={rows} />
                </div>
            </div>
        </div>
    );

    render() {
        return (
            <html lang="en">
                <head>
                    <style>
                        {styles}
                    </style>
                </head>
                <body>
                    <div className="page">
                        <header className="header-style">
                            <div
                                className="logo-style"
                                style={{
                                    backgroundImage: this.props.companyLogo ? `url(${this.props.companyLogo.src})` : '',
                                    backgroundPositionX: 'left',
                                    width: '16rem',
                                    height: '5rem'
                                }}
                            />
                            <div>
                                <div className="header-title">{t('MULTI_VOYAGE_REPORT')}</div>
                                <div className="vessel-name-text">{this.props.vesselName}</div>
                                <div className="voyage-number-text">
                                    {`${t('PREVIEW.HEADER.DATE_RANGE')} ${this.props.dateRange}`}
                                </div>
                            </div>
                            <div
                                className="logo-style"
                                style={{
                                    backgroundImage: `url(${this.props.orbitLogo})`,
                                    backgroundPositionX: 'right',
                                    width: '16rem',
                                    height: '3rem'
                                }}
                            />
                        </header>
                        {this.props.summaryReport && this.renderBallastLadenSummary()}
                    </div>
                    {this.props.voyageReports.length > 0 && (
                        <div className="page">
                            <MultiVoyageReportPdfTable
                                voyageReports={this.props.voyageReports}
                                voyageNumbers={this.props.voyageNumbers}
                            />
                        </div>
                    )}
                </body>
            </html>
        );
    }
}

EnergyManagementReportsPreviewMultiVoyageReport.propTypes = {
    companyLogo: PropTypes.objectOf(PropTypes.any),
    dateRange: PropTypes.string.isRequired,
    orbitLogo: PropTypes.string.isRequired,
    summaryReport: PropTypes.objectOf(PropTypes.any),
    vesselName: PropTypes.string.isRequired,
    voyageNumbers: PropTypes.arrayOf(PropTypes.number).isRequired,
    voyageReports: PropTypes.arrayOf(PropTypes.object).isRequired
};

EnergyManagementReportsPreviewMultiVoyageReport.defaultProps = {
    companyLogo: null,
    summaryReport: null
};
