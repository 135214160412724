import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
/* helpers */
import { closestParentByTagName } from 'utils/helpers/html-helper';
import { getClassName } from 'utils/helpers/info-helper';
/* config */
import config from 'app-config';
/* actions */
import { navMenuExpand, navMenuSetActiveLink } from './left-side-bar-actions';
/* components */
import NavMenu from './nav-menu/nav-menu';
import ContactInquiry from './contact-inquiry/contact-inquiry';
import CargoInquiry from './cargo-inquiry/cargo-inquiry';
import Account from './account/account';
/* styles */
import './left-side-bar.scss';
import './left-side-bar-panel.scss';

export class LeftSideBar extends React.PureComponent {
    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside = e => {
        if (e.cancelBubble) {
            return;
        }
        const isModalOpen = e.currentTarget
            && e.currentTarget.body
            && typeof e.currentTarget.body.className === 'string'
            && e.currentTarget.body.className.indexOf('sten-modal--open') > -1;
        const targetButton = closestParentByTagName(e.target, 'button');
        const isKeyboardClick = targetButton && typeof targetButton.className === 'string'
            && targetButton.className.indexOf('keyboard-button') > -1;
        if ((this.props.isNavMenuExpanded || this.props.activeNavLinkName)
            && this.props.navMenuExpand
            && !isModalOpen
            && !isKeyboardClick
            && (!this.leftSideMenuRef || !this.leftSideMenuRef.contains(e.target))
        ) {
            this.props.navMenuExpand(false);
            this.props.navMenuSetActiveLink('');
        }
    };

    saveRef = (c) => { this.leftSideMenuRef = c; };

    getPanelComponent = (activeNavLinkName, permissions) => {
        const panelMap = {
            'contact-inquiry': permissions.MenuEmailSupport && <ContactInquiry />,
            'cargo-inquiry': permissions.MenuCargoInquiry && <CargoInquiry />,
            account: <Account />
        };
        if (activeNavLinkName && panelMap[activeNavLinkName]) {
            return (
                <CSSTransition
                    key={activeNavLinkName}
                    classNames="transition-fade"
                    timeout={{ enter: config.transitionLength, exit: config.transitionLength }}
                >
                    {panelMap[activeNavLinkName]}
                </CSSTransition>
            );
        }
        return null;
    }

    render() {
        const { activeNavLinkName, isNavMenuExpanded, navMenuExpand, navMenuSetActiveLink, permissions } = this.props;
        const sidebarClassName = getClassName('sten-left-sidebar clearfix', this.props.className);
        const sidebarItemClassName = getClassName('sten-left-sidebar__item sten-left-sidebar__collapsible-item', {
            'sten-left-sidebar__collapsible-item--collapsed': !activeNavLinkName
        });
        return (
            <aside className={sidebarClassName} ref={this.saveRef}>
                <div className="sten-left-sidebar__item">
                    <NavMenu
                        isExpanded={isNavMenuExpanded}
                        expand={navMenuExpand}
                        activeLinkName={activeNavLinkName}
                        setActiveLink={navMenuSetActiveLink}
                    />
                </div>
                <div className={sidebarItemClassName}>
                    <TransitionGroup className="sten-left-sidebar__panels-container">
                        {this.getPanelComponent(activeNavLinkName, permissions)}
                    </TransitionGroup>
                </div>
            </aside>
        );
    }
}

LeftSideBar.propTypes = {
    activeNavLinkName: PropTypes.string.isRequired,
    className: PropTypes.string,
    isNavMenuExpanded: PropTypes.bool.isRequired,
    navMenuExpand: PropTypes.func.isRequired,
    navMenuSetActiveLink: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired
};

LeftSideBar.defaultProps = {
    className: ''
};

function mapStateToProps(state) {
    return {
        activeNavLinkName: state.leftSideBarReducer.activeNavLinkName,
        isNavMenuExpanded: state.leftSideBarReducer.isNavMenuExpanded,
        permissions: state.userReducer.permissions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        navMenuExpand: () => {
            navMenuExpand(dispatch);
        },
        navMenuSetActiveLink: (itemName) => {
            navMenuSetActiveLink(dispatch, itemName);
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideBar);
