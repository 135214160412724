/* utils */
import { t } from 'utils/i18n/i18n-model';
import { formatNumber } from 'utils/helpers/info-helper';

export const currentYear = { Name: new Date().getFullYear().toString() };
export const defaultVesselType = { Name: 'Tanker' };
export const defaultCurrency = { Ticker: 'EUR' };

const calculateEuEtsPrice = (euEts, euEtsPrice) => (typeof euEts?.co2Emissions === 'number'
    ? euEts?.co2Emissions * euEtsPrice?.price * euEts?.summaryCoefficient
    : null);

export const formatEuEtsPrice = ({ voyageEuEts, euEtsPrice }, formatData = true) => {
    let ets = null;
    if ((typeof voyageEuEts?.summaryCoefficient === 'number' || voyageEuEts?.result) && euEtsPrice) {
        ets = voyageEuEts.result
            ? voyageEuEts.result.reduce((acc, curr) => acc + curr.price, 0)
            : calculateEuEtsPrice(voyageEuEts, euEtsPrice);
    }
    if (formatData) {
        return ets === null ? '-' : `${formatNumber(ets, 1)} ${euEtsPrice?.currency}`;
    }
    return ets;
};

const formatCoefficients = (voyageEuEts, euEtsPrice, showEmissions = false) => {
    const etsPrice = euEtsPrice.price;
    const currency = euEtsPrice.currency;
    let coefficientNames = '';
    const coefficients = [];
    voyageEuEts.voyageCoefficient.forEach(vc => {
        coefficients.push(vc.value);
        coefficientNames += `${vc.name}: ${vc.value} \n`;
    });
    const co2Emissions = typeof voyageEuEts.co2Emissions === 'number'
        ? `${formatNumber(voyageEuEts.co2Emissions, 2)} mt`
        : t('GLOBAL.NOT_AVAILABLE');
    return `${coefficientNames}`
        + `${showEmissions ? `${t('FOOTPRINT_TABLE.CO2_EMISSIONS')}: ${co2Emissions}\n` : '\n'}`
        + `${t('FOOTPRINT_TABLE.RESULT')}: ${co2Emissions} * ${etsPrice} ${currency} * ${coefficients.join(' * ')}`;
};

export const formatEuEtsTooltip = ({ voyageEuEts, euEtsPrice }) => {
    if ((!voyageEuEts?.voyageCoefficient && !voyageEuEts?.result) || !euEtsPrice?.price) {
        return '';
    }
    if (voyageEuEts.voyageCoefficient) {
        return `${t('FOOTPRINT_TABLE.COEFFICIENTS')}:\n${formatCoefficients(voyageEuEts, euEtsPrice)}`;
    }
    let result = `${t('FOOTPRINT_TABLE.SECTIONS')}:\n`;
    const sectionTotalsFormatted = [];
    voyageEuEts.result.forEach(section => {
        const totalFormatted = typeof section.price === 'number'
            ? `${formatNumber(section.price, 1)} ${euEtsPrice.currency}`
            : t('GLOBAL.NOT_AVAILABLE');
        sectionTotalsFormatted.push(totalFormatted);
        result += `\n${section.name}\n${section.explanation}\n${t('FOOTPRINT_TABLE.TOTAL')}: ${totalFormatted}`;
    });
    result += `\n\n${t('FOOTPRINT_TABLE.TOTAL')}: ${sectionTotalsFormatted.join(' + ')}`
        + ` = ${formatNumber(voyageEuEts.price, 1)} ${euEtsPrice.currency}`;
    return result;
};
