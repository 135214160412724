import { formatNumber } from 'utils/helpers/info-helper';
import { translate } from 'utils/i18n/i18n-model';
import { convertWidthToCol } from 'utils/helpers/xlsx-helper';
/* images */
import { getLogoBase64 } from 'utils/base64images';
/* services */
import CompanyService from 'services/core-api/company-service';

export function* getLogoForCompany(id, typeId = 0) {
    if (id !== null) {
        const logo = yield CompanyService.Internal.getLogoByIdAndType(id, { typeId });
        if (logo) {
            return yield new Promise(resolve => {
                const image = new Image();
                image.src = logo;
                image.onload = function getImageProps() {
                    resolve(this);
                };
            });
        }
    }
    return null;
}

export const formatXLSXCellValue = (value, format = {}) => {
    if (format.type === 'number') {
        return value;
    }
    if (Array.isArray(value)) {
        if (value.length === 0) {
            return '-';
        }
        const result = value.map((v) => formatNumber(v, 1)).join(', ');
        return format.unit ? `${result} ${format.unit}` : result;
    }
    if (value && format.type === 'date') {
        return new Date(value);
    }
    if (typeof value === 'boolean') {
        return value ? translate('GLOBAL.YES') : translate('GLOBAL.NO');
    }
    if (value === null || value === '' || value === undefined) {
        return '-';
    }
    return value;
};

export const generateXLSXHeaderLogos = (companyLogo, colWidths, orbitLeftCol = 5) => {
    const logoHeight = 80;
    const orbitLeftOffset = convertWidthToCol(getLogoBase64().offsetLeft, colWidths);
    const values = [
        {
            image: { base64: getLogoBase64().dark, extension: 'png' },
            imageProps: {
                tl: { col: colWidths ? orbitLeftOffset : orbitLeftCol, row: 2 },
                ext: { width: getLogoBase64().width, height: getLogoBase64().height }
            }
        }
    ];
    if (companyLogo) {
        const calculatedLogoWidth = (logoHeight * companyLogo.width) / companyLogo.height;
        values.unshift({
            image: { base64: companyLogo.src, extension: 'png' },
            imageProps: {
                tl: { col: 0.0001, row: 1 },
                ext: { width: calculatedLogoWidth, height: logoHeight }
            }
        });
    }

    return ([
        { values },
        null,
        null,
        null,
        null,
        null
    ]);
};
