import React from 'react';
import PropTypes from 'prop-types';
import ScrollArea from 'components/scroll-area/scroll-area';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* types */
import {
    TFootprint,
    TVmsFootprint,
    TYearlyRatingWithCorrection
} from 'components/footprint-table/footprint-table-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import TimeHelper from 'utils/helpers/time-helper';
import { getClassName, getLinkToSedna } from 'utils/helpers/info-helper';
/* services */
import ConfigService from 'services/config-api/config-service';
/* actions */
import {
    getVesselData,
    getVoyageData,
    getVoyagePerformanceData,
    getVoyageEuEts,
    generatePDF,
    resetAll
} from './voyage-performance-actions';
import { sendReminder } from 'components/vessel-report/vessel-report-actions';
/* selectors */
import {
    getReportProps,
    getReversedVoyageReports,
    getVoyagePerformanceCharts
} from './voyage-performance-selectors';
/* components */
import CargoPanel from './cargo-panel/cargo-panel';
import ChartPanel from './chart-panel/chart-panel';
import FootprintTable from 'components/footprint-table/footprint-table';
import NetDaily from './net-daily/net-daily';
import PerformancePanel from './performance-panel/voyage-performance-panel';
import RawReportsTable from 'components/raw-reports-table/raw-reports-table';
import ReportsPanel from './reports-panel/reports-panel';
import TimelinePanel from './timeline-panel/timeline-panel';
import VesselSelect from 'components/vessel-select/vessel-select';
import VoyageSelect from 'components/voyage-select/voyage-select';
import VesselReport from 'components/vessel-report/vessel-report';
import RightSideBar from 'components/right-side-bar/right-side-bar';
/* styles */
import './voyage-performance.scss';

export class VoyagePerformance extends React.PureComponent {
    componentDidMount() {
        if (this.props.queryParams.vessel) {
            this.props.getVesselData(this.props.queryParams.vessel);
        }
        if (this.props.queryParams.voyage) {
            this.props.getVoyageData(this.props.queryParams.voyage);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.queryParams !== prevProps.queryParams) {
            if (this.props.queryParams.vessel !== prevProps.queryParams.vessel) {
                this.props.getVesselData(this.props.queryParams.vessel);
            }
            if (this.props.queryParams.voyage !== prevProps.queryParams.voyage) {
                this.props.getVoyageData(this.props.queryParams.voyage);
            }
        }
    }

    componentWillUnmount() {
        this.props.resetAll();
    }

    handleVoyageSelect = voyage => this.props.router.updateQueryParams({ add: { voyage: voyage.VoyageId } });

    handleVesselChange = (vessel) => this.props.router.updateQueryParams({
        remove: ['voyage'],
        add: { vessel: vessel.Imo }
    });

    handleReportTypeClick = (reportType) => this.props.router.updateQueryParams({
        add: { rawReportsType: reportType }
    });

    handleRawReportsBackClick = () => this.props.router.updateQueryParams({ remove: ['rawReportsType'] });

    handlePerformanceParamsChange = (params) => {
        this.props.getVoyagePerformanceData(this.props.queryParams.voyage, params);
    };

    renderCargoAndFootprint = ({ selectedDate }) => (
        <div className="flex-row flex-row--sm">
            <div className="col-sm-14 col-xs-24">
                <CargoPanel
                    className="spread-v--sm"
                    wings={this.props.selectedVoyageTankLayout}
                    cargoSummary={this.props.selectedVoyageCargoSummary}
                />
            </div>
            <div className="col-sm-10 col-xs-24 col-pad--xs">
                <div className="sten-panel sten-voyage-performance__footprint-panel">
                    <div className="sten-panel__header">
                        <h4 className="flex-grow text-uppercase">{t('VOYAGE_PERFORMANCE.FOOTPRINT.TITLE')}</h4>
                        <div className="flex-shrink">
                            <span
                                className="icon sten-icon-info-tooltip"
                                title={t('VOYAGE_PERFORMANCE.FOOTPRINT.BASED_ON_VALUES_FROM_ALL_REPORTS')}
                            />
                        </div>
                    </div>
                    <FootprintTable
                        selectedDate={selectedDate}
                        vesselCii={this.props.selectedVesselCii}
                        voyageEuEts={this.props.selectedVoyageEuEts}
                        voyageFootprint={this.props.selectedVoyageFootprint}
                        voyageVmsFootprint={this.props.selectedVoyageVmsFootprint}
                        onEuEtsYearChange={this.props.getVoyageEuEts}
                        euEtsPrice={this.props.euEtsPrice}
                        euEtsYearsAvailable={this.props.euEtsYearsAvailable}
                        showVesselCii={!ConfigService.hiddenFeatures.voyagePerformanceVesselCiiRating}
                        showVoyageEmissions={!ConfigService.hiddenFeatures.voyagePerformanceVoyageEmissions}
                        showVoyageEnergyEfficiency={
                            !ConfigService.hiddenFeatures.voyagePerformanceVoyageEnergyEfficiency}
                        showConsumption={!ConfigService.hiddenFeatures.voyagePerformanceFootprintFuelConsumptions}
                        showVmsVoyageEmissions={!ConfigService.hiddenFeatures.voyagePerformanceVmsVoyageEmissions}
                        tableSize="xs"
                    />
                </div>
            </div>
        </div>
    );

    linkToVesselOnMap = () => this.props.router.getLinkTo({
        pathname: appRoutes.Map,
        query: { vessel: this.props.queryParams.vessel, voyage: this.props.queryParams.voyage }
    });

    handleRecalculate = () => {
        this.props.getVesselData(this.props.queryParams.vessel);
        this.props.getVoyageData(this.props.queryParams.voyage);
    };

    generateFootprintPdf =() => this.props.generatePDF(true);

    generatePDF = () => this.props.generatePDF();

    renderFooter = () => {
        return (
            <footer className="sten-content__footer flex-row">
                {this.props.editedReportIds || this.props.deletedReportIds
                    ? (
                        <React.Fragment>
                            <p className="flex-grow">{t('VOYAGE_PERFORMANCE.REPORTS_UPDATED_TEXT')}</p>
                            <div className="flex-shrink">
                                <button
                                    onClick={this.handleRecalculate}
                                    className="btn btn--primary"
                                >
                                    {t('VOYAGE_PERFORMANCE.RECALCULATE')}
                                </button>
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="flex-grow" />
                            {!ConfigService.hiddenFeatures.voyagePerformanceFootprintPdfExport && (
                                <div className="flex-shrink">
                                    <button onClick={this.generateFootprintPdf} className="btn btn--primary">
                                        {t('VOYAGE_PERFORMANCE.EXPORT_FOOTPRINT_PDF')}
                                    </button>
                                </div>
                            )}
                            {!ConfigService.hiddenFeatures.voyagePerformancePdfExport && (
                                <div className="flex-shrink">
                                    <button onClick={this.generatePDF} className="btn btn--primary">
                                        {t('VOYAGE_PERFORMANCE.EXPORT_PDF')}
                                    </button>
                                </div>
                            )}
                        </React.Fragment>
                    )}
            </footer>
        );
    };

    goToSedna = () => {
        const {
            queryParams,
            selectedVesselBase,
            selectedVesselVoyages: voyages
        } = this.props;
        const voyage = queryParams.voyage
            ? voyages.find(voyage => voyage.VoyageId === queryParams.voyage)
            : null;
        const { pathname, search } = getLinkToSedna(
            selectedVesselBase.Title,
            voyage && voyage.VoyageNumber
        );
        window.open(`${pathname}?${search}`, '_blank');
    }

    render() {
        const {
            consumption,
            queryParams,
            performance,
            performanceCharts,
            performanceParams,
            permissions,
            reportProps,
            selectedVesselBase,
            selectedVesselVoyages: voyages,
            selectedVoyageEarnings: earnings,
            selectedVoyageReports: reports,
            sendReminder
        } = this.props;
        const voyage = queryParams.voyage
            ? voyages.find(voyage => voyage.VoyageId === queryParams.voyage)
            : null;

        const hasFooter = !!voyage;
        const className = getClassName('sten-content', {
            'sten-content--has-footer': hasFooter
        });

        return (
            <React.Fragment>
                <RightSideBar isCollapsed={!(queryParams.reportId && queryParams.reportType)}>
                    {queryParams.reportId && queryParams.reportType && (
                        <VesselReport
                            areBunkerTypeUnitsShown
                            isEditing={queryParams.reportEdit}
                            activeReportId={queryParams.reportId}
                            activeReportType={queryParams.reportType}
                            reloadWhenFinished={false}
                            closeOnCancel
                            closeOnSubmit
                        />
                    )}
                </RightSideBar>
                {voyage && reportProps && queryParams.rawReportsType ? (
                    <RawReportsTable
                        vessel={selectedVesselBase}
                        voyage={voyage}
                        type={queryParams.rawReportsType}
                        counts={reportProps[queryParams.rawReportsType]}
                        onBackClick={this.handleRawReportsBackClick}
                        onRecalculateClick={this.handleRecalculate}
                    />
                ) : (
                    <div className={className}>
                        <div className="sten-content__header">
                            <h1 className="text-uppercase">{t('VOYAGE_PERFORMANCE.TITLE')}</h1>
                        </div>
                        <ScrollArea className="sten-content__body">
                            <div className="sten-content__section">
                                <div className="flex-row flex--wrap">
                                    <div className="col-lg-4 col-md-6 col-sm-8 col-xs-12">
                                        <label className="label">{t('VOYAGE_PERFORMANCE.VESSEL')}</label>
                                        <VesselSelect
                                            value={selectedVesselBase}
                                            onChange={this.handleVesselChange}
                                            filterLNGVessels
                                        />
                                    </div>
                                    <div className="col-lg-4 col-md-6 col-sm-10 col-xs-12">
                                        <label className="label">{t('VOYAGE_PERFORMANCE.VOYAGE')}</label>
                                        <VoyageSelect
                                            value={voyage}
                                            options={voyages}
                                            onChange={this.handleVoyageSelect}
                                        />
                                    </div>
                                    <div className="col-lg-4 col-sm-6 col-xs-12 flex-self-end">
                                        {voyage && (
                                            <div className="sten-voyage-performance__voyage-dates">
                                                <label className="label">
                                                    {t('VOYAGE_PERFORMANCE.START')}:
                                                    <span className="label--value">
                                                        {voyage.StartDate
                                                            ? ` ${TimeHelper.getFormatted(voyage.StartDate)}`
                                                            : ' -'}
                                                    </span>
                                                </label>
                                                <label className="label">
                                                    {t('VOYAGE_PERFORMANCE.END')}:
                                                    <span className="label--value">
                                                        {voyage.EndDate
                                                            ? ` ${TimeHelper.getFormatted(voyage.EndDate)}`
                                                            : ' -'}
                                                    </span>
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex-grow flex-self-center">
                                        {earnings && (
                                            <NetDaily
                                                netDaily={earnings.NetDaily}
                                                estimatedNetDaily={earnings.EstimatedNetDaily}
                                            />
                                        )}
                                    </div>
                                    {selectedVesselBase && (
                                        <div className="flex-shrink flex flex-self-end">
                                            <div className="flex-grow">
                                                <Link
                                                    className="btn-link btn-link--inverted form-element"
                                                    to={this.linkToVesselOnMap()}
                                                >
                                                    <span className="btn__icon icon icon-position" />
                                                    {voyage
                                                        ? t('VOYAGE_PERFORMANCE.SHOW_VOYAGE_ON_MAP')
                                                        : t('VOYAGE_PERFORMANCE.SHOW_VESSEL_ON_MAP')}
                                                </Link>
                                            </div>
                                            {permissions && permissions.SednaIntegrationEnabled
                                                && ConfigService.featureToggles.sednaIntegrationURL && (
                                                <React.Fragment>
                                                    <div className="sten-content__vertical-separator" />
                                                    <div className="flex-shrink">
                                                        <div className="btn-link form-element" onClick={this.goToSedna}>
                                                            <span className="btn__icon icon icon-sedna" />
                                                            {t('VOYAGE_PERFORMANCE.EXPLORE_IN_SEDNA')}
                                                            <span className="icon btn-link__outbound-icon" />
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="sten-content__section">
                                <div className="sten-content__separator" />
                            </div>
                            <div className="sten-content__section">
                                <div className="flex-row flex-row--lg">
                                    <div className="col-lg-12 col-xs-24 sten-voyage-performance__left-container">
                                        <PerformancePanel
                                            onParamsChange={this.handlePerformanceParamsChange}
                                            performance={performance}
                                            consumption={consumption}
                                            params={performanceParams}
                                        />
                                        <div className="show-md col-pad--md">
                                            {this.renderCargoAndFootprint({
                                                selectedDate: voyage && voyage.EndDate
                                            })}
                                        </div>
                                        <div className="show-md">
                                            <ChartPanel
                                                chartData={performanceCharts}
                                                endDate={voyage ? voyage.EndDate : ''}
                                                startDate={voyage ? voyage.StartDate : ''}
                                                updateQueryParams={this.props.router.updateQueryParams}
                                            />
                                        </div>
                                        <ReportsPanel
                                            className="flex-grow"
                                            reportProps={reportProps}
                                            onTypeClick={this.handleReportTypeClick}
                                        />
                                    </div>
                                    <div className="col-lg-12 hide-md">
                                        {this.renderCargoAndFootprint({
                                            selectedDate: voyage && voyage.EndDate
                                        })}
                                        <ChartPanel
                                            chartData={performanceCharts}
                                            endDate={voyage ? voyage.EndDate : ''}
                                            startDate={voyage ? voyage.StartDate : ''}
                                            updateQueryParams={this.props.router.updateQueryParams}
                                        />
                                    </div>
                                </div>
                            </div>
                            {reports.length > 0 && (
                                <div className="sten-content__section">
                                    <TimelinePanel
                                        activeReportId={queryParams.reportId}
                                        reports={reports}
                                        onReminderSend={sendReminder}
                                        permissions={permissions}
                                        deletedReportIds={this.props.deletedReportIds}
                                        editedReportIds={this.props.editedReportIds}
                                        updateQueryParams={this.props.router.updateQueryParams}
                                    />
                                </div>
                            )}
                        </ScrollArea>
                        {hasFooter && this.renderFooter()}
                    </div>
                )}
            </React.Fragment>
        );
    }
}

VoyagePerformance.propTypes = {
    consumption: PropTypes.objectOf(PropTypes.any),
    deletedReportIds: PropTypes.objectOf(PropTypes.any),
    editedReportIds: PropTypes.objectOf(PropTypes.any),
    euEtsPrice: PropTypes.objectOf(PropTypes.any),
    euEtsYearsAvailable: PropTypes.arrayOf(PropTypes.object).isRequired,
    generatePDF: PropTypes.func.isRequired,
    getVesselData: PropTypes.func.isRequired,
    getVoyageData: PropTypes.func.isRequired,
    getVoyageEuEts: PropTypes.func.isRequired,
    getVoyagePerformanceData: PropTypes.func.isRequired,
    performance: PropTypes.objectOf(PropTypes.any),
    performanceCharts: PropTypes.objectOf(PropTypes.any),
    performanceParams: PropTypes.shape({
        windForceMax: PropTypes.string,
        hoursSteamedMin: PropTypes.string,
        avgSpeedMin: PropTypes.string,
        instructedSpeedMin: PropTypes.string
    }),
    permissions: PropTypes.objectOf(PropTypes.any),
    queryParams: PropTypes.shape({
        vessel: PropTypes.number,
        voyage: PropTypes.number,
        rawReportsType: PropTypes.string,
        reportEdit: PropTypes.bool,
        reportId: PropTypes.number,
        reportType: PropTypes.number
    }).isRequired,
    reportProps: PropTypes.objectOf(PropTypes.any),
    resetAll: PropTypes.func,
    router: TRouter.isRequired,
    selectedVesselBase: PropTypes.objectOf(PropTypes.any),
    selectedVesselCii: TYearlyRatingWithCorrection,
    selectedVesselVoyages: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedVoyage: PropTypes.objectOf(PropTypes.any),
    selectedVoyageCargoSummary: PropTypes.objectOf(PropTypes.any),
    selectedVoyageEarnings: PropTypes.objectOf(PropTypes.any),
    selectedVoyageEuEts: PropTypes.objectOf(PropTypes.any),
    selectedVoyageFootprint: TFootprint,
    selectedVoyageReports: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedVoyageTankLayout: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedVoyageVmsFootprint: TVmsFootprint,
    sendReminder: PropTypes.func.isRequired
};

VoyagePerformance.defaultProps = {
    consumption: null,
    deletedReportIds: null,
    editedReportIds: null,
    euEtsPrice: null,
    performance: null,
    performanceCharts: null,
    performanceParams: null,
    permissions: null,
    reportProps: null,
    resetAll: undefined,
    selectedVesselBase: null,
    selectedVesselCii: null,
    selectedVoyage: null,
    selectedVoyageCargoSummary: null,
    selectedVoyageEarnings: null,
    selectedVoyageEuEts: null,
    selectedVoyageFootprint: null,
    selectedVoyageVmsFootprint: null
};

function mapStateToProps(state) {
    return {
        ciiYearlyRating: state.voyagePerformanceReducer.ciiYearlyRating,
        ciiYearlyRatingWithCorrection: state.voyagePerformanceReducer.ciiYearlyRatingWithCorrection,
        consumption: state.voyagePerformanceReducer.consumption,
        deletedReportIds: state.voyagePerformanceReducer.deletedReportIds,
        editedReportIds: state.voyagePerformanceReducer.editedReportIds,
        euEtsPrice: state.euEtsReducer.euEtsPrice,
        euEtsYearsAvailable: state.euEtsReducer.yearsAvailable,
        performance: state.voyagePerformanceReducer.performance,
        performanceCharts: getVoyagePerformanceCharts(state),
        performanceParams: state.voyagePerformanceReducer.performanceParams,
        permissions: state.userReducer.permissions,
        reportProps: getReportProps(state),
        selectedVesselBase: state.voyagePerformanceReducer.selectedVesselBase,
        selectedVesselCii: state.voyagePerformanceReducer.selectedVesselCii,
        selectedVesselVoyages: state.voyagePerformanceReducer.selectedVesselVoyages,
        selectedVoyage: state.voyagePerformanceReducer.selectedVoyage,
        selectedVoyageCargoSummary: state.voyagePerformanceReducer.selectedVoyageCargoSummary,
        selectedVoyageEarnings: state.voyagePerformanceReducer.selectedVoyageEarnings,
        selectedVoyageEuEts: state.voyagePerformanceReducer.selectedVoyageEuEts,
        selectedVoyageFootprint: state.voyagePerformanceReducer.selectedVoyageFootprint,
        selectedVoyageReports: getReversedVoyageReports(state),
        selectedVoyageTankLayout: state.voyagePerformanceReducer.selectedVoyageTankLayout,
        selectedVoyageVmsFootprint: state.voyagePerformanceReducer.selectedVoyageVmsFootprint
    };
}

function mapDispatchToProps(dispatch) {
    return {
        generatePDF: (isForFootprint) => generatePDF(dispatch, isForFootprint),
        getVesselData: vesselImo => getVesselData(dispatch, vesselImo),
        getVoyageData: voyageId => getVoyageData(dispatch, voyageId),
        getVoyageEuEts: year => getVoyageEuEts(dispatch, year),
        getVoyagePerformanceData: (voyageId, params) => getVoyagePerformanceData(dispatch, voyageId, params),
        resetAll: () => resetAll(dispatch),
        sendReminder: missingReportId => sendReminder(dispatch, missingReportId)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VoyagePerformance));
