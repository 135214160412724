import React from 'react';
import PropTypes from 'prop-types';
/* types */
import { MonthShape } from 'components/htec-date-picker/types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import Popover from 'components/popover/popover';
import MonthPicker from './month-picker';
import Input from 'components/input/input';
/* constants */
import constants from './month-popup-constants';

export default class MonthPopup extends React.PureComponent {
    state = {
        isPopoverVisible: false
    };

    togglePopover = () => this.setState({ isPopoverVisible: !this.state.isPopoverVisible });

    handleClickOutside = () => {
        this.setState({ isPopoverVisible: false });
    };

    handleChange = (args) => {
        this.props.onChange(args);
        this.setState({ isPopoverVisible: false });
    };

    handleClear = (e) => {
        e.stopPropagation();
        this.props.onChange(null);
    };

    formatValue = () => {
        if (this.props.value && this.props.value.month && this.props.value.year) {
            return this.props.formatDate(this.props.value.month, this.props.value.year);
        }
        return null;
    };

    render() {
        return (
            <Popover
                contentClassName={this.props.popoverClassName}
                content={this.state.isPopoverVisible && (
                    <MonthPicker
                        initialYear={this.props.initialYear}
                        minDate={this.props.minDate}
                        maxDate={this.props.maxDate}
                        onChange={this.handleChange}
                        value={this.props.value}
                    />
                )}
                onClickOutside={this.handleClickOutside}
                placement={this.props.placement}
                alignment={this.props.alignment}
                isVisible={this.state.isPopoverVisible}
            >
                <Input
                    readOnly
                    clearable={this.props.clearable}
                    placeholder={this.props.placeholder}
                    value={this.formatValue()}
                    disabled={this.props.disabled}
                    invalid={this.props.invalid}
                    onClick={this.togglePopover}
                    onClear={this.handleClear}
                />
            </Popover>
        );
    }
}

MonthPopup.propTypes = {
    alignment: PropTypes.oneOf(['start', 'center', 'end']),
    clearable: PropTypes.bool,
    contentClassName: PropTypes.string,
    disabled: PropTypes.bool,
    formatDate: PropTypes.func,
    initialYear: PropTypes.number,
    inputClassName: PropTypes.string,
    invalid: PropTypes.bool,
    maxDate: PropTypes.oneOfType([PropTypes.shape(MonthShape), PropTypes.object, PropTypes.number, PropTypes.string]),
    minDate: PropTypes.oneOfType([PropTypes.shape(MonthShape), PropTypes.object, PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    popoverClassName: PropTypes.string,
    renderMonth: PropTypes.func,
    renderYear: PropTypes.func,
    today: PropTypes.shape(MonthShape),
    value: PropTypes.shape(MonthShape)
};

MonthPopup.defaultProps = {
    alignment: 'end',
    clearable: false,
    contentClassName: '',
    disabled: false,
    formatDate: (month, year) => `${constants.monthNames.short[month]}, ${year}`,
    initialYear: undefined,
    inputClassName: '',
    invalid: false,
    maxDate: null,
    minDate: null,
    name: '',
    placeholder: translate('MONTH_POPUP.SELECT_MONTH'),
    placement: 'bottom',
    popoverClassName: '',
    renderMonth: undefined,
    renderYear: undefined,
    today: undefined,
    value: null
};
