import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { getSpeedSeverity, getConsumptionSeverity, formatValue } from 'utils/helpers/info-helper';
import { getPanelRows } from 'utils/helpers/template-helper';
/* components */
import Accordion from 'components/accordion/accordion';

const t = (key) => translate(`VESSEL_INFO.ENERGY.${key}`);
const tu = (key) => translate(`UNITS.${key}`);

const config = {
    AvgSpeed: {
        label: t('SPEED'),
        key: 'AvgSpeed',
        type: 'number',
        unit: tu('SPEED'),
        getSeverity: getSpeedSeverity,
        severityKey: 'InstructedSpeed'
    },
    InstructedSpeed: { label: t('INSTRUCTED'), key: 'InstructedSpeed', type: 'number', unit: tu('SPEED') },
    TotalConsumption: {
        label: t('CONSUMPTION'),
        key: 'TotalConsumption',
        type: 'number',
        unit: tu('AVG_CONSUMPTION'),
        getSeverity: getConsumptionSeverity,
        severityKey: 'Budget',
        title: t('CONSUMPTION_TITLE')
    },
    Budget: { label: t('BUDGET'), key: 'Budget', type: 'number', unit: tu('AVG_BUDGET') }
};


export class VesselInfoEnergyPerformance extends React.PureComponent {
    render() {
        const { latestVoyageReport: vr } = this.props;
        const columnClasses = ['col-8', 'col-7', 'col-9'];
        let weatherDirection = '';
        if (vr.WeatherDirection) {
            weatherDirection = vr.WeatherDirection.split(';').reverse().join('');
        }
        const rows = [
            [
                <label className="sten-panel__label">{config.AvgSpeed.label}</label>,
                <label className="sten-panel__label sten-panel__label--value">
                    {formatValue(vr, config.AvgSpeed)}
                </label>,
                <label className="sten-panel__label sten-panel__label--value">
                    <span className="text-secondary">{`${config.InstructedSpeed.label}: `}</span>
                    {formatValue(vr, config.InstructedSpeed)}
                </label>
            ],
            [
                <label className="sten-panel__label">{config.TotalConsumption.label}</label>,
                <label className="sten-panel__label sten-panel__label--value" title={config.TotalConsumption.title}>
                    {formatValue(vr, config.TotalConsumption)}
                </label>,
                <label className="sten-panel__label sten-panel__label--value">
                    <span className="text-secondary">{`${config.Budget.label}: `}</span>
                    {formatValue(vr, config.Budget)}
                </label>
            ],
            [
                <label className="sten-panel__label">{t('WIND_FORCE')}</label>,
                <label className="sten-panel__label sten-panel__label--value">
                    <span className="text-secondary">{`${t('REPORTER')}: `}</span>
                    {vr.WindForce ? `${vr.WindForce} ${weatherDirection}` : '-'}
                </label>,
                <label className="sten-panel__label sten-panel__label--value">
                    <span className="text-secondary">{`${t('EXTERNAL')}: `}</span>
                    {vr.ExternalWindForce ? vr.ExternalWindForce : '-'}
                </label>
            ]
        ];
        return (
            <Accordion header={this.props.children}>
                <div className="sten-content__section">
                    {getPanelRows(columnClasses, rows)}
                </div>
            </Accordion>
        );
    }
}

VesselInfoEnergyPerformance.propTypes = {
    children: PropTypes.node,
    latestVoyageReport: PropTypes.objectOf(PropTypes.any)
};

VesselInfoEnergyPerformance.defaultProps = {
    children: null,
    latestVoyageReport: null
};

export default VesselInfoEnergyPerformance;
