import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/*  helpers */
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import {
    getFixtureStatuses,
    getGroupings,
    getTableColumns,
    getVesselConfigurableProperties,
    getVessels,
    reset,
    setSelectedVessels,
    toggleCompose,
    toggleSettings,
    updateLoadReadyDate
} from './position-list-actions';
import { updateUserSettings } from '../user-actions';
/* selectors */
import { getVisibleColumns, getFiltersChanged } from './position-list-selectors';
/* components */
import PositionListTable from './position-list-table/position-list-table';
import PositionListFilters from './position-list-filters/position-list-filters';
import PositionListSettings from './position-list-settings/position-list-settings';
import PositionListCompose from './position-list-compose/position-list-compose';
import EmptyContent from 'components/empty-content/empty-content';
import IconButton from 'components/icon-button/icon-button';
import Modal from 'components/modal/modal';
/* styles */
import './position-list.scss';

const emptyArray = [];

class PositionList extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            areFiltersVisible: true,
            draftVessel: undefined,
            isDatePickerModalOpen: false
        };
    }

    componentDidMount() {
        this.props.getTableColumns();
        this.props.getVessels();
        this.props.getGroupings();
        this.props.getFixtureStatuses();
        this.props.getVesselConfigurableProperties();
    }

    componentDidUpdate(prevProps) {
        if (this.props.settingUpdated !== prevProps.settingUpdated
            && this.props.settingUpdated.name === 'PositionListFilters') {
            this.props.getVessels();
            this.props.setSelectedVessels(emptyArray);
        } else if (this.props.visibleColumns.find(this.isEtaDateColumn)
            && !prevProps.visibleColumns.find(this.isEtaDateColumn)) {
            this.props.getVessels();
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

    closeDatePickerModal = () => this.setState({ isDatePickerModalOpen: false });

    openChooseLoadReadyDateModal = (vessel) => {
        this.setState({
            draftVessel: vessel,
            isDatePickerModalOpen: true
        });
    };

    handleLoadReadyDateChange = (newDateMoment) => {
        this.props.updateLoadReadyDate(
            this.state.draftVessel.IMO,
            newDateMoment.toISOString()
        );
        this.setState({
            draftVessel: undefined,
            isDatePickerModalOpen: false
        });
    };

    isEtaDateColumn = column => column.ColumnPropertyName === 'EtaDate';

    openComposeView = () => {
        this.props.toggleCompose(true);
    };

    toggleFilters = () => {
        this.setState({
            areFiltersVisible: !this.state.areFiltersVisible
        });
    };

    toggleSettings = (bool) => {
        this.props.toggleSettings(bool);
    };

    render() {
        const isFooterVisible = (this.props.permissions
            && this.props.permissions.ComposeAndEditPositionList
            && this.props.vessels
            && this.props.vessels.length > 0
        ) && this.props.selectedVessels.length !== 0;
        let sectionClass = 'sten-content';

        if (isFooterVisible) {
            sectionClass += ' sten-content--has-footer';
        }

        let filtersClassName = 'sten-position-list-page__filters';

        if (!this.state.areFiltersVisible) {
            filtersClassName += ' sten-position-list-page__filters--hidden';
        }

        const loadReadyDateTimestamp = this.state.draftVessel && this.state.draftVessel.LoadReadyDate
            ? new Date(this.state.draftVessel.LoadReadyDate.LoadReadyDate).getTime()
            : Date.now();

        return (
            <div className="sten-position-list-page">
                {!this.props.isComposeViewActive
                    ? (
                        <section className={sectionClass}>
                            <div className="sten-content__header flex-row">
                                <div className="flex-grow">
                                    <h1 className="sten-content__title flex-row flex-row--sm">
                                        <div className="flex-shrink">
                                            {translate('POSITION_LIST.TITLE')}
                                        </div>
                                        <div className="flex-grow text-secondary">
                                            {TimeHelper.getFormatted()}
                                        </div>
                                    </h1>
                                </div>
                                <div className="flex-shrink">
                                    <IconButton
                                        isLink
                                        changeIndicator={this.props.filtersChanged}
                                        icon="icon-filter-outline"
                                        isActive={this.state.areFiltersVisible}
                                        onClick={this.toggleFilters}
                                    />
                                </div>
                                <div className="flex-shrink">
                                    <IconButton
                                        isLink
                                        icon="icon-cog"
                                        isActive={this.props.areSettingsVisible}
                                        onClick={this.toggleSettings}
                                    />
                                </div>
                            </div>
                            <div className="sten-content__body sten-position-list__body">
                                <div className={filtersClassName}>
                                    <PositionListFilters visibleColumns={this.props.visibleColumns} />
                                </div>
                                {this.props.vessels && this.props.vessels.length > 0
                                    ? (
                                        <PositionListTable
                                            onEditLoadReadyDate={this.openChooseLoadReadyDateModal}
                                            areFiltersVisible={this.state.areFiltersVisible}
                                        />
                                    ) : <EmptyContent>{translate('POSITION_LIST.EMPTY_TABLE_MESSAGE')}</EmptyContent>
                                }
                            </div>
                            {isFooterVisible && (
                                <footer className="sten-content__footer flex-row">
                                    <div className="flex-sm-grow col-xs-12">
                                        <div className="flex-row flex-center">
                                            <div className="flex-shrink hide-xs text-nowrap">
                                                <span>
                                                    {translate('POSITION_LIST.VESSELS_SELECTED', {
                                                        vessels: this.props.selectedVessels.length
                                                    }, true)}
                                                </span>
                                            </div>
                                            <div className="flex-sm-shrink flex-xs-grow">
                                                <button
                                                    className="btn btn--secondary col-24"
                                                    onClick={this.props.setSelectedVessels.bind(this, [])}
                                                >
                                                    <span className="btn__icon icon icon-close" />
                                                    {translate('POSITION_LIST.CLEAR_SELECTION')}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-sm-shrink col-xs-12">
                                        <button
                                            className="btn btn--primary col-24"
                                            onClick={this.openComposeView}
                                        >
                                            {translate('POSITION_LIST.COMPOSE_LIST')}
                                        </button>
                                    </div>
                                </footer>
                            )}
                        </section>
                    )
                    : (
                        <PositionListCompose />
                    )
                }
                <Modal.DatetimePicker
                    hideTimePicker
                    isOpen={this.state.isDatePickerModalOpen}
                    onCancel={this.closeDatePickerModal}
                    onSelect={this.handleLoadReadyDateChange}
                    timestamp={loadReadyDateTimestamp}
                    utc
                />
                <PositionListSettings
                    areSettingsVisible={this.props.areSettingsVisible}
                    groupings={this.props.groupings}
                    selectedGrouping={this.props.selectedGrouping}
                    selectedExportGrouping={this.props.selectedExportGrouping}
                    selectedVesselInfoConfig={this.props.selectedVesselInfoConfig}
                    selectedExportVesselInfoConfig={this.props.selectedExportVesselInfoConfig}
                    tableColumns={this.props.tableColumns}
                    visibleColumnIds={this.props.visibleColumnIds}
                    visibleExportColumnIds={this.props.visibleExportColumnIds}
                    updateUserSettings={this.props.updateUserSettings}
                    toggleSettings={this.toggleSettings}
                />
            </div>
        );
    }
}

PositionList.propTypes = {
    areSettingsVisible: PropTypes.bool.isRequired,
    filtersChanged: PropTypes.bool.isRequired,
    getFixtureStatuses: PropTypes.func.isRequired,
    getGroupings: PropTypes.func.isRequired,
    getTableColumns: PropTypes.func.isRequired,
    getVesselConfigurableProperties: PropTypes.func.isRequired,
    getVessels: PropTypes.func.isRequired,
    groupings: PropTypes.arrayOf(PropTypes.object).isRequired,
    isComposeViewActive: PropTypes.bool.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    reset: PropTypes.func.isRequired,
    selectedExportGrouping: PropTypes.number.isRequired,
    selectedExportVesselInfoConfig: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedGrouping: PropTypes.number.isRequired,
    selectedVesselInfoConfig: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedVessels: PropTypes.arrayOf(PropTypes.any).isRequired,
    setSelectedVessels: PropTypes.func.isRequired,
    settingUpdated: PropTypes.objectOf(PropTypes.any).isRequired,
    tableColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    toggleCompose: PropTypes.func.isRequired,
    toggleSettings: PropTypes.func.isRequired,
    updateLoadReadyDate: PropTypes.func.isRequired,
    updateUserSettings: PropTypes.func.isRequired,
    vessels: PropTypes.arrayOf(PropTypes.object).isRequired,
    visibleColumnIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    visibleColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    visibleExportColumnIds: PropTypes.arrayOf(PropTypes.number).isRequired
};

function mapStateToProps(state) {
    return {
        areSettingsVisible: state.positionListReducer.areSettingsVisible,
        filtersChanged: getFiltersChanged(state),
        groupings: state.positionListReducer.groupings,
        isComposeViewActive: state.positionListReducer.isComposeViewActive,
        permissions: state.userReducer.permissions,
        selectedExportGrouping: state.userReducer.settings
            && state.userReducer.settings.PositionsListFilterSelectedExportGroupBy,
        selectedExportVesselInfoConfig: state.userReducer.settings
            && state.userReducer.settings.PositionListExportVesselProperties,
        selectedGrouping: state.userReducer.settings && state.userReducer.settings.PositionsListFilterSelectedGroupBy,
        selectedVesselInfoConfig: state.userReducer.settings && state.userReducer.settings.PositionListVesselProperties,
        selectedVessels: state.positionListReducer.selectedVessels,
        settingUpdated: state.userReducer.settingUpdated,
        tableColumns: state.positionListReducer.tableColumns,
        vessels: state.positionListReducer.vessels,
        visibleColumnIds: state.userReducer.settings
            ? state.userReducer.settings.PositionsListVisibleColumns
            : emptyArray,
        visibleColumns: getVisibleColumns(state),
        visibleExportColumnIds: state.userReducer.settings
            ? state.userReducer.settings.PositionsListExportColumns
            : emptyArray
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getFixtureStatuses: () => getFixtureStatuses(dispatch),
        getGroupings: () => getGroupings(dispatch),
        getTableColumns: () => getTableColumns(dispatch),
        getVesselConfigurableProperties: () => getVesselConfigurableProperties(dispatch),
        getVessels: () => getVessels(dispatch),
        reset: () => reset(dispatch),
        setSelectedVessels: selectedVessels => setSelectedVessels(dispatch, selectedVessels),
        toggleCompose: flag => toggleCompose(dispatch, flag),
        toggleSettings: () => toggleSettings(dispatch),
        updateLoadReadyDate: (IMO, LoadReadyDate) => updateLoadReadyDate(dispatch, IMO, LoadReadyDate),
        updateUserSettings: settings => updateUserSettings(dispatch, settings)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PositionList);
