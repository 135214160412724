import React from 'react';
import PropTypes from 'prop-types';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* components */
import Attributes from './fleet-management-fleet-attributes';
import Checkbox from 'components/checkbox/checkbox';
import FlipSwitch from 'components/flip-switch/flip-switch';
import Input from 'components/input/input';
import Validation from 'components/validation/validation';
import VesselTable from '../vessel-table/fleet-management-vessel-table';
/* services */
import ConfigService from 'services/config-api/config-service';
import DynamicFleetService from 'services/core-api/dynamic-fleet-service';

const emptyFleet = {
    VesselGroupName: '',
    VesselGroupId: null,
    GroupNameImmutable: false,
    IsPublic: false,
    IsDynamic: false,
    DynamicFleetMainAttributeId: null,
    DynamicFleetSecondaryAttributeId: null,
    DynamicFleetValueAttribute: null
};

const emptyArray = [];

export class FleetEdit extends React.PureComponent {
    state = {
        fleet: emptyFleet,
        fleetVessels: emptyArray,
        attributes: null,
        prevFleet: null,
        showOnlySelected: false
    };

    static getDerivedStateFromProps(props, state) {
        if (state.prevFleet !== props.fleet) {
            return {
                prevFleet: props.fleet,
                fleet: props.fleet ? { ...props.fleet } : emptyFleet,
                fleetVessels: props.fleet && props.fleet.Vessels ? [...props.fleet.Vessels] : emptyArray,
                attributes: props.fleet && props.fleet.IsDynamic
                    ? {
                        SecondaryAttributeId: props.fleet.DynamicFleetSecondaryAttributeId,
                        Value: props.fleet.DynamicFleetValueAttribute
                    } : null
            };
        }
        return null;
    }

    toggleIsPublic = () => {
        this.setState({
            fleet: { ...this.state.fleet, IsPublic: !this.state.fleet.IsPublic }
        });
    };

    toggleIsDynamic = () => {
        this.setState({
            fleet: { ...this.state.fleet, IsDynamic: !this.state.fleet.IsDynamic }
        });
    };

    toggleShowOnlySelected = () => this.setState({ showOnlySelected: !this.state.showOnlySelected });

    handleVesselFleetNameChange = value => {
        this.setState({ fleet: { ...this.state.fleet, VesselGroupName: value } });
    };

    handleAttributeChange = (val) => {
        this.setState({ fleet: { ...this.state.fleet, ...val } });
    };

    handleAttributeApply = () => {
        this.setState({
            attributes: {
                SecondaryAttributeId: this.state.fleet.DynamicFleetSecondaryAttributeId,
                Value: this.state.fleet.DynamicFleetValueAttribute
            }
        });
    };

    fetchDynamicFleetVessels = (params) => {
        const {
            DynamicFleetMainAttributeId,
            DynamicFleetSecondaryAttributeId,
            DynamicFleetValueAttribute
        } = this.state.fleet;
        if (DynamicFleetMainAttributeId !== null
            && DynamicFleetSecondaryAttributeId !== null
            && DynamicFleetValueAttribute !== null) {
            return DynamicFleetService.getVessels(params);
        }
        return Promise.resolve(null);
    };

    handleSelectionChange = (fleetVessels) => {
        this.setState({
            fleetVessels,
            showOnlySelected: fleetVessels.length === 0 ? false : this.state.showOnlySelected
        });
    };

    handleClearClick = () => this.setState({ fleetVessels: emptyArray, showOnlySelected: false });

    handleCancelClick = () => {
        if (this.props.fleet?.VesselGroupId) {
            this.props.router.navigate(`${appRoutes.Vessel.FleetManagement}/${this.props.fleet.VesselGroupId}`);
        } else {
            this.props.router.navigate(appRoutes.Vessel.FleetManagement);
        }
    };

    handleFormSubmit = () => {
        const { fleet, fleetVessels } = this.state;
        const newFleet = {
            VesselGroupId: fleet.VesselGroupId,
            GroupName: this.state.fleet.VesselGroupName,
            IsPublic: fleet.IsPublic,
            IsDynamic: fleet.IsDynamic,
            Imos: fleetVessels.map(vessel => vessel.IMO),
            DynamicFleetMainAttributeId: null,
            DynamicFleetSecondaryAttributeId: null,
            DynamicFleetValueAttribute: null
        };
        if (fleet.IsDynamic) {
            newFleet.Imos = [];
            newFleet.DynamicFleetMainAttributeId = fleet.DynamicFleetMainAttributeId;
            newFleet.DynamicFleetSecondaryAttributeId = fleet.DynamicFleetSecondaryAttributeId;
            newFleet.DynamicFleetValueAttribute = fleet.DynamicFleetValueAttribute;
        }
        this.props.onUpdate(newFleet);
    }

    saveFormRef = (c) => { this.formRef = c; }

    fleetNameValidations = {
        required: true,
        minLength: 3,
        maxLength: 50,
        differsFrom: {
            value: 'My Fleet',
            hint: 'Fleet name can\'t be "My Fleet"'
        }
    };

    render() {
        const { options, permissions } = this.props;
        const { attributes, fleet, fleetVessels, showOnlySelected } = this.state;
        const showPublicOption = permissions.CanCreatePublicFleetGroups && !this.state.fleet.GroupNameImmutable;
        return (
            <Validation.Form
                ref={this.saveFormRef}
                className="sten-content sten-fleet-management-fleet-edit sten-content--has-footer"
                onSubmit={this.handleFormSubmit}
            >
                <div className="sten-content__header flex-row flex-center">
                    <div className="col-lg-12 col-md-10 col-xs-8">
                        <Validation.Wrapper
                            hintsOnHover
                            validations={this.fleetNameValidations}
                            validate={!fleet.GroupNameImmutable}
                        >
                            <Input
                                name="fleetName"
                                value={fleet.VesselGroupName}
                                onChange={this.handleVesselFleetNameChange}
                                readOnly={fleet.GroupNameImmutable}
                                placeholder={t('FLEET_MANAGEMENT.FLEET_EDIT.FLEET_NAME_PLACEHOLDER')}
                            />
                        </Validation.Wrapper>
                    </div>
                    <div className="flex-grow">
                        {showPublicOption && (
                            <Checkbox onChange={this.toggleIsPublic} isChecked={fleet.IsPublic}>
                                {t('FLEET_MANAGEMENT.FLEET_EDIT.SHARED_FLEET')}
                            </Checkbox>
                        )}
                    </div>
                    {ConfigService.featureToggles.showDynamicFleets && (
                        <div className="flex-shrink">
                            <FlipSwitch
                                doubleOn
                                activeLabel={t('FLEET_MANAGEMENT.FLEET_EDIT.DYNAMIC')}
                                activeTitle={t('FLEET_MANAGEMENT.FLEET_EDIT.DYNAMIC_TITLE')}
                                inactiveLabel={t('FLEET_MANAGEMENT.FLEET_EDIT.STATIC')}
                                inactiveTitle={t('FLEET_MANAGEMENT.FLEET_EDIT.STATIC_TITLE')}
                                onChange={this.toggleIsDynamic}
                                value={fleet.IsDynamic}
                                disabled={!!fleet.VesselGroupId}
                            />
                        </div>
                    )}
                </div>
                {fleet.IsDynamic && (
                    <Attributes
                        isEditable
                        onChange={this.handleAttributeChange}
                        onApply={this.handleAttributeApply}
                        mainAttributeOptions={options.mainAttributes}
                        mainAttributeId={fleet.DynamicFleetMainAttributeId}
                        secondaryAttributeId={fleet.DynamicFleetSecondaryAttributeId}
                        valueAttribute={fleet.DynamicFleetValueAttribute}
                    />
                )}
                <div className="sten-content__body">
                    {fleet.IsDynamic
                        ? (
                            <VesselTable
                                options={options}
                                fetchVessels={this.fetchDynamicFleetVessels}
                                areFiltersVisible={false}
                                filters={attributes}
                            />
                        ) : (
                            <VesselTable
                                isSelectable
                                onSelectionChange={this.handleSelectionChange}
                                options={options}
                                selectedVessels={fleetVessels}
                                vessels={showOnlySelected ? fleetVessels : null}
                                areFiltersEnabled={!showOnlySelected}
                            />
                        )
                    }
                </div>
                <div className="sten-content__footer flex-row">
                    {!fleet.IsDynamic && (
                        <React.Fragment>
                            <div className="col-lg-3 col-md-4 col-sm-6 hide-sm">
                                {t('FLEET_MANAGEMENT.FLEET_EDIT.VESSELS_SELECTED', {
                                    count: fleetVessels.length
                                }, true)}
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12 hide-xs">
                                <Checkbox
                                    isDisabled={fleetVessels.length === 0}
                                    onChange={this.toggleShowOnlySelected}
                                    isChecked={showOnlySelected}
                                >
                                    {t('FLEET_MANAGEMENT.FLEET_EDIT.SHOW_ALL_SELECTED')}
                                </Checkbox>
                            </div>
                            <div className="sten-content__vertical-separator hide-xs" />
                            <div className="col-lg-4 col-md-5 col-sm-6 hide-sm">
                                <button
                                    type="button"
                                    className="btn btn--secondary col-24"
                                    onClick={this.handleClearClick}
                                >
                                    {t('FLEET_MANAGEMENT.FLEET_EDIT.CLEAR_SELECTION')}
                                </button>
                            </div>
                        </React.Fragment>
                    )}
                    <div className="flex-grow hide-xs" />
                    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <button type="button" className="btn btn--secondary col-24" onClick={this.handleCancelClick}>
                            {t('GLOBAL.CANCEL')}
                        </button>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-xs-12">
                        <Validation.Button className="btn btn--primary col-24">
                            {fleet.VesselGroupId ? t('GLOBAL.SAVE') : t('GLOBAL.CREATE')}
                        </Validation.Button>
                    </div>
                </div>
            </Validation.Form>
        );
    }
}

FleetEdit.propTypes = {
    canCreatePublicFleetGroups: PropTypes.bool,
    fleet: PropTypes.objectOf(PropTypes.any),
    onUpdate: PropTypes.func.isRequired,
    options: PropTypes.shape({
        commercialOperators: PropTypes.arrayOf(PropTypes.object),
        companyFleets: PropTypes.arrayOf(PropTypes.object),
        segments: PropTypes.arrayOf(PropTypes.object),
        technicalManagers: PropTypes.arrayOf(PropTypes.object),
        vesselOwners: PropTypes.arrayOf(PropTypes.object),
        mainAttributes: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    router: TRouter.isRequired
};

FleetEdit.defaultProps = {
    canCreatePublicFleetGroups: false,
    fleet: null
};

export default withRouter(FleetEdit);
