import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { getClassName } from 'utils/helpers/info-helper';
/* styles */
import './cii-rating.scss';

class CiiRating extends React.PureComponent {
    static defaultValue = t('GLOBAL.NOT_AVAILABLE');

    static ratingsEnum = {
        F: 'sten-cii-rating--f',
        E: 'sten-cii-rating--e',
        D: 'sten-cii-rating--d',
        C: 'sten-cii-rating--c',
        B: 'sten-cii-rating--b',
        A: 'sten-cii-rating--a'
    }

    render() {
        const rating = CiiRating.ratingsEnum[this.props.value];
        const classNames = getClassName('sten-cii-rating', this.props.className, {
            'sten-cii-rating--estimated': this.props.isEstimated,
            'sten-cii-rating--absent': !this.props.value || !rating,
            [rating]: !!rating
        });

        return (
            <span className={classNames}>{this.props.value || CiiRating.defaultValue}</span>
        );
    }
}

CiiRating.propTypes = {
    className: PropTypes.string,
    isEstimated: PropTypes.bool,
    value: PropTypes.string
};

CiiRating.defaultProps = {
    className: '',
    isEstimated: false,
    value: null
};

export default CiiRating;
