import React from 'react';
import PropTypes from 'prop-types';
/* types */
import {
    TVmsFootprint,
    TYearlyRatingWithCorrection
} from 'components/footprint-table/footprint-table-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { formatNumber, formatValue } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* services */
import ConfigService from 'services/config-api/config-service';
import EuEtsService from 'services/mt-api/euets-service';
/* components */
import FootprintTable from 'components/footprint-table/footprint-table';
import TankLayoutVessel from 'components/tank-layout/vessel/tank-layout-vessel';
import RawReportsTablePDF from 'components/raw-reports-table/export/raw-reports-table-pdf';
import TrendIcon from 'components/trend-icon/trend-icon';
/* styles */
import styles from './voyage-performance-pdf-style';
/* constants */
import {
    ballastLadenTotalRows,
    cargoRows,
    consumptionTableColumns,
    consumptionTableRows
} from '../voyage-performance-constants';

class VoyagePerformancePDF extends React.PureComponent {
    renderRow = (data, row) => (
        <tr key={row.key}>
            <td>{row.label}</td>
            <td>{formatValue(data, row)}</td>
        </tr>
    );

    renderRows = (data, rows) => {
        return (
            <tbody>
                {rows.map(row => this.renderRow(data, row))}
            </tbody>
        );
    };

    renderConsumptionRows = (data) => consumptionTableRows.map(row => (
        <tr key={row.key}>
            <td>{row.label}</td>
            {consumptionTableColumns.map(col => (
                <td key={col}>{formatValue(data, row, `${col}.${row.key}`)}</td>
            ))}
        </tr>
    ));

    renderFootprintContent = (withTitle = true) => (
        <React.Fragment>
            {withTitle && (
                <div className="report-title">{t('VOYAGE_PERFORMANCE.FOOTPRINT.TITLE')}</div>
            )}
            <div className="row">
                <div className="col-12">
                    <FootprintTable
                        selectedDate={this.props.voyage?.EndDate}
                        vesselCii={this.props.selectedVesselCii}
                        voyageEuEts={this.props.selectedVoyageEuEts}
                        voyageFootprint={this.props.selectedVoyageFootprint}
                        voyageVmsFootprint={this.props.selectedVoyageVmsFootprint}
                        euEtsPrice={this.props.euEtsPrice}
                        showVesselCii={!ConfigService.hiddenFeatures.voyagePerformanceVesselCiiRating}
                        showVoyageEmissions={!ConfigService.hiddenFeatures.voyagePerformanceVoyageEmissions}
                        showVoyageEnergyEfficiency={
                            !ConfigService.hiddenFeatures.voyagePerformanceVoyageEnergyEfficiency}
                        showConsumption={!ConfigService.hiddenFeatures.voyagePerformanceFootprintFuelConsumptions}
                        showVmsVoyageEmissions={!ConfigService.hiddenFeatures.voyagePerformanceVmsVoyageEmissions}
                        tableSize="sm"
                    />
                </div>
                {ConfigService.featureToggles.showEuEts && (
                    <div className="col-12">
                        <img
                            className="footprint-image"
                            src={EuEtsService.getEtsChartLink(this.props.euEtsPrice?.currency, 'light', false)}
                            alt="footprint-img"
                        />
                    </div>
                )}
            </div>
        </React.Fragment>
    );

    render() {
        const {
            cargoSummary,
            cargoWings,
            companyLogo,
            consumption,
            earnings,
            isForFootprint,
            performance,
            vesselName,
            voyage
        } = this.props;
        const ballastTable = this.renderRows(performance && performance.Ballast, ballastLadenTotalRows);
        const ladenTable = this.renderRows(performance && performance.Laden, ballastLadenTotalRows);
        const totalTable = this.renderRows(performance && performance.TotalVoyage, ballastLadenTotalRows);
        const cargoSummaryTable = this.renderRows(cargoSummary, cargoRows);
        const consumptionsTable = this.renderConsumptionRows(consumption);
        const netDailyFormatted = earnings && typeof earnings.NetDaily === 'number'
            ? `$${formatNumber(earnings.NetDaily, 0)}`
            : '-';
        const estimatedValueFormatted = earnings && typeof earnings.EstimatedNetDaily === 'number'
            ? `$${formatNumber(earnings.EstimatedNetDaily, 0)}`
            : '-';
        const startDate = voyage?.StartDate ? ` ${TimeHelper.getFormatted(voyage.StartDate)}` : ' -';
        const endDate = voyage?.EndDate ? ` ${TimeHelper.getFormatted(voyage.EndDate)}` : ' -';
        return (
            <html lang="en">
                <head>
                    {/* eslint-disable-next-line react/no-danger */}
                    <style dangerouslySetInnerHTML={{ __html: styles }} />
                </head>
                <body>
                    <div className="page">
                        <header className="summary-header">
                            <div
                                className="summary-header-company-logo"
                                style={{ backgroundImage: companyLogo ? `url(${companyLogo.src})` : '' }}
                            />
                            <div>
                                <div className="summary-vessel-name-text">{vesselName}</div>
                                <div className="summary-voyage-number-text">
                                    {`${t('VOYAGE_PERFORMANCE.VOYAGE')}: `}
                                    <span style={{ fontWeight: '700' }}>
                                        {`${voyage.VoyageNumber} ${voyage.VoyagePortInfo}`}
                                    </span>
                                </div>
                                <div className="summary-voyage-number-text">
                                    {`${t('VOYAGE_PERFORMANCE.START')}: `}
                                    <span style={{ fontWeight: '700' }}>{startDate}</span>
                                    {` ${t('VOYAGE_PERFORMANCE.END')}: `}
                                    <span style={{ fontWeight: '700' }}>{endDate}</span>
                                </div>
                            </div>
                            <div
                                className="summary-header-orbit-logo"
                                style={{ backgroundImage: `url(${this.props.orbitLogo})` }}
                            />
                        </header>
                        {isForFootprint ? this.renderFootprintContent() : (
                            <React.Fragment>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="report-title">{t('GLOBAL.TOTAL')}</div>
                                        <table className="sten-table sten-table--sm">{totalTable}</table>
                                    </div>
                                    <div className="col-6">
                                        <div className="report-title">{t('GLOBAL.BALLAST')}</div>
                                        <table className="sten-table sten-table--sm">{ballastTable}</table>
                                    </div>
                                    <div className="col-6">
                                        <div className="report-title">{t('GLOBAL.LADEN')}</div>
                                        <table className="sten-table sten-table--sm">{ladenTable}</table>
                                    </div>
                                    {earnings && (
                                        <div className="col-6">
                                            <div className="report-title">
                                                {t('VOYAGE_PERFORMANCE.NET_DAILY.TITLE')}
                                            </div>
                                            <div className="summary-vessel-name-text flex flex-center">
                                                <dev>{netDailyFormatted}</dev>
                                                {typeof earnings.NetDaily === 'number' && (
                                                    <div className="sten-voyage-performance-net-daily__trend">
                                                        <TrendIcon
                                                            value={earnings.NetDaily}
                                                            breakpoints={[
                                                                earnings.EstimatedNetDaily - 500,
                                                                earnings.EstimatedNetDaily + 500
                                                            ]}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            <div className="summary-voyage-number-text">
                                                {`${t('VOYAGE_PERFORMANCE.NET_DAILY.ESTIMATED')}: `}
                                                <span style={{ fontWeight: '700' }}>{estimatedValueFormatted}</span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="report-title">{t('VOYAGE_PERFORMANCE.CARGO.TITLE')}</div>
                                        <table className="sten-table sten-table--sm">
                                            <tbody>
                                                <tr>
                                                    <td colSpan="2" className="tank-layout-cell">
                                                        <TankLayoutVessel wings={cargoWings} />
                                                    </td>
                                                </tr>
                                            </tbody>
                                            {cargoSummaryTable}
                                        </table>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </div>
                    {!isForFootprint && (<div className="page">{this.renderFootprintContent()}</div>)}
                    {!isForFootprint && (
                        <div className="page">
                            <div className="row">
                                <div className="col-24">
                                    <div className="report-title">
                                        {t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.TITLE')}
                                    </div>
                                    <table className="sten-table sten-table--sm">
                                        <thead>
                                            <tr>
                                                <th rowSpan="2">
                                                    {t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.VALUE')}
                                                </th>
                                                <th colSpan="3" className="text-center">
                                                    {t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.AT_SEA')}
                                                </th>
                                                <th className="text-center">
                                                    {t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.IN_PORT')}
                                                </th>
                                            </tr>
                                            <tr>
                                                <th className="text-center">
                                                    {t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.TOTAL')}
                                                </th>
                                                <th className="text-center">
                                                    {t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.BALLAST')}
                                                </th>
                                                <th className="text-center">
                                                    {t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.LADEN')}
                                                </th>
                                                <th className="text-center">
                                                    {t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.TOTAL')}
                                                </th>
                                            </tr>
                                        </thead>
                                        {consumptionsTable}
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                    <RawReportsTablePDF
                        dailyReports={this.props.dailyReports}
                        arrivalReports={this.props.arrivalReports}
                        cargoReports={this.props.cargoReports}
                        departureReports={this.props.departureReports}
                        eventReports={this.props.eventReports}
                        sofReports={this.props.sofReports}
                        rowConfigs={this.props.rowConfigs}
                    />
                </body>
            </html>
        );
    }
}

VoyagePerformancePDF.propTypes = {
    arrivalReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    cargoReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    cargoSummary: PropTypes.objectOf(PropTypes.any).isRequired,
    cargoWings: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
    companyLogo: PropTypes.objectOf(PropTypes.any),
    consumption: PropTypes.objectOf(PropTypes.any).isRequired,
    dailyReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    departureReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    earnings: PropTypes.objectOf(PropTypes.any),
    euEtsPrice: PropTypes.objectOf(PropTypes.any),
    eventReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    isForFootprint: PropTypes.bool,
    orbitLogo: PropTypes.string.isRequired,
    performance: PropTypes.objectOf(PropTypes.any).isRequired,
    rowConfigs: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedVesselCii: TYearlyRatingWithCorrection.isRequired,
    selectedVoyageFootprint: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedVoyageEuEts: PropTypes.objectOf(PropTypes.any),
    selectedVoyageVmsFootprint: TVmsFootprint,
    sofReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    vesselName: PropTypes.string.isRequired,
    voyage: PropTypes.objectOf(PropTypes.any).isRequired
};

VoyagePerformancePDF.defaultProps = {
    companyLogo: null,
    earnings: null,
    euEtsPrice: null,
    isForFootprint: false,
    selectedVoyageEuEts: null,
    selectedVoyageVmsFootprint: null
};

export default VoyagePerformancePDF;
