import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { splitSegments } from 'components/segment-select/segment-select-helper';
/* components */
import Input from 'components/input/input';
import Select from 'components/select/select';
import CommercialOperatorSelect from './fleet-management-commercial-operator-select';

const t = (key, values) => translate(`FLEET_MANAGEMENT.FILTERS.${key}`, values);

const emptyArray = [];

const filterKeys = [
    'commercialOperators', 'companyFleets', 'segments', 'technicalManagers', 'vesselOwners'
];

const initialState = {
    searchTerm: '',
    commercialOperators: emptyArray,
    companyFleets: emptyArray,
    segments: emptyArray,
    technicalManagers: emptyArray,
    vesselOwners: emptyArray
};

class VesselTableFilters extends React.PureComponent {
    state = initialState;

    componentWillUnmount() {
        if (this.filterChangeDebounce) {
            clearTimeout(this.filterChangeDebounce);
        }
    }

    areFiltersChanged = (state = this.state) => state.searchTerm.trim().length > 0
        || filterKeys.some(filterKey => state[filterKey] && state[filterKey].length > 0);

    handleFilterUpdate = (state = this.state) => {
        if (this.filterChangeDebounce) {
            clearTimeout(this.filterChangeDebounce);
        }
        this.filterChangeDebounce = setTimeout(() => {
            this.props.onFilterChange(state, this.areFiltersChanged(state));
        }, 1000);
    }

    handleFilterChange = (key) => (val) => {
        this.setState({ [key]: val }, this.handleFilterUpdate);
    };

    handleResetClick = () => this.setState(initialState, this.handleFilterUpdate);

    handleSearchTermChange = (val) => {
        const trimmedVal = val.trim();
        if (trimmedVal.length === 0 || trimmedVal.length > 2) {
            this.handleFilterChange('searchTerm')(val);
        }
    };

    handleCommercialOperatorsChange = this.handleFilterChange('commercialOperators');

    handleCompanyFleetsChange = this.handleFilterChange('companyFleets');

    handleSegmentsChange = this.handleFilterChange('segments');

    handleTechnicalManagersChange = this.handleFilterChange('technicalManagers');

    handleVesselOwnersChange = this.handleFilterChange('vesselOwners');

    render() {
        const { options } = this.props;
        const {
            searchTerm,
            commercialOperators,
            companyFleets,
            segments,
            technicalManagers,
            vesselOwners
        } = this.state;
        return (
            <div className="sten-fleet-management-vessel-table-filters">
                <div className="sten-content__section">
                    <div className="form-row flex-row flex-row--sm">
                        <div className="col-md-6 col-sm-8 col-xs-24">
                            <label className="label text-nowrap">{t('SEARCH_VESSELS')}</label>
                            <Input
                                value={searchTerm}
                                placeholder={t('SEARCH_VESSELS_PLACEHOLDER')}
                                onChange={this.handleSearchTermChange}
                                inputFieldProps={{ maxLength: 50 }}
                            />
                        </div>
                        <div className="col-md-6 col-sm-8 col-xs-24 form-row--xs">
                            <label className="label text-nowrap">{t('COMMERCIAL_OPERATOR')}</label>
                            <CommercialOperatorSelect
                                clearable
                                multiple
                                value={commercialOperators}
                                onChange={this.handleCommercialOperatorsChange}
                                placeholder={t('COMMERCIAL_OPERATOR_PLACEHOLDER')}
                            />
                        </div>
                        <div className="col-md-6 col-sm-8 col-xs-24 form-row--xs">
                            <label className="label text-nowrap">{t('COMPANY_FLEET')}</label>
                            <Select
                                clearable
                                searchable
                                multiple
                                value={companyFleets}
                                valueKey="Id"
                                labelKey="Name"
                                options={options.companyFleets}
                                onChange={this.handleCompanyFleetsChange}
                                placeholder={t('COMPANY_FLEET_PLACEHOLDER')}
                            />
                        </div>
                    </div>
                    <div className="form-row flex-row flex-row--sm flex-end">
                        <div className="col-md-6 col-sm-8 col-xs-24">
                            <label className="label text-nowrap">{t('SEGMENT')}</label>
                            <Select
                                clearable
                                searchable
                                multiple
                                value={segments}
                                valueKey="VesselTypeId"
                                labelKey="VesselTypeName"
                                options={options.segments}
                                onChange={this.handleSegmentsChange}
                                placeholder={t('SEGMENT_PLACEHOLDER')}
                                splitOptionsIntoSections={splitSegments}
                            />
                        </div>
                        <div className="col-md-6 col-sm-8 col-xs-24 form-row--xs">
                            <label className="label text-nowrap">{t('TECHNICAL_MANAGER')}</label>
                            <Select
                                clearable
                                searchable
                                multiple
                                value={technicalManagers}
                                valueKey="Id"
                                labelKey="Name"
                                options={options.technicalManagers}
                                onChange={this.handleTechnicalManagersChange}
                                placeholder={t('TECHNICAL_MANAGER_PLACEHOLDER')}
                            />
                        </div>
                        <div className="col-md-6 col-sm-8 col-xs-24 form-row--xs">
                            <label className="label text-nowrap">{t('VESSEL_OWNER')}</label>
                            <Select
                                clearable
                                searchable
                                multiple
                                value={vesselOwners}
                                valueKey="Id"
                                labelKey="Name"
                                options={options.vesselOwners}
                                onChange={this.handleVesselOwnersChange}
                                placeholder={t('VESSEL_OWNER_PLACEHOLDER')}
                            />
                        </div>
                        <div className="col-md-6 hide-sm">
                            <button
                                className="btn btn--secondary col-24"
                                onClick={this.handleResetClick}
                                disabled={!this.areFiltersChanged()}
                            >
                                {t('RESET_FILTERS')}
                            </button>
                        </div>
                    </div>
                    <div className="form-row show-sm">
                        <button
                            className="btn btn--secondary col-24"
                            onClick={this.handleResetClick}
                            disabled={!this.areFiltersChanged()}
                        >
                            {t('RESET_FILTERS')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

VesselTableFilters.propTypes = {
    className: PropTypes.string,
    onFilterChange: PropTypes.func.isRequired,
    options: PropTypes.shape({
        companyFleets: PropTypes.arrayOf(PropTypes.object),
        segments: PropTypes.arrayOf(PropTypes.object),
        technicalManagers: PropTypes.arrayOf(PropTypes.object),
        vesselOwners: PropTypes.arrayOf(PropTypes.object)
    }).isRequired
};

VesselTableFilters.defaultProps = {
    className: ''
};

export default VesselTableFilters;
