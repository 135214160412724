import React from 'react';
import PropTypes from 'prop-types';
/* helpers */
import { omitObjectKeys } from 'utils/helpers/info-helper';
/* styles */
import './positioner.scss';

const propsToOmit = ['left', 'top', 'className', 'style'];

export default class Positioner extends React.PureComponent {
    componentDidMount() {
        this.map = document.getElementsByClassName('ol-viewport')[0];
    }

    forwardWheelEventToMap = (event) => {
        const newEvent = new WheelEvent(event.nativeEvent.type, event.nativeEvent);
        this.map.dispatchEvent(newEvent);
    };

    render() {
        let style = {
            transform: `translate(${this.props.left}px, ${this.props.top}px)`
        };
        if (this.props.style) {
            style = { ...this.props.style, ...style };
        }

        let className = 'positioner ';
        if (this.props.className) {
            className += this.props.className;
        }

        return (
            <div
                onWheel={this.forwardWheelEventToMap}
                className={className}
                style={style}
                {...omitObjectKeys(this.props, propsToOmit)}
            >
                {this.props.children}
            </div>
        );
    }
}

Positioner.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    left: PropTypes.number,
    style: PropTypes.objectOf(PropTypes.any),
    top: PropTypes.number
};

Positioner.defaultProps = {
    className: '',
    style: null,
    top: 0,
    left: 0
};
