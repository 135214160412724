import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { getClassName } from 'utils/helpers/info-helper';
/* style */
import './tank-layout-bar.scss';

class TankLayoutBar extends React.PureComponent {
    render() {
        let value = this.props.value || 0;
        if (value > 100) {
            value = 100;
        }
        const className = getClassName('sten-tank-layout-bar', this.props.className, {
            'sten-tank-layout-bar--slop': this.props.isSlop,
            'sten-tank-layout-bar--invalid': value > 100
        });

        return (
            <div className={className}>
                <div className="sten-tank-layout-bar__level" style={{ width: `${value}%` }} />
            </div>
        );
    }
}

TankLayoutBar.propTypes = {
    className: PropTypes.string,
    isSlop: PropTypes.bool.isRequired,
    value: PropTypes.number
};

TankLayoutBar.defaultProps = {
    className: '',
    value: 0
};

export default TankLayoutBar;
