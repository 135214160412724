import React from 'react';
import PropTypes from 'prop-types';
/* styles */
import './copyright-info.scss';

export default class OlMapCopyrightInfo extends React.PureComponent {
    render() {
        return (
            <div className="sten-map-copyright-info">
                <a href="http://mapbox.com/about/maps" className="mapbox-wordmark">Mapbox</a>
                <div className="mapbox-attribution-container">
                    <a href="https://www.mapbox.com/about/maps/">© Mapbox | </a>
                    {this.props.mapTypeId === 2 && (
                        <a href="https://www.digitalglobe.com/">© DigitalGlobe | </a>
                    )}
                    <a href="http://www.openstreetmap.org/copyright">© OpenStreetMap | </a>
                    <a href="https://www.mapbox.com/map-feedback/"><strong>Improve this map</strong></a>
                </div>
            </div>
        );
    }
}

OlMapCopyrightInfo.propTypes = {
    mapTypeId: PropTypes.number.isRequired
};
