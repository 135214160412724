/* eslint-disable max-len */
/* utils */
import { translate } from 'utils/i18n/i18n-model';

export const performanceSummarySections = [
    {
        key: 'Speed',
        label: translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.PERFORMANCE_SUMMARY_PANEL.SPEED'),
        type: 'number',
        decimals: 1,
        unit: translate('UNITS.SPEED'),
        optimizationKey: 'IsSpeedPerformanceOptimal',
        charterpartyLabel: translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.PERFORMANCE_SUMMARY_PANEL.INSTRUCTED'),
        charterpartyKey: 'CharterPartySpeed'
    },
    {
        key: 'HfoConsumptionCalculated',
        label: translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.PERFORMANCE_SUMMARY_PANEL.HFO'),
        type: 'number',
        decimals: 1,
        unit: translate('UNITS.CONSUMPTION'),
        optimizationKey: 'IsHfoConsumptionPerformanceOptimal',
        charterpartyLabel: translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.PERFORMANCE_SUMMARY_PANEL.BUDGET'),
        charterpartyKey: 'HfoConsumptionCharterParty'
    },
    {
        key: 'MdoConsumptionCalculated',
        label: translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.PERFORMANCE_SUMMARY_PANEL.MDO'),
        type: 'number',
        decimals: 1,
        unit: translate('UNITS.CONSUMPTION'),
        optimizationKey: 'IsMdoConsumptionPerformanceOptimal',
        charterpartyLabel: translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.PERFORMANCE_SUMMARY_PANEL.BUDGET'),
        charterpartyKey: 'MdoConsumptionCharterParty'
    },
    // {
    //     key: 'LngConsumptionCalculated',
    //     label: translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.PERFORMANCE_SUMMARY_PANEL.LNG'),
    //     type: 'number',
    //     decimals: 1,
    //     unit: translate('UNITS.CONSUMPTION'),
    //     optimizationKey: 'IsLngConsumptionPerformanceOptimal',
    //     charterpartyLabel: translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.PERFORMANCE_SUMMARY_PANEL.BUDGET'),
    //     charterpartyKey: 'LngConsumptionCharterParty'
    // },
    {
        key: 'GoodWeatherTime',
        label: translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.PERFORMANCE_SUMMARY_PANEL.GW_TIME'),
        type: 'number',
        decimals: 0,
        unit: translate('UNITS.HOUR')
    }
];
