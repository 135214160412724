import React from 'react';
import PropTypes from 'prop-types';
/* helpers */
import { divisionWithPositiveRemainder, compareTimes, getMinTime, getMaxTime } from '../helpers';
/* components */
import TimePickerBox from './time-picker-box';
/* variables */
import variables from '../variables';
/* types */
import { TimeShape, TimeConfigFieldShape } from '../types';
/* styles */
import './time-picker.scss';

export default class HtecDPTimePicker extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            config: { ...variables.timeConfig, ...this.props.config }
        };
    }

    handleTimeChange = (diff) => {
        const hour = this.props.selectedTime ? this.props.selectedTime.hour : 0;
        const minute = this.props.selectedTime ? this.props.selectedTime.minute : 0;
        const second = this.props.selectedTime ? this.props.selectedTime.second : 0;
        const newSecond = divisionWithPositiveRemainder(second + diff.second, 60);
        const newMinute = divisionWithPositiveRemainder(minute + diff.minute + newSecond.quotient, 60);
        const newHour = divisionWithPositiveRemainder(hour + diff.hour + newMinute.quotient, 24);
        let newTime = {
            dayDiff: newHour.quotient,
            hour: newHour.remainder,
            minute: newMinute.remainder,
            second: newSecond.remainder
        };
        if (this.props.minTime) {
            newTime = { ...newTime, ...getMinTime(newTime, this.props.minTime) };
        }
        if (this.props.maxTime) {
            newTime = { ...newTime, ...getMaxTime(newTime, this.props.maxTime) };
        }
        this.props.onTimeChange(newTime);
    };

    handleHourIncrease = () => this.handleTimeChange({ hour: 1, minute: 0, second: 0 });

    handleHourDecrease = () => this.handleTimeChange({ hour: -1, minute: 0, second: 0 });

    handleMinuteIncrease = () => this.handleTimeChange({ hour: 0, minute: 1, second: 0 });

    handleMinuteDecrease = () => this.handleTimeChange({ hour: 0, minute: -1, second: 0 });

    handleSecondIncrease = () => this.handleTimeChange({ hour: 0, minute: 0, second: 1 });

    handleSecondDecrease = () => this.handleTimeChange({ hour: 0, minute: 0, second: -1 });

    handleMeridianClick = () => {
        const hourDivision = divisionWithPositiveRemainder(this.props.selectedTime.hour, 12);
        if (hourDivision.quotient === 0) {
            this.handleTimeChange({ hour: 12, minute: 0, second: 0 });
        } else {
            this.handleTimeChange({ hour: -12, minute: 0, second: 0 });
        }
    };

    getDisabled = (direction, unit) => {
        if (direction < 0 && this.props.minTime) {
            return compareTimes(this.props.selectedTime, this.props.minTime, unit) !== 1;
        }
        if (direction > 0 && this.props.maxTime) {
            return compareTimes(this.props.selectedTime, this.props.maxTime, unit) !== -1;
        }
        return false;
    };

    render = () => {
        let timePickerClass = 'htec-dp-time-picker';
        if (this.props.className) {
            timePickerClass += ` ${this.props.className}`;
        }
        if (this.props.showUnits && this.props.format === '24-hour') {
            timePickerClass += ' htec-dp-time-picker--pad-right';
        }
        if (!this.props.selectedTime) {
            return (
                <div className={timePickerClass}>
                    {!this.state.config.hour.hidden && (
                        <TimePickerBox
                            decreaseBtn={this.props.decreaseBtn}
                            increaseBtn={this.props.increaseBtn}
                            onDecrease={this.handleHourDecrease}
                            onIncrease={this.handleHourIncrease}
                            isDisabled={this.state.config.hour.disabled}
                            value={null}
                            title={this.props.showTitles ? variables.timeNames.long.hour : ''}
                            unit={this.props.showUnits ? variables.timeNames.short.hour : ''}
                        />
                    )}
                    {!this.state.config.minute.hidden && (
                        <TimePickerBox
                            decreaseBtn={this.props.decreaseBtn}
                            increaseBtn={this.props.increaseBtn}
                            onDecrease={this.handleMinuteDecrease}
                            onIncrease={this.handleMinuteIncrease}
                            isDisabled={this.state.config.minute.disabled}
                            value={null}
                            title={this.props.showTitles ? variables.timeNames.long.minute : ''}
                            unit={this.props.showUnits ? variables.timeNames.short.minute : ''}
                        />
                    )}
                    {!this.state.config.second.hidden && (
                        <TimePickerBox
                            decreaseBtn={this.props.decreaseBtn}
                            increaseBtn={this.props.increaseBtn}
                            onDecrease={this.handleSecondDecrease}
                            onIncrease={this.handleSecondIncrease}
                            isDisabled={this.state.config.second.disabled}
                            value={null}
                            title={this.props.showTitles ? variables.timeNames.long.second : ''}
                            unit={this.props.showUnits ? variables.timeNames.short.second : ''}
                        />
                    )}
                </div>
            );
        }
        let hour = this.props.selectedTime.hour;
        let meridian = 'AM';
        if (this.props.format === '12-hour') {
            const hourDivision = divisionWithPositiveRemainder(hour, 12);
            hour = hourDivision.remainder === 0 ? 12 : hourDivision.remainder;
            meridian = hourDivision.quotient === 0 ? 'AM' : 'PM';
        }
        return (
            <div className={timePickerClass}>
                {!this.state.config.hour.hidden && (
                    <TimePickerBox
                        decreaseBtn={this.props.decreaseBtn}
                        increaseBtn={this.props.increaseBtn}
                        onDecrease={this.handleHourDecrease}
                        onIncrease={this.handleHourIncrease}
                        isIncreaseDisabled={this.getDisabled(1, 'hour')}
                        isDecreaseDisabled={this.getDisabled(-1, 'hour')}
                        isDisabled={this.state.config.hour.disabled}
                        value={hour}
                        title={this.props.showTitles ? variables.timeNames.long.hour : ''}
                        unit={this.props.showUnits ? variables.timeNames.short.hour : ''}
                    />
                )}
                {!this.state.config.minute.hidden && (
                    <TimePickerBox
                        decreaseBtn={this.props.decreaseBtn}
                        increaseBtn={this.props.increaseBtn}
                        onDecrease={this.handleMinuteDecrease}
                        onIncrease={this.handleMinuteIncrease}
                        isIncreaseDisabled={this.getDisabled(1, 'minute')}
                        isDecreaseDisabled={this.getDisabled(-1, 'minute')}
                        isDisabled={this.state.config.minute.disabled}
                        value={this.props.selectedTime.minute}
                        title={this.props.showTitles ? variables.timeNames.long.minute : ''}
                        unit={this.props.showUnits ? variables.timeNames.short.minute : ''}
                    />
                )}
                {!this.state.config.second.hidden && (
                    <TimePickerBox
                        decreaseBtn={this.props.decreaseBtn}
                        increaseBtn={this.props.increaseBtn}
                        onDecrease={this.handleSecondDecrease}
                        onIncrease={this.handleSecondIncrease}
                        isIncreaseDisabled={this.getDisabled(1, 'second')}
                        isDecreaseDisabled={this.getDisabled(-1, 'second')}
                        isDisabled={this.state.config.second.disabled}
                        value={this.props.selectedTime.second}
                        title={this.props.showTitles ? variables.timeNames.long.second : ''}
                        unit={this.props.showUnits ? variables.timeNames.short.second : ''}
                    />
                )}
                {this.props.format === '12-hour' && (
                    <div className="htec-dp-time-picker__meridian" onClick={this.handleMeridianClick}>{meridian}</div>
                )}
            </div>
        );
    }
}

HtecDPTimePicker.propTypes = {
    className: PropTypes.string,
    config: PropTypes.shape({
        hour: PropTypes.shape(TimeConfigFieldShape),
        minute: PropTypes.shape(TimeConfigFieldShape),
        second: PropTypes.shape(TimeConfigFieldShape)
    }),
    decreaseBtn: PropTypes.node,
    format: PropTypes.oneOf(['24-hour', '12-hour']),
    increaseBtn: PropTypes.node,
    maxTime: PropTypes.objectOf(PropTypes.number),
    minTime: PropTypes.objectOf(PropTypes.number),
    onTimeChange: PropTypes.func.isRequired,
    selectedTime: PropTypes.shape(TimeShape),
    showTitles: PropTypes.bool,
    showUnits: PropTypes.bool
};

HtecDPTimePicker.defaultProps = {
    className: '',
    config: null,
    decreaseBtn: undefined,
    format: '24-hour',
    increaseBtn: undefined,
    maxTime: null,
    minTime: null,
    selectedTime: { hour: 0, minute: 0, second: 0 },
    showTitles: false,
    showUnits: false
};
