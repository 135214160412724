import deepMerge from 'deepmerge';
import CoreApi from './core-api';
import LocalStorageService from 'services/local-storage-service';

const defaultOptions = {
    pdf: {
        format: 'A4',
        landscape: true,
        margin: {
            left: '1cm',
            right: '1cm',
            top: '1cm',
            bottom: '1cm'
        },
        scale: 0.5
    },
    goto: {
        timeout: 0
    }
};

class PdfGeneratorService {
    constructor() {
        this.resourceUrl = 'PdfGenerator';
    }

    triggerFileDownload = (blob, fileName) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, `${fileName}.pdf`);
        } else {
            const a = document.createElement('a');
            a.style.cssText = 'display: none';
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = `${fileName}.pdf`;
            a.click();
            window.URL.revokeObjectURL(url);
        }
    };

    convert = (options, fileName) => {
        if (!options) {
            return Promise.reject(new Error('Data object is undefined.'));
        }

        if (!options.html && !options.url) {
            return Promise.reject(new Error('HTML and URL fields are empty.'));
        }

        if (LocalStorageService.isDebugModeActive()) {
            this.openHtmlInNewTab(options.html);
        }

        const data = { ...deepMerge(defaultOptions, options), output: 'pdf' };

        return CoreApi.getPdf(`${this.resourceUrl}/FromHtml`, data).then((res) => {
            this.triggerFileDownload(new Blob([res.data], { type: 'application/pdf' }), fileName);
        }, () => null);
    };

    openHtmlInNewTab = (htmlString) => {
        const newTab = window.open('');
        if (newTab) {
            newTab.document.documentElement.innerHTML = htmlString;
        }
    }
}

export default new PdfGeneratorService();
