/* eslint-disable react/no-multi-comp */
/* eslint-disable react/no-unused-state */
import React from 'react';
import PropTypes from 'prop-types';

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default function createSharedStateContext(defaultState) {
    const { Consumer, Provider } = React.createContext(defaultState);

    class SharedStateProvider extends React.Component {
        constructor(props) {
            super(props);

            this.setSharedState = this.setSharedState.bind(this);

            this.state = {
                sharedState: this.props.initialState,
                setSharedState: this.setSharedState
            };
        }

        setSharedState(newState) {
            this.setState({
                sharedState: newState
            });
        }

        render() {
            return (
                <Provider value={this.state}>{this.props.children}</Provider>
            );
        }
    }

    SharedStateProvider.propTypes = {
        children: PropTypes.node.isRequired,
        initialState: PropTypes.objectOf(PropTypes.any)
    };

    SharedStateProvider.defaultProps = {
        initialState: null
    };

    function withSharedState(Component) {
        class WithSharedState extends React.PureComponent {
            renderWrappedComponent = (value) => (
                <Component
                    {...this.props}
                    setSharedState={value.setSharedState}
                    {...value.sharedState}
                />
            );

            render = () => (
                <Consumer>{this.renderWrappedComponent}</Consumer>
            );
        }

        WithSharedState.displayName = `WithSharedState(${getDisplayName(Component)})`;

        return WithSharedState;
    }

    return { withSharedState, SharedStateProvider };
}
