import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { getClassName } from 'utils/helpers/info-helper';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
/* styles */
import './sub-menu.scss';

const directions = {
    UP: 'up',
    DOWN: 'down'
};

const mouseLeaveTimeoutLength = 500;

class SubMenu extends React.PureComponent {
    state = {
        isSubMenuOpen: false,
        direction: directions.DOWN
    };

    componentWillUnmount() {
        document.removeEventListener('click', this.handleDocumentClick);
        if (this.mouseLeaveTimeout) {
            clearTimeout(this.mouseLeaveTimeout);
        }
    }

    handleClick = () => {
        this.handleSubMenuOpen();
    };

    handleMouseEnter = () => {
        if (!this.state.isSubMenuOpen) {
            this.handleSubMenuOpen(true);
        }
        if (this.mouseLeaveTimeout) {
            clearTimeout(this.mouseLeaveTimeout);
        }
    };

    handleMouseLeave = () => {
        if (this.state.isSubMenuOpen) {
            this.mouseLeaveTimeout = setTimeout(() => {
                this.setState({ isSubMenuOpen: false });
            }, mouseLeaveTimeoutLength);
        }
    };

    handleSubMenuOpen = () => {
        if (!this.props.isDisabled) {
            const submenuClientRect = this.subMenu.getBoundingClientRect();
            const isBelowScreenHalf = (window.innerHeight / 2) - submenuClientRect.y < 0;
            this.setState({
                isSubMenuOpen: true,
                direction: isBelowScreenHalf ? directions.UP : directions.DOWN
            });
            document.addEventListener('click', this.handleDocumentClick);
        }
    };

    handleDocumentClick = () => {
        this.setState({ isSubMenuOpen: false });
        document.removeEventListener('click', this.handleDocumentClick);
    };

    saveRef = (c) => { this.subMenu = c; };

    render() {
        const { children, className, icon, isDisabled, text } = this.props;
        const classNames = getClassName('sten-menu-item sten-sub-menu flex flex-center', className, {
            'sten-menu-item--active': this.state.isSubMenuOpen,
            'sten-menu-item--disabled': isDisabled,
            'sten-menu-item--clickable': !isDisabled
        });
        const menuItemsClass = getClassName('sten-menu__items sten-menu__items--visible', {
            'sten-menu__items--collapse-down': this.state.direction === directions.DOWN,
            'sten-menu--no-icons': !this.props.children.some((child) => child && child.props.icon)
        });

        return (
            <div
                ref={this.saveRef}
                className={classNames}
                onClick={this.handleClick}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                <div className="sten-menu-item__icon">{icon && <span className={`icon ${icon}`} />}</div>
                <span className="sten-menu-item__text">{text}</span>
                {this.state.isSubMenuOpen && (
                    <div className={menuItemsClass}>
                        <ScrollArea shouldDispatchEvent={false} stopScrollPropagation>{children}</ScrollArea>
                    </div>
                )}
                <span className="sten-sub-menu__chevron icon icon-chevron-right" />
            </div>
        );
    }
}

SubMenu.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    icon: PropTypes.string,
    isDisabled: PropTypes.bool,
    text: PropTypes.string.isRequired
};

SubMenu.defaultProps = {
    className: '',
    icon: '',
    isDisabled: false
};

export default SubMenu;
