export const ActionTypes = {
    CONTACT_LIST_TABLE_GET_CONTACT_LIST: 'CONTACT_LIST_TABLE_GET_CONTACT_LIST',
    CONTACT_LIST_TABLE_SET_CONTACT_LIST: 'CONTACT_LIST_TABLE_SET_CONTACT_LIST',
    CONTACT_LIST_TABLE_SET_OFFSET: 'CONTACT_LIST_TABLE_SET_OFFSET',
    CONTACT_LIST_TABLE_SET_SORT_PROP: 'CONTACT_LIST_TABLE_SET_SORT_PROP',
    CONTACT_LIST_TABLE_SET_FETCHING_PROGRESS: 'CONTACT_LIST_TABLE_SET_FETCHING_PROGRESS'
};

export function getContactList(dispatch, overrideCurrent) {
    dispatch({
        type: ActionTypes.CONTACT_LIST_TABLE_GET_CONTACT_LIST,
        overrideCurrent
    });
}

export function setSortProp(dispatch, sortProp) {
    dispatch({
        type: ActionTypes.CONTACT_LIST_TABLE_SET_SORT_PROP,
        sortProp
    });
}

export function setOffset(dispatch, offset) {
    dispatch({
        type: ActionTypes.CONTACT_LIST_TABLE_SET_OFFSET,
        offset
    });
}
