import { createSelector } from 'reselect';

const emptyArray = [];

export const getNotificationsByImo = createSelector(
    state => state.homeReducer.selectedVesselId,
    state => state.notificationsReducer.activeNotifications,

    (vesselId, activeNotifications) => {
        if (activeNotifications && vesselId) {
            return activeNotifications.filter(notification => notification.IMO === vesselId);
        }

        return emptyArray;
    }
);

export const getSelectedPredefinedRange = createSelector(
    state => state.userReducer.settings && state.userReducer.settings.VesselRangeId,
    state => state.vesselInfoReducer.rangeOptions,

    (vesselRangeId, rangeOptions) => {
        if (vesselRangeId && rangeOptions && rangeOptions.length) {
            return rangeOptions.find(range => range.Id === vesselRangeId);
        }

        return null;
    }
);

export default getNotificationsByImo;
