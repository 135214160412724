import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import { sendReminder } from 'components/vessel-report/vessel-report-actions';
/* components */
import Accordion from 'components/accordion/accordion';
import ScrollArea from 'components/scroll-area/scroll-area';
import Select from 'components/select/select';
import ShowMoreButton from 'components/show-more-button/show-more-button';
import VesselInfoEnergyAverageVoyageData from './vessel-info-energy-average-voyage-data';
import VesselInfoEnergyBunkerRob from './vessel-info-energy-bunker-rob';
import VesselInfoEnergyPerformance from './vessel-info-energy-performance';
import VesselInfoEnergyReport from './vessel-info-energy-report/vessel-info-energy-report';
/* selectors */
import { getParsedLatestReport } from '../../../home-selectors';
import { getReportFuelTypes } from 'components/vessel-report/vessel-report-selectors';

export class VesselInfoEnergy extends React.PureComponent {
    SHOW_ALL_REPORTS_ID = -1;

    constructor(props) {
        super(props);
        this.state = {
            selectedReportType: props.reportTypeOptions ? props.reportTypeOptions[0] : null,
            activeConfirmReportId: null,
            showMoreReports: this.props.showMoreReports
        };
    }

    toggleConfirmId = (reportId) => {
        this.setState({
            activeConfirmReportId: reportId
        });
    };

    selectReportType = (value) => this.setState({ selectedReportType: value });

    getReports = () => {
        const selectedReportsTypeId = this.state.selectedReportType.Id;
        const { selectedVoyageReports } = this.props;
        const filteredReports = selectedReportsTypeId === this.SHOW_ALL_REPORTS_ID
            ? selectedVoyageReports
            : selectedVoyageReports.filter(r => r.ReportType === selectedReportsTypeId);
        const numReportsInShortMode = 3;
        const visibleReports = this.state.showMoreReports
            ? filteredReports
            : filteredReports.slice(0, numReportsInShortMode);
        return (
            <Accordion header={<h4 className="text-uppercase">{t('VESSEL_INFO.ENERGY.REPORTS.TITLE')}</h4>}>
                <div className="sten-content__section">
                    <Select
                        valueKey="Id"
                        labelKey="Name"
                        options={this.props.reportTypeOptions}
                        value={this.state.selectedReportType}
                        onChange={this.selectReportType}
                    />
                </div>
                <div className="sten-content__separator">
                    {visibleReports.map((report) => (
                        <VesselInfoEnergyReport
                            key={report.ReportId || `missing-${report.MissingReportId}`}
                            report={report}
                            isConfirmActive={report.MissingReportId
                                && this.state.activeConfirmReportId === report.MissingReportId}
                            onConfirmToggle={this.toggleConfirmId}
                            onReminderConfirm={this.props.sendReminder}
                            permissions={this.props.permissions}
                        />
                    ))}
                    {visibleReports.length === 0 && (
                        <div className="sten-content__section text-secondary text-center">
                            {t('VESSEL_INFO.ENERGY.NO_REPORTS')}
                        </div>
                    )}
                    {filteredReports.length > numReportsInShortMode && (
                        <div
                            key="showMore"
                            onClick={this.toggleShowMoreReports}
                            className="sten-content__section text-center"
                        >
                            <ShowMoreButton isMoreShown={this.state.showMoreReports} />
                        </div>
                    )}
                </div>
            </Accordion>
        );
    }

    toggleShowMoreReports = () => {
        this.setState({
            showMoreReports: !this.state.showMoreReports
        });
    };

    render() {
        const {
            fuelTypes,
            latestVoyageReport,
            notifications,
            selectedVoyage,
            permissions,
            selectedVoyageReports,
            selectedVesselBase
        } = this.props;
        if (!selectedVoyage) {
            return null;
        }
        const now = moment.utc();
        const voyagePerformance = selectedVoyage.VoyagePerformance;
        const isCurrentVoyage = moment.utc(selectedVoyage.EndDate).isAfter(now);
        return (
            <ScrollArea className="sten-vessel-info-overview">
                {isCurrentVoyage && latestVoyageReport && (
                    <VesselInfoEnergyPerformance latestVoyageReport={latestVoyageReport} notifications={notifications}>
                        <h4 className="text-uppercase">
                            {t('VESSEL_INFO.ENERGY.LATEST')}
                            <span className="text-active">{` ${t('VESSEL_INFO.ENERGY.DAILY_REPORT')} `}</span>
                            {latestVoyageReport.Date && (
                                <span className="text-secondary">
                                    {` - ${TimeHelper.getFormatted(
                                        latestVoyageReport.Date, { utc: true, time: true }
                                    )}`}
                                </span>
                            )}
                        </h4>
                    </VesselInfoEnergyPerformance>
                )}
                <VesselInfoEnergyBunkerRob latestVoyageReport={latestVoyageReport} fuelTypes={fuelTypes} />
                {permissions.GetVoyagePerformance && (
                    <VesselInfoEnergyAverageVoyageData
                        selectedVoyage={selectedVoyage}
                        voyagePerformance={voyagePerformance}
                        vesselBase={selectedVesselBase}
                    />
                )}
                {selectedVoyageReports && this.getReports()}
            </ScrollArea>
        );
    }
}

VesselInfoEnergy.propTypes = {
    fuelTypes: PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.object),
        map: PropTypes.objectOf(PropTypes.any)
    }).isRequired,
    latestVoyageReport: PropTypes.objectOf(PropTypes.any),
    notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    reportTypeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedVesselBase: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedVoyage: PropTypes.objectOf(PropTypes.any),
    selectedVoyageReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    sendReminder: PropTypes.func.isRequired,
    showMoreReports: PropTypes.bool
};

VesselInfoEnergy.defaultProps = {
    latestVoyageReport: null,
    selectedVoyage: null,
    showMoreReports: false
};

function mapStateToProps(state) {
    return {
        fuelTypes: getReportFuelTypes(state, getParsedLatestReport(state)),
        latestVoyageReport: getParsedLatestReport(state),
        permissions: state.userReducer.permissions,
        selectedVesselBase: state.homeReducer.selectedVesselBase,
        selectedVoyage: state.homeReducer.selectedVoyage,
        selectedVoyageReports: state.homeReducer.selectedVoyageReports
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sendReminder: id => sendReminder(dispatch, id)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VesselInfoEnergy);
