import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import Textarea from '../textarea/textarea';
import Input from '../input/input';
import Validation from 'components/validation/validation';
/* styles */
import './text-crud.scss';

class TextCrud extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            activeMode: '',
            isActive: false,
            propsIsActive: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        let newState = null;
        if (props.isActive !== state.propsIsActive) {
            newState = { propsIsActive: props.isActive, isActive: props.isActive };
            if (props.isActive === false) {
                newState = { ...newState, value: '', activeMode: '' };
            }
        }
        return newState;
    }

    onActivate = mode => {
        this.setState({
            value: this.props.value || '',
            isActive: true,
            activeMode: mode
        });
        if (this.props.onActivate) {
            this.props.onActivate(mode);
        }
    };

    onDeactivate = () => {
        this.setState({
            value: '',
            activeMode: '',
            isActive: false
        });
        if (this.props.onDeactivate) {
            this.props.onDeactivate();
        }
    };

    onSubmit = () => {
        const value = this.state.value;
        this.props.onUpdate(value);
        this.onDeactivate();
    };

    onRemove = () => {
        this.props.onRemove();
        this.onDeactivate();
    };

    setValue = value => {
        this.setState({ value });
    };

    render() {
        const { isReadOnly } = this.props;
        let addButtonIconClass = 'btn-link btn-link--secondary icon';
        if (this.props.addButtonIconClass) {
            addButtonIconClass += ` ${this.props.addButtonIconClass}`;
        } else {
            addButtonIconClass += ' icon-edit';
        }

        let className = 'sten-text-crud';
        if (this.props.className) {
            className += ` ${this.props.className}`;
        }

        return (
            <div className={className}>
                {!this.state.isActive && !this.props.value && (
                    <div className="sten-text-crud__content flex-row flex-center">
                        <div className="flex-grow text-right">
                            {!isReadOnly && (
                                <button className={addButtonIconClass} onClick={this.onActivate.bind(this, 'update')} />
                            )}
                        </div>
                    </div>
                )}
                {!this.state.isActive && !!this.props.value && (
                    <div className="sten-text-crud__content flex-row flex-center">
                        <div className="flex-grow text-ellipsis">
                            {this.props.children || this.props.value}
                        </div>
                        {!isReadOnly && (
                            <div className="flex-shrink">
                                <button
                                    className="btn-link icon icon-edit"
                                    onClick={this.onActivate.bind(this, 'update')}
                                />
                            </div>
                        )}
                        {!isReadOnly && this.props.onRemove && (
                            <div className="flex-shrink">
                                <button
                                    className="btn-link icon icon-delete"
                                    onClick={this.onActivate.bind(this, 'remove')}
                                />
                            </div>
                        )}
                    </div>
                )}
                {this.state.isActive && this.state.activeMode === 'update' && (
                    <div className="sten-text-crud__form">
                        <Validation.Form
                            className="sten-text-crud__content flex-row flex-center"
                            onSubmit={this.onSubmit}
                        >
                            <div className="flex-grow">
                                <Validation.Wrapper
                                    hintsOnHover
                                    validations={this.props.validations}
                                >
                                    {this.props.type === 'textarea'
                                        ? (
                                            <Textarea
                                                name="textarea"
                                                autoFocus
                                                autoComplete="off"
                                                value={this.state.value}
                                                onChange={this.setValue}
                                                rows={this.props.rows}
                                            />
                                        ) : (
                                            <Input
                                                name="input"
                                                autoFocus
                                                autoComplete="off"
                                                value={this.state.value}
                                                onChange={this.setValue}
                                            />
                                        )
                                    }
                                </Validation.Wrapper>
                            </div>
                            <div className="flex-shrink">
                                <Validation.Button className="btn-link icon icon-check" />
                            </div>
                            <div className="flex-shrink">
                                <button
                                    type="button"
                                    className="btn-link icon icon-close"
                                    onClick={this.onDeactivate}
                                />
                            </div>
                        </Validation.Form>
                    </div>
                )}
                {this.state.isActive && this.state.activeMode === 'remove' && this.props.onRemove && (
                    <div className="sten-text-crud__form">
                        <div className="sten-text-crud__content flex-row flex-center">
                            <div className="flex-grow text-left">
                                {this.props.removeText}
                            </div>
                            <div className="flex-shrink">
                                <button
                                    className="btn-link icon icon-check"
                                    onClick={this.onRemove}
                                />
                            </div>
                            <div className="flex-shrink">
                                <button
                                    className="btn-link icon icon-close"
                                    onClick={this.onDeactivate}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

TextCrud.propTypes = {
    addButtonIconClass: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    isActive: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    onActivate: PropTypes.func,
    onDeactivate: PropTypes.func,
    onRemove: PropTypes.func,
    onUpdate: PropTypes.func.isRequired,
    removeText: PropTypes.string,
    rows: PropTypes.number,
    type: PropTypes.string,
    validations: PropTypes.objectOf(PropTypes.any),
    value: PropTypes.string
};

TextCrud.defaultProps = {
    addButtonIconClass: '',
    children: null,
    className: '',
    isActive: false,
    isReadOnly: false,
    onActivate: undefined,
    onDeactivate: undefined,
    onRemove: undefined,
    removeText: translate('TEXT_CRUD.REMOVE'),
    rows: 3,
    type: 'input',
    validations: { maxLength: 1024 },
    value: ''
};

export default TextCrud;
