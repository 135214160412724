export const ActionTypes = {
    ADMIN_COMPANY_MANAGEMENT_COMPANIES_GET: 'ADMIN_COMPANY_MANAGEMENT_COMPANIES_GET',
    ADMIN_COMPANY_MANAGEMENT_COMPANIES_SET: 'ADMIN_COMPANY_MANAGEMENT_COMPANIES_SET',
    ADMIN_COMPANY_MANAGEMENT_COMPANIES_UPDATE: 'ADMIN_COMPANY_MANAGEMENT_COMPANIES_UPDATE',
    ADMIN_COMPANY_MANAGEMENT_PLATFORM_STATISTICS_GET: 'ADMIN_COMPANY_MANAGEMENT_PLATFORM_STATISTICS_GET',
    ADMIN_COMPANY_MANAGEMENT_PLATFORM_STATISTICS_SET: 'ADMIN_COMPANY_MANAGEMENT_PLATFORM_STATISTICS_SET'
};

export function getCompanies(dispatch) {
    dispatch({
        type: ActionTypes.ADMIN_COMPANY_MANAGEMENT_COMPANIES_GET
    });
}

export function updateCompany(dispatch, company, formData) {
    dispatch({
        type: ActionTypes.ADMIN_COMPANY_MANAGEMENT_COMPANIES_UPDATE,
        company,
        formData
    });
}

export function getPlatformStatistics(dispatch) {
    dispatch({
        type: ActionTypes.ADMIN_COMPANY_MANAGEMENT_PLATFORM_STATISTICS_GET
    });
}
