import React from 'react';
import PropTypes from 'prop-types';
/* components */
import Checkbox from 'components/checkbox/checkbox';

class VesselFilterRow extends React.PureComponent {
    handleChange = (e) => this.props.onChange(this.props.value, e);

    render() {
        return (
            <div className="sten-vessel-filter__row form-row flex-row flex-center">
                <div className="flex-shrink">
                    <Checkbox
                        className="text-uppercase"
                        onChange={this.handleChange}
                        isChecked={this.props.isChecked}
                        id={this.props.checkboxId}
                        name={this.props.checkboxName}
                        isDisabled={this.props.isDisabled}
                    >
                        {this.props.children}
                    </Checkbox>
                </div>
                <div className="flex-grow">
                    {this.props.badges}
                </div>
                {this.props.count !== null && (
                    <p className="flex-shrink text-right text-secondary">
                        {this.props.count}
                    </p>
                )}
            </div>
        );
    }
}

VesselFilterRow.propTypes = {
    checkboxId: PropTypes.string.isRequired,
    checkboxName: PropTypes.string,
    children: PropTypes.node,
    count: PropTypes.number,
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
    badges: PropTypes.arrayOf(PropTypes.any)
};

VesselFilterRow.defaultProps = {
    checkboxName: '',
    children: null,
    count: null,
    isChecked: false,
    isDisabled: false,
    value: null,
    badges: null
};

export default VesselFilterRow;
