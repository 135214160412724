import { takeEvery, put, select, all } from 'redux-saga/effects';
/* utils */
import { sortByProp } from 'utils/helpers/array-helper';
/* actions */
import { ActionTypes } from './port-info-actions';
import { ActionTypes as CommentActionTypes } from 'components/comments/comment-actions';
/* services */
import CommentService from 'services/core-api/comment-service';
import PortService from 'services/core-api/port-service';
import PortManagementService from 'services/core-api/port-management-service';
import TerminalsService from 'services/core-api/terminals-service';

function* getActivities(action) {
    const permissions = yield select(state => state.userReducer.permissions);
    if (permissions && !permissions.GetPortActivities) {
        return null;
    }
    return yield PortService.getActivities(action.portId);
}

function* getBunkerPrices(action) {
    const permissions = yield select(state => state.userReducer.permissions);
    if (!permissions || !permissions.GetPortBunkerPrices) {
        return null;
    }
    return yield PortService.getBunkerPrices(action.portId);
}

function* getComments(action, saveState = true) {
    const permissions = yield select(state => state.userReducer.permissions);
    if (!permissions || !permissions.GetAllPortComments) {
        return null;
    }
    const comments = yield CommentService.get('port', action.portId);
    if (saveState) {
        yield put({ type: ActionTypes.PORT_INFO_SET_COMMENTS, comments });
    }
    return comments;
}

function* updateComments(action) {
    if (action.resource === 'port') {
        yield getComments({ portId: action.resourceId });
    }
}

function* getBunkeringOptions() {
    const permissions = yield select(state => state.userReducer.permissions);
    if (!permissions || !permissions.GetPortBunkeringTypes) {
        return null;
    }
    const bunkeringTypes = yield select(state => state.userReducer.bunkeringTypes);
    if (bunkeringTypes) {
        return bunkeringTypes;
    }
    return yield PortService.getBunkeringTypes();
}

function* getServices() {
    const permissions = yield select(state => state.userReducer.permissions);
    const services = yield select(state => state.portInfoReducer.services);
    if (!permissions || !permissions.PortManagementGetPortCompleteInfo || services.length > 0) {
        return services;
    }
    return yield PortManagementService.getServices();
}

function* getTerminals(action, saveState = true) {
    const permissions = yield select(state => state.userReducer.permissions);
    const isMounted = yield select(state => state.portInfoReducer.isMounted);
    if (permissions.ShowTerminals && (!saveState || isMounted)) {
        const terminals = yield TerminalsService.getTerminalsByPortId(action.portId);
        if (terminals && terminals.length) {
            terminals.sort(sortByProp('TerminalName'));
        }
        if (saveState) {
            yield put({ type: ActionTypes.PORT_INFO_SET_TERMINALS, terminals });
        }
        return terminals;
    }
    return null;
}

function* getResources(action) {
    const res = yield all({
        services: getServices(),
        bunkeringTypes: getBunkeringOptions(),
        port: PortManagementService.getPort(action.portId),
        comments: getComments(action, false),
        bunkerPrices: getBunkerPrices(action),
        activities: getActivities(action),
        terminals: getTerminals(action, false)
    });
    yield put({ ...res, type: ActionTypes.PORT_INFO_SET_RESOURCES });
}

export default function* portInfoSaga() {
    yield takeEvery(ActionTypes.PORT_INFO_GET_RESOURCES, getResources);
    yield takeEvery(CommentActionTypes.COMMENT_UPDATED, updateComments);
}
