import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { formatNumber, getObjectProp } from 'utils/helpers/info-helper';
import ReportHelper from 'utils/helpers/report-helper';
// If this number is changed please change logic for setting max width of table!
const numberOfReportsPerPage = 4;
const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.${key}`);

function breakRowsJS() {
    const reports = document.getElementsByClassName('raw-reports-table-pdf');
    const bodyClone = reports[0].cloneNode(true);
    const originalPages = reports[0].getElementsByClassName('report-page');
    const mmToPxRatio = document.documentElement.offsetWidth / 277;
    const zoomRatio = 277 / 297;
    const pageHeightInPx = Math.round(190 * mmToPxRatio);
    const tableHeightLimit = pageHeightInPx - 80;
    // sliced to keep the original indexes in array
    const pageClones = Array.prototype.slice.call(
        bodyClone.getElementsByClassName('report-page')
    );
    for (let i = 0; i < originalPages.length; i++) {
        if (originalPages[i].getBoundingClientRect().height > tableHeightLimit) {
            // original table rows used for row height calculations
            const originalTrs = originalPages[i].getElementsByTagName('tr');
            // sliced to keep the original indexes in array
            const trClones = Array.prototype.slice.call(pageClones[i].getElementsByTagName('tr'));
            let heightSoFar = 0;
            let currentElementHeight;
            let isPageCloned = false;
            let clonedPage;
            let tableBody;
            let dataGroup;
            let dataGroupProps = null;
            for (let trIndex = 0; trIndex < originalTrs.length; trIndex++) {
                currentElementHeight = originalTrs[trIndex].getBoundingClientRect().height * zoomRatio;
                dataGroup = originalTrs[trIndex].getElementsByTagName('td')[0]
                    ? originalTrs[trIndex].getElementsByTagName('td')[0].getAttribute('data-group')
                    : null;
                if (heightSoFar > tableHeightLimit) {
                    if (heightSoFar > tableHeightLimit * 2) {
                        isPageCloned = false;
                    }
                    if (!isPageCloned) {
                        heightSoFar = tableHeightLimit;
                        const nextSibling = clonedPage ? clonedPage.nextSibling : pageClones[i].nextSibling;
                        clonedPage = pageClones[i].cloneNode(true);
                        tableBody = clonedPage.getElementsByTagName('tbody')[0];
                        const trClone = clonedPage.getElementsByTagName('tr')[0].cloneNode(true);
                        tableBody.innerHTML = '';
                        tableBody.appendChild(trClone);
                        pageClones[i].parentNode.insertBefore(clonedPage, nextSibling);
                        isPageCloned = true;
                    }
                    if (dataGroupProps?.name === dataGroup) {
                        for (let i = 0; i < dataGroupProps.indexes.length; i++) {
                            tableBody.appendChild(trClones[dataGroupProps.indexes[i]]);
                        }
                        dataGroupProps = null;
                    }
                    tableBody.appendChild(trClones[trIndex]);
                } else if (dataGroup) {
                    if (dataGroupProps && dataGroup === dataGroupProps.name) {
                        dataGroupProps.indexes.push(trIndex);
                    } else {
                        dataGroupProps = {
                            name: dataGroup,
                            indexes: [trIndex]
                        };
                    }
                } else {
                    dataGroupProps = null;
                }
                heightSoFar += currentElementHeight;
            }
        }
    }
    reports[0].innerHTML = bodyClone.innerHTML;
}

export default class RawReportsTablePDF extends React.PureComponent {
    splitReportsInPages(reports, chunkSize) {
        const chunks = [];
        let page = -1;
        reports.forEach((report, index) => {
            if (index % chunkSize === 0) {
                page++;
                chunks[page] = [];
            }
            chunks[page].push(report);
        });
        return chunks;
    }

    generateWidthsArr = memoize((numColumns) => {
        const cellWidthStyle = `${100 / numColumns}%`;
        const maxWidth = `${(100 / (numberOfReportsPerPage + 1)) * numColumns}%`;
        const widths = new Array(numColumns);
        for (let i = 0; i < numColumns; i++) {
            widths[i] = cellWidthStyle;
        }
        return { widths, maxWidth };
    });

    calculateCellClassName = (report, key) => {
        if (report.ExclusionFields?.[key]) {
            return 'excluded-cell-emphasized';
        }
        return report.IsExcluded || report.IsMissing ? 'excluded-cell' : null;
    };

    generateSingleRow(reports, keyConfig) {
        const cells = [];
        if (reports?.length > 0) {
            cells.unshift({
                value: keyConfig.label,
                dataGroup: keyConfig.dataGroup
            });
            reports.forEach((report) => {
                const key = getObjectProp(report, keyConfig.key);
                const className = this.calculateCellClassName(report, keyConfig.key);
                if (keyConfig.type === 'syncedWithVeracity') {
                    const { value } = ReportHelper.getSyncWithVeracityData(report);
                    cells.push({
                        value,
                        className: className ? `${className} cell-style` : 'cell-style',
                        colSpan: report.FuelTypes?.length ? report.FuelTypes.length * 4 : 4
                    });
                } else if (keyConfig.type === 'fuelTypes') {
                    report.FuelTypes.forEach(fuelType => {
                        cells.push({
                            value: fuelType.Name,
                            colSpan: 4
                        });
                    });
                } else if (keyConfig.type === 'fuelTypeQuantities') {
                    report.FuelTypes.forEach(fuelType => {
                        const quantity = key?.FuelTypeQuantities[fuelType.Id]
                            ? formatNumber(key.FuelTypeQuantities[fuelType.Id].Quantity, 2)
                            : '-';
                        cells.push({
                            value: quantity,
                            className,
                            colSpan: 4
                        });
                    });
                } else if (keyConfig.type === 'utilizationLabels') {
                    key.values.forEach((value, index) => {
                        cells.push({
                            value: `${keyConfig.utilizationLabel} ${index + 1}`,
                            colSpan: report.FuelTypes?.length ? report.FuelTypes.length : 1
                        });
                    });
                } else if (keyConfig.type === 'utilizationValues') {
                    key.forEach(value => {
                        cells.push({
                            value: value || '-',
                            className,
                            colSpan: report.FuelTypes?.length ? report.FuelTypes.length : 1
                        });
                    });
                } else if (keyConfig.type === 'other') {
                    cells.push({
                        value: keyConfig.renderValue(report),
                        className: `${className ? `${className} ` : ''}sten-table__cell--no-padding`,
                        colSpan: report.FuelTypes?.length ? report.FuelTypes.length * 4 : 4
                    });
                } else {
                    cells.push({
                        value: ReportHelper.formatValue(report, keyConfig),
                        className: className ? `${className} cell-style` : 'cell-style',
                        colSpan: report.FuelTypes?.length ? report.FuelTypes.length * 4 : 4
                    });
                }
            });
        }
        return cells;
    }

    renderReportsCollection(reports, reportRows, reportTitle) {
        let rows;
        let row;
        let rowClassName;
        const reportsPerPage = this.splitReportsInPages(reports, numberOfReportsPerPage);
        return reportsPerPage.map((rpp, index) => {
            rows = [];
            reportRows.forEach((rowConfig) => {
                if (!rowConfig.hiddenInExport) {
                    row = this.generateSingleRow(rpp, rowConfig);
                    rowClassName = null;
                    if (rowConfig.type === 'fuelTypes' || rowConfig.type === 'utilizationLabels') {
                        rowClassName = 'fuel-row';
                    }
                    rows.push(
                        <tr key={rowConfig.key} className={rowClassName}>
                            {row.map(({ value, colSpan, dataGroup, className, style }, cellIndex) => (
                                <td
                                    data-group={dataGroup}
                                    colSpan={colSpan}
                                    // eslint-disable-next-line
                                    key={cellIndex}
                                    className={className}
                                    style={style}
                                >
                                    {value}
                                </td>
                            ))}
                        </tr>
                    );
                }
            });
            const { maxWidth } = this.generateWidthsArr(rpp.length + 1);
            return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="report-page page">
                    <div className="report-title">{reportTitle}</div>
                    <table className="sten-table sten-table--sm" style={{ maxWidth }}>
                        <tbody>{rows}</tbody>
                    </table>
                    {/* <span style={styles.pageNumber}>{index + 2}</span> */}
                </div>
            );
        });
    }

    render() {
        const { cargoReports, departureReports, arrivalReports, dailyReports, eventReports, sofReports } = this.props;
        return (
            <React.Fragment>
                <div className="raw-reports-table-pdf">
                    {dailyReports.length > 0 && this.renderReportsCollection(
                        dailyReports,
                        this.props.rowConfigs.dailyReports,
                        t('DAILY_REPORTS')
                    )}
                    {arrivalReports.length > 0 && this.renderReportsCollection(
                        arrivalReports,
                        this.props.rowConfigs.arrivalReports,
                        t('ARRIVAL_REPORTS')
                    )}
                    {departureReports.length > 0 && this.renderReportsCollection(
                        departureReports,
                        this.props.rowConfigs.departureReports,
                        t('DEPARTURE_REPORTS')
                    )}
                    {cargoReports.length > 0 && this.renderReportsCollection(
                        cargoReports,
                        this.props.rowConfigs.cargoReports,
                        t('CARGO_REPORTS')
                    )}
                    {sofReports.length > 0 && this.renderReportsCollection(
                        sofReports,
                        this.props.rowConfigs.sofReports,
                        t('SOF_REPORTS')
                    )}
                    {eventReports.length > 0 && this.renderReportsCollection(
                        eventReports,
                        this.props.rowConfigs.eventReports,
                        t('EVENT_REPORTS')
                    )}
                </div>
                <script
                    type="text/javascript"
                    /* eslint-disable-next-line react/no-danger */
                    dangerouslySetInnerHTML={{ __html: `(${breakRowsJS.toString()})();` }}
                />
            </React.Fragment>
        );
    }
}

RawReportsTablePDF.propTypes = {
    arrivalReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    cargoReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    companyLogo: PropTypes.objectOf(PropTypes.any),
    dailyReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    departureReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    eventReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    rowConfigs: PropTypes.objectOf(PropTypes.any).isRequired,
    sofReports: PropTypes.arrayOf(PropTypes.any).isRequired
};

RawReportsTablePDF.defaultProps = {
    companyLogo: null
};
