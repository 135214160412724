export const ActionTypes = {
    ENERGY_MANAGEMENT_PERFORMANCE_SUMMARY_REPORT_GENERATE: 'ENERGY_MANAGEMENT_PERFORMANCE_SUMMARY_REPORT_GENERATE',
    ENERGY_MANAGEMENT_PERFORMANCE_SUMMARY_REPORT_SET: 'ENERGY_MANAGEMENT_PERFORMANCE_SUMMARY_REPORT_SET',
    ENERGY_MANAGEMENT_PERFORMANCE_SUMMARY_REPORT_GENERATE_XLSX:
        'ENERGY_MANAGEMENT_PERFORMANCE_SUMMARY_REPORT_GENERATE_XLSX',
    ENERGY_MANAGEMENT_PERFORMANCE_SUMMARY_REPORT_GENERATE_PDF:
        'ENERGY_MANAGEMENT_PERFORMANCE_SUMMARY_REPORT_GENERATE_PDF'
};

export function generateXLSX(dispatch) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_PERFORMANCE_SUMMARY_REPORT_GENERATE_XLSX
    });
}

export function generatePDF(dispatch) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_PERFORMANCE_SUMMARY_REPORT_GENERATE_PDF
    });
}
