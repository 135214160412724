import { takeEvery, put, all, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
/* router */
import { updateQueryParams } from 'app-router';
/* helpers */
import {
    convertTankDataForEdit,
    convertTankDataForUpdate,
    convertUtilizationForUpdate,
    convertValidationRules,
    convertValidations,
    convertWayPointsDataForUpdate,
    createDSST,
    extractAndMapColumns,
    extractEquipmentItems,
    extractUtilization,
    extractWaterData
} from './vessel-report-helpers';
import {
    convertFuelData,
    parseFuelData
} from 'components/fuel-data-table/fuel-data-table-helpers';
import TimeHelper from 'utils/helpers/time-helper';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { getObjectProp, setObjectProp } from 'utils/helpers/info-helper';
import { splitArrayByProp, mapArrayByProp, mapArrayByValue } from 'utils/helpers/array-helper';
/* actions */
import { ActionTypes } from './vessel-report-actions';
import { ActionTypes as SendReminderModalActions } from './send-reminder-modal/send-reminder-modal-actions';
/* services */
import ReportService from 'services/core-api/report-service';
import CargoService from 'services/core-api/cargo-service';
import PortService from 'services/core-api/port-service';
import VesselService from 'services/core-api/vessel-service';
import ConfigService from 'services/config-api/config-service';
import LocalStorageService from 'services/local-storage-service';
/* selectors */
import { getReportFuelTypes } from './vessel-report-selectors';
/* mocked data */
import { mockAuroraReportData } from './vessel-report-test-utils';

const dateOffsetKeys = [
    'ArrivingPilotStationOffset',
    'ReportDateOffset', 'CompletionOfCargoOpsOffset', 'CommencedCargoDateOffset', 'CompletedCargoDateOffset',
    'EstimatedTimeOfDepartureFromBerthOffset', 'EndOfSeaPassageOffset', 'AnchoredDateOffset',
    'EstimatedTimeOfBerthingOffset', 'NoticeOfReadinessOffset',
    'EtaDateOffset', 'LastPropellerCleaningOffset', 'LastHullCleaningOffset',
    'DepartureDateOffset', 'DroppingOutwardPilotOffset', 'AnchoredCommencedDriftingOffset',
    'AnchorAweighStoppedDriftingOffset', 'FreePratiqueGrantedOffset',
    'FreePractiqueGrantedOffset', 'PilotOnboardOffset', 'AllFastOffset', 'HosesConnectedOffset',
    'HosesDisconnectedOffset', 'CargoDocumentsOnboardOffset', 'UnmooredOffset',
    'MastersEtaAtPilotStationDateOffset', 'LayDaysFromDateOffset', 'LayDaysToDateOffset', 'LeaveBerthTerminalOffset',
    'CharterPartyDateOffset', 'EstimatedTimeOfDepartureFromBerthDateOffset', 'TankCleaningStartedOffset',
    'TankCleaningFinishedOffset', 'DailyReportAtAnchorage.EstimatedTimeOfBerthingOffset',
    'DailyReportAtAnchorage.EstimatedTimeOfDepartureOffset', 'BunkerDeliveryDateOffset',
    'DailyReportAtPort.CommencedCargoDateOffset', 'DailyReportAtPort.EstimatedTimeOfDepartureOffset'
];

const durationKeys = [
    'TimeOfPassage', 'HoursSteamedSinceLastReport', 'HoursSinceLastReport',
    'TotalTimeSpentInArea', 'TimeElapsedSinceLastEvent', 'HoursSteamedSinceLastEvent',
    'TimeAtAnchorSinceLastEvent'
];

const tankCleaningFields = [
    'TankCleaningStarted', 'TankCleaningFinished', 'WaterUsedForTankCleaning',
    'TankCleaningStartedOffset', 'TankCleaningFinishedOffset'
];

const fuelDataTypes = [
    'BunkerData',
    'Consumptions',
    'ConsumptionsSinceLastEvent',
    'ConsumptionFromNoonToEvent',
    'ConsumptionDuringStoppage',
    'DischargeOperations'
];

const auroraColExtractionProps = [
    { dataProp: 'BarrierSpaceConditions', colProp: 'CargoTankName' },
    { dataProp: 'Temperatures', colProp: 'CargoTankName' },
    { dataProp: 'CofferdamTemps', colProp: 'CofferdamName' }
];

const reportTypeMap = {
    1: 'Daily',
    2: 'Departure',
    3: 'Arrival',
    4: 'Cargo',
    5: 'Event',
    6: 'NextLeg',
    7: 'Sof'
};

function* getEditingOptions() {
    const { areEditingOptionsFetched } = yield select(state => state.vesselReportReducer);
    if (areEditingOptionsFetched) {
        return;
    }
    const res = yield all({
        cargoStatuses: CargoService.getCargoStatuses(),
        cargoActivities: PortService.getCargoActivities(),
        cargoActivityTypes: PortService.getCargoActivityTypes(),
        cardinalDirections: ReportService.getCardinalDirections(),
        consumptionTypesAndGroups: ConfigService.featureToggles.useReportConfigService
            ? ReportService.getConsumptionTypesAndGroups()
            : null,
        consumptionTypesConfig: ConfigService.featureToggles.useReportConfigService
            ? ConfigService.VesselReport.getConsumptionTypes()
            : null,
        bunkerTypes: ConfigService.featureToggles.useReportConfigService
            ? ReportService.getBunkerTypes()
            : null,
        bunkerTypesConfig: ConfigService.featureToggles.useReportConfigService
            ? ConfigService.VesselReport.getBunkerTypes()
            : null,
        beaufortScale: ReportService.getBeaufortScale(),
        vesselStatusTypes: VesselService.getStatusTypes(),
        eventTypes: ReportService.getEventTypes(),
        navigationTypes: ReportService.getNavigationTypes(),
        tugReasons: ReportService.getTugReasons(),
        speedInstructionTypes: ReportService.getSpeedInstructionTypes(),
        specialAreas: ReportService.getSpecialAreas()
    });
    res.consumptionTypesConfig = mapArrayByProp(res.consumptionTypesConfig, 'Id', item => (
        { ...item, Selectors: mapArrayByValue(item.Selectors) }
    ));
    res.bunkerTypesConfig = mapArrayByProp(res.bunkerTypesConfig, 'Id', item => (
        { ...item, Selectors: mapArrayByValue(item.Selectors) }
    ));
    yield put({ type: ActionTypes.VESSEL_REPORT_SET_EDITING_OPTIONS, ...res });
}

function* getFuelTypes() {
    const rawFuelTypes = yield ReportService.getFuelTypes();
    if (rawFuelTypes && rawFuelTypes.length) {
        const fuelTypes = {};
        rawFuelTypes.forEach(fuelType => {
            fuelTypes[fuelType.DataSourceId] = fuelType.FuelTypes;
        });
        yield put({ type: ActionTypes.VESSEL_REPORT_SET_FUEL_TYPES, fuelTypes });
    } else {
        yield put({ type: ActionTypes.VESSEL_REPORT_SET_FUEL_TYPES, fuelTypes: null });
    }
}

function* getBunkerTypes(action) {
    if (!ConfigService.featureToggles.useReportConfigService) {
        const bunkerTypes = yield ReportService.getBunkerTypesOld(action.reportTypeId, action.reportSubtypeId);
        if (bunkerTypes) {
            yield put({ type: ActionTypes.VESSEL_REPORT_SET_BUNKER_TYPES, bunkerTypes });
        }
    }
}

function* getConsumptionTypes(action) {
    const consumptionTypes = yield ReportService.getConsumptionTypesPerGroup(
        action.reportTypeId,
        action.reportSubtypeId
    );
    if (consumptionTypes) {
        yield put({ type: ActionTypes.VESSEL_REPORT_SET_CONSUMPTION_TYPES, consumptionTypes });
    }
}

let convertedValidations = null;

function* getReport(action) {
    const params = { shouldShowSuggestions: action.shouldShowSuggestions };
    let validations = null;
    let report = yield ReportService.getById(action.reportId, action.reportType, params);
    if (report) {
        yield getBunkerTypes({
            reportTypeId: action.reportType,
            reportSubtypeId: report.ReportSubtype && report.ReportSubtype.Id
        });
        if (action.shouldShowSuggestions) {
            if (!convertedValidations) {
                const res = yield ConfigService.VesselReport.getValidations();
                convertedValidations = convertValidations(res);
            }
            validations = convertValidationRules(report, convertedValidations[reportTypeMap[action.reportType]]);
        }
        report = {
            ...report,
            DSST: createDSST(report.DataSourceId, report.ReportSubtype && report.ReportSubtype.Id),
            Vessel: report.Vessel || { Imo: report.Imo, Title: report.VesselName }
        };
        // mocked AuroraReportData
        if (LocalStorageService.isDebugModeActive()) {
            report.AuroraReportData = yield mockAuroraReportData();
        }
        if (report.AuroraReportData) {
            auroraColExtractionProps.forEach(acep => {
                report.AuroraReportData[acep.dataProp] = extractAndMapColumns(
                    report.AuroraReportData[acep.dataProp], acep.colProp
                );
            });
            report.AuroraReportData.ExtractedEquipmentItems = extractEquipmentItems(
                report.AuroraReportData.EquipmentItems
            );
            report.AuroraReportData.Water = extractWaterData(report.AuroraReportData.Water);
        }
        if (report.CargoStatus === undefined) {
            report.CargoStatus = { Id: report.CargoStatusId, Title: report.CargoStatusName };
        } else if (report.CargoStatus) {
            report.CargoStatus = { Id: report.CargoStatus.Id, Title: report.CargoStatus.Name };
        }
        const fuelTypes = yield select(state => getReportFuelTypes(state, report));
        fuelDataTypes.forEach((type) => {
            if (report[type] !== undefined) {
                report[type] = convertFuelData(report[type], fuelTypes.map);
            }
        });
        report.Utilization = extractUtilization(report);

        report.Suggestions = splitArrayByProp(report.Suggestions, 'Key');

        durationKeys.forEach(durationKey => {
            const value = getObjectProp(report, durationKey);
            if (value !== undefined && value !== null) {
                setObjectProp(report, durationKey, TimeHelper.getStringFromDuration(value * 60 * 1000));
            }
            if (report.Suggestions[durationKey]) {
                report.Suggestions[durationKey] = report.Suggestions[durationKey].map((suggestion) => {
                    if (suggestion.Type !== 'Value') {
                        return suggestion;
                    }
                    return { ...suggestion, Value: TimeHelper.getStringFromDuration(suggestion.Value * 60 * 1000) };
                });
            }
        });
        dateOffsetKeys.forEach(dateOffsetKey => {
            const value = getObjectProp(report, dateOffsetKey);
            if (value !== undefined && value !== null) {
                setObjectProp(report, dateOffsetKey, TimeHelper.getUTCOffsetString(value, false), true);
            }
            if (report.Suggestions[dateOffsetKey]) {
                report.Suggestions[dateOffsetKey] = report.Suggestions[dateOffsetKey].map((suggestion) => {
                    if (suggestion.Type !== 'Value') {
                        return suggestion;
                    }
                    return { ...suggestion, Value: TimeHelper.getUTCOffsetString(suggestion.Value, false) };
                });
            }
        });
        /* create TankCleaning field based on values in tank cleaning fields */
        report.TankCleaning = tankCleaningFields.some(fieldName => !!report[fieldName]);
        if (report.ReportTypeId === 7 && report.CargoOperations !== undefined) {
            report.CargoOperations = report.CargoOperations.map((cargoOperation) => ({
                ...cargoOperation,
                CommencedDateOffset: TimeHelper.getUTCOffsetString(cargoOperation.CommencedDateOffset, false),
                CompletedDateOffset: TimeHelper.getUTCOffsetString(cargoOperation.CompletedDateOffset, false)
            }));
        }
        if (report.Tanks) {
            report.Tanks = convertTankDataForEdit(report.Tanks);
        }
        yield put({ type: ActionTypes.VESSEL_REPORT_SET, report, reportType: action.reportType, validations });
    }
}

function* updateReport(action) {
    let parsedReport = action.report;
    if (action.report) {
        const report = action.report;
        parsedReport = {
            ...report,
            VesselName: report.Vessel.Title,
            Imo: report.Vessel.Imo,
            VoyageNumber: report.Voyage.VoyageNumber,
            ...report.Utilization ? convertUtilizationForUpdate(report) : null
        };
        durationKeys.forEach(durationKey => {
            const value = getObjectProp(parsedReport, durationKey);
            if (value !== null && value !== undefined) {
                setObjectProp(
                    parsedReport, durationKey, !value ? null : TimeHelper.getDurationFromString(value).asMinutes()
                );
            }
        });
        dateOffsetKeys.forEach(dateOffsetKey => {
            const value = getObjectProp(report, dateOffsetKey);
            if (value !== null && value !== undefined) {
                setObjectProp(parsedReport, dateOffsetKey, TimeHelper.getMinutesFromTimeSpan(value, false), true);
            }
        });
        if (report.ReportTypeId === 7 && report.CargoOperations !== undefined) {
            parsedReport.CargoOperations = report.CargoOperations.map((cargoOperation) => ({
                ...cargoOperation,
                CommencedDateOffset: TimeHelper.getMinutesFromTimeSpan(cargoOperation.CommencedDateOffset, false),
                CompletedDateOffset: TimeHelper.getMinutesFromTimeSpan(cargoOperation.CompletedDateOffset, false)
            }));
        }
        const fuelTypes = yield select(state => getReportFuelTypes(state, report));
        fuelDataTypes.forEach((type) => {
            if (parsedReport[type] !== undefined) {
                parsedReport[type] = parseFuelData(parsedReport[type], fuelTypes.map);
            }
        });
        /* clear Consumptions or DischargeOperations based on PortActivityType */
        if (report.PortActivityType?.Name === 'Discharge') {
            parsedReport.Consumptions = [];
        }
        if (report.PortActivityType?.Name === 'Load') {
            parsedReport.DischargeOperations = [];
        }
        /* clear tank cleaning values based on TankCleaning value */
        if (!report.TankCleaning) {
            tankCleaningFields.forEach(field => {
                parsedReport[field] = null;
            });
        }
        /* clear tug reason value based on number of tugs */
        if (!parseInt(report.NumberOfTugsUsed, 10)) {
            parsedReport.TugReason = null;
        } else if (report.TugReason) {
            parsedReport.TugReason = report.TugReason.Id;
        }
        if (report.CargoStatus) {
            parsedReport.CargoStatus = { Id: report.CargoStatus.Id, Name: report.CargoStatus.Title };
        }
        if (report.Tanks) {
            parsedReport.Tanks = convertTankDataForUpdate(report.Tanks);
        }
        if (report.Waypoints && report.Waypoints.length) {
            parsedReport.Waypoints = convertWayPointsDataForUpdate(report.Waypoints);
        }
        if (parsedReport.Vessel !== undefined) {
            delete parsedReport.Vessel;
        }
        if (parsedReport.Utilization) {
            delete parsedReport.Utilization;
        }
        if (report.ReportTypeId === 3 && !report.CargoOperations) {
            parsedReport.NoticeOfReadiness = null;
            parsedReport.NoticeOfReadinessOffset = null;
        }
        if (report.ReportTypeId === 5) {
            if (report.EventType.Id !== 8 && report.EventType.Id !== 9) {
                parsedReport.SpecialArea = null;
            }
            if (report.EventType.Id !== 9 && report.EventType.Id !== 10 && report.EventType.Id !== 11) {
                parsedReport.TotalDistanceSteamedInArea = null;
                parsedReport.TotalTimeSpentInArea = null;
            }
        }
    }
    const reportResponse = yield ReportService.update(action.report.ReportId, action.report.ReportTypeId, parsedReport);
    if (reportResponse) {
        yield put({
            type: ActionTypes.VESSEL_REPORT_SUCCESSFULLY_UPDATED,
            reportId: action.report.ReportId
        });
        toast(translate('REPORT_LABELS.REPORT_SUCCESSFULLY_UPDATED'), { type: toast.TYPE.SUCCESS });
        yield updateQueryParams({
            remove: ['reportEdit', ...action.shouldClose ? ['reportId', 'reportType'] : []]
        });
        if (action.shouldReload) {
            window.location.reload();
        } else if (!action.shouldClose) {
            yield getReport({
                reportId: parsedReport.ReportId, reportType: parsedReport.ReportTypeId, shouldShowSuggestions: false
            });
        }
    }
}

function* deleteReport(action) {
    const reportId = action.reportId;
    const response = yield ReportService.remove(reportId);
    if (response) {
        yield put({
            type: ActionTypes.VESSEL_REPORT_SUCCESSFULLY_DELETED,
            reportId
        });
        toast(translate('REPORT_LABELS.REPORT_SUCCESSFULLY_DELETED'), { type: toast.TYPE.SUCCESS });
        yield updateQueryParams({ remove: ['reportId', 'reportType'] });
        if (action.shouldReload) {
            window.location.reload();
        }
    }
}

function* sendReminder(action) {
    const report = yield ReportService.sendReminder(action.missingReportId);
    if (report) {
        yield put({ type: ActionTypes.VESSEL_REPORT_REMINDER_SENT, report });
        yield put({ type: SendReminderModalActions.TOGGLE_MISSING_REPORT_REMINDER_MODAL, report: null });
        toast(translate('REPORT_LABELS.REMINDER_SUCCESSFULLY_SENT'), { type: toast.TYPE.SUCCESS });
    }
}

let areConfigsFetched = false;
let isFetchingConfigs = false;

function* getConfigs() {
    if (!areConfigsFetched && !isFetchingConfigs) {
        const permissions = yield select(state => state.userReducer.permissions);
        if (permissions && permissions.GetReportTypes) {
            isFetchingConfigs = true;
            const res = yield all({
                visibleTypesAndFields: ConfigService.VesselReport.getHiddenFields(),
                editableReports: ConfigService.VesselReport.getEditable()
            });
            if (res) {
                areConfigsFetched = true;
                yield put({ type: ActionTypes.VESSEL_REPORT_SET_CONFIGS, ...res });
            }
            isFetchingConfigs = false;
        }
    }
}

export default function* vesselReportSaga() {
    yield takeEvery(ActionTypes.VESSEL_REPORT_GET, getReport);
    yield takeEvery(ActionTypes.VESSEL_REPORT_GET_EDITING_OPTIONS, getEditingOptions);
    yield takeEvery(ActionTypes.VESSEL_REPORT_GET_CONSUMPTION_TYPES, getConsumptionTypes);
    yield takeEvery(ActionTypes.VESSEL_REPORT_GET_FUEL_TYPES, getFuelTypes);
    yield takeEvery(ActionTypes.VESSEL_REPORT_GET_CONFIGS, getConfigs);
    yield takeEvery(ActionTypes.VESSEL_REPORT_UPDATE, updateReport);
    yield takeEvery(ActionTypes.VESSEL_REPORT_DELETE, deleteReport);
    yield takeEvery(ActionTypes.VESSEL_REPORT_SEND_REMINDER, sendReminder);
}
