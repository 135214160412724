import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
import { formatNumber } from 'utils/helpers/info-helper';
/* styles */
import './notification.scss';

class Notification extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            confirmType: '',
            areDetailsShown: false,
            isConfirmActive: props.isConfirmActive
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (state.isConfirmActive !== props.isConfirmActive && !props.isConfirmActive) {
            return {
                confirmType: '',
                isConfirmActive: props.isConfirmActive
            };
        }
        return null;
    }

    onAcceptConfirm = () => {
        switch (this.state.confirmType) {
        case 'reminder': {
            if (this.props.notification.Context.MissingReportId) {
                this.props.onReminderConfirm(parseInt(this.props.notification.Context.MissingReportId, 10));
            }
            break;
        }
        default:
            this.props.onDismissConfirm(this.props.notification.NotificationId);
        }
        this.setState({ confirmType: '' });
        this.props.onConfirmToggle(null);
    };

    onCloseConfirm = () => {
        this.setState({
            confirmType: ''
        }, () => {
            this.props.onConfirmToggle(null);
        });
    };

    getTitleLink = (vesselImo) => {
        const { router } = this.props;
        if (vesselImo && (!router.query.vessel || router.query.vessel !== vesselImo.toString())) {
            return router.getLinkTo({
                remove: [
                    'port',
                    'reportId',
                    'reportType',
                    'reportEdit',
                    'questionnaireId',
                    'questionnaireEdit',
                    'voyage',
                    'terminal',
                    'berth',
                    'terminalUpdate',
                    'berthUpdate',
                    'terminalsShown'
                ],
                add: { vessel: vesselImo }
            });
        }
        return router.location;
    }

    onSelectToggle = () => {
        if (!this.props.notification.Dismissed && this.props.onSelectToggle) {
            this.props.onSelectToggle(this.props.notification);
        }
    };

    getConfirmText() {
        const confirmTextMap = {
            dismiss: (
                <React.Fragment>
                    {t('NOTIFICATIONS.CONFIRM.DISMISS_MESSAGE_1')}
                    <br />
                    {t('NOTIFICATIONS.CONFIRM.DISMISS_MESSAGE_2')}
                </React.Fragment>
            ),
            reminder: t('NOTIFICATIONS.CONFIRM.SEND_REMINDER')
        };
        if (!this.state.confirmType) {
            return '';
        }
        return confirmTextMap[this.state.confirmType];
    }

    toggleDetails = () => {
        this.setState({
            areDetailsShown: !this.state.areDetailsShown
        });
    };

    handleReportLink = () => {
        if (this.props.notification.ReportId && this.props.showReportedAsLink) {
            const add = {
                reportId: this.props.notification.ReportId.toString(),
                reportType: 1
            };
            if (this.props.router.query.reportId !== add.reportId) {
                return this.props.router.getLinkTo({ add });
            }
        }
        return this.props.router.location;
    };

    getContent() {
        const { notification } = this.props;
        let content = [];
        const reportLinkClassName = this.props.showReportedAsLink ? 'sten-link' : '';

        switch (notification.Class) {
        case 'EtaChanged': {
            if (notification.Context.ReferenceValue) {
                content = [
                    <h4 key="value" className="text-ellipsis">
                        {t('NOTIFICATIONS.MESSAGE.NEW_ETA', {
                            timestamp: TimeHelper.getFormatted(
                                notification.Context.Value, { utc: true, time: true, convert: true }
                            )
                        })}
                        {notification.VoyageNumber && t('NOTIFICATIONS.MESSAGE.VOYAGE_NUMBER', {
                            voyageNumber: notification.VoyageNumber
                        })}
                    </h4>,
                    <p key="refValue" className="text-secondary text-ellipsis">
                        {t('NOTIFICATIONS.MESSAGE.OLD_ETA', {
                            timestamp: TimeHelper.getFormatted(
                                notification.Context.ReferenceValue, { utc: true, time: true, convert: true }
                            )
                        })}
                    </p>
                ];
            } else {
                content = [<div key="message" className="text-ellipsis">{notification.Message}</div>];
            }
            content.push(
                <Link
                    key="timestamp"
                    className={reportLinkClassName}
                    to={this.handleReportLink()}
                >
                    {t('NOTIFICATIONS.MESSAGE.REPORTED', {
                        timestamp: TimeHelper.getFormatted(notification.ReportTimestamp, { utc: true, time: true })
                    })}
                </Link>
            );
            break;
        }
        case 'TrimDeviation': {
            const { DraftAft, DraftFwd, CargoStatus, BallastOptimumTrim, LadenOptimumTrim } = notification.Context;
            const trim = formatNumber(DraftAft - DraftFwd);
            content = [
                <h4 key="message" className="text-ellipsis">
                    {t('NOTIFICATIONS.MESSAGE.TRIM_DEVIATION')}
                </h4>,
                <p key="message2" className="text-ellipsis text-secondary">
                    {CargoStatus === 'Ballast'
                        ? t('NOTIFICATIONS.MESSAGE.REPORTED_BALLAST_TRIM', { value: trim })
                        : t('NOTIFICATIONS.MESSAGE.REPORTED_LADEN_TRIM', { value: trim })}
                </p>,
                <p key="message3" className="text-ellipsis text-secondary">
                    {CargoStatus === 'Ballast'
                        ? t('NOTIFICATIONS.MESSAGE.OPTIMAL_BALLAST_TRIM', { value: BallastOptimumTrim })
                        : t('NOTIFICATIONS.MESSAGE.OPTIMAL_LADEN_TRIM', { value: LadenOptimumTrim })}
                </p>
            ];
            content.push(
                <Link
                    key="timestamp"
                    className={reportLinkClassName}
                    to={this.handleReportLink()}
                >
                    {t('NOTIFICATIONS.MESSAGE.REPORTED', {
                        timestamp: TimeHelper.getFormatted(notification.ReportTimestamp, { utc: true, time: true })
                    })}
                </Link>
            );
            break;
        }
        case 'SpeedDeviation': {
            const { ReferenceValue, Value } = notification.Context;
            if (ReferenceValue) {
                const deviation = formatNumber(Value - ReferenceValue);
                content = [
                    <h4 key="message" className="text-ellipsis">
                        {t('NOTIFICATIONS.MESSAGE.SPEED_DEVIATION', { deviation })}
                        {notification.VoyageNumber && t('NOTIFICATIONS.MESSAGE.VOYAGE_NUMBER', {
                            voyageNumber: notification.VoyageNumber
                        })}
                    </h4>
                ];
            } else {
                content = [<div key="message" className="text-ellipsis">{notification.Message}</div>];
            }
            content.push(
                <Link
                    key="timestamp"
                    className={reportLinkClassName}
                    to={this.handleReportLink()}
                >
                    {t('NOTIFICATIONS.MESSAGE.REPORTED', {
                        timestamp: TimeHelper.getFormatted(notification.ReportTimestamp, { utc: true, time: true })
                    })}
                </Link>
            );
            break;
        }
        case 'SpeedDeviationAgainstCPA': {
            content = [
                <h4 key="message" className="text-ellipsis">
                    {t('NOTIFICATIONS.MESSAGE.SPEED_DEVIATION_CPA', {
                        value: notification.Context.SpeedDeviationAgainstCPA
                    })}
                    {notification.VoyageNumber && t('NOTIFICATIONS.MESSAGE.VOYAGE_NUMBER', {
                        voyageNumber: notification.VoyageNumber
                    })}
                </h4>,
                <Link
                    key="timestamp"
                    className={reportLinkClassName}
                    to={this.handleReportLink()}
                >
                    {t('NOTIFICATIONS.MESSAGE.REPORTED', {
                        timestamp: TimeHelper.getFormatted(notification.ReportTimestamp, { utc: true, time: true })
                    })}
                </Link>
            ];
            break;
        }
        case 'ConsumptionDeviation': {
            const { ReferenceValue, Value } = notification.Context;
            const deviation = formatNumber(Math.abs(Value - ReferenceValue));
            if (deviation >= 0) {
                content = [
                    <h4 key="message" className="text-ellipsis">
                        {t('NOTIFICATIONS.MESSAGE.CONSUMPTION_DEVIATION', { deviation })}
                        {notification.VoyageNumber && t('NOTIFICATIONS.MESSAGE.VOYAGE_NUMBER', {
                            voyageNumber: notification.VoyageNumber
                        })}
                    </h4>
                ];
            } else {
                content = [
                    <p key="message" className="text-ellipsis">
                        {t('NOTIFICATIONS.MESSAGE.CONSUMPTION_DEVIATION_UNDER', { deviation })}
                    </p>
                ];
            }
            content.push(
                <Link
                    key="timestamp"
                    className={reportLinkClassName}
                    to={this.handleReportLink()}
                >
                    {t('NOTIFICATIONS.MESSAGE.REPORTED', {
                        timestamp: TimeHelper.getFormatted(notification.ReportTimestamp, { utc: true, time: true })
                    })}
                </Link>
            );
            break;
        }
        case 'ConsumptionDeviationAgainstCPA': {
            content = [
                <h4 key="message" className="text-ellipsis">
                    {t('NOTIFICATIONS.MESSAGE.CONSUMPTION_DEVIATION_CPA', {
                        value: notification.Context.ConsumptionDeviationAgainstCPA
                    })}
                    {notification.VoyageNumber && t('NOTIFICATIONS.MESSAGE.VOYAGE_NUMBER', {
                        voyageNumber: notification.VoyageNumber
                    })}
                </h4>,
                <Link
                    key="timestamp"
                    className={reportLinkClassName}
                    to={this.handleReportLink()}
                >
                    {t('NOTIFICATIONS.MESSAGE.REPORTED', {
                        timestamp: TimeHelper.getFormatted(notification.ReportTimestamp, { utc: true, time: true })
                    })}
                </Link>
            ];
            break;
        }
        case 'MissingReport': {
            content = [
                <h4 key="message1" className="text-ellipsis">
                    {t('NOTIFICATIONS.MESSAGE.DAILY_REPORT', {
                        timestamp: TimeHelper.getFormatted(notification.Context.Date, { utc: true, time: true })
                    })}
                </h4>,
                <p key="message2" className="text-ellipsis text-secondary">
                    {t('NOTIFICATIONS.MESSAGE.MISSING_REPORT')}
                    {notification.VoyageNumber && t('NOTIFICATIONS.MESSAGE.VOYAGE_NUMBER', {
                        voyageNumber: notification.VoyageNumber
                    })}
                </p>,
                <p key="timestamp" className="text-secondary">
                    {t('NOTIFICATIONS.MESSAGE.REPORTED', {
                        timestamp: TimeHelper.getFormatted(notification.Timestamp, { time: true })
                    })}
                </p>
            ];

            const showDetails = this.state.areDetailsShown
                ? [t('GLOBAL.HIDE_DETAILS'), <span key="icon" className="icon icon-caret-up" />]
                : [t('GLOBAL.SHOW_DETAILS'), <span key="icon" className="icon icon-caret-down" />];

            if (notification.Context.ReminderDate) {
                content.push(
                    <div key="message3" className="sten-notification__detached">
                        <p key="reminder1">
                            {t('NOTIFICATIONS.MESSAGE.REMINDER_SENT')}
                            <button className="sten-link" onClick={this.toggleDetails}>{showDetails}</button>
                        </p>
                        {this.state.areDetailsShown && [
                            <h6 key="reminder2" className="text-secondary">
                                {t('NOTIFICATIONS.MESSAGE.LAST_REMINDER_SENT', {
                                    timestamp: TimeHelper.getFormatted(
                                        notification.Context.ReminderDate, { time: true }
                                    )
                                })}
                            </h6>,
                            <h6 key="reminder3" className="text-secondary">
                                {t('NOTIFICATIONS.MESSAGE.REMINDER_SENT_BY', {
                                    user: notification.Context.UserName
                                })}
                            </h6>,
                            <div key="reminder4" className="flex text-secondary">
                                <h6 className="flex-shrink text-nowrap">
                                    {`${t('NOTIFICATIONS.MESSAGE.REMINDER_SENT_TO')} `}
                                </h6>
                                <h6 className="flex-grow text-ellipsis">
                                    {notification.Context.EmailTo.split(';').join('\n')}
                                </h6>
                            </div>
                        ]}
                    </div>
                );
            }
            break;
        }
        case 'CdiAge': {
            content = [
                <h4 key="message" className="text-ellipsis">
                    {t('NOTIFICATIONS.MESSAGE.CDI_AGE', {
                        timestamp: TimeHelper.getFormatted(
                            notification.Context.InspectionDate, { utc: true, convert: true }
                        )
                    })}
                </h4>,
                <p key="timestamp" className="text-secondary">
                    {t('NOTIFICATIONS.MESSAGE.REPORTED', {
                        timestamp: TimeHelper.getFormatted(notification.Timestamp, { time: true })
                    })}
                </p>
            ];
            break;
        }
        case 'SireAge': {
            content = [
                <h4 key="message" className="text-ellipsis">
                    {t('NOTIFICATIONS.MESSAGE.SIRE_AGE', {
                        timestamp: TimeHelper.getFormatted(
                            notification.Context.InspectionDate, { utc: true, convert: true }
                        )
                    })}
                </h4>,
                <p key="timestamp" className="text-secondary">
                    {t('NOTIFICATIONS.MESSAGE.REPORTED', {
                        timestamp: TimeHelper.getFormatted(notification.Timestamp, { time: true })
                    })}
                </p>
            ];
            break;
        }
        default:
            content = [
                <h4 key="message" className="text-ellipsis">{notification.Message}</h4>,
                <p key="timestamp" className="text-secondary">
                    {t('NOTIFICATIONS.MESSAGE.REPORTED', {
                        timestamp: TimeHelper.getFormatted(notification.Timestamp, { time: true })
                    })}
                </p>
            ];
        }

        if (notification.Dismissed) {
            content.push(
                <p key="dismissed" className="sten-notification__detached">
                    <span className="text-secondary">{`${t('NOTIFICATIONS.MESSAGE.DISMISSED_BY')}: `}</span>
                    {notification.DismissedBy || 'OrbitApp'}
                </p>
            );
        }

        return (
            <div className="flex-grow">
                {!this.props.hideTitle && (
                    <Link
                        className="sten-notification__title sten-title-link"
                        to={this.getTitleLink(notification.IMO)}
                    >
                        {notification.VesselName}
                    </Link>
                )}
                {content}
            </div>
        );
    }

    toggleConfirm(confirmType) {
        this.setState({
            confirmType
        }, () => {
            this.props.onConfirmToggle(this.props.notification.NotificationId);
        });
    }

    render() {
        if (!this.props.notification) {
            return null;
        }
        let notificationClass = 'sten-notification';
        let checkboxIconClass = 'icon flex-grow';
        if (this.props.className) {
            notificationClass += ` ${this.props.className}`;
        }
        if (!this.props.notification.Dismissed && this.props.onSelectToggle) {
            notificationClass += ' sten-notification--selectable';
        }
        if (this.props.isSelected) {
            notificationClass += ' sten-notification--selected';
            checkboxIconClass += ' icon-check';
        } else if (this.props.notification.Severity === 'Alert') {
            checkboxIconClass += ' icon-exclamation-circle';
        } else {
            checkboxIconClass += ' icon-info-circle';
        }
        let confirmClass = 'sten-notification__confirm flex';
        if (this.props.isConfirmActive && !!this.state.confirmType) {
            confirmClass += ' sten-notification__confirm--active';
        }

        let isReminderDisabled = false;
        let reminderBtnTitle = t('NOTIFICATIONS.MESSAGE.REMINDER_SEND_TITLE');

        if (this.props.notification.Class === 'MissingReport') {
            if (!this.props.notification.IsSendEnabled) {
                isReminderDisabled = true;
                reminderBtnTitle = t('NOTIFICATIONS.MESSAGE.REMINDER_DISABLED_INVALID_EMAIL');
            } else {
                const reminderDateDifference = this.props.notification.Context.ReminderDate
                    ? moment().diff(moment(this.props.notification.Context.ReminderDate))
                    : null;
                if (reminderDateDifference && reminderDateDifference < 24 * 60 * 60000) {
                    isReminderDisabled = true;
                    reminderBtnTitle = t('NOTIFICATIONS.MESSAGE.REMINDER_DISABLED');
                }
            }
        }
        return (
            <div className={notificationClass}>
                <div className="flex">
                    <div
                        className="sten-notification__checkbox flex-shrink flex flex-center"
                        onClick={this.onSelectToggle}
                    >
                        <span className={checkboxIconClass} />
                    </div>
                    <div className="flex-grow flex-row flex-center sten-notification__content">
                        {this.getContent()}
                        {this.props.sendReminderAllowed
                            && !this.props.notification.Dismissed
                            && this.props.notification.Class === 'MissingReport' && (
                            <div className="flex-shrink flex">
                                <button
                                    onClick={this.toggleConfirm.bind(this, 'reminder')}
                                    className="btn-link icon icon-alert flex-grow text-center"
                                    disabled={isReminderDisabled}
                                    title={reminderBtnTitle}
                                />
                            </div>
                        )}
                        {!this.props.hideActions && !this.props.notification.Dismissed && (
                            <div className="flex-shrink flex">
                                <button
                                    onClick={this.toggleConfirm.bind(this, 'dismiss')}
                                    className="btn-link icon icon-dismiss"
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className={confirmClass}>
                    <div className="sten-notification__content flex-grow flex-row">
                        <p className="flex-grow flex flex-center">
                            {this.getConfirmText()}
                        </p>
                        <div className="flex-shrink flex">
                            <button
                                onClick={this.onAcceptConfirm}
                                className="btn-link icon icon-check flex-grow text-center"
                            />
                        </div>
                        <div className="flex-shrink flex">
                            <button
                                onClick={this.onCloseConfirm}
                                className="btn-link icon icon-close flex-grow text-center"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Notification.propTypes = {
    className: PropTypes.string,
    hideActions: PropTypes.bool,
    hideTitle: PropTypes.bool,
    isConfirmActive: PropTypes.bool,
    isSelected: PropTypes.bool,
    notification: PropTypes.objectOf(PropTypes.any),
    onConfirmToggle: PropTypes.func,
    onDismissConfirm: PropTypes.func,
    onReminderConfirm: PropTypes.func,
    onSelectToggle: PropTypes.func,
    router: TRouter.isRequired,
    sendReminderAllowed: PropTypes.bool,
    showReportedAsLink: PropTypes.bool
};

Notification.defaultProps = {
    className: '',
    hideActions: false,
    hideTitle: false,
    isConfirmActive: false,
    isSelected: false,
    notification: null,
    onConfirmToggle: undefined,
    onDismissConfirm: undefined,
    onReminderConfirm: undefined,
    onSelectToggle: undefined,
    sendReminderAllowed: false,
    showReportedAsLink: true
};

export default withRouter(Notification);
