import { ActionTypes } from './quarterly-report-actions';

const initialState = {
    report: null
};

export default function energyManagementReportsQuarterlyReportReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ENERGY_MANAGEMENT_QUARTERLY_REPORT_SET: {
        return {
            ...state,
            report: action.report
        };
    }
    default:
        return state;
    }
}
