import Excel from 'exceljs';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { formatXLSXCellValue, generateXLSXHeaderLogos } from '../../../reports-helpers';
import {
    columnWidth,
    tableHeaderStyle,
    tableCellStyle,
    headingTitleStyle
} from 'utils/helpers/xlsx-styles';
import { addWorksheet, triggerXLSXDownload } from 'utils/helpers/xlsx-helper';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.PERFORMANCE_SUMMARY.${key}`);

const centeredTableHeaderStyle = {
    ...tableHeaderStyle,
    alignment: {
        horizontal: 'center',
        wrapText: true,
        vertical: 'middle'
    }
};

const centeredTableCellStyle = {
    ...tableCellStyle,
    alignment: {
        horizontal: 'center',
        wrapText: true,
        vertical: 'middle'
    }
};

const formatTableCell = (value, format, props = null, colSpan = 1, rowSpan = 1, key) => {
    let formattedValue = value;
    if (key === 'IsBallast') {
        formattedValue = value ? translate('GLOBAL.BALLAST') : translate('GLOBAL.LADEN');
    }
    return {
        value: formatXLSXCellValue(formattedValue, format),
        colSpan,
        rowSpan,
        props: { ...centeredTableCellStyle, ...props, numFmt: format.numFmt || null }
    };
};

const reportTableHeader = [
    {
        values: [
            { value: t('VESSEL_NAME'), colSpan: 2, rowSpan: 2, props: centeredTableHeaderStyle },
            null,
            { value: t('DEPARTURE'), colSpan: 2, props: centeredTableHeaderStyle },
            null,
            { value: t('ARRIVAL'), colSpan: 2, props: centeredTableHeaderStyle },
            null,
            { value: t('CONDITION'), props: centeredTableHeaderStyle },
            { value: t('DEPARTURE_DATA'), colSpan: 3, props: centeredTableHeaderStyle },
            null,
            null,
            { value: t('SPEED'), props: centeredTableHeaderStyle },
            { value: t('ALL_REPORTED_DATA'), colSpan: 5, props: centeredTableHeaderStyle },
            null,
            null,
            null,
            null,
            { value: t('DATA_WITH_EXCLUSIONS'), colSpan: 5, props: centeredTableHeaderStyle },
            null,
            null,
            null,
            null,
            { value: t('THEORETICAL_BASIS_CP'), colSpan: 3, props: centeredTableHeaderStyle },
            null,
            null,
            { value: t('DEVIATION_FROM_CP'), colSpan: 2, props: centeredTableHeaderStyle },
            null
        ]
    },
    {
        values: [
            null,
            null,
            { value: t('PORT'), props: centeredTableHeaderStyle },
            { value: t('DATE'), props: centeredTableHeaderStyle },
            { value: t('PORT'), props: centeredTableHeaderStyle },
            { value: t('DATE'), props: centeredTableHeaderStyle },
            { value: t('BALLAST_LADEN'), props: centeredTableHeaderStyle },
            { value: t('CARGO'), props: centeredTableHeaderStyle },
            { value: t('MEAN_DRAFT'), props: centeredTableHeaderStyle },
            { value: t('TRIM'), props: centeredTableHeaderStyle },
            { value: t('INSTRUCTED_SPEED'), props: centeredTableHeaderStyle },
            { value: t('TOTAL_DISTANCE'), props: centeredTableHeaderStyle },
            { value: t('TOTAL_TIME'), props: centeredTableHeaderStyle },
            { value: t('AVG_SPEED'), props: centeredTableHeaderStyle },
            { value: t('TOTAL_MAIN_ENG_CONS'), props: centeredTableHeaderStyle },
            { value: t('TOTAL_AUX_CONS'), props: centeredTableHeaderStyle },
            { value: t('TOTAL_DISTANCE'), props: centeredTableHeaderStyle },
            { value: t('TOTAL_TIME'), props: centeredTableHeaderStyle },
            { value: t('AVG_SPEED'), props: centeredTableHeaderStyle },
            { value: t('TOTAL_MAIN_ENG_CONS'), props: centeredTableHeaderStyle },
            { value: t('TOTAL_AUX_CONS'), props: centeredTableHeaderStyle },
            { value: t('AVG_SPEED'), props: centeredTableHeaderStyle },
            { value: t('TOTAL_MAIN_ENG_CONS'), props: centeredTableHeaderStyle },
            { value: t('TOTAL_AUX_CONS'), props: centeredTableHeaderStyle },
            { value: t('TIME_DEVIATION'), props: centeredTableHeaderStyle },
            { value: t('CONSUMPTION_DEVIATION'), props: centeredTableHeaderStyle }
        ]
    }
];

const speedRow = new Array(10).fill(null);

const sectionKeys = [
    'DeparturePort',
    'DepartureDate',
    'ArrivalPort',
    'ArrivalDate',
    'IsBallast',
    'Cargo',
    'MeanDraft',
    'Trim'
];

const keyToFormatMap = {
    InstructedSpeed: { type: 'number', numFmt: '0.0' },
    TotalDistance: { type: 'number', numFmt: '0.0' },
    TotalTime: { type: 'number', numFmt: '0.0' },
    AvgSpeed: { type: 'number', numFmt: '0.0' },
    TotalAuxCons: { type: 'number', numFmt: '0.0' },
    TotalPropCons: { type: 'number', numFmt: '0.0' },
    Consumption: { type: 'number', numFmt: '0.0' },
    Time: { type: 'number', numFmt: '0.0' },
    DeparturePort: { type: 'string' },
    DepartureDate: { type: 'date' },
    ArrivalPort: { type: 'string' },
    ArrivalDate: { type: 'date' },
    IsBallast: { type: 'string' },
    Cargo: { type: 'number', numFmt: '0.0' },
    MeanDraft: { type: 'number', numFmt: '0.0' },
    Trim: { type: 'number', numFmt: '0.0' }
};

const mapSectionValues = section =>
    sectionKeys.map(key =>
        formatTableCell(section[key], keyToFormatMap[key], null, 1, section.InstructedSpeeds.length || 1, key));

const speedSections = [
    { key: 'InstructedSpeed' },
    {
        key: 'AllReportedData',
        props: [
            'TotalDistance',
            'TotalTime',
            'AvgSpeed',
            'TotalPropCons',
            'TotalAuxCons'
        ]
    },
    {
        key: 'DataWithExclusions',
        props: [
            'TotalDistance',
            'TotalTime',
            'AvgSpeed',
            'TotalPropCons',
            'TotalAuxCons'
        ]
    },
    {
        key: 'TheoreticalBasisCP',
        props: [
            'AvgSpeed',
            'TotalPropCons',
            'TotalAuxCons'
        ]
    },
    {
        key: 'DeviationFromCP',
        props: [
            'Time',
            'Consumption'
        ]
    }
];

const mapSpeedValues = (speed) => {
    let res = [];

    speedSections.forEach((section) => {
        if (speed.IsExcluded && section.key === 'DataWithExclusions') {
            res = res.concat([
                formatTableCell(speed.ExclusionReasons.join('; '), { type: 'string' }, centeredTableCellStyle, 5),
                null,
                null,
                null,
                null
            ]);
        } else if (section.props) {
            section.props.forEach((prop) => {
                res.push(formatTableCell(speed[section.key][prop], keyToFormatMap[prop]));
            });
        } else {
            res.push(formatTableCell(speed[section.key], keyToFormatMap[section.key]));
        }
    });

    return res;
};

const generateReportTableRows = (vesselData) => {
    const vessels = vesselData.Vessels && vesselData.Vessels.length ? vesselData.Vessels : [];
    const rows = [];
    let sectionValues;
    let vesselStartRowIndex = 0;
    let sectionStartRowIndex = 0;
    vessels.forEach((vessel) => {
        vesselStartRowIndex = rows.length;
        if (vessel.Sections.length) {
            vessel.Sections.forEach((section) => {
                sectionStartRowIndex = rows.length;
                if (section.InstructedSpeeds.length) {
                    section.InstructedSpeeds.forEach((speed) => {
                        rows.push({
                            values: [
                                ...speedRow,
                                ...mapSpeedValues(speed)
                            ]
                        });
                    });
                } else {
                    rows.push({
                        values: [
                            ...speedRow,
                            formatTableCell(
                                section.ExclusionReasons.join('; '),
                                { type: 'string' },
                                centeredTableCellStyle,
                                16
                            )
                        ]
                    });
                }
                sectionValues = mapSectionValues(section);
                for (let i = 2; i < 10; i++) {
                    rows[sectionStartRowIndex].values[i] = sectionValues[i - 2];
                }
            });
        } else {
            rows.push({
                values: [
                    null,
                    null,
                    { value: t('NO_REPORTS'), colSpan: 24, props: centeredTableCellStyle }
                ]
            });
        }
        rows[vesselStartRowIndex].values[0] = {
            value: vessel.Title,
            colSpan: 2,
            props: centeredTableHeaderStyle,
            rowSpan: rows.length - vesselStartRowIndex
        };
    });
    return rows;
};

export default function* generatePerformanceSummaryReportXLSX({
    reportData,
    fileName,
    rangeStart,
    rangeEnd
}) {
    const workbook = new Excel.Workbook();
    const inclusions = reportData.Inclusions;
    const sheetConfig = {
        name: t('PERFORMANCE_SUMMARY_REPORT'),
        props: {
            properties: { showGridLines: false },
            views: [{ showGridLines: false }],
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                margins: { left: 0.4, right: 0.4, top: 0.2, bottom: 0.2, header: 0, footer: 0 }
            }
        },
        columnWidth,
        rows: [
            ...generateXLSXHeaderLogos(null, null, 0.0001),
            {
                values: [{ value: t('PERFORMANCE_SUMMARY_REPORT'), props: headingTitleStyle }]
            },
            null,
            {
                values: [{ value: `${t('DATE_RANGE')} ${rangeStart} - ${rangeEnd}` }]
            },
            {
                values: [{ value: `${t('WIND_SPEED')} ${inclusions.WindSpeedMax}` }]
            },
            {
                values: [{ value: `${t('DRAFT_IN_LADEN')} ${inclusions.DraftInLadenMax}` }]
            },
            {
                values: [{
                    value: 'Instructed Speed ballast between  '
                        + `${inclusions.InstructedSpeedBallastMin} & ${inclusions.InstructedSpeedBallastMax}`
                }]
            },
            {
                values: [{
                    value: 'Instructed Speed laden between  '
                        + `${inclusions.InstructedSpeedLadenMin} & ${inclusions.InstructedSpeedLadenMax}`
                }]
            },
            {
                values: [{
                    value: `${t('SEA_PASSAGE_MIN')} ${inclusions.SeaPassageMin}`
                }]
            },
            null,
            null,
            ...reportTableHeader,
            ...generateReportTableRows(reportData)
        ]
    };
    addWorksheet(workbook, sheetConfig);

    const xls64 = yield workbook.xlsx.writeBuffer({ base64: true });
    return triggerXLSXDownload(fileName)(xls64);
}
