const propTypes = {
    ActualSpeedBallastMax: 'float',
    ActualSpeedBallastMin: 'float',
    ActualSpeedLadenMax: 'float',
    ActualSpeedLadenMin: 'float',
    AuxCons: 'float',
    BallastDaysMax: 'integer',
    BallastDaysMin: 'integer',
    CargoCarriedMax: 'float',
    CargoCarriedMin: 'float',
    CargoDischargedMin: 'integer',
    DischargeOperationsNumberMax: 'integer',
    DischargingTimeMax: 'integer',
    DischargingTimeMin: 'integer',
    DraftInLadenMax: 'integer',
    IncludeCargoHeatedDuringOps: 'boolean',
    IncludeHeat: 'boolean',
    IncludeTc: 'boolean',
    IncludeVesselUnderwayUsingMe: 'boolean',
    InstructedSpeedBallastMax: 'float',
    InstructedSpeedBallastMin: 'float',
    InstructedSpeedLadenMax: 'float',
    InstructedSpeedLadenMin: 'float',
    LadenDaysMax: 'integer',
    LadenDaysMin: 'integer',
    LoadOperationsNumberMax: 'integer',
    LoadingTimeMax: 'integer',
    LoadingTimeMin: 'integer',
    PropConsBallast: 'float',
    PropConsLaden: 'float',
    RefCargoSizeDifferentThan: 'float',
    RefSpeedBallastDifferentThan: 'float',
    RefSpeedLadenDifferentThan: 'float',
    SeaPassageMax: 'float',
    SeaPassageMin: 'float',
    Speed: 'float',
    TotalAuxConsumptionMax: 'integer',
    WindForceMax: 'integer',
    WindSpeedMax: 'integer',
    HoursSteamedMin: 'integer'
};

export default propTypes;
