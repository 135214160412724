import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { FixedSizeList as ReactWindowFixedSizeList } from 'react-window';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
/* helpers */
import { omitObjectKeys } from 'utils/helpers/info-helper';

const heightAutoStyle = {
    overflow: 'visible',
    height: 'auto'
};

const heightFullStyle = {
    overflow: 'visible',
    height: '100%'
};

class FixedSizeList extends React.Component {
    propsToOmit = ['totalHeight', 'totalWidth', 'style', 'ItemComponent'];

    listRef = React.createRef();

    scrollAreaRef = React.createRef();

    handleScroll = (dimensions) => {
        if (this.props.layout === 'vertical') {
            this.listRef.current.scrollTo(dimensions.topPosition);
        } else {
            this.listRef.current.scrollTo(dimensions.leftPosition);
        }
    };

    getScrollAreaStyle = memoize((totalHeight) => ({ height: totalHeight }));

    getScrollAreaContentStyle = () => {
        const paddingRight = typeof this.props.paddingRight === 'number' ? this.props.paddingRight : 0;
        return {
            minWidth: this.props.itemCount * this.props.itemSize + paddingRight,
            height: this.props.totalHeight
        };
    };

    render() {
        return (
            <ScrollArea
                ref={this.scrollAreaRef}
                vertical={this.props.layout === 'vertical'}
                horizontal={this.props.layout === 'horizontal'}
                shouldScrollToActiveElement
                contentStyle={this.props.layout === 'horizontal' ? this.getScrollAreaContentStyle() : null}
                useCSS3Translate={this.props.useCSS3Translate}
                style={this.getScrollAreaStyle(this.props.totalHeight)}
                onScroll={this.handleScroll}
                minScrollSize={20}
                stopScrollPropagation
            >
                <ReactWindowFixedSizeList
                    height={this.props.totalHeight}
                    width={this.props.totalWidth}
                    ref={this.listRef}
                    style={this.props.layout === 'vertical' ? heightAutoStyle : heightFullStyle}
                    {...omitObjectKeys(this.props, this.propsToOmit)}
                >
                    {this.props.ItemComponent}
                </ReactWindowFixedSizeList>
            </ScrollArea>
        );
    }
}

FixedSizeList.propTypes = {
    ItemComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
    itemCount: PropTypes.number.isRequired,
    itemData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    itemSize: PropTypes.number.isRequired,
    layout: PropTypes.oneOf(['vertical', 'horizontal']),
    paddingRight: PropTypes.number,
    totalHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    totalWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    useCSS3Translate: PropTypes.bool
};

FixedSizeList.defaultProps = {
    itemData: null,
    layout: 'vertical',
    paddingRight: null,
    useCSS3Translate: false
};

export default FixedSizeList;
