import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* actions */
import { toggleSidebar } from '../kpi-actions';
import { getData, resetAll } from './vessel-actions';
/* selectors */
import { getVesselsById } from '../kpi-selectors';
import {
    getAuxiliaryUtilizationChartData,
    getAverageSpeedChartData,
    getBoilerUtilizationChartData,
    getKeyAverageFigures,
    getKeyTotalFigures,
    getRPMAnalysisChartData,
    getTotalData
} from './vessel-selectors';
/* components */
import Sidebar from '../shared/sidebar/sidebar';
import IconButton from 'components/icon-button/icon-button';
import ScrollArea from 'components/scroll-area/scroll-area';
import InfoGraphSection from '../shared/info-graph-section/info-graph-section';
import InfoPanel from '../shared/info-panel/info-panel';
import GraphPanel from '../shared/graph-panel/graph-panel';
import EmptyContent from 'components/empty-content/empty-content';
/* config */
import getChartConfigs from './vessel-chart-config';

const t = (key) => translate(`ENERGY_MANAGEMENT.KPI.${key}`);

class EnergyManagementKPIVessel extends React.PureComponent {
    componentDidMount() {
        if (this.props.filters.selectedRangeOption) {
            this.props.getData(this.props.router.params.vesselId, this.props.filters);
        }
    }

    componentDidUpdate(prevProps) {
        const { params: prevParams } = prevProps.router;
        const { params } = this.props.router;
        if ((prevParams.vesselId !== params.vesselId || this.props.filters !== prevProps.filters)
            && this.props.filters.selectedRangeOption) {
            this.props.getData(params.vesselId, this.props.filters);
        }
    }

    componentWillUnmount() {
        this.props.resetAll();
    }

    getData = (filters) => {
        this.props.getData(this.props.router.params.vesselId, filters);
    };

    getBackLink = () => {
        const vesselIndex = this.props.router.location.pathname.indexOf('/vessel');
        return {
            pathname: this.props.router.location.pathname.substring(0, vesselIndex),
            search: this.props.router.location.search
        };
    };

    render() {
        const { totalData, filters, router } = this.props;
        let infoPanelTableHeader = [t('PARAMETERS'), t('1-12_MONTHS'), t('13-24_MONTHS')];
        if (filters.selectedRangeOption && filters.selectedRangeOption.Id === 2) {
            const rangeEndYear = filters.selectedRangeEnd ? filters.selectedRangeEnd.year : 0;
            infoPanelTableHeader = [t('PARAMETERS'), rangeEndYear, rangeEndYear - 1];
        }

        const chartConfigs = getChartConfigs();

        return (
            <div className="sten-energy-management-kpi sten-energy-management-kpi-vessel">
                <Sidebar vesselId={router.params.vesselId} onSubmit={this.getData} />
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <div className="flex-shrink">
                            <Link className="btn-link icon icon-arrow-left" to={this.getBackLink()} />
                        </div>
                        <div className="flex-grow">
                            <h1 className="text-uppercase">
                                {this.props.vesselsById && this.props.vesselsById[router.params.vesselId]
                                && this.props.vesselsById[router.params.vesselId].Title}
                            </h1>
                        </div>
                        <div className="flex-shrink">
                            <IconButton
                                isLink
                                changeIndicator={this.props.isSidebarVisible}
                                icon="icon-filter-outline"
                                isActive={this.props.isSidebarVisible}
                                onClick={this.props.toggleSidebar}
                            />
                        </div>
                    </div>
                    {this.props.totalData && this.props.totalData.consumptionChartData
                        ? (
                            <ScrollArea className="sten-content__body">
                                <InfoGraphSection
                                    ballastConsumption={{
                                        total: totalData?.data?.BallastKPIRatio,
                                        year1: totalData?.data?.BallastKPI,
                                        year2: totalData?.data?.PreviousBallastKPI
                                    }}
                                    ladenConsumption={{
                                        total: totalData?.data?.LadenKPIRatio,
                                        year1: totalData?.data?.LadenKPI,
                                        year2: totalData?.data?.PreviousLadenKPI
                                    }}
                                    averageCargo={{
                                        total: totalData?.data?.WeightedCargoRatio,
                                        year1: totalData?.data?.WeightedCargoLaden,
                                        year2: totalData?.data?.PreviousWeightedCargoLaden
                                    }}
                                    energyEfficiency={{
                                        total: totalData?.data?.EnergyEfficiencyRatio,
                                        year1: totalData?.data?.EnergyEfficiency,
                                        year2: totalData?.data?.PreviousEnergyEfficiency
                                    }}
                                    aer={{
                                        total: totalData?.data?.AERRatio,
                                        year1: totalData?.data?.AER,
                                        year2: totalData?.data?.PreviousAER
                                    }}
                                    consumptionChartData={totalData.consumptionChartData}
                                    energyEfficiencyChartData={totalData.energyEfficiencyChartData}
                                    rangeOption={this.props.filters.selectedRangeOption}
                                    rangeEndDate={this.props.filters.selectedRangeEnd}
                                />
                                <div className="sten-content__section">
                                    <div className="flex-row--md row--fluid">
                                        <div className="col-md-12">
                                            <InfoPanel
                                                tableHeader={infoPanelTableHeader}
                                                title="Key total figures"
                                                sections={this.props.keyTotalFigures}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <InfoPanel
                                                tableHeader={infoPanelTableHeader}
                                                title="Key average figures"
                                                sections={this.props.keyAverageFigures}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="sten-content__section">
                                    <div className="row row--fluid">
                                        <div className="col-md-12">
                                            <GraphPanel
                                                chartConfig={chartConfigs.averageSpeed}
                                                title="Average Speed"
                                                chartData={this.props.averageSpeedChartData}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <GraphPanel
                                                chartConfig={chartConfigs.ballastRPMAnalysis}
                                                title="Ballast RPM Analysis"
                                                chartData={this.props.rpmAnalysisChartData.ballast}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <GraphPanel
                                                chartConfig={chartConfigs.ladenRPMAnalysis}
                                                title="Laden RPM Analysis"
                                                chartData={this.props.rpmAnalysisChartData.laden}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <GraphPanel
                                                chartConfig={chartConfigs.boilerUtilization}
                                                title="Boiler Utilization"
                                                chartData={this.props.boilerUtilizationChartData}
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <GraphPanel
                                                chartConfig={chartConfigs.auxiliaryUtilization}
                                                title="Auxiliary Utilization"
                                                chartData={this.props.auxiliaryUtilizationChartData}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </ScrollArea>
                        )
                        : <EmptyContent />
                    }
                </div>
            </div>
        );
    }
}

EnergyManagementKPIVessel.propTypes = {
    auxiliaryUtilizationChartData: PropTypes.objectOf(PropTypes.any),
    averageSpeedChartData: PropTypes.objectOf(PropTypes.any),
    boilerUtilizationChartData: PropTypes.objectOf(PropTypes.any),
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    getData: PropTypes.func.isRequired,
    isSidebarVisible: PropTypes.bool.isRequired,
    keyAverageFigures: PropTypes.arrayOf(PropTypes.object).isRequired,
    keyTotalFigures: PropTypes.arrayOf(PropTypes.object).isRequired,
    resetAll: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    rpmAnalysisChartData: PropTypes.objectOf(PropTypes.any).isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    totalData: PropTypes.objectOf(PropTypes.any).isRequired,
    vesselsById: PropTypes.objectOf(PropTypes.any).isRequired
};

EnergyManagementKPIVessel.defaultProps = {
    auxiliaryUtilizationChartData: null,
    averageSpeedChartData: null,
    boilerUtilizationChartData: null
};

function mapStateToProps(state) {
    return {
        auxiliaryUtilizationChartData: getAuxiliaryUtilizationChartData(state),
        averageSpeedChartData: getAverageSpeedChartData(state),
        boilerUtilizationChartData: getBoilerUtilizationChartData(state),
        filters: state.energyManagementKPIReducer.filters,
        isSidebarVisible: state.energyManagementKPIReducer.isSidebarVisible,
        keyAverageFigures: getKeyAverageFigures(state),
        keyTotalFigures: getKeyTotalFigures(state),
        rpmAnalysisChartData: getRPMAnalysisChartData(state),
        totalData: getTotalData(state),
        vesselsById: getVesselsById(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getData: (imo, filters) => getData(dispatch, imo, filters),
        resetAll: () => resetAll(dispatch),
        toggleSidebar: () => toggleSidebar(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EnergyManagementKPIVessel));
