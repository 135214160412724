import Excel from 'exceljs';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import ReportHelper from 'utils/helpers/report-helper';
import TimeHelper from 'utils/helpers/time-helper';
import {
    tableHeaderStyle,
    excludedReportStyle,
    fuelFieldStyle,
    tableCellStyle,
    headingTitleStyle,
    excludedFieldStyle
} from 'utils/helpers/xlsx-styles';
import { getObjectProp, leastCommonMultiple } from 'utils/helpers/info-helper';
import { addWorksheet, triggerXLSXDownload, formatXLSXCellValue } from 'utils/helpers/xlsx-helper';
import { generateXLSXHeaderLogos } from '../../../reports-helpers';
/* constants */
import {
    ballastSummaryRows,
    ladenSummaryRows,
    getFootprintRows
} from '../voyage-report-constants';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.${key}`);

let sofReportCargoOpsRowSpan = 0;
const calculateCargoOperationsRowSpan = (reports) => {
    let rowSpan = 0;
    if (reports) {
        reports.forEach(report => {
            if (report.CargoOperations && report.CargoOperations.length + 1 > rowSpan) {
                rowSpan = report.CargoOperations.length + 1;
            }
        });
    }
    return rowSpan;
};

const formatTableCell = (val, props = {}, style = null, colSpan = 1) => {
    const { value, numFmt } = formatXLSXCellValue(val, props);
    return {
        value,
        props: { ...tableCellStyle, ...style, numFmt },
        colSpan
    };
};

function formatSummaryTableCell(summaryReport, rowConfig, colSpan, style) {
    const exclusionFields = summaryReport.ExlusionFields;
    const value = rowConfig.renderXlsxValue
        ? rowConfig.renderXlsxValue(summaryReport)
        : getObjectProp(summaryReport, rowConfig.key);
    return exclusionFields && exclusionFields[rowConfig.key]
        ? formatTableCell(exclusionFields[rowConfig.key].join('; '), undefined, style, colSpan)
        : formatTableCell(value, rowConfig, style, colSpan);
}

const generateSummaryTableRow = (data, rowConfig, colSpans, colIndex) => {
    let row = [{ value: rowConfig.label, props: tableHeaderStyle, colSpan: colSpans[colIndex] }];
    if (colSpans[colIndex] > 1) {
        row = [...row, ...new Array(colSpans[colIndex] - 1).fill(null)];
    }
    let quantity;
    let colSpan;
    if (rowConfig.type === 'fuelTypes') {
        colSpan = data.FuelTypes.length ? colSpans[colIndex + 1] / data.FuelTypes.length : colSpans[colIndex + 1];

        if (data.FuelTypes.length !== 0) {
            data.FuelTypes.forEach(fuelType => {
                row.push(formatTableCell(fuelType.Name, rowConfig, fuelFieldStyle, colSpan));
                if (colSpan > 1) {
                    row = [...row, ...new Array(colSpan - 1).fill(null)];
                }
            });
        } else {
            row.push(formatTableCell('-', rowConfig, null, colSpan));
            if (colSpan > 1) {
                row = [...row, ...new Array(colSpan - 1).fill(null)];
            }
        }
    } else if (rowConfig.type === 'fuelTypeQuantities') {
        colSpan = data.FuelTypes.length ? colSpans[colIndex + 1] / data.FuelTypes.length : colSpans[colIndex + 1];

        if (data.FuelTypes.length !== 0) {
            const item = getObjectProp(data, rowConfig.key);
            data.FuelTypes.forEach(fuelType => {
                quantity = item[fuelType.Id] ? item[fuelType.Id].Quantity : '-';
                row.push(formatTableCell(quantity, rowConfig, null, colSpan));
                if (colSpan > 1) {
                    row = [...row, ...new Array(colSpan - 1).fill(null)];
                }
            });
        } else {
            row.push(formatTableCell('-', rowConfig, null, colSpan));
            if (colSpan > 1) {
                row = [...row, ...new Array(colSpan - 1).fill(null)];
            }
        }
    } else {
        row = [
            ...row,
            formatSummaryTableCell(data, rowConfig, colSpans[colIndex + 1]),
            ...new Array(colSpans[colIndex + 1] - 1).fill(null)
        ];
    }
    return row;
};

const generateFootprintTableRows = (
    vesselCii,
    voyageVmsFootprint,
    data,
    colSpans,
    isRange
) => {
    const footprintTableData = {
        FuelTypes: data?.Footprint.FuelTypes,
        voyageFootprint: data?.Footprint,
        vesselCii,
        voyageVmsFootprint
    };
    const ftRows = getFootprintRows(isRange);
    const rows = ftRows.map(rowConfig => {
        if (rowConfig.type === 'header') {
            return {
                values: [{
                    value: rowConfig.label,
                    colSpan: colSpans[1] + 1,
                    props: tableHeaderStyle
                }, ...new Array(colSpans[1]).fill(null)]
            };
        }
        return { values: generateSummaryTableRow(footprintTableData, rowConfig, colSpans, 0) };
    });
    if (!rows.length) {
        return null;
    }

    return [{
        pageBreak: true
    }, {
        values: [{
            value: translate('VOYAGE_PERFORMANCE.FOOTPRINT.TITLE'),
            colSpan: colSpans[1] + 1,
            props: headingTitleStyle
        }]
    }, ...rows];
};

const generateSummaryTableRows = (summary, colSpans) => {
    if (!summary) {
        return [];
    }
    const length = Math.max(ballastSummaryRows.length, ladenSummaryRows.length);
    const rows = new Array(length + 1);
    rows[0] = {
        values: [
            { value: translate('GLOBAL.BALLAST'), colSpan: colSpans[1] + 1, props: headingTitleStyle },
            ...new Array(colSpans[1]).fill(null),
            { value: translate('GLOBAL.LADEN'), colSpan: colSpans[2] + colSpans[3], props: headingTitleStyle }
        ]
    };

    let br;
    let lr;
    for (let i = 1; i <= length; i++) {
        br = ballastSummaryRows[i - 1];
        lr = ladenSummaryRows[i - 1];
        rows[i] = { values: [] };
        if (br) {
            rows[i].values = generateSummaryTableRow(summary.BallastReport, br, colSpans, 0);
        } else {
            rows[i].values = new Array(colSpans[1] + 1).fill(null);
        }
        if (lr) {
            rows[i].values = [...rows[i].values, ...generateSummaryTableRow(summary.LadenReport, lr, colSpans, 2)];
        }
    }
    return rows;
};

const generateReportTableRows = (reports, rowConfigs, title, colSpans) => {
    if (!reports || reports.length === 0) {
        return [];
    }
    const rows = new Array(2);
    rows[0] = { pageBreak: true };
    rows[1] = { values: [{ value: title, colSpan: 2, props: headingTitleStyle }] };
    let style;
    let index = 2;
    let key;
    let cargoOpsHeaderRowIndex;
    let cargoOpsRowIndex;
    let isCargoOpsAdded = false;
    rowConfigs.forEach((rowConfig) => {
        if (!rowConfig.hiddenInExport) {
            rows.push({ values: [{ value: rowConfig.label, props: tableHeaderStyle }] });
            reports.forEach((report, reportIndex) => {
                key = getObjectProp(report, rowConfig.key);
                style = null;
                if (report.IsExcluded || report.IsMissing) {
                    style = { ...excludedReportStyle };
                    if (report.IsExcluded && report.ExclusionFields && report.ExclusionFields[rowConfig.key]) {
                        style = { ...style, ...excludedFieldStyle };
                    }
                }
                if (rowConfig.type === 'syncedWithVeracity') {
                    const { value } = ReportHelper.getSyncWithVeracityData(report);
                    rows[index].values = [
                        ...rows[index].values,
                        formatTableCell(value, rowConfig, style, colSpans[reportIndex + 1]),
                        ...new Array(colSpans[reportIndex + 1] - 1).fill(null)
                    ];
                } else if (rowConfig.type === 'fuelTypes') {
                    const colSpan = colSpans[reportIndex + 1] / report.FuelTypes.length;
                    style = { ...style, ...fuelFieldStyle };
                    report.FuelTypes.forEach(fuelType => {
                        rows[index].values.push(
                            formatTableCell(fuelType.Name, rowConfig, style, colSpan)
                        );
                        if (colSpan > 1) {
                            rows[index].values = [...rows[index].values, ...new Array(colSpan - 1).fill(null)];
                        }
                    });
                } else if (rowConfig.type === 'fuelTypeQuantities') {
                    const colSpan = colSpans[reportIndex + 1] / report.FuelTypes.length;
                    report.FuelTypes.forEach(fuelType => {
                        const quantity = key && key.FuelTypeQuantities[fuelType.Id]
                            ? key.FuelTypeQuantities[fuelType.Id].Quantity
                            : null;
                        rows[index].values.push(formatTableCell(quantity, rowConfig, style, colSpan));
                        if (colSpan > 1) {
                            rows[index].values = [...rows[index].values, ...new Array(colSpan - 1).fill(null)];
                        }
                    });
                } else if (rowConfig.type === 'utilizationLabels') {
                    style = { ...style, ...fuelFieldStyle };
                    const colSpan = colSpans[reportIndex + 1] / key.values.length;
                    key.values.forEach((value, valueIndex) => {
                        const label = `${rowConfig.utilizationLabel} ${valueIndex + 1}`;
                        rows[index].values.push(formatTableCell(label, rowConfig, style, colSpan));
                        if (colSpan > 1) {
                            rows[index].values = [...rows[index].values, ...new Array(colSpan - 1).fill(null)];
                        }
                    });
                } else if (rowConfig.type === 'utilizationValues') {
                    const colSpan = colSpans[reportIndex + 1] / key.length;
                    key.forEach((value) => {
                        rows[index].values.push(formatTableCell(value, rowConfig, style, colSpan));
                        if (colSpan > 1) {
                            rows[index].values = [...rows[index].values, ...new Array(colSpan - 1).fill(null)];
                        }
                    });
                } else if (rowConfig.type === 'other') {
                    if (rowConfig.xlsCellType === 'CargoOperations') {
                        cargoOpsHeaderRowIndex = index;
                        cargoOpsRowIndex = index;
                        style = { ...style, ...fuelFieldStyle };
                        const colSpan = (colSpans[reportIndex + 1] - 2) / 2;

                        rows[index].values.push(
                            formatTableCell('#', rowConfig, style, 2),
                            null,
                            formatTableCell(translate('REPORT_LABELS.COMMENCED_DATE'), rowConfig, style, colSpan),
                            ...new Array(colSpan - 1).fill(null),
                            formatTableCell(translate('REPORT_LABELS.COMPLETED_DATE'), rowConfig, style, colSpan),
                            ...new Array(colSpan - 1).fill(null)
                        );

                        style = null;
                        for (let i = 0; i < sofReportCargoOpsRowSpan - 1; i++) {
                            const operation = report.CargoOperations[i];
                            if (cargoOpsRowIndex - index < sofReportCargoOpsRowSpan) {
                                cargoOpsRowIndex++;
                                if (!rows[cargoOpsRowIndex]) {
                                    rows.push({ values: [{ value: null }] });
                                }
                            }

                            if (!operation) {
                                rows[cargoOpsRowIndex].values.push(
                                    formatTableCell(null, rowConfig, style, colSpans[reportIndex + 1]),
                                    ...new Array(colSpans[reportIndex + 1] - 1).fill(null)
                                );
                            } else {
                                const commencedDateValue = TimeHelper.getFormattedWithOffset(
                                    operation.CommencedDate, operation.CommencedDateOffset
                                );
                                const completedDateValue = TimeHelper.getFormattedWithOffset(
                                    operation.CompletedDate, operation.CompletedDateOffset
                                );
                                rows[cargoOpsRowIndex].values.push(
                                    formatTableCell(i + 1, rowConfig, { ...style, ...fuelFieldStyle }, 2),
                                    null,
                                    formatTableCell(commencedDateValue, rowConfig, style, colSpan),
                                    ...new Array(colSpan - 1).fill(null),
                                    formatTableCell(completedDateValue, rowConfig, style, colSpan),
                                    ...new Array(colSpan - 1).fill(null)
                                );
                            }
                        }
                        isCargoOpsAdded = true;
                    }
                } else {
                    if (rowConfig.getFormatted) {
                        key = rowConfig.getFormatted(report, false);
                    }
                    rows[index].values = [
                        ...rows[index].values,
                        formatTableCell(key, rowConfig, style, colSpans[reportIndex + 1]),
                        ...new Array(colSpans[reportIndex + 1] - 1).fill(null)
                    ];
                }
            });
            if (isCargoOpsAdded) {
                index += sofReportCargoOpsRowSpan;
                isCargoOpsAdded = false;
            } else {
                index++;
            }
        }
        if (cargoOpsHeaderRowIndex) {
            rows[cargoOpsHeaderRowIndex].values[0] = {
                value: rowConfig.label, props: tableHeaderStyle, rowSpan: sofReportCargoOpsRowSpan
            };
            cargoOpsHeaderRowIndex = null;
        }
    });
    return rows;
};

const calculateColSpansAndWidths = (data, columnWidth, minWidth = 160) => {
    const reportTypes = [
        'arrivalReports',
        'cargoReports',
        'dailyReports',
        'departureReports',
        'eventReports',
        'sofReports'
    ];
    let maxLength = reportTypes.reduce((res, type) => {
        if (data[type].length > res) {
            return data[type].length;
        }
        return res;
    }, 0);
    if (maxLength * columnWidth < minWidth) {
        maxLength = Math.ceil(minWidth / columnWidth);
    }
    // maxLength is the min number of columns needed

    const colSpans = [1];
    let colWidths = [{ width: columnWidth }];
    let colSpan;
    for (let i = 0; i < maxLength; i++) {
        colSpan = reportTypes.reduce((res, type) => {
            if (type === 'sofReports' && data[type][i]) {
                return leastCommonMultiple(res, 3);
            }
            if (type === 'dailyReports' && data[type][i] && data[type][i].FuelTypes) {
                const lcm = leastCommonMultiple(4, data[type][i].FuelTypes.length);
                return leastCommonMultiple(res, lcm);
            }
            if (data[type][i] && data[type][i].FuelTypes) {
                return leastCommonMultiple(res, data[type][i].FuelTypes.length);
            }
            return res;
        }, 1);
        if (i === 0) {
            colSpan = leastCommonMultiple(colSpan, data.summaryReport.BallastReport.FuelTypes.length);
        }
        if (i === 2) {
            colSpan = leastCommonMultiple(colSpan, data.summaryReport.LadenReport.FuelTypes.length);
        }
        colSpans.push(colSpan);
        colWidths = [...colWidths, ...new Array(colSpan).fill({ width: columnWidth / colSpan })];
    }
    return { colSpans, colWidths };
};

export default function* generateVoyageReportsXLSX(data) {
    const {
        vesselCii,
        voyageVmsFootprint,
        cargoReports,
        departureReports,
        arrivalReports,
        dailyReports,
        eventReports,
        sofReports,
        summaryReport,
        range,
        voyageNumber,
        vesselName,
        companyLogo,
        rowConfigs
    } = data;
    const workbook = new Excel.Workbook();

    const { colSpans, colWidths } = calculateColSpansAndWidths(data, 32);

    sofReportCargoOpsRowSpan = calculateCargoOperationsRowSpan(sofReports);

    const voyageRangeValue = voyageNumber
        ? `${t('HEADER.VOYAGE_NUMBER')} ${voyageNumber}`
        : `${t('HEADER.DATE_RANGE')} ${TimeHelper.getFormatted(range.rangeStart, { utc: true })} - `
            + `${TimeHelper.getFormatted(range.rangeEnd, { utc: true })}`;

    const sheetConfig = {
        name: 'Voyage report',
        props: {
            properties: { showGridLines: false },
            views: [{ showGridLines: false }],
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                margins: { left: 0.4, right: 0.4, top: 0.2, bottom: 0.2, header: 0, footer: 0 }
            }
        },
        columns: colWidths,
        rows: [
            ...generateXLSXHeaderLogos(companyLogo, colWidths),
            {
                values: [{
                    value: translate('ENERGY_MANAGEMENT.REPORTS.PERFORMANCE_SUMMARY'),
                    props: headingTitleStyle
                }]
            },
            null,
            {
                values: [{ value: vesselName, props: headingTitleStyle }]
            },
            {
                values: [{ value: voyageRangeValue }]
            },
            null,
            ...generateSummaryTableRows(summaryReport, colSpans),
            ...generateFootprintTableRows(
                vesselCii,
                voyageVmsFootprint,
                summaryReport,
                colSpans,
                !!range
            ),
            ...generateReportTableRows(dailyReports, rowConfigs.dailyReports, t('DAILY_REPORTS'), colSpans),
            ...generateReportTableRows(arrivalReports, rowConfigs.arrivalReports, t('ARRIVAL_REPORTS'), colSpans),
            ...generateReportTableRows(departureReports, rowConfigs.departureReports, t('DEPARTURE_REPORTS'), colSpans),
            ...generateReportTableRows(cargoReports, rowConfigs.cargoReports, t('CARGO_REPORTS'), colSpans),
            ...generateReportTableRows(sofReports, rowConfigs.sofReports, t('SOF_REPORTS'), colSpans),
            ...generateReportTableRows(eventReports, rowConfigs.eventReports, t('EVENT_REPORTS'), colSpans)
        ]
    };

    addWorksheet(workbook, sheetConfig);

    const xls64 = yield workbook.xlsx.writeBuffer({ base64: true });
    return triggerXLSXDownload(
        `${translate('ENERGY_MANAGEMENT.REPORTS.PERFORMANCE_SUMMARY')}_${vesselName}-${voyageNumber}`
    )(xls64);
}
