export default {
    Admin: {
        CompanyManagement: '/admin/company-management',
        UserManagement: '/admin/user-management',
        VesselManagement: '/admin/vessel-management'
    },
    EmissionEstimator: '/emission-estimator',
    EnergyManagement: {
        Root: '/energy-management',
        KPI: '/energy-management/kpi',
        Reports: '/energy-management/reports'
    },
    FleetDashboard: '/fleet-dashboard',
    Map: '/map',
    MarketIntel: '/market-intel',
    MarketOverview: {
        MarketIntel: '/market-overview/market-intel',
        PortCallsAnalytics: '/market-overview/port-calls-analytics'
    },
    PortManagement: '/port-management',
    Routing: {
        DistanceCalculator: '/routing/distance-calculator',
        WeatherRouting: '/routing/weather-routing'
    },
    Vessel: {
        FleetManagement: '/vessels/fleet-management',
        OperatorsDiary: '/vessels/operators-diary',
        PositionList: '/vessels/position-list',
        Inspections: '/vessels/inspections',
        VoyagePerformance: '/vessels/voyage-performance'
    }
};
