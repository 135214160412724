/* ol styles */
import Style from 'ol/style/Style';
import StyleStroke from 'ol/style/Stroke';
import StyleText from 'ol/style/Text';
import StyleFill from 'ol/style/Fill';
import StyleIcon from 'ol/style/Icon';
/* helpers */
import ColorHelper from 'utils/helpers/color-helper';
import { getOrExecProp } from 'utils/helpers/info-helper';
/* config */
import MapConfig from './configs/map';
/* elements */
import PointCanvas from './ol-map-elements/point';

const getZoomMultiplier = zoomLevel => (
    (zoomLevel + 1.4) / 4 < 1.2 ? (zoomLevel + 1.4) / 4 : 1.2
);

const getPointStyle = (feature, getZoom, updateOverlay) => {
    const zoom = getZoom();
    const relativeZoom = parseInt(zoom - MapConfig.minZoom, 10) + 1;
    const rotation = getOrExecProp(feature.data, feature.props.rotation, feature.isHovered, relativeZoom);
    const props = {
        type: getOrExecProp(feature.data, feature.props.type, feature.isHovered, relativeZoom),
        primaryColor: getOrExecProp(feature.data, feature.props.primaryColor, feature.isHovered, relativeZoom),
        secondaryColor: getOrExecProp(feature.data, feature.props.secondaryColor, feature.isHovered, relativeZoom),
        backgroundColor: getOrExecProp(feature.data, feature.props.backgroundColor, feature.isHovered, relativeZoom),
        opacity: getOrExecProp(feature.data, feature.props.opacity, feature.isHovered, relativeZoom),
        fill: getOrExecProp(feature.data, feature.props.fill, feature.isHovered, relativeZoom),
        label: getOrExecProp(feature.data, feature.props.label, feature.isHovered, relativeZoom),
        hideLabel: getOrExecProp(feature.data, feature.props.hideLabel, feature.isHovered, relativeZoom),
        hidden: getOrExecProp(feature.data, feature.props.hidden, feature.isHovered, relativeZoom),
        rotation: rotation !== null ? rotation : 0,
        textBold: getOrExecProp(feature.data, feature.props.textBold, feature.isHovered, relativeZoom),
        textStroke: getOrExecProp(feature.data, feature.props.textStroke, feature.isHovered, relativeZoom),
        textOnTop: getOrExecProp(feature.data, feature.props.textOnTop, feature.isHovered, relativeZoom),
        textOffset: getOrExecProp(feature.data, feature.props.textOffset, feature.isHovered, relativeZoom),
        textScale: getOrExecProp(feature.data, feature.props.textScale, feature.isHovered, relativeZoom),
        overlay: getOrExecProp(feature.data, feature.props.overlay, feature.isHovered, relativeZoom),
        overlayProps: getOrExecProp(feature.data, feature.props.overlayProps, feature.isHovered, relativeZoom),
        isActive: getOrExecProp(feature.data, feature.props.isActive, feature.isHovered, relativeZoom),
        size: getOrExecProp(feature.data, feature.props.size, feature.isHovered, relativeZoom),
        zIndex: getOrExecProp(feature.data, feature.props.zIndex, feature.isHovered, relativeZoom)
    };

    if (props.hidden) {
        return undefined;
    }

    if (props.overlay && props.overlayProps) {
        const overlayProps = {
            hidden: getOrExecProp(feature.data, props.overlayProps.hidden),
            id: getOrExecProp(feature.data, props.overlayProps.id),
            isActive: props.isActive,
            minZoom: getOrExecProp(feature.data, props.overlayProps.minZoom),
            offset: getOrExecProp(feature.data, props.overlayProps.offset),
            position: getOrExecProp(feature.data, feature.props.coordinates),
            positioning: getOrExecProp(feature.data, props.overlayProps.positioning)
        };
        updateOverlay(feature.get('layerName'), props.overlay, overlayProps);
    }

    const options = {
        type: props.type,
        primaryColor: props.primaryColor,
        secondaryColor: props.secondaryColor,
        backgroundColor: props.backgroundColor,
        opacity: props.opacity,
        fill: props.fill,
        isHovered: feature.props.isHovered,
        isActive: props.isActive
    };

    const fontSizeMultiplier = MapConfig.getFontSizeMultiplier();
    const sizeMultiplier = fontSizeMultiplier * getZoomMultiplier(relativeZoom) * (props.size || 1);

    let textStyle;
    if (props.label && !props.hideLabel) {
        const textColor = ColorHelper.rgba(props.primaryColor, props.opacity);
        const stroke = props.textStroke
            ? new StyleStroke({
                color: ColorHelper.rgba('#000', props.opacity),
                width: typeof props.textStroke === 'number' ? props.textStroke : 3
            })
            : undefined;
        const textOffset = typeof props.textOffset === 'number' ? props.textOffset : 20;
        textStyle = new StyleText({
            font: `${props.textBold ? 'bold ' : ''} 12px Roboto, Arial, Helvetica, sans-serif`,
            scale: fontSizeMultiplier * (props.textScale || 1),
            text: props.label,
            fill: new StyleFill({
                color: textColor
            }),
            stroke,
            offsetY: (props.textOnTop ? -1 : 1)
                * (((textOffset / 2) * fontSizeMultiplier) + ((textOffset / 2) * sizeMultiplier)),
            textAlign: 'center'
        });
    }

    let zIndex = props.zIndex || 0;
    if (props.isActive) {
        zIndex += 1;
    }
    if (feature.isHovered) {
        zIndex += 2;
    }

    return new Style({
        image: new StyleIcon({
            img: PointCanvas.get(options, sizeMultiplier),
            imgSize: [40 * sizeMultiplier, 40 * sizeMultiplier],
            rotation: (Math.PI * props.rotation) / 180
        }),
        text: textStyle,
        zIndex
    });
};

const getTextStyle = (feature, getZoom, updateOverlay) => {
    const zoom = getZoom();
    const relativeZoom = parseInt(zoom - MapConfig.minZoom, 10) + 1;
    const props = {
        color: getOrExecProp(feature.data, feature.props.color, feature.isHovered, relativeZoom),
        opacity: getOrExecProp(feature.data, feature.props.opacity, feature.isHovered, relativeZoom),
        text: getOrExecProp(feature.data, feature.props.text, feature.isHovered, relativeZoom),
        hidden: getOrExecProp(feature.data, feature.props.hidden, feature.isHovered, relativeZoom),
        bold: getOrExecProp(feature.data, feature.props.textBold, feature.isHovered, relativeZoom),
        stroke: getOrExecProp(feature.data, feature.props.stroke, feature.isHovered, relativeZoom),
        strokeProps: getOrExecProp(feature.data, feature.props.strokeProps, feature.isHovered, relativeZoom),
        overlay: getOrExecProp(feature.data, feature.props.overlay, feature.isHovered, relativeZoom),
        overlayProps: getOrExecProp(feature.data, feature.props.overlayProps, feature.isHovered, relativeZoom),
        size: getOrExecProp(feature.data, feature.props.size, feature.isHovered, relativeZoom)
    };

    if (props.hidden) {
        return undefined;
    }

    if (props.overlay && props.overlayProps) {
        const overlayProps = {
            hidden: getOrExecProp(feature.data, props.overlayProps.hidden),
            id: getOrExecProp(feature.data, props.overlayProps.id),
            isActive: props.isActive,
            minZoom: getOrExecProp(feature.data, props.overlayProps.minZoom),
            offset: getOrExecProp(feature.data, props.overlayProps.offset),
            position: getOrExecProp(feature.data, feature.props.coordinates),
            positioning: getOrExecProp(feature.data, props.overlayProps.positioning)
        };
        updateOverlay(feature.get('layerName'), props.overlay, overlayProps);
    }

    const fontSizeMultiplier = MapConfig.getFontSizeMultiplier();
    const textColor = ColorHelper.rgba(props.color, props.opacity);
    const textStyle = new StyleText({
        font: `${props.bold ? 'bold ' : ''} 12px Roboto, Arial, Helvetica, sans-serif`,
        scale: fontSizeMultiplier * (props.textScale || 1),
        text: props.text,
        fill: new StyleFill({
            color: textColor
        })
    });

    if (props.stroke && props.strokeProps) {
        const strokeProps = {
            color: getOrExecProp(feature.data, props.strokeProps.color, feature.isHovered, relativeZoom),
            width: getOrExecProp(feature.data, props.strokeProps.width, feature.isHovered, relativeZoom)
        };
        textStyle.setStroke(
            new StyleStroke({
                color: ColorHelper.rgba(strokeProps.color, props.opacity),
                width: strokeProps.width
            })
        );
    }

    return new Style({ text: textStyle });
};

const getIconStyle = (feature, getZoom, updateOverlay) => {
    const zoom = getZoom();
    const relativeZoom = parseInt(zoom - MapConfig.minZoom, 10) + 1;
    const rotation = getOrExecProp(feature.data, feature.props.rotation, feature.isHovered, relativeZoom, feature);
    const useSizeMultiplier = getOrExecProp(
        feature.data, feature.props.useSizeMultiplier, feature.isHovered, relativeZoom
    );
    const props = {
        img: getOrExecProp(feature.data, feature.props.img, feature.isHovered, relativeZoom),
        src: getOrExecProp(feature.data, feature.props.src, feature.isHovered, relativeZoom, feature),
        color: getOrExecProp(feature.data, feature.props.color, feature.isHovered, relativeZoom),
        opacity: getOrExecProp(feature.data, feature.props.opacity, feature.isHovered, relativeZoom),
        label: getOrExecProp(feature.data, feature.props.label, feature.isHovered, relativeZoom),
        hideLabel: getOrExecProp(feature.data, feature.props.hideLabel, feature.isHovered, relativeZoom),
        hidden: getOrExecProp(feature.data, feature.props.hidden, feature.isHovered, relativeZoom),
        rotation: rotation !== null ? rotation : 0,
        textBold: getOrExecProp(feature.data, feature.props.textBold, feature.isHovered, relativeZoom),
        textStroke: getOrExecProp(feature.data, feature.props.textStroke, feature.isHovered, relativeZoom),
        textOnTop: getOrExecProp(feature.data, feature.props.textOnTop, feature.isHovered, relativeZoom),
        textOffset: getOrExecProp(feature.data, feature.props.textOffset, feature.isHovered, relativeZoom),
        textScale: getOrExecProp(feature.data, feature.props.textScale, feature.isHovered, relativeZoom),
        overlay: getOrExecProp(feature.data, feature.props.overlay, feature.isHovered, relativeZoom),
        overlayProps: getOrExecProp(feature.data, feature.props.overlayProps, feature.isHovered, relativeZoom),
        isActive: getOrExecProp(feature.data, feature.props.isActive, feature.isHovered, relativeZoom),
        size: getOrExecProp(feature.data, feature.props.size, feature.isHovered, relativeZoom),
        imgSize: getOrExecProp(feature.data, feature.props.imgSize, feature.isHovered, relativeZoom),
        scale: getOrExecProp(feature.data, feature.props.scale, feature.isHovered, relativeZoom),
        useSizeMultiplier: useSizeMultiplier !== null ? useSizeMultiplier : true,
        zIndex: getOrExecProp(feature.data, feature.props.zIndex, feature.isHovered, relativeZoom)
    };

    if (props.hidden) {
        return undefined;
    }

    if (props.overlay && props.overlayProps) {
        const overlayProps = {
            hidden: getOrExecProp(feature.data, props.overlayProps.hidden),
            id: getOrExecProp(feature.data, props.overlayProps.id),
            isActive: props.isActive,
            minZoom: getOrExecProp(feature.data, props.overlayProps.minZoom),
            offset: getOrExecProp(feature.data, props.overlayProps.offset),
            position: getOrExecProp(feature.data, feature.props.coordinates),
            positioning: getOrExecProp(feature.data, props.overlayProps.positioning)
        };
        updateOverlay(feature.get('layerName'), props.overlay, overlayProps);
    }

    const fontSizeMultiplier = MapConfig.getFontSizeMultiplier();
    const sizeMultiplier = props.useSizeMultiplier ? fontSizeMultiplier * getZoomMultiplier(relativeZoom) : 1;

    let textStyle;
    if (props.label && !props.hideLabel) {
        const textColor = ColorHelper.rgba(props.color, props.opacity);
        const stroke = props.textStroke
            ? new StyleStroke({
                color: ColorHelper.rgba('#000', props.opacity),
                width: typeof props.textStroke === 'number' ? props.textStroke : 3
            })
            : undefined;
        const textOffset = typeof props.textOffset === 'number' ? props.textOffset : 20;
        textStyle = new StyleText({
            font: `${props.textBold ? 'bold ' : ''} 12px Roboto, Arial, Helvetica, sans-serif`,
            scale: fontSizeMultiplier * (props.textScale || 1),
            text: props.label,
            fill: new StyleFill({
                color: textColor
            }),
            stroke,
            offsetY: (props.textOnTop ? -1 : 1)
                * (((textOffset / 2) * fontSizeMultiplier) + ((textOffset / 2) * sizeMultiplier)),
            textAlign: 'center'
        });
    }

    let zIndex = props.zIndex || 0;
    if (props.isActive) {
        zIndex += 1;
    }
    if (feature.isHovered) {
        zIndex += 2;
    }

    const options = {
        color: props.color,
        opacity: props.opacity,
        scale: sizeMultiplier * (props.scale || 1),
        rotation: (Math.PI * props.rotation) / 180
    };

    if (props.img) {
        options.img = props.img;
    }
    if (props.imgSize) {
        options.imgSize = props.imgSize;
    }
    if (props.src) {
        options.src = props.src;
    }

    return new Style({
        image: options.src ? new StyleIcon(options) : null,
        text: textStyle,
        zIndex
    });
};

const getLineStyle = (lineFeature, getZoom) => {
    const zoom = getZoom();
    const relativeZoom = parseInt(zoom - MapConfig.minZoom, 10) + 1;
    const zoomMultiplier = getZoomMultiplier(relativeZoom);
    const fontSizeMultiplier = MapConfig.getFontSizeMultiplier();
    const multiplier = fontSizeMultiplier * zoomMultiplier;
    const lineProps = lineFeature.props;
    const line = lineFeature.data;
    const props = {
        color: getOrExecProp(line, lineProps.color),
        width: getOrExecProp(line, lineProps.width),
        lineDash: getOrExecProp(line, lineProps.lineDash),
        label: getOrExecProp(line, lineProps.label),
        labelColor: getOrExecProp(line, lineProps.labelColor),
        labelProps: getOrExecProp(line, lineProps.labelProps),
        lineDashMaxZoom: getOrExecProp(line, lineProps.lineDashMaxZoom),
        zoomColor: getOrExecProp(line, lineProps.zoomColor),
        zoomWidth: getOrExecProp(line, lineProps.zoomWidth)
    };

    let textStyle;
    if (props.label) {
        const textProps = {
            font: '14px Roboto, Arial, Helvetica, sans-serif',
            scale: fontSizeMultiplier,
            text: props.label,
            fill: new StyleFill({ color: props.labelColor || props.color }),
            stroke: new StyleStroke({ color: 'rgba(0,0,0)', width: 3 }),
            textBaseline: 'bottom',
            placement: 'line'
        };
        if (props.labelProps) {
            textStyle = new StyleText({ ...textProps, ...props.labelProps });
        } else {
            textStyle = new StyleText(textProps);
        }
    }
    let color = props.color;
    let width = props.width * multiplier;
    let lineDash = props.lineDash ? props.lineDash.map(value => value * multiplier) : undefined;
    if (props.lineDashMaxZoom && zoom > props.lineDashMaxZoom) {
        color = props.zoomColor ? props.zoomColor : props.color;
        width = props.zoomWidth ? props.zoomWidth * multiplier : width;
        lineDash = undefined;
    }
    return [
        new Style({
            stroke: new StyleStroke({
                color,
                width,
                lineDash
            }),
            text: textStyle
        }),
        new Style({
            stroke: new StyleStroke({
                color: ColorHelper.rgba(props.color, 0.006),
                width: props.width * multiplier * 5
            })
        })
    ];
};

const getAreaStyle = (areaFeature) => {
    const fontSizeMultiplier = MapConfig.getFontSizeMultiplier();
    const area = areaFeature.data;
    const areaProps = areaFeature.props;
    const props = {
        fillColor: getOrExecProp(area, areaProps.fillColor),
        lineColor: getOrExecProp(area, areaProps.lineColor),
        lineWidth: getOrExecProp(area, areaProps.lineWidth),
        lineDash: getOrExecProp(area, areaProps.lineDash),
        textColor: getOrExecProp(area, areaProps.textColor),
        label: getOrExecProp(area, areaProps.label)
    };

    let textStyle;
    if (props.label) {
        textStyle = new StyleText({
            text: props.label,
            font: '14px Roboto, Arial, Helvetica, sans-serif',
            fill: new StyleFill({
                color: props.textColor || props.lineColor
            }),
            scale: fontSizeMultiplier
        });
    }

    return new Style({
        fill: new StyleFill({
            color: props.fillColor || ColorHelper.rgba(props.lineColor, 0.2)
        }),
        stroke: new StyleStroke({
            color: props.lineColor,
            width: props.lineWidth
        }),
        text: textStyle
    });
};

const getGeoJsonStyle = (feature) => {
    const properties = feature.getProperties();
    const props = {
        fill: properties.props && properties.props.fill
            ? getOrExecProp(properties, properties.props.fill, feature.isHovered)
            : properties.fill,
        stroke: properties.props && properties.props.stroke
            ? getOrExecProp(properties, properties.props.stroke, feature.isHovered)
            : properties.stroke
    };
    return new Style({
        fill: props.fill ? new StyleFill(props.fill) : undefined,
        stroke: props.stroke ? new StyleStroke(props.stroke) : undefined
    });
};

export {
    getPointStyle,
    getTextStyle,
    getIconStyle,
    getLineStyle,
    getAreaStyle,
    getGeoJsonStyle
};
