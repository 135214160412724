/* actions */
import { ActionTypes } from './contact-list-table-actions';

const initialState = {
    contactList: [],
    limit: 20,
    offset: 0,
    sortProp: 'Name',
    sortOrder: 'ASC',
    contactListAllFetched: false,
    isFetchingData: true
};

export default function contactListTableReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.CONTACT_LIST_TABLE_SET_CONTACT_LIST: {
        let contactList;
        let offset = state.offset;
        if (action.overrideCurrent) {
            contactList = action.contacts;
            offset = 0;
        } else {
            contactList = state.contactList.slice().concat(action.contacts);
        }
        return {
            ...state,
            contactList,
            contactListAllFetched: action.contactListAllFetched,
            offset,
            isFetchingData: false
        };
    }
    case ActionTypes.CONTACT_LIST_TABLE_SET_SORT_PROP: {
        if (state.sortProp === action.sortProp) {
            let sortOrder;
            if (state.sortOrder === 'ASC') {
                sortOrder = 'DESC';
            } else {
                sortOrder = 'ASC';
            }
            return {
                ...state,
                sortOrder
            };
        }
        return {
            ...state,
            sortProp: action.sortProp,
            sortOrder: 'ASC'
        };
    }
    case ActionTypes.CONTACT_LIST_TABLE_SET_FETCHING_PROGRESS: {
        return {
            ...state,
            isFetchingData: action.isFetchingData
        };
    }
    case ActionTypes.CONTACT_LIST_TABLE_SET_OFFSET: {
        return {
            ...state,
            offset: action.offset
        };
    }
    default:
        return state;
    }
}
