/* utils */
import MockHelper from 'utils/helpers/mock-helper';
import TimeHelper from '../../utils/helpers/time-helper';

const fuelTypes = [
    { Id: 1, Name: 'Hfo', DisplayName: 'Hfo' },
    { Id: 2, Name: 'Mdo', DisplayName: 'Mdo' },
    { Id: 3, Name: 'Lng', DisplayName: 'Lng' }
];

const currentTypes = [
    { Id: 1, Name: 'ExcludeAllCurrents', DisplayName: 'Exclude all currents' },
    { Id: 2, Name: 'ExcludeAdverseCurrents', DisplayName: 'Exclude adverse currents' },
    { Id: 3, Name: 'FavourableCurrents', DisplayName: 'Favourable currents' }
];

const allowanceTypes = [
    { Id: 1, Name: 'Plus', DisplayName: 'Plus' },
    { Id: 2, Name: 'PlusMinus', DisplayName: 'Plus–minus' }
];

export const getMockedData = (voyageId) => {
    return new Promise((resolve) => {
        resolve(MockHelper.getRandomArrayOfItems(0, 5, 0.2, () => {
            const offset = TimeHelper.getUTCOffsetString(MockHelper.getRandomNumber(-12, 12) * 60, false);
            return {
                VoyageId: voyageId,
                Id: MockHelper.getUniqueId('charterpartyId'),
                DateEffective: `${MockHelper.getRandomDate().slice(0, -1)}${offset}`,
                Speed: MockHelper.getRandomNumber(24, 30) / 2,
                SpeedAllowance: MockHelper.getRandomNumber(0, 10) / 2,
                WindSpeed: MockHelper.getRandomNumber(2, 20),
                WaveHeight: MockHelper.getRandomNumber(0, 30, 1, 0, 0.2),
                CurrentType: MockHelper.getRandomArrayItem(currentTypes),
                AllowanceType: MockHelper.getRandomArrayItem(allowanceTypes, 0, 1),
                FuelRestrictions: fuelTypes.map(ft => ({
                    Id: MockHelper.getUniqueId('charterpartyFuelTypeId'),
                    FuelType: ft,
                    Allowance: MockHelper.getRandomNumber(0, 20, 0, 0.25),
                    Consumption: MockHelper.getRandomNumber(4, 6, 1, 0)
                }))
            };
        }));
    });
};
