import { createSelector } from 'reselect';
/* helpers */
import { getConvertedObject } from 'utils/helpers/info-helper';
/* prop types */
import inclusionPropTypes from './sidebar/inclusions/inclusion-prop-types';

const generateVesselOptions = vessels => {
    const options = {
        vesselsBySegment: {},
        vesselsByFleet: {},
        vesselsByCustomFleet: {}
    };
    vessels.forEach((vessel) => {
        if (!options.vesselsBySegment[vessel.VesselTypeId]) {
            options.vesselsBySegment[vessel.VesselTypeId] = [];
        }
        options.vesselsBySegment[vessel.VesselTypeId].push(vessel.Imo);
        vessel.CompanyFleetsIds.forEach((fleetId) => {
            if (!options.vesselsByFleet[fleetId]) {
                options.vesselsByFleet[fleetId] = [];
            }
            options.vesselsByFleet[fleetId].push(vessel.Imo);
        });
        vessel.CustomFleetsIds.forEach((customFleetId) => {
            if (!options.vesselsByCustomFleet[customFleetId]) {
                options.vesselsByCustomFleet[customFleetId] = [];
            }
            options.vesselsByCustomFleet[customFleetId].push(vessel.Imo);
        });
    });
    return options;
};


export const getVesselsByOptions = createSelector(
    state => state.energyManagementReportsReducer.vessels,
    generateVesselOptions
);

export const getVesselsForFleetListByOptions = createSelector(
    state => state.energyManagementReportsReducer.vesselsForFleetList,
    generateVesselOptions
);

export const getSelectedPresetCompanyId = createSelector(
    state => state.energyManagementReportsReducer.companies,
    state => state.energyManagementReportsReducer.selectedInclusionPreset,
    (companies, selectedPreset) => {
        if (companies.length > 0 && selectedPreset) {
            const foundCompany = companies.find((company) =>
                company.Name.toLowerCase() === selectedPreset.Name.toLowerCase());
            return foundCompany ? foundCompany.Id : null;
        }
        return null;
    }
);

export const getConvertedInclusions = createSelector(
    state => state.energyManagementReportsReducer.inclusions,
    (inclusions) => {
        return inclusions ? getConvertedObject(inclusions, inclusionPropTypes) : null;
    }
);
