import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* components */
import OperatorsDiaryFilters from './operators-diary-filters/operators-diary-filters';
import OperatorsDiaryTable from './operators-diary-table/operators-diary-table';
import IconButton from 'components/icon-button/icon-button';
import OperatorsDiaryRightSideBar from './operators-diary-right-side-bar/operators-diary-right-side-bar';
/* actions */
import {
    getDiary,
    getOptions,
    reset,
    resetDiary
} from './operators-diary-actions';
import { toggleActivePanel } from 'components/right-side-bar/right-side-bar-actions';
import { toggleUserSetting } from 'pages/user-pages/user-actions';
/* selectors */
import { getConvertedDiary } from './operators-diary-selectors';
/* styles */
import './operators-diary.scss';

export class OperatorsDiary extends React.PureComponent {
    state = {
        areFiltersVisible: true
    };

    componentDidMount() {
        this.props.getOptions();
    }

    componentWillUnmount() {
        this.props.reset();
    }

    handleFiltersToggle = () => {
        this.setState({ areFiltersVisible: !this.state.areFiltersVisible });
    };

    handleFiltersUpdate = (filters) => {
        this.props.getDiary(filters);
        if (this.operatorsDiaryTableRef && this.operatorsDiaryTableRef.tableRef) {
            this.operatorsDiaryTableRef.tableRef.scrollArea.scrollTop();
        }
    };

    saveTableRef = c => { this.operatorsDiaryTableRef = c; };

    render() {
        const selectedVoyageId = this.props.router.params.id
            ? Number(this.props.router.params.id)
            : null;
        return (
            <div className="sten-operators-diary">
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <div className="flex-grow">
                            <h1 className="sten-content__title">{t('OPERATORS_DIARY.TITLE')}</h1>
                        </div>
                        <div className="flex-shrink">
                            <IconButton
                                isLink
                                isActive={this.state.areFiltersVisible}
                                icon="icon-filter-outline"
                                onClick={this.handleFiltersToggle}
                            />
                        </div>
                    </div>
                    <div className="sten-content__body">
                        {this.state.areFiltersVisible && (
                            <div className="sten-operators-diary__content-top">
                                <OperatorsDiaryFilters
                                    fleets={this.props.fleets}
                                    timeFrameOptions={this.props.timeFrameOptions}
                                    onSubmit={this.handleFiltersUpdate}
                                    onReset={this.props.resetDiary}
                                />
                            </div>
                        )}
                        <div className="sten-operators-diary__content-bottom">
                            <OperatorsDiaryTable
                                diary={this.props.diary}
                                permissions={this.props.permissions}
                                setInnerRef={this.saveTableRef}
                                selectedVoyageId={selectedVoyageId}
                                toggleUserSetting={this.props.toggleUserSetting}
                                userSettings={this.props.userSettings}
                                userSettingUpdated={this.props.userSettingUpdated}
                            />
                        </div>
                    </div>
                </div>
                <OperatorsDiaryRightSideBar />
            </div>
        );
    }
}

OperatorsDiary.propTypes = {
    diary: PropTypes.arrayOf(PropTypes.object).isRequired,
    fleets: PropTypes.arrayOf(PropTypes.object).isRequired,
    getDiary: PropTypes.func.isRequired,
    getOptions: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    reset: PropTypes.func.isRequired,
    resetDiary: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    timeFrameOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    toggleActivePanel: PropTypes.func.isRequired,
    toggleUserSetting: PropTypes.func.isRequired,
    userSettings: PropTypes.objectOf(PropTypes.any),
    userSettingUpdated: PropTypes.objectOf(PropTypes.any).isRequired
};

OperatorsDiary.defaultProps = {
    userSettings: null
};

function mapStateToProps(state) {
    return {
        diary: getConvertedDiary(state),
        fleets: state.operatorsDiaryReducer.fleets,
        permissions: state.userReducer.permissions,
        timeFrameOptions: state.operatorsDiaryReducer.timeFrameOptions,
        userSettings: state.userReducer.settings,
        userSettingUpdated: state.userReducer.settingUpdated
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getDiary: (params) => getDiary(dispatch, params),
        getOptions: () => getOptions(dispatch),
        reset: () => reset(dispatch),
        resetDiary: () => resetDiary(dispatch),
        toggleActivePanel: (panelName, enable) => toggleActivePanel(dispatch, panelName, enable),
        toggleUserSetting: (settingName, value, settingUpdated) =>
            toggleUserSetting(dispatch, settingName, value, settingUpdated)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OperatorsDiary));
