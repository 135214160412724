import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
/* types */
import { TAisPosition } from './vessel-details-tooltip-types';
/* components */
import VesselDetailsTooltipContent from './vessel-details-tooltip-content';
/* config */
import MapConfig from 'components/ol/configs/map';
/* styles */
import './vessel-details-tooltip.scss';

export class VesselDetailsTooltip extends React.PureComponent {
    getPositionStyle = memoize((position) => {
        const zoomMultiplier = position.zoomLevel < 4.8 ? 0.4 + (position.zoomLevel / 2) : 2.75;
        const correctionTop = zoomMultiplier * 8 * MapConfig.getFontSizeMultiplier();
        if (position && position.pixels) {
            return {
                transform: `translate(${position.pixels[0]}px,`
                    + `${position.pixels[1] + correctionTop}px)`,
                willChange: 'transform'
            };
        }
        return {};
    });

    render() {
        if (!this.props.position || !this.props.selectedVesselPosition) {
            return null;
        }

        let classes = 'sten-vessel-details-tooltip';
        classes += this.props.position ? '' : ' sten-vessel-details-tooltip--hidden';

        return (
            <div className={classes} style={this.getPositionStyle(this.props.position)}>
                <VesselDetailsTooltipContent data={this.props.selectedVesselPosition} />
            </div>
        );
    }
}

VesselDetailsTooltip.propTypes = {
    position: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedVesselPosition: TAisPosition
};

VesselDetailsTooltip.defaultProps = {
    selectedVesselPosition: null
};

function mapStateToProps(state) {
    return {
        position: state.vesselDetailsTooltipReducer.position
    };
}

export default connect(mapStateToProps)(VesselDetailsTooltip);
