import React from 'react';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { formatNumber, formatWktPosition, getObjectProp, getClassName } from 'utils/helpers/info-helper';
import { joinArrayByProp } from 'utils/helpers/array-helper';
/* components */
import Accordion from 'components/accordion/accordion';
import CoordinatesInput from 'components/coordinates-input/coordinates-input';
import DateTimePopup from 'components/date-time-popup/date-time-popup';
import FlipSwitch from '../flip-switch/flip-switch';
import Input from 'components/input/input';
import PortSelect from 'components/port-select/port-select';
import Select from 'components/select/select';
import Textarea from 'components/textarea/textarea';
import TimeHelper from 'utils/helpers/time-helper';
import Validation from 'components/validation/validation';
import MultiStringSelect from 'components/multi-string-select/multi-string-select';

export const timeFormattingOptions = { time: true, utc: true };
const dateFormattingOptions = { time: false, utc: true };
const dateOffsetInputProps = { maxLength: 6 };

const renderRow = (fieldName, label, value, inline, valueInfo) => (inline
    ? (
        <div className="flex-row flex-center form-row form-row--inline" key={fieldName}>
            <div className="col-8">{label || ''}</div>
            <div className="col-16">
                {value}
                {valueInfo}
            </div>
        </div>
    ) : (
        <div className="form-row" key={fieldName}>
            {label || null}
            {value}
            {valueInfo}
        </div>
    )
);

export const renderFormFields = (
    item,
    fields,
    fieldNames,
    isEditing = false,
    handleChange,
    inline = false,
    options,
    nameSuffix,
    renderValueInfo
) => {
    let fieldConfig;
    return fieldNames.reduce((res, fieldName) => {
        if (typeof fields[fieldName] === 'function') {
            fieldConfig = fields[fieldName](nameSuffix);
        } else {
            fieldConfig = fields[fieldName];
        }
        if (fields[fieldName]) {
            if (!fieldConfig.condition || fieldConfig.condition(item)) {
                const valueInfo = renderValueInfo
                    ? renderValueInfo(item, fieldConfig, fieldName, handleChange, options, isEditing)
                    : null;
                if (isEditing && fieldConfig.edit) {
                    return [
                        ...res,
                        renderRow(
                            fieldName,
                            fieldConfig.label,
                            fieldConfig.edit(item, handleChange, options),
                            inline,
                            valueInfo
                        )
                    ];
                }
                if (!isEditing && fieldConfig.view) {
                    return [...res, renderRow(fieldName, fieldConfig.label, fieldConfig.view(item), inline, valueInfo)];
                }
            }
        }
        return res;
    }, []);
};

export const renderFields = ({
    data,
    fieldNames,
    fieldMap,
    isEditing = false,
    onChange,
    isInline = true,
    renderValueInfo,
    ...params
}) => {
    let fieldConfig;
    return fieldNames.reduce((res, fieldName) => {
        if (typeof fieldMap[fieldName] === 'function') {
            fieldConfig = fieldMap[fieldName](params.nameSuffix);
        } else {
            fieldConfig = fieldMap[fieldName];
        }
        if (fieldMap[fieldName]) {
            if (!fieldConfig.condition || fieldConfig.condition(data)) {
                const valueInfo = renderValueInfo
                    ? renderValueInfo(data, fieldConfig, fieldName, onChange, params, isEditing)
                    : null;
                if (isEditing && fieldConfig.edit) {
                    return [
                        ...res,
                        renderRow(
                            fieldName,
                            fieldConfig.label,
                            fieldConfig.edit(data, onChange, params),
                            isInline,
                            valueInfo
                        )
                    ];
                }
                if (!isEditing && fieldConfig.view) {
                    return [
                        ...res,
                        renderRow(fieldName, fieldConfig.label, fieldConfig.view(data), isInline, valueInfo)
                    ];
                }
            }
        }
        return res;
    }, []);
};

const renderTitle = (section, params) => {
    if (!section.title && !section.getTitle) {
        return null;
    }
    return (
        <h4 className="text-uppercase">
            {section.getTitle ? section.getTitle(params.data) : section.title}
        </h4>
    );
};

const renderSection = (section, sectionIndex, fieldMap, dataMap, params, isPanel = false) => {
    if (section.data) {
        return dataMap[section.data] ? dataMap[section.data](params, sectionIndex, section.dataFields) : null;
    }
    if (section.fields) {
        const className = getClassName(section.className, {
            'sten-content__section': !isPanel,
            'sten-panel__section': isPanel
        });
        return (
            <div key={sectionIndex} className={className}>
                {renderTitle(section, params)}
                {renderFields({
                    ...params,
                    fieldNames: section.fields,
                    isInline: section.inline,
                    fieldMap
                })}
            </div>
        );
    }
    return null;
};

const filterSections = (sections, params) => sections.filter(sn => !sn.condition || sn.condition(params));

export const renderFormContent = ({
    sections,
    fieldMap,
    dataMap,
    params,
    isPanel = false
}) => {
    return filterSections(sections, params).map((section, sectionIndex) => {
        if (section.type === 'accordion') {
            return (
                <Accordion
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={sectionIndex}
                    header={renderTitle(section, params)}
                    {...section.accordionProps}
                >
                    {section.sections
                        ? filterSections(section.sections, params).map((s, si) =>
                            renderSection(s, si, fieldMap, dataMap, params, isPanel))
                        : renderSection(section, sectionIndex, fieldMap, dataMap, params, isPanel)}
                </Accordion>
            );
        }
        return renderSection(section, sectionIndex, fieldMap, dataMap, params, isPanel);
    });
};


export const boolOptionsMap = {
    true: { value: true, label: t('GLOBAL.YES') },
    false: { value: false, label: t('GLOBAL.NO') }
};

export const boolOptions = Object.values(boolOptionsMap);

const formatFlipSwitchValue = (value, activeLabel = t('GLOBAL.YES'), inactiveLabel = t('GLOBAL.NO')) => {
    if (typeof value !== 'boolean') {
        return '-';
    }
    return value ? activeLabel : inactiveLabel;
};

export const renderLabel = (label) => {
    if (typeof label === 'function') {
        return label();
    }
    return label ? <label className="label">{label}</label> : null;
};

const handleDateValueChange = (prop, handleChange) => value =>
    handleChange(prop)(value && value.toISOString ? value.toISOString() : value);

export const getNameWithSuffix = (prop, params) => `${prop}${params && params.nameSuffix
    ? `.${params.nameSuffix}`
    : ''}`;

export const commonFields = {
    Custom: (label, view, edit) => ({
        label: renderLabel(label),
        view: <label className="label label--value">{view}</label>,
        edit
    }),
    MultiValueSelect: (label, prop, validations, formatValue) => ({
        label: renderLabel(label),
        view: (data) => {
            const values = getObjectProp(data, prop);
            const formatter = typeof formatValue === 'function';
            return values.length
                ? values.map(value => {
                    const title = formatter ? formatValue(value) : value;
                    return (
                        <label
                            key={value}
                            title={title}
                            className="label label--value"
                        >{title}
                        </label>
                    );
                })
                : <label className="label label--value">-</label>;
        },
        edit: (data, handleChange) => {
            const value = getObjectProp(data, prop) || '';
            return (
                <Validation.Wrapper validations={validations}>
                    <MultiStringSelect
                        name={prop}
                        value={value}
                        onChange={handleChange(prop)}
                    />
                </Validation.Wrapper>
            );
        }
    }),
    Boolean: (label, prop, disabled) => ({
        label: renderLabel(label),
        view: (data) => {
            let value = getObjectProp(data, prop);
            if (typeof value === 'boolean') {
                value = value ? t('GLOBAL.YES') : t('GLOBAL.NO');
            } else {
                value = '-';
            }
            return <label className="label label--value">{value}</label>;
        },
        edit: (data, handleChange, params) => {
            const value = getObjectProp(data, prop);
            return (
                <Select
                    clearable
                    name={getNameWithSuffix(prop, params)}
                    labelKey="label"
                    valueKey="value"
                    options={boolOptions}
                    onChange={handleChange(prop)}
                    value={value}
                    disabled={disabled}
                />
            );
        }
    }),
    FlipSwitch: (label, prop, validations, activeLabel = t('GLOBAL.YES'), inactiveLabel = t('GLOBAL.NO')) => ({
        label: renderLabel(label),
        view: (data) => (
            <label className="label label--value">
                {formatFlipSwitchValue(getObjectProp(data, prop), activeLabel, inactiveLabel)}
            </label>
        ),
        edit: (data, handleChange, params) => {
            const value = getObjectProp(data, prop);
            return (
                <Validation.Wrapper hintsOnHover validations={validations}>
                    <FlipSwitch
                        name={getNameWithSuffix(prop, params)}
                        activeLabel={activeLabel}
                        inactiveLabel={inactiveLabel}
                        value={value}
                        onChange={handleChange(prop)}
                    />
                </Validation.Wrapper>
            );
        }
    }),
    String: (label, prop, placeholder, validations, isFieldDisabled) => ({
        label: renderLabel(label),
        view: (data) => {
            const value = getObjectProp(data, prop) || '-';
            return <label className="label label--value">{value}</label>;
        },
        edit: (data, handleChange, params) => {
            const value = getObjectProp(data, prop) || '';
            const isDisabled = isFieldDisabled ? isFieldDisabled(params, prop) : false;
            return (
                <Validation.Wrapper hintsOnHover validations={validations} validate={!isDisabled}>
                    <Input
                        name={getNameWithSuffix(prop, params)}
                        value={value}
                        onChange={handleChange(prop)}
                        placeholder={placeholder}
                        disabled={isDisabled}
                    />
                </Validation.Wrapper>
            );
        }
    }),
    Number: (label, prop, decimals, validations, unit, prefix, isFieldDisabled) => ({
        label: renderLabel(label),
        view: (data) => {
            const value = getObjectProp(data, prop);
            return (
                <label className="label label--value">
                    {typeof value === 'number' ? `${formatNumber(value, decimals)}${unit ? ` ${unit}` : ''}` : '-'}
                </label>
            );
        },
        edit: (data, handleChange, params) => {
            const value = getObjectProp(data, prop);
            const isDisabled = isFieldDisabled ? isFieldDisabled(params, prop) : false;
            return (
                <Validation.Wrapper hintsOnHover validations={validations} validate={!isDisabled}>
                    <Input
                        prefix={prefix}
                        name={getNameWithSuffix(prop, params)}
                        value={typeof value === 'number' ? value.toString() : value}
                        onChange={handleChange(prop)}
                        suffix={unit}
                        disabled={isDisabled}
                    />
                </Validation.Wrapper>
            );
        }
    }),
    Textarea: (label, prop, validations) => ({
        label: renderLabel(label),
        view: (data) => {
            const value = getObjectProp(data, prop) || '-';
            return <label className="label label--value">{value}</label>;
        },
        edit: (data, handleChange, params) => {
            const value = getObjectProp(data, prop) || '';
            return (
                <Validation.Wrapper hintsOnHover validations={validations}>
                    <Textarea name={getNameWithSuffix(prop, params)} value={value} onChange={handleChange(prop)} />
                </Validation.Wrapper>
            );
        }
    }),
    Date: (label, prop, validations, isDisabled) => ({
        label: <label className="label">{label}</label>,
        view: (report) => {
            const value = getObjectProp(report, prop);
            const date = TimeHelper.getFormatted(value, dateFormattingOptions);
            return (
                <label className="label label--value">{value ? date : '-'}</label>
            );
        },
        edit: (data, handleChange, params) => {
            const disabled = isDisabled && isDisabled(data);
            return (
                <Validation.Wrapper
                    hintsOnHover
                    validate={!disabled}
                    validations={validations}
                >
                    <DateTimePopup
                        withTime={false}
                        disabled={disabled}
                        alignment="end"
                        alwaysShow6Weeks
                        autoPlacement
                        name={getNameWithSuffix(prop, params)}
                        isClearable
                        isUTC
                        dateFormat={TimeHelper.getFormat()}
                        onDateSelect={handleDateValueChange(prop, handleChange)}
                        value={getObjectProp(data, prop)}
                    />
                </Validation.Wrapper>
            );
        }
    }),
    DateTime: (label, prop, offsetProp, validations, offsetValidations, isFieldDisabled) => ({
        label: renderLabel(label),
        view: (data) => {
            const value = getObjectProp(data, prop);
            const offsetValue = getObjectProp(data, offsetProp);
            const date = TimeHelper.getFormatted(value, timeFormattingOptions);
            const dateOffset = offsetValue ? ` (UTC ${offsetValue})` : '';
            return (
                <label className="label label--value">{value ? `${date}${dateOffset}` : '-'}</label>
            );
        },
        edit: (data, handleChange, params) => {
            const isDisabled = isFieldDisabled ? isFieldDisabled(params, prop) : false;
            return (
                <div className="flex-row--md flex-center--md">
                    <div className="col-md-18 col-xs-24">
                        <Validation.Wrapper hintsOnHover validations={validations} validate={!isDisabled}>
                            <DateTimePopup
                                alignment="end"
                                alwaysShow6Weeks
                                autoPlacement
                                name={getNameWithSuffix(prop, params)}
                                isClearable
                                isUTC
                                dateFormat={TimeHelper.getFormat()}
                                onDateSelect={handleDateValueChange(prop, handleChange)}
                                value={getObjectProp(data, prop)}
                                disabled={isDisabled}
                            />
                        </Validation.Wrapper>
                    </div>
                    <div className="col-md-6 col-xs-8">
                        <Validation.Wrapper hintsOnHover validations={offsetValidations} validate={!isDisabled}>
                            <Input
                                name={getNameWithSuffix(offsetProp, params)}
                                value={getObjectProp(data, offsetProp)}
                                inputFieldProps={dateOffsetInputProps}
                                onChange={handleChange(offsetProp)}
                                placeholder="±HH:mm"
                            />
                        </Validation.Wrapper>
                    </div>
                </div>
            );
        }
    }),
    Select: (
        label,
        prop,
        optionsKey,
        validations,
        selectProps,
        isFieldDisabled
    ) => ({
        label: renderLabel(label),
        view: data => {
            const value = getObjectProp(data, prop);
            const labelKey = (selectProps && selectProps.labelKey) || 'Name';
            const formattedValue = selectProps && selectProps.multiple
                ? joinArrayByProp(value, labelKey, ', ')
                : value?.[labelKey];
            return <label className="label label--value">{formattedValue || '-'}</label>;
        },
        edit: (data, handleChange, params) => {
            const options = typeof optionsKey === 'function'
                ? optionsKey(params, data)
                : getObjectProp(params, optionsKey);
            const isDisabled = isFieldDisabled ? isFieldDisabled(params, prop) : false;
            return (
                <Validation.Wrapper hintsOnHover validations={validations} validate={!isDisabled}>
                    <Select
                        fixedListWidth
                        name={getNameWithSuffix(prop, params)}
                        value={getObjectProp(data, prop)}
                        options={options}
                        onChange={handleChange(prop)}
                        disabled={isDisabled}
                        {...selectProps}
                    />
                </Validation.Wrapper>
            );
        }
    }),
    Position: (label, prop, validations) => ({
        label: renderLabel(label),
        view: (data) => {
            const value = getObjectProp(data, prop) || null;
            return <label className="label label--value">{value ? formatWktPosition(value) : '-'}</label>;
        },
        edit: (data, handleChange, params) => {
            const value = getObjectProp(data, prop) || null;
            return (
                <Validation.Wrapper hintsOnHover validations={validations}>
                    <CoordinatesInput
                        value={value}
                        name={getNameWithSuffix('Position', params)}
                        onChange={handleChange('Position', 'Coordinates')}
                    />
                </Validation.Wrapper>
            );
        }
    }),
    Point: (label, prop, validations, isDisabled, portSelectProps) => ({
        label: renderLabel(label),
        view: report => {
            const value = getObjectProp(report, prop);
            return <label className="label label--value">{value ? value.Name : '-'}</label>;
        },
        edit: (data, handleChange, params) => {
            const value = getObjectProp(data, prop) || null;
            const disabled = isDisabled && isDisabled(data);
            return (
                <Validation.Wrapper hintsOnHover validate={!disabled} validations={validations}>
                    <PortSelect
                        disabled={disabled}
                        name={getNameWithSuffix(prop, params)}
                        fixedListWidth
                        clearable
                        value={value}
                        onChange={handleChange(prop)}
                        {...portSelectProps}
                    />
                </Validation.Wrapper>
            );
        }
    })
};
