/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

class ScrollBar extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isDragging: false,
            lastClientPosition: 0,
            containerStyle: null
        };

        if (props.type === 'vertical') {
            this.boundHandleMouseMove = this.handleMouseMoveForVertical.bind(this);
        } else {
            this.boundHandleMouseMove = this.handleMouseMoveForHorizontal.bind(this);
        }

        this.boundHandleMouseUp = this.handleMouseUp.bind(this);
    }

    componentDidMount() {
        if (this.props.ownerDocument) {
            this.props.ownerDocument.addEventListener('mousemove', this.boundHandleMouseMove);
            this.props.ownerDocument.addEventListener('mouseup', this.boundHandleMouseUp);
        }
    }

    static calculateFractionalPosition(realContentSize, containerSize, contentPosition) {
        const relativeSize = realContentSize - containerSize;

        return 1 - ((relativeSize - contentPosition) / relativeSize);
    }

    static getDerivedStateFromProps(props) {
        const containerSize = props.containerSize - props.offsetStart - props.offsetEnd;
        const realSize = props.realSize - props.offsetStart - props.offsetEnd;
        const fractionalPosition = ScrollBar.calculateFractionalPosition(
            realSize,
            containerSize,
            props.position
        );
        const proportionalToPageScrollSize = (containerSize * containerSize) / realSize;
        const scrollSize = proportionalToPageScrollSize < props.minScrollSize
            ? props.minScrollSize
            : proportionalToPageScrollSize;
        const scrollPosition = (containerSize - scrollSize) * fractionalPosition;
        const containerStyle = { ...props.containerStyle };
        if (props.type === 'horizontal') {
            containerStyle.left = props.offsetStart;
            containerStyle.right = props.offsetEnd;
        } else {
            containerStyle.top = props.offsetStart;
            containerStyle.bottom = props.offsetEnd;
        }
        return {
            scrollSize,
            position: Math.round(scrollPosition),
            containerStyle
        };
    }

    componentWillUnmount() {
        if (this.props.ownerDocument) {
            this.props.ownerDocument.removeEventListener('mousemove', this.boundHandleMouseMove);
            this.props.ownerDocument.removeEventListener('mouseup', this.boundHandleMouseUp);
        }
    }

    saveScrollbarContainerRef = ref => {
        this.scrollbarContainer = ref;
    };

    handleScrollBarContainerMouseDown = e => {
        if (!this.state.isDragging) {
            e.preventDefault();
            const multiplier = this.computeMultiplier();
            const clientPosition = this.isVertical() ? e.clientY : e.clientX;
            const { top, left } = this.scrollbarContainer.getBoundingClientRect();
            const clientScrollPosition = this.isVertical() ? top : left;
            const position = clientPosition - clientScrollPosition;
            const containerSize = this.props.containerSize - this.props.offsetStart - this.props.offsetEnd;
            const realSize = this.props.realSize - this.props.offsetStart - this.props.offsetEnd;
            const proportionalToPageScrollSize = (containerSize * containerSize) / realSize;

            this.setState({ isDragging: true, lastClientPosition: clientPosition });
            this.props.onPositionChange((position - (proportionalToPageScrollSize / 2)) / multiplier);
        }
    };

    handleMouseMoveForHorizontal(e) {
        if (this.state.isDragging) {
            const multiplier = this.computeMultiplier();
            e.preventDefault();
            const deltaX = this.state.lastClientPosition - e.clientX;
            this.setState({ lastClientPosition: e.clientX });
            this.props.onMove(0, deltaX / multiplier);
        }
    }

    handleMouseMoveForVertical(e) {
        if (this.state.isDragging) {
            const multiplier = this.computeMultiplier();
            e.preventDefault();
            const deltaY = this.state.lastClientPosition - e.clientY;
            this.setState({ lastClientPosition: e.clientY });
            this.props.onMove(deltaY / multiplier, 0);
        }
    }

    handleMouseDown = e => {
        if (!this.state.isDragging) {
            e.preventDefault();
            // e.stopPropagation();
            const lastClientPosition = this.isVertical() ? e.clientY : e.clientX;
            this.setState({ isDragging: true, lastClientPosition });
        }
    };

    handleMouseUp(e) {
        if (this.state.isDragging) {
            e.preventDefault();
            this.setState({ isDragging: false });
        }
    }

    createScrollStyles() {
        if (this.props.type === 'vertical') {
            return {
                willChange: 'transform, height',
                height: this.state.scrollSize,
                transform: `translateY(${this.state.position}px)`
            };
        }

        return {
            willChange: 'transform, width',
            width: this.state.scrollSize,
            transform: `translateX(${this.state.position}px)`
        };
    }

    computeMultiplier() {
        const containerSize = this.props.containerSize - this.props.offsetStart - this.props.offsetEnd;
        const realSize = this.props.realSize - this.props.offsetStart - this.props.offsetEnd;
        return containerSize / realSize;
    }

    isVertical() {
        return this.props.type === 'vertical';
    }

    render() {
        const { isDragging, type, scrollbarStyle } = this.props;
        const scrollStyles = this.createScrollStyles();
        let scrollbarClasses = 'scrollbar-container';
        if (isDragging) { scrollbarClasses += ' active'; }
        scrollbarClasses += `${type === 'horizontal' ? ' horizontal' : ' vertical'}`;

        return (
            <div
                className={scrollbarClasses}
                style={this.state.containerStyle}
                onMouseDown={this.handleScrollBarContainerMouseDown}
                ref={this.saveScrollbarContainerRef}
            >
                <div
                    className="scrollbar"
                    style={{ ...scrollbarStyle, ...scrollStyles }}
                    onMouseDown={this.handleMouseDown}
                />
            </div>
        );
    }
}

ScrollBar.propTypes = {
    containerSize: PropTypes.number.isRequired,
    containerStyle: PropTypes.object,
    isDragging: PropTypes.bool,
    minScrollSize: PropTypes.number.isRequired,
    offsetStart: PropTypes.number.isRequired,
    offsetEnd: PropTypes.number.isRequired,
    onMove: PropTypes.func.isRequired,
    onPositionChange: PropTypes.func.isRequired,
    ownerDocument: PropTypes.any.isRequired,
    position: PropTypes.number.isRequired,
    realSize: PropTypes.number.isRequired,
    scrollbarStyle: PropTypes.object,
    type: PropTypes.oneOf(['vertical', 'horizontal'])
};

ScrollBar.defaultProps = {
    containerStyle: null,
    isDragging: false,
    scrollbarStyle: null,
    type: 'vertical'
};
export default ScrollBar;
