export const ActionTypes = {
    VESSEL_LIST_TOGGLE: 'VESSEL_LIST_TOGGLE',
    VESSEL_LIST_GET_VESSELS: 'VESSEL_LIST_GET_VESSELS',
    VESSEL_LIST_ADD_VESSELS: 'VESSEL_LIST_ADD_VESSELS',
    VESSEL_LIST_RESET_VESSEL_LIST: 'VESSEL_LIST_RESET_VESSEL_LIST'
};

export function getVessels(dispatch, params, overrideCurrent) {
    dispatch({
        type: ActionTypes.VESSEL_LIST_GET_VESSELS,
        params,
        overrideCurrent
    });
}

export function resetVesselList(dispatch) {
    dispatch({
        type: ActionTypes.VESSEL_LIST_RESET_VESSEL_LIST
    });
}
