import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import getFontSize from 'utils/helpers/cached-font-size';
import TimeHelper from 'utils/helpers/time-helper';
import { getClassName } from 'utils/helpers/info-helper';
/* components */
import BadgeToggle from 'components/badge-toggle/badge-toggle';
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
import FlipSwitch from 'components/flip-switch/flip-switch';
import Modal from 'components/modal/modal';
import SendReminderModal from 'components/vessel-report/send-reminder-modal/send-reminder-modal';
import TableCell from './raw-reports-table-cell';
import TableRow from './raw-reports-table-row';
/* actions */
import { getReports, resetAll, setMounted, setVisibility } from './raw-reports-table-actions';
import { deleteReport } from 'components/vessel-report/vessel-report-actions';
import {
    toggleMissingReportReminderModal
} from 'components/vessel-report/send-reminder-modal/send-reminder-modal-actions';
/* services */
import ConfigService from 'services/config-api/config-service';
/* selectors */
import { getFilteredReports, getRowKeys } from './raw-reports-table-selectors';
import { getEditableReports } from '../vessel-report/vessel-report-selectors';
/* constants */
import { visibilityBadgeTypes, reportProps } from './raw-reports-table-constants';
/* style */
import './raw-reports-table.scss';

const cellDisplayThreshold = 0;

const isIE = () => {
    return !!navigator.userAgent.match(/Trident/g)
        || !!navigator.userAgent.match(/MSIE/g)
        || window.navigator.userAgent.indexOf('Edge') > -1;
};

const emptyArray = [];

const dateOptions = { utc: true };

export class RawReportsTable extends React.PureComponent {
    state = {
        deleteModalReportId: null,
        modalData: null,
        highlightedRowKey: '',
        selectedRowKeys: {},
        renderSliceStart: null,
        renderSliceEnd: null
    };

    rowClickHandlers = {};

    rowHoverHandlers = {};

    visibilityHandlers = {};

    componentDidMount() {
        this.getData();
        this.props.setMounted();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.type !== this.props.type
            || prevProps.voyage !== this.props.voyage
            || prevProps.vessel !== this.props.vessel
            || prevProps.inclusions !== this.props.inclusions
        ) {
            this.getData();
        }
        if (prevProps.reports !== this.props.reports) {
            this.fixedHeaderTable.scrollArea.scrollLeft();
            this.fixedHeaderTable.scrollArea.refresh();
        }
    }

    componentWillUnmount() {
        this.props.resetAll();
    }

    getData = () => {
        const { inclusions, type, vessel, voyage, range } = this.props;
        if (vessel && (voyage || range)) {
            this.props.getReports(
                {
                    range,
                    reportType: type,
                    vesselImo: vessel.Imo || vessel.IMO,
                    voyage,
                    inclusions
                }
            );
        }
    };

    getRowHoverHandler = (rowKey) => {
        if (!isIE() && !this.rowHoverHandlers[rowKey]) {
            this.rowHoverHandlers[rowKey] = () => {
                if (this.state.highlightedRowKey !== rowKey) {
                    this.setState({ highlightedRowKey: rowKey });
                }
            };
        }
        return this.rowHoverHandlers[rowKey];
    };

    getVisibilityHandler = (type) => {
        if (!this.visibilityHandlers[type]) {
            this.visibilityHandlers[type] = () => {
                this.props.setVisibility({
                    [type]: !this.props.visibility[type]
                });
            };
        }
        return this.visibilityHandlers[type];
    };

    renderBadges = (counts, visibility) => {
        const visibilityTypes = this.props.inclusions ? ['included', 'excluded', 'missing'] : ['available', 'missing'];
        const suspiciousOnlyLabel = t(
            'RAW_REPORTS_TABLE.SUSPICIOUS_ONLY',
            {
                count: counts?.suspiciousLimitReached ? t('GLOBAL.NOT_AVAILABLE') : (counts?.suspicious || 0)
            }
        );
        const suspiciousInactiveTitle = counts.suspiciousLimitReached
            ? t('RAW_REPORTS_TABLE.SUSPICIOUS_INACTIVE_LIMIT_TITLE')
            : t('RAW_REPORTS_TABLE.SUSPICIOUS_INACTIVE_TITLE');
        const veracitySyncErrorOnlyLabel = t(
            'RAW_REPORTS_TABLE.VERACITY_SYNC_ERROR_ONLY', { count: counts?.veracitySyncError }
        );
        return (
            <React.Fragment>
                {visibilityTypes.map(visibilityType => (
                    <div className="flex-shrink" key={visibilityType}>
                        <BadgeToggle
                            className={visibilityBadgeTypes[visibilityType].className}
                            onClick={this.getVisibilityHandler(visibilityBadgeTypes[visibilityType].key)}
                            label={`${counts?.[visibilityBadgeTypes[visibilityType].key]} ${visibilityType}`}
                            isActive={visibility[visibilityBadgeTypes[visibilityType].key]}
                            isDisabled={counts?.[visibilityBadgeTypes[visibilityType].key] === 0}
                        />
                    </div>
                ))}
                <div className="sten-content__vertical-separator" />
                <div className="flex-shrink">
                    <FlipSwitch
                        className="sten-flip-switch--xs"
                        onChange={this.getVisibilityHandler('suspicious')}
                        activeLabel={suspiciousOnlyLabel}
                        inactiveLabel={suspiciousOnlyLabel}
                        activeTitle={t('RAW_REPORTS_TABLE.SHOW_ALL_REPORTS_TITLE')}
                        inactiveTitle={suspiciousInactiveTitle}
                        value={visibility.suspicious}
                        disabled={visibility.veracitySyncError
                            || (!counts.suspiciousLimitReached && counts?.suspicious === 0)}
                    />
                </div>
                {ConfigService.featureToggles.showSyncVesselWithVeracityToggle && (
                    <div className="flex-shrink">
                        <FlipSwitch
                            className="sten-flip-switch--xs"
                            onChange={this.getVisibilityHandler('veracitySyncError')}
                            activeLabel={veracitySyncErrorOnlyLabel}
                            inactiveLabel={veracitySyncErrorOnlyLabel}
                            activeTitle={t('RAW_REPORTS_TABLE.SHOW_ALL_REPORTS_TITLE')}
                            inactiveTitle={t('RAW_REPORTS_TABLE.VERACITY_SYNC_ERROR_INACTIVE_TITLE')}
                            value={visibility.veracitySyncError}
                            disabled={visibility.suspicious || counts?.veracitySyncError === 0}
                        />
                    </div>
                )}
            </React.Fragment>
        );
    };

    getRowClickHandler = (isSelectable, rowKey) => {
        if (isSelectable && !this.rowClickHandlers[rowKey]) {
            this.rowClickHandlers[rowKey] = () => {
                this.setState({
                    selectedRowKeys: {
                        ...this.state.selectedRowKeys,
                        [rowKey]: !this.state.selectedRowKeys[rowKey]
                    }
                });
            };
        }
        return this.rowClickHandlers[rowKey];
    };

    toggleDeleteModal = (reportId) => {
        this.setState({ deleteModalReportId: reportId });
    };

    handleOpenVeracityModal = (modalData) => {
        this.setState({ modalData });
    }

    handleCloseVeracityModal = () => {
        this.setState({ modalData: null });
    }

    renderTableRow = (rowFormat, index) => {
        const isSelectable = rowFormat.selectable !== false;
        const isHighlighted = isSelectable && rowFormat.key === this.state.highlightedRowKey;
        const isSelected = isSelectable && this.state.selectedRowKeys[rowFormat.key] === true;
        const cellWidth = reportProps[this.props.type].cellWidth;
        if (!this.cellStyle) {
            this.cellStyle = {
                width: `${cellWidth}rem`,
                minWidth: `${cellWidth}rem`,
                maxWidth: `${cellWidth}rem`
            };
        }
        const rowClassName = getClassName({
            'sten-table__row--highlighted': isHighlighted || isSelected,
            'cursor-pointer': isSelectable
        });
        return (
            <tr
                key={rowFormat.key}
                className={rowClassName}
                onClick={this.getRowClickHandler(isSelectable, rowFormat.key)}
                onMouseOver={this.getRowHoverHandler(rowFormat.key)}
            >
                <TableCell
                    id={rowFormat.key}
                    className={rowFormat.isActionCell ? 'sten-raw-reports-table-row__cell--action' : ''}
                >
                    {rowFormat.label}
                </TableCell>
                <TableRow
                    isUpdateRow={index === 0}
                    permissions={this.props.permissions}
                    reports={this.props.reports}
                    renderSliceStart={this.state.renderSliceStart}
                    renderSliceEnd={this.state.renderSliceEnd}
                    deletedReports={this.props.deletedReports}
                    editableReports={this.props.editableReports}
                    updatedReports={this.props.updatedReports}
                    toggleReminderModal={this.props.toggleReminderModal}
                    toggleDeleteModal={this.toggleDeleteModal}
                    openModal={this.handleOpenVeracityModal}
                    rowKey={rowFormat.key}
                    rowFormat={rowFormat}
                    cellStyle={this.cellStyle}
                    cellWidth={cellWidth}
                />
            </tr>
        );
    };

    getScrollAreaStyles = memoize((numReports, cellWidth) => ({
        minWidth: `${(numReports * cellWidth) + 20}rem`, // 20rem is width of first column
        width: `${(numReports * cellWidth) + 20}rem`
    }));

    isUpdateScheduled = false;

    handleScroll = scroll => {
        if (!this.isUpdateScheduled) {
            this.isUpdateScheduled = true;
            window.requestAnimationFrame(() => {
                const cellWidth = reportProps[this.props.type].cellWidth * getFontSize();
                const newState = {
                    renderSliceStart: Math.floor(scroll.leftPosition / cellWidth) - cellDisplayThreshold,
                    renderSliceEnd:
                        Math.ceil((scroll.leftPosition + scroll.containerWidth) / cellWidth + cellDisplayThreshold)
                };
                if (newState.renderSliceStart < 0) {
                    newState.renderSliceStart = 0;
                }
                if (newState.renderSliceStart !== this.state.renderSliceStart
                    || newState.renderSliceEnd !== this.state.renderSliceEnd) {
                    this.setState(newState);
                }
                this.isUpdateScheduled = false;
            });
        }
    };

    handleReportDelete = () => {
        this.props.deleteReport(this.state.deleteModalReportId);
        this.toggleDeleteModal(null);
    };

    handleRecalculateClick = () => {
        this.getData(true, true);
        if (this.props.onRecalculateClick) {
            this.props.onRecalculateClick();
        }
    };

    closeDeleteModal = () => this.toggleDeleteModal(null);

    static headerRowSelector(content) {
        if (content) {
            const tBody = content.getElementsByTagName('tbody')[0];
            if (tBody) {
                const firstRow = tBody.getElementsByTagName('tr')[0];
                if (firstRow) {
                    return firstRow.children;
                }
            }
        }
        return null;
    }

    static headerSeparatorSelector(content) {
        if (content) {
            const tBody = content.getElementsByTagName('tbody')[0];
            if (tBody?.getElementsByTagName('tr')[0]) {
                return [tBody.getElementsByTagName('tr')[0]];
            }
        }
        return null;
    }

    mapHeaderRow = (children) => {
        if (this.headerRowChildren !== children.props.children.props.children[0]) {
            // this.headerRow = children.props.children;
            this.headerRowChildren = children.props.children.props.children[0];
            this.headerRowClone = React.cloneElement(
                children.props.children,
                { key: 'header-row' },
                [children.props.children.props.children[0]]
            );
        }
        return this.headerRowClone;
    };

    saveRef = c => { this.fixedHeaderTable = c; };

    renderSection = (data, translationKey) => {
        if (!data.length) {
            return null;
        }
        return (
            <div className="sten-content__section">
                {data.map((message, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <p key={index} className="sten-raw-reports-table__veracity-modal-text">
                        <span>{t(translationKey, { index: index + 1 })}</span>
                        {message}
                    </p>
                ))}
            </div>
        );
    }

    renderVeracityModal = () => {
        const { modalData } = this.state;
        if (!modalData) {
            return null;
        }

        return (
            <Modal.Default
                modalSize="sm"
                isOpen={!!modalData}
                onRequestClose={this.handleCloseVeracityModal}
            >
                <div className="sten-modal__header flex flex-center text-uppercase">
                    <div className="sten-modal__title flex-grow">
                        {modalData.title}
                        {modalData.subtitle && <h6 className="text-secondary">{modalData.subtitle}</h6>}
                    </div>
                    <div className="flex-shrink">
                        <button
                            className="btn-link icon icon-close"
                            onClick={this.handleCloseVeracityModal}
                        />
                    </div>
                </div>
                {modalData.notSentMessage && <div className="sten-content__section">{modalData.notSentMessage}</div>}
                {this.renderSection(modalData.errors, 'RAW_REPORTS_TABLE.VERACITY_MODAL.ERROR')}
                {modalData.errors.length > 0
                    && modalData.warnings.length > 0
                    && <div className="sten-content__separator" />}
                {this.renderSection(modalData.warnings, 'RAW_REPORTS_TABLE.VERACITY_MODAL.WARNING')}
            </Modal.Default>
        );
    }

    render() {
        const {
            counts,
            deletedReports,
            range,
            reports,
            rowKeys,
            type,
            updatedReports,
            vessel,
            voyage
        } = this.props;
        const titleText = `${reportProps[type].title} (${(counts?.total) || 0})`;
        const showFooter = deletedReports.length > 0 || updatedReports.length > 0;
        const className = getClassName('sten-content', this.props.className, {
            'sten-content--has-footer': showFooter
        });
        return (
            <div className={className}>
                <Modal.Delete
                    isOpen={!!this.state.deleteModalReportId}
                    onDelete={this.handleReportDelete}
                    onCancel={this.closeDeleteModal}
                >
                    {t('RAW_REPORTS_TABLE.CONFIRM_DELETE')}
                </Modal.Delete>
                {this.renderVeracityModal()}
                <SendReminderModal />
                <div className="sten-content__header flex-row">
                    <div className="flex-shrink">
                        <button className="btn-link icon icon-arrow-left" onClick={this.props.onBackClick} />
                    </div>
                    <div className="flex-grow">
                        <h1>{titleText}</h1>
                        {vessel && voyage && (
                            <h6 className="text-secondary">
                                {`${vessel.Title}, ${voyage.VoyageNumber} ${voyage.VoyagePortInfo}`}
                            </h6>
                        )}
                        {vessel && range && (
                            <h6 className="text-secondary">
                                {`${vessel.Title}, ${TimeHelper.getFormatted(range.rangeStart, dateOptions)} - `
                                    + `${TimeHelper.getFormatted(range.rangeEnd, dateOptions)}`}
                            </h6>
                        )}
                    </div>
                    {this.renderBadges(counts, this.props.visibility)}
                </div>
                <div className="sten-content__body">
                    <FixedHeaderTable
                        className="sten-raw-reports-table"
                        ref={this.saveRef}
                        onScroll={this.handleScroll}
                        customFirstChild={TableCell}
                        contentStyle={this.getScrollAreaStyles(reports.length, reportProps[type].cellWidth)}
                        headerRowSelector={RawReportsTable.headerRowSelector}
                        headerSeparatorSelector={RawReportsTable.headerSeparatorSelector}
                        stopScrollPropagation
                        mapHeaderRow={this.mapHeaderRow}
                        withHeaderColumn
                    >
                        <table className="sten-table sten-table--xs">
                            <tbody>{rowKeys.map(this.renderTableRow)}</tbody>
                        </table>
                    </FixedHeaderTable>
                </div>
                {showFooter && (
                    <footer className="sten-content__footer flex-row">
                        <p className="flex-grow">{t('RAW_REPORTS_TABLE.REPORTS_UPDATED_TEXT')}</p>
                        <div className="flex-shrink">
                            <button className="btn btn--primary" onClick={this.handleRecalculateClick}>
                                {t('RAW_REPORTS_TABLE.RECALCULATE')}
                            </button>
                        </div>
                    </footer>
                )}
            </div>
        );
    }
}

RawReportsTable.propTypes = {
    className: PropTypes.string,
    counts: PropTypes.shape({
        total: PropTypes.number,
        included: PropTypes.number,
        excluded: PropTypes.number,
        missing: PropTypes.number,
        suspicious: PropTypes.number,
        suspiciousLimitReached: PropTypes.bool,
        veracitySyncError: PropTypes.number
    }),
    deletedReports: PropTypes.arrayOf(PropTypes.any),
    deleteReport: PropTypes.func.isRequired,
    editableReports: PropTypes.arrayOf(PropTypes.object).isRequired,
    getReports: PropTypes.func.isRequired,
    inclusions: PropTypes.objectOf(PropTypes.any),
    onBackClick: PropTypes.func.isRequired,
    onRecalculateClick: PropTypes.func,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    range: PropTypes.shape({
        rangeStart: PropTypes.any,
        rangeEnd: PropTypes.any
    }),
    reports: PropTypes.arrayOf(PropTypes.object).isRequired,
    resetAll: PropTypes.func.isRequired,
    rowKeys: PropTypes.arrayOf(PropTypes.object).isRequired,
    setMounted: PropTypes.func.isRequired,
    setVisibility: PropTypes.func.isRequired,
    toggleReminderModal: PropTypes.func.isRequired,
    type: PropTypes.oneOf(['daily', 'arrival', 'departure', 'cargo', 'sof', 'event']).isRequired,
    updatedReports: PropTypes.arrayOf(PropTypes.any),
    vessel: PropTypes.objectOf(PropTypes.any),
    visibility: PropTypes.shape({
        included: PropTypes.bool,
        excluded: PropTypes.bool,
        missing: PropTypes.bool,
        suspicious: PropTypes.bool,
        veracitySyncError: PropTypes.bool
    }).isRequired,
    voyage: PropTypes.objectOf(PropTypes.any)
};

RawReportsTable.defaultProps = {
    className: '',
    counts: null,
    deletedReports: emptyArray,
    inclusions: null,
    onRecalculateClick: undefined,
    range: null,
    updatedReports: emptyArray,
    vessel: null,
    voyage: null
};

function mapStateToProps(state, ownProps) {
    return {
        deletedReports: state.rawReportsTableReducer.deletedReports,
        editableReports: getEditableReports(state),
        permissions: state.userReducer.permissions,
        reports: getFilteredReports(state),
        rowKeys: getRowKeys(state, ownProps),
        updatedReports: state.rawReportsTableReducer.updatedReports,
        visibility: state.rawReportsTableReducer.visibility
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deleteReport: (reportId) => deleteReport(dispatch, reportId),
        getReports: (params) => getReports(dispatch, params),
        resetAll: () => resetAll(dispatch),
        setMounted: () => setMounted(dispatch),
        setVisibility: (visibility) => setVisibility(dispatch, visibility),
        toggleReminderModal: missingReport => toggleMissingReportReminderModal(dispatch, missingReport)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RawReportsTable);
