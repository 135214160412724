import { ActionTypes } from './counter-pvar-actions';

const initialState = {
    counterPvarList: null,
    selectedVessel: null,
    selectedVesselVoyages: null,
    selectedVoyage: null,
    validationData: null
};

export default function counterPvarReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.COUNTER_PVAR_SET_COUNTER_PVAR_LIST: {
        return {
            ...state,
            counterPvarList: action.counterPvarList
        };
    }
    case ActionTypes.COUNTER_PVAR_SET_VALIDATION_DATA: {
        return {
            ...state,
            validationData: action.validationData
        };
    }
    case ActionTypes.COUNTER_PVAR_SET_SELECTED_VESSEL: {
        return {
            ...state,
            selectedVessel: action.selectedVessel
        };
    }
    case ActionTypes.COUNTER_PVAR_SET_SELECTED_VOYAGE: {
        return {
            ...state,
            selectedVoyage: action.selectedVoyage,
            selectedVesselVoyages: action.selectedVesselVoyages
        };
    }
    default:
        return state;
    }
}
