import { createSelector } from 'reselect';
import { getCalculationPoints } from './estimator/estimator-selectors';
import { splitRoute180, generateMiddlePoints, mapLonLat } from 'components/ol/ol-helpers';
import RouteHelper from 'utils/helpers/route-helper';

const emptyArray = [];

function getCombinedPorts(itineraryPorts, ports) {
    const combinedPorts = [];
    if (itineraryPorts.length > 0) {
        itineraryPorts.forEach((itineraryPort) => {
            if (itineraryPort.Point && itineraryPort.Point.Type !== 3 && itineraryPort.Point.Location) {
                const existingPort = combinedPorts.find(el => el.Id === itineraryPort.Point.Id);
                if (existingPort) {
                    if (itineraryPort.Activities) {
                        if (existingPort.Activities) {
                            existingPort.Activities = existingPort.Activities.concat(itineraryPort.Activities);
                        } else {
                            existingPort.Activities = itineraryPort.Activities;
                        }
                    }
                } else {
                    combinedPorts.push({
                        ...itineraryPort.Point,
                        Activities: itineraryPort.Activities,
                        Ata: itineraryPort.Ata,
                        Atd: itineraryPort.Atd,
                        Eta: itineraryPort.Eta,
                        Etd: itineraryPort.Etd,
                        selectedInVoyage: true
                    });
                }
            }
        });
    }

    ports.forEach((port) => {
        if (!combinedPorts.find(el => el.Id === port.Id)) {
            combinedPorts.push(port);
        }
    });

    return combinedPorts;
}

export const getFetchedPorts = createSelector(
    state => state.emissionEstimatorPageReducer.ports,
    state => getCalculationPoints(state),

    (ports, calculationPoints) => {
        if (calculationPoints.length > 0) {
            return getCombinedPorts(calculationPoints, ports);
        }
        return ports;
    }
);

export const getPins = createSelector(
    state => getCalculationPoints(state),

    (calculationPoints) => {
        if (calculationPoints.length > 0) {
            return calculationPoints.reduce((res, point) => {
                if (point.Point && point.Point.Type === 3 && point.Point.Location) {
                    res.push({
                        ...point.Point,
                        Activities: point.Activities,
                        Ata: point.Ata,
                        Atd: point.Atd,
                        Eta: point.Eta,
                        Etd: point.Etd,
                        selectedInVoyage: true,
                        pointId: point.CalculationPointId
                    });
                }
                return res;
            }, []);
        }
        return emptyArray;
    }
);

export const getCalculationVesselPosition = createSelector(
    state => state.estimatorReducer.calculationVesselPosition,
    state => state.estimatorReducer.calculationVesselNotifications,

    (calculationVesselPosition, calculationVesselNotifications) => {
        const alerts = calculationVesselNotifications
            ? calculationVesselNotifications.filter(n => n.Severity === 'Alert')
            : null;

        if (calculationVesselPosition) {
            if (alerts && alerts.length) {
                return {
                    ...calculationVesselPosition,
                    LatestAlert: alerts[0]
                };
            }
            return calculationVesselPosition;
        }
        return null;
    }
);

export const getHistoricalRoute = createSelector(
    state => state.estimatorReducer.calculationVoyageAisPoints,
    RouteHelper.extractRoutesFromAisPoints
);

const createCalculationRouteSelector = (selector) => createSelector(
    selector,
    (calculationRoutePoints) => {
        if (calculationRoutePoints) {
            return calculationRoutePoints.map(mapLonLat);
        }
        return emptyArray;
    }
);

const createSplitRouteSelector = (selector) => createSelector(
    selector,
    (calculationRoute) => {
        if (calculationRoute.length > 0) {
            return splitRoute180(generateMiddlePoints(calculationRoute));
        }
        return emptyArray;
    }
);

export const getCalculationRoute = createCalculationRouteSelector(
    state => state.estimatorReducer.calculationRoutePoints
);

export const getCalculationRoutePoints = createSplitRouteSelector(getCalculationRoute);

export const getCalculationVoyageRoute = createCalculationRouteSelector(
    state => state.estimatorReducer.calculationVoyageRoutePoints
);

export const getCalculationVoyageRoutePoints = createSplitRouteSelector(getCalculationVoyageRoute);
