/* common */
import { t, tu, commonFields, commonData } from '../vessel-report-common-fields';

const isNoticeOfReadinessDisabled = (report) => {
    return report.CargoOperations !== true;
};

export const fieldMap = {

    // Basic info

    Vessel: commonFields.Vessel(),
    CallSign: commonFields.CallSign(),
    VoyageNumber: commonFields.VoyageNumber(),
    CharterpartyDate: commonFields.CharterpartyDate(),
    ReportDate: commonFields.ReportDate(),
    Position: commonFields.Position(),
    CargoStatus: commonFields.CargoStatus(),
    VesselStatus: commonFields.VesselStatus(),

    // Since last event

    TimeElapsedSinceLastEvent: commonFields.String('TimeElapsedSinceLastEvent', t('TIME_SINCE_LAST_EVENT')),
    TimeAtAnchorSinceLastEvent: commonFields.String('TimeAtAnchorSinceLastEvent', t('TIME_AT_ANCHOR_SINCE_LAST_EVENT')),
    HoursSteamedSinceLastEvent: commonFields.String('HoursSteamedSinceLastEvent', t('HOURS_STEAMED_SINCE_LAST_EVENT')),
    DistanceSinceLastEvent: commonFields.Number(
        'DistanceSinceLastEvent',
        t('DISTANCE_SINCE_LAST_EVENT'),
        2,
        tu('DISTANCE')
    ),
    WindForceSinceLastEvent: commonFields.WindForce('WindForceSinceLastEvent', t('WIND_FORCE_SINCE_LAST_EVENT')),
    TotalCargoSinceLastEvent: commonFields.Number(
        'TotalCargoSinceLastEvent',
        t('TOTAL_CARGO_ONBOARD'),
        0,
        tu('CARGO')
    ),

    // Port information
    PreviousPort: commonFields.Point('PreviousPort', t('PREVIOUS_PORT')),
    Point: commonFields.Point('PointOfArrival', t('PORT_OF_ARRIVAL')),
    EndOfSeaPassage: commonFields.DateTime('EndOfSeaPassage', 'EndOfSeaPassageOffset', t('END_OF_SEA_PASSAGE')),
    Terminal: commonFields.String('Terminal', t('BERTH_TERMINAL_ANCHORAGE')),
    EstimatedTimeOfBerthing: commonFields.DateTime(
        'EstimatedTimeOfBerthing',
        'EstimatedTimeOfBerthingOffset',
        t('ET_OF_BERTHING')
    ),
    AverageSpeed: commonFields.Speed('AverageSpeed', t('AVERAGE_SPEED')),
    TimeOfPassage: commonFields.String('TimeOfPassage', t('TIME_OF_PASSAGE')),
    DistanceFromLastPort: commonFields.Number(
        'DistanceFromLastPort',
        t('DISTANCE_FROM_LAST_PORT'),
        2,
        tu('DISTANCE')
    ),
    FreePratiqueGranted: commonFields.DateTime(
        'FreePratiqueGranted',
        'FreePratiqueGrantedOffset',
        t('FREE_PRATIQUE_GRANTED')
    ),
    ArrivingPilotStation: commonFields.DateTime(
        'ArrivingPilotStation',
        'ArrivingPilotStationOffset',
        t('ARRIVING_PILOT_STATION')
    ),
    CargoOperations: commonFields.Boolean('CargoOperations', t('CARGO_OPERATIONS')),
    NoticeOfReadiness: commonFields.DateTime(
        'NoticeOfReadiness',
        'NoticeOfReadinessOffset',
        t('NOR_TENDERED'),
        undefined,
        isNoticeOfReadinessDisabled
    ),
    AnchoredDate: commonFields.DateTime('AnchoredDate', 'AnchoredDateOffset', t('ANCHORED_DATE')),
    DraftFwd: commonFields.Draft('DraftFwd', t('ARRIVAL_DRAFT_FWD')),
    DraftMid: commonFields.Draft('DraftMid', t('ARRIVAL_DRAFT_MID')),
    DraftAft: commonFields.Draft('DraftAft', t('ARRIVAL_DRAFT_AFT')),
    Displacement: commonFields.Displacement(),
    EstimatedTimeOfDepartureFromBerth: commonFields.DateTime(
        'EstimatedTimeOfDepartureFromBerth',
        'EstimatedTimeOfDepartureFromBerthOffset',
        t('ETD_FROM_BERTH')
    ),
    AverageRpm: commonFields.Number('AverageRpm', t('AVERAGE_RPM'), 1),

    // Remarks

    Remarks: commonFields.Remarks(),

    // Cargo

    'AuroraReportData.CargoUsed.ForceUsed':
        commonFields.ViewOnly('AuroraReportData.CargoUsed.ForceUsed', t('FORCER_USED')),
    'AuroraReportData.CargoUsed.Hours':
        commonFields.ViewOnly('AuroraReportData.CargoUsed.Hours', t('FORCER_HOURS'), 0),

    // Other info

    'AuroraReportData.VoyageBoilOff':
        commonFields.ViewOnly('AuroraReportData.VoyageBoilOff', t('BOILER_OFF'), 1, tu('CUBIC_CAPACITY')),
    'AuroraReportData.MainEngineMiles':
        commonFields.ViewOnly('AuroraReportData.MainEngineMiles', t('ENGINE_DISTANCE'), 1, tu('DISTANCE')),
    'AuroraReportData.DistanceThroughIce':
        commonFields.ViewOnly('AuroraReportData.DistanceThroughIce', t('DISTANCE_THROUGH_ICE'), 1, tu('DISTANCE')),
    'AuroraReportData.CpWarrantedConsumption': commonFields.ViewOnly(
        'AuroraReportData.CpWarrantedConsumption',
        t('WARRANTED_CONSUMPTION'),
        1,
        tu('CONSUMPTION')
    ),
    'AuroraReportData.Trim': commonFields.ViewOnly('AuroraReportData.Trim', t('TRIM'), 1, tu('TRIM')),
    'AuroraReportData.TrimType': commonFields.ViewOnly('AuroraReportData.TrimType', t('TRIM_TYPE')),
    'AuroraReportData.ElectricalLoad':
        commonFields.ViewOnly('AuroraReportData.ElectricalLoad', t('ELECTRICAL_LOAD'), 0, tu('ELECTRICAL_LOAD'))
};

export const dataMap = {
    ConsumptionsSinceLastEvent: commonData.ConsumptionFuelData('ConsumptionsSinceLastEvent', 6),
    BunkerData: commonData.BunkerFuelData('BunkerData'),
    Consumptions: commonData.ConsumptionFuelData('Consumptions', 1),
    'AuroraReportData.ExtractedEquipmentItems.MainTurbine': commonData.AuroraDataTable(
        'AuroraReportData.ExtractedEquipmentItems.MainTurbine', 'MainTurbine', t('SIDE'), t('TURBINE')
    ),
    'AuroraReportData.ExtractedEquipmentItems.PropulsionMotor': commonData.AuroraDataTable(
        'AuroraReportData.ExtractedEquipmentItems.PropulsionMotor', 'PropulsionMotor', t('SIDE'), t('PROPULSION_MOTORS')
    ),
    'AuroraReportData.ExtractedEquipmentItems.MainEngine': commonData.AuroraDataTable(
        'AuroraReportData.ExtractedEquipmentItems.MainEngine', 'MainEnginePower', t('ENGINE')
    ),
    'AuroraReportData.Water': commonData.AuroraDataTable('AuroraReportData.Water', 'Water', t('WATER_TYPE')),
    'AuroraReportData.Temperatures': commonData.AuroraDataTable(
        'AuroraReportData.Temperatures', 'Temperatures', t('TANK')
    ),
    'AuroraReportData.BarrierSpaceConditions': commonData.AuroraDataTable(
        'AuroraReportData.BarrierSpaceConditions', 'BarrierSpaceConditions', t('TANK'), t('BARRIER_SPACE_CONDITIONS')
    ),
    'AuroraReportData.CofferdamTemps': commonData.AuroraDataTable(
        'AuroraReportData.CofferdamTemps', 'CofferdamTemps', '#', t('COFFERDAM_LOWEST_TEMPERATURES'), { minColWidth: 0 }
    )
};

export default { fieldMap, dataMap };
