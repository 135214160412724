import { ActionTypes } from './send-reminder-modal-actions';

const initialState = {
    report: null
};

export default function sendReminderModalReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.TOGGLE_MISSING_REPORT_REMINDER_MODAL: {
        return {
            ...state,
            report: action.report
                ? {
                    ...action.report,
                    Date: action.report && action.report.Date ? action.report.Date : action.report.ReportDate
                }
                : null
        };
    }
    default:
        return state;
    }
}
