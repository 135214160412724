import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import Cell from './cell';
import variables from '../variables';
import './month.scss';

const calculateNumberOfDaysInAMonth = (year, month) => 32 - new Date(year, month - 1, 32).getDate();
const calculateFirstWeekDayInAMonth = (year, month, weekStart) => {
    let firstWeekDay = new Date(year, month - 1, 1).getDay();
    if (weekStart === 'Monday') {
        firstWeekDay = firstWeekDay - 1 >= 0 ? firstWeekDay - 1 : 6;
    }
    return firstWeekDay;
};

export default class Month extends React.Component {
    state = {
        prevPropsMonth: null,
        prevPropsYear: null
    };

    static getDerivedStateFromProps(props, state) {
        if (state.prevPropsMonth !== props.month || state.prevPropsYear !== props.year) {
            const numberOfDaysInCurrentMonth = calculateNumberOfDaysInAMonth(props.year, props.month);
            const numberOfDaysInPreviousMonth = props.month === 1
                ? 31
                : calculateNumberOfDaysInAMonth(props.year, props.month - 1);

            return {
                numberOfDaysInCurrentMonth,
                numberOfDaysInPreviousMonth,
                numberOfOutsideDaysInTheBeginningOfMonth:
                    calculateFirstWeekDayInAMonth(props.year, props.month, props.weekStart),
                prevPropsMonth: props.month,
                prevPropsYear: props.year
            };
        }

        return null;
    }

    renderWeek(rowNumber) {
        const offset = (7 * rowNumber) - this.state.numberOfOutsideDaysInTheBeginningOfMonth;
        const days = new Array(7);

        for (let i = 1; i <= 7; i++) {
            // day is from this month
            let day = offset + i;
            let month = this.props.month;
            let year = this.props.year;
            let isOutsideOfCurrentMonth = false;

            if (day <= 0) { // day is from previous month
                isOutsideOfCurrentMonth = true;
                day = this.state.numberOfDaysInPreviousMonth + day;
                month = this.props.month === 1 ? 12 : this.props.month - 1;
                year = this.props.month === 1 ? this.props.year - 1 : this.props.year;
            } else if (day > this.state.numberOfDaysInCurrentMonth) { // day is from next month
                isOutsideOfCurrentMonth = true;
                day -= this.state.numberOfDaysInCurrentMonth;
                month = this.props.month === 12 ? 1 : this.props.month + 1;
                year = this.props.month === 12 ? this.props.year + 1 : this.props.year;
            }

            days[i - 1] = (
                <td className="htec-dp-month__day" key={i}>
                    {!(this.props.hideOutsideDays && isOutsideOfCurrentMonth)
                        && this.props.renderDay({ day, month, year, weekDay: i, isOutsideOfCurrentMonth })
                    }
                </td>
            );
        }

        return (
            <tr className="htec-dp-month__week" key={rowNumber}>
                {days}
            </tr>
        );
    }

    render() {
        const totalWeeks = this.props.alwaysShow6Weeks
            ? 6
            : Math.ceil(
                (this.state.numberOfDaysInCurrentMonth + this.state.numberOfOutsideDaysInTheBeginningOfMonth) / 7
            );
        const weeks = new Array(totalWeeks);

        for (let i = 0; i < totalWeeks; i++) {
            weeks[i] = this.renderWeek(i);
        }
        let className = 'htec-dp-month';
        if (this.props.className) {
            className += ` ${this.props.className}`;
        }

        return (
            <table className={className}>
                {this.props.renderWeekDays(this.props.weekStart)}
                <tbody className="htec-dp-month__weeks">{weeks}</tbody>
            </table>
        );
    }
}

Month.propTypes = {
    alwaysShow6Weeks: PropTypes.bool,
    className: PropTypes.string,
    hideOutsideDays: PropTypes.bool,
    month: PropTypes.number.isRequired,
    renderDay: PropTypes.func,
    renderWeekDays: PropTypes.func,
    weekStart: PropTypes.oneOf(['Monday', 'Sunday']),
    year: PropTypes.number.isRequired
};

Month.defaultProps = {
    className: '',
    weekStart: 'Sunday',
    alwaysShow6Weeks: true,
    hideOutsideDays: false,
    renderDay: (args) => {
        return <Cell {...args} title={args.day} />;
    },
    renderWeekDays: memoize((weekStart) => {
        const weekdays = [];
        let weekDay;
        for (let i = 0; i < 7; i++) {
            if (weekStart === 'Monday') {
                if (i + 1 > 6) {
                    weekDay = 0;
                } else {
                    weekDay = i + 1;
                }
            } else {
                weekDay = i;
            }
            weekdays.push(
                <th key={i} title={variables.weekDayNames.long[weekDay]}>
                    {variables.weekDayNames.short[weekDay]}
                </th>
            );
        }
        return (
            <thead className="htec-dp-month__week-days">
                <tr>{weekdays}</tr>
            </thead>
        );
    })
};
