import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* types */
import { TEnergyManagementReportsQueryParams } from '../reports-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* actions */
import { setSelectedReportType, toggleSidebar } from '../reports-actions';
/* components */
import EnergyManagementInfoPanel from '../shared/info-panel';
import ScrollArea from 'components/scroll-area/scroll-area';
import ContentHeader from '../shared/content-header';

const reportTypePropsMap = {
    1: {
        title: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.DASHBOARD.VOYAGE_REPORT'),
        content: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.DASHBOARD.VOYAGE_REPORT_DESCRIPTION'),
        iconClassName: 'icon-voyage-reports'
    },
    2: {
        title: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.DASHBOARD.PERFORMANCE_SUMMARY_REPORT'),
        content: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.DASHBOARD.PERFORMANCE_SUMMARY_REPORT_DESCRIPTION'),
        iconClassName: 'icon-performance-summary'
    },
    4: {
        title: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.DASHBOARD.MULTI_VOYAGE_REPORT'),
        content: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.DASHBOARD.MULTI_VOYAGE_REPORT_DESCRIPTION'),
        iconClassName: 'icon-multi-voyage-report'
    },
    9: {
        title: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.DASHBOARD.FLEET_SPEED_REPORT'),
        content: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.DASHBOARD.FLEET_SPEED_REPORT_DESCRIPTION'),
        iconClassName: 'icon-gauge'
    },
    10: {
        title: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.DASHBOARD.FLEET_PERFORMANCE_REPORT'),
        content: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.DASHBOARD.FLEET_PERFORMANCE_REPORT_DESCRIPTION'),
        iconClassName: 'icon-fleet-performance'
    },
    11: {
        title: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.DASHBOARD.QUARTERLY_REPORT'),
        content: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.DASHBOARD.QUARTERLY_REPORT_DESCRIPTION'),
        iconClassName: 'icon-quarterly-report'
    },
    12: {
        title: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.DASHBOARD.FLEET_LIST_REPORT'),
        content: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.DASHBOARD.FLEET_LIST_REPORT_DESCRIPTION'),
        iconClassName: 'icon-fleet-export-report'
    },
    13: {
        title: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.DASHBOARD.WEATHER_PERFORMANCE_REPORT'),
        content: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.DASHBOARD.WEATHER_PERFORMANCE_REPORT_DESCRIPTION'),
        iconClassName: 'icon-weather-performance'
    }
};

class EnergyManagementReportsDashboard extends React.PureComponent {
    componentDidMount() {
        if (this.props.reportTypes && this.props.queryParams.emReportType) {
            this.handleInfoPanelClick(this.props.queryParams.emReportType);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.reportTypes
            && this.props.queryParams.emReportType
            && (this.props.queryParams.emReportType !== prevProps.queryParams.emReportType
                || this.props.reportTypes !== prevProps.reportTypes)) {
            this.handleInfoPanelClick(this.props.queryParams.emReportType);
        }
    }

    handleInfoPanelClick = (reportTypeId) => {
        this.props.toggleSidebar(true);
        const reportType = this.props.reportTypes.find(rt => rt.Id === reportTypeId);
        this.props.setSelectedReportType(reportType);
        if (this.props.queryParams.reportId || this.props.queryParams.reportType) {
            this.props.router.updateQueryParams({ query: null });
        }
    };

    render() {
        const { reportTypes } = this.props;
        return (
            <div className="sten-content">
                <ContentHeader />
                <ScrollArea className="sten-content__body">
                    <div className="sten-content__section">
                        <div className="row row--fluid">
                            {reportTypes.map(rt => (
                                <div className="col-lg-8 col-md-12" key={rt.Id}>
                                    <EnergyManagementInfoPanel
                                        {...reportTypePropsMap[rt.Id]}
                                        typeId={rt.Id}
                                        onClick={this.handleInfoPanelClick}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </ScrollArea>
            </div>
        );
    }
}

EnergyManagementReportsDashboard.propTypes = {
    queryParams: TEnergyManagementReportsQueryParams.isRequired,
    reportTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    router: TRouter.isRequired,
    setSelectedReportType: PropTypes.func.isRequired,
    toggleSidebar: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        reportTypes: state.energyManagementReportsReducer.reportTypes
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setSelectedReportType: reportType => setSelectedReportType(dispatch, reportType),
        toggleSidebar: flag => toggleSidebar(dispatch, flag)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EnergyManagementReportsDashboard));
