import React from 'react';
import PropTypes from 'prop-types';
/* styles */
import './input.scss';

export default class HtecDPRangeInputButton extends React.Component {
    renderPlaceholder = (text, handleClick) => (
        <div onClick={handleClick} className="htec-dp-input__value htec-dp-input__placeholder">{text}</div>
    );

    renderDateField = (text, handleClick) => (
        <div onClick={handleClick} className="htec-dp-input__value">{text}</div>
    );

    handleIconClick = (e) => {
        if (!this.props.isDisabled && this.props.onIconClick) {
            this.props.onIconClick(e);
        }
    };

    handleStartDateClick = (e) => {
        if (!this.props.isDisabled && this.props.onStartDateClick) {
            this.props.onStartDateClick(e);
        }
    };

    handleEndDateClick = (e) => {
        if (!this.props.isDisabled && this.props.onEndDateClick) {
            this.props.onEndDateClick(e);
        }
    };

    render() {
        const {
            className,
            isActive,
            isDisabled,
            isInvalid,
            startDate,
            startDatePlaceholder,
            endDate,
            endDatePlaceholder,
            datesSeparator,
            postIcon
        } = this.props;

        let rangeInputClass = 'htec-dp-input htec-dp-input--range';
        if (className) {
            rangeInputClass += ` ${className}`;
        }
        if (isActive) {
            rangeInputClass += ' htec-dp-input--active';
        }
        if (isDisabled) {
            rangeInputClass += ' htec-dp-input--disabled';
        }
        if (isInvalid) {
            rangeInputClass += ' htec-dp-input--invalid';
        }

        return (
            <div className={rangeInputClass} ref={this.props.saveRef}>
                {(startDate && this.renderDateField(startDate, this.handleStartDateClick))
                    || this.renderPlaceholder(startDatePlaceholder, this.handleStartDateClick)}
                <div className="htec-dp-input__separator">{datesSeparator}</div>
                {(endDate && this.renderDateField(endDate, this.handleEndDateClick))
                    || this.renderPlaceholder(endDatePlaceholder, this.handleEndDateClick)}
                <div className="htec-dp-input__icon" onClick={this.handleIconClick}>{postIcon}</div>
            </div>
        );
    }
}

HtecDPRangeInputButton.propTypes = {
    className: PropTypes.string,
    datesSeparator: PropTypes.node,
    endDate: PropTypes.string,
    endDatePlaceholder: PropTypes.string,
    isActive: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isInvalid: PropTypes.bool,
    name: PropTypes.string,
    onEndDateClick: PropTypes.func,
    onIconClick: PropTypes.func,
    onStartDateClick: PropTypes.func,
    postIcon: PropTypes.node,
    saveRef: PropTypes.func,
    startDate: PropTypes.string,
    startDatePlaceholder: PropTypes.string
};

HtecDPRangeInputButton.defaultProps = {
    className: '',
    datesSeparator: '→',
    endDate: '',
    endDatePlaceholder: '',
    isActive: false,
    isDisabled: false,
    isInvalid: false,
    name: '',
    onEndDateClick: undefined,
    onIconClick: undefined,
    onStartDateClick: undefined,
    postIcon: null,
    saveRef: undefined,
    startDate: '',
    startDatePlaceholder: ''
};
