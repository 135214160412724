import React from 'react';
import PropTypes from 'prop-types';
/* components */
import TankLayoutCard from './tank-layout-card';
import TankLayoutCommodities from './tank-layout-commodities';
import ShowMoreButton from 'components/show-more-button/show-more-button';
/* style */
import './tank-layout-cards.scss';

class TankLayoutCards extends React.PureComponent {
    state = {
        expandedWings: {}
    };

    toggleCollapsed = (wingIndex) => {
        this.setState({
            expandedWings: {
                ...this.state.expandedWings,
                [wingIndex]: !this.state.expandedWings[wingIndex]
            }
        });
    };

    render() {
        const { className, showPreviousCommodities, isCollapsible } = this.props;
        const { expandedWings } = this.state;
        return (
            <div className={`sten-tank-layout-cards${className ? ` ${className}` : ''}`}>
                {this.props.wings.map((wing) => (
                    <div key={wing.Index} className="sten-tank-layout-cards__wing">
                        <div className="flex">
                            <TankLayoutCard
                                wing={wing}
                                className="col-12"
                                side="Port"
                                isCollapsed={isCollapsible && !expandedWings[wing.Index]}
                            />
                            <TankLayoutCard
                                wing={wing}
                                className="col-12"
                                side="Starboard"
                                isCollapsed={isCollapsible && !expandedWings[wing.Index]}
                            />
                        </div>
                        {(!isCollapsible || expandedWings[wing.Index]) && showPreviousCommodities && (
                            <div className="flex sten-content__separator">
                                <TankLayoutCommodities
                                    className="col-12"
                                    commodities={wing.Port.PreviousCommodities}
                                />
                                <TankLayoutCommodities
                                    className="col-12"
                                    commodities={wing.Starboard.PreviousCommodities}
                                />
                            </div>
                        )}
                        {isCollapsible && (!wing.Port.IsSlop || !wing.Starboard.IsSlop) && (
                            <div
                                className="sten-tank-layout-cards__toggle-collapse"
                                onClick={this.toggleCollapsed.bind(this, wing.Index)}
                            >
                                <ShowMoreButton isMoreShown={expandedWings[wing.Index]} />
                            </div>
                        )}
                    </div>
                ))}
            </div>
        );
    }
}

TankLayoutCards.propTypes = {
    className: PropTypes.string,
    isCollapsible: PropTypes.bool,
    showPreviousCommodities: PropTypes.bool,
    wings: PropTypes.arrayOf(PropTypes.object)
};

TankLayoutCards.defaultProps = {
    className: '',
    isCollapsible: false,
    showPreviousCommodities: false,
    wings: []
};

export default TankLayoutCards;
