const types = {
    hexShort: /^#[0-9a-fA-f]{3}$/,
    hexLong: /^#[0-9a-fA-f]{6}$/,
    rgb: /^rgba?\((\d{1,3}),\s?(\d{1,3}),\s?(\d{1,3})(,.*)?\)$/,
    hexKml: /^[0-9a-fA-F]{8}$/
};

class ColorHelper {
    validateRgbValue(value) {
        const res = parseInt(value, 10);
        if (res > 255) {
            return 255;
        }
        if (res < 0) {
            return 0;
        }
        return res;
    }

    rgba(color, alpha) {
        const res = { r: 0, g: 0, b: 0, a: (typeof alpha === 'number') ? alpha : 1 };
        const rgbMatch = color.match(types.rgb);

        if (types.hexShort.test(color)) {
            res.r = parseInt(color.charAt(1) + color.charAt(1), 16);
            res.g = parseInt(color.charAt(2) + color.charAt(2), 16);
            res.b = parseInt(color.charAt(3) + color.charAt(3), 16);
        } else if (types.hexLong.test(color)) {
            res.r = parseInt(color.substr(1, 2), 16);
            res.g = parseInt(color.substr(3, 2), 16);
            res.b = parseInt(color.substr(5, 2), 16);
        } else if (types.hexKml.test(color)) {
            res.r = parseInt(color.substr(6, 2), 16);
            res.g = parseInt(color.substr(4, 2), 16);
            res.b = parseInt(color.substr(2, 2), 16);
            if (typeof alpha !== 'number') {
                res.a = parseInt(color.substr(0, 2), 16) / 255;
            }
        } else if (rgbMatch.length > 3) {
            if (typeof alpha !== 'number') {
                return color;
            }
            res.r = this.validateRgbValue(rgbMatch[1]);
            res.g = this.validateRgbValue(rgbMatch[2]);
            res.b = this.validateRgbValue(rgbMatch[3]);
        }

        return `rgba(${res.r},${res.g},${res.b},${res.a})`;
    }
}

export default new ColorHelper();
