import React from 'react';
/* utils */
import TimeHelper from 'utils/helpers/time-helper';
import { ellipsis, formatNumber, getObjectProp, getClassName } from 'utils/helpers/info-helper';
import { t } from 'utils/i18n/i18n-model';

const timeOptions = { utc: true, time: true };

export const routeColors = [
    '#9575cd',
    '#ea3d7b',
    '#fd6f4a',
    '#ffc334',
    '#9dcc6b'
];

const formatNumberValue = (data, key, decimals, unit) => {
    const value = getObjectProp(data, key);
    return typeof value !== 'number'
        ? '-'
        : `${formatNumber(value, decimals)}${unit ? ` ${unit}` : ''}`;
};

const renderRequestStatus = (data) => {
    if (data) {
        const className = getClassName({
            'text-danger': data.Status.Name === 'Error',
            'text-success': data.Status.Name === 'Ongoing'
            // 'text-warning': data.Status.Name === 'RouteReady'
        });
        return <span className={className}>{data.Status.DisplayName}</span>;
    }
    return '-';
};

export const requestActionField = {
    label: t('WEATHER_ROUTING.LEG.ROUTING_STATUS'),
    renderValue: renderRequestStatus,
    style: { minWidth: '13rem', flexGrow: 1 }
};

export const requestFields = [
    {
        renderValue: (data) => {
            return (
                <React.Fragment>
                    <label className="label">
                        {`${t('WEATHER_ROUTING.LEG.FROM')}: `}
                        <span className="label--value">{getObjectProp(data, 'PortOfDeparture.Name') || '-'}</span>
                    </label>
                    <label className="label">
                        {`${t('WEATHER_ROUTING.LEG.TO')}: `}
                        <span className="label--value">{getObjectProp(data, 'PortOfDestination.Name') || '-'}</span>
                    </label>
                </React.Fragment>
            );
        },
        style: { minWidth: '12rem' }
    }, {
        label: t('WEATHER_ROUTING.LEG.ETD'),
        getValue: (data) => (data.Etd ? TimeHelper.getFormattedISO(data.Etd, timeOptions) : '-'),
        style: { minWidth: '15rem' }
    }, {
        label: t('WEATHER_ROUTING.LEG.ETA'),
        getValue: (data) => (data.Eta ? TimeHelper.getFormattedISO(data.Eta, timeOptions) : '-'),
        style: { minWidth: '15rem' }
    }, {
        label: t('WEATHER_ROUTING.LEG.OPTIMIZATION'),
        prop: 'OptimizationType.DisplayName',
        style: { minWidth: '7.5rem' }
    }, {
        label: t('WEATHER_ROUTING.LEG.SOURCE'),
        prop: 'Source.DisplayName',
        style: { minWidth: '6.5rem' }
    }, {
        label: t('WEATHER_ROUTING.LEG.SPEED'),
        getValue: (data) => {
            if (data.RouteCalculationType.Id === 1) {
                return formatNumber(data.Speed.InstructedSpeed, 2);
            }
            return `${formatNumber(data.Speed.MinimumSpeed, 2)} - ${formatNumber(data.Speed.MaximumSpeed, 2)}`;
        },
        unit: t('UNITS.SPEED'),
        style: { minWidth: '7.5rem' }
    }, {
        label: t('WEATHER_ROUTING.LEG.REMARKS'),
        getValue: (data) => ellipsis(data.Remarks, 50),
        getTitle: (data) => data.Remarks,
        style: { flexGrow: '1', minWidth: '21rem' }
    }
];

export const sposFields = [
    {
        label: t('WEATHER_ROUTING.LEG.REPORT_DATE'),
        getValue: (data) => (data.VesselPositionTime
            ? TimeHelper.getFormatted(data.VesselPositionTime, timeOptions)
            : '-'),
        style: { minWidth: '13rem', flexGrow: 1 }
    }, {
        renderValue: (data) => {
            return (
                <React.Fragment>
                    <label className="label">
                        {`${t('WEATHER_ROUTING.LEG.FROM')}: `}
                        <span className="label--value">{getObjectProp(data, 'From') || '-'}</span>
                    </label>
                    <label className="label">
                        {`${t('WEATHER_ROUTING.LEG.TO')}: `}
                        <span className="label--value">{getObjectProp(data, 'Destination') || '-'}</span>
                    </label>
                </React.Fragment>
            );
        },
        style: { minWidth: '12rem' }
    }, {
        label: t('WEATHER_ROUTING.LEG.ETD'),
        getValue: (data) => (data.DepartureDate ? TimeHelper.getFormatted(data.DepartureDate, timeOptions) : '-'),
        style: { minWidth: '15rem' }
    }, {
        label: t('WEATHER_ROUTING.LEG.ETA'),
        getValue: (data) => (data.DestinationDate ? TimeHelper.getFormatted(data.DestinationDate, timeOptions) : '-'),
        style: { minWidth: '15rem' }
    }
];

export const routeAdviceColumns = [
    {
        label: t('WEATHER_ROUTING.LEG.DATE_OF_ADVICE'),
        getValue: (data) => (data?.DateOfAdvice ? TimeHelper.getFormatted(data.DateOfAdvice, timeOptions) : null),
        width: 20
    }, {
        label: t('WEATHER_ROUTING.LEG.ETD'),
        getValue: (data) => (data?.Etd ? TimeHelper.getFormatted(data.Etd, timeOptions) : '-'),
        width: 12
    }, {
        label: t('WEATHER_ROUTING.LEG.ETA'),
        getValue: (data) => (data?.AdvisedEta ? TimeHelper.getFormatted(data.AdvisedEta, timeOptions) : '-'),
        width: 12
    }, {
        label: t('WEATHER_ROUTING.LEG.DISTANCE'),
        getValue: (data) => (data?.DistanceToGo ? formatNumber(data.DistanceToGo, 0) : null),
        unit: t('UNITS.DISTANCE'),
        width: 11
    }, {
        label: t('WEATHER_ROUTING.LEG.COST'),
        getValue: (data) => (data?.TotalCost ? formatNumber(data.TotalCost, 0) : null),
        unit: t('UNITS.COST'),
        width: 11
    }, {
        label: t('WEATHER_ROUTING.LEG.TOTAL_CONSUMPTION'),
        renderValue: (data) => {
            return (
                <div className="flex-row">
                    <div className="col-12">
                        <label className="label">
                            {`${t('WEATHER_ROUTING.LEG.ECA')}: `}
                            <span className="label--value">
                                {data?.Consumption?.Eca
                                    ? formatNumberValue(data, 'Consumption.Eca', 1, t('UNITS.CONSUMPTION'))
                                    : '-'}
                            </span>
                        </label>
                    </div>
                    <div className="col-12">
                        <label className="label">
                            {`${t('WEATHER_ROUTING.LEG.NON_ECA')}: `}
                            <span className="label--value">
                                {data?.Consumption?.NonEca
                                    ? formatNumberValue(data, 'Consumption.NonEca', 1, t('UNITS.CONSUMPTION'))
                                    : '-'}
                            </span>
                        </label>
                    </div>
                </div>
            );
        },
        width: 18.5
    }, {
        label: t('WEATHER_ROUTING.LEG.COMMENT'),
        renderValue: (data, component) => (
            <button
                disabled={!data?.Comment}
                className="btn-link icon icon-comment-outline"
                onClick={component.getTextModalHandler({
                    title: t('WEATHER_ROUTING.LEG.COMMENT'),
                    content: data?.Comment
                })}
            />
        ),
        width: 6
    }, {
        label: t('WEATHER_ROUTING.LEG.SYNOPSIS'),
        renderValue: (data, component) => (
            <button
                disabled={!data?.Synopsis}
                className="btn-link icon icon-file-text"
                onClick={component.getTextModalHandler({
                    title: t('WEATHER_ROUTING.LEG.SYNOPSIS'),
                    content: data?.Synopsis
                })}
            />
        ),
        width: 6
    }
];

export const routeAdviceTableWidth = routeAdviceColumns.reduce((res, col) => res + col.width, 0);

export const voyageFields = [
    {
        label: t('WEATHER_ROUTING.OVERVIEW.VESSEL'),
        prop: 'Vessel.Title',
        width: 12
    },
    {
        label: t('WEATHER_ROUTING.OVERVIEW.VOYAGE'),
        prop: 'VoyageNumber',
        width: 7
    },
    {
        renderValue: (data) => {
            return (
                <React.Fragment>
                    <label className="label">
                        {`${t('WEATHER_ROUTING.OVERVIEW.FROM')} `}
                        <span className="label--value">{getObjectProp(data, 'PortOfDeparture.Name') || '-'}</span>
                    </label>
                    <label className="label">
                        {`${t('WEATHER_ROUTING.OVERVIEW.TO')} `}
                        <span className="label--value">{getObjectProp(data, 'PortOfDestination.Name') || '-'}</span>
                    </label>
                </React.Fragment>
            );
        },
        width: 14
    },
    {
        label: t('WEATHER_ROUTING.OVERVIEW.START'),
        formatValue: (data) => (data.StartDate ? TimeHelper.getFormatted(data.StartDate, timeOptions) : '-'),
        width: 10
    },
    {
        label: t('WEATHER_ROUTING.OVERVIEW.END'),
        formatValue: (data) => (data.EndDate ? TimeHelper.getFormatted(data.EndDate, timeOptions) : '-'),
        width: 10
    }
];

export const legActionColumn = {
    label: t('WEATHER_ROUTING.OVERVIEW.LEG'),
    renderValue: (data) => (
        <React.Fragment>
            <div className="form-section-inline" style={{ width: '12rem' }}>
                <label className="label text-nowrap">
                    {`${t('WEATHER_ROUTING.OVERVIEW.FROM')} `}
                    <span className="label--value text-ellipsis">
                        {getObjectProp(data, 'Request.PortOfDeparture.Name')
                            || getObjectProp(data, 'LatestSposRoute.From')
                            || '-'}
                    </span>
                </label>
                <label className="label text-nowrap">
                    {`${t('WEATHER_ROUTING.OVERVIEW.TO')} `}
                    <span className="label--value text-ellipsis">
                        {getObjectProp(data, 'Request.PortOfDestination.Name')
                            || getObjectProp(data, 'LatestSposRoute.Destination')
                            || '-'}
                    </span>
                </label>
            </div>
            {data.Request ? (
                <div className="form-section-inline flex-grow">
                    <label className="label label--value text-nowrap">{renderRequestStatus(data.Request)}</label>
                    <label className="label text-nowrap">
                        {`${t('WEATHER_ROUTING.OVERVIEW.SOURCE')} `}
                        <span className="label--value">{getObjectProp(data, 'Request.Source.DisplayName')}</span>
                    </label>
                </div>
            ) : <div className="flex-grow" />}
            {data.LatestSposRoute && (
                <div className="flex-shrink">
                    <span className="sten-badge sten-badge--secondary" title={t('WEATHER_ROUTING.OVERVIEW.MIR_TITLE')}>
                        {t('WEATHER_ROUTING.OVERVIEW.MIR')}
                    </span>
                </div>
            )}
        </React.Fragment>
    ),
    width: 30
};

export const legColumns = [
    {
        label: t('WEATHER_ROUTING.OVERVIEW.VESSEL_STATUS'),
        prop: 'VesselCondition.Name',
        width: 8
    },
    {
        label: t('WEATHER_ROUTING.OVERVIEW.OPTIMIZATION'),
        prop: 'Request.OptimizationType.DisplayName',
        width: 8
    },
    {
        label: t('WEATHER_ROUTING.OVERVIEW.SPEED'),
        renderValue: (data) => {
            if (!data.Request) {
                return '-';
            }
            const speedValue = data.Request.RouteCalculationType.Name === 'InstructedSpeed'
                ? formatNumber(data.Request.Speed.InstructedSpeed, 2)
                : `${formatNumber(data.Request.Speed.MinimumSpeed, 2)}`
                    + ` - ${formatNumber(data.Request.Speed.MaximumSpeed, 2)}`;
            return (
                <label className="label">
                    {`${t('WEATHER_ROUTING.OVERVIEW.REQUESTED')} `}
                    <span className="label--value">{`${speedValue} ${t('UNITS.SPEED')}`}</span>
                </label>
            );
        },
        width: 12.5
    },
    {
        label: t('WEATHER_ROUTING.OVERVIEW.ETD'),
        formatValue: (data) => {
            if (getObjectProp(data, 'Request.Etd')) {
                return TimeHelper.getFormatted(data.Request.Etd, timeOptions);
            }
            if (getObjectProp(data, 'LatestSposRoute.DepartureDate')) {
                return TimeHelper.getFormatted(data.LatestSposRoute.DepartureDate, timeOptions);
            }
            return '-';
        },
        width: 10
    },
    {
        label: t('WEATHER_ROUTING.OVERVIEW.ETA'),
        renderValue: (data) => {
            if (!data.Request && !data.LatestSposRoute) {
                return '-';
            }
            if (!data.Request) {
                return TimeHelper.getFormatted(data.LatestSposRoute.DestinationDate, timeOptions);
            }
            const AdvisedEta = data.Request.LatestExternalRequest?.RouteAdvice?.AdvisedEta;
            return (
                <React.Fragment>
                    <label className="label">
                        {`${t('WEATHER_ROUTING.OVERVIEW.REQUESTED')} `}
                        <span className="label--value">
                            {data.Request.Eta ? TimeHelper.getFormatted(data.Request.Eta, timeOptions) : '-'}
                        </span>
                    </label>
                    <label className="label">
                        {`${t('WEATHER_ROUTING.OVERVIEW.ADVISED')} `}
                        <span className="label--value">
                            {AdvisedEta ? TimeHelper.getFormatted(AdvisedEta, timeOptions) : '-'}
                        </span>
                    </label>
                </React.Fragment>
            );
        },
        width: 15
    }
];
