import { ActionTypes } from './multi-voyage-report-actions';

const emptyArray = [];

const initialState = {
    summaryReport: null,
    multiVoyageReportDetails: emptyArray
};

export default function energyManagementReportsMultiVoyageReportReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ENERGY_MANAGEMENT_MULTI_VOYAGE_REPORT_SET: {
        return {
            ...state,
            summaryReport: action.report ? action.report.SummaryData : null,
            multiVoyageReportDetails: action.report ? action.report.MultivoyageDetails : emptyArray
        };
    }
    default:
        return state;
    }
}
