import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { setObjectProp } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
import { convertPortForUpdate } from 'components/port-form/port-form-helper';
import { renderFormFields } from 'components/form-fields/form-fields-helper';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
import Validation from 'components/validation/validation';
import Accordion from 'components/accordion/accordion';
/* selectors */
import { getConvertedPort } from '../port-info-selectors';
/* actions */
import { updatePort } from '../port-info-actions';
/* services */
import PortManagementService from 'services/core-api/port-management-service';
/* constants */
import { portFields, Port } from 'components/port-form/port-form-constants';

const t = (key) => translate(`PORT_INFO.DETAILS.${key}`);

const formFields = {
    Restrictions: [
        'Restrictions.MaxDraft',
        'Restrictions.MaxLOA',
        'Restrictions.MaxBeam',
        'Restrictions.MaxDisplacement',
        'Restrictions.MaxDWT'
    ],
    Vendors: ['Vendors.PreferredAgent', 'Vendors.TowingContract'],
    Bunkering: ['Bunkering.Types', 'Bunkering.Info']
};

export class PortInfoDetails extends React.PureComponent {
    state = {
        port: null
    };

    componentWillUnmount() {
        this.setState({ port: null });
    }

    handleEditClick = () => this.setState({ port: new Port(this.props.port) });

    handleEditCancel = () => {
        this.setState({ port: null });
    }

    handleValueChange = (key, prop) => (value) => {
        const port = { ...this.state.port };
        setObjectProp(port, key, prop ? value[prop] : value);
        this.setState({ port });
    }

    handleSubmit = () => {
        const convertedPort = convertPortForUpdate(this.state.port);
        PortManagementService.updatePort(this.state.port.Id, convertedPort).then((res) => {
            if (res) {
                toast(t('SUCCESSFULLY_UPDATED'), { type: toast.TYPE.SUCCESS });
                this.props.updatePort(res);
                this.handleEditCancel();
            }
        });
    };

    getUpdatedInfo = (port) => (
        <section className="sten-content__info-section flex-row">
            <div className="flex-grow">
                <p className="text-secondary">
                    {translate('PORT_INFO.UPDATED_INFO', {
                        at: port.UpdatedAt ? TimeHelper.getFormatted(port.UpdatedAt, { time: true }) : '-',
                        by: port.UpdatedBy.trim() || '-'
                    })}
                </p>
            </div>
            <div className="flex-shrink">
                <button className="btn-link btn--icon icon icon-edit" onClick={this.handleEditClick} />
            </div>
        </section>
    );

    renderSectionContent = (fieldNames) => {
        const options = { bunkeringTypes: this.props.bunkeringTypes };
        return renderFormFields(
            this.state.port || this.props.port,
            portFields,
            fieldNames,
            !!this.state.port,
            this.handleValueChange,
            true,
            options
        );
    };

    renderAdditionalServices = () => {
        if (this.state.port) {
            return this.state.port.Services && Object.values(this.state.port.Services).map(
                service => portFields.AdditionalService.edit(service, this.handleValueChange)
            );
        }
        return this.props.port.Services && Object.values(this.props.port.Services).map(
            service => portFields.AdditionalService.view(service, this.handleValueChange)
        );
    };

    renderAccordionHeader = (text) => (<h4 className="text-uppercase">{text}</h4>);

    renderContent = () => (
        <React.Fragment>
            <Accordion header={this.renderAccordionHeader(t('RESTRICTIONS'))}>
                <div className="sten-content__section">
                    {this.renderSectionContent(formFields.Restrictions)}
                </div>
            </Accordion>
            <Accordion header={this.renderAccordionHeader(t('VENDORS'))}>
                <div className="sten-content__section">
                    {this.renderSectionContent(formFields.Vendors)}
                </div>
            </Accordion>
            <Accordion header={this.renderAccordionHeader(t('ADDITIONAL_SERVICES'))}>
                <div className="sten-content__section">
                    {this.renderAdditionalServices()}
                </div>
            </Accordion>
            <Accordion header={this.renderAccordionHeader(t('BUNKERING'))}>
                <div className="sten-content__section">
                    {this.renderSectionContent(formFields.Bunkering)}
                </div>
            </Accordion>
        </React.Fragment>
    );

    saveFormRef = (c) => { this.formRef = c; };

    render() {
        const { port } = this.props;

        if (this.state.port) {
            return (
                <Validation.Form
                    className="sten-port-info-details"
                    ref={this.saveFormRef}
                    onSubmit={this.handleSubmit}
                >
                    <ScrollArea className="sten-port-info-details__content">
                        {this.renderContent()}
                    </ScrollArea>
                    <footer className="sten-content__footer flex-row">
                        <div className="col-12">
                            <button className="btn btn--secondary col-24" type="button" onClick={this.handleEditCancel}>
                                {translate('GLOBAL.CANCEL')}
                            </button>
                        </div>
                        <div className="col-12">
                            <Validation.Button className="btn btn--primary col-24">
                                {translate('GLOBAL.SAVE')}
                            </Validation.Button>
                        </div>
                    </footer>
                </Validation.Form>
            );
        }

        return (
            <ScrollArea className="sten-port-info-details">
                {this.getUpdatedInfo(port)}
                {this.renderContent()}
            </ScrollArea>
        );
    }
}

PortInfoDetails.propTypes = {
    bunkeringTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    port: PropTypes.objectOf(PropTypes.any),
    updatePort: PropTypes.func.isRequired
};

PortInfoDetails.defaultProps = {
    port: null
};

function mapStateToProps(state) {
    return {
        bunkeringTypes: state.portInfoReducer.bunkeringTypes,
        permissions: state.userReducer.permissions,
        port: getConvertedPort(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updatePort: port => updatePort(dispatch, port)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PortInfoDetails);
