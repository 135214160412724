import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* helpers */
import { mapLonLat, wktParse } from 'components/ol/ol-helpers';
/* selectors */
import { getTradingPorts, getSelectedTradingRoutes } from '../market-intel-selectors';
/* actions */
import { emptyActivePanels } from 'components/right-side-bar/right-side-bar-actions';
import { setTradingRouteTooltipPositions } from './trading-route-tooltip/trading-route-tooltip-actions';
import { setBunkerPortPositions } from './bunker-prices-tooltip/bunker-prices-tooltip-actions';
import { setVesselDistributionAreaPositions } from './vessel-distribution-areas/vessel-distribution-areas-actions';
import { toggleUserSetting } from '../../../user-actions';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import TradingRouteTooltip from './trading-route-tooltip/trading-route-tooltip';
import BunkerPricesTooltip from './bunker-prices-tooltip/bunker-prices-tooltip';
import VesselDistributionAreas from './vessel-distribution-areas/vessel-distribution-areas';
import Ol from 'components/ol/ol';
/* config */
import config from './market-intel-map-config';

const animationLength = 600;

class MarketIntelMap extends React.PureComponent {
    constructor(props) {
        super(props);
        this.cachedViewData = {
            importanceLevel: 10,
            extent: null,
            BBox: ''
        };
        this.state = {
            mapZoomControlElement: null
        };
        this.animationStartTime = null;
    }

    componentDidMount() {
        this.updateElementPositions();
        this.setState({ mapZoomControlElement: document.getElementById('stenMapZoomControls') });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isRightSideBarCollapsed !== this.props.isRightSideBarCollapsed) {
            window.requestAnimationFrame(this.updateSizeOnFrame);
        }
        if (prevProps.selectedLayerId !== this.props.selectedLayerId
            || prevProps.vesselDistributionAreas !== this.props.vesselDistributionAreas) {
            this.updateElementPositions(this.props);
        }
    }

    updateSizeOnFrame = (timestamp) => {
        if (!this.animationStartTime) {
            this.animationStartTime = timestamp;
        }
        if (timestamp - this.animationStartTime < animationLength) {
            this.map.updateSize();
            window.requestAnimationFrame(this.updateSizeOnFrame);
        } else {
            this.animationStartTime = null;
        }
    };

    updateElementPositions = (props = this.props) => {
        if (this.map) {
            const showAllFeatures = ConfigService.featureToggles.showAllMarketIntelFeatures;
            if (showAllFeatures && props.selectedLayerId === 1) {
                this.setTradingRouteTooltipPositions();
            } else if (showAllFeatures && props.selectedLayerId === 2) {
                this.setBunkerPortPositions();
            } else if ((!showAllFeatures || props.selectedLayerId === 3) && props.selectedDistributionType === 2) {
                this.setVesselDistributionAreaPositions(props);
            }
        }
    };

    onMapPostRender = () => {
        this.updateElementPositions();
    };

    onMapClick = (event, feature) => {
        if (!feature) {
            this.props.closeRightSidebar();
        }
    };

    onBunkerPortClick = port => {
        if (port && port.data) {
            this.props.toggleUserSetting('BunkerPricesSelectedPorts', port.data.Id);
            this.setBunkerPortPositions();
        }
    };

    onTradingRouteClick = tradingRouteFeature => {
        if (tradingRouteFeature && tradingRouteFeature.data) {
            this.props.toggleUserSetting('TradingRouteIds', tradingRouteFeature.data.Id);
            this.setTradingRouteTooltipPositions();
        }
    };

    getBunkerPortActive = port => {
        return this.props.activeBunkerPorts && this.props.activeBunkerPorts.indexOf(port.Id) > -1;
    };

    getBunkerPortSecondaryColor = (port, isHovered) => {
        if (isHovered || this.getBunkerPortActive(port)) {
            return config.bunkerPort.primaryColor;
        }
        return null;
    };

    getBunkerPortOpacity = (port, isHovered) => {
        if (isHovered || this.getBunkerPortActive(port)) {
            return 1;
        }
        return 0.8;
    };

    getTradingRouteLabel = line => {
        if (this.props.selectedTradingRoutes.findIndex(route => route.RouteCode === line.RouteCode) > -1) {
            return null;
        }
        return line.RouteCode;
    };

    getTradingRouteLabelProps(line) {
        if (line.RouteCode === 'TD3') {
            return { maxAngle: 0 };
        }
        return { maxAngle: Math.PI / 8 };
    }

    setBunkerPortPositions = () => {
        const bunkerPortsPositions = this.props.bunkerPorts.reduce((res, bunkerPort) => {
            if (this.props.activeBunkerPorts.indexOf(bunkerPort.Id) > -1) {
                const coordinates = wktParse(bunkerPort.Location);
                const positions = this.map.getPixelFromCoordinate(coordinates);
                res.push({
                    ...bunkerPort,
                    left: positions && positions[0] ? Math.floor(positions[0]) : 0,
                    top: positions && positions[1] ? Math.floor(positions[1]) : 0
                });
            }
            return res;
        }, []);

        this.props.setBunkerPortPositions(bunkerPortsPositions);
    };

    setTradingRouteTooltipPositions = () => {
        const tradingRouteTooltipPositions = this.props.selectedTradingRoutes.map((selectedRoute) => {
            const coordinates = wktParse(selectedRoute.TooltipPoint);
            const positions = this.map.getPixelFromCoordinate(coordinates);
            return {
                ...selectedRoute,
                left: Math.floor(positions[0]),
                top: Math.floor(positions[1])
            };
        });

        this.props.setTradingRouteTooltipPositions(tradingRouteTooltipPositions);
    };

    setVesselDistributionAreaPositions = (props = this.props) => {
        if (props.vesselDistributionAreas && this.map) {
            const distributionAreaPositions = props.vesselDistributionAreas.map((area) => {
                const positions = this.map.getPixelFromCoordinate(
                    [area.AreaCenter.Longitude, area.AreaCenter.Latitude]
                );
                if (positions && positions.length > 0) {
                    return {
                        ...area,
                        left: Math.floor(positions[0]),
                        top: Math.floor(positions[1])
                    };
                }
                return area;
            });
            props.setVesselDistributionAreaPositions(distributionAreaPositions);
        }
    };

    tradingRoutesElementProps = {
        coordinates: 'Route',
        color: config.route.trading.color,
        width: config.route.trading.width,
        lineDash: config.route.trading.lineDash,
        lineDashMaxZoom: config.route.trading.lineDashMaxZoom,
        label: this.getTradingRouteLabel,
        labelColor: config.route.trading.labelColor,
        labelProps: this.getTradingRouteLabelProps
    };

    selectedTradingRoutesElementProps = {
        coordinates: 'Route',
        color: config.route.tradingSelected.color,
        width: config.route.tradingSelected.width
    };

    tradingPortsElementProps = {
        type: 'port',
        primaryColor: config.tradingPort.primaryColor,
        coordinates: port => wktParse(port.Location),
        isActive: false,
        opacity: 0.8,
        label: 'Name',
        textStroke: true,
        textOnTop: true
    };

    bunkerPortsElementProps = {
        type: 'port',
        primaryColor: config.bunkerPort.primaryColor,
        secondaryColor: this.getBunkerPortSecondaryColor,
        coordinates: port => wktParse(port.Location),
        isActive: this.getBunkerPortActive,
        opacity: this.getBunkerPortOpacity,
        label: port => port.Name.toUpperCase(),
        hideLabel: this.getBunkerPortActive,
        textStroke: true,
        textOnTop: true
    };

    heatMapElementProps = {
        coordinates: mapLonLat
    };

    render() {
        let mapClass = 'sten-map';
        if (!this.props.isRightSideBarCollapsed) {
            mapClass += ' sten-map--pad-right';
        }
        return (
            <div className={mapClass}>
                {this.props.selectedLayerId === 1 && <TradingRouteTooltip />}
                {this.props.selectedLayerId === 2 && <BunkerPricesTooltip />}
                {this.props.selectedLayerId === 3 && this.props.selectedDistributionType === 2 && (
                    <VesselDistributionAreas />
                )}
                <Ol.Map
                    ref={(c) => { this.map = c; }}
                    mapTypeId={this.props.mapTypeId}
                    countryBordersNamesEnabled={this.props.countryBordersNamesEnabled}
                    zoomControlElement={this.state.mapZoomControlElement}
                    onPostRender={this.onMapPostRender}
                    onClick={this.onMapClick}
                >
                    {this.props.selectedLayerId === 1 && (
                        <React.Fragment>
                            <Ol.Layer
                                name="tradingRoutes"
                                type="multiLine"
                                elements={this.props.tradingRoutes}
                                elementProps={this.tradingRoutesElementProps}
                                observes={[this.props.selectedTradingRoutes]}
                                onClick={this.onTradingRouteClick}
                                zIndex={config.route.trading.zIndex}
                            />
                            <Ol.Layer
                                name="selectedTradingRoutes"
                                type="multiLine"
                                elements={this.props.selectedTradingRoutes}
                                elementProps={this.selectedTradingRoutesElementProps}
                                zIndex={config.route.tradingSelected.zIndex}
                            />
                            <Ol.Layer
                                name="tradingPorts"
                                type="point"
                                elements={this.props.tradingPorts}
                                elementProps={this.tradingPortsElementProps}
                                zIndex={config.tradingPort.zIndex}
                            />
                        </React.Fragment>
                    )}
                    {this.props.selectedLayerId === 2 && (
                        <Ol.Layer
                            name="bunkerPorts"
                            type="point"
                            elements={this.props.bunkerPorts}
                            elementProps={this.bunkerPortsElementProps}
                            observes={[
                                this.props.activeBunkerPorts
                            ]}
                            zIndex={config.bunkerPort.zIndex}
                            onClick={this.onBunkerPortClick}
                        />
                    )}
                    {this.props.selectedLayerId === 3 && this.props.selectedDistributionType === 1 && (
                        <Ol.Layer
                            name="heatMap"
                            type="heatMap"
                            elements={this.props.vesselDistributionHeatMap}
                            elementProps={this.heatMapElementProps}
                            layerProps={{
                                gradient: config.heatMap.gradient,
                                blur: 20,
                                radius: 4
                            }}
                            zIndex={config.heatMap.zIndex}
                        />
                    )}
                </Ol.Map>
            </div>
        );
    }
}

MarketIntelMap.propTypes = {
    activeBunkerPorts: PropTypes.arrayOf(PropTypes.number).isRequired,
    bunkerPorts: PropTypes.arrayOf(PropTypes.object).isRequired,
    closeRightSidebar: PropTypes.func.isRequired,
    countryBordersNamesEnabled: PropTypes.bool.isRequired,
    isRightSideBarCollapsed: PropTypes.bool.isRequired,
    mapTypeId: PropTypes.number.isRequired,
    selectedDistributionType: PropTypes.number.isRequired,
    selectedLayerId: PropTypes.number.isRequired,
    selectedTradingRoutes: PropTypes.arrayOf(PropTypes.object).isRequired,
    setBunkerPortPositions: PropTypes.func.isRequired,
    setTradingRouteTooltipPositions: PropTypes.func.isRequired,
    setVesselDistributionAreaPositions: PropTypes.func.isRequired,
    settingUpdated: PropTypes.objectOf(PropTypes.any).isRequired,
    toggleUserSetting: PropTypes.func.isRequired,
    tradingPorts: PropTypes.arrayOf(PropTypes.object).isRequired,
    tradingRoutes: PropTypes.arrayOf(PropTypes.object).isRequired,
    vesselDistributionAreas: PropTypes.arrayOf(PropTypes.object),
    vesselDistributionHeatMap: PropTypes.arrayOf(PropTypes.object).isRequired
};

MarketIntelMap.defaultProps = {
    vesselDistributionAreas: null
};

function mapStateToProps(state) {
    return {
        activeBunkerPorts: state.userReducer.settings && state.userReducer.settings.BunkerPricesSelectedPorts,
        bunkerPorts: state.marketIntelReducer.bunkerPorts,
        countryBordersNamesEnabled: state.userReducer.settings
            && state.userReducer.settings.MarketIntelCountryBordersNamesEnabled,
        mapTypeId: state.userReducer.settings && state.userReducer.settings.MarketIntelMapType,
        selectedDistributionType: state.userReducer.settings && state.userReducer.settings.VesselDistributionType,
        selectedLayerId: ConfigService.featureToggles.showAllMarketIntelFeatures
            ? state.userReducer.settings && state.userReducer.settings.MarketIntelLayerType
            : 3,
        selectedTradingRoutes: getSelectedTradingRoutes(state),
        settingUpdated: state.userReducer.settingUpdated,
        tradingPorts: getTradingPorts(state),
        tradingRoutes: state.marketIntelReducer.tradingRoutes,
        vesselDistributionAreas: state.marketIntelReducer.options.vesselDistributionAreas,
        vesselDistributionHeatMap: state.marketIntelReducer.vesselDistributionHeatMap
    };
}

function mapDispatchToProps(dispatch) {
    return {
        closeRightSidebar: () => emptyActivePanels(dispatch),
        setBunkerPortPositions: positions => setBunkerPortPositions(dispatch, positions),
        setTradingRouteTooltipPositions: positions => setTradingRouteTooltipPositions(dispatch, positions),
        setVesselDistributionAreaPositions: positions => setVesselDistributionAreaPositions(dispatch, positions),
        toggleUserSetting: (settingName, value) => toggleUserSetting(dispatch, settingName, value)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketIntelMap);
