import { takeEvery, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* actions */
import { ActionTypes } from './position-list-compose-actions';
import { ActionTypes as PLActionTypes } from '../position-list-actions';
/* services */
import PositionListService from 'services/core-api/position-list-service';
import CompanyService from 'services/core-api/company-service';

function* sendEmail(action) {
    const email = yield select(state => state.positionListComposeReducer.email);
    const signatures = yield select(state => state.positionListComposeReducer.signatures);
    const { PositionListSignatureCompanyId } = yield select(state => state.userReducer.settings);
    const selectedSignature = signatures.find(signature => signature.Id === PositionListSignatureCompanyId);
    if (email.recipient) {
        const response = yield PositionListService.sendEmail({
            Content: action.content,
            FromName: selectedSignature ? selectedSignature.Name : '',
            Subject: email.subject,
            To: email.recipient,
            Cc: email.cc,
            Bcc: email.bcc,
            Attachments: action.attachments
        });
        if (response) {
            toast(translate('POSITION_LIST.COMPOSE.SEND_EMAIL_SUCCESS'), { type: toast.TYPE.SUCCESS });
            yield put({ type: PLActionTypes.POSITION_LIST_TOGGLE_COMPOSE, flag: false });
        }
    }
}

function* getRecentRecipients() {
    const recentRecipientsEmails = yield PositionListService.getRecentRecipients();
    yield put({ type: ActionTypes.POSITION_LIST_COMPOSE_SET_RECENT_RECIPIENTS, recentRecipientsEmails });
}

function setHighLightedFields(cols) {
    const highlightedFields = [];
    cols.forEach((col) => {
        highlightedFields.push(col.ColumnId);
    });
    return highlightedFields;
}

function* getUpdatedVessels() {
    const selectedVessels = yield select(state => state.positionListReducer.selectedVessels);
    const updated = yield PositionListService.getUpdatedVessels({ imos: selectedVessels });
    const updatedVessels = updated.reduce((values, field) => ({
        ...values,
        [field.IMO]: {
            editedColumns: field.EditedColumns,
            lastModifiedTime: field.LastModifiedTime,
            lastModifiedUser: field.LastModifiedUser,
            highlightedFields: setHighLightedFields(field.EditedColumns)
        }
    }), {});
    yield put({ type: ActionTypes.POSITION_LIST_COMPOSE_SET_UPDATED_VESSELS, updatedVessels });
}

function* updateVesselColumn(action) {
    const updatedColumnForImo = [{
        IMO: action.imo,
        Values: [{
            ColumnId: action.columnId,
            Value: action.value
        }]
    }];
    yield PositionListService.updateColumn(updatedColumnForImo);
    yield getUpdatedVessels();
}

function* resetUpdatedVessels(action) {
    const selectedVessels = yield select(state => state.positionListReducer.selectedVessels);
    const visibleColumns = yield select(state => state.userReducer.settings.PositionsListExportColumns);
    let updateColumnsForImo = [];
    if (typeof action.imo === 'number') {
        updateColumnsForImo = [{
            IMO: action.imo,
            Columns: visibleColumns
        }];
        yield PositionListService.resetUpdatedVessels(updateColumnsForImo);
        toast(translate('POSITION_LIST.COMPOSE.VESSEL_DATA_SET_TO_DEFAULT'), { type: toast.TYPE.SUCCESS });
    } else {
        selectedVessels.forEach(vessel => (
            updateColumnsForImo.push({
                IMO: vessel,
                Columns: visibleColumns
            })
        ));
        yield PositionListService.resetUpdatedVessels(updateColumnsForImo);
        toast(translate('POSITION_LIST.COMPOSE.VESSEL_DATA_SET_TO_DEFAULT'), { type: toast.TYPE.SUCCESS });
    }
    yield getUpdatedVessels();
}

function* getSignatureCompanies() {
    const signatures = yield CompanyService.getPositionListSignatures();
    if (signatures) {
        yield put({ type: ActionTypes.POSITION_LIST_COMPOSE_SET_SIGNATURE_COMPANIES, signatures });
    }
}

export default function* positionListComposeSaga() {
    yield takeEvery(ActionTypes.POSITION_LIST_COMPOSE_SEND_EMAIL, sendEmail);
    yield takeEvery(ActionTypes.POSITION_LIST_COMPOSE_GET_RECENT_RECIPIENTS, getRecentRecipients);
    yield takeEvery(ActionTypes.POSITION_LIST_COMPOSE_UPDATE_VESSEL_COLUMN, updateVesselColumn);
    yield takeEvery(ActionTypes.POSITION_LIST_COMPOSE_GET_UPDATED_VESSELS, getUpdatedVessels);
    yield takeEvery(ActionTypes.POSITION_LIST_COMPOSE_RESET_UPDATED_VESSELS, resetUpdatedVessels);
    yield takeEvery(ActionTypes.POSITION_LIST_COMPOSE_GET_SIGNATURE_COMPANIES, getSignatureCompanies);
}
