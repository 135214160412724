import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { takeEvery, put, all, select } from 'redux-saga/effects';
/* mocks */
import { getMockedVoyageFootprint } from 'components/footprint-table/footprint-table-test-utils';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { getLogoBase64 } from 'utils/base64images';
import { getLogoForCompany } from 'pages/user-pages/energy-management/reports/reports-helpers';
import { mapArrayByProp } from 'utils/helpers/array-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import { ActionTypes } from './voyage-performance-actions';
import { ActionTypes as VRActionTypes } from 'components/vessel-report/vessel-report-actions';
import { getAllReportsAndRows } from 'components/raw-reports-table/raw-reports-table-saga';
import { getEuEts } from 'components/euets/euets-saga';
/* components */
import VoyagePerformancePDF from './export/voyage-performance-pdf';
/* selectors */
import { convertReports, convertRowKeys } from 'components/raw-reports-table/raw-reports-table-selectors';
import { getHiddenFieldsByTypeDSST } from 'components/vessel-report/vessel-report-selectors';
/* services */
import CargoService from 'services/core-api/cargo-service';
import ConfigService from 'services/config-api/config-service';
import EnergyManagementService from 'services/core-api/energy-management-service';
import PdfGeneratorService from 'services/core-api/pdf-generator-service';
import VesselService from 'services/core-api/vessel-service';
import VoyageService from 'services/core-api/voyage-service';
import LocalStorageService from 'services/local-storage-service';

function* getVesselData(action) {
    if (action.vesselImo) {
        const permissions = yield select(state => state.userReducer.permissions);
        const yearlyRatingEnabled = permissions?.VesselFootprint
            && ConfigService.featureToggles.showFootprint
            && !ConfigService.hiddenFeatures.voyagePerformanceVesselCiiRating;
        const res = yield all({
            vesselBase: VesselService.getBaseById(action.vesselImo),
            voyages: permissions?.GetVoyage ? VesselService.getVoyagesPreviewById(action.vesselImo) : null,
            selectedVesselCii: yearlyRatingEnabled && VesselService.getCiiGradePerYears({
                IMO: action.vesselImo,
                endYear: new Date().getFullYear()
            })
        });
        yield put({
            ...res,
            type: ActionTypes.VOYAGE_PERFORMANCE_SET_VESSEL_DATA,
            selectedVesselCii: yearlyRatingEnabled ? mapArrayByProp(res.selectedVesselCii, 'Year') : null
        });
    } else {
        yield put({ type: ActionTypes.VOYAGE_PERFORMANCE_SET_VESSEL_DATA });
    }
}

function* getVoyagePerformanceData(action) {
    if (action.voyageId) {
        const res = yield all({
            consumption: VoyageService.getVoyageConsumptionById(action.voyageId, action.params),
            performance: VoyageService.getVoyagePerformanceById(action.voyageId, action.params)
        });
        yield put({
            type: ActionTypes.VOYAGE_PERFORMANCE_SET_VOYAGE_PERFORMANCE_DATA,
            params: action.params,
            ...res
        });
    } else {
        yield put({ type: ActionTypes.VOYAGE_PERFORMANCE_SET_VOYAGE_PERFORMANCE_DATA, params: action.params });
    }
}

const calculateLatestNoon = (voyageEndDate) => {
    const latestNoon = new Date(voyageEndDate);
    if (latestNoon.getUTCHours() < 12) {
        latestNoon.setUTCDate(latestNoon.getDate() - 1);
    }
    latestNoon.setUTCHours(12);
    latestNoon.setUTCMinutes(0);
    latestNoon.setUTCSeconds(0);
    latestNoon.setUTCMilliseconds(0);
    return latestNoon.toISOString();
};

function* getVoyageAisPoints(voyage) {
    const startRange = {
        startTime: voyage.StartDate,
        endTime: TimeHelper.getDateFromDiff(voyage.StartDate, TimeHelper.units.hour)
    };
    const endRange = {
        startTime: voyage.EndDate,
        endTime: TimeHelper.getDateFromDiff(voyage.EndDate, TimeHelper.units.hour)
    };
    const res = yield all({
        start: VesselService.getHistoricalById(voyage.IMO, startRange),
        end: VesselService.getHistoricalById(voyage.IMO, endRange)
    });
    let voyageAisPoints = null;
    if (res?.start?.Entries?.[0] && res?.end?.Entries?.[0]) {
        voyageAisPoints = [...res?.end?.Entries?.[0].Entries, ...res?.start?.Entries?.[0].Entries];
    }
    return voyageAisPoints;
}

function* getVoyageEuEts(action) {
    const { selectedVoyageAisPoints, selectedVoyageFootprint } = yield select(state => state.voyagePerformanceReducer);
    const selectedVoyageEuEts = yield getEuEts({
        emissions: selectedVoyageFootprint?.TotalEmissions,
        coordinates: selectedVoyageAisPoints,
        year: action?.year || null
    });
    if (selectedVoyageEuEts) {
        yield put({ type: ActionTypes.VOYAGE_PERFORMANCE_SET_VOYAGE_EU_ETS, selectedVoyageEuEts });
    }
}

function* getVoyageFootprint(voyageId) {
    return LocalStorageService.isDebugModeActive()
        ? yield getMockedVoyageFootprint()
        : yield VoyageService.getFootprint(voyageId);
}

function* getVoyageData(action) {
    const voyage = action.voyageId
        ? yield VoyageService.getById(action.voyageId)
        : null;
    yield put({ type: VRActionTypes.VESSEL_REPORT_GET_CONFIGS });
    const params = yield select(state => state.voyagePerformanceReducer.performanceParams);
    if (voyage) {
        const permissions = yield select(state => state.userReducer.permissions);
        const footprintEnabled = permissions?.VesselFootprint && ConfigService.featureToggles.showFootprint;
        const voyageParams = { VoyageId: voyage.VoyageId };

        const res = yield all({
            counts: EnergyManagementService.Reports.VoyageReport.getReportCounts(voyageParams),
            earnings: permissions.GetVoyageEarnings
                && ConfigService.featureToggles.showNetDailyAndCO2PerDollar
                ? VoyageService.getEarnings(voyage.VoyageId)
                : null,
            graphs: VoyageService.getVoyageGraphsById(voyage.VoyageId),
            reports: permissions.GetReports ? VoyageService.getReportsByVoyageId(voyage.VoyageId) : null,
            selectedVoyageAisPoints: ConfigService.featureToggles.showEuEts && getVoyageAisPoints(voyage),
            selectedVoyageFootprint: footprintEnabled ? getVoyageFootprint(voyage.VoyageId) : null,
            selectedVoyageVmsFootprint: ConfigService.featureToggles.showVmsFootprint
                ? VoyageService.getVmsFootprint(voyage.VoyageId)
                : null,
            tankLayout: permissions.GetCargoTanks ? CargoService.getTankLayout(voyage.VoyageId) : null,
            voyage,
            voyagePerformance: getVoyagePerformanceData({ ...action, voyageId: voyage.VoyageId, params })
        });
        const { IMO, StartDate, EndDate } = voyage;
        const results = yield all({
            sensorData: ConfigService.featureToggles.showSensorData
                ? VesselService.getSensorData({
                    imo: IMO,
                    fromDate: StartDate,
                    toDate: calculateLatestNoon(EndDate)
                })
                : null
        });
        yield put({ type: ActionTypes.VOYAGE_PERFORMANCE_SET_VOYAGE_DATA, ...res, ...results });
        yield getVoyageEuEts();
    } else {
        yield getVoyagePerformanceData({ ...action, voyageId: null, params });
        yield put({ type: ActionTypes.VOYAGE_PERFORMANCE_SET_VOYAGE_DATA });
    }
}

const reportTypes = ['daily', 'arrival', 'departure', 'cargo', 'event', 'sof'];

function* prepareRawReportsData(selectedVoyage, selectedVessel) {
    const reportData = yield getAllReportsAndRows({
        VoyageId: selectedVoyage.VoyageId, Imo: selectedVessel.IMO
    });
    const hiddenFieldsByType = yield select(getHiddenFieldsByTypeDSST);
    const rowConfigs = {};
    reportTypes.forEach(rt => {
        rowConfigs[`${rt}Reports`] = convertRowKeys(
            rt, reportData.typesAndGrades[rt], hiddenFieldsByType, false
        );
    });
    return {
        dailyReports: convertReports(reportData.reports.daily, reportData.typesAndGrades.daily),
        arrivalReports: convertReports(reportData.reports.arrival, reportData.typesAndGrades.arrival),
        departureReports: convertReports(reportData.reports.departure, reportData.typesAndGrades.departure),
        cargoReports: convertReports(reportData.reports.cargo, reportData.typesAndGrades.cargo),
        eventReports: convertReports(reportData.reports.event, reportData.typesAndGrades.event),
        sofReports: convertReports(reportData.reports.sof, reportData.typesAndGrades.sof),
        rowConfigs
    };
}

function* generatePDF(action) {
    const {
        performance,
        consumption,
        selectedVesselBase,
        selectedVesselCii,
        selectedVesselVoyages,
        selectedVoyage,
        selectedVoyageCargoSummary,
        selectedVoyageFootprint,
        selectedVoyageEuEts,
        selectedVoyageTankLayout,
        selectedVoyageEarnings,
        selectedVoyageVmsFootprint
    } = yield select(state => state.voyagePerformanceReducer);
    const { euEtsPrice } = yield select(state => state.euEtsReducer);
    const voyage = selectedVoyage
        ? selectedVesselVoyages.find(voyage => voyage.VoyageId === selectedVoyage.VoyageId)
        : null;
    let companyLogo = null;
    if (selectedVoyage.CompanyId) {
        companyLogo = yield getLogoForCompany(selectedVoyage.CompanyId);
    }
    const preparedReportsData = yield prepareRawReportsData(selectedVoyage, selectedVesselBase);
    const reportsData = {
        cargoSummary: selectedVoyageCargoSummary,
        cargoWings: selectedVoyageTankLayout,
        companyLogo,
        consumption,
        earnings: selectedVoyageEarnings,
        euEtsPrice,
        orbitLogo: getLogoBase64().dark,
        performance,
        selectedVesselCii,
        selectedVoyageFootprint,
        selectedVoyageEuEts,
        selectedVoyageVmsFootprint,
        vesselName: selectedVesselBase.Title,
        voyage,
        ...preparedReportsData
    };

    const htmlString = ReactDOMServer.renderToStaticMarkup(
        <VoyagePerformancePDF {...reportsData} isForFootprint={action.isForFootprint} />
    );

    const title = action.isForFootprint
        ? t('VOYAGE_PERFORMANCE.FOOTPRINT_REPORT')
        : t('VOYAGE_PERFORMANCE.TITLE');

    PdfGeneratorService.convert(
        { html: htmlString, viewport: { width: 1920, height: 1080 } },
        `${title}-${reportsData.vesselName}-${reportsData.voyage.VoyageNumber}`
    );
}

export default function* voyagePerformanceSaga() {
    yield takeEvery(ActionTypes.VOYAGE_PERFORMANCE_GET_VESSEL_DATA, getVesselData);
    yield takeEvery(ActionTypes.VOYAGE_PERFORMANCE_GET_VOYAGE_DATA, getVoyageData);
    yield takeEvery(ActionTypes.VOYAGE_PERFORMANCE_GET_VOYAGE_PERFORMANCE_DATA, getVoyagePerformanceData);
    yield takeEvery(ActionTypes.VOYAGE_PERFORMANCE_GET_VOYAGE_EU_ETS, getVoyageEuEts);
    yield takeEvery(ActionTypes.VOYAGE_PERFORMANCE_GENERATE_PDF, generatePDF);
}
