export const ActionTypes = {
    PORT_INFO_PORT_CALLS_GET: 'PORT_INFO_PORT_CALLS_GET',
    PORT_INFO_PORT_CALLS_SET: 'PORT_INFO_PORT_CALLS_SET',
    PORT_INFO_PORT_CALLS_SET_GETTING_DATA: 'PORT_INFO_PORT_CALLS_SET_GETTING_DATA',
    PORT_INFO_CALLS_GET_INITIAL_DATA: 'PORT_INFO_CALLS_GET_INITIAL_DATA',
    PORT_INFO_CALLS_SET_OPTIONS: 'PORT_INFO_CALLS_SET_OPTIONS',
    PORT_INFO_CALLS_UPDATE_FILTER: 'PORT_INFO_CALLS_UPDATE_FILTER',
    PORT_INFO_PORT_CALLS_RESET: 'PORT_INFO_PORT_CALLS_RESET'
};

export function getPortCalls(dispatch, portId, overrideCurrent = false) {
    dispatch({
        type: ActionTypes.PORT_INFO_PORT_CALLS_GET,
        portId,
        overrideCurrent
    });
}

export function getInitialData(dispatch, portId) {
    dispatch({
        type: ActionTypes.PORT_INFO_CALLS_GET_INITIAL_DATA,
        portId
    });
}

export function updateFilter(dispatch, filterType, filterData) {
    dispatch({
        type: ActionTypes.PORT_INFO_CALLS_UPDATE_FILTER,
        filterType,
        filterData
    });
}

export function reset(dispatch) {
    dispatch({
        type: ActionTypes.PORT_INFO_PORT_CALLS_RESET
    });
}
