export const ActionTypes = {
    FLEET_MANAGEMENT_GET_INITIAL_DATA: 'FLEET_MANAGEMENT_GET_INITIAL_DATA',
    FLEET_MANAGEMENT_SET_INITIAL_DATA: 'FLEET_MANAGEMENT_SET_INITIAL_DATA',
    FLEET_MANAGEMENT_GET_ACTIVE_FLEET: 'FLEET_MANAGEMENT_GET_ACTIVE_FLEET',
    FLEET_MANAGEMENT_SET_ACTIVE_FLEET: 'FLEET_MANAGEMENT_SET_ACTIVE_FLEET',
    FLEET_MANAGEMENT_ADD_TO_MY_FLEET: 'FLEET_MANAGEMENT_ADD_TO_MY_FLEET',
    FLEET_MANAGEMENT_REMOVE_FROM_MY_FLEET: 'FLEET_MANAGEMENT_REMOVE_FROM_MY_FLEET',
    FLEET_MANAGEMENT_SET_FLEETS: 'FLEET_MANAGEMENT_SET_FLEETS',
    FLEET_MANAGEMENT_UPDATE_FLEETS: 'FLEET_MANAGEMENT_UPDATE_FLEETS',
    FLEET_MANAGEMENT_UPDATE_VESSEL_IN_FLEET: 'FLEET_MANAGEMENT_UPDATE_VESSEL_IN_FLEET',
    FLEET_MANAGEMENT_RESET_ALL: 'FLEET_MANAGEMENT_RESET_ALL'
};

export function getInitialData(dispatch) {
    dispatch({ type: ActionTypes.FLEET_MANAGEMENT_GET_INITIAL_DATA });
}

export function updateFleets(dispatch, method, fleet) {
    dispatch({
        type: ActionTypes.FLEET_MANAGEMENT_UPDATE_FLEETS,
        method,
        fleet
    });
}

export function addToMyFleet(dispatch, imo) {
    dispatch({
        type: ActionTypes.FLEET_MANAGEMENT_ADD_TO_MY_FLEET,
        imo
    });
}

export function removeFromMyFleet(dispatch, imo) {
    dispatch({
        type: ActionTypes.FLEET_MANAGEMENT_REMOVE_FROM_MY_FLEET,
        imo
    });
}

export function resetAll(dispatch) {
    dispatch({ type: ActionTypes.FLEET_MANAGEMENT_RESET_ALL });
}
