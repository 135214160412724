import moment from 'moment';

const getDateFromDifference = (date, unit, n) => {
    if (unit === 'd') {
        return new Date(date.valueOf + (n * 24 * 3600 * 1000));
    }
    if (unit === 'm') {
        let month = date.getMonth();
        let year = date.getFullYear();
        year += Math.floor((month + n) / 12);
        month = (month + n) % 12 < 0 ? 12 + ((month + n) % 12) : (month + n) % 12;
        return new Date(Date.UTC(year, month, 1));
    }
    return new Date(Date.UTC(date.getFullYear() + n, date.getMonth(), 1));
};

export function generateTimeCategories(length = 24, timeUnit = 'm') {
    const now = new Date();
    const categories = [];
    let category = timeUnit === 'd'
        ? new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()))
        : new Date(Date.UTC(now.getFullYear(), now.getMonth(), 1));
    for (let i = 0; i < length; i++) {
        categories.unshift(category);
        category = getDateFromDifference(category, timeUnit, -1);
    }
    return categories;
}

export function generateSeriesData(categories, length = 1, min = 0, max = 100, nullsPercentage = 0) {
    const series = [];
    let value;
    let row;
    categories.forEach((category) => {
        row = [category];
        for (let i = 0; i < length; i++) {
            value = parseFloat(((Math.random() * (max - min)) + min).toFixed(2));
            if (nullsPercentage > 0 && Math.random() < nullsPercentage) {
                value = null;
            }
            row.push(value);
        }
        series.push(row);
    });
    return series;
}

function getRandomNumber(min, max, distribution = null) {
    let randomNumber;
    if (distribution === null) {
        randomNumber = Math.random();
    } else {
        const r = Math.random() ** 2;
        randomNumber = (Math.random() * r) + (distribution * (1 - r));
    }
    return (randomNumber * (max - min)) + min;
}

export function generateCategorySeriesData(
    categories,
    min = 0,
    max = 100,
    nullsPercentage = 0,
    maxDiff = 0.5,
    distribution = 0.5,
    decimals = 2
) {
    const series = [];
    let value;
    let newValue;
    categories.forEach(() => {
        if (nullsPercentage > 0 && Math.random() < nullsPercentage) {
            value = null;
        } else if (value) {
            newValue = getRandomNumber(min, max, distribution);
            value = parseFloat(((newValue * maxDiff) + (value * (1 - maxDiff))).toFixed(decimals));
        } else {
            value = parseFloat((getRandomNumber(min, max, distribution)).toFixed(decimals));
        }
        series.push(value);
    });
    return series;
}

export function generateChartData(series, length) {
    const chartData = { categories: generateTimeCategories(length), series: [] };
    if (series && series.length > 0) {
        series.forEach((set) => {
            chartData.series.push(generateSeriesData(
                chartData.categories,
                set.length,
                set.min,
                set.max,
                set.nullsPercentage,
                set.maxDiff,
                set.distribution,
                set.decimals
            ));
        });
    }
    return chartData;
}

export function generateCategoryChartData(series, length) {
    const categories = generateTimeCategories(length).map((category) => moment(category).format('MMM YYYY'));
    const chartData = { categories, series: [] };
    if (series && series.length > 0) {
        series.forEach((set) => {
            chartData.series.push(generateCategorySeriesData(
                categories,
                set.min,
                set.max,
                set.nullsPercentage,
                set.maxDiff,
                set.distribution,
                set.decimals
            ));
        });
    }
    return chartData;
}

export function updateYAxisExtremes(chart) {
    chart.yAxis.forEach(yAxis => {
        const min = yAxis.dataMin !== null ? Math.floor(yAxis.dataMin) : null;
        const max = yAxis.dataMax !== null ? Math.ceil(yAxis.dataMax) : null;
        if ((min !== null && min !== yAxis.userMin) || (max !== null && max !== yAxis.userMax)) {
            yAxis.setExtremes(min, max);
        }
    });
}
