import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* components */
import EnergyManagementReportsPreviewMultiVoyageReportTable from './multi-voyage-report-table';
import EnergyManagementReportsPreviewHeader from '../preview-header';
import ScrollArea from 'components/scroll-area/scroll-area';
import SummaryTable from '../../shared/summary-table';
import ContentHeader from '../../shared/content-header';
import EmptyContent from 'components/empty-content/empty-content';
/* selectors */
import { getVoyageNumbers, getReports } from './multi-voyage-report-selectors';
/* actions */
import { generatePDF, generateXLSX } from './multi-voyage-report-actions';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import ReportHelper from 'utils/helpers/report-helper';
import {
    summaryTranslationKeys,
    ballastSummaryKeys,
    ladenSummaryKeys,
    keyToUnitMap,
    keyToNumberOfDecimalsMap
} from './constants';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.${key}`);
const numDecimals = (key) => (typeof keyToNumberOfDecimalsMap[key] === 'undefined' ? 1 : keyToNumberOfDecimalsMap[key]);

let value;
let exclusionFields;
export const transformSummaryToLabelValueArray = (summary, keysOrdered) => keysOrdered.map((key) => {
    exclusionFields = summary.ExlusionFields;
    value = exclusionFields && exclusionFields[key]
        ? <span className="text-secondary">{exclusionFields[key].join('; ')}</span>
        : ReportHelper.formatCellValue(summary[key], false, keyToUnitMap[key], numDecimals(key));
    return ({
        label: t(`MULTI_VOYAGE_REPORT.SUMMARY.${summaryTranslationKeys[key]}`),
        value,
        tooltip: key === 'AverageAux' ? t('MULTI_VOYAGE_REPORT.SUMMARY.AVERAGE_AUXILIARY_DISCLAIMER') : null
    });
});

class MultiVoyageReport extends React.PureComponent {
    renderBallastLadenSummary() {
        const ballastRows = transformSummaryToLabelValueArray(this.props.summaryReport.Ballast, ballastSummaryKeys);
        const ladenRows = transformSummaryToLabelValueArray(this.props.summaryReport.Laden, ladenSummaryKeys);
        return (
            <div className="sten-content__body">
                <div className="flex flex-row flex-row--sm">
                    {this.renderSummaryTable(translate('GLOBAL.BALLAST'), ballastRows)}
                    {this.renderSummaryTable(translate('GLOBAL.LADEN'), ladenRows)}
                </div>
            </div>
        );
    }

    renderSummaryTable = (title, rows) => (
        <div className="col-sm-12 col-pad-xs">
            <div className="sten-panel spread-v--sm">
                <div className="sten-panel__header text-uppercase">{title}</div>
                <div className="sten-panel__body">
                    <SummaryTable rows={rows} />
                </div>
            </div>
        </div>
    );

    render() {
        return (
            <div className="sten-content sten-content--has-footer">
                <ContentHeader />
                {!this.props.summaryReport ? <EmptyContent>{t('EMPTY_REPORT')}</EmptyContent>
                    : (
                        <React.Fragment>
                            <div className="sten-energy-management-reports-preview sten-content__body">
                                <ScrollArea>
                                    <EnergyManagementReportsPreviewHeader />
                                    <div className="sten-content__section">
                                        {this.renderBallastLadenSummary()}
                                    </div>
                                    {this.props.voyageReports.length !== 0 && (
                                        <div className="sten-content__section">
                                            <EnergyManagementReportsPreviewMultiVoyageReportTable
                                                voyageReports={this.props.voyageReports}
                                                voyageNumbers={this.props.voyageNumbers}
                                            />
                                        </div>
                                    )}
                                </ScrollArea>
                            </div>
                            <footer className="sten-content__footer flex-row">
                                <p className="flex-grow text-secondary hide-sm">{t('NOTE_TEXT')}</p>
                                <div className="flex-shrink">
                                    <button onClick={this.props.generatePDF} className="btn btn--primary">
                                        {t('EXPORT_PDF')}
                                    </button>
                                </div>
                                <div className="flex-shrink">
                                    <button onClick={this.props.generateXLSX} className="btn btn--primary">
                                        {t('EXPORT_XLSX')}
                                    </button>
                                </div>
                            </footer>
                        </React.Fragment>
                    )}
            </div>
        );
    }
}

MultiVoyageReport.propTypes = {
    summaryReport: PropTypes.objectOf(PropTypes.any),
    voyageNumbers: PropTypes.arrayOf(PropTypes.number).isRequired,
    voyageReports: PropTypes.arrayOf(PropTypes.object).isRequired,
    generatePDF: PropTypes.func.isRequired,
    generateXLSX: PropTypes.func.isRequired
};

MultiVoyageReport.defaultProps = {
    summaryReport: null
};

function mapStateToProps(state) {
    return {
        voyageReports: getReports(state),
        voyageNumbers: getVoyageNumbers(state),
        summaryReport: state.energyManagementReportsMultiVoyageReportReducer.summaryReport
    };
}

function mapDispatchToProps(dispatch) {
    return {
        generatePDF: () => generatePDF(dispatch),
        generateXLSX: () => generateXLSX(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MultiVoyageReport);
