import React from 'react';
import TextHighlight from 'components/text-highlight/text-highlight';
import PropTypes from 'prop-types';
import './global-search.scss';

export default class GlobalSearchOptionVessel extends React.PureComponent {
    render() {
        return (
            <div
                key={this.props.Imo}
                className={this.props.vesselClass}
            >
                <div className="col-2">
                    <span className="sten-global-search-results__item-icon icon icon-laden" />
                </div>
                <div className="col-22">
                    <h3 className="sten-global-search-results__item-title">
                        <TextHighlight input={this.props.Title} highlight={this.props.searchCriteria} />
                    </h3>
                    <h5 className="sten-global-search-results__item-subtitle">
                        {this.props.VesselSubtitle}
                    </h5>
                </div>
            </div>
        );
    }
}

GlobalSearchOptionVessel.propTypes = {
    Imo: PropTypes.string.isRequired,
    searchCriteria: PropTypes.string.isRequired,
    Title: PropTypes.string,
    vesselClass: PropTypes.string.isRequired,
    VesselSubtitle: PropTypes.string
};

GlobalSearchOptionVessel.defaultProps = {
    Title: '',
    VesselSubtitle: ''
};
