/* constants */
import { chartConfigBaseColors, rem, fsm, getTitle } from './weather-performance-report-chart-constants';

const getConsumptionConfig = (labels, colors, showScrollbarAndXLabels = false) => {
    const config = {
        chart: {
            backgroundColor: chartConfigBaseColors.colorChartBg,
            plotBackgroundColor: chartConfigBaseColors.colorChartBg,
            spacing: [2 * rem, rem, rem, 2.5 * rem],
            resetZoomButton: { theme: { display: 'none' } }
        },
        ...(showScrollbarAndXLabels && { scrollbar: { enabled: true } }),
        xAxis: {
            gridLineColor: chartConfigBaseColors.colorXAxisGridLine,
            gridZIndex: 1,
            tickPixelInterval: 400,
            tickWidth: 0,
            labels: {
                ...(showScrollbarAndXLabels ? { rotation: -60 } : { enabled: false })
            },
            startOnTick: true
        },
        yAxis: {
            minTickInterval: 2,
            tickAmount: 3,
            labels: {
                reserveSpace: false
            },
            title: {
                ...getTitle(fsm),
                text: labels.title
            },
            legend: {
                itemMarginTop: 10 * fsm
            }
        },
        series: [
            {
                type: 'areasplinerange',
                lineWidth: 0,
                data: [],
                zIndex: 0,
                color: chartConfigBaseColors.colorAreaBoundaryFill,
                fillColor: chartConfigBaseColors.colorAreaBoundaryFill,
                showInLegend: false,
                states: { hover: { enabled: false }, inactive: { enabled: false } },
                marker: {
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                connectNulls: true,
                enableMouseTracking: false
            },
            {
                name: labels.calculated,
                type: 'area',
                color: colors.calculated,
                lineWidth: 2 * fsm,
                data: [],
                zIndex: 1,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            },
            {
                name: labels.charterparty,
                type: 'area',
                color: colors.charterparty,
                lineWidth: 2 * fsm,
                data: [],
                zIndex: 1,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            }
        ]
    };
    return config;
};

export default getConsumptionConfig;
