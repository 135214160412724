import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* services */
import AuthService from 'services/auth-service';

import store from './app';

let requestsInProgress = 0;
const expiredSessionToastId = null;

const formatErrorMessage = (error, message) => {
    return `${error.config.method.toUpperCase()} \n"${error.config.url}": \n${message}`;
};

axios.interceptors.request.use((request) => {
    if (!request.preventLoader) {
        requestsInProgress++;
        store.dispatch({ type: 'TOGGLE_LOADER', payload: true });
    }
    return request;
});

axios.interceptors.response.use((response) => {
    if (response.config.loginRequest && expiredSessionToastId) {
        toast.dismiss(expiredSessionToastId);
    }
    if (!response.config.preventLoader) {
        requestsInProgress--;
    }
    if (requestsInProgress <= 0) {
        store.dispatch({ type: 'TOGGLE_LOADER', payload: false });
    }
    return response;
}, (error) => {
    if (!error.config.preventLoader) {
        requestsInProgress--;
    }
    if (requestsInProgress <= 0) {
        store.dispatch({ type: 'TOGGLE_LOADER', payload: false });
    }
    if (error.config.preventErrorHandling) {
        return Promise.reject(error);
    }

    // trying to login with wrong credentials
    if (error.response && error.response.data.error === 'invalid_grant') {
        return Promise.reject(error);
    }

    /* handling generic 400 errors */
    if (error.response && error.response.status === 400 && error.response.data) {
        let message;
        Object.keys(error.response.data).forEach((key) => {
            if (Array.isArray(error.response.data[key])) {
                message = `${key}: ${error.response.data[key].join('; ')}`;
            } else if (typeof error.response.data[key] === 'string') {
                message = `${key}: ${error.response.data[key]}`;
            } else {
                message = t('ERROR.SOME_ERROR_OCCURRED');
            }
            toast(formatErrorMessage(error, message), { type: toast.TYPE.WARNING });
        });
    }

    /* handling unauthorized errors */
    if (error.response && error.response.status === 401) {
        AuthService.login(true);
        return Promise.reject(error);
    }

    /* handling disabled users errors */
    if (error.response && error.response.status === 422) {
        const location = useLocation();
        if (location.pathname !== '/authorization/unauthorized-access') {
            const navigate = useNavigate();
            navigate('/authorization/unauthorized-access');
        }
        return Promise.reject(error);
    }

    /* handling forbidden errors */
    if (error.response && error.response.status === 403) {
        toast(formatErrorMessage(error, t('ERROR.FORBIDDEN')), { type: toast.TYPE.WARNING });
    }

    /* handling 404 errors */
    if (error.response && error.response.status === 404) {
        if (error.response.data && error.response.data.Message) {
            toast(formatErrorMessage(error, error.response.data.Message), { type: toast.TYPE.ERROR });
        } else {
            toast(formatErrorMessage(error, t('ERROR.NOT_FOUND')), { type: toast.TYPE.ERROR });
        }
    }

    /* handling > 404 errors */
    if (error.response && error.response.status > 404
        && error.response.status < 500
        && error.response.status !== 413) {
        if (error.response.data) {
            if (error.response.data.Error && error.response.data.Error.Code) {
                toast(formatErrorMessage(error, error.response.data.Error.Code), { type: toast.TYPE.ERROR });
            } else {
                toast(formatErrorMessage(error, error.response.data), { type: toast.TYPE.ERROR });
            }
        } else {
            toast(formatErrorMessage(error, t('ERROR.SOME_ERROR_OCCURRED')), { type: toast.TYPE.ERROR });
        }
    }

    /* handling >500 errors */
    if (error.response && error.response.status >= 500 && error.response.status < 600) {
        if (error.response.data && error.response.data.Message) {
            toast(formatErrorMessage(error, error.response.data.Message), { type: toast.TYPE.ERROR });
        } else {
            toast(formatErrorMessage(error, t('ERROR.SERVER')), { type: toast.TYPE.ERROR });
        }
    }

    /* handling other errors */
    if ((!error.response || !error.response.status) && error.message) {
        toast(formatErrorMessage(error, error.message), { type: toast.TYPE.ERROR });
    }

    return Promise.reject(error);
});
