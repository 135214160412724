import ApiHelper from './gsm-api';

class GsmDrillService {
    constructor() {
        this.resourceUrlSufix = 'Drillings';
    }

    getAll() {
        return ApiHelper.get(`${this.resourceUrlSufix}`)
            .then(response => response.data, () => null);
    }

    getById(id) {
        return ApiHelper.get(`${this.resourceUrlSufix}/${id}`)
            .then(response => response.data, () => null);
    }
}

export default new GsmDrillService();
