import Excel from 'exceljs';
import { addWorksheet, triggerXLSXDownload } from 'utils/helpers/xlsx-helper';
/* utils */
import TimeHelper from 'utils/helpers/time-helper';
import { columnWidth, tableCellStyle, tableHeaderStyle } from 'utils/helpers/xlsx-styles';
import { translate } from 'utils/i18n/i18n-model';
import { keyToFormatMap } from './constants';

const formatTableCell = (value, format = {}, props = null, colSpan = 1, rowSpan = 1) => ({
    value,
    colSpan,
    rowSpan,
    props: { ...tableCellStyle, ...props, numFmt: format.numFmt || null }
});

const renderHeader = () => {
    return [{
        values: [
            { value: translate('PORT_CALLS_ANALYTICS.VESSEL_NAME'), props: tableHeaderStyle },
            { value: translate('PORT_CALLS_ANALYTICS.IMO'), props: tableHeaderStyle },
            { value: translate('PORT_CALLS_ANALYTICS.COUNTRY'), props: tableHeaderStyle },
            { value: translate('PORT_CALLS_ANALYTICS.PORT_NAME'), props: tableHeaderStyle },
            { value: translate('PORT_CALLS_ANALYTICS.ETA'), props: tableHeaderStyle },
            { value: translate('PORT_CALLS_ANALYTICS.ETD'), props: tableHeaderStyle },
            { value: translate('PORT_CALLS_ANALYTICS.TIME_IN_PORT'), props: tableHeaderStyle },
            { value: translate('PORT_CALLS_ANALYTICS.DRAFT_BEFORE'), props: tableHeaderStyle },
            { value: translate('PORT_CALLS_ANALYTICS.DRAFT_AFTER'), props: tableHeaderStyle },
            { value: translate('PORT_CALLS_ANALYTICS.ACTIVITY'), props: tableHeaderStyle }
            // { value: translate('PORT_CALLS_ANALYTICS.PROBABILITY'), props: tableHeaderStyle }
        ]
    }];
};

const generatePortCallsRows = data => {
    const portCallsRows = [];
    const millisecondsInDay = 86400000;
    let timeInPort;
    let imoCell;
    let vesselNameCell;
    let portCallPoint;
    let portCallCountry;
    let draftBefore;
    let draftAfter;
    const formatSettings = { time: true };
    if (data && data.length && data.length > 0) {
        data.forEach(call => {
            imoCell = formatTableCell(call.Imo, keyToFormatMap.Imo);
            vesselNameCell = formatTableCell(call.VesselName, keyToFormatMap.VesselName);
            if (call.PortCalls && call.PortCalls.length > 0) {
                call.PortCalls.forEach(portCall => {
                    portCallPoint = formatTableCell(
                        portCall.Point ? portCall.Point.Name : null,
                        keyToFormatMap.PortName
                    );
                    portCallCountry = formatTableCell(
                        portCall.Point && portCall.Point.Country ? portCall.Point.Country.CountryName : null,
                        keyToFormatMap.CountryName
                    );
                    draftBefore = formatTableCell(portCall.DraftBefore, keyToFormatMap.DraftBefore);
                    draftAfter = formatTableCell(portCall.DraftAfter, keyToFormatMap.DraftAfter);
                    if (portCall.Activities && portCall.Activities.length > 0) {
                        portCall.Activities.forEach(activity => {
                            timeInPort = (Date.parse(activity.Etd) - Date.parse(activity.Eta)) / millisecondsInDay;
                            portCallsRows.push({
                                values: [
                                    vesselNameCell,
                                    imoCell,
                                    portCallCountry,
                                    portCallPoint,
                                    formatTableCell(
                                        TimeHelper.getFormatted(activity.Eta, formatSettings), keyToFormatMap.Eta
                                    ),
                                    formatTableCell(
                                        TimeHelper.getFormatted(activity.Etd, formatSettings), keyToFormatMap.Etd
                                    ),
                                    formatTableCell(timeInPort, keyToFormatMap.TimeInPort),
                                    draftBefore,
                                    draftAfter,
                                    formatTableCell(activity.Activity, keyToFormatMap.Activity)
                                    // formatTableCell(activity.Probability, keyToFormatMap.Probability)
                                ]
                            });
                        });
                    }
                });
            }
        });
    }
    return portCallsRows;
};

export default function* generatePortCallsAnalyticsXLSX(portCallsAnalyticsData, dateRange) {
    const workbook = new Excel.Workbook();
    const from = TimeHelper.getFormatted(dateRange.from);
    const to = TimeHelper.getFormatted(dateRange.to);
    const sheetConfig = {
        name: 'Port Calls Analytics',
        props: {
            properties: { showGridLines: false },
            views: [{ showGridLines: false }],
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                margins: { left: 0.4, right: 0.4, top: 0.2, bottom: 0.2, header: 0, footer: 0 }
            }
        },
        columnWidth,
        columns: [
            { width: 20 }, // Vessel Name
            { width: 12 }, // IMO
            { width: 30 }, // Country Name
            { width: 25 }, // Port Name
            { width: 20 }, // Eta (UTC)
            { width: 20 }, // Etd (UTC)
            { width: 18 }, // Time In Port
            { width: 16 }, // Draft before
            { width: 16 }, // Draft after
            { width: 16 } // Activity
            // { width: 18 } // Probability
        ],
        rows: [
            ...renderHeader(),
            ...generatePortCallsRows(portCallsAnalyticsData)
        ]
    };

    addWorksheet(workbook, sheetConfig);

    const xls64 = yield workbook.xlsx.writeBuffer({ base64: true });
    return triggerXLSXDownload(`Port Calls Analytics ${from} - ${to}`)(xls64);
}
