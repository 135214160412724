import React from 'react';
import PropTypes from 'prop-types';

/* styles */
import './table-head-cell.scss';

class TableHeadCell extends React.PureComponent {
    render() {
        const props = this.props;
        let headCellClass = 'sten-table-head-cell';
        if (props.className) {
            headCellClass += ` ${props.className}`;
        }
        if (props.sortable) {
            headCellClass += ' sten-table-head-cell--sortable';
        }

        let iconClass = 'icon icon-caret-up';
        if (props.sortOrder) {
            if ((!props.sortOrderInvert && props.sortOrder === 'ASC')
                || (props.sortOrderInvert && props.sortOrder === 'DESC')) {
                iconClass = 'icon icon-caret-up';
                headCellClass += ' sten-table-head-cell--sorted';
            } else {
                iconClass = 'icon icon-caret-down';
                headCellClass += ' sten-table-head-cell--sorted';
            }
        }
        return (
            <th
                style={props.style}
                className={headCellClass}
                onClick={props.sortable ? props.onClick : undefined}
            >
                {props.sortable
                    ? (
                        <div className="flex flex-center">
                            <div className="flex-shrink">
                                {props.children}
                            </div>
                            <div className="flex-grow">
                                <span className={iconClass} />
                            </div>
                        </div>
                    )
                    : props.children
                }
            </th>
        );
    }
}

TableHeadCell.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
    sortOrder: PropTypes.oneOf(['ASC', 'DESC', '']),
    sortOrderInvert: PropTypes.bool,
    sortable: PropTypes.bool,
    style: PropTypes.objectOf(PropTypes.any)
};

TableHeadCell.defaultProps = {
    children: null,
    className: '',
    onClick: undefined,
    sortOrder: '',
    sortOrderInvert: false,
    sortable: false,
    style: null
};

export default TableHeadCell;
