import React from 'react';
import PropTypes from 'prop-types';

const NotificationEmpty = props => (
    <div className="sten-notification sten-notification-empty flex">
        <div className="sten-notification__checkbox col-3 flex flex-center">
            <span className="icon flex-grow icon-check text-center" />
        </div>
        <div className="col-21 flex flex-center">
            <div className="sten-notification__content">
                {props.children}
            </div>
        </div>
    </div>
);

NotificationEmpty.propTypes = {
    children: PropTypes.node.isRequired
};

export default NotificationEmpty;
