import axios from 'axios';
/* services */
import AuthService from 'services/auth-service';

const initialHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json'
};

const formHeaders = {
    'Content-Type': 'application/x-www-form-urlencoded'
};

const pdfHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/pdf'
};

export const httpRequest = (config, isFormRequest, isPublic) => {
    if (isPublic) {
        return axios({ ...config, headers: { ...isFormRequest ? formHeaders : initialHeaders, ...config.headers } });
    }
    return AuthService.userManager.getUser().then(user => {
        const headers = {
            ...isFormRequest ? formHeaders : initialHeaders,
            Authorization: `Bearer ${user.access_token}`
        };
        return axios({ ...config, headers: { ...headers, ...config.headers } });
    });
};

export const createQueryString = (params) => {
    if (!params) {
        return '';
    }
    const paramsKeys = Object.keys(params);
    let queryString = '';
    paramsKeys.forEach((key, index) => {
        if (Array.isArray(params[key])) {
            if (params[key].length > 0) {
                queryString += index === 0 ? '?' : '&';
                params[key].forEach((el, i) => {
                    if (i > 0) {
                        queryString += '&';
                    }
                    queryString += `${encodeURIComponent(`${key}[${i}]`)}=${encodeURIComponent(el)}`;
                });
            }
        } else {
            queryString += index === 0 ? '?' : '&';
            queryString += `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
        }
    });
    return queryString;
};

export const getAppUrl = () => {
    return `${window.location.protocol}//${window.location.hostname}`
        + `${window.location.port ? `:${window.location.port}` : ''}`;
};

class ApiHelper {
    static baseUrl;

    constructor(apiUrl, isPublic = false, serviceBaseUrl) {
        this.apiUrl = apiUrl;
        this.isPublic = isPublic;
        this.serviceBaseUrl = serviceBaseUrl;
        this.domainUrl = window.location.hostname === 'localhost'
            ? process.env.REACT_APP_BASE_URL
            : getAppUrl();
    }

    getBaseApiUrl = () => {
        const serviceBaseUrl = this.serviceBaseUrl && typeof this.serviceBaseUrl === 'function'
            ? this.serviceBaseUrl()
            : this.serviceBaseUrl;
        return `${serviceBaseUrl || ApiHelper.baseUrl || this.domainUrl}/${this.apiUrl}`;
    };

    get(resourceUrl, params = null, preventLoader = false, config) {
        const url = `${this.getBaseApiUrl()}/${resourceUrl}${createQueryString(params)}`;
        return httpRequest({
            method: 'get',
            url,
            preventLoader,
            ...config
        }, false, this.isPublic);
    }

    downloadFile(resourceUrl, params = null, preventLoader = false, config) {
        const url = `${this.getBaseApiUrl()}/${resourceUrl}${createQueryString(params)}`;
        return httpRequest({
            method: 'get',
            url,
            responseType: 'blob',
            preventLoader,
            ...config
        }, false, this.isPublic);
    }

    getPdf(resourceUrl, data, preventLoader = false, config) {
        const url = `${this.getBaseApiUrl()}/${resourceUrl}`;
        return httpRequest({
            method: 'post',
            url,
            headers: pdfHeaders,
            data,
            responseType: 'arraybuffer',
            preventLoader,
            ...config
        }, false, this.isPublic);
    }

    fetchPdf(resourceUrl) {
        const url = `${this.getBaseApiUrl()}/${resourceUrl}`;
        return httpRequest({
            method: 'get',
            url,
            headers: pdfHeaders,
            responseType: 'arraybuffer',
            transformResponse: [(data, ct) => {
                if (ct && ct['content-type'] === 'application/json' && data instanceof ArrayBuffer) {
                    return JSON.parse(String.fromCharCode.apply(
                        null,
                        new Uint8Array(data)
                    ));
                }
                return data;
            }]
        }, false, this.isPublic);
    }

    post(resourceUrl, data, isFormRequest, config) {
        const url = `${this.getBaseApiUrl()}/${resourceUrl}`;
        return httpRequest({
            method: 'post',
            url,
            data,
            ...config
        }, isFormRequest, this.isPublic);
    }

    put(resourceUrl, data, isFormRequest, config) {
        const url = `${this.getBaseApiUrl()}/${resourceUrl}`;
        return httpRequest({
            method: 'put',
            url,
            data,
            ...config
        }, isFormRequest, this.isPublic);
    }

    patch(resourceUrl, data, isFormRequest, config) {
        const url = `${this.getBaseApiUrl()}/${resourceUrl}`;
        return httpRequest({
            method: 'patch',
            url,
            data,
            ...config
        }, isFormRequest, this.isPublic);
    }

    remove(resourceUrl, data, config) {
        const url = `${this.getBaseApiUrl()}/${resourceUrl}`;
        return httpRequest({
            method: 'delete',
            url,
            data,
            ...config
        }, false, this.isPublic);
    }
}

export default ApiHelper;
