import { createSelector } from 'reselect';
/* utils */
import { generateMockedFleetPerformanceData } from './fleet-performance-report-test-utils';
import { convertData } from './fleet-performance-report-helpers';
/* service */
import LocalStorageService from 'services/local-storage-service';

export const getTableData = createSelector(
    state => state.energyManagementReportsFleetPerformanceReportReducer.report,
    (state) => state.energyManagementReportsReducer.vessels,
    (report, vessels) => {
        const data = LocalStorageService.isDebugModeActive()
            ? generateMockedFleetPerformanceData(vessels, 200, 300)
            : report;

        if (!data || !data.Summary || data.Segments.length === 0) {
            return null;
        }
        const convert = convertData();
        const parsedData = {};

        parsedData.Summary = {
            ...data.Summary,
            Total: convert.convertAndExtract(data.Summary.Total)
        };
        parsedData.Segments = data.Segments.map(segment => (
            {
                ...segment,
                Summary: {
                    ...segment.Summary,
                    Total: convert.convertAndExtract(segment.Summary.Total)
                },
                OtherVessels: !segment.OtherVessels ? null : {
                    Summary: {
                        ...segment.OtherVessels.Summary,
                        Total: convert.convertAndExtract(segment.OtherVessels.Summary.Total)
                    },
                    Vessels: segment.OtherVessels.Vessels.map(vessel => convert.convertAndExtract(vessel))
                },
                Fleets: segment.Fleets.map(fleet => ({
                    ...fleet,
                    Summary: {
                        ...fleet.Summary,
                        Total: convert.convertAndExtract(fleet.Summary.Total)
                    },
                    Contracts: fleet.Contracts.map(contract => ({
                        ...contract,
                        Summary: {
                            ...contract.Summary,
                            Total: convert.convertAndExtract(contract.Summary.Total)
                        },
                        Vessels: contract.Vessels.map(vessel => convert.convertAndExtract(vessel))
                    }))
                }))
            }
        ));

        const fuelTypes = convert.getFuelTypes();
        return { fuelTypes, parsedData };
    }
);

export const getPerformancePerVoyageData = createSelector(
    state => state.energyManagementReportsFleetPerformanceReportReducer.performancePerVoyage,
    reportPerVoyage => {
        if (!reportPerVoyage || (!reportPerVoyage.voyages.length && !reportPerVoyage.vesselImo)) {
            return null;
        }
        const convert = convertData();
        const { vesselName, vesselImo } = reportPerVoyage;
        const voyages = reportPerVoyage.voyages.map(voyage => {
            return {
                ...voyage.Summary,
                VoyageNumber: voyage.Voyage.VoyageNumber,
                Total: convert.convertAndExtract(voyage.Summary.Total)
            };
        });

        const fuelTypes = convert.getFuelTypes();
        return { fuelTypes, voyages, vesselName, vesselImo };
    }
);
