export const ActionTypes = {
    ADMIN_VESSEL_MANAGEMENT_SET_MOUNTED: 'ADMIN_VESSEL_MANAGEMENT_SET_MOUNTED',
    ADMIN_VESSEL_MANAGEMENT_TOGGLE_UPDATE_MODE: 'ADMIN_VESSEL_MANAGEMENT_TOGGLE_UPDATE_MODE',
    ADMIN_VESSEL_MANAGEMENT_GET_SEGMENTS: 'ADMIN_VESSEL_MANAGEMENT_GET_SEGMENTS',
    ADMIN_VESSEL_MANAGEMENT_SET_SEGMENTS: 'ADMIN_VESSEL_MANAGEMENT_SET_SEGMENTS',
    ADMIN_VESSEL_MANAGEMENT_GET_TRADING_AREAS: 'ADMIN_VESSEL_MANAGEMENT_GET_TRADING_AREAS',
    ADMIN_VESSEL_MANAGEMENT_SET_TRADING_AREAS: 'ADMIN_VESSEL_MANAGEMENT_SET_TRADING_AREAS',
    ADMIN_VESSEL_MANAGEMENT_GET_VESSEL_TYPES: 'ADMIN_VESSEL_MANAGEMENT_GET_VESSEL_TYPES',
    ADMIN_VESSEL_MANAGEMENT_SET_VESSEL_TYPES: 'ADMIN_VESSEL_MANAGEMENT_SET_VESSEL_TYPES',
    ADMIN_VESSEL_MANAGEMENT_GET_FLEETS: 'ADMIN_VESSEL_MANAGEMENT_GET_FLEETS',
    ADMIN_VESSEL_MANAGEMENT_SET_FLEETS: 'ADMIN_VESSEL_MANAGEMENT_SET_FLEETS',
    ADMIN_VESSEL_MANAGEMENT_SET_ACTIVE_FLEET_ID: 'ADMIN_VESSEL_MANAGEMENT_SET_ACTIVE_FLEET_ID',
    ADMIN_VESSEL_MANAGEMENT_RESET_ALL: 'ADMIN_VESSEL_MANAGEMENT_RESET_ALL',
    ADMIN_VESSEL_MANAGEMENT_GET_STATUSES: 'ADMIN_VESSEL_MANAGEMENT_GET_STATUSES',
    ADMIN_VESSEL_MANAGEMENT_REMOVE_VESSEL_FROM_FLEET: 'ADMIN_VESSEL_MANAGEMENT_REMOVE_VESSEL_FROM_FLEET',
    ADMIN_VESSEL_MANAGEMENT_TABLE_GET_VESSELS: 'ADMIN_VESSEL_MANAGEMENT_TABLE_GET_VESSELS',
    ADMIN_VESSEL_MANAGEMENT_TABLE_ADD_VESSELS: 'ADMIN_VESSEL_MANAGEMENT_TABLE_ADD_VESSELS',
    ADMIN_VESSEL_MANAGEMENT_TABLE_ADD_VESSEL: 'ADMIN_VESSEL_MANAGEMENT_TABLE_ADD_VESSEL',
    ADMIN_VESSEL_MANAGEMENT_TABLE_REMOVE_VESSEL: 'ADMIN_VESSEL_MANAGEMENT_TABLE_REMOVE_VESSEL',
    ADMIN_VESSEL_MANAGEMENT_TABLE_SET_FETCHING_DATA: 'ADMIN_VESSEL_MANAGEMENT_TABLE_SET_FETCHING_DATA',
    ADMIN_VESSEL_MANAGEMENT_TABLE_SET_OFFSET: 'ADMIN_VESSEL_MANAGEMENT_TABLE_SET_OFFSET',
    ADMIN_VESSEL_MANAGEMENT_TABLE_SET_SORT_PROP: 'ADMIN_VESSEL_MANAGEMENT_TABLE_SET_SORT_PROP',
    ADMIN_VESSEL_MANAGEMENT_TABLE_RESET_ALL: 'ADMIN_VESSEL_MANAGEMENT_TABLE_RESET_ALL',
    ADMIN_VESSEL_MANAGEMENT_FILTERS_TOGGLE: 'ADMIN_VESSEL_MANAGEMENT_FILTERS_TOGGLE',
    ADMIN_VESSEL_MANAGEMENT_FILTERS_SET_SELECTED_SEGMENTS: 'ADMIN_VESSEL_MANAGEMENT_FILTERS_SET_SELECTED_SEGMENTS',
    ADMIN_VESSEL_MANAGEMENT_FILTERS_UPDATE_LOADED: 'ADMIN_VESSEL_MANAGEMENT_FILTERS_UPDATE_LOADED',
    ADMIN_VESSEL_MANAGEMENT_FILTERS_UPDATE_SEARCH_CRITERIA: 'ADMIN_VESSEL_MANAGEMENT_FILTERS_UPDATE_SEARCH_CRITERIA',
    ADMIN_VESSEL_MANAGEMENT_FILTERS_RESET_ALL: 'ADMIN_VESSEL_MANAGEMENT_FILTERS_RESET_ALL'
};

export function setMounted(dispatch, isMounted) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_SET_MOUNTED,
        isMounted
    });
}

export function removeVesselFromFleet(dispatch, imo, fleetId) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_REMOVE_VESSEL_FROM_FLEET,
        imo,
        fleetId
    });
}

export function getFleets(dispatch) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_GET_FLEETS
    });
}

export function setActiveFleetId(dispatch, fleetId) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_SET_ACTIVE_FLEET_ID,
        fleetId
    });
}

export function getSegments(dispatch) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_GET_SEGMENTS
    });
}

export function getTradingAreas(dispatch) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_GET_TRADING_AREAS
    });
}

export function getVesselTypes(dispatch) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_GET_VESSEL_TYPES
    });
}

export function toggleUpdateMode(dispatch, vesselImo, isActive) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_TOGGLE_UPDATE_MODE,
        vesselImo,
        isActive
    });
}

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_RESET_ALL
    });
}

export function getVessels(dispatch, fleetId, overrideCurrent, resetFilters = false) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_TABLE_GET_VESSELS,
        fleetId,
        overrideCurrent,
        resetFilters
    });
}

export function setSortProp(dispatch, sortProp) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_TABLE_SET_SORT_PROP,
        sortProp
    });
}

export function setOffset(dispatch, offset) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_TABLE_SET_OFFSET,
        offset
    });
}

export function resetAllTable(dispatch) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_TABLE_RESET_ALL
    });
}

export function setSelectedSegments(dispatch, selectedSegments) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FILTERS_SET_SELECTED_SEGMENTS,
        selectedSegments
    });
}

export function toggleFilters(dispatch) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FILTERS_TOGGLE
    });
}

export function filterUpdateLoaded(dispatch) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FILTERS_UPDATE_LOADED
    });
}

export function updateSearchCriteria(dispatch, searchCriteria) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FILTERS_UPDATE_SEARCH_CRITERIA,
        searchCriteria
    });
}

export function resetAllFilters(dispatch) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FILTERS_RESET_ALL
    });
}
