import CoreApi from './core-api';

class PositionListService {
    constructor() {
        this.resourceUrl = 'PositionsList';
        this.commentsUrl = 'Comments';
    }

    getVessels(params) {
        return CoreApi.get(this.resourceUrl, params).then(response => response.data, () => []);
    }

    getTableColumns() {
        return CoreApi.get(`${this.resourceUrl}/Columns`).then(response => response.data, () => []);
    }

    getGroupings() {
        return CoreApi.get(`${this.resourceUrl}/GroupBy`).then(response => response.data, () => []);
    }

    getVesselInfoConfigurableProperties() {
        return CoreApi.get(`${this.resourceUrl}/VesselProperties`).then(response => response.data, () => []);
    }

    submitNote(note) {
        return CoreApi.post(this.commentsUrl, note).then(response => response.data, () => []);
    }

    updateNote(note) {
        return CoreApi.put(`${this.commentsUrl}/${note.id}`, note).then(response => response.data, () => []);
    }

    removeNote(id) {
        return CoreApi.remove(`${this.commentsUrl}/${id}`).then(() => true, () => false);
    }

    setFixtureStatus(id, params) {
        return CoreApi.put(`${this.resourceUrl}/${id}/FixtureStatus`, params)
            .then(() => true, () => null);
    }

    sendEmail(data) {
        return CoreApi.post(`${this.resourceUrl}/Send`, data).catch(() => null);
    }

    getRecentRecipients() {
        return CoreApi.get(`${this.resourceUrl}/Recipients`).then(response => response.data, () => []);
    }

    getFixtureStatuses() {
        return CoreApi.get(`${this.resourceUrl}/FixtureStatuses`).then(response => response.data, () => []);
    }

    updateColumn(data) {
        return CoreApi.post(`${this.resourceUrl}/SavePositionList`, data).catch(() => null);
    }

    getUpdatedVessels(imoList) {
        return CoreApi.get(`${this.resourceUrl}/EditedValues`, imoList).then(response => response.data, () => null);
    }

    resetUpdatedVessels(data) {
        return CoreApi.remove(`${this.resourceUrl}/DeleteImos`, data)
            .then(response => response.data, () => null);
    }

    deleteLoadReadyDate(id) {
        return CoreApi.remove(`${this.resourceUrl}/LoadReadyDate/${id}`)
            .then(response => response.data, () => null);
    }

    updateLoadReadyDate(IMO, date) {
        return CoreApi.post(`${this.resourceUrl}/LoadReadyDate`, {
            Imo: IMO,
            LoadReadyDate: date
        }).then(response => response.data, () => null);
    }
}

export default new PositionListService();
