import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
/* utils */
import { getClassName } from 'utils/helpers/info-helper';
/* styles */
import './menu-item.scss';

class MenuItem extends React.PureComponent {
    handleItemClick = (event) => {
        if (!this.props.isDisabled && this.props.onClick) {
            this.props.onClick(event);
        }
        if (!this.props.preventMenuClose && !this.props.isDisabled) {
            this.context.toggleMenu(false, event);
        }
        if (this.props.isDisabled) {
            event.preventDefault();
            event.stopPropagation();
        }
    };

    render() {
        const { children, className, icon, isDisabled, linkProps, onClick, title, to } = this.props;
        const classNames = getClassName('sten-menu-item flex flex-center', className, {
            'sten-menu-item--clickable': !isDisabled && (onClick || to),
            'sten-menu-item--disabled': isDisabled
        });
        if (to) {
            return (
                <Link
                    to={to}
                    className={classNames}
                    onClick={this.handleItemClick}
                    title={title}
                    {...linkProps}
                >
                    <div className="sten-menu-item__icon">{icon && <span className={`icon ${icon}`} />}</div>
                    <span className="sten-menu-item__text">{children}</span>
                </Link>
            );
        }
        return (
            <div className={classNames} onClick={this.handleItemClick} title={title}>
                <div className="sten-menu-item__icon">{icon && <span className={`icon ${icon}`} />}</div>
                <div className="sten-menu-item__text">{children}</div>
            </div>
        );
    }
}

MenuItem.contextTypes = {
    toggleMenu: PropTypes.func.isRequired
};

MenuItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    icon: PropTypes.string,
    isDisabled: PropTypes.bool,
    linkProps: PropTypes.objectOf(PropTypes.any),
    onClick: PropTypes.func,
    preventMenuClose: PropTypes.bool,
    title: PropTypes.string,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func])
};

MenuItem.defaultProps = {
    children: null,
    className: '',
    icon: '',
    isDisabled: false,
    linkProps: null,
    onClick: undefined,
    preventMenuClose: false,
    title: '',
    to: null
};

export default MenuItem;
