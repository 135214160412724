import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import { DateShape } from '../types';
import { compareDates, getNthFromMonth, compareMonths } from '../helpers';
import Month from '../shared/month';
import DatePickerPanel from './date-picker-panel';
import PanelSlider from 'components/htec-panel-slider/panel-slider';
import variables from '../variables';
/* styles */
import './date-picker.scss';

export default class DatePickerModeDays extends React.PureComponent {
    handleMonthChange = diff => {
        const nthMonth = getNthFromMonth({ month: this.props.currentMonth, year: this.props.currentYear }, diff);
        this.props.updateMonth(nthMonth.month);
        this.props.updateYear(nthMonth.year);
    };

    getDayProps = args => {
        let isSelected = false;
        if (this.props.selectedDate) {
            isSelected = compareDates(args, this.props.selectedDate) === 0;
        }
        const isDisabled = (this.props.minDate && compareDates(args, this.props.minDate) === -1)
            || (this.props.maxDate && compareDates(args, this.props.maxDate) === 1);
        const isToday = compareDates(args, this.props.today) === 0;
        return {
            isSelected,
            isDisabled,
            isToday
        };
    };

    renderDay = (args) => {
        return this.props.renderDay({
            ...args,
            ...this.getDayProps(args),
            onClick: this.props.onCellClick
        });
    };

    getMonth = ({ month, year }) => {
        return (
            <DatePickerPanel
                key={`${month}${year}`}
                onPanelTitleClick={this.props.onPanelTitleClick}
                title={`${variables.monthNames.long[month]} / ${year}`}
            >
                <Month
                    className="htec-dp-date-picker-mode"
                    renderDay={this.renderDay}
                    month={month}
                    year={year}
                    alwaysShow6Weeks={this.props.alwaysShow6Weeks}
                    hideOutsideDays={this.props.hideOutsideDays}
                    renderWeekDays={this.props.renderWeekDays}
                    weekStart={this.props.weekStart}
                />
            </DatePickerPanel>
        );
    };

    prevBtnDisabled = (diff) => {
        if (!this.props.disableMonthsOutOfRange || !this.props.minDate) {
            return false;
        }
        const current = { month: this.props.currentMonth, year: this.props.currentYear };
        return compareMonths(getNthFromMonth(current, diff - 1), this.props.minDate) === -1;
    };

    nextBtnDisabled = (diff) => {
        if (!this.props.disableMonthsOutOfRange || !this.props.maxDate) {
            return false;
        }
        const current = { month: this.props.currentMonth, year: this.props.currentYear };
        return compareMonths(getNthFromMonth(current, diff + 1), this.props.maxDate) === 1;
    };

    getPanels = (month, year) => [this.getMonth({ month, year })];

    getNextPanel = memoize((month, year) => this.getMonth(getNthFromMonth({ month, year }, 1)));

    getPrevPanel = memoize((month, year) => this.getMonth(getNthFromMonth({ month, year }, -1)));

    render = () => {
        return (
            <PanelSlider
                panels={this.getPanels(this.props.currentMonth, this.props.currentYear)}
                panelsClass="htec-dp-date-picker-mode"
                onPanelChange={this.handleMonthChange}
                nextPanel={this.getNextPanel(this.props.currentMonth, this.props.currentYear)}
                prevPanel={this.getPrevPanel(this.props.currentMonth, this.props.currentYear)}
                nextBtn={this.props.nextBtn}
                prevBtn={this.props.prevBtn}
                nextBtnDisabled={this.nextBtnDisabled}
                prevBtnDisabled={this.prevBtnDisabled}
            />
        );
    }
}

DatePickerModeDays.propTypes = {
    alwaysShow6Weeks: PropTypes.bool,
    currentMonth: PropTypes.number.isRequired,
    currentYear: PropTypes.number.isRequired,
    disableMonthsOutOfRange: PropTypes.bool,
    hideOutsideDays: PropTypes.bool,
    maxDate: PropTypes.shape(DateShape),
    minDate: PropTypes.shape(DateShape),
    nextBtn: PropTypes.node,
    onCellClick: PropTypes.func.isRequired,
    onPanelTitleClick: PropTypes.func.isRequired,
    prevBtn: PropTypes.node,
    renderDay: PropTypes.func,
    renderWeekDays: PropTypes.func,
    selectedDate: PropTypes.shape(DateShape),
    today: PropTypes.shape(DateShape),
    updateMonth: PropTypes.func.isRequired,
    updateYear: PropTypes.func.isRequired,
    weekStart: PropTypes.oneOf(['Monday', 'Sunday'])
};


DatePickerModeDays.defaultProps = {
    alwaysShow6Weeks: Month.defaultProps.alwaysShow6Weeks,
    disableMonthsOutOfRange: false,
    hideOutsideDays: Month.defaultProps.hideOutsideDays,
    maxDate: null,
    minDate: null,
    nextBtn: '►',
    prevBtn: '◄',
    renderDay: Month.defaultProps.renderDay,
    renderWeekDays: Month.defaultProps.renderWeekDays,
    selectedDate: null,
    today: null,
    weekStart: Month.defaultProps.weekStart
};
