import React from 'react';
import PropTypes from 'prop-types';
/* components */
import Checkbox from 'components/checkbox/checkbox';
import TextHighlight from 'components/text-highlight/text-highlight';
import './checkable-list-item.scss';

class CheckableListItem extends React.PureComponent {
    handleClick = (e) => {
        e.preventDefault();
        this.props.onClick(this.props.item);
    };

    render() {
        let titleClassName = 'text-uppercase text-ellipsis text-nowrap';
        if (this.props.titleClassName) {
            titleClassName += ` ${this.props.titleClassName}`;
        }
        const { searchCriteria } = this.props;
        return (
            <div className="btn-section sten-checkable-list-item" onClick={this.handleClick}>
                <div className="flex-row flex-center">
                    <div className="flex-shrink">
                        <Checkbox
                            isChecked={this.props.isChecked}
                            isDisabled={this.props.isDisabled}
                        />
                    </div>
                    <div className="flex-grow">
                        <h3 className={titleClassName}>
                            <TextHighlight
                                input={this.props.title}
                                highlight={searchCriteria}
                            />
                        </h3>
                        {this.props.subtitle && (
                            <p className="text-secondary">
                                <TextHighlight
                                    input={this.props.subtitle}
                                    highlight={searchCriteria}
                                />
                            </p>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

CheckableListItem.propTypes = {
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    item: PropTypes.objectOf(PropTypes.any).isRequired,
    onClick: PropTypes.func.isRequired,
    searchCriteria: PropTypes.string,
    subtitle: PropTypes.string,
    title: PropTypes.string.isRequired,
    titleClassName: PropTypes.string
};

CheckableListItem.defaultProps = {
    isChecked: false,
    isDisabled: false,
    searchCriteria: '',
    subtitle: '',
    titleClassName: ''
};

export default CheckableListItem;
