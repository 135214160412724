import { ActionTypes } from './position-list-compose-actions';

const emptyArray = [];

const initialState = {
    signatures: emptyArray,
    email: {
        subject: 'Position list export',
        recipient: emptyArray,
        cc: emptyArray,
        bcc: emptyArray,
        message: 'Dear Sir/Madam,\nHere is a Position List export for desired vessels.'
    },
    isEmailModalShown: false,
    recentRecipientsEmails: emptyArray,
    updatedVessels: emptyArray
};

export default function positionListComposeReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.POSITION_LIST_COMPOSE_SET_EMAIL_VALUE: {
        if (action.field) {
            const email = {};
            email[action.field] = action.value;
            return {
                ...state,
                email: {
                    ...state.email,
                    ...email
                }
            };
        }
        return {
            ...state
        };
    }
    case ActionTypes.POSITION_LIST_COMPOSE_TOGGLE_MODAL: {
        return {
            ...state,
            isEmailModalShown: action.flag ? action.flag : !state.isEmailModalShown
        };
    }
    case ActionTypes.POSITION_LIST_COMPOSE_SET_RECENT_RECIPIENTS: {
        return {
            ...state,
            recentRecipientsEmails: action.recentRecipientsEmails
        };
    }
    case ActionTypes.POSITION_LIST_COMPOSE_SET_UPDATED_VESSELS: {
        return {
            ...state,
            updatedVessels: action.updatedVessels
        };
    }
    case ActionTypes.POSITION_LIST_COMPOSE_SET_SIGNATURE_COMPANIES: {
        return {
            ...state,
            signatures: action.signatures
        };
    }
    case ActionTypes.POSITION_LIST_COMPOSE_RESET_ALL: {
        return initialState;
    }
    default:
        return state;
    }
}
