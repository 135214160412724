import { sortByProp } from 'utils/helpers/array-helper';

const sortPropMap = {
    VesselName: 'Title',
    Segment: 'Segment.Name',
    CoatingType: 'CoatingType',
    CommercialOperator: 'CommercialOperator.Name'
};

export const sortAndFilterVessels = (vessels, params, filters) => {
    let newVessels;
    if (filters) {
        newVessels = vessels.filter(vessel => {
            if (`${vessel.Title}${vessel.IMO}`.toLowerCase().indexOf(filters.SearchTerm) < 0) {
                return false;
            }
            if (filters.CommercialOperatorIds.length > 0
                && (!vessel.CommercialOperator
                    || !filters.CommercialOperatorIds.some(co => vessel.CommercialOperator.Id === co))) {
                return false;
            }
            if (filters.CompanyFleetIds.length > 0
                && !vessel.CompanyFleets.some(cf => filters.CompanyFleetIds.indexOf(cf.Id) > -1)) {
                return false;
            }
            if (filters.SegmentIds.length > 0
                && (!vessel.Segment || !filters.SegmentIds.some(sg => vessel.Segment.Id === sg))) {
                return false;
            }
            if (filters.TechnicalManagerIds.length > 0
                && (!vessel.TechnicalManager
                    || !filters.TechnicalManagerIds.some(tm => vessel.TechnicalManager.Id === tm))) {
                return false;
            }
            if (filters.VesselOwnerIds.length > 0
                && (!vessel.VesselOwner || !filters.VesselOwnerIds.some(vo => vessel.VesselOwner.Id === vo))) {
                return false;
            }
            return true;
        });
    } else {
        newVessels = [...vessels];
    }
    return newVessels.sort(sortByProp(sortPropMap[params.SortBy] || params.SortBy, params.SortOrder === 'ASC'));
};
