/* ol */
import { unByKey } from 'ol/Observable';

class OlMapHandlers {
    constructor() {
        this.handlers = {};
        this.handlerKeys = {};
        this.lastHoveredFeature = null;
        this.lastHit = false;
    }

    setWindowResizeHandler(callback) {
        this.handlers.updateSize = () => {
            if (this.updateSizeTimeout) {
                clearTimeout(this.updateSizeTimeout);
            }
            this.updateSizeTimeout = setTimeout(() => {
                callback();
            }, 500);
        };
        window.addEventListener('resize', this.handlers.updateSize);
    }

    getHitByTolerance(map, pixel, featureClickListeners) {
        return map.hasFeatureAtPixel(pixel, {
            layerFilter: layer => layer.get('layerName')
                && featureClickListeners[layer.get('layerName')]
                && !layer.get('zeroHitTolerance'),
            hitTolerance: 8
        }) || map.hasFeatureAtPixel(pixel, {
            layerFilter: layer => layer.get('layerName')
                && featureClickListeners[layer.get('layerName')]
                && !!layer.get('zeroHitTolerance'),
            hitTolerance: 0
        });
    }

    getHoveredFeaturesByTolerance(map, pixel) {
        const hoveredFeatures = map.getFeaturesAtPixel(pixel, {
            layerFilter: (layer) => !!layer.get('layerName') && !layer.get('zeroHitTolerance'),
            hitTolerance: 8
        });
        const zeroHitToleranceFeatures = map.getFeaturesAtPixel(pixel, {
            layerFilter: (layer) => !!layer.get('layerName') && !!layer.get('zeroHitTolerance'),
            hitTolerance: 0
        });
        if (zeroHitToleranceFeatures) {
            if (hoveredFeatures) {
                return hoveredFeatures.concat(zeroHitToleranceFeatures);
            }
            return zeroHitToleranceFeatures;
        }
        return hoveredFeatures;
    }

    setHoverHandler(map, layers, featureClickListeners, onMapHover, featureHoverListeners) {
        const viewport = map.getViewport();
        this.handlers.featureHover = (e) => {
            // if (onMapHover && typeof onMapHover === 'function') { onMapHover(e); }
            if (!e.dragging && (!e.frameState || !e.frameState.animate)) {
                const pixel = map.getEventPixel(e.originalEvent);
                const hit = this.getHitByTolerance(map, pixel, featureClickListeners);
                let hoveredFeature = null;

                const hoveredFeatures = this.getHoveredFeaturesByTolerance(map, pixel);
                if (hoveredFeatures && hoveredFeatures.length > 0) {
                    hoveredFeature = hoveredFeatures.reduce((prev, curr) => {
                        if (layers[curr.get('layerName')] && (!prev
                            || layers[prev.get('layerName')].getZIndex() < layers[curr.get('layerName')].getZIndex())) {
                            return curr;
                        }
                        return prev;
                    }, null);
                }

                if (onMapHover && typeof onMapHover === 'function') {
                    const allowHoverOnFeature = onMapHover(e, hoveredFeature);
                    if (allowHoverOnFeature === false) {
                        return;
                    }
                }

                clearTimeout(this.hoverDebounce);

                if (hoveredFeature !== this.lastHoveredFeature) {
                    if (this.lastHoveredFeature) {
                        this.lastHoveredFeature.isHovered = false;
                        if (this.lastHoveredFeature.changed) {
                            this.lastHoveredFeature.changed();
                        } else {
                            layers[this.lastHoveredFeature.get('layerName')].changed();
                        }
                        if (this.lastHoveredFeature.get('layerName')
                            && featureHoverListeners[this.lastHoveredFeature.get('layerName')]) {
                            featureHoverListeners[this.lastHoveredFeature.get('layerName')](
                                e, this.lastHoveredFeature, false
                            );
                        }
                        this.lastHoveredFeature = null;
                    }
                    if (hoveredFeature) {
                        this.hoverDebounce = setTimeout(() => {
                            this.lastHoveredFeature = hoveredFeature;
                            hoveredFeature.isHovered = true;
                            if (hoveredFeature.changed) {
                                hoveredFeature.changed();
                            } else {
                                layers[hoveredFeature.get('layerName')].changed();
                            }
                            if (hoveredFeature.get('layerName')
                                && featureHoverListeners[hoveredFeature.get('layerName')]) {
                                featureHoverListeners[hoveredFeature.get('layerName')](
                                    e, hoveredFeature, true
                                );
                            }
                        }, 25);
                    } else {
                        this.lastHoveredFeature = null;
                    }
                }
                if (hit !== this.lastHit) {
                    this.lastHit = hit;
                    viewport.style.cursor = hit ? 'pointer' : '';
                }
            }
        };
        this.handlers.mouseLeave = (e) => {
            clearTimeout(this.hoverDebounce);
            if (this.lastHoveredFeature) {
                this.lastHoveredFeature.isHovered = false;
                if (this.lastHoveredFeature.changed) {
                    this.lastHoveredFeature.changed();
                } else {
                    layers[this.lastHoveredFeature.get('layerName')].changed();
                }
                if (this.lastHoveredFeature.get('layerName')
                    && featureHoverListeners[this.lastHoveredFeature.get('layerName')]) {
                    featureHoverListeners[this.lastHoveredFeature.get('layerName')](
                        e, this.lastHoveredFeature, false
                    );
                }
                this.lastHoveredFeature = null;
            }
            if (onMapHover && typeof onMapHover === 'function') { onMapHover(e); }
        };

        this.handlerKeys.pointerMove = map.on('pointermove', this.handlers.featureHover);
        viewport.addEventListener('mouseleave', this.handlers.mouseLeave);
    }

    setClickHandler(map, fireClickListeners, resetZoom) {
        this.handlers.middleClick = (e) => {
            if (e.which === 2 || e.button === 4) {
                e.preventDefault();
                resetZoom();
            }
        };
        this.handlers.mouseClick = (e) => {
            const pixel = map.getEventPixel(e.originalEvent);
            const clickedFeatures = map.getFeaturesAtPixel(pixel, { hitTolerance: 8 });
            fireClickListeners(clickedFeatures, e);
        };
        document.addEventListener('mousedown', this.handlers.middleClick);
        this.handlerKeys.singleClick = map.on('singleclick', this.handlers.mouseClick);
    }

    resetPostRenderFlag = () => {
        this.calledPostRenderInThisFrame = false;
    };

    calledPostRenderInThisFrame = false;

    setPostRenderHandler(map, onMapPostRender) {
        this.handlers.postRender = (e) => {
            if (onMapPostRender && !this.calledPostRenderInThisFrame) {
                this.calledPostRenderInThisFrame = true;
                window.requestAnimationFrame(this.resetPostRenderFlag);
                onMapPostRender(e);
            }
        };

        this.handlerKeys.postRender = map.on('postrender', this.handlers.postRender);
    }

    setRenderCompleteHandler(map, onMapRenderComplete) {
        this.handlers.renderComplete = (e) => {
            if (onMapRenderComplete) {
                onMapRenderComplete(e);
            }
        };

        this.handlerKeys.renderComplete = map.on('rendercomplete', this.handlers.renderComplete);
    }

    removeEventHandlers(map) {
        /* resize handlers */
        window.removeEventListener('onresize', this.handlers.updateSize);
        /* move handlers */
        // unByKey(this.handlerKeys.resolutionChange);
        // unByKey(this.handlerKeys.centerChange);
        /* hover handlers */
        unByKey(this.handlerKeys.pointerMove);
        if (map) {
            const viewport = map.getViewport();
            viewport.removeEventListener('mouseleave', this.handlers.mouseLeave);
        }
        /* click handlers */
        document.removeEventListener('mousedown', this.handlers.middleClick);
        unByKey(this.handlerKeys.singleClick);
        /* post render handler */
        unByKey(this.handlerKeys.postRender);
        /* render complete handler */
        unByKey(this.handlerKeys.renderComplete);
    }
}

export default new OlMapHandlers();
