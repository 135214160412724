/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
/* utils */
import TimeHelper from 'utils/helpers/time-helper';
import { translate } from 'utils/i18n/i18n-model';
import { formatNumber } from 'utils/helpers/info-helper';
import stenTableStyles, { border } from 'utils/helpers/pdf-styles/sten-table';
import headings from 'utils/helpers/pdf-styles/headings';
import { getLogoBase64 } from 'utils/base64images';

const styles = `
html {
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
}
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
}
table {
    border-right: ${border};
    border-left: ${border};
}
${stenTableStyles}
.text-danger {
    color: #f44336;
}
.sten-table th {
    white-space: normal;
}
.sten-table--sm td, .sten-table--sm th {
    padding: 6px;
    overflow-wrap: break-word;
}
.page {
    page-break-inside: avoid;
    page-break-after: always;
}`;

const t = (key) => translate(`INSPECTIONS.TABLE.${key}`);

const columnWidths = {
    VesselName: '10%',
    InspectionDate: '12%',
    Type: '4%',
    TechManager: '12%',
    Port: '15%',
    InspectedBy: '11%',
    Observation: '3%',
    Comment: '13%',
    Activity: '8%',
    Owner: '12%'
};

function breakRowsJS() {
    const footer = document.getElementById('sten-inspections-pdf__footer');
    const averagesText = document.getElementById('sten-inspections-pdf__footer').innerHTML;
    footer.remove();
    const originalTable = document.getElementById('sten-inspections-pdf-table');
    const mmToPxRatio = document.documentElement.offsetWidth / 277;
    const pageHeightInPx = Math.round(190 * mmToPxRatio);
    const tbody = originalTable.getElementsByTagName('tbody')[0];
    const trs = tbody.getElementsByTagName('tr');
    const originalThead = originalTable.getElementsByTagName('thead')[0];
    const zoomRatio = 277 / 297;
    const tHeadHeight = originalThead.offsetHeight * zoomRatio;
    let dim;
    // A4 210 × 297mm
    // A4 with margins 190 x 277mm
    const tbodyHeightLimitWithoutTitleAndHeader = pageHeightInPx - tHeadHeight; // 1297px
    const firstPageHeaderHeight = 128 * zoomRatio;
    let tbodyHeightLimit = tbodyHeightLimitWithoutTitleAndHeader - firstPageHeaderHeight;

    // collect row heights
    const rowHeights = new Array(trs.length);
    // let dim;
    for (let trIndex = 0; trIndex < trs.length; trIndex++) {
        dim = trs[trIndex].getBoundingClientRect();
        rowHeights[trIndex] = dim.height * zoomRatio;
    }

    // let nextRow;
    // creating a page to be used as a model later for cloning pages
    const newBody = document.createDocumentFragment();
    const tableShallowClone = originalTable.cloneNode(false);
    const thead = originalTable.getElementsByTagName('thead')[0].cloneNode(true);
    tableShallowClone.appendChild(thead);
    const tbodyShallowClone = originalTable.getElementsByTagName('tbody')[0].cloneNode(false);
    tableShallowClone.appendChild(tbodyShallowClone);
    const page = document.createElement('div');
    page.classList.add('page');
    page.appendChild(tableShallowClone);
    // mark page breaks in a table
    originalTable.style.display = 'none';
    let isFirstPage = true;
    let heightSoFar = rowHeights[0];
    for (let trIndex = 1; trIndex < trs.length; trIndex++) {
        // nextRow = trs[trIndex];
        heightSoFar += rowHeights[trIndex];
        if (heightSoFar > tbodyHeightLimit) {
            trs[trIndex - 1].classList.add('page-break');
            heightSoFar = rowHeights[trIndex];
            if (isFirstPage) {
                tbodyHeightLimit = tbodyHeightLimitWithoutTitleAndHeader;
                isFirstPage = false;
            }
        }
    }

    let currentTr;
    let pageClone = page.cloneNode(true);
    let tbodyRef = pageClone.getElementsByTagName('tbody')[0];
    for (let trIndex = 0; trIndex < trs.length; trIndex++) {
        currentTr = trs[trIndex];
        tbodyRef.appendChild(currentTr.cloneNode(true));
        if (currentTr.classList.contains('page-break')) {
            newBody.appendChild(pageClone);
            pageClone = page.cloneNode(true);
            tbodyRef = pageClone.getElementsByTagName('tbody')[0];
        }
    }

    newBody.appendChild(pageClone);
    document.body.appendChild(newBody);
    const lastBody = document.querySelectorAll('.page:last-child');
    const footerElement = document.createElement('div');
    footerElement.style.marginTop = '20px';
    footerElement.innerHTML = averagesText;
    lastBody[0].appendChild(footerElement);
}

class InspectionsPDF extends React.PureComponent {
    renderHeaderInfo() {
        return (
            <div
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '20px' }}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h1 style={headings.h1}>{translate('INSPECTIONS.TITLE')}</h1>
                </div>
                <img style={{ height: '5rem', marginTop: '20px' }} src={getLogoBase64().dark} alt="pdf-placeholder" />
            </div>
        );
    }

    getTableHeader = () => {
        return (
            <tr>
                <td style={{ width: columnWidths.VesselName }}>{t('VESSEL')}</td>
                <td style={{ width: columnWidths.InspectionDate }}>{t('INSPECTION_DATE')}</td>
                <td style={{ width: columnWidths.Type }}>{t('TYPE')}</td>
                <td style={{ width: columnWidths.Port }}>{t('PORT')}</td>
                <td style={{ width: columnWidths.InspectedBy }}>{t('INSPECTED_BY')}</td>
                <td style={{ width: columnWidths.Observation }}>{t('OBS_NO')}</td>
                <td style={{ width: columnWidths.Activity }}>{t('ACTIVITY')}</td>
                <td style={{ width: columnWidths.Comment }}>{t('COMMENT')}</td>
                <td style={{ width: columnWidths.TechManager }}>{t('TECHNICAL_MANAGER')}</td>
                <td style={{ width: columnWidths.Owner }}>{t('OWNER')}</td>
            </tr>
        );
    };

    getTableBody = () => this.props.selectedInspections.map((selectedInspection) => (
        <tr key={selectedInspection.Id}>
            <td style={{ width: columnWidths.VesselName }}>
                {selectedInspection.Vessel.Title}
            </td>
            <td style={{ width: columnWidths.InspectionDate }}>
                {selectedInspection.Date
                    ? (
                        <React.Fragment>
                            <span className={selectedInspection.IsInAlert ? 'text-danger' : ''}>
                                {TimeHelper.getFormatted(selectedInspection.Date, { utc: true })}
                            </span>
                            &nbsp;({moment.utc(selectedInspection.Date).fromNow()})
                        </React.Fragment>
                    )
                    : '-'
                }
            </td>
            <td style={{ width: columnWidths.Type }}>
                {selectedInspection.Type ? selectedInspection.Type : '-'}
            </td>
            <td style={{ width: columnWidths.Port }}>
                {selectedInspection.Port ? selectedInspection.Port : '-'}
            </td>
            <td style={{ width: columnWidths.InspectedBy }}>
                {selectedInspection.Company ? selectedInspection.Company : '-'}
            </td>
            <td style={{ width: columnWidths.Observation }}>
                {typeof selectedInspection.Observation === 'number' ? selectedInspection.Observation : '-'}
            </td>
            <td style={{ width: columnWidths.Activity }}>
                {selectedInspection.Activity ? selectedInspection.Activity : '-'}
            </td>
            <td style={{ width: columnWidths.Comment }}>
                {selectedInspection.Comment ? selectedInspection.Comment : '-'}
            </td>
            <td style={{ width: columnWidths.TechManager }}>
                {selectedInspection.TechnicalManager ? selectedInspection.TechnicalManager.Name : '-'}
            </td>
            <td style={{ width: columnWidths.Owner }}>
                {selectedInspection.Owner ? selectedInspection.Owner.Name : '-'}
            </td>
        </tr>
    ));

    render() {
        const obs = this.props.averages;
        const sireText = obs.sireInspectionsTotalCount ? translate('INSPECTIONS.OBSERVATION_STATS', {
            avg: typeof obs.sireInspectionsAvg === 'number'
                ? formatNumber(obs.sireInspectionsAvg)
                : translate('GLOBAL.UNKNOWN'),
            count: obs.sireInspectionsTotalCount,
            type: 'SIRE'
        }) : '';
        const cdiText = obs.cdiInspectionsTotalCount ? translate('INSPECTIONS.OBSERVATION_STATS', {
            avg: typeof obs.cdiInspectionsAvg === 'number'
                ? formatNumber(obs.cdiInspectionsAvg)
                : translate('GLOBAL.UNKNOWN'),
            count: obs.cdiInspectionsTotalCount,
            type: 'CDI'
        }) : '';
        const summaryText = `${translate('INSPECTIONS.AVERAGE_OBSERVATION')} ${cdiText} ${sireText}`;
        return (
            <html lang="en">
                <head><style>{styles}</style></head>
                <body>
                    {this.renderHeaderInfo()}
                    <table
                        id="sten-inspections-pdf-table"
                        className="sten-table sten-table--sm"
                        style={{ width: '100%', tableLayout: 'fixed' }}
                    >
                        <thead>{this.getTableHeader()}</thead>
                        <tbody>{this.getTableBody()}</tbody>
                    </table>
                    <div style={{ visibility: 'none' }} id="sten-inspections-pdf__footer">{summaryText}</div>
                    <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{ __html: `(${breakRowsJS.toString()})();` }}
                    />
                </body>
            </html>
        );
    }
}
InspectionsPDF.propTypes = {
    averages: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedInspections: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default InspectionsPDF;
