import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* router */
import { TRouter, withRouter } from 'app-router';
/* Components */
import GsmMap from './gsm-map/gsm-map';
import GsmRightSideBar from './gsm-right-side-bar/gsm-right-side-bar';
import GsmMapLegend from './gsm-map-legend/gsm-map-legend';
import GsmMapViewSwitch from './gsm-map-view-switch/gsm-map-view-switch';
import GsmRightSideBarSecondary from './gsm-right-side-bar-secondary/gsm-right-side-bar-secondary';
/* Actions */
import {
    getFerries,
    getFerryRoutes,
    getSelectedCity,
    getSelectedDrill,
    getSelectedFerry,
    getSelectedTanker,
    getTankers,
    removeSelectedCity,
    removeSelectedDrill,
    removeSelectedFerry,
    removeSelectedTanker,
    removeSelectedVessel,
    removeVisibleAddresses,
    resetAppUi
} from './gsm-actions';

import {
    setDrillsLayerVisibility,
    setFerriesLayerVisibility,
    setFerryRoutesLayerVisibility,
    setOfficesLayerVisibility,
    setTankersLayerVisibility
} from './gsm-map-legend/gsm-map-legend-actions';

/* Consts */
const minute = 60000;

const validQueryParams = ['tanker', 'ferry', 'drill', 'city'];

export class Gsm extends React.PureComponent {
    // Lifecycles
    constructor(props) {
        super(props);

        this.mapUpdateIntervalLength = 5 * minute;
        this.resetAppUiTimoutValue = 5 * minute;
    }

    componentDidMount() {
        this.updateGsmReducer(this.props, true);

        this.mapUpdateInterval = setInterval(this.updateMapData, this.mapUpdateIntervalLength);
        this.clearUrlOfUnknownQueryParams(this.props);
    }

    componentWillUnmount() {
        clearInterval(this.mapUpdateInterval);
        clearTimeout(this.resetAppUiTimeout);
    }

    // App logic
    updateMapData = () => {
        this.props.getTankers();
        this.props.getFerries();
    };

    clearUrlOfUnknownQueryParams(props) {
        const queryParams = { ...props.query };
        const queryKeys = Object.keys(queryParams);
        const queryKeysLength = queryKeys.length;
        const remove = [];
        for (let i = 0; i < queryKeysLength; i++) {
            const matchingParam = validQueryParams.find(allowedParam => allowedParam === queryKeys[i]);
            if (!matchingParam) {
                delete queryParams[queryKeys[i]];
                remove.push(queryKeys[i]);
            }
        }
        if (remove.length) {
            this.props.router.updateQueryParams({ remove });
        }
    }

    componentDidUpdate(prevProps) {
        this.updateGsmReducer(prevProps);
    }

    updateGsmReducer = (prevProps, isInitial) => {
        /* Tanker */
        if (this.props.queryParams.tanker) {
            let tankerId = null;
            if (isInitial || this.props.queryParams.tanker !== prevProps.queryParams.tanker) {
                tankerId = this.props.queryParams.tanker;
            }
            if (tankerId) {
                this.props.getSelectedTanker(tankerId);
            }
        } else if (this.props.selectedTankerId) {
            this.props.removeSelectedTanker();
            this.props.removeSelectedVessel();
        }
        /* City */
        if (this.props.queryParams.city) {
            let cityId = null;
            if (isInitial || this.props.queryParams.city !== prevProps.queryParams.city) {
                cityId = this.props.queryParams.city;
            }
            if (cityId) {
                this.props.getSelectedCity(cityId);
            }
        } else if (this.props.selectedCityId) {
            this.props.removeSelectedCity();
            this.props.removeVisibleAddresses();
        }
        /* Ferry */
        if (this.props.queryParams.ferry) {
            let ferryId = null;
            if (isInitial || this.props.queryParams.ferry !== prevProps.queryParams.ferry) {
                ferryId = this.props.queryParams.ferry;
            }
            if (ferryId) {
                this.props.getSelectedFerry(ferryId);
            }
        } else if (this.props.selectedFerryId) {
            this.props.removeSelectedFerry();
            this.props.removeSelectedVessel();
        }
        /* Drill */
        if (this.props.queryParams.drill) {
            let drillId = null;
            if (isInitial || this.props.queryParams.drill !== prevProps.queryParams.drill) {
                drillId = this.props.queryParams.drill;
            }
            if (drillId) {
                this.props.getSelectedDrill(drillId);
            }
        } else if (this.props.selectedDrillId) {
            this.props.removeSelectedDrill();
            this.props.removeSelectedVessel();
        }
    };

    globalClick = () => {
        clearTimeout(this.resetAppUiTimeout);
        this.resetAppUiTimeout = setTimeout(() => {
            this.props.resetAppUi();
        }, this.resetAppUiTimoutValue);
    };

    // Render
    render() {
        return (
            <div
                className="gsm-page"
                onMouseMove={this.globalClick}
                onTouchEnd={this.globalClick}
            >
                <GsmMap queryParams={this.props.queryParams} />
                <GsmMapLegend queryParams={this.props.queryParams} />
                <GsmRightSideBar />
                <GsmRightSideBarSecondary />
                <GsmMapViewSwitch />
            </div>
        );
    }
}

Gsm.propTypes = {
    getFerries: PropTypes.func.isRequired,
    getFerryRoutes: PropTypes.func.isRequired,
    getSelectedCity: PropTypes.func.isRequired,
    getSelectedDrill: PropTypes.func.isRequired,
    getSelectedFerry: PropTypes.func.isRequired,
    getSelectedTanker: PropTypes.func.isRequired,
    getTankers: PropTypes.func.isRequired,
    queryParams: PropTypes.shape({
        tanker: PropTypes.number,
        ferry: PropTypes.number,
        drill: PropTypes.number,
        city: PropTypes.number
    }).isRequired,
    removeSelectedCity: PropTypes.func.isRequired,
    removeSelectedDrill: PropTypes.func.isRequired,
    removeSelectedFerry: PropTypes.func.isRequired,
    removeSelectedTanker: PropTypes.func.isRequired,
    removeSelectedVessel: PropTypes.func.isRequired,
    removeVisibleAddresses: PropTypes.func.isRequired,
    resetAppUi: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    selectedCityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedDrillId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedFerryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedTankerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setDrillsLayerVisibility: PropTypes.func.isRequired,
    setFerriesLayerVisibility: PropTypes.func.isRequired,
    setFerryRoutesLayerVisibility: PropTypes.func.isRequired,
    setOfficesLayerVisibility: PropTypes.func.isRequired,
    setTankersLayerVisibility: PropTypes.func.isRequired
};

Gsm.defaultProps = {
    selectedCityId: null,
    selectedDrillId: null,
    selectedFerryId: null,
    selectedTankerId: null
};

function mapStateToProps(state) {
    return {
        selectedCityId: state.gsmReducer.selectedCityId,
        selectedDrillId: state.gsmReducer.selectedDrillId,
        selectedFerryId: state.gsmReducer.selectedFerryId,
        selectedTankerId: state.gsmReducer.selectedTankerId
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getFerries: () => getFerries(dispatch),
        getFerryRoutes: () => getFerryRoutes(dispatch),
        getSelectedCity: cityId => getSelectedCity(dispatch, cityId),
        getSelectedDrill: drillId => getSelectedDrill(dispatch, drillId),
        getSelectedFerry: ferryId => getSelectedFerry(dispatch, ferryId),
        getSelectedTanker: tankerId => getSelectedTanker(dispatch, tankerId),
        getTankers: () => getTankers(dispatch),
        removeSelectedCity: () => removeSelectedCity(dispatch),
        removeSelectedDrill: () => removeSelectedDrill(dispatch),
        removeSelectedFerry: () => removeSelectedFerry(dispatch),
        removeSelectedTanker: () => removeSelectedTanker(dispatch),
        removeSelectedVessel: () => removeSelectedVessel(dispatch),
        removeVisibleAddresses: () => removeVisibleAddresses(dispatch),
        resetAppUi: () => resetAppUi(dispatch),
        setDrillsLayerVisibility: drillsLayerStatus => setDrillsLayerVisibility(dispatch, drillsLayerStatus),
        setFerriesLayerVisibility: ferriesLayerStatus => setFerriesLayerVisibility(dispatch, ferriesLayerStatus),
        setFerryRoutesLayerVisibility: ferryRoutesLayerStatus =>
            setFerryRoutesLayerVisibility(dispatch, ferryRoutesLayerStatus),
        setOfficesLayerVisibility: officesLayerStatus => setOfficesLayerVisibility(dispatch, officesLayerStatus),
        setTankersLayerVisibility: tankersLayerStatus => setTankersLayerVisibility(dispatch, tankersLayerStatus)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Gsm));
