import { takeEvery, put, select, all } from 'redux-saga/effects';
/* actions */
import { ActionTypes } from './reports-actions';
import { ActionTypes as VRActionTypes } from './preview/voyage-report/voyage-report-actions';
import { ActionTypes as MVRActionTypes } from './preview/multi-voyage-report/multi-voyage-report-actions';
import {
    ActionTypes as FASRActionTypes
} from './preview/fleet-average-speed-report/fleet-average-speed-report-actions';
import { ActionTypes as PSRActionTypes } from './preview/performance-summary-report/performance-summary-actions';
import { ActionTypes as FPRActionTypes } from './preview/fleet-performance-report/fleet-performance-report-actions';
import { ActionTypes as QRActionTypes } from './preview/quarterly-report/quarterly-report-actions';
import { ActionTypes as FERActionTypes } from './preview/fleet-list-report/fleet-list-report-actions';
import { ActionTypes as WPRActionTypes } from './preview/weather-performance-report/weather-performance-report-actions';
/* Services */
import EnergyManagementService from 'services/core-api/energy-management-service';
import SearchService from 'services/core-api/search-service';
import VesselService from 'services/core-api/vessel-service';
import ContactListService from 'services/core-api/contact-list-service';
import FleetService from 'services/core-api/fleet-service';
import FleetManagementService from 'services/core-api/fleet-management-service';
/* helpers */
import { getCustomFleets } from 'utils/helpers/fleet-helper';

const routeNameMap = {
    1: 'VoyageReport',
    4: 'MultiVoyageReport',
    9: 'FleetSpeedReport',
    2: 'PerformanceSummaryReport',
    10: 'FleetPerformanceReport',
    11: 'QuarterlyReport',
    12: 'FleetListReport',
    13: 'WeatherPerformanceReport'
};

function* getReportTypes() {
    const reportTypes = yield EnergyManagementService.Reports.getReportTypes();
    if (reportTypes) {
        yield put({
            type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_TYPES,
            reportTypes: reportTypes.map(reportType => ({
                ...reportType,
                RouteName: routeNameMap[reportType.Id]
            }))
        });
    }
}

function* getCompanies() {
    const companies = yield ContactListService.getCompanies();
    if (companies) {
        yield put({ type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_COMPANIES, companies });
    }
}

function* getInclusionPresets(action) {
    if (action && action.reportType) {
        const presets = yield EnergyManagementService.Reports.Inclusions.getPresetsByType({
            reportType: action.reportType.Id
        });
        yield put({ type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_INCLUSION_PRESETS, presets });
    }
}

function* getInclusions(action) {
    const { selectedReportType } = yield select(state => state.energyManagementReportsReducer);
    let inclusions = null;
    if (action.preset && action.preset.Id) {
        inclusions = yield EnergyManagementService.Reports.Inclusions.getPresetByType(selectedReportType.RouteName, {
            companyId: action.preset.Id
        });
        yield put({ type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_INCLUSIONS, inclusions });
    }
}

function* generatePreview(action) {
    const { selectedReportType } = yield select(state => state.energyManagementReportsReducer);
    if (selectedReportType && selectedReportType.Id) {
        switch (selectedReportType.Id) {
        case 1: // Voyage Report
            yield put({
                type: VRActionTypes.ENERGY_MANAGEMENT_VOYAGE_REPORT_GENERATE,
                isRecalculate: action.isRecalculate
            });
            break;
        case 4: // Multi-voyage Report
            yield put({ type: MVRActionTypes.ENERGY_MANAGEMENT_MULTI_VOYAGE_REPORT_GENERATE });
            break;
        case 9: // Fleet Speed Report
            yield put({ type: FASRActionTypes.ENERGY_MANAGEMENT_FLEET_AVERAGE_SPEED_REPORT_GENERATE });
            break;
        case 2: // Performance Summary Report
            yield put({ type: PSRActionTypes.ENERGY_MANAGEMENT_PERFORMANCE_SUMMARY_REPORT_GENERATE });
            break;
        case 10: // Fleet Performance Report
            yield put({ type: FPRActionTypes.ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_GENERATE });
            break;
        case 11: // Quarterly Report
            yield put({ type: QRActionTypes.ENERGY_MANAGEMENT_QUARTERLY_REPORT_GENERATE });
            break;
        case 12: // Fleet report
            yield put({ type: FERActionTypes.ENERGY_MANAGEMENT_FLEET_LIST_REPORT_GENERATE });
            break;
        case 13: // Weather performance report
            yield put({ type: WPRActionTypes.ENERGY_MANAGEMENT_WEATHER_PERFORMANCE_REPORT_GENERATE });
            break;
        default:
        }
        yield put({
            type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_PREVIEW_GENERATED,
            generatedReportType: selectedReportType,
            isSidebarVisible: false
        });
    } else {
        yield put({ type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_PREVIEW_GENERATED, isGenerated: false });
    }
}

function* getVesselFilteringOptions() {
    const res = yield all({
        segments: VesselService.getSegmentsByScope(),
        vessels: SearchService.getVesselsByScope({
            FilterLNGVessels: true
        }),
        vesselsForFleetList: SearchService.getVesselsForFleetListByScope(),
        fleets: FleetService.get()
    });

    yield put({
        type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_SEGMENT_VESSELS_FLEETS,
        ...res
    });

    const permissions = yield select(state => state.userReducer.permissions);
    const fleetRequests = [];
    if (permissions.ShowFleetManagementPrivateGroups) {
        fleetRequests.push(FleetManagementService.getPrivate());
    }
    if (permissions.ShowFleetManagementPublicGroups) {
        fleetRequests.push(FleetManagementService.getPublic());
    }
    const vesselFleetsResponses = yield all(fleetRequests);
    const customFleets = getCustomFleets(vesselFleetsResponses, true);
    if (customFleets) {
        yield put({ type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_CUSTOM_FLEETS, customFleets });
    }
}

export default function* energyManagementReportsSaga() {
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_REPORTS_GET_VESSEL_FILTERING_OPTIONS, getVesselFilteringOptions);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_REPORTS_GET_TYPES, getReportTypes);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_REPORTS_GET_COMPANIES, getCompanies);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_TYPE, getInclusionPresets);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_INCLUSION_PRESET, getInclusions);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_REPORTS_GENERATE_PREVIEW, generatePreview);
}
