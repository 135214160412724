import React from 'react';
import moment from 'moment/moment';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { formatCoordinates, formatNumber } from 'utils/helpers/info-helper';
import { renderYesNo, renderDateField, getVesselStatus } from 'utils/helpers/template-helper';
import TimeHelper from 'utils/helpers/time-helper';

const inspectionLimits = {
    SIRE: -5,
    CDI: -11
};

const getInspectionClassName = (limitMonths) => (value) => {
    const earliestDate = moment.utc().startOf('d').add(limitMonths, 'M').toISOString();
    return value && value < earliestDate ? 'text-danger' : '';
};

export const vesselOverviewRows = [
    {
        label: t('VESSEL_INFO.OVERVIEW.STATUS'),
        getValue: (data) => getVesselStatus(
            data.vesselBase.CurrentAis?.NavStatus || '',
            data.vesselBase.IsLaden,
            data.vesselBase.BallastLadenSource,
            true
        )
    },
    {
        label: t('VESSEL_INFO.OVERVIEW.POSITION'),
        valueKey: 'vesselBase.Builder',
        renderValue: (data) => {
            if (data.vesselPosition?.CurrentAis) {
                return (
                    <label className="sten-panel__label">
                        <span className="sten-panel__label--value">
                            {formatCoordinates(
                                data.vesselPosition.CurrentAis.Longitude,
                                data.vesselPosition.CurrentAis.Latitude
                            )}
                        </span>
                        {` (updated ${moment.utc(data.vesselPosition.PositionTime).fromNow()})`}
                    </label>
                );
            }
            return <label key="coordinates" className="sten-panel__label sten-panel__label--value">-</label>;
        }
    },
    {
        label: t('VESSEL_INFO.OVERVIEW.TIME_ONBOARD'),
        getValue: (data) => {
            if (data.vesselPosition) {
                const vesselTime = data.isNowSelected ? moment() : moment(data.vesselPosition.RequestTime);
                vesselTime.utcOffset(data.vesselPosition.LocationOffsetMinutes);
                return TimeHelper.getFormattedWithOffset(
                    vesselTime,
                    data.vesselPosition.LocationOffsetMinutes,
                    { utc: false, time: true }
                );
            }
            return null;
        }
    },
    {
        label: t('VESSEL_INFO.OVERVIEW.NEXT_PORT'),
        getValue: (data) => (data.reportData?.NextPoint
            ? data.reportData?.NextPoint.Name
            : data.reportData?.NextPort
        ),
        getBadge: () => 'NR',
        shouldRender: (data) => !!(data.reportData?.NextPort || data.reportData?.NextPoint)
    },
    {
        label: t('VESSEL_INFO.OVERVIEW.DESTINATION'),
        getValue: (data) => (data.aisData?.NextPoint ? data.aisData?.NextPoint.Name : data.aisData?.Destination),
        getBadge: () => 'AIS',
        shouldRender: (data) => !(data.reportData?.NextPort || data.reportData?.NextPoint) && !!data.aisData
    },
    {
        label: t('VESSEL_INFO.OVERVIEW.ETA'),
        getValue: (data) => {
            if (!data.reportData?.ETA && !data.aisData?.ETA) {
                return null;
            }
            return data.reportData?.ETA
                ? TimeHelper.getFormatted(data.reportData?.ETA, { utc: true, time: true })
                : TimeHelper.getFormatted(data.aisData?.ETA, { utc: true, time: true });
        },
        getBadge: (data) => (data.reportData?.ETA ? 'NR' : 'AIS')
    },
    {
        label: t('VESSEL_INFO.OVERVIEW.DRAFT'),
        renderValue: (data) => {
            if (data.vesselPosition?.Draught) {
                return (
                    <label className="sten-panel__label">
                        <span className="sten-panel__label--value">
                            {`${formatNumber(data.vesselPosition.Draught)} ${t('UNITS.DRAFT')}`}
                        </span>
                        {data.vesselSensorData?.Draft
                            && ` (${t('VESSEL_INFO.ENERGY.SENSOR')}:`
                            + `${formatNumber(data.vesselSensorData.Draft)} ${t('UNITS.DRAFT')})`
                        }
                    </label>
                );
            }
            return <label key="coordinates" className="sten-panel__label sten-panel__label--value">-</label>;
        }
    },
    {
        label: t('VESSEL_INFO.LAST_SIRE_DATE'),
        renderValue: renderDateField('vesselDetails.LastSireDate', getInspectionClassName(inspectionLimits.SIRE)),
        shouldRender: (data) => !!data.vesselDetails
    },
    {
        label: t('VESSEL_INFO.LAST_CDI_DATE'),
        renderValue: renderDateField('vesselDetails.LastCDIDate', getInspectionClassName(inspectionLimits.CDI)),
        shouldRender: (data) => !!data.vesselDetails
    },
    {
        label: t('VESSEL_INFO.LAST_HULL_CLEANING'),
        renderValue: renderDateField('vesselDetails.LastHullCleaning'),
        shouldRender: (data) => !!data.vesselDetails
    },
    {
        label: t('VESSEL_INFO.REDELIVERY_DATE'),
        renderValue: renderDateField('vesselDetails.RedeliveryDate'),
        shouldRender: (data) => !!data.vesselDetails
    },
    {
        label: t('VESSEL_INFO.LAST_DRY_DOCK_DATE'),
        renderValue: renderDateField('vesselDetails.LastDryDockDate'),
        shouldRender: (data) => !!data.vesselDetails
    },
    {
        label: t('VESSEL_INFO.NEXT_DRY_DOCK_DATE'),
        renderValue: renderDateField('vesselDetails.NextDryDockDate'),
        shouldRender: (data) => !!data.vesselDetails
    },
    {
        label: t('VESSEL_INFO.OVERVIEW.LAST_UPDATED_BY'),
        valueKey: 'selectedVoyage.LastUpdatedBy',
        shouldRender: (data) => !!data.selectedVoyage && data.permissions?.ShowCommercialOperatorName
    },
    {
        label: t('VESSEL_INFO.OVERVIEW.LAST_UPDATED'),
        getValue: (data) => (data.selectedVoyage?.LastUpdated
            ? TimeHelper.getFormatted(data.selectedVoyage.LastUpdated, { utc: true, time: true })
            : null
        ),
        shouldRender: (data) => !!data.selectedVoyage && data.permissions?.ShowCommercialOperatorName
    },
    { label: t('VESSEL_INFO.OVERVIEW.CHARTERED_BY'), valueKey: 'selectedVoyageCharterer' }
];

export const vesselOverviewCargoRows = [
    {
        label: t('VESSEL_INFO.OVERVIEW.CHARTERPARTY_DATE'),
        getValue: (data) => (data.cargo?.CharterpartyDate
            ? TimeHelper.getFormatted(data.cargo.CharterpartyDate, { utc: true })
            : null
        )
    },
    { label: t('VESSEL_INFO.OVERVIEW.CHARTERER'), valueKey: 'fixture.ChartererAlias' },
    { label: t('VESSEL_INFO.OVERVIEW.COMMODITY'), valueKey: 'cargo.Commodity' },
    {
        label: t('VESSEL_INFO.OVERVIEW.QUANTITY'),
        getValue: (data) => formatNumber(data.cargo.Quantity, 0),
        unit: t('UNITS.CARGO')
    }
];

export const vesselSpecificationRows = [
    { label: t('VESSEL_INFO.VESSEL.DELIVERED'), valueKey: 'vesselBase.Delivered' },
    { label: t('VESSEL_INFO.VESSEL.BUILDER'), valueKey: 'vesselBase.Builder' },
    { label: t('VESSEL_INFO.VESSEL.FLAG'), valueKey: 'vesselBase.Flag' },
    { label: t('VESSEL_INFO.VESSEL.PORT_OF_REGISTRY'), valueKey: 'vesselBase.PortofRegistry' },
    { label: t('VESSEL_INFO.VESSEL.CALL_SIGN'), valueKey: 'vesselBase.CallSign' },
    { label: t('VESSEL_INFO.VESSEL.IMO_NUMBER'), valueKey: 'vesselBase.IMO' },
    { label: t('VESSEL_INFO.VESSEL.MMSI'), valueKey: 'vesselBase.MMSI' },
    { label: t('VESSEL_INFO.VESSEL.SCRUBBER'), getValue: (data) => renderYesNo(data.vesselBase.HasScrubber) },
    {
        label: t('VESSEL_INFO.VESSEL.SCRUBBER_ACTIVE_FROM'),
        getValue: ({ vesselBase }) => (vesselBase.HasScrubber && vesselBase.ScrubberInstallationDate
            ? TimeHelper.getFormatted(vesselBase.ScrubberInstallationDate, { utc: true })
            : null)
    },
    { label: t('VESSEL_INFO.VESSEL.SHIP_TYPE'), valueKey: 'vesselBase.ShipType' },
    { label: t('VESSEL_INFO.VESSEL.CLASSIFICATION'), valueKey: 'vesselBase.ClassNotation' },
    {
        label: t('VESSEL_INFO.LAST_SIRE_DATE'),
        renderValue: renderDateField('vesselDetails.LastSireDate', getInspectionClassName(inspectionLimits.SIRE)),
        shouldRender: (data) => !!data.vesselDetails
    },
    {
        label: t('VESSEL_INFO.LAST_CDI_DATE'),
        renderValue: renderDateField('vesselDetails.LastCDIDate', getInspectionClassName(inspectionLimits.CDI)),
        shouldRender: (data) => !!data.vesselDetails
    },
    {
        label: t('VESSEL_INFO.LAST_HULL_CLEANING'),
        renderValue: renderDateField('vesselDetails.LastHullCleaning'),
        shouldRender: (data) => !!data.vesselDetails
    },
    {
        label: t('VESSEL_INFO.REDELIVERY_DATE'),
        renderValue: renderDateField('vesselDetails.RedeliveryDate'),
        shouldRender: (data) => !!data.vesselDetails
    },
    {
        label: t('VESSEL_INFO.LAST_DRY_DOCK_DATE'),
        renderValue: renderDateField('vesselDetails.LastDryDockDate'),
        shouldRender: (data) => !!data.vesselDetails
    },
    {
        label: t('VESSEL_INFO.NEXT_DRY_DOCK_DATE'),
        renderValue: renderDateField('vesselDetails.NextDryDockDate'),
        shouldRender: (data) => !!data.vesselDetails
    },
    { label: t('VESSEL_INFO.VESSEL.LOA'), valueKey: 'vesselBase.Loa', unit: t('UNITS.LOA') },
    { label: t('VESSEL_INFO.VESSEL.BEAM'), valueKey: 'vesselBase.Beam', unit: t('UNITS.BEAM') },
    {
        label: t('VESSEL_INFO.VESSEL.BALLAST_OPTIMUM_TRIM'),
        getValue: (data) => formatNumber(data.vesselBase.BallastOptimumTrim, 1),
        unit: t('UNITS.TRIM')
    },
    {
        label: t('VESSEL_INFO.VESSEL.LADEN_OPTIMUM_TRIM'),
        getValue: (data) => formatNumber(data.vesselBase.LadenOptimumTrim, 1),
        unit: t('UNITS.TRIM')
    },
    {
        label: t('VESSEL_INFO.VESSEL.TOTAL_CUBIC_CAPACITY'),
        getValue: (data) => formatNumber(data.vesselBase.TotalCubicCapacity, 0),
        unit: t('UNITS.CUBIC_CAPACITY')
    },
    {
        label: t('VESSEL_INFO.VESSEL.DWT'),
        getValue: (data) => formatNumber(data.vesselBase.DWT, 0),
        unit: t('UNITS.DWT')
    },
    {
        label: t('VESSEL_INFO.VESSEL.TOTAL_SLOP_TANK_CAPACITY'),
        getValue: (data) => formatNumber(data.vesselBase.TotalSlopTankCapacity, 0),
        unit: t('UNITS.CUBIC_CAPACITY')
    },
    {
        label: t('VESSEL_INFO.VESSEL.TPC'),
        getValue: (data) => formatNumber(data.vesselBase.TPC, 1),
        unit: t('UNITS.TPC')
    },
    { label: t('VESSEL_INFO.VESSEL.COATING_TYPE'), valueKey: 'vesselBase.CoatingType' }
];
