/* utils */
import { t } from 'utils/i18n/i18n-model';

export const arrivalReportFieldsAndSections = [
    {
        fields: [
            'Vessel',
            'CallSign',
            'VoyageNumber',
            'CharterpartyDate',
            'ReportDate',
            'Position',
            'CargoStatus',
            'VesselStatus'
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.SINCE_LAST_EVENT'),
        sections: [
            {
                fields: [
                    'TimeElapsedSinceLastEvent',
                    'TimeAtAnchorSinceLastEvent',
                    'HoursSteamedSinceLastEvent',
                    'DistanceSinceLastEvent',
                    'WindForceSinceLastEvent',
                    'TotalCargoSinceLastEvent'
                ]
            }
        ]
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.CONSUMPTIONS_SINCE_LAST_EVENT'),
        condition: (p) => ((p.isEditing && p.editingOptions.consumptionTypes)
            || (p.data.ConsumptionsSinceLastEvent && p.data.ConsumptionsSinceLastEvent.rows.length)),
        data: 'ConsumptionsSinceLastEvent'
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.PORT_INFORMATION'),
        sections: [
            {
                fields: [
                    'PreviousPort',
                    'Point',
                    'EndOfSeaPassage',
                    'Terminal',
                    'EstimatedTimeOfBerthing',
                    'AverageSpeed',
                    'TimeOfPassage',
                    'DistanceFromLastPort',
                    'FreePratiqueGranted',
                    'ArrivingPilotStation',
                    'CargoOperations',
                    'NoticeOfReadiness',
                    'AnchoredDate',
                    'DraftFwd',
                    'DraftMid',
                    'DraftAft',
                    'Displacement',
                    'EstimatedTimeOfDepartureFromBerth',
                    'AverageRpm'
                ]
            }
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.REMARKS'),
        sections: [
            {
                inline: false,
                fields: ['Remarks']
            }
        ]
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.BUNKER_DATA'),
        condition: (p) => ((p.isEditing && p.editingOptions.bunkerTypes.length)
            || (p.data.BunkerData && p.data.BunkerData.rows.length)),
        data: 'BunkerData'
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.CONSUMPTIONS'),
        condition: (p) => ((p.isEditing && p.editingOptions.consumptionTypes)
            || (p.data.Consumptions && p.data.Consumptions.rows.length)),
        data: 'Consumptions'
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.ENGINEERING'),
        condition: (p) => p.data.AuroraReportData
            && (p.data.AuroraReportData.Water
                || (p.data.AuroraReportData.ExtractedEquipmentItems
                    && (p.data.AuroraReportData.ExtractedEquipmentItems.MainTurbine
                        || p.data.AuroraReportData.ExtractedEquipmentItems.PropulsionMotor
                        || p.data.AuroraReportData.ExtractedEquipmentItems.MainEngine))),
        sections: [
            {
                data: 'AuroraReportData.ExtractedEquipmentItems.MainTurbine',
                condition: (p) => p.data.AuroraReportData.ExtractedEquipmentItems
                    && p.data.AuroraReportData.ExtractedEquipmentItems.MainTurbine
            },
            {
                data: 'AuroraReportData.ExtractedEquipmentItems.PropulsionMotor',
                condition: (p) => p.data.AuroraReportData.ExtractedEquipmentItems
                    && p.data.AuroraReportData.ExtractedEquipmentItems.PropulsionMotor
            },
            {
                data: 'AuroraReportData.ExtractedEquipmentItems.MainEngine',
                condition: (p) => p.data.AuroraReportData.ExtractedEquipmentItems
                    && p.data.AuroraReportData.ExtractedEquipmentItems.MainEngine
            },
            { data: 'AuroraReportData.Water', condition: (p) => p.data.AuroraReportData.Water }
        ]
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.CARGO'),
        condition: (p) => p.data.AuroraReportData && p.data.AuroraReportData.Temperatures,
        sections: [
            { data: 'AuroraReportData.Temperatures' },
            {
                fields: [
                    'AuroraReportData.CargoUsed.ForceUsed',
                    'AuroraReportData.CargoUsed.Hours'
                ]
            }
        ]
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.CARGO_CONTAINMENT'),
        condition: (p) => p.data.AuroraReportData
            && (p.data.AuroraReportData.BarrierSpaceConditions || p.data.AuroraReportData.CofferdamTemps),
        sections: [
            { data: 'AuroraReportData.BarrierSpaceConditions' },
            { data: 'AuroraReportData.CofferdamTemps' }
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.OTHER_INFO'),
        sections: [
            {
                fields: [
                    'AuroraReportData.VoyageBoilOff',
                    'AuroraReportData.MainEngineMiles',
                    'AuroraReportData.DistanceThroughIce',
                    'AuroraReportData.CpWarrantedConsumption',
                    'AuroraReportData.Trim',
                    'AuroraReportData.TrimType',
                    'AuroraReportData.ElectricalLoad'
                ]
            }
        ]
    }
];
