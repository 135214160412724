import { takeEvery, put, select, all } from 'redux-saga/effects';
/* actions */
import { ActionTypes } from './operators-diary-actions';
/* services */
import OperatorsDiaryService from 'services/core-api/operators-diary-service';

let latestDiaryParams = null;

function* getDiary(action) {
    if (action.params) {
        latestDiaryParams = action.params;
    }
    if (latestDiaryParams) {
        const diary = yield OperatorsDiaryService.get(latestDiaryParams);
        yield put({ type: ActionTypes.OPERATORS_DIARY_SET, diary });
    }
}

function* getFleets() {
    const permissions = yield select(state => state.userReducer.permissions);
    return permissions.GetOperatorsDiaryVesselGroups ? yield OperatorsDiaryService.getVesselGroups() : null;
}

function* getTags() {
    const tags = yield OperatorsDiaryService.getTags();
    if (tags && tags.length > 0) {
        const leg = tags.filter(tag => tag.EntityName === 'Leg');
        const fixture = tags.filter(tag => tag.EntityName === 'Fixture');
        const voyage = tags.filter(tag => tag.EntityName === 'Voyage');
        return { leg, fixture, voyage };
    }
    return null;
}

function* getOptions() {
    const res = yield all({
        fleets: getFleets(),
        tags: getTags(),
        etbStatuses: OperatorsDiaryService.getEtbStatuses()
    });
    yield put({ type: ActionTypes.OPERATORS_DIARY_SET_OPTIONS, ...res });
}

export default function* operatorsDiarySaga() {
    yield takeEvery(ActionTypes.OPERATORS_DIARY_GET, getDiary);
    yield takeEvery(ActionTypes.OPERATORS_DIARY_GET_OPTIONS, getOptions);
    yield takeEvery(ActionTypes.OPERATORS_DIARY_VOYAGE_UPDATED, getDiary);
}
