import React from 'react';
/* types */
import { TEnergyManagementReportsQueryParams } from '../reports-types';
/* components */
import CharterpartyForm from 'components/charterparty-form/charterparty-form';
import VesselReport from 'components/vessel-report/vessel-report';
import RightSideBar from 'components/right-side-bar/right-side-bar';

const panelNames = {
    VesselReport: 'vessel-report',
    CharterpartyForm: 'charterparty-form'
};

export class EnergyManagementReportsRightSideContainer extends React.PureComponent {
    state = {
        activePanelName: null
    };

    static getDerivedStateFromProps(props, state) {
        const { cpVoyage, reportId, reportType } = props.queryParams;
        if (state.activePanelName !== panelNames.VesselReport && reportId && reportType) {
            return { activePanelName: panelNames.VesselReport };
        }
        if (state.activePanelName !== panelNames.CharterpartyForm && cpVoyage) {
            return { activePanelName: panelNames.CharterpartyForm };
        }
        if (state.activePanelName && !cpVoyage && !reportId && !reportType) {
            return { activePanelName: '' };
        }
        return null;
    }

    render() {
        const { cpEdit, cpVoyage, reportEdit, reportId, reportType } = this.props.queryParams;
        return (
            <RightSideBar isCollapsed={!this.state.activePanelName} className="sten-right-side-container">
                {this.state.activePanelName === panelNames.VesselReport && reportId && reportType && (
                    <VesselReport
                        areBunkerTypeUnitsShown
                        isEditing={reportEdit}
                        activeReportId={reportId}
                        activeReportType={reportType}
                        reloadWhenFinished={false}
                        closeOnCancel
                        closeOnSubmit
                    />
                )}
                {this.state.activePanelName === panelNames.CharterpartyForm && cpVoyage && (
                    <CharterpartyForm
                        voyageId={cpVoyage}
                        isEditing={cpEdit}
                        closeOnCancel
                        closeOnSubmit
                    />
                )}
            </RightSideBar>
        );
    }
}

EnergyManagementReportsRightSideContainer.propTypes = {
    queryParams: TEnergyManagementReportsQueryParams.isRequired
};

export default EnergyManagementReportsRightSideContainer;
