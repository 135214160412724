import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
/* utils */
import { getClassName } from 'utils/helpers/info-helper';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
import PureWrapper from 'components/scroll-area/pure-wrapper';
/* styles */
import './fixed-header-table.scss';

class FixedHeaderTable extends React.PureComponent {
    state = {
        headerColumn: null,
        headerRow: null,
        headerSeparator: null,
        footerRow: null,
        footerSeparator: null,
        scrollAreaOffset: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0
        }
    };

    scheduledActions = [];

    componentDidMount() {
        this.updateHeaders();
        this.resizeObserver = new ResizeObserver(this.updateHeaderSizes);
        this.resizeObserver.observe(this.wrapper);
    }

    componentDidUpdate(prevProps) {
        if (this.props.children !== prevProps.children) {
            this.updateHeaders(this.props);
        }
    }

    componentWillUnmount() {
        this.resizeObserver.unobserve(this.wrapper);
    }

    mapFirstColChildren = (children) => {
        let skipChildren = 0;
        /* eslint-disable react/no-array-index-key */
        return React.Children.map(children, (child) => {
            if (skipChildren > 0 || !child) {
                skipChildren--;
                return null;
            }
            if (child.props && child.props.children) {
                const firstChild = child.props.children[0] || child.props.children;
                if (firstChild.type === 'th'
                    || firstChild.type === 'td'
                    || (this.props.customFirstChild
                        && firstChild.type
                        && this.props.customFirstChild === firstChild.type)) {
                    if (firstChild.props.rowSpan && firstChild.props.rowSpan > 1) {
                        skipChildren = parseInt(firstChild.props.rowSpan, 10) - 1;
                        return React.cloneElement(
                            child,
                            { key: child.key },
                            [React.cloneElement(firstChild, { rowSpan: 1, key: 'headerCell' })]
                        );
                    }
                    return React.cloneElement(
                        child,
                        { key: child.key },
                        [React.cloneElement(firstChild, { key: 'headerCell' })]
                    );
                }
                return React.cloneElement(child, { key: child.key }, this.mapFirstColChildren(child.props.children));
            }
            return child;
        });
        /* eslint-enable react/no-array-index-key */
    };

    createHeaderColumn(props) {
        if (!props.withHeaderColumn) {
            return;
        }
        const tableClone = React.cloneElement(
            props.children,
            {
                className: `${props.children.props.className} sten-fixed-header-table__header-column`,
                ref: (c) => { this.headerColumn = c; }
            },
            this.mapFirstColChildren(props.children.props.children)
        );
        this.setState({ headerColumn: tableClone }, this.updateHeaderColumnSizes);
    }

    saveHeaderRowRef = (c) => { this.headerRow = c; };

    createHeaderRow(props) {
        if (!props.withHeaderRow) {
            return;
        }
        const clonedHeaderRow = props.mapHeaderRow(props.children);
        if (this.clonedHeaderRow !== clonedHeaderRow) {
            this.clonedHeaderRow = clonedHeaderRow;
            const tableClone = React.cloneElement(
                props.children,
                {
                    className: `${props.children.props.className} sten-fixed-header-table__header-row`,
                    ref: this.saveHeaderRowRef
                },
                props.mapHeaderRow(props.children)
            );
            this.setState({ headerRow: tableClone }, this.updateHeaderRowSizes);
        } else {
            this.setState({}, this.updateHeaderRowSizes);
        }
    }

    createHeaderSeparator(props) {
        if (!props.withHeaderColumn || !props.withHeaderRow) {
            return;
        }
        if (this.clonedHeaderSeparator !== props.mapHeaderRow(props.children)) {
            this.clonedHeaderSeparator = props.mapHeaderRow(props.children);
            const tableClone = React.cloneElement(
                props.children,
                {
                    className: `${props.children.props.className} sten-fixed-header-table__header-separator`,
                    ref: (c) => { this.headerSeparator = c; }
                },
                this.mapFirstColChildren([props.mapHeaderRow(props.children)])
            );
            this.setState({ headerSeparator: tableClone }, this.updateHeaderSeparatorSizes);
        } else {
            this.setState({}, this.updateHeaderSeparatorSizes);
        }
    }

    saveFooterRowRef = (c) => { this.footerRow = c; };

    createFooterRow(props) {
        if (!props.withFooterRow) {
            return;
        }
        const clonedFooterRow = props.mapFooterRow(props.children);
        if (this.clonedFooterRow !== clonedFooterRow) {
            this.clonedFooterRow = clonedFooterRow;
            const tableClone = React.cloneElement(
                props.children,
                {
                    className: `${props.children.props.className} sten-fixed-header-table__footer-row`,
                    ref: this.saveFooterRowRef
                },
                props.mapFooterRow(props.children)
            );
            this.setState({ footerRow: tableClone }, this.updateFooterRowSizes);
        } else {
            this.setState({}, this.updateFooterRowSizes);
        }
    }

    createFooterSeparator(props) {
        if (!props.withHeaderColumn || !props.withFooterRow) {
            return;
        }
        if (this.clonedFooterSeparator !== props.mapFooterRow(props.children)) {
            this.clonedFooterSeparator = props.mapFooterRow(props.children);
            const tableClone = React.cloneElement(
                props.children,
                {
                    className: `${props.children.props.className} sten-fixed-header-table__footer-separator`,
                    ref: (c) => { this.footerSeparator = c; }
                },
                this.mapFirstColChildren([props.mapFooterRow(props.children)])
            );
            this.setState({ footerSeparator: tableClone }, this.updateFooterSeparatorSizes);
        } else {
            this.setState({}, this.updateFooterSeparatorSizes);
        }
    }

    updateHeaders = (props = this.props) => {
        this.createHeaderColumn(props);
        this.createHeaderRow(props);
        this.createHeaderSeparator(props);
        this.createFooterRow(props);
        this.createFooterSeparator(props);
    };

    updateHeaderSizes = () => {
        this.updateHeaderColumnSizes();
        this.updateHeaderRowSizes();
        this.updateHeaderSeparatorSizes();
        this.updateFooterRowSizes();
        this.updateFooterSeparatorSizes();
    };

    updateHeaderColumnSizes() {
        if (!this.props.withHeaderColumn) {
            return;
        }
        const rows = this.content ? this.content.querySelectorAll(
            ':scope > table > tbody > tr, :scope > table > thead > tr'
        ) : [];
        const rowsCloned = this.headerColumn ? this.headerColumn.getElementsByTagName('tr') : null;
        const cellRectangles = [];
        if (rows && rowsCloned) {
            this.scheduleAction('updateHeaderColumnSizes', () => {
                let rect = null;
                for (let i = 0; i < rows.length; i++) {
                    rect = rows[i].firstChild.getBoundingClientRect();
                    cellRectangles.push(rect);
                    if (rows[i].firstChild.rowSpan > 1) {
                        i += rows[i].firstChild.rowSpan - 1;
                    }
                }
                if (cellRectangles[0] && this.headerColumn) {
                    const tableWidth = cellRectangles[0].width + 1;
                    this.headerColumn.style.width = `${tableWidth}px`;
                }
                cellRectangles.forEach((cellRect, index) => {
                    if (rowsCloned[index]) {
                        rowsCloned[index].firstChild.style.cssText = `max-width: ${cellRect.width}px; `
                            + `width: ${cellRect.width}px; height: ${cellRect.height}px`;
                    }
                });
            });
        }
        this.scheduleAction('updateScrollAreaOffset', this.updateScrollAreaOffset, 1);
    }

    updateHeaderRowSizes() {
        if (!this.props.withHeaderRow || !this.headerRow) {
            return;
        }
        const tHeaders = this.props.headerRowSelector(this.content);
        const tHeadersCloned = this.props.headerRowSelector(this.headerRow);
        const cellRectangles = [];
        if (tHeaders && tHeadersCloned) {
            this.scheduleAction('updateHeaderRowSizes', () => {
                let rect = null;
                for (let i = 0; i < tHeaders.length; i++) {
                    rect = tHeaders[i].getBoundingClientRect();
                    cellRectangles.push(rect);
                }
                cellRectangles.forEach((cellRect, index) => {
                    tHeadersCloned[index].style.cssText = `min-width: ${cellRect.width}px; `
                        + `width: ${cellRect.width}px; height: ${cellRect.height}px`;
                });
            });
        }
        this.scheduleAction('updateScrollAreaOffset', this.updateScrollAreaOffset, 1);
    }

    updateHeaderSeparatorSizes() {
        if (!this.props.withHeaderColumn || !this.props.withHeaderRow) {
            return;
        }
        const tHeadRows = this.props.headerSeparatorSelector(this.content);
        const headerSeparatorRows = this.props.headerSeparatorSelector(this.headerSeparator);
        const cellRectangles = [];
        if (tHeadRows && headerSeparatorRows) {
            this.scheduleAction('updateHeaderSeparatorSizes', () => {
                for (let i = 0; i < tHeadRows.length; i++) {
                    let rect = null;
                    rect = tHeadRows[i].firstChild.getBoundingClientRect();
                    cellRectangles.push(rect);
                    if (tHeadRows[i].firstChild.rowSpan > 1) {
                        i += tHeadRows[i].firstChild.rowSpan - 1;
                    }
                }
                if (cellRectangles[0] && this.headerSeparator) {
                    const tableWidth = cellRectangles[0].width + 1;
                    this.headerSeparator.style.width = `${tableWidth}px`;
                }
                cellRectangles.forEach((cellRect, index) => {
                    headerSeparatorRows[index].firstChild.style.cssText = `min-width: ${cellRect.width}px; `
                        + `width: ${cellRect.width}px; height: ${cellRect.height}px`;
                });
            });
        }
        this.scheduleAction('updateScrollAreaOffset', this.updateScrollAreaOffset, 1);
    }

    updateFooterRowSizes() {
        if (!this.props.withFooterRow || !this.footerRow) {
            return;
        }
        const tFooters = this.props.footerRowSelector(this.content);
        const tFootersCloned = this.props.footerRowSelector(this.footerRow);
        const cellRectangles = [];
        if (tFooters && tFootersCloned) {
            this.scheduleAction('updateFooterRowSizes', () => {
                let rect = null;
                for (let i = 0; i < tFooters.length; i++) {
                    rect = tFooters[i].getBoundingClientRect();
                    cellRectangles.push(rect);
                }
                cellRectangles.forEach((cellRect, index) => {
                    tFootersCloned[index].style.cssText = `min-width: ${cellRect.width}px; `
                        + `width: ${cellRect.width}px; height: ${cellRect.height}px`;
                });
            });
        }
        this.scheduleAction('updateScrollAreaOffset', this.updateScrollAreaOffset, 1);
    }

    updateFooterSeparatorSizes() {
        if (!this.props.withHeaderColumn || !this.props.withFooterRow) {
            return;
        }
        const tFootRows = this.props.footerSeparatorSelector(this.content);
        const footerSeparatorRows = this.props.footerSeparatorSelector(this.footerSeparator);
        const cellRectangles = [];
        if (tFootRows && footerSeparatorRows) {
            this.scheduleAction('updateFooterSeparatorSizes', () => {
                for (let i = 0; i < tFootRows.length; i++) {
                    let rect = null;
                    rect = tFootRows[i].firstChild.getBoundingClientRect();
                    cellRectangles.push(rect);
                    if (tFootRows[i].firstChild.rowSpan > 1) {
                        i += tFootRows[i].firstChild.rowSpan - 1;
                    }
                }
                if (cellRectangles[0] && this.footerSeparator) {
                    const tableWidth = cellRectangles[0].width + 1;
                    this.footerSeparator.style.width = `${tableWidth}px`;
                }
                cellRectangles.forEach((cellRect, index) => {
                    footerSeparatorRows[index].firstChild.style.cssText = `min-width: ${cellRect.width}px; `
                        + `width: ${cellRect.width}px; height: ${cellRect.height}px`;
                });
            });
        }
        this.scheduleAction('updateScrollAreaOffset', this.updateScrollAreaOffset, 1);
    }

    updateScrollAreaOffset = () => {
        let offsetChanged = false;
        let rect = null;
        const offset = { ...this.state.scrollAreaOffset };
        if (this.props.withHeaderRow && this.headerRow) {
            rect = this.headerRow.getBoundingClientRect();
            if (rect.height !== offset.top) {
                offset.top = rect.height;
                offsetChanged = true;
            }
        }
        if (this.props.withHeaderColumn && this.headerColumn) {
            rect = this.headerColumn.getBoundingClientRect();
            if (rect.width !== offset.left) {
                offset.left = rect.width;
                offsetChanged = true;
            }
        }
        if (this.props.withFooterRow && this.footerRow) {
            rect = this.footerRow.getBoundingClientRect();
            if (rect.height !== offset.bottom) {
                offset.bottom = rect.height;
                offsetChanged = true;
            }
        }
        if (offsetChanged) {
            this.setState({ scrollAreaOffset: offset });
        }
    };

    scheduleAction = (name, action, priority = 0) => {
        if (!this.scheduledActions[priority]) {
            this.scheduledActions[priority] = {};
        }
        this.scheduledActions[priority][name] = action;
        if (this.scheduleTimeout) {
            clearTimeout(this.scheduleTimeout);
        }
        this.scheduleTimeout = setTimeout(() => {
            this.scheduledActions.forEach((priority) => {
                Object.keys(priority).forEach((action) => {
                    priority[action]();
                });
            });
            this.scheduledActions = [];
        }, this.props.updateTimeout);
    };

    handleScroll = (state) => {
        if (this.headerColumn && this.props.withHeaderColumn) {
            this.headerColumn.style.transform = `translate(0, -${state.topPosition || 0}px)`;
        }
        if (this.headerRow && this.props.withHeaderRow) {
            this.headerRow.style.transform = `translate(-${state.leftPosition || 0}px, 0)`;
        }
        if (this.footerRow && this.props.withFooterRow) {
            this.footerRow.style.transform = `translate(-${state.leftPosition || 0}px, 0)`;
        }
        if (this.props.useCSS3Translate && this.displacement) {
            this.displacement.setAttribute(
                'style',
                `transform: translate(${state.leftPosition || 0}px,${state.topPosition || 0}px);`
                + ' will-change: transform; position: relative;'
            );
        }
        if (this.props.onScroll) {
            this.props.onScroll(state);
        }
    };

    renderContent = memoize((children, headerRow, headerColumn, headerSeparator, footerRow, footerSeparator) => (
        <React.Fragment>
            {this.props.useCSS3Translate
                ? (
                    <div ref={(c) => { this.displacement = c; }} className="sten-fixed-header-table__displacement">
                        {headerRow}
                        {headerColumn}
                        {headerSeparator}
                        {footerRow}
                        {footerSeparator}
                    </div>
                ) : (
                    <React.Fragment>
                        {headerRow}
                        {headerColumn}
                        {headerSeparator}
                        {footerRow}
                        {footerSeparator}
                    </React.Fragment>
                )
            }
            <div className="sten-fixed-header-table__content" ref={(c) => { this.content = c; }}>
                <PureWrapper>{children}</PureWrapper>
            </div>
        </React.Fragment>
    ));

    saveScrollRef = (c) => { this.scrollArea = c; }

    render() {
        const className = getClassName('sten-fixed-header-table', this.props.className, {
            'sten-fixed-header-table__column-separator': this.props.withHeaderColumnSeparator
        });
        return (
            <div className={className} ref={(c) => { this.wrapper = c; }}>
                <ScrollArea
                    animateScrollTo={this.props.animateScrollTo}
                    onScroll={this.handleScroll}
                    className="sten-fixed-header-table__scrollarea"
                    contentClassName={this.props.contentClassName}
                    contentStyle={this.props.contentStyle}
                    ref={this.saveScrollRef}
                    offset={this.state.scrollAreaOffset}
                    useCSS3Translate={this.props.useCSS3Translate}
                    stopScrollPropagation={this.props.stopScrollPropagation}
                    dragMode={this.props.dragMode}
                >
                    {this.renderContent(
                        this.props.children,
                        this.state.headerRow,
                        this.state.headerColumn,
                        this.state.headerSeparator,
                        this.state.footerRow,
                        this.state.footerSeparator
                    )}
                </ScrollArea>
            </div>
        );
    }
}

FixedHeaderTable.propTypes = {
    animateScrollTo: PropTypes.bool,
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    contentStyle: PropTypes.objectOf(PropTypes.any),
    customFirstChild: PropTypes.func,
    dragMode: PropTypes.bool,
    footerRowSelector: PropTypes.func,
    footerSeparatorSelector: PropTypes.func,
    headerRowSelector: PropTypes.func,
    headerSeparatorSelector: PropTypes.func,
    mapFooterRow: PropTypes.func,
    mapHeaderRow: PropTypes.func,
    onScroll: PropTypes.func,
    stopScrollPropagation: PropTypes.bool,
    updateTimeout: PropTypes.number,
    useCSS3Translate: PropTypes.bool,
    withFooterRow: PropTypes.bool,
    withHeaderColumn: PropTypes.bool,
    withHeaderColumnSeparator: PropTypes.bool,
    withHeaderRow: PropTypes.bool
};

FixedHeaderTable.defaultProps = {
    animateScrollTo: false,
    dragMode: false,
    className: '',
    contentClassName: '',
    contentStyle: null,
    customFirstChild: undefined,
    onScroll: undefined,
    stopScrollPropagation: false,
    headerRowSelector: (content) => {
        if (content) {
            const tHead = content.getElementsByTagName('thead')[0];
            if (tHead) {
                return tHead.querySelectorAll('th, td');
            }
        }
        return null;
    },
    headerSeparatorSelector: (content) => {
        if (content) {
            const tHead = content.getElementsByTagName('thead')[0];
            if (tHead) {
                return tHead.getElementsByTagName('tr');
            }
        }
        return null;
    },
    mapHeaderRow: (children) => [children.props.children[0]],
    footerRowSelector: (content) => {
        if (content) {
            const tFoot = content.getElementsByTagName('tfoot')[0];
            if (tFoot) {
                return tFoot.querySelectorAll('th, td');
            }
        }
        return null;
    },
    footerSeparatorSelector: (content) => {
        if (content) {
            const tFoot = content.getElementsByTagName('tfoot')[0];
            if (tFoot) {
                return tFoot.getElementsByTagName('tr');
            }
        }
        return null;
    },
    mapFooterRow: (children) => [children.props.children[children.props.children.length - 1]],
    updateTimeout: 50,
    useCSS3Translate: true,
    withHeaderColumn: false,
    withHeaderColumnSeparator: false,
    withHeaderRow: true,
    withFooterRow: false
};

export default FixedHeaderTable;
