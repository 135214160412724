import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* actions */
import { closePerformancePerVoyageModal } from './fleet-performance-report-actions';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import Modal from 'components/modal/modal';
import EmptyContent from 'components/empty-content/empty-content';
import FleetPerformanceReportPerVoyageTypeB from './type-b/fleet-performance-report-per-voyage-type-b';
import FleetPerformanceReportPerVoyageTypeA from './type-a/fleet-performance-report-per-voyage-type-a';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.${key}`);

class FleetPerformanceReportPerVoyage extends React.PureComponent {
    render() {
        const { performancePerVoyage: { voyages, vesselName, vesselImo } } = this.props;
        return (
            <Modal.Default
                className="sten-energy-management-reports-preview__fleet-performance-per-voyage-modal"
                modalSize="lg"
                isOpen={this.props.isPerformancePerVoyageModalOpen}
                onRequestClose={this.props.closePerformancePerVoyageModal}
            >
                <div className="sten-modal__header flex flex-center text-uppercase">
                    <div className="sten-modal__title flex-grow">
                        {`${vesselName} (${t('IMO')}: ${vesselImo})`}
                    </div>
                    <div className="flex-shrink">
                        <button
                            type="button"
                            className="btn-link icon icon-close"
                            onClick={this.props.closePerformancePerVoyageModal}
                        />
                    </div>
                </div>
                <div className="sten-modal__body">
                    <div className="sten-content__section">
                        {ConfigService.featureToggles.fleetPerformanceReportType === 'B'
                            ? (
                                <FleetPerformanceReportPerVoyageTypeB
                                    performancePerVoyage={this.props.performancePerVoyage}
                                />
                            )
                            : (
                                <FleetPerformanceReportPerVoyageTypeA
                                    performancePerVoyage={this.props.performancePerVoyage}
                                />
                            )}
                    </div>
                </div>
                {voyages.length === 0 && <EmptyContent>{t('EMPTY_VESSEL_DATA')}</EmptyContent>}
            </Modal.Default>
        );
    }
}

FleetPerformanceReportPerVoyage.propTypes = {
    isPerformancePerVoyageModalOpen: PropTypes.bool,
    closePerformancePerVoyageModal: PropTypes.func.isRequired,
    performancePerVoyage: PropTypes.shape({
        fuelTypes: PropTypes.objectOf(PropTypes.any),
        voyages: PropTypes.arrayOf(PropTypes.any),
        vesselName: PropTypes.string,
        vesselImo: PropTypes.number
    })
};

FleetPerformanceReportPerVoyage.defaultProps = {
    performancePerVoyage: null,
    isPerformancePerVoyageModalOpen: false
};

function mapDispatchToProps(dispatch) {
    return {
        closePerformancePerVoyageModal: () => closePerformancePerVoyageModal(dispatch)
    };
}

export default connect(null, mapDispatchToProps)(FleetPerformanceReportPerVoyage);
