import { takeEvery, put, select, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
/* router */
import { navigate, appRoutes } from 'app-router';
/* utils */
import TimeHelper from 'utils/helpers/time-helper';
import { t } from 'utils/i18n/i18n-model';
import { convertObjToDate, convertDateToObj } from 'components/htec-date-picker/helpers';
/* actions */
import { ActionTypes } from './inspections-form-actions';
import { ActionTypes as VDActions } from '../inspections-actions';
/* services */
import CompanyService from 'services/core-api/company-service';
import InspectionsService from 'services/core-api/inspections-service';
import VesselService from 'services/core-api/vessel-service';
/* mocked data */

function* getInspectionCompanies() {
    const inspectionCompanies = yield InspectionsService.getInspectionCompanies();
    if (inspectionCompanies) {
        yield put({
            type: ActionTypes.INSPECTIONS_FORM_SET_INSPECTION_COMPANIES,
            inspectionCompanies
        });
    }
    return inspectionCompanies;
}

function* addNewCompany(action) {
    const companyData = {
        CompanyAlias: action.companyName,
        CompanyType: 'Inspection'
    };
    const response = yield CompanyService.addInspectionCompany(companyData);
    if (response) {
        yield getInspectionCompanies();
        yield put({
            type: ActionTypes.INSPECTIONS_FORM_SET_NEW_INSPECTION_COMPANY,
            newInspectionCompanyId: response.Id
        });
        toast(t('INSPECTIONS.FORM.SUCCESSFULLY_ADDED_NEW_COMPANY'), { type: toast.TYPE.SUCCESS });
    }
}

function* uploadFiles(action) {
    if (action.files) {
        const response = yield InspectionsService.uploadFiles(action.files);
        const oldFiles = yield select(state => state.inspectionsFormReducer.editingInspection.Files);
        const allFiles = oldFiles.slice();
        if (response) {
            response.forEach((file) => {
                const parsedFile = {
                    name: file.FileName,
                    id: file.Id,
                    time: TimeHelper.getFormatted(file.TimeStamp, { time: true, utc: true }),
                    author: file.User,
                    type: file.MimeType
                };
                const foundIndex = allFiles.findIndex(oldFile => oldFile.name === file.FileName);
                if (foundIndex >= 0) {
                    allFiles[foundIndex] = parsedFile;
                } else {
                    allFiles.push(parsedFile);
                }
            });
            yield put({ type: ActionTypes.INSPECTIONS_FORM_SET_UPLOADED_FILES, files: allFiles });
        }
    }
}

function* submitData() {
    const inspectionData = yield select(state => state.inspectionsFormReducer.editingInspection);
    const activeInspectionId = yield select(state => state.inspectionsFormReducer.editingInspection.Id);
    const inspectionDate = inspectionData.InspectionDate;
    let response;
    const parsedData = {
        Imo: inspectionData.Vessel ? inspectionData.Vessel.Imo : null,
        PortId: inspectionData.Port ? inspectionData.Port.Id : null,
        InspectedById: inspectionData.InspectionCompanyId,
        TechnicalManagerId: inspectionData.TechnicalManagerId,
        OwnerId: inspectionData.HeadOwnerId,
        InspectionTypeId: inspectionData.InspectionTypeId,
        InspectionActivityId: inspectionData.InspectionActivityId,
        Comment: inspectionData.Comment ? inspectionData.Comment : '',
        FileIds: inspectionData.Files ? inspectionData.Files.map(file => file.id) : [],
        NumberOfObservations: inspectionData.NumberOfObservations,
        InspectionDate: inspectionDate
            ? convertObjToDate(inspectionDate, true, true, 'string')
            : null
    };
    if (activeInspectionId) {
        parsedData.Id = activeInspectionId;
        parsedData.FileIdsForDelete = inspectionData.FileIdsForDelete ? inspectionData.FileIdsForDelete : [];
        response = yield InspectionsService.updateInspection(activeInspectionId, parsedData);
    } else {
        response = yield InspectionsService.addInspection(parsedData);
    }
    if (response) {
        if (activeInspectionId) {
            toast(t('INSPECTIONS.FORM.SUCCESSFULLY_UPDATED_INSPECTION'), { type: toast.TYPE.SUCCESS });
        } else {
            toast(t('INSPECTIONS.FORM.SUCCESSFULLY_ADDED_NEW_INSPECTION'), { type: toast.TYPE.SUCCESS });
        }
        yield navigate(appRoutes.Vessel.Inspections);
        yield put({ type: VDActions.INSPECTIONS_UPDATE_INSPECTION, inspection: response.data });
        yield put({ type: ActionTypes.INSPECTIONS_FORM_RESET_ALL });
    }
}

function* getInspection(action) {
    const inspectionData = yield InspectionsService.getInspection(action.id);
    if (inspectionData) {
        yield put({
            type: ActionTypes.INSPECTIONS_FORM_SET_EDITING_INSPECTION,
            editingInspection: {
                ...inspectionData,
                InspectionDate: convertDateToObj(inspectionData.InspectionDate, true),
                Files: inspectionData.Files
                    ? inspectionData.Files.map(file => ({
                        name: file.FileName,
                        id: file.Id,
                        type: file.MimeType,
                        time: TimeHelper.getFormatted(file.TimeStamp, { time: true, utc: true }),
                        author: file.User
                    }))
                    : []
            }
        });
    }
}

function* getHeadOwners(params) {
    const headOwners = yield VesselService.getHeadOwners(params);
    if (headOwners) {
        yield put({
            type: ActionTypes.INSPECTIONS_FORM_SET_HEAD_OWNERS,
            headOwners
        });
    }
    return headOwners;
}

function* getTechnicalManagers(params) {
    const technicalManagers = yield VesselService.getTechnicalManagers(params);
    if (technicalManagers) {
        yield put({
            type: ActionTypes.INSPECTIONS_FORM_SET_TECHNICAL_MANAGERS,
            technicalManagers
        });
    }
    return technicalManagers;
}

function* getVesselOptions(action) {
    const activeInspectionId = yield select(state => state.inspectionsFormReducer.editingInspection.Id);
    const params = action.vessel ? { imo: action.vessel.Imo } : {};
    const options = yield all({
        technicalManagers: getTechnicalManagers(params),
        headOwners: getHeadOwners(params)
    });
    if (!activeInspectionId && options && params.imo) {
        if (options.headOwners) {
            const headOwner = options.headOwners.find(owner => owner.Id === action.vessel.OwnerId);
            yield put({
                type: ActionTypes.INSPECTIONS_FORM_SET_VALUE,
                field: 'HeadOwnerId',
                value: headOwner ? headOwner.Id : null
            });
        }
        if (options.technicalManagers) {
            const techManager = options.technicalManagers.find(
                techManager => techManager.Id === action.vessel.TechnicalManagerId
            );
            yield put({
                type: ActionTypes.INSPECTIONS_FORM_SET_VALUE,
                field: 'TechnicalManagerId',
                value: techManager ? techManager.Id : null
            });
        }
    }
}

export default function* inspectionsFormSaga() {
    yield takeEvery(ActionTypes.INSPECTIONS_FORM_SET_AS_NEW_COMPANY, addNewCompany);
    yield takeEvery(ActionTypes.INSPECTIONS_FORM_UPLOAD_FILES, uploadFiles);
    yield takeEvery(ActionTypes.INSPECTIONS_FORM_SUBMIT_INSPECTION_DATA, submitData);
    yield takeEvery(ActionTypes.INSPECTIONS_FORM_GET_VESSEL_OPTIONS, getVesselOptions);
    yield takeEvery(ActionTypes.INSPECTIONS_FORM_GET_INSPECTIONS_COMPANIES, getInspectionCompanies);
    yield takeEvery(ActionTypes.INSPECTIONS_FORM_GET_INSPECTION, getInspection);
}
