import React from 'react';
import PropTypes from 'prop-types';
/* styling */
import './tendency.scss';
/* helpers */
import { formatNumber } from 'utils/helpers/info-helper';

const Tendency = (props) => {
    if (!props) { return null; }
    const value = formatNumber(props.value, 2, true) || '';
    let iconClass = '';
    if (props.value > 0) {
        iconClass = 'icon-caret-up';
    } else if (props.value < 0) {
        iconClass = 'icon-caret-down';
    }
    return (
        <span className="sten-tendency">
            {iconClass && (
                <span className={`icon sten-tendency__icon ${iconClass}`} />
            )}
            <span className="sten-tendency__value">{value}</span>
        </span>
    );
};

Tendency.propTypes = {
    value: PropTypes.number
};

Tendency.defaultProps = {
    value: null
};

export default Tendency;
