import React from 'react';
/* utils */
import { formatNumber } from 'utils/helpers/info-helper';
import ReportHelper from 'utils/helpers/report-helper';
/* components */
import Input from 'components/input/input';
import Validation from 'components/validation/validation';
/* common */
import { t, tu, commonFields, commonData } from '../vessel-report-common-fields';

export const fieldMap = {

    // Basic Info

    Vessel: commonFields.Vessel(),
    CallSign: commonFields.CallSign(),
    VoyageNumber: commonFields.VoyageNumber(),
    CharterpartyDate: commonFields.CharterpartyDate(),
    ReportDate: commonFields.ReportDate(),
    Position: commonFields.Position(),
    Course: commonFields.Number('Course', t('COURSE'), 0, tu('COURSE')),
    CargoStatus: commonFields.CargoStatus(),
    VesselStatus: commonFields.VesselStatus(),
    'DailyReportAtSea.LogSpeed': commonFields.Number('DailyReportAtSea.LogSpeed', t('LOG_SPEED'), 2, tu('SPEED')),
    'DailyReportAtSea.LogDistance': commonFields.Number(
        'DailyReportAtSea.LogDistance',
        t('LOG_DISTANCE'),
        2,
        tu('DISTANCE')
    ),

    // Since last event

    TimeElapsedSinceLastEvent: commonFields.String('TimeElapsedSinceLastEvent', t('TIME_SINCE_LAST_EVENT')),
    TimeAtAnchorSinceLastEvent: commonFields.String('TimeAtAnchorSinceLastEvent', t('TIME_AT_ANCHOR_SINCE_LAST_EVENT')),
    HoursSteamedSinceLastEvent: commonFields.String('HoursSteamedSinceLastEvent', t('HOURS_STEAMED_SINCE_LAST_EVENT')),
    DistanceSinceLastEvent: commonFields.Number(
        'DistanceSinceLastEvent',
        t('DISTANCE_SINCE_LAST_EVENT'),
        2,
        tu('DISTANCE')
    ),
    WindForceSinceLastEvent: commonFields.WindForce('WindForceSinceLastEvent', t('WIND_FORCE_SINCE_LAST_EVENT')),
    TotalCargoSinceLastEvent: commonFields.Number(
        'TotalCargoSinceLastEvent',
        t('TOTAL_CARGO_ONBOARD'),
        0,
        tu('CARGO')
    ),

    // Port information

    'DailyReportAtPort.PortActivityType': commonFields.Select(
        'DailyReportAtPort.PortActivityType',
        t('PORT_ACTIVITY_TYPE'),
        (params, report) => params.editingOptions.cargoActivityTypes[report.DataSourceId]
    ),
    'AuroraReportData.StartPort': commonFields.ViewOnly('AuroraReportData.StartPort.Name', t('START_PORT')),
    PreviousPort: commonFields.Point('PreviousPort', t('PREVIOUS_PORT')),
    NextPoint: commonFields.Point('NextPoint', t('NEXT_PORT')),
    'DailyReportAtPort.Point': commonFields.Point('DailyReportAtPort.Point', t('CURRENT_PORT')),
    'DailyReportAtPort.BerthTerminalAnchorage':
        commonFields.String('DailyReportAtPort.BerthTerminalAnchorage', t('BERTH_TERMINAL_ANCHORAGE')),
    EtaDate: commonFields.DateTime('EtaDate', 'EtaDateOffset', t('ETA_DATE')),
    'DailyReportAtAnchorage.EstimatedTimeOfBerthing': commonFields.DateTime(
        'DailyReportAtAnchorage.EstimatedTimeOfBerthing',
        'DailyReportAtAnchorage.EstimatedTimeOfBerthingOffset',
        t('ETB_DATE')
    ),
    'DailyReportAtAnchorage.EstimatedTimeOfDeparture': commonFields.DateTime(
        'DailyReportAtAnchorage.EstimatedTimeOfDeparture',
        'DailyReportAtAnchorage.EstimatedTimeOfDepartureOffset',
        t('ETD_DATE')
    ),
    'DailyReportAtPort.EstimatedTimeOfDeparture': commonFields.DateTime(
        'DailyReportAtPort.EstimatedTimeOfDeparture',
        'DailyReportAtPort.EstimatedTimeOfDepartureOffset',
        t('ETD_DATE')
    ),
    DistanceToGo: commonFields.Number('DistanceToGo', t('DISTANCE_TO_GO'), 2, tu('DISTANCE')),
    DraftFwd: commonFields.Draft('DraftFwd', t('DRAFT_FWD')),
    DraftMid: commonFields.Draft('DraftMid', t('DRAFT_MID')),
    DraftAft: commonFields.Draft('DraftAft', t('DRAFT_AFT')),
    'DailyReportAtPort.CommencedCargoDate': commonFields.DateTime(
        'DailyReportAtPort.CommencedCargoDate',
        'DailyReportAtPort.CommencedCargoDateOffset',
        t('COMMENCED_CARGO_DATE')
    ),
    'DailyReportAtPort.QtyLoadedDischarged': commonFields.Number(
        'DailyReportAtPort.QtyLoadedDischarged',
        t('QTY_LOADED_DISCHARGED'),
        0,
        tu('CARGO')
    ),
    'DailyReportAtPort.TotalCargoLoadedDischarged': commonFields.Number(
        'DailyReportAtPort.TotalCargoLoadedDischarged',
        t('TOTAL_CARGO_LOADED_DISCHARGED'),
        0,
        tu('CARGO')
    ),
    'DailyReportAtPort.BalanceCargoToLoadDischarge': commonFields.Number(
        'DailyReportAtPort.BalanceCargoToLoadDischarge',
        t('BALANCE_CARGO_LOADED_DISCHARGED'),
        0,
        tu('CARGO')
    ),
    'DailyReportAtPort.AverageLoadDischargeRate': commonFields.ReadOnly(
        ReportHelper.getAverageLoadDischargeRate,
        t('AVERAGE_LOAD_DISCHARGE_RATE'),
        2,
        tu('LOAD_DISCHARGE_RATE'),
        t('AVERAGE_LOAD_DISCHARGE_RATE_TITLE')
    ),
    'DailyReportAtPort.NoOfGangsHatchesWorking': commonFields.Number(
        'DailyReportAtPort.NoOfGangsHatchesWorking',
        t('NUMBER_OF_GANGS_HATCHES_WORKING'),
        0
    ),

    // Remarks

    Remarks: commonFields.Remarks(),

    // Since last daily report

    HoursSinceLastReport: commonFields.String('HoursSinceLastReport', t('HOURS_SINCE_LAST_REPORT')),
    HoursSteamedSinceLastReport: commonFields.String(
        'HoursSteamedSinceLastReport', t('HOURS_STEAMED_SINCE_LAST_REPORT')
    ),
    TotalDistanceSteamedSinceLastReport: commonFields.Number(
        'TotalDistanceSteamedSinceLastReport',
        t('TOTAL_DISTANCE_STEAMED_SINCE_LAST_REPORT'),
        2,
        tu('DISTANCE')
    ),
    EcaDistanceSteamedSinceLastReport: commonFields.Number(
        'EcaDistanceSteamedSinceLastReport',
        t('ECA_DISTANCE_STEAMED_SINCE_LAST_REPORT'),
        2,
        tu('DISTANCE')
    ),
    InstructedSpeed: commonFields.Speed('InstructedSpeed', t('INSTRUCTED_SPEED')),
    AverageSpeed: commonFields.Speed('AverageSpeed', t('AVERAGE_SPEED')),
    Rpm: commonFields.Number('Rpm', t('RPM'), 1),
    AverageRpm: commonFields.Number('AverageRpm', t('AVERAGE_RPM'), 1),
    'DailyReportAtSea.SpeedInstructionType': commonFields.Select(
        'DailyReportAtSea.SpeedInstructionType',
        t('SPEED_INSTRUCTION_TYPE'),
        'editingOptions.speedInstructionTypes'
    ),
    'DailyReportAtSea.MePowerInKw':
        commonFields.Number('DailyReportAtSea.MePowerInKw', t('ME_POWER'), 2, tu('POWER_KW')),
    // Cargo
    'AuroraReportData.CargoUsed.ForceUsed':
        commonFields.ViewOnly('AuroraReportData.CargoUsed.ForceUsed', t('FORCER_USED')),
    'AuroraReportData.CargoUsed.Hours':
        commonFields.ViewOnly('AuroraReportData.CargoUsed.Hours', t('FORCER_HOURS'), 0),

    // Weather

    WindBeaufort: commonFields.WindForce('WindBeaufort', t('WIND_FORCE')),
    WindBeaufortExternal: {
        label: <label className="label">{t('WIND_FORCE_EXTERNAL')}</label>,
        view: report => (
            <label className="label label--value">
                {report.WindBeaufortExternal
                    ? `${report.WindBeaufortExternal.Id} ${tu('WIND_FORCE')} (${report.WindBeaufortExternal.Name})`
                    : '-'}
            </label>
        )
    },
    Wind: commonFields.ValueDirection('WindSpeed', 'WindDirection', t('WIND'), tu('WIND_SPEED')),
    Swell: commonFields.ValueDirection('SwellHeight', 'SwellDirection', t('SWELL'), tu('SWELL_HEIGHT')),
    SeasHeightAndCondition: {
        label: <label className="label">{t('SEA')}</label>,
        view: report => {
            let value = '-';
            if (typeof report.SeasHeight === 'number') {
                value = `${formatNumber(report.SeasHeight, 0)} ${tu('SEA_HEIGHT')}`;
            }
            if (report.SeaCondition) {
                value += ` (${report.SeaCondition})`;
            }
            return (
                <label className="label label--value">{value}</label>
            );
        },
        edit: (report, setValue, options) => {
            return (
                <div className="row">
                    <div className="col-8">
                        <Validation.Wrapper hintsOnHover validations={options.validationRules.SeasHeight}>
                            <Input
                                name="SeasHeight"
                                value={report.SeasHeight}
                                onChange={setValue.bind(this, 'SeasHeight')}
                                suffix={tu('SEA_HEIGHT')}
                            />
                        </Validation.Wrapper>
                    </div>
                    <div className="col-16">
                        <Validation.Wrapper hintsOnHover validations={options.validationRules.SeaCondition}>
                            <Input
                                name="SeaCondition"
                                value={report.SeaCondition}
                                onChange={setValue.bind(this, 'SeaCondition')}
                            />
                        </Validation.Wrapper>
                    </div>
                </div>
            );
        }
    },
    'DailyReportAtSea.SeasHeightAndDirection':
        commonFields.ValueDirection('SeasHeight', 'DailyReportAtSea.SeasDirection', t('SEA'), tu('SEA_HEIGHT')),
    'DailyReportAtAnchorage.SeasHeightAndDirection':
        commonFields.ValueDirection('SeasHeight', 'DailyReportAtAnchorage.SeasDirection', t('SEA'), tu('SEA_HEIGHT')),
    SeaCondition: commonFields.String('SeaCondition', t('SEA_CONDITION')),
    'DailyReportAtSea.CurrentKn': commonFields.ValueDirection(
        'DailyReportAtSea.CurrentKn',
        'DailyReportAtSea.CurrentDirection',
        t('CURRENT'),
        tu('SPEED')
    ),
    'DailyReportAtAnchorage.CurrentKn': commonFields.ValueDirection(
        'DailyReportAtAnchorage.CurrentKn',
        'DailyReportAtAnchorage.CurrentDirection',
        t('CURRENT'),
        tu('SPEED')
    ),
    InmarsatAreaCodeInUse: commonFields.String('InmarsatAreaCodeInUse', t('INMARSAT_AREA_CODE')),

    // Additional Info

    Mcr: commonFields.Number('Mcr', t('MCR'), 0, tu('PERCENTAGE')),
    Slip: commonFields.Number('Slip', t('SLIP'), 0, tu('PERCENTAGE')),
    LastPropellerCleaning: commonFields.DateTime(
        'LastPropellerCleaning',
        'LastPropellerCleaningOffset',
        t('LAST_PROPELLER_CLEANING')
    ),
    LastHullCleaning: commonFields.DateTime('LastHullCleaning', 'LastHullCleaningOffset', t('LAST_HULL_CLEANING')),
    FreshWaterAvailableForTankCleaning: commonFields.Number(
        'FreshWaterAvailableForTankCleaning',
        t('FRESH_WATER_FOR_TANK_CLEANING'),
        0,
        tu('FRESH_WATER')
    ),
    WaterTemperature: commonFields.Number(
        'WaterTemperature',
        t('WATER_TEMPERATURE'),
        1,
        tu('TEMPERATURE')
    ),
    'AuroraReportData.MainEngineMiles':
        commonFields.ViewOnly('AuroraReportData.MainEngineMiles', t('ENGINE_DISTANCE'), 1, tu('DISTANCE')),
    'AuroraReportData.DistanceThroughIce':
        commonFields.ViewOnly('AuroraReportData.DistanceThroughIce', t('DISTANCE_THROUGH_ICE'), 1, tu('DISTANCE')),
    'AuroraReportData.CpWarrantedConsumption': commonFields.ViewOnly(
        'AuroraReportData.CpWarrantedConsumption',
        t('WARRANTED_CONSUMPTION'),
        1,
        tu('CONSUMPTION')
    ),
    'AuroraReportData.Trim': commonFields.ViewOnly('AuroraReportData.Trim', t('TRIM'), 1, tu('TRIM')),
    'AuroraReportData.TrimType': commonFields.ViewOnly('AuroraReportData.TrimType', t('TRIM_TYPE')),
    'AuroraReportData.ElectricalLoad':
        commonFields.ViewOnly('AuroraReportData.ElectricalLoad', t('ELECTRICAL_LOAD'), 0, tu('ELECTRICAL_LOAD'))
};

export const dataMap = {
    ConsumptionsSinceLastEvent: commonData.ConsumptionFuelData('ConsumptionsSinceLastEvent', 6),
    BunkerData: commonData.BunkerFuelData('BunkerData'),
    Consumptions: commonData.ConsumptionFuelData('Consumptions', 1),
    Utilization: commonData.Utilization('Utilization'),
    'AuroraReportData.ExtractedEquipmentItems.Boiler': commonData.AuroraDataTable(
        'AuroraReportData.ExtractedEquipmentItems.Boiler', 'Boiler', t('BOILER')
    ),
    'AuroraReportData.ExtractedEquipmentItems.MainTurbine': commonData.AuroraDataTable(
        'AuroraReportData.ExtractedEquipmentItems.MainTurbine', 'MainTurbine', t('SIDE'), t('TURBINE')
    ),
    'AuroraReportData.ExtractedEquipmentItems.PropulsionMotor': commonData.AuroraDataTable(
        'AuroraReportData.ExtractedEquipmentItems.PropulsionMotor', 'PropulsionMotor', t('SIDE'), t('PROPULSION_MOTORS')
    ),
    'AuroraReportData.ExtractedEquipmentItems.HoursConsumption': commonData.AuroraDataTable(
        'AuroraReportData.ExtractedEquipmentItems.HoursConsumption', 'HoursConsumption', t('ENGINE')
    ),
    'AuroraReportData.ExtractedEquipmentItems.MainEngine': commonData.AuroraDataTable(
        'AuroraReportData.ExtractedEquipmentItems.MainEngine', 'MainEnginePower', t('ENGINE')
    ),
    'AuroraReportData.Water': commonData.AuroraDataTable('AuroraReportData.Water', 'Water', t('WATER_TYPE')),
    'AuroraReportData.Temperatures': commonData.AuroraDataTable(
        'AuroraReportData.Temperatures', 'Temperatures', t('TANK')
    ),
    'AuroraReportData.BarrierSpaceConditions': commonData.AuroraDataTable(
        'AuroraReportData.BarrierSpaceConditions', 'BarrierSpaceConditions', t('TANK'), t('BARRIER_SPACE_CONDITIONS')
    ),
    'AuroraReportData.CofferdamTemps': commonData.AuroraDataTable(
        'AuroraReportData.CofferdamTemps', 'CofferdamTemps', '#', t('COFFERDAM_LOWEST_TEMPERATURES'), { minColWidth: 0 }
    )
};

export default { fieldMap, dataMap };
