export const ActionTypes = {
    ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_GENERATE:
    'ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_GENERATE',
    ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_SET:
    'ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_SET',
    ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_GET_PER_VOYAGE:
    'ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_GET_PER_VOYAGE',
    ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_SET_PER_VOYAGE:
    'ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_SET_PER_VOYAGE',
    ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_PER_VOYAGE_MODAL_CLOSE:
    'ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_PER_VOYAGE_MODAL_CLOSE',
    ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_GENERATE_XLSX:
    'ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_GENERATE_XLSX',
    ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_GENERATE_PDF:
    'ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_GENERATE_PDF'
};

export function generateXLSX(dispatch) {
    dispatch({ type: ActionTypes.ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_GENERATE_XLSX });
}

export function generatePDF(dispatch) {
    dispatch({ type: ActionTypes.ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_GENERATE_PDF });
}

export function getPerformancePerVoyage(dispatch, vesselName, vesselImo) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_GET_PER_VOYAGE,
        vesselName,
        vesselImo
    });
}

export function closePerformancePerVoyageModal(dispatch) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_FLEET_PERFORMANCE_REPORT_PER_VOYAGE_MODAL_CLOSE
    });
}
