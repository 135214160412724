import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
/* components */
import Accordion from 'components/accordion/accordion';
import FuelDataTable from 'components/fuel-data-table/fuel-data-table';

export class VesselInfoEnergyBunkerRob extends React.PureComponent {
    render() {
        const { latestVoyageReport, fuelTypes } = this.props;
        if (!latestVoyageReport) {
            return null;
        }

        const header = (
            <h4 className="text-uppercase">
                {translate('VESSEL_INFO.ENERGY.BUNKER_ROB.TITLE')}
                {latestVoyageReport.Date && (
                    <span className="text-secondary">
                        {`  - ${TimeHelper.getFormatted(latestVoyageReport.Date, { utc: true, time: true })}`}
                    </span>
                )}
            </h4>
        );

        return (
            <Accordion header={header}>
                <FuelDataTable
                    data={latestVoyageReport.BunkerData}
                    fuelTypeOptions={fuelTypes.list}
                />
            </Accordion>
        );
    }
}

VesselInfoEnergyBunkerRob.propTypes = {
    fuelTypes: PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.object),
        map: PropTypes.objectOf(PropTypes.any)
    }).isRequired,
    latestVoyageReport: PropTypes.objectOf(PropTypes.any)
};

VesselInfoEnergyBunkerRob.defaultProps = {
    latestVoyageReport: null
};

export default VesselInfoEnergyBunkerRob;
