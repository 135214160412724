import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
import { formatNumber } from 'utils/helpers/info-helper';
import { getPanelRows } from 'utils/helpers/template-helper';
/* components */
import Accordion from 'components/accordion/accordion';
import BunkerexInfo from 'components/bunkerex-info/bunkerex-info';
import Tendency from 'components/tendency/tendency';

export class PortInfoBunkerPrices extends React.PureComponent {
    getValues = () => {
        const columnValues = [];
        const columnClasses = ['col-8', 'col-8', 'col-8'];

        if (this.props.bunkerPrices.BunkerPrices && this.props.bunkerPrices.BunkerPrices.length > 0) {
            this.props.bunkerPrices.BunkerPrices.forEach((fuelType) => {
                columnValues.push([
                    <label className="sten-panel__label">{fuelType.FuelName}</label>,
                    <label className="sten-panel__label sten-panel__label--value">
                        {fuelType.Value
                            ? `${formatNumber(fuelType.Value)} ${fuelType.Unit}`
                            : t('GLOBAL.NOT_AVAILABLE')}

                    </label>,
                    <label className="sten-panel__label">
                        <Tendency value={fuelType.Difference} />
                    </label>
                ]);
            });
        }
        if (columnValues.length === 0) {
            return null;
        }

        const response = [
            <div key="values" className="sten-content__section">
                {getPanelRows(columnClasses, columnValues)}
            </div>
        ];

        if (this.props.bunkerPrices.LastUpdated) {
            response.push(
                <div
                    key="updated"
                    className="sten-content__section sten-content__separator sten-content__separator--inner"
                >
                    <p className="text-secondary">
                        {t('PORT_INFO.DETAILS.UPDATED', {
                            date: TimeHelper.getFormatted(this.props.bunkerPrices.LastUpdated, { time: true })
                        })}
                    </p>
                </div>
            );
        }

        return response;
    };

    render() {
        if (!this.props.bunkerPrices || this.props.bunkerPrices.BunkerPrices.length === 0) {
            return null;
        }

        return (
            <Accordion header={<h4 className="text-uppercase">{t('PORT_INFO.DETAILS.BUNKER_PRICES')}</h4>}>
                {this.getValues()}
                <div className="sten-content__section">
                    <label className="sten-panel__label">
                        {t('PORT_INFO.DETAILS.BUNKER_PRICES_DISCLAIMER')}
                    </label>
                </div>
                <div key="bunkerex" className="sten-content__section">
                    <BunkerexInfo />
                </div>
            </Accordion>
        );
    }
}

PortInfoBunkerPrices.propTypes = {
    bunkerPrices: PropTypes.objectOf(PropTypes.any)
};

PortInfoBunkerPrices.defaultProps = {
    bunkerPrices: null
};

export default PortInfoBunkerPrices;
