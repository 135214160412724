import React from 'react';
import PropTypes from 'prop-types';
/* helpers */
import { formatNumber } from 'utils/helpers/info-helper';
/* components */
import TrendIcon from 'components/trend-icon/trend-icon';
/* styles */
import './vessel-distribution-areas.scss';

export default class VesselDistributionArea extends React.PureComponent {
    render() {
        const { dotSize, historicalDiff, areaName, isActive, vesselCount } = this.props;
        const style = {};
        if (isActive) {
            style.marginTop = `-${dotSize / 2}rem`;
            style.marginLeft = `-${dotSize / 2}rem`;
        }
        const dotStyle = {
            width: `${dotSize}rem`,
            height: `${dotSize}rem`
        };
        let dotClass = 'sten-vessel-distribution-area__dot';
        if (historicalDiff < 0) {
            dotClass += ' sten-vessel-distribution-area__dot--decrease';
        }

        return isActive
            ? (
                <React.Fragment>
                    <div className={dotClass} style={dotStyle}>
                        <div className="sten-vessel-distribution-area__dot-label">
                            {formatNumber(vesselCount, 0)}
                        </div>
                    </div>
                    <div className="sten-vessel-distribution-area__trend">
                        <TrendIcon breakpoints={[0]} value={historicalDiff} />
                        {historicalDiff}
                    </div>
                </React.Fragment>
            ) : (
                <div className="sten-vessel-distribution-area__label">{areaName}</div>
            );
    }
}

VesselDistributionArea.propTypes = {
    areaName: PropTypes.string.isRequired,
    dotSize: PropTypes.number.isRequired,
    historicalDiff: PropTypes.number.isRequired,
    isActive: PropTypes.bool.isRequired,
    vesselCount: PropTypes.number.isRequired
};
