import { createSelector } from 'reselect';

const emptyArray = [];

export const selectEditingOptions = createSelector(
    state => state.questionnaireReducer.editingOptions,
    state => state.vesselReportReducer.fuelTypes,
    state => state.questionnaireReducer.questionnaire,
    (editingOptions, fuelTypes, questionnaire) => {
        let list = emptyArray;
        if (fuelTypes) {
            if (questionnaire && questionnaire.DataSourceId) {
                list = fuelTypes[questionnaire.DataSourceId] || emptyArray;
            } else {
                list = fuelTypes[7].filter(fuelType => fuelType.Visible);
            }
        }
        return { ...editingOptions, fuelTypes: list };
    }
);
