import React from 'react';
import PropTypes from 'prop-types';
import './summary-table.scss';

export default class EnergyManagementReportsSummaryTable extends React.PureComponent {
    render() {
        return (
            <table
                className="
                    sten-table
                    sten-table--inverted
                    sten-table--xs
                    sten-table--no-border-top
                    sten-table--no-border-vertical
                    sten-table--no-border-bottom
                    sten-energy-management-reports-preview__summary-table"
            >
                <tbody>
                    {this.props.rows.map((row, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <tr key={index}>
                            <td>
                                <div className="flex flex-center">
                                    <div className="flex-shrink">
                                        <span className="text-secondary">
                                            {row.label}
                                        </span>
                                    </div>
                                    {row.tooltip && (
                                        <div className="flex-grow">
                                            <span
                                                className="icon sten-icon-info-tooltip"
                                                title={row.tooltip}
                                            />
                                        </div>
                                    )}
                                </div>
                            </td>
                            <td>{row.value}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
}

EnergyManagementReportsSummaryTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]).isRequired
    })).isRequired
};
