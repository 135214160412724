import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import TimeHelper from 'utils/helpers/time-helper';
import { getObjectProp } from 'utils/helpers/info-helper';
import { convertAndDownloadFile } from 'utils/helpers/file-helper';
/* components */
import Checkbox from 'components/checkbox/checkbox';
import EmptyContent from 'components/empty-content/empty-content';
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
import Menu, { MenuItem } from 'components/menu/menu';
import TableHeadCell from 'components/table-head-cell/table-head-cell';
import TextHighlight from 'components/text-highlight/text-highlight';
import LongTextCell from 'components/long-text-cell/long-text-cell';
import Modal from 'components/modal/modal';
/* Services */
import InspectionsService from 'services/core-api/inspections-service';
/* actions */
import {
    toggleFilteredInspections,
    toggleSelectedInspection,
    deleteInspection,
    setSortProp
} from '../inspections-actions';
/* selectors */
import { getFilteredInspections, areFilteredInspectionsSelected } from '../inspections-selectors';
/* styles */
import './inspections-table.scss';

const tableHeadCells = [
    {
        title: t('INSPECTIONS.TABLE.VESSEL'),
        sortProp: 'Vessel.Title',
        sortable: true,
        withCheckbox: true,
        columnPropertyName: 'Vessel.Title'
    },
    {
        title: t('INSPECTIONS.TABLE.INSPECTION_DATE'),
        sortProp: 'Date',
        sortable: true,
        columnPropertyName: 'Date'
    },
    {
        title: t('INSPECTIONS.TABLE.TYPE'),
        sortProp: 'Type',
        sortable: true,
        columnPropertyName: 'Type'
    },
    {
        title: t('INSPECTIONS.TABLE.PORT'),
        sortProp: 'Port',
        sortable: true,
        columnPropertyName: 'Port'
    },
    {
        title: t('INSPECTIONS.TABLE.INSPECTED_BY'),
        sortProp: 'Company',
        sortable: true,
        columnPropertyName: 'Company'
    },
    {
        title: t('INSPECTIONS.TABLE.OBS_NO'),
        sortProp: 'Observation',
        sortable: true,
        columnPropertyName: 'Observations'
    },
    {
        title: t('INSPECTIONS.TABLE.ACTIVITY'),
        sortProp: 'Activity',
        sortable: true,
        columnPropertyName: 'Activity'
    },
    {
        title: t('INSPECTIONS.TABLE.COMMENT'),
        sortProp: 'Comment',
        sortable: false,
        columnPropertyName: 'Comment'
    },
    {
        title: t('INSPECTIONS.TABLE.TECHNICAL_MANAGER'),
        sortProp: 'TechnicalManager.Name',
        sortable: true,
        columnPropertyName: 'TechnicalManager.Name'
    },
    {
        title: t('INSPECTIONS.TABLE.OWNER'),
        sortProp: 'Owner.Name',
        sortable: true,
        columnPropertyName: 'Owner.Name'
    }
];

class InspectionsTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isDeleteModalOpen: false,
            inspectionIdForDelete: -1,
            tableRowLimit: 50,
            inspections: null
        };
    }

    scrollHeight = null;

    static getDerivedStateFromProps(props, state) {
        if (props.inspections !== state.inspections) {
            return {
                tableRowLimit: 50,
                inspections: props.inspections
            };
        }
        return null;
    }

    closeModal = () => {
        this.setState({
            isDeleteModalOpen: false,
            inspectionIdForDelete: -1
        });
    };

    deleteInspection = () => {
        this.props.deleteInspection(this.state.inspectionIdForDelete);
        this.setState({
            isDeleteModalOpen: false,
            inspectionIdForDelete: -1
        });
    };

    componentDidUpdate(prevProps) {
        if (this.props.inspections !== prevProps.inspections) {
            this.scrollHeight = null;
            if (this.fixedHeaderTable) {
                this.fixedHeaderTable.scrollArea.scrollArea.scrollTop();
            }
        }
    }

    setSortProp = (sortProp) => {
        this.props.setSortProp(sortProp);
        this.fixedHeaderTable.scrollArea.scrollArea.scrollTop();
        this.setState({ tableRowLimit: 50 });
    };

    scrollHandler = scrollProps => {
        const bottomPosition = scrollProps.realHeight - scrollProps.topPosition;
        if (scrollProps.realHeight !== this.scrollHeight) {
            if (bottomPosition < scrollProps.containerHeight + 100
                && this.state.tableRowLimit < this.props.inspections.length) {
                this.setState({ tableRowLimit: this.state.tableRowLimit + 20 });
                this.scrollHeight = scrollProps.realHeight;
            }
        }
        // if (bottomPosition > 4 * scrollProps.containerHeight && this.state.tableRowLimit > 50) {
        //     this.setState({ tableRowLimit: this.state.tableRowLimit - 20 });
        //     this.scrollHeight = scrollProps.realHeight;
        // }
    };

    stopCheckboxPropagation = (e) => {
        e.stopPropagation();
    };

    handleEditInspectionClick = (inspectionId) => {
        this.props.router.navigate(`${appRoutes.Vessel.Inspections}/edit/${inspectionId}`);
    };

    handleDeleteInspectionClick = (inspectionId) => {
        this.setState({
            isDeleteModalOpen: true,
            inspectionIdForDelete: inspectionId
        });
    };

    editVessel = imo => this.props.router.navigate(`${appRoutes.Admin.VesselManagement}/edit/${imo}`);

    locateVesselOnMap = (imo) => {
        this.props.router.navigate({ pathname: appRoutes.Map, query: { vessel: imo } });
    };

    renderContextMenu = (inspectionId, imo) => {
        return (
            <Menu menuRight>
                <MenuItem
                    onClick={this.handleEditInspectionClick.bind(this, inspectionId)}
                    icon="icon-vetting-database"
                >
                    {t('INSPECTIONS.TABLE.EDIT_INSPECTION')}
                </MenuItem>
                <MenuItem onClick={this.handleDeleteInspectionClick.bind(this, inspectionId)} icon="icon-delete">
                    {t('INSPECTIONS.TABLE.DELETE_INSPECTION')}
                </MenuItem>
                {this.props.permissions && this.props.permissions.AssignVesselToFleet && (
                    <MenuItem onClick={this.editVessel.bind(this, imo)} icon="icon-edit">
                        {t('INSPECTIONS.TABLE.EDIT_VESSEL')}
                    </MenuItem>
                )}
                <MenuItem onClick={this.locateVesselOnMap.bind(this, imo)} icon="icon-position">
                    {t('INSPECTIONS.TABLE.LOCATE_ON_MAP')}
                </MenuItem>
            </Menu>
        );
    };

    handleFileDownload = (file) => {
        InspectionsService.downloadFile(file.Id).then(response => {
            if (response) {
                convertAndDownloadFile(response, { name: file.FileName });
            }
        });
    };

    refreshFixedHeaderTable = () => {
        if (this.fixedHeaderTable) {
            this.fixedHeaderTable.updateHeaderSizes();
        }
    };

    renderDownloadFilesMenu = (inspectionId, files) => {
        return (
            <Menu menuIcon="file-download">
                {files.map((file) => (
                    <MenuItem
                        key={file.Id}
                        onClick={this.handleFileDownload.bind(this, file)}
                        icon="icon-vetting-database"
                    >
                        {file.FileName}
                    </MenuItem>
                ))}
            </Menu>
        );
    };

    getDate = (date, isInAlert) => {
        if (!date) { return '-'; }
        return (
            <React.Fragment>
                <span className={isInAlert ? 'text-danger text-bold' : ''}>
                    {TimeHelper.getFormatted(date, { utc: true })}
                </span>
                <span className="text-secondary"> ({moment.utc(date).fromNow()})</span>
            </React.Fragment>
        );
    };

    renderTableRow = (inspection) => {
        return (
            /* eslint-disable react/no-array-index-key */
            <tr key={inspection.Id}>
                {tableHeadCells.map((column) => {
                    switch (column.columnPropertyName) {
                    case 'Vessel.Title': {
                        return (
                            <th key={column.columnPropertyName}>
                                <div className="flex-row flex-center">
                                    <div className="flex-shrink">
                                        <Checkbox
                                            isChecked={!!this.props.selectedInspections[inspection.Id]}
                                            onChange={this.props.toggleSelectedInspection.bind(this, inspection.Id)}
                                        />
                                    </div>
                                    <div className="flex-grow">
                                        <h3 className="text-uppercase">
                                            <TextHighlight
                                                input={inspection.Vessel.Title}
                                                highlight={this.props.searchCriteria}
                                            />
                                        </h3>
                                        <p className="text-secondary">
                                            {inspection.Vessel.Segment}
                                        </p>
                                    </div>
                                    <div className="flex-shrink">
                                        {this.renderContextMenu(inspection.Id, inspection.Vessel.Imo)}
                                    </div>
                                </div>
                            </th>
                        );
                    }
                    case 'Date': {
                        return (
                            <td key={column.columnPropertyName}>
                                {this.getDate(inspection.Date, inspection.IsInAlert)}
                            </td>
                        );
                    }
                    case 'Comment': {
                        return (
                            <td key={column.columnPropertyName}>
                                <LongTextCell
                                    onClick={this.refreshFixedHeaderTable}
                                    text={inspection.Comment || ''}
                                />
                            </td>
                        );
                    }
                    case 'Observations': {
                        return (
                            <td key={column.columnPropertyName}>
                                <div className="flex-row flex-center">
                                    <div className="flex-grow">
                                        {typeof inspection.Observation === 'number'
                                            ? inspection.Observation
                                            : t('GLOBAL.UNKNOWN')}
                                    </div>
                                    {inspection.Files.length > 0 && (
                                        <div className="flex-shrink">
                                            {this.renderDownloadFilesMenu(inspection.Id, inspection.Files)}
                                        </div>
                                    )}
                                </div>
                            </td>
                        );
                    }
                    default:
                        return (
                            <td key={column.columnPropertyName} className="text-word-break">
                                {getObjectProp(inspection, column.columnPropertyName) || '-'}
                            </td>
                        );
                    }
                })}
            </tr>
            /* eslint-enable react/no-array-index-key */
        );
    };

    saveRef = c => { this.fixedHeaderTable = c; };

    render() {
        if (!this.props.inspections || this.props.inspections.length === 0) {
            return <EmptyContent />;
        }
        const inspections = this.props.inspections.slice(0, this.state.tableRowLimit);

        return (
            <div className="sten-inspections-table">
                <FixedHeaderTable
                    customFirstChild={TableHeadCell}
                    ref={this.saveRef}
                    withHeaderColumn
                    contentClassName="sten-inspections-table__scroll-content"
                    onScroll={this.scrollHandler}
                >
                    <table className="sten-table sten-inspections-table__table">
                        <thead>
                            <tr>
                                {tableHeadCells.map((cell) => (
                                    <TableHeadCell
                                        sortOrder={this.props.sortProp === cell.sortProp ? this.props.sortOrder : ''}
                                        sortable={cell.sortable}
                                        key={cell.title}
                                        onClick={this.setSortProp.bind(this, cell.sortProp)}
                                    >
                                        {cell.withCheckbox
                                            ? (
                                                <div className="flex-row flex-center">
                                                    <div className="flex-shrink">
                                                        <Checkbox
                                                            isChecked={this.props.areAllInspectionsSelected}
                                                            onChange={this.props.toggleFilteredInspections}
                                                            onClick={this.stopCheckboxPropagation}
                                                        />
                                                    </div>
                                                    <div className="flex-grow">
                                                        {cell.title}
                                                    </div>
                                                </div>
                                            )
                                            : cell.title
                                        }
                                    </TableHeadCell>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {inspections.map(this.renderTableRow)}
                        </tbody>
                    </table>
                </FixedHeaderTable>
                <Modal.Delete
                    isOpen={this.state.isDeleteModalOpen}
                    onDelete={this.deleteInspection}
                    onCancel={this.closeModal}
                >
                    {t('INSPECTIONS.TABLE.CONFIRM_DELETE')}
                </Modal.Delete>
            </div>
        );
    }
}

InspectionsTable.propTypes = {
    areAllInspectionsSelected: PropTypes.bool.isRequired,
    deleteInspection: PropTypes.func.isRequired,
    inspections: PropTypes.arrayOf(PropTypes.any).isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    router: TRouter.isRequired,
    searchCriteria: PropTypes.string.isRequired,
    selectedInspections: PropTypes.objectOf(PropTypes.any).isRequired,
    setSortProp: PropTypes.func.isRequired,
    sortOrder: PropTypes.string.isRequired,
    sortProp: PropTypes.string.isRequired,
    toggleFilteredInspections: PropTypes.func.isRequired,
    toggleSelectedInspection: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        areAllInspectionsSelected: areFilteredInspectionsSelected(state),
        inspections: getFilteredInspections(state),
        permissions: state.userReducer.permissions,
        searchCriteria: state.inspectionsReducer.selectedFilterOptions.vessel,
        selectedInspections: state.inspectionsReducer.selectedInspections,
        sortOrder: state.inspectionsReducer.sortOrder,
        sortProp: state.inspectionsReducer.sortProp
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deleteInspection: inspectionId => deleteInspection(dispatch, inspectionId),
        setSortProp: sortProp => setSortProp(dispatch, sortProp),
        toggleFilteredInspections: () => toggleFilteredInspections(dispatch),
        toggleSelectedInspection: inspectionId => toggleSelectedInspection(dispatch, inspectionId)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InspectionsTable));
