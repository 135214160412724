/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import TimeHelper from 'utils/helpers/time-helper';
import { translate } from 'utils/i18n/i18n-model';
import ReportHelper from 'utils/helpers/report-helper';
import stenTableStyles, { border } from 'utils/helpers/pdf-styles/sten-table';
import headings from 'utils/helpers/pdf-styles/headings';
import { getLogoBase64 } from 'utils/base64images';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.PERFORMANCE_SUMMARY.${key}`);
const styles = `
html {
    font-size: 22px;
    font-family: Arial, Helvetica, sans-serif;
}
html, body {
    margin: 0;
    padding: 0;
}
table {
    border-right: ${border};
    border-left: ${border};
}
.page {
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    page-break-inside: avoid;
    page-break-after: always;
}
${stenTableStyles}
.sten-table th {
    white-space: normal;
}
.sten-table--sm td, .sten-table--sm th {
    padding: 0.125rem 0.5rem;
}
.page-break {
}
.page {
    page-break-inside: avoid;
    page-break-after: always;
}
table.header-info {
    margin-bottom: 1rem;
    width: auto;
    border: none !important;
}
table.header-info td {
    display: table-cell;
    vertical-align: top;
    border: none !important;
}
table.header-info tr {
    border: none !important;
}
`;

const cw = [
    10, 3, 10, 3, 4, 10, 3, 2.5, 3, 6, 3.5, 3, 3, 3, 5, 3, 3, 3, 3, 3, 3, 3, 3, 3
];
function widthRangeStyle(from, to) {
    let sum = 0;
    for (let i = from; i <= to; i++) {
        sum += cw[i];
    }
    return { width: `${sum}%` };
}
const totalWidth = cw.reduce((prev, curr) => prev + curr, 0);
cw.unshift(100 - totalWidth);
const w = cw.map((width) => ({ width: `${width}%` }));

function breakRowsJS() {
    const originalTable = document.getElementById('reports-table');
    const tbody = originalTable.getElementsByTagName('tbody')[0];
    const trs = tbody.getElementsByTagName('tr');
    const originalTableWidth = originalTable.offsetWidth;
    const originalThead = originalTable.getElementsByTagName('thead')[0];
    const theadRows = originalThead.getElementsByTagName('tr');
    let dim;
    let headerHeight = 0;
    for (let trIndex = 0; trIndex < theadRows.length; trIndex++) {
        dim = theadRows[trIndex].lastChild.getBoundingClientRect();
        headerHeight += dim.height;
    }
    // A4 210 × 297mm
    // A4 without margins 190 x 277mm
    const magicConstant = 0.91;
    const tbodyHeightLimitWithoutTitleAndHeader = Math.round(
        ((190 * originalTableWidth * magicConstant) / 277) - headerHeight
    );
    const titleAndExclusionsInfoHeight = 325;
    let tbodyHeightLimit = tbodyHeightLimitWithoutTitleAndHeader - titleAndExclusionsInfoHeight;

    // collect row heights
    const rowHeights = new Array(trs.length);
    // let dim;
    for (let trIndex = 0; trIndex < trs.length; trIndex++) {
        dim = trs[trIndex].lastChild.getBoundingClientRect();
        rowHeights[trIndex] = dim.height;
    }

    let currentTr = trs[0];
    let heightSoFar = rowHeights[0];
    let firstCell = currentTr.firstChild;
    let currentVesselNameTh = firstCell;
    let rowSpanOffsetFromVesselName = 1;
    let rowSpanOffsetFromLeg = 1;
    let currentLegTr = currentTr;
    let vesselNameThClone;
    let legTdClone;
    let legStartIndex;
    let legEndIndex;
    let nextRow;

    // creating a page to be used as a model later for cloning pages
    const newBody = document.createDocumentFragment();
    const tableShallowClone = originalTable.cloneNode(false);
    const thead = originalTable.getElementsByTagName('thead')[0].cloneNode(true);
    tableShallowClone.appendChild(thead);
    const tbodyShallowClone = originalTable.getElementsByTagName('tbody')[0].cloneNode(false);
    tableShallowClone.appendChild(tbodyShallowClone);
    const page = document.createElement('div');
    page.classList.add('page');
    page.appendChild(tableShallowClone);

    // mark page breaks in a table
    originalTable.style.display = 'none';
    let isFirstPage = true;
    for (let trIndex = 1; trIndex < trs.length; trIndex++) {
        nextRow = trs[trIndex];
        heightSoFar += rowHeights[trIndex];

        if (heightSoFar > tbodyHeightLimit) { // break row
            if (!(nextRow.firstChild.tagName === 'TH')) { // need to cut rowspans
                if (currentTr.childNodes.length > 2
                    && !(nextRow.firstChild.dataset.legStart === 'true')
                ) { // has leg tds
                    legStartIndex = currentLegTr.firstChild.tagName === 'TH' ? 1 : 0;
                    legEndIndex = legStartIndex + 7;
                    for (let i = legEndIndex; i >= legStartIndex; i--) {
                        legTdClone = currentLegTr.childNodes[i].cloneNode(true);
                        legTdClone.rowSpan -= rowSpanOffsetFromLeg;
                        currentLegTr.childNodes[i].rowSpan = rowSpanOffsetFromLeg;
                        nextRow.insertBefore(legTdClone, nextRow.firstChild);
                    }
                }

                vesselNameThClone = currentVesselNameTh.cloneNode(true);
                currentVesselNameTh.rowSpan = rowSpanOffsetFromVesselName;
                vesselNameThClone.rowSpan -= rowSpanOffsetFromVesselName;
                nextRow.insertBefore(vesselNameThClone, nextRow.firstChild);
            }

            currentTr.classList.add('page-break');
            // for debugging:
            // nextRow.style.backgroundColor = 'red';
            // console.log(heightSoFar);
            heightSoFar = 0;
            if (isFirstPage) {
                tbodyHeightLimit = tbodyHeightLimitWithoutTitleAndHeader;
                isFirstPage = false;
            }
        }

        rowSpanOffsetFromVesselName++;
        rowSpanOffsetFromLeg++;
        currentTr = trs[trIndex];
        firstCell = currentTr.firstChild;
        if (firstCell.tagName === 'TH') {
            currentVesselNameTh = firstCell;
            rowSpanOffsetFromVesselName = 1;
        }
        if (firstCell.tagName === 'TH' || (firstCell.tagName === 'TD' && firstCell.dataset.legStart === 'true')) {
            currentLegTr = currentTr;
            rowSpanOffsetFromLeg = 1;
        }
    }

    let pageClone = page.cloneNode(true);
    let tbodyRef = pageClone.getElementsByTagName('tbody')[0];
    for (let trIndex = 0; trIndex < trs.length; trIndex++) {
        currentTr = trs[trIndex];
        tbodyRef.appendChild(currentTr.cloneNode(true));

        if (currentTr.classList.contains('page-break')) {
            newBody.appendChild(pageClone);
            pageClone = page.cloneNode(true);
            tbodyRef = pageClone.getElementsByTagName('tbody')[0];
        }
    }

    newBody.appendChild(pageClone);
    // document.body.innerHTML = '';
    document.body.appendChild(newBody);
}

class PerformanceSummaryReportPDF extends React.PureComponent {
    reportTopHorizontalHeader = (
        <thead>
            <tr>
                <th style={w[0]} rowSpan="2">{t('VESSEL_NAME')}</th>
                <th style={widthRangeStyle(1, 2)} colSpan="2">{t('DEPARTURE')}</th>
                <th style={widthRangeStyle(3, 4)} colSpan="2">{t('ARRIVAL')}</th>
                <th style={w[5]}>{t('CONDITION')}</th>
                <th style={widthRangeStyle(6, 8)} colSpan="3">{t('DEPARTURE_DATA')}</th>
                <th style={w[9]}>{t('SPEED')}</th>
                <th style={widthRangeStyle(10, 14)} colSpan="5">{t('ALL_REPORTED_DATA')}</th>
                <th style={widthRangeStyle(15, 19)} colSpan="5">{t('DATA_WITH_EXCLUSIONS')}</th>
                <th style={widthRangeStyle(20, 22)} colSpan="3">{t('THEORETICAL_BASIS_CP')}</th>
                <th style={widthRangeStyle(23, 24)} colSpan="2">{t('DEVIATION_FROM_CP')}</th>
            </tr>
            <tr>
                <th style={w[1]}>{t('PORT')}</th>
                <th style={w[2]}>{t('DATE')}</th>
                <th style={w[3]}>{t('PORT')}</th>
                <th style={w[4]}>{t('DATE')}</th>
                <th style={w[5]}>{t('BALLAST_LADEN')}</th>
                <th style={w[6]}>{t('CARGO')}</th>
                <th style={w[7]}>{t('MEAN_DRAFT')}</th>
                <th style={w[8]}>{t('TRIM')}</th>
                <th style={w[9]}>{t('INSTRUCTED_SPEED')}</th>
                <th style={w[10]}>{t('TOTAL_DISTANCE')}</th>
                <th style={w[11]}>{t('TOTAL_TIME')}</th>
                <th style={w[12]}>{t('AVG_SPEED')}</th>
                <th style={w[13]}>{t('TOTAL_MAIN_ENG_CONS')}</th>
                <th style={w[14]}>{t('TOTAL_AUX_CONS')}</th>
                <th style={w[15]}>{t('TOTAL_DISTANCE')}</th>
                <th style={w[16]}>{t('TOTAL_TIME')}</th>
                <th style={w[17]}>{t('AVG_SPEED')}</th>
                <th style={w[18]}>{t('TOTAL_MAIN_ENG_CONS')}</th>
                <th style={w[19]}>{t('TOTAL_AUX_CONS')}</th>
                <th style={w[20]}>{t('AVG_SPEED')}</th>
                <th style={w[21]}>{t('TOTAL_MAIN_ENG_CONS')}</th>
                <th style={w[22]}>{t('TOTAL_AUX_CONS')}</th>
                <th style={w[23]}>{t('TIME_DEVIATION')}</th>
                <th style={w[24]}>{t('CONSUMPTION_DEVIATION')}</th>
            </tr>
        </thead>
    );

    renderSingleReportRow = (vessel, section, sectionIndex, instructedSpeed, index, titleRowSpan) => {
        const rowSpan = Math.max(section.InstructedSpeeds.length, 1);
        let ballastLaden = null;
        if (index === 0) {
            ballastLaden = section.IsBallast ? translate('GLOBAL.BALLAST') : translate('GLOBAL.LADEN');
        }
        return (
            <tr key={`${vessel.Imo}-${sectionIndex}-${index}`}>
                {titleRowSpan ? <th style={w[0]} rowSpan={titleRowSpan}>{vessel.Title}</th> : null}
                {index === 0 && (
                    <React.Fragment>
                        <td style={w[1]} rowSpan={rowSpan} data-leg-start="true">
                            {section.DeparturePort}
                        </td>
                        <td style={w[2]} rowSpan={rowSpan}>
                            {ReportHelper.formatCellValue(section.DepartureDate, true)}
                        </td>
                        <td style={w[3]} rowSpan={rowSpan}>
                            {section.ArrivalPort}
                        </td>
                        <td style={w[4]} rowSpan={rowSpan}>
                            {ReportHelper.formatCellValue(section.ArrivalDate, true)}
                        </td>
                        <td style={w[5]} rowSpan={rowSpan}>
                            {ballastLaden}
                        </td>
                        <td style={w[6]} rowSpan={rowSpan}>
                            {ReportHelper.formatCellValue(section.Cargo)}
                        </td>
                        <td style={w[7]} rowSpan={rowSpan}>
                            {ReportHelper.formatCellValue(section.MeanDraft)}
                        </td>
                        <td style={w[8]} rowSpan={rowSpan}>
                            {ReportHelper.formatCellValue(section.Trim)}
                        </td>
                    </React.Fragment>
                )}
                {instructedSpeed ? (
                    <React.Fragment>
                        <td style={w[9]}>
                            {ReportHelper.formatCellValue(instructedSpeed.InstructedSpeed)}
                        </td>
                        {instructedSpeed.AllReportedData ? (
                            <React.Fragment>
                                <td style={w[10]}>
                                    {ReportHelper.formatCellValue(instructedSpeed.AllReportedData.TotalDistance)}
                                </td>
                                <td style={w[11]}>
                                    {ReportHelper.formatCellValue(instructedSpeed.AllReportedData.TotalTime)}
                                </td>
                                <td style={w[12]}>
                                    {ReportHelper.formatCellValue(instructedSpeed.AllReportedData.AvgSpeed)}
                                </td>
                                <td style={w[13]}>
                                    {ReportHelper.formatCellValue(instructedSpeed.AllReportedData.TotalPropCons)}
                                </td>
                                <td style={w[14]}>
                                    {ReportHelper.formatCellValue(instructedSpeed.AllReportedData.TotalAuxCons)}
                                </td>
                            </React.Fragment>
                        ) : (
                            <td colSpan="5" style={widthRangeStyle(10, 14)} />
                        )}
                        {instructedSpeed.DataWithExclusions ? (
                            <React.Fragment>
                                <td style={w[15]}>
                                    {ReportHelper.formatCellValue(instructedSpeed.DataWithExclusions.TotalDistance)}
                                </td>
                                <td style={w[16]}>
                                    {ReportHelper.formatCellValue(instructedSpeed.DataWithExclusions.TotalTime)}
                                </td>
                                <td style={w[17]}>
                                    {ReportHelper.formatCellValue(instructedSpeed.DataWithExclusions.AvgSpeed)}
                                </td>
                                <td style={w[18]}>
                                    {ReportHelper.formatCellValue(instructedSpeed.DataWithExclusions.TotalPropCons)}
                                </td>
                                <td style={w[19]}>
                                    {ReportHelper.formatCellValue(instructedSpeed.DataWithExclusions.TotalAuxCons)}
                                </td>
                            </React.Fragment>
                        ) : (
                            <td colSpan="5" className="text-secondary" style={widthRangeStyle(15, 19)}>
                                {instructedSpeed.ExclusionReasons
                                    ? instructedSpeed.ExclusionReasons.join('; ')
                                    : '\u00A0'}
                            </td>
                        )}
                        {instructedSpeed.TheoreticalBasisCP ? (
                            <React.Fragment>
                                <td style={w[20]}>
                                    {ReportHelper.formatCellValue(instructedSpeed.TheoreticalBasisCP.AvgSpeed)}
                                </td>
                                <td style={w[21]}>
                                    {ReportHelper.formatCellValue(instructedSpeed.TheoreticalBasisCP.TotalPropCons)}
                                </td>
                                <td style={w[22]}>
                                    {ReportHelper.formatCellValue(instructedSpeed.TheoreticalBasisCP.TotalAuxCons)}
                                </td>
                            </React.Fragment>
                        ) : (
                            <td colSpan="3" style={widthRangeStyle(20, 22)} />
                        )}
                        {instructedSpeed.DeviationFromCP ? (
                            <React.Fragment>
                                <td style={w[23]}>
                                    {ReportHelper.formatCellValue(instructedSpeed.DeviationFromCP.Time)}
                                </td>
                                <td style={w[24]}>
                                    {ReportHelper.formatCellValue(instructedSpeed.DeviationFromCP.Consumption)}
                                </td>
                            </React.Fragment>
                        ) : (
                            <td colSpan="2" style={widthRangeStyle(23, 24)} />
                        )}

                    </React.Fragment>
                ) : (
                    <td
                        colSpan="16"
                        className="text-secondary"
                        style={widthRangeStyle(9, 24)}
                    >
                        {section.ExclusionReasons ? section.ExclusionReasons.join('; ') : '\u00A0'}
                    </td>
                )}
            </tr>
        );
    };

    calculateRowSpan = (sections) => {
        return sections.reduce((res, section) => {
            return res + Math.max(section.InstructedSpeeds.length, 1);
        }, 0);
    };

    renderReportTable = (performanceSummaryReport) => {
        const rows = [];
        let rowSpan = 0;
        // for testing only
        // const testVessels = performanceSummaryReport.Vessels.slice(0, 17);
        // testVessels.forEach((vessel) => {
        performanceSummaryReport.Vessels.forEach((vessel) => {
            if (vessel.Sections.length > 0) {
                vessel.Sections.forEach((section, sectionIndex) => {
                    rowSpan = sectionIndex === 0
                        ? this.calculateRowSpan(vessel.Sections) : 0;
                    if (section.InstructedSpeeds.length > 0) {
                        section.InstructedSpeeds.forEach((instructedSpeed, index) => {
                            if (index > 0) {
                                rowSpan = 0;
                            }
                            rows.push(
                                this.renderSingleReportRow(
                                    vessel, section, sectionIndex, instructedSpeed, index, rowSpan
                                )
                            );
                        }, this);
                    } else {
                        rows.push(
                            this.renderSingleReportRow(vessel, section, sectionIndex, null, 0, rowSpan)
                        );
                    }
                }, this);
            } else {
                rows.push(
                    <tr key={vessel.Imo}>
                        <th style={w[0]}>{vessel.Title}</th>
                        <td style={{ width: `${totalWidth}%` }} colSpan={24}>{t('NO_REPORTS')}</td>
                    </tr>
                );
            }
        }, this);

        return (
            <table id="reports-table" className="sten-table sten-table--sm" style={{ width: '100%' }}>
                {this.reportTopHorizontalHeader}
                <tbody>{rows}</tbody>
            </table>
        );
    };

    renderHeaderInfo() {
        const { inclusions, selectedRange } = this.props;
        const range = selectedRange
            ? `${TimeHelper.getFormatted(selectedRange.rangeStart)}`
                + ` - ${TimeHelper.getFormatted(selectedRange.rangeEnd)}`
            : null;
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h1 style={headings.h1}>{t('PERFORMANCE_SUMMARY_REPORT')}</h1>
                    <table className="sten-table sten-table--sm header-info" style={{ border: 'none' }}>
                        <tr>
                            <td>{t('PERIOD')}</td>
                            <td>{range}
                            </td>
                        </tr>
                        <tr>
                            <td rowSpan="5">{t('PARAMETERS')}</td>
                            <td>{`${t('WIND_SPEED')} ${inclusions.WindSpeedMax}`}</td>
                        </tr>
                        <tr>
                            <td>{`${t('DRAFT_IN_LADEN')} ${inclusions.DraftInLadenMax}`}</td>
                        </tr>
                        <tr>
                            <td>{translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.PERFORMANCE_SUMMARY.BALLAST_BETWEEN', {
                                minSpeed: inclusions.InstructedSpeedBallastMin,
                                maxSpeed: inclusions.InstructedSpeedBallastMax })
                            }
                            </td>
                        </tr>
                        <tr>
                            <td>{translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.PERFORMANCE_SUMMARY.LADEN_BETWEEN', {
                                minSpeed: inclusions.InstructedSpeedLadenMin,
                                maxSpeed: inclusions.InstructedSpeedLadenMax })
                            }
                            </td>
                        </tr>
                        <tr>
                            <td>{`${t('SEA_PASSAGE_MIN')} ${inclusions.SeaPassageMin}`}</td>
                        </tr>
                    </table>
                </div>
                <img
                    src={getLogoBase64().dark}
                    style={{ marginTop: '2rem', height: '5rem' }}
                    alt="Orbit logo"
                />
            </div>
        );
    }

    render() {
        return (
            <html lang="en">
                <head><style>{styles}</style></head>
                <body>
                    {this.renderHeaderInfo()}
                    {this.renderReportTable(this.props.data)}
                    <script
                        type="text/javascript"
                        dangerouslySetInnerHTML={{ __html: `(${breakRowsJS.toString()})();` }}
                    />
                </body>
            </html>
        );
    }
}

PerformanceSummaryReportPDF.propTypes = {
    data: PropTypes.objectOf(PropTypes.any),
    inclusions: PropTypes.objectOf(PropTypes.any),
    selectedRange: PropTypes.objectOf(PropTypes.any)
};

PerformanceSummaryReportPDF.defaultProps = {
    data: null,
    inclusions: null,
    selectedRange: null
};

export default PerformanceSummaryReportPDF;
