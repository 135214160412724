import { translate } from 'utils/i18n/i18n-model';

const tu = (key) => translate(`UNITS.${key}`);

export const cargoProps = [
    { key: 'TotalCargoCarried', decimals: 0, type: 'number', numFmt: '# ### ##0' },
    { key: 'DistanceLaden', decimals: 0, type: 'number', numFmt: '# ### ##0' },
    { key: 'DistanceLadenPercentage', decimals: 2, unit: tu('PERCENTAGE'), type: 'number', numFmt: '0.00"%"' },
    { key: 'DistanceBallast', decimals: 0, type: 'number', numFmt: '# ### ##0' },
    { key: 'DistanceBallastPercentage', decimals: 2, unit: tu('PERCENTAGE'), type: 'number', numFmt: '0.00"%"' },
    { key: 'DaysInPort', decimals: 2, type: 'number', numFmt: '# ### ##0.00' },
    { key: 'DaysInPortPercentage', decimals: 2, unit: tu('PERCENTAGE'), type: 'number', numFmt: '0.00"%"' }
];

const emissionConfig = { decimals: 0, avgDecimals: 4, type: 'number', numFmt: '# ### ##0', avgNumFmt: '0.0000' };

export const emissionProps = [
    { ...emissionConfig, key: 'CO2', label: 'CO2' },
    { ...emissionConfig, key: 'SOx', label: 'SOx' },
    { ...emissionConfig, key: 'NOx', label: 'NOx' },
    { ...emissionConfig, key: 'PM', label: 'PM', decimals: 1, avgDecimals: 6, numFmt: '0.0', avgNumFmt: '0.000000' }
];

export const otherProps = {
    fuel: { decimals: 1, type: 'number', numFmt: '# ### ##0.0' },
    TotalConsumptionAtSea: { decimals: 1, type: 'number', numFmt: '# ### ##0.0' },
    TotalConsumptionInPort: { decimals: 1, type: 'number', numFmt: '# ### ##0.0' },
    FuelPerDistance: { decimals: 4, type: 'number', numFmt: '0.0000' },
    FuelPerCargo: { decimals: 4, type: 'number', numFmt: '0.0000' }
};

export const fuelColWidth = { web: 7, xls: 12, pdf: 5 };

export const colWidths = {
    Value: { web: 20, xls: 36, pdf: 20, modal: 10 },
    TotalCargoCarried: { web: 11, xls: 20, pdf: 8 },
    DistanceLaden: { web: 9, xls: 16, pdf: 7 },
    DistanceLadenPercentage: { web: 6, xls: 12, pdf: 4 },
    DistanceBallast: { web: 9, xls: 16, pdf: 7 },
    DistanceBallastPercentage: { web: 6, xls: 12, pdf: 4 },
    DaysInPort: { web: 7, xls: 12, pdf: 5 },
    DaysInPortPercentage: { web: 6, xls: 12, pdf: 3 },
    TotalConsumptionAtSea: { web: 7, xls: 12, pdf: 9 },
    TotalConsumptionInPort: { web: 7, xls: 12, pdf: 9 },
    CO2: { web: 7, xls: 12, pdf: 5 },
    SOx: { web: 6, xls: 12, pdf: 4 },
    NOx: { web: 6, xls: 12, pdf: 4 },
    PM: { web: 6, xls: 12, pdf: 4 }
};
