import CoreApi from './core-api';

class RoutingService {
    constructor() {
        this.resourceUrl = 'Routing';
    }

    get(params) {
        return CoreApi.post(`${this.resourceUrl}/Routes`, params)
            .then(response => response.data, () => null);
    }
}

export default new RoutingService();
