import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* styles */
import './admin-company-management-platform-statistics-box.scss';

class AdminCompanyManagementPlatformStatisticsBox extends React.PureComponent {
    render() {
        return (
            <div className={`sten-panel${this.props.className ? ` ${this.props.className}` : ''}`}>
                <div className="sten-panel__section spread-v flex-col">
                    <h4 className="sten-admin-company-management-platform-statistics-box__title">
                        {translate('ADMIN.COMPANY_MANAGEMENT.PLATFORM_STATISTICS.TOTAL_USERS_ON_PLATFORM')}
                    </h4>
                    <div className="flex flex-grow flex-center">
                        <h3 className="sten-admin-company-management-platform-statistics-box__value">
                            {this.props.totalUsersOnPlatform ? this.props.totalUsersOnPlatform.Total : '-'}
                        </h3>
                    </div>
                </div>
            </div>
        );
    }
}

AdminCompanyManagementPlatformStatisticsBox.propTypes = {
    className: PropTypes.string,
    totalUsersOnPlatform: PropTypes.objectOf(PropTypes.number)
};

AdminCompanyManagementPlatformStatisticsBox.defaultProps = {
    className: '',
    totalUsersOnPlatform: null
};

export default AdminCompanyManagementPlatformStatisticsBox;
