import React from 'react';
import PropTypes from 'prop-types';
/* helpers */
import { translate } from 'utils/i18n/i18n-model';
import { formatNumber, getClassName } from 'utils/helpers/info-helper';
/* components */
import TankLayoutBar from '../bar/tank-layout-bar';
/* image */
import tankLayoutSvg from './tank-layout-vessel-svg';
/* style */
import './tank-layout-vessel.scss';

const t = (key) => translate(`TANK_LAYOUT.${key}`);

class TankLayoutVessel extends React.PureComponent {
    static getLoadPercentage(data) {
        return typeof data.Loaded === 'number' && data.Size
            ? (data.Loaded / data.Size) * 100
            : null;
    }

    render() {
        return (
            <div className="sten-tank-layout-vessel">
                <div className="sten-tank-layout-vessel__wrapper">
                    {tankLayoutSvg}
                    <div className="sten-tank-layout-vessel__wings flex">
                        {this.props.wings.map((wing) => {
                            const sLoadPercentage = TankLayoutVessel.getLoadPercentage(wing.Starboard);
                            const pLoadPercentage = TankLayoutVessel.getLoadPercentage(wing.Port);
                            let sTitle = wing.Starboard.Commodity || '';
                            let pTitle = wing.Port.Commodity || '';
                            if (wing.Starboard.Commodity && sLoadPercentage !== null) {
                                sTitle += ` (${formatNumber(sLoadPercentage, 0)}%)`;
                            }
                            if (wing.Port.Commodity && pLoadPercentage !== null) {
                                pTitle += ` (${formatNumber(pLoadPercentage, 0)}%)`;
                            }
                            const vesselTankClass = getClassName('sten-tank-layout-vessel__tank', {
                                'sten-tank-layout-vessel__tank--slop': wing.IsSlop
                            });
                            return (
                                <div key={wing.Index} className="sten-tank-layout-vessel__wing flex-column flex-grow">
                                    <div className={vesselTankClass} title={sTitle}>
                                        <div className="flex-grow flex flex-center">
                                            {`${wing.IsSlop ? 'S' : wing.Index + 1}S` }
                                        </div>
                                        <TankLayoutBar
                                            className="flex-shrink"
                                            value={sLoadPercentage}
                                            isSlop={wing.Starboard.IsSlop}
                                        />
                                    </div>
                                    <div className={vesselTankClass} title={pTitle}>
                                        <TankLayoutBar
                                            className="flex-shrink"
                                            value={pLoadPercentage}
                                            isSlop={wing.Port.IsSlop}
                                        />
                                        <div className="flex-grow flex flex-center">
                                            {`${wing.IsSlop ? 'S' : wing.Index + 1}P` }
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {this.props.showLegend && (
                    <div className="sten-tank-layout-vessel__legend">
                        <div className="sten-tank-layout-vessel__legend-item">{t('CARGO_TANK')}</div>
                        <div className="sten-tank-layout-vessel__legend-item">{t('SLOP_TANK')}</div>
                    </div>
                )}
            </div>
        );
    }
}

TankLayoutVessel.propTypes = {
    showLegend: PropTypes.bool,
    wings: PropTypes.arrayOf(PropTypes.object)
};

TankLayoutVessel.defaultProps = {
    showLegend: true,
    wings: []
};

export default TankLayoutVessel;
