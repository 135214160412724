import { createSelector } from 'reselect';

export const getTankerDetails = createSelector(
    state => state.gsmReducer.selectedTanker,

    (selectedTanker) => {
        if (selectedTanker) {
            const translatePrefix = 'GSM.VESSEL_INFO.';
            const displayProperties = [
                {
                    key: 'Trading',
                    label: `${translatePrefix}TRADING_AREA`
                },
                {
                    key: 'Delivered',
                    label: `${translatePrefix}YEAR_BUILT`,
                    formatNumber: false
                },
                {
                    key: 'DesignSpeed',
                    label: `${translatePrefix}DESIGN_SPEED`,
                    valueSuffix: 'UNITS.SPEED',
                    roundNumber: true
                },
                {
                    key: 'Loa',
                    label: `${translatePrefix}LENGTH`,
                    valueSuffix: 'UNITS.LOA',
                    roundNumber: true
                },
                {
                    key: 'Beam',
                    label: `${translatePrefix}BREADTH`,
                    valueSuffix: 'UNITS.BEAM',
                    roundNumber: true
                },
                {
                    key: 'Draft',
                    label: `${translatePrefix}DESIGN_DRAFT`,
                    valueSuffix: 'UNITS.DRAFT',
                    roundNumber: true
                },
                {
                    key: 'Deadweight',
                    label: `${translatePrefix}DEADWEIGHT`,
                    valueSuffix: 'UNITS.DWT',
                    roundNumber: true
                },
                {
                    key: 'TotalCubicCapacity',
                    label: `${translatePrefix}CUBIC_CAPACITY`,
                    valueSuffix: 'UNITS.CUBIC_CAPACITY',
                    roundNumber: true
                }
            ];

            return displayProperties.map((item) => {
                const detail = {
                    name: item.label,
                    metricsUnit: item.valueSuffix ? item.valueSuffix : null
                };

                if ((item.key === 'Draft' || item.key === 'Deadweight') && selectedTanker.Dimensions) {
                    const dimensionsNormalBallast = selectedTanker.Dimensions.find(
                        element => element.Type === 'Summer'
                    );

                    if (dimensionsNormalBallast) {
                        const loadlinesDraftOrDeadweight = dimensionsNormalBallast.Loadlines.find(
                            element => element.LoadlineType === item.key
                        );
                        detail.value = loadlinesDraftOrDeadweight !== null && loadlinesDraftOrDeadweight !== undefined
                            ? loadlinesDraftOrDeadweight.Value : '';
                    } else {
                        detail.value = '';
                    }
                } else if (item.key === 'Delivered') {
                    detail.value = selectedTanker[item.key] ? new Date(selectedTanker[item.key]).getFullYear() : '';
                } else {
                    detail.value = selectedTanker[item.key] !== null
                        && selectedTanker[item.key] !== undefined
                        && selectedTanker[item.key].length !== 0
                        ? selectedTanker[item.key] : '';
                }

                // Round the number
                if (item.roundNumber) {
                    detail.value = Math.round(detail.value);
                }
                if (item.formatNumber === false) {
                    detail.formatNumber = item.formatNumber;
                }
                return detail;
            });
        }
        return [];
    }
);

export const getFerryDetails = createSelector(
    state => state.gsmReducer.selectedFerry,

    (selectedFerry) => {
        if (selectedFerry) {
            let selectedFerryCopy = { ...selectedFerry };
            const translatePrefix = 'GSM.VESSEL_INFO.';
            let displayProperties = [
                {
                    key: 'YearBuilt',
                    label: `${translatePrefix}YEAR_BUILT`,
                    formatNumber: false
                },
                {
                    key: 'PassengerCapacity',
                    label: `${translatePrefix}PASSENGER_CAPACITY`
                },
                {
                    key: 'CarCapacity',
                    label: `${translatePrefix}CAR_CAPACITY`
                },
                {
                    key: 'LaneMeterCapacity',
                    label: `${translatePrefix}LANE_METER_CAPACITY`,
                    valueSuffix: 'UNITS.LINE_METER_CAPACITY',
                    roundNumber: true
                }
            ];

            if (selectedFerryCopy.LineFerryProperties) {
                const lineFerryDisplayProperties = [
                    {
                        key: 'DesignSpeed',
                        label: `${translatePrefix}DESIGN_SPEED`,
                        valueSuffix: 'UNITS.SPEED',
                        roundNumber: true
                    },
                    {
                        key: 'Length',
                        label: `${translatePrefix}LENGTH`,
                        valueSuffix: 'UNITS.LOA',
                        roundNumber: true
                    },
                    {
                        key: 'DesignDrafts',
                        label: `${translatePrefix}DESIGN_DRAFT`,
                        valueSuffix: 'UNITS.DRAFT',
                        roundNumber: true
                    },
                    {
                        key: 'BedCapacity',
                        label: `${translatePrefix}BED_CAPACITY`
                    },
                    {
                        key: 'Breadth',
                        label: `${translatePrefix}BREADTH`,
                        valueSuffix: 'UNITS.BEAM',
                        roundNumber: true
                    },
                    {
                        key: 'ServicesOnboard',
                        label: `${translatePrefix}SERVICES_ONBOARD`
                    }
                ];
                displayProperties = displayProperties.concat(lineFerryDisplayProperties);
                const lineProperties = selectedFerryCopy.LineFerryProperties;
                selectedFerryCopy = {
                    ...selectedFerryCopy,
                    ...lineProperties
                };
            } else if (selectedFerryCopy.RoRoFerryProperties) {
                const roroFerryDisplayProperties = [
                    {
                        key: 'GrossTonnage',
                        label: `${translatePrefix}GROSS_TONNAGE`,
                        valueSuffix: 'UNITS.GROSS_TONNAGE',
                        roundNumber: true
                    }
                ];
                displayProperties = displayProperties.concat(roroFerryDisplayProperties);
                const roroProperties = selectedFerryCopy.RoRoFerryProperties;
                selectedFerryCopy = {
                    ...selectedFerryCopy,
                    ...roroProperties
                };
            }

            return displayProperties.map((item) => {
                const detail = {
                    name: item.label,
                    metricsUnit: item.valueSuffix ? item.valueSuffix : null
                };

                switch (item.key) {
                case 'ServicesOnboard': {
                    detail.value = selectedFerryCopy[item.key] && selectedFerryCopy[item.key].reduce(
                        (sum, currentValue, currentIndex) => sum + currentValue.toString() + (
                            currentIndex < selectedFerryCopy[item.key].length - 1 ? ', ' : ''), ''
                    );
                    break;
                }

                case 'YearBuilt': {
                    detail.value = selectedFerryCopy[item.key];
                    break;
                }

                case 'DesignDrafts': {
                    if (selectedFerryCopy[item.key] && selectedFerryCopy[item.key].length) {
                        const infoItem = selectedFerryCopy[item.key].find(designDraft => designDraft.Type === 'Info');

                        detail.value = infoItem ? infoItem.Value : '';
                    }
                    break;
                }

                default: {
                    detail.value = selectedFerryCopy[item.key] !== null
                        && selectedFerryCopy[item.key] !== undefined
                        && selectedFerryCopy[item.key].length !== 0
                        ? selectedFerryCopy[item.key] : '';
                    break;
                }
                }

                // Round the number
                if (item.roundNumber) {
                    detail.value = Math.round(detail.value);
                }
                if (item.formatNumber === false) {
                    detail.formatNumber = item.formatNumber;
                }

                return detail;
            });
        }
        return [];
    }
);

export const getDrillDetails = createSelector(
    state => state.gsmReducer.selectedDrill,

    (selectedDrill) => {
        if (selectedDrill) {
            const translatePrefix = 'GSM.VESSEL_INFO.';
            const displayProperties = [
                {
                    key: 'TradingArea',
                    label: `${translatePrefix}TRADING_AREA`
                },
                {
                    key: 'YearBuilt',
                    label: `${translatePrefix}YEAR_BUILT`,
                    formatNumber: false
                },
                {
                    key: 'Length',
                    label: `${translatePrefix}LENGTH`,
                    valueSuffix: 'UNITS.LOA',
                    roundNumber: true
                },
                {
                    key: 'Breadth',
                    label: `${translatePrefix}BREADTH`,
                    valueSuffix: 'UNITS.BEAM',
                    roundNumber: true
                },
                {
                    key: 'Operational',
                    label: `${translatePrefix}OPERATIONAL`,
                    valueSuffix: 'UNITS.DRAFT',
                    roundNumber: true
                },
                {
                    key: 'Transit',
                    label: `${translatePrefix}TRANSIT`,
                    valueSuffix: 'UNITS.DRAFT',
                    roundNumber: true
                },
                {
                    key: 'MaxDrillingDepth',
                    label: `${translatePrefix}MAX_DRILLING_DEPTCH`,
                    valueSuffix: 'UNITS.MAX_DRILLING_DEPTH',
                    roundNumber: true
                },
                {
                    key: 'MaxWaterDepth',
                    label: `${translatePrefix}MAX_WATER_DEPTH`,
                    valueSuffix: 'UNITS.MAX_WATER_DEPTH',
                    roundNumber: true
                },
                {
                    key: 'PeopleOnBoard',
                    label: `${translatePrefix}PEOPLE_ON_BOARD`
                }
            ];

            return displayProperties.map((item) => {
                const detail = {
                    name: item.label,
                    metricsUnit: item.valueSuffix ? item.valueSuffix : null
                };
                if (item.key === 'Operational' || item.key === 'Transit') {
                    detail.value = selectedDrill.DesignDrafts
                        .find(designDraftsItem => designDraftsItem.Type === item.key).Value;
                } else {
                    detail.value = selectedDrill[item.key] !== null
                        && selectedDrill[item.key] !== undefined
                        && selectedDrill[item.key].length !== 0
                        ? selectedDrill[item.key] : '';
                }

                // Round the number
                if (item.roundNumber) {
                    detail.value = Math.round(detail.value);
                }
                if (item.formatNumber === false) {
                    detail.formatNumber = item.formatNumber;
                }

                return detail;
            });
        }
        return [];
    }
);
