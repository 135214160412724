import { ActionTypes } from './port-management-actions';

const emptyArray = [];

const initialState = {
    isMounted: false,
    bunkeringTypes: emptyArray,
    columns: emptyArray,
    countries: emptyArray,
    services: emptyArray,
    pinnedPorts: emptyArray,
    ports: null,
    portParams: {
        Limit: 20,
        Offset: 0,
        CountryName: '',
        PortName: '',
        TerminalName: '',
        BerthName: '',
        SortBy: 'Name',
        SortOrder: 'ASC'
    }
};

export default function portManagementReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.PORT_MANAGEMENT_SET: {
        return {
            ...state,
            ports: action.ports,
            portParams: action.params
        };
    }
    case ActionTypes.PORT_MANAGEMENT_RESET_PORTS: {
        return {
            ...state,
            ports: initialState.ports,
            portParams: initialState.portParams
        };
    }
    case ActionTypes.PORT_MANAGEMENT_SET_PARAMS: {
        return {
            ...state,
            portParams: action.params
        };
    }
    case ActionTypes.PORT_MANAGEMENT_SET_PINNED: {
        return {
            ...state,
            pinnedPorts: action.ports || emptyArray
        };
    }
    case ActionTypes.PORT_MANAGEMENT_SET_OPTIONS: {
        return {
            ...state,
            isMounted: true,
            bunkeringTypes: action.bunkeringTypes,
            columns: action.columns || emptyArray,
            countries: action.countries || emptyArray,
            services: action.services || emptyArray
        };
    }
    case ActionTypes.PORT_MANAGEMENT_RESET: {
        return initialState;
    }
    default:
        return state;
    }
}
