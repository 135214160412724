/* components */
import Confirm from './modal-confirm';
import Delete from './modal-delete';
import Default from './modal-default';
import DatetimePicker from './modal-datetime-picker';
/* styles */
import './modal.scss';

export default {
    Confirm,
    Delete,
    Default,
    DatetimePicker
};
