/* eslint-disable max-len */
import { sortByProp } from 'utils/helpers/array-helper';
import MockHelper from 'utils/helpers/mock-helper';

const fleetsArray = [
    { Id: 1, Name: 'Stena Bulk LNG' },
    { Id: 2, Name: 'GSB Tankers' },
    { Id: 3, Name: 'Stena Bulk Products and Chemicals' },
    { Id: 4, Name: 'Stena Sonangol Suezmax Pool' },
    { Id: 5, Name: 'Stena Bulk Crude and Fuel' }
];

const contractTypesMap = {
    0: 'Owned',
    1: 'Bareboat Charter',
    2: 'Time Charter',
    3: 'Commercial Management'
};

const emptyEmissions = {
    CO2: null,
    SOx: null,
    NOx: null,
    PM: null
};

const getEmptyData = (id = null, name = '') => ({
    AvgConsumptionAnchoredDrifting: null,
    AvgConsumptionAtSea: null,
    AvgConsumptionAtSeaBallast: null,
    AvgConsumptionAtSeaLaden: null,
    AvgConsumptionCargoDischarge: null,
    AvgConsumptionCargoLoad: null,
    AvgConsumptionInCanal: null,
    AvgConsumptionInPort: null,
    ConsumptionsAnchoredDrifting: [],
    ConsumptionsAtSea: [],
    ConsumptionsAtSeaBallast: [],
    ConsumptionsAtSeaLaden: [],
    ConsumptionsCargoDischarge: [],
    ConsumptionsCargoLoad: [],
    ConsumptionsInCanal: [],
    ConsumptionsInPort: [],
    DaysAnchoredDrifting: null,
    DaysAtSea: null,
    DaysBallast: null,
    DaysCargoDischarge: null,
    DaysCargoLoad: null,
    DaysInCanal: null,
    DaysInCanalPercentage: null,
    DaysInPort: null,
    DaysInPortPercentage: null,
    DaysLaden: null,
    DistanceBallast: null,
    DistanceBallastPercentage: null,
    DistanceLaden: null,
    DistanceLadenPercentage: null,
    Emissions: { ...emptyEmissions },
    Id: id,
    Name: name,
    TotalCargoCarried: null,
    TotalConsumptionAnchoredDrifting: null,
    TotalConsumptionAtSea: null,
    TotalConsumptionAtSeaBallast: null,
    TotalConsumptionAtSeaLaden: null,
    TotalConsumptionCargoDischarge: null,
    TotalConsumptionCargoLoad: null,
    TotalConsumptionInCanal: null,
    TotalConsumptionInPort: null
});

const emissionKeys = ['CO2', 'SOx', 'NOx', 'PM'];

const generateFleets = () => {
    const fleets = MockHelper.getArraySlice(fleetsArray, 0, 2);
    return fleets.map(fleet => ({
        ...fleet,
        ContractTypeId: MockHelper.getRandomNumber(0, 3)
    }));
};

const generateVesselsWithFleets = (vessels) => vessels.map(vessel => ({
    ...vessel,
    Fleets: generateFleets()
}));

const generateDataRow = (name, id, nullPercentage = 0.5) => {
    if (Math.random() < nullPercentage) {
        return getEmptyData(id, name);
    }
    const fuelTypes = MockHelper.getRandomFuelTypes(1, 2);

    const TotalDistance = MockHelper.getRandomNumber(20000, 100000);
    const DistanceLaden = MockHelper.getRandomNumber(20000, TotalDistance);
    const DistanceLadenPercentage = Number(((DistanceLaden / TotalDistance) * 100).toFixed(2));

    const DaysBallast = MockHelper.getRandomNumber(0, 100, 0, 0, 0.1);
    const DaysLaden = MockHelper.getRandomNumber(0, 100, 0, 0, 0.1);
    const DaysAtSea = DaysBallast + DaysLaden;

    const DaysInCanal = MockHelper.getRandomNumber(0, 100, 0, 0, 0.1);

    const DaysAnchoredDrifting = MockHelper.getRandomNumber(0, 100, 0, 0, 0.1);
    const DaysCargoLoad = MockHelper.getRandomNumber(0, 100, 0, 0, 0.1);
    const DaysCargoDischarge = MockHelper.getRandomNumber(0, 100, 0, 0, 0.1);
    const DaysInPort = DaysAnchoredDrifting + DaysCargoLoad + DaysCargoDischarge;

    const ConsumptionsAtSeaBallast = MockHelper.getFuelTypeQuantities(0, 1000, 2, fuelTypes);
    const ConsumptionsAtSeaLaden = MockHelper.getFuelTypeQuantities(0, 1000, 2, fuelTypes);
    const ConsumptionsAtSea = ConsumptionsAtSeaBallast.map((fuelType, index) => ({
        ...fuelType,
        Quantity: fuelType.Quantity + ConsumptionsAtSeaLaden[index].Quantity
    }));

    const ConsumptionsInCanal = MockHelper.getFuelTypeQuantities(2000, 10000, 2, fuelTypes);

    const ConsumptionsAnchoredDrifting = MockHelper.getFuelTypeQuantities(0, 1000, 2, fuelTypes);
    const ConsumptionsCargoLoad = MockHelper.getFuelTypeQuantities(0, 1000, 2, fuelTypes);
    const ConsumptionsCargoDischarge = MockHelper.getFuelTypeQuantities(0, 1000, 2, fuelTypes);
    const ConsumptionsInPort = ConsumptionsAnchoredDrifting.map((fuelType, index) => ({
        ...fuelType,
        Quantity: fuelType.Quantity + ConsumptionsCargoLoad[index].Quantity + ConsumptionsCargoDischarge[index].Quantity
    }));

    const TotalConsumptionAtSea = ConsumptionsAtSea.length > 0 ? ConsumptionsAtSea.reduce((sum, fuelType) => sum + fuelType.Quantity, 0) : null;
    const TotalConsumptionAtSeaBallast = ConsumptionsAtSeaBallast.length > 0 ? ConsumptionsAtSeaBallast.reduce((sum, fuelType) => sum + fuelType.Quantity, 0) : null;
    const TotalConsumptionAtSeaLaden = ConsumptionsAtSeaLaden.length > 0 ? ConsumptionsAtSeaLaden.reduce((sum, fuelType) => sum + fuelType.Quantity, 0) : null;
    const TotalConsumptionInCanal = ConsumptionsInCanal.length > 0 ? ConsumptionsInCanal.reduce((sum, fuelType) => sum + fuelType.Quantity, 0) : null;
    const TotalConsumptionAnchoredDrifting = ConsumptionsAnchoredDrifting.length > 0 ? ConsumptionsAnchoredDrifting.reduce((sum, fuelType) => sum + fuelType.Quantity, 0) : null;
    const TotalConsumptionCargoDischarge = ConsumptionsCargoDischarge.length > 0 ? ConsumptionsCargoDischarge.reduce((sum, fuelType) => sum + fuelType.Quantity, 0) : null;
    const TotalConsumptionCargoLoad = ConsumptionsCargoLoad.length > 0 ? ConsumptionsCargoLoad.reduce((sum, fuelType) => sum + fuelType.Quantity, 0) : null;
    const TotalConsumptionInPort = ConsumptionsInPort.length > 0 ? ConsumptionsInPort.reduce((sum, fuelType) => sum + fuelType.Quantity, 0) : null;

    return {
        AvgConsumptionAnchoredDrifting: DaysAnchoredDrifting ? TotalConsumptionAnchoredDrifting / DaysAnchoredDrifting : null,
        AvgConsumptionAtSea: DaysAtSea ? TotalConsumptionAtSea / DaysAtSea : null,
        AvgConsumptionAtSeaBallast: DaysBallast ? TotalConsumptionAtSeaBallast / DaysBallast : null,
        AvgConsumptionAtSeaLaden: DaysLaden ? TotalConsumptionAtSeaLaden / DaysLaden : null,
        AvgConsumptionCargoDischarge: DaysCargoDischarge ? TotalConsumptionCargoDischarge / DaysCargoDischarge : null,
        AvgConsumptionCargoLoad: DaysCargoLoad ? TotalConsumptionCargoLoad / DaysCargoLoad : null,
        AvgConsumptionInCanal: DaysInCanal ? TotalConsumptionInCanal / DaysInCanal : null,
        AvgConsumptionInPort: DaysInPort ? TotalConsumptionInPort / DaysInPort : null,
        ConsumptionsAnchoredDrifting,
        ConsumptionsAtSea,
        ConsumptionsAtSeaBallast,
        ConsumptionsAtSeaLaden,
        ConsumptionsCargoDischarge,
        ConsumptionsCargoLoad,
        ConsumptionsInCanal,
        ConsumptionsInPort,
        DaysAnchoredDrifting,
        DaysAtSea,
        DaysBallast,
        DaysCargoDischarge,
        DaysCargoLoad,
        DaysInCanal,
        DaysInCanalPercentage: MockHelper.getRandomNumber(0, 10, 2),
        DaysInPort,
        DaysInPortPercentage: MockHelper.getRandomNumber(0, 20, 2),
        DaysLaden,
        DistanceBallast: parseInt(TotalDistance - DistanceLaden, 10),
        DistanceBallastPercentage: Number((100 - DistanceLadenPercentage).toFixed(2)),
        DistanceLaden,
        DistanceLadenPercentage,
        Emissions: {
            CO2: MockHelper.getRandomNumber(20000, 40000, 2),
            SOx: MockHelper.getRandomNumber(100, 600),
            NOx: MockHelper.getRandomNumber(400, 1000),
            PM: MockHelper.getRandomNumber(4, 15, 1)
        },
        Id: id,
        Name: name,
        TotalCargoCarried: MockHelper.getRandomNumber(100000, 800000),
        TotalConsumptionAnchoredDrifting,
        TotalConsumptionAtSea,
        TotalConsumptionAtSeaBallast,
        TotalConsumptionAtSeaLaden,
        TotalConsumptionCargoDischarge,
        TotalConsumptionCargoLoad,
        TotalConsumptionInCanal,
        TotalConsumptionInPort
    };
};

const emptySummary = {
    Total: getEmptyData(),
    FuelPerDistance: null,
    FuelPerCargo: null,
    EmissionsPerCargo: { ...emptyEmissions }
};

const summaryProps = [
    'TotalCargoCarried',
    'DistanceLaden',
    'DistanceBallast',
    'DaysLaden',
    'DaysBallast',
    'DaysAtSea',
    'DaysInCanal',
    'DaysAnchoredDrifting',
    'DaysCargoLoad',
    'DaysCargoDischarge',
    'DaysInPort',
    'AvgConsumptionAnchoredDrifting',
    'AvgConsumptionAtSea',
    'AvgConsumptionAtSeaBallast',
    'AvgConsumptionAtSeaLaden',
    'AvgConsumptionCargoDischarge',
    'AvgConsumptionCargoLoad',
    'AvgConsumptionInCanal',
    'AvgConsumptionInPort',
    'TotalConsumptionAtSeaBallast',
    'TotalConsumptionAtSeaLaden',
    'TotalConsumptionAtSea',
    'TotalConsumptionInCanal',
    'TotalConsumptionAnchoredDrifting',
    'TotalConsumptionCargoLoad',
    'TotalConsumptionCargoDischarge',
    'TotalConsumptionInPort'
];

const getCalculatedSummary = (elements) => {
    const summary = {
        ...emptySummary,
        Total: getEmptyData(),
        EmissionsPerCargo: { ...emptyEmissions }
    };
    const consumptions = {
        ConsumptionsAtSeaBallast: {},
        ConsumptionsAtSeaLaden: {},
        ConsumptionsAtSea: {},
        ConsumptionsInCanal: {},
        ConsumptionsAnchoredDrifting: {},
        ConsumptionsCargoLoad: {},
        ConsumptionsCargoDischarge: {},
        ConsumptionsInPort: {}
    };
    let DistanceLadenPercentageSum = null;
    let DistanceBallastPercentageSum = null;
    let DaysInCanalPercentageSum = null;
    let DaysInPortPercentageSum = null;
    let elementsCount = 0;
    elements.forEach((element) => {
        if (element) {
            elementsCount++;
            summaryProps.forEach((prop) => {
                if (element[prop]) {
                    summary.Total[prop] += element[prop];
                }
            });
            if (element.DistanceLadenPercentage) DistanceLadenPercentageSum += element.DistanceLadenPercentage;
            if (element.DistanceBallastPercentage) DistanceBallastPercentageSum += element.DistanceBallastPercentage;
            if (element.DaysInCanalPercentage) DaysInCanalPercentageSum += element.DaysInCanalPercentage;
            if (element.DaysInPortPercentage) DaysInPortPercentageSum += element.DaysInPortPercentage;
            Object.keys(consumptions).forEach(consKey => {
                element[consKey].forEach(fuelType => {
                    if (!consumptions[consKey][fuelType.Id]) {
                        consumptions[consKey][fuelType.Id] = { ...fuelType };
                    } else {
                        consumptions[consKey][fuelType.Id].Quantity += fuelType.Quantity;
                    }
                });
            });
            emissionKeys.forEach((key) => {
                if (element.Emissions[key]) {
                    summary.Total.Emissions[key] += element.Emissions[key];
                }
            });
        }
    });
    summary.Total.DistanceLadenPercentage = DistanceLadenPercentageSum ? Number((DistanceLadenPercentageSum / elementsCount).toFixed(2)) : null;
    summary.Total.DistanceBallastPercentage = DistanceBallastPercentageSum ? Number((DistanceBallastPercentageSum / elementsCount).toFixed(2)) : null;
    Object.keys(consumptions).forEach(consKey => {
        summary.Total[consKey] = Object.keys(consumptions[consKey]).map(fuelKey => consumptions[consKey][fuelKey]).sort(sortByProp('Sequence'));
    });
    summary.Total.DaysInCanalPercentage = DaysInCanalPercentageSum ? Number((DaysInCanalPercentageSum / elementsCount).toFixed(2)) : null;
    summary.Total.DaysInPortPercentage = DaysInPortPercentageSum ? Number((DaysInPortPercentageSum / elementsCount).toFixed(2)) : null;
    summary.FuelPerDistance = Number((summary.Total.TotalConsumptionAtSea / (summary.Total.DistanceLaden + summary.Total.DistanceBallast)).toFixed(4));
    if (isNaN(summary.FuelPerDistance)) {
        summary.FuelPerDistance = null;
    }
    summary.FuelPerCargo = Number((summary.Total.TotalConsumptionAtSea / summary.Total.TotalCargoCarried).toFixed(4));
    if (isNaN(summary.FuelPerCargo)) {
        summary.FuelPerCargo = null;
    }
    emissionKeys.forEach((key) => {
        summary.EmissionsPerCargo[key] = Number((summary.Total.Emissions[key] / summary.Total.TotalCargoCarried).toFixed(key === 'PM' ? 6 : 4));
        if (isNaN(summary.EmissionsPerCargo[key])) {
            summary.EmissionsPerCargo[key] = null;
        }
    });
    return summary;
};

/* eslint-disable no-param-reassign */
export const generateMockedFleetPerformanceData = (userVessels, min = 50, max = 200) => {
    const vesselsWithFleets = generateVesselsWithFleets(MockHelper.getArraySlice(userVessels, min, max));
    const segmentMap = {};
    const segmentFleetMap = {};
    const segmentFleetContractMap = {};
    const data = {
        Summary: {},
        Segments: []
    };
    let sIndex;
    let sfIndex;
    let sfcIndex;
    vesselsWithFleets.forEach((vessel) => {
        if (segmentMap[vessel.VesselTypeId] === undefined) {
            data.Segments.push({
                Name: vessel.VesselTypeName,
                Id: vessel.VesselTypeId,
                Summary: null,
                Fleets: [],
                OtherVessels: null
            });
            segmentMap[vessel.VesselTypeId] = data.Segments.length - 1;
            segmentFleetMap[vessel.VesselTypeId] = {};
            segmentFleetContractMap[vessel.VesselTypeId] = {};
        }
        sIndex = segmentMap[vessel.VesselTypeId];
        const vesselData = generateDataRow(vessel.Title, vessel.Imo);
        if (vessel.Fleets.length > 0) {
            vessel.Fleets.forEach((fleet) => {
                if (segmentFleetMap[vessel.VesselTypeId][fleet.Id] === undefined) {
                    data.Segments[sIndex].Fleets.push({
                        Name: fleet.Name,
                        Id: fleet.Id,
                        Summary: null,
                        Contracts: []
                    });
                    segmentFleetMap[vessel.VesselTypeId][fleet.Id] = data.Segments[sIndex].Fleets.length - 1;
                    segmentFleetContractMap[vessel.VesselTypeId][fleet.Id] = {};
                }
                sfIndex = segmentFleetMap[vessel.VesselTypeId][fleet.Id];
                if (segmentFleetContractMap[vessel.VesselTypeId][fleet.Id][fleet.ContractTypeId] === undefined) {
                    data.Segments[sIndex]
                        .Fleets[sfIndex].Contracts.push({
                            Name: contractTypesMap[fleet.ContractTypeId],
                            Id: fleet.ContractTypeId,
                            Summary: null,
                            Vessels: []
                        });
                    segmentFleetContractMap[vessel.VesselTypeId][fleet.Id][fleet.ContractTypeId] = data.Segments[sIndex].Fleets[sfIndex].Contracts.length - 1;
                }
                sfcIndex = segmentFleetContractMap[vessel.VesselTypeId][fleet.Id][fleet.ContractTypeId];
                data.Segments[sIndex].Fleets[sfIndex].Contracts[sfcIndex].Vessels.push(vesselData);
            });
        } else {
            if (!data.Segments[sIndex].OtherVessels) {
                data.Segments[sIndex].OtherVessels = {
                    Summary: null,
                    Vessels: []
                };
            }
            data.Segments[sIndex].OtherVessels.Vessels.push(vesselData);
        }
    });

    data.Segments.forEach((segment) => {
        segment.Fleets.forEach((fleet) => {
            fleet.Contracts.sort(sortByProp('Id'));
            fleet.Contracts.forEach((contract) => {
                contract.Summary = getCalculatedSummary(contract.Vessels);
            });
            fleet.Summary = getCalculatedSummary(
                fleet.Contracts.map(contract => contract.Summary.Total)
            );
        });
        if (segment.OtherVessels) {
            segment.OtherVessels.Summary = getCalculatedSummary(segment.OtherVessels.Vessels);
        }
        segment.Summary = getCalculatedSummary([
            ...segment.Fleets.map(fleet => fleet.Summary.Total),
            segment.OtherVessels ? segment.OtherVessels.Summary.Total : null
        ]);
    });

    data.Summary = getCalculatedSummary(data.Segments.map(segment => segment.Summary.Total));

    return data;
};
