import { takeEvery, put, all } from 'redux-saga/effects';
/* actions */
import { ActionTypes } from './kpi-actions';
/* Services */
import ConfigService from 'services/config-api/config-service';
import LocalStorageService from 'services/local-storage-service';
import EnergyManagementService from 'services/core-api/energy-management-service';
import SearchService from 'services/core-api/search-service';
/* Mocks */
import { mockedKpiVessels, mockedKpiRangeOptions } from './mocks';

function* getInitialData() {
    const {
        vessels,
        rangeOptions
    } = LocalStorageService.isDebugModeActive()
        || ConfigService.featureToggles.showKpiWithMockedData
        ? {
            vessels: mockedKpiVessels,
            rangeOptions: mockedKpiRangeOptions
        }
        : yield all({
            vessels: SearchService.getVesselsByScope({
                AreVesselsForKpi: true,
                FilterLNGVessels: true
            }),
            rangeOptions: EnergyManagementService.KPI.getRangeOptions()
        });
    if (vessels) {
        yield put({ type: ActionTypes.ENERGY_MANAGEMENT_KPI_SET_VESSELS, vessels });
    }
    if (rangeOptions) {
        yield put({ type: ActionTypes.ENERGY_MANAGEMENT_KPI_SET_RANGE_OPTIONS, rangeOptions });
    }
}

export default function* energyManagementKPISaga() {
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_KPI_GET_INITIAL_DATA, getInitialData);
}
