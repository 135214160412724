export const isValidDateObj = (date) => {
    return date && date.year !== undefined && date.month !== undefined && date.day !== undefined;
};

export const isValidTimeObj = (date) => {
    return date && date.hour !== undefined && date.minute !== undefined && date.second !== undefined;
};

export const isValidDateTimeObj = (dateTime) => {
    return isValidDateObj(dateTime) && isValidDateTimeObj(dateTime);
};

export const isValidDate = (date) => {
    if (!isValidDateObj(date)) {
        return false;
    }
    const validDate = new Date(date.year, date.month - 1, date.day);
    validDate.setFullYear(date.year);
    return date.day === validDate.getDate()
        && date.month === validDate.getMonth() + 1
        && date.year === validDate.getFullYear();
};

export const isValidTime = (time) => {
    if (!isValidTimeObj(time)) {
        return false;
    }
    return time.hour >= 0 && time.hour < 24
        && time.minute >= 0 && time.minute < 60
        && time.second >= 0 && time.second < 60;
};

export const compareDates = (a, b) => {
    if (a === null || b === null) {
        return null;
    }
    if (a.year < b.year) {
        return -1;
    } if (a.year === b.year) {
        if (a.month < b.month) {
            return -1;
        } if (a.month === b.month) {
            if (a.day < b.day) {
                return -1;
            } if (a.day === b.day) {
                return 0;
            }
        }
    }
    return 1;
};

export const compareDateTimes = (a, b) => {
    if (a === null || b === null) {
        return null;
    } if (a.year < b.year) {
        return -1;
    } if (a.year === b.year) {
        if (a.month < b.month) {
            return -1;
        } if (a.month === b.month) {
            if (a.day < b.day) {
                return -1;
            } if (a.day === b.day) {
                if (a.hour < b.hour) {
                    return -1;
                } if (a.hour === b.hour) {
                    if (a.minute < b.minute) {
                        return -1;
                    } if (a.minute === b.minute) {
                        if (a.second < b.second) {
                            return -1;
                        } if (a.second === b.second) {
                            return 0;
                        }
                    }
                }
            }
        }
    }
    return 1;
};

export const compareMonths = (a, b) => {
    if (a === null || b === null) {
        return null;
    }
    if (a.year < b.year) {
        return -1;
    } if (a.year === b.year) {
        if (a.month < b.month) {
            return -1;
        } if (a.month === b.month) {
            return 0;
        }
    }
    return 1;
};

export const convertDateToObj = (dateValue, isUtc = false, withTime = false) => {
    if (!dateValue) {
        return null;
    }
    let date = new Date(dateValue);
    if (isNaN(date.valueOf())) {
        date = new Date();
    }
    const dateObj = {
        day: isUtc ? date.getUTCDate() : date.getDate(),
        month: isUtc ? date.getUTCMonth() + 1 : date.getMonth() + 1,
        year: isUtc ? date.getUTCFullYear() : date.getFullYear()
    };
    if (withTime) {
        dateObj.hour = isUtc ? date.getUTCHours() : date.getHours();
        dateObj.minute = isUtc ? date.getUTCMinutes() : date.getMinutes();
        dateObj.second = isUtc ? date.getUTCSeconds() : date.getSeconds();
    }
    return dateObj;
};

export const convertObjToDate = (dateObj, isUTC = false, isStart = true, type = 'date') => {
    const hour = isStart ? 0 : 23;
    const minute = isStart ? 0 : 59;
    const second = isStart ? 0 : 59;
    const millisecond = isStart ? 0 : 999;
    if (!dateObj) {
        return null;
    }
    const date = isUTC
        ? new Date(Date.UTC(dateObj.year, dateObj.month - 1, dateObj.day,
            dateObj.hour || hour, dateObj.minute || minute, dateObj.second || second,
            dateObj.millisecond || millisecond))
        : new Date(dateObj.year, dateObj.month - 1, dateObj.day,
            dateObj.hour || hour, dateObj.minute || minute, dateObj.second || second,
            dateObj.millisecond || millisecond);
    return type === 'string' ? date.toISOString() : date;
};

export const getRangeInDays = (a, b) => {
    if (a === null || b === null) {
        return null;
    }
    const aDate = convertObjToDate(a, true).valueOf();
    const bDate = convertObjToDate(b, true).valueOf();
    return Math.abs(aDate - bDate) / (1000 * 3600 * 24);
};

export const getNthFromMonth = (dateObj, n) => {
    const nthMonth = { ...dateObj };
    if ((dateObj.month + n) % 12 <= 0) {
        nthMonth.month = 12 + ((dateObj.month + n) % 12);
    } else {
        nthMonth.month = (dateObj.month + n) % 12;
    }
    nthMonth.year = dateObj.year + Math.floor(((dateObj.month + n) - 1) / 12);
    return nthMonth;
};

export const getDateFromDifference = (dateObj, unit, value) => {
    if (unit === 'day') {
        const date = convertObjToDate(dateObj, true).valueOf();
        return convertDateToObj(date + (value * 24 * 3600 * 1000), true);
    }
    if (unit === 'month') {
        return getNthFromMonth(dateObj, value);
    }
    return { ...dateObj, year: dateObj.year + value };
};

export const getDateFromStartOf = (dateObj, unit) => {
    if (unit === 'month') {
        return { ...dateObj, day: 1 };
    }
    return { ...dateObj, month: 1, day: 1 };
};

export const divisionWithPositiveRemainder = (dividend, divisor) => {
    const remainder = dividend % divisor;
    return {
        quotient: Math.floor(dividend / divisor),
        remainder: remainder < 0 ? remainder + divisor : remainder
    };
};

export const compareTimes = (a, b, unit) => {
    if (a === null || b === null) {
        return null;
    }
    if (a.hour < b.hour) {
        return -1;
    } if (a.hour === b.hour) {
        if (unit === 'hour') {
            return 0;
        }
        if (a.minute < b.minute) {
            return -1;
        } if (a.minute === b.minute) {
            if (unit === 'minute') {
                return 0;
            }
            if (a.second < b.second) {
                return -1;
            } if (a.second === b.second) {
                return 0;
            }
        }
    }
    return 1;
};

export const getMinTime = (a, b) => {
    const res = { ...a };
    if (res.hour < b.hour) {
        res.hour = b.hour;
    }
    if (res.hour === b.hour) {
        if (res.minute < b.minute) {
            res.minute = b.minute;
        }
        if (res.minute === b.minute && res.second < b.second) {
            res.second = b.second;
        }
    }
    return res;
};

export const getMaxTime = (a, b) => {
    const res = { ...a };
    if (res.hour > b.hour) {
        res.hour = b.hour;
    }
    if (res.hour === b.hour) {
        if (res.minute > b.minute) {
            res.minute = b.minute;
        }
        if (res.minute === b.minute && res.second > b.second) {
            res.second = b.second;
        }
    }
    return res;
};

const repeatStringNumTimes = (str, count) => {
    let repeatedString = '';
    for (let i = 0; i < count; i++) {
        repeatedString += str;
    }
    return repeatedString;
};

const formatNumberDigits = (num, digits) => {
    const numString = num.toString();
    if (numString.length >= digits) {
        return numString;
    }
    return `${repeatStringNumTimes('0', digits - numString.length)}${numString}`;
};

const dateRegex = {
    day: /DD|dd/g,
    month: /MM/g,
    year: /YYYY|yyyy/g
};

const dateKeys = Object.keys(dateRegex);

export const formatDate = (date, format) => {
    if (date && format) {
        return dateKeys.reduce((res, key) => {
            if (typeof date[key] === 'number') {
                return res.replace(dateRegex[key], (match) => {
                    return formatNumberDigits(date[key], match.length);
                });
            }
            return res;
        }, format);
    }
    return '';
};

const timeRegex = {
    hour: /HH|h/g,
    minute: /mm|m/g,
    second: /ss|s/g
};

const timeKeys = Object.keys(timeRegex);

export const formatTime = (time, format) => {
    if (time && format) {
        return timeKeys.reduce((res, key) => {
            if (typeof time[key] === 'number') {
                return res.replace(timeRegex[key], (match) => {
                    return formatNumberDigits(time[key], match.length);
                });
            }
            return res;
        }, format);
    }
    return '';
};

export const getTimeFormatFromConfig = (config) => {
    const format = [];
    if (!config.hour.hidden && !config.hour.disabled) {
        format.push('HH');
    }
    if (!config.minute.hidden && !config.minute.disabled) {
        format.push('mm');
    }
    if (!config.second.hidden && !config.second.disabled) {
        format.push('ss');
    }
    return format.join(':');
};
