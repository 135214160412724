import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
/* utils */
import { getClassName } from '../../utils/helpers/info-helper';
/* styles */
import './nav-button.scss';

class NavButton extends React.PureComponent {
    render() {
        const { className, to, isActive, children, onClick } = this.props;
        const classNames = getClassName('sten-nav-button', className, {
            'sten-nav-button--active': isActive
        });
        return this.props.to ? (
            <Link
                to={to}
                className={classNames}
                onClick={onClick}
            >
                {children}
            </Link>
        ) : (
            <button className={classNames} onClick={onClick}>{children}</button>
        );
    }
}

NavButton.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func])
};

NavButton.defaultProps = {
    className: '',
    isActive: false,
    onClick: undefined,
    to: null
};

export default NavButton;
