import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* types */
import {
    TVmsFootprint,
    TYearlyRatingWithCorrection
} from 'components/footprint-table/footprint-table-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import EnergyManagementReportsPreviewHeader from '../preview-header';
import LabelValueTable from '../../shared/label-value-table';
import ScrollArea from 'components/scroll-area/scroll-area';
import ContentHeader from '../../shared/content-header';
import EmptyContent from 'components/empty-content/empty-content';
import RawReportsTable from 'components/raw-reports-table/raw-reports-table';
/* actions */
import { generatePDF, generateXLSX, toggleFullScreen, resetAll, setMounted } from './voyage-report-actions';
import { generateReportPreview } from '../../reports-actions';
/* selectors */
import { getReportProps, getSummary } from './voyage-report-selectors';
import { getConvertedInclusions } from '../../reports-selectors';
/* constants */
import {
    badges,
    ballastSummaryRows,
    ladenSummaryRows,
    getFootprintRows
} from './voyage-report-constants';

const t = (key, props) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.${key}`, props);

class VoyageReport extends React.PureComponent {
    componentDidMount() {
        this.props.setMounted();
    }

    renderSummaryContent = (title, rows, data, info, tooltip) => (
        <div className="sten-panel sten-energy-management-reports-preview__panel">
            <div className="sten-panel__header">
                <h4 className={`${tooltip ? 'flex-shrink' : 'flex-grow'} text-uppercase`}>{title}</h4>
                {tooltip && (
                    <div className="flex-grow">
                        <span className="icon sten-icon-info-tooltip" title={tooltip} />
                    </div>
                )}
                {info && <div className="flex-shrink">{info}</div>}
            </div>
            <div className="sten-panel__body">
                <LabelValueTable rowConfigs={rows} data={data} />
            </div>
        </div>
    );

    toggleFullScreen = (type) => (e) => {
        e.stopPropagation();
        this.props.toggleFullScreen(type);
    };

    renderBadges = (reportProps) => {
        return (
            <React.Fragment>
                {badges.reduce((res, badge) => {
                    if (badge.dataMissingKey && reportProps[badge.dataMissingKey]) {
                        res.push(
                            <div key={badge.key} className="flex-shrink">
                                <span
                                    className={badge.className}
                                    title={translate('VOYAGE_PERFORMANCE.REPORTS.LIMIT_REACHED', { type: badge.label })}
                                >
                                    {`${translate('GLOBAL.NOT_AVAILABLE')} ${badge.label}`}
                                </span>
                            </div>
                        );
                    } else if (reportProps[badge.key] > 0) {
                        res.push(
                            <div key={badge.key} className="flex-shrink">
                                <span className={badge.className}>{`${reportProps[badge.key]} ${badge.label}`}</span>
                            </div>
                        );
                    }
                    return res;
                }, [])}
            </React.Fragment>
        );
    };

    renderButton = (reportProps) => {
        const titleText = `${reportProps.title} (${reportProps.total})`;
        const { missing, excluded, suspicious, included } = reportProps;
        const isClickable = missing || excluded || suspicious || included;
        let className = 'sten-panel';
        if (isClickable) {
            className += ' sten-panel--btn';
        }
        return (
            <div className={className} onClick={isClickable ? this.toggleFullScreen(reportProps.type) : undefined}>
                <div className="sten-panel__header flex-row">
                    <div className="flex-shrink">
                        <h4>{titleText}</h4>
                    </div>
                    {this.renderBadges(reportProps)}
                    {!!isClickable && (
                        <div className="flex-grow text-right">
                            <span className="btn-link icon icon-chevron-right" />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    getFooter = () => {
        return (
            <React.Fragment>
                <p className="flex-grow text-secondary hide-xs">{t('NOTE_TEXT')}</p>
                <div className="flex-shrink">
                    <button
                        onClick={this.props.generatePDF}
                        className="btn btn--primary"
                    >
                        {t('EXPORT_PDF')}
                    </button>
                </div>
                <div className="flex-shrink">
                    <button
                        onClick={this.props.generateXLSX}
                        className="btn btn--primary"
                    >
                        {t('EXPORT_XLSX')}
                    </button>
                </div>
            </React.Fragment>
        );
    };

    handleRecalculateClick = () => {
        this.props.generateReportPreview(true);
    };

    getFooterWithRecalculate = () => {
        return (
            <React.Fragment>
                <p className="flex-grow">{t('REPORTS_UPDATED_TEXT')}</p>
                <div className="flex-shrink">
                    <button
                        onClick={this.handleRecalculateClick}
                        className="btn btn--primary"
                    >
                        {t('RECALCULATE')}
                    </button>
                </div>
            </React.Fragment>
        );
    };

    render() {
        const {
            inclusions,
            rangeSelectorActive,
            reportProps,
            summaryReport,
            shouldRecalculateReport,
            shownFullScreenReports,
            selectedRange,
            selectedVessel,
            selectedVoyage,
            vesselCii,
            voyageVmsFootprint
        } = this.props;
        if (shownFullScreenReports) {
            return (
                <RawReportsTable
                    type={shownFullScreenReports}
                    vessel={selectedVessel}
                    voyage={rangeSelectorActive ? null : selectedVoyage}
                    range={rangeSelectorActive ? selectedRange : null}
                    counts={reportProps[shownFullScreenReports]}
                    inclusions={inclusions}
                    onBackClick={this.toggleFullScreen('')}
                    onRecalculateClick={this.handleRecalculateClick}
                />
            );
        }
        const footprintTableData = {
            FuelTypes: summaryReport?.Footprint.FuelTypes,
            voyageFootprint: summaryReport?.Footprint,
            vesselCii,
            voyageVmsFootprint
        };
        return (
            <div className="sten-content sten-content--has-footer">
                <ContentHeader />
                {!summaryReport ? <EmptyContent>{t('EMPTY_REPORT')}</EmptyContent>
                    : (
                        <React.Fragment>
                            <div className="sten-energy-management-reports-preview sten-content__body">
                                <ScrollArea>
                                    <EnergyManagementReportsPreviewHeader />
                                    <div className="sten-content__section">
                                        <div className="flex-row flex--wrap">
                                            <div className="col-lg-12 col-xs-24">
                                                {summaryReport && this.renderSummaryContent(
                                                    translate('GLOBAL.BALLAST'),
                                                    ballastSummaryRows,
                                                    summaryReport.BallastReport
                                                )}
                                                {summaryReport && this.renderSummaryContent(
                                                    translate('GLOBAL.LADEN'),
                                                    ladenSummaryRows,
                                                    summaryReport.LadenReport
                                                )}
                                            </div>
                                            <div className="col-lg-12 col-xs-24">
                                                {this.renderSummaryContent(
                                                    t('FOOTPRINT'),
                                                    getFootprintRows(rangeSelectorActive),
                                                    footprintTableData,
                                                    null,
                                                    t('VOYAGE_REPORT.SUMMARY.ALL_REPORTS_DISCLAIMER')
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="sten-content__section">
                                        <h1>{t('VOYAGE_REPORT.RAW_DATA')}</h1>
                                    </div>
                                    <div className="sten-content__section">
                                        <div className="row row--fluid">
                                            {Object.keys(reportProps).map(key => (
                                                <div key={reportProps[key].id} className="col-md-12 col-xs-24">
                                                    {this.renderButton(reportProps[key])}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </ScrollArea>
                            </div>
                            <footer className="sten-content__footer flex-row">
                                {shouldRecalculateReport
                                    ? this.getFooterWithRecalculate()
                                    : this.getFooter()
                                }
                            </footer>
                        </React.Fragment>
                    )}
            </div>
        );
    }
}

VoyageReport.propTypes = {
    generatePDF: PropTypes.func.isRequired,
    generateReportPreview: PropTypes.func.isRequired,
    generateXLSX: PropTypes.func.isRequired,
    inclusions: PropTypes.objectOf(PropTypes.any),
    rangeSelectorActive: PropTypes.bool.isRequired,
    reportProps: PropTypes.objectOf(PropTypes.any),
    resetAll: PropTypes.func.isRequired,
    selectedRange: PropTypes.shape({
        rangeStart: PropTypes.any,
        rangeEnd: PropTypes.any
    }),
    selectedVessel: PropTypes.objectOf(PropTypes.any),
    selectedVoyage: PropTypes.objectOf(PropTypes.any),
    setMounted: PropTypes.func.isRequired,
    shouldRecalculateReport: PropTypes.bool.isRequired,
    shownFullScreenReports: PropTypes.string.isRequired,
    summaryReport: PropTypes.objectOf(PropTypes.any),
    toggleFullScreen: PropTypes.func.isRequired,
    vesselCii: TYearlyRatingWithCorrection,
    voyageVmsFootprint: TVmsFootprint
};

VoyageReport.defaultProps = {
    inclusions: null,
    reportProps: null,
    selectedRange: null,
    selectedVessel: null,
    selectedVoyage: null,
    summaryReport: null,
    vesselCii: null,
    voyageVmsFootprint: null
};

function mapStateToProps(state) {
    return {
        inclusions: getConvertedInclusions(state),
        rangeSelectorActive: state.energyManagementReportsReducer.generatedReportSettings.rangeSelectorActive,
        reportProps: getReportProps(state),
        selectedRange: state.energyManagementReportsReducer.generatedReportSettings.selectedRange,
        selectedVessel: state.energyManagementReportsReducer.generatedReportSettings.selectedVessel,
        selectedVoyage: state.energyManagementReportsReducer.generatedReportSettings.selectedVoyage,
        shouldRecalculateReport: state.energyManagementReportsVoyageReportReducer.shouldRecalculateReport,
        shownFullScreenReports: state.energyManagementReportsVoyageReportReducer.shownFullScreenReports,
        summaryReport: getSummary(state),
        vesselCii: state.energyManagementReportsVoyageReportReducer.vesselCii,
        voyageVmsFootprint: state.energyManagementReportsVoyageReportReducer.voyageVmsFootprint
    };
}

function mapDispatchToProps(dispatch) {
    return {
        generatePDF: () => generatePDF(dispatch),
        generateReportPreview: (isRecalculate) => generateReportPreview(dispatch, isRecalculate),
        generateXLSX: () => generateXLSX(dispatch),
        resetAll: () => resetAll(dispatch),
        setMounted: () => setMounted(dispatch),
        toggleFullScreen: (reportType) => toggleFullScreen(dispatch, reportType)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VoyageReport);
