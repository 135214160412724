import getFontSize from 'utils/helpers/cached-font-size';

const mapConfig = {
    url: 'https://api.mapbox.com/styles/v1/orbitmi/',
    mapStyles: {
        classic: 'ck0mhp2z60cq81cmp31o3hjqu',
        classicNamesAndBordersEnabled: 'ck0mh7lt50vg31cpodtyrica9',
        satellite: 'ck0mhq96e33my1cpqdcevdviw',
        satelliteNamesAndBordersEnabled: 'ck0mhrmim0ai11cpl7xyd4658',
        classicLand: 'ckvibg2d17ze514nnl7ban2vk',
        classicLandNamesAndBordersEnabled: 'ckvib8f6u1qjo14oa8d4fd2tk'
    },
    mapUrlSuffix: '/tiles/256/{z}/{x}/{y}@2x',
    access_token: 'pk.eyJ1Ijoib3JiaXRtaSIsImEiOiJjanpqd2JoazEwMDM3M2JzNm5tMGJtOTBvIn0.3nSgTlvjQ5Wu91VLm61sfA',
    getTileUrl: (type) => {
        const mapStyleType = mapConfig.mapStyles[type] ? mapConfig.mapStyles[type] : mapConfig.mapStyles.classic;
        return `${mapConfig.url}${mapStyleType}${mapConfig.mapUrlSuffix}?access_token=${mapConfig.access_token}`;
    },
    getFontSizeMultiplier: () => {
        return getFontSize() / 16;
    },
    minZoom: 2,
    maxZoom: 17.6,
    layerZIndex: {
        graticule: 10,
        weatherLayer: 20,
        areaOfInterest: 30,
        route: 40,
        port: 50,
        vessels: 60,
        tradingRoute: 90,
        marketIntelRoute: 100,
        bunkerPort: 110
    },
    sourceProjection: 'EPSG:4326',
    targetProjection: 'EPSG:3857',
    startPosition: [1300000, 4800000],
    tradingRouteLabelPositions: {
        TD20: [-16.5, 25],
        TC2: [-32, 48],
        TD3: [75, 6],
        TC7: [140, -10],
        TC11: [116.5, 20],
        TD17: [10.5, 58],
        TC12: [111.5, 10]
    }
};
export default mapConfig;
