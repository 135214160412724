import { takeEvery, put, select } from 'redux-saga/effects';
/* utils */
import { omitObjectKeys } from 'utils/helpers/info-helper';
/* actions */
import { ActionTypes } from './overview-actions';
/* Services */
import ConfigService from 'services/config-api/config-service';
import EnergyManagementService from 'services/core-api/energy-management-service';
import LocalStorageService from 'services/local-storage-service';
/* Mocks */
import { mockedKpiSegments, mockedKpiAdvancedSegments } from '../mocks';

function* getOverviewBasic(action) {
    let filters = action.filters;
    if (!filters) {
        filters = yield select(state => state.energyManagementKPIReducer.filters);
    }
    const selectedRangeEnd = new Date(Date.UTC(filters.selectedRangeEnd.year, filters.selectedRangeEnd.month, 1) - 1);
    const params = {
        DateRangeOption: filters.selectedRangeOption.Id,
        EndDate: selectedRangeEnd.toISOString(),
        Imos: []
    };
    if (filters.selectedVesselsBySegment) {
        for (const segmentId in filters.selectedVesselsBySegment) {
            if (filters.selectedVesselsBySegment.hasOwnProperty(segmentId)) {
                for (const imo in filters.selectedVesselsBySegment[segmentId]) {
                    if (filters.selectedVesselsBySegment[segmentId].hasOwnProperty(imo)) {
                        params.Imos.push(imo);
                    }
                }
            }
        }
    }
    const overviewBasicData = LocalStorageService.isDebugModeActive()
        || ConfigService.featureToggles.showKpiWithMockedData
        ? mockedKpiSegments
        : yield EnergyManagementService.KPI.Overview.getOverviewBasic(params);

    const keysToOmit = ['Segments'];
    if (overviewBasicData.EnergyEfficiencyDataList) {
        let tempData;
        const EEListFormatted = overviewBasicData.EnergyEfficiencyDataList.map((data) => {
            tempData = omitObjectKeys(data, keysToOmit);
            data.Segments.forEach((segment) => {
                tempData[segment.Name] = segment.Efficiency;
            });
            return tempData;
        });

        yield put({
            type: ActionTypes.ENERGY_MANAGEMENT_KPI_OVERVIEW_SET_BASIC_OVERVIEW,
            overviewBasicData: {
                ...overviewBasicData,
                EnergyEfficiencyDataList: EEListFormatted
            }
        });
    } else {
        yield put({
            type: ActionTypes.ENERGY_MANAGEMENT_KPI_OVERVIEW_SET_BASIC_OVERVIEW,
            overviewBasicData
        });
    }
}

function* getAdvancedData(action) {
    let filters = action.filters;
    if (!filters) {
        filters = yield select(state => state.energyManagementKPIReducer.filters);
    }
    const selectedRangeEnd = new Date(Date.UTC(filters.selectedRangeEnd.year, filters.selectedRangeEnd.month, 1) - 1);
    const params = {
        DateRangeOption: filters.selectedRangeOption.Id,
        EndDate: selectedRangeEnd.toISOString(),
        Imos: []
    };
    if (filters.selectedVesselsBySegment) {
        for (const segmentId in filters.selectedVesselsBySegment) {
            if (filters.selectedVesselsBySegment.hasOwnProperty(segmentId)) {
                for (const imo in filters.selectedVesselsBySegment[segmentId]) {
                    if (filters.selectedVesselsBySegment[segmentId].hasOwnProperty(imo)) {
                        params.Imos.push(imo);
                    }
                }
            }
        }
    }
    const overviewAdvancedData = LocalStorageService.isDebugModeActive()
        || ConfigService.featureToggles.showKpiWithMockedData
        ? mockedKpiAdvancedSegments
        : yield EnergyManagementService.KPI.Overview.getOverviewAdvanced(params);
    yield put({
        type: ActionTypes.ENERGY_MANAGEMENT_KPI_OVERVIEW_SET_ADVANCED_OVERVIEW,
        overviewAdvancedData
    });
}

export default function* energyManagementKPIOverviewSaga() {
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_KPI_OVERVIEW_GET_BASIC_OVERVIEW, getOverviewBasic);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_KPI_OVERVIEW_GET_ADVANCED_OVERVIEW, getAdvancedData);
}
