import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import fastDeepEqual from 'fast-deep-equal';
/* types */
import { TRouteRendererMapData, TRouteRendererQueryParams } from '../route-renderer-types';
/* helpers */
import { wktParse, mapCurrentAisLonLat } from 'components/ol/ol-helpers';
/* services */
import ConfigService from 'services/config-api/config-service';
/* actions */
import { setVesselDetailsTooltipPosition } from 'components/vessel-details-tooltip/vessel-details-tooltip-actions';
/* components */
import NauticalCharts from 'components/nautical-charts/nautical-charts';
import Ol from 'components/ol/ol';
import VesselDetailsTooltip from 'components/vessel-details-tooltip/vessel-details-tooltip';
/* config */
import config from './route-renderer-map-config';

const mapViewProps = {
    zoom: 3
};

class RouteRendererMap extends React.PureComponent {
    state = {
        isRenderCompleted: false
    };

    lastHoveredFeature = null;

    componentDidMount() {
        this.fitElementsOnMap();
    }

    fitElementsOnMap = () => {
        const { Vessel, FutureRouteRaw, HistoricalRouteRaw, Ports } = this.props.data;
        let lineString = [];

        if (HistoricalRouteRaw.length > 0) {
            lineString = [...lineString, ...HistoricalRouteRaw];
        }
        if (Vessel?.CurrentAis) {
            lineString.push(mapCurrentAisLonLat(Vessel));
        }
        if (FutureRouteRaw.length > 0) {
            lineString = [...lineString, ...FutureRouteRaw];
        }
        if (Ports) {
            Ports.forEach((port) => {
                lineString.push(wktParse(port.Location));
            });
        }
        if (lineString.length === 1) {
            this.map.setCenter(lineString[0]);
        } else if (lineString.length > 1) {
            this.map.fitElements(lineString, { padding: [40, 80, 40, 80], duration: 0 });
        }
    };

    setVesselDetailsTooltipPosition = () => {
        const { Vessel } = this.props.data;
        if (Vessel?.CurrentAis) {
            const pixels = this.map.getPixelFromCoordinate([Vessel.CurrentAis.Longitude, Vessel.CurrentAis.Latitude]);
            const vesselPositionDetails = {
                pixels,
                zoomLevel: this.map.getZoom()
            };

            if (!fastDeepEqual(vesselPositionDetails, this.lastVesselPositionDetails)) {
                this.props.setVesselDetailsTooltipPosition(vesselPositionDetails);
            }

            this.lastVesselPositionDetails = vesselPositionDetails;
        }
    };

    handleMapPostRender = () => {
        if (this.map) {
            this.setVesselDetailsTooltipPosition();
        }
    };

    handleMapRenderComplete = () => {
        this.setState({ isRenderCompleted: true });
    };

    portsLayerElementProps = {
        type: port => config.pointTypesMap[port.Type],
        primaryColor: port => (port.IsHistorical
            ? config.port.primaryColor
            : config.port.secondaryColor),
        secondaryColor: config.port.secondaryColor,
        coordinates: port => wktParse(port.Location),
        isActive: true,
        opacity: port => (port.IsHistorical ? 1 : 0.8),
        label: port => {
            let label = '';
            if (port) {
                label = port.Name;
                if (port.selectedInVoyage && port.Activities && port.Activities.length > 0) {
                    label += ` (${port.Activities.map(activity => activity.Code).join(',')})`;
                }
            }
            return label;
        },
        textStroke: true,
        textOnTop: true,
        zIndex: config.port.zIndex
    };

    vesselsLayerElementProps = {
        type: 'vessel',
        primaryColor: config.vessel.primaryColor,
        coordinates: mapCurrentAisLonLat,
        fill: vessel => vessel.CurrentAis.IsLaden === true,
        isActive: true,
        hideLabel: true,
        opacity: 1,
        rotation: vessel => vessel.CurrentAis.Heading || 0,
        textStroke: true,
        textOffset: 24
    };

    saveMapRef = (c) => { this.map = c; };

    render() {
        const { Vessel, FutureRoute, HistoricalRoute, Ports } = this.props.data;
        const { countryBordersNamesEnabled, mapTypeId } = this.props.queryParams;
        const { isRenderCompleted } = this.state;
        const isNauticalMapSelected = mapTypeId === 3;
        return (
            <div className={`sten-map${isRenderCompleted ? ' map-render-completed' : ''}`}>
                {Vessel && <VesselDetailsTooltip selectedVesselPosition={Vessel} />}
                <Ol.Map
                    ref={this.saveMapRef}
                    mapTypeId={mapTypeId || 1}
                    mapViewProps={mapViewProps}
                    onPostRender={this.handleMapPostRender}
                    onRenderComplete={this.handleMapRenderComplete}
                    showCoordinates={false}
                    countryBordersNamesEnabled={countryBordersNamesEnabled}
                    shouldResetToInitial
                    showCopyrightInfo={false}
                >
                    {ConfigService.featureToggles.showNauticalCharts && (
                        <NauticalCharts zIndex={config.nautical.zIndex} showBaseMap={isNauticalMapSelected} />
                    )}
                    {HistoricalRoute?.ballast.length > 0 && (
                        <Ol.Layer
                            name="historicalRouteBallast"
                            type="multiLine"
                            elements={HistoricalRoute.ballast}
                            elementProps={config.route.ballast}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    {HistoricalRoute?.ballastApprox.length > 0 && (
                        <Ol.Layer
                            name="historicalRouteBallastApprox"
                            type="multiLine"
                            elements={HistoricalRoute.ballastApprox}
                            elementProps={config.route.ballastApprox}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    {HistoricalRoute?.laden.length > 0 && (
                        <Ol.Layer
                            name="historicalRouteLaden"
                            type="multiLine"
                            elements={HistoricalRoute.laden}
                            elementProps={config.route.laden}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    {HistoricalRoute?.ladenApprox.length > 0 && (
                        <Ol.Layer
                            name="historicalRouteLadenApprox"
                            type="multiLine"
                            elements={HistoricalRoute.ladenApprox}
                            elementProps={config.route.ladenApprox}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    {FutureRoute?.length > 0 && (
                        <Ol.Layer
                            name="futureRoute"
                            type="multiLine"
                            elements={FutureRoute}
                            elementProps={config.route.future}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    <Ol.Layer
                        name="ports"
                        type="point"
                        elements={Ports}
                        elementProps={this.portsLayerElementProps}
                        observes={[Ports]}
                        zIndex={config.port.zIndex}
                    />
                    <Ol.Layer
                        name="vessels"
                        type="point"
                        elements={Vessel ? [Vessel] : []}
                        elementProps={this.vesselsLayerElementProps}
                        observes={[Vessel]}
                        zIndex={config.vessel.zIndex}
                    />
                </Ol.Map>
            </div>
        );
    }
}

RouteRendererMap.propTypes = {
    data: TRouteRendererMapData,
    queryParams: TRouteRendererQueryParams.isRequired,
    setVesselDetailsTooltipPosition: PropTypes.func.isRequired
};

RouteRendererMap.defaultProps = {
    data: null
};

function mapDispatchToProps(dispatch) {
    return {
        setVesselDetailsTooltipPosition: position => setVesselDetailsTooltipPosition(dispatch, position)
    };
}

export default connect(null, mapDispatchToProps, null, { withRef: true })(RouteRendererMap);
