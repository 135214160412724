/* utils */
import { t } from 'utils/i18n/i18n-model';

export const cargoReportFieldsAndSections = [
    {
        fields: [
            'Vessel',
            'CallSign',
            'VoyageNumber',
            'CharterpartyDate',
            'ReportDate',
            'Position'
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.SINCE_LAST_EVENT'),
        sections: [
            {
                fields: [
                    'TimeElapsedSinceLastEvent',
                    'TimeAtAnchorSinceLastEvent',
                    'HoursSteamedSinceLastEvent',
                    'DistanceSinceLastEvent',
                    'WindForceSinceLastEvent',
                    'TotalCargoSinceLastEvent'
                ]
            }
        ]
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.CONSUMPTIONS_SINCE_LAST_EVENT'),
        condition: (p) => ((p.isEditing && p.editingOptions.consumptionTypes)
            || (p.data.ConsumptionsSinceLastEvent && p.data.ConsumptionsSinceLastEvent.rows.length)),
        data: 'ConsumptionsSinceLastEvent'
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.PORT_INFORMATION'),
        sections: [
            {
                fields: [
                    'PortActivityType',
                    'ShipToShipCargoTransfer',
                    'Point',
                    'Terminal',
                    'NextPort',
                    'IsUsingMainEngine',
                    'IsHeated',
                    'CommencedCargoDate',
                    'CompletedCargoDate',
                    'QtyLoadedDischarged',
                    'CalculatedAverageLoadDischargeRate',
                    'LoadDischargeRateMaxFromTerminal',
                    'AverageLoadDischargeRate',
                    'PumpPressureMaxFromTerminal',
                    'PumpPressureReached'
                ]
            }
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.REMARKS'),
        sections: [
            {
                inline: false,
                fields: ['Remarks']
            }
        ]
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.BUNKER_DATA'),
        condition: (p) => ((p.isEditing && p.editingOptions.bunkerTypes.length)
            || (p.data.BunkerData && p.data.BunkerData.rows.length)),
        data: 'BunkerData'
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.CONSUMPTIONS'),
        condition: (p) => ((p.isEditing && p.editingOptions.consumptionTypes)
            || (p.data.Consumptions && p.data.Consumptions.rows.length)),
        data: 'Consumptions'
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.CONSUMPTIONS'),
        condition: (p) => (((p.isEditing && p.editingOptions.consumptionTypes)
            || (p.data.Consumptions?.rows.length))
            && p.data.PortActivityType?.Name === 'Load'),
        data: 'LoadConsumptions'
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.DISCHARGE_OPERATIONS'),
        condition: (p) => (((p.isEditing && p.editingOptions.consumptionTypes)
            || (p.data.DischargeOperations?.rows.length))
            && p.data.PortActivityType?.Name === 'Discharge'),
        data: 'DischargeOperations'
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.TANKS'),
        condition: (p) => p.data.Tanks.length || p.isEditing,
        data: 'Tanks'
    }
];
