import { takeEvery, all, put } from 'redux-saga/effects';
/* Services */
import GsmVesselListService from 'services/gsm-api/gsm-vessel-list';
/* Actions */
import { ActionTypes } from './gsm-right-side-bar-actions';
/* Config */
import config from '../gsm-map/gsm-map-config';

function* getVesselList() {
    const vesselList = yield GsmVesselListService.get();

    if (vesselList) {
        // Tankers
        const tankers = vesselList.Vessels.Tankers.map(tanker => ({
            id: tanker.IMO,
            title: tanker.ShipName,
            subtitle: tanker.CargoType,
            iconColor: config.tanker.primaryColor,
            clickQueryParam: 'tanker'
        }));

        // Ferries
        const ferries = vesselList.Vessels.Ferries.map(ferry => ({
            id: ferry.IMO,
            title: ferry.ShipName,
            subtitle: ferry.ShipType,
            iconColor: config.ferry.primaryColor,
            clickQueryParam: 'ferry'
        }));

        // Drills
        const drills = vesselList.Drills.map(drill => ({
            id: drill.IMO,
            title: drill.ShipName,
            subtitle: drill.ShipType,
            iconColor: config.drill.primaryColor,
            clickQueryParam: 'drill'
        }));

        // Ferry routes
        const ferryRoutes = vesselList.FerryRoutes.map(route => ({
            id: route.Id,
            title: route.Name,
            subtitle: route.Area
        }));

        // Offices
        const offices = vesselList.Offices.map(city => ({
            ...city,
            Companies: city.Companies.map(company => ({
                ...company,
                clickQueryParam: 'city'
            }))
        }));

        all([
            yield put({ type: ActionTypes.GSM_RIGHT_SIDE_BAR_SET_ALL_ENTITIES, allEntities: vesselList }),
            yield put({ type: ActionTypes.GSM_RIGHT_SIDE_BAR_SET_TANKERS, tankers }),
            yield put({ type: ActionTypes.GSM_RIGHT_SIDE_BAR_SET_FERRIES, ferries }),
            yield put({ type: ActionTypes.GSM_RIGHT_SIDE_BAR_SET_DRILLS, drills }),
            yield put({ type: ActionTypes.GSM_RIGHT_SIDE_BAR_SET_FERRY_ROUTES, ferryRoutes }),
            yield put({ type: ActionTypes.GSM_RIGHT_SIDE_BAR_SET_OFFICES, offices })
        ]);
    }
}

// Saga
export default function* gsmRightSideBarSaga() {
    yield takeEvery(ActionTypes.GSM_RIGHT_SIDE_BAR_GET_VESSEL_LIST, getVesselList);
}
