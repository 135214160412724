import React from 'react';
import PropTypes from 'prop-types';
/* context */
import { ValidationContext } from './validation-form';

class ValidationButton extends React.PureComponent {
    handleClick = (e) => {
        if (this.props.onClick) {
            this.props.onClick(this.props.context, e);
        }
    }

    render() {
        const errors = this.props.getErrors(this.props.context);
        const oneWithPrefix = this.props.title ? `${this.props.title}, one` : 'One';
        const errorsWithPrefix = this.props.title ? `${this.props.title}, ${errors}` : errors;
        const title = `${errors > 1 ? errorsWithPrefix : oneWithPrefix} field${errors > 1 ? 's' : ''} invalid`;

        return (
            <button
                type={this.props.type}
                className={`sten-validation-button${this.props.className ? ` ${this.props.className}` : ''}`}
                title={errors ? title : ''}
                disabled={this.props.disabled}
                onClick={this.handleClick}
            >
                {this.props.children}
                {errors > 0 && <span className="sten-validation-button__badge">{errors}</span>}
            </button>
        );
    }
}

ValidationButton.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    context: PropTypes.objectOf(PropTypes.any).isRequired,
    disabled: PropTypes.bool,
    getErrors: PropTypes.func,
    onClick: PropTypes.func,
    type: PropTypes.string,
    title: PropTypes.string
};

ValidationButton.defaultProps = {
    children: null,
    className: '',
    disabled: false,
    getErrors: (context) => (context.showErrors ? Object.keys(context.errors).length : 0),
    onClick: undefined,
    type: 'submit',
    title: ''
};

export default props => (
    <ValidationContext.Consumer>
        {context => <ValidationButton {...props} context={context} />}
    </ValidationContext.Consumer>
);
