import React from 'react';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import { formatNumber } from 'utils/helpers/info-helper';
import { sortByProp } from 'utils/helpers/array-helper';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.FLEET_PERFORMANCE_REPORT.${key}`);

const consMap = {
    ConsumptionsAtSea: 'sea',
    ConsumptionsAtSeaBallast: 'seaBallast',
    ConsumptionsAtSeaLaden: 'seaLaden',
    ConsumptionsInCanal: 'canal',
    ConsumptionsAnchoredDrifting: 'anchoredDrifting',
    ConsumptionsCargoLoad: 'cargoLoad',
    ConsumptionsCargoDischarge: 'cargoDischarge',
    ConsumptionsInPort: 'port'
};

export function convertData() {
    const fuelTypes = {
        sea: [],
        seaBallast: [],
        seaLaden: [],
        canal: [],
        anchoredDrifting: [],
        cargoLoad: [],
        cargoDischarge: [],
        port: []
    };
    const fuelTypeMap = {
        sea: {},
        seaBallast: {},
        seaLaden: {},
        canal: {},
        anchoredDrifting: {},
        cargoLoad: {},
        cargoDischarge: {},
        port: {}
    };
    return {
        convertAndExtract: (item) => {
            const parsedItem = { ...item };
            Object.keys(consMap).forEach(consKey => {
                parsedItem[consKey] = {};
                if (item[consKey]) {
                    item[consKey].forEach(fuelType => {
                        parsedItem[consKey][fuelType.Id] = fuelType;
                        if (!fuelTypeMap[consMap[consKey]][fuelType.Id]) {
                            fuelTypeMap[consMap[consKey]][fuelType.Id] = true;
                            fuelTypes[consMap[consKey]].push({
                                Id: fuelType.Id,
                                Name: fuelType.Name,
                                Sequence: fuelType.Sequence
                            });
                        }
                    });
                }
            });
            return parsedItem;
        },
        getFuelTypes: () => {
            Object.keys(fuelTypes).forEach(consKey => {
                fuelTypes[consKey].sort(sortByProp('Sequence'));
            });
            return fuelTypes;
        }
    };
}

export const renderDataProps = (data, props, firstColSpan = 1, isAvg = false) => (
    props.map((prop, index) => (
        <td key={prop.key} colSpan={index === 0 ? firstColSpan : 1}>
            {typeof data[prop.key] === 'number'
                ? `${formatNumber(data[prop.key], isAvg ? prop.avgDecimals : prop.decimals)}${prop.unit || ''}`
                : '-'}
        </td>
    ))
);

export const renderFuelData = (data, fuelTypes, otherProps) => (
    fuelTypes.map(fuelType => (
        <td key={`${fuelType.Id}.cons`}>
            {data[fuelType.Id] ? formatNumber(data[fuelType.Id].Quantity, otherProps.fuel.decimals) : '-'}
        </td>
    ))
);

export const formatValue = (value, decimals) => (value !== null ? formatNumber(value, decimals) : '-');

export const fleetPerformanceReportTableHeaderTypeA = (
    fuelTypes,
    colWidths,
    fuelColWidth,
    emissionProps,
    cellTrsl,
    firstHeaderCellWidth = 'web'
) => {
    const seaConsCols = fuelTypes.sea.length;
    const portConsCols = fuelTypes.port.length;
    const totalConsumptionAtSeaWidth = colWidths.TotalConsumptionAtSea.web + (seaConsCols ? 0 : 4);
    const totalConsumptionInPortWidth = colWidths.TotalConsumptionInPort.web + (portConsCols ? 0 : 4);
    return (
        <thead>
            <tr>
                <th rowSpan="2" style={{ minWidth: `${colWidths.Value[firstHeaderCellWidth]}rem` }}>{cellTrsl}</th>
                <th colSpan="5" className="text-center">{t('AT_SEA')}</th>
                <th colSpan="2" className="text-center">{t('IN_PORT')}</th>
                <th colSpan={seaConsCols + 1} className="text-center">{t('CONSUMPTION_AT_SEA')}</th>
                <th colSpan={portConsCols + 1} className="text-center">{t('CONSUMPTION_IN_PORT')}</th>
                <th colSpan="4" className="text-center">{t('EMISSIONS')}</th>
            </tr>
            <tr>
                <th style={{ minWidth: `${colWidths.TotalCargoCarried.web}rem` }}>
                    {t('TOTAL_CARGO_CARRIED')}
                </th>
                <th style={{ minWidth: `${colWidths.DistanceLaden.web}rem` }}>{t('DISTANCE_LADEN')}</th>
                <th style={{ minWidth: `${colWidths.DistanceLadenPercentage.web}rem` }}>
                    {t('DISTANCE_LADEN_PERCENTAGE')}
                </th>
                <th style={{ minWidth: `${colWidths.DistanceBallast.web}rem` }}>{t('DISTANCE_BALLAST')}</th>
                <th style={{ minWidth: `${colWidths.DistanceBallastPercentage.web}rem` }}>
                    {t('DISTANCE_BALLAST_PERCENTAGE')}
                </th>
                <th style={{ minWidth: `${colWidths.DaysInPort.web}rem` }}>{t('DAYS_IN_PORT')}</th>
                <th style={{ minWidth: `${colWidths.DaysInPortPercentage.web}rem` }}>
                    {t('DAYS_IN_PORT_PERCENTAGE')}
                </th>
                {fuelTypes.sea.map(fuelType => (
                    <th key={`${fuelType.Id}.cons`} style={{ minWidth: `${fuelColWidth.web}rem` }}>
                        {`${fuelType.Name} (${translate('UNITS.CONSUMPTION')})`}
                    </th>
                ))}
                <th style={{ minWidth: `${totalConsumptionAtSeaWidth}rem` }}>{t('TOTAL')}</th>
                {fuelTypes.port.map(fuelType => (
                    <th key={`${fuelType.Id}.cons`} style={{ minWidth: `${fuelColWidth.web}rem` }}>
                        {`${fuelType.Name} (${translate('UNITS.CONSUMPTION')})`}
                    </th>
                ))}
                <th style={{ minWidth: `${totalConsumptionInPortWidth}rem` }}>{t('TOTAL')}</th>
                {emissionProps.map(emissionProp => (
                    <th
                        key={`${emissionProp.key}.cons`}
                        style={{ minWidth: `${colWidths[emissionProp.key].web}rem` }}
                    >
                        {`${emissionProp.label} (${translate('UNITS.EMISSIONS')})`}
                    </th>
                ))}
            </tr>
        </thead>
    );
};

export const fleetPerformanceReportTableHeaderTypeB = (
    fuelTypes,
    fuelColWidth,
    emissionProps,
    colWidths,
    cellTrsl,
    firstHeaderCellWidth = 'web'
) => {
    const seaBallastCols = fuelTypes.seaBallast.length;
    const seaLadenCols = fuelTypes.seaLaden.length;
    const seaTotalCols = fuelTypes.sea.length;
    const seaConsCols = seaBallastCols + seaLadenCols + seaTotalCols;
    const canalConsCols = fuelTypes.canal.length;
    const portAnchoredCols = fuelTypes.anchoredDrifting.length;
    const portCargoLoadCols = fuelTypes.cargoLoad.length;
    const portCargoDischargeCols = fuelTypes.cargoDischarge.length;
    const portTotalCols = fuelTypes.port.length;
    const portConsCols = portAnchoredCols + portCargoLoadCols + portCargoDischargeCols + portTotalCols;
    return (
        <thead>
            <tr>
                <th rowSpan="3" style={{ minWidth: `${colWidths.Value[firstHeaderCellWidth]}rem` }}>{cellTrsl}</th>
                <th rowSpan="2" colSpan="8" className="text-center">{t('AT_SEA')}</th>
                <th rowSpan="2" colSpan="2" className="text-center">{t('CANAL')}</th>
                <th rowSpan="2" colSpan="5" className="text-center">{t('IN_PORT')}</th>
                <th colSpan={(seaConsCols + 6)} className="text-center">{t('CONSUMPTION_AT_SEA')}</th>
                <th rowSpan="2" colSpan={canalConsCols + 2} className="text-center">
                    {t('CONSUMPTION_IN_CANAL')}
                </th>
                <th colSpan={portConsCols + 8} className="text-center">{t('CONSUMPTION_IN_PORT')}</th>
                <th rowSpan="2" colSpan="4" className="text-center">{t('EMISSIONS')}</th>
            </tr>
            <tr>
                <th colSpan={seaBallastCols + 2} className="text-center">{t('BALLAST')}</th>
                <th colSpan={seaLadenCols + 2} className="text-center">{t('LADEN')}</th>
                <th colSpan={seaTotalCols + 2} className="text-center">{t('TOTALS')}</th>
                <th colSpan={portAnchoredCols + 2} className="text-center">{t('ANCHORED_DRIFTING')}</th>
                <th colSpan={portCargoLoadCols + 2} className="text-center">
                    {t('CARGO_LOAD_OPERATIONS')}
                </th>
                <th colSpan={portCargoDischargeCols + 2} className="text-center">
                    {t('CARGO_DISCHARGE_OPERATIONS')}
                </th>
                <th colSpan={portTotalCols + 2} className="text-center">{t('TOTALS')}</th>
            </tr>
            <tr>
                <th style={{ minWidth: `${colWidths.TotalCargoCarried.web}rem` }}>
                    {t('TOTAL_CARGO_CARRIED')}
                </th>
                <th style={{ minWidth: `${colWidths.DaysLaden.web}rem` }}>{t('DAYS_LADEN')}</th>
                <th style={{ minWidth: `${colWidths.DistanceLaden.web}rem` }}>{t('DISTANCE_LADEN')}</th>
                <th style={{ minWidth: `${colWidths.DistanceLadenPercentage.web}rem` }}>
                    {t('DISTANCE_LADEN_PERCENTAGE')}
                </th>
                <th style={{ minWidth: `${colWidths.DaysBallast.web}rem` }}>{t('DAYS_BALLAST')}</th>
                <th style={{ minWidth: `${colWidths.DistanceBallast.web}rem` }}>{t('DISTANCE_BALLAST')}</th>
                <th style={{ minWidth: `${colWidths.DistanceBallastPercentage.web}rem` }}>
                    {t('DISTANCE_BALLAST_PERCENTAGE')}
                </th>
                <th style={{ minWidth: `${colWidths.DaysAtSea.web}rem` }}>{t('DAYS_AT_SEA')}</th>
                <th style={{ minWidth: `${colWidths.DaysInCanal.web}rem` }}>{t('DAYS_IN_CANAL')}</th>
                <th style={{ minWidth: `${colWidths.DaysInCanalPercentage.web}rem` }}>
                    {t('DAYS_IN_CANAL_PERCENTAGE')}
                </th>
                <th style={{ minWidth: `${colWidths.DaysAnchoredDrifting.web}rem` }}>
                    {t('DAYS_ANCHORED_DRIFTING')}
                </th>
                <th style={{ minWidth: `${colWidths.DaysCargoLoad.web}rem` }}>
                    {t('DAYS_CARGO_LOAD')}
                </th>
                <th style={{ minWidth: `${colWidths.DaysCargoDischarge.web}rem` }}>
                    {t('DAYS_CARGO_DISCHARGE')}
                </th>
                <th style={{ minWidth: `${colWidths.DaysInPort.web}rem` }}>{t('DAYS_IN_PORT')}</th>
                <th style={{ minWidth: `${colWidths.DaysInPortPercentage.web}rem` }}>
                    {t('DAYS_IN_PORT_PERCENTAGE')}
                </th>
                {fuelTypes.seaBallast.map(fuelType => (
                    <th key={`sea.${fuelType.Id}.cons`} style={{ minWidth: `${fuelColWidth.web}rem` }}>
                        {`${fuelType.Name} (${translate('UNITS.CONSUMPTION')})`}
                    </th>
                ))}
                <th style={{ minWidth: `${colWidths.AvgConsumptionAtSeaBallast.web}rem` }}>{t('AVG')}</th>
                <th style={{ minWidth: `${colWidths.TotalConsumptionAtSeaBallast.web}rem` }}>
                    {t('TOTAL')}
                </th>
                {fuelTypes.seaLaden.map(fuelType => (
                    <th key={`sea.${fuelType.Id}.cons`} style={{ minWidth: `${fuelColWidth.web}rem` }}>
                        {`${fuelType.Name} (${translate('UNITS.CONSUMPTION')})`}
                    </th>
                ))}
                <th style={{ minWidth: `${colWidths.AvgConsumptionAtSeaLaden.web}rem` }}>{t('AVG')}</th>
                <th style={{ minWidth: `${colWidths.TotalConsumptionAtSeaLaden.web}rem` }}>{t('TOTAL')}</th>
                {fuelTypes.sea.map(fuelType => (
                    <th key={`sea.${fuelType.Id}.cons`} style={{ minWidth: `${fuelColWidth.web}rem` }}>
                        {`${fuelType.Name} (${translate('UNITS.CONSUMPTION')})`}
                    </th>
                ))}
                <th style={{ minWidth: `${colWidths.AvgConsumptionAtSea.web}rem` }}>{t('AVG')}</th>
                <th style={{ minWidth: `${colWidths.TotalConsumptionAtSea.web}rem` }}>{t('TOTAL')}</th>
                {fuelTypes.canal.map(fuelType => (
                    <th key={`canal.${fuelType.Id}.cons`} style={{ minWidth: `${fuelColWidth.web}rem` }}>
                        {`${fuelType.Name} (${translate('UNITS.CONSUMPTION')})`}
                    </th>
                ))}
                <th style={{ minWidth: `${colWidths.AvgConsumptionInCanal.web}rem` }}>{t('AVG')}</th>
                <th style={{ minWidth: `${colWidths.TotalConsumptionInCanal.web}rem` }}>{t('TOTAL')}</th>
                {fuelTypes.anchoredDrifting.map(fuelType => (
                    <th key={`anch.${fuelType.Id}.cons`} style={{ minWidth: `${fuelColWidth.web}rem` }}>
                        {`${fuelType.Name} (${translate('UNITS.CONSUMPTION')})`}
                    </th>
                ))}
                <th style={{ minWidth: `${colWidths.AvgConsumptionAnchoredDrifting.web}rem` }}>
                    {t('AVG')}
                </th>
                <th style={{ minWidth: `${colWidths.TotalConsumptionAnchoredDrifting.web}rem` }}>
                    {t('TOTAL')}
                </th>
                {fuelTypes.cargoLoad.map(fuelType => (
                    <th key={`cargoL.${fuelType.Id}.cons`} style={{ minWidth: `${fuelColWidth.web}rem` }}>
                        {`${fuelType.Name} (${translate('UNITS.CONSUMPTION')})`}
                    </th>
                ))}
                <th style={{ minWidth: `${colWidths.AvgConsumptionCargoLoad.web}rem` }}>{t('AVG')}</th>
                <th style={{ minWidth: `${colWidths.TotalConsumptionCargoLoad.web}rem` }}>{t('TOTAL')}</th>
                {fuelTypes.cargoDischarge.map(fuelType => (
                    <th key={`cargoD.${fuelType.Id}.cons`} style={{ minWidth: `${fuelColWidth.web}rem` }}>
                        {`${fuelType.Name} (${translate('UNITS.CONSUMPTION')})`}
                    </th>
                ))}
                <th style={{ minWidth: `${colWidths.AvgConsumptionCargoDischarge.web}rem` }}>{t('AVG')}</th>
                <th style={{ minWidth: `${colWidths.TotalConsumptionCargoDischarge.web}rem` }}>
                    {t('TOTAL')}
                </th>
                {fuelTypes.port.map(fuelType => (
                    <th key={`port.${fuelType.Id}.cons`} style={{ minWidth: `${fuelColWidth.web}rem` }}>
                        {`${fuelType.Name} (${translate('UNITS.CONSUMPTION')})`}
                    </th>
                ))}
                <th style={{ minWidth: `${colWidths.AvgConsumptionInPort.web}rem` }}>{t('AVG')}</th>
                <th style={{ minWidth: `${colWidths.TotalConsumptionInPort.web}rem` }}>{t('TOTAL')}</th>
                {emissionProps.map(emissionProp => (
                    <th
                        key={`${emissionProp.key}.cons`}
                        style={{ minWidth: `${colWidths[emissionProp.key].web}rem` }}
                    >
                        {`${emissionProp.label} (${translate('UNITS.EMISSIONS')})`}
                    </th>
                ))}
            </tr>
        </thead>
    );
};
