import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { getClassName } from 'utils/helpers/info-helper';
/* styles */
import './bunkerex-info.scss';
/* images */
import imgLogoBunkerex from 'assets/images/bunkerex/bunkerex_logo_white.png';

export class BunkerexInfo extends React.PureComponent {
    render() {
        return (
            <div className={getClassName('sten-bunkerex-info', this.props.className)}>
                <a
                    className="sten-bunkerex-info__link"
                    href="https://www.bunker-ex.com/"
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={imgLogoBunkerex} alt="bunkerex" />
                </a>
                <h4 className="text-secondary">{t('BUNKEREX_INFO')}</h4>
            </div>
        );
    }
}

BunkerexInfo.propTypes = {
    className: PropTypes.string
};

BunkerexInfo.defaultProps = {
    className: ''
};

export default BunkerexInfo;
