import { createSelector } from 'reselect';
/* utils */
import createByIdSelector from 'utils/helpers/selector-helper';
import { tileLoadFunction } from './nautical-charts-helpers';
/* constants */
import { nauticalChartLayersConfigs, requestParams } from './nautical-charts-constants';

const emptyArray = [];

export const getAvailableLayerOptions = createSelector(
    state => state.nauticalChartsReducer.availableLayers,
    (availableLayers) => availableLayers
        .filter(layer => nauticalChartLayersConfigs[layer.Name])
        .map(layer => ({
            ...layer,
            ...nauticalChartLayersConfigs[layer.Name],
            sourceProps: {
                params: { ...requestParams, LAYERS: layer.Name },
                url: '/',
                tileLoadFunction
            }
        }))
);

export const getSelectedLayerOptions = createSelector(
    getAvailableLayerOptions,
    state => state.userReducer.settings,
    (availableLayers, settings) => (settings
        ? availableLayers.filter(layer => settings.MapOptionsNauticalLayerIds?.indexOf(layer.Name) > -1)
        : emptyArray)
);

export const getSelectedLayerOptionsMap = createByIdSelector(
    getSelectedLayerOptions,
    'Name'
);
