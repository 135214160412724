import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import { formatNumber } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';

export function getNotificationProps(notification) {
    const res = {
        Message: notification.Message,
        Timestamp: notification.Timestamp
    };

    switch (notification.Class) {
    case 'EtaChanged': {
        if (notification.Context.ReferenceValue) {
            res.Message = translate('NOTIFICATIONS.MESSAGE.NEW_ETA', {
                timestamp: TimeHelper.getFormatted(notification.Context.Value, { utc: true, time: true, convert: true })
            });
        }
        res.Timestamp = TimeHelper.getFormatted(notification.ReportTimestamp, { utc: true, time: true });
        break;
    }
    case 'SpeedDeviation': {
        const { ReferenceValue, Value } = notification.Context;
        if (ReferenceValue) {
            const deviation = formatNumber(Value - ReferenceValue);
            res.Message = translate('NOTIFICATIONS.MESSAGE.SPEED_DEVIATION', { deviation });
        }
        res.Timestamp = TimeHelper.getFormatted(notification.ReportTimestamp, { utc: true, time: true });
        break;
    }
    case 'ConsumptionDeviation': {
        const { ReferenceValue, Value } = notification.Context;
        const deviation = formatNumber(Math.abs(Value - ReferenceValue));
        if (deviation >= 0) {
            res.Message = translate('NOTIFICATIONS.MESSAGE.CONSUMPTION_DEVIATION', { deviation });
        } else {
            res.Message = translate('NOTIFICATIONS.MESSAGE.CONSUMPTION_DEVIATION_UNDER', { deviation });
        }
        res.Timestamp = TimeHelper.getFormatted(notification.ReportTimestamp, { utc: true, time: true });
        break;
    }
    case 'ConsumptionDeviationAgainstCPA': {
        res.Timestamp = TimeHelper.getFormatted(notification.ReportTimestamp, { utc: true, time: true });
        break;
    }
    case 'SpeedDeviationAgainstCPA': {
        res.Timestamp = TimeHelper.getFormatted(notification.ReportTimestamp, { utc: true, time: true });
        break;
    }
    case 'MissingReport': {
        res.Message = translate('NOTIFICATIONS.MESSAGE.DAILY_REPORT', {
            timestamp: TimeHelper.getFormatted(notification.Context.Date, { utc: true, time: true })
        });
        res.Timestamp = TimeHelper.getFormatted(notification.Timestamp, { utc: true, time: true });
        break;
    }
    case 'CdiAge': {
        res.Message = translate('NOTIFICATIONS.MESSAGE.CDI_AGE', {
            timestamp: TimeHelper.getFormatted(notification.Context.InspectionDate, { utc: true, convert: true })
        });
        res.Timestamp = TimeHelper.getFormatted(notification.Timestamp, { time: true });
        break;
    }
    case 'SireAge': {
        res.Message = translate('NOTIFICATIONS.MESSAGE.SIRE_AGE', {
            timestamp: TimeHelper.getFormatted(notification.Context.InspectionDate, { utc: true, convert: true })
        });
        res.Timestamp = TimeHelper.getFormatted(notification.Timestamp, { time: true });
        break;
    }
    default:
    }

    return res;
}

export default getNotificationProps;
