/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { getVesselTechnicalDetails } from '../../shared/get-vessel-technical-details';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
import { getClassName } from 'utils/helpers/info-helper';
/* components */
import Checkbox from 'components/checkbox/checkbox';
import PositionListNote from '../../position-list-note/position-list-note';
import Select from 'components/select/select';
import ShowMoreButton from 'components/show-more-button/show-more-button';
/* styles */
import './position-list-table-row.scss';

export class PositionListTableRow extends React.PureComponent {
        state = {
            showMoreReports: false
        };

    getRemainingItinerary = () => {
        const remainingItineraries = this.props.vessel.NextPorts.length < 4 || this.state.showMoreReports
            ? this.props.vessel.NextPorts
            : this.props.vessel.NextPorts.slice(0, 2);
        if (!remainingItineraries || remainingItineraries.length === 0) {
            return null;
        }
        return (
            <ul className="sten-ul">
                {remainingItineraries.map((nextPort, portIndex) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={`${nextPort.Id}-${portIndex}`}>
                        {nextPort.Name}
                        <span className="text-secondary">
                            {` - ${t('POSITION_LIST.ETA')}${TimeHelper.getFormatted(nextPort.ETA, { utc: true, time: true })}`}
                        </span>
                    </li>
                ))}
                {this.props.vessel && this.props.vessel.NextPorts.length && this.props.vessel.NextPorts.length > 3 ? (
                    <ShowMoreButton isMoreShown={this.state.showMoreReports} onClick={this.toggleShowMoreReports} />
                ) : null}
            </ul>
        );
    };

    setActiveNoteForm = (mode) => {
        this.props.setActiveInputId(mode ? `${this.props.vessel.IMO}Note` : null);
    };

    setActiveInternalNoteForm = (mode) => {
        this.props.setActiveInputId(mode ? `${this.props.vessel.IMO}InternalNote` : null);
    };

    toggleShowMoreReports = () => {
        this.setState({
            showMoreReports: !this.state.showMoreReports
        });
    };

    submitNote = (isInternal = false) => (note, id) => {
        this.props.submitNote(this.props.vessel.IMO, note, id, isInternal);
    };

    removeNote = (isInternal = false) => (id) => {
        this.props.removeNote(this.props.vessel.IMO, id, isInternal);
    };

    vesselLinks = {};

    getLinkToVesselOnMap = (vessel) => {
        if (!this.vesselLinks[vessel]) {
            this.vesselLinks[vessel] = this.props.router.getLinkTo({ add: { vessel }, pathname: appRoutes.Map });
        }
        return this.vesselLinks[vessel];
    }

    openLoadReadyDateModal = () => this.props.onEditLoadReadyDate(this.props.vessel);

    handleLoadReadyDateDelete = () => {
        this.props.deleteLoadReadyDate(this.props.vessel.LoadReadyDate.Id);
    };

    renderLoadReadyDateCell() {
        const { LoadReadyDate } = this.props.vessel;
        const dateIconClass = `btn-link icon icon-calendar${!LoadReadyDate ? ' btn-link--secondary' : ''}`;

        return (
            <div className="text-left">
                <div
                    className="sten-text-crud__content flex-row flex-center sten-position-list-table-row__load-ready-date"
                >
                    <div className="flex-grow text-ellipsis">
                        {LoadReadyDate && (
                            <React.Fragment>
                                <h6 className="text-secondary flex-row">
                                    <div className="flex-grow">{LoadReadyDate.Author}</div>
                                    <div className="flex-shrink text-nowrap">
                                        {TimeHelper.getFormatted(LoadReadyDate.DateModified, { time: true })}
                                    </div>
                                </h6>
                                <p className="sten-position-list-table-row__load-ready-date--emphasized">
                                    {TimeHelper.getFormatted(LoadReadyDate.LoadReadyDate, { utc: true })}
                                </p>
                            </React.Fragment>
                        )}
                    </div>
                    {this.props.permissions.UpdateLoadReadyDate && (
                        <div className="flex-shrink">
                            <button className={dateIconClass} onClick={this.openLoadReadyDateModal} />
                        </div>
                    )}
                    {this.props.permissions.DeleteLoadReadyDate && LoadReadyDate && (
                        <div className="flex-shrink">
                            <button
                                className="btn-link icon icon-delete"
                                onClick={this.handleLoadReadyDateDelete}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }

    render() {
        const rowClass = getClassName('sten-position-list-table-row', {
            'sten-position-list-table-row--selected': this.props.isSelected,
            'sten-position-list-table-row--primary': this.props.vessel.IsVesselInUserScope
        });
        let nextOpen;
        if (this.props.vessel.OpenPort && this.props.vessel.OpenPort.Name) {
            nextOpen = `${this.props.vessel.OpenPort.Name}${this.props.vessel.OpenPort.Country
                ? ` (${this.props.vessel.OpenPort.Country.CountryCode})`
                : ''}`;
        } else if (!this.props.vessel.OpenPort && this.props.vessel.AreaName) {
            nextOpen = `${t('POSITION_LIST.REGION')} ${this.props.vessel.AreaName}`;
        } else {
            nextOpen = t('GLOBAL.UNAVAILABLE');
        }
        const vessel = {
            ...this.props.vessel,
            openStatus: this.props.vessel.open ? this.props.vessel.open : '',

            openDate: this.props.vessel.OpenDate
                ? TimeHelper.getFormatted(this.props.vessel.OpenDate, { utc: true })
                : '',

            nextOpen,

            etaDate: this.props.vessel.EtaDate
                ? TimeHelper.getFormatted(this.props.vessel.EtaDate, { utc: true })
                : '',

            errorHappened: this.props.vessel.ErrorHappened ? this.props.vessel.ErrorHappened : false,
            vesselStatus: this.props.vessel.vesselStatus ? this.props.vessel.vesselStatus : '',

            lastCargoes: this.props.vessel.Cargoes && this.props.vessel.Cargoes.length
                ? this.props.vessel.Cargoes : [],

            listItems: [
                `${this.props.vessel.vesselStatus || ''} ${this.props.vessel.cargo || ''}`,
                this.props.vessel.position || '',
                `${this.props.vessel.ETA
                    ? TimeHelper.getFormatted(this.props.vessel.eta, { utc: true, time: true, day: true })
                    : ''}`
            ]
        };
        const sireValue = vessel.LastSireDate
            ? [
                TimeHelper.getFormatted(vessel.LastSireDate, { utc: true }),
                <div key="sireAgo" className="text-secondary">{moment.utc(vessel.LastSireDate).fromNow()}</div>
            ]
            : null;

        const vesselTechnicalDetails = getVesselTechnicalDetails(
            vessel,
            this.props.selectedVesselInfoConfig,
            this.props.vesselInfoConfigurableProperties
        );

        return (
            <tr className={rowClass}>
                {this.props.onChange && (
                    <td className="sten-position-list-table__checkbox-cell">
                        <Checkbox
                            isChecked={this.props.isSelected}
                            onChange={this.props.onChange.bind(this, vessel.IMO)}
                        />
                    </td>
                )}
                {this.props.tableColumns.map((column) => {
                    switch (column.ColumnId) {
                    case 1: {
                        return (
                            <td key={column.ColumnId} style={{ width: `${column.width}rem` }}>
                                <Link to={this.getLinkToVesselOnMap(vessel.IMO)}>
                                    <h3 className="sten-link text-uppercase">
                                        <div className="sten-position-list-table-row__title">{vessel.VesselName}</div>
                                    </h3>
                                </Link>
                                <p className="text-secondary">
                                    {vesselTechnicalDetails.join(', ')}
                                </p>
                            </td>
                        );
                    }
                    case 9: {
                        return (
                            <td
                                key={column.ColumnId}
                                style={{ width: `${column.width}rem` }}
                            >
                                {this.props.setFixtureStatus ? (
                                    <Select
                                        valueKey="FixtureStatusId"
                                        labelKey="FixtureStatusDisplayName"
                                        options={this.props.fixtureStatuses}
                                        value={vessel.FixtureStatus}
                                        onChange={this.props.setFixtureStatus.bind(this, vessel.IMO)}
                                    />
                                ) : vessel.FixtureStatus.FixtureStatusDisplayName}
                            </td>
                        );
                    }
                    case 2: {
                        return (
                            <td
                                key={column.ColumnId}
                                style={{ width: `${column.width}rem` }}
                            >
                                {vessel.openDate}
                            </td>
                        );
                    }
                    case 3: {
                        return (
                            <td
                                key={column.ColumnId}
                                style={{ width: `${column.width}rem` }}
                            >
                                {vessel.nextOpen}
                            </td>
                        );
                    }
                    case 4: {
                        let etaDate;
                        if (!this.props.selectedPort) {
                            etaDate = t('POSITION_LIST.NO_PORT_SELECTED');
                        } else if (vessel.errorHappened) {
                            etaDate = t('POSITION_LIST.ROUTING_ERROR');
                        } else {
                            etaDate = vessel.etaDate;
                        }
                        return (
                            <td
                                key={column.ColumnId}
                                style={{ width: `${column.width}rem` }}
                            >
                                {etaDate}
                            </td>
                        );
                    }
                    case 10: {
                        return (
                            <td
                                key={column.ColumnId}
                                style={{ width: `${column.width}rem` }}
                            >
                                {vessel.Coating || '' }
                            </td>
                        );
                    }
                    case 5: {
                        return (
                            <td
                                key={column.ColumnId}
                                style={{ width: `${column.width}rem` }}
                            >
                                {this.getRemainingItinerary()}
                            </td>
                        );
                    }
                    case 6: {
                        return (
                            <td
                                key={column.ColumnId}
                                style={{ width: `${column.width}rem` }}
                            >
                                {sireValue}
                            </td>
                        );
                    }
                    case 7: {
                        return (
                            <td
                                key={column.ColumnId}
                                style={{ width: `${column.width}rem` }}
                            >
                                {vessel.lastCargoes.map((cargo, cargoIndex) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <ul className="sten-ul" key={cargoIndex}>
                                        <li>
                                            <span>{`${cargo.VoyageNumber} - `}</span>
                                            <span>
                                                {cargo.VoyageCargoes && cargo.VoyageCargoes.length > 0
                                                    ? cargo.VoyageCargoes.map(
                                                        voyageCommodity => (`${voyageCommodity.Commodity}`)
                                                    ).join(', ')
                                                    : t('GLOBAL.REQUEST_INFO')
                                                }
                                            </span>
                                        </li>
                                    </ul>
                                ))}
                            </td>
                        );
                    }
                    case 8: {
                        return (
                            <td
                                key={column.ColumnId}
                                className="sten-table__cell--no-padding"
                                style={{ width: `${column.width}rem` }}
                            >
                                <PositionListNote
                                    isReadOnly={!this.props.canUpdateComments}
                                    isActive={this.props.activeInputId === `${this.props.vessel.IMO}Note`}
                                    onActivate={this.setActiveNoteForm}
                                    onDeactivate={this.setActiveNoteForm}
                                    onUpdate={this.submitNote()}
                                    onRemove={this.removeNote()}
                                    note={this.props.vessel.Note}
                                />
                            </td>
                        );
                    }
                    case 11: {
                        return (
                            <td
                                key={column.ColumnId}
                                className="sten-table__cell--no-padding"
                                style={{ width: `${column.width}rem` }}
                            >
                                <PositionListNote
                                    isReadOnly={!this.props.canUpdateComments}
                                    isActive={this.props.activeInputId === `${this.props.vessel.IMO}InternalNote`}
                                    onActivate={this.setActiveInternalNoteForm}
                                    onDeactivate={this.setActiveInternalNoteForm}
                                    onUpdate={this.submitNote(true)}
                                    onRemove={this.removeNote(true)}
                                    note={this.props.vessel.InternalNote}
                                />
                            </td>
                        );
                    }
                    case 12: { // Load ready date
                        return (
                            <td
                                key={column.ColumnId}
                                className="sten-table__cell--no-padding"
                                style={{ width: `${column.width}rem` }}
                            >
                                {this.renderLoadReadyDateCell(column)}
                            </td>
                        );
                    }
                    default:
                        return null;
                    }
                })}
            </tr>
        );
    }
}

PositionListTableRow.propTypes = {
    activeInputId: PropTypes.string,
    canUpdateComments: PropTypes.bool.isRequired,
    deleteLoadReadyDate: PropTypes.func.isRequired,
    fixtureStatuses: PropTypes.arrayOf(PropTypes.object).isRequired,
    isSelected: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
    onEditLoadReadyDate: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    removeNote: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    selectedPort: PropTypes.objectOf(PropTypes.any),
    selectedVesselInfoConfig: PropTypes.arrayOf(PropTypes.any).isRequired,
    setActiveInputId: PropTypes.func.isRequired,
    setFixtureStatus: PropTypes.func,
    submitNote: PropTypes.func.isRequired,
    tableColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    vessel: PropTypes.objectOf(PropTypes.any).isRequired,
    vesselInfoConfigurableProperties: PropTypes.objectOf(PropTypes.any).isRequired
};

PositionListTableRow.defaultProps = {
    activeInputId: null,
    onChange: undefined,
    selectedPort: null,
    setFixtureStatus: undefined
};

export default withRouter(PositionListTableRow);
