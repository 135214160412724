import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* context */
import { WrapperContext } from '../wrapper/wrapper';
/* styles */
import './textarea.scss';

class Textarea extends React.PureComponent {
    state = {
        value: this.props.value,
        prevValue: this.props.value
    };

    static contextType = WrapperContext;

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.prevValue) {
            return {
                prevValue: props.value,
                value: props.value
            };
        }
        return null;
    }

    componentWillUnmount() {
        if (this.context.activeInput === this.textarea) {
            this.context.setActiveInput(null);
        }
        if (this.keyboardTimeout) {
            clearTimeout(this.keyboardTimeout);
        }
        if (this.changeDebounce) {
            clearTimeout(this.changeDebounce);
        }
    }

    componentDidMount() {
        this.handleResize();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.handleResize();
        }
    }

    onChange = event => {
        this.handleResize();
        this.setState({ value: event.target.value }, () => {
            if (this.changeDebounce) {
                clearTimeout(this.changeDebounce);
            }
            this.changeDebounce = setTimeout(() => {
                this.props.onChange(this.state.value);
            }, 50);
        });
    };

    handleResize = () => {
        if (this.textarea) {
            this.textarea.style.cssText = 'height: auto;';
            this.textarea.style.cssText = `height: ${this.textarea.scrollHeight}px`;
        }
    };

    onFocusChange(isFocused) {
        if (isFocused && !this.props.disabled && this.props.onScreenKeyboardEnabled) {
            if (this.context.activeInput !== this.textarea) {
                this.context.setActiveInput(this.textarea);
            }
            if (this.keyboardTimeout) {
                clearTimeout(this.keyboardTimeout);
            }
        } else {
            this.keyboardTimeout = setTimeout(() => {
                if (this.context.activeInput === this.textarea) {
                    this.context.setActiveInput(null);
                }
            }, 300);
        }
    }

    handleKeyDown(e) {
        e.stopPropagation();
    }

    saveTextareaRef = (c) => { this.textarea = c; };

    render() {
        let textareaClass = 'sten-textarea';
        if (this.props.invalid) {
            textareaClass += ' sten-textarea--invalid';
        }
        if (this.props.className) {
            textareaClass += ` ${this.props.className}`;
        }
        return (
            <textarea
                ref={this.saveTextareaRef}
                className={textareaClass}
                autoComplete={this.props.autoComplete}
                name={this.props.name}
                value={this.state.value}
                disabled={this.props.disabled}
                rows={this.props.rows}
                onInput={this.onChange}
                onChange={this.onChange}
                onBlur={this.onFocusChange.bind(this, false)}
                onFocus={this.onFocusChange.bind(this, true)}
                onScroll={this.handleScroll}
                onKeyDown={this.handleKeyDown}
                spellCheck={false}
                placeholder={this.props.placeholder}
            />
        );
    }
}

Textarea.propTypes = {
    autoComplete: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    onScreenKeyboardEnabled: PropTypes.bool,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    value: PropTypes.string
};

Textarea.defaultProps = {
    autoComplete: 'on',
    className: '',
    disabled: false,
    invalid: false,
    name: '',
    onScreenKeyboardEnabled: false,
    placeholder: '',
    rows: 8,
    value: ''
};

function mapStateToProps(state) {
    return {
        onScreenKeyboardEnabled: state.userReducer.settings && state.userReducer.settings.OnScreenKeyboardEnabled
    };
}


export default connect(mapStateToProps, null, null, { withRef: true })(Textarea);
