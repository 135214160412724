import React from 'react';
import PropTypes from 'prop-types';
/* styles */
import './fleet-average-speed-report-info-card.scss';

export default class FleetAverageSpeedReportInfoCard extends React.PureComponent {
    render() {
        return (
            <div className="sten-panel sten-energy-management-fleet-average-speed-report-info-card">
                <div className="sten-panel__header">
                    <h4 className="sten-panel__title">{this.props.headline}</h4>
                </div>
                <div className="sten-panel__body sten-energy-management-fleet-average-speed-report-info-card__body">
                    <div className="sten-panel__section flex-row flex-center">
                        <div className="flex-shrink">
                            <div className="sten-energy-management-fleet-average-speed-report-info-card__icon">
                                <span className={`icon ${this.props.iconClassName}`} />
                            </div>
                        </div>
                        <div className="sten-energy-management-fleet-average-speed-report-info-card__value flex-grow">
                            {this.props.value}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

FleetAverageSpeedReportInfoCard.propTypes = {
    className: PropTypes.string,
    headline: PropTypes.string.isRequired,
    iconClassName: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    valueClassName: PropTypes.string
};

FleetAverageSpeedReportInfoCard.defaultProps = {
    className: '',
    iconClassName: '',
    valueClassName: ''
};
