import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* Config */
import MapConfig from 'components/ol/configs/map';
/* Components */
import GsmVesselDetailsTooltipContent from './gsm-vessel-details-tooltip-content';

export class GsmVesselDetailsTooltip extends React.PureComponent {
    getPositionStyle() {
        const zoomMultiplier = this.props.position.zoomLevel < 4.8 ? 0.4 + (this.props.position.zoomLevel / 2) : 2.75;
        const correctionTop = zoomMultiplier * 8 * MapConfig.getFontSizeMultiplier();
        if (this.props.position && this.props.position.pixels) {
            return {
                transform: `translate(${this.props.position.pixels[0]}px,`
                + `${this.props.position.pixels[1] + correctionTop}px)`,
                willChange: 'transform'
            };
        }
        return {};
    }

    render() {
        if (!this.props.position || !this.props.selectedVessel) {
            return null;
        }

        let classes = 'sten-vessel-details-tooltip';
        classes += this.props.position ? '' : ' sten-vessel-details-tooltip--hidden';

        return (
            <div className={classes} style={this.getPositionStyle()}>
                <GsmVesselDetailsTooltipContent selectedVessel={this.props.selectedVessel} />
            </div>
        );
    }
}

GsmVesselDetailsTooltip.propTypes = {
    position: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedVessel: PropTypes.objectOf(PropTypes.any)
};

GsmVesselDetailsTooltip.defaultProps = {
    selectedVessel: null
};

function mapStateToProps(state) {
    return {
        position: state.gsmVesselDetailsTooltipReducer.position,
        selectedVessel: state.gsmReducer.selectedVessel
    };
}

export default connect(mapStateToProps)(GsmVesselDetailsTooltip);
