import React from 'react';
import PropTypes from 'prop-types';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* components */
import { Link } from 'react-router-dom';

class SuggestionLink extends React.PureComponent {
    render() {
        const { index, suggestion } = this.props;
        const { Key, Type, Value } = suggestion;
        return (
            <Link
                to={this.props.router.getLinkTo({ add: { reportId: Value.Id, reportType: Value.ReportType } })}
                key={`${Key}.${Type}.${index}`}
                className="sten-vessel-report__link"
            >
                <div
                    className="sten-vessel-report__suggestions cursor-pointer"
                    title={t('REPORT_LABELS.SUGGESTED_LINK_TOOLTIP')}
                >
                    <span className="text-secondary">{t('REPORT_LABELS.SUGGESTED_LINK')}</span>
                    <span className="sten-vessel-report__suggestions-label text-warning">{Value.Id}</span>
                </div>
            </Link>
        );
    }
}

SuggestionLink.propTypes = {
    index: PropTypes.number.isRequired,
    router: TRouter.isRequired,
    suggestion: PropTypes.shape({
        Key: PropTypes.string,
        Type: PropTypes.string,
        Value: PropTypes.shape({
            Id: PropTypes.string,
            ReportType: PropTypes.string
        })
    }).isRequired
};

export default withRouter(SuggestionLink);
