import React from 'react';
import PropTypes from 'prop-types';
/* helpers */
import { compareDates, getDateFromDifference, getDateFromStartOf } from 'components/htec-date-picker/helpers';
/* prop shapes */
import { DateShape } from 'components/htec-date-picker/types';
/* styles */
import './date-range-presets.scss';

const presetOptions = [
    { id: 0, title: '2 weeks', type: 'Last', unit: 'day', value: -14 },
    { id: 1, title: '1 month', type: 'Last', unit: 'month', value: -1 },
    { id: 2, title: '2 months', type: 'Last', unit: 'month', value: -2 },
    { id: 3, title: '3 months', type: 'Last', unit: 'month', value: -3 },
    { id: 4, title: '4 months', type: 'Last', unit: 'month', value: -4 },
    { id: 5, title: '5 months', type: 'Last', unit: 'month', value: -5 },
    { id: 6, title: '6 months', type: 'Last', unit: 'month', value: -6 },
    { id: 7, title: 'year', type: 'Last', unit: 'year', value: -1 },
    { id: 8, title: 'year to date', type: 'ToDate', unit: 'year', value: 1 }
];

export default class DateRangePresets extends React.PureComponent {
    generateDefaultPresets = () => {
        const currentDate = new Date();
        const today = {
            day: currentDate.getDate(),
            month: currentDate.getMonth() + 1,
            year: currentDate.getFullYear()
        };

        return presetOptions.map(po => ({
            id: po.id,
            title: po.title,
            startDate: po.type === 'ToDate'
                ? getDateFromStartOf(today, po.unit)
                : getDateFromDifference(today, po.unit, po.value),
            endDate: today
        }));
    };

    defaultPresets = this.generateDefaultPresets();

    handlePresetClick = (rangeStart, rangeEnd) => this.props.onPresetClick({ rangeStart, rangeEnd });

    render() {
        const { rangeStart, rangeEnd } = this.props;
        const presets = this.props.presets && this.props.presets.length ? this.props.presets : this.defaultPresets;
        return (
            <div className="sten-date-range-presets">
                <div className="row row--fluid">
                    {presets.map(({ id, title, startDate, endDate }) => {
                        let btnClassName = 'btn btn--xs sten-date-range-presets__btn';
                        if (compareDates(rangeStart, startDate) === 0 && compareDates(rangeEnd, endDate) === 0) {
                            btnClassName += ' sten-date-range-presets__btn--selected';
                        }

                        return (
                            <div key={id} className="col--fluid">
                                <button
                                    type="button"
                                    className={btnClassName}
                                    onClick={this.handlePresetClick.bind(this, startDate, endDate)}
                                >
                                    {title}
                                </button>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

DateRangePresets.propTypes = {
    onPresetClick: PropTypes.func.isRequired,
    presets: PropTypes.arrayOf(PropTypes.shape({
        endDate: PropTypes.shape(DateShape),
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        startDate: PropTypes.shape(DateShape),
        title: PropTypes.string
    })),
    rangeEnd: PropTypes.shape(DateShape),
    rangeStart: PropTypes.shape(DateShape)
};

DateRangePresets.defaultProps = {
    presets: null,
    rangeEnd: null,
    rangeStart: null
};
