import React from 'react';

const tankLayoutSvg = (
    <div className="sten-tank-layout-vessel-svg flex">
        <svg
            height="166"
            width="140"
            viewBox="0 0 140 166"
            className="sten-tank-layout-vessel-svg__head flex-shrink"
            preserveAspectRatio="xMinYMid slice"
        >
            <path
                className="sten-tank-layout-vessel-svg__bg"
                d="M0 83 Q30 0 140 0 L140 166 Q30 166 0 83"
            />
        </svg>
        <svg
            height="166"
            width="2080"
            viewBox="0 0 2080 166"
            className="sten-tank-layout-vessel-svg__tail flex-grow"
            preserveAspectRatio="xMaxYMax slice"
        >
            <path
                className="sten-tank-layout-vessel-svg__bg"
                d="M0 0 L2010 0 L2080 20 L2080 146 L2010 166 L0 166"
            />
            <path
                className="sten-tank-layout-vessel-svg__path-1"
                d="M1000 26 L2042 26 L2042 50 L2058 50 L2058 116 L2042 116 L2042 140
                    L2000 140 L2000 100 L2014 100 L2014 66 L2000 66 L2000 26"
            />
            <path
                className="sten-tank-layout-vessel-svg__path-2"
                d="M1000 18 L2010 18 L2016 52 L2028 52 L2028 114 L2016 114 L2010 148
                    L2000 148 L2000 100 L2014 100 L2014 66 L2000 66 L2000 18"
            />
            <path
                className="sten-tank-layout-vessel-svg__path-3"
                d="M1028 66 L2042 66 L2042 100 L2028 100 L2028 66"
            />
        </svg>
    </div>
);

export default tankLayoutSvg;
