import React from 'react';
import PropTypes from 'prop-types';
/* components */
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
/* styles */
import './reorderable-rows-table.scss';

export default class ReorderableRowsTable extends React.Component {
    static NOT_EXIST = -1;

    static DRAG_START_TIMEOUT = 200;

    state = {
        isDragging: false,
        indexOfItemBeingDragged: ReorderableRowsTable.NOT_EXIST,
        highlightedDropZoneIndex: ReorderableRowsTable.NOT_EXIST
    };

    dragItemWrapperRefs = {};

    handleMouseDown = (index, e) => {
        const domEvent = e.nativeEvent;
        window.addEventListener('mouseup', this.handleMouseUp);
        this.dragstartTimeoutHandle = setTimeout(() => {
            this.clearTextSelection();
            this.createGhostElement(index, domEvent);
            this.updateGhostElementPosition(domEvent);
            window.addEventListener('mousemove', this.updateGhostElementPosition);
            this.setState({
                isDragging: true,
                indexOfItemBeingDragged: index,
                highlightedDropZoneIndex: index
            });
        }, ReorderableRowsTable.DRAG_START_TIMEOUT);
    };

    updateGhostElementPosition = (e) => {
        this.ghostElement.style.transform = `translate(${e.pageX}px, ${e.pageY}px)`;
    };

    createGhostElement = (index, e) => {
        const itemDomNode = this.dragItemWrapperRefs[index];
        const dimensions = itemDomNode.getBoundingClientRect();
        const tdSizes = [];
        for (let child = itemDomNode.firstChild; child !== null; child = child.nextSibling) {
            tdSizes.push(child.getBoundingClientRect());
        }
        let wrapper = itemDomNode.cloneNode(true);
        const children = Array.prototype.slice.call(wrapper.children);
        children.forEach((c, i) => {
            c.style.width = `${tdSizes[i].width}px`; // eslint-disable-line no-param-reassign
            c.style.height = `${tdSizes[i].height}px`; // eslint-disable-line no-param-reassign
        });
        let el = itemDomNode;
        let tmp;
        while (el.nodeName !== 'TABLE') {
            el = el.parentElement;
            tmp = el.cloneNode(false);
            tmp.appendChild(wrapper);
            wrapper = tmp;
        }
        wrapper.style.width = 'auto';
        wrapper.style.height = 'auto';
        tmp = document.createElement('div');
        tmp.appendChild(wrapper);
        wrapper = tmp;
        wrapper.style.width = `${dimensions.width}px`;
        wrapper.style.height = `${dimensions.height}px`;
        wrapper.style.position = 'fixed';
        wrapper.style.top = `${dimensions.top - e.clientY}px`;
        wrapper.style.left = `${dimensions.left - e.clientX}px`;
        wrapper.style.pointerEvents = 'none';
        wrapper.style.willChange = 'transform, translate';
        wrapper.setAttribute(
            'class',
            `${wrapper.getAttribute('class')} reorderable-rows-table__row`
        );
        this.ghostElement = wrapper;
        document.body.appendChild(this.ghostElement);
    };

    killGhostElement = () => {
        if (this.ghostElement) {
            document.body.removeChild(this.ghostElement);
            this.ghostElement = undefined;
        }
    };

    componentWillUnmount() {
        this.killGhostElement();
    }

    handleMouseUp = () => {
        clearTimeout(this.dragstartTimeoutHandle);
        if (this.state.isDragging) {
            window.removeEventListener('mousemove', this.updateGhostElementPosition);
            window.removeEventListener('mouseup', this.handleMouseUp);
            this.killGhostElement();

            const { highlightedDropZoneIndex, indexOfItemBeingDragged } = this.state;
            const highlightedDropZoneIndexFixed = highlightedDropZoneIndex > indexOfItemBeingDragged
                ? highlightedDropZoneIndex - 1
                : highlightedDropZoneIndex;
            const shouldReorderRows = highlightedDropZoneIndexFixed !== ReorderableRowsTable.NOT_EXIST
                && highlightedDropZoneIndexFixed !== indexOfItemBeingDragged;

            if (shouldReorderRows) {
                this.props.onDragComplete(
                    indexOfItemBeingDragged,
                    highlightedDropZoneIndexFixed
                );
            }

            this.setState({
                isDragging: false,
                indexOfItemBeingDragged: ReorderableRowsTable.NOT_EXIST
            });
        }
    };

    saveDragItemWrapperRef = (index, ref) => {
        this.dragItemWrapperRefs[index] = ref;
    };

    renderRow = (row, index) => this.props.renderRow({
        dragHandleMouseDown: this.handleMouseDown.bind(this, index),
        row,
        index,
        ref: this.saveDragItemWrapperRef.bind(this, index),
        handleMouseEnter: this.determineWhichDropZoneShouldBeHighlighted.bind(this, index)
    });

    determineWhichDropZoneShouldBeHighlighted = (index, e) => {
        if (this.state.isDragging) {
            const rect = e.currentTarget.getBoundingClientRect();
            const yPositionWithinElement = e.clientY - rect.top;
            if (yPositionWithinElement > rect.height / 2) { // lower half
                this.highlightDropZone(index);
            } else { // upper half
                this.highlightDropZone(index + 1);
            }
        }
    };

    highlightDropZone = (dropZoneIndex) => {
        if (dropZoneIndex !== this.state.highlightedDropZoneIndex) {
            this.setState({
                highlightedDropZoneIndex: dropZoneIndex
            });
        }
    };

    renderRowsWithDropZones() {
        const { highlightedDropZoneIndex, indexOfItemBeingDragged } = this.state;
        const { renderDropZone, rows } = this.props;

        const items = [];
        rows.forEach((row, index) => {
            if (index === highlightedDropZoneIndex) { // render drop zone
                items.push(renderDropZone(`dropzone-${index}`));
            }
            if (index !== indexOfItemBeingDragged) { // skip dragged row
                items.push(this.renderRow(row, index));
            }
        }, this);

        if (highlightedDropZoneIndex === rows.length) {
            items.push(renderDropZone(`dropzone-${highlightedDropZoneIndex}`));
        }

        return items;
    }

    clearTextSelection = () => {
        if (window.getSelection) {
            if (window.getSelection().empty) { // Chrome
                window.getSelection().empty();
            } else if (window.getSelection().removeAllRanges) { // Firefox
                window.getSelection().removeAllRanges();
            }
        } else if (document.selection) { // IE?
            document.selection.empty();
        }
    };

    renderRows() {
        const items = [];
        let itemReactEl;
        this.props.rows.forEach((row, index) => {
            itemReactEl = this.renderRow(row, index);
            items.push(itemReactEl);
        }, this);

        return items;
    }

    saveTableRef = (c) => {
        this.fixedHeaderTableRef = c;
    };

    render() {
        const content = this.state.isDragging
            ? this.renderRowsWithDropZones()
            : this.renderRows();
        return (
            <FixedHeaderTable
                ref={this.saveTableRef}
                {...this.props.fixedHeaderTableProps}
                dragMode={this.state.isDragging}
            >
                {this.props.rowsTableWrapper(content)}
            </FixedHeaderTable>
        );
    }
}

ReorderableRowsTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.any).isRequired,
    renderDropZone: PropTypes.func,
    onDragComplete: PropTypes.func.isRequired,
    renderRow: PropTypes.func.isRequired,
    rowsTableWrapper: PropTypes.func,
    fixedHeaderTableProps: PropTypes.objectOf(PropTypes.any).isRequired
};

ReorderableRowsTable.defaultProps = {
    renderDropZone: (key) => {
        return (
            <tr key={key} className="reorderable-rows-table__drop-zone">
                <td colSpan="11"><div /></td>
            </tr>
        );
    },
    rowsTableWrapper: (children) => (
        <table><tbody>{children}</tbody></table>
    )
};
