import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import Menu, { MenuItem } from 'components/menu/menu';
/* styles */
import './file-upload.scss';

const iconsMap = {
    'application/vnd.ms-excel': 'icon-excel',
    'application/pdf': 'icon-pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'icon-excel'
};

class FileUploadItem extends React.PureComponent {
    render() {
        const time = this.props.file.time;
        const author = this.props.file.author;
        return (
            <li key={this.props.file.name} className="sten-file-upload__item flex flex-center">
                <div className={`sten-file-upload__item-icon flex-shrink icon ${iconsMap[this.props.file.type]}`} />
                <div className="sten-file-upload__item-info text-ellipsis flex-grow">
                    {this.props.file.name}
                    {author && time && (
                        <div className="text-secondary flex">
                            <div className="flex-shrink">
                                {`${translate('FILE_UPLOAD.UPLOADED_INFO', { time, author })}`}
                            </div>
                        </div>
                    )}
                </div>
                <Menu>
                    <MenuItem key="delete" onClick={this.props.onDeleteClick}>
                        {`${translate('GLOBAL.DELETE')}`}
                    </MenuItem>
                    {this.props.onDownloadClick && this.props.file.id && (
                        <MenuItem key="download" onClick={this.props.onDownloadClick}>
                            {`${translate('GLOBAL.DOWNLOAD')}`}
                        </MenuItem>
                    )}
                </Menu>
            </li>
        );
    }
}

FileUploadItem.propTypes = {
    file: PropTypes.objectOf(PropTypes.any),
    onDeleteClick: PropTypes.func,
    onDownloadClick: PropTypes.func
};

FileUploadItem.defaultProps = {
    file: null,
    onDeleteClick: undefined,
    onDownloadClick: undefined
};

export default FileUploadItem;
