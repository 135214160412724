import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const portalsRoot = document.createElement('div');
document.body.appendChild(portalsRoot);

export default class Portal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.el = document.createElement('div');
    }

    componentDidMount() {
        portalsRoot.appendChild(this.el);
    }

    componentWillUnmount() {
        portalsRoot.removeChild(this.el);
    }

    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.el
        );
    }
}

Portal.propTypes = {
    children: PropTypes.node.isRequired
};
