export default {
    monthNames: {
        long: {
            1: 'January',
            2: 'February',
            3: 'March',
            4: 'April',
            5: 'May',
            6: 'June',
            7: 'July',
            8: 'August',
            9: 'September',
            10: 'October',
            11: 'November',
            12: 'December'
        },
        short: {
            1: 'Jan',
            2: 'Feb',
            3: 'Mar',
            4: 'Apr',
            5: 'May',
            6: 'Jun',
            7: 'Jul',
            8: 'Aug',
            9: 'Sep',
            10: 'Oct',
            11: 'Nov',
            12: 'Dec'
        }
    },
    weekDayNames: {
        short: {
            0: 'Su',
            1: 'Mo',
            2: 'Tu',
            3: 'We',
            4: 'Th',
            5: 'Fr',
            6: 'Sa'
        },
        long: {
            0: 'Sunday',
            1: 'Monday',
            2: 'Tuesday',
            3: 'Wednesday',
            4: 'Thursday',
            5: 'Friday',
            6: 'Saturday'
        }
    },
    timeNames: {
        long: {
            hour: 'hour',
            minute: 'minute',
            second: 'second'
        },
        short: {
            hour: 'h',
            minute: 'm',
            second: 's'
        }
    },
    timeConfig: {
        hour: {
            hidden: false,
            disabled: false
        },
        minute: {
            hidden: false,
            disabled: false
        },
        second: {
            hidden: false,
            disabled: false
        }
    }
};
