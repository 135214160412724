import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* types */
import { TEnergyManagementReportsQueryParams } from '../reports-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* components */
import Select from 'components/select/select';
import VoyageReportInclusions from './inclusions/voyage-report-inclusions';
import WeatherPerformanceReportInclusions from './inclusions/weather-performance-report-inclusions';
import MultiVoyageReportInclusions from './inclusions/multi-voyage-report-inclusions';
import FleetAverageSpeedReportInclusions from './inclusions/fleet-average-speed-report-inclusions';
import PerformanceSummaryReportInclusions from './inclusions/performance-summary-report-inclusions';
import FleetPerformanceReportInclusions from './inclusions/fleet-performance-report-inclusions';
import QuarterlyReportInclusions from './inclusions/quarterly-report-inclusions';
import FleetListReportInclusions from './inclusions/fleet-list-report-inclusions';
import RightSideBar from 'components/right-side-bar/right-side-bar';
/* actions */
import {
    toggleRangeSelector,
    toggleSidebar,
    setSelectedReportType,
    setSelectedVessel,
    setSelectedVoyage,
    setSelectedRange,
    setSelectedInclusionPreset,
    setInclusionValue,
    resetInclusions,
    generateReportPreview
} from '../reports-actions';
/* selectors */
import { getVesselsByOptions, getVesselsForFleetListByOptions } from '../reports-selectors';
/* styles */
import './sidebar.scss';

class EnergyManagementReportsSidebar extends React.PureComponent {
    getInclusions = () => {
        if (this.props.selectedReportType) {
            switch (this.props.selectedReportType.Id) {
            case 1:
                return (
                    <VoyageReportInclusions
                        rangeSelectorActive={this.props.rangeSelectorActive}
                        selectedRange={this.props.selectedRange}
                        selectedVessel={this.props.selectedVessel}
                        setSelectedVessel={this.props.setSelectedVessel}
                        setSelectedRange={this.props.setSelectedRange}
                        selectedVoyage={this.props.selectedVoyage}
                        setSelectedVoyage={this.props.setSelectedVoyage}
                        inclusionPresets={this.props.inclusionPresets}
                        selectedInclusionPreset={this.props.selectedInclusionPreset}
                        setSelectedInclusionPreset={this.props.setSelectedInclusionPreset}
                        toggleRangeSelector={this.props.toggleRangeSelector}
                        inclusions={this.props.inclusions}
                        setInclusionValue={this.setInclusionValue}
                        toggleInclusionValue={this.toggleInclusionValue}
                        resetInclusions={this.props.resetInclusions}
                        onSubmit={this.props.generateReportPreview}
                    />
                );
            case 4:
                return (
                    <MultiVoyageReportInclusions
                        selectedVessel={this.props.selectedVessel}
                        setSelectedVessel={this.props.setSelectedVessel}
                        selectedRange={this.props.selectedRange}
                        setSelectedRange={this.props.setSelectedRange}
                        inclusionPresets={this.props.inclusionPresets}
                        selectedInclusionPreset={this.props.selectedInclusionPreset}
                        setSelectedInclusionPreset={this.props.setSelectedInclusionPreset}
                        inclusions={this.props.inclusions}
                        setInclusionValue={this.setInclusionValue}
                        toggleInclusionValue={this.toggleInclusionValue}
                        resetInclusions={this.props.resetInclusions}
                        onSubmit={this.props.generateReportPreview}
                    />
                );
            case 2:
                return (
                    <PerformanceSummaryReportInclusions
                        selectedRange={this.props.selectedRange}
                        setSelectedRange={this.props.setSelectedRange}
                        inclusions={this.props.inclusions}
                        setInclusionValue={this.setInclusionValue}
                        resetInclusions={this.props.resetInclusions}
                        onSubmit={this.props.generateReportPreview}
                        vesselsBySegment={this.props.vesselsByOption.vesselsBySegment}
                    />
                );
            case 9:
                return (
                    <FleetAverageSpeedReportInclusions
                        selectedRange={this.props.selectedRange}
                        setSelectedRange={this.props.setSelectedRange}
                        inclusionPresets={this.props.inclusionPresets}
                        selectedInclusionPreset={this.props.selectedInclusionPreset}
                        setSelectedInclusionPreset={this.props.setSelectedInclusionPreset}
                        inclusions={this.props.inclusions}
                        setInclusionValue={this.setInclusionValue}
                        toggleInclusionValue={this.toggleInclusionValue}
                        resetInclusions={this.props.resetInclusions}
                        onSubmit={this.props.generateReportPreview}
                        vesselsBySegment={this.props.vesselsByOption.vesselsBySegment}
                    />
                );
            case 10:
                return (
                    <FleetPerformanceReportInclusions
                        selectedRange={this.props.selectedRange}
                        setSelectedRange={this.props.setSelectedRange}
                        onSubmit={this.props.generateReportPreview}
                        vesselsByCustomFleet={this.props.vesselsByOption.vesselsByCustomFleet}
                        vesselsByFleet={this.props.vesselsByOption.vesselsByFleet}
                        vesselsBySegment={this.props.vesselsByOption.vesselsBySegment}
                    />
                );
            case 11:
                return (
                    <QuarterlyReportInclusions
                        selectedRange={this.props.selectedRange}
                        setSelectedRange={this.props.setSelectedRange}
                        onSubmit={this.props.generateReportPreview}
                        vesselsByCustomFleet={this.props.vesselsByOption.vesselsByCustomFleet}
                        vesselsByFleet={this.props.vesselsByOption.vesselsByFleet}
                        vesselsBySegment={this.props.vesselsByOption.vesselsBySegment}
                    />
                );
            case 12:
                return (
                    <FleetListReportInclusions
                        onSubmit={this.props.generateReportPreview}
                        vesselsByCustomFleet={this.props.vesselsForFleetListByOptions.vesselsByCustomFleet}
                        vesselsByFleet={this.props.vesselsForFleetListByOptions.vesselsByFleet}
                        vesselsBySegment={this.props.vesselsForFleetListByOptions.vesselsBySegment}
                    />
                );
            case 13:
                return (
                    <WeatherPerformanceReportInclusions
                        queryParams={this.props.queryParams}
                        selectedRange={this.props.selectedRange}
                        setSelectedRange={this.props.setSelectedRange}
                        selectedVessel={this.props.selectedVessel}
                        setSelectedVessel={this.props.setSelectedVessel}
                        selectedVoyage={this.props.selectedVoyage}
                        setSelectedVoyage={this.props.setSelectedVoyage}
                        onSubmit={this.props.generateReportPreview}
                    />
                );
            default:
                return '';
            }
        }
        return null;
    };

    setInclusionValue = (key, prop) => {
        return (value) => {
            if (prop && value && value[prop]) {
                this.props.setInclusionValue(key, value[prop]);
            } else {
                this.props.setInclusionValue(key, value);
            }
        };
    };

    toggleInclusionValue = (key) => {
        return () => {
            this.props.setInclusionValue(key, !this.props.inclusions[key]);
        };
    };

    render() {
        return (
            <RightSideBar isCollapsed={!this.props.isSidebarVisible}>
                {this.props.isSidebarVisible && (
                    <div className="sten-content">
                        <div className="sten-content__header flex-row">
                            <h1 className="sten-content__title flex-grow">
                                {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.GENERATE_REPORT')}
                            </h1>
                            <div className="flex-shrink">
                                <button onClick={this.props.toggleSidebar} className="btn-link icon icon-close" />
                            </div>
                        </div>
                        <div className="sten-energy-management-reports-sidebar__body-fixed">
                            <div className="sten-content__section">
                                <div className="form-row">
                                    <Select
                                        options={this.props.reportTypes}
                                        labelKey="Name"
                                        valueKey="Id"
                                        value={this.props.selectedReportType}
                                        onChange={this.props.setSelectedReportType}
                                        placeholder={t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.SELECT_REPORT_TYPE')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sten-energy-management-reports-sidebar__body-content">
                            {this.getInclusions()}
                        </div>
                    </div>
                )}
            </RightSideBar>
        );
    }
}

EnergyManagementReportsSidebar.propTypes = {
    inclusionPresets: PropTypes.arrayOf(PropTypes.object).isRequired,
    inclusions: PropTypes.objectOf(PropTypes.any),
    generateReportPreview: PropTypes.func.isRequired,
    isSidebarVisible: PropTypes.bool.isRequired,
    queryParams: TEnergyManagementReportsQueryParams.isRequired,
    rangeSelectorActive: PropTypes.bool.isRequired,
    reportTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    resetInclusions: PropTypes.func.isRequired,
    selectedInclusionPreset: PropTypes.objectOf(PropTypes.any),
    selectedRange: PropTypes.objectOf(PropTypes.any),
    selectedReportType: PropTypes.objectOf(PropTypes.any),
    selectedVessel: PropTypes.objectOf(PropTypes.any),
    selectedVoyage: PropTypes.objectOf(PropTypes.any),
    setInclusionValue: PropTypes.func.isRequired,
    setSelectedInclusionPreset: PropTypes.func.isRequired,
    setSelectedRange: PropTypes.func.isRequired,
    setSelectedReportType: PropTypes.func.isRequired,
    setSelectedVessel: PropTypes.func.isRequired,
    setSelectedVoyage: PropTypes.func.isRequired,
    toggleRangeSelector: PropTypes.func.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    vesselsByOption: PropTypes.objectOf(PropTypes.any).isRequired,
    vesselsForFleetListByOptions: PropTypes.objectOf(PropTypes.any).isRequired
};

EnergyManagementReportsSidebar.defaultProps = {
    inclusions: null,
    selectedInclusionPreset: null,
    selectedRange: null,
    selectedReportType: null,
    selectedVessel: null,
    selectedVoyage: null
};

function mapStateToProps(state) {
    return {
        inclusionPresets: state.energyManagementReportsReducer.inclusionPresets,
        inclusions: state.energyManagementReportsReducer.inclusions,
        isSidebarVisible: state.energyManagementReportsReducer.isSidebarVisible,
        rangeSelectorActive: state.energyManagementReportsReducer.rangeSelectorActive,
        reportTypes: state.energyManagementReportsReducer.reportTypes,
        selectedInclusionPreset: state.energyManagementReportsReducer.selectedInclusionPreset,
        selectedRange: state.energyManagementReportsReducer.selectedRange,
        selectedReportType: state.energyManagementReportsReducer.selectedReportType,
        selectedVessel: state.energyManagementReportsReducer.selectedVessel,
        selectedVoyage: state.energyManagementReportsReducer.selectedVoyage,
        vesselsByOption: getVesselsByOptions(state),
        vesselsForFleetListByOptions: getVesselsForFleetListByOptions(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        generateReportPreview: () => generateReportPreview(dispatch),
        resetInclusions: () => resetInclusions(dispatch),
        setInclusionValue: (field, value) => setInclusionValue(dispatch, field, value),
        setSelectedInclusionPreset: (preset) => setSelectedInclusionPreset(dispatch, preset),
        setSelectedRange: range => setSelectedRange(dispatch, range),
        setSelectedReportType: reportType => setSelectedReportType(dispatch, reportType),
        setSelectedVessel: vessel => setSelectedVessel(dispatch, vessel),
        setSelectedVoyage: voyage => setSelectedVoyage(dispatch, voyage),
        toggleRangeSelector: flag => toggleRangeSelector(dispatch, flag),
        toggleSidebar: () => toggleSidebar(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EnergyManagementReportsSidebar);
