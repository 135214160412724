import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
/* Components */
import Portal from 'components/portal/portal';
import ScrollArea from 'components/scroll-area/scroll-area';
import Input from 'components/input/input';
import SelectOption from './select-option/select-option';
import SelectValue from './select-value/select-value';
/* utils */
import keyCodes from 'utils/key-codes';
import { getClassName } from 'utils/helpers/info-helper';
/* styles */
import './select.scss';

class Select extends React.PureComponent {
    state = {
        isMenuShown: false,
        searchCriteria: '',
        collapseDown: this.props.collapseDown,
        inputFocused: false,
        highlightedIndex: -1
    };

    componentDidMount() {
        if (!this.props.value && this.props.preSelectedValue !== null) {
            if (this.props.onSearch) {
                this.props.onSearch(this.props.preSelectedValue);
            }
            if (this.props.options.length) {
                this.preselectValue(this.props.preSelectedValue);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (this.scrollArea && prevProps.options !== this.props.options) {
            this.scrollArea.scrollTop();
        }
        if (!this.props.value && this.props.preSelectedValue !== null && prevProps.options !== this.props.options) {
            this.preselectValue(this.props.preSelectedValue);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.documentOnClick);
        document.removeEventListener('scrollChanged', this.handleScrollChange);
        if (this.debounce) {
            clearTimeout(this.debounce);
        }
        if (this.focusDebounce) {
            clearTimeout(this.focusDebounce);
        }
        if (this.blurDebounce) {
            clearTimeout(this.blurDebounce);
        }
    }

    valuePreselected = false;

    preselectValue = (value) => {
        if (!this.valuePreselected) {
            const { options, valueKey, multiple } = this.props;
            const selectedValue = options.find(op => op[valueKey] === value);
            if (selectedValue) {
                this.valuePreselected = true;
                this.props.onChange(multiple ? [selectedValue] : selectedValue);
            }
        }
    };

    onInputChange = value => {
        this.setState({
            searchCriteria: value,
            highlightedIndex: -1,
            highlightedItem: null
        });
        if (this.scrollArea && this.scrollArea.state.topPosition !== 0) {
            this.scrollArea.scrollTop();
        }
        if (value.length > 0) {
            this.toggleSelectMenu(true);
        }
        if (this.props.onSearch) {
            if (this.debounce) {
                clearTimeout(this.debounce);
            }
            const trimmedValue = value.trim();
            if (trimmedValue.length >= 3) {
                this.debounce = setTimeout(() => {
                    this.props.onSearch(trimmedValue);
                }, 500);
            }
        }
    };

    handleInputFocusChange = isFocused => {
        if (this.focusDebounce) {
            clearTimeout(this.focusDebounce);
        }
        this.focusDebounce = setTimeout(() => {
            if (isFocused && !this.state.inputFocused) {
                const searchCriteria = this.props.setSearchCriteriaOnFocus
                && this.props.value
                && this.props.value[this.props.labelKey]
                    ? this.props.value[this.props.labelKey]
                    : '';
                this.setState({ inputFocused: true, searchCriteria });
            } else if (!isFocused && this.state.inputFocused) {
                this.setState({ inputFocused: false });
            }
        }, 200);
    };

    onOuterDivFocus = () => {
        if (this.selectInput) {
            this.selectInput.wrappedInstance.setFocus();
        }
    };

    isKeyboardClick = target => (
        target && typeof target.className === 'string'
        && target.className.indexOf('keyboard-button') >= 0
    );

    onOuterDivBlur = e => {
        if (this.blurDebounce) {
            clearTimeout(this.blurDebounce);
        }
        const target = e.relatedTarget || e.target;
        if (!this.isKeyboardClick(target) && this.select && !this.select.contains(target)
            && this.selectOptionsRef && !this.selectOptionsRef.contains(target)) {
            this.blurDebounce = setTimeout(() => {
                this.setState({ inputFocused: false });
                this.toggleSelectMenu(false);
            }, 200);
        }
    };

    onSelectClick = () => {
        if (!this.props.disabled && !this.props.readOnly) {
            this.onOuterDivFocus();
            this.toggleSelectMenu();
        }
    };

    onMenuClick = (e) => {
        e.stopPropagation();
    };

    splitOptionsIntoSections = (options) => {
        if (this.props.splitOptionsIntoSections) {
            return this.props.splitOptionsIntoSections(options);
        }
        return { options };
    };

    getOptions = () => {
        const options = this.props.onSearch && this.props.multiple
            ? this.getUniqueOptionsMemoized(this.props.options)
            : this.props.options;
        return this.getOptionsMemoized(options, this.state.searchCriteria);
    };

    getUniqueOptionsMemoized = memoize((options) => {
        if (options.length === 0) {
            return this.props.value;
        }
        if (this.props.value.length === 0) {
            return options;
        }
        const idMap = {};
        const uniqueOptions = [...options];
        options.forEach(option => {
            idMap[option[this.props.valueKey]] = true;
        });
        this.props.value.forEach(selectedOption => {
            if (!idMap[selectedOption[this.props.valueKey]]) {
                uniqueOptions.push(selectedOption);
            }
        });
        return uniqueOptions;
    });

    getOptionsMemoized = memoize((optionsUnfiltered, searchCriteria) => {
        let options = [...optionsUnfiltered] || [];
        if (this.props.searchable && searchCriteria.length > 0) {
            const searchCriteriaTrimmed = searchCriteria.trim();
            const searchCriteriaTrimmedUC = searchCriteriaTrimmed.toUpperCase();
            if (!this.props.onSearch) {
                options = options.filter(
                    option => option[this.props.labelKey].toUpperCase().indexOf(searchCriteriaTrimmedUC) >= 0
                );
            }
            if (this.props.includeSearchInResults) {
                const matchingOption = options.find(
                    option => option[this.props.labelKey].toUpperCase() === searchCriteriaTrimmedUC
                );
                if (!matchingOption) {
                    const searchCriteriaOption = {};
                    searchCriteriaOption[this.props.labelKey] = searchCriteriaTrimmed;
                    searchCriteriaOption[this.props.valueKey] = 'customInput';
                    options.unshift(searchCriteriaOption);
                }
            }
        }
        if (this.props.optionDisabledCallback) {
            options = options.map((option) => ({
                ...option,
                disabled: this.props.optionDisabledCallback(option)
            }));
        }
        return this.splitOptionsIntoSections(options);
    });

    getItemDomNode(index) {
        const items = this.scrollArea.contentWrapperRef.getElementsByClassName('sten-select__option');
        return items[index];
    }

    handleItemHover = (e, item) => {
        const options = this.getOptions()[this.props.sectionOptionsKey];
        if (!item.disabled) {
            this.setState({ highlightedIndex: options.indexOf(item), highlightedItem: item });
        }
    };

    handleKeyDown = e => {
        if (this.state.inputFocused) {
            switch (e.keyCode) {
            case keyCodes.enter:
                this.handleEnter(e);
                break;
            case keyCodes.escape:
                e.stopPropagation();
                this.toggleSelectMenu(false);
                break;
            case keyCodes.upArrow:
                this.handleArrowUp();
                break;
            case keyCodes.downArrow:
                this.handleArrowDown();
                break;
            default:
            }
        }
    };

    handleEnter = e => {
        const options = this.getOptions()[this.props.sectionOptionsKey];
        if (this.state.isMenuShown) {
            if (this.state.highlightedIndex !== -1) {
                this.selectOption(options[this.state.highlightedIndex], e);
            } else if (options[0]) {
                this.selectOption(options[0], e);
            }
        } else {
            e.preventDefault();
            this.toggleSelectMenu(true);
        }
    };

    getNewHighlightedItemIndex = (change) => {
        const options = this.getOptions()[this.props.sectionOptionsKey];
        let highlightedIndex = this.state.highlightedIndex;
        let tempIndex = this.state.highlightedIndex;
        let indexInvalid = true;
        let count = 0;
        while (indexInvalid) {
            if (change < 0) {
                tempIndex = Math.max(tempIndex + change, 0);
                indexInvalid = tempIndex !== 0;
            } else {
                tempIndex = Math.min(tempIndex + change, options.length - 1);
                indexInvalid = tempIndex !== options.length - 1;
            }
            if (!options[tempIndex] || !options[tempIndex].disabled) {
                highlightedIndex = tempIndex;
                indexInvalid = false;
            }
            count++;
            if (count > 500) {
                indexInvalid = false;
            }
        }
        return highlightedIndex;
    };

    handleArrowUp = () => {
        const highlightedIndex = this.getNewHighlightedItemIndex(-1);
        if (highlightedIndex === this.state.highlightedIndex) {
            return;
        }
        if (this.scrollArea) {
            const highlightedItem = this.getItemDomNode(highlightedIndex);
            if (highlightedItem) {
                const itemUpperBorderPosition = highlightedItem.offsetTop;

                if (itemUpperBorderPosition < 0) {
                    this.scrollArea.scrollYTo(this.scrollArea.state.topPosition + itemUpperBorderPosition);
                }
            }
        }
        const options = this.getOptions()[this.props.sectionOptionsKey];
        this.toggleSelectMenu(true);
        this.setState({ highlightedIndex, highlightedItem: options[highlightedIndex] });
    };

    handleArrowDown = () => {
        const highlightedIndex = this.getNewHighlightedItemIndex(1);
        if (highlightedIndex === this.state.highlightedIndex) {
            return;
        }
        if (this.scrollArea) {
            const highlightedItem = this.getItemDomNode(highlightedIndex);
            if (highlightedItem) {
                const itemRect = highlightedItem.getBoundingClientRect();
                const menuHeight = this.scrollArea.sizes.containerHeight;
                const lowerItemBorderPosition = itemRect.height + highlightedItem.offsetTop;

                if (lowerItemBorderPosition > menuHeight) {
                    const delta = lowerItemBorderPosition - menuHeight;
                    this.scrollArea.scrollYTo(this.scrollArea.state.topPosition + delta);
                }
            }
        }
        const options = this.getOptions()[this.props.sectionOptionsKey];
        this.toggleSelectMenu(true);
        this.setState({ highlightedIndex, highlightedItem: options[highlightedIndex] });
    };

    documentOnClick = e => {
        if (!this.isKeyboardClick(e.target) && this.selectOptionsRef && !this.selectOptionsRef.contains(e.target)) {
            this.toggleSelectMenu(false);
        }
    };

    selectOption = (item, event) => {
        if (event) {
            event.stopPropagation();
            event.nativeEvent.stopImmediatePropagation();
            event.preventDefault();
        }
        if (item.disabled) {
            return;
        }

        let newValue;
        if (this.props.multiple) {
            newValue = this.props.value.slice();
            const index = newValue.findIndex(option => option[this.props.valueKey] === item[this.props.valueKey]);
            if (index > -1) {
                newValue.splice(index, 1);
                if (this.props.onItemDeselect) {
                    this.props.onItemDeselect(item);
                }
            } else {
                newValue.push(item);
                if (this.props.onItemSelect) {
                    this.props.onItemSelect(item);
                }
            }
            this.onOuterDivFocus();
        } else {
            newValue = item;
            this.toggleSelectMenu(false);
            this.setState({ searchCriteria: this.props.setSearchCriteriaOnFocus ? newValue[this.props.labelKey] : '' });
        }
        if (this.props.onChange) {
            this.props.onChange(newValue);
        }
    };

    handleScrollChange = () => {
        if (this.selectRect && this.select) {
            const newSelectRect = this.select.getBoundingClientRect();
            if (this.selectRect
                && (this.selectRect.top !== newSelectRect.top || this.selectRect.left !== newSelectRect.left)) {
                this.toggleSelectMenu(false);
            }
        }
    };

    saveSelectRect = () => {
        if (this.select) {
            this.selectRect = this.select.getBoundingClientRect();
        }
    };

    toggleSelectMenu = flag => {
        if (!this.props.disabled && !this.props.readOnly) {
            const flagIsBool = typeof (flag) === 'boolean';
            if ((flagIsBool && flag !== this.state.isMenuShown) || !flagIsBool) {
                if (!this.state.isMenuShown) {
                    document.addEventListener('click', this.documentOnClick);
                    document.addEventListener('scrollChanged', this.handleScrollChange);
                    this.saveSelectRect();
                    if (this.select) {
                        const boundingBox = this.select.getBoundingClientRect();
                        let collapseDown = this.props.collapseDown;
                        if (typeof this.props.collapseDown !== 'boolean') {
                            collapseDown = boundingBox.top > window.innerHeight / 2;
                            if (collapseDown !== this.state.collapseDown) {
                                this.setState({ collapseDown });
                            }
                        }
                        const selectRect = this.select.getBoundingClientRect();
                        const selectWidth = selectRect.width - 2;
                        this.selectOptionsStyle = {
                            position: 'fixed',
                            zIndex: 99,
                            minWidth: selectWidth,
                            maxWidth: this.props.fixedListWidth ? selectWidth : 'calc(100% + 2px)',
                            top: collapseDown ? selectRect.top + 1 : selectRect.top + selectRect.height - 1,
                            left: selectRect.left
                        };
                    }
                } else {
                    document.removeEventListener('click', this.documentOnClick);
                    document.removeEventListener('scrollChanged', this.handleScrollChange);
                }
                if (this.state.isMenuShown) {
                    this.setState({
                        highlightedIndex: -1,
                        highlightedItem: null,
                        isMenuShown: false
                    });
                } else {
                    const options = this.getOptions()[this.props.sectionOptionsKey];
                    this.setState({ isMenuShown: true, highlightedIndex: 0, highlightedItem: options[0] });
                }
            }
        }
    };

    clearSelection = e => {
        e.stopPropagation();
        if (this.props.onChange) {
            if (this.props.multiple) {
                this.props.onChange([]);
            } else {
                this.props.onChange(null);
            }
        }
    };

    renderOption = (option) => (
        <SelectOption
            searchCriteria={this.state.searchCriteria}
            className={this.props.optionClassName}
            multiple={this.props.multiple}
            value={this.props.value}
            labelKey={this.props.labelKey}
            valueKey={this.props.valueKey}
            optionRenderer={this.props.optionRenderer}
            onClick={this.selectOption}
            option={option}
            isHighlighted={this.state.highlightedItem === option}
            onMouseOver={this.handleItemHover}
            key={option[this.props.valueKey]}
        />
    );

    renderSection = (section, index) => (
        <React.Fragment key={index}>
            {section[this.props.sectionLabelKey] && section[this.props.sectionOptionsKey].length > 0 && (
                <div className="sten-select__section-header">{section[this.props.sectionLabelKey]}</div>
            )}
            {section[this.props.sectionOptionsKey].map(this.renderOption)}
        </React.Fragment>
    );

    saveRef = (c) => { this.select = c; };

    saveInputRef = (c) => { this.selectInput = c; };

    saveScrollRef = (c) => { this.scrollArea = c; };

    saveOptionsRef = (c) => { this.selectOptionsRef = c; };

    render() {
        const { disabled, readOnly } = this.props;
        const filteredOptions = this.getOptions();
        const collapseDown = typeof this.props.collapseDown === 'boolean'
            ? this.props.collapseDown
            : this.state.collapseDown;

        const hasValue = this.props.value
            && ((this.props.multiple && this.props.value.length > 0) || !this.props.multiple);

        const selectClass = getClassName('sten-select', this.props.className, {
            'sten-select--searchable': this.props.searchable,
            'sten-select--disabled': disabled,
            'sten-select--read-only': readOnly,
            'sten-select--warning': this.props.invalid && this.props.warning,
            'sten-select--invalid': this.props.invalid && !this.props.warning,
            'sten-select--clearable': hasValue && this.props.clearable,
            'sten-select--focused': this.state.inputFocused,
            'sten-select--active': this.state.isMenuShown && filteredOptions.options.length > 0
        });

        const menuClass = getClassName('sten-select__menu', {
            'sten-select__menu--fixed-width': this.props.fixedListWidth,
            'sten-menu__items--collapse-down': collapseDown,
            'sten-menu__items--right-aligned': this.props.isRightAligned,
            'sten-select__menu--open': this.state.isMenuShown && filteredOptions.options.length > 0
        });

        let chevronClassConfig = null;
        if (!this.props.onSearch) {
            if (this.state.isMenuShown && filteredOptions.options.length > 0) {
                chevronClassConfig = { 'icon-chevron-up': !collapseDown, 'icon-chevron-down': collapseDown };
            } else {
                chevronClassConfig = { 'icon-chevron-up': collapseDown, 'icon-chevron-down': !collapseDown };
            }
        }
        const sideIconClass = getClassName(
            'icon sten-select__icon',
            { 'icon-search': this.props.onSearch },
            chevronClassConfig
        );
        const inputType = this.props.searchable ? 'text' : 'button';
        return (
            <div
                ref={this.saveRef}
                className={selectClass}
                onKeyDown={this.handleKeyDown}
                onClick={this.onSelectClick}
                onBlur={this.onOuterDivBlur}
                title={this.props.title}
            >
                <span className={sideIconClass} />
                <div className="sten-select__input">
                    <Input
                        autoFocus={this.props.autoFocus}
                        autoFocusDelay={this.props.autoFocusDelay}
                        ref={this.saveInputRef}
                        disabled={disabled}
                        readOnly={readOnly}
                        type={inputType}
                        name={this.props.name}
                        onFocusChange={this.handleInputFocusChange}
                        value={this.state.searchCriteria}
                        onChange={this.onInputChange}
                        showKeyboard={this.props.showKeyboard}
                    />
                </div>
                {hasValue && this.props.clearable && (
                    <span
                        className="sten-select__icon sten-select__icon--clear icon icon-close"
                        onClick={this.clearSelection}
                    />
                )}
                {(!this.state.inputFocused || (!this.state.searchCriteria && !this.props.setSearchCriteriaOnFocus)) && (
                    <SelectValue
                        valueRenderer={this.props.valueRenderer}
                        multiple={this.props.multiple}
                        placeholder={disabled
                            ? (this.props.disabledPlaceholder || this.props.placeholder)
                            : this.props.placeholder
                        }
                        optionRenderer={this.props.optionRenderer}
                        value={this.props.value}
                        labelKey={this.props.labelKey}
                        label={this.props.label}
                    />
                )}
                {this.state.isMenuShown && filteredOptions.options.length > 0 && !disabled && !readOnly && (
                    <Portal>
                        <div style={this.selectOptionsStyle} ref={this.saveOptionsRef}>
                            <ScrollArea
                                ref={this.saveScrollRef}
                                className={menuClass}
                                onClick={this.onMenuClick}
                                stopScrollPropagation
                                useCSS3Translate={false}
                            >
                                {filteredOptions.sections
                                    ? filteredOptions.sections.map(this.renderSection)
                                    : filteredOptions.options.map(this.renderOption)}
                            </ScrollArea>
                        </div>
                    </Portal>
                )}
            </div>
        );
    }
}

Select.propTypes = {
    autoFocus: PropTypes.bool,
    autoFocusDelay: PropTypes.number,
    chevronIconClass: PropTypes.string,
    className: PropTypes.string,
    clearable: PropTypes.bool,
    collapseDown: PropTypes.bool,
    disabled: PropTypes.bool,
    disabledPlaceholder: PropTypes.string,
    fixedListWidth: PropTypes.bool,
    includeSearchInResults: PropTypes.bool,
    invalid: PropTypes.bool,
    isRightAligned: PropTypes.bool,
    label: PropTypes.string,
    labelKey: PropTypes.string,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onItemDeselect: PropTypes.func,
    onItemSelect: PropTypes.func,
    onSearch: PropTypes.func,
    optionClassName: PropTypes.string,
    optionDisabledCallback: PropTypes.func,
    optionRenderer: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.object),
    placeholder: PropTypes.string,
    preSelectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    readOnly: PropTypes.bool,
    searchable: PropTypes.bool,
    sectionLabelKey: PropTypes.string,
    sectionOptionsKey: PropTypes.string,
    setSearchCriteriaOnFocus: PropTypes.bool,
    showKeyboard: PropTypes.bool,
    splitOptionsIntoSections: PropTypes.func,
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
    valueKey: PropTypes.string,
    valueRenderer: PropTypes.func,
    warning: PropTypes.bool
};

Select.defaultProps = {
    autoFocus: false,
    autoFocusDelay: undefined,
    chevronIconClass: '',
    className: '',
    clearable: false,
    collapseDown: null,
    disabled: false,
    disabledPlaceholder: null,
    fixedListWidth: false,
    includeSearchInResults: false,
    invalid: false,
    isRightAligned: false,
    label: '',
    labelKey: '',
    multiple: false,
    name: '',
    onChange: undefined,
    onItemDeselect: undefined,
    onItemSelect: undefined,
    onSearch: undefined,
    optionClassName: '',
    optionDisabledCallback: undefined,
    optionRenderer: undefined,
    options: [],
    placeholder: '',
    preSelectedValue: null,
    readOnly: false,
    searchable: false,
    sectionLabelKey: 'name',
    sectionOptionsKey: 'options',
    setSearchCriteriaOnFocus: false,
    showKeyboard: false,
    splitOptionsIntoSections: undefined,
    title: '',
    value: null,
    valueKey: '',
    valueRenderer: undefined,
    warning: false
};

export default Select;
