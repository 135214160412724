import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* components */
import DateTimePicker from 'components/date-time-picker/date-time-picker';
/* actions */
import { updateDate, addHours, resetDate, setMounted } from './map-date-picker-actions';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
/* localization */
import { translate } from 'utils/i18n/i18n-model';
/* styles */
import './map-date-picker.scss';

export class MapDatePicker extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isDatePickerShown: false,
            updateIntervalLength: 5 * 60000,
            now: moment().startOf('hour')
        };
    }

    componentDidMount() {
        this.init();
        this.props.setMounted(true);
        this.mapUpdateInterval = setInterval(this.init, this.state.updateIntervalLength);
    }

    componentWillUnmount() {
        this.props.setMounted(false);
        clearInterval(this.mapUpdateInterval);
    }

    onDateSelect = newDate => {
        this.updateDate(newDate);
        this.toggleDateTimePicker(false);
    };

    onCancel = () => {
        this.toggleDateTimePicker(false);
    };

    onDatePickerClick(e) {
        /* eslint-disable */
        e.nativeEvent.datePickerClick = true;
        /* eslint-enable */
    }

    updateDate = date => {
        this.props.router.updateQueryParams({ remove: ['voyage'] });
        this.props.updateDate(date);
    };

    toggleDateTimePicker = isShown => {
        const isDatePickerShown = typeof (isShown) === 'boolean' ? isShown : !this.state.isDatePickerShown;
        if (this.state.isDatePickerShown !== isDatePickerShown) {
            if (isDatePickerShown) {
                document.addEventListener('click', this.documentOnClick);
            } else {
                document.removeEventListener('click', this.documentOnClick);
            }
            this.setState({
                isDatePickerShown
            });
        }
    };

    init = () => {
        const now = moment().startOf('hour');
        if (!this.props.selectedDate || this.props.isNowSelected) {
            this.props.updateDate(now, true);
        }
        if (this.state.now.isBefore(now)) {
            this.setState({ now });
        }
    };

    documentOnClick = e => {
        if (!e.datePickerClick) {
            this.toggleDateTimePicker(false);
        }
    };

    renderDatePicker = () => {
        const dateTimePickerClass = 'sten-map-date-picker__date-time-picker';
        const { now } = this.state;

        return (
            <DateTimePicker
                onClick={this.onDatePickerClick}
                timestamp={moment(this.props.selectedDate).valueOf()}
                onSelect={this.onDateSelect}
                onCancel={this.onCancel}
                className={dateTimePickerClass}
                minDate={this.props.startDate.valueOf()}
                maxDate={now.valueOf()}
            />
        );
    };

    render() {
        const { now } = this.state;
        const selectedDateMoment = moment(this.props.selectedDate);
        const prevBtnDisabled = selectedDateMoment.isSame(this.props.startDate);
        const nextBtnDisabled = selectedDateMoment.isSame(now);
        let classNames = 'sten-map-date-picker';
        if (this.props.isDisabled) {
            classNames += ' sten-map-date-picker--disabled';
        }
        if (this.props.fullWidth) {
            classNames += ' sten-map-date-picker--full-width';
        }
        let bodyClassNames = 'sten-map-date-picker__body flex-grow flex flex-center';
        if (this.state.isDatePickerShown) {
            bodyClassNames += ' sten-map-date-picker__body--active';
        }

        return (
            <div className={classNames}>
                <div className="sten-map-date-picker__wrapper">
                    <div className="sten-map-date-picker__selector flex flex-center">
                        <button
                            type="button"
                            disabled={prevBtnDisabled}
                            className="sten-map-date-picker__btn"
                            onClick={this.props.addHours.bind(this, -24)}
                        >
                            <span className="icon icon-angle-double-left" />
                        </button>
                        <button
                            type="button"
                            disabled={prevBtnDisabled}
                            className="sten-map-date-picker__btn"
                            onClick={this.props.addHours.bind(this, -1)}
                        >
                            <span className="icon icon-angle-left" />
                        </button>
                        <div className={bodyClassNames}>
                            <div className="flex-grow text-center">
                                <button
                                    type="button"
                                    className="btn-link sten-map-date-picker__date-btn"
                                    onClick={this.toggleDateTimePicker}
                                >
                                    {TimeHelper.getFormatted(this.props.selectedDate, { time: true, day: true })}
                                </button>
                                {selectedDateMoment.isBefore(now) && (
                                    <button
                                        type="button"
                                        className="btn btn--secondary btn--xs sten-map-date-picker__reset-btn"
                                        onClick={this.props.resetDate}
                                    >
                                        {translate('MAP_DATE_PICKER.RESET')}
                                    </button>
                                )}
                            </div>
                            {this.state.isDatePickerShown && this.renderDatePicker()}
                        </div>
                        <button
                            disabled={nextBtnDisabled}
                            className="sten-map-date-picker__btn"
                            onClick={this.props.addHours.bind(this, 1)}
                        >
                            <span className="icon icon-angle-right" />
                        </button>
                        <button
                            disabled={nextBtnDisabled}
                            className="sten-map-date-picker__btn"
                            onClick={this.props.addHours.bind(this, 24)}
                        >
                            <span className="icon icon-angle-double-right" />
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

MapDatePicker.propTypes = {
    addHours: PropTypes.func.isRequired,
    fullWidth: PropTypes.bool.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    isNowSelected: PropTypes.bool.isRequired,
    resetDate: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    selectedDate: PropTypes.string.isRequired,
    setMounted: PropTypes.func.isRequired,
    startDate: PropTypes.objectOf(PropTypes.any).isRequired,
    updateDate: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        isDisabled: state.mapDatePickerReducer.isDisabled,
        isNowSelected: state.mapDatePickerReducer.isNowSelected,
        selectedDate: state.mapDatePickerReducer.selectedDate,
        startDate: state.mapDatePickerReducer.startDate
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addHours: hours => addHours(dispatch, hours),
        resetDate: () => resetDate(dispatch),
        setMounted: isMounted => setMounted(dispatch, isMounted),
        updateDate: (date, forceUpdate) => updateDate(dispatch, date, forceUpdate)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MapDatePicker));
