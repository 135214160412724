import React from 'react';
import PropTypes from 'prop-types';
/* components */
import Checkbox from 'components/checkbox/checkbox';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import './checkable-list-item.scss';

export default class SelectAllItem extends React.PureComponent {
    render = () => {
        return (
            <div
                className="btn-section sten-checkable-list-item spread-v"
                onClick={this.props.onClick}
            >
                <div className="flex-row flex-center spread-v">
                    <div className="flex-shrink">
                        <Checkbox isChecked={this.props.isChecked} />
                    </div>
                    <div className="flex-grow">
                        <h3 className="text-uppercase text-primary">
                            {translate('GLOBAL.SELECT_ALL')}
                        </h3>
                    </div>
                </div>
            </div>
        );
    };
}

SelectAllItem.propTypes = {
    isChecked: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired
};
