import { ActionTypes } from './left-side-bar-actions';

const initialState = {
    isNavMenuExpanded: false,
    activeNavLinkName: ''
};

export default function leftSideBarReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.NAVMENU_EXPAND: {
        let menuExpanded;
        if (typeof action.expand === 'boolean') {
            menuExpanded = action.expand;
        } else {
            menuExpanded = !state.isNavMenuExpanded;
        }
        return {
            ...state,
            isNavMenuExpanded: menuExpanded,
            activeNavLinkName: ''
        };
    }
    case ActionTypes.NAVMENU_SET_ACTIVE_LINK:
        if (!action.name || state.activeNavLinkName === action.name) {
            return {
                ...state,
                activeNavLinkName: '',
                isNavMenuExpanded: false
            };
        }
        return {
            ...state,
            activeNavLinkName: action.name,
            isNavMenuExpanded: false
        };
    default:
        return state;
    }
}
