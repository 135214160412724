import React from 'react';
import PropTypes from 'prop-types';
/* types */
import {
    TEuEts,
    TYearlyRatingWithCorrection,
    TVmsFootprint,
    TFootprint
} from './footprint-table-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { formatValue } from 'utils/helpers/info-helper';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import EmptyContent from 'components/empty-content/empty-content';
import Modal from 'components/modal/modal';
import Select from 'components/select/select';
/* constants */
import { getFootprintRows } from './footprint-table-constants';
/* styles */
import './footprint-table.scss';

class FootprintTable extends React.PureComponent {
    state = {
        isModalShown: false,
        modalData: null
    };

    renderRow = (data, rowFormat, defaultValue = t('GLOBAL.NOT_AVAILABLE')) => {
        const { key, label } = rowFormat;
        const value = rowFormat.renderValue
            ? rowFormat.renderValue(data, defaultValue)
            : formatValue(data, rowFormat, null, defaultValue);
        const text = <span className="text-secondary">{label}</span>;
        const rowModalData = rowFormat.getModalData ? rowFormat.getModalData(data) : null;
        const tooltip = rowFormat.getTooltip ? rowFormat.getTooltip(data) : rowFormat.tooltip;
        return (
            <tr key={key}>
                <td className="text-secondary">
                    {tooltip
                        ? (
                            <div className="flex flex-center">
                                <div className="flex-shrink">{text}</div>
                                <div className="flex-grow">
                                    <span className="icon sten-icon-info-tooltip" title={tooltip} />
                                </div>
                            </div>
                        )
                        : text}
                </td>
                <td>
                    {value}
                    {value === defaultValue && rowFormat.noValueTooltip && (
                        <span className="icon sten-icon-info-tooltip" title={rowFormat.noValueTooltip} />
                    )}
                    {rowModalData && (
                        <button
                            className="btn-link icon sten-icon-info-tooltip"
                            title={rowFormat.modalButtonTooltip || t('FOOTPRINT_TABLE.SHOW_INFO')}
                            onClick={this.toggleModal.bind(this, rowModalData)}
                        />
                    )}
                </td>
            </tr>
        );
    };

    toggleModal = (data) => {
        this.setState({
            isModalShown: !this.state.isModalShown,
            modalData: data || null
        });
    };

    closeModal = () => {
        this.setState({ isModalShown: false, modalData: null });
    };

    renderTableHeader = (title, value = t('FOOTPRINT_TABLE.VALUE')) => (
        <thead>
            <tr>
                <th className="text-secondary">{title}</th>
                <th className="text-secondary">{value}</th>
            </tr>
        </thead>
    );

    render() {
        const {
            euEtsYearsAvailable,
            isRange,
            onEuEtsYearChange,
            showConsumption,
            showVesselCii,
            showVoyageEmissions,
            showVoyageEnergyEfficiency,
            showVmsVoyageEmissions,
            tableSize,
            vesselCii,
            voyageEuEts,
            voyageFootprint,
            voyageVmsFootprint
        } = this.props;
        if ((!voyageFootprint && !vesselCii && !voyageVmsFootprint)
            || (!showConsumption
                && !showVesselCii
                && !showVoyageEmissions
                && !showVoyageEnergyEfficiency
                && !showVmsVoyageEmissions)) {
            return <EmptyContent className="flex-grow" />;
        }
        const footprintRows = getFootprintRows(!isRange);
        return (
            <React.Fragment>
                {ConfigService.featureToggles.showEuEts && (
                    <Modal.Default modalSize="auto" isOpen={this.state.isModalShown} onRequestClose={this.closeModal}>
                        <div className="sten-modal__header flex flex-center text-uppercase">
                            {this.state.modalData?.title && (
                                <div className="sten-modal__title flex-grow">{this.state.modalData?.title}</div>
                            )}
                            <div className="flex-shrink">
                                <button type="button" className="btn-link icon icon-close" onClick={this.closeModal} />
                            </div>
                        </div>
                        <div className="sten-modal__body text-center">
                            {this.state.modalData?.imageSrc && (
                                <img src={this.state.modalData?.imageSrc} alt="modal-img" />
                            )}
                        </div>
                    </Modal.Default>
                )}
                <table className={`sten-table sten-table--${tableSize} sten-footprint-table`}>
                    {showVesselCii && vesselCii && (
                        <React.Fragment>
                            {this.renderTableHeader(t('FOOTPRINT_TABLE.VESSEL_CII'))}
                            <tbody>
                                {footprintRows.ciiYearlyRating().map(rowFormat =>
                                    this.renderRow(this.props, rowFormat))
                                }
                            </tbody>
                        </React.Fragment>
                    )}
                    {(voyageFootprint || voyageVmsFootprint) && (showVoyageEnergyEfficiency
                        || showVoyageEmissions
                        || showConsumption
                        || showVmsVoyageEmissions) && (
                        <React.Fragment>
                            {showVoyageEnergyEfficiency && voyageFootprint && (
                                <React.Fragment>
                                    {this.renderTableHeader(
                                        isRange
                                            ? t('FOOTPRINT_TABLE.DATE_RANGE_ENERGY_EFFICIENCY')
                                            : t('FOOTPRINT_TABLE.VOYAGE_ENERGY_EFFICIENCY')
                                    )}
                                    <tbody>
                                        {footprintRows.energyEfficiency.map(rowConfig =>
                                            this.renderRow(this.props, rowConfig))}
                                    </tbody>
                                </React.Fragment>
                            )}
                            {showVoyageEmissions && voyageFootprint && (
                                <React.Fragment>
                                    {this.renderTableHeader(
                                        isRange
                                            ? t('FOOTPRINT_TABLE.DATE_RANGE_EMISSIONS')
                                            : t('FOOTPRINT_TABLE.VOYAGE_EMISSIONS')
                                    )}
                                    <tbody>
                                        {ConfigService.featureToggles.showEuEts && (
                                            <tr>
                                                <td className="text-secondary">{t('FOOTPRINT_TABLE.EU_ETS_YEAR')}</td>
                                                {onEuEtsYearChange && euEtsYearsAvailable ? (
                                                    <td className="sten-table__cell--has-input">
                                                        <Select
                                                            className="sten-select--sm"
                                                            value={voyageEuEts?.year || null}
                                                            onChange={onEuEtsYearChange}
                                                            options={euEtsYearsAvailable}
                                                            valueKey="Name"
                                                            labelKey="Name"
                                                            name="year"
                                                            placeholder={t('FOOTPRINT_TABLE.EU_ETS_YEAR')}
                                                        />
                                                    </td>
                                                ) : (
                                                    <td>{voyageEuEts?.year?.Name || '-'}</td>
                                                )}
                                            </tr>
                                        )}
                                        {footprintRows.emissions.map(rowConfig =>
                                            this.renderRow(this.props, rowConfig, '-'))}
                                    </tbody>
                                </React.Fragment>
                            )}
                            {showVmsVoyageEmissions && voyageVmsFootprint && (
                                <React.Fragment>
                                    <thead>
                                        <tr>
                                            <th className="text-secondary">
                                                {t('FOOTPRINT_TABLE.VOYAGE_EMISSIONS')}
                                                <span className="sten-badge sten-badge--inline sten-badge--success">
                                                    VMS
                                                </span>
                                            </th>
                                            <th className="text-secondary">{t('FOOTPRINT_TABLE.VALUE')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {footprintRows.vmsEmissions.map(rowConfig =>
                                            this.renderRow(this.props, rowConfig))}
                                    </tbody>
                                </React.Fragment>
                            )}
                            {showConsumption && voyageFootprint?.Consumptions?.length > 0 && (
                                <React.Fragment>
                                    {this.renderTableHeader(
                                        t('FOOTPRINT_TABLE.FUEL'),
                                        t('FOOTPRINT_TABLE.CONSUMPTION')
                                    )}
                                    <tbody>
                                        {voyageFootprint?.Consumptions.map((fuelType, index) => this.renderRow(
                                            this.props,
                                            {
                                                ...footprintRows.consumption,
                                                label: fuelType.Name,
                                                key: `voyageFootprint.Consumptions.${index}.Quantity`
                                            },
                                            '-'
                                        ))}
                                    </tbody>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                </table>
            </React.Fragment>
        );
    }
}

FootprintTable.propTypes = {
    euEtsPrice: PropTypes.objectOf(PropTypes.any),
    euEtsYearsAvailable: PropTypes.arrayOf(PropTypes.object),
    isRange: PropTypes.bool,
    onEuEtsYearChange: PropTypes.func,
    showConsumption: PropTypes.bool,
    showVesselCii: PropTypes.bool,
    showVmsVoyageEmissions: PropTypes.bool,
    showVoyageEmissions: PropTypes.bool,
    showVoyageEnergyEfficiency: PropTypes.bool,
    tableSize: PropTypes.string,
    vesselCii: TYearlyRatingWithCorrection,
    voyageVmsFootprint: TVmsFootprint,
    voyageEuEts: TEuEts,
    voyageFootprint: TFootprint
};

FootprintTable.defaultProps = {
    euEtsPrice: null,
    euEtsYearsAvailable: null,
    isRange: false,
    onEuEtsYearChange: undefined,
    showConsumption: false,
    showVesselCii: false,
    showVmsVoyageEmissions: false,
    showVoyageEmissions: false,
    showVoyageEnergyEfficiency: false,
    tableSize: 'sm',
    vesselCii: null,
    voyageVmsFootprint: null,
    voyageEuEts: null,
    voyageFootprint: null
};

export default FootprintTable;
