import { ActionTypes } from './segment-actions';

const initialState = {
    basicSegmentData: null,
    advancedSegmentData: null,
    sortProp: 'Vessel.Title',
    sortOrder: 'ASC'
};

export default function energyManagementKPISegmentReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ENERGY_MANAGEMENT_KPI_SEGMENT_SET_BASIC_DATA: {
        return {
            ...state,
            basicSegmentData: action.basicSegmentData
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_KPI_SEGMENT_SET_ADVANCED_DATA: {
        return {
            ...state,
            advancedSegmentData: action.advancedSegmentData
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_KPI_BASIC_SEGMENT_SET_SORT_PROP: {
        if (state.sortProp === action.sortProp) {
            let sortOrder;
            if (state.sortOrder === 'ASC') {
                sortOrder = 'DESC';
            } else {
                sortOrder = 'ASC';
            }
            return {
                ...state,
                sortOrder
            };
        }
        return {
            ...state,
            sortProp: action.sortProp,
            sortOrder: 'ASC'
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_KPI_SEGMENT_RESET_ALL: {
        return initialState;
    }
    default:
        return state;
    }
}
