import React from 'react';
import PropTypes from 'prop-types';
/* components */
import Input from 'components/input/input';
import Validation from 'components/validation/validation';

export class EditableList extends React.PureComponent {
    handleItemChange = (index) => (value) => {
        const list = [...this.props.value];
        list[index] = value;
        this.props.onChange(list);
    };

    handleItemAdd = () => {
        this.props.onChange([...this.props.value, '']);
    };

    handleItemRemove = (index) => () => {
        const list = [...this.props.value];
        list.splice(index, 1);
        this.props.onChange(list);
    };

    getLabelText = (label, hideOrdinals, index) => (
        `${label ? `${label} ` : ''}${hideOrdinals ? '' : index + 1}`
    );

    uniqueValidation = {
        rule: (value, param, components, name) => !this.props.value.some((item, index) => (
            name !== `${this.props.name}[${index}]`
            && value.trim().toLowerCase() === item.trim().toLowerCase()
        )),
        hint: () => 'Should be unique'
    };

    getValidations = () => {
        if (this.props.validateUnique) {
            return {
                custom: this.uniqueValidation,
                ...this.props.validations
            };
        }
        return this.props.validations;
    }

    render() {
        const {
            addBtnLabel,
            label,
            labelInline,
            hideOrdinals,
            name,
            placeholder,
            readOnlyLength,
            value
        } = this.props;

        const validations = this.getValidations();

        return (
            <React.Fragment>
                {value && value.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="form-row" key={index}>
                        {!labelInline && (label || !hideOrdinals) && (
                            <label className="label">{this.getLabelText(label, hideOrdinals, index)}</label>
                        )}
                        <div className="flex-row flex-center">
                            {labelInline && (label || !hideOrdinals) && (
                                <div className="flex-shrink">
                                    <label className="label label--inline">
                                        {this.getLabelText(label, hideOrdinals, index)}
                                    </label>
                                </div>
                            )}
                            <div className="flex-grow">
                                {validations
                                    ? (
                                        <Validation.Wrapper
                                            validations={validations}
                                            hintsOnHover
                                            validate={index >= readOnlyLength}
                                        >
                                            <Input
                                                name={`${name}[${index}]`}
                                                placeholder={placeholder}
                                                value={item}
                                                onChange={this.handleItemChange(index)}
                                                disabled={index < readOnlyLength}
                                            />
                                        </Validation.Wrapper>
                                    ) : (
                                        <Input
                                            name={`${name}[${index}]`}
                                            placeholder={placeholder}
                                            value={item}
                                            onChange={this.handleItemChange(index)}
                                            disabled={index < readOnlyLength}
                                        />
                                    )
                                }
                            </div>
                            {index >= readOnlyLength && (
                                <div className="flex-shrink">
                                    <button
                                        type="button"
                                        className="btn-link btn--icon icon icon-delete"
                                        onClick={this.handleItemRemove(index)}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                ))}
                <div className="form-row">
                    <button
                        type="button"
                        className="btn-link btn-link--inverted text-uppercase"
                        onClick={this.handleItemAdd}
                    >
                        <span className="btn__icon icon icon-plus" />
                        {addBtnLabel}
                    </button>
                </div>
            </React.Fragment>
        );
    }
}

EditableList.propTypes = {
    addBtnLabel: PropTypes.string,
    hideOrdinals: PropTypes.bool,
    label: PropTypes.string,
    labelInline: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    readOnlyLength: PropTypes.number,
    validateUnique: PropTypes.bool,
    validations: PropTypes.objectOf(PropTypes.any),
    value: PropTypes.arrayOf(PropTypes.string).isRequired
};

EditableList.defaultProps = {
    addBtnLabel: '',
    hideOrdinals: false,
    label: '',
    labelInline: false,
    name: '',
    placeholder: '',
    readOnlyLength: 0,
    validateUnique: true,
    validations: null
};

export default EditableList;
