export const ActionTypes = {
    DISTANCE_CALCULATOR_PAGE_TOGGLE_FULL_SCREEN: 'DISTANCE_CALCULATOR_PAGE_TOGGLE_FULL_SCREEN',
    DISTANCE_CALCULATOR_PAGE_GET_PORTS: 'DISTANCE_CALCULATOR_PAGE_GET_PORTS',
    DISTANCE_CALCULATOR_PAGE_SET_PORTS: 'DISTANCE_CALCULATOR_PAGE_SET_PORTS',
    DISTANCE_CALCULATOR_PAGE_RESET_ALL: 'DISTANCE_CALCULATOR_PAGE_RESET_ALL'
};

export function toggleFullScreen(dispatch, isFullScreen) {
    dispatch({
        type: ActionTypes.DISTANCE_CALCULATOR_PAGE_TOGGLE_FULL_SCREEN,
        isFullScreen
    });
}

export function getPorts(dispatch, params) {
    dispatch({
        type: ActionTypes.DISTANCE_CALCULATOR_PAGE_GET_PORTS,
        params
    });
}

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.DISTANCE_CALCULATOR_PAGE_RESET_ALL
    });
}
