import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* actions */
import { toggleSidebar } from '../reports-actions';
/* components */
import IconButton from 'components/icon-button/icon-button';

class EnergyManagementReportsContentHeader extends React.PureComponent {
    getReportTitle() {
        if (this.props.generatedReportType) {
            return this.props.generatedReportType.Name;
        }
        return translate('ENERGY_MANAGEMENT.REPORTS.TITLE');
    }

    render() {
        return (
            <div className="sten-content__header flex-row">
                <div className="flex-grow">
                    <h1 className="sten-content__title">{this.getReportTitle()}</h1>
                    {this.props.subtitle && (<p className="text-secondary">{this.props.subtitle}</p>)}
                </div>
                <div className="flex-shrink">
                    <IconButton
                        isLink
                        changeIndicator={this.props.isSidebarVisible}
                        icon="icon-filter-outline"
                        isActive={this.props.isSidebarVisible}
                        onClick={this.props.toggleSidebar}
                    />
                </div>
            </div>
        );
    }
}

EnergyManagementReportsContentHeader.propTypes = {
    generatedReportType: PropTypes.objectOf(PropTypes.any),
    isSidebarVisible: PropTypes.bool.isRequired,
    subtitle: PropTypes.string,
    toggleSidebar: PropTypes.func.isRequired
};

EnergyManagementReportsContentHeader.defaultProps = {
    generatedReportType: null,
    subtitle: ''
};

function mapStateToProps(state) {
    return {
        generatedReportType: state.energyManagementReportsReducer.generatedReportType,
        isSidebarVisible: state.energyManagementReportsReducer.isSidebarVisible
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleSidebar: () => toggleSidebar(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EnergyManagementReportsContentHeader);
