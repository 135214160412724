export const ActionTypes = {
    GSM_MAP_LEGEND_SET_TANKERS_LAYER_VISIBILITY: 'GSM_MAP_LEGEND_SET_TANKERS_LAYER_VISIBILITY',
    GSM_MAP_LEGEND_SET_OFFICES_LAYER_VISIBILITY: 'GSM_MAP_LEGEND_SET_OFFICES_LAYER_VISIBILITY',
    GSM_MAP_LEGEND_SET_FERRIES_LAYER_VISIBILITY: 'GSM_MAP_LEGEND_SET_FERRIES_LAYER_VISIBILITY',
    GSM_MAP_LEGEND_SET_FERRY_ROUTES_LAYER_VISIBILITY: 'GSM_MAP_LEGEND_SET_FERRY_ROUTES_LAYER_VISIBILITY',
    GSM_MAP_LEGEND_SET_DRILLS_LAYER_VISIBILITY: 'GSM_MAP_LEGEND_SET_DRILLS_LAYER_VISIBILITY'

};

export function setTankersLayerVisibility(dispatch, visibilityState) {
    dispatch({
        type: ActionTypes.GSM_MAP_LEGEND_SET_TANKERS_LAYER_VISIBILITY,
        visibilityState
    });
}

export function setOfficesLayerVisibility(dispatch, visibilityState) {
    dispatch({
        type: ActionTypes.GSM_MAP_LEGEND_SET_OFFICES_LAYER_VISIBILITY,
        visibilityState
    });
}

export function setFerriesLayerVisibility(dispatch, visibilityState) {
    dispatch({
        type: ActionTypes.GSM_MAP_LEGEND_SET_FERRIES_LAYER_VISIBILITY,
        visibilityState
    });
}

export function setFerryRoutesLayerVisibility(dispatch, visibilityState) {
    dispatch({
        type: ActionTypes.GSM_MAP_LEGEND_SET_FERRY_ROUTES_LAYER_VISIBILITY,
        visibilityState
    });
}

export function setDrillsLayerVisibility(dispatch, visibilityState) {
    dispatch({
        type: ActionTypes.GSM_MAP_LEGEND_SET_DRILLS_LAYER_VISIBILITY,
        visibilityState
    });
}
