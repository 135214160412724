import { ActionTypes } from './gsm-vessel-details-tooltip-actions';

const initialState = {
    position: {
        pixels: [0, 0],
        zoomLevel: 2.4
    }
};

export default function gsmVesselDetailsTooltipReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.GSM_VESSEL_DETAILS_TOOLTIP_SET_POSITION: {
        const position = {
            ...action.payload,
            pixels: [Math.floor(action.payload.pixels[0]), Math.floor(action.payload.pixels[1])]
        };
        return {
            ...state,
            position
        };
    }
    default:
        return state;
    }
}
