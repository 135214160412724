export const closestParentByTagName = (el, tagName) => {
    let element = el;
    const uppercaseTagName = tagName.toUpperCase();
    while (element && element.tagName !== uppercaseTagName) {
        element = element.parentElement;
    }
    return element;
};

export function findTotalTopOffset(child, parent) {
    let currentElement = child;
    let totalOffsetTop = currentElement.offsetTop;
    let styles;

    while (currentElement.parentElement !== parent) {
        currentElement = currentElement.parentElement;
        styles = window.getComputedStyle(currentElement);

        if (styles.position === 'relative') {
            totalOffsetTop += currentElement.offsetTop;
        }
    }

    return totalOffsetTop;
}
