import { toast } from 'react-toastify';
import CoreApi from './core-api';
/* utils */
import { t } from 'utils/i18n/i18n-model';

let weatherErrorToastId = null;

const handleWeatherServiceError = () => {
    if (!toast.isActive(weatherErrorToastId)) {
        weatherErrorToastId = toast(t('ERROR.WEATHER_SERVICE_UNAVAILABLE'), { type: toast.TYPE.ERROR });
    }
};

class WeatherService {
    constructor() {
        this.resourceUrl = 'WeatherTiles';
    }

    getCatalogLayers() {
        return CoreApi.get(`${this.resourceUrl}/catalog/layers`, null, false, { preventErrorHandling: true })
            .then(response => response.data, handleWeatherServiceError);
    }

    getLayerMetadata(layerId) {
        return CoreApi.get(`${this.resourceUrl}/metadata/${layerId}`, null, false, { preventErrorHandling: true })
            .then(response => response.data, handleWeatherServiceError);
    }

    getLayerTimestamps(layerId, params) {
        return CoreApi.get(`${this.resourceUrl}/timestamps/${layerId}`, params, false, { preventErrorHandling: true })
            .then(response => response.data, handleWeatherServiceError);
    }

    getLayerTiles(params) {
        return CoreApi.get(
            `${this.resourceUrl}/tiles/${params}`,
            null,
            true,
            {
                responseType: 'arraybuffer',
                headers: {
                    Accept: 'application/x-protobuf'
                },
                preventErrorHandling: true
            }
        ).then(response => response.data, handleWeatherServiceError);
    }
}

export default new WeatherService();
