import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { formatNumber } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import {
    resetAll,
    getInitialData,
    getFreshData,
    getVessels,
    getLatestPrediction
} from './fleet-dashboard-actions';
import { updateUserSettings } from 'pages/user-pages/user-actions';
/* selectors */
import {
    getEarningsChartData,
    getSelectedFleet,
    getSelectedModel,
    getSelectedVoyageOption
} from './fleet-dashboard-selectors';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import CiiRating from 'components/cii-rating/cii-rating';
import Map from './map/map';
import ScrollArea from 'components/scroll-area/scroll-area';
import InfoCard from './info-card/info-card';
import Select from 'components/select/select';
import Chart from 'components/chart/chart';
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
import EmptyContent from 'components/empty-content/empty-content';
/* styles */
import './fleet-dashboard.scss';
/* config */
import { getChartConfig } from './fleet-dashboard-chart-config';

const dataFetchInterval = 5 * 60 * 1000;

export class FleetDashboardPage extends React.PureComponent {
    componentDidMount() {
        this.props.getInitialData(this.props.selectedVoyageOptionId, this.props.selectedFleetId);
        this.dataFetchInterval = setInterval(() => {
            this.props.getFreshData(this.props.selectedVoyageOptionId, this.props.selectedFleetId);
        }, dataFetchInterval);
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedModel && this.props.selectedModel !== prevProps.selectedModel) {
            this.props.getLatestPrediction(this.props.selectedModel.ModelName);
        }
        if (this.props.selectedVoyageOptionId
            && this.props.selectedVoyageOptionId !== prevProps.selectedVoyageOptionId) {
            this.props.getVessels(this.props.selectedVoyageOptionId, this.props.selectedFleetId);
        }
        if (this.props.selectedFleetId && this.props.selectedFleetId !== prevProps.selectedFleetId) {
            this.props.getFreshData(this.props.selectedVoyageOptionId, this.props.selectedFleetId);
        }
    }

    componentWillUnmount() {
        if (this.dataFetchInterval) {
            clearInterval(this.dataFetchInterval);
        }
        this.props.resetAll();
    }

    handleVoyageOptionChange = (voyageOption) => {
        if (this.props.selectedVoyageOptionId !== voyageOption.Id) {
            this.props.updateUserSettings({
                PoolDashboardOption: voyageOption.Id
            });
        }
    };

    handleFleetChange = (fleet) => {
        if (this.props.selectedFleetId !== fleet.Id) {
            this.props.updateUserSettings({
                PoolDashboardFleetId: fleet.Id
            });
        }
    };

    handleVesselClick = (vesselIMO, voyageId) => () => {
        const query = {};
        if (vesselIMO) {
            query.vessel = vesselIMO.toString();
        }
        if (voyageId) {
            query.voyage = voyageId.toString();
        }
        this.props.router.navigate({ pathname: appRoutes.Map, query });
    };

    getTceTitle = () => {
        const currentMonth = moment().startOf('month').format('MMMM');
        return `${t('FLEET_DASHBOARD.CURRENT_TCE')} ${currentMonth}`;
    };

    renderVesselsPanel = () => (
        <div className="sten-panel">
            <div className="sten-panel__header">
                <h4 className="sten-panel__title flex-grow">
                    {t('FLEET_DASHBOARD.VESSELS')}
                </h4>
                <div className="col-sm-8 col-xs-12">
                    <Select
                        collapseDown={false}
                        isRightAligned
                        placeholder={t('FLEET_DASHBOARD.SELECT_VOYAGE_OPTION')}
                        options={this.props.voyageOptions}
                        value={this.props.selectedVoyageOption}
                        valueKey="Id"
                        labelKey="Description"
                        onChange={this.handleVoyageOptionChange}
                    />
                </div>
            </div>
            {this.props.vessels.length > 0 ? (
                <FixedHeaderTable
                    className="sten-fleet-dashboard-page__table"
                    contentClassName="sten-fleet-dashboard-page__table-content"
                >
                    <table className="sten-table sten-table--sm">
                        <thead>
                            <tr>
                                <th>{t('FLEET_DASHBOARD.TABLE.VESSEL_NAME')}</th>
                                <th>{t('FLEET_DASHBOARD.TABLE.CHARTERER')}</th>
                                <th>{t('FLEET_DASHBOARD.TABLE.LATEST_TCE')}</th>
                                <th className="text-center">
                                    {t('FLEET_DASHBOARD.TABLE.YTD_CII')}
                                    <span
                                        className="icon sten-icon-info-tooltip"
                                        title={t('FLEET_DASHBOARD.TABLE.YTD_CII_TOOLTIP')}
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.props.vessels.map((vessel) => (
                                <tr key={vessel.Imo}>
                                    <td
                                        className="text-uppercase text-success btn-link"
                                        onClick={this.handleVesselClick(vessel.Imo, vessel.VoyageId)}
                                    >
                                        {vessel.Title}
                                    </td>
                                    <td className={vessel.Charterer ? '' : 'text-secondary'}>
                                        {vessel.Charterer || t('FLEET_DASHBOARD.TABLE.IN_BALLAST')}
                                    </td>
                                    <td className={vessel.Tce ? '' : 'text-secondary'}>
                                        {vessel.Tce
                                            ? `$ ${formatNumber(vessel.Tce, 0)}`
                                            : t('FLEET_DASHBOARD.TABLE.IN_BALLAST')}
                                    </td>
                                    <td className="text-center">
                                        <CiiRating
                                            isEstimated
                                            value={
                                                vessel.YtdCiiWithCorrectionFactor
                                                || vessel.YtdCiiWithoutCorrectionFactor
                                            }
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </FixedHeaderTable>
            ) : <EmptyContent className="sten-fleet-dashboard-page__table" />}
        </div>
    );

    render() {
        const { earnings, latestPrediction, chartData } = this.props;
        const showTceIndex = ConfigService.featureToggles.showAllMarketIntelFeatures;
        const predictedWSFooter = [{
            label: t('FLEET_DASHBOARD.UPDATED_ON'),
            value: latestPrediction ? TimeHelper.getFormatted(latestPrediction.BITRDate) : '-'
        }];
        const currentTCEFooter = [
            {
                label: t('FLEET_DASHBOARD.LAST_YEAR_TCE'),
                value: earnings && earnings.Current.LastYearTce !== null
                    ? `$ ${formatNumber(earnings.Current.LastYearTce, 0)}`
                    : '-'
            }
        ];
        const tceIndexAreaClassNames = 'col-lg-12 col-md-14 col-xs-24 flex justify-content-center';
        const tceIndexClassNames = ' col-lg-12 col-md-12 col-xs-12 flex justify-content-center';
        const earningsClassNames = showTceIndex ? 'col-lg-18 col-xs-24' : 'col-xs-24';
        return (
            <div className="sten-fleet-dashboard-page">
                <div className="sten-content">
                    <div className="sten-content__header">
                        <div className="flex-grow">
                            <h1 className="sten-content__title">{t('FLEET_DASHBOARD.TITLE')}</h1>
                            <p className="sten-content__subtitle">
                                {t('FLEET_DASHBOARD.DISCLAIMER')}
                            </p>
                        </div>
                        <div className="col-lg-5 col-sm-8 col-xs-12">
                            <Select
                                collapseDown={false}
                                isRightAligned
                                placeholder={t('FLEET_DASHBOARD.SELECT_FLEET')}
                                options={this.props.fleets}
                                value={this.props.selectedFleet}
                                valueKey="Id"
                                labelKey="Name"
                                onChange={this.handleFleetChange}
                            />
                        </div>
                    </div>
                    <ScrollArea className="sten-content__body">
                        <div className="sten-content__section">
                            <div className="flex-row flex--wrap">
                                <div className={earningsClassNames}>
                                    <div className="sten-panel">
                                        <div className="sten-panel__header">
                                            <h4 className="sten-panel__title">
                                                {t('FLEET_DASHBOARD.EARNINGS_TITLE')}
                                            </h4>
                                        </div>
                                        <div className="sten-panel__body">
                                            <div className="sten-panel__section">
                                                <div className="flex-row flex--wrap">
                                                    <div className={tceIndexAreaClassNames}>
                                                        <div className={tceIndexClassNames}>
                                                            <InfoCard
                                                                title={this.getTceTitle()}
                                                                value={earnings && earnings.Current.CurrentTce !== null
                                                                    ? `$${formatNumber(earnings.Current.CurrentTce, 0)}`
                                                                    : '-'}
                                                                footerContent={currentTCEFooter}
                                                            />
                                                        </div>
                                                        {earnings
                                                            && earnings.Index.IsTceIndexVisible
                                                            && showTceIndex && (
                                                            <div className={tceIndexClassNames}>
                                                                <InfoCard
                                                                    title={t('FLEET_DASHBOARD.TCE_INDEX')}
                                                                    value={earnings && earnings.Index.TceIndex !== null
                                                                        ? `$${formatNumber(earnings.Index.TceIndex, 0)}`
                                                                        : '-'}
                                                                    footerContent={[
                                                                        { label: t('FLEET_DASHBOARD.BASED_ON') },
                                                                        { label: t('FLEET_DASHBOARD.ON_TD20_ROUTE') }
                                                                    ]}
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="col-lg-12 col-md-10 col-xs-24">
                                                        {chartData.categories.length > 0 && (
                                                            <Chart
                                                                className="sten-fleet-dashboard-page__chart"
                                                                series={chartData.series}
                                                                categories={chartData.categories}
                                                                config={getChartConfig()}
                                                                isChart
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {showTceIndex && (
                                    <div className="col-lg-6 col-pad--md col-xs-24">
                                        <div className="sten-panel spread-v--md">
                                            <div className="sten-panel__header">
                                                <div className="flex flex-grow flex-justify-space-between">
                                                    <h4 className="sten-panel__title">
                                                        {t('FLEET_DASHBOARD.REPORTED_WS')}
                                                    </h4>
                                                    <h6 className="sten-panel__subtitle">
                                                        {t('FLEET_DASHBOARD.MARKET_PREDICTION_SUBTITLE')}
                                                    </h6>
                                                </div>
                                            </div>
                                            <div className="sten-panel__body flex-grow">
                                                <div className="sten-panel__section">
                                                    <InfoCard
                                                        value={latestPrediction
                                                            ? formatNumber(latestPrediction.BITR, 2)
                                                            : '-'}
                                                        trendIconValue={latestPrediction
                                                    && latestPrediction.BITRTrend}
                                                        footerContent={predictedWSFooter}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="sten-content__section">
                            <div className="flex-row flex--wrap">
                                <div className="col-lg-12 col-xs-24">
                                    {this.renderVesselsPanel()}
                                </div>
                                <div className="col-lg-12 col-xs-24">
                                    <Map />
                                </div>
                            </div>
                        </div>
                    </ScrollArea>
                </div>
            </div>
        );
    }
}

FleetDashboardPage.propTypes = {
    chartData: PropTypes.objectOf(PropTypes.any).isRequired,
    earnings: PropTypes.objectOf(PropTypes.any),
    fleets: PropTypes.arrayOf(PropTypes.object).isRequired,
    getFreshData: PropTypes.func.isRequired,
    getInitialData: PropTypes.func.isRequired,
    getLatestPrediction: PropTypes.func.isRequired,
    getVessels: PropTypes.func.isRequired,
    latestPrediction: PropTypes.objectOf(PropTypes.any),
    resetAll: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    selectedFleet: PropTypes.objectOf(PropTypes.any),
    selectedFleetId: PropTypes.number.isRequired,
    selectedModel: PropTypes.objectOf(PropTypes.any),
    selectedVoyageOption: PropTypes.objectOf(PropTypes.any),
    selectedVoyageOptionId: PropTypes.number.isRequired,
    settingUpdated: PropTypes.objectOf(PropTypes.any).isRequired,
    updateUserSettings: PropTypes.func.isRequired,
    vesselPositions: PropTypes.arrayOf(PropTypes.object).isRequired,
    vessels: PropTypes.arrayOf(PropTypes.object).isRequired,
    voyageOptions: PropTypes.arrayOf(PropTypes.object).isRequired
};

FleetDashboardPage.defaultProps = {
    earnings: null,
    latestPrediction: null,
    selectedFleet: null,
    selectedModel: null,
    selectedVoyageOption: null
};

function mapStateToProps(state) {
    return {
        chartData: getEarningsChartData(state),
        earnings: state.fleetDashboardReducer.earnings,
        fleets: state.fleetDashboardReducer.fleets,
        latestPrediction: state.fleetDashboardReducer.latestPrediction,
        selectedFleet: getSelectedFleet(state),
        selectedFleetId: state.userReducer.settings && state.userReducer.settings.PoolDashboardFleetId,
        selectedModel: getSelectedModel(state),
        selectedVoyageOption: getSelectedVoyageOption(state),
        selectedVoyageOptionId: state.userReducer.settings && state.userReducer.settings.PoolDashboardOption,
        settingUpdated: state.userReducer.settingUpdated,
        vesselPositions: state.fleetDashboardReducer.vesselPositions,
        vessels: state.fleetDashboardReducer.vessels,
        voyageOptions: state.fleetDashboardReducer.voyageOptions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getFreshData: (voyageOptionId, fleetId) => getFreshData(dispatch, voyageOptionId, fleetId),
        getInitialData: (voyageOptionId, fleetId) => getInitialData(dispatch, voyageOptionId, fleetId),
        getLatestPrediction: (model) => getLatestPrediction(dispatch, model),
        getVessels: (voyageOptionId, fleetId) => getVessels(dispatch, voyageOptionId, fleetId),
        resetAll: () => resetAll(dispatch),
        updateUserSettings: settings => updateUserSettings(dispatch, settings)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FleetDashboardPage));
