import { takeEvery, all, put, select } from 'redux-saga/effects';
import moment from 'moment-timezone';
/* helpers */
import { mapArrayByProp } from 'utils/helpers/array-helper';
import { wktMaker } from 'utils/helpers/info-helper';
import RouteHelper from 'utils/helpers/route-helper';
import { getEuEts } from 'components/euets/euets-saga';
/* actions */
import { ActionTypes } from './home-actions';
import { ActionTypes as MapDatePickerActionTypes } from './map-date-picker/map-date-picker-actions';
import { ActionTypes as ReportActionTypes } from 'components/vessel-report/vessel-report-actions';
import { ActionTypes as QuestionnaireActionTypes } from 'components/questionnaire/questionnaire-actions';
/* selectors */
import { getSelectedPredefinedRange } from './right-side-bar/vessel-info/vessel-info-selectors';
/* services */
import CargoService from 'services/core-api/cargo-service';
import ConfigService from 'services/config-api/config-service';
import LocalStorageService from 'services/local-storage-service';
import NotificationService from 'services/notification-api/notification-service';
import PortService from 'services/core-api/port-service';
import QuestionnaireService from 'services/core-api/questionnaire-service';
import RoutingService from 'services/core-api/route-service';
import VesselService from 'services/core-api/vessel-service';
import VoyageService from 'services/core-api/voyage-service';
/* mocks */
import { getMockedVoyageFootprint } from 'components/footprint-table/footprint-table-test-utils';

function* getVessels() {
    const selectedVesselId = yield select(state => state.homeReducer.selectedVesselId);
    const { selectedDate, isNowSelected } = yield select(state => state.mapDatePickerReducer);
    if (selectedDate) {
        const startTime = selectedDate;
        const endTime = moment(selectedDate).add(1, 'h').toISOString();
        yield put({ type: MapDatePickerActionTypes.MAP_DATE_PICKER_ENABLE, isEnabled: false });
        let allVessels;

        if (selectedVesselId) {
            const result = yield all([
                VesselService.getHistorical({
                    StartTime: startTime,
                    EndTime: endTime,
                    Latest: ConfigService.featureToggles.aisAlwaysSendLatest ? true : isNowSelected
                }),
                put({
                    type: ActionTypes.HOME_GET_SELECTED_VESSEL_POSITION_AND_VOYAGE,
                    vesselId: selectedVesselId
                })
            ]);
            allVessels = result[0];
        } else {
            allVessels = yield VesselService.getHistorical({
                StartTime: startTime,
                EndTime: endTime,
                Latest: ConfigService.featureToggles.aisAlwaysSendLatest ? true : isNowSelected
            });
        }
        let vessels;
        if (allVessels && allVessels.Entries) {
            vessels = allVessels.Entries.map(vessel => {
                const firstEntry = vessel.Entries[0];

                return {
                    ...vessel,
                    // IMO: vessel.IMO,
                    Title: vessel.VesselName ? vessel.VesselName.trim() : '',

                    CurrentAis: {
                        Longitude: firstEntry.Location.Longitude,
                        Latitude: firstEntry.Location.Latitude,
                        Heading: firstEntry.Heading
                    },

                    Draught: firstEntry.Draught,
                    SpeedOverGround: firstEntry.SpeedOverGround,
                    Destination: firstEntry.Destination,
                    PositionTime: firstEntry.PositionTime,
                    ETA: firstEntry.ETA,
                    IsLaden: firstEntry.IsLaden
                };
            });
        } else {
            vessels = [];
        }
        yield put({
            type: ActionTypes.HOME_SET_VESSELS,
            vessels,
            vesselsOnMapCount: allVessels && allVessels.TotalEntries ? allVessels.TotalEntries : 0
        });
        yield put({ type: MapDatePickerActionTypes.MAP_DATE_PICKER_ENABLE, isEnabled: true });
    }
}

function* getPorts(action) {
    const ports = yield PortService.get(action.params);
    if (ports) {
        yield put({ type: ActionTypes.HOME_SET_PORTS, ports });
    }
}

function* getVesselPosition(action) {
    let { selectedDate, isNowSelected } = yield select(state => state.mapDatePickerReducer);
    if (action.selectedDate) {
        selectedDate = action.selectedDate;
        isNowSelected = moment.utc(action.selectedDate).isSameOrAfter(moment.utc().startOf('h'));
    }
    const startTime = selectedDate || moment.utc().startOf('h').toISOString();
    const endTime = moment.utc(startTime).add(1, 'h').toISOString();
    let Latest = selectedDate ? isNowSelected : true;
    if (ConfigService.featureToggles.aisAlwaysSendLatest) {
        Latest = true;
    }
    let vesselPosition = yield VesselService.getHistoricalById(action.vesselId, {
        startTime,
        endTime,
        Latest
    });
    if (vesselPosition && vesselPosition.Entries && vesselPosition.Entries.length > 0) {
        const entry = vesselPosition.Entries[0].Entries[0];
        vesselPosition = {
            ...vesselPosition.Entries[0],
            // IMO: vesselPosition.Entries[0].IMO,
            Title: vesselPosition.Entries[0].VesselName.trim(),
            CurrentAis: {
                Longitude: entry.Location.Longitude,
                Latitude: entry.Location.Latitude,
                Heading: entry.Heading
            },
            NextPoint: entry.NextPoint,
            Draught: entry.Draught,
            SpeedOverGround: entry.SpeedOverGround,
            Destination: entry.Destination,
            PositionTime: entry.PositionTime,
            LocationTime: entry.LocationTime,
            LocationOffsetMinutes: entry.LocationOffsetMinutes,
            ETA: entry.ETA,
            IsLaden: entry.IsLaden,
            RequestTime: entry.RequestTime
        };
    } else {
        vesselPosition = null;
    }
    if (!action.selectedDate) {
        yield put({ type: ActionTypes.HOME_SET_SELECTED_VESSEL_POSITION, vesselPosition });
    }
    return vesselPosition;
}

function* getVesselRelativeDetails(action) {
    const At = action.selectedDate || moment.utc().startOf('h').toISOString();
    const params = {
        At,
        IsLatest: ConfigService.featureToggles.aisAlwaysSendLatest ? true : action.isLatest
    };
    if (action && action.voyageId) {
        params.VoyageId = action.voyageId;
    } else {
        const selectedVoyage = yield select(state => state.mapDatePickerReducer.selectedVoyage);
        if (selectedVoyage) {
            params.VoyageId = selectedVoyage.VoyageId;
        }
    }
    const relativeDetails = yield VesselService.getRelativeDetailsById(action.vesselId, params);
    yield put({ type: ActionTypes.HOME_SET_SELECTED_VESSEL_RELATIVE_DETAILS, relativeDetails });
    return relativeDetails;
}

function* getVoyageItinerary(voyage) {
    const permissions = yield select(state => state.userReducer.permissions);
    let response = null;
    let voyageItinerary = null;
    if (permissions && permissions.GetVoyageItinerary) {
        if (voyage && voyage.VoyageId) {
            response = yield VoyageService.getVoyageItinerary({
                VoyageId: voyage.VoyageId
            });
        } else {
            response = yield VoyageService.getVoyageItinerary({
                IMO: voyage.IMO,
                Start: voyage.StartDate,
                End: voyage.EndDate
            });
        }
    }
    if (response) {
        const now = moment.utc().startOf('h');
        const isHistoricalVoyage = moment(voyage.EndDate).isSameOrBefore(now);

        voyageItinerary = response.map((point) => {
            let utcEta;
            let utcEtd;
            if (point.Point && point.Point.TimeZoneId) {
                utcEta = point.Eta ? moment.tz(point.Eta, point.Point.TimeZoneId).utc() : null;
                utcEtd = point.Etd ? moment.tz(point.Etd, point.Point.TimeZoneId).utc() : null;
            } else {
                utcEta = point.Eta ? moment.utc(point.Eta) : null;
                utcEtd = point.Etd ? moment.utc(point.Etd) : null;
            }
            let Activities = point.Activities;
            // TODO check if this logic is still needed
            if (!Activities || Activities.length === 0) {
                Activities = [{
                    Activity: 'Unknown',
                    Code: 'U',
                    Eta: point.Eta,
                    Etd: point.Etd,
                    Probability: 0,
                    Sequence: 0
                }];
            }
            const itineraryPoint = {
                ...point,
                Activities,
                Ata: utcEta && utcEta.isBefore(now) ? point.Eta : null,
                Atd: utcEtd && utcEtd.isBefore(now) ? point.Etd : null
            };
            itineraryPoint.IsHistorical = itineraryPoint.Atd || isHistoricalVoyage;
            return itineraryPoint;
        });
    }
    yield put({ type: ActionTypes.HOME_SET_VOYAGE_ITINERARY, voyageItinerary });
    return voyageItinerary || [];
}

function* getSelectedVesselSensorData(latestReport) {
    const selectedVesselSensorData = yield VesselService.getDailySensorData(
        { Imo: latestReport.IMO, ToDate: latestReport.Date }
    );

    yield put({ type: ActionTypes.HOME_SET_SELECTED_VESSEL_SENSOR_DATA, selectedVesselSensorData });
}

function* getReportsByVoyageId(voyageId) {
    let latestVoyageReport = null;
    let selectedVoyageReports = null;
    const permissions = yield select(state => state.userReducer.permissions);
    if (permissions && permissions.GetReports && voyageId) {
        selectedVoyageReports = yield VoyageService.getReportsByVoyageId(voyageId);
        if (selectedVoyageReports) {
            latestVoyageReport = selectedVoyageReports.find(report => report.IsLatest);
        }
    }

    if (latestVoyageReport && ConfigService.featureToggles.showSensorData) {
        yield getSelectedVesselSensorData(latestVoyageReport);
    }

    yield put({ type: ActionTypes.HOME_SET_SELECTED_VOYAGE_REPORTS, selectedVoyageReports });
    yield put({ type: ActionTypes.HOME_SET_LATEST_VOYAGE_REPORT, latestVoyageReport });
    return latestVoyageReport;
}

function* getVoyageRoutePoints(voyage) {
    const requests = {
        itinerary: getVoyageItinerary(voyage)
    };
    const isCurrentOrFutureVoyage = moment.utc(voyage.EndDate).isSameOrAfter(moment.utc().startOf('h'));
    requests.relativeDetails = getVesselRelativeDetails({
        selectedDate: voyage.EndDate,
        vesselId: voyage.IMO,
        voyageId: voyage.VoyageId,
        isLatest: ConfigService.featureToggles.aisAlwaysSendLatest ? true : isCurrentOrFutureVoyage
    });
    const { itinerary, relativeDetails } = yield all(requests);
    const RoutingPoints = [];
    if (moment.utc(voyage.StartDate).isSameOrBefore(moment.utc().startOf('h'))
        && relativeDetails && relativeDetails.Ais && relativeDetails.Ais.Location) {
        RoutingPoints.push({
            Location: wktMaker('point', [
                relativeDetails.Ais.Location.Longitude,
                relativeDetails.Ais.Location.Latitude
            ]),
            IsCustomPoint: true
        });
    }
    let noFuturePoints = true;
    let isInLastPoint = false;
    if (itinerary && itinerary.length > 0) {
        itinerary.forEach((point, index) => {
            if (!point.Ata && point.Eta && point.Point && point.Point.Location) {
                noFuturePoints = false;
                RoutingPoints.push({ Location: point.Point.Location, IsCustomPoint: false });
            }
            if (index === itinerary.length - 1 && point.Ata && !point.Atd) {
                isInLastPoint = true;
            }
        });
    }
    let nextPoint = null;
    if (noFuturePoints && !isInLastPoint && isCurrentOrFutureVoyage && relativeDetails) {
        if (relativeDetails.Report && relativeDetails.Report.NextPort) {
            if (relativeDetails.Report.NextPoint && relativeDetails.Report.NextPoint.Location) {
                nextPoint = relativeDetails.Report.NextPoint;
                RoutingPoints.push({ Location: relativeDetails.Report.NextPoint.Location, IsCustomPoint: false });
            }
        } else if (relativeDetails.Ais && relativeDetails.Ais.NextPoint && relativeDetails.Ais.NextPoint.Location) {
            nextPoint = relativeDetails.Ais.NextPoint;
            RoutingPoints.push({ Location: relativeDetails.Ais.NextPoint.Location, IsCustomPoint: false });
        }
    }
    yield put({ type: ActionTypes.HOME_SET_SELECTED_VESSEL_NEXT_POINT, point: nextPoint });
    let voyageRoutePoints = null;
    if (RoutingPoints.length > 1) {
        const routeResponse = yield RoutingService.get({ IMO: voyage.IMO, RoutingPoints });
        if (routeResponse) {
            const extractedVoyageRoute = RouteHelper.extractRoutePoints(routeResponse);
            voyageRoutePoints = extractedVoyageRoute.routePoints;
        }
    }
    yield put({ type: ActionTypes.HOME_SET_SELECTED_VOYAGE_ROUTE, voyageRoutePoints });
}

function* getVoyageAisPoints(voyage) {
    const aisResponse = yield VesselService.getHistoricalById(voyage.IMO, {
        startTime: voyage.StartDate,
        endTime: moment.utc(voyage.EndDate).add(1, 'h').toISOString()
    });
    let voyageAisPoints = null;
    if (aisResponse && aisResponse.Entries && aisResponse.Entries.length && aisResponse.Entries[0]) {
        voyageAisPoints = aisResponse.Entries[0].Entries;
    }
    yield put({ type: ActionTypes.HOME_SET_SELECTED_VOYAGE_AIS_POINTS, voyageAisPoints });
}

function* getVoyageCargo(voyage) {
    const permissions = yield select(state => state.userReducer.permissions);
    let tankLayout = null;
    let fixtures = null;
    if (voyage.VoyageId && permissions.GetCargoTanks) {
        tankLayout = yield CargoService.getTankLayout(voyage.VoyageId);
    } else if (permissions && permissions.GetFixtures) {
        if (voyage.VoyageId) {
            fixtures = yield CargoService.getFixtures({ VoyageId: voyage.VoyageId });
        } else {
            fixtures = yield CargoService.getFixtures({
                IMO: voyage.IMO,
                StartDate: voyage.StartDate,
                EndDate: voyage.EndDate
            });
        }
    }
    yield put({ type: ActionTypes.HOME_SET_VOYAGE_CARGO, fixtures, tankLayout });
}

function* getVoyageActivity(voyage) {
    const permissions = yield select(state => state.userReducer.permissions);
    if (permissions && permissions.ShowVesselActivity && !voyage.VoyageId) {
        const voyageActivity = yield VesselService.getActivityById(voyage.IMO, {
            StartDate: voyage.StartDate,
            EndDate: voyage.EndDate
        });
        yield put({ type: ActionTypes.HOME_SET_VOYAGE_ACTIVITY, voyageActivity });
    } else {
        yield put({ type: ActionTypes.HOME_SET_VOYAGE_ACTIVITY, voyageActivity: null });
    }
}

function* getVesselVoyageActivity() {
    const { improvisedVoyage } = yield select(state => state.homeReducer);

    yield getVoyageActivity(improvisedVoyage);
}

function* getVoyageEuEts(action) {
    const { selectedVoyageAisPoints, selectedVoyageFootprint } = yield select(state => state.homeReducer);
    const voyageEuEts = yield getEuEts({
        emissions: selectedVoyageFootprint?.TotalEmissions,
        coordinates: selectedVoyageAisPoints,
        year: action?.year || null
    });
    yield put({ type: ActionTypes.HOME_SET_VOYAGE_EU_ETS, voyageEuEts });
}

function* getVoyageFootprint(voyage) {
    const permissions = yield select(state => state.userReducer.permissions);
    let voyageFootprint = null;
    if (ConfigService.featureToggles.showFootprint && permissions?.VesselFootprint) {
        if ((voyage?.VoyageId) || (voyage?.StartDate && voyage?.EndDate)) {
            if (LocalStorageService.isDebugModeActive()) {
                voyageFootprint = yield getMockedVoyageFootprint();
            } else {
                voyageFootprint = voyage?.VoyageId
                    ? yield VoyageService.getFootprint(voyage.VoyageId)
                    : yield VesselService.getFootprint(voyage.IMO, {
                        StartDate: voyage.StartDate,
                        EndDate: voyage.EndDate
                    });
            }
        }
    }
    yield put({ type: ActionTypes.HOME_SET_VOYAGE_FOOTPRINT, voyageFootprint });
}

function* getVoyageEarnings(voyage) {
    const permissions = yield select(state => state.userReducer.permissions);
    let voyageEarnings = null;
    if (permissions
        && permissions.GetVoyageEarnings
        && voyage.VoyageId
        && ConfigService.featureToggles.showNetDailyAndCO2PerDollar) {
        voyageEarnings = yield VoyageService.getEarnings(voyage.VoyageId);
    }
    yield put({ type: ActionTypes.HOME_SET_VOYAGE_EARNINGS, voyageEarnings });
}

function* getVoyageVmsFootprint(voyage) {
    const permissions = yield select(state => state.userReducer.permissions);
    let voyageVmsFootprint = null;
    if (permissions
        && permissions.VesselFootprint
        && voyage.VoyageId
        && ConfigService.featureToggles.showVmsFootprint) {
        voyageVmsFootprint = yield VoyageService.getVmsFootprint(voyage.VoyageId);
    }
    yield put({ type: ActionTypes.HOME_SET_VOYAGE_VMS_FOOTPRINT, voyageVmsFootprint });
}

function* getVesselCiiYearlyRating(action) {
    const permissions = yield select(state => state.userReducer.permissions);
    let ciiYearlyRatingWithCorrection = null;
    if (permissions?.VesselFootprint
        && ConfigService.featureToggles.showFootprint
        && !ConfigService.hiddenFeatures.mapVesselInfoVesselCiiRating) {
        ciiYearlyRatingWithCorrection = yield VesselService.getCiiGradePerYears({
            IMO: action.vesselId,
            endYear: new Date().getFullYear()
        });
        yield put({
            type: ActionTypes.HOME_SET_VESSEL_CII_YEARLY_RATING,
            ciiYearlyRatingWithCorrection: mapArrayByProp(ciiYearlyRatingWithCorrection, 'Year')
        });
    }
}

function* getVoyageCharterer(voyage) {
    const permissions = yield select(state => state.userReducer.permissions);
    if (permissions && permissions.GetChartererInfo) {
        let selectedVoyageCharterer = null;
        if (voyage) {
            let params = {
                Imo: voyage.IMO,
                Start: voyage.StartDate,
                End: voyage.EndDate
            };
            if (voyage.VoyageId) {
                params = { VoyageId: voyage.VoyageId };
            }
            const res = yield VoyageService.getVoyageCharterer(params);
            if (res && res[0] && res[0].Name) {
                selectedVoyageCharterer = res[0].Name;
            }
        }
        yield put({
            type: ActionTypes.HOME_SET_VOYAGE_CHARTERER,
            selectedVoyageCharterer
        });
    }
}

function* getImprovisedVoyage(action) {
    const {
        selectedVesselId,
        selectedVoyage,
        improvisedVoyage
    } = yield select(state => state.homeReducer);
    const { selectedDate } = yield select(state => state.mapDatePickerReducer);
    const selectedRange = yield select(state => getSelectedPredefinedRange(state));
    const isTimestampInImprovisedVoyage = !!improvisedVoyage && !!selectedVesselId && !!action.range
        && selectedRange
        && action.range.Id === selectedRange.Id
        && improvisedVoyage.IMO === selectedVesselId
        && moment.utc(selectedDate).isBetween(improvisedVoyage.StartDate, improvisedVoyage.EndDate, null, '[]');

    if (selectedVesselId) {
        if (!isTimestampInImprovisedVoyage) {
            const voyage = {
                IMO: selectedVesselId,
                StartDate: action.range.Type === 'ToDate'
                    ? moment.utc(selectedDate).startOf(action.range.Unit).toISOString()
                    : moment.utc(selectedDate).subtract(action.range.Value, action.range.Unit).toISOString(),
                EndDate: moment.utc(selectedDate).toISOString()
            };
            yield put({ type: ActionTypes.HOME_SET_IMPROVISED_VOYAGE, voyage });
            if (selectedVoyage) {
                yield all([
                    put({ type: ActionTypes.HOME_SET_SELECTED_VOYAGE, voyage: null })
                ]);
            }
            yield all([
                getVoyageCharterer(voyage),
                getVoyageCargo(voyage),
                getVoyageAisPoints(voyage),
                getVoyageRoutePoints(voyage),
                getVoyageActivity(voyage),
                getVoyageFootprint(voyage),
                getVoyageEarnings(voyage),
                getReportsByVoyageId()
            ]);
            yield getVoyageEuEts();
        }
    }
    if (!selectedVesselId && (selectedVoyage || improvisedVoyage)) {
        yield all([
            put({ type: ActionTypes.HOME_SET_SELECTED_VOYAGE, voyage: null }),
            put({ type: ActionTypes.HOME_SET_IMPROVISED_VOYAGE, voyage: null }),
            put({ type: ActionTypes.HOME_SET_SELECTED_VOYAGE_REPORTS, selectedVoyageReports: null }),
            put({ type: ActionTypes.HOME_SET_LATEST_VOYAGE_REPORT, latestVoyageReport: null }),
            put({ type: ActionTypes.HOME_SET_VOYAGE_ITINERARY, voyageItinerary: null }),
            put({ type: ActionTypes.HOME_SET_VOYAGE_CARGO, fixtures: null, tankLayout: null }),
            put({ type: ActionTypes.HOME_SET_VOYAGE_ACTIVITY, voyageActivity: null }),
            put({ type: ActionTypes.HOME_SET_VOYAGE_CHARTERER, selectedVoyageCharterer: null }),
            put({ type: ActionTypes.HOME_SET_SELECTED_VOYAGE_ROUTE, voyageRoute: null, voyageRoutePoints: null }),
            put({ type: ActionTypes.HOME_SET_SELECTED_VOYAGE_AIS_POINTS, voyageAisPoints: null }),
            put({ type: ActionTypes.HOME_SET_VOYAGE_FOOTPRINT, voyageFootprint: null }),
            put({ type: ActionTypes.HOME_SET_VOYAGE_EU_ETS, voyageEuEts: null })
        ]);
    }
}

function* getCurrentVoyage(action) {
    const permissions = yield select(state => state.userReducer.permissions);
    let selectedVesselId = yield select(state => state.homeReducer.selectedVesselId);
    let selectedVesselPosition = yield select(state => state.homeReducer.selectedVesselPosition);
    if (action) {
        selectedVesselId = action.selectedVesselId || selectedVesselId;
        selectedVesselPosition = action.vesselPosition || selectedVesselPosition;
    }
    const {
        selectedVoyage,
        improvisedVoyage,
        selectedVoyageAisPoints } = yield select(state => state.homeReducer);
    let selectedDate = yield select(state => state.mapDatePickerReducer.selectedDate);
    selectedDate = selectedDate || moment.utc().startOf('h').toISOString();
    const isNowSelected = yield select(state => state.mapDatePickerReducer.isNowSelected);
    const selectedRange = yield select(state => getSelectedPredefinedRange(state));
    const lastAisPoint = selectedVoyageAisPoints
        ? selectedVoyageAisPoints[selectedVoyageAisPoints.length - 1]
        : null;
    const isTimestampInSelectedVoyage = !!selectedVoyage && !!selectedVesselId
        && selectedVoyage.IMO === selectedVesselId
        && (moment(selectedDate).isBetween(
            moment.utc(selectedVoyage.StartDate),
            moment.utc(selectedVoyage.EndDate),
            null,
            '[]'
        ) || (isNowSelected && moment.utc(selectedVoyage.StartDate).isAfter(moment.utc(selectedDate))));
    const isTimestampInImprovisedVoyage = !!improvisedVoyage && !!selectedVesselId
        && improvisedVoyage.IMO === selectedVesselId
        && moment(selectedDate).isBetween(improvisedVoyage.StartDate, improvisedVoyage.EndDate, null, '[]');
    const newAisLocationAvailable = isNowSelected && lastAisPoint && selectedVesselPosition
        && lastAisPoint.PositionTime !== selectedVesselPosition.PositionTime;

    let voyage;
    if (selectedVesselId) {
        if (!isTimestampInSelectedVoyage) {
            let response = null;
            if (permissions.GetVoyage) {
                response = yield VesselService.getAllVesselVoyages(selectedVesselId, { timestamp: selectedDate });
            }
            if (response && response.length > 0 && response[0]) {
                voyage = {
                    ...response[0],
                    EndDate: response[0].EndDate || moment().add(1, 'h').toISOString()
                };
                yield all({
                    charterer: getVoyageCharterer(voyage),
                    fixtures: getVoyageCargo(voyage),
                    aisPoints: getVoyageAisPoints(voyage),
                    activity: getVoyageActivity(voyage),
                    voyageFootprint: getVoyageFootprint(voyage),
                    voyageEarnings: getVoyageEarnings(voyage),
                    voyageVmsFootprint: getVoyageVmsFootprint(voyage),
                    routePoints: getVoyageRoutePoints(voyage),
                    reports: getReportsByVoyageId(voyage.VoyageId)
                });
                yield getVoyageEuEts();
                yield put({ type: ActionTypes.HOME_SET_SELECTED_VOYAGE, voyage });
                if (improvisedVoyage) {
                    yield put({ type: ActionTypes.HOME_SET_IMPROVISED_VOYAGE, voyage: null });
                }
            } else if (!isTimestampInImprovisedVoyage) {
                voyage = {
                    IMO: selectedVesselId,
                    StartDate: selectedRange.Type === 'ToDate'
                        ? moment.utc(selectedDate).startOf(selectedRange.Unit).toISOString()
                        : moment.utc(selectedDate).subtract(selectedRange.Value, selectedRange.Unit).toISOString(),
                    EndDate: moment.utc(selectedDate).toISOString()
                };
                yield all([
                    put({ type: ActionTypes.HOME_SET_IMPROVISED_VOYAGE, voyage }),
                    getVoyageCharterer(voyage),
                    getVoyageCargo(voyage),
                    getVoyageActivity(voyage),
                    getVoyageAisPoints(voyage),
                    getVoyageRoutePoints(voyage),
                    getVoyageFootprint(voyage),
                    getVoyageEarnings(voyage),
                    getReportsByVoyageId()
                ]);
                yield getVoyageEuEts();
                if (selectedVoyage) {
                    yield put({ type: ActionTypes.HOME_SET_SELECTED_VOYAGE, voyage: null });
                }
            }
        }
        if (!voyage && newAisLocationAvailable) {
            voyage = selectedVoyage || improvisedVoyage;
            yield all([
                getVoyageAisPoints(voyage),
                getVoyageRoutePoints(voyage),
                getVoyageActivity(voyage)
            ]);
            yield getVoyageEuEts();
        }
    }

    if ((!selectedVesselId && (selectedVoyage || improvisedVoyage))
        || (!voyage && !isTimestampInSelectedVoyage && !isTimestampInImprovisedVoyage)) {
        yield all([
            put({ type: ActionTypes.HOME_SET_SELECTED_VESSEL_RELATIVE_DETAILS, relativeDetails: null }),
            put({ type: ActionTypes.HOME_SET_SELECTED_VOYAGE, voyage: null }),
            put({ type: ActionTypes.HOME_SET_IMPROVISED_VOYAGE, voyage: null }),
            put({ type: ActionTypes.HOME_SET_SELECTED_VOYAGE_REPORTS, selectedVoyageReports: null }),
            put({ type: ActionTypes.HOME_SET_LATEST_VOYAGE_REPORT, latestVoyageReport: null }),
            put({ type: ActionTypes.HOME_SET_VOYAGE_ITINERARY, voyageItinerary: null }),
            put({ type: ActionTypes.HOME_SET_VOYAGE_CARGO, fixtures: null, tankLayout: null }),
            put({ type: ActionTypes.HOME_SET_VOYAGE_ACTIVITY, voyageActivity: null }),
            put({ type: ActionTypes.HOME_SET_VOYAGE_CHARTERER, selectedVoyageCharterer: null }),
            put({ type: ActionTypes.HOME_SET_SELECTED_VOYAGE_ROUTE, voyageRoute: null, voyageRoutePoints: null }),
            put({ type: ActionTypes.HOME_SET_SELECTED_VOYAGE_AIS_POINTS, voyageAisPoints: null }),
            put({ type: ActionTypes.HOME_SET_VOYAGE_FOOTPRINT, voyageFootprint: null }),
            put({ type: ActionTypes.HOME_SET_VOYAGE_EU_ETS, voyageEuEts: null })
        ]);
    }
}

function* getVoyageById(action) {
    const { selectedVoyage, improvisedVoyage } = yield select(state => state.homeReducer);
    const selectedVesselId = yield select(state => state.homeReducer.selectedVesselId);
    const vesselId = action.vesselId || selectedVesselId;
    if (!selectedVoyage || selectedVoyage.VoyageId !== action.voyageId) {
        const voyageResponse = yield VoyageService.getById(action.voyageId);
        if (voyageResponse && vesselId === voyageResponse.IMO) {
            const voyage = {
                ...voyageResponse,
                EndDate: voyageResponse.EndDate || moment().add(1, 'h').toISOString()
            };
            yield put({ type: ActionTypes.HOME_SET_SELECTED_VOYAGE, voyage });
            if (improvisedVoyage) {
                yield put({ type: ActionTypes.HOME_SET_IMPROVISED_VOYAGE, voyage: null });
            }
            yield all([
                getVoyageAisPoints(voyage),
                getVoyageRoutePoints(voyage),
                getVoyageCharterer(voyage),
                getVoyageActivity(voyage),
                getVoyageFootprint(voyage),
                getVoyageEarnings(voyage),
                getVoyageCargo(voyage),
                getVoyageVmsFootprint(voyage),
                getReportsByVoyageId(voyage.VoyageId)
            ]);
            yield getVoyageEuEts();
            const voyageEndDate = voyage.EndDate
                ? moment.utc(voyage.EndDate).startOf('hour')
                : moment.utc().startOf('hour');
            yield put({
                type: MapDatePickerActionTypes.MAP_DATE_PICKER_UPDATE_DATE,
                date: voyageEndDate,
                forceUpdate: true
            });
        } else {
            yield getCurrentVoyage({ selectedVesselId: action.vesselId });
        }
    }
}

function* getVesselVoyages(action) {
    const permissions = yield select(state => state.userReducer.permissions);
    if (permissions && permissions.GetVoyage) {
        const voyages = yield VesselService.getVoyagesPreviewById(action.vesselId);
        yield put({ type: ActionTypes.HOME_SET_SELECTED_VESSEL_VOYAGES, voyages });
    }
}

function* getVesselQuestionnaireList(action) {
    const { selectedVesselId, isMounted } = yield select(state => state.homeReducer);
    const permissions = yield select(state => state.userReducer.permissions);
    if (isMounted && permissions.GetQuestionnaire && ConfigService.featureToggles.showQuestionnaire) {
        const vesselId = action.vesselId || selectedVesselId;
        const questionnaireList = yield QuestionnaireService.get(vesselId);
        yield put({ type: ActionTypes.HOME_SET_SELECTED_VESSEL_QUESTIONNAIRE_LIST, questionnaireList });
    }
}

function* getVesselNotifications(action) {
    let vesselId = yield select(state => state.homeReducer.selectedVesselId);
    vesselId = action && action.vesselId ? action.vesselId : vesselId;
    let notifications = null;
    if (vesselId) {
        const response = yield NotificationService.getNotificationsByImo(vesselId, {
            Limit: 5000,
            Offset: 0,
            Dismissed: false
        });
        notifications = response && response.Notifications ? response.Notifications : null;
    }
    yield put({ type: ActionTypes.HOME_SET_SELECTED_VESSEL_NOTIFICATIONS, notifications });
}

function* getVesselImages(action) {
    const permissions = yield select(state => state.userReducer.permissions);
    if (permissions.ShowVesselImages) {
        const images = yield VesselService.getImages(action.vesselId);
        yield put({
            type: ActionTypes.HOME_SET_SELECTED_VESSEL_IMAGES,
            images
        });
    }
}

function* getVesselInfo(action) {
    const permissions = yield select(state => state.userReducer.permissions);
    let vesselBase;
    let vesselDetails;
    if (permissions && permissions.GetVesselDetails) {
        (({ vesselBase, vesselDetails } = yield all({
            vesselBase: VesselService.getBaseById(action.vesselId),
            vesselDetails: VesselService.getDetailsById(action.vesselId)
        })));
    } else {
        vesselBase = yield VesselService.getBaseById(action.vesselId);
    }
    yield put({ type: ActionTypes.HOME_SET_SELECTED_VESSEL_BASE, vesselBase: vesselBase || null });
    yield put({ type: ActionTypes.HOME_SET_SELECTED_VESSEL_DETAILS, vesselDetails: vesselDetails || null });
}

function* getSelectedVesselBase() {
    const { selectedVesselId } = yield select(state => state.homeReducer);
    if (selectedVesselId) {
        const vesselBase = yield VesselService.getBaseById(selectedVesselId);
        yield put({ type: ActionTypes.HOME_SET_SELECTED_VESSEL_BASE, vesselBase: vesselBase || null });
    }
}

function* getBudgetedConsumption(vesselId) {
    const budgetedConsumption = yield VesselService.getBudgetedConsumption(vesselId);
    yield put({ type: ActionTypes.HOME_SET_SELECTED_VESSEL_BUDGETED_CONSUMPTION, budgetedConsumption });
}

function* getVessel(action) {
    const permissions = yield select(state => state.userReducer.permissions);
    yield put({ type: ActionTypes.HOME_REMOVE_SELECTED_VESSEL });
    const actions = [
        getVesselInfo(action),
        getVesselImages(action),
        getVesselPosition(action),
        getVesselVoyages(action),
        getVesselQuestionnaireList(action),
        getVesselCiiYearlyRating(action)
    ];
    if (action.voyageId) {
        actions.push(getVoyageById(action));
    } else {
        actions.push(getCurrentVoyage({ selectedVesselId: action.vesselId }));
    }
    if (permissions && permissions.GetNotificationsByImo) {
        actions.push(getVesselNotifications(action));
    }
    if (permissions && permissions.GetVesselConsumption) {
        actions.push(getBudgetedConsumption(action.vesselId));
    }
    yield all(actions);
}

function* getVesselPositionAndVoyage(action) {
    const vesselPosition = yield getVesselPosition(action);
    yield getCurrentVoyage({ vesselPosition });
}

function* updateReportsAndNotifications(action) {
    const { selectedVoyageReports, isMounted } = yield select(state => state.homeReducer);
    if (isMounted && selectedVoyageReports && selectedVoyageReports.length > 0) {
        const foundReportIndex = selectedVoyageReports.findIndex(el =>
            el.MissingReportId === action.report.MissingReportId);
        if (foundReportIndex >= 0) {
            const newSelectedVoyageReports = selectedVoyageReports.slice();
            newSelectedVoyageReports.splice(foundReportIndex, 1, action.report);
            yield put({
                type: ActionTypes.HOME_SET_SELECTED_VOYAGE_REPORTS,
                selectedVoyageReports: newSelectedVoyageReports
            });
        }
    }
    yield getVesselNotifications();
}

export default function* homeSaga() {
    yield takeEvery(ActionTypes.HOME_GET_VESSELS, getVessels);
    yield takeEvery(ActionTypes.HOME_GET_PORTS, getPorts);
    yield takeEvery(ActionTypes.HOME_GET_SELECTED_VESSEL, getVessel);
    yield takeEvery(ActionTypes.HOME_GET_SELECTED_VESSEL_BASE, getSelectedVesselBase);
    yield takeEvery(ActionTypes.HOME_GET_SELECTED_VESSEL_VOYAGE_ACTIVITY, getVesselVoyageActivity);
    yield takeEvery(ActionTypes.HOME_GET_SELECTED_VESSEL_POSITION_AND_VOYAGE, getVesselPositionAndVoyage);
    yield takeEvery(ActionTypes.HOME_GET_SELECTED_VESSEL_NOTIFICATIONS, getVesselNotifications);
    yield takeEvery(ActionTypes.HOME_GET_SELECTED_VESSEL_CURRENT_VOYAGE, getCurrentVoyage);
    yield takeEvery(ActionTypes.HOME_GET_SELECTED_VESSEL_VOYAGE_BY_ID, getVoyageById);
    yield takeEvery(ActionTypes.HOME_GET_IMPROVISED_VOYAGE, getImprovisedVoyage);
    yield takeEvery(ActionTypes.HOME_GET_VOYAGE_EU_ETS, getVoyageEuEts);
    yield takeEvery(QuestionnaireActionTypes.QUESTIONNAIRE_DELETED, getVesselQuestionnaireList);
    yield takeEvery(QuestionnaireActionTypes.QUESTIONNAIRE_UPDATED, getVesselQuestionnaireList);
    yield takeEvery(ReportActionTypes.VESSEL_REPORT_REMINDER_SENT, updateReportsAndNotifications);
}
