import React from 'react';
import PropTypes from 'prop-types';
/* types */
import { YearShape } from '../htec-date-picker/types';
/* utils */
import { translate } from '../../utils/i18n/i18n-model';
/* components */
import Input from 'components/input/input';
import Popover from 'components/popover/popover';
import YearPicker from './year-picker';

export default class YearPopup extends React.PureComponent {
    state = {
        isPopoverVisible: false
    };

    componentDidMount() {
        document.addEventListener('scrollChanged', this.disablePopover);
    }

    componentWillUnmount() {
        document.removeEventListener('scrollChanged', this.disablePopover);
    }

    disablePopover = () => this.setState({ isPopoverVisible: false });

    togglePopover = () => this.setState({ isPopoverVisible: !this.state.isPopoverVisible });

    handleClickOutside = () => {
        this.setState({ isPopoverVisible: false });
    };

    handleChange = (args) => {
        this.props.onChange(args);
        this.setState({ isPopoverVisible: false });
    };

    handleClear = (e) => {
        e.stopPropagation();
        this.props.onChange(null);
    };

    render() {
        return (
            <Popover
                contentClassName={this.props.popoverClassName}
                content={this.state.isPopoverVisible && (
                    <YearPicker
                        initialYear={this.props.initialYear}
                        minDate={this.props.minDate}
                        maxDate={this.props.maxDate}
                        onChange={this.handleChange}
                        value={this.props.value}
                    />
                )}
                onClickOutside={this.handleClickOutside}
                placement={this.props.placement}
                alignment={this.props.alignment}
                isVisible={this.state.isPopoverVisible}
            >
                <Input
                    readOnly
                    clearable={this.props.clearable}
                    placeholder={this.props.placeholder}
                    value={this.props.value && this.props.value.year}
                    disabled={this.props.disabled}
                    invalid={this.props.invalid}
                    onClick={this.togglePopover}
                    onClear={this.handleClear}
                />
            </Popover>
        );
    }
}

YearPopup.propTypes = {
    alignment: PropTypes.oneOf(['start', 'center', 'end']),
    clearable: PropTypes.bool,
    contentClassName: PropTypes.string,
    disabled: PropTypes.bool,
    initialYear: PropTypes.number,
    inputClassName: PropTypes.string,
    invalid: PropTypes.bool,
    maxDate: PropTypes.oneOfType([PropTypes.shape(YearShape), PropTypes.object, PropTypes.number, PropTypes.string]),
    minDate: PropTypes.oneOfType([PropTypes.shape(YearShape), PropTypes.object, PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    popoverClassName: PropTypes.string,
    renderMonth: PropTypes.func,
    renderYear: PropTypes.func,
    today: PropTypes.shape(YearShape),
    value: PropTypes.shape(YearShape)
};

YearPopup.defaultProps = {
    alignment: 'end',
    clearable: false,
    contentClassName: '',
    disabled: false,
    initialYear: undefined,
    inputClassName: '',
    invalid: false,
    maxDate: null,
    minDate: null,
    name: '',
    placeholder: translate('YEAR_POPUP.SELECT_YEAR'),
    placement: 'bottom',
    popoverClassName: '',
    renderMonth: undefined,
    renderYear: undefined,
    today: undefined,
    value: null
};
