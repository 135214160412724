/* helpers */
import MockHelper from 'utils/helpers/mock-helper';
import { mapArrayByProp } from 'utils/helpers/array-helper';
import { vesselConditions, speedTypes } from './questionnaire-helpers';
/* services */
import PortService from 'services/core-api/port-service';

const vesselCondition = mapArrayByProp(vesselConditions, 'Name');
const speedType = mapArrayByProp(speedTypes, 'Name');

const mockSpeedDetails = () => {
    const ballastMinSpeed = MockHelper.getRandomNumber(20, 26) / 2;
    const ballastRpmMin = MockHelper.getRandomNumber(100, 150);
    const ladenMinSpeed = MockHelper.getRandomNumber(20, 26) / 2;
    const ladenRpmMin = MockHelper.getRandomNumber(ballastRpmMin, ballastRpmMin + 10);

    return [
        {
            Id: 1,
            VesselCondition: vesselCondition.Ballast,
            MinimumSpeed: ballastMinSpeed,
            RpmAtMinimumSpeed: ballastRpmMin
        },
        {
            Id: 2,
            VesselCondition: vesselCondition.Laden,
            MinimumSpeed: ladenMinSpeed,
            RpmAtMinimumSpeed: ladenRpmMin
        }
    ];
};

const mockSpeedInformation = () => {
    const ecoSpeed = MockHelper.getRandomNumber(20, 24) / 2;
    const fullSpeed = MockHelper.getRandomNumber(28, 32) / 2;
    const ecoTotalConsumption = MockHelper.getRandomNumber(30, 40);
    const fullTotalConsumption = MockHelper.getRandomNumber(ecoTotalConsumption + 10, ecoTotalConsumption + 20);
    const ecoRpm = MockHelper.getRandomNumber(100, 140);
    const fullRpm = MockHelper.getRandomNumber(150, 190);
    const ecoData = [
        {
            Id: 1,
            Speed: ecoSpeed,
            TotalConsumption: ecoTotalConsumption,
            NormalRpm: ecoRpm,
            VesselCondition: vesselCondition.Ballast,
            SpeedType: speedType.Eco
        },
        {
            Id: 2,
            Speed: MockHelper.getRandomNumber(2 * (ecoSpeed - 2), 2 * (ecoSpeed - 1)) / 2,
            TotalConsumption: MockHelper.getRandomNumber(ecoTotalConsumption + 1, ecoTotalConsumption + 5),
            NormalRpm: MockHelper.getRandomNumber(ecoRpm, ecoRpm + 10),
            VesselCondition: vesselCondition.Laden,
            SpeedType: speedType.Eco
        }
    ];
    const fullData = [
        {
            Id: 3,
            Speed: fullSpeed,
            TotalConsumption: fullTotalConsumption,
            NormalRpm: fullRpm,
            VesselCondition: vesselCondition.Ballast,
            SpeedType: speedType.Full
        },
        {
            Id: 4,
            Speed: MockHelper.getRandomNumber(2 * (fullSpeed - 2), 2 * (fullSpeed - 1)) / 2,
            TotalConsumption: MockHelper.getRandomNumber(fullTotalConsumption + 1, fullTotalConsumption + 5),
            NormalRpm: MockHelper.getRandomNumber(fullRpm, fullRpm + 10),
            VesselCondition: vesselCondition.Laden,
            SpeedType: speedType.Full
        }
    ];
    const hasEco = MockHelper.getRandomBoolean();
    const hasFull = hasEco ? MockHelper.getRandomBoolean() : true;

    return [
        ...hasEco ? ecoData : [],
        ...hasFull ? fullData : []
    ];
};

export const mockQuestionnaireData = () => {
    const vessel = MockHelper.getRandomVessel();
    const offset = MockHelper.getRandomNumber(-12, 12) * 60;
    const rpmMax = MockHelper.getRandomNumber(60, 200);
    const currentDate = new Date().valueOf();
    const minDate = currentDate - 180 * 24 * 3600 * 1000;
    const ProlongedPortStays = MockHelper.getRandomBoolean();
    return PortService.get({ ImportanceLevel: 15 }).then(ports => ({
        BunkerData: [{
            Id: 1,
            Name: 'ROB',
            FuelTypeQuantities: MockHelper.getFuelTypeQuantities(0, 5000)
        }],
        CallSign: MockHelper.getRandomCharSet(5).toUpperCase(),
        CleaningEquipmentOnBoard: MockHelper.getRandomText(),
        DataSourceId: 7,
        Id: MockHelper.getRandomNumber(0, 2000),
        Imo: vessel.Imo,
        VesselName: vessel.Title,
        LastPropellerPolish: MockHelper.getRandomDate(minDate, currentDate),
        LastPropellerPolishOffset: offset,
        LastUwCleaning: MockHelper.getRandomDate(minDate, currentDate),
        LastUwCleaningOffset: offset,
        LastUwInspection: MockHelper.getRandomDate(minDate, currentDate),
        LastUwInspectionOffset: offset,
        PitchOfPropeller: MockHelper.getRandomNumber(0, 20, 2),
        PropellerDiameter: MockHelper.getRandomNumber(0, 20, 2),
        PropellerNumberOfBlades: MockHelper.getRandomNumber(3, 20, 0),
        PropellerExpandedArea: MockHelper.getRandomNumber(0, 100, 2),
        PropellerAverageSlip: MockHelper.getRandomNumber(-100, 100, 2),
        PropellerEnergySavingDevices: MockHelper.getRandomText(),
        OelTorque: MockHelper.getRandomNumber(0, 10000, 2),
        OelPower: MockHelper.getRandomNumber(0, 100, 2),
        OelRpm: MockHelper.getRandomNumber(100, 200, 0),
        PortOfLastPropellerPolish: MockHelper.getRandomArrayItem(ports),
        PortOfLastUwCleaning: MockHelper.getRandomArrayItem(ports),
        PortOfLastUwInspection: MockHelper.getRandomArrayItem(ports),
        PortOfProlongedPortStay: ProlongedPortStays ? MockHelper.getRandomArrayItem(ports) : null,
        PortVisits: MockHelper.getRandomArrayOfItems(0, 10, null, (i) => {
            return {
                ReportPort: MockHelper.getRandomArrayItem(ports),
                Charterer: MockHelper.getRandomWord().toUpperCase(),
                Cargo: MockHelper.getRandomWord(),
                Sequence: i
            };
        }),
        ProlongedPortStays,
        Remarks: MockHelper.getRandomText(),
        ReportDate: currentDate,
        ReportDateOffset: offset,
        RpmMax: rpmMax,
        RpmMin: MockHelper.getRandomNumber(0, rpmMax),
        SpeedAndConsumptionDetails: MockHelper.getRandomText(),
        SpeedDetails: mockSpeedDetails(),
        SpeedInformations: mockSpeedInformation()
    }));
};
