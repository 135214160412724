import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { fleetOptionRenderer } from 'utils/helpers/fleet-helper';
/* components */
import Select from 'components/select/select';
import Validation from 'components/validation/validation';

const t = (key) => translate(`OPERATORS_DIARY.FILTERS.${key}`);

const initialState = {
    selectedTimeFrame: null,
    selectedFleets: []
};

class OperatorsDiaryFilters extends React.PureComponent {
    state = initialState;

    handleTimeFrameChange = (val) => this.setState({ selectedTimeFrame: val });

    handleFleetChange = (val) => this.setState({ selectedFleets: val });

    handleSubmit = () => {
        const { selectedTimeFrame, selectedFleets } = this.state;

        const params = {
            FleetIds: selectedFleets.map(fl => fl.VesselGroupId),
            IncludeFuture: selectedTimeFrame.IncludeFuture,
            IncludeHistorical: selectedTimeFrame.IncludeHistorical
        };

        if (selectedTimeFrame && selectedTimeFrame.VoyagesPerVessel) {
            params.VoyagesPerVessel = selectedTimeFrame.VoyagesPerVessel;
        }

        this.props.onSubmit({
            ...params
        });
    };

    handleFiltersReset = () => {
        this.setState(initialState);
        this.props.onReset();
        this.formRef.resetValidation();
    }

    areFiltersChanged = () => {
        const { selectedTimeFrame, selectedFleets } = this.state;
        return !!selectedTimeFrame || selectedFleets.length > 0;
    };

    saveFormRef = (c) => { this.formRef = c; };

    render() {
        const areFiltersChanged = this.areFiltersChanged();
        return (
            <Validation.Form
                ref={this.saveFormRef}
                className="sten-operators-diary-filters sten-content__section flex-row flex-row--sm flex-end"
                onSubmit={this.handleSubmit}
            >
                <div className="col-sm-18 col-xs-24">
                    <div className="row">
                        <div className="col-lg-6 col-md-8 col-sm-10 col-xs-12 col-pad--xs">
                            <label className="label">{t('FLEETS')}</label>
                            <Validation.Wrapper validations={{ required: true }} hintsOnHover>
                                <Select
                                    name="Fleets"
                                    multiple
                                    searchable
                                    clearable
                                    valueKey="VesselGroupId"
                                    labelKey="VesselGroupName"
                                    options={this.props.fleets}
                                    optionRenderer={fleetOptionRenderer}
                                    placeholder={t('FLEETS_PLACEHOLDER')}
                                    value={this.state.selectedFleets}
                                    onChange={this.handleFleetChange}
                                />
                            </Validation.Wrapper>
                        </div>
                        <div className="col-lg-6 col-md-8 col-sm-10 col-xs-12 col-pad--xs">
                            <label className="label">{t('TIME_FRAME')}</label>
                            <Validation.Wrapper validations={{ required: true }} hintsOnHover>
                                <Select
                                    name="TimeFrame"
                                    valueKey="Id"
                                    labelKey="Title"
                                    options={this.props.timeFrameOptions}
                                    placeholder={t('TIME_FRAME_PLACEHOLDER')}
                                    value={this.state.selectedTimeFrame}
                                    onChange={this.handleTimeFrameChange}
                                />
                            </Validation.Wrapper>
                        </div>
                    </div>
                </div>
                <div className="col-xs-24 col-sm-6 col-pad--xs">
                    <div className="row">
                        <div className="col-12">
                            <button
                                disabled={!areFiltersChanged}
                                type="button"
                                className="btn btn--secondary col-24"
                                onClick={this.handleFiltersReset}
                            >
                                {translate('GLOBAL.RESET')}
                            </button>
                        </div>
                        <div className="col-12">
                            <Validation.Button
                                className="btn btn--primary col-24"
                                disabled={!areFiltersChanged}
                            >
                                {translate('GLOBAL.APPLY')}
                            </Validation.Button>
                        </div>
                    </div>
                </div>
            </Validation.Form>
        );
    }
}

OperatorsDiaryFilters.propTypes = {
    fleets: PropTypes.arrayOf(PropTypes.object).isRequired,
    onReset: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    timeFrameOptions: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default OperatorsDiaryFilters;
