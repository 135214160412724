import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* helpers */
import { getClassName } from 'utils/helpers/info-helper';
/* context */
import { WrapperContext } from '../wrapper/wrapper';
/* styles */
import './input.scss';

export class Input extends React.PureComponent {
    state = {
        value: this.props.value,
        prevValue: this.props.value,
        isFocused: false
    };

    static contextType = WrapperContext;

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.prevValue) {
            return {
                prevValue: props.value,
                value: props.value
            };
        }
        return null;
    }

    componentDidMount() {
        if (this.props.autoFocus) {
            this.autoFocusTimeout = setTimeout(() => {
                this.input.focus();
            }, this.props.autoFocusDelay);
        }
    }

    componentWillUnmount() {
        if (this.context.activeInput === this.input) {
            this.context.setActiveInput(null);
        }
        if (this.keyboardTimeout) {
            clearTimeout(this.keyboardTimeout);
        }
        if (this.autoFocusTimeout) {
            clearTimeout(this.autoFocusTimeout);
        }
        if (this.changeDebounce) {
            clearTimeout(this.changeDebounce);
        }
    }

    onOuterDivBlur = () => {
        this.setState({ isFocused: false });
    };

    onInput = event => {
        if (this.inputTimeout) {
            clearTimeout(this.inputTimeout);
        }
        const value = event.target.value;
        this.inputTimeout = setTimeout(() => this.onChange(event, value), 0);
    };

    onChange = (event, value) => {
        if (this.inputTimeout) {
            clearTimeout(this.inputTimeout);
        }
        this.setState({ value: value !== undefined ? value : event.target.value }, () => {
            if (this.changeDebounce) {
                clearTimeout(this.changeDebounce);
            }
            this.changeDebounce = setTimeout(() => {
                this.props.onChange(this.state.value);
            }, this.props.changeDebounce);
        });
    };

    onFocusChange = isFocused => {
        if (this.props.onFocusChange) {
            this.props.onFocusChange(isFocused);
        }
        if (isFocused !== this.state.isFocused) {
            this.setState({ isFocused });
        }
        if ((this.props.onScreenKeyboardEnabled || this.props.showKeyboard)
            && !this.props.disabled && !this.props.readOnly) {
            if (isFocused && this.props.type !== 'button') {
                if (!this.props.showKeyboard && this.context.activeInput !== this.input) {
                    this.context.setActiveInput(this.input);
                }
                if (this.keyboardTimeout) {
                    clearTimeout(this.keyboardTimeout);
                }
            } else {
                this.keyboardTimeout = setTimeout(() => {
                    if (this.context.activeInput === this.input) {
                        this.context.setActiveInput(null);
                    }
                }, 300);
            }
        }
    };

    setFocus = () => {
        this.input.focus();
    };

    showKeyboard = () => {
        if (this.context.activeInput !== this.input) {
            this.context.setActiveInput(this.input);
        } else {
            this.context.setActiveInput(null);
        }
        this.input.focus();
    };

    clearValue = e => {
        if (this.props.onClear) {
            this.props.onClear(e);
        } else {
            e.stopPropagation();
            if (!this.props.disabled && this.props.onChange) {
                this.props.onChange('');
            }
        }
    };

    getIconElement = (iconClass) => (<span className={`icon sten-input__icon ${iconClass}`} />);

    render() {
        const className = getClassName('sten-input', this.props.className, {
            'sten-input--disabled': this.props.disabled,
            'sten-input--focused': !this.props.disabled && this.state.isFocused,
            'sten-input--warning': this.props.invalid && this.props.warning,
            'sten-input--invalid': this.props.invalid && !this.props.warning
        });

        const clearIconClass = getClassName('icon sten-input__icon sten-input__icon--clear icon-close', {
            'sten-input__icon--clickable': !this.props.disabled && this.props.onChange
        });

        const keyboardIconClass = getClassName('icon sten-input__icon icon-keyboard sten-input__keyboard', {
            'sten-input__keyboard--active': this.context.activeInput === this.input
        });

        let inputValue = typeof this.state.value === 'number' || typeof this.state.value === 'string'
            ? this.state.value
            : '';

        if (this.props.readOnly && this.props.displayValue) {
            inputValue = this.props.displayValue;
        }
        const suffixClickHandler = this.props.onSuffixClick && !this.props.disabled
            ? this.props.onSuffixClick
            : this.setFocus;
        const prefixClickHandler = this.props.onPrefixClick && !this.props.disabled
            ? this.props.onPrefixClick
            : this.setFocus;

        const suffix = this.props.suffixIcon ? this.getIconElement(this.props.suffixIcon) : this.props.suffix;
        const prefix = this.props.prefixIcon ? this.getIconElement(this.props.prefixIcon) : this.props.prefix;
        return (
            <div
                className={className}
                title={this.props.title}
                onClick={!this.props.disabled ? this.props.onClick : undefined}
                onBlur={this.onOuterDivBlur}
            >
                {prefix && (
                    <div
                        key="prefix"
                        className={`sten-input__item${this.props.onPrefixClick ? ' sten-input__item--clickable' : ''}`}
                        onClick={prefixClickHandler}
                    >
                        {prefix}
                    </div>
                )}
                <input
                    key="input"
                    onInput={this.onInput}
                    autoComplete={this.props.autoComplete}
                    readOnly={this.props.readOnly}
                    ref={(input) => { this.input = input; }}
                    type={this.props.type}
                    className="sten-input__input"
                    name={this.props.name || this.props.id}
                    id={this.props.id}
                    value={inputValue}
                    placeholder={this.props.placeholder}
                    onChange={this.onChange}
                    onFocus={this.onFocusChange.bind(this, true)}
                    onBlur={this.onFocusChange.bind(this, false)}
                    disabled={this.props.disabled}
                    {...this.props.inputFieldProps}
                />
                {this.props.clearable && this.props.value && (
                    <div className="sten-input__item sten-input__item--clickable" onClick={this.clearValue}>
                        <span key="clearable" className={clearIconClass} />
                    </div>
                )}
                {!this.props.disabled && this.props.showKeyboard && (
                    <div className="sten-input__item sten-input__item--clickable" onClick={this.showKeyboard}>
                        <span key="showkeyboard" className={keyboardIconClass} />
                    </div>
                )}
                {suffix && (
                    <div
                        key="suffix"
                        className={`sten-input__item${this.props.onSuffixClick ? ' sten-input__item--clickable' : ''}`}
                        onClick={suffixClickHandler}
                    >
                        {suffix}
                    </div>
                )}
            </div>
        );
    }
}

Input.propTypes = {
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    autoFocusDelay: PropTypes.number,
    changeDebounce: PropTypes.number,
    className: PropTypes.string,
    clearable: PropTypes.bool,
    disabled: PropTypes.bool,
    displayValue: PropTypes.string,
    id: PropTypes.string,
    inputFieldProps: PropTypes.objectOf(PropTypes.any),
    invalid: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    onClick: PropTypes.func,
    onFocusChange: PropTypes.func,
    onPrefixClick: PropTypes.func,
    onScreenKeyboardEnabled: PropTypes.bool,
    onSuffixClick: PropTypes.func,
    placeholder: PropTypes.string,
    prefix: PropTypes.node,
    prefixIcon: PropTypes.string,
    readOnly: PropTypes.bool,
    showKeyboard: PropTypes.bool,
    suffix: PropTypes.node,
    suffixIcon: PropTypes.string,
    type: PropTypes.string,
    unit: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    warning: PropTypes.bool,
    title: PropTypes.string
};

Input.defaultProps = {
    autoComplete: undefined,
    autoFocus: false,
    autoFocusDelay: 500,
    changeDebounce: 50,
    className: '',
    clearable: false,
    disabled: false,
    displayValue: undefined,
    id: undefined,
    inputFieldProps: null,
    invalid: false,
    name: '',
    onChange: undefined,
    onClear: undefined,
    onClick: undefined,
    onFocusChange: undefined,
    onPrefixClick: undefined,
    onScreenKeyboardEnabled: false,
    onSuffixClick: undefined,
    placeholder: '',
    prefix: '',
    prefixIcon: '',
    readOnly: false,
    showKeyboard: false,
    suffix: '',
    suffixIcon: '',
    type: 'text',
    unit: '',
    value: '',
    warning: false,
    title: ''
};

function mapStateToProps(state) {
    return {
        onScreenKeyboardEnabled: state.userReducer.settings && state.userReducer.settings.OnScreenKeyboardEnabled
    };
}

export default connect(mapStateToProps, null, null, { withRef: true })(Input);
