import { sortByProp } from 'utils/helpers/array-helper';

export const convertFuelData = (initialData, allFuelTypesMap) => {
    const fuelTypes = [];
    const fuelTypeMap = {};
    const rows = initialData.map(item => {
        const FuelTypeQuantities = {};
        item.FuelTypeQuantities.forEach(fuelType => {
            FuelTypeQuantities[fuelType.Id] = {
                ...fuelType,
                Quantity: allFuelTypesMap?.[fuelType.Id]?.Coefficient
                    ? fuelType.Quantity / allFuelTypesMap[fuelType.Id].Coefficient
                    : fuelType.Quantity
            };
            if (!fuelTypeMap[fuelType.Id]) {
                fuelTypeMap[fuelType.Id] = true;
                fuelTypes.push({
                    Id: fuelType.Id,
                    Name: fuelType.Name,
                    Sequence: fuelType.Sequence
                });
            }
        });
        return { ...item, FuelTypeQuantities };
    });

    fuelTypes.sort(sortByProp('Sequence'));

    return { fuelTypes, rows };
};

export const parseFuelData = (data, allFuelTypesMap) => {
    const parsedData = [];
    data.rows.forEach(item => {
        const FuelTypeQuantities = [];
        Object.keys(item.FuelTypeQuantities).forEach(id => {
            if (item.FuelTypeQuantities[id].Quantity !== null) {
                FuelTypeQuantities.push({
                    ...item.FuelTypeQuantities[id],
                    Quantity: allFuelTypesMap?.[id]?.Coefficient
                        ? parseFloat(item.FuelTypeQuantities[id].Quantity) * allFuelTypesMap[id].Coefficient
                        : parseFloat(item.FuelTypeQuantities[id].Quantity)
                });
            }
        });
        if (FuelTypeQuantities.length) {
            parsedData.push({
                ...item,
                FuelTypeQuantities
            });
        }
    });

    return parsedData;
};
