import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* styles */
import './loader.scss';
import loaderOuterSrc from 'assets/images/loader/loader_outer_circle.svg';
import loaderInnerSrc from 'assets/images/loader/loader_inner_circle.svg';

export class Loader extends React.PureComponent {
    static content = (
        <div className="sten-loader">
            <div className="sten-loader__segments">
                <img
                    src={loaderOuterSrc}
                    className="sten-loader__segment sten-loader__segment--outer"
                    role="presentation"
                />
                <img
                    src={loaderInnerSrc}
                    className="sten-loader__segment sten-loader__segment--inner"
                    role="presentation"
                />
            </div>
        </div>
    );

    render() {
        if (!this.props.isLoaderShown && !this.props.forceLoaderShown) {
            return null;
        }
        return Loader.content;
    }
}

Loader.propTypes = {
    isLoaderShown: PropTypes.bool.isRequired,
    forceLoaderShown: PropTypes.bool
};

Loader.defaultProps = {
    forceLoaderShown: false
};

function mapStateToProps(state) {
    return {
        isLoaderShown: state.loaderReducer.isLoaderShown
    };
}

export default connect(mapStateToProps)(Loader);
