export default {
    activeSideBarMapCenter: [8800000, 4800000],
    route: {
        trading: {
            color: 'rgb(40,255,202)',
            width: 3,
            lineDash: [6, 12],
            lineDashMaxZoom: 12,
            labelColor: 'rgb(40,255,202)',
            zIndex: 10
        },
        tradingSelected: {
            color: 'rgba(40,255,202,0.4)',
            width: 8,
            zIndex: 20
        }
    },
    tradingPort: {
        primaryColor: 'rgb(255,255,255)',
        zIndex: 30
    },
    bunkerPort: {
        primaryColor: 'rgb(40,255,202)',
        secondaryColor: 'rgb(40,255,202)',
        zIndex: 40
    },
    heatMap: {
        zIndex: 40,
        gradient: ['#0000ff', '#00ffff', '#0c9c12', '#f4d403', '#ff3f3f']
    },
    nautical: {
        zIndex: 10
    }
};
