import { ActionTypes } from './terminal-info-actions';

const emptyArray = [];

const initialState = {
    isMounted: false,
    terminal: null,
    comments: emptyArray,
    berths: emptyArray
};

export default function terminalInfoReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.TERMINAL_INFO_SET_RESOURCES: {
        return {
            ...state,
            isMounted: true,
            terminal: action.terminal,
            comments: action.comments || emptyArray,
            berths: action.berths || emptyArray
        };
    }
    case ActionTypes.TERMINAL_INFO_SET_BERTHS: {
        return {
            ...state,
            berths: action.berths || emptyArray
        };
    }
    case ActionTypes.TERMINAL_INFO_UPDATED: {
        if (state.isMounted && action.terminal) {
            return {
                ...state,
                terminal: action.terminal
            };
        }
        return state;
    }
    case ActionTypes.TERMINAL_INFO_SET_COMMENTS: {
        return {
            ...state,
            comments: action.comments || emptyArray
        };
    }
    case ActionTypes.TERMINAL_INFO_RESET: {
        return initialState;
    }
    default:
        return state;
    }
}
