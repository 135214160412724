import React from 'react';
import PropTypes from 'prop-types';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* components */
import Accordion from 'components/accordion/accordion';
import ScrollArea from 'components/scroll-area/scroll-area';

export class PortInfoTerminals extends React.PureComponent {
    state = {
        expandedAccordionId: null
    };

    componentDidUpdate() {
        if (!this.props.router.query.terminalsShown) {
            this.props.router.updateQueryParams({ add: { terminalsShown: true } });
        }
    }

    componentWillUnmount() {
        if (this.props.router.query.terminalsShown) {
            this.props.router.updateQueryParams({ remove: ['terminalsShown'] });
        }
    }

    handleTerminalAddClick = () => this.props.router.updateQueryParams({ add: { terminalAddPort: this.props.portId } });

    getBerthAddClickHandler = (terminalId) => () => {
        this.props.router.updateQueryParams({ add: { berthAddTerminal: terminalId } });
    };

    getTerminalDetailsClickHandler = (terminalId) => (e) => {
        e.stopPropagation();
        this.props.router.updateQueryParams({ add: { terminal: terminalId } });
    };

    getBerthDetailsClickHandler = (berthId) => (e) => {
        e.stopPropagation();
        this.props.router.updateQueryParams({ add: { berth: berthId } });
    };

    getToggleCollapseHandler = (terminalId) => () => {
        this.setState({
            expandedAccordionId: terminalId === this.state.expandedAccordionId ? null : terminalId
        });
    };

    renderTerminalHeader = (terminal) => (
        <div className="flex-grow flex-row flex-center">
            <h4 className="flex-grow">
                {terminal.TerminalName}
                {terminal.Berths.length > 0
                    ? <span className="text-secondary">{` (${terminal.Berths.length})`}</span>
                    : ''}
            </h4>
            <div className="flex-shrink">
                <button
                    className="btn-link icon icon-details"
                    title={t('TERMINAL.TERMINAL_INFO')}
                    onClick={this.getTerminalDetailsClickHandler(terminal.TerminalId)}
                />
            </div>
        </div>
    );

    render() {
        return (
            <ScrollArea>
                {this.props.permissions.AddUpdateTerminal && (
                    <div className="sten-content__section">
                        <button className="sten-link text-uppercase" onClick={this.handleTerminalAddClick}>
                            <span className="btn__icon icon icon-plus" />
                            {t('TERMINAL.ADD_NEW_TERMINAL')}
                        </button>
                    </div>
                )}
                {this.props.terminals.map(terminal => (
                    <Accordion
                        key={terminal.TerminalId}
                        header={this.renderTerminalHeader(terminal)}
                        isCollapsed={terminal.TerminalId !== this.state.expandedAccordionId}
                        isDisabled={!this.props.permissions.UpdateBerth && terminal.Berths.length === 0}
                        onToggleCollapse={this.getToggleCollapseHandler(terminal.TerminalId)}
                    >
                        {this.props.permissions.UpdateBerth && (
                            <div className="sten-content__section">
                                <button
                                    className="sten-link text-uppercase"
                                    onClick={this.getBerthAddClickHandler(terminal.TerminalId)}
                                >
                                    <span className="btn__icon icon icon-plus" />
                                    {t('BERTH.ADD_NEW_BERTH')}
                                </button>
                            </div>
                        )}
                        {terminal.Berths.map(berth => (
                            <div className="sten-content__section sten-content__separator" key={berth.Id}>
                                <div className="flex-grow flex-row flex-center">
                                    <h5 className="flex-grow">{berth.Name}</h5>
                                    <div className="flex-shrink">
                                        <button
                                            className="btn-link icon icon-details"
                                            title={t('BERTH.BERTH_INFO')}
                                            onClick={this.getBerthDetailsClickHandler(berth.Id)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="sten-content__separator" />
                    </Accordion>
                ))}
            </ScrollArea>
        );
    }
}

PortInfoTerminals.propTypes = {
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    portId: PropTypes.number,
    router: TRouter.isRequired,
    terminals: PropTypes.arrayOf(PropTypes.any).isRequired
};

PortInfoTerminals.defaultProps = {
    portId: null
};

export default withRouter(PortInfoTerminals);
