import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
/* router */
import { appRoutes } from 'app-router';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { sortByProp } from 'utils/helpers/array-helper';
/* components */
import EditableList from '../editable-list/admin-company-management-editable-list';
/* services */
import CompanyManagementService from 'services/core-api/company-management-service';
/* constants */
import { activeStatusIds } from '../../admin-user-management/admin-user-management-constants';

const t = (key, props, pluralize) => translate(`ADMIN.COMPANY_MANAGEMENT.DEPARTMENTS.${key}`, props, pluralize);

const emptyArray = [];

class AdminCompanyManagementDepartments extends React.PureComponent {
    state = {
        departments: emptyArray,
        updatedDepartmentIndex: null,
        userCountMessage: null
    };

    componentDidMount() {
        CompanyManagementService.Departments.get().then(res => this.setState({ departments: res || emptyArray }));
    }

    handleUpdate = (department) => {
        this.setState({ updatedDepartmentIndex: null });
        const { departments } = this.state;
        let newDepartments;
        if (department.Id) {
            CompanyManagementService.Departments.update(department.Id, department).then((res) => {
                if (res) {
                    newDepartments = departments.map(department => (department.Id === res.Id ? res : department))
                        .sort(sortByProp('Name'));
                    this.setState({
                        departments: newDepartments,
                        updatedDepartmentIndex: newDepartments.findIndex(department => department.Id === res.Id)
                    });
                    toast(t('SUCCESSFULLY_UPDATED'), { type: toast.TYPE.SUCCESS });
                }
            });
        } else {
            CompanyManagementService.Departments.add(department).then((res) => {
                if (res) {
                    newDepartments = [...departments, res].sort(sortByProp('Name'));
                    this.setState({
                        departments: newDepartments,
                        updatedDepartmentIndex: newDepartments.findIndex(department => department.Id === res.Id)
                    });
                    toast(t('SUCCESSFULLY_ADDED'), { type: toast.TYPE.SUCCESS });
                }
            });
        }
    };

    handleRemove = (department) => {
        CompanyManagementService.Departments.delete(department.Id).then((res) => {
            if (res) {
                this.setState({
                    departments: this.state.departments.filter(dep => dep.Id !== department.Id),
                    updatedDepartmentIndex: null
                });
                toast(t('SUCCESSFULLY_DELETED'), { type: toast.TYPE.SUCCESS });
            }
        });
    };

    getUserCount = (department, callback) => {
        CompanyManagementService.Departments.getUserCountById(department.Id).then((res) => {
            if (res.ConnectedUsers) {
                const viewUsersURL = {
                    pathname: appRoutes.Admin.UserManagement,
                    search: `department=${department.Id}&statuses=${activeStatusIds}`
                };
                this.setState({
                    userCountMessage: (
                        <React.Fragment>
                            {t('DELETE_USERS', {
                                count: res.ConnectedUsers,
                                name: department.Name
                            }, true)}
                            <Link to={viewUsersURL} className="sten-link">
                                {t('VIEW_USERS', { count: res.ConnectedUsers }, true)}
                            </Link>
                        </React.Fragment>
                    )
                });
            }
            callback();
        });
    };

    handleModeChange = (mode, department, callback) => {
        if (department && mode === 'remove') {
            this.getUserCount(department, callback);
        } else {
            if (this.state.userCountMessage) {
                this.setState({ userCountMessage: null });
            }
            callback();
        }
    };

    render() {
        return (
            <div className="sten-panel sten-admin-company-management-departments">
                <div className="sten-panel__header flex">
                    <h4 className="text-uppercase flex-grow">{t('TITLE')}</h4>
                </div>
                <EditableList
                    addBtnLabel={t('ADD')}
                    isRemovable={!!this.state.userCountMessage}
                    items={this.state.departments}
                    onItemRemove={this.handleRemove}
                    onItemUpdate={this.handleUpdate}
                    onModeChange={this.handleModeChange}
                    removeConfirmMessage={this.state.userCountMessage || t('DELETE_CONFIRM')}
                    updatedItemIndex={this.state.updatedDepartmentIndex}
                />
            </div>
        );
    }
}

export default AdminCompanyManagementDepartments;
