import { createSelector } from 'reselect';
import { createByIdSelector, createCategoryChartDataSelector } from 'utils/helpers/selector-helper';

export const getActiveAlertsByIMO = createByIdSelector(state => state.fleetDashboardReducer.notifications, 'IMO', true);

export const vesselsByIMO = createByIdSelector(state => state.fleetDashboardReducer.vessels, 'Imo');

export const getVesselsWithAlerts = createSelector(
    state => state.fleetDashboardReducer.vesselPositions,
    getActiveAlertsByIMO,
    vesselsByIMO,

    (vesselPositions, alerts, vesselsByImo) => vesselPositions.map(
        vesselPosition =>
            ({
                ...vesselPosition,
                LatestAlert: alerts[vesselPosition.IMO] || null,
                VoyageId: vesselsByImo[vesselPosition.IMO] ? vesselsByImo[vesselPosition.IMO].VoyageId : null
            })
    )
);

const earningsSeriesKeys = ['Tce', 'TceIndex', 'FixRate'];

export const getEarningsChartData = createCategoryChartDataSelector(
    state => (state.fleetDashboardReducer.earnings
        ? state.fleetDashboardReducer.earnings.Graph
        : null),
    earningsSeriesKeys
);

export const modelsById = createByIdSelector(state => state.fleetDashboardReducer.models, 'Id');

export const getSelectedModel = createSelector(
    state => state.userReducer.settings.MarketIntelModelNameId,
    modelsById,

    (userSavedModelId, allModels) => {
        if (userSavedModelId && allModels && allModels[userSavedModelId]) {
            return allModels[userSavedModelId];
        }
        if (allModels && allModels.length > 1) {
            return allModels[1];
        }
        return null;
    }
);

const fleetsById = createByIdSelector(state => state.fleetDashboardReducer.fleets, 'Id');
export const getSelectedFleet = createSelector(
    state => state.userReducer.settings.PoolDashboardFleetId,
    fleetsById,

    (userSavedFleetId, fleets) => {
        if (userSavedFleetId && fleets && fleets[userSavedFleetId]) {
            return fleets[userSavedFleetId];
        }
        return null;
    }
);

export const voyageOptionsById = createByIdSelector(state => state.fleetDashboardReducer.voyageOptions, 'Id');

export const getSelectedVoyageOption = createSelector(
    state => state.userReducer.settings.PoolDashboardOption,
    voyageOptionsById,

    (voyageOptionId, voyageOptions) => {
        if (voyageOptionId && voyageOptions && voyageOptions[voyageOptionId]) {
            return voyageOptions[voyageOptionId];
        }
        if (voyageOptions && voyageOptions.length > 1) {
            return voyageOptions[1];
        }
        return null;
    }
);
