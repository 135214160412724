import { ActionTypes } from './vessel-info-actions';

const emptyArray = [];

const initialState = {
    activeTabId: 0,
    rangeOptions: emptyArray
};

export default function vesselInfoReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.VESSEL_INFO_SET_ACTIVE_TAB: {
        return {
            ...state,
            activeTabId: action.activeTabId
        };
    }
    case ActionTypes.VESSEL_INFO_SET_RANGE_OPTIONS: {
        return {
            ...state,
            rangeOptions: action.rangeOptions
        };
    }
    default:
        return state;
    }
}
