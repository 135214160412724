export const summaryTranslationKeys = {
    AverageAux: 'AVERAGE_AUXILIARY',
    AverageCargo: 'AVERAGE_CARGO',
    AverageCargoIncluded: 'AVERAGE_CARGO_INCLUDED',
    AverageConsumption: 'AVERAGE_CONSUMPTION',
    AverageSpeed: 'AVERAGE_SPEED',
    CorrectedConsumption: 'CORRECTED_CONSUMPTION',
    CorrectionForCargo: 'CORRECTION_FOR_CARGO',
    CorrectionForCargoIncluded: 'CORRECTION_FOR_CARGO_INCLUDED',
    CorrectionForSpeed: 'CORRECTION_FOR_SPEED',
    DischargeConsumption: 'DISCHARGE_CONSUMPTION',
    LoadConsumption: 'LOAD_CONSUMPTION',
    RefCargoSizeDifferentThan: 'REFERENCE_CARGO',
    RefSpeedDifferentThan: 'REFERENCE_SPEED',
    TotalDays: 'TOTAL_DAYS'
};

export const ballastSummaryKeys = [
    'TotalDays',
    'RefSpeedDifferentThan',
    'AverageSpeed',
    'AverageConsumption',
    'CorrectedConsumption',
    'LoadConsumption',
    'AverageAux'
];

export const ladenSummaryKeys = [
    'TotalDays',
    'RefSpeedDifferentThan',
    'RefCargoSizeDifferentThan',
    'AverageSpeed',
    'AverageConsumption',
    'CorrectionForSpeed',
    'AverageCargo',
    'CorrectionForCargo',
    'AverageCargoIncluded',
    'DischargeConsumption',
    'CorrectionForCargoIncluded'
];

export const summaryKeyToFormatMap = {
    TotalDays: { type: 'number', numFmt: '0.00' },
    RefSpeedDifferentThan: { type: 'number', numFmt: '0.0 "kn"' },
    AverageSpeed: { type: 'number', numFmt: '0.0 "kn"' },
    AverageConsumption: { type: 'number', numFmt: '0.0 "mt/day"' },
    CorrectedConsumption: { type: 'number', numFmt: '0.0 "mt/day"' },
    LoadConsumption: { type: 'number', numFmt: '0.0 "mt"' },
    AverageAux: { type: 'number', numFmt: '0.0 "mt/day"' },
    RefCargoSizeDifferentThan: { type: 'number', numFmt: '# "mt"' },
    CorrectionForSpeed: { type: 'number', numFmt: '0.0 "mt/day"' },
    AverageCargo: { type: 'number', numFmt: '0.0 "mt"' },
    CorrectionForCargo: { type: 'number', numFmt: '0.0 "mt"' },
    AverageCargoIncluded: { type: 'number', numFmt: '0.0 "mt"' },
    DischargeConsumption: { type: 'number', numFmt: '0.0 "mt"' },
    CorrectionForCargoIncluded: { type: 'number', numFmt: '0.0' }
};

export const keyToUnitMap = {
    AverageAux: 'mt/day',
    AverageCargo: 'mt',
    AverageCargoIncluded: 'mt',
    AverageConsumption: 'mt/day',
    AverageSpeed: 'kn',
    CorrectedConsumption: 'mt/day',
    CorrectionForCargo: 'mt',
    CorrectionForSpeed: 'mt/day',
    DischargeConsumption: 'mt',
    LoadConsumption: 'mt',
    RefCargoSizeDifferentThan: 'mt',
    RefSpeedDifferentThan: 'kn',
    CorrectionForCargoIncluded: 'mt'
};

export const keyToNumberOfDecimalsMap = {
    TotalDays: 2,
    RefCargoSizeDifferentThan: 0
};

export const ballastKeys = [
    'InstructedSpeeds',
    'TotalTime',
    'TotalDistance',
    'TotalMECons',
    'AvgMEConsPerDay',
    'AvgSpeed',
    'TotalAuxCons'
];

export const ladenKeys = [
    'CargoOnboard',
    'CargoHeated',
    'InstructedSpeeds',
    'TotalTime',
    'TotalDistance',
    'TotalMECons',
    'AvgMEConsPerDay',
    'AvgSpeed',
    'TotalAuxCons'
];

export const keyToFormatMap = {
    ExclusionReason: { type: 'string' },
    InstructedSpeeds: { type: 'string' },
    TotalTime: { type: 'number', numFmt: '0.0' },
    TotalDistance: { type: 'number', numFmt: '0.0' },
    TotalMECons: { type: 'number', numFmt: '0.0' },
    AvgMEConsPerDay: { type: 'number', numFmt: '0.0' },
    AvgSpeed: { type: 'number', numFmt: '0.0' },
    TotalAuxCons: { type: 'number', numFmt: '0.0' },
    TotalCons: { type: 'number', numFmt: '0.0' },
    CargoOnboard: { type: 'number', numFmt: '0.0' },
    CargoHeated: { type: 'string' }
};
