import React from 'react';
import PropTypes from 'prop-types';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';

export class TerminalInfoBerths extends React.PureComponent {
    handleBerthAddClick = () => this.props.router.updateQueryParams({
        add: { berthAddTerminal: this.props.terminalId }
    });

    getBerthDetailsClickHandler = (berthId) => (e) => {
        e.stopPropagation();
        this.props.router.updateQueryParams({ add: { berth: berthId } });
    };

    render() {
        return (
            <ScrollArea className="sten-port-info-terminals">
                {this.props.permissions.UpdateBerth && (
                    <div className="sten-content__section">
                        <button className="sten-link text-uppercase" onClick={this.handleBerthAddClick}>
                            <span className="btn__icon icon icon-plus" />
                            {t('BERTH.ADD_NEW_BERTH')}
                        </button>
                    </div>
                )}
                {this.props.berths.map(berth => (
                    <div className="sten-content__section sten-content__separator" key={berth.BerthId}>
                        <div className="flex-grow flex-row flex-center">
                            <h5 className="flex-grow">{berth.BerthName}</h5>
                            <div className="flex-shrink">
                                <button
                                    className="btn-link icon icon-details"
                                    title={t('BERTH.BERTH_INFO')}
                                    onClick={this.getBerthDetailsClickHandler(berth.BerthId)}
                                />
                            </div>
                        </div>
                    </div>
                ))}
                <div className="sten-content__separator" />
            </ScrollArea>
        );
    }
}

TerminalInfoBerths.propTypes = {
    berths: PropTypes.arrayOf(PropTypes.any).isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    router: TRouter.isRequired,
    terminalId: PropTypes.number.isRequired
};

export default withRouter(TerminalInfoBerths);
