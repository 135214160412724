import React from 'react';
import {
    Navigate,
    Route,
    createBrowserRouter,
    createRoutesFromElements
} from 'react-router-dom';
/* router */
import appRoutes from './router/app-routes';
import { TRouter, withRouter } from './router/with-router';
import { getSearchFromQuery, getQueryFromSearch } from './router/router-helpers';
/* pages */
import AdminCompanyManagement from 'pages/user-pages/admin/admin-company-management/admin-company-management';
import AdminUserManagement from 'pages/user-pages/admin/admin-user-management/admin-user-management';
import AdminVesselManagement from 'pages/user-pages/admin/admin-vessel-management/admin-vessel-management';
import AuthPostLogout from 'components/auth/auth-post-logout';
import AuthSignInRedirect from 'components/auth/auth-signin-redirect';
import AuthSilentRedirect from 'components/auth/auth-silent-redirect';
import ContactList from 'pages/user-pages/contact-list/contact-list';
import DistanceCalculatorPage from 'pages/user-pages/distance-calculator-page/distance-calculator-page';
import EmissionEstimatorPage from 'pages/user-pages/emission-estimator-page/emission-estimator-page';
import EnergyManagementKPI from 'pages/user-pages/energy-management/kpi/kpi';
import EnergyManagementKPIOverview from 'pages/user-pages/energy-management/kpi/overview/overview';
import EnergyManagementKPISegment from 'pages/user-pages/energy-management/kpi/segment/segment';
import EnergyManagementKPIVessel from 'pages/user-pages/energy-management/kpi/vessel/vessel';
import EnergyManagementReports from 'pages/user-pages/energy-management/reports/reports';
import FleetDashboard from 'pages/user-pages/fleet-dashboard/fleet-dashboard';
import FleetManagement from 'pages/user-pages/fleet-management/fleet-management';
import Gsm from 'pages/gsm/gsm';
import HomePage from 'pages/user-pages/home/home';
import Inspections from 'pages/user-pages/inspections/inspections';
import MarketIntel from 'pages/user-pages/market-overview/market-intel/market-intel';
import OperatorsDiary from 'pages/user-pages/operators-diary/operators-diary';
import PortCallsAnalytics from 'pages/user-pages/market-overview/port-calls-analytics/port-calls-analytics';
import PortManagement from 'pages/user-pages/port-management/port-management';
import PositionList from 'pages/user-pages/position-list/position-list';
import RouteRendererPage from 'pages/route-renderer/route-renderer';
import TestPage from 'pages/user-pages/test/test';
import UnauthorizedAccess from 'components/auth/unauthorized-access';
import User from 'pages/user-pages/user';
import VoyagePerformance from 'pages/user-pages/voyage-performance/voyage-performance';
import WeatherRoutingPage from 'pages/user-pages/weather-routing/weather-routing';
import WeatherRoutingLeg from 'pages/user-pages/weather-routing/leg/weather-routing-leg';
import WeatherRoutingOverview from 'pages/user-pages/weather-routing/overview/weather-routing-overview';
/* components */
import AuthWrapper from 'components/auth/auth-wrapper';
import Wrapper from 'components/wrapper/wrapper';

/* Route setup */
export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<Wrapper />}>
            <Route path="authorization/post-logout" element={<AuthPostLogout />} />
            <Route path="authorization/silent-redirect" element={<AuthSilentRedirect />} />
            <Route path="authorization/signin-redirect" element={<AuthSignInRedirect />} />
            <Route path="authorization/unauthorized-access" element={<UnauthorizedAccess />} />
            <Route
                path="gsm"
                element={(
                    <Gsm
                        queryParamsMap={{
                            tanker: 'number',
                            ferry: 'number',
                            drill: 'number',
                            city: 'number'
                        }}
                    />
                )}
            >
                <Route path=":type" />
            </Route>
            <Route
                path="route-renderer"
                element={(
                    <RouteRendererPage
                        queryParamsMap={{
                            voyageId: 'number',
                            mapTypeId: 'number',
                            countryBordersNamesEnabled: 'bool'
                        }}
                    />
                )}
            />
            <Route element={<AuthWrapper />}>
                <Route element={<User />}>
                    <Route
                        path="map"
                        element={(
                            <HomePage
                                queryParamsMap={{
                                    berth: 'number',
                                    berthAddTerminal: 'number',
                                    berthUpdate: 'number',
                                    cpVoyage: 'number',
                                    cpEdit: 'bool',
                                    notificationSettings: 'bool',
                                    port: 'number',
                                    questionnaireEdit: 'bool',
                                    questionnaireId: 'number',
                                    reportEdit: 'bool',
                                    reportId: 'number',
                                    reportType: 'number',
                                    terminal: 'number',
                                    terminalAddPort: 'number',
                                    terminalsShown: 'bool',
                                    terminalUpdate: 'number',
                                    vessel: 'number',
                                    voyage: 'number'
                                }}
                            />
                        )}
                    />
                    <Route path="vessels">
                        <Route path="position-list" element={<PositionList />} />
                        <Route path="fleet-management" element={<FleetManagement />}>
                            <Route path="new" />
                            <Route path="edit/:id" />
                            <Route path=":id" />
                            <Route path="*" element={<Navigate replace to={appRoutes.Vessel.FleetManagement} />} />
                        </Route>
                        <Route path="inspections" element={<Inspections />}>
                            <Route path="new" />
                            <Route path="edit/:id" />
                            <Route path="*" element={<Navigate replace to={appRoutes.Vessel.Inspections} />} />
                        </Route>
                        <Route path="operators-diary" element={<OperatorsDiary />}>
                            <Route path=":id" />
                            <Route path="edit/:id" />
                            <Route path="*" element={<Navigate replace to={appRoutes.Vessel.OperatorsDiary} />} />
                        </Route>
                        <Route
                            path="voyage-performance"
                            element={(
                                <VoyagePerformance
                                    queryParamsMap={{
                                        vessel: 'number',
                                        voyage: 'number',
                                        rawReportsType: 'string',
                                        reportEdit: 'bool',
                                        reportId: 'number',
                                        reportType: 'number'
                                    }}
                                />
                            )}
                        />
                        <Route index path="*" element={<Navigate replace to={appRoutes.Vessel.FleetManagement} />} />
                    </Route>
                    <Route path="port-management" element={<PortManagement />}>
                        <Route path="settings" />
                        <Route path="new" />
                        <Route path="edit/:id" />
                        <Route path=":id" />
                        <Route path="*" element={<Navigate replace to={appRoutes.PortManagement} />} />
                    </Route>
                    <Route path="energy-management">
                        <Route
                            path="reports"
                            element={(
                                <EnergyManagementReports
                                    queryParamsMap={{
                                        cpEdit: 'bool',
                                        cpVoyage: 'number',
                                        emReportType: 'number',
                                        emVesselImo: 'number',
                                        emVoyageId: 'number',
                                        reportEdit: 'bool',
                                        reportId: 'number',
                                        reportType: 'number'
                                    }}
                                />
                            )}
                        />
                        <Route path="kpi" element={<EnergyManagementKPI />}>
                            <Route
                                index
                                element={<EnergyManagementKPIOverview queryParamsMap={{ advanced: 'bool' }} />}
                            />
                            <Route path="segment/:segmentId">
                                <Route
                                    index
                                    element={<EnergyManagementKPISegment queryParamsMap={{ advanced: 'bool' }} />}
                                />
                                <Route
                                    path="vessel/:vesselId"
                                    element={<EnergyManagementKPIVessel queryParamsMap={{ advanced: 'bool' }} />}
                                />
                            </Route>
                            <Route
                                path="vessel/:vesselId"
                                element={<EnergyManagementKPIVessel queryParamsMap={{ advanced: 'bool' }} />}
                            />
                            <Route path="*" element={<Navigate replace to={appRoutes.EnergyManagement.KPI} />} />
                        </Route>
                        <Route path="*" index element={<Navigate replace to={appRoutes.EnergyManagement.Reports} />} />
                    </Route>
                    <Route path="market-overview">
                        <Route path="market-intel" element={<MarketIntel queryParamsMap={{ routeCode: 'string' }} />} />
                        <Route path="port-calls-analytics" element={<PortCallsAnalytics />} />
                        <Route
                            index
                            path="*"
                            element={<Navigate replace to={appRoutes.MarketOverview.MarketIntel} />}
                        />
                    </Route>
                    <Route path="contact-list" element={<ContactList />} />
                    <Route path="fleet-dashboard" element={<FleetDashboard />} />
                    <Route path="routing">
                        <Route
                            path="distance-calculator"
                            element={
                                <DistanceCalculatorPage queryParamsMap={{ deviation: 'bool', vessel: 'number' }} />
                            }
                        />
                        <Route
                            path="weather-routing"
                            element={(
                                <WeatherRoutingPage
                                    queryParamsMap={{
                                        showCounterPvar: 'bool',
                                        cpEdit: 'bool',
                                        cpVoyage: 'number',
                                        requestEdit: 'bool',
                                        requestId: 'number',
                                        voyage: 'number',
                                        vessel: 'number'
                                    }}
                                />
                            )}
                        >
                            <Route index element={<WeatherRoutingOverview />} />
                            <Route path=":legId" element={<WeatherRoutingLeg />} />
                            <Route path="*" element={<Navigate replace to={appRoutes.Routing.WeatherRouting} />} />
                        </Route>
                        <Route
                            index
                            path="*"
                            element={<Navigate replace to={appRoutes.Routing.DistanceCalculator} />}
                        />
                    </Route>
                    <Route
                        path="emission-estimator"
                        element={<EmissionEstimatorPage queryParamsMap={{ deviation: 'bool', vessel: 'number' }} />}
                    />
                    <Route path="admin">
                        <Route path="company-management" element={<AdminCompanyManagement />}>
                            <Route path="company/new" />
                            <Route path="company/edit/:id" />
                            <Route path="*" element={<Navigate replace to={appRoutes.Admin.CompanyManagement} />} />
                        </Route>
                        <Route path="user-management" element={<AdminUserManagement />}>
                            <Route path="new" />
                            <Route path="edit/:id" />
                            <Route path="*" element={<Navigate replace to={appRoutes.Admin.UserManagement} />} />
                        </Route>
                        <Route
                            path="vessel-management"
                            element={<AdminVesselManagement queryParamsMap={{ fleet: 'number' }} />}
                        >
                            <Route path="new" />
                            <Route path="edit/:id" />
                            <Route path="*" element={<Navigate replace to={appRoutes.Admin.VesselManagement} />} />
                        </Route>
                    </Route>
                    <Route path="test" element={<TestPage />} />
                    <Route
                        path="distance-calculator"
                        element={<Navigate replace to={appRoutes.Routing.DistanceCalculator} />}
                    />
                    <Route
                        path="*"
                        element={<Navigate replace to={localStorage.getItem('homePage') || appRoutes.Map} />}
                    />
                </Route>
            </Route>
            <Route path="*" element={<Navigate replace to={appRoutes.Map} />} />
        </Route>
    ),
    { basename: '/' }
);

export const navigate = (route, options) => {
    const to = typeof route === 'string'
        ? route
        : {
            pathname: route.pathname || router.state.location.pathname,
            search: getSearchFromQuery(route.query) || route.search || ''
        };
    if (to.pathname !== router.state.location.pathname || to.search !== router.state.location.search) {
        return router.navigate(to, options);
    }
    return Promise.resolve();
};

export const getLinkTo = ({ remove = null, add = null, pathname = null, query: q = null }) => {
    let newQuery = q
        ? { ...q }
        : { ...getQueryFromSearch(router.state.location.search) };
    if (remove && Array.isArray(remove)) {
        remove.forEach((param) => {
            if (newQuery[param]) {
                delete newQuery[param];
            }
        });
    }
    if (add) {
        newQuery = { ...newQuery, ...add };
    }
    return {
        pathname: pathname || location.pathname,
        search: getSearchFromQuery(newQuery)
    };
};

export const updateQueryParams = (props) => {
    return navigate(getLinkTo(props));
};

export { appRoutes, withRouter, TRouter, getSearchFromQuery, getQueryFromSearch };
