/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
import { setObjectProp, getObjectProp, deleteObjectProp, compareVersions } from 'utils/helpers/info-helper';
import { mapArrayByValue } from 'utils/helpers/array-helper';
import { translate } from 'utils/i18n/i18n-model';
/* constants */
import {
    defaultValidationsByType,
    validationTypeMap as vtm,
    ruleSetTypeMap as rtm
} from './vessel-report-validations';

const emptyArray = [];

// eslint-disable-next-line max-len
export const fieldConfigRegex = /^(?:d\[((?:\d{1,2}(?:,(?=\d)|\b))+)\])?(?:s\[((?:\d{1,2}(?:,(?=\d)|\b))+)\])?(?:v\[((?:(?:\d+\.\d+\.\d+\b)(?:-\d+\.\d+\.\d+\b)?(?:,(?=\d)|\b))+)\])?$/;

export const createDSST = (DataSourceId, SubTypeId) => `${DataSourceId}-${SubTypeId || 1}`;

export function extractItemFuelData(data, fuelTypes) {
    const res = { FuelTypes: fuelTypes || [], FuelQuantityMap: {} };
    data.forEach(fuelType => {
        res.FuelQuantityMap[fuelType.Id] = fuelType;
        if (!fuelTypes) {
            res.FuelTypes.push({ Id: fuelType.Id, Name: fuelType.Name, Sequence: fuelType.Sequence });
        }
    });

    return res;
}

function getFuelTypeQuantities(item) {
    const FuelTypeQuantities = {};
    item.FuelTypeQuantities.forEach(fuelType => {
        FuelTypeQuantities[fuelType.Id] = fuelType;
    });
    return FuelTypeQuantities;
}


export function extractReportFuelData(report, availableFuelTypes = emptyArray) {
    const parsedData = {};
    const keys = [
        'BunkerData',
        'Consumptions',
        'ConsumptionDuringStoppage',
        'ConsumptionFromNoonToEvent',
        'ConsumptionsSinceLastEvent',
        'DischargeOperations'
    ];
    keys.forEach(key => {
        if (report[key]) {
            parsedData[key] = {};
            report[key].forEach(item => {
                parsedData[key][item.Id] = {
                    ...item,
                    FuelTypeQuantities: getFuelTypeQuantities(item)
                };
            });
        }
    });

    return {
        fuelTypes: availableFuelTypes,
        parsedData
    };
}

export function extractAllExclusionFields(report) {
    if (report && report.IsExcluded && report.Consumptions && report.Consumptions.length) {
        let consumptionsExclusionMap = null;
        report.Consumptions.forEach(consumption => {
            if (report.ExclusionFields[consumption.EnumName]) {
                consumptionsExclusionMap = {
                    [`Consumptions.${consumption.Id}`]: report.ExclusionFields[consumption.EnumName]
                };
            }
        });
        if (consumptionsExclusionMap) {
            return {
                ...report.ExclusionFields,
                ...consumptionsExclusionMap
            };
        }
    }
    return report.ExclusionFields;
}

const convertToNumber = (value) => {
    if (typeof value === 'number') {
        return value;
    }
    return value ? parseFloat(value) : null;
};

const tankProps = ['CargoWeight', 'Charterer', 'Commodity', 'Loaded', 'Sg', 'Size', 'Temperature'];

export const tankOptions = {
    Empty: { Id: 0, Label: translate('TANK_LAYOUT.FORM.EMPTY') },
    Cargo: { Id: 1, Label: translate('TANK_LAYOUT.FORM.CARGO') },
    Slop: { Id: 2, Label: translate('TANK_LAYOUT.FORM.SLOPS') }
};

const getTankState = (tank) => {
    if (tank.IsSlop) {
        return tankOptions.Slop;
    }
    return tankProps.some(prop => !!tank[prop]) ? tankOptions.Cargo : tankOptions.Empty;
};

export function convertTankDataForEdit(wings) {
    return wings.map(wing => ({
        ...wing,
        Port: { ...wing.Port, TankState: getTankState(wing.Port) },
        Starboard: { ...wing.Starboard, TankState: getTankState(wing.Starboard) }
    }));
}

// const tankHasValues = tank => (
//     tank.TankState.Id !== 0
//     && (!!tank.Commodity || !!tank.Charterer || !!tank.Size || !!tank.Temperature || !!tank.Sg || !!tank.Loaded)
// );

export function convertTankDataForUpdate(wings) {
    return wings.map(wing => ({
        ...wing,
        Port: {
            // ...wing.Port,
            Size: wing.Port.TankState.Id === 0 ? null : convertToNumber(wing.Port.Size),
            Loaded: wing.Port.TankState.Id === 0 ? null : convertToNumber(wing.Port.Loaded),
            Commodity: wing.Port.TankState.Id === 0 || !wing.Port.Commodity ? null : wing.Port.Commodity,
            Charterer: wing.Port.TankState.Id === 0 || !wing.Port.Charterer ? null : wing.Port.Charterer,
            Temperature: wing.Port.TankState.Id === 0 ? null : convertToNumber(wing.Port.Temperature),
            Sg: wing.Port.TankState.Id === 0 ? null : convertToNumber(wing.Port.Sg),
            IsSlop: wing.Port.TankState.Id === 2
        },
        Starboard: {
            // ...wing.Starboard,
            Size: wing.Starboard.TankState.Id === 0 ? null : convertToNumber(wing.Starboard.Size),
            Loaded: wing.Starboard.TankState.Id === 0 ? null : convertToNumber(wing.Starboard.Loaded),
            Commodity: wing.Starboard.TankState.Id === 0 || !wing.Starboard.Commodity ? null : wing.Starboard.Commodity,
            Charterer: wing.Starboard.TankState.Id === 0 || !wing.Starboard.Charterer ? null : wing.Starboard.Charterer,
            Temperature: wing.Starboard.TankState.Id === 0 ? null : convertToNumber(wing.Starboard.Temperature),
            Sg: wing.Starboard.TankState.Id === 0 ? null : convertToNumber(wing.Starboard.Sg),
            IsSlop: wing.Starboard.TankState.Id === 2
        }
    }));
}

const auxProps = [
    'Auxiliary1RunningHoursPerDay',
    'Auxiliary2RunningHoursPerDay',
    'Auxiliary3RunningHoursPerDay',
    'Auxiliary4RunningHoursPerDay'
];

const auxEnergyProps = [
    'Auxiliary1EnergyProduced',
    'Auxiliary2EnergyProduced',
    'Auxiliary3EnergyProduced',
    'Auxiliary4EnergyProduced'
];

const boilerProps = [
    'Boiler1RunningHoursPerDay',
    'Boiler2RunningHoursPerDay',
    'Boiler3RunningHoursPerDay',
    'Boiler4RunningHoursPerDay'
];

const emptyValues = new Array(4).fill(null);

export function extractUtilization(report) {
    if (report) {
        const auxValues = [...emptyValues];
        let isAuxEmpty = true;
        const auxEnergyValues = [...emptyValues];
        let isAuxEnergyEmpty = true;
        const boilerValues = [...emptyValues];
        let isBoilerEmpty = true;
        for (let i = 0; i < 4; i++) {
            if (typeof report[auxProps[i]] === 'number') {
                auxValues[i] = TimeHelper.getTimeSpanFromMinutes(report[auxProps[i]]);
                isAuxEmpty = false;
            }
            if (typeof report[auxEnergyProps[i]] === 'number') {
                auxEnergyValues[i] = report[auxEnergyProps[i]];
                isAuxEnergyEmpty = false;
            }
            if (typeof report[boilerProps[i]] === 'number') {
                boilerValues[i] = TimeHelper.getTimeSpanFromMinutes(report[boilerProps[i]]);
                isBoilerEmpty = false;
            }
        }
        return {
            AuxiliaryRunningHours: { values: auxValues, isEmpty: isAuxEmpty },
            AuxiliaryEnergyProduced: { values: auxEnergyValues, isEmpty: isAuxEnergyEmpty },
            BoilerRunningHours: { values: boilerValues, isEmpty: isBoilerEmpty }
        };
    }
    return null;
}

export function convertUtilizationForUpdate(report) {
    if (report && report.Utilization) {
        const utilizationValues = {};
        report.Utilization.AuxiliaryRunningHours.values.forEach((val, index) => {
            utilizationValues[auxProps[index]] = TimeHelper.getMinutesFromTimeSpan(val);
        });
        report.Utilization.AuxiliaryEnergyProduced.values.forEach((val, index) => {
            utilizationValues[auxEnergyProps[index]] = val;
        });
        report.Utilization.BoilerRunningHours.values.forEach((val, index) => {
            utilizationValues[boilerProps[index]] = TimeHelper.getMinutesFromTimeSpan(val);
        });
        return utilizationValues;
    }
    return null;
}

export function convertWayPointsDataForUpdate(waypoints) {
    return waypoints.map((waypoint, index) => ({
        ...waypoint,
        WaypointNumber: index
    }));
}

export const convertConfigSelector = (selector, field = '/') => {
    let convertedSelector = selector.includes('*');
    if (!convertedSelector) {
        convertedSelector = selector.map(wildCard => {
            const res = wildCard.match(fieldConfigRegex);
            if (!res) {
                // eslint-disable-next-line no-console
                console.error(`Invalid validation selector field wildcard "${wildCard}" for field "${field}"`);
                return {};
            }
            return {
                DataSourceIds: !res[1] ? null : mapArrayByValue(res[1].split(',')),
                ReportSubtypes: !res[2] ? null : mapArrayByValue(res[2].split(',')),
                Versions: !res[3] ? null : res[3].split(',').map(ver => {
                    const splitVer = ver.split('-');
                    return { min: splitVer[0], max: splitVer[1] || null };
                })
            };
        });
    }
    return convertedSelector;
};

const versionRegex = new RegExp(/\d+\.\d+\.\d+(?=\)*$)/);

let prevReport = null;
let prevVersion = null;

const extractVersion = (report) => {
    if (!prevVersion || report !== prevReport) {
        const matches = versionRegex.exec(report.OrClientVersion);
        prevVersion = matches?.[0] || '0.0.0';
        prevReport = report;
    }
    return prevVersion;
};

const selectorKeys = {
    d: 'DataSourceId',
    r: 'ReportTypeId',
    s: 'ReportSubtype.Id'
};

export const createOptionSelector = (report, keys = ['d', 'r', 's']) => {
    let res = '';
    keys.forEach(key => {
        if (selectorKeys[key]) {
            res += `${key}[${getObjectProp(report, selectorKeys[key])}]`;
        }
    });
    return res;
};

export const extractConsumptionTypesPerGroup = (report, editingOptions) => {
    const { consumptionTypesAndGroups, consumptionTypesConfig } = editingOptions;
    const selector = createOptionSelector(report);
    const res = {};
    let groupSelector;
    if (consumptionTypesAndGroups) {
        consumptionTypesAndGroups.ConsumptionGroupTypes.forEach(cg => {
            groupSelector = `${selector}g[${cg.Id}]`;
            res[cg.Id] = consumptionTypesAndGroups.ConsumptionTypes.filter(ct => (
                consumptionTypesConfig[ct.Id]?.Selectors[groupSelector]
            ));
        });
    }
    return res;
};

export const extractBunkerTypes = (report, editingOptions) => {
    const { bunkerTypes, bunkerTypesConfig } = editingOptions;
    if (bunkerTypes && bunkerTypesConfig) {
        const selector = createOptionSelector(report);
        return bunkerTypes.filter(bt => bunkerTypesConfig[bt.Id]?.Selectors[selector]);
    }
    return emptyArray;
};

export const areConditionsMet = (conditions, report) => {
    if (!report) {
        return false;
    }
    const OrClientVersion = extractVersion(report);
    const ReportSubtypeId = report.ReportSubtype ? report.ReportSubtype.Id : report.ReportSubtypeId;
    return (conditions && (conditions === true || conditions.some(condition => (
        (!condition.DataSourceIds || condition.DataSourceIds[report.DataSourceId])
        && (!condition.ReportSubtypes || condition.ReportSubtypes[ReportSubtypeId])
        && (!condition.Versions || condition.Versions.some(version => (
            compareVersions(OrClientVersion, version.min) >= 0
                && (!version.max || compareVersions(OrClientVersion, version.max) < 0)
        )))
    ))));
};

export const convertValidations = (validations) => {
    const convertedValidations = {};
    Object.keys(validations).forEach(reportTypeName => {
        convertedValidations[reportTypeName] = {};
        Object.keys(validations[reportTypeName]).forEach(field => {
            convertedValidations[reportTypeName][field] = validations[reportTypeName][field].map(rule => ({
                ...rule,
                Selector: convertConfigSelector(rule.Selector, field)
            }));
        });
    });
    return convertedValidations;
};

export const convertValidationRules = (report, validationRules) => {
    const res = { ...defaultValidationsByType[report.ReportTypeId] };
    if (validationRules) {
        Object.keys(validationRules).forEach(field => {
            validationRules[field].forEach(rule => {
                if (areConditionsMet(rule.Selector, report)) {
                    if (vtm[rule.Type]) {
                        let valueSet = false;
                        for (let i = 0; i < vtm[rule.Type].length; i++) {
                            if (getObjectProp(res, `${field}.${vtm[rule.Type][i]}`) !== undefined) {
                                setObjectProp(res, `${field}.${vtm[rule.Type][i]}`, rule.Value, true);
                                valueSet = true;
                                break;
                            }
                        }
                        if (!valueSet) {
                            setObjectProp(res, `${field}.${vtm[rule.Type][0]}`, rule.Value, true);
                        }
                    }
                    if (rtm[rule.Type]) {
                        if (rtm[rule.Type].handle) {
                            rtm[rule.Type].handle(field, res, rule.Value);
                        } else if (rule.Value) {
                            setObjectProp(res, `${field}.${rtm[rule.Type].name}`, rtm[rule.Type].value, true);
                        } else {
                            deleteObjectProp(res, `${field}.${rtm[rule.Type].name}`, true);
                        }
                    }
                }
            });
        });
    }
    return res;
};

export const extractAndMapColumns = (data, columnNameProp) => {
    if (!data.length) {
        return null;
    }
    const columns = [];
    const columnMap = {};
    data.forEach(item => {
        columnMap[item[columnNameProp]] = item;
        columns.push(item[columnNameProp]);
    });
    columns.sort();
    return { columns, columnMap };
};

export const extractWaterData = (data) => ({
    columns: ['Fresh', 'Distilled'],
    columnMap: {
        Fresh: {
            Consumed: data.FreshWaterConsumed,
            Produced: data.FreshWaterProduced
        },
        Distilled: {
            Consumed: data.DistilledWaterConsumed,
            Produced: data.DistilledWaterProduced
        }
    }
});

export const extractEquipmentItems = (data) => {
    if (!data.length) {
        return null;
    }
    const extractedData = {};
    data.forEach(item => {
        if (item.Name) {
            if (!extractedData[item.Type.Name]) {
                extractedData[item.Type.Name] = { columns: [], columnMap: {}, fuelTypes: [], fuelTypeMap: {} };
            }
            if (!extractedData[item.Type.Name].columnMap[item.Name]) {
                extractedData[item.Type.Name].columns.push(item.Name);
                extractedData[item.Type.Name].columnMap[item.Name] = { Name: item.Name, Type: item.Type };
                if (item.FuelType && item.FuelType.Id !== null) {
                    extractedData[item.Type.Name].columnMap[item.Name].FuelTypeQuantities = {};
                }
            }
            if (item.FuelType && item.FuelType.Id !== null) {
                if (!extractedData[item.Type.Name].fuelTypeMap[item.FuelType.Id]) {
                    extractedData[item.Type.Name].fuelTypeMap[item.FuelType.Id] = item.FuelType;
                    extractedData[item.Type.Name].fuelTypes.push(item.FuelType);
                }
                const fuelTypeQuantity = { ...item.FuelType };
                item.Values.forEach(value => {
                    fuelTypeQuantity[value.ValueType.Name] = value.Value;
                });
                extractedData[item.Type.Name].columnMap[item.Name]
                    .FuelTypeQuantities[item.FuelType.Id] = fuelTypeQuantity;
            } else {
                item.Values.forEach(value => {
                    extractedData[item.Type.Name].columnMap[item.Name][value.ValueType.Name] = value.Value;
                });
            }
        }
    });
    return extractedData;
};
