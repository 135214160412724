/* eslint-disable max-len */
import React from 'react';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { formatCoordinates, formatNumber, getObjectProp, formatWktPosition } from 'utils/helpers/info-helper';
import { wktParse, mapLonLat } from 'components/ol/ol-helpers';
import TimeHelper from 'utils/helpers/time-helper';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import CoordinatesInput from 'components/coordinates-input/coordinates-input';
import DataTable from './data-table/vessel-report-data-table';
import DateTimePopup from 'components/date-time-popup/date-time-popup';
import FlipSwitch from 'components/flip-switch/flip-switch';
import FuelDataTable from 'components/fuel-data-table/fuel-data-table';
import Input from 'components/input/input';
import PortSelect from 'components/port-select/port-select';
import Select from 'components/select/select';
import Textarea from 'components/textarea/textarea';
import UtilizationTable from './utilization-table/vessel-report-utilization-table';
import Validation from 'components/validation/validation';
import VesselSelectInScope from 'components/vessel-select/vessel-select-in-scope';
import VoyageSelect from 'components/voyage-select/voyage-select';
import SuggestionLink from '../suggestion-link/suggestion-link';

export const t = (key, values) => translate(`REPORT_LABELS.${key}`, values);
export const tu = (key) => translate(`UNITS.${key}`);
export const tg = (key) => translate(`GLOBAL.${key}`);

const emptyArray = [];

export const formatBoolValue = (value, activeLabel = tg('YES'), inactiveLabel = tg('NO')) => {
    if (typeof value !== 'boolean') {
        return '-';
    }
    return value ? activeLabel : inactiveLabel;
};

export const customDateValidation = {
    rule: (value) => value !== 'Invalid Date',
    hint: () => 'Date is invalid'
};

const dateOffsetInputProps = { maxLength: 6 };

const timeFormattingOptions = { time: true, utc: true };
const dateFormattingOptions = { time: false, utc: true };

const setDateValue = (prop, setValue) => value => setValue(
    prop,
    value && value.toISOString ? value.toISOString() : value
);

const setVesselValue = (setValue) => value => {
    setValue('Vessel', value);
    setValue('Voyage', null);
};

const getPositionChangeHandler = (prop, setValue) => (value) => setValue(
    prop,
    value ? { Longitude: value.Coordinates[0], Latitude: value.Coordinates[1] } : null
);

const renderValueSuggestion = (index, prop, setValue, suggestedValue, suggestionLabel) => (
    <div
        key={`${prop}.Value.${index}`}
        className="sten-vessel-report__suggestions cursor-pointer"
        title={t('SUGGESTED_TOOLTIP')}
        onClick={setValue.bind(this, prop, suggestedValue)}
    >
        <span className="text-secondary">{t('SUGGESTED')}</span>
        <span className="sten-vessel-report__suggestions-label text-warning">{suggestionLabel}</span>
    </div>
);

const windBeaufortOptionRenderer = option => `${option.Id} - ${option.Name}`;

export const commonSuggestions = {
    Link: (suggestion, index) => <SuggestionLink suggestion={suggestion} index={index} />,
    Value: (suggestion, index, report, prop, setValue) => {
        const value = getObjectProp(report, prop);
        return suggestion.Value && suggestion.Value !== value && renderValueSuggestion(index, prop, setValue, suggestion.Value, suggestion.Value);
    }
};

export const commonFields = {
    Vessel: () => ({
        label: <label className="label">{t('VESSEL')}</label>,
        view: report => <label className="label label--value">{`${report.VesselName} (${report.Imo})`}</label>,
        edit: (report, getChangeHandler, params) => (
            <Validation.Wrapper hintsOnHover validations={params.validationRules.Vessel}>
                <VesselSelectInScope
                    fixedListWidth
                    name="Vessel"
                    clearable
                    value={report.Vessel}
                    title={t('SEARCH_TITLE')}
                    onChange={setVesselValue(getChangeHandler)}
                />
            </Validation.Wrapper>
        )
    }),
    VoyageNumber: () => ({
        label: <label className="label">{t('VOYAGE_NUMBER')}</label>,
        view: report => (
            <label className="label label--value">
                {report.Voyage && report.Voyage.VoyageNumber ? report.Voyage.VoyageNumber : '-'}
            </label>
        ),
        edit: (report, setValue, params) => (
            <Validation.Wrapper hintsOnHover validations={params.validationRules.VoyageNumber}>
                <VoyageSelect
                    fixedListWidth
                    name="VoyageNumber"
                    vesselId={report.Vessel ? report.Vessel.Imo : report.Imo}
                    value={report.Voyage}
                    onChange={setValue.bind(this, 'Voyage')}
                />
            </Validation.Wrapper>
        ),
        suggestions: {
            Value: (suggestion, index, report, prop, setValue) => {
                const value = getObjectProp(report, 'Voyage');
                const suggestionLabel = suggestion.Value.VoyagePortInfo
                    ? `${suggestion.Value.VoyageNumber} ${suggestion.Value.VoyagePortInfo}`
                    : suggestion.Value.VoyageNumber;
                return value && (suggestion.Value.VoyageNumber !== value.VoyageNumber)
                    && renderValueSuggestion(index, 'Voyage', setValue, suggestion.Value, suggestionLabel);
            }
        }
    }),
    Position: (prop = 'Position', nameSuffix = '') => ({
        label: <label className="label">{t('POSITION')}</label>,
        view: (report) => {
            const value = getObjectProp(report, prop);
            return (
                <label className="label label--value">
                    {value && value.Longitude && value.Latitude
                        ? formatCoordinates(value.Longitude, value.Latitude)
                        : '-'}
                </label>
            );
        },
        edit: (report, setValue, params) => {
            const value = getObjectProp(report, prop);
            const suffix = nameSuffix ? `.${nameSuffix}` : '';
            return (
                <Validation.Wrapper hintsOnHover validations={params.validationRules[prop]}>
                    <CoordinatesInput
                        clearable
                        name={`${prop}${suffix}`}
                        value={value ? mapLonLat(value) : null}
                        onChange={getPositionChangeHandler(prop, setValue)}
                    />
                </Validation.Wrapper>
            );
        },
        suggestions: {
            Value: (suggestion, index, report, prop, setValue) => {
                const value = getObjectProp(report, prop);
                const suggestedCoordinates = wktParse(suggestion.Value);
                const suggestedValue = { Longitude: suggestedCoordinates[0], Latitude: suggestedCoordinates[1] };
                return (!value || (suggestedValue.Longitude !== value.Longitude && suggestedValue.Latitude !== value.Latitude))
                    && renderValueSuggestion(index, prop, setValue, suggestedValue, formatWktPosition(suggestion.Value));
            }
        }
    }),
    Point: (prop = 'Point', label = t('POINT')) => ({
        label: <label className="label">{label}</label>,
        view: report => {
            const value = getObjectProp(report, prop);
            return <label className="label label--value">{value?.Name || '-'}</label>;
        },
        edit: (report, setValue, params) => {
            return (
                <Validation.Wrapper hintsOnHover validations={params.validationRules[prop]}>
                    <PortSelect
                        name={prop}
                        fixedListWidth
                        clearable
                        value={getObjectProp(report, prop)}
                        onChange={setValue.bind(this, prop)}
                    />
                </Validation.Wrapper>
            );
        }
    }),
    Textarea: (prop, label) => ({
        label: <label className="label">{label}</label>,
        view: (report) => <p className="text-word-break">{getObjectProp(report, prop) || '-'}</p>,
        edit: (report, setValue, params) => {
            return (
                <Validation.Wrapper hintsOnHover validations={params.validationRules[prop]}>
                    <Textarea
                        name={prop}
                        value={getObjectProp(report, prop) || ''}
                        onChange={setValue.bind(this, prop)}
                    />
                </Validation.Wrapper>
            );
        }
    }),
    String: (prop, label, isDisabled) => ({
        label: <label className="label">{label}</label>,
        view: (report) => <label className="label label--value">{getObjectProp(report, prop) || '-'}</label>,
        edit: (report, setValue, params) => {
            const disabled = isDisabled && isDisabled(report);
            const value = getObjectProp(report, prop);
            return (
                <Validation.Wrapper hintsOnHover validate={!disabled} validations={params.validationRules[prop]}>
                    <Input
                        disabled={disabled}
                        name={prop}
                        value={value}
                        onChange={setValue.bind(this, prop)}
                    />
                </Validation.Wrapper>
            );
        }
    }),
    Number: (prop, label, decimals, unit, isDisabled) => ({
        label: <label className="label">{label}</label>,
        view: (report) => {
            const value = getObjectProp(report, prop);
            return (
                <label className="label label--value">
                    {typeof value === 'number' ? `${formatNumber(value, decimals)}${unit ? ` ${unit}` : ''}` : '-'}
                </label>
            );
        },
        edit: (report, setValue, params) => {
            const disabled = isDisabled && isDisabled(report);
            return (
                <Validation.Wrapper hintsOnHover validate={!disabled} validations={params.validationRules[prop]}>
                    <Input
                        disabled={disabled}
                        name={prop}
                        value={getObjectProp(report, prop)}
                        onChange={setValue.bind(this, prop)}
                        suffix={unit}
                    />
                </Validation.Wrapper>
            );
        }
    }),
    Select: (prop, label, optionsKey, valueKey = 'Id', labelKey = 'Name', selectProps) => ({
        label: <label className="label">{label}</label>,
        view: report => {
            const value = getObjectProp(report, prop);
            return <label className="label label--value">{value ? value[labelKey] : '-'}</label>;
        },
        edit: (data, setValue, params) => {
            const options = typeof optionsKey === 'function'
                ? optionsKey(params, data)
                : getObjectProp(params, optionsKey);
            const extractedProps = selectProps && typeof selectProps === 'function'
                ? selectProps(params, data)
                : selectProps;
            const disabled = extractedProps ? extractedProps.disabled : false;
            return (
                <Validation.Wrapper hintsOnHover validate={!disabled} validations={params.validationRules[prop] || null}>
                    <Select
                        fixedListWidth
                        name={prop}
                        value={getObjectProp(data, prop)}
                        options={options || emptyArray}
                        valueKey={valueKey}
                        labelKey={labelKey}
                        onChange={setValue.bind(this, prop)}
                        {...extractedProps}
                    />
                </Validation.Wrapper>
            );
        },
        suggestions: {
            Value: (suggestion, index, report, prop, setValue, options) => {
                const value = getObjectProp(report, prop)[valueKey];
                const suggestedValue = getObjectProp(options, optionsKey).find(option => option[valueKey] === suggestion.Value);
                const suggestionLabel = suggestedValue[labelKey];
                return suggestion.Value !== value && renderValueSuggestion(index, prop, setValue, suggestedValue, suggestionLabel);
            }
        }
    }),
    Boolean: (prop, label, activeLabel = tg('YES'), inactiveLabel = tg('NO')) => ({
        label: <label className="label">{label}</label>,
        view: (report) => (
            <label className="label label--value">
                {formatBoolValue(getObjectProp(report, prop), activeLabel, inactiveLabel)}
            </label>
        ),
        edit: (report, setValue, params) => {
            const value = getObjectProp(report, prop);
            return (
                <Validation.Wrapper hintsOnHover validations={params.validationRules[prop] || null}>
                    <FlipSwitch
                        name={prop}
                        activeLabel={activeLabel}
                        inactiveLabel={inactiveLabel}
                        value={value}
                        onChange={setValue.bind(this, prop, !value)}
                    />
                </Validation.Wrapper>
            );
        }
    }),
    Date: (prop, label, isDisabled) => ({
        label: <label className="label">{label}</label>,
        view: (report) => {
            const value = getObjectProp(report, prop);
            const date = TimeHelper.getFormatted(value, dateFormattingOptions);
            return <label className="label label--value">{value ? date : '-'}</label>;
        },
        edit: (report, setValue, params) => {
            const disabled = isDisabled && isDisabled(report);
            return (
                <Validation.Wrapper
                    hintsOnHover
                    validate={!disabled}
                    validations={params.validationRules[prop]}
                >
                    <DateTimePopup
                        withTime={false}
                        disabled={disabled}
                        alignment="end"
                        alwaysShow6Weeks
                        autoPlacement
                        name={prop}
                        isClearable
                        isUTC
                        dateFormat={TimeHelper.getFormat()}
                        onDateSelect={setDateValue(prop, setValue)}
                        value={getObjectProp(report, prop)}
                    />
                </Validation.Wrapper>
            );
        }
    }),
    DateTime: (prop, offsetProp, label, nameSuffix = '', isDisabled) => ({
        label: <label className="label">{label}</label>,
        view: (report) => {
            const value = getObjectProp(report, prop);
            const offsetValue = getObjectProp(report, offsetProp);
            const date = TimeHelper.getFormatted(value, timeFormattingOptions);
            const dateOffset = offsetValue ? ` (UTC ${offsetValue})` : '';
            return <label className="label label--value">{value ? `${date}${dateOffset}` : '-'}</label>;
        },
        edit: (report, setValue, params) => {
            const suffix = nameSuffix !== '' ? `.${nameSuffix}` : '';
            const disabled = isDisabled && isDisabled(report);
            return (
                <div className="flex-row--md flex-center--md">
                    <div className="col-md-18 col-xs-24">
                        <Validation.Wrapper
                            hintsOnHover
                            validate={!disabled}
                            validations={params.validationRules[prop]}
                        >
                            <DateTimePopup
                                disabled={disabled}
                                alignment="end"
                                alwaysShow6Weeks
                                autoPlacement
                                name={`${prop}${suffix}`}
                                isClearable
                                isUTC
                                dateFormat={TimeHelper.getFormat()}
                                onDateSelect={setDateValue(prop, setValue)}
                                value={getObjectProp(report, prop)}
                            />
                        </Validation.Wrapper>
                    </div>
                    <div className="col-md-6 col-xs-8">
                        <Validation.Wrapper
                            hintsOnHover
                            validate={!disabled}
                            validations={params.validationRules[offsetProp]}
                        >
                            <Input
                                placeholder="±HH:mm"
                                disabled={disabled}
                                name={`${offsetProp}${suffix}`}
                                value={getObjectProp(report, offsetProp)}
                                inputFieldProps={dateOffsetInputProps}
                                onChange={setValue.bind(this, offsetProp)}
                            />
                        </Validation.Wrapper>
                    </div>
                </div>
            );
        }
    }),
    ReportDate: (label = t('REPORT_DATE')) => ({
        ...commonFields.DateTime('ReportDate', 'ReportDateOffset', label),
        view: undefined
    }),
    CargoStatus: (prop = 'CargoStatus') => commonFields.Select(prop, t('VESSEL_CONDITION'), 'editingOptions.cargoStatuses', 'Id', 'Title'),
    CharterpartyDate: () => commonFields.Date('CharterpartyDate', t('CHARTERPARTY_DATE')),
    Remarks: (label) => commonFields.Textarea('Remarks', label),
    DWT: (prop, label) => commonFields.Number(prop, label, 2, tu('DWT')),
    Draft: (prop, label) => commonFields.Number(prop, label, 2, tu('DRAFT')),
    Speed: (prop, label) => commonFields.Number(prop, label, 1, tu('SPEED')),
    VesselStatus: (prop = 'VesselStatus') => commonFields.Select(prop, t('VESSEL_STATUS'), 'editingOptions.vesselStatusTypes'),
    ValueDirection: (valueProp, directionProp, label, unit) => ({
        label: <label className="label">{label}</label>,
        view: report => {
            let value = getObjectProp(report, valueProp);
            if (typeof value === 'number') {
                value = `${formatNumber(value, 0)} ${unit}`;
                const direction = getObjectProp(report, directionProp);
                if (direction && direction.Name) {
                    value += ` (${direction.Name})`;
                }
            } else {
                value = '-';
            }
            return <label className="label label--value">{value}</label>;
        },
        edit: (report, setValue, params) => {
            return (
                <div className="row">
                    <div className="col-8">
                        <Validation.Wrapper hintsOnHover validations={params.validationRules[valueProp]}>
                            <Input
                                name={valueProp}
                                value={getObjectProp(report, valueProp)}
                                onChange={setValue.bind(this, valueProp)}
                                suffix={unit}
                            />
                        </Validation.Wrapper>
                    </div>
                    <div className="col-16">
                        <Validation.Wrapper hintsOnHover validations={params.validationRules[directionProp]}>
                            <Select
                                name={directionProp}
                                options={params.editingOptions.cardinalDirections}
                                value={getObjectProp(report, directionProp)}
                                valueKey="Id"
                                labelKey="Name"
                                onChange={setValue.bind(this, directionProp)}
                            />
                        </Validation.Wrapper>
                    </div>
                </div>
            );
        }
    }),
    CallSign: () => commonFields.String('CallSign', t('CALL_SIGN')),
    Displacement: () => commonFields.Number('Displacement', t('DISPLACEMENT'), 0, tu('DISPLACEMENT')),
    ViewOnly: (prop, label, decimals, unit) => ({
        label: <label className="label">{label}</label>,
        view: report => {
            let value = getObjectProp(report, prop);
            if (typeof value === 'number') {
                value = `${formatNumber(value, decimals)}${unit ? ` ${unit}` : ''}`;
            } else if (typeof value === 'boolean') {
                value = formatBoolValue(value);
            }
            return <label className="label label--value">{value || '-'}</label>;
        }
    }),
    ReadOnly: (prop, label, decimals, unit, title) => ({
        label: <label className="label">{label}</label>,
        view: report => {
            let value = typeof prop === 'string'
                ? getObjectProp(report, prop)
                : prop(report);
            if (typeof value === 'number') {
                value = `${formatNumber(value, decimals)}${unit ? ` ${unit}` : ''}`;
            }
            return <label className="label label--value">{value || '-'}</label>;
        },
        edit: (report) => {
            const value = typeof prop === 'string'
                ? getObjectProp(report, prop)
                : prop(report);
            return (
                <Input
                    title={title}
                    readOnly
                    value={value}
                    suffix={unit}
                />
            );
        }
    }),
    WindForce: (prop, label) => ({
        ...commonFields.Select(
            prop,
            label,
            'editingOptions.beaufortScale',
            'Id',
            'Name',
            {
                optionRenderer: windBeaufortOptionRenderer
            }
        ),
        view: report => {
            const value = getObjectProp(report, prop);
            return (
                <label className="label label--value">
                    {value ? `${value.Id} ${tu('WIND_FORCE')} (${value.Name})` : '-'}
                </label>
            );
        }
    })
};

export const auroraProps = {
    Boiler: {
        rows: [
            { prop: 'SteamFlow', title: t('STEAM_FLOW'), decimals: 1 }
        ],
        columnsOrdered: [
            'Main Boiler No.01 Stbd',
            'Main Boiler No.02 Port',
            'Auxiliary Boiler No.01 Stbd',
            'Auxiliary Boiler No.02 Port'
        ],
        headerMap: {
            'Auxiliary Boiler No.01 Stbd': 'Aux 1',
            'Auxiliary Boiler No.02 Port': 'Aux 2',
            'Main Boiler No.01 Stbd': 'Main 1',
            'Main Boiler No.02 Port': 'Main 2'
        }
    },
    MainTurbine: {
        rows: [
            { prop: 'Revs', title: t('REVS'), decimals: 0 },
            { prop: 'ShaftPower', title: t('SHAFT_POWER'), decimals: 0 },
            { prop: 'CondenserVacuum', title: t('CONDENSER_VACUUM'), decimals: 0 }
        ]
    },
    PropulsionMotor: {
        rows: [
            { prop: 'PropellerRevs', title: t('REVS'), decimals: 0 },
            { prop: 'MotorLoad', title: t('PROPULSION_MOTOR_LOAD'), decimals: 0 },
            { prop: 'ShaftPower', title: t('SHAFT_POWER'), decimals: 0 }
        ],
        columnsOrdered: ['Electric Propulsion Motor No.02 - Port', 'Electric Propulsion Motor No.01 - Stbd'],
        headerMap: {
            'Electric Propulsion Motor No.02 - Port': 'Port',
            'Electric Propulsion Motor No.01 - Stbd': 'Starboard'
        }
    },
    HoursConsumption: {
        columnsOrdered: [
            'Main Engine',
            'Main Generator Engine No.01',
            'Main Generator Engine No.02',
            'Main Generator Engine No.03',
            'Main Generator Engine No.04',
            'Boiler',
            'IGG',
            'GCU',
            'Gas Combustion Unit (GCU)'
        ],
        headerMap: {
            'Main Engine': 'Main',
            'Main Generator Engine No.01': 'Main 1',
            'Main Generator Engine No.02': 'Main 2',
            'Main Generator Engine No.03': 'Main 3',
            'Main Generator Engine No.04': 'Main 4',
            'Gas Combustion Unit (GCU)': 'GCU'
        },
        fuelProp: { prop: 'Hours', decimals: 0, suffix: ' (h)' }
    },
    MainEnginePower: {
        rows: [
            { prop: 'Power', title: t('GEN_LOAD'), decimals: 0 }
        ],
        columnsOrdered: [
            'Main Generator Engine No.01',
            'Main Generator Engine No.02',
            'Main Generator Engine No.03',
            'Main Generator Engine No.04'
        ],
        headerMap: {
            'Main Generator Engine No.01': 'Main 1',
            'Main Generator Engine No.02': 'Main 2',
            'Main Generator Engine No.03': 'Main 3',
            'Main Generator Engine No.04': 'Main 4'
        }
    },
    Water: {
        rows: [
            { prop: 'Consumed', title: t('CONSUMED'), decimals: 0 },
            { prop: 'Produced', title: t('PRODUCED'), decimals: 0 }
        ]
    },
    Temperatures: {
        rows: [
            { prop: 'Liquid', title: t('LIQUID_TEMP'), decimals: 2 },
            { prop: 'Vapour', title: t('VAPOUR_TEMP'), decimals: 2 },
            { prop: 'Pressure', title: t('TANK_PRES'), decimals: 0 }
        ],
        headerMap: {
            'Cargo Tank No.01': 1,
            'Cargo Tank No.02': 2,
            'Cargo Tank No.03': 3,
            'Cargo Tank No.04': 4
        }
    },
    BarrierSpaceConditions: {
        rows: [
            { prop: 'SecondaryBarrierAvgTemp', title: t('SECONDARY_BARRIER_AVG_TEMP'), decimals: 1 },
            { prop: 'PrimaryInsulationSpacePress', title: t('PRIMARY_INSULATION_SPACE_PRES'), decimals: 1 },
            { prop: 'SecondaryInsulationSpacePress', title: t('SECONDARY_INSULATION_SPACE_PRES'), decimals: 1 }
        ],
        headerMap: {
            'Cargo Tank No.01': 1,
            'Cargo Tank No.02': 2,
            'Cargo Tank No.03': 3,
            'Cargo Tank No.04': 4
        }
    },
    CofferdamTemps: {
        rows: [
            { prop: 'TopTemp', title: t('TOP'), decimals: 1 },
            { prop: 'MiddleTemp', title: t('MIDDLE'), decimals: 1 },
            { prop: 'BottomTemp', title: t('BOTTOM'), decimals: 1 }
        ],
        headerMap: {
            'Cofferdam No.01': 1,
            'Cofferdam No.02': 2,
            'Cofferdam No.03': 3,
            'Cofferdam No.04': 4,
            'Cofferdam No.05': 5
        }
    }
};

const getBunkerDataFieldDisabled = (item, fuel) => (item.Id === 4 && fuel.Id === 10);

export const commonData = {
    ConsumptionFuelData: (prop, typeId) => (params, index) => (
        <FuelDataTable
            key={index}
            name={prop}
            data={params.data[prop]}
            onChange={params.onChange.bind(this, prop)}
            isEditing={params.isEditing}
            fuelTypeOptions={params.editingOptions.fuelTypes}
            fuelTypeValidations={params.validationRules[prop].FuelTypes}
            rowOptions={params.editingOptions.consumptionTypes?.[typeId] || emptyArray}
            rowOptionsLabel={t('CONSUMPTION_TYPES')}
            rowOptionsLabelKey={ConfigService.featureToggles.useReportConfigService ? 'DisplayName' : 'Name'}
            rowOptionValidations={params.validationRules[prop].ConsumptionTypes}
            fieldValidations={params.validationRules[prop]}
        />
    ),
    BunkerFuelData: (prop) => (params, index) => (
        <FuelDataTable
            areUnitsShown
            key={index}
            name={prop}
            data={params.data[prop]}
            onChange={params.onChange.bind(this, prop)}
            isEditing={params.isEditing}
            fuelTypeOptions={params.editingOptions.fuelTypes}
            fuelTypeValidations={params.validationRules[prop].FuelTypes}
            rowOptions={params.editingOptions.bunkerTypes}
            rowOptionsLabel={t('BUNKER_TYPES')}
            rowOptionValidations={params.validationRules[prop].BunkerTypes}
            fieldValidations={params.validationRules[prop]}
            getFieldDisabled={getBunkerDataFieldDisabled}
        />
    ),
    Utilization: (prop) => (params, index, dataFields) => (
        <UtilizationTable
            key={index}
            validations={params.validationRules[prop]}
            onChange={params.isEditing ? params.onChange.bind(this, prop) : undefined}
            data={params.data[prop]}
            rowKeys={dataFields}
        />
    ),
    AuroraDataTable: (prop, auroraProp, headerTitle = '', title = '', otherProps) => (params, index) => {
        const data = getObjectProp(params.data, prop);
        if (!data) {
            return null;
        }
        return (
            <DataTable
                key={index}
                title={title}
                columns={data.columns}
                columnsOrdered={auroraProps[auroraProp].columnsOrdered}
                data={data.columnMap}
                headerMap={auroraProps[auroraProp].headerMap}
                headerTitle={headerTitle}
                rows={auroraProps[auroraProp].rows}
                fuelTypes={data.fuelTypes}
                fuelProp={auroraProps[auroraProp].fuelProp}
                {...otherProps}
            />
        );
    }
};
