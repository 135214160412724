import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { formatNumber, getClassName } from 'utils/helpers/info-helper';
/* components */
import TankLayoutBar from '../bar/tank-layout-bar';

class TankLayoutCard extends React.PureComponent {
    static getFormattedNumber(value, decimals, unit = '') {
        return typeof value === 'number' ? `${formatNumber(value, decimals)} ${unit}` : '-';
    }

    render() {
        const { wing, side, className } = this.props;
        const data = wing[side];
        const headerClass = getClassName('flex sten-tank-layout-card__header', {
            'sten-tank-layout-card__header--slop': wing.IsSlop
        });
        const commodityClass = getClassName('sten-tank-layout-card__body-commodity', {
            'sten-tank-layout-card__body-commodity--slop': data.IsSlop && !!data.Commodity
        });
        return (
            <div className={`sten-tank-layout-card${className ? ` ${className}` : ''}`}>
                <div className={headerClass}>
                    <div className="flex-grow">
                        <h4>{`${wing.IsSlop ? 'S' : wing.Index + 1}${side === 'Port' ? 'P' : 'S'}`}</h4>
                    </div>
                    <div className="flex-shrink">
                        <h4>{data.TankUtilization ? `${formatNumber(data.TankUtilization, 0)}%` : '-'}</h4>
                    </div>
                </div>
                <TankLayoutBar value={data.TankUtilization} isSlop={data.IsSlop} />
                <div className="sten-tank-layout-card__body">
                    <div className="form-row">
                        <p className="text-secondary">{translate('TANK_LAYOUT.COMMODITY')}</p>
                        <p className={commodityClass}>{data.Commodity ? data.Commodity.toLowerCase() : '-'}</p>
                    </div>
                    <div className="form-row flex-row">
                        <div className="col-12">
                            <p className="text-secondary">{translate('TANK_LAYOUT.SIZE')}</p>
                            <p>{TankLayoutCard.getFormattedNumber(data.Size, 0, translate('UNITS.CUBIC_CAPACITY'))}</p>
                        </div>
                        <div className="col-12">
                            <p className="text-secondary">{translate('TANK_LAYOUT.LOADED')}</p>
                            <p>
                                {TankLayoutCard.getFormattedNumber(data.Loaded, 0, translate('UNITS.CUBIC_CAPACITY'))}
                            </p>
                        </div>
                    </div>
                    {!this.props.isCollapsed && !data.IsSlop && (
                        <React.Fragment>
                            <div className="form-row">
                                <p className="text-secondary">{translate('TANK_LAYOUT.CHARTERER')}</p>
                                <p className="text-capitalize">{data.Charterer ? data.Charterer.toLowerCase() : '-'}</p>
                            </div>
                            <div className="form-row flex-row">
                                <div className="col-12">
                                    <p className="text-secondary">
                                        {translate('TANK_LAYOUT.LOADED_TEMPERATURE')}
                                    </p>
                                    <p>
                                        {TankLayoutCard.getFormattedNumber(
                                            data.Temperature, 1, translate('UNITS.TEMPERATURE')
                                        )}
                                    </p>
                                </div>
                                <div className="col-12">
                                    <p className="text-secondary">{translate('TANK_LAYOUT.SG')}</p>
                                    <p>{TankLayoutCard.getFormattedNumber(data.Sg, 4)}</p>
                                </div>
                            </div>
                            <div className="form-row flex-row">
                                <div className="col-24">
                                    <p className="text-secondary">{translate('TANK_LAYOUT.CARGO_WEIGHT')}</p>
                                    <p>
                                        {TankLayoutCard.getFormattedNumber(
                                            data.CargoWeight, 1, translate('UNITS.CARGO')
                                        )}
                                    </p>
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}

TankLayoutCard.propTypes = {
    className: PropTypes.string,
    isCollapsed: PropTypes.bool,
    side: PropTypes.oneOf(['Port', 'Starboard']).isRequired,
    wing: PropTypes.shape({
        Index: PropTypes.number,
        IsSlop: PropTypes.bool,
        Port: PropTypes.shape({
            CargoWeight: PropTypes.number,
            Charterer: PropTypes.string,
            Commodity: PropTypes.string,
            IsSlop: PropTypes.bool,
            Loaded: PropTypes.number,
            Sg: PropTypes.number,
            Size: PropTypes.number,
            TankUtilization: PropTypes.number,
            Temperature: PropTypes.number
        }),
        Starboard: PropTypes.shape({
            CargoWeight: PropTypes.number,
            Charterer: PropTypes.string,
            Commodity: PropTypes.string,
            IsSlop: PropTypes.bool,
            Loaded: PropTypes.number,
            Sg: PropTypes.number,
            Size: PropTypes.number,
            TankUtilization: PropTypes.number,
            Temperature: PropTypes.number
        })
    }).isRequired
};

TankLayoutCard.defaultProps = {
    className: '',
    isCollapsed: false
};

export default TankLayoutCard;
