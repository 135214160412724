import { ActionTypes } from './voyage-report-actions';
import { ActionTypes as ReportActionTypes } from 'components/vessel-report/vessel-report-actions';

const initialState = {
    isMounted: false,
    shownFullScreenReports: '',
    generatedReportsCounter: 0,
    counts: null,
    summaryReport: null,
    vesselCii: null,
    voyageVmsFootprint: null,
    shouldRecalculateReport: false
};

export default function energyManagementReportsVoyageReportReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ENERGY_MANAGEMENT_VOYAGE_REPORT_SET_MOUNTED: {
        return { ...state, isMounted: true };
    }
    case ActionTypes.ENERGY_MANAGEMENT_VOYAGE_REPORT_SET: {
        return {
            ...state,
            vesselCii: action.vesselCii,
            voyageVmsFootprint: action.voyageVmsFootprint,
            shownFullScreenReports: action.isRecalculate
                ? state.shownFullScreenReports
                : initialState.shownFullScreenReports,
            counts: action.summaryReport ? action.summaryReport.ReportCounts : null,
            summaryReport: action.summaryReport,
            generatedReportsCounter: state.generatedReportsCounter + 1,
            shouldRecalculateReport: false
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_VOYAGE_REPORT_TOGGLE_FULL_SCREEN: {
        return {
            ...state,
            shownFullScreenReports: action.reportType
        };
    }
    case ReportActionTypes.VESSEL_REPORT_SUCCESSFULLY_DELETED: {
        if (state.isMounted && !state.shouldRecalculateReport) {
            return { ...state, shouldRecalculateReport: true };
        }
        return state;
    }
    case ReportActionTypes.VESSEL_REPORT_SUCCESSFULLY_UPDATED: {
        if (state.isMounted && !state.shouldRecalculateReport) {
            return { ...state, shouldRecalculateReport: true };
        }
        return state;
    }
    case ActionTypes.ENERGY_MANAGEMENT_VOYAGE_REPORT_RESET_ALL: {
        return initialState;
    }
    default:
        return state;
    }
}
