import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
/* helpers */
import { getClassName } from 'utils/helpers/info-helper';
/* styles */
import './right-side-bar.scss';
/* config */
import config from 'app-config';

export class RightSideBar extends React.PureComponent {
    render() {
        const className = getClassName('sten-right-sidebar clearfix', this.props.className, {
            'sten-right-sidebar--collapsed': this.props.isCollapsed
        });

        return (
            <aside className={className} style={this.props.style}>
                {this.props.buttonRenderer && (
                    <div className="sten-right-sidebar__button-container">{this.props.buttonRenderer()}</div>
                )}
                <TransitionGroup className="sten-right-sidebar__panel-container">
                    {React.Children.map(this.props.children, child => {
                        if (!child) return null;
                        return (
                            <CSSTransition
                                key={child.key}
                                classNames="transition-fade"
                                timeout={config.transitionLength}
                                {...this.props.transitionProps}
                            >
                                {child}
                            </CSSTransition>
                        );
                    })}
                </TransitionGroup>
            </aside>
        );
    }
}

RightSideBar.propTypes = {
    buttonRenderer: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    isCollapsed: PropTypes.bool,
    style: PropTypes.objectOf(PropTypes.any),
    transitionProps: PropTypes.objectOf(PropTypes.any)
};

RightSideBar.defaultProps = {
    buttonRenderer: undefined,
    children: null,
    className: '',
    isCollapsed: true,
    style: null,
    transitionProps: null
};

export default RightSideBar;
