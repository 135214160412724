import React from 'react';
import PropTypes from 'prop-types';
/* ol */
import Map from 'ol/Map';
import View from 'ol/View';
import Overlay from 'ol/Overlay';
/* ol control */
import ControlZoom from 'ol/control/Zoom';
/* ol proj */
import { transform as projTransform, transformExtent as projTransformExtent } from 'ol/proj';
/* ol layers */
import LayerTile from 'ol/layer/Tile';
import LayerVector from 'ol/layer/Vector';
import LayerVectorTile from 'ol/layer/VectorTile';
/* ol source */
import SourceXYZ from 'ol/source/XYZ';
import SourceVector from 'ol/source/Vector';
import SourceVectorTile from 'ol/source/VectorTile';
import SourceTileWMS from 'ol/source/TileWMS';
/* ol format */
import FormatKML from 'ol/format/KML';
import FormatGeoJSON from 'ol/format/GeoJSON';
import FormatMVT from 'ol/format/MVT';
/* ol interaction */
import { defaults as interactionDefaults } from 'ol/interaction';
/* ol geometries */
import GeomLineString from 'ol/geom/LineString';
/* helpers */
import { fixRouteCoordinates, roundNumber } from './ol-helpers';
/* handlers */
import MapHandlers from './ol-map-handlers';
/* vectors */
import {
    getAreaVector,
    getClusterVector,
    getGeoJsonVector,
    getGraticuleVector,
    getLineVector,
    getMultiLineVector,
    getPointVector,
    getIconVector,
    getHeatMapVector
} from './ol-map-vectors';
/* features */
import {
    getPointFeature,
    getGraticuleFeature,
    getAreaFeature,
    getHeatMapFeature
} from './ol-map-features';
/* components */
import OlMapCopyrightInfo from './copyright-info/copyright-info';
import OlMapCoordinateInfo from './coordinate-info/coordinate-info';
/* styles */
import './ol.scss';
/* config */
import MapConfig from './configs/map';
import Graticule from './configs/graticule';

const mapContainerId = 'olMap';
const EPSG_3857_WORLD_WIDTH = 40075016; // 360 degrees in EPSG:3857 map projection

export class OlMap extends React.PureComponent {
    state = {
        map: null,
        view: null
    };

    layers = {};

    overlays = {};

    featureClickListeners = {};

    awayClickListeners = {};

    featureHoverListeners = {};

    featurePostRenderListeners = {};

    getChildContext() {
        return {
            addLayer: this.addLayer,
            addListeners: this.addListeners,
            layerChanged: this.layerChanged,
            removeLayer: this.removeLayer,
            removeListeners: this.removeListeners,
            updateLayerProp: this.updateLayerProp
        };
    }

    componentDidMount() {
        this.generateMap(this.props.mapViewProps);
    }

    componentDidUpdate(prevProps) {
        if (this.props.zoomControlElement && prevProps.zoomControlElement !== this.props.zoomControlElement) {
            this.addZoomControl(this.props.zoomControlElement);
        }
        if (prevProps.mapTypeId !== this.props.mapTypeId
            || prevProps.countryBordersNamesEnabled !== this.props.countryBordersNamesEnabled) {
            this.setMapSource(this.props.mapTypeId, this.props.countryBordersNamesEnabled);
        }
        if (prevProps.mapTypeId !== this.props.mapTypeId
            || prevProps.countryBordersNamesEnabled !== this.props.countryBordersNamesEnabled
            || prevProps.showClassicLandOverlay !== this.props.showClassicLandOverlay) {
            this.updateLandOverlay(
                this.props.showClassicLandOverlay,
                this.props.mapTypeId,
                this.props.countryBordersNamesEnabled
            );
        }
        if (this.props.mapViewProps && prevProps.mapViewProps !== this.props.mapViewProps) {
            this.setCenter(this.props.mapViewProps.center, this.props.mapViewProps.zoom, undefined, false);
        }
    }

    componentWillUnmount() {
        MapHandlers.removeEventHandlers(this.state.map);
    }

    setHandlers() {
        MapHandlers.setHoverHandler(
            this.state.map, this.layers, this.featureClickListeners, this.handleHover, this.featureHoverListeners
        );
        MapHandlers.setClickHandler(this.state.map, this.fireClickListeners, this.resetZoom);
        MapHandlers.setPostRenderHandler(this.state.map, this.props.onPostRender);
        MapHandlers.setRenderCompleteHandler(this.state.map, this.props.onRenderComplete);
        MapHandlers.setWindowResizeHandler(this.setMinZoom);
    }

    setMapSource(mapTypeId, countryBordersNamesEnabled) {
        const mapSources = {
            1: 'classic',
            2: 'satellite'
        };
        if (this.layers.mapTiles) {
            this.state.map.removeLayer(this.layers.mapTiles);
        }
        if (mapSources[mapTypeId]) {
            const mapType = `${mapSources[mapTypeId]}${countryBordersNamesEnabled ? 'NamesAndBordersEnabled' : ''}`;
            this.layers.mapTiles = new LayerTile({
                source: new SourceXYZ({
                    url: MapConfig.getTileUrl(mapType),
                    wrapY: false
                }),
                preload: Infinity
            });
            this.state.map.addLayer(this.layers.mapTiles);
        }
    }

    updateLandOverlay = (showClassicLandOverlay, mapTypeId, countryBordersNamesEnabled) => {
        const landType = `classicLand${countryBordersNamesEnabled ? 'NamesAndBordersEnabled' : ''}`;

        if (this.layers.overlayLand) {
            if (!showClassicLandOverlay) {
                this.removeLayer('overlayLand');
            } else {
                this.layers.overlayLand.setSource(new SourceXYZ({
                    url: MapConfig.getTileUrl(landType),
                    wrapY: false
                }));
            }
        } else if (showClassicLandOverlay) {
            this.layers.overlayLand = new LayerTile({
                source: new SourceXYZ({
                    url: MapConfig.getTileUrl(landType),
                    wrapY: false
                }),
                zIndex: MapConfig.layerZIndex.weatherLayer + 1
            });
            this.state.map.addLayer(this.layers.overlayLand);
        }
    }

    getPixelFromCoordinate(coordinates, fixCenter = true, transform = true) {
        const transformedCoordinates = transform ? this.transformCoordinates(coordinates) : coordinates;
        const fixedCoordinates = fixCenter ? this.fixCoordinates(transformedCoordinates) : transformedCoordinates;
        return this.state.map.getPixelFromCoordinate(fixedCoordinates);
    }

    getCenter() {
        const center = this.state.view ? this.state.view.getCenter() : null;
        return center || MapConfig.startPosition;
    }

    getZoom = () => {
        const zoom = this.state.view ? this.state.view.getZoom() : null;
        return roundNumber(zoom, 1) || MapConfig.minZoom;
    };

    getSize = () => (this.state.map ? this.state.map.getSize() : null);

    getRelativeZoom = (zoom) => {
        return zoom
            ? Math.round((zoom - MapConfig.minZoom) + 1)
            : Math.round((this.getZoom() - MapConfig.minZoom) + 1);
    };

    getExtent() {
        if (this.state.map && this.state.view) {
            const size = this.state.map.getSize();
            const extent = this.state.view.calculateExtent(size); // in meters
            return projTransformExtent(extent, MapConfig.targetProjection, MapConfig.sourceProjection);
        }
        return [];
    }

    updateSize = () => {
        if (this.state.map) {
            this.state.map.updateSize();
            this.setMinZoom();
        }
    };

    setCenter = (coordinates, zoom, duration = 500, transform = true) => {
        const center = transform
            ? projTransform(coordinates, MapConfig.sourceProjection, MapConfig.targetProjection)
            : coordinates;
        const props = {
            center,
            duration
        };
        if (zoom) {
            props.zoom = (zoom + MapConfig.minZoom) - 1;
        } else if (zoom === 0) {
            props.zoom = MapConfig.minZoom;
        }

        if (this.state.view) {
            this.state.view.animate(props);
        }
    };

    setMinZoom = () => {
        if (!this.map) {
            return;
        }
        /* Empirically determined function */
        const minZoom = parseFloat((Math.log2(this.map.scrollHeight / 365) + 1).toFixed(1));
        MapConfig.minZoom = minZoom >= 1 ? minZoom : 1;
        if (this.state.view && this.state.view.setMinZoom) {
            this.state.view.setMinZoom(MapConfig.minZoom);
            this.state.view.changed();
        }
    };

    getView(mapViewProps) {
        this.setMinZoom(mapViewProps);
        return new View({
            enableRotation: false,
            showFullExtent: true,
            zoom: mapViewProps && mapViewProps.zoom ? mapViewProps.zoom : MapConfig.minZoom,
            minZoom: MapConfig.minZoom,
            maxZoom: MapConfig.maxZoom,
            center: mapViewProps && mapViewProps.center
                ? mapViewProps.center
                : MapConfig.startPosition
        });
    }

    static getMap(view) {
        return new Map({
            interactions: interactionDefaults({
                altShiftDragRotate: false,
                pinchRotate: false
            }),
            controls: [],
            loadTilesWhileInteracting: true,
            layers: [],
            target: mapContainerId,
            view
        });
    }

    fitElements(elementCoordinates, options) {
        const fitOptions = options || { padding: [80, 600, 80, 80], duration: 500 };
        if (elementCoordinates.length > 1) {
            const lineString = new GeomLineString(fixRouteCoordinates(elementCoordinates)).transform(
                MapConfig.sourceProjection,
                MapConfig.targetProjection
            );
            if (this.fitElementsTimeout) {
                clearTimeout(this.fitElementsTimeout);
            }
            this.fitElementsTimeout = setTimeout(() => {
                this.state.view.fit(lineString, fitOptions);
            }, 200);
        }
    }

    generateMap(mapViewProps) {
        const view = this.getView(mapViewProps);
        const map = OlMap.getMap(view);
        this.setState({ view, map }, () => {
            this.setHandlers();
            // this.drawGraticule();
            this.setMapSource(this.props.mapTypeId, this.props.countryBordersNamesEnabled);
            this.updateLandOverlay(
                this.props.showClassicLandOverlay,
                this.props.mapTypeId,
                this.props.countryBordersNamesEnabled
            );
            this.addZoomControl(this.props.zoomControlElement);
        });
    }

    resetZoom = () => {
        if (this.state.view) {
            const { mapViewProps, shouldResetToInitial } = this.props;
            let zoom = MapConfig.minZoom;
            let center = MapConfig.startPosition;
            if (shouldResetToInitial && mapViewProps) {
                if (mapViewProps.zoom) {
                    zoom = mapViewProps.zoom;
                }
                if (mapViewProps.center) {
                    center = mapViewProps.center;
                }
            }
            this.state.view.animate({
                center,
                duration: 300,
                zoom
            });
        }
    };

    addZoomControl(targetRenderElement) {
        if (this.state.map && targetRenderElement) {
            if (this.mapZoomControl) {
                this.state.map.removeControl(this.mapZoomControl);
            }
            this.mapZoomControl = new ControlZoom({
                target: targetRenderElement,
                className: 'sten-map-zoom-controls',
                zoomInLabel: '',
                zoomOutLabel: '',
                zoomInTipLabel: '',
                zoomOutTipLabel: ''
            });
            this.state.map.addControl(this.mapZoomControl);
        }
    }

    transformCoordinates = (coordinates, toSource = false) => projTransform(
        coordinates,
        toSource ? MapConfig.targetProjection : MapConfig.sourceProjection,
        toSource ? MapConfig.sourceProjection : MapConfig.targetProjection
    );

    fixCoordinates(coordinates) {
        const centerOfMap = this.state.view.getCenter();
        const delta = (centerOfMap[0] - coordinates[0]) / EPSG_3857_WORLD_WIDTH;
        const fixedX = coordinates[0] + (EPSG_3857_WORLD_WIDTH * Math.round(delta));
        return [fixedX, coordinates[1]];
    }

    drawGraticule() {
        this.drawGraticuleLines(Graticule.smallLines, Graticule.linesConfig);
        this.drawGraticuleLines(Graticule.groupLines, Graticule.groupLinesConfig);
        this.drawGraticuleLines(Graticule.crossLines, Graticule.crossLinesConfig);
    }

    drawGraticuleLines(lines, config) {
        const features = [getGraticuleFeature(lines)];
        const vector = getGraticuleVector(features, config);
        this.state.map.addLayer(vector);
    }

    addListeners = (layerName, onClick, onAwayClick, onHover, onPostRender) => {
        if (onClick) {
            this.featureClickListeners[layerName] = onClick;
        }
        if (onAwayClick) {
            this.awayClickListeners[layerName] = onAwayClick;
        }
        if (onHover) {
            this.featureHoverListeners[layerName] = onHover;
        }
        if (onPostRender) {
            this.featurePostRenderListeners[layerName] = (e) => {
                onPostRender(e, this.state.map);
            };
            this.layers[layerName].on('postrender', this.featurePostRenderListeners[layerName]);
        }
    };

    removeListeners = layerName => {
        if (this.featureClickListeners[layerName]) {
            delete this.featureClickListeners[layerName];
        }
        if (this.awayClickListeners[layerName]) {
            delete this.awayClickListeners[layerName];
        }
        if (this.featureHoverListeners[layerName]) {
            delete this.featureHoverListeners[layerName];
        }
        if (this.featurePostRenderListeners[layerName]) {
            this.layers[layerName].un('postrender', this.featurePostRenderListeners[layerName]);
            delete this.featurePostRenderListeners[layerName];
        }
    };

    fireClickListeners = (features, event) => {
        let clickedFeature = null;
        if (features && features.length) {
            for (let i = 0; i < features.length; i++) {
                if (features[i].get('layerName') && this.featureClickListeners[features[i].get('layerName')]) {
                    if (features[i].isHovered) {
                        clickedFeature = features[i];
                        clickedFeature.isHovered = false;
                        clickedFeature.changed();
                        break;
                    } else if (clickedFeature) {
                        const currZIndex = this.layers[features[i].get('layerName')].getZIndex();
                        const prevZIndex = this.layers[clickedFeature.get('layerName')].getZIndex();
                        if (currZIndex > prevZIndex) {
                            clickedFeature = features[i];
                        }
                    } else {
                        clickedFeature = features[i];
                    }
                }
            }
        }
        const shouldFireClickListeners = this.props.onClick
            ? this.props.onClick(event, clickedFeature)
            : true;
        if (shouldFireClickListeners !== false) {
            const layerName = (clickedFeature && clickedFeature.get('layerName')) || null;
            if (layerName && this.featureClickListeners[layerName]) {
                this.featureClickListeners[layerName](clickedFeature);
            }
            Object.keys(this.awayClickListeners).forEach((key) => {
                if (!layerName || key !== layerName) {
                    this.awayClickListeners[key]();
                }
            });
        }
    };

    removeOverlays = name => {
        if (this.overlays[name]) {
            Object.keys(this.overlays[name]).forEach((key) => {
                this.state.map.removeOverlay(this.overlays[name][key]);
            });
            delete this.overlays[name];
        }
    };

    updateLayerOverlay = (layerName, element, props) => {
        const zoom = this.getZoom();
        const relativeZoom = parseInt(zoom - MapConfig.minZoom, 10) + 1;
        if (!this.overlays[layerName]) {
            this.overlays[layerName] = {};
        }
        if (!this.overlays[layerName][props.id]) {
            if (props.id && element && props.position && !props.hidden
                && (props.isActive || props.minZoom <= relativeZoom)) {
                this.overlays[layerName][props.id] = new Overlay({
                    id: props.id,
                    element,
                    position: this.fixCoordinates(this.transformCoordinates(props.position)),
                    stopEvent: false,
                    positioning: props.positioning,
                    offset: props.offset
                });
                this.state.map.addOverlay(this.overlays[layerName][props.id]);
            }
        } else {
            const overlay = this.overlays[layerName][props.id];
            if ((props.minZoom > relativeZoom && !props.isActive) || props.hidden) {
                this.state.map.removeOverlay(overlay);
                delete this.overlays[layerName][props.id];
            } else {
                overlay.setPosition(this.fixCoordinates(this.transformCoordinates(props.position)));
            }
        }
    };

    addLayer = (name, type, elements, elementProps, zIndex, opacity, layerProps, sourceProps) => {
        if (this.state.map) {
            switch (type) {
            case 'point':
                return this.drawPointLayer(name, elements, elementProps, zIndex, opacity, layerProps);
            case 'icon':
                return this.drawIconLayer(name, elements, elementProps, zIndex, opacity, layerProps);
            case 'line':
                return this.drawLineLayer(name, elements, elementProps, zIndex, opacity, layerProps);
            case 'multiLine':
                return this.drawMultiLineLayer(name, elements, elementProps, zIndex, opacity, layerProps);
            case 'area':
                return this.drawAreaLayer(name, elements, elementProps, zIndex, opacity, layerProps);
            case 'kml':
                return this.drawKMLLayer(name, elements, elementProps, zIndex, opacity, layerProps);
            case 'geoJson':
                return this.drawGeoJsonLayer(name, elements, elementProps, zIndex, opacity, layerProps);
            case 'heatMap':
                return this.drawHeatMapLayer(name, elements, elementProps, zIndex, opacity, layerProps);
            case 'vectorTile':
                return this.drawVectorTileLayer(name, elements, elementProps, zIndex, opacity, layerProps, sourceProps);
            case 'wmsTile':
                return this.drawWMSTileLayer(name, elements, elementProps, zIndex, opacity, layerProps, sourceProps);
            case 'cluster':
                return this.drawClusterLayer(name, elements, elementProps, zIndex, opacity, layerProps, sourceProps);
            default:
                return null;
            }
        }
        return null;
    };

    removeLayer = name => {
        if (this.state.map && name) {
            this.removeListeners(name);
            if (this.layers[name]) {
                this.state.map.removeLayer(this.layers[name]);
                delete this.layers[name];
            }
        }
    };

    updateLayerProp = (name, prop, value) => {
        if (this.layers[name]) {
            this.layers[name].set(prop, value);
        }
    };

    layerChanged = name => {
        if (this.layers && this.layers[name] && this.layers[name].changed) {
            this.layers[name].changed();
        }
    };

    drawPointLayer(name, elements, props, zIndex, opacity, layerProps) {
        if (this.layers[name]) {
            this.state.map.removeLayer(this.layers[name]);
        }
        if (this.overlays[name]) {
            this.removeOverlays(name);
        }

        const features = elements.reduce((res, element) => {
            const feature = getPointFeature(name, element, props);
            if (feature) {
                res.push(feature);
            }
            return res;
        }, []);

        this.layers[name] = getPointVector(
            name, features, zIndex, this.getZoom, this.updateLayerOverlay, opacity, layerProps
        );
        this.state.map.addLayer(this.layers[name]);
    }

    drawClusterLayer(name, elements, props, zIndex, opacity, layerProps, sourceProps) {
        if (this.layers[name]) {
            this.state.map.removeLayer(this.layers[name]);
        }
        if (this.overlays[name]) {
            this.removeOverlays(name);
        }

        const features = elements.reduce((res, element) => {
            const feature = getPointFeature(name, element, props);
            if (feature) {
                res.push(feature);
            }
            return res;
        }, []);

        this.layers[name] = getClusterVector(
            name, features, zIndex, this.getZoom, this.updateLayerOverlay, opacity, layerProps, sourceProps
        );
        this.state.map.addLayer(this.layers[name]);
    }

    drawIconLayer(name, elements, props, zIndex, opacity, layerProps) {
        if (this.layers[name]) {
            this.state.map.removeLayer(this.layers[name]);
        }
        if (this.overlays[name]) {
            this.removeOverlays(name);
        }

        const features = elements.reduce((res, element) => {
            const feature = getPointFeature(name, element, props);
            if (feature) {
                res.push(feature);
            }
            return res;
        }, []);

        this.layers[name] = getIconVector(
            name, features, zIndex, this.getZoom, this.updateLayerOverlay, opacity, layerProps
        );
        this.state.map.addLayer(this.layers[name]);
    }

    drawLineLayer = (name, line, props, zIndex, opacity, layerProps) => {
        if (this.layers[name]) {
            this.state.map.removeLayer(this.layers[name]);
        }
        this.layers[name] = getLineVector(name, line, props, zIndex, this.getZoom, opacity, layerProps);
        this.state.map.addLayer(this.layers[name]);
    };

    drawMultiLineLayer = (name, lines, props, zIndex, opacity, layerProps) => {
        if (this.layers[name]) {
            this.state.map.removeLayer(this.layers[name]);
        }

        this.layers[name] = getMultiLineVector(name, lines, props, zIndex, this.getZoom, opacity, layerProps);
        this.state.map.addLayer(this.layers[name]);
    };

    drawAreaLayer = (name, areas, props, zIndex, opacity, layerProps) => {
        if (this.layers[name]) {
            this.state.map.removeLayer(this.layers[name]);
        }

        if (areas) {
            const features = [];
            Object.keys(areas).forEach((key) => {
                areas[key].forEach((area) => {
                    features.push(getAreaFeature(name, area, props));
                });
            });
            this.layers[name] = getAreaVector(name, features, zIndex, opacity, layerProps);
            this.state.map.addLayer(this.layers[name]);
        }
    };

    drawKMLLayer(name, kml, props, zIndex, opacity, layerProps) {
        if (this.layers[name]) {
            this.state.map.removeLayer(this.layers[name]);
        }

        if (kml) {
            this.layers[name] = new LayerVector({
                source: new SourceVector({
                    features: (new FormatKML()).readFeatures(kml, {
                        dataProjection: MapConfig.sourceProjection,
                        featureProjection: MapConfig.targetProjection
                    })
                }),
                zIndex,
                opacity,
                ...layerProps
            });
            this.state.map.addLayer(this.layers[name]);
        }
    }

    drawGeoJsonLayer(name, geoJson, props, zIndex, opacity, layerProps) {
        if (this.layers[name]) {
            this.state.map.removeLayer(this.layers[name]);
        }

        if (geoJson) {
            const features = (new FormatGeoJSON({
                featureProjection: MapConfig.targetProjection
            })).readFeatures(geoJson);
            features.forEach((feature) => {
                feature.set('layerName', name);
                feature.set('props', props);
            });
            this.layers[name] = getGeoJsonVector(name, features, zIndex, opacity, layerProps);
            this.state.map.addLayer(this.layers[name]);
        }
    }

    drawVectorTileLayer(name, vector, props, zIndex, opacity, layerProps, sourceProps) {
        if (this.layers[name]) {
            this.state.map.removeLayer(this.layers[name]);
        }
        if (vector) {
            const { tileUrl, metadata } = vector;
            this.layers[name] = new LayerVectorTile({
                source: new SourceVectorTile({
                    format: new FormatMVT(),
                    url: tileUrl,
                    ...metadata && {
                        minZoom: metadata.minZoom,
                        maxZoom: metadata.maxZoom
                    },
                    ...sourceProps
                }),
                opacity,
                zIndex,
                layerName: name,
                ...layerProps.props,
                style: (feature) => (layerProps.style
                    ? layerProps.style(
                        feature,
                        props,
                        name,
                        this.getZoom,
                        this.updateLayerOverlay,
                        opacity,
                        layerProps
                    )
                    : null)
            });

            this.state.map.addLayer(this.layers[name]);
        }
    }

    drawWMSTileLayer(name, data, props, zIndex, opacity, layerProps, sourceProps) {
        if (this.layers[name]) {
            this.state.map.removeLayer(this.layers[name]);
        }
        this.layers[name] = new LayerTile({
            source: new SourceTileWMS(sourceProps),
            opacity,
            zIndex,
            layerName: name,
            ...layerProps
        });

        this.state.map.addLayer(this.layers[name]);
    }

    drawHeatMapLayer(name, elements, props, zIndex, opacity, layerProps) {
        if (this.layers[name]) {
            this.state.map.removeLayer(this.layers[name]);
        }
        if (this.overlays[name]) {
            this.removeOverlays(name);
        }

        const features = elements.reduce((res, element) => {
            const feature = getHeatMapFeature(name, element, props);
            if (feature) {
                res.push(feature);
            }
            return res;
        }, []);

        this.layers[name] = getHeatMapVector(
            name, features, zIndex, this.getZoom, this.updateLayerOverlay, opacity, layerProps
        );
        this.state.map.addLayer(this.layers[name]);
    }

    handleWheel = (e) => {
        const zoom = this.getZoom();
        if ((e.deltaY > 0 && MapConfig.minZoom && zoom !== MapConfig.minZoom)
            || (e.deltaY < 0 && MapConfig.maxZoom && zoom !== MapConfig.maxZoom)) {
            e.stopPropagation();
        }
    };

    handleHover = (e, feature) => {
        if (this.props.onHover) {
            this.props.onHover(e, feature);
        }
        if (this.coordinateInfo) {
            this.coordinateInfo.handlePointerMove(e);
        }
    };

    saveMapRef = (c) => { this.map = c; };

    saveCoordinateInfoRef = (c) => { this.coordinateInfo = c; };

    render() {
        return (
            <div className="sten-ol-map" id={mapContainerId} ref={this.saveMapRef} onWheel={this.handleWheel}>
                {this.props.showCopyrightInfo && <OlMapCopyrightInfo mapTypeId={this.props.mapTypeId} />}
                {this.props.showCoordinates && (
                    <OlMapCoordinateInfo map={this.state.map} ref={this.saveCoordinateInfoRef} />
                )}
                {this.state.map && this.state.view && this.props.children}
            </div>
        );
    }
}

OlMap.childContextTypes = {
    addLayer: PropTypes.func,
    addListeners: PropTypes.func,
    layerChanged: PropTypes.func,
    removeLayer: PropTypes.func,
    removeListeners: PropTypes.func,
    updateLayerProp: PropTypes.func
};

OlMap.propTypes = {
    children: PropTypes.node,
    countryBordersNamesEnabled: PropTypes.bool,
    showClassicLandOverlay: PropTypes.bool,
    mapTypeId: PropTypes.oneOf([1, 2, 3]).isRequired,
    mapViewProps: PropTypes.objectOf(PropTypes.any),
    onClick: PropTypes.func,
    onHover: PropTypes.func,
    onPostRender: PropTypes.func,
    onRenderComplete: PropTypes.func,
    shouldResetToInitial: PropTypes.bool,
    showCoordinates: PropTypes.bool,
    showCopyrightInfo: PropTypes.bool,
    zoomControlElement: PropTypes.objectOf(PropTypes.any)
};

OlMap.defaultProps = {
    children: null,
    countryBordersNamesEnabled: false,
    showClassicLandOverlay: false,
    mapViewProps: null,
    onClick: undefined,
    onHover: undefined,
    onPostRender: undefined,
    onRenderComplete: undefined,
    shouldResetToInitial: false,
    showCoordinates: true,
    showCopyrightInfo: true,
    zoomControlElement: null
};

export default OlMap;
