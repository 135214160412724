export const ActionTypes = {
    GSM_MAP_VIEW_SWITCH_SET_MAP_TYPE: 'GSM_MAP_VIEW_SWITCH_SET_MAP_TYPE'
};

export function setMapType(dispatch, mapType) {
    dispatch({
        type: ActionTypes.GSM_MAP_VIEW_SWITCH_SET_MAP_TYPE,
        mapType
    });
}
