import { takeEvery, all, put, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* services */
import VesselService from 'services/core-api/vessel-service';
import FleetManagementService from 'services/core-api/fleet-management-service';
import DynamicFleetService from 'services/core-api/dynamic-fleet-service';
/* actions */
import { ActionTypes } from './fleet-management-actions';
import { ActionTypes as UserActionTypes } from '../user-actions';
import { ActionTypes as ATFMActionTypes } from 'components/assign-to-fleet-modal/assign-to-fleet-modal-actions';

function* getFleets(shouldSave = true) {
    const { permissions, user } = yield select(state => state.userReducer);

    const { privateFleets, publicFleets } = yield all({
        privateFleets: permissions.ShowFleetManagementPrivateGroups ? FleetManagementService.getPrivate() : null,
        publicFleets: permissions.ShowFleetManagementPublicGroups ? FleetManagementService.getPublic() : null
    });
    const fleets = {
        private: privateFleets,
        public: publicFleets ? publicFleets.filter(fleet => fleet.UserId !== user.UserId) : null
    };
    if (shouldSave) {
        yield put({ type: ActionTypes.FLEET_MANAGEMENT_SET_FLEETS, fleets });
    }
    return fleets;
}

function* addToMyFleet(action) {
    const response = yield FleetManagementService.addToMyFleet({ Imo: action.imo });
    if (response) {
        yield put({ type: UserActionTypes.USER_RESOURCE_UPDATED, resourceName: 'fleets' });
        yield getFleets();
        toast(translate('FLEET_MANAGEMENT.VESSEL_SUCCESSFULLY_ADDED_TO_YOUR_FLEET'), { type: toast.TYPE.SUCCESS });
    }
}

function* removeFromMyFleet(action) {
    const response = yield FleetManagementService.removeFromMyFleet(action.imo);
    if (response) {
        yield put({ type: UserActionTypes.USER_RESOURCE_UPDATED, resourceName: 'fleets' });
        yield getFleets();
        toast(translate('FLEET_MANAGEMENT.VESSEL_SUCCESSFULLY_REMOVED_FROM_YOUR_FLEET'), { type: toast.TYPE.SUCCESS });
    }
}

function* getInitialData() {
    const initialData = yield all({
        fleets: getFleets(false),
        companyFleets: VesselService.getCompanies(),
        segments: VesselService.getSegments(),
        technicalManagers: VesselService.getTechnicalManagers(),
        vesselOwners: VesselService.getHeadOwners(),
        mainAttributes: DynamicFleetService.getMainAttributes()
    });
    yield put({ type: ActionTypes.FLEET_MANAGEMENT_SET_INITIAL_DATA, ...initialData });
}

export default function* fleetManagementSaga() {
    yield takeEvery(ActionTypes.FLEET_MANAGEMENT_GET_INITIAL_DATA, getInitialData);
    yield takeEvery(ActionTypes.FLEET_MANAGEMENT_ADD_TO_MY_FLEET, addToMyFleet);
    yield takeEvery(ActionTypes.FLEET_MANAGEMENT_REMOVE_FROM_MY_FLEET, removeFromMyFleet);
    yield takeEvery(ATFMActionTypes.ASSIGN_TO_FLEET_MODAL_RELATIONSHIPS_SAVED, getFleets);
    yield takeEvery(ActionTypes.FLEET_MANAGEMENT_UPDATE_FLEETS, getFleets);
}
