import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import {
    generateXLSX,
    generatePDF,
    getPerformancePerVoyage
} from './fleet-performance-report-actions';
/* selectors */
import { getTableData, getPerformancePerVoyageData } from './fleet-performance-report-selectors';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import EmptyContent from 'components/empty-content/empty-content';
import ContentHeader from '../../shared/content-header';
import FleetPerformanceReportTypeATable from './type-a/fleet-performance-report-type-a-table';
import FleetPerformanceReportTypeBTable from './type-b/fleet-performance-report-type-b-table';
import FleetPerformanceReportPerVoyage from './fleet-performance-report-per-voyage';
/* styles */
import './fleet-performance-report.scss';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.${key}`);

const timeFormat = { utc: true };

class FleetPerformanceReport extends React.PureComponent {
    renderTable = (data) => {
        switch (ConfigService.featureToggles.fleetPerformanceReportType) {
        case 'A':
            return (
                <FleetPerformanceReportTypeATable
                    data={data}
                    getPerformancePerVoyage={this.props.getPerformancePerVoyage}
                />
            );
        case 'B':
            return (
                <FleetPerformanceReportTypeBTable
                    data={data}
                    getPerformancePerVoyage={this.props.getPerformancePerVoyage}
                />
            );
        default:
            return (
                <FleetPerformanceReportTypeATable
                    data={data}
                    getPerformancePerVoyage={this.props.getPerformancePerVoyage}
                />
            );
        }
    };

    render() {
        const { selectedRange, tableData, performancePerVoyage, generatePDF, generateXLSX } = this.props;
        const subtitle = selectedRange
            ? `${TimeHelper.getFormatted(selectedRange.rangeStart, timeFormat)}`
            + ` - ${TimeHelper.getFormatted(selectedRange.rangeEnd, timeFormat)}`
            : null;
        return (
            <div className="sten-content sten-content--has-footer">
                {performancePerVoyage && (
                    <FleetPerformanceReportPerVoyage
                        isPerformancePerVoyageModalOpen={this.props.isPerformancePerVoyageModalOpen}
                        performancePerVoyage={performancePerVoyage}
                    />
                )}
                <ContentHeader subtitle={subtitle} />
                {!tableData ? <EmptyContent>{t('EMPTY_REPORT')}</EmptyContent> : (
                    <React.Fragment>
                        <div className="sten-energy-management-reports-preview sten-content__body">
                            {this.renderTable(tableData)}
                        </div>
                        <footer className="sten-content__footer flex-row">
                            <div className="flex-grow hide-xs" />
                            <div className="flex-shrink">
                                <button onClick={generatePDF} className="btn btn--primary">
                                    {t('EXPORT_PDF')}
                                </button>
                            </div>
                            <div className="flex-shrink">
                                <button onClick={generateXLSX} className="btn btn--primary">
                                    {t('EXPORT_XLSX')}
                                </button>
                            </div>
                        </footer>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

FleetPerformanceReport.propTypes = {
    generatePDF: PropTypes.func.isRequired,
    generateXLSX: PropTypes.func.isRequired,
    getPerformancePerVoyage: PropTypes.func.isRequired,
    selectedRange: PropTypes.objectOf(PropTypes.any),
    tableData: PropTypes.shape({
        fuelTypes: PropTypes.objectOf(PropTypes.any),
        parsedData: PropTypes.objectOf(PropTypes.any)
    }),
    performancePerVoyage: PropTypes.shape({
        fuelTypes: PropTypes.objectOf(PropTypes.any),
        voyages: PropTypes.arrayOf(PropTypes.any),
        vesselName: PropTypes.string,
        vesselImo: PropTypes.number
    }),
    isPerformancePerVoyageModalOpen: PropTypes.bool.isRequired
};

FleetPerformanceReport.defaultProps = {
    selectedRange: null,
    tableData: null,
    performancePerVoyage: {
        fuelTypes: {},
        voyages: [],
        vesselName: '',
        vesselImo: null
    }
};

function mapStateToProps(state) {
    return {
        selectedRange: state.energyManagementReportsReducer.selectedRange,
        tableData: getTableData(state),
        performancePerVoyage: getPerformancePerVoyageData(state),
        isPerformancePerVoyageModalOpen:
            state.energyManagementReportsFleetPerformanceReportReducer.isPerformancePerVoyageModalOpen
    };
}

function mapDispatchToProps(dispatch) {
    return {
        generatePDF: () => generatePDF(dispatch),
        generateXLSX: () => generateXLSX(dispatch),
        getPerformancePerVoyage: (vesselName, vesselImo) => getPerformancePerVoyage(dispatch, vesselName, vesselImo)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FleetPerformanceReport);
