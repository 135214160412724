import React from 'react';
import PropTypes from 'prop-types';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
import MultiVoyageReportTableRow from './multi-voyage-report-table-row';
import MultiVoyageReportTableHead from './multi-voyage-report-table-head';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* style */
import './multi-voyage-report-table.scss';

const tableClassNames = 'sten-energy-management-reports-preview__multivoyage-table sten-table sten-table--xs';
const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.MULTI_VOYAGE_REPORT.TABLE_HEADER.${key}`);
const emphasizedClass = 'sten-energy-management-reports-preview__multivoyage-table--emphasized-column';

export default class EnergyManagementReportsPreviewMultiVoyageReportTable extends React.PureComponent {
    state = {
        highlightedRowVoyageNumber: null,
        rowHeights: [],
        selectedRows: {}
    };

    componentDidMount() {
        this.collectRowHeights();
        window.addEventListener('resize', this.collectRowHeights);
    }

    componentDidUpdate(prevProps) {
        if (this.props.voyageReports !== prevProps.voyageReports) {
            this.collectRowHeights();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.collectRowHeights);
    }

    handleRowClick = (rowKey) => {
        this.setState({
            ...this.state,
            selectedRows: {
                ...this.state.selectedRows,
                [rowKey]: !this.state.selectedRows[rowKey]
            }
        });
    };

    highlightRow = (rowKey) => {
        if (this.state.highlightedRowVoyageNumber !== rowKey) {
            this.setState({ highlightedRowVoyageNumber: rowKey });
        }
    };

    removeRowHighlight = this.highlightRow.bind(this, null);

    tableHeader = (
        <React.Fragment>
            <tr onMouseMove={this.removeRowHighlight}>
                <th colSpan={7}>{t('BALLAST')}</th>
                <th className={emphasizedClass}>{t('LOAD')}</th>
                <th colSpan={9}>{t('LADEN')}</th>
                <th className={emphasizedClass}>{t('DISCHARGE')}</th>
            </tr>
            <tr onMouseMove={this.removeRowHighlight}>
                <th>{t('INSTRUCTED_SPEED')}</th>
                <th>{t('TOTAL_TIME')}</th>
                <th>{t('TOTAL_DISTANCE')}</th>
                <th>{t('TOTAL_ME_CONS')}</th>
                <th>{t('AVG_ME_CONS_PER_DAY')}</th>
                <th>{t('AVG_SPEED')}</th>
                <th>{t('TOTAL_AUX_CONS')}</th>
                <th className={emphasizedClass}>{t('TOTAL_LOAD_CONS')}</th>
                <th>{t('CARGO_ONBOARD')}</th>
                <th>{t('CARGO_HEATED')}</th>
                <th>{t('INSTRUCTED_SPEED')}</th>
                <th>{t('TOTAL_TIME')}</th>
                <th>{t('TOTAL_DISTANCE')}</th>
                <th>{t('TOTAL_ME_CONS')}</th>
                <th>{t('AVG_ME_CONS_PER_DAY')}</th>
                <th>{t('AVG_SPEED')}</th>
                <th>{t('TOTAL_AUX_CONS')}</th>
                <th className={emphasizedClass}>{t('TOTAL_DISCH_CONS')}</th>
            </tr>
        </React.Fragment>
    );

    collectRowHeights = () => window.requestAnimationFrame(() => {
        if (this.tableBodyRef) {
            const trs = this.tableBodyRef.children;
            const rowHeights = new Array(trs.length);

            for (let i = 0; i < trs.length; i++) {
                const dimensions = trs[i].getBoundingClientRect();
                rowHeights[i] = dimensions.height;
            }

            this.setState({ rowHeights });
        }
    });

    renderLeftHeaderColumn() {
        return (
            <table
                className={`${tableClassNames}
                sten-energy-management-reports-preview__multivoyage-table__header-column`}
            >
                <MultiVoyageReportTableHead
                    voyageNumbers={this.props.voyageNumbers}
                    onHover={this.highlightRow}
                    onClick={this.handleRowClick}
                    rowHeights={this.state.rowHeights}
                    highlightedRowVoyageNumber={this.state.highlightedRowVoyageNumber}
                    selectedRows={this.state.selectedRows}
                />
            </table>
        );
    }

    renderReports() {
        return (
            <table className={tableClassNames}>
                <tbody ref={(r) => { this.tableBodyRef = r; }}>
                    {this.tableHeader}
                    {this.props.voyageReports.map((vr) => (
                        <MultiVoyageReportTableRow
                            voyageNumber={vr.VoyageNumber}
                            key={vr.VoyageNumber}
                            report={vr}
                            isSelected={this.state.selectedRows[vr.VoyageNumber] === true}
                            isHighlighted={vr.VoyageNumber === this.state.highlightedRowVoyageNumber}
                            onHover={this.highlightRow}
                            onClick={this.handleRowClick}
                        />
                    ), this)}
                </tbody>
            </table>
        );
    }

    tableStyles = {
        minWidth: '120rem',
        height: '100%'
    };

    render() {
        return (
            <div className="sten-energy-management-voyageReports-preview__raw-data flex flex-center">
                {this.renderLeftHeaderColumn()}
                <ScrollArea
                    horizontal
                    vertical={false}
                    contentStyle={this.tableStyles}
                >
                    {this.renderReports()}
                </ScrollArea>
            </div>
        );
    }
}

EnergyManagementReportsPreviewMultiVoyageReportTable.propTypes = {
    voyageNumbers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
    voyageReports: PropTypes.arrayOf(PropTypes.any).isRequired
};
