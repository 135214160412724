export const ActionTypes = {
    ADMIN_VESSEL_MANAGEMENT_FORM_GET_ALL_CLASS_LEVELS: 'ADMIN_VESSEL_MANAGEMENT_FORM_GET_ALL_CLASS_LEVELS',
    ADMIN_VESSEL_MANAGEMENT_FORM_SET_ICE_CLASS_LEVELS: 'ADMIN_VESSEL_MANAGEMENT_FORM_SET_ICE_CLASS_LEVELS',
    ADMIN_VESSEL_MANAGEMENT_FORM_GET_VESSEL_BY_IMO: 'ADMIN_VESSEL_MANAGEMENT_FORM_GET_VESSEL_BY_IMO',
    ADMIN_VESSEL_MANAGEMENT_FORM_SET_EDITING_VESSEL: 'ADMIN_VESSEL_MANAGEMENT_FORM_SET_EDITING_VESSEL',
    ADMIN_VESSEL_MANAGEMENT_FORM_SEARCH_VESSELS: 'ADMIN_VESSEL_MANAGEMENT_FORM_SEARCH_VESSELS',
    ADMIN_VESSEL_MANAGEMENT_FORM_SET_FOUND_VESSELS: 'ADMIN_VESSEL_MANAGEMENT_FORM_SET_FOUND_VESSELS',
    ADMIN_VESSEL_MANAGEMENT_FORM_TOGGLE_MANUAL_ADDING: 'ADMIN_VESSEL_MANAGEMENT_FORM_TOGGLE_MANUAL_ADDING',
    ADMIN_VESSEL_MANAGEMENT_FORM_SET_AS_NEW_COMPANY: 'ADMIN_VESSEL_MANAGEMENT_FORM_SET_AS_NEW_COMPANY',
    ADMIN_VESSEL_MANAGEMENT_FORM_GET_TECHNICAL_MANAGERS: 'ADMIN_VESSEL_MANAGEMENT_FORM_GET_TECHNICAL_MANAGERS',
    ADMIN_VESSEL_MANAGEMENT_FORM_SET_TECHNICAL_MANAGERS: 'ADMIN_VESSEL_MANAGEMENT_FORM_SET_TECHNICAL_MANAGERS',
    ADMIN_VESSEL_MANAGEMENT_FORM_RESET_ALL: 'ADMIN_VESSEL_MANAGEMENT_FORM_RESET_ALL',
    ADMIN_VESSEL_MANAGEMENT_FORM_SUBMIT: 'ADMIN_VESSEL_MANAGEMENT_FORM_SUBMIT',
    ADMIN_VESSEL_MANAGEMENT_FORM_IMPORT_VESSEL: 'ADMIN_VESSEL_MANAGEMENT_FORM_IMPORT_VESSEL',
    ADMIN_VESSEL_MANAGEMENT_FORM_GET_COMMERCIAL_OPERATORS: 'ADMIN_VESSEL_MANAGEMENT_FORM_GET_COMMERCIAL_OPERATORS',
    ADMIN_VESSEL_MANAGEMENT_FORM_SET_COMMERCIAL_OPERATORS: 'ADMIN_VESSEL_MANAGEMENT_FORM_SET_COMMERCIAL_OPERATORS',
    ADMIN_VESSEL_MANAGEMENT_FORM_GET_HEAD_OWNERS: 'ADMIN_VESSEL_MANAGEMENT_FORM_GET_HEAD_OWNERS',
    ADMIN_VESSEL_MANAGEMENT_FORM_SET_HEAD_OWNERS: 'ADMIN_VESSEL_MANAGEMENT_FORM_SET_HEAD_OWNERS',
    ADMIN_VESSEL_MANAGEMENT_FORM_SET_NEW_COMPANY: 'ADMIN_VESSEL_MANAGEMENT_FORM_SET_NEW_COMPANY',
    ADMIN_VESSEL_MANAGEMENT_FORM_SET_VALIDATION_ERRORS: 'ADMIN_VESSEL_MANAGEMENT_FORM_SET_VALIDATION_ERRORS'
};

export function getVessel(dispatch, imo) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_GET_VESSEL_BY_IMO,
        imo
    });
}

export function getIceClassLevels(dispatch) {
    dispatch({ type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_GET_ALL_CLASS_LEVELS });
}

export function searchVessels(dispatch, searchCriteria) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SEARCH_VESSELS,
        searchCriteria
    });
}

export function getCommercialOperators(dispatch, imo) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_GET_COMMERCIAL_OPERATORS,
        imo
    });
}

export function getHeadOwners(dispatch, imo) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_GET_HEAD_OWNERS,
        imo
    });
}

export function getTechnicalManagers(dispatch, imo) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_GET_TECHNICAL_MANAGERS,
        imo
    });
}

export function toggleManualAdding(dispatch, isAddingManually) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_TOGGLE_MANUAL_ADDING,
        isAddingManually
    });
}

export function setAsNewCompany(dispatch, companyName, key) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SET_AS_NEW_COMPANY,
        companyName,
        key
    });
}

export function importVessel(dispatch, imo) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_IMPORT_VESSEL,
        imo
    });
}

export function submitForm(dispatch, vessel) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_SUBMIT,
        vessel
    });
}

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.ADMIN_VESSEL_MANAGEMENT_FORM_RESET_ALL
    });
}
