import { createSelector } from 'reselect';
import moment from 'moment';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
import { mapLocationLonLat } from 'components/ol/ol-helpers';

const emptyArray = [];

export const getCalculationPoints = createSelector(
    state => state.calculatorReducer.calculationPoints,
    state => state.calculatorReducer.startingPoint,

    (calculationPoints, startingPoint) => {
        if (startingPoint && startingPoint.Point && startingPoint.Point.Type) {
            return [startingPoint, ...calculationPoints];
        }

        return calculationPoints;
    }
);

export const getSplitCalculationPoints = createSelector(
    state => state.calculatorReducer.calculationVesselStartingPosition,
    state => state.calculatorReducer.calculationVessel,
    state => state.calculatorReducer.calculationPoints,
    state => state.calculatorReducer.startingPoint,

    (startingPosition, calculationVessel, calculationPoints, startingPoint) => {
        const futurePoints = [];
        const historicalPoints = [];
        let earliestChangeableIndex = 0;
        let previousPoint = null;
        let earliestEta = null;
        let pointOffset;
        let timeInPort;
        if (!startingPosition || startingPosition.value !== 3) {
            pointOffset = 0;
            timeInPort = 0;
            if (startingPoint.Atd) {
                earliestEta = moment.utc(startingPoint.Atd);
            } else {
                if (startingPoint.Eta) {
                    earliestEta = moment.utc(startingPoint.Eta);
                }
                if (startingPoint.TimeInPort) {
                    timeInPort = TimeHelper.getDurationFromString(startingPoint.TimeInPort).asMinutes();
                }
            }
            if (startingPoint.Eta || startingPoint.Atd) {
                if (startingPoint.Point && startingPoint.Point.TimeZoneId) {
                    pointOffset = moment.tz(startingPoint.Point.TimeZoneId).utcOffset();
                } else if (startingPosition
                    && startingPosition.value !== 1
                    && calculationVessel
                    && calculationVessel.Vessel
                    && calculationVessel.Vessel.TimeZoneId) {
                    pointOffset = moment.tz(calculationVessel.Vessel.TimeZoneId).utcOffset();
                }
            }
            if (earliestEta) {
                earliestEta.add(timeInPort - pointOffset, 'm');
            }
        }
        if (calculationPoints && calculationPoints.length > 0) {
            calculationPoints.forEach((calculationPoint, index) => {
                if (previousPoint) {
                    pointOffset = 0;
                    timeInPort = 0;
                    if (previousPoint.Eta && !previousPoint.IsSpeedSelected) {
                        earliestEta = moment.utc(previousPoint.Eta);
                        if (previousPoint.Point && previousPoint.Point.TimeZoneId) {
                            pointOffset = moment.tz(previousPoint.Point.TimeZoneId).utcOffset();
                        }
                    }
                    if (previousPoint.TimeInPort) {
                        timeInPort = TimeHelper.getDurationFromString(previousPoint.TimeInPort).asMinutes();
                    }
                    if (earliestEta) {
                        earliestEta.add(timeInPort - pointOffset, 'm');
                    }
                }
                if (calculationPoint.Ata) {
                    historicalPoints.push({
                        calculationPoint,
                        earliestEta: earliestEta ? earliestEta.toISOString() : '',
                        index,
                        previousPoint,
                        isFixed: true
                    });
                    earliestChangeableIndex++;
                } else {
                    futurePoints.push({
                        calculationPoint,
                        earliestEta: earliestEta ? earliestEta.toISOString() : '',
                        index,
                        previousPoint,
                        isFixed: false
                    });
                }
                previousPoint = calculationPoint;
            });
        }
        return {
            historicalPoints,
            futurePoints,
            earliestChangeableIndex
        };
    }
);

export const getCalculationsWithTimes = createSelector(
    state => state.calculatorReducer.calculations,
    state => state.calculatorReducer.bunkerROB,
    state => state.calculatorReducer.bunkerROBDate,
    state => state.calculatorReducer.calculationPoints,
    state => state.calculatorReducer.calculationVoyageItinerary,
    (calculations, bunkerROB, bunkerROBDate, calculationPoints, itinerary) => {
        if (!calculations) {
            return null;
        }
        let WaitingTime = 0;
        let TimeInPort = 0;
        let ItineraryTimeInPort = 0;
        calculationPoints.forEach((point) => {
            if (point.WaitingTime) {
                WaitingTime += point.WaitingTime;
            }
            if (point.TimeInPort) {
                TimeInPort += TimeHelper.getDurationFromString(point.TimeInPort).asMilliseconds();
            }
        });
        if (itinerary) {
            itinerary.forEach((point) => {
                if (point.TimeInPort) {
                    ItineraryTimeInPort += point.TimeInPort;
                }
            });
        }
        return {
            CalculationValues: {
                ...calculations.CalculationValues,
                WaitingTime,
                TimeInPort
            },
            DeviationSummary: {
                ...calculations.DeviationSummary,
                TimeInPort: TimeInPort - ItineraryTimeInPort
            },
            BunkerROB: bunkerROB,
            BunkerROBDate: bunkerROBDate
        };
    }
);

export const getCalculationVoyageAisPoints = createSelector(
    state => state.calculatorReducer.calculationVoyageAisPoints,
    aisPoints => (
        aisPoints
            ? aisPoints.map(mapLocationLonLat)
            : emptyArray
    )
);
