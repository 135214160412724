import React from 'react';
import PropTypes from 'prop-types';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { getClassName } from 'utils/helpers/info-helper';

class NavSubMenu extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isCollapsed: true,
            isActive: props.rootPath
                ? this.props.router.location.pathname.startsWith(props.rootPath)
                : false
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                isActive: this.props.rootPath
                    ? this.props.router.location.pathname.startsWith(this.props.rootPath)
                    : false
            });
        }
    }

    componentWillUnmount() {
        if (this.props.toggleCollapseEvent) {
            document.removeEventListener(this.props.toggleCollapseEvent, this.handleToggleCollapseEvent);
        }
    }

    handleToggleCollapseEvent = () => {
        if (!this.state.isCollapsed) {
            this.toggleCollapse(null, true);
        }
    };

    getActive = () => {
        if (typeof this.props.isActive === 'boolean') {
            return this.props.isActive;
        }
        return this.state.isActive;
    };

    toggleCollapse = (event, flag) => {
        const isCollapsed = typeof flag === 'boolean' ? flag : !this.state.isCollapsed;
        if (isCollapsed !== this.state.isCollapsed) {
            if (this.props.toggleCollapseEvent) {
                if (!isCollapsed) {
                    const toggleCollapseEvent = new CustomEvent(this.props.toggleCollapseEvent);
                    document.dispatchEvent(toggleCollapseEvent);
                    document.addEventListener(this.props.toggleCollapseEvent, this.handleToggleCollapseEvent);
                } else {
                    document.removeEventListener(this.props.toggleCollapseEvent, this.handleToggleCollapseEvent);
                }
            }
            this.setState({ isCollapsed });
        }
        if (event) {
            event.stopPropagation();
        }
    };

    handleClick = (e) => {
        let toggleCollapse = true;
        const { location } = this.props.router;
        if (this.props.to && location.pathname !== this.props.to && !this.state.isActive) {
            this.props.router.navigate(this.props.to);
            toggleCollapse = false;
        }
        if (this.props.onClick) {
            toggleCollapse = !!this.props.onClick();
        }
        if (toggleCollapse) {
            this.toggleCollapse(e);
        } else {
            this.toggleCollapse(e, false);
        }
    };

    render() {
        const classNames = getClassName('sten-nav-sub-menu', {
            'sten-nav-sub-menu--collapsed': this.state.isCollapsed,
            'sten-nav-sub-menu--active': this.getActive()
        }, this.props.className);
        return (
            <div className={classNames} title={this.props.tooltipTitle}>
                <div className="sten-nav-sub-menu__header" onClick={this.handleClick}>
                    {this.props.icon && (
                        <span className={`sten-nav-link__icon icon icon-${this.props.icon}`} />
                    )}
                    {this.props.title && (
                        <span className="sten-nav-link__content">{this.props.title}</span>
                    )}
                    <div className="sten-nav-sub-menu__chevron icon icon-chevron-down" onClick={this.toggleCollapse} />
                </div>
                {this.props.children}
            </div>
        );
    }
}

NavSubMenu.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    icon: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func,
    rootPath: PropTypes.string,
    router: TRouter.isRequired,
    toggleCollapseEvent: PropTypes.string,
    tooltipTitle: PropTypes.string,
    title: PropTypes.node,
    to: PropTypes.string
};

NavSubMenu.defaultProps = {
    children: null,
    className: '',
    icon: '',
    isActive: undefined,
    onClick: undefined,
    rootPath: '',
    toggleCollapseEvent: '',
    tooltipTitle: '',
    title: '',
    to: ''
};

export default withRouter(NavSubMenu);
