import { takeEvery, put, select, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
/* helpers */
import { getConvertedObject } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
import { getLogoBase64 } from 'utils/base64images';
import { getLogoForCompany } from '../../reports-helpers';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* actions */
import { ActionTypes } from './fleet-average-speed-report-actions';
/* services */
import EnergyManagementService from 'services/core-api/energy-management-service';
/* export xlsx */
import generateFleetSpeedReportXLSX from './export/fleet-average-speed-report-xlsx';
/* selectors */
import { getVesselSpeedData } from './fleet-average-speed-report-selectors';
import { getSelectedPresetCompanyId } from '../../reports-selectors';
/* props types */
import inclusionPropTypes from '../../sidebar/inclusions/inclusion-prop-types';

function* prepareReportDataForExport() {
    const { selectedRange } = yield select(state => state.energyManagementReportsReducer);
    const rangeStart = selectedRange && TimeHelper.getFormatted(selectedRange.rangeStart);
    const rangeEnd = selectedRange && TimeHelper.getFormatted(selectedRange.rangeEnd);
    const fileName = `Fleet_Average_Speed_Report_${rangeStart}_${rangeEnd}`;
    const companyId = yield select(getSelectedPresetCompanyId);
    const companyLogo = yield getLogoForCompany(companyId);
    const VesselSpeedData = yield select(state => getVesselSpeedData(state));
    const {
        TotalAvgSpeedBallast,
        TotalAvgSpeedLaden
    } = yield select(state => state.energyManagementReportsFleetAverageSpeedReportReducer);
    return {
        TotalAvgSpeedBallast,
        TotalAvgSpeedLaden,
        VesselSpeedData,
        fileName,
        companyLogo,
        rangeStart,
        rangeEnd,
        orbitLogo: getLogoBase64().dark
    };
}

function* generateXLSX() {
    yield put({ type: 'TOGGLE_LOADER', payload: true });
    yield delay(0);
    const reportData = yield prepareReportDataForExport();
    yield generateFleetSpeedReportXLSX(reportData);
    yield put({ type: 'TOGGLE_LOADER', payload: false });
}

function* getFleetAverageSpeedReports() {
    const { filters, inclusions, selectedRange } = yield select(state => state.energyManagementReportsReducer);
    if (inclusions) {
        const convertedInclusions = getConvertedObject(inclusions, inclusionPropTypes);
        const params = {
            ...convertedInclusions,
            FromDate: selectedRange ? selectedRange.rangeStart : null,
            ToDate: selectedRange ? selectedRange.rangeEnd : null,
            Imos: Object.keys(filters.selectedVessels)
        };
        const report = yield EnergyManagementService.Reports.FleetSpeedReport.getReport(params);
        yield put({ type: ActionTypes.ENERGY_MANAGEMENT_FLEET_AVERAGE_SPEED_REPORT_SET, report });
    } else {
        toast(translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.SET_PARAMETERS_VESSELS'), { type: toast.TYPE.WARNING });
    }
}

export default function* energyManagementReportsFleetAverageSpeedReportSaga() {
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_FLEET_AVERAGE_SPEED_REPORT_GENERATE, getFleetAverageSpeedReports);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_FLEET_AVERAGE_SPEED_REPORT_GENERATE_XLSX, generateXLSX);
}
