import { ActionTypes } from './weather-performance-report-actions';

const initialState = {
    report: null
};

export default function energyManagementReportsWeatherPerformanceReportReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ENERGY_MANAGEMENT_WEATHER_PERFORMANCE_REPORT_SET: {
        return {
            ...state,
            report: action.report || null
        };
    }
    default:
        return state;
    }
}
