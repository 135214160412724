/* eslint-disable max-len */
export default {
    ACCOUNT: {
        TITLE: 'Account',
        DEPARTMENTS: 'Departments',
        COMPANY: 'Company',
        COMPANIES: 'Companies',
        POSITION: 'Position',
        LOCATION: 'Location',
        VERSION: 'App version: #{version}',
        LAST_UPDATE: 'Last update: #{update}',
        LOGOUT: 'Logout',
        CHANGE_PASSWORD: 'Change Password',
        UNSUPPORTED_FORMAT_MESSAGE: 'JPEG and PNG files allowed',
        UNSUPPORTED_FILE_SIZE: 'File too large. Max file size is 10MB.',
        SETTINGS: {
            TITLE: 'User settings',
            ENABLE_SCREEN_ON_KEYBOARD: 'Enable On-screen Keyboard',
            SET_DATE_FORMAT: 'Set date format',
            SET_EU_ETS_CURRENCY: 'Set EU ETS currency',
            SELECT_CURRENCY: 'Select currency',
            RELOAD_MESSAGE: 'In order to apply new time format page must be reloaded.'
        }
    },
    ADMIN: {
        COMPANY_MANAGEMENT: {
            ADD_COMPANY: 'Add company',
            NUMBER_OF_USERS_OVER_TIME: 'Number Of Users Over Time',
            TITLE: 'Company Management',
            TOTAL_USERS_ON_PLATFORM: 'Total Users On Platform',
            USERS_CURRENTLY_ONLINE: 'Users Currently Online',
            EDITABLE_ITEM: {
                DELETE_CONFIRM: 'Are you sure you wish to delete this item?'
            },
            FLEETS: {
                TITLE: 'Fleets',
                ADD: 'Add Fleet',
                SUCCESSFULLY_UPDATED: 'Fleet successfully updated!',
                SUCCESSFULLY_ADDED: 'Fleet successfully added!'
            },
            DEPARTMENTS: {
                TITLE: 'Departments',
                ADD: 'Add Department',
                DELETE_CONFIRM: 'Are you sure you wish to delete this department?',
                DELETE_USERS: 'Department \'#{name}\' is assigned to #{count} #{pluralize[count]: [user,users]}. \n Please adjust user profiles in order to proceed with deletion. ',
                VIEW_USERS: 'View #{pluralize[count]: [user,users]}',
                SUCCESSFULLY_UPDATED: 'Department successfully updated!',
                SUCCESSFULLY_ADDED: 'Department successfully added!',
                SUCCESSFULLY_DELETED: 'Department successfully deleted!'
            },
            COMPANY: {
                OFFICES: 'Offices',
                USERS: 'Users',
                EDIT: 'Edit Company',
                ADD: 'Add Company',
                NAME: 'Name',
                USERS_COUNT: '#{count} users',
                MORE_OPTIONS: 'For more options visit User Management',
                ADD_OFFICE: 'Add Office',
                LOGO_LIGHT_BG: 'Light Background Logo',
                LOGO_DARK_BG: 'Dark Background Logo',
                LOGO_LIGHT_TOOLTIP: 'This logo version will be displayed in exported documents on light background',
                LOGO_DARK_TOOLTIP: 'This logo version will be displayed throughout the application on dark background',
                SUCCESSFULLY_UPDATED: 'Company successfully updated!',
                SUCCESSFULLY_ADDED: 'Company successfully added!'
            },
            OFFICE: {
                TITLE: 'Office',
                CITY: 'City',
                CITY_PLACEHOLDER: 'Enter city name where office is located',
                ADDRESS: 'Address',
                ADDRESS_PLACEHOLDER: 'Enter office address',
                PHONE: 'Phone',
                PHONE_PLACEHOLDER: 'Enter office phone number',
                PHONE_ADD: 'Add phone',
                EMAIL: 'Email',
                EMAIL_PLACEHOLDER: 'Enter office email',
                EMAIL_ADD: 'Add email'
            },
            PLATFORM_STATISTICS: {
                TITLE: 'Platform Statistics',
                TOTAL_USERS_ON_PLATFORM: 'Total Users On Platform',
                NUMBER_OF_USERS_OVER_TIME: 'Number Of Users Over Time'
            }
        },
        VESSEL_MANAGEMENT: {
            ADD_NEW_VESSEL: 'Add new vessel',
            TITLE: 'Vessel Management',
            FILTERS: {
                VESSELS: 'Vessels',
                SEARCH_VESSELS_PLACEHOLDER: 'Search Vessels',
                SEARCH_VESSELS_TITLE: 'Search by Vessel Name, IMO, Owner or Technical Manager',
                SEGMENT: 'Segment',
                APPLY: 'Apply',
                RESET: 'Reset'
            },
            SAVE_SUCCESSFUL: 'Saved changes successfully',
            TABLE: {
                VESSEL_NAME: 'Vessel name',
                OWNER: 'Owner',
                TECH_MANAGER: 'Technical manager',
                SORT_ORDER: 'Sort order',
                OPERATOR: 'Operator',
                EDIT_VESSEL_DETAILS: 'Edit vessel details',
                MANAGE_FLEETS: 'Manage Fleets',
                EDIT_VESSEL: 'Edit vessel',
                LOCATE_ON_MAP: 'Locate on map',
                ASSIGN_TO_FLEET: 'Assign to fleet',
                SET_VESSEL_STATUS: 'Set vessel status',
                CHOOSE_COMMERCIAL_CONTRACT: 'Choose commercial contract',
                RESPONSIBLE_OFFICE: 'Responsible office',
                ASSIGN_VESSEL_TO_THIS_FLEET: 'Assign vessel to this fleet',
                REMOVE_FROM_FLEET: 'Remove from fleet',
                CANCEL: 'CANCEL',
                SAVE: 'SAVE',
                STATUS: 'Status',
                COMMERCIAL_CONTRACT: 'Commercial contract',
                ASSIGNED: 'Assigned',
                CAPACITY: 'Capacity',
                IMO: 'IMO',
                DWT: 'DWT',
                IMO_CLASS: 'IMO Class'
            },
            FORM: {
                ADD_MANUALLY: 'Add manually',
                ADD_VALUES: 'Add values',
                ADD_VESSEL: 'Add vessel',
                BALLAST: 'Ballast (mt/day)',
                BALLAST_DRAFT: 'Ballast Draft',
                BALLAST_OPTIMUM_TRIM: 'Ballast Optimum Trim',
                LADEN_OPTIMUM_TRIM: 'Laden Optimum Trim',
                BEAM: 'Beam',
                BUDGET_CONSUMPTION: 'Budget consumption',
                CANALS_ALLOWED: 'Canals allowed',
                CAPACITY: 'Capacity',
                CLASS: 'Class',
                COMMENT: 'Comment',
                COMMERCIAL_INFORMATION: 'Commercial information',
                COMMERCIAL_OPERATOR: 'Commercial Operator',
                CUBIC_CAPACITY: 'Total Cubic Capacity',
                DISCARD: 'Discard',
                DWT: 'DWT',
                EDIT: 'Edit',
                EDIT_VESSEL: 'Edit vessel',
                EDIT_VESSEL_DETAILS: 'Edit vessel details',
                FOUND_IN_VESSEL_PROVIDER: 'Found on vessel data provider and in Orbit under different names.',
                GENERAL_INFORMATION: 'General information',
                GT: 'GT',
                HEAD_OWNER: 'Head Owner',
                BLOCK_COEFFICIENT_CB: 'Block coefficient of the ship',
                BLOCK_COEFFICIENT_CB_INFO: 'This will be applied to the CII Correction Factors formula, per vessel',
                CUBIC_CAPACITY_CORRECTION_FACTOR_FC: 'Cubic capacity - for chemical tankers',
                CUBIC_CAPACITY_CORRECTION_FACTOR_FC_INFO: 'This will be applied to the CII Correction Factors formula, per vessel',
                SHIP_SPECIFIC_VOLUNTARY_STRUCTURAL_ENHACEMENT: 'Ship specific voluntary structural enhancement',
                SHIP_SPECIFIC_VOLUNTARY_STRUCTURAL_ENHACEMENT_INFO: 'This will be applied to the CII Correction Factors formula, for self-unloading bulk carriers',
                ICE_CLASS: 'Ice Class',
                ICE_CLASS_LEVEL: 'Ice Class Level',
                ICE_CLASS_LEVELS: 'Ice Class Level (Bureau Veritas)',
                SELECT_ICE_CLASS_LEVEL: 'Select Ice Class Level',
                IMO: 'IMO',
                IMO_CLASS: 'IMO Class',
                IMPORT: 'Import',
                KIEL: 'Kiel',
                LADEN: 'Laden (mt/day)',
                LOA: 'LOA',
                MMSI: 'MMSI',
                NEW_COMPANY_CONFIRM_TEXT: 'New company will be added to Orbit system.',
                NOX_AUX_ENGINE: 'NOx Aux Engine',
                NOX_MAIN_ENGINE: 'NOx Main Engine',
                NO_VESSEL_FOUND_TEXT: 'You can continue with Adding New Vessel manually or use exact search parameters.',
                NO_VESSEL_FOUND_TITLE: 'Vessel not found.',
                OPERATIONS_EMAIL: 'Operations email',
                OTHER: 'Other',
                OWNER_EMAIL: 'Owner email',
                PANAMA: 'Panama',
                SCRUBBER: 'Scrubber',
                SCRUBBER_ACTIVE_FROM: 'Scrubber active from',
                SEARCH_BY_PLACEHOLDER: 'Search by Vessel Name or IMO',
                SEGMENT: 'Segment',
                SELECT_COMMERCIAL_OPERATOR: 'Select Commercial Operator',
                SELECT_HEAD_OWNER: 'Select Head Owner',
                SELECT_SEGMENT: 'Select Segment',
                SELECT_TECHNICAL_MANAGER: 'Select Technical Manager',
                SELECT_TRADING_AREA: 'Select Trading Area',
                SELECT_VESSEL_TYPE: 'Select Vessel Type',
                SEND_TO_VERACITY: 'Send vessel reports to Veracity',
                SFC_AUX_ENGINE: 'SFC Aux Engine',
                SFC_MAIN_ENGINE: 'SFC Main Engine',
                SHOW_ON_STENA_BULK: 'Show on stenabulk.com',
                SORT_ORDER: 'Sort order',
                SPEED: 'Speed (kn)',
                SUCCESSFULLY_ADDED_NEW_COMPANY: 'Company is successfully added',
                SUCCESSFULLY_ADDED_VESSEL: 'Vessel is successfully added',
                SUCCESSFULLY_IMPORTED_VESSEL: 'Vessel is successfully imported',
                SUCCESSFULLY_UPDATED_VESSEL: 'Vessel is successfully updated',
                SUEZ: 'Suez',
                TECHNICAL_INFORMATION: 'Technical Information',
                TECHNICAL_MANAGEMENT_EMAIL: 'Technical Management Email',
                TECHNICAL_MANAGER: 'Technical Manager',
                TRADING_AREA: 'Trading Area',
                UPDATE_VESSEL: 'Update vessel',
                VESSELS: 'Vessels',
                VESSEL_EMAIL: 'Vessel Email',
                VESSEL_EXISTS_MESSAGE: 'You can choose to Cancel the process or edit vessel details in Orbit.',
                VESSEL_EXISTS_TITLE: 'This vessel already exists in #{source}',
                VESSEL_NAME: 'Vessel Name',
                VESSEL_TYPE: 'Vessel Type',
                YEAR_BUILT: 'Year Built'
            }
        },
        USER_MANAGEMENT: {
            TITLE: 'User Management',
            CREATE_NEW_USER: 'Create new user',
            USER_CREATED: 'User created successfully!',
            USER_UPDATED: 'User updated successfully!',
            FILTERS: {
                SEARCH: 'Search',
                SEARCH_BY_EMAIL: 'Search by Email...',
                COMPANY: 'Company',
                SEARCH_BY_COMPANY: 'Search by Company...',
                ROLE: 'Role',
                SELECT_ROLE: 'Select Role',
                DEPARTMENT: 'Department',
                SELECT_DEPARTMENTS: 'Select Departments',
                STATUS: 'Status',
                LOCAL_LOGIN: 'Local Login',
                SELECT_STATUS: 'Select Status',
                SELECT_ACCOUNT_TYPE: 'Select Account Type',
                CONTACT_LIST_VISIBILITY: 'Contact List Visibility',
                SELECT_VISIBILITY: 'Select Visibility'
            },
            TABLE: {
                USER: 'User',
                STATUS: 'Status',
                ROLE: 'Role',
                COMPANY: 'Company',
                LOCAL_LOGIN: 'Local Login',
                CONTACT_LIST_VISIBILITY: 'Contact list Visibility',
                DEPARTMENTS: 'Departments'
            },
            FORM: {
                USER_ACTIVE: 'Active User',
                USER_VISIBLE_IN_CONTACT_LIST: 'User Visible In Contact List',
                LOCAL_LOGIN: 'Allow local login',
                SET_USERNAME: 'Set username',
                FIRST_NAME: 'First Name',
                LAST_NAME: 'Last Name',
                PASSWORD: 'Password',
                CONFIRM_PASSWORD: 'Confirm Password',
                TITLE: 'Title',
                LOCATION: 'Location',
                MOBILE_PHONE: 'Mobile Phone',
                WORK_PHONE: 'Work Phone',
                EMAIL: 'Email',
                COMPANY: 'Company',
                SELECT_VESSELS: 'Select Vessel(s)',
                ROLE: 'Role',
                USER_TYPE: 'User Type',
                FLEET: 'Fleet',
                DISCARD: 'Discard',
                CREATE: 'Create',
                DEPARTMENTS: 'Departments',
                SELECT_DEPARTMENTS: 'Select Departments',
                ADD_NEW_DEPARTMENT: 'Add new Department',
                DEPARTMENT: 'Department',
                DEPARTMENT_NAME: 'Department Name',
                DEPARTMENT_ALREADY_EXISTS: 'Department "#{departmentName}" already exists.',
                SUCCESSFULLY_ADDED_NEW_DEPARTMENT: 'Successfully added new department',
                EMAIL_CHANGED_MODAL_TEXT: 'Changing email address will affect login credentials. Are you sure you want to save these changes?',
                EMAIL_CHANGED_MODAL_TITLE: 'Change email'
            }
        }
    },
    ALERTS: {
        TITLE: 'Alerts',
        REPORTED: 'Alert reported',
        SHOW_DISMISSED: 'Show dismissed',
        HIDE_DISMISSED: 'Hide dismissed',
        DISMISSED_ALERTS: 'Dismissed alerts',
        NO_ALERTS: 'There are currently no active alerts',
        NO_DISMISSED_ALERTS: 'There are currently no dismissed alerts',
        ACTIVE: 'Active',
        DISMISSED: 'Dismissed',
        DISMISS_SUCCESS: 'Alert dismissed successfully!'
    },
    AREAS_OF_INTEREST: {
        TITLE: 'Areas of interest'
    },
    ASSIGN_TO_FLEET_MODAL: {
        ASSIGN_TO_FLEET: 'Assign to fleet',
        ASSIGNED: 'Assigned',
        ASSIGN_VESSEL_TO_THIS_FLEET: 'Assign vessel to this fleet',
        STATUS: 'Status',
        SET_VESSEL_STATUS: 'Set vessel status',
        COMMERCIAL_CONTRACT: 'Commercial contract',
        CHOOSE_COMMERCIAL_CONTRACT: 'Choose commercial contract',
        RESPONSIBLE_OFFICE: 'Responsible office',
        EDIT_VESSEL_DETAILS: 'Edit vessel details',
        MANAGE_FLEETS: 'Manage Fleets',
        EDIT_VESSEL: 'Edit vessel',
        DWT: 'DWT',
        CAPACITY: 'Capacity',
        IMO_CLASS: 'IMO Class',
        SAVE_SUCCESSFUL: 'Saved changes successfully'
    },
    AUTH: {
        POST_LOGOUT: {
            SUCCESSFULLY_LOGGED_OUT_MSG: 'You are successfully logged out from OrbitMI.',
            BACK_TO_LOGIN: 'Back To Login'
        },
        UNAUTHORIZED_ACCESS: {
            LOGIN_WITH_DIFFERENT_ACCOUNT: 'Log in with different account',
            UNAUTHORIZED_ACCESS: 'Unauthorized Access',
            CANT_USE: 'You are not authorized to use OrbitMI application.'
        }
    },
    BUNKER_PRICE_TOOLTIP: {
        NO_DATA: 'There is no bunker price data for this port..'
    },
    BUNKEREX_INFO: 'Indicative bunker prices from BunkerEx. For more information please contact brokers@bunker-ex.com.',
    CARGO_INQUIRY: {
        CARGO_INQUIRY: 'Cargo Inquiry',
        WELCOME_TEXT: 'Please put your cargo details below and any '
            + 'other relevant information that you would like to provide.',
        ADDITIONAL_WELCOME_TEXT: ' We will contact you as soon as possible with freight possibilities.',
        SUCCESSFULLY_SENT_TEXT: 'Successfully sent. We will get back to you shortly. Thank you.',
        CARGO_INFORMATION: 'Cargo Information (required)',
        LOADING_PORT: 'Loading port (optional)',
        SELECT_PORT: 'Select port...',
        LOADING_DATE: 'Loading date...',
        DISCHARGING_PORT: 'Discharging port (optional)',
        DISCHARGING_DATE: 'Discharging date',
        SEND_INQUIRY: 'Send inquiry',
        SUCCESSFULLY_SENT_TITLE: 'Inquiry successfully sent',
        CLOSE_INQUIRY_FORM: 'Close inquiry form'
    },
    CARGO_LIST: {
        TITLE: 'My cargoes',
        CARGO_STATUS: 'Cargo Status',
        COMMODITY: 'Commodity',
        CHARTERPARTY_DATE: 'Charter party date',
        QUANTITY: 'Quantity',
        LOAD_PORT: 'Load port',
        DISCHARGE_PORT: 'Discharge port',
        ACTIVE: 'Active',
        HISTORICAL: 'Historical',
        NO_ACTIVE_CARGOES: 'No active cargoes',
        NO_HISTORICAL_CARGOES: 'No historical cargoes',
        FROM: 'from',
        TO: 'to',
        VOYAGE_DURATION: 'Voyage duration'
    },
    CARGO_EXPORT: {
        COATING: 'Coating:',
        LAST_5_CARGOES_PER_TANK: 'Last 5 cargoes per tank on #{date}',
        LAST_ORDINAL: '#{ordinal} last',
        EXPORT_PDF: 'Export PDF',
        EXPORT_XLSX: 'Export XLSX'
    },
    CHARTERPARTY_FORM: {
        TITLE: 'Charter party Details',
        CHARTERPARTY_INFORMATION: 'Charter party information',
        DATE_EFFECTIVE: 'Date effective',
        SPEED: 'Speed',
        WIND_SPEED: 'Wind speed',
        WAVE_HEIGHT: 'Wave height',
        CURRENTS: 'Currents',
        NO_INFORMATION: 'No charter party information',
        TABLE: {
            FUEL: 'Fuel',
            CONSUMPTION: 'Consumption',
            DEVIATION: 'Deviation'
        },
        TOASTS: {
            VOYAGE_DATA_UPDATED: 'Voyage charter party information updated successfully!'
        }
    },
    COMMENTS: {
        TITLE: 'Comments',
        VIEW_ALL: 'View all',
        NO_RECENT_COMMENTS: 'No recent comments',
        RECENT_COMMENTS: 'Recent comments',
        ADD_NEW_COMMENT: 'Add new comment',
        POST_COMMENT: 'Post comment',
        PREVIOUS_COMMENTS: 'Previous Comments',
        REMOVE_COMMENT: 'Are you sure you want to delete this comment?',
        COMMENT_SUCCESSFULLY_ADDED: 'Comment successfully added!',
        COMMENT_SUCCESSFULLY_UPDATED: 'Comment successfully updated!',
        COMMENT_SUCCESSFULLY_DELETED: 'Comment successfully deleted!'
    },
    COMMON: {
        EEOI_INFO_TOOLTIP: 'Energy Efficiency Operational Indicator: Total CO₂ emissions per tonne-mile (distance traveled per laden leg multiplied by cargo quantity)',
        AER_INFO_TOOLTIP: 'Annual Efficiency Ratio: Total CO₂ emissions per DWT-mile (total distance traveled multiplied by deadweight)'
    },
    COMPANY_SELECT: {
        ADD_NEW_COMPANY: 'Add New Company',
        COMPANY_ALREADY_EXISTS: 'Company "#{company}" already exists.',
        COMPANY_NAME: 'Company name'
    },
    CONTACT_INQUIRY: {
        CONTACT_US: 'Contact Us',
        SUCCESSFULLY_SENT_TEXT: 'We will get back to you shortly. Thank you.',
        SUBJECT: 'Subject',
        MESSAGE: 'Message',
        SEND: 'Send',
        SUCCESSFULLY_SENT_TITLE: 'Message successfully sent'
    },
    CONTACT_LIST: {
        TITLE: 'Contact List',
        TABLE: {
            NAME: 'Name',
            COMPANY: 'Company',
            DEPARTMENT: 'Department',
            LOCATION: 'Location',
            PHONE: 'Phone',
            EMAIL: 'Email',
            OFFICE: 'Office: ',
            MOBILE: 'Mobile: '
        },
        FILTERS: {
            SEARCH: 'Search',
            COMPANY: 'Company',
            DEPARTMENT: 'Department',
            SELECT_COMPANIES: 'Select companies',
            SELECT_DEPARTMENTS: 'Select departments',
            SEARCH_CRITERIA_PLACEHOLDER: 'Search by name, position or location...',
            RESET_FILTERS: 'Reset filters'
        }
    },
    COUNTER_PVAR: {
        COUNTER_PVAR_TITLE: 'Counter PVAR',
        COUNTER_PVAR_FORM_TITLE: 'Request Counter PVAR',
        LINK_TO_CHARTER_PARTY: 'Click here to add them.',
        LINK_TO_EDIT_VESSEL: 'Click here to go to the vessels page and add them.',
        MISSING_CHARTERPARTY: 'Charter party details for this voyage are missing.',
        MISSING_REPORTS: 'Requesting Counter PVAR is not possible because reports for this voyage are missing.',
        MISSING_VESSEL_PARTICULARS: 'Some vessel particulars are missing:',
        REQUEST_COUNTER_PVAR: 'Request Counter PVAR',
        VALIDATION_FAILED_MESSAGE: 'Requesting Counter PVAR is not possible at the moment. Please resolve the issues mentioned below to continue.',
        COUNTER_PVAR_FORM: {
            COMMENT: 'Comment',
            EMAILS: 'Counter PVAR Emails',
            INSTRUCTED_SPEED: 'Instructed Speed',
            SEND_REQUEST: 'Send Request',
            VESSEL: 'Vessel',
            VESSEL_CONDITION: 'Vessel Condition',
            VOYAGE: 'Voyage'
        },
        LIST_ITEM: {
            VESSEL_CONDITION: 'Vessel Condition: ',
            REQUESTED_BY: 'Requested by #{name} on #{date}'
        },
        TOASTS: {
            REQUEST_SENT: 'New Counter PVAR request successfully sent!',
            VOYAGE_NOT_FOUND_ERROR: 'Voyage not found!'
        }
    },
    DATE_RANGE_POPUP: {
        START_DATE_PLACEHOLDER: 'Start Date',
        END_DATE_PLACEHOLDER: 'End Date'
    },
    DATE_TIME_POPUP: {
        PLACEHOLDER: 'Date',
        DATE_TIME_PLACEHOLDER: 'Date and time'
    },
    DISTANCE_CALCULATOR: {
        AREAS: 'Routing options',
        AREAS_PLACEHOLDER: 'Select option',
        TITLE: 'Distance Calculator',
        HIDE_FILTERS: 'Hide filters',
        SHOW_FILTERS: 'Show filters',
        DEVIATION_REQUEST: 'Deviation request',
        DEVIATION_CALCULATOR: 'Deviation Calculator',
        VESSEL_NAME: 'Vessel Name',
        VESSEL: 'Vessel',
        SEARCH_VESSELS: 'Search Vessels',
        SEARCH_VESSELS_BY_NAME_OR_IMO: 'Search Vessels by Name or IMO',
        STARTING_LOCATION: 'Starting location',
        DEFAULT_SPEED: 'Default Speed',
        WEATHER_FACTOR: 'Weather Factor',
        CANALS_ALLOWED: 'Canals allowed',
        CANALS_PLACEHOLDER: 'Select canal',
        RESET: 'Reset',
        COMMENT: 'Comment (optional)',
        CALCULATE: 'Calculate',
        CLEAR: 'Clear',
        SEND_REQUEST: 'Send request',
        CONFIRM_SEND_CALCULATION: 'Send the calculation',
        CONFIRM_SEND_DEVIATION_REQUEST: 'Send deviation request',
        CLIPBOARD: {
            ETA: 'ETA',
            ETD: 'ETD',
            SPEED: 'Speed',
            START_TIME: 'Start time',
            VESSEL_NAME: 'Vessel name',
            VOYAGE_TIME: 'Voyage Time'
        },
        POINT: {
            TITLE: 'New Port',
            TITLE_START: 'Start Point',
            STATUS: 'Status',
            START_POINT: 'Start Point',
            DESTINATION: 'Destination',
            PORT_SELECT_PLACEHOLDER: 'Select port or point',
            COORDINATES_SELECT_PLACEHOLDER: '(eg. 44 48 48 N 020 22 18 E)',
            PORT_WITHOUT_LOCATION:
                'The selected port doesn\'t have a location set. Please select a port with location.',
            SPEED: 'Speed',
            WEATHER_FACTOR: 'Weather Factor',
            ARRIVAL_TIME: 'Arrival Time',
            START_TIME: 'Start Time',
            START_TIME_DISABLED_MESSAGE: 'Please select point first',
            ARRIVAL_TIME_DISABLED_MESSAGE: 'Select destination first',
            TIME_DISABLED_MESSAGE: 'Select destination first',
            ETA: 'ETA',
            TIME_IN_PORT: 'Time In Port',
            DEPARTURE: 'Departure',
            DEPARTURE_PLACEHOLDER: '(eg. 5d 10h)',
            WAITING_TIME: 'Waiting time',
            STEAMING_TIME: 'Steaming Time',
            CONSUMPTION_PER_DAY_FOR_SPEED: 'Consumption for speed #{speed} kn is #{consumption} mt/day.',
            INSERT_BEFORE: 'Insert before',
            INSERT_AFTER: 'Insert after',
            MOVE_UP: 'Move up',
            MOVE_DOWN: 'Move down'
        },
        BUTTON: {
            ADD_POINT: 'Add point'
        },
        SUMMARY: {
            DEVIATION_TITLE: 'Deviation summary',
            CALCULATION_TITLE: 'Calculation summary',
            DIFFERENCE: 'Difference',
            LAST_PORT: 'Last Port',
            ETA: 'ETA',
            TOTAL_VOYAGE_TIME: 'Total Voyage Time',
            TOTAL_STEAMING_TIME: 'Total Steaming Time',
            AVERAGE_PER_DAY: 'Average per day',
            DISTANCE: 'Distance',
            CONSUMPTION: 'Consumption',
            ECA: 'ECA',
            NON_ECA: 'Non ECA',
            TOTAL: 'Total',
            ZONES: 'Zones',
            TITLE: 'Summary',
            START: 'Start',
            END: 'End',
            DETAILS: 'Details',
            AVERAGE: 'Average',
            BUNKER_ROB: 'Bunker ROB',
            CONSUMPTION_TOOLTIP: 'Possibly Insufficient Bunker ROB',
            YTD_AER: 'YTD AER',
            YTD_AER_TOOLTIP: 'Year to date AER calculated for the historical voyages of the routed vessel',
            YTD_CII_GRADE: 'YTD CII Grade',
            YTD_CII_GRADE_TOOLTIP: 'Year to date CII calculated for the historical voyages of the routed vessel',
            VOYAGE_AER: 'Voyage AER',
            VOYAGE_AER_TOOLTIP: 'AER calculated using consumption and distance predictions for the routed voyage',
            VOYAGE_CII_GRADE: 'Voyage CII Grade',
            VOYAGE_CII_GRADE_TOOLTIP: 'CII calculated using consumption and distance predictions for the routed voyage',
            PREDICTED_AER: 'Predicted AER',
            PREDICTED_AER_TOOLTIP: 'AER calculated by combining YTD vessel AER grade and voyage AER grade resulting with predicted vessel AER after the routed voyage is completed',
            PREDICTED_CII_GRADE: 'Predicted CII Grade',
            PREDICTED_CII_GRADE_TOOLTIP: 'CII calculated by combining YTD vessel CII grade and voyage CII grade resulting with predicted vessel CII after the routed voyage is completed'
        },
        SEND_SUCCESS_TITLE: 'Calculation request successfully sent',
        SEND_SUCCESS_TEXT: 'Successfully sent. We will get back to you shortly. Thank you.',
        TABLE: {
            DESTINATIONS: 'Destinations',
            STATUS: 'Status',
            STEAMING: 'Steaming',
            DISTANCE: 'Distance',
            SPEED: 'Speed',
            CURRENT_CONSUMPTION: 'Current consumption',
            WAITING: 'Waiting',
            ARRIVAL_DATE: 'Arrival date (LT)',
            TIME_IN_PORT: 'Time in port',
            DEPARTURE_DATE: 'Departure date (LT)'
        }
    },
    CALCULATOR_FILTERS: {
        ADDITIONAL_FILTERS: 'Additional Filters',
        WEATHER_FACTOR: 'Weather Factor',
        BUNKER_PLACEHOLDER: 'Select bunker',
        ECA_BUNKER: 'ECA bunker',
        NON_ECA_BUNKER: 'Non ECA bunker',
        HRA_ZONES: 'Avoid High Risk Areas',
        ECA_ZONES: 'Avoid ECA Zones',
        PANAMA: 'Panama',
        SUEZ: 'Suez',
        KIEL: 'Kiel',
        EU_ETS_YEAR: 'EU ETS Year',
        EU_ETS_VESSEL_TYPE: 'EU ETS Vessel Type',
        STARTING_POSITION: {
            CUSTOM_PORT: 'Custom port',
            NEXT_OPEN_PORT: 'Next open port',
            CURRENT_POSITION: 'Current position',
            DEVIATE_CURRENT_VOYAGE: 'Deviate current voyage',
            CURRENT_POSITION_MISSING: 'Current position not available for this vessel',
            NEXT_OPEN_PORT_MISSING: 'Next open port not available for this vessel',
            CURRENT_VOYAGE_MISSING: 'Current voyage not available for this vessel'
        }
    },
    CALCULATION_MESSAGE: {
        ORBIT_ROUTING_ERROR: 'Orbit Routing unavailable',
        CALCULATION_SUCCESS: 'Calculation finished successfully!',
        ONE_PORT_MINIMUM: 'Please add at least one future port for the calculation.',
        MAXIMUM_FUTURE_POINTS: 'Maximum #{max} future points allowed for the calculation.'
    },
    EMISSION_ESTIMATOR: {
        AREAS: 'Routing options',
        AREAS_PLACEHOLDER: 'Select option',
        TITLE: 'Emission Estimator',
        DEVIATION_REQUEST: 'Deviation request',
        DEVIATION_CALCULATOR: 'Deviation Calculator',
        VESSEL_NAME: 'Vessel Name',
        VESSEL: 'Vessel',
        SEARCH_VESSELS: 'Search Vessels',
        SEARCH_VESSELS_BY_NAME_OR_IMO: 'Search Vessels by Name or IMO',
        STARTING_LOCATION: 'Starting location',
        DEFAULT_SPEED: 'Default Speed',
        CANALS_ALLOWED: 'Canals allowed',
        CANALS_PLACEHOLDER: 'Select canal',
        RESET: 'Reset',
        COMMENT: 'Comment (optional)',
        CALCULATE: 'Calculate',
        CLEAR: 'Clear',
        SEND_REQUEST: 'Send request',
        CONFIRM_SEND_CALCULATION: 'Send the calculation',
        CONFIRM_SEND_DEVIATION_REQUEST: 'Send deviation request',
        CLIPBOARD: {
            AER: 'AER',
            CII_GRADE: 'CII grade',
            ETA: 'ETA',
            ETD: 'ETD',
            SPEED: 'Speed',
            START_TIME: 'Start time',
            VESSEL_NAME: 'Vessel name',
            CO2_EMISSIONS: 'CO₂ Emissions',
            VOYAGE_TIME: 'Voyage Time'
        },
        POINT: {
            TITLE: 'New Port',
            TITLE_START: 'Start Point',
            STATUS: 'Status',
            START_POINT: 'Start Point',
            DESTINATION: 'Destination',
            PORT_SELECT_PLACEHOLDER: 'Select port or point',
            COORDINATES_SELECT_PLACEHOLDER: '(eg. 44 48 48 N 020 22 18 E)',
            PORT_WITHOUT_LOCATION:
                'The selected port doesn\'t have a location set. Please select a port with location.',
            SPEED: 'Speed',
            WEATHER_FACTOR: 'Weather Factor',
            ARRIVAL_TIME: 'Arrival Time',
            START_TIME: 'Start Time',
            START_TIME_DISABLED_MESSAGE: 'Please select point first',
            ARRIVAL_TIME_DISABLED_MESSAGE: 'Select destination first',
            TIME_DISABLED_MESSAGE: 'Select destination first',
            ETA: 'ETA',
            TIME_IN_PORT: 'Time In Port',
            DEPARTURE: 'Departure',
            DEPARTURE_PLACEHOLDER: '(eg. 5d 10h)',
            WAITING_TIME: 'Waiting time',
            STEAMING_TIME: 'Steaming Time',
            CONSUMPTION_PER_DAY_FOR_SPEED: 'Consumption for speed #{speed} kn is #{consumption} mt/day.',
            INSERT_BEFORE: 'Insert before',
            INSERT_AFTER: 'Insert after',
            MOVE_UP: 'Move up',
            MOVE_DOWN: 'Move down'
        },
        BUTTON: {
            ADD_POINT: 'Add point'
        },
        SUMMARY: {
            AER: 'AER',
            CII_GRADE: 'CII grade',
            CII_RATING_TOOLTIP: 'Voyage rating without corrections',
            DEVIATION_TITLE: 'Deviation summary',
            CALCULATION_TITLE: 'Calculation summary',
            DIFFERENCE: 'Difference',
            LAST_PORT: 'Last Port',
            ETA: 'ETA',
            VOYAGE_TIME: 'Voyage Time',
            TOTAL_STEAMING_TIME: 'Total Steaming Time',
            AVERAGE_PER_DAY: 'Average per day',
            DISTANCE: 'Distance',
            CONSUMPTION: 'Consumption',
            ECA: 'ECA',
            NON_ECA: 'Non ECA',
            TOTAL: 'Total',
            CO2: 'CO₂',
            CH4: 'CH₄',
            CO: 'CO',
            N2O: 'N₂O',
            NMVOC: 'NMVOC',
            ZONES: 'Zones',
            TITLE: 'Summary',
            START: 'Start',
            END: 'End',
            DETAILS: 'Details',
            AVERAGE: 'Average',
            BUNKER_ROB: 'Bunker ROB',
            CONSUMPTION_TOOLTIP: 'Possibly Insufficient Bunker ROB'
        },
        SEND_SUCCESS_TITLE: 'Calculation request successfully sent',
        SEND_SUCCESS_TEXT: 'Successfully sent. We will get back to you shortly. Thank you.',
        TABLE: {
            DESTINATIONS: 'Destinations',
            STATUS: 'Status',
            STEAMING: 'Steaming',
            DISTANCE: 'Distance',
            SPEED: 'Speed',
            CONSUMPTION: 'Consumption',
            WAITING: 'Waiting',
            ARRIVAL_DATE: 'Arrival date (LT)',
            TIME_IN_PORT: 'Time in port',
            DEPARTURE_DATE: 'Departure date (LT)'
        }
    },
    EMPLOYED_VESSELS: {
        CAPACITY: 'Capacity',
        DELIVERY_PORT: 'Delivery port',
        REDELIVERY_PORT: 'Redelivery port',
        DURATION: 'Duration',
        MY_CARGOES: 'My Shipments',
        MY_VESSELS: 'My Chartered Vessels',
        TITLE: 'Employed Vessels',
        NO_VESSELS: 'There are no employed vessels',
        DWT: 'DWT',
        IMO_CLASS: 'IMO Class'
    },
    EMPTY_CONTENT: {
        NO_DATA_MESSAGE: 'There is no relevant data.'
    },
    ENERGY_MANAGEMENT: {
        REPORTS: {
            TITLE: 'Reports',
            VOYAGE_REPORT: 'Voyage report',
            PERFORMANCE_SUMMARY: 'Performance summary',
            FLEET_SUMMARY: 'Fleet summary',
            MULTI_VOYAGE_REPORT: 'Multi voyage report',
            RAW_DATA_REPORT: 'Raw report data',
            SUSTAINABILITY_REPORT: 'Sustainability report',
            VESSEL_BENCHMARK: 'Vessel benchmark',
            FLEET_POSITION: 'Fleet position',
            FLEET_AVERAGE_SPEED_REPORT: 'Fleet average speed report',
            FLEET_PERFORMANCE_REPORT: 'Fleet performance report',
            QUARTERLY_REPORT: 'Quarterly report',
            PERFORMANCE_SUMMARY_REPORT: 'Performance Summary Report',
            SET_PARAMETERS_MESSAGE: 'Set parameters in order to generate Report',
            CONFIRM_DELETE: 'Are you sure you want to delete this report?',
            SIDEBAR: {
                CHARTERPARTY: 'Charter party',
                CHARTERPARTY_PLACEHOLDER: 'If there is no Charter party added for this voyage, some information could '
                    + 'be missing from report. You will be able to add Charter party directly from the generated '
                    + 'Weather+ Performance report and see the full scope of it.',
                GENERATE_REPORT: 'Generate report',
                SELECT_REPORT_TYPE: 'Select report type',
                SEARCH_VESSELS: 'Search Vessels',
                SELECT_SEGMENTS: 'Select Segments',
                SELECT_COMPANY_FLEETS: 'Select Company Fleets',
                SELECT_CUSTOM_FLEETS: 'Select Custom Fleets',
                DATA_RANGE: 'Data range',
                VOYAGE_NUMBER: 'Voyage number',
                NO_VESSEL_PLACEHOLDER: 'Please select the vessel first',
                NO_VOYAGES_PLACEHOLDER: 'There are no voyages for selected vessel',
                PARAMETERS: 'Parameters',
                PRESET: 'Preset',
                SELECT_PRESET_PLACEHOLDER: 'Select preset parameters',
                LESS_THAN: 'Less than',
                GREATER_THAN: 'Greater than',
                LONGER_THAN: 'Longer than',
                SHORTER_THAN: 'Shorter than',
                WIND_FORCE: 'Wind Force (BF)',
                WIND_SPEED: 'Wind Speed',
                HOURS_STEAMED: 'Hours Steamed',
                INCLUDE_HEAT: 'Include Heat',
                CARGO_CARRIED: 'Cargo Carried',
                INSTRUCTED_SPEED_BALLAST: 'Instructed Speed Ballast',
                INSTRUCTED_SPEED_LADEN: 'Instructed Speed Laden',
                ACTUAL_SPEED_BALLAST: 'Actual Speed Ballast',
                ACTUAL_SPEED_LADEN: 'Actual Speed Laden',
                REF_SPEED_BALLAST: 'Ref Speed Ballast',
                REF_SPEED_LADEN: 'Ref Speed Laden',
                REF_SPEED: 'Ref Speed',
                BALLAST: 'Ballast',
                LADEN: 'Laden',
                LOADING_TIME: 'Loading Time',
                SEA_PASSAGE: 'Sea Passage',
                DISCHARGING_TIME: 'Discharging Time',
                CARGO_DISCHARGED: 'Cargo Discharged',
                REF_CARGO_SIZE: 'Ref Cargo Size',
                OPERATIONS_NUMBER: 'Operations Number',
                LOAD: 'Load',
                SPEED: 'Speed',
                DISCHARGE: 'Discharge',
                TOTAL_AUX_CONSUMPTION: 'Total Aux Consumption',
                AUX_CONSUMPTION: 'Aux Consumption (mt/day)',
                DRAFT_IN_LADEN: 'Draft in Laden (m)',
                PROP_CONS: 'Prop Cons',
                BALLAST_DAYS: 'Ballast Days',
                DAYS: 'Days',
                CARGO_HEATED_DURING_OPS: 'Cargo Heated During Ops',
                VESSEL_UNDERWAY_USING_ME: 'Vessel Underway Using ME',
                INCLUDE_TC_VOYAGES: 'Include TC voyages',
                RESET_PARAMETERS: 'Reset parameters',
                CALCULATE: 'Calculate',
                SEGMENT: 'Segment',
                COMPANY_FLEET: 'Company Fleet',
                CUSTOM_FLEET: 'Custom Fleet',
                INCLUDED_VESSELS: 'Included vessels',
                CP_SPEED: 'CP Speed',
                DUPLICATE_VALUES: 'You have the same value on multiple CP Speeds',
                NO_VESSELS_SELECTED: 'Please select at least one vessel',
                VESSEL: 'Vessel',
                VESSELS: 'Vessels',
                SEARCH_BY_PLACEHOLDER: 'Search Vessels',
                SEARCH_VESSELS_TITLE: 'Search by Vessel Name or IMO'
            },
            PREVIEW: {
                IMO: 'IMO',
                SUSPICIOUS_ONLY: 'Suspicious Only (#{count})',
                SUSPICIOUS_ACTIVE_TITLE: 'Show all reports',
                SUSPICIOUS_INACTIVE_TITLE: 'Show only suspicious reports',
                FUEL: 'Fuel',
                CII_SUMMARY_YEAR_TO_DATE: 'CII Summary Year to Date',
                CONSUMPTION: 'Consumption',
                ENERGY_EFFICIENCY: 'Energy Efficiency',
                FOOTPRINT: 'Footprint',
                EMISSIONS: 'Emissions',
                VMS_EMISSIONS: 'VMS Emissions',
                SET_PARAMETERS_VESSELS: 'Please select parameters and vessels in order to generate report.',
                REPORTS_UPDATED_TEXT: 'Calculation needs to be updated. Please recalculate reports in order to see updated data.',
                RECALCULATE: 'Recalculate',
                NOTE_TEXT: 'These values will be used in future Pool Point calculations. Should you have any comments on the same, please notify us within 10 business days of receipt of this report.',
                GET_SHARABLE_LINK: 'Get sharable link',
                EXPORT_PDF: 'Export PDF',
                EXPORT_XLSX: 'Export XLSX',
                VESSEL_NAME: 'Vessel Name',
                VESSELS_SELECTED: 'vessels selected',
                AVERAGE_SPEED_BALLAST: 'Average Speed Ballast',
                AVERAGE_SPEED_LADEN: 'Average Speed Laden',
                DAILY_REPORTS: 'Daily Reports',
                ARRIVAL_REPORTS: 'Arrival Reports',
                CARGO_REPORTS: 'Cargo Reports',
                DEPARTURE_REPORTS: 'Departure Reports',
                SOF_REPORTS: 'SOF Reports',
                EVENT_REPORTS: 'Event Reports',
                EMPTY_REPORT: 'No data is available for this report.',
                EMPTY_VESSEL_DATA: 'No data is available for this vessel.',
                EXPORT_DISCLAIMER: '*These values will be used in future Pool Point calculations. Should you have any comments on the same, please notify us within 10 business days of receipt of this report.',
                HEADER: {
                    VOYAGE_NUMBER: 'Voyage number',
                    PERFORMANCE_SUMMARY: 'Performance summary',
                    DATE_RANGE: 'Date range',
                    START: 'Start',
                    END: 'End'
                },
                DASHBOARD: {
                    VOYAGE_REPORT: 'Voyage report',
                    VOYAGE_REPORT_DESCRIPTION: `Calculates Ballast and Laden summary for specified voyage with possibility to exclude not relevant reports.
                        The raw data lists out all reports for specified voyage and marks the excluded or suspicious values.`,
                    PERFORMANCE_SUMMARY_REPORT: 'Performance summary report',
                    PERFORMANCE_SUMMARY_REPORT_DESCRIPTION: 'Shows all the legs for selected vessels over time with possibility to exclude not relevant reports. This report can also calculate deviation from Charter party speed and consumption.',
                    MULTI_VOYAGE_REPORT: 'Multi voyage report',
                    MULTI_VOYAGE_REPORT_DESCRIPTION: `Calculates Ballast and Laden summary for specific vessel over time with possibility to exclude not relevant reports.
                        The raw data lists out the data for each of the voyages in selected period.`,
                    FLEET_SPEED_REPORT: 'Fleet speed report',
                    FLEET_SPEED_REPORT_DESCRIPTION: 'Calculates the average speed over time for each of the selected vessels with possibility to exclude not relevant reports.',
                    FLEET_PERFORMANCE_REPORT: 'Fleet performance report',
                    FLEET_PERFORMANCE_REPORT_DESCRIPTION: 'Calculates multiple vessel consumption and emissions in selected period.',
                    QUARTERLY_REPORT: 'Quarterly report',
                    QUARTERLY_REPORT_DESCRIPTION: `The report compiles all the voyage days, used bunkers, emissions and Energy efficiency per selected vessels segments.
                        Compares the data from selected quarter, year to date and rolling 12 months with the same periods of the year before.`,
                    FLEET_LIST_REPORT: 'Fleet list report',
                    FLEET_LIST_REPORT_DESCRIPTION: 'The report compiles vessel data for selected vessel segments or fleets.',
                    WEATHER_PERFORMANCE_REPORT: 'Weather+ performance report',
                    WEATHER_PERFORMANCE_REPORT_DESCRIPTION: 'Complies information from various sources in one: Orbit Reporter, Weather provider, and Charter party Agreement.'
                },
                VOYAGE_FOOTPRINT: 'Voyage Footprint',
                VOYAGE_REPORT: {
                    RAW_DATA: 'Raw Data',
                    CONFIRM_DELETE: 'Are you sure you want to delete this report?',
                    SUMMARY: {
                        AER: 'AER',
                        AER_WITH_CORRECTIONS: 'AER (with corrections)',
                        ALL_REPORTS_DISCLAIMER: 'Based on values from all reports',
                        AVERAGE_AUX_CONSUMPTION: 'Average Aux Consumption',
                        AVERAGE_CARGO_HEATING_CONSUMPTION: 'Average Cargo Heating Consumption',
                        AVERAGE_ME_CONSUMPTION: 'Average ME Consumption',
                        AVERAGE_SPEED: 'Average Speed',
                        BALLAST_MILES: 'Ballast Miles',
                        CARGO_HEATED_DURING_VOYAGE: 'Cargo Heated During Voyage',
                        CARGO_ONBOARD: 'Cargo onboard',
                        CH4: 'CH₄',
                        CO2: 'CO₂',
                        CO2_EMISSION_PER_DAY: 'CO₂ per day',
                        CO2_EMISSION_PER_DOLLAR: 'CO₂ per $',
                        CO: 'CO',
                        CONSUMPTION: 'Consumption',
                        DISCHARGING_CONSUMPTION: 'Discharging Consumption',
                        EEOI: 'EEOI',
                        INSTRUCTED_SPEED: 'Instructed Speed',
                        LADEN_MILES: 'Laden Miles',
                        LOADING_CONSUMPTION: 'Loading Consumption',
                        N2O: 'N₂O',
                        NMVOC: 'NMVOC',
                        SUSPICIOUS_CARGO_ONBOARD: 'Cargo on board is less than 10 000 mt',
                        SUSPICIOUS_CONSUMPTION_DISCLAIMER: 'To correct this, please review values for Start ROB, lifted bunkers for the voyage and End ROB.',
                        SUSPICIOUS_MILES: 'Less than 400 NM',
                        TOTAL_AUX_CONSUMPTION: 'Total Aux Consumption',
                        TOTAL_CARGO_HEATING_CONSUMPTION: 'Total Cargo Heating Consumption',
                        TOTAL_CONSUMPTION: 'Total Consumption',
                        TOTAL_ME_CONSUMPTION: 'Total ME Consumption',
                        TOTAL_OBS_DISTANCE: 'Total Obs. Distance',
                        TOTAL_TIME: 'Total Time',
                        VOYAGE_END: 'Voyage End',
                        VOYAGE_START: 'Voyage Start'
                    }
                },
                MULTI_VOYAGE_REPORT: {
                    TABLE_HEADER: {
                        AVG_ME_CONS_PER_DAY: 'Avg M/E Cons Per Day (mt/day)',
                        AVG_SPEED: 'Avg Speed (kn)',
                        BALLAST: 'Ballast',
                        CARGO_HEATED: 'Cargo Heated',
                        CARGO_ONBOARD: 'Cargo onboard (mt)',
                        DISCHARGE: 'Discharge',
                        INSTRUCTED_SPEED: 'Instructed Speed (kn)',
                        LADEN: 'Laden',
                        LOAD: 'Load',
                        TOTAL_AUX_CONS: 'Total Aux Cons (mt)',
                        TOTAL_DISCH_CONS: 'Total Disch Cons (mt)',
                        TOTAL_DISTANCE: 'Total Distance (NM)',
                        TOTAL_LOAD_CONS: 'Total Load Cons (mt)',
                        TOTAL_ME_CONS: 'Total M/E Cons (mt)',
                        TOTAL_TIME: 'Total Time (h)',
                        VOYAGE_NUMBER: 'Voyage Number'
                    },
                    SUMMARY: {
                        AVERAGE_AUXILIARY: 'Average Auxiliary',
                        AVERAGE_AUXILIARY_DISCLAIMER: 'Based on both Ballast and Laden reports.',
                        AVERAGE_CARGO: 'Average Cargo Carried',
                        AVERAGE_CARGO_INCLUDED: 'Average Cargo Discharged',
                        AVERAGE_CONSUMPTION: 'Average Consumption',
                        AVERAGE_SPEED: 'Average Speed',
                        CORRECTED_CONSUMPTION: 'Corrected Cons for Speed',
                        CORRECTION_FOR_CARGO: 'Corrected for Cargo Carried',
                        CORRECTION_FOR_CARGO_INCLUDED: 'Corrected for Cargo Discharged',
                        CORRECTION_FOR_SPEED: 'Corrected Cons for Speed',
                        DISCHARGE_CONSUMPTION: 'Discharge Consumption',
                        LOAD_CONSUMPTION: 'Load Consumption',
                        REFERENCE_CARGO: 'Reference Cargo',
                        REFERENCE_SPEED: 'Reference Speed',
                        TOTAL_DAYS: 'Total Days'
                    }
                },
                PERFORMANCE_SUMMARY: {
                    DEPARTURE: 'Departure',
                    VESSEL_NAME: 'Vessel Name',
                    ARRIVAL: 'Arrival',
                    CONDITION: 'Condition',
                    DEPARTURE_DATA: 'Departure Data',
                    SPEED: 'Speed',
                    ALL_REPORTED_DATA: 'All Reported Data',
                    DATA_WITH_EXCLUSIONS: 'Data with Exclusions',
                    THEORETICAL_BASIS_CP: 'Theoretical Basis CP',
                    DEVIATION_FROM_CP: 'Deviation from CP',
                    PORT: 'Port',
                    DATE: 'Date',
                    BALLAST_LADEN: 'Ballast / Laden',
                    CARGO: 'Cargo (mt)',
                    MEAN_DRAFT: 'Mean Draft (m)',
                    TRIM: 'Trim (m)',
                    EXCLUDED: 'excluded',
                    NO_REPORTS: 'There are no reports for this vessel.',
                    EXCLUSIONS_FOOTER_EXPLANATION: 'Based on set Inclusions. To modify them, click on filter icon at the top right corner.',
                    INSTRUCTED_SPEED: 'Instructed Speed (kn)',
                    TOTAL_DISTANCE: 'Tot. Distance (NM)',
                    TOTAL_TIME: 'Tot. Time (h)',
                    AVG_SPEED: 'Avg. Speed (kn)',
                    TOTAL_MAIN_ENG_CONS: 'Tot. Main Eng. Cons. (mt)',
                    TOTAL_AUX_CONS: 'Tot. Aux. Cons. (mt)',
                    TIME_DEVIATION: 'Deviation in Speed / Time (h)',
                    CONSUMPTION_DEVIATION: 'Deviation in Cons. (mt)',
                    EXPORT_PDF: 'Export PDF',
                    EXPORT_XLSX: 'Export XLSX',
                    EMPTY_REPORT_MESSAGE: 'There are no reports. Try setting different parameters.',
                    DATE_RANGE: 'Date Range',
                    PERFORMANCE_SUMMARY_REPORT: 'Performance Summary Report',
                    WIND_SPEED: 'Wind Speed (BF) Max: ',
                    DRAFT_IN_LADEN: 'Draft in Laden (m) Max: ',
                    SEA_PASSAGE_MIN: 'Sea passage Min: ',
                    PERIOD: 'Period',
                    PARAMETERS: 'Parameters',
                    BALLAST_BETWEEN: 'Instructed speed ballast between #{minSpeed} & #{maxSpeed}',
                    LADEN_BETWEEN: 'Instructed speed laden between #{minSpeed} & #{maxSpeed}'
                },
                FLEET_AVERAGE_SPEED_REPORT: {
                    TOTAL_AVERAGE_SPEED: 'Total average speed',
                    AVERAGE_SPEED_BY_VESSEL: 'Average speed by vessel'
                },
                FLEET_PERFORMANCE_REPORT: {
                    ANCHORED_DRIFTING: 'Anchored / Drifting / Passage',
                    AT_SEA: 'At sea',
                    AVG: 'Avg/day (mt)',
                    BALLAST: 'Ballast',
                    CANAL: 'Canal',
                    CANAL_PERCENTAGE: '% Canal',
                    CARGO_DISCHARGE_OPERATIONS: 'Cargo discharge operations',
                    CARGO_LOAD_OPERATIONS: 'Cargo load operations',
                    CONSUMPTION_AT_SEA: 'Consumption at sea',
                    CONSUMPTION_IN_CANAL: 'Consumption in canal',
                    CONSUMPTION_IN_PORT: 'Consumption in port',
                    CONSUMPTIONS: 'Consumptions',
                    DATE_RANGE: 'Date range',
                    DAYS_ANCHORED_DRIFTING: 'Days Anch / Drift / Passage',
                    DAYS_AT_SEA: 'Days at sea',
                    DAYS_BALLAST: 'Days Ballast',
                    DAYS_CARGO_DISCHARGE: 'Days Disch. Operations',
                    DAYS_CARGO_LOAD: 'Days Load Operations',
                    DAYS_IN_CANAL: 'Days in canal',
                    DAYS_IN_CANAL_PERCENTAGE: '% Canal',
                    DAYS_IN_PORT: 'Days in port',
                    DAYS_IN_PORT_PERCENTAGE: '% Port',
                    DAYS_LADEN: 'Days Laden',
                    DISTANCE_BALLAST: 'Dist.Ballast (NM)',
                    DISTANCE_BALLAST_PERCENTAGE: '% Ballast',
                    DISTANCE_LADEN: 'Dist.Laden (NM)',
                    DISTANCE_LADEN_PERCENTAGE: '% Laden',
                    EMISSIONS: 'Emissions',
                    EMISSIONS_PER_CARGO: 'MT emission / MT cargo',
                    FUEL_PER_CARGO: 'MT fuel / MT cargo',
                    FUEL_PER_DISTANCE: 'MT fuel / NM',
                    GRAND_TOTAL: 'Grand Total',
                    IN_PORT: 'In port',
                    LADEN: 'Laden',
                    OTHER_VESSELS: 'Other Vessels',
                    PORT_FUEL_CONSUMPTION: 'Port fuel cons.',
                    TITLE: 'Fleet Performance Report',
                    TOTAL: 'Total (mt)',
                    TOTAL_AT_SEA: 'Total at sea(mt)',
                    TOTAL_CARGO_CARRIED: 'Tot.cargo carried (mt)',
                    TOTAL_FOR: 'Total for',
                    TOTAL_FOR_COMPANY_FLEET: 'Total for Company Fleet',
                    TOTAL_FOR_SEGMENT: 'Total for Segment',
                    TOTAL_IN_PORT: 'Total in port (mt)',
                    TOTALS: 'Totals',
                    VALUE: 'Value',
                    VOYAGE_NUMBER: 'Voyage number'
                },
                QUARTERLY_REPORT: {
                    AER: 'AER',
                    BALLAST_LADEN_RATIO: 'B/L ratio',
                    BALLAST_PERCENTAGE_BASED_ON_TIME: '% Ballast based on time',
                    BALLAST_PERCENTAGE_BASED_ON_DISTANCE: '% Ballast based on distance',
                    DAYS_IN_PORT_PERCENTAGE: '% Days in port',
                    DELTA: 'Delta',
                    DELTA_PERCENTAGE: 'Delta %',
                    EEOI: 'EEOI',
                    EMISSIONS: 'Emissions',
                    LADEN_PERCENTAGE_BASED_ON_TIME: '% Laden based on time',
                    LADEN_PERCENTAGE_BASED_ON_DISTANCE: '% Laden based on distance',
                    OFF_HIRE_DAYS: 'Off-Hire days',
                    QUARTER: 'Quarter',
                    ROLLING: 'Rolling 12 months',
                    TOTAL: 'Total',
                    USED_BUNKER: 'Used bunker',
                    VALUE: 'Value',
                    YEAR_TO_DATE: 'Year To Date'
                },
                WEATHER_PERFORMANCE_REPORT: {
                    VOYAGE: 'Voyage',
                    FROM: 'From',
                    TO: 'To',
                    GENERATE_PDF: 'Generate PDF',
                    LEGEND: {
                        SUCCESS_LABEL: 'Vessel performance is good, in line or even better than CP conditions',
                        DANGER_LABEL: 'Vessel under-performing compared to CP conditions',
                        WARNING_LABEL: 'Data is not available'
                    },
                    PERFORMANCE_SUMMARY_PANEL: {
                        BUDGET: 'Budget',
                        COMMENT: 'Comment',
                        GW_TIME: 'GW time',
                        HFO: 'HFO',
                        INSTRUCTED: 'Instructed',
                        MDO: 'MDO',
                        LNG: 'LNG',
                        SPEED: 'Speed',
                        TITLE: 'Performance summary'
                    },
                    CURRENT_SITUATION_PANEL: {
                        TITLE: 'Current situation',
                        SPEED_ORDER: 'Speed order',
                        SAILED_DISTANCE: 'Sailed distance',
                        TOTAL_DISTANCE: 'Total distance',
                        SOSP_ATD: 'SOSP / ATD',
                        REPORTED_ETA: 'Reported ETA',
                        CALCULATED_ETA_ADVICE: 'Calculated ETA (advice)'
                    },
                    REPORTED_SITUATION_PANEL: {
                        TITLE: 'Last known position reported by master',
                        DATE_TIME: 'Date / time',
                        LATITUDE: 'Latitude',
                        LONGITUDE: 'Longitude',
                        WIND_SPEED_LAST_24: 'Wind speed (last 24h)',
                        WAVES_AND_SWELL_LAST_24: 'Waves and swell (last 24h)',
                        AVERAGE_SPEED_LAST_24: 'Average speed (last 24h)',
                        HFO_CONSUMPTION_LAST_24: 'HFO Consumption (last 24h)',
                        MDO_CONSUMPTION_LAST_24: 'MDO Consumption (last 24h)',
                        LNG_CONSUMPTION_LAST_24: 'LNG Consumption (last 24h)'
                    },
                    SPEED_ANALYSIS_PANEL: {
                        TITLE: 'Speed analysis',
                        PARAMETERS: 'Parameters',
                        ALL_WEATHER: 'All weather',
                        GOOD_WEATHER: 'Good weather',
                        DISTANCE: 'Distance',
                        TIME: 'Time',
                        AVERAGE_SPEED: 'Average speed',
                        CURRENT_FACTOR: 'Current factor',
                        WEATHER_FACTOR: 'Weather factor',
                        PERFORMANCE_INSTRUCTED_SPEED: 'Performance Speed / Instructed speed'
                    },
                    TIME_ANALYSIS_PANEL: {
                        TITLE: 'Time analysis',
                        PARAMETERS: 'Parameters',
                        CALCULATION: 'Calculation',
                        RESULT: 'Result',
                        CP_TIME: 'C/P time',
                        MAXIMUM_WARRANTED_TIME: 'Maximum warranted time',
                        GWT_EXTRAPOLATED_VOYAGE: 'GWT extrapolated voyage',
                        TIME_LOST: 'Time lost'
                    },
                    BUNKER_ANALYSIS_PANEL: {
                        HFO_BUNKER_ANALYSIS: 'HFO Bunker analysis',
                        MDO_BUNKER_ANALYSIS: 'MDO Bunker analysis',
                        LNG_BUNKER_ANALYSIS: 'LNG Bunker analysis',
                        PARAMETERS: 'Parameters',
                        ALL_WEATHER: 'All weather',
                        GOOD_WEATHER: 'Good weather',
                        TOTAL_CONSUMPTION: 'Total Consumption',
                        TOTAL_CO2_EMISSION: 'Total CO2 Emission',
                        AVERAGE_CONSUMPTION: 'Average Consumption',
                        ALLOWED_CONSUMPTION: 'Allowed Consumption',
                        MINIMUM_ALLOWED: 'Minimum Allowed',
                        WITHOUT_ALLOWANCE: 'Without Allowance',
                        MAXIMUM_ALLOWED: 'Maximum Allowed',
                        ALLOWED_CONSUMPTION_EXTRAPOLATED: 'Allowed Consumption Extrapolated',
                        GOOD_WEATHER_CONSUMPTION_EXTRAPOLATED_VOYAGE: 'Good Weather Consumption Extrapolated Voyage',
                        CONSUMPTION_DELTA: 'Consumption Delta'
                    },
                    CHARTERPARTY_PANEL: {
                        CHARTERPARTY_PLACEHOLDER: 'There is no Charter party added for this voyage and some information could be missing from report. You will be able to add Charter party directly from the generated Weather+ Performance report and see the full scope of it.',
                        TITLE: 'Charter party details',
                        FROM: 'From',
                        FUEL: 'Fuel',
                        CONSUMPTION: 'Consumption',
                        DEVIATION: 'Deviation',
                        SPEED: 'Speed',
                        WAVE_HEIGHT: 'Wave Height',
                        WIND_SPEED: 'Wind Speed',
                        CURRENT: 'Current',
                        LINK_TITLE: 'Edit charter party info'
                    },
                    CHARTS: {
                        HFO_CONSUMPTION: 'HFO Consumption',
                        CALCULATED_CONSUMPTION: 'Calculated Consumption',
                        CP_BUDGET: 'CP Budget',
                        CHARTERPARTY_WIND_SPEED: 'Charter party Wind Speed',
                        LNG_CONSUMPTION: 'LNG Consumption',
                        MDO_CONSUMPTION: 'MDO Consumption',
                        VESSEL_SPEED_KN: 'Vessel Speed (kn)',
                        REPORTED_SPEED: 'Reported Speed',
                        AVERAGE_SOG: 'Average SOG',
                        CHARTERPARTY_SPEED: 'Charter party Speed',
                        WAVE_HEIGHT_M: 'Wave height (m)',
                        REPORTED_HEIGHT: 'Reported Height',
                        REPORTED_WIND_SPEED: 'Reported Wind Speed',
                        EXTERNAL_WIND_SPEED: 'External Wind Speed',
                        WEATHER_PROVIDER_REPORTED_HEIGHT: 'Weather provider reported height',
                        CHARTERPARTY_AGREEMENT_HEIGHT: 'Charter party Agreement Height',
                        WIND_SPEED_KN: 'Wind Speed (kn)'
                    }
                },
                FLEET_LIST_REPORT: {
                    FLEET_LIST_REPORT: 'Fleet list report',
                    VESSEL: 'Vessel',
                    IMO_NUMBER: 'IMO number',
                    DWT: 'DWT',
                    YEAR_BUILT: 'Year built',
                    LOA: 'LOA',
                    BEAM: 'Beam',
                    DRAFT: 'Draft',
                    NUMBER_OF_TANKS: '# of tanks',
                    SCRUBBER: 'Scrubber',
                    COATING: 'Coating',
                    ICE_CLASS: 'Ice class',
                    FLAG: 'Flag'
                }
            }
        },
        KPI: {
            ADVANCED: 'Advanced',
            BASIC: 'Basic',
            '1-12_MONTHS': '1-12 Months',
            '13-24_MONTHS': '13-24 Months',
            PARAMETERS: 'Parameters',
            ADJUST_FILTERS_MSG: 'The list is empty. Try adjusting the filters.',
            INFO_GRAPH_SECTION: {
                CONSUMPTION_CARGO: 'Consumption / Cargo',
                BALLAST_CONSUMPTION: 'Ballast consumption',
                LADEN_CONSUMPTION: 'Laden consumption',
                AVERAGE_CARGO: 'Average cargo',
                FOOTPRINT: 'Footprint',
                EEOI: 'EEOI',
                AER: 'AER',
                ENERGY_EFFICIENCY: 'Energy efficiency'
            },
            SIDEBAR: {
                VESSELS: 'Vessels',
                SEARCH_BY_TITLE: 'Search by Vessel Name, IMO or Segment',
                SEARCH_BY_PLACEHOLDER: 'Search Vessels',
                SEGMENT: 'Segment',
                INCLUDED_VESSELS: 'Included vessels',
                DATA_RANGE: 'Data range',
                END_DATE: 'End date',
                FILTERS: 'Filters',
                NO_VESSELS_SELECTED: 'Please select at least one vessel'
            },
            OVERVIEW: {
                TITLE: 'KPI Overview'
            },
            SEGMENT: {
                NAME: 'Name',
                BALLAST_BUNKER: 'Ballast bunker',
                LADEN_BUNKER: 'Laden bunker',
                AVERAGE_CARGO: 'Average Cargo',
                TOTAL_CHANGE: 'Total change',
                YB: 'YB',
                DWT: 'DWT',
                CAPACITY: 'Capacity',
                IMO_CLASS: 'IMO Class',
                ADVANCED: {
                    KEY_TOTAL_FIGURES: 'Key total figures',
                    TOTAL: 'Total',
                    PERIOD: 'Period',
                    TOTAL_CARGO: 'Total Cargo (Laden)',
                    TOTAL_DISTANCE: 'Total Distance (Nm)',
                    TOTAL_BUNKER_PROP_AUX_CONSUMPTION: 'Total Bunker Cons. (prop. + aux)(mt)',
                    VESSEL_SPEED: 'Vessel Speed',
                    OTHER_DATA: 'Other Data',
                    NUMBER_OF_DAYS_AT_SEA: 'Number of Days at Sea (Reporter)',
                    BALLAST_LADEN: 'Ballast / Laden (Reporter)',
                    BALLAST_AVERAGE_SPEED: 'Ballast Average Speed',
                    BALLAST_AVERAGE_INSTRUCTED_SPEED: 'Ballast Average Instructed Speed',
                    LADEN_AVERAGE_SPEED: 'Laden Average Speed',
                    LADEN_AVERAGE_INSTRUCTED_SPEED: 'Laden Average Instructed Speed',
                    GOOD_TRIM_BALLAST: 'Good Trim (Ballast)',
                    GOOD_TRIM_LADEN: 'Good Trim (Laden)',
                    AUXILIARY_RUNNING_HOURS_PER_DAY: 'Auxiliary Running Hours per Day',
                    BOILER_RUNNING_HOURS_PER_DAY: 'Boiler Running Hours per Day',
                    BUNKER: 'mt Bunker / mNm / kCargo',
                    ENERGY_EFFICIENCY: 'Energy Efficiency',
                    FUEL_CONSUMPTION_PER_DAY: 'Fuel Consumption per Day',
                    ENERGY_EFFICIENCY_VALUE: 'Energy Efficiency Value',
                    AVERAGE_AMOUNT_OF_CARGO: 'Average Amount of Cargo (laden)',
                    FLEET_AVERAGE_AGE: 'Fleet Average Age (years)',
                    AVERAGE_BALLAST_MT_PER_KNM: 'Average Ballast mt/knm (incl. aux)',
                    AVERAGE_LADEN_MT_PER_KNM: 'Average Laden mt/knm (incl. aux)',
                    AVERAGE_BALLAST_MT_DAY: 'Average Ballast mt/Day (incl. aux)',
                    AVERAGE_LADEN_MT_DAY: 'Average Laden mt/Day (incl. aux)'
                }
            },
            VESSEL: {
                BALLAST: 'Ballast',
                LADEN: 'Laden',
                PORT: 'Port',
                KEY_AVERAGE_FIGURES: {
                    SULPHUR_CONTENT: 'Sulph. Content. - Avg. bunker purch. (Reporter)',
                    BUNKER_CONSUMPTION_PROP: 'Bunker Consumption - Average Prop.',
                    BUNKER_CONSUMPTION_AUX: 'Bunker Consumption - Average Aux.',
                    BUNKER_CONSUMPTION_PROP_AUX: 'Bunker Consumption - Average Prop. + Aux.',
                    BUDGET_CONSUMPTION_PROP_AUX: 'Budget Consumption - Average Prop. + Aux.',
                    WIND_SPEED_REPORTER: 'Wind Speed - Average (Reporter)',
                    WIND_SPEED_WNI: 'Wind Speed - Average (WNI)',
                    DRAFT: 'Draft - Average (Reporter)',
                    TRIM: 'Trim - Average (Reporter)',
                    OTHER_DATA: 'Other Data',
                    LOAD_DISCHARGE_CONSUMPTION: 'Load / Disch. consumption - Total (Reporter)',
                    CARGO_HEATING: 'Cargo Heating - Total (Reporter)',
                    TANK_CLEANING: 'Tank Cleaning - Total (Reporter)',
                    CARGO_CIRCULATION: 'Cargo Circulation - Total (Reporter)',
                    BALLAST_EXCHANGE: 'Ballast Exchange - Total (Reporter)',
                    INERT_GAS: 'Inert Gas (IG) - Total (Reporter)',
                    BALLAST_SPEED: 'Ballast Speed - Average (Reporter)',
                    LADEN_SPEED: 'Laden Speed - Average (Reporter)',
                    MONTHS_SINCE_PROP_POLISH: '# Months since last prop polish (Reporter)',
                    MONTHS_SINCE_HULL_CLEAN: '# Months since last hull clean (Reporter)',
                    MAIN_ENGINE_PERFORMANCE: 'Main Engine Performance (Reporter)',
                    AUX_RUNNING_HOURS: 'Aux running hours per day (Reporter)',
                    BOILER_RUNNING_HOURS: 'Boiler running hours per day (Reporter)'
                },
                KEY_TOTAL_FIGURES: {
                    TOTAL_CARGO_LADEN: 'Total Cargo - Laden',
                    DISTANCE: 'Distance - Total',
                    NUMBER_OF_DAYS_REPORTER: 'Number of days - Total (Reporter)',
                    NUMBER_OF_DAYS_SHIPNET: 'Number of days - Total (ShipNet)',
                    BALLAST_LADEN_REPORTER: 'Ballast / Laden (Reporter)',
                    BALLAST_LADEN_SHIPNET: 'Ballast / Laden (ShipNet)',
                    BUNKER_SUPPLY: 'Bunker Supply - Total (Reporter)',
                    BUNKER_PURCHASE_MT: 'Bunker Purchase - Total(mt) (ShipNet)',
                    BUNKER_PURCHASE_USD: 'Bunker Purchase - Total(USD) (ShipNet)',
                    BUNKER_CONSUMPTION_PROP: 'Bunker Consumption - Total Prop.',
                    BUNKER_CONSUMPTION_AUX: 'Bunker Consumption - Total Aux.',
                    BUNKER_CONSUMPTION_PROP_AUX: 'Bunker Consumption - Total Prop. + Aux.',
                    ENERGY_EFFICIENCY: 'Energy efficiency',
                    BUNKER_CONSUMPTION_DIFF_MT: 'Bunker Consumption - Diff',
                    BUNKER_CONSUMPTION_DIFF_USD: 'Bunker Consumption - Diff (USD)',
                    BUNKER_KNM_CARGO: 'mt Bunker / kNm / mt Cargo',
                    EEV: 'EEV'
                }
            }
        }
    },
    ERROR: {
        SERVER: 'Some error occurred. Please try again later.',
        UNAUTHORIZED: 'Unauthorized or token expired.',
        NOT_FOUND: 'Resource not found.',
        FORBIDDEN: 'You have no authorization for this resource.',
        TOKEN_EXPIRED: 'Session expired. Please log in again.',
        SOME_ERROR_OCCURRED: 'Some error occurred.',
        NAVIGATIONAL_CHARTS_UNAVAILABLE: 'Navigational Charts Unavailable',
        WEATHER_SERVICE_UNAVAILABLE: 'Weather Service Unavailable'
    },
    FILE_UPLOAD: {
        FILE_ALREADY_EXIST_MESSAGE: 'The file "#{fileName}" already exists.',
        FILE_TOO_LARGE: 'The file "#{fileName}" is larger than #{maxSize}MB.',
        FILE_TOO_SMALL: 'The file "#{fileName}" must be larger than #{minSize}MB.',
        ERROR_UPLOADING_FILE: 'There was an error uploading the file, please try again.',
        UPLOADED_INFO: 'Uploaded on #{time} by #{author}',
        DROP_FILE_NOW: 'You can drop file now.',
        UNSUPPORTED_TYPE: 'File extension is unsupported.',
        DROP_TEXT: 'Click to browse or drag&drop to upload.',
        ADDITIONAL_DROP_TEXT: 'Tip: Image smaller than 300px in width or height is not recommended',
        ERROR_UPLOADING_FILE_EXTENSION: 'File extension "#{extension}" is unsupported.'
    },
    FLEET_DASHBOARD: {
        TITLE: 'Fleet Dashboard',
        SELECT_FLEET: 'Select fleet',
        SELECT_VOYAGE_OPTION: 'Select voyage option',
        DISCLAIMER: 'Disclaimer: These numbers are based on operational data and might not correspond to final earnings.',
        EARNINGS_TITLE: 'Earnings',
        EARNINGS_SUBTITLE: 'Earnings and vessels',
        UPDATED_ON: 'Updated on:',
        LAST_YEAR_TCE: 'Last Year TCE:',
        CURRENT_TCE: 'Current TCE',
        TCE_INDEX: 'TCE Index',
        BASED_ON: 'Based on 100-pt vessel',
        ON_TD20_ROUTE: 'on TD20 route',
        VESSELS: 'Vessels',
        TABLE: {
            VESSEL_NAME: 'Vessel Name',
            CHARTERER: 'Charterer',
            LATEST_TCE: 'Latest TCE',
            IN_BALLAST: 'In Ballast',
            YTD_CII: 'YTD CII',
            YTD_CII_TOOLTIP: 'Carbon Index Indicator grade calculated for the current year to date range with applied correction factors where applicable and data available.'
        },
        MARKET_PREDICTION_TITLE: 'Market prediction',
        MARKET_PREDICTION_SUBTITLE: 'TD20 WAF-UK CONT',
        REPORTED_WS: 'Reported World Scale'
    },
    FLEET_MANAGEMENT: {
        TITLE: 'Fleet Management',
        NEW_FLEET: 'New Fleet',
        DYNAMIC: 'Dynamic',
        STATIC: 'Static',
        FLEET_SUCCESSFULLY_DELETED: 'Fleet successfully deleted!',
        FLEET_SUCCESSFULLY_UPDATED: 'Fleet successfully updated!',
        FLEET_SUCCESSFULLY_ADDED: 'Fleet successfully added!',
        VESSEL_SUCCESSFULLY_ADDED_TO_YOUR_FLEET: 'Vessel successfully added to My Fleet',
        VESSEL_SUCCESSFULLY_REMOVED_FROM_YOUR_FLEET: 'Vessel successfully removed from My Fleet',
        FLEET_LIST: {
            ALL_VESSELS: 'All Vessels',
            MY_FLEETS: 'My fleets',
            SHARED_FLEETS: 'Shared fleets'
        },
        VESSEL_TABLE: {
            ADD_TO_MY_FLEET: 'Add to my fleet',
            ASSIGN_TO_FLEET: 'Assign to company fleet',
            CAPACITY: 'Capacity',
            COATING_TYPE: 'Coating Type',
            COMMERCIAL_OPERATOR: 'Commercial Operator',
            COMPANY_FLEETS: 'Company Fleets',
            DWT: 'DWT',
            EDIT_VESSEL: 'Edit vessel',
            NO_VESSELS: 'No vessels to show',
            MAX_SELECTION: 'Selection of #{max} or more items not allowed',
            IMO: 'IMO',
            IMO_CLASS: 'IMO Class',
            LOCATE_ON_MAP: 'Locate on map',
            REMOVE_FROM_MY_FLEET: 'Remove from my fleet',
            SEGMENT: 'Segment',
            VESSEL_NAME: 'Vessel name'
        },
        FILTERS: {
            COATING_TYPE: 'Coating Type',
            COATING_TYPE_PLACEHOLDER: 'Select Coating Type',
            COMMERCIAL_OPERATOR: 'Commercial Operator',
            COMMERCIAL_OPERATOR_PLACEHOLDER: 'Select Commercial Operator',
            COMPANY_FLEET: 'Company Fleet',
            COMPANY_FLEET_PLACEHOLDER: 'Select Company Fleet',
            RESET_FILTERS: 'Reset Filters',
            SEARCH_VESSELS: 'Search Vessels',
            SEARCH_VESSELS_PLACEHOLDER: 'Enter name or IMO',
            SEGMENT: 'Segment',
            SEGMENT_PLACEHOLDER: 'Select Segment',
            TECHNICAL_MANAGER: 'Technical Manager',
            TECHNICAL_MANAGER_PLACEHOLDER: 'Select Technical Manager',
            VESSEL_OWNER: 'Vessel Owner',
            VESSEL_OWNER_PLACEHOLDER: 'Select Vessel Owner'
        },
        ATTRIBUTES: {
            MAIN_ATTRIBUTE: 'Main Attribute',
            MAIN_ATTRIBUTE_PLACEHOLDER: 'Select Main Attribute',
            SECONDARY_ATTRIBUTE: 'Secondary Attribute',
            SECONDARY_ATTRIBUTE_PLACEHOLDER: 'Select Secondary Attribute',
            SECONDARY_ATTRIBUTE_DISABLED_PLACEHOLDER: 'Select Main Attribute first',
            VALUE: 'Value',
            VALUE_PLACEHOLDER: 'Select Value',
            MIN: 'Min',
            MAX: 'Max'
        },
        FLEET: {
            ALL_VESSELS: 'All Vessels',
            CONFIRM_DELETE: 'Are you sure you want to delete this fleet?',
            CREATED_BY: 'Created By',
            FLEET_SUCCESSFULLY_DELETED: 'Fleet successfully deleted!',
            FLEET_SUCCESSFULLY_UPDATED: 'Fleet successfully updated!',
            FLEET_SUCCESSFULLY_ADDED: 'Fleet successfully added!',
            LOADING_FLEET: 'Loading fleet...'
        },
        FLEET_EDIT: {
            CLEAR_SELECTION: 'Clear selection',
            DYNAMIC: 'Dynamic',
            DYNAMIC_TITLE: 'Dynamic fleet vessels are dynamically fetched with set parameters',
            FLEET_NAME_PLACEHOLDER: 'Fleet name',
            SHARED_FLEET: 'Shared Fleet',
            SHOW_ALL_SELECTED: 'Show all selected',
            STATIC: 'Static',
            STATIC_TITLE: 'Static fleet vessels are added manually',
            VESSELS_SELECTED: '#{count} #{pluralize[count]: [vessel,vessels]} selected'
        },
        ADD_VESSELS: 'Try adding some vessels from the left panel.',
        IMO: 'IMO',
        SHARED_FLEET: 'Shared Fleet',
        CREATED_BY: 'Created By'
    },
    FLEET_OVERVIEW: {
        TITLE: 'Fleet Overview',
        VESSEL_COUNT_MESSAGE: 'There are more than 1000 vessels selected on map.',
        VESSEL_COUNT_MESSAGE_2: 'Please adjust your filters in order to see the fleet activity.',
        VESSELS: 'Vessels',
        ACTIVITY: 'Activity',
        RANGE: 'Range',
        LADEN_BALLAST_RATIO: 'Laden / Ballast ratio',
        AVERAGE_BALLAST_SPEED: 'Average ballast speed',
        AVERAGE_LADEN_SPEED: 'Average laden speed',
        INCLUDE_TC_VOYAGES: 'Include TC voyages',
        DISCLAIMER_TEXT:
        'Disclaimer: All days are included, no exclusion for bad weather days. This is an average for guidance only and not to be used for performance calculations.'
    },
    FLEET_RANGE: {
        SEARCH_VESSELS_TITLE: 'Search by Vessel Name or IMO',
        SEARCH_VESSELS_PLACEHOLDER: 'Search Vessels',
        REPORTED_ON: 'Reported on: ',
        HSFO_DAYS_LEFT: 'HSFO: #{days} days of steaming time left',
        HSFO_DAY_LEFT: 'HSFO: 1 day of steaming time left',
        MISSING_DATA: 'Missing data - ',
        FO_ROB: 'FO ROB',
        GO_ROB: 'GO ROB',
        LG_ROB: 'LG ROB',
        EXPORT_XLSX: 'Export XLSX',
        REMAINING_DAYS: 'Remaining days',
        VESSEL: 'Vessel',
        NOTE: 'Note'
    },
    FOOTPRINT_TABLE: {
        AER: 'AER',
        AER_RANGE_SELECTED: 'AER calculated for selected time range',
        AER_VOYAGE_SELECTED: 'AER calculated for selected voyage',
        AER_WITH_CORRECTIONS: 'AER (with corrections)',
        AER_WITH_CORRECTIONS_INFO_TOOLTIP: 'Annual Efficiency Ratio: Total CO₂ emissions per DWT-mile (total distance traveled multiplied by deadweight)',
        EEOI: 'EEOI',
        VOYAGE_EMISSIONS: 'Voyage Emissions',
        VOYAGE_EMISSIONS_VMS: 'Voyage Emissions VMS',
        VOYAGE_ENERGY_EFFICIENCY: 'Voyage Energy Efficiency',
        DATE_RANGE_EMISSIONS: 'Date Range Emissions',
        DATE_RANGE_ENERGY_EFFICIENCY: 'Date Range Energy Efficiency',
        CONSUMPTION: 'Consumptions',
        VESSEL_CII: 'Vessel CII',
        YTD_CII_AND_AER: '#{year} YTD CII and AER',
        YTD_CII_AND_AER_WITH_CORRECTION: '#{year} YTD CII and AER (with corrections)',
        CII_AND_AER: '#{year} CII and AER',
        CII_AND_AER_WITH_CORRECTION: '#{year} CII and AER (with corrections)',
        FUEL: 'Fuel',
        VALUE: 'Value',
        VALUE_CANT_BE_CALCULATED_TOOLTIP: 'Value cannot be calculated, reported data is not correct',
        CH4: 'CH₄',
        CO: 'CO',
        CO2: 'CO₂',
        CO2_EMISSIONS: 'CO₂ Emissions',
        CO2_PER_DAY: 'CO₂ per day',
        CO2_PER_DOLLAR: 'CO₂ per $',
        N2O: 'N₂O',
        NMVOC: 'NMVOC',
        SOX: 'SOx',
        SOX_MT: 'SOx (mt)',
        NOX: 'NOx',
        NOX_MT: 'NOx (mt)',
        CO2_PRICE: 'CO₂ Price per mt',
        EU_ETS_PRICE: 'EU ETS Price',
        EU_ETS_CHART: 'EU ETS Chart',
        EU_ETS_YEAR: 'EU ETS Year',
        SHOW_EU_ETS_CHART: 'Show EU ETS Chart',
        SHOW_INFO: 'Show info',
        COEFFICIENTS: 'Coefficients',
        RESULT: 'Result',
        SECTIONS: 'Sections',
        TOTAL: 'Total'
    },
    GLOBAL: {
        ADD: 'Add',
        APPLY: 'Apply',
        AT: 'at',
        AT_SEA: 'At sea',
        BACK: 'Back',
        BALLAST: 'Ballast',
        CANCEL: 'Cancel',
        CAPACITY: 'Capacity',
        CLEAR: 'Clear',
        CLEAR_ALL: 'Clear all',
        CLOSE: 'Close',
        CONFIRM: 'Confirm',
        COPY_TO_CLIPBOARD: 'Copy to clipboard',
        COPY_TO_CLIPBOARD_SUCCESS: 'Copied to clipboard successfully!',
        CREATE: 'Create',
        CREATED_BY: 'Created By',
        DELETE: 'Delete',
        DISMISS: 'Dismiss',
        DOWNLOAD: 'Download',
        DWT: 'DWT',
        EDIT: 'Edit',
        FUEL: 'Fuel',
        FUEL_TYPES: 'Bunker Grades',
        HIDE_DETAILS: 'hide details',
        IMO: 'IMO',
        IMO_CLASS: 'IMO Class',
        IN_PORT: 'In port',
        KNOWN: 'Known',
        LADEN: 'Laden',
        MAXIMIZE: 'Maximize',
        MOBILE: 'Mobile',
        NO: 'No',
        NO_NAME: '--NO-NAME--',
        OFF: 'OFF',
        OFFSET: 'Offset',
        ON: 'ON',
        PREVIEW: 'Preview',
        REQUEST_INFO: 'Request Info',
        RESET: 'Reset',
        RESTORE_DOWN: 'Restore down',
        SAVE: 'Save',
        SAVE_CHANGES: 'Save Changes',
        SEARCH: 'Search',
        SELECT: 'Select',
        SELECT_ALL: 'Select All',
        SEND: 'Send',
        SHARED: 'Shared',
        SHOW_DETAILS: 'show details',
        SHOW_LESS: 'show less',
        SHOW_MORE: 'show more',
        SUBMIT: 'Submit',
        TEL: 'Tel',
        TOTAL: 'Total',
        UNAVAILABLE: 'Unavailable',
        UPDATED_BY_USER: 'Updated on #{date} by #{user}',
        UNDEFINED: 'Undefined',
        UNKNOWN: 'Unknown',
        YB: 'YB',
        YES: 'Yes',
        NOT_AVAILABLE: 'N/A'
    },
    GLOBAL_SEARCH: {
        SEARCH: 'Search...',
        STATUS: 'Status: ',
        CAPACITY: 'Capacity',
        DWT: 'DWT',
        IMO_CLASS: 'IMO Class'
    },
    GSM: {
        MAP_TYPE_CHANGE: {
            CHANGE_TO_SATELITE_VIEW: 'Satellite',
            CHANGE_TO_CLASSIC_VIEW: 'Map'
        },
        VESSEL_INFO: {
            BED_CAPACITY: 'Bed capacity',
            BREADTH: 'Breadth',
            CAR_CAPACITY: 'Car capacity',
            CUBIC_CAPACITY: 'Cubic capacity',
            DEADWEIGHT: 'Deadweight',
            DESIGN_DRAFT: 'Design draft',
            DESIGN_SPEED: 'Design speed',
            GROSS_TONNAGE: 'Gross tonnage',
            LANE_METER_CAPACITY: 'Lane meter capacity',
            LENGTH: 'Length',
            MAX_DRILLING_DEPTCH: 'Max drilling depth',
            MAX_WATER_DEPTH: 'Max water depth',
            OPERATIONAL: 'Draft Operational',
            PASSENGER_CAPACITY: 'Passenger capacity',
            PEOPLE_ON_BOARD: 'People on board',
            SERVICES_ONBOARD: 'Service onboard',
            SHIP_NAME: 'Ship name',
            SHIP_TYPE: 'Ship type',
            TRADING_AREA: 'Trading area',
            TRANSIT: 'Draft Transit',
            VESSEL_DESCRIPTION: 'Vessel description',
            VESSEL_DETAILS: 'Vessel details',
            YEAR_BUILT: 'Year built'
        },
        RIGHT_SIDE_MENU_SUBTITLE: {
            TANKER: 'Tanker',
            FERRY: 'Ferry',
            DRILLING_RIG: 'Drilling rig'
        },
        CITY_INFO: {
            OFFICES: 'Offices',
            ADDRESS: 'Address',
            EMAIL: 'Email',
            FAX: 'Fax',
            TELEPHONE: 'Telephone',
            QR_TEXT: 'For more information please scan our QR code and visit our website.',
            NO_OFFICE_CONTACT_INFO: 'No contact information for office'
        },
        MAP_LEGEND: {
            MAP_LEGEND: 'Map legend',
            ROW_ELEMENT_TITLE_TANKERS: 'Tankers',
            ROW_ELEMENT_TITLE_OFFICES: 'Offices',
            ROW_ELEMENT_TITLE_FERRIES: 'Ferries',
            ROW_ELEMENT_TITLE_RIGS_DRILL_SHIPS: 'Drilling rigs',
            ROW_ELEMENT_TITLE_FERRY_ROUTES: 'Ferry routes'
        },
        MAIN_PANEL: {
            HEADER: 'Our Fleet',
            TAB_TITLE_RIGS_DRILLS: 'Drilling rigs',
            TAB_TITLE_FERRY_ROUTES: 'Ferry routes',
            TAB_TITLE_OFFICES: 'Offices',
            TAB_TITLE_TANKERS: 'Tankers',
            TAB_TITLE_FERRIES: 'Ferries'
        }
    },
    INSPECTIONS: {
        TITLE: 'Inspections',
        ADD_INSPECTION: 'Add inspection',
        DELETE_SUCCESS: 'Successfully deleted inspection.',
        EXPORT_XLSX: 'Export XLSX',
        EXPORT_PDF: 'Export PDF',
        AVERAGE_OBSERVATION: 'Average observation number: ',
        OBSERVATION_STATS: '#{avg} for #{count} #{type} inspections',
        CLEAR_SELECTION: 'Clear selection',
        TABLE: {
            LOCATE_ON_MAP: 'Locate on map',
            VESSEL: 'Vessel',
            INSPECTION_DATE: 'Inspection date',
            TYPE: 'Type',
            PORT: 'Port',
            INSPECTED_BY: 'Inspected by',
            OBS_NO: 'Obs.',
            ACTIVITY: 'Activity',
            COMMENT: 'Comment',
            TECHNICAL_MANAGER: 'Technical manager',
            OWNER: 'Owner',
            EDIT_INSPECTION: 'Edit Inspection',
            DELETE_INSPECTION: 'Delete Inspection',
            CONFIRM_DELETE: 'Are you sure you want to delete this inspection?',
            EDIT_VESSEL: 'Edit Vessel'
        },
        FILTERS: {
            ALL_INSPECTIONS: 'All inspections',
            APPLY_FILTERS: 'Apply filters',
            CLEAR_FILTERS: 'Clear filters',
            COMPANY_FLEET: 'Company Fleet',
            HEAD_OWNER: 'Head Owner',
            INSPECTED_BY: 'Inspected by',
            INSPECTED_BY_PLACEHOLDER: 'Select Inspection Companies',
            INSPECTION_RANGE: 'Inspection range',
            INSPECTION_TYPE: 'Inspection Type',
            INSPECTION_TYPE_PLACEHOLDER: 'Select Inspection Type',
            LAST_INSPECTION: '1 last inspection',
            LAST_INSPECTIONS: '#{num} last inspections',
            SEGMENT: 'Segment',
            SEGMENT_PLACEHOLDER: 'Select Segments',
            SELECT_COMPANY_FLEETS: 'Select Company Fleets',
            SELECT_HEAD_OWNER: 'Select Head Owner',
            SELECT_TECHNICAL_MANAGER: 'Select Technical Manager',
            TECHNICAL_MANAGER: 'Technical Manager',
            VESSEL: 'Vessel',
            VESSEL_PLACEHOLDER: 'Search Vessels',
            VESSEL_SEARCH_TITLE: 'Search by Vessel Name or IMO',
            VIEW: 'View',
            VIEW_PLACEHOLDER: 'View'
        },
        FORM: {
            ADD_NEW_INSPECTION: 'Add New Inspection',
            COMMENT: 'Comment',
            EDIT_INSPECTION: 'Edit Inspection',
            GENERAL_INFORMATION: 'General Information',
            HEAD_OWNER: 'Head Owner',
            INSPECTED_BY: 'Inspected by',
            INSPECTION_ACTIVITY: 'Inspection Activity',
            INSPECTION_DATE: 'Inspection Date',
            INSPECTION_DOCUMENTATION: 'Inspection documentation',
            INSPECTION_TYPE: 'Inspection Type',
            NUMBER_OF_OBSERVATIONS: 'Number of Observations',
            PORT: 'Port',
            SUCCESSFULLY_ADDED_NEW_COMPANY: 'Company is successfully added',
            SUCCESSFULLY_ADDED_NEW_INSPECTION: 'Inspection is successfully added',
            SUCCESSFULLY_UPDATED_INSPECTION: 'Inspection is successfully updated',
            TECHNICAL_MANAGER: 'Technical Manager',
            VESSEL: 'Vessel'
        }
    },
    ITINERARY: {
        ACTIVITY: 'Activity',
        AGENT: 'Agent',
        ARRIVAL: 'Arrival',
        CALCULATE_DEVIATION: 'Calculate deviation',
        DEPARTURE: 'Departure',
        ETA: 'ETA',
        ETB: 'ETB',
        ETB_DATE: 'ETB Date',
        ETB_STATUS: 'ETB Status',
        ETD: 'ETD',
        FO: 'FO',
        LG: 'LG',
        GO: 'GO',
        RANGE: 'Range',
        REQUEST_DEVIATION: 'Request deviation',
        ROB: 'ROB',
        STEAM: 'Steam',
        TAGS: 'Tags',
        TITLE: 'Itinerary',
        VESSEL_NAME: 'Vessel name'
    },
    MAP: {
        TOOLTIPS: {
            REPORTED: 'Reported: ',
            DAILY_REPORT: 'Daily report'
        }
    },
    MAP_DATE_PICKER: {
        RESET: 'Reset'
    },
    MAP_OPTIONS: {
        TITLE: 'Map Options',
        LAYERS: 'Layers',
        COUNTRY_NAMES_AND_BORDERS: 'Country names and borders',
        AREAS_OF_INTEREST: 'Areas of interest',
        WEATHER_CONDITIONS: 'Weather conditions',
        NAVIGATIONAL_LAYERS: 'Navigational layers',
        RESET_ALL: 'Reset all',
        PORTS: 'Ports',
        ALWAYS_SHOW_NAMES_FOR_VESSELS: 'Always show vessel names',
        LEGEND: {
            ATTENTION_LADEN: 'In need of attention (laden)',
            ATTENTION_BALLAST: 'In need of attention (ballast)',
            LADEN: 'Vessels in fleets (laden)',
            MANAGED_LADEN: 'Managed vessels (laden)',
            OWNED_LADEN: 'Owned vessels (laden)',
            BALLAST: 'Vessels in fleets (ballast)',
            MANAGED_BALLAST: 'Managed vessels (ballast)',
            OWNED_BALLAST: 'Owned vessels (ballast)',
            OTHER_BALLAST: 'Other vessels (ballast)',
            OTHER_LADEN: 'Other vessels (laden)',
            EMPLOYED_VESSEL_LADEN: 'Shipments (laden)',
            EMPLOYED_VESSEL_BALLAST: 'Shipments (ballast)',
            MY_VESSEL_BALLAST: 'My Vessels (ballast)',
            MY_VESSEL_LADEN: 'My Vessels (laden)',
            TITLE: 'Map legend',
            ROUTE_LADEN: 'Historical route (laden)',
            ROUTE_BALLAST: 'Historical route (ballast)',
            PLANNED_ROUTE: 'Planned route',
            POSITIONS_MISSING: 'Positions missing',
            TC_VESSEL_LADEN: 'Chartered vessels (laden)',
            TC_VESSEL_BALLAST: 'Chartered vessels (ballast)'
        }
    },
    MAP_WEATHER_LAYERS: {
        LAYER: 'Layer',
        VALUE: 'Value'
    },
    MAP_WEATHER_TOOLTIP: {
        SWELL_WAVE_HEIGHT: 'Swell wave height:',
        ICE_PACK: 'Ice pack:'
    },
    MARKET_INTEL: {
        OPTIONS: {
            TITLE: 'Options',
            LAYERS: 'Layers',
            VESSEL_DISTRIBUTION: 'Vessel Distribution',
            COUNTRY_NAMES_AND_BORDERS: 'Country names and borders',
            NO_LAYER: 'No layer',
            SHOW_ALL: 'Show all',
            RESET_ALL: 'Reset all',
            SEGMENT: 'Segment',
            VESSEL_SEGMENT: 'Vessel segment',
            STATUS: 'Status',
            CARGO_STATUS: 'Cargo status'
        },
        TOTAL_VESSEL_DISTRIBUTION: {
            TITLE: 'Total vessel distribution',
            EAST: 'East',
            WEST: 'West'
        }
    },
    MONTH_POPUP: {
        SELECT_MONTH: 'Select month'
    },
    YEAR_POPUP: {
        SELECT_YEAR: 'Select year'
    },
    QUARTER_PICKER: {
        YEAR: 'Year',
        QUARTER: 'Quarter'
    },
    NAV_MENU: {
        ACCOUNT: 'Account',
        ADMIN_PANEL: 'Admin Panel',
        ALERTS: 'Alerts',
        CARGO_INQUIRY: 'Cargo Inquiry',
        CONTACT_US: 'Contact Us',
        DISTANCE_CALCULATOR: 'Distance Calculator',
        EMISSION_ESTIMATOR: 'Emission Estimator',
        ENERGY_MANAGEMENT: 'Energy Management',
        FLEET_DASHBOARD: 'Fleet Dashboard',
        FLEET_MANAGEMENT: 'Fleet Management',
        INSPECTIONS: 'Inspections',
        OPERATORS_DIARY: 'Operators Diary',
        KPI: 'KPI',
        MAP: 'Map',
        MAP_OPTIONS: 'Map options',
        MARKET_INTEL: 'Market Intel',
        MARKET_OVERVIEW: 'Market Overview',
        NOTIFICATIONS: 'Notifications',
        PORT_CALLS_ANALYTICS: 'Port Calls Analytics',
        POSITION_LIST: 'Position List',
        REPORTS: 'Reports',
        ROUTING: 'Routing',
        SETTINGS: 'Settings',
        SHIPS_OVERVIEW: {
            EAST: 'East of Suez',
            WEST: 'West of Suez',
            ACTIVE: 'At Sea',
            NEED_ATTENTION: 'In need of attention',
            IN_PORT: 'In Port',
            INACTIVE: 'INACTIVE',
            TOTAL: 'Total',
            TITLE: 'Ships overview'
        },
        VESSELS: 'Vessels',
        VOYAGE_PERFORMANCE: 'Voyage Performance',
        WEATHER_ROUTING: 'Weather Routing'
    },
    NOTIFICATION_SETTINGS: {
        TITLE: 'Notification Settings',
        DESCRIPTION: 'Description',
        PUSH_NOTIFICATIONS: 'Push Notifications',
        COMPANY_FLEETS: {
            NO_FLEETS_SELECTED: 'No company fleets selected',
            SELECTED_FLEETS: '#{fleetsNumber} company #{pluralize[fleetsNumber]: [fleet, fleets]} selected'
        },
        CUSTOM_FLEETS: {
            NO_FLEETS_SELECTED: 'No custom fleets selected',
            SELECTED_FLEETS: '#{fleetsNumber} custom #{pluralize[fleetsNumber]: [fleet, fleets]} selected'
        }
    },
    NOTIFICATIONS: {
        TITLE: 'Notifications',
        REPORTED: 'Reported',
        DISMISS_ALL: 'Dismiss all',
        DISMISSED_NOTIFICATIONS: 'Dismissed notifications',
        NO_NOTIFICATIONS: 'There are currently no active notifications',
        NO_DISMISSED_NOTIFICATIONS: 'There are currently no dismissed notifications',
        ACTIVE: 'Active',
        DISMISSED: 'Dismissed',
        DISMISS_SUCCESS: 'Notification dismissed successfully!',
        SELECTED: 'Selected',
        CONFIRM: {
            DISMISS_MESSAGE_1: 'This action will dismiss the alert for all users.',
            DISMISS_MESSAGE_2: 'Are you sure you want to proceed?',
            SEND_REMINDER: 'Send reminder to captain?'
        },
        MESSAGE: {
            CDI_AGE: 'Latest CDI inspection: #{timestamp}',
            CONSUMPTION_DEVIATION: 'Consumption #{deviation} mt over budget',
            CONSUMPTION_DEVIATION_CPA: 'CPA Consumption #{value} mt over the budget',
            CONSUMPTION_DEVIATION_UNDER: 'Consumption #{deviation} mt under budget',
            DAILY_REPORT: 'Daily report - #{timestamp}',
            DISMISSED_BY: 'Dismissed by',
            LAST_REMINDER_SENT: 'Last reminder sent on #{timestamp}',
            MISSING_REPORT: 'Report is missing',
            NEW_ETA: 'New ETA: #{timestamp}',
            OLD_ETA: 'Old ETA: #{timestamp}',
            OPTIMAL_BALLAST_TRIM: 'Optimal ballast trim: #{value} m',
            OPTIMAL_LADEN_TRIM: 'Optimal laden trim: #{value} m',
            REMINDER_DISABLED: 'Reminder sent less than a day ago',
            REMINDER_DISABLED_INVALID_EMAIL: 'Operations or vessel email invalid',
            REMINDER_SEND_TITLE: 'Send reminder to captain',
            REMINDER_SENT: 'Reminder sent - ',
            REMINDER_SENT_BY: 'Sent by: #{user}',
            REMINDER_SENT_TO: 'Sent to: ',
            REPORTED: 'Reported: #{timestamp}',
            REPORTED_BALLAST_TRIM: 'Reported ballast trim: #{value} m',
            REPORTED_LADEN_TRIM: 'Reported laden trim: #{value} m',
            SIRE_AGE: 'Latest SIRE inspection: #{timestamp}',
            SPEED_DEVIATION: 'Speed deviation: #{deviation} kn',
            SPEED_DEVIATION_CPA: 'CPA Speed Deviation #{value} kn',
            TRIM_DEVIATION: 'Trim condition not optimal',
            VOYAGE_NUMBER: ' on voyage #{voyageNumber}'
        }
    },
    NOTIFICATIONS_RULE: {
        TYPE: 'Type',
        RULE: 'Rule',
        COMPANY_FLEETS: 'Company Fleets',
        PUSH_NOTIFICATIONS: 'Push Notifications',
        DISABLED_RULE: 'Disabled Rule'
    },
    OPERATORS_DIARY: {
        TITLE: 'Operators Diary',
        FILTERS: {
            FLEETS: 'Fleets',
            FLEETS_PLACEHOLDER: 'Select Fleets',
            TIME_FRAME: 'Time frame',
            TIME_FRAME_PLACEHOLDER: 'Select Time Frame'
        },
        ITINERARY: {
            ACTIVITY: 'Activity',
            STEAM: 'Steam',
            ETA: 'ETA',
            ETB: 'ETB',
            ETD: 'ETD',
            AGENT: 'Agent',
            TAGS: 'Tags',
            ROB: 'ROB'
        },
        FIXTURES: {
            HEADER: 'Fixture: #{FixtureId}',
            COMMODITY: 'Commodity',
            CHARTERER: 'Charterer',
            BROKER: 'Broker',
            LAYCAN: 'L/C',
            COA: 'CoA',
            LOI: 'Loi'
        },
        TABLE: {
            ACTIVITY: 'Activity',
            AGENT: 'Agent',
            BROKER: 'Broker',
            CHARTERER: 'Charterer',
            CHARTERER_BROKER: 'Charterer/Broker',
            COMMODITY: 'Commodity',
            ETA: 'ETA',
            ETB: 'ETB',
            ETD: 'ETD',
            FIX_NUMBER: 'Fix #',
            FIXTURE: 'Fixture',
            FO: 'FO',
            GO: 'GO',
            ITINERARY: 'Itinerary',
            LATEST_COMMENT: 'Latest Comment',
            LAYCAN: 'L/C',
            LG: 'LG',
            NO_FIXTURES: 'No fixtures for this voyage',
            NO_ITINERARY: 'No itinerary for this voyage',
            NO_VOYAGES: 'No voyages',
            NONE: 'NONE',
            PORT: 'Port',
            STEAM: 'Steam',
            TAGS: 'Tags',
            VOYAGE: 'Voyage'
        }
    },
    PASSWORD_STRENGTH: {
        WEAK: 'Weak',
        MEDIUM: 'Medium',
        STRONG: 'Strong'
    },
    PORT_CALLS_ANALYTICS: {
        VESSEL: 'Vessel',
        VESSEL_NAME: 'Vessel Name',
        ACTIVITY: 'Activity',
        ADD_PORTS: 'Add ports',
        ADD_PORTS_MESSAGE: 'Add ports to include them in the calculation',
        ADD_VESSELS: 'Add vessels',
        ADD_VESSELS_MESSAGE: 'Add vessels to include them in the calculation',
        CALCULATE: 'Calculate',
        CAPACITY: 'Capacity',
        CLEAR_ALL: 'Clear all',
        CONFIRM: 'Confirm',
        DRAFT_AFTER: 'Draft after',
        DRAFT_BEFORE: 'Draft before',
        FLEET: 'Fleet',
        COUNTRIES: 'Countries',
        COUNTRY: 'Country',
        DWT: 'DWT',
        EDIT_PORTS: 'Edit ports',
        EDIT_VESSELS: 'Edit vessels',
        ETA: 'Eta (UTC)',
        ETD: 'Etd (UTC)',
        EXPORT_XLSX: 'Export XLSX',
        IMO: 'Imo',
        IMO_CLASS: 'IMO Class',
        MAX_500_VESSELS: 'No more than 500 vessels can be selected',
        NOTE_MESSAGE_CALCULATE: 'Please select parameters and calculate data for report.',
        NOTE_MESSAGE_EXPORT: 'We found #{count} stops for #{vesselsCount} vessel(s) that you can export.',
        NO_PORTS: 'No ports. Try changing search term.',
        NO_VESSELS: 'No vessels. Try changing search term.',
        NO_VESSELS_SELECTED: 'Please select at least one vessel.',
        NO_PORTS_SELECTED: 'Please select at least one port.',
        PORTS: 'Ports',
        PORT_NAME: 'Port Name',
        PORT_OF_DEPARTURE: 'Port of departure',
        PROBABILITY: 'Probability (%)',
        SEARCH_VESSELS_TITLE: 'Search by Vessel Name, IMO or Segment',
        SEARCH_PORTS_PLACEHOLDER: 'Search Ports',
        SEARCH_PORT_TITLE: 'Search by Port Name',
        SEARCH_VESSELS: 'Search Vessels',
        SEGMENT: 'Segment',
        SELECTED: 'selected',
        SELECT_COUNTRIES: 'Select ports by country',
        TIME_IN_PORT: 'Time in port (days)',
        TITLE: 'Port Calls Analytics',
        VESSELS: 'Vessels',
        VESSELS_SELECTED: 'vessels selected.',
        YB: 'YB',
        NO_VESSELS_ERROR: 'Please select at least one vessel.',
        NETWORK_ERROR: 'Request failed due to quantity of data. Please choose less vessels or a shorter date range.'
    },
    PORT_FORM: {
        EDIT_PORT: 'Edit Port',
        NEW_PORT: 'New Port',
        BASIC_INFO: 'Basic info',
        PORT_NAME: 'Port Name',
        PORT_ALIASES: 'Port Aliases',
        ADD_NEW_ALIAS: 'Add new alias',
        COUNTRY: 'Country',
        UNLO_CODE: 'UNLO Code',
        POSITION: 'Position',
        TIME_ZONE: 'Time Zone',
        ISPS_COMPLIANT: 'ISPS Compliant',
        LIQUID_FACILITIES: 'Liquid Facilities',
        RESTRICTIONS: 'Restrictions',
        VENDORS: 'Vendors',
        PREFERRED_AGENT: 'Preferred agent',
        TOWING_CONTRACT: 'Towing contract',
        ADDITIONAL_SERVICES: 'Additional Services',
        AWRP: 'AWRP',
        HULL_CLEANING: 'Hull cleaning',
        SLOPS: 'Slops',
        ADD_NOTE: 'Add note',
        BUNKERING: 'Bunkering',
        TYPE: 'Type',
        OTHER_INFO: 'Other Info',
        UPDATED_BY_USER: 'Updated on #{date} by #{user}',
        SUCCESSFULLY_ADDED: 'Port successfully added',
        SUCCESSFULLY_UPDATED: 'Port successfully updated'
    },
    PORT_INFO: {
        INVALID_ID_HEADLINE: 'Invalid port ID',
        INVALID_ID_TEXT: 'Couldn\'t find a port with ID: ',
        UPDATED_INFO: 'Port information updated on #{at} by #{by}',
        SUCCESSFULLY_UPDATED: 'Port info updated successfully',
        OVERVIEW: {
            TITLE: 'Overview',
            COORDINATES: 'Lat / Lng',
            LOCAL_TIME: 'Local Time',
            ACTIVITY: 'Activity',
            BUNKER_PRICES: 'Bunker prices',
            WIND_SPEED: 'Wind speed',
            PRECIPITATION: 'Precipitation',
            AIR_PRESSURE: 'Air pressure',
            ETA: 'ETA',
            IN_PORT: 'In port',
            UNLO_CODE: 'UNLO code'
        },
        DETAILS: {
            ADD_NOTE: 'Add note',
            ADDITIONAL_SERVICES: 'Additional services',
            AWRP: 'AWRP',
            BUNKER_PRICES: 'Bunker prices',
            BUNKER_PRICES_DISCLAIMER: 'Please always contact the bunker desk for updated indications and avails. '
                + 'Also remember to check if your vessel is allowed to bunker at the chosen location.',
            BUNKERING: 'Bunkering',
            HULL_CLEANING: 'Hull cleaning',
            OTHER_INFO: 'Other info',
            PREFERRED_AGENT: 'Preferred agent',
            RESTRICTIONS: 'Restrictions',
            SLOPS: 'Slops',
            SUCCESSFULLY_UPDATED: 'Port information successfully updated!',
            TITLE: 'Details',
            TOWING_CONTRACT: 'Towing contract',
            TYPE: 'Type',
            UPDATED: 'Updated on #{date}',
            UPDATED_BY_USER: 'Updated on #{date} by #{user}',
            VENDORS: 'Vendors'
        },
        TERMINALS: 'Terminals',
        PORT_CALLS: {
            TITLE: 'Port calls',
            START: 'Start: ',
            END: 'End: ',
            COST: 'Cost: ',
            FILTERS: {
                ACTIVITY: 'Activity',
                SEGMENTS: 'Segments',
                SELECT_ACTIVITIES: 'Select activities',
                SELECT_SEGMENTS: 'Select segment(s)'
            }
        },
        WEATHER: {
            TITLE: 'Weather'
        }
    },
    PORT_MANAGEMENT: {
        TITLE: 'Ports',
        CREATE_NEW_PORT: 'Create new port',
        FILTERS: {
            COUNTRY: 'Country',
            COUNTRY_PLACEHOLDER: 'Search Countries',
            PORT: 'Port',
            PORT_PLACEHOLDER: 'Search Ports',
            TERMINAL: 'Terminal',
            TERMINAL_PLACEHOLDER: 'Search Terminals',
            BERTH: 'Berth',
            BERTH_PLACEHOLDER: 'Search Berths'
        },
        SETTINGS: {
            TITLE: 'Columns Configuration'
        },
        TABLE: {
            SUBMITTED_BY: 'Submitted by',
            SEARCH_RESULTS: 'Search Results',
            NO_SEARCH_RESULTS: 'No items found',
            PINNED_ITEMS: 'Pinned Items',
            PORT_INFORMATION: 'Port Information',
            TERMINAL_INFORMATION: 'Terminal Information',
            BERTH_INFORMATION: 'Berth Information',
            TERMINALS: 'Terminals',
            BERTHS: 'Berths',
            EDIT_PORT: 'Edit Port',
            EDIT_TERMINAL: 'Edit Terminal',
            EDIT_BERTH: 'Edit Berth',
            ADD_TERMINAL: 'Add Terminal',
            ADD_BERTH: 'Add Berth',
            NAME: 'Name',
            LATEST_COMMENT: 'Latest Comment',
            POSITION: 'Position',
            LOCAL_TIME: 'Local time',
            ISPS_COMPLIANT: 'ISPS Compliant',
            LIQUID_FACILITIES: 'Liquid Facilities',
            LAST_UPDATED: 'Last Updated',
            PREFERRED_AGENT: 'Preferred agent',
            TOWING_CONTRACT: 'Towing contract',
            AWRP: 'AWRP',
            HULL_CLEANING: 'Hull cleaning',
            SLOPS: 'Slops',
            BUNKERING: 'Bunkering'
        }
    },
    POSITION_LIST: {
        TITLE: 'Position List',
        VESSEL_NAME: 'Vessel name',
        STATUS: 'Status',
        OPEN_DATE: 'Open Date',
        NEXT_OPEN: 'Next Open',
        ETA_DATE: 'Eta Date',
        DESTINATION: 'Destination :',
        ETA: 'ETA: ',
        LAST_CARGOES: '3 Last Cargoes',
        SELECTED: 'Selected',
        COMPOSE_LIST: 'Compose list',
        CLEAR_SELECTION: 'Clear selection',
        VESSELS_SELECTED: '#{vessels} #{pluralize[vessels]: [vessel,vessels]} selected',
        UNMAPPED: 'Unmapped',
        SEGMENT: 'Segment:',
        REGION: 'Region:',
        ROUTING_ERROR: 'Routing error',
        SIRE: 'Last Sire Date',
        YB: 'YB',
        DWT: 'DWT',
        CAPACITY: 'Capacity',
        IMO_CLASS: 'IMO Class',
        EMPTY_TABLE_MESSAGE: 'The list is empty. Try adjusting the filters.',
        NO_PORT_SELECTED: 'No port selected',
        FILTERS: {
            PORT: 'Port',
            COMPANY_FLEET: 'Company fleet',
            REGION: 'Region',
            FLEET: 'Fleet',
            GROUP_BY: 'Group By',
            RESET: 'Reset',
            SELECT_GROUP: 'Select group: ',
            SEARCH_PORT: 'Search port',
            SELECT_COMPANY_FLEETS: 'Select company fleets',
            SELECT_REGIONS: 'Select regions',
            SELECT_FLEETS: 'Select fleets',
            SPEED: 'Speed',
            ETA_COLUMN_DISABLED: 'ETA column disabled'
        },
        FIXTURE_STATUS: {
            OPEN: 'Open',
            ON_SUBS: 'On Subs',
            FIXED: 'Fixed'
        },
        NOTES: {
            TITLE: 'Notes',
            SUBMITTED_BY: 'Submitted by',
            REMOVE_NOTE: 'Are you sure you want to delete this note?'
        },
        COMPOSE: {
            TITLE: 'Compose Position List',
            SEND_EMAIL_SUCCESS: 'Email sent successfully!',
            RESET_CHANGES: 'Reset changes',
            SEND_POSITION_LIST: 'Send position list',
            LAST_UPDATED_BY: 'Last updated by: ',
            ON: ' on ',
            EDITED_INFORMATION_FOR_VESSELS: 'Edited information for #{count} #{pluralize[count]: [vessel,vessels]}',
            VESSEL_DATA_SET_TO_DEFAULT: 'Vessel data set to default',
            MODAL: {
                TITLE: 'Position List Email',
                SUBJECT: 'Subject',
                SIGNATURE: 'Signature',
                TO: 'To',
                CC: 'CC',
                BCC: 'BCC',
                MESSAGE: 'Message'
            }
        },
        SETTINGS: {
            TITLE: 'Settings',
            GROUPING: 'Grouping',
            COLUMNS: 'Columns',
            SELECT_GROUPING: 'Select grouping',
            APPLY_SETTINGS: 'Apply settings',
            VESSEL_PROPERTIES: 'Vessel Properties',
            INTERNAL: 'Internal',
            EXPORT: 'Export'
        }
    },
    QUESTIONNAIRE: {
        TITLE: 'Questionnaire',
        SECTIONS: {
            BUNKER_ROB: 'Bunker ROB',
            CLEANING: 'Cleaning',
            CP_SPEED_INFORMATION: 'CP Speed Information (Eco and Full)',
            MINIMUM_SPEED_DETAILS: 'Minimum Speed Details',
            OPERATIONAL_ENGINE_LIMITS: 'Operational engine limits',
            PROPELLER_CHARACTERISTICS: 'Propeller characteristics',
            PORT_VISITS: 'Port visits (newest to oldest - 1 to 10)',
            RPM_RANGE: 'RPM Range',
            SPEED_CONSUMPTIONS: 'Speed Consumptions',
            REMARKS: 'Remarks'
        },
        MESSAGES: {
            BUNKER_ESTIMATIONS_MUST_BE_DEFINED: 'Bunker estimations must be defined',
            QUESTIONNAIRE_SUCCESSFULLY_UPDATED: 'Questionnaire successfully updated!',
            QUESTIONNAIRE_SUCCESSFULLY_DELETED: 'Questionnaire successfully deleted!'
        },
        LABELS: {
            ANSWER: 'Answer',
            AUX_CONSUMPTION: 'AUX Consumption (mt)',
            AVERAGE_SLIP: 'Average Slip',
            BALLAST_CONSUMPTION: 'Ballast Cons (mt)',
            BUNKER_TYPES: 'Bunker Types',
            CALL_SIGN: 'Call Sign',
            CARGO: 'Cargo',
            CLEANING_EQUIPMENT_ON_BOARD: 'Which (if any) cleaning equipment is onboard?',
            CHARTERER: 'Charterer',
            DATE: 'Date',
            ENERGY_SAVING_DEVICES_FITTER: 'Energy saving devices fitter',
            EXPANDED_AREA: 'Expanded area',
            LADEN_CONSUMPTION: 'Laden Cons (mt)',
            LAST_PROPELLER_POLISH: 'The vessel\'s last propeller polish?',
            LAST_UW_CLEANING: 'The vessel\'s last UW cleaning?',
            LAST_UW_INSPECTION: 'The vessel\'s last UW inspection?',
            MAX: 'Max',
            MAX_SPEED: 'Maximum speed (kn)',
            MAX_SPEED_CONSUMPTION: 'Consumption at maximum speed (mt)',
            MAX_SPEED_RPM: 'RPM at maximum speed',
            ME_CONSUMPTION: 'ME Consumption (mt)',
            MIN: 'Min',
            MIN_SPEED: 'Minimum speed (kn)',
            MIN_SPEED_CONSUMPTION: 'Consumption at minimum speed (mt)',
            MIN_SPEED_RPM: 'RPM at minimum speed',
            NORMAL_RPM: 'Normal RPM',
            NUMBER_OF_BLADES: 'Number of blades',
            NO_PORT_VISITS_AVAILABLE: 'No Port visits available',
            PITCH_OF_PROPELLER: 'Pitch of the propeller',
            POINT: 'Point',
            PORT: 'Port',
            PORT_VISIT: 'Port visit',
            POWER: 'Power',
            PROLONGED_PORT_STAYS: 'Have you had any prolonged port stays (over 15 days) since?',
            PROPELLER_DIAMETER: 'Propeller diameter',
            REMARKS: 'Remarks',
            REPORT_DATE: 'Report Date',
            RPM: 'RPM',
            SEARCH_TITLE: 'Search by Vessel Name or IMO',
            SPEED: 'Speed',
            SPEED_AND_CONSUMPTION_DETAILS: 'The vessel\'s speed and consumption details, from the sea trials data',
            SPEED_KN: 'Speed (kn)',
            STATE: 'State',
            TORQUE: 'Torque',
            TOTAL_CONSUMPTION: 'Total Consumption (mt)',
            TYPE: 'Type',
            VESSEL: 'Vessel'
        }
    },
    RAW_REPORTS_TABLE: {
        ARRIVAL_REPORTS: 'Arrival Reports',
        CARGO_REPORTS: 'Cargo Reports',
        CONFIRM_DELETE: 'Are you sure you want to delete this report?',
        DAILY_REPORTS: 'Daily Reports',
        DELETED: 'Deleted',
        DEPARTURE_REPORTS: 'Departure Reports',
        EVENT_REPORTS: 'Event Reports',
        EXCLUDED: 'Excluded',
        INCLUDED: 'Included',
        MISSING: 'Missing',
        RECALCULATE: 'Recalculate',
        REPORTS_UPDATED_TEXT: 'Calculation needs to be updated. Please recalculate reports in order to see updated data.',
        SHOW_ALL_REPORTS_TITLE: 'Show all reports',
        SOF_REPORTS: 'SOF Reports',
        SUSPICIOUS: 'Suspicious',
        SUSPICIOUS_INACTIVE_TITLE: 'Show only suspicious reports',
        SUSPICIOUS_INACTIVE_LIMIT_TITLE: 'Show only suspicious reports - count not available',
        SUSPICIOUS_ONLY: 'Suspicious Only (#{count})',
        UPDATED: 'Updated',
        VERACITY_SYNC_ERROR_INACTIVE_TITLE: 'Show only reports with Veracity sync errors',
        VERACITY_SYNC_ERROR_ONLY: 'Sync Error Only (#{count})',
        VERACITY_SYNC_ERROR: 'Sync Errors',
        VERACITY_MODAL: {
            TITLE: 'Details from Veracity',
            ERROR: 'Error #{index}: ',
            WARNING: 'Warning #{index}: ',
            NOT_SENT_MESSAGE: 'This report could not be sent because the report version does not include required fields for Veracity'
        }
    },
    REPORT_LABELS: {
        ADD_OPERATION: 'Add Operation',
        ADDITIONAL_INFORMATION: 'Additional information',
        ALL_FAST: 'All Fast',
        ANCHOR_AWEIGH_STOPPED_DRIFTING: 'Anchor Aweigh Stopped Drifting',
        ANCHORED: 'Anchored',
        ANCHORED_COMMENCED_DRIFTING: 'Anchored Commenced Drifting',
        ANCHORED_DATE: 'Anchored Date',
        ARRIVAL_DATE: 'Arrival Date',
        ARRIVAL_DRAFT_AFT: 'Arrival Draft Aft',
        ARRIVAL_DRAFT_FWD: 'Arrival Draft Fwd',
        ARRIVAL_DRAFT_MID: 'Arrival Draft Mid',
        ARRIVAL_REPORT: 'Arrival Report',
        ARRIVING_PILOT_STATION: 'Arriving Pilot Station Date',
        AT_LEAST_ONE_REQUIRED: 'Must have at least one value defined',
        AUXILIARY: 'Auxiliary',
        AVERAGE_LOAD_DISCHARGE_RATE: 'Average load / discharge rate',
        AVERAGE_LOAD_DISCHARGE_RATE_TITLE: 'Total cargo loaded/discharged / (Report Date - Commenced Date)',
        AVERAGE_RPM: 'Average RPM',
        AVERAGE_SPEED: 'Average Speed',
        AVG_SPEED: 'Average Speed',
        BALANCE_CARGO_LOADED_DISCHARGED: 'Balance Cargo to Load/Discharge',
        BALLAST: 'Ballast',
        BALLAST_LADEN: 'Cargo Status',
        BARRIER_SPACE_CONDITIONS: 'Barrier Space Conditions',
        BERTH_NAME: 'Berth Name',
        BERTH_TERMINAL: 'Berth / Terminal',
        BERTH_TERMINAL_ANCHORAGE: 'Berth / Terminal / Anchorage',
        BOILER: 'Boiler',
        BOILER_OFF: 'Boiler Off',
        BOTTOM: 'Bottom (°C)',
        BUDGET: 'Budget',
        BUNKER: 'Bunker',
        BUNKER_AUX: 'Bunker AUX',
        BUNKER_BALLAST_EXCHANGE: 'Bunker Ballast Exchange',
        BUNKER_CARGO_CIRCULATION: 'Bunker Cargo Circulation',
        BUNKER_CLEANING: 'Bunker Cleaning',
        BUNKER_DATA: 'Bunker Data',
        BUNKER_DELIVERY_NOTE: 'Bunker Delivery Note (BDN)',
        BUNKER_DELIVERY_NOTE_NUMBER: 'BDN Number',
        BUNKER_DELIVERY_NOTE_DATE: 'BDN Date',
        BUNKER_EXCHANGE: 'Ballast exchange',
        BUNKER_HEATING: 'Bunker Heating',
        BUNKER_IG: 'Bunker IG',
        BUNKER_LIFTED: 'Bunker Lifted',
        BUNKER_LOAD_DISCHARGE: 'Bunker Load Discharge',
        BUNKER_PROPULSION: 'Bunker Propulsion',
        BUNKER_ROB: 'Bunker ROB',
        BUNKER_ROB_MUST_BE_DEFINED: 'Must have one ROB fuel type defined',
        BUNKER_ROB_SULPHUR_MUST_BE_DEFINED: 'Sulphur percentage must be defined',
        BUNKER_ROB_NEXT_PORT_MUST_BE_DEFINED: 'Estimated ROB at arrival next port must be defined',
        BUNKER_SULPHUR: 'Bunker Sulphur',
        BUNKER_TYPES: 'Bunker Types',
        CALL_SIGN: 'Call Sign',
        CARGO: 'Cargo',
        CARGO_CIRCULATION: 'Cargo circulation',
        CARGO_CONTAINMENT: 'Cargo Containment',
        CARGO_DOCUMENTS_ONBOARD: 'Cargo Documents Onboard',
        CARGO_GRADE: 'Cargo Grade',
        CARGO_ITEM_TITLE: 'Operation',
        CARGO_OPERATIONS: 'Cargo Operations',
        CARGO_OPS: 'Cargo Ops',
        CARGO_QUANTITY: 'Cargo Quantity',
        CARGO_REPORT: 'Cargo Report',
        CARGO_STATUS: 'Cargo Status',
        CHARTERPARTY_SPEED: 'Charter party Speed',
        CHARTERER: 'Charterer',
        CHARTERPARTY_DATE: 'Charter party Date',
        CLEANING: 'Cleaning',
        COFFERDAM_LOWEST_TEMPERATURES: 'Cofferdam Lowest Temperatures',
        COMMENCED: {
            DATE: 'Date',
            TITLE: 'Commenced',
            ROB_HSFO: 'ROB HSFO',
            ROB_LSGO: 'ROB LSGO',
            LIFTED_HSFO: 'Lifted HSFO',
            LIFTED_LSGO: 'Lifted LSGO'
        },
        COMMENCED_CARGO_DATE: 'Commenced Cargo Date',
        COMMENCED_DATE: 'Commenced Date',
        COMMENCED_DO: 'Commenced DO',
        COMMENCED_HSFO: 'Commenced HSFO',
        COMMENCED_LSDO: 'Commenced LSDO',
        COMMENCED_LSGO: 'Commenced LSGO',
        COMMENCED_ROB_HSFO: 'Commenced ROB HSFO',
        COMMENCED_ROB_LSGO: 'Commenced ROB LSGO',
        COMPLETED: {
            TITLE: 'Completed',
            CARGO: 'Completed Cargo',
            DATE: 'Date',
            ROB_HSFO: 'ROB HSFO',
            ROB_LSGO: 'ROB LSGO',
            LIFTED_HSFO: 'Lifted HSFO',
            LIFTED_LSGO: 'Lifted LSGO'
        },
        CARGO_LOADED_DISCHARGED_THIS_PORT: 'Cargo loaded / discharged in this port (mt)',
        COMPLETED_CARGO: 'Completed Cargo',
        COMPLETED_DATE: 'Completed Date',
        COMPLETED_DO: 'Completed DO',
        COMPLETED_HSFO: 'Completed HSFO',
        COMPLETED_LSDO: 'Completed LSDO',
        COMPLETED_LSGO: 'Completed LSGO',
        COMPLETED_ROB_HSFO: 'Completed ROB HSFO',
        COMPLETED_ROB_LSGO: 'Completed ROB LSGO',
        COMPLETION_CARGO_OPS: 'Completion of Cargo Ops Date',
        CONDENSER_VACUUM: 'Cond. Vacuum (mm Hg)',
        CONSUMED_HSFO: 'Consumed HSFO',
        CONSUMED_LSGO: 'Consumed LSGO',
        CONSUMED: 'Consumed (mt)',
        CONSUMPTION_TYPES: 'Consumption Types',
        CONSUMPTIONS_DURING_STOPPAGE: 'Consumptions During Stoppage',
        CONSUMPTIONS_FROM_DAILY_TO_EVENT: 'Consumption From Daily To Event',
        CONSUMPTIONS: 'Consumptions',
        CONSUMPTIONS_SINCE_LAST_EVENT: 'Consumptions Since Last Event',
        COURSE: 'Course',
        CURRENT_PORT: 'Current Port',
        CURRENT: 'Current',
        DAILY_DATE_LT_ZD: 'Report Date',
        DAILY_REPORT: 'Daily Report',
        DATE: 'Date',
        DEADWEIGHT: 'Deadweight',
        DEPARTURE_DATE: 'COSP Date',
        DEPARTURE_PORT: 'Departure Port',
        DEPARTURE_REPORT_DATE: 'Departure Date',
        DEPARTURE_REPORT_ETA: 'ETA',
        DEPARTURE_REPORT: 'Departure Report',
        DEPARTURE: 'Departure',
        DESTINATION_PORT: 'Destination Port',
        DISCHARGE_OPERATIONS: 'Discharge Operation Consumptions',
        DISPLACEMENT: 'Displacement',
        DIST_FROM_LAST_PORT: 'Dist. From Last Port',
        DIST_LAST_PORT: 'Dist Last Port',
        DISTANCE_FROM_LAST_PORT: 'Distance From Last Port',
        DISTANCE_THROUGH_ICE: 'Distance Through Ice',
        DISTANCE_TO_GO: 'Distance To Go',
        DISTANCE_SINCE_LAST_EVENT: 'Distance SLE',
        DRAFT_AFT: 'Draft AFT',
        DRAFT_FWD: 'Draft FWD',
        DRAFT_MID: 'Draft MID',
        DROPPING_OUTWARD_PILOT: 'Dropping Outward Pilot Date',
        DWT_BALLAST: 'DWT Ballast',
        DWT_CARGO: 'DWT Cargo',
        DWT_FW: 'DWT FW',
        DWT_SLOPS: 'DWT Slops',
        DWT_STORES: 'DWT Stores',
        DWT_TOTAL_BUNKERS: 'DWT Total Bunkers',
        DWTFW: 'DWTFW',
        ECA_DISTANCE_STEAMED_SINCE_LAST_REPORT: 'Eca Distance Steamed Since Last Report',
        EDIT_INFO: 'Last Edited',
        ELECTRICAL_LOAD: 'Electrical Load',
        END_OF_SEA_PASSAGE: 'End Of Sea Passage',
        ENERGY_PRODUCED: 'Energy Produced (kWh)',
        ENGINE_DISTANCE: 'Engine Distance',
        ENGINE: 'Engine',
        ENGINEERING: 'Engineering',
        EOSP_DATE: 'EOSP Date',
        ET_OF_BERTHING: 'Estimated Time Of Berthing',
        ETA_DATE: 'ETA Date',
        ETB_DATE: 'ETB Date',
        ETD_DATE: 'ETD Date',
        ETD_FROM_BERTH_ANCHORAGE: 'ETD from berth / anchorage',
        ETD_FROM_BERTH: 'ETD From Berth',
        EVENT_DATE: 'Event Date',
        EVENT_REPORT: 'Event Report',
        EVENT_TYPE: 'Event Type',
        EVENT: 'Event',
        EXCLUSION_REASONS: 'Exclusion Reasons',
        EXPECTED_DEPARTURE_DRAFT: 'Expected Departure Draft',
        EXTERNAL_WIND_FORCE: 'External Wind Force',
        FORCER_HOURS: 'Forcer Hours',
        FORCER_USED: 'Forcer Used',
        FREE_PRATIQUE_GRANTED: 'Free Pratique Granted',
        FRESH_WATER_FOR_TANK_CLEANING: 'Fresh Water for Tank Cleaning',
        FRESH_WATER: 'Fresh Water',
        FUEL_TYPES: 'Bunker Grades',
        FUEL: 'Fuel',
        GEN_LOAD: 'Gen Load (kW)',
        GM: 'GM',
        HEATED: 'Heated',
        HOSES_CONNECTED: 'Hoses Connected',
        HOSES_DISCONNECTED: 'Hoses Disconnected',
        HOURS_SINCE_LAST_REPORT: 'Hours Since Last Report',
        HOURS_STEAMED_SINCE_LAST_REPORT: 'Hours Steamed Since Last Report',
        HOURS_STEAMED_SINCE_LAST_EVENT: 'Hours Steamed SLE',
        HOURS_STEAMED: 'Hours Steamed',
        HOURS: 'Hrs',
        IMO: 'IMO',
        INMARSAT_AREA_CODE: 'Inmarsat Area Code in Use',
        INSTRUCTED_SPEED: 'Instructed Speed',
        INVALID_ID_HEADLINE: 'Invalid report ID',
        LIFTED_REQUIRED_TO_BE_SET: 'Can be set only if lifted is set',
        LAST_HULL_CLEANING: 'Last Hull Cleaning',
        LAST_PROPELLER_CLEANING: 'Last Propeller Cleaning',
        LAY_DAYS_FROM_DATE: 'Lay Days From Date',
        LAY_DAYS_TO_DATE: 'Lay Days To Date',
        LEAVE_BERT_TERMINAL: 'Leave Berth / Anchorage Date',
        LIFTED_HSFO: 'Lifted HSFO',
        LIFTED_LSGO: 'Lifted LSGO',
        LIQUID_TEMP: 'Liquid Temp. (°C)',
        LOAD_DISCHARGE_RATE_AVG: 'Average load / discharge rate',
        LOAD_DISCHARGE_RATE_MAX: 'Load/discharge rate max from terminal',
        LOAD_DISCHARGE: 'Load Discharge',
        LOG_SPEED: 'Log Speed',
        LOG_DISTANCE: 'Log Distance',
        MAIN_ENGINE: 'Using Main Engine',
        MASTERS_ETA_AT_PILOT_STATION: 'Masters ETA At Pilot Station',
        MASTERS_INTENDED_ROUTE: 'Masters Intended Route',
        MASTERS_INTENDED_RPM_SETTING_THIS_VOYAGE: 'Masters Intended RPM Setting',
        MASTERS_NAME: 'Masters Name',
        MCR: 'MCR',
        ME_POWER: 'ME power',
        MIDDLE: 'Middle (°C)',
        NAVIGATION_TYPE: 'Navigation Type',
        NEXT_PORT_INFORMATION: 'Port Information',
        NEXT_PORT: 'Next Port',
        NEXT_LEG_REPORT: 'Next Leg Report',
        NO_AIS_SUGGESTION: 'No AIS suggestion.',
        NOR_DATE: 'NOR Date',
        NOR_TENDERED: 'NOR Tendered',
        NOR: 'NOR',
        NOTICE_OF_READINESS: 'Notice of readiness',
        NUMBER_OF_GANGS_HATCHES_WORKING: 'Number of gangs / hatches working',
        NUMBER_OF_TUGS_USED: 'Number of tugs used',
        OBSERVED_DISTANCE: 'Observed Distance',
        OPERATION_NUMBER: 'Operation #{operationNumber}',
        OTHER_INFO: 'Other Info',
        PILOT_EXEMPTED: 'No (exempted)',
        PILOT_ONBOARD: 'Pilot Onboard',
        PILOT_USED: 'Pilot used',
        POINT: 'Point',
        PORT_ACTIVITY_TYPE: 'Port Activity Type',
        PORT_INFORMATION: 'Port Information',
        PORT_NAME: 'Port Name',
        PORT_OF_ARRIVAL: 'Port of Arrival',
        PORT_OF_DEPARTURE: 'Port of departure',
        PORT_OF_LAST_HULL_CLEANING: 'Port of last hull cleaning',
        PORT_OF_LAST_PROPELLER_CLEANING: 'Port of last propeller cleaning',
        PORT_TYPE: 'Port Type',
        PORT: 'Port Name',
        POSITION: 'Position',
        PREVIOUS_PORT: 'Previous Port',
        PRIMARY_INSULATION_SPACE_PRES: 'Primary Insulation Space Press (mbar)',
        PRODUCED: 'Produced (mt)',
        PROPULSION_MOTOR_LOAD: 'Prop. Motor Load (kW)',
        PROPULSION_MOTORS: 'Propulsion Motors',
        PUMP_PRESSURE_MAX: 'Pump pressure max from terminal',
        PUMP_PRESSURE_REACHED: 'Pump pressure reached',
        QTY_LOADED_DISCHARGED: 'Qty loaded / discharged',
        REASON_FOR_USING_TUGS: 'Reason for using tugs',
        REMARKS: 'Remarks',
        REMINDER_DATE: 'Reminder Date',
        REMINDER_SUCCESSFULLY_SENT: 'Reminder successfully sent!',
        REPORT_DATE: 'Report Date',
        REPORT_SUCCESSFULLY_DELETED: 'Report successfully deleted',
        REPORT_SUCCESSFULLY_UPDATED: 'Report successfully updated',
        REQUIRED_WHEN_LIFTED_IS_DEFINED: 'Required when Lifted is defined',
        REVS: 'Revs',
        ROLL_PERIOD: 'Roll period',
        RPM_RANGE: 'RPM Range',
        RPM: 'RPM',
        RUNNING_HOURS_PER_DAY: 'Running Hours Per Day',
        SEA_CONDITION: 'Sea Condition',
        SEA_HEIGHT: 'Sea Height',
        SEA: 'Sea',
        SEARCH_TITLE: 'Search by Vessel Name or IMO',
        SECONDARY_BARRIER_AVG_TEMP: 'Secondary Barrier Avg. Temp. (°C)',
        SECONDARY_INSULATION_SPACE_PRES: 'Secondary Insulation Space Press (mbar)',
        SELECT_BUNKER_TYPES: 'Select Bunker Types',
        SELECT_CONS_TYPES: 'Select Cons. Types',
        SELECT_FUEL_TYPES: 'Select Bunker Grade',
        SHAFT_POWER: 'Shaft Power (kW)',
        SHIP_TO_SHIP_CARGO_TRANSFER: 'Ship to ship cargo transfer',
        SIDE: 'Side',
        SINCE_LAST_DAILY_REPORT: 'Since last daily report',
        SINCE_LAST_EVENT: 'Since last event (SLE)',
        SLIP: 'Slip',
        SLOPS: 'Slops',
        SOF_INFO: 'SOF Info',
        SOF_REPORT: 'SOF Report',
        SPEED_INSTRUCTION_TYPE: 'Speed instruction type',
        SPECIAL_AREA: 'Special Area',
        START_PORT: 'Start Port',
        STATUS: 'Status',
        STEAM_FLOW: 'Steam Flow (mt/h)',
        STOPPAGE_FROM: 'Stoppage From',
        STOPPAGE_TO: 'Stoppage To',
        STORES: 'Stores',
        SUBTYPE: 'Subtype',
        SUGGESTED_TOOLTIP: 'Click to change',
        SUGGESTED: 'Suggested:',
        SUGGESTED_LINK_TOOLTIP: 'Click to open',
        SUGGESTED_LINK: 'Suggested linked report:',
        SWELL: 'Swell',
        SYNC_WITH_VERACITY: 'Sync with Veracity',
        TANK_CLEANING_FINISHED: 'Tank cleaning finished',
        TANK_CLEANING_STARTED: 'Tank cleaning started',
        TANK_CLEANING: 'Tank cleaning before loading',
        TANK_PRES: 'Tank Pres. (mbar)',
        TANK: 'Tank',
        TANKS: 'Tanks',
        TIME_OF_PASSAGE: 'Time Of Passage',
        TIME_SINCE_LAST_EVENT: 'Time Elapsed SLE',
        TIME_AT_ANCHOR_SINCE_LAST_EVENT: 'Time At Anchor SLE',
        TOP: 'Top (°C)',
        TOTAL_BUNKERS: 'Total Bunkers',
        TOTAL_CARGO_LOADED_DISCHARGED: 'Total cargo loaded / discharged',
        TOTAL_CARGO_ONBOARD: 'Total Cargo Onboard',
        TOTAL_DISTANCE_STEAMED_SINCE_LAST_REPORT: 'Total Distance Steamed Since Last Report',
        TOTAL_DISTANCE_STEAMED_IN_AREA: 'Total Distance Steamed In Event Circumstances',
        TOTAL_TIME_SPENT_IN_AREA: 'Total Time Spent In Event Circumstances',
        TRIM_TYPE: 'Trim Type',
        TRIM: 'Trim',
        TURBINE: 'Turbine',
        UNMOORED: 'Unmoored',
        USING_MAIN_ENGINE: 'Using Main Engine',
        UTILIZATION: 'Utilization',
        VAPOUR_TEMP: 'Vapour Temp. (°C)',
        VERACITY_SYNC_ERROR: 'Sync error',
        VERACITY_NOT_SENT: 'Not sent to Veracity',
        VERACITY_SYNCED_SUCCESSFULLY: 'Synced successfully',
        VESSEL_CONDITION: 'Vessel Condition',
        VESSEL_NAME: 'Vessel Name',
        VESSEL_STATUS: 'Vessel Status',
        VESSEL: 'Vessel',
        VOYAGE_NUMBER: 'Voyage Number',
        VOYAGE: 'Voyage',
        WARRANTED_CONSUMPTION: 'Warranted Consumption',
        WATER_TEMPERATURE: 'Water Temperature',
        WATER_TYPE: 'Water Type',
        WATER_USED_FOR_TANK_CLEANING: 'Water used for tank cleaning',
        WAYPOINT_ITEM_TITLE: 'Waypoint',
        WEATHER_DIRECTION_IS_NOT_VALID: 'Weather direction #{directionVal} is not valid.',
        WEATHER_DIRECTION: 'Weather Direction',
        WEATHER: 'Weather',
        WIND_FORCE_EXTERNAL: 'Wind Force (external)',
        WIND_FORCE: 'Wind Force',
        WIND_FORCE_SINCE_LAST_EVENT: 'Wind Force SLE',
        WIND_SPEED: 'Wind Speed',
        WIND: 'Wind'
    },
    RESTRICTIONS: {
        TITLE: 'Restrictions',
        MAX_DRAFT: 'Max Draft',
        MAX_LOA: 'Max LOA',
        MAX_BEAM: 'Max Beam',
        MAX_DISPLACEMENT: 'Max Displacement',
        MAX_DWT: 'Max DWT',
        WATER_DENSITY: 'Water Density',
        UKC: 'UKC',
        SIDE: 'Side',
        MANIFOLD: 'Manifold',
        DISTANCE_TO_SHORE_TANKS: 'Distance to Shore Tanks',
        DIST_TO_SHORE_TANKS: 'Dist. to Shore Tanks',
        AVG_LOADING_RATE: 'Avg. Loading rate'
    },
    SEND_REMINDER_MODAL: {
        SEND_REMINDER_TO_CAPTAIN: 'Send reminder to captain?',
        MISSING_REPORT_ON_DATE: 'Report is missing on #{missingReportDate}',
        REPORT_MISSING: 'Report is missing',
        LAST_REMINDER_SENT: 'Last reminder sent on: #{timestamp}',
        REMINDER_SENT_BY: 'Sent by: #{user}',
        REMINDER_SENT_TO: 'Sent to: #{emails}',
        REMINDER_DISABLED: 'Reminder sent less than a day ago',
        REMINDER_DISABLED_INVALID_EMAIL: 'Operations or vessel email invalid',
        REMINDER_SEND_TITLE: 'Send reminder to captain'
    },
    TANK_LAYOUT: {
        CARGO_WEIGHT: 'Cargo Weight:',
        COMMODITY: 'Commodity:',
        SIZE: 'Size:',
        LOADED: 'Loaded:',
        CHARTERER: 'Charterer:',
        LOADED_TEMPERATURE: 'Loaded Tmp:',
        SG: 'SG:',
        PREVIOUS_COMMODITIES: 'Previous commodities:',
        STARBOARD: 'Starboard',
        PORT: 'Port',
        CARGO_TANK: 'Cargo Tank',
        SLOP_TANK: 'Slop Tank',
        FORM: {
            CHOOSE_NUMBER_OF_WINGS: 'Choose number of wings',
            NUMBER_OF_WINGS: 'Number Of Wings',
            ADD_SLOP_WING: 'Add slop wing',
            AFT: 'AFT',
            FWD: 'FWD',
            TANK_STATE: 'Tank State',
            COMMODITY: 'Commodity',
            SIZE: 'Size',
            LOADED: 'Loaded',
            CHARTERER: 'Charterer',
            TEMPERATURE: 'Temperature',
            SG: 'SG',
            WING: 'Wing #{no}',
            SLOP_WING: 'Slop Wing',
            PORT: '#{no}P ',
            SLOP_PORT: 'SP',
            STARBOARD: '#{no}S',
            SLOP_STARBOARD: 'SS',
            EMPTY: 'Empty',
            SLOPS: 'Slops',
            CARGO: 'Cargo'
        },
        SUMMARY: {
            TOTAL_CARGO_QUANTITY: 'Total Cargo Quantity',
            TOTAL_WEIGHT: 'Total Weight',
            VESSEL_UTILIZATION: 'Vessel Utilization',
            CHARTERERS: 'Charterers',
            COATING: 'Coating'
        }
    },
    TERMINAL: {
        OVERVIEW: 'Overview',
        BERTHS: 'Berths',
        ADD_NEW_TERMINAL: 'Add New Terminal',
        TERMINAL_INFO: 'Terminal Info',
        TERMINAL_EDIT: 'Edit Terminal',
        NEW_TERMINAL: 'New terminal',
        TERMINAL_NAME: 'Terminal Name',
        FACILITY_TYPE: 'Facility Type',
        TERMINAL_OPERATOR: 'Terminal Operator',
        POSITION: 'Position',
        RESTRICTIONS: 'Restrictions',
        TERMINAL_SUCCESSFULLY_UPDATED: 'Terminal successfully updated',
        TERMINAL_SUCCESSFULLY_CREATED: 'Terminal successfully created'
    },
    BERTH: {
        OVERVIEW: 'Overview',
        ADD_NEW_BERTH: 'Add New Berth',
        BERTH_INFO: 'Berth Info',
        BERTH_EDIT: 'Edit Berth',
        NEW_BERTH: 'New berth',
        BERTH_NAME: 'Berth Name',
        RESTRICTIONS: 'Restrictions',
        BERTH_SUCCESSFULLY_UPDATED: 'Berth successfully updated',
        BERTH_SUCCESSFULLY_CREATED: 'Berth successfully created'
    },
    TAG_SELECT: {
        NO_TAGS: 'No tags',
        PLACEHOLDER: 'Select tags'
    },
    TEXT_CRUD: {
        REMOVE: 'Delete?'
    },
    TRADING_ROUTE_DETAILS: {
        LATEST: 'Latest',
        PREDICTED: 'Predicted',
        FOR: 'for',
        WORLD_SCALE: 'World scale',
        PROBABILITY: 'probability',
        WORLD_SCALE_DRIVERS: 'World scale drivers',
        ACCURACY: 'Accuracy',
        SELECT_MODEL: 'Select model',
        SELECT_RANGE: 'Select range',
        CATEGORY: 'Category',
        WORLD_SCALE_INDEX: 'World Scale Index',
        DIRECTION: 'Direction',
        OVERALL: 'Overall',
        TREND: {
            STRONG_UP: 'Up more than 10 pts',
            UP: 'Up 2.5 to 10 pts',
            NEUTRAL: 'Neutral',
            DOWN: 'Down 2.5 - 10 pts',
            STRONG_DOWN: 'Down more than 10 pts'
        }
    },
    TRADING_ROUTE_TOOLTIP: {
        LATEST_BITR: 'Latest BITR',
        PREDICTED_WS: 'Predicted WS',
        ROUTE_OVERVIEW: 'Route overview',
        NO_ROUTE_DATA_MESSAGE: 'There\'s no Market Intel data at this moment'
    },
    UNITS: {
        AREA: 'm²',
        AVG_BUDGET: 'mt/day',
        AVG_CONSUMPTION: 'mt/day',
        AVG_EMISSIONS: 'mt/day',
        AVG_EMISSIONS_KG: 'kg/day',
        AVG_LOADING_RATE: 'm³/h',
        BEAM: 'm',
        BEAUFORT: 'bf',
        BUDGET: 'mt',
        CARBON_INTENSITY: 'g/mt-nm',
        CARGO: 'mt',
        CONSUMPTION: 'mt',
        CONSUMPTION_MAP: 'g/KWh',
        COST: '$',
        COURSE: '°',
        CUBIC_CAPACITY: 'm³',
        CURRENT_SPEED: 'kn',
        DAY: 'd',
        DISPLACEMENT: 'mt',
        DISTANCE: 'NM',
        DISTANCE_SHORE_TANKS: 'm',
        DOUGLAS: 'dg',
        DRAFT: 'm',
        DWT: 'mt',
        ELECTRICAL_LOAD: 'kW',
        EMISSIONS: 'mt',
        EMISSIONS_KG: 'kg',
        EMISSIONS_KG_PROFIT: 'kg/$',
        ENERGY_EFFICIENCY: 'gCO₂/tnm',
        ENERGY_EFFICIENCY_MT_KNM: 'mt/kN-m',
        ENERGY_PRODUCED: 'kWh',
        FRESH_WATER: 'mt',
        FUEL: 'mt',
        GM: 'm',
        GROSS_TONNAGE: 'mt',
        HOUR: 'h',
        LINE_METER_CAPACITY: 'm',
        LOA: 'm',
        LOAD_DISCHARGE_RATE: 'mt/day',
        MANIFOLD: 'in',
        MAX_DISPLACEMENT: 'LTons',
        MAX_DRILLING_DEPTH: 'm',
        MAX_WATER_DEPTH: 'm',
        METER: 'm',
        MINUTE: 'm',
        PEAK_WAVE_PERIOD: 's',
        PERCENTAGE: '%',
        POWER_KW: 'kW',
        PRESSURE: 'bar',
        ROB: 'mt',
        SEA_HEIGHT: 'm',
        SECOND: 's',
        SPEED: 'kn',
        STEAM: 'd',
        SWELL_HEIGHT: 'm',
        TEMPERATURE: '°C',
        TORQUE: 'Nm',
        TPC: 't/cm',
        TRIM: 'm',
        UKC: 'm',
        VISIBILITY: 'NM',
        WATER_DENSITY: 'kg/m³',
        WAVE_HEIGHT: 'm',
        WAVE_LOADS: 'mt/kNm',
        WEATHER_ADVENT_DISTANCE: 'nm',
        WIND_FORCE: 'bf',
        WIND_SPEED: 'kn'
    },
    VESSEL_DETAILS_TOOLTIP: {
        SPEED: 'Speed:',
        SOG: 'SOG:',
        COURSE: 'Course:',
        COORDINATES: 'Lat/Lng:'
    },
    VESSEL_FILTER: {
        TITLE: 'Filters',
        FLEETS: 'Fleets',
        COMPANY_FLEETS: 'Company fleets',
        MANAGE_COMPANY_FLEETS: 'Manage company fleets',
        SEGMENTS: 'Segments',
        DISPLAY_VESSELS_ON_MAP: 'Display vessels on map',
        DRY_BULK_VESSELS: 'Dry Bulk Vessels',
        OTHER: 'Other',
        DISPLAY_BOTH: 'Display both',
        MANAGE_FLEETS: 'Manage fleets',
        RESET: 'Reset',
        SELECTED: 'Selected',
        TANKER_VESSELS: 'Tanker Vessels',
        MY_FLEETS: 'My fleets',
        SHARED_FLEETS: 'Shared fleets',
        VESSELS_ON_MAP: 'Vessels on map: #{vessels}'
    },
    VESSEL_INFO: {
        DEPARTURE: 'Departure',
        ARRIVAL: 'Arrival',
        VESSEL_NAME: 'Vessel name',
        VOYAGE: 'Voyage',
        RANGE: 'Range',
        INVALID_ID_HEADLINE: 'Invalid vessel IMO',
        INVALID_ID_TEXT: 'Couldn\'t find a vessel with IMO: ',
        VOYAGE_PLACEHOLDER: 'No active voyage',
        NO_VOYAGES: 'This vessel has no voyages',
        LAST_SIRE_DATE: 'Last Sire Date',
        LAST_CDI_DATE: 'Last CDI Date',
        LAST_HULL_CLEANING: 'Last Hull Cleaning',
        LAST_DRY_DOCK_DATE: 'Last Dry Dock Date',
        NEXT_DRY_DOCK_DATE: 'Next Dry Dock Date',
        REDELIVERY_DATE: 'Redelivery date',
        TOTAL_CUBIC_CAPACITY: 'Total Cubic Capacity',
        DWT: 'DWT',
        CAPACITY: 'Capacity',
        IMO_CLASS: 'IMO Class',
        MENU: {
            LOCATE_ON_MAP: 'Locate on map',
            ADD_TO_MY_FLEET: 'Add to my fleet',
            REMOVE_FROM_MY_FLEET: 'Remove from my fleet',
            CALCULATE_DISTANCE: 'Calculate distance',
            ESTIMATE_EMISSIONS: 'Estimate emissions',
            EDIT_VESSEL: 'Edit vessel',
            SHOW_CHARTERPARTY_INFORMATION: 'Show charter party info',
            EXPLORE_IN_SEDNA: 'Explore in SEDNA'
        },
        CARDS: {
            CII_RATING: 'CII Rating',
            CII_RATING_TOOLTIP: 'Voyage rating without corrections',
            EEOI: 'EEOI',
            ESTIMATED: 'Estimated',
            WITH_CORRECTIONS: 'With corrections ',
            NET_DAILY: 'Net Daily'
        },
        OVERVIEW: {
            ACTIVITY: 'Activity',
            BUDGET: 'Budget',
            BUNKER_BUDGET: 'Bunker budget',
            BUNKER_ROB: 'Bunker ROB',
            CARGO: 'Cargo',
            CHARTERPARTY_DATE: 'Charter party date',
            CHARTERED_BY: 'Chartered by',
            CHARTERER: 'Charterer',
            COMMODITY: 'Commodity',
            CONSUMPTION: 'Consumption',
            DESTINATION: 'Destination',
            DRAFT: 'Draft',
            ENERGY_MANAGEMENT: 'Energy management',
            ETA: 'ETA',
            EXTERNAL: 'External',
            INCLUDE_TC_VOYAGES: 'Include TC voyages',
            INSTRUCTED: 'Instructed',
            LADEN_BALAST_RATIO: 'Laden / Ballast ratio',
            LAST_UPDATED: 'Last Updated',
            LAST_UPDATED_BY: 'Last Updated By',
            NEXT_PORT: 'Next port',
            NO_NOTIFICATIONS: 'This vessel has no notifications.',
            NOTIFICATIONS: 'Notifications',
            POSITION: 'Position',
            QUANTITY: 'Quantity',
            REDELIVERY_DATE: 'Redelivery date',
            SIRE: 'SIRE',
            SPEED: 'Speed',
            STATUS: 'Status',
            TIME_ONBOARD: 'Time Onboard',
            TIME_RANGE: 'Time range',
            TITLE: 'Overview',
            VESSEL_PERFORMANCE: 'Performance',
            WIND: 'Wind',
            FOOTPRINT: {
                BASED_ON_VOYAGE: 'Based on voyage',
                BASED_ON_SELECTED_RANGE: 'Based on selected range',
                TITLE: 'Footprint',
                EEOI: 'EEOI',
                AER: 'AER',
                VOYAGE_TOTAL: 'Voyage Total',
                CH4: 'CH₄',
                CO: 'CO',
                CO2: 'CO₂',
                CO2_PER_DAY: 'CO₂ per day',
                CO2_PER_DOLLAR: 'CO₂ per $',
                N2O: 'N₂O',
                NMVOC: 'NMVOC',
                METHANE: 'Methane',
                CARBON_DIOXIDE: 'Carbon Dioxide',
                CARBON_MONOXIDE: 'Carbon Monoxide',
                NITROUS_OXIDE: 'Nitrous Oxide',
                NON_METHANE_VOLATILE_ORGANIC_COMPOUND: 'Non-methane Volatile Organic Compound'
            }
        },
        VESSEL: {
            TITLE: 'Vessel',
            CONTACT_DETAILS: 'Contact Details',
            COMMERCIAL_OPERATOR: 'Commercial Operator',
            TECHNICAL_SPECIFICATIONS: 'Technical Specifications',
            DISPONENT_OWNER: 'Disponent Owner',
            ADDRESS: 'Address',
            TEL: 'Tel.',
            EMAIL: 'Email',
            VESSEL: 'Vessel',
            DELIVERED: 'Delivered',
            BUILDER: 'Builder',
            FLAG: 'Flag',
            PORT_OF_REGISTRY: 'Port Of Registry',
            CALL_SIGN: 'Call Sign',
            IMO_NUMBER: 'IMO Number',
            MMSI: 'MMSI',
            SCRUBBER: 'Scrubber',
            SCRUBBER_ACTIVE_FROM: 'Scrubber active from',
            SHIP_TYPE: 'Ship Type',
            CLASSIFICATION: 'Classification',
            DIMENSIONS: 'Dimensions',
            LADEN: 'Laden',
            BALLAST: 'Ballast',
            SPEED: 'Speed',
            BUDGETED_CONSUMPTION: 'Budgeted consumption',
            DETAILS: 'Details',
            LIGHTSHIP: 'Lightship',
            MEASURE: 'Measure',
            NORMAL_BALLAST: 'NormalBallast',
            SUMMER: 'Summer',
            TECHNICAL_MANAGER: 'Technical Manager',
            LOA: 'LOA',
            BEAM: 'Beam',
            BALLAST_OPTIMUM_TRIM: 'Ballast Optimum Trim',
            LADEN_OPTIMUM_TRIM: 'Laden Optimum Trim',
            TOTAL_CUBIC_CAPACITY: 'Total Cubic Capacity',
            TOTAL_SLOP_TANK_CAPACITY: 'Total Slop Tank Capacity',
            COATING_TYPE: 'Coating Type',
            Q88_DETAILS: 'View vessel details on Q88',
            QUESTIONNAIRE: 'Questionnaire',
            TPC: 'TPC',
            DWT: 'DWT'
        },
        ITINERARY: {
            TITLE: 'Itinerary',
            REQUEST_DEVIATION: 'Request deviation',
            CALCULATE_DEVIATION: 'Calculate deviation'
        },
        CARGO: {
            BROKER: 'Broker',
            CAPACITY: 'Capacity',
            CHARTERER: 'Charterer',
            CHARTERPARTY_DATE: 'Charter party date',
            COB: 'COB',
            COMMODITY: 'Commodity',
            CRUDE_OIL: 'Crude Oil',
            DISCHARGE_POINT: 'Discharge point',
            DISCHARGE_PORT: 'Discharge port',
            FROM: 'from',
            LOAD_POINT: 'Load point',
            LOAD_PORT: 'Load port',
            NO_CARGO_INFORMATION: 'No cargo information',
            PALM_OIL: 'Palm Oil',
            QUANTITY: 'Quantity',
            TITLE: 'Cargo',
            TO: 'to',
            VEGETABLE_OIL: 'Vegetable Oil',
            VOYAGE: 'Voyage'
        },
        ENERGY: {
            ALL_REPORTS: 'All Reports',
            MISSING_REPORTS: 'Missing Reports',
            ARRIVAL_REPORTS: 'Arrival Reports',
            DEPARTURE_REPORTS: 'Departure Reports',
            DAILY_REPORTS: 'Daily Reports',
            CARGO_REPORTS: 'Cargo Reports',
            EVENT_REPORTS: 'Event Reports',
            NEXT_LEG_REPORTS: 'Next Leg Reports',
            SOF_REPORTS: 'SOF Reports',
            NO_REPORTS: 'No reports.',
            TITLE: 'Performance',
            LATEST: 'Latest',
            DAILY_REPORT: 'Daily Report',
            NEXT_LEG_REPORT: 'Next Leg Report',
            SPEED: 'Speed',
            CONSUMPTION: 'Consumption',
            CONSUMPTION_TITLE: 'Propulsion + AUX',
            BUDGET: 'Budget',
            REPORTER: 'Reporter',
            EXTERNAL: 'External',
            INSTRUCTED: 'Instructed',
            WIND: 'Wind',
            WIND_DIRECTION: 'Wind Direction',
            WIND_FORCE: 'Wind Force',
            SENSOR: 'Sensor',
            AVERAGE_VOYAGE_DATA: {
                TITLE: 'Average Voyage Data',
                AVERAGE_SPEED_TOTAL_VOYAGE: 'Average Speed Total Voyage:',
                TOTAL_CONSUMPTION_TOTAL_VOYAGE: 'Total Cons. Total Voyage:',
                TOTAL_CONSUMPTION: 'Total Cons.:',
                GOOD_WEATHER_SPEED_TOTAL_VOYAGE: 'Good Weather Speed - Total Voyage:',
                GOOD_WEATHER_CONSUMPTION_TOTAL_VOYAGE: 'Good Weather Cons. - Total Voyage:'
            },
            BUNKER_ROB: {
                TITLE: 'Bunker ROB'
            },
            REPORTS: {
                TITLE: 'Reports',
                DAILY_REPORT: 'Daily Report',
                NEXT_LEG_REPORT: 'Next Leg Report',
                DEPARTURE_REPORT: 'Departure report',
                ARRIVAL_REPORT: 'Arrival report',
                MISSING_REPORT: 'Report is missing',
                CARGO_REPORT: 'Cargo report',
                EVENT_REPORT: 'Event report',
                SOF_REPORT: 'SOF report',
                PORT: 'Port',
                EVENT_TYPE: 'Event Type',
                SEND_REMINDER: 'Send reminder to captain?',
                REMINDER_SENT: 'Reminder sent - ',
                LAST_REMINDER_SENT: 'Last reminder sent on #{timestamp}',
                REMINDER_SENT_BY: 'Sent by: #{user}',
                REMINDER_SENT_TO: 'Sent to: ',
                REMINDER_SEND_TITLE: 'Send reminder to captain',
                REMINDER_DISABLED: 'Reminder sent less than a day ago',
                REMINDER_DISABLED_INVALID_EMAIL: 'Operations or vessel email invalid',
                REMINDER_SUCCESSFULLY_SENT: 'Reminder successfully sent!'
            }
        }
    },
    VESSEL_LIST: {
        ALL_VESSELS: 'All Vessels',
        SHOW_ADVANCED_SEARCH: 'Show advanced search',
        HIDE_ADVANCED_SEARCH: 'Hide advanced search',
        STATUS: 'Status: ',
        ETA: 'Eta: ',
        DESTINATION: 'Destination: ',
        DWT: 'DWT',
        CAPACITY: 'Capacity',
        IMO_CLASS: 'IMO Class',
        SEARCH_VESSELS_TITLE: 'Search by Vessel Name or IMO',
        SEARCH_VESSELS_PLACEHOLDER: 'Search Vessels'
    },
    VESSEL_SELECT: {
        DWT: 'DWT',
        CAPACITY: 'Capacity',
        IMO_CLASS: 'IMO Class',
        PLACEHOLDER: 'Search Vessels'
    },
    VOYAGE_INFO: {
        SUBTITLE: 'Voyage #{voyageNumber} #{voyagePortInfo}',
        OVERVIEW: {
            TITLE: 'Overview',
            VOYAGE_INFORMATION: 'Voyage information',
            VOYAGE_START: 'Voyage Start',
            VOYAGE_END: 'Voyage End',
            LOI: 'Loi'
        },
        ITINERARY: {
            TITLE: 'Itinerary'
        },
        CARGO: {
            TITLE: 'Cargo'
        }
    },
    VOYAGE_PERFORMANCE: {
        TITLE: 'Voyage Performance',
        FOOTPRINT_REPORT: 'Footprint report',
        VESSEL: 'Vessel',
        VOYAGE: 'Voyage',
        SHOW_VOYAGE_ON_MAP: 'Show voyage on map',
        SHOW_VESSEL_ON_MAP: 'Show vessel on map',
        EXPLORE_IN_SEDNA: 'Explore in SEDNA',
        VOYAGE_NUMBER: 'Voyage number',
        EXPORT_PDF: 'Export PDF',
        EXPORT_FOOTPRINT_PDF: 'Export Footprint PDF',
        NOTE_TEXT: 'These values will be used in future Pool Point calculations. Should you have any comments on the same, please notify us within 10 business days of receipt of this report.',
        CARGO: {
            TITLE: 'Cargo',
            TOTAL_CARGO_QUANTITY: 'Total Cargo Quantity',
            TOTAL_WEIGHT: 'Total Weight',
            VESSEL_UTILIZATION: 'Vessel utilization',
            CHARTERERS: 'Charterers',
            COATING: 'Coating',
            DISCLAIMER: 'Based on values from all reports'
        },
        NET_DAILY: {
            TITLE: 'Net daily',
            ESTIMATED: 'Estimated'
        },
        REPORTS: {
            TITLE: 'Reports',
            MISSING: 'Missing',
            SUSPICIOUS: 'Suspicious',
            LIMIT_REACHED: '#{type} count not available',
            VERACITY_SYNC_ERROR: 'Sync Errors',
            VERACITY_WARNING: 'Veracity Warnings',
            BUNKER_ERROR: 'Bunker Errors',
            BUNKER_WARNING: 'Bunker Warnings',
            DAILY_REPORTS: 'Daily Reports',
            CARGO_REPORTS: 'Cargo Reports',
            EVENT_REPORTS: 'Event Reports',
            DEPARTURE_REPORTS: 'Departure Reports',
            ARRIVAL_REPORTS: 'Arrival Reports',
            SOF_REPORTS: 'SOF Reports',
            SHOW_REPORTS: 'Show reports'
        },
        TOTAL_VOYAGE: 'Total voyage',
        BALLAST: 'Ballast',
        LADEN: 'Laden',
        AVERAGE_SPEED_LABEL: 'Average speed (kn)',
        INSTRUCTED_SPEED: 'Instructed speed',
        GOOD_WEATHER_AVERAGE_SPEED_LABEL: 'Good weather average speed (kn)',
        GOOD_WEATHER_SPEED_LABEL: 'Good weather speed (kn)',
        TOTAL_CONSUMPTION_LABEL: 'Total consumption (mt)',
        GOOD_WEATHER_TOTAL_CONSUMPTION_LABEL: 'Good Weather Total consumption (mt)',
        TOTAL_CONSUMPTION: 'Total consumption',
        CONSUMPTION: 'Consumption',
        BUDGET: 'Budget',
        SPEED: 'Speed',
        INSTRUCTED: 'Instructed',
        START: 'Start',
        END: 'End',
        NO_ACTIVE_VOYAGE: 'No active voyage',
        PERFORMANCE_PANEL: {
            MODAL: {
                PARAMETERS: 'Parameters',
                WIND_FORCE: 'Wind Force',
                HOURS_STEAMED: 'Hours Steamed',
                AVERAGE_SPEED: 'Average Speed',
                INSTRUCTED_SPEED: 'Instructed Speed'
            },
            SET_PARAMETERS: 'Set parameters',
            SPEED: 'Speed',
            INSTRUCTED_SPEED: 'Instructed speed: ',
            CONSUMPTION: 'Consumption',
            TITLE: 'Performance',
            VALUE: 'Value',
            TOTAL: 'Total',
            AT_SEA: 'At Sea',
            IN_PORT: 'In Port',
            BALLAST: 'Ballast',
            LADEN: 'Laden',
            AVERAGE_CONSUMPTION_PER_DAY: 'Average Consumption per day',
            TOTAL_ME_CONSUMPTION: 'Total ME Consumption',
            AVERAGE_ME_CONSUMPTION_PER_DAY: 'Average ME Consumption per day',
            TOTAL_AUX_CONSUMPTION: 'Total Aux consumption',
            AVERAGE_AUX_CONSUMPTION_PER_DAY: 'Average Aux Consumption per day',
            TOTAL_HEATING_CONSUMPTION: 'Total Heating Consumption',
            AVERAGE_HEATING_CONSUMPTION_PER_DAY: 'Average Heating Consumption per day',
            BASED_ON_VALUES_FROM_ALL_REPORTS: 'Based on values from all reports',
            AVERAGE_LOAD_DISCHARGE: 'Average Load/Discharge Consumption per day',
            TOTAL_LOAD_DISCHARGE: 'Total Load/Discharge Consumption'
        },
        FOOTPRINT: {
            TITLE: 'Footprint',
            BASED_ON_VALUES_FROM_ALL_REPORTS: 'Based on values from all reports'
        },
        CHARTS: {
            AUX: 'Aux',
            AVERAGE_SPEED: 'Average Speed',
            BOUNDARY: 'Boundary',
            BUDGET: 'Budget',
            CARGO_HEATING: 'Cargo Heating',
            CH4: 'CH₄',
            CO: 'CO',
            CO2: 'CO₂',
            CONSUMPTION_MT: 'Consumption (mt)',
            DAILY_AVEGARE_SPEED_KN: 'Daily average speed (kn)',
            EMISSIONS_MT: 'Emissions (mt)',
            EXTERNAL_WIND_SPEED: 'External Wind Speed',
            IG: 'IG',
            INSTRUCTED_SPEED: 'Instructed Speed',
            N2O: 'N₂O',
            NMVOC: 'NMVOC',
            PROPULTION_PLUS_AUX: 'Propulsion + Aux',
            REPORTED_PROPULTION: 'Reported Propulsion',
            REPORTED_WIND_SPEED: 'Reported Wind Speed',
            SENSOR_AVERAGE_SPEED: 'Sensor Average Speed',
            SENSOR_PROPULTION: 'Sensor Propultion',
            SENSOR_WIND_SPEED: 'Sensor Wind Speed',
            TANK_CLEANING: 'Tank Cleaning',
            WEATHER_BF: 'Weather (bf)'
        },
        HSFO: 'HSFO',
        VLSFO: 'VLSFO',
        LSMGO: 'LSMGO',
        BIOFO: 'BIOFO',
        TIMELINE_TITLE: 'Voyage reports timeline',
        RECALCULATE: 'Recalculate',
        REPORTS_UPDATED_TEXT: 'Calculation needs to be updated. Please recalculate reports in order to see updated data.'
    },
    VOYAGE_TIMELINE: {
        MISSING: 'Missing Report',
        UPDATED: 'Updated Report',
        DELETED: 'Deleted Report',
        DAILY_REPORT: 'Daily Report',
        DEPARTURE_REPORT: 'Departure Report',
        ARRIVAL_REPORT: 'Arrival Report',
        CARGO_REPORT: 'Cargo Report',
        EVENT_REPORT: 'Event Report',
        NEXT_LEG_REPORT: 'Next Leg Report',
        SOF_REPORT: 'SOF Report'
    },
    WEATHER_DATE_PICKER: {
        WEATHER_DATE: 'Weather Date',
        RESET: 'Reset'
    },
    WEATHER_ROUTING: {
        LOCATE_VESSEL_ON_MAP: 'Locate vessel on map',
        LOCATE_VOYAGE_ON_MAP: 'Locate voyage on map',
        NEW_ROUTING_REQUEST: 'New routing request',
        ORIGINAL_REQUEST: 'Original request',
        REQUEST_BUTTON_TITLE: 'Create a new routing request',
        REQUEST_ROUTING: 'Request routing',
        ROUTING_STATUS: 'Routing status',
        TITLE: 'Weather Routing',
        NO_PARAMS: 'Please select vessel and voyage in order to view routing requests or create a new one',
        SHOW_ORIGINAL_REQUEST: 'Show original request',
        DUPLICATE_REQUEST: 'Duplicate request',
        SHOW_THE_MAP: 'Show the map',
        HIDE_THE_MAP: 'Hide the map',
        MANAGE_REQUESTS: 'Manage requests',
        TOASTS: {
            REQUEST_INTENDED: 'Request successfully sent to captain!',
            REQUEST_DELETED: 'Request successfully deleted!',
            REQUEST_CREATED: 'New routing request successfully created!',
            REQUEST_STOPPED: 'Request stopped',
            LEG_DOESNT_EXIST: 'This leg doesn\'t exist',
            LEG_DELETED: 'Leg successfully deleted'
        },
        FILTERS: {
            COMPANY_FLEET: 'Company fleet',
            COMPANY_FLEET_PLACEHOLDER: 'Select company fleets',
            CUSTOM_FLEET: 'Fleet',
            CUSTOM_FLEET_PLACEHOLDER: 'Select fleets',
            VESSEL: 'Vessel',
            VOYAGE: 'Voyage',
            VOYAGES_AVAILABLE_FOR_ROUTING: 'Voyages available for routing'
        },
        OVERVIEW: {
            ACTIVE_ROUTING: 'Active routing',
            ADVISED: 'Advised:',
            AVAILABLE_FOR_ROUTING: 'Available for routing',
            COUNTER_PVAR: 'Counter PVAR',
            DELETE_LEG: 'Delete leg',
            DELETE_LEG_DISABLED_TITLE: 'Only legs without Master Intended Routes and without intended requests can be deleted',
            END: 'End',
            ETA: 'ETA / End',
            ETD: 'ETD / Start',
            FROM: 'From:',
            ITINERARY: 'Itinerary',
            LEG: 'Leg',
            LEG_DELETE_CONTENT: 'Are you sure that you wish to delete this leg?',
            LEG_DELETE_TITLE: 'Delete leg?',
            MANAGE_REQUESTS: 'Manage requests',
            MIR: 'MIR',
            MIR_TITLE: 'Master Intended Route available',
            OPTIMIZATION: 'Optimization',
            OPTIONS: 'Options',
            REQUEST_OFFICIAL_PVAR: 'Request official PVAR',
            REQUEST_ROUTING: 'Request routing',
            REQUESTED: 'Requested:',
            ROUTING_STATUS: 'Routing status',
            SHOW_LATEST_ROUTE: 'Show latest route',
            SHOW_REQUESTS: 'Show requests',
            SHOW_VOYAGE_ON_MAP: 'Show voyage on map',
            SOURCE: 'Source:',
            SPEED: 'Speed',
            START: 'Start',
            TO: 'To:',
            VESSEL: 'Vessel',
            VESSEL_STATUS: 'Vessel status',
            VOYAGE: 'Voyage',
            VOYAGE_PERFORMANCE: 'Voyage performance',
            VOYAGE_REQUESTS: 'Voyage requests',
            WEATHER_PERFORMANCE: 'Weather performance'
        },
        LEG: {
            ACTIVE_REQUEST: 'Active request',
            AIS: 'AIS',
            AIS_TITLE: 'Toggle showing AIS route on map',
            COMMENT: 'Comment',
            COST: 'Cost',
            DATE_OF_ADVICE: 'Date of advice',
            DELETE: 'Delete',
            DELETE_DISABLED_TITLE: 'Only non-intended requests can be deleted',
            DELETE_CONFIRM_CONTENT: 'Are you sure that you wish to delete this request?',
            DELETE_CONFIRM_TITLE: 'Delete request?',
            DISTANCE: 'Distance',
            ECA: 'ECA',
            ERROR: 'Something went wrong with this routing request.',
            ETA: 'ETA',
            ETD: 'ETD',
            EXPLORE_NEW_OPTIONS: 'Explore new options',
            FROM: 'From',
            HISTORICAL_REQUESTS: 'Historical requests',
            MAX_SELECTED_ROUTES: 'Up to 5 routes can be shown on map',
            NO_INFORMATION: 'No information available about this leg',
            NO_REQUESTS: 'No requests were created for this leg',
            NO_ROUTES_WAIT: 'Routing request for selected voyage has been sent. Please wait a few minutes for the updates to arrive.',
            NO_ROUTES_REFRESH: 'Routing request for selected voyage has been sent. Please refresh the page in a few minutes to check for the updates.',
            NON_ECA: 'Non-ECA',
            OPTIMIZATION: 'Optimization',
            OPTIONS: 'Options',
            REFRESH_DISABLED_TITLE: 'Only non-intended automatic requests can be refreshed',
            REFRESH_REQUEST: 'Get latest advice',
            REMARKS: 'Remarks',
            REPORT_DATE: 'Report Date',
            ROUTING_STATUS: 'Routing Status',
            SEND_TO_CAPTAIN: 'Send to captain',
            SEND_TO_CAPTAIN_CONFIRM_CONTENT: 'Are you sure that you wish to send this route to captain and continue routing using these params?',
            SEND_TO_CAPTAIN_CONFIRM_TITLE: 'Send route to captain?',
            SOURCE: 'Source',
            SPEED: 'Speed',
            STOP_DISABLED_TITLE: 'Only uncompleted intended automatic requests can be stopped',
            STOP_REQUEST: 'Stop request',
            STOP_REQUEST_MODAL_TITLE: 'Stop request?',
            STOP_REQUEST_MODAL_CONTENT: 'Are you sure that you wish to stop this request?',
            MASTER_INTENDED_ROUTES: 'Master Intended Routes (SPOS)',
            SYNOPSIS: 'Synopsis',
            TITLE: 'Manage leg requests',
            TO: 'To',
            TOTAL_CONSUMPTION: 'Total consumption'
        },
        TOOLTIP: {
            ADVISED_SPEED: 'Advised Speed',
            SPEED_THROUGH_WATER: 'Speed through water',
            SPEED_OVER_GROUND: 'Speed over ground'
        },
        REQUEST: {
            ABOUT: 'About',
            ADD_VALUES: 'Add Values',
            ADDITIONAL_INFO: 'Additional info',
            BEAM_WAVES_HEIGHT: 'Beam Waves Height',
            BEAM_WAVES_PERIOD: 'Beam waves period',
            BEAM_WIND_SPEED: 'Beam Wind Speed',
            BUDGETED_CONSUMPTION: 'Budget Consumption',
            BUNKER_COST_HFO: 'High Sulphur fuel price (per mt)',
            BUNKER_COST_HFOLS: 'Low Sulphur fuel price (per mt)',
            CONSUMPTION_SPEED: 'Speed (kn)',
            DATE_EFFECTIVE: 'Date effective',
            DRAFT: 'Max Draft',
            ETA_DATE: 'ETA',
            ETD_DATE: 'ETD',
            EXCLUDE_ADVERSE_CURRENTS: 'Exclude adverse currents',
            EXCLUDE_ALL_CURRENTS: 'Exclude all currents',
            FOLLOWING_WAVES_HEIGHT: 'Following Waves Height',
            FOLLOWING_WAVES_PERIOD: 'Following waves period',
            FOLLOWING_WIND_SPEED: 'Following Wind Speed',
            FROM: 'From',
            FUEL_CONSUMPTION: 'Fuel consumption (mt/day)',
            HEAD_WAVES_HEIGHT: 'Head Waves Height',
            HEAD_WAVES_PERIOD: 'Head waves period',
            HEAD_WIND_SPEED: 'Head Wind Speed',
            HURRICANE_DISTANCE: 'Hurricane distance',
            ICE_CONCENTRATION: 'Ice concentration',
            ICING_RISK: 'Icing Risk',
            INSTRUCTED_SPEED: 'Instructed speed',
            ITINERARY: 'Itinerary',
            NEXT_LEG_REPORT: 'Next leg report',
            NEXT_LEG_REPORT_PLACEHOLDER: 'Select a report to populate request',
            NONE: 'None',
            OPTIMIZATION: 'Optimization',
            OPTIMIZATION_TYPE: 'Optimization type',
            PERFORMANCE: 'Performance',
            PERFORMANCE_EMAILS: 'Performance emails',
            PERFORMANCE_REQUEST: 'Performance request',
            PERFORMANCE_WAVE_HEIGHT: 'Wave height',
            PERFORMANCE_WIND_SPEED: 'Wind speed',
            POINT: 'Point',
            PORT: 'Port',
            PORT_OF_DEPARTURE: 'Port of departure',
            PORT_OF_DESTINATION: 'Port of destination',
            PREPOPULATE_DATA: 'Prepopulate data',
            PREPOPULATE_DATA_MISSING: 'No data available',
            PVAR_EMAILS: 'Pvar emails',
            REMARKS: 'Comment / Charter party information',
            RESTRICTED_AREAS: 'Restricted Areas',
            RESTRICTIONS: 'Restrictions',
            RISK_WAVE_HEIGHT: 'Risk Wave Height',
            RISK_WIND_SPEED: 'Risk Wind Speed',
            ROUTE_CALCULATION_TYPE: 'Calculation type',
            ROUTE_SOURCE: 'Source',
            ROUTING_EMAILS: 'Routing emails',
            ROUTING_EMAILS_OPERATIONS: 'Operation emails',
            ROUTING_EMAILS_OTHER: 'Additional emails',
            ROUTING_EMAILS_VESSEL: 'Vessel emails',
            SELECT_AN_ITEM_TO_POPULATE_REQUEST: 'Select an item to populate request',
            SUCCESSFULLY_CREATED: 'New routing request successfully created!',
            TEMPLATE: 'Prepopulate data',
            TEMPLATE_PLACEHOLDER: 'Select a report or itinerary to populate request',
            TO: 'To',
            TROPICAL_DEPRESSION_DISTANCE: 'Tropical depression distance',
            TROPICAL_STORM_DISTANCE: 'Tropical storm distance',
            VESSEL: 'Vessel',
            VESSEL_CONDITION: 'Vessel condition',
            VESSEL_COSTS: 'Vessel costs (daily)',
            VISIBILITY: 'Visibility',
            VOYAGE_NUMBER: 'Voyage number',
            WAVE_HEIGHT: 'Wave Height',
            WAYPOINT: 'Waypoint',
            WEATHER_LIMITS: 'Weather limits',
            WIND_GUSTS_10M: 'Wind Gusts 10m',
            WIND_GUSTS_50M: 'Wind Gusts 50m',
            WIND_SPEED_10M: 'Wind Speed 10m',
            WIND_SPEED_50M: 'Wind Speed 50m'
        }
    }
};
