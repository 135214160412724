import { ActionTypes } from './right-side-bar-actions';

const initialState = {
    activePanels: [],
    isCollapsed: true
};

export default function rightSideBarReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.RIGHT_SIDE_BAR_EMPTY_ACTIVE_PANELS: {
        return {
            ...state,
            activePanels: [],
            isCollapsed: true
        };
    }
    case ActionTypes.RIGHT_SIDE_BAR_TOGGLE_ACTIVE_PANEL: {
        const activePanels = state.activePanels.slice();
        const foundIndex = activePanels.indexOf(action.panelName);
        if (foundIndex > -1) {
            activePanels.splice(foundIndex, 1);
        }
        if ((typeof action.enable === 'boolean' && action.enable)
            || (typeof action.enable !== 'boolean' && foundIndex < 0)) {
            activePanels.push(action.panelName);
        }
        return {
            ...state,
            activePanels,
            isCollapsed: activePanels.length === 0
        };
    }
    default:
        return state;
    }
}
