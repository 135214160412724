export const ActionTypes = {
    GSM_RIGHT_SIDE_BAR_SECONDARY_COLLAPSE: 'GSM_RIGHT_SIDE_BAR_SECONDARY_COLLAPSE',
    GSM_RIGHT_SIDE_BAR_SECONDARY_SET_ACTIVE_PANEL: 'GSM_RIGHT_SIDE_BAR_SECONDARY_SET_ACTIVE_PANEL'
};

export function gsmMapRightSideBarCollapse(dispatch, expand) {
    dispatch({
        type: ActionTypes.GSM_RIGHT_SIDE_BAR_SECONDARY_COLLAPSE,
        expand
    });
}

export function gsmSetActivePanel(dispatch, panelName, expand) {
    dispatch({
        type: ActionTypes.GSM_RIGHT_SIDE_BAR_SECONDARY_SET_ACTIVE_PANEL,
        panelName,
        expand
    });
}
