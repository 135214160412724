const options = {
    commodities: ['Palm oil products', 'CPP', 'No heat crude'],
    charterers: ['Avin Chartering S.A.', 'Cheniere Marketing Internation', 'Standard Tankers LLC']
};

export const slopWingPositions = {
    FWD: 'fwd',
    AFT: 'aft'
};

const generatePreviousCommodities = (num) => {
    const res = [];
    for (let i = 0; i < num; i++) {
        res.push({
            Commodity: options.commodities[Math.round(Math.random() * 2)],
            LoadPercentage: Math.round(Math.random() * 100)
        });
    }
    return res;
};

const generateTank = () => {
    const Size = Math.round(Math.random() * 20000);
    const Loaded = Math.round(Math.random() * Size);
    return {
        Commodity: options.commodities[Math.round(Math.random() * 2)],
        Size,
        Loaded,
        LoadPercentage: Math.round((Loaded / Size) * 100),
        Charterer: options.charterers[Math.round(Math.random() * 2)],
        Temperature: Math.round(Math.random() * 100),
        Sg: Math.round(Math.random() * 2),
        PreviousCommodities: generatePreviousCommodities(Math.round(Math.random() * 5))
    };
};

export const generateWings = (num) => {
    const wings = [];
    for (let i = 0; i < num; i++) {
        wings.push({
            Index: i,
            Port: generateTank(),
            Starboard: generateTank()
        });
    }
    return wings;
};
