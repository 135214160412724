import React from 'react';
import PropTypes from 'prop-types';
import ScrollArea from 'components/scroll-area/scroll-area';
import { connect } from 'react-redux';
import moment from 'moment';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import { formatNumber } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import {
    getTradingRouteModels,
    getTradingRouteRanges,
    getTradingRouteDetailsData,
    tradingRouteDetailsResetAll
} from './trading-route-details-actions';
import { updateUserSettings } from 'pages/user-pages/user-actions';
/* selectors */
import { getRouteData, getSelectedModel, getSelectedRange } from './trading-route-details-selector';
/* components */
import TradingRouteDetailsBox from './trading-route-details-box/trading-route-details-box';
import Bar from 'components/bar/bar';
import ProgressCircle from 'components/progress-circle/progress-circle';
import Select from 'components/select/select';
import EmptyContent from 'components/empty-content/empty-content';
import { createSynchronizedCharts } from 'components/chart/chart';
/* config */
import { getWorldScaleChartConfig, getDirectionChartConfig } from './trading-route-details-chart-config';
/* styles */
import './trading-route-details.scss';

const t = (key) => translate(`TRADING_ROUTE_DETAILS.${key}`);
const trends = [
    { value: 15, label: 'StrongUp', description: 'Strong up', probability: t('TREND.STRONG_UP') },
    { value: 5, label: 'Up', description: 'Up', probability: t('TREND.UP') },
    { value: 0, label: 'Neutral', description: 'Neutral', probability: t('TREND.NEUTRAL') },
    { value: -5, label: 'Down', description: 'Down', probability: t('TREND.DOWN') },
    { value: -15, label: 'StrongDown', description: 'Strong down', probability: t('TREND.STRONG_DOWN') }
];
const initialState = {
    min: null,
    max: null
};
const { SharedStateProvider, SynchronizedChart } = createSynchronizedCharts();

class TradingRouteDetails extends React.PureComponent {
    constructor(props) {
        super(props);

        this.chartOptions = {
            xAxis: {
                min: moment().subtract(14, 'd').valueOf(),
                max: moment().add(14, 'd').valueOf()
            }
        };
    }

    componentDidMount() {
        this.props.getTradingRouteModels();
        this.props.getTradingRouteRanges();
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedModel && this.props.selectedRange
            && (this.props.selectedModel !== prevProps.selectedModel
                || this.props.selectedRange !== prevProps.selectedRange)) {
            this.props.getTradingRouteDetailsData(
                this.props.routeCode,
                this.props.selectedModel.ModelName,
                this.props.selectedRange
            );
        }
    }

    componentWillUnmount() {
        this.props.tradingRouteDetailsResetAll();
    }

    changeModel = (newModel) => {
        this.props.updateUserSettings({
            MarketIntelModelNameId: newModel.Id
        });
    };

    changeRange = (newRange) => {
        this.props.updateUserSettings({
            MarketIntelRangeId: newRange.Id
        });
    };

    getLatestPanel = () => {
        if (!this.props.latestPrediction) {
            return null;
        }
        return (
            <div className="sten-panel flex-grow">
                <div className="sten-panel__header">
                    <h4>
                        <span className="text-uppercase">{t('LATEST')}</span>
                        <span className="text-secondary">
                            {` ${t('FOR')} ${TimeHelper.getFormatted(this.props.latestPrediction.BITRDate)}`}
                        </span>
                    </h4>
                </div>
                <div className="sten-panel__body flex flex-grow">
                    <div
                        className="flex flex-grow flex-center sten-panel__section sten-trading-route-details__section"
                    >
                        <TradingRouteDetailsBox
                            className="flex-grow"
                            title={t('WORLD_SCALE')}
                            value={formatNumber(this.props.latestPrediction.BITR, 2)}
                            size="lg"
                            trendIconValue={this.props.latestPrediction.BITRTrend}
                            trendIconDescription="Latest trend"
                        />
                    </div>
                </div>
            </div>
        );
    };

    getPredictedPanel = () => {
        if (!this.props.latestPrediction) {
            return null;
        }
        const sectionClass = 'flex flex-grow sten-panel__section sten-trading-route-details__section';
        const probabilityLeft = [];
        const probabilityRight = [];
        const probabilities = trends.reduce((res, trend) => {
            if (this.props.latestPrediction[`${trend.label}Prob`] !== null) {
                res.push({
                    value: this.props.latestPrediction[`${trend.label}Prob`],
                    trendIconValue: trend.value,
                    trendIconDescription: trend.probability
                });
            }
            return res;
        }, []);
        probabilities.sort((a, b) => {
            if (a.value > b.value) {
                return -1;
            } if (a.value < b.value) {
                return 1;
            }
            return 0;
        });
        probabilities.forEach((probability, index) => {
            if (index < 2) {
                probabilityLeft.push(
                    <TradingRouteDetailsBox
                        key={`probability${probability.trendIconValue}`}
                        isHorizontal
                        isLight
                        className="flex-grow"
                        title={t('PROBABILITY')}
                        value={`${formatNumber(probability.value * 100, 0)}%`}
                        size="md"
                        trendIconValue={probability.trendIconValue}
                        trendIconDescription={probability.trendIconDescription}
                    />
                );
            } else {
                probabilityRight.push(
                    <TradingRouteDetailsBox
                        key={`probability${probability.trendIconValue}`}
                        isHorizontal
                        isLight
                        className="flex-grow"
                        value={`${formatNumber(probability.value * 100, 0)}%`
                        + ` ${t('PROBABILITY')}`}
                        size="sm"
                        trendIconValue={probability.trendIconValue}
                        trendIconDescription={probability.trendIconDescription}
                    />
                );
            }
        });

        const explanations = this.props.latestPrediction.PredictionExplanations;

        const barMaxValue = explanations.reduce((max, explanation) => (
            (max > Math.abs(explanation.Value)) ? max : Math.abs(explanation.Value)
        ), 10);

        return (
            <div className="sten-panel flex-grow">
                <div className="sten-panel__header">
                    <h4>
                        <span className="text-uppercase">{t('PREDICTED')}</span>
                        <span className="text-secondary">
                            {` ${t('FOR')}`
                                + ` ${TimeHelper.getFormatted(this.props.latestPrediction.ForDate)}`}
                        </span>
                    </h4>
                </div>
                <div className="sten-panel__body flex-row--md">
                    <div className="col-lg-6 col-md-4 flex">
                        <div className={`${sectionClass} flex-center`}>
                            <TradingRouteDetailsBox
                                className="flex-grow"
                                title={t('WORLD_SCALE')}
                                value={formatNumber(this.props.latestPrediction.PredictedWs, 2)}
                                size="lg"
                                trendIconValue={this.props.latestPrediction.WSTrend}
                                trendIconDescription="Prediction trend"
                            />
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-10 flex">
                        <div className={`${sectionClass} sten-trading-route-details__section--separated`}>
                            <div className="flex-grow flex-row">
                                <div className="col-12 flex-grow flex-col">
                                    {probabilityLeft}
                                </div>
                                <div className="col-12 flex-grow flex-col">
                                    {probabilityRight}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-9 col-md-10 flex">
                        <div className={`${sectionClass} flex-col sten-trading-route-details__section--separated`}>
                            <div className="sten-panel__row flex flex-grow flex-center">
                                <h3 className="text-uppercase">
                                    {t('WORLD_SCALE_DRIVERS')}
                                </h3>
                            </div>
                            <div className="sten-panel__row flex flex-center flex-grow">
                                {explanations && explanations.length > 0 && (
                                    <div className="flex-grow flex-row">
                                        <div className="col-12 flex-grow flex-col">
                                            {explanations.map((explanation, index) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <div className="flex-grow flex-row flex-center" key={index}>
                                                    <label className="flex-grow flex flex-center sten-panel__label">
                                                        {explanation.Description}
                                                    </label>
                                                    <label
                                                        className="flex-shrink flex flex-center text-right
                                                            sten-panel__label--value"
                                                    >
                                                        {` ${formatNumber(explanation.Value, 2)}`}
                                                    </label>
                                                </div>
                                            ))}
                                        </div>
                                        <div className="col-12 flex-grow flex-col">
                                            {explanations.map((explanation, index) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <Bar value={explanation.Value} maxValue={barMaxValue} key={index} />
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    getRangeOption = (option) => `Last ${option.DisplayName}`;

    getAccuracyPanel = () => {
        if (!this.props.tradingRouteData || !this.props.tradingRouteData.IntelAccuracy) {
            return null;
        }
        const accuracy = this.props.tradingRouteData.IntelAccuracy;
        const accuracyPercentages = trends.reduce((res, trend) => {
            if (accuracy) {
                res.push({
                    trendValue: trend.value,
                    trendIconDescription: `${trend.description} category accuracy`,
                    value: accuracy[`${trend.label}Percentage`] !== null
                        ? `${formatNumber(accuracy[`${trend.label}Percentage`], 0)}%`
                        : '-'
                });
            }
            return res;
        }, []);
        const sectionClass = 'flex flex-grow sten-panel__section sten-trading-route-details__section';
        const containerClass = `${sectionClass} flex-col sten-trading-route-details__section--separated`;
        return (
            <div className="sten-panel">
                <div className="sten-panel__header">
                    <h4 className="text-uppercase flex-grow">{t('ACCURACY')}</h4>
                    <div className="col-md-3 col-10">
                        <Select
                            isRightAligned
                            placeholder={t('SELECT_RANGE')}
                            options={this.props.rangeOptions}
                            optionRenderer={this.getRangeOption}
                            value={this.props.selectedRange}
                            valueKey="Id"
                            labelKey="DisplayName"
                            onChange={this.changeRange}
                        />
                    </div>
                </div>
                <div className="sten-panel__body flex-row--md">
                    <div className="col-md-8 flex">
                        <div className="col-15 flex-grow flex">
                            <div
                                className={`${sectionClass} flex-center sten-panel__separator`
                                    + ' sten-panel__separator--right'}
                            >
                                {accuracy && accuracy.OverAllPercentage !== null && (
                                    <ProgressCircle
                                        className="sten-trading-route-details__progress-circle"
                                        innerClassName="flex flex-center"
                                        value={accuracy.OverAllPercentage}
                                    >
                                        <TradingRouteDetailsBox
                                            hideTrend
                                            className="flex-grow"
                                            title={t('OVERALL')}
                                            value={`${formatNumber(accuracy.OverAllPercentage, 0)}%`}
                                            size="lg"
                                        />
                                    </ProgressCircle>
                                )}
                            </div>
                        </div>
                        <div className="col-9 flex-grow flex">
                            <div className={`${sectionClass} flex-col`}>
                                <div className="sten-panel__row flex-shrink">
                                    <h3 className="text-uppercase">{t('CATEGORY')}</h3>
                                </div>
                                <div className="sten-panel__row flex-grow flex-col">
                                    {accuracyPercentages.length > 0 && accuracyPercentages.map(el => (
                                        <TradingRouteDetailsBox
                                            key={`percentage${el.trendValue}`}
                                            isHorizontal
                                            isLight
                                            className="flex-grow"
                                            value={el.value}
                                            size="sm"
                                            trendIconValue={el.trendValue}
                                            trendIconDescription={el.trendIconDescription}
                                        />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <SharedStateProvider initialState={initialState}>
                        <div className="col-md-8 flex-grow">
                            <div className={containerClass}>
                                <div className="sten-panel__row flex-shrink">
                                    <h3 className="text-uppercase">{t('WORLD_SCALE_INDEX')}</h3>
                                </div>
                                <div className="sten-panel__row flex-grow">
                                    <SynchronizedChart
                                        className="sten-trading-route-details__chart"
                                        highchartsMore
                                        series={this.props.tradingRouteData.worldScaleSeries}
                                        categories={this.props.tradingRouteData.categories}
                                        config={getWorldScaleChartConfig()}
                                        options={this.chartOptions}
                                        manageResetZoom
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 flex-grow">
                            <div className={containerClass}>
                                <div className="sten-panel__row flex-shrink">
                                    <h3 className="text-uppercase">{t('DIRECTION')}</h3>
                                </div>
                                <div className="sten-panel__row flex-shrink">
                                    <SynchronizedChart
                                        className="sten-trading-route-details__chart"
                                        highchartsMore
                                        series={this.props.tradingRouteData.directionSeries}
                                        categories={this.props.tradingRouteData.categories}
                                        config={getDirectionChartConfig()}
                                        options={this.chartOptions}
                                        manageResetZoom
                                    />
                                </div>
                            </div>
                        </div>
                    </SharedStateProvider>
                </div>
            </div>
        );
    };

    goToMarketIntel = () => this.props.router.navigate(appRoutes.MarketOverview.MarketIntel);

    render() {
        return (
            <div className="sten-trading-route-details sten-content">
                <div className="sten-content__header flex-row">
                    <div className="flex-grow flex flex-center">
                        <h1 className="sten-content__title sten-trading-route-details__title text-uppercase">
                            <span>{this.props.routeCode}</span>
                            {this.props.routeName}
                        </h1>
                    </div>
                    <div className="col-md-5 col-12 flex flex-center">
                        <Select
                            isRightAligned
                            placeholder={t('SELECT_MODEL')}
                            options={this.props.modelOptions}
                            value={this.props.selectedModel}
                            valueKey="Id"
                            labelKey="ModelName"
                            onChange={this.changeModel}
                        />
                    </div>
                    <div className="flex-shrink">
                        <button
                            className="btn-link icon icon-close"
                            onClick={this.goToMarketIntel}
                        />
                    </div>
                </div>
                {(!this.props.latestPrediction || !this.props.tradingRouteData.IntelAccuracy) ? <EmptyContent />
                    : (
                        <ScrollArea className="sten-content__body">
                            <div className="sten-content__section">
                                <div className="flex-row flex-row--md">
                                    <div className="col-md-5 flex">
                                        {this.getLatestPanel()}
                                    </div>
                                    <div className="col-md-19 col-pad--sm flex">
                                        {this.getPredictedPanel()}
                                    </div>
                                </div>
                            </div>
                            <div className="sten-content__section">
                                {this.getAccuracyPanel()}
                            </div>
                        </ScrollArea>
                    )
                }
            </div>
        );
    }
}

TradingRouteDetails.propTypes = {
    getTradingRouteDetailsData: PropTypes.func.isRequired,
    getTradingRouteModels: PropTypes.func.isRequired,
    getTradingRouteRanges: PropTypes.func.isRequired,
    latestPrediction: PropTypes.objectOf(PropTypes.any),
    modelOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    rangeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    routeCode: PropTypes.string.isRequired,
    routeName: PropTypes.string,
    router: TRouter.isRequired,
    selectedModel: PropTypes.objectOf(PropTypes.any),
    selectedRange: PropTypes.objectOf(PropTypes.any),
    tradingRouteData: PropTypes.objectOf(PropTypes.any).isRequired,
    tradingRouteDetailsResetAll: PropTypes.func.isRequired,
    updateUserSettings: PropTypes.func.isRequired
};

TradingRouteDetails.defaultProps = {
    latestPrediction: null,
    routeName: '',
    selectedModel: null,
    selectedRange: null
};

function mapStateToProps(state) {
    return {
        latestPrediction: state.tradingRouteDetailsReducer.latestPrediction,
        modelOptions: state.tradingRouteDetailsReducer.modelOptions,
        rangeOptions: state.tradingRouteDetailsReducer.rangeOptions,
        routeCode: state.marketIntelReducer.selectedRouteCode,
        routeName: state.tradingRouteDetailsReducer.routeName,
        selectedModel: getSelectedModel(state),
        selectedRange: getSelectedRange(state),
        tradingRouteData: getRouteData(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getTradingRouteDetailsData: (routeCode, model, range) =>
            getTradingRouteDetailsData(dispatch, routeCode, model, range),
        getTradingRouteModels: () => getTradingRouteModels(dispatch),
        getTradingRouteRanges: () => getTradingRouteRanges(dispatch),
        tradingRouteDetailsResetAll: () => tradingRouteDetailsResetAll(dispatch),
        updateUserSettings: settings => updateUserSettings(dispatch, settings)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TradingRouteDetails));
