import { takeEvery, all, put, select } from 'redux-saga/effects';
/* actions */
import { ActionTypes } from './port-info-calls-actions';
/* services */
import PortService from 'services/core-api/port-service';
import VesselService from 'services/core-api/vessel-service';

function* getPortCalls(action) {
    const {
        selectedPortActivities,
        selectedSegments,
        offset,
        limit
    } = yield select(state => state.portInfoCallsReducer);
    const params = {
        PortId: action.portId,
        Limit: limit,
        PortActivities: [],
        VesselSegments: []
    };
    if (selectedPortActivities) {
        selectedPortActivities.forEach((portActivity) => {
            params.PortActivities.push(portActivity.PortActivityId);
        });
    }
    if (selectedSegments) {
        selectedSegments.forEach((segment) => {
            params.VesselSegments.push(segment.VesselTypeId);
        });
    }
    if (action.overrideCurrent) {
        params.Offset = offset;
    } else {
        params.Offset = offset + limit;
    }
    yield put({ type: ActionTypes.PORT_INFO_PORT_CALLS_SET_GETTING_DATA, isGettingData: true });
    const portCallsResponse = yield PortService.getCalls(params);
    if (portCallsResponse) {
        const currentPage = (portCallsResponse.Offset / portCallsResponse.Limit) + 1;
        const allFetched = currentPage >= portCallsResponse.TotalPagesCount;
        yield put({
            allFetched,
            offset: params.Offset,
            overrideCurrent: action.overrideCurrent,
            portCalls: portCallsResponse.PortCalls,
            type: ActionTypes.PORT_INFO_PORT_CALLS_SET
        });
        yield put({ type: ActionTypes.PORT_INFO_PORT_CALLS_SET_GETTING_DATA, isGettingData: false });
    }
}

function* getInitialData(action) {
    let { portActivities, segments } = yield select(state => state.portInfoCallsReducer);
    if (!portActivities.length || !segments.length) {
        [portActivities, segments] = yield all([
            PortService.getAvailablePortActivities(),
            VesselService.getSegments()
        ]);
        if (portActivities && segments) {
            yield put({
                type: ActionTypes.PORT_INFO_CALLS_SET_OPTIONS,
                portActivities,
                segments
            });
        }
    }
    yield getPortCalls({ portId: action.portId, overrideCurrent: true });
}

export default function* portInfoCallsSaga() {
    yield takeEvery(ActionTypes.PORT_INFO_CALLS_GET_INITIAL_DATA, getInitialData);
    yield takeEvery(ActionTypes.PORT_INFO_PORT_CALLS_GET, getPortCalls);
}
