import React from 'react';
import moment from 'moment';
import { t } from 'utils/i18n/i18n-model';
import { getClassName, getObjectProp } from './info-helper';
import TimeHelper from './time-helper';

export const renderYesNo = val => {
    if (typeof val === 'boolean') {
        return val ? t('GLOBAL.YES') : t('GLOBAL.NO');
    }
    return null;
};

export const renderDateField = (key, getClass) => (data) => {
    const value = getObjectProp(data, key);
    const className = typeof getClass === 'function' ? getClass(value) : null;
    return value
        ? (
            <label className="sten-panel__label">
                <span className={getClassName('sten-panel__label--value', className)}>
                    {TimeHelper.getFormatted(value, { utc: true })}
                </span>
                {` (${moment.utc(value).fromNow()})`}
            </label>
        )
        : <label className="sten-panel__label sten-panel__label--value">-</label>;
};

export function getPanelRows(columnClasses, columnValues, rowClasses = '') {
    /* eslint-disable react/no-array-index-key */
    return columnValues.map((value, index) => (
        <div key={index} className={`flex-row flex-center form-row ${rowClasses}`}>
            {columnClasses.map((columnClass, columnIndex) => (
                <div key={columnIndex} className={columnClass}>
                    {value[columnIndex]}
                </div>
            ))}
        </div>
    ));
    /* eslint-enable react/no-array-index-key */
}

export const renderLabelValueRows = (data, rows, columnClasses = ['col-8', 'col-16'], rowClasses = '') => {
    const columnValues = rows.filter(row => !row.shouldRender || row.shouldRender(data)).map(row => {
        const res = [
            <label className={getClassName('sten-panel__label', row.className)}>{row.label}</label>];
        if (row.renderValue) {
            res.push(row.renderValue(data));
        } else {
            const value = row.getValue ? row.getValue(data) : getObjectProp(data, row.valueKey);
            res.push(
                <label className="sten-panel__label sten-panel__label--value">
                    {value || '-'}
                    {value && row.unit ? ` ${row.unit}` : ''}
                    {value && row.getBadge && (
                        <span className="sten-badge sten-badge--success">{row.getBadge(data)}</span>
                    )}
                </label>
            );
        }
        return res;
    });
    return getPanelRows(columnClasses, columnValues, rowClasses);
};

export const getVesselStatus = (navigationStatus, isVesselLaden, ladenSource, showBadge = false) => {
    const badgeText = {
        Itinerary: 'VMS',
        Ais: 'AIS',
        Report: 'NR'
    };
    let vesselCondition = null;
    if (typeof isVesselLaden === 'boolean') {
        vesselCondition = isVesselLaden ? t('GLOBAL.LADEN') : t('GLOBAL.BALLAST');
    }
    let navStatus = null;
    if (navigationStatus) {
        navStatus = vesselCondition ? ` / ${navigationStatus}` : navigationStatus;
    }
    return vesselCondition || navStatus
        ? (
            <React.Fragment>
                {vesselCondition}
                {vesselCondition && showBadge && ladenSource && badgeText[ladenSource] && (
                    <span className="sten-badge sten-badge--inline sten-badge--success">{badgeText[ladenSource]}</span>
                )}
                {navStatus}
            </React.Fragment>
        )
        : t('GLOBAL.UNKNOWN');
};

export default getPanelRows;
