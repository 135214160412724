import moment from 'moment';
import { takeEvery, put, select } from 'redux-saga/effects';
/* router */
import { updateQueryParams } from 'app-router';
/* actions */
import { ActionTypes } from './map-date-picker-actions';
import { ActionTypes as HomeActionTypes } from '../home-actions';

const waitForAisResponse = 6 * 60000;

function* updateDate(action) {
    const { isDisabled, isMounted, startDate, selectedDate } = yield select(state => state.mapDatePickerReducer);
    if ((!action.forceUpdate && (isDisabled || !isMounted)) || !action.date) {
        return;
    }
    const now = moment().startOf('hour');
    let newDate = action.date.startOf('hour');
    let isNowSelected = false;
    if (newDate.isBefore(startDate)) {
        newDate = startDate;
    }
    if (newDate.isSameOrAfter(now)) {
        newDate = now;
        isNowSelected = true;
    }
    if (!moment(selectedDate).isSame(newDate)
        || (moment().valueOf() <= now.valueOf() + waitForAisResponse)) {
        yield put({ type: ActionTypes.MAP_DATE_PICKER_SET_DATE, selectedDate: newDate.toISOString(), isNowSelected });
        yield put({ type: HomeActionTypes.HOME_GET_VESSELS });
    }
}

function* addHours(action) {
    const { selectedDate } = yield select(state => state.mapDatePickerReducer);
    yield updateQueryParams({ remove: ['voyage'] });
    yield updateDate({ date: moment(selectedDate).add(action.hours, 'h') });
}

function* reset() {
    const now = moment().startOf('hour');
    yield updateQueryParams({ remove: ['voyage'] });
    yield updateDate({ date: now });
}

export default function* mapDatePickerSaga() {
    yield takeEvery(ActionTypes.MAP_DATE_PICKER_UPDATE_DATE, updateDate);
    yield takeEvery(ActionTypes.MAP_DATE_PICKER_ADD_HOURS, addHours);
    yield takeEvery(ActionTypes.MAP_DATE_PICKER_RESET, reset);
}
