import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
/* router */
import { appRoutes } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* actions */
import {
    generateCargoPDF as generateCargoPDFAction,
    generateCargoXLSX as generateCargoXLSXAction,
    getResources,
    reset
} from './voyage-info-actions';
/* selectors */
import { getConvertedItinerary, getConvertedVoyage } from './voyage-info-selectors';
/* components */
import TabSelector from 'components/tab-selector/tab-selector';
import VoyageInfoOverview from './voyage-info-overview/voyage-info-overview';
import VoyageInfoItinerary from './voyage-info-itinerary/voyage-info-itinerary';
import VoyageInfoCargo from './voyage-info-cargo/voyage-info-cargo';
import Comments from 'components/comments/comments';
/* styles */
import './voyage-info.scss';

class VoyageInfo extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            activeTabId: typeof props.activeTabId === 'number' ? props.activeTabId : 0
        };
    }

    componentDidMount() {
        if (this.props.voyageId) {
            this.props.getResources(this.props.voyageId);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.voyageId && this.props.voyageId !== prevProps.voyageId) {
            this.props.getResources(this.props.voyageId);
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

    getTabs = () => {
        const {
            voyage,
            // permissions,
            cargo,
            itinerary,
            comments,
            voyageId,
            generateCargoXLSX,
            generateCargoPDF
        } = this.props;

        return [
            {
                id: 0,
                title: t('VOYAGE_INFO.OVERVIEW.TITLE'),
                icon: 'icon-overview',
                content: <VoyageInfoOverview voyage={voyage} />
            },
            {
                id: 1,
                title: t('VOYAGE_INFO.ITINERARY.TITLE'),
                icon: 'icon-itinerary',
                disabled: itinerary.length === 0,
                content: (
                    <VoyageInfoItinerary
                        voyage={voyage}
                        itinerary={itinerary}
                    />
                )
            },
            {
                id: 2,
                title: t('VOYAGE_INFO.CARGO.TITLE'),
                icon: 'icon-cargo',
                disabled: cargo.CargoWings.length === 0,
                content: cargo ? (
                    <VoyageInfoCargo
                        generateCargoPDF={generateCargoPDF}
                        generateCargoXLSX={generateCargoXLSX}
                        vesselName={voyage.VesselName}
                        selectedVoyageTankLayout={cargo.CargoWings}
                        selectedVoyageCargoSummary={cargo.CargoSummary}
                    />
                ) : null
            },
            {
                id: 3,
                title: t('COMMENTS.TITLE'),
                icon: 'icon-message',
                content: (
                    <Comments
                        canCreateComment // TODO: permissions.CreateVoyageComment
                        canUpdateAllComments // TODO: permissions.UpdateAllVoyageComments
                        canUpdateComments // TODO: permissions.UpdateAllVoyageComments
                        comments={comments}
                        resource="voyage"
                        resourceId={voyageId}
                    />
                )
            }
        ];
    };

    setActiveTab = tabId => {
        if (tabId !== this.state.activeTabId) {
            this.setState({ activeTabId: tabId });
        }
    };

    render() {
        const { voyage, voyageId } = this.props;

        if (!voyage) {
            return (
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <div className="flex-grow" />
                        <div className="flex-shrink">
                            <button
                                className="btn-link icon icon-close"
                                onClick={this.props.onCloseClick}
                            />
                        </div>
                    </div>
                    <div className="sten-content__body" />
                </div>
            );
        }

        const tabs = this.getTabs();
        const activeTab = tabs.find(tab => tab.id === this.state.activeTabId);

        return (
            <div className="sten-content">
                <div className="sten-content__header flex-row">
                    <div className="flex-grow">
                        <h1 className="sten-content__title">
                            {voyage.VesselName}
                        </h1>
                        <p className="sten-content__subtitle">
                            {t('VOYAGE_INFO.SUBTITLE', {
                                voyageNumber: voyage.VoyageNumber,
                                voyagePortInfo: voyage.VoyagePortInfo
                            })}
                        </p>
                    </div>
                    <div className="flex-shrink">
                        <Link
                            className="btn-link icon icon-edit"
                            to={`${appRoutes.Vessel.OperatorsDiary}/edit/${voyageId}`}
                        />
                    </div>
                    <div className="flex-shrink">
                        <div className="sten-content__vertical-separator" />
                    </div>
                    <div className="flex-shrink">
                        <button className="btn-link icon icon-close" onClick={this.props.onCloseClick} />
                    </div>
                </div>
                <div className="sten-content__body sten-voyage-info__body-fixed">
                    <TabSelector
                        tabItems={tabs}
                        activeTabId={this.state.activeTabId}
                        onChange={this.setActiveTab}
                    />
                </div>
                <div className="sten-content__body sten-voyage-info__body-content">
                    {activeTab && activeTab.content}
                </div>
            </div>
        );
    }
}

VoyageInfo.propTypes = {
    activeTabId: PropTypes.number,
    cargo: PropTypes.objectOf(PropTypes.any),
    comments: PropTypes.arrayOf(PropTypes.object).isRequired,
    generateCargoPDF: PropTypes.func.isRequired,
    generateCargoXLSX: PropTypes.func.isRequired,
    getResources: PropTypes.func.isRequired,
    itinerary: PropTypes.arrayOf(PropTypes.object).isRequired,
    onCloseClick: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    reset: PropTypes.func.isRequired,
    voyage: PropTypes.objectOf(PropTypes.any),
    voyageId: PropTypes.number
};

VoyageInfo.defaultProps = {
    activeTabId: 0,
    cargo: null,
    voyage: null,
    voyageId: null
};

function mapStateToProps(state) {
    return {
        cargo: state.voyageInfoReducer.cargo,
        comments: state.voyageInfoReducer.comments,
        itinerary: getConvertedItinerary(state),
        permissions: state.userReducer.permissions,
        voyage: getConvertedVoyage(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        generateCargoXLSX: () => generateCargoXLSXAction(dispatch),
        generateCargoPDF: () => generateCargoPDFAction(dispatch),
        getResources: voyageId => getResources(dispatch, voyageId),
        reset: () => reset(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VoyageInfo);
