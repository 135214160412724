import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { getClassName } from 'utils/helpers/info-helper';
import { refreshAdminUserManagementData, setAsNewDepartment } from '../admin-user-management-actions';
/* components */
import FlipSwitch from 'components/flip-switch/flip-switch';
import Input from 'components/input/input';
import Modal from 'components/modal/modal';
import PasswordStrength from 'components/password-strength/password-strength';
import ScrollArea from 'components/scroll-area/scroll-area';
import Select from 'components/select/select';
import TextHighlight from 'components/text-highlight/text-highlight';
import Validation from 'components/validation/validation';
/* services */
import SearchService from 'services/core-api/search-service';
import UserManagementService from 'services/core-api/user-management-service';
/* styles */
import './admin-user-management-form.scss';

const initialUserFormData = {
    ConfirmPassword: '',
    Email: '',
    HasPassword: false,
    Title: '',
    FirstName: '',
    IsActive: true,
    IsVisibleInContactList: false,
    LocalLoginEnabled: true,
    LastName: '',
    Location: '',
    MobilePhone: '',
    Password: '',
    ProfileImageUrl: '',
    Role: null,
    Departments: [],
    Scopes: [],
    UserCompanies: [],
    Vessels: [],
    WorkPhone: ''
};

class AdminUserManagementForm extends React.PureComponent {
    fieldChangeHandlers = {};

    state = {
        emailExists: null,
        isNewDepartmentModalShown: false,
        departmentName: '',
        initialEmail: null,
        isChangedEmailModalShown: false,
        error: null,
        userFormData: initialUserFormData,
        availableCompanies: [],
        availableVessels: [],
        availableFleets: [],
        fetchedUser: null
    };


    async componentDidMount() {
        if (this.props.userId) {
            await this.getActiveUser(this.props.userId);
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.error && state.error !== props.error && props.error.EmailAlreadyRegistered) {
            return {
                emailExists: props.email,
                error: props.error
            };
        }
        return null;
    }

    async componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId) {
            this.form.resetValidation();
            if (this.props.userId) {
                await this.getActiveUser(this.props.userId);
            } else {
                this.resetUserFormData();
            }
        }
    }

    resetUserFormData() {
        this.setState({ userFormData: initialUserFormData });
    }

    setInitialUserFormDataStateOnEdit() {
        if (!this.state.fetchedUser) return;

        const fetchedUser = this.state.fetchedUser;

        this.setState({
            userFormData: {
                ...fetchedUser,
                Password: '',
                ConfirmPassword: ''
            }
        });
    }

    componentWillUnmount() {
        this.form.resetValidation();
        this.resetUserFormData();
    }

    toggleConfirmModal = (value) => {
        this.setState({
            isNewDepartmentModalShown: !this.state.isNewDepartmentModalShown,
            departmentName: value ? value.Name : ''
        });
    };

    getFieldChangeHandler = (key) => {
        if (key in this.fieldChangeHandlers) return this.fieldChangeHandlers[key];

        this.fieldChangeHandlers[key] = (value) => {
            if (key && value && Array.isArray(value) && value.length > 0) {
                const customValue = value.find(val => val.Id === 'customInput');
                if (customValue) {
                    this.toggleConfirmModal(customValue);
                    return;
                }

                this.setState((prev) => ({
                    ...prev,
                    userFormData: {
                        ...prev.userFormData,
                        [key]: value
                    }
                }));
            } else {
                this.setState((prev) => ({
                    ...prev,
                    userFormData: {
                        ...prev.userFormData,
                        [key]: value
                    }
                }));
            }
        };

        return this.fieldChangeHandlers[key];
    };

    handleSetRole = async (role) => {
        const selectedRole = this.state.userFormData.Role;
        const newUserFormData = { ...this.state.userFormData };

        if (selectedRole && role && selectedRole !== role && selectedRole.IsInternalRole !== role.IsInternalRole) {
            newUserFormData.Scopes = [];
            newUserFormData.Vessels = [];
        }

        newUserFormData.Role = role;

        this.setState((prev) => ({
            ...prev,
            userFormData: newUserFormData
        }));
        await this.checkCompaniesPerRole(role);
    }

    closeAndDiscardForm = () => {
        this.form.resetValidation();
        this.resetUserFormData();
        this.props.router.navigate(appRoutes.Admin.UserManagement);
    };

    submitFormOrShowChangedEmailModal = async () => {
        const { initialEmail, userFormData: { Email } } = this.state;

        const { userId } = this.props;
        if (userId && Email && initialEmail && Email.trim().toLowerCase() !== initialEmail.trim().toLowerCase()) {
            this.setState({ isChangedEmailModalShown: true });
        } else {
            await this.handleSubmit();
        }
    };

    setAsNewDepartment = () => {
        this.props.setAsNewDepartment(this.state.departmentName);
        this.toggleConfirmModal();
    };

    renderCompanyOrVesselSelect = (isInternalUser) => {
        if (!this.state.userFormData.Role) { return null; }
        const isGodmotherRoleSelected = this.state.userFormData.Role
            && this.state.userFormData.Role.Name === 'Godmother';
        if (isInternalUser && !isGodmotherRoleSelected) {
            return (
                <React.Fragment>
                    <div className="form-row">
                        <label className="label">{t('ADMIN.USER_MANAGEMENT.FORM.COMPANY')}</label>
                        <Validation.Wrapper validations={{ required: true }}>
                            <Select
                                fixedListWidth
                                name="internalCompany"
                                searchable
                                clearable
                                multiple
                                valueKey="Id"
                                labelKey="Name"
                                options={this.props.internalCompanies}
                                value={this.state.userFormData.UserCompanies}
                                onChange={this.getFieldChangeHandler('UserCompanies')}
                            />
                        </Validation.Wrapper>
                    </div>
                    {this.state.userFormData.UserCompanies && this.state.userFormData.UserCompanies.length > 0 && (
                        <div className="form-row" key="scope">
                            <label className="label">{t('ADMIN.USER_MANAGEMENT.FORM.FLEET')}</label>
                            <Validation.Wrapper validations={{ required: true }}>
                                <Select
                                    fixedListWidth
                                    name="scopes"
                                    searchable
                                    multiple
                                    clearable
                                    valueKey="Id"
                                    labelKey="Name"
                                    options={this.state.availableFleets}
                                    value={this.state.userFormData.Scopes}
                                    onChange={this.getFieldChangeHandler('Scopes')}
                                />
                            </Validation.Wrapper>
                        </div>
                    )}
                </React.Fragment>
            );
        } if (!isInternalUser && !isGodmotherRoleSelected) {
            return (
                <div className="form-row">
                    <label className="label">{t('ADMIN.USER_MANAGEMENT.FORM.COMPANY')}</label>
                    <Validation.Wrapper validations={{ required: true }}>
                        <Select
                            onSearch={this.getCompaniesPerRole}
                            fixedListWidth
                            name="company"
                            searchable
                            multiple
                            clearable
                            valueKey="Id"
                            labelKey="Name"
                            options={this.state.availableCompanies}
                            value={this.state.userFormData.UserCompanies}
                            onChange={this.getFieldChangeHandler('UserCompanies')}
                        />
                    </Validation.Wrapper>
                </div>
            );
        }
        return (
            <div className="form-row">
                <label className="label">{t('ADMIN.USER_MANAGEMENT.FORM.SELECT_VESSELS')}</label>
                <Select
                    onSearch={this.searchVessels}
                    fixedListWidth
                    name="vessels"
                    searchable
                    multiple
                    clearable
                    valueKey="Imo"
                    labelKey="Title"
                    options={this.state.availableVessels}
                    value={this.state.userFormData.Vessels}
                    onChange={this.getFieldChangeHandler('Vessels')}
                />
            </div>
        );
    };

    getOption = (option, searchCriteria) => (
        <div className="flex-row">
            <div className="flex-shrink text-ellipsis">
                {option.Id === 'customInput' && (<div>{t('ADMIN.USER_MANAGEMENT.FORM.ADD_NEW_DEPARTMENT')}</div>)}
                <TextHighlight input={option.Name} highlight={searchCriteria} />
            </div>
        </div>
    );

    closeAndDiscardDepartmentAdding = () => {
        this.setState({
            isNewDepartmentModalShown: false,
            departmentName: ''
        });
    };

    validateDepartmentName = {
        rule: (value) => {
            if (value) {
                const list = this.props.departments;
                const valueLowerCase = value.trim().toLowerCase();
                return !list.some(department => department.Name.trim().toLowerCase() === valueLowerCase);
            }
            return true;
        },
        hint: (value) => t('ADMIN.USER_MANAGEMENT.FORM.DEPARTMENT_ALREADY_EXISTS', { departmentName: value.trim() })
    };

    updateNewDepartmentName = (value) => {
        this.setState({
            departmentName: value
        });
    };

    onEmailChangedModalConfirm = async () => {
        this.setState({ isChangedEmailModalShown: false });
        await this.handleSubmit();
    };

    onEmailChangedModalCancel = () => this.setState({ isChangedEmailModalShown: false });

    searchVessels = async (searchCriteria, selectedVessels) => {
        const params = {
            Limit: selectedVessels ? selectedVessels.length : 10
        };

        if (searchCriteria) {
            params.SearchTerm = searchCriteria;
        }

        if (selectedVessels) {
            params.Imos = selectedVessels;
        }

        const vessels = await SearchService.searchAllVessels(params);

        if (!vessels) return;

        this.setState({ availableVessels: vessels });
    };

    getFleetsPerRole = async (role, searchCriteria) => {
        if (!role) return this.setState({ availableFleets: [] });

        const params = { RoleId: role.Id };

        if (searchCriteria) {
            params.Query = searchCriteria;
        }

        const fleets = await UserManagementService.getCompaniesPerRole(params);
        return this.setState({ availableFleets: fleets });
    };


    getActiveUser = async (userId) => {
        const user = await UserManagementService.getById(userId);

        if (!user) return;

        this.setState({ fetchedUser: user, initialEmail: user.Email });

        if (user.Role) {
            await this.getFleetsPerRole(user.Role);
        }

        if (user.Role && !user.Role.IsInternalRole) {
            this.setState((prev) => ({
                ...prev,
                userFormData: {
                    ...prev.userFormData,
                    UserCompanies: user.UserCompanies
                }
            }));
        }

        if (user.Vessels && user.Vessels.length) {
            await this.searchVessels(null, user.Vessels.map((v) => v.Imo));
        }

        this.setInitialUserFormDataStateOnEdit();
    };

    getCompaniesPerRole = async (searchCriteria) => {
        const { Role, UserCompanies } = this.state.userFormData;

        const params = { RoleId: Role.Id };

        if (searchCriteria) {
            params.Query = searchCriteria;
        }

        const companies = await UserManagementService.getCompaniesPerRole(params);

        UserCompanies.forEach((selectedCompany) => {
            const foundIndex = companies.findIndex(company => company.Id === selectedCompany.Id);
            if (foundIndex >= 0) {
                companies.splice(foundIndex, 1);
            }
            companies.unshift(selectedCompany);
        });

        this.setState((prev) => ({
            ...prev,
            availableCompanies: companies
        }));
    }

    checkCompaniesPerRole = async (role) => {
        const { Role: SelectedRole, UserCompanies } = this.state.userFormData;

        if (!role) return null;

        if (role.IsInternalRole) {
            await this.getFleetsPerRole(role);

            if (!(!SelectedRole || !SelectedRole.IsInternalRole)) return null;

            return this.setState((prev) => ({
                ...prev,
                userFormData: {
                    ...prev.userFormData,
                    UserCompanies: []
                }
            }));
        }

        this.setState({ availableCompanies: [] });

        if (!UserCompanies || !UserCompanies.length) return null;

        const checkedCompanies = await UserManagementService.checkCompaniesPerRole({
            CompanyIds: UserCompanies.map(company => company.Id),
            RoleId: role.Id
        });
        const newUserCompanies = UserCompanies.filter(company => checkedCompanies.indexOf(company.Id) > -1);

        return this.setState((prev) => ({
            ...prev,
            userFormData: {
                ...prev.userFormData,
                UserCompanies: newUserCompanies
            }
        }));
    };

    handleSubmit = async () => {
        let userResponse;

        const { userId } = this.props;
        const { userFormData: userData } = this.state;

        const parsedUserData = {
            ...userData,
            DepartmentIds: userData.Departments.length
                ? userData.Departments.map(role => role.Id)
                : [],
            Imos: userData.Vessels.length ? userData.Vessels.map((v) => v.Imo) : [],
            IsVisibleInContactList: userData.Role.Name !== 'Customer' && userData.Role.Name !== 'PrivilegedCustomer'
                ? userData.IsVisibleInContactList
                : false,
            Role: userData.Role ? userData.Role.Name : null,
            Scopes: userData.Scopes.length ? userData.Scopes.map(scope => scope.Name) : [],
            UserCompaniesIds: userData.UserCompanies.length ? userData.UserCompanies.map((c) => c.Id) : []
        };

        if (!parsedUserData.LocalLoginEnabled || !userData.Password) {
            delete parsedUserData.Password;
            delete parsedUserData.ConfirmPassword;
        }

        try {
            if (userId) {
                userResponse = await UserManagementService.update(userId, parsedUserData);
            } else {
                userResponse = await UserManagementService.add(parsedUserData);
            }
        } catch (error) {
            if (this.state.error !== error && error.EmailAlreadyRegistered) {
                this.form.validateAll();
            }

            this.setState({ error });
        }

        if (!userResponse) return;

        const toastMessage = userId
            ? t('ADMIN.USER_MANAGEMENT.USER_UPDATED')
            : t('ADMIN.USER_MANAGEMENT.USER_CREATED');

        toast(toastMessage, { type: toast.TYPE.SUCCESS });
        this.props.router.navigate(appRoutes.Admin.UserManagement);

        this.setState((prev) => ({
            ...prev,
            userFormData: initialUserFormData
        }));

        this.props.refreshAdminUserManagementData();
    }


    render() {
        const formClass = getClassName(
            'sten-admin-user-management-form sten-content sten-content--has-footer',
            this.props.className
        );

        const isInternalUser = this.state.userFormData.Role
            && this.state.userFormData.Role.IsInternalRole;
        return (
            <React.Fragment>
                <Modal.Confirm
                    isOpen={this.state.isChangedEmailModalShown}
                    onCancel={this.onEmailChangedModalCancel}
                    onConfirm={this.onEmailChangedModalConfirm}
                    title={t('ADMIN.USER_MANAGEMENT.FORM.EMAIL_CHANGED_MODAL_TITLE')}
                >
                    <span className="label">{t('ADMIN.USER_MANAGEMENT.FORM.EMAIL_CHANGED_MODAL_TEXT')}</span>
                </Modal.Confirm>
                <Modal.Default
                    modalSize="xs"
                    isOpen={this.state.isNewDepartmentModalShown}
                    onRequestClose={this.closeAndDiscardDepartmentAdding}
                >
                    <Validation.Form onSubmit={this.setAsNewDepartment}>
                        <div className="sten-modal__header flex flex-center text-uppercase">
                            <div className="sten-modal__title flex-grow">
                                {t('ADMIN.USER_MANAGEMENT.FORM.ADD_NEW_DEPARTMENT')}
                            </div>
                            <div className="flex-shrink">
                                <button
                                    type="button"
                                    className="btn-link icon icon-close"
                                    onClick={this.closeAndDiscardDepartmentAdding}
                                />
                            </div>
                        </div>
                        <div className="sten-modal__body">
                            <div className="sten-content__section">
                                <p className="text-secondary">{t('ADMIN.USER_MANAGEMENT.FORM.DEPARTMENT_NAME')}</p>
                            </div>
                            <div className="sten-content__section">
                                <Validation.Wrapper
                                    validations={{
                                        required: true,
                                        minLength: 2,
                                        custom: this.validateDepartmentName
                                    }}
                                >
                                    <Input
                                        autoFocus
                                        name="newDepartment"
                                        value={this.state.departmentName}
                                        onChange={this.updateNewDepartmentName}
                                    />
                                </Validation.Wrapper>
                            </div>
                        </div>
                        <footer className="sten-modal__footer">
                            <div className="flex-grow flex-row">
                                <div className="flex-grow text-right">
                                    <button
                                        type="button"
                                        onClick={this.closeAndDiscardDepartmentAdding}
                                        className="btn btn--secondary"
                                    >
                                        {t('GLOBAL.CANCEL')}
                                    </button>
                                </div>
                                <div className="flex-shrink">
                                    <Validation.Button className="btn btn--primary">
                                        {t('GLOBAL.SAVE')}
                                    </Validation.Button>
                                </div>
                            </div>
                        </footer>
                    </Validation.Form>
                </Modal.Default>
                <Validation.Form
                    onSubmit={this.submitFormOrShowChangedEmailModal}
                    ref={(c) => { this.form = c; }}
                    className={formClass}
                >
                    <div className="sten-content__header">
                        <h1 className="sten-content__title flex-grow">
                            {this.props.userId ? 'Edit user' : 'Create User'}
                        </h1>
                        <div className="flex-shrink">
                            <button
                                type="button"
                                className="btn-link icon icon-close"
                                onClick={this.closeAndDiscardForm}
                            />
                        </div>
                    </div>
                    <ScrollArea className="sten-content__body" shouldScrollToActiveElement>
                        <div className="sten-content__section">
                            <div className="form-row flex-row flex-center">
                                <div className="flex-grow">
                                    <label className="label">{t('ADMIN.USER_MANAGEMENT.FORM.USER_ACTIVE')}</label>
                                </div>
                                <div className="flex-shrink">
                                    <FlipSwitch
                                        activeLabel={t('GLOBAL.YES')}
                                        inactiveLabel={t('GLOBAL.NO')}
                                        onChange={this.getFieldChangeHandler('IsActive')}
                                        value={this.state.userFormData.IsActive}
                                    />
                                </div>
                            </div>
                            {isInternalUser && (
                                <div className="form-row flex-row flex-center">
                                    <div className="flex-grow">
                                        <label className="label">
                                            {t('ADMIN.USER_MANAGEMENT.FORM.USER_VISIBLE_IN_CONTACT_LIST')}
                                        </label>
                                    </div>
                                    <div className="flex-shrink">
                                        <FlipSwitch
                                            disabled={!this.state.userFormData.IsActive}
                                            activeLabel={t('GLOBAL.YES')}
                                            inactiveLabel={t('GLOBAL.NO')}
                                            onChange={this.getFieldChangeHandler('IsVisibleInContactList')}
                                            value={this.state.userFormData.IsVisibleInContactList}
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="form-row flex-row flex-center">
                                <div className="flex-grow">
                                    <label className="label">{t('ADMIN.USER_MANAGEMENT.FORM.LOCAL_LOGIN')}</label>
                                </div>
                                <div className="flex-shrink">
                                    <FlipSwitch
                                        activeLabel={t('GLOBAL.YES')}
                                        inactiveLabel={t('GLOBAL.NO')}
                                        onChange={this.getFieldChangeHandler('LocalLoginEnabled')}
                                        value={this.state.userFormData.LocalLoginEnabled}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <label className="label">{t('ADMIN.USER_MANAGEMENT.FORM.ROLE')}</label>
                                <Validation.Wrapper validations={{ required: true }}>
                                    <Select
                                        fixedListWidth
                                        name="type"
                                        searchable
                                        clearable
                                        valueKey="Id"
                                        labelKey="Name"
                                        options={this.props.roles}
                                        value={this.state.userFormData.Role}
                                        onChange={this.handleSetRole}
                                    />
                                </Validation.Wrapper>
                            </div>
                            <div className="form-row">
                                <label className="label">{t('ADMIN.USER_MANAGEMENT.FORM.FIRST_NAME')}</label>
                                <Validation.Wrapper validations={{ required: true, maxLength: 30 }}>
                                    <Input
                                        name="firstName"
                                        value={this.state.userFormData.FirstName}
                                        onChange={this.getFieldChangeHandler('FirstName')}
                                    />
                                </Validation.Wrapper>
                            </div>
                            <div className="form-row">
                                <label className="label">{t('ADMIN.USER_MANAGEMENT.FORM.LAST_NAME')}</label>
                                <Validation.Wrapper validations={{ required: true, maxLength: 30 }}>
                                    <Input
                                        name="lastName"
                                        value={this.state.userFormData.LastName}
                                        onChange={this.getFieldChangeHandler('LastName')}
                                    />
                                </Validation.Wrapper>
                            </div>
                            <div className="form-row">
                                {this.state.userFormData.LocalLoginEnabled && (
                                    <React.Fragment>
                                        <label className="label">{t('ADMIN.USER_MANAGEMENT.FORM.PASSWORD')}</label>
                                        <Validation.Wrapper
                                            validate={
                                                !this.props.userId
                                                || !this.state.userFormData.HasPassword
                                                || (!!this.props.userId && !!this.state.userFormData.Password)
                                            }
                                            validations={{
                                                required: !this.state.userFormData.HasPassword,
                                                minLength: 8,
                                                maxLength: 30,
                                                similarity: {
                                                    testValue: this.state.userFormData.Email,
                                                    testLabel: 'email address',
                                                    length: 4
                                                },
                                                containsCharTypes: {
                                                    types: ['lowercase', 'uppercase', 'digits', 'special'],
                                                    length: 3
                                                },
                                                strength: {
                                                    totalScore: 60,
                                                    diffChars: 5
                                                }
                                            }}
                                        >
                                            <Input
                                                name="password"
                                                autoComplete="new-password"
                                                type="password"
                                                value={this.state.userFormData.Password}
                                                onChange={this.getFieldChangeHandler('Password')}
                                            />
                                        </Validation.Wrapper>
                                        <PasswordStrength value={this.state.userFormData.Password} />
                                    </React.Fragment>
                                ) }
                            </div>
                            <div className="form-row">
                                {this.state.userFormData.LocalLoginEnabled && (
                                    <React.Fragment>
                                        <label className="label">
                                            {t('ADMIN.USER_MANAGEMENT.FORM.CONFIRM_PASSWORD')}
                                        </label>
                                        <Validation.Wrapper
                                            validate={
                                                !this.props.userId
                                                || !this.state.userFormData.HasPassword
                                                || (!!this.props.userId && !!this.state.userFormData.Password)
                                            }
                                            validations={{
                                                required: !this.state.userFormData.HasPassword,
                                                equalAs: { prop: 'password', label: 'Password' }
                                            }}
                                        >
                                            <Input
                                                name="confirmPassword"
                                                autoComplete="new-password"
                                                type="password"
                                                value={this.state.userFormData.ConfirmPassword}
                                                onChange={this.getFieldChangeHandler('ConfirmPassword')}
                                            />
                                        </Validation.Wrapper>
                                    </React.Fragment>
                                )}
                            </div>
                            {this.renderCompanyOrVesselSelect(isInternalUser)}
                            {isInternalUser && (
                                <div className="form-row">
                                    <label className="label">{t('ADMIN.USER_MANAGEMENT.FORM.DEPARTMENTS')}</label>
                                    <Validation.Wrapper validations={{ required: true }}>
                                        <Select
                                            fixedListWidth
                                            name="departments"
                                            clearable
                                            searchable
                                            multiple
                                            valueKey="Id"
                                            labelKey="Name"
                                            includeSearchInResults={
                                                this.props.permissions && this.props.permissions.UpdateDepartment
                                            }
                                            options={this.props.departments}
                                            onChange={this.getFieldChangeHandler('Departments')}
                                            value={this.state.userFormData.Departments}
                                            optionRenderer={this.getOption}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            )}
                            <div className="form-row">
                                <label className="label">{t('ADMIN.USER_MANAGEMENT.FORM.TITLE')}</label>
                                <Validation.Wrapper validations={{ required: true }}>
                                    <Input
                                        name="title"
                                        value={this.state.userFormData.Title}
                                        onChange={this.getFieldChangeHandler('Title')}
                                    />
                                </Validation.Wrapper>
                            </div>
                            <div className="form-row">
                                <label className="label">{t('ADMIN.USER_MANAGEMENT.FORM.LOCATION')}</label>
                                <Validation.Wrapper validations={{ required: true }}>
                                    <Input
                                        name="location"
                                        value={this.state.userFormData.Location}
                                        onChange={this.getFieldChangeHandler('Location')}
                                    />
                                </Validation.Wrapper>
                            </div>
                            <div className="form-row">
                                <label className="label">{t('ADMIN.USER_MANAGEMENT.FORM.EMAIL')}</label>
                                <Validation.Wrapper
                                    validations={{
                                        required: true,
                                        email: true,
                                        maxLength: 50,
                                        differsFrom: {
                                            value: this.state.emailExists,
                                            hint: `Email '${this.state.emailExists} already exists in database'`
                                        }
                                    }}
                                >
                                    <Input
                                        name="email"
                                        value={this.state.userFormData.Email}
                                        onChange={this.getFieldChangeHandler('Email')}
                                    />
                                </Validation.Wrapper>
                                <label className="label">
                                    <span className="sten-admin-user-management-form-warning-text">
                                        <span style={{ color: 'white' }}>Warning: </span>
                                        Changing email address will affect login credentials
                                    </span>
                                </label>
                            </div>
                            <div className="row form-row">
                                <div className="col-12">
                                    <label className="label">{t('ADMIN.USER_MANAGEMENT.FORM.WORK_PHONE')}</label>
                                    <Validation.Wrapper
                                        validate={!!this.state.userFormData.WorkPhone}
                                        validations={{ phone: true }}
                                    >
                                        <Input
                                            name="workPhone"
                                            value={this.state.userFormData.WorkPhone}
                                            onChange={this.getFieldChangeHandler('WorkPhone')}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="col-12">
                                    <label className="label">{t('ADMIN.USER_MANAGEMENT.FORM.MOBILE_PHONE')}</label>
                                    <Validation.Wrapper
                                        validate={!!this.state.userFormData.MobilePhone}
                                        validations={{ phone: true }}
                                    >
                                        <Input
                                            name="mobilePhone"
                                            value={this.state.userFormData.MobilePhone}
                                            onChange={this.getFieldChangeHandler('MobilePhone')}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                        </div>
                    </ScrollArea>
                    <footer className="sten-content__footer flex-row">
                        <div className="col-12">
                            <button
                                className="btn btn--secondary col-24"
                                type="button"
                                onClick={this.closeAndDiscardForm}
                            >
                                {t('ADMIN.USER_MANAGEMENT.FORM.DISCARD')}
                            </button>
                        </div>
                        <div className="col-12">
                            <Validation.Button className="btn btn--primary col-24">
                                {this.props.userId ? t('GLOBAL.SAVE_CHANGES') : t('GLOBAL.CREATE')}
                            </Validation.Button>
                        </div>
                    </footer>
                </Validation.Form>
            </React.Fragment>
        );
    }
}

AdminUserManagementForm.propTypes = {
    className: PropTypes.string,
    departments: PropTypes.arrayOf(PropTypes.any).isRequired,
    internalCompanies: PropTypes.arrayOf(PropTypes.any).isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    refreshAdminUserManagementData: PropTypes.func.isRequired,
    roles: PropTypes.arrayOf(PropTypes.any).isRequired,
    router: TRouter.isRequired,
    setAsNewDepartment: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired
};

AdminUserManagementForm.defaultProps = {
    className: ''
};

function mapStateToProps(state) {
    return {
        departments: state.adminUserManagementReducer.options.departments,
        internalCompanies: state.adminUserManagementReducer.options.internalCompanies,
        permissions: state.userReducer.permissions,
        roles: state.adminUserManagementReducer.options.roles,
        userId: state.adminUserManagementReducer.activeUserId
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setAsNewDepartment: (departmentName) => setAsNewDepartment(dispatch, departmentName),
        refreshAdminUserManagementData: () => refreshAdminUserManagementData(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminUserManagementForm));
