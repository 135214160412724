import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { isObjPropDifferent } from 'utils/helpers/info-helper';
/* components */
import IconButton from 'components/icon-button/icon-button';
import AdminUserManagementFilters from './admin-user-management-filters/admin-user-management-filters';
import AdminUserManagementTable from './admin-user-management-table/admin-user-management-table';
import AdminUserManagementForm from './admin-user-management-form/admin-user-management-form';
import RightSideBar from 'components/right-side-bar/right-side-bar';
/* actions */
import {
    getOptions,
    toggleUpdateMode,
    getCompanies,
    getUsers,
    reset
} from './admin-user-management-actions';
/* services */
import UserManagementService from 'services/core-api/user-management-service';
/* styles */
import './admin-user-management.scss';
import { activeStatusIds } from './admin-user-management-constants';

export class AdminUserManagement extends React.PureComponent {
    state = {
        department: null,
        areFiltersVisible: true,
        areOptionsFetched: false,
        appliedOptions: {
            offset: 0,
            limit: 20,
            sortOrder: 'ASC',
            sortProp: 'Username',
            companySearchCriteria: '',
            emailSearchCriteria: '',
            selectedDepartments: [],
            selectedCompany: null,
            selectedRole: null,
            selectedStatuses: [],
            selectedContactListVisibility: null
        }
    };

    static getDerivedStateFromProps(props, state) {
        let newState = null;
        const newAppliedOptions = { ...state.appliedOptions };
        if (props.router.query.department !== state.department
            && props.options.departments
            && props.options.departments.length > 0) {
            const departmentId = Number(props.router.query.department);
            const selectedDepartments = props.options.departments.find(department => department.Id === departmentId);
            newState = { department: props.router.query.department };
            if (selectedDepartments) {
                newAppliedOptions.selectedDepartments = [selectedDepartments];
                newState.appliedOptions = newAppliedOptions;
            }
        }

        const { statuses, areFetched } = props.options;

        if (areFetched && !state.areOptionsFetched && statuses.length !== 0) {
            newAppliedOptions.selectedStatuses = statuses.filter(
                status => activeStatusIds.includes(status.Id)
            );

            newState = {
                ...newState,
                appliedOptions: newAppliedOptions,
                areOptionsFetched: true
            };
        }

        if (areFetched && props.router.query.statuses && props.router.query.statuses !== state.statuses) {
            const { statuses: queryStatuses } = props.router.query;
            newState = { ...newState, statuses: queryStatuses };
            const selectedStatuses = statuses.filter(status => {
                return queryStatuses.includes(`${status.Id}`);
            });
            if (selectedStatuses) {
                newAppliedOptions.selectedStatuses = selectedStatuses;
                newState.appliedOptions = newAppliedOptions;
            }
        }

        return newState;
    }

    componentDidMount() {
        this.handleRouteChange();
        if (this.props.router.query.company) {
            this.fetchSelectedCompany(this.props.router.query.company);
        }
        this.props.getOptions();
    }

    componentDidUpdate(prevProps) {
        this.handleRouteChange(prevProps);
        if (prevProps.router.query.company !== this.props.router.query.company) {
            this.fetchSelectedCompany(this.props.router.query.company);
        }
        if (prevProps.options.areFetched !== this.props.options.areFetched) {
            this.props.getUsers(this.state.appliedOptions, true);
        }
        if (this.props.shouldRefreshData && this.props.shouldRefreshData !== prevProps.shouldRefreshData) {
            this.props.getUsers({
                ...this.state.appliedOptions,
                offset: 0,
                limit: this.state.appliedOptions.offset + this.state.appliedOptions.limit
            }, true);
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

    fetchSelectedCompany = (companyId) => {
        UserManagementService.getCompanies({ CompanyId: Number(companyId) })
            .then((res) => {
                if (res && res.length) {
                    this.setState({
                        appliedOptions: {
                            ...this.state.appliedOptions,
                            selectedCompany: res[0]
                        }
                    });
                }
            });
    }

    toggleFilters = () => {
        this.setState({ areFiltersVisible: !this.state.areFiltersVisible });
    };

    handleRouteChange = (prevProps) => {
        const { params } = this.props.router;
        if (isObjPropDifferent(prevProps, this.props, 'router.location.pathname')
             && this.props.isUpdateModeActive) {
            this.props.toggleUpdateMode('', false);
        } else if (this.props.router.location.pathname.endsWith('new')) {
            this.props.toggleUpdateMode('', true);
        } else if (params && params.id && params.id !== this.props.activeUserId) {
            this.props.toggleUpdateMode(params.id, true);
        }
    };

    editUser = (id) => this.props.router.navigate(`${appRoutes.Admin.UserManagement}/edit/${id}`)

    createUser = () => this.props.router.navigate(`${appRoutes.Admin.UserManagement}/new`)

    onFilterChange = (type, value) => {
        if (this.usersTable.fixedHeaderTable) {
            this.usersTable.fixedHeaderTable.scrollArea.scrollTop();
        }
        const { sortOrder, sortProp } = this.state.appliedOptions;
        const newAppliedOptions = {
            ...this.state.appliedOptions,
            [type]: value,
            offset: 0
        };

        if (type && type === 'sortProp') {
            newAppliedOptions.sortOrder = sortProp === value && sortOrder === 'ASC' ? 'DESC' : 'ASC';
        }

        this.setState({ appliedOptions: newAppliedOptions }, () => {
            if (type && type === 'emailSearchCriteria') {
                if (value.length >= 3 || !value) {
                    this.props.getUsers(this.state.appliedOptions, true);
                }
            } else {
                this.props.getUsers(this.state.appliedOptions, true);
            }
        });
    };

    handleTableScroll = (value) => {
        if (value.realHeight - value.topPosition < value.containerHeight + 100) {
            if (!this.props.isFetchingData && !this.props.usersAllFetched) {
                const newAppliedOptions = { ...this.state.appliedOptions };
                newAppliedOptions.offset = this.state.appliedOptions.offset + this.state.appliedOptions.limit;
                this.setState({ appliedOptions: newAppliedOptions }, () => {
                    this.props.getUsers(this.state.appliedOptions, false);
                });
            }
        }
    };

    saveTableRef = c => { this.usersTable = c; };

    render() {
        return (
            <div className="sten-admin-user-management">
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <div className="flex-grow">
                            <h1 className="sten-content__title">{t('ADMIN.USER_MANAGEMENT.TITLE')}</h1>
                        </div>
                        <div className="flex-shrink">
                            <IconButton
                                isLink
                                isActive={this.state.areFiltersVisible}
                                icon="icon-filter-outline"
                                onClick={this.toggleFilters}
                            />
                        </div>
                        <div className="flex-shrink">
                            {!this.props.isUpdateModeActive && (
                                <button
                                    className="btn btn--primary"
                                    onClick={this.createUser}
                                >
                                    <span className="btn__icon icon icon-plus" />
                                    {t('ADMIN.USER_MANAGEMENT.CREATE_NEW_USER')}
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="sten-content__body">
                        {this.state.areFiltersVisible && (
                            <div className="sten-admin-user-management__content-top">
                                <AdminUserManagementFilters
                                    onCompaniesSearch={this.props.getCompanies}
                                    appliedOptions={this.state.appliedOptions}
                                    options={this.props.options}
                                    searchCriteria={this.state.searchCriteria}
                                    onFilterChange={this.onFilterChange}
                                />
                            </div>
                        )}
                        <div className="sten-admin-user-management__content-bottom">
                            <AdminUserManagementTable
                                ref={this.saveTableRef}
                                onSortChange={this.onFilterChange}
                                handleScroll={this.handleTableScroll}
                                sortOrder={this.state.appliedOptions.sortOrder}
                                sortProp={this.state.appliedOptions.sortProp}
                                users={this.props.users}
                                usersAllFetched={this.props.usersAllFetched}
                                searchCriteria={this.state.appliedOptions.emailSearchCriteria}
                                isUpdateModeActive={this.props.isUpdateModeActive}
                                onRowClick={this.editUser}
                                statusesById={this.props.options.statusesById}
                            />
                        </div>
                    </div>
                </div>
                <RightSideBar isCollapsed={!this.props.isUpdateModeActive}>
                    {this.props.isUpdateModeActive && <AdminUserManagementForm />}
                </RightSideBar>
            </div>
        );
    }
}

AdminUserManagement.propTypes = {
    activeUserId: PropTypes.string.isRequired,
    getCompanies: PropTypes.func.isRequired,
    getOptions: PropTypes.func.isRequired,
    getUsers: PropTypes.func.isRequired,
    isFetchingData: PropTypes.bool.isRequired,
    isUpdateModeActive: PropTypes.bool.isRequired,
    options: PropTypes.objectOf(PropTypes.any).isRequired,
    reset: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    shouldRefreshData: PropTypes.bool.isRequired,
    toggleUpdateMode: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    usersAllFetched: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        activeUserId: state.adminUserManagementReducer.activeUserId,
        isUpdateModeActive: state.adminUserManagementReducer.isUpdateModeActive,
        isFetchingData: state.adminUserManagementReducer.isFetchingData,
        options: state.adminUserManagementReducer.options,
        users: state.adminUserManagementReducer.users,
        usersAllFetched: state.adminUserManagementReducer.usersAllFetched,
        shouldRefreshData: state.adminUserManagementReducer.shouldRefreshData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCompanies: (searchCriteria) => getCompanies(dispatch, searchCriteria),
        getOptions: () => getOptions(dispatch),
        getUsers: (fetchOptions, overrideCurrent) => getUsers(dispatch, fetchOptions, overrideCurrent),
        reset: () => reset(dispatch),
        toggleUpdateMode: (userId, isActive) => toggleUpdateMode(dispatch, userId, isActive)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminUserManagement));
