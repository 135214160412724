import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* helpers */
import { wktParse, getImportanceByRelativeZoomLevel, mapCurrentAisLonLat } from 'components/ol/ol-helpers';
import { t } from 'utils/i18n/i18n-model';
/* services */
import ConfigService from 'services/config-api/config-service';
/* selectors */
import { getVesselsWithAlerts } from '../fleet-dashboard-selectors';
/* actions */
import { getPorts } from '../fleet-dashboard-actions';
/* components */
import MapZoomControls from 'components/map-zoom-controls/map-zoom-controls';
import NauticalCharts from 'components/nautical-charts/nautical-charts';
import Ol from 'components/ol/ol';
/* config */
import config from './map-config';

const wholeWorldBBox = 'MULTIPOLYGON(((-180 -90,180 -90,180 90,-180 90,-180 -90)))';

class FleetDashboardMap extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            mapViewProps: { center: config.mapCenter },
            mapZoomControlElement: null
        };
    }

    currentImportanceLevel = -1;

    componentDidMount() {
        this.setState({ mapZoomControlElement: document.getElementById('stenMapZoomControls') });
    }

    onMapPostRender = () => {
        if (this.map) {
            this.getPorts();
        }
    };

    getPorts = (forceGetPorts = false) => {
        if (forceGetPorts) {
            this.forceGetPorts = true;
        }
        if (!this.map) {
            return;
        }

        const relativeZoomLevel = this.map.getRelativeZoom();
        const importanceLevel = getImportanceByRelativeZoomLevel(relativeZoomLevel);

        if (this.currentImportanceLevel !== importanceLevel) {
            this.props.getPorts({
                ImportanceLevel: importanceLevel,
                BBox: wholeWorldBBox
            });

            this.currentImportanceLevel = importanceLevel;
        }
    };

    getPortOpacity = (port, isHovered) => {
        return isHovered ? 1 : 0.8;
    };

    getPortHideLabel = (port, isHovered, zoomLevel) => {
        return !isHovered && config.port.labelMinZoomLevel > zoomLevel;
    };

    getPortSecondaryColor = (port, isHovered) => {
        return isHovered ? config.port.secondaryColor : null;
    };

    onPortClick = portFeature => {
        if (portFeature?.data?.Id) {
            this.props.router.navigate({ pathname: appRoutes.Map, query: { port: portFeature.data.Id.toString() } });
        }
    };

    /* vessels */
    getVesselColor = vessel => {
        if (vessel.LatestAlert) {
            return config.vessel.alertColor;
        }
        if (vessel.RelationshipType === 'TimeCharter') {
            return config.vessel.quaternaryColor;
        }
        if (vessel.RelationshipType === 'CargoOwner') {
            return config.vessel.tertiaryColor;
        }
        if (vessel.IsVesselInUserScope) {
            return config.vessel.primaryColor;
        }
        return config.vessel.secondaryColor;
    };

    getVesselOpacity = (vessel, isHovered) => {
        return isHovered ? 1 : 0.8;
    };

    getVesselFill = (vessel) => vessel.IsLaden === true;

    getVesselHideLabel = (vessel, isHovered, zoomLevel) => {
        return config.vessel.labelMinZoomLevel > zoomLevel && !isHovered;
    };

    onVesselClick = vesselFeature => {
        if (vesselFeature?.data) {
            const query = {};
            if (vesselFeature.data.IMO) {
                query.vessel = vesselFeature.data.IMO.toString();
            }
            if (vesselFeature.data.VoyageId) {
                query.voyage = vesselFeature.data.VoyageId.toString();
            }
            this.props.router.navigate({ pathname: appRoutes.Map, query });
        }
    };

    vesselElementProps = {
        type: 'vessel',
        primaryColor: this.getVesselColor,
        coordinates: mapCurrentAisLonLat,
        fill: this.getVesselFill,
        isActive: this.getVesselActive,
        label: vessel => (vessel.VesselName
            ? vessel.VesselName.toUpperCase()
            : t('GLOBAL.NO_NAME')),
        hideLabel: this.getVesselHideLabel,
        opacity: this.getVesselOpacity,
        overlay: this.getVesselOverlay,
        overlayProps: this.getVesselOverlayProps,
        rotation: 'CurrentAis.Heading',
        textBold: vessel => !!vessel.LatestAlert,
        textStroke: true,
        textOffset: 24
    };

    portElementProps = {
        type: 'port',
        primaryColor: config.port.secondaryColor,
        secondaryColor: this.getPortSecondaryColor,
        coordinates: port => wktParse(port.Location),
        hidden: this.getPortHidden,
        opacity: this.getPortOpacity,
        label: 'Name',
        hideLabel: this.getPortHideLabel,
        textStroke: true,
        textOnTop: true,
        zIndex: port => port.ImportanceLevel || 0
    };

    render() {
        const isNauticalMapSelected = this.props.mapTypeId === 3;
        return (
            <div className="sten-fleet-dashboard-map sten-panel">
                <MapZoomControls className="sten-fleet-dashboard-map__zoom-controls" />
                <Ol.Map
                    ref={(c) => { this.map = c; }}
                    mapViewProps={this.state.mapViewProps}
                    mapTypeId={this.props.mapTypeId}
                    onPostRender={this.onMapPostRender}
                    countryBordersNamesEnabled={this.props.countryBordersNamesEnabled}
                    zoomControlElement={this.state.mapZoomControlElement}
                >
                    {ConfigService.featureToggles.showNauticalCharts && (
                        <NauticalCharts zIndex={config.nautical.zIndex} showBaseMap={isNauticalMapSelected} />
                    )}
                    <Ol.Layer
                        name="ports"
                        type="point"
                        elements={this.props.ports}
                        elementProps={this.portElementProps}
                        zIndex={config.port.zIndex}
                        onClick={this.onPortClick}
                        onClickAway={this.onPortClick}
                    />
                    <Ol.Layer
                        name="vessels"
                        type="point"
                        elements={this.props.vessels}
                        elementProps={this.vesselElementProps}
                        zIndex={config.vessel.zIndex}
                        onClick={this.onVesselClick}
                        onClickAway={this.onVesselClick}
                    />
                </Ol.Map>
            </div>
        );
    }
}

FleetDashboardMap.propTypes = {
    countryBordersNamesEnabled: PropTypes.bool.isRequired,
    getPorts: PropTypes.func.isRequired,
    mapTypeId: PropTypes.number.isRequired,
    ports: PropTypes.arrayOf(PropTypes.object).isRequired,
    router: TRouter.isRequired,
    vessels: PropTypes.arrayOf(PropTypes.object).isRequired
};

function mapStateToProps(state) {
    return {
        countryBordersNamesEnabled: state.userReducer.settings
            && state.userReducer.settings.MarketIntelCountryBordersNamesEnabled,
        mapTypeId: state.userReducer.settings && state.userReducer.settings.MarketIntelMapType,
        ports: state.fleetDashboardReducer.ports,
        vessels: getVesselsWithAlerts(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getPorts: params => getPorts(dispatch, params)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(FleetDashboardMap));
