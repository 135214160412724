import { translate } from 'utils/i18n/i18n-model';
import { formatNumber } from 'utils/helpers/info-helper';

export const getVesselTechnicalDetails = (vessel, chosenVesselInfoConfig, vesselInfoConfigMap) => {
    const infoConfig = chosenVesselInfoConfig.slice();
    const sortedInfoConfig = infoConfig.sort();

    const technicalDetails = [];
    const dataMap = {
        BuiltYear: {
            label: translate('GLOBAL.YB'),
            value: new Date(vessel.BuiltYear).getFullYear()
        },
        DWT: {
            label: translate('GLOBAL.DWT'),
            value: formatNumber(vessel.DWT, 0),
            unit: translate('UNITS.DWT')
        },
        TotalCubicCapacity: {
            label: translate('GLOBAL.CAPACITY'),
            value: formatNumber(vessel.TotalCubicCapacity, 0),
            unit: translate('UNITS.CUBIC_CAPACITY')
        },
        IMOClass: {
            label: translate('GLOBAL.IMO_CLASS'),
            value: vessel.IMOClass && vessel.IMOClass.split(',').join('/')
        }
    };
    if (sortedInfoConfig) {
        sortedInfoConfig.forEach(infoId => {
            if (vesselInfoConfigMap[infoId]) {
                const prop = dataMap[vesselInfoConfigMap[infoId].Name];
                if (prop.value) {
                    technicalDetails.push(`${prop.label} ${prop.value}${prop.unit ? ` ${prop.unit}` : ''}`);
                }
            }
        });
        return technicalDetails;
    }
    return '';
};
