import CoreApi from './core-api';

class CounterPvarService {
    constructor() {
        this.coreResourceUrl = 'WeatherRoutingV2/Voyages';
    }

    postCounterPvarRequest = (data) => {
        return CoreApi.post(`${this.coreResourceUrl}/${data.VoyageId}/PostVoyageReports`, data)
            .then(response => response.data, () => null);
    }

    getCounterPvarList = (voyageId) => {
        return CoreApi.get(`${this.coreResourceUrl}/${voyageId}/PostVoyageReports`)
            .then(response => response.data, () => null);
    }

    getCounterPvarValidationData = (voyageId) => {
        return CoreApi.get(`${this.coreResourceUrl}/${voyageId}/PostVoyageReportValidationData`)
            .then(response => response.data, () => null);
    };
}

export default new CounterPvarService();
