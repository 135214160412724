export const ActionTypes = {
    NOTIFICATIONS_GET_ACTIVE: 'NOTIFICATIONS_GET_ACTIVE',
    NOTIFICATIONS_SET_ACTIVE: 'NOTIFICATIONS_SET_ACTIVE',
    NOTIFICATIONS_GET_DISMISSED: 'NOTIFICATIONS_GET_DISMISSED',
    NOTIFICATIONS_SET_DISMISSED: 'NOTIFICATIONS_SET_DISMISSED',
    NOTIFICATIONS_REMOVE_FROM_ACTIVE: 'NOTIFICATIONS_REMOVE_FROM_ACTIVE',
    NOTIFICATIONS_DISMISS: 'NOTIFICATIONS_DISMISS'
};

export function dismissNotifications(dispatch, notificationIds) {
    dispatch({
        type: ActionTypes.NOTIFICATIONS_DISMISS,
        notificationIds
    });
}

export function getActiveNotifications(dispatch, params) {
    dispatch({
        type: ActionTypes.NOTIFICATIONS_GET_ACTIVE,
        params
    });
}

export function getDismissedNotifications(dispatch, params, addAction) {
    dispatch({
        type: ActionTypes.NOTIFICATIONS_GET_DISMISSED,
        params,
        addAction
    });
}
