import { ActionTypes } from './notifications-actions';

const initialState = {
    activeNotifications: [],
    dismissedNotifications: [],
    limit: 20,
    offset: 0,
    isLastPage: true,
    activeTabId: 1
};

export default function notificationsReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.NOTIFICATIONS_SET_ACTIVE: {
        const activeNotifications = action.notifications.Notifications || [];
        return {
            ...state,
            activeNotifications,
            offset: action.notifications.Offset,

            isLastPage: action.notifications.TotalPagesCount === Math.round(
                action.notifications.Offset / action.notifications.Limit
            ) + 1
        };
    }
    case ActionTypes.NOTIFICATIONS_SET_DISMISSED: {
        let dismissedNotifications = [];
        if (action.addAction) {
            dismissedNotifications = dismissedNotifications.concat(state.dismissedNotifications.slice());
        }
        if (action.dismissedNotifications.Notifications) {
            dismissedNotifications = dismissedNotifications.concat(action.dismissedNotifications.Notifications);
        }
        return {
            ...state,
            dismissedNotifications,
            offset: action.dismissedNotifications.Offset,

            isLastPage: action.dismissedNotifications.TotalPagesCount === Math.round(
                action.dismissedNotifications.Offset / action.dismissedNotifications.Limit
            ) + 1
        };
    }
    case ActionTypes.NOTIFICATIONS_REMOVE_FROM_ACTIVE: {
        const activeNotifications = state.activeNotifications.filter(
            notification => action.notificationIds.indexOf(notification.NotificationId) < 0
        );
        return {
            ...state,
            activeNotifications
        };
    }
    default:
        return state;
    }
}
