import { takeEvery, put, select } from 'redux-saga/effects';
/* actions */
import { ActionTypes } from './contact-list-table-actions';
/* services */
import ContactListService from 'services/core-api/contact-list-service';

export function* getContactList(action) {
    const {
        searchCriteria,
        selectedCompanies,
        selectedDepartments
    } = yield select(state => state.contactListFiltersReducer);
    const { offset, limit, sortOrder, sortProp } = yield select(state => state.contactListTableReducer);

    const params = {
        offset: action.overrideCurrent ? 0 : offset,
        limit,
        sortBy: sortProp,
        sortOrder
    };

    if (searchCriteria) {
        params.SearchTerm = searchCriteria;
    }

    if (selectedCompanies) {
        params.CompaniesIds = selectedCompanies.map(company => company.Id);
    }

    if (selectedDepartments) {
        params.DepartmentsIds = selectedDepartments.map(department => department.Id);
    }

    yield put({ type: ActionTypes.CONTACT_LIST_TABLE_SET_FETCHING_PROGRESS, isFetchingData: true });
    const contactListResponse = yield ContactListService.get(params);

    if (contactListResponse) {
        const contacts = contactListResponse.PagedUsers;
        const currentPage = (contactListResponse.Offset / contactListResponse.Limit) + 1;
        const contactListAllFetched = currentPage >= contactListResponse.TotalPagesCount;
        yield put({
            type: ActionTypes.CONTACT_LIST_TABLE_SET_CONTACT_LIST,
            contacts,
            overrideCurrent: action.overrideCurrent,
            contactListAllFetched
        });
    } else {
        yield put({ type: ActionTypes.CONTACT_LIST_TABLE_SET_FETCHING_PROGRESS, isFetchingData: false });
    }
}

export default function* contactListTableSaga() {
    yield takeEvery(ActionTypes.CONTACT_LIST_TABLE_GET_CONTACT_LIST, getContactList);
}
