import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
/* router */
import { appRoutes } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { renderBadges, renderCreatedBy } from '../fleet-management-utils';
/* components */
import Attributes from './fleet-management-fleet-attributes';
import IconButton from 'components/icon-button/icon-button';
import Modal from 'components/modal/modal';
import VesselTable from '../vessel-table/fleet-management-vessel-table';
/* services */
import DynamicFleetService from 'services/core-api/dynamic-fleet-service';
/* styles */
import './fleet-management-fleet.scss';

export class FleetView extends React.PureComponent {
    state = {
        areFiltersVisible: true,
        areFiltersChanged: false,
        isDeleteModalOpen: false,
        prevFleet: null,
        attributes: null
    };

    static getDerivedStateFromProps(props, state) {
        if (state.prevFleet !== props.fleet) {
            return {
                prevFleet: props.fleet,
                attributes: props.fleet?.IsDynamic
                    ? {
                        SecondaryAttributeId: props.fleet.DynamicFleetSecondaryAttributeId,
                        Value: props.fleet.DynamicFleetValueAttribute
                    } : null
            };
        }
        return null;
    }

    fetchDynamicFleetVessels = (params) => {
        const {
            DynamicFleetMainAttributeId,
            DynamicFleetSecondaryAttributeId,
            DynamicFleetValueAttribute
        } = this.props.fleet;
        if (DynamicFleetMainAttributeId !== null
            && DynamicFleetSecondaryAttributeId !== null
            && DynamicFleetValueAttribute !== null) {
            return DynamicFleetService.getVessels(params);
        }
        return Promise.resolve(null);
    };

    toggleFilters = () => this.setState({ areFiltersVisible: !this.state.areFiltersVisible });

    handleFilterChange = (filters, areFiltersChanged) => {
        this.setState({ areFiltersChanged });
    };

    openDeleteModal = () => this.setState({ isDeleteModalOpen: true });

    closeDeleteModal = () => this.setState({ isDeleteModalOpen: false });

    handleDelete = () => {
        if (this.props.fleet) {
            this.props.onDelete(this.props.fleet.VesselGroupId);
        }
        this.closeDeleteModal();
    };

    render() {
        const { fleet, options, user } = this.props;
        const { isDeleteModalOpen, areFiltersChanged, areFiltersVisible, attributes } = this.state;
        const showFilterIcon = !fleet || !fleet.IsDynamic;
        return (
            <div className="sten-content sten-fleet-management-fleet-view">
                <Modal.Delete isOpen={isDeleteModalOpen} onDelete={this.handleDelete} onCancel={this.closeDeleteModal}>
                    {t('FLEET_MANAGEMENT.FLEET.CONFIRM_DELETE')}
                </Modal.Delete>
                <div className="sten-content__header flex-row">
                    <div className="flex-shrink">
                        <h1 className="sten-content__title">
                            {fleet?.VesselGroupName || t('FLEET_MANAGEMENT.FLEET.ALL_VESSELS')}
                        </h1>
                        {fleet && !fleet.GroupNameImmutable && fleet.UserId !== user.UserId && renderCreatedBy(fleet)}
                    </div>
                    <div className="flex-grow">{renderBadges(fleet, true)}</div>
                    {showFilterIcon && (
                        <div className="flex-shrink">
                            <IconButton
                                isLink
                                changeIndicator={!areFiltersVisible && areFiltersChanged}
                                isActive={areFiltersVisible}
                                icon="icon-filter-outline"
                                onClick={this.toggleFilters}
                            />
                        </div>
                    )}
                    {showFilterIcon && fleet && <div className="sten-content__vertical-separator" />}
                    {fleet && !fleet.GroupImmutable && (
                        <div className="flex-shrink">
                            <Link
                                to={`${appRoutes.Vessel.FleetManagement}/edit/${fleet.VesselGroupId}`}
                                className="icon icon-edit btn-link"
                            />
                        </div>
                    )}
                    {fleet && !fleet.GroupNameImmutable && !fleet.GroupImmutable && (
                        <div className="flex-shrink">
                            <button className="icon icon-delete btn-link" onClick={this.openDeleteModal} />
                        </div>
                    )}
                </div>
                {fleet?.IsDynamic && (
                    <Attributes
                        mainAttributeOptions={options.mainAttributes}
                        mainAttributeId={fleet.DynamicFleetMainAttributeId}
                        secondaryAttributeId={fleet.DynamicFleetSecondaryAttributeId}
                        valueAttribute={fleet.DynamicFleetValueAttribute}
                    />
                )}
                {fleet?.IsDynamic
                    ? (
                        <VesselTable
                            className="sten-content__body"
                            options={options}
                            fetchVessels={this.fetchDynamicFleetVessels}
                            areFiltersVisible={false}
                            filters={attributes}
                        />
                    ) : (
                        <VesselTable
                            className="sten-content__body"
                            options={options}
                            vessels={fleet ? fleet.Vessels : null}
                            areFiltersVisible={areFiltersVisible}
                            onFilterChange={this.handleFilterChange}
                        />
                    )
                }
            </div>
        );
    }
}

FleetView.propTypes = {
    fleet: PropTypes.objectOf(PropTypes.any),
    onDelete: PropTypes.func.isRequired,
    options: PropTypes.shape({
        commercialOperators: PropTypes.arrayOf(PropTypes.object),
        companyFleets: PropTypes.arrayOf(PropTypes.object),
        segments: PropTypes.arrayOf(PropTypes.object),
        technicalManagers: PropTypes.arrayOf(PropTypes.object),
        vesselOwners: PropTypes.arrayOf(PropTypes.object),
        mainAttributes: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    user: PropTypes.objectOf(PropTypes.any).isRequired
};

FleetView.defaultProps = {
    fleet: null
};

export default FleetView;
