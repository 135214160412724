import React from 'react';
import PropTypes from 'prop-types';
/* styles */
import './icon-button.scss';

class IconButton extends React.PureComponent {
    getNotificationsAndAlerts() {
        if (this.props.notification) {
            return (
                <span className="sten-icon-button__badge sten-icon-button__badge--notification">
                    {this.props.notification}
                </span>
            );
        }
        if (this.props.alert) {
            return (
                <span className="sten-icon-button__badge sten-icon-button__badge--alert">{this.props.alert}</span>
            );
        }
        return '';
    }

    getClassNames() {
        let classNames = 'sten-icon-button';
        if (this.props.isLink) {
            classNames += ' sten-icon-button--link';
        }
        if (this.props.isActive) {
            classNames += ' sten-icon-button--active';
        }
        if (this.props.className) {
            classNames += ` ${this.props.className}`;
        }
        if (this.props.isDisabled) {
            classNames += ' sten-icon-button--disabled';
        }
        if (this.props.changeIndicator) {
            classNames += ' sten-icon-button--has-change-indicator';
        }
        return classNames;
    }

    render() {
        const iconClassNames = `icon ${this.props.icon}`;
        return (
            <button
                type={this.props.type}
                className={this.getClassNames()}
                onClick={this.props.isDisabled ? null : this.props.onClick}
                title={this.props.tooltipTitle}
            >
                {this.props.icon && (
                    <i className={iconClassNames} />
                )}
                {this.props.children}
                {this.getNotificationsAndAlerts()}
            </button>
        );
    }
}

IconButton.propTypes = {
    alert: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    changeIndicator: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    icon: PropTypes.string,
    isActive: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLink: PropTypes.bool,
    notification: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    tooltipTitle: PropTypes.string,
    type: PropTypes.string
};

IconButton.defaultProps = {
    alert: '',
    changeIndicator: false,
    children: null,
    className: '',
    icon: '',
    isActive: false,
    isDisabled: false,
    isLink: false,
    notification: '',
    onClick: undefined,
    tooltipTitle: '',
    type: 'button'
};

export default IconButton;
