export const ActionTypes = {
    WNI_WEATHER_GET_DATA: 'WNI_WEATHER_GET_DATA',
    WNI_WEATHER_SET_DATA: 'WNI_WEATHER_SET_DATA'
};

export function getWniWeatherData(dispatch, layerType) {
    dispatch({
        type: ActionTypes.WNI_WEATHER_GET_DATA,
        layerType
    });
}
