/* eslint-disable react/no-find-dom-node */
/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-const */

import React from 'react';
import ReactDOM from 'react-dom';

export function isReact13(r) {
    const { version } = r;
    if (typeof version !== 'string') {
        return true;
    }

    const parts = version.split('.');
    const major = parseInt(parts[0], 10);
    const minor = parseInt(parts[1], 10);

    return major === 0 && minor === 13;
}

const react13 = isReact13(React);
let didWarnAboutChild = false;

export function findDOMNode(component) {
    if (!react13) {
        return component;
    }

    return ReactDOM.findDOMNode(component);
}

export function warnAboutFunctionChild() {
    if (didWarnAboutChild || react13) {
        return;
    }

    didWarnAboutChild = true;
}

export function warnAboutElementChild() {
    if (didWarnAboutChild || !react13) {
        return;
    }

    didWarnAboutChild = true;
}

export function positiveOrZero(number) {
    return number < 0 ? 0 : number;
}
