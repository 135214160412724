import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import fastDeepEqual from 'fast-deep-equal';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { setObjectProp } from 'utils/helpers/info-helper';
import { renderFormFields } from 'components/form-fields/form-fields-helper';
import ScrollArea from 'components/scroll-area/scroll-area';
import TimeHelper from 'utils/helpers/time-helper';
/* components */
import Accordion from '../../accordion/accordion';
import Validation from 'components/validation/validation';
/* actions */
import { berthUpdated } from './berth-info-actions';
/* services */
import TerminalsService from 'services/core-api/terminals-service';
/* constants */
import { Berth, Restrictions, berthFields, berthFieldNames } from '../terminal-and-berth-constants';

const t = (key) => translate(`BERTH.${key}`);

export class BerthForm extends React.PureComponent {
    state = {
        initialBerth: null,
        berth: null
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.isActive && prevProps.id !== this.props.id) {
            this.loadData();
        }
    }

    handleCancel = (berthId) => {
        const add = typeof berthId === 'number' ? { berth: berthId } : null;
        this.props.router.updateQueryParams({ remove: ['berthUpdate', 'berthAddTerminal'], add });
    };

    loadData = () => {
        const berth = { TerminalId: this.props.terminalId };
        if (this.props.id) {
            TerminalsService.getBerthById(this.props.id).then(res => {
                this.setState({ initialBerth: res, berth: new Berth({ ...res, ...berth }) });
            });
        } else {
            this.setState({ initialBerth: null, berth: new Berth(berth) });
        }
    };

    handleSubmit = () => {
        TerminalsService.updateBerth({
            ...this.state.berth,
            Restrictions: fastDeepEqual(this.state.berth.Restrictions, Restrictions)
                ? null
                : this.state.berth.Restrictions
        }).then(berth => {
            if (berth) {
                toast(t('BERTH_SUCCESSFULLY_UPDATED'), { type: toast.TYPE.SUCCESS });
                this.props.onUpdate(berth.BerthId, { ...berth, TerminalId: this.state.berth.TerminalId });
                this.handleCancel(berth.BerthId);
            }
        });
    };

    handleValueChange = (key, prop) => (value) => {
        const berth = { ...this.state.berth };
        setObjectProp(berth, key, prop ? value[prop] : value);
        this.setState({ berth });
    }

    renderContent = (fieldNames, inline = false) => {
        return renderFormFields(this.state.berth, berthFields, fieldNames, true, this.handleValueChange, inline);
    };

    renderAccordionHeader = (text) => (<h4 className="text-uppercase">{text}</h4>);

    render() {
        const { berth, initialBerth } = this.state;
        if (!this.props.permissions.UpdateBerth || !berth) {
            return (
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <div className="flex-grow" />
                        <div className="flex-shrink">
                            <button className="btn-link icon icon-close" onClick={this.handleCancel} />
                        </div>
                    </div>
                    <div className="sten-content__body" />
                </div>
            );
        }
        const id = parseInt(this.props.id, 10);
        const isEditing = !isNaN(id);

        return (
            <Validation.Form onSubmit={this.handleSubmit} className="sten-content sten-content--has-footer">
                <div className="sten-content__header">
                    <div className="flex-grow">
                        <h1 className="sten-content__title text-ellipsis">
                            {isEditing && initialBerth ? initialBerth.BerthName : t('NEW_BERTH')}
                        </h1>
                        {berth.UpdatedBy && berth.UpdatedAt && (
                            <p className="sten-content__subtitle text-ellipsis text-nowrap">
                                {translate('GLOBAL.UPDATED_BY_USER', {
                                    date: TimeHelper.getFormatted(berth.UpdatedAt, { time: true }),
                                    user: `${berth.UpdatedBy}`
                                })}
                            </p>
                        )}
                    </div>
                    <div className="flex-shrink">
                        <button type="button" className="btn-link icon icon-close" onClick={this.handleCancel} />
                    </div>
                </div>
                <ScrollArea className="sten-content__body" shouldScrollToActiveElement>
                    <div className="sten-content__section">
                        {this.renderContent(berthFieldNames.BasicInfo)}
                    </div>
                    <Accordion header={this.renderAccordionHeader(t('RESTRICTIONS'))}>
                        <div className="sten-content__section">
                            {this.renderContent(berthFieldNames.Restrictions, true)}
                        </div>
                    </Accordion>
                </ScrollArea>
                <footer className="sten-content__footer flex-row">
                    <div className="col-12">
                        <button className="btn btn--secondary col-24" type="button" onClick={this.handleCancel}>
                            {translate('GLOBAL.CANCEL')}
                        </button>
                    </div>
                    <div className="col-12">
                        <Validation.Button className="btn btn--primary col-24">
                            {isEditing ? translate('GLOBAL.SAVE') : translate('GLOBAL.CREATE')}
                        </Validation.Button>
                    </div>
                </footer>
            </Validation.Form>
        );
    }
}

BerthForm.propTypes = {
    id: PropTypes.number,
    isActive: PropTypes.bool,
    onUpdate: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    router: TRouter.isRequired,
    terminalId: PropTypes.number
};

BerthForm.defaultProps = {
    id: null,
    isActive: false,
    terminalId: null
};

function mapStateToProps(state) {
    return {
        permissions: state.userReducer.permissions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdate: (id, berth) => berthUpdated(dispatch, id, berth)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BerthForm));
