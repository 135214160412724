/* ol proj */
import { transform as projTransform } from 'ol/proj';
/* ol format */
import FormatWKT from 'ol/format/WKT';
import Arc from 'arc';
/* utils */
import { mapObjectValues } from 'utils/helpers/info-helper';
/* config */
import MapConfig from './configs/map';

export const getLonLatMapper = (lonProp = 'Longitude', latProp = 'Latitude') =>
    (point) => mapObjectValues(point, lonProp, latProp);

export const mapLonLat = getLonLatMapper();
export const mapLocationLonLat = getLonLatMapper('Location.Longitude', 'Location.Latitude');
export const mapPositionLonLat = getLonLatMapper('Position.Longitude', 'Position.Latitude');
export const mapCurrentAisLonLat = getLonLatMapper('CurrentAis.Longitude', 'CurrentAis.Latitude');
export const mapCurrentAisLocationLonLat = getLonLatMapper(
    'CurrentAis.Location.Longitude', 'CurrentAis.Location.Latitude'
);

function convertToNormalExtent(value) {
    const limit = Math.PI * 6378137;
    let v = value;

    while (v < -limit || v > limit) {
        if (v > limit) {
            v -= -2 * limit;
        } else {
            v += 2 * limit;
        }
    }

    return v;
}

export function roundNumber(value, decimals) {
    if (typeof value === 'number' && value === 0) {
        return 0;
    }
    const multiplier = decimals ? 10 ** decimals : 1;
    return typeof value === 'number' ? Math.round(value * multiplier) / multiplier : null;
}

export function normalizeCoordinateExtent(coordinates) {
    return [convertToNormalExtent(coordinates[0]), convertToNormalExtent(coordinates[1])];
}

export function convertCoordinates(coordinates) {
    return projTransform(
        coordinates,
        MapConfig.targetProjection,
        MapConfig.sourceProjection
    );
}

const formatWKT = new FormatWKT();

export function wktParse(wkt) {
    try {
        return formatWKT.readFeature(wkt).getGeometry().getCoordinates();
    } catch (error) {
        return [];
    }
}

export function extendExtent(extent, scale = 40) {
    const factor = scale / 100;
    const xIncrement = factor * (extent[2] - extent[0]);
    const yIncrement = factor * (extent[3] - extent[1]);

    return [
        extent[0] - xIncrement,
        extent[1] - yIncrement,
        extent[2] + xIncrement,
        extent[3] + yIncrement
    ];
}

function convertToMinus180To180Range(value) {
    let v = value;

    while (v < -180 || v > 180) {
        if (v > 180) {
            v -= 360;
        } else {
            v += 360;
        }
    }

    return v;
}

export function normalizeCoordinates(coordinates) {
    return [convertToMinus180To180Range(coordinates[0]), coordinates[1]];
}

// returns an array of intervals for longitude axis
function calculateXIntervalsForBBOX(xMin, xMax) {
    if (Math.abs(xMax - xMin) >= 360) {
        return [[-180, 180]];
    }

    const xMinNormalized = convertToMinus180To180Range(xMin);
    const xMaxNormalized = convertToMinus180To180Range(xMax);

    if (xMinNormalized > xMaxNormalized) {
        return [
            [-180, xMaxNormalized],
            [xMinNormalized, 180]
        ];
    }

    return [[xMinNormalized, xMaxNormalized]];
}

export function calculateMultipolygonCoordinates(xMin, xMax, yMin, yMax) {
    const xIntervals = calculateXIntervalsForBBOX(xMin, xMax);
    const yMinNormalized = Math.max(-90, yMin);
    const yMaxNormalized = Math.min(90, yMax);

    let interval;
    const multipolygonCoords = [];

    for (let i = 0; i < xIntervals.length; i++) {
        interval = xIntervals[i];

        multipolygonCoords.push([
            [interval[0], yMinNormalized],
            [interval[1], yMinNormalized],
            [interval[1], yMaxNormalized],
            [interval[0], yMaxNormalized],
            [interval[0], yMinNormalized]
        ]);
    }

    return multipolygonCoords;
}

export function isExtentWithinCached(extent, cached) {
    let isWithinCached = true;
    for (let i = 0; i < 4; i++) {
        if ((i < 2 && cached[i] > extent[i]) || (i > 1 && cached[i] < extent[i])) {
            isWithinCached = false;
            break;
        }
    }
    return isWithinCached;
}

// this function has been used for calculating positions for focusing view on active route
export function getRangeCoordinatesFromRoute(route, xOffset, yOffset) {
    let minX = 180;
    let maxX = -180;
    let minY = 90;
    let maxY = -90;
    route.forEach((point) => {
        if (point[0] < minX) {
            minX = point[0];
        }
        if (point[0] > maxX) {
            maxX = point[0];
        }
        if (point[1] < minY) {
            minY = point[1];
        }
        if (point[1] > maxY) {
            maxY = point[1];
        }
    });
    let xPoint = (minX + maxX) / 2;
    if (xOffset) {
        xPoint += xOffset;
    }
    let yPoint = (minY + maxY) / 2;
    if (yOffset) {
        yPoint += yOffset;
    }
    return {
        edges: [minX, minY, maxX, maxY],
        center: [xPoint, yPoint]
    };
}

function getSplitPoints(prev, curr) {
    const initialDiff = Math.abs(prev[0] - curr[0]);
    const altCurr = curr[0] < 0 ? curr[0] + 360 : curr[0] - 360;
    const altDiff = Math.abs(prev[0] - altCurr);
    if (initialDiff > altDiff) {
        if (altDiff === 0) {
            return true;
        }
        const xDiff = altDiff;
        const xEndDiff = 180 - Math.abs(prev[0]);
        const yDiff = Math.abs(Math.abs(curr[1]) - Math.abs(prev[1]));
        const yResDiff = (yDiff * xEndDiff) / xDiff;
        const yRes = prev[1] > curr[1] ? prev[1] - yResDiff : prev[1] + yResDiff;
        return {
            endPoint: prev[0] > curr[0] ? [180, yRes] : [-180, yRes],
            startPoint: prev[0] > curr[0] ? [-180, yRes] : [180, yRes]
        };
    }
    return null;
}

export function splitRoute180(route) {
    const newRoutes = [];
    let index = 0;
    let prevPoint;
    route.forEach((point) => {
        if (!newRoutes[index]) {
            newRoutes[index] = [];
        }
        if (!prevPoint) {
            prevPoint = point;
        } else {
            const splitPoints = getSplitPoints(prevPoint, point);
            if (splitPoints) {
                if (splitPoints.endPoint && splitPoints.startPoint) {
                    newRoutes[index].push(splitPoints.endPoint);
                    index++;
                    newRoutes[index] = [];
                    newRoutes[index].push(splitPoints.startPoint);
                } else {
                    index++;
                    newRoutes[index] = [];
                }
                prevPoint = null;
            } else {
                prevPoint = point;
            }
        }
        newRoutes[index].push(point);
    });
    return newRoutes;
}

export function getDistanceBetweenPoints(point1, point2) {
    const xDiff = point2[0] - point1[0];
    const yDiff = point2[1] - point1[1];
    return Math.sqrt((xDiff ** 2) + (yDiff ** 2));
}

export function addMiddlePoints(route, maxDistance = 0.5) { // 55.6km
    if (route && route.length > 0) {
        const newRoute = [route[0]];
        for (let i = 1; i < route.length; i++) {
            const xDiff = route[i][0] - route[i - 1][0];
            const yDiff = route[i][1] - route[i - 1][1];
            const distance = Math.sqrt((xDiff ** 2) + (yDiff ** 2));
            if (distance > maxDistance) {
                const divider = Math.ceil(distance / maxDistance);
                const xStep = xDiff / divider;
                const yStep = yDiff / divider;
                let curr = route[i - 1];
                for (let j = 0; j < divider; j++) {
                    newRoute.push(curr);
                    curr = [curr[0] + xStep, curr[1] + yStep];
                }
            }
            newRoute.push(route[i]);
        }
        return newRoute;
    }
    return route;
}

const arcConfig = { offset: 1 };

export function generateMiddlePoints(route, minPointDensity = 0.5, minArcPointDensity = 5) {
    if (route && route.length > 0) {
        const newRoute = new Array(2000);
        let arcGenerator;
        let newRouteIndex = 0;
        for (let i = 1; i < route.length; i++) {
            const xDiff = route[i][0] - route[i - 1][0];
            const yDiff = route[i][1] - route[i - 1][1];
            const distance = Math.sqrt((xDiff ** 2) + (yDiff ** 2));
            if (distance > minPointDensity) {
                const numberOfPoints = Math.ceil(distance / minPointDensity);
                if (distance > minArcPointDensity) {
                    // create arc
                    arcGenerator = new Arc.GreatCircle(
                        { x: route[i - 1][0], y: route[i - 1][1] }, // x=lon y=lat
                        { x: route[i][0], y: route[i][1] }
                    );
                    const gc = arcGenerator.Arc(numberOfPoints, arcConfig);
                    for (let j = 0; j < gc.geometries.length; j++) {
                        const coords = gc.geometries[j].coords;
                        for (let k = 0; k < coords.length; k++) {
                            newRoute[newRouteIndex++] = coords[k];
                        }
                    }
                } else {
                    // create line
                    const xStep = xDiff / numberOfPoints;
                    const yStep = yDiff / numberOfPoints;
                    let curr = route[i - 1];
                    for (let j = 0; j < numberOfPoints; j++) {
                        newRoute[newRouteIndex++] = curr;
                        curr = [curr[0] + xStep, curr[1] + yStep];
                    }
                }
            } else {
                newRoute[newRouteIndex++] = route[i - 1];
            }
        }
        newRoute[newRouteIndex++] = route[route.length - 1];
        newRoute.length = newRouteIndex;
        return newRoute;
    }
    return route;
}

export const calculateDistanceBetweenPoints = (lat1, lon1, lat2, lon2) => {
    const p = 0.017453292519943295;
    const c = Math.cos;
    const a = (0.5 - (c((lat2 - lat1) * p) / 2)) + (c(lat1 * p) * c(lat2 * p) * ((1 - c((lon2 - lon1) * p)) / 2));
    return 12742 * Math.asin(Math.sqrt(a)); // 2 * R; R = 6371 km
};

/* convert route coordinates to all be on the same side */
export function fixLongitude(prev, curr) {
    const currDiff = prev > curr ? prev - curr : curr - prev;
    const altCurr = curr < prev ? curr + 360 : curr - 360;
    const altDiff = prev > altCurr ? prev - altCurr : altCurr - prev;
    if (currDiff > altDiff) {
        return altCurr;
    }
    return curr;
}

export function fixRouteCoordinates(coordinates) {
    let prevLon;
    return coordinates.map((point) => {
        if (!prevLon) {
            prevLon = point[0];
            return point;
        }
        prevLon = fixLongitude(prevLon, point[0]);
        return [prevLon, point[1]];
    });
}

/* get port importance for specific zoom level */
export const getImportanceByRelativeZoomLevel = relativeZoomLevel => (
    relativeZoomLevel > 7
        ? 1
        : 17 - (relativeZoomLevel * 2)
);

/* reduce number of calculation points when fitting into view */
export const reducePointNumber = (points, maxPoints = 50) => {
    if (points.length < maxPoints) {
        return points;
    }
    const skip = parseInt(points.length / maxPoints, 10);
    return points.filter((point, index) => index % skip === 0);
};

/* calculate extent points (top left and bottom right) from route */
export const getExtent = (points) => {
    let tl = null;
    let br = null;
    points.forEach((point) => {
        if (!tl) {
            tl = [...point];
        } else {
            if (tl[0] > point[0]) { tl[0] = point[0]; }
            if (tl[1] < point[1]) { tl[1] = point[1]; }
        }
        if (!br) {
            br = [...point];
        } else {
            if (br[0] < point[0]) { br[0] = point[0]; }
            if (br[1] > point[1]) { br[1] = point[1]; }
        }
    });
    return [tl, br];
};
