import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* utils */
import { renderFormContent } from 'components/form-fields/form-fields-helper';
import { setObjectProp } from 'utils/helpers/info-helper';
import { t } from 'utils/i18n/i18n-model';
/* actions */
import { postCounterPvarRequest } from '../counter-pvar-actions';
import { toggleActivePanel } from 'components/right-side-bar/right-side-bar-actions';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
import Validation from 'components/validation/validation';
/* constants */
import { fieldMap, sectionsAndFields } from './counter-pvar-form-fields-map-and-sections';

const cargoStatuses = [
    { Id: 1, Name: 'Ballast' },
    { Id: 2, Name: 'Laden' }
];

export const validations = {
    VesselCondition: { required: true },
    InstructedSpeed: { required: true, numeric: { min: 0, max: 30, div: 0.5, divHint: 'whole or half' } },
    Emails: { required: true },
    Comment: { maxLength: 2000 }
};

const initialState = {
    Comment: null,
    Emails: [],
    InstructedSpeed: null,
    VesselCondition: null,
    Vessel: null,
    Voyage: null
};

export class CounterPvarForm extends React.PureComponent {
    state = {
        editingCounterPvar: initialState
    }

    static getDerivedStateFromProps(props, state) {
        const newState = state;
        if (props.selectedVessel !== state.Vessel) {
            newState.editingCounterPvar.Vessel = props.selectedVessel;
        }
        if (props.selectedVoyage !== state.Voyage) {
            newState.editingCounterPvar.Voyage = props.selectedVoyage;
        }
        return newState;
    }

    handleClose = () => {
        this.props.toggleActivePanel('counter-pvar-form', false);
    }

    handleValueChange = (prop) => (value) => {
        this.setState((prevState) => {
            const editingCounterPvar = setObjectProp({ ...prevState.editingCounterPvar }, prop, value, true);
            return { editingCounterPvar };
        });
    };

    handleSubmit = () => {
        this.props.postCounterPvarRequest(this.state.editingCounterPvar);
    };

    renderContent = () => {
        const params = {
            data: this.state.editingCounterPvar,
            onChange: this.handleValueChange,
            isEditing: true,
            editingOptions: { cargoStatuses }
        };
        return renderFormContent({
            sections: sectionsAndFields,
            fieldMap,
            params
        });
    };

    render() {
        const { editingCounterPvar } = this.state;
        return (editingCounterPvar && (
            <Validation.Form
                className="sten-validation-form sten-content sten-content--has-footer"
                onSubmit={this.handleSubmit}
            >
                <header className="sten-content__header flex-row">
                    <button
                        type="button"
                        className="btn-link icon icon-arrow-left flex-shrink"
                        onClick={this.handleClose}
                    />
                    <h1 className="sten-content__title flex-grow">
                        {t('COUNTER_PVAR.COUNTER_PVAR_FORM_TITLE')}
                    </h1>
                </header>
                <ScrollArea className="sten-content__body" shouldScrollToActiveElement>
                    {this.renderContent()}
                </ScrollArea>
                <footer className="sten-content__footer flex-row">
                    <div className="col-12">
                        <button type="button" className="btn btn--secondary col-24" onClick={this.handleClose}>
                            {t('GLOBAL.CANCEL')}
                        </button>
                    </div>
                    <div className="col-12">
                        <Validation.Button className="btn btn--primary col-24">
                            {t('COUNTER_PVAR.COUNTER_PVAR_FORM.SEND_REQUEST')}
                        </Validation.Button>
                    </div>
                </footer>
            </Validation.Form>
        ));
    }
}

CounterPvarForm.propTypes = {
    selectedVessel: PropTypes.objectOf(PropTypes.any),
    selectedVoyage: PropTypes.objectOf(PropTypes.any),
    toggleActivePanel: PropTypes.func.isRequired,
    postCounterPvarRequest: PropTypes.func.isRequired
};

CounterPvarForm.defaultProps = {
    selectedVessel: null,
    selectedVoyage: null
};

function mapStateToProps(state) {
    return {
        selectedVessel: state.counterPvarReducer.selectedVessel,
        selectedVoyage: state.counterPvarReducer.selectedVoyage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        postCounterPvarRequest: (counterPvarRequest) => postCounterPvarRequest(dispatch, counterPvarRequest),
        toggleActivePanel: (panelName, enable) => toggleActivePanel(dispatch, panelName, enable)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CounterPvarForm);
