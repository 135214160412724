import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import {
    resetUpdatedVessels,
    setValue,
    sendPositionList,
    toggleModal,
    getRecentRecipients,
    getSignatureCompanies,
    resetAll
} from './position-list-compose-actions';
import { toggleCompose } from '../position-list-actions';
import { updateUserSettings } from '../../user-actions';
/* components */
import PositionListComposeTable from './position-list-compose-table/position-list-compose-table';
import PositionListComposeModal from './position-list-compose-modal/position-list-compose-modal';
/* selectors */
import {
    getExportGroupedVessels,
    getVisibleExportColumns,
    getSelectedExportGrouping,
    getSelectedSignature,
    getSelectSignatureCompanies,
    getVesselInfoConfigMap
} from '../position-list-selectors';
import getVessels from './position-list-compose-selectors';

class PositionListCompose extends React.PureComponent {
    componentDidMount() {
        this.props.getSignatureCompanies();
    }

    openSendModal = () => {
        this.props.getRecentRecipients();
        this.props.toggleModal(true);
    };

    closeSendModal = () => {
        this.props.toggleModal(false);
    };

    closeCompose = () => {
        this.props.toggleCompose(false);
    };

    componentWillUnmount() {
        this.props.resetAll();
    }

    render() {
        let updatedCount = 0;
        if (this.props.vessels && this.props.vessels.length) {
            this.props.vessels.forEach((vessel) => {
                if (vessel.Updated) {
                    updatedCount++;
                }
            });
        }
        return (
            <section className="sten-content sten-content--has-footer">
                <PositionListComposeModal
                    email={this.props.email}
                    fixtureStatuses={this.props.fixtureStatuses}
                    groupedVessels={this.props.groupedVessels}
                    isModalOpen={this.props.isEmailModalShown}
                    onCancel={this.closeSendModal}
                    onSend={this.props.sendPositionList}
                    recentRecipientsEmails={this.props.recentRecipientsEmails}
                    selectedGrouping={this.props.selectedGrouping}
                    selectedPort={this.props.selectedPort}
                    setValue={this.props.setValue}
                    signatures={this.props.signatures}
                    selectedSignature={this.props.selectedSignature}
                    updateUserSettings={this.props.updateUserSettings}
                    visibleColumns={this.props.visibleColumns}
                    selectedExportVesselInfoConfig={this.props.selectedExportVesselInfoConfig}
                    vesselInfoConfigurableProperties={this.props.vesselInfoConfigurableProperties}
                />
                <div className="sten-content__header flex-row">
                    <div className="flex-shrink text-left">
                        <button
                            className="btn-link icon icon-arrow-left"
                            onClick={this.closeCompose}
                        />
                    </div>
                    <div className="flex-grow">
                        <h1 className="sten-content__title">
                            {translate('POSITION_LIST.COMPOSE.TITLE')}
                            <span className="sten-content__subtitle">
                                {TimeHelper.getFormatted()}
                            </span>
                        </h1>
                    </div>
                </div>
                <div className="sten-content__body">
                    <PositionListComposeTable
                        vessels={this.props.vessels}
                        groupedVessels={this.props.groupedVessels}
                        visibleColumns={this.props.visibleColumns}
                        selectedGrouping={this.props.selectedGrouping}
                        onRowReset={this.props.resetUpdatedFields}
                    />
                </div>
                <footer className="sten-content__footer">
                    <div className="flex-sm-grow col-xs-12">
                        {updatedCount > 0 && (
                            <div className="flex-row flex-center">
                                <div className="flex-shrink hide-xs text-nowrap">
                                    <span>
                                        {translate('POSITION_LIST.COMPOSE.EDITED_INFORMATION_FOR_VESSELS', {
                                            count: updatedCount
                                        }, true)}
                                    </span>
                                </div>
                                <div className="flex-sm-shrink flex-xs-grow">
                                    <button
                                        className="btn btn--secondary position-list__footer-confirm-button"
                                        onClick={this.props.resetUpdatedFields}
                                    >
                                        <span className="btn__icon icon icon-close" />
                                        {translate('POSITION_LIST.COMPOSE.RESET_CHANGES')}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="flex-sm-shrink col-xs-12">
                        <button className="btn btn--primary" onClick={this.openSendModal}>
                            {translate('POSITION_LIST.COMPOSE.SEND_POSITION_LIST')}
                        </button>
                    </div>
                </footer>
            </section>
        );
    }
}

PositionListCompose.propTypes = {
    email: PropTypes.objectOf(PropTypes.any).isRequired,
    fixtureStatuses: PropTypes.arrayOf(PropTypes.object).isRequired,
    getRecentRecipients: PropTypes.func.isRequired,
    getSignatureCompanies: PropTypes.func.isRequired,
    groupedVessels: PropTypes.objectOf(PropTypes.any).isRequired,
    isEmailModalShown: PropTypes.bool.isRequired,
    recentRecipientsEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
    resetAll: PropTypes.func.isRequired,
    resetUpdatedFields: PropTypes.func.isRequired,
    selectedExportVesselInfoConfig: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedGrouping: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedPort: PropTypes.objectOf(PropTypes.any),
    selectedSignature: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string
    }),
    sendPositionList: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    signatures: PropTypes.arrayOf(PropTypes.object).isRequired,
    toggleCompose: PropTypes.func.isRequired,
    toggleModal: PropTypes.func.isRequired,
    updateUserSettings: PropTypes.func.isRequired,
    vesselInfoConfigurableProperties: PropTypes.objectOf(PropTypes.any).isRequired,
    vessels: PropTypes.arrayOf(PropTypes.object).isRequired,
    visibleColumns: PropTypes.arrayOf(PropTypes.object).isRequired
};

PositionListCompose.defaultProps = {
    selectedPort: null,
    selectedSignature: null
};

const colWidths = { 1: 28, 2: 12, 3: 18, 4: 13, 5: 23, 6: 12, 8: 24, 9: 13, 10: 20, 12: 12 };

function mapStateToProps(state) {
    return {
        email: state.positionListComposeReducer.email,
        fixtureStatuses: state.positionListReducer.fixtureStatuses,
        groupedVessels: getExportGroupedVessels(state, getVessels(state)),
        isEmailModalShown: state.positionListComposeReducer.isEmailModalShown,
        recentRecipientsEmails: state.positionListComposeReducer.recentRecipientsEmails,
        selectedGrouping: getSelectedExportGrouping(state),
        selectedPort: state.positionListFiltersReducer.selectedPort,
        selectedSignature: getSelectedSignature(state),
        signatures: getSelectSignatureCompanies(state),
        selectedExportVesselInfoConfig: state.userReducer.settings
            && state.userReducer.settings.PositionListExportVesselProperties,
        vesselInfoConfigurableProperties: getVesselInfoConfigMap(state),
        vessels: getVessels(state),
        visibleColumns: getVisibleExportColumns(state, { colWidths })
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getRecentRecipients: () => getRecentRecipients(dispatch),
        getSignatureCompanies: () => getSignatureCompanies(dispatch),
        resetUpdatedFields: imo => resetUpdatedVessels(dispatch, imo),
        sendPositionList: (content, attachments) => sendPositionList(dispatch, content, attachments),
        setValue: (field, value) => setValue(dispatch, field, value),
        toggleCompose: flag => toggleCompose(dispatch, flag),
        toggleModal: flag => toggleModal(dispatch, flag),
        resetAll: () => resetAll(dispatch),
        updateUserSettings: (settings) => updateUserSettings(dispatch, settings)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PositionListCompose);
