import React from 'react';
import PropTypes from 'prop-types';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
import EditableListItem from './admin-company-management-editable-list-item';

class AdminCompanyManagementEditableList extends React.PureComponent {
    state = {
        activeItem: null
    };

    componentDidUpdate(prevProps) {
        if (typeof this.props.updatedItemIndex === 'number'
            && prevProps.updatedItemIndex !== this.props.updatedItemIndex
            && this.scrollArea) {
            const items = this.scrollArea.contentWrapperRef
                .getElementsByClassName('sten-admin-company-management-editable-list-item');
            const foundItemOffsetTop = items[this.props.updatedItemIndex].offsetTop;
            const foundItemOffsetHeight = items[this.props.updatedItemIndex].offsetHeight;
            if (foundItemOffsetTop + foundItemOffsetHeight
                > this.scrollArea.state.topPosition + this.scrollArea.state.containerHeight
                || foundItemOffsetTop < this.scrollArea.state.topPosition) {
                const newScrollTop = parseInt(foundItemOffsetTop
                    + (foundItemOffsetHeight / 2)
                    - (this.scrollArea.state.containerHeight / 2), 10);
                if (newScrollTop < 0) {
                    this.scrollArea.scrollTop();
                } else if (newScrollTop > this.scrollArea.state.realHeight - this.scrollArea.state.containerHeight) {
                    this.scrollArea.scrollBottom();
                } else {
                    this.scrollArea.scrollYTo(newScrollTop, true);
                }
            }
        }
    }

    validateUniqueName = {
        rule: (value) => !this.props.items.some((item) => (
            this.state.activeItem !== item
                && value.trim().toLowerCase() === item.Name.trim().toLowerCase()
        )),
        hint: () => 'Name should be unique'
    };

    validations = {
        required: true,
        minLength: 2,
        maxLength: 100,
        custom: this.validateUniqueName
    };

    handleModeChange = (mode, item, callback) => {
        if (mode && (!item || this.state.activeItem !== item)) {
            this.setState({ activeItem: item });
        }
        if (this.props.onModeChange) {
            this.props.onModeChange(mode, item, callback);
        } else {
            callback();
        }
    };

    saveScrollAreaRef = (c) => { this.scrollArea = c; };

    render() {
        return (
            <ScrollArea className="sten-admin-company-management-editable-list" ref={this.saveScrollAreaRef}>
                {this.props.items.map((item, index) => (
                    <EditableListItem
                        addBtnLabel={this.props.addBtnLabel}
                        removeConfirmMessage={this.props.removeConfirmMessage}
                        isRemovable={this.props.isRemovable}
                        key={item.Id}
                        index={index}
                        isActive={this.state.activeItem === item}
                        isUpdated={this.props.updatedItemIndex === index}
                        item={item}
                        onModeChange={this.handleModeChange}
                        onRemove={this.props.onItemRemove}
                        onUpdate={this.props.onItemUpdate}
                        validations={this.validations}
                    />
                ))}
                {this.props.onItemUpdate && (
                    <EditableListItem
                        addBtnLabel={this.props.addBtnLabel}
                        isActive={this.state.activeItem === null}
                        onUpdate={this.props.onItemUpdate}
                        onModeChange={this.handleModeChange}
                        validations={this.validations}
                    />
                )}
            </ScrollArea>
        );
    }
}

AdminCompanyManagementEditableList.propTypes = {
    addBtnLabel: PropTypes.string,
    isRemovable: PropTypes.bool,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    onItemRemove: PropTypes.func,
    onItemUpdate: PropTypes.func,
    onModeChange: PropTypes.func,
    removeConfirmMessage: PropTypes.node,
    updatedItemIndex: PropTypes.number
};

AdminCompanyManagementEditableList.defaultProps = {
    addBtnLabel: EditableListItem.defaultProps.addBtnLabel,
    isRemovable: EditableListItem.defaultProps.isRemovable,
    onItemRemove: undefined,
    onItemUpdate: undefined,
    onModeChange: undefined,
    removeConfirmMessage: EditableListItem.defaultProps.removeConfirmMessage,
    updatedItemIndex: null
};

export default AdminCompanyManagementEditableList;
