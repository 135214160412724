import React from 'react';
import PropTypes from 'prop-types';

export default class HtecTimePickerBox extends React.PureComponent {
    componentWillUnmount() {
        this.clearIntervalsAndTimeouts();
    }

    clearIntervalsAndTimeouts() {
        if (this.mouseHoldTimeout) {
            clearTimeout(this.mouseHoldTimeout);
        }
        if (this.mouseHoldInterval) {
            clearInterval(this.mouseHoldInterval);
        }
    }

    isDirectionDisabled = (direction) => {
        return (direction > 0 && this.props.isIncreaseDisabled) || (direction < 0 && this.props.isDecreaseDisabled);
    };

    handleChange = (direction) => {
        if (this.isDirectionDisabled(direction)) {
            return;
        }
        if (direction > 0) {
            this.props.onIncrease();
        } else {
            this.props.onDecrease();
        }
    };

    handleBtnMouseDown = (e, direction) => {
        if (this.isDirectionDisabled(direction) || (e && e.button !== 0)) {
            return;
        }
        this.handleChange(direction);
        if (this.mouseHoldTimeout) {
            clearTimeout(this.mouseHoldTimeout);
        }
        this.mouseHoldTimeout = setTimeout(() => {
            if (this.mouseHoldInterval) {
                clearInterval(this.mouseHoldInterval);
            }
            this.mouseHoldInterval = setInterval(() => {
                this.handleChange(direction);
            }, this.props.mouseHoldInterval);
        }, this.props.mouseHoldTimeout);
    };

    handleBtnMouseUp = () => {
        this.clearIntervalsAndTimeouts();
    };

    handleIncrease = (e) => this.handleBtnMouseDown(e, 1);

    handleDecrease = (e) => this.handleBtnMouseDown(e, -1);

    includeZeroPrefix = (num) => {
        if (typeof num !== 'number') { return null; }
        if (num < 10) {
            return `0${num}`;
        }
        return num;
    };

    handleWheel = (e) => {
        if (!this.props.isDisabled) {
            e.stopPropagation();
            this.handleChange(-1 * e.deltaY);
        }
    };

    render = () => {
        let increaseButtonClass = 'htec-dp-time-picker-box__btn htec-dp-time-picker-box__btn--increase';
        if (this.props.isIncreaseDisabled) {
            increaseButtonClass += ' htec-dp-time-picker-box__btn--disabled';
        }
        let decreaseButtonClass = 'htec-dp-time-picker-box__btn htec-dp-time-picker-box__btn--decrease';
        if (this.props.isDecreaseDisabled) {
            decreaseButtonClass += ' htec-dp-time-picker-box__btn--disabled';
        }
        return (
            <div
                className="htec-dp-time-picker-box"
                onWheel={this.handleWheel}
                title={this.props.title}
                data-unit={this.props.unit}
            >
                {!this.props.isDisabled && (
                    <React.Fragment>
                        <button
                            onMouseDown={this.handleIncrease}
                            onMouseUp={this.handleBtnMouseUp}
                            onMouseLeave={this.handleBtnMouseUp}
                            className={increaseButtonClass}
                        >
                            {this.props.increaseBtn}
                        </button>
                        <button
                            onMouseDown={this.handleDecrease}
                            onMouseUp={this.handleBtnMouseUp}
                            onMouseLeave={this.handleBtnMouseUp}
                            className={decreaseButtonClass}
                        >
                            {this.props.decreaseBtn}
                        </button>
                    </React.Fragment>
                )}
                {typeof this.props.value === 'number' ? this.includeZeroPrefix(this.props.value) : '--' }
            </div>
        );
    }
}

HtecTimePickerBox.propTypes = {
    decreaseBtn: PropTypes.node,
    increaseBtn: PropTypes.node,
    isDecreaseDisabled: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isIncreaseDisabled: PropTypes.bool,
    mouseHoldInterval: PropTypes.number,
    mouseHoldTimeout: PropTypes.number,
    onDecrease: PropTypes.func.isRequired,
    onIncrease: PropTypes.func.isRequired,
    title: PropTypes.string,
    unit: PropTypes.string,
    value: PropTypes.number
};

HtecTimePickerBox.defaultProps = {
    decreaseBtn: '▼',
    increaseBtn: '▲',
    isDecreaseDisabled: false,
    isDisabled: false,
    isIncreaseDisabled: false,
    mouseHoldInterval: 50,
    mouseHoldTimeout: 500,
    title: '',
    unit: '',
    value: null
};
