import React from 'react';
import PropTypes from 'prop-types';
import DatePickerModeDays from './date-picker-mode-days';
import DatePickerModeMonths from './date-picker-mode-months';
import DatePickerModeYears from './date-picker-mode-years';
import { DateShape } from '../types';
/* styles */
import './date-picker.scss';

export default class DatePicker extends React.PureComponent {
    constructor(props) {
        super(props);
        const currentDate = new Date();
        let currentMonth = null;
        let currentYear = null;
        if (this.props.initialMonth) {
            currentYear = this.props.initialMonth.year;
            currentMonth = this.props.initialMonth.month;
        } else if (this.props.selectedDate) {
            currentYear = this.props.selectedDate.year;
            currentMonth = this.props.selectedDate.month;
        } else {
            currentYear = currentDate.getFullYear();
            currentMonth = currentDate.getMonth() + 1;
        }
        this.state = {
            today: props.today || {
                day: currentDate.getDate(),
                month: currentDate.getMonth() + 1,
                year: currentDate.getFullYear()
            },
            selectedDate: this.props.selectedDate,
            mode: this.props.mode,
            currentYear,
            currentMonth
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.selectedDate && props.selectedDate !== state.selectedDate) {
            return {
                currentYear: props.selectedDate.year,
                currentMonth: props.selectedDate.month,
                selectedDate: props.selectedDate
            };
        }
        return null;
    }

    switchActiveView = () => {
        if (!this.props.canSwitchMode) { return; }
        if (this.state.mode === 'days') {
            this.setState({ mode: 'months' });
        } else if (this.state.mode === 'months') {
            this.setState({ mode: 'years' });
        }
    };

    onCellClick = args => {
        if (!this.props.onDateSelect) { return; }
        if (this.state.mode === 'days') {
            this.props.onDateSelect({ day: args.day, month: args.month, year: args.year });
        } else if (this.state.mode === 'months') {
            this.setState({
                mode: 'days',
                currentMonth: args.month,
                currentYear: args.year
            });
        } else {
            this.setState({
                mode: 'months',
                currentYear: args.year
            });
        }
    };

    updateYear = year => {
        this.setState({
            currentYear: year
        });
    };

    updateMonth = month => {
        this.setState({
            currentMonth: month
        });
    };

    render = () => {
        return (
            <div className="htec-dp-date-picker">
                {this.state.mode === 'days' && (
                    <DatePickerModeDays
                        alwaysShow6Weeks={this.props.alwaysShow6Weeks}
                        disableMonthsOutOfRange={this.props.disableMonthsOutOfRange}
                        currentMonth={this.state.currentMonth}
                        currentYear={this.state.currentYear}
                        hideOutsideDays={this.props.hideOutsideDays}
                        maxDate={this.props.maxDate}
                        minDate={this.props.minDate}
                        nextBtn={this.props.nextBtn}
                        onCellClick={this.onCellClick}
                        onPanelTitleClick={this.switchActiveView}
                        prevBtn={this.props.prevBtn}
                        renderWeekDays={this.props.renderWeekDays}
                        selectedDate={this.props.selectedDate}
                        today={this.state.today}
                        updateMonth={this.updateMonth}
                        updateYear={this.updateYear}
                        weekStart={this.props.weekStart}
                    />
                )}
                {this.state.mode === 'months' && (
                    <DatePickerModeMonths
                        currentMonth={this.state.currentMonth}
                        currentYear={this.state.currentYear}
                        disableYearsOutOfRange={this.props.disableMonthsOutOfRange}
                        maxDate={this.props.maxDate}
                        minDate={this.props.minDate}
                        nextBtn={this.props.nextBtn}
                        onCellClick={this.onCellClick}
                        onPanelTitleClick={this.switchActiveView}
                        prevBtn={this.props.prevBtn}
                        selectedDate={this.props.selectedDate}
                        today={this.state.today}
                    />
                )}
                {this.state.mode === 'years' && (
                    <DatePickerModeYears
                        currentYear={this.state.currentYear}
                        disableYearsOutOfRange={this.props.disableMonthsOutOfRange}
                        maxDate={this.props.maxDate}
                        minDate={this.props.minDate}
                        nextBtn={this.props.nextBtn}
                        onCellClick={this.onCellClick}
                        prevBtn={this.props.prevBtn}
                        selectedDate={this.props.selectedDate}
                        today={this.state.today}
                    />
                )}
            </div>
        );
    }
}

DatePicker.propTypes = {
    alwaysShow6Weeks: PropTypes.bool,
    canSwitchMode: PropTypes.bool,
    disableMonthsOutOfRange: PropTypes.bool,
    hideOutsideDays: PropTypes.bool,
    initialMonth: PropTypes.shape({ month: PropTypes.number, year: PropTypes.number }),
    maxDate: PropTypes.shape(DateShape),
    minDate: PropTypes.shape(DateShape),
    mode: PropTypes.oneOf(['days', 'months', 'years']),
    nextBtn: PropTypes.node,
    onDateSelect: PropTypes.func.isRequired,
    prevBtn: PropTypes.node,
    renderDay: PropTypes.func,
    renderMonth: PropTypes.func,
    renderWeekDays: PropTypes.func,
    renderYear: PropTypes.func,
    selectedDate: PropTypes.shape(DateShape),
    today: PropTypes.shape(DateShape),
    weekStart: PropTypes.oneOf(['Monday', 'Sunday'])
};

DatePicker.defaultProps = {
    alwaysShow6Weeks: false,
    canSwitchMode: true,
    disableMonthsOutOfRange: false,
    hideOutsideDays: false,
    initialMonth: null,
    maxDate: null,
    minDate: null,
    mode: 'days',
    nextBtn: undefined,
    prevBtn: undefined,
    renderDay: undefined,
    renderMonth: undefined,
    renderWeekDays: undefined,
    renderYear: undefined,
    selectedDate: null,
    today: undefined,
    weekStart: undefined
};
