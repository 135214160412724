/* actions */
import { ActionTypes } from './inspections-actions';
/* selectors */
import { sortInspections } from './inspections-selectors';
/* utils */
import { translate } from 'utils/i18n/i18n-model';

const viewOptions = [
    { Id: 0, Name: translate('INSPECTIONS.FILTERS.ALL_INSPECTIONS') },
    { Id: 1, Name: translate('INSPECTIONS.FILTERS.LAST_INSPECTION') },
    { Id: 2, Name: translate('INSPECTIONS.FILTERS.LAST_INSPECTIONS', { num: 2 }) },
    { Id: 3, Name: translate('INSPECTIONS.FILTERS.LAST_INSPECTIONS', { num: 3 }) }
];

const emptyArray = [];

const initialState = {
    companies: emptyArray,
    segments: emptyArray,
    inspectionTypes: emptyArray,
    inspectionActivities: emptyArray,
    viewOptions,
    selectedFilterOptions: {
        vessel: '',
        inspectionRange: { from: null, to: null },
        companies: emptyArray,
        segments: emptyArray,
        viewOption: viewOptions[0],
        headOwner: null,
        technicalManager: null,
        inspectionType: null
    },
    headOwners: emptyArray,
    technicalManagers: emptyArray,
    inspections: emptyArray,
    selectedInspections: {},
    sortProp: 'Date',
    sortOrder: 'DESC'
};

export default function inspectionsTableReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.INSPECTIONS_SET_OPTIONS: {
        return {
            ...state,
            segments: action.segments,
            companies: action.companies,
            inspectionTypes: action.inspectionTypes,
            inspectionActivities: action.inspectionActivities,
            headOwners: action.headOwners,
            technicalManagers: action.technicalManagers
        };
    }
    case ActionTypes.INSPECTIONS_SET_SELECTED_FILTER_OPTION: {
        if (action.option === 'reset') {
            return {
                ...state,
                selectedFilterOptions: initialState.selectedFilterOptions
            };
        }
        let sortProps = null;
        const selectedFilterOptions = { ...state.selectedFilterOptions };
        if (action.option && selectedFilterOptions[action.option] !== undefined) {
            selectedFilterOptions[action.option] = action.value;
        }
        if (action.option === 'viewOption' && action.value) {
            sortProps = action.value.Id === 0
                ? { sortProp: 'Date', sortOrder: 'DESC' }
                : { sortProp: 'Vessel.Title', sortOrder: 'ASC' };
        }
        return {
            ...state,
            selectedFilterOptions,
            ...sortProps
        };
    }
    case ActionTypes.INSPECTIONS_SET_INSPECTIONS: {
        return {
            ...state,
            inspections: action.inspections
        };
    }
    case ActionTypes.INSPECTIONS_SET_SORT_PROP: {
        if (state.sortProp === action.sortProp) {
            let sortOrder;
            if (state.sortOrder === 'ASC') {
                sortOrder = 'DESC';
            } else {
                sortOrder = 'ASC';
            }
            return {
                ...state,
                sortOrder
            };
        }
        return {
            ...state,
            sortProp: action.sortProp,
            sortOrder: 'ASC'
        };
    }
    case ActionTypes.INSPECTIONS_TOGGLE_SELECTED_INSPECTION: {
        const newSelectedInspections = { ...state.selectedInspections };
        if (newSelectedInspections[action.inspectionId]) {
            delete newSelectedInspections[action.inspectionId];
        } else {
            newSelectedInspections[action.inspectionId] = true;
        }
        return {
            ...state,
            selectedInspections: newSelectedInspections
        };
    }
    case ActionTypes.INSPECTIONS_SET_SELECTED_INSPECTIONS: {
        return {
            ...state,
            selectedInspections: action.selectedInspections
        };
    }
    case ActionTypes.INSPECTIONS_UPDATE_INSPECTION: {
        const foundIndex = state.inspections.findIndex(inspection => inspection.Id === action.inspection.Id);
        const inspections = state.inspections.slice();
        if (foundIndex > -1) {
            inspections.splice(foundIndex, 1, action.inspection);
        } else {
            inspections.push(action.inspection);
        }
        return {
            ...state,
            inspections: sortInspections(inspections, initialState.sortProp, initialState.sortOrder)
        };
    }
    case ActionTypes.INSPECTIONS_RESET_ALL: {
        return initialState;
    }
    default:
        return state;
    }
}
