const formats = {
    date: {
        'DD/MM/YYYY': {
            regex: /^(\d{1,2})\/(\d{1,2})\/(\d{2,4})$/,
            matches: ['', 'day', 'month', 'year']
        },
        'MM/DD/YYYY': {
            regex: /^(\d{1,2})\/(\d{1,2})\/(\d{2,4})$/,
            matches: ['', 'month', 'day', 'year']
        },
        'YYYY-MM-DD': {
            regex: /^(\d{2,4})-(\d{1,2})-(\d{1,2})$/,
            matches: ['', 'year', 'month', 'day']
        }
    },
    time: {
        HH: {
            regex: /^\d{1,2}$/,
            matches: ['hour']
        },
        'HH:mm': {
            regex: /^(\d{1,2}):(\d{1,2})$/,
            matches: ['', 'hour', 'minute']
        },
        'HH:mm:ss': {
            regex: /^(\d{1,2}):(\d{1,2}):(\d{1,2})$/,
            matches: ['', 'hour', 'minute', 'second']
        }
    }
};

export default formats;
