import CoreApi from './core-api';

class InquiryService {
    constructor() {
        this.resourceUrl = 'Contact';
    }

    sendContactInquiryData(data) {
        return CoreApi.post(`${this.resourceUrl}/EmailSupport`, data, true)
            .then(() => true, () => false);
    }

    sendCargoInquiryData(data) {
        return CoreApi.post(`${this.resourceUrl}/CargoInquiry`, data)
            .then(() => true, () => false);
    }
}

export default new InquiryService();
