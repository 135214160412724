class PointCanvas {
    constructor() {
        this.cached = {};
        this.checksumProps = [
            'type',
            'primaryColor',
            'secondaryColor',
            'backgroundColor',
            'opacity',
            'fill',
            'isActive',
            'isHovered'
        ];
    }

    getChecksum(options, sizeMultiplier) {
        let checksum = '';
        this.checksumProps.forEach((prop) => {
            checksum += options[prop];
        });
        checksum += sizeMultiplier;
        return checksum;
    }

    get(options, sizeMultiplier) {
        const checksum = this.getChecksum(options, sizeMultiplier);
        if (this.cached[checksum]) {
            return this.cached[checksum];
        }
        let canvas = null;
        switch (options.type) {
        case 'vessel':
            canvas = this.getVesselCanvas(options, sizeMultiplier);
            break;
        case 'port':
            canvas = this.getPortCanvas(options, sizeMultiplier);
            break;
        case 'point':
            canvas = this.getPointCanvas(options, sizeMultiplier);
            break;
        case 'drill':
            canvas = this.getDrillCanvas(options, sizeMultiplier);
            break;
        case 'pin':
            canvas = this.getPinCanvas(options, sizeMultiplier);
            break;
        case 'dot':
            canvas = this.getDotCanvas(options, sizeMultiplier);
            break;
        case 'report':
            canvas = this.getVesselCanvas(options, sizeMultiplier);
            break;
        default:
            canvas = null;
        }

        this.cached[checksum] = canvas;
        return canvas;
    }

    getVesselCanvas(options, sizeMultiplier) {
        const size = 40 * sizeMultiplier;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        const drawVesselOnCanvas = (
            scale = 1,
            color = options.primaryColor,
            fill = options.fill,
            withShadow = false
        ) => {
            const strokeWidth = 1.5 * sizeMultiplier * scale;
            const radiusOut = 16 * sizeMultiplier;
            const radiusIn = 13 * sizeMultiplier;
            const halfSizeRender = size / 2;

            let radiusTop = (7 * sizeMultiplier) + strokeWidth;
            let radiusEdge = (7 * sizeMultiplier) + strokeWidth;
            let radiusMiddle = 2 * sizeMultiplier;

            let angle0;

            context.translate(0, size / 2);
            context.beginPath();

            context.lineWidth = strokeWidth;

            if (withShadow) {
                context.shadowColor = '#000';
                context.shadowBlur = 3;
            }

            if (fill) {
                radiusTop = (8 * sizeMultiplier) + strokeWidth;
                radiusEdge = (8 * sizeMultiplier) + strokeWidth;
                radiusMiddle = 3 * sizeMultiplier;
                context.lineWidth = scale;
                context.fillStyle = color;
            } else {
                context.fillStyle = 'transparent';
            }

            context.globalAlpha = options.opacity;

            /**
             * top point
             * */
            angle0 = -Math.PI / 2;
            context.lineTo((size / 2) + (radiusTop * Math.cos(angle0)),
                (size / 2) + ((radiusTop * Math.sin(angle0)) - (size / 2)));

            /**
             * bottom right point
             */
            angle0 = 0.27 * Math.PI;
            context.lineTo((size / 2) + (radiusEdge * Math.cos(angle0)),
                (size / 2) + ((radiusEdge * Math.sin(angle0)) - (size / 2)));

            /**
             * bottom middle point
             */
            angle0 = Math.PI / 2;
            context.lineTo((size / 2) + (radiusMiddle * Math.cos(angle0)),
                (size / 2) + ((radiusMiddle * Math.sin(angle0)) - (size / 2)));

            /**
             * bottom left point
             */
            angle0 = 0.73 * Math.PI;
            context.lineTo((size / 2) + (radiusEdge * Math.cos(angle0)),
                (size / 2) + ((radiusEdge * Math.sin(angle0)) - (size / 2)));

            context.strokeStyle = color;

            context.closePath();

            context.stroke();

            if (fill) {
                context.fill();
            }

            context.translate(0, -size / 2);

            if (options.isActive) {
                if (withShadow) {
                    context.shadowColor = '#000';
                    context.shadowBlur = 3;
                }

                context.lineWidth = 1.6 * sizeMultiplier * scale;

                // draws a outer circle
                context.strokeStyle = color;

                context.beginPath();
                context.arc(halfSizeRender, halfSizeRender, radiusOut, 0.55 * Math.PI, 1.55 * Math.PI, false);
                context.stroke();

                context.beginPath();
                context.arc(halfSizeRender, halfSizeRender, radiusOut, 1.8 * Math.PI, 0.3 * Math.PI, false);
                context.stroke();

                // draws an inner circle
                context.lineWidth = 1.2 * sizeMultiplier;
                context.strokeStyle = color;

                context.beginPath();
                context.arc(halfSizeRender, halfSizeRender, radiusIn, 0.35 * Math.PI, 0.85 * Math.PI, false);
                context.stroke();

                context.beginPath();
                context.arc(halfSizeRender, halfSizeRender, radiusIn, 1.1 * Math.PI, 0.1 * Math.PI, false);
                context.stroke();
            }
        };

        drawVesselOnCanvas(1.4, '#000', false, true);
        drawVesselOnCanvas(1.2);

        return canvas;
    }

    getPortCanvas(options, sizeMultiplier) {
        const size = 40 * sizeMultiplier;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        context.globalAlpha = options.opacity;
        context.shadowColor = '#000';
        context.shadowBlur = 3;

        const radius = 9 * sizeMultiplier;
        const dotWidth = 4 * sizeMultiplier;

        const halfSize = size / 2;

        const drawOuterCircle = (scale = 1, color = options.secondaryColor) => {
            context.lineWidth = 2 * sizeMultiplier * scale;
            context.strokeStyle = color;

            context.beginPath();
            context.arc(halfSize, halfSize, radius, 0, 2 * Math.PI, false);
            context.stroke();

            if (options.backgroundColor) {
                context.fillStyle = options.backgroundColor;
                context.fill();
            }
        };

        if (options.secondaryColor) {
            drawOuterCircle(1.2, '#000');
            drawOuterCircle();
        }

        // draws central dot
        context.fillStyle = options.primaryColor;
        context.strokeStyle = '#000';
        context.lineWidth = 1 * sizeMultiplier;
        context.beginPath();
        context.arc(halfSize, halfSize, dotWidth, 0, 2 * Math.PI, true);
        context.stroke();
        context.fill();

        return canvas;
    }

    getPointCanvas(options, sizeMultiplier) {
        const size = 40 * sizeMultiplier;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        context.globalAlpha = options.opacity;

        const rectSize = 20 * sizeMultiplier;
        const dotWidth = 4 * sizeMultiplier;

        const halfSize = size / 2;

        const drawPointOnCanvas = (
            scale = 1,
            primaryColor = options.primaryColor,
            secondaryColor = '#fff',
            withShadow = false
        ) => {
            context.lineWidth = 2 * sizeMultiplier * scale;
            if (withShadow) {
                context.shadowColor = '#000';
                context.shadowBlur = 3;
            }

            // draws outer rectangle
            context.strokeStyle = secondaryColor;
            context.beginPath();
            context.setLineDash([2 * sizeMultiplier, 2 * sizeMultiplier]);
            context.rect((size - rectSize) / 2, (size - rectSize) / 2, rectSize, rectSize);
            context.stroke();
            if (options.backgroundColor) {
                context.fillStyle = options.backgroundColor;
                context.fill();
            }

            // draws central dot
            context.strokeStyle = primaryColor;
            context.beginPath();
            context.setLineDash([]);
            context.arc(halfSize, halfSize, dotWidth, 0, 2 * Math.PI, true);
            context.stroke();
        };

        drawPointOnCanvas(1.2, '#000', '#000', true);
        drawPointOnCanvas();

        return canvas;
    }

    getDrillCanvas(options, sizeMultiplier) {
        const size = 40 * sizeMultiplier;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        const radiusOut = 16 * sizeMultiplier;
        const radiusIn = 13 * sizeMultiplier;
        const halfSize = size / 2;

        if (!options.isHovered) {
            context.globalAlpha = options.opacity;
        }

        // Draw rhombus
        const width = size / 3;
        const height = size / 3;
        const x = size / 2;
        const y = (size / 2) - (height / 2);

        context.shadowColor = '#000';
        context.shadowBlur = 2;

        context.beginPath();
        context.moveTo(x, y);

        // top left edge
        context.lineTo(x - (width / 2), y + (height / 2));

        // bottom left edge
        context.lineTo(x, y + height);

        // bottom right edge
        context.lineTo(x + (width / 2), y + (height / 2));

        // the top right edge
        context.closePath();

        context.fillStyle = options.primaryColor;
        context.fill();

        if (options.isActive) {
            context.lineWidth = 1.6 * sizeMultiplier;

            // draws a outer circle
            context.shadowColor = options.shadowColor;
            context.strokeStyle = options.primaryColor;

            context.beginPath();
            context.arc(halfSize, halfSize, radiusOut, 0.55 * Math.PI, 1.55 * Math.PI, false);
            context.stroke();

            context.beginPath();
            context.arc(halfSize, halfSize, radiusOut, 1.8 * Math.PI, 0.3 * Math.PI, false);
            context.stroke();

            // draws an inner circle
            context.lineWidth = 1.2 * sizeMultiplier;
            context.strokeStyle = options.primaryColor;

            context.beginPath();
            context.arc(halfSize, halfSize, radiusIn, 0.35 * Math.PI, 0.85 * Math.PI, false);
            context.stroke();

            context.beginPath();
            context.arc(halfSize, halfSize, radiusIn, 1.1 * Math.PI, 0.1 * Math.PI, false);
            context.stroke();
        }
        return canvas;
    }

    getPinCanvas(options, sizeMultiplier) {
        const size = 40 * sizeMultiplier;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        const halfSize = size / 2;
        const innerRadius = 2.5 * sizeMultiplier;
        const radius = 10 * sizeMultiplier;
        const top = [halfSize, halfSize - radius];
        const topLeft = [halfSize - radius, halfSize - radius];
        const bottomLeft = [halfSize - radius, halfSize];
        const topRight = [halfSize + radius, halfSize - radius];
        const bottomRight = [halfSize + radius, halfSize];
        const bottom = [halfSize, halfSize + radius];

        context.globalAlpha = options.opacity;
        context.shadowColor = '#000';
        context.shadowBlur = 3;

        // Pin body
        context.fillStyle = options.primaryColor;
        context.beginPath();
        context.moveTo(top[0], top[1]);
        context.bezierCurveTo(topLeft[0], topLeft[1], bottomLeft[0], bottomLeft[1], bottom[0], bottom[1]);
        context.bezierCurveTo(bottomRight[0], bottomRight[1], topRight[0], topRight[1], top[0], top[1]);
        context.arc(halfSize, halfSize - (radius / 4), innerRadius, 0, 2 * Math.PI);
        context.closePath();
        context.fill();

        return canvas;
    }

    getDotCanvas(options, sizeMultiplier) {
        const size = 40 * sizeMultiplier;
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        context.globalAlpha = options.opacity;
        context.shadowColor = '#000';
        context.shadowBlur = 3;

        const dotWidth = 4 * sizeMultiplier;
        const halfSize = size / 2;

        // draws central dot
        context.fillStyle = options.primaryColor;
        context.strokeStyle = '#000';
        context.lineWidth = 1 * sizeMultiplier;
        context.beginPath();
        context.arc(halfSize, halfSize, dotWidth, 0, 2 * Math.PI, true);
        context.stroke();
        context.fill();

        return canvas;
    }
}

export default new PointCanvas();
