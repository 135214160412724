import React from 'react';
import PropTypes from 'prop-types';
/* helpers */
import { formatValue } from 'utils/helpers/info-helper';
/* styles */
import './voyage-performance-sub-panel.scss';
/* constants */
import { ballastLadenTotalRows } from '../../voyage-performance-constants';

class SubPanel extends React.PureComponent {
    render() {
        const { data } = this.props;
        return (
            <div className="sten-panel sten-panel--sub sten-voyage-performance-sub-panel">
                <div className="sten-panel__header">
                    <h4 className="sten-panel__title">{this.props.title}</h4>
                </div>
                <div className="sten-panel__body">
                    <div className="sten-voyage-performance-sub-panel__box">
                        <div className="sten-voyage-performance-sub-panel__label text-secondary">
                            {ballastLadenTotalRows[0].label}
                        </div>
                        <h1 className="sten-voyage-performance-sub-panel__value">
                            {formatValue(data, ballastLadenTotalRows[0])}
                        </h1>
                        <div className="sten-voyage-performance-sub-panel__label">
                            <span className="text-secondary">{`${ballastLadenTotalRows[1].label}: `}</span>
                            {formatValue(data, ballastLadenTotalRows[1])}
                        </div>
                    </div>
                    <div className="sten-voyage-performance-sub-panel__box">
                        <div className="sten-voyage-performance-sub-panel__label text-secondary">
                            {ballastLadenTotalRows[2].label}
                        </div>
                        <h1 className="sten-voyage-performance-sub-panel__value">
                            {formatValue(data, ballastLadenTotalRows[2])}
                        </h1>
                        <div className="sten-voyage-performance-sub-panel__label">
                            <span className="text-secondary">{`${ballastLadenTotalRows[3].label}: `}</span>
                            {formatValue(data, ballastLadenTotalRows[3])}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SubPanel.propTypes = {
    data: PropTypes.objectOf(PropTypes.any),
    title: PropTypes.string.isRequired
};

SubPanel.defaultProps = {
    data: null
};

export default SubPanel;
