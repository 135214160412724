import { ActionTypes } from './admin-vessel-management-actions';

const emptyArray = [];

const initialState = {
    isMounted: false,
    activeVesselImo: null,
    fleets: emptyArray,
    navFleets: emptyArray,
    isUpdateModeActive: false,
    segments: emptyArray,
    tradingAreas: emptyArray,
    areFiltersVisible: true,
    filterUpdated: false,
    selectedSegments: emptyArray,
    searchCriteria: '',
    isFetchingData: false,
    limit: 20,
    offset: 0,
    vessels: emptyArray,
    vesselTypes: emptyArray,
    vesselsAllFetched: false,
    sortProp: 'Title',
    sortOrder: 'ASC'
};

export default function adminVesselManagementReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_SET_MOUNTED: {
        return {
            ...state,
            isMounted: action.isMounted
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_SET_SEGMENTS: {
        return {
            ...state,
            segments: action.segments
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_SET_TRADING_AREAS: {
        return {
            ...state,
            tradingAreas: action.tradingAreas
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_SET_VESSEL_TYPES: {
        return {
            ...state,
            vesselTypes: action.vesselTypes
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_SET_FLEETS:
        return {
            ...state,
            fleets: action.fleets || emptyArray,
            navFleets: action.navFleets || emptyArray
        };
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_TOGGLE_UPDATE_MODE: {
        return {
            ...state,
            activeVesselImo: action.vesselImo,
            isUpdateModeActive: action.isActive
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_RESET_ALL: {
        return initialState;
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_TABLE_SET_FETCHING_DATA: {
        return {
            ...state,
            isFetchingData: action.isFetchingData
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_TABLE_ADD_VESSELS: {
        let newVessels;
        let offset = 0;
        if (action.overrideCurrent) {
            newVessels = action.vessels;
        } else {
            newVessels = state.vessels.concat(action.vessels);
            offset = state.offset;
        }
        offset += state.limit;
        const vesselsAllFetched = action.allFetched;
        return {
            ...state,
            vessels: newVessels,
            offset,
            vesselsAllFetched,
            isFetchingData: false
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_TABLE_ADD_VESSEL: {
        const vessels = state.vessels.slice();
        const importedVessel = action.importedVessel;
        importedVessel.isHighlighted = true;
        vessels.unshift(importedVessel);
        return {
            ...state,
            vessels
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_TABLE_REMOVE_VESSEL: {
        const vessels = state.vessels.filter((vessel) => vessel.IMO !== action.imo);
        return {
            ...state,
            vessels
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_TABLE_SET_SORT_PROP: {
        if (state.sortProp === action.sortProp) {
            let sortOrder;
            if (state.sortOrder === 'ASC') {
                sortOrder = 'DESC';
            } else {
                sortOrder = 'ASC';
            }
            return {
                ...state,
                sortOrder
            };
        }
        return {
            ...state,
            sortProp: action.sortProp,
            sortOrder: 'ASC'
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_TABLE_SET_OFFSET: {
        return {
            ...state,
            offset: action.offset
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_TABLE_RESET_ALL: {
        return initialState;
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_FILTERS_TOGGLE:
        return {
            ...state,
            areFiltersVisible: !state.areFiltersVisible
        };
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_FILTERS_UPDATE_SEARCH_CRITERIA: {
        return {
            ...state,
            searchCriteria: action.searchCriteria,
            filterUpdated: true
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_FILTERS_SET_SELECTED_SEGMENTS: {
        return {
            ...state,
            selectedSegments: action.selectedSegments,
            filterUpdated: true
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_FILTERS_UPDATE_LOADED: {
        return {
            ...state,
            filterUpdated: false
        };
    }
    case ActionTypes.ADMIN_VESSEL_MANAGEMENT_FILTERS_RESET_ALL: {
        return {
            ...state,
            areFiltersVisible: initialState.areFiltersVisible,
            selectedSegments: initialState.selectedSegments,
            searchCriteria: initialState.searchCriteria,
            filterUpdated: true
        };
    }
    default:
        return state;
    }
}
