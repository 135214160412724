export default [
    {
        DWT: 158491.0,
        Imo: 5000005,
        ImoClass: null,
        Title: 'Canal',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false,
        InclusionCompanyId: null,
        OwnerId: 16729,
        TechnicalManagerId: 655,
        CompanyFleetsIds: [
            9,
            7
        ],
        CustomFleetsIds: [
            583,
            637,
            582
        ]
    },
    {
        DWT: 159158.0,
        Imo: 3000001,
        ImoClass: 'N/A',
        Title: 'Bowery',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false,
        InclusionCompanyId: null,
        OwnerId: 16729,
        TechnicalManagerId: 655,
        CompanyFleetsIds: [
            9,
            7
        ],
        CustomFleetsIds: [
            637,
            582
        ]
    },
    {
        DWT: 49718.0,
        Imo: 2000004,
        ImoClass: '2',
        Title: 'Ludlow',
        TotalCubicCapacity: null,
        VesselTypeId: 22,
        VesselTypeName: 'MR',
        IsCompetition: false,
        InclusionCompanyId: null,
        OwnerId: 16729,
        TechnicalManagerId: 655,
        CompanyFleetsIds: [
            9,
            7
        ],
        CustomFleetsIds: [
            637,
            582
        ]
    },
    {
        DWT: 159034.0,
        Imo: 4000008,
        ImoClass: 'N/A',
        Title: 'Spring',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false,
        InclusionCompanyId: null,
        OwnerId: 16729,
        TechnicalManagerId: 655,
        CompanyFleetsIds: [
            9,
            7
        ],
        CustomFleetsIds: [
            637,
            582
        ]
    },
    {
        DWT: 49750.0,
        Imo: 1000007,
        ImoClass: '2',
        Title: 'Bedford',
        TotalCubicCapacity: null,
        VesselTypeId: 22,
        VesselTypeName: 'MR',
        IsCompetition: false,
        InclusionCompanyId: null,
        OwnerId: 16729,
        TechnicalManagerId: 655,
        CompanyFleetsIds: [
            9,
            7
        ],
        CustomFleetsIds: [
            536,
            582,
            637
        ]
    },
    {
        DWT: 49719.0,
        Imo: 9667473,
        ImoClass: '2,3',
        Title: 'Stena Image DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 22,
        VesselTypeName: 'MR',
        IsCompetition: false,
        InclusionCompanyId: 7,
        OwnerId: 18965,
        TechnicalManagerId: 655,
        CompanyFleetsIds: [
            7
        ],
        CustomFleetsIds: [
            581
        ]
    },
    {
        DWT: 159158.0,
        Imo: 9579042,
        ImoClass: 'N/A',
        Title: 'Stena Suede DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false,
        InclusionCompanyId: 7,
        OwnerId: null,
        TechnicalManagerId: 655,
        CompanyFleetsIds: [
            7
        ],
        CustomFleetsIds: [
            581
        ]
    },
    {
        DWT: 159039.0,
        Imo: 9585900,
        ImoClass: 'N/A',
        Title: 'AST Sunshine DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false,
        InclusionCompanyId: 7,
        OwnerId: null,
        TechnicalManagerId: 655,
        CompanyFleetsIds: [
            7
        ],
        CustomFleetsIds: [
            637,
            536,
            581
        ]
    },
    {
        DWT: 49683.0,
        Imo: 9797400,
        ImoClass: '2',
        Title: 'Stena Impero DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 22,
        VesselTypeName: 'MR',
        IsCompetition: false,
        InclusionCompanyId: 7,
        OwnerId: 17377,
        TechnicalManagerId: 655,
        CompanyFleetsIds: [
            7
        ],
        CustomFleetsIds: [
            581
        ]
    },
    {
        DWT: 47288.0,
        Imo: 9258595,
        ImoClass: '2,3',
        Title: 'Stena Concert DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 22,
        VesselTypeName: 'MR',
        IsCompetition: false,
        InclusionCompanyId: 7,
        OwnerId: 16817,
        TechnicalManagerId: 655,
        CompanyFleetsIds: [
            7
        ],
        CustomFleetsIds: [
            581
        ]
    },
    {
        DWT: 159034.0,
        Imo: 9592214,
        ImoClass: 'N/A',
        Title: 'Stena Sunrise DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false,
        InclusionCompanyId: 7,
        OwnerId: null,
        TechnicalManagerId: 655,
        CompanyFleetsIds: [
            7
        ],
        CustomFleetsIds: [
            581
        ]
    },
    {
        DWT: 159031.0,
        Imo: 9585895,
        ImoClass: 'N/A',
        Title: 'Stena Supreme DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false,
        InclusionCompanyId: 7,
        OwnerId: null,
        TechnicalManagerId: 655,
        CompanyFleetsIds: [
            7
        ],
        CustomFleetsIds: [
            581,
            637
        ]
    },
    {
        DWT: 157872.0,
        Imo: 9575565,
        ImoClass: 'N/A',
        Title: 'Sonangol Huila DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false,
        InclusionCompanyId: 7,
        OwnerId: null,
        TechnicalManagerId: 11096,
        CompanyFleetsIds: [
            7
        ],
        CustomFleetsIds: [
            637,
            581
        ]
    },
    {
        DWT: 49647.0,
        Imo: 9685475,
        ImoClass: '2,3',
        Title: 'Stena Immortal DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 22,
        VesselTypeName: 'MR',
        IsCompetition: false,
        InclusionCompanyId: 7,
        OwnerId: null,
        TechnicalManagerId: 655,
        CompanyFleetsIds: [
            7
        ],
        CustomFleetsIds: [
            581
        ]
    },
    {
        DWT: 49750.0,
        Imo: 9667485,
        ImoClass: '2,3',
        Title: 'Stena Imperial DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 22,
        VesselTypeName: 'MR',
        IsCompetition: false,
        InclusionCompanyId: 7,
        OwnerId: null,
        TechnicalManagerId: 655,
        CompanyFleetsIds: [
            7
        ],
        CustomFleetsIds: [
            581
        ]
    },
    {
        DWT: 49718.0,
        Imo: 9797395,
        ImoClass: '2',
        Title: 'Stena Imprimis DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 22,
        VesselTypeName: 'MR',
        IsCompetition: false,
        InclusionCompanyId: 7,
        OwnerId: 17377,
        TechnicalManagerId: 655,
        CompanyFleetsIds: [
            7
        ],
        CustomFleetsIds: [
            581
        ]
    },
    {
        DWT: 159236.0,
        Imo: 9579030,
        ImoClass: 'N/A',
        Title: 'Stena Superior DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false,
        InclusionCompanyId: 7,
        OwnerId: 17377,
        TechnicalManagerId: 655,
        CompanyFleetsIds: [
            7
        ],
        CustomFleetsIds: [
            581
        ]
    },
    {
        DWT: 158491.0,
        Imo: 9533995,
        ImoClass: 'N/A',
        Title: 'Stena Surprise DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false,
        InclusionCompanyId: 7,
        OwnerId: 18348,
        TechnicalManagerId: 655,
        CompanyFleetsIds: [
            7
        ],
        CustomFleetsIds: [
            581
        ]
    },
    {
        DWT: 157756.0,
        Imo: 9575541,
        ImoClass: 'N/A',
        Title: 'Sonangol Rangel DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false,
        InclusionCompanyId: 7,
        OwnerId: null,
        TechnicalManagerId: 11095,
        CompanyFleetsIds: [
            7
        ],
        CustomFleetsIds: [
            581
        ]
    },
    {
        DWT: 49731.0,
        Imo: 9667497,
        ImoClass: '2,3',
        Title: 'Stena Important DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 22,
        VesselTypeName: 'MR',
        IsCompetition: false,
        InclusionCompanyId: 7,
        OwnerId: 17590,
        TechnicalManagerId: 655,
        CompanyFleetsIds: [
            7
        ],
        CustomFleetsIds: [
            581
        ]
    },
    {
        DWT: 156899.0,
        Imo: 9766310,
        ImoClass: 'N/A',
        Title: 'Sonangol Cazenga DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false,
        InclusionCompanyId: 7,
        OwnerId: null,
        TechnicalManagerId: 11095,
        CompanyFleetsIds: [
            7
        ],
        CustomFleetsIds: [
            637,
            581
        ]
    },
    {
        DWT: 159165.0,
        Imo: 9203772,
        ImoClass: 'N/A',
        Title: 'Sonangol Kizomba DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false,
        InclusionCompanyId: 7,
        OwnerId: null,
        TechnicalManagerId: 11095,
        CompanyFleetsIds: [
            7
        ],
        CustomFleetsIds: [
            637,
            581
        ]
    },
    {
        DWT: 157639.0,
        Imo: 9575577,
        ImoClass: 'N/A',
        Title: 'Sonangol Porto Amboim DEMO',
        TotalCubicCapacity: null,
        VesselTypeId: 1,
        VesselTypeName: 'SUEZMAX',
        IsCompetition: false,
        InclusionCompanyId: 7,
        OwnerId: null,
        TechnicalManagerId: 11096,
        CompanyFleetsIds: [
            7
        ],
        CustomFleetsIds: [
            581,
            637
        ]
    }
];
