import React from 'react';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { wktParse } from 'components/ol/ol-helpers';
/* services */
import ConfigService from 'services/config-api/config-service';

export const swedishCompare = () => {
    if (Intl && Intl.Collator) {
        const Collator = new Intl.Collator('sv', { sensitivity: 'base' });
        return Collator.compare;
    }
    return (a, b) => {
        if (a > b) { return 1; }
        if (a < b) { return -1; }
        return 0;
    };
};

/* string helpers */
export const upperCaseMatch = (string, searchTerm) => {
    const s = Number.isInteger(string) ? string.toString() : string;

    if (s && s.toUpperCase) {
        return s.toUpperCase().indexOf(searchTerm) !== -1;
    }

    return false;
};

const stringStrengthTests = {
    digits: /\d/,
    uppercase: /[A-ZÄÅÂÆÉÈÊËÏÓÒÔÖØÜ]/u,
    lowercase: /[a-zäåâæéèêëïóòôöøü]/u,
    special: /[^a-zäåâæéèêëïóòôöøüA-ZÄÅÂÆÉÈÊËÏÓÒÔÖØÜ0-9_\s]/u
};

export function getStringStrength(string) {
    let totalScore = 0;
    const testScores = {
        diffChars: 0,
        length: 0,
        digits: 0,
        uppercase: 0,
        lowercase: 0,
        special: 0
    };
    if (string && string.length) {
        const chars = {};
        for (let i = 0; i < string.length; i++) {
            if (!chars[string[i]]) {
                chars[string[i]] = true;
                testScores.diffChars++;
            }
            for (const key in stringStrengthTests) {
                if (stringStrengthTests[key].test(string[i])) {
                    testScores[key]++;
                }
            }
        }
        testScores.length = string.length;
        /* total calculation */
        totalScore = testScores.diffChars
            + testScores.digits
            + (testScores.uppercase * 2)
            + testScores.lowercase
            + (testScores.special * 2);

        /* bonuses */
        let bonusSum = 0;
        for (const key in testScores) {
            let minValue = 0;
            if (key === 'diffChars') {
                minValue = 4;
            }
            if (key === 'length') {
                minValue = 7;
            }
            if (testScores[key] > minValue) {
                bonusSum++;
                totalScore += bonusSum * 5;
            }
        }
    }

    testScores.totalScore = totalScore;

    return testScores;
}

export function joinArrayObjProp(arr, prop, separator = ', ') {
    if (!arr || arr.length <= 0 || !prop) { return arr; }
    return arr.reduce((res, item, index) => (index === 0 ? res + item[prop] : res + separator + item[prop]), '');
}

export const ellipsis = (string, length) => {
    if (!string || string.length <= length) {
        return string;
    }
    const lastSpaceIndex = string.lastIndexOf(' ', length);
    const lastDotIndex = string.lastIndexOf('.', length);
    const endIndex = lastSpaceIndex > lastDotIndex ? lastSpaceIndex : lastDotIndex;
    return `${string.slice(0, endIndex)}...`;
};

/* object helpers */
export function omitObjectKeys(obj, keysToOmit) {
    const newObj = {};

    for (const key in obj) {
        if (!keysToOmit.includes(key)) {
            newObj[key] = obj[key];
        }
    }
    return newObj;
}

export function filterObjectKeys(obj, filterFn) {
    const newObj = {};
    Object.keys(obj).filter(filterFn).forEach(key => {
        newObj[key] = obj[key];
    });
    return newObj;
}

export function getObjectProp(obj, prop) {
    if (!obj) { return obj; }
    const property = prop.split('.');
    let value = obj;

    for (let i = 0; i < property.length; i++) {
        if (value) {
            value = value[property[i]];
        } else {
            value = undefined;
            break;
        }
    }
    return value !== undefined ? value : obj[prop];
}

export function setObjectProp(obj, prop, val, spread = false) {
    const keys = prop.split('.');
    let value = obj;

    for (let i = 0; i < keys.length; i++) {
        if (i === keys.length - 1) {
            value[keys[i]] = val;
        } else {
            if (!value[keys[i]]) {
                value[keys[i]] = {};
            } else if (spread) {
                value[keys[i]] = Array.isArray(value[keys[i]])
                    ? [...value[keys[i]]]
                    : { ...value[keys[i]] };
            }
            value = value[keys[i]];
        }
    }
    return obj;
}

export function deleteObjectProp(obj, prop, spread = false) {
    const keys = prop.split('.');
    let value = obj;

    for (let i = 0; i < keys.length; i++) {
        if (i === keys.length - 1) {
            delete value[keys[i]];
        } else {
            if (!value[keys[i]]) {
                value[keys[i]] = {};
            } else if (spread) {
                value[keys[i]] = Array.isArray(value[keys[i]])
                    ? [...value[keys[i]]]
                    : { ...value[keys[i]] };
            }
            value = value[keys[i]];
        }
    }
}

export function getOrExecProp(obj, prop, ...args) {
    if (prop !== undefined) {
        if (typeof prop === 'function') {
            return prop(obj, ...args);
        }
        if (typeof prop === 'string') {
            const objectProp = getObjectProp(obj, prop);
            if (objectProp !== undefined) {
                return objectProp;
            }
        }
        return prop;
    }
    return null;
}

export function objLength(obj) {
    let count = 0;
    // eslint-disable-next-line
    for (const key in obj) {
        count++;
    }

    return count;
}

export function getConvertedObject(obj, propTypes) {
    const convertedObject = { ...obj };
    Object.keys(propTypes).forEach((key) => {
        if (obj[key]) {
            if (propTypes[key] === 'integer') {
                convertedObject[key] = parseInt(obj[key], 10);
            } else if (propTypes[key] === 'float') {
                convertedObject[key] = parseFloat(obj[key]);
            }
            if (propTypes[key] === 'integer' || propTypes[key] === 'float') {
                if (isNaN(convertedObject[key])) {
                    convertedObject[key] = null;
                }
            }
        }
    });
    return convertedObject;
}

export const mapObjectKeys = (obj, convertKey) => {
    const objKeysArray = [];
    Object.keys(obj).forEach(key => {
        if (obj[key]) {
            objKeysArray.push(convertKey ? convertKey(key) : key);
        }
    });
    return objKeysArray;
};

export const isObjPropDifferent = (objA, objB, prop) => (
    !objA || !objB || getObjectProp(objA, prop) !== getObjectProp(objB, prop)
);

export function mapObjectValues(obj, ...rest) {
    const res = [];
    for (let i = 0; i < rest.length; i++) {
        res.push(getObjectProp(obj, rest[i]));
    }
    return res;
}

/* number helpers */
export function roundNumber(value, decimals) {
    if (typeof value === 'number' && value === 0) {
        return 0;
    }
    const multiplier = decimals ? 10 ** decimals : 1;
    return typeof value === 'number' ? Math.round(value * multiplier) / multiplier : null;
}

const thousandsRegex = /\d(?=(?:\d{3})+(?:\.|$))/g;

export function formatNumber(value, decimals = 1, showPlus = false, commaSeparator = '.', thousandsSeparator = ' ') {
    if (typeof value !== 'number') {
        return value;
    }
    const n = String(value.toFixed(decimals));
    const p = n.indexOf('.');
    let formattedNumber = n.replace(
        thousandsRegex,
        (m, i) => { return p < 0 || i < p ? `${m}${thousandsSeparator}` : m; }
    );

    if (commaSeparator !== '.') {
        formattedNumber = formattedNumber.replace('.', commaSeparator);
    }

    return value > 0 && showPlus ? `+${formattedNumber}` : formattedNumber;
}

export function padNumber(num, digits) {
    let res = `${num}`;
    let count = res.length;
    while (count < digits) {
        res = `0${res}`;
        count++;
    }
    return res;
}

const ordinalSuffixes = ['th', 'st', 'nd', 'rd'];
export function getOrdinal(number, prefixWithNum = true) {
    const v = Math.abs(number) % 100;
    const suffix = ordinalSuffixes[(v - 20) % 10] || ordinalSuffixes[v] || ordinalSuffixes[0];
    return prefixWithNum ? `${number}${suffix}` : suffix;
}

export function isBetween(x, min, max, minIncluded = true, maxIncluded = false) {
    return (minIncluded ? x >= min : x > min) && (maxIncluded ? x <= max : x < max);
}

/*
    isBetweenLimits(2, '(1,5)') => true
    isBetweenLimits(2, '(2,5)') => false
*/
export function isBetweenLimits(x, limits) {
    const minIncluded = limits.charAt(0) === '[';
    const maxIncluded = limits.slice(-1) === ']';
    const limitsArr = limits.slice(1, -1).split(',');
    const min = limitsArr[0] === '-Infinity' ? -Infinity : parseInt(limitsArr[0], 10);
    const max = limitsArr[1] === 'Infinity' ? Infinity : parseInt(limitsArr[1], 10);
    return isBetween(x, min, max, minIncluded, maxIncluded);
}

const greatestCommonDivisor = (x, y) => {
    const a = x >= y ? x : y;
    const b = x >= y ? y : x;
    return b === 0 ? Math.abs(a) : greatestCommonDivisor(b, a % b);
};

export function leastCommonMultiple(x, y) {
    if (x === 0) { return y; }
    if (y === 0) { return x; }
    return (x * y) / greatestCommonDivisor(x, y);
}

export function closestNumberDivisibleBy(number, divisor) {
    const q = Math.floor(number / divisor);
    const n1 = divisor * q;
    const n2 = (number * divisor) > 0
        ? (divisor * (q + 1))
        : (divisor * (q - 1));
    if (Math.abs(number - n1) < Math.abs(number - n2)) {
        return n1;
    }
    return n2;
}

export const hasValue = (value) => {
    if (typeof value === 'undefined') {
        return false;
    }
    if (typeof value === 'object') {
        if (Array.isArray(value)) {
            return value.length > 0;
        }
        return !!value;
    }
    return !!value.toString().trim();
};

/* geo helpers */
export function wktMaker(type, coordinates) {
    switch (type) {
    case 'polygon': {
        const wkt = coordinates.map(coord => coord.join(' '));
        return `POLYGON((${wkt.join(',')}))`;
    }
    case 'multipolygon': {
        const polygons = new Array(coordinates.length);
        let points;

        coordinates.forEach((coords, index) => {
            points = coords.map(coord => coord.join(' '));
            polygons[index] = `(${points.join(',')})`;
        });

        return `MULTIPOLYGON((${polygons.join(', ')}))`;
    }
    default:
        return `POINT(${coordinates.join(' ')})`;
    }
}

/*
* x = -94.9661667, y = 29.6074667
* format = 0 -> 29° 36′ 27″ N 094° 57′ 58″ W
* format = 1 -> 29.61 N / 94.97 W
* format = 2 -> 29.607466 -94.966166
* format = 3 -> N293627W0945758 (used for sorting)
*/

export function formatCoordinates(x, y, formatId = 0) {
    if (x > 180 || x < -180 || y > 90 || y < -90) {
        return 'Invalid coordinates';
    }
    const xAbs = Math.abs(x);
    const yAbs = Math.abs(y);
    const lonVal = {
        deg: Math.floor(xAbs),
        min: Math.floor((xAbs % 1) * 60),
        sec: Math.round((((xAbs % 1) * 60) % 1) * 60)
    };
    const latVal = {
        deg: Math.floor(yAbs),
        min: Math.floor((yAbs % 1) * 60),
        sec: Math.round((((yAbs % 1) * 60) % 1) * 60)
    };
    const lon = {
        sign: x >= 0 ? 'E' : 'W',
        deg: padNumber(lonVal.deg, 3),
        min: padNumber(lonVal.sec < 60 ? lonVal.min : lonVal.min + 1, 2),
        sec: padNumber(lonVal.sec < 60 ? lonVal.sec : 0, 2)
    };
    const lat = {
        sign: y >= 0 ? 'N' : 'S',
        deg: padNumber(latVal.deg, 2),
        min: padNumber(latVal.sec < 60 ? latVal.min : latVal.min + 1, 2),
        sec: padNumber(latVal.sec < 60 ? latVal.sec : 0, 2)
    };
    const formatMap = {
        0: `${lat.deg}° ${lat.min}′ ${lat.sec}″ ${lat.sign} ${lon.deg}° ${lon.min}′ ${lon.sec}″ ${lon.sign}`,
        1: `${formatNumber(yAbs, 2)} ${lat.sign} / ${formatNumber(xAbs, 2)} ${lon.sign}`,
        2: `${formatNumber(y, 6)}, ${formatNumber(x, 6)}`,
        3: `${lat.sign}${lat.deg}${lat.min}${lat.sec}${lon.sign}${lon.deg}${lon.min}${lon.sec}`,
        4: {
            Latitude: `${lat.deg}° ${lat.min}′ ${lat.sec}″ ${lat.sign}`,
            Longitude: `${lon.deg}° ${lon.min}′ ${lon.sec}″ ${lon.sign}`
        }
    };

    return formatMap[formatId];
}

export const formatWktPosition = (wkt, formatId) => {
    const coordinates = wktParse(wkt);
    return formatCoordinates(coordinates[0], coordinates[1], formatId);
};

/* eslint-disable */
const regex = [
    /^(-?\d{1,2}(?:\.?\d+)?) ?[ ,\/]{1} ?(-?\d{1,3}(?:\.?\d+)?)$/, // 44.81, 20.41
    /^(\d{1,2}(?:\.?\d+)?) ?([NSns]{1}) ?[,\/]? ?(\d{1,3}(?:\.?\d+)?) ?([EWew]{1})$/, // 44.81 N / 20.41 E
    /^(\d{1,2})[° ] ?(\d{1,2}(?:\.?\d+)?)[′,']? ?([NSns]{1}) ?[,\/]? ?(\d{1,3})[° ] ?(\d{1,2})[′,']? ?([EWew]{1})$/, // 44°48.8'N 20°24.4'E
    /^(\d{1,2})[° ] ?(\d{1,2})[′,' ] ?(\d{1,2}(?:\.?\d+)?)[″,"]? ?([NSns]{1}) ?[,\/]? ?(\d{1,3})[° ] ?(\d{1,2})[′,' ] ?(\d{1,2}(?:\.?\d+)?)[″,"]? ?([EWew]{1})$/ // 44°48'48.8"N 20°24'25.3"E
];
export function parseCoordinates(coordinates) {
    /* eslint-enable */
    let format = null;
    let type = 0;
    const trimmedCoordinates = coordinates.trim();
    for (let i = 0; i < regex.length; i++) {
        format = trimmedCoordinates.match(regex[i]);
        if (format !== null) {
            type = i;
            break;
        }
    }
    let lon = null;
    let lat = null;
    let lonValue = null;
    let latValue = null;
    if (format !== null) {
        switch (type) {
        case 0: {
            lon = parseFloat(format[1]);
            lat = parseFloat(format[2]);
            break;
        }
        case 1: {
            const lonSign = format[2] === 'S' || format[2] === 's' ? -1 : 1;
            const latSign = format[4] === 'W' || format[4] === 'w' ? -1 : 1;
            lon = lonSign * parseFloat(format[1]);
            lat = latSign * parseFloat(format[3]);
            break;
        }
        case 2: {
            const lonSign = format[3] === 'S' || format[3] === 's' ? -1 : 1;
            const latSign = format[6] === 'W' || format[6] === 'w' ? -1 : 1;
            lonValue = { d: parseFloat(format[1]), m: parseFloat(format[2]) };
            latValue = { d: parseFloat(format[4]), m: parseFloat(format[5]) };
            if (lonValue.d <= 90 && lonValue.m < 60 && latValue.d <= 180 && latValue.m < 60) {
                lon = lonSign * (lonValue.d + (lonValue.m / 60));
                lat = latSign * (latValue.d + (latValue.m / 60));
            }
            break;
        }
        case 3: {
            const lonSign = format[4] === 'S' || format[4] === 's' ? -1 : 1;
            const latSign = format[8] === 'W' || format[8] === 'w' ? -1 : 1;
            lonValue = { d: parseFloat(format[1]), m: parseFloat(format[2]), s: parseFloat(format[3]) };
            latValue = { d: parseFloat(format[5]), m: parseFloat(format[6]), s: parseFloat(format[7]) };
            if (lonValue.d <= 90 && lonValue.m < 60 && lonValue.s < 60
                && latValue.d <= 180 && latValue.m < 60 && latValue.s < 60) {
                lon = lonSign * (lonValue.d + (lonValue.m / 60) + (lonValue.s / 3600));
                lat = latSign * (latValue.d + (latValue.m / 60) + (latValue.s / 3600));
            }
            break;
        }
        default: {
            break;
        }
        }
    }
    if (typeof lon === 'number' && typeof lat === 'number'
        && isBetween(lon, -90, 90, true, true) && isBetween(lat, -180, 180, true, true)) {
        return [lat, lon];
    }
    return null;
}

/* className helpers */
export function getSeverity(config, value, refValue) {
    if (refValue === null) {
        return '';
    }
    const deviation = refValue ? ((value - refValue) / refValue) * 100 : value;
    let severityClass = '';
    const configArr = Object.keys(config);
    for (let i = 0; i < configArr.length; i++) {
        const key = configArr[i];
        if (Array.isArray(config[key])) {
            for (let j = 0; j < config[key].length; j++) {
                if (isBetweenLimits(deviation, config[key][j])) {
                    severityClass = key;
                    break;
                }
            }
        } else if (isBetweenLimits(deviation, config[key])) {
            severityClass = key;
        }
        if (severityClass) {
            break;
        }
    }
    return severityClass;
}

export function getSpeedSeverity(value, refValue) {
    return getSeverity({
        'text-danger': ['(-Infinity,-10)', '(10,Infinity)'],
        'text-warning': ['[-10,-5)', '(5,10]']
    }, value, refValue);
}

export function getConsumptionSeverity(value, refValue) {
    return getSeverity({
        'text-warning': ['[5,10]'],
        'text-danger': ['(10,Infinity)']
    }, value, refValue);
}

export function getClassName(...args) {
    let className = '';
    for (let i = 0; i < args.length; i++) {
        if (args[i]) {
            if (typeof args[i] === 'string' || typeof args[i] === 'number') {
                className += ` ${args[i]}`;
            } else if (typeof args[i] === 'object') {
                // eslint-disable-next-line no-loop-func
                const argKeys = Object.keys(args[i]);
                for (let j = 0; j < argKeys.length; j++) {
                    if (args[i][argKeys[j]]) {
                        className += ` ${argKeys[j]}`;
                    }
                }
            }
        }
    }
    return className.trim();
}

/* converters */

export const convertStringToNumber = (value, isInteger = false) => {
    const parsedValue = isInteger ? parseInt(value, 10) : parseFloat(value);
    return isNaN(parsedValue) ? null : parsedValue;
};

/* misc helpers */

export const compareVersions = (ver1, ver2) => {
    const v1 = ver1.split('.');
    const v2 = ver2.split('.');
    let res = 0;
    let v1Part;
    let v2Part;
    for (let i = 0; i < v1.length; i++) {
        v1Part = parseInt(v1[i], 10);
        v2Part = parseInt(v2[i], 10);
        if (v1Part > v2Part) {
            res = 1;
            break;
        } else if (v1Part < v2Part) {
            res = -1;
            break;
        }
    }
    return res;
};

// Waits for a callback function "condition" to return truthy value
export const waitForCondition = (condition, timeout = 10000, cycle = 200) => {
    const start = Date.now();
    // waitFor makes the decision whether the condition is met
    // or not met or the timeout has been exceeded which means
    // this promise will be rejected
    function waitFor(resolve, reject) {
        if (condition()) {
            resolve();
        } else if (timeout && (Date.now() - start) >= timeout) {
            reject(new Error('timeout'));
        } else {
            setTimeout(waitFor.bind(this, resolve, reject), cycle);
        }
    }
    return new Promise(waitFor);
};

export const getVesselTechnicalDetails = (vessel) => {
    const technicalDetails = [];
    if (vessel.IMO) {
        technicalDetails.push(
            `${translate('GLOBAL.IMO')} ${vessel.IMO}`
        );
    }
    if (vessel && vessel.Segment) {
        technicalDetails.push(vessel.Segment);
    }
    if (vessel && vessel.VesselTypeName) {
        technicalDetails.push(vessel.VesselTypeName);
    }
    if (vessel.DWT) {
        technicalDetails.push(
            `${translate('GLOBAL.DWT')}
            ${formatNumber(vessel.DWT, 0)} ${translate('UNITS.DWT')}`
        );
    }
    if (vessel.TotalCubicCapacity) {
        technicalDetails.push(
            `${translate('GLOBAL.CAPACITY')} ${formatNumber(vessel.TotalCubicCapacity, 0)}`
            + ` ${translate('UNITS.CUBIC_CAPACITY')}`
        );
    }
    if (vessel.IMOClass) {
        technicalDetails.push(
            `${translate('GLOBAL.IMO_CLASS')} ${vessel.IMOClass && vessel.IMOClass
                .split(',')
                .join('/')}`
        );
    }
    return technicalDetails.join(', ');
};

export const setArrayToFormData = (formData, name, value) => {
    value.forEach((item, index) => {
        if (typeof item === 'object') {
            Object.keys(item).forEach((key) => {
                formData.set(`${name}[${index}].${key}`, item[key]);
            });
        } else {
            formData.set(`${name}[${index}]`, item);
        }
    });
};

export const formatValue = (data, config, key = null, fallbackValue = '-') => {
    const value = getObjectProp(data, key || config.key);
    let formattedValue = value;
    if (config.type === 'number' && typeof value === 'number') {
        formattedValue = formatNumber(value, config.decimals);
    } else if (config.type === 'array') {
        formattedValue = value.join(', ');
    } else if (config.type === 'range') {
        formattedValue = value.map(v => formatNumber(v, config.decimals)).join(' - ');
    }
    if (!formattedValue) {
        return fallbackValue;
    }
    if (config.unit) {
        formattedValue = `${formattedValue} ${config.unit}`;
    }
    let valueClassName = config.valueClass;
    if (config.getSeverity) {
        let severityValue = null;
        if (config.severityKey) {
            severityValue = getObjectProp(data, config.severityKey);
        }
        const severityClass = config.getSeverity(value, severityValue);
        valueClassName = valueClassName ? `${valueClassName} ${severityClass}` : severityClass;
    }
    if (valueClassName) {
        return <span className={valueClassName}>{formattedValue}</span>;
    }
    return formattedValue;
};

export const getLinkToSedna = (vesselName, voyageNumber) => {
    const query = `${vesselName}${voyageNumber ? `-${voyageNumber}` : ''}`;
    return {
        pathname: `${ConfigService.featureToggles.sednaIntegrationURL}/launch/list`,
        search: [
            'filter=all',
            `filterSet={"type":"and","children":[{"term":{"type":"jobReference","query":"${query}"}}]}`,
            'teams=all'
        ].join('&')
    };
};

export default formatCoordinates;
