import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* actions */
import { getResources, reset } from './terminal-info-actions';
/* components */
import Comments from 'components/comments/comments';
import TerminalInfoOverview from './terminal-info-overview';
import TerminalInfoBerths from './terminal-info-berths';
import TabSelector from 'components/tab-selector/tab-selector';
/* styles */
import './terminal-info.scss';

export class TerminalInfo extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            activeTabId: typeof props.activeTabId === 'number' ? props.activeTabId : 0
        };
    }

    componentDidMount() {
        if (this.props.id) {
            this.props.getResources(this.props.id);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.id && this.props.id !== prevProps.id) {
            this.props.getResources(this.props.id);
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

    getTabs = () => {
        const { permissions, berths, comments, terminal, id } = this.props;

        return [{
            id: 0,
            title: t('TERMINAL.OVERVIEW'),
            icon: 'icon-overview',
            content: (
                <TerminalInfoOverview
                    comments={comments}
                    onCommentsChange={this.getComments}
                    onShowMoreClick={this.setActiveTab.bind(this, 4)}
                    permissions={permissions}
                    terminal={terminal}
                />
            )
        }, {
            id: 2,
            title: t('TERMINAL.BERTHS'),
            icon: 'icon-berth',
            content: (
                <TerminalInfoBerths
                    berths={berths}
                    permissions={permissions}
                    terminalId={id}
                />
            ),
            disabled: berths.length === 0 && !permissions.UpdateBerth
        }, {
            id: 4,
            title: t('COMMENTS.TITLE'),
            icon: 'icon-message',
            content: (
                <Comments
                    canCreateComment={permissions.CreatePortComment}
                    canUpdateAllComments={permissions.UpdateAllPortComments}
                    canUpdateComments={permissions.UpdatePortComment}
                    comments={comments}
                    resource="terminal"
                    resourceId={id}
                />
            ),
            disabled: !permissions.GetAllPortComments || (comments.length === 0 && !permissions.CreatePortComment)
        }];
    };

    setActiveTab = tabId => {
        if (tabId !== this.state.activeTabId) {
            this.setState({ activeTabId: tabId });
        }
    };

    handleCloseClick = () => this.props.router.updateQueryParams({ remove: ['terminal'] });

    handleEditClick = () =>
        this.props.router.updateQueryParams({ add: { terminalUpdate: this.props.terminal.TerminalId } });

    render() {
        if (!this.props.terminal) {
            return (
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <div className="flex-grow" />
                        <div className="flex-shrink">
                            <button className="btn-link icon icon-close" onClick={this.handleCloseClick} />
                        </div>
                    </div>
                    <div className="sten-content__body" />
                </div>
            );
        }
        const tabs = this.getTabs();
        const activeTab = tabs.find(tab => tab.id === this.state.activeTabId);
        return (
            <div className="sten-content sten-terminal-info">
                <div className="sten-content__header flex-row">
                    <div className="flex-grow">
                        <h1 className="sten-content__title text-ellipsis">{this.props.terminal.TerminalName}</h1>
                        {this.props.terminal.PortName && (
                            <p className="sten-content__subtitle text-ellipsis text-nowrap">
                                {this.props.terminal.PortName}
                            </p>
                        )}
                    </div>
                    {this.props.permissions && this.props.permissions.AddUpdateTerminal && (
                        <React.Fragment>
                            <div className="flex-shrink">
                                <button className="btn-link icon icon-edit" onClick={this.handleEditClick} />
                            </div>
                            <div className="sten-content__vertical-separator" />
                        </React.Fragment>
                    )}
                    <div className="flex-shrink">
                        <button className="btn-link icon icon-close" onClick={this.handleCloseClick} />
                    </div>
                </div>
                <div className="sten-content__body sten-terminal-info__body-fixed">
                    <TabSelector
                        tabItems={tabs}
                        activeTabId={this.state.activeTabId}
                        onChange={this.setActiveTab}
                    />
                </div>
                <div className="sten-content__body sten-terminal-info__body-content">
                    {activeTab && activeTab.content}
                </div>
            </div>
        );
    }
}

TerminalInfo.propTypes = {
    activeTabId: PropTypes.number,
    berths: PropTypes.arrayOf(PropTypes.object).isRequired,
    comments: PropTypes.arrayOf(PropTypes.object).isRequired,
    getResources: PropTypes.func.isRequired,
    id: PropTypes.number,
    isUpdating: PropTypes.bool,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    reset: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    terminal: PropTypes.objectOf(PropTypes.any)
};

TerminalInfo.defaultProps = {
    activeTabId: 0,
    id: null,
    isUpdating: false,
    terminal: null
};

function mapStateToProps(state) {
    return {
        berths: state.terminalInfoReducer.berths,
        comments: state.terminalInfoReducer.comments,
        permissions: state.userReducer.permissions,
        terminal: state.terminalInfoReducer.terminal
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getResources: terminalId => getResources(dispatch, terminalId),
        reset: () => reset(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TerminalInfo));
