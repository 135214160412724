import React from 'react';
import PropTypes from 'prop-types';
/* components */
import InputField from './input-field';
/* types */
import { DateTimeShape, TimeConfigFieldShape } from '../types';
/* styles */
import './input.scss';

export default class HtecDPRangeInput extends React.Component {
    handleClick = (e) => {
        if (!this.props.isDisabled) {
            if (this.props.onClick) {
                this.props.onClick(e);
            }
            if (!document.activeElement || !this.inputRef.contains(document.activeElement)) {
                this.startInputFieldRef.dateInputRef.focus();
            }
        }
    };

    handleClearClick = (e) => {
        this.startInputFieldRef.clearAll();
        this.endInputFieldRef.clearAll();
        this.props.onRangeChange({
            rangeStart: null,
            rangeEnd: null
        });
        e.stopPropagation();
    };

    handleIconClick = (e) => {
        if (!this.props.isDisabled && this.props.onIconClick) {
            this.props.onIconClick(e);
        }
    };

    handleStartDateClick = (e) => {
        if (this.props.onStartDateClick) {
            if (!this.props.isDisabled) {
                this.props.onStartDateClick(e);
            }
        }
    };

    handleEndDateClick = (e) => {
        if (this.props.onEndDateClick) {
            if (!this.props.isDisabled) {
                this.props.onEndDateClick(e);
            }
        }
    };

    handleStartDateChange = (date) => {
        this.props.onRangeChange({
            rangeStart: date
        }, true);
    };

    handleEndDateChange = (date) => {
        this.props.onRangeChange({
            rangeEnd: date
        }, true);
    };

    handleStartDateKeyDown = (event) => {
        if (event.keyCode === 39
            && this.endInputFieldRef
            && this.endInputFieldRef.dateInputRef
            && event.target.selectionStart === event.target.selectionEnd
            && event.target.selectionStart === event.target.value.length) {
            this.endInputFieldRef.dateInputRef.focus();
            this.endInputFieldRef.dateInputRef.setSelectionRange(0, 0);
            event.preventDefault();
        }
    };

    handleEndDateKeyDown = (event) => {
        if ((event.keyCode === 8 || event.keyCode === 37)
            && this.startInputFieldRef
            && this.startInputFieldRef.dateInputRef
            && event.target.selectionStart === event.target.selectionEnd
            && event.target.selectionStart === 0) {
            this.startInputFieldRef.dateInputRef.focus();
            this.startInputFieldRef.dateInputRef.setSelectionRange(
                this.startInputFieldRef.dateInputRef.value.length, this.startInputFieldRef.dateInputRef.value.length
            );
            event.preventDefault();
        }
    };

    saveRef = (c) => {
        this.inputRef = c;
        if (this.props.saveRef) {
            this.props.saveRef(c);
        }
    };

    saveStartInputFieldRef = (c) => {
        this.startInputFieldRef = c;
    };

    saveEndInputFieldRef = (c) => {
        this.endInputFieldRef = c;
    };

    render() {
        const props = this.props;

        let className = 'htec-dp-input htec-dp-input--range';
        if (props.className) {
            className += ` ${props.className}`;
        }
        if (props.isActive) {
            className += ' htec-dp-input--active';
        }
        if (props.isDisabled) {
            className += ' htec-dp-input--disabled';
        }
        if (props.isInvalid) {
            className += ' htec-dp-input--invalid';
        }

        return (
            <div
                ref={this.saveRef}
                className={className}
                onClick={this.handleClick}
            >
                <InputField
                    ref={this.saveStartInputFieldRef}
                    isDisabled={this.props.isDisabled}
                    date={props.startDate}
                    dateFormat={props.dateFormat}
                    onClick={this.handleStartDateClick}
                    onDateChange={this.handleStartDateChange}
                    onKeyDown={this.handleStartDateKeyDown}
                    timeConfig={props.timeConfig}
                    withTime={props.withTime}
                />
                <div className="htec-dp-input__separator">{props.datesSeparator}</div>
                <InputField
                    ref={this.saveEndInputFieldRef}
                    isDisabled={this.props.isDisabled}
                    date={props.endDate}
                    dateFormat={props.dateFormat}
                    onClick={this.handleEndDateClick}
                    onDateChange={this.handleEndDateChange}
                    onKeyDown={this.handleEndDateKeyDown}
                    timeConfig={props.timeConfig}
                    withTime={props.withTime}
                />
                {props.isClearable && (props.startDate || props.endDate) && (
                    <button className="htec-dp-input__icon" onClick={this.handleClearClick}>{props.clearIcon}</button>
                )}
                <div className="htec-dp-input__icon" onClick={this.handleIconClick}>{props.icon}</div>
            </div>
        );
    }
}

HtecDPRangeInput.propTypes = {
    className: PropTypes.string,
    clearIcon: PropTypes.node,
    dateFormat: PropTypes.string,
    datesSeparator: PropTypes.node,
    endDate: PropTypes.shape(DateTimeShape),
    icon: PropTypes.node,
    isActive: PropTypes.bool,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isInvalid: PropTypes.bool,
    onClick: PropTypes.func,
    name: PropTypes.string,
    onEndDateClick: PropTypes.func,
    onIconClick: PropTypes.func,
    onRangeChange: PropTypes.func.isRequired,
    onStartDateClick: PropTypes.func,
    saveRef: PropTypes.func,
    startDate: PropTypes.shape(DateTimeShape),
    timeConfig: PropTypes.shape({
        hour: PropTypes.shape(TimeConfigFieldShape),
        minute: PropTypes.shape(TimeConfigFieldShape),
        second: PropTypes.shape(TimeConfigFieldShape)
    }),
    withTime: PropTypes.bool
};

HtecDPRangeInput.defaultProps = {
    className: '',
    clearIcon: <span>&times;</span>,
    dateFormat: 'DD/MM/YYYY',
    datesSeparator: '→',
    endDate: null,
    icon: null,
    isActive: false,
    isClearable: true,
    isDisabled: false,
    isInvalid: false,
    name: '',
    onClick: undefined,
    onEndDateClick: undefined,
    onIconClick: undefined,
    onStartDateClick: undefined,
    saveRef: undefined,
    startDate: null,
    timeConfig: null,
    withTime: false
};
