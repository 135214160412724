import CoreApi from './core-api';

class EnergyManagementService {
    constructor() {
        this.resourceUrl = 'EnergyManagement';
    }

    Reports = {
        getReportTypes: () => {
            return CoreApi.get(`${this.resourceUrl}/ReportTypes`)
                .then(response => response.data, () => null);
        },
        Inclusions: {
            getPresetByType: (type, params) => {
                return CoreApi.get(`${this.resourceUrl}/Inclusions/${type}`, params)
                    .then(response => response.data, () => null);
            },
            getPresetsByType: (params) => {
                return CoreApi.get(`${this.resourceUrl}/Inclusions/Companies`, params)
                    .then(response => response.data, () => null);
            }
        },
        FleetSpeedReport: {
            getReport: (data) => {
                return CoreApi.post(`${this.resourceUrl}/FleetSpeedReport`, data)
                    .then(response => response.data, () => null);
            }
        },
        VoyageReport: {
            getDailyReports: (params) => {
                return CoreApi.post(`${this.resourceUrl}/VoyageReport/DailyReports`, params)
                    .then(response => response.data, () => null);
            },
            getDepartureReports: (params) => {
                return CoreApi.post(`${this.resourceUrl}/VoyageReport/DepartureReports`, params)
                    .then(response => response.data, () => null);
            },
            getArrivalReports: (params) => {
                return CoreApi.post(`${this.resourceUrl}/VoyageReport/ArrivalReports`, params)
                    .then(response => response.data, () => null);
            },
            getCargoReports: (params) => {
                return CoreApi.post(`${this.resourceUrl}/VoyageReport/CargoReports`, params)
                    .then(response => response.data, () => null);
            },
            getSofReports: (params) => {
                return CoreApi.post(`${this.resourceUrl}/VoyageReport/SofReports`, params)
                    .then(response => response.data, () => null);
            },
            getEventReports: (params) => {
                return CoreApi.post(`${this.resourceUrl}/VoyageReport/EventReports`, params)
                    .then(response => response.data, () => null);
            },
            getReportCounts: (params) => {
                return CoreApi.post(`${this.resourceUrl}/VoyageReport/CountReports`, params)
                    .then(response => response.data, () => null);
            },
            getStatistics: (data) => {
                return CoreApi.post(`${this.resourceUrl}/VoyageReport/Statistics`, data)
                    .then(response => response.data, () => null);
            },
            getReportDataTypes: (params) => {
                return CoreApi.get(`${this.resourceUrl}/VoyageReport/TypesAndGrades`, params)
                    .then(response => response.data, () => null);
            }
        },
        MultiVoyageReport: {
            getReport: (params) => {
                return CoreApi.post(`${this.resourceUrl}/MultiVoyageReport`, params)
                    .then(response => response.data, () => null);
            }
        },
        PerformanceSummary: {
            getReport: (params) => {
                return CoreApi.post(`${this.resourceUrl}/PerformanceSummaryReport`, params)
                    .then(response => response.data, () => null);
            }
        },
        FleetPerformanceReport: {
            getTypeAReport: (params) => {
                return CoreApi.post(`${this.resourceUrl}/FleetPerformanceReports`, params)
                    .then(response => response.data, () => null);
            },
            getTypeBReport: (params) => {
                return CoreApi.post(
                    `${this.resourceUrl}/FleetPerformanceDetailedPortAndCanalConsumptionsReports`, params
                ).then(response => response.data, () => null);
            },
            getReportPerVoyage: (params) => {
                return CoreApi.post(
                    `${this.resourceUrl}/FleetPerformanceDetailedPortAndCanalConsumptionsReports/VesselPerformance`,
                    params
                ).then(response => response.data, () => null);
            }
        },
        QuarterlyReport: {
            getReport: (params) => {
                return CoreApi.post(`${this.resourceUrl}/QuarterlyReport`, params)
                    .then(response => response.data, () => null);
            }
        },
        FleetListReport: {
            getReport: (params) => {
                return CoreApi.post(`${this.resourceUrl}/FleetListReport`, params)
                    .then(response => response.data, () => null);
            }
        },
        WeatherPerformanceReport: {
            getReport(params) {
                return CoreApi.post('Performance/Dashboard', params)
                    .then(response => response.data, () => null);
            },
            generatePDF(voyageId) {
                return CoreApi.fetchPdf(`Performance/GenerateWarrantyReport/${voyageId}`)
                    .then(response => response, () => null);
            }
        }
    };

    KPI = {
        getRangeOptions: () => {
            return CoreApi.get(`${this.resourceUrl}/Kpi/RangeOptions`)
                .then(response => response.data, () => null);
        },
        Vessel: {
            getAverageSpeed: (imo, params) => {
                return CoreApi.get(`${this.resourceUrl}/Kpi/${imo}/AverageSpeed`, params)
                    .then(response => response.data, () => null);
            },
            getBasicData: (imo, params) => {
                return CoreApi.get(`${this.resourceUrl}/Kpi/${imo}/BasicData`, params)
                    .then(response => response.data, () => null);
            },
            getRPMAnalysis: (imo, params) => {
                return CoreApi.get(`${this.resourceUrl}/Kpi/${imo}/RPMAnalysis`, params)
                    .then(response => response.data, () => null);
            },
            getBoilerUtilization: (imo, params) => {
                return CoreApi.get(`${this.resourceUrl}/Kpi/${imo}/BoilerUtilization`, params)
                    .then(response => response.data, () => null);
            },
            getAuxiliaryUtilization: (imo, params) => {
                return CoreApi.get(`${this.resourceUrl}/Kpi/${imo}/AuxiliaryUtilization`, params)
                    .then(response => response.data, () => null);
            },
            getKeyTotalFigures: (imo, params) => {
                return CoreApi.get(`${this.resourceUrl}/Kpi/${imo}/KeyTotalFigures`, params)
                    .then(response => response.data, () => null);
            },
            getKeyAverageFigures: (imo, params) => {
                return CoreApi.get(`${this.resourceUrl}/Kpi/${imo}/KeyAverageFigures`, params)
                    .then(response => response.data, () => null);
            }
        },
        Segment: {
            getBasicData: (params) => {
                return CoreApi.post(`${this.resourceUrl}/Kpi/Segment`, params)
                    .then(response => response.data, () => null);
            },
            getAdvancedData: (params) => {
                return CoreApi.post(`${this.resourceUrl}/Kpi/AdvancedSegment`, params)
                    .then(response => response.data, () => null);
            }
        },
        Overview: {
            getOverviewBasic: (params) => {
                return CoreApi.post(`${this.resourceUrl}/Kpi/Segments`, params)
                    .then(response => response.data, () => null);
            },
            getOverviewAdvanced: (params) => {
                return CoreApi.post(`${this.resourceUrl}/Kpi/AdvancedSegments`, params)
                    .then(response => response.data, () => null);
            }
        }
    }
}

export default new EnergyManagementService();
