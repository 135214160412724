import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { findTotalTopOffset } from 'utils/helpers/html-helper';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
import DateRangePopup from 'components/date-range-popup/date-range-popup';
import Accordion from 'components/accordion/accordion';
import Input from 'components/input/input';
import Validation from 'components/validation/validation';
import IncludedVesselsSection from '../included-vessels-section';
/* actions */
import { updateFilters, setCPSpeedValue } from '../../reports-actions';

const emptyObj = {};
const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.SIDEBAR.${key}`);
const labelHeight = 25;

class PerformanceSummaryReportInclusions extends React.PureComponent {
    handleFormSubmit = () => {
        const filters = {
            selectedVessels: this.includedVesselsSectionRef.state.selectedVessels
        };

        this.props.updateFilters(filters);

        if (this.props.onSubmit) {
            this.props.onSubmit(filters);
        }
    }

    renderFooter = () => (
        <footer className="sten-content__footer flex-row">
            <div className="col-12">
                <button
                    onClick={this.props.resetInclusions}
                    className="btn btn--secondary col-24"
                    type="button"
                >
                    {t('RESET_PARAMETERS')}
                </button>
            </div>
            <div className="col-12">
                <Validation.Button className="btn btn--primary col-24">
                    {t('CALCULATE')}
                </Validation.Button>
            </div>
        </footer>
    );

    setCPSpeedValue = (key) => (value) => this.props.setCPSpeedValue(key, value);

    setCPSpeedArrValue = this.setCPSpeedValue('Speeds');

    setCPSpeedArrEntryValue = (index, key) => (value) => this.setCPSpeedArrValue(
        this.props.CPSpeed.Speeds.map((entry, i) => {
            return i === index ? {
                ...this.props.CPSpeed.Speeds[i],
                [key]: value
            } : entry;
        }, this)
    );

    addCPSpeedEntry = () => this.setCPSpeedArrValue([
        ...this.props.CPSpeed.Speeds,
        {
            Speed: null,
            PropConsBallast: null,
            PropConsLaden: null
        }
    ]);

    removeCPSpeedArrEntry = (index) => () => this.setCPSpeedArrValue(
        this.props.CPSpeed.Speeds.filter((entry, i) => i !== index, this)
    );

    renderCPSpeedEntries(CPSpeed) {
        return CPSpeed.Speeds.map((entry, index, speeds) => {
            return (
                // eslint-disable-next-line react/no-array-index-key
                <div className="sten-content__section sten-content__separator" key={index}>
                    <div className="form-row flex-row flex-center">
                        <div className="col-10">
                            <label className="label">
                                {`${t('SPEED')} ${index + 1} (${translate('UNITS.SPEED')})`}
                            </label>
                        </div>
                        <div className="col-14 flex-row flex-center">
                            <div className="flex-grow">
                                <Validation.Wrapper
                                    hintsOnHover
                                    validations={{
                                        required: true,
                                        custom: {
                                            rule: value => !(speeds.some(
                                                (s, i) => i !== index && parseFloat(s.Speed) === parseFloat(value)
                                            )),
                                            hint: () => t('DUPLICATE_VALUES')
                                        }
                                    }}
                                >
                                    <Input
                                        autoFocus
                                        name={`Speed(${index})`}
                                        value={entry.Speed}
                                        onChange={this.setCPSpeedArrEntryValue(index, 'Speed')}
                                    />
                                </Validation.Wrapper>
                            </div>
                            <div className="flex-shrink" onClick={this.handleDelete}>
                                <button
                                    type="button"
                                    className="btn-link icon icon-delete"
                                    onClick={this.removeCPSpeedArrEntry(index)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="row">
                            <div className="col-7 col-offset-10">
                                <label className="label text-center">{t('BALLAST')}</label>
                            </div>
                            <div className="col-7">
                                <label className="label text-center">{t('LADEN')}</label>
                            </div>
                        </div>
                        <div className="flex-row flex-center">
                            <div className="col-10">
                                <label className="label">
                                    {`${t('PROP_CONS')} ${index + 1} (${translate('UNITS.AVG_CONSUMPTION')})`}
                                </label>
                            </div>
                            <div className="col-7">
                                <Validation.Wrapper
                                    hintsOnHover
                                    validations={{ numeric: { min: 0, max: 99999 } }}
                                >
                                    <Input
                                        name="PropConsBallast"
                                        value={CPSpeed.Speeds[index].PropConsBallast}
                                        onChange={this.setCPSpeedArrEntryValue(index, 'PropConsBallast')}
                                    />
                                </Validation.Wrapper>
                            </div>
                            <div className="col-7">
                                <Validation.Wrapper
                                    hintsOnHover
                                    validations={{ numeric: { min: 0, max: 99999 } }}
                                >
                                    <Input
                                        name="PropConsLaden"
                                        value={CPSpeed.Speeds[index].PropConsLaden}
                                        onChange={this.setCPSpeedArrEntryValue(index, 'PropConsLaden')}
                                    />
                                </Validation.Wrapper>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }, this);
    }

    scrollToInvalidField = () => {
        if (this.scrollAreaRef && this.scrollAreaRef.contentWrapperRef) {
            const scrollAreaDiv = this.scrollAreaRef.contentWrapperRef;
            const elements = scrollAreaDiv.getElementsByClassName(
                'sten-validation-wrapper--invalid'
            );

            if (elements && elements[0]) {
                const totalOffsetTop = findTotalTopOffset(elements[0], scrollAreaDiv);
                this.scrollAreaRef.scrollYTo(totalOffsetTop - labelHeight);
            }
        }
    };

    render() {
        const CPSpeed = this.props.CPSpeed || emptyObj;
        const inclusions = this.props.inclusions || emptyObj;
        const { setInclusionValue, selectedRange } = this.props;
        return (
            <Validation.Form
                className="sten-energy-management-reports-sidebar-inclusions"
                onSubmit={this.handleFormSubmit}
                onInvalid={this.scrollToInvalidField}
            >
                <ScrollArea
                    className="sten-content__body"
                    ref={r => { this.scrollAreaRef = r; }}
                    shouldScrollToActiveElement
                >
                    <div className="sten-content__section">
                        <div className="form-row">
                            <label className="label">{t('DATA_RANGE')}</label>
                            <Validation.Wrapper validations={{ dateRange: true }}>
                                <DateRangePopup
                                    isUTC
                                    alignment="end"
                                    shouldRenderDefaultPresets
                                    name="dataRange"
                                    onRangeSelect={this.props.setSelectedRange}
                                    value={selectedRange}
                                />
                            </Validation.Wrapper>
                        </div>
                    </div>
                    <Accordion
                        header={<h4 className="text-uppercase">{t('PARAMETERS')}</h4>}
                        isCollapsedBodyRendered
                    >
                        <div className="sten-content__section">
                            <div className="form-row">
                                <div className="flex-row flex-center">
                                    <div className="col-10">
                                        <label className="label">{t('INSTRUCTED_SPEED_BALLAST')}</label>
                                    </div>
                                    <div className="col-7">
                                        <Validation.Wrapper
                                            hintsOnHover
                                            validations={{
                                                required: true,
                                                numeric: { min: 0, max: 99, maxProp: 'InstructedSpeedBallastMax' }
                                            }}
                                        >
                                            <Input
                                                prefix="min"
                                                name="InstructedSpeedBallastMin"
                                                value={inclusions.InstructedSpeedBallastMin}
                                                onChange={setInclusionValue('InstructedSpeedBallastMin')}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                    <div className="col-7">
                                        <Validation.Wrapper
                                            hintsOnHover
                                            validations={{
                                                required: true,
                                                numeric: { min: 0, max: 99, minProp: 'InstructedSpeedBallastMin' }
                                            }}
                                        >
                                            <Input
                                                prefix="max"
                                                name="InstructedSpeedBallastMax"
                                                value={inclusions.InstructedSpeedBallastMax}
                                                onChange={setInclusionValue('InstructedSpeedBallastMax')}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">{t('INSTRUCTED_SPEED_LADEN')}</label>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: true,
                                            numeric: { min: 0, max: 99, maxProp: 'InstructedSpeedLadenMax' }
                                        }}
                                    >
                                        <Input
                                            prefix="min"
                                            name="InstructedSpeedLadenMin"
                                            value={inclusions.InstructedSpeedLadenMin}
                                            onChange={setInclusionValue('InstructedSpeedLadenMin')}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: true,
                                            numeric: { min: 0, max: 99, minProp: 'InstructedSpeedLadenMin' }
                                        }}
                                    >
                                        <Input
                                            prefix="max"
                                            name="InstructedSpeedLadenMax"
                                            value={inclusions.InstructedSpeedLadenMax}
                                            onChange={setInclusionValue('InstructedSpeedLadenMax')}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">{t('SEA_PASSAGE')}</label>
                                </div>
                                <div className="col-14">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: true,
                                            numeric: { min: 0, max: 99999, decimals: 0 }
                                        }}
                                    >
                                        <Input
                                            prefix="min"
                                            name="SeaPassageMin"
                                            value={inclusions.SeaPassageMin}
                                            onChange={setInclusionValue('SeaPassageMin')}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">{t('HOURS_STEAMED')}</label>
                                </div>
                                <div className="col-14">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: true,
                                            numeric: { min: 0, max: 25, decimals: 0 }
                                        }}
                                    >
                                        <Input
                                            prefix="min"
                                            name="HoursSteamedMin"
                                            value={inclusions.HoursSteamedMin}
                                            onChange={setInclusionValue('HoursSteamedMin')}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">{t('WIND_SPEED')}</label>
                                </div>
                                <div className="col-14">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: true,
                                            numeric: { min: 0, max: 12, decimals: 0 }
                                        }}
                                    >
                                        <Input
                                            prefix="max"
                                            name="WindSpeedMax"
                                            value={inclusions.WindSpeedMax}
                                            onChange={setInclusionValue('WindSpeedMax')}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">{t('DRAFT_IN_LADEN')}</label>
                                </div>
                                <div className="col-14">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{ required: true, numeric: { min: 0, max: 99 } }}
                                    >
                                        <Input
                                            prefix="max"
                                            name="DraftInLadenMax"
                                            value={inclusions.DraftInLadenMax}
                                            onChange={setInclusionValue('DraftInLadenMax')}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                        </div>
                    </Accordion>
                    <Accordion
                        header={<h4 className="text-uppercase">{t('CP_SPEED')}</h4>}
                        isCollapsedBodyRendered
                    >
                        <div className="sten-content__section">
                            <div className="form-row">
                                <div className="flex-row flex-center">
                                    <div className="col-10">
                                        <label className="label">{t('AUX_CONSUMPTION')}</label>
                                    </div>
                                    <div className="col-14">
                                        <Validation.Wrapper
                                            hintsOnHover
                                            validations={{ numeric: { min: 0, max: 99 } }}
                                        >
                                            <Input
                                                name="AuxCons"
                                                value={CPSpeed.AuxCons}
                                                onChange={this.setCPSpeedValue('AuxCons')}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.renderCPSpeedEntries(CPSpeed)}
                        <div className="sten-content__section sten-content__separator">
                            <div className="form-row flex-row flex-center">
                                <button
                                    type="button"
                                    className="btn-link"
                                    onClick={this.addCPSpeedEntry}
                                >
                                    <span className="btn__icon icon icon-plus-box" />
                                    {t('CP_SPEED')}
                                </button>
                            </div>
                        </div>
                    </Accordion>
                    <IncludedVesselsSection
                        ref={(r) => { this.includedVesselsSectionRef = r; }}
                        segments={this.props.segments}
                        vessels={this.props.vessels}
                        vesselsBySegment={this.props.vesselsBySegment}
                        initialSelectedVessels={this.props.selectedVessels}
                    />
                </ScrollArea>
                {this.renderFooter()}
            </Validation.Form>
        );
    }
}

PerformanceSummaryReportInclusions.propTypes = {
    CPSpeed: PropTypes.objectOf(PropTypes.any).isRequired,
    inclusions: PropTypes.objectOf(PropTypes.any),
    onSubmit: PropTypes.func.isRequired,
    resetInclusions: PropTypes.func.isRequired,
    segments: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedRange: PropTypes.objectOf(PropTypes.any),
    selectedVessels: PropTypes.objectOf(PropTypes.any).isRequired,
    setCPSpeedValue: PropTypes.func.isRequired,
    setInclusionValue: PropTypes.func.isRequired,
    setSelectedRange: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    vessels: PropTypes.arrayOf(PropTypes.any).isRequired,
    vesselsBySegment: PropTypes.objectOf(PropTypes.any).isRequired
};

PerformanceSummaryReportInclusions.defaultProps = {
    inclusions: null,
    selectedRange: null
};

function mapStateToProps(state) {
    return {
        CPSpeed: state.energyManagementReportsReducer.CPSpeed,
        segments: state.energyManagementReportsReducer.segments,
        selectedVessels: state.energyManagementReportsReducer.filters.selectedVessels,
        vessels: state.energyManagementReportsReducer.vessels
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateFilters: filters => updateFilters(dispatch, filters),
        setCPSpeedValue: (key, value) => setCPSpeedValue(dispatch, key, value)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceSummaryReportInclusions);
