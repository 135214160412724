import React from 'react';
import PropTypes from 'prop-types';

class PureWrapper extends React.PureComponent {
    render() {
        return <React.Fragment>{this.props.children}</React.Fragment>;
    }
}

PureWrapper.propTypes = {
    children: PropTypes.node
};

PureWrapper.defaultProps = {
    children: null
};

export default PureWrapper;
