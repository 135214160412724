import React from 'react';
import PropTypes from 'prop-types';
/* helpers */
import { isValidDate, isValidTime } from '../helpers';
/* components */
import DateTimeLogic from '../shared/date-time-logic';
import DatePicker from '../date-picker/date-picker';
import TimePicker from '../time-picker/time-picker';
/* types */
import { DateShape, DateTimeShape, TimeConfigFieldShape } from '../types';
/* styles */
import './date-time-picker.scss';

export default class HtecDPDateTimePicker extends React.PureComponent {
    renderDateTime = (args) => {
        const selectedTime = isValidTime(this.props.selectedDateTime) ? this.props.selectedDateTime : null;
        const selectedDate = isValidDate(this.props.selectedDateTime) ? this.props.selectedDateTime : null;
        return (
            <div className="htec-dp-date-time-picker">
                <DatePicker
                    alwaysShow6Weeks={this.props.alwaysShow6Weeks}
                    canSwitchMode={this.props.canSwitchMode}
                    disableMonthsOutOfRange={this.props.disableMonthsOutOfRange}
                    hideOutsideDays={this.props.hideOutsideDays}
                    initialMonth={this.props.initialMonth}
                    maxDate={this.props.maxDateTime}
                    minDate={this.props.minDateTime}
                    nextBtn={this.props.nextBtn}
                    onDateSelect={args.handleDateChange}
                    prevBtn={this.props.prevBtn}
                    renderDay={this.props.renderDay}
                    renderMonth={this.props.renderMonth}
                    renderWeekDays={this.props.renderWeekDays}
                    renderYear={this.props.renderYear}
                    selectedDate={selectedDate}
                    today={this.props.today}
                    weekStart={this.props.weekStart}
                />
                <div className="htec-dp-date-time-picker__time">
                    <TimePicker
                        config={this.props.timeConfig}
                        decreaseBtn={this.props.decreaseBtn}
                        format={this.props.timeFormat}
                        increaseBtn={this.props.increaseBtn}
                        maxTime={args.maxTime}
                        minTime={args.minTime}
                        onTimeChange={args.handleTimeChange}
                        selectedTime={selectedTime}
                        showTitles={this.props.showTimeTitles}
                        showUnits={this.props.showTimeUnits}
                    />
                </div>
            </div>
        );
    };

    render() {
        return (
            <DateTimeLogic
                selectedDateTime={this.props.selectedDateTime}
                renderDateTime={this.renderDateTime}
                onDateTimeChange={this.props.onDateTimeChange}
                minDateTime={this.props.minDateTime}
                maxDateTime={this.props.maxDateTime}
            />
        );
    }
}

HtecDPDateTimePicker.propTypes = {
    alwaysShow6Weeks: PropTypes.bool,
    canSwitchMode: PropTypes.bool,
    decreaseBtn: PropTypes.node,
    disableMonthsOutOfRange: PropTypes.bool,
    hideOutsideDays: PropTypes.bool,
    increaseBtn: PropTypes.node,
    initialMonth: PropTypes.shape({ month: PropTypes.number, year: PropTypes.number }),
    maxDateTime: PropTypes.shape(DateTimeShape),
    minDateTime: PropTypes.shape(DateTimeShape),
    nextBtn: PropTypes.node,
    onDateTimeChange: PropTypes.func.isRequired,
    prevBtn: PropTypes.node,
    renderDay: PropTypes.func,
    renderMonth: PropTypes.func,
    renderWeekDays: PropTypes.func,
    renderYear: PropTypes.func,
    selectedDateTime: PropTypes.shape(DateTimeShape),
    showTimeTitles: PropTypes.bool,
    showTimeUnits: PropTypes.bool,
    timeConfig: PropTypes.shape({
        hour: PropTypes.shape(TimeConfigFieldShape),
        minute: PropTypes.shape(TimeConfigFieldShape),
        second: PropTypes.shape(TimeConfigFieldShape)
    }),
    timeFormat: PropTypes.oneOf(['24-hour', '12-hour']),
    today: PropTypes.shape(DateShape),
    weekStart: PropTypes.oneOf(['Monday', 'Sunday'])
};

HtecDPDateTimePicker.defaultProps = {
    alwaysShow6Weeks: undefined,
    canSwitchMode: undefined,
    decreaseBtn: undefined,
    disableMonthsOutOfRange: undefined,
    hideOutsideDays: undefined,
    increaseBtn: undefined,
    initialMonth: undefined,
    maxDateTime: null,
    minDateTime: null,
    nextBtn: undefined,
    prevBtn: undefined,
    renderDay: undefined,
    renderMonth: undefined,
    renderWeekDays: undefined,
    renderYear: undefined,
    selectedDateTime: null,
    showTimeTitles: undefined,
    showTimeUnits: undefined,
    timeConfig: null,
    timeFormat: undefined,
    today: undefined,
    weekStart: undefined
};
