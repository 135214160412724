import React from 'react';
import PropTypes from 'prop-types';
/* helpers */
import { compareDates, compareDateTimes, getDateFromDifference, isValidDate, isValidTime } from '../helpers';
/* types */
import { DateTimeShape } from '../types';

export default class HtecDPDateTimeLogic extends React.Component {
    state = {
        minTime: null,
        maxTime: null
    };

    handleDateTimeChange(newDateTime) {
        const { onDateTimeChange, minDateTime, maxDateTime } = this.props;
        let updatedDateTime = newDateTime;
        const updatedState = { minTime: null, maxTime: null };
        updatedDateTime.isDateInvalid = !isValidDate(newDateTime);
        updatedDateTime.isTimeInvalid = !isValidTime(newDateTime);
        if (!updatedDateTime.isDateInvalid && !updatedDateTime.isTimeInvalid) {
            if (minDateTime && compareDates(minDateTime, updatedDateTime) > -1) {
                updatedState.minTime = minDateTime;
                if (compareDateTimes(minDateTime, updatedDateTime) > -1) {
                    updatedDateTime = minDateTime;
                }
            }
            if (maxDateTime && compareDates(maxDateTime, updatedDateTime) < 1) {
                updatedState.maxTime = maxDateTime;
                if (compareDateTimes(maxDateTime, updatedDateTime) < 1) {
                    updatedDateTime = maxDateTime;
                }
            }
        }
        this.setState(updatedState);
        onDateTimeChange(updatedDateTime);
    }

    handleDateChange = (date) => {
        const newDateTime = { ...this.props.selectedDateTime, ...date };
        this.handleDateTimeChange(newDateTime);
    };

    handleTimeChange = (time) => {
        const { dayDiff, ...newTime } = time;
        let newDate = this.props.selectedDateTime;
        if (newDate && dayDiff) {
            newDate = getDateFromDifference(newDate, 'day', dayDiff);
        }
        this.handleDateTimeChange({ ...newDate, ...newTime });
    };

    render() {
        return this.props.renderDateTime({
            handleDateChange: this.handleDateChange,
            handleTimeChange: this.handleTimeChange,
            minTime: this.state.minTime,
            maxTime: this.state.maxTime
        });
    }
}

HtecDPDateTimeLogic.propTypes = {
    maxDateTime: PropTypes.shape(DateTimeShape),
    minDateTime: PropTypes.shape(DateTimeShape),
    onDateTimeChange: PropTypes.func.isRequired,
    renderDateTime: PropTypes.func.isRequired,
    selectedDateTime: PropTypes.shape(DateTimeShape)
};

HtecDPDateTimeLogic.defaultProps = {
    maxDateTime: null,
    minDateTime: null,
    selectedDateTime: null
};
