import React from 'react';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import RadioButton from 'components/radio-button/radio-button';
import Select from 'components/select/select';
/* styles */
import './tag-select.scss';

const emptyArray = [];

class TagSelect extends React.PureComponent {
    state = {
        categories: emptyArray,
        options: null,
        selectedCategories: emptyArray,
        value: null
    };

    static getDerivedStateFromProps(props, state) {
        const newState = {};
        let stateChanged = false;
        if (state.options !== props.options) {
            newState.categories = TagSelect.extractCategories(props.options);
            newState.options = props.options;
            stateChanged = true;
        }
        if (state.value !== props.value) {
            newState.selectedCategories = TagSelect.extractCategories(props.value);
            newState.value = props.value;
            stateChanged = true;
        }
        return stateChanged ? newState : null;
    }

    static extractCategories = (tags) => {
        const categories = [];
        const categoriesMap = {};
        let categoryIndex = 0;
        tags.forEach(tag => {
            if (!categoriesMap[tag.CategoryId]) {
                categoriesMap[tag.CategoryId] = categoryIndex;
                categories.push({
                    Id: tag.CategoryId,
                    Name: tag.CategoryName,
                    Tags: [tag],
                    SelectedTag: tag,
                    IsEditable: tag.IsEditable
                });
                categoryIndex++;
            } else {
                categories[categoriesMap[tag.CategoryId]].Tags.push(tag);
                if (tag.IsEditable && !categories[categoriesMap[tag.CategoryId]].IsEditable) {
                    categories[categoriesMap[tag.CategoryId]].IsEditable = true;
                }
            }
        });
        return categories;
    };

    handleTagClick = (e) => {
        e.stopPropagation();
    };

    handleTagChange = (SelectedTag) => () => {
        this.setState({
            categories: this.state.categories.map(category => {
                if (category.Id === SelectedTag.CategoryId) {
                    return { ...category, SelectedTag };
                }
                return category;
            })
        });
        this.props.onChange(this.props.value.map(tag => {
            if (tag.CategoryId === SelectedTag.CategoryId) {
                return SelectedTag;
            }
            return tag;
        }));
    };

    renderOption =(option, searchCriteria, isSelected) => {
        return (
            <React.Fragment>
                <span className="sten-tag-select__label">{option.Name}</span>
                {option.Tags.length > 1 && option.Tags.map(tag => (
                    <RadioButton
                        key={tag.Id}
                        className="sten-tag-select__tag"
                        name="tagSelect"
                        value={tag.Id}
                        isDisabled={!isSelected || !tag.IsEditable}
                        isChecked={tag === option.SelectedTag}
                        onClick={this.handleTagClick}
                        onChange={this.handleTagChange(tag)}
                    >
                        {tag.Name}
                    </RadioButton>
                ))}
            </React.Fragment>
        );
    };

    isOptionDisabled = (option) => !option.IsEditable;

    handleChange = (selectedOptions) => {
        this.props.onChange(selectedOptions.map(cat => cat.SelectedTag));
    };

    render() {
        const placeholder = this.state.categories.length === 0
            ? translate('TAG_SELECT.NO_TAGS')
            : translate('TAG_SELECT.PLACEHOLDER');
        return (
            <Select
                {...this.props}
                searchable
                multiple
                className={`sten-tag-select${this.props.className ? ` ${this.props.className}` : ''}`}
                optionClassName="sten-tag-select__option"
                valueKey="Id"
                labelKey="Name"
                optionRenderer={this.renderOption}
                optionDisabledCallback={this.isOptionDisabled}
                placeholder={this.props.placeholder || placeholder}
                options={this.state.categories}
                value={this.state.selectedCategories}
                disabled={this.state.categories.length === 0}
                onChange={this.handleChange}
            />
        );
    }
}

TagSelect.propTypes = Select.propTypes;

TagSelect.defaultProps = Select.defaultProps;

export default TagSelect;
