export const xlsxColors = {
    ash: 'FF7DA2B3',
    black: 'FF000000',
    blue: 'FF0E5370',
    darkBlue: 'FF0F2E39',
    green: 'FFA5D6A7',
    darkGrey: 'FF999999',
    lightBlue: 'FFDBE8FF',
    lightestBlue: 'FFEBF2FF',
    lightestGrey: 'FFEEEEEE',
    lightGrey: 'FFCCCCCC',
    red: 'FFE57373',
    white: 'FFFFFFFF',
    whiteSmoke: 'FFF5F5F5',
    yellow: 'FFFEF176'
};

const thinBorder = { style: 'thin', color: { argb: xlsxColors.lightGrey } };
export const thinBlueBorder = { style: 'thin', color: { argb: xlsxColors.blue } };
const thinDarkBorder = { style: 'thin', color: { argb: xlsxColors.black } };

const defaultFontSize = 10;

export const columnWidth = 18;

export const headingTitleStyle = {
    font: {
        bold: true,
        size: 12,
        name: 'Arial'
    }
};

export const tableHeaderStyle = {
    font: {
        bold: true,
        name: 'Arial',
        size: defaultFontSize
    },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        bgColor: { argb: xlsxColors.black },
        fgColor: { argb: xlsxColors.lightBlue }
    },
    alignment: {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'left'
    },
    border: {
        top: thinBorder,
        left: thinBorder,
        bottom: thinBorder,
        right: thinBorder
    }
};

export const excludedReportStyle = {
    font: {
        color: { argb: xlsxColors.darkGrey },
        name: 'Arial',
        size: defaultFontSize
    },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        bgColor: { argb: xlsxColors.whiteSmoke },
        fgColor: { argb: xlsxColors.whiteSmoke } // they are the same ??
    },
    alignment: {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'left'
    }
};

export const tableCellStyle = {
    font: {
        name: 'Arial',
        size: defaultFontSize
    },
    border: {
        top: thinBorder,
        left: thinBorder,
        bottom: thinBorder,
        right: thinBorder
    },
    alignment: {
        wrapText: true,
        vertical: 'top',
        horizontal: 'left'
    }
};

export const alertCellStyle = {
    font: {
        name: 'Arial',
        size: defaultFontSize,
        bold: true,
        color: { argb: xlsxColors.red }
    }
};

export const excludedFieldStyle = {
    font: {
        name: 'Arial',
        size: defaultFontSize,
        bold: true,
        color: { argb: xlsxColors.black }
    }
};

export const fuelFieldStyle = {
    fill: {
        type: 'pattern',
        pattern: 'solid',
        bgColor: { argb: xlsxColors.lightestBlue },
        fgColor: { argb: xlsxColors.lightestBlue }
    }
};

export const darkHeaderRowStyle = {
    fill: {
        type: 'pattern',
        pattern: 'solid',
        bgColor: { argb: xlsxColors.darkBlue },
        fgColor: { argb: xlsxColors.darkBlue }
    },
    alignment: {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'left'
    }
};

export const darkCellStyle = {
    font: {
        color: { argb: xlsxColors.white },
        name: 'Arial',
        size: 10
    },
    fill: {
        type: 'pattern',
        pattern: 'solid',
        bgColor: { argb: xlsxColors.blue },
        fgColor: { argb: xlsxColors.blue }
    },
    alignment: {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'left'
    },
    border: {
        top: thinDarkBorder,
        right: thinDarkBorder,
        bottom: null,
        left: thinDarkBorder
    }
};

export const borderWithDarkerRightSideStyle = {
    border: {
        top: thinBorder,
        left: thinBorder,
        bottom: thinBorder,
        right: thinDarkBorder
    }
};

export const thinBorderWithoutLeftSideStyle = {
    border: {
        top: thinBorder,
        left: null,
        bottom: thinBorder,
        right: thinBorder
    }
};
