/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import TimeHelper from 'utils/helpers/time-helper';
import { formatNumber } from 'utils/helpers/info-helper';
import { getPanelRows, renderLabelValueRows } from 'utils/helpers/template-helper';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import Accordion from 'components/accordion/accordion';
import ScrollArea from 'components/scroll-area/scroll-area';
/* constants */
import { vesselSpecificationRows } from '../vessel-info-constants';
/* styles */
import './vessel-info-vessel.scss';

export class VesselInfoVessel extends React.PureComponent {
    getLinkToQuestionnaire = (questionnaireId) => this.props.getLinkTo({ add: { questionnaireId } });

    getContactDetails = (vesselBase) => {
        if (vesselBase.CommercialOperator) {
            return (
                <Accordion header={<h4 className="text-uppercase">{t('VESSEL_INFO.VESSEL.COMMERCIAL_OPERATOR')}</h4>}>
                    <div className="sten-content__section">
                        <p dangerouslySetInnerHTML={{ __html: vesselBase.CommercialOperator }} />
                    </div>
                </Accordion>
            );
        }
        return null;
    }

    getQuestionnaireList = () => (
        <Accordion header={<h4 className="text-uppercase">{t('VESSEL_INFO.VESSEL.QUESTIONNAIRE')}</h4>}>
            {this.props.questionnaireList.map(q => (
                <div
                    key={q.Id}
                    className="sten-vessel-info-questionnaire sten-vessel-info-questionnaire--clickable"
                >
                    <Link
                        className="sten-vessel-info-questionnaire__link flex"
                        to={this.getLinkToQuestionnaire(q.Id)}
                    >
                        <div className="sten-vessel-info-questionnaire__icon flex-shrink flex flex-center">
                            <span className="icon flex-grow icon-questionnaire" />
                        </div>
                        <div className="sten-vessel-info-questionnaire__content flex-grow flex-row flex-center">
                            <div className="flex-grow">
                                <p>{TimeHelper.getFormatted(q.ReportDateUtc, { utc: true })}</p>
                            </div>
                            <div className="flex-shrink flex">
                                <span className="icon icon-chevron-right flex-grow text-center" />
                            </div>
                        </div>
                    </Link>
                </div>
            ))}
        </Accordion>
    );

    getDisponentOwner = (vesselBase) => {
        const rows = [];
        if (vesselBase && vesselBase.Owner) {
            rows.push((
                <div key="owner" className="sten-content__section">
                    <p dangerouslySetInnerHTML={{ __html: vesselBase.Owner }} />
                </div>
            ));
        }

        if (rows.length === 0) {
            return null;
        }

        return (
            <Accordion header={<h4 className="text-uppercase">{t('VESSEL_INFO.VESSEL.DISPONENT_OWNER')}</h4>}>
                {rows}
            </Accordion>
        );
    };

    getVesselSpecifications = (vesselBase, vesselDetails) => (
        <Accordion
            header={<h4 className="text-uppercase">{t('VESSEL_INFO.VESSEL.TECHNICAL_SPECIFICATIONS')}</h4>}
        >
            {vesselBase.TechnicalManager && (
                <div className="sten-content__section">
                    <div className="row sten-panel__row">
                        <div className="col-8">
                            <label className="sten-panel__label">
                                {t('VESSEL_INFO.VESSEL.TECHNICAL_MANAGER')}
                            </label>
                        </div>
                        <div className="col-16">
                            <div className="sten-panel__label sten-panel__label--value">
                                {vesselBase.TechnicalManager}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div
                key="body"
                className={`sten-content__section${vesselBase.TechnicalManager ? ' sten-content__separator' : ''}`}
            >
                {renderLabelValueRows({ vesselBase, vesselDetails }, vesselSpecificationRows)}
            </div>
        </Accordion>
    );

    getDimensions = (vesselBase) => {
        const columnClasses = ['col-12', 'col-12'];
        let dimensions;
        if (vesselBase.Dimensions && vesselBase.Dimensions.length > 0) {
            dimensions = vesselBase.Dimensions.map((dimension, index) => {
                let classes = 'sten-content__section';
                if (index > 0) {
                    classes += ' sten-content__separator';
                }
                const rows = [];
                dimension.Loadlines.forEach((loadLine) => {
                    rows.push([
                        <label className="sten-panel__label">
                            {`${loadLine.LoadlineType} ${t('VESSEL_INFO.VESSEL.MEASURE')}`}
                        </label>,
                        <label className="sten-panel__label sten-panel__label--value">
                            {loadLine.Measure.toLowerCase() === 'm'
                                ? `${formatNumber(loadLine.Value)} ${loadLine.Measure.toLowerCase()}`
                                : `${formatNumber(loadLine.Value, 0)} ${loadLine.Measure.toLowerCase()}`
                            }
                        </label>
                    ]);
                });

                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index} className={classes}>
                        <div className="sten-panel__row sten-panel__label sten-panel__label--value">
                            {dimension.Type}
                        </div>
                        {getPanelRows(columnClasses, rows)}
                    </div>
                );
            });
        }

        if (!dimensions) {
            return null;
        }
        return (
            <Accordion header={<h4 className="text-uppercase">{t('VESSEL_INFO.VESSEL.DIMENSIONS')}</h4>}>
                {dimensions}
            </Accordion>
        );
    };

    getBudgetedConsumption = () => (
        <Accordion header={<h4 className="text-uppercase">{t('VESSEL_INFO.VESSEL.BUDGETED_CONSUMPTION')}</h4>}>
            <table
                className="
                    sten-table
                    sten-table--no-border-vertical
                    sten-table--centered
                    sten-table--sm"
            >
                <thead>
                    <tr>
                        <th className="text-left text-secondary">
                            {`${t('VESSEL_INFO.VESSEL.SPEED')} (${t('UNITS.SPEED')})`}
                        </th>
                        <th className="text-center text-secondary">
                            {`${t('VESSEL_INFO.VESSEL.BALLAST')} (${t('UNITS.AVG_CONSUMPTION')})`}
                        </th>
                        <th className="text-right text-secondary">
                            {`${t('VESSEL_INFO.VESSEL.LADEN')} (${t('UNITS.AVG_CONSUMPTION')})`}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {this.props.selectedVesselBudgetedConsumption.map(
                        (bc, index) => this.getBudgetedConsumptionRow(
                            bc.Speed,
                            bc.BallastConsumption,
                            bc.LadenConsumption,
                            index
                        )
                    )}
                </tbody>
            </table>
        </Accordion>
    );

    getBudgetedConsumptionRow = (speed, ballast, laden, index) => (
        <tr key={index}>
            <td className="text-left">
                {typeof speed === 'number' ? formatNumber(speed, 2) : '-'}
            </td>
            <td className="text-center">
                {typeof ballast === 'number' ? formatNumber(ballast, 2) : '-'}
            </td>
            <td className="text-right">
                {typeof laden === 'number' ? formatNumber(laden, 2) : '-'}
            </td>
        </tr>
    );

    getDetails = (vesselBase) => {
        const columnClasses = ['col-12', 'col-12'];
        const details = [];
        if (vesselBase.Q88Link) {
            details.push([
                <label className="sten-panel__label sten-panel__label--value">
                    <a
                        href={vesselBase.Q88Link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn-link btn-link--sm btn-link--inverted"
                    >
                        {t('VESSEL_INFO.VESSEL.Q88_DETAILS')}
                    </a>
                </label>
            ]);
        }

        if (!details.length) {
            return null;
        }
        return (
            <Accordion header={<h4 className="text-uppercase">{t('VESSEL_INFO.VESSEL.DETAILS')}</h4>}>
                <div className="sten-content__section">
                    {getPanelRows(columnClasses, details)}
                </div>
            </Accordion>
        );
    };

    render() {
        const vesselBase = this.props.selectedVesselBase;
        const vesselDetails = this.props.selectedVesselDetails;
        return (
            <ScrollArea className="sten-vessel-info-overview">
                {this.props.questionnaireList.length > 0 && this.getQuestionnaireList()}
                {vesselBase && this.getContactDetails(vesselBase)}
                {vesselBase && this.getDisponentOwner(vesselBase)}
                {vesselBase && this.getVesselSpecifications(vesselBase, vesselDetails)}
                {vesselBase && this.getDimensions(vesselBase)}
                {this.props.selectedVesselBudgetedConsumption.length > 0 && this.getBudgetedConsumption()}
                {ConfigService.featureToggles.showQ88VesselDetails && vesselBase && this.getDetails(vesselBase)}
            </ScrollArea>
        );
    }
}

VesselInfoVessel.propTypes = {
    getLinkTo: PropTypes.func.isRequired,
    selectedVesselBase: PropTypes.objectOf(PropTypes.any),
    selectedVesselBudgetedConsumption: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedVesselDetails: PropTypes.objectOf(PropTypes.any),
    questionnaireList: PropTypes.arrayOf(PropTypes.object).isRequired
};

VesselInfoVessel.defaultProps = {
    selectedVesselBase: null,
    selectedVesselDetails: null
};

function mapStateToProps(state) {
    return {
        selectedVesselBase: state.homeReducer.selectedVesselBase,
        selectedVesselBudgetedConsumption: state.homeReducer.selectedVesselBudgetedConsumption,
        selectedVesselDetails: state.homeReducer.selectedVesselDetails,
        questionnaireList: state.homeReducer.questionnaireList
    };
}

export default connect(mapStateToProps)(VesselInfoVessel);
