import React from 'react';
import PropTypes from 'prop-types';
import './panel-slider.scss';

export default class PanelSlider extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            direction: 0
        };
        this.currentDirection = 0;
        this.preventAnimation = false;
    }

    componentWillUnmount() {
        this.clearIntervalsAndTimeouts();
    }

    clearIntervalsAndTimeouts() {
        if (this.preventAnimationTimeout) {
            clearTimeout(this.preventAnimationTimeout);
        }
        if (this.panelChangeTimeout) {
            clearTimeout(this.panelChangeTimeout);
        }
        if (this.mouseHoldTimeout) {
            clearTimeout(this.mouseHoldTimeout);
        }
        if (this.mouseHoldInterval) {
            clearInterval(this.mouseHoldInterval);
        }
    }

    stopPanelChange() {
        this.clearIntervalsAndTimeouts();
        this.currentDirection = 0;
        this.preventAnimation = false;
        this.setState({ direction: 0 });
    }

    handlePanelChange = (direction) => {
        if (direction < 0) {
            if (this.props.prevBtnDisabled(this.currentDirection)) {
                this.stopPanelChange();
                return;
            }
            this.currentDirection -= 1;
        } else {
            if (this.props.nextBtnDisabled(this.currentDirection)) {
                this.stopPanelChange();
                return;
            }
            this.currentDirection += 1;
        }
        if (this.preventAnimationTimeout) {
            clearTimeout(this.preventAnimationTimeout);
        }
        if (this.panelChangeTimeout) {
            clearTimeout(this.panelChangeTimeout);
        }
        if (this.preventAnimation) {
            this.preventAnimationTimeout = setTimeout(() => {
                this.preventAnimation = false;
            }, this.props.transitionDuration);
            this.setState({ direction: 0 }, () => {
                this.props.onPanelChange(this.currentDirection);
                this.currentDirection = 0;
            });
        } else {
            this.preventAnimation = true;
            this.setState({ direction });
            this.panelChangeTimeout = setTimeout(() => {
                this.setState({ direction: 0 }, () => {
                    this.props.onPanelChange(this.currentDirection);
                    this.currentDirection = 0;
                    this.preventAnimation = false;
                });
            }, this.props.transitionDuration);
        }
    };

    handleBtnMouseDown = (e, direction) => {
        if (e && e.button !== 0) {
            return;
        }
        this.handlePanelChange(direction);
        if (this.mouseHoldTimeout) {
            clearTimeout(this.mouseHoldTimeout);
        }
        this.mouseHoldTimeout = setTimeout(() => {
            if (this.mouseHoldInterval) {
                clearInterval(this.mouseHoldInterval);
            }
            this.mouseHoldInterval = setInterval(() => {
                this.handlePanelChange(direction);
            }, this.props.mouseHoldInterval);
        }, this.props.mouseHoldTimeout);
    };

    handleBtnMouseUp = () => {
        if (this.mouseHoldTimeout) {
            clearTimeout(this.mouseHoldTimeout);
        }
        if (this.mouseHoldInterval) {
            clearInterval(this.mouseHoldInterval);
        }
    };

    handlePrevBtnMouseDown = (e) => this.handleBtnMouseDown(e, -1);

    handleNextBtnMouseDown = (e) => this.handleBtnMouseDown(e, 1);

    render() {
        const panels = this.props.panels.slice();
        let panelsWrapperStyle = null;
        if (this.state.direction > 0) {
            panelsWrapperStyle = {
                transitionDuration: `${this.props.transitionDuration}ms`,
                right: `${100 / panels.length}%`
            };
            panels.push(this.props.nextPanel);
        } else if (this.state.direction < 0) {
            panelsWrapperStyle = {
                transitionDuration: `${this.props.transitionDuration}ms`,
                right: `${-100 / panels.length}%`,
                transform: `translateX(${-100 / panels.length}%)`
            };
            panels.unshift(this.props.prevPanel);
        }

        const panelsClass = `htec-dp-panel-slider__panels ${this.props.panelsClass}`;

        let className = 'htec-dp-panel-slider';
        if (this.props.className) {
            className += ` ${this.props.className}`;
        }
        return (
            <div className={className}>
                <button
                    className="htec-dp-panel-slider__btn htec-dp-panel-slider__btn--prev"
                    onMouseDown={this.handlePrevBtnMouseDown}
                    onMouseUp={this.handleBtnMouseUp}
                    onMouseLeave={this.handleBtnMouseUp}
                    disabled={this.props.prevBtnDisabled(this.currentDirection)}
                >
                    {this.props.prevBtn}
                </button>
                <button
                    className="htec-dp-panel-slider__btn htec-dp-panel-slider__btn--next"
                    onMouseDown={this.handleNextBtnMouseDown}
                    onMouseUp={this.handleBtnMouseUp}
                    onMouseLeave={this.handleBtnMouseUp}
                    disabled={this.props.nextBtnDisabled(this.currentDirection)}
                >
                    {this.props.nextBtn}
                </button>
                <div className={panelsClass}>
                    <div className="htec-dp-panel-slider__panels-wrapper" style={panelsWrapperStyle}>
                        {panels}
                    </div>
                </div>
            </div>
        );
    }
}

PanelSlider.propTypes = {
    className: PropTypes.string,
    mouseHoldInterval: PropTypes.number,
    mouseHoldTimeout: PropTypes.number,
    nextBtn: PropTypes.node,
    nextBtnDisabled: PropTypes.func,
    nextPanel: PropTypes.node.isRequired,
    onPanelChange: PropTypes.func.isRequired,
    panels: PropTypes.node.isRequired,
    panelsClass: PropTypes.string,
    prevBtn: PropTypes.node,
    prevBtnDisabled: PropTypes.func,
    prevPanel: PropTypes.node.isRequired,
    transitionDuration: PropTypes.number
};

PanelSlider.defaultProps = {
    className: '',
    mouseHoldInterval: 50,
    mouseHoldTimeout: 500,
    nextBtn: '►',
    nextBtnDisabled: () => false,
    panelsClass: '',
    prevBtn: '◄',
    prevBtnDisabled: () => false,
    transitionDuration: 300
};
