import { createSelector } from 'reselect';
/* helpers */
import { createByIdSelector } from 'utils/helpers/selector-helper';
import { mapArrayByProp, sortByProp } from 'utils/helpers/array-helper';
/* constants */
import { fixedColumn, tableColumns } from './port-management-constants';

export const getBunkeringTypesById = createByIdSelector(
    state => state.portManagementReducer.bunkeringTypes, 'Id'
);

const tableColumnsByProp = mapArrayByProp(tableColumns, 'prop');

export const getConvertedPorts = createSelector(
    state => state.portManagementReducer.ports,
    state => state.portManagementReducer.bunkeringTypes,
    getBunkeringTypesById,
    (ports, bunkeringTypes, bunkeringTypesById) => {
        if (!ports || bunkeringTypes.length === 0) {
            return null;
        }
        return {
            ...ports,
            PageItems: ports.PageItems.map(port => ({
                ...port,
                Bunkering: port.Bunkering ? {
                    ...port.Bunkering,
                    Types: port.Bunkering.Types.map(typeId => bunkeringTypesById[typeId])
                } : null,
                Services: port.Services ? mapArrayByProp(port.Services, 'Type') : null,
                Terminals: port.Terminals.map(terminal => ({
                    ...terminal,
                    Berths: terminal.Berths.map(berth => ({ ...berth, PortId: terminal.PortId }))
                }))
            }))
        };
    }
);

const emptyArray = [];

export const getConvertedPinnedPorts = createSelector(
    state => state.portManagementReducer.pinnedPorts,
    state => state.portManagementReducer.bunkeringTypes,
    getBunkeringTypesById,
    (ports, bunkeringTypes, bunkeringTypesById) => {
        if (ports.length === 0 || bunkeringTypes.length === 0) {
            return emptyArray;
        }
        return ports.map(port => ({
            ...port,
            Bunkering: port.Bunkering ? {
                ...port.Bunkering,
                Types: port.Bunkering.Types.map(typeId => bunkeringTypesById[typeId])
            } : null,
            Services: port.Services ? mapArrayByProp(port.Services, 'Type') : null,
            Terminals: port.Terminals.map(terminal => ({
                ...terminal,
                Berths: terminal.Berths.map(berth => ({ ...berth, PortId: terminal.PortId }))
            }))
        }));
    }
);

export const getSortedPinnedPorts = createSelector(
    getConvertedPinnedPorts,
    state => state.portManagementReducer.portParams.SortBy,
    state => state.portManagementReducer.portParams.SortOrder,
    (ports, sortBy, sortOrder) => {
        const isAsc = sortOrder === 'ASC';
        const transformValue = tableColumnsByProp[sortBy] && tableColumnsByProp[sortBy].sortValue;
        return ports.map(port => ({
            ...port,
            Terminals: port.Terminals.map(terminal => ({
                ...terminal,
                Berths: terminal.Berths.slice(0).sort(sortByProp(sortBy, isAsc, transformValue))
            })).sort(sortByProp(sortBy, isAsc, transformValue))
        })).sort(sortByProp(sortBy, isAsc, transformValue));
    }
);

export const getMappedColumnIds = createSelector(
    state => state.userReducer.settings,
    (settings) => (settings
        ? settings.PortManagementVisibleColumns.reduce((res, col) => {
            res[col] = true;
            return res;
        }, {})
        : null)
);

export const getVisibleColumns = createSelector(
    getMappedColumnIds,
    (mappedColumns) => {
        const visibleColumns = [];
        tableColumns.forEach(col => {
            if (mappedColumns[col.id]) {
                visibleColumns.push({ ...col, style: { width: `${col.width}rem` } });
            }
        });
        return visibleColumns;
    }
);

export const getTableContentStyle = createSelector(
    getVisibleColumns,
    (visibleColumns) => {
        const visibleColumnsWidth = visibleColumns.reduce((res, col) => res + col.width, 0);
        return { minWidth: `${visibleColumnsWidth + fixedColumn.width}rem` };
    }
);
