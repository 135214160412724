import CoreApi from './core-api';

class UserManagementService {
    constructor() {
        this.resourceUrl = 'Users';
    }

    get(params) {
        return CoreApi.get(this.resourceUrl, params)
            .then(response => response.data, () => null);
    }

    getById(userId) {
        return CoreApi.get(`${this.resourceUrl}/${userId}`)
            .then(response => response.data, () => null);
    }

    getStatuses() {
        return CoreApi.get(`${this.resourceUrl}/Statuses`)
            .then(response => response.data, () => null);
    }

    add(data) {
        return CoreApi.post(this.resourceUrl, data);
    }

    update(id, data) {
        return CoreApi.put(`${this.resourceUrl}/${id}`, data);
    }

    getCompanies(params) {
        return CoreApi.get(`${this.resourceUrl}/Companies`, params)
            .then(response => response.data, () => null);
    }

    getCompaniesPerRole(params) {
        return CoreApi.get(`${this.resourceUrl}/CompaniesPerRole`, params)
            .then(response => response.data, () => null);
    }

    getRoles() {
        return CoreApi.get(`${this.resourceUrl}/Roles`)
            .then(response => response.data, () => null);
    }

    checkCompaniesPerRole(params) {
        return CoreApi.get(`${this.resourceUrl}/CheckedCompanies`, params)
            .then(response => response.data, () => null);
    }
}

export default new UserManagementService();
