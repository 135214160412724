export const ActionTypes = {
    OPERATORS_DIARY_GET: 'OPERATORS_DIARY_GET',
    OPERATORS_DIARY_SET: 'OPERATORS_DIARY_SET',
    OPERATORS_DIARY_SET_OPTIONS: 'OPERATORS_DIARY_SET_OPTIONS',
    OPERATORS_DIARY_GET_OPTIONS: 'OPERATORS_DIARY_GET_OPTIONS',
    OPERATORS_DIARY_VOYAGE_UPDATED: 'OPERATORS_DIARY_VOYAGE_UPDATED',
    OPERATORS_DIARY_RESET: 'OPERATORS_DIARY_RESET'
};

export function getDiary(dispatch, params) {
    dispatch({ type: ActionTypes.OPERATORS_DIARY_GET, params });
}

export function getOptions(dispatch) {
    dispatch({ type: ActionTypes.OPERATORS_DIARY_GET_OPTIONS });
}

export function voyageUpdated(dispatch, voyage) {
    dispatch({ type: ActionTypes.OPERATORS_DIARY_VOYAGE_UPDATED, voyage });
}

export function reset(dispatch) {
    dispatch({ type: ActionTypes.OPERATORS_DIARY_RESET });
}

export function resetDiary(dispatch) {
    dispatch({ type: ActionTypes.OPERATORS_DIARY_SET, diary: null });
}
