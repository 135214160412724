import React from 'react';
/* helpers */
import { convertCoordinates, normalizeCoordinates } from '../ol-helpers';
import { formatCoordinates } from 'utils/helpers/info-helper';
/* styles */
import './coordinate-info.scss';

export default class OlMapCoordinateInfo extends React.PureComponent {
    state = {
        coordinates: null
    };

    handlePointerMove = (e) => {
        this.setState({
            coordinates: e.coordinate ? normalizeCoordinates(convertCoordinates(e.coordinate)) : null
        });
    };

    render() {
        return (
            <div className="sten-ol-map-coordinate-info">
                {this.state.coordinates && formatCoordinates(this.state.coordinates[0], this.state.coordinates[1])}
            </div>
        );
    }
}
