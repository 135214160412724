import { ActionTypes } from './user-actions';

const emptyArray = [];

const initialState = {
    user: null,
    permissions: null,
    restrictedRoutes: emptyArray,
    supportedCurrencies: emptyArray,
    timeFormats: emptyArray,
    settings: null,
    settingUpdated: { name: '' },
    resourceUpdated: { name: '' }
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.USER_SET: {
        return {
            ...state,
            user: action.user,
            permissions: action.permissions,
            restrictedRoutes: action.restrictedRoutes || emptyArray,
            settings: action.settings,
            timeFormats: action.timeFormats || emptyArray,
            supportedCurrencies: action.supportedCurrencies || emptyArray
        };
    }
    case ActionTypes.USER_SETTINGS_SET: {
        return {
            ...state,
            settings: action.settings,
            settingUpdated: action.settingUpdated
                ? { name: action.settingUpdated }
                : state.settingUpdated
        };
    }
    case ActionTypes.USER_RESOURCE_UPDATED: {
        return {
            ...state,
            resourceUpdated: action.resourceName
                ? { name: action.resourceName }
                : state.resourceUpdated
        };
    }
    default:
        return state;
    }
}
