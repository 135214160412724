import CoreApi from './core-api';

class CompanyService {
    constructor() {
        this.resourceUrl = 'Companies';
    }

    addPerRole(data) {
        return CoreApi.put(this.resourceUrl, data)
            .then(response => response.data, () => null);
    }

    addInspectionCompany(data) {
        return CoreApi.post(this.resourceUrl, data)
            .then(response => response.data, () => null);
    }

    getPositionListSignatures() {
        return CoreApi.get(`${this.resourceUrl}/PositionListSignatures`)
            .then(response => response.data, () => null);
    }

    Internal = {
        getAll: () => {
            return CoreApi.get(`${this.resourceUrl}/Internal`)
                .then(response => response.data, () => null);
        },

        getById: (id) => {
            return CoreApi.get(`${this.resourceUrl}/Internal/${id}`)
                .then(response => response.data, () => null);
        },

        update: (id, data) => {
            return CoreApi.put(`${this.resourceUrl}/Internal/${id}`, data, true)
                .then(response => response.data, () => null);
        },

        add: (data) => {
            return CoreApi.post(`${this.resourceUrl}/Internal`, data, true)
                .then(response => response.data, () => null);
        },

        getLogoByIdAndType: (companyId, params, forImgTag = true) => {
            return CoreApi.get(
                `${this.resourceUrl}/Internal/Image/${companyId}`,
                { height: 300, width: 300, ...params }
            )
                .then(
                    response => {
                        if (response.data) {
                            return forImgTag ? `data:image/png;base64,${response.data}` : response.data;
                        }
                        return null;
                    },
                    () => null
                );
        }
    }
}

export default new CompanyService();
