import React from 'react';
import PropTypes from 'prop-types';
/* components */
import EmptyContent from 'components/empty-content/empty-content';
/* helpers */
import { formatValue, getClassName } from 'utils/helpers/info-helper';

const tableRenderer = (data, config) => config.rows.map(row => (
    <tr key={row.key}>
        <td className="text-secondary">{row.label}</td>
        {config.columns.length
            ? config.columns.map(col => {
                if (col.renderValue) {
                    return (
                        <td
                            className={col.getCellClass ? col.getCellClass(data, row) : ''}
                            key={col.key}
                        >{col.renderValue(data, col, row)}
                        </td>
                    );
                }
                return (
                    <td
                        className={row.getCellClass ? row.getCellClass(data, col) : ''}
                        key={`${col}.${row.key}`}
                    >{row.renderValue
                            ? row.renderValue(data[col], row, col)
                            : formatValue(data, row, `${col}.${row.key}`)
                        }
                    </td>
                );
            })
            : (<td>{row.renderValue ? row.renderValue(data, row) : formatValue(data, row)}</td>)
        }
    </tr>
));

class WeatherPerformanceReportPanel extends React.PureComponent {
    render() {
        return (
            <div className={getClassName('sten-weather-performance-report-panel sten-panel', this.props.className)}>
                <div className="sten-panel__header">
                    <h4 className="text-uppercase">{this.props.title}</h4>
                </div>
                <div className="sten-panel__body spread-v">
                    {!this.props.data
                        ? (<EmptyContent />)
                        : (
                            <table className="sten-table sten-table--xs">
                                {this.props.config.headerLabels && (
                                    <thead>
                                        <tr>
                                            {this.props.config.headerLabels.map((hl, i) => (
                                                // eslint-disable-next-line react/no-array-index-key
                                                <th key={i} className="text-left">{hl}</th>
                                            ))}
                                        </tr>
                                    </thead>
                                )}
                                <tbody>
                                    {tableRenderer(this.props.data, this.props.config)}
                                </tbody>
                            </table>
                        )}
                </div>
            </div>
        );
    }
}

WeatherPerformanceReportPanel.propTypes = {
    className: PropTypes.string,
    config: PropTypes.shape({
        rows: PropTypes.arrayOf(PropTypes.any),
        columns: PropTypes.arrayOf(PropTypes.any),
        headerLabels: PropTypes.arrayOf(PropTypes.any)
    }),
    data: PropTypes.objectOf(PropTypes.any),
    title: PropTypes.string
};

WeatherPerformanceReportPanel.defaultProps = {
    className: '',
    config: null,
    data: null,
    title: ''
};

export default WeatherPerformanceReportPanel;
