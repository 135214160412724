import { createSelector } from 'reselect';
import {
    generateMiddlePoints,
    mapLocationLonLat,
    mapLonLat,
    mapPositionLonLat,
    splitRoute180
} from 'components/ol/ol-helpers';
import RouteHelper from 'utils/helpers/route-helper';
import TimeHelper from 'utils/helpers/time-helper';
import { calculateCourse } from './weather-routing-helpers';

const tU = TimeHelper.units;

const remapPortProps = (port) => ({
    Id: port.PortId,
    Name: port.Name,
    PortCode: port.PortCode,
    Location: port.Position,
    selectedInVoyage: true
});

export const getCombinedPorts = createSelector(
    state => state.weatherRoutingReducer.ports,
    (state, ownProps) => ownProps.selectedRoutes,
    (ports, selectedRoutes) => {
        if (selectedRoutes) {
            const combinedPorts = [];
            selectedRoutes.forEach(route => {
                if (route.PortOfDeparture && !combinedPorts.find(el => el.Id === route.PortOfDeparture.PortId)) {
                    combinedPorts.push(remapPortProps(route.PortOfDeparture));
                }
                if (route.PortOfDestination && !combinedPorts.find(el => el.Id === route.PortOfDestination.PortId)) {
                    combinedPorts.push(remapPortProps(route.PortOfDestination));
                }
            });
            ports.forEach(port => {
                if (!combinedPorts.find(el => el.Id === port.Id)) {
                    combinedPorts.push(port);
                }
            });

            return combinedPorts;
        }
        return ports;
    }
);

export const getConvertedLegRequests = createSelector(
    state => state.weatherRoutingReducer.selectedLegRequests,
    (requests) => {
        if (!requests.length) {
            return null;
        }
        const res = {};
        requests.forEach(request => {
            if (!res[request.Intention.Name]) {
                res[request.Intention.Name] = [];
            }
            res[request.Intention.Name].push({
                ...request,
                ExternalRequests: request.ExternalRequests.map(er => {
                    const { RouteAdvice } = er;
                    return {
                        ...er,
                        RouteAdvice: RouteAdvice
                            ? {
                                ...RouteAdvice,
                                Points: RouteAdvice.Points.map((point, index) => {
                                    let Course = point.Course || 0;
                                    if (!Course && RouteAdvice.Points.length > 1) {
                                        Course = RouteAdvice.Points[index - 1]
                                            ? calculateCourse(RouteAdvice.Points[index - 1], point, true)
                                            : calculateCourse(point, RouteAdvice.Points[index + 1], true);
                                    }
                                    return {
                                        ...point,
                                        Course
                                    };
                                }),
                                Route: splitRoute180(RouteAdvice.Points.map(mapLonLat))
                            }
                            : null
                    };
                })
            });
        });
        return res;
    }
);

export const getConvertedLegSposRoutes = createSelector(
    state => state.weatherRoutingReducer.selectedLegSposRoutes,
    (routes) => {
        return routes.map(route => ({
            ...route,
            Points: route.Waypoints.map((wp, index) => {
                let Course = wp.Course || 0;
                if (!Course && route.Waypoints.length > 1) {
                    Course = route.Waypoints[index - 1]
                        ? calculateCourse(route.Waypoints[index - 1].Position, wp.Position, true)
                        : calculateCourse(wp.Position, route.Waypoints[index + 1].Position, true);
                }
                return {
                    ...wp,
                    Longitude: wp.Position.Longitude,
                    Latitude: wp.Position.Latitude,
                    Distance: wp.SpeedOverGround && route.Waypoints[index - 1]
                        ? (TimeHelper.getDateDiff(wp.Time, route.Waypoints[index - 1].Time) / tU.hour)
                            * wp.SpeedOverGround
                        : 0,
                    Course
                };
            }),
            Route: splitRoute180(
                generateMiddlePoints(route.Waypoints.map(mapPositionLonLat))
            )
        }));
    }
);

const emptyArray = [];

export const getSelectedLegAisPoints = createSelector(
    state => state.weatherRoutingReducer.selectedLegAisPoints,
    aisPoints => (
        aisPoints
            ? aisPoints.map(mapLocationLonLat)
            : emptyArray
    )
);

export const getSelectedLegAisRoute = createSelector(
    state => state.weatherRoutingReducer.selectedLegAisPoints,
    RouteHelper.extractRoutesFromAisPoints
);
