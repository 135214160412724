import CoreApi from './core-api';

class AreaService {
    constructor() {
        this.resourceUrl = 'Areas';
    }

    getTypes() {
        return CoreApi.get(`${this.resourceUrl}/Types`)
            .then(response => response.data, () => null);
    }

    getAreasByTypeId(areaTypesIds, showHidden = false) {
        return CoreApi.post(this.resourceUrl, { AreaTypeIds: areaTypesIds, showHidden })
            .then(response => response.data, () => null);
    }

    getVesselDistributionAreas() {
        return CoreApi.get(`${this.resourceUrl}/VesselDistributionAreas`)
            .then(response => response.data, () => null);
    }
}

export default new AreaService();
