import getFontSize from 'utils/helpers/cached-font-size';

const chartColors = ['#28ffca', '#ffffff', '#ffffff'];
const colorChartPlotBg = '#122830';
const colorChartBg = '#122830';

export const getChartConfig = () => {
    const rem = getFontSize();
    const fsm = rem / 16;

    return {
        colors: chartColors,
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                connectNulls: true
            }
        },
        chart: {
            spacing: [rem, rem, rem, rem],
            backgroundColor: colorChartBg,
            plotBackgroundColor: colorChartPlotBg,
            zoomType: false,
            panning: false,
            dynamicExtremes: false
        },
        xAxis: {
            minRange: 5,
            type: 'category',
            tickInterval: null,
            tickPositioner: null,
            tickLength: 0,
            startOnTick: true,
            endOnTick: false,
            labels: { enabled: false, formatter: undefined },
            plotLines: [{
                color: 'rgba(255,255,255,0.4)',
                width: fsm,
                zIndex: 5,
                value: 11
            }]
        },
        yAxis: {
            minTickInterval: 500,
            plotLines: [{
                color: 'rgba(255,255,255,0.4)',
                width: fsm,
                zIndex: 5,
                value: 0
            }]
        },
        series: [
            {
                name: 'Fleet TCE',
                type: 'line',
                lineWidth: 2 * fsm,
                zIndex: 2,
                data: []
            },
            {
                name: 'Index TCE',
                type: 'line',
                lineWidth: 2 * fsm,
                data: []
            },
            {
                name: 'Fix Rate',
                type: 'line',
                lineWidth: 0,
                data: [],
                states: { hover: { lineWidthPlus: 0 } },
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    valuePrefix: '',
                    valueSuffix: '%'
                }
            }
        ],
        tooltip: {
            valueDecimals: 0,
            valuePrefix: '$ '
        }
    };
};
