import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { renderInfoRows, LABEL_CELL, VALUE_CELL } from 'utils/helpers/panel-info-helper';
import { translate } from 'utils/i18n/i18n-model';
import { getClassName, formatValue } from 'utils/helpers/info-helper';
/* components */
import EmptyContent from 'components/empty-content/empty-content';
import TankLayoutVessel from 'components/tank-layout/vessel/tank-layout-vessel';
/* constants */
import { cargoRows } from '../voyage-performance-constants';

const t = (val) => translate(`VOYAGE_PERFORMANCE.CARGO.${val}`);

class VoyagePerformanceCargoPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.columnClassNames = ['col-10', 'col-14'];
    }

    getRowConfigs = (data) => {
        if (data) {
            return cargoRows.map((row) => ([
                { value: row.label, type: LABEL_CELL },
                { value: formatValue(data, row), type: VALUE_CELL }
            ]));
        }

        return [];
    };

    render() {
        const rowConfigs = this.getRowConfigs(this.props.cargoSummary);

        return (
            <div className={getClassName('sten-panel sten-voyage-performance-cargo-panel', this.props.className)}>
                <div className="sten-panel__header">
                    <h4 className="flex-grow text-uppercase">{t('TITLE')}</h4>
                    <div className="flex-shrink">
                        <span className="icon sten-icon-info-tooltip" title={t('DISCLAIMER')} />
                    </div>
                </div>
                {!this.props.cargoSummary && (!this.props.wings || this.props.wings.length === 0) && (
                    <EmptyContent className="flex-grow" />
                )}
                {this.props.wings && this.props.wings.length > 0 && (
                    <div className="sten-panel__section">
                        <div className="flex flex-col flex-center">
                            <TankLayoutVessel wings={this.props.wings} />
                        </div>
                    </div>
                )}
                {this.props.cargoSummary && (
                    <div className="sten-panel__section">
                        {renderInfoRows(rowConfigs, this.columnClassNames)}
                    </div>
                )}
            </div>
        );
    }
}

VoyagePerformanceCargoPanel.propTypes = {
    cargoSummary: PropTypes.objectOf(PropTypes.any),
    className: PropTypes.string,
    wings: PropTypes.arrayOf(PropTypes.any)
};

VoyagePerformanceCargoPanel.defaultProps = {
    cargoSummary: null,
    className: '',
    wings: undefined
};

export default VoyagePerformanceCargoPanel;
