const keys = {
    homePage: 'homePage',
    lastVisitedLocation: 'lastVisitedLocation',
    debugMode: 'debugMode'
};

class LocalStorageService {
    clearAll() {
        localStorage.removeItem(keys.homePage);
        localStorage.removeItem(keys.lastVisitedLocation);
        localStorage.removeItem(keys.debugMode);
    }

    setLastVisitedLocation(location) {
        const lastVisitedLocation = `${location.pathname}${location.search}`;
        localStorage.setItem(keys.lastVisitedLocation, lastVisitedLocation);
    }

    getLastVisitedLocation() {
        return localStorage.getItem(keys.lastVisitedLocation);
    }

    setHomePage(homePage) {
        localStorage.setItem(keys.homePage, homePage);
    }

    getHomePage() {
        return localStorage.getItem(keys.homePage);
    }

    isDebugModeActive() {
        return localStorage.getItem(keys.debugMode);
    }

    toggleDebugMode() {
        const debugMode = this.isDebugModeActive();
        if (debugMode !== 'active') {
            localStorage.setItem(keys.debugMode, 'active');
            return true;
        }
        localStorage.removeItem(keys.debugMode);
        return false;
    }
}

export default new LocalStorageService();
