import { tableHeaderBg } from './colors';

export const border = '1px solid #ccc';

export default `
/* STEN TABLE */
.sten-table {
    width: 100%;
    border-collapse: collapse;
    border-bottom: ${border};
}

.sten-table thead {
    background-color: ${tableHeaderBg};
}
.sten-table tr {
    height: 100%;
    border-top: ${border};
}
.sten-table th, .sten-table td {
    padding: 0.5rem 2rem;
}
.sten-table th.sten-table__cell--no-padding, .sten-table td.sten-table__cell--no-padding {
    padding: 0;
}
.sten-table th:not(:first-child), .sten-table td:not(:first-child) {
    border-left: ${border};
}
.sten-table th {
    text-transform: uppercase;
    text-align: left;
    white-space: nowrap;
    font-weight: 400;
    font-size: 1.125rem;
    height: 3rem;
}
.sten-table td {
    font-size: 0.875rem;
    height: 1px;
}

@-moz-document url-prefix() {
    .sten-table td {
        height: 100%;
    }
}
.sten-table--inverted {
    background-color: #ffffff;
    border-bottom: ${border};
}
.sten-table--inverted thead {
    background-color: ${tableHeaderBg};
}
.sten-table--inverted tr {
    border-top: ${border};
}

.sten-table--sm td, .sten-table--sm th {
    padding: 0.25rem 0.5rem;
}
.sten-table--sm th {
    text-transform: none;
    font-size: 0.875rem;
    height: auto;
}
.sten-table--no-border-bottom {
    border-bottom: none;
}
.sten-table--no-border-top tr:first-child {
    border-top: none;
}
.sten-table--no-border-vertical th:not(:first-child), .sten-table--no-border-vertical td:not(:first-child) {
    border-left: none;
}
`;
