import React from 'react';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { formatNumber, getObjectProp } from 'utils/helpers/info-helper';
import { mapArrayByProp } from 'utils/helpers/array-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* components */
import { TankLayout } from 'components/tank-layout';
/* services */
import EnergyManagementService from 'services/core-api/energy-management-service';

export const visibilityBadgeTypes = {
    available: { key: 'included', className: 'sten-badge-toggle--secondary' },
    included: { key: 'included', className: 'sten-badge-toggle--secondary' },
    excluded: { key: 'excluded', className: 'sten-badge-toggle--alt' },
    missing: { key: 'missing', className: 'sten-badge-toggle--danger' }
};

export const badges = {
    missing: <span className="sten-badge sten-badge--danger">{t('RAW_REPORTS_TABLE.MISSING')}</span>,
    updated: <span className="sten-badge sten-badge--success">{t('RAW_REPORTS_TABLE.UPDATED')}</span>,
    excluded: <span className="sten-badge sten-badge--alt">{t('RAW_REPORTS_TABLE.EXCLUDED')}</span>,
    suspicious: <span className="sten-badge sten-badge--warning">{t('RAW_REPORTS_TABLE.SUSPICIOUS')}</span>,
    deleted: <span className="sten-badge sten-badge--danger">{t('RAW_REPORTS_TABLE.DELETED')}</span>
};

const getValueDirectionFormatted = (valueProp, directionProp, unit) => (report) => {
    let value = getObjectProp(report, valueProp);
    if (typeof value === 'number') {
        value = `${formatNumber(value, 0)} ${unit}`;
        const direction = getObjectProp(report, directionProp);
        if (direction && direction.Name) {
            value += ` (${direction.Name})`;
        }
    } else {
        value = '-';
    }
    return value;
};

const commonRows = {
    callSign: { key: 'CallSign', type: 'string', label: t('REPORT_LABELS.CALL_SIGN') },
    capacity: { type: 'number', decimals: 0, unit: t('UNITS.CUBIC_CAPACITY'), numFmt: '0 "m³"' },
    cargo: { type: 'number', decimals: 0, numFmt: '0 "mt"', unit: t('UNITS.CARGO') },
    charterpartyDate: {
        key: 'CharterpartyDate',
        type: 'date',
        label: t('REPORT_LABELS.CHARTERPARTY_DATE'),
        dateOptions: { time: false, utc: true }
    },
    displacement: {
        key: 'Displacement',
        type: 'number',
        label: t('REPORT_LABELS.DISPLACEMENT'),
        unit: t('UNITS.DISPLACEMENT'),
        numFmt: '0 "mt"'
    },
    distance: { type: 'number', unit: t('UNITS.DISTANCE'), decimals: 2, numFmt: '0.00 "NM"' },
    draft: { type: 'number', unit: t('UNITS.DRAFT'), numFmt: '0.0' },
    duration: { type: 'duration', multiplier: 60000, displayFormat: ['h', 'm'] },
    lastModified: {
        key: 'LastModifiedBy',
        type: 'edit',
        dateKey: 'LastModifiedDate',
        label: t('REPORT_LABELS.EDIT_INFO'),
        hiddenInExport: true
    },
    percentage: { type: 'number', decimals: 0, unit: t('UNITS.PERCENTAGE'), numFmt: '0"%"' },
    position: { key: 'Position', type: 'position', label: t('REPORT_LABELS.POSITION') },
    pressure: { type: 'number', decimals: 0, unit: t('UNITS.PRESSURE'), numFmt: '0 "bar"' },
    reportDate: {
        key: 'ReportDate',
        type: 'date',
        offsetKey: 'ReportDateOffset',
        label: t('REPORT_LABELS.REPORT_DATE'),
        isActionCell: true
    },
    speed: { type: 'number', unit: t('UNITS.SPEED'), numFmt: '0.0' },
    isSyncedWithVeracity: {
        key: 'IsSyncedWithVeracity',
        type: 'syncedWithVeracity',
        label: t('REPORT_LABELS.SYNC_WITH_VERACITY')
    },
    valueDirection: (valueProp, directionProp, unit) => ({
        type: 'custom',
        getFormatted: getValueDirectionFormatted(valueProp, directionProp, unit)
    }),
    voyageNumber: { key: 'Voyage.VoyageNumber', type: 'string', label: t('REPORT_LABELS.VOYAGE_NUMBER') }
};

export const rawReportTypes = [
    {
        id: 1,
        type: 'daily',
        title: t('RAW_REPORTS_TABLE.DAILY_REPORTS'),
        key: 'DailyReports',
        get: EnergyManagementService.Reports.VoyageReport.getDailyReports
    },
    {
        id: 3,
        type: 'arrival',
        title: t('RAW_REPORTS_TABLE.ARRIVAL_REPORTS'),
        key: 'ArrivalReports',
        get: EnergyManagementService.Reports.VoyageReport.getArrivalReports
    },
    {
        id: 2,
        type: 'departure',
        title: t('RAW_REPORTS_TABLE.DEPARTURE_REPORTS'),
        key: 'DepartureReports',
        get: EnergyManagementService.Reports.VoyageReport.getDepartureReports
    },
    {
        id: 4,
        type: 'cargo',
        title: t('RAW_REPORTS_TABLE.CARGO_REPORTS'),
        key: 'CargoReports',
        get: EnergyManagementService.Reports.VoyageReport.getCargoReports
    },
    {
        id: 7,
        type: 'sof',
        title: t('RAW_REPORTS_TABLE.SOF_REPORTS'),
        key: 'SofReports',
        get: EnergyManagementService.Reports.VoyageReport.getSofReports
    },
    {
        id: 5,
        type: 'event',
        title: t('RAW_REPORTS_TABLE.EVENT_REPORTS'),
        key: 'EventReports',
        get: EnergyManagementService.Reports.VoyageReport.getEventReports
    }
];

export const rawReportTypesByType = mapArrayByProp(rawReportTypes, 'type');

export const reportTypeMap = {
    daily: 1,
    departure: 2,
    arrival: 3,
    cargo: 4,
    event: 5,
    nextLeg: 6,
    sof: 7
};

export const reportProps = {
    daily: {
        title: t('RAW_REPORTS_TABLE.DAILY_REPORTS'),
        cellWidth: 22,
        rows: [
            commonRows.reportDate,
            commonRows.isSyncedWithVeracity,
            commonRows.lastModified,
            { key: 'ReportSubtype.DisplayName', type: 'string', label: t('REPORT_LABELS.SUBTYPE') },
            commonRows.callSign,
            commonRows.voyageNumber,
            commonRows.charterpartyDate,
            commonRows.position,
            {
                key: 'Course',
                type: 'number',
                label: t('REPORT_LABELS.COURSE'),
                unit: t('UNITS.COURSE'),
                numFmt: '0 "°"'
            },
            { key: 'CargoStatus.Name', type: 'string', label: t('REPORT_LABELS.VESSEL_CONDITION') },
            { key: 'VesselStatus.Name', type: 'string', label: t('REPORT_LABELS.VESSEL_STATUS') },
            { ...commonRows.speed, key: 'DailyReportAtSea.LogSpeed', label: t('REPORT_LABELS.LOG_SPEED') },
            { ...commonRows.distance, key: 'DailyReportAtSea.LogDistance', label: t('REPORT_LABELS.LOG_DISTANCE') },

            // Since Last Event

            {
                ...commonRows.duration,
                key: 'TimeElapsedSinceLastEvent',
                label: t('REPORT_LABELS.TIME_SINCE_LAST_EVENT')
            },
            {
                ...commonRows.duration,
                key: 'TimeAtAnchorSinceLastEvent',
                label: t('REPORT_LABELS.TIME_AT_ANCHOR_SINCE_LAST_EVENT')
            },
            {
                ...commonRows.duration,
                key: 'HoursSteamedSinceLastEvent',
                label: t('REPORT_LABELS.HOURS_STEAMED_SINCE_LAST_EVENT')
            },
            {
                ...commonRows.distance,
                key: 'DistanceSinceLastEvent',
                label: t('REPORT_LABELS.DISTANCE_SINCE_LAST_EVENT')
            },
            {
                key: 'WindForceSinceLastEvent',
                type: 'custom',
                label: t('REPORT_LABELS.WIND_FORCE_SINCE_LAST_EVENT'),
                getFormatted: (report) => (report.WindForceSinceLastEvent
                    ? `${report.WindForceSinceLastEvent.Id} ${t('UNITS.WIND_FORCE')}
                       (${report.WindForceSinceLastEvent.Name})`
                    : '-')
            },
            {
                ...commonRows.cargo,
                key: 'TotalCargoSinceLastEvent',
                label: t('REPORT_LABELS.TOTAL_CARGO_ONBOARD')
            },
            {
                key: 'ConsumptionsSinceLastEvent',
                type: 'fuelTypes',
                selectable: false,
                label: t('REPORT_LABELS.CONSUMPTIONS_SINCE_LAST_EVENT'),
                dataGroup: 'ConsumptionsSinceLastEvent'
            },
            'ConsumptionSinceLastEventTypes',

            // Port information

            {
                key: 'DailyReportAtPort.PortActivityType.Name',
                type: 'string',
                label: t('REPORT_LABELS.PORT_ACTIVITY_TYPE')
            },
            { key: 'PreviousPort.Name', type: 'string', label: t('REPORT_LABELS.PREVIOUS_PORT') },
            { key: 'NextPoint.Name', type: 'string', label: t('REPORT_LABELS.NEXT_PORT') },
            { key: 'DailyReportAtPort.Point.Name', type: 'string', label: t('REPORT_LABELS.CURRENT_PORT') },
            {
                key: 'DailyReportAtPort.BerthTerminalAnchorage',
                type: 'string',
                label: t('REPORT_LABELS.BERTH_TERMINAL_ANCHORAGE')
            },
            { key: 'EtaDate', type: 'date', offsetKey: 'EtaDateOffset', label: t('REPORT_LABELS.ETA_DATE') },
            {
                key: 'DailyReportAtAnchorage.EstimatedTimeOfBerthing',
                type: 'date',
                offsetKey: 'DailyReportAtAnchorage.EstimatedTimeOfBerthingOffset',
                label: t('REPORT_LABELS.ETB_DATE')
            },
            {
                key: 'EstimatedTimeOfDeparture',
                keys: ['DailyReportAtAnchorage', 'DailyReportAtPort'],
                type: 'custom',
                label: t('REPORT_LABELS.ETD_DATE'),
                getFormatted: (report) => {
                    let etd;
                    let etdOffset;
                    if (report.DailyReportAtAnchorage) {
                        etd = getObjectProp(report, 'DailyReportAtAnchorage.EstimatedTimeOfDeparture');
                        etdOffset = getObjectProp(report, 'DailyReportAtAnchorage.EstimatedTimeOfDepartureOffset');
                    }
                    if (report.DailyReportAtPort) {
                        etd = getObjectProp(report, 'DailyReportAtPort.EstimatedTimeOfDeparture');
                        etdOffset = getObjectProp(report, 'DailyReportAtPort.EstimatedTimeOfDepartureOffset');
                    }
                    if (!etd) {
                        return '-';
                    }
                    const date = TimeHelper.getFormatted(etd, { time: true, utc: true });
                    const dateOffset = typeof etdOffset === 'number'
                        ? ` ${TimeHelper.getUTCOffsetString(etdOffset)}`
                        : '';
                    return `${date}${dateOffset}`;
                }
            },
            {
                ...commonRows.distance,
                key: 'DistanceToGo',
                label: t('REPORT_LABELS.DISTANCE_TO_GO')
            },
            { ...commonRows.draft, key: 'DraftFwd', label: t('REPORT_LABELS.DRAFT_FWD') },
            { ...commonRows.draft, key: 'DraftMid', label: t('REPORT_LABELS.DRAFT_MID') },
            { ...commonRows.draft, key: 'DraftAft', label: t('REPORT_LABELS.DRAFT_AFT') },
            {
                key: 'DailyReportAtPort.CommencedCargoDate',
                type: 'date',
                offsetKey: 'DailyReportAtPort.CommencedCargoDateOffset',
                label: t('REPORT_LABELS.COMMENCED_CARGO_DATE')
            },
            {
                ...commonRows.cargo,
                key: 'DailyReportAtPort.QtyLoadedDischarged',
                label: t('REPORT_LABELS.QTY_LOADED_DISCHARGED')
            },
            {
                ...commonRows.cargo,
                key: 'DailyReportAtPort.TotalCargoLoadedDischarged',
                label: t('REPORT_LABELS.TOTAL_CARGO_LOADED_DISCHARGED')
            },
            {
                ...commonRows.cargo,
                key: 'DailyReportAtPort.BalanceCargoToLoadDischarge',
                label: t('REPORT_LABELS.BALANCE_CARGO_LOADED_DISCHARGED')
            },
            {
                ...commonRows.cargo,
                key: 'DailyReportAtPort.AverageLoadDischargeRate',
                label: t('REPORT_LABELS.AVERAGE_LOAD_DISCHARGE_RATE')
            },
            {
                key: 'DailyReportAtPort.NoOfGangsHatchesWorking',
                type: 'number',
                label: t('REPORT_LABELS.NUMBER_OF_GANGS_HATCHES_WORKING'),
                decimals: 0,
                numFmt: '0'
            },

            // Remarks

            { key: 'Remarks', type: 'string', label: t('REPORT_LABELS.REMARKS') },

            // SLDR

            { ...commonRows.duration, key: 'HoursSinceLastReport', label: t('REPORT_LABELS.HOURS_SINCE_LAST_REPORT') },
            {
                ...commonRows.duration,
                key: 'HoursSteamedSinceLastReport',
                label: t('REPORT_LABELS.HOURS_STEAMED_SINCE_LAST_REPORT')
            },
            {
                ...commonRows.distance,
                key: 'TotalDistanceSteamedSinceLastReport',
                label: t('REPORT_LABELS.TOTAL_DISTANCE_STEAMED_SINCE_LAST_REPORT')
            },
            {
                ...commonRows.distance,
                key: 'EcaDistanceSteamedSinceLastReport',
                label: t('REPORT_LABELS.ECA_DISTANCE_STEAMED_SINCE_LAST_REPORT')
            },
            { ...commonRows.speed, key: 'InstructedSpeed', label: t('REPORT_LABELS.INSTRUCTED_SPEED') },
            { ...commonRows.speed, key: 'AverageSpeed', label: t('REPORT_LABELS.AVERAGE_SPEED') },
            { key: 'Rpm', type: 'number', label: t('REPORT_LABELS.RPM'), numFmt: '0.0' },
            { key: 'AverageRpm', type: 'number', label: t('REPORT_LABELS.AVERAGE_RPM'), numFmt: '0.0' },
            {
                key: 'DailyReportAtSea.SpeedInstructionType.Name',
                type: 'string',
                label: t('REPORT_LABELS.SPEED_INSTRUCTION_TYPE')
            },
            {
                key: 'DailyReportAtSea.MePowerInKw',
                type: 'number',
                label: t('REPORT_LABELS.ME_POWER'),
                unit: t('UNITS.POWER_KW'),
                decimals: 2,
                numFmt: '0.00 "kW"'
            },

            // Bunker data

            {
                key: 'BunkerData',
                type: 'fuelTypes',
                selectable: false,
                label: t('REPORT_LABELS.BUNKER_DATA'),
                dataGroup: 'BunkerData'
            },
            'BunkerDataTypes',

            // Consumptions

            {
                key: 'Consumptions',
                type: 'fuelTypes',
                selectable: false,
                label: t('REPORT_LABELS.CONSUMPTIONS'),
                dataGroup: 'Consumptions'
            },
            'ConsumptionTypes',

            // Utilization

            {
                key: 'Utilization.AuxiliaryRunningHours',
                type: 'utilizationLabels',
                utilizationLabel: 'Aux',
                selectable: false,
                label: t('REPORT_LABELS.AUXILIARY'),
                dataGroup: 'Auxiliary'
            },
            {
                key: 'Utilization.AuxiliaryRunningHours.values',
                type: 'utilizationValues',
                label: t('REPORT_LABELS.RUNNING_HOURS_PER_DAY'),
                dataGroup: 'Auxiliary'
            },
            {
                key: 'Utilization.AuxiliaryEnergyProduced.values',
                type: 'utilizationValues',
                label: t('REPORT_LABELS.ENERGY_PRODUCED'),
                dataGroup: 'Auxiliary'
            },
            {
                key: 'Utilization.BoilerRunningHours',
                type: 'utilizationLabels',
                utilizationLabel: 'Boiler',
                selectable: false,
                label: t('REPORT_LABELS.BOILER'),
                dataGroup: 'Boiler'
            },
            {
                key: 'Utilization.BoilerRunningHours.values',
                type: 'utilizationValues',
                label: t('REPORT_LABELS.RUNNING_HOURS_PER_DAY'),
                dataGroup: 'Boiler'
            },

            // Weather

            {
                key: 'WindBeaufort',
                type: 'custom',
                label: t('REPORT_LABELS.WIND_FORCE'),
                getFormatted: (report) => (report.WindBeaufort
                    ? `${report.WindBeaufort.Id} ${t('UNITS.WIND_FORCE')} (${report.WindBeaufort.Name})`
                    : '-')
            },
            {
                key: 'WindBeaufortExternal',
                type: 'custom',
                label: t('REPORT_LABELS.WIND_FORCE_EXTERNAL'),
                getFormatted: (report) => (report.WindBeaufortExternal
                    ? `${report.WindBeaufortExternal.Id} ${t('UNITS.WIND_FORCE')} (${report.WindBeaufortExternal.Name})`
                    : '-')
            },
            {
                ...commonRows.valueDirection('WindSpeed', 'WindDirection', t('UNITS.WIND_SPEED')),
                key: 'Wind',
                label: t('REPORT_LABELS.WIND')
            },
            {
                ...commonRows.valueDirection('SwellHeight', 'SwellDirection', t('UNITS.SWELL_HEIGHT')),
                key: 'Swell',
                label: t('REPORT_LABELS.SWELL')
            },
            {
                key: 'SeasHeightAndCondition',
                type: 'custom',
                label: t('REPORT_LABELS.SEA'),
                getFormatted: (report) => {
                    let value = '-';
                    if (typeof report.SeasHeight === 'number') {
                        value = `${formatNumber(report.SeasHeight, 0)} ${t('UNITS.SEA_HEIGHT')}`;
                    }
                    if (report.SeaCondition) {
                        value += ` (${report.SeaCondition})`;
                    }
                    return value;
                }
            },
            {
                key: 'SeasHeightAndDirection',
                keys: ['DailyReportAtSea', 'DailyReportAtAnchorage'],
                label: t('REPORT_LABELS.SEA'),
                type: 'custom',
                getFormatted: (report) => {
                    if (report.DailyReportAtSea) {
                        return getValueDirectionFormatted(
                            'SeasHeight', 'DailyReportAtSea.SeasDirection', t('UNITS.SEA_HEIGHT')
                        )(report);
                    }
                    if (report.DailyReportAtAnchorage) {
                        return getValueDirectionFormatted(
                            'SeasHeight', 'DailyReportAtAnchorage.SeasDirection', t('UNITS.SEA_HEIGHT')
                        )(report);
                    }
                    return '-';
                }
            },
            { key: 'SeaCondition', type: 'string', label: t('REPORT_LABELS.SEA_CONDITION') },
            {
                ...commonRows.valueDirection(
                    'DailyReportAtSea.CurrentKn', 'DailyReportAtSea.CurrentDirection', t('UNITS.SPEED')
                ),
                key: 'CurrentSpeedAndDirection',
                keys: ['DailyReportAtSea', 'DailyReportAtAnchorage'],
                label: t('REPORT_LABELS.CURRENT'),
                type: 'custom',
                getFormatted: (report) => {
                    if (report.DailyReportAtSea) {
                        return getValueDirectionFormatted(
                            'DailyReportAtSea.CurrentKn',
                            'DailyReportAtSea.CurrentDirection',
                            t('UNITS.SPEED')
                        )(report);
                    }
                    if (report.DailyReportAtAnchorage) {
                        return getValueDirectionFormatted(
                            'DailyReportAtAnchorage.CurrentKn',
                            'DailyReportAtAnchorage.CurrentDirection',
                            t('UNITS.SPEED')
                        )(report);
                    }
                    return '-';
                }
            },
            { key: 'InmarsatAreaCodeInUse', type: 'string', label: t('REPORT_LABELS.INMARSAT_AREA_CODE') },

            // Additional Info

            { ...commonRows.percentage, key: 'Mcr', label: t('REPORT_LABELS.MCR') },
            { ...commonRows.percentage, key: 'Slip', label: t('REPORT_LABELS.SLIP') },
            {
                key: 'LastPropellerCleaning',
                type: 'date',
                label: t('REPORT_LABELS.LAST_PROPELLER_CLEANING'),
                hiddenInExport: true
            },
            {
                key: 'LastHullCleaning',
                type: 'date',
                label: t('REPORT_LABELS.LAST_HULL_CLEANING'),
                hiddenInExport: true
            },
            {
                key: 'FreshWaterAvailableForTankCleaning',
                type: 'number',
                decimals: 0,
                label: t('REPORT_LABELS.FRESH_WATER_FOR_TANK_CLEANING'),
                unit: t('UNITS.FRESH_WATER'),
                numFmt: '0 "mt"'
            },
            {
                key: 'WaterTemperature',
                type: 'number',
                decimals: 1,
                label: t('REPORT_LABELS.WATER_TEMPERATURE'),
                unit: t('UNITS.TEMPERATURE'),
                numFmt: '0.0 "°C"'
            },

            // Exclusions

            {
                key: 'ExclusionReasons',
                type: 'array',
                label: t('REPORT_LABELS.EXCLUSION_REASONS'),
                hiddenWithoutInclusions: true
            }
        ]
    },
    arrival: {
        title: t('RAW_REPORTS_TABLE.ARRIVAL_REPORTS'),
        cellWidth: 22,
        rows: [

            // Basic info

            commonRows.reportDate,
            commonRows.isSyncedWithVeracity,
            commonRows.lastModified,
            commonRows.callSign,
            commonRows.voyageNumber,
            commonRows.charterpartyDate,
            commonRows.position,
            { key: 'CargoStatus.Name', type: 'string', label: t('REPORT_LABELS.VESSEL_CONDITION') },
            { key: 'VesselStatus.Name', type: 'string', label: t('REPORT_LABELS.VESSEL_STATUS') },

            // Since Last Event

            {
                ...commonRows.duration,
                key: 'TimeElapsedSinceLastEvent',
                label: t('REPORT_LABELS.TIME_SINCE_LAST_EVENT')
            },
            {
                ...commonRows.duration,
                key: 'TimeAtAnchorSinceLastEvent',
                label: t('REPORT_LABELS.TIME_AT_ANCHOR_SINCE_LAST_EVENT')
            },
            {
                ...commonRows.duration,
                key: 'HoursSteamedSinceLastEvent',
                label: t('REPORT_LABELS.HOURS_STEAMED_SINCE_LAST_EVENT')
            },
            {
                ...commonRows.distance,
                key: 'DistanceSinceLastEvent',
                label: t('REPORT_LABELS.DISTANCE_SINCE_LAST_EVENT')
            },
            {
                key: 'WindForceSinceLastEvent',
                type: 'custom',
                label: t('REPORT_LABELS.WIND_FORCE_SINCE_LAST_EVENT'),
                getFormatted: (report) => (report.WindForceSinceLastEvent
                    ? `${report.WindForceSinceLastEvent.Id} ${t('UNITS.WIND_FORCE')}
                       (${report.WindForceSinceLastEvent.Name})`
                    : '-')
            },
            {
                ...commonRows.cargo,
                key: 'TotalCargoSinceLastEvent',
                label: t('REPORT_LABELS.TOTAL_CARGO_ONBOARD')
            },
            {
                key: 'ConsumptionsSinceLastEvent',
                type: 'fuelTypes',
                selectable: false,
                label: t('REPORT_LABELS.CONSUMPTIONS_SINCE_LAST_EVENT'),
                dataGroup: 'ConsumptionsSinceLastEvent'
            },
            'ConsumptionSinceLastEventTypes',

            // Port information

            { key: 'PreviousPort.Name', type: 'string', label: t('REPORT_LABELS.PREVIOUS_PORT') },
            { key: 'PointOfArrival.Name', type: 'string', label: t('REPORT_LABELS.PORT_OF_ARRIVAL') },
            {
                key: 'EndOfSeaPassage',
                type: 'date',
                offsetKey: 'EndOfSeaPassageOffset',
                label: t('REPORT_LABELS.END_OF_SEA_PASSAGE')
            },
            { key: 'Terminal', type: 'string', label: t('REPORT_LABELS.BERTH_TERMINAL_ANCHORAGE') },
            {
                key: 'EstimatedTimeOfBerthing',
                type: 'date',
                offsetKey: 'EstimatedTimeOfBerthingOffset',
                label: t('REPORT_LABELS.ET_OF_BERTHING')
            },
            { ...commonRows.speed, key: 'AverageSpeed', label: t('REPORT_LABELS.AVERAGE_SPEED') },
            { ...commonRows.duration, key: 'TimeOfPassage', label: t('REPORT_LABELS.TIME_OF_PASSAGE') },
            {
                ...commonRows.distance,
                key: 'DistanceFromLastPort',
                label: t('REPORT_LABELS.DISTANCE_FROM_LAST_PORT')
            },
            {
                key: 'FreePratiqueGranted',
                type: 'date',
                offsetKey: 'FreePratiqueGrantedOffset',
                label: t('REPORT_LABELS.FREE_PRATIQUE_GRANTED')
            },
            {
                key: 'ArrivingPilotStation',
                type: 'date',
                offsetKey: 'ArrivingPilotStationOffset',
                label: t('REPORT_LABELS.ARRIVING_PILOT_STATION')
            },
            { key: 'CargoOperations', type: 'bool', label: t('REPORT_LABELS.CARGO_OPERATIONS') },
            {
                key: 'NoticeOfReadiness',
                type: 'date',
                offsetKey: 'NoticeOfReadinessOffset',
                label: t('REPORT_LABELS.NOR_TENDERED')
            },
            {
                key: 'AnchoredDate',
                type: 'date',
                offsetKey: 'AnchoredDateOffset',
                label: t('REPORT_LABELS.ANCHORED_DATE')
            },
            { ...commonRows.draft, key: 'DraftFwd', label: t('REPORT_LABELS.ARRIVAL_DRAFT_FWD') },
            { ...commonRows.draft, key: 'DraftMid', label: t('REPORT_LABELS.ARRIVAL_DRAFT_MID') },
            { ...commonRows.draft, key: 'DraftAft', label: t('REPORT_LABELS.ARRIVAL_DRAFT_AFT') },
            commonRows.displacement,
            {
                key: 'EstimatedTimeOfDepartureFromBerth',
                type: 'date',
                offsetKey: 'EstimatedTimeOfDepartureFromBerthOffset',
                label: t('REPORT_LABELS.ETD_FROM_BERTH_ANCHORAGE')
            },
            { key: 'AverageRpm', type: 'number', label: t('REPORT_LABELS.AVERAGE_RPM'), numFmt: '0.0' },

            // Remarks

            { key: 'Remarks', type: 'string', label: t('REPORT_LABELS.REMARKS') },

            // Bunker data

            {
                key: 'BunkerData',
                type: 'fuelTypes',
                selectable: false,
                label: t('REPORT_LABELS.BUNKER_DATA'),
                dataGroup: 'BunkerData'
            },
            'BunkerDataTypes',

            // Consumptions

            {
                key: 'Consumptions',
                type: 'fuelTypes',
                selectable: false,
                label: t('REPORT_LABELS.CONSUMPTIONS'),
                dataGroup: 'Consumptions'
            },
            'ConsumptionTypes',

            // Exclusions

            {
                key: 'ExclusionReasons',
                type: 'array',
                label: t('REPORT_LABELS.EXCLUSION_REASONS'),
                hiddenWithoutInclusions: true
            }
        ]
    },
    departure: {
        title: t('RAW_REPORTS_TABLE.DEPARTURE_REPORTS'),
        cellWidth: 22,
        rows: [

            // Basic info

            commonRows.reportDate,
            commonRows.isSyncedWithVeracity,
            commonRows.lastModified,
            commonRows.callSign,
            commonRows.voyageNumber,
            commonRows.charterpartyDate,
            commonRows.position,
            { key: 'CargoStatus.Name', type: 'string', label: t('REPORT_LABELS.VESSEL_CONDITION') },
            { key: 'VesselStatus.Name', type: 'string', label: t('REPORT_LABELS.VESSEL_STATUS') },

            // Since Last Event

            {
                ...commonRows.duration,
                key: 'TimeElapsedSinceLastEvent',
                label: t('REPORT_LABELS.TIME_SINCE_LAST_EVENT')
            },
            {
                ...commonRows.duration,
                key: 'TimeAtAnchorSinceLastEvent',
                label: t('REPORT_LABELS.TIME_AT_ANCHOR_SINCE_LAST_EVENT')
            },
            {
                ...commonRows.duration,
                key: 'HoursSteamedSinceLastEvent',
                label: t('REPORT_LABELS.HOURS_STEAMED_SINCE_LAST_EVENT')
            },
            {
                ...commonRows.distance,
                key: 'DistanceSinceLastEvent',
                label: t('REPORT_LABELS.DISTANCE_SINCE_LAST_EVENT')
            },
            {
                key: 'WindForceSinceLastEvent',
                type: 'custom',
                label: t('REPORT_LABELS.WIND_FORCE_SINCE_LAST_EVENT'),
                getFormatted: (report) => (report.WindForceSinceLastEvent
                    ? `${report.WindForceSinceLastEvent.Id} ${t('UNITS.WIND_FORCE')}
                       (${report.WindForceSinceLastEvent.Name})`
                    : '-')
            },
            {
                key: 'ConsumptionsSinceLastEvent',
                type: 'fuelTypes',
                selectable: false,
                label: t('REPORT_LABELS.CONSUMPTIONS_SINCE_LAST_EVENT'),
                dataGroup: 'ConsumptionsSinceLastEvent'
            },
            'ConsumptionSinceLastEventTypes',

            // Departure

            { key: 'PointOfDeparture.Name', type: 'string', label: t('REPORT_LABELS.PORT_OF_DEPARTURE') },
            { key: 'Terminal', type: 'string', label: t('REPORT_LABELS.BERTH_TERMINAL_ANCHORAGE') },
            {
                key: 'CompletionOfCargoOps',
                type: 'date',
                offsetKey: 'CompletionOfCargoOpsOffset',
                label: t('REPORT_LABELS.COMPLETION_CARGO_OPS')
            },
            {
                key: 'LeaveBerthTerminal',
                type: 'date',
                offsetKey: 'LeaveBerthTerminalOffset',
                label: t('REPORT_LABELS.LEAVE_BERT_TERMINAL')
            },
            {
                key: 'DroppingOutwardPilot',
                type: 'date',
                offsetKey: 'DroppingOutwardPilotOffset',
                label: t('REPORT_LABELS.DROPPING_OUTWARD_PILOT')
            },
            {
                key: 'DepartureDate',
                type: 'date',
                offsetKey: 'DepartureDateOffset',
                label: t('REPORT_LABELS.DEPARTURE_DATE')
            },
            {
                key: 'CargoLoadedDischarged',
                type: 'number',
                label: t('REPORT_LABELS.CARGO_LOADED_DISCHARGED_THIS_PORT'),
                decimals: 0,
                numFmt: '0 "mt"'
            },
            {
                key: 'CargoOps',
                type: 'number',
                label: t('REPORT_LABELS.CARGO_OPS'),
                decimals: 0,
                unit: 'h',
                numFmt: '0 "h"'
            },
            { ...commonRows.draft, key: 'DraftFwd', label: t('REPORT_LABELS.DRAFT_FWD') },
            { ...commonRows.draft, key: 'DraftMid', label: t('REPORT_LABELS.DRAFT_MID') },
            { ...commonRows.draft, key: 'DraftAft', label: t('REPORT_LABELS.DRAFT_AFT') },
            { key: 'TankCleaning', type: 'bool', label: t('REPORT_LABELS.TANK_CLEANING') },
            {
                key: 'TankCleaningStarted',
                type: 'date',
                offsetKey: 'TankCleaningStartedOffset',
                label: t('REPORT_LABELS.TANK_CLEANING_STARTED')
            },
            {
                key: 'TankCleaningFinished',
                type: 'date',
                offsetKey: 'TankCleaningFinishedOffset',
                label: t('REPORT_LABELS.TANK_CLEANING_FINISHED')
            },
            {
                ...commonRows.capacity,
                key: 'WaterUsedForTankCleaning',
                label: t('REPORT_LABELS.WATER_USED_FOR_TANK_CLEANING'),
                decimals: 2,
                numFmt: '0.00'
            },
            {
                key: 'NumberOfTugsUsed',
                type: 'number',
                label: t('REPORT_LABELS.NUMBER_OF_TUGS_USED'),
                decimals: 0,
                numFmt: '0'
            },
            { key: 'TugReason.Name', type: 'string', label: t('REPORT_LABELS.REASON_FOR_USING_TUGS') },

            // Remarks

            { key: 'Remarks', type: 'string', label: t('REPORT_LABELS.REMARKS') },

            // Bunker data

            {
                key: 'BunkerData',
                type: 'fuelTypes',
                selectable: false,
                label: t('REPORT_LABELS.BUNKER_DATA'),
                dataGroup: 'BunkerData'
            },
            'BunkerDataTypes',
            { key: 'BunkerDeliveryDate', type: 'date', label: t('REPORT_LABELS.BUNKER_DELIVERY_NOTE') },
            { key: 'BunkerDeliveryNoteNumber', type: 'string', label: t('REPORT_LABELS.BUNKER_DELIVERY_NOTE_NUMBER') },

            // Consumptions

            {
                key: 'Consumptions',
                type: 'fuelTypes',
                selectable: false,
                label: t('REPORT_LABELS.CONSUMPTIONS'),
                dataGroup: 'Consumptions'
            },
            'ConsumptionTypes',

            // Deadweight

            { key: 'DWTCargo', type: 'number', label: t('REPORT_LABELS.CARGO'), decimals: 2, numFmt: '0.00' },
            { key: 'DWTBallast', type: 'number', label: t('REPORT_LABELS.BALLAST'), decimals: 2, numFmt: '0.00' },
            { key: 'DWTSlops', type: 'number', label: t('REPORT_LABELS.SLOPS'), decimals: 2, numFmt: '0.00' },
            {
                key: 'DWTTotalBunkers',
                type: 'number',
                label: t('REPORT_LABELS.TOTAL_BUNKERS'),
                decimals: 2,
                numFmt: '0.00'
            },
            {
                key: 'DWTFreshWater',
                type: 'number',
                label: t('REPORT_LABELS.FRESH_WATER'),
                decimals: 2,
                numFmt: '0.00'
            },
            { key: 'DWTStores', type: 'number', label: t('REPORT_LABELS.STORES'), numFmt: '0.00', decimals: 2 },

            // Cleaning

            {
                key: 'LastPropellerCleaning',
                type: 'date',
                offsetKey: 'LastPropellerCleaningOffset',
                label: t('REPORT_LABELS.LAST_PROPELLER_CLEANING')
            },
            {
                key: 'PortOfLastPropellerCleaning.Name',
                type: 'string',
                label: t('REPORT_LABELS.PORT_OF_LAST_PROPELLER_CLEANING')
            },
            {
                key: 'LastHullCleaning',
                type: 'date',
                offsetKey: 'LastHullCleaningOffset',
                label: t('REPORT_LABELS.LAST_HULL_CLEANING')
            },
            {
                key: 'PortOfLastHullCleaning.Name',
                type: 'string',
                label: t('REPORT_LABELS.PORT_OF_LAST_HULL_CLEANING')
            },

            // Next port

            { key: 'NextPoint.Name', type: 'string', label: t('REPORT_LABELS.NEXT_PORT') },
            { key: 'EtaDate', type: 'date', offsetKey: 'EtaDateOffset', label: t('REPORT_LABELS.ETA_DATE') },
            { ...commonRows.speed, key: 'InstructedSpeed', label: t('REPORT_LABELS.INSTRUCTED_SPEED') },
            commonRows.displacement,
            {
                ...commonRows.distance,
                key: 'DistanceToGo',
                label: t('REPORT_LABELS.DISTANCE_TO_GO')
            },

            // Exclusions

            {
                key: 'ExclusionReasons',
                type: 'array',
                label: t('REPORT_LABELS.EXCLUSION_REASONS'),
                hiddenWithoutInclusions: true
            }
        ]
    },
    cargo: {
        title: t('RAW_REPORTS_TABLE.CARGO_REPORTS'),
        cellWidth: 28,
        rows: [

            // Basic info

            { ...commonRows.reportDate,
                fallback: {
                    key: 'CommencedCargoDate',
                    offsetKey: 'CommencedCargoDateOffset'
                }
            },
            commonRows.isSyncedWithVeracity,
            commonRows.lastModified,
            commonRows.callSign,
            commonRows.voyageNumber,
            commonRows.charterpartyDate,
            commonRows.position,

            // Since Last Event

            {
                ...commonRows.duration,
                key: 'TimeElapsedSinceLastEvent',
                label: t('REPORT_LABELS.TIME_SINCE_LAST_EVENT')
            },
            {
                ...commonRows.duration,
                key: 'TimeAtAnchorSinceLastEvent',
                label: t('REPORT_LABELS.TIME_AT_ANCHOR_SINCE_LAST_EVENT')
            },
            {
                ...commonRows.duration,
                key: 'HoursSteamedSinceLastEvent',
                label: t('REPORT_LABELS.HOURS_STEAMED_SINCE_LAST_EVENT')
            },
            {
                ...commonRows.distance,
                key: 'DistanceSinceLastEvent',
                label: t('REPORT_LABELS.DISTANCE_SINCE_LAST_EVENT')
            },
            {
                key: 'WindForceSinceLastEvent',
                type: 'custom',
                label: t('REPORT_LABELS.WIND_FORCE_SINCE_LAST_EVENT'),
                getFormatted: (report) => (report.WindForceSinceLastEvent
                    ? `${report.WindForceSinceLastEvent.Id} ${t('UNITS.WIND_FORCE')}
                       (${report.WindForceSinceLastEvent.Name})`
                    : '-')
            },
            {
                ...commonRows.cargo,
                key: 'TotalCargoSinceLastEvent',
                label: t('REPORT_LABELS.TOTAL_CARGO_ONBOARD')
            },
            {
                key: 'ConsumptionsSinceLastEvent',
                type: 'fuelTypes',
                selectable: false,
                label: t('REPORT_LABELS.CONSUMPTIONS_SINCE_LAST_EVENT'),
                dataGroup: 'ConsumptionsSinceLastEvent'
            },
            'ConsumptionSinceLastEventTypes',

            // Port information

            { key: 'PortActivityType.Name', type: 'string', label: t('REPORT_LABELS.PORT_ACTIVITY_TYPE') },
            { key: 'ShipToShipCargoTransfer', type: 'bool', label: t('REPORT_LABELS.SHIP_TO_SHIP_CARGO_TRANSFER') },
            { key: 'Point.Name', type: 'string', label: t('REPORT_LABELS.PORT_OF_DEPARTURE') },
            { key: 'Terminal', type: 'string', label: t('REPORT_LABELS.BERTH_TERMINAL_ANCHORAGE') },
            { key: 'IsUsingMainEngine', type: 'bool', label: t('REPORT_LABELS.USING_MAIN_ENGINE') },
            { key: 'IsHeated', type: 'bool', label: t('REPORT_LABELS.HEATED') },
            {
                key: 'CommencedCargoDate',
                type: 'date',
                offsetKey: 'CommencedCargoDateOffset',
                label: t('REPORT_LABELS.COMMENCED_DATE')
            },
            {
                key: 'CompletedCargoDate',
                type: 'date',
                offsetKey: 'CompletedCargoDateOffset',
                label: t('REPORT_LABELS.COMPLETED_DATE')
            },
            { ...commonRows.cargo, key: 'QtyLoadedDischarged', label: t('REPORT_LABELS.QTY_LOADED_DISCHARGED') },
            {
                ...commonRows.cargo,
                key: 'CalculatedAverageLoadDischargeRate',
                label: t('REPORT_LABELS.AVERAGE_LOAD_DISCHARGE_RATE')
            },
            {
                ...commonRows.capacity,
                key: 'LoadDischargeRateMaxFromTerminal',
                label: t('REPORT_LABELS.LOAD_DISCHARGE_RATE_MAX')
            },
            {
                ...commonRows.capacity,
                key: 'AverageLoadDischargeRate',
                label: t('REPORT_LABELS.LOAD_DISCHARGE_RATE_AVG')
            },
            { ...commonRows.pressure, key: 'PumpPressureMaxFromTerminal', label: t('REPORT_LABELS.PUMP_PRESSURE_MAX') },
            { ...commonRows.pressure, key: 'PumpPressureReached', label: t('REPORT_LABELS.PUMP_PRESSURE_REACHED') },

            // Remarks

            { key: 'Remarks', type: 'string', label: t('REPORT_LABELS.REMARKS') },

            // Bunker data

            {
                key: 'BunkerData',
                type: 'fuelTypes',
                selectable: false,
                label: t('REPORT_LABELS.BUNKER_DATA'),
                dataGroup: 'BunkerData'
            },
            'BunkerDataTypes',

            // DischargeOperations

            {
                key: 'DischargeOperations',
                type: 'fuelTypes',
                selectable: false,
                label: t('REPORT_LABELS.DISCHARGE_OPERATIONS'),
                dataGroup: 'DischargeOperations'
            },
            'DischargeOperationTypes',

            // Consumptions

            {
                key: 'Consumptions',
                altKey: 'LoadConsumptions',
                type: 'fuelTypes',
                selectable: false,
                label: t('REPORT_LABELS.CONSUMPTIONS'),
                dataGroup: 'Consumptions'
            },
            'ConsumptionTypes',

            // Tanks

            {
                key: 'Tanks',
                type: 'other',
                selectable: false,
                label: t('REPORT_LABELS.TANKS'),
                hiddenInExport: true,
                renderValue: (report) => {
                    if (report.Tanks.length > 0) {
                        return <TankLayout wings={report.Tanks} />;
                    }
                    return '-';
                }
            },

            // Exclusions

            {
                key: 'ExclusionReasons',
                type: 'array',
                label: t('REPORT_LABELS.EXCLUSION_REASONS'),
                hiddenWithoutInclusions: true
            }
        ]
    },
    sof: {
        title: t('RAW_REPORTS_TABLE.SOF_REPORTS'),
        cellWidth: 24,
        rows: [

            // Basic info

            commonRows.reportDate,
            commonRows.lastModified,
            commonRows.callSign,
            commonRows.voyageNumber,

            // Port information

            { key: 'Point.Name', type: 'string', label: t('REPORT_LABELS.PORT_OF_DEPARTURE') },
            { key: 'Charterer', type: 'string', label: t('REPORT_LABELS.CHARTERER') },
            {
                key: 'EndOfSeaPassage',
                type: 'date',
                offsetKey: 'EndOfSeaPassageOffset',
                label: t('REPORT_LABELS.END_OF_SEA_PASSAGE')
            },
            {
                key: 'AnchoredCommencedDrifting',
                type: 'date',
                offsetKey: 'AnchoredCommencedDriftingOffset',
                label: t('REPORT_LABELS.ANCHORED_COMMENCED_DRIFTING')
            },
            {
                key: 'AnchorAweighStoppedDrifting',
                type: 'date',
                offsetKey: 'AnchorAweighStoppedDriftingOffset',
                label: t('REPORT_LABELS.ANCHOR_AWEIGH_STOPPED_DRIFTING')
            },
            {
                key: 'NoticeOfReadiness',
                type: 'date',
                offsetKey: 'NoticeOfReadinessOffset',
                label: t('REPORT_LABELS.NOR_TENDERED')
            },
            {
                key: 'FreePractiqueGranted',
                type: 'date',
                offsetKey: 'FreePractiqueGrantedOffset',
                label: t('REPORT_LABELS.FREE_PRATIQUE_GRANTED')
            },
            {
                key: 'PilotOnboard',
                type: 'date',
                offsetKey: 'PilotOnboardOffset',
                label: t('REPORT_LABELS.PILOT_ONBOARD')
            },
            {
                key: 'AllFast',
                type: 'date',
                offsetKey: 'AllFastOffset',
                label: t('REPORT_LABELS.ALL_FAST')
            },
            {
                key: 'HosesConnected',
                type: 'date',
                offsetKey: 'HosesConnectedOffset',
                label: t('REPORT_LABELS.HOSES_CONNECTED')
            },

            // Remarks

            {
                key: 'Remarks',
                type: 'string',
                label: t('REPORT_LABELS.REMARKS')
            },

            // Cargo operations

            {
                key: 'CargoOperations',
                type: 'other',
                selectable: false,
                label: t('REPORT_LABELS.CARGO_OPERATIONS'),
                xlsCellType: 'CargoOperations',
                renderValue: (report) => {
                    if (report.CargoOperations.length > 0) {
                        return (
                            <table className="sten-table sten-table--xs sten-raw-reports-table-row__cargo-operations">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th className="col-12">{t('REPORT_LABELS.COMMENCED_DATE')}</th>
                                        <th className="col-12">{t('REPORT_LABELS.COMPLETED_DATE')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {report.CargoOperations.map((op, index) => (
                                        /* eslint-disable-next-line react/no-array-index-key */
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                                {TimeHelper.getFormattedWithOffset(
                                                    op.CommencedDate, op.CommencedDateOffset
                                                )}
                                            </td>
                                            <td>
                                                {TimeHelper.getFormattedWithOffset(
                                                    op.CompletedDate, op.CompletedDateOffset
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        );
                    }
                    return '-';
                }
            },

            // Other info

            {
                key: 'HosesDisconnected',
                type: 'date',
                offsetKey: 'HosesDisconnectedOffset',
                label: t('REPORT_LABELS.HOSES_DISCONNECTED')
            },
            {
                key: 'CargoDocumentsOnboard',
                type: 'date',
                offsetKey: 'CargoDocumentsOnboardOffset',
                label: t('REPORT_LABELS.CARGO_DOCUMENTS_ONBOARD')
            },
            {
                key: 'Unmoored',
                type: 'date',
                offsetKey: 'UnmooredOffset',
                label: t('REPORT_LABELS.UNMOORED')
            },

            // Exclusions

            {
                key: 'ExclusionReasons',
                type: 'array',
                label: t('REPORT_LABELS.EXCLUSION_REASONS'),
                hiddenWithoutInclusions: true
            }
        ]
    },
    event: {
        title: t('RAW_REPORTS_TABLE.EVENT_REPORTS'),
        cellWidth: 22,
        rows: [

            // Basic info

            commonRows.reportDate,
            commonRows.isSyncedWithVeracity,
            commonRows.lastModified,
            commonRows.callSign,
            commonRows.voyageNumber,
            { key: 'CargoStatus.Name', type: 'string', label: t('REPORT_LABELS.VESSEL_CONDITION') },
            { key: 'VesselStatus.Name', type: 'string', label: t('REPORT_LABELS.VESSEL_STATUS') },

            // Event

            { key: 'EventType.Name', type: 'string', label: t('REPORT_LABELS.EVENT_TYPE') },
            { key: 'SpecialArea.Name', type: 'string', label: t('REPORT_LABELS.SPECIAL_AREA') },
            commonRows.position,
            { ...commonRows.speed, key: 'InstructedSpeed', label: t('REPORT_LABELS.INSTRUCTED_SPEED') },
            {
                key: 'TotalDistanceSteamedInArea',
                type: 'string',
                label: t('REPORT_LABELS.TOTAL_DISTANCE_STEAMED_IN_AREA')
            },
            { ...commonRows.duration, key: 'TotalTimeSpentInArea', label: t('REPORT_LABELS.TOTAL_TIME_SPENT_IN_AREA') },

            // Remarks

            { key: 'Remarks', type: 'string', label: t('REPORT_LABELS.REMARKS') },

            // Bunker data

            {
                key: 'BunkerData',
                type: 'fuelTypes',
                selectable: false,
                label: t('REPORT_LABELS.BUNKER_DATA'),
                dataGroup: 'BunkerData'
            },
            'BunkerDataTypes',

            // Consumptions from noon to event

            {
                key: 'ConsumptionFromNoonToEvent',
                type: 'fuelTypes',
                selectable: false,
                label: t('REPORT_LABELS.CONSUMPTIONS_FROM_DAILY_TO_EVENT'),
                dataGroup: 'ConsumptionFromNoonToEvent'
            },
            'ConsumptionFromNoonToEventTypes',

            // Consumptions during stoppage

            {
                key: 'ConsumptionDuringStoppage',
                type: 'fuelTypes',
                selectable: false,
                label: t('REPORT_LABELS.CONSUMPTIONS_DURING_STOPPAGE'),
                dataGroup: 'ConsumptionDuringStoppage'
            },
            'ConsumptionDuringStoppageTypes',

            // Exclusions

            {
                key: 'ExclusionReasons',
                type: 'array',
                label: t('REPORT_LABELS.EXCLUSION_REASONS'),
                hiddenWithoutInclusions: true
            }
        ]
    }
};
