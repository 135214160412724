import { ActionTypes } from './gsm-map-view-switch-actions';

const initialState = {
    mapType: 'classic'
};

export default function gsmMapViewSwitchReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.GSM_MAP_VIEW_SWITCH_SET_MAP_TYPE: {
        return {
            ...state,
            mapType: action.mapType
        };
    }
    default:
        return state;
    }
}
