import React from 'react';
import PropTypes from 'prop-types';
/* components */
import TrendIcon from 'components/trend-icon/trend-icon';
/* styles */
import './trading-route-details-box.scss';

class TradingRouteDetailsBox extends React.PureComponent {
    render() {
        let classNames = 'sten-trading-route-details-box';
        if (this.props.className) {
            classNames += ` ${this.props.className}`;
        }
        if (this.props.isLight) {
            classNames += ' sten-trading-route-details-box--light';
        }
        if (this.props.size) {
            classNames += ` sten-trading-route-details-box--${this.props.size}`;
        }

        if (this.props.isHorizontal) {
            classNames += ' flex';
            return (
                <div className={classNames}>
                    <div className="flex-grow flex flex-center">
                        <div className="flex-grow">
                            <div className="sten-trading-route-details-box__value">
                                {this.props.value}
                            </div>
                            <div className="sten-trading-route-details-box__title">
                                {this.props.title}
                            </div>
                        </div>
                    </div>
                    {typeof this.props.trendIconValue === 'number' && (
                        <div
                            title={this.props.trendIconDescription}
                            className="flex-shrink flex flex-center sten-trading-route-details-box__trend"
                        >
                            <TrendIcon
                                value={this.props.trendIconValue}
                                breakpoints={this.props.trendIconBreakpoints}
                            />
                        </div>
                    )}
                </div>
            );
        }

        return (
            <div className={classNames}>
                <div className="sten-trading-route-details-box__row text-center">
                    <div className="sten-trading-route-details-box__title">
                        {this.props.title}
                    </div>
                </div>
                <div className="sten-trading-route-details-box__row text-center">
                    <div className="sten-trading-route-details-box__value">
                        {this.props.value}
                    </div>
                </div>
                {typeof this.props.trendIconValue === 'number' && (
                    <div className="sten-trading-route-details-box__row text-center">
                        <div
                            title={this.props.trendIconDescription}
                            className="
                                sten-trading-route-details-box__trend sten-trading-route-details-box__inline-block"
                        >
                            <TrendIcon
                                value={this.props.trendIconValue}
                                breakpoints={this.props.trendIconBreakpoints}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

TradingRouteDetailsBox.propTypes = {
    className: PropTypes.string,
    isHorizontal: PropTypes.bool,
    isLight: PropTypes.bool,
    size: PropTypes.string,
    title: PropTypes.string,
    trendIconBreakpoints: PropTypes.arrayOf(PropTypes.number),
    trendIconDescription: PropTypes.string,
    trendIconValue: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

TradingRouteDetailsBox.defaultProps = {
    className: '',
    isHorizontal: false,
    isLight: false,
    size: '',
    title: '',
    trendIconBreakpoints: undefined,
    trendIconDescription: '',
    trendIconValue: null
};

export default TradingRouteDetailsBox;
