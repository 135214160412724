/* utils */
import { mapArrayByProp } from 'utils/helpers/array-helper';
import { wktMaker } from 'utils/helpers/info-helper';
import { wktParse } from 'components/ol/ol-helpers';
/* constants */
import { boolOptionsMap } from 'components/form-fields/form-fields-helper';

export const convertPortForUpdate = (port) => {
    const {
        Aliases,
        Bunkering,
        LiquidFacilities,
        IspsCompliance,
        Position,
        Services,
        ...rest
    } = port;

    return {
        ...rest,
        Aliases: Aliases.filter(alias => !!alias),
        Position: wktMaker('point', Position),
        LiquidFacilities: LiquidFacilities ? LiquidFacilities.value : null,
        IspsCompliance: IspsCompliance ? IspsCompliance.value : null,
        Services: Object.values(Services).filter(service => service.Value || service.Comment),
        Bunkering: {
            ...Bunkering,
            Types: Bunkering.Types.map(type => type.Id)
        }
    };
};

export const convertPortForEdit = (port, services, bunkeringTypes) => {
    if (!port) {
        return null;
    }
    const {
        Bunkering,
        LiquidFacilities,
        IspsCompliance,
        Position,
        Services
    } = port;

    const portServices = mapArrayByProp(services, 'Id', (item) => (
        Services.find(s => s.Id === item.Id) || { ...item, Comment: '', Value: '' }
    ));

    return {
        ...port,
        Position: wktParse(Position),
        LiquidFacilities: boolOptionsMap[LiquidFacilities],
        IspsCompliance: boolOptionsMap[IspsCompliance],
        Services: portServices,
        Bunkering: {
            ...Bunkering,
            Types: Bunkering.Types.map(id => bunkeringTypes.find(item => item.Id === id))
        }
    };
};
