import { takeEvery, put, select } from 'redux-saga/effects';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
import { convertAndDownloadFile } from 'utils/helpers/file-helper';
/* actions */
import { ActionTypes } from './weather-performance-report-actions';
import { ActionTypes as CPActionTypes } from 'components/charterparty-form/charterparty-form-actions';
/* services */
import EnergyManagementService from 'services/core-api/energy-management-service';

function* getWeatherPerformanceReport() {
    const {
        selectedVessel,
        selectedRange,
        selectedVoyage
    } = yield select(state => state.energyManagementReportsReducer);
    const params = {
        Imo: selectedVessel.Imo,
        VoyageId: selectedVoyage.VoyageId,
        StartDate: selectedRange.rangeStart,
        EndDate: selectedRange.rangeEnd
    };
    const report = yield EnergyManagementService.Reports.WeatherPerformanceReport.getReport(params);
    if (report) {
        yield put({ type: ActionTypes.ENERGY_MANAGEMENT_WEATHER_PERFORMANCE_REPORT_SET, report });
    }
}

function* updateReport() {
    const { report } = yield select(state => state.energyManagementReportsWeatherPerformanceReportReducer);
    if (report) {
        yield getWeatherPerformanceReport();
    }
}

function* generatePDF() {
    const { selectedVessel, selectedVoyage } = yield select(state => state.energyManagementReportsReducer);
    const res = yield EnergyManagementService.Reports.WeatherPerformanceReport.generatePDF(selectedVoyage.VoyageId);
    if (res) {
        convertAndDownloadFile(res, {
            name: `WarrantyReport_IMO-${selectedVessel.Imo}`
            + `_VoyageNumber-${selectedVoyage.VoyageNumber}`
            + `_Date-${TimeHelper.getFormatted(null, { utc: true, time: true })}`,
            type: 'application/pdf'
        });
    }
}

export default function* energyManagementReportsWeatherPerformanceReportSaga() {
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_WEATHER_PERFORMANCE_REPORT_GENERATE_PDF, generatePDF);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_WEATHER_PERFORMANCE_REPORT_GENERATE, getWeatherPerformanceReport);
    yield takeEvery(CPActionTypes.CHARTERPARTY_FORM_VOYAGE_UPDATED, updateReport);
}
