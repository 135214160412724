import React from 'react';
import PropTypes from 'prop-types';
/* types */
import { MonthShape } from 'components/htec-date-picker/types';
/* components */
import DatePickerModeMonths from 'components/htec-date-picker/date-picker/date-picker-mode-months';
import DatePickerModeYears from 'components/htec-date-picker/date-picker/date-picker-mode-years';
/* styles */
import './month-picker.scss';

class MonthPicker extends React.PureComponent {
    constructor(props) {
        super(props);
        const currentDate = new Date();
        let currentYear;
        if (props.initialYear) {
            currentYear = props.initialYear;
        } else if (props.value) {
            currentYear = props.value.year;
        } else {
            currentYear = currentDate.getFullYear();
        }
        this.state = {
            mode: 'months',
            currentYear,
            today: {
                day: currentDate.getDate(),
                month: currentDate.getMonth() + 1,
                year: currentDate.getFullYear()
            }
        };
    }

    handleMonthSelect = (args, e) => {
        e.stopPropagation();
        this.props.onChange(args);
    };

    handleYearSelect = (args, e) => {
        e.stopPropagation();
        this.setState({
            currentYear: args.year,
            mode: 'months'
        });
    };

    switchToYearMode = () => {
        this.setState({ mode: 'years' });
    };

    render() {
        return (
            <div className="sten-month-picker">
                {this.state.mode === 'years' && (
                    <DatePickerModeYears
                        minDate={this.props.minDate}
                        maxDate={this.props.maxDate}
                        today={this.state.today}
                        onCellClick={this.handleYearSelect}
                        currentYear={this.state.currentYear}
                        selectedDate={this.props.value}
                    />
                )}
                {this.state.mode === 'months' && (
                    <DatePickerModeMonths
                        minDate={this.props.minDate}
                        maxDate={this.props.maxDate}
                        today={this.state.today}
                        onCellClick={this.handleMonthSelect}
                        currentYear={this.state.currentYear}
                        selectedDate={this.props.value}
                        onPanelTitleClick={this.switchToYearMode}
                    />
                )}
            </div>
        );
    }
}

MonthPicker.propTypes = {
    initialYear: PropTypes.number,
    maxDate: PropTypes.shape(MonthShape),
    minDate: PropTypes.shape(MonthShape),
    onChange: PropTypes.func.isRequired,
    value: PropTypes.shape(MonthShape)
};

MonthPicker.defaultProps = {
    initialYear: null,
    maxDate: null,
    minDate: null,
    value: null
};

export default MonthPicker;
