import { addMiddlePoints } from '../ol-helpers';

const config = {
    linesConfig: {
        frequencyX: 1,
        frequencyY: 1,
        lineColor: 'rgba(183, 183, 183, 0.02)',
        lineWidth: 1
    },
    groupLinesConfig: {
        frequencyX: 10,
        frequencyY: 10,
        lineColor: 'rgba(183, 183, 183, 0.06)',
        lineWidth: 1
    },
    crossLinesConfig: {
        lineColor: 'rgba(183, 183, 183, 0.10)',
        crossLength: 0.25
    }
};

const generateCrosses = () => {
    const crossLines = [];
    for (let y = -90; y < 90; y += config.groupLinesConfig.frequencyY) {
        const crossLength = config.crossLinesConfig.crossLength;
        let beginDotX = -180 - (crossLength / 2);
        let endDotX = beginDotX + crossLength;
        while (endDotX < 180) {
            crossLines.push([[beginDotX, y], [endDotX, y]]);
            beginDotX = (endDotX + config.groupLinesConfig.frequencyX) - crossLength;
            endDotX = beginDotX + crossLength;
        }
    }
    for (let x = -180; x < 180; x += config.groupLinesConfig.frequencyX) {
        const crossLength = config.crossLinesConfig.crossLength;
        let beginDotY = -80 - (crossLength / 2);
        let endDotY = beginDotY + crossLength;
        while (endDotY < 90) {
            crossLines.push([[x, beginDotY], [x, endDotY]]);
            beginDotY = (endDotY + config.groupLinesConfig.frequencyY) - crossLength;
            endDotY = beginDotY + crossLength;
        }
    }
    return crossLines;
};

const generate = () => {
    const smallLines = [];
    const groupLines = [];
    let line;
    for (let y = -90; y < 90; y += config.linesConfig.frequencyY) {
        line = addMiddlePoints([[-180, y], [180, y]], 10);
        if (y % config.groupLinesConfig.frequencyY === 0) {
            groupLines.push(line);
        } else {
            smallLines.push(line);
        }
    }
    for (let x = -180; x < 180; x += config.linesConfig.frequencyX) {
        line = addMiddlePoints([[-x, -90], [-x, 90]], 10);
        if (x % config.groupLinesConfig.frequencyX === 0) {
            groupLines.push(line);
        } else {
            smallLines.push(line);
        }
    }
    return {
        smallLines,
        linesConfig: config.linesConfig,
        groupLines,
        groupLinesConfig: config.groupLinesConfig,
        crossLines: generateCrosses(),
        crossLinesConfig: config.crossLinesConfig
    };
};

export default generate();
