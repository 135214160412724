import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';
/* types */
import { TFilters, TOptions } from '../emission-estimator-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import keyCodes from 'utils/key-codes';
import TimeHelper from 'utils/helpers/time-helper';
import { formatCoordinates, getClassName, formatNumber } from 'utils/helpers/info-helper';
import { wktParse } from 'components/ol/ol-helpers';
import { copyTextToClipboard } from 'utils/helpers/clipboard-helper';
/* components */
import EstimatorFilters from './estimator-filters/estimator-filters';
import EstimatorPoint from './estimator-point/estimator-point';
import EstimatorSummary from './estimator-summary/estimator-summary';
import IconButton from 'components/icon-button/icon-button';
import Modal from 'components/modal/modal';
import ReorderableRowsTable from 'components/reorderable-rows-table/reorderable-rows-table';
import ScrollArea from 'components/scroll-area/scroll-area';
import Textarea from 'components/textarea/textarea';
import Validation from 'components/validation/validation';
/* actions */
import {
    addPoint,
    getCalculation,
    getCalculationVessel,
    movePoint,
    removePoint,
    reset,
    sendRequest,
    setActivePinPointId,
    setCalculationPoints,
    setCalculationVessel,
    getBunkerOptions,
    setCustomCalculationPoint,
    setRoutePoints,
    setVesselStartingPosition,
    updatePoint,
    updateStartingPoint,
    setFilters
} from './estimator-actions';
import { toggleFullScreen } from '../emission-estimator-page-actions';
/* selectors */
import { getSplitCalculationPoints, getCalculationsWithTimes } from './estimator-selectors';
/* style */
import './estimator.scss';

const isIE = () => {
    const ua = window.navigator.userAgent.toLowerCase();
    return ua.indexOf('msie ') >= 0 || ua.indexOf('trident/') >= 0;
};

export class Estimator extends React.PureComponent {
    state = {
        comment: '',
        isConfirmModalOpen: false,
        isSummaryCollapsed: true,
        focusedPointIndex: null
    };

    fixedFooter = !isIE();

    focusedPointIndex = null;

    lastMovedFromIndex = null;

    componentDidMount() {
        this.props.getBunkerOptions();
        this.init();
        document.addEventListener('keydown', this.handleKeyDown);
    }

    componentDidUpdate(prevProps) {
        if (this.props.calculations !== prevProps.calculations) {
            if ((this.props.calculations && this.state.isSummaryCollapsed)
                || (!this.props.calculations && !this.state.isSummaryCollapsed)) {
                this.toggleSummaryCollapsed();
            }
        }
        this.init(prevProps);
    }

    componentWillUnmount() {
        if (this.scrollFixTimeout) {
            clearTimeout(this.scrollFixTimeout);
        }
        if (this.movedFromIndexResetTimeout) {
            clearTimeout(this.movedFromIndexResetTimeout);
        }
        if (this.scrollTimeout) {
            clearTimeout(this.movedFromIndexResetTimeout);
        }
        document.removeEventListener('keydown', this.handleKeyDown);
        this.props.reset();
    }

    renderCalculationPoint = ({ dragHandleMouseDown, row, ref, handleMouseEnter }) => (
        <EstimatorPoint
            addPoint={this.handleAddPoint}
            calculationPoint={row.calculationPoint}
            defaultSpeed={Number(this.props.filters.defaultSpeed)}
            dragHandleMouseDown={dragHandleMouseDown}
            earliestEta={row.earliestEta}
            earliestChangeableIndex={this.props.earliestChangeableIndex}
            focusOnMount={this.lastMovedFromIndex !== row.index}
            form={this.form}
            handleMouseEnter={handleMouseEnter}
            id={row.calculationPoint.CalculationPointId}
            index={row.index}
            isAddEnabled={this.props.futureCalculationPoints.length < this.props.maxFuturePoints}
            isCalculated={!!this.props.calculations}
            isFocused={this.state.focusedPointIndex === row.index}
            isLastPoint={this.props.calculationPoints.length === row.index + 1}
            isPinActive={this.props.activePinPointId === row.calculationPoint.CalculationPointId}
            key={row.calculationPoint.CalculationPointId}
            movePoint={this.props.movePoint}
            onChange={this.props.updatePoint}
            onDelete={row.index === this.props.earliestChangeableIndex
                && this.props.calculationPoints.length - this.props.earliestChangeableIndex === 1
                ? undefined
                : this.props.removePoint}
            onPinLocationClick={this.props.setActivePinPointId}
            previousPoint={row.previousPoint}
            saveRef={ref}
            scrollToPoint={this.scrollToPointIfNeeded.bind(this, row.index + 1)}
            setCustomCalculationPoint={this.props.setCustomCalculationPoint}
            setFocusedPointIndex={this.setFocusedPointIndex}
            showConsumption={this.props.permissions.ShowConsumption}
        />
    );

    setComment = value => {
        this.setState({ comment: value });
    };

    setFocusedPointIndex = (index) => {
        if (index !== this.focusedPointIndex) {
            this.focusedPointIndex = index;
            this.setState({ focusedPointIndex: index });
        }
    };

    handleAddPoint = (index) => {
        this.scrollToPointIfNeededWithDelay(index + 1);
        this.props.addPoint(index);
    };

    handleMovePoint = (fromIndex, toIndex) => {
        const fixedFromIndex = this.props.earliestChangeableIndex + fromIndex;
        const fixedToIndex = this.props.earliestChangeableIndex + toIndex;
        this.lastMovedFromIndex = fixedFromIndex;
        this.props.movePoint(fixedFromIndex, fixedToIndex);
        this.movedFromIndexResetTimeout = setTimeout(() => {
            this.lastMovedFromIndex = null;
        }, 500);
    };

    isPointChangeDisabled = () => !!this.props.calculationPoints[this.focusedPointIndex]
        && !!this.props.calculationPoints[this.focusedPointIndex].Ata;

    handleKeyDown = e => {
        if (!e.ctrlKey && !e.altKey && !e.shiftKey) {
            switch (e.keyCode) {
            case keyCodes.F5:
                e.preventDefault();
                if (this.props.calculations !== null
                    && !this.props.calculationPointsChanged
                    && !this.props.isDeviation) {
                    this.copyToClipboard();
                }
                break;
            case keyCodes.F6:
                e.preventDefault();
                if (this.isPointChangeDisabled()
                    || (this.focusedPointIndex === this.props.earliestChangeableIndex
                        && this.props.calculationPoints.length - this.props.earliestChangeableIndex === 1)) {
                    return;
                }
                if (typeof this.focusedPointIndex === 'number'
                    && this.props.calculationPoints[this.focusedPointIndex]) {
                    let newFocusedPointIndex = this.focusedPointIndex + 1;
                    if (!this.props.calculationPoints[this.focusedPointIndex + 1]) {
                        if (this.props.calculationPoints[this.focusedPointIndex - 1]) {
                            newFocusedPointIndex = this.focusedPointIndex;
                        } else {
                            newFocusedPointIndex = 0;
                        }
                    }
                    this.props.removePoint(this.focusedPointIndex);
                    if (this.pointsContainerRef
                        && this.pointsContainerRef.children
                        && this.pointsContainerRef.children[newFocusedPointIndex]) {
                        const inputFields = this.pointsContainerRef.children[newFocusedPointIndex]
                            .getElementsByTagName('input');
                        if (inputFields && inputFields[0]) {
                            inputFields[0].focus();
                        }
                    }
                }
                break;
            case keyCodes.F7:
                e.preventDefault();
                if (this.isPointChangeDisabled()) {
                    return;
                }
                if (typeof this.focusedPointIndex === 'number'
                    && this.focusedPointIndex > this.props.earliestChangeableIndex) {
                    this.handleAddPoint(this.focusedPointIndex);
                } else {
                    this.handleAddPoint(this.props.earliestChangeableIndex);
                }
                break;
            case keyCodes.F8:
                e.preventDefault();
                if (this.isPointChangeDisabled()) {
                    return;
                }
                if (typeof this.focusedPointIndex === 'number') {
                    this.handleAddPoint(this.focusedPointIndex + 1);
                } else {
                    this.handleAddPoint(this.props.calculationPoints.length);
                }
                break;
            case keyCodes.F9:
                e.preventDefault();
                this.form.onSubmit();
                break;
            case keyCodes.F10:
                e.preventDefault();
                this.resetCalculation();
                break;
            case keyCodes.pageUp:
                e.preventDefault();
                if (this.isPointChangeDisabled()) {
                    return;
                }
                if (typeof this.focusedPointIndex === 'number'
                    && this.focusedPointIndex > this.props.earliestChangeableIndex) {
                    this.props.movePoint(this.focusedPointIndex, this.focusedPointIndex - 1);
                    this.scrollToPointIfNeededWithDelay(this.focusedPointIndex);
                    this.focusedPointIndex--;
                }
                break;
            case keyCodes.pageDown:
                e.preventDefault();
                if (this.isPointChangeDisabled()) {
                    return;
                }
                if (typeof this.focusedPointIndex === 'number'
                    && this.focusedPointIndex + 1 < this.props.calculationPoints.length) {
                    this.props.movePoint(this.focusedPointIndex, this.focusedPointIndex + 1);
                    this.focusedPointIndex++;
                    this.scrollToPointIfNeededWithDelay(this.focusedPointIndex + 1);
                }
                break;
            case keyCodes.escape:
                if (this.props.activePinPointId !== null) {
                    this.props.setActivePinPointId(null);
                }
                break;
            default:
            }
        }
    };

    scrollToPointIfNeededWithDelay = (index) => {
        this.scrollTimeout = setTimeout(this.scrollToPointIfNeeded.bind(this, index), 100);
    };

    scrollToPointIfNeeded = (index) => {
        if (this.tableRef && this.tableRef.fixedHeaderTableRef && this.tableRef.fixedHeaderTableRef.scrollArea) {
            this.tableRef.fixedHeaderTableRef.scrollArea.scrollArea.refresh();
            const pointDomElement = this.pointsContainerRef.children[index];
            const headerRowOffset = (this.tableRef.fixedHeaderTableRef.headerRow
                && this.tableRef.fixedHeaderTableRef.headerRow.offsetHeight) || 0;
            const footerRowOffset = (this.tableRef.fixedHeaderTableRef.footerRow
                && this.tableRef.fixedHeaderTableRef.footerRow.offsetHeight) || 0;

            if (pointDomElement) {
                let delta;
                const visibleContentHeight = this.tableRef.fixedHeaderTableRef.scrollArea.sizes.containerHeight;
                const bottomBoundPosition = pointDomElement.offsetHeight + pointDomElement.offsetTop;
                const topPosition = this.tableRef.fixedHeaderTableRef.scrollArea.state.topPosition;

                if (bottomBoundPosition + footerRowOffset - visibleContentHeight > topPosition) {
                    // check if lower bound is not visible
                    delta = bottomBoundPosition + footerRowOffset - visibleContentHeight;
                    this.tableRef.fixedHeaderTableRef.scrollArea.scrollYTo(delta);
                } else if (pointDomElement.offsetTop - headerRowOffset < topPosition) {
                    // check if top bound is not visible
                    this.tableRef.fixedHeaderTableRef.scrollArea.scrollYTo(
                        pointDomElement.offsetTop - headerRowOffset
                    );
                }
            }
            this.refreshScrollArea();
        }
    };

    resetCalculation = () => {
        this.props.reset();
        this.form.resetValidation();
    };

    init = prevProps => {
        if (typeof this.props.queryParams.vessel === 'number') {
            if (!prevProps || this.props.queryParams.vessel !== prevProps.queryParams.vessel) {
                this.props.getCalculationVessel(this.props.queryParams.vessel, this.props.queryParams.deviation);
            } else if (this.props.queryParams.deviation !== prevProps.queryParams.deviation
                && this.props.queryParams.deviation) {
                this.props.setVesselStartingPosition(this.props.options.startingPositionOptions[3]);
            }
        }
        if (prevProps && this.props.futureCalculationPoints !== prevProps.futureCalculationPoints && this.form) {
            this.props.futureCalculationPoints.forEach((point) => {
                if (point.calculationPoint.ValidationErrors) {
                    if (point.calculationPoint.ValidationErrors.EtaError) {
                        this.form.showError(
                            `arrivalDate${point.calculationPoint.CalculationPointId}`,
                            () => point.calculationPoint.ValidationErrors.EtaError
                        );
                    }
                }
            });
            this.refreshScrollArea();
        }
    };

    toggleConfirmModal = flag => {
        this.setState({
            isConfirmModalOpen: typeof flag === 'boolean' ? flag : !this.state.isConfirmModalOpen
        });
    };

    sendRequest = () => {
        this.props.sendRequest(this.state.comment);
        this.toggleConfirmModal(false);
    };

    static formatPointName(point) {
        if (point.Name) {
            return point.Name;
        }

        const location = wktParse(point.Location);
        return formatCoordinates(location[0], location[1]);
    }

    static formatAndRoundDate(dateTime) {
        const roundingInterval = 30;
        const dateTimeMoment = moment.utc(dateTime);
        const rounded = Math.round(dateTimeMoment.minute() / roundingInterval) * roundingInterval;
        dateTimeMoment.minute(rounded).second(0);
        return TimeHelper.getFormatted(dateTimeMoment, { time: true });
    }

    handleChange = (name, val) => {
        this.props.setFilters(name, val);
    }

    copyToClipboard = () => {
        let line;
        const SEPARATOR = '\r\n';
        const lines = [];
        const summaryData = this.props.isDeviation
            ? this.props.calculations.DeviationSummary
            : this.props.calculations.CalculationValues;
        this.props.calculationPoints.forEach((point) => {
            if (point.Point) {
                line = point.Point.Name;
                if (point.Eta) {
                    line += `,${SEPARATOR}${t('EMISSION_ESTIMATOR.CLIPBOARD.ETA')}`
                    + `: ${Estimator.formatAndRoundDate(point.Eta)}`;
                }
                if (point.Etd) {
                    line += `,${SEPARATOR}${t('EMISSION_ESTIMATOR.CLIPBOARD.ETD')}`
                    + `: ${Estimator.formatAndRoundDate(point.Etd)}`;
                }
                line += `,${SEPARATOR}${t('EMISSION_ESTIMATOR.CLIPBOARD.SPEED')}`
                    + `: ${point.Speed ? `${point.Speed}${t('UNITS.SPEED')}` : '-'}`;
                lines.push(line);
            }
        });
        const { startingPoint } = this.props;
        if (startingPoint && startingPoint.Point) {
            line = Estimator.formatPointName(startingPoint.Point);
            if (startingPoint.Eta) {
                line += `,${SEPARATOR}${t('EMISSION_ESTIMATOR.CLIPBOARD.START_TIME')}`
                    + `: ${Estimator.formatAndRoundDate(startingPoint.Eta)}`;
            }
            lines.unshift(line);
        }
        if (this.props.filters.calculationVessel && this.props.filters.calculationVessel.Vessel.Title) {
            lines.unshift(`${t('EMISSION_ESTIMATOR.CLIPBOARD.VESSEL_NAME')}`
                + `: ${this.props.filters.calculationVessel.Vessel.Title}`);
        }
        if (summaryData.Time) {
            const time = summaryData.Time && (summaryData.Time < -1000 || summaryData.Time > 1000)
                ? TimeHelper.getStringFromDuration(summaryData.Time, ['d', 'h'], this.props.isDeviation)
                : '-';
            const ciiGrade = summaryData.CarbonIntensityIndicatorGrade
                ? formatNumber(summaryData.CarbonIntensityIndicatorGrade, 2, this.props.isDeviation)
                : '-';
            const aer = summaryData.Aer ? formatNumber(summaryData.Aer, 2, this.props.isDeviation) : '-';
            const voyageData = `${t('EMISSION_ESTIMATOR.CLIPBOARD.VOYAGE_TIME')}: ${time}`
                + `${SEPARATOR}${t('EMISSION_ESTIMATOR.CLIPBOARD.CII_GRADE')}: ${ciiGrade}`
                + `${SEPARATOR}${t('EMISSION_ESTIMATOR.CLIPBOARD.AER')}: ${aer}${t('UNITS.ENERGY_EFFICIENCY')}`;
            if (summaryData.Emissions && summaryData.Emissions.CO2) {
                const emission = formatNumber(summaryData.Emissions.CO2, 2, this.props.isDeviation);
                lines.push(`${t('EMISSION_ESTIMATOR.CLIPBOARD.CO2_EMISSIONS')}: ${emission}${t('UNITS.CONSUMPTION')}`
                + `,${SEPARATOR}${voyageData}`);
            } else {
                lines.push(voyageData);
            }
        }
        copyTextToClipboard(lines.join('\r\n\r\n'));
        toast(t('GLOBAL.COPY_TO_CLIPBOARD_SUCCESS'), { type: toast.TYPE.SUCCESS });
    };

    refreshScrollArea = () => {
        if (this.tableRef && this.tableRef.fixedHeaderTableRef && this.tableRef.fixedHeaderTableRef.scrollArea) {
            if (this.scrollFixTimeout) {
                clearTimeout(this.scrollFixTimeout);
            }
            this.scrollFixTimeout = setTimeout(this.tableRef.fixedHeaderTableRef.scrollArea.refresh, 200);
        }
    };

    toggleSummaryCollapsed = () => {
        this.setState((prevState) => ({ isSummaryCollapsed: !prevState.isSummaryCollapsed }), this.refreshScrollArea);
    };

    saveTableRef = (c) => {
        this.tableRef = c;
    };

    savePointsContainerRef = (c) => { this.pointsContainerRef = c; };

    saveFormRef = (c) => { this.form = c; };

    renderTableWrapper = (draggableRows) => {
        const tableClassName = getClassName('sten-table sten-table--xs sten-estimator__table', {
            'sten-table--no-border-bottom': this.fixedFooter
        });
        let lastPointName = '';
        let lastPointETA = '';
        if (this.props.calculations
            && !this.props.calculationPointsChanged
            && this.props.calculationPoints
            && this.props.calculationPoints.length > 0) {
            const lastCalculationPoint = this.props.calculationPoints[this.props.calculationPoints.length - 1].Point
                ? this.props.calculationPoints[this.props.calculationPoints.length - 1]
                : this.props.calculationPoints[this.props.calculationPoints.length - 2];
            lastPointName = lastCalculationPoint.Point?.Name || '';
            lastPointETA = lastCalculationPoint.Eta || '';
        }
        return (
            <table
                className={tableClassName}
            >
                <thead>
                    <tr>
                        <th className="sten-estimator__th--0" />
                        <th className="sten-estimator__th--1">{t('EMISSION_ESTIMATOR.TABLE.DESTINATIONS')}</th>
                        <th className="sten-estimator__th--2">{t('EMISSION_ESTIMATOR.TABLE.STATUS')}</th>
                        <th className="sten-estimator__th--3">{t('EMISSION_ESTIMATOR.TABLE.STEAMING')}</th>
                        <th className="sten-estimator__th--4">{t('EMISSION_ESTIMATOR.TABLE.DISTANCE')}</th>
                        <th className="sten-estimator__th--5">{t('EMISSION_ESTIMATOR.TABLE.SPEED')}</th>
                        {this.props.permissions.ShowConsumption && (
                            <th className="sten-estimator__th--6"> {t('EMISSION_ESTIMATOR.TABLE.CONSUMPTION')}</th>
                        )}
                        <th className="sten-estimator__th--7">{t('EMISSION_ESTIMATOR.TABLE.WAITING')}</th>
                        <th className="sten-estimator__th--8">{t('EMISSION_ESTIMATOR.TABLE.TIME_IN_PORT')}</th>
                        <th className="sten-estimator__th--9">{t('EMISSION_ESTIMATOR.TABLE.ARRIVAL_DATE')}</th>
                        <th className="sten-estimator__th--10">{t('EMISSION_ESTIMATOR.TABLE.DEPARTURE_DATE')}</th>
                    </tr>
                </thead>
                <tbody ref={this.savePointsContainerRef}>
                    {this.props.isDeviation
                        ? (
                            this.props.historicalCalculationPoints.map(
                                point => this.renderCalculationPoint({ row: point })
                            )
                        ) : (
                            <EstimatorPoint
                                addPoint={this.handleAddPoint}
                                calculationPoint={this.props.startingPoint}
                                defaultSpeed={Number(this.props.filters.defaultSpeed)}
                                earliestChangeableIndex={this.props.earliestChangeableIndex}
                                form={this.form}
                                id="S"
                                index="S"
                                isCalculated={!!this.props.calculations}
                                isFocused={this.state.focusedPointIndex === 'S'}
                                isLastPoint={!draggableRows || draggableRows.length === 0}
                                isPinActive={this.props.activePinPointId === 'S'}
                                isStarting
                                key="startingPoint"
                                onChange={this.props.updateStartingPoint}
                                onPinLocationClick={this.props.setActivePinPointId}
                                setCustomCalculationPoint={this.props.setCustomCalculationPoint}
                                setFocusedPointIndex={this.setFocusedPointIndex}
                                showConsumption={this.props.permissions.ShowConsumption}
                                startingPointFixed={this.props.filters.calculationVesselStartingPosition
                                    && this.props.filters.calculationVesselStartingPosition.value !== 0}
                            />
                        )
                    }
                    {draggableRows}
                    {this.fixedFooter && (
                        <tr className="sten-estimator__space-fill-row">
                            <td colSpan="3" />
                            <td />
                            <td />
                            <td />
                            {this.props.permissions.ShowConsumption && <td />}
                            <td />
                            <td />
                            <td />
                            <td />
                        </tr>
                    )}
                </tbody>
                <EstimatorSummary
                    isCollapsed={this.state.isSummaryCollapsed}
                    toggleCollapsed={this.props.calculations ? this.toggleSummaryCollapsed : undefined}
                    isDifference={this.props.isDeviation}
                    showConsumption={this.props.permissions.ShowConsumption}
                    lastPointName={lastPointName}
                    lastPointETA={lastPointETA}
                    data={this.props.calculations}
                />
            </table>
        );
    };

    render() {
        const isCopyToClipboardDisabled = this.props.calculations === null || this.props.calculationPointsChanged;
        let title = t('EMISSION_ESTIMATOR.TITLE');
        if (this.props.isDeviation) {
            if (this.props.permissions.ShowDeviationAsRequest) {
                title = t('EMISSION_ESTIMATOR.DEVIATION_REQUEST');
            } else {
                title = t('EMISSION_ESTIMATOR.DEVIATION_CALCULATOR');
            }
        }
        let confirmText = t('EMISSION_ESTIMATOR.CONFIRM_SEND_CALCULATION');
        if (this.props.isDeviation && this.props.permissions.ShowDeviationAsRequest) {
            confirmText = t('EMISSION_ESTIMATOR.CONFIRM_SEND_DEVIATION_REQUEST');
        }

        return (
            <div className="sten-estimator sten-content">
                {this.props.permissions.SendCalculation && (
                    <Modal.Confirm
                        title={confirmText}
                        isOpen={this.state.isConfirmModalOpen}
                        onConfirm={this.sendRequest}
                        onCancel={this.toggleConfirmModal.bind(this, false)}
                    >
                        <label className="label">{t('EMISSION_ESTIMATOR.COMMENT')}</label>
                        <Textarea
                            name="comment"
                            value={this.state.comment}
                            onChange={this.setComment}
                        />
                    </Modal.Confirm>
                )}
                {!this.props.isRequestSent
                    ? (
                        <Validation.Form
                            className="sten-estimator__form"
                            ref={this.saveFormRef}
                            onSubmit={this.props.getCalculation}
                            clearErrorsOnSubmit
                        >
                            <div className="sten-content__header flex-row">
                                <h1 className="flex-grow sten-content__title">{title}</h1>
                                <div className="flex-shrink sten-estimator--hide-when-minimized">
                                    <IconButton
                                        isLink
                                        isDisabled={isCopyToClipboardDisabled}
                                        icon="icon-copy"
                                        onClick={this.copyToClipboard}
                                        className="flex-shrink cursor-pointer"
                                        tooltipTitle={`${t('GLOBAL.COPY_TO_CLIPBOARD')} (F5)`}
                                    />
                                </div>
                                <div className="flex-shrink">
                                    <IconButton
                                        isLink
                                        icon="icon-full-screen"
                                        isActive={this.props.isFullScreen}
                                        onClick={this.props.toggleFullScreen}
                                        tooltipTitle={this.props.isFullScreen
                                            ? t('GLOBAL.RESTORE_DOWN')
                                            : t('GLOBAL.MAXIMIZE')}
                                    />
                                </div>
                                <div
                                    className="sten-content__vertical-separator sten-estimator--hide-when-minimized"
                                />
                                <div className="flex-shrink sten-estimator--hide-when-minimized">
                                    <button
                                        type="button"
                                        className="btn btn--secondary text-uppercase"
                                        onClick={this.resetCalculation}
                                    >
                                        {`${t('EMISSION_ESTIMATOR.CLEAR')} (F10)`}
                                    </button>
                                </div>
                                <div className="flex-shrink sten-estimator--hide-when-minimized">
                                    <Validation.Button
                                        className={`btn text-uppercase ${this.props.permissions.SendCalculations
                                            ? 'btn--secondary' : 'btn--primary'}`}
                                        disabled={!this.props.calculationPointsChanged}
                                    >
                                        {`${t('EMISSION_ESTIMATOR.CALCULATE')} (F9)`}
                                    </Validation.Button>
                                </div>
                                {this.props.permissions.SendCalculation && (
                                    <div className="flex-shrink sten-estimator--hide-when-minimized">
                                        <button
                                            type="button"
                                            className="btn btn--primary text-uppercase col-24"
                                            onClick={this.toggleConfirmModal}
                                            disabled={!this.props.isCalculated || this.props.calculationPointsChanged}
                                        >
                                            <span className="btn__icon icon icon-send" />
                                            {t('EMISSION_ESTIMATOR.SEND_REQUEST')}
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className="sten-content__body">
                                <EstimatorFilters
                                    setCalculationVessel={this.props.setCalculationVessel}
                                    setVesselStartingPosition={this.props.setVesselStartingPosition}
                                    options={this.props.options}
                                    handleChange={this.handleChange}
                                    filters={this.props.filters}
                                    euEtsYearsAvailable={this.props.euEtsYearsAvailable}
                                    euEtsVesselTypes={this.props.euEtsVesselTypes}
                                />
                                <ReorderableRowsTable
                                    ref={this.saveTableRef}
                                    rows={this.props.futureCalculationPoints}
                                    rowsTableWrapper={this.renderTableWrapper}
                                    renderRow={this.renderCalculationPoint}
                                    onDragComplete={this.handleMovePoint}
                                    fixedHeaderTableProps={{
                                        className: 'sten-estimator__fixed-header-table',
                                        contentClassName: 'sten-estimator__fixed-header-table-content',
                                        withFooterRow: this.fixedFooter,
                                        useCSS3Translate: false,
                                        updateTimeout: 100
                                    }}
                                />
                            </div>
                        </Validation.Form>
                    ) : (
                        <div className="sten-estimator__success">
                            <div className="sten-content__header flex-row">
                                <div className="flex-shrink text-left">
                                    <button
                                        className="btn-link icon icon-close"
                                        onClick={this.resetCalculation}
                                    />
                                </div>
                                <h1 className="sten-content__title flex-grow">{title}</h1>
                            </div>
                            <ScrollArea className="sten-content__body">
                                <div className="sten-content__section">
                                    <h1 className="text-uppercase text-success text-center">
                                        {t('EMISSION_ESTIMATOR.SEND_SUCCESS_TITLE')}
                                    </h1>
                                </div>
                                <div className="sten-cntent__section">
                                    <p className="text-center">
                                        {t('EMISSION_ESTIMATOR.SEND_SUCCESS_TEXT')}
                                    </p>
                                </div>
                            </ScrollArea>
                            <footer className="sten-content__footer flex-row">
                                <div className="flex-grow text-right">
                                    <button
                                        onClick={this.resetCalculation}
                                        className="btn btn--primary text-uppercase col-24"
                                    >
                                        {t('GLOBAL.CLOSE')}
                                    </button>
                                </div>
                            </footer>
                        </div>
                    )
                }
            </div>
        );
    }
}

Estimator.propTypes = {
    activePinPointId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    addPoint: PropTypes.func.isRequired,
    calculationPoints: PropTypes.arrayOf(PropTypes.object).isRequired,
    calculationPointsChanged: PropTypes.bool.isRequired,
    calculationVoyageItinerary: PropTypes.arrayOf(PropTypes.object).isRequired,
    calculations: PropTypes.objectOf(PropTypes.any),
    earliestChangeableIndex: PropTypes.number.isRequired,
    euEtsVesselTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    euEtsYearsAvailable: PropTypes.arrayOf(PropTypes.object).isRequired,
    futureCalculationPoints: PropTypes.arrayOf(PropTypes.object).isRequired,
    getCalculation: PropTypes.func.isRequired,
    getCalculationVessel: PropTypes.func.isRequired,
    historicalCalculationPoints: PropTypes.arrayOf(PropTypes.object).isRequired,
    isCalculated: PropTypes.bool.isRequired,
    isDeviation: PropTypes.bool.isRequired,
    isFullScreen: PropTypes.bool.isRequired,
    isRequestSent: PropTypes.bool.isRequired,
    maxFuturePoints: PropTypes.number.isRequired,
    movePoint: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    removePoint: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    sendRequest: PropTypes.func.isRequired,
    setActivePinPointId: PropTypes.func.isRequired,
    setCalculationPoints: PropTypes.func.isRequired,
    setCalculationVessel: PropTypes.func.isRequired,
    setCustomCalculationPoint: PropTypes.func.isRequired,
    setRoutePoints: PropTypes.func.isRequired,
    setVesselStartingPosition: PropTypes.func.isRequired,
    getBunkerOptions: PropTypes.func.isRequired,
    startingPoint: PropTypes.objectOf(PropTypes.any).isRequired,
    toggleFullScreen: PropTypes.func.isRequired,
    updatePoint: PropTypes.func.isRequired,
    updateStartingPoint: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    queryParams: PropTypes.objectOf(PropTypes.any).isRequired,
    options: TOptions.isRequired,
    filters: TFilters.isRequired
};

Estimator.defaultProps = {
    activePinPointId: null,
    calculations: null
};

function mapStateToProps(state) {
    return {
        filters: state.estimatorReducer.filters,
        activePinPointId: state.estimatorReducer.activePinPointId,
        calculationPoints: state.estimatorReducer.calculationPoints,
        calculationPointsChanged: state.estimatorReducer.calculationPointsChanged,
        calculationVoyageItinerary: state.estimatorReducer.calculationVoyageItinerary,
        calculations: getCalculationsWithTimes(state),
        earliestChangeableIndex: getSplitCalculationPoints(state).earliestChangeableIndex,
        euEtsVesselTypes: state.euEtsReducer.vesselTypes,
        euEtsYearsAvailable: state.euEtsReducer.yearsAvailable,
        futureCalculationPoints: getSplitCalculationPoints(state).futurePoints,
        historicalCalculationPoints: getSplitCalculationPoints(state).historicalPoints,
        isCalculated: state.estimatorReducer.isCalculated,
        isDeviation: !!state.estimatorReducer.filters.calculationVesselStartingPosition
            && state.estimatorReducer.filters.calculationVesselStartingPosition.value === 3,
        isFullScreen: state.emissionEstimatorPageReducer.isFullScreen,
        isRequestSent: state.estimatorReducer.isRequestSent,
        maxFuturePoints: state.estimatorReducer.maxFuturePoints,
        permissions: state.userReducer.permissions,
        startingPoint: state.estimatorReducer.startingPoint,
        options: state.estimatorReducer.options
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addPoint: index => addPoint(dispatch, index),
        getCalculation: vesselPosition => getCalculation(dispatch, vesselPosition),
        getCalculationVessel: (vesselImo, isDeviation) => getCalculationVessel(dispatch, vesselImo, isDeviation),
        movePoint: (fromIndex, toIndex) => movePoint(dispatch, fromIndex, toIndex),
        removePoint: index => removePoint(dispatch, index),
        reset: () => reset(dispatch),
        sendRequest: comment => sendRequest(dispatch, comment),
        setActivePinPointId: activePinPointId => setActivePinPointId(dispatch, activePinPointId),
        setCalculationPoints: points => setCalculationPoints(dispatch, points),
        setCalculationVessel: vessel => setCalculationVessel(dispatch, vessel),
        setCustomCalculationPoint: (coordinates, index) => setCustomCalculationPoint(dispatch, coordinates, index),
        setRoutePoints: routePoints => setRoutePoints(dispatch, routePoints),
        setVesselStartingPosition: startingPosition => setVesselStartingPosition(dispatch, startingPosition),
        getBunkerOptions: () => getBunkerOptions(dispatch),
        setFilters: (name, value) => setFilters(dispatch, name, value),
        toggleFullScreen: () => toggleFullScreen(dispatch),
        updatePoint: (point, index) => updatePoint(dispatch, point, index),
        updateStartingPoint: point => updateStartingPoint(dispatch, point)
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Estimator);
