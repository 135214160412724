import { ActionTypes } from './fleet-dashboard-actions';

const emptyArray = [];

const initialState = {
    vessels: emptyArray,
    notifications: emptyArray,
    vesselPositions: emptyArray,
    voyageOptions: emptyArray,
    earnings: null,
    latestPrediction: null,
    models: emptyArray,
    ports: emptyArray,
    fleets: emptyArray
};

export default function fleetDashboardReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.FLEET_DASHBOARD_SET_VOYAGE_OPTIONS: {
        return { ...state, voyageOptions: action.voyageOptions || emptyArray };
    }
    case ActionTypes.FLEET_DASHBOARD_SET_VESSELS: {
        return { ...state, vessels: action.vessels || emptyArray };
    }
    case ActionTypes.FLEET_DASHBOARD_SET_NOTIFICATIONS: {
        return { ...state, notifications: action.notifications ? action.notifications.Notifications : emptyArray };
    }
    case ActionTypes.FLEET_DASHBOARD_SET_VESSEL_POSITIONS: {
        return { ...state, vesselPositions: action.vesselPositions || emptyArray };
    }
    case ActionTypes.FLEET_DASHBOARD_SET_EARNINGS: {
        return { ...state, earnings: action.earnings };
    }
    case ActionTypes.FLEET_DASHBOARD_SET_LATEST_PREDICTION: {
        return { ...state, latestPrediction: action.latestPrediction };
    }
    case ActionTypes.FLEET_DASHBOARD_SET_MODELS: {
        return { ...state, models: action.models };
    }
    case ActionTypes.FLEET_DASHBOARD_SET_PORTS: {
        return { ...state, ports: action.ports };
    }
    case ActionTypes.FLEET_DASHBOARD_SET_FLEETS: {
        return { ...state, fleets: action.fleets };
    }
    case ActionTypes.FLEET_DASHBOARD_RESET_ALL: {
        return initialState;
    }
    default:
        return state;
    }
}
