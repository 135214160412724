import { call, put, select, takeEvery } from 'redux-saga/effects';
import { toast } from 'react-toastify';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* actions */
import { ActionTypes } from './counter-pvar-actions';
import { ActionTypes as CPActionTypes } from 'components/charterparty-form/charterparty-form-actions';
/* services */
import CounterPvarService from 'services/core-api/counter-pvar-service';
import LocalStorageService from 'services/local-storage-service';
import VesselService from 'services/core-api/vessel-service';
/* constants */
import {
    getMockedCounterPvarList,
    getMockedCounterPvarValidationData,
    getMockedVessel,
    getMockedVoyage,
    getMockedVoyages,
    postMockRequest
} from './counter-pvar-test-utils';

function* getCounterPvarList(action) {
    const res = LocalStorageService.isDebugModeActive()
        ? yield getMockedCounterPvarList()
        : yield CounterPvarService.getCounterPvarList(action.voyageId);
    if (res) {
        yield put({ type: ActionTypes.COUNTER_PVAR_SET_COUNTER_PVAR_LIST, counterPvarList: res });
    }
}

function* getCounterPvarValidationData(action) {
    const res = LocalStorageService.isDebugModeActive()
        ? yield getMockedCounterPvarValidationData()
        : yield CounterPvarService.getCounterPvarValidationData(action.voyageId);
    if (res) {
        yield put({ type: ActionTypes.COUNTER_PVAR_SET_VALIDATION_DATA, validationData: res });
    }
}

function* getSelectedVessel(action) {
    const res = LocalStorageService.isDebugModeActive()
        ? yield getMockedVessel(action.vesselImo)
        : yield VesselService.getBaseById(action.vesselImo);
    if (res) {
        yield put({ type: ActionTypes.COUNTER_PVAR_SET_SELECTED_VESSEL, selectedVessel: res });
    }
}

function* getSelectedVoyage(action) {
    const getVoyages = LocalStorageService.isDebugModeActive()
        ? getMockedVoyages
        : VesselService.getVoyagesPreviewById.bind(VesselService);
    const selectedVesselVoyages = action.vesselImo
        ? yield getVoyages(action.vesselImo)
        : yield select(state => state.counterPvarReducer.selectedVesselVoyages);
    const selectedVoyage = LocalStorageService.isDebugModeActive()
        ? yield getMockedVoyage(action.voyageId)
        : selectedVesselVoyages.find(voyage => voyage.VoyageId === action.voyageId);
    if (selectedVoyage) {
        yield put({
            type: ActionTypes.COUNTER_PVAR_SET_SELECTED_VOYAGE,
            selectedVoyage,
            selectedVesselVoyages
        });
    } else {
        toast(t('COUNTER_PVAR.TOASTS.VOYAGE_NOT_FOUND_ERROR'), { type: toast.TYPE.ERROR });
    }
}

function* postCounterPvarRequest(action) {
    const { counterPvarRequest } = action;
    const data = {
        Comment: counterPvarRequest.Comment,
        Emails: counterPvarRequest.Emails,
        InstructedSpeed: counterPvarRequest.InstructedSpeed,
        VesselCondition: counterPvarRequest.VesselCondition.Id,
        VoyageId: counterPvarRequest.Voyage.VoyageId
    };
    const res = LocalStorageService.isDebugModeActive()
        ? yield postMockRequest(data)
        : yield CounterPvarService.postCounterPvarRequest(data);
    if (res) {
        yield call(getCounterPvarList, { voyageId: counterPvarRequest.Voyage.VoyageId });
        toast(t('COUNTER_PVAR.TOASTS.REQUEST_SENT'), { type: toast.TYPE.SUCCESS });
    }
}

function* updateData(action) {
    yield call(getCounterPvarValidationData, action);
}

export default function* counterPvarSaga() {
    yield takeEvery(CPActionTypes.CHARTERPARTY_FORM_VOYAGE_UPDATED, updateData);
    yield takeEvery(ActionTypes.COUNTER_PVAR_GET_COUNTER_PVAR_LIST, getCounterPvarList);
    yield takeEvery(ActionTypes.COUNTER_PVAR_GET_COUNTER_PVAR_VALIDATION_DATA, getCounterPvarValidationData);
    yield takeEvery(ActionTypes.COUNTER_PVAR_GET_SELECTED_VESSEL, getSelectedVessel);
    yield takeEvery(ActionTypes.COUNTER_PVAR_GET_SELECTED_VOYAGE, getSelectedVoyage);
    yield takeEvery(ActionTypes.COUNTER_PVAR_POST_COUNTER_PVAR_REQUEST, postCounterPvarRequest);
}
