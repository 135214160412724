import CoreApi from './core-api';

class DynamicFleetService {
    constructor() {
        this.resourceUrl = 'DynamicFleet';
    }

    getVessels(params) {
        return CoreApi.get(this.resourceUrl, params).then(response => response.data, () => null);
    }

    getMainAttributes() {
        return CoreApi.get(`${this.resourceUrl}/MainAttributes`)
            .then(response => response.data, () => null);
    }

    getSecondaryAttributes(mainAttributeId) {
        return CoreApi.get(`${this.resourceUrl}/SecondaryAttributes/${mainAttributeId}`)
            .then(response => response.data, () => null);
    }

    getValueAttributes(secondaryAttributeId) {
        return CoreApi.get(`${this.resourceUrl}/ValueAttributes/${secondaryAttributeId}`)
            .then(response => response.data, () => null);
    }
}

export default new DynamicFleetService();
