/* actions */
import { ActionTypes } from './fleet-management-actions';

const emptyArray = [];

const initialState = {
    isMounted: false,
    privateFleets: emptyArray,
    publicFleets: emptyArray,
    options: {
        companyFleets: emptyArray,
        segments: emptyArray,
        technicalManagers: emptyArray,
        vesselOwners: emptyArray,
        mainAttributes: emptyArray
    }
};

export default function fleetManagementReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.FLEET_MANAGEMENT_SET_INITIAL_DATA: {
        return {
            ...state,
            privateFleets: action.fleets ? action.fleets.private : emptyArray,
            publicFleets: action.fleets ? action.fleets.public : emptyArray,
            options: {
                companyFleets: action.companyFleets || emptyArray,
                segments: action.segments || emptyArray,
                technicalManagers: action.technicalManagers || emptyArray,
                vesselOwners: action.vesselOwners || emptyArray,
                mainAttributes: action.mainAttributes || emptyArray
            },
            isMounted: true
        };
    }
    case ActionTypes.FLEET_MANAGEMENT_SET_FLEETS: {
        return {
            ...state,
            privateFleets: action.fleets ? action.fleets.private : emptyArray,
            publicFleets: action.fleets ? action.fleets.public : emptyArray
        };
    }
    case ActionTypes.FLEET_MANAGEMENT_RESET_ALL: {
        return initialState;
    }
    default:
        return state;
    }
}
