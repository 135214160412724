import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { formatNumber } from 'utils/helpers/info-helper';
/* components */
import TextHighlight from 'components/text-highlight/text-highlight';
import Select from 'components/select/select';
/* Services */
import VesselService from 'services/core-api/vessel-service';
/* styles */
import './vessel-select.scss';

const emptyArray = [];

class VesselSelect extends React.PureComponent {
    state = {
        vessels: emptyArray
    };

    mapVessel = (vessel) => {
        const subtitleArray = [];
        if (vessel.VesselTypeName) {
            subtitleArray.push(vessel.VesselTypeName);
        }
        if (vessel.VesselTypeName === 'LNG' && vessel.TotalCubicCapacity) {
            subtitleArray.push(
                `${translate('VESSEL_SELECT.CAPACITY')} `
                + `${formatNumber(vessel.TotalCubicCapacity, 0)} `
                + `${translate('UNITS.CUBIC_CAPACITY')}`
            );
        } else if (vessel.DWT) {
            subtitleArray.push(
                `${translate('VESSEL_SELECT.DWT')} `
                + `${formatNumber(vessel.DWT, 0)} ${translate('UNITS.DWT')}`
            );
        }
        if (vessel.ImoClass) {
            subtitleArray.push(`${translate('VESSEL_SELECT.IMO_CLASS')} ${vessel.ImoClass}`);
        }
        return {
            ...vessel,
            Id: vessel.Imo.toString(),
            IsPrimary: vessel.IsPrimary,
            Title: vessel.Title,
            Subtitles: subtitleArray.join(', ')
        };
    };

    handleSearch = searchCriteria => {
        if (this.props.onSearch) {
            this.props.onSearch(
                searchCriteria,
                this.mapVessel,
                (vessels) => this.setState({ vessels: vessels || emptyArray })
            );
        } else {
            this.onSearch(searchCriteria);
        }
    };

    onSearch = (searchCriteria) => {
        const { filterLNGVessels } = this.props;
        VesselService.searchByCriteria({ searchCriteria, limit: 10, filterLNGVessels }).then((res) => {
            let vessels = emptyArray;
            if (res && res.length) {
                vessels = res.map(vessel => this.mapVessel({
                    ...vessel,
                    Title: vessel.Vessel.Title,
                    Imo: vessel.Vessel.Imo,
                    IsPrimary: vessel.Vessel.IsVesselInUserScope,
                    VesselTypeName: vessel.Vessel.VesselType
                }));
            }

            this.setState({ vessels });
        });
    }

    getVesselOption = (vessel, searchCriteria) => {
        let titleClass = 'sten-vessel-select__item-title';
        if (vessel.IsPrimary) {
            titleClass += ' sten-vessel-select__item-title--primary';
        }
        return (
            <div className="sten-vessel-select__item">
                <h3 className={titleClass}>
                    {searchCriteria
                        ? <TextHighlight input={vessel.Title} highlight={searchCriteria} />
                        : vessel.Title
                    }
                </h3>
                <p className="sten-vessel-select__item-subtitle text-secondary">
                    {vessel.Subtitles}
                </p>
            </div>
        );
    };

    render() {
        return (
            <Select
                {...this.props}
                className={`sten-vessel-select${this.props.className ? ` ${this.props.className}` : ''}`}
                searchable
                onSearch={this.handleSearch}
                optionRenderer={this.getVesselOption}
                placeholder={this.props.placeholder}
                valueKey="Imo"
                labelKey="Title"
                options={this.state.vessels}
                title={this.props.title}
            />
        );
    }
}

VesselSelect.propTypes = {
    ...Select.PropTypes,
    className: PropTypes.string,
    filterLNGVessels: PropTypes.bool,
    inScope: PropTypes.bool,
    onSearch: PropTypes.func,
    placeholder: PropTypes.string,
    preSelectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.string
};

VesselSelect.defaultProps = {
    className: '',
    inScope: false,
    title: '',
    placeholder: translate('VESSEL_SELECT.PLACEHOLDER'),
    preSelectedValue: null,
    onSearch: null,
    filterLNGVessels: false
};

export default VesselSelect;
