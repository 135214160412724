import Excel from 'exceljs';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { formatXLSXCellValue, generateXLSXHeaderLogos } from '../../../reports-helpers';
import {
    columnWidth,
    tableHeaderStyle,
    tableCellStyle,
    headingTitleStyle
} from 'utils/helpers/xlsx-styles';
import { addWorksheet, triggerXLSXDownload } from 'utils/helpers/xlsx-helper';
import {
    ladenSummaryKeys,
    ballastSummaryKeys,
    summaryTranslationKeys,
    summaryKeyToFormatMap,
    keyToFormatMap,
    ballastKeys,
    ladenKeys
} from '../constants';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.${key}`);

const tableHeadStyle = {
    ...tableHeaderStyle,
    alignment: {
        wrapText: true,
        vertical: 'middle',
        horizontal: 'center'
    }
};

const formatTableCell = (value, format = {}, props = null, colSpan = 1, rowSpan = 1) => ({
    value: formatXLSXCellValue(value, format),
    colSpan,
    rowSpan,
    props: { ...tableCellStyle, ...props, numFmt: format.numFmt || null }
});

function formatSummaryTableCell(summary, key) {
    const exclusionFields = summary.ExlusionFields;
    return exclusionFields && exclusionFields[key]
        ? formatTableCell(exclusionFields[key].join('; '), keyToFormatMap.ExclusionReason)
        : formatTableCell(summary[key], summaryKeyToFormatMap[key]);
}

const generateSummaryTableRows = (summary) => {
    if (!summary) {
        return [];
    }
    const length = Math.max(ballastSummaryKeys.length, ladenSummaryKeys.length);
    const rows = new Array(length + 1);
    rows[0] = {
        values: [
            { value: translate('GLOBAL.BALLAST'), colSpan: 2, props: headingTitleStyle },
            null,
            null,
            null,
            { value: translate('GLOBAL.LADEN'), colSpan: 2, props: headingTitleStyle }
        ]
    };
    let bk;
    let lk;
    for (let i = 1; i <= length; i++) {
        bk = ballastSummaryKeys[i - 1];
        lk = ladenSummaryKeys[i - 1];

        rows[i] = {
            values: [
                bk ? {
                    value: t(`PREVIEW.MULTI_VOYAGE_REPORT.SUMMARY.${summaryTranslationKeys[bk]}`),
                    colSpan: 2,
                    props: tableHeaderStyle
                } : null,
                null,
                bk ? formatSummaryTableCell(summary.Ballast, bk) : null,
                null,
                lk ? {
                    value: t(`PREVIEW.MULTI_VOYAGE_REPORT.SUMMARY.${summaryTranslationKeys[lk]}`),
                    colSpan: 2,
                    props: tableHeaderStyle
                } : null,
                null,
                lk ? formatSummaryTableCell(summary.Laden, lk) : null
            ]
        };
    }
    return rows;
};

const tH = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.MULTI_VOYAGE_REPORT.TABLE_HEADER.${key}`);
const reportTableHeader = [
    {
        values: [
            { value: tH('VOYAGE_NUMBER'), rowSpan: 2, props: tableHeadStyle },
            { value: tH('BALLAST'), colSpan: 7, props: tableHeadStyle },
            null,
            null,
            null,
            null,
            null,
            null,
            { value: tH('LOAD'), props: tableHeadStyle },
            { value: tH('LADEN'), colSpan: 9, props: tableHeadStyle },
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            null,
            { value: tH('DISCHARGE'), props: tableHeadStyle }
        ]
    },
    {
        values: [
            null,
            { value: tH('INSTRUCTED_SPEED'), props: tableHeadStyle },
            { value: tH('TOTAL_TIME'), props: tableHeadStyle },
            { value: tH('TOTAL_DISTANCE'), props: tableHeadStyle },
            { value: tH('TOTAL_ME_CONS'), props: tableHeadStyle },
            { value: tH('AVG_ME_CONS_PER_DAY'), props: tableHeadStyle },
            { value: tH('AVG_SPEED'), props: tableHeadStyle },
            { value: tH('TOTAL_AUX_CONS'), props: tableHeadStyle },
            { value: tH('TOTAL_LOAD_CONS'), props: tableHeadStyle },
            { value: tH('CARGO_ONBOARD'), props: tableHeadStyle },
            { value: tH('CARGO_HEATED'), props: tableHeadStyle },
            { value: tH('INSTRUCTED_SPEED'), props: tableHeadStyle },
            { value: tH('TOTAL_TIME'), props: tableHeadStyle },
            { value: tH('TOTAL_DISTANCE'), props: tableHeadStyle },
            { value: tH('TOTAL_ME_CONS'), props: tableHeadStyle },
            { value: tH('AVG_ME_CONS_PER_DAY'), props: tableHeadStyle },
            { value: tH('AVG_SPEED'), props: tableHeadStyle },
            { value: tH('TOTAL_AUX_CONS'), props: tableHeadStyle },
            { value: tH('TOTAL_DISCH_CONS'), props: tableHeadStyle }
        ]
    }
];

const alignedCellProps = {
    alignment: {
        vertical: 'middle',
        horizontal: 'center',
        wrapText: true
    }
};

const generateReportTableRows = (reports) => {
    if (!reports || reports.length === 0) {
        return [];
    }
    const rows = new Array(reports.length);
    let ballast;
    let laden;
    reports.forEach((report, index) => {
        ballast = report.MultivoyageReportDataBallast;
        laden = report.MultivoyageReportDataLaden;
        rows[index] = {
            values: [
                { value: report.VoyageNumber, props: tableHeadStyle },
                ...ballast.ExclusionReason
                    ? [
                        formatTableCell(
                            ballast.ExclusionReason,
                            keyToFormatMap.ExclusionReason,
                            alignedCellProps,
                            7
                        ),
                        null, null, null, null, null, null
                    ]
                    : ballastKeys.map((key) => formatTableCell(ballast[key], keyToFormatMap[key], alignedCellProps)),
                report.MultivoyageReportDataLoad.ExclusionReason
                    ? formatTableCell(
                        report.MultivoyageReportDataLoad.ExclusionReason,
                        keyToFormatMap.ExclusionReason,
                        alignedCellProps
                    )
                    : formatTableCell(
                        report.MultivoyageReportDataLoad.TotalCons,
                        keyToFormatMap.TotalCons,
                        alignedCellProps
                    ),
                ...laden.ExclusionReason
                    ? [
                        formatTableCell(
                            laden.ExclusionReason,
                            keyToFormatMap.ExclusionReason,
                            alignedCellProps,
                            9
                        ),
                        null, null, null, null, null, null, null, null
                    ]
                    : ladenKeys.map((key) => formatTableCell(laden[key], keyToFormatMap[key], alignedCellProps)),
                report.MultivoyageReportDataDischarge.ExclusionReason
                    ? formatTableCell(
                        report.MultivoyageReportDataDischarge.ExclusionReason,
                        keyToFormatMap.ExclusionReason,
                        alignedCellProps
                    )
                    : formatTableCell(
                        report.MultivoyageReportDataDischarge.TotalCons,
                        keyToFormatMap.TotalCons,
                        alignedCellProps
                    )
            ]
        };
    });
    return rows;
};

export default function* generateMultiVoyageReportsXLSX({
    vesselName,
    companyLogo,
    fileName,
    rangeStart,
    rangeEnd,
    voyageReports,
    summaryReport
}) {
    const workbook = new Excel.Workbook();

    const sheetConfig = {
        name: 'Multi-voyage report',
        props: {
            properties: { showGridLines: false },
            views: [{ showGridLines: false }],
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                margins: { left: 0.4, right: 0.4, top: 0.2, bottom: 0.2, header: 0, footer: 0 }
            }
        },
        columnWidth,
        rows: [
            ...generateXLSXHeaderLogos(companyLogo),
            {
                values: [{ value: t('MULTI_VOYAGE_REPORT'), props: headingTitleStyle }]
            },
            null,
            {
                values: [{ value: vesselName, props: headingTitleStyle }]
            },
            {
                values: [{ value: `${t('PREVIEW.HEADER.DATE_RANGE')} ${rangeStart} - ${rangeEnd}` }]
            },
            null,
            ...generateSummaryTableRows(summaryReport),
            null,
            { pageBreak: true },
            ...reportTableHeader,
            ...generateReportTableRows(voyageReports)
        ]
    };

    addWorksheet(workbook, sheetConfig);

    const xls64 = yield workbook.xlsx.writeBuffer({ base64: true });
    return triggerXLSXDownload(fileName)(xls64);
}
