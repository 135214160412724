export const ActionTypes = {
    NAUTICAL_CHARTS_GET_AVAILABLE_LAYERS: 'NAUTICAL_CHARTS_GET_AVAILABLE_LAYERS',
    NAUTICAL_CHARTS_SET_AVAILABLE_LAYERS: 'NAUTICAL_CHARTS_SET_AVAILABLE_LAYERS'
};

export function getAvailableLayers(dispatch) {
    dispatch({
        type: ActionTypes.NAUTICAL_CHARTS_GET_AVAILABLE_LAYERS
    });
}
