import React from 'react';
import { formatNumber, getObjectProp, formatCoordinates, roundNumber } from './info-helper';
import TimeHelper from './time-helper';
import { translate } from 'utils/i18n/i18n-model';

const defaultDateOptions = { time: true, utc: true };

class ReportHelper {
    formatValue(report, formatConfig) {
        let format = formatConfig;
        if (format.type === 'custom') {
            return format.getFormatted(report);
        }
        let value = getObjectProp(report, format.key);
        const dateOptions = formatConfig.dateOptions || defaultDateOptions;
        if (value === null && formatConfig.fallback) {
            format = { ...formatConfig, ...formatConfig.fallback };
            value = getObjectProp(report, format.key);
        }
        if (value === null || value === '' || value === undefined) {
            return '-';
        }
        if (format.type === 'edit') {
            const lastModifiedBy = getObjectProp(report, format.key);
            const lastModifiedDate = getObjectProp(report, format.dateKey);
            if (lastModifiedBy && lastModifiedDate) {
                return (
                    <React.Fragment>
                        <div className="text-ellipsis" title={lastModifiedBy}>{lastModifiedBy}</div>
                        <div>{TimeHelper.getFormatted(lastModifiedDate, dateOptions)}</div>
                    </React.Fragment>
                );
            }
            return '-';
        }
        if (format.type === 'position') {
            let position = '';
            let Latitude;
            let Longitude;
            if (report.Position) {
                Latitude = report.Position.Latitude;
                Longitude = report.Position.Longitude;
            }
            if (typeof Latitude === 'number' && typeof Longitude === 'number') {
                position = formatCoordinates(Longitude, Latitude);
            }
            return position;
        }
        if (format.type === 'date') {
            const date = TimeHelper.getFormatted(value, dateOptions);
            const offsetValue = format.offsetKey ? getObjectProp(report, format.offsetKey) : null;
            const dateOffset = typeof offsetValue === 'number' ? ` ${TimeHelper.getUTCOffsetString(offsetValue)}` : '';
            return `${date}${dateOffset}`;
        }
        if (format.type === 'bool') {
            const activeLabel = format.activeLabel || translate('GLOBAL.YES');
            const inactiveLabel = format.inactiveLabel || translate('GLOBAL.NO');
            return value ? activeLabel : inactiveLabel;
        }
        if (format.type === 'number') {
            const number = formatNumber(value, format.decimals);
            return `${number}${format.unit ? ` ${format.unit}` : ''}`;
        }
        if (format.type === 'duration') {
            const multiplier = format.multiplier || 1;
            const displayFormat = format.displayFormat || ['d', 'h', 'm'];
            if (value === 0) {
                return `0${displayFormat[0]}`;
            }
            return TimeHelper.getStringFromDuration(value * multiplier, displayFormat) || '-';
        }
        if (format.type === 'array') {
            if (value.length === 0) {
                return '-';
            }
            const array = value.map((v) => formatNumber(v, format.decimals)).join(', ');
            return `${array}${format.unit ? ` ${format.unit}` : ''}`;
        }
        return value;
    }

    formatCellValue(value, isDateCell, unit = '', numDecimals = 1) {
        if (typeof value === 'number') {
            const result = formatNumber(value, numDecimals);
            return unit === '' ? result : `${result} ${unit}`;
        } if (Array.isArray(value)) {
            if (value.length === 0) {
                return '-';
            }
            const result = value.map((v) => formatNumber(v, numDecimals)).join(', ');
            return unit === '' ? result : `${result} ${unit}`;
        } if (value && isDateCell) {
            return TimeHelper.getFormatted(value, defaultDateOptions);
        } if (value === null) {
            return '-';
        } if (typeof value === 'boolean') {
            return value ? translate('GLOBAL.YES') : translate('GLOBAL.NO');
        } if (value === '') {
            return '\u00A0';
        }

        return value;
    }

    getAverageLoadDischargeRate = (report) => {
        let commencedDate;
        let completedDate;
        let qtyLoadedDischarged;
        if (report.ReportTypeId === 1
            && report.ReportDate
            && report.DailyReportAtPort
            && report.DailyReportAtPort.CommencedCargoDate
        ) {
            commencedDate = new Date(report.DailyReportAtPort.CommencedCargoDate).valueOf();
            completedDate = new Date(report.ReportDate).valueOf();
            qtyLoadedDischarged = Number(report.DailyReportAtPort.TotalCargoLoadedDischarged);
        }
        if (report.ReportTypeId === 4 && report.CommencedCargoDate && report.CompletedCargoDate) {
            commencedDate = new Date(report.CommencedCargoDate).valueOf();
            completedDate = new Date(report.CompletedCargoDate).valueOf();
            qtyLoadedDischarged = Number(report.QtyLoadedDischarged);
        }
        if (commencedDate && completedDate && !isNaN(qtyLoadedDischarged)) {
            const dateDiff = (completedDate - commencedDate) / (1000 * 3600 * 24);
            if (dateDiff > 0) {
                return roundNumber(qtyLoadedDischarged / dateDiff, 2);
            }
        }
        return null;
    };

    getSyncWithVeracityData = (report) => {
        const IsSyncedWithVeracityBunkerLog = report.ReportTypeId === 2
            ? report.IsSyncedWithVeracityBunkerLog
            : true;
        const isSynced = report.IsSyncedWithVeracity && IsSyncedWithVeracityBunkerLog;
        const hasErrors = !!(report.VeracityAbstractErrors || report.VeracityBunkerErrors);
        const hasWarnings = !!(report.VeracityAbstractWarnings || report.VeracityBunkerWarnings);
        const sentToVeracity = isSynced || hasErrors || hasWarnings;
        let value = translate('REPORT_LABELS.VERACITY_NOT_SENT');
        if (sentToVeracity) {
            value = hasErrors
                ? translate('REPORT_LABELS.VERACITY_SYNC_ERROR')
                : translate('REPORT_LABELS.VERACITY_SYNCED_SUCCESSFULLY');
        }
        return { value, hasErrors, hasWarnings, sentToVeracity };
    }
}

export default new ReportHelper();
