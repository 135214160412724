import { ActionTypes } from './position-list-table-actions';

const initialState = {
    collapsedGroups: [],
    sortProp: 'OpenPort.Name',
    sortOrder: 'DESC'
};

export default function positionListTableReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.POSITION_LIST_TABLE_TOGGLE_GROUP_COLLAPSE: {
        const index = state.collapsedGroups.indexOf(action.group);
        const collapsedGroups = state.collapsedGroups.slice();
        if (index === -1) {
            collapsedGroups.push(action.group);
        } else {
            collapsedGroups.splice(index, 1);
        }
        return {
            ...state,
            collapsedGroups
        };
    }
    case ActionTypes.POSITION_LIST_TABLE_SET_SORT_PROP: {
        if (state.sortProp === action.sortProp) {
            let sortOrder;
            if (state.sortOrder === 'ASC') {
                sortOrder = 'DESC';
            } else {
                sortOrder = 'ASC';
            }
            return {
                ...state,
                sortOrder
            };
        }
        return {
            ...state,
            sortProp: action.sortProp,
            sortOrder: 'ASC'
        };
    }
    default:
        return state;
    }
}
