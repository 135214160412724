import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ScrollArea from 'components/scroll-area/scroll-area';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
import { getPanelRows } from 'utils/helpers/template-helper';
/* actions */
import { getActiveCargoes, getHistoricalCargoes } from '../employed-vessels-panel-actions';
/* components */
import Accordion from 'components/accordion/accordion';
import Select from 'components/select/select';
/* styles */
import './cargo-list.scss';

export class CargoesList extends React.PureComponent {
    constructor(params) {
        super(params);

        this.state = {
            activeOptionId: 0
        };
    }

    componentDidMount() {
        if (this.state.activeOptionId === 0) {
            this.props.getActiveCargoes();
        } else {
            this.props.getHistoricalCargoes(true);
        }
    }

    onTitleClick(cargoItem, event) {
        event.stopPropagation();
        if (cargoItem.Vessel.Imo) {
            const newQuery = {};
            newQuery.vessel = cargoItem.Vessel.Imo;
            if (cargoItem.Voyage.VoyageId) {
                newQuery.voyage = cargoItem.Voyage.VoyageId;
            }
            this.props.router.navigate({ query: newQuery });
        }
    }

    getCargoDetails(cargo, voyageDuration, loading, discharging) {
        const rows = [];
        const columnClasses = ['col-8', 'col-16'];
        rows.push([
            <label className="sten-panel__label">{translate('CARGO_LIST.CHARTERPARTY_DATE')}</label>,
            <label className="sten-panel__label sten-panel__label--value">
                {cargo.CharterpartyDate ? TimeHelper.getFormatted(cargo.CharterpartyDate, { utc: true }) : '-'}
            </label>
        ]);
        rows.push([
            <label className="sten-panel__label">{translate('CARGO_LIST.COMMODITY')}</label>,
            <label className="sten-panel__label sten-panel__label--value">{cargo.Commodity || '-'}</label>
        ]);
        rows.push([
            <label className="sten-panel__label">{translate('CARGO_LIST.LOAD_PORT')}</label>,
            <label className="sten-panel__label sten-panel__label--value">{loading}</label>
        ]);
        rows.push([
            <label className="sten-panel__label">{translate('CARGO_LIST.DISCHARGE_PORT')}</label>,
            <label className="sten-panel__label sten-panel__label--value">{discharging}</label>
        ]);
        rows.push([
            <label className="sten-panel__label">{translate('CARGO_LIST.VOYAGE_DURATION')}</label>,
            <label className="sten-panel__label sten-panel__label--value">{voyageDuration}</label>
        ]);
        rows.push([
            <label className="sten-panel__label">{translate('CARGO_LIST.QUANTITY')}</label>,
            <label className="sten-panel__label sten-panel__label--value">
                {cargo.Quantity
                    ? `${cargo.Quantity.toLocaleString()} ${translate('UNITS.CARGO')}`
                    : translate('GLOBAL.UNKNOWN')}
            </label>
        ]);
        return <div className="sten-panel__section" key={cargo.CargoId}>{getPanelRows(columnClasses, rows)}</div>;
    }

    getOptions() {
        return [{
            id: 0,
            title: translate('CARGO_LIST.ACTIVE')
        }, {
            id: 1,
            title: translate('CARGO_LIST.HISTORICAL')
        }];
    }

    getVesselCargoes(cargoes) {
        const cargoItems = [];
        cargoes.forEach((cargoItem) => {
            cargoItem.Cargoes.forEach((cargo) => {
                cargoItems.push({
                    ...cargo,
                    Vessel: cargoItem.Vessel,
                    Voyage: cargoItem.Voyage
                });
            });
        });

        return cargoItems.map((cargoItem) => {
            const loading = cargoItem.LoadPortName || cargoItem.LoadPointName;
            const discharging = cargoItem.DischargePortName || cargoItem.DischargePointName;
            let fromTo;
            if (loading || discharging) {
                fromTo = `${translate('CARGO_LIST.FROM')} ${loading} ${translate('CARGO_LIST.TO')} ${discharging}`;
            }
            const lastTime = TimeHelper.getFormatted(cargoItem.Voyage.LastEtdTime, { utc: true });
            const firstTime = TimeHelper.getFormatted(cargoItem.Voyage.FirstEtaTime, { utc: true });
            const voyageDuration = `${firstTime} ${translate('CARGO_LIST.TO')} ${lastTime}`;
            const header = (
                <div className="flex-grow">
                    <h4 className="text-uppercase sten-title-link" onClick={this.onTitleClick.bind(this, cargoItem)}>
                        {cargoItem.Commodity ? `${cargoItem.Commodity} ${fromTo}` : fromTo}
                    </h4>
                    <h6 className="text-secondary">
                        {`${cargoItem.Vessel.Title}, ${voyageDuration}`}
                    </h6>
                </div>
            );
            return (
                <Accordion
                    className="sten-panel sten-cargo-list__fixture"
                    key={cargoItem.CargoId}
                    header={header}
                    isCollapsed={this.state.activeOptionId === 1}
                    chevronRight
                >
                    {this.getCargoDetails(cargoItem, voyageDuration, loading, discharging)}
                </Accordion>
            );
        });
    }

    setActiveOption = option => {
        this.setState({ activeOptionId: option.id });
        if (option.id === 0) {
            this.props.getActiveCargoes();
        } else {
            this.props.getHistoricalCargoes(true);
        }
        this.scrollArea.scrollArea.scrollTop();
    };

    scrollHandler = value => {
        if (this.state.activeOptionId === 1 && (value.realHeight - value.topPosition < value.containerHeight + 100)) {
            this.props.getHistoricalCargoes(false);
        }
    };

    saveRef = c => { this.scrollArea = c; };

    render() {
        const options = this.getOptions();
        let cargoes;
        if (this.state.activeOptionId === 0) {
            cargoes = this.props.activeCargoes;
        } else {
            cargoes = this.props.historicalCargoes;
        }
        return (
            <div className="sten-cargo-list">
                <div className="sten-content__section sten-cargo-list__body-fixed">
                    <label className="label">
                        {translate('CARGO_LIST.CARGO_STATUS')}
                    </label>
                    <Select
                        className="sten-vessel-info__select"
                        valueKey="id"
                        labelKey="title"
                        options={options}
                        menuShownIcon="icon-chevron-down"
                        value={options.find(option => option.id === this.state.activeOptionId)}
                        onChange={this.setActiveOption}
                    />
                </div>
                <ScrollArea
                    className="sten-cargo-list__body-content sten-content__separator"
                    ref={this.saveRef}
                    onScroll={this.scrollHandler}
                >
                    <div className="sten-content__section">
                        {cargoes.length === 0
                            ? (
                                <p className="text-secondary">
                                    {this.state.activeOptionId === 0
                                        ? translate('CARGO_LIST.NO_ACTIVE_CARGOES')
                                        : translate('CARGO_LIST.NO_HISTORICAL_CARGOES')
                                    }
                                </p>
                            )
                            : this.getVesselCargoes(cargoes)
                        }
                    </div>
                </ScrollArea>
            </div>
        );
    }
}

CargoesList.propTypes = {
    activeCargoes: PropTypes.arrayOf(PropTypes.any).isRequired,
    getActiveCargoes: PropTypes.func.isRequired,
    getHistoricalCargoes: PropTypes.func.isRequired,
    historicalCargoes: PropTypes.arrayOf(PropTypes.any).isRequired,
    router: TRouter.isRequired
};

function mapStateToProps(state) {
    return {
        activeCargoes: state.employedVesselsPanelReducer.activeCargoes,
        historicalCargoes: state.employedVesselsPanelReducer.historicalCargoes
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getActiveCargoes: () => getActiveCargoes(dispatch),
        getHistoricalCargoes: overrideCurrent => getHistoricalCargoes(dispatch, overrideCurrent)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CargoesList));
