/* ol */
import Feature from 'ol/Feature';
/* ol layers */
import LayerVector from 'ol/layer/Vector';
import LayerHeatmap from 'ol/layer/Heatmap';
/* ol sources */
import SourceVector from 'ol/source/Vector';
import SourceCluster from 'ol/source/Cluster';
/* ol styles */
import Style from 'ol/style/Style';
import StyleStroke from 'ol/style/Stroke';
/* ol geometries */
import GeomLineString from 'ol/geom/LineString';
import GeomMultiLineString from 'ol/geom/MultiLineString';
/* helpers */
import { getOrExecProp } from 'utils/helpers/info-helper';
/* config */
import MapConfig from './configs/map';
/* style functions */
import {
    getPointStyle,
    getIconStyle,
    getLineStyle,
    getAreaStyle,
    getGeoJsonStyle
} from './ol-map-styles';

const getGraticuleVector = (features, config) => new LayerVector({
    source: new SourceVector({
        features
    }),
    style: new Style({
        stroke: new StyleStroke({
            color: config.lineColor,
            width: config.lineWidth,
            lineDash: config.lineDash
        })
    }),
    renderBuffer: 1,
    zIndex: MapConfig.layerZIndex.graticule
});

const getPointVector = (layerName, features, zIndex, getZoom, updateOverlay, opacity, layerProps) => {
    return new LayerVector({
        source: new SourceVector({
            features
        }),
        style: feature => getPointStyle(feature, getZoom, updateOverlay),
        renderBuffer: 10,
        zIndex,
        opacity,
        layerName,
        ...layerProps
    });
};

const getClusterVector = (layerName, features, zIndex, getZoom, updateOverlay, opacity, layerProps, sourceProps) => {
    return new LayerVector({
        source: new SourceCluster({
            ...sourceProps,
            createCluster: (point, features) => {
                const feature = new Feature({
                    geometry: point,
                    features
                });
                feature.set('layerName', layerName);
                feature.data = features[0].data;
                feature.props = features[0].props;
                feature.isHovered = false;
                return feature;
            },
            source: new SourceVector({
                features
            })
        }),
        style: feature => getPointStyle(feature, getZoom, updateOverlay),
        renderBuffer: 10,
        zIndex,
        opacity,
        layerName,
        ...layerProps
    });
};

const getIconVector = (layerName, features, zIndex, getZoom, updateOverlay, opacity, layerProps) => {
    return new LayerVector({
        source: new SourceVector({
            features
        }),
        style: feature => getIconStyle(feature, getZoom, updateOverlay),
        renderBuffer: 10,
        zIndex,
        opacity,
        layerName,
        ...layerProps
    });
};

const getHeatMapVector = (layerName, features, zIndex, getZoom, updateOverlay, opacity, layerProps) => {
    return new LayerHeatmap({
        source: new SourceVector({
            features
        }),
        renderBuffer: 10,
        zIndex,
        opacity,
        layerName,
        ...layerProps
    });
};

const getLineVector = (layerName, line, lineProps, zIndex, getZoom, opacity, layerProps) => {
    const features = [];
    if (lineProps.coordinates) {
        const coordinates = getOrExecProp(line, lineProps.coordinates);
        const lineString = new GeomLineString(coordinates).transform(
            MapConfig.sourceProjection,
            MapConfig.targetProjection
        );
        const feature = new Feature({
            geometry: lineString,
            name: 'Line'
        });
        feature.data = line;
        feature.props = lineProps;
        feature.set('layerName', layerName);
        features.push(feature);
    } else {
        const lineString = new GeomLineString(line).transform(
            MapConfig.sourceProjection,
            MapConfig.targetProjection
        );
        const lineFeature = new Feature({
            geometry: lineString,
            name: 'Line'
        });
        lineFeature.set('layerName', layerName);
        lineFeature.props = lineProps;
        features.push(lineFeature);
    }

    return new LayerVector({
        source: new SourceVector({ features }),
        style: feature => getLineStyle(feature, getZoom),
        renderBuffer: 5,
        zIndex,
        opacity,
        layerName,
        ...layerProps
    });
};

const getMultiLineVector = (layerName, lines, lineProps, zIndex, getZoom, opacity, layerProps) => {
    const features = [];
    if (lineProps.coordinates) {
        lines.forEach((line) => {
            const coordinates = getOrExecProp(line, lineProps.coordinates);
            const lineString = new GeomLineString(coordinates).transform(
                MapConfig.sourceProjection,
                MapConfig.targetProjection
            );
            const feature = new Feature({
                geometry: lineString,
                name: 'MultiLine'
            });
            feature.data = line;
            feature.props = lineProps;
            feature.set('layerName', layerName);
            features.push(feature);
        });
    } else {
        const multiLineString = new GeomMultiLineString(lines).transform(
            MapConfig.sourceProjection,
            MapConfig.targetProjection
        );
        const multiLineFeature = new Feature({
            geometry: multiLineString,
            name: 'MultiLine'
        });
        multiLineFeature.set('layerName', layerName);
        multiLineFeature.props = lineProps;
        features.push(multiLineFeature);
    }

    return new LayerVector({
        source: new SourceVector({ features }),
        style: feature => getLineStyle(feature, getZoom),
        renderBuffer: 5,
        zIndex,
        opacity,
        layerName,
        ...layerProps
    });
};

const getAreaVector = (layerName, features, zIndex, opacity, layerProps) => {
    return new LayerVector({
        source: new SourceVector({
            features
        }),
        style: getAreaStyle,
        zIndex,
        opacity,
        layerName,
        ...layerProps
    });
};

const getGeoJsonVector = (layerName, features, zIndex, opacity, layerProps) => {
    return new LayerVector({
        source: new SourceVector({
            features
        }),
        style: getGeoJsonStyle,
        renderBuffer: 5,
        zIndex,
        opacity,
        layerName,
        ...layerProps
    });
};

export {
    getPointVector,
    getClusterVector,
    getIconVector,
    getAreaVector,
    getGraticuleVector,
    getLineVector,
    getMultiLineVector,
    getGeoJsonVector,
    getHeatMapVector
};
