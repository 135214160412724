import { takeEvery, put } from 'redux-saga/effects';
/* actions */
import { ActionTypes } from './nautical-charts-actions';
/* services */
import ConfigService from 'services/config-api/config-service';
import NauticalChartsService from 'services/core-api/nautical-charts-service';

let areAvailableLayersFetched = false;
let fetchingAvailableLayers = false;

function* getAvailableLayers() {
    if (!areAvailableLayersFetched && !fetchingAvailableLayers && ConfigService.featureToggles.showNauticalLayers) {
        fetchingAvailableLayers = true;
        const capabilities = yield NauticalChartsService.getCapabilities();
        fetchingAvailableLayers = false;
        if (capabilities) {
            areAvailableLayersFetched = true;
            yield put({
                type: ActionTypes.NAUTICAL_CHARTS_SET_AVAILABLE_LAYERS,
                payload: capabilities?.WMS_Capabilities?.Capability?.Layer?.Layer || null
            });
        }
    }
}

export default function* nauticalChartsSaga() {
    yield takeEvery(ActionTypes.NAUTICAL_CHARTS_GET_AVAILABLE_LAYERS, getAvailableLayers);
}
