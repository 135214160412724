/* actions */
import { ActionTypes } from './position-list-actions';

const emptyArray = [];

const initialState = {
    areSettingsVisible: false,
    fixtureStatuses: emptyArray,
    groupings: emptyArray,
    isComposeViewActive: false,
    selectedVessels: emptyArray,
    tableColumns: emptyArray,
    vesselInfoConfigurableProperties: emptyArray,
    vessels: emptyArray
};

export default function positionListReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.POSITION_LIST_TOGGLE_SETTINGS: {
        return {
            ...state,
            areSettingsVisible:
                typeof action.areSettingsVisible === 'boolean' ? action.areSettingsVisible : !state.areSettingsVisible
        };
    }
    case ActionTypes.POSITION_LIST_SET_TABLE_COLUMNS: {
        return {
            ...state,
            tableColumns: action.tableColumns || emptyArray
        };
    }
    case ActionTypes.POSITION_LIST_SET_VESSEL_INFO_CONFIGURABLE_PROPERTIES: {
        return {
            ...state,
            vesselInfoConfigurableProperties: action.vesselInfoConfigurableProperties || emptyArray
        };
    }
    case ActionTypes.POSITION_LIST_SET_GROUPINGS: {
        return {
            ...state,
            groupings: action.groupings
        };
    }
    case ActionTypes.POSITION_LIST_SET_VESSELS: {
        return {
            ...state,
            vessels: action.vessels
        };
    }
    case ActionTypes.POSITION_LIST_SET_SELECTED_VESSELS: {
        return {
            ...state,
            selectedVessels: action.selectedVessels
        };
    }
    case ActionTypes.POSITION_LIST_UPDATE_VESSEL_NOTE: {
        const vessels = state.vessels.map((vessel) => {
            if (vessel.IMO === action.vesselImo) {
                if (action.isInternal) {
                    return {
                        ...vessel,
                        InternalNote: action.submittedNote
                    };
                }
                return {
                    ...vessel,
                    Note: action.submittedNote
                };
            }
            return vessel;
        });
        return {
            ...state,
            vessels
        };
    }
    case ActionTypes.POSITION_LIST_TOGGLE_COMPOSE: {
        return {
            ...state,
            isComposeViewActive: action.flag
        };
    }
    case ActionTypes.POSITION_LIST_SET_FIXTURE_STATUSES: {
        return {
            ...state,
            fixtureStatuses: action.fixtureStatuses
        };
    }
    case ActionTypes.POSITION_LIST_RESET: {
        return initialState;
    }
    default:
        return state;
    }
}
