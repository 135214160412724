export default {
    mapCenter: [1300000, 5000000],
    vessel: {
        alertColor: 'rgb(219,64,68)',
        primaryColor: 'rgb(40,255,202)',
        secondaryColor: 'rgb(255,255,255)',
        tertiaryColor: 'rgb(255, 235, 59)',
        quaternaryColor: 'rgb(255, 152, 0)',
        labelMinZoomLevel: 5,
        alertMinZoomLevel: 5,
        zIndex: 60
    },
    port: {
        primaryColor: 'rgb(40,255,202)',
        secondaryColor: 'rgb(255,255,255)',
        backgroundColor: 'rgba(15,46,57,0.3)',
        labelMinZoomLevel: 3,
        zoomOnSelect: 10,
        zIndex: 50
    },
    nautical: {
        zIndex: 10
    }
};
