import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import memoize from 'memoize-one';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { formatCoordinates, formatNumber } from 'utils/helpers/info-helper';
/* actions */
import { getResults, setDisabled, toggleFocus } from './global-search-actions';
/* components */
import Select from 'components/select/select';
import GlobalSearchOptionPort from './global-search-option-port';
import GlobalSearchOptionTerminal from './global-search-option-terminal';
import GlobalSearchOptionVessel from './global-search-option-vessel';
/* styles */
import './global-search.scss';

export class GlobalSearch extends React.PureComponent {
    componentDidMount() {
        this.props.setDisabled(false);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isFocused !== this.props.isFocused && this.props.isFocused) {
            setTimeout(this.selectRef.onOuterDivFocus, 10);
        }
    }

    componentWillUnmount() {
        this.props.setDisabled(true);
    }

    onSearchTermChange = value => {
        if (this.debounce) {
            clearTimeout(this.debounce);
        }
        const trimmedValue = value.trim();
        if (trimmedValue.length >= 3) {
            this.debounce = setTimeout(() => {
                this.props.getResults(trimmedValue);
            }, 500);
        }
    };

    handleOptionClick = (option) => {
        switch (option.type) {
        case 'vessel':
            this.props.router.updateQueryParams({ add: { vessel: option.value.Imo } });
            break;
        case 'port':
            this.props.router.updateQueryParams({ add: { port: option.value.Id } });
            break;
        case 'terminal':
            this.props.router.updateQueryParams({ add: { terminal: option.value.Id, port: option.value.PortId } });
            break;
        default:
        }
    };

    preventClickPropagation(e) {
        e.stopPropagation();
    }

    parseLocation = (location) => {
        if (location && location.length > 5) {
            const coordinates = location.substring(6, location.length - 1).split(' ');
            return formatCoordinates(coordinates[0], coordinates[1]);
        }
        return '';
    };

    generateVesselObj = (vessel) => {
        const subtitleArray = [];
        if (vessel.VesselType) {
            subtitleArray.push(vessel.VesselType);
        }
        if (vessel.VesselType === 'LNG' && vessel.TotalCubicCapacity) {
            subtitleArray.push(
                `${translate('GLOBAL_SEARCH.CAPACITY')} `
                + `${formatNumber(vessel.TotalCubicCapacity, 0)} ${translate('UNITS.CUBIC_CAPACITY')}`
            );
        } else if (vessel.DWT) {
            subtitleArray.push(
                `${translate('GLOBAL_SEARCH.DWT')} ${formatNumber(vessel.DWT, 0)} ${translate('UNITS.DWT')}`
            );
        }
        if (vessel.ImoClass) {
            subtitleArray.push(`${translate('GLOBAL_SEARCH.IMO_CLASS')} ${vessel.ImoClass}`);
        }

        const imo = vessel.Imo.toString();

        return {
            type: 'vessel',
            label: vessel.Title,
            key: `vessel${imo}`,
            value: {
                ...vessel,
                Imo: imo,
                VesselType: vessel.VesselType || translate('GLOBAL.UNKNOWN'),
                VesselSubtitle: subtitleArray.join(', '),

                vesselClass: 'sten-global-search-results__item flex flex-center'
                    + `${vessel.IsVesselInUserScope ? ' sten-global-search-results__item--primary' : ''}`
            }
        };
    };

    generatePointObj = (point) => {
        const subtitleArray = [];
        if (point.Country) {
            subtitleArray.push(point.Country);
        }
        if (point.Location) {
            subtitleArray.push(this.parseLocation(point.Location));
        }
        const id = point.Id.toString();

        return {
            type: 'port',
            label: point.Name,
            key: `port${id}`,
            value: {
                ...point,
                Id: id,
                Subtitle: subtitleArray.join(', ')
            }
        };
    };

    generateTerminalObj = (terminal) => {
        const subtitleArray = [terminal.Port.Name];
        if (terminal.Port.Country) {
            subtitleArray.push(terminal.Port.Country);
        }
        const id = terminal.Id.toString();

        return {
            type: 'terminal',
            label: terminal.Name,
            key: `terminal${id}`,
            value: {
                ...terminal,
                Id: id,
                PortId: terminal.Port.Id,
                Subtitle: subtitleArray.join(', ')
            }
        };
    };

    optionRenderer = (option, searchTerm) => {
        switch (option.type) {
        case 'vessel':
            return (
                <GlobalSearchOptionVessel
                    searchCriteria={searchTerm}
                    {...option.value}
                />
            );
        case 'port':
            return (
                <GlobalSearchOptionPort
                    searchCriteria={searchTerm}
                    {...option.value}
                />
            );
        case 'terminal':
            return (
                <GlobalSearchOptionTerminal
                    searchCriteria={searchTerm}
                    {...option.value}
                />
            );
        default:
            return null;
        }
    };

    generateOptions = memoize((searchResults) => {
        let options = [];

        if (searchResults) {
            const ports = [];
            const points = [];
            searchResults.Points.forEach((obj) => {
                if (obj.Type === 2) { // port
                    ports.push(this.generatePointObj(obj));
                } else { // point
                    points.push(this.generatePointObj(obj));
                }
            });

            const vesselsInFleets = [];
            const competitorVessels = [];
            searchResults.Vessels.forEach((obj) => {
                if (obj.IsCompetition === true) {
                    competitorVessels.push(this.generateVesselObj(obj));
                } else {
                    vesselsInFleets.push(this.generateVesselObj(obj));
                }
            });

            options = [
                ...vesselsInFleets,
                ...ports,
                ...points,
                ...competitorVessels,
                ...searchResults.Terminals.map(this.generateTerminalObj)
            ];
        }

        return options;
    });

    render() {
        let className = 'sten-global-search';
        if (this.props.fullWidth) {
            className += ' sten-global-search--full-width';
        }
        if (this.props.isDisabled) {
            className += ' sten-global-search--disabled';
        }

        const options = this.generateOptions(this.props.searchResults);

        return (
            <div className={className}>
                <div className="sten-global-search__panel" onClick={this.preventClickPropagation}>
                    <Select
                        ref={(r) => { this.selectRef = r; }}
                        fixedListWidth
                        searchable
                        className="sten-global-search__select"
                        stopPropagation
                        onSearch={this.onSearchTermChange}
                        optionRenderer={this.optionRenderer}
                        placeholder={translate('GLOBAL_SEARCH.SEARCH')}
                        valueKey="key"
                        labelKey="label"
                        options={options}
                        disabled={this.props.isDisabled}
                        onChange={this.handleOptionClick}
                        chevronIconClass="icon-search"
                    />
                </div>
            </div>
        );
    }
}

GlobalSearch.propTypes = {
    fullWidth: PropTypes.bool.isRequired,
    getResults: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    isFocused: PropTypes.bool.isRequired,
    router: TRouter.isRequired,
    searchResults: PropTypes.objectOf(PropTypes.array),
    setDisabled: PropTypes.func.isRequired,
    toggleFocus: PropTypes.func.isRequired
};

GlobalSearch.defaultProps = {
    searchResults: null
};

function mapStateToProps(state) {
    return {
        isDisabled: state.globalSearchReducer.isDisabled,
        isFocused: state.globalSearchReducer.isFocused,
        searchResults: state.globalSearchReducer.searchResults
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getResults: searchCriteria => getResults(dispatch, searchCriteria),
        setDisabled: isDisabled => setDisabled(dispatch, isDisabled),
        toggleFocus: isFocused => toggleFocus(dispatch, isFocused)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GlobalSearch));
