import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
/* components */
import Tooltip from 'components/tooltip/tooltip';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
/* styles */
import './voyage-reports.scss';

export default class VoyageReportTooltip extends React.PureComponent {
    getTooltipContent = memoize((report) => {
        return (
            <div className="flex-row flex-center">
                <div className="flex-shrink">
                    <span className="sten-voyage-report__icon icon icon-daily-report" />
                </div>
                <div className="flex-grow">
                    <p className="sten-voyage-report__content-text">
                        {translate('MAP.TOOLTIPS.DAILY_REPORT')}
                    </p>
                    <h6>
                        {translate('MAP.TOOLTIPS.REPORTED')}
                        {TimeHelper.getFormatted(report.Date, { utc: true, time: true })}
                    </h6>
                </div>
            </div>
        );
    });

    render() {
        return (
            <Tooltip
                placement="right"
                contentClassName="sten-voyage-report__tooltip"
                content={this.getTooltipContent(this.props.report)}
                alwaysVisible={this.props.isActive}
            />
        );
    }
}

VoyageReportTooltip.propTypes = {
    isActive: PropTypes.bool.isRequired,
    report: PropTypes.objectOf(PropTypes.any).isRequired
};
