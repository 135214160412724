import React from 'react';
import PropTypes from 'prop-types';
import fastDeepEqual from 'fast-deep-equal';
/* rules */
import rules from './validation-rules';

export const ValidationContext = React.createContext();

class ValidationForm extends React.PureComponent {
    constructor(props) {
        super(props);
        this.components = {};

        this.state = {
            showErrors: props.showErrors,
            errors: {},
            staticErrors: {},
            warnings: {}
        };
    }

    getContext() {
        return {
            components: this.components,
            errors: { ...this.state.errors, ...this.getStaticErrors() },
            warnings: this.getWarnings(),
            register: this.register,
            resetValidation: this.resetValidation,
            showErrors: this.state.showErrors,
            unregister: this.unregister,
            validateState: this.validateState,
            validateAll: this.validateAll
        };
    }

    onSubmit = (e) => {
        if (e) {
            e.preventDefault();
            if (this.props.stopPropagation) {
                e.stopPropagation();
            }
        }
        if (this.props.beforeSubmit) {
            this.props.beforeSubmit();
        }
        const errors = this.validateAll();
        if (!Object.keys(errors).length) {
            this.props.onSubmit(e);
        }
    };

    getStaticErrors = () => {
        const staticErrors = {};
        Object.keys(this.state.staticErrors).forEach((key) => {
            if (this.components[key]) {
                staticErrors[key] = this.state.staticErrors[key];
            }
        });
        return staticErrors;
    };

    getWarnings = () => {
        const warnings = {};
        Object.keys(this.state.warnings).forEach((key) => {
            if (this.components[key]) {
                warnings[key] = this.state.warnings[key];
            }
        });
        return warnings;
    };

    getErrors = () => {
        const errors = {};

        Object.keys(this.components).forEach((name) => {
            const component = this.components[name];
            const validations = component.props.validations;
            const validate = typeof component.state.validate === 'boolean' ? component.state.validate : true;

            if (validate && validations) {
                Object.keys(validations).forEach((key) => {
                    const validationRule = rules[key] || rules.custom;
                    if (!validationRule.rule(component.state.value, validations[key], this.components, name)) {
                        errors[name] = errors[name] || [];
                        errors[name].push(key);
                    }
                });
            }
        });

        return errors;
    };

    register = component => {
        if (process.env.NODE_ENV === 'development') {
            /* eslint-disable no-console */
            if (!component.props.children.props.name) {
                console.error(component.props.children, 'must have a valid "name" prop');
            }
            /* eslint-enable no-console */
        }
        this.components[component.props.children.props.name] = component;
        this.validateState(true);
    };

    unregister = componentName => {
        delete this.components[componentName];
        this.validateState();
    };

    validateState = (forceValidate = false) => {
        if (this.state.showErrors) {
            const errors = this.getErrors();
            if (forceValidate || !fastDeepEqual(this.state.errors, errors)) {
                this.setState({ errors });
            }
        }
    };

    validateAll = () => {
        const errors = this.getErrors();
        if (this.props.clearErrorsOnSubmit) {
            this.setState(prevState => ({
                ...prevState,
                showErrors: true,
                errors,
                staticErrors: {},
                warnings: {}
            }), this.props.onInvalid);
            return errors;
        }
        this.setState(prevState => ({
            ...prevState,
            showErrors: true,
            errors,
            warnings: {}
        }), this.props.onInvalid);
        return { ...errors, ...this.getStaticErrors() };
    };

    showError = (name, error) => {
        this.setState((prevState) => {
            const staticErrors = { ...prevState.staticErrors };
            staticErrors[name] = [error];
            return { ...prevState, staticErrors };
        });
    };

    hideError = name => {
        this.setState((prevState) => {
            const staticErrors = { ...prevState.staticErrors };
            if (staticErrors[name]) {
                delete staticErrors[name];
            }
            return { ...prevState, staticErrors };
        });
    };

    hideAllErrors = callback => {
        this.setState(prevState => ({ ...prevState, staticErrors: {} }), callback);
    };

    showWarning = (name, warning) => {
        this.setState((prevState) => {
            const warnings = { ...prevState.warnings };
            warnings[name] = [warning];
            return { ...prevState, warnings };
        });
    };

    hideWarning = name => {
        this.setState((prevState) => {
            const warnings = { ...prevState.warnings };
            if (warnings[name]) {
                delete warnings[name];
            }
            return { ...prevState, warnings };
        });
    };

    hideAllWarnings = callback => {
        this.setState(prevState => ({ ...prevState, warnings: {} }), callback);
    };

    resetValidation = () => {
        this.setState({ errors: {}, staticErrors: {}, warnings: {}, showErrors: this.props.showErrors });
    };

    render() {
        let classNames = 'sten-validation-form';
        if (this.props.className) {
            classNames += ` ${this.props.className}`;
        }
        return (
            <ValidationContext.Provider value={this.getContext()}>
                <form className={classNames} onSubmit={this.onSubmit}>
                    {this.props.children}
                </form>
            </ValidationContext.Provider>
        );
    }
}

ValidationForm.propTypes = {
    beforeSubmit: PropTypes.func,
    children: PropTypes.node,
    className: PropTypes.string,
    clearErrorsOnSubmit: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onInvalid: PropTypes.func,
    showErrors: PropTypes.bool,
    stopPropagation: PropTypes.bool
};

ValidationForm.defaultProps = {
    beforeSubmit: undefined,
    children: null,
    className: '',
    clearErrorsOnSubmit: false,
    onInvalid: undefined,
    showErrors: false,
    stopPropagation: false
};

export default ValidationForm;
