import React from 'react';
/* services */
import AuthService from 'services/auth-service';
import ConfigService from 'services/config-api/config-service';

export class AuthSilentRedirect extends React.PureComponent {
    componentDidMount() {
        AuthService.userManager.signinSilentCallback().catch(() => {
            ConfigService.clearCache();
            AuthService.logout();
        });
    }

    render() {
        return null;
    }
}

export default AuthSilentRedirect;
