import { ActionTypes } from './performance-summary-actions';

const initialState = {
    performanceSummaryReport: null
};

export default function energyManagementReportsPerformanceSummaryReportReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ENERGY_MANAGEMENT_PERFORMANCE_SUMMARY_REPORT_SET: {
        return {
            ...state,
            performanceSummaryReport: action.performanceSummaryReport
        };
    }
    default:
        return state;
    }
}
