import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
/* actions */
import { generateXLSX, generatePDF } from './performance-summary-actions';
/* components */
import EmptyContent from 'components/empty-content/empty-content';
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
import ContentHeader from '../../shared/content-header';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import ReportHelper from 'utils/helpers/report-helper';
/* styles */
import './performance-summary-report.scss';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.PERFORMANCE_SUMMARY.${key}`);

class PerformanceSummaryReport extends React.PureComponent {
    reportTopHorizontalHeader = (
        <thead>
            <tr>
                <th rowSpan="2">{t('VESSEL_NAME')}</th>
                <th colSpan="2">{t('DEPARTURE')}</th>
                <th colSpan="2">{t('ARRIVAL')}</th>
                <th>{t('CONDITION')}</th>
                <th colSpan="3">{t('DEPARTURE_DATA')}</th>
                <th>{t('SPEED')}</th>
                <th colSpan="5">{t('ALL_REPORTED_DATA')}</th>
                <th colSpan="5">{t('DATA_WITH_EXCLUSIONS')}</th>
                <th colSpan="3">{t('THEORETICAL_BASIS_CP')}</th>
                <th colSpan="2">{t('DEVIATION_FROM_CP')}</th>
            </tr>
            <tr>
                <th>{t('PORT')}</th>
                <th>{t('DATE')}</th>
                <th>{t('PORT')}</th>
                <th>{t('DATE')}</th>
                <th>{t('BALLAST_LADEN')}</th>
                <th>{t('CARGO')}</th>
                <th>{t('MEAN_DRAFT')}</th>
                <th>{t('TRIM')}</th>
                <th>{t('INSTRUCTED_SPEED')}</th>
                <th>{t('TOTAL_DISTANCE')}</th>
                <th>{t('TOTAL_TIME')}</th>
                <th>{t('AVG_SPEED')}</th>
                <th>{t('TOTAL_MAIN_ENG_CONS')}</th>
                <th>{t('TOTAL_AUX_CONS')}</th>
                <th>{t('TOTAL_DISTANCE')}</th>
                <th>{t('TOTAL_TIME')}</th>
                <th>{t('AVG_SPEED')}</th>
                <th>{t('TOTAL_MAIN_ENG_CONS')}</th>
                <th>{t('TOTAL_AUX_CONS')}</th>
                <th>{t('AVG_SPEED')}</th>
                <th>{t('TOTAL_MAIN_ENG_CONS')}</th>
                <th>{t('TOTAL_AUX_CONS')}</th>
                <th>{t('TIME_DEVIATION')}</th>
                <th>{t('CONSUMPTION_DEVIATION')}</th>
            </tr>
        </thead>
    );

    renderSingleReportRow = (vessel, section, sectionIndex, instructedSpeed, index, titleRowSpan) => {
        const rowSpan = Math.max(section.InstructedSpeeds.length, 1);
        let ballastLaden = null;
        if (index === 0) {
            ballastLaden = section.IsBallast ? translate('GLOBAL.BALLAST') : translate('GLOBAL.LADEN');
        }
        return (
            <tr key={`${vessel.Imo}-${sectionIndex}-${index}`}>
                {titleRowSpan ? <th rowSpan={titleRowSpan}>{vessel.Title}</th> : null}
                {index === 0 && (
                    <React.Fragment>
                        <td rowSpan={rowSpan}>
                            {section.DeparturePort}
                        </td>
                        <td rowSpan={rowSpan}>
                            {ReportHelper.formatCellValue(section.DepartureDate, true)}
                        </td>
                        <td rowSpan={rowSpan}>
                            {section.ArrivalPort}
                        </td>
                        <td rowSpan={rowSpan}>
                            {ReportHelper.formatCellValue(section.ArrivalDate, true)}
                        </td>
                        <td rowSpan={rowSpan}>
                            {ballastLaden}
                        </td>
                        <td rowSpan={rowSpan}>
                            {ReportHelper.formatCellValue(section.Cargo)}
                        </td>
                        <td rowSpan={rowSpan}>
                            {ReportHelper.formatCellValue(section.MeanDraft)}
                        </td>
                        <td rowSpan={rowSpan}>
                            {ReportHelper.formatCellValue(section.Trim)}
                        </td>
                    </React.Fragment>
                )}
                {instructedSpeed ? (
                    <React.Fragment>
                        <td>
                            {ReportHelper.formatCellValue(instructedSpeed.InstructedSpeed)}
                        </td>
                        {instructedSpeed.AllReportedData ? (
                            <React.Fragment>
                                <td>
                                    {ReportHelper.formatCellValue(instructedSpeed.AllReportedData.TotalDistance)}
                                </td>
                                <td>
                                    {ReportHelper.formatCellValue(instructedSpeed.AllReportedData.TotalTime)}
                                </td>
                                <td>
                                    {ReportHelper.formatCellValue(instructedSpeed.AllReportedData.AvgSpeed)}
                                </td>
                                <td>
                                    {ReportHelper.formatCellValue(instructedSpeed.AllReportedData.TotalPropCons)}
                                </td>
                                <td>
                                    {ReportHelper.formatCellValue(instructedSpeed.AllReportedData.TotalAuxCons)}
                                </td>
                            </React.Fragment>
                        ) : (
                            <td colSpan="5" />
                        )}
                        {instructedSpeed.DataWithExclusions ? (
                            <React.Fragment>
                                <td>
                                    {ReportHelper.formatCellValue(instructedSpeed.DataWithExclusions.TotalDistance)}
                                </td>
                                <td>
                                    {ReportHelper.formatCellValue(instructedSpeed.DataWithExclusions.TotalTime)}
                                </td>
                                <td>
                                    {ReportHelper.formatCellValue(instructedSpeed.DataWithExclusions.AvgSpeed)}
                                </td>
                                <td>
                                    {ReportHelper.formatCellValue(instructedSpeed.DataWithExclusions.TotalPropCons)}
                                </td>
                                <td>
                                    {ReportHelper.formatCellValue(instructedSpeed.DataWithExclusions.TotalAuxCons)}
                                </td>
                            </React.Fragment>
                        ) : (
                            <td colSpan="5" className="text-secondary">
                                {instructedSpeed.ExclusionReasons
                                    ? instructedSpeed.ExclusionReasons.join('; ')
                                    : '\u00A0'}
                            </td>
                        )}
                        {instructedSpeed.TheoreticalBasisCP ? (
                            <React.Fragment>
                                <td>
                                    {ReportHelper.formatCellValue(instructedSpeed.TheoreticalBasisCP.AvgSpeed)}
                                </td>
                                <td>
                                    {ReportHelper.formatCellValue(instructedSpeed.TheoreticalBasisCP.TotalPropCons)}
                                </td>
                                <td>
                                    {ReportHelper.formatCellValue(instructedSpeed.TheoreticalBasisCP.TotalAuxCons)}
                                </td>
                            </React.Fragment>
                        ) : (
                            <td colSpan="3" />
                        )}
                        {instructedSpeed.DeviationFromCP ? (
                            <React.Fragment>
                                <td>
                                    {ReportHelper.formatCellValue(instructedSpeed.DeviationFromCP.Time)}
                                </td>
                                <td>
                                    {ReportHelper.formatCellValue(instructedSpeed.DeviationFromCP.Consumption)}
                                </td>
                            </React.Fragment>
                        ) : (
                            <td colSpan="2" />
                        )}

                    </React.Fragment>
                ) : (
                    <td colSpan="16" className="text-secondary">
                        {section.ExclusionReasons ? section.ExclusionReasons.join('; ') : '\u00A0'}
                    </td>
                )}
            </tr>
        );
    };

    calculateRowSpan = (sections) => {
        return sections.reduce((res, section) => {
            return res + Math.max(section.InstructedSpeeds.length, 1);
        }, 0);
    };

    renderTable = memoize((performanceSummaryReport) => {
        const rows = [];
        let rowSpan = 0;
        performanceSummaryReport.Vessels.forEach((vessel) => {
            if (vessel.Sections.length > 0) {
                vessel.Sections.forEach((section, sectionIndex) => {
                    if (sectionIndex === 0) {
                        rowSpan = this.calculateRowSpan(vessel.Sections);
                    } else {
                        rowSpan = 0;
                    }
                    if (section.InstructedSpeeds.length > 0) {
                        section.InstructedSpeeds.forEach((instructedSpeed, index) => {
                            if (index > 0) {
                                rowSpan = 0;
                            }
                            rows.push(
                                this.renderSingleReportRow(
                                    vessel, section, sectionIndex, instructedSpeed, index, rowSpan
                                )
                            );
                        }, this);
                    } else {
                        rows.push(
                            this.renderSingleReportRow(vessel, section, sectionIndex, null, 0, rowSpan)
                        );
                    }
                }, this);
            } else {
                rows.push(
                    <tr key={vessel.Imo}>
                        <th>{vessel.Title}</th>
                        <td colSpan={24}>{t('NO_REPORTS')}</td>
                    </tr>
                );
            }
        }, this);

        return (
            <table className="sten-table sten-table--sm">
                {this.reportTopHorizontalHeader}
                <tbody>{rows}</tbody>
            </table>
        );
    });

    renderFooter = memoize(({ ExcludedCount }) => (
        <footer className="sten-content__footer flex-row">
            <div className="flex flex-shrink">
                {typeof ExcludedCount === 'number' && (
                    <p className="flex-shrink hide-sm">
                        <span className="sten-badge sten-badge--secondary">
                            {`${ExcludedCount} ${t('EXCLUDED')}`}
                        </span>
                    </p>
                )}
            </div>
            <div className="flex flex-grow">
                <p className="flex-grow text-secondary">
                    {t('EXCLUSIONS_FOOTER_EXPLANATION')}
                </p>
            </div>
            <div className="flex-shrink">
                <button
                    onClick={this.props.generatePDF}
                    className="btn btn--primary"
                >
                    {t('EXPORT_PDF')}
                </button>
            </div>
            <div className="flex-shrink">
                <button
                    onClick={this.props.generateXLSX}
                    className="btn btn--primary"
                >
                    {t('EXPORT_XLSX')}
                </button>
            </div>
        </footer>
    ));

    render() {
        const contentClassName = 'sten-energy-management-reports-preview__performance-summary-table-content';

        return (
            <div className="sten-content sten-content--has-footer">
                <ContentHeader />
                {!this.props.performanceSummaryReport
                    ? <EmptyContent>{translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.EMPTY_REPORT')}</EmptyContent> : (
                        <React.Fragment>
                            <div className="sten-content__body
                            sten-energy-management-reports-preview__performance-summary-table"
                            >
                                <FixedHeaderTable
                                    contentClassName={contentClassName}
                                    withHeaderColumn
                                >
                                    {this.renderTable(this.props.performanceSummaryReport)}
                                </FixedHeaderTable>
                            </div>
                            {this.renderFooter(this.props.performanceSummaryReport)}
                        </React.Fragment>
                    )}
            </div>
        );
    }
}

PerformanceSummaryReport.propTypes = {
    generatePDF: PropTypes.func.isRequired,
    generateXLSX: PropTypes.func.isRequired,
    performanceSummaryReport: PropTypes.objectOf(PropTypes.any)
};

PerformanceSummaryReport.defaultProps = {
    performanceSummaryReport: null
};

function mapStateToProps(state) {
    return {
        performanceSummaryReport:
            state.energyManagementReportsPerformanceSummaryReportReducer.performanceSummaryReport
    };
}

function mapDispatchToProps(dispatch) {
    return {
        generateXLSX: () => generateXLSX(dispatch),
        generatePDF: () => generatePDF(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceSummaryReport);
