import { ActionTypes } from './emission-estimator-page-actions';

const emptyArray = [];

const initialState = {
    isFullScreen: false,
    ports: emptyArray
};

export default function emissionEstimatorPageReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.EMISSION_ESTIMATOR_PAGE_TOGGLE_FULL_SCREEN: {
        return {
            ...state,
            isFullScreen: typeof action.isFullScreen === 'boolean' ? action.isFullScreen : !state.isFullScreen
        };
    }
    case ActionTypes.EMISSION_ESTIMATOR_PAGE_SET_PORTS: {
        return {
            ...state,
            ports: action.ports
        };
    }
    case ActionTypes.EMISSION_ESTIMATOR_PAGE_RESET_ALL:
        return initialState;
    default:
        return state;
    }
}
