import { createSelector } from 'reselect';
/* utils */
import { convertPortForEdit } from 'components/port-form/port-form-helper';

export const getConvertedPort = createSelector(
    state => state.portInfoReducer.port,
    state => state.portInfoReducer.services,
    state => state.portInfoReducer.bunkeringTypes,
    convertPortForEdit
);
