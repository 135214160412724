import React from 'react';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { formatNumber } from 'utils/helpers/info-helper';
/* components */
import Tooltip from 'components/tooltip/tooltip';
/* styles */
import './weather-routing-leg-tooltip.scss';

const emptyArray = [];

class WeatherRoutingLegTooltip extends React.PureComponent {
    state = {
        pixels: emptyArray,
        feature: null
    };

    isWeatherTooltipActive = false;

    tooltipDebounce = null;

    componentWillUnmount() {
        if (this.tooltipDebounce) {
            clearTimeout(this.tooltipDebounce);
        }
    }

    update(event, hoveredFeature, map) {
        if (this.tooltipDebounce) {
            clearTimeout(this.tooltipDebounce);
        }
        if (hoveredFeature?.get('layerName').includes('selectedRouteDots')) {
            const pixels = map.getPixelFromCoordinate(hoveredFeature?.getGeometry()?.getCoordinates(), true, false);
            const feature = hoveredFeature;
            if (pixels !== this.state.pixels || feature !== this.state.feature) {
                if (this.isWeatherTooltipActive) {
                    this.setState({ pixels, feature });
                } else {
                    this.tooltipDebounce = setTimeout(() => {
                        this.isWeatherTooltipActive = true;
                        this.setState({ pixels, feature });
                    }, 200);
                }
            }
        } else if (this.state.feature) {
            this.isWeatherTooltipActive = false;
            this.setState({ pixels: emptyArray, feature: null });
        }
    }

    renderContent = (feature) => (
        <React.Fragment>
            <h4 style={{ color: feature.props.primaryColor }}>
                {t('WEATHER_ROUTING.TOOLTIP.ADVISED_SPEED')}
            </h4>
            <label className="label">{t('WEATHER_ROUTING.TOOLTIP.SPEED_THROUGH_WATER')}:
                <span className="label--value">
                    {` ${formatNumber(feature.data.SpeedInCalmWater, 2)} ${t('UNITS.SPEED')}`}
                </span>
            </label>
            <label className="label">{t('WEATHER_ROUTING.TOOLTIP.SPEED_OVER_GROUND')}:
                <span className="label--value">
                    {` ${formatNumber(feature.data.SpeedOverGround, 3)} ${t('UNITS.SPEED')}`}
                </span>
            </label>
        </React.Fragment>
    );

    render() {
        const { pixels, feature } = this.state;
        if (!pixels?.length || !feature) {
            return null;
        }
        return (
            <Tooltip
                contentClassName="sten-weather-routing-leg-tooltip"
                alwaysVisible
                content={this.renderContent(feature)}
                style={{ top: `${pixels[1]}px`, left: `${pixels[0]}px` }}
            />
        );
    }
}

export default WeatherRoutingLegTooltip;
