import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ScrollArea from 'components/scroll-area/scroll-area';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* components */
import NavLink from 'components/nav-link/nav-link';
import NavSubMenu from 'components/nav-link/nav-sub-menu';
/* services */
import ConfigService from 'services/config-api/config-service';
/* styles */
import './nav-menu.scss';
/* images */
import imgSrc from 'assets/images/orbit_logo.svg';
import imgSrcMcs from 'assets/images/orbit_mcs_logo.svg';

export class NavMenu extends React.PureComponent {
    goToContactInquiry = this.props.setActiveLink.bind(this, 'contact-inquiry');

    goToCargoInquiry = this.props.setActiveLink.bind(this, 'cargo-inquiry');

    goToAccount = this.props.setActiveLink.bind(this, 'account');

    removeActiveLink = () => {
        if (this.props.activeLinkName) {
            this.props.setActiveLink();
        }
    };

    renderBottomLinks = () => {
        const { permissions, isExpanded, activeLinkName } = this.props;
        return (
            <React.Fragment>
                {permissions?.MenuAdminManagement && (
                    <NavSubMenu
                        toggleCollapseEvent="NavSubMenuCollapse"
                        icon="settings"
                        rootPath="/admin"
                        title={t('NAV_MENU.ADMIN_PANEL')}
                        tooltipTitle={!isExpanded ? t('NAV_MENU.ADMIN_PANEL') : ''}
                    >
                        {permissions?.MenuAdminVesselManagement && (
                            <NavLink
                                onClick={this.removeActiveLink}
                                to={appRoutes.Admin.VesselManagement}
                                icon="vessel-management"
                                tooltipTitle={!isExpanded ? t('ADMIN.VESSEL_MANAGEMENT.TITLE') : ''}
                                isActive={location.pathname.startsWith(appRoutes.Admin.VesselManagement)}
                            >
                                {t('ADMIN.VESSEL_MANAGEMENT.TITLE')}
                            </NavLink>
                        )}
                        {permissions?.MenuAdminUserManagement && (
                            <NavLink
                                onClick={this.removeActiveLink}
                                to={appRoutes.Admin.UserManagement}
                                icon="user-management"
                                tooltipTitle={!isExpanded ? t('ADMIN.USER_MANAGEMENT.TITLE') : ''}
                                isActive={location.pathname.startsWith(appRoutes.Admin.UserManagement)}
                            >
                                {t('ADMIN.USER_MANAGEMENT.TITLE')}
                            </NavLink>
                        )}
                        {permissions?.AdminCompanyManagement && (
                            <NavLink
                                onClick={this.removeActiveLink}
                                to={appRoutes.Admin.CompanyManagement}
                                icon="company-management"
                                tooltipTitle={!isExpanded ? t('ADMIN.COMPANY_MANAGEMENT.TITLE') : ''}
                                isActive={location.pathname.startsWith(appRoutes.Admin.CompanyManagement)}
                            >
                                {t('ADMIN.COMPANY_MANAGEMENT.TITLE')}
                            </NavLink>
                        )}
                    </NavSubMenu>
                )}
                <NavLink
                    onClick={this.removeActiveLink}
                    to="/contact-list"
                    icon="contact"
                    tooltipTitle={!isExpanded ? t('CONTACT_LIST.TITLE') : ''}
                    isActive={location.pathname === '/contact-list'}
                >
                    {t('CONTACT_LIST.TITLE')}
                </NavLink>
                <NavLink
                    onClick={this.goToAccount}
                    icon="account"
                    isActive={activeLinkName === 'account'}
                    tooltipTitle={!isExpanded ? t('NAV_MENU.ACCOUNT') : ''}
                >
                    {t('NAV_MENU.ACCOUNT')}
                </NavLink>
            </React.Fragment>
        );
    };

    renderVesselsMenu = (permissions, isExpanded) => {
        if (!permissions) {
            return null;
        }
        const { location } = this.props.router;
        const links = [];
        if (permissions.MenuVesselsManagement && ConfigService.featureToggles.showFleetManagement) {
            links.push(
                <NavLink
                    key="fleet-management"
                    onClick={this.removeActiveLink}
                    to={appRoutes.Vessel.FleetManagement}
                    icon="fleet-management"
                    tooltipTitle={!isExpanded ? t('NAV_MENU.FLEET_MANAGEMENT') : ''}
                    isActive={location.pathname.startsWith(appRoutes.Vessel.FleetManagement)}
                >
                    {t('NAV_MENU.FLEET_MANAGEMENT')}
                </NavLink>
            );
        }
        if (permissions.MenuPositionList && ConfigService.featureToggles.showPositionList) {
            links.push(
                <NavLink
                    key="position-list"
                    onClick={this.removeActiveLink}
                    to={appRoutes.Vessel.PositionList}
                    icon="position-list"
                    tooltipTitle={!isExpanded ? t('NAV_MENU.POSITION_LIST') : ''}
                    isActive={location.pathname.startsWith(appRoutes.Vessel.PositionList)}
                >
                    {t('NAV_MENU.POSITION_LIST')}
                </NavLink>
            );
        }
        if (permissions.Inspections && ConfigService.featureToggles.showInspections) {
            links.push(
                <NavLink
                    key="inspections"
                    onClick={this.removeActiveLink}
                    to={appRoutes.Vessel.Inspections}
                    icon="vetting-database"
                    tooltipTitle={!isExpanded ? t('NAV_MENU.INSPECTIONS') : ''}
                    isActive={location.pathname.startsWith(appRoutes.Vessel.Inspections)}
                >
                    {t('NAV_MENU.INSPECTIONS')}
                </NavLink>
            );
        }
        if (ConfigService.featureToggles.showOperatorsDiary) {
            links.push(
                <NavLink
                    key="operators-diary"
                    onClick={this.removeActiveLink}
                    to={appRoutes.Vessel.OperatorsDiary}
                    icon="diary"
                    tooltipTitle={!isExpanded ? t('NAV_MENU.OPERATORS_DIARY') : ''}
                    isActive={location.pathname.startsWith(appRoutes.Vessel.OperatorsDiary)}
                >
                    {t('NAV_MENU.OPERATORS_DIARY')}
                </NavLink>
            );
        }
        if (permissions.GetVoyagePerformance && ConfigService.featureToggles.showVoyagePerformance) {
            links.push(
                <NavLink
                    key="voyage-performance"
                    onClick={this.removeActiveLink}
                    to={appRoutes.Vessel.VoyagePerformance}
                    icon="voyage-performance"
                    tooltipTitle={!isExpanded ? t('NAV_MENU.VOYAGE_PERFORMANCE') : ''}
                    isActive={location.pathname.startsWith(appRoutes.Vessel.VoyagePerformance)}
                >
                    {t('NAV_MENU.VOYAGE_PERFORMANCE')}
                </NavLink>
            );
        }
        if (links.length > 1) {
            return (
                <NavSubMenu
                    toggleCollapseEvent="NavSubMenuCollapse"
                    icon="laden"
                    rootPath="/vessels"
                    title={t('NAV_MENU.VESSELS')}
                    tooltipTitle={!isExpanded ? t('NAV_MENU.VESSELS') : ''}
                >
                    {links}
                </NavSubMenu>
            );
        }
        return links;
    };

    renderMarketOverviewMenu = (permissions, isExpanded) => {
        if (!permissions) {
            return null;
        }
        const { location } = this.props.router;
        const links = [];
        if (permissions.ShowMarketPredictions) {
            links.push(
                <NavLink
                    key="market-intel"
                    onClick={this.removeActiveLink}
                    to={appRoutes.MarketOverview.MarketIntel}
                    icon="grid-world"
                    tooltipTitle={!isExpanded ? t('NAV_MENU.MARKET_INTEL') : ''}
                    isActive={location.pathname.startsWith(appRoutes.MarketOverview.MarketIntel)}
                >
                    {t('NAV_MENU.MARKET_INTEL')}
                </NavLink>
            );
        }
        if (permissions.GetPortCalls && ConfigService.featureToggles.showPortCallAnalytics) {
            links.push(
                <NavLink
                    key="port-call-analytics"
                    onClick={this.removeActiveLink}
                    to={appRoutes.MarketOverview.PortCallsAnalytics}
                    icon="compare"
                    tooltipTitle={!isExpanded ? t('NAV_MENU.PORT_CALLS_ANALYTICS') : ''}
                    isActive={location.pathname.startsWith(appRoutes.MarketOverview.PortCallsAnalytics)}
                >
                    {t('NAV_MENU.PORT_CALLS_ANALYTICS')}
                </NavLink>
            );
        }
        if (links.length > 1) {
            return (
                <NavSubMenu
                    toggleCollapseEvent="NavSubMenuCollapse"
                    icon="graph"
                    rootPath="/market-overview"
                    title={t('NAV_MENU.MARKET_OVERVIEW')}
                    tooltipTitle={!isExpanded ? t('NAV_MENU.MARKET_OVERVIEW') : ''}
                >
                    {links}
                </NavSubMenu>
            );
        }
        return links;
    };

    renderRoutingMenu = (permissions, isExpanded) => {
        if (!permissions) {
            return null;
        }
        const { location } = this.props.router;
        const links = [];
        if (permissions.ShowDistanceCalc && ConfigService.featureToggles.showDistanceCalculator) {
            links.push(
                <NavLink
                    key="distance-calculator"
                    onClick={this.removeActiveLink}
                    to={appRoutes.Routing.DistanceCalculator}
                    icon="distance-calculator"
                    tooltipTitle={!isExpanded ? t('NAV_MENU.DISTANCE_CALCULATOR') : ''}
                    isActive={location.pathname.startsWith(appRoutes.Routing.DistanceCalculator)}
                >
                    {t('NAV_MENU.DISTANCE_CALCULATOR')}
                </NavLink>
            );
        }
        if (permissions.GetWeatherRoutingV2Voyages && ConfigService.featureToggles.showWeatherRouting) {
            links.push(
                <NavLink
                    key="weather-routing"
                    onClick={this.removeActiveLink}
                    to={appRoutes.Routing.WeatherRouting}
                    icon="next-leg-report"
                    tooltipTitle={!isExpanded ? t('NAV_MENU.WEATHER_ROUTING') : ''}
                    isActive={location.pathname.startsWith(appRoutes.Routing.WeatherRouting)}
                >
                    {t('NAV_MENU.WEATHER_ROUTING')}
                </NavLink>
            );
        }
        if (links.length > 1) {
            return (
                <NavSubMenu
                    toggleCollapseEvent="NavSubMenuCollapse"
                    icon="itinerary"
                    rootPath="/routing"
                    title={t('NAV_MENU.ROUTING')}
                    tooltipTitle={!isExpanded ? t('NAV_MENU.ROUTING') : ''}
                >
                    {links}
                </NavSubMenu>
            );
        }
        return links;
    };

    render() {
        if (!this.props.setActiveLink) {
            return null;
        }
        const { permissions, expand, isExpanded, activeLinkName, user } = this.props;
        const { location } = this.props.router;

        const navClassNames = `sten-nav-menu${isExpanded ? ' sten-nav-menu--expanded' : ''}`;
        const logoImgSrc = ConfigService.tenantConfig.logo === 'mcs' ? imgSrcMcs : imgSrc;

        return (
            <nav className={navClassNames}>
                <div className="sten-nav-menu__container sten-nav-menu__container--top">
                    <ScrollArea horizontal={false}>
                        <NavLink
                            className="sten-nav-link--heading"
                            hasContentLink
                            onClick={expand}
                            to={appRoutes.Map}
                            icon="menu"
                            isActive={isExpanded}
                        >
                            <img className="sten-nav-menu__logo" src={logoImgSrc} alt="OrbitMI" />
                        </NavLink>
                        {user.HomePage === 'Map' && (
                            <NavLink
                                onClick={this.removeActiveLink}
                                to={appRoutes.Map}
                                icon="map"
                                tooltipTitle={!isExpanded ? t('NAV_MENU.MAP') : ''}
                                isActive={location.pathname === appRoutes.Map}
                            >
                                {t('NAV_MENU.MAP')}
                            </NavLink>
                        )}
                        {permissions?.ShowPoolDashboard && ConfigService.featureToggles.showFleetDashboard && (
                            <NavLink
                                onClick={this.removeActiveLink}
                                to={appRoutes.FleetDashboard}
                                icon="pool-overview"
                                tooltipTitle={!isExpanded ? t('NAV_MENU.FLEET_DASHBOARD') : ''}
                                isActive={location.pathname.startsWith(appRoutes.FleetDashboard)}
                            >
                                {t('NAV_MENU.FLEET_DASHBOARD')}
                            </NavLink>
                        )}
                        {user.HomePage !== 'Map' && (
                            <NavLink
                                onClick={this.removeActiveLink}
                                to={appRoutes.Map}
                                icon="map"
                                tooltipTitle={!isExpanded ? t('NAV_MENU.MAP') : ''}
                            >
                                {t('NAV_MENU.MAP')}
                            </NavLink>
                        )}
                        {this.renderRoutingMenu(permissions, isExpanded)}
                        {permissions?.ShowEmissionEstimator && ConfigService.featureToggles.showEmissionEstimator && (
                            <NavLink
                                onClick={this.removeActiveLink}
                                to={appRoutes.EmissionEstimator}
                                icon="distance-calculator"
                                tooltipTitle={!isExpanded ? t('NAV_MENU.EMISSION_ESTIMATOR') : ''}
                                isActive={location.pathname.startsWith(appRoutes.EmissionEstimator)}
                            >
                                {t('NAV_MENU.EMISSION_ESTIMATOR')}
                            </NavLink>
                        )}
                        {this.renderVesselsMenu(permissions, isExpanded)}
                        {permissions?.PortManagementGetPorts && ConfigService.featureToggles.showPortManagement && (
                            <NavLink
                                onClick={this.removeActiveLink}
                                to={appRoutes.PortManagement}
                                icon="port"
                                tooltipTitle={!isExpanded ? t('PORT_MANAGEMENT.TITLE') : ''}
                                isActive={location.pathname.startsWith(appRoutes.PortManagement)}
                            >
                                {t('PORT_MANAGEMENT.TITLE')}
                            </NavLink>
                        )}
                        {this.renderMarketOverviewMenu(permissions, isExpanded)}
                        {permissions?.GetEnergyManagementReports
                            && ConfigService.featureToggles.showEnergyManagement && (
                                <NavSubMenu
                                    toggleCollapseEvent="NavSubMenuCollapse"
                                    icon="energy-management"
                                    rootPath={appRoutes.EnergyManagement.Root}
                                    title={t('NAV_MENU.ENERGY_MANAGEMENT')}
                                    tooltipTitle={!isExpanded ? t('NAV_MENU.ENERGY_MANAGEMENT') : ''}
                                >
                                    <NavLink
                                        onClick={this.removeActiveLink}
                                        to={appRoutes.EnergyManagement.Reports}
                                        icon="file-chart"
                                        tooltipTitle={!isExpanded ? t('NAV_MENU.REPORTS') : ''}
                                        isActive={location.pathname.startsWith(appRoutes.EnergyManagement.Reports)}
                                    >
                                        {t('NAV_MENU.REPORTS')}
                                    </NavLink>
                                    <NavLink
                                        onClick={this.removeActiveLink}
                                        to={appRoutes.EnergyManagement.KPI}
                                        icon="vessel-benchmark"
                                        tooltipTitle={!isExpanded ? t('NAV_MENU.KPI') : ''}
                                        isActive={location.pathname.startsWith(appRoutes.EnergyManagement.KPI)}
                                    >
                                        {t('NAV_MENU.KPI')}
                                    </NavLink>
                                </NavSubMenu>
                            )}
                        {permissions?.MenuEmailSupport && ConfigService.featureToggles.showContactForm && (
                            <NavLink
                                onClick={this.goToContactInquiry}
                                icon="message-dots"
                                isActive={activeLinkName === 'contact-inquiry'}
                                tooltipTitle={!isExpanded ? t('NAV_MENU.CONTACT_US') : ''}
                            >
                                {t('NAV_MENU.CONTACT_US')}
                            </NavLink>
                        )}
                        {permissions?.MenuCargoInquiry && (
                            <NavLink
                                onClick={this.goToCargoInquiry}
                                icon="cargo-inquiry"
                                isActive={activeLinkName === 'cargo-inquiry'}
                                tooltipTitle={!isExpanded ? t('NAV_MENU.CARGO_INQUIRY') : ''}
                            >
                                {t('NAV_MENU.CARGO_INQUIRY')}
                            </NavLink>
                        )}
                        <div className="sten-nav-menu__bottom-button-container">
                            {this.renderBottomLinks()}
                        </div>
                    </ScrollArea>
                </div>
                <div className="sten-nav-menu__container sten-nav-menu__container--bottom">
                    {this.renderBottomLinks()}
                </div>
            </nav>
        );
    }
}

NavMenu.propTypes = {
    activeLinkName: PropTypes.string.isRequired,
    expand: PropTypes.func.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    router: TRouter.isRequired,
    setActiveLink: PropTypes.func.isRequired,
    user: PropTypes.objectOf(PropTypes.any).isRequired
};

function mapStateToProps(state) {
    return {
        permissions: state.userReducer.permissions,
        user: state.userReducer.user
    };
}

export default connect(mapStateToProps, null)(withRouter(NavMenu));
