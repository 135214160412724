import Excel from 'exceljs';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import {
    tableHeaderStyle as basicTableHeaderStyle,
    tableCellStyle,
    headingTitleStyle
} from 'utils/helpers/xlsx-styles';
import { addWorksheet, triggerXLSXDownload, formatXLSXCellValue } from 'utils/helpers/xlsx-helper';
import { generateXLSXHeaderLogos } from '../../../reports-helpers';
import { getObjectProp } from 'utils/helpers/info-helper';
/* constants */
import { colWidths, fuelColWidth } from '../quarterly-report-constants';

const tr = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.${key}`);
const tq = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.QUARTERLY_REPORT.${key}`);
const tu = (key) => translate(`UNITS.${key}`);

const customColors = {
    segmentHeader: 'FF9ED2FA',
    periodHeader: 'FFD5ECFD'
};

const tableHeaderStyle = {
    ...basicTableHeaderStyle,
    fill: {
        ...basicTableHeaderStyle.fill,
        fgColor: { argb: customColors.periodHeader }
    }
};

const centeredTableHeaderStyle = {
    ...tableHeaderStyle,
    alignment: {
        horizontal: 'center',
        wrapText: true,
        vertical: 'middle'
    }
};

const rightAlignedTableHeaderStyle = {
    ...tableHeaderStyle,
    alignment: {
        horizontal: 'right',
        wrapText: true,
        vertical: 'middle'
    }
};

const rightAlignedTableCellStyle = {
    ...tableCellStyle,
    alignment: {
        horizontal: 'right',
        wrapText: true,
        vertical: 'middle'
    }
};

const summaryCellStyle = {
    ...tableCellStyle,
    font: {
        ...tableCellStyle.font,
        bold: true
    }
};

const rightAlignedSummaryCellStyle = {
    ...rightAlignedTableCellStyle,
    font: {
        ...rightAlignedTableCellStyle.font,
        bold: true
    }
};

const segmentHeaderStyle = {
    ...tableHeaderStyle,
    fill: {
        ...tableHeaderStyle.fill,
        fgColor: { argb: customColors.segmentHeader }
    }
};

const periodHeaderStyle = {
    ...tableHeaderStyle,
    fill: {
        ...tableHeaderStyle.fill,
        fgColor: { argb: customColors.periodHeader }
    }
};

const formatTableCell = (val, props = {}, style = null, rowField, colSpan = 1, numberFmt) => {
    const { value, numFmt } = formatXLSXCellValue(val, props);
    return {
        value,
        props: {
            ...rightAlignedTableCellStyle,
            ...style,
            numFmt: numberFmt || numFmt
        },
        colSpan
    };
};

const renderHeaderRow = (label, headerColSpan, style) => ({
    values: [
        { value: label, props: style, colSpan: headerColSpan + 1 },
        ...new Array(headerColSpan).fill(null)
    ]
});

const renderDataRow = (label, data, key, columns, numFmt) => ({
    values: [
        { value: label, props: summaryCellStyle },
        ...columns.map(col => {
            const val = getObjectProp(data[key], col.key);
            return formatTableCell(val, col, rightAlignedSummaryCellStyle, key, 1, numFmt);
        })
    ]
});

const getReportTableHeader = (fuelTypes) => ([
    {
        values: [
            { value: tq('VALUE'), rowSpan: 2, props: tableHeaderStyle },
            { value: tq('BALLAST_LADEN_RATIO'), colSpan: 5, props: centeredTableHeaderStyle },
            null,
            null,
            null,
            null,
            { value: tq('OFF_HIRE_DAYS'), rowSpan: 2, props: centeredTableHeaderStyle },
            { value: tq('USED_BUNKER'), colSpan: fuelTypes.length + 1, props: centeredTableHeaderStyle },
            ...new Array(fuelTypes.length).fill(null),
            { value: tq('EMISSIONS'), colSpan: 4, props: centeredTableHeaderStyle }
        ]
    },
    {
        values: [
            null,
            { value: tq('LADEN_PERCENTAGE_BASED_ON_DISTANCE'), props: centeredTableHeaderStyle },
            { value: tq('BALLAST_PERCENTAGE_BASED_ON_DISTANCE'), props: centeredTableHeaderStyle },
            { value: tq('LADEN_PERCENTAGE_BASED_ON_TIME'), props: centeredTableHeaderStyle },
            { value: tq('BALLAST_PERCENTAGE_BASED_ON_TIME'), props: centeredTableHeaderStyle },
            { value: tq('DAYS_IN_PORT_PERCENTAGE'), props: centeredTableHeaderStyle },
            null,
            ...fuelTypes.map(fuelType => ({
                value: `${fuelType.Name} (${translate('UNITS.CONSUMPTION')})`,
                props: rightAlignedTableHeaderStyle
            })),
            { value: tq('TOTAL'), props: rightAlignedTableHeaderStyle },
            { value: `CO2 (${tu('EMISSIONS')})`, props: centeredTableHeaderStyle },
            { value: `SOx (${tu('EMISSIONS')})`, props: centeredTableHeaderStyle },
            { value: `NOx (${tu('EMISSIONS')})`, props: centeredTableHeaderStyle },
            { value: `PM (${tu('EMISSIONS')})`, props: centeredTableHeaderStyle }
        ]
    }
]);

const renderSection = (data, qr, label, columns, headerColSpan) => ([
    renderHeaderRow(label, headerColSpan, segmentHeaderStyle),

    renderHeaderRow(`${tq('QUARTER')} ${qr.quarter.Name}`, headerColSpan, periodHeaderStyle),
    renderDataRow(qr.year, data, 'QuarterSelected', columns),
    renderDataRow(qr.year - 1, data, 'QuarterPrevious', columns),
    renderDataRow(tq('DELTA'), data, 'QuarterDelta', columns),
    renderDataRow(tq('DELTA_PERCENTAGE'), data, 'QuarterDeltaPercentage', columns),

    renderHeaderRow(tq('YEAR_TO_DATE'), headerColSpan, periodHeaderStyle),
    renderDataRow(qr.year, data, 'YearToDateSelected', columns),
    renderDataRow(qr.year - 1, data, 'YearToDatePrevious', columns),
    renderDataRow(tq('DELTA'), data, 'YearToDateDelta', columns),
    renderDataRow(tq('DELTA_PERCENTAGE'), data, 'YearToDateDeltaPercentage', columns),

    renderHeaderRow(`${tq('ROLLING')} ${qr.quarter.Name}`, headerColSpan, periodHeaderStyle),
    renderDataRow(qr.year, data, 'RollingSelected', columns),
    renderDataRow(qr.year - 1, data, 'RollingPrevious', columns),
    renderDataRow(tq('DELTA'), data, 'RollingDelta', columns),
    renderDataRow(tq('DELTA_PERCENTAGE'), data, 'RollingDeltaPercentage', columns)
]);

export default function* generateQuarterlyXLSX(data) {
    const { tableData, fileName, selectedQuarter } = data;
    const { parsedData, fuelTypes, columns: tableDataColumns } = tableData;

    const workbook = new Excel.Workbook();

    const usedBunkerCols = fuelTypes.length;
    const headerColSpan = 11 + fuelTypes.length;

    const columns = [
        { width: colWidths.Value.xls },
        { width: colWidths.LadenPercentageBasedOnDistance.xls },
        { width: colWidths.BallastPercentageBasedOnDistance.xls },
        { width: colWidths.LadenPercentageBasedOnTime.xls },
        { width: colWidths.BallastPercentageBasedOnTime.xls },
        { width: colWidths.DaysInPortPercentage.xls },
        { width: colWidths.OffHireDays.xls },
        ...new Array(usedBunkerCols).fill({ width: fuelColWidth.xls }),
        { width: colWidths.BunkerTotal.xls },
        { width: colWidths.CO2.xls },
        { width: colWidths.SOx.xls },
        { width: colWidths.NOx.xls },
        { width: colWidths.PM.xls }
    ];

    const sheetConfig = {
        name: 'Quarterly Report',
        props: {
            properties: { showGridLines: false },
            views: [{ showGridLines: false }],
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                margins: { left: 0.4, right: 0.4, top: 0.2, bottom: 0.2, header: 0, footer: 0 }
            }
        },
        columns,
        rows: [
            ...generateXLSXHeaderLogos(null, null, 0.0001),
            {
                values: [{
                    value: tr('QUARTERLY_REPORT'),
                    props: headingTitleStyle
                }]
            },
            null,
            {
                values: [{
                    value: `${selectedQuarter.year} - ${selectedQuarter.quarter.Name}`
                }]
            },
            null,
            ...getReportTableHeader(fuelTypes),
            ...parsedData.Segments.flatMap(sg => renderSection(
                sg.Summary, selectedQuarter, sg.Name, tableDataColumns, headerColSpan
            )),
            ...renderSection(parsedData.Summary, selectedQuarter, tq('TOTAL'), tableDataColumns, headerColSpan)
        ]
    };

    addWorksheet(workbook, sheetConfig);

    const xls64 = yield workbook.xlsx.writeBuffer({ base64: true });
    return triggerXLSXDownload(fileName)(xls64);
}
