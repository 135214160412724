import { ActionTypes } from './berth-info-actions';

const emptyArray = [];

const initialState = {
    isMounted: false,
    berth: null,
    comments: emptyArray
};

export default function berthInfoReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.BERTH_INFO_SET_RESOURCES: {
        return {
            ...state,
            isMounted: true,
            berth: action.berth,
            comments: action.comments || emptyArray
        };
    }
    case ActionTypes.BERTH_INFO_UPDATED: {
        if (state.isMounted && action.berth) {
            return {
                ...state,
                berth: action.berth
            };
        }
        return state;
    }
    case ActionTypes.BERTH_INFO_SET_COMMENTS: {
        return {
            ...state,
            comments: action.comments || emptyArray
        };
    }
    case ActionTypes.BERTH_INFO_RESET: {
        return initialState;
    }
    default:
        return state;
    }
}
