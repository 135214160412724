import moment from 'moment';
import { mapArrayByValue } from 'utils/helpers/array-helper';
/* utils */
import { hasValue } from 'utils/helpers/info-helper';

export const customDateValidation = {
    rule: (value) => value !== 'Invalid Date',
    hint: () => 'Date is invalid'
};

export const customDuplicateEmailValidation = {
    rule: (value, params, components) => {
        const { componentList } = params;
        if (componentList?.length) {
            const emails = mapArrayByValue(value);
            return !componentList.some((componentName) => {
                const componentEmails = components[componentName]
                    ? components[componentName].state.value
                    : [];
                return componentEmails.some(x => emails[x]);
            });
        }
        return true;
    },
    hint: () => 'Shouldn\'t have the same email more than once in routing emails'
};

const allOrNothingFields = ['Cost.VesselDailyCosts', 'Cost.BunkerCostHFO', 'Cost.BunkerCostHFOLS'];
const allOrNothingHint = 'Either "Vessel costs", "High Sulphur fuel price" '
    + 'and "Low Sulphur fuel price" are entered or none';

export const commonValidations = {
    CostOptimization: {
        numeric: { min: 0 },
        allOrNothing: {
            rule: (value, params, components) => {
                if (components?.OptimizationType?.state?.value?.Name === 'Cost') {
                    return hasValue(value);
                }
                const validFields = allOrNothingFields.filter(field => hasValue(components[field]?.state?.value));
                return !validFields.length || validFields.length === allOrNothingFields.length;
            },
            hint: (value, params, components) => {
                if (components?.OptimizationType?.state?.value?.Name === 'Cost') {
                    return 'Required';
                }
                return allOrNothingHint;
            }
        }
    },
    DateTimeRequired: { required: true, custom: customDateValidation },
    DateTimeLimitedRequired: {
        required: true,
        custom: customDateValidation,
        dateLimits: {
            min: moment.utc('1990-01-01T00:00:00'),
            utc: true
        }
    },
    DateOffset: { timeSpan: { min: -13 * 60, max: 13 * 60 } },
    DateOffsetRequired: { required: true, timeSpan: { min: -13 * 60, max: 13 * 60 } }
};

export const validations = {
    Vessel: { required: true },
    Voyage: { required: true },
    Source: { required: true },
    VesselCondition: { required: true },
    OptimizationType: { required: true },
    VesselDailyCosts: commonValidations.CostOptimization,
    BunkerCostHFO: commonValidations.CostOptimization,
    BunkerCostHFOLS: commonValidations.CostOptimization,
    RouteCalculationType: { required: true },
    Remarks: { maxLength: 2000 },
    InstructedSpeed: {
        required: true,
        maxLength: (components) => (components.Source?.state?.value?.Name === 'AutomaticWeatherApi' ? 5 : 1),
        numberList: { min: 0, max: 30, decimals: 2 }
    },
    MinimumSpeed: {
        required: true,
        numeric: {
            min: (components) => {
                const range = 3;
                const max = components['Speed.MaximumSpeed']
                    && parseFloat(components['Speed.MaximumSpeed'].state.value);
                return !isNaN(max) && max - range >= 0 && max <= 30 ? max - range : 0;
            },
            max: 30,
            maxProp: 'Speed.MaximumSpeed',
            decimals: 2
        }
    },
    MaximumSpeed: {
        required: true,
        numeric: {
            min: 0,
            max: (components) => {
                const range = 3;
                const min = components['Speed.MinimumSpeed']
                    && parseFloat(components['Speed.MinimumSpeed'].state.value);
                return !isNaN(min) && min >= 0 && min + range <= 30 ? min + range : 30;
            },
            minProp: 'Speed.MinimumSpeed',
            decimals: 2
        }
    },
    RequestType: { required: true },
    Draft: { required: true, numeric: { min: 0, max: 30 } },
    Eta: {
        ...commonValidations.DateTimeRequired,
        dateLimits: {
            ...commonValidations.DateTimeLimitedRequired.dateLimits,
            minProp: 'Etd'
        }
    },
    EtaOffset: commonValidations.DateOffsetRequired,
    Etd: commonValidations.DateTimeRequired,
    EtdOffset: commonValidations.DateOffsetRequired,
    PortOfDeparture: { required: true },
    PortOfDestination: { required: true },
    Waypoints: {
        Point: { required: true }
    },
    RoutingEmailsVessel: {
        required: true,
        emailList: true,
        emailDuplicates: {
            ...customDuplicateEmailValidation,
            componentList: ['RoutingEmailsOperations', 'RoutingEmailsOther']
        }
    },
    RoutingEmailsOperations: {
        required: true,
        emailList: true,
        emailDuplicates: {
            ...customDuplicateEmailValidation,
            componentList: ['RoutingEmailsVessel', 'RoutingEmailsOther']
        }
    },
    RoutingEmailsOther: {
        emailList: true,
        emailDuplicates: {
            ...customDuplicateEmailValidation,
            componentList: ['RoutingEmailsVessel', 'RoutingEmailsOperations']
        }
    },
    PvarEmails: { required: true, emailList: true },
    PerformanceEmails: { required: true, emailList: true }
};
