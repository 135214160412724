import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { formatNumber } from 'utils/helpers/info-helper';
/* actions */
import {
    getInspections,
    getOptions,
    exportToXlsx,
    exportToPdf,
    resetAll,
    setSelectedInspections
} from './inspections-actions';
/* selectors */
import { areFiltersChanged, calcObservationAverages } from './inspections-selectors';
/* components */
import IconButton from 'components/icon-button/icon-button';
import InspectionsFilters from './filters/inspections-filters';
import InspectionsTable from './table/inspections-table';
import InspectionsForm from './form/inspections-form';
/* styles */
import './inspections.scss';

class Inspections extends React.PureComponent {
    state = {
        areFiltersVisible: true
    };

    componentDidMount() {
        this.props.getInspections();
        this.props.getOptions();
    }

    componentWillUnmount() {
        this.props.resetAll();
    }

    toggleFilters = () => {
        this.setState({ areFiltersVisible: !this.state.areFiltersVisible });
    };

    handleAddInspectionClick = () => this.props.router.navigate(`${appRoutes.Vessel.Inspections}/new`);

    render() {
        const hasFooter = Object.keys(this.props.selectedInspections).length > 0;
        let contentClassName = 'sten-content';
        if (hasFooter) {
            contentClassName += ' sten-content--has-footer';
        }

        const obs = this.props.observationAverages;
        const sireText = obs.sireInspectionsTotalCount ? t('INSPECTIONS.OBSERVATION_STATS', {
            avg: typeof obs.sireInspectionsAvg === 'number'
                ? formatNumber(obs.sireInspectionsAvg)
                : t('GLOBAL.UNKNOWN'),
            count: obs.sireInspectionsTotalCount,
            type: 'SIRE'
        }) : '';
        const cdiText = obs.cdiInspectionsTotalCount ? t('INSPECTIONS.OBSERVATION_STATS', {
            avg: typeof obs.cdiInspectionsAvg === 'number'
                ? formatNumber(obs.cdiInspectionsAvg)
                : t('GLOBAL.UNKNOWN'),
            count: obs.cdiInspectionsTotalCount,
            type: 'CDI'
        }) : '';
        return (
            <div className="sten-inspections">
                <InspectionsForm />
                <div className={contentClassName}>
                    <div className="sten-content__header flex-row">
                        <div className="flex-grow">
                            <h1 className="sten-content__title">{t('INSPECTIONS.TITLE')}</h1>
                        </div>
                        <div className="flex-shrink">
                            <IconButton
                                isLink
                                changeIndicator={!this.state.areFiltersVisible && this.props.areFiltersChanged}
                                isActive={this.state.areFiltersVisible}
                                icon="icon-filter-outline"
                                onClick={this.toggleFilters}
                            />
                        </div>
                        <div className="flex-shrink">
                            <button className="btn btn--primary" onClick={this.handleAddInspectionClick}>
                                {t('INSPECTIONS.ADD_INSPECTION')}
                            </button>
                        </div>
                    </div>
                    <div className="sten-content__body">
                        {this.state.areFiltersVisible && (
                            <div className="sten-inspections-content__top">
                                <InspectionsFilters />
                            </div>
                        )}
                        <div className="sten-inspections-content__bottom">
                            <InspectionsTable />
                        </div>
                    </div>
                    {hasFooter && (
                        <footer className="sten-content__footer flex-justify-end">
                            {(cdiText || sireText) && (
                                <div className="flex-xs-grow flex-shrink">
                                    <div className="flex-row flex-center">
                                        <div className="flex-shrink">
                                            <div className="flex-row flex-row--md flex-center">
                                                <div className="flex-shrink text-secondary">
                                                    {t('INSPECTIONS.AVERAGE_OBSERVATION')}
                                                </div>
                                                <div className="flex-shrink">
                                                    {cdiText ? <div>{cdiText}</div> : null}
                                                    {sireText ? <div>{sireText}</div> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex-grow">
                                            <button
                                                className="btn btn--secondary"
                                                onClick={this.props.clearSelectedInspections}
                                            >
                                                <span className="btn__icon icon icon-close" />
                                                {t('INSPECTIONS.CLEAR_SELECTION')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className="flex-shrink">
                                <div className="flex-row">
                                    <div className="flex-sm-shrink col-xs-12">
                                        <button
                                            className="btn btn--primary"
                                            onClick={this.props.exportToPdf}
                                            disabled={Object.keys(this.props.selectedInspections).length === 0}
                                        >
                                            {t('INSPECTIONS.EXPORT_PDF')}
                                        </button>
                                    </div>
                                    <div className="flex-sm-shrink col-xs-12">
                                        <button
                                            className="btn btn--primary"
                                            onClick={this.props.exportToXlsx}
                                            disabled={Object.keys(this.props.selectedInspections).length === 0}
                                        >
                                            {t('INSPECTIONS.EXPORT_XLSX')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </footer>
                    )}
                </div>
            </div>
        );
    }
}

Inspections.propTypes = {
    areFiltersChanged: PropTypes.bool.isRequired,
    clearSelectedInspections: PropTypes.func.isRequired,
    exportToPdf: PropTypes.func.isRequired,
    exportToXlsx: PropTypes.func.isRequired,
    getInspections: PropTypes.func.isRequired,
    getOptions: PropTypes.func.isRequired,
    observationAverages: PropTypes.objectOf(PropTypes.any).isRequired,
    resetAll: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    selectedInspections: PropTypes.objectOf(PropTypes.any).isRequired
};

function mapStateToProps(state) {
    return {
        observationAverages: calcObservationAverages(state),
        areFiltersChanged: areFiltersChanged(state),
        selectedInspections: state.inspectionsReducer.selectedInspections
    };
}

function mapDispatchToProps(dispatch) {
    return {
        clearSelectedInspections: () => setSelectedInspections(dispatch, {}),
        exportToXlsx: () => exportToXlsx(dispatch),
        exportToPdf: () => exportToPdf(dispatch),
        getOptions: () => getOptions(dispatch),
        getInspections: () => getInspections(dispatch),
        resetAll: () => resetAll(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Inspections));
