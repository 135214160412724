import React from 'react';
import PropTypes from 'prop-types';
/* types */
import { TFilters, TOptions } from '../../emission-estimator-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* components */
import Input from 'components/input/input';
import NumberInput from 'components/number-input/number-input';
import Modal from 'components/modal/modal';
import Select from 'components/select/select';
import Validation from 'components/validation/validation';
import VesselSelect from 'components/vessel-select/vessel-select';
/* styles */
import './estimator-filters.scss';

const additionalFiltersValidationFields = ['selectedEcaBunker', 'selectedNonEcaBunker'];

const optionRenderer = (option) => (
    <React.Fragment>
        {option.Name}
        {option.ECA && <span className="icon sten-icon-eco" />}
    </React.Fragment>
);

export class EstimatorFilters extends React.PureComponent {
    state = {
        isOpen: false,
        selectedEcaBunker: null,
        selectedNonEcaBunker: null,
        defaultWeatherFactor: 0,
        selectedEuEtsVesselType: null
    }

    toggleFilterModal = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    handleAdditionalFilterChange = (field, value) => {
        this.setState({
            ...this.state,
            [field]: value
        });
    }

    handleOpen = () => {
        const propValues = {
            selectedEcaBunker: this.props.filters.selectedEcaBunker,
            selectedNonEcaBunker: this.props.filters.selectedNonEcaBunker,
            defaultWeatherFactor: this.props.filters.defaultWeatherFactor,
            selectedEuEtsVesselType: this.props.filters.selectedEuEtsVesselType
        };
        this.setState({ ...propValues });
        this.toggleFilterModal();
    }

    getErrors = (context) => {
        return Object.keys(context.errors).filter(error => additionalFiltersValidationFields.includes(error)).length;
    };

    handleConfirm = (context) => {
        const errors = context.validateAll();
        const hasErrors = additionalFiltersValidationFields.some(field => !!errors[field]);
        if (!hasErrors) {
            this.props.handleChange('selectedEcaBunker', this.state.selectedEcaBunker);
            this.props.handleChange('selectedNonEcaBunker', this.state.selectedNonEcaBunker);
            this.props.handleChange('defaultWeatherFactor', this.state.defaultWeatherFactor);
            this.props.handleChange('selectedEuEtsVesselType', this.state.selectedEuEtsVesselType);
            this.toggleFilterModal();
        }
    };

    render() {
        return (
            <React.Fragment>
                <Modal.Default
                    modalSize="xs"
                    isOpen={this.state.isOpen}
                    onRequestClose={this.toggleFilterModal}
                    className="sten-modal--with-footer"
                >
                    <div className="sten-modal__header flex flex-center text-uppercase">
                        <div className="sten-modal__title flex-grow">{t('CALCULATOR_FILTERS.ADDITIONAL_FILTERS')}</div>
                        <div className="flex-shrink">
                            <button
                                className="btn-link icon icon-close"
                                onClick={this.toggleFilterModal}
                            />
                        </div>
                    </div>
                    <div className="sten-modal__body">
                        <div className="sten-content__section">
                            <div className="form-row">
                                <label className="label">{t('CALCULATOR_FILTERS.ECA_BUNKER')}</label>
                                <Validation.Wrapper hintsOnHover validations={{ required: true }}>
                                    <Select
                                        optionRenderer={optionRenderer}
                                        collapseDown={false}
                                        fixedListWidth
                                        value={this.state.selectedEcaBunker}
                                        onChange={this.handleAdditionalFilterChange.bind(this, 'selectedEcaBunker')}
                                        options={this.props.options.bunkerOptions}
                                        valueKey="Id"
                                        labelKey="Name"
                                        name="selectedEcaBunker"
                                        placeholder={t('CALCULATOR_FILTERS.BUNKER_PLACEHOLDER')}
                                    />
                                </Validation.Wrapper>
                            </div>
                            <div className="form-row">
                                <label className="label">{t('CALCULATOR_FILTERS.NON_ECA_BUNKER')}</label>
                                <Validation.Wrapper hintsOnHover validations={{ required: true }}>
                                    <Select
                                        optionRenderer={optionRenderer}
                                        collapseDown={false}
                                        fixedListWidth
                                        value={this.state.selectedNonEcaBunker}
                                        onChange={this.handleAdditionalFilterChange.bind(this, 'selectedNonEcaBunker')}
                                        options={this.props.options.bunkerOptions}
                                        valueKey="Id"
                                        labelKey="Name"
                                        name="selectedNonEcaBunker"
                                        placeholder={t('CALCULATOR_FILTERS.BUNKER_PLACEHOLDER')}
                                    />
                                </Validation.Wrapper>
                            </div>
                            <div className="form-row">
                                <label className="label">{t('CALCULATOR_FILTERS.WEATHER_FACTOR')}</label>
                                <NumberInput
                                    value={this.state.defaultWeatherFactor}
                                    unit="%"
                                    step={1}
                                    decimals={0}
                                    min={0}
                                    max={100}
                                    onChange={this.handleAdditionalFilterChange.bind(this, 'defaultWeatherFactor')}
                                />
                            </div>
                            <div className="form-row">
                                <label className="label">{t('CALCULATOR_FILTERS.EU_ETS_VESSEL_TYPE')}</label>
                                <Validation.Wrapper hintsOnHover validations={{ required: true }}>
                                    <Select
                                        collapseDown={false}
                                        fixedListWidth
                                        value={this.state.selectedEuEtsVesselType}
                                        onChange={
                                            this.handleAdditionalFilterChange.bind(this, 'selectedEuEtsVesselType')}
                                        options={this.props.euEtsVesselTypes}
                                        valueKey="Name"
                                        labelKey="Name"
                                        name="selectedEuEtsVesselType"
                                    />
                                </Validation.Wrapper>
                            </div>
                        </div>
                    </div>
                    <footer className="sten-modal__footer">
                        <div className="flex-grow flex-row">
                            <div className="flex-grow text-right">
                                <button
                                    type="button"
                                    onClick={this.toggleFilterModal}
                                    className="btn btn--secondary"
                                >
                                    {t('GLOBAL.CANCEL')}
                                </button>
                            </div>
                            <div className="flex-shrink">
                                <Validation.Button
                                    className="btn btn--primary"
                                    type="button"
                                    onClick={this.handleConfirm}
                                    getErrors={this.getErrors}
                                    title={t('CALCULATOR_FILTERS.ADDITIONAL_FILTERS')}
                                >
                                    {t('GLOBAL.SAVE')}
                                </Validation.Button>
                            </div>
                        </div>
                    </footer>
                </Modal.Default>
                {!this.state.isOpen && (
                    <div className="estimator-filters__bunker-values">
                        <Validation.Wrapper validations={{ required: true }}>
                            <Validation.Value
                                value={this.props.filters.selectedEcaBunker}
                                name="selectedEcaBunker"
                            />
                        </Validation.Wrapper>
                        <Validation.Wrapper validations={{ required: true }}>
                            <Validation.Value
                                value={this.props.filters.selectedNonEcaBunker}
                                name="selectedNonEcaBunker"
                            />
                        </Validation.Wrapper>
                    </div>
                )}
                <div className="sten-content__section sten-estimator__filters">
                    <div className="flex-row flex--wrap flex-end">
                        <div className="col-lg-5 col-sm-8 col-xs-12">
                            <label className="label">{t('EMISSION_ESTIMATOR.VESSEL')}</label>
                            <VesselSelect
                                placeholder={t('EMISSION_ESTIMATOR.SEARCH_VESSELS')}
                                title={t('EMISSION_ESTIMATOR.SEARCH_VESSELS_BY_NAME_OR_IMO')}
                                collapseDown={false}
                                clearable
                                fixedListWidth
                                value={this.props.filters.calculationVessel}
                                onChange={this.props.setCalculationVessel}
                            />
                        </div>
                        <div className="col-lg-5 col-sm-8 col-xs-12">
                            <label className="label">{t('EMISSION_ESTIMATOR.STARTING_LOCATION')}</label>
                            <Select
                                disabled={!this.props.filters.calculationVessel}
                                collapseDown={false}
                                fixedListWidth
                                value={this.props.filters.calculationVesselStartingPosition}
                                onChange={this.props.setVesselStartingPosition}
                                options={this.props.options.startingPositionOptions}
                                valueKey="value"
                                labelKey="label"
                            />
                        </div>
                        <div className="col-lg-4 col-sm-8 col-xs-12">
                            <label className="label">{t('EMISSION_ESTIMATOR.CANALS_ALLOWED')}</label>
                            <Select
                                collapseDown={false}
                                multiple
                                fixedListWidth
                                value={this.props.filters.selectedCanals}
                                onChange={this.props.handleChange.bind(this, 'selectedCanals')}
                                options={this.props.options.canalOptions}
                                valueKey="id"
                                labelKey="name"
                                placeholder={t('EMISSION_ESTIMATOR.CANALS_PLACEHOLDER')}
                            />
                        </div>
                        <div className="col-lg-4 col-sm-8 col-xs-12">
                            <label className="label">{t('EMISSION_ESTIMATOR.AREAS')}</label>
                            <Select
                                collapseDown={false}
                                multiple
                                fixedListWidth
                                value={this.props.filters.selectedAreas}
                                onChange={this.props.handleChange.bind(this, 'selectedAreas')}
                                options={this.props.options.areaOptions}
                                valueKey="id"
                                labelKey="name"
                                placeholder={t('EMISSION_ESTIMATOR.AREAS_PLACEHOLDER')}
                            />
                        </div>
                        <div className="col-lg-4 col-sm-8 col-xs-12">
                            <label className="label">{t('EMISSION_ESTIMATOR.DEFAULT_SPEED')}</label>
                            <Validation.Wrapper
                                hintsOnHover
                                validations={{
                                    required: true,
                                    numeric: { decimals: 2, min: 0, max: 30 }
                                }}
                            >
                                <Input
                                    name="defaultSpeed"
                                    value={this.props.filters.defaultSpeed}
                                    suffix={t('UNITS.SPEED')}
                                    onChange={this.props.handleChange.bind(this, 'defaultSpeed')}
                                />
                            </Validation.Wrapper>
                        </div>
                        <div className="sten-content__vertical-separator" />
                        <div className="col-lg-1 col-sm-7 col-xs-10">
                            <Validation.Button
                                onClick={this.handleOpen}
                                className="btn-link btn-link--inverted"
                                type="button"
                                getErrors={this.getErrors}
                                title={t('CALCULATOR_FILTERS.ADDITIONAL_FILTERS')}
                            >
                                <span className="btn__icon icon icon-additional-filters" />
                            </Validation.Button>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

EstimatorFilters.propTypes = {
    euEtsVesselTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    euEtsYearsAvailable: PropTypes.arrayOf(PropTypes.object).isRequired,
    filters: TFilters.isRequired,
    options: TOptions.isRequired,
    handleChange: PropTypes.func.isRequired,
    setCalculationVessel: PropTypes.func.isRequired,
    setVesselStartingPosition: PropTypes.func.isRequired
};

export default EstimatorFilters;
