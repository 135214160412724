export const ActionTypes = {
    ENERGY_MANAGEMENT_KPI_OVERVIEW_GET_BASIC_OVERVIEW: 'ENERGY_MANAGEMENT_KPI_OVERVIEW_GET_BASIC_OVERVIEW',
    ENERGY_MANAGEMENT_KPI_OVERVIEW_GET_ADVANCED_OVERVIEW: 'ENERGY_MANAGEMENT_KPI_OVERVIEW_GET_ADVANCED_OVERVIEW',
    ENERGY_MANAGEMENT_KPI_OVERVIEW_SET_BASIC_OVERVIEW: 'ENERGY_MANAGEMENT_KPI_OVERVIEW_SET_BASIC_OVERVIEW',
    ENERGY_MANAGEMENT_KPI_OVERVIEW_SET_ADVANCED_OVERVIEW: 'ENERGY_MANAGEMENT_KPI_OVERVIEW_SET_ADVANCED_OVERVIEW',
    ENERGY_MANAGEMENT_KPI_OVERVIEW_RESET_ALL: 'ENERGY_MANAGEMENT_KPI_OVERVIEW_RESET_ALL'
};

export function getBasicOverview(dispatch) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_KPI_OVERVIEW_GET_BASIC_OVERVIEW
    });
}

export function getAdvancedOverview(dispatch, segmentId, filters) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_KPI_OVERVIEW_GET_ADVANCED_OVERVIEW,
        segmentId,
        filters
    });
}

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_KPI_OVERVIEW_RESET_ALL
    });
}
