export const splitSegments = (segments) => {
    const typeMap = {};
    segments.forEach(segment => {
        if (!typeMap[segment.VesselSegmentsType]) {
            typeMap[segment.VesselSegmentsType] = { options: [], name: segment.VesselSegmentsType };
        }
        typeMap[segment.VesselSegmentsType].options.push(segment);
    });
    let options = [];
    const sections = Object.keys(typeMap).sort((a, b) => (b === 'Other' ? -1 : 0)).map(key => {
        options = [...options, ...typeMap[key].options];
        return typeMap[key];
    });
    return { sections, options };
};
