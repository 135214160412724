export default {
    TotalData: {
        BallastKPI: 115.42662434652722,
        PreviousBallastKPI: 118.10890816467023,
        LadenKPI: 133.44440884331939,
        PreviousLadenKPI: 131.94186515946708,
        WeightedCargoLaden: 45408.603741392682,
        PreviousWeightedCargoLaden: 41882.179343668213,
        EnergyEfficiency: 20.0,
        PreviousEnergyEfficiency: 21.3294413037123,
        PreviousAER: 3.42870382091,
        AER: 2.917773,
        BallastKPIRatio: -2.2710258352429236,
        LadenKPIRatio: 1.1387922112790392,
        WeightedCargoRatio: 8.4198684332734075,
        EnergyEfficiencyRatio: -6.647206519,
        AERRatio: -17.870608236443472
    },
    MonthlyDataList: [
        {
            Date: '2019-10-01T00:00:00Z',
            Cargo: null,
            BallastKPI: 101.73892329680798,
            LadenKPI: 131.13166006164687
        },
        {
            Date: '2019-11-01T00:00:00Z',
            Cargo: 49.833,
            BallastKPI: null,
            LadenKPI: 129.63904422979155
        },
        {
            Date: '2019-12-01T00:00:00Z',
            Cargo: 46.5575,
            BallastKPI: 122.25638599810786,
            LadenKPI: 135.90872698158526
        },
        {
            Date: '2020-01-01T00:00:00Z',
            Cargo: 39.852333333333327,
            BallastKPI: 133.34910122989595,
            LadenKPI: 119.45373467112596
        },
        {
            Date: '2020-02-01T00:00:00Z',
            Cargo: 39.852333333333327,
            BallastKPI: 126.26924336717983,
            LadenKPI: 130.34139724169117
        },
        {
            Date: '2020-03-01T00:00:00Z',
            Cargo: 47.218250000000005,
            BallastKPI: 123.27586206896552,
            LadenKPI: 136.23464633629817
        },
        {
            Date: '2020-04-01T00:00:00Z',
            Cargo: 47.218250000000005,
            BallastKPI: 109.00000000000001,
            LadenKPI: 142.08333333333331
        },
        {
            Date: '2020-05-01T00:00:00Z',
            Cargo: 47.218250000000005,
            BallastKPI: 124.17639429312582,
            LadenKPI: 125.28135048231513
        },
        {
            Date: '2020-06-01T00:00:00Z',
            Cargo: 47.218250000000005,
            BallastKPI: 112.48253376804846,
            LadenKPI: null
        },
        {
            Date: '2020-07-01T00:00:00Z',
            Cargo: 47.218250000000005,
            BallastKPI: null,
            LadenKPI: null
        },
        {
            Date: '2020-08-01T00:00:00Z',
            Cargo: 47.218250000000005,
            BallastKPI: null,
            LadenKPI: null
        },
        {
            Date: '2020-09-01T00:00:00Z',
            Cargo: 47.218250000000005,
            BallastKPI: null,
            LadenKPI: null
        },
        {
            Date: '2020-10-01T00:00:00Z',
            Cargo: 47.218250000000005,
            BallastKPI: null,
            LadenKPI: null
        },
        {
            Date: '2020-11-01T00:00:00Z',
            Cargo: 47.218250000000005,
            BallastKPI: 161.32075471698116,
            LadenKPI: null
        },
        {
            Date: '2020-12-01T00:00:00Z',
            Cargo: 46.346666666666671,
            BallastKPI: 110.29905645290262,
            LadenKPI: null
        },
        {
            Date: '2021-01-01T00:00:00Z',
            Cargo: 44.946333333333335,
            BallastKPI: 113.82252559726963,
            LadenKPI: 144.51726745651624
        },
        {
            Date: '2021-02-01T00:00:00Z',
            Cargo: 77.193666666666658,
            BallastKPI: 114.31391905231986,
            LadenKPI: 123.13117066290552
        },
        {
            Date: '2021-03-01T00:00:00Z',
            Cargo: 82.846750000000014,
            BallastKPI: 131.09090909090907,
            LadenKPI: 125.48123195380174
        },
        {
            Date: '2021-04-01T00:00:00Z',
            Cargo: 78.2365,
            BallastKPI: 124.53102453102451,
            LadenKPI: 136.6697559468644
        },
        {
            Date: '2021-05-01T00:00:00Z',
            Cargo: 70.5174,
            BallastKPI: 114.70432946145723,
            LadenKPI: 116.16481774960381
        },
        {
            Date: '2021-06-01T00:00:00Z',
            Cargo: 66.904,
            BallastKPI: 120.4728950403691,
            LadenKPI: 130.66346922462034
        },
        {
            Date: '2021-07-01T00:00:00Z',
            Cargo: 66.904,
            BallastKPI: 105.37439613526571,
            LadenKPI: 127.28318380492291
        },
        {
            Date: '2021-08-01T00:00:00Z',
            Cargo: 60.814285714285724,
            BallastKPI: null,
            LadenKPI: 169.22781406069919
        },
        {
            Date: '2021-09-01T00:00:00Z',
            Cargo: 63.20975,
            BallastKPI: 120.3883495145631,
            LadenKPI: 130.42071197411002
        }
    ],
    EnergyEfficiencyDataList: [
        {
            Date: '2012-01-01T00:00:00Z',
            Efficiency: 39.0,
            AER: 7.3601030912245135308278806809,
            Target: 4.5805994758856421427044796635,
            Segments: [

            ]
        },
        {
            Date: '2013-01-01T00:00:00Z',
            Efficiency: 29.329441303712356992273932348,
            AER: 6.848537073879952605332457173,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2014-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2015-01-01T00:00:00Z',
            Efficiency: 32.935969162300417602644371491,
            AER: 5.6307042652719382039630865197,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2016-01-01T00:00:00Z',
            Efficiency: 26.935969162300417602644371491,
            AER: 7.0887751423604466635274482533,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2017-01-01T00:00:00Z',
            Efficiency: 24.935969162300417602644371491,
            AER: 7.0056947944081112604594645736,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2018-01-01T00:00:00Z',
            Efficiency: 23.351461677543122382842121171,
            AER: 5.6307042652719382039630865197,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2019-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2020-01-01T00:00:00Z',
            Efficiency: 21.329441303712356992273932348,
            AER: 3.428703820910480548501453433,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2021-01-01T00:00:00Z',
            Efficiency: 20.0,
            AER: 2.917773830077569203942469408,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2022-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2023-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2024-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2025-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2026-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2027-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2028-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2029-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2030-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: 2.7483596855313852856226877981,
            Segments: [

            ]
        },
        {
            Date: '2031-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2032-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2033-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2034-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2035-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2036-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2037-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2038-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2039-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2040-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2041-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2042-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2043-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2044-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2045-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2046-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2047-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2048-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2049-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2050-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: 0.0,
            Segments: [

            ]
        }
    ]
};
