import { createSelector } from 'reselect';
/* utils */
import { convertFuelData } from 'components/fuel-data-table/fuel-data-table-helpers';
import { splitRoute180, generateMiddlePoints, mapLocationLonLat, mapLonLat } from 'components/ol/ol-helpers';
import createByIdSelector from 'utils/helpers/selector-helper';
import RouteHelper from 'utils/helpers/route-helper';
/* selectors */
import { getLatestActiveAlertsByIMO } from '../notifications/notifications-selectors';
import { getReportFuelTypes } from 'components/vessel-report/vessel-report-selectors';

const emptyArray = [];

function getCombinedPorts(itineraryPorts, ports, nextPoint) {
    const combinedPorts = [];
    if (itineraryPorts.length > 0) {
        itineraryPorts.forEach((itineraryPort) => {
            if (itineraryPort.Point && itineraryPort.Point.Location) {
                const existingPort = combinedPorts.find(el => el.Id === itineraryPort.Point.Id);
                if (existingPort) {
                    if (itineraryPort.Activities) {
                        if (existingPort.Activities) {
                            existingPort.Activities = existingPort.Activities.concat(itineraryPort.Activities);
                        } else {
                            existingPort.Activities = itineraryPort.Activities;
                        }
                    }
                } else {
                    combinedPorts.push({
                        ...itineraryPort.Point,
                        Activities: itineraryPort.Activities,
                        Ata: itineraryPort.Ata,
                        Atd: itineraryPort.Atd,
                        Eta: itineraryPort.Eta,
                        Etd: itineraryPort.Etd,
                        IsHistorical: itineraryPort.IsHistorical,
                        selectedInVoyage: true
                    });
                }
            }
        });
    }
    if (nextPoint && !combinedPorts.find(el => el.Id === nextPoint.Id)) {
        combinedPorts.push({ ...nextPoint, selectedInVoyage: true });
    }

    ports.forEach((port) => {
        if (!combinedPorts.find(el => el.Id === port.Id)) {
            combinedPorts.push(port);
        }
    });

    return combinedPorts;
}

const portsByIdSelector = createByIdSelector(
    state => state.homeReducer.ports,
    'Id'
);

export const getSelectedPort = createSelector(
    state => state.portInfoReducer.port,
    (port) => {
        if (port) {
            return {
                ...port,
                Location: port.Position,
                PortCode: port.Code
            };
        }
        return null;
    }
);

export const getFetchedPorts = createSelector(
    portsByIdSelector,
    state => state.homeReducer.ports,
    getSelectedPort,
    state => state.homeReducer.selectedVesselNextPoint,
    state => state.homeReducer.selectedVoyageItinerary,

    (portsById, ports, selectedPort, selectedVesselNextPoint, selectedVoyageItinerary) => {
        let portsWithSelected;

        if (selectedPort && !portsById[selectedPort.Id]) {
            portsWithSelected = [...ports, selectedPort];
        } else {
            portsWithSelected = ports;
        }
        if (selectedVoyageItinerary.length > 0 || selectedVesselNextPoint) {
            return getCombinedPorts(selectedVoyageItinerary, portsWithSelected, selectedVesselNextPoint);
        }
        return portsWithSelected;
    }
);

export const getAllVessels = createSelector(
    state => state.homeReducer.vessels,
    state => state.homeReducer.selectedVesselId,
    state => state.homeReducer.selectedVesselPosition,

    (vessels, selectedVesselId, selectedVesselPosition) => {
        if (selectedVesselId && selectedVesselPosition) {
            const selectedVesselIndex = vessels.findIndex(vessel => (selectedVesselId === vessel.IMO));
            if (selectedVesselIndex < 0) {
                return [...vessels, selectedVesselPosition];
            }
        }

        return vessels;
    }
);

export const getSelectedVesselPositionWithAlert = createSelector(
    state => state.homeReducer.selectedVesselNotifications,
    state => state.homeReducer.selectedVesselPosition,

    (selectedVesselNotifications, selectedVesselPosition) => {
        const vesselPosition = selectedVesselPosition;
        const alerts = selectedVesselNotifications.filter(n => n.Severity === 'Alert');

        if (vesselPosition) {
            if (alerts && alerts.length) {
                return {
                    ...vesselPosition,
                    LatestAlert: alerts[0]
                };
            }
            return vesselPosition;
        }
        return null;
    }
);

export const getVesselsWithAlerts = createSelector(
    state => getAllVessels(state),
    getLatestActiveAlertsByIMO,

    (vessels, alerts) => vessels.map(vessel =>
        ({
            ...vessel,
            LatestAlert: alerts[vessel.IMO] || null
        }))
);

export const getSelectedVoyageAisPoints = createSelector(
    state => state.homeReducer.selectedVoyageAisPoints,
    aisPoints => (
        aisPoints
            ? aisPoints.map(mapLocationLonLat)
            : emptyArray
    )
);

export const getHistoricalRoute = createSelector(
    state => state.homeReducer.selectedVoyageAisPoints,
    RouteHelper.extractRoutesFromAisPoints
);

export const getFutureRoute = createSelector(
    state => state.homeReducer.selectedVoyageRoutePoints,

    (routePoints) => {
        if (routePoints) {
            const futureRoute = routePoints.map(mapLonLat);
            return splitRoute180(generateMiddlePoints(futureRoute));
        }
        return emptyArray;
    }
);

const totalConsumptionIds = [1, 2, 3, 4, 12];

export const getParsedLatestReport = createSelector(
    state => state.homeReducer.latestVoyageReport,
    state => getReportFuelTypes(state, state.homeReducer.latestVoyageReport),
    (report, fuelTypes) => {
        if (report) {
            const parsedReport = { ...report };

            parsedReport.BunkerData = convertFuelData(report.BunkerData, fuelTypes.map);
            parsedReport.TotalConsumption = null;
            parsedReport.Consumptions.forEach((consumption) => {
                if (totalConsumptionIds.includes(consumption.Id)) {
                    consumption.FuelTypeQuantities.forEach((fuel) => {
                        if (!parsedReport.TotalConsumption) {
                            parsedReport.TotalConsumption = 0;
                        }
                        if (fuel.Quantity) {
                            parsedReport.TotalConsumption += fuel.Quantity;
                        }
                    });
                }
            });

            return parsedReport;
        }
        return report;
    }
);

export const getAllVoyageReportTypes = createSelector(
    state => state.vesselReportReducer.visibleTypesAndFields,
    (types) => {
        if (types && types.length && types.length > 0) {
            const optionAll = {
                Id: -1,
                Name: 'All Reports'
            };
            return [optionAll, ...types];
        }
        return types;
    }
);
