import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import fastDeepEqual from 'fast-deep-equal';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { setObjectProp, wktMaker } from 'utils/helpers/info-helper';
import { renderFormFields } from 'components/form-fields/form-fields-helper';
import ScrollArea from 'components/scroll-area/scroll-area';
import TimeHelper from 'utils/helpers/time-helper';
/* components */
import Accordion from '../../accordion/accordion';
import Validation from 'components/validation/validation';
/* actions */
import { terminalUpdated } from './terminal-info-actions';
/* services */
import TerminalsService from 'services/core-api/terminals-service';
/* constants */
import { Terminal, Restrictions, terminalFields, terminalFieldNames } from '../terminal-and-berth-constants';

export class TerminalForm extends React.PureComponent {
    state = {
        initialTerminal: null,
        terminal: null
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.isActive && prevProps.id !== this.props.id) {
            this.loadData();
        }
    }

    handleCancel = (terminalId) => {
        const add = typeof terminalId === 'number' ? { terminal: terminalId } : null;
        this.props.router.updateQueryParams({ remove: ['terminalUpdate', 'terminalAddPort'], add });
    };

    loadData = () => {
        if (this.props.id) {
            TerminalsService.getTerminalById(this.props.id).then(res => {
                this.setState({ initialTerminal: res, terminal: new Terminal(res) });
            });
        } else {
            this.setState({ initialTerminal: null, terminal: new Terminal({ PortId: this.props.portId }) });
        }
    };

    handleSubmit = () => {
        const { terminal } = this.state;
        TerminalsService.updateTerminal({
            ...terminal,
            Restrictions: fastDeepEqual(terminal.Restrictions, Restrictions)
                ? null
                : terminal.Restrictions,
            Longitude: terminal.Position ? terminal.Position[0] : null,
            Latitude: terminal.Position ? terminal.Position[1] : null,
            Position: wktMaker('point', terminal.Position)
        }).then(terminal => {
            if (terminal) {
                toast(t('TERMINAL.TERMINAL_SUCCESSFULLY_UPDATED'), { type: toast.TYPE.SUCCESS });
                this.props.onUpdate(terminal.TerminalId, terminal);
                this.handleCancel(terminal.TerminalId);
            }
        });
    };

    handleValueChange = (key, prop) => (value) => {
        const terminal = { ...this.state.terminal };
        setObjectProp(terminal, key, prop ? value[prop] : value);
        this.setState({ terminal });
    }

    renderContent = (fieldNames, inline = false) => renderFormFields(
        this.state.terminal, terminalFields, fieldNames, true, this.handleValueChange, inline
    );

    renderAccordionHeader = (text) => (<h4 className="text-uppercase">{text}</h4>);

    render() {
        const { terminal, initialTerminal } = this.state;
        if (!terminal) {
            return (
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <div className="flex-grow" />
                        <div className="flex-shrink">
                            <button className="btn-link icon icon-close" onClick={this.handleCancel} />
                        </div>
                    </div>
                    <div className="sten-content__body" />
                </div>
            );
        }
        const id = parseInt(this.props.id, 10);
        const isEditing = !isNaN(id);

        return (
            <Validation.Form onSubmit={this.handleSubmit} className="sten-content sten-content--has-footer">
                <div className="sten-content__header">
                    <div className="flex-grow">
                        <h1 className="sten-content__title text-ellipsis">
                            {initialTerminal ? initialTerminal.TerminalName : t('TERMINAL.NEW_TERMINAL')}
                        </h1>
                        {terminal.UpdatedBy && terminal.UpdatedAt && (
                            <p className="sten-content__subtitle text-ellipsis text-nowrap">
                                {t('GLOBAL.UPDATED_BY_USER', {
                                    date: TimeHelper.getFormatted(terminal.UpdatedAt, { time: true }),
                                    user: `${terminal.UpdatedBy}`
                                })}
                            </p>
                        )}
                    </div>
                    <div className="flex-shrink">
                        <button type="button" className="btn-link icon icon-close" onClick={this.handleCancel} />
                    </div>
                </div>
                <ScrollArea className="sten-content__body" shouldScrollToActiveElement>
                    <div className="sten-content__section">
                        {this.renderContent(terminalFieldNames.BasicInfo)}
                    </div>
                    <Accordion header={this.renderAccordionHeader(t('TERMINAL.RESTRICTIONS'))}>
                        <div className="sten-content__section">
                            {this.renderContent(terminalFieldNames.Restrictions, true)}
                        </div>
                    </Accordion>
                </ScrollArea>
                <footer className="sten-content__footer flex-row">
                    <div className="col-12">
                        <button className="btn btn--secondary col-24" type="button" onClick={this.handleCancel}>
                            {t('GLOBAL.CANCEL')}
                        </button>
                    </div>
                    <div className="col-12">
                        <Validation.Button className="btn btn--primary col-24">
                            {isEditing ? t('GLOBAL.SAVE') : t('GLOBAL.CREATE')}
                        </Validation.Button>
                    </div>
                </footer>
            </Validation.Form>
        );
    }
}

TerminalForm.propTypes = {
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isActive: PropTypes.bool,
    onUpdate: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    portId: PropTypes.number,
    router: TRouter.isRequired
};

TerminalForm.defaultProps = {
    id: null,
    isActive: false,
    portId: null
};

function mapStateToProps(state) {
    return {
        permissions: state.userReducer.permissions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        onUpdate: (id, terminal) => terminalUpdated(dispatch, id, terminal)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TerminalForm));
