export default {
    TotalCargoLaden: {
        Laden: 219862.09798338570821744131415,
        PreviousLaden: 137043.24855108343852005614450
    },
    TotalDistance: {
        Ballast: 17963,
        Laden: 35617,
        PreviousBallast: 35493,
        PreviousLaden: 27713
    },
    TotalDays: {
        Ballast: 65,
        Laden: 119,
        PreviousBallast: 116,
        PreviousLaden: 96
    },
    BallastLadenPercent: {
        Ballast: 35,
        Laden: 65,
        PreviousBallast: 55,
        PreviousLaden: 45
    },
    TotalBunkerSupply: {
        PreviousFuelTypeQuantities: [
            {
                Quantity: 713.013,
                Id: 4,
                Name: 'LSMGO',
                Sequence: 7
            },
            {
                Quantity: 7021.64,
                Id: 2,
                Name: 'HSFO',
                Sequence: 1
            },
            {
                Quantity: 0.0,
                Id: 7,
                Name: 'VLSFO',
                Sequence: 3
            },
            {
                Quantity: 0.0,
                Id: 10,
                Name: 'BIOFO',
                Sequence: 8
            }
        ],
        FuelTypeQuantities: [
            {
                Quantity: 502.34,
                Id: 2,
                Name: 'HSFO',
                Sequence: 1
            },
            {
                Quantity: 3407.64,
                Id: 4,
                Name: 'LSMGO',
                Sequence: 7
            },
            {
                Quantity: 5738.23,
                Id: 7,
                Name: 'VLSFO',
                Sequence: 3
            },
            {
                Quantity: 0.0,
                Id: 10,
                Name: 'BIOFO',
                Sequence: 8
            }
        ]
    },
    TotalBunkerPropConsumption: {
        Ballast: 1822.8999999999996,
        Laden: 4144.9000000000005,
        Port: 372.9,
        PreviousBallast: 3457.9999999999982,
        PreviousLaden: 3198.0000000000009,
        PreviousPort: 184.60000000000002
    },
    TotalBunkerAuxConsumption: {
        Ballast: 269.5,
        Laden: 501.80000000000007,
        Port: 767.60000000000025,
        PreviousBallast: 651.5,
        PreviousLaden: 521.99999999999989,
        PreviousPort: 1856.9000000000003
    },
    TotalBunkerPropAuxConsumption: {
        Ballast: 2092.3999999999996,
        Laden: 4646.7000000000007,
        Port: 1140.5000000000002,
        PreviousBallast: 4109.4999999999982,
        PreviousLaden: 3720.0000000000009,
        PreviousPort: 2041.5000000000005
    },
    TotalBunkerConsumptionDiff: {
        Ballast: 717.36684782608666,
        Laden: 3005.2027173913043,
        PreviousBallast: 2230.6471698113196,
        PreviousLaden: 1662.5207547169819
    },
    Kpi: {
        Kpi: 231.88556162208897,
        PreviousKpi: 659.04137540806335
    },
    Eev: {
        EEV: 1226.4755771776695,
        PreviousEEV: 1198.7006866163547
    }
};
