export default {
    PreviousPeriod: '13-24 months',
    Period: '1-12 months',
    Items: [
        {
            IMO: 3000001,
            Title: 'Bowery',
            TotalCargoPrevious: 523980.32953437942526499055232,
            TotalCargo: 406451.47577092511067455395814,
            TotalDistancePrevious: 31777.0,
            TotalDistance: 36713.0,
            NumberOfDaysAtSeaPrevious: 115.0,
            NumberOfDaysAtSea: 135.0,
            BallastLadenPrevious: {
                BallastPercent: 56.0,
                LadenPercent: 44.0
            },
            BallastLaden: {
                BallastPercent: 35.0,
                LadenPercent: 65.0
            },
            BallastAverageSpeedPrevious: 11.497184179456907,
            BallastAverageSpeed: 10.263147321428573,
            BallastAverageInstructedSpeedPrevious: 11.831168831168831,
            BallastAverageInstructedSpeed: 10.314732142857142,
            LadenAverageSpeedPrevious: 11.931746031746034,
            LadenAverageSpeed: 11.668771889952152,
            LadenAverageInstructedSpeedPrevious: 12.521428571428572,
            LadenAverageInstructedSpeed: 12.145454545454545,
            GoodTrimBallastPrevious: 0.60000000000000142,
            GoodTrimBallast: 0.83499999999999908,
            GoodTrimLadenPrevious: 0.06666666666666643,
            GoodTrimLaden: 0.12916666666666643,
            AuxiliaryRunningHoursPerDayPrevious: 39.600552049671769,
            AuxiliaryRunningHoursPerDay: 36.47274632939299,
            BoilerRunningHoursPerDayPrevious: 0.0,
            BoilerRunningHoursPerDay: null,
            BunkerPrevious: 217.04609753368607,
            Bunker: 276.82342882618514,
            EnergyEfficiencyPrevious: 1404.2425245845186,
            EnergyEfficiency: 1349.7384879168153,
            TotalBunkerPropAuxConsumptionPrevious: 4854.1000000000013,
            TotalBunkerPropAuxConsumption: 5333.8999999999987,
            AverageBallastMtDayPrevious: 28.215624999999992,
            AverageBallastMtDay: 26.874468085106376,
            AverageLadenMtDayPrevious: 34.484313725490196,
            AverageLadenMtDay: 35.132954545454545,
            AverageBallastMTPerKnmPrevious: 104.20682093600324,
            AverageBallastMTPerKnm: 105.68990042674253,
            AverageLadenMTPerKnmPrevious: 121.72619047619048,
            AverageLadenMTPerKnm: 124.85663516678783,
            AverageAmountOfCargoPrevious: 41746.092667752928,
            AverageAmountOfCargo: 44196.651127105331,
            FleetAverageAgePrevious: 9.1500852173555955,
            FleetAverageAge: 10.152159181913387
        },
        {
            IMO: 5000005,
            Title: 'Canal',
            TotalCargoPrevious: 182904.67541190050436589324219,
            TotalCargo: 268282.71062622190154884814339,
            TotalDistancePrevious: 39465.0,
            TotalDistance: 64454.0,
            NumberOfDaysAtSeaPrevious: 139.0,
            NumberOfDaysAtSea: 216.0,
            BallastLadenPrevious: {
                BallastPercent: 49.0,
                LadenPercent: 51.0
            },
            BallastLaden: {
                BallastPercent: 52.0,
                LadenPercent: 48.0
            },
            BallastAverageSpeedPrevious: 11.821846590909091,
            BallastAverageSpeed: 12.154127718360071,
            BallastAverageInstructedSpeedPrevious: 11.96875,
            BallastAverageInstructedSpeed: 12.458333333333334,
            LadenAverageSpeedPrevious: 12.191563025210085,
            LadenAverageSpeed: 12.426203489729225,
            LadenAverageInstructedSpeedPrevious: 12.719281045751634,
            LadenAverageInstructedSpeed: 13.0,
            GoodTrimBallastPrevious: 0.41111111111111143,
            GoodTrimBallast: 0.29999999999999982,
            GoodTrimLadenPrevious: 0.025000000000000355,
            GoodTrimLaden: 0.055555555555555358,
            AuxiliaryRunningHoursPerDayPrevious: 35.871019425048061,
            AuxiliaryRunningHoursPerDay: 33.397549284865448,
            BoilerRunningHoursPerDayPrevious: 0.40601307189542496,
            BoilerRunningHoursPerDay: 0.0041666666666666666,
            BunkerPrevious: 968.30735598659135,
            Bunker: 376.59427964038645,
            EnergyEfficiencyPrevious: 1193.5071620327808,
            EnergyEfficiency: 1264.24298612311,
            TotalBunkerPropAuxConsumptionPrevious: 5698.0999999999995,
            TotalBunkerPropAuxConsumption: 8184.4999999999991,
            AverageBallastMtDayPrevious: 32.833823529411767,
            AverageBallastMtDay: 30.861946902654861,
            AverageLadenMtDayPrevious: 38.540845070422542,
            AverageLadenMtDay: 39.368932038834949,
            AverageBallastMTPerKnmPrevious: 114.93951093951092,
            AverageBallastMTPerKnm: 103.29364374148449,
            AverageLadenMTPerKnmPrevious: 136.54690618762473,
            AverageLadenMTPerKnm: 132.11911898866151,
            AverageAmountOfCargoPrevious: 42993.016594166729,
            AverageAmountOfCargo: 44468.874351007013,
            FleetAverageAgePrevious: 13.421220148257667,
            FleetAverageAge: 14.423294112815459
        },
        {
            IMO: 4000008,
            Title: 'Spring',
            TotalCargoPrevious: 137043.24855108343852005614450,
            TotalCargo: 196481.29493532625762386426934,
            TotalDistancePrevious: 63206.0,
            TotalDistance: 53580.0,
            NumberOfDaysAtSeaPrevious: 212.0,
            NumberOfDaysAtSea: 184.0,
            BallastLadenPrevious: {
                BallastPercent: 55.0,
                LadenPercent: 45.0
            },
            BallastLaden: {
                BallastPercent: 35.0,
                LadenPercent: 65.0
            },
            BallastAverageSpeedPrevious: 12.877775002775003,
            BallastAverageSpeed: 12.597381868131869,
            BallastAverageInstructedSpeedPrevious: 13.008608058608059,
            BallastAverageInstructedSpeed: 13.26,
            LadenAverageSpeedPrevious: 12.501608114108116,
            LadenAverageSpeed: 12.549418598335707,
            LadenAverageInstructedSpeedPrevious: 13.43881118881119,
            LadenAverageInstructedSpeed: 13.344155844155843,
            GoodTrimBallastPrevious: 0.4041666666666659,
            GoodTrimBallast: 0.43333333333333357,
            GoodTrimLadenPrevious: 0.0625,
            GoodTrimLaden: 0.0,
            AuxiliaryRunningHoursPerDayPrevious: 37.561688311688307,
            AuxiliaryRunningHoursPerDay: 36.246704321523076,
            BoilerRunningHoursPerDayPrevious: 2.3363844488844494,
            BoilerRunningHoursPerDay: 2.3247524997524991,
            BunkerPrevious: 659.04137540806335,
            Bunker: 231.88556162208891,
            EnergyEfficiencyPrevious: 1198.7006866163547,
            EnergyEfficiency: 1226.4755771776697,
            TotalBunkerPropAuxConsumptionPrevious: 9870.9999999999982,
            TotalBunkerPropAuxConsumption: 7879.5999999999976,
            AverageBallastMtDayPrevious: 35.143965517241369,
            AverageBallastMtDay: 31.241538461538461,
            AverageLadenMtDayPrevious: 38.243750000000013,
            AverageLadenMtDay: 39.047899159663856,
            AverageBallastMTPerKnmPrevious: 114.85926802468092,
            AverageBallastMTPerKnm: 113.04904525970048,
            AverageLadenMTPerKnmPrevious: 132.4793418251362,
            AverageLadenMTPerKnm: 130.46298116068169,
            AverageAmountOfCargoPrevious: 41882.179343668213,
            AverageAmountOfCargo: 45408.603741392682,
            FleetAverageAgePrevious: 13.960587828634402,
            FleetAverageAge: 14.962661793192193
        },
        {
            IMO: 9203772,
            Title: 'Sonangol Kizomba DEMO',
            TotalCargoPrevious: 391845.37609778094539988627367,
            TotalCargo: 15876.557797246529270472451698,
            TotalDistancePrevious: 45552.0,
            TotalDistance: 21937.0,
            NumberOfDaysAtSeaPrevious: 155.0,
            NumberOfDaysAtSea: 73.0,
            BallastLadenPrevious: {
                BallastPercent: 45.0,
                LadenPercent: 55.0
            },
            BallastLaden: {
                BallastPercent: 47.0,
                LadenPercent: 53.0
            },
            BallastAverageSpeedPrevious: 11.835690943043884,
            BallastAverageSpeed: 12.535934065934066,
            BallastAverageInstructedSpeedPrevious: 11.956535947712418,
            BallastAverageInstructedSpeed: 12.446428571428571,
            LadenAverageSpeedPrevious: 12.439398996065663,
            LadenAverageSpeed: 12.199351851851853,
            LadenAverageInstructedSpeedPrevious: 12.727777777777778,
            LadenAverageInstructedSpeed: 12.155555555555557,
            GoodTrimBallastPrevious: 0.83166666666666611,
            GoodTrimBallast: 0.59375000000000089,
            GoodTrimLadenPrevious: 0.0,
            GoodTrimLaden: 0.043181818181816212,
            AuxiliaryRunningHoursPerDayPrevious: 32.82613881556896,
            AuxiliaryRunningHoursPerDay: 37.650000000000006,
            BoilerRunningHoursPerDayPrevious: 15.981117369490537,
            BoilerRunningHoursPerDay: 7.5539215686274517,
            BunkerPrevious: 175.15209800644195,
            Bunker: 194.12150370577,
            EnergyEfficiencyPrevious: 1626.7162933784969,
            EnergyEfficiency: 935.41014660920234,
            TotalBunkerPropAuxConsumptionPrevious: 5416.4999999999982,
            TotalBunkerPropAuxConsumption: 3261.0000000000005,
            AverageBallastMtDayPrevious: 27.579999999999995,
            AverageBallastMtDay: 31.779411764705884,
            AverageLadenMtDayPrevious: 34.845882352941175,
            AverageLadenMtDay: 33.882051282051286,
            AverageBallastMTPerKnmPrevious: 95.267702936096711,
            AverageBallastMTPerKnm: 104.65904688105385,
            AverageLadenMTPerKnmPrevious: 117.13133230513702,
            AverageLadenMTPerKnm: 113.78627400327218,
            AverageAmountOfCargoPrevious: 48594.146883959562,
            AverageAmountOfCargo: 32186.044586263837,
            FleetAverageAgePrevious: 11.9838189695887,
            FleetAverageAge: 12.985892934146491
        },
        {
            IMO: 9533995,
            Title: 'Stena Surprise DEMO',
            TotalCargoPrevious: 191582.59535675089371260553721,
            TotalCargo: 234576.42247328486413325077183,
            TotalDistancePrevious: 43907.0,
            TotalDistance: 45683.0,
            NumberOfDaysAtSeaPrevious: 148.0,
            NumberOfDaysAtSea: 156.0,
            BallastLadenPrevious: {
                BallastPercent: 53.0,
                LadenPercent: 47.0
            },
            BallastLaden: {
                BallastPercent: 40.0,
                LadenPercent: 60.0
            },
            BallastAverageSpeedPrevious: 12.243970588235294,
            BallastAverageSpeed: 12.460048144257701,
            BallastAverageInstructedSpeedPrevious: 12.083333333333334,
            BallastAverageInstructedSpeed: 12.301339285714285,
            LadenAverageSpeedPrevious: 12.507447579993535,
            LadenAverageSpeed: 12.7127591765873,
            LadenAverageInstructedSpeedPrevious: 12.875,
            LadenAverageInstructedSpeed: 13.016666666666666,
            GoodTrimBallastPrevious: 0.37499999999999911,
            GoodTrimBallast: 0.58095238095238155,
            GoodTrimLadenPrevious: -0.0023809523809514843,
            GoodTrimLaden: 0.04444444444444251,
            AuxiliaryRunningHoursPerDayPrevious: 30.662594537815121,
            AuxiliaryRunningHoursPerDay: 33.230628501400567,
            BoilerRunningHoursPerDayPrevious: 5.0,
            BoilerRunningHoursPerDay: 12.868518518518513,
            BunkerPrevious: 497.5050448242921,
            Bunker: 314.40033199985157,
            EnergyEfficiencyPrevious: 1409.5689814636444,
            EnergyEfficiency: 1478.9849059784538,
            TotalBunkerPropAuxConsumptionPrevious: 5770.9999999999982,
            TotalBunkerPropAuxConsumption: 5316.0000000000018,
            AverageBallastMtDayPrevious: 27.603846153846153,
            AverageBallastMtDay: 26.89193548387097,
            AverageLadenMtDayPrevious: 34.495714285714293,
            AverageLadenMtDay: 33.8159574468085,
            AverageBallastMTPerKnmPrevious: 93.223934880498788,
            AverageBallastMTPerKnm: 94.389719202898547,
            AverageLadenMTPerKnmPrevious: 116.0299841430013,
            AverageLadenMTPerKnm: 113.4480174167529,
            AverageAmountOfCargoPrevious: 43780.081274839024,
            AverageAmountOfCargo: 46764.6078954228,
            FleetAverageAgePrevious: 9.76337638692102,
            FleetAverageAge: 10.765450351478812
        },
        {
            IMO: 9575541,
            Title: 'Sonangol Rangel DEMO',
            TotalCargoPrevious: 358157.00736468641487100813959,
            TotalCargo: 31894.632999325377613407671646,
            TotalDistancePrevious: 56144.0,
            TotalDistance: 24357.0,
            NumberOfDaysAtSeaPrevious: 189.0,
            NumberOfDaysAtSea: 81.0,
            BallastLadenPrevious: {
                BallastPercent: 37.0,
                LadenPercent: 63.0
            },
            BallastLaden: {
                BallastPercent: 40.0,
                LadenPercent: 60.0
            },
            BallastAverageSpeedPrevious: 11.41092287784679,
            BallastAverageSpeed: 12.27077380952381,
            BallastAverageInstructedSpeedPrevious: 11.250165631469979,
            BallastAverageInstructedSpeed: 12.3125,
            LadenAverageSpeedPrevious: 13.072921580053935,
            LadenAverageSpeed: 12.696931216931217,
            LadenAverageInstructedSpeedPrevious: 12.953257575757576,
            LadenAverageInstructedSpeed: 12.553791887125222,
            GoodTrimBallastPrevious: 0.42500000000000071,
            GoodTrimBallast: 0.23749999999999893,
            GoodTrimLadenPrevious: 0.0,
            GoodTrimLaden: 0.017499999999998295,
            AuxiliaryRunningHoursPerDayPrevious: 31.203763220297748,
            AuxiliaryRunningHoursPerDay: 34.063131313131308,
            BoilerRunningHoursPerDayPrevious: 1.7487413304229193,
            BoilerRunningHoursPerDay: null,
            BunkerPrevious: 251.40138797044344,
            Bunker: 322.75067639292166,
            EnergyEfficiencyPrevious: 1382.102532491047,
            EnergyEfficiency: 840.87869797181088,
            TotalBunkerPropAuxConsumptionPrevious: 6791.5999999999995,
            TotalBunkerPropAuxConsumption: 4218.4,
            AverageBallastMtDayPrevious: 24.944927536231877,
            AverageBallastMtDay: 34.096875000000004,
            AverageLadenMtDayPrevious: 36.732499999999987,
            AverageLadenMtDay: 36.814285714285717,
            AverageBallastMTPerKnmPrevious: 95.696652952296219,
            AverageBallastMTPerKnm: 115.2529840498574,
            AverageLadenMTPerKnmPrevious: 115.51706064259132,
            AverageLadenMTPerKnm: 121.14842175957018,
            AverageAmountOfCargoPrevious: 44693.492639290438,
            AverageAmountOfCargo: 30804.587777232362,
            FleetAverageAgePrevious: 8.4765600936364223,
            FleetAverageAge: 9.4786340581942135
        },
        {
            IMO: 9575565,
            Title: 'Sonangol Huila DEMO',
            TotalCargoPrevious: 481735.52718591467783992837959,
            TotalCargo: 627285.17319176396899357757027,
            TotalDistancePrevious: 49658.0,
            TotalDistance: 40337.0,
            NumberOfDaysAtSeaPrevious: 169.0,
            NumberOfDaysAtSea: 135.0,
            BallastLadenPrevious: {
                BallastPercent: 39.0,
                LadenPercent: 61.0
            },
            BallastLaden: {
                BallastPercent: 33.0,
                LadenPercent: 67.0
            },
            BallastAverageSpeedPrevious: 12.123337595907927,
            BallastAverageSpeed: 12.847506944444444,
            BallastAverageInstructedSpeedPrevious: 11.9875,
            BallastAverageInstructedSpeed: 12.732638888888889,
            LadenAverageSpeedPrevious: 12.137414252414249,
            LadenAverageSpeed: 12.533532679738563,
            LadenAverageInstructedSpeedPrevious: 12.63131313131313,
            LadenAverageInstructedSpeed: 12.626579520697168,
            GoodTrimBallastPrevious: 0.7666666666666675,
            GoodTrimBallast: 0.78787878787878718,
            GoodTrimLadenPrevious: 0.0,
            GoodTrimLaden: 0.27727272727272734,
            AuxiliaryRunningHoursPerDayPrevious: 32.695535809312076,
            AuxiliaryRunningHoursPerDay: 36.9726344325609,
            BoilerRunningHoursPerDayPrevious: 4.03153153153153,
            BoilerRunningHoursPerDay: 10.923026315789473,
            BunkerPrevious: 239.01607106629166,
            Bunker: 180.90419406898965,
            EnergyEfficiencyPrevious: 1823.9699718250067,
            EnergyEfficiency: 1544.2627595740746,
            TotalBunkerPropAuxConsumptionPrevious: 5874.7000000000007,
            TotalBunkerPropAuxConsumption: 5361.6999999999971,
            AverageBallastMtDayPrevious: 27.140909090909094,
            AverageBallastMtDay: 29.118181818181824,
            AverageLadenMtDayPrevious: 33.898058252427184,
            AverageLadenMtDay: 33.801098901098918,
            AverageBallastMTPerKnmPrevious: 94.988864142538972,
            AverageBallastMTPerKnm: 96.840513983371125,
            AverageLadenMTPerKnmPrevious: 113.36038961038962,
            AverageLadenMTPerKnm: 113.4725347696167,
            AverageAmountOfCargoPrevious: 52877.121939882243,
            AverageAmountOfCargo: 51179.775392001691,
            FleetAverageAgePrevious: 12.180948274091874,
            FleetAverageAge: 13.183022238649665
        },
        {
            IMO: 9575577,
            Title: 'Sonangol Porto Amboim DEMO',
            TotalCargoPrevious: 402856.64645217942189296614477,
            TotalCargo: 343309.70005237888697176775041,
            TotalDistancePrevious: 36731.0,
            TotalDistance: 55947.0,
            NumberOfDaysAtSeaPrevious: 129.0,
            NumberOfDaysAtSea: 187.0,
            BallastLadenPrevious: {
                BallastPercent: 47.0,
                LadenPercent: 53.0
            },
            BallastLaden: {
                BallastPercent: 43.0,
                LadenPercent: 57.0
            },
            BallastAverageSpeedPrevious: 11.350014245014243,
            BallastAverageSpeed: 12.04475,
            BallastAverageInstructedSpeedPrevious: 11.606837606837608,
            BallastAverageInstructedSpeed: 12.412037037037038,
            LadenAverageSpeedPrevious: 12.455562091503268,
            LadenAverageSpeed: 12.916015469824295,
            LadenAverageInstructedSpeedPrevious: 12.808333333333332,
            LadenAverageInstructedSpeed: 13.128472222222221,
            GoodTrimBallastPrevious: 0.58000000000000007,
            GoodTrimBallast: 0.69444444444444553,
            GoodTrimLadenPrevious: 0.0,
            GoodTrimLaden: -0.0099999999999997868,
            AuxiliaryRunningHoursPerDayPrevious: 40.154727085929132,
            AuxiliaryRunningHoursPerDay: 34.606503176762459,
            BoilerRunningHoursPerDayPrevious: 11.149871029794305,
            BoilerRunningHoursPerDay: 1.9833871257601372,
            BunkerPrevious: 276.73222585890471,
            Bunker: 350.07844133357708,
            EnergyEfficiencyPrevious: 1434.9419811057448,
            EnergyEfficiency: 1214.386947425555,
            TotalBunkerPropAuxConsumptionPrevious: 5647.1000000000013,
            TotalBunkerPropAuxConsumption: 7506.8000000000011,
            AverageBallastMtDayPrevious: 29.942622950819683,
            AverageBallastMtDay: 28.522222222222226,
            AverageLadenMtDayPrevious: 41.155882352941184,
            AverageLadenMtDay: 40.589622641509415,
            AverageBallastMTPerKnmPrevious: 110.88513841670712,
            AverageBallastMTPerKnm: 99.103466026081,
            AverageLadenMTPerKnmPrevious: 138.1410731033121,
            AverageLadenMTPerKnm: 131.83698483223532,
            AverageAmountOfCargoPrevious: 47800.668922081226,
            AverageAmountOfCargo: 43093.842046324033,
            FleetAverageAgePrevious: 13.558115498607092,
            FleetAverageAge: 14.560189463164884
        },
        {
            IMO: 9579030,
            Title: 'Stena Superior DEMO',
            TotalCargoPrevious: 105925.10799616405613981737645,
            TotalCargo: 24869.032094165399068507070878,
            TotalDistancePrevious: 33343.0,
            TotalDistance: 29772.0,
            NumberOfDaysAtSeaPrevious: 105.0,
            NumberOfDaysAtSea: 101.0,
            BallastLadenPrevious: {
                BallastPercent: 34.0,
                LadenPercent: 66.0
            },
            BallastLaden: {
                BallastPercent: 40.0,
                LadenPercent: 60.0
            },
            BallastAverageSpeedPrevious: 12.611979166666666,
            BallastAverageSpeed: 12.094144345238096,
            BallastAverageInstructedSpeedPrevious: 12.5234375,
            BallastAverageInstructedSpeed: 12.010416666666668,
            LadenAverageSpeedPrevious: 13.274440476190478,
            LadenAverageSpeed: 12.774734848484849,
            LadenAverageInstructedSpeedPrevious: 13.435714285714287,
            LadenAverageInstructedSpeed: 12.761363636363637,
            GoodTrimBallastPrevious: 0.89374999999999982,
            GoodTrimBallast: 0.84642857142857064,
            GoodTrimLadenPrevious: 0.0,
            GoodTrimLaden: 0.0,
            AuxiliaryRunningHoursPerDayPrevious: 33.229192927170864,
            AuxiliaryRunningHoursPerDay: 32.739800170068015,
            BoilerRunningHoursPerDayPrevious: 1.4366747835497837,
            BoilerRunningHoursPerDay: 16.954044117647058,
            BunkerPrevious: 271.62733334923445,
            Bunker: 241.12855508116209,
            EnergyEfficiencyPrevious: 1038.2200653905682,
            EnergyEfficiency: 859.938838687753,
            TotalBunkerPropAuxConsumptionPrevious: 5008.9,
            TotalBunkerPropAuxConsumption: 4334.9,
            AverageBallastMtDayPrevious: 30.769444444444439,
            AverageBallastMtDay: 26.729999999999997,
            AverageLadenMtDayPrevious: 38.475362318840588,
            AverageLadenMtDay: 31.27377049180328,
            AverageBallastMTPerKnmPrevious: 97.931217398992132,
            AverageBallastMTPerKnm: 96.36773321315907,
            AverageLadenMTPerKnmPrevious: 120.49745824255628,
            AverageLadenMTPerKnm: 102.14167157466402,
            AverageAmountOfCargoPrevious: 36948.3586560653,
            AverageAmountOfCargo: 27171.002160419186,
            FleetAverageAgePrevious: 10.185014065997251,
            FleetAverageAge: 11.187088030555042
        },
        {
            IMO: 9579042,
            Title: 'Stena Suede DEMO',
            TotalCargoPrevious: 84706.97623320562669641557938,
            TotalCargo: 290339.54518969440566719433669,
            TotalDistancePrevious: 61403.0,
            TotalDistance: 46133.0,
            NumberOfDaysAtSeaPrevious: 207.0,
            NumberOfDaysAtSea: 159.0,
            BallastLadenPrevious: {
                BallastPercent: 53.0,
                LadenPercent: 47.0
            },
            BallastLaden: {
                BallastPercent: 11.0,
                LadenPercent: 89.0
            },
            BallastAverageSpeedPrevious: 11.701559078176727,
            BallastAverageSpeed: 11.427666666666667,
            BallastAverageInstructedSpeedPrevious: 11.588591800356506,
            BallastAverageInstructedSpeed: 12.458333333333334,
            LadenAverageSpeedPrevious: 13.233856837606842,
            LadenAverageSpeed: 12.522826096845051,
            LadenAverageInstructedSpeedPrevious: 13.416666666666666,
            LadenAverageInstructedSpeed: 12.863636363636363,
            GoodTrimBallastPrevious: 0.39285714285714324,
            GoodTrimBallast: 0.56111111111111267,
            GoodTrimLadenPrevious: 0.013888888888889284,
            GoodTrimLaden: 0.022222222222222143,
            AuxiliaryRunningHoursPerDayPrevious: 30.47151112180984,
            AuxiliaryRunningHoursPerDay: 35.428503035588008,
            BoilerRunningHoursPerDayPrevious: 1.6712775735294114,
            BoilerRunningHoursPerDay: 2.9340108401084004,
            BunkerPrevious: 391.7180283642594,
            Bunker: 376.93955033949368,
            EnergyEfficiencyPrevious: 1178.7138888190889,
            EnergyEfficiency: 1346.9683467827331,
            TotalBunkerPropAuxConsumptionPrevious: 7754.6999999999962,
            TotalBunkerPropAuxConsumption: 7169.7000000000007,
            AverageBallastMtDayPrevious: 30.158181818181827,
            AverageBallastMtDay: 27.888235294117642,
            AverageLadenMtDayPrevious: 39.6216494845361,
            AverageLadenMtDay: 37.375352112676048,
            AverageBallastMTPerKnmPrevious: 105.7271249641457,
            AverageBallastMTPerKnm: 116.25796959293771,
            AverageLadenMTPerKnmPrevious: 127.99906747485512,
            AverageLadenMTPerKnm: 126.19902508619663,
            AverageAmountOfCargoPrevious: 39931.301764294862,
            AverageAmountOfCargo: 47845.869633200862,
            FleetAverageAgePrevious: 13.725127826033392,
            FleetAverageAge: 14.727201790591183
        },
        {
            IMO: 9585895,
            Title: 'Stena Supreme DEMO',
            TotalCargoPrevious: 977747.5653047040310336886694,
            TotalCargo: 626616.46143326039387308533917,
            TotalDistancePrevious: 60590.0,
            TotalDistance: 30727.0,
            NumberOfDaysAtSeaPrevious: 204.0,
            NumberOfDaysAtSea: 107.0,
            BallastLadenPrevious: {
                BallastPercent: 48.0,
                LadenPercent: 52.0
            },
            BallastLaden: {
                BallastPercent: 34.0,
                LadenPercent: 66.0
            },
            BallastAverageSpeedPrevious: 12.402993244129608,
            BallastAverageSpeed: 12.31678492063492,
            BallastAverageInstructedSpeedPrevious: 12.376446280991734,
            BallastAverageInstructedSpeed: 12.071428571428573,
            LadenAverageSpeedPrevious: 12.694297644485147,
            LadenAverageSpeed: 11.668168498168496,
            LadenAverageInstructedSpeedPrevious: 12.902421652421651,
            LadenAverageInstructedSpeed: 12.864285714285714,
            GoodTrimBallastPrevious: 3.0299999999999994,
            GoodTrimBallast: 2.9999999999999973,
            GoodTrimLadenPrevious: 0.30000000000000071,
            GoodTrimLaden: 0.0,
            AuxiliaryRunningHoursPerDayPrevious: 27.099105639730642,
            AuxiliaryRunningHoursPerDay: 27.848303620436649,
            BoilerRunningHoursPerDayPrevious: 1.9588777685107965,
            BoilerRunningHoursPerDay: 0.17399827491570605,
            BunkerPrevious: 151.9471249190309,
            Bunker: 219.67086942955513,
            EnergyEfficiencyPrevious: 3054.771083246198,
            EnergyEfficiency: 3124.6034959597064,
            TotalBunkerPropAuxConsumptionPrevious: 9195.1000000000058,
            TotalBunkerPropAuxConsumption: 5243.1999999999989,
            AverageBallastMtDayPrevious: 36.5360824742268,
            AverageBallastMtDay: 36.983333333333327,
            AverageLadenMtDayPrevious: 46.184112149532709,
            AverageLadenMtDay: 46.269014084507027,
            AverageBallastMTPerKnmPrevious: 123.12823541673905,
            AverageBallastMTPerKnm: 123.02716688227684,
            AverageLadenMTPerKnmPrevious: 155.36517118873203,
            AverageLadenMTPerKnm: 165.0389349409696,
            AverageAmountOfCargoPrevious: 126260.6221001221,
            AverageAmountOfCargo: 132889.44902565994,
            FleetAverageAgePrevious: 18.8696550921648,
            FleetAverageAge: 19.871729056722589
        },
        {
            IMO: 9585900,
            Title: 'AST Sunshine DEMO',
            TotalCargoPrevious: 979035.2387222946951138205882,
            TotalCargo: 146405.0,
            TotalDistancePrevious: 59836.0,
            TotalDistance: 0.0,
            NumberOfDaysAtSeaPrevious: 196.0,
            NumberOfDaysAtSea: 0.0,
            BallastLadenPrevious: {
                BallastPercent: 27.0,
                LadenPercent: 73.0
            },
            BallastLaden: {
                BallastPercent: null,
                LadenPercent: null
            },
            BallastAverageSpeedPrevious: 12.940430249022684,
            BallastAverageSpeed: null,
            BallastAverageInstructedSpeedPrevious: 12.873949579831933,
            BallastAverageInstructedSpeed: null,
            LadenAverageSpeedPrevious: 12.819538485221676,
            LadenAverageSpeed: null,
            LadenAverageInstructedSpeedPrevious: 13.114333333333331,
            LadenAverageInstructedSpeed: null,
            GoodTrimBallastPrevious: 3.0305555555555559,
            GoodTrimBallast: 3.0,
            GoodTrimLadenPrevious: 0.17407407407407227,
            GoodTrimLaden: null,
            AuxiliaryRunningHoursPerDayPrevious: 31.040916913451682,
            AuxiliaryRunningHoursPerDay: null,
            BoilerRunningHoursPerDayPrevious: 3.861309523809525,
            BoilerRunningHoursPerDay: null,
            BunkerPrevious: 149.6823533503995,
            Bunker: null,
            EnergyEfficiencyPrevious: 2912.6259683271651,
            EnergyEfficiency: null,
            TotalBunkerPropAuxConsumptionPrevious: 10531.07,
            TotalBunkerPropAuxConsumption: 6.4,
            AverageBallastMtDayPrevious: 42.060566037735832,
            AverageBallastMtDay: null,
            AverageLadenMtDayPrevious: 49.411188811188815,
            AverageLadenMtDay: null,
            AverageBallastMTPerKnmPrevious: 137.1990398818316,
            AverageBallastMTPerKnm: null,
            AverageLadenMTPerKnmPrevious: 162.10424887583738,
            AverageLadenMTPerKnm: null,
            AverageAmountOfCargoPrevious: 136594.19850838391,
            AverageAmountOfCargo: null,
            FleetAverageAgePrevious: 7.7510147367844677,
            FleetAverageAge: 8.7530887013422589
        },
        {
            IMO: 9592214,
            Title: 'Stena Sunrise DEMO',
            TotalCargoPrevious: 724723.0,
            TotalCargo: 133893.0,
            TotalDistancePrevious: 56788.0,
            TotalDistance: 9603.0,
            NumberOfDaysAtSeaPrevious: 183.0,
            NumberOfDaysAtSea: 35.0,
            BallastLadenPrevious: {
                BallastPercent: 28.0,
                LadenPercent: 72.0
            },
            BallastLaden: {
                BallastPercent: 46.0,
                LadenPercent: 54.0
            },
            BallastAverageSpeedPrevious: 12.712931998556998,
            BallastAverageSpeed: 11.548095238095238,
            BallastAverageInstructedSpeedPrevious: 12.4447601010101,
            BallastAverageInstructedSpeed: 13.0,
            LadenAverageSpeedPrevious: 12.797904352974614,
            LadenAverageSpeed: 10.355999999999998,
            LadenAverageInstructedSpeedPrevious: 12.974423076923078,
            LadenAverageInstructedSpeed: 13.0,
            GoodTrimBallastPrevious: 2.9642857142857153,
            GoodTrimBallast: 3.0500000000000016,
            GoodTrimLadenPrevious: 0.038888888888887863,
            GoodTrimLaden: 0.0,
            AuxiliaryRunningHoursPerDayPrevious: 30.331965649574681,
            AuxiliaryRunningHoursPerDay: null,
            BoilerRunningHoursPerDayPrevious: 0.0020283975659229209,
            BoilerRunningHoursPerDay: null,
            BunkerPrevious: 202.34239584810317,
            Bunker: 1247.7616635193483,
            EnergyEfficiencyPrevious: 2690.2297116481159,
            EnergyEfficiency: 3032.2730314081373,
            TotalBunkerPropAuxConsumptionPrevious: 9531.0800000000036,
            TotalBunkerPropAuxConsumption: 1827.0,
            AverageBallastMtDayPrevious: 39.071153846153848,
            AverageBallastMtDay: 41.65,
            AverageLadenMtDayPrevious: 48.274427480916025,
            AverageLadenMtDay: 49.368421052631582,
            AverageBallastMTPerKnmPrevious: 127.77987421383648,
            AverageBallastMTPerKnm: 145.1219512195122,
            AverageLadenMTPerKnmPrevious: 154.66518293875953,
            AverageLadenMTPerKnm: 187.18818599082019,
            AverageAmountOfCargoPrevious: 120904.98805335625,
            AverageAmountOfCargo: 133893.0,
            FleetAverageAgePrevious: 7.9618335763225829,
            FleetAverageAge: 8.9639075408803741
        },
        {
            IMO: 9766310,
            Title: 'Sonangol Cazenga DEMO',
            TotalCargoPrevious: 1334290.4771608883663742226657,
            TotalCargo: 888745.7079891218356358967234,
            TotalDistancePrevious: 47515.0,
            TotalDistance: 65860.0,
            NumberOfDaysAtSeaPrevious: 157.0,
            NumberOfDaysAtSea: 222.0,
            BallastLadenPrevious: {
                BallastPercent: 29.0,
                LadenPercent: 71.0
            },
            BallastLaden: {
                BallastPercent: 38.0,
                LadenPercent: 62.0
            },
            BallastAverageSpeedPrevious: 12.236836419753086,
            BallastAverageSpeed: 12.788280880858272,
            BallastAverageInstructedSpeedPrevious: 12.561728395061728,
            BallastAverageInstructedSpeed: 12.4,
            LadenAverageSpeedPrevious: 12.632717304625201,
            LadenAverageSpeed: 12.098761174772939,
            LadenAverageInstructedSpeedPrevious: 12.871371610845296,
            LadenAverageInstructedSpeed: 12.978872549019608,
            GoodTrimBallastPrevious: 2.7044973544973523,
            GoodTrimBallast: 3.0000000000000009,
            GoodTrimLadenPrevious: 0.056250000000002132,
            GoodTrimLaden: 0.0,
            AuxiliaryRunningHoursPerDayPrevious: 30.810432249875337,
            AuxiliaryRunningHoursPerDay: null,
            BoilerRunningHoursPerDayPrevious: 4.1055555555555543,
            BoilerRunningHoursPerDay: null,
            BunkerPrevious: 86.676848290577325,
            Bunker: 144.51194994071128,
            EnergyEfficiencyPrevious: 3409.721974377368,
            EnergyEfficiency: 3323.9954861224437,
            TotalBunkerPropAuxConsumptionPrevious: 7820.3600000000006,
            TotalBunkerPropAuxConsumption: 9086.29,
            AverageBallastMtDayPrevious: 35.044444444444451,
            AverageBallastMtDay: 33.911904761904758,
            AverageLadenMtDayPrevious: 42.882589285714296,
            AverageLadenMtDay: 40.2068115942029,
            AverageBallastMTPerKnmPrevious: 118.54468916785686,
            AverageBallastMTPerKnm: 111.54795003328503,
            AverageLadenMTPerKnmPrevious: 140.3849526481936,
            AverageLadenMTPerKnm: 137.60236093544626,
            AverageAmountOfCargoPrevious: 138481.34451949914,
            AverageAmountOfCargo: 122498.61381044595,
            FleetAverageAgePrevious: 7.1349856602120534,
            FleetAverageAge: 8.1370596247698437
        }
    ],
    Title: null,
    TotalCargoPrevious: 2860737.4901840454047035673695,
    TotalCargo: 2439366.5451303327015654439943,
    TotalDistancePrevious: 461186.0,
    TotalDistance: 418913.0,
    NumberOfDaysAtSeaPrevious: 1568.0,
    NumberOfDaysAtSea: 1427.0,
    BallastLadenPrevious: {
        BallastPercent: 47.0,
        LadenPercent: 53.0
    },
    BallastLaden: {
        BallastPercent: 37.0,
        LadenPercent: 63.0
    },
    BallastAverageSpeedPrevious: 11.96064967657933,
    BallastAverageSpeed: 12.022975831180256,
    BallastAverageInstructedSpeedPrevious: 11.989873182509257,
    BallastAverageInstructedSpeed: 12.17143198958982,
    LadenAverageSpeedPrevious: 12.654039081539159,
    LadenAverageSpeed: 12.46961797122365,
    LadenAverageInstructedSpeedPrevious: 13.006433189075912,
    LadenAverageInstructedSpeed: 12.843561265799496,
    GoodTrimBallastPrevious: 0.58465698653198572,
    GoodTrimBallast: 0.59672619047619158,
    GoodTrimLadenPrevious: 0.025162337662335332,
    GoodTrimLaden: 0.048673027544355207,
    AuxiliaryRunningHoursPerDayPrevious: 34.395246935850722,
    AuxiliaryRunningHoursPerDay: 34.593283751422142,
    BoilerRunningHoursPerDayPrevious: 3.652808342357317,
    BoilerRunningHoursPerDay: 2.794234231908395,
    BunkerPrevious: 30.7616911917028,
    Bunker: 27.058082428646536,
    EnergyEfficiencyPrevious: 1363.9928474236935,
    EnergyEfficiency: 1242.771546159755,
    TotalBunkerPropAuxConsumptionPrevious: 62687.699999999953,
    TotalBunkerPropAuxConsumption: 58566.499999999993,
    AverageBallastMtDayPrevious: 29.760162601625986,
    AverageBallastMtDay: 29.448411214953275,
    AverageLadenMtDayPrevious: 37.035180722891596,
    AverageLadenMtDay: 36.648878923766816,
    AverageBallastMTPerKnmPrevious: 103.78901007504298,
    AverageBallastMTPerKnm: 103.07695327322925,
    AverageLadenMTPerKnmPrevious: 123.16667601593115,
    AverageLadenMTPerKnm: 122.86679670909957,
    AverageAmountOfCargoPrevious: 44411.859645671961,
    AverageAmountOfCargo: 42804.682902708839,
    FleetAverageAgePrevious: 11.640485430912342,
    FleetAverageAge: 12.642559395470133
};
