import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import DtnWeatherLegend from 'components/dtn-weather/map-legend/dtn-weather-map-legend';
import MapLegendTable from './map-legend-table/map-legend-table';
/* style */
import './map-legend.scss';

const t = (key) => translate(`MAP_OPTIONS.LEGEND.${key}`);

class NavMenuMapLegend extends React.PureComponent {
    render() {
        const { permissions } = this.props;

        let ballastVesselsLabel = t('BALLAST');
        let ladenVesselsLabel = t('LADEN');
        if (permissions.ShowLegendOwned) {
            ladenVesselsLabel = t('OWNED_LADEN');
            ballastVesselsLabel = t('OWNED_BALLAST');
        } else if (permissions.ShowLegendManaged) {
            ladenVesselsLabel = t('MANAGED_LADEN');
            ballastVesselsLabel = t('MANAGED_BALLAST');
        }

        let rows = [
            { id: 1, iconClass: 'sten-icon-laden', label: ladenVesselsLabel },
            { id: 2, iconClass: 'sten-icon-ballast', label: ballastVesselsLabel },
            { id: 3, iconClass: 'sten-icon-laden-competition', label: t('OTHER_LADEN') },
            { id: 4, iconClass: 'sten-icon-ballast-competition', label: t('OTHER_BALLAST') }
        ];


        if (permissions.ShowLegendTC) {
            rows = [
                { id: 5, iconClass: 'sten-icon-laden-quaternary', label: t('TC_VESSEL_LADEN') },
                { id: 6, iconClass: 'sten-icon-ballast-quaternary', label: t('TC_VESSEL_BALLAST') },
                ...rows
            ];
        }

        if (permissions.ShowLegendEmployed) {
            rows = [
                { id: 7, iconClass: 'sten-icon-laden-tertiary', label: t('EMPLOYED_VESSEL_LADEN') },
                { id: 8, iconClass: 'sten-icon-ballast-tertiary', label: t('EMPLOYED_VESSEL_BALLAST') },
                ...rows
            ];
        }

        if (permissions.ShowLegendAlert) {
            rows = [
                { id: 9, iconClass: 'sten-icon-laden-danger', label: t('ATTENTION_LADEN') },
                { id: 10, iconClass: 'sten-icon-ballast-danger', label: t('ATTENTION_BALLAST') },
                ...rows
            ];
        }

        if (permissions.ShowLegendDedicated) {
            rows = [
                { id: 11, iconClass: 'sten-icon-laden-tertiary', label: t('MY_VESSEL_LADEN') },
                { id: 12, iconClass: 'sten-icon-ballast-tertiary', label: t('MY_VESSEL_BALLAST') },
                ...rows
            ];
        }

        const routeRows = [
            { id: 0, iconClass: 'sten-icon-laden-route', label: t('ROUTE_LADEN') },
            { id: 1, iconClass: 'sten-icon-ballast-route', label: t('ROUTE_BALLAST') },
            { id: 2, iconClass: 'sten-icon-future-route', label: (t('PLANNED_ROUTE')) },
            { id: 3, iconClass: 'sten-icon-no-position-route', label: t('POSITIONS_MISSING') }
        ];

        return (
            <div className="sten-map-legend">
                {!this.props.isWeatherLegendHidden && <DtnWeatherLegend />}
                <div className="sten-map-legend__body">
                    <MapLegendTable rows={rows} />
                </div>
                <div className="sten-content__separator" />
                <div className="sten-map-legend__body">
                    <MapLegendTable rows={routeRows} />
                </div>
            </div>
        );
    }
}

NavMenuMapLegend.propTypes = {
    isWeatherLegendHidden: PropTypes.bool,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired
};

NavMenuMapLegend.defaultProps = {
    isWeatherLegendHidden: false
};

function mapStateToProps(state) {
    return {
        permissions: state.userReducer.permissions
    };
}

export default connect(mapStateToProps, null)(NavMenuMapLegend);
