import React from 'react';
import PropTypes from 'prop-types';
/* helpers */
import { translate } from 'utils/i18n/i18n-model';
import {
    convertDateToObj,
    convertObjToDate,
    getDateFromDifference,
    compareDates
} from 'components/htec-date-picker/helpers';
import TimeHelper from 'utils/helpers/time-helper';
/* components */
import Popover from 'components/popover/popover';
import DateRangePresets from './date-range-presets';
import { DateRangePicker, DateRangeInput } from 'components/htec-date-picker';
/* styles */
import './date-range-popup.scss';

const emptyRange = {
    rangeStart: null,
    rangeEnd: null
};

export default class DateRangePopover extends React.PureComponent {
    state = {
        isPopoverVisible: false,
        editDate: null,
        prevValue: null,
        value: emptyRange
    };

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.prevValue) {
            const newState = { prevValue: props.value };
            if (props.value) {
                if (props.dateType === 'object') {
                    newState.value = props.value;
                } else {
                    let isValueChanged = false;
                    const value = state.value ? { ...state.value } : { ...props.value };
                    if (!props.value.rangeStart || props.value.rangeStart !== props.invalidDate) {
                        value.rangeStart = convertDateToObj(props.value.rangeStart, props.isUTC);
                        isValueChanged = true;
                    }
                    if (!props.value.rangeEnd || props.value.rangeEnd !== props.invalidDate) {
                        value.rangeEnd = convertDateToObj(props.value.rangeEnd, props.isUTC);
                        isValueChanged = true;
                    }
                    if (isValueChanged) {
                        newState.value = value;
                    }
                }
            } else {
                newState.value = emptyRange;
            }
            return newState;
        }
        return null;
    }

    getPresets = () => {
        if (this.props.presets && this.props.presetDateType !== 'object') {
            return this.props.presets.map(preset => ({
                ...preset,
                startDate: convertDateToObj(preset.startDate, this.props.isUTC),
                endDate: convertDateToObj(preset.endDate, this.props.isUTC)
            }));
        }
        return this.props.presets;
    };

    getContent = (rangeStart, rangeEnd, hoveredDate) => {
        let minDate = this.props.minDate || null;
        let maxDate = this.props.maxDate || null;
        let initialMonth = this.props.initialMonth;
        let initialMonthSource = null;
        if (this.props.dateType !== 'object') {
            if (minDate) {
                minDate = convertDateToObj(minDate, this.props.isUTC);
            }
            if (maxDate) {
                maxDate = convertDateToObj(maxDate, this.props.isUTC);
            }
        }
        if (!this.props.initialMonth && (rangeEnd || (hoveredDate && rangeStart))) {
            if (hoveredDate && rangeStart) {
                if (compareDates(hoveredDate, rangeStart) < 0) {
                    initialMonthSource = getDateFromDifference(hoveredDate, 'month', 1);
                } else {
                    initialMonthSource = hoveredDate;
                }
            } else {
                initialMonthSource = rangeEnd;
            }
            initialMonth = { year: initialMonthSource.year, month: initialMonthSource.month };
        }
        let contentClassName = 'sten-date-range-popup__content';
        if (this.props.contentClassName) {
            contentClassName += ` ${this.props.contentClassName}`;
        }
        return (
            <div className={contentClassName}>
                <DateRangePicker
                    ref={(c) => { this.dateRangePicker = c; }}
                    className="hide-xs"
                    alwaysShow6Weeks={this.props.alwaysShow6Weeks}
                    disableMonthsOutOfRange={this.props.disableMonthsOutOfRange}
                    hideOutsideDays={this.props.hideOutsideDays}
                    hoveredDate={hoveredDate}
                    hoverTracking={this.props.hoverTracking}
                    minRangeDays={this.props.minRangeDays}
                    maxRangeDays={this.props.maxRangeDays}
                    renderWeekDays={this.props.renderWeekDays}
                    today={this.props.today}
                    weekStart={this.props.weekStart}
                    renderDay={this.props.renderDay}
                    onRangeSelect={this.handleRangeChange}
                    rangeStart={rangeStart}
                    rangeEnd={rangeEnd}
                    initialMonth={initialMonth}
                    nextBtn={<span className="icon icon-chevron-right sten-date-range-popup__icon" />}
                    prevBtn={<span className="icon icon-chevron-left sten-date-range-popup__icon" />}
                    minDate={minDate}
                    maxDate={maxDate}
                />
                <DateRangePicker
                    ref={(c) => { this.dateRangePickerXs = c; }}
                    className="show-xs"
                    alwaysShow6Weeks={this.props.alwaysShow6Weeks}
                    disableMonthsOutOfRange={this.props.disableMonthsOutOfRange}
                    hideOutsideDays={this.props.hideOutsideDays}
                    hoveredDate={hoveredDate}
                    hoverTracking={this.props.hoverTracking}
                    minRangeDays={this.props.minRangeDays}
                    maxRangeDays={this.props.maxRangeDays}
                    renderWeekDays={this.props.renderWeekDays}
                    today={this.props.today}
                    weekStart={this.props.weekStart}
                    renderDay={this.props.renderDay}
                    onRangeSelect={this.handleRangeChange}
                    rangeStart={rangeStart}
                    rangeEnd={rangeEnd}
                    initialMonth={this.props.initialMonth}
                    nextBtn={<span className="icon icon-chevron-right sten-date-range-popup__icon" />}
                    prevBtn={<span className="icon icon-chevron-left sten-date-range-popup__icon" />}
                    minDate={minDate}
                    maxDate={maxDate}
                    monthsToShow={1}
                />
                {(this.props.presets || this.props.shouldRenderDefaultPresets) && (
                    <DateRangePresets
                        presets={this.getPresets()}
                        rangeStart={rangeStart}
                        rangeEnd={rangeEnd}
                        onPresetClick={this.handlePresetClick}
                    />
                )}
                {this.props.children}
            </div>
        );
    };

    togglePopover = (e, editDate) => {
        e.stopPropagation();
        if (!editDate || !this.state.isPopoverVisible) {
            this.setState({
                isPopoverVisible: editDate ? true : !this.state.isPopoverVisible,
                editDate: editDate || null
            });
        }
    };

    handleStartDateClick = (e) => this.togglePopover(e, 'start');

    handleEndDateClick = (e) => this.togglePopover(e, 'end');

    handlePresetClick = (range) => {
        this.updateCurrentMonth(range);
        this.handleRangeChange(range);
    };

    updateCurrentMonth = (range) => {
        let currentMonth = range.rangeStart || range.rangeEnd;
        if (currentMonth && !currentMonth.isDateInvalid) {
            if (currentMonth === range.rangeStart) {
                currentMonth = getDateFromDifference(range.rangeStart, 'month', 1);
            }
            if (this.dateRangePicker) {
                this.dateRangePicker.updateCurrentMonth(currentMonth);
            }
            if (this.dateRangePickerXs) {
                this.dateRangePickerXs.updateCurrentMonth(currentMonth);
            }
        }
    };

    handleRangeChange = (range, isDateInput = false) => {
        const {
            value,
            onRangeSelect,
            dateType,
            shouldCloseOnRangeSelect,
            convertBeforeSelect
        } = this.props;

        if (onRangeSelect) {
            let rangeStart = value ? value.rangeStart : null;
            let rangeEnd = value ? value.rangeEnd : null;
            if (range.rangeStart === null || range.rangeStart) {
                rangeStart = dateType !== 'object'
                    ? convertBeforeSelect(range.rangeStart, this.props)
                    : range.rangeStart;
            }
            if (range.rangeEnd === null || range.rangeEnd) {
                rangeEnd = dateType !== 'object'
                    ? convertBeforeSelect(range.rangeEnd, this.props, false)
                    : range.rangeEnd;
            }
            onRangeSelect({ rangeStart, rangeEnd });
        }
        this.setState({ editDate: null });
        if (isDateInput) {
            this.updateCurrentMonth(range);
        } else if (shouldCloseOnRangeSelect && range.rangeStart && range.rangeEnd) {
            this.setState({ isPopoverVisible: false });
        }
    };

    handleClickOutside = (e) => {
        if (!this.dateInputRef || !this.dateInputRef.contains(e.target)) {
            e.stopPropagation();
            this.setState({ isPopoverVisible: false });
        }
    };

    handlePopoverShow = (popover) => {
        document.addEventListener('scrollChanged', popover.handlePositionUpdate);
    };

    handlePopoverHide = (popover) => {
        document.removeEventListener('scrollChanged', popover.handlePositionUpdate);
    };

    saveInputRef = (c) => { this.dateInputRef = c; };

    render() {
        if (!this.state.value) {
            return null;
        }
        const { rangeStart, rangeEnd } = this.state.value;
        let popupRangeStart = rangeStart && !rangeStart.isDateInvalid ? rangeStart : null;
        let popupRangeEnd = rangeEnd && !rangeEnd.isDateInvalid ? rangeEnd : null;
        let hoveredDate = null;
        if (this.state.editDate) {
            if (this.state.editDate === 'start') {
                hoveredDate = popupRangeStart;
                popupRangeStart = popupRangeEnd;
            } else {
                hoveredDate = popupRangeEnd;
            }
            popupRangeEnd = null;
        }
        const isClearable = this.props.isClearable && (!!this.state.value.rangeStart || !!this.state.value.rangeEnd);
        return (
            <Popover
                contentClassName={this.props.popoverClassName}
                content={this.state.isPopoverVisible && this.getContent(popupRangeStart, popupRangeEnd, hoveredDate)}
                autoPlacement={this.props.autoPlacement}
                autoPlacementSize={this.props.autoPlacementSize}
                placement={this.props.placement}
                alignment={this.props.alignment}
                isVisible={this.state.isPopoverVisible}
                onClickOutside={this.handleClickOutside}
                onShow={this.handlePopoverShow}
                onHide={this.handlePopoverHide}
                onUnmount={this.handlePopoverHide}
            >
                <DateRangeInput
                    saveRef={this.saveInputRef}
                    className={this.props.inputClassName}
                    startDate={rangeStart}
                    startDatePlaceholder={this.props.startDatePlaceholder}
                    endDate={rangeEnd}
                    endDatePlaceholder={this.props.endDatePlaceholder}
                    name={this.props.name}
                    onIconClick={this.togglePopover}
                    onRangeChange={this.handleRangeChange}
                    onStartDateClick={this.handleStartDateClick}
                    onEndDateClick={this.handleEndDateClick}
                    isActive={this.state.isPopoverVisible}
                    isDisabled={this.props.disabled}
                    isInvalid={this.props.invalid}
                    isClearable={isClearable}
                    datesSeparator={<span className="icon icon-arrow-right sten-date-range-popup__icon" />}
                    icon={<span className="icon icon-calendar sten-date-range-popup__icon" />}
                    clearIcon={<span className="icon icon-close sten-date-range-popup__icon" />}
                    dateFormat={TimeHelper.getFormat()}
                />
            </Popover>
        );
    }
}

const DateShape = {
    day: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired
};

DateRangePopover.propTypes = {
    alignment: PropTypes.oneOf(['start', 'center', 'end']),
    alwaysShow6Weeks: PropTypes.bool,
    autoPlacement: PropTypes.bool,
    autoPlacementSize: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number
    }),
    children: PropTypes.node,
    contentClassName: PropTypes.string,
    convertBeforeSelect: PropTypes.func,
    dateFormat: PropTypes.string,
    dateType: PropTypes.oneOf(['object', 'date', 'string']),
    disabled: PropTypes.bool,
    disableMonthsOutOfRange: PropTypes.bool,
    endDatePlaceholder: PropTypes.string,
    hideOutsideDays: PropTypes.bool,
    hoverTracking: PropTypes.oneOf(['default', 'active', 'disabled']),
    initialMonth: PropTypes.shape({ month: PropTypes.number, year: PropTypes.number }),
    inputClassName: PropTypes.string,
    invalid: PropTypes.bool,
    invalidDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isClearable: PropTypes.bool,
    isUTC: PropTypes.bool,
    maxDate: PropTypes.oneOfType([PropTypes.shape(DateShape), PropTypes.object, PropTypes.number, PropTypes.string]),
    maxRangeDays: PropTypes.number,
    minDate: PropTypes.oneOfType([PropTypes.shape(DateShape), PropTypes.object, PropTypes.number, PropTypes.string]),
    minRangeDays: PropTypes.number,
    name: PropTypes.string,
    onRangeSelect: PropTypes.func.isRequired,
    onRangeStartSelect: PropTypes.func,
    placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    popoverClassName: PropTypes.string,
    presets: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        title: PropTypes.string,
        startDate: PropTypes.oneOfType(
            [PropTypes.shape(DateShape), PropTypes.object, PropTypes.number, PropTypes.string]
        ),
        endDate: PropTypes.oneOfType(
            [PropTypes.shape(DateShape), PropTypes.object, PropTypes.number, PropTypes.string]
        )
    })),
    presetDateType: PropTypes.oneOf(['object', 'date', 'string']),
    renderDay: PropTypes.func,
    renderWeekDays: PropTypes.func,
    shouldCloseOnRangeSelect: PropTypes.bool,
    shouldRenderDefaultPresets: PropTypes.bool,
    startDatePlaceholder: PropTypes.string,
    today: PropTypes.shape(DateShape),
    value: PropTypes.shape({
        rangeEnd: PropTypes.oneOfType(
            [PropTypes.shape(DateShape), PropTypes.object, PropTypes.number, PropTypes.string]
        ),
        rangeStart: PropTypes.oneOfType(
            [PropTypes.shape(DateShape), PropTypes.object, PropTypes.number, PropTypes.string]
        )
    }),
    weekStart: PropTypes.oneOf(['Monday', 'Sunday'])
};

DateRangePopover.defaultProps = {
    alignment: 'start',
    alwaysShow6Weeks: undefined,
    autoPlacement: false,
    autoPlacementSize: null,
    children: null,
    contentClassName: '',
    convertBeforeSelect: (date, props, isStart = true) => {
        return date && date.isDateInvalid
            ? props.invalidDate
            : convertObjToDate(date, props.isUTC, isStart, props.dateType);
    },
    dateFormat: 'DD/MM/YYYY',
    dateType: 'string',
    disabled: false,
    disableMonthsOutOfRange: undefined,
    endDatePlaceholder: translate('DATE_RANGE_POPUP.END_DATE_PLACEHOLDER'),
    hideOutsideDays: undefined,
    hoverTracking: undefined,
    initialMonth: undefined,
    inputClassName: '',
    invalid: false,
    invalidDate: 'Invalid Date',
    isClearable: false,
    isUTC: false,
    maxDate: null,
    maxRangeDays: undefined,
    minDate: null,
    minRangeDays: undefined,
    name: '',
    onRangeStartSelect: undefined,
    placement: 'bottom',
    popoverClassName: '',
    presets: null,
    presetDateType: 'string',
    renderDay: undefined,
    renderWeekDays: undefined,
    shouldCloseOnRangeSelect: true,
    shouldRenderDefaultPresets: false,
    startDatePlaceholder: translate('DATE_RANGE_POPUP.START_DATE_PLACEHOLDER'),
    today: undefined,
    value: null,
    weekStart: undefined
};
