/* utils */
import { t } from 'utils/i18n/i18n-model';
import getFontSize from 'utils/helpers/cached-font-size';

export const chartConfigBaseColors = {
    colorChartBg: '#0f2e39',
    colorAreaBoundaryFill: 'rgba(12, 156, 18, 0.2)',
    colorXAxisGridLine: 'rgba(255, 255, 255, 0.1)',
    colorSeriesGradient: 'rgba(0, 0, 0, 0.3)'
};

export const plotOptions = {
    series: {
        cursor: 'pointer',
        marker: {
            states: {
                hover: {
                    radiusPlus: 4
                }
            }
        }
    }
};

export const chartLabels = {
    vesselSpeedLabels: {
        title: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.VESSEL_SPEED_KN'),
        reported: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.REPORTED_SPEED'),
        external: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.AVERAGE_SOG'),
        charterparty: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.CHARTERPARTY_SPEED')
    },
    windSpeedLabels: {
        title: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.WIND_SPEED_KN'),
        reported: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.REPORTED_WIND_SPEED'),
        external: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.EXTERNAL_WIND_SPEED'),
        charterparty: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.CHARTERPARTY_WIND_SPEED')
    },
    waveHeightLabels: {
        title: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.WAVE_HEIGHT_M'),
        reported: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.REPORTED_HEIGHT'),
        external:
            t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.WEATHER_PROVIDER_REPORTED_HEIGHT'),
        charterparty:
            t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.CHARTERPARTY_AGREEMENT_HEIGHT')
    },
    hfoLabels: {
        title: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.HFO_CONSUMPTION'),
        calculated: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.CALCULATED_CONSUMPTION'),
        charterparty: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.CP_BUDGET')
    },
    mdoLabels: {
        title: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.MDO_CONSUMPTION'),
        calculated: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.CALCULATED_CONSUMPTION'),
        charterparty: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.CP_BUDGET')
    },
    lngLabels: {
        title: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.LNG_CONSUMPTION'),
        calculated: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.CALCULATED_CONSUMPTION'),
        charterparty: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTS.CP_BUDGET')
    }
};

export const chartColors = {
    hfoColors: {
        calculated: '#F48003',
        charterparty: '#0C9C12'
    },
    lngColors: {
        calculated: '#F48003',
        charterparty: '#0C9C12'
    },
    mdoColors: {
        calculated: '#FF8F8F',
        charterparty: '#0C9C12'
    },
    waveHeightColors: {
        reported: '#5D6CBE',
        external: '#9575CD',
        charterparty: '#BA68C8'
    },
    windSpeedColors: {
        reported: '#02A0E7',
        external: '#88DAFF',
        charterparty: '#28FFC9'
    },
    vesselSpeedColors: {
        reported: '#0C9C12',
        external: '#2FA69A',
        charterparty: '#C7FF85'
    }
};


export const rem = getFontSize();
export const fsm = rem / 16;

export const getTitle = (fsm) => ({
    style: {
        color: 'white',
        fontSize: '0.75rem',
        fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
        fontWeight: 700
    },
    useHTML: true,
    align: 'high',
    rotation: 0,
    margin: 20 * fsm,
    reserveSpace: false,
    y: -10 * fsm,
    textAlign: 'left'
});

export const getOptions = chartMin => {
    return {
        plotOptions,
        xAxis: { min: chartMin }
    };
};
