import MtApi from './mt-api';

class CurrencyService {
    getSupportedCurrencies() {
        return MtApi.get('currency/supported_currencies')
            .then(response => response.data, () => null);
    }
}

export default new CurrencyService();
