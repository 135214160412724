import React from 'react';
import PropTypes from 'prop-types';
/* types */
import { TFuelData, TFuelTypeQuantity } from './fuel-data-table-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { formatNumber, filterObjectKeys } from 'utils/helpers/info-helper';
import { sortByProp, mapArrayByProp } from 'utils/helpers/array-helper';
/* components */
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
import Input from 'components/input/input';
import Select from 'components/select/select';
import Validation from 'components/validation/validation';
/* styles */
import './fuel-data-table.scss';

const defaultValidations = { numeric: { min: 0, max: 20000 } };

const emptyArray = [];

class FuelDataTable extends React.Component {
    state = {
        prevFuelTypeOptions: null,
        fuelTypeMap: null,
        prevRowOptions: emptyArray,
        rowOptionMap: emptyArray
    };

    static getDerivedStateFromProps(props, state) {
        if (props.fuelTypeOptions !== state.prevFuelTypeOptions || props.rowOptions !== state.prevRowOptions) {
            return {
                fuelTypeMap: mapArrayByProp(props.fuelTypeOptions, 'Id'),
                prevFuelTypeOptions: props.fuelTypeOptions,
                rowOptionMap: mapArrayByProp(props.rowOptions, 'Id'),
                prevRowOptions: props.rowOptions
            };
        }
        return null;
    }

    onChangeMap = {};

    handleValueChange = (itemIndex, fuel) => (value) => {
        if (this.props.onChange) {
            const rows = this.props.data.rows.slice();
            rows[itemIndex].FuelTypeQuantities[fuel.Id] = { ...fuel, Quantity: value.length > 0 ? value : null };
            this.props.onChange({ ...this.props.data, rows });
        }
    };

    handleFuelTypeChange = (selectedFuelTypes) => {
        if (this.props.onChange) {
            const fuelTypes = [...selectedFuelTypes].sort(sortByProp('Sequence'));
            const fuelTypeMap = mapArrayByProp(fuelTypes, 'Id');
            const rows = this.props.data.rows.map(row => ({
                ...row,
                FuelTypeQuantities: filterObjectKeys(row.FuelTypeQuantities, key => !!fuelTypeMap[key])
            }));
            this.props.onChange({ rows, fuelTypes });
        }
    };

    handleRowOptionsChange = (selectedRowOptions) => {
        if (this.props.onChange) {
            const rowsMap = mapArrayByProp(this.props.data.rows, 'Id');
            const rows = [...selectedRowOptions].sort(sortByProp('Sequence')).map(ro => {
                if (rowsMap[ro.Id]) {
                    return rowsMap[ro.Id];
                }
                return {
                    Id: ro.Id,
                    Name: ro.Name,
                    FuelTypeQuantities: {}
                };
            });
            this.props.onChange({ ...this.props.data, rows });
        }
    }

    getValidations = (item) => {
        if (!this.props.fieldValidations) {
            return defaultValidations;
        }
        return {
            ...defaultValidations,
            ...this.props.fieldValidations.Default,
            ...this.props.fieldValidations[item.Id]
        };
    };

    getUnit = (item, fuel) => {
        const { fuelTypeMap } = this.state;
        const rowOption = this.props.areUnitsShown
            ? this.props.rowOptions.find(ro => ro.Id === item.Id)
            : null;
        let unit = '';
        if (rowOption && rowOption.Unit) {
            unit = rowOption.Unit;
        } else if (fuelTypeMap && fuelTypeMap[fuel.Id] && fuelTypeMap[fuel.Id].Unit) {
            unit = fuelTypeMap[fuel.Id].Unit;
        }
        return unit;
    };

    renderValidationInput = (item, itemIndex, fuel) => {
        const unit = this.getUnit(item, fuel);
        const value = item.FuelTypeQuantities[fuel.Id]?.Quantity || null;
        if (!this.onChangeMap[`${itemIndex}${fuel.Id}`]) {
            this.onChangeMap[`${itemIndex}${fuel.Id}`] = this.handleValueChange(itemIndex, fuel);
        }
        const isDisabled = this.props.getFieldDisabled ? this.props.getFieldDisabled(item, fuel) : false;

        return (
            <Validation.Wrapper
                validate={!isDisabled}
                validations={this.getValidations(item)}
                hintsOnHover
                hintsOnTop
                maxHintLines={3}
            >
                <Input
                    disabled={isDisabled}
                    className="sten-input--sm"
                    name={`${this.props.name}.${item.Id}.${fuel.Id}`}
                    value={value !== null ? `${value}` : value}
                    onChange={this.onChangeMap[`${itemIndex}${fuel.Id}`]}
                    suffix={unit}
                />
            </Validation.Wrapper>
        );
    };

    renderValue = (item, fuel) => {
        const unit = this.getUnit(item, fuel);
        const value = item.FuelTypeQuantities[fuel.Id]?.Quantity || null;
        if (value !== null) {
            return `${formatNumber(value, 2)}${unit ? ` ${unit}` : ''}`;
        }
        return '-';
    };

    render() {
        const {
            fuelTypeOptions,
            fuelTypeValidations,
            rowOptions,
            rowOptionsLabel,
            rowOptionsLabelKey,
            rowOptionProps,
            rowOptionValidations,
            data,
            fixedHeaderTableProps,
            name,
            isEditing
        } = this.props;
        const { rowOptionMap } = this.state;
        const selectedRowOptions = data.rows.map(row => rowOptionMap[row.Id] || row);
        return (
            <React.Fragment>
                {isEditing && (
                    <div className="sten-content__section">
                        <div className="row">
                            {rowOptions && (
                                <div className="col-12">
                                    {rowOptionsLabel && <label className="label">{rowOptionsLabel}</label>}
                                    <Validation.Wrapper
                                        validations={rowOptionValidations}
                                        hintsOnHover
                                        hintsOnTop
                                        maxHintLines={3}
                                    >
                                        <Select
                                            clearable
                                            name={`${name}.RowOptions`}
                                            onChange={this.handleRowOptionsChange}
                                            multiple
                                            value={selectedRowOptions}
                                            options={rowOptions}
                                            valueKey="Id"
                                            labelKey={rowOptionsLabelKey}
                                            {...rowOptionProps}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            )}
                            {fuelTypeOptions && (
                                <div className="col-12">
                                    <label className="label">{t('GLOBAL.FUEL_TYPES')}</label>
                                    <Validation.Wrapper
                                        validations={fuelTypeValidations}
                                        hintsOnHover
                                        hintsOnTop
                                        maxHintLines={3}
                                    >
                                        <Select
                                            clearable
                                            name={`${name}.FuelTypes`}
                                            onChange={this.handleFuelTypeChange}
                                            multiple
                                            value={data.fuelTypes}
                                            options={fuelTypeOptions}
                                            valueKey="Id"
                                            labelKey="Name"
                                        />
                                    </Validation.Wrapper>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {data && data.fuelTypes.length > 0 && data.rows.length > 0 && (
                    <FixedHeaderTable
                        className="sten-fuel-data-table"
                        contentStyle={{ minWidth: `${12 + data.fuelTypes.length * 6.66}rem` }}
                        {...fixedHeaderTableProps}
                        withHeaderColumn
                    >
                        <table className="sten-table sten-table--xs">
                            <thead>
                                <tr>
                                    <th className="text-secondary">{t('GLOBAL.FUEL')}</th>
                                    {data.fuelTypes.map(fuel => (
                                        <th
                                            key={fuel.Id}
                                            className="text-secondary"
                                            style={{ width: `${100 / data.fuelTypes.length}%` }}
                                        >
                                            {fuel.Name}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data.rows.map((item, itemIndex) => (
                                    <tr key={item.Id}>
                                        <th className="text-secondary">{item.Name}</th>
                                        {data.fuelTypes.map((fuel) => (
                                            <td key={fuel.Id} className={isEditing ? 'sten-table__td--has-input' : ''}>
                                                {isEditing
                                                    ? this.renderValidationInput(item, itemIndex, fuel)
                                                    : this.renderValue(item, fuel) }
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </FixedHeaderTable>
                )}
            </React.Fragment>
        );
    }
}

FuelDataTable.propTypes = {
    areUnitsShown: PropTypes.bool,
    data: PropTypes.shape({
        fuelTypes: PropTypes.arrayOf(TFuelTypeQuantity),
        rows: PropTypes.arrayOf(TFuelData)
    }).isRequired,
    fieldValidations: PropTypes.objectOf(PropTypes.any),
    fuelTypeOptions: PropTypes.arrayOf(TFuelTypeQuantity),
    fuelTypeValidations: PropTypes.objectOf(PropTypes.any),
    fixedHeaderTableProps: PropTypes.objectOf(PropTypes.any),
    getFieldDisabled: PropTypes.func,
    isEditing: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    rowOptions: PropTypes.arrayOf(PropTypes.object),
    rowOptionsLabel: PropTypes.string,
    rowOptionsLabelKey: PropTypes.string,
    rowOptionProps: PropTypes.objectOf(Select.propTypes),
    rowOptionValidations: PropTypes.objectOf(PropTypes.any)
};

FuelDataTable.defaultProps = {
    areUnitsShown: false,
    fieldValidations: null,
    fixedHeaderTableProps: null,
    fuelTypeOptions: null,
    fuelTypeValidations: null,
    getFieldDisabled: undefined,
    isEditing: false,
    name: '',
    onChange: undefined,
    rowOptionProps: null,
    rowOptions: null,
    rowOptionsLabel: '',
    rowOptionsLabelKey: 'Name',
    rowOptionValidations: null
};

export default FuelDataTable;
