export const ActionTypes = {
    HOME_SET_MOUNTED: 'HOME_SET_MOUNTED',
    HOME_RESET_ALL: 'HOME_RESET_ALL',
    HOME_GET_VESSELS: 'HOME_GET_VESSELS',
    HOME_SET_VESSELS: 'HOME_SET_VESSELS',
    HOME_GET_PORTS: 'HOME_GET_PORTS',
    HOME_SET_PORTS: 'HOME_SET_PORTS',
    HOME_SET_MAP_VIEW_PROPS: 'HOME_SET_MAP_VIEW_PROPS',
    HOME_GET_SELECTED_VESSEL: 'HOME_GET_SELECTED_VESSEL',
    HOME_GET_SELECTED_VESSEL_BASE: 'HOME_GET_SELECTED_VESSEL_BASE',
    HOME_SET_SELECTED_VESSEL_BASE: 'HOME_SET_SELECTED_VESSEL_BASE',
    HOME_SET_SELECTED_VESSEL_DETAILS: 'HOME_SET_SELECTED_VESSEL_DETAILS',
    HOME_SET_SELECTED_VESSEL_IMAGES: 'HOME_SET_SELECTED_VESSEL_IMAGES',
    HOME_SET_SELECTED_VESSEL_BUDGETED_CONSUMPTION: 'HOME_SET_SELECTED_VESSEL_BUDGETED_CONSUMPTION',
    HOME_GET_SELECTED_VESSEL_POSITION: 'HOME_GET_SELECTED_VESSEL_POSITION',
    HOME_SET_SELECTED_VESSEL_POSITION: 'HOME_SET_SELECTED_VESSEL_POSITION',
    HOME_GET_SELECTED_VESSEL_POSITION_AND_VOYAGE: 'HOME_GET_SELECTED_VESSEL_POSITION_AND_VOYAGE',
    HOME_SET_SELECTED_VESSEL_SENSOR_DATA: 'HOME_SET_SELECTED_VESSEL_SENSOR_DATA',
    HOME_GET_SELECTED_VESSEL_CURRENT_VOYAGE: 'HOME_GET_SELECTED_VESSEL_CURRENT_VOYAGE',
    HOME_SET_SELECTED_VESSEL_VOYAGES: 'HOME_SET_SELECTED_VESSEL_VOYAGES',
    HOME_GET_SELECTED_VESSEL_NOTIFICATIONS: 'HOME_GET_SELECTED_VESSEL_NOTIFICATIONS',
    HOME_SET_SELECTED_VESSEL_NOTIFICATIONS: 'HOME_SET_SELECTED_VESSEL_NOTIFICATIONS',
    HOME_SET_SELECTED_VESSEL_NEXT_POINT: 'HOME_SET_SELECTED_VESSEL_NEXT_POINT',
    HOME_SET_SELECTED_VESSEL_RELATIVE_DETAILS: 'HOME_SET_SELECTED_VESSEL_RELATIVE_DETAILS',
    HOME_SET_SELECTED_VESSEL_QUESTIONNAIRE_LIST: 'HOME_SET_SELECTED_VESSEL_QUESTIONNAIRE_LIST',
    HOME_GET_SELECTED_VESSEL_VOYAGE_ACTIVITY: 'HOME_GET_SELECTED_VESSEL_VOYAGE_ACTIVITY',
    HOME_REMOVE_SELECTED_VESSEL_NOTIFICATIONS: 'HOME_REMOVE_SELECTED_VESSEL_NOTIFICATIONS',
    HOME_REMOVE_SELECTED_VESSEL: 'HOME_REMOVE_SELECTED_VESSEL',
    HOME_GET_SELECTED_VESSEL_VOYAGE_BY_ID: 'HOME_GET_SELECTED_VESSEL_VOYAGE_BY_ID',
    HOME_SET_SELECTED_VOYAGE: 'HOME_SET_SELECTED_VOYAGE',
    HOME_GET_IMPROVISED_VOYAGE: 'HOME_GET_IMPROVISED_VOYAGE',
    HOME_SET_IMPROVISED_VOYAGE: 'HOME_SET_IMPROVISED_VOYAGE',
    HOME_SET_SELECTED_VOYAGE_ROUTE: 'HOME_SET_SELECTED_VOYAGE_ROUTE',
    HOME_SET_SELECTED_VOYAGE_ROUTE_POINTS: 'HOME_SET_SELECTED_VOYAGE_ROUTE_POINTS',
    HOME_SET_SELECTED_VOYAGE_AIS_POINTS: 'HOME_SET_SELECTED_VOYAGE_AIS_POINTS',
    HOME_SET_LATEST_VOYAGE_REPORT: 'HOME_SET_LATEST_VOYAGE_REPORT',
    HOME_SET_VOYAGE_CARGO: 'HOME_SET_VOYAGE_CARGO',
    HOME_SET_VOYAGE_ITINERARY: 'HOME_SET_VOYAGE_ITINERARY',
    HOME_SET_VOYAGE_ACTIVITY: 'HOME_SET_VOYAGE_ACTIVITY',
    HOME_SET_VOYAGE_CHARTERER: 'HOME_SET_VOYAGE_CHARTERER',
    HOME_SET_SELECTED_VOYAGE_REPORTS: 'HOME_SET_SELECTED_VOYAGE_REPORTS',
    HOME_SET_VOYAGE_FOOTPRINT: 'HOME_SET_VOYAGE_FOOTPRINT',
    HOME_SET_VOYAGE_EARNINGS: 'HOME_SET_VOYAGE_EARNINGS',
    HOME_SET_VOYAGE_VMS_FOOTPRINT: 'HOME_SET_VOYAGE_VMS_FOOTPRINT',
    HOME_SET_VESSEL_CII_YEARLY_RATING: 'HOME_SET_VESSEL_CII_YEARLY_RATING',
    HOME_SET_SHOULD_CENTER_ACTIVE_ELEMENT: 'HOME_SET_SHOULD_CENTER_ACTIVE_ELEMENT',
    HOME_GET_VOYAGE_EU_ETS: 'HOME_GET_VOYAGE_EU_ETS',
    HOME_SET_VOYAGE_EU_ETS: 'HOME_SET_VOYAGE_EU_ETS'
};

export function setMounted(dispatch, isMounted) {
    dispatch({
        type: ActionTypes.HOME_SET_MOUNTED,
        isMounted
    });
}

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.HOME_RESET_ALL
    });
}

export function getVessels(dispatch) {
    dispatch({
        type: ActionTypes.HOME_GET_VESSELS
    });
}

export function getVoyageActivity(dispatch) {
    dispatch({
        type: ActionTypes.HOME_GET_SELECTED_VESSEL_VOYAGE_ACTIVITY
    });
}

export function getPorts(dispatch, params) {
    dispatch({
        type: ActionTypes.HOME_GET_PORTS,
        params
    });
}

export function setMapViewProps(dispatch, mapViewProps) {
    dispatch({
        type: ActionTypes.HOME_SET_MAP_VIEW_PROPS,
        mapViewProps
    });
}

export function getSelectedVessel(dispatch, vesselId, voyageId = null) {
    dispatch({
        type: ActionTypes.HOME_GET_SELECTED_VESSEL,
        vesselId,
        voyageId
    });
}

export function getSelectedVesselBase(dispatch) {
    dispatch({
        type: ActionTypes.HOME_GET_SELECTED_VESSEL_BASE
    });
}

export function removeSelectedVessel(dispatch) {
    dispatch({
        type: ActionTypes.HOME_REMOVE_SELECTED_VESSEL
    });
}

export function getSelectedVesselNotifications(dispatch) {
    dispatch({
        type: ActionTypes.HOME_GET_SELECTED_VESSEL_NOTIFICATIONS
    });
}

export function getCurrentVoyage(dispatch, selectedVesselId) {
    dispatch({
        type: ActionTypes.HOME_GET_SELECTED_VESSEL_CURRENT_VOYAGE,
        selectedVesselId
    });
}

export function getVoyageById(dispatch, voyageId) {
    dispatch({
        type: ActionTypes.HOME_GET_SELECTED_VESSEL_VOYAGE_BY_ID,
        voyageId
    });
}

export function getImprovisedVoyage(dispatch, range) {
    dispatch({
        type: ActionTypes.HOME_GET_IMPROVISED_VOYAGE,
        range
    });
}

export function getVoyageEuEts(dispatch, year) {
    dispatch({
        type: ActionTypes.HOME_GET_VOYAGE_EU_ETS,
        year
    });
}

export function setShouldCenterActiveElement(dispatch, shouldCenterActiveElement) {
    dispatch({
        type: ActionTypes.HOME_SET_SHOULD_CENTER_ACTIVE_ELEMENT,
        shouldCenterActiveElement
    });
}
