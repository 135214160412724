import React from 'react';
import PropTypes from 'prop-types';
/* styles */
import './equalize-tables.scss';

class EqualizeTables extends React.PureComponent {
    componentDidMount() {
        this.equalizeInterval = setInterval(this.equalize, 50);
        this.resizeListener = window.addEventListener('resize', this.equalize.bind(this, true));
    }

    componentWillUnmount() {
        clearInterval(this.equalizeInterval);
        window.removeEventListener('resize', this.resizeListener);
    }

    getMaxTdWidthPerCell(eachTdsWidth) {
        const maxWidthPerCell = [];
        for (let i = 0; i < eachTdsWidth.length; i++) {
            for (let j = 0; j < eachTdsWidth[i].length; j++) {
                if (!maxWidthPerCell[j] || eachTdsWidth[i][j] > maxWidthPerCell[j]) {
                    maxWidthPerCell[j] = eachTdsWidth[i][j];
                }
            }
        }
        return maxWidthPerCell;
    }

    equalize = isResize => {
        const tdsWidth = [];
        let totalCellsPerRow;
        const tablesInDom = this.tableWrapper ? this.tableWrapper.getElementsByTagName('table') : [];
        const totalTablesInDom = tablesInDom.length;

        if (tablesInDom && totalTablesInDom) {
            for (let i = 0; i < totalTablesInDom; i++) {
                const table = tablesInDom[i];
                tdsWidth.push([]);
                if (table.rows[0]) {
                    totalCellsPerRow = table.rows[0].cells.length;
                    for (let j = 0; j < totalCellsPerRow; j++) {
                        tdsWidth[i].push(tablesInDom[i].rows[0].cells[j].offsetWidth);
                    }
                }
            }

            const maxWidthPerCell = this.getMaxTdWidthPerCell(tdsWidth);

            for (let i = 0; i < totalCellsPerRow; i++) {
                Object.keys(tablesInDom).forEach((value, key) => {
                    if (tablesInDom[key].rows[0]) {
                        if (isResize && tablesInDom[key].rows[0].cells[i]) {
                            tablesInDom[key].rows[0].cells[i].style.removeProperty('min-width');
                        } else {
                            tablesInDom[key].rows[0].cells[i].style.minWidth = `${maxWidthPerCell[i]}px`;
                        }
                    }
                });
            }
        }
    };

    render() {
        let classNames = 'sten-equalize-tables';
        if (this.props.className) {
            classNames += ` ${this.props.className}`;
        }
        return (
            <div className={classNames} ref={(c) => { this.tableWrapper = c; }}>
                {this.props.children}
            </div>
        );
    }
}

EqualizeTables.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};

EqualizeTables.defaultProps = {
    className: ''
};

export default EqualizeTables;
