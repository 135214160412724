import { ActionTypes } from './fleet-average-speed-report-actions';

const initialState = {
    TotalAvgSpeedBallast: null,
    TotalAvgSpeedLaden: null,
    VesselSpeedData: [],
    NumberOfVessels: 0,
    sortOrder: 'ASC',
    sortProp: 'VesselTitle'
};

export default function energyManagementReportsFleetAverageSpeedReportReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ENERGY_MANAGEMENT_FLEET_AVERAGE_SPEED_REPORT_SET: {
        return {
            ...state,
            ...action.report
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_FLEET_AVERAGE_SPEED_REPORT_SET_SORT_PROP: {
        if (state.sortProp === action.sortProp) {
            let sortOrder;
            if (state.sortOrder === 'ASC') {
                sortOrder = 'DESC';
            } else {
                sortOrder = 'ASC';
            }
            return {
                ...state,
                sortOrder
            };
        }
        return {
            ...state,
            sortProp: action.sortProp,
            sortOrder: 'ASC'
        };
    }
    default:
        return state;
    }
}
