import { translate } from 'utils/i18n/i18n-model';

const tu = (key) => translate(`UNITS.${key}`);

export const rowProps = ['QuarterSelected', 'QuarterPrevious', 'QuarterDelta', 'QuarterDeltaPercentage',
    'YearToDateSelected', 'YearToDatePrevious', 'YearToDateDelta', 'YearToDateDeltaPercentage',
    'RollingSelected', 'RollingPrevious', 'RollingDelta', 'RollingDeltaPercentage'
];

const emissionConfig = { decimals: 0, type: 'number', numFmt: '# ### ##0' };

export const colProps = [
    { key: 'LadenPercentageBasedOnDistance', decimals: 1, type: 'number', numFmt: '0.0"%"', unit: tu('PERCENTAGE') },
    { key: 'BallastPercentageBasedOnDistance', decimals: 1, type: 'number', numFmt: '0.0"%"', unit: tu('PERCENTAGE') },
    { key: 'LadenPercentageBasedOnTime', decimals: 1, type: 'number', numFmt: '0.0"%"', unit: tu('PERCENTAGE') },
    { key: 'BallastPercentageBasedOnTime', decimals: 1, type: 'number', numFmt: '0.0"%"', unit: tu('PERCENTAGE') },
    { key: 'DaysInPortPercentage', decimals: 1, type: 'number', numFmt: '0.0"%"', unit: tu('PERCENTAGE') },
    { key: 'OffHireDays', decimals: 1, type: 'number', numFmt: '# ### ##0.0' },
    { key: 'BunkerData', decimals: 1, type: 'number', numFmt: '# ### ##0.0', isFuelData: true },
    { key: 'BunkerTotal', decimals: 1, type: 'number', numFmt: '# ### ##0.0' },
    { ...emissionConfig, key: 'Emissions.CO2', label: 'CO2' },
    { ...emissionConfig, key: 'Emissions.SOx', label: 'SOx' },
    { ...emissionConfig, key: 'Emissions.NOx', label: 'NOx' },
    { ...emissionConfig, key: 'Emissions.PM', label: 'PM', decimals: 1, numFmt: '0.0' }
];

export const fuelColWidth = { web: 7, xls: 12, pdf: 5 };

export const colWidths = {
    Value: { web: 20, xls: 36, pdf: 20 },
    LadenPercentageBasedOnDistance: { web: 6, xls: 12, pdf: 4 },
    BallastPercentageBasedOnDistance: { web: 6, xls: 12, pdf: 4 },
    LadenPercentageBasedOnTime: { web: 6, xls: 12, pdf: 4 },
    BallastPercentageBasedOnTime: { web: 6, xls: 12, pdf: 4 },
    DaysInPortPercentage: { web: 8, xls: 12, pdf: 4 },
    OffHireDays: { web: 7, xls: 12, pdf: 4 },
    BunkerTotal: { web: 7, xls: 13, pdf: 6 },
    CO2: { web: 7, xls: 12, pdf: 5 },
    SOx: { web: 6, xls: 12, pdf: 4 },
    NOx: { web: 6, xls: 12, pdf: 4 },
    PM: { web: 6, xls: 12, pdf: 4 }
};
