import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import TabSelector from 'components/tab-selector/tab-selector';
import CargoesList from './cargo-list/cargo-list';
import EmployedVesselsList from './employed-vessels-list/employed-vessels-list';
/* actions */
import { getEmployedVessels } from './employed-vessels-panel-actions';
/* styles */
import './employed-vessels-panel.scss';

export class EmployedVesselsPanel extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeTabId: 0
        };
    }

    componentDidMount() {
        this.props.getEmployedVessels();
    }

    getTabs() {
        return [
            {
                id: 0,
                title: translate('EMPLOYED_VESSELS.MY_CARGOES'),
                icon: 'icon-cargo'
            },
            {
                id: 1,
                title: translate('EMPLOYED_VESSELS.MY_VESSELS'),
                icon: 'icon-laden',
                disabled: this.props.employedVessels.length === 0
            }
        ];
    }

    setActiveTab = tabId => {
        this.setState({
            activeTabId: tabId
        });
    };

    render() {
        return (
            <div className="sten-employed-vessels-panel sten-content">
                <div className="sten-content__header">
                    <h1 className="sten-content__title flex-grow">
                        { translate('EMPLOYED_VESSELS.TITLE') }
                    </h1>
                    <div className="flex-shrink">
                        {this.props.onCloseClick && (
                            <button
                                className="btn-link icon icon-close"
                                onClick={this.props.onCloseClick}
                            />
                        )}
                    </div>
                </div>
                <div className="sten-content__body sten-employed-vessels-panel__body-fixed">
                    <TabSelector
                        tabItems={this.getTabs()}
                        activeTabId={this.state.activeTabId}
                        onChange={this.setActiveTab}
                    />
                </div>
                <div className="sten-content__body sten-employed-vessels-panel__body-content">
                    {this.state.activeTabId === 1
                        ? <EmployedVesselsList employedVessels={this.props.employedVessels} />
                        : <CargoesList />}
                </div>
            </div>
        );
    }
}

EmployedVesselsPanel.propTypes = {
    employedVessels: PropTypes.arrayOf(PropTypes.object).isRequired,
    getEmployedVessels: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        employedVessels: state.employedVesselsPanelReducer.employedVessels
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getEmployedVessels: () => getEmployedVessels(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EmployedVesselsPanel);
