export const ActionTypes = {
    GSM_RIGHT_SIDE_BAR_COLLAPSE: 'GSM_RIGHT_SIDE_BAR_COLLAPSE',
    GSM_RIGHT_SIDE_BAR_SET_ALL_ENTITIES: 'GSM_RIGHT_SIDE_BAR_SET_ALL_ENTITIES',
    GSM_RIGHT_SIDE_BAR_SET_ACTIVE_PANEL: 'GSM_RIGHT_SIDE_BAR_SET_ACTIVE_PANEL',

    GSM_RIGHT_SIDE_BAR_GET_VESSEL_LIST: 'GSM_RIGHT_SIDE_BAR_GET_VESSEL_LIST',

    GSM_RIGHT_SIDE_BAR_SET_TANKERS: 'GSM_RIGHT_SIDE_BAR_SET_TANKERS',
    GSM_RIGHT_SIDE_BAR_SET_FERRIES: 'GSM_RIGHT_SIDE_BAR_SET_FERRIES',
    GSM_RIGHT_SIDE_BAR_SET_DRILLS: 'GSM_RIGHT_SIDE_BAR_SET_DRILLS',
    GSM_RIGHT_SIDE_BAR_SET_FERRY_ROUTES: 'GSM_RIGHT_SIDE_BAR_SET_FERRY_ROUTES',
    GSM_RIGHT_SIDE_BAR_SET_OFFICES: 'GSM_RIGHT_SIDE_BAR_SET_OFFICES',

    GSM_RIGHT_SIDE_BAR_REMOVE_ALL_ENTITIES: 'GSM_RIGHT_SIDE_BAR_REMOVE_ALL_ENTITIES',
    GSM_RIGHT_SIDE_BAR_SET_CAROUSEL_IMAGES: 'GSM_RIGHT_SIDE_BAR_SET_CAROUSEL_IMAGES',
    GSM_RIGHT_SIDE_BAR_REMOVE_CAROUSEL_IMAGES: 'GSM_RIGHT_SIDE_BAR_REMOVE_CAROUSEL_IMAGES'
};

export function gsmMapRightSideBarCollapse(dispatch, expand) {
    dispatch({
        type: ActionTypes.GSM_RIGHT_SIDE_BAR_COLLAPSE,
        expand
    });
}

export function gsmSetActivePanel(dispatch, panelName, expand) {
    dispatch({
        type: ActionTypes.GSM_RIGHT_SIDE_BAR_SET_ACTIVE_PANEL,
        panelName,
        expand
    });
}

export function getVesselList(dispatch) {
    dispatch({
        type: ActionTypes.GSM_RIGHT_SIDE_BAR_GET_VESSEL_LIST
    });
}

export function removeAllEntities(dispatch) {
    dispatch({
        type: ActionTypes.GSM_RIGHT_SIDE_BAR_REMOVE_ALL_ENTITIES
    });
}

export function removeCarouselImages(dispatch) {
    dispatch({
        type: ActionTypes.GSM_RIGHT_SIDE_BAR_REMOVE_CAROUSEL_IMAGES
    });
}
