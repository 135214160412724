import React from 'react';
import PropTypes from 'prop-types';
/* helpers */
import { formatNumber, getSeverity } from 'utils/helpers/info-helper';

class EnergyManagementKPISegmentBasicTableCell extends React.PureComponent {
    getSeverityClass = (value) => {
        let className = '';
        if (value && !this.props.isCargo) {
            className += ` ${getSeverity({ 'text-danger': '[0,Infinity)' }, value)}`;
        }
        return className;
    };

    getFormattedValue = (value, unit, showPlus = false) => {
        const isEfficiency = this.props.isEnergyEfficiency;
        return value ? `${formatNumber(value, isEfficiency && unit !== '%' ? 2 : 1, showPlus)} ${unit}` : '-';
    };

    render() {
        return (
            <td>
                <h1 className={this.getSeverityClass(this.props.totalValue)}>
                    {this.getFormattedValue(this.props.totalValue, '%', true)}
                </h1>
            </td>
        );
    }
}

EnergyManagementKPISegmentBasicTableCell.propTypes = {
    isCargo: PropTypes.bool,
    isEnergyEfficiency: PropTypes.bool,
    prevValue: PropTypes.number,
    selectedRangeEnd: PropTypes.objectOf(PropTypes.any),
    selectedRangeOption: PropTypes.objectOf(PropTypes.any),
    totalValue: PropTypes.number,
    value: PropTypes.number
};

EnergyManagementKPISegmentBasicTableCell.defaultProps = {
    isCargo: false,
    isEnergyEfficiency: false,
    prevValue: null,
    selectedRangeEnd: null,
    selectedRangeOption: null,
    totalValue: null,
    value: null
};

export default EnergyManagementKPISegmentBasicTableCell;
