import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* actions */
import { toggleUserSetting } from '../../../../user-actions';
/* components */
import Positioner from 'components/positioner/positioner';
import VesselDistributionArea from './vessel-distribution-area';
/* styles */
import './vessel-distribution-areas.scss';

const dot = { min: 3, max: 9 };
const emptyDistribution = { VesselCount: 0, HistoricalDifference: 0 };

export class VesselDistributionAreas extends React.PureComponent {
    toggleArea = areaId => () => {
        this.props.toggleUserSetting('VesselDistributionAreas', areaId);
    };

    getDotSize(vesselCount, maxCount) {
        let diffSize = 0;
        if (maxCount && vesselCount) {
            diffSize = (vesselCount / maxCount) * (dot.max - dot.min);
        }
        return dot.min + diffSize;
    }

    renderArea = (area) => {
        const distributions = this.props.totalVesselDistribution
            && this.props.totalVesselDistribution.AreaDistributions;
        const maxCount = this.props.totalVesselDistribution && this.props.totalVesselDistribution.MaxCount;
        const distribution = distributions && distributions[area.AreaId]
            ? distributions[area.AreaId]
            : emptyDistribution;
        const dotSize = this.getDotSize(distribution.VesselCount, maxCount);
        const isActive = this.props.selectedDistributionAreas.indexOf(area.AreaId) > -1;
        const style = {};

        if (isActive) {
            style.marginTop = `-${dotSize / 2}rem`;
            style.marginLeft = `-${dotSize / 2}rem`;
        }

        return (
            <Positioner
                top={area.top}
                left={area.left}
                style={style}
                className="sten-vessel-distribution-area"
                onClick={this.toggleArea(area.AreaId)}
                key={area.AreaId}
            >
                <VesselDistributionArea
                    areaName={area.Name}
                    vesselCount={distribution.VesselCount}
                    historicalDiff={distribution.HistoricalDifference}
                    isActive={isActive}
                    dotSize={dotSize}
                />
            </Positioner>
        );
    };

    render() {
        if (this.props.positions && this.props.positions.length > 0) {
            return (
                <div className="sten-vessel-distribution-areas">{this.props.positions.map(this.renderArea)}</div>
            );
        }
        return null;
    }
}

VesselDistributionAreas.propTypes = {
    positions: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedDistributionAreas: PropTypes.arrayOf(PropTypes.number).isRequired,
    toggleUserSetting: PropTypes.func.isRequired,
    totalVesselDistribution: PropTypes.objectOf(PropTypes.any)
};

VesselDistributionAreas.defaultProps = {
    totalVesselDistribution: null
};

function mapStateToProps(state) {
    return {
        positions: state.vesselDistributionAreasReducer.positions,
        selectedDistributionAreas: state.userReducer.settings && state.userReducer.settings.VesselDistributionAreas,
        totalVesselDistribution: state.marketIntelReducer.totalVesselDistribution
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleUserSetting: (settingName, value, settingUpdated) =>
            toggleUserSetting(dispatch, settingName, value, settingUpdated)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VesselDistributionAreas);
