import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import moment from 'moment';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { getObjectProp, formatNumber, getClassName } from 'utils/helpers/info-helper';
import { areConditionsMet } from 'components/vessel-report/vessel-report-helpers';
import ReportHelper from 'utils/helpers/report-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* components */
import Menu, { MenuItem } from 'components/menu/menu';
import TableCell from './raw-reports-table-cell';
/* constants */
import { badges } from './raw-reports-table-constants';

class RawReportsTableRow extends React.PureComponent {
    getCellClass = (report, rowFormat, isSuspicious, isDeleted, isUpdated) => getClassName({
        'sten-raw-reports-table-row__cell--missing': report.IsMissing,
        'sten-raw-reports-table-row__cell--excluded': report.IsExcluded,
        'sten-raw-reports-table-row__cell--excluded-value':
            report.IsExcluded
            && ((report.ExclusionFields && report.ExclusionFields[rowFormat.key])
                || (rowFormat.key === 'ExclusionReasons'
                    && report.ExclusionReasons
                    && report.ExclusionReasons.length > 0)),
        'sten-raw-reports-table-row__cell--action': rowFormat.isActionCell,
        'sten-raw-reports-table-row__cell--deleted': isDeleted,
        'sten-raw-reports-table-row__cell--updated': isUpdated && !isDeleted,
        'sten-raw-reports-table-row__cell--suspicious': !rowFormat.isActionCell && isSuspicious,
        'sten-raw-reports-table-row__cell--other': rowFormat.type === 'other',
        'sten-raw-reports-table-row__cell--header':
            rowFormat.type === 'fuelTypes' || rowFormat.type === 'utilizationLabels'
    });

    openReportEdit = (report) => {
        this.props.router.updateQueryParams({
            add: {
                reportId: report.ReportId,
                reportType: report.ReportTypeId || report.ReportType,
                reportEdit: true
            }
        });
    };

    openVeracityModal = (report, openModal) => {
        const date = TimeHelper.getFormatted(report.ReportDate, { time: true, utc: true });
        const dateOffset = TimeHelper.getUTCOffsetString(report.ReportDateOffset);
        const errors = [
            ...(report.VeracityAbstractErrors ? report.VeracityAbstractErrors : []),
            ...(report.VeracityBunkerErrors ? report.VeracityBunkerErrors : [])
        ];
        const warnings = [
            ...(report.VeracityAbstractWarnings ? report.VeracityAbstractWarnings : []),
            ...(report.VeracityBunkerWarnings ? report.VeracityBunkerWarnings : [])
        ];
        const notSentMessage = !errors.length && !warnings.length
            ? t('RAW_REPORTS_TABLE.VERACITY_MODAL.NOT_SENT_MESSAGE')
            : null;

        const modalData = {
            title: t('RAW_REPORTS_TABLE.VERACITY_MODAL.TITLE'),
            subtitle: `${date}${dateOffset}`,
            errors,
            warnings,
            notSentMessage
        };
        openModal(modalData);
    };

    getBadges = (isMissing, isUpdated, isDeleted, isExcluded, isSuspicious) => (
        <div className="flex">
            {isMissing && badges.missing}
            {isUpdated && !isDeleted && badges.updated}
            {isExcluded && !isDeleted && badges.excluded}
            {isSuspicious && !isDeleted && badges.suspicious}
            {isDeleted && badges.deleted}
        </div>
    );

    renderSuspiciousValue = (value) => (
        <div className="flex flex-center flex-justify-space-between">
            <span>{value}</span>
            <span className="icon icon-info-circle" />
        </div>
    );

    renderIsSyncedWithVeracity = (report) => {
        const { value, hasErrors, hasWarnings, sentToVeracity } = ReportHelper.getSyncWithVeracityData(report);
        const iconClassname = getClassName('icon icon-info-circle sten-raw-reports-table-row__veracity-icon', {
            'sten-raw-reports-table-row__veracity-icon--error': hasErrors || hasWarnings
        });
        const className = hasErrors ? 'sten-raw-reports-table-row__cell--veracity-error' : '';
        return (
            <div className="sten-raw-reports-table-row__cell--justify-between">
                <span className={className}>{value}</span>
                {(hasErrors || hasWarnings || !sentToVeracity) && (
                    <span
                        className={iconClassname}
                        onClick={this.openVeracityModal.bind(this, report, this.props.openModal)}
                    />
                )}
            </div>
        );
    }

    renderCell = (rowFormat, deletedReports, updatedReports, report) => {
        const reportIdKey = report.ReportId || `M${report.MissingReportId}`;
        const suspiciousExplanation = getObjectProp(report.WrongReportsFields, rowFormat.key) || '';
        const exclusionTitle = getObjectProp(report.ExclusionFields, rowFormat.key) || '';
        const title = suspiciousExplanation || exclusionTitle;
        const key = getObjectProp(report, rowFormat.key);
        let value = '-';

        if (report.FuelTypes?.length !== 0) {
            const fuelCellWidth = `${this.props.cellWidth / report.FuelTypes.length}rem`;
            this.fuelCellStyle = { minWidth: fuelCellWidth, width: fuelCellWidth, maxWidth: fuelCellWidth };
        }
        if (!this.utilizationCellStyle) {
            const utilCellWidth = `${this.props.cellWidth / 4}rem`;
            this.utilizationCellStyle = { minWidth: utilCellWidth, width: utilCellWidth, maxWidth: utilCellWidth };
        }
        if (rowFormat && !report.IsMissing) {
            if (rowFormat.renderValue) {
                value = rowFormat.renderValue(report);
            } else if (rowFormat.type === 'fuelTypes') {
                return (
                    <React.Fragment key={reportIdKey}>
                        {report.FuelTypes.length !== 0
                            ? report.FuelTypes.map(fuelType => (
                                <TableCell
                                    colSpan={4}
                                    style={this.fuelCellStyle}
                                    title={title}
                                    className={this.getCellClass(report, rowFormat, !!suspiciousExplanation)}
                                    key={fuelType.Id}
                                    id={rowFormat.key}
                                >
                                    {fuelType.Name}
                                </TableCell>
                            )) : (
                                <TableCell colSpan={4} style={this.props.cellStyle} title={title}>-</TableCell>
                            )}
                    </React.Fragment>
                );
            } else if (rowFormat.type === 'fuelTypeQuantities') {
                return (
                    <React.Fragment key={reportIdKey}>
                        {report.FuelTypes.length !== 0
                            ? report.FuelTypes.map(fuelType => {
                                const quantity = key && key.FuelTypeQuantities[fuelType.Id]
                                    ? formatNumber(key.FuelTypeQuantities[fuelType.Id].Quantity, 2)
                                    : '-';
                                const suspiciousFuelTypeExplanation = getObjectProp(
                                    report.WrongReportsFields, `${rowFormat.key}.${fuelType.Id}`
                                ) || '';
                                return (
                                    <TableCell
                                        colSpan={4}
                                        style={this.fuelCellStyle}
                                        title={suspiciousFuelTypeExplanation || exclusionTitle}
                                        className={this.getCellClass(
                                            report, rowFormat, !!suspiciousFuelTypeExplanation
                                        )}
                                        key={fuelType.Id}
                                        id={rowFormat.key}
                                    >
                                        {suspiciousFuelTypeExplanation
                                            ? this.renderSuspiciousValue(quantity)
                                            : quantity
                                        }
                                    </TableCell>
                                );
                            }) : (
                                <TableCell colSpan={4} style={this.props.cellStyle} title={title}>-</TableCell>
                            )}
                    </React.Fragment>
                );
            } else if (rowFormat.type === 'utilizationLabels') {
                return (
                    <React.Fragment key={reportIdKey}>
                        {key.values.map((value, index) => (
                            <TableCell
                                key={`${index}/${rowFormat.key}/${reportIdKey}`} // eslint-disable-line
                                style={this.utilizationCellStyle}
                                colSpan={report.FuelTypes?.length !== 0
                                    ? report.FuelTypes.length : 1}
                                title={title}
                                className={this.getCellClass(report, rowFormat, !!suspiciousExplanation)}
                                id={rowFormat.key}
                            >
                                {rowFormat.utilizationLabel} {index + 1}
                            </TableCell>
                        ))}
                    </React.Fragment>
                );
            } else if (rowFormat.type === 'utilizationValues') {
                return (
                    <React.Fragment key={reportIdKey}>
                        {key.map((value, index) => (
                            <TableCell
                                key={`${index}/${rowFormat.key}/${reportIdKey}`} // eslint-disable-line
                                style={this.utilizationCellStyle}
                                colSpan={report.FuelTypes?.length !== 0
                                    ? report.FuelTypes.length : 1}
                                title={title}
                                className={this.getCellClass(report, rowFormat.key, !!suspiciousExplanation)}
                                id={rowFormat.key}
                            >
                                {value || '-'}
                            </TableCell>
                        ))}
                    </React.Fragment>
                );
            } else if (rowFormat.type === 'syncedWithVeracity') {
                return (
                    <TableCell
                        colSpan={report.FuelTypes?.length !== 0 ? report.FuelTypes.length * 4 : 4}
                        title={title}
                        className={this.getCellClass(report, rowFormat, !!suspiciousExplanation)}
                        key={reportIdKey}
                        id={rowFormat.key}
                        style={this.props.cellStyle}
                    >
                        {this.renderIsSyncedWithVeracity(report)}
                    </TableCell>
                );
            } else {
                value = ReportHelper.formatValue(report, rowFormat);
            }
        }
        if (suspiciousExplanation) {
            value = this.renderSuspiciousValue(value);
        }
        return (
            <TableCell
                colSpan={report.FuelTypes?.length !== 0 ? report.FuelTypes.length * 4 : 4}
                title={title}
                className={this.getCellClass(report, rowFormat, !!suspiciousExplanation)}
                key={reportIdKey}
                id={rowFormat.key}
                style={this.props.cellStyle}
            >
                {value}
            </TableCell>
        );
    };

    renderActionCell = (rowFormat, deletedReports, updatedReports, editableReports, report) => {
        const suspiciousExplanation = getObjectProp(report.WrongReportsFields, rowFormat.key) || '';
        const isDeleted = deletedReports.includes(report.ReportId);
        const isUpdated = updatedReports.includes(report.ReportId);
        const isSuspicious = report.WrongReportsFields && Object.keys(report.WrongReportsFields).length > 0;
        const isEditable = areConditionsMet(editableReports, report);
        let value = ReportHelper.formatValue(report, rowFormat);
        let reminderInfoText = t('SEND_REMINDER_MODAL.REMINDER_SEND_TITLE');
        if (!report.IsSendEnabled) {
            reminderInfoText = t('SEND_REMINDER_MODAL.REMINDER_DISABLED_INVALID_EMAIL');
        } else {
            const reminderDateDifference = report.ReminderDate
                ? moment().diff(moment(report.ReminderDate))
                : null;
            if (reminderDateDifference && reminderDateDifference < 24 * 60 * 60000) {
                reminderInfoText = t('SEND_REMINDER_MODAL.REMINDER_DISABLED');
            }
        }
        if (suspiciousExplanation) {
            value = (
                <div title={suspiciousExplanation}>
                    <div className="flex flex-row flex-center">
                        <span className="flex-shrink">{value}</span>
                        <div className="flex">
                            <span className="icon icon-info-circle" />
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <TableCell
                colSpan={report.FuelTypes?.length !== 0 ? report.FuelTypes.length * 4 : 4}
                className={this.getCellClass(report, rowFormat, !!suspiciousExplanation, isDeleted, isUpdated)}
                style={this.props.cellStyle}
                key={report.ReportId || `M${report.MissingReportId}`}
                id={rowFormat.key}
            >
                <div className="flex flex-center">
                    <div className="flex-grow">
                        {value}
                        {this.getBadges(report.IsMissing, isUpdated, isDeleted, report.IsExcluded, isSuspicious)}
                    </div>
                    {!isDeleted && !report.IsMissing && isEditable && (
                        <div className="flex-shrink">
                            <Menu
                                className="sten-raw-reports-table-row__menu"
                                collapseDown
                                stopPropagation
                            >
                                <MenuItem
                                    onClick={this.openReportEdit.bind(this, report)}
                                    icon="icon-edit"
                                    isDisabled={isDeleted}
                                >
                                    {t('GLOBAL.EDIT')}
                                </MenuItem>
                                <MenuItem
                                    icon="icon-delete"
                                    isDisabled={isDeleted}
                                    onClick={this.props.toggleDeleteModal.bind(this, report.ReportId)}
                                >
                                    {t('GLOBAL.DELETE')}
                                </MenuItem>
                            </Menu>
                        </div>
                    )}
                    {report.IsMissing && this.props.permissions && this.props.permissions.SendMissingReportReminder && (
                        <div className="flex-shrink">
                            <button
                                title={reminderInfoText}
                                className="btn-link icon icon-alert text-center"
                                onClick={this.props.toggleReminderModal.bind(this, report)}
                            />
                        </div>
                    )}
                </div>
            </TableCell>
        );
    };

    renderActionCells = memoize((
        reports,
        rowFormat,
        deletedReports,
        updatedReports,
        editableReports
    ) => {
        const renderActionsCellBound = this.renderActionCell
            .bind(this, rowFormat, deletedReports, updatedReports, editableReports);
        return reports.map(renderActionsCellBound, this);
    });

    renderCells = memoize((reports, rowFormat, deletedReports, updatedReports) => {
        const renderCellBound = this.renderCell.bind(this, rowFormat, deletedReports, updatedReports);
        return reports.map(renderCellBound, this);
    });

    render() {
        const {
            cellWidth,
            rowFormat,
            renderSliceStart,
            renderSliceEnd,
            reports,
            deletedReports,
            updatedReports,
            editableReports
        } = this.props;
        const reportsSlice = reports.slice(renderSliceStart, renderSliceEnd);
        const cellLeftWidth = `${renderSliceStart * cellWidth}rem`;
        const cellRightWidth = `${(reports.length - renderSliceEnd) * cellWidth}rem`;
        return (
            <React.Fragment>
                {renderSliceStart > 0 && (
                    <TableCell style={{ width: cellLeftWidth }} />
                )}
                {rowFormat.isActionCell
                    ? this.renderActionCells(reportsSlice, rowFormat, deletedReports, updatedReports, editableReports)
                    : this.renderCells(reportsSlice, rowFormat, deletedReports, updatedReports)
                }
                {renderSliceEnd < reports.length && (
                    <TableCell style={{ width: cellRightWidth }} />
                )}
            </React.Fragment>
        );
    }
}

RawReportsTableRow.propTypes = {
    cellStyle: PropTypes.objectOf(PropTypes.any),
    cellWidth: PropTypes.number,
    deletedReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    editableReports: PropTypes.arrayOf(PropTypes.object).isRequired,
    openModal: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any),
    renderSliceStart: PropTypes.number,
    renderSliceEnd: PropTypes.number,
    reports: PropTypes.arrayOf(PropTypes.any).isRequired,
    router: TRouter.isRequired,
    rowFormat: PropTypes.objectOf(PropTypes.any),
    toggleDeleteModal: PropTypes.func.isRequired,
    toggleReminderModal: PropTypes.func.isRequired,
    updatedReports: PropTypes.arrayOf(PropTypes.any).isRequired
};

RawReportsTableRow.defaultProps = {
    cellStyle: null,
    cellWidth: null,
    renderSliceStart: null,
    renderSliceEnd: null,
    rowFormat: null,
    permissions: null
};

export default withRouter(RawReportsTableRow);
