export const ActionTypes = {
    MAP_LOCATION_TOOLTIP_SET_COORDINATES: 'MAP_LOCATION_TOOLTIP_SET_COORDINATES',
    MAP_LOCATION_TOOLTIP_RESET: 'MAP_LOCATION_TOOLTIP_RESET'
};

export function setLocationTooltipCoordinates(dispatch, pixels, coordinates) {
    dispatch({
        type: ActionTypes.MAP_LOCATION_TOOLTIP_SET_COORDINATES,
        pixels,
        coordinates
    });
}

export function reset(dispatch) {
    dispatch({
        type: ActionTypes.MAP_LOCATION_TOOLTIP_RESET
    });
}
