/* ol */
import Feature from 'ol/Feature';
/* ol geometries */
import GeomPoint from 'ol/geom/Point';
import GeomMultiLineString from 'ol/geom/MultiLineString';
/* ol format */
import FormatWKT from 'ol/format/WKT';
/* helpers */
import { getOrExecProp } from 'utils/helpers/info-helper';
/* config */
import MapConfig from './configs/map';

const getPointFeature = (layerName, data, props) => {
    const coordinates = getOrExecProp(data, props.coordinates);
    if (coordinates && coordinates.length > 0) {
        const feature = new Feature({
            geometry: new GeomPoint(coordinates).transform(
                MapConfig.sourceProjection,
                MapConfig.targetProjection
            ),
            name: 'Point'
        });

        feature.set('layerName', layerName);
        feature.data = data;
        feature.props = props;
        feature.isHovered = false;

        return feature;
    }
    return null;
};

const getHeatMapFeature = (layerName, data, props) => {
    const coordinates = getOrExecProp(data, props.coordinates);
    if (coordinates && coordinates.length > 0) {
        const feature = new Feature({
            geometry: new GeomPoint(coordinates).transform(
                MapConfig.sourceProjection,
                MapConfig.targetProjection
            ),
            name: 'HeatMap'
        });

        feature.set('layerName', layerName);
        feature.data = data;
        feature.props = props;
        feature.isHovered = false;

        return feature;
    }
    return null;
};

const getAreaFeature = (layerName, area, areaProps) => {
    const format = new FormatWKT();
    const wkt = getOrExecProp(area, areaProps.wkt);
    const areaFeature = format.readFeature(wkt, {
        dataProjection: MapConfig.sourceProjection,
        featureProjection: MapConfig.targetProjection
    });
    areaFeature.set('layerName', layerName);
    areaFeature.data = area;
    areaFeature.props = areaProps;
    return areaFeature;
};

const getGraticuleFeature = line => (
    new Feature({
        geometry: new GeomMultiLineString(line).transform(
            MapConfig.sourceProjection,
            MapConfig.targetProjection
        ),
        name: 'GraticuleLine'
    })
);

export {
    getPointFeature,
    getAreaFeature,
    getGraticuleFeature,
    getHeatMapFeature
};
