export default [
    {
        BaseLine: 25.0,
        Date: '2019-10-01T00:00:00Z',
        Utilization: 39.21875
    },
    {
        BaseLine: 25.0,
        Date: '2019-11-01T00:00:00Z',
        Utilization: 36.92307692307692
    },
    {
        BaseLine: 25.0,
        Date: '2019-12-01T00:00:00Z',
        Utilization: 36.3125
    },
    {
        BaseLine: 25.0,
        Date: '2020-01-01T00:00:00Z',
        Utilization: 32.541666666666671
    },
    {
        BaseLine: 25.0,
        Date: '2020-02-01T00:00:00Z',
        Utilization: 30.777777777777779
    },
    {
        BaseLine: 25.0,
        Date: '2020-03-01T00:00:00Z',
        Utilization: 35.036904761904765
    },
    {
        BaseLine: 25.0,
        Date: '2020-04-01T00:00:00Z',
        Utilization: 42.226495726495727
    },
    {
        BaseLine: 25.0,
        Date: '2020-05-01T00:00:00Z',
        Utilization: 44.457264957264954
    },
    {
        BaseLine: 25.0,
        Date: '2020-06-01T00:00:00Z',
        Utilization: 36.07692307692308
    },
    {
        BaseLine: 25.0,
        Date: '2020-07-01T00:00:00Z',
        Utilization: 40.708333333333329
    },
    {
        BaseLine: 25.0,
        Date: '2020-08-01T00:00:00Z',
        Utilization: 37.625
    },
    {
        BaseLine: 25.0,
        Date: '2020-09-01T00:00:00Z',
        Utilization: 39.0
    },
    {
        BaseLine: 25.0,
        Date: '2020-10-01T00:00:00Z',
        Utilization: 33.95
    },
    {
        BaseLine: 25.0,
        Date: '2020-11-01T00:00:00Z',
        Utilization: 30.357142857142858
    },
    {
        BaseLine: 25.0,
        Date: '2020-12-01T00:00:00Z',
        Utilization: 33.615384615384613
    },
    {
        BaseLine: 25.0,
        Date: '2021-01-01T00:00:00Z',
        Utilization: 40.879120879120876
    },
    {
        BaseLine: 25.0,
        Date: '2021-02-01T00:00:00Z',
        Utilization: 32.5
    },
    {
        BaseLine: 25.0,
        Date: '2021-03-01T00:00:00Z',
        Utilization: 32.681818181818187
    },
    {
        BaseLine: 25.0,
        Date: '2021-04-01T00:00:00Z',
        Utilization: 39.85
    },
    {
        BaseLine: 25.0,
        Date: '2021-05-01T00:00:00Z',
        Utilization: 35.944444444444443
    },
    {
        BaseLine: 25.0,
        Date: '2021-06-01T00:00:00Z',
        Utilization: 37.441176470588232
    },
    {
        BaseLine: 25.0,
        Date: '2021-07-01T00:00:00Z',
        Utilization: 34.230769230769226
    },
    {
        BaseLine: 25.0,
        Date: '2021-08-01T00:00:00Z',
        Utilization: 39.5
    },
    {
        BaseLine: 25.0,
        Date: '2021-09-01T00:00:00Z',
        Utilization: 41.222222222222221
    }
];
