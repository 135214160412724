import { ActionTypes } from './assign-to-fleet-modal-actions';

const emptyArray = [];

const initialState = {
    imo: null,
    vessel: null,
    fleet: null,
    isAssignToFleetModalShown: false,
    relationships: {},
    statuses: emptyArray,
    fleets: emptyArray,
    responsibleOffices: emptyArray,
    commercialContracts: emptyArray
};

export default function assignToFleetModalReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ASSIGN_TO_FLEET_MODAL_OPEN: {
        return {
            ...state,
            isAssignToFleetModalShown: true,
            vessel: action.vessel,
            imo: action.vessel.IMO,
            fleet: action.fleet
        };
    }
    case ActionTypes.ASSIGN_TO_FLEET_MODAL_CLOSE: {
        return {
            ...state,
            isAssignToFleetModalShown: false,
            vessel: null,
            imo: null,
            fleet: null
        };
    }
    case ActionTypes.ASSIGN_TO_FLEET_MODAL_SET_OPTIONS: {
        return {
            ...state,
            fleets: action.fleets,
            statuses: action.statuses,
            responsibleOffices: action.responsibleOffices,
            commercialContracts: action.commercialContracts
        };
    }
    case ActionTypes.ASSIGN_TO_FLEET_MODAL_SET_RELATIONSHIPS: {
        return {
            ...state,
            relationships: {
                ...state.relationships,
                [action.imo]: action.relationships
            }
        };
    }
    case ActionTypes.ASSIGN_TO_FLEET_MODAL_CLEAR_RELATIONSHIPS: {
        return {
            ...state,
            relationships: {}
        };
    }
    default:
        return state;
    }
}
