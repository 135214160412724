import MtApi from './mt-api';
/* utils */
import { createQueryString } from 'utils/helpers/api-helper';
/* services */
import ConfigService from 'services/config-api/config-service';

const chartStyleMap = {
    light: 'default',
    dark: 'dark_background'
};

class EuEtsService {
    constructor() {
        this.resourceUrl = 'euets';
    }

    getEtsPrice(currency) {
        return MtApi.get(`${this.resourceUrl}/ets_price?currency=${currency}`)
            .then(response => response.data, () => null);
    }

    getVoyageCoefficient(data) {
        return MtApi.post(`${this.resourceUrl}/voyage_coefficients`, data)
            .then(response => response.data, () => null);
    }

    getVoyagePrice(data) {
        return MtApi.post(`${this.resourceUrl}/voyage_price`, data)
            .then(response => response.data, () => null);
    }

    getEtsChartLink(currency = 'EUR', style = 'dark', transparency = true) {
        const apiUrl = ConfigService.authority?.apiUrl;
        const params = {
            currency,
            style: chartStyleMap[style] || chartStyleMap.dark,
            transparency
        };
        return `${apiUrl}/core/api/v3/${this.resourceUrl}/chart${createQueryString(params)}`;
    }
}

export default new EuEtsService();
