import PropTypes from 'prop-types';

export const TEnergyManagementReportsQueryParams = PropTypes.shape({
    cpEdit: PropTypes.bool,
    cpVoyage: PropTypes.number,
    emReportType: PropTypes.number,
    emVesselImo: PropTypes.number,
    emVoyageId: PropTypes.number,
    reportEdit: PropTypes.bool,
    reportId: PropTypes.number,
    reportType: PropTypes.number
});
