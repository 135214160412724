import React from 'react';
import PropTypes from 'prop-types';
/* Components */
import Checkbox from '../../checkbox/checkbox';
import TextHighlight from '../../text-highlight/text-highlight';
/* styles */
import './select-option.scss';

class SelectOption extends React.PureComponent {
    clickHandler = e => {
        if (this.props.multiple && e) {
            e.stopPropagation();
        }
        this.props.onClick(this.props.option, e);
    };

    handleMouseOver = e => {
        this.props.onMouseOver(e, this.props.option);
    };

    render() {
        const props = this.props;
        let disabledTitle = '';
        let optionClass = 'sten-select__option';
        let isOptionSelected = false;
        if (props.value) {
            isOptionSelected = props.multiple
                ? props.value.some(item => item[props.valueKey] === props.option[props.valueKey])
                : props.value[props.valueKey] === props.option[props.valueKey];
        }
        if (props.className) {
            optionClass += ` ${props.className}`;
        }
        if (isOptionSelected) {
            optionClass += ' sten-select__option--selected';
        }
        if (props.option.disabled) {
            optionClass += ' sten-select__option--disabled';
            if (props.option.disabledTitle) {
                disabledTitle = props.option.disabledTitle;
            }
        }
        if (this.props.isHighlighted) {
            optionClass += ' sten-select__option--highlighted';
        }

        if (props.multiple) {
            return (
                <div
                    className={optionClass}
                    onClick={this.clickHandler}
                    onMouseMove={this.handleMouseOver}
                >
                    <div className="flex-row">
                        <div className="flex-shrink">
                            <Checkbox
                                isDisabled={props.option.disabled}
                                onChange={this.clickHandler}
                                isChecked={isOptionSelected}
                                title={disabledTitle}
                            />
                        </div>
                        <div className="flex-grow text-ellipsis">
                            {props.optionRenderer
                                ? props.optionRenderer(props.option, props.searchCriteria, isOptionSelected)
                                : (
                                    <TextHighlight
                                        input={props.option[props.labelKey]}
                                        highlight={props.searchCriteria}
                                    />
                                )
                            }
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div
                className={optionClass}
                onClickCapture={this.clickHandler}
                title={disabledTitle}
                onMouseMove={this.handleMouseOver}
            >
                {props.optionRenderer
                    ? props.optionRenderer(props.option, this.props.searchCriteria)
                    : (
                        <TextHighlight
                            input={props.option[props.labelKey]}
                            highlight={this.props.searchCriteria}
                        />
                    )
                }
            </div>
        );
    }
}

SelectOption.propTypes = {
    className: PropTypes.string,
    isHighlighted: PropTypes.bool,
    labelKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    multiple: PropTypes.bool,
    onClick: PropTypes.func,
    onMouseOver: PropTypes.func,
    option: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.any), PropTypes.number]),
    optionRenderer: PropTypes.func,
    searchCriteria: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any), PropTypes.objectOf(PropTypes.any)]),
    valueKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SelectOption.defaultProps = {
    className: '',
    isHighlighted: false,
    labelKey: '',
    multiple: false,
    onClick: undefined,
    onMouseOver: undefined,
    option: null,
    optionRenderer: undefined,
    searchCriteria: '',
    value: null,
    valueKey: ''
};

export default SelectOption;
