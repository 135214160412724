import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import Chart from 'components/chart/chart';
/* config */
import { getChartConfig } from './admin-company-management-platform-statistics-chart-config';
/* styles */
import './admin-company-management-platform-statistics-chart.scss';

class AdminCompanyManagementPlatformStatisticsChart extends React.PureComponent {
    render() {
        const chartMin = this.props.chartData.categories.length - 12;
        return (
            <div className={`sten-panel${this.props.className ? ` ${this.props.className}` : ''}`}>
                <div className="sten-panel__section">
                    <h4 className="text-uppercase">
                        {translate('ADMIN.COMPANY_MANAGEMENT.PLATFORM_STATISTICS.NUMBER_OF_USERS_OVER_TIME')}
                    </h4>
                </div>
                <Chart
                    className="sten-admin-company-management-platform-statistics-chart"
                    series={this.props.chartData.series}
                    categories={this.props.chartData.categories}
                    config={getChartConfig()}
                    options={{ xAxis: { min: chartMin } }}
                    isChart
                />
            </div>
        );
    }
}

AdminCompanyManagementPlatformStatisticsChart.propTypes = {
    className: PropTypes.string,
    chartData: PropTypes.exact({
        categories: PropTypes.array,
        series: PropTypes.objectOf(PropTypes.any)
    })
};

AdminCompanyManagementPlatformStatisticsChart.defaultProps = {
    className: '',
    chartData: null
};

export default AdminCompanyManagementPlatformStatisticsChart;
