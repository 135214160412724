import React from 'react';
import PropTypes from 'prop-types';
/* styles */
import './ol.scss';

export class OlLayer extends React.Component {
    componentDidMount() {
        this.updateLayer();
    }

    shouldComponentUpdate(nextProps) {
        this.updateLayer(nextProps);

        return false;
    }

    componentWillUnmount() {
        this.context.removeLayer(this.props.name);
    }

    updateLayer = nextProps => {
        const props = nextProps || this.props;
        if (!nextProps || this.props.elements !== nextProps.elements) {
            this.context.addLayer(
                props.name,
                props.type,
                props.elements,
                props.elementProps,
                props.zIndex,
                props.opacity,
                props.layerProps,
                props.sourceProps
            );
            this.context.updateLayerProp(props.name, 'visible', !props.hidden);
        }
        if (nextProps && nextProps.observes) {
            let changed = false;
            if (!this.props.observes || nextProps.observes.length !== this.props.observes.length) {
                changed = true;
            } else {
                for (let i = 0; i < nextProps.observes.length; i++) {
                    if (nextProps.observes[i] !== this.props.observes[i]) {
                        changed = true;
                        break;
                    }
                }
            }
            if (changed) {
                this.context.layerChanged(this.props.name);
            }
        }
        if (typeof props.hidden === 'boolean' && (!nextProps || this.props.hidden !== nextProps.hidden)) {
            this.context.updateLayerProp(props.name, 'visible', !props.hidden);
        }
        if (!nextProps || this.props.disabled !== nextProps.disabled) {
            if (!props.disabled) {
                this.context.addListeners(
                    props.name,
                    props.onClick,
                    props.onClickAway,
                    props.onHover,
                    props.onPostRender
                );
            } else {
                this.context.removeListeners(props.name);
            }
        }
    };

    render() {
        return null;
    }
}

OlLayer.contextTypes = {
    addLayer: PropTypes.func,
    addListeners: PropTypes.func,
    layerChanged: PropTypes.func,
    removeLayer: PropTypes.func,
    removeListeners: PropTypes.func,
    updateLayerProp: PropTypes.func
};

OlLayer.propTypes = {
    disabled: PropTypes.bool,
    elementProps: PropTypes.objectOf(PropTypes.any),
    elements: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.string]),
    hidden: PropTypes.bool,
    layerProps: PropTypes.objectOf(PropTypes.any),
    name: PropTypes.string.isRequired,
    observes: PropTypes.arrayOf(PropTypes.any),
    onClick: PropTypes.func,
    onClickAway: PropTypes.func,
    onHover: PropTypes.func,
    opacity: PropTypes.number,
    sourceProps: PropTypes.objectOf(PropTypes.any),
    type: PropTypes.oneOf([
        'point', 'icon', 'line', 'multiLine', 'area', 'kml', 'geoJson', 'heatMap', 'vectorTile', 'cluster', 'wmsTile'
    ]).isRequired,
    zIndex: PropTypes.number
};

OlLayer.defaultProps = {
    disabled: false,
    elements: null,
    elementProps: null,
    hidden: false,
    layerProps: null,
    observes: null,
    onClick: undefined,
    onClickAway: undefined,
    onHover: undefined,
    opacity: 1,
    sourceProps: null,
    zIndex: null
};

export default OlLayer;
