/* actions */
import { ActionTypes } from './contact-list-filters-actions';

const emptyArray = [];

const initialState = {
    companies: emptyArray,
    departments: emptyArray,
    searchCriteria: '',
    selectedCompanies: emptyArray,
    selectedDepartments: emptyArray,
    filterUpdated: false,
    offset: 0,
    limit: 20
};

export default function contactListFiltersReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.CONTACT_LIST_FILTERS_SET_OPTIONS: {
        return {
            ...state,
            companies: action.companies,
            departments: action.departments
        };
    }
    case ActionTypes.CONTACT_LIST_FILTERS_SET_VALUE: {
        return {
            ...state,
            [action.key]: action.value,
            filterUpdated: true,
            offset: 0
        };
    }
    case ActionTypes.CONTACT_LIST_FILTERS_UPDATE_LOADED: {
        return {
            ...state,
            filterUpdated: false
        };
    }
    default:
        return state;
    }
}
