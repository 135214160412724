import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
/* eslint-disable max-len */
/* Sagas */
import adminCompanyManagementSaga from 'pages/user-pages/admin/admin-company-management/admin-company-management-saga';
import adminUserManagementSaga from 'pages/user-pages/admin/admin-user-management/admin-user-management-saga';
import adminVesselManagementFormSaga from 'pages/user-pages/admin/admin-vessel-management/admin-vessel-management-form/admin-vessel-management-form-saga';
import adminVesselManagementSaga from 'pages/user-pages/admin/admin-vessel-management/admin-vessel-management-saga';
import assignToFleetModalSaga from 'components/assign-to-fleet-modal/assign-to-fleet-modal-saga';
import berthInfoSaga from 'components/terminal-and-berth-info/berth/berth-info-saga';
import calculatorSaga from 'pages/user-pages/distance-calculator-page/calculator/calculator-saga';
import cargoInquirySaga from 'pages/user-pages/left-side-bar/cargo-inquiry/cargo-inquiry-saga';
import charterpartyFormSaga from 'components/charterparty-form/charterparty-form-saga';
import contactListFiltersSaga from 'pages/user-pages/contact-list/contact-list-filters/contact-list-filters-saga';
import contactListTableSaga from 'pages/user-pages/contact-list/contact-list-table/contact-list-table-saga';
import counterPvarSaga from 'components/counter-pvar/counter-pvar-saga';
import distanceCalculatorPageSaga from 'pages/user-pages/distance-calculator-page/distance-calculator-page-saga';
import dtnWeatherSaga from 'components/dtn-weather/dtn-weather-saga';
import emissionEstimatorPageSaga from 'pages/user-pages/emission-estimator-page/emission-estimator-page-saga';
import employedVesselsPanelSaga from 'pages/user-pages/home/right-side-bar/employed-vessels-panel/employed-vessels-panel-saga';
import energyManagementKPIOverviewSaga from 'pages/user-pages/energy-management/kpi/overview/overview-saga';
import energyManagementKPISaga from 'pages/user-pages/energy-management/kpi/kpi-saga';
import energyManagementKPISegmentSaga from 'pages/user-pages/energy-management/kpi/segment/segment-saga';
import energyManagementKPIVesselSaga from 'pages/user-pages/energy-management/kpi/vessel/vessel-saga';
import energyManagementReportsFleetAverageSpeedReportSaga from 'pages/user-pages/energy-management/reports/preview/fleet-average-speed-report/fleet-average-speed-report-saga';
import energyManagementReportsFleetListReportSaga from 'pages/user-pages/energy-management/reports/preview/fleet-list-report/fleet-list-report-saga';
import energyManagementReportsFleetPerformanceReportSaga from 'pages/user-pages/energy-management/reports/preview/fleet-performance-report/fleet-performance-report-saga';
import energyManagementReportsPerformanceSummaryReportSaga from 'pages/user-pages/energy-management/reports/preview/performance-summary-report/performance-summary-saga';
import energyManagementReportsPreviewMultiVoyageReportSaga from 'pages/user-pages/energy-management/reports/preview/multi-voyage-report/multi-voyage-report-saga';
import energyManagementReportsQuarterlyReportSaga from 'pages/user-pages/energy-management/reports/preview/quarterly-report/quarterly-report-saga';
import energyManagementReportsSaga from 'pages/user-pages/energy-management/reports/reports-saga';
import energyManagementReportsVoyageReportSaga from 'pages/user-pages/energy-management/reports/preview/voyage-report/voyage-report-saga';
import energyManagementReportsWeatherPerformanceReportSaga from 'pages/user-pages/energy-management/reports/preview/weather-performance-report/weather-performance-report-saga';
import estimatorSaga from 'pages/user-pages/emission-estimator-page/estimator/estimator-saga';
import euEtsSaga from 'components/euets/euets-saga';
import fleetActivitySaga from 'pages/user-pages/home/right-side-bar/fleet-overview/fleet-activity/fleet-activity-saga';
import fleetDashboardSaga from 'pages/user-pages/fleet-dashboard/fleet-dashboard-saga';
import fleetManagementSaga from 'pages/user-pages/fleet-management/fleet-management-saga';
import fleetRangeSaga from 'pages/user-pages/home/right-side-bar/fleet-overview/fleet-range/fleet-range-saga';
import globalSearchSaga from 'pages/user-pages/home/global-search/global-search-saga';
import gsmRightSideBarSaga from 'pages/gsm/gsm-right-side-bar/gsm-right-side-bar-saga';
import gsmSaga from 'pages/gsm/gsm-saga';
import homePageSaga from 'pages/user-pages/home/home-saga';
import inspectionsFormSaga from 'pages/user-pages/inspections/form/inspections-form-saga';
import inspectionsSaga from 'pages/user-pages/inspections/inspections-saga';
import mapDatePickerSaga from 'pages/user-pages/home/map-date-picker/map-date-picker-saga';
import mapOptionsSaga from 'components/map-options/map-options-saga';
import marketIntelSaga from 'pages/user-pages/market-overview/market-intel/market-intel-saga';
import nauticalChartsSaga from 'components/nautical-charts/nautical-charts-saga';
import notificationSettingsSaga from 'pages/user-pages/home/notification-settings/notification-settings-saga';
import notificationsSaga from 'pages/user-pages/notifications/notifications-saga';
import operatorsDiarySaga from 'pages/user-pages/operators-diary/operators-diary-saga';
import portCallsAnalyticsSaga from 'pages/user-pages/market-overview/port-calls-analytics/port-calls-analytics-saga';
import portInfoCallsSaga from 'components/port-info/port-info-calls/port-info-calls-saga';
import portInfoSaga from 'components/port-info/port-info-saga';
import portManagementSaga from './pages/user-pages/port-management/port-management-saga';
import positionListComposeSaga from 'pages/user-pages/position-list/position-list-compose/position-list-compose-saga';
import positionListFiltersSaga from 'pages/user-pages/position-list/position-list-filters/position-list-filters-saga';
import positionListSaga from 'pages/user-pages/position-list/position-list-saga';
import questionnaireSaga from 'components/questionnaire/questionnaire-saga';
import rawReportsTableSaga from 'components/raw-reports-table/raw-reports-table-saga';
import terminalInfoSaga from 'components/terminal-and-berth-info/terminal/terminal-info-saga';
import testSaga from 'pages/user-pages/test/test-saga';
import tradingRouteDetailsSaga from 'pages/user-pages/market-overview/market-intel/trading-route-details/trading-route-details-saga';
import userSaga from 'pages/user-pages/user-saga';
import vesselFilterSaga from 'pages/user-pages/home/right-side-bar/vessel-filter/vessel-filter-saga';
import vesselInfoSaga from 'pages/user-pages/home/right-side-bar/vessel-info/vessel-info-saga';
import vesselListSaga from 'pages/user-pages/home/right-side-bar/fleet-overview/vessel-list/vessel-list-saga';
import vesselReportSaga from 'components/vessel-report/vessel-report-saga';
import voyageInfoSaga from 'pages/user-pages/operators-diary/voyage-info/voyage-info-saga';
import voyagePerformanceSaga from 'pages/user-pages/voyage-performance/voyage-performance-saga';
import weatherRoutingSaga from 'pages/user-pages/weather-routing/weather-routing-saga';
import wniWeatherSaga from 'components/wni-weather/wni-weather-saga';
/* Reducers */
import adminCompanyManagementReducer from 'pages/user-pages/admin/admin-company-management/admin-company-management-reducer';
import adminUserManagementReducer from 'pages/user-pages/admin/admin-user-management/admin-user-management-reducer';
import adminVesselManagementFormReducer from 'pages/user-pages/admin/admin-vessel-management/admin-vessel-management-form/admin-vessel-management-form-reducer';
import adminVesselManagementReducer from 'pages/user-pages/admin/admin-vessel-management/admin-vessel-management-reducer';
import assignToFleetModalReducer from 'components/assign-to-fleet-modal/assign-to-fleet-modal-reducer';
import berthInfoReducer from 'components/terminal-and-berth-info/berth/berth-info-reducer';
import bunkerPricesTooltipReducer from 'pages/user-pages/market-overview/market-intel/map/bunker-prices-tooltip/bunker-prices-tooltip-reducer';
import calculatorReducer from 'pages/user-pages/distance-calculator-page/calculator/calculator-reducer';
import cargoInquiryReducer from 'pages/user-pages/left-side-bar/cargo-inquiry/cargo-inquiry-reducer';
import charterpartyFormReducer from 'components/charterparty-form/charterparty-form-reducer';
import contactListFiltersReducer from 'pages/user-pages/contact-list/contact-list-filters/contact-list-filters-reducer';
import contactListTableReducer from 'pages/user-pages/contact-list/contact-list-table/contact-list-table-reducer';
import counterPvarReducer from './components/counter-pvar/counter-pvar-reducer';
import distanceCalculatorPageReducer from 'pages/user-pages/distance-calculator-page/distance-calculator-page-reducer';
import dtnWeatherReducer from 'components/dtn-weather/dtn-weather-reducer';
import emissionEstimatorPageReducer from 'pages/user-pages/emission-estimator-page/emission-estimator-page-reducer';
import employedVesselsPanelReducer from 'pages/user-pages/home/right-side-bar/employed-vessels-panel/employed-vessels-panel-reducer';
import energyManagementKPIOverviewReducer from 'pages/user-pages/energy-management/kpi/overview/overview-reducer';
import energyManagementKPIReducer from 'pages/user-pages/energy-management/kpi/kpi-reducer';
import energyManagementKPISegmentReducer from 'pages/user-pages/energy-management/kpi/segment/segment-reducer';
import energyManagementKPIVesselReducer from 'pages/user-pages/energy-management/kpi/vessel/vessel-reducer';
import energyManagementReportsFleetAverageSpeedReportReducer from 'pages/user-pages/energy-management/reports/preview/fleet-average-speed-report/fleet-average-speed-report-reducer';
import energyManagementReportsFleetListReportReducer from 'pages/user-pages/energy-management/reports/preview/fleet-list-report/fleet-list-report-reducer';
import energyManagementReportsFleetPerformanceReportReducer from 'pages/user-pages/energy-management/reports/preview/fleet-performance-report/fleet-performance-report-reducer';
import energyManagementReportsMultiVoyageReportReducer from 'pages/user-pages/energy-management/reports/preview/multi-voyage-report/multi-voyage-report-reducer';
import energyManagementReportsPerformanceSummaryReportReducer from 'pages/user-pages/energy-management/reports/preview/performance-summary-report/performance-summary-reducer';
import energyManagementReportsQuarterlyReportReducer from 'pages/user-pages/energy-management/reports/preview/quarterly-report/quarterly-report-reducer';
import energyManagementReportsReducer from 'pages/user-pages/energy-management/reports/reports-reducer';
import energyManagementReportsVoyageReportReducer from 'pages/user-pages/energy-management/reports/preview/voyage-report/voyage-report-reducer';
import energyManagementReportsWeatherPerformanceReportReducer from 'pages/user-pages/energy-management/reports/preview/weather-performance-report/weather-performance-report-reducer';
import estimatorReducer from 'pages/user-pages/emission-estimator-page/estimator/estimator-reducer';
import euEtsReducer from 'components/euets/euets-reducer';
import fleetActivityReducer from 'pages/user-pages/home/right-side-bar/fleet-overview/fleet-activity/fleet-activity-reducer';
import fleetDashboardReducer from 'pages/user-pages/fleet-dashboard/fleet-dashboard-reducer';
import fleetManagementReducer from 'pages/user-pages/fleet-management/fleet-management-reducer';
import fleetRangeReducer from 'pages/user-pages/home/right-side-bar/fleet-overview/fleet-range/fleet-range-reducer';
import globalSearchReducer from 'pages/user-pages/home/global-search/global-search-reducer';
import gsmMapLegendReducer from 'pages/gsm/gsm-map-legend/gsm-map-legend-reducer';
import gsmMapViewSwitchReducer from 'pages/gsm/gsm-map-view-switch/gsm-map-view-switch-reducer';
import gsmReducer from 'pages/gsm/gsm-reducer';
import gsmRightSideBarReducer from 'pages/gsm/gsm-right-side-bar/gsm-right-side-bar-reducer';
import gsmRightSideBarSecondaryReducer from 'pages/gsm/gsm-right-side-bar-secondary/gsm-right-side-bar-secondary-reducer';
import gsmVesselDetailsTooltipReducer from 'pages/gsm/gsm-map/gsm-vessel-details-tooltip/gsm-vessel-details-tooltip-reducer';
import homeReducer from 'pages/user-pages/home/home-reducer';
import inspectionsFormReducer from 'pages/user-pages/inspections/form/inspections-form-reducer';
import inspectionsReducer from 'pages/user-pages/inspections/inspections-reducer';
import leftSideBarReducer from 'pages/user-pages/left-side-bar/left-side-bar-reducer';
import loaderReducer from 'components/loader/loader-reducer';
import mapDatePickerReducer from 'pages/user-pages/home/map-date-picker/map-date-picker-reducer';
import mapLocationTooltipReducer from 'components/map-location-tooltip/map-location-tooltip-reducer';
import mapOptionsReducer from 'components/map-options/map-options-reducer';
import marketIntelReducer from 'pages/user-pages/market-overview/market-intel/market-intel-reducer';
import nauticalChartsReducer from 'components/nautical-charts/nautical-charts-reducer';
import notificationSettingsReducer from 'pages/user-pages/home/notification-settings/notification-settings-reducer';
import notificationsReducer from 'pages/user-pages/notifications/notifications-reducer';
import operatorsDiaryReducer from 'pages/user-pages/operators-diary/operators-diary-reducer';
import portCallsAnalyticsReducer from 'pages/user-pages/market-overview/port-calls-analytics/port-calls-analytics-reducer';
import portInfoCallsReducer from 'components/port-info/port-info-calls/port-info-calls-reducer';
import portInfoReducer from 'components/port-info/port-info-reducer';
import portManagementReducer from './pages/user-pages/port-management/port-management-reducer';
import positionListComposeReducer from 'pages/user-pages/position-list/position-list-compose/position-list-compose-reducer';
import positionListFiltersReducer from 'pages/user-pages/position-list/position-list-filters/position-list-filters-reducer';
import positionListReducer from 'pages/user-pages/position-list/position-list-reducer';
import positionListTableReducer from 'pages/user-pages/position-list/position-list-table/position-list-table-reducer';
import questionnaireReducer from 'components/questionnaire/questionnaire-reducer';
import rawReportsTableReducer from 'components/raw-reports-table/raw-reports-table-reducer';
import rightSideBarReducer from 'components/right-side-bar/right-side-bar-reducer';
import sendReminderModalReducer from 'components/vessel-report/send-reminder-modal/send-reminder-modal-reducer';
import terminalInfoReducer from 'components/terminal-and-berth-info/terminal/terminal-info-reducer';
import testReducer from 'pages/user-pages/test/test-reducer';
import tradingRouteDetailsReducer from 'pages/user-pages/market-overview/market-intel/trading-route-details/trading-route-details-reducer';
import tradingRouteTooltipReducer from 'pages/user-pages/market-overview/market-intel/map/trading-route-tooltip/trading-route-tooltip-reducer';
import userReducer from 'pages/user-pages/user-reducer';
import vesselDetailsTooltipReducer from 'components/vessel-details-tooltip/vessel-details-tooltip-reducer';
import vesselDistributionAreasReducer from 'pages/user-pages/market-overview/market-intel/map/vessel-distribution-areas/vessel-distribution-areas-reducer';
import vesselFilterReducer from 'pages/user-pages/home/right-side-bar/vessel-filter/vessel-filter-reducer';
import vesselInfoReducer from 'pages/user-pages/home/right-side-bar/vessel-info/vessel-info-reducer';
import vesselListReducer from 'pages/user-pages/home/right-side-bar/fleet-overview/vessel-list/vessel-list-reducer';
import vesselReportReducer from 'components/vessel-report/vessel-report-reducer';
import voyageInfoReducer from 'pages/user-pages/operators-diary/voyage-info/voyage-info-reducer';
import voyagePerformanceReducer from 'pages/user-pages/voyage-performance/voyage-performance-reducer';
import voyageReportsReducer from 'pages/user-pages/home/map/voyage-reports/voyage-reports-reducer';
import weatherRoutingReducer from './pages/user-pages/weather-routing/weather-routing-reducer';
import wniWeatherReducer from 'components/wni-weather/wni-weather-reducer';
/* eslint-enable max-len */

const sagaMiddleware = createSagaMiddleware();

const appReducer = combineReducers({
    adminCompanyManagementReducer,
    adminUserManagementReducer,
    adminVesselManagementFormReducer,
    adminVesselManagementReducer,
    assignToFleetModalReducer,
    berthInfoReducer,
    bunkerPricesTooltipReducer,
    calculatorReducer,
    cargoInquiryReducer,
    charterpartyFormReducer,
    contactListFiltersReducer,
    contactListTableReducer,
    counterPvarReducer,
    distanceCalculatorPageReducer,
    dtnWeatherReducer,
    emissionEstimatorPageReducer,
    employedVesselsPanelReducer,
    energyManagementKPIOverviewReducer,
    energyManagementKPIReducer,
    energyManagementKPISegmentReducer,
    energyManagementKPIVesselReducer,
    energyManagementReportsFleetAverageSpeedReportReducer,
    energyManagementReportsFleetListReportReducer,
    energyManagementReportsFleetPerformanceReportReducer,
    energyManagementReportsMultiVoyageReportReducer,
    energyManagementReportsPerformanceSummaryReportReducer,
    energyManagementReportsQuarterlyReportReducer,
    energyManagementReportsReducer,
    energyManagementReportsVoyageReportReducer,
    energyManagementReportsWeatherPerformanceReportReducer,
    estimatorReducer,
    euEtsReducer,
    fleetActivityReducer,
    fleetDashboardReducer,
    fleetManagementReducer,
    fleetRangeReducer,
    globalSearchReducer,
    gsmMapLegendReducer,
    gsmMapViewSwitchReducer,
    gsmReducer,
    gsmRightSideBarReducer,
    gsmRightSideBarSecondaryReducer,
    gsmVesselDetailsTooltipReducer,
    homeReducer,
    inspectionsFormReducer,
    inspectionsReducer,
    leftSideBarReducer,
    loaderReducer,
    mapDatePickerReducer,
    mapLocationTooltipReducer,
    mapOptionsReducer,
    marketIntelReducer,
    nauticalChartsReducer,
    notificationSettingsReducer,
    notificationsReducer,
    operatorsDiaryReducer,
    portCallsAnalyticsReducer,
    portInfoCallsReducer,
    portInfoReducer,
    portManagementReducer,
    positionListComposeReducer,
    positionListFiltersReducer,
    positionListReducer,
    positionListTableReducer,
    questionnaireReducer,
    rawReportsTableReducer,
    rightSideBarReducer,
    sendReminderModalReducer,
    terminalInfoReducer,
    testReducer,
    tradingRouteDetailsReducer,
    tradingRouteTooltipReducer,
    userReducer,
    vesselDetailsTooltipReducer,
    vesselDistributionAreasReducer,
    vesselFilterReducer,
    vesselInfoReducer,
    vesselListReducer,
    vesselReportReducer,
    voyageInfoReducer,
    voyagePerformanceReducer,
    voyageReportsReducer,
    weatherRoutingReducer,
    wniWeatherReducer
});

let store;
export function getStore(router, preloadedState) {
    sagaMiddleware.setContext({ router });
    let enhancer = applyMiddleware(sagaMiddleware);
    if (process.env.NODE_ENV === 'development') {
        /* eslint-disable no-underscore-dangle */
        const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        enhancer = composeEnhancers(enhancer);
    }

    if (!store) {
        store = createStore(appReducer, preloadedState, enhancer);
    }
    return store;
}

export function startSagas() {
    sagaMiddleware.run(adminCompanyManagementSaga);
    sagaMiddleware.run(adminUserManagementSaga);
    sagaMiddleware.run(adminVesselManagementFormSaga);
    sagaMiddleware.run(adminVesselManagementSaga);
    sagaMiddleware.run(assignToFleetModalSaga);
    sagaMiddleware.run(berthInfoSaga);
    sagaMiddleware.run(calculatorSaga);
    sagaMiddleware.run(cargoInquirySaga);
    sagaMiddleware.run(charterpartyFormSaga);
    sagaMiddleware.run(contactListFiltersSaga);
    sagaMiddleware.run(contactListTableSaga);
    sagaMiddleware.run(counterPvarSaga);
    sagaMiddleware.run(distanceCalculatorPageSaga);
    sagaMiddleware.run(dtnWeatherSaga);
    sagaMiddleware.run(emissionEstimatorPageSaga);
    sagaMiddleware.run(employedVesselsPanelSaga);
    sagaMiddleware.run(energyManagementKPIOverviewSaga);
    sagaMiddleware.run(energyManagementKPISaga);
    sagaMiddleware.run(energyManagementKPISegmentSaga);
    sagaMiddleware.run(energyManagementKPIVesselSaga);
    sagaMiddleware.run(energyManagementReportsFleetAverageSpeedReportSaga);
    sagaMiddleware.run(energyManagementReportsFleetListReportSaga);
    sagaMiddleware.run(energyManagementReportsFleetPerformanceReportSaga);
    sagaMiddleware.run(energyManagementReportsPerformanceSummaryReportSaga);
    sagaMiddleware.run(energyManagementReportsPreviewMultiVoyageReportSaga);
    sagaMiddleware.run(energyManagementReportsQuarterlyReportSaga);
    sagaMiddleware.run(energyManagementReportsSaga);
    sagaMiddleware.run(energyManagementReportsVoyageReportSaga);
    sagaMiddleware.run(energyManagementReportsWeatherPerformanceReportSaga);
    sagaMiddleware.run(estimatorSaga);
    sagaMiddleware.run(euEtsSaga);
    sagaMiddleware.run(fleetActivitySaga);
    sagaMiddleware.run(fleetDashboardSaga);
    sagaMiddleware.run(fleetManagementSaga);
    sagaMiddleware.run(fleetRangeSaga);
    sagaMiddleware.run(globalSearchSaga);
    sagaMiddleware.run(gsmRightSideBarSaga);
    sagaMiddleware.run(gsmSaga);
    sagaMiddleware.run(homePageSaga);
    sagaMiddleware.run(inspectionsFormSaga);
    sagaMiddleware.run(inspectionsSaga);
    sagaMiddleware.run(mapDatePickerSaga);
    sagaMiddleware.run(mapOptionsSaga);
    sagaMiddleware.run(marketIntelSaga);
    sagaMiddleware.run(nauticalChartsSaga);
    sagaMiddleware.run(notificationSettingsSaga);
    sagaMiddleware.run(notificationsSaga);
    sagaMiddleware.run(operatorsDiarySaga);
    sagaMiddleware.run(portCallsAnalyticsSaga);
    sagaMiddleware.run(portInfoCallsSaga);
    sagaMiddleware.run(portInfoSaga);
    sagaMiddleware.run(portManagementSaga);
    sagaMiddleware.run(positionListComposeSaga);
    sagaMiddleware.run(positionListFiltersSaga);
    sagaMiddleware.run(positionListSaga);
    sagaMiddleware.run(questionnaireSaga);
    sagaMiddleware.run(rawReportsTableSaga);
    sagaMiddleware.run(terminalInfoSaga);
    sagaMiddleware.run(testSaga);
    sagaMiddleware.run(tradingRouteDetailsSaga);
    sagaMiddleware.run(userSaga);
    sagaMiddleware.run(vesselFilterSaga);
    sagaMiddleware.run(vesselInfoSaga);
    sagaMiddleware.run(vesselListSaga);
    sagaMiddleware.run(vesselReportSaga);
    sagaMiddleware.run(voyageInfoSaga);
    sagaMiddleware.run(voyagePerformanceSaga);
    sagaMiddleware.run(weatherRoutingSaga);
    sagaMiddleware.run(wniWeatherSaga);
}
