import TimeHelper from 'utils/helpers/time-helper';
import { RUNTIME_INTERVAL } from './dtn-weather-constants';

export const getRoundedDate = (timestamp, rounding) => {
    const dateValue = timestamp ? new Date(timestamp).valueOf() : Date.now();
    return new Date(Math.floor(dateValue / rounding) * rounding);
};

export const getLatestRuntime = (date, rounding = RUNTIME_INTERVAL) => {
    const now = getRoundedDate(null, rounding);
    return !date || date.valueOf() > now ? now : getRoundedDate(date.valueOf(), rounding);
};

export const getTimestampsFetchParams = (date, earliestDate, middleRange = false) => {
    const rangeMax = middleRange ? new Date(date.valueOf() + (12 * TimeHelper.units.hour)) : date;
    let rangeMin = new Date(rangeMax.valueOf() - TimeHelper.units.day);
    if (earliestDate && rangeMin < earliestDate) {
        rangeMin = new Date(earliestDate);
    }
    return {
        time: `${rangeMin.toISOString()},${rangeMax.toISOString()}`,
        status: 'complete,processing'
    };
};

export const extractTimestamps = (data, timestamps, runtime = true) => {
    const res = { ...timestamps };
    for (const layerId in data) {
        if (data[layerId].length) {
            res[layerId] = res[layerId] ? { ...res[layerId] } : {};
            data[layerId].forEach(run => {
                const rt = run.runtime || runtime;
                run.timestamps.forEach(timestamp => {
                    if (!res[layerId][timestamp] || res[layerId][timestamp] < rt) {
                        res[layerId][timestamp] = rt;
                    }
                });
            });
        }
    }
    return res;
};

export const findClosestTimestamp = (timestamps, selectedTimestamp) => {
    if (timestamps[selectedTimestamp]) {
        return selectedTimestamp;
    }
    let closestTimestamp = null;
    Object.keys(timestamps).forEach(ts => {
        if (timestamps[ts] && ts < selectedTimestamp && (!closestTimestamp || ts > closestTimestamp)) {
            closestTimestamp = ts;
        }
    });
    return closestTimestamp;
};
