import React from 'react';
import PropTypes from 'prop-types';

export default class EnergyManagementReportsTableCell extends React.PureComponent {
    handleHover = () => this.props.onHover(this.props.id);

    handleClick = () => this.props.onClick(this.props.id);

    render() {
        let className = this.props.className || '';
        if (this.props.onClick) {
            className += ' cursor-pointer';
        }
        return (
            <td
                title={this.props.title ? this.props.title : ''}
                style={{ ...this.props.style, height: this.props.height }}
                onMouseOver={this.props.onHover && this.handleHover}
                onClick={this.props.onClick && this.handleClick}
                className={className}
                rowSpan={this.props.rowSpan}
                colSpan={this.props.colSpan}
            >
                {this.props.children}
            </td>
        );
    }
}

EnergyManagementReportsTableCell.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    colSpan: PropTypes.number,
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    onHover: PropTypes.func,
    rowSpan: PropTypes.number,
    style: PropTypes.objectOf(PropTypes.any),
    title: PropTypes.string
};

EnergyManagementReportsTableCell.defaultProps = {
    children: null,
    className: '',
    colSpan: null,
    height: null,
    id: null,
    onClick: undefined,
    onHover: undefined,
    rowSpan: null,
    style: null,
    title: ''
};
