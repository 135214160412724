import { ActionTypes } from './vessel-list-actions';

const initialState = {
    allFetched: false,
    vessels: []
};

export default function vesselListReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.VESSEL_LIST_ADD_VESSELS: {
        let vessels;
        if (action.overrideCurrent) {
            vessels = action.vessels;
        } else {
            vessels = state.vessels.slice().concat(action.vessels);
        }
        return {
            ...state,
            vessels,
            allFetched: action.allFetched
        };
    }
    case ActionTypes.VESSEL_LIST_RESET_VESSEL_LIST: {
        return {
            ...state,
            ...initialState
        };
    }
    default:
        return state;
    }
}
