import CoreApi from './core-api';
/* utils */
import { sortByProp } from 'utils/helpers/array-helper';
import { mockDataForOperatorsDiary } from 'pages/user-pages/operators-diary/operators-diary-utils';
import TimeHelper from 'utils/helpers/time-helper';
// import { findInNestedArray } from 'utils/helpers/array-helper';
/* services */
import LocalStorageService from 'services/local-storage-service';

const filterMockedData = (mockedData, params) => {
    if (params && params.VoyagesPerVessel && params.VoyagesPerVessel.Id === 1) {
        const now = TimeHelper.getUTC();
        return mockedData.map(vessel => ({
            ...vessel,
            Voyages: vessel.Voyages.filter(voyage => now > voyage.StartDate && now < voyage.EndDate)
        }));
    }
    return mockedData;
};

class OperatorsDiaryService {
    constructor() {
        this.resourceUrl = 'OperatorsDiary';
        this.mockedData = null;
    }

    static getMockedData() {
        if (this.mockedData) {
            return Promise.resolve(this.mockedData);
        }
        return mockDataForOperatorsDiary().then(res => {
            this.mockedData = res;
            return Promise.resolve(this.mockedData);
        });
    }

    get(params) {
        if (LocalStorageService.isDebugModeActive()) {
            return OperatorsDiaryService.getMockedData().then(res => {
                return filterMockedData(res, params);
            });
        }
        return CoreApi.get(this.resourceUrl, params).then(response => response.data, () => null);
    }

    getVesselGroups() {
        return CoreApi.get(`${this.resourceUrl}/VesselGroups`).then(response => response.data, () => null);
    }

    getTags() {
        return CoreApi.get(`${this.resourceUrl}/Tags`).then(response => response.data, () => null);
    }

    getEtbStatuses() {
        return CoreApi.get(`${this.resourceUrl}/EtbStatuses`).then(response => response.data, () => null);
    }

    getVoyageById(id) {
        return CoreApi.get(`${this.resourceUrl}/Voyages/${id}`).then(response => response.data, () => null);
    }

    updateItinerary(data) {
        return CoreApi.post(`${this.resourceUrl}/Itinerary`, data)
            .then(response => response.data, () => null);
    }

    getItineraryByVoyageId(id) {
        return CoreApi.get(`${this.resourceUrl}/Voyages/${id}/Itinerary`)
            .then(response => response.data.sort(sortByProp('Eta')), () => null);
    }

    getCargoByVoyageId(id) {
        return CoreApi.get(`${this.resourceUrl}/Voyages/${id}/Cargo`)
            .then(response => response.data, () => null);
    }
}

export default new OperatorsDiaryService();
