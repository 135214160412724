/* helpers */
import MockHelper from 'utils/helpers/mock-helper';
/* services */
import PortService from 'services/core-api/port-service';

let reportsArray = null;

const mockCargoOperationData = () => {
    const currentDate = new Date().valueOf();
    const offset = MockHelper.getRandomNumber(-12, 12) * 60;
    const minDate = currentDate - 180 * 24 * 3600 * 1000;
    return {
        CommencedDate: MockHelper.getRandomDate(minDate, currentDate),
        CommencedDateOffset: offset,
        CompletedDate: MockHelper.getRandomDate(minDate, currentDate),
        CompletedDateOffset: offset
    };
};

const mockCargoOperationsData = () => {
    return MockHelper.getRandomArrayOfItems(1, 6, null, mockCargoOperationData);
};

const mockSofReportData = (ports) => {
    const vessel = MockHelper.getRandomVessel();
    const offset = MockHelper.getRandomNumber(-12, 12) * 60;
    const currentDate = new Date().valueOf();
    const minDate = currentDate - 180 * 24 * 3600 * 1000;
    const pointPort = MockHelper.getRandomArrayItem(ports, 0.01);

    return {
        Point: {
            Id: pointPort.Id,
            Name: pointPort.Name
        },
        Charterer: MockHelper.getRandomWord().toUpperCase(),
        EndOfSeaPassage: MockHelper.getRandomDate(minDate, currentDate),
        EndOfSeaPassageOffset: offset,
        AnchoredCommencedDrifting: MockHelper.getRandomDate(minDate, currentDate),
        AnchoredCommencedDriftingOffset: offset,
        AnchorAweighStoppedDrifting: MockHelper.getRandomDate(minDate, currentDate),
        AnchorAweighStoppedDriftingOffset: offset,
        NoticeOfReadiness: MockHelper.getRandomDate(minDate, currentDate),
        NoticeOfReadinessOffset: offset,
        FreePractiqueGranted: MockHelper.getRandomDate(minDate, currentDate),
        FreePractiqueGrantedOffset: offset,
        PilotOnboard: MockHelper.getRandomDate(minDate, currentDate),
        PilotOnboardOffset: offset,
        AllFast: MockHelper.getRandomDate(minDate, currentDate),
        AllFastOffset: offset,
        HosesConnected: MockHelper.getRandomDate(minDate, currentDate),
        HosesConnectedOffset: offset,
        CargoOperations: mockCargoOperationsData(),
        HosesDisconnected: MockHelper.getRandomDate(minDate, currentDate),
        HosesDisconnectedOffset: offset,
        CargoDocumentsOnboard: MockHelper.getRandomDate(minDate, currentDate),
        CargoDocumentsOnboardOffset: offset,
        Unmoored: MockHelper.getRandomDate(minDate, currentDate),
        UnmooredOffset: offset,
        ReportId: MockHelper.getUniqueId('ReportId'),
        VesselName: vessel.Title,
        Imo: vessel.Imo,
        ReportTypeId: 7,
        ReportDate: MockHelper.getRandomDate(minDate, currentDate),
        ReportDateOffset: offset,
        ReportDateUtc: MockHelper.getRandomDate(minDate, currentDate),
        Remarks: MockHelper.getRandomText(),
        Voyage: {
            VoyageId: null,
            VoyageNumber: MockHelper.getRandomNumber(1000, 9000),
            StartDate: null,
            EndDate: null,
            VoyagePortInfo: null
        },
        LastModifiedBy: MockHelper.getRandomEmail(),
        LastModifiedDate: MockHelper.getRandomDate(minDate, currentDate),
        CallSign: MockHelper.getRandomCharSet(5).toUpperCase(),
        DataSourceId: 7,
        // sof reports don't have exclusions, suggestions or suspicious
        IsExcluded: false,
        ExclusionFields: {},
        ExclusionReasons: [],
        Suggestions: null,
        WrongReportsFields: {},
        ReportSubtype: {
            Id: 1,
            Name: 'Default',
            DisplayName: 'Default'
        }
    };
};

export const sofReportsMockedData = visibility => {
    const { included, excluded } = visibility;
    const dtoBasicShape = {
        Limit: 100,
        Offset: 0,
        PageItemsCount: 0,
        Reports: [],
        TotalPagesCount: 1
    };
    if (!reportsArray) {
        return PortService.get({ ImportanceLevel: 15 }).then(ports => {
            reportsArray = MockHelper.getRandomArrayOfItems(1, 10, null, () => mockSofReportData(ports));
            return {
                ...dtoBasicShape,
                PageItemsCount: reportsArray.length,
                Reports: reportsArray
            };
        });
    }
    if (!included && excluded) {
        const filteredExcluded = reportsArray.filter(report => report.IsExcluded);
        return {
            ...dtoBasicShape,
            PageItemsCount: filteredExcluded.length,
            Reports: filteredExcluded
        };
    }
    if (included && !excluded) {
        const filteredExcluded = reportsArray.filter(report => !report.IsExcluded);
        return {
            ...dtoBasicShape,
            PageItemsCount: filteredExcluded.length,
            Reports: filteredExcluded
        };
    }
    return {
        ...dtoBasicShape,
        PageItemsCount: reportsArray.length,
        Reports: reportsArray
    };
};
