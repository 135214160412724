/* utils */
import { t } from 'utils/i18n/i18n-model';
import { getSpeedSeverity, getConsumptionSeverity } from 'utils/helpers/info-helper';

const CONSUMPTION = t('UNITS.CONSUMPTION');
const SPEED = t('UNITS.SPEED');
const AVG_CONSUMPTION = t('UNITS.AVG_CONSUMPTION');

export const ballastLadenTotalRows = [{
    label: t('VOYAGE_PERFORMANCE.CONSUMPTION'),
    key: 'Consumption.Total',
    type: 'number',
    unit: CONSUMPTION,
    severityKey: 'Consumption.Budget',
    getSeverity: getConsumptionSeverity
}, {
    label: t('VOYAGE_PERFORMANCE.BUDGET'), key: 'Consumption.Budget', type: 'number', unit: CONSUMPTION
}, {
    label: t('VOYAGE_PERFORMANCE.SPEED'),
    key: 'Speed.Average',
    type: 'number',
    unit: SPEED,
    severityKey: 'Speed.Instructed',
    getSeverity: getSpeedSeverity
}, {
    label: t('VOYAGE_PERFORMANCE.INSTRUCTED'), key: 'Speed.Instructed', type: 'number', unit: SPEED
}];

export const cargoRows = [{
    label: t('VOYAGE_PERFORMANCE.CARGO.TOTAL_CARGO_QUANTITY'),
    key: 'TotalCargoQuantity',
    type: 'number',
    decimals: 0,
    unit: t('UNITS.CUBIC_CAPACITY')
}, {
    label: t('VOYAGE_PERFORMANCE.CARGO.TOTAL_WEIGHT'),
    key: 'TotalWeight',
    type: 'number',
    decimals: 0,
    unit: t('UNITS.CARGO')
}, {
    label: t('VOYAGE_PERFORMANCE.CARGO.VESSEL_UTILIZATION'),
    key: 'VesselUtilization',
    decimals: 0,
    type: 'number',
    unit: t('UNITS.PERCENTAGE')
}, {
    label: t('VOYAGE_PERFORMANCE.CARGO.CHARTERERS'), key: 'Charterers', type: 'array'
}, {
    label: t('VOYAGE_PERFORMANCE.CARGO.COATING'), key: 'Coating', type: 'string'
}];

export const consumptionTableColumns = ['AtSea.Total', 'AtSea.Ballast', 'AtSea.Laden', 'InPort.Total'];

export const consumptionTableRows = [{
    key: 'AverageConsumption',
    label: t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.AVERAGE_CONSUMPTION_PER_DAY'),
    type: 'number',
    unit: AVG_CONSUMPTION
}, {
    key: 'TotalMeConsumption',
    label: t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.TOTAL_ME_CONSUMPTION'),
    type: 'number',
    unit: CONSUMPTION
}, {
    key: 'AverageMeConsumption',
    label: t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.AVERAGE_ME_CONSUMPTION_PER_DAY'),
    type: 'number',
    unit: AVG_CONSUMPTION
}, {
    key: 'TotalAuxConsumption',
    label: t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.TOTAL_AUX_CONSUMPTION'),
    type: 'number',
    unit: CONSUMPTION
}, {
    key: 'AverageAuxConsumption',
    label: t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.AVERAGE_AUX_CONSUMPTION_PER_DAY'),
    type: 'number',
    unit: AVG_CONSUMPTION
}, {
    key: 'TotalCargoHeatingConsumption',
    label: t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.TOTAL_HEATING_CONSUMPTION'),
    type: 'number',
    unit: CONSUMPTION,
    tooltip: t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.BASED_ON_VALUES_FROM_ALL_REPORTS')
}, {
    key: 'AverageCargoHeatingConsumption',
    label: t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.AVERAGE_HEATING_CONSUMPTION_PER_DAY'),
    type: 'number',
    unit: AVG_CONSUMPTION,
    tooltip: t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.BASED_ON_VALUES_FROM_ALL_REPORTS')
}, {
    key: 'TotalLoadDischargeConsumption',
    label: t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.TOTAL_LOAD_DISCHARGE'),
    type: 'number',
    unit: CONSUMPTION
}, {
    key: 'AverageLoadDischargeConsumption',
    label: t('VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.AVERAGE_LOAD_DISCHARGE'),
    type: 'number',
    unit: AVG_CONSUMPTION
}];
