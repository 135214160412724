import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import {
    renderDataProps,
    renderFuelData,
    formatValue,
    fleetPerformanceReportTableHeaderTypeB
} from '../fleet-performance-report-helpers';
/* components */
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
/* constants */
import {
    cargoProps,
    emissionProps,
    otherProps,
    colWidths,
    fuelColWidth
} from './fleet-performance-report-type-b-constants';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.FLEET_PERFORMANCE_REPORT.${key}`);

class FleetPerformanceReportPerVoyageTypeB extends React.PureComponent {
    render() {
        const { performancePerVoyage: { fuelTypes, voyages, vesselName, vesselImo } } = this.props;
        const seaLadenCols = fuelTypes.seaLaden.length;
        const seaBallastCols = fuelTypes.seaBallast.length;
        const seaTotalCols = fuelTypes.sea.length;
        const seaConsCols = seaBallastCols + seaLadenCols + seaTotalCols;
        const canalConsCols = fuelTypes.canal.length;
        const portAnchoredCols = fuelTypes.anchoredDrifting.length;
        const portCargoLoadCols = fuelTypes.cargoLoad.length;
        const portCargoDischargeCols = fuelTypes.cargoDischarge.length;
        const portTotalCols = fuelTypes.port.length;
        const portConsCols = portAnchoredCols + portCargoLoadCols + portCargoDischargeCols + portTotalCols;
        const fuelColsWidth = ((seaConsCols * fuelColWidth.web) || 4)
            + ((canalConsCols * fuelColWidth.web) || 4)
            + ((portConsCols * fuelColWidth.web) || 4);
        const contentMinWidth = parseInt(
            Object.keys(colWidths).reduce((sum, key) => sum + colWidths[key].web, 0) + fuelColsWidth, 10
        );

        return (
            <FixedHeaderTable
                className="sten-energy-management-reports-preview__fleet-performance-per-voyage-table"
                contentStyle={{ minWidth: `${contentMinWidth}rem` }}
                updateTimeout={200}
                withHeaderColumn
                useCSS3Translate={false}
            >
                <table className="sten-table sten-table--xs">
                    {fleetPerformanceReportTableHeaderTypeB(
                        fuelTypes, fuelColWidth, emissionProps, colWidths, t('VOYAGE_NUMBER'), 'modal'
                    )}
                    <tbody key={`voyage.${vesselName}${vesselImo}`}>
                        {voyages.map(voyage => (
                            <tr key={`voyage.${voyage.VoyageNumber}`}>
                                <td>{voyage.VoyageNumber}</td>
                                {renderDataProps(voyage.Total, cargoProps)}
                                {renderFuelData(voyage.Total.ConsumptionsAtSeaBallast,
                                    fuelTypes.seaBallast, otherProps)
                                }
                                <td>{formatValue(voyage.Total.AvgConsumptionAtSeaBallast,
                                    otherProps.AvgConsumption.decimals)}
                                </td>
                                <td>{formatValue(voyage.Total.TotalConsumptionAtSeaBallast,
                                    otherProps.TotalConsumption.decimals)}
                                </td>
                                {renderFuelData(voyage.Total.ConsumptionsAtSeaLaden, fuelTypes.seaLaden, otherProps)}
                                <td>{formatValue(voyage.Total.AvgConsumptionAtSeaLaden,
                                    otherProps.AvgConsumption.decimals)}
                                </td>
                                <td>{formatValue(voyage.Total.TotalConsumptionAtSeaLaden,
                                    otherProps.TotalConsumption.decimals)}
                                </td>
                                {renderFuelData(voyage.Total.ConsumptionsAtSea, fuelTypes.sea, otherProps)}
                                <td>{formatValue(voyage.Total.AvgConsumptionAtSea,
                                    otherProps.AvgConsumption.decimals)}
                                </td>
                                <td>{formatValue(voyage.Total.TotalConsumptionAtSea,
                                    otherProps.TotalConsumption.decimals)}
                                </td>
                                {renderFuelData(voyage.Total.ConsumptionsInCanal, fuelTypes.canal, otherProps)}
                                <td>{formatValue(voyage.Total.AvgConsumptionInCanal,
                                    otherProps.AvgConsumption.decimals)}
                                </td>
                                <td>{formatValue(voyage.Total.TotalConsumptionInCanal,
                                    otherProps.TotalConsumption.decimals)}
                                </td>
                                {renderFuelData(voyage.Total.ConsumptionsAnchoredDrifting,
                                    fuelTypes.anchoredDrifting, otherProps)}
                                <td>{formatValue(voyage.Total.AvgConsumptionAnchoredDrifting,
                                    otherProps.AvgConsumption.decimals)}
                                </td>
                                <td>{formatValue(voyage.Total.TotalConsumptionAnchoredDrifting,
                                    otherProps.TotalConsumption.decimals)}
                                </td>
                                {renderFuelData(voyage.Total.ConsumptionsCargoLoad, fuelTypes.cargoLoad, otherProps)}
                                <td>{formatValue(voyage.Total.AvgConsumptionCargoLoad,
                                    otherProps.AvgConsumption.decimals)}
                                </td>
                                <td>{formatValue(voyage.Total.TotalConsumptionCargoLoad,
                                    otherProps.TotalConsumption.decimals)}
                                </td>
                                {renderFuelData(voyage.Total.ConsumptionsCargoDischarge,
                                    fuelTypes.cargoDischarge, otherProps)}
                                <td>{formatValue(voyage.Total.AvgConsumptionCargoDischarge,
                                    otherProps.AvgConsumption.decimals)}
                                </td>
                                <td>{formatValue(voyage.Total.TotalConsumptionCargoDischarge,
                                    otherProps.TotalConsumption.decimals)}
                                </td>
                                {renderFuelData(voyage.Total.ConsumptionsInPort, fuelTypes.port, otherProps)}
                                <td>{formatValue(voyage.Total.AvgConsumptionInPort,
                                    otherProps.AvgConsumption.decimals)}
                                </td>
                                <td>{formatValue(voyage.Total.TotalConsumptionInPort,
                                    otherProps.TotalConsumption.decimals)}
                                </td>
                                {renderDataProps(voyage.Total.Emissions, emissionProps)}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </FixedHeaderTable>
        );
    }
}

FleetPerformanceReportPerVoyageTypeB.propTypes = {
    performancePerVoyage: PropTypes.shape({
        fuelTypes: PropTypes.objectOf(PropTypes.any),
        voyages: PropTypes.arrayOf(PropTypes.any),
        vesselName: PropTypes.string,
        vesselImo: PropTypes.number
    })
};

FleetPerformanceReportPerVoyageTypeB.defaultProps = {
    performancePerVoyage: null
};

export default FleetPerformanceReportPerVoyageTypeB;
