import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { formatNumber, getObjectProp } from 'utils/helpers/info-helper';
/* components */
import FlipSwitch from 'components/flip-switch/flip-switch';
import Input from 'components/input/input';
import Validation from 'components/validation/validation';

class FuelRestrictionsTable extends React.Component {
    valueChangeHandlers = {};

    getValueChangeHandler = (prop, fuelTypeId) => {
        if (!this.valueChangeHandlers[`${prop}.${fuelTypeId}`]) {
            this.valueChangeHandlers[`${prop}.${fuelTypeId}`] = (value) => {
                const newValue = this.props.data.FuelRestrictions.map(fr => {
                    if (fr.FuelType.Id !== fuelTypeId) {
                        return fr;
                    }
                    return { ...fr, [prop]: value };
                });
                this.props.onRestrictionsChange(newValue);
            };
        }

        return this.valueChangeHandlers[`${prop}.${fuelTypeId}`];
    };

    renderValueCell = (data, prop, decimals, prefix, suffix) => {
        const value = getObjectProp(data, prop);
        if (this.props.isEditing) {
            return (
                <td className="sten-table__cell--has-input">
                    <Validation.Wrapper validations={this.props.validationRules[prop]} hintsOnHover>
                        <Input
                            className="sten-input--sm"
                            name={`FuelRestrictions.${this.props.nameSuffix}`
                                + `.${prop}.${data.FuelType.Id}`}
                            prefix={prefix}
                            suffix={suffix}
                            value={value}
                            onChange={this.getValueChangeHandler(prop, data.FuelType.Id)}
                        />
                    </Validation.Wrapper>
                </td>
            );
        }
        return <td>{`${prefix ? `${prefix} ` : ''}${formatNumber(value, decimals)}${suffix ? ` ${suffix}` : ''}`}</td>;
    };

    handleAllowanceTypeChange = () => {
        const { data, allowanceTypes } = this.props;
        const newValue = data.AllowanceType.Id === allowanceTypes[0].Id
            ? allowanceTypes[1]
            : allowanceTypes[0];
        this.props.onAllowanceTypeChange(newValue);
    };

    render() {
        const { data, isEditing, allowanceTypes } = this.props;
        if (!data.FuelRestrictions.length) {
            return null;
        }

        return (
            <table className="sten-charterparty-form-fuel-restrictions-table sten-table sten-table--xs">
                <thead>
                    <tr>
                        <th>{t('CHARTERPARTY_FORM.TABLE.FUEL')}</th>
                        <th>{t('CHARTERPARTY_FORM.TABLE.CONSUMPTION')}</th>
                        <th>
                            <div className="flex flex-center">
                                <div className="flex-grow">
                                    {t('CHARTERPARTY_FORM.TABLE.DEVIATION')}
                                </div>
                                <div className="flex-shrink">
                                    {isEditing && (
                                        <FlipSwitch
                                            doubleOn
                                            activeLabel="±"
                                            activeTitle={allowanceTypes[1].DisplayName}
                                            inactiveLabel="+"
                                            inactiveTitle={allowanceTypes[0].DisplayName}
                                            size="xs"
                                            value={data.AllowanceType.Name === 'PlusMinus'}
                                            onChange={this.handleAllowanceTypeChange}
                                        />
                                    )}
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.FuelRestrictions.map(fr => (
                        <tr key={fr.FuelType.Id}>
                            <th className="text-uppercase">{fr.FuelType.DisplayName}</th>
                            {this.renderValueCell(fr, 'Consumption', 1, '', t('UNITS.CONSUMPTION'))}
                            {this.renderValueCell(
                                fr,
                                'Allowance',
                                1,
                                data.AllowanceType.Name === 'PlusMinus' ? '±' : '+',
                                '%'
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
}

FuelRestrictionsTable.propTypes = {
    allowanceTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    isEditing: PropTypes.bool,
    nameSuffix: PropTypes.number,
    onAllowanceTypeChange: PropTypes.func.isRequired,
    onRestrictionsChange: PropTypes.func.isRequired,
    validationRules: PropTypes.shape({
        Allowance: PropTypes.objectOf(PropTypes.any),
        Consumption: PropTypes.objectOf(PropTypes.any)
    }).isRequired
};

FuelRestrictionsTable.defaultProps = {
    isEditing: false,
    nameSuffix: null
};

export default FuelRestrictionsTable;
