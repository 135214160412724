import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { formatNumber, getClassName, getObjectProp } from 'utils/helpers/info-helper';
/* components */
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
/* constants */
import { colWidths, fuelColWidth } from './quarterly-report-constants';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.QUARTERLY_REPORT.${key}`);
const tu = (key) => translate(`UNITS.${key}`);

class QuarterlyReportTable extends React.PureComponent {
    state = {
        collapsedAccordions: {},
        expandedSummaries: {}
    };

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            this.fixedHeaderTable.scrollArea.scrollLeft();
            this.fixedHeaderTable.scrollArea.refresh();
        }
    }

    toggleAccordion = (key, id) => () => {
        const collapsedAccordions = {
            ...this.state[key],
            [id]: !this.state[key][id]
        };
        this.setState({ [key]: collapsedAccordions });
    };

    renderDataRow = (data, key, label, columns, unit = '', showPlus = false) => (
        <tr key={key}>
            <td>{label}</td>
            {columns.map(col => {
                const val = getObjectProp(data[key], col.key);
                return (
                    <td key={col.key}>
                        {typeof val === 'number'
                            ? `${formatNumber(val, col.decimals, showPlus)}${col.unit || unit}`
                            : '-'}
                    </td>
                );
            })}
        </tr>
    );

    renderTableAccordionHeader = (key, label, colSpan, accordionClassName) => {
        const className = getClassName('sten-table__accordion', accordionClassName, {
            'sten-table__accordion--collapsed': this.state.collapsedAccordions[key]
        });

        return (
            <tbody key={key} onClick={this.toggleAccordion('collapsedAccordions', key)} className={className}>
                <tr>
                    <td>{label}</td>
                    <td colSpan={colSpan} />
                </tr>
            </tbody>
        );
    };

    renderSection = (data, qr, key, label, columns, headerColSpan, accordionClassName) => (
        <React.Fragment key={key}>
            {this.renderTableAccordionHeader(key, label, headerColSpan, accordionClassName)}
            {!this.state.collapsedAccordions[key] && (
                <tbody>
                    <tr className="sten-energy-management-reports-quarterly-report-table__row--section">
                        <td>{`${t('QUARTER')} ${qr.quarter.Name}`}</td>
                        <td colSpan={headerColSpan} />
                    </tr>
                    {this.renderDataRow(data, 'QuarterSelected', qr.year, columns)}
                    {this.renderDataRow(data, 'QuarterPrevious', qr.year - 1, columns)}
                    {this.renderDataRow(data, 'QuarterDelta', t('DELTA'), columns)}
                    {this.renderDataRow(
                        data, 'QuarterDeltaPercentage', t('DELTA_PERCENTAGE'), columns, tu('PERCENTAGE'), true
                    )}
                    <tr className="sten-energy-management-reports-quarterly-report-table__row--section">
                        <td>{t('YEAR_TO_DATE')}</td>
                        <td colSpan={headerColSpan} />
                    </tr>
                    {this.renderDataRow(data, 'YearToDateSelected', qr.year, columns)}
                    {this.renderDataRow(data, 'YearToDatePrevious', qr.year - 1, columns)}
                    {this.renderDataRow(data, 'YearToDateDelta', t('DELTA'), columns)}
                    {this.renderDataRow(
                        data, 'YearToDateDeltaPercentage', t('DELTA_PERCENTAGE'), columns, tu('PERCENTAGE'), true
                    )}
                    <tr className="sten-energy-management-reports-quarterly-report-table__row--section">
                        <td>{`${t('ROLLING')} ${qr.quarter.Name}`}</td>
                        <td colSpan={headerColSpan} />
                    </tr>
                    {this.renderDataRow(data, 'RollingSelected', qr.year, columns)}
                    {this.renderDataRow(data, 'RollingPrevious', qr.year - 1, columns)}
                    {this.renderDataRow(data, 'RollingDelta', t('DELTA'), columns)}
                    {this.renderDataRow(
                        data, 'RollingDeltaPercentage', t('DELTA_PERCENTAGE'), columns, tu('PERCENTAGE'), true
                    )}
                </tbody>
            )}
        </React.Fragment>
    );

    saveRef = c => { this.fixedHeaderTable = c; };

    render() {
        const selectedQuarter = this.props.selectedQuarter;
        const { columns, parsedData, fuelTypes } = this.props.data;

        const headerColSpan = 11 + fuelTypes.length;
        const fuelColsWidth = fuelTypes.length * fuelColWidth.web;
        const contentMinWidth = parseInt(
            Object.keys(colWidths).reduce((sum, key) => sum + colWidths[key].web, 0) + fuelColsWidth, 10
        );

        return (
            <FixedHeaderTable
                className="sten-energy-management-reports-quarterly-report-table"
                contentStyle={{ minWidth: `${contentMinWidth}rem` }}
                updateTimeout={200}
                withHeaderColumn
                useCSS3Translate={false}
                ref={this.saveRef}
            >
                <table className="sten-table sten-table--xs">
                    <thead>
                        <tr>
                            <th rowSpan="2" style={{ minWidth: `${colWidths.Value.web}rem` }}>{t('VALUE')}</th>
                            <th colSpan="5" className="text-center">{t('BALLAST_LADEN_RATIO')}</th>
                            <th
                                rowSpan="2"
                                style={{ minWidth: `${colWidths.OffHireDays.web}rem` }}
                                className="text-center"
                            >
                                {t('OFF_HIRE_DAYS')}
                            </th>
                            <th colSpan={(fuelTypes.length + 1)} className="text-center">{t('USED_BUNKER')}</th>
                            <th colSpan="4" className="text-center">{t('EMISSIONS')}</th>
                        </tr>
                        <tr>
                            <th style={{ minWidth: `${colWidths.LadenPercentageBasedOnDistance.web}rem` }}>
                                {t('LADEN_PERCENTAGE_BASED_ON_DISTANCE')}
                            </th>
                            <th style={{ minWidth: `${colWidths.BallastPercentageBasedOnDistance.web}rem` }}>
                                {t('BALLAST_PERCENTAGE_BASED_ON_DISTANCE')}
                            </th>
                            <th style={{ minWidth: `${colWidths.LadenPercentageBasedOnTime.web}rem` }}>
                                {t('LADEN_PERCENTAGE_BASED_ON_TIME')}
                            </th>
                            <th style={{ minWidth: `${colWidths.BallastPercentageBasedOnTime.web}rem` }}>
                                {t('BALLAST_PERCENTAGE_BASED_ON_TIME')}
                            </th>
                            <th style={{ minWidth: `${colWidths.DaysInPortPercentage.web}rem` }}>
                                {t('DAYS_IN_PORT_PERCENTAGE')}
                            </th>
                            {fuelTypes.map(fuelType => (
                                <th key={`bunkerData.${fuelType.Id}`} style={{ minWidth: `${fuelColWidth.web}rem` }}>
                                    {`${fuelType.Name} (${tu('CONSUMPTION')})`}
                                </th>
                            ))}
                            <th style={{ minWidth: `${colWidths.BunkerTotal.web}rem` }}>{t('TOTAL')}</th>
                            <th style={{ minWidth: `${colWidths.CO2.web}rem` }}>{`CO2 (${tu('EMISSIONS')})`}</th>
                            <th style={{ minWidth: `${colWidths.SOx.web}rem` }}>{`SOx (${tu('EMISSIONS')})`}</th>
                            <th style={{ minWidth: `${colWidths.NOx.web}rem` }}>{`NOx (${tu('EMISSIONS')})`}</th>
                            <th style={{ minWidth: `${colWidths.PM.web}rem` }}>{`PM (${tu('EMISSIONS')})`}</th>
                        </tr>
                    </thead>
                    {parsedData.Segments.map(sg => this.renderSection(
                        sg.Summary, selectedQuarter, `Segment.${sg.Id}`, sg.Name, columns, headerColSpan
                    ))}
                    {this.renderSection(
                        parsedData.Summary,
                        selectedQuarter,
                        'Total',
                        t('TOTAL'),
                        columns,
                        headerColSpan,
                        'sten-table__accordion--secondary'
                    )}
                </table>
            </FixedHeaderTable>
        );
    }
}

QuarterlyReportTable.propTypes = {
    data: PropTypes.shape({
        columns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        fuelTypes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        parsedData: PropTypes.objectOf(PropTypes.any)
    }),
    selectedQuarter: PropTypes.shape({
        quarter: PropTypes.objectOf(PropTypes.any),
        year: PropTypes.number
    }).isRequired
};

QuarterlyReportTable.defaultProps = {
    data: null
};

export default QuarterlyReportTable;
