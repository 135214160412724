import { takeEvery, put, select, all } from 'redux-saga/effects';
/* utils */
import TimeHelper from 'utils/helpers/time-helper';
import {
    getRoundedDate,
    getTimestampsFetchParams,
    extractTimestamps,
    getLatestRuntime,
    findClosestTimestamp
} from './dtn-weather-utils';
/* actions */
import { ActionTypes } from './dtn-weather-actions';
import { ActionTypes as UserActionTypes } from 'pages/user-pages/user-actions';
import { ActionTypes as DatePickerActionTypes } from 'pages/user-pages/home/map-date-picker/map-date-picker-actions';
/* services */
import WeatherService from 'services/core-api/weather-service';
import ConfigService from 'services/config-api/config-service';
/* constants */
import {
    getWeatherLayerConfigs,
    TIMESTAMP_INTERVAL,
    LAYER_DATA_TYPES
} from './dtn-weather-constants';

let areAvailableLayersFetched = false;
let fetchingAvailableLayers = false;

function* getAvailableLayers() {
    if (!areAvailableLayersFetched && !fetchingAvailableLayers) {
        fetchingAvailableLayers = true;
        const layers = yield WeatherService.getCatalogLayers();
        fetchingAvailableLayers = false;
        if (layers) {
            areAvailableLayersFetched = true;
            const weatherLayerConfigs = getWeatherLayerConfigs();
            yield put({
                type: ActionTypes.DTN_WEATHER_SET_AVAILABLE_LAYERS,
                payload: layers
                    .filter(layer => !!weatherLayerConfigs[layer.id])
                    .map(layer => (weatherLayerConfigs[layer.id].Label
                        ? { ...layer, label: weatherLayerConfigs[layer.id].Label }
                        : layer))
            });
        }
    }
}

const prevTs = {};

function* getSelectedLayersTimestamps(action) {
    if (ConfigService.featureToggles.showDTNWeather) {
        const {
            MapWeatherLayerTileIdsV2,
            MapOptionsWeatherLayerEnabled
        } = yield select(state => state.userReducer.settings);
        const { layerTimestamps, ...rest } = yield select(state => state.dtnWeatherReducer);
        const layerData = action.layerData || rest.layerData;
        const layerIds = action.layerIds || MapWeatherLayerTileIdsV2;
        const isEnabled = action.isEnabled || MapOptionsWeatherLayerEnabled;
        const selectedTimestamp = TimeHelper
            .convertISOString(new Date(action.timestamp || rest.selectedTimestamp).toISOString());
        const roundedTimestampDate = getRoundedDate(selectedTimestamp, TIMESTAMP_INTERVAL);
        const roundedRuntimeDate = getLatestRuntime(roundedTimestampDate);
        const roundedTimestamp = TimeHelper.convertISOString(roundedTimestampDate.toISOString());
        const roundedRuntime = TimeHelper.convertISOString(roundedRuntimeDate.toISOString());
        const weatherLayerConfigs = getWeatherLayerConfigs();
        if (isEnabled && layerData) {
            const request = {};
            let timestamps = { ...layerTimestamps };
            let shouldFetchData = false;
            layerIds.forEach(layerId => {
                if (layerId) {
                    if (!timestamps[layerId]) {
                        timestamps[layerId] = {};
                    }
                    if (weatherLayerConfigs[layerId]?.LayerDataType === LAYER_DATA_TYPES.FORECAST) {
                        if (roundedTimestamp >= layerData[layerId].metadata.oldestDataAvailable
                            && roundedTimestamp !== prevTs[layerId]) {
                            prevTs[layerId] = roundedTimestamp;
                            if (!timestamps[layerId][roundedTimestamp]
                                || timestamps[layerId][roundedTimestamp] < roundedRuntime) {
                                timestamps[layerId][roundedTimestamp] = '';
                                const fetchParams = getTimestampsFetchParams(
                                    roundedRuntimeDate, layerData[layerId].metadata.oldestDataAvailable
                                );
                                shouldFetchData = true;
                                request[layerId] = WeatherService.getLayerTimestamps(layerId, fetchParams);
                            }
                        }
                    } else if (selectedTimestamp > layerData[layerId].metadata.oldestDataAvailable) {
                        const obTimestamp = findClosestTimestamp(timestamps[layerId], selectedTimestamp);
                        if (!obTimestamp
                            || TimeHelper.getDateDiff(obTimestamp, selectedTimestamp) > TimeHelper.units.hour) {
                            const fetchParams = getTimestampsFetchParams(
                                new Date(selectedTimestamp), layerData[layerId].metadata.oldestDataAvailable, true
                            );
                            shouldFetchData = true;
                            request[layerId] = WeatherService.getLayerTimestamps(layerId, fetchParams);
                        }
                    }
                }
            });
            if (shouldFetchData) {
                const res = yield all(request);
                timestamps = extractTimestamps(res, timestamps);
                return yield put({
                    type: ActionTypes.DTN_WEATHER_SET_LAYER_TIMESTAMPS, timestamps, roundedTimestamp, selectedTimestamp
                });
            }
        }
        return yield put({ type: ActionTypes.DTN_WEATHER_SET_LAYER_TIMESTAMPS, roundedTimestamp, selectedTimestamp });
    }
    return null;
}

let prevSelectedLayerIds = null;

function* getSelectedLayersData(action) {
    const {
        MapWeatherLayerTileIdsV2,
        MapOptionsWeatherLayerEnabled
    } = yield select(state => state.userReducer.settings);
    const { layerData } = yield select(state => state.dtnWeatherReducer);
    const layerIds = action?.layerIds || MapWeatherLayerTileIdsV2;
    const isEnabled = action?.isEnabled || MapOptionsWeatherLayerEnabled;
    if (isEnabled && layerIds !== prevSelectedLayerIds) {
        prevSelectedLayerIds = layerIds;
        const request = {};
        let shouldFetchData = false;
        layerIds.forEach(layerId => {
            if (layerId && (!layerData || !layerData[layerId])) {
                shouldFetchData = true;
                request[layerId] = all({
                    metadata: WeatherService.getLayerMetadata(layerId)
                });
            }
        });
        if (shouldFetchData) {
            const res = yield all(request);
            const payload = { ...layerData, ...res };
            yield put({ type: ActionTypes.DTN_WEATHER_SET_LAYER_DATA, payload });
            return payload;
        }
    }
    return layerData;
}

function* initialize() {
    yield getAvailableLayers();
    const layerData = yield getSelectedLayersData();
    yield getSelectedLayersTimestamps({
        layerData,
        timestamp: TimeHelper.getStartOf('hour', null, false).toISOString()
    });
}

function* getUserWeatherLayers(action) {
    if (ConfigService.featureToggles.showDTNWeather) {
        const { settings, newSettings } = action;
        if (newSettings.MapWeatherLayerTileIdsV2 || newSettings.MapOptionsWeatherLayerEnabled) {
            const params = {
                layerIds: settings.MapWeatherLayerTileIdsV2,
                isEnabled: settings.MapOptionsWeatherLayerEnabled
            };
            const layerData = yield getSelectedLayersData(params);
            yield getSelectedLayersTimestamps({ ...params, layerData });
        }
    }
}

export default function* dtnWeatherSaga() {
    yield takeEvery(ActionTypes.DTN_WEATHER_INITIALIZE, initialize);
    yield takeEvery(ActionTypes.DTN_WEATHER_GET_AVAILABLE_LAYERS, getAvailableLayers);
    yield takeEvery(ActionTypes.DTN_WEATHER_GET_DATA_FOR_TIMESTAMP, getSelectedLayersTimestamps);
    yield takeEvery(
        DatePickerActionTypes.MAP_DATE_PICKER_SET_DATE,
        (action) => getSelectedLayersTimestamps({ type: action.type, timestamp: action.selectedDate })
    );
    yield takeEvery(UserActionTypes.USER_SETTINGS_SET, getUserWeatherLayers);
}
