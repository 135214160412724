import getTitle from './title-config';
import getFontSize from 'utils/helpers/cached-font-size';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* constants */
import { chartConfigBaseColors, formatter } from './chart-config-constants';

const chartColors = {
    CO2: '#fec83d',
    CH4: '#c7ff85',
    CO: '#9575cd',
    N2O: '#32c7d9',
    NMVOC: '#79909b'
};

const getEmissionConfig = () => {
    const rem = getFontSize();
    const fsm = rem / 16;
    return {
        chart: {
            backgroundColor: chartConfigBaseColors.colorChartBg,
            plotBackgroundColor: chartConfigBaseColors.colorChartPlotBg,
            spacing: [2 * rem, rem, rem, 2.5 * rem],
            resetZoomButton: { theme: { display: 'none' } }
        },
        xAxis: {
            gridLineColor: chartConfigBaseColors.colorXAxisGridLine,
            gridZIndex: 1,
            tickPixelInterval: 400,
            tickWidth: 0,
            labels: {
                enabled: false
            },
            startOnTick: true
        },
        yAxis: {
            minTickInterval: 2,
            tickAmount: 3,
            labels: {
                reserveSpace: false
            },
            title: {
                ...getTitle(fsm),
                text: t('VOYAGE_PERFORMANCE.CHARTS.EMISSIONS_MT')
            }
        },
        legend: {
            itemMarginTop: 10 * fsm
        },
        tooltip: {
            formatter
        },
        series: [
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.CO2'),
                type: 'area',
                color: chartColors.CO2,
                lineWidth: 2 * fsm,
                data: [],
                zIndex: 2,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            },
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.CH4'),
                type: 'area',
                color: chartColors.CH4,
                lineWidth: 2 * fsm,
                data: [],
                zIndex: 2,
                visible: false,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            },
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.CO'),
                type: 'area',
                color: chartColors.CO,
                lineWidth: 2 * fsm,
                data: [],
                zIndex: 2,
                visible: false,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            },
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.N2O'),
                type: 'area',
                color: chartColors.N2O,
                lineWidth: 2 * fsm,
                zIndex: 2,
                visible: false,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            },
            {
                name: t('VOYAGE_PERFORMANCE.CHARTS.NMVOC'),
                type: 'area',
                color: chartColors.NMVOC,
                lineWidth: 2 * fsm,
                zIndex: 2,
                visible: false,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            }
        ]
    };
};

export default getEmissionConfig;
