import React from 'react';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { commonFields, renderLabel } from 'components/form-fields/form-fields-helper';
/* components */
import Input from 'components/input/input';
import ColumnNumberValuesTable from 'components/column-values-table/column-values-table';
import ContentArray from 'components/content-array/content-array';
import FuelDataTable from 'components/fuel-data-table/fuel-data-table';
import Validation from 'components/validation/validation';
import VesselSelectInScope from 'components/vessel-select/vessel-select-in-scope';
/* constants */
import { validations } from './questionnaire-validations';

const isPortOfProlongedPortStayDisabled = (item) => {
    return item.ProlongedPortStays !== true;
};

export const fieldMap = {

    // Basic Info

    Vessel: {
        label: renderLabel(t('QUESTIONNAIRE.LABELS.VESSEL')),
        view: item => <label className="label label--value">{`${item.VesselName} (${item.Imo})`}</label>,
        edit: (item, handleChange) => (
            <Validation.Wrapper hintsOnHover validations={validations.Vessel}>
                <VesselSelectInScope
                    fixedListWidth
                    name="Vessel"
                    clearable
                    value={item.Vessel}
                    title={t('QUESTIONNAIRE.LABELS.SEARCH_TITLE')}
                    onChange={handleChange('Vessel')}
                />
            </Validation.Wrapper>
        )
    },
    CallSign: commonFields.String(t('QUESTIONNAIRE.LABELS.CALL_SIGN'), 'CallSign', '', validations.CallSign),
    ReportDate: {
        ...commonFields.DateTime(
            t('QUESTIONNAIRE.LABELS.REPORT_DATE'),
            'ReportDate',
            'ReportDateOffset',
            validations.ReportDate,
            validations.ReportDateOffset
        ),
        view: undefined
    },

    // Bunker ROB

    CleaningEquipmentOnBoard: commonFields.Textarea(
        t('QUESTIONNAIRE.LABELS.CLEANING_EQUIPMENT_ON_BOARD'),
        'CleaningEquipmentOnBoard',
        validations.CleaningEquipmentOnBoard
    ),
    LastUwInspection: commonFields.DateTime(
        t('QUESTIONNAIRE.LABELS.DATE'),
        'LastUwInspection',
        'LastUwInspectionOffset',
        validations.LastUwInspection,
        validations.LastUwInspectionOffset
    ),
    PortOfLastUwInspection: commonFields.Point(
        t('QUESTIONNAIRE.LABELS.PORT'),
        'PortOfLastUwInspection',
        validations.PortOfLastUwInspection
    ),
    LastUwCleaning: commonFields.DateTime(
        t('QUESTIONNAIRE.LABELS.DATE'),
        'LastUwCleaning',
        'LastUwCleaningOffset',
        validations.LastUwCleaning,
        validations.LastUwCleaningOffset
    ),
    PortOfLastUwCleaning: commonFields.Point(
        t('QUESTIONNAIRE.LABELS.PORT'),
        'PortOfLastUwCleaning',
        validations.PortOfLastUwCleaning
    ),
    LastPropellerPolish: commonFields.DateTime(
        t('QUESTIONNAIRE.LABELS.DATE'),
        'LastPropellerPolish',
        'LastPropellerPolishOffset',
        validations.LastPropellerPolish,
        validations.LastPropellerPolishOffset
    ),
    PortOfLastPropellerPolish: commonFields.Point(
        t('QUESTIONNAIRE.LABELS.PORT'),
        'PortOfLastPropellerPolish',
        validations.PortOfLastPropellerPolish
    ),
    ProlongedPortStays: commonFields.FlipSwitch(
        t('QUESTIONNAIRE.LABELS.ANSWER'),
        'ProlongedPortStays',
        validations.ProlongedPortStays
    ),
    PortOfProlongedPortStay: commonFields.Point(
        t('QUESTIONNAIRE.LABELS.PORT'),
        'PortOfProlongedPortStay',
        validations.PortOfProlongedPortStay,
        isPortOfProlongedPortStayDisabled
    ),

    // Propeller characteristics

    PitchOfPropeller: commonFields.Number(
        t('QUESTIONNAIRE.LABELS.PITCH_OF_PROPELLER'),
        'PitchOfPropeller',
        2,
        validations.PitchOfPropeller,
        t('UNITS.METER')
    ),
    PropellerDiameter: commonFields.Number(
        t('QUESTIONNAIRE.LABELS.PROPELLER_DIAMETER'),
        'PropellerDiameter',
        2,
        validations.PropellerDiameter,
        t('UNITS.METER')
    ),
    PropellerNumberOfBlades: commonFields.Number(
        t('QUESTIONNAIRE.LABELS.NUMBER_OF_BLADES'),
        'PropellerNumberOfBlades',
        0,
        validations.PropellerNumberOfBlades
    ),
    PropellerExpandedArea: commonFields.Number(
        t('QUESTIONNAIRE.LABELS.EXPANDED_AREA'),
        'PropellerExpandedArea',
        2,
        validations.PropellerExpandedArea,
        t('UNITS.AREA')
    ),
    PropellerAverageSlip: commonFields.Number(
        t('QUESTIONNAIRE.LABELS.AVERAGE_SLIP'),
        'PropellerAverageSlip',
        2,
        validations.PropellerAverageSlip,
        t('UNITS.PERCENTAGE')
    ),
    PropellerEnergySavingDevices: commonFields.Textarea(
        t('QUESTIONNAIRE.LABELS.ENERGY_SAVING_DEVICES_FITTER'),
        'PropellerEnergySavingDevices',
        validations.PropellerEnergySavingDevices
    ),

    // Operational engine limits

    OelTorque: commonFields.Number(
        t('QUESTIONNAIRE.LABELS.TORQUE'),
        'OelTorque',
        2,
        validations.OelTorque,
        t('UNITS.TORQUE')
    ),
    OelPower: commonFields.Number(
        t('QUESTIONNAIRE.LABELS.POWER'),
        'OelPower',
        2,
        validations.OelPower,
        t('UNITS.PERCENTAGE')
    ),
    OelRpm: commonFields.Number(
        t('QUESTIONNAIRE.LABELS.RPM'),
        'OelRpm',
        0,
        validations.OelRpm
    ),

    // Port visits

    PortVisits: {
        ReportPort: commonFields.Point(
            t('QUESTIONNAIRE.LABELS.PORT'),
            'ReportPort',
            validations.PortVisits.ReportPort,
            false,
            { excludePoints: true }
        ),
        Charterer: commonFields.String(
            t('QUESTIONNAIRE.LABELS.CHARTERER'),
            'Charterer',
            null,
            validations.PortVisits.Charterer
        ),
        Cargo: commonFields.String(
            t('QUESTIONNAIRE.LABELS.CARGO'),
            'Cargo',
            null,
            validations.PortVisits.Cargo
        )
    },

    // RPM range

    RpmMinMax: {
        label: renderLabel(t('QUESTIONNAIRE.LABELS.RPM')),
        view: item => (
            <div className="flex-row flex-center">
                <div className="col-4">
                    <label className="label">{t('QUESTIONNAIRE.LABELS.MIN')}</label>
                </div>
                <div className="col-8">
                    <label className="label label--value">{item.RpmMin}</label>
                </div>
                <div className="col-4">
                    <label className="label">{t('QUESTIONNAIRE.LABELS.MAX')}</label>
                </div>
                <div className="col-8">
                    <label className="label label--value">{item.RpmMax}</label>
                </div>
            </div>
        ),
        edit: (item, handleChange) => (
            <div className="flex-row flex-center">
                <div className="col-4">
                    <label className="label">{t('QUESTIONNAIRE.LABELS.MIN')}</label>
                </div>
                <div className="col-8">
                    <Validation.Wrapper hintsOnHover validations={validations.RpmMin}>
                        <Input
                            name="RpmMin"
                            value={item.RpmMin || ''}
                            onChange={handleChange('RpmMin')}
                        />
                    </Validation.Wrapper>
                </div>
                <div className="col-4">
                    <label className="label">{t('QUESTIONNAIRE.LABELS.MAX')}</label>
                </div>
                <div className="col-8">
                    <Validation.Wrapper hintsOnHover validations={validations.RpmMax}>
                        <Input
                            name="RpmMax"
                            value={item.RpmMax || ''}
                            onChange={handleChange('RpmMax')}
                        />
                    </Validation.Wrapper>
                </div>
            </div>
        )
    },
    SpeedAndConsumptionDetails: commonFields.Textarea(
        t('QUESTIONNAIRE.LABELS.SPEED_AND_CONSUMPTION_DETAILS'),
        'SpeedAndConsumptionDetails',
        validations.SpeedAndConsumptionDetails
    ),

    // Remarks

    Remarks: commonFields.Textarea(null, 'Remarks', validations.Remarks)
};

const speedDetailsRowConfig = [
    {
        prop: 'MinimumSpeed',
        label: t('QUESTIONNAIRE.LABELS.MIN_SPEED'),
        formatProps: {
            decimals: 1
        },
        validations: validations.SpeedDetails.MinimumSpeed
    },
    {
        prop: 'RpmAtMinimumSpeed',
        label: t('QUESTIONNAIRE.LABELS.MIN_SPEED_RPM'),
        formatProps: {
            decimals: 0
        },
        validations: validations.SpeedDetails.RpmAtMinimumSpeed
    }
];

const speedInformationRowConfig = [
    {
        prop: 'Speed',
        label: t('QUESTIONNAIRE.LABELS.SPEED_KN'),
        formatProps: {
            decimals: 1
        },
        validations: validations.SpeedInformations.Speed
    },
    {
        prop: 'TotalConsumption',
        label: t('QUESTIONNAIRE.LABELS.TOTAL_CONSUMPTION'),
        formatProps: {
            decimals: 2
        },
        validations: validations.SpeedInformations.TotalConsumption
    },
    {
        prop: 'NormalRpm',
        label: t('QUESTIONNAIRE.LABELS.NORMAL_RPM'),
        formatProps: {
            decimals: 0
        },
        validations: validations.SpeedInformations.NormalRpm
    }
];

const portVisitsSections = [
    {
        fields: [
            'ReportPort',
            'Charterer',
            'Cargo'
        ]
    }
];

const emptyPortVisit = {
    ReportPort: null,
    Charterer: null,
    Cargo: null
};

const renderVesselCondition = item => item.VesselCondition.Name;
const renderSpeedInformationHeaderRow = data => (
    <tr>
        <th className="text-secondary">{t('QUESTIONNAIRE.LABELS.TYPE')}</th>
        <th colSpan="2" className="text-secondary text-center text-uppercase">{data[0].SpeedType.Name}</th>
        {data[2] && (
            <th colSpan="2" className="text-secondary text-center text-uppercase">{data[2].SpeedType.Name}</th>
        )}
    </tr>
);

export const dataMap = {
    BunkerData: (params) => (
        <FuelDataTable
            key={params.index}
            name="BunkerData"
            data={params.data.BunkerData}
            onChange={params.handleChange('BunkerData')}
            isEditing={params.isEditing}
            fuelTypeOptions={params.editingOptions.fuelTypes}
            fuelTypeValidations={validations.BunkerData.FuelTypes}
            rowOptions={params.editingOptions.bunkerTypes}
            rowOptionsLabel={t('QUESTIONNAIRE.LABELS.BUNKER_TYPES')}
            rowOptionValidations={validations.BunkerData.BunkerTypes}
            fieldValidations={validations.BunkerData.Fields}
        />
    ),
    SpeedDetails: (params) => (
        <ColumnNumberValuesTable
            key={params.index}
            rowConfig={speedDetailsRowConfig}
            isEditing={params.isEditing}
            title={t('QUESTIONNAIRE.LABELS.STATE')}
            name="SpeedDetails"
            onChange={params.handleChange('SpeedDetails')}
            renderLabel={renderVesselCondition}
            data={params.data.SpeedDetails}
        />
    ),
    SpeedInformations: (params) => (
        <ColumnNumberValuesTable
            key={params.index}
            rowConfig={speedInformationRowConfig}
            isEditing={params.isEditing}
            name="SpeedInformation"
            title={t('QUESTIONNAIRE.LABELS.STATE')}
            onChange={params.handleChange('SpeedInformations')}
            renderLabel={renderVesselCondition}
            renderHeaderRow={renderSpeedInformationHeaderRow}
            data={params.data.SpeedInformations}
        />
    ),
    PortVisits: (params) => (params.data.PortVisits?.length || params.isEditing
        ? (
            <ContentArray
                hasFieldsWithHandlerCreators
                fieldMap={fieldMap.PortVisits}
                sections={portVisitsSections}
                onChange={params.handleChange(('PortVisits'))}
                data={params.data.PortVisits}
                isEditing={params.isEditing}
                editingOptions={params.editingOptions}
                newItem={emptyPortVisit}
                itemTitle={t('QUESTIONNAIRE.LABELS.PORT_VISIT')}
                minLength={0}
                maxLength={10}
            />
        )
        : (
            <div className="sten-questionnaire__section">
                <label className="label">{t('QUESTIONNAIRE.LABELS.NO_PORT_VISITS_AVAILABLE')}</label>
            </div>
        )
    )
};
