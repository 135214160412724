import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* helpers */
import { formatNumber, getConsumptionSeverity, getSpeedSeverity } from 'utils/helpers/info-helper';
import { getPanelRows } from 'utils/helpers/template-helper';
/* components */
import Accordion from 'components/accordion/accordion';

export class VesselInfoEnergyAverageVoyageData extends React.PureComponent {
    getValues = voyagePerformance => {
        const columnClasses = ['col-8', 'col-7', 'col-9'];
        const sections = [];

        const totalSpeedSeverityClass = getSpeedSeverity(
            voyagePerformance.TotalVoyage.Speed.Average,
            voyagePerformance.TotalVoyage.Speed.Instructed
        );
        const goodWeatherSpeedSeverityClass = getSpeedSeverity(
            voyagePerformance.TotalVoyage.GoodWeatherSpeed.Average,
            voyagePerformance.TotalVoyage.GoodWeatherSpeed.Instructed
        );
        const totalConsumptionSeverityClass = getConsumptionSeverity(
            voyagePerformance.TotalVoyage.Consumption.Total,
            voyagePerformance.TotalVoyage.Consumption.Budget
        );
        const goodWeatherConsumptionSeverityClass = getConsumptionSeverity(
            voyagePerformance.TotalVoyage.GoodWeatherConsumption.Total,
            voyagePerformance.TotalVoyage.GoodWeatherConsumption.Budget
        );
        sections.push(
            getPanelRows(
                columnClasses,
                [[
                    <label key="avgSpeedLabel" className="sten-panel__label">
                        {t('VESSEL_INFO.ENERGY.AVERAGE_VOYAGE_DATA.AVERAGE_SPEED_TOTAL_VOYAGE')}
                    </label>,
                    <label
                        key="avgSpeedValue"
                        className={`sten-panel__label sten-panel__label--value ${totalSpeedSeverityClass}`}
                    >
                        {typeof (voyagePerformance.TotalVoyage.Speed.Average) === 'number'
                            ? `${formatNumber(voyagePerformance.TotalVoyage.Speed.Average)} ${t('UNITS.SPEED')}`
                            : '-'
                        }
                    </label>,
                    <label key="insSpeedLabel" className="sten-panel__label">
                        {t('VESSEL_INFO.ENERGY.INSTRUCTED')}:
                        <span className="sten-panel__label--value">
                            {typeof (voyagePerformance.TotalVoyage.Speed.Instructed) === 'number'
                                ? ` ${formatNumber(voyagePerformance.TotalVoyage.Speed.Instructed)}`
                                + ` ${t('UNITS.SPEED')}`
                                : ' -'
                            }
                        </span>
                    </label>
                ]]
            )
        );
        sections.push(
            getPanelRows(
                columnClasses,
                [[
                    <label key="totalConsumptionLabel" className="sten-panel__label">
                        {t('VESSEL_INFO.ENERGY.AVERAGE_VOYAGE_DATA.TOTAL_CONSUMPTION_TOTAL_VOYAGE')}
                    </label>,
                    <label
                        key="totalConsumptionValue"
                        className={`sten-panel__label sten-panel__label--value ${totalConsumptionSeverityClass}`}
                    >
                        {
                            typeof (voyagePerformance.TotalVoyage.Consumption.Total === 'number')
                            && typeof (voyagePerformance.TotalVoyage.Consumption.Budget === 'number')
                            && `${formatNumber(voyagePerformance.TotalVoyage.Consumption.Total
                                - voyagePerformance.TotalVoyage.Consumption.Budget, 1, true)}`
                                + ` ${t('UNITS.CONSUMPTION')}`
                        }
                    </label>,
                    [
                        <label key="totalConsumptionLabel" className="sten-panel__label">
                            {t('VESSEL_INFO.ENERGY.CONSUMPTION')}:
                            <span className="sten-panel__label--value">
                                {typeof (voyagePerformance.TotalVoyage.Consumption.Total) === 'number'
                                    ? ` ${formatNumber(voyagePerformance.TotalVoyage.Consumption.Total)}`
                                        + ` ${t('UNITS.CONSUMPTION')}`
                                    : ' -'
                                }
                            </span>
                        </label>,
                        <label key="totalConsumptionBudget" className="sten-panel__label">
                            {t('VESSEL_INFO.ENERGY.BUDGET')}:
                            <span className="sten-panel__label--value">
                                {typeof (voyagePerformance.TotalVoyage.Consumption.Budget) === 'number'
                                    ? ` ${formatNumber(voyagePerformance.TotalVoyage.Consumption.Budget)}`
                                    + ` ${t('UNITS.BUDGET')}`
                                    : ' -'
                                }
                            </span>
                        </label>
                    ]
                ]]
            )
        );
        sections.push(
            getPanelRows(
                columnClasses,
                [[
                    <label key="goodWeatherAvgSpeedLabel" className="sten-panel__label">
                        {t('VESSEL_INFO.ENERGY.AVERAGE_VOYAGE_DATA.GOOD_WEATHER_SPEED_TOTAL_VOYAGE')}
                    </label>,
                    <label
                        key="avgSpeedValue"
                        className={`sten-panel__label sten-panel__label--value ${goodWeatherSpeedSeverityClass}`}
                    >
                        {typeof (voyagePerformance.TotalVoyage.GoodWeatherSpeed.Average) === 'number'
                            ? `${formatNumber(voyagePerformance.TotalVoyage.GoodWeatherSpeed.Average)}`
                                + ` ${t('UNITS.SPEED')}`
                            : '-'
                        }
                    </label>,
                    <label key="insSpeedLabel" className="sten-panel__label">
                        {t('VESSEL_INFO.ENERGY.INSTRUCTED')}:
                        <span className="sten-panel__label--value">
                            {typeof (voyagePerformance.TotalVoyage.GoodWeatherSpeed.Instructed) === 'number'
                                ? ` ${formatNumber(voyagePerformance.TotalVoyage.GoodWeatherSpeed.Instructed)}`
                                + ` ${t('UNITS.SPEED')}`
                                : ' -'
                            }
                        </span>
                    </label>
                ]]
            )
        );
        sections.push(
            getPanelRows(
                columnClasses,
                [[
                    <label key="totalConsumptionLabel" className="sten-panel__label">
                        {t('VESSEL_INFO.ENERGY.AVERAGE_VOYAGE_DATA.GOOD_WEATHER_CONSUMPTION_TOTAL_VOYAGE')}
                    </label>,
                    <label
                        key="totalConsumptionValue"
                        className={`sten-panel__label sten-panel__label--value ${goodWeatherConsumptionSeverityClass}`}
                    >
                        {
                            typeof (voyagePerformance.TotalVoyage.GoodWeatherConsumption.Total === 'number')
                            && typeof (voyagePerformance.TotalVoyage.GoodWeatherConsumption.Budget === 'number')
                            && ` ${formatNumber(voyagePerformance.TotalVoyage.GoodWeatherConsumption.Total
                                - voyagePerformance.TotalVoyage.GoodWeatherConsumption.Budget, 1, true)}`
                                + ` ${t('UNITS.CONSUMPTION')}`
                        }
                    </label>,
                    [
                        <label key="totalConsumptionLabel" className="sten-panel__label">
                            {t('VESSEL_INFO.ENERGY.CONSUMPTION')}:
                            <span className="sten-panel__label--value">
                                {typeof (voyagePerformance.TotalVoyage.GoodWeatherConsumption.Total) === 'number'
                                    ? ` ${formatNumber(voyagePerformance.TotalVoyage.GoodWeatherConsumption.Total)}`
                                    + ` ${t('UNITS.CONSUMPTION')}`
                                    : ' -'
                                }
                            </span>
                        </label>,
                        <label key="totalConsumptionBudget" className="sten-panel__label">
                            {t('VESSEL_INFO.ENERGY.BUDGET')}:
                            <span className="sten-panel__label--value">
                                {typeof (voyagePerformance.TotalVoyage.GoodWeatherConsumption.Budget) === 'number'
                                    ? ` ${formatNumber(voyagePerformance.TotalVoyage.GoodWeatherConsumption.Budget)}`
                                    + ` ${t('UNITS.BUDGET')}`
                                    : ' -'
                                }
                            </span>
                        </label>
                    ]
                ]]
            )
        );
        return sections.map((section, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={`sten-content__section${index > 0 ? ' sten-content__separator' : ''}`}>
                {section}
            </div>
        ));
    };

    linkToVoyagePerformancePage = () => this.props.router.getLinkTo({
        pathname: appRoutes.Vessel.VoyagePerformance,
        query: {
            vessel: this.props.router.query.vessel,
            voyage: this.props.selectedVoyage ? this.props.selectedVoyage.VoyageId : this.props.router.query.voyage
        }
    });

    render() {
        const { voyagePerformance } = this.props;
        if (!voyagePerformance || !voyagePerformance.TotalVoyage) {
            return null;
        }
        const header = (
            <React.Fragment>
                <h4 key="title" className="flex-grow text-uppercase">
                    {t('VESSEL_INFO.ENERGY.AVERAGE_VOYAGE_DATA.TITLE')}
                </h4>
                {this.props.vesselBase.VesselTypeId !== 12 && (
                    <div key="button" className="flex-shrink">
                        <Link
                            className="btn-link icon icon-full-screen"
                            to={this.linkToVoyagePerformancePage()}
                        />
                    </div>
                )}
            </React.Fragment>
        );

        return (
            <Accordion header={header}>
                {this.getValues(voyagePerformance)}
            </Accordion>
        );
    }
}

VesselInfoEnergyAverageVoyageData.propTypes = {
    router: TRouter.isRequired,
    selectedVoyage: PropTypes.objectOf(PropTypes.any).isRequired,
    vesselBase: PropTypes.objectOf(PropTypes.any).isRequired,
    voyagePerformance: PropTypes.shape({
        TotalVoyage: PropTypes.shape({
            GoodWeatherConsumption: PropTypes.objectOf(PropTypes.any),
            GoodWeatherSpeed: PropTypes.objectOf(PropTypes.any),
            Consumption: PropTypes.objectOf(PropTypes.any),
            Speed: PropTypes.objectOf(PropTypes.any)
        })
    })
};

VesselInfoEnergyAverageVoyageData.defaultProps = {
    voyagePerformance: null
};

export default withRouter(VesselInfoEnergyAverageVoyageData);
