import { ActionTypes } from './trading-route-tooltip-actions';

const initialState = {
    tradingRoutesPositions: []
};

export default function tradingRouteTooltipReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.TRADING_ROUTE_TOOLTIP_SET_POSITIONS: {
        return {
            ...state,
            tradingRoutesPositions: action.tradingRoutesPositions
        };
    }
    default:
        return state;
    }
}
