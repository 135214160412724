import moment from 'moment';
import getFontSize from 'utils/helpers/cached-font-size';

const chartColors = ['#02a0e7', '#02a0e7', '#0c9c12'];
const colorChartPlotBg = '#122830';
const colorChartBg = '#122830';
const colorSeriesGradient = 'rgba(0, 0, 0, 0.3)';
const colorPredictionArea = 'rgba(12, 79, 104, 0.8)';
const colorPredictionQuartiles = 'rgba(16, 120, 160, 1)';

export const getWorldScaleChartConfig = () => {
    const rem = getFontSize();
    const fsm = rem / 16;

    return {
        colors: chartColors,
        chart: {
            zoomType: 'xz',
            panning: true,
            panKey: 'shift',
            backgroundColor: colorChartBg,
            plotBackgroundColor: colorChartPlotBg
        },
        navigator: {
            enabled: true,
            series: { color: 'rgba(255,255,255, 0.6)' }
        },
        yAxis: {
            minTickInterval: 4
        },
        xAxis: {
            startOnTick: true,
            plotLines: [{
                color: 'rgba(255,255,255,0.4)',
                width: fsm,
                zIndex: 5,
                value: moment().utc().startOf('d').valueOf()
            }]
        },
        plotOptions: {
            series: {
                connectNulls: true
            }
        },
        series: [
            {
                name: 'Prediction Area',
                showInNavigator: true,
                type: 'arearange',
                lineWidth: 0,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, colorPredictionArea],
                        [1, colorPredictionArea]
                    ]
                },
                data: [],
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                enableMouseTracking: false
            },
            {
                name: 'Lower Quartile',
                type: 'line',
                color: colorPredictionQuartiles,
                lineWidth: 0,
                data: [],
                zIndex: 0,
                showInLegend: false,
                states: { hover: { lineWidthPlus: 0 } },
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            {
                name: 'Upper Quartile',
                type: 'line',
                color: colorPredictionQuartiles,
                lineWidth: 0,
                data: [],
                zIndex: 0,
                showInLegend: false,
                states: { hover: { lineWidthPlus: 0 } },
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            {
                name: 'Prediction',
                type: 'line',
                showInNavigator: true,
                lineWidth: 2 * fsm,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, colorSeriesGradient],
                        [1, 'transparent']
                    ]
                },
                data: []
            },
            {
                name: 'Outcome',
                type: 'line',
                showInNavigator: true,
                lineWidth: 2 * fsm,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, colorSeriesGradient],
                        [1, 'transparent']
                    ]
                },
                data: []
            }
        ]
    };
};

export const getDirectionChartConfig = () => {
    const rem = getFontSize();
    const fsm = rem / 16;

    return {
        colors: chartColors,
        chart: {
            zoomType: 'xz',
            panning: true,
            panKey: 'shift',
            backgroundColor: colorChartBg,
            plotBackgroundColor: colorChartPlotBg
        },
        navigator: {
            enabled: true,
            series: { color: 'rgba(255,255,255, 0.6)' }
        },
        yAxis: {
            minTickInterval: 4,
            plotLines: [{
                color: 'rgba(255,255,255,0.4)',
                width: fsm,
                zIndex: 5,
                value: 0
            }]
        },
        xAxis: {
            startOnTick: true,
            plotLines: [{
                color: 'rgba(255,255,255,0.4)',
                width: fsm,
                zIndex: 5,
                value: moment().utc().startOf('d').valueOf()
            }]
        },
        plotOptions: {
            series: {
                connectNulls: true
            }
        },
        series: [
            {
                name: 'Prediction Area',
                showInNavigator: true,
                type: 'arearange',
                lineWidth: 0,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, colorPredictionArea],
                        [1, colorPredictionArea]
                    ]
                },
                data: [],
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                enableMouseTracking: false
            },
            {
                name: 'Lower Quartile',
                type: 'line',
                color: colorPredictionQuartiles,
                lineWidth: 0,
                borderWidth: 0,
                data: [],
                zIndex: 0,
                showInLegend: false,
                states: { hover: { lineWidthPlus: 0 } },
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            {
                name: 'Upper Quartile',
                type: 'line',
                color: colorPredictionQuartiles,
                lineWidth: 0,
                data: [],
                zIndex: 0,
                showInLegend: false,
                states: { hover: { lineWidthPlus: 0 } },
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            {
                name: 'Prediction',
                showInNavigator: true,
                type: 'line',
                lineWidth: 2 * fsm,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, colorSeriesGradient],
                        [1, 'transparent']
                    ]
                },
                data: []
            },
            {
                name: 'Outcome',
                showInNavigator: true,
                type: 'line',
                lineWidth: 2 * fsm,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, colorSeriesGradient],
                        [1, 'transparent']
                    ]
                },
                data: []
            }
        ]
    };
};
