import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
/* Components */
import DateTimePicker from 'components/date-time-picker/date-time-picker';
/* styles */
import './modal.scss';

export class ModalDateTimePicker extends React.PureComponent {
    render() {
        let modalClass = 'sten-modal';
        if (this.props.className) {
            modalClass += ` ${this.props.className}`;
        }
        return this.props.isOpen && (
            <ReactModal
                portalClassName={modalClass}
                bodyOpenClassName="sten-modal--open"
                className={{
                    base: 'sten-modal__content',
                    afterOpen: 'sten-modal__content--open',
                    beforeClose: 'sten-modal-content--closed'
                }}
                overlayClassName={{
                    base: 'sten-modal__overlay flex flex-center',
                    afterOpen: 'sten-modal__overlay--open',
                    beforeClose: 'sten-modal__overlay--closed'
                }}
                closeTimeoutMS={this.props.closeTimeoutMS}
                contentLabel={this.props.contentLabel}
                isOpen={this.props.isOpen}
                onAfterOpen={this.props.onAfterOpen}
                onRequestClose={this.props.onCancel}
            >
                <DateTimePicker
                    utc={this.props.utc}
                    timestamp={this.props.timestamp}
                    onSelect={this.props.onSelect}
                    onCancel={this.props.onCancel}
                    hideTimePicker={this.props.hideTimePicker}
                    viewMode={this.props.viewMode}
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                />
            </ReactModal>
        );
    }
}

ModalDateTimePicker.propTypes = {
    className: PropTypes.string,
    closeTimeoutMS: PropTypes.number,
    contentLabel: PropTypes.string,
    hideTimePicker: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    maxDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onAfterOpen: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    timestamp: PropTypes.number,
    utc: PropTypes.bool,
    viewMode: PropTypes.string
};

ModalDateTimePicker.defaultProps = {
    className: '',
    closeTimeoutMS: undefined,
    contentLabel: '',
    hideTimePicker: false,
    maxDate: null,
    minDate: null,
    onAfterOpen: undefined,
    timestamp: null,
    utc: false,
    viewMode: 'days'
};

export default ModalDateTimePicker;
