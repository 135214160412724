import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* actions */
import {
    getCompanies,
    getReportTypes,
    getVesselFilteringOptions,
    resetAll,
    setMounted,
    toggleSidebar
} from './reports-actions';
/* components */
import AfterInitialMount from 'components/after-initial-mount/after-initial-mount';
import Dashboard from './dashboard/dashboard';
import FleetAverageSpeedReportPreview from './preview/fleet-average-speed-report/fleet-average-speed-report';
import FleetPerformanceReportPreview from './preview/fleet-performance-report/fleet-performance-report';
import MultiVoyageReportPreview from './preview/multi-voyage-report/multi-voyage-report';
import QuarterlyReportPreview from './preview/quarterly-report/quarterly-report';
import FleetListReportPreview from './preview/fleet-list-report/fleet-list-report';
import PerformanceSummaryReportPreview from './preview/performance-summary-report/performance-summary-report';
import WeatherPerformanceReportPreview from './preview/weather-performance-report/weather-performance-report';
import RightSideContainer from './right-side-container/right-side-container';
import Sidebar from './sidebar/sidebar';
import VoyageReportPreview from './preview/voyage-report/voyage-report';
/* styles */
import './reports.scss';
import { TEnergyManagementReportsQueryParams } from './reports-types';

class EnergyManagementReports extends React.PureComponent {
    componentDidMount() {
        this.props.getReportTypes();
        this.props.getCompanies();
        this.props.setMounted(true);
        this.props.getVesselFilteringOptions();
    }

    componentWillUnmount() {
        this.props.resetAll();
        this.props.setMounted(false);
    }

    getReportContent() {
        if (this.props.generatedReportType) {
            switch (this.props.generatedReportType.Id) {
            case 1:
                return <VoyageReportPreview key={this.props.generatedReportsCounter} />;
            case 4:
                return <MultiVoyageReportPreview />;
            case 9:
                return <FleetAverageSpeedReportPreview />;
            case 2:
                return <PerformanceSummaryReportPreview />;
            case 10:
                return <FleetPerformanceReportPreview />;
            case 11:
                return <QuarterlyReportPreview />;
            case 12:
                return <FleetListReportPreview />;
            case 13:
                return <WeatherPerformanceReportPreview />;
            default:
            }
        }
        return (
            <Dashboard queryParams={this.props.queryParams} />
        );
    }

    render() {
        return (
            <div className="sten-energy-management-reports">
                {this.getReportContent()}
                <AfterInitialMount>
                    <RightSideContainer queryParams={this.props.queryParams} />
                    <Sidebar queryParams={this.props.queryParams} />
                </AfterInitialMount>
            </div>
        );
    }
}

EnergyManagementReports.propTypes = {
    generatedReportType: PropTypes.objectOf(PropTypes.any),
    generatedReportsCounter: PropTypes.number.isRequired,
    getCompanies: PropTypes.func.isRequired,
    getReportTypes: PropTypes.func.isRequired,
    getVesselFilteringOptions: PropTypes.func.isRequired,
    queryParams: TEnergyManagementReportsQueryParams.isRequired,
    resetAll: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    setMounted: PropTypes.func.isRequired,
    toggleSidebar: PropTypes.func.isRequired
};

EnergyManagementReports.defaultProps = {
    generatedReportType: null
};

function mapStateToProps(state) {
    return {
        generatedReportType: state.energyManagementReportsReducer.generatedReportType,
        generatedReportsCounter: state.energyManagementReportsVoyageReportReducer.generatedReportsCounter
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCompanies: () => getCompanies(dispatch),
        getReportTypes: () => getReportTypes(dispatch),
        getVesselFilteringOptions: () => getVesselFilteringOptions(dispatch),
        resetAll: () => resetAll(dispatch),
        setMounted: isMounted => setMounted(dispatch, isMounted),
        toggleSidebar: () => toggleSidebar(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EnergyManagementReports));
