import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* Utils */
import { translate } from 'utils/i18n/i18n-model';
import { getPanelRows } from 'utils/helpers/template-helper';
/* Models */
import GsmMapModels from '../../gsm-map/gsm-map-models';
/* Components */
import Accordion from 'components/accordion/accordion';
import Carousel from 'components/carousel/carousel';
import ScrollArea from 'components/scroll-area/scroll-area';
/* Styles */
import './gsm-city-info.scss';
/* Actions */
import { setVisibleAddresses, removeVisibleAddresses } from '../../gsm-actions';
/* Images */
import noImagesPlaceholder from 'assets/images/gsm/carousel_images/carousel_image_placeholder-city.png';

// Consts
const officeInfoRow = [{
    dataKey: 'Email',
    labelTranslateKey: 'GSM.CITY_INFO.EMAIL'
}, {
    dataKey: 'Fax',
    labelTranslateKey: 'GSM.CITY_INFO.FAX'
}, {
    dataKey: 'Telephone',
    labelTranslateKey: 'GSM.CITY_INFO.TELEPHONE'
}, {
    dataKey: 'Telephone2',
    labelTranslateKey: 'GSM.CITY_INFO.TELEPHONE'
}];

export class GsmCityInfo extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            collapsedOffices: {}
        };
    }

    onCloseClick = () => {
        this.props.router.clearQueryParams();
    }

    onOfficeClick = (selectedAddress) => {
        const isAddressPresent = this.props.addresses.find(address =>
            address.Address.Id === selectedAddress.Address.Id);

        if (!isAddressPresent) {
            const updatedAddresses = [selectedAddress];
            this.props.setVisibleAddresses(updatedAddresses);
        }
    };

    getAddressFromAddressLines = addressLines => (
        addressLines.map((addressLine, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span className="gsm-city-info__office-address" key={index}>{addressLine}</span>
        ))
    );

    getCompaniesPanels = (props) => {
        let panels = null;

        if (props.selectedCity) {
            const companies = (props.selectedCity && props.selectedCity.Companies);

            panels = companies.map(company => (
                <Accordion
                    className="sten-panel gsm-city-info__office"
                    key={company.Id}
                    header={<h4 className="text-uppercase">{company.Alias}</h4>}
                    isCollapsed={!this.state.collapsedOffices[company.Id]}
                    onToggleCollapse={this.toggleCollapse.bind(this, company.Id)}
                >
                    {company.Offices && this.getOffices(company)}
                    {company.QRCode && this.getQrCode(company.QRCode)}
                </Accordion>
            ));
        }

        return panels;
    };

    getHeaderTitle = (selectedCity) => {
        if (selectedCity) {
            return selectedCity.CityName;
        }

        return [];
    };

    getOfficeDetails = (office) => {
        if (office.ContactInformations) {
            const columnValues = [];
            const columnClasses = ['col-8', 'col-16'];

            if (office.Address && office.Address.AddressLines && office.Address.AddressLines.length) {
                columnValues.push([
                    <p className="text-secondary" key="label">{translate('GSM.CITY_INFO.ADDRESS')}</p>,
                    <p key="value">{this.getWholeAddress(office.Address.AddressLines, ', ')}</p>
                ]);
            }

            const contactInfo = office.ContactInformations[0];
            if (contactInfo) {
                for (const officeRow of officeInfoRow) {
                    if (contactInfo[officeRow.dataKey] !== null) {
                        columnValues.push([
                            <p className="text-secondary" key="label">{translate(officeRow.labelTranslateKey)}</p>,
                            <p key="value">{contactInfo[officeRow.dataKey]}</p>
                        ]);
                    }
                }
            }

            const detailsRows = getPanelRows(columnClasses, columnValues);
            if (detailsRows && detailsRows.length) {
                return (
                    <div
                        key={office.OfficeId}
                        className="gsm-city-info__office-details"
                        onClick={this.onOfficeClick.bind(this, office)}
                    >
                        {getPanelRows(columnClasses, columnValues)}
                    </div>
                );
            }

            return (
                <div key={office.OfficeId} className="sten-panel__section">
                    <p className="text-secondary">
                        {`${translate('GSM.CITY_INFO.NO_OFFICE_CONTACT_INFO')} `}
                        <span className="text-uppercase text-bold">{office.OfficeName}</span>
                    </p>
                </div>
            );
        }

        return null;
    };


    getOffices = company => company.Offices.map(office => this.getOfficeDetails(office));

    getQrCode(qrCode) {
        const qrStyle = {
            backgroundImage: `url("${encodeURI(qrCode)}")`
        };

        return (
            <div key="qrCode" className="sten-panel__section sten-panel__separator sten-panel__separator--inner">
                <div className="flex-row flex-center">
                    <div className="flex-grow">
                        <p className="text-secondary">{translate('GSM.CITY_INFO.QR_TEXT')}</p>
                    </div>
                    <div className="flex-shrink">
                        <div className="gsm-city-info__qr-img" style={qrStyle} />
                    </div>
                </div>
            </div>
        );
    }

    getWholeAddress = addressArray => (
        addressArray.map((address, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span className="gsm-city-info__office-address" data-separator-string=", " key={index}>{address}</span>
        ))
    );


    setInitialExpandedCompany = (props) => {
        if (props.selectedCity && props.selectedCity.Companies.length) {
            const collapsedOffices = {};
            collapsedOffices[props.selectedCity.Companies[0].Id] = true;

            this.setState({ collapsedOffices });
        }
    };

    componentDidMount() {
        this.setInitialExpandedCompany(this.props);
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedCity !== prevProps.selectedCity) {
            this.setInitialExpandedCompany(this.props);
            this.props.removeVisibleAddresses();
        }
    }

    toggleCollapse = (companyId) => {
        const collapsedOffices = { ...this.state.collapsedOffices };
        if (collapsedOffices[companyId]) {
            delete collapsedOffices[companyId];
        } else {
            collapsedOffices[companyId] = true;
        }
        this.setState({ collapsedOffices });
    };

    render() {
        if (!this.props.selectedCity) {
            return (
                <div className="sten-content">
                    <div className="sten-content__header" />
                    <div className="sten-content__body" />
                </div>
            );
        }

        return (
            <div className="sten-content">
                <div className="sten-content__header flex-row">
                    {this.props.stepBack && (
                        <div className="flex-shrink">
                            <button
                                className="btn-link icon icon-arrow-left"
                                onClick={this.onCloseClick}
                            />
                        </div>
                    )}
                    <h1 className="sten-content__title flex-grow">
                        {this.getHeaderTitle(this.props.selectedCity)}
                    </h1>
                    {!this.props.stepBack && (
                        <div className="flex-shrink">
                            <button
                                className="btn-link icon icon-close"
                                onClick={this.onCloseClick}
                            />
                        </div>
                    )}
                </div>
                <div className="sten-content__body gsm-city-info__body-carousel">
                    <Carousel noImagesPlaceholder={noImagesPlaceholder} images={this.props.carouselImages} />
                </div>
                <ScrollArea className="sten-content__body gsm-city-info__body-content">
                    <div className="sten-content__section-header text-uppercase">
                        <h4>{translate('GSM.CITY_INFO.OFFICES')}</h4>
                    </div>
                    <div className="sten-content__section">
                        {this.getCompaniesPanels(this.props, this.state)}
                    </div>
                </ScrollArea>
            </div>
        );
    }
}

GsmCityInfo.propTypes = {
    addresses: PropTypes.arrayOf(GsmMapModels.Addresses).isRequired,
    carouselImages: PropTypes.arrayOf(PropTypes.string).isRequired,
    onCloseClick: PropTypes.func,
    removeVisibleAddresses: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    selectedCity: GsmMapModels.City,
    setVisibleAddresses: PropTypes.func.isRequired,
    stepBack: PropTypes.bool
};

GsmCityInfo.defaultProps = {
    onCloseClick: undefined,
    selectedCity: null,
    stepBack: false
};

function mapStateToProps(state) {
    return {
        addresses: state.gsmReducer.addresses,
        carouselImages: state.gsmRightSideBarReducer.carouselImages,
        selectedCity: state.gsmReducer.selectedCity
    };
}

function mapDispatchToProps(dispatch) {
    return {
        removeVisibleAddresses: () => removeVisibleAddresses(dispatch),
        setVisibleAddresses: selectedAddresses => setVisibleAddresses(dispatch, selectedAddresses)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GsmCityInfo));
