import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
/* styles */
import './vessel-info-energy-report.scss';

class VesselInfoEnergyReport extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            areDetailsShown: false
        };
    }

    onAcceptConfirm = () => {
        this.props.onReminderConfirm(this.props.report.MissingReportId);
        this.props.onConfirmToggle(null);
    };

    onCloseConfirm = () => {
        this.props.onConfirmToggle(null);
    };

    onReminderClick = () => {
        this.props.onConfirmToggle(this.props.report.MissingReportId);
    };

    toggleDetails = () => {
        this.setState({ areDetailsShown: !this.state.areDetailsShown });
    };

    getPortInfo = () => (
        <p key="port">
            {`${t('VESSEL_INFO.ENERGY.REPORTS.PORT')}: ${this.props.report.PortName || '-'}`}
        </p>
    );

    getContent() {
        let content = [];
        let iconClass = 'icon flex-grow';
        const timestamp = moment(this.props.report.Date).isValid()
            ? `- ${TimeHelper.getFormatted(this.props.report.Date, { utc: true, time: true })}`
            : '';
        switch (this.props.report.ReportType) {
        case 0: {
            iconClass += ' icon-exclamation-circle';
            content = [
                <h4 key="headline">{`${t('VESSEL_INFO.ENERGY.REPORTS.DAILY_REPORT')} ${timestamp}`}</h4>,
                <p key="message1" className="text-danger">{t('VESSEL_INFO.ENERGY.REPORTS.MISSING_REPORT')}</p>
            ];
            if (this.props.report.ReminderDate) {
                const showDetails = this.state.areDetailsShown
                    ? [t('GLOBAL.HIDE_DETAILS'), <span key="icon" className="icon icon-caret-up" />]
                    : [t('GLOBAL.SHOW_DETAILS'), <span key="icon" className="icon icon-caret-down" />];

                content.push(
                    <div key="message2" className="sten-vessel-info-energy-report__detached">
                        <p key="reminder1">
                            {t('VESSEL_INFO.ENERGY.REPORTS.REMINDER_SENT')}
                            <button className="sten-link" onClick={this.toggleDetails}>{showDetails}</button>
                        </p>
                        {this.state.areDetailsShown && [
                            <h6 key="reminder2" className="text-secondary">
                                {t('VESSEL_INFO.ENERGY.REPORTS.LAST_REMINDER_SENT', {
                                    timestamp: TimeHelper.getFormatted(this.props.report.ReminderDate, { time: true })
                                })}
                            </h6>,
                            <h6 key="reminder3" className="text-secondary">
                                {t('VESSEL_INFO.ENERGY.REPORTS.REMINDER_SENT_BY', {
                                    user: this.props.report.UserName
                                })}
                            </h6>,
                            <div key="reminder4" className="flex text-secondary">
                                <h6 className="flex-shrink text-nowrap">
                                    {`${t('VESSEL_INFO.ENERGY.REPORTS.REMINDER_SENT_TO')} `}
                                </h6>
                                <h6 className="flex-grow text-ellipsis">
                                    {this.props.report.EmailTo.split(';').join('\n')}
                                </h6>
                            </div>
                        ]}
                    </div>
                );
            }
            break;
        }
        case 1: {
            iconClass += ' icon-daily-report';
            content = [
                <h4 key="headline">{`${t('VESSEL_INFO.ENERGY.REPORTS.DAILY_REPORT')} ${timestamp}`}</h4>
            ];
            break;
        }
        case 2: {
            iconClass += ' icon-departure-report';
            content = [
                <h4 key="headline">{`${t('VESSEL_INFO.ENERGY.REPORTS.DEPARTURE_REPORT')} ${timestamp}`}</h4>,
                this.getPortInfo()
            ];
            break;
        }
        case 3: {
            iconClass += ' icon-arrival-report';
            content = [
                <h4 key="headline">{`${t('VESSEL_INFO.ENERGY.REPORTS.ARRIVAL_REPORT')} ${timestamp}`}</h4>,
                this.getPortInfo()
            ];
            break;
        }
        case 4: {
            iconClass += ' icon-cargo';
            content = [
                <h4 key="headline">{`${t('VESSEL_INFO.ENERGY.REPORTS.CARGO_REPORT')} ${timestamp}`}</h4>,
                this.getPortInfo()
            ];
            break;
        }
        case 5: {
            iconClass += ' icon-event-report';
            content = [
                <h4 key="headline">{`${t('VESSEL_INFO.ENERGY.REPORTS.EVENT_REPORT')} ${timestamp}`}</h4>,
                <p key="eventType">
                    {`${t('VESSEL_INFO.ENERGY.REPORTS.EVENT_TYPE')}:
                    ${this.props.report.EventType ? this.props.report.EventType.Name : '-'}`}
                </p>
            ];
            break;
        }
        case 6: {
            iconClass += ' icon-next-leg-report';
            content = [
                <h4 key="headline">{`${t('VESSEL_INFO.ENERGY.REPORTS.NEXT_LEG_REPORT')} ${timestamp}`}</h4>
            ];
            break;
        }
        case 7: {
            iconClass += ' icon-sof-report';
            content = [
                <h4 key="headline">{`${t('VESSEL_INFO.ENERGY.REPORTS.SOF_REPORT')} ${timestamp}`}</h4>,
                this.getPortInfo()
            ];
            break;
        }
        default:
            content = [
                <h4 key="headline">{timestamp}</h4>
            ];
        }

        if (this.props.report.Remarks) {
            content.push(<p key="message" className="text-secondary">{this.props.report.Remarks}</p>);
        }

        return {
            iconClass,
            content
        };
    }

    goToVesselReportOverview = () => {
        if (this.props.report.ReportType !== 0) {
            const add = {
                reportId: this.props.report.ReportId.toString(),
                reportType: this.props.report.ReportType.toString()
            };
            if (this.props.router.query.reportId !== add.reportId) {
                this.props.router.updateQueryParams({ add });
            }
        }
    };

    render() {
        if (!this.props.report) {
            return null;
        }
        const report = this.getContent();

        let reportClass = 'sten-vessel-info-energy-report';
        if (this.props.report.ReportType !== 0) {
            reportClass += ' sten-vessel-info-energy-report--clickable';
        }

        let confirmClass = 'sten-vessel-info-energy-report__confirm flex';
        if (this.props.isConfirmActive) {
            confirmClass += ' sten-vessel-info-energy-report__confirm--active';
        }

        let isReminderDisabled = false;
        let reminderBtnTitle = t('VESSEL_INFO.ENERGY.REPORTS.REMINDER_SEND_TITLE');

        if (this.props.report.MissingReportId) {
            if (!this.props.report.IsSendEnabled) {
                isReminderDisabled = true;
                reminderBtnTitle = t('VESSEL_INFO.ENERGY.REPORTS.REMINDER_DISABLED_INVALID_EMAIL');
            } else {
                const reminderDateDifference = this.props.report.ReminderDate
                    ? moment().diff(moment(this.props.report.ReminderDate))
                    : null;
                if (reminderDateDifference && reminderDateDifference < 24 * 60 * 60000) {
                    isReminderDisabled = true;
                    reminderBtnTitle = t('VESSEL_INFO.ENERGY.REPORTS.REMINDER_DISABLED');
                }
            }
        }

        return (
            <div className={reportClass}>
                <div className="flex" onClick={this.goToVesselReportOverview}>
                    <div className="sten-vessel-info-energy-report__icon flex-shrink flex flex-center">
                        <span className={report.iconClass} />
                    </div>
                    <div className="sten-vessel-info-energy-report__content flex-grow flex-row flex-center">
                        <div className="flex-grow">
                            {report.content}
                        </div>
                        {!!this.props.report.MissingReportId
                            && this.props.permissions
                            && this.props.permissions.SendMissingReportReminder && (
                            <div className="flex-shrink">
                                <button
                                    onClick={this.onReminderClick}
                                    className="btn-link icon icon-alert"
                                    disabled={isReminderDisabled}
                                    title={reminderBtnTitle}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {!isReminderDisabled && (
                    <div className={confirmClass}>
                        <div className="sten-vessel-info-energy-report__content flex-grow flex-row flex-center">
                            <div className="flex-grow">
                                <p>{t('VESSEL_INFO.ENERGY.REPORTS.SEND_REMINDER')}</p>
                            </div>
                            <div className="flex-shrink">
                                <button
                                    onClick={this.onAcceptConfirm}
                                    className="btn-link icon icon-check"
                                />
                            </div>
                            <div className="flex-shrink">
                                <button
                                    onClick={this.onCloseConfirm}
                                    className="btn-link icon icon-close"
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

VesselInfoEnergyReport.propTypes = {
    isConfirmActive: PropTypes.bool,
    onConfirmToggle: PropTypes.func,
    onReminderConfirm: PropTypes.func,
    permissions: PropTypes.objectOf(PropTypes.any),
    report: PropTypes.objectOf(PropTypes.any).isRequired,
    router: TRouter.isRequired
};

VesselInfoEnergyReport.defaultProps = {
    isConfirmActive: false,
    onConfirmToggle: undefined,
    onReminderConfirm: undefined,
    permissions: null
};

export default withRouter(VesselInfoEnergyReport);
