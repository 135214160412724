/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
import MockHelper from 'utils/helpers/mock-helper';
import { padNumber } from 'utils/helpers/info-helper';
/* services */
import PortService from 'services/core-api/port-service';

const mockedVessels = [
    {
        VesselName: 'Stena Image',
        Imo: 9667473
    },
    {
        VesselName: 'Sonangol Cabinda',
        Imo: 9575589
    },
    {
        VesselName: 'Stena Suede',
        Imo: 9579042
    },
    {
        VesselName: 'Stena Sunrise',
        Imo: 9592214
    }
];

const tags = [
    {
        Id: 1,
        Name: 'SUBS',
        ShortName: 'SUBS',
        CategoryName: 'Vessel Status',
        CategoryId: 1,
        EntityName: 'Fixture',
        EntityId: 2,
        IsEditable: false
    }, {
        Id: 2,
        Name: 'CoA',
        ShortName: 'CoA',
        CategoryName: 'Fixture Type',
        CategoryId: 2,
        EntityName: 'Fixture',
        EntityId: 2,
        IsEditable: false
    }, {
        Id: 3,
        Name: 'Bunker ordered',
        ShortName: 'B',
        CategoryName: 'Bunker Ordered',
        CategoryId: 3,
        EntityName: 'Leg',
        EntityId: 3,
        IsEditable: false
    }, {
        Id: 4,
        Name: 'Loi',
        ShortName: 'LOI',
        CategoryName: 'Loi',
        CategoryId: 4,
        EntityName: 'Leg',
        EntityId: 3,
        IsEditable: true
    }, {
        Id: 5,
        Name: 'Agent nominated',
        ShortName: 'N',
        CategoryName: 'Agent Nominated',
        CategoryId: 5,
        EntityName: 'Leg',
        EntityId: 3,
        IsEditable: true
    }, {
        Id: 6,
        Name: 'Voyage',
        ShortName: 'V',
        CategoryName: 'Order Sent',
        CategoryId: 6,
        EntityName: 'Leg',
        EntityId: 3,
        IsEditable: true
    }, {
        Id: 7,
        Name: 'Load',
        ShortName: 'L',
        CategoryName: 'Order Sent',
        CategoryId: 6,
        EntityName: 'Leg',
        EntityId: 3,
        IsEditable: true
    }, {
        Id: 8,
        Name: 'Inspection planned in this port',
        ShortName: 'I',
        CategoryName: 'Inspection',
        CategoryId: 7,
        EntityName: 'Leg',
        EntityId: 3,
        IsEditable: true
    }
];

export const legTags = tags.filter(tag => tag.EntityName === 'Leg');
export const fixtureTags = tags.filter(tag => tag.EntityName === 'Fixture' && tag.Id !== 1);
export const voyageTags = tags.filter(tag => tag.EntityName === 'Voyage');

const fixtureSubs = tags[0];

export const etStatuses = {
    ETA: [
        { Id: 0, Name: 'ETA', ShortName: 'ETA' },
        { Id: 1, Name: 'ARR', ShortName: 'ARR' },
        { Id: 2, Name: 'ANCH', ShortName: 'ANCH' }
    ],
    ETB: [
        { Id: 0, Name: 'ETB', ShortName: 'ETB' },
        { Id: 1, Name: 'AF', ShortName: 'AF' }
    ],
    ETD: [
        { Id: 0, Name: 'ESD', ShortName: 'ESD' },
        { Id: 1, Name: 'DEP', ShortName: 'DEP' }
    ]
};

const activities = [
    { Activity: 'Loading', Code: 'L' },
    { Activity: 'Discharging', Code: 'D' },
    { Activity: 'Canal', Code: 'C' },
    { Activity: 'Intermediate', Code: 'I' },
    { Activity: 'Unknown', Code: 'U' }
];

const cargoCommodities = [
    'CSS', 'Isomerate', 'Produced water', 'Caustic Soda', 'Bio Diesel B100', 'Gas Oil', 'Mixed Aromatics', '90-Ron',
    'Vitol', 'Crude Oil', 'Akpo Condensate', 'Wti Crude', 'Fuel Oil'
];

let cargoId = 0;

const generateCargoes = () => MockHelper.getRandomArrayOfItems(0, 2, 0.5, () => ({
    CargoId: cargoId++,
    CargoNumber: 1,
    Commodity: MockHelper.getRandomArrayItem(cargoCommodities),
    Quantity: MockHelper.getRandomNumber(1000, 100000)
}));

export const generateFixtures = (minDate, maxDate, note) => MockHelper.getRandomArrayOfItems(1, 2, 0, () => {
    const LayCanFrom = MockHelper.getRandomDate(minDate, maxDate);
    const futureDate = TimeHelper.getDateFromDiff(null, 10);
    const Tags = MockHelper.getArraySlice(fixtureTags);
    if (minDate > futureDate && Math.random() < 0.3) {
        Tags.push(fixtureSubs);
    }
    return {
        FixtureId: MockHelper.getRandomNumber(100000, 999999),
        FixtureNote: `${note}`,
        LayCanFrom,
        LayCanTo: MockHelper.getRandomDate(LayCanFrom, maxDate),
        BrokerName: MockHelper.getRandomName(),
        CoBrokerName: MockHelper.getRandomName(0.5),
        ChartererName: MockHelper.getRandomName(),
        Cargoes: generateCargoes(),
        Tags
    };
});

const getVoyageNumberFromDate = (date, format = 0, voyageLength) => {
    const year = date.slice(0, 4);
    const yearStart = new Date(year, 0, 0);
    const diff = TimeHelper.getDateDiff(yearStart, date) || 1;
    const voyageIndex = Math.ceil(diff / (voyageLength * 24 * 3600 * 1000));
    if (format === 1) {
        return `${date.slice(0, 4)}${padNumber(voyageIndex, 2)}`;
    }
    return `${date.slice(2, 4)}${padNumber(voyageIndex, 2)}`;
};

const mockVoyages = (length, minVoyageLength = 25, maxVoyageLength = 35, format) => {
    const avgVoyageLength = Math.floor((minVoyageLength + maxVoyageLength) / 2);
    const now = TimeHelper.getUTC();
    let randomDays = MockHelper.getRandomNumber(maxVoyageLength, maxVoyageLength + 10) * 24 * 3600 * 1000;
    let EndDate = TimeHelper.getDateFromDiff(now, randomDays);
    let StartDate;
    return MockHelper.getRandomArrayOfItems(length, length, null, () => {
        if (StartDate) {
            EndDate = StartDate;
        }
        randomDays = MockHelper.getRandomNumber(minVoyageLength, maxVoyageLength) * 24 * 3600 * 1000 * -1;
        StartDate = TimeHelper.getDateFromDiff(EndDate, randomDays);
        return {
            VoyageId: MockHelper.getUniqueId('VoyageId'),
            VoyageNumber: getVoyageNumberFromDate(StartDate, format, avgVoyageLength),
            StartDate,
            EndDate
        };
    });
};

export const mockDataForOperatorsDiary = () => {
    let portTimeFrame;
    let activityTimeFrame;
    let activityStartDate;
    let activityEndDate;
    return PortService.get({ ImportanceLevel: 15 }).then(ports => {
        return mockedVessels.map(vessel => {
            const mockedVoyages = mockVoyages(5, 7, 14, 0).reverse();
            return {
                ...vessel,
                Voyages: mockedVoyages.map(voyage => {
                    const voyageLength = TimeHelper.getDateDiff(voyage.StartDate, voyage.EndDate);
                    const itineraryPorts = MockHelper.getArraySlice(ports, 2, 5);
                    portTimeFrame = Math.floor(voyageLength / itineraryPorts.length);
                    activityStartDate = voyage.StartDate;
                    const Itinerary = [];
                    itineraryPorts.forEach(port => {
                        const portActivities = MockHelper.getArraySlice(activities, 1, 3, true, 0, 0);
                        activityTimeFrame = Math.floor(portTimeFrame / portActivities.length);
                        activityEndDate = TimeHelper.getDateFromDiff(activityStartDate, activityTimeFrame);
                        portActivities.forEach(activity => {
                            const Eta = MockHelper.getRandomDate(activityStartDate, activityEndDate);
                            const Etb = MockHelper.getRandomDate(Eta, activityEndDate, 0.5);
                            const Etd = MockHelper.getRandomDate(Etb || Eta, activityEndDate);
                            activityStartDate = activityEndDate;
                            activityEndDate = TimeHelper.getDateFromDiff(activityEndDate, activityTimeFrame);
                            Itinerary.push({
                                ...activity,
                                Point: {
                                    Name: port.Name,
                                    Code: port.PortCode,
                                    Id: port.Id,
                                    Type: port.Type,
                                    Position: port.Location,
                                    TimeZoneOffset: port.TimeZoneOffset
                                },
                                Steam: MockHelper.getRandomNumber(0, 240, 2),
                                Eta,
                                EtaStatus: MockHelper.getRandomArrayItem(etStatuses.ETA),
                                Etb,
                                EtbStatus: Etb ? MockHelper.getRandomArrayItem(etStatuses.ETB) : null,
                                Etd,
                                EtdStatus: MockHelper.getRandomArrayItem(etStatuses.ETD),
                                AgentName: MockHelper.getRandomName(0.5),
                                Tags: MockHelper.getArraySlice(legTags, 2, 5),
                                BunkerROB: MockHelper.getFuelTypeQuantities(0, 100, 1),
                                BunkerROBsByRangeGroup: {
                                    Liquid: MockHelper.getRandomNumber(0, 100, 1, 0.33),
                                    Fuel: MockHelper.getRandomNumber(0, 100, 1, 0.33),
                                    Gas: MockHelper.getRandomNumber(0, 100, 1, 0.33)
                                }
                            });
                        });
                    });
                    return {
                        ...voyage,
                        Imo: vessel.Imo,
                        VesselName: vessel.VesselName,
                        LatestComment: MockHelper.getRandomComment(0.1),
                        Fixtures: generateFixtures(voyage.StartDate, voyage.EndDate, voyage.VoyageNumber),
                        Itinerary,
                        Tags: MockHelper.getArraySlice(voyageTags)
                    };
                })
            };
        });
    });
};
