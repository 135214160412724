import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
/* utils */
import { getClassName } from 'utils/helpers/info-helper';
/* components */
import EmptyContent from 'components/empty-content/empty-content';
import { createSynchronizedCharts } from 'components/chart/chart';
/*  configs */
// eslint-disable-next-line max-len
import getSpeedAndHeightConfig from './weather-performance-report-chart-configs/weather-performance-report-chart-speed-and-height-config';
// eslint-disable-next-line max-len
import getConsumptionConfig from './weather-performance-report-chart-configs/weather-performance-report-chart-consumption-config';
/* constants */
import {
    chartLabels,
    chartColors,
    getOptions
} from './weather-performance-report-chart-configs/weather-performance-report-chart-constants';
/* styles */
import './weather-performance-report-chart-panel.scss';

const { SharedStateProvider, SynchronizedChart } = createSynchronizedCharts();
const initialState = { min: null, max: null };

class WeatherPerformanceReportChartPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chartsKey: 1,
            chartData: props.chartData
        };
    }

    static getDerivedStateFromProps(props, state) {
        return props.chartData !== state.chartData
            ? {
                chartsKey: state.chartsKey + 1,
                chartData: props.chartData
            }
            : null;
    }

    render() {
        const { startDate, endDate, className } = this.props;
        const { chartData, chartsKey } = this.state;
        let chartMin = endDate && moment.utc(endDate).isBefore(moment.utc())
            ? moment.utc(endDate).subtract(15, 'd').startOf('d')
            : moment.utc().subtract(15, 'd').startOf('d');

        if (chartMin.isBefore(moment.utc(startDate))) {
            chartMin = moment.utc(startDate).startOf('d');
        }
        chartMin = chartMin.valueOf();

        return (
            <div className={getClassName('sten-weather-performance-report-chart-panel sten-panel', className)}>
                {chartData ? (
                    <SharedStateProvider initialState={initialState} key={chartsKey}>
                        {/* vessel speed */}
                        <SynchronizedChart
                            highchartsMore
                            config={getSpeedAndHeightConfig(
                                false,
                                chartLabels.vesselSpeedLabels,
                                chartColors.vesselSpeedColors
                            )}
                            options={getOptions(chartMin)}
                            series={chartData.vesselSpeedChart.series}
                            categories={chartData.vesselSpeedChart.categories}
                            manageResetZoom
                        />
                        <div className="sten-panel__separator" />
                        {/* wind speed */}
                        <SynchronizedChart
                            highchartsMore
                            config={getSpeedAndHeightConfig(
                                true,
                                chartLabels.windSpeedLabels,
                                chartColors.windSpeedColors
                            )}
                            options={getOptions(chartMin)}
                            series={chartData.windSpeedChart.series}
                            categories={chartData.windSpeedChart.categories}
                            manageResetZoom
                        />
                        <div className="sten-panel__separator" />
                        {/* wave height */}
                        <SynchronizedChart
                            highchartsMore
                            config={getSpeedAndHeightConfig(
                                true,
                                chartLabels.waveHeightLabels,
                                chartColors.waveHeightColors
                            )}
                            options={getOptions(chartMin)}
                            series={chartData.waveHeightChart.series}
                            categories={chartData.waveHeightChart.categories}
                            manageResetZoom
                        />
                        {/* {chartData.consumptionLngChart && (
                            <React.Fragment>
                                <div className="sten-panel__separator" />
                                <SynchronizedChart
                                    highchartsMore
                                    config={getConsumptionConfig(
                                        chartLabels.lngLabels,
                                        chartColors.lngColors,
                                        !chartData.consumptionHfoChart && !chartData.consumptionMdoChart
                                    )}
                                    options={getOptions(chartMin)}
                                    series={chartData.consumptionLngChart.series}
                                    categories={chartData.consumptionLngChart.categories}
                                    manageResetZoom
                                />
                            </React.Fragment>
                        )} */}
                        {chartData.consumptionHfoChart && (
                            <React.Fragment>
                                <div className="sten-panel__separator" />
                                {/* hfo */}
                                <SynchronizedChart
                                    highchartsMore
                                    config={getConsumptionConfig(
                                        chartLabels.hfoLabels,
                                        chartColors.hfoColors,
                                        !chartData.consumptionMdoChart
                                    )}
                                    options={getOptions(chartMin)}
                                    series={chartData.consumptionHfoChart.series}
                                    categories={chartData.consumptionHfoChart.categories}
                                    manageResetZoom
                                />
                            </React.Fragment>
                        )}
                        {chartData.consumptionMdoChart && (
                            <React.Fragment>
                                <div className="sten-panel__separator" />
                                {/* mdo */}
                                <SynchronizedChart
                                    config={getConsumptionConfig(
                                        chartLabels.mdoLabels,
                                        chartColors.mdoColors,
                                        true
                                    )}
                                    options={getOptions(chartMin)}
                                    series={chartData.consumptionMdoChart.series}
                                    categories={chartData.consumptionMdoChart.categories}
                                    manageResetZoom
                                />
                            </React.Fragment>
                        )}
                    </SharedStateProvider>
                ) : <EmptyContent className="flex-grow" />}
            </div>
        );
    }
}

WeatherPerformanceReportChartPanel.propTypes = {
    chartData: PropTypes.objectOf(PropTypes.any),
    className: PropTypes.string,
    endDate: PropTypes.string,
    startDate: PropTypes.string
};

WeatherPerformanceReportChartPanel.defaultProps = {
    className: '',
    chartData: null,
    endDate: '',
    startDate: ''
};

export default WeatherPerformanceReportChartPanel;
