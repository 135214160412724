import React from 'react';
/* services */
import AuthService from 'services/auth-service';
/* styles */
import './auth.scss';

export class AuthPostLogout extends React.PureComponent {
    componentDidMount() {
        AuthService.userManager.signoutRedirectCallback()
            .then(response => {
                if (response) {
                    AuthService.login();
                }
            })
            .catch(() => {
                AuthService.login();
            });
    }

    render() {
        return (
            <div className="sten-auth-page" />
        );
    }
}

export default AuthPostLogout;
