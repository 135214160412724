import { takeEvery, all, put, select } from 'redux-saga/effects';
/* router */
import { navigate } from 'app-router';
/* Helpers */
import { wktParse } from 'components/ol/ol-helpers';
/* Services */
import GsmTankerService from 'services/gsm-api/gsm-tanker-service';
import GsmPortService from 'services/gsm-api/gsm-port-service';
import GsmCityService from 'services/gsm-api/gsm-city-service';
import GsmAddressService from 'services/gsm-api/gsm-address-service';
import GsmFerryService from 'services/gsm-api/gsm-ferry-service';
import GsmFerryRouteService from 'services/gsm-api/gsm-ferry-route-service';
import GsmDrillService from 'services/gsm-api/gsm-drill-service';
/* Actions */
import { ActionTypes } from './gsm-actions';
import { ActionTypes as MapLegendActionTypes } from './gsm-map-legend/gsm-map-legend-actions';
import { ActionTypes as MapViewSwitchActionTypes } from './gsm-map-view-switch/gsm-map-view-switch-actions';
import { ActionTypes as RightSideBarActionTypes } from './gsm-right-side-bar/gsm-right-side-bar-actions';
/* Config */
import config from './gsm-map/gsm-map-config';

// Other
function* setCarouselImages(images, currentImages) {
    if (images && images.length) {
        yield put({
            type: RightSideBarActionTypes.GSM_RIGHT_SIDE_BAR_SET_CAROUSEL_IMAGES,
            carouselImages: images
        });
    } else if (currentImages && currentImages.length) {
        yield put({ type: RightSideBarActionTypes.GSM_RIGHT_SIDE_BAR_REMOVE_CAROUSEL_IMAGES });
    }
}

function* setSelectedVessel(vesselList, selectedListVessel) {
    if (vesselList && vesselList.length && selectedListVessel) {
        const selectedVessel = vesselList.find(listVessel => (
            listVessel.IMO === selectedListVessel.IMO
        ));

        if (selectedVessel) {
            selectedVessel.tooltipNameColor = selectedListVessel.tooltipNameColor;
            selectedVessel.CurrentAis.SpeedOverGround = selectedListVessel.CurrentAis.SpeedOverGround;
            yield put({ type: ActionTypes.GSM_SET_SELECTED_VESSEL, vessel: selectedVessel });
        }
    }
}

// Tanker
function* getTankers() {
    const tankersResponse = yield GsmTankerService.getAll();
    let tankers = [];
    const selectedTanker = yield select(state => state.gsmReducer.selectedTanker);

    if (tankersResponse && tankersResponse.Entries) {
        tankers = tankersResponse.Entries.map(vessel => (
            {
                ...vessel,
                IMO: vessel.IMO,
                Title: vessel.VesselName ? vessel.VesselName.trim() : '',
                CurrentAis: {
                    Longitude: vessel.Entries[0].Location.Longitude,
                    Latitude: vessel.Entries[0].Location.Latitude,
                    Heading: vessel.Entries[0].Heading
                }
            }));
    }
    yield put({ type: ActionTypes.GSM_SET_TANKERS, tankers });

    yield setSelectedVessel(tankers, selectedTanker);
}

function* getTanker(action) {
    const tanker = yield GsmTankerService.getById(action.tankerId);
    const carouselImages = yield select(state => state.gsmRightSideBarReducer.carouselImages);
    const tankers = yield select(state => state.gsmReducer.tankers);

    if (tanker) {
        tanker.tooltipNameColor = config.tanker.primaryColor;
        tanker.elementType = 'tanker';

        // Images
        yield setCarouselImages(tanker.Images, carouselImages);
    } else if (carouselImages && carouselImages.length) {
        yield put({ type: RightSideBarActionTypes.GSM_RIGHT_SIDE_BAR_REMOVE_CAROUSEL_IMAGES });
        navigate({ search: '' });
    }

    yield all([
        yield put({ type: ActionTypes.GSM_SET_SELECTED_TANKER, tanker })
    ]);

    yield setSelectedVessel(tankers, tanker);
}

// Port
function* getPorts(action) {
    const ports = yield GsmPortService.get(action.params);
    if (ports) {
        yield put({ type: ActionTypes.GSM_SET_PORTS, ports });
    }
}

// City
function* getCities(action) {
    const cities = yield GsmCityService.get(action.params);
    if (cities) {
        yield put({ type: ActionTypes.GSM_SET_CITIES, cities: cities.Entries });
    }
}

function* getCity(action) {
    const city = yield GsmCityService.getById(action.cityId);
    const carouselImages = yield select(state => state.gsmRightSideBarReducer.carouselImages);

    if (city) {
        // Images
        yield setCarouselImages(city.Images, carouselImages);
    } else if (carouselImages && carouselImages.length) {
        yield put({ type: RightSideBarActionTypes.GSM_RIGHT_SIDE_BAR_REMOVE_CAROUSEL_IMAGES });
        navigate({ search: '' });
    }

    yield put({ type: ActionTypes.GSM_SET_SELECTED_CITY, city });
}

// Addresses
function* getAddresses(action) {
    const addresses = yield GsmAddressService.get(action.params);
    if (addresses) {
        yield put({ type: ActionTypes.GSM_SET_ADDRESSES_COUNT, addressesCount: addresses.TotalEntries });
    }
}

// Ferries
function* getFerries() {
    const ferriesResponse = yield GsmFerryService.getAll();
    let ferries = [];
    const selectedFerry = yield select(state => state.gsmReducer.selectedFerry);

    if (ferriesResponse && ferriesResponse.Entries && ferriesResponse.Entries.length) {
        ferries = ferriesResponse.Entries.map(ferry => (
            {
                ...ferry,
                IMO: ferry.IMO,
                Title: ferry.Name ? ferry.Name.trim() : '',
                CurrentAis: {
                    Longitude: ferry.Location.Longitude,
                    Latitude: ferry.Location.Latitude,
                    Heading: ferry.Heading
                }
            }));
    }
    yield put({ type: ActionTypes.GSM_SET_FERRIES, ferries });

    yield setSelectedVessel(ferries, selectedFerry);
}

function* getFerry(action) {
    const ferry = yield GsmFerryService.getById(action.ferryId);
    yield put({ type: ActionTypes.GSM_SET_SELECTED_FERRY, ferry });
    const carouselImages = yield select(state => state.gsmRightSideBarReducer.carouselImages);
    const ferries = yield select(state => state.gsmReducer.ferries);
    if (ferry) {
        ferry.tooltipNameColor = config.ferry.primaryColor;

        // Images
        yield setCarouselImages(ferry.Images, carouselImages);
    } else if (carouselImages && carouselImages.length) {
        yield put({ type: RightSideBarActionTypes.GSM_RIGHT_SIDE_BAR_REMOVE_CAROUSEL_IMAGES });
        navigate({ search: '' });
    }

    yield put({ type: ActionTypes.GSM_SET_SELECTED_FERRY, ferry });
    yield setSelectedVessel(ferries, ferry);
}

// Ferry routes
function* getFerryRoutes() {
    const ferryResponse = yield GsmFerryRouteService.getAll();
    const ferryRoutes = ferryResponse && ferryResponse.Entries.map(route => (
        {
            ...route,
            RoutePoints: wktParse(route.RoutePoints)
        }
    ));

    if (ferryRoutes && ferryRoutes.length) {
        yield put({ type: ActionTypes.GSM_SET_FERRY_ROUTES, ferryRoutes });
    }
}

// Drills
function* getDrills() {
    const drillsResponse = yield GsmDrillService.getAll();
    let drills = [];
    const selectedDrill = yield select(state => state.gsmReducer.selectedDrill);

    if (drillsResponse && drillsResponse.Entries && drillsResponse.Entries.length) {
        drills = drillsResponse.Entries.map(drill => (
            {
                ...drill,
                IMO: drill.IMO,
                Title: drill.Name ? drill.Name.trim() : '',
                CurrentAis: {
                    Longitude: drill.Location.Longitude,
                    Latitude: drill.Location.Latitude,
                    Heading: drill.Heading
                }
            }));
    }
    yield put({ type: ActionTypes.GSM_SET_DRILLS, drills });

    yield setSelectedVessel(drills, selectedDrill);
}

function* getDrill(action) {
    const drill = yield GsmDrillService.getById(action.drillId);
    const carouselImages = yield select(state => state.gsmRightSideBarReducer.carouselImages);
    const drills = yield select(state => state.gsmReducer.drills);

    if (drill) {
        drill.tooltipNameColor = config.drill.primaryColor;

        // Images
        yield setCarouselImages(drill.Images, carouselImages);
    } else if (carouselImages && carouselImages.length) {
        yield put({ type: RightSideBarActionTypes.GSM_RIGHT_SIDE_BAR_REMOVE_CAROUSEL_IMAGES });
        navigate({ search: '' });
    }
    yield put({ type: ActionTypes.GSM_SET_SELECTED_DRILL, drill });

    yield setSelectedVessel(drills, drill);
}

// Reset app ui

function* resetAppUi() {
    all([
        yield put({ type: MapLegendActionTypes.GSM_MAP_LEGEND_SET_DRILLS_LAYER_VISIBILITY, visibilityState: true }),
        yield put({ type: MapLegendActionTypes.GSM_MAP_LEGEND_SET_FERRIES_LAYER_VISIBILITY, visibilityState: true }),
        yield put({
            type: MapLegendActionTypes.GSM_MAP_LEGEND_SET_FERRY_ROUTES_LAYER_VISIBILITY,
            visibilityState: true
        }),
        yield put({ type: MapLegendActionTypes.GSM_MAP_LEGEND_SET_OFFICES_LAYER_VISIBILITY, visibilityState: true }),
        yield put({ type: MapLegendActionTypes.GSM_MAP_LEGEND_SET_TANKERS_LAYER_VISIBILITY, visibilityState: true }),
        yield put({ type: MapViewSwitchActionTypes.GSM_MAP_VIEW_SWITCH_SET_MAP_TYPE, mapType: 'classic' }),

        yield put({ type: ActionTypes.GSM_SET_RESET_APP_UI_STATE })
    ]);
}

// Saga
export default function* gsmSaga() {
    // Tankers
    yield takeEvery(ActionTypes.GSM_GET_TANKERS, getTankers);
    yield takeEvery(ActionTypes.GSM_GET_SELECTED_TANKER, getTanker);

    // Ports
    yield takeEvery(ActionTypes.GSM_GET_PORTS, getPorts);

    // Cities
    yield takeEvery(ActionTypes.GSM_GET_CITIES, getCities);
    yield takeEvery(ActionTypes.GSM_GET_SELECTED_CITY, getCity);

    // Addresses
    yield takeEvery(ActionTypes.GSM_GET_ADDRESSES, getAddresses);

    // Ferries
    yield takeEvery(ActionTypes.GSM_GET_FERRIES, getFerries);
    yield takeEvery(ActionTypes.GSM_GET_SELECTED_FERRY, getFerry);

    // Ferry routes
    yield takeEvery(ActionTypes.GSM_GET_FERRY_ROUTES, getFerryRoutes);

    // Drills
    yield takeEvery(ActionTypes.GSM_GET_DRILLS, getDrills);
    yield takeEvery(ActionTypes.GSM_GET_SELECTED_DRILL, getDrill);

    // Reset app ui
    yield takeEvery(ActionTypes.GSM_RESET_APP_UI, resetAppUi);
}
