import moment from 'moment';
import { toast } from 'react-toastify';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
import { copyTextToClipboard } from 'utils/helpers/clipboard-helper';

export function formatAndRoundMinutes(dateTime, roundingInterval = 15) {
    const dateTimeMoment = moment.utc(dateTime);
    const rounded = Math.floor(dateTimeMoment.minute() / roundingInterval) * roundingInterval;
    dateTimeMoment.minute(rounded).second(0);
    return TimeHelper.getFormatted(dateTimeMoment, { time: true });
}

export const isActivityCurrent = (activity) => {
    const currentUtcDate = TimeHelper.getUTC();
    return activity.Eta <= currentUtcDate && currentUtcDate <= activity.Etd;
};

export const isActivityHistoric = (activity) => {
    const currentUtcDate = TimeHelper.getUTC();
    return currentUtcDate > activity.Etd;
};

const joinItineraryActivities = (activities) => {
    return activities.reduce((res, activity, index) => {
        if (activity.Activity) {
            return `${res}${index > 0 ? ', ' : ''}${activity.Activity}`;
        }
        return res;
    }, '');
};

const fuelIdsByGroup = { 2: 'FO', 4: 'GO', 7: 'FO', 8: 'GO', 9: 'FO', 10: 'FO' };
const fuelGroups = { LG: 0, FO: 0, GO: 0 };

export const aggregateFuelTypes = (fuelTypeQuantities) => {
    const res = { ...fuelGroups };
    fuelTypeQuantities.forEach(fuelType => {
        if (fuelIdsByGroup[fuelType.Id]) {
            res[fuelIdsByGroup[fuelType.Id]] += fuelType.Quantity;
        }
    });
    return res;
};

export const copyItineraryToClipboard = ({ itinerary, selectedVoyage, vesselName, voyagePortInfo, selectedRange }) => {
    let section;
    const SEPARATOR = '\r\n';
    const sections = [];
    let activities;

    let firstSection = vesselName
        ? `${translate('ITINERARY.VESSEL_NAME')}: ${vesselName.toUpperCase()}`
        : '';
    firstSection += SEPARATOR;

    if (selectedVoyage) {
        firstSection += `${selectedVoyage.VoyageNumber} ${voyagePortInfo}`;
    } else {
        firstSection += `${translate('ITINERARY.RANGE')}: ${selectedRange.DisplayName}`;
    }

    sections.push(firstSection);
    itinerary.forEach((item) => {
        if (!item.Point) {
            return;
        }

        section = item.Point.Name ? item.Point.Name : '-';
        activities = item.Activities;
        const joinedActivities = joinItineraryActivities(activities);
        if (joinedActivities) {
            section += ` [${joinItineraryActivities(activities)}]`;
        }
        const firstActivity = activities[0];
        const lastActivity = activities[activities.length - 1];

        if (firstActivity.Eta) {
            section += `${SEPARATOR}${translate('ITINERARY.ARRIVAL')}: `
                + `${formatAndRoundMinutes(firstActivity.Eta)}`;
        }

        if (lastActivity.Etd) {
            section += `${SEPARATOR}${translate('ITINERARY.DEPARTURE')}: `
                + `${formatAndRoundMinutes(lastActivity.Etd)}`;
        }

        sections.push(section);
    });

    copyTextToClipboard(sections.join('\r\n\r\n'));
    toast(translate('GLOBAL.COPY_TO_CLIPBOARD_SUCCESS'), { type: toast.TYPE.SUCCESS });
};
