export default {
    h1: {
        fontSize: '2.7rem',
        fontWeight: '100',
        fontKerning: 'normal',
        letterSpacing: '-0.03em'
    },
    h2: {
        fontSize: '2.5rem',
        fontWeight: '700',
        fontKerning: 'normal',
        letterSpacing: '-0.03em'
    },
    h3: {
        fontSize: '1.3rem',
        fontKerning: 'normal'
    }
};
