import Excel from 'exceljs';
import moment from 'moment';
import { addWorksheet, triggerXLSXDownload } from 'utils/helpers/xlsx-helper';
/* utils */
import TimeHelper from 'utils/helpers/time-helper';
import {
    columnWidth,
    tableCellStyle,
    alertCellStyle,
    tableHeaderStyle,
    headingTitleStyle
} from 'utils/helpers/xlsx-styles';
import { translate } from 'utils/i18n/i18n-model';
import { formatNumber } from 'utils/helpers/info-helper';
import { getLogoBase64 } from 'utils/base64images';

const stringFormat = { type: 'string' };

const formatTableCell = (value, format = {}, props = null, colSpan = 1, rowSpan = 1) => ({
    value,
    colSpan,
    rowSpan,
    props: { ...tableCellStyle, ...props, numFmt: format.numFmt || null }
});

const t = (key) => translate(`INSPECTIONS.TABLE.${key}`);

const renderHeader = () => {
    return [{
        values: [
            { value: t('VESSEL'), props: tableHeaderStyle },
            { value: t('INSPECTION_DATE'), props: tableHeaderStyle },
            { value: t('TYPE'), props: tableHeaderStyle },
            { value: t('PORT'), props: tableHeaderStyle },
            { value: t('INSPECTED_BY'), props: tableHeaderStyle },
            { value: t('OBS_NO'), props: tableHeaderStyle },
            { value: t('ACTIVITY'), props: tableHeaderStyle },
            { value: t('COMMENT'), props: tableHeaderStyle },
            { value: t('TECHNICAL_MANAGER'), props: tableHeaderStyle },
            { value: t('OWNER'), props: tableHeaderStyle }
        ]
    }];
};

const generateInspectionsRows = (inspections) => {
    const rows = [];
    const formatSettings = { time: true };
    if (inspections && inspections.length > 0) {
        inspections.forEach((inspection) => {
            const technicalManager = inspection.TechnicalManager ? inspection.TechnicalManager.Name : '';
            const owner = inspection.Owner ? inspection.Owner.Name : '';
            rows.push({
                values: [
                    formatTableCell(inspection.Vessel.Title, stringFormat),
                    formatTableCell(
                        `${TimeHelper.getFormatted(inspection.Date, formatSettings)}`
                            + ` (${moment.utc(inspection.Date).fromNow()})`,
                        stringFormat,
                        inspection.IsInAlert ? alertCellStyle : null
                    ),
                    formatTableCell(inspection.Type, stringFormat),
                    formatTableCell(inspection.Port, stringFormat),
                    formatTableCell(inspection.Company, stringFormat),
                    formatTableCell(inspection.Observation),
                    formatTableCell(inspection.Activity, stringFormat),
                    formatTableCell(inspection.Comment, stringFormat),
                    formatTableCell(technicalManager, stringFormat),
                    formatTableCell(owner, stringFormat)
                ]
            });
        });
    }
    return rows;
};

export default function* generateInspectionsXLSX(inspections, averages) {
    const workbook = new Excel.Workbook();
    const obs = averages;
    const sireText = obs.sireInspectionsTotalCount ? translate('INSPECTIONS.OBSERVATION_STATS', {
        avg: typeof obs.sireInspectionsAvg === 'number'
            ? formatNumber(obs.sireInspectionsAvg)
            : translate('GLOBAL.UNKNOWN'),
        count: obs.sireInspectionsTotalCount,
        type: 'SIRE'
    }) : '';
    const cdiText = obs.cdiInspectionsTotalCount ? translate('INSPECTIONS.OBSERVATION_STATS', {
        avg: typeof obs.cdiInspectionsAvg === 'number'
            ? formatNumber(obs.cdiInspectionsAvg)
            : translate('GLOBAL.UNKNOWN'),
        count: obs.cdiInspectionsTotalCount,
        type: 'CDI'
    }) : '';
    const summaryText = `${translate('INSPECTIONS.AVERAGE_OBSERVATION')} ${cdiText} ${sireText}`;
    const sheetConfig = {
        name: translate('INSPECTIONS.TITLE'),
        props: {
            properties: { showGridLines: false },
            views: [{ showGridLines: false }],
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                margins: { left: 0.4, right: 0.4, top: 0.2, bottom: 0.2, header: 0, footer: 0 }
            }
        },
        columnWidth,
        columns: [
            { width: 25 }, // VESSEL
            { width: 30 }, // INSPECTION_DATE
            { width: 8 }, // TYPE
            { width: 25 }, // PORT
            { width: 24 }, // INSPECTED_BY
            { width: 7 }, // OBS_NO
            { width: 11 }, // ACTIVITY
            { width: 24 }, // COMMENT
            { width: 28 }, // TECHNICAL_MANAGER
            { width: 28 } // OWNER
        ],
        rows: [
            null,
            {
                values: [{ value: translate('INSPECTIONS.TITLE'), props: headingTitleStyle }]
            },
            {
                values: [{
                    image: { base64: getLogoBase64().dark, extension: 'png' },
                    imageProps: { tl: { col: 9, row: 1 }, br: { col: 10, row: 3 } }
                }]
            },
            null,
            ...renderHeader(),
            ...generateInspectionsRows(inspections),
            null,
            { values: [formatTableCell(summaryText, stringFormat, null, 10)] }
        ]
    };

    addWorksheet(workbook, sheetConfig);

    const xls64 = yield workbook.xlsx.writeBuffer({ base64: true });
    return triggerXLSXDownload(translate('INSPECTIONS.TITLE'))(xls64);
}
