import { ActionTypes } from './vessel-filter-actions';

const emptyArray = [];

const initialState = {
    privateFleets: emptyArray,
    publicFleets: emptyArray,
    companies: emptyArray,
    segmentsByType: null,
    selectedCompanies: emptyArray,
    initiallyFetchedCompanies: false
};

export default function vesselFilterReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.VESSEL_FILTER_SET_OPTIONS: {
        return {
            ...state,
            privateFleets: action.privateFleets || emptyArray,
            publicFleets: action.publicFleets || emptyArray,
            segmentsByType: action.segmentsByType
        };
    }
    case ActionTypes.VESSEL_FILTER_SET_COMPANIES: {
        const newState = {
            ...state,
            companies: action.companies || emptyArray
        };
        if (!state.initiallyFetchedCompanies) {
            newState.selectedCompanies = action.companies.map(company => company.Id) || emptyArray;
            newState.initiallyFetchedCompanies = true;
        }
        return newState;
    }
    default:
        return state;
    }
}
