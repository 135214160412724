import { ActionTypes } from './charterparty-form-actions';

const emptyArray = [];

const initialState = {
    charterpartyData: emptyArray,
    voyage: null,
    options: {
        fuelTypes: emptyArray,
        currentTypes: emptyArray,
        allowanceTypes: emptyArray
    },
    areOptionsFetched: false
};

export default function charterpartyFormReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.CHARTERPARTY_FORM_SET_OPTIONS: {
        return {
            ...state,
            options: {
                fuelTypes: action.CharterpartyFuelTypes || emptyArray,
                currentTypes: action.CharterpartyCurrentTypes || emptyArray,
                allowanceTypes: action.CharterpartyAllowanceTypes || emptyArray
            },
            areOptionsFetched: true
        };
    }
    case ActionTypes.CHARTERPARTY_FORM_SET_DATA: {
        return {
            ...state,
            charterpartyData: action.charterpartyData || emptyArray,
            voyage: action.voyage || null
        };
    }
    case ActionTypes.CHARTERPARTY_FORM_VOYAGE_UPDATED: {
        return {
            ...state,
            charterpartyData: action.data || emptyArray
        };
    }
    default:
        return state;
    }
}
