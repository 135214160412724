import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { formatNumber } from 'utils/helpers/info-helper';

class TankLayoutCommodities extends React.PureComponent {
    getPreviousCommodities = () => {
        const commodities = [];
        for (let i = 0; i < 5; i++) {
            commodities.push(this.props.commodities[i] || null);
        }
        return commodities;
    };

    render() {
        const { className } = this.props;
        const previousCommodities = this.getPreviousCommodities();
        return (
            <div className={`sten-tank-layout-commodities${className ? ` ${className}` : ''}`}>
                <p className="text-secondary">{translate('TANK_LAYOUT.PREVIOUS_COMMODITIES')}</p>
                {previousCommodities.map((com, index) => (
                    /* eslint-disable-next-line */
                    <div key={index} className="flex">
                        <p className="flex-shrink">{`${index + 1}.`}&nbsp;</p>
                        <p className="flex-grow text-capitalize">
                            {com && com.Commodity ? com.Commodity.toLowerCase() : '-'}
                        </p>
                        {com && com.Commodity && (
                            <p className="flex-shrink">
                                &nbsp;
                                {typeof com.LoadPercentage === 'number'
                                    ? `${formatNumber(com.LoadPercentage, 0)}%`
                                    : ''}
                            </p>
                        )}
                    </div>
                ))}
            </div>
        );
    }
}

TankLayoutCommodities.propTypes = {
    className: PropTypes.string,
    commodities: PropTypes.arrayOf(PropTypes.object)
};

TankLayoutCommodities.defaultProps = {
    className: '',
    commodities: []
};

export default TankLayoutCommodities;
