import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* helpers */
import getFontSize from 'utils/helpers/cached-font-size';
/* components */
import SplitPane from 'react-split-pane';
import Calculator from './calculator/calculator';
import Map from './map/distance-calculator-page-map';
import RightSideBar from './right-side-bar/distance-calculator-page-right-side-bar';
/* actions */
import { resetAll, toggleFullScreen } from './distance-calculator-page-actions';
/* styles */
import './distance-calculator-page.scss';

const contentHeaderHeight = 4.5;
const rightSidebarHeight = 12;
const screenMdMax = 1480;

export class DistanceCalculatorPage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            paneSizes: this.getPaneSizes()
        };
    }

    componentDidMount() {
        window.addEventListener('resize', this.updatePaneAndMapSize);
    }

    componentWillUnmount() {
        this.props.resetAll();
        window.removeEventListener('resize', this.updatePaneAndMapSize);
    }

    saveMapRef = c => {
        this.mapRef = c;
    };

    saveCalculatorRef = c => {
        this.calcRef = c;
    };

    updateMapSize = () => {
        if (this.mapRef && this.mapRef.wrappedInstance && this.mapRef.wrappedInstance.map) {
            window.requestAnimationFrame(this.mapRef.wrappedInstance.map.updateSize);
        }
    };

    getPaneSizes = () => {
        const docHeight = document.body.offsetHeight;
        const headerHeight = getFontSize() * contentHeaderHeight;
        const sidebarHeight = getFontSize() * rightSidebarHeight;
        return {
            maxSize: docHeight - headerHeight,
            size: document.body.offsetWidth < screenMdMax
                ? document.body.offsetHeight - headerHeight
                : (3 * docHeight) / 5,
            minSize: sidebarHeight
        };
    };

    updatePaneAndMapSize = () => {
        this.setState({
            paneSizes: this.getPaneSizes()
        });
        this.updateMapSize();
    };

    refreshMapAndScrollArea = () => {
        this.updateMapSize();
        this.calcRef.wrappedInstance.refreshScrollArea();
    };

    render() {
        const className = `sten-distance-calculator-page${this.props.isFullScreen
            ? ' sten-distance-calculator-page--full-screen' : ''}`;
        return (
            <div className={className}>
                <SplitPane
                    onResizerDoubleClick={this.props.turnOnFullScreen}
                    onChange={this.refreshMapAndScrollArea}
                    allowResize={!this.props.isFullScreen}
                    maxSize={this.state.paneSizes.maxSize}
                    minSize={this.state.paneSizes.minSize}
                    size={this.props.isFullScreen ? 0 : this.state.paneSizes.size}
                    split="horizontal"
                    pane1ClassName="sten-distance-calculator-page__split-top"
                    pane2ClassName="sten-distance-calculator-page__split-bottom"
                    resizerClassName="sten-split-pane__handle sten-split-pane__handle--horizontal"
                >
                    <div className="sten-distance-calculator-page__map">
                        {!this.props.isFullScreen && (
                            <Map ref={this.saveMapRef} />
                        )}
                    </div>
                    <div className="sten-distance-calculator-page__calculator">
                        <Calculator
                            ref={this.saveCalculatorRef}
                            preselectedVessel={this.props.queryParams.vessel}
                            preselectedDeviation={this.props.queryParams.deviation}
                        />
                    </div>
                </SplitPane>
                {!this.props.isFullScreen && <RightSideBar />}
            </div>
        );
    }
}

DistanceCalculatorPage.propTypes = {
    isFullScreen: PropTypes.bool.isRequired,
    resetAll: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    turnOnFullScreen: PropTypes.func.isRequired,
    queryParams: PropTypes.objectOf(PropTypes.any).isRequired
};

function mapStateToProps(state) {
    return {
        isFullScreen: state.distanceCalculatorPageReducer.isFullScreen
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetAll: () => resetAll(dispatch),
        turnOnFullScreen: () => toggleFullScreen(dispatch, true)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DistanceCalculatorPage));
