import CoreApi from './core-api';

class TestService {
    getGeneratedMapData(voyageId) {
        return CoreApi.get(`Performance/GenerateMapData/${voyageId}`)
            .then(response => response.data, () => null);
    }
}

export default new TestService();
