import React from 'react';
import PropTypes from 'prop-types';
/* styles */
import './radio-button.scss';

class RadioButton extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isFocused: false
        };
    }

    onFocus = () => {
        this.setState({ isFocused: true });
    };

    onBlur = () => {
        this.setState({ isFocused: false });
    };

    getRadioLabelClass() {
        let radioClass = 'sten-radio-button';
        if (this.props.isChecked) {
            radioClass += ' sten-radio-button--checked';
        }
        if (this.props.className) {
            radioClass += ` ${this.props.className}`;
        }
        if (this.props.isDisabled) {
            radioClass += ' sten-radio-button--disabled';
        }
        if (!this.props.children) {
            radioClass += ' sten-radio-button--no-label';
        }
        if (this.state.isFocused) {
            radioClass += ' sten-radio-button--focused';
        }
        return radioClass;
    }

    handleClick = (e) => {
        if (!this.props.isDisabled && this.props.onClick) {
            this.props.onClick(e);
        }
    }

    render() {
        return (
            <label className={this.getRadioLabelClass()} htmlFor={this.props.id} onClick={this.handleClick}>
                <input
                    ref={(c) => { this.input = c; }}
                    type="radio"
                    id={this.props.id}
                    name={this.props.name || this.props.id}
                    value={this.props.value}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onChange={this.props.onChange}
                    checked={this.props.isChecked}
                    disabled={this.props.isDisabled}
                />
                {this.props.children}
            </label>
        );
    }
}

RadioButton.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    id: PropTypes.string,
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    stopClickPropagation: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object])
};

RadioButton.defaultProps = {
    children: null,
    className: '',
    id: null,
    isChecked: false,
    isDisabled: false,
    name: '',
    onChange: undefined,
    onClick: undefined,
    stopClickPropagation: false,
    value: ''
};

export default RadioButton;
