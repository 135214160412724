import { ActionTypes } from './port-calls-analytics-actions';
import { sidebars } from './constants';

const emptyArray = [];

const initialState = {
    portCallsAnalytics: emptyArray,
    isPortCallsAnalyticsCalculated: false,
    selectedRange: { from: null, to: null },
    addedPorts: emptyArray,
    addedVessels: emptyArray,
    openSidebar: sidebars.NONE,
    portsCountByCountryId: {},
    selectedPortsByCountryId: {},
    countries: emptyArray,
    ports: emptyArray,
    segments: emptyArray,
    vessels: emptyArray,
    fleets: emptyArray
};

export default function portCallsAnalyticsReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.PORT_CALLS_ANALYTICS_SET_RANGE: {
        return {
            ...state,
            portCallsAnalytics: emptyArray,
            isPortCallsAnalyticsCalculated: false,
            selectedRange: {
                from: action.rangeStart,
                to: action.rangeEnd
            }
        };
    }
    case ActionTypes.PORT_CALLS_ANALYTICS_SET_PORT_CALLS_ANALYTICS: {
        return {
            ...state,
            portCallsAnalytics: action.portCallsAnalytics,
            isPortCallsAnalyticsCalculated: true
        };
    }
    case ActionTypes.PORT_CALLS_ANALYTICS_SET_SIDEBAR: {
        return {
            ...state,
            openSidebar: action.sidebar
        };
    }
    case ActionTypes.PORT_CALLS_ANALYTICS_VESSELS_SIDEBAR_SET_INITIAL_DATA: {
        return {
            ...state,
            vessels: action.vessels,
            segments: action.segments,
            fleets: action.fleets
        };
    }
    case ActionTypes.PORT_CALLS_ANALYTICS_ADD_PORTS: {
        return {
            ...state,
            portCallsAnalytics: emptyArray,
            isPortCallsAnalyticsCalculated: false,
            addedPorts: action.ports
        };
    }
    case ActionTypes.PORT_CALLS_ANALYTICS_ADD_VESSELS: {
        return {
            ...state,
            portCallsAnalytics: emptyArray,
            isPortCallsAnalyticsCalculated: false,
            addedVessels: action.vessels
        };
    }
    case ActionTypes.PORT_CALLS_ANALYTICS_SET_PORTS_AND_COUNTRIES: {
        return {
            ...state,
            countries: action.countries,
            ports: action.ports,
            portsCountByCountryId: action.portsCountByCountryId
        };
    }
    case ActionTypes.PORT_CALLS_ANALYTICS_RESET_ALL:
        return initialState;
    default:
        return state;
    }
}
