import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
import FleetAverageSpeedReportInfoCard from './info-card/fleet-average-speed-report-info-card';
import EmptyContent from 'components/empty-content/empty-content';
import TableHeadCell from 'components/table-head-cell/table-head-cell';
import ContentHeader from '../../shared/content-header';
/* selectors */
import { getVesselSpeedData } from './fleet-average-speed-report-selectors';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import ReportHelper from 'utils/helpers/report-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import { generateXLSX, setSortProp } from './fleet-average-speed-report-actions';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.${key}`);

const timeFormat = { utc: true };

class FleetAverageSpeedReport extends React.PureComponent {
    getSortOrder = (columnName) => {
        if (columnName === this.props.sortProp) {
            return this.props.sortOrder;
        }
        return '';
    };

    renderReportTable = () => {
        return (
            <div className="flex-row">
                <div className="col-24">
                    <div className="sten-panel">
                        <div className="sten-panel__body">
                            <table className="sten-table sten-table--inverted">
                                <thead>
                                    <tr>
                                        <TableHeadCell
                                            sortable
                                            sortOrder={this.getSortOrder('VesselTitle')}
                                            onClick={this.props.setSortProp.bind(this, 'VesselTitle')}
                                        >
                                            {t('VESSEL_NAME')}
                                        </TableHeadCell>
                                        <TableHeadCell
                                            sortable
                                            sortOrder={this.getSortOrder('AvgSpeedBallast')}
                                            onClick={this.props.setSortProp.bind(this, 'AvgSpeedBallast')}
                                        >
                                            {t('AVERAGE_SPEED_BALLAST')}
                                        </TableHeadCell>
                                        <TableHeadCell
                                            sortable
                                            sortOrder={this.getSortOrder('AvgSpeedLaden')}
                                            onClick={this.props.setSortProp.bind(this, 'AvgSpeedLaden')}
                                        >
                                            {t('AVERAGE_SPEED_LADEN')}
                                        </TableHeadCell>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.VesselSpeedData.map(entry => (
                                        <tr key={entry.VesselTitle}>
                                            <th>{entry.VesselTitle}</th>
                                            <td>{ReportHelper.formatCellValue(entry.AvgSpeedBallast)}</td>
                                            <td>{ReportHelper.formatCellValue(entry.AvgSpeedLaden)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { selectedRange, TotalAvgSpeedBallast, TotalAvgSpeedLaden, VesselSpeedData, generateXLSX } = this.props;
        const subtitle = selectedRange
            ? `${TimeHelper.getFormatted(selectedRange.rangeStart, timeFormat)}`
                + ` - ${TimeHelper.getFormatted(selectedRange.rangeEnd, timeFormat)}`
            : null;
        return (
            <div className="sten-content sten-content--has-footer">
                <ContentHeader subtitle={subtitle} />
                {VesselSpeedData.length === 0 ? <EmptyContent>{t('EMPTY_REPORT')}</EmptyContent> : (
                    <React.Fragment>
                        <div className="sten-energy-management-reports-preview sten-content__body">
                            <ScrollArea>
                                <div className="row">
                                    <div className="col-lg-16">
                                        <div className="sten-content__section">
                                            <div className="flex-row">
                                                <div className="col-12">
                                                    <FleetAverageSpeedReportInfoCard
                                                        iconClassName="icon-gauge"
                                                        value={ReportHelper.formatCellValue(
                                                            TotalAvgSpeedBallast, false, 'kn'
                                                        )}
                                                        headline="Total Average Speed Ballast"
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <FleetAverageSpeedReportInfoCard
                                                        iconClassName="icon-gauge text-success"
                                                        value={ReportHelper.formatCellValue(
                                                            TotalAvgSpeedLaden, false, 'kn'
                                                        )}
                                                        headline="Total Average Speed Laden"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="sten-content__section">
                                            {this.renderReportTable()}
                                        </div>
                                    </div>
                                </div>
                            </ScrollArea>
                        </div>
                        <footer className="sten-content__footer flex-row">
                            <p className="flex-grow hide-xs">
                                {`${VesselSpeedData.length} ${t('VESSELS_SELECTED')}`}
                            </p>
                            <div className="flex-shrink">
                                <button
                                    onClick={generateXLSX}
                                    className="btn btn--primary"
                                >
                                    {t('EXPORT_XLSX')}
                                </button>
                            </div>
                        </footer>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

FleetAverageSpeedReport.propTypes = {
    generateXLSX: PropTypes.func.isRequired,
    selectedRange: PropTypes.objectOf(PropTypes.any),
    setSortProp: PropTypes.func.isRequired,
    sortOrder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    sortProp: PropTypes.string.isRequired,
    TotalAvgSpeedBallast: PropTypes.number,
    TotalAvgSpeedLaden: PropTypes.number,
    VesselSpeedData: PropTypes.arrayOf(PropTypes.object).isRequired
};

FleetAverageSpeedReport.defaultProps = {
    selectedRange: null,
    TotalAvgSpeedBallast: null,
    TotalAvgSpeedLaden: null
};

function mapStateToProps(state) {
    return {
        selectedRange: state.energyManagementReportsReducer.selectedRange,
        sortOrder: state.energyManagementReportsFleetAverageSpeedReportReducer.sortOrder,
        sortProp: state.energyManagementReportsFleetAverageSpeedReportReducer.sortProp,
        TotalAvgSpeedBallast: state.energyManagementReportsFleetAverageSpeedReportReducer.TotalAvgSpeedBallast,
        TotalAvgSpeedLaden: state.energyManagementReportsFleetAverageSpeedReportReducer.TotalAvgSpeedLaden,
        VesselSpeedData: getVesselSpeedData(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        generateXLSX: () => generateXLSX(dispatch),
        setSortProp: (sortColumn) => setSortProp(dispatch, sortColumn)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FleetAverageSpeedReport);
