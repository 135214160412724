import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { takeEvery, put, select, delay } from 'redux-saga/effects';
/* helpers */
import { getConvertedObject } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
import { getLogoBase64 } from 'utils/base64images';
import { getLogoForCompany } from '../../reports-helpers';
/* actions */
import { ActionTypes } from './multi-voyage-report-actions';
/* services */
import EnergyManagementService from 'services/core-api/energy-management-service';
import PdfGeneratorService from 'services/core-api/pdf-generator-service';
/* selectors */
import { getVoyageNumbers, getReports } from './multi-voyage-report-selectors';
import { getSelectedPresetCompanyId } from '../../reports-selectors';
/* exports */
import MultiVoyageReportPDF from './export/multi-voyage-report-pdf';
import generateMultiVoyageReportsXLSX from './export/multi-voyage-report-xlsx';
/* prop types */
import inclusionPropTypes from '../../sidebar/inclusions/inclusion-prop-types';

function* prepareReportDataForExport() {
    const { selectedVessel, selectedRange } = yield select(s => s.energyManagementReportsReducer);
    const rangeStart = selectedRange && TimeHelper.getFormatted(selectedRange.rangeStart);
    const rangeEnd = selectedRange && TimeHelper.getFormatted(selectedRange.rangeEnd);
    const fileName = `${selectedVessel.Title}_${rangeStart}_${rangeEnd}`;
    const companyId = yield select(getSelectedPresetCompanyId);
    const companyLogo = yield getLogoForCompany(companyId);
    const state = yield select(s => s);
    return {
        summaryReport: state.energyManagementReportsMultiVoyageReportReducer.summaryReport,
        voyageNumbers: getVoyageNumbers(state),
        voyageReports: getReports(state),
        fileName,
        companyLogo,
        rangeStart,
        rangeEnd,
        orbitLogo: getLogoBase64().dark,
        vesselName: selectedVessel.Title
    };
}

function* generateXLSX() {
    yield put({ type: 'TOGGLE_LOADER', payload: true });
    yield delay(0);
    const reportData = yield prepareReportDataForExport();
    yield generateMultiVoyageReportsXLSX(reportData);
    yield put({ type: 'TOGGLE_LOADER', payload: false });
}

function* generatePDF() {
    const reportData = yield prepareReportDataForExport();

    const htmlString = ReactDOMServer.renderToStaticMarkup(
        <MultiVoyageReportPDF
            summaryReport={reportData.summaryReport}
            voyageNumbers={reportData.voyageNumbers}
            voyageReports={reportData.voyageReports}
            vesselName={reportData.vesselName}
            dateRange={`${reportData.rangeStart} - ${reportData.rangeEnd}`}
            companyLogo={reportData.companyLogo}
            orbitLogo={reportData.orbitLogo}
        />
    );

    PdfGeneratorService.convert({ html: htmlString }, reportData.fileName);
}

function* getMultiVoyageReports() {
    const { selectedVessel, inclusions, selectedRange } = yield select(state => state.energyManagementReportsReducer);
    const convertedInclusions = getConvertedObject(inclusions, inclusionPropTypes);
    const params = {
        ...convertedInclusions,
        FromDate: selectedRange && selectedRange.rangeStart,
        ToDate: selectedRange && selectedRange.rangeEnd,
        VesselImo: selectedVessel.Imo
    };
    const report = yield EnergyManagementService.Reports.MultiVoyageReport.getReport(params);
    yield put({ type: ActionTypes.ENERGY_MANAGEMENT_MULTI_VOYAGE_REPORT_SET, report });
}


export default function* energyManagementReportsPreviewMultiVoyageReportSaga() {
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_MULTI_VOYAGE_REPORT_GENERATE, getMultiVoyageReports);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_MULTI_VOYAGE_REPORT_GENERATE_PDF, generatePDF);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_MULTI_VOYAGE_REPORT_GENERATE_XLSX, generateXLSX);
}
