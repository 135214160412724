import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import Validation from 'components/validation/validation';
import Input from 'components/input/input';
import Modal from 'components/modal/modal';

const t = (key, params) => translate(`VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.MODAL.${key}`, params);

const paramProps = ['windForceMax', 'hoursSteamedMin', 'avgSpeedMin', 'instructedSpeedMin'];
const initialParams = {
    windForceMax: '',
    hoursSteamedMin: '',
    avgSpeedMin: '',
    instructedSpeedMin: ''
};

class PerformancePanel extends React.PureComponent {
    state = {
        params: initialParams,
        prevParams: null
    };

    static getDerivedStateFromProps(props, state) {
        if (props.params !== state.prevParams) {
            return {
                prevParams: props.params,
                params: { ...initialParams, ...props.params }
            };
        }
        return null;
    }

    handleSubmit = () => {
        const params = {};
        let changed = false;
        let value;
        paramProps.forEach(param => {
            value = this.state.params[param].trim();
            if (value) {
                params[param] = value;
                changed = true;
            }
        });
        if (changed) {
            this.props.onSubmit(params);
        } else {
            this.props.onSubmit(null);
        }
    };

    handleReset = () => this.props.onSubmit(null);

    handleChange = (field) => (value) => this.setState({
        params: { ...this.state.params, [field]: value }
    });

    handleWindForceMaxChange = this.handleChange('windForceMax');

    handleHoursSteamedMinChange = this.handleChange('hoursSteamedMin');

    handleAvgSpeedMinChange = this.handleChange('avgSpeedMin');

    handleInstructedSpeedMinChange = this.handleChange('instructedSpeedMin');

    render() {
        const params = this.state.params;
        return (
            <Modal.Default modalSize="xs" isOpen={this.props.isModalShown} onRequestClose={this.props.onModalClose}>
                <Validation.Form onSubmit={this.handleSubmit} stopPropagation>
                    <div className="sten-modal__header flex flex-center text-uppercase">
                        <div className="sten-modal__title flex-grow">{t('PARAMETERS')}</div>
                        <div className="flex-shrink">
                            <button
                                type="button"
                                className="btn-link icon icon-close"
                                onClick={this.props.onModalClose}
                            />
                        </div>
                    </div>
                    <div className="sten-modal__body">
                        <div className="sten-content__section">
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">{t('WIND_FORCE')}</label>
                                </div>
                                <div className="col-14">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{ numeric: { min: 0, max: 12 } }}
                                    >
                                        <Input
                                            prefix="max"
                                            suffix={translate('UNITS.WIND_FORCE')}
                                            name="windForceMax"
                                            value={params.windForceMax}
                                            onChange={this.handleWindForceMaxChange}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">{t('HOURS_STEAMED')}</label>
                                </div>
                                <div className="col-14">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            numeric: { min: 0, max: 25, decimals: 0 }
                                        }}
                                    >
                                        <Input
                                            prefix="min"
                                            suffix={translate('UNITS.HOUR')}
                                            name="hoursSteamedMin"
                                            value={params.hoursSteamedMin}
                                            onChange={this.handleHoursSteamedMinChange}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">{t('AVERAGE_SPEED')}</label>
                                </div>
                                <div className="col-14">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{ numeric: { min: 0, max: 30 } }}
                                    >
                                        <Input
                                            prefix="min"
                                            suffix={translate('UNITS.SPEED')}
                                            name="avgSpeedMin"
                                            value={params.avgSpeedMin}
                                            onChange={this.handleAvgSpeedMinChange}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">{t('INSTRUCTED_SPEED')}</label>
                                </div>
                                <div className="col-14">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{ numeric: { min: 0, max: 30 } }}
                                    >
                                        <Input
                                            prefix="min"
                                            suffix={translate('UNITS.SPEED')}
                                            name="instructedSpeedMin"
                                            value={params.instructedSpeedMin}
                                            onChange={this.handleInstructedSpeedMinChange}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footer className="sten-modal__footer">
                        <div className="flex-grow flex-row">
                            <div className="flex-grow text-right">
                                <button type="button" onClick={this.handleReset} className="btn btn--secondary">
                                    {translate('GLOBAL.RESET')}
                                </button>
                            </div>
                            <div className="flex-shrink">
                                <Validation.Button className="btn btn--primary">
                                    {translate('GLOBAL.APPLY')}
                                </Validation.Button>
                            </div>
                        </div>
                    </footer>
                </Validation.Form>
            </Modal.Default>
        );
    }
}

PerformancePanel.propTypes = {
    isModalShown: PropTypes.bool.isRequired,
    onModalClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    params: PropTypes.shape({
        windForceMax: PropTypes.string,
        hoursSteamedMin: PropTypes.string,
        avgSpeedMin: PropTypes.string,
        instructedSpeedMin: PropTypes.string
    })
};

PerformancePanel.defaultProps = {
    params: null
};

export default PerformancePanel;
