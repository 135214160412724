/* utils */
import { t } from 'utils/i18n/i18n-model';
import TimeHelper from 'utils/helpers/time-helper';
/* services */
import ConfigService from 'services/config-api/config-service';
/* icons */
// import windSpeed00 from 'assets/images/weather/wind-speed/ic_wind_0.svg';
import windSpeed01 from 'assets/images/weather/wind-speed/ic_wind_1.svg';
import windSpeed01s from 'assets/images/weather/wind-speed/ic_wind_1_s.svg';
import windSpeed02 from 'assets/images/weather/wind-speed/ic_wind_2.svg';
import windSpeed02s from 'assets/images/weather/wind-speed/ic_wind_2_s.svg';
import windSpeed03 from 'assets/images/weather/wind-speed/ic_wind_3.svg';
import windSpeed03s from 'assets/images/weather/wind-speed/ic_wind_3_s.svg';
import windSpeed04 from 'assets/images/weather/wind-speed/ic_wind_4.svg';
import windSpeed04s from 'assets/images/weather/wind-speed/ic_wind_4_s.svg';
import windSpeed05 from 'assets/images/weather/wind-speed/ic_wind_5.svg';
import windSpeed05s from 'assets/images/weather/wind-speed/ic_wind_5_s.svg';
import windSpeed06 from 'assets/images/weather/wind-speed/ic_wind_6.svg';
import windSpeed06s from 'assets/images/weather/wind-speed/ic_wind_6_s.svg';
import windSpeed07 from 'assets/images/weather/wind-speed/ic_wind_7.svg';
import windSpeed07s from 'assets/images/weather/wind-speed/ic_wind_7_s.svg';
import windSpeed08 from 'assets/images/weather/wind-speed/ic_wind_8.svg';
import windSpeed08s from 'assets/images/weather/wind-speed/ic_wind_8_s.svg';
import windSpeed09 from 'assets/images/weather/wind-speed/ic_wind_9.svg';
import windSpeed09s from 'assets/images/weather/wind-speed/ic_wind_9_s.svg';
import windSpeed10 from 'assets/images/weather/wind-speed/ic_wind_10.svg';
import windSpeed10s from 'assets/images/weather/wind-speed/ic_wind_10_s.svg';
import windSpeed11 from 'assets/images/weather/wind-speed/ic_wind_11.svg';
import windSpeed11s from 'assets/images/weather/wind-speed/ic_wind_11_s.svg';
import windSpeed12 from 'assets/images/weather/wind-speed/ic_wind_12.svg';
import windSpeed12s from 'assets/images/weather/wind-speed/ic_wind_12_s.svg';
import windSpeed13 from 'assets/images/weather/wind-speed/ic_wind_13.svg';
import windSpeed13s from 'assets/images/weather/wind-speed/ic_wind_13_s.svg';
import windSpeed14 from 'assets/images/weather/wind-speed/ic_wind_14.svg';
import windSpeed14s from 'assets/images/weather/wind-speed/ic_wind_14_s.svg';
import windSpeed15 from 'assets/images/weather/wind-speed/ic_wind_15.svg';
import windSpeed15s from 'assets/images/weather/wind-speed/ic_wind_15_s.svg';
import currentDirection1 from 'assets/images/weather/current/ic_currents_sprite_1_new.svg';
import currentDirection2 from 'assets/images/weather/current/ic_currents_sprite_2_new.svg';
import currentDirection3 from 'assets/images/weather/current/ic_currents_sprite_3_new.svg';
import waveDirection from 'assets/images/weather/wave/ic_wave_arrow.svg';
import stormTracksL from 'assets/images/weather/storm-tracks/low-pressure.svg';
import stormTracksT from 'assets/images/weather/storm-tracks/tropical-storm.svg';
import stormTracksTS from 'assets/images/weather/storm-tracks/tropical-storm-south.svg';
import stormTracksH from 'assets/images/weather/storm-tracks/hurricane.svg';
import stormTracksHS from 'assets/images/weather/storm-tracks/hurricane-south.svg';
/* styles */
import {
    getAreaStyle,
    getGeoJsonStyle,
    getIconStyle,
    getLineStyle,
    getTextStyle
} from 'components/ol/ol-map-styles';

export const DTN_LAYER_IDS_MAP = {
    SEA_LEVEL_PRESSURE_4_hPA: 'fcst-manta-mean-sea-level-pressure-4mb-isolines',
    VISIBILITY: 'fcst-manta-visibility-contours',
    VISIBILITY_VALUES: 'fcst-manta-visibility-grid',
    CURRENT_SPEED: 'fcst-manta-current-speed-contours',
    CURRENT_SPEED_VALUES: 'fcst-manta-current-speed-grid',
    CURRENT_DIRECTION: 'fcst-manta-current-direction-grid',
    MEAN_WAVE_DIRECTION: 'fcst-manta-mean-wave-direction-grid',
    PEAK_WAVE_PERIOD: 'fcst-manta-peak-wave-period-contours',
    WAVE_HEIGHT: 'fcst-manta-significant-wave-height-contours',
    WAVE_HEIGHT_VALUES: 'fcst-manta-significant-wave-height-grid',
    WIND_SPEED: 'fcst-manta-wind-speed-contours',
    WIND_SPEED_VALUES: 'fcst-manta-wind-speed-grid',
    WIND_SPEED_DIRECTION: 'fcst-manta-wind-symbol-grid',
    ICE_PACK: 'fcst-ecmwf-sea-ice-fraction-contours',
    STORM_TRACKS: 'sevwx-dtn-tropical-cyclones-plot'
};

export const TIMESTAMP_INTERVAL = 3 * TimeHelper.units.hour;
export const RUNTIME_INTERVAL = 6 * TimeHelper.units.hour;

export const LEGEND_TYPES = {
    ICON: 1,
    COLOR: 2
};

export const LAYER_VALUE_TYPES = {
    VECTOR: 1,
    OTHER: 2
};

export const LAYER_TYPES = {
    ISO_LINE: 1,
    VECTOR: 2,
    VALUE: 3,
    ICON: 4
};

export const LAYER_DATA_TYPES = {
    FORECAST: 1,
    OBSERVATION: 2
};

const currentIcons = {
    '0 - 0.5': currentDirection1,
    '0.5 - 1': currentDirection2,
    '1 +': currentDirection3
};

const windSpeedIcons = {
    north: {
        '0 - 2.5': null,
        '2.5 - 7.5': windSpeed01,
        '7.5 - 12.5': windSpeed02,
        '12.5 - 17.5': windSpeed03,
        '17.5 - 22.5': windSpeed04,
        '22.5 - 27.5': windSpeed05,
        '27.5 - 32.5': windSpeed06,
        '32.5 - 37.5': windSpeed07,
        '37.5 - 42.5': windSpeed08,
        '42.5 - 47.5': windSpeed09,
        '47.5 - 52.5': windSpeed10,
        '52.5 - 57.5': windSpeed11,
        '57.5 - 62.5': windSpeed12,
        '62.5 - 67.5': windSpeed13,
        '67.5 - 72.5': windSpeed14,
        '72.5 +': windSpeed15
    },
    south: {
        '0 - 2.5': null,
        '2.5 - 7.5': windSpeed01s,
        '7.5 - 12.5': windSpeed02s,
        '12.5 - 17.5': windSpeed03s,
        '17.5 - 22.5': windSpeed04s,
        '22.5 - 27.5': windSpeed05s,
        '27.5 - 32.5': windSpeed06s,
        '32.5 - 37.5': windSpeed07s,
        '37.5 - 42.5': windSpeed08s,
        '42.5 - 47.5': windSpeed09s,
        '47.5 - 52.5': windSpeed10s,
        '52.5 - 57.5': windSpeed11s,
        '57.5 - 62.5': windSpeed12s,
        '62.5 - 67.5': windSpeed13s,
        '67.5 - 72.5': windSpeed14s,
        '72.5 +': windSpeed15s
    }
};

export const weatherLayerColorMap = {
    [DTN_LAYER_IDS_MAP.VISIBILITY]: {
        '0 - 0.25': '#FFFFFF',
        '0.25 - 1.0': '#dfe4e6',
        '1.0 - 5.0': '#afbcc1',
        '5.0 - 10.0': '#8fa1a9',
        '10.0 +': 'transparent'
    },
    [DTN_LAYER_IDS_MAP.CURRENT_SPEED]: {
        '0 - 0.25': 'transparent',
        '0.25 - 0.75': '#275C9A',
        '0.75 - 1.25': '#2B75A1',
        '1.25 - 1.75': '#229599',
        '1.75 - 2.25': '#A1A55A',
        '2.25 - 2.75': '#9A7E61',
        '2.75 - 3.25': '#A0675A',
        '3.25 - 3.75': '#914950',
        '3.75 +': '#71353C'
    },
    [DTN_LAYER_IDS_MAP.PEAK_WAVE_PERIOD]: {
        '0 - 5': '#275C9A',
        '5 - 10': '#308C74',
        '10 - 15': '#A1A55A',
        '15 - 20': '#A0675A',
        '20 +': '#71353C'
    },
    [DTN_LAYER_IDS_MAP.WAVE_HEIGHT]: {
        '0 - 0.10': '#2C83AC',
        '0.10 - 0.50': '#229599',
        '0.50 - 1.25': '#308C74',
        '1.25 - 2.00': '#75A860',
        '2.00 - 4.00': '#A1A55A',
        '4.00 - 6.00': '#9A7E61',
        '6.00 - 9.00': '#A0675A',
        '9.00 - 14.00': '#914950',
        '14 +': '#71353C'
    },
    [DTN_LAYER_IDS_MAP.WIND_SPEED]: {
        '0 - 10': 'transparent',
        '11 - 16': '#2C83AC',
        '17 - 21': '#229599',
        '22 - 27': '#308C74',
        '28 - 33': '#75A860',
        '34 - 40': '#A1A55A',
        '41 - 47': '#9A7E61',
        '48 - 55': '#A0675A',
        '56 - 63': '#914950',
        '64 +': '#8D3C43'
    },
    [DTN_LAYER_IDS_MAP.ICE_PACK]: {
        '0 - 5': 'transparent',
        '5 - 15': '#1F517D',
        '15 - 25': '#215C89',
        '25 - 35': '#27679A',
        '35 - 45': '#2D76AA',
        '45 - 55': '#2C83AC',
        '55 - 65': '#3392A9',
        '65 - 75': '#419EB3',
        '75 - 85': '#4EA7BC',
        '85 - 95': '#5EB3C7',
        '95 +': '#7EB8C6'
    }
};

export const legendValuesMap = {
    [DTN_LAYER_IDS_MAP.VISIBILITY]: {
        LegendType: LEGEND_TYPES.COLOR,
        LegendValues: weatherLayerColorMap[DTN_LAYER_IDS_MAP.VISIBILITY],
        Unit: t('UNITS.VISIBILITY')
    },
    [DTN_LAYER_IDS_MAP.CURRENT_SPEED]: {
        LegendType: LEGEND_TYPES.COLOR,
        LegendValues: weatherLayerColorMap[DTN_LAYER_IDS_MAP.CURRENT_SPEED],
        Unit: t('UNITS.CURRENT_SPEED')
    },
    [DTN_LAYER_IDS_MAP.CURRENT_DIRECTION]: {
        LegendType: LEGEND_TYPES.ICON,
        LegendValues: currentIcons
    },
    [DTN_LAYER_IDS_MAP.PEAK_WAVE_PERIOD]: {
        LegendType: LEGEND_TYPES.COLOR,
        LegendValues: weatherLayerColorMap[DTN_LAYER_IDS_MAP.PEAK_WAVE_PERIOD],
        Unit: t('UNITS.PEAK_WAVE_PERIOD')
    },
    [DTN_LAYER_IDS_MAP.WAVE_HEIGHT]: {
        LegendType: LEGEND_TYPES.COLOR,
        LegendValues: weatherLayerColorMap[DTN_LAYER_IDS_MAP.WAVE_HEIGHT],
        Unit: t('UNITS.WAVE_HEIGHT')
    },
    [DTN_LAYER_IDS_MAP.WIND_SPEED]: {
        LegendType: LEGEND_TYPES.COLOR,
        LegendValues: weatherLayerColorMap[DTN_LAYER_IDS_MAP.WIND_SPEED],
        Unit: t('UNITS.WIND_SPEED')
    },
    [DTN_LAYER_IDS_MAP.WIND_SPEED_DIRECTION]: {
        LegendType: LEGEND_TYPES.ICON,
        LegendValues: windSpeedIcons.north
    },
    [DTN_LAYER_IDS_MAP.ICE_PACK]: {
        LegendType: LEGEND_TYPES.COLOR,
        LegendValues: weatherLayerColorMap[DTN_LAYER_IDS_MAP.ICE_PACK],
        Unit: '%'
    }
};

const getRangeValueConverter = (ranges, rangeLength) => {
    if (rangeLength) {
        const maxRange = Object.keys(ranges).find(key => key.indexOf(' +') > -1);
        const maxRangeStart = Number(maxRange.replace(' +', ''));
        return (value) => {
            if (value >= maxRangeStart) {
                return ranges[`${maxRangeStart} +`];
            }
            if (value < rangeLength / 2) {
                return ranges[`0 - ${rangeLength / 2}`];
            }
            const roundedByRange = Math.round(value / rangeLength) * rangeLength;
            return ranges[`${roundedByRange - (rangeLength / 2)} - ${roundedByRange + (rangeLength / 2)}`];
        };
    }
    const rangesMinMax = Object.keys(ranges).map(range => {
        if (range.indexOf(' +') > -1) {
            return { min: Number(range.replace(' +', '')), max: Infinity, range };
        }
        const [min, max] = range.split(' - ').map(v => Number(v));
        return { min, max, range };
    });
    return (value) => {
        const foundRange = rangesMinMax.find(({ min, max }) => value >= min && value < max);
        return foundRange ? ranges[foundRange.range] : rangesMinMax[0].range;
    };
};

const rangeValueConverterByLayerId = {
    [DTN_LAYER_IDS_MAP.VISIBILITY]: getRangeValueConverter(weatherLayerColorMap[DTN_LAYER_IDS_MAP.VISIBILITY]),
    [DTN_LAYER_IDS_MAP.CURRENT_SPEED]:
        getRangeValueConverter(weatherLayerColorMap[DTN_LAYER_IDS_MAP.CURRENT_SPEED], 0.5),
    [DTN_LAYER_IDS_MAP.PEAK_WAVE_PERIOD]:
        getRangeValueConverter(weatherLayerColorMap[DTN_LAYER_IDS_MAP.PEAK_WAVE_PERIOD]),
    [DTN_LAYER_IDS_MAP.WAVE_HEIGHT]: getRangeValueConverter(weatherLayerColorMap[DTN_LAYER_IDS_MAP.WAVE_HEIGHT]),
    [DTN_LAYER_IDS_MAP.WIND_SPEED]: getRangeValueConverter(weatherLayerColorMap[DTN_LAYER_IDS_MAP.WIND_SPEED]),
    [DTN_LAYER_IDS_MAP.ICE_PACK]: getRangeValueConverter(weatherLayerColorMap[DTN_LAYER_IDS_MAP.ICE_PACK], 10)
};

const iconElementProps = {
    [DTN_LAYER_IDS_MAP.CURRENT_DIRECTION]: {
        // current icon
        color: '#fff',
        opacity: 1,
        size: [24, 24],
        hideLabel: true,
        useSizeMultiplier: false,
        rotation: properties => parseInt(properties.value1, 10) + 90,
        src: ({ value }) => {
            if (value >= 1) {
                return currentIcons['1 +'];
            }
            if (value >= 0.5) {
                return currentIcons['0.5 - 1'];
            }
            return currentIcons['0 - 0.5'];
        }
    },
    [DTN_LAYER_IDS_MAP.MEAN_WAVE_DIRECTION]: {
        // Wave icon
        color: '#fff',
        opacity: 1,
        size: [24, 24],
        hideLabel: true,
        useSizeMultiplier: false,
        rotation: properties => Number(properties.value) + 90,
        src: waveDirection
    },
    [DTN_LAYER_IDS_MAP.WIND_SPEED_DIRECTION]: {
        // Wind icon
        color: '#fff',
        opacity: 1,
        size: [24, 24],
        hideLabel: true,
        useSizeMultiplier: false,
        rotation: ({ value1 }) => Number(value1) + 90,
        src: props => {
            const { value, isNorth } = props;
            const icons = windSpeedIcons[isNorth ? 'north' : 'south'];
            const maxRange = Object.keys(icons).find(key => key.indexOf(' +') > -1);
            const maxRangeStart = Number(maxRange.replace(' +', ''));
            if (value < 2.5) {
                return icons['0 - 2.5'];
            }
            if (value >= maxRangeStart) {
                return icons[`${maxRangeStart} +`];
            }
            const midRange = Math.ceil((value - 2.49999) / 5) * 5;
            return icons[`${midRange - 2.5} - ${midRange + 2.5}`];
        }
    }
};

const setStyleFunction = (styleFunction) => (feature, props, name, ...args) => {
    const data = feature.getProperties();
    return styleFunction(
        Object.assign(feature, {
            data,
            properties: Object.assign(data, { props, layerName: name }),
            props,
            isHovered: feature.isHovered || false
        }),
        ...args
    );
};

const vectorStylesMap = {};

const layerProps = {
    icon: {
        style: setStyleFunction(getIconStyle),
        props: {
            declutter: true,
            renderBuffer: 24
        }
    },
    vector: {
        style: (feature, props, name) => {
            if (!vectorStylesMap[name]) {
                vectorStylesMap[name] = {};
            }
            const properties = feature.getProperties();

            if (!properties.value) {
                return getGeoJsonStyle(Object.assign(
                    feature,
                    { properties: Object.assign(properties, { props, layerName: name }) }
                ));
            }
            if (!vectorStylesMap[name][properties.value]) {
                const featureWithProps = Object.assign(
                    feature,
                    { properties: Object.assign(properties, { props, layerName: name }) }
                );
                vectorStylesMap[name][properties.value] = getGeoJsonStyle(featureWithProps);
            }
            return vectorStylesMap[name][properties.value];
        },
        props: {
            preload: 10,
            declutter: true,
            renderBuffer: 24
        }
    },
    value: {
        style: setStyleFunction(getTextStyle),
        props: {
            declutter: true,
            renderBuffer: 24
        }
    },
    isoline: {
        style: setStyleFunction(getLineStyle),
        props: {
            preload: 10
        }
    },
    stormTrack: {
        style: (feature, ...args) => {
            if (feature.get('layer') === 'tropical_cyclone_consensus_history_track'
                || feature.get('positionType') === 0) {
                return null;
            }
            if (feature.getType() === 'Point') {
                return setStyleFunction(getIconStyle)(feature, ...args);
            }
            return setStyleFunction(getAreaStyle)(feature, ...args);
        },
        props: {
            preload: 10,
            renderBuffer: 1000
        }
    }
};

let stormRotation = 0;
let stormRotationS = 0;
const stormRotationDelta = 5;

const elementProps = {
    value: () => ({
        text: properties =>
            `${Number.parseFloat(properties.value).toFixed(1)}`,
        color: '#fff',
        stroke: true,
        strokeProps: {
            color: '#1d5a72',
            width: 2
        }
    }),
    isoline: () => ({
        color: properties => properties['line-color'] || '#fff'
    }),
    stormTracks: () => ({
        color: props => {
            if (props.layer === 'tropical_cyclone_consensus_points') {
                return '#bd2626';
            }
            return '#fff';
        },
        lineColor: '#fff',
        opacity: 1,
        size: [100, 100],
        label: (props, isHovered, zoom) => {
            if (props.layer === 'tropical_cyclone_consensus_points'
                && (props.positionType === 1 || zoom >= 4)) {
                return `${props.name}\n${TimeHelper.getWeatherFormatted(props.validTime)}`;
            }
            return null;
        },
        rotation: (props, isHovered, zoom, feature) => {
            if (props.windSpeedKnots > 33 && zoom >= 4) {
                const isSouth = feature.getFlatCoordinates
                    ? feature.getFlatCoordinates()[1] < 0
                    : feature.getGeometry().flatCoordinates[1] < 0;
                return isSouth ? stormRotationS : stormRotation;
            }
            return 0;
        },
        textBold: true,
        textStroke: 2,
        textOffset: 30,
        // zIndex: props => (props.positionType === 1 ? 1 : 0),
        src: (props, isHovered, zoom, feature) => {
            const isSouth = feature.getFlatCoordinates
                ? feature.getFlatCoordinates()[1] < 0
                : feature.getGeometry().flatCoordinates[1] < 0;
            if (props.windSpeedKnots <= 33) {
                return stormTracksL;
            }
            if (props.windSpeedKnots < 64) {
                return isSouth ? stormTracksTS : stormTracksT;
            }
            return isSouth ? stormTracksHS : stormTracksH;
        }
    }),
    vector: (layerId, key = 'value') => {
        const stylingFunction = properties => ({
            color: rangeValueConverterByLayerId[layerId]
                ? rangeValueConverterByLayerId[layerId](properties[key]) || 'transparent'
                : properties['fill-color']
        });
        return {
            fill: stylingFunction,
            // There is a bug for 1080 resolution screens, where if we don't pass stroke
            // app get stuck drawing tile
            stroke: stylingFunction
        };
    },
    icon: layerId => iconElementProps[layerId]
};

const handleStormTracksPostRender = (e) => {
    if (ConfigService.featureToggles.showDTNWeatherStormIconRotation) {
        stormRotation = stormRotation - stormRotationDelta === 0 ? 360 : stormRotation - stormRotationDelta;
        stormRotationS = stormRotationS + stormRotationDelta === 360 ? 0 : stormRotationS + stormRotationDelta;
        e.target.changed();
    }
};

export const getWeatherLayerConfigs = () => {
    return {
        [DTN_LAYER_IDS_MAP.SEA_LEVEL_PRESSURE_4_hPA]: {
            Label: 'Mean Sea Level Pressure',
            LayerType: LAYER_TYPES.ISO_LINE,
            LayerValueType: LAYER_VALUE_TYPES.OTHER,
            LayerDataType: LAYER_DATA_TYPES.FORECAST,
            elementProps: elementProps.isoline(),
            layerProps: layerProps.isoline,
            order: 2
        },
        [DTN_LAYER_IDS_MAP.VISIBILITY]: {
            LayerType: LAYER_TYPES.VECTOR,
            LayerValueType: LAYER_VALUE_TYPES.VECTOR,
            LayerDataType: LAYER_DATA_TYPES.FORECAST,
            elementProps: elementProps.vector(DTN_LAYER_IDS_MAP.VISIBILITY),
            layerProps: layerProps.vector,
            opacity: 0.8,
            order: 0
        },
        [DTN_LAYER_IDS_MAP.VISIBILITY_VALUES]: {
            LayerType: LAYER_TYPES.VALUE,
            LayerValueType: LAYER_VALUE_TYPES.OTHER,
            LayerDataType: LAYER_DATA_TYPES.FORECAST,
            elementProps: elementProps.value(),
            layerProps: layerProps.value,
            order: 2
        },
        [DTN_LAYER_IDS_MAP.CURRENT_SPEED]: {
            Label: 'Ocean Current Speed',
            LayerType: LAYER_TYPES.VECTOR,
            LayerValueType: LAYER_VALUE_TYPES.VECTOR,
            LayerDataType: LAYER_DATA_TYPES.FORECAST,
            elementProps: elementProps.vector(DTN_LAYER_IDS_MAP.CURRENT_SPEED),
            layerProps: layerProps.vector,
            order: 0
        },
        [DTN_LAYER_IDS_MAP.CURRENT_SPEED_VALUES]: {
            Label: 'Ocean Current Speed',
            LayerType: LAYER_TYPES.VALUE,
            LayerValueType: LAYER_VALUE_TYPES.OTHER,
            LayerDataType: LAYER_DATA_TYPES.FORECAST,
            elementProps: elementProps.value(),
            layerProps: layerProps.value,
            order: 2
        },
        [DTN_LAYER_IDS_MAP.CURRENT_DIRECTION]: {
            Label: 'Ocean Current Direction',
            LayerType: LAYER_TYPES.ICON,
            LayerValueType: LAYER_VALUE_TYPES.OTHER,
            LayerDataType: LAYER_DATA_TYPES.FORECAST,
            elementProps: elementProps.icon(DTN_LAYER_IDS_MAP.CURRENT_DIRECTION),
            layerProps: layerProps.icon,
            order: 2
        },
        [DTN_LAYER_IDS_MAP.MEAN_WAVE_DIRECTION]: {
            LayerType: LAYER_TYPES.ICON,
            LayerValueType: LAYER_VALUE_TYPES.OTHER,
            LayerDataType: LAYER_DATA_TYPES.FORECAST,
            elementProps: elementProps.icon(DTN_LAYER_IDS_MAP.MEAN_WAVE_DIRECTION),
            layerProps: layerProps.icon,
            order: 2
        },
        [DTN_LAYER_IDS_MAP.PEAK_WAVE_PERIOD]: {
            LayerType: LAYER_TYPES.VECTOR,
            LayerValueType: LAYER_VALUE_TYPES.VECTOR,
            LayerDataType: LAYER_DATA_TYPES.FORECAST,
            elementProps: elementProps.vector(DTN_LAYER_IDS_MAP.PEAK_WAVE_PERIOD),
            layerProps: layerProps.vector,
            order: 0
        },
        [DTN_LAYER_IDS_MAP.WAVE_HEIGHT]: {
            LayerType: LAYER_TYPES.VECTOR,
            LayerValueType: LAYER_VALUE_TYPES.VECTOR,
            LayerDataType: LAYER_DATA_TYPES.FORECAST,
            elementProps: elementProps.vector(DTN_LAYER_IDS_MAP.WAVE_HEIGHT),
            layerProps: layerProps.vector,
            order: 0
        },
        [DTN_LAYER_IDS_MAP.WAVE_HEIGHT_VALUES]: {
            LayerType: LAYER_TYPES.VALUE,
            LayerValueType: LAYER_VALUE_TYPES.OTHER,
            LayerDataType: LAYER_DATA_TYPES.FORECAST,
            elementProps: elementProps.value(),
            layerProps: layerProps.value,
            order: 2
        },
        [DTN_LAYER_IDS_MAP.WIND_SPEED]: {
            LayerType: LAYER_TYPES.VECTOR,
            LayerValueType: LAYER_VALUE_TYPES.VECTOR,
            LayerDataType: LAYER_DATA_TYPES.FORECAST,
            elementProps: elementProps.vector(DTN_LAYER_IDS_MAP.WIND_SPEED),
            layerProps: layerProps.vector,
            opacity: 0.8,
            order: 0
        },
        [DTN_LAYER_IDS_MAP.WIND_SPEED_VALUES]: {
            LayerType: LAYER_TYPES.VALUE,
            LayerValueType: LAYER_VALUE_TYPES.OTHER,
            LayerDataType: LAYER_DATA_TYPES.FORECAST,
            elementProps: elementProps.value(),
            layerProps: layerProps.value,
            order: 2
        },
        [DTN_LAYER_IDS_MAP.WIND_SPEED_DIRECTION]: {
            Label: 'Wind Direction',
            LayerType: LAYER_TYPES.ICON,
            LayerValueType: LAYER_VALUE_TYPES.OTHER,
            LayerDataType: LAYER_DATA_TYPES.FORECAST,
            elementProps: elementProps.icon(DTN_LAYER_IDS_MAP.WIND_SPEED_DIRECTION),
            layerProps: layerProps.icon,
            order: 2
        },
        [DTN_LAYER_IDS_MAP.ICE_PACK]: {
            LayerType: LAYER_TYPES.VECTOR,
            LayerValueType: LAYER_VALUE_TYPES.VECTOR,
            LayerDataType: LAYER_DATA_TYPES.FORECAST,
            elementProps: elementProps.vector(DTN_LAYER_IDS_MAP.ICE_PACK),
            layerProps: layerProps.vector,
            order: 0
        },
        [DTN_LAYER_IDS_MAP.STORM_TRACKS]: ConfigService.featureToggles.showDTNWeatherStormTracks ? {
            Label: 'Tropical Storms',
            LayerType: LAYER_TYPES.VECTOR,
            LayerValueType: LAYER_VALUE_TYPES.VECTOR,
            LayerDataType: LAYER_DATA_TYPES.OBSERVATION,
            elementProps: elementProps.stormTracks(),
            layerProps: layerProps.stormTrack,
            order: 4,
            onPostRender: handleStormTracksPostRender
        } : null
    };
};
