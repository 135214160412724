import React from 'react';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
/* components */
import TextCrud from 'components/text-crud/text-crud';
import ShowMoreButton from 'components/show-more-button/show-more-button';
/* styles */
import './position-list-note.scss';

export class PositionListNote extends React.PureComponent {
    state = {
        isExpanded: false,
        isClamped: false
    };

    componentDidMount() {
        this.checkIfClamped();
    }

    componentDidUpdate() {
        this.checkIfClamped();
    }

    toggleExpand = () => this.setState({ isExpanded: !this.state.isExpanded });

    checkIfClamped = () => {
        if (this.linesElipsisRef) {
            const isClamped = this.linesElipsisRef.isClamped();
            if (this.state.isClamped !== isClamped) {
                this.setState({ isClamped });
            }
        }
    };

    handleUpdate = (text) => this.props.onUpdate(text, this.props.note ? this.props.note.Id : null);

    handleRemove = () => this.props.onRemove(this.props.note ? this.props.note.Id : null);

    saveRef = (r) => {
        this.linesElipsisRef = r;
    };

    render() {
        let noteInfoRow;
        if (!this.props.hideAuthor && this.props.note && this.props.note.Author) {
            const noteModifiedDate = TimeHelper.getFormatted(this.props.note.DateModified, { time: true });
            noteInfoRow = (
                <h6 className="text-secondary flex-row">
                    <div className="flex-grow">{this.props.note.Author}</div>
                    <div className="flex-shrink text-nowrap">{noteModifiedDate}</div>
                </h6>
            );
        }

        const note = this.props.note
            ? this.props.note.Text
            : null;

        return (
            <TextCrud
                isReadOnly={this.props.isReadOnly}
                addButtonIconClass="icon-message-add"
                className="sten-position-list-note"
                isActive={this.props.isActive}
                onActivate={this.props.onActivate}
                onDeactivate={this.props.onDeactivate}
                onUpdate={this.handleUpdate}
                onRemove={this.handleRemove}
                type="textarea"
                value={note}
                validations={{ required: true, maxLength: 1024 }}
                removeText={translate('POSITION_LIST.NOTES.REMOVE_NOTE')}
                rows={2}
            >
                {noteInfoRow}
                {this.state.isExpanded ? (
                    <p className="text-left text-ellipsis">{note}</p>
                ) : (
                    <LinesEllipsis
                        ref={this.saveRef}
                        text={note}
                        maxLine={1}
                        className="text-left text-ellipsis"
                    />
                )}
                {this.state.isClamped && (
                    <ShowMoreButton
                        className="sten-position-list-note__show-more-link"
                        isMoreShown={this.state.isExpanded}
                        onClick={this.toggleExpand}
                    />
                )}
            </TextCrud>
        );
    }
}

PositionListNote.propTypes = {
    hideAuthor: PropTypes.bool,
    isActive: PropTypes.bool.isRequired,
    isReadOnly: PropTypes.bool,
    note: PropTypes.objectOf(PropTypes.any),
    onActivate: PropTypes.func.isRequired,
    onDeactivate: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired
};

PositionListNote.defaultProps = {
    hideAuthor: false,
    isReadOnly: false,
    note: null
};

export default PositionListNote;
