export default {
    SegmentList: [
        {
            Imo: 3000001,
            Title: null,
            TotalChange: 44.570599507304138,
            BallastKPIRatio: -5.7801620909167895,
            LadenKPIRatio: 6.9281711390604812,
            WeightedCargoRatio: 5.2501142599165291,
            EnergyEfficiencyRatio: -22.339884981610709,
            AERRatio: null
        },
        {
            Imo: 4000008,
            Title: null,
            TotalChange: -64.8147187301388,
            BallastKPIRatio: -2.2710258352429094,
            LadenKPIRatio: 1.1387922112790818,
            WeightedCargoRatio: 8.419868433273408,
            EnergyEfficiencyRatio: -33.248594941398127,
            AERRatio: null
        },
        {
            Imo: 5000005,
            Title: null,
            TotalChange: -27.54130664948795,
            BallastKPIRatio: -10.072306239814992,
            LadenKPIRatio: -5.4228346615183654,
            WeightedCargoRatio: 5.8701504805630975,
            EnergyEfficiencyRatio: -12.45373905554149,
            AERRatio: null
        },
        {
            Imo: 9203772,
            Title: 'Sonangol Kizomba DEMO',
            TotalChange: -37.480732191729757,
            BallastKPIRatio: -2.395362756296791,
            LadenKPIRatio: 0.87814647801856438,
            WeightedCargoRatio: -6.9208950168793848,
            EnergyEfficiencyRatio: -2.3619322546855557,
            AERRatio: null
        },
        {
            Imo: 9533995,
            Title: 'Stena Surprise DEMO',
            TotalChange: -25.461294844148028,
            BallastKPIRatio: 1.3351990767455106,
            LadenKPIRatio: 1.5522149066577811,
            WeightedCargoRatio: -9.4529558853885618,
            EnergyEfficiencyRatio: -12.7075649122271,
            AERRatio: null
        },
        {
            Imo: 9575541,
            Title: 'Sonangol Rangel DEMO',
            TotalChange: 15.135097526914862,
            BallastKPIRatio: -1.4545701759882803,
            LadenKPIRatio: -0.51315471557839487,
            WeightedCargoRatio: -2.1372290074083651,
            EnergyEfficiencyRatio: 32.107431369448392,
            AERRatio: null
        },
        {
            Imo: 9575565,
            Title: 'Sonangol Huila DEMO',
            TotalChange: -30.596531628294088,
            BallastKPIRatio: 37.89554537733261,
            LadenKPIRatio: -16.630923948574903,
            WeightedCargoRatio: -1.9703634401252543,
            EnergyEfficiencyRatio: -20.283339692877604,
            AERRatio: null
        },
        {
            Imo: 9575577,
            Title: 'Sonangol Porto Amboim DEMO',
            TotalChange: -3.4269865281877827,
            BallastKPIRatio: 20.464303060928628,
            LadenKPIRatio: 7.6772108253833551,
            WeightedCargoRatio: -10.457190592780293,
            EnergyEfficiencyRatio: -77.9641538801504,
            AERRatio: null
        },
        {
            Imo: 9579030,
            Title: 'Stena Superior DEMO',
            TotalChange: -66.724970728338462,
            BallastKPIRatio: -12.577412230863672,
            LadenKPIRatio: -2.0197830115288156,
            WeightedCargoRatio: -11.541432360084229,
            EnergyEfficiencyRatio: -7.3654190412735261,
            AERRatio: null
        },
        {
            Imo: 9579042,
            Title: 'Stena Suede DEMO',
            TotalChange: -32.31234595674073,
            BallastKPIRatio: null,
            LadenKPIRatio: 17.855520699476671,
            WeightedCargoRatio: 1.5586635158195747,
            EnergyEfficiencyRatio: -8.83814008781944,
            AERRatio: null
        },
        {
            Imo: 9585895,
            Title: 'Stena Supreme DEMO',
            TotalChange: 97.190866450445924,
            BallastKPIRatio: -1.4764553312448925,
            LadenKPIRatio: -1.7527784442886229,
            WeightedCargoRatio: 1.5607758933359008,
            EnergyEfficiencyRatio: -20.940755143518544,
            AERRatio: null
        },
        {
            Imo: 9585900,
            Title: 'AST Sunshine DEMO',
            TotalChange: -25.761601164064402,
            BallastKPIRatio: 6.4224486946568078,
            LadenKPIRatio: -12.368209348686804,
            WeightedCargoRatio: 7.720273939722702,
            EnergyEfficiencyRatio: -10.144109563800214,
            AERRatio: null
        },
        {
            Imo: 9592214,
            Title: 'Stena Sunrise DEMO',
            TotalChange: -23.910218368756347,
            BallastKPIRatio: 12.096811059045876,
            LadenKPIRatio: 9.7354963841598448,
            WeightedCargoRatio: -3.1399121971021628,
            EnergyEfficiencyRatio: -16.41714037613329,
            AERRatio: null
        },
        {
            Imo: 9766310,
            Title: 'Sonangol Cazenga DEMO',
            TotalChange: 11.81655025844265,
            BallastKPIRatio: 11.88314987922962,
            LadenKPIRatio: 31.679641338974136,
            WeightedCargoRatio: -18.396181397238223,
            EnergyEfficiencyRatio: -45.535387730094833,
            AERRatio: null
        }
    ],
    TotalData: {
        BallastKPI: 121.13738639716577,
        PreviousBallastKPI: 117.92168947367445,
        LadenKPI: 153.35239290999107,
        PreviousLadenKPI: 145.74300334226604,
        WeightedCargoLaden: 132049.17734983648,
        PreviousWeightedCargoLaden: 130282.49332557332,
        EnergyEfficiency: 21.942961507263497,
        PreviousEnergyEfficiency: 24.41626315516341,
        PreviousAER: 4.555650226197704,
        AER: 3.5650975898005442,
        BallastKPIRatio: 2.7269766383471108,
        LadenKPIRatio: 5.221101111697962,
        WeightedCargoRatio: 1.3560409991910944,
        EnergyEfficiencyRatio: -11.196335264615129,
        AERRatio: -27.5171574209478962
    },
    MonthlyDataList: [
        {
            Date: '2019-10-01T00:00:00Z',
            Cargo: 1726.159,
            BallastKPI: 112.42241330220381,
            LadenKPI: 144.37058463592373
        },
        {
            Date: '2019-11-01T00:00:00Z',
            Cargo: 1863.5215,
            BallastKPI: 112.6323144104803,
            LadenKPI: 154.99702518675224
        },
        {
            Date: '2019-12-01T00:00:00Z',
            Cargo: 1996.6223333333335,
            BallastKPI: 116.34853305054787,
            LadenKPI: 145.00449309696924
        },
        {
            Date: '2020-01-01T00:00:00Z',
            Cargo: 1945.404,
            BallastKPI: 115.28140750420013,
            LadenKPI: 149.44105691056907
        },
        {
            Date: '2020-02-01T00:00:00Z',
            Cargo: 1867.8502,
            BallastKPI: 111.94366016427105,
            LadenKPI: 139.944560586414
        },
        {
            Date: '2020-03-01T00:00:00Z',
            Cargo: 1955.6018333333332,
            BallastKPI: 105.06438396254018,
            LadenKPI: 135.87171731060081
        },
        {
            Date: '2020-04-01T00:00:00Z',
            Cargo: 1970.7649999999999,
            BallastKPI: 125.40158203072279,
            LadenKPI: 143.55000824904511
        },
        {
            Date: '2020-05-01T00:00:00Z',
            Cargo: 2049.1447500000004,
            BallastKPI: 131.37303595051881,
            LadenKPI: 146.49411258805821
        },
        {
            Date: '2020-06-01T00:00:00Z',
            Cargo: 2047.7052222222223,
            BallastKPI: 132.90970336327553,
            LadenKPI: 145.01301453481668
        },
        {
            Date: '2020-07-01T00:00:00Z',
            Cargo: 2002.7157,
            BallastKPI: 116.60220994475138,
            LadenKPI: 141.92067024088647
        },
        {
            Date: '2020-08-01T00:00:00Z',
            Cargo: 1955.3428181818183,
            BallastKPI: 114.09274344219421,
            LadenKPI: 151.94162074862257
        },
        {
            Date: '2020-09-01T00:00:00Z',
            Cargo: 1887.0701666666671,
            BallastKPI: 122.44136163574568,
            LadenKPI: 148.86078002108164
        },
        {
            Date: '2020-10-01T00:00:00Z',
            Cargo: 1884.3433846153846,
            BallastKPI: 114.47563084745059,
            LadenKPI: 156.98368152998648
        },
        {
            Date: '2020-11-01T00:00:00Z',
            Cargo: 1833.7716923076923,
            BallastKPI: 109.7280514444199,
            LadenKPI: 148.5406452311943
        },
        {
            Date: '2020-12-01T00:00:00Z',
            Cargo: 1787.7616153846152,
            BallastKPI: 113.78340907340369,
            LadenKPI: 146.04904963790892
        },
        {
            Date: '2021-01-01T00:00:00Z',
            Cargo: 1734.8856153846152,
            BallastKPI: 112.68747916898126,
            LadenKPI: 139.83216106082816
        },
        {
            Date: '2021-02-01T00:00:00Z',
            Cargo: 1709.2754615384615,
            BallastKPI: 117.95730628611867,
            LadenKPI: 155.21932686034472
        },
        {
            Date: '2021-03-01T00:00:00Z',
            Cargo: 1695.471,
            BallastKPI: 118.61185915349823,
            LadenKPI: 156.80218050026747
        },
        {
            Date: '2021-04-01T00:00:00Z',
            Cargo: 1598.2436923076921,
            BallastKPI: 130.42369663104213,
            LadenKPI: 151.42682146448493
        },
        {
            Date: '2021-05-01T00:00:00Z',
            Cargo: 1549.4567692307692,
            BallastKPI: 130.79903766313959,
            LadenKPI: 158.50819369664035
        },
        {
            Date: '2021-06-01T00:00:00Z',
            Cargo: 1463.5255384615384,
            BallastKPI: 127.34003782811274,
            LadenKPI: 155.79220779220768
        },
        {
            Date: '2021-07-01T00:00:00Z',
            Cargo: 1388.4316153846155,
            BallastKPI: 117.53587858294347,
            LadenKPI: 159.25372334008082
        },
        {
            Date: '2021-08-01T00:00:00Z',
            Cargo: 1380.8973076923078,
            BallastKPI: 127.57521225544484,
            LadenKPI: 155.39605435006757
        },
        {
            Date: '2021-09-01T00:00:00Z',
            Cargo: 1455.804846153846,
            BallastKPI: 128.45264792133867,
            LadenKPI: 155.70414886684523
        }
    ],
    EnergyEfficiencyDataList: [
        {
            Date: '2012-01-01T00:00:00Z',
            Efficiency: 40.79122434987232029721798,
            AER: 6.3955941596219697336348280527,
            Target: 119.79122434987232029721798,
            Segments: [

            ]
        },
        {
            Date: '2013-01-01T00:00:00Z',
            Efficiency: 37.070464707906613086362221,
            AER: 6.18592311677188907,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2014-01-01T00:00:00Z',
            Efficiency: 35.050948323430724630321853104,
            AER: 3.5819541371318500429035537117,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2015-01-01T00:00:00Z',
            Efficiency: 36.590364213131936328835431092,
            AER: 3.8937402175768125236537859196,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2016-01-01T00:00:00Z',
            Efficiency: 34.567868833042623559481301353,
            AER: 5.184645999808395057411634933,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2017-01-01T00:00:00Z',
            Efficiency: 30.2265660990680842846559642292,
            AER: 7.938578064574465009412968043,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2018-01-01T00:00:00Z',
            Efficiency: 28.4678833455086457284277348322,
            AER: 5.670884365913740755192432705,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2019-01-01T00:00:00Z',
            Efficiency: 27.618465609520897608022942124,
            AER: 6.5419802952344635088731592007,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2020-01-01T00:00:00Z',
            Efficiency: 24.415305406266237492308306066,
            AER: 4.5545204011109940497409895527,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2021-01-01T00:00:00Z',
            Efficiency: 21.301609365948180485239062427,
            AER: 3.5573939426141202745485141914,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2022-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2023-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2024-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2025-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2026-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2027-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2028-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2029-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2030-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: 71779.674734609923392178330788,
            Segments: [

            ]
        },
        {
            Date: '2031-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2032-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2033-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2034-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2035-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2036-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2037-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2038-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2039-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2040-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2041-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2042-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2043-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2044-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2045-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2046-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2047-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2048-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2049-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [

            ]
        },
        {
            Date: '2050-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: 0.0,
            Segments: [

            ]
        }
    ]
};
