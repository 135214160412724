import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import ScrollArea from 'components/scroll-area/scroll-area';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { formatWktPosition } from 'utils/helpers/info-helper';
import { getPanelRows } from 'utils/helpers/template-helper';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
/* components */
import CommentList from 'components/comments/comment-list';
import PortInfoBunkerPrices from './port-info-bunker-prices';
import Accordion from 'components/accordion/accordion';
/* selectors */
import { getRecentComments, getPortActivities } from './port-info-overview-selectors';

export class PortInfoOverview extends React.PureComponent {
    getUpdatedInfo = (port) => {
        if (port.Type === 1) {
            return null;
        }
        return (
            <section className="sten-content__info-section">
                <p className="text-secondary">
                    {t('PORT_INFO.UPDATED_INFO', {
                        at: port.UpdatedAt ? TimeHelper.getFormatted(port.UpdatedAt, { time: true }) : '-',
                        by: port.UpdatedBy ? port.UpdatedBy.trim() : '-'
                    })}
                </p>
            </section>
        );
    }

    getOverview(port) {
        const columnValues = [];
        const columnClasses = ['col-8', 'col-16'];

        if (port.Code) {
            columnValues.push([
                <label className="sten-panel__label">{t('PORT_INFO.OVERVIEW.UNLO_CODE')}</label>,
                <label className="sten-panel__label sten-panel__label--value">{port.Code}</label>
            ]);
        }

        if (port.Position) {
            columnValues.push([
                <label className="sten-panel__label">{t('PORT_INFO.OVERVIEW.COORDINATES')}</label>,
                <label className="sten-panel__label sten-panel__label--value">{formatWktPosition(port.Position)}</label>
            ]);
        }

        if (typeof port.TimeZoneOffset === 'number') {
            const offsetString = TimeHelper.getUTCOffsetString(port.TimeZoneOffset);
            const localTime = moment().utcOffset(port.TimeZoneOffset);
            columnValues.push([
                <label className="sten-panel__label">{t('PORT_INFO.OVERVIEW.LOCAL_TIME')}</label>,
                <label className="sten-panel__label sten-panel__label--value">
                    {`${TimeHelper.getFormatted(localTime, { time: true })} ${offsetString}`}
                </label>
            ]);
        }

        return <div className="sten-content__section">{getPanelRows(columnClasses, columnValues)}</div>;
    }

    getActivity() {
        if (!this.props.portActivities.length) {
            return null;
        }
        const columnValues = [];
        const columnClasses = ['col-10', 'col-2', 'col-12'];

        this.props.portActivities.forEach((portActivity) => {
            let vesselClasses = 'icon ';
            const emptyVessel = typeof portActivity.IsLaden === 'boolean' ? !portActivity.IsLaden : false;
            if (portActivity.HasAlert) {
                vesselClasses += emptyVessel ? 'sten-icon-ballast-danger' : 'sten-icon-laden-danger';
            } else {
                vesselClasses += emptyVessel ? 'sten-icon-ballast' : 'sten-icon-laden';
            }
            columnValues.push([
                <label
                    className="sten-port-info-overview-activity__vessel-name"
                    onClick={this.getVesselClickHandler(portActivity.IMO)}
                >
                    {portActivity.VesselName}
                </label>,
                <label className={vesselClasses} />,
                <label className="sten-panel__label sten-panel__label--value">
                    {portActivity.InPort
                        ? t('PORT_INFO.OVERVIEW.IN_PORT')
                        : `${t('PORT_INFO.OVERVIEW.ETA')} ${
                            TimeHelper.getFormatted(portActivity.ETA, { utc: true, time: true })}`
                    }
                </label>
            ]);
        });

        return (
            <Accordion header={<h4 className="text-uppercase">{t('PORT_INFO.OVERVIEW.ACTIVITY')}</h4>}>
                <div key="body" className="sten-content__section">
                    {getPanelRows(columnClasses, columnValues)}
                </div>
            </Accordion>
        );
    }

    getRecentComments = () => (
        <React.Fragment>
            <div className="sten-content__section-header text-uppercase">
                <h4>{t('COMMENTS.RECENT_COMMENTS')}</h4>
            </div>
            {this.props.port && this.props.comments.length > 0
                ? (
                    <CommentList
                        canUpdateComments={this.props.permissions.UpdatePortComment}
                        canUpdateAllComments={this.props.permissions.UpdateAllPortComments}
                        comments={this.props.recentComments}
                        resource="port"
                        resourceId={this.props.port.Id}
                    />
                ) : (
                    <div className="sten-content__section text-secondary">
                        <p>{t('COMMENTS.NO_RECENT_COMMENTS')}</p>
                    </div>
                )}
            {(this.props.comments.length > 3 || this.props.permissions.CreatePortComment) && (
                <div className="sten-content__section sten-content__separator text-center">
                    <button className="sten-link" onClick={this.props.onShowMoreClick}>
                        {this.props.comments.length > 3 || !this.props.permissions.CreatePortComment
                            ? t('COMMENTS.VIEW_ALL')
                            : t('COMMENTS.ADD_NEW_COMMENT')}
                    </button>
                </div>
            )}
        </React.Fragment>
    );

    getVesselClickHandler = (imo) => () => this.props.router.updateQueryParams({
        pathname: appRoutes.Map,
        add: { vessel: imo }
    });

    render() {
        return (
            <ScrollArea className="sten-port-info__overview">
                {this.getUpdatedInfo(this.props.port)}
                {this.getOverview(this.props.port)}
                {this.getActivity()}
                <PortInfoBunkerPrices bunkerPrices={this.props.bunkerPrices} />
                {this.getRecentComments(this.props.port)}
            </ScrollArea>
        );
    }
}

PortInfoOverview.propTypes = {
    bunkerPrices: PropTypes.objectOf(PropTypes.any),
    comments: PropTypes.arrayOf(PropTypes.object).isRequired,
    onShowMoreClick: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    port: PropTypes.objectOf(PropTypes.any),
    portActivities: PropTypes.arrayOf(PropTypes.object).isRequired,
    portId: PropTypes.number,
    recentComments: PropTypes.arrayOf(PropTypes.object).isRequired,
    router: TRouter.isRequired
};

PortInfoOverview.defaultProps = {
    bunkerPrices: null,
    port: null,
    portId: null
};

function mapStateToProps(state) {
    return {
        bunkerPrices: state.portInfoReducer.bunkerPrices,
        comments: state.portInfoReducer.comments,
        permissions: state.userReducer.permissions,
        port: state.portInfoReducer.port,
        portActivities: getPortActivities(state),
        recentComments: getRecentComments(state)
    };
}

export default connect(mapStateToProps)(withRouter(PortInfoOverview));
