export const ActionTypes = {
    VOYAGE_PERFORMANCE_GET_VESSEL_DATA: 'VOYAGE_PERFORMANCE_GET_VESSEL_DATA',
    VOYAGE_PERFORMANCE_SET_VESSEL_DATA: 'VOYAGE_PERFORMANCE_SET_VESSEL_DATA',
    VOYAGE_PERFORMANCE_GET_VOYAGE_DATA: 'VOYAGE_PERFORMANCE_GET_VOYAGE_DATA',
    VOYAGE_PERFORMANCE_SET_VOYAGE_DATA: 'VOYAGE_PERFORMANCE_SET_VOYAGE_DATA',
    VOYAGE_PERFORMANCE_GET_VOYAGE_PERFORMANCE_DATA: 'VOYAGE_PERFORMANCE_GET_VOYAGE_PERFORMANCE_DATA',
    VOYAGE_PERFORMANCE_SET_VOYAGE_PERFORMANCE_DATA: 'VOYAGE_PERFORMANCE_SET_VOYAGE_PERFORMANCE_DATA',
    VOYAGE_PERFORMANCE_GET_VOYAGE_EU_ETS: 'VOYAGE_PERFORMANCE_GET_VOYAGE_EU_ETS',
    VOYAGE_PERFORMANCE_SET_VOYAGE_EU_ETS: 'VOYAGE_PERFORMANCE_SET_VOYAGE_EU_ETS',
    VOYAGE_PERFORMANCE_GENERATE_PDF: 'VOYAGE_PERFORMANCE_GENERATE_PDF',
    VOYAGE_PERFORMANCE_RESET_ALL: 'VOYAGE_PERFORMANCE_RESET_ALL'
};

export function getVesselData(dispatch, vesselImo) {
    dispatch({
        type: ActionTypes.VOYAGE_PERFORMANCE_GET_VESSEL_DATA,
        vesselImo
    });
}

export function getVoyageData(dispatch, voyageId) {
    dispatch({
        type: ActionTypes.VOYAGE_PERFORMANCE_GET_VOYAGE_DATA,
        voyageId
    });
}

export function getVoyageEuEts(dispatch, year) {
    dispatch({
        type: ActionTypes.VOYAGE_PERFORMANCE_GET_VOYAGE_EU_ETS,
        year
    });
}

export function getVoyagePerformanceData(dispatch, voyageId, params) {
    dispatch({
        type: ActionTypes.VOYAGE_PERFORMANCE_GET_VOYAGE_PERFORMANCE_DATA,
        voyageId,
        params
    });
}

export function generatePDF(dispatch, isForFootprint = false) {
    dispatch({
        type: ActionTypes.VOYAGE_PERFORMANCE_GENERATE_PDF,
        isForFootprint
    });
}

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.VOYAGE_PERFORMANCE_RESET_ALL
    });
}
