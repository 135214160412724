import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { formatNumber } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import { getFleetActivity } from './fleet-activity-actions';
import { updateUserSettings } from '../../../../user-actions';
/* components */
import Accordion from 'components/accordion/accordion';
import Chart from 'components/chart/chart';
import Progress from 'components/progress/progress';
import ScrollArea from 'components/scroll-area/scroll-area';
import DateRangePopup from 'components/date-range-popup/date-range-popup';
import Validation from 'components/validation/validation';
import Checkbox from 'components/checkbox/checkbox';
import EmptyContent from 'components/empty-content/empty-content';
/* selectors */
import { getSelectedDateRange } from './fleet-activity-selectors';
import { getSelectedDate } from '../../../map-date-picker/map-date-picker-selectors';
/* styles */
import './fleet-activity.scss';
/* config */
import { getConfig } from './fleet-activity-chart-config';

const maxVessels = 1000;

export class FleetActivity extends React.PureComponent {
    constructor(props) {
        super(props);
        const { selectedRange } = this.props;
        this.state = {
            datePickerFrom: selectedRange.from,
            datePickerTo: selectedRange.to,
            prevSelectedRange: null,
            prevChartData: null,
            chartKey: 0
        };
    }

    componentDidMount() {
        this.fetchFleetActivityStats(this.props);
    }

    static getDerivedStateFromProps(props, state) {
        let newState = null;
        if (state.prevSelectedRange !== props.selectedRange) {
            newState = {
                datePickerFrom: props.selectedRange.from,
                datePickerTo: props.selectedRange.to,
                prevSelectedRange: props.selectedRange
            };
        }
        if (state.prevChartData !== props.chartData) {
            newState = {
                ...newState,
                prevChartData: props.chartData,
                chartKey: state.chartKey + 1
            };
        }
        return newState;
    }

    componentDidUpdate(prevProps) {
        const p = this.props;
        if (prevProps.selectedRange !== p.selectedRange
            || prevProps.modeId !== p.modeId
            || (prevProps.settingUpdated !== p.settingUpdated && p.settingUpdated.name === 'mapFilters')
            || (prevProps.resourceUpdated !== p.resourceUpdated && p.resourceUpdated.name === 'fleets')
            || p.settings.IncludeTCVoyagesFleet !== prevProps.settings.IncludeTCVoyagesFleet
        ) {
            this.fetchFleetActivityStats(p);
        }
    }

    fetchFleetActivityStats = (props) => {
        this.props.getFleetActivity(
            props.selectedRange.from.toISOString(),
            props.selectedRange.to.toISOString()
        );
    };

    updateSelectedRange = () => {
        const rangePresets = this.generateDateRangePresets();
        const selectedRangePreset = rangePresets.find((rp) => {
            return TimeHelper.isSameDay(rp.startDate, this.state.datePickerFrom)
                && TimeHelper.isSameDay(rp.endDate, this.state.datePickerTo);
        });

        if (selectedRangePreset) {
            this.props.updateUserSettings({
                FleetActivityRangeId: selectedRangePreset.id,
                FleetActivityModeId: this.props.rangeModes.PREDEFINED_RANGE
            });
        } else {
            this.props.updateUserSettings({
                VesselActivityDateFrom: this.state.datePickerFrom,
                VesselActivityDateTo: this.state.datePickerTo,
                FleetActivityModeId: this.props.rangeModes.CUSTOM_DATE_RANGE
            });
        }
    };

    generateDateRangePresets = () => {
        const endDate = this.props.selectedDate.clone().startOf('d');

        return this.props.rangeOptions.map((ro) => {
            return {
                id: ro.Id,
                title: ro.DisplayName,
                startDate: ro.Type === 'ToDate'
                    ? endDate.clone().startOf(ro.Unit)
                    : endDate.clone().subtract(ro.Value, ro.Unit),
                endDate
            };
        });
    };

    handleRangeChange = ({ rangeStart, rangeEnd }) => {
        this.setState({
            datePickerFrom: rangeStart,
            datePickerTo: rangeEnd
        });
    };

    handleTCVoyagesCheckboxChange = () => {
        return this.props.updateUserSettings({
            IncludeTCVoyagesFleet: !this.props.settings.IncludeTCVoyagesFleet
        });
    };

    static getMaxDate() {
        const now = new Date();
        return new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()));
    }

    render() {
        const stats = this.props.stats;
        const isApplyDateRangeButtonDisabled = this.props.vesselsOnMapCount > maxVessels
            || (TimeHelper.isSameDay(this.state.datePickerFrom, this.props.selectedRange.from)
                && TimeHelper.isSameDay(this.state.datePickerTo, this.props.selectedRange.to));
        return (
            <div className="sten-fleet-activity">
                <div className="sten-content__body sten-fleet-activity__filters">
                    <div className="sten-content__section">
                        <Validation.Form className="flex-row" onSubmit={this.updateSelectedRange}>
                            <div className="flex-grow">
                                <Validation.Wrapper hintsOnHover validations={{ dateRange: true }}>
                                    <DateRangePopup
                                        disabled={this.props.vesselsOnMapCount > maxVessels}
                                        isUTC
                                        alignment="end"
                                        name="dateRange"
                                        dateType="date"
                                        presets={this.generateDateRangePresets()}
                                        onRangeSelect={this.handleRangeChange}
                                        maxDate={FleetActivity.getMaxDate()}
                                        value={{
                                            rangeStart: this.state.datePickerFrom,
                                            rangeEnd: this.state.datePickerTo
                                        }}
                                    />
                                </Validation.Wrapper>
                            </div>
                            <div className="flex-shrink">
                                <Validation.Button
                                    disabled={isApplyDateRangeButtonDisabled}
                                    className="btn btn--primary icon icon-check btn--icon col-24"
                                />
                            </div>
                        </Validation.Form>
                    </div>
                    <div className="sten-content__section">
                        <div className="flex-row">
                            <div className="flex-shrink">
                                <Checkbox
                                    isChecked={this.props.settings.IncludeTCVoyagesFleet}
                                    onChange={this.handleTCVoyagesCheckboxChange}
                                    isDisabled={this.props.vesselsOnMapCount > maxVessels}
                                >
                                    {translate('FLEET_OVERVIEW.INCLUDE_TC_VOYAGES')}
                                </Checkbox>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.vesselsOnMapCount > maxVessels
                    ? (
                        <EmptyContent>
                            {translate('FLEET_OVERVIEW.VESSEL_COUNT_MESSAGE')}
                            <br />
                            {translate('FLEET_OVERVIEW.VESSEL_COUNT_MESSAGE_2')}
                        </EmptyContent>
                    ) : (
                        <ScrollArea className="sten-content__body sten-fleet-activity__body">
                            {stats && (
                                <Accordion
                                    header={<h4 className="text-uppercase">{translate('FLEET_OVERVIEW.ACTIVITY')}</h4>}
                                >
                                    <div className="sten-content__section">
                                        <label className="sten-panel__label">
                                            {translate('FLEET_OVERVIEW.LADEN_BALLAST_RATIO')}
                                        </label>
                                        <Progress
                                            value={stats.PercentageLaden}
                                            refValue={stats.PercentageBallast}
                                            label="Laden"
                                            refLabel="Ballast"
                                        />
                                    </div>
                                    <Chart
                                        key={this.state.chartKey}
                                        className="sten-fleet-activity__chart"
                                        highchartsMore
                                        config={getConfig()}
                                        series={this.props.chartData.series}
                                        categories={this.props.chartData.categories}
                                    />
                                    <div className="sten-content__section">
                                        <div className="sten-panel__row flex-row flex-center">
                                            <div className="col-8">
                                                <label className="sten-panel__label">
                                                    {translate('FLEET_OVERVIEW.AVERAGE_BALLAST_SPEED')}
                                                </label>
                                            </div>
                                            <div className="col-16">
                                                <label className="sten-panel__label">
                                                    {typeof stats.AverageBallastSpeed === 'number' && (
                                                        <span className="sten-panel__label--value">
                                                            {`${formatNumber(stats.AverageBallastSpeed)}`
                                                                + `${translate('UNITS.SPEED')}`}
                                                        </span>
                                                    )}
                                                    {!stats.AverageBallastSpeed && (
                                                        <span className="sten-panel__label--value">-</span>
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="sten-panel__row flex-row flex-center">
                                            <div className="col-8">
                                                <label className="sten-panel__label">
                                                    {translate('FLEET_OVERVIEW.AVERAGE_LADEN_SPEED')}
                                                </label>
                                            </div>
                                            <div className="col-16">
                                                <label className="sten-panel__label">
                                                    {typeof stats.AverageLadenSpeed === 'number' && (
                                                        <span className="sten-panel__label--value">
                                                            {`${formatNumber(stats.AverageLadenSpeed)}`
                                                                + `${translate('UNITS.SPEED')}`}
                                                        </span>
                                                    )}
                                                    {!stats.AverageLadenSpeed && (
                                                        <span className="sten-panel__label--value">-</span>
                                                    )}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="sten-content__section sten-content__separator
                                        sten-content__separator--inner"
                                    >
                                        <div className="sten-panel__row">
                                            <label className="sten-panel__label">
                                                {translate('FLEET_OVERVIEW.DISCLAIMER_TEXT')}
                                            </label>
                                        </div>
                                    </div>
                                </Accordion>
                            )}
                        </ScrollArea>
                    )
                }
            </div>
        );
    }
}

FleetActivity.propTypes = {
    chartData: PropTypes.objectOf(PropTypes.any),
    getFleetActivity: PropTypes.func.isRequired,
    modeId: PropTypes.number.isRequired,
    rangeModes: PropTypes.objectOf(PropTypes.any).isRequired,
    rangeOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
    resourceUpdated: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedDate: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedRange: PropTypes.objectOf(PropTypes.any).isRequired,
    settingUpdated: PropTypes.objectOf(PropTypes.any).isRequired,
    settings: PropTypes.objectOf(PropTypes.any).isRequired,
    stats: PropTypes.objectOf(PropTypes.any),
    updateUserSettings: PropTypes.func.isRequired,
    vesselsOnMapCount: PropTypes.number.isRequired
};

FleetActivity.defaultProps = {
    chartData: null,
    stats: null
};

function mapStateToProps(state) {
    return {
        chartData: state.fleetActivityReducer.chartData,
        modeId: state.userReducer.settings && state.userReducer.settings.FleetActivityModeId,
        rangeModes: state.fleetActivityReducer.rangeModes,
        rangeOptions: state.vesselInfoReducer.rangeOptions,
        resourceUpdated: state.userReducer.resourceUpdated,
        selectedDate: getSelectedDate(state),
        selectedRange: getSelectedDateRange(state),
        settingUpdated: state.userReducer.settingUpdated,
        settings: state.userReducer.settings,
        stats: state.fleetActivityReducer.stats,
        vesselsOnMapCount: state.homeReducer.vesselsOnMapCount
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getFleetActivity: (from, to) => getFleetActivity(dispatch, from, to),
        updateUserSettings: settings => updateUserSettings(dispatch, settings)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FleetActivity);
