export const ActionTypes = {
    MARKET_INTEL_RESET_ALL: 'MARKET_INTEL_RESET_ALL',
    MARKET_INTEL_SET_SELECTED_ROUTE_CODE: 'MARKET_INTEL_SET_SELECTED_ROUTE_CODE',
    MARKET_INTEL_GET_BUNKER_PORTS: 'MARKET_INTEL_GET_BUNKER_PORTS',
    MARKET_INTEL_SET_BUNKER_PORTS: 'MARKET_INTEL_SET_BUNKER_PORTS',
    MARKET_INTEL_GET_ROUTES: 'MARKET_INTEL_GET_ROUTES',
    MARKET_INTEL_SET_ROUTES: 'MARKET_INTEL_SET_ROUTES',
    MARKET_INTEL_GET_OPTIONS: 'MARKET_INTEL_GET_OPTIONS',
    MARKET_INTEL_SET_OPTIONS: 'MARKET_INTEL_SET_OPTIONS',
    MARKET_INTEL_GET_DISTRIBUTION_HEATMAP: 'MARKET_INTEL_GET_DISTRIBUTION_HEATMAP',
    MARKET_INTEL_SET_DISTRIBUTION_HEATMAP: 'MARKET_INTEL_SET_DISTRIBUTION_HEATMAP',
    MARKET_INTEL_GET_TOTAL_VESSEL_DISTRIBUTION: 'MARKET_INTEL_GET_TOTAL_VESSEL_DISTRIBUTION',
    MARKET_INTEL_SET_TOTAL_VESSEL_DISTRIBUTION: 'MARKET_INTEL_SET_TOTAL_VESSEL_DISTRIBUTION'
};

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.MARKET_INTEL_RESET_ALL
    });
}

export function setSelectedRouteCode(dispatch, routeCode) {
    dispatch({
        type: ActionTypes.MARKET_INTEL_SET_SELECTED_ROUTE_CODE,
        routeCode
    });
}

export function getBunkerPorts(dispatch) {
    dispatch({ type: ActionTypes.MARKET_INTEL_GET_BUNKER_PORTS });
}

export function getRoutes(dispatch) {
    dispatch({ type: ActionTypes.MARKET_INTEL_GET_ROUTES });
}

export function getOptions(dispatch) {
    dispatch({ type: ActionTypes.MARKET_INTEL_GET_OPTIONS });
}

export function getDistributionHeatMap(dispatch) {
    dispatch({ type: ActionTypes.MARKET_INTEL_GET_DISTRIBUTION_HEATMAP });
}

export function getTotalVesselDistribution(dispatch) {
    dispatch({ type: ActionTypes.MARKET_INTEL_GET_TOTAL_VESSEL_DISTRIBUTION });
}
