import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
import { TankLayout } from 'components/tank-layout';
/* styles */
import './voyage-info-cargo.scss';

class VoyageInfoCargo extends React.PureComponent {
    render() {
        return (
            <div className="sten-voyage-info-cargo">
                <ScrollArea className="sten-voyage-info-cargo__body  sten-voyage-info-cargo__body--with-footer">
                    <TankLayout
                        cargoSummary={this.props.selectedVoyageCargoSummary}
                        wings={this.props.selectedVoyageTankLayout}
                        showPreviousCommodities
                    />
                </ScrollArea>
                <footer className="sten-content__footer">
                    <div className="flex-grow">
                        <div className="row">
                            <div className="col-12">
                                <button
                                    onClick={this.props.generateCargoPDF}
                                    className="btn btn--secondary text-uppercase col-24"
                                >
                                    {translate('CARGO_EXPORT.EXPORT_PDF')}
                                </button>
                            </div>
                            <div className="col-12">
                                <button
                                    onClick={this.props.generateCargoXLSX}
                                    className="btn btn--secondary text-uppercase col-24"
                                >
                                    {translate('CARGO_EXPORT.EXPORT_XLSX')}
                                </button>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

VoyageInfoCargo.propTypes = {
    vesselName: PropTypes.string.isRequired,
    generateCargoXLSX: PropTypes.func.isRequired,
    generateCargoPDF: PropTypes.func.isRequired,
    selectedVoyageTankLayout: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedVoyageCargoSummary: PropTypes.objectOf(PropTypes.any).isRequired
};

export default VoyageInfoCargo;
