
import { ActionTypes } from './cargo-inquiry-actions';

const initialState = {
    cargoInformation: '',
    loadingPort: null,
    dischargingPort: null,
    loadingDate: null,
    dischargingDate: null,
    messageSent: false
};

export default function cargoInquiryReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.CARGO_INQUIRY_SET_INFORMATION: {
        return {
            ...state,
            cargoInformation: action.cargoInformation
        };
    }
    case ActionTypes.CARGO_INQUIRY_SET_DATE: {
        if (action.portType === 'loading') {
            const dateObj = {
                loadingDate: action.date.toISOString()
            };
            if (action.date.isAfter(state.dischargingDate)) {
                dateObj.dischargingDate = action.date.toISOString();
            }
            return {
                ...state,
                ...dateObj
            };
        }
        return {
            ...state,
            dischargingDate: action.date.toISOString()
        };
    }
    case ActionTypes.CARGO_INQUIRY_SET_PORT: {
        if (action.portType === 'loading') {
            return {
                ...state,
                loadingPort: action.port
            };
        }
        return {
            ...state,
            dischargingPort: action.port
        };
    }
    case ActionTypes.CARGO_INQUIRY_MESSAGE_SENT: {
        return {
            ...initialState,
            messageSent: true
        };
    }
    case ActionTypes.CARGO_INQUIRY_CLOSE_PANEL: {
        if (state.messageSent) {
            return {
                ...state,
                ...initialState
            };
        }
        return state;
    }
    default:
        return state;
    }
}
