import moment from 'moment';
import { createSelector } from 'reselect';
/* helpers */
import createByIdSelector from 'utils/helpers/selector-helper';
import { roundNumber } from 'utils/helpers/info-helper';

export const getRouteData = createSelector(
    state => state.tradingRouteDetailsReducer.data,

    (tradingRouteData) => {
        const parsedData = {
            worldScaleSeries: {
                PredictionDeltaArea: [],
                PredictionLowerQuartile: [],
                PredictionUpperQuartile: [],
                Prediction: [],
                Outcome: []
            },
            directionSeries: {
                PredictionDeltaArea: [],
                PredictionLowerQuartile: [],
                PredictionUpperQuartile: [],
                Prediction: [],
                Outcome: []
            },
            categories: [],
            IntelAccuracy: null
        };
        if (tradingRouteData && tradingRouteData.IntelAccuracy) {
            parsedData.IntelAccuracy = tradingRouteData.IntelAccuracy;
        }

        if (tradingRouteData && tradingRouteData.Intel && tradingRouteData.Intel.length) {
            tradingRouteData.Intel.forEach((intelData) => {
                const utcDate = moment.utc(intelData.Date).startOf('day').valueOf();

                parsedData.worldScaleSeries.Outcome.push([utcDate, roundNumber(intelData.BITR, 2)]);
                parsedData.worldScaleSeries.Prediction.push([utcDate, roundNumber(intelData.PredictedWS, 2)]);
                parsedData.worldScaleSeries.PredictionLowerQuartile.push(
                    [utcDate, roundNumber(intelData.LowerQuartile, 2)]
                );
                parsedData.worldScaleSeries.PredictionUpperQuartile.push(
                    [utcDate, roundNumber(intelData.UpperQuartile, 2)]
                );
                parsedData.worldScaleSeries.PredictionDeltaArea.push([
                    utcDate,
                    roundNumber(intelData.LowerQuartile, 2),
                    roundNumber(intelData.UpperQuartile, 2)
                ]);

                parsedData.directionSeries.Outcome.push([utcDate, roundNumber(intelData.ActualWSDelta, 2)]);
                parsedData.directionSeries.Prediction.push([utcDate, roundNumber(intelData.PredictionDelta, 2)]);
                parsedData.directionSeries.PredictionLowerQuartile.push(
                    [utcDate, roundNumber(intelData.DeltaLowerQuartile, 2)]
                );
                parsedData.directionSeries.PredictionUpperQuartile.push(
                    [utcDate, roundNumber(intelData.DeltaUpperQuartile, 2)]
                );
                parsedData.directionSeries.PredictionDeltaArea.push([
                    utcDate,
                    roundNumber(intelData.DeltaLowerQuartile, 2),
                    roundNumber(intelData.DeltaUpperQuartile, 2)
                ]);

                parsedData.categories.push(utcDate);
            });
        }
        return parsedData;
    }
);

export const rangesById = createByIdSelector(state => state.tradingRouteDetailsReducer.rangeOptions, 'Id');
export const modelsById = createByIdSelector(state => state.tradingRouteDetailsReducer.modelOptions, 'Id');

export const getSelectedModel = createSelector(
    state => state.userReducer.settings.MarketIntelModelNameId,
    modelsById,

    (userSavedModelId, allModels) => {
        if (userSavedModelId && allModels && allModels[userSavedModelId]) {
            return allModels[userSavedModelId];
        }
        if (allModels && allModels.length > 1) {
            return allModels[1];
        }
        return null;
    }
);

export const getSelectedRange = createSelector(
    state => state.userReducer.settings.MarketIntelRangeId,
    rangesById,

    (userSavedRangeId, allRanges) => {
        if (userSavedRangeId && allRanges && allRanges[userSavedRangeId]) {
            return allRanges[userSavedRangeId];
        }
        if (allRanges && allRanges.length > 1) {
            return allRanges[1];
        }
        return null;
    }
);

export default getRouteData;
