import { createSelector } from 'reselect';
/* utils */
import { sortByProp } from 'utils/helpers/array-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* services */
import LocalStorageService from 'services/local-storage-service';
/* mocks */
import { generateMockedQuarterlyReport } from './quarterly-report-test-utils';
/* constants */
import { rowProps, colProps } from './quarterly-report-constants';

export const getQuarterFromRange = createSelector(
    state => state.energyManagementReportsReducer.selectedRange,
    selectedRange => {
        return selectedRange
            ? TimeHelper.getQuarterFromRange(selectedRange.rangeStart, selectedRange.rangeEnd)
            : null;
    }
);

export const getTableData = createSelector(
    state => state.energyManagementReportsQuarterlyReportReducer.report,
    (state) => state.energyManagementReportsReducer.vessels,
    (report, vessels) => {
        const data = LocalStorageService.isDebugModeActive()
            ? generateMockedQuarterlyReport(vessels, 200, 300)
            : report;
        if (!data || !data.Summary || data.Segments.length === 0) {
            return null;
        }

        const fuelTypes = [];
        const fuelTypeMap = {};
        const parsedData = {};

        const convertAndExtract = (item) => {
            const parsedItem = { ...item };
            rowProps.forEach(prop => {
                parsedItem[prop] = { ...item[prop], BunkerData: {} };
                if (item[prop].BunkerData) {
                    item[prop].BunkerData.forEach(fuelType => {
                        parsedItem[prop].BunkerData[fuelType.Id] = fuelType;
                        if (!fuelTypeMap[fuelType.Id]) {
                            fuelTypeMap[fuelType.Id] = true;
                            fuelTypes.push({
                                Id: fuelType.Id,
                                Name: fuelType.Name,
                                Sequence: fuelType.Sequence
                            });
                        }
                    });
                }
            });
            return parsedItem;
        };

        parsedData.Summary = convertAndExtract(data.Summary);
        parsedData.Segments = data.Segments.map(segment => (
            { ...segment, Summary: convertAndExtract(segment.Summary) }
        ));
        fuelTypes.sort(sortByProp('Sequence'));

        const columns = [];
        colProps.forEach(colProp => {
            if (colProp.isFuelData) {
                fuelTypes.forEach(ft => {
                    columns.push({ ...colProp, key: `${colProp.key}.${ft.Id}.Quantity` });
                });
            } else {
                columns.push(colProp);
            }
        });

        return { fuelTypes, parsedData, columns };
    }
);
