import { takeEvery, put } from 'redux-saga/effects';
/* actions */
import { ActionTypes } from './distance-calculator-page-actions';
/* services */
import PortService from 'services/core-api/port-service';

function* getPorts(action) {
    const ports = yield PortService.get(action.params);
    if (ports) {
        yield put({ type: ActionTypes.DISTANCE_CALCULATOR_PAGE_SET_PORTS, ports });
    }
}

export default function* distanceCalculatorPageSaga() {
    yield takeEvery(ActionTypes.DISTANCE_CALCULATOR_PAGE_GET_PORTS, getPorts);
}
