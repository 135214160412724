export const ActionTypes = {
    ASSIGN_TO_FLEET_MODAL_OPEN: 'ASSIGN_TO_FLEET_MODAL_OPEN',
    ASSIGN_TO_FLEET_MODAL_CLOSE: 'ASSIGN_TO_FLEET_MODAL_CLOSE',
    ASSIGN_TO_FLEET_MODAL_GET_OPTIONS: 'ASSIGN_TO_FLEET_MODAL_GET_OPTIONS',
    ASSIGN_TO_FLEET_MODAL_SET_OPTIONS: 'ASSIGN_TO_FLEET_MODAL_SET_OPTIONS',
    ASSIGN_TO_FLEET_MODAL_GET_RELATIONSHIPS: 'ASSIGN_TO_FLEET_MODAL_GET_RELATIONSHIPS',
    ASSIGN_TO_FLEET_MODAL_SET_RELATIONSHIPS: 'ASSIGN_TO_FLEET_MODAL_SET_RELATIONSHIPS',
    ASSIGN_TO_FLEET_MODAL_SAVE_RELATIONSHIPS: 'ASSIGN_TO_FLEET_MODAL_SAVE_RELATIONSHIPS',
    ASSIGN_TO_FLEET_MODAL_RELATIONSHIPS_SAVED: 'ASSIGN_TO_FLEET_MODAL_RELATIONSHIPS_SAVED',
    ASSIGN_TO_FLEET_MODAL_CLEAR_RELATIONSHIPS: 'ASSIGN_TO_FLEET_MODAL_CLEAR_RELATIONSHIPS',
    ASSIGN_TO_FLEET_MODAL_REMOVE_VESSEL_FROM_FLEET: 'ASSIGN_TO_FLEET_MODAL_REMOVE_VESSEL_FROM_FLEET'
};

export function openAssignToFleetModal(dispatch, vessel, fleet) {
    dispatch({
        type: ActionTypes.ASSIGN_TO_FLEET_MODAL_OPEN,
        vessel,
        fleet
    });
}

export function closeAssignToFleetModal(dispatch) {
    dispatch({
        type: ActionTypes.ASSIGN_TO_FLEET_MODAL_CLOSE
    });
}

export function getOptions(dispatch) {
    dispatch({
        type: ActionTypes.ASSIGN_TO_FLEET_MODAL_GET_OPTIONS
    });
}

export function saveRelationships(dispatch, imo, relationships) {
    dispatch({
        type: ActionTypes.ASSIGN_TO_FLEET_MODAL_SAVE_RELATIONSHIPS,
        imo,
        relationships
    });
}

export function clearRelationships(dispatch) {
    dispatch({
        type: ActionTypes.ASSIGN_TO_FLEET_MODAL_CLEAR_RELATIONSHIPS
    });
}

export function getVesselRelationships(dispatch, imo) {
    dispatch({
        type: ActionTypes.ASSIGN_TO_FLEET_MODAL_GET_RELATIONSHIPS,
        imo
    });
}
