export const ActionTypes = {
    CALCULATOR_SET_BUNKER_OPTIONS_AND_INITIAL_VALUES: 'CALCULATOR_SET_BUNKER_OPTIONS_AND_INITIAL_VALUES',
    CALCULATOR_SET_FILTERS: 'CALCULATOR_SET_FILTERS',
    CALCULATOR_GET_VESSEL: 'CALCULATOR_GET_VESSEL',
    CALCULATOR_SET_VESSEL: 'CALCULATOR_SET_VESSEL',
    CALCULATOR_SET_CALCULATION_VESSEL: 'CALCULATOR_SET_CALCULATION_VESSEL',
    CALCULATOR_SET_VESSEL_NOTIFICATIONS: 'CALCULATOR_SET_VESSEL_NOTIFICATIONS',
    CALCULATOR_SET_VESSEL_STARTING_POSITION: 'CALCULATOR_SET_VESSEL_STARTING_POSITION',
    CALCULATOR_SET_VOYAGE_ITINERARY: 'CALCULATOR_SET_VOYAGE_ITINERARY',
    CALCULATOR_SET_VOYAGE_ROUTE: 'CALCULATOR_SET_VOYAGE_ROUTE',
    CALCULATOR_SET_VOYAGE_AIS_POINTS: 'CALCULATOR_SET_VOYAGE_AIS_POINTS',
    CALCULATOR_RESET_CALCULATION_VOYAGE: 'CALCULATOR_RESET_CALCULATION_VOYAGE',
    CALCULATOR_SET_POINTS: 'CALCULATOR_SET_POINTS',
    CALCULATOR_SET_ROUTE_POINTS: 'CALCULATOR_SET_ROUTE_POINTS',
    CALCULATOR_RESET: 'CALCULATOR_RESET',
    CALCULATOR_GET_BUNKER_OPTIONS: 'CALCULATOR_GET_BUNKER_OPTIONS',
    CALCULATOR_GET_CALCULATION: 'CALCULATOR_GET_CALCULATION',
    CALCULATOR_ADD_POINT: 'CALCULATOR_ADD_POINT',
    CALCULATOR_MOVE_POINT: 'CALCULATOR_MOVE_POINT',
    CALCULATOR_REMOVE_POINT: 'CALCULATOR_REMOVE_POINT',
    CALCULATOR_UPDATE_POINT: 'CALCULATOR_UPDATE_POINT',
    CALCULATOR_UPDATE_STARTING_POINT: 'CALCULATOR_UPDATE_STARTING_POINT',
    CALCULATOR_SEND_REQUEST: 'CALCULATOR_SEND_REQUEST',
    CALCULATOR_REQUEST_SENT: 'CALCULATOR_REQUEST_SENT',
    CALCULATOR_SET_CALCULATIONS: 'CALCULATOR_SET_CALCULATIONS',
    CALCULATOR_RESET_SUMMARY: 'CALCULATOR_RESET_SUMMARY',
    CALCULATOR_SET_CUSTOM_POINT: 'CALCULATOR_SET_CUSTOM_POINT',
    CALCULATOR_SET_ACTIVE_PIN_POINT_ID: 'CALCULATOR_SET_ACTIVE_PIN_POINT_ID',
    CALCULATOR_SET_CLICKED_POINT_DATA: 'CALCULATOR_SET_CLICKED_POINT_DATA'
};

export function setCalculationVessel(dispatch, vessel) {
    dispatch({
        type: ActionTypes.CALCULATOR_SET_VESSEL,
        vessel
    });
}

export function getCalculationVessel(dispatch, vesselImo, isDeviation) {
    dispatch({
        type: ActionTypes.CALCULATOR_GET_VESSEL,
        vesselImo,
        isDeviation
    });
}

export function getBunkerOptions(dispatch) {
    dispatch({
        type: ActionTypes.CALCULATOR_GET_BUNKER_OPTIONS
    });
}

export function setFilters(dispatch, name, value) {
    dispatch({
        type: ActionTypes.CALCULATOR_SET_FILTERS,
        name,
        value
    });
}

export function getCalculation(dispatch, points) {
    dispatch({
        type: ActionTypes.CALCULATOR_GET_CALCULATION,
        points
    });
}

export function setCalculationPoints(dispatch, points) {
    dispatch({
        type: ActionTypes.CALCULATOR_SET_POINTS,
        points
    });
}

export function setRoutePoints(dispatch, routePoints) {
    dispatch({
        type: ActionTypes.CALCULATOR_SET_ROUTE_POINTS,
        routePoints
    });
}

export function reset(dispatch) {
    dispatch({
        type: ActionTypes.CALCULATOR_RESET
    });
}

export function resetSummary(dispatch) {
    dispatch({
        type: ActionTypes.CALCULATOR_RESET_SUMMARY
    });
}

export function addPoint(dispatch, index) {
    dispatch({
        type: ActionTypes.CALCULATOR_ADD_POINT,
        index
    });
}

export function movePoint(dispatch, fromIndex, toIndex) {
    dispatch({
        type: ActionTypes.CALCULATOR_MOVE_POINT,
        fromIndex,
        toIndex
    });
}

export function removePoint(dispatch, index) {
    dispatch({
        type: ActionTypes.CALCULATOR_REMOVE_POINT,
        index
    });
}

export function updatePoint(dispatch, point, index) {
    dispatch({
        type: ActionTypes.CALCULATOR_UPDATE_POINT,
        point,
        index
    });
}

export function updateStartingPoint(dispatch, point) {
    dispatch({
        type: ActionTypes.CALCULATOR_UPDATE_STARTING_POINT,
        point
    });
}

export function setVesselStartingPosition(dispatch, startingPosition) {
    dispatch({
        type: ActionTypes.CALCULATOR_SET_VESSEL_STARTING_POSITION,
        startingPosition
    });
}

export function sendRequest(dispatch, comment) {
    dispatch({
        type: ActionTypes.CALCULATOR_SEND_REQUEST,
        comment
    });
}

export function setActivePinPointId(dispatch, activePinPointId) {
    dispatch({
        type: ActionTypes.CALCULATOR_SET_ACTIVE_PIN_POINT_ID,
        activePinPointId
    });
}

export function setClickedPointData(dispatch, portId, coordinates) {
    dispatch({
        type: ActionTypes.CALCULATOR_SET_CLICKED_POINT_DATA,
        portId,
        coordinates
    });
}

export function setCustomCalculationPoint(dispatch, coordinates, index) {
    dispatch({
        type: ActionTypes.CALCULATOR_SET_CUSTOM_POINT,
        coordinates,
        index
    });
}
