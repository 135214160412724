import React from 'react';
import PropTypes from 'prop-types';
/* helpers */
import { isBetween } from 'utils/helpers/info-helper';

class TrendIcon extends React.PureComponent {
    constructor(props) {
        super(props);
        this.trends = {
            1: ['strong-down', 'strong-up'],
            2: ['strong-down', 'flat', 'strong-up'],
            4: ['strong-down', 'moderate-down', 'flat', 'moderate-up', 'strong-up']
        };
    }

    getArrowClass = (value, breakpoints) => {
        let arrowClass = '';
        const trends = this.trends[breakpoints.length];
        let min;
        let max;
        if (trends) {
            for (let i = 0; i < trends.length; i++) {
                min = breakpoints[i - 1];
                max = breakpoints[i];
                if (i === 0) {
                    min = -Infinity;
                } else if (i === trends.length - 1) {
                    max = Infinity;
                }
                if (isBetween(value, min, max)) {
                    arrowClass += ` sten-icon-trend-${trends[i]}`;
                    break;
                }
            }
        }
        return arrowClass;
    };

    render() {
        if (typeof (this.props.value) !== 'number') {
            return null;
        }
        const arrowClass = this.getArrowClass(this.props.value, this.props.breakpoints);
        return (
            <span className={`sten-trend-icon icon${arrowClass}`} />
        );
    }
}

TrendIcon.propTypes = {
    breakpoints: PropTypes.arrayOf(PropTypes.number),
    value: PropTypes.number
};

TrendIcon.defaultProps = {
    breakpoints: [-10, -2.5, 2.5, 10],
    value: null
};

export default TrendIcon;
