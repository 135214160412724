export default {
    global: {
        Escape: 'navmenu-collapse'
    },
    '/map': {
        f: 'global-search-input-focus',
        'ctrl+f': 'global-search-input-focus',
        ArrowRight: 'map-date-picker-add-hour',
        ArrowLeft: 'map-date-picker-subtract-hour',
        'ctrl+ArrowRight': 'map-date-picker-add-day',
        'ctrl+ArrowLeft': 'map-date-picker-subtract-day',
        Escape: 'deselect-element',
        preventDefault: [
            'ctrl+f'
        ]
    }
};
