import CoreApi from './core-api';

class UserService {
    constructor() {
        this.resourceUrl = 'User';
    }

    get() {
        return CoreApi.get(this.resourceUrl)
            .then(response => response.data, () => null);
    }

    updateSettings(data) {
        return CoreApi.post('Account/Settings', data)
            .then(response => response.data, () => null);
    }

    getHomePage() {
        return CoreApi.get(`${this.resourceUrl}/HomePage`)
            .then(response => response.data, () => null);
    }
}

export default new UserService();
