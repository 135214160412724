import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* components */
import VoyageReport from './voyage-report';
import { getReportsById } from './voyage-report-selectors';
/* utils */
import getFontSize from 'utils/helpers/cached-font-size';
/* styles */
import './voyage-reports.scss';

export class VoyageReports extends React.PureComponent {
    handleWheel = (event) => {
        const newEvent = new WheelEvent(event.nativeEvent.type, event.nativeEvent);
        if (this.mapViewPort) {
            this.mapViewPort.dispatchEvent(newEvent);
        }
    };

    componentDidMount() {
        this.mapViewPort = document.getElementsByClassName('ol-viewport')[0];
    }

    static getZoomMultiplier(zoomLevel) {
        return zoomLevel / 4 < 1.2 ? zoomLevel / 4 : 1.2;
    }

    render() {
        if (this.props.reportPositions && this.props.reportPositions.length) {
            const { reports, selectedVesselReportId } = this.props;
            const zoomMultiplier = VoyageReports.getZoomMultiplier(this.props.zoomLevel);
            const fontSize = getFontSize();
            let report;
            return (
                <div onWheel={this.handleWheel}>
                    {this.props.reportPositions.map(reportPosition => {
                        report = reports[reportPosition.reportId];
                        return report ? (
                            <VoyageReport
                                key={report.ReportId}
                                report={report}
                                zoomMultiplier={zoomMultiplier}
                                fontSizeMultiplier={fontSize}
                                isActive={report.ReportId === selectedVesselReportId}
                                reportPosition={reportPosition}
                            />
                        ) : null;
                    }, this)}
                </div>
            );
        }
        return null;
    }
}

VoyageReports.propTypes = {
    reportPositions: PropTypes.arrayOf(PropTypes.any).isRequired,
    reports: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedVesselReportId: PropTypes.number,
    zoomLevel: PropTypes.number.isRequired
};

VoyageReports.defaultProps = {
    selectedVesselReportId: null
};

function mapStateToProps(state) {
    return {
        reportPositions: state.voyageReportsReducer.reportPositions,
        reports: getReportsById(state),
        zoomLevel: state.voyageReportsReducer.zoomLevel
    };
}

export default connect(mapStateToProps, null)(VoyageReports);
