import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
/* Utils */
import { translate } from 'utils/i18n/i18n-model';
/* Helpers */
import TimeHelper from 'utils/helpers/time-helper';
/* Actions */
import { toggleMissingReportReminderModal } from './send-reminder-modal-actions';
import { sendReminder } from '../vessel-report-actions';
/* components */
import Modal from 'components/modal/modal';

class SendReminderModal extends React.PureComponent {
    sendReminder = () => {
        this.props.sendReminder(this.props.report.MissingReportId);
    };

    closeMissingReportReminderModal = () => this.props.toggleMissingReportReminderModal(null);

    render() {
        if (!this.props.report) { return null; }
        let isReminderDisabled = false;
        let reminderInfoText = translate('SEND_REMINDER_MODAL.REMINDER_SEND_TITLE');
        if (!this.props.report.IsSendEnabled) {
            isReminderDisabled = true;
            reminderInfoText = translate('SEND_REMINDER_MODAL.REMINDER_DISABLED_INVALID_EMAIL');
        } else {
            const reminderDateDifference = this.props.report.ReminderDate
                ? moment().diff(moment(this.props.report.ReminderDate))
                : null;
            if (reminderDateDifference && reminderDateDifference < 24 * 60 * 60000) {
                isReminderDisabled = true;
                reminderInfoText = translate('SEND_REMINDER_MODAL.REMINDER_DISABLED');
            }
        }
        const infoMessage = `Daily Report - ${
            TimeHelper.getFormatted(this.props.report.Date, { time: true, utc: true })}`;
        const emails = this.props.report.EmailTo ? this.props.report.EmailTo.split(';').join('\n') : '';
        return (
            <Modal.Default
                className="sten-modal--with-footer"
                modalSize="xs"
                isOpen={!!this.props.report}
                onRequestClose={this.closeMissingReportReminderModal}
            >
                {this.props.report && (
                    <React.Fragment>
                        <div className="sten-modal__header flex flex-center text-uppercase">
                            <div className="sten-modal__title">
                                {reminderInfoText}
                            </div>
                        </div>
                        <div className="sten-modal__body">
                            <div className="sten-content__section">
                                {this.props.report.ReminderDate ? (
                                    <React.Fragment>
                                        <h4>{infoMessage}</h4>
                                        <h6 className="text-secondary">
                                            {translate('SEND_REMINDER_MODAL.LAST_REMINDER_SENT', {
                                                timestamp: TimeHelper.getFormatted(
                                                    this.props.report.ReminderDate, { time: true }
                                                )
                                            })}
                                        </h6>
                                        <h6 className="text-secondary">
                                            {translate('SEND_REMINDER_MODAL.REMINDER_SENT_BY', {
                                                user: this.props.report.UserName
                                            })}
                                        </h6>
                                        <div className="flex text-secondary">
                                            <h6 className="text-nowrap">{`${translate(
                                                'SEND_REMINDER_MODAL.REMINDER_SENT_TO', { emails }
                                            )}`}
                                            </h6>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {isReminderDisabled
                                            ? translate('SEND_REMINDER_MODAL.REMINDER_DISABLED_INVALID_EMAIL')
                                            : infoMessage
                                        }
                                        <p className="text-danger">{translate('SEND_REMINDER_MODAL.REPORT_MISSING')}</p>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <footer className="sten-modal__footer flex flex-center">
                            <button
                                type="button"
                                onClick={this.closeMissingReportReminderModal}
                                className="btn btn--secondary col-11"
                            >
                                {translate('GLOBAL.CANCEL')}
                            </button>
                            <button
                                disabled={isReminderDisabled}
                                title={reminderInfoText}
                                className="btn btn--primary col-11 col-offset-2"
                                onClick={this.sendReminder}
                            >
                                {translate('GLOBAL.SEND')}
                            </button>
                        </footer>
                    </React.Fragment>
                )}
            </Modal.Default>
        );
    }
}

SendReminderModal.propTypes = {
    report: PropTypes.objectOf(PropTypes.any),
    sendReminder: PropTypes.func.isRequired,
    toggleMissingReportReminderModal: PropTypes.func.isRequired
};

SendReminderModal.defaultProps = {
    report: null
};

function mapStateToProps(state) {
    return {
        report: state.sendReminderModalReducer.report
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sendReminder: reportId => sendReminder(dispatch, reportId),
        toggleMissingReportReminderModal: report => toggleMissingReportReminderModal(dispatch, report)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SendReminderModal);
