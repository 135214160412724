import React from 'react';
import PropTypes from 'prop-types';
/* components */
import TooltipContent from './tooltip-content/tooltip-content';

export class Tooltip extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isVisible: false,
            placement: this.props.placement
        };
    }

    handleMouseEnter = () => {
        let calculatedPlacement = this.props.placement;
        if (this.tooltipRef) {
            const boundingBox = this.tooltipRef.current.getBoundingClientRect();
            if (this.props.placement === 'verticalAuto' && boundingBox.top < window.innerHeight / 2) {
                calculatedPlacement = 'bottom';
            } else {
                calculatedPlacement = 'top';
            }
            if (this.props.placement === 'horizontalAuto' && boundingBox.left < window.innerWidth / 2) {
                calculatedPlacement = 'right';
            } else {
                calculatedPlacement = 'left';
            }
        }
        if (this.props.alwaysVisible || this.props.isDisabled) { return; }
        if (this.setVisibility) {
            clearTimeout(this.setVisibility);
        }
        this.setVisibility = setTimeout(() => {
            if (this && this.state) {
                this.setState({
                    isVisible: !this.state.isVisible,
                    placement: calculatedPlacement
                });
            }
        }, 300);
    };

    handleMouseLeave = () => {
        if (this.props.alwaysVisible || this.props.isDisabled) { return; }
        if (this.setVisibility) {
            clearTimeout(this.setVisibility);
        }
        this.setState({
            isVisible: false
        });
    };

    tooltipRef = React.createRef();

    render() {
        return (
            <div
                className={`sten-tooltip${this.props.className ? ` ${this.props.className}` : ''}`}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                ref={this.tooltipRef}
            >
                <TooltipContent
                    placement={this.state.placement}
                    isVisible={this.props.alwaysVisible || this.state.isVisible}
                    className={this.props.contentClassName}
                    isDisabled={this.props.isDisabled}
                    style={this.props.style}
                >
                    {this.props.content}
                </TooltipContent>
                {this.props.children}
            </div>
        );
    }
}

Tooltip.propTypes = {
    alwaysVisible: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    content: PropTypes.node.isRequired,
    contentClassName: PropTypes.string,
    isDisabled: PropTypes.bool,
    placement: PropTypes.oneOf(['horizontalAuto', 'verticalAuto', 'top', 'bottom', 'left', 'right']),
    style: PropTypes.objectOf(PropTypes.any)
};

Tooltip.defaultProps = {
    alwaysVisible: false,
    children: null,
    className: '',
    contentClassName: '',
    isDisabled: false,
    placement: 'top',
    style: null
};

export default Tooltip;
