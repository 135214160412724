import ApiHelper from './gsm-api';

class GsmAddressService {
    constructor() {
        this.resourceUrl = 'Addresses';
    }

    get(params) {
        return ApiHelper.get(`${this.resourceUrl}`, params, true)
            .then(response => response.data, () => null);
    }
}

export default new GsmAddressService();
