import React from 'react';
import PropTypes from 'prop-types';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* components */
import TrendIcon from 'components/trend-icon/trend-icon';

class TradingRouteTooltipContent extends React.PureComponent {
    openRouteDetails = () => this.props.router.navigate({
        pathname: appRoutes.MarketOverview.MarketIntel,
        query: { routeCode: this.props.tradingRoute.RouteCode }
    });

    toggleTradingRoute = () => {
        this.props.toggleUserSetting('TradingRouteIds', this.props.tradingRoute.Id);
    };

    render() {
        const { tradingRoute } = this.props;

        return (
            <React.Fragment>
                <div className="sten-trading-route-tooltip__header">
                    <div className="flex-row flex-center">
                        <h3 className="flex-grow sten-trading-route-tooltip__title">
                            {tradingRoute.RouteCode}
                        </h3>
                        <div className="flex-shrink">
                            <button
                                className="btn-link icon icon-close"
                                onClick={this.toggleTradingRoute}
                            />
                        </div>
                    </div>
                </div>
                <div className="sten-trading-route-tooltip__body">
                    {tradingRoute.RouteCode === 'TD20' && ([
                        <div className="row" key="tooltipLatestBitr">
                            <div className="col-14 sten-panel__label">
                                {t('TRADING_ROUTE_TOOLTIP.LATEST_BITR')}
                            </div>
                            <div
                                className="
                                    col-10
                                    sten-panel__label
                                    sten-panel__label--value
                                    sten-trading-route-tooltip__value"
                            >
                                {tradingRoute.BITR && tradingRoute.BITR.toFixed(2)}
                                <TrendIcon value={tradingRoute.BITRTrend} />
                            </div>
                        </div>,
                        <div className="sten-trading-route-tooltip__footer" key="tooltipFooter">
                            <button
                                className="sten-link text-uppercase"
                                onClick={this.openRouteDetails}
                            >
                                <span className="btn__icon icon icon-box-chart" />
                                {t('TRADING_ROUTE_TOOLTIP.ROUTE_OVERVIEW')}
                            </button>
                        </div>
                    ])}
                    {tradingRoute.RouteCode !== 'TD20' && (
                        <span>{t('TRADING_ROUTE_TOOLTIP.NO_ROUTE_DATA_MESSAGE')}</span>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

TradingRouteTooltipContent.propTypes = {
    router: TRouter.isRequired,
    toggleUserSetting: PropTypes.func.isRequired,
    tradingRoute: PropTypes.objectOf(PropTypes.any).isRequired
};

export default withRouter(TradingRouteTooltipContent);
