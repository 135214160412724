import { ActionTypes } from './weather-routing-actions';
import { sortByProp } from 'utils/helpers/array-helper';

const emptyArray = [];

const routingRequestInitialState = {
    LegId: null,
    Vessel: null,
    Voyage: null,
    TemplateData: null,
    VesselCondition: null,
    OptimizationType: null,
    ConditionalAreas: emptyArray,
    Cost: {
        VesselDailyCosts: null,
        BunkerCostHFO: null,
        BunkerCostHFOLS: null
    },
    ConsumptionBudget: emptyArray,
    Source: null,
    RouteCalculationType: null,
    Speed: {
        InstructedSpeed: emptyArray,
        MinimumSpeed: null,
        MaximumSpeed: null
    },
    RequestType: null,
    Eta: null,
    EtaOffset: null,
    Etd: null,
    EtdOffset: null,
    RoutingEmailsVessel: emptyArray,
    RoutingEmailsOperations: emptyArray,
    RoutingEmailsOther: emptyArray,
    PortOfDeparture: null,
    Waypoints: emptyArray,
    PortOfDestination: null,
    PvarEmails: emptyArray,
    PerformanceEmails: emptyArray,
    Remarks: null
};

const initialState = {
    editingRequest: routingRequestInitialState,
    selectedVoyageId: null,
    templateOptions: emptyArray,
    areEditingOptionsFetched: false,
    isMapShown: true,
    isAisShown: true,
    filters: {
        AvailableForRouting: false,
        Vessel: null,
        CompanyFleets: emptyArray,
        CustomFleets: emptyArray
    },
    companyFleets: emptyArray,
    customFleets: emptyArray,
    ports: emptyArray,
    voyages: emptyArray,
    voyagesParams: {
        Offset: 0,
        TotalPagesCount: 0
    },
    editingOptions: {
        conditionalAreas: emptyArray,
        vesselConditions: emptyArray,
        optimizationTypes: emptyArray,
        routeCalculationTypes: emptyArray,
        requestTypes: emptyArray,
        optimizationCalculationRelations: {}
    },
    selectedLeg: null,
    selectedLegRequests: emptyArray,
    selectedLegSposRoutes: emptyArray,
    selectedLegAisPoints: emptyArray,
    consumptionBudget: null
};

export default function weatherRoutingReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.WEATHER_ROUTING_SORT_ASC: {
        return {
            ...state,
            editingRequest: {
                ...state.editingRequest,
                ConsumptionBudget: state.editingRequest.ConsumptionBudget.sort(sortByProp('Speed'))
            }
        };
    }
    case ActionTypes.WEATHER_ROUTING_CLEAR_DATA: {
        if (state.editingRequest.Id) {
            return {
                ...state,
                editingRequest: routingRequestInitialState
            };
        }
        if (state.editingRequest.LegId && !action.overwriteLegId) {
            return {
                ...state,
                editingRequest: {
                    ...state.editingRequest,
                    TemplateData: null,
                    OptimizationType: null,
                    Cost: {
                        VesselDailyCosts: null,
                        BunkerCostHFO: null,
                        BunkerCostHFOLS: null
                    },
                    ConsumptionBudget: emptyArray,
                    Source: null,
                    RouteCalculationType: null,
                    Speed: {
                        InstructedSpeed: emptyArray,
                        MinimumSpeed: null,
                        MaximumSpeed: null
                    },
                    RequestType: null,
                    Eta: null,
                    EtaOffset: null,
                    Etd: null,
                    EtdOffset: null,
                    RoutingEmailsVessel: emptyArray,
                    RoutingEmailsOperations: emptyArray,
                    RoutingEmailsOther: emptyArray,
                    PortOfDeparture: null,
                    Waypoints: emptyArray,
                    PortOfDestination: null,
                    PvarEmails: emptyArray,
                    PerformanceEmails: emptyArray,
                    Remarks: null
                }
            };
        }
        return {
            ...state,
            editingRequest: routingRequestInitialState
        };
    }
    case ActionTypes.WEATHER_ROUTING_UPDATE_FIELD_VALUE: {
        const { editingRequest, templateOptions, selectedVoyageId, consumptionBudget } = action;
        return {
            ...state,
            editingRequest,
            templateOptions,
            selectedVoyageId,
            consumptionBudget
        };
    }
    case ActionTypes.WEATHER_ROUTING_SET_DUPLICATE_REQUEST_DATA: {
        return {
            ...state,
            editingRequest: { ...state.editingRequest, ...action.data },
            templateOptions: action.templateOptions,
            selectedVoyageId: action.selectedVoyageId,
            consumptionBudget: action.consumptionBudget
        };
    }
    case ActionTypes.WEATHER_ROUTING_SET_FLEETS: {
        return {
            ...state,
            customFleets: action.customFleets,
            companyFleets: action.companyFleets
        };
    }
    case ActionTypes.WEATHER_ROUTING_SET_VOYAGES: {
        const { PageItems, Offset, TotalPagesCount } = action;
        return {
            ...state,
            voyages: PageItems || emptyArray,
            voyagesParams: {
                ...state.voyagesParams,
                Offset,
                TotalPagesCount
            }
        };
    }
    case ActionTypes.WEATHER_ROUTING_SET_FILTERS: {
        return {
            ...state,
            filters: {
                ...state.filters,
                ...action.filters
            }
        };
    }
    case ActionTypes.WEATHER_ROUTING_SET_EDITING_OPTIONS: {
        return {
            ...state,
            editingOptions: { ...state.editingOptions, ...action.editingOptions },
            areEditingOptionsFetched: action.areEditingOptionsFetched
        };
    }
    case ActionTypes.WEATHER_ROUTING_SET_PORTS: {
        return {
            ...state,
            ports: action.ports
        };
    }
    case ActionTypes.WEATHER_ROUTING_TOGGLE_MAP_SHOWN: {
        return {
            ...state,
            isMapShown: !state.isMapShown
        };
    }
    case ActionTypes.WEATHER_ROUTING_TOGGLE_AIS_SHOWN: {
        return {
            ...state,
            isAisShown: !state.isAisShown
        };
    }
    case ActionTypes.WEATHER_ROUTING_SET_LEG_AIS_POINTS: {
        return {
            ...state,
            selectedLegAisPoints: action.selectedLegAisPoints || emptyArray
        };
    }
    case ActionTypes.WEATHER_ROUTING_SET_LEG_DATA: {
        return {
            ...state,
            selectedLeg: action.leg || null,
            selectedLegRequests: action.legRequests || emptyArray,
            selectedLegSposRoutes: action.legSposRoutes || emptyArray
        };
    }
    case ActionTypes.WEATHER_ROUTING_RESET_ALL: {
        return initialState;
    }
    default:
        return state;
    }
}
