export const ActionTypes = {
    TOGGLE_MISSING_REPORT_REMINDER_MODAL: 'TOGGLE_MISSING_REPORT_REMINDER_MODAL'
};

export function toggleMissingReportReminderModal(dispatch, report) {
    dispatch({
        type: ActionTypes.TOGGLE_MISSING_REPORT_REMINDER_MODAL,
        report
    });
}
