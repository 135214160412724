import { ActionTypes } from './port-info-calls-actions';

const initialState = {
    allFetched: false,
    portActivities: [],
    portCalls: [],
    segments: [],
    selectedPortActivities: [],
    selectedSegments: [],
    limit: 20,
    offset: 0,
    isGettingData: true
};

export default function portInfoCallsReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.PORT_INFO_CALLS_SET_OPTIONS: {
        return {
            ...state,
            portActivities: action.portActivities,
            segments: action.segments
        };
    }
    case ActionTypes.PORT_INFO_CALLS_UPDATE_FILTER: {
        if (action.filterType === 'activityType') {
            return {
                ...state,
                selectedPortActivities: action.filterData,
                limit: initialState.limit,
                offset: initialState.offset
            };
        }
        return {
            ...state,
            selectedSegments: action.filterData
        };
    }
    case ActionTypes.PORT_INFO_PORT_CALLS_SET_GETTING_DATA: {
        return {
            ...state,
            isGettingData: action.isGettingData
        };
    }
    case ActionTypes.PORT_INFO_PORT_CALLS_SET: {
        let portCalls;
        if (action.overrideCurrent) {
            portCalls = action.portCalls;
        } else {
            portCalls = state.portCalls.concat(action.portCalls);
        }
        return {
            ...state,
            portCalls,
            offset: action.offset,
            allFetched: action.allFetched,
            isGettingData: false
        };
    }
    case ActionTypes.PORT_INFO_PORT_CALLS_RESET: {
        return {
            ...initialState,
            portActivities: state.portActivities,
            segments: state.segments
        };
    }
    default:
        return state;
    }
}
