import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* utils */
import ColorHelper from 'utils/helpers/color-helper';
/* actions */
import { getWniWeatherData } from './wni-weather-actions';
/* components */
import Ol from 'components/ol/ol';
import WniWeatherTooltip from './tooltip/wni-weather-tooltip';

class WniWeather extends React.Component {
    componentDidMount() {
        this.props.getWeatherData();
    }

    getWeatherStroke = (featureProps, isHovered) => ({
        color: ColorHelper.rgba(featureProps.stroke.color, isHovered ? 1 : 0.3)
    });

    weatherLayerElementProps = {
        stroke: this.getWeatherStroke,
        fill: 'fill'
    };

    render() {
        const { weatherData, zIndex } = this.props;
        if (!weatherData) {
            return null;
        }
        return (
            <React.Fragment>
                <WniWeatherTooltip key="WniWeatherTooltip" ref={this.props.tooltipRef} />
                <Ol.Layer
                    name="wni-weather"
                    type="geoJson"
                    elements={this.props.weatherData}
                    elementProps={this.weatherLayerElementProps}
                    layerProps={{ zeroHitTolerance: true }}
                    opacity={0.8}
                    zIndex={zIndex}
                />
            </React.Fragment>
        );
    }
}

WniWeather.propTypes = {
    getWeatherData: PropTypes.func.isRequired,
    tooltipRef: PropTypes.func.isRequired,
    weatherData: PropTypes.objectOf(PropTypes.any),
    zIndex: PropTypes.number.isRequired
};

WniWeather.defaultProps = {
    weatherData: null
};

function mapStateToProps(state) {
    return {
        weatherData: state.wniWeatherReducer.weatherData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getWeatherData: () => getWniWeatherData(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WniWeather);
