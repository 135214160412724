import { takeEvery, put } from 'redux-saga/effects';
import GlobalSearchService from 'services/core-api/search-service';
import { ActionTypes } from './global-search-actions';

function* getResults(action) {
    const searchResults = yield GlobalSearchService.getGlobalSearchResults(action.searchCriteria, 30, [1, 2]);
    if (searchResults) {
        yield put({ type: ActionTypes.GLOBAL_SEARCH_SET_RESULTS, searchResults });
    }
}

export default function* globalSearchSaga() {
    yield takeEvery(ActionTypes.GLOBAL_SEARCH_GET_RESULTS, getResults);
}
