/* utils */
import { t } from 'utils/i18n/i18n-model';
/* helpers */
import { formatValue } from 'utils/helpers/info-helper';

export const charterpartyRows = [
    {
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTERPARTY_PANEL.SPEED'),
        key: 'Speed',
        type: 'number',
        decimals: 1,
        unit: t('UNITS.SPEED'),
        renderValue: (data, row) => {
            return `${formatValue(data, row)} (${formatValue(data, {
                key: 'SpeedAllowance',
                type: 'number',
                decimals: 1,
                unit: t('UNITS.SPEED')
            })})`;
        }
    },
    {
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTERPARTY_PANEL.WAVE_HEIGHT'),
        key: 'WaveHeight',
        decimals: 1,
        type: 'number',
        unit: t('UNITS.METER'),
        renderValue: (data, row) => {
            return `${formatValue(data, row)} (${formatValue(data, {
                key: 'WaveHeightDouglas',
                decimals: 0,
                type: 'number',
                unit: t('UNITS.DOUGLAS')
            })})`;
        }
    },
    {
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTERPARTY_PANEL.WIND_SPEED'),
        key: 'WindSpeed',
        decimals: 1,
        type: 'number',
        unit: t('UNITS.SPEED'),
        renderValue: (data, row) => {
            return `${formatValue(data, row)} (${formatValue(data, {
                key: 'WindSpeedBeaufort',
                decimals: 0,
                type: 'number',
                unit: t('UNITS.WIND_FORCE')
            })})`;
        }
    },
    {
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTERPARTY_PANEL.CURRENT'),
        key: 'CurrentType.DisplayName',
        type: 'string'
    }
];
