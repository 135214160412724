import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { renderFormFields, renderFormContent } from 'components/form-fields/form-fields-helper';

class ContentArray extends React.Component {
    valueChangeHandlers = {};

    itemDeleteHandlers = {};

    getValueChangeHandler = (index) => {
        // returns value change handler for specified index
        if (!this.valueChangeHandlers[index]) {
            if (this.props.hasFieldsWithHandlerCreators) {
                this.valueChangeHandlers[index] = (field) => (value) => {
                    const newValue = [...this.props.data];
                    newValue[index] = { ...newValue[index], [field]: value };
                    this.props.onChange(newValue);
                };
            } else {
                this.valueChangeHandlers[index] = (field, value) => {
                    const newValue = [...this.props.data];
                    newValue[index] = { ...newValue[index], [field]: value };
                    this.props.onChange(newValue);
                };
            }
        }

        return this.valueChangeHandlers[index];
    };

    getItemDeleteHandler = (index) => {
        if (!this.itemDeleteHandlers[index]) {
            this.itemDeleteHandlers[index] = () => {
                const newValue = [...this.props.data];
                newValue.splice(index, 1);
                this.props.onChange(newValue);
            };
        }
        return this.itemDeleteHandlers[index];
    };

    handleItemAdd = () => {
        const newValue = [...this.props.data, this.props.newItem];
        this.props.onChange(newValue);
    };

    renderRow = (item, index) => {
        const { fields, editingOptions, fieldMap, validationRules } = this.props;

        return renderFormFields(
            item,
            fieldMap,
            fields,
            this.props.isEditing,
            this.getValueChangeHandler(index),
            true,
            { ...editingOptions, validationRules },
            index
        );
    };

    getItemIndexLabel = index => `${this.props.itemTitle} ${index + 1}`;

    render() {
        const { data, fields, sections, fieldMap, dataMap, editingOptions, validationRules, isEditing } = this.props;

        const isRemoveDisabled = typeof this.props.minLength === 'number'
            && this.props.minLength === data.length;
        const isAddAllowed = isEditing
            && this.props.maxLength
            && data.length + 1 <= this.props.maxLength;
        return (
            <React.Fragment>
                {data && data.length > 0 && (
                    <div className="sten-content__section">
                        <div className="sten-content__panel-wrapper">
                            {data.map((item, index) => (
                                // eslint-disable-next-line react/no-array-index-key
                                <div className="sten-panel" key={index}>
                                    <div className="sten-panel__header">
                                        <h4 className="flex-grow">
                                            {this.getItemIndexLabel(index)}
                                        </h4>
                                        {isEditing && (
                                            <div className="flex-shrink">
                                                <button
                                                    type="button"
                                                    className="btn-link btn--icon icon icon-delete"
                                                    onClick={this.getItemDeleteHandler(index)}
                                                    disabled={isRemoveDisabled}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    {fields && (
                                        <div className="sten-panel__section">
                                            {this.renderRow(item, index)}
                                        </div>
                                    )}
                                    {sections && renderFormContent({
                                        sections,
                                        fieldMap,
                                        dataMap,
                                        params: {
                                            data: item,
                                            isEditing,
                                            onChange: this.getValueChangeHandler(index),
                                            editingOptions,
                                            nameSuffix: index,
                                            validationRules
                                        },
                                        isPanel: true
                                    })}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {isAddAllowed && (
                    <div className="sten-content__section">
                        <button type="button" className="btn-link btn-link--inverted" onClick={this.handleItemAdd}>
                            <span className="icon icon-plus btn__icon" />
                            <span className="text-uppercase text-semibold">
                                {`${t('GLOBAL.ADD')} ${this.props.itemTitle ? this.props.itemTitle : ''}`}
                            </span>
                        </button>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

ContentArray.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    dataMap: PropTypes.objectOf(PropTypes.any),
    editingOptions: PropTypes.objectOf(PropTypes.any),
    fieldMap: PropTypes.objectOf(PropTypes.any),
    fields: PropTypes.arrayOf(PropTypes.string),
    hasFieldsWithHandlerCreators: PropTypes.bool,
    isEditing: PropTypes.bool,
    itemTitle: PropTypes.string,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    newItem: PropTypes.objectOf(PropTypes.any).isRequired,
    onChange: PropTypes.func,
    sections: PropTypes.arrayOf(PropTypes.object),
    validationRules: PropTypes.objectOf(PropTypes.any)
};

ContentArray.defaultProps = {
    dataMap: null,
    editingOptions: null,
    fieldMap: null,
    fields: null,
    hasFieldsWithHandlerCreators: false,
    isEditing: false,
    itemTitle: '',
    maxLength: 50,
    minLength: 0,
    onChange: undefined,
    sections: null,
    validationRules: null
};

export default ContentArray;
