/* utils */
import { t } from 'utils/i18n/i18n-model';

export const sofReportFieldsAndSections = [
    {
        fields: [
            'Vessel',
            'CallSign',
            'VoyageNumber',
            'ReportDate'
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.PORT_INFORMATION'),
        sections: [
            {
                fields: [
                    'Point',
                    'Charterer',
                    'EndOfSeaPassage',
                    'AnchoredCommencedDrifting',
                    'AnchorAweighStoppedDrifting',
                    'NoticeOfReadiness',
                    'FreePractiqueGranted',
                    'PilotOnboard',
                    'AllFast',
                    'HosesConnected'
                ]
            }
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.REMARKS'),
        sections: [
            {
                inline: false,
                fields: ['Remarks']
            }
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.CARGO_OPERATIONS'),
        condition: (p) => (p.isEditing || (p.data.CargoOperations && p.data.CargoOperations.length)),
        data: 'CargoOperations'
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.OTHER_INFO'),
        sections: [
            {
                fields: [
                    'HosesDisconnected',
                    'CargoDocumentsOnboard',
                    'Unmoored'
                ]
            }
        ]
    }
];
