/* utils */
import { t } from 'utils/i18n/i18n-model';

export const nextLegReportFieldsAndSections = [
    {
        fields: [
            'Vessel',
            'CallSign',
            'VoyageNumber',
            'ReportDate'
        ]
    }, {
        className: 'sten-content__separator',
        fields: [
            'MastersName',
            'Charterer',
            'CharterPartyDate',
            'LayDaysFromDate',
            'LayDaysToDate',
            'CharterPartySpeed'
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.VOYAGE'),
        sections: [
            {
                fields: [
                    'CargoStatus',
                    'DeparturePort',
                    'ExpectedDepartureDraft',
                    'EstimatedTimeOfDepartureFromBerthDate',
                    'MastersIntendedRpmSettingThisVoyage',
                    'RPMRange',
                    'Gm',
                    'RollPeriod',
                    'CargoGrade',
                    'CargoQuantity',
                    'InstructedSpeed',
                    'DestinationPort',
                    'MastersEtaAtPilotStationDate'
                ]
            }
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.MASTERS_INTENDED_ROUTE'),
        condition: (p) => (p.isEditing || (p.data.Waypoints && p.data.Waypoints.length)),
        data: 'Waypoints'
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.REMARKS'),
        sections: [
            {
                inline: false,
                fields: ['Remarks']
            }
        ]
    }
];
