import { ActionTypes } from './trading-route-details-actions';

const initialState = {
    routeName: null,
    data: null,
    modelOptions: [],
    rangeOptions: [],
    latestPrediction: null
};

export default function tradingRouteDetailsReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.TRADING_ROUTE_DETAILS_SET_DATA: {
        return {
            ...state,
            data: action.data,
            routeName: action.routeName
        };
    }
    case ActionTypes.TRADING_ROUTE_DETAILS_SET_LATEST_PREDICTION: {
        return {
            ...state,
            latestPrediction: action.latestPrediction
        };
    }
    case ActionTypes.TRADING_ROUTE_DETAILS_SET_MODELS: {
        return {
            ...state,
            modelOptions: action.models
        };
    }
    case ActionTypes.TRADING_ROUTE_DETAILS_SET_RANGES: {
        return {
            ...state,
            rangeOptions: action.ranges
        };
    }
    case ActionTypes.TRADING_ROUTE_DETAILS_RESET_ALL: {
        return initialState;
    }
    default:
        return state;
    }
}
