import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* helpers */
import { renderPanels } from 'components/right-side-bar/right-side-bar-helper';
import { isObjPropDifferent } from 'utils/helpers/info-helper';
/* actions */
import { toggleActivePanel, emptyActivePanels } from 'components/right-side-bar/right-side-bar-actions';
import { updateUserSettings } from 'pages/user-pages/user-actions';
/* components */
import BerthForm from 'components/terminal-and-berth-info/berth/berth-form';
import BerthInfo from 'components/terminal-and-berth-info/berth/berth-info';
import PortForm from 'components/port-form/port-form';
import PortInfo from 'components/port-info/port-info';
import Settings from '../settings/port-management-settings';
import TerminalForm from 'components/terminal-and-berth-info/terminal/terminal-form';
import TerminalInfo from 'components/terminal-and-berth-info/terminal/terminal-info';

export class PortManagementRightSideBar extends React.PureComponent {
    componentDidMount() {
        this.updateActivePanel();
    }

    componentDidUpdate(prevProps) {
        this.updateActivePanel(prevProps);
    }

    updateActivePanel(prevProps) {
        const { location, query, params } = this.props.router;
        if (isObjPropDifferent(prevProps, this.props, 'router.location.pathname')) {
            this.props.toggleActivePanel(
                'port-form',
                location.pathname.endsWith(`edit/${params.id}`)
                || location.pathname.endsWith('new')
            );
            this.props.toggleActivePanel(
                'port-info',
                !location.pathname.includes('/edit')
                && location.pathname.endsWith(params.id)
            );
        }
        if (isObjPropDifferent(prevProps, this.props, 'router.query.terminal')) {
            this.props.toggleActivePanel('terminal-info', !!query.terminal);
        }
        if (isObjPropDifferent(prevProps, this.props, 'router.query.terminalUpdate')
            || isObjPropDifferent(prevProps, this.props, 'router.query.terminalAddPort')) {
            this.props.toggleActivePanel('terminal-form', !!this.showTerminalForm());
        }
        if (isObjPropDifferent(prevProps, this.props, 'router.query.berth')) {
            this.props.toggleActivePanel('berth-info', !!query.berth);
        }
        if (isObjPropDifferent(prevProps, this.props, 'router.query.berthUpdate')
            || isObjPropDifferent(prevProps, this.props, 'router.query.berthAddTerminal')) {
            this.props.toggleActivePanel('berth-form', !!this.showBerthForm());
        }
    }

    componentWillUnmount() {
        this.props.emptyActivePanels();
    }

    handleSettingsUpdate = (visibleColumns) => {
        this.props.toggleActivePanel('settings');
        this.props.updateUserSettings({ PortManagementVisibleColumns: visibleColumns });
    };

    handleButtonClick = (panelName) => () => {
        this.props.router.updateQueryParams({
            remove: ['terminal', 'berth', 'terminalUpdate', 'berthUpdate']
        });
        this.props.toggleActivePanel(panelName);
    };

    handleCloseClick = () => this.props.router.navigate({ pathname: appRoutes.PortManagement, query: null });

    bottomPanelContainer = {
        panelMap: {
            'port-form': () => (
                this.props.countries.length
                && this.props.services.length
                && this.props.bunkeringTypes.length
                && (this.props.permissions.PortManagementAddPort
                    || this.props.permissions.PortManagementEditPort)
                    ? (
                        <PortForm
                            key="port-form"
                            isActive={
                                this.props.router.location.pathname.endsWith(`edit/${this.props.router.params.id}`)
                                || this.props.router.location.pathname.endsWith('new')
                            }
                            id={this.props.router.params.id}
                            onSubmit={this.props.onPortUpdate}
                            countries={this.props.countries}
                            services={this.props.services}
                            bunkeringTypes={this.props.bunkeringTypes}
                        />
                    ) : null
            ),
            settings: () => (this.props.columns ? (
                <Settings
                    key="settings"
                    onSubmit={this.handleSettingsUpdate}
                    onClose={this.handleButtonClick('settings')}
                    settings={this.props.userSettings.PortManagementVisibleColumns}
                    columns={this.props.columns}
                />
            ) : null),
            'port-info': () => (
                <PortInfo
                    key="port-info"
                    activeTabId={Number(this.props.router.query.tabId) || null}
                    portId={Number(this.props.router.params.id) || null}
                    terminalId={Number(this.props.router.query.terminal) || null}
                    berthId={Number(this.props.router.query.berth) || null}
                    onCloseClick={this.handleCloseClick}
                />
            )
        }
    };

    showTerminalForm = () => {
        const { permissions, router } = this.props;
        return !!(permissions.AddUpdateTerminal && (router.query.terminalUpdate || router.query.terminalAddPort));
    }

    middlePanelContainer = {
        panelMap: {
            'terminal-info': () => (this.props.router.query.terminal ? (
                <TerminalInfo
                    key="terminal-info"
                    id={Number(this.props.router.query.terminal) || null}
                    activeTabId={Number(this.props.router.query.tabId) || null}
                />
            ) : null),
            'terminal-form': () => (this.showTerminalForm() ? (
                <TerminalForm
                    key="terminal-form"
                    id={Number(this.props.router.query.terminalUpdate) || null}
                    portId={Number(this.props.router.query.terminalAddPort || null)}
                    isActive={this.showTerminalForm()}
                />
            ) : null)
        },
        style: {
            zIndex: 4
        }
    };

    showBerthForm = () => {
        const { permissions, router } = this.props;
        return !!(permissions.UpdateBerth && (router.query.berthUpdate || router.query.berthAddTerminal));
    }

    topPanelContainer = {
        panelMap: {
            'berth-info': () => (this.props.router.query.berth ? (
                <BerthInfo
                    key="berth-info"
                    id={Number(this.props.router.query.berth)}
                    activeTabId={Number(this.props.router.query.tabId) || null}
                />
            ) : null),
            'berth-form': () => (this.showBerthForm() ? (
                <BerthForm
                    key="berth-form"
                    id={Number(this.props.router.query.berthUpdate) || null}
                    terminalId={Number(this.props.router.query.berthAddTerminal) || null}
                    isActive={this.showBerthForm()}
                />
            ) : null)
        },
        style: {
            zIndex: 5
        }
    };

    render() {
        return renderPanels(
            this.props.activePanels,
            this.bottomPanelContainer,
            this.middlePanelContainer,
            this.topPanelContainer
        );
    }
}

PortManagementRightSideBar.propTypes = {
    activePanels: PropTypes.arrayOf(PropTypes.string).isRequired,
    bunkeringTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    countries: PropTypes.arrayOf(PropTypes.object).isRequired,
    emptyActivePanels: PropTypes.func.isRequired,
    onPortUpdate: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    router: TRouter.isRequired,
    services: PropTypes.arrayOf(PropTypes.object).isRequired,
    toggleActivePanel: PropTypes.func.isRequired,
    updateUserSettings: PropTypes.func.isRequired,
    userSettings: PropTypes.objectOf(PropTypes.any).isRequired
};

function mapStateToProps(state) {
    return {
        activePanels: state.rightSideBarReducer.activePanels,
        bunkeringTypes: state.portManagementReducer.bunkeringTypes,
        columns: state.portManagementReducer.columns,
        countries: state.portManagementReducer.countries,
        permissions: state.userReducer.permissions,
        services: state.portManagementReducer.services,
        userSettings: state.userReducer.settings
    };
}

function mapDispatchToProps(dispatch) {
    return {
        emptyActivePanels: () => emptyActivePanels(dispatch),
        toggleActivePanel: (panelName, enable) => toggleActivePanel(dispatch, panelName, enable),
        updateUserSettings: (settings, settingUpdated) => updateUserSettings(dispatch, settings, settingUpdated)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PortManagementRightSideBar));
