import { takeEvery, put, all, select } from 'redux-saga/effects';
import fastDeepEqual from 'fast-deep-equal';
/* utils */
import { getConvertedObject } from 'utils/helpers/info-helper';
import { sofReportsMockedData } from './raw-reports-table-mock-sof-reports';
import {
    getMockedEventReportData,
    getMockedTypesAndGrades
} from './raw-reports-table-mock-event-reports';
/* actions */
import { ActionTypes } from './raw-reports-table-actions';
import { ActionTypes as VRActionTypes } from 'components/vessel-report/vessel-report-actions';
/* services */
import ConfigService from 'services/config-api/config-service';
import EnergyManagementService from 'services/core-api/energy-management-service';
import LocalStorageService from 'services/local-storage-service';
/* selectors */
import { getAvailableRawReportTypes } from './raw-reports-table-selectors';
/* constants */
import { rawReportTypesByType } from './raw-reports-table-constants';
/* prop types */
import inclusionPropTypes from 'pages/user-pages/energy-management/reports/sidebar/inclusions/inclusion-prop-types';

const dataTypesMap = {
    Consumption: 'Consumptions',
    ConsumptionFromNoonToEvent: 'ConsumptionFromNoonToEvent',
    ConsumptionDuringStoppage: 'ConsumptionDuringStoppage',
    ConsumptionSinceLastEvent: 'ConsumptionsSinceLastEvent',
    DischargeOperation: 'DischargeOperations'
};

function* formatReportDataTypes(params) {
    let dataTypes = yield EnergyManagementService.Reports.VoyageReport.getReportDataTypes(params);
    if (!dataTypes) {
        dataTypes = {
            AvailableReportVariations: [],
            AvailableFuelTypes: [],
            BunkerDataTypes: [],
            ConsumptionGroupTypes: [],
            ConsumptionTypes: []
        };
    }
    const res = {
        ...dataTypes,
        Types: {
            BunkerData: dataTypes.BunkerDataTypes
        }
    };
    dataTypes.ConsumptionGroupTypes.forEach(groupType => {
        if (dataTypesMap[groupType.Name]) {
            res.Types[dataTypesMap[groupType.Name]] = groupType.ConsumptionTypes;
        }
    });
    return res;
}

const cachedReportsDataAndParams = {
    params: null,
    reports: null,
    typesAndGrades: null
};

export function* getAllReportsAndRows({ VoyageId, Imo, Inclusions, DateFrom, DateTo }) {
    yield put({ type: VRActionTypes.VESSEL_REPORT_GET_CONFIGS });
    const availableReportTypes = yield select(getAvailableRawReportTypes);

    const voyageRangeParams = VoyageId ? { VoyageId } : { DateFrom, DateTo };

    const params = {
        Inclusions: Inclusions ? {
            ...getConvertedObject(Inclusions, inclusionPropTypes),
            VesselImo: Imo
        } : undefined,
        ...voyageRangeParams,
        Offset: 0,
        Limit: 5000,
        SortOrder: ConfigService.featureToggles.rawReportsSortOrder,
        AreMissingReportsVisible: false,
        AreIncludedReportsVisible: true,
        AreExcludedReportsVisible: true,
        AreOnlySuspiciousReportsVisible: false,
        AreOnlyVeracitySyncErrorReportsVisible: false
    };
    if (!cachedReportsDataAndParams.params || !fastDeepEqual(params, cachedReportsDataAndParams.params)) {
        const availableTypesAndGrades = {};
        const availableReports = {};
        availableReportTypes.forEach(reportType => {
            availableTypesAndGrades[reportType.type] = formatReportDataTypes({
                ...voyageRangeParams,
                reportType: reportType.id,
                imo: Imo
            });
            availableReports[reportType.type] = reportType.get(params);
        });
        const res = yield all({
            typesAndGrades: all(availableTypesAndGrades),
            reports: all(availableReports)
        });
        cachedReportsDataAndParams.typesAndGrades = res.typesAndGrades;
        cachedReportsDataAndParams.reports = res.reports;
        cachedReportsDataAndParams.params = params;
    }
    return cachedReportsDataAndParams;
}

function* getReportDataTypes(action) {
    if (rawReportTypesByType[action.reportType]) {
        let reportDataTypes;
        if (LocalStorageService.isDebugModeActive() && action.reportType === 'event') {
            reportDataTypes = yield getMockedTypesAndGrades(action);
        } else {
            reportDataTypes = yield formatReportDataTypes({
                ...action.voyage
                    ? { VoyageId: action.voyage.VoyageId }
                    : { DateFrom: action.range.rangeStart, DateTo: action.range.rangeEnd },
                reportType: rawReportTypesByType[action.reportType].id,
                imo: action.vesselImo
            });
        }
        if (reportDataTypes) {
            yield put({
                type: ActionTypes.RAW_REPORTS_TABLE_SET_REPORT_DATA_TYPES,
                reportType: action.reportType,
                reportDataTypes
            });
        }
    }
}

let isFetchingReports = false;

function* getReports(action) {
    const reportType = rawReportTypesByType[action.reportType];
    yield put({ type: VRActionTypes.VESSEL_REPORT_GET_CONFIGS });
    if (reportType && !isFetchingReports) {
        isFetchingReports = true;
        let reports;
        if (reportType.id === 5 && LocalStorageService.isDebugModeActive()) {
            reports = yield getMockedEventReportData(action).Reports;
        } else if (reportType.id === 7 && LocalStorageService.isDebugModeActive()) {
            reports = yield sofReportsMockedData();
        } else {
            reports = yield reportType.get({
                Inclusions: action.inclusions
                    ? { VesselImo: action.vesselImo, ...action.inclusions }
                    : null,
                ...action.voyage
                    ? { VoyageId: action.voyage.VoyageId }
                    : { DateFrom: action.range.rangeStart, DateTo: action.range.rangeEnd },
                Offset: 0,
                Limit: 5000,
                SortOrder: ConfigService.featureToggles.rawReportsSortOrder,
                AreMissingReportsVisible: true,
                AreIncludedReportsVisible: true,
                AreExcludedReportsVisible: true,
                AreOnlySuspiciousReportsVisible: false,
                AreOnlyVeracitySyncErrorReportsVisible: false
            });
            yield getReportDataTypes(action);
            yield put({ type: ActionTypes.RAW_REPORTS_TABLE_RESET_UPDATED_REPORTS });
            isFetchingReports = false;
        }
        yield put({ type: ActionTypes.RAW_REPORTS_TABLE_SET_REPORTS, reports });
    }
}

export default function* rawReportsTableSaga() {
    yield takeEvery(ActionTypes.RAW_REPORTS_TABLE_GET_REPORTS, getReports);
}
