import { takeEvery, select, put, all, delay } from 'redux-saga/effects';
import { toast } from 'react-toastify';
/* actions */
import { ActionTypes } from './port-calls-analytics-actions';
/* services */
import PortService from 'services/core-api/port-service';
import VesselService from 'services/core-api/vessel-service';
import vesselFleetsService from 'services/core-api/fleet-management-service';
/* utils */
import { swedishCompare } from 'utils/helpers/info-helper';
import { noCountryId } from './constants';
import { translate } from 'utils/i18n/i18n-model';

import generatePortCallsAnalyticsXLSX from './export/port-calls-analytics-xlsx';

function* getVesselsSidebarInitialData() {
    const permissions = yield select(state => state.userReducer.permissions);
    const userId = yield select(state => state.userReducer.user.UserId);
    const [privateFleets, publicFleetsResponse, segments, vessels] = yield all([
        permissions.ShowFleetManagementPrivateGroups ? vesselFleetsService.getPrivate() : [],
        permissions.ShowFleetManagementPublicGroups ? vesselFleetsService.getPublic() : [],
        permissions.GetVesselSegment ? VesselService.getSegments() : [],
        permissions.GetVesselFleetManagementList ? VesselService.getAllWithoutPagination() : []
    ]);
    const publicFleets = publicFleetsResponse.reduce((res, fleet) => {
        if (fleet.UserId !== userId) {
            res.push(fleet);
        }
        return res;
    }, []);
    const fleets = privateFleets.concat(publicFleets);
    vessels.sort((a, b) => swedishCompare(a.Title, b.Title));

    yield put({
        type: ActionTypes.PORT_CALLS_ANALYTICS_VESSELS_SIDEBAR_SET_INITIAL_DATA,
        vessels,
        segments,
        fleets
    });
}

function* getPortsAndCountries() {
    const ports = yield PortService.getAll();
    const addedCountriesById = {};
    const countries = [];
    const portsCountByCountryId = {};
    let countryId;
    ports.forEach((port) => {
        countryId = port.Country ? port.Country.CountryId : noCountryId;
        if (typeof portsCountByCountryId[countryId] === 'undefined') {
            portsCountByCountryId[countryId] = 0;
        }
        portsCountByCountryId[countryId]++;
        if (countryId !== noCountryId && addedCountriesById[countryId] !== true) {
            addedCountriesById[countryId] = true;
            countries.push(port.Country);
        }
    });

    countries.sort((a, b) => swedishCompare(a.CountryName, b.CountryName));
    ports.sort((a, b) => swedishCompare(a.Name, b.Name));

    yield put({
        type: ActionTypes.PORT_CALLS_ANALYTICS_SET_PORTS_AND_COUNTRIES,
        countries,
        ports,
        portsCountByCountryId
    });
}

function* calculatePortCallsAnalytics() {
    const { selectedRange, addedPorts, addedVessels } = yield select(state => state.portCallsAnalyticsReducer);
    const portsIds = [];
    const vesselsImos = [];
    if (addedPorts && addedPorts.length > 0) {
        addedPorts.forEach(port => {
            portsIds.push(port.Id);
        });
    }
    if (addedVessels && addedVessels.length > 0) {
        addedVessels.forEach(vessel => {
            vesselsImos.push(vessel.IMO);
        });
    } else {
        toast(translate('PORT_CALLS_ANALYTICS.NO_VESSELS_ERROR'), { type: toast.TYPE.WARNING });
        return;
    }
    const params = {
        StartDate: selectedRange.from,
        EndDate: selectedRange.to,
        PortIds: portsIds,
        Imos: vesselsImos
    };
    const portCallsAnalytics = yield PortService.getPortCallsAnalyticsData(params);
    if (portCallsAnalytics) {
        yield put({
            type: ActionTypes.PORT_CALLS_ANALYTICS_SET_PORT_CALLS_ANALYTICS,
            portCallsAnalytics
        });
    } else if (portCallsAnalytics === false) {
        toast(translate('PORT_CALLS_ANALYTICS.NETWORK_ERROR'), { type: toast.TYPE.WARNING });
    }
}

function* generateXLSX() {
    const { portCallsAnalytics, selectedRange } = yield select(state => state.portCallsAnalyticsReducer);
    yield put({ type: 'TOGGLE_LOADER', payload: true });
    yield delay(0);
    yield generatePortCallsAnalyticsXLSX(portCallsAnalytics, selectedRange);
    yield put({ type: 'TOGGLE_LOADER', payload: false });
}

export default function* marketIntelSaga() {
    yield takeEvery(ActionTypes.PORT_CALLS_ANALYTICS_GET_PORTS_AND_COUNTRIES, getPortsAndCountries);
    yield takeEvery(ActionTypes.PORT_CALLS_ANALYTICS_CALCULATE_PORT_CALLS_ANALYTICS, calculatePortCallsAnalytics);
    yield takeEvery(ActionTypes.PORT_CALLS_ANALYTICS_GENERATE_XLSX, generateXLSX);
    yield takeEvery(ActionTypes.PORT_CALLS_ANALYTICS_VESSELS_SIDEBAR_GET_INITIAL_DATA, getVesselsSidebarInitialData);
}
