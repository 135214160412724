export const ActionTypes = {
    SET_REPORTS_POSITIONS: 'SET_REPORTS_POSITIONS'
};

export function setReportsPositions(dispatch, reportPositions, zoomLevel) {
    dispatch({
        type: ActionTypes.SET_REPORTS_POSITIONS,
        reportPositions,
        zoomLevel
    });
}
