import { t } from 'utils/i18n/i18n-model';

export const sectionsAndFields = [
    {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('ADMIN.VESSEL_MANAGEMENT.FORM.GENERAL_INFORMATION'),
        sections: [{ fields: ['Title', 'Imo', 'IMOClass', 'ShouldSyncReportsToVeracity'] }]
    },
    {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('ADMIN.VESSEL_MANAGEMENT.FORM.TECHNICAL_INFORMATION'),
        sections: [{
            fields: [
                'VesselType',
                'VesselSegment',
                'DWT',
                'TotalCubicCapacity',
                'Class',
                'GT',
                'BlockCoefficientCb',
                'CubicCapacityCorrectionFactorFc',
                'VoluntaryStructuralEnhancement',
                'IceClassLevelBureauVeritas',
                'Draft',
                'YearBuilt',
                'HasScrubber',
                'ScrubberInstallationDate',
                'NoxMainEngine',
                'NoxAuxEngine',
                'SfcMainEngine',
                'SfcAuxEngine',
                'Loa',
                'MMSI',
                'Beam',
                'BallastOptimumTrim',
                'LadenOptimumTrim'
            ] }]
    },
    {
        type: 'accordion',
        title: t('ADMIN.VESSEL_MANAGEMENT.FORM.BUDGET_CONSUMPTION'),
        condition: (params) => params.data.BudgetConsumptionDataSourceId,
        sections: [{ data: 'BudgetConsumption' }]
    },
    {
        type: 'accordion',
        title: t('ADMIN.VESSEL_MANAGEMENT.FORM.COMMERCIAL_INFORMATION'),
        sections: [
            { fields: [
                'ShowOnStenaBulk',
                'SortOrder',
                'CanalsAllowed',
                'CommercialOperator',
                'VesselOwner',
                'TechnicalManager',
                'TechnicalManagementEmail',
                'VesselEmail',
                'OperationsEmail',
                'OwnerEmail',
                'TradingArea'
            ] }
        ]
    },
    {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('ADMIN.VESSEL_MANAGEMENT.FORM.OTHER'),
        sections: [{ fields: ['Comment'] }]
    }
];
