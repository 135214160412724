import { createSelector } from 'reselect';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
import { convertFixture, getTimeStatusValue, isFixtureOnSubs } from './operators-diary-helpers';

let nextDateSet = false;

const getTimeState = (data, prop, statusProp, currentUtcTime) => {
    const value = getTimeStatusValue(data, prop, statusProp);
    let isCurrent = false;
    if (!nextDateSet && data[prop] && data[prop] >= currentUtcTime) {
        isCurrent = true;
        nextDateSet = true;
    }

    return {
        value,
        isCurrent,
        isHistoric: data[prop]
            ? data[prop] < currentUtcTime
            : data.Eta && data.Eta < currentUtcTime
    };
};

export const convertToTagCategories = (tags, tagCategories) => {
    const categories = {};
    tags.forEach(tag => {
        categories[tag.CategoryId] = tag;
    });
    return tagCategories.map(category => ({ ...category, Tag: categories[category.Id] }));
};

const getLegTagCategories = createSelector(
    state => state.operatorsDiaryReducer.tags,
    (tags) => {
        if (tags.leg.length === 0) {
            return tags.leg;
        }
        const categories = [];
        const categoriesMap = {};
        tags.leg.forEach(tag => {
            if (!categoriesMap[tag.CategoryId]) {
                categoriesMap[tag.CategoryId] = { Id: tag.CategoryId, Name: tag.CategoryName };
                categories.push(categoriesMap[tag.CategoryId]);
            }
        });
        return categories;
    }
);

export const getConvertedDiary = createSelector(
    state => state.operatorsDiaryReducer.diary,
    getLegTagCategories,
    (diary, legTagCategories) => {
        const currentUtcDate = TimeHelper.getUTC();
        return diary.map(vessel => {
            nextDateSet = false;
            return {
                ...vessel,
                Voyages: vessel.Voyages.map(voyage => {
                    const Itinerary = [];
                    if (voyage.Itinerary.length > 0) {
                        let currentPoint = null;
                        voyage.Itinerary.forEach(activity => {
                            if (currentPoint && (
                                (activity.Point && currentPoint.Point && activity.Point.Id !== currentPoint.Point.Id)
                                || activity.Point === currentPoint.Point)) {
                                Itinerary.push(currentPoint);
                                currentPoint = null;
                            }
                            if (!currentPoint) {
                                currentPoint = {
                                    Point: activity.Point,
                                    Activities: []
                                };
                            }
                            currentPoint.Activities.push({
                                ...activity,
                                EtaState: getTimeState(activity, 'Eta', 'EtaStatus', currentUtcDate),
                                EtbState: getTimeState(activity, 'Etb', 'EtbStatus', currentUtcDate),
                                EtdState: getTimeState(activity, 'Etd', 'EtdStatus', currentUtcDate),
                                TagCategories: convertToTagCategories(activity.Tags, legTagCategories)
                            });
                        });
                        Itinerary.push(currentPoint);
                    }
                    return {
                        ...voyage,
                        IsOnSubs: voyage.Fixtures.length > 0 && voyage.Fixtures.every(isFixtureOnSubs),
                        Itinerary,
                        Fixtures: voyage.Fixtures.map(convertFixture)
                    };
                })
            };
        });
    }
);
