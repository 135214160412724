export const ActionTypes = {
    RIGHT_SIDE_BAR_TOGGLE_ACTIVE_PANEL: 'RIGHT_SIDE_BAR_TOGGLE_ACTIVE_PANEL',
    RIGHT_SIDE_BAR_EMPTY_ACTIVE_PANELS: 'RIGHT_SIDE_BAR_EMPTY_ACTIVE_PANELS'
};

export function toggleActivePanel(dispatch, panelName, enable, empty) {
    dispatch({
        type: ActionTypes.RIGHT_SIDE_BAR_TOGGLE_ACTIVE_PANEL,
        panelName,
        enable,
        empty
    });
}

export function emptyActivePanels(dispatch) {
    dispatch({
        type: ActionTypes.RIGHT_SIDE_BAR_EMPTY_ACTIVE_PANELS
    });
}
