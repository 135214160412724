import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import TimeHelper, { quarters } from 'utils/helpers/time-helper';
/* components */
import YearPopup from 'components/year-popup/year-popup';
import Select from 'components/select/select';
/* styles */
import './quarter-picker.scss';

class QuarterPicker extends React.PureComponent {
    state = {
        year: null,
        quarter: null,
        quarterOptions: quarters,
        prevValue: null
    };

    static getDerivedStateFromProps(prop, state) {
        if (prop.value !== state.prevValue) {
            let newState = { prevValue: prop.value };
            if (prop.value) {
                const parsedQuarter = TimeHelper.getQuarterFromRange(prop.value.rangeStart, prop.value.rangeEnd);
                if (parsedQuarter) {
                    newState = { ...newState, quarter: parsedQuarter.quarter, year: { year: parsedQuarter.year } };
                }
            }
            return newState;
        }
        return null;
    }

    handleYearSelect = val => {
        this.setState({ year: val });
        if (this.state.quarter && val) {
            this.props.onChange(TimeHelper.getRangeFromQuarterAndYear(this.state.quarter.Name, val.year));
        }
    }

    handleQuarterSelect = (quarter) => {
        this.setState({ quarter });
        if (this.state.year && quarter) {
            this.props.onChange(TimeHelper.getRangeFromQuarterAndYear(quarter.Name, this.state.year.year));
        }
    };

    render() {
        return (
            <div className="sten-quarter-picker flex">
                <div className="flex-grow">
                    <YearPopup
                        clearable={this.props.clearable}
                        placeholder={translate('QUARTER_PICKER.YEAR')}
                        invalid={this.props.invalid}
                        disabled={this.props.disabled}
                        value={this.state.year}
                        onChange={this.handleYearSelect}
                    />
                </div>
                <div className="flex-shrink">
                    <Select
                        clearable={this.props.clearable}
                        className="sten-quarter-picker__quarter"
                        placeholder={translate('QUARTER_PICKER.QUARTER')}
                        invalid={this.props.invalid}
                        disabled={this.props.disabled}
                        valueKey="Id"
                        labelKey="Name"
                        options={this.state.quarterOptions}
                        value={this.state.quarter}
                        onChange={this.handleQuarterSelect}
                    />
                </div>
            </div>
        );
    }
}

const QuarterPickerValueShape = {
    rangeStart: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    rangeEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
};

QuarterPicker.propTypes = {
    clearable: PropTypes.bool,
    disabled: PropTypes.bool,
    invalid: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.shape(QuarterPickerValueShape)
};

QuarterPicker.defaultProps = {
    clearable: false,
    disabled: false,
    invalid: false,
    name: '',
    value: null
};

export default QuarterPicker;
