import { createSelector } from 'reselect';
/* helpers */
import { extractChartData } from '../kpi-helpers';

export const getTotalData = createSelector(
    state => state.energyManagementKPIOverviewReducer.overviewBasicData,
    (data) => ({
        consumptionChartData: data && data.MonthlyDataList && data.MonthlyDataList.length > 0
            ? extractChartData(data.MonthlyDataList, ['BallastKPI', 'LadenKPI', 'Cargo'])
            : null,
        energyEfficiencyChartData: data && data.EnergyEfficiencyDataList && data.EnergyEfficiencyDataList.length > 0
            ? extractChartData(
                data.EnergyEfficiencyDataList,
                ['Efficiency', 'Suezmax', 'P-MAX', 'Intermediate', 'Medium Range', 'AER'],
                {
                    xAxisYear: true
                }
            )
            : null,
        data: data && data.TotalData
    })
);

export const getSegmentsData = createSelector(
    state => state.energyManagementKPIOverviewReducer.overviewBasicData,
    (overviewBasicData) => {
        const segments = [];
        if (overviewBasicData && overviewBasicData.SegmentGraphList && overviewBasicData.SegmentGraphList.length) {
            overviewBasicData.SegmentGraphList.forEach(segment => {
                segments.push({
                    title: segment.Title,
                    id: segment.Id,
                    infoData: {
                        BallastKPIRatio: segment.BallastKPIRatio,
                        LadenKPIRatio: segment.LadenKPIRatio,
                        WeightedCargoRatio: segment.WeightedCargoRatio
                    },
                    consumptionChartData: extractChartData(segment.MonthlyDataList, ['BallastKPI', 'LadenKPI', 'Cargo'])
                });
            });
        }
        return segments;
    }
);
