export const ActionTypes = {
    ESTIMATOR_GET_VESSEL: 'ESTIMATOR_GET_VESSEL',
    ESTIMATOR_SET_VESSEL: 'ESTIMATOR_SET_VESSEL',
    ESTIMATOR_SET_CALCULATION_VESSEL: 'ESTIMATOR_SET_CALCULATION_VESSEL',
    ESTIMATOR_SET_VESSEL_NOTIFICATIONS: 'ESTIMATOR_SET_VESSEL_NOTIFICATIONS',
    ESTIMATOR_SET_VESSEL_STARTING_POSITION: 'ESTIMATOR_SET_VESSEL_STARTING_POSITION',
    ESTIMATOR_SET_VOYAGE_ITINERARY: 'ESTIMATOR_SET_VOYAGE_ITINERARY',
    ESTIMATOR_SET_VOYAGE_ROUTE: 'ESTIMATOR_SET_VOYAGE_ROUTE',
    ESTIMATOR_SET_VOYAGE_AIS_POINTS: 'ESTIMATOR_SET_VOYAGE_AIS_POINTS',
    ESTIMATOR_RESET_CALCULATION_VOYAGE: 'ESTIMATOR_RESET_CALCULATION_VOYAGE',
    ESTIMATOR_SET_POINTS: 'ESTIMATOR_SET_POINTS',
    ESTIMATOR_SET_ROUTE_POINTS: 'ESTIMATOR_SET_ROUTE_POINTS',
    ESTIMATOR_RESET: 'ESTIMATOR_RESET',
    ESTIMATOR_GET_CALCULATION: 'ESTIMATOR_GET_CALCULATION',
    ESTIMATOR_ADD_POINT: 'ESTIMATOR_ADD_POINT',
    ESTIMATOR_MOVE_POINT: 'ESTIMATOR_MOVE_POINT',
    ESTIMATOR_REMOVE_POINT: 'ESTIMATOR_REMOVE_POINT',
    ESTIMATOR_UPDATE_POINT: 'ESTIMATOR_UPDATE_POINT',
    ESTIMATOR_UPDATE_STARTING_POINT: 'ESTIMATOR_UPDATE_STARTING_POINT',
    ESTIMATOR_SEND_REQUEST: 'ESTIMATOR_SEND_REQUEST',
    ESTIMATOR_REQUEST_SENT: 'ESTIMATOR_REQUEST_SENT',
    ESTIMATOR_SET_CALCULATIONS: 'ESTIMATOR_SET_CALCULATIONS',
    ESTIMATOR_SET_CUSTOM_POINT: 'ESTIMATOR_SET_CUSTOM_POINT',
    ESTIMATOR_SET_ACTIVE_PIN_POINT_ID: 'ESTIMATOR_SET_ACTIVE_PIN_POINT_ID',
    ESTIMATOR_SET_CLICKED_POINT_DATA: 'ESTIMATOR_SET_CLICKED_POINT_DATA',
    ESTIMATOR_GET_BUNKER_OPTIONS: 'ESTIMATOR_GET_BUNKER_OPTIONS',
    ESTIMATOR_SET_BUNKER_OPTIONS_AND_INITIAL_VALUES: 'ESTIMATOR_SET_BUNKER_OPTIONS_AND_INITIAL_VALUES',
    ESTIMATOR_SET_FILTERS: 'ESTIMATOR_SET_FILTERS',
    ESTIMATOR_SET_CALCULATION_EU_ETS: 'ESTIMATOR_SET_CALCULATION_EU_ETS'
};

export function setFilters(dispatch, name, value) {
    dispatch({
        type: ActionTypes.ESTIMATOR_SET_FILTERS,
        name,
        value
    });
}

export function setCalculationVessel(dispatch, vessel) {
    dispatch({
        type: ActionTypes.ESTIMATOR_SET_VESSEL,
        vessel
    });
}

export function getCalculationVessel(dispatch, vesselImo, isDeviation) {
    dispatch({
        type: ActionTypes.ESTIMATOR_GET_VESSEL,
        vesselImo,
        isDeviation
    });
}

export function getBunkerOptions(dispatch) {
    dispatch({
        type: ActionTypes.ESTIMATOR_GET_BUNKER_OPTIONS
    });
}

export function getCalculation(dispatch, points) {
    dispatch({
        type: ActionTypes.ESTIMATOR_GET_CALCULATION,
        points
    });
}

export function setCalculationPoints(dispatch, points) {
    dispatch({
        type: ActionTypes.ESTIMATOR_SET_POINTS,
        points
    });
}

export function setRoutePoints(dispatch, routePoints) {
    dispatch({
        type: ActionTypes.ESTIMATOR_SET_ROUTE_POINTS,
        routePoints
    });
}

export function reset(dispatch) {
    dispatch({
        type: ActionTypes.ESTIMATOR_RESET
    });
}

export function addPoint(dispatch, index) {
    dispatch({
        type: ActionTypes.ESTIMATOR_ADD_POINT,
        index
    });
}

export function movePoint(dispatch, fromIndex, toIndex) {
    dispatch({
        type: ActionTypes.ESTIMATOR_MOVE_POINT,
        fromIndex,
        toIndex
    });
}

export function removePoint(dispatch, index) {
    dispatch({
        type: ActionTypes.ESTIMATOR_REMOVE_POINT,
        index
    });
}

export function updatePoint(dispatch, point, index) {
    dispatch({
        type: ActionTypes.ESTIMATOR_UPDATE_POINT,
        point,
        index
    });
}

export function updateStartingPoint(dispatch, point) {
    dispatch({
        type: ActionTypes.ESTIMATOR_UPDATE_STARTING_POINT,
        point
    });
}

export function setVesselStartingPosition(dispatch, startingPosition) {
    dispatch({
        type: ActionTypes.ESTIMATOR_SET_VESSEL_STARTING_POSITION,
        startingPosition
    });
}

export function sendRequest(dispatch, comment) {
    dispatch({
        type: ActionTypes.ESTIMATOR_SEND_REQUEST,
        comment
    });
}

export function setActivePinPointId(dispatch, activePinPointId) {
    dispatch({
        type: ActionTypes.ESTIMATOR_SET_ACTIVE_PIN_POINT_ID,
        activePinPointId
    });
}

export function setClickedPointData(dispatch, portId, coordinates) {
    dispatch({
        type: ActionTypes.ESTIMATOR_SET_CLICKED_POINT_DATA,
        portId,
        coordinates
    });
}

export function setCustomCalculationPoint(dispatch, coordinates, index) {
    dispatch({
        type: ActionTypes.ESTIMATOR_SET_CUSTOM_POINT,
        coordinates,
        index
    });
}
