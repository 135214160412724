import React from 'react';
import PropTypes from 'prop-types';
/* router */
import { appRoutes } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { renderBadges, renderCreatedBy } from '../fleet-management-utils';
/* components */
import Accordion from 'components/accordion/accordion';
import NavButton from 'components/nav-button/nav-button';
import ScrollArea from 'components/scroll-area/scroll-area';
/* styles */
import './fleet-management-fleet-list.scss';

class FleetList extends React.PureComponent {
    renderFleetLink = (fleet, activeFleetId, showPublic = false) => (
        <NavButton
            key={fleet.VesselGroupId}
            isActive={activeFleetId === fleet.VesselGroupId}
            to={`${appRoutes.Vessel.FleetManagement}/${fleet.VesselGroupId}`}
        >
            <div className="flex-row flex-center">
                <div className="flex-grow">
                    <h3>
                        {`${fleet.VesselGroupName}${fleet.IsDynamic ? '' : ` (${fleet.VesselsCount})`}`}
                    </h3>
                    {!showPublic && fleet.IsPublic && renderCreatedBy(fleet)}
                </div>
                <div className="flex-shrink">{renderBadges(fleet, showPublic)}</div>
            </div>
        </NavButton>
    );

    myFleetsHeader = <h4 className="text-uppercase">{t('FLEET_MANAGEMENT.FLEET_LIST.MY_FLEETS')}</h4>;

    sharedFleetsHeader = <h4 className="text-uppercase">{t('FLEET_MANAGEMENT.FLEET_LIST.SHARED_FLEETS')}</h4>;

    render() {
        const { activeFleetId, publicFleets, privateFleets } = this.props;

        return (
            <ScrollArea className="sten-fleet-management__fleet-list">
                <NavButton className="text-uppercase" to={appRoutes.Vessel.FleetManagement}>
                    {t('FLEET_MANAGEMENT.FLEET_LIST.ALL_VESSELS')}
                </NavButton>
                <Accordion header={this.myFleetsHeader}>
                    {privateFleets.map(fleet => this.renderFleetLink(fleet, activeFleetId, true))}
                </Accordion>
                {publicFleets && publicFleets.length > 0 && (
                    <Accordion header={this.sharedFleetsHeader}>
                        {publicFleets.map(fleet => this.renderFleetLink(fleet, activeFleetId))}
                    </Accordion>
                )}
            </ScrollArea>
        );
    }
}

FleetList.propTypes = {
    activeFleetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    privateFleets: PropTypes.arrayOf(PropTypes.object).isRequired,
    publicFleets: PropTypes.arrayOf(PropTypes.object).isRequired
};

FleetList.defaultProps = {
    activeFleetId: null
};

export default FleetList;
