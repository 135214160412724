export const ActionTypes = {
    VESSEL_DETAILS_TOOLTIP_SET_POSITION: 'VESSEL_DETAILS_TOOLTIP_SET_POSITION'
};

export function setVesselDetailsTooltipPosition(dispatch, payload) {
    dispatch({
        type: ActionTypes.VESSEL_DETAILS_TOOLTIP_SET_POSITION,
        payload
    });
}
