import { ActionTypes } from './reports-actions';

const emptyArray = [];

const initialState = {
    isMounted: false,
    isSidebarVisible: false,
    reportTypes: emptyArray,
    inclusionPresets: emptyArray,
    rangeSelectorActive: false,
    selectedReportType: null,
    selectedVessel: null,
    selectedVoyage: null,
    selectedRange: null, // { rangeStart: '2021-07-01T00:00:00.000Z', rangeEnd: '2021-09-30T23:59:59.999Z' },
    selectedInclusionPreset: null,
    presetInclusions: null,
    inclusions: null,
    generatedReportType: null, // { Id: 11, Name: 'Quarterly Report' },
    generatedReportSettings: null,
    vessels: emptyArray,
    vesselsForFleetList: emptyArray,
    segments: emptyArray,
    fleets: emptyArray,
    customFleets: emptyArray,
    CPSpeed: {
        Speeds: []
    },
    filters: {
        selectedVessels: {},
        selectedFleetListVessels: {}
    },
    companies: emptyArray
};

export default function energyManagementReportsReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_MOUNTED: {
        return {
            ...state,
            isMounted: action.isMounted
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_TOGGLE_SIDEBAR: {
        const isSidebarVisible = typeof action.flag === 'boolean' ? action.flag : !state.isSidebarVisible;
        if (!isSidebarVisible) {
            return {
                ...state,
                ...state.generatedReportSettings,
                isSidebarVisible
            };
        }
        return { ...state, isSidebarVisible };
    }
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_TYPES: {
        return {
            ...state,
            reportTypes: action.reportTypes || emptyArray
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_UPDATE_FILTERS: {
        return {
            ...state,
            filters: {
                ...state.filters,
                ...action.filters
            }
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_SEGMENT_VESSELS_FLEETS: {
        // eslint-disable-next-line max-len
        const selectedFleetListVessels = action.vesselsForFleetList.reduce((sum, vessel) => ({ ...sum, [vessel.Imo]: true }), {});
        const selectedVessels = action.vessels.reduce((sum, vessel) => ({ ...sum, [vessel.Imo]: true }), {});
        return {
            ...state,
            vessels: action.vessels,
            vesselsForFleetList: action.vesselsForFleetList,
            segments: action.segments,
            fleets: action.fleets,
            filters: {
                ...state.filters,
                selectedVessels,
                selectedFleetListVessels
            }
        };
    }

    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_CUSTOM_FLEETS: {
        return {
            ...state,
            customFleets: action.customFleets
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_TYPE: {
        return {
            ...state,
            selectedReportType: action.reportType,
            selectedVessel: null,
            selectedVoyage: null,
            selectedRange: null,
            selectedInclusionPreset: null,
            presetInclusions: null,
            inclusions: null
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_VESSEL: {
        return {
            ...state,
            selectedVessel: action.vessel
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_VOYAGE: {
        return {
            ...state,
            selectedVoyage: action.voyage
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_RANGE: {
        return {
            ...state,
            selectedRange: action.range
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_COMPANIES: {
        return {
            ...state,
            companies: action.companies
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_INCLUSION_PRESETS: {
        return {
            ...state,
            inclusionPresets: action.presets || emptyArray
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_INCLUSIONS: {
        return {
            ...state,
            presetInclusions: action.inclusions,
            inclusions: action.inclusions
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_INCLUSION_PRESET: {
        return {
            ...state,
            selectedInclusionPreset: action.preset,
            inclusions: null
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_INCLUSION_CP_VALUE: {
        return {
            ...state,
            CPSpeed: {
                ...state.CPSpeed,
                [action.key]: action.value
            }
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_INCLUSION_VALUE: {
        if (action.field) {
            const inclusions = { ...state.inclusions };
            inclusions[action.field] = action.value;
            return {
                ...state,
                inclusions
            };
        }
        return state;
    }
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_TOGGLE_RANGE_SELECTOR: {
        return {
            ...state,
            rangeSelectorActive: typeof action.flag === 'boolean' ? action.flag : !state.rangeSelectorActive
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_RESET_INCLUSIONS: {
        return {
            ...state,
            inclusions: state.presetInclusions
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_PREVIEW_GENERATED: {
        return {
            ...state,
            generatedReportType: action.generatedReportType,
            generatedReportSettings: {
                rangeSelectorActive: state.rangeSelectorActive,
                inclusionPresets: state.inclusionPresets,
                selectedReportType: state.selectedReportType,
                selectedVessel: state.selectedVessel,
                selectedVoyage: state.selectedVoyage,
                selectedRange: state.selectedRange,
                selectedInclusionPreset: state.selectedInclusionPreset,
                presetInclusions: state.presetInclusions,
                inclusions: state.inclusions
            },
            isSidebarVisible: typeof action.isSidebarVisible === 'boolean'
                ? action.isSidebarVisible
                : state.isSidebarVisible
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_REPORTS_RESET_ALL: {
        return {
            ...initialState,
            reportTypes: state.reportTypes,
            vessels: state.vessels,
            segments: state.segments
        };
    }
    default:
        return state;
    }
}
