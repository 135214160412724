import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import Input from 'components/input/input';
import Validation from 'components/validation/validation';

const t = (key) => translate(`PORT_MANAGEMENT.FILTERS.${key}`);

const initialState = {
    CountryName: '',
    PortName: '',
    TerminalName: '',
    BerthName: ''
};

class PortManagementFilters extends React.PureComponent {
    state = initialState;

    handleCountryUpdate = (val) => this.setState({ CountryName: val });

    handlePortUpdate = (val) => this.setState({ PortName: val });

    handleTerminalUpdate = (val) => this.setState({ TerminalName: val });

    handleBerthUpdate = (val) => this.setState({ BerthName: val });

    handleSubmit = () => this.props.onSubmit(this.state);

    handleFiltersReset = () => {
        this.setState(initialState);
        this.props.onReset();
    }

    areFiltersChanged = () => {
        const { CountryName, PortName, TerminalName, BerthName } = this.state;
        return CountryName || PortName || TerminalName || BerthName;
    };

    render() {
        const areFiltersChanged = this.areFiltersChanged();
        return (
            <Validation.Form
                className="sten-port-management-filters sten-content__section flex-row flex-row--sm flex-end"
                onSubmit={this.handleSubmit}
            >
                <div className="col-sm-18 col-xs-24">
                    <div className="row">
                        <div className="col-sm-6 col-xs-12">
                            <label className="label">{t('COUNTRY')}</label>
                            <Validation.Wrapper validations={{ minLength: 3, maxLength: 30 }} hintsOnHover>
                                <Input
                                    name="CountryName"
                                    placeholder={t('COUNTRY_PLACEHOLDER')}
                                    onChange={this.handleCountryUpdate}
                                    value={this.state.CountryName}
                                />
                            </Validation.Wrapper>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                            <label className="label">{t('PORT')}</label>
                            <Validation.Wrapper validations={{ minLength: 3, maxLength: 30 }} hintsOnHover>
                                <Input
                                    name="PortName"
                                    placeholder={t('PORT_PLACEHOLDER')}
                                    onChange={this.handlePortUpdate}
                                    value={this.state.PortName}
                                />
                            </Validation.Wrapper>
                        </div>
                        <div className="col-sm-6 col-xs-12 col-pad--xs">
                            <label className="label">{t('TERMINAL')}</label>
                            <Validation.Wrapper validations={{ minLength: 3, maxLength: 30 }} hintsOnHover>
                                <Input
                                    name="TerminalName"
                                    placeholder={t('TERMINAL_PLACEHOLDER')}
                                    onChange={this.handleTerminalUpdate}
                                    value={this.state.TerminalName}
                                />
                            </Validation.Wrapper>
                        </div>
                        <div className="col-sm-6 col-xs-12 col-pad--xs">
                            <label className="label">{t('BERTH')}</label>
                            <Validation.Wrapper validations={{ minLength: 3, maxLength: 30 }} hintsOnHover>
                                <Input
                                    name="BerthName"
                                    placeholder={t('BERTH_PLACEHOLDER')}
                                    onChange={this.handleBerthUpdate}
                                    value={this.state.BerthName}
                                />
                            </Validation.Wrapper>
                        </div>
                    </div>
                </div>
                <div className="col-xs-24 col-sm-6 col-pad--xs">
                    <div className="row">
                        <div className="col-12">
                            <button
                                disabled={!areFiltersChanged}
                                type="button"
                                className="btn btn--secondary col-24"
                                onClick={this.handleFiltersReset}
                            >
                                {translate('GLOBAL.RESET')}
                            </button>
                        </div>
                        <div className="col-12">
                            <Validation.Button
                                className="btn btn--primary col-24"
                                disabled={!areFiltersChanged}
                            >
                                {translate('GLOBAL.SEARCH')}
                            </Validation.Button>
                        </div>
                    </div>
                </div>
            </Validation.Form>
        );
    }
}

PortManagementFilters.propTypes = {
    onReset: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired
};

export default PortManagementFilters;
