import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { getClassName, getObjectProp } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* service */
import ConfigService from 'services/config-api/config-service';
/* actions */
import { stopRequest, deleteRequest, refreshRequest, setIntendedRequest } from '../../weather-routing-actions';
/* components */
import Checkbox from 'components/checkbox/checkbox';
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
import Menu, { MenuItem } from 'components/menu/menu';
/* configs */
import {
    requestActionField,
    requestFields,
    sposFields,
    routeAdviceColumns,
    routeColors,
    routeAdviceTableWidth
} from '../../weather-routing-constants';

export class WeatherRoutingLegTable extends React.PureComponent {
    state = {
        isSendToCaptainActive: null,
        collapsedSections: {},
        selectedRoutesMap: null,
        prevSelectedRoutes: null,
        prevSelectedLegRequests: null
    };

    static getDerivedStateFromProps(props, state) {
        let newState = null;
        if (props.selectedRoutes !== state.prevSelectedRoutes) {
            const selectedRoutesMap = {};
            const selectedSposRoutesMap = {};
            props.selectedRoutes.forEach(route => {
                if (route.RouteType === 'sposRoute') {
                    selectedSposRoutesMap[route.Id] = route;
                } else {
                    selectedRoutesMap[route.Id] = route;
                }
            });
            newState = {
                selectedRoutesMap,
                selectedSposRoutesMap,
                prevSelectedRoutes: props.selectedRoutes
            };
        }
        if (props.selectedLegRequests !== state.prevSelectedLegRequests) {
            newState = {
                ...newState,
                collapsedSections: {},
                prevSelectedLegRequests: props.selectedLegRequests
            };
        }
        if (props.selectedLeg && state.isSendToCaptainActive === null) {
            const currentDate = new Date().toISOString();
            newState = {
                ...newState,
                isSendToCaptainActive: !ConfigService.hiddenFeatures.weatherRoutingIntendWhenVoyageEnded
                    || props.selectedLeg.Voyage.EndDate > currentDate
            };
        }
        return newState;
    }

    componentDidMount() {
        this.selectLatestOrDeleteRouteAdvices(this.props.selectedLegRequests);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.selectedLegRequests !== this.props.selectedLegRequests) {
            this.selectLatestOrDeleteRouteAdvices(this.props.selectedLegRequests);
            if (this.scrollArea) {
                this.scrollArea.scrollTop();
            }
        }
    }

    selectLatestOrDeleteRouteAdvices(data) {
        this.availableColors = [...routeColors];
        const requests = data ? (data.Intended || data.HistoricalIntended || data.NotIntended) : null;
        const RouteAdvice = requests?.[0]?.ExternalRequests?.[0]?.RouteAdvice;
        if (RouteAdvice) {
            this.props.onSelectedRoutesChange([{
                ...RouteAdvice,
                Color: this.availableColors.shift(),
                RouteType: 'route',
                Eta: RouteAdvice.AdvisedEta,
                RouteStartDate: RouteAdvice.Etd > RouteAdvice.DateOfAdvice ? RouteAdvice.Etd : RouteAdvice.DateOfAdvice,
                VesselCondition: requests[0].VesselCondition,
                PortOfDeparture: requests[0].PortOfDeparture,
                PortOfDestination: requests[0].PortOfDestination
            }]);
        } else {
            this.props.onSelectedRoutesChange([]);
        }
    }

    availableColors = [...routeColors];

    sectionCollapseToggleHandlers = {};

    getSectionCollapseToggleHandler = (id) => {
        if (!this.sectionCollapseToggleHandlers[id]) {
            this.sectionCollapseToggleHandlers[id] = () => this.setState({
                collapsedSections: {
                    ...this.state.collapsedSections,
                    [id]: !this.state.collapsedSections[id]
                }
            });
        }
        return this.sectionCollapseToggleHandlers[id];
    };

    handleRouteCheckboxToggle = (e, params) => {
        const selectedRouteIndex = this.props.selectedRoutes.findIndex(
            route => (route.Id === params.route.Id && route.RouteType === params.routeType)
        );
        if (selectedRouteIndex > -1) {
            this.availableColors.unshift(this.props.selectedRoutes[selectedRouteIndex].Color);
            this.props.onSelectedRoutesChange(this.props.selectedRoutes.filter(
                route => (route.RouteType !== params.routeType || route.Id !== params.route.Id)
            ));
        } else {
            let RouteStartDate = params.route.Etd || params.route.DepartureDate;
            if (params.route.DateOfAdvice && params.route.DateOfAdvice > RouteStartDate) {
                RouteStartDate = params.route.DateOfAdvice;
            }
            if (params.route.VesselPositionTime && params.route.VesselPositionTime > RouteStartDate) {
                RouteStartDate = params.route.VesselPositionTime;
            }
            this.props.onSelectedRoutesChange([
                ...this.props.selectedRoutes,
                {
                    ...params.route,
                    Color: this.availableColors.shift(),
                    RouteType: params.routeType,
                    Etd: params.route.Etd || params.route.DepartureDate,
                    RouteStartDate,
                    Eta: params.route.AdvisedEta || params.route.DestinationDate,
                    ...params.request ? {
                        VesselCondition: params.request.VesselCondition,
                        PortOfDeparture: params.request.PortOfDeparture,
                        PortOfDestination: params.request.PortOfDestination
                    } : null
                }
            ]);
        }
    };

    renderValue = (data, config) => {
        if (config.renderValue) {
            return config.renderValue(data, this);
        }
        const value = config.getValue ? config.getValue(data, this) : getObjectProp(data, config.prop);
        return value ? `${value}${config.unit ? ` ${config.unit}` : ''}` : '-';
    };

    getTitleMessage = (array, label) => {
        let str = '';
        array.forEach((item, i) => { str += `${label} ${i + 1}: ${item}\n`; });
        return str;
    }

    renderRouteAdvices = (request) => {
        if (!request.ExternalRequests.length) {
            const noRoutesMessage = ConfigService.featureToggles.weatherRoutingLegCheckInterval
                ? t('WEATHER_ROUTING.LEG.NO_ROUTES_WAIT')
                : t('WEATHER_ROUTING.LEG.NO_ROUTES_REFRESH');
            return (
                <tbody>
                    <tr>
                        <td />
                        <td colSpan={routeAdviceColumns.length - 1}>
                            {request.Status.Name === 'Error'
                                ? t('WEATHER_ROUTING.LEG.ERROR')
                                : noRoutesMessage}
                        </td>
                    </tr>
                </tbody>
            );
        }
        const { selectedRoutesMap } = this.state;
        const { selectedRoutes } = this.props;
        const iconClass = 'icon icon-info-circle icon--md sten-weather-routing-leg-table__icon-info';
        const warningInfoClass = `${iconClass} text-warning`;
        const errorInfoClass = `${iconClass} text-danger`;
        return (
            <tbody>
                <tr className="sten-weather-routing-leg-table__advices-header">
                    {routeAdviceColumns.map((col, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <th key={index}>{col.label}</th>
                    ))}
                </tr>
                {request.ExternalRequests.map((er, index) => {
                    const route = er.RouteAdvice;
                    const hasWarnings = er.Warnings.length > 0;
                    const hasErrors = er.Errors.length > 0;
                    const warningTitle = this.getTitleMessage(er.Warnings, 'Warning');
                    const errorTitle = this.getTitleMessage(er.Errors, 'Error');
                    return (
                        <tr key={route?.Id || index}>
                            {routeAdviceColumns.map((col, index) => {
                                const checkbox = er.IsSuccess ? (
                                    <Checkbox
                                        color={selectedRoutesMap[route.Id] ? selectedRoutesMap[route.Id].Color : ''}
                                        isChecked={!!selectedRoutesMap[route.Id]}
                                        onChange={this.handleRouteCheckboxToggle}
                                        isDisabled={!selectedRoutesMap[route.Id] && selectedRoutes.length >= 5}
                                        routeType="route"
                                        route={route}
                                        request={request}
                                        title={!selectedRoutesMap[route.Id] && selectedRoutes.length >= 5
                                            ? t('WEATHER_ROUTING.LEG.MAX_SELECTED_ROUTES')
                                            : ''}
                                    >
                                        {this.renderValue(route, col)}
                                    </Checkbox>
                                ) : (
                                    <Checkbox isDisabled>
                                        {TimeHelper.getFormatted(er.CreatedAt, { utc: true, time: true })}
                                    </Checkbox>
                                );
                                return (
                                // eslint-disable-next-line react/no-array-index-key
                                    <td key={index} title={col.getTitle && route && col.getTitle(route)}>
                                        {index === 0 ? (
                                            <div className="flex flex-row flex-center">
                                                <div className="flex-grow">{checkbox}</div>
                                                {(hasWarnings || hasErrors) && (
                                                    <div className="flex-shrink">
                                                        {hasWarnings && (
                                                            <span className={warningInfoClass} title={warningTitle} />
                                                        )}
                                                        {hasErrors && (
                                                            <span className={errorInfoClass} title={errorTitle} />
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        ) : this.renderValue(route, col)}
                                        {col.getSubtitle && route && (
                                            <h6 className="text-secondary">{col.getSubtitle(route)}</h6>
                                        )}
                                    </td>
                                );
                            })}
                        </tr>
                    );
                })}
            </tbody>
        );
    };

    renderRequestField = (field, data, index) => (
        <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className="form-section-inline"
            style={field.style}
        >
            {field.label && <div className="label text-nowrap">{field.label}</div>}
            <div
                className="label text-nowrap label--value"
                title={field.getTitle && field.getTitle(data)}
            >
                {this.renderValue(data, field)}
            </div>
        </div>
    );

    getLinkToRequestView = (request, requestEdit = false) =>
        this.props.router.getLinkTo({
            query: { requestId: request.Id, requestEdit }
        });

    getDeleteClickHandler = (requestId) => () => {
        this.props.showModal({
            type: 'Delete',
            onConfirm: () => this.props.deleteRequest(requestId, this.props.selectedLeg.Id),
            title: t('WEATHER_ROUTING.LEG.DELETE_CONFIRM_TITLE'),
            children: t('WEATHER_ROUTING.LEG.DELETE_CONFIRM_CONTENT')
        });
    };

    stopRequestHandler = (requestId) => () => {
        this.props.showModal({
            type: 'Confirm',
            onConfirm: () => this.props.stopRequest(requestId, this.props.selectedLeg.Id),
            title: t('WEATHER_ROUTING.LEG.STOP_REQUEST_MODAL_TITLE'),
            children: t('WEATHER_ROUTING.LEG.STOP_REQUEST_MODAL_CONTENT')
        });
    };

    refreshRequestHandler = (requestId) => () => {
        this.props.refreshRequest(requestId, this.props.selectedLeg.Id);
    };

    renderMenu = (request) => {
        const Intention = request.Intention.Name;
        const Source = request.Source.Name;
        const isRefreshDisabled = !(Intention === 'NotIntended' && Source === 'AutomaticWeatherApi');
        const isStopDisabled = Intention !== 'Intended'
            || Source !== 'AutomaticWeatherApi'
            || request.Status.Name === 'Completed';
        return (
            <Menu stopPropagation>
                <MenuItem
                    to={this.getLinkToRequestView(request)}
                    icon="icon-next-leg-report"
                >
                    {t('WEATHER_ROUTING.SHOW_ORIGINAL_REQUEST')}
                </MenuItem>
                <MenuItem
                    isDisabled={isRefreshDisabled}
                    title={isRefreshDisabled ? t('WEATHER_ROUTING.LEG.REFRESH_DISABLED_TITLE') : ''}
                    icon="icon-reload"
                    onClick={this.refreshRequestHandler(request.Id)}
                >
                    {t('WEATHER_ROUTING.LEG.REFRESH_REQUEST')}
                </MenuItem>
                <MenuItem
                    isDisabled={isStopDisabled}
                    title={isStopDisabled ? t('WEATHER_ROUTING.LEG.STOP_DISABLED_TITLE') : ''}
                    icon="icon-close-circle"
                    onClick={this.stopRequestHandler(request.Id)}
                >
                    {t('WEATHER_ROUTING.LEG.STOP_REQUEST')}
                </MenuItem>
                <MenuItem
                    icon="icon-copy"
                    to={this.getLinkToRequestView(request, true)}
                >
                    {t('WEATHER_ROUTING.DUPLICATE_REQUEST')}
                </MenuItem>
                <MenuItem
                    icon="icon-delete"
                    isDisabled={Intention !== 'NotIntended'}
                    title={Intention !== 'NotIntended' ? t('WEATHER_ROUTING.LEG.DELETE_DISABLED_TITLE') : ''}
                    onClick={this.getDeleteClickHandler(request.Id)}
                >
                    {t('WEATHER_ROUTING.LEG.DELETE')}
                </MenuItem>
            </Menu>
        );
    };

    getSendToCaptainClickHandler = (requestId) => () => {
        this.props.showModal({
            type: 'Confirm',
            onConfirm: () => this.props.setIntendedRequest(requestId, this.props.selectedLeg.Id),
            title: t('WEATHER_ROUTING.LEG.SEND_TO_CAPTAIN_CONFIRM_TITLE'),
            children: t('WEATHER_ROUTING.LEG.SEND_TO_CAPTAIN_CONFIRM_CONTENT')
        });
    };

    renderRequest = (request) => {
        let isSendToCaptainActive = false;
        if (this.state.isSendToCaptainActive
            && request.Intention.Name === 'NotIntended'
            && request.Status.Name === 'RouteReady') {
            const latestAdvice = request.ExternalRequests?.[0]?.RouteAdvice;
            if (latestAdvice) {
                const dateDiff = TimeHelper.getDateDiff(latestAdvice.DateOfAdvice, new Date().toISOString());
                isSendToCaptainActive = dateDiff <= TimeHelper.units.day;
            }
        }
        return (
            <React.Fragment key={request.Id}>
                <tbody>
                    <tr className="sten-weather-routing-leg-table__request">
                        <td>
                            <div className="flex-row flex--wrap flex-center">
                                {this.renderRequestField(requestActionField, request)}
                                {isSendToCaptainActive && (
                                    <button
                                        onClick={this.getSendToCaptainClickHandler(request.Id)}
                                        className="btn-link btn-link--inverted icon icon-send"
                                        title={t('WEATHER_ROUTING.LEG.SEND_TO_CAPTAIN')}
                                    />
                                )}
                                <div className="sten-content__vertical-separator" />
                                {this.renderMenu(request)}
                            </div>
                        </td>
                        <td colSpan={routeAdviceColumns.length - 1}>
                            <div className="flex-row flex--wrap">
                                {requestFields.map((field, index) => this.renderRequestField(field, request, index))}
                            </div>
                        </td>
                    </tr>
                </tbody>
                {this.renderRouteAdvices(request)}
            </React.Fragment>
        );
    };

    renderSpos = (sposRoute) => {
        const { selectedSposRoutesMap } = this.state;
        const { selectedRoutes } = this.props;
        return (
            <tbody key={sposRoute.Id}>
                <tr className="sten-weather-routing-leg-table__request">
                    <td>
                        <Checkbox
                            color={selectedSposRoutesMap[sposRoute.Id] ? selectedSposRoutesMap[sposRoute.Id].Color : ''}
                            isChecked={!!selectedSposRoutesMap[sposRoute.Id]}
                            onChange={this.handleRouteCheckboxToggle}
                            isDisabled={!selectedSposRoutesMap[sposRoute.Id] && selectedRoutes.length >= 5}
                            route={sposRoute}
                            routeType="sposRoute"
                            title={!selectedSposRoutesMap[sposRoute.Id] && selectedRoutes.length >= 5
                                ? t('WEATHER_ROUTING.LEG.MAX_SELECTED_ROUTES')
                                : ''}
                        >
                            <div className="flex-row flex--wrap flex-center">
                                {this.renderRequestField(sposFields[0], sposRoute)}
                            </div>
                        </Checkbox>
                    </td>
                    <td colSpan={routeAdviceColumns.length - 1}>
                        <div className="flex-row flex--wrap">
                            {sposFields.slice(1).map((field, index) =>
                                this.renderRequestField(field, sposRoute, index))}
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    renderSection = (section, title, isSpos = false) => {
        if (!section) {
            return null;
        }
        const sectionClass = getClassName('sten-table__accordion', {
            'sten-table__accordion--collapsed': this.state.collapsedSections[title]
        });
        return (
            <React.Fragment>
                <tbody>
                    <tr className={sectionClass} onClick={this.getSectionCollapseToggleHandler(title)}>
                        <td>{title}</td>
                        <td colSpan={routeAdviceColumns.length - 1} />
                    </tr>
                </tbody>
                {!this.state.collapsedSections[title] && section.map(isSpos ? this.renderSpos : this.renderRequest)}
            </React.Fragment>
        );
    };

    getTextModalHandler = (modalData) => () => this.props.showModal(modalData);

    saveTableRef = (c) => { this.tableRef = c; }

    render() {
        const { selectedLegRequests, selectedLegSposRoutes } = this.props;
        return (
            <FixedHeaderTable
                ref={this.saveTableRef}
                className="sten-weather-routing-leg-table"
                contentStyle={{ minWidth: `${routeAdviceTableWidth}rem` }}
                withHeaderColumn
                withHeaderColumnSeparator
                withHeaderRow={false}
            >
                <table className="sten-table sten-table--xs">
                    <thead>
                        <tr>
                            {routeAdviceColumns.map((col, index) => (
                                <th
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    style={index === 0 ? { width: `${col.width}rem` } : { minWidth: `${col.width}rem` }}
                                />
                            ))}
                        </tr>
                    </thead>
                    {selectedLegSposRoutes.length > 0 && this.renderSection(
                        selectedLegSposRoutes,
                        t('WEATHER_ROUTING.LEG.MASTER_INTENDED_ROUTES'),
                        true
                    )}
                    {this.renderSection(selectedLegRequests?.Intended, t('WEATHER_ROUTING.LEG.ACTIVE_REQUEST'))}
                    {this.renderSection(
                        selectedLegRequests?.HistoricalIntended,
                        t('WEATHER_ROUTING.LEG.HISTORICAL_REQUESTS')
                    )}
                    {this.renderSection(selectedLegRequests?.NotIntended, t('WEATHER_ROUTING.LEG.OPTIONS'))}
                </table>
            </FixedHeaderTable>
        );
    }
}

WeatherRoutingLegTable.propTypes = {
    deleteRequest: PropTypes.func.isRequired,
    onSelectedRoutesChange: PropTypes.func.isRequired,
    selectedLeg: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedLegRequests: PropTypes.shape({
        Intended: PropTypes.arrayOf(PropTypes.object),
        HistoricalIntended: PropTypes.arrayOf(PropTypes.object),
        NotIntended: PropTypes.arrayOf(PropTypes.object)
    }),
    selectedLegSposRoutes: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedRoutes: PropTypes.arrayOf(PropTypes.object).isRequired,
    setIntendedRequest: PropTypes.func.isRequired,
    showModal: PropTypes.func.isRequired,
    stopRequest: PropTypes.func.isRequired,
    refreshRequest: PropTypes.func.isRequired,
    router: TRouter.isRequired
};

WeatherRoutingLegTable.defaultProps = {
    selectedLegRequests: null
};

function mapDispatchToProps(dispatch) {
    return {
        deleteRequest: (requestId, legId) => deleteRequest(dispatch, requestId, legId),
        stopRequest: (requestId, legId) => stopRequest(dispatch, requestId, legId),
        refreshRequest: (requestId, legId) => refreshRequest(dispatch, requestId, legId),
        setIntendedRequest: (requestId, legId) => setIntendedRequest(dispatch, requestId, legId)
    };
}

export default connect(null, mapDispatchToProps)(withRouter(WeatherRoutingLegTable));
