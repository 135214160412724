import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import keyCodes from 'utils/key-codes';
import { getClassName } from 'utils/helpers/info-helper';
/* components */
import Portal from 'components/portal/portal';
import ScrollArea from 'components/scroll-area/scroll-area';
/* styles */
import './multi-string-select.scss';

class MultiStringSelect extends React.PureComponent {
    resizeObserver = new ResizeObserver(() => {
        this.calculatePosition();
    });

    state = {
        selectOptionsStyle: {},
        inputValue: '',
        filteredOptions: [],
        selectedOptions: [],
        value: [],
        highlightedIndex: 0,
        isMenuShown: false
    };

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.value) {
            return {
                selectedOptions: props.value,
                value: props.value
            };
        }
        return null;
    }

    componentDidMount() {
        this.updateFilteredOptions();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.options !== this.props.options) {
            this.updateFilteredOptions(this.props.options);
        }
    }

    componentWillUnmount() {
        this.setListeners(false);
        if (this.select) {
            this.resizeObserver.unobserve(this.select);
        }
    }

    onListItemHover = highlightedIndex => {
        this.setState({ highlightedIndex });
    };

    handleKeyDown = e => {
        e.stopPropagation();
        switch (e.keyCode) {
        case keyCodes.enter:
        case keyCodes.semicolon:
            this.handleEnter(e);
            break;
        case keyCodes.tab:
            this.toggleSelectMenu(false);
            break;
        case keyCodes.escape:
            e.stopPropagation();
            this.toggleSelectMenu(false);
            this.handleEscape();
            break;
        case keyCodes.backspace:
            if (e.target.value === '') {
                this.deselectOption(this.state.selectedOptions[this.state.selectedOptions.length - 1]);
                e.preventDefault();
            }
            break;
        case keyCodes.upArrow:
            this.handleArrowUp();
            break;
        case keyCodes.downArrow:
            this.handleArrowDown();
            break;
        default:
        }
    };

    isKeyboardClick = target => (
        target && typeof target.className === 'string'
        && target.className.indexOf('keyboard-button') >= 0
    );

    onOuterDivBlur = e => {
        const target = e.relatedTarget || e.target;
        if (!this.isKeyboardClick(target) && this.select && !this.select.contains(target)) {
            if (this.state.isMenuShown && this.state.inputValue) {
                if (this.state.selectedOptions.indexOf(this.state.inputValue) === -1
                    && (!this.props.validateInput || this.props.validateInput(this.state.inputValue))) {
                    this.selectOption(this.state.inputValue);
                } else {
                    this.setState({
                        inputValue: ''
                    });
                }
                this.toggleSelectMenu(false);
            }
        }
    };

    onSelectClick = () => {
        if (!this.props.disabled) {
            this.inputElement.focus();
        }
    };

    calculatePosition = () => {
        if (this.select) {
            const selectRect = this.select.getBoundingClientRect();
            const collapseDown = !(selectRect.top > window.innerHeight / 2);
            const selectWidth = selectRect.width - 2;
            const selectOptionsStyle = {
                position: 'fixed',
                zIndex: 99,
                minWidth: selectWidth,
                maxWidth: this.props.fixedListWidth ? selectWidth : 'calc(100% + 2px)',
                top: collapseDown
                    ? selectRect.top + selectRect.height + 1
                    : `calc(${selectRect.top}px - 4px - 2.25rem - 4px)`,
                left: selectRect.left
            };
            this.setState({ selectOptionsStyle });
        }
    }

    setInputValue = e => {
        if (this.state.isMenuShown) {
            const inputValue = e.target.value.trim();
            this.setState({
                inputValue,
                highlightedIndex: 0
            }, this.updateFilteredOptions);
        }
    };

    getItemDomNode(index) {
        const items = this.scrollArea.contentWrapperRef.children;
        return items[index];
    }

    handleEnter = e => {
        const selectedOption = this.state.filteredOptions[this.state.highlightedIndex];
        if (selectedOption) {
            this.selectOption(selectedOption);
        }
        e.preventDefault();
    };

    handleEscape() {
        this.setState({
            inputValue: '',
            highlightedIndex: 0
        }, this.updateFilteredOptions);
        this.toggleSelectMenu(false);
    }

    handleArrowUp = () => {
        const highlightedIndex = Math.max(0, this.state.highlightedIndex - 1);

        this.scrollIfNeeded(highlightedIndex);

        this.setState({
            highlightedIndex,
            isMenuOpen: true
        });
    };

    scrollIfNeeded(highlightedIndex) {
        if (this.scrollArea) {
            const highlightedItem = this.getItemDomNode(highlightedIndex);
            if (highlightedItem) {
                const itemUpperBorderPosition = highlightedItem.offsetTop;
                const itemRect = highlightedItem.getBoundingClientRect();
                const menuHeight = this.scrollArea.sizes.containerHeight;
                const lowerItemBorderPosition = itemRect.height + highlightedItem.offsetTop;

                if (itemUpperBorderPosition < 0) {
                    this.scrollArea.scrollYTo(this.scrollArea.state.topPosition + itemUpperBorderPosition);
                } else if (lowerItemBorderPosition > menuHeight) {
                    const delta = lowerItemBorderPosition - menuHeight;
                    this.scrollArea.scrollYTo(this.scrollArea.state.topPosition + delta);
                }
            }
        }
    }

    handleArrowDown = () => {
        let highlightedIndex;

        if (!this.state.isMenuOpen) {
            highlightedIndex = 0;
        } else {
            highlightedIndex = Math.min(
                this.state.filteredOptions.length - 1,
                this.state.highlightedIndex + 1
            );
        }

        this.scrollIfNeeded(highlightedIndex);

        this.setState({
            highlightedIndex,
            isMenuOpen: true
        });
    };

    selectOption(option) {
        const selectedOptions = [...this.state.selectedOptions];
        selectedOptions.push(option);
        const filteredOptions = this.state.filteredOptions.filter(filteredOption => filteredOption !== option);
        this.setState({
            selectedOptions,
            highlightedIndex: Math.max(this.state.highlightedIndex - 1, 0),
            filteredOptions,
            inputValue: '',
            value: selectedOptions
        }, this.updateFilteredOptions);
        this.props.onChange(selectedOptions);
        this.inputElement.focus();
    }

    deselectOption(deselectedOption) {
        const selectedOptions = this.state.selectedOptions.filter(option => option !== deselectedOption);
        this.props.onChange(selectedOptions);
        this.setState({
            selectedOptions,
            value: selectedOptions
        }, this.updateFilteredOptions);
        this.inputElement.focus();
    }

    updateFilteredOptions = (options = this.props.options) => {
        const inputValue = this.state.inputValue;
        const filteredOptions = options
            ? options.filter(option =>
                option.indexOf(inputValue) > -1 && this.state.selectedOptions.indexOf(option) === -1)
            : [];
        if ((inputValue && this.state.selectedOptions.length === 0)
            || (inputValue && this.state.selectedOptions.indexOf(inputValue) === -1)) {
            if (!this.props.validateInput || this.props.validateInput(inputValue)) {
                filteredOptions.unshift(inputValue);
            }
        }
        this.setState({
            filteredOptions
        }, () => this.scrollIfNeeded(this.state.highlightedIndex));
    };

    handleScrollChange = () => {
        if (this.selectRect && this.select) {
            const newSelectRect = this.select.getBoundingClientRect();
            if (this.selectRect
                && (this.selectRect.top !== newSelectRect.top || this.selectRect.left !== newSelectRect.left)) {
                this.toggleSelectMenu(false);
            }
        }
    };

    saveSelectRect = () => {
        if (this.select) {
            this.selectRect = this.select.getBoundingClientRect();
        }
    };

    setListeners = (enable) => {
        if (enable) {
            document.addEventListener('click', this.documentOnClick);
            document.addEventListener('scrollChanged', this.handleScrollChange);
        } else {
            document.removeEventListener('click', this.documentOnClick);
            document.removeEventListener('scrollChanged', this.handleScrollChange);
        }
    };

    toggleSelectMenu = flag => {
        if (!this.props.disabled) {
            const flagIsBool = typeof flag === 'boolean';
            if ((flagIsBool && flag !== this.state.isMenuShown) || !flagIsBool) {
                if (!this.state.isMenuShown) {
                    this.setListeners(true);
                    this.saveSelectRect();
                    this.calculatePosition();
                } else {
                    this.setListeners(false);
                }
                this.setState({
                    isMenuShown: !this.state.isMenuShown
                });
            }
            if (flag) {
                this.inputElement.focus();
            }
        }
    };

    documentOnClick = e => {
        if (!this.isKeyboardClick(e.target) && this.selectOptionsRef && !this.selectOptionsRef.contains(e.target)) {
            this.toggleSelectMenu(false);
        }
    };

    renderOptions() {
        const widthStyle = { width: this.inputElement?.clientWidth };
        return this.state.filteredOptions.map((option, index) => {
            const itemClass = getClassName('sten-multi-string-select__list-item', {
                'sten-multi-string-select__list-item--active': this.state.highlightedIndex === index
            });
            return (
                <div
                    key={option}
                    className={itemClass}
                    onClick={this.selectOption.bind(this, option)}
                    onMouseOver={this.onListItemHover.bind(this, index)}
                    style={widthStyle}
                    title={option}
                >
                    {option}
                </div>
            );
        });
    }

    renderSelectedOptions = () => this.state.selectedOptions.map((option) => (
        <span
            key={option}
            className="sten-multi-string-select__item"
            title={option}
        >
            <span className="sten-multi-string-select__item-dots">{option}</span>
            <span
                onClick={this.deselectOption.bind(this, option)}
                className="icon icon-close"
            />
        </span>
    ));

    onInputFocus = () => {
        this.toggleSelectMenu(true);
    };

    saveRef = (c) => {
        this.select = c;
        if (this.select) {
            this.resizeObserver.observe(this.select);
        }
    };

    saveInputRef = (c) => { this.inputElement = c; };

    saveInputSizingRef = (c) => { this.inputSizing = c; };

    saveScrollRef = (c) => { this.scrollArea = c; };

    saveOptionsRef = (c) => { this.selectOptionsRef = c; };

    render() {
        let inputStyle = { width: 0, marginLeft: 0 };
        if (this.state.isMenuShown && this.inputSizing) {
            inputStyle = { width: `${this.inputSizing.scrollWidth + 1}px` };
        }
        const classNames = getClassName('sten-multi-string-select', this.props.className, {
            'sten-multi-string-select--active': this.state.isMenuShown,
            'sten-multi-string-select--disable': this.props.disabled,
            'sten-multi-string-select--invalid': this.props.invalid
        });
        const menuClass = getClassName('sten-multi-string-select__list', {
            'sten-multi-string-select__list--fixed-width': this.props.fixedListWidth
        });

        return (
            <div
                ref={this.saveRef}
                className={classNames}
                onClick={this.onSelectClick}
                onBlur={this.onOuterDivBlur}
            >
                {this.renderSelectedOptions()}
                {this.state.selectedOptions.length === 0 && this.props.placeholder && !this.state.isMenuShown && (
                    <span className="sten-multi-string-select__placeholder">{this.props.placeholder}</span>
                )}
                <span
                    ref={this.saveInputSizingRef}
                    className="sten-multi-string-select__input-sizing"
                >
                    {this.state.inputValue}
                </span>
                <input
                    style={inputStyle}
                    ref={this.saveInputRef}
                    disabled={this.props.disabled}
                    className="sten-multi-string-select__input"
                    onChange={this.setInputValue}
                    onKeyDown={this.handleKeyDown}
                    onFocus={this.onInputFocus}
                    value={this.state.inputValue}
                />
                {this.state.isMenuShown && this.state.filteredOptions.length > 0 && (
                    <Portal>
                        <div style={this.state.selectOptionsStyle} ref={this.saveOptionsRef}>
                            <ScrollArea
                                ref={this.saveScrollRef}
                                className={menuClass}
                                stopScrollPropagation
                                useCSS3Translate={false}
                            >
                                {this.renderOptions()}
                            </ScrollArea>
                        </div>
                    </Portal>
                )}
            </div>
        );
    }
}

MultiStringSelect.propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    fixedListWidth: PropTypes.bool,
    invalid: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.string),
    placeholder: PropTypes.string,
    validateInput: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.string)
};

MultiStringSelect.defaultProps = {
    className: '',
    disabled: false,
    fixedListWidth: false,
    invalid: false,
    options: null,
    placeholder: '',
    validateInput: undefined,
    value: []
};

export default MultiStringSelect;
