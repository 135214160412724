export const ActionTypes = {
    ENERGY_MANAGEMENT_FLEET_AVERAGE_SPEED_REPORT_GENERATE: 'ENERGY_MANAGEMENT_FLEET_AVERAGE_SPEED_REPORT_GENERATE',
    ENERGY_MANAGEMENT_FLEET_AVERAGE_SPEED_REPORT_SET: 'ENERGY_MANAGEMENT_FLEET_AVERAGE_SPEED_REPORT_SET',
    ENERGY_MANAGEMENT_FLEET_AVERAGE_SPEED_REPORT_GENERATE_XLSX:
        'ENERGY_MANAGEMENT_FLEET_AVERAGE_SPEED_REPORT_GENERATE_XLSX',
    ENERGY_MANAGEMENT_FLEET_AVERAGE_SPEED_REPORT_SET_SORT_PROP:
        'ENERGY_MANAGEMENT_FLEET_AVERAGE_SPEED_REPORT_SET_SORT_PROP'
};

export function setSortProp(dispatch, sortProp) {
    dispatch({ type: ActionTypes.ENERGY_MANAGEMENT_FLEET_AVERAGE_SPEED_REPORT_SET_SORT_PROP, sortProp });
}


export function generateXLSX(dispatch) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_FLEET_AVERAGE_SPEED_REPORT_GENERATE_XLSX
    });
}
