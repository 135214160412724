import fastDeepEqual from 'fast-deep-equal';
/* helpers */
import MockHelper from 'utils/helpers/mock-helper';

const cargoStatuses = [
    { Id: 1, Name: 'Ballast' },
    { Id: 2, Name: 'Laden' }
];

const consumptions = [
    { EnumName: 'MainEngineAtSea', Id: 1, Name: 'Main engine at sea' },
    { EnumName: 'AuxEngineAtSea', Id: 2, Name: 'Auxiliary engine at sea' },
    { EnumName: 'CargoHeating', Id: 5, Name: 'Cargo heating' },
    { EnumName: 'Boiler', Id: 8, Name: 'Boiler' },
    { EnumName: 'BallastExchange', Id: 11, Name: 'Ballast exchange' }
];

const eventTypes = [
    { Id: 1, Name: 'Commenced stoppage' },
    { Id: 2, Name: 'Resumed voyage' },
    { Id: 3, Name: 'Some other event' }
];

const exclusions = [
    { Key: 'InstructedSpeed', Value: 'Instructed speed out of range' },
    { Key: 'ReportDate', Value: 'Report date not valid' },
    { Key: 'Position', Value: 'Position not valid' }
];

const fuelTypes = MockHelper.getRandomFuelTypes(1, 4);

const vesselStatuses = [
    { Id: 1, Name: 'At sea' },
    { Id: 2, Name: 'Drifting' },
    { Id: 3, Name: 'Anchored' },
    { Id: 4, Name: 'Maneuvering' },
    { Id: 5, Name: 'At berth' },
    { Id: 6, Name: 'In canal' },
    { Id: 7, Name: 'Idle' }
];

const mockedData = {};

const generateConsumptions = (consumptions, fuelTypes) => (
    MockHelper.getArraySlice(consumptions, 0, 3, true, 0.7).map(consumption => ({
        ...consumption,
        FuelTypeQuantities: MockHelper.getFuelTypeQuantities(0, 1000, 2, fuelTypes)
    }))
);

const generateExclusions = (inclusions) => {
    const data = {
        ExclusionFields: {},
        ExclusionReasons: [],
        IsExcluded: inclusions
            ? MockHelper.getRandomBoolean(0, 0.5)
            : false
    };
    if (data.IsExcluded) {
        const exs = MockHelper.getArraySlice(exclusions, 1, 3, true, 0.1);
        exs.forEach(exclusion => {
            data.ExclusionFields[exclusion.Key] = exclusion.Value;
            data.ExclusionReasons.push(exclusion.Value);
        });
    }
    return data;
};

const generatePosition = () => ({
    Latitude: MockHelper.getRandomNumber(-90, 90, 5),
    Longitude: MockHelper.getRandomNumber(-180, 180, 5)
});

const generateSuspiciousField = () => {
    const isSuspicious = MockHelper.getRandomBoolean(0, 0.6);
    return {
        isSuspicious,
        WrongReportsFields: isSuspicious
            ? { Position: 'Position is not valid for previous reported position' }
            : {}
    };
};

const generateMockedEventReportData = (voyage, inclusions) => {
    const currentDate = new Date();
    const offset = MockHelper.getRandomNumber(-12, 12) * 60;
    const vessel = MockHelper.getRandomVessel();
    const { ExclusionFields, ExclusionReasons, IsExcluded } = generateExclusions(inclusions);
    const { isSuspicious, WrongReportsFields } = generateSuspiciousField();

    return {
        BunkerData: [
            {
                Id: 1,
                Name:
                'ROB',
                FuelTypeQuantities: MockHelper.getFuelTypeQuantities(0, 1000, 2, fuelTypes)
            }
        ],
        CallSign: MockHelper.getRandomCharSet(5).toUpperCase(),
        CargoStatus: MockHelper.getRandomArrayItem(cargoStatuses, 0.01),
        ConsumptionDuringStoppage: generateConsumptions(consumptions, fuelTypes),
        ConsumptionFromNoonToEvent: generateConsumptions(consumptions, fuelTypes),
        DataSourceId: 7,
        EventType: MockHelper.getRandomArrayItem(eventTypes, 0.01),
        ExclusionFields,
        ExclusionReasons,
        Imo: vessel.Imo,
        InstructedSpeed: MockHelper.getRandomNumber(0, 20, 0, null, 0.4) + 0.5,
        isSuspicious,
        IsExcluded,
        LastModifiedBy: MockHelper.getRandomEmail(),
        LastModifiedDate: MockHelper.getRandomDate(currentDate.valueOf()),
        Position: generatePosition(),
        Remarks: MockHelper.getRandomText(),
        ReportDate: currentDate.toISOString(),
        ReportDateOffset: offset,
        ReportId: MockHelper.getRandomNumber(100000, 900000),
        ReportSubtype: { Id: 1, Name: 'Default', DisplayName: 'Default' },
        ReportTypeId: 5,
        Suggestions: null,
        VesselName: vessel.Title,
        VesselStatus: MockHelper.getRandomArrayItem(vesselStatuses, 0.01),
        Voyage: {
            EndDate: null,
            StartDate: null,
            VoyageId: voyage.VoyageId,
            VoyageNumber: voyage.VoyageNumber,
            VoyagePortInfo: null
        },
        VoyageNumber: MockHelper.getRandomNumber(1000, 9000),
        WrongReportsFields
    };
};

const extractFuelTypes = (reports) => {
    const array = [];
    const map = {};
    const keys = ['BunkerData', 'ConsumptionFromNoonToEvent', 'ConsumptionDuringStoppage'];

    reports.forEach(report => {
        keys.forEach(key => {
            report[key].forEach(item => {
                item.FuelTypeQuantities.forEach(fuelTypeQuantity => {
                    if (!map[fuelTypeQuantity.Id]) {
                        const fuelType = {
                            Id: fuelTypeQuantity.Id,
                            Name: fuelTypeQuantity.Name
                        };
                        map[fuelTypeQuantity.Id] = fuelType;
                        array.push(fuelType);
                    }
                });
            });
        });
    });
    return array;
};

const extractConsumptionTypes = (reports, consumptionType) => {
    const array = [];
    const map = {};
    reports.forEach(report => {
        report[consumptionType].forEach(consumption => {
            if (!map[consumption.Id]) {
                const consumptionType = {
                    Id: consumption.Id,
                    Name: consumption.Name
                };
                map[consumption.Id] = consumptionType;
                array.push(consumptionType);
            }
        });
    });
    return array;
};

export const getMockedEventReportData = ({ voyage, inclusions }) => {
    if (mockedData.Voyage !== voyage || !fastDeepEqual(mockedData.Inclusions, inclusions)) {
        mockedData.Voyage = voyage;
        mockedData.Inclusions = inclusions;
        mockedData.Reports = MockHelper.getRandomArrayOfItems(1, 10, null, () => (
            generateMockedEventReportData(voyage, inclusions)
        ));
        mockedData.ConsumptionDuringStoppageTypes = extractConsumptionTypes(
            mockedData.Reports, 'ConsumptionDuringStoppage'
        );
        mockedData.ConsumptionFromNoonToEventTypes = extractConsumptionTypes(
            mockedData.Reports, 'ConsumptionFromNoonToEvent'
        );
        mockedData.AvailableFuelTypes = extractFuelTypes(mockedData.Reports);
    }
    return mockedData;
};

export const getMockedTypesAndGrades = (action) => {
    const data = getMockedEventReportData(action);
    return {
        AvailableReportVariations: [{ DataSourceId: 7, ReportSubtypeId: 1, OrClientVersion: '1.2.1' }],
        AvailableFuelTypes: data.AvailableFuelTypes,
        Types: {
            BunkerData: [{ Id: 1, Name: 'ROB', Unit: 'mt' }],
            ConsumptionDuringStoppage: data.ConsumptionDuringStoppageTypes,
            ConsumptionFromNoonToEvent: data.ConsumptionFromNoonToEventTypes
        }
    };
};
