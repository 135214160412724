import React from 'react';
import { Link } from 'react-router-dom';
/* router */
import { appRoutes } from 'app-router';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { getClassName, formatNumber } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';

const t = (key) => translate(`OPERATORS_DIARY.TABLE.${key}`);

export const timeFrameOptions = [
    {
        Id: 0,
        Title: 'Historical 5 voyages',
        VoyagesPerVessel: 5,
        IncludeFuture: false,
        IncludeHistorical: true
    },
    {
        Id: 1,
        Title: 'Active and future voyages',
        VoyagesPerVessel: null,
        IncludeFuture: true,
        IncludeHistorical: false
    },
    {
        Id: 2,
        Title: 'Latest 5 voyages added',
        VoyagesPerVessel: 5,
        IncludeFuture: true,
        IncludeHistorical: true
    }
];

export const tableColGroups = {
    Itinerary: { id: 'Itinerary', title: t('ITINERARY'), colSpan: 1, style: { textAlign: 'center' } },
    Fixture: { id: 'Fixture', title: t('FIXTURE'), colSpan: 1, style: { textAlign: 'center' } }
};

const renderTimeStatus = (data, prop) => (
    <span
        className={getClassName({
            'sten-operators-diary-table__cell--current': data[prop].isCurrent,
            'sten-operators-diary-table__cell--historic': data[prop].isHistoric
        })}
    >
        {data[prop].value || t('NONE')}
    </span>
);

const renderLegTags = (data) => (
    <div className="sten-operators-diary__badge-wrapper">
        {data.TagCategories.map(category => (
            <div className="sten-operators-diary__badge" key={category.Name}>
                {category.Tag && (
                    <span className="sten-badge" title={category.Name}>{category.Tag.ShortName}</span>
                )}
            </div>
        ))}
    </div>
);

const getBunkerROBFieldRenderer = (field) => (data) =>
    (data.BunkerROBsByRangeGroup[field] ? formatNumber(data.BunkerROBsByRangeGroup[field]) : '-');

const stopLinkClickPropagation = e => e.stopPropagation();

export const tableColumns = [
    {
        id: 0,
        width: 6,
        title: t('VOYAGE'),
        prop: 'VoyageNumber',
        rowGroup: 'Voyage',
        render: data => (
            <Link
                to={`${appRoutes.Vessel.OperatorsDiary}/${data.VoyageId}`}
                onClick={stopLinkClickPropagation}
                className="sten-link"
            >
                {data.VoyageNumber}
            </Link>
        )
    },
    {
        id: 1,
        width: 10,
        title: t('PORT'),
        prop: 'Point.Name',
        colGroup: 'Itinerary',
        rowGroup: 'Port'
    },
    {
        id: 2,
        width: 5,
        title: t('ACTIVITY'),
        prop: 'Code',
        colGroup: 'Itinerary',
        rowGroup: 'Activity',
        render: data => (data.Code
            ? (
                <div className="sten-operators-diary__badge">
                    <span className="sten-badge" title={data.Activity}>{data.Code}</span>
                </div>
            )
            : '-')
    },
    {
        id: 3,
        width: 5,
        title: t('STEAM'),
        prop: 'Steam',
        colGroup: 'Itinerary',
        rowGroup: 'Activity',
        render: data => (data.Steam ? `${formatNumber(data.Steam, 2)}d` : '-')
    },
    {
        id: 4,
        width: 12.5,
        title: t('ETA'),
        prop: 'Eta',
        colGroup: 'Itinerary',
        rowGroup: 'Activity',
        render: data => renderTimeStatus(data, 'EtaState')
    },
    {
        id: 5,
        width: 12,
        title: t('ETB'),
        prop: 'Etb',
        colGroup: 'Itinerary',
        rowGroup: 'Activity',
        render: data => renderTimeStatus(data, 'EtbState')
    },
    {
        id: 6,
        width: 12,
        title: t('ETD'),
        prop: 'Etd',
        colGroup: 'Itinerary',
        rowGroup: 'Activity',
        render: data => renderTimeStatus(data, 'EtdState')
    },
    // {
    //     id: 7,
    //     width: 10,
    //     title: t('AGENT'),
    //     prop: 'AgentName',
    //     colGroup: 'Itinerary',
    //     rowGroup: 'Activity'
    // },
    {
        id: 8,
        width: 10,
        title: t('TAGS'),
        prop: 'Tags',
        colGroup: 'Itinerary',
        rowGroup: 'Activity',
        render: renderLegTags
    },
    {
        id: 9,
        width: 6,
        title: t('LG'),
        prop: 'LG',
        colGroup: 'Itinerary',
        rowGroup: 'Activity',
        render: getBunkerROBFieldRenderer('LiquidGas')
    },
    {
        id: 10,
        width: 6,
        title: t('FO'),
        prop: 'FO',
        colGroup: 'Itinerary',
        rowGroup: 'Activity',
        render: getBunkerROBFieldRenderer('Fuel')
    },
    {
        id: 11,
        width: 6,
        title: t('GO'),
        prop: 'Go',
        colGroup: 'Itinerary',
        rowGroup: 'Activity',
        render: getBunkerROBFieldRenderer('Gas')
    },
    {
        id: 12,
        width: 7,
        title: t('FIX_NUMBER'),
        prop: 'FixtureId',
        colGroup: 'Fixture',
        rowGroup: 'Fixture',
        render: data => (
            <React.Fragment>
                {data.FixtureId && <div className="sten-operators-diary-table__fixture-number">{data.FixtureId}</div>}
                <div className="sten-operators-diary__badge-wrapper">
                    {data.FixtureId && data.Tags.map(tag => (
                        <div
                            key={tag.Id}
                            className={getClassName('sten-operators-diary__badge',
                                { 'sten-operators-diary__badge--subs': tag.Id === 1 })}
                        >
                            <span className="sten-badge" title={tag.CategoryName}>{tag.ShortName}</span>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    },
    {
        id: 13,
        width: 10,
        title: t('COMMODITY'),
        prop: 'Commodity',
        colGroup: 'Fixture',
        rowGroup: 'Fixture'
    },
    {
        id: 14,
        width: 14,
        title: t('CHARTERER_BROKER'),
        prop: 'ChartererName',
        colGroup: 'Fixture',
        rowGroup: 'Fixture',
        render: data => (
            <React.Fragment>
                <div>{`${t('CHARTERER')}: ${data.ChartererName || '-'}`}</div>
                <div>{`${t('BROKER')}: ${data.BrokerName || '-'}`}</div>
            </React.Fragment>
        )
    },
    {
        id: 15,
        width: 5,
        title: t('LAYCAN'),
        prop: 'LayCan',
        colGroup: 'Fixture',
        rowGroup: 'Fixture'
    },
    {
        id: 16,
        width: 24,
        title: t('LATEST_COMMENT'),
        prop: 'LatestComment.DateModified',
        rowGroup: 'Comment',
        render: (data) => {
            const btnClass = `btn-link btn-link--secondary icon icon-message${data.LatestComment ? '' : '-add'}`;
            return (
                <div className="flex-row flex-center">
                    <div className="flex-grow">
                        {data.LatestComment && (
                            <React.Fragment>
                                <h6 className="text-secondary flex-row">
                                    <div className="flex-grow">{data.LatestComment.Author}</div>
                                    <div className="flex-shrink text-nowrap">
                                        {TimeHelper.getFormatted(data.LatestComment.DateModified, { time: true })}
                                    </div>
                                </h6>
                                <p className="text-ellipsis">{data.LatestComment.Text}</p>
                            </React.Fragment>
                        )}
                    </div>
                    { /* TODO: check permissions */ }
                    <div className="flex-shrink">
                        <Link
                            to={{ pathname: `${appRoutes.Vessel.OperatorsDiary}/${data.VoyageId}`, search: 'tabId=3' }}
                            onClick={stopLinkClickPropagation}
                            className={btnClass}
                        />
                    </div>
                </div>
            );
        }
    }
];

export const tableContentStyle = {
    minWidth: `${tableColumns.reduce((res, col) => res + col.width, 0)}rem`
};

let calculatedRowGroups;

export const getRowGroups = () => {
    if (calculatedRowGroups) {
        return calculatedRowGroups;
    }
    const rowGroups = {
        Fixture: [],
        Itinerary: []
    };
    let rowGroup;
    tableColumns.forEach(col => {
        rowGroup = col.rowGroup || 'Other';
        if (!rowGroups[rowGroup]) {
            rowGroups[rowGroup] = [];
        }
        rowGroups[rowGroup].push(col);
    });
    calculatedRowGroups = rowGroups;
    return rowGroups;
};

let calculatedHeaderRows;

export const getHeaderRows = () => {
    if (calculatedHeaderRows) {
        return calculatedHeaderRows;
    }
    const headerRows = [[], []];
    let colGroup;
    tableColumns.forEach((col, index) => {
        if (!col.colGroup) {
            if (colGroup) {
                headerRows[0].push(colGroup);
                colGroup = null;
            }
            headerRows[0].push({
                ...col,
                rowSpan: 2,
                style: { width: `${col.width}rem` },
                className: index > 0 ? 'sten-table__cell--group-start' : ''
            });
        } else {
            if (!colGroup || colGroup.id !== col.colGroup) {
                if (colGroup) {
                    headerRows[0].push(colGroup);
                }
                colGroup = {
                    ...tableColGroups[col.colGroup],
                    className: index > 0 ? 'sten-table__cell--group-start' : ''
                };
            } else {
                colGroup.colSpan++;
            }
            headerRows[1].push({
                ...col,
                style: { width: `${col.width}rem` },
                className: colGroup.colSpan === 1 ? 'sten-table__cell--group-start' : ''
            });
        }
    });
    if (colGroup) {
        headerRows[0].push(colGroup);
    }
    calculatedHeaderRows = headerRows;
    return headerRows;
};
