import { ActionTypes } from './kpi-actions';

const emptyArray = [];

const initialState = {
    isSidebarVisible: false,
    vessels: emptyArray,
    rangeOptions: emptyArray,
    segments: emptyArray,
    filters: {
        selectedRangeOption: null,
        selectedVesselsBySegment: {},
        selectedRangeEnd: null
    },
    defaultFilters: {
        selectedRangeOption: null,
        selectedVesselsBySegment: {},
        selectedRangeEnd: null
    }
};

export default function energyManagementKPIReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ENERGY_MANAGEMENT_KPI_TOGGLE_SIDEBAR: {
        const isSidebarVisible = typeof action.flag === 'boolean' ? action.flag : !state.isSidebarVisible;
        if (!isSidebarVisible) {
            return {
                ...state,
                isSidebarVisible
            };
        }
        return { ...state, isSidebarVisible };
    }
    case ActionTypes.ENERGY_MANAGEMENT_KPI_UPDATE_FILTERS: {
        return {
            ...state,
            filters: action.filters
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_KPI_SET_RANGE_OPTIONS: {
        const now = new Date();
        return {
            ...state,
            rangeOptions: action.rangeOptions,
            filters: {
                ...state.filters,
                selectedRangeOption: (action.rangeOptions && action.rangeOptions[0]) || null,
                selectedRangeEnd: {
                    month: now.getMonth() + 1,
                    year: now.getFullYear()
                }
            },
            defaultFilters: {
                ...state.defaultFilters,
                selectedRangeOption: (action.rangeOptions && action.rangeOptions[0]) || null,
                selectedRangeEnd: {
                    month: now.getMonth() + 1,
                    year: now.getFullYear()
                }
            }
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_KPI_SET_VESSELS: {
        const selectedVesselsBySegment = {};
        const segments = [];
        action.vessels.forEach(vessel => {
            if (!selectedVesselsBySegment[vessel.VesselTypeId]) {
                segments.push({ VesselTypeId: vessel.VesselTypeId, VesselTypeName: vessel.VesselTypeName });
                selectedVesselsBySegment[vessel.VesselTypeId] = {};
            }
            selectedVesselsBySegment[vessel.VesselTypeId][vessel.Imo] = true;
        });

        return {
            ...state,
            segments,
            vessels: action.vessels,
            filters: {
                ...state.filters,
                selectedVesselsBySegment
            },
            defaultFilters: {
                ...state.filters,
                selectedVesselsBySegment
            }
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_KPI_RESET_ALL: {
        return initialState;
    }
    default:
        return state;
    }
}
