import MockHelper from 'utils/helpers/mock-helper';

const ciiGrades = ['A', 'B', 'C', 'D', 'E'];
const fields = ['EEOI', 'AER', 'AERWithCorrections', 'CII', 'CIIWithCorrections'];

export const getMockedVoyageFootprint = (fuelTypes) => {
    const AvailableFuelTypes = fuelTypes || MockHelper.getRandomFuelTypes(2, 4);
    const BallastMiles = MockHelper.getRandomNumber(500, 20000, 0);
    const LadenMiles = MockHelper.getRandomNumber(500, 20000, 0);
    const availableFields = [...fields, ...AvailableFuelTypes.map(ft => `Consumptions.${ft.Id}`)];
    const SuspiciousFields = {};
    MockHelper.getArraySlice(availableFields, 0, 3).forEach(field => {
        SuspiciousFields[field] = MockHelper.getRandomArrayOfItems(1, 2, null, () => MockHelper.getRandomText());
    });
    return new Promise(resolve => {
        const response = {
            SuspiciousFields,
            AvailableFuelTypes,
            Consumptions: MockHelper.getFuelTypeQuantities(0, 60, 1, AvailableFuelTypes),
            EEOI: MockHelper.getRandomNumber(2, 10, 8),
            AER: MockHelper.getRandomNumber(2, 10, 8),
            AERWithCorrections: MockHelper.getRandomNumber(2, 10, 8),
            AverageEmissions: {
                CO: MockHelper.getRandomNumber(0, 0.2, 8),
                N2O: MockHelper.getRandomNumber(0, 0.02, 8),
                CO2: MockHelper.getRandomNumber(10, 200, 8),
                CH4: MockHelper.getRandomNumber(0, 0.002, 8),
                NMVOC: MockHelper.getRandomNumber(0, 0.2, 8)
            },
            TotalEmissions: {
                CO: MockHelper.getRandomNumber(0, 2, 8),
                N2O: MockHelper.getRandomNumber(0, 0.2, 8),
                CO2: MockHelper.getRandomNumber(100, 2000, 8),
                CH4: MockHelper.getRandomNumber(0, 0.02, 8),
                NMVOC: MockHelper.getRandomNumber(0, 2, 8)
            },
            BallastMiles,
            LadenMiles,
            CargoOnBoard: MockHelper.getRandomNumber(5000, 20000),
            CII: MockHelper.getRandomArrayItem(ciiGrades, 0, 0.2),
            CIIWithCorrections: MockHelper.getRandomArrayItem(ciiGrades, 0, 0.2),
            AERWithCorrectionsParameters: {
                TotalCO2: MockHelper.getRandomNumber(100, 2000, 8),
                VesselFactor: MockHelper.getRandomNumber(0, 2, 2),
                VesselDwt: MockHelper.getRandomNumber(5000, 200000),
                TraveledDistance: BallastMiles + LadenMiles
            }
        };
        // eslint-disable-next-line
        console.log('Mocked voyage footprint: ', response);
        resolve(response);
    });
};
