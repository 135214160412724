import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { takeEvery, put, select, delay } from 'redux-saga/effects';
/* actions */
import { ActionTypes } from './fleet-list-report-actions';
/* selectors */
import { getTableData } from './fleet-list-report-selectors';
/* services */
import EnergyManagementService from 'services/core-api/energy-management-service';
import LocalStorageService from 'services/local-storage-service';
import PdfGeneratorService from 'services/core-api/pdf-generator-service';
/* exports */
import generateFleetListXLSX from './export/fleet-list-report-xlsx';
import FleetListReportPDF from './export/fleet-list-report-pdf';

function* getFleetListReport() {
    const { filters } = yield select(state => state.energyManagementReportsReducer);
    const params = {
        IncludedVessels: Object.keys(filters.selectedFleetListVessels).map(imo => parseInt(imo, 10))
    };
    if (!LocalStorageService.isDebugModeActive()) {
        const report = yield EnergyManagementService.Reports.FleetListReport.getReport(params);
        yield put({ type: ActionTypes.ENERGY_MANAGEMENT_FLEET_LIST_REPORT_SET, report });
    }
}

function* prepareReportDataForExport() {
    const fileName = 'Fleet_list_Report';
    const tableData = yield select(state => getTableData(state));
    return { tableData, fileName };
}

function* generateXLSX() {
    yield put({ type: 'TOGGLE_LOADER', payload: true });
    yield delay(0);
    const reportData = yield prepareReportDataForExport();
    yield generateFleetListXLSX(reportData);
    yield put({ type: 'TOGGLE_LOADER', payload: false });
}

function* generatePDF() {
    const data = yield prepareReportDataForExport();
    const htmlString = ReactDOMServer.renderToStaticMarkup(
        <FleetListReportPDF {...data} />
    );

    PdfGeneratorService.convert({ html: htmlString, viewport: { width: 1920, height: 1080 } }, data.fileName);
}

export default function* energyManagementReportsFleetListReportSaga() {
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_FLEET_LIST_REPORT_GENERATE, getFleetListReport);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_FLEET_LIST_REPORT_GENERATE_XLSX, generateXLSX);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_FLEET_LIST_REPORT_GENERATE_PDF, generatePDF);
}
