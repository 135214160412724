import React from 'react';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import moment from 'moment';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* Styles */
import './date-time-picker.scss';

class DateTimePicker extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            timestamp: null,
            date: null
        };

        moment.updateLocale('en', {
            weekdaysMin: 'S_M_T_W_T_F_S'.split('_'),
            week: {
                dow: 1
            }
        });
    }

    setDate = date => {
        const newDate = this.state.date.clone();
        newDate.set({
            year: date.year(),
            month: date.month(),
            date: date.date()
        });
        if (this.props.minDate && newDate.valueOf() < this.props.minDate) {
            this.setState({
                date: this.props.utc ? moment.utc(this.props.minDate) : moment(this.props.minDate)
            });
        } else if (this.props.maxDate && newDate.valueOf() > this.props.maxDate) {
            this.setState({
                date: this.props.utc ? moment.utc(this.props.maxDate) : moment(this.props.maxDate)
            });
        } else {
            this.setState({
                date: newDate
            });
        }
    };

    static getDerivedStateFromProps(props, state) {
        if (props.timestamp !== state.timestamp) {
            let date;
            if (props.timestamp) {
                date = props.utc ? moment.utc(props.timestamp) : moment(props.timestamp);
            } else {
                date = props.utc ? moment.utc().startOf('h') : moment().startOf('h');
                date.add(1, 'h');
                if (props.minDate && date.valueOf() < props.minDate) {
                    date = moment.utc(this.props.minDate);
                } else if (props.maxDate && date.valueOf() > props.maxDate) {
                    date = moment.utc(this.props.maxDate);
                }
            }
            return { date, timestamp: props.timestamp };
        }
        return null;
    }

    updateHours = hours => {
        const newDate = this.state.date.clone();
        newDate.startOf('h').add(hours, 'h');
        this.setState({
            date: newDate
        });
    };

    validate = current => {
        let valid = true;
        if (this.props.minDate) {
            valid = this.props.utc
                ? moment.utc(this.props.minDate).isSameOrBefore(current, 'd')
                : moment(this.props.minDate).isSameOrBefore(current, 'd');
        }
        if (this.props.maxDate) {
            valid = this.props.utc
                ? moment.utc(this.props.maxDate).isSameOrAfter(current, 'd')
                : moment(this.props.maxDate).isSameOrAfter(current, 'd');
        }
        return valid;
    };

    render() {
        let classNames = 'sten-date-time-picker';
        if (this.props.className) {
            classNames += ` ${this.props.className} `;
        }
        const currentTime = this.state.date.valueOf();
        const oneHour = 3600000;
        const disableHourMinus = this.props.minDate !== null && currentTime - oneHour < this.props.minDate;
        const disableHourPlus = this.props.maxDate !== null && currentTime + oneHour > this.props.maxDate;

        return (
            <div className={classNames} onClick={this.props.onClick}>
                <Datetime
                    isValidDate={this.validate}
                    utc={this.props.utc}
                    open
                    input={false}
                    viewMode={this.props.viewMode}
                    value={this.state.date}
                    onChange={this.setDate}
                    locale="en"
                />
                {!this.props.hideTimePicker && (
                    <div className="sten-date-time-picker__time">
                        <div className="flex-row flex-center">
                            <div className="flex-shrink">
                                <button
                                    disabled={disableHourMinus}
                                    className="btn-link btn-link--icon icon icon-minus"
                                    onClick={this.updateHours.bind(this, -1)}
                                />
                            </div>
                            <div className="flex-grow">
                                <span className="sten-date-time-picker__time-clock">
                                    {this.state.date
                                        ? this.state.date.format('HH:mm')
                                        : 'Set time'
                                    }
                                </span>
                            </div>
                            <div className="flex-shrink">
                                <button
                                    disabled={disableHourPlus}
                                    className="btn-link btn-link--icon icon icon-plus"
                                    onClick={this.updateHours.bind(this, 1)}
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div className="sten-date-time-picker__action-controls">
                    <div className="row">
                        <div className="col-12">
                            <button className="btn btn--secondary col-24" onClick={this.props.onCancel}>
                                {translate('GLOBAL.CANCEL')}
                            </button>
                        </div>
                        <div className="col-12">
                            <button
                                className="btn btn--primary col-24"
                                onClick={this.props.onSelect.bind(this, this.state.date)}
                            >
                                {translate('GLOBAL.SELECT')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DateTimePicker.propTypes = {
    className: PropTypes.string,
    hideTimePicker: PropTypes.bool,
    maxDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    minDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onCancel: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    timestamp: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    utc: PropTypes.bool,
    viewMode: PropTypes.string
};

DateTimePicker.defaultProps = {
    className: '',
    hideTimePicker: false,
    maxDate: null,
    minDate: null,
    onClick: undefined,
    timestamp: null,
    utc: false,
    viewMode: 'days'
};

export default DateTimePicker;
