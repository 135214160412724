import CoreApi from './core-api';

class CharterpartyService {
    constructor() {
        this.resourceUrl = 'Charterparties';
    }

    getByVoyageId(voyageId) {
        return CoreApi.get(`${this.resourceUrl}/Voyage/${voyageId}`)
            .then(response => response.data, () => []);
    }

    getOptions() {
        return CoreApi.get(`${this.resourceUrl}/Configuration`)
            .then(response => response.data, () => null);
    }

    setByVoyageId(voyageId, data) {
        return CoreApi.put(`${this.resourceUrl}/Voyage/${voyageId}`, data)
            .then(response => response.data, () => null);
    }
}

export default new CharterpartyService();
