import CoreApi from './core-api';

class SearchService {
    constructor() {
        this.resourceUrl = 'Search';
    }

    getGlobalSearchResults(searchCriteria, limit, pointTypes) {
        return CoreApi.get(`${this.resourceUrl}/Map`, {
            Query: searchCriteria,
            limit,
            pointTypes
        }).then(response => response.data, () => null);
    }

    getVesselsByScope(params) {
        return CoreApi.get(`${this.resourceUrl}/UserVessels`, params)
            .then(response => response.data, () => null);
    }

    getVesselsForFleetListByScope() {
        return CoreApi.get(`${this.resourceUrl}/UserFleetVessels`)
            .then(response => response.data, () => null);
    }

    getAllVesselsByScope(params) {
        return CoreApi.get(`${this.resourceUrl}/AllUserVessels`, params)
            .then(response => response.data, () => null);
    }

    searchAllVessels(params) {
        return CoreApi.get(`${this.resourceUrl}/Vessels`, params)
            .then(response => response.data, () => null);
    }
}

export default new SearchService();
