import { wktParse } from 'components/ol/ol-helpers';
import { sortByProp } from './array-helper';
import { roundNumber } from './info-helper';
import { loremWords, names, lastNames, characters, fuelTypes, vessels } from './mock-helper-constants';

class MockHelper {
    uniqueId = {};

    getUniqueId = (varName, startId = 1) => {
        if (!this.uniqueId[varName]) {
            this.uniqueId[varName] = startId;
        } else {
            this.uniqueId[varName]++;
        }
        return this.uniqueId[varName];
    };

    // ex. getRandomNumber(0, 10, 1, 0.2, 0.5)
    // returns most of values around 5 (0.5 distribution)
    // and in 20% cases (0.2 nullPercentage) value will be null
    getRandomNumber = (min, max, decimals = 0, nullPercentage = 0, distribution = null) => {
        if (nullPercentage && Math.random() < nullPercentage) {
            return null;
        }
        let randomNumber = Math.random();
        if (distribution !== null) {
            const r = randomNumber ** 2;
            randomNumber = (Math.random() * r) + (distribution * (1 - r));
        }
        randomNumber = (randomNumber * (max - min)) + min;
        return decimals ? parseFloat(randomNumber.toFixed(decimals)) : Math.round(randomNumber);
    };

    getRandomBoolean = (nullPercentage = 0, distribution = 0.5) => {
        if (nullPercentage && Math.random() < nullPercentage) {
            return null;
        }
        return Math.random() < distribution;
    };

    // ex. getValueByDistribution({ 'Yes': 0.2, 'No': 0.4 }, ['Yes', 'No'])
    // returns 'Yes' in 20% of cases, 'No' in 40%, and null in remaining 40%
    // distribution values must add up to <= 1
    getValueByDistribution = (distribution, distributionKeys = []) => {
        let res = null;
        const randomNum = Math.random();
        const keys = distribution.length ? distributionKeys : Object.keys(distribution);
        let percentile = 0;
        for (let i = 0; i < keys.length; i++) {
            percentile += distribution[keys[i]];
            if (randomNum < percentile) {
                res = keys[i];
                break;
            }
        }
        return res;
    };

    getArraySlice = (
        array,
        minLength = 0,
        maxLength,
        unique = true,
        lengthDistribution = null,
        valueDistribution = null
    ) => {
        if (!Array.isArray(array)) {
            return array;
        }
        const maxLen = !maxLength || (unique && maxLength > array.length) ? array.length : maxLength;
        const minLen = minLength < 0 || (unique && minLength > array.length) ? 0 : minLength;
        const length = this.getRandomNumber(minLen, maxLen, 0, 0, lengthDistribution);
        const slice = [];
        let randomIndex;
        const usedIndexes = {};
        while (slice.length < length) {
            randomIndex = this.getRandomNumber(0, array.length - 1, 0, 0, valueDistribution);
            if (!unique || !usedIndexes[randomIndex]) {
                usedIndexes[randomIndex] = true;
                slice.push(array[randomIndex]);
            }
        }
        return slice;
    };

    getRandomArrayOfItems = (minLength, maxLength, lengthDistribution = null, getItem) => {
        const length = this.getRandomNumber(minLength, maxLength, 0, 0, lengthDistribution);
        const res = [];
        let prevItem = null;
        for (let i = 0; i < length; i++) {
            if (i > 0) {
                prevItem = res[i - 1];
            }
            res.push(getItem(i, length, prevItem));
        }
        return res;
    };

    getRandomArrayItem = (array, nullPercentage = 0, distribution) => {
        if (nullPercentage && Math.random() < nullPercentage) {
            return null;
        }
        const randomIndex = this.getRandomNumber(0, array.length - 1, 0, 0, distribution);
        return array[randomIndex];
    };

    getRandomWord = () => loremWords[Math.floor(loremWords.length * Math.random())];

    capitalizeWord = (word) => `${word[0].toUpperCase()}${word.slice(1)}`;

    // words - length of whole text
    // sentenceLength - length of a single sentence
    getRandomText = (words = 10, sentenceLength = 10, nullPercentage = 0) => {
        if (nullPercentage && Math.random() < nullPercentage) {
            return null;
        }
        const wordCollection = [];
        let sentenceWordCount = 0;
        let randomWord;
        for (let i = 0; i < words; i++) {
            randomWord = this.getRandomWord();
            sentenceWordCount++;
            if (sentenceWordCount === 1) {
                randomWord = this.capitalizeWord(randomWord);
            }
            if (sentenceWordCount === sentenceLength) {
                randomWord += '.';
                sentenceWordCount = 0;
            }
            wordCollection.push(randomWord);
        }
        return wordCollection.join(' ');
    };

    getRandomTitle = (minWords = 2, maxWords = 5, nullPercentage = 0) => {
        if (nullPercentage && Math.random() < nullPercentage) {
            return null;
        }
        const wordCollection = [];
        const words = this.getRandomNumber(minWords, maxWords);
        for (let i = 0; i < words; i++) {
            wordCollection.push(this.capitalizeWord(this.getRandomWord()));
        }
        return wordCollection.join(' ');
    };

    getRandomCoordinates = ({
        nearPoint,
        deltaX = 0.5,
        deltaY = 0.5,
        xDirection,
        yDirection,
        xLimit,
        yLimit
    }) => {
        const xLim = xLimit || [-180, 180];
        const yLim = yLimit || [-90, 90];
        if (nearPoint) {
            xLim[0] = nearPoint[0] - deltaX;
            xLim[1] = nearPoint[0] + deltaX;
            yLim[0] = nearPoint[1] - deltaY > -90 ? nearPoint[1] - deltaY : -90;
            yLim[1] = nearPoint[1] + deltaY < 90 ? nearPoint[1] + deltaY : 90;
            if (xDirection) {
                xLim[0] = xDirection > 0 ? nearPoint[0] : xLim[0];
                xLim[1] = xDirection < 0 ? nearPoint[0] : xLim[1];
            }
            if (yDirection) {
                yLim[0] = yDirection > 0 ? nearPoint[1] : yLim[0];
                yLim[1] = yDirection < 0 ? nearPoint[1] : yLim[1];
            }
        }
        let xRandom = this.getRandomNumber(xLim[0], xLim[1], 5);
        if (xRandom < -180) {
            xRandom += 360;
        }
        if (xRandom > 180) {
            xRandom -= 360;
        }
        return [xRandom, this.getRandomNumber(yLim[0], yLim[1], 5)];
    };

    getRandomWktPoint = (params) => {
        const randomCoordinates = this.getRandomCoordinates({
            ...params,
            nearPoint: params && params.nearPoint ? wktParse(params.nearPoint) : null
        });
        return `POINT(${randomCoordinates[0]} ${randomCoordinates[1]})`;
    };

    getRandomDate = (minDate = 0, maxDate, nullPercentage = 0, distribution) => {
        if (nullPercentage && Math.random() < nullPercentage) {
            return null;
        }
        const min = new Date(minDate).valueOf();
        const max = maxDate ? new Date(maxDate).valueOf() : new Date().valueOf();
        return new Date(this.getRandomNumber(min, max, 0, 0, distribution)).toISOString();
    };

    getRandomName = (nullPercentage = 0) => {
        if (nullPercentage && Math.random() < nullPercentage) {
            return null;
        }
        const name = names[Math.floor(names.length * Math.random())];
        const lastName = lastNames[Math.floor(lastNames.length * Math.random())];
        return `${name} ${lastName}`;
    };

    getRandomChar = (chars = characters.digits + characters.lowerCaseLetters) =>
        chars.charAt(this.getRandomNumber(0, chars.length - 1));

    getRandomCharSet = (length = 1, chars = characters.digits + characters.lowerCaseLetters) => {
        if (length === 1) {
            return this.getRandomChar(chars);
        }
        let charSet = '';
        for (let i = 0; i < length; i++) {
            charSet += this.getRandomChar(chars);
        }
        return charSet;
    };

    getRandomEmail = () => {
        const name = names[Math.floor(names.length * Math.random())];
        const lastName = lastNames[Math.floor(lastNames.length * Math.random())];
        const domainName = `${this.getRandomWord()}.${this.getRandomCharSet(2, characters.lowerCaseLetters)}`;
        return `${name}.${lastName}@${domainName}`.toLowerCase();
    };

    getRandomUserId = () => {
        return `${this.getRandomCharSet(8)}-${this.getRandomCharSet(4)}`
            + `-${this.getRandomCharSet(4)}-${this.getRandomCharSet(12)}`;
    };

    getRandomComment = (nullPercentage) => {
        if (nullPercentage && Math.random() < nullPercentage) {
            return null;
        }
        return {
            Author: this.getRandomName(),
            AuthorId: this.getRandomUserId(),
            DateModified: this.getRandomDate(),
            Id: this.getRandomNumber(0, 5000),
            Text: this.getRandomText()
        };
    };

    getRandomFuelTypes = (minLength = 1, maxLength = 2) =>
        this.getArraySlice(fuelTypes, minLength, maxLength).sort(sortByProp('Sequence'));

    getFuelTypeQuantities = (min, max, decimals = 0, fuels) => {
        const randomFuelTypes = fuels || this.getRandomFuelTypes(2, 4);
        return randomFuelTypes.map(fuelType => ({ ...fuelType, Quantity: this.getRandomNumber(min, max, decimals) }));
    };

    getVessels = () => vessels;

    getRandomVessel = (vesselList = vessels) => vesselList[this.getRandomNumber(0, vesselList.length - 1)];

    getRandomRouteBetweenTwoPoints = (pointA, pointB, maxDistance = 0.5, chaosConst = 0.05) => {
        const route = [pointA];
        let xDirection;
        let yDirection;
        let currentPoint = pointA;
        let routeNotComplete = true;
        const totalDistanceX = Math.abs(pointA[0] - pointB[0]);
        const totalDistanceY = Math.abs(pointA[1] - pointB[1]);
        const deltaX = totalDistanceX < totalDistanceY ? maxDistance * (totalDistanceX / totalDistanceY) : maxDistance;
        const deltaY = totalDistanceY < totalDistanceX ? maxDistance * (totalDistanceY / totalDistanceX) : maxDistance;
        let deltaXChaos;
        let deltaYChaos;
        let maxLength = Math.round(Math.sqrt((totalDistanceX ** 2) + (totalDistanceY ** 2)) / maxDistance) * 2;
        if (maxLength > 500) {
            maxLength = 500;
        }
        while (routeNotComplete) {
            xDirection = currentPoint[0] < pointB[0] && Math.random() > chaosConst ? 1 : -1;
            yDirection = currentPoint[1] < pointB[1] && Math.random() > chaosConst ? 1 : -1;
            deltaXChaos = Math.random() > chaosConst ? deltaX : maxDistance;
            deltaYChaos = Math.random() > chaosConst ? deltaY : maxDistance;
            currentPoint = this.getRandomCoordinates({
                nearPoint: currentPoint,
                deltaX: deltaXChaos,
                deltaY: deltaYChaos,
                xDirection,
                yDirection
            });
            route.push(currentPoint);
            if ((Math.abs(currentPoint[0] - pointB[0]) < maxDistance
                    && Math.abs(currentPoint[1] - pointB[1]) < maxDistance)
                        || route.length > maxLength) {
                routeNotComplete = false;
            }
        }
        route.push(pointB);
        return route;
    };

    getRandomDivisions = (num, noOfDiv, divDiff = 0.1, decimals = 0) => {
        let sum = 0;
        const divisions = [];
        const min = (num / noOfDiv) * (1 - divDiff);
        const max = (num / noOfDiv) * (1 + divDiff);
        let randomDivision;
        for (let i = 0; i < noOfDiv; i++) {
            if (i < noOfDiv - 1) {
                randomDivision = this.getRandomNumber(min, max, decimals);
                sum += randomDivision;
            } else {
                randomDivision = roundNumber(num - sum, decimals);
            }
            divisions.push(randomDivision);
        }
        return divisions;
    };
}

export default new MockHelper();
