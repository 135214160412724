export const ActionTypes = {
    TRADING_ROUTE_TOOLTIP_SET_POSITIONS: 'SET_TRADING_ROUTES_POSITIONS'
};

export function setTradingRouteTooltipPositions(dispatch, tradingRoutesPositions) {
    dispatch({
        type: ActionTypes.TRADING_ROUTE_TOOLTIP_SET_POSITIONS,
        tradingRoutesPositions
    });
}
