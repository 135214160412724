import { ActionTypes } from './fleet-list-report-actions';

const initialState = {
    report: null
};

export default function energyManagementReportsFleetListReportReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ENERGY_MANAGEMENT_FLEET_LIST_REPORT_SET: {
        return {
            ...state,
            report: action.report
        };
    }
    default:
        return state;
    }
}
