import { createSelector } from 'reselect';
import { createByIdSelector } from 'utils/helpers/selector-helper';

export const getVesselsBySegment = createSelector(
    state => state.energyManagementKPIReducer.vessels,
    (vessels) => {
        return vessels.reduce((sum, vessel) => {
            return {
                ...sum,
                [vessel.VesselTypeId]: sum[vessel.VesselTypeId] ? [...sum[vessel.VesselTypeId], vessel] : [vessel]
            };
        }, {});
    }
);

export const getSegmentsById = createByIdSelector(state => state.energyManagementKPIReducer.segments, 'VesselTypeId');

export const getVesselsById = createByIdSelector(state => state.energyManagementKPIReducer.vessels, 'Imo');
