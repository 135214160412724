import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { mapArrayByValue } from 'utils/helpers/array-helper';
import { formatNumber } from 'utils/helpers/info-helper';
/* components */
import Input from 'components/input/input';
import Validation from 'components/validation/validation';
import FixedHeaderTable from '../../fixed-header-table/fixed-header-table';
/* styles */
import './vessel-report-utilization-table.scss';

const auxRows = [
    { key: 'AuxiliaryRunningHours', label: t('REPORT_LABELS.RUNNING_HOURS_PER_DAY') },
    {
        key: 'AuxiliaryEnergyProduced',
        label: t('REPORT_LABELS.ENERGY_PRODUCED'),
        format: (val) => formatNumber(val, 0)
    }
];

const boilerRows = [
    { key: 'BoilerRunningHours', label: t('REPORT_LABELS.RUNNING_HOURS_PER_DAY') }
];

class VesselReportUtilizationTable extends React.Component {
    getTableHeader = prefix => {
        const heads = [];
        const headStyle = { width: `${100 / this.props.columns}%` };
        for (let i = 0; i < this.props.columns; i++) {
            heads.push(<th style={headStyle} key={i}>{prefix} {i + 1}</th>);
        }
        return heads;
    };

    changeHandlers = {};

    getChangeHandler = (type, index) => {
        if (!this.changeHandlers[type]) {
            this.changeHandlers[type] = {};
        }
        if (!this.changeHandlers[type][index]) {
            this.changeHandlers[type][index] = (value) => {
                const newData = { ...this.props.data };
                newData[type].values[index] = value;
                this.props.onChange(newData);
            };
        }
        return this.changeHandlers[type][index];
    };

    formatVal = (val, format) => {
        const value = format ? format(val) : val;
        return value === null ? '-' : value;
    };

    renderRow = ({ key, label, format }) => (
        <tr key={key}>
            <th className="text-secondary">{label}</th>
            {this.props.data[key].values.map((val, index) => (
                <td
                    className={this.props.onChange ? 'sten-table__td--has-input' : ''}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                >
                    {this.props.onChange
                        ? (
                            <Validation.Wrapper
                                validations={this.props.validations[key]}
                                hintsOnHover
                                hintsOnTop={false}
                                maxHintLines={3}
                            >
                                <Input
                                    className="sten-input--sm"
                                    value={val}
                                    name={`Utilization.${key}.${index}`}
                                    onChange={this.getChangeHandler(key, index)}
                                />
                            </Validation.Wrapper>
                        )
                        : this.formatVal(val, format)}
                </td>
            ))}
        </tr>
    );

    renderSection = (label, headPrefix, rows) => (
        <React.Fragment>
            <thead>
                <tr className="text-secondary">
                    <th>{label}</th>
                    {this.getTableHeader(headPrefix)}
                </tr>
            </thead>
            <tbody>
                {rows.map(this.renderRow)}
            </tbody>
        </React.Fragment>
    );

    render() {
        const { fixedHeaderTableProps, rowKeys } = this.props;
        const rowKeysMap = mapArrayByValue(rowKeys);
        const filteredAuxRows = auxRows.filter(row => rowKeysMap[row.key]);
        const filteredBoilerRows = boilerRows.filter(row => rowKeysMap[row.key]);
        if (!filteredAuxRows.length && !filteredBoilerRows.length) {
            return null;
        }
        return (
            <FixedHeaderTable
                className="sten-vessel-report-utilization-table"
                contentClassName="sten-vessel-report-utilization-table__content"
                {...fixedHeaderTableProps}
                withHeaderColumn
                withHeaderRow={false}
            >
                <table className="sten-table sten-table--xs">
                    {filteredAuxRows.length > 0
                        && this.renderSection(t('REPORT_LABELS.AUXILIARY'), 'Aux', filteredAuxRows)}
                    {filteredBoilerRows.length > 0
                        && this.renderSection(t('REPORT_LABELS.BOILER'), 'Boiler', filteredBoilerRows)}
                </table>
            </FixedHeaderTable>
        );
    }
}

VesselReportUtilizationTable.propTypes = {
    columns: PropTypes.number,
    data: PropTypes.objectOf(PropTypes.any),
    fixedHeaderTableProps: PropTypes.objectOf(PropTypes.any),
    onChange: PropTypes.func,
    rowKeys: PropTypes.arrayOf(PropTypes.string),
    validations: PropTypes.objectOf(PropTypes.any)
};

VesselReportUtilizationTable.defaultProps = {
    columns: 4,
    data: null,
    fixedHeaderTableProps: {
        updateTimeout: 300
    },
    onChange: undefined,
    rowKeys: null,
    validations: null
};

export default VesselReportUtilizationTable;
