import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* components */
import Select from 'components/select/select';
import Input from 'components/input/input';

class AdminUserManagementFilters extends React.PureComponent {
    render() {
        let classNames = 'sten-admin-user-management-filters sten-content__section';
        if (this.props.className) {
            classNames += ` ${this.props.className}`;
        }
        return (
            <div className={classNames}>
                <div className="row">
                    <div className="col-xs-12 col-lg-3 col-md-8">
                        <label className="label">{t('ADMIN.USER_MANAGEMENT.FILTERS.SEARCH')}</label>
                        <Input
                            changeDebounce={500}
                            suffixIcon="icon-search"
                            onChange={this.props.onFilterChange.bind(this, 'emailSearchCriteria')}
                            value={this.props.options.emailSearchCriteria}
                            placeholder={t('ADMIN.USER_MANAGEMENT.FILTERS.SEARCH_BY_EMAIL')}
                        />
                    </div>
                    <div className="col-xs-12 col-lg-4 col-md-8">
                        <label className="label">{t('ADMIN.USER_MANAGEMENT.FILTERS.COMPANY')}</label>
                        <Select
                            onSearch={this.props.onCompaniesSearch}
                            searchable
                            clearable
                            valueKey="Id"
                            labelKey="Name"
                            fixedListWidth
                            options={this.props.options.companies}
                            placeholder={t('ADMIN.USER_MANAGEMENT.FILTERS.SEARCH_BY_COMPANY')}
                            value={this.props.appliedOptions.selectedCompany}
                            onChange={this.props.onFilterChange.bind(this, 'selectedCompany')}
                        />
                    </div>
                    <div className="col-xs-12 col-lg-3 col-md-8 col-pad--sm">
                        <label className="label">{t('ADMIN.USER_MANAGEMENT.FILTERS.ROLE')}</label>
                        <Select
                            clearable
                            searchable
                            valueKey="Id"
                            labelKey="Name"
                            options={this.props.options.roles}
                            placeholder={t('ADMIN.USER_MANAGEMENT.FILTERS.SELECT_ROLE')}
                            value={this.props.appliedOptions.selectedRole}
                            onChange={this.props.onFilterChange.bind(this, 'selectedRole')}
                        />
                    </div>
                    <div className="col-xs-12 col-lg-4 col-md-6 col-pad--md">
                        <label className="label text-ellipsis text-nowrap">
                            {t('ADMIN.USER_MANAGEMENT.FILTERS.DEPARTMENT')}
                        </label>
                        <Select
                            clearable
                            searchable
                            valueKey="Id"
                            labelKey="Name"
                            multiple
                            options={this.props.options.departments}
                            placeholder={t('ADMIN.USER_MANAGEMENT.FILTERS.SELECT_DEPARTMENTS')}
                            value={this.props.appliedOptions.selectedDepartments}
                            onChange={this.props.onFilterChange.bind(this, 'selectedDepartments')}
                        />
                    </div>
                    <div className="col-xs-12 col-lg-3 col-md-6 col-pad--md">
                        <label className="label">{t('ADMIN.USER_MANAGEMENT.FILTERS.STATUS')}</label>
                        <Select
                            clearable
                            searchable
                            valueKey="Id"
                            labelKey="Title"
                            options={this.props.options.statuses}
                            placeholder={t('ADMIN.USER_MANAGEMENT.FILTERS.SELECT_STATUS')}
                            multiple
                            value={this.props.appliedOptions.selectedStatuses}
                            onChange={this.props.onFilterChange.bind(this, 'selectedStatuses')}
                        />
                    </div>
                    <div className="col-xs-12 col-lg-4 col-md-6 col-pad--md">
                        <label className="label">{t('ADMIN.USER_MANAGEMENT.FILTERS.LOCAL_LOGIN')}</label>
                        <Select
                            clearable
                            valueKey="isLocalLoginEnabled"
                            labelKey="title"
                            options={this.props.options.accountTypes}
                            placeholder={t('ADMIN.USER_MANAGEMENT.FILTERS.SELECT_ACCOUNT_TYPE')}
                            value={this.props.appliedOptions.selectedAccountType}
                            onChange={this.props.onFilterChange.bind(this, 'selectedAccountType')}
                        />
                    </div>
                    <div className="col-xs-12 col-lg-3 col-md-6 col-pad--md col-pad--sm">
                        <label className="label text-ellipsis text-nowrap">
                            {t('ADMIN.USER_MANAGEMENT.FILTERS.CONTACT_LIST_VISIBILITY')}
                        </label>
                        <Select
                            clearable
                            valueKey="isVisible"
                            labelKey="title"
                            options={this.props.options.visibilities}
                            placeholder={t('ADMIN.USER_MANAGEMENT.FILTERS.SELECT_VISIBILITY')}
                            value={this.props.appliedOptions.selectedContactListVisibility}
                            onChange={this.props.onFilterChange.bind(this, 'selectedContactListVisibility')}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

AdminUserManagementFilters.propTypes = {
    appliedOptions: PropTypes.objectOf(PropTypes.any).isRequired,
    className: PropTypes.string,
    onFilterChange: PropTypes.func.isRequired,
    options: PropTypes.objectOf(PropTypes.any).isRequired,
    onCompaniesSearch: PropTypes.func.isRequired
};

AdminUserManagementFilters.defaultProps = {
    className: ''
};

export default AdminUserManagementFilters;
