import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* types */
import { TGsmQueryParams } from '../gsm-types';
/* Actions */
import {
    setDrillsLayerVisibility,
    setFerriesLayerVisibility,
    setFerryRoutesLayerVisibility,
    setOfficesLayerVisibility,
    setTankersLayerVisibility
} from './gsm-map-legend-actions';
/* Components */
import MapLegendRow from './gsm-map-legend-row/gsm-map-legend-row';
/* Styles */
import './gsm-map-legend.scss';
import { translate } from 'utils/i18n/i18n-model';
/* Configs */
import config from '../gsm-map/gsm-map-config';

const mapLegendRows = [{
    dataCountKey: 'officesCount',
    layerVisibilityVar: 'officesLayerVisibility',
    initialState: true,
    title: 'GSM.MAP_LEGEND.ROW_ELEMENT_TITLE_OFFICES',
    type: 'office',
    color: config.office.primaryColor
}, {
    dataCountKey: 'tankersCount',
    layerVisibilityVar: 'tankersLayerVisibility',
    initialState: true,
    title: 'GSM.MAP_LEGEND.ROW_ELEMENT_TITLE_TANKERS',
    type: 'tanker',
    color: config.tanker.primaryColor
}, {
    dataCountKey: 'ferriesCount',
    layerVisibilityVar: 'ferriesLayerVisibility',
    initialState: true,
    title: 'GSM.MAP_LEGEND.ROW_ELEMENT_TITLE_FERRIES',
    type: 'ferry',
    color: config.ferry.primaryColor
}, {
    dataCountKey: 'drillsCount',
    layerVisibilityVar: 'drillsLayerVisibility',
    initialState: true,
    title: 'GSM.MAP_LEGEND.ROW_ELEMENT_TITLE_RIGS_DRILL_SHIPS',
    type: 'drill',
    color: config.drill.primaryColor
}, {
    dataCountKey: 'ferryRoutesCount',
    layerVisibilityVar: 'ferryRoutesLayerVisibility',
    initialState: true,
    title: 'GSM.MAP_LEGEND.ROW_ELEMENT_TITLE_FERRY_ROUTES',
    type: 'route',
    color: config.ferryRoute.primaryColor
}];

export class GsmMapLegend extends React.PureComponent {
    componentDidMount() {
        this.setInitialOptionsStates(this.props.router.params);
    }

    setInitialOptionsStates = (params) => {
        for (const row of mapLegendRows) {
            const initialState = params && params.type
                ? params.type === row.type
                : row.initialState;
            this.changeOptionState(row.dataCountKey, initialState);
        }
    }

    clearRightSideBar(dataCountKey) {
        const queryParams = this.props.queryParams;
        if (Object.keys(queryParams).length) {
            if (dataCountKey === 'officesCount' && queryParams.city === null) {
                return;
            }
            if (dataCountKey === 'tankersCount' && queryParams.tanker === null) {
                return;
            }
            if (dataCountKey === 'drillsCount' && queryParams.drill === null) {
                return;
            }
            if (dataCountKey === 'ferriesCount' && queryParams.ferry === null) {
                return;
            }
            this.props.router.clearQueryParams();
        }
    }

    generateRows = () => (
        mapLegendRows.map((element, index) => {
            let row = null;
            if (this.props[element.dataCountKey] !== null) {
                row = (
                    <MapLegendRow
                        checkedState={this.props[element.layerVisibilityVar]}
                        elementCount={this.props[element.dataCountKey]}
                        elementIconColor={element.color}
                        elementTitle={translate(element.title)}
                        elementType={element.type}
                        key={index} // eslint-disable-line react/no-array-index-key
                        onChange={this.changeOptionState.bind(this, element.dataCountKey)}
                    />
                );
            }
            return row;
        })
    );

    changeOptionState = (dataCountKey, value) => {
        switch (dataCountKey) {
        case 'tankersCount':
            this.props.setTankersLayerVisibility(value);
            if (!value) {
                this.clearRightSideBar(dataCountKey);
            }
            break;
        case 'officesCount':
            this.props.setOfficesLayerVisibility(value);
            if (!value) {
                this.clearRightSideBar(dataCountKey);
            }
            break;
        case 'drillsCount':
            this.props.setDrillsLayerVisibility(value);
            if (!value) {
                this.clearRightSideBar(dataCountKey);
            }
            break;
        case 'ferriesCount':
            this.props.setFerriesLayerVisibility(value);
            if (!value) {
                this.clearRightSideBar(dataCountKey);
            }
            break;
        case 'ferryRoutesCount':
            this.props.setFerryRoutesLayerVisibility(value);
            break;

        default:
            break;
        }
    };

    render() {
        return (
            <div className="gsm-map-legend">
                <div className="sten-content">
                    <div className="sten-content__header">
                        <h3 className="text-uppercase">{translate('GSM.MAP_LEGEND.MAP_LEGEND')}</h3>
                    </div>
                    <div className="sten-content__body">
                        <div className="sten-content__section">
                            {this.generateRows()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

GsmMapLegend.propTypes = {
    drillsCount: PropTypes.number.isRequired,
    drillsLayerVisibility: PropTypes.bool.isRequired,
    ferriesCount: PropTypes.number.isRequired,
    ferriesLayerVisibility: PropTypes.bool.isRequired,
    ferryRoutesCount: PropTypes.number.isRequired,
    ferryRoutesLayerVisibility: PropTypes.bool.isRequired,
    officesCount: PropTypes.number.isRequired,
    officesLayerVisibility: PropTypes.bool.isRequired,
    queryParams: TGsmQueryParams.isRequired,
    router: TRouter.isRequired,
    setDrillsLayerVisibility: PropTypes.func.isRequired,
    setFerriesLayerVisibility: PropTypes.func.isRequired,
    setFerryRoutesLayerVisibility: PropTypes.func.isRequired,
    setOfficesLayerVisibility: PropTypes.func.isRequired,
    setTankersLayerVisibility: PropTypes.func.isRequired,
    tankersCount: PropTypes.number.isRequired,
    tankersLayerVisibility: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        drillsCount: state.gsmReducer.drills.length,
        drillsLayerVisibility: state.gsmMapLegendReducer.drillsLayerVisibility,
        ferriesCount: state.gsmReducer.ferries.length,
        ferriesLayerVisibility: state.gsmMapLegendReducer.ferriesLayerVisibility,
        ferryRoutesCount: state.gsmReducer.ferryRoutes.length,
        ferryRoutesLayerVisibility: state.gsmMapLegendReducer.ferryRoutesLayerVisibility,
        officesCount: state.gsmReducer.addressesCount || 0,
        officesLayerVisibility: state.gsmMapLegendReducer.officesLayerVisibility,
        tankersCount: state.gsmReducer.tankers.length,
        tankersLayerVisibility: state.gsmMapLegendReducer.tankersLayerVisibility
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setDrillsLayerVisibility: drillsLayerStatus => setDrillsLayerVisibility(dispatch, drillsLayerStatus),
        setFerriesLayerVisibility: ferriesLayerStatus => setFerriesLayerVisibility(dispatch, ferriesLayerStatus),
        setFerryRoutesLayerVisibility: ferryRoutesLayerStatus =>
            setFerryRoutesLayerVisibility(dispatch, ferryRoutesLayerStatus),
        setOfficesLayerVisibility: officesLayerStatus => setOfficesLayerVisibility(dispatch, officesLayerStatus),
        setTankersLayerVisibility: tankersLayerStatus => setTankersLayerVisibility(dispatch, tankersLayerStatus)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GsmMapLegend));
