import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import ReportHelper from 'utils/helpers/report-helper';
import { t } from 'utils/i18n/i18n-model';
/* components */
import EmptyContent from 'components/empty-content/empty-content';
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
/* actions */
import { getAdvancedOverview, resetAll } from '../overview-actions';
import './advanced.scss';

const tableKeysSorted = [{
    body: [
        'TotalCargo',
        'TotalDistance',
        'NumberOfDaysAtSea',
        'BallastLaden',
        'TotalBunkerPropAuxConsumption',
        'AverageAmountOfCargo',
        'FleetAverageAge',
        'AverageBallastMTPerKnm',
        'AverageLadenMTPerKnm'
    ]
}, {
    head: 'VESSEL_SPEED',
    body: [
        'BallastAverageSpeed',
        'BallastAverageInstructedSpeed',
        'LadenAverageSpeed',
        'LadenAverageInstructedSpeed'
    ]
}, {
    head: 'FUEL_CONSUMPTION_PER_DAY',
    body: [
        'AverageBallastMtDay',
        'AverageLadenMtDay'
    ]
}, {
    head: 'OTHER_DATA',
    body: [
        'GoodTrimBallast',
        'GoodTrimLaden',
        'AuxiliaryRunningHoursPerDay',
        'BoilerRunningHoursPerDay'
    ]
}];

const translationKeys = {
    TotalCargo: 'TOTAL_CARGO',
    TotalDistance: 'TOTAL_DISTANCE',
    NumberOfDaysAtSea: 'NUMBER_OF_DAYS_AT_SEA',
    BallastLaden: 'BALLAST_LADEN',
    TotalBunkerPropAuxConsumption: 'TOTAL_BUNKER_PROP_AUX_CONSUMPTION',
    BallastAverageSpeed: 'BALLAST_AVERAGE_SPEED',
    BallastAverageInstructedSpeed: 'BALLAST_AVERAGE_INSTRUCTED_SPEED',
    LadenAverageSpeed: 'LADEN_AVERAGE_SPEED',
    LadenAverageInstructedSpeed: 'LADEN_AVERAGE_INSTRUCTED_SPEED',
    GoodTrimBallast: 'GOOD_TRIM_BALLAST',
    GoodTrimLaden: 'GOOD_TRIM_LADEN',
    AuxiliaryRunningHoursPerDay: 'AUXILIARY_RUNNING_HOURS_PER_DAY',
    BoilerRunningHoursPerDay: 'BOILER_RUNNING_HOURS_PER_DAY',
    Bunker: 'BUNKER',
    EnergyEfficiency: 'ENERGY_EFFICIENCY_VALUE',
    AverageAmountOfCargo: 'AVERAGE_AMOUNT_OF_CARGO',
    FleetAverageAge: 'FLEET_AVERAGE_AGE',
    AverageBallastMTPerKnm: 'AVERAGE_BALLAST_MT_PER_KNM',
    AverageLadenMTPerKnm: 'AVERAGE_LADEN_MT_PER_KNM',
    AverageBallastMtDay: 'AVERAGE_BALLAST_MT_DAY',
    AverageLadenMtDay: 'AVERAGE_LADEN_MT_DAY'
};

class EnergyManagementKPIAdvanced extends React.PureComponent {
    componentDidMount() {
        if (this.props.filters.selectedRangeOption) {
            this.fetchReportData();
        }
    }

    componentDidUpdate(prevProps) {
        const { params: prevParams } = prevProps.router;
        const { params } = this.props.router;
        if ((prevParams.segmentId !== params.segmentId || this.props.filters !== prevProps.filters)
            && this.props.filters.selectedRangeOption) {
            this.fetchReportData();
        }
    }

    fetchReportData() {
        this.props.getAdvancedOverview(this.props.router.params.segmentId, this.props.filters);
    }

    componentWillUnmount() {
        this.props.resetAll();
    }

    formatField = (rowKey, segment) => {
        const value = segment[rowKey];
        if ((rowKey === 'BallastLaden' || rowKey === 'BallastLadenPrevious') && value !== null) {
            return [
                ReportHelper.formatCellValue(value.BallastPercent),
                ReportHelper.formatCellValue(value.LadenPercent)
            ].join('/');
        }
        return ReportHelper.formatCellValue(value);
    };

    formatPreviousField = (rowKey, segment) => this.formatField(`${rowKey}Previous`, segment);

    render() {
        const { report } = this.props;
        if (!report) {
            return <EmptyContent />;
        }

        return (
            <FixedHeaderTable
                className="sten-content__body sten-kpi-overview-advanced"
                withHeaderColumn
                contentStyle={{ minWidth: `${(report.Items.length * 15) + 22}rem` }}
                contentClassName="sten-kpi-overview-advanced__content"
            >
                <table className="sten-table">
                    <thead>
                        <tr>
                            <th>{t('ENERGY_MANAGEMENT.KPI.SEGMENT.ADVANCED.KEY_TOTAL_FIGURES')}</th>
                            <th>{t('ENERGY_MANAGEMENT.KPI.SEGMENT.ADVANCED.PERIOD')}</th>
                            <th>{t('ENERGY_MANAGEMENT.KPI.SEGMENT.ADVANCED.TOTAL')}</th>
                            {report.Items.map((segment) => (
                                <th key={segment.Id}>
                                    <Link
                                        className="sten-title-link"
                                        to={`segment/${segment.Id}?advanced=true`}
                                        replace
                                    >
                                        {segment.Title}
                                    </Link>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    {tableKeysSorted.map((section, sectionIndex) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <React.Fragment key={sectionIndex}>
                            {section.head ? (
                                <thead>
                                    <tr>
                                        <th>{t(`ENERGY_MANAGEMENT.KPI.SEGMENT.ADVANCED.${section.head}`)}</th>
                                        <th colSpan={report.Items.length + 2} />
                                    </tr>
                                </thead>
                            ) : null}
                            <tbody>
                                {section.body.map((rowKey) => (
                                    <React.Fragment key={rowKey}>
                                        <tr>
                                            <th rowSpan="2">{t(`ENERGY_MANAGEMENT.KPI.SEGMENT.ADVANCED.${
                                                translationKeys[rowKey]
                                            }`)}
                                            </th>
                                            <td>{report.PreviousPeriod}</td>
                                            <td>{this.formatPreviousField(rowKey, report)}</td>
                                            {report.Items.map((segment) => (
                                                <td key={segment.Id}>
                                                    {this.formatPreviousField(rowKey, segment)}
                                                </td>
                                            ))}
                                        </tr>
                                        <tr>
                                            <td>{report.Period}</td>
                                            <td>{this.formatField(rowKey, report)}</td>
                                            {report.Items.map((segment) => (
                                                <td key={segment.Id}>
                                                    {this.formatField(rowKey, segment)}
                                                </td>
                                            ))}
                                        </tr>
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </React.Fragment>
                    ))}
                </table>
            </FixedHeaderTable>
        );
    }
}

EnergyManagementKPIAdvanced.propTypes = {
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    getAdvancedOverview: PropTypes.func.isRequired,
    report: PropTypes.objectOf(PropTypes.any),
    resetAll: PropTypes.func.isRequired,
    router: TRouter.isRequired
};

EnergyManagementKPIAdvanced.defaultProps = {
    report: null
};

function mapStateToProps(state) {
    return {
        report: state.energyManagementKPIOverviewReducer.overviewAdvancedData,
        filters: state.energyManagementKPIReducer.filters
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAdvancedOverview: (segmentId, filters) => getAdvancedOverview(dispatch, segmentId, filters),
        resetAll: () => resetAll(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EnergyManagementKPIAdvanced));
