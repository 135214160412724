import React from 'react';
import PropTypes from 'prop-types';
import ScrollArea from 'components/scroll-area/scroll-area';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { findTotalTopOffset } from 'utils/helpers/html-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import Accordion from 'components/accordion/accordion';
import BoolSelect from 'components/bool-select/bool-select';
import DateRangePopup from 'components/date-range-popup/date-range-popup';
import FlipSwitch from 'components/flip-switch/flip-switch';
import Input from 'components/input/input';
import Select from 'components/select/select';
import Validation from 'components/validation/validation';
import VesselSelectInScope from 'components/vessel-select/vessel-select-in-scope';
import VoyageSelect from 'components/voyage-select/voyage-select';

const labelHeight = 25;

class VoyageReportInclusions extends React.PureComponent {
    handleVesselChange = (vessel) => {
        if (vessel && typeof vessel.InclusionCompanyId === 'number' && this.props.inclusionPresets) {
            const selectedInclusionPreset = this.props.inclusionPresets.find((preset) =>
                preset.Id === vessel.InclusionCompanyId);
            if (selectedInclusionPreset) {
                this.props.setSelectedInclusionPreset(selectedInclusionPreset);
            }
        }
        if (!vessel) {
            this.props.setSelectedInclusionPreset(null);
        }
        this.props.setSelectedVessel(vessel);
    };

    scrollToInvalidField = () => {
        if (this.scrollAreaRef && this.scrollAreaRef.contentWrapperRef) {
            const scrollAreaDiv = this.scrollAreaRef.contentWrapperRef;
            const elements = scrollAreaDiv.getElementsByClassName('sten-validation-wrapper--invalid');

            if (elements && elements[0]) {
                const totalOffsetTop = findTotalTopOffset(elements[0], scrollAreaDiv);
                this.scrollAreaRef.scrollYTo(totalOffsetTop - labelHeight);
            }
        }
    };

    render() {
        const inclusions = this.props.inclusions || {};
        const { setSelectedRange, setInclusionValue, toggleInclusionValue } = this.props;
        const vesselSelectPlaceholder = this.props.selectedVessel
            ? t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.NO_VOYAGES_PLACEHOLDER')
            : t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.NO_VESSEL_PLACEHOLDER');
        return (
            <Validation.Form
                className="sten-energy-management-reports-sidebar-inclusions"
                onSubmit={this.props.onSubmit}
                onInvalid={this.scrollToInvalidField}
            >
                <ScrollArea
                    className="sten-content__body"
                    ref={r => { this.scrollAreaRef = r; }}
                    shouldScrollToActiveElement
                >
                    <div className="sten-content__section">
                        <div className="form-row">
                            <label className="label">{t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.VESSEL')}</label>
                            <Validation.Wrapper validations={{ required: true }}>
                                <VesselSelectInScope
                                    filterLNGVessels
                                    autoFocus
                                    name="vessel"
                                    clearable
                                    value={this.props.selectedVessel}
                                    onChange={this.handleVesselChange}
                                    title={t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.SEARCH_VESSELS_TITLE')}
                                />
                            </Validation.Wrapper>
                        </div>
                        <div className="form-row flex-row">
                            <div className="flex-grow">
                                {this.props.rangeSelectorActive
                                    ? (
                                        <React.Fragment>
                                            <label className="label">
                                                {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.DATA_RANGE')}
                                            </label>
                                            <Validation.Wrapper
                                                validations={{
                                                    dateRange: true,
                                                    dateRangeMax: {
                                                        value: 367 * TimeHelper.units.day,
                                                        message: '366 days'
                                                    }
                                                }}
                                            >
                                                <DateRangePopup
                                                    isUTC
                                                    alignment="end"
                                                    shouldRenderDefaultPresets
                                                    name="dataRange"
                                                    onRangeSelect={setSelectedRange}
                                                    value={this.props.selectedRange}
                                                />
                                            </Validation.Wrapper>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <label className="label">
                                                {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.VOYAGE_NUMBER')}
                                            </label>
                                            <Validation.Wrapper validations={{ required: true }}>
                                                <VoyageSelect
                                                    shouldSelectFirst
                                                    name="voyage"
                                                    vesselId={this.props.selectedVessel?.Imo || null}
                                                    value={this.props.selectedVoyage}
                                                    onChange={this.props.setSelectedVoyage}
                                                    placeholder={vesselSelectPlaceholder}
                                                />
                                            </Validation.Wrapper>
                                        </React.Fragment>
                                    )}
                            </div>
                            {!ConfigService.hiddenFeatures.voyageReportRangeSelector && (
                                <div className="flex-shrink">
                                    <FlipSwitch
                                        // eslint-disable-next-line max-len
                                        className="sten-energy-management-reports-voyage-report-inclusions__range-selector"
                                        doubleOn
                                        value={this.props.rangeSelectorActive}
                                        onChange={this.props.toggleRangeSelector}
                                        activeLabel="Range"
                                        activeTitle="Switch to voyage selector"
                                        inactiveLabel="Voyage"
                                        inactiveTitle="Switch to range selector"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <Accordion
                        header={<h4 className="text-uppercase">{t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.PARAMETERS')}</h4>}
                        isCollapsedBodyRendered
                    >
                        <div className="sten-content__section">
                            <div className="form-row">
                                <label className="label">{t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.PRESET')}</label>
                                <Validation.Wrapper validations={{ required: true }}>
                                    <Select
                                        name="fleet"
                                        options={this.props.inclusionPresets}
                                        labelKey="Name"
                                        valueKey="Id"
                                        value={this.props.selectedInclusionPreset}
                                        onChange={this.props.setSelectedInclusionPreset}
                                        placeholder={t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.SELECT_PRESET_PLACEHOLDER')}
                                    />
                                </Validation.Wrapper>
                            </div>
                        </div>
                        <div className="sten-content__section">
                            <div className="form-row">
                                <div className="flex-row flex-center">
                                    <div className="col-10">
                                        <label className="label">
                                            {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.INSTRUCTED_SPEED_BALLAST')}
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <Validation.Wrapper
                                            hintsOnHover
                                            validations={{
                                                required: !!this.props.selectedInclusionPreset,
                                                numeric: { min: 0, max: 99, maxProp: 'InstructedSpeedBallastMax' }
                                            }}
                                        >
                                            <Input
                                                prefix="min"
                                                name="InstructedSpeedBallastMin"
                                                value={inclusions.InstructedSpeedBallastMin}
                                                onChange={setInclusionValue('InstructedSpeedBallastMin')}
                                                disabled={!this.props.selectedInclusionPreset}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                    <div className="col-7">
                                        <Validation.Wrapper
                                            hintsOnHover
                                            validations={{
                                                required: !!this.props.selectedInclusionPreset,
                                                numeric: { min: 0, max: 99, minProp: 'InstructedSpeedBallastMin' }
                                            }}
                                        >
                                            <Input
                                                prefix="max"
                                                name="InstructedSpeedBallastMax"
                                                value={inclusions.InstructedSpeedBallastMax}
                                                onChange={setInclusionValue('InstructedSpeedBallastMax')}
                                                disabled={!this.props.selectedInclusionPreset}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">
                                        {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.INSTRUCTED_SPEED_LADEN')}
                                    </label>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset,
                                            numeric: { min: 0, max: 99, maxProp: 'InstructedSpeedLadenMax' }
                                        }}
                                    >
                                        <Input
                                            prefix="min"
                                            name="InstructedSpeedLadenMin"
                                            value={inclusions.InstructedSpeedLadenMin}
                                            onChange={setInclusionValue('InstructedSpeedLadenMin')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset,
                                            numeric: { min: 0, max: 99, minProp: 'InstructedSpeedLadenMin' }
                                        }}
                                    >
                                        <Input
                                            prefix="max"
                                            name="InstructedSpeedLadenMax"
                                            value={inclusions.InstructedSpeedLadenMax}
                                            onChange={setInclusionValue('InstructedSpeedLadenMax')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="flex-row flex-center">
                                    <div className="col-10">
                                        <label className="label">
                                            {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.ACTUAL_SPEED_BALLAST')}
                                        </label>
                                    </div>
                                    <div className="col-7">
                                        <Validation.Wrapper
                                            hintsOnHover
                                            validations={{
                                                required: !!this.props.selectedInclusionPreset,
                                                numeric: { min: 0, max: 99, maxProp: 'ActualSpeedBallastMax' }
                                            }}
                                        >
                                            <Input
                                                prefix="min"
                                                name="ActualSpeedBallastMin"
                                                value={inclusions.ActualSpeedBallastMin}
                                                onChange={setInclusionValue('ActualSpeedBallastMin')}
                                                disabled={!this.props.selectedInclusionPreset}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                    <div className="col-7">
                                        <Validation.Wrapper
                                            hintsOnHover
                                            validations={{
                                                required: !!this.props.selectedInclusionPreset,
                                                numeric: { min: 0, max: 99, minProp: 'ActualSpeedBallastMin' }
                                            }}
                                        >
                                            <Input
                                                prefix="max"
                                                name="ActualSpeedBallastMax"
                                                value={inclusions.ActualSpeedBallastMax}
                                                onChange={setInclusionValue('ActualSpeedBallastMax')}
                                                disabled={!this.props.selectedInclusionPreset}
                                            />
                                        </Validation.Wrapper>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">
                                        {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.ACTUAL_SPEED_LADEN')}
                                    </label>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset,
                                            numeric: { min: 0, max: 99, maxProp: 'ActualSpeedLadenMax' }
                                        }}
                                    >
                                        <Input
                                            prefix="min"
                                            name="ActualSpeedLadenMin"
                                            value={inclusions.ActualSpeedLadenMin}
                                            onChange={setInclusionValue('ActualSpeedLadenMin')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset,
                                            numeric: { min: 0, max: 99, minProp: 'ActualSpeedLadenMin' }
                                        }}
                                    >
                                        <Input
                                            prefix="max"
                                            name="ActualSpeedLadenMax"
                                            value={inclusions.ActualSpeedLadenMax}
                                            onChange={setInclusionValue('ActualSpeedLadenMax')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                        </div>
                        <div className="sten-content__section sten-content__separator sten-content__separator--inner">
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">
                                        {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.CARGO_CARRIED')}
                                    </label>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset,
                                            numeric: { min: 0, max: 999999, maxProp: 'CargoCarriedMax' }
                                        }}
                                    >
                                        <Input
                                            prefix="min"
                                            name="CargoCarriedMin"
                                            value={inclusions.CargoCarriedMin}
                                            onChange={setInclusionValue('CargoCarriedMin')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset,
                                            numeric: { min: 0, max: 999999, minProp: 'CargoCarriedMin' }
                                        }}
                                    >
                                        <Input
                                            prefix="max"
                                            name="CargoCarriedMax"
                                            value={inclusions.CargoCarriedMax}
                                            onChange={setInclusionValue('CargoCarriedMax')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">
                                        {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.LOADING_TIME')}
                                    </label>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset,
                                            numeric: { decimals: 0, min: 0, max: 99999, maxProp: 'LoadingTimeMax' }
                                        }}
                                    >
                                        <Input
                                            prefix="min"
                                            name="LoadingTimeMin"
                                            value={inclusions.LoadingTimeMin}
                                            onChange={setInclusionValue('LoadingTimeMin')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset,
                                            numeric: { decimals: 0, min: 0, max: 99999, minProp: 'LoadingTimeMin' }
                                        }}
                                    >
                                        <Input
                                            prefix="max"
                                            name="LoadingTimeMax"
                                            value={inclusions.LoadingTimeMax}
                                            onChange={setInclusionValue('LoadingTimeMax')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">
                                        {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.DISCHARGING_TIME')}
                                    </label>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset,
                                            numeric: {
                                                decimals: 0,
                                                min: 0,
                                                max: 99999,
                                                maxProp: 'DischargingTimeMax'
                                            }
                                        }}
                                    >
                                        <Input
                                            prefix="min"
                                            name="DischargingTimeMin"
                                            value={inclusions.DischargingTimeMin}
                                            onChange={setInclusionValue('DischargingTimeMin')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset,
                                            numeric: {
                                                decimals: 0,
                                                min: 0,
                                                max: 99999,
                                                minProp: 'DischargingTimeMin'
                                            }
                                        }}
                                    >
                                        <Input
                                            prefix="max"
                                            name="DischargingTimeMax"
                                            value={inclusions.DischargingTimeMax}
                                            onChange={setInclusionValue('DischargingTimeMax')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">
                                        {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.CARGO_DISCHARGED')}
                                    </label>
                                </div>
                                <div className="col-14">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset,
                                            numeric: { min: 0, max: 999999, decimals: 0 }
                                        }}
                                    >
                                        <Input
                                            prefix="min"
                                            name="CargoDischargedMin"
                                            value={inclusions.CargoDischargedMin}
                                            onChange={setInclusionValue('CargoDischargedMin')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="flex-grow">
                                    <label className="label">
                                        {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.INCLUDE_HEAT')}
                                    </label>
                                </div>
                                <div className="flex-shrink">
                                    <FlipSwitch
                                        value={inclusions.IncludeHeat || false}
                                        activeLabel={t('GLOBAL.YES')}
                                        inactiveLabel={t('GLOBAL.NO')}
                                        disabled={!this.props.selectedInclusionPreset}
                                        onChange={toggleInclusionValue('IncludeHeat')}
                                    />
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="flex-grow">
                                    <label className="label">
                                        {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.CARGO_HEATED_DURING_OPS')}
                                    </label>
                                </div>
                                <div className="flex-shrink">
                                    <FlipSwitch
                                        value={inclusions.IncludeCargoHeatedDuringOps || false}
                                        activeLabel={t('GLOBAL.YES')}
                                        inactiveLabel={t('GLOBAL.NO')}
                                        disabled={!this.props.selectedInclusionPreset}
                                        onChange={toggleInclusionValue('IncludeCargoHeatedDuringOps')}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="sten-content__section sten-content__separator sten-content__separator--inner">
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">
                                        {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.SEA_PASSAGE')}
                                    </label>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset,
                                            numeric: { min: 0, max: 99999, maxProp: 'SeaPassageMax' }
                                        }}
                                    >
                                        <Input
                                            prefix="min"
                                            name="SeaPassageMin"
                                            value={inclusions.SeaPassageMin}
                                            onChange={setInclusionValue('SeaPassageMin')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="col-7">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset,
                                            numeric: { min: 0, max: 99999, minProp: 'SeaPassageMin' }
                                        }}
                                    >
                                        <Input
                                            prefix="max"
                                            name="SeaPassageMax"
                                            value={inclusions.SeaPassageMax}
                                            onChange={setInclusionValue('SeaPassageMax')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">
                                        {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.HOURS_STEAMED')}
                                    </label>
                                </div>
                                <div className="col-14">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset,
                                            numeric: { min: 0, max: 25, decimals: 0 }
                                        }}
                                    >
                                        <Input
                                            prefix="min"
                                            name="HoursSteamedMin"
                                            value={inclusions.HoursSteamedMin}
                                            onChange={setInclusionValue('HoursSteamedMin')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-10">
                                    <label className="label">{t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.WIND_FORCE')}</label>
                                </div>
                                <div className="col-14">
                                    <Validation.Wrapper
                                        hintsOnHover
                                        validations={{
                                            required: !!this.props.selectedInclusionPreset,
                                            numeric: { min: 0, max: 12, decimals: 0 }
                                        }}
                                    >
                                        <Input
                                            prefix="max"
                                            name="WindForceMax"
                                            value={inclusions.WindForceMax}
                                            onChange={setInclusionValue('WindForceMax')}
                                            disabled={!this.props.selectedInclusionPreset}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </div>
                            <div className="form-row flex-row flex-center">
                                <div className="col-17">
                                    <label className="label">
                                        {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.VESSEL_UNDERWAY_USING_ME')}
                                    </label>
                                </div>
                                <div className="col-7">
                                    <BoolSelect
                                        clearable
                                        disabled={!this.props.selectedInclusionPreset}
                                        name="IncludeVesselUnderwayUsingMe"
                                        onChange={setInclusionValue('IncludeVesselUnderwayUsingMe')}
                                        value={inclusions.IncludeVesselUnderwayUsingMe}
                                    />
                                </div>
                            </div>
                        </div>
                    </Accordion>
                </ScrollArea>
                <footer className="sten-content__footer flex-row">
                    <div className="col-12">
                        <button
                            onClick={this.props.resetInclusions}
                            className="btn btn--secondary col-24"
                            type="button"
                        >
                            {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.RESET_PARAMETERS')}
                        </button>
                    </div>
                    <div className="col-12">
                        <Validation.Button className="btn btn--primary col-24">
                            {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.CALCULATE')}
                        </Validation.Button>
                    </div>
                </footer>
            </Validation.Form>
        );
    }
}

VoyageReportInclusions.propTypes = {
    inclusionPresets: PropTypes.arrayOf(PropTypes.object).isRequired,
    inclusions: PropTypes.objectOf(PropTypes.any),
    onSubmit: PropTypes.func.isRequired,
    rangeSelectorActive: PropTypes.bool.isRequired,
    resetInclusions: PropTypes.func.isRequired,
    selectedInclusionPreset: PropTypes.objectOf(PropTypes.any),
    selectedRange: PropTypes.objectOf(PropTypes.any),
    selectedVessel: PropTypes.objectOf(PropTypes.any),
    selectedVoyage: PropTypes.objectOf(PropTypes.any),
    setInclusionValue: PropTypes.func.isRequired,
    setSelectedInclusionPreset: PropTypes.func.isRequired,
    setSelectedRange: PropTypes.func.isRequired,
    setSelectedVessel: PropTypes.func.isRequired,
    setSelectedVoyage: PropTypes.func.isRequired,
    toggleInclusionValue: PropTypes.func.isRequired,
    toggleRangeSelector: PropTypes.func.isRequired
};

VoyageReportInclusions.defaultProps = {
    inclusions: null,
    selectedInclusionPreset: null,
    selectedRange: null,
    selectedVessel: null,
    selectedVoyage: null
};

export default VoyageReportInclusions;
