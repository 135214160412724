import { ActionTypes } from './questionnaire-actions';

const emptyArray = [];

const initialState = {
    questionnaire: null,
    editingOptions: {
        bunkerTypes: emptyArray
    },
    areEditingOptionsFetched: false
};

export default function questionnaireReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.QUESTIONNAIRE_SET: {
        return {
            ...state,
            questionnaire: action.questionnaire
        };
    }
    case ActionTypes.QUESTIONNAIRE_SET_EDITING_OPTIONS: {
        return {
            ...state,
            editingOptions: { ...action.editingOptions },
            areEditingOptionsFetched: true
        };
    }
    case ActionTypes.QUESTIONNAIRE_RESET_ALL: {
        return {
            ...initialState,
            editingOptions: state.editingOptions,
            areEditingOptionsFetched: state.areEditingOptionsFetched
        };
    }
    default:
        return state;
    }
}
