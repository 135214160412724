import { translate } from 'utils/i18n/i18n-model';
import { getObjectProp, swedishCompare } from 'utils/helpers/info-helper';
import { createSelector } from 'reselect';
import createByIdSelector from 'utils/helpers/selector-helper';

const emptyArray = [];
const etaColumnId = 4;

const userSettings = state => state.userReducer.settings;

export const selectedVisibleGrouping = createSelector(
    userSettings,
    settings => (settings && settings.PositionsListFilterSelectedGroupBy
        ? settings.PositionsListFilterSelectedGroupBy : 2)
);

export const selectedExportGrouping = createSelector(
    userSettings,
    settings => (settings && settings.PositionsListFilterSelectedExportGroupBy
        ? settings.PositionsListFilterSelectedExportGroupBy : 2)
);

const calculateGroupedVessels = (positionListVessels, vesselsList, selectedGrouping, positionListTableReducer) => {
    const vessels = vesselsList ? vesselsList.slice() : positionListVessels.slice();
    const sortOrdering = positionListTableReducer.sortOrder === 'DESC' ? -1 : 1;
    const sortProp = positionListTableReducer.sortProp;
    vessels.sort((a, b) => {
        const valueA = getObjectProp(a, sortProp);
        const valueB = getObjectProp(b, sortProp);
        let result = 0;
        if (valueA === null || valueA === undefined || valueA < valueB) {
            result = -1;
        }
        if (valueB === null || valueB === undefined || valueA > valueB) {
            result = 1;
        }
        return result * sortOrdering;
    });

    const groupedVessels = {};
    if (selectedGrouping === 1) {
        if (vessels && vessels.length) {
            vessels.forEach((vessel) => {
                let areaName = vessel.AreaName;
                if (!vessel.AreaName) {
                    areaName = translate('POSITION_LIST.UNMAPPED');
                }
                if (!groupedVessels[areaName]) {
                    groupedVessels[areaName] = [];
                }
                groupedVessels[areaName].isExpanded = true;
                groupedVessels[areaName].title = translate('POSITION_LIST.REGION');
                groupedVessels[areaName].push(vessel);
            });
        }
    } else if (selectedGrouping === 2 && vessels && vessels.length) {
        vessels.forEach((vessel) => {
            if (!groupedVessels[vessel.VesselSegment]) {
                groupedVessels[vessel.VesselSegment] = [];
            }
            groupedVessels[vessel.VesselSegment].title = translate('POSITION_LIST.SEGMENT');
            groupedVessels[vessel.VesselSegment].push(vessel);
        });
    }
    return groupedVessels;
};

export const getGroupedVessels = createSelector(
    state => state.positionListReducer.vessels,
    (state, vesselsList) => vesselsList,
    selectedVisibleGrouping,
    state => state.positionListTableReducer,
    calculateGroupedVessels
);

export const getExportGroupedVessels = createSelector(
    state => state.positionListReducer.vessels,
    (state, vesselsList) => vesselsList,
    selectedExportGrouping,
    state => state.positionListTableReducer,
    calculateGroupedVessels
);

const colWidthMapDefaults = {
    1: 27, // vessel name
    2: 10, // open date
    3: 14, // next open
    4: 16, // selected port eta
    5: 21, // remaining itinerary
    6: 12, // last sire
    7: 24, // last cargoes
    8: 24, // note
    9: 13, // fixture status
    10: 18, // coating
    11: 24, // internal note
    12: 24 // load ready date
};

const getColumns = (state, args) => {
    if (args) {
        return args.columns;
    }
    return null;
};

const getColWidths = (state, args) => {
    if (args) {
        return args.colWidths;
    }
    return null;
};

const getTableColumns = state => state.positionListReducer.tableColumns;

const getPositionListVisibleColumns = (state) => {
    if (state.userReducer.settings) {
        return state.userReducer.settings.PositionsListVisibleColumns;
    }
    return emptyArray;
};

const getPositionListVisibleExportColumns = (state) => {
    if (state.userReducer.settings) {
        return state.userReducer.settings.PositionsListExportColumns;
    }
    return emptyArray;
};

const calculateVisibleColumns = (columns, colWidths, tableColumns, PositionsListVisibleColumns) => {
    let colWidthMap = colWidthMapDefaults;

    if (colWidths) {
        colWidthMap = {
            ...colWidthMap,
            ...colWidths
        };
    }

    const visibleColumns = columns || PositionsListVisibleColumns;

    const tc = tableColumns.filter(
        column => (visibleColumns.indexOf(column.ColumnId) > -1) || column.alwaysVisible
    );

    return tc.map(tableColumn => ({
        ...tableColumn,
        width: colWidthMap[tableColumn.ColumnId]
    }));
};

const calculateVisibleExportColumns = (
    columns,
    colWidths,
    tableColumns,
    PositionsListVisibleColumns,
    PositionsListExportColumns
) => {
    let colWidthMap = colWidthMapDefaults;

    if (colWidths) {
        colWidthMap = {
            ...colWidthMap,
            ...colWidths
        };
    }

    const visibleColumns = columns || PositionsListExportColumns;
    const isEtaSelectedInVisibleColumns = PositionsListVisibleColumns.indexOf(etaColumnId) !== -1;

    const tc = tableColumns.filter(
        (column) => {
            // if ETA is not selected on position list visible columns, then don't show it on export columns
            if (!isEtaSelectedInVisibleColumns && column.ColumnId === etaColumnId) {
                return false;
            }

            return (visibleColumns.indexOf(column.ColumnId) > -1) || column.alwaysVisible;
        }
    );

    return tc.map(tableColumn => ({
        ...tableColumn,
        width: colWidthMap[tableColumn.ColumnId]
    }));
};

export const getVisibleColumns = createSelector(
    getColumns,
    getColWidths,
    getTableColumns,
    getPositionListVisibleColumns,
    calculateVisibleColumns
);

export const getVisibleExportColumns = createSelector(
    getColumns,
    getColWidths,
    getTableColumns,
    getPositionListVisibleColumns,
    getPositionListVisibleExportColumns,
    calculateVisibleExportColumns
);

export const getRegionsSortedAlphabetically = createSelector(
    state => state.positionListFiltersReducer.regions,
    regions => regions.slice().sort((regionA, regionB) => swedishCompare(regionA.Name, regionB.Name))
);

export const getSelectedGrouping = createSelector(
    state => state.userReducer.settings,
    state => state.positionListReducer.groupings,

    (settings, groupings) => {
        const selectedGrouping = settings && settings.PositionsListFilterSelectedGroupBy
            ? settings.PositionsListFilterSelectedGroupBy
            : null;
        return groupings.find(group => group.FilterId === selectedGrouping);
    }
);

export const getSelectedExportGrouping = createSelector(
    state => state.userReducer.settings,
    state => state.positionListReducer.groupings,

    (settings, groupings) => {
        const selectedGrouping = settings && settings.PositionsListFilterSelectedExportGroupBy
            ? settings.PositionsListFilterSelectedExportGroupBy
            : null;
        return groupings.find(group => group.FilterId === selectedGrouping);
    }
);

export const getFiltersChanged = createSelector(
    state => state.userReducer.settings,

    (settings) => {
        const filterSettings = [
            'PositionsListFilterSelectedCompanies',
            'PositionListSelectedVesselGroups',
            'PositionListRegionsIds',
            'VesselDefaultSpeed'
        ];
        let filtersChanged = false;
        if (settings) {
            for (let i = 0; i < filterSettings.length; i++) {
                if (settings[filterSettings[i]] && settings[filterSettings[i]].length > 0) {
                    filtersChanged = true;
                    break;
                }
            }
        }
        return filtersChanged;
    }
);

export const getSelectedSignature = createSelector(
    state => state.userReducer.settings,
    state => state.positionListComposeReducer.signatures,

    (settings, signatures) => {
        const signature = signatures.find(signature => signature.Id === settings.PositionListSignatureCompanyId);
        if (signature) {
            return {
                id: signature.Id,
                title: signature.Name
            };
        }
        return null;
    }
);

export const getSelectSignatureCompanies = createSelector(
    state => state.positionListComposeReducer.signatures,
    signatures => signatures.map(signature => ({ id: signature.Id, title: signature.Name }))
);

export const getVesselInfoConfigMap = createByIdSelector(
    state => state.positionListReducer.vesselInfoConfigurableProperties, 'Id'
);

export default getVisibleColumns;
