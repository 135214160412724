import ApiHelper from './gsm-api';

class GsmVesselListService {
    constructor() {
        this.resourceUrl = 'Overview';
    }

    get() {
        return ApiHelper.get(`${this.resourceUrl}`)
            .then(response => response.data, () => null);
    }
}

export default new GsmVesselListService();
