export const ActionTypes = {
    BERTH_INFO_SET_COMMENTS: 'BERTH_INFO_SET_COMMENTS',
    BERTH_INFO_GET_RESOURCES: 'BERTH_INFO_GET_RESOURCES',
    BERTH_INFO_SET_RESOURCES: 'BERTH_INFO_SET_RESOURCES',
    BERTH_INFO_UPDATED: 'BERTH_INFO_UPDATED',
    BERTH_INFO_RESET: 'BERTH_INFO_RESET'
};

export function getResources(dispatch, berthId) {
    dispatch({ type: ActionTypes.BERTH_INFO_GET_RESOURCES, berthId });
}

export function berthUpdated(dispatch, id, berth) {
    dispatch({ type: ActionTypes.BERTH_INFO_UPDATED, id, berth });
}

export function reset(dispatch) {
    dispatch({ type: ActionTypes.BERTH_INFO_RESET });
}
