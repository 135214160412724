import { ActionTypes } from './gsm-right-side-bar-secondary-actions';

const initialState = {
    isExpanded: false,
    activePanelName: ''
};

export default function gsmRightSideBarSecondaryReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.GSM_RIGHT_SIDE_BAR_SECONDARY_COLLAPSE: {
        return {
            ...state,
            isExpanded: false,
            activePanelName: ''
        };
    }
    case ActionTypes.GSM_RIGHT_SIDE_BAR_SECONDARY_SET_ACTIVE_PANEL: {
        return {
            ...state,
            activePanelName: action.panelName,
            isExpanded: action.expand
        };
    }
    default:
        return state;
    }
}
