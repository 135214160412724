import React from 'react';
import PropTypes from 'prop-types';
import fastDeepEqual from 'fast-deep-equal';
import LinesEllipsis from 'react-lines-ellipsis';
/* rules */
import rules from './validation-rules';
/* context */
import { ValidationContext } from './validation-form';

/* TODO Add should component update */

class ValidationWrapper extends React.PureComponent {
    constructor(props, context) {
        super(props, context);

        const child = props.children;

        const isCheckbox = typeof child.props.isChecked === 'boolean';
        const checkboxValue = child.props.isChecked ? child.props.value : '';

        this.state = {
            stateChangeCount: 0,
            hintsOnTop: false,
            validate: props.validate,
            validations: props.validations,
            value: isCheckbox ? checkboxValue : child.props.value,
            isCheckbox,
            isChecked: isCheckbox ? !!child.props.isChecked : true
        };
    }

    componentDidMount() {
        this.props.context.register(this);
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
        let stateChanged = false;
        if (props.children.props.value !== state.value
            || (state.isCheckbox && props.children.props.isChecked !== state.isChecked)) {
            newState.isChecked = state.isCheckbox ? !state.isChecked : true;
            const checkboxValue = newState.isChecked ? props.children.props.value : '';

            newState.value = state.isCheckbox ? checkboxValue : props.children.props.value;
            stateChanged = true;
        }
        if (props.validate !== state.validate) {
            newState.validate = props.validate;
            stateChanged = true;
        }
        if (props.shouldTrackValidations && !fastDeepEqual(props.validations, state.validations)) {
            newState.validations = props.validations;
            stateChanged = true;
        }
        if (stateChanged) {
            newState.stateChangeCount = state.stateChangeCount + 1;
            return newState;
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.stateChangeCount !== this.state.stateChangeCount) {
            this.props.context.validateState();
        }
        if (prevProps.children.props.name !== this.props.children.props.name) {
            this.props.context.unregister(prevProps.children.props.name);
            this.props.context.register(this);
        }
    }

    componentWillUnmount() {
        this.props.context.unregister(this.props.children.props.name);
    }

    handleMouseEnter = () => {
        if (this.props.hintsOnHover && this.validationWrapper) {
            const boundingBox = this.validationWrapper.getBoundingClientRect();
            if (typeof this.props.hintsOnTop === 'boolean') {
                if (this.props.hintsOnTop !== this.state.hintsOnTop) {
                    this.setState({ hintsOnTop: this.props.hintsOnTop });
                }
            } else {
                const hintsOnTop = boundingBox.top > window.innerHeight / 2;
                if (hintsOnTop !== this.state.hintsOnTop) {
                    this.setState({ hintsOnTop });
                }
            }
        }
    };

    renderHints = (hints, hintType) => {
        const className = hintType === 'error'
            ? 'sten-validation-wrapper__errors'
            : 'sten-validation-wrapper__warnings';
        return (
            <div className={className}>
                {this.props.maxHintLines
                    ? (
                        <LinesEllipsis
                            text={hints.join('; ')}
                            maxLine={this.props.maxHintLines}
                            className="text-left text-ellipsis"
                        />
                    ) : hints.join('; ')
                }
            </div>
        );
    };

    saveRef = (c) => { this.validationWrapper = c; };

    getHints = (keys) => {
        const hints = [];
        if (keys && keys.length > 0) {
            const { name, value } = this.props.children.props;
            const components = this.props.context.components;
            const changedValue = this.state.isCheckbox ? value : this.state.value;
            keys.forEach((key) => {
                if (typeof message === 'function') { // used with static errors
                    hints.push(key(changedValue, components));
                } else {
                    const validationRule = rules[key] || rules.custom;
                    hints.push(
                        validationRule.hint(changedValue, this.props.validations[key], components, name)
                    );
                }
            });
        }
        return hints;
    };

    render() {
        let classNames = 'sten-validation-wrapper';
        if (this.props.hintsOnHover) {
            classNames += ' sten-validation-wrapper--hints-on-hover';
            if (this.state.hintsOnTop) {
                classNames += ' sten-validation-wrapper--hints-on-top';
            }
            if (this.props.hintsInset) {
                classNames += ' sten-validation-wrapper--hints-inset';
            }
        }
        if (this.props.className) {
            classNames += ` ${this.props.className}`;
        }
        if (!this.props.context.showErrors && !this.props.hideWhileValid) {
            return (
                <div className={classNames}>{this.props.children}</div>
            );
        }
        const name = this.props.children.props.name;
        const errors = this.props.context.errors[name];
        const errorHints = this.getHints(errors);
        const warnings = this.props.context.warnings[name];
        const warningHints = this.getHints(warnings);
        if (this.props.hideWhileValid && (!errorHints.length && !warningHints.length)) {
            return null;
        }
        const isInvalid = !!warnings || !!errors;
        const isWarning = !errors && !!warnings;

        const child = React.cloneElement(this.props.children, {
            invalid: isInvalid,
            warning: isWarning,
            isChecked: this.state.isChecked
        });

        if (isInvalid) {
            classNames += ' sten-validation-wrapper--invalid';
        }
        if (isWarning) {
            classNames += ' sten-validation-wrapper--warning';
        }

        return (
            <div className={classNames} ref={this.saveRef} onMouseEnter={this.handleMouseEnter}>
                {child}
                {!this.props.hideHints && (errorHints.length > 0 || warningHints.length > 0) && (
                    <div className="sten-validation-wrapper__hints">
                        {errorHints.length > 0 && this.renderHints(errorHints, 'error')}
                        {warningHints.length > 0 && this.renderHints(warningHints, 'warning')}
                    </div>
                )}
            </div>
        );
    }
}

ValidationWrapper.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    context: PropTypes.objectOf(PropTypes.any).isRequired,
    hideHints: PropTypes.bool,
    hintsInset: PropTypes.bool,
    hideWhileValid: PropTypes.bool,
    hintsOnHover: PropTypes.bool,
    hintsOnTop: PropTypes.bool,
    maxHintLines: PropTypes.number,
    shouldTrackValidations: PropTypes.bool,
    validate: PropTypes.bool,
    validations: PropTypes.objectOf(PropTypes.any)
};

ValidationWrapper.defaultProps = {
    children: null,
    className: '',
    hideHints: false,
    hideWhileValid: false,
    hintsOnHover: false,
    hintsInset: false,
    hintsOnTop: undefined,
    maxHintLines: null,
    shouldTrackValidations: false,
    validate: true,
    validations: null
};

export default props => (
    <ValidationContext.Consumer>
        {context => <ValidationWrapper {...props} context={context} />}
    </ValidationContext.Consumer>
);
