import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* styles */
import './avatar.scss';

const supportedFileTypes = ['image/jpeg', 'image/png'];

class Avatar extends React.PureComponent {
    getBase64 = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.props.handleImageUpload(reader.result.substring(reader.result.indexOf(',') + 1), file.name);
        };
        reader.onerror = (error) => {
            throw new Error(error);
        };
    };

    uploadImage = () => {
        if (this.props.isEditable) {
            this.fileInput.click();
        }
    };

    handleImageUpload = event => {
        if (!event.target.files[0]) {
            return;
        }
        if (!supportedFileTypes.find(type => type === event.target.files[0].type)) {
            toast(translate('ACCOUNT.UNSUPPORTED_FORMAT_MESSAGE'), { type: toast.TYPE.WARNING });
            return;
        }
        if (event.target.files[0].size > 10 * (2 ** 20)) {
            toast(translate('ACCOUNT.UNSUPPORTED_FILE_SIZE'), { type: toast.TYPE.WARNING });
            return;
        }
        this.getBase64(event.target.files[0]);
    };

    getAvatar = () => {
        if (this.props.profileImageUrl) {
            return (
                <div
                    className="sten-avatar__placeholder"
                    style={{ backgroundImage: `url("${this.props.profileImageUrl}")` }}
                />
            );
        }
        return <div className="sten-avatar__placeholder" />;
    };

    saveRef = c => { this.fileInput = c; };

    render() {
        let avatarClassName = 'sten-avatar';
        avatarClassName += ` sten-avatar--${this.props.size}`;
        if (this.props.className) {
            avatarClassName += ` ${this.props.className}`;
        }
        if (this.props.isEditable) {
            avatarClassName += ' sten-avatar__placeholder--editable';
        }
        return (
            <div className={avatarClassName} onClick={this.uploadImage}>
                <input
                    type="file"
                    className="sten-avatar__editable-input"
                    ref={this.saveRef}
                    onChange={this.handleImageUpload}
                />
                {this.getAvatar()}
            </div>
        );
    }
}

Avatar.propTypes = {
    className: PropTypes.string,
    handleImageUpload: PropTypes.func,
    isEditable: PropTypes.bool,
    profileImageUrl: PropTypes.string,
    size: PropTypes.oneOf(['sm', 'md', 'lg'])
};

Avatar.defaultProps = {
    className: '',
    handleImageUpload: undefined,
    isEditable: false,
    profileImageUrl: '',
    size: 'sm'
};

export default Avatar;
