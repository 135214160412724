import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
/* utils */
import { getClassName } from 'utils/helpers/info-helper';
/*  chartConfigs */
import getConsumptionConfig from './chart-configs/consumption-config';
import getDailyAverageSpeedConfig from './chart-configs/daily-average-speed-config';
import getWeatherConfig from './chart-configs/weather-config';
import getEmissionConfig from './chart-configs/emission-config';
/* components */
import EmptyContent from 'components/empty-content/empty-content';
import { createSynchronizedCharts } from 'components/chart/chart';
/* styles */
import './chart-panel.scss';

const { SharedStateProvider, SynchronizedChart } = createSynchronizedCharts();
const initialState = { min: null, max: null };

class VoyagePerformanceChartPanel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            chartsKey: 1,
            chartData: props.chartData
        };
    }

    static getDerivedStateFromProps(props, state) {
        return props.chartData !== state.chartData
            ? {
                chartsKey: state.chartsKey + 1,
                chartData: props.chartData
            }
            : null;
    }

    onChartPointClick = (chartType, event) => {
        const { chartData } = this.props;
        const reportIdData = chartData[chartType].reportIdData;
        this.props.updateQueryParams({
            add: { reportId: reportIdData[event.point.category], reportType: 1 }
        });
    };

    render() {
        const { startDate, endDate, className } = this.props;
        const { chartData, chartsKey } = this.state;
        let chartMin = endDate && moment.utc(endDate).isBefore(moment.utc())
            ? moment.utc(endDate).subtract(15, 'd').startOf('d')
            : moment.utc().subtract(15, 'd').startOf('d');

        if (chartMin.isBefore(moment.utc(startDate))) {
            chartMin = moment.utc(startDate).startOf('d');
        }
        chartMin = chartMin.valueOf();

        return (
            <div className={getClassName('sten-voyage-performance-chart-panel sten-panel', className)}>
                {chartData ? (
                    <SharedStateProvider initialState={initialState} key={chartsKey}>
                        <SynchronizedChart
                            highchartsMore
                            config={getDailyAverageSpeedConfig()}
                            options={{
                                plotOptions: {
                                    series: {
                                        cursor: 'pointer',
                                        point: {
                                            events: {
                                                click: this.onChartPointClick.bind(this, 'dailyAverageSpeedChart')
                                            }
                                        },
                                        marker: {
                                            states: {
                                                hover: {
                                                    radiusPlus: 4
                                                }
                                            }
                                        }
                                    }
                                },
                                xAxis: { min: chartMin }
                            }}
                            series={chartData.dailyAverageSpeedChart.series}
                            categories={chartData.dailyAverageSpeedChart.categories}
                            manageResetZoom
                        />
                        <div className="sten-panel__separator" />
                        <SynchronizedChart
                            highchartsMore
                            config={getConsumptionConfig()}
                            options={{
                                plotOptions: {
                                    series: {
                                        cursor: 'pointer',
                                        point: {
                                            events: {
                                                click: this.onChartPointClick.bind(this, 'consumptionChart')
                                            }
                                        },
                                        marker: {
                                            states: {
                                                hover: {
                                                    radiusPlus: 4
                                                }
                                            }
                                        }
                                    }
                                },
                                xAxis: { min: chartMin }
                            }}
                            series={chartData.consumptionChart.series}
                            categories={chartData.consumptionChart.categories}
                            manageResetZoom
                        />
                        <div className="sten-panel__separator" />
                        <SynchronizedChart
                            highchartsMore
                            config={getEmissionConfig()}
                            options={{
                                plotOptions: {
                                    series: {
                                        cursor: 'pointer',
                                        point: {
                                            events: {
                                                click: this.onChartPointClick.bind(this, 'emissionChart')
                                            }
                                        },
                                        marker: {
                                            states: {
                                                hover: {
                                                    radiusPlus: 4
                                                }
                                            }
                                        }
                                    }
                                },
                                xAxis: { min: chartMin }
                            }}
                            series={chartData.emissionChart.series}
                            categories={chartData.emissionChart.categories}
                            manageResetZoom
                        />
                        <div className="sten-panel__separator" />
                        <SynchronizedChart
                            config={getWeatherConfig()}
                            options={{
                                plotOptions: {
                                    series: {
                                        cursor: 'pointer',
                                        point: {
                                            events: {
                                                click: this.onChartPointClick.bind(this, 'weatherChart')
                                            }
                                        },
                                        marker: {
                                            states: {
                                                hover: {
                                                    radiusPlus: 4
                                                }
                                            }
                                        }
                                    }
                                },
                                xAxis: { min: chartMin }
                            }}
                            series={chartData.weatherChart.series}
                            categories={chartData.weatherChart.categories}
                            manageResetZoom
                        />
                    </SharedStateProvider>
                ) : <EmptyContent className="flex-grow" />}
            </div>
        );
    }
}

VoyagePerformanceChartPanel.propTypes = {
    chartData: PropTypes.objectOf(PropTypes.any),
    className: PropTypes.string,
    endDate: PropTypes.string,
    updateQueryParams: PropTypes.func.isRequired,
    startDate: PropTypes.string
};

VoyagePerformanceChartPanel.defaultProps = {
    className: '',
    chartData: null,
    endDate: '',
    startDate: ''
};

export default VoyagePerformanceChartPanel;
