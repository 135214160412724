import { takeEvery, put, select } from 'redux-saga/effects';
/* actions */
import { ActionTypes } from './position-list-actions';
/* services */
import PositionListService from 'services/core-api/position-list-service';

const columnsOrder = [1, 9, 2, 12, 3, 4, 5, 6, 10, 7, 8, 11];
const sortableColumns = [1, 2, 3, 4, 5, 6, 10, 12];

const columnPermissionsMap = {
    11: 'ShowInternalNotes'
};
const sortPropsMap = {
    3: 'OpenPort.Name',
    5: 'NextPorts.length',
    12: 'LoadReadyDate.LoadReadyDate'
};
function* getTableColumns() {
    const permissions = yield select(state => state.userReducer.permissions);
    const response = yield PositionListService.getTableColumns();
    if (response && response.length > 0) {
        const tableColumns = [];
        columnsOrder.forEach((columnId) => {
            const tableColumn = response.find(column => column.ColumnId === columnId);
            if (tableColumn
                && (!columnPermissionsMap[tableColumn.ColumnId]
                    || permissions[columnPermissionsMap[tableColumn.ColumnId]])) {
                tableColumns.push({
                    ...tableColumn,
                    alwaysVisible: tableColumn.ColumnId === 1,
                    sortProp: sortPropsMap[tableColumn.ColumnId] || tableColumn.ColumnPropertyName,
                    sortable: sortableColumns.findIndex(sortableColumnId => sortableColumnId === tableColumn.ColumnId)
                    >= 0
                });
            }
        });
        yield put({ type: ActionTypes.POSITION_LIST_SET_TABLE_COLUMNS, tableColumns });
    }
}

function* getGroupings() {
    const groupings = yield PositionListService.getGroupings();
    if (groupings) {
        yield put({ type: ActionTypes.POSITION_LIST_SET_GROUPINGS, groupings });
    }
}

function* vesselInfoConfigurableProperties() {
    const vesselInfoConfigurableProperties = yield PositionListService.getVesselInfoConfigurableProperties();
    if (vesselInfoConfigurableProperties) {
        yield put({
            type: ActionTypes.POSITION_LIST_SET_VESSEL_INFO_CONFIGURABLE_PROPERTIES,
            vesselInfoConfigurableProperties
        });
    }
}

function* getVessels() {
    const selectedPort = yield select(state => state.positionListFiltersReducer.selectedPort);
    const params = {};
    if (selectedPort && selectedPort.Id) {
        params.Id = selectedPort.Id;
    }
    const vessels = yield PositionListService.getVessels(params);
    yield put({ type: ActionTypes.POSITION_LIST_SET_VESSELS, vessels });
}

function* deleteLoadReadyDate(action) {
    yield PositionListService.deleteLoadReadyDate(action.IMO);
    yield getVessels();
}

function* updateLoadReadyDate(action) {
    yield PositionListService.updateLoadReadyDate(
        action.IMO,
        action.LoadReadyDate
    );
    yield getVessels();
}

function* submitNote(action) {
    const noteForSubmit = {
        Imo: action.imo,
        Comment: action.note,
        CommentType: action.isInternal ? 'InternalNote' : 'Note'
    };
    let submittedNote;
    if (action.id) {
        noteForSubmit.id = action.id;
        submittedNote = yield PositionListService.updateNote(noteForSubmit);
    } else {
        submittedNote = yield PositionListService.submitNote(noteForSubmit);
    }
    if (submittedNote) {
        yield put({
            type: ActionTypes.POSITION_LIST_UPDATE_VESSEL_NOTE,
            submittedNote,
            vesselImo: action.imo,
            isInternal: action.isInternal
        });
    }
}

function* removeNote(action) {
    const removedNote = yield PositionListService.removeNote(action.id);
    if (removedNote) {
        yield put({
            type: ActionTypes.POSITION_LIST_UPDATE_VESSEL_NOTE,
            submittedNote: null,
            vesselImo: action.imo,
            isInternal: action.isInternal
        });
    }
}

function* getFixtureStatuses() {
    const fixtureStatuses = yield PositionListService.getFixtureStatuses();
    yield put({ type: ActionTypes.POSITION_LIST_SET_FIXTURE_STATUSES, fixtureStatuses });
}

function* setFixtureStatus(action) {
    const fixedFeature = yield PositionListService.setFixtureStatus(action.vesselId, {
        FixtureStatusId: action.fixtureStatus.FixtureStatusId
    });
    if (fixedFeature) {
        const oldVessels = yield select(state => state.positionListReducer.vessels);
        const vessels = oldVessels.map((vessel) => {
            if (vessel.IMO === action.vesselId) {
                return {
                    ...vessel,
                    FixtureStatus: action.fixtureStatus
                };
            }
            return vessel;
        });
        yield put({ type: ActionTypes.POSITION_LIST_SET_VESSELS, vessels });
    }
}

export default function* positionListSaga() {
    yield takeEvery(
        ActionTypes.POSITION_LIST_GET_VESSEL_INFO_CONFIGURABLE_PROPERTIES,
        vesselInfoConfigurableProperties
    );
    yield takeEvery(ActionTypes.POSITION_LIST_GET_TABLE_COLUMNS, getTableColumns);
    yield takeEvery(ActionTypes.POSITION_LIST_GET_GROUPINGS, getGroupings);
    yield takeEvery(ActionTypes.POSITION_LIST_GET_VESSELS, getVessels);
    yield takeEvery(ActionTypes.POSITION_LIST_SUBMIT_VESSEL_NOTE, submitNote);
    yield takeEvery(ActionTypes.POSITION_LIST_REMOVE_VESSEL_NOTE, removeNote);
    yield takeEvery(ActionTypes.POSITION_LIST_GET_FIXTURE_STATUSES, getFixtureStatuses);
    yield takeEvery(ActionTypes.POSITION_LIST_SET_VESSEL_FIXTURE_STATUS, setFixtureStatus);
    yield takeEvery(ActionTypes.POSITION_LIST_DELETE_LOAD_READY_DATE, deleteLoadReadyDate);
    yield takeEvery(ActionTypes.POSITION_LIST_UPDATE_LOAD_READY_DATE, updateLoadReadyDate);
}
