export const ActionTypes = {
    ENERGY_MANAGEMENT_KPI_VESSEL_GET_DATA: 'ENERGY_MANAGEMENT_KPI_VESSEL_GET_DATA',
    ENERGY_MANAGEMENT_KPI_VESSEL_SET_DATA: 'ENERGY_MANAGEMENT_KPI_VESSEL_SET_DATA',
    ENERGY_MANAGEMENT_KPI_VESSEL_RESET_ALL: 'ENERGY_MANAGEMENT_KPI_VESSEL_RESET_ALL'
};

export function getData(dispatch, imo, filters) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_KPI_VESSEL_GET_DATA,
        imo,
        filters
    });
}

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_KPI_VESSEL_RESET_ALL
    });
}
