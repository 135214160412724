import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ScrollArea from 'components/scroll-area/scroll-area';
/* actions */
import { generateCargoXLSX, generateCargoPDF } from '../vessel-info-actions';
/* components */
import Accordion from 'components/accordion/accordion';
import { TankLayout } from 'components/tank-layout';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
import { formatNumber } from 'utils/helpers/info-helper';
import { getPanelRows } from 'utils/helpers/template-helper';
/* styles */
import './vessel-info-cargo.scss';

export class VesselInfoCargo extends React.PureComponent {
    getFixtures() {
        const columnClasses = ['col-8', 'col-16'];
        const fixtures = [];
        this.props.selectedVoyageFixtures.forEach((fixture) => {
            let cargoes = (
                <div className="sten-panel__section"><p>{translate('VESSEL_INFO.CARGO.NO_CARGO_INFORMATION')}</p></div>
            );
            if (fixture.Cargoes) {
                cargoes = fixture.Cargoes.map((cargo, index) => {
                    const rows = [];
                    rows.push([
                        <label className="sten-panel__label">
                            {translate('VESSEL_INFO.CARGO.CHARTERPARTY_DATE')}
                        </label>,
                        <label className="sten-panel__label sten-panel__label--value">
                            {cargo.CharterpartyDate
                                ? TimeHelper.getFormatted(cargo.CharterpartyDate, { utc: true })
                                : '-'
                            }
                        </label>
                    ]);
                    rows.push([
                        <label className="sten-panel__label">{translate('VESSEL_INFO.CARGO.COMMODITY')}</label>,
                        <label className="sten-panel__label sten-panel__label--value">
                            {cargo.Commodity || '-'}
                        </label>
                    ]);
                    rows.push([
                        <label className="sten-panel__label">{translate('VESSEL_INFO.CARGO.QUANTITY')}</label>,
                        <label className="sten-panel__label sten-panel__label--value">
                            {typeof cargo.Quantity === 'number'
                                ? `${formatNumber(cargo.Quantity, 0)} ${translate('UNITS.CARGO')}`
                                : '-'}
                        </label>
                    ]);
                    if (cargo.LoadPortName) {
                        rows.push([
                            <label className="sten-panel__label">{translate('VESSEL_INFO.CARGO.LOAD_PORT')}</label>,
                            <label className="sten-panel__label sten-panel__label--value">
                                {cargo.LoadPortName}
                            </label>
                        ]);
                    } else if (cargo.LoadPointName) {
                        rows.push([
                            <label className="sten-panel__label">{translate('VESSEL_INFO.CARGO.LOAD_POINT')}</label>,
                            <label className="sten-panel__label sten-panel__label--value">
                                {cargo.LoadPointName}
                            </label>
                        ]);
                    }
                    if (cargo.DischargePortName) {
                        rows.push([
                            <label className="sten-panel__label">
                                {translate('VESSEL_INFO.CARGO.DISCHARGE_PORT')}
                            </label>,
                            <label className="sten-panel__label sten-panel__label--value">
                                {cargo.DischargePortName}
                            </label>
                        ]);
                    } else if (cargo.DischargePointName) {
                        rows.push([
                            <label className="sten-panel__label">
                                {translate('VESSEL_INFO.CARGO.DISCHARGE_POINT')}
                            </label>,
                            <label className="sten-panel__label sten-panel__label--value">
                                {cargo.DischargePointName}
                            </label>
                        ]);
                    }
                    if (cargo.Broker) {
                        rows.push([
                            <label className="sten-panel__label">{translate('VESSEL_INFO.CARGO.BROKER')}</label>,
                            <label className="sten-panel__label sten-panel__label--value">
                                {cargo.Broker}
                            </label>
                        ]);
                    }
                    if (rows.length === 0) {
                        return null;
                    }
                    let className = 'sten-panel__section';
                    if (index !== 0) {
                        className += ' sten-panel__separator';
                    }
                    return <div key={cargo.CargoId} className={className}>{getPanelRows(columnClasses, rows)}</div>;
                });
            }
            let header = <h4 key="title" className="text-uppercase">{fixture.ChartererAlias}</h4>;
            if (!this.props.showVoyageInfo) {
                header = (
                    <div className="flex-grow">
                        <h4 key="title" className="text-uppercase">{fixture.ChartererAlias}</h4>
                        <p key="subtitle" className="text-secondary">
                            {`${translate('VESSEL_INFO.CARGO.VOYAGE')} ${fixture.VoyageNumber}
                                ${translate('VESSEL_INFO.CARGO.FROM')}
                                ${TimeHelper.getFormatted(fixture.StartDate)}
                                ${translate('VESSEL_INFO.CARGO.TO')}
                                ${TimeHelper.getFormatted(fixture.EndDate)}`}
                        </p>
                    </div>
                );
            }
            fixtures.push(
                <Accordion
                    key={fixture.FixtureId}
                    className="sten-panel sten-vessel-info-cargo__fixture"
                    header={header}
                    chevronRight
                >
                    {cargoes}
                </Accordion>
            );
        });
        return fixtures;
    }

    render() {
        const isTankLayout = this.props.selectedVoyageTankLayout.length > 0;
        let scrollAreaClass = 'sten-vessel-info-cargo__body';
        if (isTankLayout) {
            scrollAreaClass += ' sten-vessel-info-cargo__body--with-footer';
        }

        return (
            <div className="sten-vessel-info-cargo">
                <ScrollArea className={scrollAreaClass}>
                    {isTankLayout
                        ? (
                            <TankLayout
                                cargoSummary={this.props.selectedVoyageCargoSummary}
                                wings={this.props.selectedVoyageTankLayout}
                                showPreviousCommodities
                            />
                        )
                        : (<div className="sten-content__section">{this.getFixtures()}</div>)
                    }
                </ScrollArea>
                {isTankLayout && (
                    <footer className="sten-content__footer">
                        <div className="flex-grow">
                            <div className="row">
                                <div className="col-12">
                                    <button
                                        onClick={this.props.generateCargoPDF}
                                        className="btn btn--secondary text-uppercase col-24"
                                    >
                                        {translate('CARGO_EXPORT.EXPORT_PDF')}
                                    </button>
                                </div>
                                <div className="col-12">
                                    <button
                                        onClick={this.props.generateCargoXLSX}
                                        className="btn btn--secondary text-uppercase col-24"
                                    >
                                        {translate('CARGO_EXPORT.EXPORT_XLSX')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </footer>
                )}
            </div>
        );
    }
}

VesselInfoCargo.propTypes = {
    generateCargoXLSX: PropTypes.func.isRequired,
    generateCargoPDF: PropTypes.func.isRequired,
    selectedVoyageFixtures: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedVoyageTankLayout: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedVoyageCargoSummary: PropTypes.objectOf(PropTypes.any),
    showVoyageInfo: PropTypes.bool
};

VesselInfoCargo.defaultProps = {
    selectedVoyageCargoSummary: null,
    showVoyageInfo: false
};

function mapStateToProps(state) {
    return {
        selectedVoyageFixtures: state.homeReducer.selectedVoyageFixtures,
        selectedVoyageCargoSummary: state.homeReducer.selectedVoyageCargoSummary,
        selectedVoyageTankLayout: state.homeReducer.selectedVoyageTankLayout
    };
}

function mapDispatchToProps(dispatch) {
    return {
        generateCargoXLSX: () => generateCargoXLSX(dispatch),
        generateCargoPDF: () => generateCargoPDF(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VesselInfoCargo);
