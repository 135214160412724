/* utils */
import { t } from 'utils/i18n/i18n-model';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import { getFootprintRows as fT_getFootprintRows } from 'components/footprint-table/footprint-table-constants';
import React from 'react';

export const badges = [
    { key: 'included', className: 'sten-badge', label: t('RAW_REPORTS_TABLE.INCLUDED') },
    { key: 'excluded', className: 'sten-badge sten-badge--alt', label: t('RAW_REPORTS_TABLE.EXCLUDED') },
    { key: 'missing', className: 'sten-badge sten-badge--danger', label: t('RAW_REPORTS_TABLE.MISSING') },
    {
        key: 'suspicious',
        dataMissingKey: 'suspiciousLimitReached',
        className: 'sten-badge sten-badge--warning',
        label: t('RAW_REPORTS_TABLE.SUSPICIOUS')
    },
    {
        key: 'veracitySyncError',
        className: 'sten-badge sten-badge--error',
        label: t('RAW_REPORTS_TABLE.VERACITY_SYNC_ERROR')
    }
];

const commonRows = {
    averageConsumption: { type: 'number', unit: t('UNITS.AVG_CONSUMPTION'), numFmt: '0.0 "mt/day"' },
    distance: { type: 'number', unit: t('UNITS.DISTANCE'), numFmt: '0.0 "NM"' },
    duration: { type: 'duration', multiplier: 3600000, displayFormat: ['h', 'm'] },
    speed: { type: 'number', unit: t('UNITS.SPEED'), numFmt: '0.0 "kn"' },
    speedArray: { type: 'array', unit: t('UNITS.SPEED') },
    totalConsumption: { type: 'number', unit: t('UNITS.CONSUMPTION'), numFmt: '0.0 "mt"' }
};

export const ballastSummaryRows = [
    {
        ...commonRows.speedArray,
        key: 'InstructedSpeed',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.INSTRUCTED_SPEED') },
    {
        ...commonRows.speed,
        key: 'AverageSpeed',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.AVERAGE_SPEED') },
    {
        ...commonRows.duration,
        key: 'TotalTime',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.TOTAL_TIME') },
    {
        ...commonRows.distance,
        key: 'TotalObsDistance',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.TOTAL_OBS_DISTANCE') },
    {
        ...commonRows.averageConsumption,
        key: 'AverageMeConsumption',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.AVERAGE_ME_CONSUMPTION') },
    {
        ...commonRows.totalConsumption,
        key: 'TotalMeConsumption',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.TOTAL_ME_CONSUMPTION') },
    {
        ...commonRows.averageConsumption,
        key: 'AverageAuxConsumption',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.AVERAGE_AUX_CONSUMPTION') },
    {
        ...commonRows.totalConsumption,
        key: 'TotalAuxConsumption',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.TOTAL_AUX_CONSUMPTION') },
    {
        key: 'FuelTypes',
        type: 'fuelTypes',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.FUEL')
    },
    {
        key: 'FuelQuantityMap',
        type: 'fuelTypeQuantities',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.LOADING_CONSUMPTION')
    }
];

export const ladenSummaryRows = [
    {
        key: 'CargoOnboard',
        type: 'number',
        unit: t('UNITS.CARGO'),
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.CARGO_ONBOARD'),
        numFmt: '0 "mt"'
    },
    {
        ...commonRows.speedArray,
        key: 'InstructedSpeed',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.INSTRUCTED_SPEED')
    },
    {
        ...commonRows.speed,
        key: 'AverageSpeed',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.AVERAGE_SPEED')
    },
    {
        ...commonRows.duration,
        key: 'TotalTime',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.TOTAL_TIME')
    },
    {
        ...commonRows.distance,
        key: 'TotalObsDistance',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.TOTAL_OBS_DISTANCE')
    },
    {
        ...commonRows.averageConsumption,
        key: 'AverageMeConsumption',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.AVERAGE_ME_CONSUMPTION')
    },
    {
        ...commonRows.totalConsumption,
        key: 'TotalMeConsumption',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.TOTAL_ME_CONSUMPTION')
    },
    {
        ...commonRows.averageConsumption,
        key: 'AverageAuxConsumption',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.AVERAGE_AUX_CONSUMPTION')
    },
    {
        ...commonRows.totalConsumption,
        key: 'TotalAuxConsumption',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.TOTAL_AUX_CONSUMPTION')
    },
    {
        ...commonRows.averageConsumption,
        key: 'CargoHeatingConsumption.AverageCargoHeatingConsumption',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.AVERAGE_CARGO_HEATING_CONSUMPTION'),
        tooltip: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.ALL_REPORTS_DISCLAIMER')
    },
    {
        ...commonRows.totalConsumption,
        key: 'CargoHeatingConsumption.TotalCargoHeatingConsumption',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.TOTAL_CARGO_HEATING_CONSUMPTION'),
        tooltip: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.ALL_REPORTS_DISCLAIMER')
    },
    {
        key: 'CargoHeatingConsumption.CargoHeatedDuringVoyage',
        type: 'bool',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.CARGO_HEATED_DURING_VOYAGE'),
        tooltip: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.ALL_REPORTS_DISCLAIMER')
    },
    {
        key: 'FuelTypes',
        type: 'fuelTypes',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.FUEL')
    },
    {
        key: 'FuelQuantityMap',
        type: 'fuelTypeQuantities',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.DISCHARGING_CONSUMPTION')
    }
];

export const fuelConsumptionRows = [
    {
        key: 'voyageFootprint.FuelTypes', type: 'fuelTypes', label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.FUEL')
    }, {
        key: 'voyageFootprint.FuelQuantityMap',
        type: 'fuelTypeQuantities',
        label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.CONSUMPTION'),
        boundaries: { min: 0, max: Infinity },
        suspiciousTooltip:
            t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.SUSPICIOUS_CONSUMPTION_DISCLAIMER')
    }
];

export const getFootprintRows = (isRange) => {
    const ftRows = fT_getFootprintRows(!isRange, true, false);
    const ciiRows = !ConfigService.featureToggles.showFootprint
        || ConfigService.hiddenFeatures.voyageReportVesselCiiRating
        ? []
        : [
            {
                type: 'header',
                label: t('FOOTPRINT_TABLE.VESSEL_CII'),
                key: 'CIISummaryYTDHeader'
            },
            ...ftRows.ciiYearlyRating()
        ];
    const eeRows = !ConfigService.featureToggles.showFootprint
        || ConfigService.hiddenFeatures.voyageReportVoyageEnergyEfficiency
        ? []
        : [
            {
                type: 'header',
                label: isRange
                    ? t('FOOTPRINT_TABLE.DATE_RANGE_ENERGY_EFFICIENCY')
                    : t('FOOTPRINT_TABLE.VOYAGE_ENERGY_EFFICIENCY'),
                key: 'EnergyEfficiencyHeader'
            },
            ...ftRows.energyEfficiency
        ];
    const fcRows = !ConfigService.featureToggles.showFootprint
        || ConfigService.hiddenFeatures.voyagePerformanceFootprintFuelConsumptions
        ? []
        : fuelConsumptionRows;
    const emissionRows = !ConfigService.featureToggles.showFootprint
        || ConfigService.hiddenFeatures.voyageReportVoyageEmissions
        ? []
        : [
            {
                type: 'header',
                label: isRange
                    ? t('FOOTPRINT_TABLE.DATE_RANGE_EMISSIONS')
                    : t('FOOTPRINT_TABLE.VOYAGE_EMISSIONS'),
                key: 'EmissionsHeader'
            },
            ...ftRows.emissions
        ];
    const vmsEmissionRows = !ConfigService.featureToggles.showVmsFootprint
        || ConfigService.hiddenFeatures.voyageReportVmsVoyageEmissions
        ? []
        : [
            {
                type: 'header',
                label: t('FOOTPRINT_TABLE.VOYAGE_EMISSIONS_VMS'),
                getLabel: () => (
                    <React.Fragment>
                        {t('FOOTPRINT_TABLE.VOYAGE_EMISSIONS')}
                        <span className="sten-badge sten-badge--inline sten-badge--success">VMS</span>
                    </React.Fragment>
                ),
                key: 'VmsEmissionsHeader'
            },
            ...ftRows.vmsEmissions
        ];
    return [...ciiRows, ...eeRows, ...fcRows, ...emissionRows, ...vmsEmissionRows];
};
