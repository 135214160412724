import React from 'react';
import ReactTestUtils from 'react-dom/test-utils';
import PropTypes from 'prop-types';
import { translate } from 'utils/i18n/i18n-model';
import { connect } from 'react-redux';
import fastDeepEqual from 'fast-deep-equal';
import memoize from 'memoize-one';
/* router */
import { TRouter, withRouter } from 'app-router';
/* types */
import { THomeQueryParams } from '../home-types';
/* helpers */
import { wktParse, getImportanceByRelativeZoomLevel, mapCurrentAisLonLat } from 'components/ol/ol-helpers';
import { getNotificationProps } from 'utils/helpers/notification-helper';
/* services */
import ConfigService from 'services/config-api/config-service';
/* actions */
import { setMapViewProps, getPorts, getVessels, setShouldCenterActiveElement } from '../home-actions';
import { setVesselDetailsTooltipPosition } from 'components/vessel-details-tooltip/vessel-details-tooltip-actions';
import { emptyActivePanels } from 'components/right-side-bar/right-side-bar-actions';
import { setReportsPositions } from './voyage-reports/voyage-reports-actions';
import { getSelectedAreas } from 'components/map-options/map-options-actions';
/* selectors */
import {
    getFetchedPorts,
    getFutureRoute,
    getHistoricalRoute,
    getSelectedVesselPositionWithAlert,
    getSelectedVoyageAisPoints,
    getVesselsWithAlerts,
    getSelectedPort
} from '../home-selectors';
/* components */
import DtnWeather from 'components/dtn-weather/dtn-weather';
import NauticalCharts from 'components/nautical-charts/nautical-charts';
import Ol from 'components/ol/ol';
import Tooltip from 'components/tooltip/tooltip';
import VesselDetailsTooltip from 'components/vessel-details-tooltip/vessel-details-tooltip';
import VoyageReports from './voyage-reports/voyage-reports';
import WniWeather from 'components/wni-weather/wni-weather';
/* config */
import config from './map-config';
/* styles */
import './map.scss';
/* icons */
import terminalIcon from 'assets/images/icon-terminal-alt.svg';

class Map extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            mapZoomControlElement: null
        };
        this.vesselOverlays = {};
    }

    currentImportanceLevel = -1;

    componentDidMount() {
        this.props.getSelectedAreas();
        this.setState({ mapZoomControlElement: document.getElementById('stenMapZoomControls') });
    }

    componentDidUpdate(prevProps) {
        if ((this.props.settingUpdated !== prevProps.settingUpdated && this.props.settingUpdated.name === 'mapFilters')
            || (this.props.resourceUpdated !== prevProps.resourceUpdated
                && this.props.resourceUpdated.name === 'fleets')) {
            this.props.getVessels();
        }
        if (prevProps.arePortsInvisible === true && this.props.arePortsInvisible === false) {
            this.getPorts(true);
        }
        if (this.props.selectedPort
            && this.props.selectedPort.Location
            && (prevProps.selectedPort !== this.props.selectedPort
                || prevProps.queryParams.terminalsShown !== this.props.queryParams.terminalsShown
                || prevProps.selectedTerminal !== this.props.selectedTerminal)) {
            if (this.props.selectedTerminal && this.props.selectedTerminal.Position) {
                this.map.setCenter(wktParse(this.props.selectedTerminal.Position), 12);
            } else if (this.props.selectedPortTerminals && this.props.queryParams.terminalsShown) {
                this.map.setCenter(wktParse(this.props.selectedPort.Location), 11);
            } else {
                this.map.setCenter(wktParse(this.props.selectedPort.Location), 6);
            }
        }
        if (prevProps.selectedVesselPosition !== this.props.selectedVesselPosition
            || this.props.shouldCenterActiveElement) {
            if (this.props.shouldCenterActiveElement) {
                this.props.setShouldCenterActiveElement(false);
            }
            if (this.props.selectedVesselPosition) {
                if (this.props.selectedVesselPosition.CurrentAis) {
                    this.map.setCenter(
                        [
                            this.props.selectedVesselPosition.CurrentAis.Longitude,
                            this.props.selectedVesselPosition.CurrentAis.Latitude
                        ],
                        this.props.shouldCenterActiveElement ? 0 : null
                    );
                }
            } else if (this.props.selectedPort?.Location) {
                this.map.setCenter(wktParse(this.props.selectedPort.Location), 6);
            }
        }
        if (this.props.selectedVoyageReports && this.props.selectedVoyageReports !== prevProps.selectedVoyageReports) {
            this.reportPositionSet = false;
            this.setVoyageReportsPositions(this.props);
        }
    }

    componentWillUnmount() {
        this.props.setMapViewProps({
            zoom: this.map.getZoom(),
            center: this.map.getCenter()
        });
        if (this.queryParamsUpdateTimeout) {
            clearTimeout(this.queryParamsUpdateTimeout);
        }
        if (this.reportPositioningTimeout) {
            clearTimeout(this.reportPositioningTimeout);
        }
    }

    handleMapPostRender = () => {
        if (this.map) {
            this.getPorts();
            this.setVesselDetailsTooltipPosition();
            this.setVoyageReportsPositions();
        }
    };

    handleMapClick = (event, clickedFeature) => {
        if (!clickedFeature) {
            this.props.closeRightSidebar();
        }
        return true;
    };

    handleMapHover = (event, hoveredFeature) => {
        if (this.wniWeatherTooltipRef) {
            this.wniWeatherTooltipRef.update(event, hoveredFeature);
        }
    };

    onVesselClick = vesselFeature => {
        const newQuery = this.query ? { ...this.query } : { ...this.props.router.query };
        const queryParams = [
            'vessel',
            'reportId',
            'reportType',
            'reportEdit',
            'questionnaireId',
            'questionnaireEdit',
            'voyagePerformance',
            'voyage'
        ];
        queryParams.forEach((param) => {
            if (newQuery[param]) {
                delete newQuery[param];
            }
        });
        if (vesselFeature && vesselFeature.data && vesselFeature.data.IMO) {
            newQuery.vessel = vesselFeature.data.IMO.toString();
        }
        this.updateQuery(newQuery);
    };

    onPortClick = portFeature => {
        if (portFeature && this.props.queryParams.port === portFeature.data.Id) {
            return;
        }
        const newQuery = this.query ? { ...this.query } : { ...this.props.router.query };
        const queryParams = ['port', 'terminal', 'berth', 'terminalUpdate', 'berthUpdate', 'terminalsShown'];
        queryParams.forEach((param) => {
            if (newQuery[param]) {
                delete newQuery[param];
            }
        });
        if (portFeature && portFeature.data && portFeature.data.Id) {
            newQuery.port = portFeature.data.Id.toString();
        }
        this.updateQuery(newQuery);
    };

    onTerminalClick = terminalFeature => {
        const newQuery = this.query ? { ...this.query } : { ...this.props.router.query };
        const queryParams = ['terminal', 'berth', 'terminalUpdate', 'berthUpdate'];
        queryParams.forEach((param) => {
            if (newQuery[param]) {
                delete newQuery[param];
            }
        });
        if (terminalFeature && terminalFeature.data && terminalFeature.data.TerminalId) {
            newQuery.terminal = terminalFeature.data.TerminalId.toString();
        }
        this.updateQuery(newQuery, true);
    };

    /* ports */
    getPorts = (forceGetPorts = false) => {
        if (forceGetPorts) {
            this.forceGetPorts = true;
        }
        if (!this.map) {
            return;
        }
        const relativeZoomLevel = this.map.getRelativeZoom();
        const importanceLevel = getImportanceByRelativeZoomLevel(relativeZoomLevel);

        if (this.forceGetPorts || importanceLevel !== this.currentImportanceLevel) {
            this.currentImportanceLevel = importanceLevel;
            this.props.getPorts({
                ImportanceLevel: importanceLevel,
                BBox: 'MULTIPOLYGON(((-180 -90,180 -90,180 90,-180 90,-180 -90)))'
            });
            this.forceGetPorts = false;
        }
    }

    getPortPrimaryColor = port => {
        if (this.props.queryParams.port === port.Id
            || (port.selectedInVoyage && port.IsHistorical)) {
            return config.port.primaryColor;
        }
        return config.port.secondaryColor;
    };

    getPortSecondaryColor = (port, isHovered) => {
        if (this.props.queryParams.port === port.Id) {
            return config.port.primaryColor;
        }
        if (isHovered || port.selectedInVoyage) {
            return config.port.secondaryColor;
        }
        return null;
    };

    getPortHidden = port => {
        return this.props.arePortsInvisible && !port.selectedInVoyage && port.Id !== this.props.queryParams.port;
    };

    getActivityCodeLabel = activities => {
        const activityCodeCounts = {};
        let label = '';
        activities.forEach((activity) => {
            if (activity.Code) {
                if (!activityCodeCounts[activity.Code]) {
                    activityCodeCounts[activity.Code] = 1;
                } else {
                    activityCodeCounts[activity.Code]++;
                }
            }
        });
        Object.keys(activityCodeCounts).forEach((key) => {
            if (activityCodeCounts[key] >= 2) {
                label += ` (${activityCodeCounts[key]}${key})`;
            } else {
                label += ` (${key})`;
            }
        });
        return label;
    };

    getPortLabel = port => {
        let label = '';
        if (port) {
            label = port.Name;
            if (port.selectedInVoyage && port.Activities && port.Activities.length > 0) {
                label += this.getActivityCodeLabel(port.Activities);
            }
        }
        return label;
    };

    getPortHideLabel = (port, isHovered, zoomLevel) => {
        return this.props.queryParams.port !== port.Id && !port.selectedInVoyage
            && !isHovered && config.port.labelMinZoomLevel > zoomLevel;
    };

    getPortOpacity = (port, isHovered) => {
        if (isHovered || (this.props.queryParams.port && this.props.queryParams.port === port.Id)) {
            return 1;
        }
        if ((this.props.queryParams.vessel && !port.selectedInVoyage) || this.props.queryParams.port) {
            return 0.3;
        }
        return 0.8;
    };

    getPortZIndex = (port, isHovered) => {
        const zIndex = port.ImportanceLevel || 0;
        if (isHovered) {
            return zIndex + 21;
        }
        if (this.props.queryParams.port && this.props.queryParams.port === port.Id) {
            return zIndex + 20;
        }
        return zIndex;
    };

    getTerminalOpacity = (terminal, isHovered) => {
        if (isHovered || (this.props.queryParams.terminal && this.props.queryParams.terminal === terminal.TerminalId)) {
            return 1;
        }
        if (this.props.queryParams.terminal) {
            return 0.6;
        }
        return 0.8;
    };

    getTerminalColor = terminal => {
        if (this.props.queryParams.terminal === terminal.TerminalId) {
            return config.terminal.primaryColor;
        }
        return config.terminal.secondaryColor;
    };

    getTerminalHideLabel = (terminal, isHovered, zoomLevel) => {
        return this.props.queryParams.terminal !== terminal.TerminalId
            && !isHovered && config.terminal.labelMinZoomLevel > zoomLevel;
    };

    getTerminalHidden = (terminal, isHovered, zoomLevel) => {
        return (this.props.queryParams.terminal !== terminal.TerminalId && zoomLevel < config.terminal.minZoom)
            || zoomLevel < config.terminal.minZoomSelected;
    };

    /* vessels */
    getVesselColor = vessel => {
        if (vessel.LatestAlert) {
            return config.vessel.alertColor;
        }
        if (vessel.RelationshipType === 'TimeCharter') {
            return config.vessel.quaternaryColor;
        }
        if (vessel.RelationshipType === 'CargoOwner' || vessel.IsDedicatedVessel) {
            return config.vessel.tertiaryColor;
        }
        if (vessel.IsVesselInUserScope) {
            // if (vessel.RelationshipType === 'VesselOwner') {
            //     return config.vessel.tertiaryColor;
            // }
            return config.vessel.primaryColor;
        }
        return config.vessel.secondaryColor;
    };

    getVesselOpacity = (vessel, isHovered) => {
        if (isHovered || this.props.queryParams.vessel === vessel.IMO) {
            return 1;
        }
        if (this.props.queryParams.port || this.props.queryParams.vessel) {
            if (vessel.LatestAlert) {
                return 0.6;
            }
            return 0.3;
        }
        return 0.8;
    };

    static getVesselFill(vessel) {
        return vessel.IsLaden === true;
    }

    getVesselActive = vessel => {
        return vessel.IMO === this.props.queryParams.vessel;
    };

    getVesselHideLabel = (vessel, isHovered, zoomLevel) => {
        if (this.getVesselActive(vessel)) {
            return true;
        }
        if (this.props.alwaysShowVesselNames) {
            return false;
        }
        return config.vessel.labelMinZoomLevel > zoomLevel && !isHovered;
    };

    static getTooltipContent(vessel) {
        const notificationProps = getNotificationProps(vessel.LatestAlert);
        return (
            <div className="flex-center flex-row">
                <div className="flex-shrink">
                    <span className="icon icon-exclamation-circle sten-map__tooltip-icon" />
                </div>
                <div className="flex-grow">
                    <div className="sten-map__tooltip-message text-danger">
                        {notificationProps.Message}
                    </div>
                    <h6>
                        {translate('MAP.TOOLTIPS.REPORTED')}
                        {notificationProps.Timestamp}
                    </h6>
                </div>
            </div>
        );
    }

    getVesselOverlay = vessel => {
        if (vessel.LatestAlert) {
            const hashKey = `${vessel.IMO}-${vessel.LatestAlert.NotificationId}`;
            if (!this.vesselOverlays[hashKey]) {
                this.vesselOverlays[hashKey] = ReactTestUtils.renderIntoDocument(
                    <div>
                        <Tooltip
                            alwaysVisible
                            contentClassName="sten-map__tooltip"
                            content={Map.getTooltipContent(vessel)}
                        />
                    </div>
                );
            }
            return this.vesselOverlays[hashKey];
        }
        return null;
    };

    getVesselOverlayProps = (vessel, isHovered) => {
        if (vessel.LatestAlert) {
            const hidden = !isHovered && this.props.queryParams.vessel !== vessel.IMO;
            return {
                hidden,
                id: vessel.LatestAlert ? `vessel-alert-${vessel.LatestAlert.NotificationId}` : '',
                offset: [0, -15],
                positioning: 'top-center'
            };
        }
        return null;
    };

    getReportPositions = (props = this.props) => {
        const reportPositions = [];
        props.selectedVoyageReports.forEach((report) => {
            if (report.ReportType
                && report.ReportType === 1
                && typeof report.Longitude === 'number'
                && typeof report.Latitude === 'number'
                && report.Latitude >= -90
                && report.Latitude <= 90) {
                const positions = this.map.getPixelFromCoordinate([report.Longitude, report.Latitude]);
                if (positions && positions.length) {
                    reportPositions.push({
                        reportId: report.ReportId,
                        left: Math.floor(positions[0]),
                        top: Math.floor(positions[1])
                    });
                }
            }
        });
        return reportPositions;
    };

    setVoyageReportsPositions = (props = this.props) => {
        if (!props.selectedVoyageReports || props.selectedVoyageReports.length === 0) {
            return;
        }
        if (this.reportPositioningTimeout) {
            clearTimeout(this.reportPositioningTimeout);
        }
        if (this.zoomLevel !== this.map.getZoom()
            || this.mapCenter !== this.map.getCenter()
            || this.mapSize !== this.map.getSize()) {
            this.zoomLevel = this.map.getZoom();
            this.mapCenter = this.map.getCenter();
            this.mapSize = this.map.getSize();
        } else if (this.reportPositionSet) {
            return;
        }
        if (props.selectedVoyageReports.length > 100) {
            this.reportPositioningTimeout = setTimeout(() => {
                this.props.setReportsPositions(this.getReportPositions(props), this.zoomLevel);
                this.reportPositionSet = true;
            }, 300);
            this.props.setReportsPositions([], 0);
            this.reportPositionSet = false;
        } else {
            this.props.setReportsPositions(this.getReportPositions(props), this.zoomLevel);
            this.reportPositionSet = true;
        }
    };

    getVisibleTerminals = memoize((terminals) => {
        return terminals.filter((t) => typeof t.Location === 'string' && t.Location.length > 0);
    });

    setVesselDetailsTooltipPosition = () => {
        const vesselPosition = this.props.selectedVesselPosition;
        if (vesselPosition) {
            const position = vesselPosition.CurrentAis;
            const pixels = this.map.getPixelFromCoordinate([position.Longitude, position.Latitude]);
            const vesselPositionDetails = {
                pixels,
                zoomLevel: this.map.getZoom()
            };

            if (!fastDeepEqual(vesselPositionDetails, this.lastVesselPositionDetails)) {
                this.props.setVesselDetailsTooltipPosition(vesselPositionDetails);
            }

            this.lastVesselPositionDetails = vesselPositionDetails;
        }
    };

    updateQuery(query, force) {
        if (!this.forcedQuery || force) {
            if (force) {
                this.forcedQuery = true;
            }
            const currentLocation = this.props.router.location;
            this.query = query;
            if (this.queryParamsUpdateTimeout) {
                clearTimeout(this.queryParamsUpdateTimeout);
            }
            this.queryParamsUpdateTimeout = setTimeout(() => {
                if (!fastDeepEqual(this.query, this.props.router.query)) {
                    this.props.router.navigate({ pathname: currentLocation.pathname, query });
                }
                this.forcedQuery = false;
                this.query = null;
            }, 200);
        }
    }

    futureRouteElementProps = {
        color: config.route.future.color,
        width: config.route.future.width,
        lineDash: config.route.future.lineDash,
        lineDashMaxZoom: config.route.future.lineDashMaxZoom
    };

    vesselsElementProps = {
        type: 'vessel',
        primaryColor: this.getVesselColor,
        coordinates: mapCurrentAisLonLat,
        fill: Map.getVesselFill,
        isActive: this.getVesselActive,
        label: vessel => (vessel.VesselName
            ? vessel.VesselName.toUpperCase()
            : translate('GLOBAL.NO_NAME')),
        hideLabel: this.getVesselHideLabel,
        opacity: this.getVesselOpacity,
        overlay: this.getVesselOverlay,
        overlayProps: this.getVesselOverlayProps,
        rotation: 'CurrentAis.Heading',
        textBold: vessel => !!vessel.LatestAlert,
        textStroke: true,
        textOffset: 24
    };

    areasOfInterestElementProps = {
        lineColor: area => config.areaOfInterest[area.AreaTypeId]?.lineColor || config.areaOfInterest.lineColorDefault,
        lineWidth: config.areaOfInterest.lineWidth,
        label: 'Description',
        wkt: 'AreaDefinition'
    };

    historicalLadenApproxRouteElementProps = {
        color: config.route.ladenApprox.color,
        width: config.route.ladenApprox.width,
        lineDash: config.route.ladenApprox.lineDash,
        lineDashMaxZoom: config.route.ladenApprox.lineDashMaxZoom,
        zoomColor: config.route.ladenApprox.zoomColor,
        zoomWidth: config.route.ladenApprox.zoomWidth
    };

    historicalBallastApproxRouteElementProps = {
        color: config.route.ballastApprox.color,
        width: config.route.ballastApprox.width,
        lineDash: config.route.ballastApprox.lineDash,
        lineDashMaxZoom: config.route.ballastApprox.lineDashMaxZoom,
        zoomColor: config.route.ballastApprox.zoomColor,
        zoomWidth: config.route.ballastApprox.zoomWidth
    };

    historicalBallastRouteElementProps = {
        color: config.route.ballast.color,
        width: config.route.ballast.width
    };

    historicalLadenRouteElementProps = {
        color: config.route.laden.color,
        width: config.route.laden.width
    };

    isPortActive = (port) => port.selectedInVoyage || port.Id === this.props.queryParams.port;

    portsElementProps = {
        type: (port) => config.pointTypesMap[port.Type],
        primaryColor: this.getPortPrimaryColor,
        secondaryColor: this.getPortSecondaryColor,
        coordinates: port => wktParse(port.Location),
        hidden: this.getPortHidden,
        isActive: this.isPortActive,
        opacity: this.getPortOpacity,
        label: this.getPortLabel,
        hideLabel: this.getPortHideLabel,
        textStroke: true,
        textOnTop: true,
        zIndex: this.getPortZIndex
    };

    terminalsElementProps = {
        src: terminalIcon,
        color: this.getTerminalColor,
        opacity: this.getTerminalOpacity,
        hidden: this.getTerminalHidden,
        coordinates: terminal => wktParse(terminal.Location),
        isActive: terminal => terminal.TerminalId === this.props.queryParams.terminal,
        label: terminal => terminal.TerminalName || '',
        hideLabel: this.getTerminalHideLabel,
        textOffset: config.terminal.textOffset,
        textStroke: true,
        textOnTop: true,
        scale: 0.25
    };

    saveWniWeatherTooltipRef = (c) => { this.wniWeatherTooltipRef = c; };

    render() {
        const isNauticalMapSelected = this.props.mapTypeId === 3;
        return (
            <div className="sten-map">
                <VesselDetailsTooltip
                    key="VesselDetailsTooltip"
                    selectedVesselPosition={this.props.selectedVesselPositionWithAlert}
                />
                <VoyageReports key="VoyageReports" selectedVesselReportId={this.props.queryParams.reportId} />
                <Ol.Map
                    key="Map"
                    ref={(c) => { this.map = c; }}
                    mapTypeId={this.props.mapTypeId}
                    mapViewProps={this.props.mapViewProps}
                    countryBordersNamesEnabled={this.props.countryBordersNamesEnabled}
                    zoomControlElement={this.state.mapZoomControlElement}
                    onPostRender={this.handleMapPostRender}
                    onClick={this.handleMapClick}
                    onHover={this.handleMapHover}
                >
                    {!isNauticalMapSelected && ConfigService.featureToggles.showWNIWeather && (
                        <WniWeather zIndex={config.weather.zIndex} tooltipRef={this.saveWniWeatherTooltipRef} />
                    )}
                    {!isNauticalMapSelected && ConfigService.featureToggles.showDTNWeather && (
                        <DtnWeather zIndex={config.weather.zIndex} />
                    )}
                    {ConfigService.featureToggles.showNauticalCharts && (
                        <NauticalCharts zIndex={config.nautical.zIndex} showBaseMap={isNauticalMapSelected} />
                    )}
                    {this.props.selectedAreas && (
                        <Ol.Layer
                            name="areasOfInterest"
                            type="area"
                            elements={this.props.selectedAreas}
                            elementProps={this.areasOfInterestElementProps}
                            zIndex={config.areaOfInterest.zIndex}
                        />
                    )}
                    {this.props.selectedVoyageHistoricalRoute.ballast.length > 0 && (
                        <Ol.Layer
                            name="historicalBallastRoute"
                            type="multiLine"
                            elements={this.props.selectedVoyageHistoricalRoute.ballast}
                            elementProps={this.historicalBallastRouteElementProps}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    {this.props.selectedVoyageHistoricalRoute.ballastApprox.length > 0 && (
                        <Ol.Layer
                            name="historicalBallastApproxRoute"
                            type="multiLine"
                            elements={this.props.selectedVoyageHistoricalRoute.ballastApprox}
                            elementProps={this.historicalBallastApproxRouteElementProps}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    {this.props.selectedVoyageHistoricalRoute.laden.length > 0 && (
                        <Ol.Layer
                            name="historicalLadenRoute"
                            type="multiLine"
                            elements={this.props.selectedVoyageHistoricalRoute.laden}
                            elementProps={this.historicalLadenRouteElementProps}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    {this.props.selectedVoyageHistoricalRoute.ladenApprox.length > 0 && (
                        <Ol.Layer
                            name="historicalLadenApproxRoute"
                            type="multiLine"
                            elements={this.props.selectedVoyageHistoricalRoute.ladenApprox}
                            elementProps={this.historicalLadenApproxRouteElementProps}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    {this.props.selectedVoyageFutureRoute.length > 0 && (
                        <Ol.Layer
                            name="futureRoute"
                            type="multiLine"
                            elements={this.props.selectedVoyageFutureRoute}
                            elementProps={this.futureRouteElementProps}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    <Ol.Layer
                        name="ports"
                        type="point"
                        elements={this.props.ports}
                        elementProps={this.portsElementProps}
                        observes={[
                            this.props.queryParams.vessel,
                            this.props.queryParams.port,
                            this.props.arePortsInvisible
                        ]}
                        zIndex={config.port.zIndex}
                        onClick={this.onPortClick}
                        onClickAway={this.onPortClick}
                    />
                    {this.props.selectedPortTerminals && (
                        <Ol.Layer
                            name="terminals"
                            type="icon"
                            elements={this.getVisibleTerminals(this.props.selectedPortTerminals)}
                            elementProps={this.terminalsElementProps}
                            observes={[
                                this.props.selectedPortTerminals,
                                this.props.queryParams.vessel,
                                this.props.queryParams.terminal,
                                this.props.queryParams.port,
                                this.props.arePortsInvisible
                            ]}
                            zIndex={config.terminal.zIndex}
                            onClick={this.onTerminalClick}
                        />
                    )}
                    <Ol.Layer
                        name="vessels"
                        type="point"
                        elements={this.props.vessels}
                        elementProps={this.vesselsElementProps}
                        observes={[
                            this.props.queryParams.port,
                            this.props.queryParams.vessel,
                            this.props.alwaysShowVesselNames
                        ]}
                        zIndex={config.vessel.zIndex}
                        onClick={this.onVesselClick}
                        onClickAway={this.onVesselClick}
                    />
                </Ol.Map>
            </div>
        );
    }
}

Map.propTypes = {
    alwaysShowVesselNames: PropTypes.bool.isRequired,
    arePortsInvisible: PropTypes.bool.isRequired,
    closeRightSidebar: PropTypes.func.isRequired,
    countryBordersNamesEnabled: PropTypes.bool.isRequired,
    getPorts: PropTypes.func.isRequired,
    getSelectedAreas: PropTypes.func.isRequired,
    getVessels: PropTypes.func.isRequired,
    mapTypeId: PropTypes.number.isRequired,
    mapViewProps: PropTypes.objectOf(PropTypes.any),
    ports: PropTypes.arrayOf(PropTypes.object).isRequired,
    queryParams: THomeQueryParams.isRequired,
    resourceUpdated: PropTypes.objectOf(PropTypes.any).isRequired,
    router: TRouter.isRequired,
    selectedAreas: PropTypes.objectOf(PropTypes.any),
    selectedPort: PropTypes.objectOf(PropTypes.any),
    selectedPortTerminals: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedTerminal: PropTypes.objectOf(PropTypes.any),
    selectedVesselPosition: PropTypes.objectOf(PropTypes.any),
    selectedVesselPositionWithAlert: PropTypes.objectOf(PropTypes.any),
    selectedVoyageAisPoints: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedVoyageFutureRoute: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedVoyageHistoricalRoute: PropTypes.objectOf(PropTypes.array).isRequired,
    selectedVoyageReports: PropTypes.arrayOf(PropTypes.object).isRequired,
    setMapViewProps: PropTypes.func.isRequired,
    setReportsPositions: PropTypes.func.isRequired,
    setShouldCenterActiveElement: PropTypes.func.isRequired,
    settingUpdated: PropTypes.objectOf(PropTypes.any).isRequired,
    setVesselDetailsTooltipPosition: PropTypes.func.isRequired,
    shouldCenterActiveElement: PropTypes.bool.isRequired,
    vessels: PropTypes.arrayOf(PropTypes.object).isRequired
};

Map.defaultProps = {
    mapViewProps: null,
    selectedAreas: null,
    selectedPort: null,
    selectedTerminal: null,
    selectedVesselPosition: null,
    selectedVesselPositionWithAlert: null
};

function mapStateToProps(state) {
    return {
        alwaysShowVesselNames: state.userReducer.settings
            && state.userReducer.settings.ShowVesselNames,
        arePortsInvisible: state.userReducer.settings && state.userReducer.settings.ArePortsInvisible,
        countryBordersNamesEnabled: state.userReducer.settings
            && state.userReducer.settings.MapOptionsCountryBordersNamesEnabled,
        mapTypeId: state.userReducer.settings && state.userReducer.settings.MapOptionsMapType,
        mapViewProps: state.homeReducer.mapViewProps,
        ports: getFetchedPorts(state),
        resourceUpdated: state.userReducer.resourceUpdated,
        selectedAreas: state.mapOptionsReducer.selectedAreas,
        selectedPort: getSelectedPort(state),
        selectedPortTerminals: state.portInfoReducer.terminals,
        selectedTerminal: state.terminalInfoReducer.terminal,
        selectedVesselPosition: state.homeReducer.selectedVesselPosition,
        selectedVesselPositionWithAlert: getSelectedVesselPositionWithAlert(state),
        selectedVoyageAisPoints: getSelectedVoyageAisPoints(state),
        selectedVoyageFutureRoute: getFutureRoute(state),
        selectedVoyageHistoricalRoute: getHistoricalRoute(state),
        selectedVoyageReports: state.homeReducer.selectedVoyageReports,
        settingUpdated: state.userReducer.settingUpdated,
        shouldCenterActiveElement: state.homeReducer.shouldCenterActiveElement,
        vessels: getVesselsWithAlerts(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        closeRightSidebar: () => emptyActivePanels(dispatch),
        getPorts: params => getPorts(dispatch, params),
        getSelectedAreas: () => getSelectedAreas(dispatch),
        getVessels: () => getVessels(dispatch),
        setMapViewProps: mapViewProps => setMapViewProps(dispatch, mapViewProps),
        setReportsPositions: (reportPositions, zoomLevel) =>
            setReportsPositions(dispatch, reportPositions, zoomLevel),
        setShouldCenterActiveElement: shouldCenter => setShouldCenterActiveElement(dispatch, shouldCenter),
        setVesselDetailsTooltipPosition: position => setVesselDetailsTooltipPosition(dispatch, position)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Map));
