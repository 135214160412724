import { navMenuExpand } from '../../pages/user-pages/left-side-bar/left-side-bar-actions';
import { addHours } from '../../pages/user-pages/home/map-date-picker/map-date-picker-actions';
import { toggleFocus } from '../../pages/user-pages/home/global-search/global-search-actions';

export default {
    'navmenu-collapse': {
        action: navMenuExpand,
        payload: false
    },
    'map-date-picker-add-hour': {
        action: addHours,
        payload: 1
    },
    'map-date-picker-subtract-hour': {
        action: addHours,
        payload: -1
    },
    'map-date-picker-add-day': {
        action: addHours,
        payload: 24
    },
    'map-date-picker-subtract-day': {
        action: addHours,
        payload: -24
    },
    'deselect-element': (currentLocation, query, navigate) => {
        const newQuery = { ...query };
        const queryParams = [
            'vessel',
            'port',
            'reportId',
            'reportType',
            'reportEdit',
            'questionnaireId',
            'questionnaireEdit',
            'voyage',
            'terminal',
            'berth',
            'terminalUpdate',
            'berthUpdate',
            'terminalsShown'
        ];
        queryParams.forEach((param) => {
            if (newQuery[param]) {
                delete newQuery[param];
            }
        });
        navigate({ pathname: currentLocation.pathname, query: newQuery });
    },
    'global-search-input-focus': {
        action: toggleFocus
    }
};
