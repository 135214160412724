import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* types */
import { TWeatherRoutingQueryParams } from '../weather-routing-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { renderFormContent } from 'components/form-fields/form-fields-helper';
import { getClassName } from 'utils/helpers/info-helper';
/* actions */
import {
    clearData,
    postRequest,
    sortAscending,
    getRequestData,
    updateRequestData
} from '../weather-routing-actions';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
import Validation from 'components/validation/validation';
/* constants */
import { fieldMap, dataMap } from './weather-routing-request-field-map';
import { sectionsAndFields } from './weather-routing-request-fields-and-sections';

export class WeatherRoutingRequest extends React.PureComponent {
    componentDidMount() {
        this.props.getRequestData(this.props.queryParams, this.props.legId, {
            requestId: true,
            vesselId: true,
            legId: true
        });
    }

    componentDidUpdate(prevProps) {
        const requestIdChanged = this.props.queryParams.requestId
            && (prevProps.queryParams.requestId !== this.props.queryParams.requestId);
        const vesselIdChanged = this.props.queryParams.vessel
            && !this.props.legId && (this.props.queryParams.vessel !== prevProps.queryParams.vessel);
        const legIdChanged = this.props.legId && (prevProps.legId !== this.props.legId);

        if (requestIdChanged || vesselIdChanged || legIdChanged
            || (this.props.queryParams.requestEdit !== prevProps.queryParams.requestEdit)) {
            this.props.getRequestData(this.props.queryParams, this.props.legId, {
                requestId: requestIdChanged,
                vesselId: vesselIdChanged,
                legId: legIdChanged
            });
        }
    }

    handleSubmit = () => {
        this.props.postRequest();
    };

    handleClose = () => {
        this.handleClear();
        this.props.updateQueryParams({ remove: ['requestEdit', 'requestId', 'vessel', 'voyage'] });
    }

    handleClear = () => {
        this.props.clearData();
    };

    handleValueChange = (prop) => (value) => {
        this.props.updateRequestData(prop, value, this.props.editingOptions);
    };

    renderContent = () => {
        const params = {
            data: this.props.editingRequest,
            onChange: this.handleValueChange,
            isEditing: this.props.queryParams.requestEdit,
            sortAscending: this.props.sortAscending,
            editingOptions: {
                ...this.props.editingOptions,
                templateOptions: this.props.templateOptions
            },
            disabledButtons: {
                Vessel: this.props.queryParams.vessel || this.props.legId,
                Voyage: this.props.queryParams.voyage || this.props.legId,
                VesselCondition: this.props.legId
            }
        };
        return renderFormContent({
            sections: sectionsAndFields,
            fieldMap,
            dataMap,
            params
        });
    };

    render() {
        const { queryParams } = this.props;
        let title = t('WEATHER_ROUTING.ORIGINAL_REQUEST');
        if (queryParams.requestEdit) {
            title = t('WEATHER_ROUTING.NEW_ROUTING_REQUEST');
        }
        const className = getClassName('sten-content', {
            'sten-content--has-footer': queryParams.requestEdit
        });
        return (
            <Validation.Form className={className} onSubmit={this.handleSubmit}>
                <header className="sten-content__header">
                    <div className="flex-grow">
                        <h1 className="sten-content__title">{title}</h1>
                        <p className="sten-content__subtitle text-ellipsis text-nowrap">
                            {this.props.editingRequest.Vessel && this.props.editingRequest.Voyage
                                ? `${this.props.editingRequest.Vessel.Title},
                                   ${this.props.editingRequest.Voyage.VoyageNumber}`
                                : ''}
                        </p>
                    </div>
                    <div className="flex-shrink">
                        <button type="button" className="btn-link icon icon-close" onClick={this.handleClose} />
                    </div>
                </header>
                <ScrollArea className="sten-content__body" shouldScrollToActiveElement>
                    {this.renderContent()}
                </ScrollArea>
                {this.props.queryParams.requestEdit && (
                    <footer className="sten-content__footer flex-row">
                        <div className="col-12">
                            <button type="button" className="btn btn--secondary col-24" onClick={this.handleClose}>
                                {t('GLOBAL.CANCEL')}
                            </button>
                        </div>
                        { this.props.editingRequest && this.props.editingRequest.LegId && (
                            <div className="col-12">
                                <button type="button" className="btn btn--secondary col-24" onClick={this.handleClear}>
                                    {t('GLOBAL.CLEAR')}
                                </button>
                            </div>
                        )}
                        <div className="col-12">
                            <Validation.Button
                                disabled={queryParams.requestId && !this.props.editingRequest}
                                className="btn btn--primary col-24"
                            >
                                {queryParams.requestId && !this.props.editingRequest
                                    ? t('GLOBAL.SAVE') : t('GLOBAL.CREATE')}
                            </Validation.Button>
                        </div>
                    </footer>
                )}
            </Validation.Form>
        );
    }
}

WeatherRoutingRequest.propTypes = {
    clearData: PropTypes.func.isRequired,
    editingOptions: PropTypes.objectOf(PropTypes.any).isRequired,
    editingRequest: PropTypes.objectOf(PropTypes.any).isRequired,
    getRequestData: PropTypes.func.isRequired,
    legId: PropTypes.string,
    postRequest: PropTypes.func.isRequired,
    queryParams: TWeatherRoutingQueryParams.isRequired,
    sortAscending: PropTypes.func.isRequired,
    templateOptions: PropTypes.arrayOf(PropTypes.any),
    updateQueryParams: PropTypes.func.isRequired,
    updateRequestData: PropTypes.func.isRequired
};

WeatherRoutingRequest.defaultProps = {
    legId: null,
    templateOptions: []
};

function mapStateToProps(state) {
    return {
        editingOptions: state.weatherRoutingReducer.editingOptions,
        editingRequest: state.weatherRoutingReducer.editingRequest,
        templateOptions: state.weatherRoutingReducer.templateOptions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        sortAscending: () => sortAscending(dispatch),
        clearData: () => clearData(dispatch),
        getRequestData: (queryParams, legId, changed) => getRequestData(dispatch, queryParams, legId, changed),
        postRequest: () => postRequest(dispatch),
        updateRequestData: (prop, value, editingOptions) => updateRequestData(dispatch, prop, value, editingOptions)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WeatherRoutingRequest);
