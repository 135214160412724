import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
/* router */
import { TRouter, withRouter } from 'app-router';
/* Utils */
import { translate } from 'utils/i18n/i18n-model';
/* Helpers */
import { getPanelRows } from 'utils/helpers/template-helper';
import { formatNumber, formatCoordinates } from 'utils/helpers/info-helper';
/* Components */
import Carousel from 'components/carousel/carousel';
import ScrollArea from 'components/scroll-area/scroll-area';
/* Selectors */
import { getTankerDetails } from '../gsm-right-side-bar-selectors';
/* Styles */
import './gsm-tanker-info.scss';
/* Images */
import noImagesPlaceholder from 'assets/images/gsm/carousel_images/carousel_image_placeholder-tanker.png';

export class GsmTankerInfo extends React.PureComponent {
    onCloseClick = () => {
        this.props.router.clearQueryParams();
    }

    getVesselDetails() {
        const columnValues = [];
        const columnClasses = ['col-9', 'col-15'];

        const { CurrentAis } = this.props.selectedTanker;
        columnValues.push([
            <label className="sten-panel__label">{translate('VESSEL_INFO.OVERVIEW.POSITION')}</label>,
            <label key="coordinates" className="sten-panel__label">
                {CurrentAis?.Time && (
                    <React.Fragment>
                        <span className="sten-panel__label--value" key="value">
                            {formatCoordinates(CurrentAis.Longitude, CurrentAis.Latitude)}
                        </span>
                        <span className="sten-panel__label">
                            {`(updated ${moment.utc(CurrentAis.Time).fromNow()})`}
                        </span>
                    </React.Fragment>
                )}
            </label>
        ]);

        for (const tankerDetailRow of this.props.selectedTankerDetails) {
            const metricsUnit = tankerDetailRow.metricsUnit ? translate(tankerDetailRow.metricsUnit) : '';
            columnValues.push([
                <label className="sten-panel__label">{translate(tankerDetailRow.name)}</label>,
                <label className="sten-panel__label sten-panel__label--value">
                    {tankerDetailRow.formatNumber !== false
                        ? this.formatRowValue(tankerDetailRow.value, metricsUnit, true)
                        : this.formatRowValue(tankerDetailRow.value)
                    }
                </label>
            ]);
        }

        if (columnValues.length === 0) {
            return null;
        }

        return (
            <div key="values" className="sten-content__section">
                {getPanelRows(columnClasses, columnValues)}
            </div>
        );
    }

    formatRowValue(value, unit = '', formatValue) {
        const formattedValue = typeof value === 'number' && value && formatValue ? formatNumber(value, 0) : value;
        return formattedValue ? `${formattedValue} ${unit}` : '-';
    }

    render() {
        const tanker = this.props.selectedTanker;
        if (!tanker) {
            return (
                <div className="sten-content">
                    <div className="sten-content__header" />
                    <div className="sten-content__body" />
                </div>
            );
        }

        return (
            <div className="sten-content">
                <div className="sten-content__header flex-row">
                    {this.props.stepBack && (
                        <div className="flex-shrink">
                            <button
                                className="btn-link icon icon-arrow-left"
                                onClick={this.onCloseClick}
                            />
                        </div>
                    )}
                    <div className="flex-grow">
                        <h1 className="sten-content__title">
                            {tanker.Title}
                        </h1>
                        <p className="sten-content__subtitle">
                            {tanker.CargoType || translate('GSM.RIGHT_SIDE_MENU_SUBTITLE.TANKER')}
                        </p>
                    </div>
                    {!this.props.stepBack && (
                        <div className="flex-shrink">
                            <button
                                className="btn-link icon icon-close"
                                onClick={this.onCloseClick}
                            />
                        </div>
                    )}
                </div>
                <div className="sten-content__body gsm-tanker-info__body-carousel">
                    <Carousel noImagesPlaceholder={noImagesPlaceholder} images={this.props.carouselImages} />
                </div>

                <div className="sten-content__body gsm-tanker-info__body-content">
                    <ScrollArea className="sten-vessel-info-overview">
                        <div className="sten-content__section-header">
                            <h4 className="text-uppercase">{translate('GSM.VESSEL_INFO.VESSEL_DETAILS')}</h4>
                        </div>
                        {this.getVesselDetails()}

                        {/* <div className="sten-content__separator" />
                        <PanelSection header={panelHeaderDescription}>
                            <div key="values" className="sten-content__section">
                                {this.props.tankerDescription}
                            </div>
                        </PanelSection> */}
                    </ScrollArea>
                </div>
            </div>
        );
    }
}

GsmTankerInfo.propTypes = {
    carouselImages: PropTypes.arrayOf(PropTypes.string).isRequired,
    router: TRouter.isRequired,
    selectedTanker: PropTypes.objectOf(PropTypes.any),
    selectedTankerDetails: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        metricsUnit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        formatNumber: PropTypes.bool
    })),
    stepBack: PropTypes.bool
};

GsmTankerInfo.defaultProps = {
    selectedTanker: null,
    selectedTankerDetails: null,
    stepBack: false
};

function mapStateToProps(state) {
    return {
        carouselImages: state.gsmRightSideBarReducer.carouselImages,
        selectedTanker: state.gsmReducer.selectedTanker,
        selectedTankerDetails: getTankerDetails(state)
    };
}

export default connect(mapStateToProps)(withRouter(GsmTankerInfo));
