import CoreApi from './core-api';

class FleetService {
    constructor() {
        this.resourceUrl = 'Fleets';
    }

    get(id) {
        return CoreApi.get(id ? `${this.resourceUrl}/${id}` : this.resourceUrl)
            .then(response => response.data, () => null);
    }

    add(data) {
        return CoreApi.post(`${this.resourceUrl}`, data)
            .then(response => response.data, () => null);
    }

    update(id, data) {
        return CoreApi.put(`${this.resourceUrl}/${id}`, data)
            .then(response => response.data, () => null);
    }

    delete(id) {
        return CoreApi.remove(`${this.resourceUrl}/${id}`)
            .then(() => true, () => false);
    }
}

export default new FleetService();
