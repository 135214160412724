import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import { getClassName } from 'utils/helpers/info-helper';
/* styles */
import './empty-content.scss';

class EmptyContent extends React.PureComponent {
    render() {
        const className = getClassName('sten-empty-content', this.props.className, {
            'sten-empty-content--no-image': this.props.noImage
        });
        return (
            <div className={className}>
                {this.props.children ? (
                    <div className="sten-empty-content__text">{this.props.children}</div>
                ) : <div className="sten-empty-content__text">{translate('EMPTY_CONTENT.NO_DATA_MESSAGE')}</div>}
                <div className="sten-empty-content__image" />
            </div>
        );
    }
}

EmptyContent.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    noImage: PropTypes.bool
};

EmptyContent.defaultProps = {
    children: null,
    className: '',
    noImage: false
};

export default EmptyContent;
