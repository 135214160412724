export const ActionTypes = {
    INSPECTIONS_FORM_SET_AS_NEW_COMPANY: 'INSPECTIONS_FORM_SET_AS_NEW_COMPANY',
    INSPECTIONS_FORM_UPLOAD_FILES: 'INSPECTIONS_FORM_UPLOAD_FILES',
    INSPECTIONS_FORM_SET_UPLOADED_FILES: 'INSPECTIONS_FORM_SET_UPLOADED_FILES',
    INSPECTIONS_FORM_REMOVE_FILE: 'INSPECTIONS_FORM_REMOVE_FILE',
    INSPECTIONS_FORM_SET_VALUE: 'INSPECTIONS_FORM_SET_VALUE',
    INSPECTIONS_FORM_SET_NEW_INSPECTION_COMPANY: 'INSPECTIONS_FORM_SET_NEW_INSPECTION_COMPANY',
    INSPECTIONS_FORM_SUBMIT_INSPECTION_DATA: 'INSPECTIONS_FORM_SUBMIT_INSPECTION_DATA',
    INSPECTIONS_FORM_GET_INSPECTIONS_COMPANIES: 'INSPECTIONS_FORM_GET_INSPECTIONS_COMPANIES',
    INSPECTIONS_FORM_SET_TECHNICAL_MANAGERS: 'INSPECTIONS_FORM_SET_TECHNICAL_MANAGERS',
    INSPECTIONS_FORM_SET_HEAD_OWNERS: 'INSPECTIONS_FORM_SET_HEAD_OWNERS',
    INSPECTIONS_FORM_GET_VESSEL_OPTIONS: 'INSPECTIONS_FORM_GET_VESSEL_OPTIONS',
    INSPECTIONS_FORM_SET_INSPECTION_COMPANIES: 'INSPECTIONS_FORM_SET_INSPECTION_COMPANIES',
    INSPECTIONS_FORM_GET_INSPECTION: 'INSPECTIONS_FORM_GET_INSPECTION',
    INSPECTIONS_FORM_SET_EDITING_INSPECTION: 'INSPECTIONS_FORM_SET_EDITING_INSPECTION',
    INSPECTIONS_FORM_RESET_ALL: 'INSPECTIONS_FORM_RESET_ALL'
};

export function submitInspectionData(dispatch) {
    dispatch({
        type: ActionTypes.INSPECTIONS_FORM_SUBMIT_INSPECTION_DATA
    });
}

export function getVesselOptions(dispatch, vessel) {
    dispatch({
        type: ActionTypes.INSPECTIONS_FORM_GET_VESSEL_OPTIONS,
        vessel
    });
}

export function getInspectionCompanies(dispatch) {
    dispatch({
        type: ActionTypes.INSPECTIONS_FORM_GET_INSPECTIONS_COMPANIES
    });
}

export function getInspection(dispatch, id) {
    dispatch({
        type: ActionTypes.INSPECTIONS_FORM_GET_INSPECTION,
        id
    });
}

export function setValue(dispatch, field, value) {
    dispatch({
        type: ActionTypes.INSPECTIONS_FORM_SET_VALUE,
        field,
        value
    });
}

export function uploadFiles(dispatch, files) {
    dispatch({
        type: ActionTypes.INSPECTIONS_FORM_UPLOAD_FILES,
        files
    });
}

export function removeFile(dispatch, index) {
    dispatch({
        type: ActionTypes.INSPECTIONS_FORM_REMOVE_FILE,
        index
    });
}

export function setAsNewCompany(dispatch, companyName) {
    dispatch({
        type: ActionTypes.INSPECTIONS_FORM_SET_AS_NEW_COMPANY,
        companyName
    });
}

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.INSPECTIONS_FORM_RESET_ALL
    });
}
