import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import Validation from 'components/validation/validation';
import Textarea from 'components/textarea/textarea';
import CommentService from 'services/core-api/comment-service';
import { toast } from 'react-toastify';
import { commentUpdated } from './comment-actions';
import { connect } from 'react-redux';

class CommentForm extends React.PureComponent {
    state = {
        comment: ''
    };

    handleChange = value => {
        this.setState({ comment: value });
    };

    handleSubmit = () => {
        CommentService.submit(this.props.resource, this.props.resourceId, this.state.comment)
            .then(res => {
                if (res) {
                    toast(translate('COMMENTS.COMMENT_SUCCESSFULLY_ADDED'), { type: toast.TYPE.SUCCESS });
                    this.props.onUpdate(this.props.resource, this.props.resourceId, null, this.state.comment);
                    this.setState({ comment: '' });
                    this.form.resetValidation();
                }
            });
    };

    saveFormRef = (c) => { this.form = c; }

    validations = { required: true, maxLength: 1024 };

    render() {
        return (
            <Validation.Form
                className="sten-content__section sten-comments__form"
                onSubmit={this.handleSubmit}
                ref={this.saveFormRef}
            >
                <div className="form-row">
                    <label className="label">{translate('COMMENTS.ADD_NEW_COMMENT')}</label>
                    <Validation.Wrapper hintsOnHover validations={this.validations}>
                        <Textarea
                            className="sten-comments__textarea"
                            name="comment"
                            autoComplete="off"
                            rows={2}
                            value={this.state.comment}
                            onChange={this.handleChange}
                        />
                    </Validation.Wrapper>
                </div>
                <div className="form-row text-right">
                    <Validation.Button className="btn btn--primary">
                        {translate('COMMENTS.POST_COMMENT')}
                    </Validation.Button>
                </div>
            </Validation.Form>
        );
    }
}

CommentForm.propTypes = {
    resource: PropTypes.string.isRequired,
    resourceId: PropTypes.number.isRequired,
    onUpdate: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
    return {
        onUpdate: (resource, resourceId, commentId, comment) =>
            commentUpdated(dispatch, resource, resourceId, commentId, comment)
    };
}

export default connect(null, mapDispatchToProps)(CommentForm);
