export const ActionTypes = {
    PORT_MANAGEMENT_SET: 'PORT_MANAGEMENT_SET',
    PORT_MANAGEMENT_GET: 'PORT_MANAGEMENT_GET',
    PORT_MANAGEMENT_RESET_PORTS: 'PORT_MANAGEMENT_RESET_PORTS',
    PORT_MANAGEMENT_SET_PARAMS: 'PORT_MANAGEMENT_SET_PARAMS',
    PORT_MANAGEMENT_SET_PINNED: 'PORT_MANAGEMENT_SET_PINNED',
    PORT_MANAGEMENT_GET_PINNED: 'PORT_MANAGEMENT_GET_PINNED',
    PORT_MANAGEMENT_SET_OPTIONS: 'PORT_MANAGEMENT_SET_OPTIONS',
    PORT_MANAGEMENT_GET_OPTIONS: 'PORT_MANAGEMENT_GET_OPTIONS',
    PORT_MANAGEMENT_HANDLE_UPDATE: 'PORT_MANAGEMENT_HANDLE_UPDATE',
    PORT_MANAGEMENT_RESET: 'PORT_MANAGEMENT_RESET'
};

export function getPorts(dispatch, params) {
    dispatch({ type: ActionTypes.PORT_MANAGEMENT_GET, params });
}

export function resetPorts(dispatch) {
    dispatch({ type: ActionTypes.PORT_MANAGEMENT_RESET_PORTS });
}

export function getPinnedPorts(dispatch) {
    dispatch({ type: ActionTypes.PORT_MANAGEMENT_GET_PINNED });
}

export function getOptions(dispatch) {
    dispatch({ type: ActionTypes.PORT_MANAGEMENT_GET_OPTIONS });
}

export function handlePortUpdate(dispatch, port, id) {
    dispatch({ type: ActionTypes.PORT_MANAGEMENT_HANDLE_UPDATE, port, id });
}

export function reset(dispatch) {
    dispatch({ type: ActionTypes.PORT_MANAGEMENT_RESET });
}
