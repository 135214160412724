import PropTypes from 'prop-types';

export const TFuelTypeQuantity = PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    Quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    Sequence: PropTypes.number.isRequired
});

export const TFuelData = PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired,
    FuelTypeQuantities: PropTypes.objectOf(TFuelTypeQuantity)
});
