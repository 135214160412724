import React from 'react';
import PropTypes from 'prop-types';
/* helpers */
import {
    compareDateTimes,
    compareDates,
    formatDate,
    formatTime,
    getTimeFormatFromConfig,
    isValidDate,
    isValidTime
} from '../helpers';
/* variables */
import variables from '../variables';
/* formats */
import formats from '../formats';
/* types */
import { DateTimeShape, TimeConfigFieldShape } from '../types';
/* styles */
import './input.scss';

export default class HtecDPInputField extends React.Component {
    constructor(props) {
        super(props);

        const timeConfig = { ...variables.timeConfig, ...this.props.timeConfig };
        let timeFormat = getTimeFormatFromConfig(timeConfig);
        timeFormat = formats.time[timeFormat] ? timeFormat : 'HH:mm';
        this.state = {
            prevDate: null,
            date: null,
            dateInput: '',
            dateFormat: '',
            dateFormatConfig: null,
            timeInput: '',
            timeFormat,
            timeFormatConfig: formats.time[timeFormat]
        };
    }

    static getDerivedStateFromProps(props, state) {
        const dateFormat = formats.date[props.dateFormat] ? props.dateFormat : 'DD/MM/YYYY';
        if (props.date !== state.prevDate || state.dateFormat !== dateFormat) {
            if (props.withTime) {
                if (compareDateTimes(state.date, props.date) !== 0) {
                    return {
                        prevDate: props.date,
                        date: props.date,
                        dateFormat,
                        dateFormatConfig: formats.date[dateFormat],
                        dateInput: formatDate(props.date, dateFormat),
                        timeInput: formatTime(props.date, state.timeFormat)
                    };
                }
            } else if (compareDates(state.date, props.date) !== 0) {
                return {
                    prevDate: props.date,
                    date: props.date,
                    dateFormat: props.dateFormat,
                    dateFormatConfig: formats.date[dateFormat],
                    dateInput: formatDate(props.date, props.dateFormat)
                };
            }
            return {
                prevDate: props.date,
                dateFormat,
                dateFormatConfig: formats.date[dateFormat]
            };
        }
        return null;
    }

    componentWillUnmount() {
        if (this.changeDebounce) {
            clearTimeout(this.changeDebounce);
        }
    }

    parseDateString = (value) => {
        if (!value) {
            return null;
        }
        const matches = value.match(this.state.dateFormatConfig.regex);
        if (matches) {
            const parsedDate = {};
            this.state.dateFormatConfig.matches.forEach((key, index) => {
                if (key) {
                    let value = parseInt(matches[index], 10);
                    if (key === 'year') {
                        if (matches[index].length < 3) {
                            if (value < 50) {
                                value += 2000;
                            } else if (value < 100) {
                                value += 1900;
                            }
                        } else if (matches[index].length < 4) {
                            if (value < 500) {
                                value += 2000;
                            } else if (value < 1000) {
                                value += 1000;
                            }
                        }
                    }
                    parsedDate[key] = value;
                }
            });
            return parsedDate;
        }
        return false;
    };

    parseTimeString = (value) => {
        if (!value) {
            return null;
        }
        const matches = value.match(this.state.timeFormatConfig.regex);
        if (matches) {
            const parsedTime = {};
            this.state.timeFormatConfig.matches.forEach((key, index) => {
                if (key) {
                    parsedTime[key] = parseInt(matches[index], 10);
                }
            });
            if (isNaN(parsedTime.second)) {
                parsedTime.second = 0;
            }
            if (isNaN(parsedTime.minute)) {
                parsedTime.minute = 0;
            }
            return parsedTime;
        }
        return null;
    };

    getValidatedDateTime = (value, type) => {
        let date;
        let time;
        if (type === 'time') {
            if (!value && !this.state.dateInput) {
                return null;
            }
            time = this.parseTimeString(value);
            date = this.parseDateString(this.state.dateInput);
        } else {
            if (!value && !this.state.timeInput) {
                return null;
            }
            date = this.parseDateString(value);
            time = this.parseTimeString(this.state.timeInput);
        }
        let dateTime = { ...date, ...time };
        if (!date || !isValidDate(date)) {
            dateTime.isDateInvalid = true;
            if (this.props.date) {
                dateTime = {
                    ...dateTime,
                    day: this.props.date.day,
                    month: this.props.date.month,
                    year: this.props.date.year
                };
            }
        }
        if (this.props.withTime && (!time || !isValidTime(time))) {
            dateTime.isTimeInvalid = true;
            if (this.props.date) {
                dateTime = {
                    ...dateTime,
                    hour: this.props.date.hour,
                    minute: this.props.date.minute,
                    second: this.props.date.second
                };
            } else {
                dateTime = {
                    ...dateTime,
                    hour: 0,
                    minute: 0,
                    second: 0
                };
            }
        }
        return dateTime;
    };

    clearAll = () => {
        this.setState({ timeInput: '', dateInput: '', date: null });
    };

    handleClick = (e) => {
        // if (this.inputFieldRef && !this.inputFieldRef.contains(e.target) && this.dateInputRef) {
        //     this.dateInputRef.focus();
        // }
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    };

    handleChange = (date) => {
        if (this.changeDebounce) {
            clearTimeout(this.changeDebounce);
        }
        this.changeDebounce = setTimeout(() => {
            this.props.onDateChange(date);
        }, this.props.changeDebounce);
    };

    handleDateChange = (event) => {
        const validatedDate = this.getValidatedDateTime(event.target.value, 'date');
        this.setState({ dateInput: event.target.value, date: validatedDate });
        this.handleChange(validatedDate);
        if (this.timeInputRef
            && validatedDate
            && !validatedDate.isDateInvalid
            && event.target.value.length === this.props.dateFormat.length
            && event.target.selectionStart === event.target.selectionEnd
            && event.target.selectionStart === this.props.dateFormat.length) {
            this.timeInputRef.focus();
        }
    };

    handleDateKeyDown = (event) => {
        if (event.keyCode === 39
            && this.timeInputRef
            && event.target.selectionStart === event.target.selectionEnd
            && event.target.selectionStart === event.target.value.length) {
            this.timeInputRef.focus();
            this.timeInputRef.setSelectionRange(0, 0);
            event.preventDefault();
        } else if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    };

    handleTimeChange = (event) => {
        const validatedDate = this.getValidatedDateTime(event.target.value, 'time');
        this.setState({ timeInput: event.target.value, date: validatedDate });
        this.handleChange(validatedDate);
    };

    handleTimeKeyDown = (event) => {
        if ((event.keyCode === 8 || event.keyCode === 37)
            && this.dateInputRef
            && event.target.selectionStart === event.target.selectionEnd
            && event.target.selectionStart === 0) {
            this.dateInputRef.focus();
            this.dateInputRef.setSelectionRange(this.dateInputRef.value.length, this.dateInputRef.value.length);
            event.preventDefault();
        } else if (this.props.onKeyDown) {
            this.props.onKeyDown(event);
        }
    };

    saveDateInputRef = (c) => { this.dateInputRef = c; };

    saveTimeInputRef = (c) => { this.timeInputRef = c; };

    saveRef = (c) => { this.inputFieldRef = c; };

    render() {
        const props = this.props;

        return (
            <div className="htec-dp-input-field" onClick={this.handleClick} ref={this.saveRef}>
                <div className="htec-dp-input-field__sizing-wrapper">
                    <input
                        ref={this.saveDateInputRef}
                        disabled={this.props.isDisabled}
                        className="htec-dp-input-field__input"
                        placeholder={this.state.dateFormat}
                        type="text"
                        value={this.state.dateInput}
                        onChange={this.handleDateChange}
                        onKeyDown={this.handleDateKeyDown}
                        maxLength={props.dateFormat.length}
                    />
                    <div className="htec-dp-input-field__sizing-field">
                        {this.state.dateInput || this.state.dateFormat}
                    </div>
                </div>
                {props.withTime && (
                    <div className="htec-dp-input-field__sizing-wrapper">
                        <input
                            ref={this.saveTimeInputRef}
                            disabled={this.props.isDisabled}
                            className="htec-dp-input-field__input"
                            placeholder={this.state.timeFormat}
                            type="text"
                            value={this.state.timeInput}
                            onChange={this.handleTimeChange}
                            onKeyDown={this.handleTimeKeyDown}
                            maxLength={this.state.timeFormat.length}
                        />
                        <div className="htec-dp-input-field__sizing-field">
                            {this.state.timeInput || this.state.timeFormat}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

HtecDPInputField.propTypes = {
    changeDebounce: PropTypes.number,
    date: PropTypes.shape(DateTimeShape),
    dateFormat: PropTypes.string,
    isDisabled: PropTypes.bool,
    onClick: PropTypes.func,
    onDateChange: PropTypes.func.isRequired,
    onKeyDown: PropTypes.func,
    timeConfig: PropTypes.shape({
        hour: PropTypes.shape(TimeConfigFieldShape),
        minute: PropTypes.shape(TimeConfigFieldShape),
        second: PropTypes.shape(TimeConfigFieldShape)
    }),
    withTime: PropTypes.bool
};

HtecDPInputField.defaultProps = {
    changeDebounce: 200,
    date: '',
    dateFormat: 'DD/MM/YYYY',
    isDisabled: false,
    onClick: undefined,
    onKeyDown: undefined,
    timeConfig: null,
    withTime: false
};
