import CoreApi from './core-api';

class CompanyManagementService {
    constructor() {
        this.resourceUrl = 'CompanyManagement';
    }

    Departments = {
        get: () => {
            return CoreApi.get(`${this.resourceUrl}/Departments`).then(response => response.data, () => null);
        },

        getUserCountById: (id) => {
            return CoreApi.get(`${this.resourceUrl}/Departments/${id}/UserCount`)
                .then(response => response.data, () => null);
        },

        delete: (id) => {
            return CoreApi.remove(`${this.resourceUrl}/Departments/${id}`)
                .then(() => true, () => false);
        },

        update: (id, data) => {
            return CoreApi.put(`${this.resourceUrl}/Departments/${id}`, data)
                .then(response => response.data, () => null);
        },

        add: (data) => {
            return CoreApi.post(`${this.resourceUrl}/Departments`, data)
                .then(response => response.data, () => null);
        }
    };

    PlatformStatistics = {
        get: () => {
            return CoreApi.get(`${this.resourceUrl}/PlatformStatistics`).then(response => response.data, () => null);
        }
    };
}

export default new CompanyManagementService();
