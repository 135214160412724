export const ActionTypes = {
    NOTIFICATION_SETTINGS_GET: 'NOTIFICATION_SETTINGS_GET',
    NOTIFICATION_SETTINGS_SET: 'NOTIFICATION_SETTINGS_SET',
    NOTIFICATION_SETTINGS_GET_FLEETS: 'NOTIFICATION_SETTINGS_GET_FLEETS',
    NOTIFICATION_SETTINGS_SET_FLEETS: 'NOTIFICATION_SETTINGS_SET_FLEETS',
    NOTIFICATION_SETTINGS_GET_CUSTOM_FLEETS: 'NOTIFICATION_SETTINGS_GET_CUSTOM_FLEETS',
    NOTIFICATION_SETTINGS_SET_CUSTOM_FLEETS: 'NOTIFICATION_SETTINGS_SET_CUSTOM_FLEETS',
    NOTIFICATION_SETTINGS_UPDATE: 'NOTIFICATION_SETTINGS_UPDATE',
    NOTIFICATION_SETTINGS_UPDATE_FLEETS: 'NOTIFICATION_SETTINGS_UPDATE_FLEETS'
};

export function getSettings(dispatch) {
    dispatch({ type: ActionTypes.NOTIFICATION_SETTINGS_GET });
}

export function getFleets(dispatch) {
    dispatch({ type: ActionTypes.NOTIFICATION_SETTINGS_GET_FLEETS });
}

export function getCustomFleets(dispatch) {
    dispatch({ type: ActionTypes.NOTIFICATION_SETTINGS_GET_CUSTOM_FLEETS });
}

export function updateSetting(dispatch, id, data) {
    dispatch({ type: ActionTypes.NOTIFICATION_SETTINGS_UPDATE, id, data });
}

export function updateSettingFleets(dispatch, id, fleets) {
    dispatch({ type: ActionTypes.NOTIFICATION_SETTINGS_UPDATE_FLEETS, id, fleets });
}
