import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* helpers */
import { translate } from 'utils/i18n/i18n-model';
import TimeHelper from 'utils/helpers/time-helper';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.HEADER.${key}`);

const dateOptions = { utc: true };

class EnergyManagementReportsPreviewHeader extends React.Component {
    renderTitle = () => {
        const {
            generatedReportType,
            selectedVessel,
            selectedVoyage,
            selectedRange,
            rangeSelectorActive
        } = this.props;
        if (generatedReportType && selectedVessel) {
            if (generatedReportType.Id === 1 && selectedVoyage && !rangeSelectorActive) {
                return (
                    <React.Fragment>
                        <h1 className="text-uppercase">{this.props.selectedVessel.Title}</h1>
                        <div className="flex-row">
                            <p className="flex-shrink">
                                <span className="text-secondary">{`${t('VOYAGE_NUMBER')}: `}</span>
                                {this.props.selectedVoyage.VoyageNumber}
                            </p>
                            <p className="flex-shrink">
                                <span className="text-secondary">{`${t('START')}: `}</span>
                                {this.props.selectedVoyage.StartDate
                                    ? TimeHelper.getFormatted(this.props.selectedVoyage.StartDate)
                                    : '-'}
                            </p>
                            <p className="flex-shrink">
                                <span className="text-secondary">{`${t('END')}: `}</span>
                                {this.props.selectedVoyage.EndDate
                                    ? TimeHelper.getFormatted(this.props.selectedVoyage.EndDate)
                                    : '-'}
                            </p>
                        </div>
                    </React.Fragment>
                );
            }
            if (selectedRange) {
                return (
                    <React.Fragment>
                        <h1 className="text-uppercase">{this.props.selectedVessel.Title}</h1>
                        <p className="text-secondary">
                            {`${TimeHelper.getFormatted(this.props.selectedRange.rangeStart, dateOptions)} - `
                                + `${TimeHelper.getFormatted(this.props.selectedRange.rangeEnd, dateOptions)}`}
                        </p>
                    </React.Fragment>
                );
            }
        }
        return null;
    };

    render() {
        return (
            <div className="sten-energy-management-reports-preview-header sten-content__section">
                {this.renderTitle()}
            </div>
        );
    }
}

EnergyManagementReportsPreviewHeader.propTypes = {
    generatedReportType: PropTypes.objectOf(PropTypes.any),
    rangeSelectorActive: PropTypes.bool,
    selectedRange: PropTypes.objectOf(PropTypes.any),
    selectedVessel: PropTypes.objectOf(PropTypes.any),
    selectedVoyage: PropTypes.objectOf(PropTypes.any)
};

EnergyManagementReportsPreviewHeader.defaultProps = {
    generatedReportType: null,
    rangeSelectorActive: false,
    selectedRange: null,
    selectedVessel: null,
    selectedVoyage: null
};

function mapStateToProps(state) {
    return {
        generatedReportType: state.energyManagementReportsReducer.generatedReportType,
        rangeSelectorActive: state.energyManagementReportsReducer.generatedReportSettings.rangeSelectorActive,
        selectedRange: state.energyManagementReportsReducer.generatedReportSettings.selectedRange,
        selectedVessel: state.energyManagementReportsReducer.generatedReportSettings.selectedVessel,
        selectedVoyage: state.energyManagementReportsReducer.generatedReportSettings.selectedVoyage
    };
}

export default connect(mapStateToProps)(EnergyManagementReportsPreviewHeader);
