import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* helpers */
import { formatNumber, getSeverity, getVesselTechnicalDetails } from 'utils/helpers/info-helper';
/* actions */
import { getBasicSegmentData, setSortProp, resetAll } from '../segment-actions';
/* selectors */
import { getTotalData, getSegmentVessels } from '../segment-selectors';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
import InfoGraphSection from '../../shared/info-graph-section/info-graph-section';
import TableHeadCell from 'components/table-head-cell/table-head-cell';
import BasicTableCell from './basic-table-cell';
import EmptyContent from 'components/empty-content/empty-content';

const getTableHeaderCells = () => {
    const tableHeaderCells = [
        { title: 'Name', sortProp: 'Vessel.Title', sortable: true },
        { title: 'Ballast Bunker', sortProp: 'BallastKPIRatio', sortable: true },
        { title: 'Laden Bunker', sortProp: 'LadenKPIRatio', sortable: true },
        { title: 'Average Cargo', sortProp: 'WeightedCargoRatio', sortable: true },
        { title: 'Total Change', sortProp: 'TotalChange', sortable: true }
    ];

    if (ConfigService.featureToggles.showKpiEnergyEfficiencyLayout) {
        tableHeaderCells.push({ title: 'Energy Efficiency', sortProp: null, sortable: false });
    }

    return tableHeaderCells;
};

class EnergyManagementKPISegmentBasic extends React.PureComponent {
    componentDidMount() {
        if (this.props.filters.selectedRangeOption) {
            this.props.getBasicSegmentData(this.props.router.params.segmentId, this.props.filters);
        }
    }

    componentDidUpdate(prevProps) {
        const { params: prevParams } = prevProps.router;
        const { params } = this.props.router;
        if ((prevParams.segmentId !== params.segmentId || prevProps.filters !== this.props.filters)
            && this.props.filters.selectedRangeOption) {
            this.props.getBasicSegmentData(this.props.router.params.segmentId, this.props.filters);
        }
    }

    componentWillUnmount() {
        this.props.resetAll();
    }

    getSeverityClass = (value) => {
        let className = 'col-lg-9 text-center';
        if (value) {
            className += ` ${getSeverity({ 'text-danger': '[0,Infinity)' }, value)}`;
        }
        return className;
    };

    getFormattedValue = (value, unit) => {
        return value ? `${formatNumber(value, 1, true)} ${unit}` : '-';
    };

    setSortProp = sortProp => {
        this.props.setSortProp(sortProp);
    };

    render() {
        if (!this.props.segmentVessels) { return null; }
        const { totalData, router } = this.props;
        const { selectedRangeOption, selectedRangeEnd } = this.props.filters;
        if (!this.props.segmentVessels.length) {
            return <EmptyContent />;
        }
        return (
            <ScrollArea className="sten-energy-management-kpi-segment-basic sten-content__body">
                <InfoGraphSection
                    ballastConsumption={{
                        total: totalData.data?.BallastKPIRatio,
                        year1: totalData.data?.BallastKPI,
                        year2: totalData.data?.PreviousBallastKPI
                    }}
                    ladenConsumption={{
                        total: totalData.data?.LadenKPIRatio,
                        year1: totalData.data?.LadenKPI,
                        year2: totalData.data?.PreviousLadenKPI
                    }}
                    averageCargo={{
                        total: totalData.data?.WeightedCargoRatio,
                        year1: totalData.data?.WeightedCargoLaden,
                        year2: totalData.data?.PreviousWeightedCargoLaden
                    }}
                    energyEfficiency={{
                        total: totalData.data?.EnergyEfficiencyRatio,
                        year1: totalData.data?.EnergyEfficiency,
                        year2: totalData.data?.PreviousEnergyEfficiency
                    }}
                    aer={{
                        total: totalData.data?.AERRatio,
                        year1: totalData.data?.AER,
                        year2: totalData.data?.PreviousAER
                    }}
                    consumptionChartData={totalData.consumptionChartData}
                    energyEfficiencyChartData={totalData.energyEfficiencyChartData}
                    rangeOption={selectedRangeOption}
                    rangeEndDate={selectedRangeEnd}
                />
                <div className="sten-content__section">
                    <div className="sten-panel">
                        <ScrollArea
                            horizontal
                            vertical={false}
                            contentClassName="sten-kpi-segment-basic__table-horizontal-scroll"
                        >
                            <table className="sten-table sten-table--sm sten-kpi-segment-basic__table">
                                <thead>
                                    <tr>
                                        {getTableHeaderCells().map((cell, index) => (
                                            <TableHeadCell
                                                sortOrder={
                                                    this.props.sortProp === cell.sortProp
                                                        ? this.props.sortOrder
                                                        : ''
                                                }
                                                onClick={this.setSortProp.bind(this, cell.sortProp)}
                                                sortable={cell.sortable}
                                                key={index} // eslint-disable-line react/no-array-index-key
                                            >
                                                {cell.title}
                                            </TableHeadCell>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.props.segmentVessels.map((vessel, index) => (
                                        // eslint-disable-next-line react/no-array-index-key
                                        <tr key={index}>
                                            <td>
                                                <Link
                                                    className="
                                                        sten-h3 sten-kpi-segment-basic__table-title sten-title-link"
                                                    to={{
                                                        pathname: `vessel/${vessel.Vessel.Imo}`,
                                                        search: router.location.search
                                                    }}
                                                >
                                                    {vessel.Vessel.Title}
                                                </Link>
                                                <div className="text-secondary">
                                                    {getVesselTechnicalDetails(vessel.Vessel)}
                                                </div>
                                            </td>
                                            <BasicTableCell totalValue={vessel.BallastKPIRatio} />
                                            <BasicTableCell totalValue={vessel.LadenKPIRatio} />
                                            <BasicTableCell totalValue={vessel.WeightedCargoRatio} isCargo />
                                            <td>
                                                <h1 className={this.getSeverityClass(vessel.TotalChange)}>
                                                    {this.getFormattedValue(vessel.TotalChange, '%')}
                                                </h1>
                                            </td>
                                            {ConfigService.featureToggles.showKpiEnergyEfficiencyLayout && (
                                                <BasicTableCell
                                                    totalValue={vessel.EnergyEfficiencyRatio}
                                                    value={vessel.EnergyEfficiency}
                                                    prevValue={vessel.PreviousEnergyEfficiency}
                                                    selectedRangeOption={selectedRangeOption}
                                                    selectedRangeEnd={selectedRangeEnd}
                                                    isEnergyEfficiency
                                                />
                                            )}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </ScrollArea>
                    </div>
                </div>
            </ScrollArea>
        );
    }
}

EnergyManagementKPISegmentBasic.propTypes = {
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    getBasicSegmentData: PropTypes.func.isRequired,
    resetAll: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    segmentVessels: PropTypes.arrayOf(PropTypes.object),
    setSortProp: PropTypes.func.isRequired,
    sortOrder: PropTypes.string.isRequired,
    sortProp: PropTypes.string.isRequired,
    totalData: PropTypes.objectOf(PropTypes.any).isRequired
};

EnergyManagementKPISegmentBasic.defaultProps = {
    segmentVessels: null
};

function mapStateToProps(state) {
    return {
        totalData: getTotalData(state),
        segmentVessels: getSegmentVessels(state),
        sortOrder: state.energyManagementKPISegmentReducer.sortOrder,
        sortProp: state.energyManagementKPISegmentReducer.sortProp,
        filters: state.energyManagementKPIReducer.filters
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getBasicSegmentData: (segmentId, filters) => getBasicSegmentData(dispatch, segmentId, filters),
        setSortProp: sortProp => setSortProp(dispatch, sortProp),
        resetAll: () => resetAll(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EnergyManagementKPISegmentBasic));
