import React from 'react';
import PropTypes from 'prop-types';
/* types */
import { YearShape } from '../htec-date-picker/types';
/* components */
import DatePickerModeYears from 'components/htec-date-picker/date-picker/date-picker-mode-years';
/* styles */
import './year-picker.scss';

class YearPicker extends React.PureComponent {
    constructor(props) {
        super(props);
        const currentDate = new Date();
        let currentYear;
        if (props.initialYear) {
            currentYear = props.initialYear;
        } else if (props.value) {
            currentYear = props.value.year;
        } else {
            currentYear = currentDate.getFullYear();
        }
        this.state = {
            currentYear: currentYear || currentDate.getFullYear(),
            today: {
                year: currentDate.getFullYear()
            }
        };
    }

    handleYearSelect = (args, e) => {
        e.stopPropagation();
        this.props.onChange(args);
    };

    render() {
        return (
            <div className="sten-year-picker">
                <DatePickerModeYears
                    minDate={this.props.minDate}
                    maxDate={this.props.maxDate}
                    today={this.state.today}
                    onCellClick={this.handleYearSelect}
                    currentYear={this.state.currentYear}
                    selectedDate={this.props.value}
                />
            </div>
        );
    }
}

YearPicker.propTypes = {
    initialYear: PropTypes.number,
    maxDate: PropTypes.shape(YearShape),
    minDate: PropTypes.shape(YearShape),
    onChange: PropTypes.func.isRequired,
    value: PropTypes.shape(YearShape)
};

YearPicker.defaultProps = {
    initialYear: null,
    maxDate: null,
    minDate: null,
    value: null
};

export default YearPicker;
