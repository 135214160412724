import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* types */
import { THomeQueryParams } from '../home-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { renderPanels } from 'components/right-side-bar/right-side-bar-helper';
/* actions */
import {
    toggleActivePanel,
    emptyActivePanels
} from 'components/right-side-bar/right-side-bar-actions';
/* components */
import BerthForm from 'components/terminal-and-berth-info/berth/berth-form';
import BerthInfo from 'components/terminal-and-berth-info/berth/berth-info';
import CharterpartyForm from 'components/charterparty-form/charterparty-form';
import EmployedVesselsPanel from './employed-vessels-panel/employed-vessels-panel';
import FleetOverview from './fleet-overview/fleet-overview';
import IconButton from 'components/icon-button/icon-button';
import MapOptions from 'components/map-options/map-options';
import MapZoomControls from 'components/map-zoom-controls/map-zoom-controls';
import NotificationsPanel from './notifications-panel/notifications-panel';
import PortInfo from 'components/port-info/port-info';
import TerminalForm from 'components/terminal-and-berth-info/terminal/terminal-form';
import TerminalInfo from 'components/terminal-and-berth-info/terminal/terminal-info';
import VesselFilter from './vessel-filter/vessel-filter';
import VesselInfo from './vessel-info/vessel-info';
import VesselReport from 'components/vessel-report/vessel-report';
import Questionnaire from 'components/questionnaire/questionnaire';

const tooltipTitleMap = {
    employedVessels: t('EMPLOYED_VESSELS.TITLE'),
    fleetOverview: t('FLEET_OVERVIEW.TITLE'),
    mapOptions: t('MAP_OPTIONS.TITLE'),
    notifications: t('NOTIFICATIONS.TITLE'),
    vesselFilter: t('VESSEL_FILTER.TITLE')
};

export class HomeRightSideBar extends React.PureComponent {
    componentDidMount() {
        this.updateActivePanel();
    }

    componentDidUpdate(prevProps) {
        this.updateActivePanel(prevProps);
    }

    updateActivePanel(prevProps) {
        if (!prevProps || this.props.queryParams.port !== prevProps.queryParams.port) {
            this.props.toggleActivePanel('port-info', !!this.props.queryParams.port);
        }
        if (!prevProps || this.props.queryParams.vessel !== prevProps.queryParams.vessel) {
            this.props.toggleActivePanel('vessel-info', !!this.props.queryParams.vessel);
        }
        if (!prevProps || this.props.queryParams.reportId !== prevProps.queryParams.reportId) {
            this.props.toggleActivePanel('vessel-report', !!this.props.queryParams.reportId);
        }
        if (!prevProps || this.props.queryParams.cpVoyage !== prevProps.queryParams.cpVoyage) {
            this.props.toggleActivePanel('charterparty-form', !!this.props.queryParams.cpVoyage);
        }
        if (!prevProps || this.props.queryParams.questionnaireId !== prevProps.queryParams.questionnaireId) {
            this.props.toggleActivePanel('questionnaire', this.props.queryParams.questionnaireId !== null);
        }
        if (!prevProps || this.props.queryParams.terminal !== prevProps.queryParams.terminal) {
            this.props.toggleActivePanel('terminal-info', !!this.props.queryParams.terminal);
        }
        if (!prevProps
            || this.props.queryParams.terminalUpdate !== prevProps.queryParams.terminalUpdate
            || this.props.queryParams.terminalAddPort !== prevProps.queryParams.terminalAddPort) {
            this.props.toggleActivePanel('terminal-form', !!this.showTerminalForm());
        }
        if (!prevProps || this.props.queryParams.berth !== prevProps.queryParams.berth) {
            this.props.toggleActivePanel('berth-info', !!this.props.queryParams.berth);
        }
        if (!prevProps
            || this.props.queryParams.berthUpdate !== prevProps.queryParams.berthUpdate
            || this.props.queryParams.berthAddTerminal !== prevProps.queryParams.berthAddTerminal) {
            this.props.toggleActivePanel('berth-form', !!this.showBerthForm());
        }
    }

    componentWillUnmount() {
        this.props.emptyActivePanels();
    }

    handleButtonClick = (panelName) => () => {
        this.props.router.updateQueryParams({
            remove: [
                'reportId',
                'reportType',
                'reportEdit',
                'questionnaireId',
                'questionnaireEdit',
                'terminal',
                'terminalUpdate',
                'berth',
                'berthUpdate'
            ]
        });
        this.props.toggleActivePanel(panelName);
    };

    handleCloseClick = this.props.router.clearQueryParams;

    getButtons = () => {
        const { permissions, userSettings } = this.props;
        const areFiltersChanged = !(userSettings.MapFilterSelectedCompetition === false
            && userSettings.MapFilterSelectedGroups.length === 0
            && userSettings.MapFilterSelectedSegments.length === 0
            && (this.props.companiesCount === 0
                || userSettings.MapFilterSelectedCompanies.length === this.props.companiesCount)
        );
        const areMapOptionsChanged = userSettings
            && (userSettings.MapOptionsMapType > 1
                || userSettings.ShowVesselNames
                || userSettings.ArePortsInvisible
                || userSettings.MapOptionsAreasOfInterestEnabled
                || userSettings.MapOptionsWeatherLayerEnabled
                || userSettings.MapOptionsCountryBordersNamesEnabled);
        return (
            <React.Fragment>
                {permissions && permissions.MenuAlerts && (
                    <IconButton
                        icon="icon-alert"
                        isActive={this.props.activePanels.indexOf('notifications') > -1}
                        notification={this.props.activeNotifications}
                        onClick={this.handleButtonClick('notifications')}
                        tooltipTitle={tooltipTitleMap.notifications}
                    />
                )}
                {permissions && permissions.GetMyTCVessels && permissions.GetMyCargoes && (
                    <IconButton
                        icon="icon-laden"
                        isActive={this.props.activePanels.indexOf('employed-vessels-panel') > -1}
                        onClick={this.handleButtonClick('employed-vessels-panel')}
                        tooltipTitle={tooltipTitleMap.employedVessels}
                    />
                )}
                {permissions && permissions.ShowVesselList && (
                    <IconButton
                        icon="icon-vessel-list"
                        isActive={this.props.activePanels.indexOf('fleet-overview') > -1}
                        onClick={this.handleButtonClick('fleet-overview')}
                        tooltipTitle={tooltipTitleMap.fleetOverview}
                    />
                )}
                <IconButton
                    changeIndicator={areFiltersChanged}
                    icon="icon-filter-outline"
                    isActive={this.props.activePanels.indexOf('vessel-filter') > -1}
                    onClick={this.handleButtonClick('vessel-filter')}
                    tooltipTitle={tooltipTitleMap.vesselFilter}
                />
                <IconButton
                    changeIndicator={areMapOptionsChanged}
                    icon="icon-layers"
                    isActive={this.props.activePanels.indexOf('map-options') > -1}
                    onClick={this.handleButtonClick('map-options')}
                    tooltipTitle={tooltipTitleMap.mapOptions}
                />
                <MapZoomControls />
            </React.Fragment>
        );
    };

    bottomPanelContainer = {
        panelMap: {
            'vessel-filter': () => (
                <VesselFilter
                    key="vessel-filter"
                    onCloseClick={this.handleButtonClick('vessel-filter')}
                />
            ),
            'fleet-overview': () => (
                <FleetOverview
                    key="fleet-overview"
                    onCloseClick={this.handleButtonClick('fleet-overview')}
                    permissions={this.props.permissions}
                />
            ),
            'employed-vessels-panel': () => (
                <EmployedVesselsPanel
                    key="employed-vessels-panel"
                    onCloseClick={this.handleButtonClick('employed-vessels-panel')}
                />
            ),
            'vessel-info': () => <VesselInfo key="vessel-info" />,
            'port-info': () => (
                <PortInfo
                    key="port-info"
                    portId={this.props.queryParams.port}
                    activeTabId={this.props.queryParams.terminalsShown ? 2 : undefined}
                    onCloseClick={this.handleCloseClick}
                />
            ),
            notifications: () => (
                <NotificationsPanel
                    key="notifications"
                    onCloseClick={this.handleButtonClick('notifications')}
                />
            ),
            'map-options': () => (
                <MapOptions
                    key="map-options"
                    onCloseClick={this.handleButtonClick('map-options')}
                />
            )
        },
        buttons: this.getButtons
    };

    showTerminalForm = () => {
        const { permissions, queryParams } = this.props;
        return !!(permissions.AddUpdateTerminal && (queryParams.terminalUpdate || queryParams.terminalAddPort));
    }

    middlePanelContainer = {
        panelMap: {
            'vessel-report': () => (
                <VesselReport
                    key="vessel-report"
                    activeReportId={this.props.queryParams.reportId}
                    activeReportType={this.props.queryParams.reportType}
                    isEditing={this.props.queryParams.reportEdit}
                />
            ),
            'charterparty-form': () => (
                <CharterpartyForm
                    key="charterparty-form"
                    voyageId={this.props.queryParams.cpVoyage}
                    isEditing={this.props.queryParams.cpEdit}
                />
            ),
            questionnaire: () => (
                <Questionnaire
                    key="questionnaire"
                    id={this.props.queryParams.questionnaireId}
                    isEditing={this.props.queryParams.questionnaireEdit}
                />
            ),
            'terminal-info': () => (this.props.queryParams.terminal ? (
                <TerminalInfo key="terminal-info" id={this.props.queryParams.terminal} />
            ) : null),
            'terminal-form': () => (this.showTerminalForm() ? (
                <TerminalForm
                    key="terminal-form"
                    id={this.props.queryParams.terminalUpdate}
                    portId={this.props.queryParams.terminalAddPort}
                    isActive={this.showTerminalForm()}
                />
            ) : null)
        },
        style: {
            zIndex: 4
        }
    };

    showBerthForm = () => {
        const { permissions, queryParams } = this.props;
        return !!(permissions.UpdateBerth && (queryParams.berthUpdate || queryParams.berthAddTerminal));
    }

    topPanelContainer = {
        panelMap: {
            'berth-info': () => (this.props.queryParams.berth ? (
                <BerthInfo key="berth-info" id={this.props.queryParams.berth} />
            ) : null),
            'berth-form': () => (this.showBerthForm() ? (
                <BerthForm
                    key="berth-form"
                    id={this.props.queryParams.berthUpdate}
                    terminalId={this.props.queryParams.berthAddTerminal}
                    isActive={this.showBerthForm()}
                />
            ) : null)
        },
        style: {
            zIndex: 5
        }
    };

    render() {
        return renderPanels(
            this.props.activePanels,
            this.bottomPanelContainer,
            this.middlePanelContainer,
            this.topPanelContainer
        );
    }
}

HomeRightSideBar.propTypes = {
    activeNotifications: PropTypes.number.isRequired,
    activePanels: PropTypes.arrayOf(PropTypes.string).isRequired,
    companiesCount: PropTypes.number.isRequired,
    emptyActivePanels: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    queryParams: THomeQueryParams.isRequired,
    router: TRouter.isRequired,
    toggleActivePanel: PropTypes.func.isRequired,
    userSettings: PropTypes.objectOf(PropTypes.any).isRequired
};

function mapStateToProps(state) {
    return {
        activeNotifications: state.notificationsReducer.activeNotifications
            ? state.notificationsReducer.activeNotifications.length
            : 0,
        activePanels: state.rightSideBarReducer.activePanels,
        companiesCount: state.vesselFilterReducer.companies.length,
        permissions: state.userReducer.permissions,
        userSettings: state.userReducer.settings
    };
}

function mapDispatchToProps(dispatch) {
    return {
        emptyActivePanels: () => emptyActivePanels(dispatch),
        toggleActivePanel: (panelName, enable) => toggleActivePanel(dispatch, panelName, enable)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeRightSideBar));
