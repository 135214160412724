import React from 'react';
import PropTypes from 'prop-types';
/* helpers */
import { getClassName } from 'utils/helpers/info-helper';
/* components */
import Portal from 'components/portal/portal';
import MenuItem from './menu-item/menu-item';
import SubMenu from './sub-menu/sub-menu';
/* styles */
import './menu.scss';

class Menu extends React.PureComponent {
    state = {
        active: false
    };

    position = {};

    getChildContext() {
        return {
            toggleMenu: this.toggleMenu
        };
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.closeMenuOnClickOutside);
        if (this.state.active) {
            document.removeEventListener('scrollChanged', this.closeMenuOptionsOnScroll);
        }
    }

    toggleMenu = (active, e) => {
        if (!active) {
            this.toggleMenuOptions(active, e);
        }
    };

    closeMenuOnClickOutside = e => {
        if (this.optionsMenu && !this.optionsMenu.contains(e.target)) {
            this.toggleMenuOptions(false, e);
        }
    };

    closeMenuOptionsOnScroll = () => {
        this.toggleMenuOptions(false);
    };

    toggleMenuOptions = (active, event) => {
        if (this.props.isDisabled) {
            return;
        }
        const activeIsBool = typeof (active) === 'boolean';
        if (activeIsBool && active !== this.state.active) {
            if (event && this.props.stopPropagation) {
                event.stopPropagation();
                if (event.target === this.threeDotIconRef) {
                    event.preventDefault();
                }
            }
            if (!this.state.active) {
                document.addEventListener('click', this.closeMenuOnClickOutside);
                document.addEventListener('scrollChanged', this.closeMenuOptionsOnScroll);
                this.setState({ active: true });
            } else {
                document.removeEventListener('click', this.closeMenuOnClickOutside);
                document.removeEventListener('scrollChanged', this.closeMenuOptionsOnScroll);
                this.setState({ active: false });
            }
        }
    };

    saveMenuRef = (c) => { this.optionsMenu = c; };

    renderMenuItems() {
        let collapseDown = this.props.collapseDown;
        let boundingBox = null;
        if (this.threeDotIconRef) {
            boundingBox = this.threeDotIconRef.getBoundingClientRect();
            if (boundingBox.top < window.innerHeight / 2) {
                collapseDown = true;
            }
        }
        const menuItemsClass = getClassName('sten-menu__items', this.props.itemsClassName, {
            'sten-menu__items--visible': this.state.active,
            'sten-menu__items--right': this.props.menuRight,
            'sten-menu__items--collapse-down': collapseDown
        });
        const className = !this.props.children.some((child) => child && child.props.icon) ? 'sten-menu--no-icons' : '';

        return (
            <Portal>
                <div
                    className={className}
                    style={{
                        position: 'fixed',
                        left: boundingBox ? boundingBox.left : 0,
                        top: boundingBox ? boundingBox.top : 0,
                        zIndex: 9999
                    }}
                    ref={this.saveMenuRef}
                >
                    <div className={menuItemsClass}>
                        {this.props.children}
                    </div>
                </div>
            </Portal>
        );
    }

    saveThreeDotIconRef = (c) => { this.threeDotIconRef = c; };

    render() {
        const menuClass = getClassName('sten-menu', this.props.className, {
            'sten-menu--active': this.state.active,
            'sten-menu--disabled': this.props.isDisabled
        });

        return (
            <div className={menuClass} onClick={this.props.onClick}>
                <span
                    ref={this.saveThreeDotIconRef}
                    className={`sten-menu__icon icon icon-${this.props.menuIcon}`}
                    onClick={this.toggleMenuOptions.bind(this, !this.state.active)}
                />
                {this.state.active && this.renderMenuItems()}
            </div>
        );
    }
}

Menu.childContextTypes = {
    toggleMenu: PropTypes.func.isRequired
};

Menu.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    collapseDown: PropTypes.bool,
    isDisabled: PropTypes.bool,
    itemsClassName: PropTypes.string,
    menuRight: PropTypes.bool,
    menuIcon: PropTypes.string,
    onClick: PropTypes.func,
    stopPropagation: PropTypes.bool
};

Menu.defaultProps = {
    children: null,
    className: '',
    collapseDown: false,
    isDisabled: false,
    itemsClassName: '',
    menuRight: false,
    menuIcon: 'dots-vertical',
    onClick: undefined,
    stopPropagation: false
};

export { MenuItem, SubMenu };

export default Menu;
