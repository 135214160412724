import React from 'react';
import PropTypes from 'prop-types';
import LinesEllipsis from 'react-lines-ellipsis';
/* components */
import ShowMoreButton from 'components/show-more-button/show-more-button';

export class LongTextCell extends React.PureComponent {
    state = {
        isExpanded: false,
        isClamped: false
    };

    toggleExpand = () => {
        this.setState({ isExpanded: !this.state.isExpanded }, this.props.onClick);
    };

    checkIfClamped = () => {
        if (this.linesElipsisRef) {
            const isClamped = this.linesElipsisRef.isClamped();
            if (this.state.isClamped !== isClamped) {
                this.setState({ isClamped });
            }
        }
    };

    componentDidMount() {
        this.checkIfClamped();
    }

    componentDidUpdate() {
        this.checkIfClamped();
    }

    saveRef = (r) => {
        this.linesElipsisRef = r;
    };

    render() {
        const { text } = this.props;

        return (
            <div className="long-text-cell text-word-break">
                {this.state.isExpanded
                    ? <p className="text-left text-ellipsis">{text}</p>
                    : (
                        <LinesEllipsis
                            ref={this.saveRef}
                            text={text}
                            maxLine={this.props.numLinesToShow}
                            className="text-left"
                        />
                    )
                }
                {this.state.isClamped && (
                    <ShowMoreButton
                        className="long-text-cell__show-more-link"
                        isMoreShown={this.state.isExpanded}
                        onClick={this.toggleExpand}
                    />
                )}
            </div>
        );
    }
}

LongTextCell.propTypes = {
    text: PropTypes.string.isRequired,
    numLinesToShow: PropTypes.number,
    onClick: PropTypes.func
};

LongTextCell.defaultProps = {
    numLinesToShow: 1,
    onClick: () => {}
};

export default LongTextCell;
