/* utils */
import { sortByProp } from 'utils/helpers/array-helper';
/* actions */
import { ActionTypes } from './port-info-actions';
import { ActionTypes as TerminalActionTypes } from 'components/terminal-and-berth-info/terminal/terminal-info-actions';
import { ActionTypes as BerthActionTypes } from 'components/terminal-and-berth-info/berth/berth-info-actions';

const emptyArray = [];

const initialState = {
    isMounted: false,
    port: null,
    activities: emptyArray,
    comments: emptyArray,
    bunkerPrices: null,
    bunkeringTypes: emptyArray,
    services: emptyArray,
    terminals: emptyArray
};

export default function portInfoReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.PORT_INFO_SET_RESOURCES: {
        return {
            ...state,
            isMounted: true,
            port: action.port,
            activities: action.activities || emptyArray,
            comments: action.comments || emptyArray,
            bunkerPrices: action.bunkerPrices || null,
            bunkeringTypes: action.bunkeringTypes || emptyArray,
            services: action.services || emptyArray,
            terminals: action.terminals || emptyArray
        };
    }
    case ActionTypes.PORT_INFO_UPDATE: {
        if (state.isMounted && action.port) {
            return {
                ...state,
                port: action.port
            };
        }
        return state;
    }
    case TerminalActionTypes.TERMINAL_INFO_UPDATED: {
        if (!state.isMounted) {
            return state;
        }
        if (action.terminal) {
            const terminals = [...state.terminals];
            const foundIndex = terminals.findIndex(t => t.TerminalId === action.id);
            if (foundIndex >= 0) {
                terminals.splice(foundIndex, 1, {
                    ...terminals[foundIndex],
                    Location: action.terminal.Position,
                    TerminalId: action.terminal.TerminalId,
                    TerminalName: action.terminal.TerminalName
                });
            } else {
                terminals.push({
                    Location: action.terminal.Position,
                    TerminalId: action.terminal.TerminalId,
                    TerminalName: action.terminal.TerminalName,
                    Berths: []
                });
            }
            terminals.sort(sortByProp('TerminalName'));
            return { ...state, terminals };
        }
        return state;
    }
    case BerthActionTypes.BERTH_INFO_UPDATED: {
        if (!state.isMounted) {
            return state;
        }
        if (action.berth) {
            const terminals = [...state.terminals];
            const foundTerminalIndex = terminals.findIndex(t => t.TerminalId === action.berth.TerminalId);
            const newTerminal = { ...terminals[foundTerminalIndex], Berths: [...terminals[foundTerminalIndex].Berths] };
            const foundIndex = newTerminal.Berths.findIndex(t => t.Id === action.id);
            if (foundIndex >= 0) {
                newTerminal.Berths.splice(foundIndex, 1, { Id: action.id, Name: action.berth.BerthName });
            } else {
                newTerminal.Berths.push({ Id: action.id, Name: action.berth.BerthName });
            }
            newTerminal.Berths.sort(sortByProp('BerthName'));
            terminals.splice(foundTerminalIndex, 1, newTerminal);
            return { ...state, terminals };
        }
        return state;
    }
    case ActionTypes.PORT_INFO_SET_COMMENTS: {
        return {
            ...state,
            comments: action.comments || emptyArray
        };
    }
    case ActionTypes.PORT_INFO_RESET: {
        return {
            ...initialState,
            bunkeringTypes: state.bunkeringTypes,
            services: state.services
        };
    }
    default:
        return state;
    }
}
