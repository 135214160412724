import { createSelector } from 'reselect';

export const getReports = createSelector(
    state => state.energyManagementReportsMultiVoyageReportReducer.multiVoyageReportDetails,
    (reports) => reports.sort((a, b) => a.VoyageNumber - b.VoyageNumber)
);

export const getVoyageNumbers = createSelector(
    getReports,
    (reports) => reports.map(r => r.VoyageNumber)
);
