export const ActionTypes = {
    QUESTIONNAIRE_GET: 'QUESTIONNAIRE_GET',
    QUESTIONNAIRE_SET: 'QUESTIONNAIRE_SET',
    QUESTIONNAIRE_DELETE: 'QUESTIONNAIRE_DELETE',
    QUESTIONNAIRE_DELETED: 'QUESTIONNAIRE_DELETED',
    QUESTIONNAIRE_UPDATE: 'QUESTIONNAIRE_UPDATE',
    QUESTIONNAIRE_UPDATED: 'QUESTIONNAIRE_UPDATED',
    QUESTIONNAIRE_GET_EDITING_OPTIONS: 'QUESTIONNAIRE_GET_EDITING_OPTIONS',
    QUESTIONNAIRE_SET_EDITING_OPTIONS: 'QUESTIONNAIRE_SET_EDITING_OPTIONS',
    QUESTIONNAIRE_RESET_ALL: 'QUESTIONNAIRE_RESET_ALL'
};

export function getQuestionnaire(dispatch, id) {
    dispatch({ type: ActionTypes.QUESTIONNAIRE_GET, id });
}

export function getEditingOptions(dispatch) {
    dispatch({ type: ActionTypes.QUESTIONNAIRE_GET_EDITING_OPTIONS });
}

export function deleteQuestionnaire(dispatch, id) {
    dispatch({ type: ActionTypes.QUESTIONNAIRE_DELETE, id });
}

export function updateQuestionnaire(dispatch, data) {
    dispatch({ type: ActionTypes.QUESTIONNAIRE_UPDATE, data });
}

export function resetAll(dispatch) {
    dispatch({ type: ActionTypes.QUESTIONNAIRE_RESET_ALL });
}
