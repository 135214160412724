export const gutter = 2;

const createCols = (gridColumns = 24) => {
    const cols = {};
    for (let i = 1; i <= gridColumns; i++) {
        cols[`.col-${i}`] = {
            width: `${(100 * i) / gridColumns}%`,
            padding: `0 ${gutter / 2}rem`,
            position: 'relative',
            minHeight: '1px',
            float: 'left',
            boxSizing: 'border-box'
        };
    }
    return cols;
};

export default {
    '.row': {
        margin: `0 -${gutter / 2}rem`
    },
    '.row:after': {
        content: '""',
        display: 'table',
        clear: 'both'
    },
    '.flex': {
        display: 'flex'
    },
    '.flex-column': {
        display: 'flex',
        flexDirection: 'column'
    },
    '.flex-shrink': {
        flexShrink: 1
    },
    '.flex-shrink .flex-row': {
        paddingLeft: `${gutter / 2}rem`,
        paddingRight: `${gutter / 2}rem`
    },
    '.flex-grow': {
        flexGrow: 1,
        minWidth: 0
    },
    '.flex-grow .flex-row': {
        paddingLeft: `${gutter / 2}rem`,
        paddingRight: `${gutter / 2}rem`
    },
    '.flex-center': {
        alignItems: 'center'
    },
    ...createCols()
};
