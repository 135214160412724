import { sortByProp } from 'utils/helpers/array-helper';

export const vesselConditions = [
    { Id: 1, Name: 'Ballast' },
    { Id: 2, Name: 'Laden' }
];

export const speedTypes = [
    { Id: 1, Name: 'Full' },
    { Id: 2, Name: 'Eco' }
];

export const convertSpeedInformations = (data) => {
    const res = [];
    vesselConditions.forEach(vc => {
        speedTypes.forEach(st => {
            const speedInfo = data.find(si => si.VesselCondition.Id === vc.Id && si.SpeedType.Id === st.Id);
            if (speedInfo) {
                res.push({
                    ...speedInfo,
                    TotalConsumption: speedInfo.TotalConsumption || (speedInfo.MeConsumption + speedInfo.AuxConsumption)
                });
            } else {
                res.push({
                    VesselCondition: vc,
                    SpeedType: st,
                    Speed: null,
                    TotalConsumption: null,
                    NormalRpm: null
                });
            }
        });
    });
    res.sort(
        sortByProp(item => `${item.SpeedType.Name}${item.VesselCondition.Name}`)
    );
    return res;
};
