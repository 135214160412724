import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SplitPane from 'react-split-pane';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { getClassName } from 'utils/helpers/info-helper';
import getFontSize from 'utils/helpers/cached-font-size';
import TimeHelper from 'utils/helpers/time-helper';
import { calculateCourse } from '../weather-routing-helpers';
/* services */
import ConfigService from 'services/config-api/config-service';
/* actions */
import { getLegData, getLegAisPoints, toggleAisShown, toggleMapShown } from '../weather-routing-actions';
import { getWeatherDataForTimestamp } from 'components/dtn-weather/dtn-weather-actions';
/* selectors */
import { getConvertedLegRequests, getConvertedLegSposRoutes } from '../weather-routing-selectors';
/* components */
import EmptyContent from 'components/empty-content/empty-content';
import BadgeToggle from 'components/badge-toggle/badge-toggle';
import IconButton from 'components/icon-button/icon-button';
import Map from './map/weather-routing-leg-map';
import Modal from 'components/modal/modal';
import WeatherRoutingLegTable from './table/weather-routing-leg-table';
import WeatherRoutingDatePicker from '../date-picker/weather-routing-date-picker';
/* styles */
import './weather-routing-leg.scss';

const contentHeaderHeight = 4.5;
const rightSidebarHeight = 12;
const screenMdMax = 1480;
const checkIntervalLength = 30 * 1000;

const getPaneSizes = () => {
    const docHeight = document.body.offsetHeight;
    const headerHeight = getFontSize() * contentHeaderHeight;
    const sidebarHeight = getFontSize() * rightSidebarHeight;
    return {
        maxSize: docHeight - headerHeight,
        size: document.body.offsetWidth < screenMdMax ? document.body.offsetHeight - headerHeight : docHeight / 2,
        minSize: sidebarHeight
    };
};

export class WeatherRoutingLeg extends React.PureComponent {
    state = {
        modalData: null,
        paneSizes: getPaneSizes(),
        selectedRoutes: [],
        selectedTimestamp: '',
        minTimestamp: '',
        maxTimestamp: '',
        selectedVesselPositions: [],
        selectedVesselAisPosition: null,
        prevSelectedLegAisPoints: null
    };

    checkInterval = null;

    checkIntervalCount = 0;

    static getDerivedStateFromProps(props, state) {
        if (state.prevSelectedLegAisPoints !== props.selectedLegAisPoints) {
            return {
                prevSelectedLegAisPoints: props.selectedLegAisPoints,
                selectedVesselAisPosition: WeatherRoutingLeg.calculateAisPositionForTimestamp(
                    state.selectedTimestamp, props.selectedLegAisPoints
                )
            };
        }
        return null;
    }

    componentDidMount() {
        if (this.props.router.params.legId) {
            this.props.getLegData(this.props.router.params.legId);
            this.setCheckInterval();
        }
        window.addEventListener('resize', this.updatePaneAndMapSize);
    }

    componentDidUpdate(prevProps) {
        const { params } = this.props.router;
        if (params.legId && params.legId !== prevProps.router.params.legId) {
            this.props.getLegData(params.legId);
        }
        if (this.props.selectedLeg !== prevProps.selectedLeg
            || this.props.selectedLegRequests !== prevProps.selectedLegRequests
            || this.props.isAisShown !== prevProps.isAisShown) {
            this.props.getLegAisPoints();
        }
    }

    componentWillUnmount() {
        if (this.checkInterval) {
            clearInterval(this.checkInterval);
        }
        this.props.getLegData();
        window.removeEventListener('resize', this.updatePaneAndMapSize);
    }

    setCheckInterval = () => {
        if (ConfigService.featureToggles.weatherRoutingLegCheckInterval) {
            this.checkInterval = setInterval(() => {
                const hasRequested = this.props.selectedLegRequests?.NotIntended?.find(request =>
                    request.Status.Id === 1);
                this.checkIntervalCount += checkIntervalLength;
                if (hasRequested
                    || this.checkIntervalCount >= ConfigService.featureToggles.weatherRoutingLegCheckInterval) {
                    this.checkIntervalCount = 0;
                    this.props.getLegData(this.props.router.params.legId, true);
                }
            }, checkIntervalLength);
        }
    };

    linkToRequestRouting = () => this.props.router.getLinkTo({ add: { requestEdit: true } });

    calculatePositionForDuration = (points, duration) => {
        const durationInHours = duration / (3600 * 1000);
        let currentDuration = 0;
        let sectionDuration;
        let currentDistance;
        let midDistance = 0;
        let res = null;
        for (let i = 1; i < points.length; i++) {
            if (!points[i].WaypointType || points[i].WaypointType === 'Point') {
                currentDistance = points[i].Distance;
                midDistance += currentDistance;
            } else {
                currentDistance = points[i].Distance - midDistance;
                midDistance = 0;
            }
            sectionDuration = points[i].SpeedOverGround === 0
                ? 0
                : currentDistance / points[i].SpeedOverGround;
            if (currentDuration + sectionDuration < durationInHours) {
                currentDuration += sectionDuration;
            } else {
                const sectionPercentageCovered = (durationInHours - currentDuration) / sectionDuration;
                res = {
                    Longitude: points[i - 1].Longitude
                        + ((points[i].Longitude - points[i - 1].Longitude) * sectionPercentageCovered),
                    Latitude: points[i - 1].Latitude
                        + ((points[i].Latitude - points[i - 1].Latitude) * sectionPercentageCovered),
                    Course: points[i].Course
                };
                break;
            }
        }
        if (!res) {
            res = {
                Longitude: points[points.length - 1].Longitude,
                Latitude: points[points.length - 1].Latitude,
                Course: points[points.length - 1].Course
            };
        }
        return res;
    };

    static calculateAisPositionForTimestamp = (timestamp, aisPoints) => {
        let selectedVesselAisPosition = null;
        for (let i = 0; i < aisPoints.length; i++) {
            if (aisPoints[i].PositionTime <= timestamp) {
                selectedVesselAisPosition = {
                    IsLaden: aisPoints[i].IsLaden,
                    Course: aisPoints[i].Heading || (aisPoints[i - 1]
                        ? calculateCourse(aisPoints[i].Location, aisPoints[i - 1].Location, true)
                        : calculateCourse(aisPoints[i + 1].Location, aisPoints[i].Location, true)),
                    Longitude: aisPoints[i].Location.Longitude,
                    Latitude: aisPoints[i].Location.Latitude
                };
                break;
            }
        }
        return selectedVesselAisPosition;
    };

    handleSelectedRoutesChange = (selectedRoutes) => {
        const currentHour = TimeHelper.getStartOf('hour').toISOString();
        let minTimestamp;
        let maxTimestamp;
        selectedRoutes.forEach(route => {
            if (!minTimestamp || minTimestamp > route.RouteStartDate) {
                minTimestamp = TimeHelper.getStartOf('hour', route.RouteStartDate).toISOString();
            }
            if (!maxTimestamp || maxTimestamp < route.Eta) {
                maxTimestamp = route.Eta;
            }
        });
        maxTimestamp = TimeHelper.getStartOf('hour', maxTimestamp, false, 1).toISOString();
        let selectedTimestamp = this.state.selectedTimestamp;
        if (!selectedTimestamp || selectedTimestamp < minTimestamp || selectedTimestamp > maxTimestamp) {
            if (maxTimestamp < currentHour) {
                selectedTimestamp = maxTimestamp;
            } else if (minTimestamp > currentHour) {
                selectedTimestamp = minTimestamp;
            } else {
                selectedTimestamp = currentHour;
            }
            if (ConfigService.featureToggles.showDTNWeather) {
                this.props.getWeatherDataForTimestamp(selectedTimestamp);
            }
        }

        const selectedVesselPositions = selectedRoutes
            .filter(route => route.RouteStartDate <= selectedTimestamp)
            .map(route => ({
                Id: route.Id,
                Color: route.Color,
                IsLaden: route.VesselCondition.Name === 'Laden',
                ...this.calculatePositionForDuration(
                    route.Points,
                    TimeHelper.getDateDiff(route.RouteStartDate, selectedTimestamp)
                )
            }));

        this.setState({
            selectedRoutes,
            minTimestamp,
            maxTimestamp,
            selectedTimestamp,
            selectedVesselPositions,
            selectedVesselAisPosition:
                WeatherRoutingLeg.calculateAisPositionForTimestamp(selectedTimestamp, this.props.selectedLegAisPoints)
        });
    };

    handleTimestampChange = (selectedTimestamp) => {
        const selectedVesselPositions = this.state.selectedRoutes
            .filter(route => route.RouteStartDate <= selectedTimestamp)
            .map(route => ({
                Id: route.Id,
                Color: route.Color,
                IsLaden: route.VesselCondition.Name === 'Laden',
                ...this.calculatePositionForDuration(
                    route.Points,
                    TimeHelper.getDateDiff(route.RouteStartDate, selectedTimestamp)
                )
            }));

        this.props.getWeatherDataForTimestamp(selectedTimestamp);

        this.setState({
            selectedTimestamp,
            selectedVesselPositions,
            selectedVesselAisPosition:
                WeatherRoutingLeg.calculateAisPositionForTimestamp(selectedTimestamp, this.props.selectedLegAisPoints)
        });
    };

    saveMapRef = c => { this.mapRef = c; };

    saveTableRef = c => { this.tableRef = c; };

    updateMapSize = () => {
        if (this.mapRef && this.mapRef.wrappedInstance && this.mapRef.wrappedInstance.map) {
            window.requestAnimationFrame(this.mapRef.wrappedInstance.map.updateSize);
        }
    };

    updatePaneAndMapSize = () => {
        this.setState({
            paneSizes: getPaneSizes()
        });
        this.updateMapSize();
    };

    refreshMapAndScrollArea = () => {
        this.updateMapSize();
        if (this.tableRef && this.tableRef.tableRef && this.tableRef.tableRef.scrollArea) {
            this.tableRef.tableRef.scrollArea.refresh();
        }
    };

    showModal = (modalData) => this.setState({ modalData });

    handleModalClose = () => this.setState({ modalData: null });

    handleModalAction = () => {
        this.state.modalData.onConfirm();
        this.setState({ modalData: null });
    };

    renderModal = () => {
        const { modalData } = this.state;
        if (!modalData) {
            return null;
        }
        if (modalData.type === 'Delete') {
            return (
                <Modal.Delete
                    {...modalData}
                    isOpen={!!modalData}
                    onCancel={this.handleModalClose}
                    onDelete={this.handleModalAction}
                />
            );
        }
        if (modalData.type === 'Confirm') {
            return (
                <Modal.Confirm
                    {...modalData}
                    isOpen={!!modalData}
                    onCancel={this.handleModalClose}
                    onConfirm={this.handleModalAction}
                />
            );
        }
        return (
            <Modal.Default
                modalSize="sm"
                isOpen={!!modalData}
                onRequestClose={this.handleModalClose}
            >
                <div className="sten-modal__header flex flex-center text-uppercase">
                    <div className="sten-modal__title flex-grow">
                        {modalData.title}
                        {modalData.subtitle && <h6 className="text-secondary">{modalData.subtitle}</h6>}
                    </div>
                    <div className="flex-shrink">
                        <button className="btn-link icon icon-close" onClick={this.handleModalClose} />
                    </div>
                </div>
                <div className="sten-content__section">
                    <p>{modalData.content}</p>
                </div>
            </Modal.Default>
        );
    };

    render() {
        const {
            isAisShown,
            isMapShown,
            isRightSideBarCollapsed,
            selectedLeg,
            selectedLegRequests,
            selectedLegSposRoutes,
            toggleAisShown,
            toggleMapShown
        } = this.props;
        const {
            paneSizes,
            selectedRoutes,
            selectedTimestamp,
            maxTimestamp,
            minTimestamp,
            selectedVesselPositions,
            selectedVesselAisPosition
        } = this.state;
        if (!selectedLeg) {
            return (
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <div className="flex-shrink">
                            <Link
                                className="btn-link icon icon-arrow-left"
                                to={appRoutes.Routing.WeatherRouting}
                            />
                        </div>
                        <div className="flex-grow">
                            <h1 className="text-uppercase">{t('WEATHER_ROUTING.LEG.TITLE')}</h1>
                        </div>
                    </div>
                    <EmptyContent>
                        {t('WEATHER_ROUTING.LEG.NO_INFORMATION')}
                    </EmptyContent>
                </div>
            );
        }
        const className = getClassName('sten-weather-routing-leg', {
            'sten-weather-routing-leg--full-screen': !isMapShown
        });
        const subtitle = `${selectedLeg.Vessel.Title},
            ${selectedLeg.Voyage.VoyageNumber},
            ${selectedLeg.VesselCondition.Name}`;
        return (
            <div className={className}>
                {this.renderModal()}
                <SplitPane
                    onResizerDoubleClick={toggleMapShown}
                    onChange={this.refreshMapAndScrollArea}
                    allowResize={isMapShown}
                    maxSize={paneSizes.maxSize}
                    minSize={paneSizes.minSize}
                    size={!isMapShown ? 0 : paneSizes.size}
                    split="horizontal"
                    pane1ClassName="sten-weather-routing-leg__split-top"
                    pane2ClassName="sten-weather-routing-leg__split-bottom"
                    resizerClassName="sten-split-pane__handle sten-split-pane__handle--horizontal"
                >
                    <div className="sten-weather-routing-leg__map">
                        {isMapShown && (
                            <React.Fragment>
                                <Map
                                    ref={this.saveMapRef}
                                    selectedRoutes={selectedRoutes}
                                    selectedVesselPositions={selectedVesselPositions}
                                    selectedVesselAisPosition={selectedVesselAisPosition}
                                />
                                <WeatherRoutingDatePicker
                                    isRightSideBarCollapsed={isRightSideBarCollapsed}
                                    onChange={this.handleTimestampChange}
                                    maxValue={maxTimestamp}
                                    minValue={minTimestamp}
                                    value={selectedTimestamp}
                                />
                            </React.Fragment>
                        )}
                    </div>
                    <div className="sten-weather-routing-leg__content">
                        <div className="sten-content">
                            <div className="sten-content__header flex-row">
                                <div className="flex-shrink">
                                    <Link
                                        className="btn-link icon icon-arrow-left"
                                        to={appRoutes.Routing.WeatherRouting}
                                    />
                                </div>
                                <div className="flex-shrink">
                                    <h1 className="text-uppercase">{t('WEATHER_ROUTING.LEG.TITLE')}</h1>
                                    <h6 className="text-secondary">{subtitle}</h6>
                                </div>
                                <div className="sten-content__vertical-separator" />
                                <div className="flex-grow">
                                    <BadgeToggle
                                        className="sten-badge-toggle--success"
                                        isActive={isAisShown}
                                        label={t('WEATHER_ROUTING.LEG.AIS')}
                                        title={t('WEATHER_ROUTING.LEG.AIS_TITLE')}
                                        onClick={toggleAisShown}
                                    />
                                </div>
                                <div className="flex-shrink">
                                    <IconButton
                                        isLink
                                        icon="icon-full-screen"
                                        isActive={isMapShown}
                                        onClick={toggleMapShown}
                                        tooltipTitle={isMapShown
                                            ? t('WEATHER_ROUTING.HIDE_THE_MAP')
                                            : t('WEATHER_ROUTING.SHOW_THE_MAP')}
                                    />
                                </div>
                                <div className="sten-content__vertical-separator" />
                                <div className="flex-shrink">
                                    <Link
                                        className="btn btn--link btn--primary text-nowrap"
                                        to={this.linkToRequestRouting()}
                                        title={t('WEATHER_ROUTING.REQUEST_BUTTON_TITLE')}
                                    >
                                        <span className="btn__icon icon icon-request-routing" />
                                        {t('WEATHER_ROUTING.LEG.EXPLORE_NEW_OPTIONS')}
                                    </Link>
                                </div>
                            </div>
                            <div className="sten-content__body">
                                {!selectedLegRequests && !selectedLegSposRoutes
                                    ? <EmptyContent>{t('WEATHER_ROUTING.LEG.NO_REQUESTS')}</EmptyContent>
                                    : (
                                        <WeatherRoutingLegTable
                                            ref={this.saveTableRef}
                                            onSelectedRoutesChange={this.handleSelectedRoutesChange}
                                            selectedLeg={selectedLeg}
                                            selectedLegRequests={selectedLegRequests}
                                            selectedLegSposRoutes={selectedLegSposRoutes}
                                            selectedRoutes={selectedRoutes}
                                            showModal={this.showModal}
                                        />
                                    )}
                            </div>
                        </div>
                    </div>
                </SplitPane>
            </div>
        );
    }
}

WeatherRoutingLeg.propTypes = {
    getLegAisPoints: PropTypes.func.isRequired,
    getLegData: PropTypes.func.isRequired,
    getWeatherDataForTimestamp: PropTypes.func.isRequired,
    isAisShown: PropTypes.bool.isRequired,
    isMapShown: PropTypes.bool.isRequired,
    isRightSideBarCollapsed: PropTypes.bool.isRequired,
    maxDtnWeatherDate: PropTypes.string,
    minDtnWeatherDate: PropTypes.string,
    router: TRouter.isRequired,
    selectedLeg: PropTypes.objectOf(PropTypes.any),
    selectedLegAisPoints: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedLegRequests: PropTypes.objectOf(PropTypes.any),
    selectedLegSposRoutes: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedWeatherTimestamp: PropTypes.string,
    toggleAisShown: PropTypes.func.isRequired,
    toggleMapShown: PropTypes.func.isRequired
};

WeatherRoutingLeg.defaultProps = {
    minDtnWeatherDate: '',
    maxDtnWeatherDate: '',
    selectedLeg: null,
    selectedLegRequests: null,
    selectedWeatherTimestamp: ''
};

function mapStateToProps(state) {
    return {
        isAisShown: state.weatherRoutingReducer.isAisShown,
        isMapShown: state.weatherRoutingReducer.isMapShown,
        isRightSideBarCollapsed: state.rightSideBarReducer.isCollapsed,
        minDtnWeatherDate: state.dtnWeatherReducer.minDate,
        maxDtnWeatherDate: state.dtnWeatherReducer.maxDate,
        selectedLeg: state.weatherRoutingReducer.selectedLeg,
        selectedLegRequests: getConvertedLegRequests(state),
        selectedLegSposRoutes: getConvertedLegSposRoutes(state),
        selectedLegAisPoints: state.weatherRoutingReducer.selectedLegAisPoints,
        selectedWeatherTimestamp: state.dtnWeatherReducer.selectedWeatherTimestamp
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getLegAisPoints: () => getLegAisPoints(dispatch),
        getLegData: (legId, hideLoader = false) => getLegData(dispatch, legId, hideLoader),
        getWeatherDataForTimestamp: (timestamp) => getWeatherDataForTimestamp(dispatch, timestamp),
        toggleAisShown: () => toggleAisShown(dispatch),
        toggleMapShown: () => toggleMapShown(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WeatherRoutingLeg));
