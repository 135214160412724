import { getObjectProp } from './info-helper';

export const mapArrayByProp = (array, prop, convertItem) => {
    const arrayMap = {};
    if (array) {
        array.forEach((item, index) => {
            arrayMap[item[prop]] = convertItem ? convertItem(item, index) : item;
        });
    }
    return arrayMap;
};

export const mapArrayIndexByProp = (array, prop) => {
    const arrayIndexMap = {};
    if (array) {
        array.forEach((item, index) => {
            arrayIndexMap[item[prop]] = index;
        });
    }
    return arrayIndexMap;
};

export const mapArrayByValue = (array, convertItem) => {
    const arrayMap = {};
    array.forEach(item => {
        if (convertItem) {
            arrayMap[item] = convertItem(item);
        } else {
            arrayMap[item] = true;
        }
    });
    return arrayMap;
};

export const joinArrayByProp = (array, prop, separator, fallbackValue) => {
    return array.reduce((res, item, index) => {
        const val = item[prop] || !fallbackValue ? item[prop] : fallbackValue;
        return index > 0
            ? res + separator + val
            : res + val;
    }, '');
};

export const splitArrayByProp = (array, prop) => {
    const arrayMap = {};
    if (array) {
        array.forEach(item => {
            if (!arrayMap[item[prop]]) {
                arrayMap[item[prop]] = [];
            }
            arrayMap[item[prop]].push(item);
        });
    }
    return arrayMap;
};

export const filterMapArray = (array, filter, map) => {
    const res = [];
    array.forEach((item, index) => {
        if (filter(item, index)) {
            res.push(map(item, index));
        }
    });
    return res;
};

/**
 * Find item in nested "prop" of "array" using "condition"
 * @param {array} array Array in which to search
 * @param {string} prop Nested prop in which to search for results
 * @param {function} condition Condition on which to search
 * @returns found item
 * @example
 * const testArray = [{
 *     Id: 0, Name: 'Houston', Terminals: [{ Id: 2, Name: 'Terminal', Berths: [{ Id: 5, Name: 'Berth' }] }]
 * }];
 * console.log(findInNestedArray(testArray, 'Terminals.Berths', b => b.Id === 5)); // { Id: 5, Name: 'Berth' }
 */
export const findInNestedArray = (array, prop, condition) => {
    if (!prop) {
        return array.find(condition);
    }
    const keys = prop.split('.');
    const nextProp = keys.slice(1).join('.');
    let found;

    for (let j = 0; j < array.length; j++) {
        if (array[j][keys[0]] && array[j][keys[0]].length > 0) {
            found = findInNestedArray(array[j][keys[0]], nextProp, condition);
            if (found) {
                break;
            }
        }
    }
    return found;
};

const IntlCollator = Intl && Intl.Collator ? new Intl.Collator() : null;

export const sortByProp = (prop, asc = true, transformValue) => (a, b) => {
    const order = asc ? 1 : -1;
    let aValue;
    let bValue;
    if (typeof prop === 'function') {
        aValue = prop(a);
        bValue = prop(b);
    } else {
        aValue = getObjectProp(a, prop);
        bValue = getObjectProp(b, prop);
    }
    if (typeof transformValue === 'function') {
        aValue = transformValue(aValue);
        bValue = transformValue(bValue);
    }
    if (aValue !== undefined && bValue !== undefined) {
        if (aValue === null && bValue !== null) {
            return -1 * order;
        }
        if (aValue !== null && bValue === null) {
            return order;
        }
        if (aValue === null && bValue === null) {
            return 0;
        }
        if (IntlCollator && typeof aValue === 'string' && typeof bValue === 'string') {
            return order * IntlCollator.compare(aValue, bValue);
        }
        aValue = typeof aValue === 'string' ? aValue.toUpperCase() : aValue;
        bValue = typeof bValue === 'string' ? bValue.toUpperCase() : bValue;
        if (aValue < bValue) {
            return -1 * order;
        }
        if (aValue > bValue) {
            return order;
        }
    }
    return 0;
};

export const sortAsNumber = (asc = true) => (a, b) => {
    const order = asc ? 1 : -1;
    if (a < b) {
        return -1 * order;
    }
    if (a > b) {
        return order;
    }
    return 0;
};

export const sortByIndexMap = (indexMap) => (a, b) => {
    if (indexMap[a] === undefined && indexMap[b] === undefined) {
        return 0;
    }
    if (indexMap[a] < indexMap[b] || indexMap[b] === undefined) {
        return -1;
    }
    if (indexMap[a] > indexMap[b] || indexMap[a] === undefined) {
        return 1;
    }
    return 0;
};

export const reduceArrayToIndexMap = (res, field, index) => {
    res[field] = index;
    return res;
};
