import { takeEvery, put } from 'redux-saga/effects';
/* actions */
import { ActionTypes } from './emission-estimator-page-actions';
/* services */
import PortService from 'services/core-api/port-service';

function* getPorts(action) {
    const ports = yield PortService.get(action.params);
    if (ports) {
        yield put({ type: ActionTypes.EMISSION_ESTIMATOR_PAGE_SET_PORTS, ports });
    }
}

export default function* emissionEstimatorPageSaga() {
    yield takeEvery(ActionTypes.EMISSION_ESTIMATOR_PAGE_GET_PORTS, getPorts);
}
