import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import CommentList from './comment-list';
import CommentForm from './comment-form';
import ScrollArea from 'components/scroll-area/scroll-area';
/* styles */
import './comments.scss';

export class Comments extends React.PureComponent {
    render() {
        if (!this.props.resourceId) {
            return null;
        }
        return (
            <div className="sten-comments">
                {this.props.canCreateComment && (
                    <CommentForm resource={this.props.resource} resourceId={this.props.resourceId} />
                )}
                {this.props.comments && this.props.comments.length > 0 && (
                    <div className="sten-content__section-header text-uppercase">
                        <h4>{translate('COMMENTS.PREVIOUS_COMMENTS')}</h4>
                    </div>
                )}
                {this.props.comments && this.props.comments.length > 0 && (
                    <ScrollArea fixRelativeScroll className="sten-comments__content">
                        <CommentList
                            canUpdateComments={this.props.canUpdateComments}
                            canUpdateAllComments={this.props.canUpdateAllComments}
                            comments={this.props.comments}
                            resource={this.props.resource}
                            resourceId={this.props.resourceId}
                        />
                    </ScrollArea>
                )}
            </div>
        );
    }
}

Comments.propTypes = {
    canCreateComment: PropTypes.bool.isRequired,
    canUpdateAllComments: PropTypes.bool.isRequired,
    canUpdateComments: PropTypes.bool.isRequired,
    comments: PropTypes.arrayOf(PropTypes.object).isRequired,
    resource: PropTypes.string.isRequired,
    resourceId: PropTypes.number
};

Comments.defaultProps = {
    resourceId: null
};

export default Comments;
