export const ActionTypes = {
    CARGO_INQUIRY_SET_PORT: 'CARGO_INQUIRY_SET_PORT',
    CARGO_INQUIRY_SET_DATE: 'CARGO_INQUIRY_SET_DATE',
    CARGO_INQUIRY_SET_INFORMATION: 'CARGO_INQUIRY_SET_INFORMATION',
    CARGO_INQUIRY_SEND_DATA: 'CARGO_INQUIRY_SEND_DATA',
    CARGO_INQUIRY_MESSAGE_SENT: 'CARGO_INQUIRY_MESSAGE_SENT',
    CARGO_INQUIRY_CLOSE_PANEL: 'CARGO_INQUIRY_CLOSE_PANEL'
};

export function setPort(dispatch, portType, port) {
    dispatch({
        type: ActionTypes.CARGO_INQUIRY_SET_PORT,
        portType,
        port
    });
}

export function setDate(dispatch, portType, date) {
    dispatch({
        type: ActionTypes.CARGO_INQUIRY_SET_DATE,
        portType,
        date
    });
}

export function setCargoInformation(dispatch, cargoInformation) {
    dispatch({
        type: ActionTypes.CARGO_INQUIRY_SET_INFORMATION,
        cargoInformation
    });
}

export function sendInquiryData(dispatch) {
    dispatch({
        type: ActionTypes.CARGO_INQUIRY_SEND_DATA
    });
}

export function closeInquiryPanel(dispatch) {
    dispatch({
        type: ActionTypes.CARGO_INQUIRY_CLOSE_PANEL
    });
}
