import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { upperCaseMatch, getVesselTechnicalDetails } from 'utils/helpers/info-helper';
import getFontSize from 'utils/helpers/cached-font-size';
import { splitSegments } from 'components/segment-select/segment-select-helper';
/* actions */
import {
    getVesselsSidebarInitialData, addVessels
} from '../port-calls-analytics-actions';
/* components */
import FixedSizeList from 'components/fixed-size-list/fixed-size-list';
import Select from 'components/select/select';
import Validation from 'components/validation/validation';
import Input from 'components/input/input';
import CheckableListItem from 'components/checkable-list-item/checkable-list-item';
import { height as vesselRowHeight } from 'components/checkable-list-item/constants';
import SelectAllItem from 'components/checkable-list-item/select-all-item';
import TextHighlight from 'components/text-highlight/text-highlight';
import AutoSizer from 'react-virtualized-auto-sizer';
import EmptyContent from 'components/empty-content/empty-content';
/* selectors */
import {
    getVesselsBySegment, getSegmentsById, getVisibleSegments, getAddedVesselsByIMO
} from '../port-calls-analytics-selectors';
import './vessels-sidebar.scss';

const t = (key, props) => translate(`PORT_CALLS_ANALYTICS.${key}`, props);

class VesselsSidebar extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            selectedVesselsByIMO: this.props.initialSelectedVesselsByImo,
            searchCriteria: '',
            fontSize: getFontSize()
        };
    }

    handleResize = () => {
        if (this.state.fontSize !== getFontSize()) {
            this.setState({
                fontSize: getFontSize()
            });
        }
    };

    getVisibleVessels = memoize((vessels, searchCriteria) => {
        if (searchCriteria.length === 0) return vessels;

        const sc = searchCriteria.toUpperCase();

        return vessels.filter(v => upperCaseMatch(v.Title, sc)
            || upperCaseMatch(v.IMO, sc)
            || upperCaseMatch(v.VesselTypeName, sc)
            || upperCaseMatch(v.Owner, sc));
    });

    resetFilters = () => this.setState({
        selectedVesselsByIMO: {},
        searchCriteria: ''
    });

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        if (this.props.segments.length === 0 && this.props.vessels.length === 0) {
            this.props.getVesselsSidebarInitialData();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    renderNoVessels = memoize(() => (
        <div className="spread-v">
            <EmptyContent>{t('NO_VESSELS')}</EmptyContent>
        </div>
    ));

    getSelectedSegmentsMemoized = memoize((vesselsBySegmentId, selectedVesselsByIMO, segmentsById) => {
        const selectedSegments = [];
        let areAllVesselsInSegmentSelected;
        let segmentId;
        for (segmentId in vesselsBySegmentId) {
            areAllVesselsInSegmentSelected = vesselsBySegmentId[segmentId].every(
                (vessel) => selectedVesselsByIMO[vessel.IMO] === true
            );
            if (areAllVesselsInSegmentSelected) {
                selectedSegments.push(segmentsById[segmentId]);
            }
        }
        return selectedSegments;
    });

    getSelectedSegments = () => this.getSelectedSegmentsMemoized(
        this.props.vesselsBySegmentId,
        this.state.selectedVesselsByIMO,
        this.props.segmentsById
    );

    getSelectedFleetsMemoized = memoize((vesselCounts, selectedVesselCounts, fleets) => {
        let fleetId;
        return fleets.filter((fleet) => {
            fleetId = fleet.VesselGroupId;
            return typeof vesselCounts[fleetId] !== 'undefined'
                && vesselCounts[fleetId] === selectedVesselCounts[fleetId];
        });
    });

    getSelectedFleets = () => {
        const vesselCounts = this.getVesselsCountByVesselGroupId();
        const selectedVesselCounts = this.getSelectedVesselsCountByVesselGroupId();
        return this.getSelectedFleetsMemoized(vesselCounts, selectedVesselCounts, this.props.fleets);
    };

    getSelectedVesselsCountMemoized = memoize((selectedVesselsByIMO) => {
        let count = 0;
        for (const imo in selectedVesselsByIMO) {
            if (selectedVesselsByIMO[imo] === true) {
                count++;
            }
        }
        return count;
    });

    getSelectedVesselsCount = () => this.getSelectedVesselsCountMemoized(this.state.selectedVesselsByIMO);

    toggleVesselSelection = (selectedVessel) => {
        const { selectedVesselsByIMO } = this.state;
        this.setState({
            selectedVesselsByIMO: {
                ...selectedVesselsByIMO,
                [selectedVessel.IMO]: !(selectedVesselsByIMO[selectedVessel.IMO] === true)
            }
        });
    };

    setSegmentSelection = (segment, value) => {
        if (this.props.vesselsBySegmentId[segment.VesselTypeId]) {
            const newSelectedVesselsByIMO = {
                ...this.state.selectedVesselsByIMO
            };
            this.props.vesselsBySegmentId[segment.VesselTypeId].forEach((vessel) => {
                newSelectedVesselsByIMO[vessel.IMO] = value;
            });
            this.setState({ selectedVesselsByIMO: newSelectedVesselsByIMO });
        }
    };

    onSegmentSelect = (segment) => {
        this.setSegmentSelection(segment, true);
    };

    onSegmentDeselect = (segment) => {
        this.setSegmentSelection(segment, false);
    };

    onSearchCriteriaChange = (searchCriteria) => {
        this.setState({ searchCriteria });
    };

    areAllVesselsSelected = () => {
        const selectedVesselsCount = this.getSelectedVesselsCount();
        return this.props.vessels.length === selectedVesselsCount;
    };

    toggleSelectAllVessels = () => {
        const value = !(this.areAllVesselsSelected());
        const newSelectedVesselsByIMO = {};
        this.props.vessels.forEach((vessel) => {
            newSelectedVesselsByIMO[vessel.IMO] = value;
        });
        this.setState({
            selectedVesselsByIMO: newSelectedVesselsByIMO
        });
    };

    renderSegmentOption = (segment, searchCriteria) => {
        const { vesselsBySegmentId } = this.props;
        const vesselsInSegment = vesselsBySegmentId[segment.VesselTypeId]
            ? vesselsBySegmentId[segment.VesselTypeId].length
            : 0;

        return (
            <TextHighlight
                input={`${segment.VesselTypeName} (${vesselsInSegment})`}
                highlight={searchCriteria}
            />
        );
    };

    renderFleetOption = (fleet, searchCriteria) => {
        const vesselCounts = this.getVesselsCountByVesselGroupId();
        return (
            <TextHighlight
                input={`${fleet.VesselGroupName} (${vesselCounts[fleet.VesselGroupId] || 0})`}
                highlight={searchCriteria}
            />
        );
    };

    getFleetDisabled = (fleet) => fleet.VesselsCount === 0;

    getVesselsCountByVesselGroupIdMemoized = memoize((vessels) => {
        const vesselCountsByVesselGroupId = {};
        vessels.forEach((vessel) => {
            vessel.VesselGroupIds.forEach((id) => {
                if (typeof vesselCountsByVesselGroupId[id] === 'undefined') {
                    vesselCountsByVesselGroupId[id] = 0;
                }
                vesselCountsByVesselGroupId[id]++;
            });
        });
        return vesselCountsByVesselGroupId;
    });

    getSelectedVesselsCountByVesselGroupIdMemoized = memoize((vessels, selectedVesselsByIMO) => {
        const selectedVesselCountsByVesselGroupId = {};
        vessels.forEach((vessel) => {
            if (selectedVesselsByIMO[vessel.IMO] === true) {
                vessel.VesselGroupIds.forEach((id) => {
                    if (typeof selectedVesselCountsByVesselGroupId[id] === 'undefined') {
                        selectedVesselCountsByVesselGroupId[id] = 0;
                    }
                    selectedVesselCountsByVesselGroupId[id]++;
                });
            }
        });
        return selectedVesselCountsByVesselGroupId;
    });

    getVesselsCountByVesselGroupId = () => this.getVesselsCountByVesselGroupIdMemoized(
        this.props.vessels
    );

    getSelectedVesselsCountByVesselGroupId = () => this.getSelectedVesselsCountByVesselGroupIdMemoized(
        this.props.vessels,
        this.state.selectedVesselsByIMO
    );

    vesselCountValidation = {
        custom: {
            rule: (value) => value > 0 && value <= 500,
            hint: (value) => {
                if (value > 500) {
                    return t('MAX_500_VESSELS');
                }
                return t('NO_VESSELS_SELECTED');
            }
        }
    };

    getVesselIMO = (index) => {
        if (index === 0) return -1; // select all option
        const visibleVessels = this.getVisibleVessels(this.props.vessels, this.state.searchCriteria);
        return visibleVessels[index - 1].IMO;
    };

    cachedVesselTechDetails = {};

    getVesselTechDetails = (vessel) => {
        if (typeof this.cachedVesselTechDetails[vessel.IMO] === 'undefined') {
            this.cachedVesselTechDetails[vessel.IMO] = getVesselTechnicalDetails(vessel);
        }

        return this.cachedVesselTechDetails[vessel.IMO];
    };

    renderSelectAllOption = (style) => {
        return (
            <div style={style}>
                <SelectAllItem
                    isChecked={this.areAllVisibleVesselsSelected()}
                    onClick={this.toggleAllVisibleVesselsSelection}
                />
            </div>
        );
    };

    areAllVisibleVesselsSelected = () => {
        const visibleVessels = this.getVisibleVessels(this.props.vessels, this.state.searchCriteria);
        const { selectedVesselsByIMO } = this.state;
        return visibleVessels.every((vessel) => selectedVesselsByIMO[vessel.IMO] === true);
    };

    toggleAllVisibleVesselsSelection = (e) => {
        e.preventDefault();
        const value = !this.areAllVisibleVesselsSelected();
        this.setVesselsSelection(
            this.getVisibleVessels(this.props.vessels, this.state.searchCriteria),
            value
        );
    };

    setVesselsSelection = (vessels, value) => {
        const newSelectedVesselsByIMO = { ...this.state.selectedVesselsByIMO };
        vessels.forEach((vessel) => {
            newSelectedVesselsByIMO[vessel.IMO] = value;
        });
        this.setState({ selectedVesselsByIMO: newSelectedVesselsByIMO });
    };

    VesselRowComponent = ({ data, index, style }) => {
        if (index === 0) {
            return this.renderSelectAllOption(style);
        }

        const vessel = data.vessels[index - 1];
        return (
            <div style={style}>
                <CheckableListItem
                    isChecked={data.state.selectedVesselsByIMO[vessel.IMO] === true}
                    item={vessel}
                    title={vessel.Title}
                    id={vessel.Imo}
                    onClick={this.toggleVesselSelection}
                    searchCriteria={data.state.searchCriteria}
                    subtitle={this.getVesselTechDetails(vessel)}
                    titleClassName={vessel.IsCompetition ? '' : 'text-primary'}
                />
            </div>
        );
    };

    setFleetSelection = (fleet, value) => {
        const newSelectedVesselsByIMO = {};
        const vesselGroupId = fleet.VesselGroupId;
        const { selectedVesselsByIMO } = this.state;
        this.props.vessels.forEach((vessel) => {
            newSelectedVesselsByIMO[vessel.IMO] = vessel.VesselGroupIds.includes(vesselGroupId)
                ? value
                : selectedVesselsByIMO[vessel.IMO];
        });
        this.setState({ selectedVesselsByIMO: newSelectedVesselsByIMO });
    };

    onFleetSelect = (fleet) => {
        this.setFleetSelection(fleet, true);
    };

    onFleetDeselect = (fleet) => {
        this.setFleetSelection(fleet, false);
    };

    createItemData = memoize((vessels, state) => ({
        vessels,
        state
    }));

    autoSizerStyle = { width: '100%' };

    addVessels = () => {
        const { selectedVesselsByIMO } = this.state;
        const selectedVessels = this.props.vessels.filter(
            (vessel) => selectedVesselsByIMO[vessel.IMO] === true
        );
        this.props.addVessels(selectedVessels);
        this.props.onClose();
    };

    render() {
        const visibleVessels = this.getVisibleVessels(this.props.vessels, this.state.searchCriteria);
        const selectedVesselsCount = this.getSelectedVesselsCount();
        return (
            <Validation.Form onSubmit={this.addVessels} className="sten-port-calls-analytics-sidebar__form">
                <div className="sten-content sten-content--has-footer">
                    <div className="sten-content__header flex-row">
                        <div className="flex-grow">
                            <h1 className="sten-content__title">
                                {t('ADD_VESSELS')}
                            </h1>
                            <p className="sten-content__subtitle">
                                {`${selectedVesselsCount} ${t('VESSELS_SELECTED')}`}
                            </p>
                        </div>
                        <div className="flex-shrink">
                            <button
                                type="button"
                                className="btn-link icon icon-close"
                                onClick={this.props.onClose}
                            />
                        </div>
                    </div>
                    <div className="sten-content__body">
                        <div className="sten-content__section">
                            <div className="form-row">
                                <label className="label">{t('FLEET')}</label>
                                <Select
                                    name="selectedFleets"
                                    searchable
                                    multiple
                                    valueKey="VesselGroupId"
                                    labelKey="VesselGroupName"
                                    options={this.props.fleets}
                                    optionDisabledCallback={this.getFleetDisabled}
                                    optionRenderer={this.renderFleetOption}
                                    placeholder="Select Fleets"
                                    value={this.getSelectedFleets()}
                                    onItemSelect={this.onFleetSelect}
                                    onItemDeselect={this.onFleetDeselect}
                                />
                            </div>
                            <div className="form-row">
                                <label className="label">{t('SEGMENT')}</label>
                                <Select
                                    name="selectedSegments"
                                    searchable
                                    multiple
                                    valueKey="VesselTypeId"
                                    labelKey="VesselTypeName"
                                    options={this.props.segments}
                                    optionRenderer={this.renderSegmentOption}
                                    placeholder="Select Segments"
                                    value={this.getSelectedSegments()}
                                    onItemSelect={this.onSegmentSelect}
                                    onItemDeselect={this.onSegmentDeselect}
                                    splitOptionsIntoSections={splitSegments}
                                />
                            </div>
                            <div className="form-row">
                                <label className="label">{t('VESSELS')}</label>
                                <Input
                                    clearable
                                    value={this.state.searchCriteria}
                                    onChange={this.onSearchCriteriaChange}
                                    placeholder={t('SEARCH_VESSELS')}
                                    title={t('SEARCH_VESSELS_TITLE')}
                                />
                            </div>
                        </div>
                        <div className="sten-content__separator sten-port-calls-analytics-sidebar__vessels-list">
                            <Validation.Wrapper
                                className="sten-port-calls-analytics-sidebar__vessels-validation"
                                validations={this.vesselCountValidation}
                            >
                                <Validation.Value value={selectedVesselsCount} name="vesselCount" />
                            </Validation.Wrapper>
                            {visibleVessels.length > 0 ? (
                                <AutoSizer style={this.autoSizerStyle}>
                                    {({ height, width }) => (
                                        <FixedSizeList
                                            itemData={this.createItemData(visibleVessels, this.state)}
                                            totalHeight={height}
                                            itemCount={visibleVessels.length + 1}
                                            totalWidth={width}
                                            itemSize={this.state.fontSize * vesselRowHeight}
                                            itemKey={this.getVesselIMO}
                                            ItemComponent={this.VesselRowComponent}
                                        />
                                    )}
                                </AutoSizer>
                            ) : this.renderNoVessels()}
                        </div>
                    </div>
                    <footer className="sten-content__footer flex-row">
                        <div className="col-12">
                            <button
                                onClick={this.resetFilters}
                                className="btn btn--secondary col-24"
                                type="button"
                            >
                                {t('CLEAR_ALL')}
                            </button>
                        </div>
                        <div className="col-12">
                            <Validation.Button className="btn btn--primary col-24">
                                {t('CONFIRM')}
                            </Validation.Button>
                        </div>
                    </footer>
                </div>
            </Validation.Form>
        );
    }
}

VesselsSidebar.propTypes = {
    addVessels: PropTypes.func.isRequired,
    fleets: PropTypes.arrayOf(PropTypes.object).isRequired,
    getVesselsSidebarInitialData: PropTypes.func.isRequired,
    initialSelectedVesselsByImo: PropTypes.objectOf(PropTypes.bool).isRequired,
    onClose: PropTypes.func.isRequired,
    segments: PropTypes.arrayOf(PropTypes.object).isRequired,
    segmentsById: PropTypes.objectOf(PropTypes.object).isRequired,
    vessels: PropTypes.arrayOf(PropTypes.object).isRequired,
    vesselsBySegmentId: PropTypes.objectOf(PropTypes.array).isRequired
};

function mapStateToProps(state) {
    return {
        fleets: state.portCallsAnalyticsReducer.fleets,
        initialSelectedVesselsByImo: getAddedVesselsByIMO(state),
        segments: getVisibleSegments(state),
        segmentsById: getSegmentsById(state),
        vessels: state.portCallsAnalyticsReducer.vessels,
        vesselsBySegmentId: getVesselsBySegment(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addVessels: addVessels.bind(null, dispatch),
        getVesselsSidebarInitialData: getVesselsSidebarInitialData.bind(null, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VesselsSidebar);
