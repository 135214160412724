import React from 'react';
import PropTypes from 'prop-types';
/* components */
import Chart from 'components/chart/chart';
/* styles */
import './graph-panel.scss';

class EnergyManagementKPIGraphPanel extends React.PureComponent {
    render() {
        return (
            <div className="sten-panel sten-energy-management-kpi-graph-panel">
                <div className="sten-panel__header">
                    <h2 className="text-uppercase flex-grow">{this.props.title}</h2>
                </div>
                <div className="sten-panel__body">
                    {this.props.chartConfig && this.props.chartData && (
                        <Chart
                            className="sten-energy-management-kpi-graph-panel__graph"
                            config={this.props.chartConfig}
                            series={this.props.chartData.series}
                            categories={this.props.chartData.categories}
                            isChart
                        />
                    )}
                </div>
            </div>
        );
    }
}

EnergyManagementKPIGraphPanel.propTypes = {
    chartConfig: PropTypes.objectOf(PropTypes.any),
    chartData: PropTypes.objectOf(PropTypes.any),
    title: PropTypes.string
};

EnergyManagementKPIGraphPanel.defaultProps = {
    chartConfig: null,
    chartData: null,
    title: ''
};

export default EnergyManagementKPIGraphPanel;
