import React from 'react';
import PropTypes from 'prop-types';
import TableCell from '../../shared/table-cell';
/* utils */
import { translate } from 'utils/i18n/i18n-model';

export default class EnergyManagementReportsPreviewMultiVoyageReportTableHead extends React.PureComponent {
    renderSingleRow = (voyageNumber, index) => {
        let className = '';
        if (
            voyageNumber === this.props.highlightedRowVoyageNumber
            || this.props.selectedRows[voyageNumber] === true
        ) {
            className = 'sten-table__row--highlighted';
        }
        return (
            <tr key={voyageNumber} className={className}>
                <TableCell
                    height={this.props.rowHeights[index + 2]}
                    id={voyageNumber}
                    onHover={this.props.onHover}
                    onClick={this.props.onClick}
                >
                    {voyageNumber}
                </TableCell>
            </tr>
        );
    };

    render() {
        const { rowHeights } = this.props;
        return (
            <thead>
                <tr>
                    <TableCell
                        height={rowHeights.length > 2 ? rowHeights[0] + rowHeights[1] : 'auto'}
                        id={null}
                        onHover={this.props.onHover}
                    >
                        {translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.MULTI_VOYAGE_REPORT.TABLE_HEADER.VOYAGE_NUMBER')}
                    </TableCell>
                </tr>
                {this.props.voyageNumbers.map(this.renderSingleRow, this)}
            </thead>
        );
    }
}

EnergyManagementReportsPreviewMultiVoyageReportTableHead.propTypes = {
    highlightedRowVoyageNumber: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onClick: PropTypes.func.isRequired,
    onHover: PropTypes.func.isRequired,
    rowHeights: PropTypes.arrayOf(PropTypes.number).isRequired,
    selectedRows: PropTypes.objectOf(PropTypes.bool).isRequired,
    voyageNumbers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired
};

EnergyManagementReportsPreviewMultiVoyageReportTableHead.defaultProps = {
    highlightedRowVoyageNumber: null
};
