import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
/* router */
import { getSearchFromQuery } from './router-helpers';
/* services */
import ConfigService from 'services/config-api/config-service';
/* helpers */
import { mapArrayByValue } from 'utils/helpers/array-helper';

const parseQueryParam = {
    number: value => {
        const parsedValue = parseInt(value, 10);
        return isNaN(parsedValue) ? null : parsedValue;
    },
    bool: value => value === 'true',
    string: value => value || null
};

function logPageView() {
    const page = window.location.pathname + window.location.search;
    ReactGA.set({ page });
    ReactGA.send({ hitType: 'pageview', page });
}

function scheduleLoggingOfPageView() {
    if (process.env.NODE_ENV === 'production' && ConfigService.tenantConfig?.googleAnalyticsMeasurementId) {
        setTimeout(logPageView, 500);
    }
}

const emptyObject = {};

function extractQueryParams(query, queryParamsMap) {
    if (query && queryParamsMap) {
        return mapArrayByValue(Object.keys({
            ...query,
            ...queryParamsMap
        }), (key) => {
            return parseQueryParam[queryParamsMap[key]]
                ? parseQueryParam[queryParamsMap[key]](query[key])
                : query[key];
        });
    }
    return emptyObject;
}

let currentPathName = null;

export function withRouter(Component, queryProps) {
    function ComponentWithRouterProp(props) {
        const location = useLocation();
        const navigate = useNavigate();
        const [searchParams] = useSearchParams();
        const params = useParams();
        const [query, setQuery] = useState(Object.fromEntries([...searchParams]));
        const queryParamsMap = queryProps || props.queryParamsMap;
        const [queryParams, setQueryParams] = useState(extractQueryParams(query, queryParamsMap));

        useLayoutEffect(() => {
            if (currentPathName !== location.pathname) {
                currentPathName = location.pathname;
                scheduleLoggingOfPageView();
            }
        }, [location]);

        const clearQueryParams = useCallback(() => {
            navigate({
                pathname: location.pathname,
                search: ''
            });
        }, [navigate, location]);

        function navigateQuery(route, options) {
            const to = typeof route === 'string'
                ? route
                : {
                    pathname: route.pathname || location.pathname,
                    search: getSearchFromQuery(route.query) || route.search || ''
                };
            if (to.pathname !== location.pathname || to.search !== location.search) {
                navigate(to, options);
            }
        }

        const getLinkTo = ({ remove = null, add = null, pathname = null, query: q = null }) => {
            let newQuery = { ...q || query };
            if (remove && Array.isArray(remove)) {
                remove.forEach((param) => {
                    if (newQuery[param]) {
                        delete newQuery[param];
                    }
                });
            }
            if (add) {
                newQuery = { ...newQuery, ...add };
            }
            return {
                pathname: pathname || location.pathname,
                search: getSearchFromQuery(newQuery)
            };
        };

        const updateQueryParams = (props) => {
            navigateQuery(getLinkTo(props));
        };

        useEffect(() => {
            const newQuery = Object.fromEntries([...searchParams]);
            setQuery(newQuery);
            if (queryParamsMap) {
                const newQueryParams = extractQueryParams(newQuery, queryParamsMap);
                setQueryParams(newQueryParams);
            }
        }, [searchParams]);
        return (
            <Component
                router={{
                    location,
                    navigate: navigateQuery,
                    query,
                    params,
                    clearQueryParams,
                    updateQueryParams,
                    getLinkTo
                }}
                queryParams={queryParams}
                {...props}
            />
        );
    }
    ComponentWithRouterProp.propTypes = {
        queryParamsMap: PropTypes.objectOf(PropTypes.any)
    };
    ComponentWithRouterProp.defaultProps = {
        queryParamsMap: null
    };
    return ComponentWithRouterProp;
}

export const TRouter = PropTypes.shape({
    location: PropTypes.shape({
        pathname: PropTypes.string,
        search: PropTypes.string,
        hash: PropTypes.string,
        state: PropTypes.objectOf(PropTypes.any),
        key: PropTypes.string
    }).isRequired,
    navigate: PropTypes.func.isRequired,
    query: PropTypes.objectOf(PropTypes.any),
    clearQueryParams: PropTypes.func,
    updateQueryParams: PropTypes.func,
    getSearchFromQuery: PropTypes.func,
    getLinkTo: PropTypes.func
});
