import React from 'react';
import PropTypes from 'prop-types';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { getObjectProp, getClassName } from 'utils/helpers/info-helper';
import { mapArrayByValue } from 'utils/helpers/array-helper';
import { translate } from 'utils/i18n/i18n-model';
/* components */
import TableHeadCell from 'components/table-head-cell/table-head-cell';
import TextHighlight from 'components/text-highlight/text-highlight';
// import Avatar from 'components/avatar/avatar';
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
import EmptyContent from 'components/empty-content/empty-content';
import Menu, { MenuItem } from 'components/menu/menu';
/* styles */
import './port-management-table.scss';
/* constants */
import { fixedColumn } from '../port-management-constants';

const t = (key) => translate(`PORT_MANAGEMENT.TABLE.${key}`);

const pinnedTypeMap = {
    port: 'PortManagementPinnedPortIds',
    terminal: 'PortManagementPinnedTerminalIds',
    berth: 'PortManagementPinnedBerthIds'
};

const initialExpandedAccordions = {
    PinnedItemsSection: true,
    PinnedItems: {},
    SearchResultsSection: true,
    SearchResults: {}
};

class PortManagementTable extends React.PureComponent {
    state = {
        expandedAccordions: initialExpandedAccordions,
        userSettingUpdated: null,
        portParams: null,
        pinnedItems: null,
        pinnedPorts: null
    };

    static getDerivedStateFromProps(props, state) {
        let newState = null;
        if (state.portParams !== props.portParams) {
            newState = {
                expandedAccordions: { ...state.expandedAccordions, SearchResultsSection: true },
                portParams: props.portParams
            };
            if (props.portParams.Offset === 0) {
                newState.expandedAccordions.SearchResults = {};
            }
            if (props.portParams.TerminalName || props.portParams.BerthName) {
                let port;
                for (let i = props.portParams.Offset; i < props.ports.PageItems.length; i++) {
                    port = props.ports.PageItems[i];
                    newState.expandedAccordions.SearchResults[`Port_${port.Id}`] = true;
                    if (props.portParams.BerthName) {
                        port.Terminals.forEach(terminal => {
                            newState.expandedAccordions.SearchResults[`Terminal_${terminal.Id}`] = true;
                        });
                    }
                }
            }
        }
        if (!state.pinnedItems
            || (props.userSettingUpdated
                && props.userSettingUpdated.name === 'PortManagementPinnedItems'
                && props.userSettingUpdated !== state.userSettingUpdated)) {
            newState = {
                ...newState,
                userSettingUpdated: props.userSettingUpdated,
                pinnedItems: {
                    port: mapArrayByValue(props.userSettings[pinnedTypeMap.port]),
                    terminal: mapArrayByValue(props.userSettings[pinnedTypeMap.terminal]),
                    berth: mapArrayByValue(props.userSettings[pinnedTypeMap.berth])
                }
            };
        }
        if (state.pinnedPorts !== props.pinnedPorts) {
            newState = {
                ...newState,
                pinnedPorts: props.pinnedPorts
            };
            if (newState.expandedAccordions) {
                newState.expandedAccordions.PinnedItems = {};
            } else {
                newState.expandedAccordions = { ...state.expandedAccordions, PinnedItems: {} };
            }
            props.pinnedPorts.forEach(port => {
                port.Terminals.forEach(terminal => {
                    if (state.pinnedItems.terminal[terminal.Id]) {
                        newState.expandedAccordions.PinnedItems[`Port_${port.Id}`] = true;
                    }
                    terminal.Berths.forEach(berth => {
                        if (state.pinnedItems.berth[berth.Id]) {
                            newState.expandedAccordions.PinnedItems[`Terminal_${terminal.Id}`] = true;
                            newState.expandedAccordions.PinnedItems[`Port_${port.Id}`] = true;
                        }
                    });
                });
            });
        }
        return newState;
    }

    handleSortChange = (SortBy) => {
        const params = this.props.portParams;
        const newParams = { Offset: 0, SortBy, SortOrder: 'ASC' };
        if (params.SortBy === SortBy) {
            newParams.SortOrder = params.SortOrder === 'ASC' ? 'DESC' : 'ASC';
        }
        this.props.onParamsChange(newParams);
        if (this.tableRef) {
            this.tableRef.scrollArea.scrollTop();
        }
    };

    handlePinClick = (type, id) => (e) => {
        e.stopPropagation();
        this.props.toggleUserSetting(pinnedTypeMap[type], id, 'PortManagementPinnedItems');
    };

    areAllPortsFetched = (ports) => ports.TotalPagesCount === 0
        || ports.Offset === (ports.TotalPagesCount - 1) * ports.Limit;

    handleScroll = (value) => {
        const ports = this.props.ports;
        const arePortsShown = this.state.expandedAccordions.SearchResultsSection;
        if (ports && arePortsShown && value.realHeight - value.topPosition < value.containerHeight + 100) {
            if (!this.areAllPortsFetched(ports)) {
                this.props.onParamsChange({
                    Offset: ports.Offset + ports.Limit
                });
            }
        }
    };

    toggleAccordion = (key) => () => {
        const props = key.split('.');
        const expandedAccordions = { ...this.state.expandedAccordions };
        if (props.length === 2) {
            expandedAccordions[props[0]][props[1]] = !expandedAccordions[props[0]][props[1]];
        } else {
            expandedAccordions[key] = !expandedAccordions[key];
        }
        this.setState({ expandedAccordions });
    };

    getAccordionClass = (isCollapsed, isDisabled) => getClassName('sten-table__accordion', {
        'sten-table__accordion--collapsed': isCollapsed,
        'sten-table__accordion--disabled': isDisabled
    });

    isAccordionCollapsed = (key) => !getObjectProp(this.state.expandedAccordions, key);

    getTypeInfo = {
        port: (item, tabId) => (e) => {
            e.stopPropagation();
            const query = tabId ? { tabId } : null;
            this.props.router.navigate({ pathname: `${appRoutes.PortManagement}/${item.Id}`, query });
        },
        terminal: (item, tabId) => (e) => {
            e.stopPropagation();
            const query = { terminal: item.Id };
            if (tabId) {
                query.tabId = tabId;
            }
            this.props.router.navigate({ pathname: appRoutes.PortManagement, query });
        },
        berth: (item, tabId) => (e) => {
            e.stopPropagation();
            const query = { berth: item.Id };
            if (tabId) {
                query.tabId = tabId;
            }
            this.props.router.navigate({ pathname: appRoutes.PortManagement, query });
        }
    };

    getTypeEdit = {
        port: (item) => () => this.props.router.navigate(`${appRoutes.PortManagement}/edit/${item.Id}`),
        terminal: (item) => () => this.props.router.navigate({
            pathname: appRoutes.PortManagement,
            query: { terminalUpdate: item.Id }
        }),
        berth: (item) => () => this.props.router.navigate({
            pathname: appRoutes.PortManagement, query: { berthUpdate: item.Id }
        })
    };

    getTerminalAdd = (item) => () => {
        this.props.router.navigate({ pathname: appRoutes.PortManagement, query: { terminalAddPort: item.Id } });
    }

    getBerthAdd = (item) => () => {
        this.props.router.navigate({ pathname: appRoutes.PortManagement, query: { berthAddTerminal: item.Id } });
    };

    renderMenu = (type, item) => {
        const permissions = this.props.permissions;
        switch (type) {
        case 'berth':
            return (
                <Menu className="sten-port-management-table__menu" stopPropagation>
                    <MenuItem onClick={this.getTypeInfo[type](item)} icon="icon-overview">
                        {t('BERTH_INFORMATION')}
                    </MenuItem>
                    {permissions.UpdateBerth && (
                        <MenuItem onClick={this.getTypeEdit[type](item)} icon="icon-edit">{t('EDIT_BERTH')}</MenuItem>
                    )}
                </Menu>
            );
        case 'terminal':
            return (
                <Menu className="sten-port-management-table__menu" stopPropagation>
                    <MenuItem onClick={this.getTypeInfo[type](item)} icon="icon-terminal">
                        {t('TERMINAL_INFORMATION')}
                    </MenuItem>
                    {permissions.AddUpdateTerminal && (
                        <MenuItem onClick={this.getTypeEdit[type](item)} icon="icon-edit">
                            {t('EDIT_TERMINAL')}
                        </MenuItem>
                    )}
                    {permissions.UpdateBerth && (
                        <MenuItem onClick={this.getBerthAdd(item)} icon="icon-plus">{t('ADD_BERTH')}</MenuItem>
                    )}
                </Menu>
            );
        default:
            return (
                <Menu className="sten-port-management-table__menu" stopPropagation>
                    <MenuItem onClick={this.getTypeInfo[type](item)} icon="icon-overview">
                        {t('PORT_INFORMATION')}
                    </MenuItem>
                    {permissions.PortManagementEditPort && (
                        <MenuItem onClick={this.getTypeEdit[type](item)} icon="icon-edit">{t('EDIT_PORT')}</MenuItem>
                    )}
                    {permissions.AddUpdateTerminal && (
                        <MenuItem onClick={this.getTerminalAdd(item)} icon="icon-plus">{t('ADD_TERMINAL')}</MenuItem>
                    )}
                </Menu>
            );
        }
    }

    renderCellValue = (type, item, col) => {
        if (col.render) {
            return col.render(item, type, this);
        }
        const value = getObjectProp(item, col.prop);
        if (col.formatValue) {
            return col.formatValue(value);
        }
        return value;
    };

    renderNameCell = (type, item, searchCriteria, highlightSearch) => {
        const isPinned = this.state.pinnedItems
            && this.state.pinnedItems[type]
            && !!this.state.pinnedItems[type][item.Id];
        const pinClass = getClassName('btn-link icon icon--md', {
            'icon-pin-off': !isPinned,
            'icon-pin-on text-success': isPinned
        });
        const countryName = this.props.portParams.CountryName;
        const permissions = this.props.permissions;
        const showMenu = permissions.UpdateBerth || permissions.AddUpdateTerminal || permissions.PortManagementEditPort;
        return (
            <div className="flex-row flex-center col-24">
                <div className="flex-grow">
                    <h4
                        className="sten-port-management-table__title sten-title-link"
                        onClick={this.getTypeInfo[type](item)}
                    >
                        {!item.Name && <span className="text-danger">NO NAME</span>}
                        {item.Name && highlightSearch && searchCriteria
                            ? <TextHighlight input={item.Name} highlight={searchCriteria} />
                            : item.Name
                        }
                        {item.Code && <span className="text-secondary"> ({item.Code})</span>}
                    </h4>
                    {item.Aliases && item.Aliases.length > 0 && (
                        <p className="sten-port-management-table__subtitle">
                            {highlightSearch && searchCriteria
                                ? <TextHighlight input={item.Aliases.join(', ')} highlight={searchCriteria} />
                                : item.Aliases.join(', ')
                            }
                        </p>
                    )}
                    {item.Country && (
                        <p className="sten-port-management-table__subtitle">
                            {item.Country.Code && (
                                <span className={`fp ${item.Country.Code.toLowerCase()}`} />
                            )}
                            {highlightSearch && countryName
                                ? <TextHighlight input={item.Country.Name} highlight={countryName} />
                                : item.Country.Name
                            }
                        </p>
                    )}
                </div>
                <div className="flex-shrink">
                    <button className={pinClass} onClick={this.handlePinClick(type, item.Id)} />
                </div>
                {showMenu && <div className="flex-shrink">{this.renderMenu(type, item)}</div>}
            </div>
        );
    }

    renderBerth = (sectionKey, colSpan, highlightSearch, berth) => {
        const key = `${sectionKey}.Berth_${berth.Id}`;

        return (
            <tr key={key} className="sten-port-management-table__berth">
                <td>{this.renderNameCell('berth', berth, this.props.portParams.BerthName, highlightSearch)}</td>
                {this.props.columns.map(col => (
                    <td key={col.id}>{this.renderCellValue('berth', berth, col)}</td>
                ))}
            </tr>
        );
    };

    renderTerminal = (sectionKey, colSpan, highlightSearch, terminal) => {
        const key = `${sectionKey}.Terminal_${terminal.Id}`;
        const isDisabled = terminal.Berths.length === 0;
        const isCollapsed = this.isAccordionCollapsed(key);
        const className = `sten-port-management-table__terminal ${
            this.getAccordionClass(isCollapsed, isDisabled)}`;

        return (
            <React.Fragment key={key}>
                <tr onClick={!isDisabled ? this.toggleAccordion(key) : undefined} className={className}>
                    <td>
                        {this.renderNameCell('terminal', terminal, this.props.portParams.TerminalName, highlightSearch)}
                    </td>
                    {this.props.columns.map(col => (
                        <td key={col.id}>{this.renderCellValue('terminal', terminal, col)}</td>
                    ))}
                </tr>
                {!isDisabled && !isCollapsed && (
                    <tr className="sten-port-management-table__berth-header">
                        <td><h4 className="text-secondary">{t('BERTHS')}</h4></td>
                        <td colSpan={colSpan} />
                    </tr>
                )}
                {!isDisabled && !isCollapsed
                    && terminal.Berths.map(this.renderBerth.bind(this, sectionKey, colSpan, highlightSearch))}
            </React.Fragment>
        );
    };

    renderPort = (sectionKey, colSpan, highlightSearch, port) => {
        const key = `${sectionKey}.Port_${port.Id}`;
        const isDisabled = port.Terminals.length === 0;
        const isCollapsed = this.isAccordionCollapsed(key);
        const className = `sten-port-management-table__port ${this.getAccordionClass(isCollapsed, isDisabled)}`;

        return (
            <React.Fragment key={key}>
                <tr onClick={!isDisabled ? this.toggleAccordion(key) : undefined} className={className}>
                    <td>{this.renderNameCell('port', port, this.props.portParams.PortName, highlightSearch)}</td>
                    {this.props.columns.map(col => (
                        <td key={col.id}>{this.renderCellValue('port', port, col)}</td>
                    ))}
                </tr>
                {!isDisabled && !isCollapsed && (
                    <tr className="sten-port-management-table__terminal-header">
                        <td><h4 className="text-secondary">{t('TERMINALS')}</h4></td>
                        <td colSpan={colSpan} />
                    </tr>
                )}
                {!isDisabled && !isCollapsed
                    && port.Terminals.map(this.renderTerminal.bind(this, sectionKey, colSpan, highlightSearch))}
            </React.Fragment>
        );
    };

    renderSection = (label, key, colSpan, ports, highlightSearch = true, count) => {
        const sectionKey = `${key}Section`;
        const isDisabled = ports.length === 0;
        const isCollapsed = this.isAccordionCollapsed(sectionKey);
        const className = `sten-port-management-table__section ${
            this.getAccordionClass(isCollapsed, isDisabled)}`;

        return (
            <tbody>
                <tr
                    key={sectionKey}
                    onClick={!isDisabled ? this.toggleAccordion(sectionKey) : undefined}
                    className={className}
                >
                    <td className="text-uppercase">
                        <div className="flex-row flex-center">
                            <div className="flex-shrink">{label}</div>
                            {typeof count === 'number' && (
                                <div className="flex-grow">
                                    <div className="sten-badge sten-badge--success">{count}</div>
                                </div>
                            )}
                        </div>
                    </td>
                    <td colSpan={colSpan} />
                </tr>
                {isDisabled && (
                    <tr className="sten-port-management-table__no-results">
                        <td>{t('NO_SEARCH_RESULTS')}</td>
                        <td colSpan={colSpan} />
                    </tr>
                )}
                {!isDisabled && !isCollapsed && ports.map(this.renderPort.bind(this, key, colSpan, highlightSearch))}
            </tbody>
        );
    };

    getPinnedCount(settings) {
        return settings[pinnedTypeMap.port].length
            + settings[pinnedTypeMap.terminal].length
            + settings[pinnedTypeMap.berth].length;
    }

    saveTableRef = c => {
        this.tableRef = c;
        this.props.setInnerRef(this.tableRef);
    };

    render() {
        const { columns, contentStyle, portParams, ports, pinnedPorts, userSettings } = this.props;
        const colSpan = this.props.columns.length;

        if ((!ports || !ports.PageItems.length) && !pinnedPorts.length) {
            return <EmptyContent />;
        }

        return (
            <FixedHeaderTable
                className="sten-port-management-table"
                withHeaderColumn
                customFirstChild={TableHeadCell}
                ref={this.saveTableRef}
                contentStyle={contentStyle}
                contentClassName="sten-port-management-table__scroll-content"
                onScroll={this.handleScroll}
            >
                <table className="sten-table sten-table--sm">
                    <thead>
                        <tr>
                            <TableHeadCell
                                style={fixedColumn.style}
                                sortOrder={portParams.SortBy === fixedColumn.prop ? portParams.SortOrder : ''}
                                sortable={fixedColumn.sortable}
                                sortOrderInvert={fixedColumn.sortOrderInvert}
                                onClick={this.handleSortChange.bind(this, fixedColumn.prop)}
                            >
                                {fixedColumn.title}
                            </TableHeadCell>
                            {columns.map((col) => (
                                <TableHeadCell
                                    style={col.style}
                                    sortOrder={portParams.SortBy === col.prop ? portParams.SortOrder : ''}
                                    sortable={col.sortable}
                                    sortOrderInvert={col.sortOrderInvert}
                                    key={col.id} // eslint-disable-line react/no-array-index-key
                                    onClick={this.handleSortChange.bind(this, col.prop)}
                                >
                                    {col.title}
                                </TableHeadCell>
                            ))}
                        </tr>
                    </thead>
                    {pinnedPorts.length > 0 && this.renderSection(
                        t('PINNED_ITEMS'),
                        'PinnedItems',
                        colSpan,
                        pinnedPorts,
                        false,
                        this.getPinnedCount(userSettings)
                    )}
                    {ports && (
                        <tbody className="sten-port-management-table__divider">
                            <tr><td /><td colSpan={colSpan} /></tr>
                        </tbody>
                    )}
                    {ports && this.renderSection(t('SEARCH_RESULTS'), 'SearchResults', colSpan, ports.PageItems)}
                </table>
            </FixedHeaderTable>
        );
    }
}

PortManagementTable.propTypes = {
    className: PropTypes.string,
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    contentStyle: PropTypes.objectOf(PropTypes.any),
    onParamsChange: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    pinnedPorts: PropTypes.arrayOf(PropTypes.object).isRequired,
    portParams: PropTypes.objectOf(PropTypes.any).isRequired,
    ports: PropTypes.objectOf(PropTypes.any),
    router: TRouter.isRequired,
    setInnerRef: PropTypes.func.isRequired,
    toggleUserSetting: PropTypes.func.isRequired,
    userSettings: PropTypes.objectOf(PropTypes.any).isRequired,
    userSettingUpdated: PropTypes.objectOf(PropTypes.any).isRequired
};

PortManagementTable.defaultProps = {
    className: '',
    contentStyle: null,
    ports: null
};

export default withRouter(PortManagementTable);
