import { ActionTypes } from './vessel-distribution-areas-actions';

const initialState = {
    positions: []
};

export default function vesselDistributionAreasReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.VESSEL_DISTRIBUTION_AREAS_SET_POSITIONS: {
        return {
            ...state,
            positions: action.positions
        };
    }
    default:
        return state;
    }
}
