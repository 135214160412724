import PropTypes from 'prop-types';

export const THomeQueryParams = PropTypes.shape({
    berth: PropTypes.number,
    berthAddTerminal: PropTypes.number,
    berthUpdate: PropTypes.number,
    cpVoyage: PropTypes.number,
    cpEdit: PropTypes.bool,
    notificationSettings: PropTypes.bool,
    port: PropTypes.number,
    questionnaireEdit: PropTypes.bool,
    questionnaireId: PropTypes.number,
    reportEdit: PropTypes.bool,
    reportId: PropTypes.number,
    reportType: PropTypes.number,
    terminal: PropTypes.number,
    terminalAddPort: PropTypes.number,
    terminalsShown: PropTypes.bool,
    terminalUpdate: PropTypes.number,
    vessel: PropTypes.number,
    voyage: PropTypes.number
});
