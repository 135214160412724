import React from 'react';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { commonFields, renderLabel } from 'components/form-fields/form-fields-helper';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import Checkbox from 'components/checkbox/checkbox';
import CompanySelect from 'components/company-select/company-select';
import BoolSelect from 'components/bool-select/bool-select';
/* constants */
import { validations } from './admin-vessel-management-form-validations';
import BudgetConsumptionTable from './budget-consumption-table/budget-consumption-table';

const userDataSourceId = 2;

const isDisabled = (params, prop) => {
    return params.disabledFields && params.disabledFields[prop];
};

const isScrubberInstallationDateDisabled = (data) => {
    return !data.HasScrubber;
};

function renderLabelInfo(label, unit, info) {
    return (
        <label className="label">{label} (<i>{unit}</i>)
            <span
                className="icon sten-icon-info-tooltip"
                title={info}
            />
        </label>
    );
}

export const fieldMap = {
    Title: commonFields.String(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.VESSEL_NAME'),
        'Title',
        '',
        validations.Title,
        isDisabled
    ),
    Imo: commonFields.Number(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.IMO'),
        'Imo',
        0,
        validations.Imo,
        null,
        null,
        isDisabled
    ),
    IMOClass: commonFields.String(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.IMO_CLASS'),
        'IMOClass',
        '',
        null,
        isDisabled
    ),
    ShouldSyncReportsToVeracity: {
        condition: () => ConfigService.featureToggles.showSyncVesselWithVeracityToggle,
        ...commonFields.FlipSwitch(
            t('ADMIN.VESSEL_MANAGEMENT.FORM.SEND_TO_VERACITY'),
            'ShouldSyncReportsToVeracity'
        )
    },
    VesselType: commonFields.Select(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.VESSEL_TYPE'),
        'VesselType',
        'editingOptions.vesselTypes',
        validations.VesselType,
        {
            placeholder: t('ADMIN.VESSEL_MANAGEMENT.FORM.SELECT_VESSEL_TYPE'),
            valueKey: 'Id',
            labelKey: 'DisplayName'
        },
        isDisabled
    ),
    VesselSegment: commonFields.Select(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.SEGMENT'),
        'VesselSegment',
        'editingOptions.vesselSegments',
        validations.VesselSegment,
        {
            placeholder: t('ADMIN.VESSEL_MANAGEMENT.FORM.SELECT_SEGMENT'),
            valueKey: 'VesselTypeId',
            labelKey: 'VesselTypeName'
        },
        isDisabled
    ),
    DWT: commonFields.Number(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.DWT'),
        'DWT',
        0,
        validations.DWT,
        null,
        null,
        isDisabled
    ),
    TotalCubicCapacity: {
        condition: (data) => data.VesselSegment && data.VesselSegment.VesselTypeName === 'LNG',
        ...commonFields.Number(
            t('ADMIN.VESSEL_MANAGEMENT.FORM.CUBIC_CAPACITY'),
            'TotalCubicCapacity',
            0,
            validations.TotalCubicCapacity
        ) },
    Class: commonFields.String(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.CLASS'),
        'Class',
        '',
        null,
        isDisabled
    ),
    GT: commonFields.Number(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.GT'),
        'GT',
        0,
        validations.GT,
        null,
        null,
        isDisabled
    ),
    BlockCoefficientCb: commonFields.Number(
        renderLabelInfo.bind(
            this,
            t('ADMIN.VESSEL_MANAGEMENT.FORM.BLOCK_COEFFICIENT_CB'),
            'Cb',
            t('ADMIN.VESSEL_MANAGEMENT.FORM.BLOCK_COEFFICIENT_CB_INFO')
        ),
        'BlockCoefficientCb',
        0,
        validations.BlockCoefficientCb,
        null,
        null,
        isDisabled
    ),
    CubicCapacityCorrectionFactorFc: commonFields.Number(
        renderLabelInfo.bind(
            this,
            t('ADMIN.VESSEL_MANAGEMENT.FORM.CUBIC_CAPACITY_CORRECTION_FACTOR_FC'),
            'fc',
            t('ADMIN.VESSEL_MANAGEMENT.FORM.CUBIC_CAPACITY_CORRECTION_FACTOR_FC_INFO')
        ),
        'CubicCapacityCorrectionFactorFc',
        0,
        validations.CubicCapacityCorrectionFactorFc,
        null,
        null,
        isDisabled
    ),
    VoluntaryStructuralEnhancement: commonFields.Number(
        renderLabelInfo.bind(
            this,
            t('ADMIN.VESSEL_MANAGEMENT.FORM.SHIP_SPECIFIC_VOLUNTARY_STRUCTURAL_ENHACEMENT'),
            'fi, VSE',
            t('ADMIN.VESSEL_MANAGEMENT.FORM.SHIP_SPECIFIC_VOLUNTARY_STRUCTURAL_ENHACEMENT_INFO')
        ),
        'VoluntaryStructuralEnhancement',
        0,
        validations.VoluntaryStructuralEnhancement,
        null,
        null,
        isDisabled
    ),
    IceClassLevelBureauVeritas: {
        label: renderLabel(t('ADMIN.VESSEL_MANAGEMENT.FORM.ICE_CLASS_LEVELS')),
        edit: (data, handleChange, params) => (
            <CompanySelect
                fixedListWidth
                clearable
                name="IceClassLevelBureauVeritas"
                options={params.editingOptions.iceClassLevels}
                placeholder={t('ADMIN.VESSEL_MANAGEMENT.FORM.SELECT_ICE_CLASS_LEVEL')}
                value={data.IceClassBureauVeritasLevel}
                onChange={handleChange('IceClassBureauVeritasLevel')}
            />
        )
    },
    Draft: commonFields.Number(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.BALLAST_DRAFT'),
        'Draft',
        2,
        validations.Draft,
        null,
        null,
        isDisabled
    ),
    YearBuilt: commonFields.String(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.YEAR_BUILT'),
        'YearBuilt',
        '',
        null,
        isDisabled
    ),
    HasScrubber: {
        label: renderLabel(t('ADMIN.VESSEL_MANAGEMENT.FORM.SCRUBBER')),
        edit: (data, handleChange) => (
            <BoolSelect
                clearable
                disabled={data.ScrubberDataSourceId !== userDataSourceId}
                name="HasScrubber"
                onChange={handleChange('HasScrubber')}
                value={data.HasScrubber}
            />
        )
    },
    ScrubberInstallationDate: commonFields.Date(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.SCRUBBER_ACTIVE_FROM'),
        'ScrubberInstallationDate',
        null,
        isScrubberInstallationDateDisabled
    ),
    NoxMainEngine: commonFields.Number(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.NOX_MAIN_ENGINE'),
        'NoxMainEngine',
        2,
        validations.NoxMainEngine,
        null,
        null,
        isDisabled
    ),
    NoxAuxEngine: commonFields.Number(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.NOX_AUX_ENGINE'),
        'NoxAuxEngine',
        2,
        validations.NoxAuxEngine,
        null,
        null,
        isDisabled
    ),
    SfcMainEngine: commonFields.Number(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.SFC_MAIN_ENGINE'),
        'SfcMainEngine',
        2,
        validations.SfcMainEngine,
        null,
        null,
        isDisabled
    ),
    SfcAuxEngine: commonFields.Number(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.SFC_AUX_ENGINE'),
        'SfcAuxEngine',
        2,
        validations.SfcAuxEngine,
        null,
        null,
        isDisabled
    ),
    Loa: commonFields.Number(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.LOA'),
        'Loa',
        0,
        validations.Loa,
        null,
        null,
        isDisabled
    ),
    MMSI: commonFields.Number(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.MMSI'),
        'Mmsi',
        0,
        validations.MMSI,
        null,
        null,
        isDisabled
    ),
    Beam: commonFields.Number(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.BEAM'),
        'Beam',
        2,
        validations.Beam,
        null,
        null,
        isDisabled
    ),
    BallastOptimumTrim: commonFields.Number(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.BALLAST_OPTIMUM_TRIM'),
        'BallastOptimumTrim',
        1,
        validations.BallastOptimumTrim,
        t('UNITS.TRIM'),
        null,
        isDisabled
    ),
    LadenOptimumTrim: commonFields.Number(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.LADEN_OPTIMUM_TRIM'),
        'LadenOptimumTrim',
        1,
        validations.LadenOptimumTrim,
        t('UNITS.TRIM'),
        null,
        isDisabled
    ),
    ShowOnStenaBulk: {
        condition: () => ConfigService.featureToggles.showVesselManagementSortOrder,
        edit: (data, handleChange, params) => {
            return (
                <Checkbox
                    isChecked={data.ShowOnStenaBulk}
                    onChange={params.toggleValue('ShowOnStenaBulk')}
                >
                    {t('ADMIN.VESSEL_MANAGEMENT.FORM.SHOW_ON_STENA_BULK')}
                </Checkbox>
            );
        }
    },
    SortOrder: {
        condition: (data) => ConfigService.featureToggles.showVesselManagementSortOrder && data.ShowOnStenaBulk,
        ...commonFields.Number(
            t('ADMIN.VESSEL_MANAGEMENT.FORM.SORT_ORDER'),
            'SortOrder',
            0,
            validations.SortOrder
        ) },
    CanalsAllowed: {
        label: renderLabel(t('ADMIN.VESSEL_MANAGEMENT.FORM.CANALS_ALLOWED')),
        edit: (data, handleChange, params) => (
            <div className="flex-row">
                <div className="flex-shrink">
                    <Checkbox
                        isChecked={data.PanamaCanal}
                        onChange={params.toggleValue('PanamaCanal')}
                    >
                        {t('ADMIN.VESSEL_MANAGEMENT.FORM.PANAMA')}
                    </Checkbox>
                </div>
                <div className="flex-shrink">
                    <Checkbox
                        isChecked={data.SuezCanal}
                        onChange={params.toggleValue('SuezCanal')}
                    >
                        {t('ADMIN.VESSEL_MANAGEMENT.FORM.SUEZ')}
                    </Checkbox>
                </div>
                <div className="flex-shrink">
                    <Checkbox
                        isChecked={data.KielCanal}
                        onChange={params.toggleValue('KielCanal')}
                    >
                        {t('ADMIN.VESSEL_MANAGEMENT.FORM.KIEL')}
                    </Checkbox>
                </div>
            </div>
        )
    },
    CommercialOperator: {
        label: renderLabel(t('ADMIN.VESSEL_MANAGEMENT.FORM.COMMERCIAL_OPERATOR')),
        edit: (data, handleChange, params) => {
            return (
                <CompanySelect
                    fixedListWidth
                    clearable
                    name="CommercialOperator"
                    options={params.editingOptions.commercialOperators}
                    placeholder={
                        t('ADMIN.VESSEL_MANAGEMENT.FORM.SELECT_COMMERCIAL_OPERATOR')
                    }
                    value={data.CommercialOperator}
                    onChange={handleChange('CommercialOperator')}
                />
            );
        }
    },
    VesselOwner: {
        label: renderLabel(t('ADMIN.VESSEL_MANAGEMENT.FORM.HEAD_OWNER')),
        edit: (data, handleChange, params) => {
            return (
                <CompanySelect
                    fixedListWidth
                    searchable
                    clearable
                    includeSearchInResults
                    name="VesselOwner"
                    options={params.editingOptions.headOwners}
                    placeholder={t('ADMIN.VESSEL_MANAGEMENT.FORM.SELECT_HEAD_OWNER')}
                    onChange={handleChange('VesselOwner')}
                    value={data.VesselOwner}
                    onCompanyAdd={params.handleCompanyAdd('OwnerId')}
                />
            );
        }
    },
    TechnicalManager: {
        label: renderLabel(t('ADMIN.VESSEL_MANAGEMENT.FORM.TECHNICAL_MANAGER')),
        edit: (data, handleChange, params) => {
            return (
                <CompanySelect
                    fixedListWidth
                    searchable
                    clearable
                    includeSearchInResults
                    name="TechnicalManager"
                    options={params.editingOptions.technicalManagers}
                    placeholder={t('ADMIN.VESSEL_MANAGEMENT.FORM.SELECT_TECHNICAL_MANAGER')}
                    onChange={handleChange('TechnicalManager')}
                    value={data.TechnicalManager}
                    onCompanyAdd={params.handleCompanyAdd('TechnicalManagerId')}
                />
            );
        }
    },
    TechnicalManagementEmail: commonFields.MultiValueSelect(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.TECHNICAL_MANAGEMENT_EMAIL'),
        'TechnicalManagementEmail',
        validations.TechnicalManagementEmail
    ),
    VesselEmail: commonFields.MultiValueSelect(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.VESSEL_EMAIL'),
        'VesselEmail',
        validations.VesselEmail
    ),
    OperationsEmail: commonFields.MultiValueSelect(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.OPERATIONS_EMAIL'),
        'OperationsEmail',
        validations.OperationsEmail
    ),
    OwnerEmail: commonFields.MultiValueSelect(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.OWNER_EMAIL'),
        'OwnerEmail',
        validations.OwnerEmail
    ),
    TradingArea: commonFields.Select(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.TRADING_AREA'),
        'TradingArea',
        'editingOptions.tradingAreas',
        null,
        {
            placeholder: t('ADMIN.VESSEL_MANAGEMENT.FORM.SELECT_TRADING_AREA'),
            valueKey: 'Id',
            labelKey: 'Name'
        }
    ),
    Comment: commonFields.Textarea(
        t('ADMIN.VESSEL_MANAGEMENT.FORM.COMMENT'),
        'Comment',
        validations.Comment
    )
};

export const dataMap = {
    BudgetConsumption: (params, index) => {
        return (
            <BudgetConsumptionTable
                key={index}
                editable={params.data.BudgetConsumptionDataSourceId === userDataSourceId}
                onChange={params.onChange('BudgetConsumption')}
                data={params.data.BudgetConsumption}
            />
        );
    }
};
