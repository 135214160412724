/* utils */
import stenTableStyles, { border } from 'utils/helpers/pdf-styles/sten-table';
import headings from 'utils/helpers/pdf-styles/headings';
import layout from 'utils/helpers/pdf-styles/layout';
import { headerCellBg, tableHighlightRowBg } from 'utils/helpers/pdf-styles/colors';
import { convertJSObjectToCssString } from 'utils/helpers/pdf-styles/style-helpers';
/* components */
import tankLayoutVesselPdfStyle from 'components/tank-layout/vessel/tank-layout-vessel-pdf-style';
import trendIconPdfStyle from 'components/trend-icon/trend-icon-pdf-style';

const logoStyle = {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPositionY: 'center',
    width: '16rem'
};

const css = {
    html: {
        fontSize: '22px',
        fontFamily: 'Arial, Helvetica, sans-serif'
    },
    button: {
        display: 'none'
    },
    'html, body': {
        margin: 0,
        padding: 0
    },
    ...layout,
    table: {
        borderRight: border,
        borderLeft: border
    },
    '.inline + .inline': {
        marginLeft: '0.5rem'
    },
    '.footprint-image': {
        width: '100%'
    },
    '.sten-cii-rating': {
        borderRadius: '0.2rem',
        backgroundColor: tableHighlightRowBg,
        padding: '0 0.25rem'
    },
    '.sten-footprint-table .sten-badge': {
        marginLeft: '0.25rem'
    },
    '.summary-vessel-name-text': headings.h2,
    '.summary-voyage-number-text': {
        ...headings.h3,
        margin: '0.5rem'
    },
    '.summary-table-and-title-container': {
        flexDirection: 'column',
        alignItems: 'center',
        flex: 1
    },
    '.summary-container': {
        display: 'flex',
        flex: 1,
        flexDirection: 'row'
    },
    '.summary-container + .summary-container': {
        paddingTop: '5rem'
    },
    '.summary-header': {
        display: 'flex',
        justifyContent: 'space-between',
        height: '7rem',
        alignItems: 'center',
        backgroundColor: '#eee',
        borderBottom: '2px solid #dadada',
        textAlign: 'center',
        marginBottom: '1rem',
        padding: '1rem 2rem'
    },
    '.summary-header-company-logo': {
        ...logoStyle,
        backgroundPositionX: 'left',
        height: '5rem'
    },
    '.summary-header-orbit-logo': {
        ...logoStyle,
        backgroundPositionX: 'right',
        height: '3rem'
    },
    '.summary-disclaimer': {
        position: 'absolute',
        bottom: 0,
        left: 0,
        color: '#999'
    },
    '.header-title': {
        ...headings.h3,
        paddingBottom: '0.5rem',
        textAlign: 'center',
        textTransform: 'uppercase'
    },
    '.page': {
        minHeight: '1080px',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-between',
        pageBreakInside: 'avoid',
        pageBreakAfter: 'always'
    },
    '.fuel-row td': {
        backgroundColor: headerCellBg
    },
    '.excluded-cell': {
        backgroundColor: '#f7f7f7',
        color: '#999'
    },
    '.excluded-cell-emphasized': {
        backgroundColor: '#f7f7f7',
        color: '#0f2e39',
        fontWeight: 'bold'
    },
    '.report-title': {
        ...headings.h1,
        paddingBottom: '0.5rem',
        paddingTop: '1rem'
    },
    '.sten-table--sm td': {
        width: '21%',
        padding: '0.25rem 0.5rem'
    },
    '.sten-table td:first-child': {
        width: '16%',
        backgroundColor: headerCellBg,
        fontWeight: 'bold'
    },
    '.sten-table td.sten-table__cell--no-padding': {
        padding: 0
    },
    '.sten-table th': {
        fontWeight: 'bold'
    },
    '.sten-table tr.sten-table__row--highlighted td': {
        backgroundColor: tableHighlightRowBg,
        fontWeight: 'bold'
    },
    '.sten-table td.tank-layout-cell:first-child': {
        backgroundColor: '#fff',
        fontWeight: 400,
        textAlign: 'center',
        padding: '1rem'
    },
    '.cell-style': {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    '.sten-table': {
        tableLayout: 'fixed'
    },
    '.ballast-laden-row': {
        width: '50%'
    },
    '.text-center': {
        textAlign: 'center !important'
    },
    '.sten-voyage-performance-net-daily__trend': {
        display: 'flex',
        backgroundColor: '#aaa',
        width: '2.5rem',
        height: '2.5rem',
        borderRadius: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '2rem',
        lineHeight: '2.5rem',
        marginLeft: '1rem'
    },
    ...tankLayoutVesselPdfStyle,
    ...trendIconPdfStyle,
    '.sten-trend-icon': {
        lineHeight: '2.5rem',
        fontWeight: 400
    },
    '.sten-trend-icon:before': {
        color: '#fff'
    },
    '.sten-table.sten-raw-reports-table-row__cargo-operations th': {
        fontWeight: 'normal',
        whiteSpace: 'normal'
    },
    '.sten-table.sten-raw-reports-table-row__cargo-operations td': {
        fontWeight: 'normal'
    },
    '.sten-table.sten-raw-reports-table-row__cargo-operations': {
        border: 'none'
    },
    '.sten-table.sten-raw-reports-table-row__cargo-operations thead tr:first-child': {
        borderTop: 'none'
    },
    '.sten-table.sten-raw-reports-table-row__cargo-operations th:first-child': {
        width: '1rem'
    },
    '.sten-table.sten-raw-reports-table-row__cargo-operations td:last-child': {
        borderRight: 'none'
    },
    '.sten-table.sten-raw-reports-table-row__cargo-operations .col-12': {
        float: 'none'
    }
};

export default `${stenTableStyles}${convertJSObjectToCssString(css)}`;
