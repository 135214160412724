import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { splitSegments } from 'components/segment-select/segment-select-helper';
/* components */
import Select from 'components/select/select';
import Input from 'components/input/input';
import DateRangePopup from 'components/date-range-popup/date-range-popup';
/* selectors */
import { areFiltersChanged } from '../inspections-selectors';
/* actions */
import { setSelectedFilterOption } from '../inspections-actions';

class InspectionsFilters extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            searchCriteria: this.props.selectedFilterOptions.vessel || ''
        };
    }

    resetFilters = () => {
        this.setState(
            { searchCriteria: '' },
            this.props.setSelectedFilterOption('reset')
        );
    };

    handleSearchCriteriaUpdate = value => {
        this.setState({
            searchCriteria: value
        });
        if (this.trimmedValue !== value.trim()) {
            this.trimmedValue = value.trim();
        }
        if (this.trimmedValue.length >= 1 || !this.trimmedValue) {
            if (this.debounce) {
                clearTimeout(this.debounce);
            }
            this.debounce = setTimeout(() => {
                this.props.setSelectedFilterOption('vessel', this.trimmedValue);
            }, 500);
        }
    };

    handleRangeChange = ({ rangeStart, rangeEnd }) => {
        this.props.setSelectedFilterOption('inspectionRange', { from: rangeStart, to: rangeEnd });
    };

    handleCompanyChange = value => this.props.setSelectedFilterOption('companies', value);

    handleSegmentChange = value => this.props.setSelectedFilterOption('segments', value);

    handleViewOptionChange = value => this.props.setSelectedFilterOption('viewOption', value);

    handleInspectionTypeChange = value => this.props.setSelectedFilterOption('inspectionType', value);

    handleHeadOwnerChange = value => this.props.setSelectedFilterOption('headOwner', value);

    handleTechnicalManagerChange = value => this.props.setSelectedFilterOption('technicalManager', value);

    render() {
        const { selectedFilterOptions } = this.props;
        return (
            <div className="sten-inspections-filters sten-content__section flex-row flex-row--md flex-end">
                <div className="col-xs-24 col-md-20 col-lg-21">
                    <div className="flex flex-col">
                        <div className="row row--fluid sten-inspections-filters__row">
                            <div className="col-xs-12 col-md-6 col-lg-6">
                                <label className="label">
                                    {translate('INSPECTIONS.FILTERS.VESSEL')}
                                </label>
                                <Input
                                    suffixIcon="icon-search"
                                    onChange={this.handleSearchCriteriaUpdate.bind(this)}
                                    value={this.state.searchCriteria}
                                    title={translate('INSPECTIONS.FILTERS.VESSEL_SEARCH_TITLE')}
                                    placeholder={translate('INSPECTIONS.FILTERS.VESSEL_PLACEHOLDER')}
                                />
                            </div>
                            <div className="col-xs-12 col-md-8 col-lg-6">
                                <label className="label">
                                    {translate('INSPECTIONS.FILTERS.INSPECTION_RANGE')}
                                </label>
                                <DateRangePopup
                                    isClearable
                                    isUTC
                                    invalid={
                                        selectedFilterOptions.inspectionRange.from === 'Invalid Date'
                                        || selectedFilterOptions.inspectionRange.to === 'Invalid Date'
                                        || selectedFilterOptions.inspectionRange.from
                                            > selectedFilterOptions.inspectionRange.to
                                    }
                                    alignment="start"
                                    shouldRenderDefaultPresets
                                    name="dataRange"
                                    onRangeSelect={this.handleRangeChange}
                                    value={{
                                        rangeStart: selectedFilterOptions.inspectionRange.from,
                                        rangeEnd: selectedFilterOptions.inspectionRange.to
                                    }}
                                />
                            </div>
                            <div className="col-xs-12 col-md-5 col-lg-6">
                                <label className="label">
                                    {translate('INSPECTIONS.FILTERS.COMPANY_FLEET')}
                                </label>
                                <Select
                                    name="selectedCompanies"
                                    searchable
                                    multiple
                                    clearable
                                    valueKey="Id"
                                    labelKey="Name"
                                    options={this.props.companies}
                                    placeholder={translate('INSPECTIONS.FILTERS.SELECT_COMPANY_FLEETS')}
                                    value={selectedFilterOptions.companies}
                                    onChange={this.handleCompanyChange}
                                />
                            </div>
                            <div className="col-xs-12 col-md-5 col-lg-6">
                                <label className="label">
                                    {translate('INSPECTIONS.FILTERS.SEGMENT')}
                                </label>
                                <Select
                                    name="selectedSegments"
                                    searchable
                                    multiple
                                    clearable
                                    valueKey="VesselTypeId"
                                    labelKey="VesselTypeName"
                                    options={this.props.segments}
                                    placeholder={translate('INSPECTIONS.FILTERS.SEGMENT_PLACEHOLDER')}
                                    value={selectedFilterOptions.segments}
                                    onChange={this.handleSegmentChange}
                                    splitOptionsIntoSections={splitSegments}
                                />
                            </div>
                        </div>
                        <div className="row row--fluid sten-inspections-filters__row">
                            <div className="col-xs-12 col-md-6 col-lg-6">
                                <label className="label">
                                    {translate('INSPECTIONS.FILTERS.INSPECTION_TYPE')}
                                </label>
                                <Select
                                    name="selectedInspectionType"
                                    searchable
                                    clearable
                                    valueKey="Id"
                                    labelKey="Name"
                                    options={this.props.inspectionTypes}
                                    placeholder={translate('INSPECTIONS.FILTERS.INSPECTION_TYPE_PLACEHOLDER')}
                                    value={selectedFilterOptions.inspectionType}
                                    onChange={this.handleInspectionTypeChange}
                                />
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-6">
                                <label className="label">
                                    {translate('INSPECTIONS.FILTERS.TECHNICAL_MANAGER')}
                                </label>
                                <Select
                                    name="selectTechnicalManager"
                                    searchable
                                    clearable
                                    valueKey="Id"
                                    labelKey="Name"
                                    options={this.props.technicalManagers}
                                    placeholder={translate('INSPECTIONS.FILTERS.SELECT_TECHNICAL_MANAGER')}
                                    value={selectedFilterOptions.technicalManager}
                                    onChange={this.handleTechnicalManagerChange}
                                />
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-6">
                                <label className="label">
                                    {translate('INSPECTIONS.FILTERS.HEAD_OWNER')}
                                </label>
                                <Select
                                    name="selectHeadOwner"
                                    searchable
                                    clearable
                                    valueKey="Id"
                                    labelKey="Name"
                                    options={this.props.headOwners}
                                    placeholder={translate('INSPECTIONS.FILTERS.SELECT_HEAD_OWNER')}
                                    value={selectedFilterOptions.headOwner}
                                    onChange={this.handleHeadOwnerChange}
                                />
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-6">
                                <label className="label">
                                    {translate('INSPECTIONS.FILTERS.VIEW')}
                                </label>
                                <Select
                                    name="selectedViewOption"
                                    valueKey="Id"
                                    labelKey="Name"
                                    options={this.props.viewOptions}
                                    placeholder={translate('INSPECTIONS.FILTERS.VIEW_PLACEHOLDER')}
                                    value={selectedFilterOptions.viewOption}
                                    onChange={this.handleViewOptionChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-24 col-md-4 col-lg-3 col-pad--sm">
                    <button
                        disabled={!this.props.areFiltersChanged}
                        type="button"
                        className="btn btn--secondary col-24"
                        onClick={this.resetFilters}
                    >
                        {translate('INSPECTIONS.FILTERS.CLEAR_FILTERS')}
                    </button>
                </div>
            </div>
        );
    }
}

InspectionsFilters.propTypes = {
    areFiltersChanged: PropTypes.bool.isRequired,
    companies: PropTypes.arrayOf(PropTypes.any).isRequired,
    headOwners: PropTypes.arrayOf(PropTypes.any).isRequired,
    inspectionTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
    segments: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedFilterOptions: PropTypes.objectOf(PropTypes.any).isRequired,
    setSelectedFilterOption: PropTypes.func.isRequired,
    technicalManagers: PropTypes.arrayOf(PropTypes.any).isRequired,
    viewOptions: PropTypes.arrayOf(PropTypes.object).isRequired
};

InspectionsFilters.defaultProps = {

};

function mapStateToProps(state) {
    return {
        areFiltersChanged: areFiltersChanged(state),
        companies: state.inspectionsReducer.companies,
        filterOptions: state.inspectionsReducer.filterOptions,
        headOwners: state.inspectionsReducer.headOwners,
        inspectionTypes: state.inspectionsReducer.inspectionTypes,
        segments: state.inspectionsReducer.segments,
        selectedFilterOptions: state.inspectionsReducer.selectedFilterOptions,
        technicalManagers: state.inspectionsReducer.technicalManagers,
        viewOptions: state.inspectionsReducer.viewOptions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setSelectedFilterOption: (option, value) => setSelectedFilterOption(dispatch, option, value)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(InspectionsFilters);
