import React from 'react';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* helpers */
import { swedishCompare } from 'utils/helpers/info-helper';
import { renderBadges } from 'pages/user-pages/fleet-management/fleet-management-utils';
/* components */
import TextHighlight from 'components/text-highlight/text-highlight';

export const getCustomFleets = (vesselFleetsResponses, omitDynamic = false) => {
    const fleets = [];
    vesselFleetsResponses.forEach((fleetResponse) => {
        fleetResponse.forEach((vesselGroup) => {
            if ((!omitDynamic || !vesselGroup.IsDynamic)
                && !fleets.find(group => group.VesselGroupId === vesselGroup.VesselGroupId)) {
                fleets.push(vesselGroup);
            }
        });
    });

    return fleets.sort((a, b) => {
        if (a.IsDefault) {
            return -1;
        }
        if (b.IsDefault) {
            return 1;
        }
        if (a.IsPublic && !b.IsPublic) {
            return 1;
        }
        if (!a.IsPublic && b.IsPublic) {
            return -1;
        }
        return swedishCompare(a.VesselGroupName, b.VesselGroupName);
    });
};

export const fleetOptionRenderer = (fleet, searchCriteria, vesselCount) => {
    return (
        <div className="flex flex-center">
            <div className="flex-grow">
                <h4>
                    {searchCriteria
                        ? <TextHighlight input={fleet.VesselGroupName} highlight={searchCriteria} />
                        : fleet.VesselGroupName
                    }
                    {typeof (vesselCount) === 'number' ? ` (${vesselCount})` : null}
                </h4>
                {fleet.IsPublic && (
                    <h6 className="text-secondary">
                        {`${t('FLEET_MANAGEMENT.CREATED_BY')}: ${fleet.OwnerFirstName} ${fleet.OwnerLastName}`}
                    </h6>
                )}
            </div>
            <div className="flex-shrink">
                {renderBadges(fleet)}
            </div>
        </div>
    );
};
