import React from 'react';
import PropTypes from 'prop-types';
/* components */
import Input from 'components/input/input';
import Select from 'components/select/select';
import Validation from 'components/validation/validation';
import Checkbox from 'components/checkbox/checkbox';
import RadioButton from 'components/radio-button/radio-button';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { slopWingPositions } from '../tank-layout-helpers';
import { getClassName } from 'utils/helpers/info-helper';
import { tankOptions } from 'components/vessel-report/vessel-report-helpers';
/* styles */
import './tank-layout-form.scss';

const t = (key, values) => translate(`TANK_LAYOUT.FORM.${key}`, values);

const emptyTank = {
    IsSlop: false,
    Charterer: null,
    Commodity: null,
    Loaded: null,
    PreviousCommodities: [],
    Sg: null,
    Size: null,
    Temperature: null,
    TankState: tankOptions.Cargo
};

class TankLayoutForm extends React.Component {
    constructor(props) {
        super(props);
        const isSlopWingAdded = !!props.wings.find(wing => wing.IsSlop);
        let slopWingPosition = slopWingPositions.AFT;
        if (isSlopWingAdded) {
            slopWingPosition = props.wings[0].IsSlop ? slopWingPositions.FWD : slopWingPositions.AFT;
        }
        this.state = {
            isSlopWingAdded,
            slopWingPosition
        };
    }

    getRows = (validationRules) => ([
        {
            label: t('TANK_STATE'),
            prop: 'TankState'
        },
        {
            label: t('COMMODITY'),
            prop: 'Commodity',
            validations: validationRules.Tanks.Commodity,
            getDisabled: (tank) => tank.TankState.Id === 0
        },
        {
            label: `${t('SIZE')} (${translate('UNITS.CUBIC_CAPACITY')})`,
            prop: 'Size',
            validations: validationRules.Tanks.Size,
            getDisabled: (tank) => tank.TankState.Id === 0
        },
        {
            label: `${t('LOADED')} (${translate('UNITS.CUBIC_CAPACITY')})`,
            prop: 'Loaded',
            validations: validationRules.Tanks.Loaded,
            getDisabled: (tank) => tank.TankState.Id === 0
        },
        {
            label: t('CHARTERER'),
            prop: 'Charterer',
            validations: validationRules.Tanks.Charterer,
            getDisabled: (tank) => tank.TankState.Id !== 1
        },
        {
            label: `${t('TEMPERATURE')} (${translate('UNITS.TEMPERATURE')})`,
            prop: 'Temperature',
            validations: validationRules.Tanks.Temperature,
            getDisabled: (tank) => tank.TankState.Id !== 1
        },
        {
            label: t('SG'),
            prop: 'Sg',
            validations: validationRules.Tanks.Sg,
            getDisabled: (tank) => tank.TankState.Id !== 1
        }
    ]);

    getWingNumber = (wing) => {
        return this.state.isSlopWingAdded && this.state.slopWingPosition === slopWingPositions.FWD
            ? wing.Index
            : wing.Index + 1;
    }

    updateWings = (newWingCount) => {
        const { onChange, wings, noOfWings } = this.props;
        const { isSlopWingAdded, slopWingPosition } = this.state;
        const newValues = Array(newWingCount || 0);

        if (noOfWings !== newWingCount) {
            onChange('NoOfWings', newWingCount);
        }

        const emptySlopTank = {
            ...emptyTank,
            TankState: tankOptions.Slop,
            IsSlop: true
        };
        const slopWingIndex = wings.findIndex(wing => wing.IsSlop);
        let indexCorrection = slopWingIndex === 0 ? 1 : 0;

        for (let i = 0; i < newWingCount; i++) {
            if (isSlopWingAdded
                && ((slopWingPosition === slopWingPositions.FWD && i === 0)
                    || (slopWingPosition === slopWingPositions.AFT && i === newWingCount - 1))) {
                indexCorrection--;
                if (slopWingIndex >= 0) {
                    newValues[i] = { ...wings[slopWingIndex], Index: i };
                } else {
                    newValues[i] = {
                        Index: i,
                        IsSlop: true,
                        Port: { ...emptySlopTank },
                        Starboard: { ...emptySlopTank }
                    };
                }
            } else if (wings[i + indexCorrection] && !wings[i + indexCorrection].IsSlop) {
                newValues[i] = { ...wings[i + indexCorrection], Index: i };
            } else {
                newValues[i] = { Index: i, IsSlop: false, Port: { ...emptyTank }, Starboard: { ...emptyTank } };
            }
        }

        onChange('Tanks', newValues);
    }

    handleNoOfWingsChange = (chosenNoOfWings) => {
        let noOfWings = chosenNoOfWings ? chosenNoOfWings.Value : 0;
        if (this.state.isSlopWingAdded) {
            noOfWings += 1;
        }
        this.updateWings(noOfWings);
    };

    handleTankChange = (index, prop, side, value) => {
        const updatedTanks = this.props.wings.slice();
        updatedTanks[index] = {
            ...updatedTanks[index],
            [side]: {
                ...updatedTanks[index][side],
                [prop]: value
            }
        };
        this.props.onChange('Tanks', updatedTanks);
    };

    handleAddingSlopWing = (event) => {
        event.stopPropagation();
        const { isSlopWingAdded } = this.state;
        const noOfWings = isSlopWingAdded
            ? this.props.noOfWings - 1
            : this.props.noOfWings + 1;
        this.setState({ isSlopWingAdded: !isSlopWingAdded }, () => {
            this.updateWings(noOfWings);
        });
    }

    handleSlopWingSide = (side) => (event) => {
        event.stopPropagation();
        this.setState({ slopWingPosition: side }, () => this.updateWings(this.props.noOfWings));
    }

    renderInput = (row, wing, side) => (
        <Validation.Wrapper validations={row.validations} hintsOnHover>
            <Input
                className="sten-input--sm"
                name={`Tanks.${wing.Index}.${side}.${row.prop}`}
                value={wing[side][row.prop]}
                onChange={this.handleTankChange.bind(this, wing.Index, row.prop, side)}
                disabled={row.getDisabled(wing[side])}
            />
        </Validation.Wrapper>
    );

    renderSelectOption = (option) => (
        <span className={`sten-tank-layout-form__select-option--${option.Id}`}>{option.Label}</span>
    );

    renderSelect = (row, wing, side) => (
        <Validation.Wrapper validations={{ required: true }} hintsOnHover>
            <Select
                className="sten-tank-layout-form__select sten-select--sm"
                name={`Tanks.${wing.Index}.${side}.${row.prop}`}
                value={wing[side][row.prop]}
                valueKey="Id"
                labelKey="Label"
                options={wing.IsSlop ? this.props.slopTankStateOptions : this.props.cargoTankStateOptions}
                onChange={this.handleTankChange.bind(this, wing.Index, row.prop, side)}
                optionRenderer={this.renderSelectOption}
            />
        </Validation.Wrapper>
    );

    /*
    * charterer, size, commodity, sg are required if loaded is set
    * size >= loaded
    * */

    render() {
        const { wings, noOfWingsOptions, noOfWings, validationRules } = this.props;
        const { isSlopWingAdded, slopWingPosition } = this.state;
        const rows = this.getRows(validationRules);
        const nonSlopWingsNumber = isSlopWingAdded ? noOfWings - 1 : noOfWings;
        const selectedNoOfWings = noOfWingsOptions[nonSlopWingsNumber - 1];

        return (
            <div className="sten-tank-layout-form">
                <div className="sten-content__section">
                    <div className="flex-row flex-center form-row form-row--inline">
                        <div className="col-8">
                            <label className="label">{t('NUMBER_OF_WINGS')}</label>
                        </div>
                        <div className="col-16">
                            <Validation.Wrapper validations={validationRules.NoOfWings} hintsOnHover>
                                <Select
                                    name="NoOfWings"
                                    clearable
                                    onChange={this.handleNoOfWingsChange}
                                    placeholder={t('CHOOSE_NUMBER_OF_WINGS')}
                                    options={noOfWingsOptions}
                                    value={selectedNoOfWings}
                                    labelKey="Value"
                                    valueKey="Value"
                                />
                            </Validation.Wrapper>
                        </div>
                    </div>
                    <div className="flex-row flex-center form-row form-row--inline">
                        <div className="flex-shrink">
                            <Checkbox isChecked={isSlopWingAdded} onChange={this.handleAddingSlopWing}>
                                {t('ADD_SLOP_WING')}
                            </Checkbox>
                        </div>
                        <div className="flex-shrink">
                            <RadioButton
                                id="aft"
                                isDisabled={!isSlopWingAdded}
                                isChecked={slopWingPosition === slopWingPositions.AFT}
                                onChange={this.handleSlopWingSide(slopWingPositions.AFT)}
                            >
                                {t('AFT')}
                            </RadioButton>
                        </div>
                        <div className="flex-shrink">
                            <RadioButton
                                id="fwd"
                                isDisabled={!isSlopWingAdded}
                                isChecked={slopWingPosition === slopWingPositions.FWD}
                                onChange={this.handleSlopWingSide(slopWingPositions.FWD)}
                            >
                                {t('FWD')}
                            </RadioButton>
                        </div>
                    </div>
                </div>
                {wings.map(wing => {
                    const wingNo = this.getWingNumber(wing);
                    const wingHeaderClass = getClassName('sten-tank-layout-form__wing-header', {
                        'sten-tank-layout-form__wing-header--slop': wing.IsSlop
                    });
                    return (
                        <React.Fragment key={wing.Index}>
                            <Validation.Wrapper>
                                <Validation.Value value={wing.IsSlop} name={`Tanks.${wing.Index}.IsSlop`} />
                            </Validation.Wrapper>
                            <table className="sten-table sten-table--no-border-top sten-tank-layout-form__wing">
                                <thead className="sten-table--sm">
                                    <tr>
                                        <th className="text-secondary">
                                            {wing.IsSlop ? t('SLOP_WING') : t('WING', { no: wingNo })}
                                        </th>
                                        <th className={wingHeaderClass}>
                                            {wing.IsSlop ? t('SLOP_PORT') : t('PORT', { no: wingNo })}
                                        </th>
                                        <th className={wingHeaderClass}>
                                            {wing.IsSlop ? t('SLOP_STARBOARD') : t('STARBOARD', { no: wingNo })}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="sten-table--xs">
                                    {rows.map(row => (
                                        <tr key={row.prop}>
                                            <td className="text-secondary">{row.label}</td>
                                            <td className="sten-table__td--has-input">
                                                {row.prop === 'TankState'
                                                    ? this.renderSelect(row, wing, 'Port')
                                                    : this.renderInput(row, wing, 'Port')}
                                            </td>
                                            <td className="sten-table__td--has-input">
                                                {row.prop === 'TankState'
                                                    ? this.renderSelect(row, wing, 'Starboard')
                                                    : this.renderInput(row, wing, 'Starboard')}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </React.Fragment>
                    );
                })}
            </div>
        );
    }
}

TankLayoutForm.propTypes = {
    cargoTankStateOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    noOfWings: PropTypes.number,
    noOfWingsOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    slopTankStateOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    validationRules: PropTypes.objectOf(PropTypes.any),
    wings: PropTypes.arrayOf(PropTypes.shape({
        Charterer: PropTypes.string,
        Commodity: PropTypes.string,
        IsSlop: PropTypes.bool,
        Loaded: PropTypes.number,
        Sg: PropTypes.number,
        Size: PropTypes.number,
        TankState: PropTypes.objectOf(PropTypes.any),
        Temperature: PropTypes.number
    }))
};

TankLayoutForm.defaultProps = {
    noOfWings: null,
    validationRules: null,
    wings: null
};

export default TankLayoutForm;
