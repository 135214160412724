import { createSelector } from 'reselect';
import moment from 'moment';

export const getSelectedPredefinedRange = createSelector(
    state => state.userReducer.settings && state.userReducer.settings.FleetActivityRangeId,
    state => state.vesselInfoReducer.rangeOptions,

    (rangeId, rangeOptions) => {
        if (rangeId && rangeOptions && rangeOptions.length) {
            return rangeOptions.find(range => range.Id === rangeId);
        }

        return null;
    }
);

export const getSelectedDateRange = createSelector(
    getSelectedPredefinedRange,
    state => state.mapDatePickerReducer.selectedDate,
    state => state.userReducer.settings && state.userReducer.settings.FleetActivityModeId,
    state => state.fleetActivityReducer.rangeModes,
    state => state.userReducer.settings && state.userReducer.settings.VesselActivityDateFrom,
    state => state.userReducer.settings && state.userReducer.settings.VesselActivityDateTo,

    (selectedRange, selectedDate, mode, rangeModes, from, to) => {
        if (mode === rangeModes.PREDEFINED_RANGE && selectedRange) {
            return {
                from: selectedRange.Type === 'ToDate'
                    ? moment.utc().startOf(selectedRange.Unit)
                    : moment.utc(selectedDate).startOf('d').subtract(selectedRange.Value, selectedRange.Unit),
                to: moment.utc(selectedDate).startOf('d')
            };
        }
        if (!from || !to) {
            return {
                from: moment.utc().startOf('d').subtract(14, 'd'),
                to: moment.utc().startOf('d')
            };
        }
        return {
            from: moment.utc(from),
            to: moment.utc(to)
        };
    }
);

export default getSelectedDateRange;
