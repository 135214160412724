import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* actions */
import { toggleUserSetting } from '../../../../user-actions';
/* components */
import Positioner from 'components/positioner/positioner';
import BunkerPricesTooltipContent from './bunker-prices-tooltip-content';
/* styles */
import './bunker-prices-tooltip.scss';

export class BunkerPricesTooltip extends React.PureComponent {
    renderSingleBunkerPort = (bunkerPort) => {
        return (
            <Positioner
                className="sten-bunker-prices-tooltip"
                top={bunkerPort.top}
                left={bunkerPort.left}
                key={bunkerPort.PortCode}
            >
                <BunkerPricesTooltipContent
                    bunkerPort={bunkerPort}
                    toggleUserSetting={this.props.toggleUserSetting}
                />
            </Positioner>
        );
    };

    render() {
        if (this.props.positions.length) {
            return (
                <div className="sten-bunker-prices-tooltips">
                    {this.props.positions.map(this.renderSingleBunkerPort)}
                </div>
            );
        }
        return null;
    }
}

BunkerPricesTooltip.propTypes = {
    positions: PropTypes.arrayOf(PropTypes.any).isRequired,
    toggleUserSetting: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        positions: state.bunkerPricesTooltipReducer.positions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleUserSetting: (settingName, value) => toggleUserSetting(dispatch, settingName, value)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(BunkerPricesTooltip);
