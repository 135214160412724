import Excel from 'exceljs';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { addWorksheet, triggerXLSXDownload } from 'utils/helpers/xlsx-helper';
import {
    columnWidth,
    darkCellStyle,
    darkHeaderRowStyle,
    tableCellStyle,
    xlsxColors,
    thinBlueBorder
} from 'utils/helpers/xlsx-styles';
import { getLogoBase64 } from 'utils/base64images';

const t = (key) => translate(`FLEET_RANGE.${key}`);

const formatTableCell = (value, props = null) => ({
    value: value !== null ? value : '-',
    colSpan: 1,
    props: { ...tableCellStyle, ...props }
});

const generateHeaderRows = () => {
    return ([
        {
            props: { height: 50 },
            values: [
                {
                    colSpan: 7,
                    props: darkHeaderRowStyle
                },
                ...new Array(6).fill(null),
                {
                    image: { base64: getLogoBase64().light, extension: 'png' },
                    imageProps: {
                        tl: { col: 0.1, row: 0.5 },
                        ext: { width: getLogoBase64().width, height: getLogoBase64().height }
                    }
                }
            ]
        }
    ]);
};

const generateVesselRow = (vessel) => {
    const severityMap = {
        Red: xlsxColors.red,
        Yellow: xlsxColors.yellow,
        Green: xlsxColors.green,
        None: xlsxColors.white
    };

    const severityFill = { fill: {
        type: 'pattern',
        pattern: 'solid',
        bgColor: { argb: severityMap[vessel.ConsumptionDaysSeverity] },
        fgColor: { argb: severityMap[vessel.ConsumptionDaysSeverity] }
    } };

    const borderStyle = { border: {
        top: thinBlueBorder,
        left: thinBlueBorder,
        bottom: thinBlueBorder,
        right: thinBlueBorder
    } };

    const severityStyle = { ...severityFill, ...borderStyle };
    return ({
        values: [
            formatTableCell(vessel.ROBFuelRangeDays, severityStyle),
            formatTableCell(vessel.Title.toUpperCase(), borderStyle),
            formatTableCell(typeof vessel.ReportDate === 'string' ? new Date(vessel.ReportDate) : '-', borderStyle),
            formatTableCell(vessel.BunkerROBFuel, borderStyle),
            formatTableCell(vessel.BunkerROBGas, borderStyle),
            formatTableCell(vessel.BunkerROBLiquidGas, borderStyle),
            formatTableCell(vessel.MissingDataMessage, borderStyle)
        ]
    });
};

const generateTableRows = (vessels) => {
    const headerRowCellStyle = { ...darkCellStyle, border: {} };
    const headerRow = {
        props: { height: 30 },
        values: [
            formatTableCell(t('REMAINING_DAYS'), headerRowCellStyle),
            formatTableCell(t('VESSEL'), headerRowCellStyle),
            formatTableCell(t('REPORTED_ON'), headerRowCellStyle),
            formatTableCell(t('FO_ROB'), headerRowCellStyle),
            formatTableCell(t('GO_ROB'), headerRowCellStyle),
            formatTableCell(t('LG_ROB'), headerRowCellStyle),
            formatTableCell(t('NOTE'), headerRowCellStyle)
        ]
    };

    const rows = [headerRow];
    if (vessels && vessels.length > 0) {
        vessels.forEach((vessel) => {
            rows.push(generateVesselRow(vessel));
        });
    }
    return rows;
};

export default function* generateFleetRangeXLSX(vessels) {
    const workbook = new Excel.Workbook();
    const sheetConfig = {
        name: 'Bunker Range',
        props: {
            properties: { showGridLines: false },
            views: [{ showGridLines: false }],
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                margins: { left: 0.4, right: 0.4, top: 0.2, bottom: 0.2, header: 0, footer: 0 }
            }
        },
        columnWidth,
        columns: [
            { width: 12 }, // Remaining days
            { width: 24 }, // Vessel
            { width: 18 }, // Reported on
            { width: 10 }, // FO ROB
            { width: 10 }, // GO ROB
            { width: 10 }, // LG ROB
            { width: 25 } // Note
        ],
        rows: [
            ...generateHeaderRows(),
            ...generateTableRows(vessels)
        ]
    };

    addWorksheet(workbook, sheetConfig);

    const xls64 = yield workbook.xlsx.writeBuffer({ base64: true });
    return triggerXLSXDownload(
        'Fleet Overview - Range'
    )(xls64);
}
