import { takeEvery, put, select } from 'redux-saga/effects';
import { ActionTypes } from './cargo-inquiry-actions';
/* Services */
import InquiryService from 'services/core-api/inquiry-service';

function* sendCargoInquiryData() {
    const parsedCargoInquiryData = {};

    const dischargingPort = yield select(state => state.cargoInquiryReducer.dischargingPort);
    const loadingPort = yield select(state => state.cargoInquiryReducer.loadingPort);

    parsedCargoInquiryData.CargoInformation = yield select(state => state.cargoInquiryReducer.cargoInformation);
    if (loadingPort) {
        if (loadingPort.Type === 2 && loadingPort.Id) {
            parsedCargoInquiryData.LoadingPortId = loadingPort.Id;
        } else {
            parsedCargoInquiryData.LoadingPortName = loadingPort.Name;
        }
    }
    if (dischargingPort) {
        if (dischargingPort.Type === 2 && dischargingPort.Id) {
            parsedCargoInquiryData.DischargingPortId = dischargingPort.Id;
        } else {
            parsedCargoInquiryData.DischargingPortName = dischargingPort.Name;
        }
    }
    parsedCargoInquiryData.LoadingDate = yield select(state => state.cargoInquiryReducer.loadingDate);
    parsedCargoInquiryData.DischargingDate = yield select(state => state.cargoInquiryReducer.dischargingDate);

    const cargoInquiryData = yield InquiryService.sendCargoInquiryData(parsedCargoInquiryData);
    if (cargoInquiryData) {
        yield put({ type: ActionTypes.CARGO_INQUIRY_MESSAGE_SENT });
    }
}

export default function* contactInquirySaga() {
    yield takeEvery(ActionTypes.CARGO_INQUIRY_SEND_DATA, sendCargoInquiryData);
}
