export default {
    SegmentGraphList: [
        {
            Id: 1,
            Title: 'Suezmax',
            BallastKPIRatio: 2.726976638347139,
            LadenKPIRatio: 5.22110111169809,
            WeightedCargoRatio: 1.356040999191066,
            MonthlyDataList: [
                {
                    Date: '2019-10-01T00:00:00Z',
                    Cargo: 1726.159,
                    BallastKPI: 112.4224133022038,
                    LadenKPI: 144.3705846359237
                },
                {
                    Date: '2019-11-01T00:00:00Z',
                    Cargo: 1216.1995,
                    BallastKPI: 122.44136163574569,
                    LadenKPI: 148.86078002108169
                },
                {
                    Date: '2019-12-01T00:00:00Z',
                    Cargo: 1428.0070000000003,
                    BallastKPI: 114.4756308474506,
                    LadenKPI: 156.9836815299866
                },
                {
                    Date: '2020-01-01T00:00:00Z',
                    Cargo: 1338.187,
                    BallastKPI: 109.72805144441989,
                    LadenKPI: 148.5406452311943
                },
                {
                    Date: '2020-02-01T00:00:00Z',
                    Cargo: 1351.1002,
                    BallastKPI: 113.78340907340366,
                    LadenKPI: 146.04904963790898
                },
                {
                    Date: '2020-03-01T00:00:00Z',
                    Cargo: 1388.4895,
                    BallastKPI: 112.68747916898121,
                    LadenKPI: 139.83216106082818
                },
                {
                    Date: '2020-04-01T00:00:00Z',
                    Cargo: 1398.5362857142857,
                    BallastKPI: 117.95730628611864,
                    LadenKPI: 155.21932686034469
                },
                {
                    Date: '2020-05-01T00:00:00Z',
                    Cargo: 1395.9913749999998,
                    BallastKPI: 118.61185915349824,
                    LadenKPI: 156.80218050026744
                },
                {
                    Date: '2020-06-01T00:00:00Z',
                    Cargo: 1366.4817777777778,
                    BallastKPI: 130.42369663104213,
                    LadenKPI: 151.42682146448487
                },
                {
                    Date: '2020-07-01T00:00:00Z',
                    Cargo: 1372.5849999999998,
                    BallastKPI: 130.79903766313959,
                    LadenKPI: 158.50819369664035
                },
                {
                    Date: '2020-08-01T00:00:00Z',
                    Cargo: 1382.4133636363638,
                    BallastKPI: 127.34003782811274,
                    LadenKPI: 155.79220779220762
                },
                {
                    Date: '2020-09-01T00:00:00Z',
                    Cargo: 1355.5429166666665,
                    BallastKPI: 117.53587858294344,
                    LadenKPI: 159.25372334008068
                },
                {
                    Date: '2020-10-01T00:00:00Z',
                    Cargo: 1366.6445384615386,
                    BallastKPI: 127.57521225544481,
                    LadenKPI: 155.39605435006763
                },
                {
                    Date: '2020-11-01T00:00:00Z',
                    Cargo: 1455.804846153846,
                    BallastKPI: 128.45264792133864,
                    LadenKPI: 155.7041488668454
                },
                {
                    Date: '2020-12-01T00:00:00Z',
                    Cargo: 1477.8513076923077,
                    BallastKPI: 120.05871229176044,
                    LadenKPI: 159.44676947181787
                },
                {
                    Date: '2021-01-01T00:00:00Z',
                    Cargo: 1489.9070000000002,
                    BallastKPI: 113.66619286901167,
                    LadenKPI: 154.0567357842514
                },
                {
                    Date: '2021-02-01T00:00:00Z',
                    Cargo: 1502.4774615384617,
                    BallastKPI: 119.13002231162486,
                    LadenKPI: 152.49810767063002
                },
                {
                    Date: '2021-03-01T00:00:00Z',
                    Cargo: 1521.7508461538464,
                    BallastKPI: 114.04452428046658,
                    LadenKPI: 147.34451485029575
                },
                {
                    Date: '2021-04-01T00:00:00Z',
                    Cargo: 1538.719692307692,
                    BallastKPI: 121.25010897044729,
                    LadenKPI: 153.84476016487247
                },
                {
                    Date: '2021-05-01T00:00:00Z',
                    Cargo: 1583.2743076923073,
                    BallastKPI: 123.46548378153589,
                    LadenKPI: 149.69192701960475
                },
                {
                    Date: '2021-06-01T00:00:00Z',
                    Cargo: 1619.5834615384613,
                    BallastKPI: 125.09683525311046,
                    LadenKPI: 145.83582018096814
                },
                {
                    Date: '2021-07-01T00:00:00Z',
                    Cargo: 1644.6522307692308,
                    BallastKPI: 121.05034897325204,
                    LadenKPI: 143.58406716622
                },
                {
                    Date: '2021-08-01T00:00:00Z',
                    Cargo: 1633.0522307692308,
                    BallastKPI: 125.76039909030885,
                    LadenKPI: 150.98934737357666
                },
                {
                    Date: '2021-09-01T00:00:00Z',
                    Cargo: 1612.2246923076925,
                    BallastKPI: 110.33090407721097,
                    LadenKPI: 150.22455355557923
                }
            ]
        },
        {
            Id: 16,
            Title: 'Intermediate',
            BallastKPIRatio: -5.4501094241349506,
            LadenKPIRatio: -12.20124895324858,
            WeightedCargoRatio: 2.9907886034442441,
            MonthlyDataList: [
                {
                    Date: '2019-10-01T00:00:00Z',
                    Cargo: 77.441,
                    BallastKPI: 85.778894472361813,
                    LadenKPI: 63.343170320404724
                },
                {
                    Date: '2019-11-01T00:00:00Z',
                    Cargo: 46.8675,
                    BallastKPI: 59.412808517502825,
                    LadenKPI: 58.09271523178807
                },
                {
                    Date: '2019-12-01T00:00:00Z',
                    Cargo: 35.543333333333329,
                    BallastKPI: 54.494000452796023,
                    LadenKPI: 62.6561942163513
                },
                {
                    Date: '2020-01-01T00:00:00Z',
                    Cargo: 29.0365,
                    BallastKPI: 54.418604651162795,
                    LadenKPI: 215.0
                },
                {
                    Date: '2020-02-01T00:00:00Z',
                    Cargo: 28.365,
                    BallastKPI: 53.63457760314342,
                    LadenKPI: 60.915275200989484
                },
                {
                    Date: '2020-03-01T00:00:00Z',
                    Cargo: 23.645999999999997,
                    BallastKPI: null,
                    LadenKPI: 53.787878787878789
                },
                {
                    Date: '2020-04-01T00:00:00Z',
                    Cargo: 24.045142857142856,
                    BallastKPI: 54.736328124999986,
                    LadenKPI: 64.686040477426047
                },
                {
                    Date: '2020-05-01T00:00:00Z',
                    Cargo: 24.045142857142856,
                    BallastKPI: null,
                    LadenKPI: 71.875
                },
                {
                    Date: '2020-06-01T00:00:00Z',
                    Cargo: 24.045142857142856,
                    BallastKPI: null,
                    LadenKPI: 58.565737051792844
                },
                {
                    Date: '2020-07-01T00:00:00Z',
                    Cargo: 24.045142857142856,
                    BallastKPI: null,
                    LadenKPI: 50.490425035030356
                },
                {
                    Date: '2020-08-01T00:00:00Z',
                    Cargo: 22.52825,
                    BallastKPI: null,
                    LadenKPI: 68.363636363636374
                },
                {
                    Date: '2020-09-01T00:00:00Z',
                    Cargo: 20.641888888888886,
                    BallastKPI: null,
                    LadenKPI: 50.275735294117645
                },
                {
                    Date: '2020-10-01T00:00:00Z',
                    Cargo: 19.021800000000002,
                    BallastKPI: 57.348242811501592,
                    LadenKPI: 71.386430678466084
                },
                {
                    Date: '2020-11-01T00:00:00Z',
                    Cargo: 12.775400000000001,
                    BallastKPI: 49.322617680826625,
                    LadenKPI: 37.728459530026115
                },
                {
                    Date: '2020-12-01T00:00:00Z',
                    Cargo: 12.384444444444446,
                    BallastKPI: 49.287042777433356,
                    LadenKPI: 56.381260096930532
                },
                {
                    Date: '2021-01-01T00:00:00Z',
                    Cargo: 12.320625000000001,
                    BallastKPI: 71.293969849246224,
                    LadenKPI: 63.937282229965156
                },
                {
                    Date: '2021-02-01T00:00:00Z',
                    Cargo: 12.721285714285715,
                    BallastKPI: null,
                    LadenKPI: null
                },
                {
                    Date: '2021-03-01T00:00:00Z',
                    Cargo: 10.561666666666667,
                    BallastKPI: null,
                    LadenKPI: null
                },
                {
                    Date: '2021-04-01T00:00:00Z',
                    Cargo: 12.6638,
                    BallastKPI: null,
                    LadenKPI: null
                },
                {
                    Date: '2021-05-01T00:00:00Z',
                    Cargo: 9.2197500000000012,
                    BallastKPI: null,
                    LadenKPI: null
                },
                {
                    Date: '2021-06-01T00:00:00Z',
                    Cargo: 9.2197500000000012,
                    BallastKPI: null,
                    LadenKPI: 231.25
                },
                {
                    Date: '2021-07-01T00:00:00Z',
                    Cargo: 7.3759999999999994,
                    BallastKPI: null,
                    LadenKPI: 39.350649350649348
                },
                {
                    Date: '2021-08-01T00:00:00Z',
                    Cargo: 7.3759999999999994,
                    BallastKPI: null,
                    LadenKPI: null
                },
                {
                    Date: '2021-09-01T00:00:00Z',
                    Cargo: 8.1926,
                    BallastKPI: null,
                    LadenKPI: null
                }
            ]
        },
        {
            Id: 17,
            Title: 'P-MAX',
            BallastKPIRatio: -3.5439585949215768,
            LadenKPIRatio: -0.053896052403942463,
            WeightedCargoRatio: 0.70712790758827282,
            MonthlyDataList: [
                {
                    Date: '2019-10-01T00:00:00Z',
                    Cargo: 249.624,
                    BallastKPI: 108.66535644095049,
                    LadenKPI: 125.61507368153033
                },
                {
                    Date: '2019-11-01T00:00:00Z',
                    Cargo: 242.404,
                    BallastKPI: 112.05089400818335,
                    LadenKPI: 124.41768757558233
                },
                {
                    Date: '2019-12-01T00:00:00Z',
                    Cargo: 269.475,
                    BallastKPI: 107.45012891844212,
                    LadenKPI: 127.11999999999999
                },
                {
                    Date: '2020-01-01T00:00:00Z',
                    Cargo: 300.211,
                    BallastKPI: 105.27037449652927,
                    LadenKPI: 131.92438135912576
                },
                {
                    Date: '2020-02-01T00:00:00Z',
                    Cargo: 302.45279999999997,
                    BallastKPI: 105.29618768328443,
                    LadenKPI: 120.22104824134585
                },
                {
                    Date: '2020-03-01T00:00:00Z',
                    Cargo: 313.70716666666664,
                    BallastKPI: 112.71084762509707,
                    LadenKPI: 125.09136212624581
                },
                {
                    Date: '2020-04-01T00:00:00Z',
                    Cargo: 324.76928571428567,
                    BallastKPI: 99.453694068678487,
                    LadenKPI: 127.43218167358837
                },
                {
                    Date: '2020-05-01T00:00:00Z',
                    Cargo: 335.903875,
                    BallastKPI: 106.45210147671338,
                    LadenKPI: 122.20031545741321
                },
                {
                    Date: '2020-06-01T00:00:00Z',
                    Cargo: 329.89822222222227,
                    BallastKPI: 107.45074977947665,
                    LadenKPI: 126.0693421014003
                },
                {
                    Date: '2020-07-01T00:00:00Z',
                    Cargo: 341.0907,
                    BallastKPI: 103.96761133603241,
                    LadenKPI: 126.33342116159149
                },
                {
                    Date: '2020-08-01T00:00:00Z',
                    Cargo: 341.71518181818186,
                    BallastKPI: 102.51796352023585,
                    LadenKPI: 124.45766523978816
                },
                {
                    Date: '2020-09-01T00:00:00Z',
                    Cargo: 341.71966666666668,
                    BallastKPI: 112.06024373419174,
                    LadenKPI: 112.92787524366469
                },
                {
                    Date: '2020-10-01T00:00:00Z',
                    Cargo: 336.501,
                    BallastKPI: 99.999999999999986,
                    LadenKPI: 131.13913607166006
                },
                {
                    Date: '2020-11-01T00:00:00Z',
                    Cargo: 346.25123076923074,
                    BallastKPI: 111.54356990240802,
                    LadenKPI: 130.59701492537312
                },
                {
                    Date: '2020-12-01T00:00:00Z',
                    Cargo: 357.68076923076927,
                    BallastKPI: 125.72966846132047,
                    LadenKPI: 124.0402982315608
                },
                {
                    Date: '2021-01-01T00:00:00Z',
                    Cargo: 364.80584615384606,
                    BallastKPI: 107.43483264121679,
                    LadenKPI: 113.23621750596507
                },
                {
                    Date: '2021-02-01T00:00:00Z',
                    Cargo: 357.47176923076921,
                    BallastKPI: 98.251289673865514,
                    LadenKPI: 124.53726615856681
                },
                {
                    Date: '2021-03-01T00:00:00Z',
                    Cargo: 371.7683076923077,
                    BallastKPI: 103.13858614734082,
                    LadenKPI: 125.53504329357949
                },
                {
                    Date: '2021-04-01T00:00:00Z',
                    Cargo: 372.45161538461537,
                    BallastKPI: 105.57630736392743,
                    LadenKPI: 120.26863123848885
                },
                {
                    Date: '2021-05-01T00:00:00Z',
                    Cargo: 372.10153846153844,
                    BallastKPI: 101.72804532577904,
                    LadenKPI: 128.99850523168914
                },
                {
                    Date: '2021-06-01T00:00:00Z',
                    Cargo: 362.55392307692307,
                    BallastKPI: 108.98841857128217,
                    LadenKPI: 125.27401415571282
                },
                {
                    Date: '2021-07-01T00:00:00Z',
                    Cargo: 358.46184615384612,
                    BallastKPI: 95.9178333115269,
                    LadenKPI: 125.8235346916794
                },
                {
                    Date: '2021-08-01T00:00:00Z',
                    Cargo: 345.30100000000004,
                    BallastKPI: 89.777158774373248,
                    LadenKPI: 113.44957931959517
                },
                {
                    Date: '2021-09-01T00:00:00Z',
                    Cargo: 340.3403076923077,
                    BallastKPI: 90.381744508423964,
                    LadenKPI: 115.58405352662392
                }
            ]
        },
        {
            Id: 22,
            Title: 'Medium Range',
            BallastKPIRatio: -0.2704367689592857,
            LadenKPIRatio: 2.2364431000692093,
            WeightedCargoRatio: -4.6434183312597952,
            MonthlyDataList: [
                {
                    Date: '2019-10-01T00:00:00Z',
                    Cargo: 963.114,
                    BallastKPI: 67.627274468492644,
                    LadenKPI: 71.776475580136463
                },
                {
                    Date: '2019-11-01T00:00:00Z',
                    Cargo: 868.7385,
                    BallastKPI: 69.130494939911429,
                    LadenKPI: 79.158178119588271
                },
                {
                    Date: '2019-12-01T00:00:00Z',
                    Cargo: 1043.257,
                    BallastKPI: 73.684342315619162,
                    LadenKPI: 77.480401081952024
                },
                {
                    Date: '2020-01-01T00:00:00Z',
                    Cargo: 1177.3092500000002,
                    BallastKPI: 68.410791525905367,
                    LadenKPI: 75.052261754329564
                },
                {
                    Date: '2020-02-01T00:00:00Z',
                    Cargo: 1115.9934,
                    BallastKPI: 65.723717316479139,
                    LadenKPI: 78.1984010809593
                },
                {
                    Date: '2020-03-01T00:00:00Z',
                    Cargo: 1092.1988333333336,
                    BallastKPI: 67.5615322323379,
                    LadenKPI: 78.325476861108186
                },
                {
                    Date: '2020-04-01T00:00:00Z',
                    Cargo: 1116.7315714285717,
                    BallastKPI: 65.996153921626359,
                    LadenKPI: 76.191461904225761
                },
                {
                    Date: '2020-05-01T00:00:00Z',
                    Cargo: 1081.8515,
                    BallastKPI: 67.826048144731743,
                    LadenKPI: 78.915009393016788
                },
                {
                    Date: '2020-06-01T00:00:00Z',
                    Cargo: 1065.244,
                    BallastKPI: 69.82826660860573,
                    LadenKPI: 82.141394720813665
                },
                {
                    Date: '2020-07-01T00:00:00Z',
                    Cargo: 1032.2559999999999,
                    BallastKPI: 69.445494025566177,
                    LadenKPI: 78.030573955920346
                },
                {
                    Date: '2020-08-01T00:00:00Z',
                    Cargo: 1021.6678181818181,
                    BallastKPI: 66.067276097396572,
                    LadenKPI: 77.214361595664457
                },
                {
                    Date: '2020-09-01T00:00:00Z',
                    Cargo: 1020.7641666666665,
                    BallastKPI: 66.283771457003041,
                    LadenKPI: 79.025522613726
                },
                {
                    Date: '2020-10-01T00:00:00Z',
                    Cargo: 1011.6865384615382,
                    BallastKPI: 69.657269720866779,
                    LadenKPI: 79.412880656250209
                },
                {
                    Date: '2020-11-01T00:00:00Z',
                    Cargo: 990.93976923076912,
                    BallastKPI: 66.253809919645363,
                    LadenKPI: 81.610424219887847
                },
                {
                    Date: '2020-12-01T00:00:00Z',
                    Cargo: 997.33376923076924,
                    BallastKPI: 69.053807622169444,
                    LadenKPI: 77.951405929442032
                },
                {
                    Date: '2021-01-01T00:00:00Z',
                    Cargo: 952.50469230769238,
                    BallastKPI: 66.814494052063111,
                    LadenKPI: 79.595684430165889
                },
                {
                    Date: '2021-02-01T00:00:00Z',
                    Cargo: 893.622153846154,
                    BallastKPI: 65.24394880243311,
                    LadenKPI: 81.749908155767926
                },
                {
                    Date: '2021-03-01T00:00:00Z',
                    Cargo: 890.04484615384615,
                    BallastKPI: 68.351844616844332,
                    LadenKPI: 79.9109285302068
                },
                {
                    Date: '2021-04-01T00:00:00Z',
                    Cargo: 893.979076923077,
                    BallastKPI: 68.258926342072385,
                    LadenKPI: 78.271825707281792
                },
                {
                    Date: '2021-05-01T00:00:00Z',
                    Cargo: 871.82353846153831,
                    BallastKPI: 68.778417919724589,
                    LadenKPI: 77.44184316438654
                },
                {
                    Date: '2021-06-01T00:00:00Z',
                    Cargo: 863.82346153846152,
                    BallastKPI: 67.345807741751159,
                    LadenKPI: 78.739397723809262
                },
                {
                    Date: '2021-07-01T00:00:00Z',
                    Cargo: 849.30215384615383,
                    BallastKPI: 66.62748406994676,
                    LadenKPI: 81.815188213995924
                },
                {
                    Date: '2021-08-01T00:00:00Z',
                    Cargo: 859.41661538461528,
                    BallastKPI: 69.120868492951971,
                    LadenKPI: 77.287001751339844
                },
                {
                    Date: '2021-09-01T00:00:00Z',
                    Cargo: 830.179923076923,
                    BallastKPI: 69.650616298661,
                    LadenKPI: 79.989266721959268
                }
            ]
        }
    ],
    TotalData: {
        BallastKPI: 97.632532823747553,
        PreviousBallastKPI: 93.688570101011663,
        LadenKPI: 107.604046918008,
        PreviousLadenKPI: 104.52356941489037,
        WeightedCargoLaden: 64487.382614917507,
        PreviousWeightedCargoLaden: 62116.990807090042,
        EnergyEfficiency: 20.210487440292766,
        PreviousEnergyEfficiency: 21.29730142639646,
        PreviousAER: 10.023174235642982,
        AER: 8.4505263872672913,
        BallastKPIRatio: 4.20965195485816,
        LadenKPIRatio: 2.9471606455479247,
        WeightedCargoRatio: 3.8160119751919979,
        EnergyEfficiencyRatio: -5.47254309528416627,
        AERRatio: -25.0127883781655868
    },
    MonthlyDataList: [
        {
            Date: '2019-10-01T00:00:00Z',
            Cargo: 2586.507,
            BallastKPI: 92.801766019467067,
            LadenKPI: 104.46143552358747
        },
        {
            Date: '2019-11-01T00:00:00Z',
            Cargo: 2374.2095,
            BallastKPI: 96.964873636936773,
            LadenKPI: 104.32784660057385
        },
        {
            Date: '2019-12-01T00:00:00Z',
            Cargo: 2776.2823333333331,
            BallastKPI: 93.593895668679565,
            LadenKPI: 108.27640334041089
        },
        {
            Date: '2020-01-01T00:00:00Z',
            Cargo: 2844.74375,
            BallastKPI: 86.549385478706952,
            LadenKPI: 102.99550473568826
        },
        {
            Date: '2020-02-01T00:00:00Z',
            Cargo: 2797.9114,
            BallastKPI: 92.2768771187863,
            LadenKPI: 99.4569306860935
        },
        {
            Date: '2020-03-01T00:00:00Z',
            Cargo: 2818.0415,
            BallastKPI: 94.287715786840124,
            LadenKPI: 102.50234496691597
        },
        {
            Date: '2020-04-01T00:00:00Z',
            Cargo: 2864.0822857142857,
            BallastKPI: 89.635762231792924,
            LadenKPI: 103.16184086447059
        },
        {
            Date: '2020-05-01T00:00:00Z',
            Cargo: 2834.78625,
            BallastKPI: 90.528777465297154,
            LadenKPI: 104.39181951247448
        },
        {
            Date: '2020-06-01T00:00:00Z',
            Cargo: 2780.3257777777781,
            BallastKPI: 101.5417043723928,
            LadenKPI: 105.37015431935096
        },
        {
            Date: '2020-07-01T00:00:00Z',
            Cargo: 2762.7633,
            BallastKPI: 102.63278038488124,
            LadenKPI: 103.55211913429297
        },
        {
            Date: '2020-08-01T00:00:00Z',
            Cargo: 2762.1805454545456,
            BallastKPI: 96.408559185159589,
            LadenKPI: 105.11612466063045
        },
        {
            Date: '2020-09-01T00:00:00Z',
            Cargo: 2733.5081666666665,
            BallastKPI: 86.645882626740644,
            LadenKPI: 109.61812483466423
        },
        {
            Date: '2020-10-01T00:00:00Z',
            Cargo: 2729.4642307692307,
            BallastKPI: 103.64090200454926,
            LadenKPI: 103.58513869880169
        },
        {
            Date: '2020-11-01T00:00:00Z',
            Cargo: 2802.8230769230768,
            BallastKPI: 98.538381407378111,
            LadenKPI: 116.18859091891542
        },
        {
            Date: '2020-12-01T00:00:00Z',
            Cargo: 2841.4396923076924,
            BallastKPI: 97.904950571219672,
            LadenKPI: 114.57550602060843
        },
        {
            Date: '2021-01-01T00:00:00Z',
            Cargo: 2814.7994615384614,
            BallastKPI: 95.095244915496949,
            LadenKPI: 110.72735236409638
        },
        {
            Date: '2021-02-01T00:00:00Z',
            Cargo: 2760.4213076923083,
            BallastKPI: 99.718380336662534,
            LadenKPI: 114.5062249513467
        },
        {
            Date: '2021-03-01T00:00:00Z',
            Cargo: 2788.4386153846158,
            BallastKPI: 96.22662007810429,
            LadenKPI: 105.08443534703319
        },
        {
            Date: '2021-04-01T00:00:00Z',
            Cargo: 2810.0210769230766,
            BallastKPI: 97.597295684208419,
            LadenKPI: 104.94528636537133
        },
        {
            Date: '2021-05-01T00:00:00Z',
            Cargo: 2830.0362307692308,
            BallastKPI: 95.270318731593846,
            LadenKPI: 104.92990654205605
        },
        {
            Date: '2021-06-01T00:00:00Z',
            Cargo: 2848.7976923076922,
            BallastKPI: 100.36170430162463,
            LadenKPI: 102.88009120116068
        },
        {
            Date: '2021-07-01T00:00:00Z',
            Cargo: 2855.2531538461544,
            BallastKPI: 98.530015504553091,
            LadenKPI: 105.15007387602905
        },
        {
            Date: '2021-08-01T00:00:00Z',
            Cargo: 2840.6067692307693,
            BallastKPI: 91.3724477585621,
            LadenKPI: 102.03303352728763
        },
        {
            Date: '2021-09-01T00:00:00Z',
            Cargo: 2785.8959230769228,
            BallastKPI: 89.18644716692188,
            LadenKPI: 105.57996225970041
        }
    ],
    EnergyEfficiencyDataList: [
        {
            Date: '2012-01-01T00:00:00Z',
            Efficiency: 39.011002562106007884341258,
            AER: 6.3832928890902622887991485464,
            Target: 39930.011002562106007884341258,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: 35.266397026541218077255253206,
                    AER: 7.7147394016460116865612859879
                },
                {
                    Name: 'P-MAX',
                    Efficiency: 4.5805994758856421427044796635,
                    AER: 3.8557142260692921302897789063
                },
                {
                    Name: 'Suezmax',
                    Efficiency: 119632.79122434987232029721798,
                    AER: 6.3955941596219697336348280527
                }
            ]
        },
        {
            Date: '2013-01-01T00:00:00Z',
            Efficiency: 37.82015009604818726651301,
            AER: 13.890816858234181023615423838,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: 485.55757213710591096441837387,
                    AER: 14.463828799544629777926468866
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: 30.163758382807244359629620415,
                    AER: 10.095007724386132785450415592
                },
                {
                    Name: 'P-MAX',
                    Efficiency: 17.775553219034137614900799868,
                    AER: 7.5462943188246264749985710128
                },
                {
                    Name: 'Suezmax',
                    Efficiency: 54033.070464707906613086362221,
                    AER: 23.185923116771889071262111092
                }
            ]
        },
        {
            Date: '2014-01-01T00:00:00Z',
            Efficiency: 35.67779157401472516168749562,
            AER: 6.3150179677081741281365650218,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: 660.10772027703646385942492622,
                    AER: 7.3938934394691393740696535047
                },
                {
                    Name: 'P-MAX',
                    Efficiency: 19.251782209424901858037282811,
                    AER: 7.7261932338219377684275032184
                },
                {
                    Name: 'Suezmax',
                    Efficiency: 8.050948323430724630321853104,
                    AER: 3.5819541371318500429035537117
                }
            ]
        },
        {
            Date: '2015-01-01T00:00:00Z',
            Efficiency: 36.384031344612354789515955817,
            AER: 6.9463492255031791762534163307,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: 35.899195502452482519892122575,
                    AER: 19.057451060945965192335181066
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: 31.808679999624919009443093883,
                    AER: 7.926815376424191443931833825
                },
                {
                    Name: 'P-MAX',
                    Efficiency: 25.463933016586193996073583627,
                    AER: 8.036269139251615477617545229
                },
                {
                    Name: 'Suezmax',
                    Efficiency: 7.590364213131936328835431092,
                    AER: 3.8937402175768125236537859196
                }
            ]
        },
        {
            Date: '2016-01-01T00:00:00Z',
            Efficiency: 31.28250885048273960811388805,
            AER: 12.264205612502325591353015688,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: 33.443533601747401829013608365,
                    AER: 14.963029847454286780240068875
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: 18.844342928091968343390323861,
                    AER: 7.4215369412594243722627045701
                },
                {
                    Name: 'P-MAX',
                    Efficiency: 114.3805399639943905192474879,
                    AER: 35.50473146881735627506290261
                },
                {
                    Name: 'Suezmax',
                    Efficiency: 8.567868833042623559481301353,
                    AER: 5.184645999808395057411634933
                }
            ]
        },
        {
            Date: '2017-01-01T00:00:00Z',
            Efficiency: 28.685803055138908574683611405,
            AER: 9.216712488309704944756547608,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: 41.234251307651018651036323637,
                    AER: 13.32928588073644337282503531
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: 20.098187323069344209137768954,
                    AER: 11.639866258085453528055226258
                },
                {
                    Name: 'P-MAX',
                    Efficiency: 23.567098059854600824177924244,
                    AER: 7.1383940559887738046135769375
                },
                {
                    Name: 'Suezmax',
                    Efficiency: 7.2265660990680842846559642292,
                    AER: 3.938578064574465009412968043
                }
            ]
        },
        {
            Date: '2018-01-01T00:00:00Z',
            Efficiency: 24.734766870189707235635463104,
            AER: 7.4822608310818078778130283113,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: 90.02846894440086412186952495,
                    AER: 29.327136330517089811131360797
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: 16.439661295597564497952657506,
                    AER: 7.0311062935279449588127902601
                },
                {
                    Name: 'P-MAX',
                    Efficiency: 23.22718938521296479610834706,
                    AER: 8.20793363716206928433864712
                },
                {
                    Name: 'Suezmax',
                    Efficiency: 7.4678833455086457284277348322,
                    AER: 3.670884365913740755192432705
                }
            ]
        },
        {
            Date: '2019-01-01T00:00:00Z',
            Efficiency: 23.062895498702929966873559185,
            AER: 8.020473545329789714461057593,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: 46.42887362077932756474309615,
                    AER: 32.560289791416467953641383412
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: 19.829395684590306006591865859,
                    AER: 7.2184389053833627466919752677
                },
                {
                    Name: 'P-MAX',
                    Efficiency: 23.068636399249235573075433447,
                    AER: 8.694890680561270670353764369
                },
                {
                    Name: 'Suezmax',
                    Efficiency: 9.618465609520897608022942124,
                    AER: 3.5419802952344635088731592007
                }
            ]
        },
        {
            Date: '2020-01-01T00:00:00Z',
            Efficiency: 21.289964458765479054817899134,
            AER: 10.071688103311150206781756355,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: 15.066416358545452292479011544,
                    AER: 14.776513981972054878209082902
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: 20.964326785336824121280590721,
                    AER: 12.786772635324096681051279042
                },
                {
                    Name: 'P-MAX',
                    Efficiency: 76.267687952192107613472752806,
                    AER: 9.891600797771701589404727522
                },
                {
                    Name: 'Suezmax',
                    Efficiency: 14.415305406266237492308306066,
                    AER: 3.5545204011109940497409895527
                }
            ]
        },
        {
            Date: '2021-01-01T00:00:00Z',
            Efficiency: 20.059814136489505225562305381,
            AER: 8.415400989170242426278137981,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: 6.2131025865327075994034947873,
                    AER: 15.21974973185522791411730502
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: 16.530217837207266865411681034,
                    AER: 15.812798885258656508417671405
                },
                {
                    Name: 'P-MAX',
                    Efficiency: 42.00353349452669602464644462,
                    AER: 7.8268117124819656915144101616
                },
                {
                    Name: 'Suezmax',
                    Efficiency: 9.301609365948180485239062427,
                    AER: 3.5573939426141202745485141914
                }
            ]
        },
        {
            Date: '2022-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2023-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2024-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2025-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2026-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2027-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2028-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2029-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2030-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: 34758.006601537263604730604755,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2031-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2032-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2033-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2034-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2035-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2036-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2037-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2038-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2039-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2040-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2041-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2042-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2043-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2044-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2045-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2046-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2047-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2048-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2049-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: null,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        },
        {
            Date: '2050-01-01T00:00:00Z',
            Efficiency: null,
            AER: null,
            Target: 0.0,
            Segments: [
                {
                    Name: 'Intermediate',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'LNG',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Medium Range',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'P-MAX',
                    Efficiency: null,
                    AER: null
                },
                {
                    Name: 'Suezmax',
                    Efficiency: null,
                    AER: null
                }
            ]
        }
    ]
};
