import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import TimeHelper from 'utils/helpers/time-helper';
import { copyItineraryToClipboard } from 'components/itinerary/helpers';
/* selectors */
import { getSelectedPredefinedRange } from '../vessel-info-selectors';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
import Accordion from 'components/accordion/accordion';
/* style */
import './vessel-info-itinerary.scss';

export class VesselInfoItinerary extends React.PureComponent {
    state = {
        extendedIndexes: [],
        activitiesPerItinerary: null
    };

    static formatDate(date) {
        return date === null
            ? '-'
            : TimeHelper.getFormatted(date, { utc: true, time: true });
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
        const activitiesPerItinerary = {};
        if (props.selectedVoyageItinerary && props.selectedVoyageItinerary !== state.selectedVoyageItinerary) {
            newState.selectedVoyageItinerary = props.selectedVoyageItinerary;
            newState.extendedIndexes = VesselInfoItinerary.getInitialExtendedIndexes(props);
            props.selectedVoyageItinerary.forEach((itineraryPoint, index) => {
                if (itineraryPoint.Point) {
                    const activityCodeCounts = {};
                    if (!activitiesPerItinerary[index]) {
                        activitiesPerItinerary[index] = [];
                    }
                    itineraryPoint.Activities.forEach((activity, activityIndex) => {
                        if (activity.Code) {
                            if (!activityCodeCounts[activity.Code]) {
                                activityCodeCounts[activity.Code] = 1;
                            } else {
                                activityCodeCounts[activity.Code]++;
                            }
                        }
                        if (activitiesPerItinerary[index]) {
                            activitiesPerItinerary[index].push({
                                title: activity.Activity,
                                index: activityIndex,
                                active: moment.utc().isBetween(activity.Eta, activity.Etd),
                                listItems: [
                                    `Start: ${VesselInfoItinerary.formatDate(activity.Eta)}`,
                                    `End: ${VesselInfoItinerary.formatDate(activity.Etd)}`
                                ]
                            });
                        }
                    });
                    activitiesPerItinerary[index].activityCodeCounts = activityCodeCounts;
                }
            });
            newState.activitiesPerItinerary = activitiesPerItinerary;
            return newState;
        }
        return null;
    }

    static getInitialExtendedIndexes = (props) => {
        const extendedIndexes = [];
        const now = moment();
        props.selectedVoyageItinerary.forEach((point, index) => {
            if (extendedIndexes.length === 0 && now.isBefore(point.Etd)) {
                extendedIndexes.push(index);
            }
        });
        if (extendedIndexes.length === 0) {
            extendedIndexes.push(props.selectedVoyageItinerary.length - 1);
        }
        return extendedIndexes;
    };

    getItineraryDetails() {
        const rows = [];
        let badgeClass = 'sten-badge sten-vessel-info-itinerary__badge';
        if (this.state.selectedVoyageItinerary) {
            this.state.selectedVoyageItinerary.forEach((itineraryPoint, index) => {
                if (!itineraryPoint.Point) { return; }
                const activities = this.state.activitiesPerItinerary[index];
                const activityCodeCounts = activities.activityCodeCounts;
                const activityBadges = [];
                if (activities.length) {
                    activities.forEach((activity) => {
                        if (activity.isActive) {
                            badgeClass += ' sten-badge--success';
                        }
                    });
                }
                Object.keys(activityCodeCounts).forEach(code => {
                    if (activityCodeCounts[code] > 2) {
                        activityBadges.push(
                            <span key={`${code}`} className={badgeClass}>
                                {`${activityCodeCounts[code]}${code}`}
                            </span>
                        );
                    } else {
                        for (let i = 0; i < activityCodeCounts[code]; i++) {
                            activityBadges.push(
                                <span key={`${code}-${i}`} className={badgeClass}>{code}</span>
                            );
                        }
                    }
                });
                let iconClass = 'icon sten-vessel-info-itinerary__icon';
                if (itineraryPoint.Point.Type === 1) {
                    iconClass += ' icon-itinerary-point';
                } else {
                    iconClass += ' icon-anchor';
                }

                const accordionHeader = [
                    <span key="icon" className={iconClass} />,
                    <h4 key="title">{itineraryPoint.Point.Name}</h4>,
                    activityBadges
                ];

                let pointClass = 'sten-vessel-info-itinerary__point sten-panel';
                if (itineraryPoint.IsHistorical) {
                    pointClass += ' sten-vessel-info-itinerary__point--historical';
                }

                /* eslint-disable react/no-array-index-key */
                rows.push(
                    <Accordion
                        key={index}
                        className={pointClass}
                        header={accordionHeader}
                        chevronRight
                        isCollapsed={this.state.extendedIndexes.indexOf(index) < 0}
                        onClick={this.extendAccordion.bind(this, index)}
                    >
                        {activities.map((rowItem, rowItemIndex) => (
                            <div key={rowItemIndex} className="flex flex-center sten-vessel-info-itinerary__action">
                                <div className="col-12">
                                    <p className={rowItem.active ? 'text-active' : ''}>
                                        {rowItem.title}
                                    </p>
                                </div>
                                <div className="col-12">
                                    <ul className="sten-ul">
                                        {rowItem.listItems.map((item, activitiesIndex) => (
                                            <li key={activitiesIndex}>{item}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                    </Accordion>
                );
                /* eslint-enable react/no-array-index-key */
            });
        }
        return rows;
    }

    extendAccordion(index) {
        this.setState((state) => {
            const extendedIndexes = state.extendedIndexes.slice();
            const foundIndex = state.extendedIndexes.indexOf(index);
            if (foundIndex >= 0) {
                extendedIndexes.splice(foundIndex, 1);
            } else {
                extendedIndexes.push(index);
            }
            return { extendedIndexes };
        });
    }

    getButtonLabels = () => {
        return this.props.permissions.ShowDeviationAsRequest
            ? t('VESSEL_INFO.ITINERARY.REQUEST_DEVIATION')
            : t('VESSEL_INFO.ITINERARY.CALCULATE_DEVIATION');
    }

    copyToClipboard = (e) => {
        e.preventDefault();
        copyItineraryToClipboard({
            itinerary: this.props.selectedVoyageItinerary,
            selectedVoyage: this.props.selectedVoyage,
            vesselName: this.props.selectedVesselBase && this.props.selectedVesselBase.Title,
            voyagePortInfo: this.props.voyagePortInfo,
            selectedRange: this.props.selectedRange
        });
    };

    render() {
        const isCurrentOrFutureVoyage = this.props.selectedVoyage && (!this.props.selectedVoyage.EndDate
            || moment.utc().startOf('h').isSameOrBefore(moment(this.props.selectedVoyage.EndDate)));

        const currentAndSelectedVoyageItinerary = isCurrentOrFutureVoyage
            && this.state.selectedVoyageItinerary
            && this.state.selectedVoyageItinerary.length > 0;

        const shouldShowDistanceCalculatorButton = this.props.permissions
            && this.props.permissions.ShowDistanceCalc
            && ConfigService.featureToggles.showDistanceCalculator
            && currentAndSelectedVoyageItinerary;

        const shouldShowEmissionEstimatorButton = this.props.permissions
            && this.props.permissions.ShowEmissionEstimator
            && ConfigService.featureToggles.showEmissionEstimator
            && currentAndSelectedVoyageItinerary;

        return (
            <div className="sten-vessel-info-itinerary">
                <ScrollArea className="sten-vessel-info-itinerary__body sten-vessel-info-itinerary__body--with-footer">
                    <div className="sten-content__section">
                        {this.getItineraryDetails()}
                    </div>
                </ScrollArea>
                <div className="sten-vessel-info-itinerary__footer sten-content__footer flex-row">
                    <div className="flex-grow flex">
                        <button
                            className="btn btn--secondary text-uppercase flex-grow"
                            onClick={this.copyToClipboard}
                        >
                            {t('GLOBAL.COPY_TO_CLIPBOARD')}
                        </button>
                    </div>
                    {shouldShowDistanceCalculatorButton && (
                        <div className="flex-grow flex">
                            <Link
                                className="btn btn--link btn--secondary text-uppercase flex-grow"
                                to={this.props.router.getLinkTo({
                                    pathname: appRoutes.Routing.DistanceCalculator,
                                    query: {
                                        vessel: this.props.router.query.vessel,
                                        deviation: true
                                    }
                                })}
                            >
                                {this.getButtonLabels()}
                            </Link>
                        </div>
                    )}
                    {shouldShowEmissionEstimatorButton && (
                        <div className="flex-grow flex">
                            <Link
                                className="btn btn--link btn--secondary text-uppercase flex-grow"
                                to={this.props.router.getLinkTo({
                                    pathname: appRoutes.EmissionEstimator,
                                    query: {
                                        vessel: this.props.router.query.vessel,
                                        deviation: true
                                    }
                                })}
                            >
                                {this.getButtonLabels()}
                            </Link>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

VesselInfoItinerary.propTypes = {
    router: TRouter.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedRange: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedVesselBase: PropTypes.objectOf(PropTypes.any),
    selectedVoyage: PropTypes.objectOf(PropTypes.any),
    selectedVoyageItinerary: PropTypes.arrayOf(PropTypes.any),
    voyagePortInfo: PropTypes.string
};

VesselInfoItinerary.defaultProps = {
    selectedVesselBase: null,
    selectedVoyage: null,
    selectedVoyageItinerary: null,
    voyagePortInfo: ''
};

function mapStateToProps(state) {
    return {
        permissions: state.userReducer.permissions,
        selectedRange: getSelectedPredefinedRange(state),
        selectedVesselBase: state.homeReducer.selectedVesselBase,
        selectedVoyage: state.homeReducer.selectedVoyage,
        selectedVoyageItinerary: state.homeReducer.selectedVoyageItinerary
    };
}

export default connect(mapStateToProps)(withRouter(VesselInfoItinerary));
