import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { getClassName } from 'utils/helpers/info-helper';
import { t } from 'utils/i18n/i18n-model';
/* actions */
import { getData, resetData, updateData } from './charterparty-form-actions';
/* components */
import ContentArray from 'components/content-array/content-array';
import EmptyContent from 'components/empty-content/empty-content';
import ScrollArea from 'components/scroll-area/scroll-area';
import Validation from 'components/validation/validation';
/* constants */
import { emptyCharterparty, sections, fieldMap, dataMap } from './charterparty-form-constants';
/* styles */
import './charterparty-form.scss';

class CharterpartyForm extends React.PureComponent {
    state = {
        editingData: null,
        prevCharterpartyData: null
    };

    static getDerivedStateFromProps(props, state) {
        if (props.isEditing
            && (!state.prevCharterpartyData || state.prevCharterpartyData !== props.charterpartyData)) {
            return {
                editingData: props.charterpartyData.map(cp => ({
                    ...cp,
                    FuelRestrictions: props.options.fuelTypes.map(ft => {
                        const ftData = cp.FuelRestrictions.find(fr => fr.FuelType.Id === ft.Id);
                        return ftData || {
                            Id: null,
                            FuelType: ft,
                            Allowance: null,
                            Consumption: null
                        };
                    })
                })),
                prevCharterpartyData: props.charterpartyData
            };
        }
        return null;
    }

    componentDidMount() {
        this.props.getData(this.props.voyageId);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.voyageId !== this.props.voyageId) {
            this.props.getData(this.props.voyageId);
        }
    }

    componentWillUnmount() {
        this.props.resetData();
    }

    handleClose = () => this.props.router.updateQueryParams({ remove: ['cpVoyage', 'cpEdit'] });

    handleCancel = () => this.props.router.updateQueryParams({
        remove: this.props.closeOnCancel ? ['cpVoyage', 'cpEdit'] : ['cpEdit']
    });

    handleEdit = () => this.props.router.updateQueryParams({ add: { cpEdit: true } });

    handleSubmit = () => {
        this.props.updateData(this.props.voyageId, this.state.editingData, this.props.closeOnSubmit);
    };

    handleValueChange = (editingData) => {
        this.setState({ editingData });
    }

    getEmptyCharterparty = (options) => ({
        ...emptyCharterparty,
        AllowanceType: options.allowanceTypes[1] || null,
        FuelRestrictions: options.fuelTypes.map(ft => ({
            Id: null,
            FuelType: ft,
            Allowance: null,
            Consumption: null
        }))
    });

    render() {
        const { areOptionsFetched, charterpartyData, isEditing, options, permissions, voyage } = this.props;
        const { editingData } = this.state;
        if (!voyage || !areOptionsFetched) {
            return (
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <div className="flex-grow">
                            <h1 className="sten-content__title">{t('CHARTERPARTY_FORM.TITLE')}</h1>
                        </div>
                        <div className="flex-shrink">
                            <button className="btn-link icon icon-close" onClick={this.handleClose} />
                        </div>
                    </div>
                    <div className="sten-content__body" />
                </div>
            );
        }
        const data = isEditing ? editingData : charterpartyData;
        const formParams = {
            editingOptions: options
        };

        return (
            <Validation.Form
                onSubmit={this.handleSubmit}
                className={getClassName('sten-content', { 'sten-content--has-footer': isEditing })}
            >
                <div className="sten-content__header flex-row">
                    <div className="flex-grow">
                        <h1 className="sten-content__title">{t('CHARTERPARTY_FORM.TITLE')}</h1>
                        {voyage && (
                            <p className="sten-content__subtitle text-ellipsis text-nowrap">
                                {`${voyage.VesselTitle}, ${voyage.VoyageNumber}`}
                            </p>
                        )}
                    </div>
                    {!isEditing && permissions.EditCharterparty && (
                        <div className="flex-shrink">
                            <button type="button" className="btn-link icon icon-edit" onClick={this.handleEdit} />
                        </div>
                    )}
                    <div className="flex-shrink">
                        <button type="button" className="btn-link icon icon-close" onClick={this.handleClose} />
                    </div>
                </div>
                {isEditing || data.length ? (
                    <ScrollArea className="sten-content__body" shouldScrollToActiveElement>
                        <ContentArray
                            data={data}
                            onChange={this.handleValueChange}
                            newItem={this.getEmptyCharterparty(options)}
                            editingOptions={options}
                            formParams={formParams}
                            dataMap={dataMap}
                            fieldMap={fieldMap}
                            isEditing={isEditing}
                            itemTitle={t('CHARTERPARTY_FORM.CHARTERPARTY_INFORMATION')}
                            maxLength={5}
                            sections={sections}
                            hasFieldsWithHandlerCreators
                        />
                    </ScrollArea>
                ) : (
                    <EmptyContent>{t('CHARTERPARTY_FORM.NO_INFORMATION')}</EmptyContent>
                )}
                {isEditing && (
                    <footer className="sten-content__footer flex-row">
                        <div className="col-12">
                            <button
                                className="btn btn--secondary col-24"
                                type="button"
                                onClick={this.handleCancel}
                            >
                                {t('GLOBAL.CANCEL')}
                            </button>
                        </div>
                        <div className="col-12">
                            <Validation.Button className="btn btn--primary col-24">
                                {t('GLOBAL.SAVE')}
                            </Validation.Button>
                        </div>
                    </footer>
                )}
            </Validation.Form>
        );
    }
}

CharterpartyForm.propTypes = {
    areOptionsFetched: PropTypes.bool.isRequired,
    charterpartyData: PropTypes.arrayOf(PropTypes.object).isRequired,
    closeOnCancel: PropTypes.bool,
    closeOnSubmit: PropTypes.bool,
    getData: PropTypes.func.isRequired,
    isEditing: PropTypes.bool,
    options: PropTypes.shape({
        fuelTypes: PropTypes.arrayOf(PropTypes.object),
        currentTypes: PropTypes.arrayOf(PropTypes.object),
        allowanceTypes: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    resetData: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    updateData: PropTypes.func.isRequired,
    voyage: PropTypes.objectOf(PropTypes.any),
    voyageId: PropTypes.number
};

CharterpartyForm.defaultProps = {
    closeOnCancel: false,
    closeOnSubmit: false,
    isEditing: false,
    voyage: null,
    voyageId: null
};

function mapStateToProps(state) {
    return {
        areOptionsFetched: state.charterpartyFormReducer.areOptionsFetched,
        charterpartyData: state.charterpartyFormReducer.charterpartyData,
        options: state.charterpartyFormReducer.options,
        permissions: state.userReducer.permissions,
        voyage: state.charterpartyFormReducer.voyage
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getData: voyageId => getData(dispatch, voyageId),
        resetData: () => resetData(dispatch),
        updateData: (voyageId, data, closeOnSubmit) => updateData(dispatch, voyageId, data, closeOnSubmit)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CharterpartyForm));
