import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { findTotalTopOffset } from 'utils/helpers/html-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* components */
import DateRangePopup from 'components/date-range-popup/date-range-popup';
import IncludedVesselsSection from '../included-vessels-section';
import QuarterPicker from '../../shared/quarter-picker/quarter-picker';
import ScrollArea from 'components/scroll-area/scroll-area';
import Validation from 'components/validation/validation';
/* actions */
import { updateFilters } from '../../reports-actions';

const labelHeight = 25;
const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.SIDEBAR.${key}`);

class QuarterlyReportInclusions extends React.PureComponent {
    componentDidMount() {
        let currentQuarter = this.props.selectedRange
            ? TimeHelper.getQuarterFromRange(this.props.selectedRange.rangeStart, this.props.selectedRange.rangeEnd)
            : null;
        if (!currentQuarter) {
            currentQuarter = TimeHelper.getCurrentQuarter();
            const initialRange = TimeHelper.getRangeFromQuarterAndYear(
                currentQuarter.quarter.Name,
                currentQuarter.year
            );
            this.props.setSelectedRange(initialRange);
        }
    }

    handleFormSubmit = () => {
        const filters = {
            selectedVessels: this.includedVesselsSectionRef.state.selectedVessels
        };
        this.props.updateFilters(filters);

        if (this.props.onSubmit) {
            this.props.onSubmit(filters);
        }
    }

    scrollToInvalidField = () => {
        if (this.scrollAreaRef && this.scrollAreaRef.contentWrapperRef) {
            const scrollAreaDiv = this.scrollAreaRef.contentWrapperRef;
            const elements = scrollAreaDiv.getElementsByClassName(
                'sten-validation-wrapper--invalid'
            );

            if (elements && elements[0]) {
                const totalOffsetTop = findTotalTopOffset(elements[0], scrollAreaDiv);
                this.scrollAreaRef.scrollYTo(totalOffsetTop - labelHeight);
            }
        }
    };

    renderFooter = () => (
        <footer className="sten-content__footer flex-row">
            <div className="col-24">
                <Validation.Button className="btn btn--primary col-24">
                    {t('CALCULATE')}
                </Validation.Button>
            </div>
        </footer>
    );

    saveRef = r => { this.scrollAreaRef = r; };

    render() {
        return (
            <Validation.Form
                className="sten-energy-management-reports-sidebar-inclusions"
                onSubmit={this.handleFormSubmit}
                onInvalid={this.scrollToInvalidField}
            >
                <ScrollArea
                    className="sten-content__body"
                    ref={this.saveRef}
                    shouldScrollToActiveElement
                >
                    <div className="sten-content__section">
                        <div className="form-row">
                            {window.location.search.indexOf('customdates') >= 0 ? (
                                <Validation.Wrapper validations={{ required: true }}>
                                    <DateRangePopup
                                        isUTC
                                        alignment="end"
                                        shouldRenderDefaultPresets
                                        name="dataRange"
                                        onRangeSelect={this.props.setSelectedRange}
                                        value={this.props.selectedRange}
                                    />
                                </Validation.Wrapper>
                            ) : (
                                <Validation.Wrapper validations={{ required: true }}>
                                    <QuarterPicker
                                        name="quarter"
                                        value={this.props.selectedRange}
                                        onChange={this.props.setSelectedRange}
                                    />
                                </Validation.Wrapper>
                            )}
                        </div>
                    </div>
                    <IncludedVesselsSection
                        showAdditionalVesselFilters
                        ref={(r) => { this.includedVesselsSectionRef = r; }}
                        segments={this.props.segments}
                        fleets={this.props.fleets}
                        customFleets={this.props.customFleets}
                        vessels={this.props.vessels}
                        vesselsByCustomFleet={this.props.vesselsByCustomFleet}
                        vesselsByFleet={this.props.vesselsByFleet}
                        vesselsBySegment={this.props.vesselsBySegment}
                        initialSelectedVessels={this.props.selectedVessels}
                    />
                </ScrollArea>
                {this.renderFooter()}
            </Validation.Form>
        );
    }
}

QuarterlyReportInclusions.propTypes = {
    customFleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    fleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    onSubmit: PropTypes.func.isRequired,
    segments: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedRange: PropTypes.objectOf(PropTypes.any),
    selectedVessels: PropTypes.objectOf(PropTypes.any).isRequired,
    setSelectedRange: PropTypes.func.isRequired,
    updateFilters: PropTypes.func.isRequired,
    vessels: PropTypes.arrayOf(PropTypes.any).isRequired,
    vesselsByCustomFleet: PropTypes.objectOf(PropTypes.any).isRequired,
    vesselsByFleet: PropTypes.objectOf(PropTypes.any).isRequired,
    vesselsBySegment: PropTypes.objectOf(PropTypes.any).isRequired
};

QuarterlyReportInclusions.defaultProps = {
    selectedRange: null
};

function mapStateToProps(state) {
    return {
        customFleets: state.energyManagementReportsReducer.customFleets,
        fleets: state.energyManagementReportsReducer.fleets,
        segments: state.energyManagementReportsReducer.segments,
        selectedVessels: state.energyManagementReportsReducer.filters.selectedVessels,
        vessels: state.energyManagementReportsReducer.vessels
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateFilters: filters => updateFilters(dispatch, filters)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuarterlyReportInclusions);
