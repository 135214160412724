import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
/* components */
import Accordion from 'components/accordion/accordion';
/* constants */
import { editRowConfigs } from './itinerary-constants';
/* helpers */
import { getClassName } from 'utils/helpers/info-helper';
import { isActivityCurrent, isActivityHistoric } from 'components/itinerary/helpers';
/* style */
import './itinerary.scss';

export class ItineraryEdit extends React.PureComponent {
    columnClasses = ['col-12', 'col-12'];

    handleChange = (prop, pointIndex, activityIndex) => (value) => {
        this.props.onChange(this.props.itinerary.map((point, pi) => {
            if (pi === pointIndex) {
                return {
                    ...point,
                    Activities: point.Activities.map((activity, ai) => (
                        ai === activityIndex ? { ...activity, [prop]: value } : activity
                    ))
                };
            }
            return point;
        }));
    };

    getSectionClassName = (activityIndex, sectionIndex) => {
        let className = 'sten-panel__section';
        if (activityIndex > 0 && sectionIndex === 0) {
            className += ' sten-panel__separator';
        }
        if (sectionIndex > 0) {
            className += ' sten-panel__separator sten-panel__separator--inner';
        }
        return className;
    };

    render() {
        const options = {
            EtbStatuses: this.props.etbStatuses,
            LegTags: this.props.legTags
        };
        return this.props.itinerary.map((itineraryPoint, pointIndex) => {
            const { Point, Activities } = itineraryPoint;

            // Note: Check if this is correct way of getting is point Active or Historic
            const isPointHistoric = Activities.every(isActivityHistoric);

            const pointClass = getClassName('sten-itinerary__point sten-panel', {
                'sten-itinerary__point--historical': isPointHistoric
            });
            const iconClass = getClassName('icon sten-itinerary__icon', {
                'icon-itinerary-point': Point && Point.Type === 1,
                'icon-port': Point && Point.Type !== 1
            });
            const header = (
                <Fragment>
                    <span key="icon" className={iconClass} />
                    <h4 className="sten-itinerary__point-title">{Point ? Point.Name : '-'}</h4>
                    {Activities.filter(activity => activity.Activity).map(activity => (
                        <div key={activity.Id} className="sten-itinerary__badge">
                            <span
                                className={getClassName('sten-badge', {
                                    'sten-badge--success': isActivityCurrent(activity)
                                })}
                                title={activity.Activity}
                            >
                                {activity.Code}
                            </span>
                        </div>
                    ))}
                </Fragment>
            );

            return (
                <Accordion
                    // eslint-disable-next-line react/no-array-index-key
                    key={pointIndex}
                    chevronRight
                    className={pointClass}
                    header={header}
                >
                    {Activities.map((activity, activityIndex) => editRowConfigs.map((section, sectionIndex) => (
                        <div
                            key={`${activity.Id}.${section.id}`}
                            className={this.getSectionClassName(activityIndex, sectionIndex)}
                        >
                            {section.rows.map(row => (
                                <div key={row.id} className="form-row">
                                    {row.render(activity, this.handleChange, options, pointIndex, activityIndex)}
                                </div>
                            ))}
                        </div>
                    )))}
                </Accordion>
            );
        });
    }
}

ItineraryEdit.propTypes = {
    etbStatuses: PropTypes.arrayOf(PropTypes.object).isRequired,
    itinerary: PropTypes.arrayOf(PropTypes.any),
    legTags: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired
};

ItineraryEdit.defaultProps = {
    itinerary: null
};

export default ItineraryEdit;
