import CoreApi from './core-api';

class TimeService {
    getTimeZoneOffsetId(locationInfo) {
        return CoreApi.get('TimeZones', locationInfo)
            .then(response => response.data, () => null);
    }

    getDateTimeFormats() {
        return CoreApi.get('DateTimeFormats')
            .then(response => response.data, () => null);
    }
}

export default new TimeService();
