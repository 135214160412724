import { put, select, takeEvery, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
/* router */
import { navigate, appRoutes, updateQueryParams } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import {
    repackWaypoints,
    extractConfigurationRelations,
    extractLegDateRange,
    filterObjectsByIds,
    extractPortInfo,
    extractConditionalAreasFromArray,
    extractConditionalAreasFromObject,
    internalConditionalAreas
} from './weather-routing-helpers';
import { sortByProp } from 'utils/helpers/array-helper';
import { getCustomFleets } from 'utils/helpers/fleet-helper';
import { formatWktPosition, setObjectProp } from 'utils/helpers/info-helper';
import { wktParse } from 'components/ol/ol-helpers';
import TimeHelper from 'utils/helpers/time-helper';
import TimeService from 'services/core-api/time-service';
/* actions */
import { ActionTypes } from './weather-routing-actions';
import { ActionTypes as UserActionTypes } from '../user-actions';
/* services */
import ConfigService from 'services/config-api/config-service';
import FleetManagementService from 'services/core-api/fleet-management-service';
import LocalStorageService from 'services/local-storage-service';
import WeatherRoutingService from 'services/core-api/weather-routing-service';
import PortService from 'services/core-api/port-service';
import VesselService from 'services/core-api/vessel-service';
/* mocks */
import {
    getMockedVoyages,
    getMockedBudgetedConsumption,
    mockedEditingOptions,
    getMockedLeg,
    getMockedLegRequests,
    getMockedLegSposRoutes,
    vesselConditions,
    setMockedIntendedRequest,
    deleteMockedRequest,
    deleteMockedLeg,
    stopMockedRequest,
    getMockedRequest,
    getMockedVoyagesForImo,
    getMockedTemplateOptions,
    refreshMockedRequest
} from './weather-routing-test-utils';

let isFetchingData = false;
let prevFilters = null;
const limit = 20;
const emptyArray = [];

function* getVoyages(action) {
    const { filters, voyagesParams, voyages } = yield select(state => state.weatherRoutingReducer);

    if (!isFetchingData
        && ((action.shouldFetchMore && (voyagesParams.Offset + limit) / limit < voyagesParams.TotalPagesCount)
            || action.forceFetch
            || filters !== prevFilters)) {
        prevFilters = filters;
        const params = {
            AvailableForRouting: filters.AvailableForRouting,
            CompanyFleets: filters.CompanyFleets.map(f => f.Id),
            CustomFleets: filters.CustomFleets.filter(f => !f.IsDynamic).map(f => f.VesselGroupId),
            DynamicFleets: filters.CustomFleets.filter(f => f.IsDynamic).map(f => f.VesselGroupId),
            Imo: filters.Vessel ? filters.Vessel.Imo : null,
            Offset: action.shouldFetchMore ? voyagesParams.Offset + limit : 0,
            Limit: limit
        };
        isFetchingData = true;
        let res;
        if (LocalStorageService.isDebugModeActive()) {
            res = yield getMockedVoyages(params);
        } else {
            res = yield WeatherRoutingService.getVoyages(params);
        }

        if (action.shouldFetchMore) {
            res = { ...res, PageItems: voyages.concat(res.PageItems) };
        }

        yield put({ type: ActionTypes.WEATHER_ROUTING_SET_VOYAGES, ...res });
        isFetchingData = false;
    }
}

function* setFiltersAndUpdate(action) {
    const { filters } = action;
    yield put({
        type: UserActionTypes.USER_SETTINGS_UPDATE,
        settings: {
            WeatherRoutingVoyagesAvailableEnabled: filters.AvailableForRouting,
            WeatherRoutingCompanyFleetIds: filters.CompanyFleets.map(company => company.Id),
            WeatherRoutingFleetIds: filters.CustomFleets.map(f => f.VesselGroupId),
            WeatherRoutingSearchVessel: {
                Imo: filters.Vessel?.Vessel?.Imo || null
            }
        }
    });
    yield put({ type: ActionTypes.WEATHER_ROUTING_SET_FILTERS, filters: action.filters });
    yield getVoyages({ shouldFetchMore: false });
}

function* getFleets() {
    const permissions = yield select(state => state.userReducer.permissions);
    const fleetRequests = [];
    if (permissions.ShowFleetManagementPrivateGroups) {
        fleetRequests.push(FleetManagementService.getPrivate());
    }
    if (permissions.ShowFleetManagementPublicGroups) {
        fleetRequests.push(FleetManagementService.getPublic());
    }
    const vesselFleetsResponses = yield all(fleetRequests);

    const customFleets = getCustomFleets(vesselFleetsResponses);
    const companyFleets = yield VesselService.getCompanies();

    yield put({ type: ActionTypes.WEATHER_ROUTING_SET_FLEETS, companyFleets, customFleets });
}

function* getInitialData() {
    const {
        WeatherRoutingCompanyFleetIds,
        WeatherRoutingFleetIds,
        WeatherRoutingSearchVessel,
        WeatherRoutingVoyagesAvailableEnabled
    } = yield select(state => state.userReducer.settings);
    yield getFleets();
    let vessel = null;
    if (WeatherRoutingSearchVessel && WeatherRoutingSearchVessel.Imo) {
        vessel = yield VesselService.getBaseById(WeatherRoutingSearchVessel.Imo);
        vessel.Imo = vessel.IMO;
    }
    const {
        companyFleets,
        customFleets
    } = yield select(state => state.weatherRoutingReducer);
    const filters = {
        CompanyFleets: filterObjectsByIds(
            companyFleets,
            WeatherRoutingCompanyFleetIds,
            'Id'
        ),
        CustomFleets: filterObjectsByIds(
            customFleets,
            WeatherRoutingFleetIds,
            'VesselGroupId'
        ),
        Vessel: vessel,
        AvailableForRouting: WeatherRoutingVoyagesAvailableEnabled
    };
    yield put({
        type: ActionTypes.WEATHER_ROUTING_SET_FILTERS,
        filters
    });
    yield getVoyages({ shouldFetchMore: false });
}

function* getConditionalAreas() {
    const res = yield WeatherRoutingService.getConditionalAreas();
    return [
        internalConditionalAreas.ForbidHighRiskAreas,
        internalConditionalAreas.ForbidEcaZones,
        ...res
    ];
}

function* getEditingOptions() {
    const { areEditingOptionsFetched } = yield select(state => state.weatherRoutingReducer);

    if (!areEditingOptionsFetched) {
        let editingOptions;
        if (LocalStorageService.isDebugModeActive()) {
            editingOptions = mockedEditingOptions;
        } else {
            const { options, conditionalAreas } = yield all({
                options: WeatherRoutingService.getConfiguration(),
                conditionalAreas: getConditionalAreas()
            });
            if (options) {
                editingOptions = {
                    vesselConditions,
                    conditionalAreas,
                    configurationRelations: extractConfigurationRelations(options.OptimizationCalculationRelations),
                    optimizationTypes: options.OptimizationTypes,
                    routeCalculationTypes: options.RouteCalculationTypes,
                    requestTypes: options.RequestTypes,
                    requestSources: options.RequestSources
                };
            }
        }
        if (editingOptions) {
            yield put({
                type: ActionTypes.WEATHER_ROUTING_SET_EDITING_OPTIONS,
                editingOptions,
                areEditingOptionsFetched: true
            });
        }
    }
}

function* getPorts(action) {
    const ports = yield PortService.get(action.params);
    if (ports) {
        yield put({ type: ActionTypes.WEATHER_ROUTING_SET_PORTS, ports });
    }
}

function* getLegAisPoints() {
    const { selectedLeg, selectedLegRequests, isAisShown } = yield select(state => state.weatherRoutingReducer);
    if (isAisShown && selectedLeg) {
        let selectedLegAisPoints = null;
        const aisResponse = yield VesselService.getHistoricalById(
            selectedLeg.Vessel.Imo,
            extractLegDateRange(selectedLeg, selectedLegRequests),
            true
        );
        if (aisResponse && aisResponse.Entries && aisResponse.Entries.length && aisResponse.Entries[0]) {
            selectedLegAisPoints = aisResponse.Entries[0].Entries;
        }
        yield put({ type: ActionTypes.WEATHER_ROUTING_SET_LEG_AIS_POINTS, selectedLegAisPoints });
    } else {
        yield put({ type: ActionTypes.WEATHER_ROUTING_SET_LEG_AIS_POINTS });
    }
}

function* getLegData(action) {
    if (action.legId) {
        let res;
        if (LocalStorageService.isDebugModeActive()) {
            res = yield all({
                leg: getMockedLeg(action.legId),
                legRequests: getMockedLegRequests(action.legId),
                legSposRoutes: getMockedLegSposRoutes(action.legId)
            });
        } else {
            res = yield all({
                leg: WeatherRoutingService.getLegById(action.legId, action.hideLoader),
                legRequests: WeatherRoutingService.getRequestsByLegId(action.legId, action.hideLoader),
                legSposRoutes: WeatherRoutingService.getSposRoutesByLegId(action.legId, action.hideLoader)
            });
        }
        if (res.leg) {
            yield put({ type: ActionTypes.WEATHER_ROUTING_SET_LEG_DATA, ...res });
        } else {
            toast(t('WEATHER_ROUTING.TOASTS.LEG_DOESNT_EXIST'), { type: toast.TYPE.WARNING });
            yield put({ type: ActionTypes.WEATHER_ROUTING_SET_LEG_DATA });
            yield navigate(appRoutes.Routing.WeatherRouting);
        }
    } else {
        yield put({ type: ActionTypes.WEATHER_ROUTING_SET_LEG_DATA });
    }
}

function* setIntendedRequest(action) {
    if (action.requestId) {
        let res;
        if (LocalStorageService.isDebugModeActive()) {
            res = yield setMockedIntendedRequest(action.requestId);
        } else {
            res = yield WeatherRoutingService.setRequestIntended(action.requestId);
        }
        if (res) {
            toast(t('WEATHER_ROUTING.TOASTS.REQUEST_INTENDED'), { type: toast.TYPE.SUCCESS });
            yield all([
                getLegData(action),
                getVoyages({ forceFetch: true })
            ]);
        }
    }
}

function* deleteRequest(action) {
    const { requestId } = action;
    if (requestId) {
        let res;
        if (LocalStorageService.isDebugModeActive()) {
            res = yield deleteMockedRequest(requestId);
        } else {
            res = yield WeatherRoutingService.deleteRequest(requestId);
        }
        if (res) {
            const { selectedLegRequests } = yield select(state => state.weatherRoutingReducer);
            toast(t('WEATHER_ROUTING.TOASTS.REQUEST_DELETED'), { type: toast.TYPE.SUCCESS });
            let shouldGetLeg = true;
            if (selectedLegRequests.length === 1) {
                const { Id: lastRequstId, LegId: lastLegId } = selectedLegRequests[0];
                shouldGetLeg = !(lastRequstId === requestId && lastLegId === action.legId);
            }
            if (shouldGetLeg) {
                yield getLegData(action);
            } else {
                yield navigate(appRoutes.Routing.WeatherRouting);
            }
            yield getVoyages({ forceFetch: true });
        }
    }
}

function* stopRequest(action) {
    if (action.requestId) {
        if (LocalStorageService.isDebugModeActive()) {
            yield stopMockedRequest(action.requestId);
        } else {
            yield WeatherRoutingService.stopRequest(action.requestId);
        }

        toast(t('WEATHER_ROUTING.TOASTS.REQUEST_STOPPED'), { type: toast.TYPE.SUCCESS });
        yield all([
            getLegData(action),
            getVoyages({ forceFetch: true })
        ]);
    }
}

function* refreshRequest(action) {
    if (action.requestId) {
        if (LocalStorageService.isDebugModeActive()) {
            yield refreshMockedRequest(action.requestId);
        } else {
            yield WeatherRoutingService.refreshRequest(action.requestId);
        }

        yield all([
            getLegData(action),
            getVoyages({ forceFetch: true })
        ]);
    }
}

function* deleteLeg(action) {
    if (action.legId) {
        let res;
        if (LocalStorageService.isDebugModeActive()) {
            res = yield deleteMockedLeg(action.legId);
        } else {
            res = yield WeatherRoutingService.deleteLeg(action.legId);
        }
        if (res) {
            toast(t('WEATHER_ROUTING.TOASTS.LEG_DELETED'), { type: toast.TYPE.SUCCESS });
            yield getVoyages({ forceFetch: true });
        }
    }
}

function getVesselEmails(editingRequest, vessel) {
    const emails = {};
    if (editingRequest) {
        if (!editingRequest.RoutingEmailsVessel?.length) {
            emails.RoutingEmailsVessel = vessel?.VesselEmails || [];
        }
        if (!editingRequest.RoutingEmailsOperations?.length) {
            emails.RoutingEmailsOperations = vessel?.OperationsEmails || [];
        }
        if (!editingRequest.RoutingEmailsOther?.length) {
            emails.RoutingEmailsOther = [];
        }
        if (!editingRequest.PvarEmails?.length) {
            emails.PvarEmails = vessel?.OperationsEmails || [];
        }
        if (!editingRequest.PerformanceEmails?.length) {
            emails.PerformanceEmails = vessel?.OperationsEmails || [];
        }
    }
    return emails;
}

function* getVesselBaseById(imo, editingRequest) {
    const vessel = yield VesselService.getBaseById(imo);
    return {
        Vessel: {
            ...vessel,
            VesselName: vessel.Title,
            Imo: vessel.IMO
        },
        Emails: getVesselEmails(editingRequest, vessel)
    };
}

function* getVoyagesById(vesselId, voyageId) {
    const getVoyagesByIdFn = LocalStorageService.isDebugModeActive()
        ? getMockedVoyagesForImo : VesselService.getVoyagesPreviewById.bind(VesselService);
    const voyages = yield getVoyagesByIdFn(vesselId);
    return voyages.find(voyage => voyage.VoyageId === voyageId);
}

function* getConsumptionBudget(vesselId) {
    const getConsumption = LocalStorageService.isDebugModeActive()
        ? getMockedBudgetedConsumption
        : VesselService.getBudgetedConsumption.bind(VesselService);
    const res = yield getConsumption(vesselId);
    if (res) {
        return res.sort(sortByProp('Speed'));
    }
    return null;
}

function* getOffset(port, offset) {
    if (offset) {
        return offset;
    }
    if (port) {
        if (!port.TimeZoneOffset) {
            const time = yield TimeService.getTimeZoneOffsetId({
                Location: port.Location || port.Position
            });
            return TimeHelper.getTimeSpanFromMinutes(time.TimeOffset, true);
        }
        return TimeHelper.getTimeSpanFromMinutes(port.TimeZoneOffset, true);
    }
    return null;
}

function* getVoyageTemplateOptions(editingRequest, currentSelectedVoyageId, currentTemplateOptions) {
    let templateOptions = currentTemplateOptions;
    let selectedVoyageId = currentSelectedVoyageId;
    if (editingRequest.Vessel && editingRequest.Voyage) {
        const { Voyage, VesselCondition } = editingRequest;
        const voyageId = Voyage.VoyageId || Voyage.Id;
        const vesselConditionId = VesselCondition ? VesselCondition.Id : null;
        if (selectedVoyageId !== voyageId) {
            if (LocalStorageService.isDebugModeActive()) {
                templateOptions = yield getMockedTemplateOptions(voyageId, vesselConditionId, editingRequest);
            } else {
                templateOptions = yield WeatherRoutingService.getTemplate({
                    VoyageId: voyageId,
                    CargoStatus: vesselConditionId
                }) || [];
            }
            selectedVoyageId = voyageId;
        }
    }
    return {
        templateOptions,
        selectedVoyageId
    };
}

function getTemplateData(editingRequest, templateOptions) {
    const { Template } = editingRequest;
    let { TemplateData } = editingRequest;
    if (templateOptions && templateOptions.length
        && Template && Template.TemplateType
        && !TemplateData) {
        if (Template.TemplateType === 1) {
            TemplateData = templateOptions.find(option => option.Template
                && option.Template.TemplateNextVoyageReportId === Template.TemplateNextVoyageReportId);
        } else {
            TemplateData = templateOptions.find(option => option.Template
                && option.Template.TemplateType === 2
                && option.VesselCondition.Id === editingRequest.VesselCondition.Id);
        }
    }
    return TemplateData;
}

function shouldResetRouteCalculationType(editingRequest, configurationRelations) {
    const { Source, OptimizationType, RouteCalculationType } = editingRequest;
    const { optimizationTypeVsCalculationType, sourceVsCalculationType } = configurationRelations;

    return !!(RouteCalculationType
        && ((Source && !sourceVsCalculationType?.[Source.Id]?.[RouteCalculationType.Id])
        || (OptimizationType && !optimizationTypeVsCalculationType?.[OptimizationType.Id]?.[RouteCalculationType.Id])));
}

function* getData(action) {
    if (action.queryParams) {
        yield getEditingOptions();
        const { editingOptions, selectedLegRequests } = yield select(state => state.weatherRoutingReducer);
        let { editingRequest, selectedVoyageId } = yield select(state => state.weatherRoutingReducer);
        const vesselImo = action.queryParams.vessel;
        let templateOptions = [];
        let consumptionBudget = null;
        const requestId = action.queryParams.requestId || selectedLegRequests?.[0]?.Id;
        const isRequestEdit = action.queryParams.requestEdit;
        const changed = action.changed;
        const allChanged = Object.keys(changed).every(key => changed[key]);

        if (allChanged) {
            if (isRequestEdit && !action.queryParams.requestId && !action.legId && !action.queryParams.vessel) {
                yield put({
                    type: ActionTypes.WEATHER_ROUTING_CLEAR_DATA,
                    overwriteLegId: true
                });
                editingRequest = yield select(state => state.weatherRoutingReducer.editingRequest);
            }
        }

        editingRequest.LegId = action.legId || null;

        if (isRequestEdit
            && changed.requestId
            && ConfigService.featureToggles.preselectedPerformanceRequestType
            && editingOptions.requestTypes.length
            && !editingRequest.RequestType) {
            const preselectedValue = ConfigService.featureToggles.preselectedPerformanceRequestType;
            const requestType = editingOptions.requestTypes.find(rt => rt.Id === preselectedValue);
            if (requestType) {
                editingRequest.RequestType = requestType;
            }
        }

        if (requestId && changed.requestId) {
            const getRequest = LocalStorageService.isDebugModeActive()
                ? getMockedRequest
                : WeatherRoutingService.getRequest.bind(WeatherRoutingService);
            const res = yield getRequest(requestId);
            if (action.queryParams && res?.Vessel) {
                const RequestType = res.RequestType
                    ? editingOptions.requestTypes.find(rt => rt.Id === res.RequestType.Id)
                    : null;
                editingRequest = {
                    Template: res.Template,
                    LegId: action.legId || null,
                    Voyage: res.Voyage,
                    VesselCondition: res.VesselCondition,
                    OptimizationType: res.OptimizationType,
                    Cost: res.Cost,
                    Draft: res.Draft,
                    ConditionalAreas: extractConditionalAreasFromObject(res.ConditionalAreas),
                    ConsumptionBudget: res.ConsumptionBudget.length === 0
                        ? null
                        : res.ConsumptionBudget.sort(sortByProp('Speed')),
                    Source: res.Source,
                    RouteCalculationType: res.RouteCalculationType,
                    Speed: {
                        InstructedSpeed: typeof res.Speed?.InstructedSpeed === 'number'
                            ? [`${res.Speed.InstructedSpeed}`]
                            : res.Speed.InstructedSpeed || null,
                        MinimumSpeed: res.Speed?.MinimumSpeed || null,
                        MaximumSpeed: res.Speed?.MaximumSpeed || null
                    },
                    RequestType,
                    RoutingEmailsVessel: res.RoutingEmailsVessel,
                    RoutingEmailsOperations: res.RoutingEmailsOperations,
                    RoutingEmailsOther: res.RoutingEmailsOther,
                    PortOfDeparture: res.PortOfDeparture,
                    PortOfDestination: res.PortOfDestination,
                    PvarEmails: res.PvarEmails,
                    PerformanceEmails: res.PerformanceEmails,
                    Remarks: res.Remarks,
                    Vessel: {
                        ...res.Vessel,
                        Title: res.Vessel.VesselName
                    },
                    Eta: TimeHelper.getDateTime(res.Eta),
                    EtaOffset: TimeHelper.getDateOffset(res.Eta),
                    Etd: TimeHelper.getDateTime(res.Etd),
                    EtdOffset: TimeHelper.getDateOffset(res.Etd),
                    TemplateData: null,
                    Waypoints: res.Waypoints.map(waypoint => ({
                        Point: {
                            ...waypoint,
                            Name: waypoint.Name || formatWktPosition(waypoint.Position)
                        }
                    }))
                };

                if ((editingRequest.Source?.Name === 'AutomaticWeatherApi')
                    || (editingRequest.RouteCalculationType?.Name === 'SpeedRange')) {
                    editingRequest.RequestType = null;
                }

                editingRequest.EtdOffset = yield getOffset(editingRequest.PortOfDeparture, editingRequest.EtdOffset);
                editingRequest.EtaOffset = yield getOffset(editingRequest.PortOfDestination, editingRequest.EtaOffset);

                if (shouldResetRouteCalculationType(editingRequest, editingOptions.configurationRelations)) {
                    editingRequest.RouteCalculationType = null;
                }
                templateOptions = [];
                selectedVoyageId = null;
            }
        }

        if (vesselImo && !action.legId && changed.vesselId) {
            const [{ Vessel, Emails }, Voyage, ConsumptionBudget] = yield all([
                getVesselBaseById(vesselImo, editingRequest),
                getVoyagesById(vesselImo, action.queryParams.voyage),
                getConsumptionBudget(vesselImo)
            ]);
            selectedVoyageId = null;
            if (ConsumptionBudget) {
                consumptionBudget = ConsumptionBudget;
            }
            editingRequest = {
                ...editingRequest,
                Vessel,
                ...Emails,
                Voyage
            };
        }

        if (action.legId && changed.legId) {
            const getLeg = LocalStorageService.isDebugModeActive()
                ? getMockedLeg : WeatherRoutingService.getLegById.bind(WeatherRoutingService);
            const leg = yield getLeg(action.legId);
            const { Vessel, Emails } = yield getVesselBaseById(leg.Vessel.Imo);
            const voyagePortInfo = extractPortInfo(leg.Voyage) || extractPortInfo(editingRequest);
            editingRequest = {
                ...editingRequest,
                LegId: action.legId,
                Voyage: {
                    ...leg.Voyage,
                    VoyageId: leg.Voyage.Id,
                    VoyagePortInfo: `(${voyagePortInfo})`
                },
                Vessel: {
                    ...Vessel,
                    Draft: editingRequest.Vessel?.Draft || null
                },
                ...Emails,
                VesselCondition: leg.VesselCondition
            };
        }

        ({
            templateOptions,
            selectedVoyageId
        } = yield getVoyageTemplateOptions(editingRequest, selectedVoyageId, templateOptions));

        editingRequest.TemplateData = getTemplateData(editingRequest, templateOptions);

        yield put({
            type: ActionTypes.WEATHER_ROUTING_SET_DUPLICATE_REQUEST_DATA,
            data: editingRequest,
            templateOptions,
            consumptionBudget,
            selectedVoyageId
        });
    }
}

function* postRequest() {
    const request = yield select(state => state.weatherRoutingReducer.editingRequest);
    // TODO: this are hotfix values (3 and 6) and should be removed soon
    let RequestType = request.RequestType ? request.RequestType.Id : 3;
    const isSpeedRangeCalculationType = request.RouteCalculationType?.Name === 'SpeedRange';
    if (isSpeedRangeCalculationType) {
        RequestType = 6;
    }
    const {
        RoutingEmailsVessel,
        RoutingEmailsOperations,
        RoutingEmailsOther
    } = request;
    const instructedSpeed = request.Speed.InstructedSpeed;
    const isAutomaticRequest = request.Source.Name === 'AutomaticWeatherApi';
    const data = {
        LegId: request.LegId,
        Imo: request.Vessel.IMO || request.Vessel.Imo,
        VoyageId: request.Voyage.VoyageId,
        RoutingEmailsVessel: RoutingEmailsVessel.length ? RoutingEmailsVessel : null,
        RoutingEmailsOperations: RoutingEmailsOperations.length ? RoutingEmailsOperations : null,
        RoutingEmailsOther: RoutingEmailsOther.length ? RoutingEmailsOther : null,
        VesselCondition: request.VesselCondition.Id,
        OptimizationType: request.OptimizationType.Id,
        RouteCalculationType: request.RouteCalculationType?.Id,
        RequestType,
        Template: request.TemplateData ? request.TemplateData.Template : null,
        SourceId: request.Source.Id,
        Speed: {
            InstructedSpeed: request.RouteCalculationType?.Name === 'InstructedSpeed'
                ? instructedSpeed
                : null,
            MinimumSpeed: isSpeedRangeCalculationType ? request.Speed.MinimumSpeed : null,
            MaximumSpeed: isSpeedRangeCalculationType ? request.Speed.MaximumSpeed : null
        },
        Cost: Object.values(request.Cost).every(x => x && String(x).trim())
            ? request.Cost
            : null,
        Draft: request.Vessel.Draft,
        ConditionalAreas: request.Source?.Name === 'ConsultantWeatherApi'
            ? null
            : extractConditionalAreasFromArray(request.ConditionalAreas),
        ConsumptionBudget: request.ConsumptionBudget?.sort(sortByProp('Speed')) || emptyArray,
        Etd: TimeHelper.joinDateAndOffset(request.Etd, request.EtdOffset),
        Eta: isSpeedRangeCalculationType ? TimeHelper.joinDateAndOffset(request.Eta, request.EtaOffset) : null,
        PortOfDeparture: {
            PortId: request.PortOfDeparture.Id || request.PortOfDeparture.PortId
        },
        PortOfDestination: {
            PortId: request.PortOfDestination.Id || request.PortOfDestination.PortId
        },
        PvarEmails: request.PvarEmails.length && request.RequestType?.Name === 'RoutingPerformanceAndPvar'
            ? request.PvarEmails
            : null,
        PerformanceEmails: request.PerformanceEmails.length && request.RequestType
            && (request.RequestType.Name === 'RoutingAndPerformance'
                || request.RequestType.Name === 'RoutingPerformanceAndPvar')
            ? request.PerformanceEmails
            : null,
        Remarks: isAutomaticRequest ? null : request.Remarks,
        Waypoints: repackWaypoints(request.Waypoints)
    };

    let res = null;
    let responses = [];
    if (request.RouteCalculationType.Name === 'InstructedSpeed') {
        if (!data.LegId) {
            data.Speed.InstructedSpeed = instructedSpeed.splice(0, 1)[0];
            res = yield WeatherRoutingService.createRequest(data);
            data.LegId = res?.LegId;
        }
        if (data.LegId) {
            responses = yield all(instructedSpeed.map(speed => WeatherRoutingService.createRequest({
                ...data,
                Speed: {
                    ...data.Speed,
                    InstructedSpeed: speed
                }
            })));
        }
        if (res) {
            responses = [res, ...responses];
        }
    } else {
        responses = [yield WeatherRoutingService.createRequest(data)];
    }
    if (responses.length) {
        const successReq = responses.filter(res => res);
        const failedReq = responses.filter(res => !res);
        successReq.forEach(() => toast(t('WEATHER_ROUTING.TOASTS.REQUEST_CREATED'), { type: toast.TYPE.SUCCESS }));
        if (!failedReq.length) {
            yield updateQueryParams({ remove: ['requestEdit', 'requestId', 'voyage', 'vessel'] });
        }
        if (successReq.length === responses.length) {
            if (data.LegId) {
                yield all([
                    getLegData({ legId: data.LegId }),
                    getVoyages({ forceFetch: true })
                ]);
            } else {
                yield getVoyages({ forceFetch: true });
            }
        }
        if (!failedReq.length) {
            yield put({
                type: ActionTypes.WEATHER_ROUTING_CLEAR_DATA
            });
        }
    }
}

function mapConsumptionBasedOnVesselCondition(consumptionBudget, vesselConditionName) {
    return consumptionBudget.map(item => ({
        Speed: item.Speed,
        FuelConsumption: vesselConditionName === 'Ballast'
            ? item.BallastConsumption
            : item.LadenConsumption
    }));
}

function* updateField(action) {
    const prevState = yield select(state => state.weatherRoutingReducer);
    const { prop, value } = action;
    let {
        editingRequest,
        templateOptions,
        selectedVoyageId,
        consumptionBudget
    } = prevState;
    let fetchTemplates = false;
    if (prop === 'TemplateData' && value) {
        editingRequest = {
            ...editingRequest,
            TemplateData: value,
            VesselCondition: value.VesselCondition,
            PortOfDeparture: value.PortOfDeparture
                ? { ...value.PortOfDeparture, Id: value.PortOfDeparture.PortId }
                : null,
            PortOfDestination: value.PortOfDestination
                ? { ...value.PortOfDestination, Id: value.PortOfDestination.PortId }
                : null,
            Eta: TimeHelper.getDateTime(value.Eta),
            EtaOffset: TimeHelper.getDateOffset(value.Eta),
            Etd: TimeHelper.getDateTime(value.Etd),
            EtdOffset: TimeHelper.getDateOffset(value.Etd),
            Remarks: value.Remarks,
            Waypoints: value.Waypoints.filter(waypoint => waypoint).map(waypoint => ({
                Point: !waypoint.Name && !waypoint.Position ? null : {
                    ...waypoint,
                    Name: waypoint.Name || formatWktPosition(waypoint.Position),
                    Coordinates: wktParse(waypoint.Position),
                    Id: 'CustomPoint',
                    Type: 3,
                    IsCustomPoint: true
                }
            }
            )),
            RoutingEmailsVessel: value.VesselEmails
        };
        if (value.VesselCondition && consumptionBudget) {
            editingRequest.ConsumptionBudget = mapConsumptionBasedOnVesselCondition(
                consumptionBudget,
                value.VesselCondition.Name
            );
        }
    } else {
        editingRequest = setObjectProp({ ...editingRequest }, prop, value, true);
    }
    if (prop === 'Vessel') {
        editingRequest.Voyage = null;
        const vesselImo = prevState.editingRequest.Vessel
            ? prevState.editingRequest.Vessel.Imo || prevState.editingRequest.Vessel.IMO
            : null;
        editingRequest.RoutingEmailsVessel = [];
        editingRequest.RoutingEmailsOperations = [];
        editingRequest.RoutingEmailsOther = [];
        editingRequest.PvarEmails = [];
        editingRequest.PerformanceEmails = [];
        if (value && value.Imo !== vesselImo) {
            const [ConsumptionBudget, { Vessel, Emails }] = yield all([
                getConsumptionBudget(value.Imo),
                getVesselBaseById(value.Imo, editingRequest)
            ]);
            if (ConsumptionBudget) {
                consumptionBudget = ConsumptionBudget;
                if (editingRequest.VesselCondition) {
                    editingRequest.ConsumptionBudget = mapConsumptionBasedOnVesselCondition(
                        editingRequest.ConsumptionBudget.sort(sortByProp('Speed')),
                        editingRequest.VesselCondition.Name
                    );
                }
            }
            editingRequest = {
                ...editingRequest,
                Vessel,
                ...Emails
            };
        }
    }
    if (prop === 'Vessel' || prop === 'Voyage') {
        editingRequest.TemplateData = null;
        templateOptions = [];
        selectedVoyageId = null;
        fetchTemplates = true;
    }
    if (prop === 'VesselCondition' && (!prevState.editingRequest.VesselCondition
        || value.Name !== prevState.editingRequest.VesselCondition.Name)) {
        editingRequest.TemplateData = null;
        templateOptions = [];
        selectedVoyageId = null;
        fetchTemplates = true;

        if (prevState.consumptionBudget
        && editingRequest.VesselCondition
        && !prevState.editingRequest.Id) {
            editingRequest.ConsumptionBudget = mapConsumptionBasedOnVesselCondition(
                prevState.consumptionBudget.sort(sortByProp('Speed')),
                editingRequest.VesselCondition.Name
            );
        }
    }
    if (prop === 'Source' && value && value.Name === 'AutomaticWeatherApi') {
        editingRequest.RequestType = null;
    }
    if (prop === 'RouteCalculationType' && value && value.Name === 'SpeedRange') {
        editingRequest.RequestType = null;
    }
    if (prop === 'RouteCalculationType' && value && value.Name === 'InstructedSpeed') {
        editingRequest.Speed.InstructedSpeed = typeof editingRequest.Speed.InstructedSpeed === 'number'
            ? [editingRequest.Speed.InstructedSpeed]
            : [];
    }

    if ((prop === 'PortOfDeparture' || prop === 'PortOfDestination')) {
        const timeProp = prop === 'PortOfDeparture' ? 'EtdOffset' : 'EtaOffset';
        if (!value) {
            editingRequest[timeProp] = null;
        } else if (value.TimeZoneOffset === null) {
            const time = yield TimeService.getTimeZoneOffsetId({ Location: value.Location });
            editingRequest[timeProp] = TimeHelper.getTimeSpanFromMinutes(time.TimeOffset);
        } else {
            editingRequest[timeProp] = TimeHelper.getTimeSpanFromMinutes(value.TimeZoneOffset);
        }
    }

    if ((prop === 'OptimizationType' || prop === 'Source')
        && shouldResetRouteCalculationType(editingRequest, prevState.editingOptions.configurationRelations)) {
        editingRequest.RouteCalculationType = null;
    }

    if (prop === 'RequestType' && value) {
        editingRequest = {
            ...editingRequest,
            ...getVesselEmails(editingRequest, editingRequest.Vessel)
        };
    }

    if (fetchTemplates) {
        ({
            templateOptions,
            selectedVoyageId
        } = yield getVoyageTemplateOptions(editingRequest, selectedVoyageId, templateOptions));
    }

    yield put({
        type: ActionTypes.WEATHER_ROUTING_UPDATE_FIELD_VALUE,
        editingRequest,
        templateOptions,
        selectedVoyageId,
        consumptionBudget
    });
}

export default function* weatherRoutingSaga() {
    yield takeEvery(ActionTypes.WEATHER_ROUTING_GET_INITIAL_DATA, getInitialData);
    yield takeEvery(ActionTypes.WEATHER_ROUTING_GET_VOYAGES, getVoyages);
    yield takeEvery(ActionTypes.WEATHER_ROUTING_SET_FILTERS_AND_UPDATE, setFiltersAndUpdate);
    yield takeEvery(ActionTypes.WEATHER_ROUTING_GET_FLEETS, getFleets);
    yield takeEvery(ActionTypes.WEATHER_ROUTING_POST_REQUEST, postRequest);
    yield takeEvery(ActionTypes.WEATHER_ROUTING_GET_PORTS, getPorts);
    yield takeEvery(ActionTypes.WEATHER_ROUTING_GET_LEG_DATA, getLegData);
    yield takeEvery(ActionTypes.WEATHER_ROUTING_GET_LEG_AIS_POINTS, getLegAisPoints);
    yield takeEvery(ActionTypes.WEATHER_ROUTING_SET_INTENDED_REQUEST, setIntendedRequest);
    yield takeEvery(ActionTypes.WEATHER_ROUTING_DELETE_REQUEST, deleteRequest);
    yield takeEvery(ActionTypes.WEATHER_ROUTING_STOP_REQUEST, stopRequest);
    yield takeEvery(ActionTypes.WEATHER_ROUTING_REFRESH_REQUEST, refreshRequest);
    yield takeEvery(ActionTypes.WEATHER_ROUTING_DELETE_LEG, deleteLeg);
    yield takeEvery(ActionTypes.WEATHER_ROUTING_GET_DATA, getData);
    yield takeEvery(ActionTypes.WEATHER_ROUTING_UPDATE_FIELD, updateField);
}
