export default {
    BallastGraph: [
        {
            Date: '2019-10-01T00:00:00Z',
            RPM: 90.1875,
            AverageSpeed: 10.93125
        },
        {
            Date: '2019-11-01T00:00:00Z',
            RPM: null,
            AverageSpeed: null
        },
        {
            Date: '2019-12-01T00:00:00Z',
            RPM: 104.91666666666667,
            AverageSpeed: 14.216666666666667
        },
        {
            Date: '2020-01-01T00:00:00Z',
            RPM: 105.75,
            AverageSpeed: 13.991666666666665
        },
        {
            Date: '2020-02-01T00:00:00Z',
            RPM: 105.44444444444444,
            AverageSpeed: 14.077777777777778
        },
        {
            Date: '2020-03-01T00:00:00Z',
            RPM: 106.5,
            AverageSpeed: 14.5
        },
        {
            Date: '2020-04-01T00:00:00Z',
            RPM: 97.6923076923077,
            AverageSpeed: 13.538461538461538
        },
        {
            Date: '2020-05-01T00:00:00Z',
            RPM: 102.46153846153847,
            AverageSpeed: 13.26923076923077
        },
        {
            Date: '2020-06-01T00:00:00Z',
            RPM: 99.769230769230774,
            AverageSpeed: 13.830769230769231
        },
        {
            Date: '2020-07-01T00:00:00Z',
            RPM: 100.5,
            AverageSpeed: 10.675
        },
        {
            Date: '2020-08-01T00:00:00Z',
            RPM: 97.25,
            AverageSpeed: 10.675
        },
        {
            Date: '2020-09-01T00:00:00Z',
            RPM: 101.0,
            AverageSpeed: 12.114285714285714
        },
        {
            Date: '2020-10-01T00:00:00Z',
            RPM: 100.5,
            AverageSpeed: 11.4
        },
        {
            Date: '2020-11-01T00:00:00Z',
            RPM: null,
            AverageSpeed: null
        },
        {
            Date: '2020-12-01T00:00:00Z',
            RPM: 93.269230769230774,
            AverageSpeed: 9.48846153846154
        },
        {
            Date: '2021-01-01T00:00:00Z',
            RPM: 100.28571428571429,
            AverageSpeed: 12.842857142857142
        },
        {
            Date: '2021-02-01T00:00:00Z',
            RPM: 102.0,
            AverageSpeed: 13.8
        },
        {
            Date: '2021-03-01T00:00:00Z',
            RPM: 110.0,
            AverageSpeed: 14.4
        },
        {
            Date: '2021-04-01T00:00:00Z',
            RPM: 108.0,
            AverageSpeed: 14.08
        },
        {
            Date: '2021-05-01T00:00:00Z',
            RPM: 103.33333333333333,
            AverageSpeed: 13.0
        },
        {
            Date: '2021-06-01T00:00:00Z',
            RPM: 106.25,
            AverageSpeed: 14.775
        },
        {
            Date: '2021-07-01T00:00:00Z',
            RPM: 100.0,
            AverageSpeed: 13.0
        },
        {
            Date: '2021-08-01T00:00:00Z',
            RPM: null,
            AverageSpeed: null
        },
        {
            Date: '2021-09-01T00:00:00Z',
            RPM: 101.0,
            AverageSpeed: 9.5
        }
    ],
    LadenGraph: [
        {
            Date: '2019-10-01T00:00:00Z',
            RPM: 93.875,
            AverageSpeed: 11.075
        },
        {
            Date: '2019-11-01T00:00:00Z',
            RPM: 95.07692307692308,
            AverageSpeed: 11.461538461538462
        },
        {
            Date: '2019-12-01T00:00:00Z',
            RPM: 103.5,
            AverageSpeed: 12.9625
        },
        {
            Date: '2020-01-01T00:00:00Z',
            RPM: 103.5,
            AverageSpeed: 13.05
        },
        {
            Date: '2020-02-01T00:00:00Z',
            RPM: 103.07692307692308,
            AverageSpeed: 12.5
        },
        {
            Date: '2020-03-01T00:00:00Z',
            RPM: 103.07142857142857,
            AverageSpeed: 13.421428571428571
        },
        {
            Date: '2020-04-01T00:00:00Z',
            RPM: 100.33333333333333,
            AverageSpeed: 12.177777777777777
        },
        {
            Date: '2020-05-01T00:00:00Z',
            RPM: 101.44444444444444,
            AverageSpeed: 13.311111111111112
        },
        {
            Date: '2020-06-01T00:00:00Z',
            RPM: 98.0,
            AverageSpeed: 12.05
        },
        {
            Date: '2020-07-01T00:00:00Z',
            RPM: 100.08333333333333,
            AverageSpeed: 12.283333333333333
        },
        {
            Date: '2020-08-01T00:00:00Z',
            RPM: 98.0,
            AverageSpeed: 13.225
        },
        {
            Date: '2020-09-01T00:00:00Z',
            RPM: null,
            AverageSpeed: null
        },
        {
            Date: '2020-10-01T00:00:00Z',
            RPM: 101.86666666666666,
            AverageSpeed: 12.793333333333333
        },
        {
            Date: '2020-11-01T00:00:00Z',
            RPM: 99.84615384615384,
            AverageSpeed: 11.614285714285714
        },
        {
            Date: '2020-12-01T00:00:00Z',
            RPM: null,
            AverageSpeed: null
        },
        {
            Date: '2021-01-01T00:00:00Z',
            RPM: 97.15384615384616,
            AverageSpeed: 11.315384615384616
        },
        {
            Date: '2021-02-01T00:00:00Z',
            RPM: 98.0,
            AverageSpeed: 13.1
        },
        {
            Date: '2021-03-01T00:00:00Z',
            RPM: 100.09090909090909,
            AverageSpeed: 12.323636363636362
        },
        {
            Date: '2021-04-01T00:00:00Z',
            RPM: 113.0,
            AverageSpeed: 14.6125
        },
        {
            Date: '2021-05-01T00:00:00Z',
            RPM: 100.0,
            AverageSpeed: 12.566666666666666
        },
        {
            Date: '2021-06-01T00:00:00Z',
            RPM: 99.470588235294116,
            AverageSpeed: 13.058823529411766
        },
        {
            Date: '2021-07-01T00:00:00Z',
            RPM: 100.38461538461539,
            AverageSpeed: 12.292307692307691
        },
        {
            Date: '2021-08-01T00:00:00Z',
            RPM: 105.22222222222223,
            AverageSpeed: 11.9
        },
        {
            Date: '2021-09-01T00:00:00Z',
            RPM: 101.11111111111111,
            AverageSpeed: 12.466666666666667
        }
    ]
};
