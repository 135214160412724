/* utils */
import { t } from 'utils/i18n/i18n-model';

export const dailyReportFieldsAndSections = [
    {
        fields: [
            'Vessel',
            'CallSign',
            'VoyageNumber',
            'CharterpartyDate',
            'ReportDate',
            'Position',
            'Course',
            'CargoStatus',
            'VesselStatus',
            'DailyReportAtSea.LogSpeed',
            'DailyReportAtSea.LogDistance'
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.SINCE_LAST_EVENT'),
        sections: [
            {
                fields: [
                    'TimeElapsedSinceLastEvent',
                    'TimeAtAnchorSinceLastEvent',
                    'HoursSteamedSinceLastEvent',
                    'DistanceSinceLastEvent',
                    'WindForceSinceLastEvent',
                    'TotalCargoSinceLastEvent'
                ]
            }
        ]
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.CONSUMPTIONS_SINCE_LAST_EVENT'),
        condition: (p) => ((p.isEditing && p.editingOptions.consumptionTypes)
            || (p.data.ConsumptionsSinceLastEvent && p.data.ConsumptionsSinceLastEvent.rows.length)),
        data: 'ConsumptionsSinceLastEvent'
    }, {
        type: 'accordion',
        getTitle: report => (!report.ReportSubtype || report.ReportSubtype.Id === 4
            ? t('REPORT_LABELS.PORT_INFORMATION')
            : t('REPORT_LABELS.NEXT_PORT_INFORMATION')),
        sections: [
            {
                fields: [
                    'DailyReportAtPort.PortActivityType',
                    'AuroraReportData.StartPort',
                    'PreviousPort',
                    'NextPoint',
                    'DailyReportAtPort.Point',
                    'DailyReportAtPort.BerthTerminalAnchorage',
                    'EtaDate',
                    'DailyReportAtAnchorage.EstimatedTimeOfBerthing',
                    'DailyReportAtAnchorage.EstimatedTimeOfDeparture',
                    'DailyReportAtPort.EstimatedTimeOfDeparture',
                    'DistanceToGo',
                    'DraftFwd',
                    'DraftMid',
                    'DraftAft',
                    'DailyReportAtPort.CommencedCargoDate',
                    'DailyReportAtPort.QtyLoadedDischarged',
                    'DailyReportAtPort.TotalCargoLoadedDischarged',
                    'DailyReportAtPort.BalanceCargoToLoadDischarge',
                    'DailyReportAtPort.AverageLoadDischargeRate',
                    'DailyReportAtPort.NoOfGangsHatchesWorking'
                ]
            }
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.REMARKS'),
        sections: [
            {
                inline: false,
                fields: ['Remarks']
            }
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.SINCE_LAST_DAILY_REPORT'),
        sections: [
            {
                fields: [
                    'HoursSinceLastReport',
                    'HoursSteamedSinceLastReport',
                    'TotalDistanceSteamedSinceLastReport',
                    'EcaDistanceSteamedSinceLastReport',
                    'InstructedSpeed',
                    'AverageSpeed',
                    'Rpm',
                    'AverageRpm',
                    'DailyReportAtSea.SpeedInstructionType',
                    'DailyReportAtSea.MePowerInKw'
                ]
            }
        ]
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.BUNKER_DATA'),
        condition: (p) => ((p.isEditing && p.editingOptions.bunkerTypes.length)
            || (p.data.BunkerData && p.data.BunkerData.rows.length)),
        data: 'BunkerData'
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.CONSUMPTIONS'),
        condition: (p) => ((p.isEditing && p.editingOptions.consumptionTypes)
            || (p.data.Consumptions && p.data.Consumptions.rows.length)),
        data: 'Consumptions'
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.UTILIZATION'),
        condition: (p) => p.data.Utilization,
        data: 'Utilization',
        dataFields: ['AuxiliaryRunningHours', 'AuxiliaryEnergyProduced', 'BoilerRunningHours']
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.ENGINEERING'),
        condition: (p) => p.data.AuroraReportData
            && (p.data.AuroraReportData.Water
                || (p.data.AuroraReportData.ExtractedEquipmentItems
                    && (p.data.AuroraReportData.ExtractedEquipmentItems.Boiler
                        || p.data.AuroraReportData.ExtractedEquipmentItems.MainTurbine
                        || p.data.AuroraReportData.ExtractedEquipmentItems.PropulsionMotor
                        || p.data.AuroraReportData.ExtractedEquipmentItems.HoursConsumption
                        || p.data.AuroraReportData.ExtractedEquipmentItems.MainEngine))),
        sections: [
            {
                data: 'AuroraReportData.ExtractedEquipmentItems.Boiler',
                condition: (p) => p.data.AuroraReportData.ExtractedEquipmentItems
                    && p.data.AuroraReportData.ExtractedEquipmentItems.Boiler
            },
            {
                data: 'AuroraReportData.ExtractedEquipmentItems.MainTurbine',
                condition: (p) => p.data.AuroraReportData.ExtractedEquipmentItems
                    && p.data.AuroraReportData.ExtractedEquipmentItems.MainTurbine
            },
            {
                data: 'AuroraReportData.ExtractedEquipmentItems.PropulsionMotor',
                condition: (p) => p.data.AuroraReportData.ExtractedEquipmentItems
                    && p.data.AuroraReportData.ExtractedEquipmentItems.PropulsionMotor
            },
            {
                data: 'AuroraReportData.ExtractedEquipmentItems.HoursConsumption',
                condition: (p) => p.data.AuroraReportData.ExtractedEquipmentItems
                    && p.data.AuroraReportData.ExtractedEquipmentItems.HoursConsumption
            },
            {
                data: 'AuroraReportData.ExtractedEquipmentItems.MainEngine',
                condition: (p) => p.data.AuroraReportData.ExtractedEquipmentItems
                    && p.data.AuroraReportData.ExtractedEquipmentItems.MainEngine
            },
            { data: 'AuroraReportData.Water', condition: (p) => p.data.AuroraReportData.Water }
        ]
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.CARGO'),
        condition: (p) => p.data.AuroraReportData && p.data.AuroraReportData.Temperatures,
        sections: [
            { data: 'AuroraReportData.Temperatures' },
            {
                fields: [
                    'AuroraReportData.CargoUsed.ForceUsed',
                    'AuroraReportData.CargoUsed.Hours'
                ]
            }
        ]
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('REPORT_LABELS.CARGO_CONTAINMENT'),
        condition: (p) => p.data.AuroraReportData
            && (p.data.AuroraReportData.BarrierSpaceConditions || p.data.AuroraReportData.CofferdamTemps),
        sections: [
            { data: 'AuroraReportData.BarrierSpaceConditions' },
            { data: 'AuroraReportData.CofferdamTemps' }
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.WEATHER'),
        sections: [
            {
                fields: [
                    'WindBeaufort',
                    'WindBeaufortExternal',
                    'Wind',
                    'Swell',
                    'SeasHeightAndCondition',
                    'DailyReportAtSea.SeasHeightAndDirection',
                    'DailyReportAtAnchorage.SeasHeightAndDirection',
                    'SeaCondition',
                    'DailyReportAtSea.CurrentKn',
                    'DailyReportAtAnchorage.CurrentKn',
                    'InmarsatAreaCodeInUse'
                ]
            }
        ]
    }, {
        type: 'accordion',
        title: t('REPORT_LABELS.ADDITIONAL_INFORMATION'),
        sections: [
            {
                fields: [
                    'Mcr',
                    'Slip',
                    'LastPropellerCleaning',
                    'LastHullCleaning',
                    'FreshWaterAvailableForTankCleaning',
                    'WaterTemperature',
                    'AuroraReportData.MainEngineMiles',
                    'AuroraReportData.DistanceThroughIce',
                    'AuroraReportData.CpWarrantedConsumption',
                    'AuroraReportData.Trim',
                    'AuroraReportData.TrimType',
                    'AuroraReportData.ElectricalLoad'
                ]
            }
        ]
    }
];
