import React from 'react';
import PropTypes from 'prop-types';
import Month from './month';
import { compareDates, getRangeInDays } from '../helpers';
import { DateShape } from '../types';

export default class HtecDPRangeLogic extends React.Component {
    state = {
        hoveredDate: null,
        prevHoveredDate: null
    };

    static getDerivedStateFromProps(props, state) {
        if (props.rangeStart && props.rangeEnd && state.hoveredDate) {
            return { hoveredDate: null };
        }
        if (props.hoveredDate !== state.prevHoveredDate) {
            return { hoveredDate: props.hoveredDate, prevHoveredDate: props.hoveredDate };
        }
        return null;
    }

    isRangeLengthValid = (rangeStart, rangeEnd) => {
        const rangeInDays = getRangeInDays(rangeStart, rangeEnd);
        const minRangeValid = !this.props.minRangeDays
            || this.props.minRangeDays < 0
            || rangeInDays >= this.props.minRangeDays;
        const maxRangeValid = !this.props.maxRangeDays || rangeInDays <= this.props.maxRangeDays;
        return minRangeValid && maxRangeValid;
    };

    setHoveredDate = (params) => {
        if (
            this.props.hoverTracking !== 'disabled'
            && (this.props.hoverTracking !== 'default' || (this.props.rangeStart && !this.props.rangeEnd))
            && (compareDates(params, this.state.hoveredDate) !== 0)
        ) {
            this.setState({ hoveredDate: params });
        }
    };

    selectDay = (params) => {
        if (!this.props.onRangeSelect) {
            return;
        }
        if (!this.props.rangeStart) {
            this.props.onRangeSelect({ rangeStart: params });
            if (this.props.onRangeStartSelect) {
                this.props.onRangeStartSelect(params);
            }
        } else if (this.props.rangeStart && this.props.rangeEnd) {
            this.props.onRangeSelect({
                rangeStart: params,
                rangeEnd: null
            });
            if (this.props.onRangeStartSelect) {
                this.props.onRangeStartSelect(params);
            }
        } else if (this.isRangeLengthValid(this.props.rangeStart, params)) {
            let rangeStart = this.props.rangeStart;
            let rangeEnd = params;
            if (compareDates(params, this.props.rangeStart) === -1) {
                rangeEnd = { ...rangeStart };
                rangeStart = params;
            }
            this.props.onRangeSelect({ rangeStart, rangeEnd });
            if (this.props.hoverTracking === 'default') {
                this.setState({ hoveredDate: null });
            }
            if (this.props.onRangeEndSelect) {
                this.props.onRangeEndSelect(rangeEnd);
            }
        }
    };

    getDayProps = (day) => {
        const { hoveredDate } = this.state;
        const { rangeStart, rangeEnd, minDate, maxDate } = this.props;
        const hoverCompare = compareDates(day, hoveredDate);
        const rangeStartCompare = compareDates(day, rangeStart);
        const rangeEndCompare = compareDates(day, rangeEnd);
        const isHovered = hoverCompare === 0;
        let isRangeStart = rangeStartCompare === 0;
        let isRangeEnd = rangeEndCompare === 0;
        const isSelected = isRangeStart || isRangeEnd;
        let isHighlighted = false;
        const isDisabled = (minDate && compareDates(day, minDate) === -1)
            || (maxDate && compareDates(day, maxDate) === 1);
        if (rangeStart) {
            if (rangeEnd) {
                if (rangeStartCompare === 1 && rangeEndCompare === -1) {
                    isHighlighted = true;
                }
            } else if (hoveredDate) {
                if (isRangeStart && hoverCompare === 1) {
                    isRangeEnd = true;
                    isRangeStart = false;
                } else if (isHovered) {
                    if (rangeStartCompare === -1) {
                        isRangeStart = true;
                    } else if (rangeStartCompare === 1) {
                        isRangeEnd = true;
                    }
                }
                if ((rangeStartCompare === 1 && hoverCompare === -1)
                    || (hoverCompare === 1 && rangeStartCompare === -1)) {
                    isHighlighted = true;
                }
            }
        }
        return {
            isHovered,
            isHighlighted,
            isSelected,
            isRangeStart,
            isRangeEnd,
            isDisabled
        };
    };

    renderDay = (args) => this.props.renderDay({
        ...args,
        ...this.getDayProps(args),
        onClick: this.selectDay,
        onMouseEnter: this.setHoveredDate
    });

    render() {
        return this.props.renderRange({ renderDay: this.renderDay });
    }
}

HtecDPRangeLogic.propTypes = {
    hoveredDate: PropTypes.shape(DateShape),
    hoverTracking: PropTypes.oneOf(['default', 'active', 'disabled']),
    maxDate: PropTypes.shape(DateShape),
    maxRangeDays: PropTypes.number,
    minDate: PropTypes.shape(DateShape),
    minRangeDays: PropTypes.number,
    onRangeEndSelect: PropTypes.func,
    onRangeSelect: PropTypes.func.isRequired,
    onRangeStartSelect: PropTypes.func,
    rangeEnd: PropTypes.shape(DateShape),
    rangeStart: PropTypes.shape(DateShape),
    renderDay: PropTypes.func,
    renderRange: PropTypes.func.isRequired
};

HtecDPRangeLogic.defaultProps = {
    hoveredDate: null,
    hoverTracking: 'default',
    maxDate: null,
    maxRangeDays: 0,
    minDate: null,
    minRangeDays: 0,
    onRangeEndSelect: undefined,
    onRangeStartSelect: undefined,
    rangeStart: null,
    rangeEnd: null,
    renderDay: Month.defaultProps.renderDay
};
