import { ActionTypes } from './voyage-performance-actions';
import { ActionTypes as ReportActionTypes } from 'components/vessel-report/vessel-report-actions';

const emptyArray = [];

const initialState = {
    consumption: null,
    counts: null,
    deletedReportIds: null,
    editedReportIds: null,
    graphs: null,
    performance: null,
    performanceParams: null,
    selectedVesselBase: null,
    selectedVesselCii: null,
    selectedVesselSensorData: null,
    selectedVesselVoyages: emptyArray,
    selectedVoyage: null,
    selectedVoyageAisPoints: emptyArray,
    selectedVoyageCargoSummary: null,
    selectedVoyageEarnings: null,
    selectedVoyageEuEts: null,
    selectedVoyageFootprint: null,
    selectedVoyageVmsFootprint: null,
    selectedVoyageReports: emptyArray,
    selectedVoyageTankLayout: emptyArray
};

export default function voyagePerformanceReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.VOYAGE_PERFORMANCE_SET_VOYAGE_PERFORMANCE_DATA: {
        return {
            ...state,
            consumption: action.consumption || null,
            performance: action.performance || null,
            performanceParams: action.params || null
        };
    }
    case ActionTypes.VOYAGE_PERFORMANCE_SET_VOYAGE_DATA: {
        return {
            ...state,
            counts: action.counts || null,
            deletedReportIds: null,
            editedReportIds: null,
            graphs: action.graphs || null,
            selectedVoyageAisPoints: action.selectedVoyageAisPoints || emptyArray,
            selectedVesselSensorData: action.sensorData || null,
            selectedVoyage: action.voyage || null,
            selectedVoyageCargoSummary: (action.tankLayout && action.tankLayout.CargoSummary) || null,
            selectedVoyageEarnings: action.earnings || null,
            selectedVoyageFootprint: action.selectedVoyageFootprint || null,
            selectedVoyageVmsFootprint: action.selectedVoyageVmsFootprint || null,
            selectedVoyageReports: action.reports || emptyArray,
            selectedVoyageTankLayout: (action.tankLayout && action.tankLayout.CargoWings) || emptyArray
        };
    }
    case ActionTypes.VOYAGE_PERFORMANCE_SET_VOYAGE_EU_ETS: {
        return {
            ...state,
            selectedVoyageEuEts: action.selectedVoyageEuEts || null
        };
    }
    case ActionTypes.VOYAGE_PERFORMANCE_SET_VESSEL_DATA: {
        return {
            ...state,
            selectedVesselBase: action.vesselBase || null,
            selectedVesselVoyages: action.voyages || emptyArray,
            selectedVesselCii: action.selectedVesselCii || null
        };
    }
    case ActionTypes.VOYAGE_PERFORMANCE_RESET_ALL: {
        return initialState;
    }
    case ReportActionTypes.VESSEL_REPORT_SUCCESSFULLY_DELETED: {
        let deletedReportIds = state.deletedReportIds ? { ...state.deletedReportIds } : null;
        const editedReportIds = state.editedReportIds ? { ...state.editedReportIds } : null;
        if (!deletedReportIds) {
            deletedReportIds = {};
        }
        if (editedReportIds?.[action.reportId]) {
            editedReportIds[action.reportId] = false;
        }
        if (!deletedReportIds[action.reportId]) {
            deletedReportIds[action.reportId] = true;
        }
        return {
            ...state,
            deletedReportIds,
            editedReportIds
        };
    }
    case ReportActionTypes.VESSEL_REPORT_SUCCESSFULLY_UPDATED: {
        let editedReportIds = state.editedReportIds ? { ...state.editedReportIds } : null;
        if (!editedReportIds) {
            editedReportIds = {};
        }
        if (!editedReportIds[action.reportId]) {
            editedReportIds[action.reportId] = true;
        }
        return {
            ...state,
            editedReportIds
        };
    }
    default:
        return state;
    }
}
