import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* components */
import Avatar from 'components/avatar/avatar';
import Checkbox from 'components/checkbox/checkbox';
import Modal from 'components/modal/modal';
import RadioButton from 'components/radio-button/radio-button';
import ScrollArea from 'components/scroll-area/scroll-area';
import ShowMoreButton from 'components/show-more-button/show-more-button';
import Select from 'components/select/select';
/* actions */
import { updateUserSettings } from '../../user-actions';
/* services */
import AuthService from 'services/auth-service';
import ConfigService from 'services/config-api/config-service';
import LocalStorageService from 'services/local-storage-service';
/* styles */
import './account.scss';

const currencyOptionRenderer = (option) => `${option.Name} (${option.Ticker})`;

class Account extends React.PureComponent {
    state = {
        showMoreCompanies: false,
        showMoreDepartments: false,
        showSettings: false,
        isConfirmModalOpen: false,
        chosenDateFormat: null,
        debugModeActive: LocalStorageService.isDebugModeActive()
    };

    isLogoutButtonDisabled = false;

    componentDidUpdate(prevProps) {
        if (this.props.preferredDateFormat !== prevProps.preferredDateFormat) {
            window.location.reload();
        }
    }

    componentWillUnmount() {
        if (this.debugClickTimeout) {
            clearTimeout(this.debugClickTimeout);
        }
    }

    toggleShowMoreCompanies = () => {
        this.setState({
            showMoreCompanies: !this.state.showMoreCompanies
        });
    };

    toggleShowMoreDepartments = () => {
        this.setState({
            showMoreDepartments: !this.state.showMoreDepartments
        });
    };

    getJobInfo = (type) => {
        let title;
        let isShown;
        let toggleShow;
        if (type === 'UserCompanies') {
            title = t('ACCOUNT.COMPANIES');
            isShown = this.state.showMoreCompanies;
            toggleShow = this.toggleShowMoreCompanies;
        } else {
            title = t('ACCOUNT.DEPARTMENTS');
            isShown = this.state.showMoreDepartments;
            toggleShow = this.toggleShowMoreDepartments;
        }
        if (this.props.user[type] && this.props.user[type].length > 0) {
            if (this.props.user[type].length > 1) {
                const items = isShown
                    ? this.props.user[type]
                    : this.props.user[type].slice(0, 1);
                return (
                    <div className="text-center">
                        <h3 className="text-secondary">{title}</h3>
                        {items.map((item) => (
                            <h2 key={item.Id}>{item.Name}</h2>
                        ))}
                        <ShowMoreButton isMoreShown={isShown} onClick={toggleShow} />
                    </div>
                );
            }
            return (
                <div className="text-center">
                    <h3 className="text-secondary">{title}</h3>
                    <h2>
                        {this.props.user[type][0].Name}
                    </h2>
                </div>
            );
        }
        return null;
    };

    handleButtonClick = (callback) => (e) => {
        e.stopPropagation();
        callback();
    };

    goToPostLogout = () => {
        if (!this.isLogoutButtonDisabled) {
            this.isLogoutButtonDisabled = true;
            ConfigService.clearCache();
            AuthService.logout();
        }
    };

    redirectToChangePassword = () => {
        AuthService.userManager.removeUser().then(() => {
            window.location.href = `${ConfigService.authority.authority}/Account/ChangePassword?ReturnUrl=${
                window.location.href
            }`;
        });
    };

    clickCount = 0;

    toggleDebugMode = () => {
        this.clickCount++;
        if (this.clickCount === 5) {
            const debugModeActive = LocalStorageService.toggleDebugMode();
            this.setState({ debugModeActive });
        }
        if (this.debugClickTimeout) {
            clearTimeout(this.debugClickTimeout);
        }
        this.debugClickTimeout = setTimeout(() => {
            this.clickCount = 0;
        }, 500);
    };

    getAccountBody = () => {
        if (this.state.showSettings) {
            return null;
        }

        let lastUpdateTitle = t('ACCOUNT.LAST_UPDATE', { update: process.env.LAST_UPDATE_DATE });
        if (ConfigService.featureToggles.showLastUpdateMessage) {
            lastUpdateTitle += ` | ${process.env.LAST_UPDATE_MESSAGE}`;
        }

        const logoutClassName = this.props.user && this.props.user.IsLocal ? 'col-12' : 'col-24';

        return (
            <div className="sten-content__body sten-account__body">
                <ScrollArea className="sten-account__body-content">
                    <div className="sten-content__section text-center">
                        <Avatar size="lg" profileImageUrl={this.props.user.ProfileImageUrl} />
                    </div>
                    <div className="sten-content__section text-center">
                        <h1 className="sten-account__name">
                            {`${this.props.user.FirstName} ${this.props.user.LastName}`}
                        </h1>
                    </div>
                    <div className="sten-content__section text-center">
                        <ul className="sten-account__contact">
                            {this.props.user.Phone && (
                                <li className="sten-account__contact-item">
                                    {`${t('GLOBAL.TEL')}: ${this.props.user.Phone}`}
                                </li>
                            )}
                            {this.props.user.Email && (
                                <li className="sten-account__contact-item sten-account__contact-email">
                                    {this.props.user.Email}
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="sten-content__section text-center">
                        {this.getJobInfo('UserCompanies')}
                    </div>
                    <div className="sten-content__section sten-content__separator text-center">
                        {this.getJobInfo('Departments')}
                    </div>
                    {this.props.user.Position && (
                        <div className="sten-content__section text-center">
                            <h3 className="text-secondary">
                                {t('ACCOUNT.POSITION')}
                            </h3>
                            <h2>
                                {this.props.user.Position}
                            </h2>
                        </div>
                    )}
                    {this.props.user.Location && (
                        <div className="sten-content__section text-center">
                            <h3 className="text-secondary">
                                {t('ACCOUNT.LOCATION')}
                            </h3>
                            <h2>
                                {this.props.user.Location}
                            </h2>
                        </div>
                    )}
                    <div className="sten-content__section text-center">
                        <h6 className="text-secondary" title={lastUpdateTitle} onClick={this.toggleDebugMode}>
                            {t('ACCOUNT.VERSION', { version: process.env.APP_VERSION })}
                            {this.state.debugModeActive && <span className="text-danger"> (DEBUG MODE ACTIVE)</span>}
                        </h6>
                    </div>
                </ScrollArea>
                <footer className="sten-content__footer flex-row">
                    {this.props.user && this.props.user.IsLocal && (
                        <div className="col-12">
                            <button
                                className="btn btn--secondary col-24"
                                onClick={this.redirectToChangePassword}
                            >
                                {t('ACCOUNT.CHANGE_PASSWORD')}
                            </button>
                        </div>
                    )}
                    <div className={logoutClassName}>
                        <button
                            className="btn btn--primary col-24"
                            onClick={this.goToPostLogout}
                        >
                            {t('ACCOUNT.LOGOUT')}
                        </button>
                    </div>
                </footer>
            </div>
        );
    };

    handleCurrencyChange = (value) => {
        if (this.props.selectedCurrency.Ticker !== value.Ticker) {
            this.props.updateUserSettings({ Currency: value });
        }
    };

    getSettings = () => {
        const { supportedCurrencies, selectedCurrency } = this.props;
        const selectedCurrencyOption = (selectedCurrency
            && supportedCurrencies.find(c => c.Ticker === selectedCurrency.Ticker))
            || null;
        return (
            <div className="sten-account__settings">
                <div className="sten-content__header flex-row text-uppercase">
                    <div className="flex-shrink">
                        <button
                            className="btn-link icon icon-arrow-left"
                            onClick={this.handleButtonClick(this.toggleSettings)}
                        />
                    </div>
                    <div className="flex-grow">
                        <h1>{t('ACCOUNT.SETTINGS.TITLE')}</h1>
                    </div>
                </div>
                <div className="sten-content__body">
                    <ScrollArea className="sten-account__body-content">
                        <div className="sten-content__section">
                            <Checkbox
                                name="onScreenKeyboard"
                                onChange={this.toggleKeyboard}
                                isChecked={this.props.onScreenKeyboardEnabled}
                            >
                                {t('ACCOUNT.SETTINGS.ENABLE_SCREEN_ON_KEYBOARD')}
                            </Checkbox>
                        </div>
                        <div className="sten-content__section-header text-uppercase">
                            {t('ACCOUNT.SETTINGS.SET_DATE_FORMAT')}
                        </div>
                        <div className="sten-content__section">
                            {this.props.timeFormats.map(format => (
                                <div className="form-row" key={format.Id}>
                                    <RadioButton
                                        className="text-uppercase"
                                        onChange={this.toggleConfirmModal.bind(this, true, format.WebClientValue)}
                                        isChecked={this.props.preferredDateFormat === format.WebClientValue}
                                    >
                                        {moment().format(format.WebClientValue)}
                                        <span className="text-secondary">{` (${format.WebClientValue})`}</span>
                                    </RadioButton>
                                </div>
                            ))}
                        </div>
                        {ConfigService.featureToggles.showEuEts && (
                            <React.Fragment>
                                <div className="sten-content__section-header text-uppercase">
                                    {t('ACCOUNT.SETTINGS.SET_EU_ETS_CURRENCY')}
                                </div>
                                <div className="sten-content__section">
                                    <Select
                                        optionRenderer={currencyOptionRenderer}
                                        value={selectedCurrencyOption}
                                        onChange={this.handleCurrencyChange}
                                        options={this.props.supportedCurrencies}
                                        valueKey="Ticker"
                                        labelKey="Name"
                                        name="supportedCurrencies"
                                        placeholder={t('ACCOUNT.SETTINGS.SELECT_CURRENCY')}
                                    />
                                </div>
                            </React.Fragment>
                        )}
                    </ScrollArea>
                </div>
            </div>
        );
    };

    getContent = () => {
        if (this.state.showSettings) {
            return this.getSettings();
        }
        return this.getAccountBody();
    };

    setPreferredDate = (e) => {
        e.stopPropagation();
        this.props.updateUserSettings({ DateFormat: this.state.chosenDateFormat });
        this.setState({
            isConfirmModalOpen: false
        });
    };

    toggleSettings = () => {
        this.setState({
            showSettings: !this.state.showSettings
        });
    };

    toggleKeyboard = () => {
        this.props.updateUserSettings({
            OnScreenKeyboardEnabled: !this.props.onScreenKeyboardEnabled
        });
    };

    toggleConfirmModal = (flag, chosenDateFormat, e) => {
        e.stopPropagation();
        this.setState({
            isConfirmModalOpen: flag !== undefined ? flag : !this.state.isConfirmModalOpen,
            chosenDateFormat
        });
    };

    render() {
        return (
            <div className="sten-left-sidebar-panel sten-account">
                <Modal.Confirm
                    isOpen={this.state.isConfirmModalOpen}
                    onConfirm={this.setPreferredDate}
                    onCancel={this.toggleConfirmModal.bind(this, false, null)}
                >
                    {t('ACCOUNT.SETTINGS.RELOAD_MESSAGE')}
                </Modal.Confirm>
                <div className="sten-content__header text-uppercase">
                    <div className="flex-grow">
                        <h1>{t('ACCOUNT.TITLE')}</h1>
                    </div>
                    <div className="flex-shrink">
                        <button
                            className="btn-link icon icon-cog"
                            onClick={this.handleButtonClick(this.toggleSettings)}
                        />
                    </div>
                </div>
                {this.getContent()}
            </div>
        );
    }
}

Account.propTypes = {
    onScreenKeyboardEnabled: PropTypes.bool.isRequired,
    preferredDateFormat: PropTypes.string.isRequired,
    selectedCurrency: PropTypes.objectOf(PropTypes.any),
    supportedCurrencies: PropTypes.arrayOf(PropTypes.object).isRequired,
    timeFormats: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateUserSettings: PropTypes.func.isRequired,
    user: PropTypes.objectOf(PropTypes.any).isRequired
};

Account.defaultProps = {
    selectedCurrency: null
};

function mapStateToProps(state) {
    return {
        onScreenKeyboardEnabled: state.userReducer.settings && state.userReducer.settings.OnScreenKeyboardEnabled,
        preferredDateFormat: state.userReducer.settings && state.userReducer.settings.DateFormat,
        selectedCurrency: state.userReducer.settings && state.userReducer.settings.Currency,
        supportedCurrencies: state.userReducer.supportedCurrencies,
        timeFormats: state.userReducer.timeFormats,
        user: state.userReducer.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateUserSettings: settings => updateUserSettings(dispatch, settings)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Account);
