import 'core-js';
import 'custom-event-polyfill/polyfill';
import 'new-event-polyfill';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router';
/* router */
import { router } from './app-router';
/* store */
import { getStore, startSagas } from './app-store';
/* axios interceptors */
import './interceptors';
/* components */
import ReactModal from 'react-modal';
/* styles */
import 'flagpack/dist/flagpack.css';
import './assets/fonts/sten-icons/font-sten-icons.scss';
import './assets/fonts/dosis/font-dosis.scss';
import './assets/fonts/roboto/font-roboto.scss';
import './app.scss';

ReactModal.setAppElement('#orbitApp');

/* Store initialization */
const store = getStore(router);
startSagas();

/* App render */
render(
    (
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    ),
    document.getElementById('orbitApp')
);

export default store;
