import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ScrollArea from 'components/scroll-area/scroll-area';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* actions */
import { getVessels, generateXLSX } from './fleet-range-actions';
/* components */
import Input from 'components/input/input';
import FleetRangeVessel from './fleet-range-vessel';
import EmptyContent from 'components/empty-content/empty-content';
/* styles */
import './fleet-range.scss';

const initialSearchParams = {
    searchValue: '',
    offset: 0,
    limit: 20
};

export class FleetRange extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            vesselsSearchParams: { ...initialSearchParams },
            isGettingData: true,
            prevVessels: props.vessels,
            prevSettingUpdated: props.settingUpdated,
            prevResourceUpdated: props.resourceUpdated
        };
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
        let stateChanged = false;
        if (state.prevVessels !== props.vessels) {
            newState.isGettingData = false;
            newState.prevVessels = props.vessels;
            stateChanged = true;
        }
        if ((props.settingUpdated !== state.prevSettingUpdated && props.settingUpdated.name === 'mapFilters')
            || (props.resourceUpdated !== state.prevResourceUpdated && props.resourceUpdated.name === 'fleets')) {
            newState.vesselsSearchParams = { ...initialSearchParams };
            newState.prevSettingUpdated = props.settingUpdated;
            newState.prevResourceUpdated = props.resourceUpdated;
            props.getVessels(initialSearchParams, true);
            stateChanged = true;
        }
        return stateChanged ? newState : null;
    }

    componentDidMount() {
        const searchParams = {
            Offset: this.state.vesselsSearchParams.offset,
            Limit: this.state.vesselsSearchParams.limit
        };
        if (this.props.getVessels) {
            this.props.getVessels(searchParams, true);
        }
    }

    searchHandler = value => {
        this.setState({
            vesselsSearchParams: {
                ...initialSearchParams,
                searchValue: value
            }
        });
        if (value.length >= 3 || !value) {
            if (this.debounce) {
                clearTimeout(this.debounce);
            }
            this.debounce = setTimeout(() => {
                const searchParams = {
                    Limit: initialSearchParams.limit,
                    Offset: initialSearchParams.offset,
                    SearchTerm: value
                };
                this.props.getVessels(searchParams, true);
            }, 500);
            if (this.scrollArea) {
                this.scrollArea.scrollTop();
            }
        }
    };

    scrollHandler = value => {
        if (value.realHeight - value.topPosition < value.containerHeight + 100) {
            if (!this.state.isGettingData && !this.props.vesselsAllFetched) {
                this.setState({
                    isGettingData: true
                });
                let newProps;
                const newOffset = this.state.vesselsSearchParams.offset + this.state.vesselsSearchParams.limit;
                this.setState({
                    vesselsSearchParams: {
                        ...this.state.vesselsSearchParams,
                        offset: newOffset
                    }
                });
                if (this.state.vesselsSearchParams.searchValue) {
                    newProps = {
                        SearchTerm: this.state.vesselsSearchParams.searchValue,
                        Limit: this.state.vesselsSearchParams.limit,
                        Offset: newOffset
                    };
                } else {
                    newProps = {
                        Limit: this.state.vesselsSearchParams.limit,
                        Offset: newOffset
                    };
                }
                this.props.getVessels(newProps);
            }
        }
    };

    saveRef = c => { this.scrollArea = c; };

    render() {
        return (
            <div className="sten-fleet-range">
                <div className="sten-content__body sten-fleet-range__filters">
                    <div className="sten-content__section">
                        <Input
                            suffixIcon="icon-search"
                            onChange={this.searchHandler}
                            placeholder={translate('FLEET_RANGE.SEARCH_VESSELS_PLACEHOLDER')}
                            title={translate('FLEET_RANGE.SEARCH_VESSELS_TITLE')}
                            value={this.state.vesselsSearchParams.searchValue}
                        />
                    </div>
                </div>
                {this.props.vessels.length ? (
                    <ScrollArea
                        className="sten-content__body sten-fleet-range__list sten-content__separator"
                        onScroll={this.scrollHandler}
                        ref={this.saveRef}
                    >
                        {this.props.vessels.map((vessel) => (
                            <FleetRangeVessel
                                vessel={vessel}
                                key={vessel.IMO}
                                searchValue={this.state.vesselsSearchParams.searchValue}
                            />
                        ))}
                    </ScrollArea>
                ) : <EmptyContent />}
                <footer className="sten-content__footer">
                    <div className="flex-grow">
                        <div className="row">
                            <div className="col-24">
                                <button
                                    onClick={this.props.generateXLSX}
                                    className="btn btn--secondary col-24"
                                >
                                    {translate('FLEET_RANGE.EXPORT_XLSX')}
                                </button>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
}

FleetRange.propTypes = {
    getVessels: PropTypes.func.isRequired,
    generateXLSX: PropTypes.func.isRequired,
    resourceUpdated: PropTypes.objectOf(PropTypes.any).isRequired,
    settingUpdated: PropTypes.objectOf(PropTypes.any).isRequired,
    vessels: PropTypes.arrayOf(PropTypes.object).isRequired,
    vesselsAllFetched: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        resourceUpdated: state.userReducer.resourceUpdated,
        settingUpdated: state.userReducer.settingUpdated,
        vessels: state.fleetRangeReducer.vessels,
        vesselsAllFetched: state.fleetRangeReducer.allFetched
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getVessels: (params, overrideCurrent) => getVessels(dispatch, params, overrideCurrent),
        generateXLSX: () => generateXLSX(dispatch)
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(FleetRange);
