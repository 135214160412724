import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ScrollArea from 'components/scroll-area/scroll-area';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* actions */
import {
    getActiveNotifications,
    getDismissedNotifications,
    dismissNotifications
} from '../../../notifications/notifications-actions';
import { sendReminder } from 'components/vessel-report/vessel-report-actions';
/* components */
import TabSelector from 'components/tab-selector/tab-selector';
import Notification from 'components/notification/notification';
import NotificationEmpty from 'components/notification/notification-empty';
/* styles */
import './notifications-panel.scss';

export class NotificationsPanel extends React.PureComponent {
    state = {
        activeTabId: 0,
        isGettingData: false,
        selectedNotifications: [],
        activeConfirmNotificationId: null,
        activeNotificationsLimit: 20,
        prevOffset: 0
    };

    static getDerivedStateFromProps(props, state) {
        if (state.isGettingData && props.offset !== state.prevOffset) {
            return {
                isGettingData: false,
                prevOffset: props.offset
            };
        }
        return null;
    }

    componentDidMount() {
        this.props.getActiveNotifications();
    }

    static getTabs() {
        return [{
            id: 0,
            title: translate('NOTIFICATIONS.ACTIVE'),
            icon: 'icon-alert'
        }, {
            id: 1,
            title: translate('NOTIFICATIONS.DISMISSED'),
            icon: 'icon-alert-dismissed'
        }];
    }

    setActiveTab = tabId => {
        this.setState({
            activeTabId: tabId,
            selectedNotifications: [],
            activeConfirmNotificationId: null,
            activeNotificationsLimit: 20
        });
        if (tabId === 0) {
            this.props.getActiveNotifications();
        } else {
            this.props.getDismissedNotifications();
        }
        this.scrollArea.scrollArea.scrollTop();
    };

    scrollHandler = value => {
        if (value.realHeight - value.topPosition < value.containerHeight + 100) {
            if (this.state.activeTabId === 1 && !this.state.isGettingData && !this.props.isLastPage) {
                this.setState({
                    isGettingData: true
                });

                const offset = this.props.offset + this.props.limit;
                if (this.state.activeTabId === 0) {
                    this.props.getActiveNotifications({
                        Limit: this.props.limit,
                        Offset: offset
                    }, true);
                } else {
                    this.props.getDismissedNotifications({
                        Limit: this.props.limit,
                        Offset: offset
                    }, true);
                }
            }
            if (this.state.activeTabId === 0) {
                this.setState({
                    activeNotificationsLimit: this.state.activeNotificationsLimit + 20
                });
            }
        }
    };

    toggleConfirmId = notificationId => {
        this.setState({
            activeConfirmNotificationId: notificationId
        });
    };

    toggleSelected = notification => {
        const selectedNotifications = this.state.selectedNotifications.slice();
        if (this.state.activeConfirmNotificationId) {
            this.setState({
                activeConfirmNotificationId: null
            });
        }
        const foundIndex = selectedNotifications.indexOf(notification.NotificationId);
        if (foundIndex >= 0) {
            selectedNotifications.splice(foundIndex, 1);
        } else {
            selectedNotifications.push(notification.NotificationId);
        }
        this.setState({
            selectedNotifications
        });
    };

    dismissSelectedNotifications = () => {
        this.props.dismissNotifications(this.state.selectedNotifications);
        this.setState({
            selectedNotifications: []
        });
    };

    dismissNotification = id => {
        this.props.dismissNotifications([id]);
    };

    clearSelection = () => {
        this.setState({
            selectedNotifications: []
        });
    };

    goToNotificationSettings = e => {
        if (e) {
            e.stopPropagation();
        }
        this.props.router.updateQueryParams({ add: { notificationSettings: true } });
    };

    saveRef = c => { this.scrollArea = c; };

    render() {
        const notifications = this.state.activeTabId === 0
            ? this.props.activeNotifications.slice(0, this.state.activeNotificationsLimit)
            : this.props.dismissedNotifications;
        const areActionsHidden = !this.props.permissions.UpdateNotificationStatus
            || this.state.selectedNotifications.length > 0;

        return (
            <div className="sten-notifications-panel sten-content">
                {this.state.selectedNotifications.length === 0
                    ? (
                        <div className="sten-content__header">
                            <h1 className="sten-content__title flex-grow flex flex-center">
                                {translate('NOTIFICATIONS.TITLE')}
                                {this.props.activeNotifications.length > 0 && (
                                    <span className="sten-badge sten-badge--success sten-notifications-panel__badge">
                                        {this.props.activeNotifications.length}
                                    </span>
                                )}
                            </h1>
                            <div className="flex-shrink">
                                {this.props.onCloseClick && (
                                    <button
                                        className="btn-link icon icon-cog"
                                        onClick={this.goToNotificationSettings}
                                    />
                                )}
                            </div>
                            <div className="sten-content__vertical-separator" />
                            <div className="flex-shrink">
                                {this.props.onCloseClick && (
                                    <button
                                        className="btn-link icon icon-close"
                                        onClick={this.props.onCloseClick}
                                    />
                                )}
                            </div>
                        </div>
                    )
                    : (
                        <div className="sten-content__header flex-row">
                            <h1 className="sten-content__title flex-grow">
                                {`${this.state.selectedNotifications.length} ${translate('NOTIFICATIONS.SELECTED')}`}
                            </h1>
                            <div className="flex-shrink">
                                <button
                                    className="btn btn--secondary"
                                    onClick={this.dismissSelectedNotifications}
                                >
                                    <span className="btn__icon icon icon-close" />
                                    {translate('GLOBAL.DISMISS')}
                                </button>
                            </div>
                            <div className="flex-shrink">
                                <button
                                    className="btn-link icon icon-close"
                                    onClick={this.clearSelection}
                                />
                            </div>
                        </div>
                    )
                }
                <div className="sten-content__body sten-notifications-panel__body-fixed">
                    <TabSelector
                        tabItems={NotificationsPanel.getTabs()}
                        activeTabId={this.state.activeTabId}
                        onChange={this.setActiveTab}
                    />
                </div>
                <ScrollArea
                    onScroll={this.scrollHandler}
                    ref={this.saveRef}
                    key={this.state.activeTabId}
                    className="sten-content__body sten-notifications-panel__body-content"
                >
                    {this.state.activeTabId === 0 && notifications.length === 0 && (
                        <NotificationEmpty>
                            <span className="text-secondary">{translate('NOTIFICATIONS.NO_NOTIFICATIONS')}</span>
                        </NotificationEmpty>
                    )}
                    {this.state.activeTabId === 1 && notifications.length === 0 && (
                        <NotificationEmpty>
                            <span className="text-secondary">
                                {translate('NOTIFICATIONS.NO_DISMISSED_NOTIFICATIONS')}
                            </span>
                        </NotificationEmpty>
                    )}
                    {notifications.length > 0 && notifications.map(notification => (
                        <Notification
                            showReportedAsLink={this.props.permissions.GetReports}
                            key={notification.NotificationId}
                            notification={notification}
                            onConfirmToggle={this.toggleConfirmId}
                            isConfirmActive={this.state.activeConfirmNotificationId === notification.NotificationId}
                            onSelectToggle={this.props.permissions.UpdateNotificationStatusBulk && this.toggleSelected}
                            isSelected={this.state.selectedNotifications.indexOf(notification.NotificationId) >= 0}
                            hideActions={areActionsHidden}
                            sendReminderAllowed={this.props.permissions.SendMissingReportReminder}
                            onReminderConfirm={this.props.sendReminder}
                            onDismissConfirm={this.dismissNotification}
                        />
                    ))}
                </ScrollArea>
            </div>
        );
    }
}

NotificationsPanel.propTypes = {
    activeNotifications: PropTypes.arrayOf(PropTypes.object).isRequired,
    dismissNotifications: PropTypes.func.isRequired,
    dismissedNotifications: PropTypes.arrayOf(PropTypes.object).isRequired,
    getActiveNotifications: PropTypes.func.isRequired,
    getDismissedNotifications: PropTypes.func.isRequired,
    isLastPage: PropTypes.bool.isRequired,
    limit: PropTypes.number.isRequired,
    offset: PropTypes.number.isRequired,
    onCloseClick: PropTypes.func,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    router: TRouter.isRequired,
    sendReminder: PropTypes.func.isRequired
};

NotificationsPanel.defaultProps = {
    onCloseClick: undefined
};

function mapStateToProps(state) {
    return {
        activeNotifications: state.notificationsReducer.activeNotifications,
        dismissedNotifications: state.notificationsReducer.dismissedNotifications,
        isLastPage: state.notificationsReducer.isLastPage,
        limit: state.notificationsReducer.limit,
        offset: state.notificationsReducer.offset,
        permissions: state.userReducer.permissions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dismissNotifications: ids => dismissNotifications(dispatch, ids),
        getActiveNotifications: params => getActiveNotifications(dispatch, params),
        getDismissedNotifications: (params, addAction) => getDismissedNotifications(dispatch, params, addAction),
        sendReminder: id => sendReminder(dispatch, id)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NotificationsPanel));
