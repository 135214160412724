/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ScrollArea from 'components/scroll-area/scroll-area';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
/* selectors */
import { getReport, getWeatherCharts } from './weather-performance-report-selectors';
/* components */
import EmptyContent from 'components/empty-content/empty-content';
import ContentHeader from 'pages/user-pages/energy-management/reports/shared/content-header';
import WeatherPerformanceReportPerformanceSummaryPanel from './weather-performance-report-performance-summary-panel/weather-performance-report-performance-summary-panel';
import WeatherPerformanceReportCharterpartyPanel from './weather-performance-report-charterparty-panel/weather-performance-report-charterparty-panel';
import WeatherPerformanceReportLegend from './weather-performance-report-legend/weather-performance-report-legend';
import WeatherPerformanceReportChartPanel from './weather-performance-report-chart-panel/weather-performance-report-chart-panel';
import WeatherPerformanceReportPanel from './weather-performance-report-panel/weather-performance-report-panel';
/* actions */
import { generatePDF, resetAll } from './weather-performance-report-actions';
/* constants */
import {
    currentSituationConfig,
    lastReportedSituationConfig,
    speedAnalysisTableConfig,
    bunkerTableConfig,
    timeAnalysisTableConfig
} from './weather-performance-report-constants';
/* styles */
import './weather-performance-report.scss';

class WeatherPerformanceReport extends React.PureComponent {
    componentWillUnmount() {
        this.props.resetAll();
    }

    render() {
        const {
            VoyageDetails,
            PerformanceSummary,
            CurrentSituation,
            LastReportedSituation,
            CharterParties,
            SpeedAnalysis,
            TimeAnalysis,
            HfoBunkerAnalysis,
            MdoBunkerAnalysis
            // LngBunkerAnalysis
        } = this.props.report || {};
        const { weatherCharts } = this.props;
        return (
            <div className="sten-content sten-weather-performance-report">
                <ContentHeader />
                {!this.props.report
                    ? <EmptyContent>{translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.EMPTY_REPORT')}</EmptyContent>
                    : (
                        <div className="sten-content sten-content--has-footer">
                            <ScrollArea className="sten-content__body">
                                <div className="sten-content__section">
                                    <div className="row row--fluid">
                                        <div className="col-sm-16 col-xs-24">
                                            <h1 className="text-uppercase sten-weather-performance__vessel-title">
                                                {VoyageDetails && VoyageDetails.VesselTitle}
                                            </h1>
                                            <div className="sten-weather-performance__voyage-info">
                                                <label className="sten-panel__label">
                                                    {`${translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.VOYAGE')}: `}
                                                    <span className="sten-panel__label--value">
                                                        { ` ${VoyageDetails && VoyageDetails.VoyageNumber}` }
                                                    </span>
                                                </label>
                                                <label className="sten-panel__label">
                                                    {`${translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.FROM')}: `}
                                                    <span className="sten-panel__label--value">
                                                        {VoyageDetails && VoyageDetails.VoyageStart
                                                            ? ` ${TimeHelper.getFormatted(
                                                                VoyageDetails.VoyageStart
                                                            )}`
                                                            : ' -'}
                                                    </span>
                                                </label>
                                                <label className="sten-panel__label">
                                                    {`${translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.TO')}: `}
                                                    <span className="sten-panel__label--value">
                                                        {VoyageDetails && VoyageDetails.VoyageEnd
                                                            ? ` ${TimeHelper.getFormatted(VoyageDetails.VoyageEnd)}`
                                                            : ' -'}
                                                    </span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-sm-8 col-xs-24">
                                            <WeatherPerformanceReportLegend />
                                        </div>
                                    </div>
                                </div>
                                <div className="sten-content__section flex-row flex-row--lg">
                                    <div className="col-lg-12 col-xs-24">
                                        <WeatherPerformanceReportPerformanceSummaryPanel
                                            data={PerformanceSummary}
                                            comment={LastReportedSituation && LastReportedSituation.Comments}
                                        />
                                        <div className="flex-row flex-row--sm col-pad--lg">
                                            <div className="col-sm-12 col-xs-24">
                                                <WeatherPerformanceReportPanel
                                                    className="spread-v--sm"
                                                    config={currentSituationConfig}
                                                    data={CurrentSituation}
                                                    title={translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CURRENT_SITUATION_PANEL.TITLE')}
                                                />
                                            </div>
                                            <div className="col-sm-12 col-xs-24 col-pad--xs">
                                                <WeatherPerformanceReportPanel
                                                    className="spread-v--sm"
                                                    config={lastReportedSituationConfig}
                                                    data={LastReportedSituation}
                                                    title={translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.REPORTED_SITUATION_PANEL.TITLE')}
                                                />
                                            </div>
                                        </div>
                                        <WeatherPerformanceReportChartPanel
                                            chartData={weatherCharts}
                                            endDate={(VoyageDetails && VoyageDetails.VoyageEnd) || ''}
                                            startDate={(VoyageDetails && VoyageDetails.VoyageStart) || ''}
                                        />
                                    </div>
                                    <div className="col-lg-12 col-xs-24 col-pad--md">
                                        <WeatherPerformanceReportCharterpartyPanel
                                            data={CharterParties}
                                            voyageId={VoyageDetails.VoyageId}
                                        />
                                        <WeatherPerformanceReportPanel
                                            config={speedAnalysisTableConfig}
                                            data={SpeedAnalysis}
                                            title={translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.SPEED_ANALYSIS_PANEL.TITLE')}
                                        />
                                        <WeatherPerformanceReportPanel
                                            config={timeAnalysisTableConfig}
                                            data={TimeAnalysis}
                                            title={translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.TIME_ANALYSIS_PANEL.TITLE')}
                                        />
                                        {HfoBunkerAnalysis && (
                                            <WeatherPerformanceReportPanel
                                                config={bunkerTableConfig}
                                                data={HfoBunkerAnalysis}
                                                title={translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.BUNKER_ANALYSIS_PANEL.HFO_BUNKER_ANALYSIS')}
                                            />
                                        )}
                                        {MdoBunkerAnalysis && (
                                            <WeatherPerformanceReportPanel
                                                config={bunkerTableConfig}
                                                data={MdoBunkerAnalysis}
                                                title={translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.BUNKER_ANALYSIS_PANEL.MDO_BUNKER_ANALYSIS')}
                                            />
                                        )}
                                        {/* {LngBunkerAnalysis && (
                                            <WeatherPerformanceReportPanel
                                                config={bunkerTableConfig}
                                                data={LngBunkerAnalysis}
                                                title={translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.BUNKER_ANALYSIS_PANEL.LNG_BUNKER_ANALYSIS')}
                                            />
                                        )} */}
                                    </div>
                                </div>
                            </ScrollArea>
                            <footer className="sten-content__footer flex-row">
                                <div className="flex-grow" />
                                <div className="flex-shrink">
                                    <button onClick={this.props.generatePDF} className="btn btn--primary">
                                        <span className="btn__icon icon icon-export" />
                                        {translate('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.GENERATE_PDF')}
                                    </button>
                                </div>
                            </footer>
                        </div>
                    )}
            </div>
        );
    }
}

WeatherPerformanceReport.propTypes = {
    generatePDF: PropTypes.func.isRequired,
    report: PropTypes.objectOf(PropTypes.any),
    resetAll: PropTypes.func.isRequired,
    weatherCharts: PropTypes.objectOf(PropTypes.any)
};

WeatherPerformanceReport.defaultProps = {
    report: null,
    weatherCharts: null
};

function mapStateToProps(state) {
    return {
        report: getReport(state),
        weatherCharts: getWeatherCharts(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        generatePDF: () => generatePDF(dispatch),
        resetAll: () => resetAll(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(WeatherPerformanceReport);
