import React from 'react';
import PropTypes from 'prop-types';
/* styles */
import './image-button.scss';

class ImageButton extends React.PureComponent {
    getClassName() {
        let className = 'sten-image-button';
        if (this.props.className) {
            className += ` ${this.props.className}`;
        }
        if (this.props.isActive) {
            className += ' sten-image-button--active';
        }
        return className;
    }

    render() {
        const imageButtonStyle = {};
        if (this.props.imageSrc) {
            imageButtonStyle.backgroundImage = `url("${this.props.imageSrc}")`;
        }
        return (
            <div className={this.getClassName()} style={imageButtonStyle} onClick={this.props.onClick}>
                {this.props.text && (
                    <span className="sten-image-button__text">{this.props.text}</span>
                )}
            </div>
        );
    }
}

ImageButton.propTypes = {
    className: PropTypes.string,
    imageSrc: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string
};

ImageButton.defaultProps = {
    className: '',
    imageSrc: '',
    isActive: false,
    text: ''
};

export default ImageButton;
