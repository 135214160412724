import PropTypes from 'prop-types';

export const DateShape = {
    day: PropTypes.number,
    month: PropTypes.number,
    year: PropTypes.number,
    isInvalid: PropTypes.bool
};

export const MonthShape = {
    month: PropTypes.number.isRequired,
    year: PropTypes.number.isRequired,
    isInvalid: PropTypes.bool
};

export const YearShape = {
    year: PropTypes.number.isRequired,
    isInvalid: PropTypes.bool
};

export const TimeShape = {
    hour: PropTypes.number.isRequired,
    minute: PropTypes.number.isRequired,
    second: PropTypes.number.isRequired,
    isInvalid: PropTypes.bool
};

export const DateTimeShape = {
    day: PropTypes.number,
    month: PropTypes.number,
    year: PropTypes.number,
    hour: PropTypes.number,
    minute: PropTypes.number,
    second: PropTypes.number,
    isDateInvalid: PropTypes.bool,
    isTimeInvalid: PropTypes.bool
};

export const TimeConfigFieldShape = {
    hidden: PropTypes.bool,
    disabled: PropTypes.bool
};

export const FormatShape = {
    regex: PropTypes.instanceOf(RegExp),
    matches: PropTypes.arrayOf(PropTypes.string)
};
