import getFontSize from 'utils/helpers/cached-font-size';

const chartColors = [
    '#ffffff',
    '#25d7af'
];

const colorChartBg = '#0f3646';
const colorChartPlotBg = '#0f3646';
const colorXAxisGridLine = 'rgba(255, 255, 255, 0.1)';
const colorSeriesGradient = 'rgba(0, 0, 0, 0.3)';

export const getConfig = () => {
    const rem = getFontSize();
    const fsm = rem / 16;

    return {
        chart: {
            spacing: [rem, rem, 0, rem],
            backgroundColor: colorChartBg,
            plotBackgroundColor: colorChartPlotBg,
            zoomType: false
        },
        scrollbar: {
            enabled: true,
            showFull: false
        },
        xAxis: {
            minRange: 0,
            type: 'category',
            gridLineColor: colorXAxisGridLine,
            gridZIndex: 1,
            tickLength: 0,
            labels: {
                enabled: false
            }
        },
        yAxis: {
            tickInterval: 1
        },
        series: [
            {
                name: 'Average Ballast Speed',
                type: 'area',
                color: chartColors[0],
                showInLegend: false,
                lineWidth: 2 * fsm,
                data: [],
                zIndex: 2,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            },
            {
                name: 'Average Laden Speed',
                type: 'area',
                color: chartColors[1],
                showInLegend: false,
                lineWidth: 2 * fsm,
                data: [],
                zIndex: 2,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            }
        ],
        tooltip: {
            pointFormat:
                '<div class="sten-chart-tooltip__point" style="color: {series.color}">'
                + '{series.name}: {point.title}</div>'
        }
    };
};
