import CoreApi from './core-api';

class InspectionsService {
    constructor() {
        this.resourceUrl = 'Inspections';
    }

    getInspections() {
        return CoreApi.get(`${this.resourceUrl}`)
            .then(response => response.data, () => null);
    }

    uploadFiles(data) {
        return CoreApi.post(`${this.resourceUrl}/Upload`, data)
            .then(response => response.data, () => null);
    }

    downloadFile(fileId) {
        return CoreApi.downloadFile(`${this.resourceUrl}/Download/${fileId}`)
            .then(response => response, () => null);
    }

    getInspection(id) {
        return CoreApi.get(`${this.resourceUrl}/${id}`)
            .then(response => response.data, () => null);
    }

    addInspection(data) {
        return CoreApi.post(`${this.resourceUrl}`, data)
            .then(response => response, () => null);
    }

    updateInspection(id, data) {
        return CoreApi.put(`${this.resourceUrl}/${id}`, data)
            .then(response => response, () => null);
    }

    deleteInspection(id) {
        return CoreApi.remove(`${this.resourceUrl}/${id}`)
            .then(() => true, () => false);
    }

    getInspectionTypes() {
        return CoreApi.get(`${this.resourceUrl}/Types`)
            .then(response => response.data, () => null);
    }

    getInspectionCompanies() {
        return CoreApi.get(`${this.resourceUrl}/Companies`)
            .then(response => response.data, () => null);
    }

    getInspectionActivities() {
        return CoreApi.get(`${this.resourceUrl}/Activities`)
            .then(response => response.data, () => null);
    }
}

export default new InspectionsService();
