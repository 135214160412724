export const ActionTypes = {
    ENERGY_MANAGEMENT_REPORTS_SET_MOUNTED: 'ENERGY_MANAGEMENT_REPORTS_SET_MOUNTED',
    ENERGY_MANAGEMENT_REPORTS_TOGGLE_SIDEBAR: 'ENERGY_MANAGEMENT_REPORTS_TOGGLE_SIDEBAR',
    ENERGY_MANAGEMENT_REPORTS_TOGGLE_RANGE_SELECTOR: 'ENERGY_MANAGEMENT_REPORTS_TOGGLE_RANGE_SELECTOR',
    ENERGY_MANAGEMENT_REPORTS_GET_TYPES: 'ENERGY_MANAGEMENT_REPORTS_GET_TYPES',
    ENERGY_MANAGEMENT_REPORTS_SET_TYPES: 'ENERGY_MANAGEMENT_REPORTS_SET_TYPES',
    ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_TYPE: 'ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_TYPE',
    ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_VESSEL: 'ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_VESSEL',
    ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_VOYAGE: 'ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_VOYAGE',
    ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_RANGE: 'ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_RANGE',
    ENERGY_MANAGEMENT_REPORTS_SET_INCLUSION_PRESETS: 'ENERGY_MANAGEMENT_REPORTS_SET_INCLUSION_PRESETS',
    ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_INCLUSION_PRESET: 'ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_INCLUSION_PRESET',
    ENERGY_MANAGEMENT_REPORTS_SET_INCLUSION_VALUE: 'ENERGY_MANAGEMENT_REPORTS_SET_INCLUSION_VALUE',
    ENERGY_MANAGEMENT_REPORTS_SET_INCLUSIONS: 'ENERGY_MANAGEMENT_REPORTS_SET_INCLUSIONS',
    ENERGY_MANAGEMENT_REPORTS_RESET_INCLUSIONS: 'ENERGY_MANAGEMENT_REPORTS_RESET_INCLUSIONS',
    ENERGY_MANAGEMENT_REPORTS_GENERATE_PREVIEW: 'ENERGY_MANAGEMENT_REPORTS_GENERATE_PREVIEW',
    ENERGY_MANAGEMENT_REPORTS_SET_PREVIEW_GENERATED: 'ENERGY_MANAGEMENT_REPORTS_SET_PREVIEW_GENERATED',
    ENERGY_MANAGEMENT_REPORTS_RESET_UPDATED_REPORTS: 'ENERGY_MANAGEMENT_REPORTS_RESET_UPDATED_REPORTS',
    ENERGY_MANAGEMENT_REPORTS_RESET_ALL: 'ENERGY_MANAGEMENT_REPORTS_RESET_ALL',
    ENERGY_MANAGEMENT_REPORTS_GET_VESSEL_FILTERING_OPTIONS: 'ENERGY_MANAGEMENT_REPORTS_GET_VESSEL_FILTERING_OPTIONS',
    ENERGY_MANAGEMENT_REPORTS_UPDATE_FILTERS: 'ENERGY_MANAGEMENT_REPORTS_UPDATE_FILTERS',
    ENERGY_MANAGEMENT_REPORTS_SET_CUSTOM_FLEETS: 'ENERGY_MANAGEMENT_REPORTS_SET_CUSTOM_FLEETS',
    ENERGY_MANAGEMENT_REPORTS_SET_INCLUSION_CP_VALUE: 'ENERGY_MANAGEMENT_REPORTS_SET_INCLUSION_CP_VALUE',
    ENERGY_MANAGEMENT_REPORTS_GET_COMPANIES: 'ENERGY_MANAGEMENT_REPORTS_GET_COMPANIES',
    ENERGY_MANAGEMENT_REPORTS_SET_COMPANIES: 'ENERGY_MANAGEMENT_REPORTS_SET_COMPANIES',
    ENERGY_MANAGEMENT_REPORTS_SET_SEGMENT_VESSELS_FLEETS: 'ENERGY_MANAGEMENT_REPORTS_SET_SEGMENT_VESSELS_FLEETS'
};

export function setMounted(dispatch, isMounted) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_MOUNTED,
        isMounted
    });
}

export function setCPSpeedValue(dispatch, key, value) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_INCLUSION_CP_VALUE,
        key,
        value
    });
}

export function toggleSidebar(dispatch, flag) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_TOGGLE_SIDEBAR,
        flag
    });
}

export function toggleRangeSelector(dispatch, flag) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_TOGGLE_RANGE_SELECTOR,
        flag
    });
}

export function getReportTypes(dispatch) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_GET_TYPES
    });
}

export function getCompanies(dispatch) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_GET_COMPANIES
    });
}

export function updateFilters(dispatch, filters) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_UPDATE_FILTERS,
        filters
    });
}

export function getVesselFilteringOptions(dispatch) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_GET_VESSEL_FILTERING_OPTIONS
    });
}

export function setSelectedReportType(dispatch, reportType) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_TYPE,
        reportType
    });
}

export function setSelectedVessel(dispatch, vessel) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_VESSEL,
        vessel
    });
}

export function setSelectedVoyage(dispatch, voyage) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_VOYAGE,
        voyage
    });
}

export function setSelectedRange(dispatch, range) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_RANGE,
        range
    });
}

export function setSelectedInclusionPreset(dispatch, preset) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_SELECTED_INCLUSION_PRESET,
        preset
    });
}

export function setInclusionValue(dispatch, field, value) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_SET_INCLUSION_VALUE,
        field,
        value
    });
}

export function resetInclusions(dispatch) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_RESET_INCLUSIONS
    });
}

export function generateReportPreview(dispatch, isRecalculate) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_GENERATE_PREVIEW,
        isRecalculate
    });
}

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_REPORTS_RESET_ALL
    });
}
