import { takeEvery, put } from 'redux-saga/effects';
/* actions */
import { ActionTypes } from './test-actions';

function* getTest(action) {
    yield put({ type: ActionTypes.TEST_SET, test: action.test });
}

export default function* testSaga() {
    yield takeEvery(ActionTypes.TEST_GET, getTest);
}
