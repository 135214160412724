import { takeEvery, put, select, all } from 'redux-saga/effects';
import { ActionTypes } from './position-list-filters-actions';
/* Services */
import VesselService from 'services/core-api/vessel-service';
import AreaService from 'services/core-api/area-service';
import FleetManagementService from 'services/core-api/fleet-management-service';
/* helpers */
import { getCustomFleets } from 'utils/helpers/fleet-helper';

function* getCompanies() {
    let companies = yield select(state => state.positionListFiltersReducer.companies);
    if (companies.length > 0) {
        return;
    }
    companies = yield VesselService.getCompanies();
    if (companies && companies.length > 0) {
        yield put({ type: ActionTypes.POSITION_LIST_FILTERS_SET_COMPANIES, companies });
    }
}

function* getRegions() {
    let regions = yield select(state => state.positionListFiltersReducer.regions);

    if (regions.length > 0) {
        return;
    }

    regions = yield AreaService.getAreasByTypeId(
        [1], // only load/discharge areas
        true // shows hidden
    );

    yield put({ type: ActionTypes.POSITION_LIST_FILTERS_SET_REGIONS, regions });
}

function* getFleets() {
    const permissions = yield select(state => state.userReducer.permissions);
    const fleetRequests = [];
    if (permissions.ShowFleetManagementPrivateGroups) {
        fleetRequests.push(FleetManagementService.getPrivate());
    }
    if (permissions.ShowFleetManagementPublicGroups) {
        fleetRequests.push(FleetManagementService.getPublic());
    }
    const vesselFleetsResponses = yield all(fleetRequests);
    const fleets = getCustomFleets(vesselFleetsResponses);

    yield put({ type: ActionTypes.POSITION_LIST_FILTERS_SET_FLEETS, fleets });
}

export default function* positionListSaga() {
    yield takeEvery(ActionTypes.POSITION_LIST_FILTERS_GET_COMPANIES, getCompanies);
    yield takeEvery(ActionTypes.POSITION_LIST_FILTERS_GET_REGIONS, getRegions);
    yield takeEvery(ActionTypes.POSITION_LIST_FILTERS_GET_FLEETS, getFleets);
}
