import { takeEvery, put, select, all } from 'redux-saga/effects';
/* router */
import { appRoutes } from 'app-router';
/* actions */
import { ActionTypes } from './user-actions';
import { ActionTypes as EuEtsActionTypes } from 'components/euets/euets-actions';
/* services */
import ConfigService from 'services/config-api/config-service';
import UserService from 'services/core-api/user-service';
import CurrencyService from 'services/mt-api/currency-service';
import TimeService from 'services/core-api/time-service';

function* getTimeFormats() {
    const timeFormats = yield TimeService.getDateTimeFormats();
    if (timeFormats && timeFormats.length) {
        return timeFormats.map(format => ({
            Id: format.SupportedDateTimeFormatId,
            WebClientValue: format.SupportedDateTimeFormatWebClientValue,
            WebServerValue: format.SupportedDateTimeFormatWebServerValue
        }));
    }
    return null;
}

const getRoutePermissions = (featureToggles = {}) => ([
    { route: appRoutes.Admin.CompanyManagement, key: 'AdminCompanyManagement' },
    {
        route: appRoutes.EnergyManagement.Root,
        key: 'GetEnergyManagementReports',
        restricted: !featureToggles.showEnergyManagement
    },
    {
        route: appRoutes.MarketOverview.PortCallsAnalytics,
        key: 'GetPortCalls',
        restricted: !featureToggles.showPortCallAnalytics
    },
    { route: appRoutes.Admin.UserManagement, key: 'MenuAdminUserManagement' },
    { route: appRoutes.Admin.VesselManagement, key: 'MenuAdminVesselManagement' },
    { route: appRoutes.PortManagement, key: 'PortManagementGetPorts', restricted: !featureToggles.showPortManagement },
    {
        route: appRoutes.Vessel.FleetManagement,
        key: 'MenuVesselsManagement',
        restricted: !featureToggles.showFleetManagement
    },
    { route: appRoutes.Vessel.OperatorsDiary, restricted: !featureToggles.showOperatorsDiary },
    { route: appRoutes.Vessel.PositionList, key: 'MenuPositionList', restricted: !featureToggles.showPositionList },
    { route: appRoutes.Vessel.Inspections, key: 'Inspections', restricted: !featureToggles.showInspections },
    {
        route: appRoutes.Vessel.VoyagePerformance,
        key: 'GetVoyagePerformance',
        restricted: !featureToggles.showVoyagePerformance
    },
    { route: appRoutes.Routing.DistanceCalculator, key: 'ShowDistanceCalc' },
    {
        route: appRoutes.EmissionEstimator,
        key: 'ShowEmissionEstimator',
        restricted: !featureToggles.showEmissionEstimator
    },
    { route: appRoutes.MarketIntel, key: 'ShowMarketPredictions' },
    { route: appRoutes.FleetDashboard, key: 'ShowPoolDashboard', restricted: !featureToggles.showFleetDashboard },
    {
        route: appRoutes.Routing.WeatherRouting,
        key: 'GetWeatherRoutingV2Voyages',
        restricted: !featureToggles.showWeatherRouting
    }
]);

function* getUser() {
    const res = yield all({
        user: UserService.get(),
        timeFormats: getTimeFormats(),
        supportedCurrencies: getSupportedCurrencies()
    });
    if (res) {
        const restrictedRoutes = [];
        const routePermissions = getRoutePermissions(ConfigService.featureToggles);
        routePermissions.forEach((permission) => {
            if ((permission.key && res.user.UserPermissions[permission.key] === false) || permission.restricted) {
                restrictedRoutes.push(permission);
            }
        });
        yield put({
            type: ActionTypes.USER_SET,
            user: res.user,
            restrictedRoutes,
            permissions: res.user.UserPermissions,
            settings: res.user.UserSettings,
            timeFormats: res.timeFormats,
            supportedCurrencies: res.supportedCurrencies
        });
        yield put({ type: EuEtsActionTypes.EU_ETS_GET_PRICE, currency: res.user.UserSettings.Currency });
    }
}

function* getSupportedCurrencies() {
    if (ConfigService.featureToggles.showEuEts) {
        const res = yield CurrencyService.getSupportedCurrencies();
        if (res) {
            return res.currencies.map(item => ({
                Default: item.default,
                Name: item.name,
                Symbol: item.symbol,
                Ticker: item.ticker
            }));
        }
    }
    return null;
}

function* updateUserSettings(action) {
    const updatedSettings = yield UserService.updateSettings(action.settings);
    if (updatedSettings) {
        yield put({
            type: ActionTypes.USER_SETTINGS_SET,
            settings: updatedSettings,
            settingUpdated: action.settingUpdated,
            newSettings: action.settings
        });
    }
}

function* toggleUserSetting(action) {
    const settings = yield select(state => state.userReducer.settings);
    const newSettings = {};
    newSettings[action.settingName] = settings[action.settingName].slice();
    const valueIndex = newSettings[action.settingName].indexOf(action.value);
    if (valueIndex < 0) {
        newSettings[action.settingName].push(action.value);
    } else {
        newSettings[action.settingName].splice(valueIndex, 1);
    }
    const updatedSettings = yield UserService.updateSettings(newSettings);
    if (updatedSettings) {
        yield put({
            type: ActionTypes.USER_SETTINGS_SET,
            settings: updatedSettings,
            settingUpdated: action.settingUpdated,
            newSettings
        });
    }
}

export default function* userSaga() {
    yield takeEvery(ActionTypes.USER_GET, getUser);
    yield takeEvery(ActionTypes.USER_SETTINGS_UPDATE, updateUserSettings);
    yield takeEvery(ActionTypes.USER_SETTINGS_TOGGLE, toggleUserSetting);
}
