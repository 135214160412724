import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* components */
import ItineraryEdit from 'components/itinerary/itinerary-edit';
import ScrollArea from 'components/scroll-area/scroll-area';
import Validation from 'components/validation/validation';
/* services */
import OperatorsDiaryService from 'services/core-api/operators-diary-service';
/* actions */
import { voyageUpdated } from '../operators-diary-actions';
/* selectors */
import { convertVoyageItinerary } from '../operators-diary-helpers';
/* styles */
import './voyage-form.scss';

const emptyArray = [];

class VoyageForm extends PureComponent {
    state = {
        voyage: null,
        itinerary: emptyArray
    };

    componentMounted = false;

    componentDidMount() {
        this.componentMounted = true;
        if (this.props.voyageId) {
            this.getResources(this.props.voyageId);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.voyageId && this.props.voyageId !== prevProps.voyageId) {
            this.getResources(this.props.voyageId);
        }
    }

    componentWillUnmount() {
        this.componentMounted = false;
    }

    getResources = (voyageId) => {
        OperatorsDiaryService.getVoyageById(voyageId).then(res => {
            if (this.componentMounted) {
                this.setState({ voyage: res });
            }
        });
        OperatorsDiaryService.getItineraryByVoyageId(voyageId).then(res => {
            if (this.componentMounted) {
                this.setState({ itinerary: convertVoyageItinerary(res) });
            }
        });
    }

    handleItineraryChange = (itinerary) => this.setState({ itinerary });

    handleSubmit = () => {
        const data = {
            Itinerary: this.state.itinerary.flatMap(item => item.Activities.map(activity => ({
                ItineraryId: activity.Id,
                Etb: activity.Etb,
                EtbStatus: activity.EtbStatus,
                TagIds: activity.Tags.map(tag => tag.Id)
            })))
        };
        OperatorsDiaryService.updateItinerary(data).then(res => {
            if (this.componentMounted) {
                this.props.onUpdate(res);
                this.closeForm();
            }
        });
    };

    closeForm = () => this.props.router.navigate(`${appRoutes.Vessel.OperatorsDiary}/${this.props.voyageId}`);

    render() {
        const { voyage, itinerary } = this.state;

        if (!voyage) {
            return (
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <div className="flex-grow" />
                        <div className="flex-shrink">
                            <button className="btn-link icon icon-close" onClick={this.props.onCloseClick} />
                        </div>
                    </div>
                    <div className="sten-content__body" />
                </div>
            );
        }

        return (
            <Validation.Form
                onSubmit={this.handleSubmit}
                className="sten-voyage-form sten-content sten-content--has-footer"
            >
                <div className="sten-content__header flex-row">
                    <div className="flex-grow">
                        <h1 className="sten-content__title">{voyage.VesselName}</h1>
                        <p className="sten-content__subtitle">
                            {t('VOYAGE_INFO.SUBTITLE', {
                                voyageNumber: voyage.VoyageNumber,
                                voyagePortInfo: voyage.VoyagePortInfo
                            })}
                        </p>
                    </div>
                    <div className="flex-shrink">
                        <button type="button" className="btn-link icon icon-close" onClick={this.props.onCloseClick} />
                    </div>
                </div>
                <ScrollArea className="sten-content__body">
                    <div className="sten-content__section">
                        <ItineraryEdit
                            itinerary={itinerary}
                            legTags={this.props.tags.leg}
                            etbStatuses={this.props.etbStatuses}
                            onChange={this.handleItineraryChange}
                        />
                    </div>
                </ScrollArea>
                <footer className="sten-content__footer flex-row">
                    <div className="col-12">
                        <button
                            className="btn btn--secondary col-24"
                            type="button"
                            onClick={this.closeForm}
                        >
                            {t('GLOBAL.CANCEL')}
                        </button>
                    </div>
                    <div className="col-12">
                        <Validation.Button
                            disabled={this.props.voyageId && !this.state.voyage}
                            className="btn btn--primary col-24"
                        >
                            {t('GLOBAL.SAVE')}
                        </Validation.Button>
                    </div>
                </footer>
            </Validation.Form>
        );
    }
}

VoyageForm.propTypes = {
    etbStatuses: PropTypes.arrayOf(PropTypes.object).isRequired,
    onCloseClick: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    tags: PropTypes.shape({
        leg: PropTypes.arrayOf(PropTypes.object),
        fixture: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    voyageId: PropTypes.number
};

VoyageForm.defaultProps = {
    voyageId: null
};

function mapDispatchToProps(dispatch) {
    return {
        onUpdate: voyage => voyageUpdated(dispatch, voyage)
    };
}

export default connect(null, mapDispatchToProps)(withRouter(VoyageForm));
