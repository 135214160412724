export default [
    {
        Date: '2019-10-01T00:00:00Z',
        AverageSpeedBallast: 10.93125,
        AverageSpeedLaden: 11.075
    },
    {
        Date: '2019-11-01T00:00:00Z',
        AverageSpeedBallast: null,
        AverageSpeedLaden: 11.461538461538462
    },
    {
        Date: '2019-12-01T00:00:00Z',
        AverageSpeedBallast: 14.216666666666667,
        AverageSpeedLaden: 12.9625
    },
    {
        Date: '2020-01-01T00:00:00Z',
        AverageSpeedBallast: 13.991666666666665,
        AverageSpeedLaden: 13.05
    },
    {
        Date: '2020-02-01T00:00:00Z',
        AverageSpeedBallast: 14.077777777777778,
        AverageSpeedLaden: 12.5
    },
    {
        Date: '2020-03-01T00:00:00Z',
        AverageSpeedBallast: 14.5,
        AverageSpeedLaden: 13.421428571428571
    },
    {
        Date: '2020-04-01T00:00:00Z',
        AverageSpeedBallast: 13.538461538461538,
        AverageSpeedLaden: 12.177777777777777
    },
    {
        Date: '2020-05-01T00:00:00Z',
        AverageSpeedBallast: 13.26923076923077,
        AverageSpeedLaden: 13.311111111111112
    },
    {
        Date: '2020-06-01T00:00:00Z',
        AverageSpeedBallast: 13.830769230769231,
        AverageSpeedLaden: 12.05
    },
    {
        Date: '2020-07-01T00:00:00Z',
        AverageSpeedBallast: 10.675,
        AverageSpeedLaden: 12.283333333333333
    },
    {
        Date: '2020-08-01T00:00:00Z',
        AverageSpeedBallast: 10.675,
        AverageSpeedLaden: 13.225
    },
    {
        Date: '2020-09-01T00:00:00Z',
        AverageSpeedBallast: 12.114285714285714,
        AverageSpeedLaden: null
    },
    {
        Date: '2020-10-01T00:00:00Z',
        AverageSpeedBallast: 11.4,
        AverageSpeedLaden: 12.793333333333333
    },
    {
        Date: '2020-11-01T00:00:00Z',
        AverageSpeedBallast: null,
        AverageSpeedLaden: 11.614285714285714
    },
    {
        Date: '2020-12-01T00:00:00Z',
        AverageSpeedBallast: 9.48846153846154,
        AverageSpeedLaden: null
    },
    {
        Date: '2021-01-01T00:00:00Z',
        AverageSpeedBallast: 12.842857142857142,
        AverageSpeedLaden: 11.315384615384616
    },
    {
        Date: '2021-02-01T00:00:00Z',
        AverageSpeedBallast: 13.8,
        AverageSpeedLaden: 13.1
    },
    {
        Date: '2021-03-01T00:00:00Z',
        AverageSpeedBallast: 14.4,
        AverageSpeedLaden: 12.323636363636362
    },
    {
        Date: '2021-04-01T00:00:00Z',
        AverageSpeedBallast: 14.08,
        AverageSpeedLaden: 14.6125
    },
    {
        Date: '2021-05-01T00:00:00Z',
        AverageSpeedBallast: 13.0,
        AverageSpeedLaden: 12.566666666666666
    },
    {
        Date: '2021-06-01T00:00:00Z',
        AverageSpeedBallast: 14.775,
        AverageSpeedLaden: 13.058823529411766
    },
    {
        Date: '2021-07-01T00:00:00Z',
        AverageSpeedBallast: 13.0,
        AverageSpeedLaden: 12.292307692307691
    },
    {
        Date: '2021-08-01T00:00:00Z',
        AverageSpeedBallast: null,
        AverageSpeedLaden: 11.9
    },
    {
        Date: '2021-09-01T00:00:00Z',
        AverageSpeedBallast: 9.5,
        AverageSpeedLaden: 12.466666666666667
    }
];
