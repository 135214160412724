import NotificationApi from './notification-api';

class NotificationRulesService {
    constructor() {
        this.resourceUrl = 'NotificationRules';
    }

    Settings = {
        get: () => {
            return NotificationApi.get(`${this.resourceUrl}/ByUser`)
                .then(response => response.data, () => null);
        },
        updateById: (id, data) => {
            return NotificationApi.put(`${this.resourceUrl}/${id}`, data)
                .then(() => true, () => null);
        }
    }
}

export default new NotificationRulesService();
