import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import { formatValue } from 'utils/helpers/info-helper';
/* components */
import EmptyContent from 'components/empty-content/empty-content';
/* styles */
import './voyage-performance-consumption-table.scss';
/* constants */
import { consumptionTableColumns, consumptionTableRows } from '../../voyage-performance-constants';

const t = (key, values) => translate(`VOYAGE_PERFORMANCE.PERFORMANCE_PANEL.${key}`, values);

class ConsumptionTable extends React.PureComponent {
    getConsumptionRows = (data) => consumptionTableRows.map(row => (
        <tr key={row.key}>
            <td>
                <div className="flex flex-center">
                    <div className="flex-shrink">
                        {row.label}
                    </div>
                    {row.tooltip && (
                        <div className="flex-grow">
                            <span
                                className="
                                    icon icon-info-circle sten-voyage-performance-consumption-table__tooltip-icon"
                                title={row.tooltip}
                            />
                        </div>
                    )}
                </div>
            </td>
            {consumptionTableColumns.map(col => (
                <td key={col}>{formatValue(data, row, `${col}.${row.key}`)}</td>
            ))}
        </tr>
    ));

    render() {
        return (
            <div className="sten-panel sten-panel--sub sten-voyage-performance-consumption-table">
                {!this.props.consumption ? <EmptyContent className="flex-grow" /> : (
                    <table className="sten-table sten-table--xs">
                        <thead>
                            <tr>
                                <th rowSpan="2">{t('VALUE')}</th>
                                <th colSpan="3" className="text-center">{t('AT_SEA')}</th>
                                <th className="text-center">{t('IN_PORT')}</th>
                            </tr>
                            <tr>
                                <th className="text-center">{t('TOTAL')}</th>
                                <th className="text-center">{t('BALLAST')}</th>
                                <th className="text-center">{t('LADEN')}</th>
                                <th className="text-center">{t('TOTAL')}</th>
                            </tr>
                        </thead>
                        <tbody>{this.getConsumptionRows(this.props.consumption)}</tbody>
                    </table>
                )}
            </div>
        );
    }
}

ConsumptionTable.propTypes = {
    consumption: PropTypes.objectOf(PropTypes.any)
};

ConsumptionTable.defaultProps = {
    consumption: null
};

export default ConsumptionTable;
