import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ScrollArea from 'components/scroll-area/scroll-area';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import { formatNumber } from 'utils/helpers/info-helper';
/* components */
import Modal from 'components/modal/modal';
import Accordion from 'components/accordion/accordion';
import Select from 'components/select/select';
import Checkbox from 'components/checkbox/checkbox';
/* actions */
import {
    getVesselRelationships,
    getOptions,
    saveRelationships,
    clearRelationships,
    closeAssignToFleetModal
} from './assign-to-fleet-modal-actions';
/* selectors */
import {
    defaultCommercialContract,
    defaultResponsibleOffice,
    defaultStatus,
    commercialContractsById,
    responsibleOfficesById,
    statusesById,
    commercialContractsSelector,
    responsibleOfficesSelector,
    statusesSelector,
    fleetsById
} from './assign-to-fleet-modal-selectors';
/* styles */
import './assign-to-fleet-modal.scss';

export class AssignToFleetModal extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            tempFleetsSettings: []
        };
    }

    componentDidMount() {
        this.props.getOptions();
    }

    componentDidUpdate(prevProps) {
        if (this.props.isAssignToFleetModalShown && !prevProps.isAssignToFleetModalShown) {
            this.props.getVesselRelationships(this.props.imo);
        }
        if (this.props.isAssignToFleetModalShown) {
            if (prevProps.fleet !== this.props.fleet) {
                this.toggleAssignVesselToThisFleet(this.props.fleet);
            }
        }
    }

    static getDerivedStateFromProps(props, state) {
        const relationships = props.relationships[props.imo];
        if (relationships && relationships !== state.oldRelationships) {
            const tempFleetsSettings = props.fleets.map((fleet) => {
                const relationship = relationships.find((r) => r.CompanyId === fleet.Id);
                const status = relationship && relationship.StatusId
                    ? props.statusesById[relationship.StatusId]
                    : props.defaultStatus;
                const commercialContract = relationship && relationship.CommercialContractId
                    ? props.commercialContractsById[relationship.CommercialContractId]
                    : props.defaultCommercialContract;
                const responsibleOffice = relationship && relationship.ResponsibleOfficeId
                    ? props.responsibleOfficesById[relationship.ResponsibleOfficeId]
                    : props.defaultResponsibleOffice;
                const shouldAssignVesselToThisFleet = fleet.Id === props.fleet.Id
                    || !!relationship;

                return {
                    Id: fleet.Id,
                    shouldAssignVesselToThisFleet,
                    status,
                    commercialContract,
                    responsibleOffice
                };
            });
            return {
                tempFleetsSettings,
                oldRelationships: relationships
            };
        }
        return null;
    }

    saveRelationships = () => {
        const relationships = this.state.tempFleetsSettings.filter(
            (tempFleetSettings => tempFleetSettings.shouldAssignVesselToThisFleet)
        ).map((tempFleetSettings) => ({
            CompanyId: tempFleetSettings.Id,
            StatusId: tempFleetSettings.status.Id,
            CommercialContractId: tempFleetSettings.commercialContract.Id,
            ResponsibleOfficeId: tempFleetSettings.responsibleOffice.Id
        }));
        this.props.saveRelationships(this.props.imo, relationships);
        this.closeAndDiscardTemp();
    };

    closeAndDiscardTemp = () => {
        this.props.closeAssignToFleetModal();
        this.setState({
            tempFleetsSettings: []
        });
    };

    handleFleetResponsibleOfficeChange = (fleet, responsibleOffice) => {
        this.updateSettingForTempFleet(fleet.Id, 'responsibleOffice', responsibleOffice);
    };

    handleFleetCommercialContractChange = (fleet, commercialContract) => {
        this.updateSettingForTempFleet(fleet.Id, 'commercialContract', commercialContract);
    };

    handleFleetStatusChange = (fleet, status) => {
        this.updateSettingForTempFleet(fleet.Id, 'status', status);
    };

    updateSettingForTempFleet = (fleetId, key, value) => {
        this.setState({
            tempFleetsSettings:
                this.state.tempFleetsSettings.map((tempFleetSettings) => (fleetId === tempFleetSettings.Id
                    ? { ...tempFleetSettings, [key]: value }
                    : tempFleetSettings
                ))
        });
    };

    toggleAssignVesselToThisFleet = (fleet) => {
        if (!fleet) { return; }
        this.updateSettingForTempFleet(
            fleet.Id,
            'shouldAssignVesselToThisFleet',
            !fleet.shouldAssignVesselToThisFleet
        );
    };

    renderAccordionHeader = (fleet) => (
        <div className="sten-assign-to-fleet__modal-accordion-header">
            <h4 className="text-uppercase">{this.props.fleetsById[fleet.Id].Name}</h4>
            {fleet.shouldAssignVesselToThisFleet && (
                <div key="badge" className="sten-badge">{translate('ASSIGN_TO_FLEET_MODAL.ASSIGNED')}</div>
            )}
        </div>
    );

    renderFleetAccordion = (fleet) => {
        return (
            <Accordion
                key={fleet.Id}
                header={this.renderAccordionHeader(fleet)}
                isCollapsed={this.props.fleet && this.props.fleet.Id !== fleet.Id}
            >
                <div className="sten-content__section">
                    <div className="form-row">
                        <Checkbox
                            onChange={this.toggleAssignVesselToThisFleet.bind(this, fleet)}
                            isChecked={fleet.shouldAssignVesselToThisFleet}
                            name="test-checkbox"
                        >
                            {translate('ASSIGN_TO_FLEET_MODAL.ASSIGN_VESSEL_TO_THIS_FLEET')}
                        </Checkbox>
                    </div>
                    <div className="form-row">
                        <label className="label">
                            {translate('ASSIGN_TO_FLEET_MODAL.STATUS')}
                        </label>
                        <Select
                            disabled={!fleet.shouldAssignVesselToThisFleet}
                            valueKey="Id"
                            labelKey="Name"
                            options={this.props.statuses}
                            placeholder={translate('ASSIGN_TO_FLEET_MODAL.SET_VESSEL_STATUS')}
                            value={fleet.status}
                            onChange={this.handleFleetStatusChange.bind(this, fleet)}
                        />
                    </div>
                    <div className="form-row">
                        <label className="label">
                            {translate('ASSIGN_TO_FLEET_MODAL.COMMERCIAL_CONTRACT')}
                        </label>
                        <Select
                            disabled={!fleet.shouldAssignVesselToThisFleet}
                            valueKey="Id"
                            labelKey="Name"
                            options={this.props.commercialContracts}
                            placeholder={translate('ASSIGN_TO_FLEET_MODAL.CHOOSE_COMMERCIAL_CONTRACT')}
                            value={fleet.commercialContract}
                            onChange={this.handleFleetCommercialContractChange.bind(this, fleet)}
                        />
                    </div>
                    <div className="form-row">
                        <label className="label">
                            {translate('ASSIGN_TO_FLEET_MODAL.RESPONSIBLE_OFFICE')}
                        </label>
                        <Select
                            disabled={!fleet.shouldAssignVesselToThisFleet}
                            valueKey="Id"
                            labelKey="Name"
                            options={this.props.responsibleOffices}
                            placeholder={translate('ASSIGN_TO_FLEET_MODAL.RESPONSIBLE_OFFICE')}
                            value={fleet.responsibleOffice}
                            onChange={this.handleFleetResponsibleOfficeChange.bind(this, fleet)}
                        />
                    </div>
                </div>
            </Accordion>
        );
    };

    getVesselDetails = () => {
        const vesselDetails = [];
        const vessel = this.props.vessel;
        if (vessel) {
            if (vessel.Title) {
                vesselDetails.push(vessel.Title);
            }
            if (vessel.Imo) {
                vesselDetails.push(vessel.Imo);
            }
            if (vessel.VesselType) {
                vesselDetails.push(vessel.VesselType);
            }
            if (vessel.DWT) {
                vesselDetails.push(
                    `${translate('ASSIGN_TO_FLEET_MODAL.DWT')} ${formatNumber(vessel.DWT, 0)} ${translate('UNITS.DWT')}`
                );
            }
            if (vessel.TotalCubicCapacity) {
                vesselDetails.push(
                    `${translate('ASSIGN_TO_FLEET_MODAL.CAPACITY')} ${formatNumber(vessel.TotalCubicCapacity, 0)}`
                    + ` ${translate('UNITS.CUBIC_CAPACITY')}`
                );
            }
            if (vessel.IMOClass) {
                vesselDetails.push(
                    `${translate('ASSIGN_TO_FLEET_MODAL.IMO_CLASS')}
                ${vessel.IMOClass && vessel.IMOClass.split(',').join('/')}`
                );
            }
        }
        return vesselDetails.join(', ');
    };

    render() {
        return (
            <Modal.Default
                modalSize="sm"
                isOpen={this.props.isAssignToFleetModalShown}
                onRequestClose={this.closeAndDiscardTemp}
                className="sten-assign-to-fleet-modal sten-modal--with-footer"
            >
                <div className="sten-modal__header flex flex-center text-uppercase">
                    <div className="sten-modal__title flex-grow">
                        {translate('ASSIGN_TO_FLEET_MODAL.ASSIGN_TO_FLEET')}
                        <h6 className="text-secondary">{this.getVesselDetails()}</h6>
                    </div>
                    <div className="flex-shrink">
                        <button
                            className="btn-link icon icon-close"
                            onClick={this.closeAndDiscardTemp}
                        />
                    </div>
                </div>
                <ScrollArea
                    className="sten-modal__body"
                    contentClassName="flex-col"
                >
                    {this.state.tempFleetsSettings.map(this.renderFleetAccordion, this)}
                </ScrollArea>
                <footer className="sten-modal__footer">
                    <div className="flex-grow flex-row">
                        <div className="flex-grow text-right">
                            <button
                                type="button"
                                onClick={this.closeAndDiscardTemp}
                                className="btn btn--secondary"
                            >
                                {translate('GLOBAL.CANCEL')}
                            </button>
                        </div>
                        <div className="flex-shrink">
                            <button
                                disabled={false}
                                type="button"
                                onClick={this.saveRelationships}
                                className="btn btn--primary"
                            >
                                {translate('GLOBAL.SAVE')}
                            </button>
                        </div>
                    </div>
                </footer>
            </Modal.Default>
        );
    }
}

AssignToFleetModal.propTypes = {
    clearRelationships: PropTypes.func.isRequired,
    closeAssignToFleetModal: PropTypes.func.isRequired,
    commercialContracts: PropTypes.arrayOf(PropTypes.any).isRequired,
    commercialContractsById: PropTypes.objectOf(PropTypes.any).isRequired,
    defaultCommercialContract: PropTypes.objectOf(PropTypes.any),
    defaultResponsibleOffice: PropTypes.objectOf(PropTypes.any),
    defaultStatus: PropTypes.objectOf(PropTypes.any),
    fleet: PropTypes.objectOf(PropTypes.any),
    fleetsById: PropTypes.objectOf(PropTypes.any).isRequired,
    getOptions: PropTypes.func.isRequired,
    getVesselRelationships: PropTypes.func.isRequired,
    imo: PropTypes.number,
    isAssignToFleetModalShown: PropTypes.bool.isRequired,
    relationships: PropTypes.objectOf(PropTypes.any),
    responsibleOffices: PropTypes.arrayOf(PropTypes.any).isRequired,
    responsibleOfficesById: PropTypes.objectOf(PropTypes.any).isRequired,
    saveRelationships: PropTypes.func.isRequired,
    statuses: PropTypes.arrayOf(PropTypes.any).isRequired,
    statusesById: PropTypes.objectOf(PropTypes.any).isRequired,
    vessel: PropTypes.objectOf(PropTypes.any)
};

AssignToFleetModal.defaultProps = {
    defaultCommercialContract: null,
    defaultResponsibleOffice: null,
    defaultStatus: null,
    fleet: null,
    imo: null,
    relationships: null,
    vessel: null
};

function mapStateToProps(state) {
    return {
        commercialContracts: commercialContractsSelector(state),
        commercialContractsById: commercialContractsById(state),
        defaultCommercialContract: defaultCommercialContract(state),
        defaultResponsibleOffice: defaultResponsibleOffice(state),
        defaultStatus: defaultStatus(state),
        fleet: state.assignToFleetModalReducer.fleet,
        fleets: state.assignToFleetModalReducer.fleets,
        fleetsById: fleetsById(state),
        imo: state.assignToFleetModalReducer.imo,
        isAssignToFleetModalShown: state.assignToFleetModalReducer.isAssignToFleetModalShown,
        relationships: state.assignToFleetModalReducer.relationships,
        responsibleOffices: responsibleOfficesSelector(state),
        responsibleOfficesById: responsibleOfficesById(state),
        statuses: statusesSelector(state),
        statusesById: statusesById(state),
        vessel: state.assignToFleetModalReducer.vessel
    };
}

function mapDispatchToProps(dispatch) {
    return {
        clearRelationships: () => clearRelationships(dispatch),
        closeAssignToFleetModal: () => closeAssignToFleetModal(dispatch),
        getOptions: () => getOptions(dispatch),
        getVesselRelationships: imo => getVesselRelationships(dispatch, imo),
        saveRelationships: (vessel, fleet) => saveRelationships(dispatch, vessel, fleet)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AssignToFleetModal);
