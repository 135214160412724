import Excel from 'exceljs';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import {
    tableHeaderStyle as basicTableHeaderStyle,
    tableCellStyle,
    headingTitleStyle
} from 'utils/helpers/xlsx-styles';
import { addWorksheet, triggerXLSXDownload, formatXLSXCellValue } from 'utils/helpers/xlsx-helper';
import { generateXLSXHeaderLogos } from '../../../reports-helpers';
/* constants */
import {
    cargoProps,
    emissionProps,
    otherProps,
    colWidths,
    fuelColWidth
} from './fleet-performance-report-type-a-constants';

const tr = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.${key}`);
const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.FLEET_PERFORMANCE_REPORT.${key}`);

const customColors = {
    segmentHeader: 'FF9ED2FA',
    fleetHeader: 'FFD5ECFD',
    contractHeader: 'FFF1F9FE'
};

const tableHeaderStyle = {
    ...basicTableHeaderStyle,
    fill: {
        ...basicTableHeaderStyle.fill,
        fgColor: { argb: customColors.fleetHeader }
    }
};

const centeredTableHeaderStyle = {
    ...tableHeaderStyle,
    alignment: {
        horizontal: 'center',
        wrapText: true,
        vertical: 'middle'
    }
};

const rightAlignedTableHeaderStyle = {
    ...tableHeaderStyle,
    alignment: {
        horizontal: 'right',
        wrapText: true,
        vertical: 'middle'
    }
};

const rightAlignedTableCellStyle = {
    ...tableCellStyle,
    alignment: {
        horizontal: 'right',
        wrapText: true,
        vertical: 'middle'
    }
};

const summaryCellStyle = {
    ...tableCellStyle,
    font: {
        ...tableCellStyle.font,
        bold: true
    }
};

const rightAlignedSummaryCellStyle = {
    ...rightAlignedTableCellStyle,
    font: {
        ...rightAlignedTableCellStyle.font,
        bold: true
    }
};

const segmentHeaderStyle = {
    ...tableHeaderStyle,
    fill: {
        ...tableHeaderStyle.fill,
        fgColor: { argb: customColors.segmentHeader }
    }
};

const fleetHeaderStyle = {
    ...tableHeaderStyle,
    fill: {
        ...tableHeaderStyle.fill,
        fgColor: { argb: customColors.fleetHeader }
    }
};

const contractHeaderStyle = {
    ...tableHeaderStyle,
    fill: {
        ...tableHeaderStyle.fill,
        fgColor: { argb: customColors.contractHeader }
    }
};

const formatTableCell = (val, props = {}, style = null, colSpan = 1) => {
    const { value, numFmt } = formatXLSXCellValue(val, props);
    return {
        value,
        props: { ...rightAlignedTableCellStyle, ...style, numFmt },
        colSpan
    };
};

const getReportTableHeader = (fuelTypes) => ([
    {
        values: [
            { value: t('VALUE'), rowSpan: 2, props: tableHeaderStyle },
            { value: t('AT_SEA'), colSpan: 5, props: centeredTableHeaderStyle },
            null,
            null,
            null,
            null,
            { value: t('IN_PORT'), colSpan: 2, props: centeredTableHeaderStyle },
            null,
            {
                value: t('CONSUMPTION_AT_SEA'),
                colSpan: fuelTypes.sea.length + 1,
                props: centeredTableHeaderStyle
            },
            ...new Array(fuelTypes.sea.length).fill(null),
            {
                value: t('CONSUMPTION_IN_PORT'),
                colSpan: fuelTypes.port.length + 1,
                props: centeredTableHeaderStyle
            },
            ...new Array(fuelTypes.port.length).fill(null),
            { value: t('EMISSIONS'), colSpan: 4, props: centeredTableHeaderStyle },
            null,
            null,
            null
        ]
    },
    {
        values: [
            null,
            { value: t('TOTAL_CARGO_CARRIED'), props: rightAlignedTableHeaderStyle },
            { value: t('DISTANCE_LADEN'), props: rightAlignedTableHeaderStyle },
            { value: t('DISTANCE_LADEN_PERCENTAGE'), props: rightAlignedTableHeaderStyle },
            { value: t('DISTANCE_BALLAST'), props: rightAlignedTableHeaderStyle },
            { value: t('DISTANCE_BALLAST_PERCENTAGE'), props: rightAlignedTableHeaderStyle },
            { value: t('DAYS_IN_PORT'), props: rightAlignedTableHeaderStyle },
            { value: t('DAYS_IN_PORT_PERCENTAGE'), props: rightAlignedTableHeaderStyle },
            ...fuelTypes.sea.map(fuelType => ({
                value: `${fuelType.Name} (${translate('UNITS.CONSUMPTION')})`,
                props: rightAlignedTableHeaderStyle
            })),
            { value: t('TOTAL'), props: rightAlignedTableHeaderStyle },
            ...fuelTypes.port.map(fuelType => ({
                value: `${fuelType.Name} (${translate('UNITS.CONSUMPTION')})`,
                props: rightAlignedTableHeaderStyle
            })),
            { value: t('TOTAL'), props: rightAlignedTableHeaderStyle },
            ...emissionProps.map(emissionProp => ({
                value: `${emissionProp.label} (${translate('UNITS.EMISSIONS')})`,
                props: rightAlignedTableHeaderStyle
            }))
        ]
    }
]);

const renderDataProps = (data, props, style, isAvg = false) => (
    props.map(prop => formatTableCell(
        data[prop.key],
        { ...prop, numFmt: isAvg ? prop.avgNumFmt : prop.numFmt },
        style
    ))
);

const renderFuelData = (data, fuelTypes, style) => (
    fuelTypes.map(fuelType => (
        formatTableCell(data[fuelType.Id] ? data[fuelType.Id].Quantity : null, otherProps.fuel, style)
    ))
);

const renderSummary = (
    label,
    summary,
    fuelTypes,
    fuelBeforeColSpan,
    fuelAfterColSpan,
    emissionColSpan
) => ([
    {
        values: [
            { value: label, props: { ...tableCellStyle, ...summaryCellStyle } },
            ...renderDataProps(summary.Total, cargoProps, rightAlignedSummaryCellStyle),
            ...renderFuelData(summary.Total.ConsumptionsAtSea, fuelTypes.sea, rightAlignedSummaryCellStyle),
            formatTableCell(
                summary.Total.TotalConsumptionAtSea, otherProps.TotalConsumptionAtSea, rightAlignedSummaryCellStyle
            ),
            ...renderFuelData(summary.Total.ConsumptionsInPort, fuelTypes.port, rightAlignedSummaryCellStyle),
            formatTableCell(
                summary.Total.TotalConsumptionInPort, otherProps.TotalConsumptionInPort, rightAlignedSummaryCellStyle
            ),
            ...renderDataProps(summary.Total.Emissions, emissionProps, rightAlignedSummaryCellStyle)
        ]
    },
    {
        values: [
            { value: t('FUEL_PER_DISTANCE'), props: summaryCellStyle },
            { colSpan: fuelBeforeColSpan, props: rightAlignedSummaryCellStyle },
            ...new Array(fuelBeforeColSpan - 1).fill(null),
            formatTableCell(summary.FuelPerDistance, otherProps.FuelPerDistance, rightAlignedSummaryCellStyle),
            { colSpan: fuelAfterColSpan, props: rightAlignedSummaryCellStyle },
            ...new Array(fuelAfterColSpan - 1).fill(null)
        ]
    },
    {
        values: [
            { value: t('FUEL_PER_CARGO'), props: summaryCellStyle },
            { colSpan: fuelBeforeColSpan, props: rightAlignedSummaryCellStyle },
            ...new Array(fuelBeforeColSpan - 1).fill(null),
            formatTableCell(summary.FuelPerCargo, otherProps.FuelPerCargo, rightAlignedSummaryCellStyle),
            { colSpan: fuelAfterColSpan, props: rightAlignedSummaryCellStyle },
            ...new Array(fuelAfterColSpan - 1).fill(null)
        ]
    },
    {
        values: [
            { value: t('EMISSIONS_PER_CARGO'), props: summaryCellStyle },
            { colSpan: emissionColSpan, props: rightAlignedSummaryCellStyle },
            ...new Array(emissionColSpan - 1).fill(null),
            ...renderDataProps(summary.EmissionsPerCargo, emissionProps, rightAlignedSummaryCellStyle, true)
        ]
    }
]);

const renderVesselList = (
    label,
    vesselList,
    fuelTypes,
    headerColSpan,
    fuelBeforeColSpan,
    fuelAfterColSpan,
    emissionColSpan,
    style
) => ([
    {
        values: [
            { value: label, props: style, colSpan: headerColSpan + 1 },
            ...new Array(headerColSpan).fill(null)
        ],
        props: { height: 20 }
    },
    ...vesselList.Vessels.map(vessel => ({
        values: [
            { value: vessel.Name, props: tableCellStyle },
            ...renderDataProps(vessel, cargoProps),
            ...renderFuelData(vessel.ConsumptionsAtSea, fuelTypes.sea),
            formatTableCell(vessel.TotalConsumptionAtSea, otherProps.TotalConsumptionAtSea),
            ...renderFuelData(vessel.ConsumptionsInPort, fuelTypes.port),
            formatTableCell(vessel.TotalConsumptionInPort, otherProps.TotalConsumptionInPort),
            ...renderDataProps(vessel.Emissions, emissionProps)
        ]
    })),
    ...renderSummary(
        `${t('TOTAL_FOR')} ${label}`,
        vesselList.Summary,
        fuelTypes,
        fuelBeforeColSpan,
        fuelAfterColSpan,
        emissionColSpan
    )
]);

const renderFleet = (
    fleet,
    fuelTypes,
    headerColSpan,
    fuelBeforeColSpan,
    fuelAfterColSpan,
    emissionColSpan
) => ([
    {
        values: [
            { value: fleet.Name, props: fleetHeaderStyle, colSpan: headerColSpan + 1 },
            ...new Array(headerColSpan).fill(null)
        ],
        props: { height: 20 }
    },
    ...renderSummary(
        t('TOTAL_FOR_COMPANY_FLEET'),
        fleet.Summary,
        fuelTypes,
        fuelBeforeColSpan,
        fuelAfterColSpan,
        emissionColSpan
    ),
    ...fleet.Contracts.reduce((res, contract) => res.concat(renderVesselList(
        contract.Name,
        contract,
        fuelTypes,
        headerColSpan,
        fuelBeforeColSpan,
        fuelAfterColSpan,
        emissionColSpan,
        contractHeaderStyle
    )), [])
]);

const renderSegment = (
    segment,
    fuelTypes,
    headerColSpan,
    fuelBeforeColSpan,
    fuelAfterColSpan,
    emissionColSpan
) => ([
    {
        values: [
            { value: segment.Name, props: segmentHeaderStyle, colSpan: headerColSpan + 1 },
            ...new Array(headerColSpan).fill(null)
        ],
        props: { height: 28 }
    },
    ...renderSummary(
        t('TOTAL_FOR_SEGMENT'),
        segment.Summary,
        fuelTypes,
        fuelBeforeColSpan,
        fuelAfterColSpan,
        emissionColSpan
    ),
    ...segment.Fleets.reduce((res, fleet) => res.concat(renderFleet(
        fleet,
        fuelTypes,
        headerColSpan,
        fuelBeforeColSpan,
        fuelAfterColSpan,
        emissionColSpan
    )), []),
    ...segment.OtherVessels
        ? renderVesselList(
            t('OTHER_VESSELS'),
            segment.OtherVessels,
            fuelTypes,
            headerColSpan,
            fuelBeforeColSpan,
            fuelAfterColSpan,
            emissionColSpan,
            fleetHeaderStyle
        )
        : []
]);

export default function* generateFleetPerformanceReportTypeAXLSX(data) {
    const { tableData, fileName, rangeStart, rangeEnd } = data;
    const { parsedData, fuelTypes } = tableData;
    const workbook = new Excel.Workbook();

    const cargoCols = cargoProps.length;
    const seaConsCols = fuelTypes.sea.length;
    const portConsCols = fuelTypes.port.length;
    const emissionCols = emissionProps.length;
    const fuelBeforeColSpan = cargoCols + seaConsCols;
    const fuelAfterColSpan = portConsCols + emissionCols + 1;
    const emissionColSpan = cargoCols + seaConsCols + portConsCols + 2;
    const headerColSpan = cargoCols + seaConsCols + portConsCols + 2 + emissionCols;

    const columns = [
        { width: colWidths.Value.xls },
        { width: colWidths.TotalCargoCarried.xls },
        { width: colWidths.DistanceLaden.xls },
        { width: colWidths.DistanceLadenPercentage.xls },
        { width: colWidths.DistanceBallast.xls },
        { width: colWidths.DistanceBallastPercentage.xls },
        { width: colWidths.DaysInPort.xls },
        { width: colWidths.DaysInPortPercentage.xls },
        ...new Array(seaConsCols).fill({ width: fuelColWidth.xls }),
        { width: colWidths.TotalConsumptionAtSea.xls },
        ...new Array(portConsCols).fill({ width: fuelColWidth.xls }),
        { width: colWidths.TotalConsumptionInPort.xls },
        { width: colWidths.CO2.xls },
        { width: colWidths.SOx.xls },
        { width: colWidths.NOx.xls },
        { width: colWidths.PM.xls }
    ];

    const sheetConfig = {
        name: 'Fleet Performance Report',
        props: {
            properties: { showGridLines: false },
            views: [{ showGridLines: false }],
            pageSetup: {
                paperSize: 9,
                orientation: 'landscape',
                margins: { left: 0.4, right: 0.4, top: 0.2, bottom: 0.2, header: 0, footer: 0 }
            }
        },
        columns,
        rows: [
            ...generateXLSXHeaderLogos(null, null, 0.0001),
            {
                values: [{
                    value: tr('FLEET_PERFORMANCE_REPORT'),
                    props: headingTitleStyle
                }]
            },
            null,
            {
                values: [{
                    value: `${tr('PREVIEW.HEADER.DATE_RANGE')} ${rangeStart} - ${rangeEnd}`
                }]
            },
            null,
            ...getReportTableHeader(fuelTypes),
            ...renderSummary(
                t('GRAND_TOTAL'),
                parsedData.Summary,
                fuelTypes,
                fuelBeforeColSpan,
                fuelAfterColSpan,
                emissionColSpan
            ),
            ...parsedData.Segments.reduce((res, segment) => res.concat(renderSegment(
                segment,
                fuelTypes,
                headerColSpan,
                fuelBeforeColSpan,
                fuelAfterColSpan,
                emissionColSpan
            )), [])
        ]
    };

    addWorksheet(workbook, sheetConfig);

    const xls64 = yield workbook.xlsx.writeBuffer({ base64: true });
    return triggerXLSXDownload(fileName)(xls64);
}
