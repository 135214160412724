import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* actions */
import { toggleSidebar } from '../kpi-actions';
/* selectors */
import { getSegmentsById } from '../kpi-selectors';
/* components */
import Sidebar from '../shared/sidebar/sidebar';
import FlipSwitch from 'components/flip-switch/flip-switch';
import EnergyManagementKPISegmentBasic from './basic/basic';
import EnergyManagementKPISegmentAdvanced from './advanced/advanced';
import IconButton from 'components/icon-button/icon-button';
/* styles */
import './segment.scss';

class EnergyManagementKPISegment extends React.PureComponent {
    handleModeChange = () => {
        if (this.props.queryParams.advanced) {
            this.props.router.updateQueryParams({ remove: ['advanced'] });
        } else {
            this.props.router.updateQueryParams({ add: { advanced: true } });
        }
    };

    render() {
        const { queryParams, router } = this.props;
        return (
            <div className="sten-energy-management-kpi sten-energy-management-kpi-segment">
                <Sidebar segmentId={router.params.segmentId} />
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <div className="flex-shrink">
                            <Link
                                className="btn-link icon icon-arrow-left"
                                to={`${appRoutes.EnergyManagement.KPI}${router.location.search}`}
                            />
                        </div>
                        <div className="flex-grow">
                            <h1 className="text-uppercase">
                                {this.props.segmentsById && this.props.segmentsById[router.params.segmentId]
                                    && this.props.segmentsById[router.params.segmentId].VesselTypeName}
                            </h1>
                        </div>
                        <div className="flex-shrink">
                            <FlipSwitch
                                value={!!queryParams.advanced}
                                onChange={this.handleModeChange}
                                activeLabel={t('ENERGY_MANAGEMENT.KPI.ADVANCED')}
                                inactiveLabel={t('ENERGY_MANAGEMENT.KPI.BASIC')}
                            />
                        </div>
                        <div className="flex-shrink">
                            <IconButton
                                isLink
                                changeIndicator={this.props.isSidebarVisible}
                                icon="icon-filter-outline"
                                isActive={this.props.isSidebarVisible}
                                onClick={this.props.toggleSidebar}
                            />
                        </div>
                    </div>
                    {queryParams.advanced
                        ? <EnergyManagementKPISegmentAdvanced />
                        : <EnergyManagementKPISegmentBasic />
                    }
                </div>
            </div>
        );
    }
}

EnergyManagementKPISegment.propTypes = {
    isSidebarVisible: PropTypes.bool.isRequired,
    queryParams: PropTypes.shape({
        advanced: PropTypes.bool
    }).isRequired,
    router: TRouter.isRequired,
    segmentsById: PropTypes.objectOf(PropTypes.any).isRequired,
    toggleSidebar: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        isSidebarVisible: state.energyManagementKPIReducer.isSidebarVisible,
        segmentsById: getSegmentsById(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleSidebar: () => toggleSidebar(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EnergyManagementKPISegment));
