import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* actions */
import { reset } from './map-location-tooltip-actions';
/* helpers */
import { formatCoordinates } from 'utils/helpers/info-helper';
/* components */
import Tooltip from 'components/tooltip/tooltip';
/* styles */
import './map-location-tooltip.scss';

class MapLocationTooltip extends React.PureComponent {
    componentWillUnmount() {
        this.props.reset();
    }

    render() {
        if (!this.props.coordinates.length || !this.props.pixels.length) {
            return null;
        }
        return (
            <Tooltip
                contentClassName="sten-pin-location-tooltip"
                alwaysVisible
                content={formatCoordinates(
                    this.props.coordinates[0],
                    this.props.coordinates[1]
                )}
                style={{
                    top: `${this.props.pixels[1]}px`,
                    left: `${this.props.pixels[0]}px`
                }}
            >
                <span
                    style={{
                        top: `${this.props.pixels[1]}px`,
                        left: `${this.props.pixels[0]}px`
                    }}
                    className="icon icon-position sten-pin-location-tooltip__icon"
                />
            </Tooltip>
        );
    }
}

MapLocationTooltip.propTypes = {
    coordinates: PropTypes.arrayOf(PropTypes.any).isRequired,
    pixels: PropTypes.arrayOf(PropTypes.any).isRequired,
    reset: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        pixels: state.mapLocationTooltipReducer.pixels,
        coordinates: state.mapLocationTooltipReducer.coordinates
    };
}

function mapDispatchToProps(dispatch) {
    return {
        reset: () => reset(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapLocationTooltip);
