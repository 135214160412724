import { customDateValidation } from './vessel-report-common-fields';
import moment from 'moment';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { deleteObjectProp, setObjectProp, hasValue } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';

const someFieldHasValue = (components, name) => {
    const keys = {
        1: ['Commodity', 'Size', 'Loaded', 'Charterer', 'Temperature', 'Sg'],
        2: ['Commodity', 'Size', 'Loaded']
    };
    const splitName = name.split('.');
    const prefix = splitName.slice(0, 3).join('.');
    const tankStateId = components[`${prefix}.TankState`] && components[`${prefix}.TankState`].state.value.Id;
    if (!keys[tankStateId] || keys[tankStateId].indexOf(splitName[3]) < 0) {
        return false;
    }
    return keys[tankStateId].some(key =>
        components[`${prefix}.${key}`] && hasValue(components[`${prefix}.${key}`].state.value));
};

const someCargoTankIsLoaded = (components) => {
    const noOfWings = components?.NoOfWings?.state?.value?.Value || 0;
    let flag = false;

    for (let i = 0; i < noOfWings; i++) {
        const isSlopWing = components[`Tanks.${i}.IsSlop`]?.state?.value;
        if (!isSlopWing && ((components[`Tanks.${i}.Port.TankState`]
            && components[`Tanks.${i}.Port.TankState`].state.value.Id === 1
            && components[`Tanks.${i}.Port.Loaded`]
            && hasValue(components[`Tanks.${i}.Port.Loaded`].state.value))
            || (components[`Tanks.${i}.Starboard.TankState`]
                && components[`Tanks.${i}.Starboard.TankState`].state.value.Id === 1
                && components[`Tanks.${i}.Starboard.Loaded`]
                && hasValue(components[`Tanks.${i}.Starboard.Loaded`].state.value)))
        ) {
            flag = true;
            break;
        }
    }

    return flag;
};

const portActivityIsLoad = (components) =>
    !components.PortActivityType || components.PortActivityType.state.value.Id === 1;

const tanksConditionallyRequired = {
    rule: (value, params, components, name) => hasValue(value) || !someFieldHasValue(components, name),
    hint: () => 'Required'
};

const tanksLoadedRequired = {
    rule: (value, params, components, name) => {
        const splitName = name.split('.');
        const isSlopWing = components && components[`Tanks.${splitName[1]}.IsSlop`]
            ? components[`Tanks.${splitName[1]}.IsSlop`].state.value
            : false;
        return isSlopWing
            || !portActivityIsLoad(components)
            || hasValue(value)
            || someCargoTankIsLoaded(components, name);
    },
    hint: () => 'At least one cargo tank in a non-slop wing must be loaded when port activity is \'Load\''
};

const noOfWingsRequired = {
    rule: (value, params, components) => !portActivityIsLoad(components) || hasValue(value),
    hint: () => 'At least one cargo tank in a non-slop wing must be loaded when port activity is \'Load\''
};


const robTypeRequired = {
    rule: (value) => {
        return !!value && value.findIndex(item => item.Id === 1) > -1;
    },
    hint: () => t('REPORT_LABELS.BUNKER_ROB_MUST_BE_DEFINED')
};

const liftedSulphurRequired = {
    rule: (value, params, components, name) => {
        const parsedValue = value !== null && Number.parseFloat(value);
        const sulphurPrefix = `BunkerData.3.${name.split('.')[2]}`;
        const sulphurValue = components[sulphurPrefix]?.state?.value;
        return !parsedValue || (parsedValue && sulphurValue);
    },
    hint: () => t('REPORT_LABELS.BUNKER_ROB_SULPHUR_MUST_BE_DEFINED')
};

const sulphurRequired = {
    rule: (value, params, components, name) => {
        const liftedPrefix = `BunkerData.2.${name.split('.')[2]}`;
        const liftedValue = components[liftedPrefix]?.state?.value;
        const liftedParsedValue = liftedValue !== null && Number.parseFloat(components[liftedPrefix]?.state?.value);
        return value || !liftedParsedValue;
    },
    hint: () => t('REPORT_LABELS.REQUIRED_WHEN_LIFTED_IS_DEFINED')
};

const consumptionRegexMap = {
    Consumptions: /Consumptions\.\d/,
    ConsumptionDuringStoppage: /ConsumptionDuringStoppage\.\d/,
    ConsumptionFromNoonToEvent: /ConsumptionFromNoonToEvent\.\d/,
    ConsumptionsSinceLastEvent: /ConsumptionsSinceLastEvent\.\d/
};
const dischargeOperationsRegex = /DischargeOperations\.\d/;

const atLeastOneRequired = (search, hint = () => t('REPORT_LABELS.AT_LEAST_ONE_REQUIRED')) => ({
    rule: (value, params, components) => Object.keys(components)
        .filter(key => key.search(search) === 0)
        .some(key => components[key] && components[key].state && components[key].state.value),
    hint
});

const robRequired = atLeastOneRequired('BunkerData.1.', () => t('REPORT_LABELS.BUNKER_ROB_MUST_BE_DEFINED'));
const robArrivalNextPortRequired = atLeastOneRequired(
    'BunkerData.5.',
    () => t('REPORT_LABELS.BUNKER_ROB_NEXT_PORT_MUST_BE_DEFINED')
);

const requiredWhenLiftedSet = {
    rule: (value, params, components) => !Object.keys(components)
        .filter(key => key.search('BunkerData.2.') === 0)
        .some(key => components[key]?.state?.value && Number.parseFloat(components[key]?.state?.value) && !value),
    hint: () => t('REPORT_LABELS.REQUIRED_WHEN_LIFTED_IS_DEFINED')
};

const liftedRequiredToBeSet = {
    rule: (value, params, components, name) => {
        const liftedPrefix = `BunkerData.2.${name.split('.')[2]}`;
        return !(components[liftedPrefix]
            && components[liftedPrefix].state
            && !components[liftedPrefix].state.value && value);
    },
    hint: () => t('REPORT_LABELS.LIFTED_REQUIRED_TO_BE_SET')
};

const dailyConsumptionValidations = { numeric: { min: 0, max: 100 } };
const consumptionValidations = { numeric: { min: 0, max: 1000000 } };

const consumptionsAtLeastOneRequired = (searchRegex) => ({
    Default: { ...dailyConsumptionValidations, atLeastOneRequired: atLeastOneRequired(searchRegex) },
    ConsumptionTypes: { required: true },
    FuelTypes: { required: true }
});

const commonValidations = {
    AverageSpeed: { required: true, numeric: { min: 0, max: 30 } },
    BunkerData: {
        1: { numeric: { min: 0, max: 5000 }, robRequired },
        2: { numeric: { min: 0, max: 5000 }, liftedSulphurRequired },
        3: { numeric: { min: 0, max: 100 }, sulphurRequired },
        4: { numeric: { min: 0, max: 100 } },
        5: { numeric: { min: 0, max: 5000 }, robArrivalNextPortRequired },
        8: { numeric: { min: 0, max: 2000 }, liftedRequiredToBeSet },
        9: { numeric: { min: 0, max: 2000 }, liftedRequiredToBeSet },
        BunkerTypes: { robTypeRequired },
        FuelTypes: { required: true }
    },
    CallSign: { required: true, maxLength: 50 },
    CargoStatus: { required: true },
    ConsumptionsDaily: { Default: dailyConsumptionValidations },
    Consumptions: { Default: consumptionValidations },
    ConsumptionsSinceLastEvent: consumptionsAtLeastOneRequired(consumptionRegexMap.ConsumptionsSinceLastEvent),
    DateOffset: { timeSpan: { min: -13 * 60, max: 13 * 60 } },
    DateOffsetRequired: { required: true, timeSpan: { min: -13 * 60, max: 13 * 60 } },
    DateTime: { custom: customDateValidation },
    DateTimeRequired: { required: true, custom: customDateValidation },
    DateTimeLimited: {
        custom: customDateValidation,
        dateLimits: {
            min: moment.utc('1990-01-01T00:00:00'),
            max: moment.utc().add(2, 'd'),
            utc: true
        }
    },
    DateTimeLimitedRequired: {
        required: true,
        custom: customDateValidation,
        dateLimits: {
            min: moment.utc('1990-01-01T00:00:00'),
            max: moment.utc().add(2, 'd'),
            utc: true
        }
    },
    Displacement: { required: true, numeric: { min: 0, max: 200000 } },
    Distance: { numeric: { min: 0, max: 21639 } },
    DistanceSinceLastEvent: { required: true, numeric: { min: 0, max: 21639 } },
    DistanceSLDR: { numeric: { min: 0, max: 600 } },
    Draft: { numeric: { min: 0, max: 20 } },
    DraftRequired: { required: true, numeric: { min: 0, max: 20 } },
    Duration: { duration: { min: 0, max: 100 * 24 * 3600 * 1000 } }, // 100 days
    HoursSteamedSinceLastEvent: { required: true, duration: { min: 0, max: 25 * 3600 * 1000 } },
    DWT: { required: true, numeric: { min: 0, max: 1000000 } },
    InstructedSpeed: { required: true, numeric: { min: 0, max: 30, div: 0.5, divHint: 'whole or half' } },
    LoadDischargeRate: { required: true, numeric: { min: 0, max: 4000, decimals: 0 } },
    Point: { required: true },
    Position: { required: true },
    PumpPressure: { required: true, numeric: { min: 0, max: 15, decimals: 0 } },
    Remarks: { maxLength: 200 },
    Rpm: { numeric: { min: 0, max: 200, decimals: 1 } },
    RpmRequired: { required: true, numeric: { min: 0, max: 200, decimals: 1 } },
    Terminal: { maxLength: 50 },
    TimeAtAnchorSinceLastEvent: { duration: true },
    TimeElapsedSinceLastEvent: {
        required: true,
        duration: { min: 0, max: 25 * 3600 * 1000 },
        custom: {
            rule: (value, params, components) => {
                if (value) {
                    const timeElapsedInMs = TimeHelper.getDurationFromString(value).asMilliseconds();
                    const timeAtAnchor = components.TimeAtAnchorSinceLastEvent?.state?.value || '';
                    const hoursSteamed = components.HoursSteamedSinceLastEvent?.state?.value || '';
                    const timeAtAnchorInMs = TimeHelper.getDurationFromString(timeAtAnchor).asMilliseconds();
                    const hoursSteamedInMs = TimeHelper.getDurationFromString(hoursSteamed).asMilliseconds();
                    return (timeElapsedInMs >= timeAtAnchorInMs
                        && timeElapsedInMs >= hoursSteamedInMs
                        && timeElapsedInMs >= timeAtAnchorInMs + hoursSteamedInMs);
                }
                return true;
            },
            hint: (value, params, components) => {
                const timeElapsedInMs = TimeHelper.getDurationFromString(value).asMilliseconds();
                const timeAtAnchor = components.TimeAtAnchorSinceLastEvent?.state?.value || '';
                const hoursSteamed = components.HoursSteamedSinceLastEvent?.state?.value || '';
                const timeAtAnchorInMs = TimeHelper.getDurationFromString(timeAtAnchor).asMilliseconds();
                const hoursSteamedInMs = TimeHelper.getDurationFromString(hoursSteamed).asMilliseconds();
                if (timeElapsedInMs < timeAtAnchorInMs) {
                    return 'Time Elapsed SLE must be higher or equal to Time At Anchor SLE';
                }
                if (timeElapsedInMs < hoursSteamedInMs) {
                    return 'Time Elapsed SLE must be higher or equal to Hours Steamed SLE';
                }
                return 'Time Elapsed SLE must be higher or equal to the sum of '
                    + 'Time At Anchor SLE and Hours Steamed SLE';
            }
        }
    },
    TotalCargoSinceLastEvent: { required: true, numeric: { min: 0, max: 200000 } },
    Vessel: { required: true },
    VesselStatus: { required: true },
    VoyageNumber: { required: true },
    WindForceSinceLastEvent: { required: true }
};

export const defaultValidationsByType = {
    1: { // daily
        'DailyReportAtAnchorage.CurrentKn': { required: true, numeric: { min: 0, max: 100 } },
        'DailyReportAtAnchorage.CurrentDirection': { required: true },
        'DailyReportAtAnchorage.EstimatedTimeOfBerthing': commonValidations.DateTimeRequired,
        'DailyReportAtAnchorage.EstimatedTimeOfBerthingOffset': commonValidations.DateOffsetRequired,
        'DailyReportAtAnchorage.EstimatedTimeOfDeparture': commonValidations.DateTimeRequired,
        'DailyReportAtAnchorage.EstimatedTimeOfDepartureOffset': commonValidations.DateOffsetRequired,
        'DailyReportAtAnchorage.SeasDirection': { required: true },
        'DailyReportAtPort.BalanceCargoToLoadDischarge': {
            required: true,
            numeric: { min: 0, max: 200000, decimals: 0 }
        },
        'DailyReportAtPort.BerthTerminalAnchorage': { required: false },
        'DailyReportAtPort.CommencedCargoDate': {
            ...commonValidations.DateTime,
            dateLimits: {
                utc: true,
                maxProp: 'ReportDate'
            }
        },
        'DailyReportAtPort.CommencedCargoDateOffset': commonValidations.DateOffset,
        'DailyReportAtPort.Point': { required: true },
        'DailyReportAtPort.EstimatedTimeOfDeparture': commonValidations.DateTimeRequired,
        'DailyReportAtPort.EstimatedTimeOfDepartureOffset': commonValidations.DateOffsetRequired,
        'DailyReportAtPort.NoOfGangsHatchesWorking': { required: true, numeric: { min: 0, max: 6, decimals: 0 } },
        'DailyReportAtPort.PortActivityType': { required: true },
        'DailyReportAtPort.QtyLoadedDischarged': { required: true, numeric: { min: 0, max: 200000, decimals: 0 } },
        'DailyReportAtPort.TotalCargoLoadedDischarged': {
            required: true,
            numeric: { min: 0, max: 200000, decimals: 0 }
        },
        'DailyReportAtSea.CurrentKn': { required: true, numeric: { min: 0, max: 100 } },
        'DailyReportAtSea.CurrentDirection': { required: true },
        'DailyReportAtSea.MePowerInKw': { numeric: { min: 0, max: 20001 } },
        'DailyReportAtSea.SeasDirection': { required: true },
        'DailyReportAtSea.SpeedInstructionType': { required: true },
        'DailyReportAtSea.LogSpeed': commonValidations.AverageSpeed,
        'DailyReportAtSea.LogDistance': { ...commonValidations.DistanceSLDR, required: true },
        AverageRpm: commonValidations.Rpm,
        AverageSpeed: commonValidations.AverageSpeed,
        BunkerData: commonValidations.BunkerData,
        CallSign: commonValidations.CallSign,
        CargoStatus: commonValidations.CargoStatus,
        CharterpartyDate: commonValidations.DateTimeRequired,
        Consumptions: consumptionsAtLeastOneRequired(consumptionRegexMap.Consumptions),
        ConsumptionsSinceLastEvent: commonValidations.ConsumptionsSinceLastEvent,
        Course: { numeric: { min: 0, max: 359, decimals: 0 } },
        DistanceToGo: commonValidations.Distance,
        DistanceSinceLastEvent: commonValidations.DistanceSinceLastEvent,
        DraftAft: commonValidations.Draft,
        DraftFwd: commonValidations.Draft,
        DraftMid: commonValidations.DraftRequired,
        EcaDistanceSteamedSinceLastReport: commonValidations.DistanceSLDR,
        EtaDate: commonValidations.DateTime,
        EtaDateOffset: commonValidations.DateOffset,
        FreshWaterAvailableForTankCleaning: { numeric: { min: 0, max: 1000000 } },
        HoursSinceLastReport: {
            duration: { min: 23 * 3600 * 1000, max: 25 * 3600 * 1000 },
            custom: {
                rule: (value, params, components) => {
                    if (value) {
                        const hoursSinceLastReportInMs = TimeHelper.getDurationFromString(value).asMilliseconds();
                        const hoursSteamed = components.HoursSteamedSinceLastReport?.state?.value || '';
                        const hoursSteamedInMs = TimeHelper.getDurationFromString(hoursSteamed).asMilliseconds();
                        return hoursSinceLastReportInMs >= hoursSteamedInMs;
                    }
                    return true;
                },
                hint: () => 'Hours Since Last Report must be higher or equal to Hours Steamed Since Last Report'
            }
        },
        HoursSteamedSinceLastReport: { required: true, duration: { min: 0, max: 25 * 3600 * 1000 } },
        HoursSteamedSinceLastEvent: commonValidations.HoursSteamedSinceLastEvent,
        InmarsatAreaCodeInUse: { maxLength: 50 },
        InstructedSpeed: commonValidations.InstructedSpeed,
        LastHullCleaning: commonValidations.DateTimeLimited,
        LastHullCleaningOffset: commonValidations.DateOffset,
        LastPropellerCleaning: commonValidations.DateTimeLimited,
        LastPropellerCleaningOffset: commonValidations.DateOffset,
        Mcr: { numeric: { min: -100, max: 100, decimals: 0 } },
        NextPoint: { required: false },
        Position: commonValidations.Position,
        Remarks: commonValidations.Remarks,
        ReportDate: commonValidations.DateTimeLimitedRequired,
        ReportDateOffset: commonValidations.DateOffsetRequired,
        Rpm: commonValidations.Rpm,
        SeaCondition: { required: false },
        SeasHeight: { numeric: { min: 0, max: 30 } },
        Slip: { numeric: { min: -100, max: 100, decimals: 0 } },
        SwellDirection: { required: false },
        SwellHeight: { numeric: { min: 0, max: 30 } },
        TimeAtAnchorSinceLastEvent: commonValidations.TimeAtAnchorSinceLastEvent,
        TimeElapsedSinceLastEvent: commonValidations.TimeElapsedSinceLastEvent,
        TotalDistanceSteamedSinceLastReport: { ...commonValidations.DistanceSLDR, required: true },
        TotalCargoSinceLastEvent: commonValidations.TotalCargoSinceLastEvent,
        Utilization: {
            AuxiliaryRunningHours: { timeSpan: { min: 0, max: 25 * 60 } },
            AuxiliaryEnergyProduced: { numeric: { min: 0, max: 99999, decimals: 0 } },
            BoilerRunningHours: { timeSpan: { min: 0, max: 25 * 60 } }
        },
        Vessel: commonValidations.Vessel,
        VesselStatus: commonValidations.VesselStatus,
        VoyageNumber: commonValidations.VoyageNumber,
        WaterTemperature: { numeric: { min: 0, max: 40, decimals: 1 } },
        WindBeaufort: { required: false },
        WindDirection: { required: false },
        WindForceSinceLastEvent: commonValidations.WindForceSinceLastEvent,
        WindSpeed: { numeric: { min: 0, max: 100 } }
    },
    2: { // departure
        BunkerData: commonValidations.BunkerData,
        BunkerDeliveryNoteNumber: { requiredWhenLiftedSet },
        BunkerDeliveryDate: { ...commonValidations.DateTime, requiredWhenLiftedSet },
        BunkerDeliveryDateOffset: { ...commonValidations.DateOffset, requiredWhenLiftedSet },
        CallSign: commonValidations.CallSign,
        CargoOps: { numeric: { min: 0, max: 1000000, decimals: 0 } },
        CargoStatus: commonValidations.CargoStatus,
        CargoLoadedDischarged: { numeric: { min: 0, decimals: 0 } },
        CharterpartyDate: commonValidations.DateTimeRequired,
        CompletionOfCargoOps: {
            ...commonValidations.DateTimeLimited,
            dateLimits: {
                ...commonValidations.DateTimeLimited.dateLimits,
                maxProp: 'DepartureDate'
            }
        },
        CompletionOfCargoOpsOffset: commonValidations.DateOffset,
        Consumptions: commonValidations.Consumptions,
        ConsumptionsSinceLastEvent: commonValidations.ConsumptionsSinceLastEvent,
        DepartureDate: commonValidations.DateTimeLimitedRequired,
        DepartureDateOffset: commonValidations.DateOffsetRequired,
        Displacement: commonValidations.Displacement,
        DistanceToGo: { required: true, ...commonValidations.Distance },
        DistanceSinceLastEvent: commonValidations.DistanceSinceLastEvent,
        DraftAft: commonValidations.DraftRequired,
        DraftFwd: commonValidations.DraftRequired,
        DraftMid: commonValidations.DraftRequired,
        DroppingOutwardPilot: commonValidations.DateTimeLimitedRequired,
        DroppingOutwardPilotOffset: commonValidations.DateOffsetRequired,
        DWTBallast: commonValidations.DWT,
        DWTCargo: commonValidations.DWT,
        DWTFreshWater: commonValidations.DWT,
        DWTSlops: commonValidations.DWT,
        DWTStores: commonValidations.DWT,
        DWTTotalBunkers: commonValidations.DWT,
        EtaDate: commonValidations.DateTimeRequired,
        EtaDateOffset: commonValidations.DateOffsetRequired,
        HoursSteamedSinceLastEvent: commonValidations.HoursSteamedSinceLastEvent,
        InstructedSpeed: commonValidations.InstructedSpeed,
        LastHullCleaning: commonValidations.DateTimeLimited,
        LastHullCleaningOffset: commonValidations.DateOffset,
        LastPropellerCleaning: commonValidations.DateTimeLimited,
        LastPropellerCleaningOffset: commonValidations.DateOffset,
        LeaveBerthTerminal: {
            ...commonValidations.DateTimeLimitedRequired,
            dateLimits: {
                ...commonValidations.DateTimeLimitedRequired.dateLimits,
                maxProp: 'DepartureDate'
            }
        },
        LeaveBerthTerminalOffset: commonValidations.DateOffsetRequired,
        NextPoint: commonValidations.Point,
        NumberOfTugsUsed: { required: true, numeric: { min: 0, max: 10, decimals: 0 } },
        PointOfDeparture: commonValidations.Point,
        PortOfLastHullCleaning: { required: false },
        PortOfLastPropellerCleaning: { required: false },
        Position: commonValidations.Position,
        Remarks: commonValidations.Remarks,
        ReportDate: commonValidations.DateTimeLimitedRequired,
        ReportDateOffset: commonValidations.DateOffsetRequired,
        TugReason: { required: true },
        TankCleaningStarted: {
            ...commonValidations.DateTimeLimitedRequired,
            dateLimits: {
                ...commonValidations.DateTimeLimitedRequired.dateLimits,
                maxProp: 'TankCleaningFinished'
            }
        },
        TankCleaningStartedOffset: commonValidations.DateOffsetRequired,
        TankCleaningFinished: {
            ...commonValidations.DateTimeLimitedRequired,
            dateLimits: {
                ...commonValidations.DateTimeLimitedRequired.dateLimits,
                minProp: 'TankCleaningStarted'
            }
        },
        TankCleaningFinishedOffset: commonValidations.DateOffsetRequired,
        TimeAtAnchorSinceLastEvent: commonValidations.TimeAtAnchorSinceLastEvent,
        TimeElapsedSinceLastEvent: commonValidations.TimeElapsedSinceLastEvent,
        TotalCargoSinceLastEvent: commonValidations.TotalCargoSinceLastEvent,
        WaterUsedForTankCleaning: { required: true, numeric: { min: 0, max: 1000 } },
        Terminal: commonValidations.Terminal,
        Vessel: commonValidations.Vessel,
        VesselStatus: commonValidations.VesselStatus,
        VoyageNumber: commonValidations.VoyageNumber,
        WindForceSinceLastEvent: commonValidations.WindForceSinceLastEvent
    },
    3: { // arrival
        AnchoredDate: commonValidations.DateTimeLimited,
        AnchoredDateOffset: commonValidations.DateOffset,
        ArrivingPilotStation: commonValidations.DateTimeRequired,
        ArrivingPilotStationOffset: commonValidations.DateOffsetRequired,
        AverageRpm: commonValidations.Rpm,
        AverageSpeed: { ...commonValidations.AverageSpeed, required: false },
        BunkerData: commonValidations.BunkerData,
        CallSign: commonValidations.CallSign,
        CargoOperations: { required: true },
        CargoStatus: commonValidations.CargoStatus,
        CharterpartyDate: commonValidations.DateTimeRequired,
        Consumptions: commonValidations.Consumptions,
        ConsumptionsSinceLastEvent: commonValidations.ConsumptionsSinceLastEvent,
        Displacement: commonValidations.Displacement,
        DistanceFromLastPort: commonValidations.Distance,
        DistanceSinceLastEvent: commonValidations.DistanceSinceLastEvent,
        DraftAft: commonValidations.DraftRequired,
        DraftMid: commonValidations.DraftRequired,
        DraftFwd: commonValidations.DraftRequired,
        EndOfSeaPassage: commonValidations.DateTimeLimitedRequired,
        EndOfSeaPassageOffset: commonValidations.DateOffsetRequired,
        EstimatedTimeOfBerthing: commonValidations.DateTime,
        EstimatedTimeOfBerthingOffset: commonValidations.DateOffset,
        EstimatedTimeOfDepartureFromBerth: commonValidations.DateTime,
        EstimatedTimeOfDepartureFromBerthOffset: commonValidations.DateOffset,
        FreePratiqueGranted: commonValidations.DateTime,
        FreePratiqueGrantedOffset: commonValidations.DateOffset,
        HoursSteamedSinceLastEvent: commonValidations.HoursSteamedSinceLastEvent,
        NoticeOfReadiness: commonValidations.DateTimeLimitedRequired,
        NoticeOfReadinessOffset: commonValidations.DateOffsetRequired,
        PointOfArrival: commonValidations.Point,
        Position: commonValidations.Position,
        Remarks: commonValidations.Remarks,
        ReportDate: commonValidations.DateTimeLimitedRequired,
        ReportDateOffset: commonValidations.DateOffsetRequired,
        Terminal: commonValidations.Terminal,
        TimeOfPassage: commonValidations.Duration,
        TimeAtAnchorSinceLastEvent: commonValidations.TimeAtAnchorSinceLastEvent,
        TimeElapsedSinceLastEvent: commonValidations.TimeElapsedSinceLastEvent,
        TotalCargoSinceLastEvent: commonValidations.TotalCargoSinceLastEvent,
        Vessel: commonValidations.Vessel,
        VesselStatus: commonValidations.VesselStatus,
        VoyageNumber: commonValidations.VoyageNumber,
        WindForceSinceLastEvent: commonValidations.WindForceSinceLastEvent
    },
    4: { // cargo
        BunkerData: {
            2: { numeric: commonValidations.BunkerData[2].numeric }
        },
        CallSign: commonValidations.CallSign,
        CharterpartyDate: commonValidations.DateTimeRequired,
        CommencedCargoDate: {
            ...commonValidations.DateTimeLimitedRequired,
            dateLimits: {
                ...commonValidations.DateTimeLimitedRequired.dateLimits,
                maxProp: 'CompletedCargoDate'
            }
        },
        CommencedCargoDateOffset: commonValidations.DateOffsetRequired,
        CompletedCargoDate: {
            ...commonValidations.DateTimeLimitedRequired,
            dateLimits: {
                ...commonValidations.DateTimeLimitedRequired.dateLimits,
                minProp: 'CommencedCargoDate'
            }
        },
        CompletedCargoDateOffset: commonValidations.DateOffsetRequired,
        Consumptions: commonValidations.ConsumptionsDaily,
        ConsumptionsSinceLastEvent: commonValidations.ConsumptionsSinceLastEvent,
        DischargeOperations: {
            Default: {
                ...consumptionValidations,
                atLeastOneRequired: atLeastOneRequired(dischargeOperationsRegex)
            },
            ConsumptionTypes: { required: true },
            FuelTypes: { required: true }
        },
        DistanceSinceLastEvent: commonValidations.DistanceSinceLastEvent,
        HoursSteamedSinceLastEvent: commonValidations.HoursSteamedSinceLastEvent,
        IsHeated: { required: true },
        IsUsingMainEngine: { required: true },
        NoOfWings: { noOfWingsRequired },
        Point: commonValidations.Point,
        PortActivityType: { required: true },
        Position: commonValidations.Position,
        QtyLoadedDischarged: { required: true, numeric: { min: 0, max: 200000, decimals: 0 } },
        LoadDischargeRateMaxFromTerminal: commonValidations.LoadDischargeRate,
        AverageLoadDischargeRate: commonValidations.LoadDischargeRate,
        PumpPressureMaxFromTerminal: commonValidations.PumpPressure,
        PumpPressureReached: commonValidations.PumpPressure,
        Remarks: commonValidations.Remarks,
        ReportDate: commonValidations.DateTimeLimitedRequired,
        ReportDateOffset: commonValidations.DateOffsetRequired,
        ShipToShipCargoTransfer: { required: true },
        Tanks: {
            Charterer: { tanksConditionallyRequired },
            Commodity: { tanksConditionallyRequired },
            Loaded: {
                numeric: { min: 0, max: 20000 },
                tanksConditionallyRequired,
                lessThanSize: {
                    rule: (value, params, components, name) => {
                        const prefix = name.split('.').slice(0, 3).join('.');
                        const size = components[`${prefix}.Size`];
                        return value && size && size.state.value
                            ? parseFloat(value) <= parseFloat(size.state.value)
                            : true;
                    },
                    hint: () => 'Must be less than \'Size\''
                },
                tanksLoadedRequired
            },
            Sg: { numeric: { min: 0, max: 2 }, tanksConditionallyRequired },
            Size: {
                numeric: { min: 0, max: 20000 },
                tanksConditionallyRequired,
                greaterThanLoaded: {
                    rule: (value, params, components, name) => {
                        const prefix = name.split('.').slice(0, 3).join('.');
                        const loaded = components[`${prefix}.Loaded`];
                        return value && loaded && loaded.state.value
                            ? parseFloat(value) >= parseFloat(loaded.state.value)
                            : true;
                    },
                    hint: () => 'Must be greater than \'Loaded\''
                }
            },
            Temperature: { numeric: { min: -20, max: 100 } }
        },
        Terminal: commonValidations.Terminal,
        TimeAtAnchorSinceLastEvent: commonValidations.TimeAtAnchorSinceLastEvent,
        TimeElapsedSinceLastEvent: commonValidations.TimeElapsedSinceLastEvent,
        TotalCargoSinceLastEvent: commonValidations.TotalCargoSinceLastEvent,
        Vessel: commonValidations.Vessel,
        VoyageNumber: commonValidations.VoyageNumber,
        WindForceSinceLastEvent: commonValidations.WindForceSinceLastEvent
    },
    5: { // event
        BunkerData: commonValidations.BunkerData,
        CallSign: commonValidations.CallSign,
        CargoStatus: commonValidations.CargoStatus,
        ConsumptionDuringStoppage: commonValidations.Consumptions,
        ConsumptionFromNoonToEvent: commonValidations.ConsumptionsDaily,
        DistanceSinceLastEvent: commonValidations.DistanceSinceLastEvent,
        EventType: { required: true },
        HoursSteamedSinceLastEvent: commonValidations.HoursSteamedSinceLastEvent,
        InstructedSpeed: { ...commonValidations.InstructedSpeed, required: false },
        Position: commonValidations.Position,
        Remarks: commonValidations.Remarks,
        ReportDate: commonValidations.DateTimeLimitedRequired,
        ReportDateOffset: commonValidations.DateOffsetRequired,
        Vessel: commonValidations.Vessel,
        VesselStatus: commonValidations.VesselStatus,
        VoyageNumber: commonValidations.VoyageNumber,
        PreviousPort: commonValidations.Point,
        SpecialArea: { required: true },
        TimeAtAnchorSinceLastEvent: commonValidations.TimeAtAnchorSinceLastEvent,
        TimeElapsedSinceLastEvent: commonValidations.TimeElapsedSinceLastEvent,
        TotalDistanceSteamedInArea: { required: true, ...commonValidations.Distance },
        TotalTimeSpentInArea: { required: true, duration: true },
        TotalCargoSinceLastEvent: commonValidations.TotalCargoSinceLastEvent,
        WindForceSinceLastEvent: commonValidations.WindForceSinceLastEvent
    },
    6: { // next leg
        CallSign: commonValidations.CallSign,
        CargoGrade: { required: true },
        CargoQuantity: { required: true, numeric: { min: 0, max: 1000000 } },
        CargoStatus: commonValidations.CargoStatus,
        Charterer: { maxLength: 50 },
        CharterPartyDate: commonValidations.DateTime,
        CharterPartyDateOffset: commonValidations.DateOffset,
        CharterPartySpeed: { ...commonValidations.AverageSpeed, required: false },
        DeparturePort: commonValidations.Point,
        DestinationPort: commonValidations.Point,
        EstimatedTimeOfDepartureFromBerthDate: commonValidations.DateTimeRequired,
        EstimatedTimeOfDepartureFromBerthDateOffset: commonValidations.DateOffsetRequired,
        ExpectedDepartureDraft: commonValidations.DraftRequired,
        Gm: { required: true, numeric: { min: 0, max: 30 } },
        RollPeriod: { required: true, numeric: { min: 0, max: 40 } },
        InstructedSpeed: commonValidations.InstructedSpeed,
        LayDaysFromDate: commonValidations.DateTime,
        LayDaysFromDateOffset: commonValidations.DateOffset,
        LayDaysToDate: commonValidations.DateTime,
        LayDaysToDateOffset: commonValidations.DateOffset,
        MastersEtaAtPilotStationDate: {
            dateLimits: {
                minProp: 'EstimatedTimeOfDepartureFromBerthDate',
                utc: true
            }
        },
        MastersEtaAtPilotStationDateOffset: commonValidations.DateOffset,
        MastersIntendedRpmSettingThisVoyage: commonValidations.RpmRequired,
        MastersName: { maxLength: 50 },
        Remarks: commonValidations.Remarks,
        ReportDate: commonValidations.DateTimeLimitedRequired,
        ReportDateOffset: commonValidations.DateOffsetRequired,
        RPMMax: { required: true, numeric: { ...commonValidations.Rpm.numeric, minProp: 'RPMMin' } },
        RPMMin: { required: true, numeric: { ...commonValidations.Rpm.numeric, maxProp: 'RPMMax' } },
        Vessel: commonValidations.Vessel,
        VoyageNumber: commonValidations.VoyageNumber,
        Waypoints: {
            Position: { required: true },
            NavigationType: { required: true }
        }
    },
    7: { // sof
        AllFast: commonValidations.DateTimeRequired,
        AllFastOffset: commonValidations.DateOffsetRequired,
        AnchorAweighStoppedDrifting: commonValidations.DateTime,
        AnchorAweighStoppedDriftingOffset: commonValidations.DateOffset,
        AnchoredCommencedDrifting: commonValidations.DateTime,
        AnchoredCommencedDriftingOffset: commonValidations.DateOffset,
        CallSign: commonValidations.CallSign,
        CargoDocumentsOnboard: commonValidations.DateTimeRequired,
        CargoDocumentsOnboardOffset: commonValidations.DateOffsetRequired,
        CargoOperations: {
            CommencedDate: {
                ...commonValidations.DateTimeRequired,
                dateLimits: {
                    minProp: (name) => {
                        const prevLeg = name.split('.').pop() - 1;
                        return prevLeg > -1 ? `CompletedDate.${prevLeg}` : undefined;
                    },
                    maxProp: (name) => `CompletedDate.${name.split('.').pop()}`,
                    utc: true
                }
            },
            CommencedDateOffset: commonValidations.DateOffsetRequired,
            CompletedDate: {
                ...commonValidations.DateTimeRequired,
                dateLimits: {
                    minProp: (name) => `CommencedDate.${name.split('.').pop()}`,
                    utc: true
                }
            },
            CompletedDateOffset: commonValidations.DateOffsetRequired
        },
        Charterer: { required: true },
        EndOfSeaPassage: commonValidations.DateTimeRequired,
        EndOfSeaPassageOffset: commonValidations.DateOffsetRequired,
        FreePractiqueGranted: commonValidations.DateTime,
        FreePractiqueGrantedOffset: commonValidations.DateOffset,
        HosesConnected: commonValidations.DateTime,
        HosesConnectedOffset: commonValidations.DateOffset,
        HosesDisconnected: commonValidations.DateTimeRequired,
        HosesDisconnectedOffset: commonValidations.DateOffsetRequired,
        NoticeOfReadiness: commonValidations.DateTimeRequired,
        NoticeOfReadinessOffset: commonValidations.DateOffsetRequired,
        PilotOnboard: commonValidations.DateTimeRequired,
        PilotOnboardOffset: commonValidations.DateOffsetRequired,
        Point: commonValidations.Point,
        Remarks: commonValidations.Remarks,
        ReportDate: commonValidations.DateTimeLimitedRequired,
        ReportDateOffset: commonValidations.DateOffsetRequired,
        Unmoored: commonValidations.DateTimeRequired,
        UnmooredOffset: commonValidations.DateOffsetRequired,
        Vessel: commonValidations.Vessel,
        VoyageNumber: commonValidations.VoyageNumber
    }
};

export const validationTypeMap = {
    Max: ['numeric.max', 'duration.max', 'timeSpan.max'],
    Min: ['numeric.min', 'duration.min', 'timeSpan.min'],
    Required: ['required'],
    MaxLength: ['maxLength'],
    MinLength: ['minLength']
};

export const ruleSetTypeMap = {
    TanksLoadedRequired: { name: 'tanksLoadedRequired', value: tanksLoadedRequired },
    NoOfWingsRequired: { name: 'noOfWingsRequired', value: noOfWingsRequired },
    WholeOrHalfNumber: { name: 'numeric.div', value: 0.5 },
    RobRequired: { name: 'robRequired', value: robRequired },
    RobTypeRequired: { name: 'robTypeRequired', value: robTypeRequired },
    LiftedSulphurRequired: { name: 'liftedSulphurRequired', value: liftedSulphurRequired },
    RequiredWhenLiftedSet: { name: 'requiredWhenLiftedSet', value: requiredWhenLiftedSet },
    SulphurRequired: { name: 'sulphurRequired', value: sulphurRequired },
    AtLeastOneRequired: {
        handle: (name, res, value) => {
            if (name.indexOf('Utilization') === 0) {
                if (value) {
                    setObjectProp(
                        res,
                        `${name}.atLeastOneRequired`,
                        atLeastOneRequired(name),
                        true
                    );
                } else {
                    deleteObjectProp(res, name, true);
                }
            } else if (value) {
                setObjectProp(
                    res,
                    `${name}.Default.atLeastOneRequired`,
                    atLeastOneRequired(consumptionRegexMap[name]),
                    true
                );
                setObjectProp(res, `${name}.ConsumptionTypes.required`, true, true);
                setObjectProp(res, `${name}.BunkerTypes.required`, true, true);
                setObjectProp(res, `${name}.FuelTypes.required`, true, true);
            } else {
                deleteObjectProp(res, `${name}.Default.atLeastOneRequired`, true);
                deleteObjectProp(res, `${name}.ConsumptionTypes.required`, true);
                deleteObjectProp(res, `${name}.BunkerTypes.required`, true);
                deleteObjectProp(res, `${name}.FuelTypes.required`, true);
            }
        }
    }
};
