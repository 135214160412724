/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
import getFontSize from 'utils/helpers/cached-font-size';
import { updateYAxisExtremes } from './chart-helpers';

const chartColors = [
    '#0c9c12', '#02a0e7',
    '#f48003', '#850ffd',
    '#88daff', '#bd2626',
    '#cc25b6', '#24e19c',
    '#ff8f8f', '#f4d503'
];
const colorResetBtn = '#fff';
const colorResetBtnBg = '#0e5370';
const colorResetBtnHoverBg = '#1d6484';
const colorChartPlotBg = '#0f3646';
const colorChartBg = '#0d2731';
const colorXAxisLabel = 'rgba(255, 255, 255, 0.6)';
const colorXAxisTitle = '#7da2b3';
const colorYAxisLabel = 'rgba(255, 255, 255, 0.9)';
const colorYAxisTitle = '#7da2b3';
const colorYAxisGridLine = 'transparent';
const colorMarkerLine = 'transparent';
const markerRadius = 3;
const colorTooltipBg = 'rgba(13, 39, 49, 0.9)';
const colorTooltipBorder = 'rgba(15, 32, 80, 0.6)';
const colorLegendHover = 'rgba(255, 255, 255, 1)';
const colorLegend = 'rgba(255, 255, 255, 0.8)';
const colorXAxisGridLine = 'rgba(14, 77, 103, 1)';

const getConfig = () => {
    const rem = getFontSize();
    const fsm = rem / 16;

    return {
        colors: chartColors,
        credits: {
            enabled: false
        },
        title: '',
        chart: {
            animation: false,
            backgroundColor: colorChartBg,
            plotBackgroundColor: colorChartPlotBg,
            spacing: [2 * rem, rem, rem, 2 * rem],
            zoomType: 'x',
            panning: true,
            panKey: 'shift',
            resetZoomButton: {
                position: {
                    x: 0,
                    y: -26 * fsm
                },
                theme: {
                    zIndex: 5,
                    fill: colorResetBtnBg,
                    style: {
                        color: colorResetBtn,
                        fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                        fontSize: '0.75rem',
                        textAlign: 'center'
                    },
                    stroke: 0,
                    r: 0,
                    height: 12 * fsm,
                    padding: 4 * fsm,
                    states: {
                        hover: {
                            fill: colorResetBtnHoverBg
                        }
                    }
                }
            },
            events: {
                render: (e) => {
                    const chart = e.target;
                    if (chart.options.chart.dynamicExtremes !== false) {
                        updateYAxisExtremes(chart);
                    }
                }
            }
        },
        navigator: {
            enabled: false,
            height: 40 * fsm,
            margin: 20 * fsm,
            handles: {
                height: 15 * fsm,
                width: 7 * fsm,
                lineWidth: fsm
            },
            series: {
                lineWidth: fsm
            },
            xAxis: {
                ordinal: false,
                series: {
                    dataGrouping: {
                        smoothed: false
                    }
                },
                labels: {
                    style: {
                        fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                        fontSize: '0.75rem'
                    }
                }
            }
        },
        rangeSelector: {
            enabled: false
        },
        scrollbar: {
            enabled: false,
            height: 6 * fsm,
            barBackgroundColor: 'rgba(40, 255, 202, 0.8)',
            barBorderRadius: 3 * fsm,
            barBorderWidth: 0,
            buttonBackgroundColor: 'transparent',
            buttonBorderWidth: 0,
            buttonBorderRadius: 0,
            rifleColor: 'transparent',
            trackBackgroundColor: '#0f2027',
            trackBorderWidth: 0,
            trackBorderRadius: 0,
            trackBorderColor: '#162f39',
            liveRedraw: true,
            states: {
                hover: {
                    height: 10 * fsm
                }
            }
        },
        legend: {
            enabled: true,
            itemHoverStyle: {
                color: colorLegendHover
            },
            itemStyle: {
                color: colorLegend,
                fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                fontSize: '0.75rem'
            },
            margin: 12 * fsm,
            padding: 5 * fsm,
            symbolHeight: 12 * fsm,
            symbolPadding: 5 * fsm,
            symbolRadius: 0
        },
        xAxis: {
            crosshair: false,
            endOnTick: true,
            gridLineColor: colorXAxisGridLine,
            gridLineWidth: 1,
            lineWidth: 0,
            maxPadding: 0,
            minPadding: 0,
            minRange: 2 * 24 * 3600 * 1000,
            minorGridLineWidth: 0,
            minorTickLength: 0,
            startOnTick: false,
            tickInterval: 24 * 3600 * 1000,
            tickLength: 8 * fsm,
            tickWidth: 1,
            tickmarkPlacement: 'on',
            type: 'datetime',
            labels: {
                autoRotation: [-60],
                style: {
                    color: colorXAxisLabel,
                    fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                    fontSize: '0.75rem',
                    whiteSpace: 'nowrap'
                },
                overflow: false,
                padding: 5 * fsm,
                y: 20 * fsm,
                /* eslint-disable */
                formatter: function () {
                    return TimeHelper.getFormatted(this.value);
                }
                /* eslint-enable */
            },
            title: {
                style: {
                    color: colorXAxisTitle,
                    fontFamily: 'Roboto, Arial, Helvetica, sans-serif'
                }
            },
            events: {
                setExtremes: (e) => {
                    const xAxis = e.target;
                    const maxDistance = 365 * 24 * 3600 * 1000;
                    if (xAxis.options.type === 'datetime' && (e.max - e.min) > maxDistance) {
                        const extremes = xAxis.getExtremes();
                        if (e.min === extremes.min || e.max === extremes.max) {
                            let min = extremes.min;
                            let max = extremes.max;
                            if (e.min !== extremes.min) {
                                min = e.max - maxDistance;
                            } else {
                                max = e.min + maxDistance;
                            }
                            window.setTimeout(() => {
                                xAxis.setExtremes(min, max);
                            }, 1);
                            return false;
                        }
                    }
                    return null;
                },
                afterSetExtremes: (e) => {
                    const chart = e.target.chart;
                    if (chart.options.chart.zoomType !== false) {
                        if (!chart.resetZoomButton) {
                            chart.showResetZoom();
                        }
                    }
                    // if (chart.options.chart.dynamicExtremes !== false) {
                    //     updateYAxisExtremes(chart);
                    // }
                },
                render: (e) => {
                    const chart = e.target.chart;
                    if (chart.options.chart.zoomType !== false) {
                        if (!chart.resetZoomButton) {
                            chart.showResetZoom();
                        }
                    }
                    // if (chart.options.chart.dynamicExtremes !== false) {
                    //     updateYAxisExtremes(chart);
                    // }
                }
            },
            /* eslint-disable */
            tickPositioner: function (min, max) {
                if (this.options.type === 'datetime') {
                    const realMin = this.dataMin > min ? this.dataMin : min;
                    const realMax = this.dataMax < max ? this.dataMax : max;
                    const day = 24 * 3600 * 1000;
                    const totalDaysSpan = (realMax - realMin) / day;
                    const tickPixelInterval = 1 * rem;
                    const maxTicks = this.width / tickPixelInterval;
                    const interval = Math.ceil(totalDaysSpan / maxTicks);
                    const ticks = this.getLinearTickPositions(interval * day, realMin, realMax);
                    if (ticks[ticks.length - 1] > realMax) {
                        ticks.pop();
                    }
                    return ticks;
                }
                return this.tickPositions;
            }
            /* eslint-enable */
        },
        yAxis: {
            opposite: false,
            gridLineColor: colorYAxisGridLine,
            tickWidth: 1,
            tickLength: 5 * fsm,
            gridLineWidth: 0,
            lineWidth: fsm,
            minorGridLineWidth: 1,
            minorTickLength: 1,
            labels: {
                padding: 5 * fsm,
                x: -8 * fsm,
                y: 3 * fsm,
                style: {
                    color: colorYAxisLabel,
                    fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                    fontSize: '0.75rem'
                }
            },
            title: {
                style: {
                    color: colorYAxisTitle,
                    fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
                    fontSize: '0.75rem'
                },
                text: null
            }
        },
        plotOptions: {
            area: {
                marker: {
                    enabled: true,
                    symbol: 'circle',
                    radius: markerRadius * fsm,
                    lineWidth: fsm,
                    lineColor: colorMarkerLine,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                }
            },
            line: {
                marker: {
                    enabled: true,
                    symbol: 'circle',
                    radius: markerRadius * fsm,
                    lineWidth: fsm,
                    lineColor: colorMarkerLine,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                }
            },
            series: {
                states: {
                    inactive: {
                        opacity: 0.8
                    }
                }
            }
        },
        tooltip: {
            outside: true,
            shared: true,
            split: false,
            useHTML: true,
            backgroundColor: colorTooltipBg,
            borderWidth: 1,
            borderColor: colorTooltipBorder,
            shadow: false,
            padding: 12 * fsm,
            headerFormat: '<div class="sten-chart-tooltip__header">{point.key}</div>',
            pointFormat:
                '<div class="sten-chart-tooltip__point" style="color: {series.color}">{series.name}: {point.y}</div>'
        }
    };
};

export default getConfig;
