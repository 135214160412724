import { createSelector } from 'reselect';
import { translate } from 'utils/i18n/i18n-model';
import { extractChartData } from '../kpi-helpers';

const emptyArray = [];

export const getAuxiliaryUtilizationChartData = createSelector(
    state => state.energyManagementKPIVesselReducer.auxiliaryUtilization,
    (auxiliaryUtilization) => auxiliaryUtilization
        && extractChartData(auxiliaryUtilization, ['BaseLine', 'Utilization'])
);

export const getAverageSpeedChartData = createSelector(
    state => state.energyManagementKPIVesselReducer.averageSpeed,
    (averageSpeed) => averageSpeed && extractChartData(averageSpeed, ['AverageSpeedBallast', 'AverageSpeedLaden'])
);

export const getBoilerUtilizationChartData = createSelector(
    state => state.energyManagementKPIVesselReducer.boilerUtilization,
    (boilerUtilization) => boilerUtilization
        && extractChartData(boilerUtilization, ['BaseLine', 'Utilization'])
);

export const getRPMAnalysisChartData = createSelector(
    state => state.energyManagementKPIVesselReducer.rpmAnalysis,
    (rpmAnalysis) => ({
        ballast: rpmAnalysis && extractChartData(rpmAnalysis.BallastGraph, ['RPM', 'AverageSpeed']),
        laden: rpmAnalysis && extractChartData(rpmAnalysis.LadenGraph, ['RPM', 'AverageSpeed'])
    })
);

export const getTotalData = createSelector(
    state => state.energyManagementKPIVesselReducer.topGraph,
    (topGraph) => ({
        consumptionChartData: topGraph && topGraph.MonthlyDataList && topGraph.MonthlyDataList.length > 0
            ? extractChartData(topGraph.MonthlyDataList, ['BallastKPI', 'LadenKPI', 'Cargo'])
            : null,
        energyEfficiencyChartData: topGraph
            && topGraph.EnergyEfficiencyDataList
            && topGraph.EnergyEfficiencyDataList.length > 0
            ? extractChartData(
                topGraph.EnergyEfficiencyDataList,
                ['Efficiency', 'Suezmax', 'P-MAX', 'Intermediate', 'Medium Range', 'AER'],
                { xAxisYear: true }
            )
            : null,
        data: topGraph && topGraph.TotalData
    })
);

const tu = (key) => translate(`UNITS.${key}`);
const tkv = (key) => translate(`ENERGY_MANAGEMENT.KPI.VESSEL.${key}`);
const tka = (key) => tkv(`KEY_AVERAGE_FIGURES.${key}`);
const tkt = (key) => tkv(`KEY_TOTAL_FIGURES.${key}`);

const getValueRows = (labels, value, keys) => {
    if (!value) {
        return [[labels[0], null, null]];
    }
    const props = keys || ['Value'];
    const rows = [];
    props.forEach((prop, index) => {
        rows.push([
            labels[index],
            value[prop],
            value[`Previous${prop}`]
        ]);
    });
    return rows;
};

const getBLRows = (label, value) => [
    [label],
    ...getValueRows([tkv('BALLAST'), tkv('LADEN')], value, ['Ballast', 'Laden'])
];

const getBLPercentRows = (label, value) => {
    if (!value) {
        return [[label, null, null]];
    }
    const ballastLaden = typeof value.Ballast === 'number' ? `${value.Ballast}% / ${value.Laden}%` : null;
    const prevBallastLaden = typeof value.PreviousBallast === 'number'
        ? `${value.PreviousBallast}% / ${value.PreviousLaden}%`
        : null;
    return [[label, ballastLaden, prevBallastLaden]];
};

const getBLPRows = (label, value) => [
    [label],
    ...getValueRows([tkv('BALLAST'), tkv('LADEN'), tkv('PORT')], value, ['Ballast', 'Laden', 'Port'])
];

export const getKeyAverageFigures = createSelector(
    state => state.energyManagementKPIVesselReducer.keyAverageFigures,
    (kaf) => {
        if (!kaf) {
            return emptyArray;
        }
        return [
            {
                decimals: 1,
                rows: getBLPRows(tka('BUNKER_CONSUMPTION_PROP'), kaf.BunkerConsumptionPropulsion),
                unit: tu('CONSUMPTION')
            },
            { decimals: 1,
                rows: getBLPRows(tka('BUNKER_CONSUMPTION_AUX'), kaf.BunkerConsumptionAuxilary),
                unit: tu('CONSUMPTION')
            },
            {
                decimals: 1,
                rows: getBLPRows(tka('BUNKER_CONSUMPTION_PROP_AUX'), kaf.BunkerConsumptionPropulsionAuxilary),
                unit: tu('CONSUMPTION')
            },
            {
                decimals: 0,
                rows: getValueRows([tka('WIND_SPEED_REPORTER')], kaf.WindSpeed),
                unit: tu('WIND_FORCE')
            },
            {
                decimals: 0,
                rows: getValueRows([tka('WIND_SPEED_WNI')], kaf.WniWindSpeed),
                unit: tu('WIND_FORCE')
            },
            {
                decimals: 1,
                rows: getBLRows(tka('DRAFT'), kaf.Draft),
                unit: tu('DRAFT')
            },
            {
                decimals: 1,
                rows: getBLRows(tka('TRIM'), kaf.Trim),
                unit: tu('TRIM') },
            { title: tka('OTHER_DATA') },
            {
                decimals: 1,
                rows: getValueRows([tka('LOAD_DISCHARGE_CONSUMPTION')], kaf.TotalLoadDisch),
                unit: tu('CONSUMPTION')
            },
            {
                decimals: 1,
                rows: getValueRows([tka('CARGO_HEATING')], kaf.TotalCargoHeating),
                unit: tu('CONSUMPTION')
            },
            {
                decimals: 1,
                rows: getValueRows([tka('TANK_CLEANING')], kaf.TotalTankCleaning),
                unit: tu('CONSUMPTION')
            },
            {
                decimals: 1,
                rows: getValueRows([tka('CARGO_CIRCULATION')], kaf.TotalCargoCirculation),
                unit: tu('CONSUMPTION')
            },
            {
                decimals: 1,
                rows: getValueRows([tka('BALLAST_EXCHANGE')], kaf.TotalBallastExchange),
                unit: tu('CONSUMPTION')
            },
            {
                decimals: 1,
                rows: getValueRows([tka('INERT_GAS')], kaf.TotalIg),
                unit: tu('CONSUMPTION')
            },
            {
                decimals: 1,
                rows: getValueRows([tka('BALLAST_SPEED')], kaf.BallastSpeed),
                unit: tu('SPEED')
            },
            {
                decimals: 1,
                rows: getValueRows([tka('LADEN_SPEED')], kaf.LadenSpeed),
                unit: tu('SPEED')
            },
            { decimals: 0,
                rows: getValueRows([tka('AUX_RUNNING_HOURS')], kaf.AuxUtilization),
                unit: tu('HOUR')
            },
            { decimals: 0,
                rows: getValueRows([tka('BOILER_RUNNING_HOURS')], kaf.BoilerUtilization),
                unit: tu('HOUR')
            }
        ];
    }
);

export const getKeyTotalFigures = createSelector(
    state => state.energyManagementKPIVesselReducer.keyTotalFigures,
    (ktf) => {
        if (!ktf) {
            return emptyArray;
        }
        return [
            {
                decimals: 0,
                rows: getValueRows([tkt('TOTAL_CARGO_LADEN')], ktf.TotalCargoLaden, ['Laden']),
                unit: tu('CARGO')
            },
            { decimals: 0, rows: getBLRows(tkt('DISTANCE'), ktf.TotalDistance), unit: tu('DISTANCE') },
            { decimals: 0, rows: getBLRows(tkt('NUMBER_OF_DAYS_REPORTER'), ktf.TotalDays), unit: tu('DAY') },
            { rows: getBLPercentRows(tkt('BALLAST_LADEN_REPORTER'), ktf.BallastLadenPercent) },
            {
                decimals: 1,
                rows: getBLPRows(tkt('BUNKER_CONSUMPTION_PROP'), ktf.TotalBunkerPropConsumption),
                unit: tu('CONSUMPTION')
            },
            {
                decimals: 1,
                rows: getBLPRows(tkt('BUNKER_CONSUMPTION_AUX'), ktf.TotalBunkerAuxConsumption),
                unit: tu('CONSUMPTION')
            },
            {
                decimals: 1,
                rows: getBLPRows(tkt('BUNKER_CONSUMPTION_PROP_AUX'), ktf.TotalBunkerPropAuxConsumption),
                unit: tu('CONSUMPTION')
            }
            // { title: tkt('ENERGY_EFFICIENCY') },
            // {
            //     decimals: 1,
            //     rows: getBLRows(tkt('BUNKER_CONSUMPTION_DIFF_MT'), ktf.TotalBunkerConsumptionDiff),
            //     unit: tu('ENERGY_EFFICIENCY_MT_KNM')
            // },
            // {
            //     decimals: 1,
            //     rows: getValueRows([tkt('BUNKER_KNM_CARGO')], ktf.Kpi, ['Kpi']),
            //     unit: tu('ENERGY_EFFICIENCY_MT_KNM')
            // },
            // { decimals: 1, rows: getValueRows([tkt('EEV')], ktf.Eev, ['EEV']), unit: tu('ENERGY_EFFICIENCY_MT_KNM') }
        ];
    }
);
