import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* actions */
import { getResources, reset } from './port-info-actions';
/* components */
import Comments from 'components/comments/comments';
import PortInfoCalls from './port-info-calls/port-info-calls';
import PortInfoDetails from './port-info-details/port-info-details';
import PortInfoOverview from './port-info-overview/port-info-overview';
import PortInfoTerminals from './port-info-terminals/port-info-terminals';
import TabSelector from 'components/tab-selector/tab-selector';
/* styles */
import './port-info.scss';

export class PortInfo extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            activeTabId: typeof props.activeTabId === 'number' ? props.activeTabId : 0
        };
    }

    componentDidMount() {
        if (this.props.portId) {
            this.props.getResources(this.props.portId);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.portId && this.props.portId !== prevProps.portId) {
            this.props.getResources(this.props.portId);
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

    getTabs = () => {
        const { permissions, port, comments, portId } = this.props;

        const hasDetailPermissions = permissions.GetPortVendors
            && permissions.GetPortBunkering
            && permissions.GetPortServices;

        const hasTerminalPermissions = permissions.ShowTerminals && permissions.ShowTerminalRestrictions;

        const tabs = [{
            id: 0,
            title: translate('PORT_INFO.OVERVIEW.TITLE'),
            icon: 'icon-overview',
            content: (<PortInfoOverview onShowMoreClick={this.setActiveTab.bind(this, 4)} portId={portId} />)
        }, {
            id: 1,
            title: translate('PORT_INFO.DETAILS.TITLE'),
            icon: 'icon-port-info',
            content: (<PortInfoDetails portId={portId} />),
            disabled: !hasDetailPermissions || port.Type === 1
        }, {
            id: 3,
            title: translate('PORT_INFO.PORT_CALLS.TITLE'),
            icon: 'icon-compare',
            content: (<PortInfoCalls portId={portId} />),
            disabled: !permissions.GetPortCalls
        }, {
            id: 4,
            title: translate('COMMENTS.TITLE'),
            icon: 'icon-message',
            content: (
                <Comments
                    canCreateComment={permissions.CreatePortComment}
                    canUpdateAllComments={permissions.UpdateAllPortComments}
                    canUpdateComments={permissions.UpdatePortComment}
                    comments={comments}
                    resource="port"
                    resourceId={portId}
                />
            ),
            disabled: !permissions.GetAllPortComments || (comments.length === 0 && !permissions.CreatePortComment)
        }];

        if (hasTerminalPermissions) {
            const terminalsTab = {
                id: 2,
                title: translate('PORT_INFO.TERMINALS'),
                icon: 'icon-terminal-alt',
                content: (
                    <PortInfoTerminals
                        permissions={this.props.permissions}
                        portId={this.props.portId}
                        terminals={this.props.terminals}
                    />
                ),
                disabled: port.Type === 1
            };
            tabs.splice(2, 0, terminalsTab);
        }

        return tabs;
    };

    setActiveTab = tabId => {
        if (tabId !== this.state.activeTabId) {
            this.setState({ activeTabId: tabId });
        }
    };

    render() {
        if (!this.props.port) {
            return (
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <div className="flex-grow" />
                        <div className="flex-shrink">
                            <button className="btn-link icon icon-close" onClick={this.props.onCloseClick} />
                        </div>
                    </div>
                    <div className="sten-content__body" />
                </div>
            );
        }
        const tabs = this.getTabs();
        const activeTab = tabs.find(tab => tab.id === this.state.activeTabId);
        return (
            <div className="sten-content sten-port-info">
                <div className="sten-content__header flex-row">
                    <div className="flex-grow">
                        <h1 className="sten-content__title text-ellipsis">{this.props.port.Name}</h1>
                        {this.props.port.Country && (
                            <p className="sten-content__subtitle">{this.props.port.Country.CountryName}</p>
                        )}
                    </div>
                    <div className="flex-shrink">
                        <button className="btn-link icon icon-close" onClick={this.props.onCloseClick} />
                    </div>
                </div>
                <div className="sten-content__body sten-port-info__body-fixed">
                    <TabSelector
                        tabItems={tabs}
                        activeTabId={this.state.activeTabId}
                        onChange={this.setActiveTab}
                    />
                </div>
                <div className="sten-content__body sten-port-info__body-content">{activeTab && activeTab.content}</div>
            </div>
        );
    }
}

PortInfo.propTypes = {
    activeTabId: PropTypes.number,
    comments: PropTypes.arrayOf(PropTypes.object).isRequired,
    getResources: PropTypes.func.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    port: PropTypes.objectOf(PropTypes.any),
    portId: PropTypes.number,
    reset: PropTypes.func.isRequired,
    terminals: PropTypes.arrayOf(PropTypes.object).isRequired
};

PortInfo.defaultProps = {
    activeTabId: 0,
    port: null,
    portId: null
};

function mapStateToProps(state) {
    return {
        comments: state.portInfoReducer.comments,
        permissions: state.userReducer.permissions,
        port: state.portInfoReducer.port,
        terminals: state.portInfoReducer.terminals
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getResources: portId => getResources(dispatch, portId),
        reset: () => reset(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PortInfo);
