import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import TimeHelper from 'utils/helpers/time-helper';
import { convertAndDownloadFile } from 'utils/helpers/file-helper';
/* components */
import Accordion from 'components/accordion/accordion';
import CompanySelect from 'components/company-select/company-select';
import DateTimePopup from 'components/date-time-popup/date-time-popup';
import FileUpload from 'components/file-upload/file-upload';
import FlipSwitch from 'components/flip-switch/flip-switch';
import Input from 'components/input/input';
import PortSelect from 'components/port-select/port-select';
import RightSideBar from 'components/right-side-bar/right-side-bar';
import ScrollArea from 'components/scroll-area/scroll-area';
import Select from 'components/select/select';
import TextArea from 'components/textarea/textarea';
import Validation from 'components/validation/validation';
import VesselSelect from 'components/vessel-select/vessel-select';
/* actions */
import {
    getInspectionCompanies,
    submitInspectionData,
    setAsNewCompany,
    setValue,
    uploadFiles,
    removeFile,
    getInspection,
    getVesselOptions,
    resetAll
} from './inspections-form-actions';
/* selectors */
import { getEditingInspection, getHeadOwners, getTechnicalManagers } from '../inspections-selectors';
/* Services */
import InspectionsService from 'services/core-api/inspections-service';
import SearchService from 'services/core-api/search-service';
/* styles */
import './inspections-form.scss';

const t = (key) => translate(`INSPECTIONS.FORM.${key}`);

const allowedFileTypes = [
    'application/vnd.ms-excel',
    'application/pdf',
    'application/x-compressed',
    'application/x-zip-compressed',
    'application/zip',
    'multipart/x-zip',
    'image/jpeg',
    'image/pjpeg',
    'image/jpeg',
    'image/png',
    'image/gif',
    'application/msword',
    'application/pkcs8',
    '.key',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/mspowerpoint',
    'application/powerpoint',
    'application/vnd.ms-powerpoint',
    'application/x-mspowerpoint',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.presentation',
    'application/excel',
    'application/vnd.ms-excel',
    'application/x-excel',
    'application/x-msexcel',
    'text/plain'
];

const customDateValidation = {
    rule: (value) => value && value.isDateInvalid !== true,
    hint: () => 'Date is invalid'
};

class InspectionsForm extends React.PureComponent {
    state = {
        isVisible: false
    };

    static getDerivedStateFromProps(props, state) {
        if (props.router.location.pathname !== state.route || props.router.params.id !== state.Id) {
            return {
                route: props.router.location.pathname,
                Id: props.router.params.id,
                isVisible: props.router.location.pathname.endsWith('new') || !!props.router.params.id
            };
        }
        return null;
    }

    componentDidMount() {
        this.props.getInspectionCompanies();
        if (this.props.router.params && this.props.router.params.id) {
            this.props.getInspection(this.props.router.params.id);
        }
        if (this.props.editingInspection.Vessel) {
            this.props.getVesselOptions(this.props.editingInspection.Vessel);
        }
    }

    componentDidUpdate(prevProps) {
        const { params, location } = this.props.router;
        const { params: prevParams, location: prevLocation } = prevProps.router;
        if (location.pathname !== prevLocation.pathname && location.pathname.endsWith('new')) {
            this.props.resetAll();
        }
        if (params && params.id && params.id !== prevParams.id) {
            this.props.getInspection(params.id);
        }
        if (this.props.editingInspection.Vessel
            && prevProps.editingInspection.Vessel !== this.props.editingInspection.Vessel) {
            this.props.getVesselOptions(this.props.editingInspection.Vessel);
        }
        if (this.props.editingInspection.NumberOfObservations !== null
            && this.props.editingInspection.NumberOfObservations !== prevProps.editingInspection.NumberOfObservations) {
            this.observationsInputRef.wrappedInstance.setFocus();
        }
    }

    componentWillUnmount() {
        this.props.resetAll();
    }

    handleCloseClick = () => {
        this.setState(null);
        this.props.resetAll();
        this.props.router.navigate(appRoutes.Vessel.Inspections);
    };

    onFilesAdd = files => {
        const filesData = new FormData();
        files.forEach(file => {
            if ((!this.props.editingInspection.Files.some(oldFile => oldFile.name === file.name))) {
                filesData.append(file.name, file);
            }
        });
        this.props.uploadFiles(filesData);
    };

    handleVesselSearch = (searchCriteria, mapVessel, resolve) => {
        const params = {
            Limit: 20,
            SearchTerm: searchCriteria
        };
        SearchService.getAllVesselsByScope(params).then((res) => {
            resolve(res && res.length
                ? res.map(vessel => mapVessel({ ...vessel, IsPrimary: !vessel.IsCompetition }))
                : null);
        });
    };

    handleFileDownload = (file) => {
        InspectionsService.downloadFile(file.id).then(response => {
            if (response) {
                convertAndDownloadFile(response, file);
            }
        });
    };

    handleFileDelete = ({ index }) => {
        this.props.removeFile(index);
    };

    setValue = (key, prop) => {
        return (value) => {
            if (prop && value && value[prop] !== undefined) {
                this.props.setValue(key, value[prop]);
            } else {
                this.props.setValue(key, value);
            }
        };
    };

    toggleObservationOptionStatus = () => {
        const value = this.props.editingInspection.NumberOfObservations === null ? '' : null;
        this.props.setValue('NumberOfObservations', value);
    };

    saveObservationsInputRef = c => { this.observationsInputRef = c; };

    render() {
        const { editingInspection } = this.props;
        const isEdit = typeof editingInspection.Id === 'number';
        return (
            <RightSideBar isCollapsed={!this.state.isVisible}>
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <h1 className="sten-content__title flex-grow">
                            {isEdit ? t('EDIT_INSPECTION') : t('ADD_NEW_INSPECTION')}
                        </h1>
                        <div className="flex-shrink">
                            <button
                                type="button"
                                className="btn-link icon icon-close"
                                onClick={this.handleCloseClick}
                            />
                        </div>
                    </div>
                    <Validation.Form
                        key={this.state.isVisible}
                        className="sten-inspections-sidebar__form"
                        onSubmit={this.props.submitInspectionData}
                    >
                        <ScrollArea className="sten-content__body" shouldScrollToActiveElement>
                            <Accordion
                                header={<h4 className="text-uppercase">{t('GENERAL_INFORMATION')}</h4>}
                                isCollapsedBodyRendered
                            >
                                <div className="sten-content__section">
                                    <div className="form-row">
                                        <label className="label">{t('VESSEL')}</label>
                                    </div>
                                    <Validation.Wrapper validations={{ required: true }} hintsOnHover>
                                        <VesselSelect
                                            autoFocus
                                            name="vessel"
                                            onSearch={this.handleVesselSearch}
                                            value={editingInspection.Vessel}
                                            onChange={this.setValue('Vessel')}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="sten-content__section">
                                    <div className="form-row">
                                        <label className="label">{t('INSPECTION_DATE')}</label>
                                    </div>
                                    <Validation.Wrapper
                                        validations={{ required: true, custom: customDateValidation }}
                                        hintsOnHover
                                    >
                                        <DateTimePopup
                                            isUTC
                                            alwaysShow6Weeks
                                            withTime={false}
                                            name="inspectionDate"
                                            alignment="start"
                                            dateType="object"
                                            dateFormat={TimeHelper.getFormat()}
                                            onDateSelect={this.setValue('InspectionDate')}
                                            value={editingInspection.InspectionDate}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="sten-content__section">
                                    <div className="form-row">
                                        <label className="label">{t('PORT')}</label>
                                    </div>
                                    <Validation.Wrapper validations={{ required: true }} hintsOnHover>
                                        <PortSelect
                                            name="port"
                                            fixedListWidth
                                            allowCustomPoint={false}
                                            searchForAll
                                            clearable
                                            value={editingInspection.Port}
                                            onChange={this.setValue('Port')}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="sten-content__section">
                                    <div className="form-row">
                                        <label className="label">{t('INSPECTION_TYPE')}</label>
                                    </div>
                                    <Validation.Wrapper validations={{ required: true }} hintsOnHover>
                                        <Select
                                            name="inspectionTypes"
                                            valueKey="Id"
                                            labelKey="Name"
                                            onChange={this.setValue('InspectionTypeId', 'Id')}
                                            options={this.props.inspectionTypes}
                                            value={editingInspection.InspectionType}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="sten-content__section">
                                    <div className="form-row">
                                        <label className="label">{t('INSPECTED_BY')}</label>
                                    </div>
                                    <Validation.Wrapper validations={{ required: true }} hintsOnHover>
                                        <CompanySelect
                                            searchable
                                            includeSearchInResults
                                            name="inspectedBy"
                                            value={editingInspection.InspectionCompany}
                                            options={this.props.inspectionCompanies}
                                            onChange={this.setValue('InspectionCompanyId', 'Id')}
                                            onCompanyAdd={this.props.setAsNewCompany}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="sten-content__section">
                                    <div className="form-row">
                                        <label className="label">{t('NUMBER_OF_OBSERVATIONS')}</label>
                                    </div>
                                    <div className="flex-row flex-center">
                                        <div className="flex-shrink">
                                            <FlipSwitch
                                                value={editingInspection.NumberOfObservations !== null}
                                                onChange={this.toggleObservationOptionStatus}
                                                activeLabel={translate('GLOBAL.KNOWN')}
                                                inactiveLabel={translate('GLOBAL.UNKNOWN')}
                                            />
                                        </div>
                                        <div className="flex-grow">
                                            <Validation.Wrapper
                                                hintsOnHover
                                                validations={{
                                                    required: editingInspection.NumberOfObservations !== null,
                                                    numeric: { min: 0, max: 50, decimals: 0 }
                                                }}
                                            >
                                                <Input
                                                    ref={this.saveObservationsInputRef}
                                                    disabled={editingInspection.NumberOfObservations === null}
                                                    name="numberOfObservations"
                                                    onChange={this.setValue('NumberOfObservations')}
                                                    value={editingInspection.NumberOfObservations}
                                                />
                                            </Validation.Wrapper>
                                        </div>
                                    </div>
                                </div>
                                <div className="sten-content__section">
                                    <div className="form-row">
                                        <label className="label">{t('INSPECTION_ACTIVITY')}</label>
                                    </div>
                                    <Validation.Wrapper validations={{ required: true }} hintsOnHover>
                                        <Select
                                            valueKey="Id"
                                            labelKey="Name"
                                            name="inspectionActivity"
                                            onChange={this.setValue('InspectionActivityId', 'Id')}
                                            options={this.props.inspectionActivities}
                                            value={editingInspection.InspectionActivity}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="sten-content__section">
                                    <div className="form-row">
                                        <label className="label">{t('TECHNICAL_MANAGER')}</label>
                                    </div>
                                    <Validation.Wrapper validations={{ required: true }} hintsOnHover>
                                        <Select
                                            fixedListWidth
                                            searchable
                                            name="TechnicalManagerId"
                                            clearable
                                            valueKey="Id"
                                            labelKey="Name"
                                            options={this.props.technicalManagers}
                                            onChange={this.setValue('TechnicalManagerId', 'Id')}
                                            value={editingInspection.TechnicalManager}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="sten-content__section">
                                    <div className="form-row">
                                        <label className="label">{t('HEAD_OWNER')}</label>
                                    </div>
                                    <Validation.Wrapper validations={{ required: true }} hintsOnHover>
                                        <Select
                                            fixedListWidth
                                            searchable
                                            name="HeadOwner"
                                            clearable
                                            valueKey="Id"
                                            labelKey="Name"
                                            optionRenderer={this.getOption}
                                            options={this.props.headOwners}
                                            onChange={this.setValue('HeadOwnerId', 'Id')}
                                            value={editingInspection.HeadOwner}
                                        />
                                    </Validation.Wrapper>
                                </div>
                                <div className="sten-content__section">
                                    <div className="form-row">
                                        <label className="label">{t('COMMENT')}</label>
                                    </div>
                                    <Validation.Wrapper validations={{ required: false, maxLength: 1000 }} hintsOnHover>
                                        <TextArea
                                            name="comment"
                                            value={editingInspection.Comment}
                                            placeholder={t('COMMENT')}
                                            onChange={this.setValue('Comment')}
                                        />
                                    </Validation.Wrapper>
                                </div>
                            </Accordion>
                            <Accordion
                                header={<h4 className="text-uppercase">{t('INSPECTION_DOCUMENTATION')}</h4>}
                                isCollapsedBodyRendered
                            >
                                <div className="sten-content__section">
                                    <FileUpload
                                        allowedFileTypes={allowedFileTypes}
                                        name="fileUpload"
                                        multiple
                                        files={editingInspection.Files}
                                        onChange={this.onFilesAdd}
                                        onDeleteClickCb={this.handleFileDelete}
                                        onDownloadClickCb={this.handleFileDownload}
                                    />
                                </div>
                            </Accordion>
                        </ScrollArea>
                        <footer className="sten-content__footer flex-row">
                            <div className="col-12">
                                <button
                                    onClick={this.handleCloseClick}
                                    className="btn btn--secondary col-24"
                                    type="button"
                                >
                                    {translate('GLOBAL.CANCEL')}
                                </button>
                            </div>
                            <div className="col-12">
                                <Validation.Button className="btn btn--primary col-24">
                                    {translate('GLOBAL.SAVE')}
                                </Validation.Button>
                            </div>
                        </footer>
                    </Validation.Form>
                </div>
            </RightSideBar>
        );
    }
}

InspectionsForm.propTypes = {
    editingInspection: PropTypes.objectOf(PropTypes.any).isRequired,
    getInspection: PropTypes.func.isRequired,
    getInspectionCompanies: PropTypes.func.isRequired,
    getVesselOptions: PropTypes.func.isRequired,
    headOwners: PropTypes.arrayOf(PropTypes.any).isRequired,
    inspectionActivities: PropTypes.arrayOf(PropTypes.any).isRequired,
    inspectionCompanies: PropTypes.arrayOf(PropTypes.any).isRequired,
    inspectionTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
    removeFile: PropTypes.func.isRequired,
    resetAll: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    setAsNewCompany: PropTypes.func.isRequired,
    setValue: PropTypes.func.isRequired,
    submitInspectionData: PropTypes.func.isRequired,
    technicalManagers: PropTypes.arrayOf(PropTypes.any).isRequired,
    uploadFiles: PropTypes.func.isRequired
};

InspectionsForm.defaultProps = {};

const mapStateToProps = (state) => {
    return {
        editingInspection: getEditingInspection(state),
        headOwners: getHeadOwners(state),
        inspectionActivities: state.inspectionsReducer.inspectionActivities,
        inspectionCompanies: state.inspectionsFormReducer.inspectionCompanies,
        inspectionTypes: state.inspectionsReducer.inspectionTypes,
        technicalManagers: getTechnicalManagers(state)
    };
};

function mapDispatchToProps(dispatch) {
    return {
        getVesselOptions: vessel => getVesselOptions(dispatch, vessel),
        getInspection: id => getInspection(dispatch, id),
        getInspectionCompanies: () => getInspectionCompanies(dispatch),
        removeFile: index => removeFile(dispatch, index),
        resetAll: () => resetAll(dispatch),
        setAsNewCompany: (companyName) => setAsNewCompany(dispatch, companyName),
        setValue: (field, value) => setValue(dispatch, field, value),
        submitInspectionData: () => submitInspectionData(dispatch),
        uploadFiles: files => uploadFiles(dispatch, files)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InspectionsForm));
