import { createSelector } from 'reselect';
import { getObjectProp } from 'utils/helpers/info-helper';

export const getVesselSpeedData = createSelector(
    state => state.energyManagementReportsFleetAverageSpeedReportReducer.VesselSpeedData,
    state => state.energyManagementReportsFleetAverageSpeedReportReducer.sortOrder,
    state => state.energyManagementReportsFleetAverageSpeedReportReducer.sortProp,

    (vessels, sortOrder, sortProp) => {
        const sortOrdering = sortOrder === 'DESC' ? -1 : 1;
        const vesselsClone = vessels.slice(0);

        return vesselsClone.sort((a, b) => {
            const valueA = getObjectProp(a, sortProp);
            const valueB = getObjectProp(b, sortProp);
            let result = 0;
            if (valueA === null || valueA === undefined || valueA < valueB) {
                result = -1;
            }
            if (valueB === null || valueB === undefined || valueA > valueB) {
                result = 1;
            }
            return result * sortOrdering;
        });
    }
);
