import { takeEvery, put, select } from 'redux-saga/effects';
/* utils */
import ColorHelper from 'utils/helpers/color-helper';
/* actions */
import { ActionTypes } from './wni-weather-actions';
import { ActionTypes as UserActionTypes } from 'pages/user-pages/user-actions';
/* services */
import MapOptionsService from 'services/core-api/map-options-service';
import ConfigService from 'services/config-api/config-service';

const weatherLayerColors = {
    icep: {
        0: '#009999',
        0.25: '#00CCCC',
        0.5: '#00FFFF',
        0.75: '#BFFFFF',
        1: '#FFFFFF'
    }
};

let prevWeatherSettings = null;

function* getWeatherData(action) {
    let { WeatherLayerType, MapOptionsWeatherLayerEnabled } = yield select(state => state.userReducer.settings);
    const { weatherLayerTypes } = yield select(state => state.wniWeatherReducer);
    if (action?.settings
        && (action.settings.MapOptionsWeatherLayerEnabled !== MapOptionsWeatherLayerEnabled
            || action.settings.WeatherLayerType !== WeatherLayerType)) {
        WeatherLayerType = action.settings.WeatherLayerType;
        MapOptionsWeatherLayerEnabled = action.settings.MapOptionsWeatherLayerEnabled;
    }
    if (!prevWeatherSettings
        || prevWeatherSettings.MapOptionsWeatherLayerEnabled !== MapOptionsWeatherLayerEnabled
        || prevWeatherSettings.WeatherLayerType !== WeatherLayerType) {
        prevWeatherSettings = { MapOptionsWeatherLayerEnabled, WeatherLayerType };
        let weatherData = null;
        const selectedWeatherLayer = weatherLayerTypes.find(wlt => wlt.Id === WeatherLayerType);
        if (ConfigService.featureToggles.showWNIWeather && MapOptionsWeatherLayerEnabled && selectedWeatherLayer) {
            const weatherResponse = yield MapOptionsService.getWeatherLayer({
                element: selectedWeatherLayer.Element,
                geoJson: true
            });
            if (weatherResponse) {
                const parsedFeatures = [];
                weatherResponse.features.forEach((feature) => {
                    if (feature.properties.Value !== '0') {
                        let featureColor;
                        if (weatherLayerColors[selectedWeatherLayer.Element]) {
                            featureColor = weatherLayerColors[selectedWeatherLayer.Element][feature.properties.Value];
                        } else {
                            featureColor = feature.properties.Color;
                        }
                        const parsedFeature = {
                            properties: {
                                ...feature.properties,
                                fill: { color: ColorHelper.rgba(featureColor, 0.2) },
                                stroke: { color: ColorHelper.rgba(featureColor, 0.3) },
                                layerType: selectedWeatherLayer.Element
                            },
                            type: 'Feature',
                            geometry: {
                                type: 'GeometryCollection',
                                geometries: feature.geometry.geometries.map(geom => ({
                                    coordinates: [geom.coordinates.map(coordinate => coordinate.slice(0, 2))],
                                    type: 'Polygon'
                                }))
                            }
                        };
                        parsedFeatures.push(parsedFeature);
                    }
                });
                weatherData = {
                    type: 'FeatureCollection',
                    features: parsedFeatures
                };
            }
        }
        yield put({ type: ActionTypes.WNI_WEATHER_SET_DATA, weatherData });
    }
}

function* handleUserSettingsUpdate(action) {
    if (action.settingUpdated === 'mapOptions') {
        yield getWeatherData(action);
    }
}

export default function* wniWeatherSaga() {
    yield takeEvery(ActionTypes.WNI_WEATHER_GET_DATA, getWeatherData);
    yield takeEvery(UserActionTypes.USER_SETTINGS_SET, handleUserSettingsUpdate);
}
