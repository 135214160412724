export default [
    {
        BaseLine: 5.0,
        Date: '2019-10-01T00:00:00Z',
        Utilization: 0.0
    },
    {
        BaseLine: 5.0,
        Date: '2019-11-01T00:00:00Z',
        Utilization: 0.0
    },
    {
        BaseLine: 5.0,
        Date: '2019-12-01T00:00:00Z',
        Utilization: 0.0
    },
    {
        BaseLine: 5.0,
        Date: '2020-01-01T00:00:00Z',
        Utilization: 0.0
    },
    {
        BaseLine: 5.0,
        Date: '2020-02-01T00:00:00Z',
        Utilization: 6.0213675213675213
    },
    {
        BaseLine: 5.0,
        Date: '2020-03-01T00:00:00Z',
        Utilization: 2.8928571428571428
    },
    {
        BaseLine: 5.0,
        Date: '2020-04-01T00:00:00Z',
        Utilization: 4.0897435897435894
    },
    {
        BaseLine: 5.0,
        Date: '2020-05-01T00:00:00Z',
        Utilization: 1.2850427350427351
    },
    {
        BaseLine: 5.0,
        Date: '2020-06-01T00:00:00Z',
        Utilization: 0.26538461538461539
    },
    {
        BaseLine: 5.0,
        Date: '2020-07-01T00:00:00Z',
        Utilization: 7.2083333333333339
    },
    {
        BaseLine: 5.0,
        Date: '2020-08-01T00:00:00Z',
        Utilization: 3.9375
    },
    {
        BaseLine: 5.0,
        Date: '2020-09-01T00:00:00Z',
        Utilization: 0.0
    },
    {
        BaseLine: 5.0,
        Date: '2020-10-01T00:00:00Z',
        Utilization: 1.9807692307692308
    },
    {
        BaseLine: 5.0,
        Date: '2020-11-01T00:00:00Z',
        Utilization: null
    },
    {
        BaseLine: 5.0,
        Date: '2020-12-01T00:00:00Z',
        Utilization: null
    },
    {
        BaseLine: 5.0,
        Date: '2021-01-01T00:00:00Z',
        Utilization: null
    },
    {
        BaseLine: 5.0,
        Date: '2021-02-01T00:00:00Z',
        Utilization: null
    },
    {
        BaseLine: 5.0,
        Date: '2021-03-01T00:00:00Z',
        Utilization: null
    },
    {
        BaseLine: 5.0,
        Date: '2021-04-01T00:00:00Z',
        Utilization: null
    },
    {
        BaseLine: 5.0,
        Date: '2021-05-01T00:00:00Z',
        Utilization: null
    },
    {
        BaseLine: 5.0,
        Date: '2021-06-01T00:00:00Z',
        Utilization: 8.0
    },
    {
        BaseLine: 5.0,
        Date: '2021-07-01T00:00:00Z',
        Utilization: 1.2333333333333334
    },
    {
        BaseLine: 5.0,
        Date: '2021-08-01T00:00:00Z',
        Utilization: 0.14285714285714285
    },
    {
        BaseLine: 5.0,
        Date: '2021-09-01T00:00:00Z',
        Utilization: 1.5
    }
];
