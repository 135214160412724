import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import EditableList from 'components/editable-list/editable-list';
import Input from 'components/input/input';
import Textarea from 'components/textarea/textarea';
import Validation from 'components/validation/validation';

const t = (key) => translate(`ADMIN.COMPANY_MANAGEMENT.OFFICE.${key}`);

export class AdminCompanyManagementOffice extends React.PureComponent {
    handleCityChange = (value) => {
        this.props.onChange(this.props.index, { ...this.props.office, City: value });
    };

    handleAddressChange = (value) => {
        this.props.onChange(this.props.index, { ...this.props.office, Address: value });
    };

    handlePhonesChange = (Phones) => {
        this.props.onChange(this.props.index, { ...this.props.office, Phones });
    };

    handleEmailsChange = (Emails) => {
        this.props.onChange(this.props.index, { ...this.props.office, Emails });
    };

    handleDelete = () => {
        this.props.onDelete(this.props.index);
    };

    render() {
        const { index, office } = this.props;
        return (
            <div className="sten-panel sten-admin-company-management-office">
                <div className="sten-panel__header flex-row">
                    <div className="flex-grow">
                        <h4>{`${t('TITLE')} ${index + 1}`}</h4>
                    </div>
                    <div className="flex-shrink">
                        <button
                            type="button"
                            className="btn-link btn--icon icon icon-delete"
                            onClick={this.handleDelete}
                        />
                    </div>
                </div>
                <div className="sten-panel__body">
                    <div className="sten-panel__section">
                        <div className="form-row">
                            <label className="label">{t('CITY')}</label>
                            <Validation.Wrapper validations={{ required: true }}>
                                <Input
                                    name={`Offices[${index}].City`}
                                    value={office.City || ''}
                                    onChange={this.handleCityChange}
                                />
                            </Validation.Wrapper>
                        </div>
                        <div className="form-row">
                            <label className="label">{t('ADDRESS')}</label>
                            <Validation.Wrapper validations={{ required: true }}>
                                <Textarea
                                    name={`Offices[${index}].Address`}
                                    value={office.Address || ''}
                                    onChange={this.handleAddressChange}
                                />
                            </Validation.Wrapper>
                        </div>
                    </div>
                    <div className="sten-panel__section sten-panel__separator">
                        <EditableList
                            addBtnLabel={t('PHONE_ADD')}
                            label={t('PHONE')}
                            placeholder={t('PHONE_PLACEHOLDER')}
                            name={`Offices[${index}].Phones`}
                            validations={{ required: true, phone: true }}
                            onChange={this.handlePhonesChange}
                            value={office.Phones}
                        />
                    </div>
                    <div className="sten-panel__section sten-panel__separator">
                        <EditableList
                            addBtnLabel={t('EMAIL_ADD')}
                            label={t('EMAIL')}
                            placeholder={t('EMAIL_PLACEHOLDER')}
                            name={`Offices[${index}].Emails`}
                            validations={{ required: true, email: true }}
                            onChange={this.handleEmailsChange}
                            value={office.Emails}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

AdminCompanyManagementOffice.propTypes = {
    index: PropTypes.number.isRequired,
    office: PropTypes.objectOf(PropTypes.any).isRequired,
    onChange: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

export default AdminCompanyManagementOffice;
