import { ActionTypes } from './fleet-activity-actions';

const initialState = {
    rangeModes: {
        PREDEFINED_RANGE: 1,
        CUSTOM_DATE_RANGE: 2
    },
    stats: null,
    selectedRange: null,
    chartData: null
};

export default function fleetActivityReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.FLEET_ACTIVITY_SET: {
        return {
            ...state,
            stats: action.stats,
            chartData: action.chartData
        };
    }
    case ActionTypes.FLEET_ACTIVITY_SET_SELECTED_RANGE: {
        return {
            ...state,
            selectedRange: action.selectedRange
        };
    }
    default:
        return state;
    }
}
