import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* types */
import {
    TEuEts,
    TFootprint,
    TVmsFootprint,
    TYearlyRatingWithCorrection
} from 'components/footprint-table/footprint-table-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { renderLabelValueRows } from 'utils/helpers/template-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import { dismissNotifications } from 'pages/user-pages/notifications/notifications-actions';
import { sendReminder } from 'components/vessel-report/vessel-report-actions';
import { updateUserSettings } from 'pages/user-pages/user-actions';
import { getVoyageEuEts } from 'pages/user-pages/home/home-actions';
/* selectors */
import { getParsedLatestReport } from '../../../home-selectors';
import { getReportFuelTypes } from 'components/vessel-report/vessel-report-selectors';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import Accordion from 'components/accordion/accordion';
import Checkbox from 'components/checkbox/checkbox';
import FootprintTable from 'components/footprint-table/footprint-table';
import Notification from 'components/notification/notification';
import NotificationEmpty from 'components/notification/notification-empty';
import Progress from 'components/progress/progress';
import ScrollArea from 'components/scroll-area/scroll-area';
import SendBudgetEmail from 'components/send-budget-email/send-budget-email';
import VesselInfoEnergyBunkerRob from '../vessel-info-energy/vessel-info-energy-bunker-rob';
import VesselInfoEnergyPerformance from '../vessel-info-energy/vessel-info-energy-performance';
import VesselInfoEnergyPerformanceWithSensors from '../vessel-info-energy/vessel-info-energy-performance-sensors';
import VesselInfoCards from '../vessel-info-cards/vessel-info-cards';
/* constants */
import { vesselOverviewCargoRows, vesselOverviewRows } from '../vessel-info-constants';
/* styles */
import './vessel-info-overview.scss';

export class VesselInfoOverview extends React.PureComponent {
    state = {
        activeConfirmNotificationId: null
    };

    getOverview = (vesselBase, vesselDetails, vesselRelativeDetails, vesselPosition, vesselSensorData) => {
        const data = {
            aisData: (vesselRelativeDetails && vesselRelativeDetails.Ais) || null,
            reportData: (vesselRelativeDetails && vesselRelativeDetails.Report) || null,
            vesselBase,
            vesselDetails,
            vesselPosition,
            vesselSensorData,
            selectedVoyage: this.props.selectedVoyage,
            selectedVoyageCharterer: this.props.selectedVoyageCharterer,
            permissions: this.props.permissions,
            isNowSelected: this.props.isNowSelected
        };

        return (
            <div className="sten-content__section">
                {renderLabelValueRows(data, vesselOverviewRows)}
            </div>
        );
    }

    shouldShowVesselActivity() {
        return this.props.isRange && this.props.permissions.ShowVesselActivity && this.props.selectedVoyageActivity;
    }

    componentDidUpdate(prevProps) {
        if (
            this.shouldShowVesselActivity()
            && this.props.settings.IncludeTCVoyagesVessel !== prevProps.settings.IncludeTCVoyagesVessel
        ) {
            this.props.getVoyageActivity();
        }
    }

    handleTCheckboxChange = () => {
        return this.props.updateUserSettings({
            IncludeTCVoyagesVessel: !this.props.settings.IncludeTCVoyagesVessel
        });
    };

    getActivity = () => (
        <Accordion header={<h4 className="text-uppercase">{t('VESSEL_INFO.OVERVIEW.ACTIVITY')}</h4>}>
            <div className="sten-content__section">
                <div className="flex-row">
                    <div className="flex-shrink">
                        <Checkbox
                            isChecked={this.props.settings.IncludeTCVoyagesVessel}
                            onChange={this.handleTCheckboxChange}
                        >
                            {t('VESSEL_INFO.OVERVIEW.INCLUDE_TC_VOYAGES')}
                        </Checkbox>
                    </div>
                </div>
            </div>
            <div className="sten-content__section">
                <label className="sten-panel__label">{t('VESSEL_INFO.OVERVIEW.LADEN_BALAST_RATIO')}</label>
                <Progress
                    value={this.props.selectedVoyageActivity.PercentageLaden}
                    refValue={this.props.selectedVoyageActivity.PercentageBallast}
                    label="Laden"
                    refLabel="Ballast"
                />
            </div>
        </Accordion>
    );

    getNotifications = () => (
        <Accordion header={<h4 className="text-uppercase">{t('VESSEL_INFO.OVERVIEW.NOTIFICATIONS')}</h4>}>
            {(!this.props.notifications || this.props.notifications.length === 0) ? (
                <NotificationEmpty key="body">
                    <p className="text-secondary">{t('VESSEL_INFO.OVERVIEW.NO_NOTIFICATIONS')}</p>
                </NotificationEmpty>
            ) : this.props.notifications.map(notification => (
                <Notification
                    showReportedAsLink={this.props.permissions.GetReports}
                    key={notification.NotificationId}
                    notification={notification}
                    onConfirmToggle={this.toggleConfirmId}
                    hideActions={!this.props.permissions.UpdateNotificationStatus}
                    sendReminderAllowed={this.props.permissions.SendMissingReportReminder}
                    isConfirmActive={this.state.activeConfirmNotificationId === notification.NotificationId}
                    hideTitle
                    onDismissConfirm={this.dismissNotification}
                    onReminderConfirm={this.props.sendReminder}
                />
            ))}
        </Accordion>
    );

    getCargo = (fixtures) => {
        const sections = [];
        fixtures.forEach((fixture, index) => {
            if (fixture.Cargoes && fixture.Cargoes.length) {
                fixture.Cargoes.forEach((cargo, cargoIndex) => {
                    sections.push(
                        <div
                            key={`${index}${cargoIndex}`} // eslint-disable-line react/no-array-index-key
                            className={`sten-content__section${sections.length > 0 ? ' sten-content__separator' : ''}`}
                        >
                            {renderLabelValueRows({ cargo, fixture }, vesselOverviewCargoRows)}
                        </div>
                    );
                });
            }
        });

        if (sections.length === 0) {
            return null;
        }
        return (
            <Accordion header={(
                <h4 className="text-uppercase">
                    {t('VESSEL_INFO.OVERVIEW.CARGO')}
                </h4>
            )}
            >
                {sections}
            </Accordion>
        );
    };

    toggleConfirmId = (notificationId) => {
        this.setState({
            activeConfirmNotificationId: notificationId
        });
    };

    dismissNotification = id => this.props.dismissNotifications([id]);

    linkToVoyagePerformancePage = () => this.props.router.getLinkTo({
        pathname: appRoutes.Vessel.VoyagePerformance,
        query: {
            vessel: this.props.router.query.vessel,
            voyage: this.props.selectedVoyage ? this.props.selectedVoyage.VoyageId : this.props.router.query.voyage
        }
    });

    getEmissions = () => {
        return (
            <Accordion header={(
                <h4 className="text-uppercase">
                    {t('VESSEL_INFO.OVERVIEW.FOOTPRINT.TITLE')}
                </h4>
            )}
            >
                <FootprintTable
                    isRange={this.props.isRange}
                    selectedDate={this.props.selectedDate}
                    vesselCii={this.props.selectedVesselCii}
                    voyageFootprint={this.props.selectedVoyageFootprint}
                    voyageEuEts={this.props.selectedVoyageEuEts}
                    onEuEtsYearChange={this.props.getVoyageEuEts}
                    euEtsPrice={this.props.euEtsPrice}
                    euEtsYearsAvailable={this.props.euEtsYearsAvailable}
                    voyageVmsFootprint={this.props.selectedVoyageVmsFootprint}
                    showVesselCii={!ConfigService.hiddenFeatures.mapVesselInfoVesselCiiRating}
                    showVoyageEmissions={!ConfigService.hiddenFeatures.mapVesselInfoVoyageEmissions}
                    showVoyageEnergyEfficiency={!ConfigService.hiddenFeatures.mapVesselInfoVoyageEnergyEfficiency}
                    showConsumption={!ConfigService.hiddenFeatures.mapVesselInfoFootprintFuelConsumptions}
                    showVmsVoyageEmissions={!ConfigService.hiddenFeatures.mapVesselInfoVmsVoyageEmissions}
                />
            </Accordion>
        );
    };

    getPerformanceAccordionTitle = (voyage, vesselBase, report) => (
        <React.Fragment>
            <h4 key="title" className="flex-grow text-uppercase">
                {t('VESSEL_INFO.OVERVIEW.VESSEL_PERFORMANCE')}
                {report.Date && (
                    <span className="text-secondary">
                        {` - ${TimeHelper.getFormatted(report.Date, { utc: true, time: true })}`}
                    </span>
                )}
            </h4>
            {voyage && ConfigService.featureToggles.showSendBudgetEmail && (
                <SendBudgetEmail key="send-budget-email" voyageId={this.props.selectedVoyage.VoyageId} />
            )}
            {voyage && voyage.VoyagePerformance && vesselBase.VesselTypeId !== 12 && (
                <div key="button" className="flex-shrink text-right">
                    <Link
                        className="btn-link icon icon-full-screen"
                        to={this.linkToVoyagePerformancePage()}
                    />
                </div>
            )}
        </React.Fragment>
    )

    getVoyagePerformance = (report, voyage, vesselBase, sensorData) => {
        if (ConfigService.featureToggles.showSensorData && sensorData) {
            return (
                <VesselInfoEnergyPerformanceWithSensors latestVoyageReport={report} sensorData={sensorData}>
                    {this.getPerformanceAccordionTitle(voyage, vesselBase, report)}
                </VesselInfoEnergyPerformanceWithSensors>
            );
        }

        return (
            <VesselInfoEnergyPerformance latestVoyageReport={report}>
                {this.getPerformanceAccordionTitle(voyage, vesselBase, report)}
            </VesselInfoEnergyPerformance>
        );
    }

    render() {
        const {
            fuelTypes,
            isRange,
            latestVoyageReport: report,
            notifications,
            permissions,
            selectedVesselSensorData: vesselSensorData,
            selectedVesselBase: vesselBase,
            selectedVesselDetails: vesselDetails,
            selectedVesselPosition: vesselPosition,
            selectedVesselRelativeDetails: vesselRelativeDetails,
            selectedVoyage: voyage,
            selectedVoyageEarnings: earnings,
            selectedVoyageFootprint,
            selectedVoyageFixtures: fixtures,
            selectedVoyageVmsFootprint
        } = this.props;
        const showEarningsAndEmissionKPIs = this.props.showEarningsInfo && permissions?.GetVoyageEarnings;
        const isAllowedToSeeNotifications = permissions?.MenuNotifications;
        return (
            <ScrollArea className="sten-vessel-info-overview">
                {this.getOverview(vesselBase, vesselDetails, vesselRelativeDetails, vesselPosition, vesselSensorData)}
                {showEarningsAndEmissionKPIs && (
                    <VesselInfoCards
                        earnings={earnings}
                        ciiRating={selectedVoyageFootprint?.CII}
                        ciiRatingWithCorrection={selectedVoyageFootprint?.CIIWithCorrections}
                        vmsCiiRating={selectedVoyageVmsFootprint?.CII}
                        voyage={voyage}
                        isRange={isRange}
                    />
                )}
                {isAllowedToSeeNotifications && notifications && this.getNotifications()}
                {report && permissions.GetVoyagePerformance
                && this.getVoyagePerformance(report, voyage, vesselBase, vesselSensorData)}
                <VesselInfoEnergyBunkerRob latestVoyageReport={report} fuelTypes={fuelTypes} />
                {this.getEmissions()}
                {fixtures && fixtures.length > 0 && this.getCargo(fixtures)}
                {this.shouldShowVesselActivity() && this.getActivity()}
            </ScrollArea>
        );
    }
}

VesselInfoOverview.propTypes = {
    dismissNotifications: PropTypes.func.isRequired,
    euEtsPrice: PropTypes.objectOf(PropTypes.any),
    euEtsYearsAvailable: PropTypes.arrayOf(PropTypes.object).isRequired,
    fuelTypes: PropTypes.shape({
        list: PropTypes.arrayOf(PropTypes.object),
        map: PropTypes.objectOf(PropTypes.any)
    }).isRequired,
    getVoyageActivity: PropTypes.func.isRequired,
    getVoyageEuEts: PropTypes.func.isRequired,
    improvisedVoyage: PropTypes.objectOf(PropTypes.any),
    isNowSelected: PropTypes.bool.isRequired,
    isRange: PropTypes.bool.isRequired,
    latestVoyageReport: PropTypes.objectOf(PropTypes.any),
    notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    router: TRouter.isRequired,
    selectedDate: PropTypes.string,
    selectedVesselBase: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedVesselCii: TYearlyRatingWithCorrection,
    selectedVesselDetails: PropTypes.objectOf(PropTypes.any),
    selectedVesselPosition: PropTypes.objectOf(PropTypes.any),
    selectedVesselRelativeDetails: PropTypes.objectOf(PropTypes.any),
    selectedVesselSensorData: PropTypes.objectOf(PropTypes.any),
    selectedVoyage: PropTypes.objectOf(PropTypes.any),
    selectedVoyageActivity: PropTypes.objectOf(PropTypes.any),
    selectedVoyageCharterer: PropTypes.string,
    selectedVoyageEarnings: PropTypes.objectOf(PropTypes.any),
    selectedVoyageFootprint: TFootprint,
    selectedVoyageEuEts: TEuEts,
    selectedVoyageFixtures: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedVoyageVmsFootprint: TVmsFootprint,
    sendReminder: PropTypes.func.isRequired,
    settings: PropTypes.objectOf(PropTypes.any).isRequired,
    showEarningsInfo: PropTypes.bool.isRequired,
    updateUserSettings: PropTypes.func.isRequired
};

VesselInfoOverview.defaultProps = {
    euEtsPrice: null,
    improvisedVoyage: null,
    latestVoyageReport: null,
    selectedDate: null,
    selectedVesselCii: null,
    selectedVesselDetails: null,
    selectedVesselPosition: null,
    selectedVesselRelativeDetails: null,
    selectedVesselSensorData: null,
    selectedVoyage: null,
    selectedVoyageActivity: null,
    selectedVoyageCharterer: null,
    selectedVoyageEarnings: null,
    selectedVoyageEuEts: null,
    selectedVoyageFootprint: null,
    selectedVoyageVmsFootprint: null
};

function mapStateToProps(state) {
    return {
        euEtsPrice: state.euEtsReducer.euEtsPrice,
        euEtsYearsAvailable: state.euEtsReducer.yearsAvailable,
        fuelTypes: getReportFuelTypes(state, getParsedLatestReport(state)),
        improvisedVoyage: state.homeReducer.improvisedVoyage,
        isNowSelected: state.mapDatePickerReducer.isNowSelected,
        latestVoyageReport: getParsedLatestReport(state),
        permissions: state.userReducer.permissions,
        selectedDate: state.mapDatePickerReducer.selectedDate,
        selectedVesselBase: state.homeReducer.selectedVesselBase,
        selectedVesselCii: state.homeReducer.selectedVesselCii,
        selectedVesselDetails: state.homeReducer.selectedVesselDetails,
        selectedVesselPosition: state.homeReducer.selectedVesselPosition,
        selectedVesselRelativeDetails: state.homeReducer.selectedVesselRelativeDetails,
        selectedVesselSensorData: state.homeReducer.selectedVesselSensorData,
        selectedVoyage: state.homeReducer.selectedVoyage,
        selectedVoyageActivity: state.homeReducer.selectedVoyageActivity,
        selectedVoyageCharterer: state.homeReducer.selectedVoyageCharterer,
        selectedVoyageEarnings: state.homeReducer.selectedVoyageEarnings,
        selectedVoyageFootprint: state.homeReducer.selectedVoyageFootprint,
        selectedVoyageEuEts: state.homeReducer.selectedVoyageEuEts,
        selectedVoyageFixtures: state.homeReducer.selectedVoyageFixtures,
        selectedVoyageVmsFootprint: state.homeReducer.selectedVoyageVmsFootprint,
        settings: state.userReducer.settings
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dismissNotifications: ids => dismissNotifications(dispatch, ids),
        getVoyageEuEts: year => getVoyageEuEts(dispatch, year),
        sendReminder: id => sendReminder(dispatch, id),
        updateUserSettings: settings => updateUserSettings(dispatch, settings)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VesselInfoOverview));
