import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* types */
import { TQuestionnaire } from './questionnaire-types';
/* utils */
import TimeHelper from 'utils/helpers/time-helper';
import { getClassName, setObjectProp } from 'utils/helpers/info-helper';
import { renderFormFields, timeFormattingOptions } from 'components/form-fields/form-fields-helper';
import { t } from 'utils/i18n/i18n-model';
/* actions */
import {
    deleteQuestionnaire,
    getEditingOptions,
    getQuestionnaire,
    resetAll,
    updateQuestionnaire
} from './questionnaire-actions';
/* selectors */
import { selectEditingOptions } from './questionnaire-selectors';
/* components */
import Accordion from 'components/accordion/accordion';
import Menu, { MenuItem } from 'components/menu/menu';
import Validation from 'components/validation/validation';
import ScrollArea from 'components/scroll-area/scroll-area';
/* styles */
import './questionnaire.scss';
/* constants */
import { sectionsAndFields } from './questionnaire-sections-and-fields';
import { fieldMap, dataMap } from './questionnaire-field-map';

export class Questionnaire extends React.PureComponent {
    state = {
        editingQuestionnaire: null
    };

    static getDerivedStateFromProps(props, state) {
        if (props.isEditing
            && props.questionnaire
            && props.permissions.UpdateReport
            && (!state.editingQuestionnaire || state.editingQuestionnaire.ReportId !== props.questionnaire.ReportId)) {
            return { editingQuestionnaire: { ...props.questionnaire } };
        }
        if (!props.isEditing && state.editingQuestionnaire && props.id) {
            return { editingQuestionnaire: null };
        }
        return null;
    }

    componentDidMount() {
        if (this.props.id) {
            this.props.getQuestionnaire(this.props.id);
        }
        this.props.getEditingOptions();
    }

    componentDidUpdate(prevProps) {
        if (this.props.id && (prevProps.id !== this.props.id)) {
            this.props.getQuestionnaire(this.props.id);
        }
    }

    componentWillUnmount() {
        this.props.resetAll();
    }

    handleClose = () => this.props.router.updateQueryParams({ remove: ['questionnaireId', 'questionnaireEdit'] });

    handleCancel = () => this.props.router.updateQueryParams({ remove: ['questionnaireEdit'] });

    handleEdit = () => this.props.router.updateQueryParams({ add: { questionnaireEdit: true } });

    handleDelete = () => this.props.deleteQuestionnaire(this.props.id);

    handleSubmit = () => this.props.updateQuestionnaire(this.state.editingQuestionnaire);

    handleValueChange = (prop) => (value) => {
        this.setState((prevState) => {
            const editingQuestionnaire = setObjectProp({ ...prevState.editingQuestionnaire }, prop, value, true);
            return { editingQuestionnaire };
        });
    };

    renderFields = (fields, params) => {
        return renderFormFields(
            params.data,
            fieldMap,
            fields,
            params.isEditing,
            params.handleChange,
            params.inline,
            params.editingOptions
        );
    };

    renderSection = (section, sectionIndex) => {
        const params = {
            data: this.state.editingQuestionnaire || this.props.questionnaire,
            handleChange: this.handleValueChange,
            index: sectionIndex,
            inline: section.inline === undefined ? true : section.inline,
            isEditing: !!this.state.editingQuestionnaire,
            editingOptions: this.props.editingOptions
        };
        if (section.data) {
            return dataMap[section.data] ? dataMap[section.data](params) : null;
        }
        return (
            <div key={sectionIndex} className="sten-questionnaire__section">
                {section.title ? <h4 className="text-secondary">{section.title}</h4> : null}
                {this.renderFields(section.fields, params)}
            </div>
        );
    };

    renderContent = () => {
        return sectionsAndFields.map((section, sectionIndex) => {
            if (section.type === 'accordion') {
                return (
                    <Accordion
                        /* eslint-disable-next-line react/no-array-index-key */
                        key={sectionIndex}
                        header={<h4 className="text-uppercase">{section.title}</h4>}
                        {...section.accordionProps}
                    >
                        {section.sections
                            ? section.sections.map(this.renderSection)
                            : this.renderSection(section)}
                    </Accordion>
                );
            }
            return this.renderSection(section, sectionIndex);
        });
    };

    render() {
        const { questionnaire, permissions } = this.props;
        if (!questionnaire) {
            return (
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <div className="flex-grow" />
                        <div className="flex-shrink">
                            <button className="btn-link icon icon-close" onClick={this.handleClose} />
                        </div>
                    </div>
                    <div className="sten-content__body" />
                </div>
            );
        }
        const sidebarContentClass = getClassName('sten-questionnaire sten-content', {
            'sten-content--has-footer': this.state.editingQuestionnaire
        });

        let reportDateFormatted = '';

        if (!this.state.editingQuestionnaire) {
            const date = TimeHelper.getFormatted(questionnaire.ReportDate, timeFormattingOptions);
            const dateOffset = questionnaire.ReportDateOffset ? ` (UTC ${questionnaire.ReportDateOffset})` : '';
            reportDateFormatted = `${date}${dateOffset}`;
        }

        return (
            <div className={sidebarContentClass}>
                <div className="sten-content__header flex-row">
                    <div className="flex-grow">
                        <h1 className="sten-content__title">{t('QUESTIONNAIRE.TITLE')}</h1>
                        {reportDateFormatted && <p className="sten-content__subtitle">{reportDateFormatted}</p>}
                    </div>
                    {!this.state.editingQuestionnaire
                        && (permissions.UpdateQuestionnaire || permissions.DeleteQuestionnaire)
                        && (
                            <div className="flex-shrink">
                                <Menu>
                                    {permissions.UpdateQuestionnaire && (
                                        <MenuItem onClick={this.handleEdit} icon="icon-edit">
                                            {t('GLOBAL.EDIT')}
                                        </MenuItem>
                                    )}
                                    {permissions.UpdateQuestionnaire && (
                                        <MenuItem onClick={this.handleDelete} icon="icon-delete">
                                            {t('GLOBAL.DELETE')}
                                        </MenuItem>
                                    )}
                                </Menu>
                            </div>
                        )
                    }
                    <div className="flex-shrink">
                        <button className="btn-link icon icon-close" onClick={this.handleClose} />
                    </div>
                </div>
                <Validation.Form className="sten-vessel-report__content" onSubmit={this.handleSubmit}>
                    <ScrollArea className="sten-content__body" shouldScrollToActiveElement>
                        {this.renderContent()}
                    </ScrollArea>
                    {this.state.editingQuestionnaire && (
                        <footer className="sten-content__footer flex-row">
                            <div className="col-12">
                                <button
                                    className="btn btn--secondary col-24"
                                    type="button"
                                    onClick={this.handleCancel}
                                >
                                    {t('GLOBAL.CANCEL')}
                                </button>
                            </div>
                            <div className="col-12">
                                <Validation.Button className="btn btn--primary col-24">
                                    {t('GLOBAL.SAVE_CHANGES')}
                                </Validation.Button>
                            </div>
                        </footer>
                    )}
                </Validation.Form>
            </div>
        );
    }
}

Questionnaire.propTypes = {
    deleteQuestionnaire: PropTypes.func.isRequired,
    editingOptions: PropTypes.objectOf(PropTypes.any).isRequired,
    getEditingOptions: PropTypes.func.isRequired,
    getQuestionnaire: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isEditing: PropTypes.bool.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    questionnaire: TQuestionnaire,
    router: TRouter.isRequired,
    resetAll: PropTypes.func.isRequired,
    updateQuestionnaire: PropTypes.func.isRequired
};

Questionnaire.defaultProps = {
    id: null,
    questionnaire: null
};

function mapStateToProps(state) {
    return {
        editingOptions: selectEditingOptions(state),
        permissions: state.userReducer.permissions,
        questionnaire: state.questionnaireReducer.questionnaire
    };
}

function mapDispatchToProps(dispatch) {
    return {
        deleteQuestionnaire: (id) => deleteQuestionnaire(dispatch, id),
        getEditingOptions: () => getEditingOptions(dispatch),
        getQuestionnaire: (id) => getQuestionnaire(dispatch, id),
        resetAll: () => resetAll(dispatch),
        updateQuestionnaire: (data) => updateQuestionnaire(dispatch, data)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Questionnaire));
