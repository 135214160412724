import MockHelper from 'utils/helpers/mock-helper';

const emissionKeys = ['CO2', 'SOx', 'NOx', 'PM'];

const generateDataRow = (fuelTypes) => {
    const LadenPercentageBasedOnDistance = MockHelper.getRandomNumber(0, 100, 2, 0, 0.6);
    const LadenPercentageBasedOnTime = MockHelper.getRandomNumber(0, 100, 2, 0, 0.6);
    const BunkerData = MockHelper.getFuelTypeQuantities(0, 1000, 0, fuelTypes);
    return {
        LadenPercentageBasedOnDistance,
        BallastPercentageBasedOnDistance: 100 - LadenPercentageBasedOnDistance,
        LadenPercentageBasedOnTime,
        BallastPercentageBasedOnTime: 100 - LadenPercentageBasedOnTime,
        DaysInPortPercentage: MockHelper.getRandomNumber(0, 100, 2, 0, 0.3),
        OffHireDays: MockHelper.getRandomNumber(0, 100, 0, 0, 0.1),
        BunkerData,
        BunkerTotal: BunkerData.reduce((res, ft) => res + ft.Quantity, 0),
        Emissions: {
            CO2: MockHelper.getRandomNumber(20000, 40000, 2),
            SOx: MockHelper.getRandomNumber(100, 600),
            NOx: MockHelper.getRandomNumber(400, 1000),
            PM: MockHelper.getRandomNumber(4, 15, 1)
        }
    };
};

const calculateDelta = (selected, previous) => ({
    LadenPercentageBasedOnDistance: selected.LadenPercentageBasedOnDistance - previous.LadenPercentageBasedOnDistance,
    BallastPercentageBasedOnDistance:
        selected.BallastPercentageBasedOnDistance - previous.BallastPercentageBasedOnDistance,
    LadenPercentageBasedOnTime: selected.LadenPercentageBasedOnTime - previous.LadenPercentageBasedOnTime,
    BallastPercentageBasedOnTime: selected.BallastPercentageBasedOnTime - previous.BallastPercentageBasedOnTime,
    DaysInPortPercentage: selected.DaysInPortPercentage - previous.DaysInPortPercentage,
    OffHireDays: selected.OffHireDays - previous.OffHireDays,
    BunkerData: selected.BunkerData.map((ft, index) => ({
        ...ft,
        Quantity: ft.Quantity - previous.BunkerData[index].Quantity
    })),
    BunkerTotal: selected.BunkerTotal - previous.BunkerTotal,
    Emissions: {
        CO2: selected.Emissions.CO2 - previous.Emissions.CO2,
        SOx: selected.Emissions.SOx - previous.Emissions.SOx,
        NOx: selected.Emissions.NOx - previous.Emissions.NOx,
        PM: selected.Emissions.PM - previous.Emissions.PM
    }
});

const calculateDeltaPercentage = (delta, previous) => ({
    LadenPercentageBasedOnDistance:
        (delta.LadenPercentageBasedOnDistance / previous.LadenPercentageBasedOnDistance) * 100,
    BallastPercentageBasedOnDistance:
        (delta.BallastPercentageBasedOnDistance / previous.BallastPercentageBasedOnDistance) * 100,
    LadenPercentageBasedOnTime: (delta.LadenPercentageBasedOnTime / previous.LadenPercentageBasedOnTime) * 100,
    BallastPercentageBasedOnTime: (delta.BallastPercentageBasedOnTime / previous.BallastPercentageBasedOnTime) * 100,
    DaysInPortPercentage: (delta.DaysInPortPercentage / previous.DaysInPortPercentage) * 100,
    OffHireDays: (delta.OffHireDays / previous.OffHireDays) * 100,
    BunkerData: delta.BunkerData.map((ft, index) => ({
        ...ft,
        Quantity: (ft.Quantity / previous.BunkerData[index].Quantity) * 100
    })),
    BunkerTotal: (delta.BunkerTotal / previous.BunkerTotal) * 100,
    Emissions: {
        CO2: (delta.Emissions.CO2 / previous.Emissions.CO2) * 100,
        SOx: (delta.Emissions.SOx / previous.Emissions.SOx) * 100,
        NOx: (delta.Emissions.NOx / previous.Emissions.NOx) * 100,
        PM: (delta.Emissions.PM / previous.Emissions.PM) * 100
    }
});

const generateSegmentData = () => {
    const fuelTypes = MockHelper.getRandomFuelTypes(2, 5);
    const QuarterSelected = generateDataRow(fuelTypes);
    const QuarterPrevious = generateDataRow(fuelTypes);
    const QuarterDelta = calculateDelta(QuarterSelected, QuarterPrevious);
    const YearToDateSelected = generateDataRow(fuelTypes);
    const YearToDatePrevious = generateDataRow(fuelTypes);
    const YearToDateDelta = calculateDelta(YearToDateSelected, YearToDatePrevious);
    const RollingSelected = generateDataRow(fuelTypes);
    const RollingPrevious = generateDataRow(fuelTypes);
    const RollingDelta = calculateDelta(RollingSelected, RollingPrevious);

    return {
        QuarterSelected,
        QuarterPrevious,
        QuarterDelta,
        QuarterDeltaPercentage: calculateDeltaPercentage(QuarterDelta, QuarterPrevious),
        YearToDateSelected,
        YearToDatePrevious,
        YearToDateDelta,
        YearToDateDeltaPercentage: calculateDeltaPercentage(YearToDateDelta, YearToDatePrevious),
        RollingSelected,
        RollingPrevious,
        RollingDelta,
        RollingDeltaPercentage: calculateDeltaPercentage(RollingDelta, RollingPrevious)
    };
};

const props = [
    { name: 'QuarterSelected' },
    { name: 'QuarterPrevious' },
    { name: 'QuarterDelta' },
    { name: 'QuarterDeltaPercentage', type: 'avg' },
    { name: 'YearToDateSelected' },
    { name: 'YearToDatePrevious' },
    { name: 'YearToDateDelta' },
    { name: 'YearToDateDeltaPercentage', type: 'avg' },
    { name: 'RollingSelected' },
    { name: 'RollingPrevious' },
    { name: 'RollingDelta' },
    { name: 'RollingDeltaPercentage', type: 'avg' }
];

const sumKeys = [
    'LadenPercentageBasedOnDistance', 'BallastPercentageBasedOnDistance', 'LadenPercentageBasedOnTime',
    'BallastPercentageBasedOnTime', 'DaysInPortPercentage', 'OffHireDays', 'BunkerTotal'
];

const getCalculatedSummary = (segments) => {
    const summary = {
        QuarterSelected: {},
        QuarterPrevious: {},
        QuarterDelta: {},
        QuarterDeltaPercentage: {},
        YearToDateSelected: {},
        YearToDatePrevious: {},
        YearToDateDelta: {},
        YearToDateDeltaPercentage: {},
        RollingSelected: {},
        RollingPrevious: {},
        RollingDelta: {},
        RollingDeltaPercentage: {}
    };

    const bunkerDataFuelIndexMap = {};

    props.forEach(({ name, type }) => {
        if (segments.length > 0) {
            segments.forEach(segment => {
                sumKeys.forEach(key => {
                    if (summary[name][key] === undefined) {
                        summary[name][key] = 0;
                    }
                    summary[name][key] += segment.Summary[name][key];
                });
                summary[name].Emissions = {};
                emissionKeys.forEach(key => {
                    if (summary[name].Emissions[key] === undefined) {
                        summary[name].Emissions[key] = 0;
                    }
                    summary[name].Emissions[key] += segment.Summary[name].Emissions[key];
                });
                summary[name].BunkerData = [];
                bunkerDataFuelIndexMap[name] = {};
                segment.Summary[name].BunkerData.forEach(ft => {
                    if (bunkerDataFuelIndexMap[name][ft.Id] === undefined) {
                        bunkerDataFuelIndexMap[name][ft.Id] = summary[name].BunkerData.length;
                        summary[name].BunkerData.push(ft);
                    } else {
                        summary[name].BunkerData[bunkerDataFuelIndexMap[name][ft.Id]].Quantity += ft.Quantity;
                    }
                });
            });
            summary[name].LadenPercentageBasedOnDistance /= segments.length;
            summary[name].BallastPercentageBasedOnDistance /= segments.length;
            summary[name].LadenPercentageBasedOnTime /= segments.length;
            summary[name].BallastPercentageBasedOnTime /= segments.length;
            summary[name].DaysInPortPercentage /= segments.length;
            summary[name].OffHireDays /= segments.length;
            if (type === 'avg') {
                summary[name].BunkerTotal /= segments.length;
                emissionKeys.forEach((key) => {
                    summary[name].Emissions[key] /= segments.length;
                });
                summary[name].BunkerData.forEach(ft => {
                    summary[name].BunkerData[bunkerDataFuelIndexMap[name][ft.Id]].Quantity /= segments.length;
                });
            }
        } else {
            sumKeys.forEach(key => {
                if (summary[name][key] === undefined) {
                    summary[name][key] = 0;
                }
            });
            summary[name].Emissions = {};
            emissionKeys.forEach(key => {
                if (summary[name].Emissions[key] === undefined) {
                    summary[name].Emissions[key] = 0;
                }
            });
            summary[name].BunkerData = [];
        }
    });

    return summary;
};

/* eslint-disable no-param-reassign */
export const generateMockedQuarterlyReport = (userVessels, min = 50, max = 200) => {
    const vessels = MockHelper.getArraySlice(userVessels, min, max);
    const segmentMap = {};
    const data = { Summary: {}, Segments: [] };
    vessels.forEach((vessel) => {
        if (segmentMap[vessel.VesselTypeId] === undefined) {
            data.Segments.push({
                Name: vessel.VesselTypeName,
                Id: vessel.VesselTypeId,
                Summary: generateSegmentData()
            });
            segmentMap[vessel.VesselTypeId] = data.Segments.length - 1;
        }
    });

    data.Summary = getCalculatedSummary(data.Segments);

    return data;
};
