export const ActionTypes = {
    INSPECTIONS_GET_INSPECTIONS: 'INSPECTIONS_GET_INSPECTIONS',
    INSPECTIONS_SET_INSPECTIONS: 'INSPECTIONS_SET_INSPECTIONS',
    INSPECTIONS_GET_OPTIONS: 'INSPECTIONS_GET_OPTIONS',
    INSPECTIONS_SET_OPTIONS: 'INSPECTIONS_SET_OPTIONS',
    INSPECTIONS_SET_SELECTED_FILTER_OPTION: 'INSPECTIONS_SET_SELECTED_FILTER_OPTION',
    INSPECTIONS_SET_SORT_PROP: 'INSPECTIONS_SET_SORT_PROP',
    INSPECTIONS_TOGGLE_SELECTED_INSPECTION: 'INSPECTIONS_TOGGLE_SELECTED_INSPECTION',
    INSPECTIONS_TOGGLE_FILTERED_INSPECTIONS: 'INSPECTIONS_TOGGLE_FILTERED_INSPECTIONS',
    INSPECTIONS_SET_SELECTED_INSPECTIONS: 'INSPECTIONS_SET_SELECTED_INSPECTIONS',
    INSPECTIONS_GET_TECHNICAL_MANAGERS: 'INSPECTIONS_GET_TECHNICAL_MANAGERS',
    INSPECTIONS_SET_TECHNICAL_MANAGERS: 'INSPECTIONS_SET_TECHNICAL_MANAGERS',
    INSPECTIONS_DELETE_INSPECTION: 'INSPECTIONS_DELETE_INSPECTION',
    INSPECTIONS_EXPORT_TO_XLSX: 'INSPECTIONS_EXPORT_TO_XLSX',
    INSPECTIONS_GET_HEAD_OWNERS: 'INSPECTIONS_GET_HEAD_OWNERS',
    INSPECTIONS_SET_HEAD_OWNERS: 'INSPECTIONS_SET_HEAD_OWNERS',
    INSPECTIONS_EXPORT_TO_PDF: 'INSPECTIONS_EXPORT_TO_PDF',
    INSPECTIONS_UPDATE_INSPECTION: 'INSPECTIONS_UPDATE_INSPECTION',
    INSPECTIONS_RESET_ALL: 'INSPECTIONS_RESET_ALL'
};

export function exportToXlsx(dispatch) {
    dispatch({ type: ActionTypes.INSPECTIONS_EXPORT_TO_XLSX });
}

export function exportToPdf(dispatch) {
    dispatch({ type: ActionTypes.INSPECTIONS_EXPORT_TO_PDF });
}

export function getInspections(dispatch, loadMore = false) {
    dispatch({ type: ActionTypes.INSPECTIONS_GET_INSPECTIONS, loadMore });
}

export function getOptions(dispatch) {
    dispatch({ type: ActionTypes.INSPECTIONS_GET_OPTIONS });
}

export function deleteInspection(dispatch, inspectionId) {
    dispatch({
        type: ActionTypes.INSPECTIONS_DELETE_INSPECTION,
        inspectionId
    });
}

export function setSelectedFilterOption(dispatch, option, value) {
    dispatch({
        type: ActionTypes.INSPECTIONS_SET_SELECTED_FILTER_OPTION,
        option,
        value
    });
}

export function setSortProp(dispatch, sortProp) {
    dispatch({
        type: ActionTypes.INSPECTIONS_SET_SORT_PROP,
        sortProp
    });
}

export function toggleSelectedInspection(dispatch, inspectionId) {
    dispatch({
        type: ActionTypes.INSPECTIONS_TOGGLE_SELECTED_INSPECTION,
        inspectionId
    });
}

export function getTechnicalManagers(dispatch) {
    dispatch({
        type: ActionTypes.INSPECTIONS_GET_TECHNICAL_MANAGERS
    });
}

export function getHeadOwners(dispatch) {
    dispatch({
        type: ActionTypes.INSPECTIONS_GET_HEAD_OWNERS
    });
}

export function toggleFilteredInspections(dispatch) {
    dispatch({
        type: ActionTypes.INSPECTIONS_TOGGLE_FILTERED_INSPECTIONS
    });
}

export function setSelectedInspections(dispatch, selectedInspections) {
    dispatch({
        type: ActionTypes.INSPECTIONS_SET_SELECTED_INSPECTIONS,
        selectedInspections
    });
}

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.INSPECTIONS_RESET_ALL
    });
}
