import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
/* styles */
import './nav-link.scss';

class NavLink extends React.PureComponent {
    getClassNames() {
        let classNames = 'sten-nav-link';
        if (this.props.isActive) {
            classNames += ' sten-nav-link--active';
        }
        if (this.props.className) {
            classNames += ` ${this.props.className}`;
        }
        return classNames;
    }

    render() {
        const iconClassNames = `sten-nav-link__icon icon icon-${this.props.icon}`;
        if (this.props.hasContentLink) {
            return (
                <div className={this.getClassNames()} title={this.props.tooltipTitle}>
                    {this.props.icon && (
                        <span className={iconClassNames} onClick={this.props.onClick} />
                    )}
                    {this.props.children && (
                        <Link
                            tabIndex="-1"
                            className="sten-nav-link__content"
                            to={this.props.to}
                            onClick={this.props.onClick}
                            target={this.props.target}
                        >
                            {this.props.children}
                        </Link>
                    )}
                </div>
            );
        }
        if (this.props.to) {
            return (
                <Link
                    className={this.getClassNames()}
                    to={this.props.to}
                    onClick={this.props.onClick}
                    title={this.props.tooltipTitle}
                    target={this.props.target}
                >
                    {this.props.icon && (
                        <span className={iconClassNames} />
                    )}
                    {this.props.children && (
                        <span className="sten-nav-link__content">{this.props.children}</span>
                    )}
                </Link>
            );
        }
        if (this.props.href) {
            return (
                <a
                    className={this.getClassNames()}
                    href={this.props.href}
                    onClick={this.props.onClick}
                    title={this.props.tooltipTitle}
                    target={this.props.target}
                >
                    {this.props.icon && (
                        <span className={iconClassNames} />
                    )}
                    {this.props.children && (
                        <span className="sten-nav-link__content">{this.props.children}</span>
                    )}
                </a>
            );
        }
        return (
            <button className={this.getClassNames()} onClick={this.props.onClick} title={this.props.tooltipTitle}>
                {this.props.icon && (
                    <span className={iconClassNames} />
                )}
                {this.props.children && (
                    <span className="sten-nav-link__content">{this.props.children}</span>
                )}
            </button>
        );
    }
}

NavLink.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    hasContentLink: PropTypes.bool,
    href: PropTypes.string,
    icon: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    target: PropTypes.string,
    to: PropTypes.string,
    tooltipTitle: PropTypes.string
};

NavLink.defaultProps = {
    children: null,
    className: '',
    hasContentLink: false,
    href: '',
    icon: '',
    isActive: false,
    target: '',
    tooltipTitle: '',
    to: ''
};

export default NavLink;
