import { createSelector } from 'reselect';
import createByIdSelector from 'utils/helpers/selector-helper';

export const getAllActiveNotifications = createSelector(
    state => state.homeReducer.selectedVesselId,
    state => state.homeReducer.selectedVesselNotifications,
    state => state.notificationsReducer.activeNotifications,

    (vesselId, vesselNotifications, activeNotifications) => {
        if (vesselNotifications.length === 0
            || activeNotifications.findIndex(notification => notification.IMO === vesselId) > -1) {
            return activeNotifications;
        }
        return activeNotifications.concat(vesselNotifications);
    }
);

export const getAllActiveAlerts = createSelector(
    state => getAllActiveNotifications(state),

    activeNotifications => activeNotifications.filter(notification => notification.Severity === 'Alert')
);

export const getLatestActiveAlertsByIMO = createByIdSelector(
    state => getAllActiveAlerts(state),
    'IMO',
    true
);

export default getAllActiveNotifications;
