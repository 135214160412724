import { ActionTypes } from './map-options-actions';

const emptyArray = [];

const initialState = {
    areaTypes: emptyArray,
    mapTypes: emptyArray,
    selectedAreas: null
};

export default function mapOptionsReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.MAP_OPTIONS_SET_OPTIONS: {
        return {
            ...state,
            areaTypes: action.areaTypes || emptyArray,
            mapTypes: action.mapTypes || emptyArray
        };
    }
    case ActionTypes.MAP_OPTIONS_SET_SELECTED_AREAS: {
        return {
            ...state,
            selectedAreas: action.selectedAreas
        };
    }
    default:
        return state;
    }
}
