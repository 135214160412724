import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { copyItineraryToClipboard } from 'components/itinerary/helpers';
/* components */
import Itinerary from 'components/itinerary/itinerary';
import ScrollArea from 'components/scroll-area/scroll-area';
/* styles */
import './voyage-info.itinerary.scss';

class VoyageInfoItinerary extends PureComponent {
    copyToClipboard = () => {
        const { voyage, itinerary } = this.props;
        copyItineraryToClipboard({
            vesselName: voyage.VesselName,
            itinerary,
            selectedVoyage: voyage,
            voyagePortInfo: voyage.VoyagePortInfo
        });
    }

    render() {
        return (
            <div className="sten-voyage-info-itinerary">
                <ScrollArea className="sten-content__body">
                    <div className="sten-content__section">
                        <Itinerary itinerary={this.props.itinerary} />
                    </div>
                </ScrollArea>
                <div className="sten-content__footer flex-row">
                    <div className="flex-grow flex">
                        <button
                            className="btn btn--secondary text-uppercase flex-grow"
                            onClick={this.copyToClipboard}
                        >
                            {translate('GLOBAL.COPY_TO_CLIPBOARD')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

VoyageInfoItinerary.propTypes = {
    itinerary: PropTypes.arrayOf(PropTypes.object).isRequired,
    voyage: PropTypes.objectOf(PropTypes.any).isRequired
};

export default VoyageInfoItinerary;
