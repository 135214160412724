import { takeEvery, put, select, all } from 'redux-saga/effects';
/* actions */
import { ActionTypes } from './berth-info-actions';
import { ActionTypes as CommentActionTypes } from 'components/comments/comment-actions';
/* services */
import CommentService from 'services/core-api/comment-service';
import TerminalsService from 'services/core-api/terminals-service';

function* getComments(action, saveState = true) {
    const permissions = yield select(state => state.userReducer.permissions);
    if (!permissions || !permissions.GetAllPortComments) {
        return null;
    }
    const comments = yield CommentService.get('berth', action.berthId);
    if (saveState) {
        yield put({ type: ActionTypes.BERTH_INFO_SET_COMMENTS, comments });
    }
    return comments;
}

function* updateComments(action) {
    const isMounted = yield select(state => state.berthInfoReducer.isMounted);
    if (isMounted && action.resource === 'berth') {
        yield getComments({ berthId: action.resourceId });
    }
}

function* getResources(action) {
    const res = yield all({
        berth: TerminalsService.getBerthById(action.berthId),
        comments: getComments(action, false)
    });
    yield put({ ...res, type: ActionTypes.BERTH_INFO_SET_RESOURCES });
}

export default function* berthInfoSaga() {
    yield takeEvery(ActionTypes.BERTH_INFO_GET_RESOURCES, getResources);
    yield takeEvery(CommentActionTypes.COMMENT_UPDATED, updateComments);
}
