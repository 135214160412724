import CoreApi from './core-api';

class FleetManagementService {
    constructor() {
        this.resourceUrl = 'VesselGroups';
    }

    getPrivate() {
        return CoreApi.get(`${this.resourceUrl}/User/Private`)
            .then(response => response.data, () => null);
    }

    getPublic() {
        return CoreApi.get(`${this.resourceUrl}/User/Public`)
            .then(response => response.data, () => null);
    }

    getById(fleetId) {
        return CoreApi.get(`${this.resourceUrl}/${fleetId}`)
            .then(response => response.data, () => null);
    }

    add(fleet) {
        return CoreApi.post(this.resourceUrl, fleet)
            .then(response => response.data, () => null);
    }

    remove(fleetId) {
        return CoreApi.remove(`${this.resourceUrl}/${fleetId}`)
            .then(() => true, () => false);
    }

    update(fleetId, fleet) {
        return CoreApi.put(`${this.resourceUrl}/${fleetId}`, fleet)
            .then(response => response.data, () => null);
    }

    addToMyFleet(imo) {
        return CoreApi.post(`${this.resourceUrl}/MyFleet/Vessels`, imo)
            .then(response => response, () => null);
    }

    removeFromMyFleet(imo) {
        return CoreApi.remove(`${this.resourceUrl}/MyFleet/Vessels/${imo}`)
            .then(response => response, () => null);
    }
}

export default new FleetManagementService();
