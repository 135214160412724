import { delay, put, all, select, takeEvery } from 'redux-saga/effects';
import ReactDOMServer from 'react-dom/server';
import React from 'react';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import { ActionTypes } from './voyage-info-actions';
import { ActionTypes as DiaryActionTypes } from '../operators-diary-actions';
import { ActionTypes as CommentActionTypes } from 'components/comments/comment-actions';
/* services */
import CommentService from 'services/core-api/comment-service';
import OperatorsDiaryService from 'services/core-api/operators-diary-service';
import PdfGeneratorService from 'services/core-api/pdf-generator-service';
/* exports */
import generateVesselInfoCargoXLSX from 'components/cargo-export/cargo-xlsx';
import CargoPDF from 'components/cargo-export/cargo-pdf';


function* getComments(action, saveState = true) {
    const permissions = yield select(state => state.userReducer.permissions);
    if (!permissions || !permissions.GetVoyageComments) {
        return null;
    }
    const comments = yield CommentService.get('voyage', action.voyageId);
    if (saveState) {
        yield put({ type: ActionTypes.VOYAGE_INFO_SET_COMMENTS, comments });
    }
    return comments;
}

function* updateComments(action) {
    const isMounted = yield select(state => state.voyageInfoReducer.isMounted);
    if (isMounted && action.resource === 'voyage') {
        yield getComments({ voyageId: action.resourceId });
        yield put({ type: DiaryActionTypes.OPERATORS_DIARY_VOYAGE_UPDATED });
    }
}

export function* getResources(action) {
    const res = yield all({
        voyage: OperatorsDiaryService.getVoyageById(action.voyageId),
        itinerary: OperatorsDiaryService.getItineraryByVoyageId(action.voyageId),
        cargo: OperatorsDiaryService.getCargoByVoyageId(action.voyageId),
        comments: getComments(action, false)
    });
    yield put({ type: ActionTypes.VOYAGE_INFO_SET_RESOURCES, ...res });
}

function* generateCargoXLSX() {
    yield put({ type: 'TOGGLE_LOADER', payload: true });
    yield delay(0);
    const voyage = yield select(state => state.voyageInfoReducer.voyage);
    const voyageCargo = yield select(state => state.voyageInfoReducer.cargo);
    yield generateVesselInfoCargoXLSX({
        date: TimeHelper.getFormatted(voyage.EndDate, { utc: true }),
        vesselName: voyage.VesselName,
        wings: voyageCargo.CargoWings,
        coating: voyageCargo.CargoSummary.Coating
    });
    yield put({ type: 'TOGGLE_LOADER', payload: false });
}

function* generateCargoPDF() {
    const voyage = yield select(state => state.voyageInfoReducer.voyage);
    const cargo = yield select(state => state.voyageInfoReducer.cargo);
    const vesselName = voyage.VesselName;

    const htmlString = ReactDOMServer.renderToStaticMarkup(
        <CargoPDF
            cargoSummary={cargo.CargoSummary}
            data={cargo.CargoWings}
            vesselName={vesselName}
            date={TimeHelper.getFormatted(voyage.EndDate, { utc: true })}
        />
    );

    PdfGeneratorService.convert(
        {
            html: htmlString,
            viewport: { width: 1080, height: 1920 },
            pdf: { format: 'A4', landscape: false }
        },
        `Last 5 cargoes per tank - ${vesselName} - ${TimeHelper.getFormatted(voyage.EndDate, { utc: true })}`
    );
}

export default function* voyageInfoSaga() {
    yield takeEvery(ActionTypes.VOYAGE_INFO_GET_RESOURCES, getResources);
    yield takeEvery(ActionTypes.VOYAGE_INFO_CARGO_GENERATE_PDF, generateCargoPDF);
    yield takeEvery(ActionTypes.VOYAGE_INFO_CARGO_GENERATE_XLSX, generateCargoXLSX);
    yield takeEvery(CommentActionTypes.COMMENT_UPDATED, updateComments);
}
