import React from 'react';
import { toast } from 'react-toastify';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { sortByProp } from 'utils/helpers/array-helper';
/* components */
import EditableList from '../editable-list/admin-company-management-editable-list';
/* services */
import FleetService from 'services/core-api/fleet-service';

const t = (key, props, pluralize) => translate(`ADMIN.COMPANY_MANAGEMENT.FLEETS.${key}`, props, pluralize);

const emptyArray = [];

class AdminCompanyManagementFleets extends React.PureComponent {
    state = {
        fleets: emptyArray,
        updatedFleetIndex: null
    };

    componentDidMount() {
        FleetService.get().then(res => this.setState({ fleets: res || emptyArray }));
    }

    handleUpdate = (fleet) => {
        this.setState({ updatedFleetIndex: null });
        const { fleets } = this.state;
        let newFleets;
        if (fleet.Id) {
            FleetService.update(fleet.Id, fleet).then((res) => {
                if (res) {
                    newFleets = fleets.map(fleet => (fleet.Id === res.Id ? res : fleet))
                        .sort(sortByProp('Name'));
                    this.setState({
                        fleets: newFleets,
                        updatedFleetIndex: newFleets.findIndex(fleet => fleet.Id === res.Id)
                    });
                    toast(t('SUCCESSFULLY_UPDATED'), { type: toast.TYPE.SUCCESS });
                }
            });
        } else {
            FleetService.add(fleet).then((res) => {
                if (res) {
                    newFleets = [...fleets, res].sort(sortByProp('Name'));
                    this.setState({
                        fleets: newFleets,
                        updatedFleetIndex: newFleets.findIndex(fleet => fleet.Id === res.Id)
                    });
                    toast(t('SUCCESSFULLY_ADDED'), { type: toast.TYPE.SUCCESS });
                }
            });
        }
    };

    render() {
        return (
            <div className="sten-panel sten-admin-company-management-fleets">
                <div className="sten-panel__header flex">
                    <h4 className="text-uppercase flex-grow">{t('TITLE')}</h4>
                </div>
                <EditableList
                    addBtnLabel={t('ADD')}
                    items={this.state.fleets}
                    onItemUpdate={this.handleUpdate}
                    updatedItemIndex={this.state.updatedFleetIndex}
                />
            </div>
        );
    }
}

export default AdminCompanyManagementFleets;
