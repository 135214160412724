import { translate } from 'utils/i18n/i18n-model';

const tu = (key) => translate(`UNITS.${key}`);

export const cargoProps = [
    { key: 'TotalCargoCarried', decimals: 0, type: 'number', numFmt: '# ### ##0' },
    { key: 'DaysLaden', decimals: 2, type: 'number', numFmt: '# ### ##0.00' },
    { key: 'DistanceLaden', decimals: 0, type: 'number', numFmt: '# ### ##0' },
    { key: 'DistanceLadenPercentage', decimals: 2, unit: tu('PERCENTAGE'), type: 'number', numFmt: '0.00"%"' },
    { key: 'DaysBallast', decimals: 2, type: 'number', numFmt: '# ### ##0.00' },
    { key: 'DistanceBallast', decimals: 0, type: 'number', numFmt: '# ### ##0' },
    { key: 'DistanceBallastPercentage', decimals: 2, unit: tu('PERCENTAGE'), type: 'number', numFmt: '0.00"%"' },
    { key: 'DaysAtSea', decimals: 2, type: 'number', numFmt: '# ### ##0.00' },
    { key: 'DaysInCanal', decimals: 2, type: 'number', numFmt: '# ### ##0.00' },
    { key: 'DaysInCanalPercentage', decimals: 2, unit: tu('PERCENTAGE'), type: 'number', numFmt: '0.00"%"' },
    { key: 'DaysAnchoredDrifting', decimals: 2, type: 'number', numFmt: '# ### ##0.00' },
    { key: 'DaysCargoLoad', decimals: 2, type: 'number', numFmt: '# ### ##0.00' },
    { key: 'DaysCargoDischarge', decimals: 2, type: 'number', numFmt: '# ### ##0.00' },
    { key: 'DaysInPort', decimals: 2, type: 'number', numFmt: '# ### ##0.00' },
    { key: 'DaysInPortPercentage', decimals: 2, unit: tu('PERCENTAGE'), type: 'number', numFmt: '0.00"%"' }
];

const emissionConfig = { decimals: 0, avgDecimals: 4, type: 'number', numFmt: '# ### ##0', avgNumFmt: '0.0000' };

export const emissionProps = [
    { ...emissionConfig, key: 'CO2', label: 'CO2' },
    { ...emissionConfig, key: 'SOx', label: 'SOx' },
    { ...emissionConfig, key: 'NOx', label: 'NOx' },
    { ...emissionConfig, key: 'PM', label: 'PM', decimals: 1, avgDecimals: 6, numFmt: '0.0', avgNumFmt: '0.000000' }
];

export const otherProps = {
    fuel: { decimals: 1, type: 'number', numFmt: '# ### ##0.0' },
    AvgConsumption: { decimals: 1, type: 'number', numFmt: '# ### ##0.0' },
    TotalConsumption: { decimals: 1, type: 'number', numFmt: '# ### ##0.0' },
    FuelPerDistance: { decimals: 4, type: 'number', numFmt: '0.0000' },
    FuelPerCargo: { decimals: 4, type: 'number', numFmt: '0.0000' }
};

export const fuelColWidth = { web: 7, xls: 12, pdf: 5 };

export const colWidths = {
    Value: { web: 20, xls: 36, pdf: 20, modal: 10 },
    TotalCargoCarried: { web: 11, xls: 20, pdf: 7 },
    DaysLaden: { web: 7, xls: 14, pdf: 4 },
    DistanceLaden: { web: 9, xls: 16, pdf: 7 },
    DistanceLadenPercentage: { web: 6, xls: 12, pdf: 4 },
    DaysBallast: { web: 7, xls: 14, pdf: 4 },
    DistanceBallast: { web: 9, xls: 16, pdf: 7 },
    DistanceBallastPercentage: { web: 6, xls: 12, pdf: 4 },
    DaysAtSea: { web: 7, xls: 14, pdf: 4 },
    DaysInCanal: { web: 8, xls: 14, pdf: 4 },
    DaysInCanalPercentage: { web: 6, xls: 12, pdf: 4 },
    DaysAnchoredDrifting: { web: 8, xls: 14, pdf: 7 },
    DaysCargoLoad: { web: 7, xls: 12, pdf: 5 },
    DaysCargoDischarge: { web: 7, xls: 12, pdf: 5 },
    DaysInPort: { web: 7, xls: 12, pdf: 4 },
    DaysInPortPercentage: { web: 6, xls: 12, pdf: 3 },
    AvgConsumptionAtSeaBallast: { web: 8, xls: 13, pdf: 6 },
    TotalConsumptionAtSeaBallast: { web: 7, xls: 13, pdf: 6 },
    AvgConsumptionAtSeaLaden: { web: 8, xls: 13, pdf: 6 },
    TotalConsumptionAtSeaLaden: { web: 7, xls: 13, pdf: 6 },
    AvgConsumptionAtSea: { web: 8, xls: 13, pdf: 6 },
    TotalConsumptionAtSea: { web: 7, xls: 13, pdf: 6 },
    AvgConsumptionInCanal: { web: 8, xls: 13, pdf: 6 },
    TotalConsumptionInCanal: { web: 7, xls: 13, pdf: 6 },
    AvgConsumptionAnchoredDrifting: { web: 8, xls: 13, pdf: 6 },
    TotalConsumptionAnchoredDrifting: { web: 7, xls: 13, pdf: 6 },
    AvgConsumptionCargoDischarge: { web: 8, xls: 13, pdf: 6 },
    TotalConsumptionCargoDischarge: { web: 7, xls: 13, pdf: 6 },
    AvgConsumptionCargoLoad: { web: 8, xls: 13, pdf: 6 },
    TotalConsumptionCargoLoad: { web: 7, xls: 13, pdf: 6 },
    AvgConsumptionInPort: { web: 8, xls: 13, pdf: 6 },
    TotalConsumptionInPort: { web: 7, xls: 13, pdf: 6 },
    CO2: { web: 7, xls: 12, pdf: 5 },
    SOx: { web: 6, xls: 12, pdf: 4 },
    NOx: { web: 6, xls: 12, pdf: 4 },
    PM: { web: 6, xls: 12, pdf: 4 }
};
