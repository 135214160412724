/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
/* types */
import {
    TVmsFootprint,
    TYearlyRatingWithCorrection
} from 'components/footprint-table/footprint-table-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import ReportHelper from 'utils/helpers/report-helper';
import { getObjectProp } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* constants */
import { ballastSummaryRows, ladenSummaryRows, getFootprintRows } from '../voyage-report-constants';
/* components */
import RawReportsPDF from 'components/raw-reports-table/export/raw-reports-table-pdf';
/* styles */
import styles from './voyage-report-pdf-style';

export default class VoyageReportsPDF extends React.PureComponent {
     generateTwoColumnTable = (data, rowsOrdered) => {
         if (!data) return null;
         return (
             <table className="sten-table sten-table--sm">
                 <tbody>
                     <tr className="label-value-table-header-row">
                         {new Array(1 + (data.FuelTypes.length || 1))
                             .fill(null)
                             // eslint-disable-next-line react/no-array-index-key
                             .map((_el, i) => <td key={i} />)}
                     </tr>
                     {rowsOrdered.map((rowConfig, rowIndex) => {
                         const exclusionFields = data.ExlusionFields;
                         let value;
                         if (exclusionFields && exclusionFields[rowConfig.key]) {
                             value = exclusionFields[rowConfig.key].join('; ');
                         } else if (rowConfig.renderValue) {
                             value = rowConfig.renderValue(data);
                         } else {
                             value = ReportHelper.formatValue(data, rowConfig);
                         }
                         let rowClasses;
                         const cells = [(<td key={rowConfig.key}>{rowConfig.label}</td>)];

                         if (rowConfig.type === 'fuelTypes') {
                             rowClasses = 'fuel-row';
                             if (data.FuelTypes.length !== 0) {
                                 data.FuelTypes.forEach(fuelType => {
                                     cells.push(<td key={fuelType.Name}>{fuelType.Name}</td>);
                                 });
                             } else {
                                 // eslint-disable-next-line react/no-array-index-key
                                 cells.push(<td key={rowIndex}>-</td>);
                             }
                         } else if (rowConfig.type === 'fuelTypeQuantities') {
                             if (data.FuelTypes.length !== 0) {
                                 const item = getObjectProp(data, rowConfig.key);
                                 data.FuelTypes.forEach(fuelType => {
                                     cells.push(
                                         <td key={`${rowConfig.key} / ${fuelType.Id}`}>
                                             {item[fuelType.Id] ? item[fuelType.Id].Quantity : '-'}
                                         </td>
                                     );
                                 });
                             } else {
                                 // eslint-disable-next-line react/no-array-index-key
                                 cells.push(<td key={rowIndex}>-</td>);
                             }
                         } else if (rowConfig.type === 'header') {
                             cells[0] = (
                                 <td key={rowConfig.key} colSpan={(data.FuelTypes.length || 1) + 1}>
                                     {rowConfig.label}
                                 </td>
                             );
                         } else {
                             cells.push(
                                 <td key={rowConfig.key} colSpan={data.FuelTypes.length || 1}>
                                     {value}
                                 </td>
                             );
                         }
                         // eslint-disable-next-line react/no-array-index-key
                         return <tr key={rowIndex} className={rowClasses}>{cells}</tr>;
                     })}
                 </tbody>
             </table>
         );
     };

     renderBallastLadenSummary(summaryReport) {
         const ballastTable = this.generateTwoColumnTable(summaryReport.BallastReport, ballastSummaryRows);
         const ladenTable = this.generateTwoColumnTable(summaryReport.LadenReport, ladenSummaryRows);

         return (
             <div className="page">
                 <header className="summary-header">
                     <div
                         className="summary-header-company-logo"
                         style={{ backgroundImage: this.props.companyLogo ? `url(${this.props.companyLogo.src})` : '' }}
                     />
                     <div>
                         <div className="header-title">
                             {t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.HEADER.PERFORMANCE_SUMMARY')}
                         </div>
                         <div className="summary-vessel-name-text">{this.props.vesselName}</div>
                         {this.props.voyageNumber && (
                             <div className="summary-voyage-number-text">
                                 {`${t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.HEADER.VOYAGE_NUMBER')} `}
                                 <span style={{ fontWeight: '700' }}>{this.props.voyageNumber}</span>
                             </div>
                         )}
                         {this.props.range && (
                             <div className="summary-voyage-number-text">
                                 {`${t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.HEADER.DATE_RANGE')} `}
                                 <span style={{ fontWeight: '700' }}>
                                     {`${TimeHelper.getFormatted(this.props.range.rangeStart, { utc: true })} - `
                                         + `${TimeHelper.getFormatted(this.props.range.rangeEnd, { utc: true })}`}
                                 </span>
                             </div>
                         )}
                     </div>
                     <div
                         className="summary-header-orbit-logo"
                         style={{ backgroundImage: `url(${this.props.orbitLogo})` }}
                     />
                 </header>
                 <div className="row">
                     <div className="col-12">
                         <div className="report-title">{t('GLOBAL.BALLAST')}</div>
                         {ballastTable}
                     </div>
                     <div className="col-12">
                         <div className="report-title">{t('GLOBAL.LADEN')}</div>
                         {ladenTable}
                     </div>
                 </div>
                 <p className="summary-disclaimer">{t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.EXPORT_DISCLAIMER')}</p>
             </div>
         );
     }

     renderFootprintTable() {
         const {
             range,
             summaryReport,
             vesselCii,
             voyageVmsFootprint
         } = this.props;
         const footprintRows = getFootprintRows(!!range);
         if (!footprintRows.length) {
             return null;
         }
         const footprintTableData = {
             FuelTypes: summaryReport?.Footprint.FuelTypes,
             voyageFootprint: summaryReport?.Footprint,
             vesselCii,
             voyageVmsFootprint
         };
         const footprintTable = this.generateTwoColumnTable(footprintTableData, footprintRows);
         return (
             <div className="page">
                 <div className="row">
                     <div className="col-12">
                         <div className="report-title">{t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.FOOTPRINT')}</div>
                         {footprintTable}
                     </div>
                 </div>
             </div>
         );
     }

     render() {
         return (
             <html lang="en">
                 <head>
                     <style dangerouslySetInnerHTML={{ __html: styles }} />
                 </head>
                 <body>
                     <React.Fragment>
                         {this.renderBallastLadenSummary(this.props.summaryReport)}
                         {this.renderFootprintTable()}
                         <RawReportsPDF
                             arrivalReports={this.props.arrivalReports}
                             cargoReports={this.props.cargoReports}
                             dailyReports={this.props.dailyReports}
                             departureReports={this.props.departureReports}
                             eventReports={this.props.eventReports}
                             rowConfigs={this.props.rowConfigs}
                             sofReports={this.props.sofReports}
                         />
                     </React.Fragment>
                 </body>
             </html>
         );
     }
}

VoyageReportsPDF.propTypes = {
    arrivalReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    cargoReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    companyLogo: PropTypes.objectOf(PropTypes.any),
    dailyReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    departureReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    eventReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    orbitLogo: PropTypes.string.isRequired,
    range: PropTypes.shape({
        rangeStart: PropTypes.any,
        rangeEnd: PropTypes.any
    }),
    rowConfigs: PropTypes.objectOf(PropTypes.any).isRequired,
    sofReports: PropTypes.arrayOf(PropTypes.any).isRequired,
    summaryReport: PropTypes.objectOf(PropTypes.any).isRequired,
    vesselName: PropTypes.string.isRequired,
    vesselCii: TYearlyRatingWithCorrection,
    voyageVmsFootprint: TVmsFootprint,
    voyageNumber: PropTypes.number
};

VoyageReportsPDF.defaultProps = {
    companyLogo: null,
    range: null,
    vesselCii: null,
    voyageNumber: null,
    voyageVmsFootprint: null
};
