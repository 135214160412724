import React from 'react';
import PropTypes from 'prop-types';
/* styles */
import './info-panel.scss';

class EnergyManagementInfoPanel extends React.PureComponent {
    handleClick = () => this.props.onClick(this.props.typeId);

    render() {
        let className = 'sten-panel sten-panel--btn sten-energy-management-info-panel';
        if (this.props.size) {
            className += ` sten-energy-management-info-panel--${this.props.size}`;
        }

        return (
            <div className={className} onClick={this.handleClick}>
                <div className="sten-panel__header flex">
                    <h2 className="text-uppercase flex-grow">{this.props.title}</h2>
                    <button className="btn-link icon icon-arrow-right" />
                </div>
                <div className="sten-panel__body">
                    <div className="sten-panel__section flex-row">
                        <div className="flex-shrink">
                            <div className="flex-col icon-container">
                                <span className={`icon ${this.props.iconClassName}`} />
                            </div>
                        </div>
                        <div className="flex-grow">
                            <p>{this.props.content}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EnergyManagementInfoPanel.propTypes = {
    content: PropTypes.node,
    iconClassName: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    size: PropTypes.string,
    title: PropTypes.string,
    typeId: PropTypes.number.isRequired
};

EnergyManagementInfoPanel.defaultProps = {
    content: 'Undefined',
    iconClassName: 'icon-exclamation-circle',
    size: '',
    title: 'Undefined'
};

export default EnergyManagementInfoPanel;
