import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* components */
import EmptyContent from 'components/empty-content/empty-content';
import FleetEdit from './fleet-management-fleet-edit';
import FleetView from './fleet-management-fleet-view';
/* services */
import FleetManagementService from 'services/core-api/fleet-management-service';
/* styles */
import './fleet-management-fleet.scss';

export class Fleet extends React.PureComponent {
    state = {
        fleetId: null,
        fleet: null
    };

    static getDerivedStateFromProps(props, state) {
        if (props.fleetId !== state.fleetId) {
            return {
                fleetId: props.fleetId,
                fleet: null
            };
        }
        return null;
    }

    componentMounted = false;

    componentDidMount() {
        this.componentMounted = true;
        this.fetchFleet();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fleetId !== this.props.fleetId
            || (prevProps.resourceUpdated !== this.props.resourceUpdated
                && this.props.resourceUpdated.name === 'fleets')) {
            this.fetchFleet(prevProps);
        }
    }

    componentWillUnmount() {
        this.componentMounted = false;
    }

    fetchFleet = () => {
        if (this.props.fleetId) {
            FleetManagementService.getById(this.props.fleetId).then(fleet => {
                if (this.componentMounted) {
                    if (fleet) {
                        this.setState({ fleet });
                    } else {
                        this.props.router.navigate(appRoutes.Vessel.FleetManagement);
                    }
                }
            });
        }
    }

    deleteFleet = () => {
        if (this.props.fleetId) {
            FleetManagementService.remove(this.props.fleetId).then(res => {
                if (res) {
                    this.props.onChange('delete', this.state.fleet);
                    this.props.router.navigate(appRoutes.Vessel.FleetManagement);
                    toast(t('FLEET_MANAGEMENT.FLEET.FLEET_SUCCESSFULLY_DELETED'), { type: toast.TYPE.SUCCESS });
                }
            });
        }
    }

    handleUpdateResponse = (message, method) => (res) => {
        if (res) {
            if (method === 'update') {
                this.fetchFleet();
            }
            this.props.onChange(method, res);
            this.props.router.navigate(`${appRoutes.Vessel.FleetManagement}/${res.VesselGroupId}`);
            toast(message, { type: toast.TYPE.SUCCESS });
        }
    };

    updateFleet = (fleet) => {
        if (fleet.VesselGroupId) {
            FleetManagementService.update(fleet.VesselGroupId, fleet)
                .then(this.handleUpdateResponse(t('FLEET_MANAGEMENT.FLEET.FLEET_SUCCESSFULLY_UPDATED'), 'update'));
        } else {
            FleetManagementService.add(fleet)
                .then(this.handleUpdateResponse(t('FLEET_MANAGEMENT.FLEET.FLEET_SUCCESSFULLY_ADDED'), 'add'));
        }
    }

    render() {
        if (this.props.fleetId && !this.state.fleet) {
            return <EmptyContent>{t('FLEET_MANAGEMENT.FLEET.LOADING_FLEET')}</EmptyContent>;
        }

        if (this.props.isEditing) {
            return (
                <FleetEdit
                    fleet={this.state.fleet}
                    options={this.props.options}
                    permissions={this.props.permissions}
                    onUpdate={this.updateFleet}
                />
            );
        }
        return (
            <FleetView
                fleet={this.state.fleet}
                onDelete={this.deleteFleet}
                options={this.props.options}
                user={this.props.user}
            />
        );
    }
}

Fleet.propTypes = {
    fleetId: PropTypes.number,
    isEditing: PropTypes.bool,
    limit: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.shape({
        commercialOperators: PropTypes.arrayOf(PropTypes.object),
        companyFleets: PropTypes.arrayOf(PropTypes.object),
        segments: PropTypes.arrayOf(PropTypes.object),
        technicalManagers: PropTypes.arrayOf(PropTypes.object),
        vesselOwners: PropTypes.arrayOf(PropTypes.object),
        mainAttributes: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    resourceUpdated: PropTypes.objectOf(PropTypes.any).isRequired,
    router: TRouter.isRequired,
    user: PropTypes.objectOf(PropTypes.any).isRequired
};

Fleet.defaultProps = {
    fleetId: null,
    isEditing: false,
    limit: 200
};

export default withRouter(Fleet);
