/* constants */
import { chartConfigBaseColors, rem, fsm, getTitle } from './weather-performance-report-chart-constants';

const getSpeedAndHeightConfig = (hideResetButton = true, labels, colors) => {
    const config = {
        chart: {
            backgroundColor: chartConfigBaseColors.colorChartBg,
            plotBackgroundColor: chartConfigBaseColors.colorChartBg,
            spacing: [2 * rem, rem, rem, 2.5 * rem],
            ...(hideResetButton && { resetZoomButton: { theme: { display: 'none' } } })
        },
        xAxis: {
            gridLineColor: chartConfigBaseColors.colorXAxisGridLine,
            gridZIndex: 1,
            tickPixelInterval: 400,
            tickWidth: 0,
            labels: {
                enabled: false
            },
            startOnTick: true
        },
        yAxis: {
            minTickInterval: 2,
            tickAmount: 3,
            labels: {
                reserveSpace: false
            },
            title: {
                ...getTitle(fsm),
                text: labels.title
            }
        },
        legend: {
            itemMarginTop: 10 * fsm
        },
        series: [
            {
                name: labels.reported,
                type: 'area',
                color: colors.reported,
                lineWidth: 2 * fsm,
                data: [],
                zIndex: 2,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            },
            {
                name: labels.external,
                type: 'area',
                color: colors.external,
                lineWidth: 2 * fsm,
                data: [],
                zIndex: 2,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            },
            {
                name: labels.charterparty,
                type: 'area',
                color: colors.charterparty,
                lineWidth: 2 * fsm,
                data: [],
                zIndex: 2,
                fillColor: {
                    linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                    stops: [
                        [0, chartConfigBaseColors.colorSeriesGradient],
                        [1, 'transparent']
                    ]
                }
            }
        ]
    };
    return config;
};

export default getSpeedAndHeightConfig;
