import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* helpers */
import { formatValue, getClassName } from 'utils/helpers/info-helper';
/* styles */
import './weather-performance-report-performance-summary-panel.scss';
/* constants */
import { performanceSummarySections } from './weather-performance-report-performance-summary-panel-constants';

class WeatherPerformanceReportPerformanceSummaryPanel extends React.PureComponent {
    panelValueClass = (data, key) => {
        return getClassName('sten-weather-performance-report-performance-summary__consumption-value',
            null, {
                'sten-weather-performance-report-performance-summary__consumption-value--optimal': data[key],
                'sten-weather-performance-report-performance-summary__consumption-value--non-optimal':
            typeof data[key] === 'boolean' && !data[key],
                'sten-weather-performance-report-performance-summary__consumption-value--no-data': data[key] === null
            });
    }

    render() {
        const { data } = this.props;
        return (
            <div className="sten-panel">
                <div className="sten-panel__header">
                    <h4 className="flex-grow text-uppercase">
                        {/* eslint-disable-next-line max-len */}
                        {t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.PERFORMANCE_SUMMARY_PANEL.TITLE')}
                    </h4>
                </div>
                <div className="flex">
                    {performanceSummarySections.filter(sec => data.hasOwnProperty(sec.key)).map((sec, index, self) => (
                        <div key={sec.key} className="flex-grow">
                            <div
                                className={getClassName('sten-panel__section', {
                                    'sten-panel__separator sten-panel__separator--right': index < self.length - 1
                                })}
                            >
                                <span className="text-secondary">
                                    {sec.label}
                                </span>
                                <h1 className={this.panelValueClass(data, sec.optimizationKey)}>
                                    {formatValue(data, sec)}
                                </h1>
                                {sec.charterpartyLabel && (
                                // eslint-disable-next-line max-len
                                    <div className="sten-weather-performance-report-performance-summary__budget">
                                        <span className="text-secondary">
                                            {sec.charterpartyLabel}
                                        </span>
                                        {` ${formatValue(data, sec, sec.charterpartyKey)}`}
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
                <div className="sten-panel__separator" />
                <div className="sten-panel__section">
                    <label className="label">
                        {/* eslint-disable-next-line max-len */}
                        {t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.PERFORMANCE_SUMMARY_PANEL.COMMENT')}
                    </label>
                    <p>{this.props.comment || ''}</p>
                </div>
            </div>
        );
    }
}

WeatherPerformanceReportPerformanceSummaryPanel.propTypes = {
    data: PropTypes.objectOf(PropTypes.any),
    comment: PropTypes.string
};

WeatherPerformanceReportPerformanceSummaryPanel.defaultProps = {
    data: null,
    comment: ''
};

export default WeatherPerformanceReportPerformanceSummaryPanel;
