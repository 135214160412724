export const getQueryFromSearch = (search) => {
    return search.replace('?', '').split('&').reduce((obj, pair) => {
        const [key, value] = pair.split('=');
        // eslint-disable-next-line no-param-reassign
        obj[key] = value;
        return obj;
    }, {});
};

export const getSearchFromQuery = (query) => {
    const queryEntries = query
        ? Object.entries(query)
        : [];
    return queryEntries.reduce((res, [key, value]) => {
        if (value === undefined || value === null) {
            return res;
        }
        return !res ? `${key}=${value}` : `${res}&${key}=${value}`;
    }, '');
};
