export const ActionTypes = {
    GLOBAL_SEARCH_GET_RESULTS: 'GLOBAL_SEARCH_GET_RESULTS',
    GLOBAL_SEARCH_SET_RESULTS: 'GLOBAL_SEARCH_SET_RESULTS',
    GLOBAL_SEARCH_TOGGLE_FOCUS: 'GLOBAL_SEARCH_TOGGLE_FOCUS',
    GLOBAL_SEARCH_SET_DISABLED: 'GLOBAL_SEARCH_SET_DISABLED'
};

export function getResults(dispatch, searchCriteria) {
    dispatch({
        type: ActionTypes.GLOBAL_SEARCH_GET_RESULTS,
        searchCriteria
    });
}

export function toggleFocus(dispatch, isFocused) {
    dispatch({
        type: ActionTypes.GLOBAL_SEARCH_TOGGLE_FOCUS,
        isFocused
    });
}

export function setDisabled(dispatch, isDisabled) {
    dispatch({
        type: ActionTypes.GLOBAL_SEARCH_SET_DISABLED,
        isDisabled
    });
}
