import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* components */
import ScrollArea from 'components/scroll-area/scroll-area';
import InfoGraphPanel from '../../shared/info-graph-panel/info-graph-panel';
import InfoGraphSection from '../../shared/info-graph-section/info-graph-section';
import EmptyContent from 'components/empty-content/empty-content';
/* actions */
import { getBasicOverview, resetAll } from '../overview-actions';
/* selectors */
import { getTotalData, getSegmentsData } from '../overview-selectors';

class EnergyManagementKPIOverviewBasic extends React.PureComponent {
    componentDidMount() {
        if (this.props.filters.selectedRangeOption) {
            this.props.getBasicOverview(this.props.filters);
        }
    }

    componentDidUpdate(prevProps) {
        if ((this.props.filters !== prevProps.filters) && this.props.filters.selectedRangeOption) {
            this.props.getBasicOverview(this.props.filters);
        }
    }

    componentWillUnmount() {
        this.props.resetAll();
    }

    render() {
        const { totalData } = this.props;
        const { selectedRangeOption, selectedRangeEnd } = this.props.filters;
        if (!this.props.segmentsData.length) {
            return <EmptyContent />;
        }
        return (
            <ScrollArea className="sten-energy-management-kpi-overview-basic sten-content__body">
                <InfoGraphSection
                    ballastConsumption={{
                        total: totalData.data?.BallastKPIRatio,
                        year1: totalData.data?.BallastKPI,
                        year2: totalData.data?.PreviousBallastKPI
                    }}
                    ladenConsumption={{
                        total: totalData.data?.LadenKPIRatio,
                        year1: totalData.data?.LadenKPI,
                        year2: totalData.data?.PreviousLadenKPI
                    }}
                    averageCargo={{
                        total: totalData.data?.WeightedCargoRatio,
                        year1: totalData.data?.WeightedCargoLaden,
                        year2: totalData.data?.PreviousWeightedCargoLaden
                    }}
                    energyEfficiency={{
                        total: totalData.data?.EnergyEfficiencyRatio,
                        year1: totalData.data?.EnergyEfficiency,
                        year2: totalData.data?.PreviousEnergyEfficiency
                    }}
                    aer={{
                        total: totalData.data?.AERRatio,
                        year1: totalData.data?.AER,
                        year2: totalData.data?.PreviousAER
                    }}
                    consumptionChartData={totalData.consumptionChartData}
                    energyEfficiencyChartData={totalData.energyEfficiencyChartData}
                    rangeOption={selectedRangeOption}
                    rangeEndDate={selectedRangeEnd}
                />
                <div className="sten-content__section">
                    <div className="row row--fluid">
                        {this.props.segmentsData.map(segment => (
                            <div className="col-md-12 col-xs-24" key={segment.id}>
                                <InfoGraphPanel
                                    chartData={segment.consumptionChartData}
                                    infoData={segment.infoData}
                                    title={segment.title}
                                    linkTo={`segment/${segment.id}`}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </ScrollArea>
        );
    }
}

EnergyManagementKPIOverviewBasic.propTypes = {
    filters: PropTypes.objectOf(PropTypes.any).isRequired,
    getBasicOverview: PropTypes.func.isRequired,
    resetAll: PropTypes.func.isRequired,
    segmentsData: PropTypes.arrayOf(PropTypes.object).isRequired,
    totalData: PropTypes.objectOf(PropTypes.any).isRequired
};

function mapStateToProps(state) {
    return {
        filters: state.energyManagementKPIReducer.filters,
        segmentsData: getSegmentsData(state),
        totalData: getTotalData(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getBasicOverview: (filters) => getBasicOverview(dispatch, filters),
        resetAll: () => resetAll(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EnergyManagementKPIOverviewBasic);
