import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { formatNumber, getClassName } from 'utils/helpers/info-helper';
/* components */
import TrendIcon from 'components/trend-icon/trend-icon';
/* styles */
import './net-daily.scss';

class VoyagePerformanceNetDaily extends React.PureComponent {
    render() {
        const { netDaily, estimatedNetDaily, estimatedNetDailyThreshold, className } = this.props;
        const netDailyFormatted = typeof netDaily === 'number' ? `$${formatNumber(netDaily, 0)}` : '-';
        const estimatedValueFormatted = typeof estimatedNetDaily === 'number'
            ? `$${formatNumber(estimatedNetDaily, 0)}`
            : '-';

        return (
            <div className={getClassName('sten-voyage-performance-net-daily', className)}>
                <div className="flex flex-center">
                    <h1 className="text-uppercase">
                        <span className="text-secondary">{`${translate('VOYAGE_PERFORMANCE.NET_DAILY.TITLE')}: `}</span>
                        {netDailyFormatted}
                    </h1>
                    {typeof netDaily === 'number' && (
                        <div className="sten-voyage-performance-net-daily__trend">
                            <TrendIcon
                                value={netDaily}
                                breakpoints={[
                                    estimatedNetDaily - estimatedNetDailyThreshold,
                                    estimatedNetDaily + estimatedNetDailyThreshold
                                ]}
                            />
                        </div>
                    )}
                </div>
                <h5>
                    <span className="text-secondary">
                        {`${translate('VOYAGE_PERFORMANCE.NET_DAILY.ESTIMATED')}: `}
                    </span>
                    {estimatedValueFormatted}
                </h5>
            </div>
        );
    }
}

VoyagePerformanceNetDaily.propTypes = {
    className: PropTypes.string,
    estimatedNetDaily: PropTypes.number,
    estimatedNetDailyThreshold: PropTypes.number,
    netDaily: PropTypes.number
};

VoyagePerformanceNetDaily.defaultProps = {
    className: '',
    estimatedNetDaily: null,
    estimatedNetDailyThreshold: 500,
    netDaily: null
};

export default VoyagePerformanceNetDaily;
