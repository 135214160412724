import React from 'react';
import PropTypes from 'prop-types';
import fastDeepEqual from 'fast-deep-equal';
import { connect } from 'react-redux';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* Components */
import RadioButton from 'components/radio-button/radio-button';
import RightSideBar from 'components/right-side-bar/right-side-bar';
import Checkbox from 'components/checkbox/checkbox';
import ScrollArea from 'components/scroll-area/scroll-area';
import Accordion from 'components/accordion/accordion';

const etaColumnId = 4; // this is currently hardcoded until BE implements some flag for this
const internalNotesId = 11; // and this also :)

class PositionListSettings extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            prevGroupings: null,
            selectedColumns: [],
            selectedExportColumns: [],
            selectedExportGrouping: null,
            selectedExportVesselInfoConfig: [],
            selectedGrouping: null,
            selectedVesselInfoConfig: []
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.groupings !== state.prevGroupings) {
            return {
                selectedGrouping: props.selectedGrouping,
                selectedExportGrouping: props.selectedExportGrouping,
                selectedVesselInfoConfig: props.selectedVesselInfoConfig,
                selectedExportVesselInfoConfig: props.selectedExportVesselInfoConfig,
                selectedColumns: props.visibleColumnIds,
                selectedExportColumns: props.visibleExportColumnIds,
                prevGroupings: props.groupings
            };
        }

        return null;
    }

    resetState = (props = this.props) => {
        this.setState({
            selectedGrouping: props.selectedGrouping,
            selectedExportGrouping: props.selectedExportGrouping,
            selectedVesselInfoConfig: props.selectedVesselInfoConfig,
            selectedExportVesselInfoConfig: props.selectedExportVesselInfoConfig,
            selectedColumns: props.visibleColumnIds,
            selectedExportColumns: props.visibleExportColumnIds
        });
    };

    updateSettings = (settingType, settingsData) => {
        if (settingType === 'vessel-info-config') {
            const selectedVesselInfoConfig = this.state.selectedVesselInfoConfig.slice();
            const foundIndex = selectedVesselInfoConfig.indexOf(settingsData);
            if (foundIndex >= 0) {
                selectedVesselInfoConfig.splice(foundIndex, 1);
            } else {
                selectedVesselInfoConfig.push(settingsData);
            }
            this.setState({
                selectedVesselInfoConfig
            });
        }

        if (settingType === 'vessel-info-export-config') {
            const selectedExportVesselInfoConfig = this.state.selectedExportVesselInfoConfig.slice();
            const foundIndex = selectedExportVesselInfoConfig.indexOf(settingsData);
            if (foundIndex >= 0) {
                selectedExportVesselInfoConfig.splice(foundIndex, 1);
            } else {
                selectedExportVesselInfoConfig.push(settingsData);
            }
            this.setState({
                selectedExportVesselInfoConfig
            });
        }

        if (settingType === 'grouping') {
            this.setState({
                selectedGrouping: settingsData
            });
        }
        if (settingType === 'export-grouping') {
            this.setState({
                selectedExportGrouping: settingsData
            });
        }
        if (settingType === 'columns') {
            const selectedColumns = this.updateSelectedColumns(
                this.state.selectedColumns,
                settingsData
            );

            // if ETA is not selected in pos list visible columns, than it cannot be selected in export columns
            const selectedExportColumns = selectedColumns.indexOf(etaColumnId) === -1
                ? this.state.selectedExportColumns.filter(id => id !== etaColumnId)
                : this.state.selectedExportColumns;

            this.setState({ selectedColumns, selectedExportColumns });
        }
        if (settingType === 'export-columns') {
            const selectedExportColumns = this.updateSelectedColumns(
                this.state.selectedExportColumns,
                settingsData
            );

            this.setState({ selectedExportColumns });
        }
    };

    updateSelectedColumns(columns, settingsData) {
        const selectedColumns = columns.slice();
        const selectedColumnIndex = selectedColumns.indexOf(settingsData);

        if (selectedColumns.indexOf(settingsData) < 0) {
            selectedColumns.push(settingsData);
        } else {
            selectedColumns.splice(selectedColumnIndex, 1);
        }

        return selectedColumns;
    }

    closeAndDiscardSettings = () => {
        this.resetState();
        this.props.toggleSettings(false);
    };

    applyUserSettings = () => {
        this.props.updateUserSettings({
            PositionsListFilterSelectedGroupBy: this.state.selectedGrouping,
            PositionsListFilterSelectedExportGroupBy: this.state.selectedExportGrouping,
            PositionsListVisibleColumns: this.state.selectedColumns,
            PositionsListExportColumns: this.state.selectedExportColumns,
            PositionListVesselProperties: this.state.selectedVesselInfoConfig,
            PositionListExportVesselProperties: this.state.selectedExportVesselInfoConfig
        });
        this.props.toggleSettings(false);
    };

    areSettingsChanged = () => {
        let groupingChanged = false;

        if (this.state.selectedGrouping !== this.props.selectedGrouping) {
            groupingChanged = true;
        }

        if (this.state.selectedExportGrouping !== this.props.selectedExportGrouping) {
            groupingChanged = true;
        }

        const columnsChanged = !fastDeepEqual(
            this.state.selectedColumns,
            this.props.visibleColumnIds
        );
        const exportColumnsChanged = !fastDeepEqual(
            this.state.selectedExportColumns,
            this.props.visibleExportColumnIds
        );

        const vesselInfoConfigChanged = !fastDeepEqual(
            this.state.selectedVesselInfoConfig,
            this.props.selectedVesselInfoConfig
        );
        const vesselInfoExportConfigChanged = !fastDeepEqual(
            this.state.selectedExportVesselInfoConfig,
            this.props.selectedExportVesselInfoConfig
        );

        return groupingChanged
            || columnsChanged
            || exportColumnsChanged
            || vesselInfoConfigChanged
            || vesselInfoExportConfigChanged;
    };

    renderColumnsVisibilitySettings = (updateSettingsType) => {
        let columns = null;
        let dataCollection = [];
        switch (updateSettingsType) {
        case 'columns':
            columns = this.state.selectedColumns;
            dataCollection = this.props.tableColumns;
            break;
        default:
            dataCollection = this.props.tableColumns;
            columns = this.state.selectedExportColumns;
        }
        return (
            <div className="sten-content__section">
                {dataCollection && dataCollection.length && dataCollection.map(column => {
                    let isDisabled = false;
                    if (updateSettingsType === 'export-columns') {
                        if ((column.ColumnId === etaColumnId && this.state.selectedColumns.indexOf(etaColumnId) === -1)
                            || column.ColumnId === internalNotesId) {
                            isDisabled = true;
                        }
                    }
                    return !column.alwaysVisible && (
                        <div className="form-row" key={column.ColumnId}>
                            <Checkbox
                                className="sten-checkbox text-uppercase"
                                isDisabled={isDisabled}
                                isChecked={columns.indexOf(column.ColumnId) >= 0}
                                onChange={this.updateSettings.bind(this, updateSettingsType, column.ColumnId)}
                            >
                                {column.ColumnDisplayName || column.DisplayName}
                            </Checkbox>
                        </div>
                    );
                })}
            </div>
        );
    };

    renderVesselInfoConfigSettings = (updateSettingsType) => {
        let configs = null;
        let dataCollection = [];
        switch (updateSettingsType) {
        case 'vessel-info-config':
            configs = this.state.selectedVesselInfoConfig;
            dataCollection = this.props.vesselInfoConfigurableProperties;
            break;
        default:
            configs = this.state.selectedExportVesselInfoConfig;
            dataCollection = this.props.vesselInfoConfigurableProperties;
        }
        return (
            <div className="sten-content__section">
                {dataCollection && dataCollection.length && dataCollection.map(data => (
                    <div className="form-row" key={data.Id}>
                        <Checkbox
                            className="sten-checkbox text-uppercase"
                            isChecked={configs.indexOf(data.Id) >= 0}
                            onChange={this.updateSettings.bind(this, updateSettingsType, data.Id)}
                        >
                            {data.DisplayName}
                        </Checkbox>
                    </div>
                ))}
            </div>
        );
    };

    renderGroupingSection = (selectedGrouping, updateKey, className) => (
        <div className={`sten-content__section${className ? ` ${className}` : ''}`}>
            {this.props.groupings && this.props.groupings.length && this.props.groupings.map(group => (
                <div className="form-row" key={group.FilterId}>
                    <RadioButton
                        className="text-uppercase"
                        onChange={this.updateSettings.bind(this, updateKey, group.FilterId)}
                        isChecked={selectedGrouping === group.FilterId}
                    >
                        {group.FilterDisplayName}
                    </RadioButton>
                </div>
            ))}
        </div>
    );

    renderSettingsContent = isExportColumnVisible => {
        const containerClass = `col-${isExportColumnVisible ? 12 : 24}`;
        return (
            <React.Fragment>
                <Accordion header={(
                    <h4 className="text-uppercase">
                        {translate('POSITION_LIST.SETTINGS.GROUPING')}
                    </h4>
                )}
                >
                    <div className="flex">
                        <div className={containerClass}>
                            <div className="sten-content__section-sub-header col-24">
                                {translate('POSITION_LIST.SETTINGS.INTERNAL')}
                            </div>
                            {this.renderGroupingSection(this.state.selectedGrouping, 'grouping')}
                        </div>
                        {isExportColumnVisible && (
                            <div className={`${containerClass} sten-content__separator sten-content__separator--left`}>
                                <div className="sten-content__section-sub-header col-24">
                                    {translate('POSITION_LIST.SETTINGS.EXPORT')}
                                </div>
                                {this.renderGroupingSection(this.state.selectedExportGrouping, 'export-grouping')}
                            </div>
                        )}
                    </div>
                </Accordion>
                <Accordion header={(
                    <h4 className="text-uppercase">
                        {translate('POSITION_LIST.SETTINGS.COLUMNS')}
                    </h4>
                )}
                >
                    <div className="flex">
                        <div className={containerClass}>
                            <div className="sten-content__section-sub-header col-24">
                                {translate('POSITION_LIST.SETTINGS.INTERNAL')}
                            </div>
                            {this.renderColumnsVisibilitySettings('columns')}
                        </div>
                        {isExportColumnVisible && (
                            <div className={`${containerClass} sten-content__separator sten-content__separator--left`}>
                                <div className="sten-content__section-sub-header col-24">
                                    {translate('POSITION_LIST.SETTINGS.EXPORT')}
                                </div>
                                {this.renderColumnsVisibilitySettings('export-columns')}
                            </div>
                        )}
                    </div>
                </Accordion>
                <Accordion header={(
                    <h4 className="text-uppercase">
                        {translate('POSITION_LIST.SETTINGS.VESSEL_PROPERTIES')}
                    </h4>
                )}
                >
                    <div className="flex">
                        <div className={containerClass}>
                            <div className="sten-content__section-sub-header col-24">
                                {translate('POSITION_LIST.SETTINGS.INTERNAL')}
                            </div>
                            {this.renderVesselInfoConfigSettings('vessel-info-config')}
                        </div>
                        {isExportColumnVisible && (
                            <div className={`${containerClass} sten-content__separator sten-content__separator--left`}>
                                <div className="sten-content__section-sub-header col-24">
                                    {translate('POSITION_LIST.SETTINGS.EXPORT')}
                                </div>
                                {this.renderVesselInfoConfigSettings('vessel-info-export-config')}
                            </div>
                        )}
                    </div>
                </Accordion>
            </React.Fragment>
        );
    };

    render() {
        const isExportColumnVisible = this.props.permissions && this.props.permissions.ComposeAndEditPositionList;
        return (
            <div className="position-list-settings">
                <RightSideBar isCollapsed={!this.props.areSettingsVisible}>
                    <div className="sten-content sten-content--has-footer">
                        <div className="sten-content__header">
                            <h1 className="sten-content__title flex-grow">
                                {translate('POSITION_LIST.SETTINGS.TITLE')}
                            </h1>
                            <div className="flex-shrink">
                                <button className="btn-link icon icon-close" onClick={this.closeAndDiscardSettings} />
                            </div>
                        </div>
                        <ScrollArea className="sten-content__body">
                            {this.renderSettingsContent(isExportColumnVisible)}
                        </ScrollArea>
                        <footer className="sten-content__footer flex-row">
                            <div className="col-12">
                                <button
                                    type="button"
                                    onClick={this.closeAndDiscardSettings}
                                    className="btn btn--secondary col-24"
                                >
                                    {translate('GLOBAL.CANCEL')}
                                </button>
                            </div>
                            <div className="col-12">
                                <button
                                    disabled={!this.areSettingsChanged()}
                                    type="button"
                                    onClick={this.applyUserSettings}
                                    className="btn btn--primary col-24"
                                >
                                    {translate('POSITION_LIST.SETTINGS.APPLY_SETTINGS')}
                                </button>
                            </div>
                        </footer>
                    </div>
                </RightSideBar>
            </div>
        );
    }
}

PositionListSettings.propTypes = {
    areSettingsVisible: PropTypes.bool.isRequired,
    groupings: PropTypes.arrayOf(PropTypes.object).isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedExportGrouping: PropTypes.number.isRequired,
    selectedExportVesselInfoConfig: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedGrouping: PropTypes.number.isRequired,
    selectedVesselInfoConfig: PropTypes.arrayOf(PropTypes.any).isRequired,
    tableColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
    toggleSettings: PropTypes.func.isRequired,
    updateUserSettings: PropTypes.func.isRequired,
    vesselInfoConfigurableProperties: PropTypes.arrayOf(PropTypes.any).isRequired,
    visibleColumnIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    visibleExportColumnIds: PropTypes.arrayOf(PropTypes.number).isRequired
};

function mapStateToProps(state) {
    return {
        permissions: state.userReducer.permissions,
        vesselInfoConfigurableProperties: state.positionListReducer.vesselInfoConfigurableProperties
    };
}
export default connect(mapStateToProps)(PositionListSettings);
