import { createSelector } from 'reselect';
import moment from 'moment';
/* utils */
import { createByIdSelector } from 'utils/helpers/selector-helper';
import { sortByProp } from 'utils/helpers/array-helper';

const isTextContainingString = (text, string) => !string || text.toUpperCase().indexOf(string.toUpperCase()) > -1;
const isDateInRange = (date, range) => (!range.from || date >= range.from) && (!range.to || date <= range.to);
const isItemInList = (item, list, propName) => {
    if (!list || list.length === 0) {
        return true;
    }
    const idIndex = propName ? list.findIndex(listItem => listItem[propName] === item) : list.indexOf(item);
    return idIndex > -1;
};
const areSomeItemsInList = (items, list, propName) => {
    if (!list || list.length === 0) {
        return true;
    }
    let res = false;
    for (let i = 0; i < items.length; i++) {
        if (list.findIndex(listItem => listItem[propName] === items[i][propName]) > -1) {
            res = true;
            break;
        }
    }
    return res;
};

export const sortInspections = (inspections, sortProp, sortOrder) =>
    inspections.sort(sortByProp(sortProp, sortOrder === 'ASC'));

const inspectionLimits = {
    SIRE: -5,
    CDI: -11
};

const shouldInspectionBeVisible = (inspection, selectedOptions) => {
    return isTextContainingString(inspection.Vessel.Title, selectedOptions.vessel)
        && isDateInRange(inspection.Date, selectedOptions.inspectionRange)
        && isItemInList(inspection.Vessel.SegmentId, selectedOptions.segments, 'VesselTypeId')
        && isItemInList(inspection.Company, selectedOptions.inspectionCompanies, 'Name')
        && areSomeItemsInList(inspection.Vessel.FleetCompanies, selectedOptions.companies, 'Id')
        && (!selectedOptions.inspectionType || inspection.Type === selectedOptions.inspectionType.Name)
        && (!selectedOptions.technicalManager
            || (inspection.TechnicalManager.Name === selectedOptions.technicalManager.Name))
        && (!selectedOptions.headOwner || (inspection.Owner.Name === selectedOptions.headOwner.Name));
};

export const getFilteredInspections = createSelector(
    state => state.inspectionsReducer.inspections,
    state => state.inspectionsReducer.sortProp,
    state => state.inspectionsReducer.sortOrder,
    state => state.inspectionsReducer.selectedFilterOptions,

    (inspections, sortProp, sortOrder, selectedOptions) => {
        const filteredInspections = [];
        const inspectionsByVessel = {};
        const inspectionsByTypePerVessel = {};
        const earliestDates = {
            CDI: moment.utc().startOf('d').add(inspectionLimits.CDI, 'M').toISOString(),
            SIRE: moment.utc().startOf('d').add(inspectionLimits.SIRE, 'M').toISOString()
        };
        let currentInspection;
        inspections.forEach((inspection) => {
            if (shouldInspectionBeVisible(inspection, selectedOptions)) {
                currentInspection = inspection;
                if (!inspectionsByTypePerVessel[inspection.Vessel.Imo]) {
                    inspectionsByTypePerVessel[inspection.Vessel.Imo] = { SIRE: false, CDI: false };
                }
                if (!inspectionsByTypePerVessel[inspection.Vessel.Imo][inspection.Type]) {
                    inspectionsByTypePerVessel[inspection.Vessel.Imo][inspection.Type] = true;
                    if (earliestDates[inspection.Type] > inspection.Date) {
                        currentInspection = { ...inspection, IsInAlert: true };
                    }
                }
                if (selectedOptions.viewOption.Id !== 0) {
                    if (!inspectionsByVessel[inspection.Vessel.Imo]) {
                        inspectionsByVessel[inspection.Vessel.Imo] = 0;
                    }
                    if (inspectionsByVessel[inspection.Vessel.Imo] < selectedOptions.viewOption.Id) {
                        filteredInspections.push(currentInspection);
                        inspectionsByVessel[inspection.Vessel.Imo]++;
                    }
                } else {
                    filteredInspections.push(currentInspection);
                }
            }
        });
        if (sortProp !== 'Date' || sortOrder !== 'DESC') {
            return sortInspections(filteredInspections, sortProp, sortOrder);
        }
        return filteredInspections;
    }
);

export const getSelectedInspections = createSelector(
    state => state.inspectionsReducer.inspections,
    state => state.inspectionsReducer.selectedInspections,
    state => state.inspectionsReducer.sortProp,
    state => state.inspectionsReducer.sortOrder,
    (inspections, selectedInspections, sortProp, sortOrder) => {
        const filteredInspections = [];
        const inspectionsByTypePerVessel = {};
        const earliestDates = {
            CDI: moment.utc().add(inspectionLimits.CDI, 'M').toISOString(),
            SIRE: moment.utc().add(inspectionLimits.SIRE, 'M').toISOString()
        };
        let currentInspection;
        inspections.forEach((inspection) => {
            if (selectedInspections[inspection.Id]) {
                currentInspection = inspection;
                if (!inspectionsByTypePerVessel[inspection.Vessel.Imo]) {
                    inspectionsByTypePerVessel[inspection.Vessel.Imo] = { SIRE: false, CDI: false };
                }
                if (!inspectionsByTypePerVessel[inspection.Vessel.Imo][inspection.Type]) {
                    inspectionsByTypePerVessel[inspection.Vessel.Imo][inspection.Type] = true;
                    if (earliestDates[inspection.Type] > inspection.Date) {
                        currentInspection = { ...inspection, IsInAlert: true };
                    }
                }
                filteredInspections.push(currentInspection);
            }
        });
        if (sortProp !== 'Date' || sortOrder !== 'DESC') {
            return sortInspections(filteredInspections, sortProp, sortOrder);
        }
        return filteredInspections;
    }
);

export const calcObservationAverages = createSelector(
    state => state.inspectionsReducer.inspections,
    state => state.inspectionsReducer.selectedInspections,

    (inspections, selectedInspections) => {
        let cdiInspectionsCount = 0;
        let sireInspectionsCount = 0;
        let cdiInspectionsTotalCount = 0;
        let sireInspectionsTotalCount = 0;
        let cdiInspectionsSum = 0;
        let sireInspectionsSum = 0;
        inspections.forEach((inspection) => {
            if (selectedInspections[inspection.Id] === true) {
                if (inspection.Type === 'SIRE') {
                    sireInspectionsTotalCount++;
                    if (inspection.Observation) {
                        sireInspectionsCount++;
                        sireInspectionsSum += inspection.Observation;
                    }
                } else {
                    cdiInspectionsTotalCount++;
                    if (inspection.Observation) {
                        cdiInspectionsCount++;
                        cdiInspectionsSum += inspection.Observation;
                    }
                }
            }
        });

        return {
            cdiInspectionsTotalCount,
            sireInspectionsTotalCount,
            cdiInspectionsAvg: cdiInspectionsSum !== 0 ? cdiInspectionsSum / cdiInspectionsCount : null,
            sireInspectionsAvg: sireInspectionsSum !== 0 ? sireInspectionsSum / sireInspectionsCount : null
        };
    }
);

export const areFiltersChanged = createSelector(
    state => state.inspectionsReducer.selectedFilterOptions,

    (selectedFilterOptions) => {
        return selectedFilterOptions.vessel !== ''
            || selectedFilterOptions.inspectionRange.from !== null
            || selectedFilterOptions.inspectionRange.to !== null
            || selectedFilterOptions.companies.length > 0
            || selectedFilterOptions.segments.length > 0
            || !!selectedFilterOptions.headOwner
            || !!selectedFilterOptions.technicalManager
            || !!selectedFilterOptions.inspectionType
            || selectedFilterOptions.viewOption.Id !== 0;
    }
);

export const areFilteredInspectionsSelected = createSelector(
    getFilteredInspections,
    state => state.inspectionsReducer.selectedInspections,

    (filteredInspections, selectedInspections) => {
        let areAllSelected = true;
        for (let i = 0; i < filteredInspections.length; i++) {
            if (!selectedInspections[filteredInspections[i].Id]) {
                areAllSelected = false;
                break;
            }
        }
        return areAllSelected;
    }
);

export const inspectionActivitiesById = createByIdSelector(
    state => state.inspectionsReducer.inspectionActivities,
    'Id'
);

export const inspectionTypesById = createByIdSelector(
    state => state.inspectionsReducer.inspectionTypes,
    'Id'
);

export const headOwnersById = createByIdSelector(
    state => (
        state.inspectionsFormReducer.editingInspection.Vessel
            ? state.inspectionsFormReducer.headOwners
            : state.inspectionsReducer.headOwners
    ),
    'Id'
);

export const technicalManagersById = createByIdSelector(
    state => (
        state.inspectionsFormReducer.editingInspection.Vessel
            ? state.inspectionsFormReducer.technicalManagers
            : state.inspectionsReducer.technicalManagers
    ),
    'Id'
);

export const inspectionCompanyById = createByIdSelector(
    state => state.inspectionsFormReducer.inspectionCompanies,
    'Id'
);

export const getEditingInspection = createSelector(
    state => state.inspectionsFormReducer.editingInspection,
    inspectionActivitiesById,
    inspectionTypesById,
    headOwnersById,
    technicalManagersById,
    inspectionCompanyById,
    (inspection, ia, it, ho, tm, ic) => {
        return {
            ...inspection,
            InspectionActivity: inspection.InspectionActivityId && ia[inspection.InspectionActivityId]
                ? ia[inspection.InspectionActivityId] : null,
            InspectionType: inspection.InspectionTypeId && it[inspection.InspectionTypeId]
                ? it[inspection.InspectionTypeId] : null,
            HeadOwner: inspection.HeadOwnerId && ho[inspection.HeadOwnerId]
                ? ho[inspection.HeadOwnerId] : null,
            TechnicalManager: inspection.TechnicalManagerId && tm[inspection.TechnicalManagerId]
                ? tm[inspection.TechnicalManagerId] : null,
            InspectionCompany: inspection.InspectionCompanyId && ic[inspection.InspectionCompanyId]
                ? ic[inspection.InspectionCompanyId] : null
        };
    }
);

export const getHeadOwners = createSelector(
    state => state.inspectionsReducer.headOwners,
    state => state.inspectionsFormReducer.headOwners,
    state => state.inspectionsFormReducer.editingInspection,
    (allHeadOwners, vesselHeadOwners, editingInspection) => {
        return editingInspection.Vessel && vesselHeadOwners ? vesselHeadOwners : allHeadOwners;
    }
);

export const getTechnicalManagers = createSelector(
    state => state.inspectionsReducer.technicalManagers,
    state => state.inspectionsFormReducer.technicalManagers,
    state => state.inspectionsFormReducer.editingInspection,
    (allTechManagers, vesselTechManagers, editingInspection) => {
        return editingInspection.Vessel && vesselTechManagers ? vesselTechManagers : allTechManagers;
    }
);
