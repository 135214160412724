import React from 'react';
/* helpers */
import { t } from 'utils/i18n/i18n-model';
/* styles */
import './auth.scss';
/* services */
import AuthService from 'services/auth-service';
import ConfigService from 'services/config-api/config-service';
/* images */
import imgSrc from 'assets/images/orbit_logo.svg';
import imgSrcMcs from 'assets/images/orbit_mcs_logo.svg';

const currentYear = new Date().getFullYear();

export class UnauthorizedAccess extends React.PureComponent {
    loginWithDifferentAccount = () => {
        ConfigService.clearCache();
        AuthService.logout();
    };

    render() {
        const logoSrc = ConfigService.tenantConfig?.logo === 'mcs' ? imgSrcMcs : imgSrc;
        return (
            <div className="sten-auth-page">
                <div className="sten-auth-page__header">
                    <img src={logoSrc} className="sten-auth-page__header-logo" alt="OrbitMI" />
                </div>
                <div className="sten-content sten-auth-page__panel">
                    <div className="sten-content__header">
                        <div className="sten-content__title">{t('AUTH.UNAUTHORIZED_ACCESS.UNAUTHORIZED_ACCESS')}</div>
                    </div>
                    <div className="sten-content__section">
                        {t('AUTH.UNAUTHORIZED_ACCESS.CANT_USE')}
                    </div>
                    <div className="sten-content__separator" />
                    <div className="sten-content__section">
                        <button
                            type="button"
                            onClick={this.loginWithDifferentAccount}
                            className="btn btn--primary btn--lg col-24"
                        >
                            {t('AUTH.UNAUTHORIZED_ACCESS.LOGIN_WITH_DIFFERENT_ACCOUNT')}
                        </button>
                    </div>
                </div>
                <div className="sten-auth-page__footer">{`Copyright ® ${currentYear} Orbit Insights`}</div>
            </div>
        );
    }
}

export default UnauthorizedAccess;
