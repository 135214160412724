import PropTypes from 'prop-types';
/* types */
import { TFuelData, TFuelTypeQuantity } from 'components/fuel-data-table/fuel-data-table-types';

export const TDate = (props, propName, componentName) => {
    const regex = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;
    if (!regex.test(props[propName])) {
        return new Error(`Invalid prop ${propName} passed to ${componentName}. Expected a valid date string.`);
    }
    return null;
};

export const TEnum = PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Name: PropTypes.string.isRequired
});

export const TSpeedInformation = PropTypes.shape({
    VesselCondition: TEnum,
    SpeedType: TEnum,
    Speed: PropTypes.number,
    TotalConsumption: PropTypes.number,
    NormalRpm: PropTypes.number
});

export const TSpeedDetail = PropTypes.shape({
    VesselCondition: TEnum,
    MinimumSpeed: PropTypes.number,
    RpmAtMinimumSpeed: PropTypes.number
});

export const TPort = PropTypes.shape({
    Id: PropTypes.number,
    Name: PropTypes.string.isRequired
});

export const TQuestionnaire = PropTypes.shape({
    BunkerData: PropTypes.shape({
        rows: PropTypes.arrayOf(TFuelData),
        fuelTypes: PropTypes.arrayOf(TFuelTypeQuantity)
    }).isRequired,
    CallSign: PropTypes.string.isRequired,
    CleaningEquipmentOnBoard: PropTypes.string,
    Id: PropTypes.number.isRequired,
    Imo: PropTypes.number.isRequired,
    LastPropellerPolish: TDate,
    LastPropellerPolishOffset: PropTypes.string,
    LastUwCleaning: TDate,
    LastUwCleaningOffset: PropTypes.string,
    LastUwInspection: TDate,
    LastUwInspectionOffset: PropTypes.string,
    OelPower: PropTypes.number,
    OelRpm: PropTypes.number,
    OelTorque: PropTypes.number,
    PitchOfPropeller: PropTypes.number,
    PortOfLastPropellerPolish: TPort,
    PortOfLastUwCleaning: TPort,
    PortOfLastUwInspection: TPort,
    PortOfProlongedPortStay: TPort,
    ProlongedPortStays: PropTypes.bool.isRequired,
    PropellerAverageSlip: PropTypes.number,
    PropellerDiameter: PropTypes.number,
    PropellerEnergySavingDevices: PropTypes.string,
    PropellerExpandedArea: PropTypes.number,
    PropellerNumberOfBlades: PropTypes.number,
    Remarks: PropTypes.string,
    ReportDate: TDate.isRequired,
    ReportDateOffset: PropTypes.string.isRequired,
    RpmMax: PropTypes.number,
    RpmMin: PropTypes.number,
    SpeedAndConsumptionDetails: PropTypes.string,
    SpeedDetails: PropTypes.arrayOf(TSpeedDetail),
    SpeedInformations: PropTypes.arrayOf(TSpeedInformation),
    VesselName: PropTypes.string.isRequired
});
