import { ActionTypes } from './overview-actions';

const initialState = {
    overviewBasicData: null,
    overviewAdvancedData: null,
    sortProp: 'Vessel.Title',
    sortOrder: 'ASC'
};

export default function energyManagementKPIOverviewReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.ENERGY_MANAGEMENT_KPI_OVERVIEW_SET_BASIC_OVERVIEW: {
        return {
            ...state,
            overviewBasicData: action.overviewBasicData
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_KPI_OVERVIEW_SET_ADVANCED_OVERVIEW: {
        return {
            ...state,
            overviewAdvancedData: action.overviewAdvancedData
        };
    }
    case ActionTypes.ENERGY_MANAGEMENT_KPI_OVERVIEW_RESET_ALL: {
        return initialState;
    }
    default:
        return state;
    }
}
