export const ActionTypes = {
    ENERGY_MANAGEMENT_KPI_TOGGLE_SIDEBAR: 'ENERGY_MANAGEMENT_KPI_TOGGLE_SIDEBAR',
    ENERGY_MANAGEMENT_KPI_RESET_ALL: 'ENERGY_MANAGEMENT_KPI_RESET_ALL',
    ENERGY_MANAGEMENT_KPI_GET_INITIAL_DATA: 'ENERGY_MANAGEMENT_KPI_GET_INITIAL_DATA',
    ENERGY_MANAGEMENT_KPI_SET_SEGMENTS: 'ENERGY_MANAGEMENT_KPI_SET_SEGMENTS',
    ENERGY_MANAGEMENT_KPI_SET_VESSELS: 'ENERGY_MANAGEMENT_KPI_SET_VESSELS',
    ENERGY_MANAGEMENT_KPI_SET_RANGE_OPTIONS: 'ENERGY_MANAGEMENT_KPI_SET_RANGE_OPTIONS',
    ENERGY_MANAGEMENT_KPI_UPDATE_FILTERS: 'ENERGY_MANAGEMENT_KPI_UPDATE_FILTERS'
};

export function getInitialData(dispatch) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_KPI_GET_INITIAL_DATA
    });
}

export function updateFilters(dispatch, filters) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_KPI_UPDATE_FILTERS,
        filters
    });
}

export function toggleSidebar(dispatch, flag) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_KPI_TOGGLE_SIDEBAR,
        flag
    });
}

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.ENERGY_MANAGEMENT_KPI_RESET_ALL
    });
}
