import React from 'react';
import PropTypes from 'prop-types';
import ScrollArea from 'components/scroll-area/scroll-area';
import { connect } from 'react-redux';
import moment from 'moment';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import EqualizeTables from 'components/equalize-tables/equalize-tables';
import PositionListComposeTableRow from './position-list-compose-table-row/position-list-compose-table-row';
import TableHeadCell from 'components/table-head-cell/table-head-cell';
import Modal from 'components/modal/modal';
/* selectors */
import { getVesselInfoConfigMap } from '../../position-list-selectors';
/* actions */
import { updateVesselColumn, getUpdatedVessels } from '../position-list-compose-actions';
/* styles */
import './position-list-compose-table.scss';


class PositionListComposeTable extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            activeForm: {
                imo: null,
                name: ''
            },
            columnId: null,
            isModalOpen: false
        };
    }

    componentDidMount() {
        this.props.getUpdatedFields();
    }

    onDateSelect = value => {
        const vessel = this.props.vessels.find(el => el.IMO === this.state.activeForm.imo);
        this.props.updateColumnField(vessel.IMO, this.state.columnId, value);
        this.setActiveForm(null, null);
    };

    setActiveForm = (imo, name, columnId) => {
        this.setState({
            activeForm: { imo, name }
        });
        if (name === 'EtaDate' || name === 'LastSireDate' || name === 'OpenDate' || name === 'LoadReadyDate') {
            this.setState({
                columnId,
                isModalOpen: true
            });
        } else {
            this.setState({
                isModalOpen: false
            });
        }
    };

    getSectionsByGroups() {
        const sections = [];
        if (this.props.groupedVessels) {
            Object.keys(this.props.groupedVessels).forEach((key) => {
                const group = this.props.groupedVessels[key];
                sections.push(
                    <div className="sten-position-list-table__group" key={key}>
                        <div className="sten-content__section-header">
                            <h4 key="title">{key}</h4>
                        </div>
                        <table className="sten-table sten-table--no-border-top">
                            <tbody>
                                {group.map(vessel => (
                                    <PositionListComposeTableRow
                                        onRowReset={this.resetRow}
                                        isEditable
                                        key={vessel.IMO}
                                        vessel={vessel}
                                        selectedGrouping={this.props.selectedGrouping}
                                        setActiveForm={this.setActiveForm}
                                        activeForm={this.state.activeForm}
                                        tableColumns={this.props.visibleColumns}
                                        fixtureStatuses={this.props.fixtureStatuses}
                                        updateColumnField={this.props.updateColumnField}
                                        selectedExportVesselInfoConfig={this.props.selectedExportVesselInfoConfig}
                                        vesselInfoConfigurableProperties={this.props.vesselInfoConfigurableProperties}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </div>
                );
            });
        }
        if (!sections.length) {
            return (
                <div className="sten-position-list-table__empty">
                    {translate('POSITION_LIST.EMPTY_TABLE_MESSAGE')}
                </div>
            );
        }
        return sections;
    }

    resetRow = imo => {
        this.props.onRowReset(imo);
    };

    render() {
        const tableWrapperWidth = this.props.visibleColumns.reduce((total, col) => total + col.width, 0);
        const datePickerFormNames = ['OpenDate', 'EtaDate', 'LastSireDate', 'LoadReadyDate'];
        let timestamp;
        if (datePickerFormNames.indexOf(this.state.activeForm.name) > -1 && this.state.activeForm.imo) {
            const vessel = this.props.vessels.find(el => el.IMO === this.state.activeForm.imo);
            timestamp = vessel[this.state.activeForm.name]
                ? moment(vessel[this.state.activeForm.name]).valueOf()
                : null;
        }
        return (
            <div className="sten-position-list-compose-table">
                <Modal.DatetimePicker
                    hideTimePicker
                    isOpen={this.state.isModalOpen}
                    onCancel={this.setActiveForm.bind(this, null, '')}
                    onSelect={this.onDateSelect}
                    timestamp={timestamp}
                    utc
                />
                <EqualizeTables>
                    <ScrollArea
                        horizontal
                        vertical={false}
                        contentStyle={{ minWidth: `${tableWrapperWidth}rem` }}
                        contentClassName="sten-position-list-table__scroll-content"
                    >
                        <table className="sten-position-list-table__head sten-table">
                            <thead>
                                <tr>
                                    {this.props.visibleColumns.map((cell) => (
                                        <TableHeadCell
                                            style={{ width: `${cell.width}rem` }}
                                            sortOrder={this.props.sortProp === cell.sortProp
                                                ? this.props.sortOrder
                                                : ''}
                                            key={cell.ColumnId}
                                        >
                                            {cell.renderLabel || cell.ColumnDisplayName}
                                        </TableHeadCell>
                                    ))}
                                </tr>
                            </thead>
                        </table>
                        <ScrollArea className="sten-position-list-table__body">
                            {this.getSectionsByGroups()}
                        </ScrollArea>
                    </ScrollArea>
                </EqualizeTables>
            </div>
        );
    }
}

PositionListComposeTable.propTypes = {
    fixtureStatuses: PropTypes.arrayOf(PropTypes.object).isRequired,
    getUpdatedFields: PropTypes.func.isRequired,
    groupedVessels: PropTypes.objectOf(PropTypes.any).isRequired,
    onRowReset: PropTypes.func.isRequired,
    selectedExportVesselInfoConfig: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedGrouping: PropTypes.objectOf(PropTypes.any).isRequired,
    sortOrder: PropTypes.string.isRequired,
    sortProp: PropTypes.string.isRequired,
    updateColumnField: PropTypes.func.isRequired,
    vesselInfoConfigurableProperties: PropTypes.objectOf(PropTypes.any).isRequired,
    vessels: PropTypes.arrayOf(PropTypes.object).isRequired,
    visibleColumns: PropTypes.arrayOf(PropTypes.object).isRequired
};

function mapStateToProps(state) {
    return {
        fixtureStatuses: state.positionListReducer.fixtureStatuses,
        sortOrder: state.positionListTableReducer.sortOrder,
        sortProp: state.positionListTableReducer.sortProp,
        vesselInfoConfigurableProperties: getVesselInfoConfigMap(state),
        selectedExportVesselInfoConfig: state.userReducer.settings
            && state.userReducer.settings.PositionListExportVesselProperties
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateColumnField: (imo, columnId, value) => updateVesselColumn(dispatch, imo, columnId, value),
        getUpdatedFields: selectedVessels => getUpdatedVessels(dispatch, selectedVessels)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PositionListComposeTable);
