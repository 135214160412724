import { takeEvery, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
/* actions */
import { ActionTypes } from './assign-to-fleet-modal-actions';
/* Services */
import VesselService from 'services/core-api/vessel-service';
/* utils */
import { translate } from 'utils/i18n/i18n-model';

function* getRelationships(action) {
    const relationships = yield VesselService.getRelationships(action.imo);
    yield put({ type: ActionTypes.ASSIGN_TO_FLEET_MODAL_SET_RELATIONSHIPS, relationships, imo: action.imo });
}

function* saveRelationships(action) {
    const success = yield VesselService.setRelationships(action.imo, action.relationships);
    if (success) {
        toast(translate('ASSIGN_TO_FLEET_MODAL.SAVE_SUCCESSFUL'), { type: toast.TYPE.SUCCESS });
        yield put({ type: ActionTypes.ASSIGN_TO_FLEET_MODAL_RELATIONSHIPS_SAVED });
    }
}

function* getOptions() {
    const [fleets, responsibleOffices, commercialContracts, statuses] = yield all([
        VesselService.getCompanies(),
        VesselService.getResponsibleOffices(),
        VesselService.getCommercialContracts(),
        VesselService.getStatuses()
    ]);
    yield put({
        type: ActionTypes.ASSIGN_TO_FLEET_MODAL_SET_OPTIONS,
        fleets,
        responsibleOffices,
        commercialContracts,
        statuses
    });
}

export default function* assignToFleetModalSaga() {
    yield takeEvery(ActionTypes.ASSIGN_TO_FLEET_MODAL_GET_OPTIONS, getOptions);
    yield takeEvery(ActionTypes.ASSIGN_TO_FLEET_MODAL_GET_RELATIONSHIPS, getRelationships);
    yield takeEvery(ActionTypes.ASSIGN_TO_FLEET_MODAL_SAVE_RELATIONSHIPS, saveRelationships);
}
