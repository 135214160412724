import React from 'react';
import PropTypes from 'prop-types';
/* components */
import InputField from './input-field';
/* types */
import { DateTimeShape, TimeConfigFieldShape } from '../types';
/* styles */
import './input.scss';

export default class HtecDPInput extends React.Component {
    handleClick = (e) => {
        if (!this.props.isDisabled) {
            if (this.props.onClick) {
                this.props.onClick(e);
            }
            if (!document.activeElement || !this.inputRef.contains(document.activeElement)) {
                this.inputFieldRef.dateInputRef.focus();
            }
        }
    };

    handleIconClick = (e) => {
        if (!this.props.isDisabled && this.props.onIconClick) {
            this.props.onIconClick(e);
        }
    };

    handleClearClick = (e) => {
        this.inputFieldRef.clearAll();
        this.props.onDateChange(null);
        e.stopPropagation();
    };

    saveRef = (c) => {
        this.inputRef = c;
        if (this.props.saveRef) {
            this.props.saveRef(c);
        }
    };

    saveInputFieldRef = (c) => {
        this.inputFieldRef = c;
    };

    render() {
        const props = this.props;

        let className = 'htec-dp-input';
        if (props.className) {
            className += ` ${props.className}`;
        }
        if (props.isActive) {
            className += ' htec-dp-input--active';
        }
        if (props.isDisabled) {
            className += ' htec-dp-input--disabled';
        }
        if (props.isInvalid) {
            className += ' htec-dp-input--invalid';
        }

        return (
            <div
                ref={this.saveRef}
                className={className}
                onClick={this.handleClick}
            >
                <InputField
                    ref={this.saveInputFieldRef}
                    isDisabled={this.props.isDisabled}
                    date={props.date}
                    dateFormat={props.dateFormat}
                    onDateChange={props.onDateChange}
                    timeConfig={props.timeConfig}
                    withTime={props.withTime}
                />
                {props.isClearable && props.date && !props.isDisabled && (
                    <div className="htec-dp-input__icon" onClick={this.handleClearClick}>{props.clearIcon}</div>
                )}
                <div className="htec-dp-input__icon" onClick={this.handleIconClick}>{props.icon}</div>
            </div>
        );
    }
}

HtecDPInput.propTypes = {
    className: PropTypes.string,
    clearIcon: PropTypes.node,
    date: PropTypes.shape(DateTimeShape),
    dateFormat: PropTypes.string,
    icon: PropTypes.node,
    isActive: PropTypes.bool,
    isClearable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isInvalid: PropTypes.bool,
    onClick: PropTypes.func,
    onDateChange: PropTypes.func.isRequired,
    onIconClick: PropTypes.func,
    placeholder: PropTypes.string,
    saveRef: PropTypes.func,
    timeConfig: PropTypes.shape({
        hour: PropTypes.shape(TimeConfigFieldShape),
        minute: PropTypes.shape(TimeConfigFieldShape),
        second: PropTypes.shape(TimeConfigFieldShape)
    }),
    withTime: PropTypes.bool
};

HtecDPInput.defaultProps = {
    className: '',
    clearIcon: <span>&times;</span>,
    date: null,
    dateFormat: 'DD/MM/YYYY',
    icon: null,
    isActive: false,
    isClearable: true,
    isDisabled: false,
    isInvalid: false,
    onClick: undefined,
    onIconClick: undefined,
    placeholder: '',
    saveRef: undefined,
    timeConfig: null,
    withTime: false
};
