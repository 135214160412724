import PropTypes from 'prop-types';

const Location = PropTypes.shape({
    Latitude: PropTypes.number,
    Longitude: PropTypes.number
});

const Addresses = PropTypes.shape({
    AddressLine: PropTypes.arrayOf(PropTypes.string),
    Id: PropTypes.number,
    ImportanceLevel: PropTypes.number,
    Latitude: PropTypes.number,
    Longitude: PropTypes.number
});

const Office = PropTypes.shape({
    Address: Addresses,
    City: PropTypes.string,
    ContactInformations: PropTypes.arrayOf(PropTypes.any),
    OfficeId: PropTypes.number,
    OfficeName: PropTypes.string
});

const Company = PropTypes.shape({
    Alias: PropTypes.string,
    Id: PropTypes.number,
    Offices: PropTypes.arrayOf(Office)
});

const City = PropTypes.shape({
    CityId: PropTypes.number,
    CityName: PropTypes.string,
    Companies: PropTypes.arrayOf(Company),
    Location
});

const Cities = PropTypes.shape({
    CityId: PropTypes.number,
    Latitude: PropTypes.number,
    Longitude: PropTypes.number,
    MaxImportanceLevel: PropTypes.number,
    MinImportanceLevel: PropTypes.number,
    Name: PropTypes.string
});

const Ports = PropTypes.shape({
    Id: PropTypes.number,
    ImportanceLevel: PropTypes.number,
    Location: PropTypes.string,
    Name: PropTypes.string,
    PortCode: PropTypes.string,
    TimeZoneId: PropTypes.string,
    Type: PropTypes.number
});

const Tanker = PropTypes.shape({
    Beam: PropTypes.number,
    BriefPresentation: PropTypes.shape({
        Description: PropTypes.string,
        FunFacts: PropTypes.string,
        History: PropTypes.string
    }),
    CargoCapacity: PropTypes.number,
    CargoType: PropTypes.string,
    CompanyId: PropTypes.number,
    CurrentAis: PropTypes.shape({
        AisId: PropTypes.number,
        Cog: PropTypes.number,
        Destination: PropTypes.string,
        Draught: PropTypes.number,
        ETA: PropTypes.string,
        Heading: PropTypes.number,
        Imo: PropTypes.number,
        IsLaden: PropTypes.bool,
        Latitude: PropTypes.number,
        Longitude: PropTypes.number,
        NavStatus: PropTypes.string,
        NavStatusCode: PropTypes.number,
        Rot: PropTypes.number,
        Time: PropTypes.string
    }),
    Delivered: PropTypes.string,
    DesignSpeed: PropTypes.number,
    Dimensions: PropTypes.arrayOf(PropTypes.shape(
        {
            DimensionId: PropTypes.number,
            Loadlines: PropTypes.arrayOf(PropTypes.shape({
                LoadlineId: PropTypes.number,
                LoadlineType: PropTypes.string,
                Measure: PropTypes.string,
                Value: PropTypes.number
            })),
            Type: PropTypes.string
        }
    )),
    IMO: PropTypes.number,
    Loa: PropTypes.number,
    Pictures: PropTypes.arrayOf(PropTypes.any),
    SpeedOverGround: PropTypes.number,
    Title: PropTypes.string,
    TotalCubicCapacity: PropTypes.number,
    Trading: PropTypes.string,
    VesselTypeId: PropTypes.number,
    VesselTypeName: PropTypes.string
});

const Vessel = PropTypes.shape({
    CurrentAis: PropTypes.shape({
        Heading: PropTypes.number,
        Latitude: PropTypes.number,
        Longitude: PropTypes.number
    }),
    IMO: PropTypes.number,
    Title: PropTypes.string
});

const Tankers = Vessel;

const Ferries = Vessel;

const Drills = Vessel;

const Ferry = PropTypes.shape({
    BedCapacity: PropTypes.number,
    Breadth: PropTypes.number,
    BriefPresentations: PropTypes.shape({
        Description: PropTypes.string,
        FunFacts: PropTypes.string,
        History: PropTypes.string
    }),
    CarCapacity: PropTypes.number,
    CompanyName: PropTypes.string,
    DesignDrafts: PropTypes.arrayOf(PropTypes.object),
    DesignSpeed: PropTypes.number,
    IMO: PropTypes.number,
    LaneMeterCapacity: PropTypes.number,
    Length: PropTypes.number,
    PassengerCapacity: PropTypes.number,
    ServicesOnboard: PropTypes.arrayOf(PropTypes.any),
    ShipName: PropTypes.string,
    ShipType: PropTypes.string,
    YearBuilt: PropTypes.number
});

const FerryRoutePort = PropTypes.shape({
    Id: PropTypes.number,
    ImportanceLevel: PropTypes.number,
    Location: PropTypes.string,
    Name: PropTypes.string,
    PortCode: PropTypes.string,
    TimeZoneId: PropTypes.string,
    Type: PropTypes.number
});

const FerryRoutes = PropTypes.shape({
    EndPort: FerryRoutePort,
    Id: PropTypes.number,
    RouteName: PropTypes.string,
    RoutePoints: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    StartPort: FerryRoutePort
});

export default {
    Addresses,
    Company,
    City,
    Cities,
    Drills,
    Ferries,
    Ferry,
    FerryRoutes,
    Office,
    Ports,
    Tankers,
    Tanker
};
