import { takeEvery, put, all } from 'redux-saga/effects';
import { ActionTypes } from './contact-list-filters-actions';
/* Services */
import ContactListService from 'services/core-api/contact-list-service';

export function* getOptions() {
    const { companies, departments } = yield all({
        companies: ContactListService.getCompanies(),
        departments: ContactListService.getDepartments()
    });
    yield put({
        type: ActionTypes.CONTACT_LIST_FILTERS_SET_OPTIONS,
        companies,
        departments
    });
}

export default function* contactListFiltersSaga() {
    yield takeEvery(ActionTypes.CONTACT_LIST_FILTERS_GET_OPTIONS, getOptions);
}
