import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router';
import { ToastContainer } from 'react-toastify';
import Keyboard, { KeyboardButton, LatinLayout } from 'react-screen-keyboard';
/* helpers */
import { closestParentByTagName } from 'utils/helpers/html-helper';
import memoize from 'memoize-one';
/* components */
import Loader from '../loader/loader';
import PureWrapper from 'components/scroll-area/pure-wrapper';
/* services */
import AuthService from 'services/auth-service';
import ConfigService from 'services/config-api/config-service';
/* styles */
import './wrapper.scss';

const CloseButton = () => <span className="toastify__close icon icon-close" />;

export const WrapperContext = React.createContext();

export class Wrapper extends React.PureComponent {
    state = {
        activeInput: null,
        initialized: false
    };

    componentDidMount() {
        if (process.env.REACT_APP_AUTHORITY_URL) {
            AuthService.init(process.env.REACT_APP_AUTHORITY_URL);
            this.setState({ initialized: true });
        } else {
            ConfigService.getAuthorityConfig().then(res => {
                if (res) {
                    AuthService.init(res.authority);
                    this.setState({ initialized: true });
                }
            });
        }
    }

    setActiveInput = (input) => {
        this.setState({ activeInput: input });
    }

    closeButton = (<CloseButton />);

    onEnterClick = () => {
        const inputNode = this.state.activeInput;
        const { value, selectionStart, selectionEnd } = inputNode;

        inputNode.value = `${value.substring(0, selectionStart)}\n${value.substring(selectionEnd)}`;
        setTimeout(() => {
            inputNode.focus();
            inputNode.setSelectionRange(selectionStart + 2, selectionStart + 2);
        }, 0);
        inputNode.dispatchEvent(new Event('input', { bubbles: true }));
    };

    onSubmitClick = (parentForm) => () => {
        const buttons = parentForm.getElementsByTagName('button');
        for (let i = 0; i < buttons.length; i++) {
            if (buttons[i].type === 'submit') {
                buttons[i].click();
                break;
            }
        }
        this.setActiveInput(null);
    };

    renderKeyboard = memoize((activeInput) => {
        const parentForm = activeInput ? closestParentByTagName(activeInput, 'form') : null;
        const rightButtons = [];
        if (activeInput && activeInput.type === 'textarea') {
            rightButtons.push(
                <KeyboardButton
                    key="enter"
                    onClick={this.onEnterClick}
                    value="Enter"
                    classes="keyboard-enter-button"
                />
            );
        }
        if (parentForm) {
            const submit = this.onSubmitClick(parentForm);
            rightButtons.push(
                <KeyboardButton
                    key="submit"
                    onClick={submit}
                    value="Submit"
                    classes="keyboard-submit-button"
                />
            );
        }
        return (
            <Keyboard
                key="Keyboard"
                inputNode={activeInput}
                layouts={[LatinLayout]}
                rightButtons={rightButtons}
            />
        );
    });

    renderToastContainer = memoize(() => (
        <PureWrapper>
            <ToastContainer
                key="ToastContainer"
                className="toastify-container"
                hideProgressBar
                closeButton={this.closeButton}
                autoClose={3000}
            />
        </PureWrapper>
    ));

    handleKeyboardWrapperClick = () => {
        if (this.state.activeInput) {
            this.state.activeInput.focus();
        }
    };

    render() {
        let className = 'sten-wrapper';
        if (this.state.activeInput) {
            const rect = this.state.activeInput.getBoundingClientRect();
            if (rect.top > window.innerHeight / 2) {
                className += ' sten-wrapper--keyboard-on-top';
            }
        }
        return (
            <WrapperContext.Provider value={{ ...this.state, setActiveInput: this.setActiveInput }}>
                <div className={className} key="sten-wrapper">
                    {this.renderToastContainer()}
                    {this.state.activeInput && (
                        <div className="sten-wrapper__keyboard" onClick={this.handleKeyboardWrapperClick}>
                            {this.renderKeyboard(this.state.activeInput)}
                        </div>
                    )}
                    <Loader key="Loader" />
                    {this.state.initialized && <Outlet />}
                </div>
            </WrapperContext.Provider>
        );
    }
}

Wrapper.propTypes = {
    children: PropTypes.node
};

Wrapper.defaultProps = {
    children: null
};


export default Wrapper;
