import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { getClassName } from 'utils/helpers/info-helper';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import FileUploadItem from 'components/file-upload/file-upload-item';
/* styles */
import './file-upload.scss';

class FileUpload extends React.PureComponent {
    handleError = rejectedFile => {
        if (rejectedFile.size > this.props.maxSize) {
            toast(translate('FILE_UPLOAD.FILE_TOO_LARGE', {
                fileName: rejectedFile.name,
                maxSize: Math.round(this.props.maxSize / 1000000)
            }), { type: toast.TYPE.ERROR });
        } else if (rejectedFile.size < this.props.minSize) {
            toast(translate('FILE_UPLOAD.FILE_TOO_SMALL', {
                fileName: rejectedFile.name,
                minSize: Math.round(this.props.minSize / 1000000)
            }), { type: toast.TYPE.ERROR });
        } else if (this.props.allowedFileTypes.find(extension => extension !== rejectedFile.type)) {
            toast(translate('FILE_UPLOAD.ERROR_UPLOADING_FILE_EXTENSION',
                { extension: rejectedFile.type }), { type: toast.TYPE.ERROR });
        } else {
            toast(translate('FILE_UPLOAD.ERROR_UPLOADING_FILE'), { type: toast.TYPE.ERROR });
        }
    };

    handleDrop = (acceptedFiles, rejectedFiles, event) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const files = this.props.files.slice();
            acceptedFiles.forEach(accFile => {
                if (!files.find(file => file.name === accFile.name && file.type === accFile.type)) {
                    files.push(accFile);
                } else {
                    toast(translate('FILE_UPLOAD.FILE_ALREADY_EXIST_MESSAGE', { fileName: accFile.name }), {
                        type: toast.TYPE.ERROR
                    });
                }
            });
            this.props.onChange(files, event);
        }
        if (rejectedFiles && rejectedFiles.length > 0) {
            rejectedFiles.forEach((rejectedFile) => {
                this.handleError(rejectedFile);
            });
            if (this.props.onRejectCb) {
                this.props.onRejectCb(rejectedFiles, event);
            }
        }
    };

    handleDelete = (event) => {
        this.props.onChange([], event);
    };

    handleDeleteAreaPropagation = (e) => {
        e.stopPropagation();
    };

    render() {
        const className = getClassName('sten-file-upload', this.props.className, {
            'sten-file-upload--invalid': this.props.invalid
        });
        return (
            <section className={className}>
                <Dropzone
                    accept={this.props.allowedFileTypes}
                    onDrop={this.handleDrop}
                    minSize={this.props.minSize}
                    maxSize={this.props.maxSize}
                    multiple={this.props.multiple}
                    disabled={this.props.isDisabled}
                >
                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                        return (
                            <React.Fragment>
                                <div
                                    {...getRootProps()}
                                    className={getClassName('sten-file-upload__drop-area', null, {
                                        'sten-file-upload__drop-area--disabled': this.props.isDisabled,
                                        'sten-file-upload__drop-area--active': isDragActive,
                                        'sten-file-upload__drop-area--has-image':
                                        this.props.isImageUpload && this.props.imageURI
                                    })}
                                >
                                    {!this.props.isDisabled && (
                                        <React.Fragment>
                                            <input {...getInputProps()} name={this.props.name} />
                                            <div className="sten-file-upload__drop-info">
                                                <div
                                                    className={`icon ${isDragReject
                                                        ? 'icon-close' : this.props.icon}`}
                                                />
                                                {!isDragActive && this.props.dropText}
                                                {!isDragActive && this.props.additionalDropText && (
                                                    <div className="sten-file-upload__additional-drop-text">
                                                        {this.props.additionalDropText}
                                                    </div>
                                                )}
                                                {isDragActive && !isDragReject && this.props.dragActiveText}
                                                {isDragReject && this.props.dragRejectedText}
                                                {this.props.imageURI && this.props.isDeletable && !isDragActive && (
                                                    <div
                                                        className="sten-file-upload__delete"
                                                        onClick={this.handleDeleteAreaPropagation}
                                                    >
                                                        <button
                                                            type="button"
                                                            onClick={this.handleDelete}
                                                            className="icon icon-delete btn-link"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </React.Fragment>
                                    )}
                                    {this.props.isImageUpload && this.props.imageURI && (
                                        <img className="sten-file-upload__image-file" src={this.props.imageURI} />
                                    )}
                                </div>
                                {!this.props.isImageUpload
                                && this.props.files.length > 0
                                && (
                                    <ul className="sten-file-upload__files">
                                        {this.props.files.map((file, index) => (
                                            <FileUploadItem
                                                onDeleteClick={this.props.onDeleteClickCb.bind(this, { file, index })}
                                                onDownloadClick={this.props.onDownloadClickCb.bind(this, file)}
                                                key={file.name}
                                                file={file}
                                            />
                                        ))}
                                    </ul>
                                )}
                            </React.Fragment>
                        );
                    }}
                </Dropzone>
            </section>
        );
    }
}

FileUpload.propTypes = {
    allowedFileTypes: PropTypes.arrayOf(PropTypes.string),
    className: PropTypes.string,
    dragActiveText: PropTypes.string,
    dragRejectedText: PropTypes.string,
    dropText: PropTypes.string,
    additionalDropText: PropTypes.string,
    files: PropTypes.arrayOf(PropTypes.any),
    imageURI: PropTypes.string,
    isDeletable: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isImageUpload: PropTypes.bool,
    maxSize: PropTypes.number,
    minSize: PropTypes.number,
    multiple: PropTypes.bool,
    name: PropTypes.string,
    onDeleteClickCb: PropTypes.func,
    onDownloadClickCb: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onRejectCb: PropTypes.func,
    value: PropTypes.string,
    icon: PropTypes.string,
    invalid: PropTypes.bool
};

FileUpload.defaultProps = {
    allowedFileTypes: [],
    className: '',
    dragActiveText: translate('FILE_UPLOAD.DROP_FILE_NOW'),
    dragRejectedText: translate('FILE_UPLOAD.UNSUPPORTED_TYPE'),
    dropText: translate('FILE_UPLOAD.DROP_TEXT'),
    additionalDropText: null,
    files: [],
    imageURI: '',
    isDeletable: false,
    isDisabled: false,
    isImageUpload: false,
    maxSize: 10485760,
    minSize: 1,
    multiple: false,
    name: '',
    onDeleteClickCb: undefined,
    onDownloadClickCb: undefined,
    onRejectCb: undefined,
    value: '',
    icon: 'icon-file-chart',
    invalid: false
};

export default FileUpload;
