import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ScrollArea from 'components/scroll-area/scroll-area';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { splitSegments } from 'components/segment-select/segment-select-helper';
/* actions */
import { updateUserSettings, toggleUserSetting } from '../../../../user-actions';
/* selectors */
import {
    getTradingRoutesSortedAlphabetically,
    getSelectedSegments,
    getSelectedCargoStatuses
} from '../../market-intel-selectors';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import Checkbox from 'components/checkbox/checkbox';
import Accordion from 'components/accordion/accordion';
import RadioButton from 'components/radio-button/radio-button';
import ImageButton from 'components/image-button/image-button';
import Select from 'components/select/select';
import BunkerexInfo from 'components/bunkerex-info/bunkerex-info';
/* image button src */
import imageButtonClassicSrc from 'assets/images/map-options/btn_classic_map.png';
import imageButtonSatelliteSrc from 'assets/images/map-options/btn_satelite_map.png';
/* styles */
import './market-intel-options.scss';

const mapImagesMap = {
    1: imageButtonClassicSrc,
    2: imageButtonSatelliteSrc
};

export class MarketIntelOptions extends React.PureComponent {
    setMapType(mapTypeId) {
        this.props.updateUserSettings({
            MarketIntelMapType: mapTypeId
        });
    }

    setMapLayerId(layerTypeId) {
        this.props.updateUserSettings({
            MarketIntelLayerType: layerTypeId
        }, 'MarketIntel');
    }

    setSelectedVesselSegments = (selectedSegments) => {
        this.props.updateUserSettings({
            VesselDistributionSelectedSegments: selectedSegments.map(segment => segment.VesselTypeId)
        }, 'MarketIntel');
    };

    setSelectedCargoStatuses = (selectedCargoStatuses) => {
        this.props.updateUserSettings({
            VesselDistributionCargoStatuses: selectedCargoStatuses.map(status => status.Id)
        }, 'MarketIntel');
    };

    setSelectedDistributionType = (distributionTypeId) => () => {
        this.props.updateUserSettings({
            VesselDistributionType: distributionTypeId
        }, 'MarketIntel');
    };

    getTradingRoutesOptions() {
        const areAllRoutesShown = this.props.activeTradingRoutes.length === this.props.tradingRoutes.length;
        return (
            <div className="sten-content__section">
                <div className="form-row">
                    <Checkbox
                        onChange={this.toggleAllTradingRoutes(areAllRoutesShown)}
                        className="text-uppercase"
                        isChecked={areAllRoutesShown}
                    >
                        {translate('GLOBAL.SELECT_ALL')}
                    </Checkbox>
                </div>
                {this.props.tradingRoutes.map(route => (
                    <div className="form-row" key={route.Id}>
                        <Checkbox
                            onChange={this.toggleTradingRoute(route.Id)}
                            className="col-24"
                            isChecked={this.props.activeTradingRoutes.indexOf(route.Id) > -1}
                        >
                            <div className="flex">
                                <div className="flex-grow text-uppercase">
                                    {route.RouteCode}
                                </div>
                                <div className="flex-shrink text-secondary">
                                    {`(${route.StartPort.Name} - ${route.EndPort.Name})`}
                                </div>
                            </div>
                        </Checkbox>
                    </div>
                ))}
            </div>
        );
    }

    getBunkerPricesOptions() {
        const areAllPortsShown = this.props.activeBunkerPorts.length === this.props.bunkerPorts.length;
        return (
            <React.Fragment>
                <div className="sten-content__section">
                    <div className="form-row">
                        <Checkbox
                            onChange={this.toggleAllBunkerPorts(areAllPortsShown)}
                            className="text-uppercase"
                            isChecked={areAllPortsShown}
                        >
                            {translate('GLOBAL.SELECT_ALL')}
                        </Checkbox>
                    </div>
                    {this.props.bunkerPorts.map(port => (
                        <div className="form-row" key={port.Id}>
                            <Checkbox
                                onChange={this.toggleBunkerPort(port.Id)}
                                className="text-uppercase"
                                isChecked={this.props.activeBunkerPorts.indexOf(port.Id) > -1}
                            >
                                {port.Name}
                            </Checkbox>
                        </div>
                    ))}
                </div>
                <BunkerexInfo />
            </React.Fragment>
        );
    }

    getVesselDistributionOptions() {
        if (!this.props.selectedDistributionAreas || !this.props.options.vesselDistributionAreas) {
            return null;
        }
        const areAllAreasShown = this.props.selectedDistributionAreas.length
            === this.props.options.vesselDistributionAreas.length;
        return (
            <React.Fragment>
                <div className="sten-content__section">
                    <div className="row">
                        {this.props.options.vesselSegments.length > 0 && (
                            <div className="col-12">
                                <label className="label label--sm text-uppercase">
                                    {translate('MARKET_INTEL.OPTIONS.SEGMENT')}
                                </label>
                                <Select
                                    clearable
                                    multiple
                                    value={this.props.selectedSegments}
                                    options={this.props.options.vesselSegments}
                                    labelKey="VesselTypeName"
                                    valueKey="VesselTypeId"
                                    onChange={this.setSelectedVesselSegments}
                                    placeholder={translate('MARKET_INTEL.OPTIONS.VESSEL_SEGMENT')}
                                    splitOptionsIntoSections={splitSegments}
                                />
                            </div>
                        )}
                        {this.props.options.cargoStatuses.length > 0 && (
                            <div className="col-12">
                                <label className="label label--sm text-uppercase">
                                    {translate('MARKET_INTEL.OPTIONS.STATUS')}
                                </label>
                                <Select
                                    clearable
                                    multiple
                                    value={this.props.selectedCargoStatuses}
                                    options={this.props.options.cargoStatuses}
                                    labelKey="Title"
                                    valueKey="Id"
                                    onChange={this.setSelectedCargoStatuses}
                                    placeholder={translate('MARKET_INTEL.OPTIONS.CARGO_STATUS')}
                                />
                            </div>
                        )}
                    </div>
                </div>
                <div className="sten-content__section">
                    {this.props.options.vesselDistributionTypes.length > 0
                        && this.props.options.vesselDistributionTypes.map(distributionType => (
                            <div className="form-row" key={distributionType.Id}>
                                <RadioButton
                                    className="text-uppercase"
                                    onChange={this.setSelectedDistributionType(distributionType.Id)}
                                    isChecked={
                                        this.props.selectedDistributionType === distributionType.Id}
                                >
                                    {distributionType.DisplayName}
                                </RadioButton>
                            </div>
                        ))
                    }
                </div>
                {this.props.selectedDistributionType === 2 && this.props.options.vesselDistributionAreas.length > 0 && (
                    <React.Fragment>
                        <div className="sten-content__section sten-content__separator sten-content__separator--inner">
                            <div className="form-row">
                                <Checkbox
                                    onChange={this.toggleAllDistributionAreas(areAllAreasShown)}
                                    isChecked={areAllAreasShown}
                                >
                                    {translate('GLOBAL.SELECT_ALL')}
                                </Checkbox>
                            </div>
                            {this.props.options.vesselDistributionAreas.map(area => (
                                <div className="form-row" key={area.AreaId}>
                                    <Checkbox
                                        onChange={this.toggleDistributionArea(area.AreaId)}
                                        isChecked={this.props.selectedDistributionAreas.indexOf(area.AreaId) > -1}
                                    >
                                        {area.Name}
                                    </Checkbox>
                                </div>
                            ))}
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    getLayerContent = layerTypeId => {
        if (layerTypeId !== this.props.selectedLayerId) {
            return null;
        }
        if (this.props.selectedLayerId === 1) {
            return this.getTradingRoutesOptions();
        } if (this.props.selectedLayerId === 2) {
            return this.getBunkerPricesOptions();
        }
        return this.getVesselDistributionOptions();
    };

    toggleTradingRoute = routeId => () => {
        this.props.toggleUserSetting('TradingRouteIds', routeId);
    };

    toggleAllTradingRoutes = areAllRoutesShown => () => {
        if (areAllRoutesShown) {
            this.props.updateUserSettings({ TradingRouteIds: [] });
        } else {
            const allRouteIds = this.props.tradingRoutes.map(route => route.Id);
            this.props.updateUserSettings({ TradingRouteIds: allRouteIds });
        }
    };

    toggleBunkerPort = portId => () => {
        this.props.toggleUserSetting('BunkerPricesSelectedPorts', portId);
    };

    toggleAllBunkerPorts = areAllPortsShown => () => {
        if (areAllPortsShown) {
            this.props.updateUserSettings({ BunkerPricesSelectedPorts: [] });
        } else {
            const allPortIds = this.props.bunkerPorts.map(port => port.Id);
            this.props.updateUserSettings({ BunkerPricesSelectedPorts: allPortIds });
        }
    };

    toggleDistributionArea = areaId => () => {
        this.props.toggleUserSetting('VesselDistributionAreas', areaId);
    };

    toggleAllDistributionAreas = areAllAreasShown => () => {
        if (areAllAreasShown) {
            this.props.updateUserSettings({ VesselDistributionAreas: [] });
        } else {
            const allAreaIds = this.props.options.vesselDistributionAreas.map(area => area.AreaId);
            this.props.updateUserSettings({ VesselDistributionAreas: allAreaIds });
        }
    };

    toggleCountryNamesAndBorders = () => {
        this.props.updateUserSettings({
            MarketIntelCountryBordersNamesEnabled: !this.props.countryBordersNamesEnabled
        });
    };

    resetAll = () => {
        this.props.updateUserSettings({
            MarketIntelMapType: 1,
            MarketIntelLayerType: 1,
            MarketIntelCountryBordersNamesEnabled: false,
            TradingRouteIds: [],
            BunkerPricesSelectedPorts: [],
            VesselDistributionSelectedSegments: [],
            VesselDistributionCargoStatuses: [],
            VesselDistributionType: 1,
            VesselDistributionAreas: []
        }, 'MarketIntel');
    };

    layerAccordionHeader = () => (
        <h4 className="text-uppercase">
            {ConfigService.featureToggles.showAllMarketIntelFeatures
                ? translate('MARKET_INTEL.OPTIONS.LAYERS')
                : translate('MARKET_INTEL.OPTIONS.VESSEL_DISTRIBUTION')}
        </h4>
    );

    render() {
        if (!this.props.options) {
            return null;
        }
        const { mapTypes, layerTypes } = this.props.options;
        return (
            <div className="sten-map-options sten-content sten-content--has-footer">
                <div className="sten-content__header">
                    <h1 className="sten-content__title flex-grow">
                        {translate('MARKET_INTEL.OPTIONS.TITLE')}
                    </h1>
                    <div className="flex-shrink">
                        {this.props.onCloseClick && (
                            <button
                                className="btn-link icon icon-close"
                                onClick={this.props.onCloseClick}
                            />
                        )}
                    </div>
                </div>
                <ScrollArea className="sten-content__body">
                    <div className="sten-content__section">
                        <div className="flex-row">
                            {mapTypes && mapTypes.map(mapType => (
                                <div className="col-8" key={mapType.Id}>
                                    <ImageButton
                                        imageSrc={mapImagesMap[mapType.Id]}
                                        text={mapType.Title}
                                        onClick={this.setMapType.bind(this, mapType.Id)}
                                        isActive={this.props.mapTypeId === mapType.Id}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="sten-content__section">
                        <div className="form-row">
                            <Checkbox
                                className="text-uppercase"
                                onChange={this.toggleCountryNamesAndBorders}
                                isChecked={this.props.countryBordersNamesEnabled}
                            >
                                {translate('MARKET_INTEL.OPTIONS.COUNTRY_NAMES_AND_BORDERS')}
                            </Checkbox>
                        </div>
                    </div>
                    <Accordion header={this.layerAccordionHeader()}>
                        {ConfigService.featureToggles.showAllMarketIntelFeatures
                            && layerTypes
                            && layerTypes.map((layerType, index) => (
                                <div
                                    key={layerType.Id}
                                    className={`sten-content__section${index > 0 ? ' sten-content__separator' : ''}`}
                                >
                                    <RadioButton
                                        key={layerType.Id}
                                        className="text-uppercase"
                                        onChange={this.setMapLayerId.bind(this, layerType.Id)}
                                        isChecked={this.props.selectedLayerId === layerType.Id}
                                    >
                                        {layerType.Title}
                                    </RadioButton>
                                    {this.getLayerContent(layerType.Id)}
                                </div>
                            ))
                        }
                        {!ConfigService.featureToggles.showAllMarketIntelFeatures
                            && this.getVesselDistributionOptions()}
                    </Accordion>
                </ScrollArea>
                <footer className="sten-content__footer">
                    <div className="flex-grow text-right">
                        <button className="btn btn--secondary" onClick={this.resetAll}>
                            <span className="btn__icon icon icon-reload" />
                            {translate('MARKET_INTEL.OPTIONS.RESET_ALL')}
                        </button>
                    </div>
                </footer>
            </div>
        );
    }
}

MarketIntelOptions.propTypes = {
    activeBunkerPorts: PropTypes.arrayOf(PropTypes.number).isRequired,
    activeTradingRoutes: PropTypes.arrayOf(PropTypes.number).isRequired,
    bunkerPorts: PropTypes.arrayOf(PropTypes.object).isRequired,
    countryBordersNamesEnabled: PropTypes.bool.isRequired,
    mapTypeId: PropTypes.number.isRequired,
    onCloseClick: PropTypes.func.isRequired,
    options: PropTypes.objectOf(PropTypes.any).isRequired,
    selectedCargoStatuses: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedDistributionAreas: PropTypes.arrayOf(PropTypes.number).isRequired,
    selectedDistributionType: PropTypes.number.isRequired,
    selectedLayerId: PropTypes.number.isRequired,
    selectedSegments: PropTypes.arrayOf(PropTypes.object).isRequired,
    toggleUserSetting: PropTypes.func.isRequired,
    tradingRoutes: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateUserSettings: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        activeBunkerPorts: state.userReducer.settings && state.userReducer.settings.BunkerPricesSelectedPorts,
        activeTradingRoutes: state.userReducer.settings && state.userReducer.settings.TradingRouteIds,
        bunkerPorts: state.marketIntelReducer.bunkerPorts,
        countryBordersNamesEnabled:
            state.userReducer.settings && state.userReducer.settings.MarketIntelCountryBordersNamesEnabled,
        mapTypeId: state.userReducer.settings && state.userReducer.settings.MarketIntelMapType,
        options: state.marketIntelReducer.options,
        selectedCargoStatuses: getSelectedCargoStatuses(state),
        selectedDistributionAreas: state.userReducer.settings && state.userReducer.settings.VesselDistributionAreas,
        selectedDistributionType: state.userReducer.settings && state.userReducer.settings.VesselDistributionType,
        selectedLayerId: state.userReducer.settings && state.userReducer.settings.MarketIntelLayerType,
        selectedSegments: getSelectedSegments(state),
        tradingRoutes: getTradingRoutesSortedAlphabetically(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleUserSetting: (settingName, value, settingUpdated) =>
            toggleUserSetting(dispatch, settingName, value, settingUpdated),
        updateUserSettings: (settings, settingUpdated) => updateUserSettings(dispatch, settings, settingUpdated)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketIntelOptions);
