import React from 'react';
import { Outlet } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* actions */
import { getUser } from './user-actions';
import { getActiveNotifications } from './notifications/notifications-actions';
import { getSelectedVesselNotifications } from './home/home-actions';
import { getRangeOptions } from './home/right-side-bar/vessel-info/vessel-info-actions';
import { getFuelTypes } from 'components/vessel-report/vessel-report-actions';
/* helpers */
import TimeHelper from 'utils/helpers/time-helper';
/* components */
import HotkeysManager from 'components/hotkeys-manager/hotkeys-manager';
import LeftSideBar from './left-side-bar/left-side-bar';
/* services */
import LocalStorageService from 'services/local-storage-service';

export class User extends React.PureComponent {
    state = {
        prevPreferredDateFormat: null,
        restrictedRoute: null,
        prevLocation: '',
        prevRestrictedRoutes: null
    };

    componentDidMount() {
        this.props.getUser();
        this.getNotificationsInterval = setInterval(this.getNotifications, 5 * 60000);
        /* This data is used in helpers, so it needs to be available before components load */
        this.props.getRangeOptions();
        this.props.getFuelTypes();
    }

    static getDerivedStateFromProps(props, state) {
        const newState = {};
        if (props.restrictedRoutes.length > 0
            && (props.router.location !== state.prevLocation
                || props.restrictedRoutes !== state.prevRestrictedRoutes)) {
            newState.prevRestrictedRoutes = props.restrictedRoutes;
            newState.restrictedRoute = null;
            for (let i = 0; i < props.restrictedRoutes.length; i++) {
                if (props.router.location.pathname === props.restrictedRoutes[i].route
                    || props.router.location.pathname.startsWith(`${props.restrictedRoutes[i].route}/`)) {
                    newState.restrictedRoute = props.restrictedRoutes[i];
                    break;
                }
            }
            if (newState.restrictedRoute) {
                if (newState.restrictedRoute.redirectTo) {
                    props.router.navigate({ pathname: newState.restrictedRoute.redirectTo }, { replace: true });
                } else {
                    const homePage = LocalStorageService.getHomePage();
                    props.router.navigate({ pathname: state.prevLocation || homePage }, { replace: true });
                }
            } else {
                newState.prevLocation = props.router.location;
            }
        }
        if (props.preferredDateFormat !== state.prevPreferredDateFormat) {
            TimeHelper.setFormat(props.preferredDateFormat);
            newState.prevPreferredDateFormat = props.preferredDateFormat;
        }
        return newState;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user && this.props.user) {
            this.getNotifications(this.props);
        }
        if ((this.props.settingUpdated !== prevProps.settingUpdated && this.props.settingUpdated.name === 'mapFilters')
            || (this.props.resourceUpdated !== prevProps.resourceUpdated
                && this.props.resourceUpdated.name === 'fleets')) {
            this.getNotifications(this.props);
        }
    }

    componentWillUnmount() {
        if (this.getNotificationsInterval) {
            clearInterval(this.getNotificationsInterval);
        }
    }

    getNotifications = (props = this.props) => {
        const { permissions } = props;

        if (permissions && permissions.MenuNotifications) {
            props.getNotifications();
            if (props.selectedVesselId) {
                props.getSelectedVesselNotifications();
            }
        }
    };

    render() {
        if (!this.props.user || this.props.rangeOptions.length === 0 || this.state.restrictedRoute) { return null; }
        return (
            <HotkeysManager pathName={this.props.router.location.pathname}>
                <div className="sten-content-page">
                    <LeftSideBar />
                    <Outlet />
                </div>
            </HotkeysManager>
        );
    }
}

User.propTypes = {
    getFuelTypes: PropTypes.func.isRequired,
    getNotifications: PropTypes.func.isRequired,
    getRangeOptions: PropTypes.func.isRequired,
    getSelectedVesselNotifications: PropTypes.func.isRequired,
    getUser: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any),
    preferredDateFormat: PropTypes.string,
    rangeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    resourceUpdated: PropTypes.objectOf(PropTypes.any).isRequired,
    restrictedRoutes: PropTypes.arrayOf(PropTypes.object).isRequired,
    router: TRouter.isRequired,
    selectedVesselId: PropTypes.number,
    settingUpdated: PropTypes.objectOf(PropTypes.any).isRequired,
    user: PropTypes.objectOf(PropTypes.any)
};

User.defaultProps = {
    permissions: null,
    preferredDateFormat: null,
    selectedVesselId: null,
    user: null
};

function mapStateToProps(state) {
    return {
        permissions: state.userReducer.permissions,
        preferredDateFormat: state.userReducer.settings && state.userReducer.settings.DateFormat,
        rangeOptions: state.vesselInfoReducer.rangeOptions,
        resourceUpdated: state.userReducer.resourceUpdated,
        restrictedRoutes: state.userReducer.restrictedRoutes,
        selectedVesselId: state.homeReducer.selectedVesselId,
        settingUpdated: state.userReducer.settingUpdated,
        user: state.userReducer.user
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getFuelTypes: () => getFuelTypes(dispatch),
        getNotifications: () => getActiveNotifications(dispatch),
        getRangeOptions: () => getRangeOptions(dispatch),
        getSelectedVesselNotifications: () => getSelectedVesselNotifications(dispatch),
        getUser: () => getUser(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(User));
