/* helpers */
import MockHelper from 'utils/helpers/mock-helper';

const iceClassDummyData = ['Yes', 'No', 'N/A'];
const coatingDummyData = ['EPOXY', 'PURE EPOXY', 'HARD EPOXY PHENOLIC', 'EPICON T-800',
    '3-COAT PURE EPOXY', 'EPICON T-800(PHENOLIC EPOXY)', 'Phenolic Epoxy CMP Epicon T-800',
    'PHENOLIC EPOXY - JOTUN TANKGUARD SPECIAL ULTRA, LIGHT GREY'];
const flagDummyData = ['Marshall Islands', 'Liberia', 'Singapore',
    'Libya', 'Panama', 'The United Kingdom of Great Britain'];

export const generateMockedFleetListReport = () => {
    const vessels = MockHelper.getVessels();
    return vessels.map((vessel) => ({
        VesselName: `${vessel.Title}`,
        Imo: vessel.Imo,
        DWT: vessel.DWT,
        YearBuilt: MockHelper.getRandomDate('1970', '2020'),
        LOA: MockHelper.getRandomNumber(150, 200, 2, 0.1),
        Beam: MockHelper.getRandomNumber(30, 40, 2, 0.1),
        Draft: MockHelper.getRandomNumber(12, 15, 2, 0.1),
        NumberOfTanks: MockHelper.getRandomNumber(12, 20),
        HasScrubber: MockHelper.getRandomBoolean(0.2),
        Coating: MockHelper.getRandomArrayItem(coatingDummyData, 0.1),
        IceClass: MockHelper.getRandomArrayItem(iceClassDummyData, 0.1),
        Flag: MockHelper.getRandomArrayItem(flagDummyData, 0.1)
    }));
};
