import React from 'react';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { getSeverity, getObjectProp, formatNumber } from 'utils/helpers/info-helper';
import { formatEuEtsPrice, formatEuEtsTooltip } from 'components/euets/euets-helpers';
import TimeHelper from 'utils/helpers/time-helper';
/* services */
import ConfigService from 'services/config-api/config-service';
import EuEtsService from 'services/mt-api/euets-service';
/* components */
import CiiRating from 'components/cii-rating/cii-rating';

const ENERGY_EFFICIENCY = t('UNITS.ENERGY_EFFICIENCY');
const EMISSIONS = t('UNITS.EMISSIONS');
const EMISSIONS_KG = t('UNITS.EMISSIONS_KG');
const CARBON_INTENSITY = t('UNITS.CARBON_INTENSITY');

const eeoiDeviations = {
    'text-warning': ['[-Infinity,3)', '(40,Infinity]']
};

const consDeviations = {
    'text-warning': ['[-Infinity,0)']
};

const getCiiValueRenderer = (ciiKey, aerKey, isText = false) => (data, defaultValue = t('GLOBAL.NOT_AVAILABLE')) => {
    const ciiValue = getObjectProp(data, ciiKey);
    const aerValue = getObjectProp(data, aerKey);
    if (!ciiValue || !aerValue) {
        return defaultValue;
    }
    if (isText) {
        return `[${ciiValue}] ${formatNumber(aerValue, 2)} ${ENERGY_EFFICIENCY}`;
    }
    return (
        <React.Fragment>
            <CiiRating className="inline" isEstimated value={ciiValue} />
            <span className="inline">{formatNumber(aerValue, 2)} {ENERGY_EFFICIENCY}</span>
        </React.Fragment>
    );
};

const VALUE_CANT_BE_CALCULATED_TOOLTIP = t('FOOTPRINT_TABLE.VALUE_CANT_BE_CALCULATED_TOOLTIP');

export const getFootprintRows = (isVoyage, withAdditionalEEFields = false, withEuEtsFields = true) => {
    let additionalAerTooltipDescription = t('FOOTPRINT_TABLE.AER_VOYAGE_SELECTED');
    if (!isVoyage) {
        additionalAerTooltipDescription = t('FOOTPRINT_TABLE.AER_RANGE_SELECTED');
    }
    const currentYearKey = new Date().getFullYear();
    const previousYearKey = currentYearKey - 1;
    const footprintRows = {
        ciiYearlyRating: () => [
            {
                label: t('FOOTPRINT_TABLE.YTD_CII_AND_AER', { year: currentYearKey }),
                key: `vesselCii.${currentYearKey}.CIIWithoutCorrectionFactor`,
                noValueTooltip: VALUE_CANT_BE_CALCULATED_TOOLTIP,
                renderValue: getCiiValueRenderer(
                    `vesselCii.${currentYearKey}.CIIWithoutCorrectionFactor`,
                    `vesselCii.${currentYearKey}.AERWithoutCorrectionFactor`
                ),
                renderXlsxValue: getCiiValueRenderer(
                    `vesselCii.${currentYearKey}.CIIWithoutCorrectionFactor`,
                    `vesselCii.${currentYearKey}.AERWithoutCorrectionFactor`,
                    true
                )
            }, {
                label: t('FOOTPRINT_TABLE.YTD_CII_AND_AER_WITH_CORRECTION', { year: currentYearKey }),
                key: `vesselCii.${currentYearKey}.CII`,
                noValueTooltip: VALUE_CANT_BE_CALCULATED_TOOLTIP,
                renderValue: getCiiValueRenderer(
                    `vesselCii.${currentYearKey}.CII`,
                    `vesselCii.${currentYearKey}.AER`
                ),
                renderXlsxValue: getCiiValueRenderer(
                    `vesselCii.${currentYearKey}.CII`,
                    `vesselCii.${currentYearKey}.AER`,
                    true
                )
            }, {
                label: t('FOOTPRINT_TABLE.CII_AND_AER', { year: previousYearKey }),
                key: `vesselCii.${previousYearKey}.CIIWithoutCorrectionFactor`,
                noValueTooltip: VALUE_CANT_BE_CALCULATED_TOOLTIP,
                renderValue: getCiiValueRenderer(
                    `vesselCii.${previousYearKey}.CIIWithoutCorrectionFactor`,
                    `vesselCii.${previousYearKey}.AERWithoutCorrectionFactor`
                ),
                renderXlsxValue: getCiiValueRenderer(
                    `vesselCii.${previousYearKey}.CIIWithoutCorrectionFactor`,
                    `vesselCii.${previousYearKey}.AERWithoutCorrectionFactor`,
                    true
                )
            }, {
                label: t('FOOTPRINT_TABLE.CII_AND_AER_WITH_CORRECTION', { year: previousYearKey }),
                key: `vesselCii.${previousYearKey}.CII`,
                noValueTooltip: VALUE_CANT_BE_CALCULATED_TOOLTIP,
                renderValue: getCiiValueRenderer(
                    `vesselCii.${previousYearKey}.CII`,
                    `vesselCii.${previousYearKey}.AER`
                ),
                renderXlsxValue: getCiiValueRenderer(
                    `vesselCii.${previousYearKey}.CII`,
                    `vesselCii.${previousYearKey}.AER`,
                    true
                )
            }
        ],
        energyEfficiency: [
            {
                label: t('FOOTPRINT_TABLE.EEOI'),
                key: 'voyageFootprint.EEOI',
                unit: ENERGY_EFFICIENCY,
                type: 'number',
                decimals: 2,
                numFmt: `0.00 "${t('UNITS.ENERGY_EFFICIENCY')}"`,
                tooltip: t('COMMON.EEOI_INFO_TOOLTIP'),
                getSeverity: (value) => getSeverity(eeoiDeviations, value),
                noValueTooltip: VALUE_CANT_BE_CALCULATED_TOOLTIP
            },
            {
                label: t('FOOTPRINT_TABLE.CII_AND_AER', { year: '' }).trim(),
                key: 'voyageFootprint.AER',
                tooltip: `${t('COMMON.AER_INFO_TOOLTIP')}; ${additionalAerTooltipDescription}`,
                noValueTooltip: VALUE_CANT_BE_CALCULATED_TOOLTIP,
                renderValue: getCiiValueRenderer('voyageFootprint.CII', 'voyageFootprint.AER'),
                renderXlsxValue: getCiiValueRenderer('voyageFootprint.CII', 'voyageFootprint.AER', true)
            },
            {
                label: t('FOOTPRINT_TABLE.CII_AND_AER_WITH_CORRECTION', { year: '' }).trim(),
                key: 'voyageFootprint.AERWithCorrections',
                tooltip: `${t('FOOTPRINT_TABLE.AER_WITH_CORRECTIONS_INFO_TOOLTIP')};`
                    + ` ${additionalAerTooltipDescription}`,
                noValueTooltip: VALUE_CANT_BE_CALCULATED_TOOLTIP,
                renderValue: getCiiValueRenderer(
                    'voyageFootprint.CIIWithCorrections',
                    'voyageFootprint.AERWithCorrections'
                ),
                renderXlsxValue: getCiiValueRenderer(
                    'voyageFootprint.CIIWithCorrections',
                    'voyageFootprint.AERWithCorrections',
                    true
                )
            }
        ],
        emissions: [
            {
                label: t('FOOTPRINT_TABLE.CO2'),
                key: 'voyageFootprint.TotalEmissions.CO2',
                type: 'number',
                decimals: 2,
                unit: EMISSIONS,
                numFmt: `0.00 "${EMISSIONS}"`
            },
            {
                label: t('FOOTPRINT_TABLE.CO2_PER_DAY'),
                key: 'voyageFootprint.AverageEmissions.CO2',
                type: 'number',
                decimals: 2,
                unit: EMISSIONS,
                numFmt: `0.00 "${EMISSIONS}"`
            },
            {
                label: t('FOOTPRINT_TABLE.CH4'),
                key: 'voyageFootprint.TotalEmissions.CH4',
                type: 'number',
                decimals: 2,
                unit: EMISSIONS_KG,
                numFmt: `0.00 "${EMISSIONS_KG}"`
            },
            {
                label: t('FOOTPRINT_TABLE.CO'),
                key: 'voyageFootprint.TotalEmissions.CO',
                type: 'number',
                decimals: 2,
                unit: EMISSIONS_KG,
                numFmt: `0.00 "${EMISSIONS_KG}"`
            },
            {
                label: t('FOOTPRINT_TABLE.N2O'),
                key: 'voyageFootprint.TotalEmissions.N2O',
                type: 'number',
                decimals: 2,
                unit: EMISSIONS_KG,
                numFmt: `0.00 "${EMISSIONS_KG}"`
            },
            {
                label: t('FOOTPRINT_TABLE.NMVOC'),
                key: 'voyageFootprint.TotalEmissions.NMVOC',
                type: 'number',
                decimals: 2,
                unit: EMISSIONS_KG,
                numFmt: `0.00 "${EMISSIONS_KG}"`
            }
        ],
        vmsEmissions: [
            {
                label: t('FOOTPRINT_TABLE.EEOI'),
                key: 'voyageVmsFootprint.EEOI',
                type: 'number',
                decimals: 2,
                unit: ENERGY_EFFICIENCY,
                numFmt: `0.00 "${ENERGY_EFFICIENCY}"`,
                getSeverity: (value) => getSeverity(eeoiDeviations, value)
            },
            {
                label: t('FOOTPRINT_TABLE.AER'),
                key: 'voyageVmsFootprint.AER',
                type: 'number',
                decimals: 2,
                unit: ENERGY_EFFICIENCY,
                numFmt: `0.00 "${ENERGY_EFFICIENCY}"`
            },
            {
                label: t('FOOTPRINT_TABLE.CO2'),
                key: 'voyageVmsFootprint.CO2',
                type: 'number',
                decimals: 2,
                unit: EMISSIONS,
                numFmt: `0.00 "${EMISSIONS}"`
            },
            {
                label: t('FOOTPRINT_TABLE.SOX_MT'),
                key: 'voyageVmsFootprint.SOxMt',
                type: 'number',
                decimals: 2,
                unit: EMISSIONS,
                numFmt: `0.00 "${EMISSIONS}"`
            },
            {
                label: t('FOOTPRINT_TABLE.SOX'),
                key: 'voyageVmsFootprint.SOx',
                type: 'number',
                decimals: 4,
                unit: CARBON_INTENSITY,
                numFmt: `0.0000 "${CARBON_INTENSITY}"`
            },
            {
                label: t('FOOTPRINT_TABLE.NOX_MT'),
                key: 'voyageVmsFootprint.NOxMt',
                type: 'number',
                decimals: 2,
                unit: EMISSIONS,
                numFmt: `0.00 "${EMISSIONS}"`
            },
            {
                label: t('FOOTPRINT_TABLE.NOX'),
                key: 'voyageVmsFootprint.NOx',
                type: 'number',
                decimals: 4,
                unit: CARBON_INTENSITY,
                numFmt: `0.0000 "${CARBON_INTENSITY}"`
            }
        ],
        consumption: {
            type: 'number',
            unit: t('UNITS.FUEL'),
            getSeverity: (value) => getSeverity(consDeviations, value)
        }
    };
    if (withAdditionalEEFields) {
        footprintRows.energyEfficiency.push({
            key: 'voyageFootprint.CargoOnBoard',
            type: 'number',
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.CARGO_ONBOARD'),
            unit: t('UNITS.CARGO'),
            numFmt: `0.0 "${t('UNITS.CARGO')}"`,
            boundaries: { min: 10000, max: Infinity },
            suspiciousTooltip: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.SUSPICIOUS_CARGO_ONBOARD')
        }, {
            key: 'voyageFootprint.LadenMiles',
            type: 'number',
            decimals: 1,
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.LADEN_MILES'),
            unit: t('UNITS.DISTANCE'),
            numFmt: '0.0 "NM"',
            boundaries: { min: 400, max: Infinity },
            suspiciousTooltip: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.SUSPICIOUS_MILES')
        }, {
            key: 'voyageFootprint.BallastMiles',
            type: 'number',
            decimals: 1,
            label: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.BALLAST_MILES'),
            unit: t('UNITS.DISTANCE'),
            numFmt: '0.0 "NM"',
            boundaries: { min: 400, max: Infinity },
            suspiciousTooltip: t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.VOYAGE_REPORT.SUMMARY.SUSPICIOUS_MILES')
        });
    }
    if (withEuEtsFields && ConfigService.featureToggles.showEuEts) {
        footprintRows.emissions.unshift(
            {
                label: t('FOOTPRINT_TABLE.EU_ETS_PRICE'),
                key: 'voyageEuEts.euEts',
                renderValue: (data) => formatEuEtsPrice(data),
                getTooltip: (data) => formatEuEtsTooltip(data)
            }, {
                label: t('FOOTPRINT_TABLE.CO2_PRICE'),
                key: 'euEtsPrice.price',
                renderValue: (data) => (
                    <React.Fragment>
                        {`${data.euEtsPrice?.price} ${data.euEtsPrice?.currency}`}
                        <br />{`(${TimeHelper.getFormatted(data.euEtsPrice?.date)})`}
                    </React.Fragment>
                ),
                getModalData: (data) => ({
                    title: t('FOOTPRINT_TABLE.EU_ETS_CHART'),
                    imageSrc: EuEtsService.getEtsChartLink(data.euEtsPrice?.currency)
                }),
                modalButtonTooltip: t('FOOTPRINT_TABLE.SHOW_EU_ETS_CHART')
            }
        );
    }
    return footprintRows;
};
