import { takeEvery, put, select, all } from 'redux-saga/effects';
/* utils */
import { findInNestedArray } from 'utils/helpers/array-helper';
/* actions */
import { ActionTypes } from './port-management-actions';
import { ActionTypes as TerminalActionTypes } from 'components/terminal-and-berth-info/terminal/terminal-info-actions';
import { ActionTypes as BerthActionTypes } from 'components/terminal-and-berth-info/berth/berth-info-actions';
import { ActionTypes as PIActionTypes } from 'components/port-info/port-info-actions';
import { ActionTypes as CommentActionTypes } from 'components/comments/comment-actions';
/* services */
import PortManagementService from 'services/core-api/port-management-service';
import PortService from 'services/core-api/port-service';

function* getPinnedPorts() {
    const userSettings = yield select(state => state.userReducer.settings);
    const params = {
        PortIds: userSettings.PortManagementPinnedPortIds,
        TerminalIds: userSettings.PortManagementPinnedTerminalIds,
        BerthIds: userSettings.PortManagementPinnedBerthIds
    };

    const ports = yield PortManagementService.getPinnedItems(params);
    if (ports) {
        yield put({ type: ActionTypes.PORT_MANAGEMENT_SET_PINNED, ports });
    }
}

let isFetchingPorts = false;

function* getPorts(action) {
    if (!isFetchingPorts) {
        const { portParams } = yield select(state => state.portManagementReducer);
        const params = { ...portParams, ...action.params };
        if (params.CountryName || params.PortName || params.TerminalName || params.BerthName) {
            isFetchingPorts = true;
            const portsResponse = yield PortManagementService.get(params);
            isFetchingPorts = false;
            if (portsResponse) {
                const ports = { ...portsResponse };
                if (params.Offset > 0) {
                    const fetchedPorts = yield select(state => state.portManagementReducer.ports);
                    ports.PageItems = [...fetchedPorts.PageItems, ...ports.PageItems];
                }
                yield put({ type: ActionTypes.PORT_MANAGEMENT_SET, ports, params });
            }
        } else {
            yield put({ type: ActionTypes.PORT_MANAGEMENT_SET_PARAMS, params });
        }
    }
}

function* getOptions() {
    const permissions = yield select(state => state.userReducer.permissions);
    const res = yield all({
        bunkeringTypes: PortService.getBunkeringTypes(),
        columns: PortManagementService.getColumns(),
        countries: PortManagementService.getCountries(),
        services: permissions.PortManagementGetPortCompleteInfo ? PortManagementService.getServices() : null
    });
    if (res) {
        yield put({ ...res, type: ActionTypes.PORT_MANAGEMENT_SET_OPTIONS });
    }
}

function* handlePortUpdate(action) {
    const { isMounted, ports, pinnedPorts } = yield select(state => state.portManagementReducer);
    if (isMounted) {
        if (!action.id || (ports && ports.PageItems.findIndex(p => p.Id === action.id) > -1)) {
            yield getPorts({ Offset: 0 });
        }
        if (!action.id || pinnedPorts.findIndex(p => p.Id === action.id) > -1) {
            yield getPinnedPorts();
        }
    }
    if (action.port) {
        PortService.get({ ImportanceLevel: action.port.ImportanceLevel });
    }
}

function* handleTerminalUpdate(action) {
    const { isMounted, ports, pinnedPorts } = yield select(state => state.portManagementReducer);
    if (isMounted) {
        if (ports) {
            const foundInPorts = ports.PageItems.find(p => p.Id === action.terminal.PortId);
            if (foundInPorts) {
                yield getPorts({ Offset: 0 });
            }
        }
        const foundInPinned = pinnedPorts.find(p => p.Id === action.terminal.PortId);
        if (foundInPinned) {
            yield getPinnedPorts();
        }
    }
}

function* handleBerthUpdate(action) {
    const { isMounted, ports, pinnedPorts } = yield select(state => state.portManagementReducer);
    if (isMounted) {
        if (ports) {
            const foundInPorts = findInNestedArray(ports.PageItems, 'Terminals', b => b.Id === action.berth.TerminalId);
            if (foundInPorts) {
                yield getPorts({ Offset: 0 });
            }
        }
        const foundInPinned = findInNestedArray(pinnedPorts, 'Terminals', b => b.Id === action.berth.TerminalId);
        if (foundInPinned) {
            yield getPinnedPorts();
        }
    }
}

function* handleCommentUpdate(action) {
    const { isMounted, ports, pinnedPorts } = yield select(state => state.portManagementReducer);
    if (isMounted) {
        const portItems = ports ? ports.PageItems : [];
        let foundInPorts;
        let foundInPinned;
        if (action.resource === 'port') {
            foundInPorts = portItems.find(p => p.Id === action.resourceId);
            foundInPinned = pinnedPorts.find(p => p.Id === action.resourceId);
        } else if (action.resource === 'terminal') {
            foundInPorts = findInNestedArray(portItems, 'Terminals', t => t.Id === action.resourceId);
            foundInPinned = findInNestedArray(pinnedPorts, 'Terminals', t => t.Id === action.resourceId);
        } else if (action.resource === 'berth') {
            foundInPorts = findInNestedArray(portItems, 'Terminals.Berths', b => b.Id === action.resourceId);
            foundInPinned = findInNestedArray(pinnedPorts, 'Terminals.Berths', b => b.Id === action.resourceId);
        }
        if (foundInPorts) {
            yield getPorts({ Offset: 0 });
        }
        if (foundInPinned) {
            yield getPinnedPorts();
        }
    }
}

export default function* portManagementSaga() {
    yield takeEvery(ActionTypes.PORT_MANAGEMENT_GET, getPorts);
    yield takeEvery(ActionTypes.PORT_MANAGEMENT_GET_PINNED, getPinnedPorts);
    yield takeEvery(ActionTypes.PORT_MANAGEMENT_GET_OPTIONS, getOptions);
    yield takeEvery(TerminalActionTypes.TERMINAL_INFO_UPDATED, handleTerminalUpdate);
    yield takeEvery(BerthActionTypes.BERTH_INFO_UPDATED, handleBerthUpdate);
    yield takeEvery(ActionTypes.PORT_MANAGEMENT_HANDLE_UPDATE, handlePortUpdate);
    yield takeEvery(PIActionTypes.PORT_INFO_UPDATE, handlePortUpdate);
    yield takeEvery(CommentActionTypes.COMMENT_UPDATED, handleCommentUpdate);
}
