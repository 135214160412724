export const ActionTypes = {
    WEATHER_ROUTING_CLEAR_DATA: 'WEATHER_ROUTING_CLEAR_DATA',
    WEATHER_ROUTING_GET_VOYAGES: 'WEATHER_ROUTING_GET_VOYAGES',
    WEATHER_ROUTING_SET_VOYAGES: 'WEATHER_ROUTING_SET_VOYAGES',
    WEATHER_ROUTING_POST_REQUEST: 'WEATHER_ROUTING_POST_REQUEST',
    WEATHER_ROUTING_GET_FLEETS: 'WEATHER_ROUTING_GET_FLEETS',
    WEATHER_ROUTING_SET_FLEETS: 'WEATHER_ROUTING_SET_FLEETS',
    WEATHER_ROUTING_SET_EDITING_OPTIONS: 'WEATHER_ROUTING_SET_EDITING_OPTIONS',
    WEATHER_ROUTING_GET_PORTS: 'WEATHER_ROUTING_GET_PORTS',
    WEATHER_ROUTING_SET_PORTS: 'WEATHER_ROUTING_SET_PORTS',
    WEATHER_ROUTING_GET_LEG_DATA: 'WEATHER_ROUTING_GET_LEG_DATA',
    WEATHER_ROUTING_SET_LEG_DATA: 'WEATHER_ROUTING_SET_LEG_DATA',
    WEATHER_ROUTING_SET_LEG_AIS_POINTS: 'WEATHER_ROUTING_SET_LEG_AIS_POINTS',
    WEATHER_ROUTING_GET_LEG_AIS_POINTS: 'WEATHER_ROUTING_GET_LEG_AIS_POINTS',
    WEATHER_ROUTING_SET_INTENDED_REQUEST: 'WEATHER_ROUTING_SET_INTENDED_REQUEST',
    WEATHER_ROUTING_DELETE_REQUEST: 'WEATHER_ROUTING_DELETE_REQUEST',
    WEATHER_ROUTING_STOP_REQUEST: 'WEATHER_ROUTING_STOP_REQUEST',
    WEATHER_ROUTING_REFRESH_REQUEST: 'WEATHER_ROUTING_REFRESH_REQUEST',
    WEATHER_ROUTING_DELETE_LEG: 'WEATHER_ROUTING_DELETE_LEG',
    WEATHER_ROUTING_TOGGLE_MAP_SHOWN: 'WEATHER_ROUTING_TOGGLE_MAP_SHOWN',
    WEATHER_ROUTING_TOGGLE_AIS_SHOWN: 'WEATHER_ROUTING_TOGGLE_AIS_SHOWN',
    WEATHER_ROUTING_SET_FILTERS: 'WEATHER_ROUTING_SET_FILTERS',
    WEATHER_ROUTING_SET_FILTERS_AND_UPDATE: 'WEATHER_ROUTING_SET_FILTERS_AND_UPDATE',
    WEATHER_ROUTING_RESET_ALL: 'WEATHER_ROUTING_RESET_ALL',
    WEATHER_ROUTING_GET_INITIAL_DATA: 'WEATHER_ROUTING_GET_INITIAL_DATA',
    WEATHER_ROUTING_GET_DATA: 'WEATHER_ROUTING_GET_DATA',
    WEATHER_ROUTING_SET_DUPLICATE_REQUEST_DATA: 'WEATHER_ROUTING_SET_DUPLICATE_REQUEST_DATA',
    WEATHER_ROUTING_UPDATE_FIELD: 'WEATHER_ROUTING_UPDATE_FIELD',
    WEATHER_ROUTING_UPDATE_FIELD_VALUE: 'WEATHER_ROUTING_UPDATE_FIELD_VALUE',
    WEATHER_ROUTING_SORT_ASC: 'WEATHER_ROUTING_SORT_ASC'
};

export function sortAscending(dispatch) {
    dispatch({ type: ActionTypes.WEATHER_ROUTING_SORT_ASC });
}

export function clearData(dispatch) {
    dispatch({
        type: ActionTypes.WEATHER_ROUTING_CLEAR_DATA
    });
}

export function getRequestData(dispatch, queryParams, legId, changed) {
    dispatch({
        type: ActionTypes.WEATHER_ROUTING_GET_DATA,
        queryParams,
        legId,
        changed
    });
}

export function postRequest(dispatch) {
    dispatch({ type: ActionTypes.WEATHER_ROUTING_POST_REQUEST });
}

export function updateRequestData(dispatch, prop, value, editingOptions) {
    dispatch({
        type: ActionTypes.WEATHER_ROUTING_UPDATE_FIELD,
        prop,
        value,
        editingOptions
    });
}

export const getInitialData = (dispatch) => {
    dispatch({ type: ActionTypes.WEATHER_ROUTING_GET_INITIAL_DATA });
};

export const getFleets = (dispatch) => {
    dispatch({ type: ActionTypes.WEATHER_ROUTING_GET_FLEETS });
};

export const getVoyages = (dispatch, shouldFetchMore, forceFetch) => {
    dispatch({
        type: ActionTypes.WEATHER_ROUTING_GET_VOYAGES,
        shouldFetchMore,
        forceFetch
    });
};

export const setFiltersAndUpdate = (dispatch, filters) => {
    dispatch({
        type: ActionTypes.WEATHER_ROUTING_SET_FILTERS_AND_UPDATE,
        filters
    });
};

export function getPorts(dispatch, params) {
    dispatch({
        type: ActionTypes.WEATHER_ROUTING_GET_PORTS,
        params
    });
}

export function getLegData(dispatch, legId, hideLoader) {
    dispatch({
        type: ActionTypes.WEATHER_ROUTING_GET_LEG_DATA,
        legId,
        hideLoader
    });
}

export function getLegAisPoints(dispatch) {
    dispatch({ type: ActionTypes.WEATHER_ROUTING_GET_LEG_AIS_POINTS });
}

export function toggleMapShown(dispatch) {
    dispatch({ type: ActionTypes.WEATHER_ROUTING_TOGGLE_MAP_SHOWN });
}

export function toggleAisShown(dispatch) {
    dispatch({ type: ActionTypes.WEATHER_ROUTING_TOGGLE_AIS_SHOWN });
}

export function setIntendedRequest(dispatch, requestId, legId) {
    dispatch({ type: ActionTypes.WEATHER_ROUTING_SET_INTENDED_REQUEST, requestId, legId });
}

export function deleteRequest(dispatch, requestId, legId) {
    dispatch({ type: ActionTypes.WEATHER_ROUTING_DELETE_REQUEST, requestId, legId });
}

export function stopRequest(dispatch, requestId, legId) {
    dispatch({ type: ActionTypes.WEATHER_ROUTING_STOP_REQUEST, requestId, legId });
}

export function refreshRequest(dispatch, requestId, legId) {
    dispatch({ type: ActionTypes.WEATHER_ROUTING_REFRESH_REQUEST, requestId, legId });
}

export function deleteLeg(dispatch, legId) {
    dispatch({ type: ActionTypes.WEATHER_ROUTING_DELETE_LEG, legId });
}

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.WEATHER_ROUTING_RESET_ALL
    });
}
