/* utils */
import { waitForCondition } from 'utils/helpers/info-helper';
import MockHelper from 'utils/helpers/mock-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* services */
import PortService from 'services/core-api/port-service';

const tU = TimeHelper.units;

const vesselConditions = [
    { Id: 1, Name: 'Ballast', DisplayName: 'Ballast' },
    { Id: 2, Name: 'Laden', DisplayName: 'Laden' }
];

const vesselParticularNames = ['Beam', 'MMSI', 'Overall Length', 'Deadweight'];

const mockedData = {
    CurrentDate: null,
    CounterPvarList: null,
    CounterPvarValidationData: null,
    Vessel: null,
    Voyage: null,
    Voyages: [],
    VesselImo: null,
    VoyageId: null
};

let isDataMocked = false;

const mockVoyages = (currentDate, ports) => {
    return MockHelper.getRandomArrayOfItems(30, 60, null, () => {
        const portOfDeparture = MockHelper.getRandomArrayItem(ports);
        const portOfDestination = MockHelper.getRandomArrayItem(ports);
        const maxEndDate = TimeHelper.getDateFromDiff(currentDate, 2 * tU.week);
        const minEndDate = TimeHelper.getDateFromDiff(currentDate, -3 * tU.month);
        const EndDate = MockHelper.getRandomDate(minEndDate, maxEndDate, 0, 0.75);
        const maxStartDate = TimeHelper.getDateFromDiff(EndDate, -tU.week);
        const minStartDate = TimeHelper.getDateFromDiff(EndDate, -3 * tU.week);
        const StartDate = MockHelper.getRandomDate(minStartDate, maxStartDate);
        return {
            EndDate,
            StartDate,
            VoyageId: MockHelper.getUniqueId('VoyageId'),
            VoyageNumber: MockHelper.getUniqueId('VoyageNumber', 2201),
            VoyagePortInfo: !portOfDeparture.Name || !portOfDestination.Name
                ? ''
                : `( ${portOfDeparture.Name} - ${portOfDestination.Name} )`
        };
    });
};

const mockCounterPvarList = (currentDate) => {
    return MockHelper.getRandomArrayOfItems(0, 10, 0, () => {
        const maxDate = TimeHelper.getDateFromDiff(currentDate, -tU.week);
        const minDate = TimeHelper.getDateFromDiff(currentDate, -3 * tU.week);
        const CreatedAt = MockHelper.getRandomDate(minDate, maxDate);
        return {
            CreatedAt,
            CreatedBy: MockHelper.getRandomName(),
            Id: MockHelper.getUniqueId('CounterPvarId'),
            VesselCondition: MockHelper.getRandomArrayItem(vesselConditions)
        };
    });
};

const mockValidationData = () => ({
    VoyageHasReports: MockHelper.getRandomBoolean(0, 0.8),
    VesselParticularsMissingInformation: MockHelper.getArraySlice(vesselParticularNames, 0, 3, true, 0),
    VoyageHasCharterparty: MockHelper.getRandomBoolean(0, 0.8)
});

let isMockingData = false;

const mockData = (currentDate) => {
    isMockingData = true;
    isDataMocked = false;
    mockedData.CurrentDate = currentDate;
    return PortService.get({ ImportanceLevel: 9 }).then(ports => {
        mockedData.Vessel = MockHelper.getRandomVessel();
        mockedData.Voyages = mockVoyages(currentDate, ports);
        mockedData.Voyage = MockHelper.getRandomArrayItem(mockedData.Voyages);
        mockedData.CounterPvarList = mockCounterPvarList(currentDate);
        isDataMocked = true;
        isMockingData = false;
        // eslint-disable-next-line
        console.log('Counter PVAR mocked data: ', mockedData);
        return mockedData;
    });
};

const getMockedData = (shouldUpdate = false) => {
    const currentDate = new Date().toISOString();
    if (shouldUpdate) {
        return mockData(currentDate);
    }
    if (isMockingData || (isDataMocked && TimeHelper.getDateDiff(mockedData.CurrentDate, currentDate) < tU.hour)) {
        return waitForCondition(() => isDataMocked).then(() => mockedData);
    }
    return mockData(currentDate);
};

export const getMockedCounterPvarList = () => {
    return getMockedData().then(data => {
        // eslint-disable-next-line
        console.log('getMockedCounterPvarList: ', data.CounterPvarList);
        return data.CounterPvarList;
    });
};

export const getMockedCounterPvarValidationData = () => {
    return getMockedData().then(data => {
        mockedData.CounterPvarValidationData = mockValidationData();
        // eslint-disable-next-line
        console.log('getMockedCounterPvarValidationData: ', data.CounterPvarValidationData);
        return mockedData.CounterPvarValidationData;
    });
};

export const getMockedVessel = (vesselImo) => {
    let shouldUpdate = false;
    if (vesselImo !== mockedData.VesselImo) {
        mockedData.VesselImo = vesselImo;
        shouldUpdate = true;
    }
    return getMockedData(shouldUpdate).then(data => {
        // eslint-disable-next-line
        console.log('getMockedVessel: ', data.Vessel);
        return data.Vessel;
    });
};

export const getMockedVoyage = (voyageId) => {
    let shouldUpdate = false;
    if (mockedData.VoyageId && voyageId !== mockedData.VoyageId) {
        mockedData.VoyageId = voyageId;
        shouldUpdate = true;
    }
    return getMockedData(shouldUpdate).then(data => {
        // eslint-disable-next-line
        console.log('getMockedVoyage: ', data.Voyage);
        return data.Voyage;
    });
};

export const getMockedVoyages = () => {
    return getMockedData().then(data => {
        // eslint-disable-next-line
        console.log('getMockedVoyages: ', data.Voyages);
        return data.Voyages;
    });
};

export const postMockRequest = (request) => {
    return getMockedData().then(data => {
        const newCounterPvarListItem = {
            CreatedAt: new Date(),
            CreatedBy: MockHelper.getRandomName(),
            Id: MockHelper.getUniqueId('CounterPvarId'),
            VesselCondition: vesselConditions.find(vc => vc.Id === request.VesselCondition)
        };
        const newCounterPvarList = [...data.CounterPvarList, newCounterPvarListItem];
        mockedData.CounterPvarList = newCounterPvarList;
        // eslint-disable-next-line
        console.log('postMockRequest: ', newCounterPvarListItem);
        return newCounterPvarListItem;
    });
};
