export const ActionTypes = {
    BUNKER_PRICES_TOOLTIP_SET_POSITIONS: 'BUNKER_PRICES_TOOLTIP_SET_POSITIONS'
};

export function setBunkerPortPositions(dispatch, positions) {
    dispatch({
        type: ActionTypes.BUNKER_PRICES_TOOLTIP_SET_POSITIONS,
        positions
    });
}
