import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* Actions */
import {
    removeCarouselImages
} from '../gsm-right-side-bar/gsm-right-side-bar-actions';
import { gsmSetActivePanel } from './gsm-right-side-bar-secondary-actions';
/* Components */
import GsmTankerInfo from '../gsm-right-side-bar/gsm-tanker-info/gsm-tanker-info';
import GsmLocationInfo from '../gsm-right-side-bar/gsm-city-info/gsm-city-info';
import GsmDrillInfo from '../gsm-right-side-bar/gsm-drill-info/gsm-drill-info';
import GsmFerryInfo from '../gsm-right-side-bar/gsm-ferry-info/gsm-ferry-info';
import RightSideBar from 'components/right-side-bar/right-side-bar';
/* Models */
import GsmRightSideBarModels from '../gsm-right-side-bar/gsm-right-side-bar-models';

export class GsmRightSideBarSecondary extends React.PureComponent {
    componentDidUpdate(prevProps) {
        if (this.props.allEntities) {
            if (this.props.selectedTankerId !== prevProps.selectedTankerId) {
                if ((this.props.activePanelName !== 'gsm-tanker-info' && this.props.selectedTankerId)
                    || (this.props.activePanelName === 'gsm-tanker-info' && !this.props.selectedTankerId)) {
                    this.toggleActivePanel('gsm-tanker-info', true, this.props);
                }
            } else if (this.props.selectedFerryId !== prevProps.selectedFerryId) {
                if ((this.props.activePanelName !== 'gsm-ferry-info' && this.props.selectedFerryId)
                    || (this.props.activePanelName === 'gsm-ferry-info' && !this.props.selectedFerryId)) {
                    this.toggleActivePanel('gsm-ferry-info', true, this.props);
                }
            } else if (this.props.selectedCityId !== prevProps.selectedCityId) {
                if (
                    (this.props.activePanelName !== 'gsm-city-info' && this.props.selectedCityId)
                    || (this.props.activePanelName === 'gsm-city-info' && !this.props.selectedCityId)) {
                    this.toggleActivePanel('gsm-city-info', true, this.props);
                }
            } else if (this.props.selectedDrillId !== prevProps.selectedDrillId) {
                if ((this.props.activePanelName !== 'gsm-drill-info' && this.props.selectedDrillId)
                    || (this.props.activePanelName === 'gsm-drill-info' && !this.props.selectedDrillId)) {
                    this.toggleActivePanel('gsm-drill-info', true, this.props);
                }
            }
        } else {
            this.props.setActivePanel('', false);
        }
    }

    componentWillUnmount() {
        this.props.setActivePanel('', false);
        this.props.removeCarouselImages();
    }

    getPanelComponent = () => {
        switch (this.props.activePanelName) {
        case 'gsm-tanker-info':
            return (
                <GsmTankerInfo
                    key="tanker-info"
                    stepBack
                />
            );
        case 'gsm-city-info':
            return (
                <GsmLocationInfo
                    key="location-info"
                    stepBack
                />
            );
        case 'gsm-ferry-info':
            return (
                <GsmFerryInfo
                    key="ferry-info"
                    stepBack
                />
            );
        case 'gsm-drill-info':
            return (
                <GsmDrillInfo
                    key="drill-info"
                    stepBack
                />
            );
        default:
            return '';
        }
    };

    toggleActivePanel = (panelName, expand, props = this.props) => {
        if (props.activePanelName === panelName) {
            if (props.selectedTankerId) {
                props.setActivePanel('gsm-tanker-info', true);
            } else if (props.selectedCityId) {
                props.setActivePanel('gsm-city-info', true);
            } else if (props.selectedFerryId) {
                props.setActivePanel('gsm-ferry-info', true);
            } else if (props.selectedFerryId) {
                props.setActivePanel('gsm-drill-info', true);
            } else {
                props.setActivePanel('', false);
            }
        } else {
            props.setActivePanel(panelName, expand);
        }
    };

    render() {
        return (
            <RightSideBar isCollapsed={!this.props.isExpanded}>
                {this.getPanelComponent()}
            </RightSideBar>
        );
    }
}

GsmRightSideBarSecondary.propTypes = {
    activePanelName: PropTypes.string.isRequired,
    allEntities: GsmRightSideBarModels.AllEntities,
    carouselImages: PropTypes.arrayOf(PropTypes.string).isRequired,
    isExpanded: PropTypes.bool.isRequired,
    removeCarouselImages: PropTypes.func.isRequired,
    selectedCityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedDrillId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedFerryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedTankerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setActivePanel: PropTypes.func.isRequired
};

GsmRightSideBarSecondary.defaultProps = {
    allEntities: null,
    selectedCityId: null,
    selectedDrillId: null,
    selectedFerryId: null,
    selectedTankerId: null
};

function mapStateToProps(state) {
    return {
        activePanelName: state.gsmRightSideBarSecondaryReducer.activePanelName,
        allEntities: state.gsmRightSideBarReducer.allEntities,
        carouselImages: state.gsmRightSideBarReducer.carouselImages,
        isExpanded: state.gsmRightSideBarSecondaryReducer.isExpanded,
        selectedCityId: state.gsmReducer.selectedCityId,
        selectedDrillId: state.gsmReducer.selectedDrillId,
        selectedFerryId: state.gsmReducer.selectedFerryId,
        selectedTankerId: state.gsmReducer.selectedTankerId
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setActivePanel: (panelName, expand) => gsmSetActivePanel(dispatch, panelName, expand),
        removeCarouselImages: () => removeCarouselImages(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GsmRightSideBarSecondary);
