import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { formatNumber } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* components */
import ShowMoreButton from 'components/show-more-button/show-more-button';
import CiiRating from 'components/cii-rating/cii-rating';
/* services */
import ConfigService from 'services/config-api/config-service';

const severityClassMap = {
    Alert: 'text-danger',
    Warning: 'text-warning'
};

const DISTANCE = t('UNITS.DISTANCE');
const CONSUMPTION = t('UNITS.CONSUMPTION');
const VALUE_CANT_BE_CALCULATED_TOOLTIP = t('FOOTPRINT_TABLE.VALUE_CANT_BE_CALCULATED_TOOLTIP');

export class CalculatorSummary extends React.PureComponent {
    getTime = (value) => {
        const { isDifference } = this.props;
        let timeClass = '';
        let time = '-';
        if (value) {
            time = TimeHelper.getStringFromDuration(value, ['d', 'h'], isDifference);
            if (value < -1000) {
                timeClass = 'text-success';
            } else if (value > 1000) {
                timeClass = severityClassMap.Alert;
            } else {
                time = '-';
            }
        }
        return <span className={isDifference ? timeClass : ''}>{time}</span>;
    };

    renderConsumption = (summaryData, isDifference) => {
        if (typeof summaryData.Consumption === 'number') {
            const severityClass = summaryData.ConsumptionSeverity
                ? severityClassMap[summaryData.ConsumptionSeverity]
                : '';
            const value = `${formatNumber(summaryData.Consumption, 1, isDifference)} ${CONSUMPTION}`;
            return severityClass ? (
                <span title={t('DISTANCE_CALCULATOR.SUMMARY.CONSUMPTION_TOOLTIP')} className={severityClass}>
                    {value}
                </span>
            ) : value;
        }
        return '';
    };

    renderRow = (value, isDifference, unit = DISTANCE) => (
        <div className="sten-calculator-summary__row">
            {typeof value === 'number'
                ? `${formatNumber(value, 1, isDifference)} ${unit}`
                : '-'}
        </div>
    );

    renderAerValue = (value) => (value === null
        ? t('GLOBAL.NOT_AVAILABLE')
        : `${formatNumber(value, 2)} ${t('UNITS.ENERGY_EFFICIENCY')}`);

    render() {
        const { isDifference } = this.props;

        let summaryData = {};
        if (this.props.data) {
            summaryData = isDifference
                ? this.props.data.DeviationSummary
                : this.props.data.CalculationValues;
        }
        const lastPointETA = this.props.lastPointETA
            ? TimeHelper.getFormatted(this.props.lastPointETA, { utc: true, time: true })
            : '-';

        return (
            <tfoot className="sten-calculator-summary">
                <tr className={this.props.toggleCollapsed ? 'cursor-pointer' : ''} onClick={this.props.toggleCollapsed}>
                    <th colSpan="3">
                        <div className="flex-row flex-center">
                            <div className={`flex-shrink${!this.props.toggleCollapsed ? ' text-secondary' : ''}`}>
                                {t('DISTANCE_CALCULATOR.SUMMARY.TITLE')}
                            </div>
                            <div className="flex-grow">
                                {this.props.toggleCollapsed && (
                                    <ShowMoreButton isMoreShown={!this.props.isCollapsed} invertArrow />
                                )}
                            </div>
                            <div className="flex-shrink text-secondary">
                                {isDifference
                                    ? `${t('DISTANCE_CALCULATOR.SUMMARY.DIFFERENCE')}:`
                                    : `${t('DISTANCE_CALCULATOR.SUMMARY.TOTAL')}:`
                                }
                            </div>
                        </div>
                    </th>
                    <th>{this.getTime(summaryData.SteamingTime)}</th>
                    <th>
                        {typeof summaryData.Distance === 'number'
                            ? `${formatNumber(summaryData.Distance, 1, isDifference)} ${DISTANCE}`
                            : '-'
                        }
                    </th>
                    <th>-</th>
                    {this.props.showConsumption && (
                        <th>{this.renderConsumption(summaryData, isDifference)}</th>
                    )}
                    <th>{this.getTime(summaryData.WaitingTime)}</th>
                    <th>{this.getTime(summaryData.TimeInPort)}</th>
                    <th colSpan="2">
                        <span className="text-secondary">
                            {`${t('DISTANCE_CALCULATOR.SUMMARY.ETA')} ${this.props.lastPointName
                                ? `${this.props.lastPointName}: `
                                : '-'}`
                            }
                        </span>
                        {this.props.lastPointName ? lastPointETA : ''}
                    </th>
                </tr>
                {!this.props.isCollapsed && (
                    <tr>
                        <td colSpan="3">
                            {this.props.data?.BunkerROBDate && (
                                <h4 className="sten-calculator-summary__bunker-rob-title">
                                    {`${t('DISTANCE_CALCULATOR.SUMMARY.BUNKER_ROB')} - ${TimeHelper.getFormatted(
                                        this.props.data.BunkerROBDate, { utc: true }
                                    )}`}
                                </h4>
                            )}
                            <div className="flex">
                                <div className="flex-grow">
                                    {this.props.data?.BunkerROB && (
                                        <div className="sten-calculator-summary__bunker-rob row">
                                            {this.props.data.BunkerROB.map((fuelType) => (
                                                <div key={fuelType.Id} className="sten-calculator-summary__row col-12">
                                                    <span className="text-secondary">{`${fuelType.Name}: `}</span>
                                                    {`${formatNumber(fuelType.Quantity, 0)} ${t('UNITS.ROB')}`}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div className="flex-shrink">
                                    <div className="sten-calculator-summary__row text-right text-secondary">
                                        {`${t('DISTANCE_CALCULATOR.SUMMARY.ECA')}:`}
                                    </div>
                                    <div className="sten-calculator-summary__row text-right text-secondary">
                                        {`${t('DISTANCE_CALCULATOR.SUMMARY.NON_ECA')}:`}
                                    </div>
                                    <div className="sten-calculator-summary__row text-right text-secondary">
                                        {`${t('DISTANCE_CALCULATOR.SUMMARY.AVERAGE_PER_DAY')}:`}
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div className="sten-calculator-summary__row">-</div>
                            <div className="sten-calculator-summary__row">-</div>
                            <div className="sten-calculator-summary__row">-</div>
                        </td>
                        <td>
                            {this.renderRow(summaryData.SecaDistance, isDifference)}
                            {this.renderRow(summaryData.NonSecaDistance, isDifference)}
                            {this.renderRow(summaryData.AverageDistance, isDifference)}
                        </td>
                        <td>
                            <div className="sten-calculator-summary__row">-</div>
                            <div className="sten-calculator-summary__row">-</div>
                            <div className="sten-calculator-summary__row">-</div>
                        </td>
                        {this.props.showConsumption && (
                            <td>
                                {this.renderRow(summaryData.ConsumptionLsgo, isDifference, CONSUMPTION)}
                                {this.renderRow(summaryData.ConsumptionHsfo, isDifference, CONSUMPTION)}
                                {this.renderRow(summaryData.AverageConsumption, isDifference, CONSUMPTION)}
                            </td>
                        )}
                        <td>
                            <div className="sten-calculator-summary__row">-</div>
                            <div className="sten-calculator-summary__row">-</div>
                            <div className="sten-calculator-summary__row">-</div>
                        </td>
                        <td>
                            <div className="sten-calculator-summary__row">-</div>
                            <div className="sten-calculator-summary__row">-</div>
                            <div className="sten-calculator-summary__row">-</div>
                        </td>
                        <td colSpan="2">
                            <div className="flex flex-end">
                                <div className="flex-grow">
                                    <div className="sten-calculator-summary__row">
                                        <span className="text-secondary">
                                            {`${t('DISTANCE_CALCULATOR.SUMMARY.TOTAL_VOYAGE_TIME')}: `}
                                        </span>
                                        {this.getTime(summaryData.Time)}
                                    </div>
                                    {ConfigService.featureToggles.showFootprint
                                        && !ConfigService.hiddenFeatures.distanceCalculatorCiiRating
                                        && (
                                            <React.Fragment>
                                                <div className="sten-calculator-summary__row">
                                                    <span className="text-secondary">
                                                        {`${t('DISTANCE_CALCULATOR.SUMMARY.YTD_CII_GRADE')}: `}
                                                    </span>
                                                    <CiiRating
                                                        isEstimated
                                                        value={summaryData?.YtdCarbonIntensityIndicatorGrade}
                                                    />
                                                    <span
                                                        className="icon sten-icon-info-tooltip"
                                                        title={summaryData?.YtdCarbonIntensityIndicatorGrade
                                                            ? t('DISTANCE_CALCULATOR.SUMMARY.YTD_CII_GRADE_TOOLTIP')
                                                            : VALUE_CANT_BE_CALCULATED_TOOLTIP}
                                                    />
                                                </div>
                                                <div className="sten-calculator-summary__row">
                                                    <span className="text-secondary">
                                                        {`${t('DISTANCE_CALCULATOR.SUMMARY.VOYAGE_CII_GRADE')}: `}
                                                    </span>
                                                    <CiiRating
                                                        isEstimated
                                                        value={summaryData?.VoyageCarbonIntensityIndicatorGrade}
                                                    />
                                                    <span
                                                        className="icon sten-icon-info-tooltip"
                                                        title={summaryData?.VoyageCarbonIntensityIndicatorGrade
                                                            ? t('DISTANCE_CALCULATOR.SUMMARY.VOYAGE_CII_GRADE_TOOLTIP')
                                                            : VALUE_CANT_BE_CALCULATED_TOOLTIP}
                                                    />
                                                </div>
                                                <div className="sten-calculator-summary__row">
                                                    <span className="text-secondary">
                                                        {`${t('DISTANCE_CALCULATOR.SUMMARY.PREDICTED_CII_GRADE')}: `}
                                                    </span>
                                                    <CiiRating
                                                        isEstimated
                                                        value={summaryData?.TotalCarbonIntensityIndicatorGrade}
                                                    />
                                                    <span
                                                        className="icon sten-icon-info-tooltip"
                                                        title={summaryData?.TotalCarbonIntensityIndicatorGrade
                                                            ? t('DISTANCE_CALCULATOR.SUMMARY.PREDICTED_CII_GRADE_TOOLTIP') // eslint-disable-line
                                                            : VALUE_CANT_BE_CALCULATED_TOOLTIP}
                                                    />
                                                </div>
                                            </React.Fragment>
                                        )}
                                </div>
                                {ConfigService.featureToggles.showFootprint
                                    && !ConfigService.hiddenFeatures.distanceCalculatorCiiRating
                                    && (
                                        <div className="flex-grow">
                                            <div className="sten-calculator-summary__row">
                                                <span className="text-secondary">
                                                    {`${t('DISTANCE_CALCULATOR.SUMMARY.YTD_AER')}: `}
                                                </span>
                                                {this.renderAerValue(summaryData?.YtdAer)}
                                                <span
                                                    className="icon sten-icon-info-tooltip"
                                                    title={summaryData?.YtdAer
                                                        ? t('DISTANCE_CALCULATOR.SUMMARY.YTD_AER_TOOLTIP')
                                                        : VALUE_CANT_BE_CALCULATED_TOOLTIP}
                                                />
                                            </div>
                                            <div className="sten-calculator-summary__row">
                                                <span className="text-secondary">
                                                    {`${t('DISTANCE_CALCULATOR.SUMMARY.VOYAGE_AER')}: `}
                                                </span>
                                                {this.renderAerValue(summaryData?.VoyageAer)}
                                                <span
                                                    className="icon sten-icon-info-tooltip"
                                                    title={summaryData?.VoyageAer
                                                        ? t('DISTANCE_CALCULATOR.SUMMARY.VOYAGE_AER_TOOLTIP')
                                                        : VALUE_CANT_BE_CALCULATED_TOOLTIP}
                                                />
                                            </div>
                                            <div className="sten-calculator-summary__row">
                                                <span className="text-secondary">
                                                    {`${t('DISTANCE_CALCULATOR.SUMMARY.PREDICTED_AER')}: `}
                                                </span>
                                                {this.renderAerValue(summaryData?.TotalAer)}
                                                <span
                                                    className="icon sten-icon-info-tooltip"
                                                    title={summaryData?.TotalAer
                                                        ? t('DISTANCE_CALCULATOR.SUMMARY.PREDICTED_AER_TOOLTIP')
                                                        : VALUE_CANT_BE_CALCULATED_TOOLTIP}
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </td>
                    </tr>
                )}
            </tfoot>
        );
    }
}

CalculatorSummary.propTypes = {
    data: PropTypes.objectOf(PropTypes.any),
    isCollapsed: PropTypes.bool,
    isDifference: PropTypes.bool,
    lastPointName: PropTypes.string,
    lastPointETA: PropTypes.string,
    showConsumption: PropTypes.bool,
    toggleCollapsed: PropTypes.func
};

CalculatorSummary.defaultProps = {
    data: null,
    isCollapsed: false,
    isDifference: false,
    lastPointName: '',
    lastPointETA: '',
    showConsumption: false,
    toggleCollapsed: undefined
};

export default CalculatorSummary;
