export const convertAndDownloadFile = (response, file) => {
    const fileName = file.name;
    const blob = new Blob([response.data], { type: file.type });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, `${fileName}`);
    } else {
        const a = document.createElement('a');
        a.style.cssText = 'display: none';
        document.body.appendChild(a);
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `${fileName}`;
        a.click();
        window.URL.revokeObjectURL(url);
    }
};
