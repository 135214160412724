import { generateMiddlePoints, splitRoute180 } from 'components/ol/ol-helpers';
import { getObjectProp } from './info-helper';
import ConfigService from 'services/config-api/config-service';

class RouteHelper {
    extractRoutePoints(routeResponse) {
        let routePoints = null;
        let route = null;
        if (routeResponse && routeResponse.Sections && routeResponse.Sections.length) {
            routePoints = [];
            const voyageRouteSections = routeResponse.Sections.map((section) => {
                const { Waypoints, ...newSection } = section;
                routePoints = routePoints.concat(Waypoints);
                return newSection;
            });
            route = { ...routeResponse, Sections: voyageRouteSections };
        }
        return { route, routePoints };
    }

    extractRoutesFromAisPoints(
        aisPoints,
        longitudeKey = 'Location.Longitude',
        latitudeKey = 'Location.Latitude',
        positionTimeKey = 'PositionTime',
        isLadenKey = 'IsLaden'
    ) {
        const routes = {
            ballast: [],
            laden: [],
            ballastApprox: [],
            ladenApprox: []
        };
        const maxTimeDiff = ConfigService.featureToggles.aisMaxTimeDiff * 60 * 60 * 1000;
        let currentBallastRoute = [];
        let currentLadenRoute = [];
        let completeBallastRoute = false;
        let completeLadenRoute = false;
        let prev = null;
        let prevPositionTime;
        if (aisPoints && aisPoints.length) {
            aisPoints.forEach((aisPoint, index) => {
                const positionTime = new Date(getObjectProp(aisPoint, positionTimeKey)).getTime();
                if (prevPositionTime && Math.abs(positionTime - prevPositionTime) > maxTimeDiff) {
                    if (currentLadenRoute.length > 0) {
                        routes.laden = routes.laden.concat(splitRoute180(currentLadenRoute));
                        currentLadenRoute = [
                            [getObjectProp(prev, longitudeKey), getObjectProp(prev, latitudeKey)],
                            [getObjectProp(aisPoint, longitudeKey), getObjectProp(aisPoint, latitudeKey)]
                        ];
                        currentLadenRoute = splitRoute180(currentLadenRoute).map(route =>
                            generateMiddlePoints(route));
                        routes.ladenApprox = routes.ladenApprox.concat(currentLadenRoute);
                        currentLadenRoute = [];
                    }
                    if (currentBallastRoute.length > 0) {
                        routes.ballast = routes.ballast.concat(splitRoute180(currentBallastRoute));
                        currentBallastRoute = [
                            [getObjectProp(prev, longitudeKey), getObjectProp(prev, latitudeKey)],
                            [getObjectProp(aisPoint, longitudeKey), getObjectProp(aisPoint, latitudeKey)]
                        ];
                        currentBallastRoute = splitRoute180(currentBallastRoute).map(route =>
                            generateMiddlePoints(route));
                        routes.ballastApprox = routes.ballastApprox.concat(currentBallastRoute);
                        currentBallastRoute = [];
                    }
                }
                if (getObjectProp(aisPoint, isLadenKey) === true || getObjectProp(aisPoint, isLadenKey) === null) {
                    if (index === aisPoints.length - 1) {
                        completeLadenRoute = true;
                    } else {
                        currentLadenRoute.push(
                            [getObjectProp(aisPoint, longitudeKey), getObjectProp(aisPoint, latitudeKey)]
                        );
                    }
                    if ((prev && getObjectProp(prev, isLadenKey) === false)) {
                        completeBallastRoute = true;
                    }
                } else if (getObjectProp(aisPoint, isLadenKey) === false) {
                    if (index === aisPoints.length - 1) {
                        completeBallastRoute = true;
                    } else {
                        currentBallastRoute.push(
                            [getObjectProp(aisPoint, longitudeKey), getObjectProp(aisPoint, latitudeKey)]
                        );
                    }
                    if (prev && (getObjectProp(prev, isLadenKey) === true
                        || getObjectProp(prev, isLadenKey) === null)) {
                        completeLadenRoute = true;
                    }
                }
                if (completeLadenRoute) {
                    completeLadenRoute = false;
                    currentLadenRoute.push(
                        [getObjectProp(aisPoint, longitudeKey), getObjectProp(aisPoint, latitudeKey)]
                    );
                    if (currentLadenRoute.length > 1) {
                        routes.laden = routes.laden.concat(splitRoute180(currentLadenRoute));
                    }
                    currentLadenRoute = [];
                }
                if (completeBallastRoute) {
                    completeBallastRoute = false;
                    currentBallastRoute.push(
                        [getObjectProp(aisPoint, longitudeKey), getObjectProp(aisPoint, latitudeKey)]
                    );
                    if (currentBallastRoute.length > 1) {
                        routes.ballast = routes.ballast.concat(splitRoute180(currentBallastRoute));
                    }
                    currentBallastRoute = [];
                }
                prev = aisPoint;
                prevPositionTime = positionTime;
            });
        }
        return routes;
    }
}

export default new RouteHelper();
