import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
import AutoSizer from 'react-virtualized-auto-sizer';
/* actions */
import { getPortsAndCountries, addPorts } from '../port-calls-analytics-actions';
/* components */
import FixedSizeList from 'components/fixed-size-list/fixed-size-list';
import Input from 'components/input/input';
import Select from 'components/select/select';
import TextHighlight from 'components/text-highlight/text-highlight';
import CheckableListItem from 'components/checkable-list-item/checkable-list-item';
import { height as portRowHeight } from 'components/checkable-list-item/constants';
import SelectAllItem from 'components/checkable-list-item/select-all-item';
import EmptyContent from 'components/empty-content/empty-content';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { upperCaseMatch, formatCoordinates } from 'utils/helpers/info-helper';
import { wktParse } from 'components/ol/ol-helpers';
import getFontSize from 'utils/helpers/cached-font-size';
/* constants */
import { noCountryId } from '../constants';
/* styles */
import './ports-sidebar.scss';
/* selectors */
import { getAddedPortsById } from '../port-calls-analytics-selectors';

const t = (key) => translate(`PORT_CALLS_ANALYTICS.${key}`);

class PortsSidebar extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            selectedPortsById: this.props.initialSelectedPortsById,
            searchCriteria: '',
            fontSize: getFontSize()
        };
    }

    handleResize = () => {
        if (this.state.fontSize !== getFontSize()) {
            this.setState({
                fontSize: getFontSize()
            });
        }
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        if (this.props.ports.length === 0 && this.props.countries.length === 0) {
            this.props.getPortsAndCountries();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    resetFilters = () => this.setState({
        selectedPortsById: {},
        searchCriteria: ''
    });

    onPortSelect = (portId) => {
        this.setState({
            selectedPortsById: {
                ...this.state.selectedPortsById,
                [portId]: !(this.state.selectedPortsById[portId] === true)
            }
        });
    };

    getSelectedPortsCountByCountryId = memoize((selectedPortsById, ports) => {
        const selectedPortsCountByCountryId = {};
        let countryId;
        ports.forEach((port) => {
            if (selectedPortsById[port.Id] === true) {
                countryId = port.Country ? port.Country.CountryId : noCountryId;
                if (typeof selectedPortsCountByCountryId[countryId] === 'undefined') {
                    selectedPortsCountByCountryId[countryId] = 0;
                }
                selectedPortsCountByCountryId[countryId]++;
            }
        });
        return selectedPortsCountByCountryId;
    });

    getTotalSelectedPortsCount = memoize((selectedPortsById) => {
        let count = 0;
        for (const id in selectedPortsById) {
            if (selectedPortsById[id] === true) {
                count++;
            }
        }
        return count;
    });

    getSelectedCountriesMemoized = memoize((countries, portsCountByCountryId, selectedPortsCountByCountryId) => {
        let countryId;
        return countries.filter((country) => {
            countryId = country.CountryId;
            return portsCountByCountryId[countryId] === selectedPortsCountByCountryId[countryId];
        });
    });

    getSelectedCountries = () => {
        const selectedPortsCountByCountryId = this.getSelectedPortsCountByCountryId(
            this.state.selectedPortsById,
            this.props.ports
        );
        const { portsCountByCountryId } = this.props;
        return this.getSelectedCountriesMemoized(
            this.props.countries,
            portsCountByCountryId,
            selectedPortsCountByCountryId
        );
    };

    cachedOnPortSelects = {};

    getOnPortSelect = (port) => {
        const portId = port.Id;
        if (typeof this.cachedOnPortSelects[portId] === 'undefined') {
            this.cachedOnPortSelects[portId] = this.onPortSelect.bind(this, portId);
        }

        return this.cachedOnPortSelects[portId];
    };

    formatLocation = (point) => {
        const coords = wktParse(point);
        return formatCoordinates(coords[0], coords[1]);
    };

    PortRowComponent = ({ data, index, style }) => {
        if (index === 0) {
            return this.renderSelectAllOption(style);
        }

        const port = data.ports[index - 1];
        const portSubtitle = port.Country ? port.Country.CountryName : this.formatLocation(port.Location);
        return (
            <div style={style}>
                <CheckableListItem
                    item={port}
                    title={port.Name}
                    subtitle={portSubtitle}
                    isChecked={data.state.selectedPortsById[port.Id] === true}
                    searchCriteria={data.state.searchCriteria}
                    onClick={this.getOnPortSelect(port)}
                />
            </div>
        );
    };

    getPortId = (index) => {
        if (index === 0) return -1; // select all option
        const ports = this.getVisiblePorts(this.props.ports, this.state.searchCriteria);
        return ports[index - 1].Id;
    };

    createItemData = memoize((ports, state) => ({
        ports,
        state
    }));

    handleSearchCriteriaChange = (searchCriteria) => this.setState({ searchCriteria });

    getVisiblePorts = memoize((ports, searchCriteria) => {
        if (searchCriteria.length === 0) return ports;
        const sc = searchCriteria.toUpperCase();
        return ports.filter((port) =>
            upperCaseMatch(port.Name, sc) || (port.Country && upperCaseMatch(port.Country.CountryName, sc)));
    });

    onCountrySelect = (country) => this.setCountrySelection(country, true);

    onCountryDeselect = (country) => this.setCountrySelection(country, false);

    setCountrySelection = (country, value) => {
        const { selectedPortsById } = this.state;
        const countryId = country.CountryId;
        const newSelectedPortsById = {};
        this.props.ports.forEach((port) => {
            newSelectedPortsById[port.Id] = port.Country && port.Country.CountryId === countryId
                ? value
                : selectedPortsById[port.Id];
        });
        this.setState({ selectedPortsById: newSelectedPortsById });
    };

    renderSelectAllOption = (style) => {
        return (
            <div style={style}>
                <SelectAllItem
                    isChecked={this.areAllVisiblePortsSelected()}
                    onClick={this.toggleAllVisiblePortsSelection}
                />
            </div>
        );
    };

    areAllVisiblePortsSelected = () => {
        const visiblePorts = this.getVisiblePorts(this.props.ports, this.state.searchCriteria);
        const { selectedPortsById } = this.state;
        return visiblePorts.every((port) => selectedPortsById[port.Id] === true);
    };

    toggleAllVisiblePortsSelection = (e) => {
        e.preventDefault();
        const value = !this.areAllVisiblePortsSelected();
        this.setPortsSelection(
            this.getVisiblePorts(this.props.ports, this.state.searchCriteria),
            value
        );
    };

    setPortsSelection = (ports, value) => {
        const newSelectedPortsById = { ...this.state.selectedPortsById };
        ports.forEach((port) => {
            newSelectedPortsById[port.Id] = value;
        });
        this.setState({ selectedPortsById: newSelectedPortsById });
    };

    renderCountryOption = (country, searchCriteria) => {
        const count = this.props.portsCountByCountryId[country.CountryId] || 0;
        const title = `${country.CountryName} (${count})`;
        return (
            <TextHighlight input={title} highlight={searchCriteria} />
        );
    };

    autoSizerStyle = { width: '100%', height: '100%' };

    renderNoPorts = memoize(() => (
        <div className="spread-v">
            <EmptyContent>{t('NO_PORTS')}</EmptyContent>
        </div>
    ));

    addPorts = () => {
        const { selectedPortsById } = this.state;
        const selectedPorts = this.props.ports.filter(
            (port) => selectedPortsById[port.Id] === true
        );
        this.props.addPorts(selectedPorts);
        this.props.onClose();
    };

    render() {
        const visiblePorts = this.getVisiblePorts(this.props.ports, this.state.searchCriteria);
        const selectedPortsCount = this.getTotalSelectedPortsCount(this.state.selectedPortsById);
        const subtitle = `${selectedPortsCount} ${t('SELECTED')}`;

        return (
            <div className="sten-content sten-content--has-footer">
                <div className="sten-content__header flex-row">
                    <div className="flex-grow">
                        <h1 className="sten-content__title">
                            {t('ADD_PORTS')}
                        </h1>
                        <p className="sten-content__subtitle">{subtitle}</p>
                    </div>
                    <div className="flex-shrink">
                        <button
                            type="button"
                            className="btn-link icon icon-close"
                            onClick={this.props.onClose}
                        />
                    </div>
                </div>
                <div className="sten-content__body">
                    <div className="sten-content__section">
                        <div className="form-row">
                            <label className="label">{t('COUNTRIES')}</label>
                            <Select
                                autoFocus
                                name="selectedCountries"
                                searchable
                                multiple
                                valueKey="CountryId"
                                labelKey="CountryName"
                                options={this.props.countries}
                                optionRenderer={this.renderCountryOption}
                                placeholder={t('SELECT_COUNTRIES')}
                                value={this.getSelectedCountries()}
                                onItemSelect={this.onCountrySelect}
                                onItemDeselect={this.onCountryDeselect}
                            />
                        </div>
                        <div className="form-row">
                            <label className="label">{t('PORTS')}</label>
                            <Input
                                clearable
                                value={this.state.searchCriteria}
                                onChange={this.handleSearchCriteriaChange}
                                placeholder={t('SEARCH_PORTS_PLACEHOLDER')}
                                title={t('SEARCH_PORT_TITLE')}
                            />
                        </div>
                    </div>
                    <div className="sten-port-calls-analytics-sidebar__ports-list sten-content__separator">
                        {visiblePorts.length > 0 ? (
                            <AutoSizer style={this.autoSizerStyle}>
                                {({ height, width }) => (
                                    <FixedSizeList
                                        itemData={this.createItemData(visiblePorts, this.state)}
                                        totalHeight={height}
                                        totalWidth={width}
                                        itemCount={visiblePorts.length + 1}
                                        itemSize={this.state.fontSize * portRowHeight}
                                        itemKey={this.getPortId}
                                        ItemComponent={this.PortRowComponent}
                                    />
                                )}
                            </AutoSizer>
                        ) : this.renderNoPorts()}
                    </div>
                </div>
                <footer className="sten-content__footer flex-row">
                    <div className="col-12">
                        <button
                            onClick={this.resetFilters}
                            className="btn btn--secondary col-24"
                            type="button"
                        >
                            {t('CLEAR_ALL')}
                        </button>
                    </div>
                    <div className="col-12">
                        <button
                            onClick={this.addPorts}
                            className="btn btn--primary col-24"
                        >
                            {t('CONFIRM')}
                        </button>
                    </div>
                </footer>
            </div>
        );
    }
}

PortsSidebar.propTypes = {
    getPortsAndCountries: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    addPorts: PropTypes.func.isRequired,
    ports: PropTypes.arrayOf(PropTypes.object).isRequired,
    countries: PropTypes.arrayOf(PropTypes.object).isRequired,
    portsCountByCountryId: PropTypes.objectOf(PropTypes.any).isRequired,
    initialSelectedPortsById: PropTypes.objectOf(PropTypes.bool).isRequired
};

function mapStateToProps(state) {
    return {
        countries: state.portCallsAnalyticsReducer.countries,
        portsCountByCountryId: state.portCallsAnalyticsReducer.portsCountByCountryId,
        ports: state.portCallsAnalyticsReducer.ports,
        initialSelectedPortsById: getAddedPortsById(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getPortsAndCountries: () => getPortsAndCountries(dispatch),
        addPorts: addPorts.bind(null, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PortsSidebar);
