import React from 'react';
import PropTypes from 'prop-types';
/* helpers */
import { translate } from 'utils/i18n/i18n-model';
import { convertDateToObj, convertObjToDate, isValidDate } from 'components/htec-date-picker/helpers';
import TimeHelper from 'utils/helpers/time-helper';
/* components */
import Popover from 'components/popover/popover';
import { DateTimePicker, DatePicker, DateInput, DateInputButton } from 'components/htec-date-picker';
/* styles */
import './date-time-popup.scss';

export default class DateTimePopup extends React.PureComponent {
    state = {
        isPopoverVisible: false,
        prevValue: null,
        value: null,
        minDate: null,
        maxDate: null
    };

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.prevValue) {
            if (props.value !== props.invalidDate) {
                if (props.dateType !== 'object') {
                    return {
                        prevValue: props.value,
                        value: convertDateToObj(props.value, props.isUTC, props.withTime),
                        minDate: props.minDate ? convertDateToObj(props.minDate, props.isUTC, props.withTime) : null,
                        maxDate: props.maxDate ? convertDateToObj(props.maxDate, props.isUTC, props.withTime) : null
                    };
                }
                return {
                    prevValue: props.value,
                    value: props.value,
                    minDate: props.minDate,
                    maxDate: props.maxDate
                };
            }
            return {
                prevValue: props.value
            };
        }
        return null;
    }

    nextBtn = <span className="icon icon-chevron-right sten-date-time-popup__icon" />;

    prevBtn = <span className="icon icon-chevron-left sten-date-time-popup__icon" />;

    increaseBtn = <span className="icon icon-chevron-up sten-date-time-popup__time-icon" />;

    decreaseBtn = <span className="icon icon-chevron-down sten-date-time-popup__time-icon" />;

    getContent = () => {
        const { value, minDate, maxDate } = this.state;
        let contentClassName = 'sten-date-time-popup__content';
        if (this.props.contentClassName) {
            contentClassName += ` ${this.props.contentClassName}`;
        }
        // const selectedDate = isValidDate(value) ? value : null;
        return (
            <div className={contentClassName}>
                {this.props.withTime
                    ? (
                        <DateTimePicker
                            alwaysShow6Weeks={this.props.alwaysShow6Weeks}
                            disableMonthsOutOfRange={this.props.disableMonthsOutOfRange}
                            hideOutsideDays={this.props.hideOutsideDays}
                            initialMonth={this.props.initialMonth}
                            increaseBtn={this.increaseBtn}
                            decreaseBtn={this.decreaseBtn}
                            maxDateTime={maxDate}
                            minDateTime={minDate}
                            nextBtn={this.nextBtn}
                            prevBtn={this.prevBtn}
                            onDateTimeChange={this.handleDateSelect}
                            renderDay={this.props.renderDay}
                            renderMonth={this.props.renderMonth}
                            renderYear={this.props.renderYear}
                            renderWeekDays={this.props.renderWeekDays}
                            selectedDateTime={value}
                            showTimeUnits={this.props.showTimeUnits}
                            showTimeTitles={this.props.showTimeTitles}
                            timeConfig={this.props.timeConfig}
                            timeFormat={this.props.timeFormat}
                            today={this.props.today}
                            weekStart={this.props.weekStart}
                        />
                    ) : (
                        <DatePicker
                            alwaysShow6Weeks={this.props.alwaysShow6Weeks}
                            disableMonthsOutOfRange={this.props.disableMonthsOutOfRange}
                            hideOutsideDays={this.props.hideOutsideDays}
                            initialMonth={this.props.initialMonth}
                            maxDate={maxDate}
                            minDate={minDate}
                            nextBtn={this.nextBtn}
                            prevBtn={this.prevBtn}
                            onDateSelect={this.handleDateSelect}
                            renderDay={this.props.renderDay}
                            renderMonth={this.props.renderMonth}
                            renderYear={this.props.renderYear}
                            renderWeekDays={this.props.renderWeekDays}
                            selectedDate={isValidDate(value) ? value : null}
                            today={this.props.today}
                            weekStart={this.props.weekStart}
                        />
                    )
                }
                {this.props.children}
            </div>
        );
    };

    handleInputClick = (e) => {
        e.stopPropagation();
        if (!this.state.isPopoverVisible) {
            this.setState({ isPopoverVisible: true });
        }
    };

    handleIconClick = (e) => {
        e.stopPropagation();
        this.setState({ isPopoverVisible: !this.state.isPopoverVisible });
    };

    handleClickOutside = (e) => {
        if (!this.dateInputRef || !this.dateInputRef.contains(e.target)) {
            e.stopPropagation();
            this.setState({ isPopoverVisible: false });
        }
    };

    handleDateSelect = (date) => {
        if (!this.props.withTime && this.props.hideOnDateSelect) {
            this.setState({ isPopoverVisible: false });
            this.handleDateChange(date);
        } else {
            this.handleDateChange(date);
        }
    };

    handleDateChange = (date) => {
        if (this.props.dateType !== 'object') {
            if (date && (date.isDateInvalid || date.isTimeInvalid)) {
                this.setState({ value: date });
            }
            this.props.convertBeforeSelect(date, this.props);
        } else {
            this.props.onDateSelect(date);
        }
    };

    handlePopoverShow = (popover) => {
        document.addEventListener('scrollChanged', popover.handlePositionUpdate);
    };

    handlePopoverHide = (popover) => {
        document.removeEventListener('scrollChanged', popover.handlePositionUpdate);
    };

    formatDate = (dateObj) => {
        if (!dateObj) {
            return null;
        }
        let date = dateObj;
        if (this.props.dateType === 'object') {
            date = convertObjToDate(dateObj, this.props.isUTC);
        }
        return TimeHelper.getFormatted(date, { time: this.props.withTime, utc: this.props.isUTC });
    };

    saveInputRef = (c) => { this.dateInputRef = c; };

    autoPlacementSize = {
        width: 314,
        height: 390
    };

    render() {
        const { value, minDate, maxDate } = this.state;
        return (
            <Popover
                contentClassName={this.props.popoverClassName}
                content={this.state.isPopoverVisible && this.getContent(value, minDate, maxDate)}
                placement={this.props.placement}
                alignment={this.props.alignment}
                autoPlacement={this.props.autoPlacement}
                autoPlacementSize={this.autoPlacementSize}
                isVisible={this.state.isPopoverVisible}
                onClickOutside={this.handleClickOutside}
                onShow={this.handlePopoverShow}
                onHide={this.handlePopoverHide}
                onUnmount={this.handlePopoverHide}
            >
                {this.props.isReadOnly ? (
                    <DateInputButton
                        className={this.props.inputClassName}
                        date={this.formatDate(this.props.value)}
                        placeholder={this.props.placeholder}
                        onClick={this.togglePopover}
                        isActive={this.state.isPopoverVisible}
                        isDisabled={this.props.disabled}
                        isInvalid={this.props.invalid}
                        icon={<span className="icon icon-calendar sten-date-time-popup__icon" />}
                    />
                ) : (
                    <DateInput
                        className={this.props.inputClassName}
                        clearIcon={<span className="icon icon-close sten-date-time-popup__icon" />}
                        date={value}
                        dateFormat={this.props.dateFormat}
                        isActive={this.state.isPopoverVisible}
                        isClearable={this.props.isClearable}
                        isDisabled={this.props.disabled}
                        isInvalid={this.props.invalid}
                        maxDate={maxDate}
                        minDate={minDate}
                        onClick={this.handleInputClick}
                        onDateChange={this.handleDateChange}
                        onIconClick={this.handleIconClick}
                        placeholder={this.props.placeholder}
                        icon={<span className="icon icon-calendar sten-date-time-popup__icon" />}
                        saveRef={this.saveInputRef}
                        timeConfig={this.props.timeConfig}
                        withTime={this.props.withTime}
                    />
                )}
            </Popover>
        );
    }
}

const DateShape = {
    day: PropTypes.number,
    month: PropTypes.number,
    year: PropTypes.number
};

const DateTimeShape = {
    second: PropTypes.number,
    minute: PropTypes.number,
    hour: PropTypes.number,
    day: PropTypes.number,
    month: PropTypes.number,
    year: PropTypes.number
};

const ConfigFieldShape = {
    hidden: PropTypes.bool,
    disabled: PropTypes.bool
};

const DatePropType = PropTypes.oneOfType([
    PropTypes.shape(DateShape),
    PropTypes.shape(DateTimeShape),
    PropTypes.object,
    PropTypes.number,
    PropTypes.string
]);

DateTimePopup.propTypes = {
    alignment: PropTypes.oneOf(['start', 'center', 'end']),
    alwaysShow6Weeks: PropTypes.bool,
    autoPlacement: PropTypes.bool,
    children: PropTypes.node,
    contentClassName: PropTypes.string,
    convertBeforeSelect: PropTypes.func,
    dateFormat: PropTypes.string,
    dateTimePlaceholder: PropTypes.string,
    dateType: PropTypes.oneOf(['object', 'date', 'string']),
    disableMonthsOutOfRange: PropTypes.bool,
    disabled: PropTypes.bool,
    hideOnDateSelect: PropTypes.bool,
    hideOutsideDays: PropTypes.bool,
    initialMonth: PropTypes.shape({ month: PropTypes.number, year: PropTypes.number }),
    inputClassName: PropTypes.string,
    invalid: PropTypes.bool,
    invalidDate: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isClearable: PropTypes.bool,
    isReadOnly: PropTypes.bool,
    isStart: PropTypes.bool,
    isUTC: PropTypes.bool,
    maxDate: DatePropType,
    minDate: DatePropType,
    onDateSelect: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    popoverClassName: PropTypes.string,
    renderDay: PropTypes.func,
    renderMonth: PropTypes.func,
    renderWeekDays: PropTypes.func,
    renderYear: PropTypes.func,
    showTimeTitles: PropTypes.bool,
    showTimeUnits: PropTypes.bool,
    timeConfig: PropTypes.shape({
        hour: PropTypes.shape(ConfigFieldShape),
        minute: PropTypes.shape(ConfigFieldShape),
        second: PropTypes.shape(ConfigFieldShape)
    }),
    timeFormat: PropTypes.oneOf(['24-hour', '12-hour']),
    today: PropTypes.shape(DateShape),
    value: DatePropType,
    weekStart: PropTypes.oneOf(['Monday', 'Sunday']),
    withTime: PropTypes.bool
};

DateTimePopup.defaultProps = {
    alignment: 'start',
    alwaysShow6Weeks: undefined,
    autoPlacement: false,
    children: null,
    contentClassName: '',
    convertBeforeSelect: (date, props) => {
        if (date && (date.isDateInvalid || date.isTimeInvalid)) {
            props.onDateSelect(props.invalidDate);
        } else {
            props.onDateSelect(convertObjToDate(date, props.isUTC, props.isStart, props.dateType));
        }
    },
    dateTimePlaceholder: translate('DATE_TIME_POPUP.DATE_TIME_PLACEHOLDER'),
    dateFormat: 'DD/MM/YYYY',
    dateType: 'string',
    disabled: false,
    disableMonthsOutOfRange: undefined,
    hideOnDateSelect: true,
    hideOutsideDays: undefined,
    initialMonth: null,
    inputClassName: '',
    invalid: false,
    invalidDate: 'Invalid Date',
    isClearable: false,
    isReadOnly: false,
    isStart: true,
    isUTC: false,
    maxDate: null,
    minDate: null,
    placeholder: translate('DATE_TIME_POPUP.PLACEHOLDER'),
    placement: 'bottom',
    showTimeTitles: true,
    showTimeUnits: false,
    timeConfig: { second: { hidden: true } },
    timeFormat: '24-hour',
    popoverClassName: '',
    renderDay: undefined,
    renderMonth: undefined,
    renderWeekDays: undefined,
    renderYear: undefined,
    today: null,
    value: null,
    weekStart: undefined,
    withTime: true
};
