import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AutoSizer from 'react-virtualized-auto-sizer';
import memoize from 'memoize-one';
/* utils */
import getFontSize from 'utils/helpers/cached-font-size';
import { formatNumber, getClassName } from 'utils/helpers/info-helper';
import { t } from 'utils/i18n/i18n-model';
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import {
    toggleMissingReportReminderModal
} from 'components/vessel-report/send-reminder-modal/send-reminder-modal-actions';
/* components */
import FixedSizeList from 'components/fixed-size-list/fixed-size-list';
import Tooltip from 'components/tooltip/tooltip';
import SendReminderModal from 'components/vessel-report/send-reminder-modal/send-reminder-modal';
/* styles */
import './voyage-timeline.scss';

const reportsIconMap = {
    0: 'icon icon-close sten-voyage-timeline--icon-close',
    1: 'sten-voyage-timeline--daily-report-circle',
    2: 'icon icon-departure-report',
    3: 'icon icon-arrival-report',
    4: 'icon icon-cargo',
    5: 'icon icon-event-report',
    6: 'icon icon-next-leg-report',
    7: 'icon icon-sof-report'
};

const reportsTypeMap = {
    1: t('VOYAGE_TIMELINE.DAILY_REPORT'),
    2: t('VOYAGE_TIMELINE.DEPARTURE_REPORT'),
    3: t('VOYAGE_TIMELINE.ARRIVAL_REPORT'),
    4: t('VOYAGE_TIMELINE.CARGO_REPORT'),
    5: t('VOYAGE_TIMELINE.EVENT_REPORT'),
    6: t('VOYAGE_TIMELINE.NEXT_LEG_REPORT'),
    7: t('VOYAGE_TIMELINE.SOF_REPORT')
};

const voyageTimelinePaddingRight = 15;
const voyageTimelineHeight = 7.9;
const itemSize = 3;

class VoyageTimeline extends React.PureComponent {
    state = {
        activeReportId: null,
        fontSize: getFontSize()
    };

    static getDerivedStateFromProps(props, state) {
        if (props.activeReportId !== state.activeReportId) {
            return {
                ...state,
                activeReportId: props.activeReportId
            };
        }
        return null;
    }

    fixedSizeList = React.createRef();

    scrollAreaToRight = () => {
        if (this.fixedSizeList && this.fixedSizeList.current && this.fixedSizeList.current.scrollAreaRef) {
            this.fixedSizeList.current.scrollAreaRef.current.scrollArea.scrollLeft();
        }
        this.updateScrollArea = window.setTimeout(() => {
            if (this.fixedSizeList.current && this.fixedSizeList.current.scrollAreaRef.current) {
                this.fixedSizeList.current.scrollAreaRef.current.scrollArea.scrollRight();
            }
        }, 300);
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        this.scrollAreaToRight();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.reports !== this.props.reports) {
            this.scrollAreaToRight();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        if (this.updateScrollArea) {
            clearTimeout(this.updateScrollArea);
        }
    }

    handleResize = () => {
        if (this.state.fontSize !== getFontSize()) {
            this.setState({
                fontSize: getFontSize()
            });
        }
    };

    getReportDotClassName = report => getClassName(
        'sten-voyage-timeline__icon',
        reportsIconMap[report.ReportType],
        {
            'sten-voyage-timeline__icon--bg': !report.MissingReportId && report.ReportType !== 1,
            'sten-voyage-timeline--daily-report-circle__ballast': report.ReportType === 1 && report.CargoStatusId === 2,
            'cursor-pointer': this.props.permissions && this.props.permissions.SendMissingReportReminder,
            'sten-voyage-timeline--deleted-report': this.props.deletedReportIds?.[report.ReportId],
            'sten-voyage-timeline--updated-report': this.props.editedReportIds?.[report.ReportId]
        }
    );

    getReportId = index => {
        const report = this.props.reports[index];
        return report.ReportId ? report.ReportId : report.MissingReportId;
    };

    getReportTooltipContent = report => {
        let additionalInfo = '';
        let badge = null;
        if (this.props.deletedReportIds?.[report.ReportId]) {
            badge = <span className="sten-badge sten-badge--danger">{t('VOYAGE_TIMELINE.DELETED')}</span>;
        } else if (this.props.editedReportIds?.[report.ReportId]) {
            badge = <span className="sten-badge sten-badge--warning">{t('VOYAGE_TIMELINE.UPDATED')}</span>;
        } else if (report.MissingReportId) {
            badge = <span className="sten-badge sten-badge--danger">{t('VOYAGE_TIMELINE.MISSING')}</span>;
        }
        if (report.ReportType === 1) {
            additionalInfo = typeof report.AvgSpeed === 'number'
                ? `Avg Speed: ${formatNumber(report.AvgSpeed)} ${t('UNITS.SPEED')}`
                : 'Avg Speed: -';
        } else if (report.PortName) {
            additionalInfo = report.PortName;
        }
        const title = report.ReportSubtype && report.ReportSubtype.Id !== 1
            ? `${reportsTypeMap[report.ReportType]} - ${report.ReportSubtype.DisplayName}`
            : reportsTypeMap[report.ReportType];
        return (
            <React.Fragment>
                {reportsTypeMap[report.ReportType] && <div>{title}</div>}
                <div>{TimeHelper.getFormatted(report.Date, { time: true, utc: true })}</div>
                <div>{badge || additionalInfo}</div>
            </React.Fragment>
        );
    };

    handleReportClick = report => {
        if (!report.MissingReportId) {
            this.props.onReportClick(report.ReportId, report.ReportType);
        } else if (this.props.permissions && this.props.permissions.SendMissingReportReminder) {
            this.props.toggleMissingReportReminderModal(report);
        }
    };

    renderReportItem = ({ data, index, style }) => {
        const report = data.reports[index];
        const isDeleted = this.props.deletedReportIds?.[report.ReportId];
        const shouldRenderText = data.reports[index - 1] && data.reports[index - 1].ReportType !== 3;
        return (
            <div style={style} className="sten-voyage-timeline__icon-container" key={this.getReportId(index)}>
                <Tooltip
                    className="sten-voyage-timeline__tooltip"
                    placement="horizontalAuto"
                    contentClassName="sten-voyage-timeline__tooltip-content"
                    content={this.getReportTooltipContent(report)}
                >
                    <span
                        onClick={isDeleted ? null : this.handleReportClick.bind(this, report)}
                        className={this.getReportDotClassName(report)}
                    />
                </Tooltip>
                {index === data.reports.length - 1 && (
                    <span className="icon sten-icon-laden sten-voyage-timeline__icon--vessel" />
                )}
                {report.ReportType === 3 && shouldRenderText && (
                    <span className="sten-voyage-timeline__text">{report.PortName}</span>
                )}
            </div>
        );
    };

    createItemData = memoize((reports, state) => ({
        reports,
        state
    }));

    autoSizerStyle = { width: '100%' };

    render() {
        if (!this.props.reports) { return null; }
        return (
            <React.Fragment>
                <SendReminderModal />
                <div className="sten-voyage-timeline">
                    <div className="sten-voyage-timeline__line" />
                    <AutoSizer style={this.autoSizerStyle}>
                        {({ width }) => (
                            <FixedSizeList
                                paddingRight={voyageTimelinePaddingRight * this.state.fontSize}
                                ref={this.fixedSizeList}
                                layout="horizontal"
                                itemData={this.createItemData(this.props.reports, this.state)}
                                itemCount={this.props.reports.length}
                                totalWidth={width}
                                totalHeight={(voyageTimelineHeight * this.state.fontSize) - 1}
                                itemSize={itemSize * this.state.fontSize}
                                ItemComponent={this.renderReportItem}
                                useCSS3Translate
                            />
                        )}
                    </AutoSizer>
                </div>
            </React.Fragment>
        );
    }
}

VoyageTimeline.propTypes = {
    activeReportId: PropTypes.number,
    deletedReportIds: PropTypes.objectOf(PropTypes.any),
    editedReportIds: PropTypes.objectOf(PropTypes.any),
    onReminderSend: PropTypes.func,
    onReportClick: PropTypes.func,
    permissions: PropTypes.objectOf(PropTypes.any),
    reports: PropTypes.arrayOf(PropTypes.object),
    toggleMissingReportReminderModal: PropTypes.func.isRequired
};

VoyageTimeline.defaultProps = {
    activeReportId: null,
    deletedReportIds: null,
    editedReportIds: null,
    onReminderSend: undefined,
    onReportClick: undefined,
    permissions: null,
    reports: null
};

function mapStateToProps(state) {
    return {
        deletedReportIds: state.voyagePerformanceReducer.deletedReportIds,
        editedReportIds: state.voyagePerformanceReducer.editedReportIds
    };
}

function mapDispatchToProps(dispatch) {
    return {
        toggleMissingReportReminderModal: missingReport => toggleMissingReportReminderModal(dispatch, missingReport)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VoyageTimeline);
