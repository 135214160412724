import { createSelector } from 'reselect';
import createByIdSelector from 'utils/helpers/selector-helper';

const getActiveNotificationsByIMO = createByIdSelector(
    state => state.notificationsReducer.activeNotifications,
    'IMO'
);

export const getPortActivities = createSelector(
    state => state.portInfoReducer.activities,
    getActiveNotificationsByIMO,

    (activities, activeNotifications) => activities.map(
        portActivity => ({
            ...portActivity,
            HasAlert: !!activeNotifications[portActivity.IMO]
        })
    )
);

export const getRecentComments = createSelector(
    state => state.portInfoReducer.comments,

    (comments) => {
        let recentComments = [];

        if (comments && comments.length) {
            recentComments = comments.slice(0, 3);
        }

        return recentComments;
    }
);

export default getPortActivities;
