import { takeEvery, put, select, all } from 'redux-saga/effects';
/* actions */
import { ActionTypes } from './notification-settings-actions';
import { ActionTypes as NotificationActionTypes } from 'pages/user-pages/notifications/notifications-actions';
/* services */
import FleetService from 'services/core-api/fleet-service';
import NotificationRulesService from 'services/notification-api/notification-rules-service';
import FleetManagementService from 'services/core-api/fleet-management-service';
/* helpers */
import { getCustomFleets as getAndSortCustomFleets } from 'utils/helpers/fleet-helper';

function* getSettings() {
    const settings = yield NotificationRulesService.Settings.get();
    if (settings) {
        yield put({ type: ActionTypes.NOTIFICATION_SETTINGS_SET, settings });
    }
}

function* getFleets() {
    const companyFleets = yield FleetService.get();
    if (companyFleets) {
        yield put({ type: ActionTypes.NOTIFICATION_SETTINGS_SET_FLEETS, companyFleets });
    }
}

function* getCustomFleets() {
    const permissions = yield select(state => state.userReducer.permissions);
    const fleetRequests = [];
    if (permissions.ShowFleetManagementPrivateGroups) {
        fleetRequests.push(FleetManagementService.getPrivate());
    }
    if (permissions.ShowFleetManagementPublicGroups) {
        fleetRequests.push(FleetManagementService.getPublic());
    }
    const vesselFleetsResponses = yield all(fleetRequests);
    const customFleets = getAndSortCustomFleets(vesselFleetsResponses);
    yield put({ type: ActionTypes.NOTIFICATION_SETTINGS_SET_CUSTOM_FLEETS, customFleets });
}

function* updateSetting(action) {
    const updatedSetting = yield NotificationRulesService.Settings.updateById(action.id, {
        CompanyFleetIds: action.data.CompanyFleetIds,
        CustomFleetIds: action.data.CustomFleetIds,
        Enabled: action.data.Enabled,
        PushNotificationsEnabled: action.data.PushNotificationsEnabled
    });
    if (updatedSetting) {
        yield getSettings();
        yield put({ type: NotificationActionTypes.NOTIFICATIONS_GET_ACTIVE });
    }
}

export default function* notificationSettingsSaga() {
    yield takeEvery(ActionTypes.NOTIFICATION_SETTINGS_GET, getSettings);
    yield takeEvery(ActionTypes.NOTIFICATION_SETTINGS_GET_FLEETS, getFleets);
    yield takeEvery(ActionTypes.NOTIFICATION_SETTINGS_GET_CUSTOM_FLEETS, getCustomFleets);
    yield takeEvery(ActionTypes.NOTIFICATION_SETTINGS_UPDATE, updateSetting);
}
