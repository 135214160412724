import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';
/* utils */
import { regexEscape } from 'utils/helpers/regex-helper';
/* styles */
import './text-highlight.scss';

class TextHighlight extends React.PureComponent {
    static generateRegex = memoize(
        (searchCriteria) => new RegExp(`(${regexEscape(searchCriteria)})`, 'i')
    );

    renderTextParts(searchCriteria) {
        const regex = TextHighlight.generateRegex(searchCriteria);
        const textParts = this.props.input.toString().split(regex);

        return textParts.map((part, index) => {
            return regex.test(part)
                ? (
                    <span
                        key={index} // eslint-disable-line react/no-array-index-key
                        className="sten-text-highlight__highlighted"
                    >
                        {part}
                    </span>
                ) : part;
        });
    }

    render() {
        const searchCriteria = this.props.highlight.trim();

        return searchCriteria.length === 0
            ? this.props.input
            : this.renderTextParts(searchCriteria);
    }
}

TextHighlight.propTypes = {
    highlight: PropTypes.string,
    input: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

TextHighlight.defaultProps = {
    highlight: ''
};

export default TextHighlight;
