/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { formatNumber } from 'utils/helpers/info-helper';
/* styles */
import './info-panel.scss';

export default class EnergyManagementKPIInfoPanel extends React.PureComponent {
    getTableHeader = () => (
        <thead>
            <tr>
                {this.props.tableHeader.map((tableHeader, index) => (
                    <th key={index}>{tableHeader}</th>
                ))}
            </tr>
        </thead>
    );

    getTitleSection = (sectionData, index) => (
        <tbody key={index}>
            <tr className="sten-energy-management-kpi-info-panel__title-row">
                <td colSpan="3">
                    {sectionData.title}
                </td>
            </tr>
        </tbody>
    );

    getText = (value, decimals, unit, prefix) => {
        if (value === null) {
            return '';
        }
        if (typeof value === 'string') {
            return value;
        }
        let formattedValue = value;
        if (typeof decimals === 'number') {
            formattedValue = formatNumber(value, decimals);
        }
        if (unit) {
            return `${formattedValue} ${unit}`;
        }
        if (prefix) {
            return `${prefix}${formattedValue}`;
        }
        return formattedValue;
    };

    getTableSection = (sectionData, index) => {
        const { decimals, unit, prefix } = sectionData;
        return (
            <tbody key={index}>
                {sectionData.rows.map((row, rowIndex) => {
                    if (row.length === 1) {
                        return (
                            <tr key={rowIndex}>
                                <td colSpan="3">{this.getText(row[0], decimals, unit, prefix)}</td>
                            </tr>
                        );
                    }
                    return (
                        <tr key={rowIndex}>
                            {row.length === 1
                                ? <td colSpan="3">{this.getText(row[0], decimals, unit, prefix)}</td>
                                : row.map((col, colIndex) => (
                                    <td key={colIndex}>{this.getText(col, decimals, unit, prefix)}</td>
                                ))}
                        </tr>
                    );
                })}
            </tbody>
        );
    };

    render() {
        let className = 'sten-energy-management-kpi-info-panel sten-panel spread-v--md';
        if (this.props.className) {
            className += ` ${this.props.className}`;
        }
        const header = <h4 className="text-uppercase">{this.props.title}</h4>;
        return (
            <div className={className}>
                <div className="sten-panel__header">{header}</div>
                <div className="sten-panel__body">
                    <table className="sten-table sten-table--no-borders">
                        {this.getTableHeader()}
                        {this.props.sections && this.props.sections.length > 0
                            && this.props.sections.map((sectionData, index) => {
                                if (sectionData.title) {
                                    return this.getTitleSection(sectionData, index);
                                }
                                return this.getTableSection(sectionData, index);
                            })
                        }
                    </table>
                </div>
            </div>
        );
    }
}

EnergyManagementKPIInfoPanel.propTypes = {
    className: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.object),
    tableHeader: PropTypes.arrayOf(PropTypes.node),
    title: PropTypes.string.isRequired
};

EnergyManagementKPIInfoPanel.defaultProps = {
    className: '',
    sections: null,
    tableHeader: ['Parameters', '2018', '2017']
};
