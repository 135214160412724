import React from 'react';
import PropTypes from 'prop-types';
import ScrollArea from 'components/scroll-area/scroll-area';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import Modal from 'components/modal/modal';
import Input from 'components/input/input';
import TextArea from 'components/textarea/textarea';
import Select from 'components/select/select';
import MultiStringSelect from 'components/multi-string-select/multi-string-select';
import HtmlPreview from 'components/html-preview/html-preview';
import Validation from 'components/validation/validation';
/* helpers */
import { getEmailTemplate } from '../position-list-compose-email/position-list-compose-email-template';
/* services */
import CompanyService from 'services/core-api/company-service';
/* styles */
import './position-list-compose-modal.scss';

class PositionListComposeModal extends React.PureComponent {
    state = {
        image: null
    };

    componentDidUpdate(prevProps) {
        if ((this.props.selectedSignature !== prevProps.selectedSignature) && !this.state.image) {
            this.setSignatureImage(this.props.selectedSignature.id);
        }
    }

    sendEmail = () => {
        this.props.onSend(
            getEmailTemplate(
                this.props.email.message,
                this.props.groupedVessels,
                this.props.visibleColumns,
                { ...this.props.selectedSignature, image: this.state.image },
                this.props.selectedPort,
                'companyLogo',
                this.props.selectedExportVesselInfoConfig,
                this.props.vesselInfoConfigurableProperties
            ),
            this.state.image
                ? [
                    {
                        ContentName: 'companyLogo.png',
                        ContentType: 'image/png',
                        ContentID: 'companyLogo',
                        Content: this.state.image
                    }
                ]
                : []
        );
    };

    closeAndDiscardForm = () => {
        this.form.resetValidation();
        this.props.onCancel();
    };

    setSignatureImage = (id) => {
        CompanyService.Internal.getLogoByIdAndType(id, { typeId: 0 }, false).then((res) => {
            this.setState({ image: res || null });
        });
    };

    handleSignatureChange = (signature) => {
        this.props.updateUserSettings({ PositionListSignatureCompanyId: signature.id });
        this.setSignatureImage(signature.id);
    };

    render() {
        return (
            <Modal.Default
                className="sten-position-list-compose-modal sten-modal--with-footer"
                modalSize="lg"
                isOpen={this.props.isModalOpen}
                onRequestClose={this.closeAndDiscardForm}
            >
                <Validation.Form
                    className="sten-position-list-compose-modal__form"
                    ref={(c) => { this.form = c; }}
                    onSubmit={this.sendEmail}
                >
                    <div className="sten-modal__header flex flex-center text-uppercase">
                        <div className="sten-modal__title flex-grow">
                            {translate('POSITION_LIST.COMPOSE.MODAL.TITLE')}
                        </div>
                        <div className="flex-shrink">
                            <button
                                type="button"
                                className="btn-link icon icon-close"
                                onClick={this.closeAndDiscardForm}
                            />
                        </div>
                    </div>
                    <ScrollArea className="sten-modal__body" shouldScrollToActiveElement>
                        <div className="sten-content__section row">
                            <Validation.Wrapper
                                className="col-md-18 col-xs-16"
                                validations={{ required: true, maxLength: 1024 }}
                            >
                                <Input
                                    name="subject"
                                    value={this.props.email.subject}
                                    placeholder={translate('POSITION_LIST.COMPOSE.MODAL.SUBJECT')}
                                    onChange={this.props.setValue.bind(this, 'subject')}
                                />
                            </Validation.Wrapper>
                            <Validation.Wrapper
                                className="col-md-6 col-xs-8"
                                validations={{ required: true }}
                            >
                                <Select
                                    name="signature"
                                    valueKey="id"
                                    labelKey="title"
                                    options={this.props.signatures}
                                    value={this.props.selectedSignature}
                                    placeholder={translate('POSITION_LIST.COMPOSE.MODAL.SIGNATURE')}
                                    onChange={this.handleSignatureChange}
                                />
                            </Validation.Wrapper>
                        </div>
                        <div className="sten-content__section row">
                            <Validation.Wrapper
                                className="col-md-8 col-pad--sm"
                                validations={{ required: true, emailList: true }}
                            >
                                <MultiStringSelect
                                    name="recipient"
                                    options={this.props.recentRecipientsEmails}
                                    placeholder={translate('POSITION_LIST.COMPOSE.MODAL.TO')}
                                    onChange={this.props.setValue.bind(this, 'recipient')}
                                    value={this.props.email.recipient}
                                />
                            </Validation.Wrapper>
                            <Validation.Wrapper
                                className="col-md-8 col-pad--sm"
                                validate={!!this.props.email.cc}
                                validations={{ emailList: true }}
                            >
                                <MultiStringSelect
                                    name="cc"
                                    options={this.props.recentRecipientsEmails}
                                    placeholder={translate('POSITION_LIST.COMPOSE.MODAL.CC')}
                                    onChange={this.props.setValue.bind(this, 'cc')}
                                    value={this.props.email.cc}
                                />
                            </Validation.Wrapper>
                            <Validation.Wrapper
                                className="col-md-8 col-pad--sm"
                                validate={!!this.props.email.bcc}
                                validations={{ emailList: true }}
                            >
                                <MultiStringSelect
                                    name="bcc"
                                    options={this.props.recentRecipientsEmails}
                                    placeholder={translate('POSITION_LIST.COMPOSE.MODAL.BCC')}
                                    onChange={this.props.setValue.bind(this, 'bcc')}
                                    value={this.props.email.bcc}
                                />
                            </Validation.Wrapper>
                        </div>
                        <div className="sten-content__section">
                            <Validation.Wrapper validations={{ required: true, maxLength: 2048 }}>
                                <TextArea
                                    name="message"
                                    value={this.props.email.message}
                                    placeholder={translate('POSITION_LIST.COMPOSE.MODAL.MESSAGE')}
                                    onChange={this.props.setValue.bind(this, 'message')}
                                />
                            </Validation.Wrapper>
                        </div>
                        <div className="sten-content__section">
                            <HtmlPreview
                                content={
                                    getEmailTemplate(
                                        this.props.email.message,
                                        this.props.groupedVessels,
                                        this.props.visibleColumns,
                                        { ...this.props.selectedSignature, image: this.state.image },
                                        this.props.selectedPort,
                                        '',
                                        this.props.selectedExportVesselInfoConfig,
                                        this.props.vesselInfoConfigurableProperties
                                    )}
                            />
                        </div>
                    </ScrollArea>
                    <footer className="sten-modal__footer">
                        <div className="flex-grow flex-row">
                            <div className="flex-grow text-right">
                                <button
                                    type="button"
                                    onClick={this.closeAndDiscardForm}
                                    className="btn btn--secondary"
                                >
                                    {translate('GLOBAL.CANCEL')}
                                </button>
                            </div>
                            <div className="flex-shrink">
                                <Validation.Button className="btn btn--primary">
                                    {translate('GLOBAL.SEND')}
                                </Validation.Button>
                            </div>
                        </div>
                    </footer>
                </Validation.Form>
            </Modal.Default>
        );
    }
}

PositionListComposeModal.propTypes = {
    email: PropTypes.objectOf(PropTypes.any).isRequired,
    groupedVessels: PropTypes.objectOf(PropTypes.any).isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSend: PropTypes.func.isRequired,
    recentRecipientsEmails: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedExportVesselInfoConfig: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedPort: PropTypes.objectOf(PropTypes.any),
    selectedSignature: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string
    }),
    setValue: PropTypes.func.isRequired,
    signatures: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateUserSettings: PropTypes.func.isRequired,
    vesselInfoConfigurableProperties: PropTypes.objectOf(PropTypes.any).isRequired,
    visibleColumns: PropTypes.arrayOf(PropTypes.object).isRequired
};

PositionListComposeModal.defaultProps = {
    selectedPort: null,
    selectedSignature: null
};

export default PositionListComposeModal;
