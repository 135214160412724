import { createSelector } from 'reselect';

export const getReportsById = createSelector(
    state => state.homeReducer.selectedVoyageReports,

    (reports) => {
        const reportsById = {};

        reports.forEach(report => {
            reportsById[report.ReportId] = report;
        });

        return reportsById;
    }
);

export default null;
