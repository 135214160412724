import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { joinArrayObjProp } from 'utils/helpers/info-helper';
/* components */
import TableHeadCell from 'components/table-head-cell/table-head-cell';
import TextHighlight from 'components/text-highlight/text-highlight';
import Avatar from 'components/avatar/avatar';
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
import EmptyContent from 'components/empty-content/empty-content';
/* actions */
import { getContactList, setSortProp, setOffset } from './contact-list-table-actions';
import { filterUpdateLoaded } from '../contact-list-filters/contact-list-filters-actions';
/* styles */
import './contact-list-table.scss';

const tableHeadCells = [
    {
        title: translate('CONTACT_LIST.TABLE.NAME'),
        sortProp: 'Name',
        sortable: true,
        columnPropertyName: 'Name'
    },
    {
        title: translate('CONTACT_LIST.TABLE.DEPARTMENT'),
        sortProp: 'Department',
        sortable: true,
        columnPropertyName: 'Department'
    },
    {
        title: translate('CONTACT_LIST.TABLE.COMPANY'),
        sortProp: 'Company',
        sortable: true,
        columnPropertyName: 'Company'
    },
    {
        title: translate('CONTACT_LIST.TABLE.LOCATION'),
        sortProp: 'Location',
        sortable: true,
        className: 'sten-contact-list-table__location-cell',
        columnPropertyName: 'Location'
    },
    {
        title: translate('CONTACT_LIST.TABLE.PHONE'),
        sortable: false,
        className: 'sten-contact-list-table__phone-cell',
        columnPropertyName: 'Phone'
    },
    {
        title: translate('CONTACT_LIST.TABLE.EMAIL'),
        sortProp: 'Email',
        sortable: true,
        columnPropertyName: 'Email'
    }
];

class ContactListTable extends React.PureComponent {
    componentDidMount() {
        this.props.getContactList(true);
    }

    componentDidUpdate() {
        if (this.props.filterUpdated) {
            this.props.filterUpdateLoaded();
            this.props.setOffset(0);
            this.props.getContactList(true);
            if (this.fixedHeaderTable) {
                this.fixedHeaderTable.scrollArea.scrollArea.scrollTop();
            }
        }
    }

    setSortProp = sortProp => {
        this.props.setSortProp(sortProp);
        this.props.getContactList(true);
        this.fixedHeaderTable.scrollArea.scrollArea.scrollTop();
    };

    getTableRow(contact) {
        return (
            /* eslint-disable react/no-array-index-key */
            <tr key={contact.UserId}>
                {tableHeadCells.map((column, colIndex) => {
                    switch (column.columnPropertyName) {
                    case 'Name': {
                        return (
                            <td key={colIndex} className={column.className ? column.className : ''}>
                                <div className="flex-row flex-center">
                                    <div className="flex-shrink">
                                        <Avatar profileImageUrl={contact.ProfileImageUrl} />
                                    </div>
                                    <div className="flex-grow">
                                        <h3 className="text-ellipsis">
                                            <TextHighlight
                                                input={`${contact.FirstName} ${contact.LastName}`}
                                                highlight={this.props.searchCriteria}
                                            />
                                        </h3>
                                        <p className="text-secondary text-ellipsis">
                                            <TextHighlight
                                                input={contact.Position}
                                                highlight={this.props.searchCriteria}
                                            />
                                        </p>
                                    </div>
                                </div>
                            </td>
                        );
                    }
                    case 'Department': {
                        return (
                            <td key={colIndex} className={column.className ? column.className : ''}>
                                {joinArrayObjProp(contact.Departments, 'Name')}
                            </td>
                        );
                    }
                    case 'Company': {
                        return (
                            <td key={colIndex} className={column.className ? column.className : ''}>
                                {joinArrayObjProp(contact.Companies, 'Name')}
                            </td>
                        );
                    }
                    case 'Location': {
                        return (
                            <td key={colIndex} className={column.className ? column.className : ''}>
                                <TextHighlight
                                    input={contact.Location}
                                    highlight={this.props.searchCriteria}
                                />
                            </td>
                        );
                    }
                    case 'Phone': {
                        return (
                            <td key={colIndex} className={column.className ? column.className : ''}>
                                <div className="flex flex-center">
                                    <div className="col-12">
                                        <span className="text-secondary">{translate('CONTACT_LIST.TABLE.OFFICE')}</span>
                                        {contact.OfficePhone}
                                    </div>
                                    <div className="col-12">
                                        <span className="text-secondary">{translate('CONTACT_LIST.TABLE.MOBILE')}</span>
                                        {contact.MobilePhone}
                                    </div>
                                </div>
                            </td>
                        );
                    }
                    case 'Email': {
                        return (
                            <td key={colIndex} className={column.className ? column.className : ''}>
                                <a className="sten-link text-ellipsis" href={`mailto: ${contact.Email}`}>
                                    {contact.Email}
                                </a>
                            </td>
                        );
                    }
                    default:
                        return null;
                    }
                })}
            </tr>
            /* eslint-enable react/no-array-index-key */
        );
    }

    scrollHandler = value => {
        if (value.realHeight - value.topPosition < value.containerHeight + 100) {
            if (!this.props.isFetchingData && !this.props.contactListAllFetched) {
                const newOffset = this.props.offset + this.props.limit;
                this.props.setOffset(newOffset);
                this.props.getContactList(false);
            }
        }
    };

    saveRef = c => { this.fixedHeaderTable = c; };

    render() {
        if (!this.props.contactList.length) {
            return <EmptyContent />;
        }
        return (
            <FixedHeaderTable
                customFirstChild={TableHeadCell}
                ref={this.saveRef}
                contentClassName="sten-contact-list-table__scroll-content"
                onScroll={this.scrollHandler}
            >
                <table className="sten-table sten-contact-list-table">
                    <thead>
                        <tr>
                            {tableHeadCells.map((cell) => (
                                <TableHeadCell
                                    className={cell.className ? cell.className : ''}
                                    sortOrder={this.props.sortProp === cell.sortProp ? this.props.sortOrder : ''}
                                    sortable={cell.sortable}
                                    key={cell.title}
                                    onClick={this.setSortProp.bind(this, cell.sortProp)}
                                >
                                    {cell.title}
                                </TableHeadCell>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.contactList
                        && this.props.contactList.length > 0
                        && this.props.contactList.map(contact => this.getTableRow(contact))}
                    </tbody>
                </table>
            </FixedHeaderTable>
        );
    }
}

ContactListTable.propTypes = {
    contactList: PropTypes.arrayOf(PropTypes.any).isRequired,
    contactListAllFetched: PropTypes.bool.isRequired,
    filterUpdateLoaded: PropTypes.func.isRequired,
    filterUpdated: PropTypes.bool.isRequired,
    getContactList: PropTypes.func.isRequired,
    isFetchingData: PropTypes.bool.isRequired,
    limit: PropTypes.number.isRequired,
    offset: PropTypes.number.isRequired,
    searchCriteria: PropTypes.string.isRequired,
    setOffset: PropTypes.func.isRequired,
    setSortProp: PropTypes.func.isRequired,
    sortOrder: PropTypes.string.isRequired,
    sortProp: PropTypes.string.isRequired
};

function mapStateToProps(state) {
    return {
        contactList: state.contactListTableReducer.contactList,
        contactListAllFetched: state.contactListTableReducer.contactListAllFetched,
        filterUpdated: state.contactListFiltersReducer.filterUpdated,
        isFetchingData: state.contactListTableReducer.isFetchingData,
        limit: state.contactListTableReducer.limit,
        offset: state.contactListTableReducer.offset,
        searchCriteria: state.contactListFiltersReducer.searchCriteria,
        sortOrder: state.contactListTableReducer.sortOrder,
        sortProp: state.contactListTableReducer.sortProp
    };
}

function mapDispatchToProps(dispatch) {
    return {
        filterUpdateLoaded: () => filterUpdateLoaded(dispatch),
        getContactList: overrideCurrent => getContactList(dispatch, overrideCurrent),
        setOffset: newOffset => setOffset(dispatch, newOffset),
        setSortProp: sortProp => setSortProp(dispatch, sortProp)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactListTable);
