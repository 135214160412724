import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* types */
import { TWeatherRoutingQueryParams } from '../weather-routing-types';
/* utils */
import { renderPanels } from 'components/right-side-bar/right-side-bar-helper';
import { translate } from 'utils/i18n/i18n-model';
/* services */
import ConfigService from 'services/config-api/config-service';
/* actions */
import {
    emptyActivePanels,
    toggleActivePanel
} from 'components/right-side-bar/right-side-bar-actions';
/* components */
import CharterpartyForm from 'components/charterparty-form/charterparty-form';
import CounterPvar from 'components/counter-pvar/counter-pvar';
import CounterPvarForm from 'components/counter-pvar/counter-pvar-form/counter-pvar-form';
import WeatherRoutingRequest from '../request/weather-routing-request';
import IconButton from 'components/icon-button/icon-button';
import MapOptions from 'components/map-options/map-options';
import MapZoomControls from 'components/map-zoom-controls/map-zoom-controls';

export class WeatherRoutingRightSideBar extends React.PureComponent {
    componentDidMount() {
        this.updateActivePanel();
    }

    componentDidUpdate(prevProps) {
        this.updateActivePanel(prevProps);
    }

    updateActivePanel(prevProps) {
        if (!prevProps
            || this.props.queryParams.requestEdit !== prevProps.queryParams.requestEdit
            || this.props.queryParams.requestId !== prevProps.queryParams.requestId) {
            this.props.toggleActivePanel(
                'weather-routing-request',
                !!(this.props.queryParams.requestEdit || this.props.queryParams.requestId)
            );
        }
        if (!prevProps || this.props.queryParams.showCounterPvar !== prevProps.queryParams.showCounterPvar) {
            const showCounterPvar = !!(
                this.props.queryParams.showCounterPvar
                && this.props.queryParams.voyage
                && this.props.queryParams.vessel
                && ConfigService.featureToggles.showCounterPvar
            );
            this.props.toggleActivePanel('counter-pvar', showCounterPvar);
        }
        if (!prevProps || this.props.queryParams.cpEdit !== prevProps.queryParams.cpEdit) {
            this.props.toggleActivePanel('charterparty-form', !!(this.props.queryParams.cpEdit));
        }
    }

    componentWillUnmount() {
        this.props.emptyActivePanels();
    }

    showRequestSidebar = () => {
        const { requestId, requestEdit } = this.props.queryParams;
        return requestId || requestEdit;
    }

    showCounterPvarSidebar = () => {
        const { vessel, voyage } = this.props.queryParams;
        return vessel && voyage;
    }

    getButtons = () => {
        const { userSettings, showButtons } = this.props;
        if (!showButtons) {
            return null;
        }
        const mapTypeSettingName = ConfigService.hiddenFeatures.weatherRoutingNauticalCharts
            ? 'MarketIntelMapType'
            : 'MapOptionsMapType';
        const areMapOptionsChanged = userSettings
            && (userSettings[mapTypeSettingName] > 1
                || userSettings.ShowVesselNames
                || userSettings.ArePortsInvisible
                || userSettings.MapOptionsAreasOfInterestEnabled
                || userSettings.MapOptionsWeatherLayerEnabled
                || userSettings.MapOptionsCountryBordersNamesEnabled);
        return (
            <React.Fragment>
                <IconButton
                    changeIndicator={areMapOptionsChanged}
                    icon="icon-layers"
                    isActive={this.props.activePanels.indexOf('map-options') > -1}
                    onClick={this.props.toggleActivePanel.bind(this, 'map-options')}
                    tooltipTitle={translate('MAP_OPTIONS.TITLE')}
                />
                <MapZoomControls />
            </React.Fragment>
        );
    };

    bottomPanelContainer = {
        panelMap: {
            'map-options': () => (
                <MapOptions
                    key="map-options"
                    areNauticalChartsHidden={ConfigService.hiddenFeatures.weatherRoutingNauticalCharts}
                    mapTypeSettingName={ConfigService.hiddenFeatures.weatherRoutingNauticalCharts
                        ? 'MarketIntelMapType'
                        : 'MapOptionsMapType'
                    }
                    onCloseClick={this.props.toggleActivePanel.bind(this, 'map-options')}
                />
            ),
            'counter-pvar': () => (this.showCounterPvarSidebar()
                ? (
                    <CounterPvar
                        key="counter-pvar"
                        queryParams={this.props.queryParams}
                    />
                ) : null
            )
        },
        buttons: this.getButtons
    };

    topPanelContainer = {
        panelMap: {
            'weather-routing-request': () => (this.showRequestSidebar() ? (
                <WeatherRoutingRequest
                    key="weather-routing-request"
                    queryParams={this.props.queryParams}
                    updateQueryParams={this.props.router.updateQueryParams}
                    legId={this.props.legId}
                />
            ) : null),
            'counter-pvar-form': () => (
                <CounterPvarForm key="counter-pvar-form" />
            ),
            'charterparty-form': () => (
                <CharterpartyForm
                    key="charterparty-form"
                    voyageId={this.props.queryParams.voyage}
                    isEditing={this.props.queryParams.cpEdit}
                />
            )
        },
        style: {
            zIndex: 5
        }
    };

    render() {
        return renderPanels(
            this.props.activePanels,
            this.bottomPanelContainer,
            this.topPanelContainer
        );
    }
}

WeatherRoutingRightSideBar.propTypes = {
    activePanels: PropTypes.arrayOf(PropTypes.string).isRequired,
    emptyActivePanels: PropTypes.func.isRequired,
    legId: PropTypes.string,
    queryParams: TWeatherRoutingQueryParams.isRequired,
    router: TRouter.isRequired,
    showButtons: PropTypes.bool,
    toggleActivePanel: PropTypes.func.isRequired,
    userSettings: PropTypes.objectOf(PropTypes.any).isRequired
};

WeatherRoutingRightSideBar.defaultProps = {
    legId: '',
    showButtons: false
};

function mapStateToProps(state) {
    return {
        activePanels: state.rightSideBarReducer.activePanels,
        userSettings: state.userReducer.settings
    };
}

function mapDispatchToProps(dispatch) {
    return {
        emptyActivePanels: () => emptyActivePanels(dispatch),
        toggleActivePanel: (panelName, enable) => toggleActivePanel(dispatch, panelName, enable)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WeatherRoutingRightSideBar));
