import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* Actions */
import {
    gsmSetActivePanel,
    removeCarouselImages,
    getVesselList,
    removeAllEntities
} from './gsm-right-side-bar-actions';
/* Components */
import IconButton from 'components/icon-button/icon-button';
import GsmTankerInfo from './gsm-tanker-info/gsm-tanker-info';
import GsmLocationInfo from './gsm-city-info/gsm-city-info';
import GsmDrillInfo from './gsm-drill-info/gsm-drill-info';
import GsmFerryInfo from './gsm-ferry-info/gsm-ferry-info';
import MapZoomControls from 'components/map-zoom-controls/map-zoom-controls';
import GsmMainPanel from './gsm-main-panel/gsm-main-panel';
import RightSideBar from 'components/right-side-bar/right-side-bar';
/* Models */
import GsmRightSideBarModels from './gsm-right-side-bar-models';

export class GsmRightSideBar extends React.PureComponent {
    componentDidUpdate(prevProps) {
        if (!this.props.allEntities) {
            if (this.props.selectedTankerId !== prevProps.selectedTankerId
                && ((this.props !== 'gsm-tanker-info' && this.props.selectedTankerId)
                    || (this.props.activePanelName === 'gsm-tanker-info' && !this.props.selectedTankerId))) {
                this.toggleActivePanel('gsm-tanker-info', true, this.props);
            } else if (this.props.selectedFerryId !== prevProps.selectedFerryId
                && ((this.props.activePanelName !== 'gsm-ferry-info' && this.props.selectedFerryId)
                    || (this.props.activePanelName === 'gsm-ferry-info' && !this.props.selectedFerryId))) {
                this.toggleActivePanel('gsm-ferry-info', true, this.props);
            } else if (this.props.selectedCityId !== prevProps.selectedCityId
                && ((this.props.activePanelName !== 'gsm-city-info' && this.props.selectedCityId)
                    || (this.props.activePanelName === 'gsm-city-info' && !this.props.selectedCityId))) {
                this.toggleActivePanel('gsm-city-info', true, this.props);
            } else if (this.props.selectedDrillId !== prevProps.selectedDrillId
                && ((this.props.activePanelName !== 'gsm-drill-info' && this.props.selectedDrillId)
                    || (this.props.activePanelName === 'gsm-drill-info' && !this.props.selectedDrillId))) {
                this.toggleActivePanel('gsm-drill-info', true, this.props);
            } else if (this.props.allEntities !== prevProps.allEntities) {
                this.props.setActivePanel('', false);
            }
        } else if (this.props.allEntities && this.props.allEntities !== prevProps.allEntities) {
            if ((this.props.activePanelName !== 'gsm-main-panel' && this.props.allEntities)
                || (this.props.activePanelName === 'gsm-main-panel' && !this.props.allEntities)) {
                this.toggleActivePanel('gsm-main-panel', true, this.props);
            }
        }
    }

    componentWillUnmount() {
        this.props.setActivePanel('', false);
        this.props.removeCarouselImages();
    }

    onButtonClick = () => {
        this.props.router.clearQueryParams();
        if (!this.props.allEntities) {
            this.props.getVesselList();
        } else {
            this.props.removeAllEntities();
        }
    };

    getPanelComponent = () => {
        switch (this.props.activePanelName) {
        case 'gsm-tanker-info':
            return (
                <GsmTankerInfo key="tanker-info" />
            );
        case 'gsm-city-info':
            return (
                <GsmLocationInfo key="location-info" />
            );
        case 'gsm-ferry-info':
            return (
                <GsmFerryInfo key="ferry-info" />
            );
        case 'gsm-drill-info':
            return (
                <GsmDrillInfo key="drill-info" />
            );
        case 'gsm-main-panel':
            return (
                <GsmMainPanel key="main-panel" />
            );
        default:
            return '';
        }
    };

    toggleActivePanel = (panelName, expand, props = this.props) => {
        if (props.activePanelName === panelName) {
            if (props.selectedTankerId) {
                props.setActivePanel('gsm-tanker-info', true);
            } else if (props.selectedCityId) {
                props.setActivePanel('gsm-city-info', true);
            } else if (props.selectedFerryId) {
                props.setActivePanel('gsm-ferry-info', true);
            } else if (props.selectedFerryId) {
                props.setActivePanel('gsm-drill-info', true);
            } else if (props.allEntities) {
                props.setActivePanel('gsm-main-panel', true);
            } else {
                props.setActivePanel('', false);
            }
        } else {
            props.setActivePanel(panelName, expand);
        }
    };

    getButtons = () => (
        <React.Fragment>
            <IconButton
                icon="icon-map"
                isActive={this.props.activePanelName === 'gsm-main-panel'}
                onClick={this.onButtonClick.bind(this, 'gsm-main-panel', true)}
                tooltipTitle={translate('GSM.MAIN_PANEL.HEADER')}
            />
            <MapZoomControls />
        </React.Fragment>
    );

    render() {
        return (
            <RightSideBar buttonRenderer={this.getButtons} isCollapsed={!this.props.isExpanded}>
                {this.getPanelComponent()}
            </RightSideBar>
        );
    }
}

GsmRightSideBar.propTypes = {
    activePanelName: PropTypes.string.isRequired,
    allEntities: GsmRightSideBarModels.AllEntities,
    carouselImages: PropTypes.arrayOf(PropTypes.string).isRequired,
    getVesselList: PropTypes.func.isRequired,
    isExpanded: PropTypes.bool.isRequired,
    removeAllEntities: PropTypes.func.isRequired,
    removeCarouselImages: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    selectedCityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedDrillId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedFerryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedTankerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    setActivePanel: PropTypes.func.isRequired
};

GsmRightSideBar.defaultProps = {
    allEntities: null,
    selectedCityId: null,
    selectedDrillId: null,
    selectedFerryId: null,
    selectedTankerId: null
};

function mapStateToProps(state) {
    return {
        activePanelName: state.gsmRightSideBarReducer.activePanelName,
        allEntities: state.gsmRightSideBarReducer.allEntities,
        carouselImages: state.gsmRightSideBarReducer.carouselImages,
        isExpanded: state.gsmRightSideBarReducer.isExpanded,
        selectedCityId: state.gsmReducer.selectedCityId,
        selectedDrillId: state.gsmReducer.selectedDrillId,
        selectedFerryId: state.gsmReducer.selectedFerryId,
        selectedTankerId: state.gsmReducer.selectedTankerId
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setActivePanel: (panelName, expand) => gsmSetActivePanel(dispatch, panelName, expand),
        getVesselList: () => getVesselList(dispatch),
        removeAllEntities: () => removeAllEntities(dispatch),
        removeCarouselImages: () => removeCarouselImages(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GsmRightSideBar));
