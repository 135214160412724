import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { formatNumber, getObjectProp, isBetween } from 'utils/helpers/info-helper';
import ReportHelper from 'utils/helpers/report-helper';

export default class EnergyManagementReportsLabelValueTable extends React.PureComponent {
    isValueSuspicious = (value, boundaries) => {
        const val = Number(value);
        return val && boundaries && !isBetween(value, boundaries.min, boundaries.max, true, true);
    };

    renderValue = (row) => {
        const { data } = this.props;
        const item = getObjectProp(data, row.key);
        let isSuspicious = this.isValueSuspicious(item, row.boundaries);
        const className = isSuspicious ? 'text-warning' : '';
        const tooltip = (isSuspicious && row.suspiciousTooltip) || '';
        const exclusionFields = data && data.ExlusionFields;
        const colSpan = data.FuelTypes?.length || 1;
        let value;
        if (row.renderValue) {
            value = row.renderValue(data);
        } else {
            value = exclusionFields && !!exclusionFields[row.key]
                ? <span className="text-secondary">{exclusionFields[row.key].join('; ')}</span>
                : ReportHelper.formatValue(data, row);
        }
        if (row.type === 'fuelTypes') {
            return (
                <React.Fragment>
                    {data.FuelTypes.length !== 0
                        ? data.FuelTypes.map(fuelType => (
                            <td
                                key={fuelType.Id}
                                title={tooltip}
                                className={className}
                            >{fuelType.Name}
                            </td>
                        ))
                        : (<td>-</td>)
                    }
                </React.Fragment>
            );
        }
        if (row.type === 'fuelTypeQuantities') {
            return (
                <React.Fragment>
                    {data.FuelTypes.length !== 0
                        ? data.FuelTypes.map(fuelType => {
                            const quantity = item && item[fuelType.Id] ? item[fuelType.Id].Quantity : null;
                            isSuspicious = this.isValueSuspicious(quantity, row.boundaries);
                            return (
                                <td
                                    key={fuelType.Id}
                                    className={isSuspicious ? 'text-warning' : ''}
                                    title={(isSuspicious && row.suspiciousTooltip) || ''}
                                >
                                    {formatNumber(quantity) || '-'}
                                </td>
                            );
                        })
                        : (<td>-</td>)
                    }
                </React.Fragment>
            );
        }
        if (row.type === 'header') {
            return (
                <td key={row.key} colSpan={colSpan} />
            );
        }
        return (
            <td colSpan={colSpan} className={className} title={tooltip}>{value}</td>
        );
    };

    render() {
        return (
            <table
                className="
                    sten-table
                    sten-table--inverted
                    sten-table--xs
                    sten-table--no-border-top
                    sten-table--no-border-vertical
                    sten-table--no-border-bottom
                    sten-energy-management-reports-preview__summary-table"
            >
                <tbody>
                    {this.props.rowConfigs.map(row => (
                        <tr
                            key={row.key}
                            className={row.type === 'fuelTypes' || row.type === 'header' ? 'sten-table__head' : ''}
                        >
                            <td>
                                <div className="flex flex-center">
                                    <div className="flex-shrink text-secondary">
                                        {row.getLabel ? row.getLabel() : row.label}
                                    </div>
                                    {row.tooltip && (
                                        <div className="flex-grow">
                                            <span className="icon sten-icon-info-tooltip" title={row.tooltip} />
                                        </div>
                                    )}
                                </div>
                            </td>
                            {this.renderValue(row)}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    }
}

EnergyManagementReportsLabelValueTable.propTypes = {
    data: PropTypes.objectOf(PropTypes.any).isRequired,
    rowConfigs: PropTypes.arrayOf(PropTypes.object).isRequired
};
