import moment from 'moment';
import { createSelector } from 'reselect';
/* utils */
import { roundNumber } from 'utils/helpers/info-helper';

const formBoundaryMap = (allowanceMin, allowanceMax) => {
    const min = {};
    const max = {};
    allowanceMin.forEach(el => {
        min[[moment.utc(el.Date).startOf('day').valueOf()]] = el.Value;
    });
    allowanceMax.forEach(el => {
        max[[moment.utc(el.Date).startOf('day').valueOf()]] = el.Value;
    });
    return { min, max };
};

export const getReport = createSelector(
    state => state.energyManagementReportsWeatherPerformanceReportReducer.report,
    report => {
        if (!report) {
            return null;
        }
        const data = { ...report };
        if (report.PerformanceSummary && report.SpeedAnalysis && data.SpeedAnalysis.GoodWeather) {
            // eslint-disable-next-line max-len
            data.SpeedAnalysis.GoodWeather.IsSpeedPerformanceOptimal = report.PerformanceSummary.IsSpeedPerformanceOptimal;
        }
        return data;
    }
);

const extractChartData = (data, keys) => {
    const res = {
        reportIdData: {},
        series: {},
        categories: []
    };
    let fillCategoryData = true;
    let dataKey;
    let chartKey;

    keys.forEach(key => {
        dataKey = key;
        chartKey = key;
        if (typeof key !== 'string') {
            dataKey = key.dataKey;
            chartKey = key.chartKey || key.dataKey;
        }
        res.series[chartKey] = [];
        if (data && data[dataKey]) {
            data[dataKey].forEach(item => {
                const utcDate = moment.utc(item.Date).startOf('day').valueOf();
                if (fillCategoryData) {
                    res.categories.push(utcDate);
                }
                if (item.ReportId && !res.reportIdData[utcDate]) {
                    res.reportIdData[utcDate] = item.ReportId;
                }
                if (key.getSeriesData) {
                    res.series[chartKey].push(key.getSeriesData(utcDate, item.Value));
                } else if (typeof key.decimals === 'number') {
                    res.series[chartKey].push([
                        utcDate,
                        typeof item.Value === 'number' ? roundNumber(item.Value, key.decimals) : null]);
                } else {
                    res.series[chartKey].push([utcDate, item.Value]);
                }
            });
            fillCategoryData = false;
        }
    });

    return res;
};

export const getWeatherCharts = createSelector(
    state => state.energyManagementReportsWeatherPerformanceReportReducer.report,
    (report) => {
        if (!report) {
            return null;
        }
        const weatherCharts = {
            ConsumptionLngGraph: report.ConsumptionLngGraph,
            ConsumptionHfoGraph: report.ConsumptionHfoGraph,
            ConsumptionMdoGraph: report.ConsumptionMdoGraph,
            VesselSpeedGraph: report.VesselSpeedGraph,
            WaveHeightGraph: report.WaveHeightGraph,
            WindSpeedGraph: report.WindSpeedGraph
        };

        const chartsData = {};
        const consumptionKeys = [
            { dataKey: 'Consumption', chartKey: 'Consumption', decimals: 1 },
            { dataKey: 'GoodWeatherAllowance', chartKey: 'GoodWeatherAllowance', decimals: 1 }
        ];
        const speedAndHeightKeys = [
            { dataKey: 'ReportedValue', chartKey: 'ReportedValue', decimals: 1 },
            { dataKey: 'ExternalProviderValue', chartKey: 'ExternalProviderValue', decimals: 1 },
            { dataKey: 'CharterPartyValue', chartKey: 'CharterPartyValue', decimals: 1 }
        ];

        chartsData.vesselSpeedChart = extractChartData(weatherCharts.VesselSpeedGraph, speedAndHeightKeys);
        chartsData.windSpeedChart = extractChartData(weatherCharts.WindSpeedGraph, speedAndHeightKeys);
        chartsData.waveHeightChart = extractChartData(weatherCharts.WaveHeightGraph, speedAndHeightKeys);

        if (weatherCharts.ConsumptionHfoGraph) {
            const hfoGoodWeatherAllowanceBoundaryMap = formBoundaryMap(
                weatherCharts.ConsumptionHfoGraph.GoodWeatherAllowanceMin,
                weatherCharts.ConsumptionHfoGraph.GoodWeatherAllowanceMax
            );
            chartsData.consumptionHfoChart = extractChartData(weatherCharts.ConsumptionHfoGraph,
                [
                    {
                        dataKey: 'GoodWeatherAllowance',
                        chartKey: 'GoodWeatherAllowanceBoundary',
                        getSeriesData: (utcDate) => [
                            utcDate,
                            hfoGoodWeatherAllowanceBoundaryMap.min[utcDate],
                            hfoGoodWeatherAllowanceBoundaryMap.max[utcDate]
                        ]
                    },
                    ...consumptionKeys
                ]);
        }

        if (weatherCharts.ConsumptionLngGraph) {
            const lngGoodWeatherAllowanceBoundaryMap = formBoundaryMap(
                weatherCharts.ConsumptionLngGraph.GoodWeatherAllowanceMin,
                weatherCharts.ConsumptionLngGraph.GoodWeatherAllowanceMax
            );
            chartsData.consumptionLngChart = extractChartData(weatherCharts.ConsumptionLngGraph,
                [
                    {
                        dataKey: 'GoodWeatherAllowance',
                        chartKey: 'GoodWeatherAllowanceBoundary',
                        getSeriesData: (utcDate) => [
                            utcDate,
                            lngGoodWeatherAllowanceBoundaryMap.min[utcDate],
                            lngGoodWeatherAllowanceBoundaryMap.max[utcDate]
                        ]
                    },
                    ...consumptionKeys
                ]);
        }

        if (weatherCharts.ConsumptionMdoGraph) {
            const mdoGoodWeatherAllowanceBoundaryMap = formBoundaryMap(
                weatherCharts.ConsumptionMdoGraph.GoodWeatherAllowanceMin,
                weatherCharts.ConsumptionMdoGraph.GoodWeatherAllowanceMax
            );
            chartsData.consumptionMdoChart = extractChartData(weatherCharts.ConsumptionMdoGraph,
                [
                    {
                        dataKey: 'GoodWeatherAllowance',
                        chartKey: 'GoodWeatherAllowanceBoundary',
                        getSeriesData: (utcDate) => [
                            utcDate,
                            mdoGoodWeatherAllowanceBoundaryMap.min[utcDate],
                            mdoGoodWeatherAllowanceBoundaryMap.max[utcDate]
                        ]
                    },
                    ...consumptionKeys
                ]);
        }
        return chartsData;
    }
);
