import React from 'react';
import PropTypes from 'prop-types';
import ScrollArea from 'components/scroll-area/scroll-area';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { findTotalTopOffset } from 'utils/helpers/html-helper';
/* components */
import Accordion from 'components/accordion/accordion';
import DateRangePopup from 'components/date-range-popup/date-range-popup';
import Validation from 'components/validation/validation';
import VesselSelectInScope from 'components/vessel-select/vessel-select-in-scope';
import VoyageSelect from 'components/voyage-select/voyage-select';

const labelHeight = 25;

class WeatherPerformanceReportInclusions extends React.PureComponent {
    scrollToInvalidField = () => {
        if (this.scrollAreaRef?.contentWrapperRef) {
            const scrollAreaDiv = this.scrollAreaRef.contentWrapperRef;
            const elements = scrollAreaDiv.getElementsByClassName(
                'sten-validation-wrapper--invalid'
            );

            if (elements && elements[0]) {
                const totalOffsetTop = findTotalTopOffset(elements[0], scrollAreaDiv);
                this.scrollAreaRef.scrollYTo(totalOffsetTop - labelHeight);
            }
        }
    };

    setSelectedVoyage = (voyage) => {
        this.props.setSelectedVoyage(voyage);
        if (voyage) {
            this.props.setSelectedRange({
                rangeStart: voyage.DateRangeMin || null,
                rangeEnd: voyage.DateRangeMax || null
            });
        } else {
            this.props.setSelectedRange(null);
        }
    }

    saveRef = (r) => {
        this.scrollAreaRef = r;
    };

    render() {
        return (
            <Validation.Form
                className="sten-energy-management-reports-sidebar-inclusions"
                onSubmit={this.props.onSubmit}
                onInvalid={this.scrollToInvalidField}
            >
                <ScrollArea
                    className="sten-content__body"
                    ref={this.saveRef}
                    shouldScrollToActiveElement
                >
                    <div className="sten-content__section">
                        <div className="form-row">
                            <label className="label">{t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.VESSEL')}</label>
                            <Validation.Wrapper validations={{ required: true }}>
                                <VesselSelectInScope
                                    preSelectedValue={this.props.queryParams.emVesselImo}
                                    autoFocus
                                    name="vessel"
                                    clearable
                                    value={this.props.selectedVessel}
                                    onChange={this.props.setSelectedVessel}
                                    title={t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.SEARCH_VESSELS_TITLE')}
                                />
                            </Validation.Wrapper>
                        </div>
                        <div className="form-row">
                            <label className="label">{t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.VOYAGE_NUMBER')}</label>
                            <Validation.Wrapper validations={{ required: true }}>
                                <VoyageSelect
                                    preSelectedValue={this.props.queryParams.emVoyageId}
                                    params={{ filterFutureVoyages: true }}
                                    shouldSelectFirst
                                    name="voyage"
                                    vesselId={this.props.selectedVessel?.Imo || null}
                                    value={this.props.selectedVoyage}
                                    onChange={this.setSelectedVoyage}
                                    placeholder={this.props.selectedVessel
                                        ? t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.NO_VOYAGES_PLACEHOLDER')
                                        : t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.NO_VESSEL_PLACEHOLDER')
                                    }
                                />
                            </Validation.Wrapper>
                        </div>
                        <div className="form-row">
                            <label className="label">{t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.DATA_RANGE')}</label>
                            <Validation.Wrapper validations={{ dateRange: true }}>
                                <DateRangePopup
                                    isUTC
                                    alignment="end"
                                    onRangeSelect={this.props.setSelectedRange}
                                    name="dataRange"
                                    value={this.props.selectedRange}
                                    minDate={this.props.selectedVoyage?.DateRangeMin || null}
                                    maxDate={this.props.selectedVoyage?.DateRangeMax || null}
                                />
                            </Validation.Wrapper>
                        </div>
                    </div>
                    <Accordion
                        // eslint-disable-next-line max-len
                        header={<h4 className="text-uppercase">{t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.CHARTERPARTY')}</h4>}
                        isCollapsedBodyRendered
                    >
                        <div className="sten-content__section">
                            <p className="text-secondary">
                                {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.CHARTERPARTY_PLACEHOLDER')}
                            </p>
                        </div>
                    </Accordion>
                </ScrollArea>
                <footer className="sten-content__footer flex-row">
                    <div className="col-24">
                        <Validation.Button className="btn btn--primary col-24">
                            {t('ENERGY_MANAGEMENT.REPORTS.SIDEBAR.CALCULATE')}
                        </Validation.Button>
                    </div>
                </footer>
            </Validation.Form>
        );
    }
}

WeatherPerformanceReportInclusions.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    queryParams: PropTypes.shape({
        cpEdit: PropTypes.bool,
        cpVoyage: PropTypes.number,
        emReportType: PropTypes.number,
        emVesselImo: PropTypes.number,
        emVoyageId: PropTypes.number,
        reportEdit: PropTypes.bool,
        reportId: PropTypes.number,
        reportType: PropTypes.number
    }).isRequired,
    selectedRange: PropTypes.objectOf(PropTypes.any),
    setSelectedRange: PropTypes.func.isRequired,
    selectedVessel: PropTypes.objectOf(PropTypes.any),
    setSelectedVessel: PropTypes.func.isRequired,
    selectedVoyage: PropTypes.objectOf(PropTypes.any),
    setSelectedVoyage: PropTypes.func.isRequired
};

WeatherPerformanceReportInclusions.defaultProps = {
    selectedRange: null,
    selectedVessel: null,
    selectedVoyage: null
};

export default WeatherPerformanceReportInclusions;
