import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { getClassName } from 'utils/helpers/info-helper';
import { mapArrayByProp } from 'utils/helpers/array-helper';
import { sortAndFilterVessels } from './fleet-management-vessel-table-utils';
/* components */
import Checkbox from 'components/checkbox/checkbox';
import EmptyContent from 'components/empty-content/empty-content';
import Filters from './fleet-management-vessel-table-filters';
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
import TableHeadCell from 'components/table-head-cell/table-head-cell';
import VesselTableRow from './fleet-management-vessel-table-row';
/* services */
import VesselService from 'services/core-api/vessel-service';
/* styles */
import './fleet-management-vessel-table.scss';

const emptyArray = [];

class VesselTable extends React.PureComponent {
    state = {
        selectedVesselsMap: {},
        selectedVessels: emptyArray,
        vessels: emptyArray,
        vesselParams: {
            SortBy: 'VesselName',
            SortOrder: 'ASC'
        },
        vesselFilters: null
    };

    isFetchingVessels = false;

    componentMounted = false;

    static getDerivedStateFromProps(props, state) {
        if (props.selectedVessels !== state.selectedVessels) {
            return {
                selectedVessels: props.selectedVessels,
                selectedVesselsMap: mapArrayByProp(props.selectedVessels, 'IMO')
            };
        }
        return null;
    }

    componentDidMount() {
        this.componentMounted = true;
        this.updateVessels();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.vessels !== this.props.vessels
            || prevProps.fetchVessels !== this.props.fetchVessels
            || prevProps.filters !== this.props.filters) {
            this.updateVessels({ Offset: 0 });
            if (this.tableRef) {
                this.tableRef.scrollArea.scrollArea.scrollTop();
            }
        }
    }

    componentWillUnmount() {
        this.componentMounted = false;
    }

    updateVessels = (params = null, filters = null) => {
        const { vessels, vesselParams, vesselFilters } = this.state;
        const newFilters = filters || vesselFilters;
        if (this.props.vessels) {
            const newVesselParams = { ...vesselParams, ...params };
            this.setState({
                vessels: sortAndFilterVessels(
                    this.props.vessels,
                    newVesselParams,
                    this.props.areFiltersEnabled ? this.props.filters || newFilters : null
                ),
                vesselParams: newVesselParams,
                vesselFilters: newFilters
            });
        } else if (!this.isFetchingVessels) {
            const { limit } = this.props;
            const shouldFetchVessels = vesselParams.Offset === undefined
                || (params && params.Offset === 0)
                || (vesselParams.Offset + vesselParams.Limit) / vesselParams.Limit < vesselParams.TotalPagesCount;
            if (shouldFetchVessels) {
                this.isFetchingVessels = true;
                const newVesselParams = {
                    ...vesselParams,
                    Limit: limit,
                    Offset: vesselParams.Offset === undefined ? 0 : vesselParams.Offset + limit,
                    ...params
                };
                this.props.fetchVessels({ ...newVesselParams, ...this.props.filters || newFilters }).then(res => {
                    if (this.componentMounted) {
                        this.isFetchingVessels = false;
                        if (res) {
                            this.setState({
                                vessels: newVesselParams.Offset === 0 ? res.PageItems : [...vessels, ...res.PageItems],
                                vesselParams: { ...newVesselParams, TotalPagesCount: res.TotalPagesCount },
                                vesselFilters: newFilters
                            });
                        } else {
                            this.setState({ vessels: emptyArray, vesselFilters: newFilters });
                        }
                    }
                });
            }
        }
    };

    handleScroll = (value) => {
        if ((value.realHeight - value.topPosition < value.containerHeight + 100)) {
            if (this.props.onScrollEnd) {
                this.props.onScrollEnd();
            }
            if (!this.props.vessels) {
                this.updateVessels();
            }
        }
    };

    areAllVesselsSelected = (vessels) => {
        const { limit } = this.props;
        if (vessels.length >= limit) {
            return false;
        }
        return vessels.every(vessel => !!this.state.selectedVesselsMap[vessel.IMO]);
    }

    handleSelectAllChange = (e, props) => {
        const vessels = this.state.vessels;
        const selectedVesselsMap = this.state.selectedVesselsMap;
        const mappedVessels = mapArrayByProp(vessels, 'IMO');
        let newSelectedVessels;
        if (props.isChecked) {
            newSelectedVessels = this.props.selectedVessels.filter(vessel => !mappedVessels[vessel.IMO]);
        } else {
            newSelectedVessels = [...this.props.selectedVessels];
            vessels.forEach(vessel => {
                if (!selectedVesselsMap[vessel.IMO]) {
                    newSelectedVessels.push(vessel);
                }
            });
        }
        if (this.props.onSelectionChange) {
            this.props.onSelectionChange(newSelectedVessels);
        }
    };

    handleSelectAllClick = (e) => e.stopPropagation();

    handleVesselSelectionChange = (vessel) => {
        let newSelectedVessels;
        const selectedVesselsMap = this.state.selectedVesselsMap;
        if (!selectedVesselsMap[vessel.IMO]) {
            newSelectedVessels = [...this.props.selectedVessels, vessel];
        } else {
            newSelectedVessels = this.props.selectedVessels.filter(sv => sv.IMO !== vessel.IMO);
        }
        if (this.props.onSelectionChange) {
            this.props.onSelectionChange(newSelectedVessels);
        }
    }

    handleFilterChange = (filters, areFiltersChanged) => {
        this.updateVessels({ Offset: 0 }, {
            SearchTerm: filters.searchTerm.trim().toLowerCase(),
            CommercialOperatorIds: filters.commercialOperators.map(co => co.Id),
            CompanyFleetIds: filters.companyFleets.map(cf => cf.Id),
            SegmentIds: filters.segments.map(sg => sg.VesselTypeId),
            TechnicalManagerIds: filters.technicalManagers.map(tm => tm.Id),
            VesselOwnerIds: filters.vesselOwners.map(vo => vo.Id)
        });
        if (this.props.onFilterChange) {
            this.props.onFilterChange(filters, areFiltersChanged);
        }
    };

    setSortProp = (sortProp) => () => {
        const { SortBy, SortOrder } = this.state.vesselParams;
        this.updateVessels({
            Offset: 0,
            SortBy: sortProp,
            SortOrder: SortBy === sortProp && SortOrder === 'ASC' ? 'DESC' : 'ASC'
        });
        this.tableRef.scrollArea.scrollArea.scrollTop();
    };

    saveTableRef = (c) => { this.tableRef = c; }

    render() {
        const { className, isSelectable, limit, options, areFiltersVisible, areFiltersEnabled } = this.props;
        const { vessels, vesselParams, selectedVesselsMap } = this.state;
        const { SortBy, SortOrder } = vesselParams;
        const classNames = getClassName('sten-fleet-management-vessel-table', className, {
            'sten-fleet-management-vessel-table--filters-shown': areFiltersVisible && areFiltersEnabled
        });
        return (
            <div className={classNames}>
                <Filters onFilterChange={this.handleFilterChange} options={options} />
                {vessels.length === 0 && (
                    <EmptyContent className="sten-fleet-management-vessel-table__table">
                        {t('FLEET_MANAGEMENT.VESSEL_TABLE.NO_VESSELS')}
                    </EmptyContent>
                )}
                {vessels.length > 0 && (
                    <FixedHeaderTable
                        ref={this.saveTableRef}
                        className="sten-fleet-management-vessel-table__table"
                        contentClassName="sten-fleet-management-vessel-table__table-content"
                        onScroll={this.handleScroll}
                        withHeaderColumn
                        withHeaderColumnSeparator
                        customFirstChild={TableHeadCell}
                    >
                        <table className="sten-table sten-table--sm">
                            <thead>
                                <tr>
                                    <TableHeadCell
                                        sortable
                                        sortOrder={SortBy === 'VesselName' ? SortOrder : ''}
                                        onClick={this.setSortProp('VesselName')}
                                    >
                                        <div className="flex-row flex-center">
                                            {isSelectable && (
                                                <div className="flex-shrink">
                                                    <Checkbox
                                                        isChecked={this.areAllVesselsSelected(vessels)}
                                                        onChange={this.handleSelectAllChange}
                                                        onClick={this.handleSelectAllClick}
                                                        isDisabled={vessels.length >= limit}
                                                        title={vessels.length >= limit
                                                            ? t('FLEET_MANAGEMENT.VESSEL_TABLE.MAX_SELECTION', {
                                                                max: limit
                                                            })
                                                            : ''}
                                                    />
                                                </div>
                                            )}
                                            <div className="flex-grow">
                                                {t('FLEET_MANAGEMENT.VESSEL_TABLE.VESSEL_NAME')}
                                            </div>
                                        </div>
                                    </TableHeadCell>
                                    <TableHeadCell
                                        sortable
                                        sortOrder={SortBy === 'CommercialOperator' ? SortOrder : ''}
                                        onClick={this.setSortProp('CommercialOperator')}
                                    >
                                        {t('FLEET_MANAGEMENT.VESSEL_TABLE.COMMERCIAL_OPERATOR')}
                                    </TableHeadCell>
                                    <th>{t('FLEET_MANAGEMENT.VESSEL_TABLE.COMPANY_FLEETS')}</th>
                                    <TableHeadCell
                                        sortable
                                        sortOrder={SortBy === 'Segment' ? SortOrder : ''}
                                        onClick={this.setSortProp('Segment')}
                                    >
                                        {t('FLEET_MANAGEMENT.VESSEL_TABLE.SEGMENT')}
                                    </TableHeadCell>
                                    <TableHeadCell
                                        sortable
                                        sortOrder={SortBy === 'CoatingType' ? SortOrder : ''}
                                        onClick={this.setSortProp('CoatingType')}
                                    >
                                        {t('FLEET_MANAGEMENT.VESSEL_TABLE.COATING_TYPE')}
                                    </TableHeadCell>
                                    {!isSelectable && (
                                        <th className="sten-fleet-management-vessel-table__action-cell" />
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {vessels.map(vessel => (
                                    <VesselTableRow
                                        key={vessel.IMO}
                                        vessel={vessel}
                                        isEditing={isSelectable}
                                        onSelectionChange={this.handleVesselSelectionChange}
                                        isSelected={!!selectedVesselsMap[vessel.IMO]}
                                        searchCriteria={vesselParams.SearchTerm || ''}
                                    />
                                ))}
                            </tbody>
                        </table>
                    </FixedHeaderTable>
                )}
            </div>
        );
    }
}

VesselTable.propTypes = {
    areFiltersVisible: PropTypes.bool,
    areFiltersEnabled: PropTypes.bool,
    className: PropTypes.string,
    fetchVessels: PropTypes.func,
    filters: PropTypes.objectOf(PropTypes.any),
    isSelectable: PropTypes.bool,
    limit: PropTypes.number,
    onFilterChange: PropTypes.func,
    onScrollEnd: PropTypes.func,
    onSelectionChange: PropTypes.func,
    options: PropTypes.shape({
        companyFleets: PropTypes.arrayOf(PropTypes.object),
        segments: PropTypes.arrayOf(PropTypes.object),
        technicalManagers: PropTypes.arrayOf(PropTypes.object),
        vesselOwners: PropTypes.arrayOf(PropTypes.object)
    }).isRequired,
    selectedVessels: PropTypes.arrayOf(PropTypes.object),
    vessels: PropTypes.arrayOf(PropTypes.object)
};

VesselTable.defaultProps = {
    areFiltersVisible: true,
    areFiltersEnabled: true,
    className: '',
    fetchVessels: VesselService.getAll,
    filters: null,
    isSelectable: false,
    limit: 200,
    onFilterChange: undefined,
    onScrollEnd: undefined,
    onSelectionChange: undefined,
    selectedVessels: emptyArray,
    vessels: null
};

export default VesselTable;
