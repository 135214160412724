import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { TRouter, withRouter } from 'app-router';
/* actions */
import { getInitialData, resetAll, toggleSidebar } from './kpi-actions';
/* components */
import { Outlet } from 'react-router';
/* styles */
import './kpi.scss';

class EnergyManagementKPI extends React.PureComponent {
    componentDidMount() {
        this.props.getInitialData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.router.location.pathname !== this.props.router.location.pathname) {
            this.props.toggleSidebar(false);
        }
    }

    componentWillUnmount() {
        this.props.resetAll();
    }

    render() {
        return <Outlet />;
    }
}

EnergyManagementKPI.propTypes = {
    children: PropTypes.node,
    getInitialData: PropTypes.func.isRequired,
    resetAll: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    toggleSidebar: PropTypes.func.isRequired
};

EnergyManagementKPI.defaultProps = {
    children: null
};

function mapDispatchToProps(dispatch) {
    return {
        getInitialData: () => getInitialData(dispatch),
        resetAll: () => resetAll(dispatch),
        toggleSidebar: (flag) => toggleSidebar(dispatch, flag)
    };
}

export default connect(null, mapDispatchToProps)(withRouter(EnergyManagementKPI));
