import { ActionTypes } from './euets-actions';

const initialState = {
    // TODO: Hardcoded EU ETS available years - remove when available on EP
    yearsAvailable: [
        { Name: '2023' },
        { Name: '2024' },
        { Name: '2025' },
        { Name: '2026' }
    ],
    // TODO: Hardcoded EU ETS vessel types - remove when available on EP
    vesselTypes: [
        { Name: 'Tanker' },
        { Name: 'Bulk' },
        { Name: 'Container' }
    ],
    euEtsPrice: null
};

export default function euEtsReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.EU_ETS_SET_PRICE: {
        return { ...state, euEtsPrice: action.price || null };
    }
    default:
        return state;
    }
}
