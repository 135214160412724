import PropTypes from 'prop-types';

export const TAisPosition = PropTypes.shape({
    CurrentAis: PropTypes.shape({
        Heading: PropTypes.number,
        Longitude: PropTypes.number,
        Latitude: PropTypes.number
    }),
    IsDedicatedVessel: PropTypes.bool,
    IsVesselInUserScope: PropTypes.bool,
    RelationshipType: PropTypes.oneOf(['TimeCharter', 'CargoOwner']),
    LatestAlert: PropTypes.objectOf(PropTypes.any),
    Title: PropTypes.string,
    SpeedOverGround: PropTypes.number
});
