export default {
    backspace: 8,
    tab: 9,
    space: 32,
    semicolon: 186,
    enter: 13,
    escape: 27,
    downArrow: 40,
    upArrow: 38,
    leftArrow: 37,
    rightArrow: 39,
    pageUp: 33,
    pageDown: 34,
    F5: 116,
    F6: 117,
    F7: 118,
    F8: 119,
    F9: 120,
    F10: 121
};
