import React from 'react';
import PropTypes from 'prop-types';
/* styles */
import './progress.scss';

class Progress extends React.PureComponent {
    getValue = () => {
        if (typeof this.props.value === 'number') {
            const unit = typeof this.props.valueUnit === 'string' ? `${this.props.valueUnit}` : '%';
            if (typeof this.props.refValue === 'number') {
                return (
                    <label className="sten-progress__value">
                        {`${this.props.value}${unit}`}
                        <span className="sten-progress__value-split">/</span>
                        <span className="sten-progress__value-ref">{`${this.props.refValue}${unit}`}</span>
                    </label>
                );
            }
            return (
                <label className="sten-progress__value">{`${this.props.value}${unit}`}</label>
            );
        }
        return '';
    };

    getWidth = () => {
        const min = this.props.min;
        const max = this.props.max;
        return ((this.props.value - min) * 100) / (max - min);
    };

    render() {
        let progressClass = 'sten-progress';
        if (this.props.className) {
            progressClass += ` ${this.props.className}`;
        }

        let progressBarClass = 'sten-progress__bar';
        if (this.props.refValue) {
            progressBarClass += ' sten-progress__bar--ref';
        }

        let label = this.props.min;
        let refLabel = this.props.max;
        let infoClass = 'sten-progress__info';
        if (this.props.label) {
            label = this.props.label;
        }
        if (this.props.refLabel) {
            refLabel = this.props.refLabel;
            infoClass += ' sten-progress__info--has-ref';
        }

        const progressWidth = this.getWidth();

        return (
            <div className={progressClass}>
                <div className={progressBarClass}>
                    {progressWidth > 0 && (
                        <div className="sten-progress__bar-value" style={{ width: `${progressWidth}%` }} />
                    )}
                </div>
                <div className={infoClass}>
                    <label className="sten-progress__label">{label}</label>
                    {this.getValue()}
                    <label className="sten-progress__label sten-progress__label--ref">{refLabel}</label>
                </div>
            </div>
        );
    }
}

Progress.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    max: PropTypes.number,
    min: PropTypes.number,
    refLabel: PropTypes.string,
    refValue: PropTypes.number,
    value: PropTypes.number,
    valueUnit: PropTypes.string
};

Progress.defaultProps = {
    className: '',
    label: '',
    max: 100,
    min: 0,
    refLabel: '',
    refValue: null,
    value: null,
    valueUnit: ''
};

export default Progress;
