import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import { getStringStrength } from 'utils/helpers/info-helper';
/* styles */
import './password-strength.scss';

const PasswordStrength = (props) => {
    let weakPasswordClass = 'sten-password-strength__bar sten-password-strength__bar--weak col-8';
    let mediumPasswordClass = 'sten-password-strength__bar sten-password-strength__bar--medium col-8';
    let strongPasswordClass = 'sten-password-strength__bar sten-password-strength__bar--strong col-8';
    let passwordStrengthClass = 'sten-password-strength flex text-center';
    if (props.className) {
        passwordStrengthClass += ` ${props.className}`;
    }

    if (props.value && props.value.length) {
        if (getStringStrength(props.value).totalScore >= 120) {
            strongPasswordClass += ' sten-password-strength__bar--scored';
        } else if (getStringStrength(props.value).totalScore >= 60) {
            mediumPasswordClass += ' sten-password-strength__bar--scored';
        } else {
            weakPasswordClass += ' sten-password-strength__bar--scored';
        }
    }
    return (
        <div className={passwordStrengthClass}>
            <div className={weakPasswordClass}>{translate('PASSWORD_STRENGTH.WEAK')}</div>
            <div className={mediumPasswordClass}>{translate('PASSWORD_STRENGTH.MEDIUM')}</div>
            <div className={strongPasswordClass}>{translate('PASSWORD_STRENGTH.STRONG')}</div>
        </div>
    );
};

PasswordStrength.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string.isRequired
};

PasswordStrength.defaultProps = {
    className: ''
};

export default PasswordStrength;
