import { createSelector } from 'reselect';
import { createByIdSelector } from 'utils/helpers/selector-helper';

export const getVesselsBySegment = createSelector(
    state => state.portCallsAnalyticsReducer.vessels,
    (vessels) => {
        const vesselsBySegmentId = {};
        let vesselTypeId;
        vessels.forEach((vessel) => {
            vesselTypeId = vessel.VesselTypeId;
            if (typeof vesselsBySegmentId[vesselTypeId] === 'undefined') {
                vesselsBySegmentId[vesselTypeId] = [];
            }
            vesselsBySegmentId[vesselTypeId].push(vessel);
        });
        return vesselsBySegmentId;
    }
);

export const getVisibleSegments = createSelector(
    state => state.portCallsAnalyticsReducer.segments,
    getVesselsBySegment,
    (segments, vesselsBySegmentId) => segments.filter((segment) => {
        return vesselsBySegmentId[segment.VesselTypeId]
            && vesselsBySegmentId[segment.VesselTypeId].length > 0;
    })
);

export const getAddedVesselsByIMO = createSelector(
    state => state.portCallsAnalyticsReducer.addedVessels,
    (vessels) => {
        const addedVesselsByIMO = {};
        vessels.forEach((vessel) => {
            addedVesselsByIMO[vessel.IMO] = true;
        });
        return addedVesselsByIMO;
    }
);

export const getAddedPortsById = createSelector(
    state => state.portCallsAnalyticsReducer.addedPorts,
    (ports) => {
        const addedPortsById = {};
        ports.forEach((port) => {
            addedPortsById[port.Id] = true;
        });
        return addedPortsById;
    }
);

export const getSegmentsById = createByIdSelector(
    state => state.portCallsAnalyticsReducer.segments,
    'VesselTypeId'
);

export const getCalculatedPortCallsAnalytics = createSelector(
    state => state.portCallsAnalyticsReducer.portCallsAnalytics,
    (calls) => {
        const parsedCalls = [];
        let stopsCount = 0;
        if (calls && calls.length) {
            calls.forEach((call, index) => {
                parsedCalls[index] = {};
                parsedCalls[index] = {
                    imo: call.Imo,
                    stops: []
                };
                if (call.PortCalls && call.PortCalls.length > 0) {
                    call.PortCalls.forEach(portCall => {
                        if (portCall.Activities && portCall.Activities.length > 0) {
                            portCall.Activities.forEach(activity => {
                                stopsCount++;
                                parsedCalls[index].stops.push(
                                    {
                                        ...activity,
                                        Point: portCall.Point
                                    }
                                );
                            });
                        }
                    });
                }
            });
        }
        return {
            parsedCalls,
            stopsCount
        };
    }
);
