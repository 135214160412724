/* components */
import Map from './ol-map';
import Layer from './ol-layer';
/* styles */
import './ol.scss';

export default {
    Map,
    Layer
};
