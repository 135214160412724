import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* styles */
import './info-card.scss';

const t = (key) => translate(`ENERGY_MANAGEMENT.KPI.${key}`);

export default class EnergyManagementKPIInfoCard extends React.PureComponent {
    render() {
        const { value, title, footerValue1, footerValue2, infoTooltip } = this.props;
        let className = 'sten-energy-management-kpi-info-card';
        if (this.props.className) {
            className += ` ${this.props.className}`;
        }
        const iconClassName = `sten-energy-management-kpi-info-card__icon icon ${this.props.iconClassName}`;
        const valueClassName = `sten-energy-management-kpi-info-card__value ${this.props.valueClassName}`;
        const rangeEndYear = this.props.rangeEndDate ? this.props.rangeEndDate.year : 0;
        const rolling12Months = !this.props.rangeOption || this.props.rangeOption.Id === 1;
        const rangeLabel = rolling12Months ? t('1-12_MONTHS') : rangeEndYear;
        const prevRangeLabel = rolling12Months ? t('13-24_MONTHS') : rangeEndYear - 1;
        const monthsRowClassName = rolling12Months
            ? 'flex flex-row sten-energy-management-kpi-info-card__rolling-12-months'
            : 'flex flex-row';
        return (
            <div className={className}>
                <div className="sten-panel__section">
                    <h4 className="text-secondary">
                        {title}
                        {infoTooltip && <span className="icon sten-icon-info-tooltip" title={infoTooltip} />}
                    </h4>
                </div>
                <div className="sten-panel__section">
                    <div className="flex flex-center">
                        <div className="sten-energy-management-kpi-info-card__icon-wrapper">
                            <span className={iconClassName} />
                        </div>
                        <h1 className={valueClassName}>{value}</h1>
                    </div>
                </div>
                <div className="sten-panel__section">
                    <div className={monthsRowClassName}>
                        <div className="flex-shrink">
                            <label className="sten-panel__label">{rangeLabel}</label>
                        </div>
                        <div className="flex-grow">
                            <label className="sten-panel__label sten-panel__label--value">{footerValue1}</label>
                        </div>
                    </div>
                    <div className={monthsRowClassName}>
                        <div className="flex-shrink">
                            <label className="sten-panel__label">{prevRangeLabel}</label>
                        </div>
                        <div className="flex-grow">
                            <label className="sten-panel__label sten-panel__label--value">{footerValue2}</label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

EnergyManagementKPIInfoCard.propTypes = {
    className: PropTypes.string,
    footerValue1: PropTypes.string.isRequired,
    footerValue2: PropTypes.string.isRequired,
    iconClassName: PropTypes.string,
    infoTooltip: PropTypes.string,
    rangeEndDate: PropTypes.objectOf(PropTypes.any),
    rangeOption: PropTypes.objectOf(PropTypes.any),
    title: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    valueClassName: PropTypes.string
};

EnergyManagementKPIInfoCard.defaultProps = {
    className: '',
    iconClassName: '',
    infoTooltip: '',
    rangeEndDate: null,
    rangeOption: null,
    valueClassName: ''
};
