import { ActionTypes } from './operators-diary-actions';
import { timeFrameOptions } from './operators-diary-constants';

const emptyArray = [];

const initialState = {
    isMounted: false,
    diary: emptyArray,
    fleets: emptyArray,
    etbStatuses: emptyArray,
    tags: {
        leg: emptyArray,
        voyage: emptyArray,
        fixture: emptyArray
    },
    timeFrameOptions
};

export default function operatorsDiaryReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.OPERATORS_DIARY_SET: {
        return {
            ...state,
            diary: action.diary || emptyArray
        };
    }
    case ActionTypes.OPERATORS_DIARY_SET_OPTIONS: {
        return {
            ...state,
            isMounted: true,
            fleets: action.fleets || emptyArray,
            tags: action.tags || initialState.tags,
            etbStatuses: action.etbStatuses || emptyArray
        };
    }
    case ActionTypes.OPERATORS_DIARY_RESET: {
        return initialState;
    }
    default:
        return state;
    }
}
