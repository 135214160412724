import { formatNumber, formatCoordinates } from 'utils/helpers/info-helper';
import { translate } from 'utils/i18n/i18n-model';
import TimeHelper from 'utils/helpers/time-helper';

export const convertWidthToCol = (width, colWidths, offset = 0, defaultColWidth = 18) => {
    let col = 0;
    if (colWidths && colWidths.length > 0) {
        let targetLogoWidth = width;
        let colWidth;
        let colDiff = 1;
        for (let i = 0; i < colWidths.length; i++) {
            if (offset <= i) {
                if (offset < i && offset + 1 > i) {
                    colWidth = (i - offset) * colWidths[i].width;
                    colDiff = i - offset;
                } else {
                    colWidth = colWidths[i].width;
                    colDiff = 1;
                }
                if (targetLogoWidth - colWidth > 0) {
                    col += colDiff;
                    targetLogoWidth -= colWidth;
                } else {
                    col += targetLogoWidth / colWidth;
                    break;
                }
            }
        }
    } else {
        col = offset + (width / defaultColWidth);
    }
    col = Math.round(col * 100) / 100;
    return col < 1 ? 1 : col;
};

const timeFormattingOptions = { time: true };

export const formatXLSXCellValue = (val, props = {}) => {
    const formatOptions = props.dateOptions || timeFormattingOptions;
    let value = val;
    const { numFmt } = props;
    if (props.type === 'number') {
        return { value, numFmt };
    }
    if (props.type === 'date') {
        return { value: value ? new Date(value) : null, numFmt: TimeHelper.getFormat(formatOptions) };
    }
    if (props.type === 'duration') {
        if (value === null) {
            return { value, numFmt };
        }
        const multiplier = props.multiplier || 1; // convert to milliseconds
        const divider = 24 * 60 * 60 * 1000; // convert to specified unit (days by default)
        return { value: (value * multiplier) / divider, numFmt: '[hh]:mm' };
    }
    if (props.type === 'bool') {
        if (typeof value === 'boolean') {
            value = value ? translate('GLOBAL.YES') : translate('GLOBAL.NO');
        } else {
            value = '-';
        }
    }
    if (props.type === 'array') {
        if (value.length === 0) {
            value = '-';
        } else {
            value = value.map((v) => formatNumber(v, 1)).join(', ');
            if (props.unit) {
                value = `${value} ${props.unit}`;
            }
        }
    }
    if (props.type === 'position') {
        if (value && typeof value.Latitude === 'number' && typeof value.Longitude === 'number') {
            value = formatCoordinates(value.Longitude, value.Latitude);
        } else {
            value = '-';
        }
    }
    if (value === null || value === '' || value === undefined) {
        value = '-';
    }
    return { value, numFmt };
};

export function triggerXLSXDownload(fileName) {
    return (xls64) => {
        const blob = new Blob([xls64], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, `${fileName}.xlsx`);
        } else {
            const a = document.createElement('a');
            const url = URL.createObjectURL(blob);
            a.href = url;
            a.download = `${fileName}.xlsx`;
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    };
}

export function addWorksheet(workbook, config) {
    const sheet = workbook.addWorksheet(config.name, config.props);
    const startingRow = sheet.actualRowCount;
    /* add empty rows */
    let sheetRow;
    let sheetCell;
    let rowSpan;
    let colSpan;
    let image;
    let imageProps;
    let prop;
    let cell;
    let cellIndex;
    let nextCellIndex;
    let numCells;
    let values;
    let rowOffset;
    let colRow;
    sheet.addRows(new Array(config.rows.length));
    config.rows.forEach((row, rowIndex) => {
        sheetRow = sheet.getRow(startingRow + rowIndex + 1);
        if (row) {
            if (row.pageBreak) {
                sheetRow.addPageBreak();
            }
            if (row.props) {
                for (prop in row.props) {
                    if (row.props.hasOwnProperty(prop)) {
                        sheetRow[prop] = row.props[prop];
                    }
                }
            }
            if (row.values) {
                values = row.values;
                numCells = values.length;
                rowOffset = startingRow + rowIndex + 1;
                for (cellIndex = 0; cellIndex < numCells; cellIndex++) {
                    cell = values[cellIndex];
                    nextCellIndex = cellIndex + 1;
                    sheetCell = sheetRow.getCell(nextCellIndex);
                    if (cell && !sheetCell.isMerged) {
                        if (cell.image) {
                            image = workbook.addImage(cell.image);
                            colRow = { col: nextCellIndex, row: rowOffset };
                            imageProps = cell.imageProps
                                ? cell.imageProps
                                : {
                                    tl: colRow,
                                    br: colRow
                                };
                            sheet.addImage(image, imageProps);
                        } else {
                            if ((cell.colSpan && cell.colSpan > 1) || (cell.rowSpan && cell.rowSpan > 1)) {
                                colSpan = cell.colSpan ? cell.colSpan - 1 : 0;
                                rowSpan = cell.rowSpan ? cell.rowSpan - 1 : 0;
                                sheet.mergeCells(
                                    rowOffset,
                                    nextCellIndex,
                                    rowOffset + rowSpan,
                                    nextCellIndex + colSpan
                                );
                            }
                            if (cell.props) {
                                for (prop in cell.props) {
                                    if (cell.props.hasOwnProperty(prop)) {
                                        sheetCell[prop] = cell.props[prop];
                                    }
                                }
                            }
                            if (cell.value !== undefined) {
                                sheetCell.value = cell.value;
                            }
                        }
                    }
                }
            }
        }
    });
    if (config.columnWidth) {
        let col;
        for (let i = 1; i <= sheet.columns.length; i++) {
            col = sheet.getColumn(i);
            col.width = config.columnWidth;
        }
    }
    if (config.columns) {
        sheet.columns = config.columns;
    }
}
