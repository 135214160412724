export const ActionTypes = {
    TEST_GET: 'TEST_GET',
    TEST_SET: 'TEST_SET',
    TEST_SET_FUEL_QUANTITY: 'TEST_SET_FUEL_QUANTITY'
};

export function getTest(dispatch, test) {
    dispatch({
        type: ActionTypes.TEST_GET,
        test
    });
}
export function setFuelQuantity(dispatch, itemIndex, fuel, value) {
    dispatch({
        type: ActionTypes.TEST_SET_FUEL_QUANTITY,
        itemIndex,
        fuel,
        value
    });
}
