import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
/* components */
import Modal from 'components/modal/modal';
/* services */
import VoyageService from 'services/core-api/voyage-service';

export class SendBudgetEmail extends React.PureComponent {
    state = {
        isConfirmModalOpen: false
    };

    openBudgetEmailModal = (e) => {
        e.stopPropagation();
        this.setState({
            isConfirmModalOpen: true
        });
    };

    sendBudgetEmail = (e) => {
        e.stopPropagation();
        VoyageService.sendBudgetConsumptionEmail(this.props.voyageId)
            .then(res => {
                if (res) {
                    toast('Daily Consumption budget email sent successfully to captain', { type: toast.TYPE.SUCCESS });
                }
                this.setState({
                    isConfirmModalOpen: false
                });
            });
    };

    cancelSending = (e) => {
        e.stopPropagation();
        this.setState({
            isConfirmModalOpen: false
        });
    };

    render() {
        return (
            <React.Fragment>
                <Modal.Confirm
                    isOpen={this.state.isConfirmModalOpen}
                    onConfirm={this.sendBudgetEmail}
                    onCancel={this.cancelSending}
                >
                    Are you sure you want to send email to captain?
                </Modal.Confirm>
                <button
                    style={{ paddingRight: '1rem' }}
                    className="btn-link icon icon-alert"
                    onClick={this.openBudgetEmailModal}
                    title="Send daily consumption budget email to captain"
                />
            </React.Fragment>
        );
    }
}

SendBudgetEmail.propTypes = {
    voyageId: PropTypes.number.isRequired
};

export default SendBudgetEmail;
