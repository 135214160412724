
export function copyTextToClipboard(text) {
    // Create a dummy input to copy the string array inside it
    const dummy = document.createElement('textarea');

    // Add it to the document
    document.body.appendChild(dummy);

    // Output the array into it
    dummy.value = text;

    // Select it
    dummy.select();

    // Copy its contents
    document.execCommand('copy');

    // Remove it as its not needed anymore
    document.body.removeChild(dummy);
}
