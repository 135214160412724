import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* actions */
import {
    toggleActivePanel,
    emptyActivePanels
} from 'components/right-side-bar/right-side-bar-actions';
/* components */
import IconButton from 'components/icon-button/icon-button';
import MapZoomControls from 'components/map-zoom-controls/map-zoom-controls';
import MarketIntelOptions from './market-intel-options/market-intel-options';
import RightSideBar from 'components/right-side-bar/right-side-bar';

export class MIRightSideBar extends React.PureComponent {
    componentWillUnmount() {
        this.props.emptyActivePanels();
    }

    getButtons = () => {
        const { userSettings } = this.props;
        const areMapOptionsChanged = userSettings
            && (userSettings.MarketIntelMapType > 1
                || userSettings.MarketIntelLayerType > 1
                || (typeof userSettings.MarketIntelCountryBordersNamesEnabled === 'boolean'
                    && userSettings.MarketIntelCountryBordersNamesEnabled));
        return (
            <React.Fragment>
                <IconButton
                    changeIndicator={areMapOptionsChanged}
                    icon="icon-layers"
                    isActive={this.props.activePanels.indexOf('market-intel-options') > -1}
                    onClick={this.props.toggleActivePanel.bind(this, 'market-intel-options')}
                    tooltipTitle={translate('MAP_OPTIONS.TITLE')}
                />
                <MapZoomControls />
            </React.Fragment>
        );
    };

    render() {
        return (
            <RightSideBar isCollapsed={this.props.activePanels.length === 0} buttonRenderer={this.getButtons}>
                {this.props.activePanels.map((panelName) => {
                    switch (panelName) {
                    case 'market-intel-options':
                        return (
                            <MarketIntelOptions
                                key="map-options"
                                onCloseClick={this.props.toggleActivePanel.bind(this, 'market-intel-options')}
                            />
                        );
                    default:
                        return '';
                    }
                })}
            </RightSideBar>
        );
    }
}

MIRightSideBar.propTypes = {
    activePanels: PropTypes.arrayOf(PropTypes.string).isRequired,
    emptyActivePanels: PropTypes.func.isRequired,
    toggleActivePanel: PropTypes.func.isRequired,
    userSettings: PropTypes.objectOf(PropTypes.any).isRequired
};

function mapStateToProps(state) {
    return {
        activePanels: state.rightSideBarReducer.activePanels,
        userSettings: state.userReducer.settings
    };
}

function mapDispatchToProps(dispatch) {
    return {
        emptyActivePanels: () => emptyActivePanels(dispatch),
        toggleActivePanel: (panelName, enable) => toggleActivePanel(dispatch, panelName, enable)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MIRightSideBar);
