import React from 'react';
import PropTypes from 'prop-types';
import Cell from '../shared/cell';
import PanelSlider from 'components/htec-panel-slider/panel-slider';
import DatePickerPanel from './date-picker-panel';
import variables from '../variables';
import { MonthShape } from '../types';
import { compareMonths } from '../helpers';
/* styles */
import './date-picker.scss';

export default class DatePickerModeMonths extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            currentYear: props.currentYear
        };
    }

    getMonthProps = args => {
        let isSelected = false;
        let isToday;
        if (this.props.selectedDate) {
            isSelected = args.month === this.props.selectedDate.month && args.year === this.props.selectedDate.year;
        }
        const isDisabled = (this.props.minDate && compareMonths(args, this.props.minDate) === -1)
            || (this.props.maxDate && compareMonths(args, this.props.maxDate) === 1);
        if (this.props.today) {
            isToday = compareMonths(args, this.props.today) === 0;
        }
        return {
            isSelected,
            isDisabled,
            isToday
        };
    };

    handleYearChange = diff => {
        this.setState({ currentYear: this.state.currentYear + diff });
    };

    renderMonth = (args) => {
        return this.props.renderMonth({
            ...args,
            ...this.getMonthProps(args),
            onClick: this.props.onCellClick
        });
    };

    renderMonths = (year) => {
        const rows = [];
        let cells = [];
        let i = 0;
        const totalCells = 12;
        for (i; i < totalCells; i++) {
            cells.push(
                <td key={i}>{this.renderMonth({ month: i + 1, year })}</td>
            );
            if ((i + 1) % 3 === 0) {
                rows.push(
                    <tr key={i}>
                        {cells}
                    </tr>
                );
                cells = [];
            }
        }
        return rows;
    };

    renderMonthsPanel = (year) => {
        return (
            <DatePickerPanel
                key={year}
                onPanelTitleClick={this.props.onPanelTitleClick}
                title={year}
            >
                <table className="htec-dp-date-picker-mode">
                    <tbody>
                        {this.renderMonths(year)}
                    </tbody>
                </table>
            </DatePickerPanel>
        );
    };

    prevBtnDisabled = (diff) => {
        if (!this.props.disableYearsOutOfRange || !this.props.minDate) {
            return false;
        }
        return this.state.currentYear + (diff - 1) < this.props.minDate.year;
    };

    nextBtnDisabled = (diff) => {
        if (!this.props.disableYearsOutOfRange || !this.props.maxDate) {
            return false;
        }
        return this.state.currentYear + (diff + 1) > this.props.maxDate.year;
    };

    render = () => {
        const nextYear = this.state.currentYear + 1;
        const prevYear = this.state.currentYear - 1;
        return (
            <PanelSlider
                panels={[this.renderMonthsPanel(this.state.currentYear)]}
                panelsClass="htec-dp-date-picker-mode"
                onPanelChange={this.handleYearChange}
                nextPanel={this.renderMonthsPanel(nextYear)}
                prevPanel={this.renderMonthsPanel(prevYear)}
                nextBtn={this.props.nextBtn}
                prevBtn={this.props.prevBtn}
                nextBtnDisabled={this.nextBtnDisabled}
                prevBtnDisabled={this.prevBtnDisabled}
            />
        );
    }
}

DatePickerModeMonths.propTypes = {
    currentYear: PropTypes.number.isRequired,
    disableYearsOutOfRange: PropTypes.bool,
    maxDate: PropTypes.shape(MonthShape),
    minDate: PropTypes.shape(MonthShape),
    nextBtn: PropTypes.node,
    onCellClick: PropTypes.func.isRequired,
    onPanelTitleClick: PropTypes.func.isRequired,
    prevBtn: PropTypes.node,
    renderMonth: PropTypes.func,
    selectedDate: PropTypes.shape(MonthShape),
    today: PropTypes.shape(MonthShape).isRequired
};

DatePickerModeMonths.defaultProps = {
    disableYearsOutOfRange: false,
    maxDate: null,
    minDate: null,
    nextBtn: '►',
    prevBtn: '◄',
    renderMonth: (args) => {
        return <Cell {...args} title={variables.monthNames.short[args.month]} />;
    },
    selectedDate: null
};
