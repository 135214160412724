import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
/* router */
import { appRoutes } from 'app-router';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* services */
import CompanyService from 'services/core-api/company-service';
/* styles */
import './admin-company-management-company-box.scss';

const t = (key) => translate(`ADMIN.COMPANY_MANAGEMENT.COMPANY.${key}`);

class AdminCompanyManagementCompanyBox extends React.PureComponent {
    state = {
        image: null
    };

    componentDidMount() {
        this.setCompanyImage();
    }

    componentDidUpdate(prevProps) {
        if (this.props.company !== prevProps.company) {
            this.setCompanyImage();
        }
    }

    setCompanyImage = () => {
        const file = this.props.company.Files.find(file => file.TypeName === 'LogoDark');
        if (file) {
            CompanyService.Internal.getLogoByIdAndType(this.props.company.Id, { typeId: file.TypeId }).then((res) => {
                this.setState({ image: res });
            });
        } else {
            this.setState({ image: null });
        }
    };

    render() {
        const { company } = this.props;
        return (
            <div className="sten-panel">
                <div className="sten-panel__header flex-row">
                    <h3 className="flex-grow text-ellipsis">{company.Name}</h3>
                    {this.props.isEditable && (
                        <div className="flex-shrink">
                            <Link
                                className="btn-link icon icon-edit"
                                to={`${appRoutes.Admin.CompanyManagement}/company/edit/${company.Id}`}
                            />
                        </div>
                    )}
                </div>
                <div className="flex">
                    <div className="col-8 sten-admin-company-management-company-box__inner-box
                     sten-panel__separator sten-panel__separator--right"
                    >
                        {this.state.image ? (
                            <img
                                className="sten-admin-company-management-company-box__image"
                                src={this.state.image}
                                alt={company.Name}
                            />
                        ) : (
                            <span className="icon icon-image
                                sten-admin-company-management-company-box__image-placeholder"
                            />
                        )}
                    </div>
                    <div className="col-8 sten-admin-company-management-company-box__inner-box
                     sten-panel__separator sten-panel__separator--right"
                    >
                        <div>
                            <h5 className="text-secondary">{t('OFFICES')}</h5>
                            <div className="sten-admin-company-management-company-box__value">
                                <span className="icon icon-company" />
                                <span>{company.OfficesCount}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-8 sten-admin-company-management-company-box__inner-box">
                        <div>
                            <h5 className="text-secondary">{t('USERS')}</h5>
                            <div className="flex flex-center sten-admin-company-management-company-box__value">
                                <span className="icon icon-user" />
                                <span>{company.UsersCount}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AdminCompanyManagementCompanyBox.propTypes = {
    company: PropTypes.objectOf(PropTypes.any),
    isEditable: PropTypes.bool
};

AdminCompanyManagementCompanyBox.defaultProps = {
    company: null,
    isEditable: false
};

export default AdminCompanyManagementCompanyBox;
