import moment from 'moment';
import { takeEvery, all, put } from 'redux-saga/effects';
import { ActionTypes } from './trading-route-details-actions';
/* Services */
import MarketIntelService from 'services/core-api/market-intel-service';

const showFutureDays = 14;

function* getTradingRouteData(action) {
    const [latestPrediction, marketIntelData] = yield all([
        MarketIntelService.getLatestPrediction({
            ModelName: action.model,
            Route: action.routeCode
        }),
        MarketIntelService.getMarketIntelData({
            StartDate: moment().subtract(action.range.Value, action.range.Unit).format('YYYY-MM-DD'),
            EndDate: moment().add(showFutureDays, 'd').format('YYYY-MM-DD'),
            Route: action.routeCode,
            ModelName: action.model
        })
    ]);

    yield put({
        type: ActionTypes.TRADING_ROUTE_DETAILS_SET_DATA,
        data: marketIntelData,
        routeName: marketIntelData.RouteName
    });
    yield put({
        type: ActionTypes.TRADING_ROUTE_DETAILS_SET_LATEST_PREDICTION,
        latestPrediction
    });
}

function* getRouteModels() {
    const models = yield MarketIntelService.getModels();
    yield put({
        type: ActionTypes.TRADING_ROUTE_DETAILS_SET_MODELS,
        models
    });
}

function* getRanges() {
    const ranges = yield MarketIntelService.getRanges();
    yield put({
        type: ActionTypes.TRADING_ROUTE_DETAILS_SET_RANGES,
        ranges
    });
}

export default function* tradingRouteDetailsSaga() {
    yield takeEvery(ActionTypes.TRADING_ROUTE_DETAILS_GET_DATA, getTradingRouteData);
    yield takeEvery(ActionTypes.TRADING_ROUTE_DETAILS_GET_MODELS, getRouteModels);
    yield takeEvery(ActionTypes.TRADING_ROUTE_DETAILS_GET_RANGES, getRanges);
}
