import { takeEvery, put, select, delay } from 'redux-saga/effects';
/* actions */
import { ActionTypes } from './quarterly-report-actions';
/* selectors */
import { getTableData, getQuarterFromRange } from './quarterly-report-selectors';
/* services */
import EnergyManagementService from 'services/core-api/energy-management-service';
import LocalStorageService from 'services/local-storage-service';
/* export */
import generateQuarterlyXLSX from './export/quarterly-xlsx';

function* prepareReportDataForExport() {
    const selectedQuarter = yield select(state => getQuarterFromRange(state));
    const fileName = `Quarterly_Report_${selectedQuarter.year}_${selectedQuarter.quarter.Name}`;
    const tableData = yield select(state => getTableData(state));
    return { tableData, fileName, selectedQuarter };
}

function* getQuarterlyReport() {
    const { filters, selectedRange } = yield select(state => state.energyManagementReportsReducer);
    const params = {
        StartDate: selectedRange.rangeStart,
        EndDate: selectedRange.rangeEnd,
        IncludedVessels: Object.keys(filters.selectedVessels).map(imo => parseInt(imo, 10))
    };
    if (!LocalStorageService.isDebugModeActive()) {
        const report = yield EnergyManagementService.Reports.QuarterlyReport.getReport(params);
        yield put({ type: ActionTypes.ENERGY_MANAGEMENT_QUARTERLY_REPORT_SET, report });
    }
}

function* generateXLSX() {
    yield put({ type: 'TOGGLE_LOADER', payload: true });
    yield delay(0);
    const reportData = yield prepareReportDataForExport();
    yield generateQuarterlyXLSX(reportData);
    yield put({ type: 'TOGGLE_LOADER', payload: false });
}

export default function* energyManagementReportsQuarterlyReportSaga() {
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_QUARTERLY_REPORT_GENERATE, getQuarterlyReport);
    yield takeEvery(ActionTypes.ENERGY_MANAGEMENT_QUARTERLY_REPORT_GENERATE_XLSX, generateXLSX);
}
