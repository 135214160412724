import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import Input from 'components/input/input';
import Modal from 'components/modal/modal';
import Select from 'components/select/select';
import TextHighlight from 'components/text-highlight/text-highlight';
import Validation from 'components/validation/validation';
/* Services */
// import CompanyService from 'services/core-api/company-service';

const t = (key) => translate(`COMPANY_SELECT.${key}`);

class CompanySelect extends React.PureComponent {
    state = {
        isModalShown: false,
        newCompanyName: ''
    };

    toggleConfirmModal = (value) => {
        this.setState({
            isModalShown: !this.state.isModalShown,
            newCompanyName: value ? value.Name : ''
        });
    };

    setAsNewCompany = () => {
        if (this.props.onCompanyAdd) {
            this.props.onCompanyAdd(this.state.newCompanyName);
        }
        this.toggleConfirmModal();
    };

    closeModal = () => {
        this.setState({ isModalShown: false, newCompanyName: '' });
    };

    updateNewCompanyName = (value) => {
        this.setState({ newCompanyName: value });
    };

    validateCompanyName = {
        rule: (value) => {
            if (value) {
                const valueLowerCase = value.trim().toLowerCase();
                return !this.props.options.some(company => company.Name.trim().toLowerCase() === valueLowerCase);
            }
            return true;
        },
        hint: (value) => `${t('COMPANY_ALREADY_EXISTS', { company: value.trim() })}`
    };

    getOption = (option, searchCriteria) => {
        return (
            <div className="flex-row">
                <div className="flex-shrink text-ellipsis">
                    {option.Id === 'customInput' && (<div>{t('ADD_NEW_COMPANY')}</div>)}
                    <TextHighlight input={option.Name} highlight={searchCriteria} />
                </div>
                {option.IsQ88 && (
                    <div className="flex-shrink">
                        <div className="sten-badge sten-badge--success">external</div>
                    </div>
                )}
            </div>
        );
    };

    onChange = (value) => {
        if (value && value.Id === 'customInput') {
            this.toggleConfirmModal(value);
        } else {
            this.props.onChange(value);
        }
    };

    render() {
        return (
            <React.Fragment>
                <Modal.Default modalSize="xs" isOpen={this.state.isModalShown} onRequestClose={this.closeModal}>
                    <Validation.Form onSubmit={this.setAsNewCompany} stopPropagation>
                        <div className="sten-modal__header flex flex-center text-uppercase">
                            <div className="sten-modal__title flex-grow">{t('ADD_NEW_COMPANY')}</div>
                            <div className="flex-shrink">
                                <button type="button" className="btn-link icon icon-close" onClick={this.closeModal} />
                            </div>
                        </div>
                        <div className="sten-modal__body">
                            <div className="sten-content__section">
                                <label className="label">{t('COMPANY_NAME')}</label>
                                <Validation.Wrapper
                                    validations={{ required: true, minLength: 3, custom: this.validateCompanyName }}
                                >
                                    <Input
                                        autoFocus
                                        name="companyName"
                                        value={this.state.newCompanyName}
                                        onChange={this.updateNewCompanyName}
                                    />
                                </Validation.Wrapper>
                            </div>
                        </div>
                        <footer className="sten-modal__footer">
                            <div className="flex-grow flex-row">
                                <div className="flex-grow text-right">
                                    <button type="button" onClick={this.closeModal} className="btn btn--secondary">
                                        {translate('GLOBAL.CANCEL')}
                                    </button>
                                </div>
                                <div className="flex-shrink">
                                    <Validation.Button className="btn btn--primary">
                                        {translate('GLOBAL.SAVE')}
                                    </Validation.Button>
                                </div>
                            </div>
                        </footer>
                    </Validation.Form>
                </Modal.Default>
                <Select
                    {...this.props}
                    onChange={this.onChange}
                    optionRenderer={this.getOption}
                    valueKey="Id"
                    labelKey="Name"
                />
            </React.Fragment>
        );
    }
}

CompanySelect.propTypes = {
    ...Select.propTypes,
    onCompanyAdd: PropTypes.func
};

CompanySelect.defaultProps = {
    ...Select.defaultProps,
    onCompanyAdd: undefined
};

export default CompanySelect;
