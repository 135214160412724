import { createSelector } from 'reselect';
/* utils */
import { createQueryString } from 'utils/helpers/api-helper';
import createByIdSelector from 'utils/helpers/selector-helper';
import { findClosestTimestamp } from './dtn-weather-utils';
/* constants */
import {
    LAYER_DATA_TYPES,
    getWeatherLayerConfigs
} from './dtn-weather-constants';

const emptyArray = [];

export const getAvailableLayersMap = createByIdSelector(
    state => state.dtnWeatherReducer.availableLayers,
    'id'
);

export const getAvailableLayersByType = createSelector(
    state => state.dtnWeatherReducer.availableLayers,
    (availableLayers) => {
        const layersByType = {};
        const weatherLayerConfigs = getWeatherLayerConfigs();
        availableLayers.forEach(layer => {
            if (!layersByType[weatherLayerConfigs[layer.id].LayerValueType]) {
                layersByType[weatherLayerConfigs[layer.id].LayerValueType] = [];
            }
            layersByType[weatherLayerConfigs[layer.id].LayerValueType].push({
                ...layer,
                ...weatherLayerConfigs[layer.id]
            });
        });
        return layersByType;
    }
);

export const getSelectedLayerIds = createSelector(
    state => state.userReducer.settings?.MapWeatherLayerTileIdsV2,
    (layerIds) => {
        const weatherLayerConfigs = getWeatherLayerConfigs();
        return layerIds.filter(layerId => !!weatherLayerConfigs[layerId]) || emptyArray;
    }
);

export const getSelectedLayerOptions = createSelector(
    getSelectedLayerIds,
    getAvailableLayersMap,
    (layerIds, layerMap) => {
        const layers = [];
        const weatherLayerConfigs = getWeatherLayerConfigs();
        layerIds.forEach((layerId) => {
            if (layerMap?.[layerId]) {
                layers.push({
                    ...layerMap[layerId],
                    ...weatherLayerConfigs[layerId]
                });
            }
        });
        return layers.length ? layers : emptyArray;
    }
);

export const getSelectedLayerOptionsMap = createByIdSelector(
    getSelectedLayerOptions,
    'id'
);

const selectedLayerCache = {};

export const getSelectedLayers = createSelector(
    state => state.userReducer.settings,
    getSelectedLayerIds,
    getAvailableLayersMap,
    state => state.dtnWeatherReducer.layerData,
    state => state.dtnWeatherReducer.layerTimestamps,
    state => state.dtnWeatherReducer.selectedTimestamp,
    state => state.dtnWeatherReducer.roundedTimestamp,
    (settings, layerIds, layerMap, layerData, layerTimestamps, selectedTimestamp, roundedTimestamp) => {
        if (!settings
            || !settings.MapOptionsWeatherLayerEnabled
            || !layerIds.length
            || !layerMap
            || !layerData
            || !layerTimestamps
            || !selectedTimestamp
            || !roundedTimestamp) {
            return emptyArray;
        }
        const weatherLayerConfigs = getWeatherLayerConfigs();
        const selectedLayers = [];
        layerIds.forEach((layerId) => {
            if (layerTimestamps[layerId]) {
                let closestTS = weatherLayerConfigs[layerId].LayerDataType === LAYER_DATA_TYPES.FORECAST
                    && layerTimestamps[layerId]?.[roundedTimestamp]
                    ? roundedTimestamp
                    : null;
                if (!closestTS) {
                    closestTS = findClosestTimestamp(layerTimestamps[layerId], selectedTimestamp);
                }
                if (closestTS && layerData[layerId] && layerMap[layerId]) {
                    if (!selectedLayerCache[layerId]) {
                        selectedLayerCache[layerId] = {
                            ...layerMap[layerId],
                            ...layerData[layerId],
                            ...weatherLayerConfigs[layerId]
                        };
                    }
                    const runtime = layerTimestamps[layerId]?.[closestTS];
                    if (selectedLayerCache[layerId].selectedTimestamp !== closestTS
                        || selectedLayerCache[layerId].selectedRuntime !== runtime) {
                        const urlRuntime = weatherLayerConfigs[layerId].LayerDataType === LAYER_DATA_TYPES.FORECAST
                            ? `${runtime}/`
                            : '';
                        selectedLayerCache[layerId] = {
                            ...selectedLayerCache[layerId],
                            selectedTimestamp: closestTS,
                            selectedRuntime: runtime,
                            tileUrl: `${layerId}/${urlRuntime}{z}/{x}/{y}.pbf`
                                + `${createQueryString({ time: closestTS })}`
                        };
                    }
                    selectedLayers.push(selectedLayerCache[layerId]);
                }
            }
        });
        return selectedLayers.length ? selectedLayers : emptyArray;
    }
);
