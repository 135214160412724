import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* components */
import Chart from 'components/chart/chart';
/* helpers */
import { formatNumber, getSeverity } from 'utils/helpers/info-helper';
/* config */
import { getConsumptionCargoChartConfig } from '../../kpi-chart-config';
/* styles */
import './info-graph-panel.scss';

class EnergyManagementKPIInfoGraphPanel extends React.PureComponent {
    getSeverityClass = (value) => {
        let className = 'text-center';
        if (value) {
            className += ` ${getSeverity({ 'text-danger': '[0,Infinity)' }, value)}`;
        }
        return className;
    };

    getFormattedValue = (value, unit, showPlus = false) => {
        return value ? `${formatNumber(value, 1, showPlus)} ${unit}` : '-';
    };

    render() {
        return (
            <div className="sten-panel sten-energy-management-kpi-info-graph-panel">
                <div className="sten-panel__header flex">
                    <h2 className="text-uppercase flex-grow">{this.props.title}</h2>
                    {this.props.linkTo && (
                        <Link
                            className="btn-link icon icon-arrow-right"
                            to={this.props.router.getLinkTo({ pathname: this.props.linkTo })}
                            replace
                        />
                    )}
                </div>
                <div className="sten-panel__body flex">
                    <div className="flex-shrink">
                        <div className="flex-col sten-energy-management-kpi-info-graph-panel__info-boxes spread-v">
                            <div className="sten-energy-management-kpi-info-graph-panel__info-box text-center">
                                <div className="flex-grow">
                                    <span
                                        className="icon icon-oil-ballast"
                                        title={t('ENERGY_MANAGEMENT.KPI.INFO_GRAPH_SECTION.BALLAST_CONSUMPTION')}
                                    />
                                    <h5 className={this.getSeverityClass(this.props.infoData.BallastKPIRatio)}>
                                        {this.getFormattedValue(this.props.infoData.BallastKPIRatio, '%', true)}
                                    </h5>
                                </div>
                            </div>
                            <div className="sten-energy-management-kpi-info-graph-panel__info-box text-center">
                                <div className="flex-grow">
                                    <span
                                        className="icon icon-oil-laden"
                                        title={t('ENERGY_MANAGEMENT.KPI.INFO_GRAPH_SECTION.LADEN_CONSUMPTION')}
                                    />
                                    <h5 className={this.getSeverityClass(this.props.infoData.LadenKPIRatio)}>
                                        {this.getFormattedValue(this.props.infoData.LadenKPIRatio, '%', true)}
                                    </h5>
                                </div>
                            </div>
                            <div className="sten-energy-management-kpi-info-graph-panel__info-box text-center">
                                <div className="flex-grow">
                                    <span
                                        className="icon icon-cargo"
                                        title={t('ENERGY_MANAGEMENT.KPI.INFO_GRAPH_SECTION.AVERAGE_CARGO')}
                                    />
                                    <h5 className="text-center">
                                        {this.getFormattedValue(this.props.infoData.WeightedCargoRatio, '%', true)}
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex-grow">
                        <Chart
                            className="sten-energy-management-kpi-info-graph-panel__graph"
                            config={getConsumptionCargoChartConfig()}
                            series={this.props.chartData.series}
                            categories={this.props.chartData.categories}
                            options={{
                                xAxis: {
                                    labels: {
                                        enabled: false
                                    },
                                    tickLength: 0
                                }
                            }}
                            isChart
                        />
                    </div>
                </div>
            </div>
        );
    }
}

EnergyManagementKPIInfoGraphPanel.propTypes = {
    chartData: PropTypes.objectOf(PropTypes.any).isRequired,
    infoData: PropTypes.objectOf(PropTypes.any).isRequired,
    linkTo: PropTypes.string,
    router: TRouter.isRequired,
    title: PropTypes.string
};

EnergyManagementKPIInfoGraphPanel.defaultProps = {
    linkTo: '',
    title: ''
};

export default withRouter(EnergyManagementKPIInfoGraphPanel);
