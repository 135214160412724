import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { getObjectProp, getClassName, setObjectProp } from 'utils/helpers/info-helper';
/* components */
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
import Input from 'components/input/input';
import Validation from 'components/validation/validation';
/* styles */
import './column-values-table.scss';
import withNumberFormat from '../with-number-format-hoc/with-number-fotmat-hoc';

export class ColumnValuesTable extends React.PureComponent {
    getChangeHandler = (dataIndex, prop) => (value) => {
        this.props.onChange(
            setObjectProp([...this.props.data], `${dataIndex}.${prop}`, value, true)
        );
    };

    render() {
        const {
            className,
            fixedHeaderTableProps,
            isEditing,
            name,
            data,
            rowConfig,
            renderLabel,
            renderHeaderRow,
            title,
            formatValue
        } = this.props;
        if (!data) {
            return null;
        }
        return (
            <FixedHeaderTable
                className="sten-column-values-table"
                contentStyle={{ minWidth: `${8 + (data.length * 6)}rem` }}
                {...fixedHeaderTableProps}
                withHeaderColumn
            >
                <table className={getClassName('sten-table sten-table--xs', className)}>
                    <thead>
                        {renderHeaderRow ? renderHeaderRow(data) : null}
                        <tr>
                            <th className="text-secondary">{title}</th>
                            {data.map((item, index) => (
                                <th
                                    className="text-secondary"
                                    /* eslint-disable-next-line react/no-array-index-key */
                                    key={index}
                                    style={{ width: `${100 / data.length}%` }}
                                >
                                    {renderLabel(item)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rowConfig.map(row => (
                            <tr key={row.prop}>
                                <th className="text-secondary">{row.label}</th>
                                {data.map((item, index) => {
                                    if (!isEditing) {
                                        return (
                                            /* eslint-disable-next-line react/no-array-index-key */
                                            <td key={index}>
                                                {formatValue(getObjectProp(item, row.prop), row.formatProps)}
                                            </td>
                                        );
                                    }
                                    return (
                                        /* eslint-disable-next-line react/no-array-index-key */
                                        <td key={index} className="sten-table__td--has-input">
                                            <Validation.Wrapper hintsOnTop hintsOnHover validations={row.validations}>
                                                <Input
                                                    className="sten-input--sm"
                                                    name={`${name}.${index}.${row.prop}`}
                                                    value={getObjectProp(item, row.prop)}
                                                    onChange={this.getChangeHandler(index, row.prop)}
                                                />
                                            </Validation.Wrapper>
                                        </td>
                                    );
                                })}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </FixedHeaderTable>
        );
    }
}

ColumnValuesTable.propTypes = {
    className: PropTypes.string,
    rowConfig: PropTypes.arrayOf(PropTypes.shape({
        prop: PropTypes.string,
        label: PropTypes.string,
        formatProps: PropTypes.objectOf(PropTypes.any),
        validation: PropTypes.objectOf(PropTypes.any)
    })).isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
    fixedHeaderTableProps: PropTypes.objectOf(PropTypes.any),
    isEditing: PropTypes.bool,
    name: PropTypes.string,
    onChange: PropTypes.func,
    renderLabel: PropTypes.func.isRequired,
    renderHeaderRow: PropTypes.func,
    title: PropTypes.string,
    formatValue: PropTypes.func
};

ColumnValuesTable.defaultProps = {
    className: '',
    data: null,
    fixedHeaderTableProps: null,
    isEditing: false,
    name: '',
    onChange: undefined,
    renderHeaderRow: undefined,
    title: '',
    formatValue: (val) => val
};

export const ColumnNumberValuesTable = withNumberFormat(ColumnValuesTable);
export default ColumnValuesTable;
