import moment from 'moment';
import { formatCoordinates } from 'utils/helpers/info-helper';
import { wktParse } from 'components/ol/ol-helpers';

export const getVesselStartingPointByType = (vessel, stateStartingPoint, type) => {
    let startingPoint = { ...stateStartingPoint, IsChanged: true };
    if (startingPoint.Point && !startingPoint.Point.Type) {
        startingPoint.Point.Type = 3;
    }
    if (type === 2) {
        startingPoint = {
            ...stateStartingPoint,
            Point: { ...vessel.NextOpenPort, IsCustomPoint: false },
            Eta: vessel.VoyageEndDate,
            Atd: null,
            Etd: null,
            IsBallast: true,
            IsChanged: true
        };
    } else if (type === 1) {
        const Eta = moment.utc().add(1, 'h').startOf('h');
        if (vessel.Vessel && vessel.Vessel.TimeZoneId) {
            const offset = moment.tz(vessel.Vessel.TimeZoneId).utcOffset();
            Eta.add(offset, 'm');
        }
        const location = wktParse(vessel.Location);
        startingPoint = {
            ...stateStartingPoint,
            Point: {
                Name: formatCoordinates(location[0], location[1]),
                Location: vessel.Location,
                TimeZoneId: vessel.Vessel.TimeZoneId,
                IsCustomPoint: true
            },
            TimeInPort: '',
            Eta: Eta.toISOString(),
            Atd: null,
            Etd: null,
            IsBallast: !vessel.Vessel.IsLaden,
            IsChanged: true
        };
    }
    return startingPoint;
};

export const getStartingPositionOptionsForVessel = (options, vessel, currentVoyage, stateStartingPoint) => {
    let startingPositionOptions = options;
    if (vessel) {
        startingPositionOptions = startingPositionOptions.map(option => (
            {
                ...option,
                disabled: (option.value === 1 && !vessel.Location)
                    || (option.value === 2 && (!vessel.NextOpenPort || !vessel.NextOpenPort.Location))
                    || (option.value === 3 && !currentVoyage)
            }
        ));
    }
    let startingPoint;
    let calculationVesselStartingPosition = startingPositionOptions[0];
    if (!startingPositionOptions[1].disabled) {
        calculationVesselStartingPosition = startingPositionOptions[1];
        startingPoint = getVesselStartingPointByType(vessel, stateStartingPoint, 1);
    } else if (!startingPositionOptions[2].disabled) {
        calculationVesselStartingPosition = startingPositionOptions[2];
        startingPoint = getVesselStartingPointByType(vessel, stateStartingPoint, 2);
    } else {
        startingPoint = getVesselStartingPointByType(vessel, stateStartingPoint, 0);
    }
    return {
        startingPositionOptions,
        startingPoint,
        calculationVesselStartingPosition
    };
};

export const getNewPoint = (CalculationPointId, Speed, WeatherFactor) => ({
    Point: null,
    Ata: null,
    Atd: null,
    Eta: null,
    Etd: null,
    EtaUpdated: false,
    CurrentConsumption: null,
    SpeedUpdated: false,
    IsSpeedSelected: true,
    IsBallast: false,
    IsChanged: true,
    IsExpanded: false,
    Speed,
    WeatherFactor,
    TimeInPort: '',
    CalculationPointId
});

export const extractBunkerOptionsAndValues = allFuelTypes => {
    let bunkerOptions = [];
    let selectedEcaBunker = null;
    let selectedNonEcaBunker = null;
    if (allFuelTypes?.length) {
        bunkerOptions = allFuelTypes;
        selectedEcaBunker = bunkerOptions.find(opt => opt.Default && opt.ECA);
        selectedNonEcaBunker = bunkerOptions.find(opt => opt.Default && !opt.ECA);
    }
    return {
        bunkerOptions,
        selectedEcaBunker: selectedEcaBunker || null,
        selectedNonEcaBunker: selectedNonEcaBunker || null
    };
};
