import React from 'react';
import { translate } from 'utils/i18n/i18n-model';

const t = (key) => translate(`FLEET_MANAGEMENT.${key}`);

export const badgeMap = {
    Dynamic: { Id: 0, Name: t('DYNAMIC'), ShortName: 'D' },
    Static: { Id: 1, Name: t('STATIC'), ShortName: 'S' },
    Shared: { Id: 2, Name: translate('GLOBAL.SHARED'), ShortName: '' }
};

export const renderBadges = (fleet, showShared = true) => {
    if (!fleet) {
        return null;
    }
    const badges = [fleet.IsDynamic ? badgeMap.Dynamic : badgeMap.Static];
    if (fleet.IsPublic && showShared) {
        badges.unshift(badgeMap.Shared);
    }
    return badges.map(badge => (
        <div
            key={badge.Id}
            className="sten-fleet-management__badge sten-badge sten-badge--secondary"
            title={badge.Name}
        >
            {badge.ShortName || badge.Name}
        </div>
    ));
};

export const renderCreatedBy = (fleet) => (
    <p className="text-secondary">
        {`${translate('GLOBAL.CREATED_BY')}: ${fleet.OwnerFirstName} ${fleet.OwnerLastName}`}
    </p>
);
