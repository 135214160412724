import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { getClassName } from 'utils/helpers/info-helper';
/* components */
import FlipSwitch from 'components/flip-switch/flip-switch';
import Input from 'components/input/input';
import Select from 'components/select/select';
import Validation from 'components/validation/validation';
/* services */
import DynamicFleetService from 'services/core-api/dynamic-fleet-service';

const t = (key, values) => translate(`FLEET_MANAGEMENT.ATTRIBUTES.${key}`, values);

const emptyArray = [];

const valueTypeImport = {
    StringValue: (value) => value,
    BoolValue: (value) => value === 'true',
    RangeValue: (value) => {
        if (!value) {
            return { min: '', max: '' };
        }
        const splitValue = value.split(',');
        return { min: splitValue[0] || '', max: splitValue[1] || '' };
    },
    IdValue: (value, options) => (value && options ? options.find(op => op.Id === parseInt(value, 10)) : null)
};

const attributeValidationFields = [
    'MainAttribute', 'SecondaryAttribute', 'MaxValueAttribute', 'MinValueAttribute', 'ValueAttribute'
];

const stringValueValidations = { required: true, maxLength: 50 };

const getRangeValidations = (max) => ({
    min: { required: true, numeric: { min: 0, max, maxProp: 'MaxValueAttribute' } },
    max: { required: true, numeric: { min: 0, max, minProp: 'MinValueAttribute' } }
});

const secondaryAttributeIdTypeMap = {
    1: { name: 'Coating type', type: 'StringValue', validations: stringValueValidations },
    2: { name: 'Ship type', type: 'StringValue', validations: stringValueValidations },
    3: { name: 'Flag', type: 'StringValue', validations: stringValueValidations },
    4: { name: 'Loa', type: 'RangeValue', validations: getRangeValidations(1000) },
    5: { name: 'DWT', type: 'RangeValue', validations: getRangeValidations(1000000) },
    6: { name: 'Total Cubic Capacity', type: 'RangeValue', validations: getRangeValidations(1000000) },
    7: { name: 'Has Scrubber', type: 'BoolValue' },
    8: { name: 'Active Voyage', type: 'IdValue' },
    9: { name: 'Any Voyage', type: 'IdValue' },
    10: { name: 'Active Voyage By Operator', type: 'StringValue', validations: stringValueValidations },
    11: { name: 'Voyage By Cargo Charterer', type: 'StringValue', validations: stringValueValidations }
};

const initialState = {
    mainAttribute: null,
    secondaryAttribute: null,
    valueAttribute: null,
    secondaryAttributeOptions: emptyArray,
    valueAttributeOptions: emptyArray
};

class FleetAttributes extends React.Component {
    state = initialState;

    componentMounted = false;

    componentDidMount() {
        this.componentMounted = true;
        this.handleAttributeIdsChange();
    }

    componentDidUpdate(prevProps) {
        this.handleAttributeIdsChange(prevProps);
    }

    componentWillUnmount() {
        this.componentMounted = false;
    }

    handleMainAttributeIdChange = (mainAttributeId, secondaryAttributeId = null) => {
        if (mainAttributeId !== null) {
            DynamicFleetService.getSecondaryAttributes(mainAttributeId).then(res => {
                if (this.componentMounted) {
                    const newState = {
                        mainAttribute: this.props.mainAttributeOptions.find(option => option.Id === mainAttributeId),
                        secondaryAttributeOptions: res
                    };
                    if (secondaryAttributeId !== null) {
                        newState.secondaryAttribute = res.find(option => option.Id === secondaryAttributeId);
                    }
                    this.setState(newState);
                }
            });
        } else {
            this.setState({ mainAttribute: null, secondaryAttributeOptions: emptyArray });
        }
    }

    handleSecondaryAttributeIdChange = (secondaryAttributeId, valueAttribute = null) => {
        if (secondaryAttributeId !== null) {
            const valueType = secondaryAttributeIdTypeMap[secondaryAttributeId];
            const newState = { valueAttributeOptions: emptyArray };
            if (this.state.secondaryAttributeOptions.length) {
                newState.secondaryAttribute = this.state.secondaryAttributeOptions
                    .find(option => option.Id === secondaryAttributeId);
            }
            if (valueType && valueType.type === 'IdValue') {
                DynamicFleetService.getValueAttributes(secondaryAttributeId).then(res => {
                    if (this.componentMounted) {
                        newState.valueAttributeOptions = res;
                        if (valueAttribute !== null) {
                            newState.valueAttribute = valueTypeImport[valueType.type](valueAttribute, res);
                        }
                        this.setState(newState);
                    }
                });
            } else {
                if (valueAttribute !== null) {
                    newState.valueAttribute = valueTypeImport[valueType.type](valueAttribute);
                }
                this.setState(newState);
            }
        } else {
            this.setState({ secondaryAttribute: null, valueAttributeOptions: emptyArray });
        }
    }

    handleValueAttributeChange = (valueAttribute, secondaryAttributeId) => {
        const valueType = secondaryAttributeId !== null ? secondaryAttributeIdTypeMap[secondaryAttributeId] : null;
        this.setState({
            valueAttribute: valueType
                ? valueTypeImport[valueType.type](valueAttribute, this.state.valueAttributeOptions)
                : null
        });
    };

    handleAttributeIdsChange = (prevProps) => {
        if (this.props.mainAttributeOptions.length > 0
            && (!prevProps
                || prevProps.mainAttributeId !== this.props.mainAttributeId
                || prevProps.mainAttributeOptions !== this.props.mainAttributeOptions)) {
            this.handleMainAttributeIdChange(this.props.mainAttributeId, this.props.secondaryAttributeId);
        }
        if (!prevProps || prevProps.secondaryAttributeId !== this.props.secondaryAttributeId) {
            this.handleSecondaryAttributeIdChange(this.props.secondaryAttributeId, this.props.valueAttribute);
        }
        if (!prevProps || prevProps.valueAttribute !== this.props.valueAttribute) {
            this.handleValueAttributeChange(this.props.valueAttribute, this.props.secondaryAttributeId);
        }
    };

    handleMainAttributeChange = (val) => {
        if (this.props.onChange) {
            const newValue = val ? val.Id : val;
            if (this.props.mainAttributeId !== val) {
                this.setState({ secondaryAttribute: null, valueAttribute: null }, () => {
                    this.props.onChange({
                        DynamicFleetMainAttributeId: newValue,
                        DynamicFleetSecondaryAttributeId: null,
                        DynamicFleetValueAttribute: null
                    });
                });
            }
        }
    };

    handleSecondaryAttributeChange = (val) => {
        if (this.props.onChange) {
            const newValue = val ? val.Id : val;
            if (this.props.secondaryAttributeId !== val) {
                this.setState({ valueAttribute: null }, () => {
                    this.props.onChange({
                        DynamicFleetSecondaryAttributeId: newValue,
                        DynamicFleetValueAttribute:
                            newValue && secondaryAttributeIdTypeMap[newValue].type === 'BoolValue' ? 'false' : null
                    });
                });
            }
        }
    };

    handleValueChange = (valueType, valueProp) => (val) => {
        if (this.props.onChange) {
            if (valueType.type === 'RangeValue') {
                const valueAttribute = {
                    ...this.state.valueAttribute,
                    [valueProp]: val
                };
                if (valueAttribute.min && valueAttribute.max) {
                    this.props.onChange({
                        DynamicFleetValueAttribute: `${valueAttribute.min},${valueAttribute.max}`
                    });
                } else {
                    this.setState({ valueAttribute });
                }
            } else if (valueType.type === 'IdValue') {
                this.props.onChange({ DynamicFleetValueAttribute: val ? `${val.Id}` : '' });
            } else {
                this.props.onChange({ DynamicFleetValueAttribute: `${val}` });
            }
        }
    };

    handleApplyClick = (context) => {
        const errors = context.validateAll();
        const hasErrors = attributeValidationFields.some(field => !!errors[field]);
        if (!hasErrors && this.props.onApply) {
            this.props.onApply();
        }
    };

    getButtonErrors = (context) => {
        return context.showErrors
            ? Object.keys(context.errors).filter(error => attributeValidationFields.indexOf(error) > -1).length
            : 0;
    };

    handleResetClick = () => {
        this.props.onChange({
            DynamicFleetMainAttributeId: null,
            DynamicFleetSecondaryAttributeId: null,
            DynamicFleetValueAttribute: null
        });
    };

    requiredValidation = { required: true };

    renderWithValidation = (content, validations = this.requiredValidation) => {
        if (this.props.isEditable) {
            return (
                <Validation.Wrapper hintsOnHover validations={validations}>
                    {content}
                </Validation.Wrapper>
            );
        }
        return content;
    }

    renderValueFields = (valueType, value, options, isEditable) => {
        switch (valueType.type) {
        case 'StringValue':
            return (
                <React.Fragment>
                    <label className="label">{t('VALUE')}</label>
                    {this.renderWithValidation((
                        <Input
                            disabled={!isEditable}
                            name="ValueAttribute"
                            value={value}
                            onChange={this.handleValueChange(valueType)}
                            placeholder={t('VALUE_PLACEHOLDER')}
                        />
                    ), valueType.validations)}
                </React.Fragment>
            );
        case 'RangeValue':
            return (
                <div className="flex-row">
                    <div className="col-12">
                        <label className="label">{t('MIN')}</label>
                        {this.renderWithValidation((
                            <Input
                                disabled={!isEditable}
                                name="MinValueAttribute"
                                value={value ? value.min : ''}
                                onChange={this.handleValueChange(valueType, 'min')}
                            />
                        ), valueType.validations.min)}
                    </div>
                    <div className="col-12">
                        <label className="label">{t('MAX')}</label>
                        {this.renderWithValidation((
                            <Input
                                disabled={!isEditable}
                                name="MaxValueAttribute"
                                value={value ? value.max : ''}
                                onChange={this.handleValueChange(valueType, 'max')}
                            />
                        ), valueType.validations.max)}
                    </div>
                </div>
            );
        case 'BoolValue':
            return (
                <React.Fragment>
                    <label className="label">{t('VALUE')}</label>
                    <FlipSwitch
                        disabled={!isEditable}
                        activeLabel={translate('GLOBAL.YES')}
                        inactiveLabel={translate('GLOBAL.NO')}
                        onChange={this.handleValueChange(valueType)}
                        value={!!value}
                    />
                </React.Fragment>
            );
        case 'IdValue':
            return (
                <React.Fragment>
                    <label className="label">{t('VALUE')}</label>
                    {this.renderWithValidation(
                        <Select
                            clearable
                            searchable
                            disabled={!isEditable}
                            name="ValueAttribute"
                            value={value}
                            valueKey="Id"
                            labelKey="Name"
                            options={options}
                            onChange={this.handleValueChange(valueType)}
                            placeholder={t('VALUE_PLACEHOLDER')}
                        />
                    )}
                </React.Fragment>
            );
        default:
            return null;
        }
    };

    render() {
        const { className, isEditable, mainAttributeOptions, secondaryAttributeId } = this.props;
        const {
            mainAttribute,
            secondaryAttribute,
            valueAttribute,
            secondaryAttributeOptions,
            valueAttributeOptions
        } = this.state;
        const valueType = secondaryAttributeId ? secondaryAttributeIdTypeMap[secondaryAttributeId] : null;
        return (
            <div className={getClassName('sten-content__section sten-fleet-management-fleet-attributes', className)}>
                <div className="flex-row flex-row--md flex-end">
                    <div className="col-md-12 col-xs-24">
                        <div className="row">
                            <div className="col-sm-12 col-xs-24">
                                <label className="label text-nowrap">{t('MAIN_ATTRIBUTE')}</label>
                                {this.renderWithValidation(
                                    <Select
                                        clearable
                                        searchable
                                        disabled={!isEditable}
                                        name="MainAttribute"
                                        value={mainAttribute}
                                        valueKey="Id"
                                        labelKey="DisplayName"
                                        options={mainAttributeOptions}
                                        onChange={this.handleMainAttributeChange}
                                        placeholder={t('MAIN_ATTRIBUTE_PLACEHOLDER')}
                                    />
                                )}
                            </div>
                            <div className="col-sm-12 col-xs-24 form-row--xs">
                                <label className="label text-nowrap">{t('SECONDARY_ATTRIBUTE')}</label>
                                {this.renderWithValidation(
                                    <Select
                                        clearable
                                        searchable
                                        name="SecondaryAttribute"
                                        disabled={!mainAttribute || !isEditable}
                                        value={secondaryAttribute}
                                        valueKey="Id"
                                        labelKey="DisplayName"
                                        options={secondaryAttributeOptions}
                                        onChange={this.handleSecondaryAttributeChange}
                                        placeholder={mainAttribute
                                            ? t('SECONDARY_ATTRIBUTE_PLACEHOLDER')
                                            : t('SECONDARY_ATTRIBUTE_DISABLED_PLACEHOLDER')}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {!valueType
                        ? <div className="flex-grow" />
                        : (
                            <div className="col-md-6 col-xs-24 form-row--sm">
                                {this.renderValueFields(valueType, valueAttribute, valueAttributeOptions, isEditable)}
                            </div>
                        )
                    }
                    {isEditable && (
                        <div className="col-md-6 col-xs-24 form-row--sm">
                            <div className="row">
                                <div className="col-12">
                                    <button
                                        type="button"
                                        className="btn btn--secondary col-24"
                                        onClick={this.handleResetClick}
                                    >
                                        {translate('GLOBAL.RESET')}
                                    </button>
                                </div>
                                <div className="col-12">
                                    <Validation.Button
                                        type="button"
                                        className="btn btn--primary col-24"
                                        onClick={this.handleApplyClick}
                                        getErrors={this.getButtonErrors}
                                    >
                                        {translate('GLOBAL.PREVIEW')}
                                    </Validation.Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

FleetAttributes.propTypes = {
    className: PropTypes.string,
    isEditable: PropTypes.bool,
    mainAttributeId: PropTypes.number,
    mainAttributeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    onApply: PropTypes.func,
    onChange: PropTypes.func,
    secondaryAttributeId: PropTypes.number,
    valueAttribute: PropTypes.string
};

FleetAttributes.defaultProps = {
    className: '',
    isEditable: false,
    mainAttributeId: null,
    onApply: undefined,
    onChange: undefined,
    secondaryAttributeId: null,
    valueAttribute: ''
};

export default FleetAttributes;
