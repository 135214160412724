import React from 'react';
import ReactTestUtils from 'react-dom/test-utils';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import fastDeepEqual from 'fast-deep-equal';
/* helpers */
import { translate } from 'utils/i18n/i18n-model';
import { getNotificationProps } from 'utils/helpers/notification-helper';
import {
    wktParse,
    getImportanceByRelativeZoomLevel,
    convertCoordinates,
    normalizeCoordinates,
    mapCurrentAisLonLat
} from 'components/ol/ol-helpers';
/* services */
import ConfigService from 'services/config-api/config-service';
/* selectors */
import {
    getCalculationRoute,
    getCalculationRoutePoints,
    getCalculationVesselPosition,
    getCalculationVoyageRoute,
    getCalculationVoyageRoutePoints,
    getFetchedPorts,
    getHistoricalRoute,
    getPins
} from '../emission-estimator-page-selectors';
import { getCalculationPoints, getCalculationVoyageAisPoints } from '../estimator/estimator-selectors';
/* actions */
import { getPorts } from '../emission-estimator-page-actions';
import { setVesselDetailsTooltipPosition } from 'components/vessel-details-tooltip/vessel-details-tooltip-actions';
import { emptyActivePanels } from 'components/right-side-bar/right-side-bar-actions';
import { setCustomCalculationPoint, setActivePinPointId, setClickedPointData } from '../estimator/estimator-actions';
import { setLocationTooltipCoordinates } from 'components/map-location-tooltip/map-location-tooltip-actions';
import { getSelectedAreas } from 'components/map-options/map-options-actions';
/* components */
import DtnWeather from 'components/dtn-weather/dtn-weather';
import MapLocationTooltip from 'components/map-location-tooltip/map-location-tooltip';
import NauticalCharts from 'components/nautical-charts/nautical-charts';
import Ol from 'components/ol/ol';
import Tooltip from 'components/tooltip/tooltip';
import VesselDetailsTooltip from 'components/vessel-details-tooltip/vessel-details-tooltip';
import WniWeather from 'components/wni-weather/wni-weather';
/* config */
import config from './emission-estimator-map-config';

const animationLength = 600;

const mapViewProps = {
    zoom: 3
};

class EmissionEstimatorMap extends React.PureComponent {
    state = {
        mapZoomControlElement: null
    };

    vesselOverlays = {};

    animationStartTime = null;

    componentDidMount() {
        this.props.getSelectedAreas();
        this.setState({ mapZoomControlElement: document.getElementById('stenMapZoomControls') });
        this.fitElementsOnMap();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isCalculatorFullScreen !== this.props.isCalculatorFullScreen) {
            window.requestAnimationFrame(this.updateSizeOnFrame);
        }
        if (prevProps.arePortsInvisible === true && this.props.arePortsInvisible === false) {
            this.getPorts(true);
        }
        if (prevProps.calculationPoints !== this.props.calculationPoints
            || prevProps.calculationVoyageAisPoints !== this.props.calculationVoyageAisPoints
            || prevProps.calculationVesselPosition !== this.props.calculationVesselPosition
            || prevProps.calculationRoute !== this.props.calculationRoute
            || prevProps.calculationVoyageRoute !== this.props.calculationVoyageRoute) {
            this.fitElementsOnMap();
        }
    }

    fitElementsOnMap = () => {
        let lineString = [];
        if (this.props.calculationVoyageAisPoints.length > 0) {
            lineString = [...lineString, ...this.props.calculationVoyageAisPoints];
        }
        if (this.props.calculationVesselPosition && this.props.calculationVesselPosition.CurrentAis) {
            lineString.push([
                this.props.calculationVesselPosition.CurrentAis.Longitude,
                this.props.calculationVesselPosition.CurrentAis.Latitude
            ]);
        }
        if (this.props.calculationRoute.length > 0) {
            lineString = [...lineString, ...this.props.calculationRoute];
        } else if (this.props.calculationPoints) {
            this.props.calculationPoints.forEach((point) => {
                if (point.Point && point.Point.Location) {
                    if (this.props.calculationVoyageAisPoints.length > 0) {
                        if (!point.Ata) {
                            lineString.push(wktParse(point.Point.Location));
                        }
                    } else {
                        lineString.push(wktParse(point.Point.Location));
                    }
                }
            });
        }
        if (this.props.calculationVoyageRoute.length > 0) {
            lineString = [...lineString, ...this.props.calculationVoyageRoute];
        }
        if (lineString.length === 1) {
            this.map.setCenter(lineString[0]);
        } else if (lineString.length > 1) {
            this.map.fitElements(lineString, { padding: [40, 80, 40, 80], duration: 500 });
        }
    };

    updateSizeOnFrame = (timestamp) => {
        if (!this.animationStartTime) {
            this.animationStartTime = timestamp;
        }
        if (timestamp - this.animationStartTime < animationLength) {
            this.map.updateSize();
            window.requestAnimationFrame(this.updateSizeOnFrame);
        } else {
            this.animationStartTime = null;
        }
    };

    handleMapHover = (event, hoveredFeature) => {
        let allowHover = true;
        if (this.props.activePinPointId !== null) {
            if (event.pixel && event.coordinate) {
                this.props.setLocationTooltipCoordinates(
                    event.pixel, normalizeCoordinates(convertCoordinates(event.coordinate))
                );
            } else {
                this.props.setLocationTooltipCoordinates([], []);
            }
            if (hoveredFeature
                && (hoveredFeature.get('layerName') === 'vessels' || hoveredFeature.get('layerName') === 'ports')) {
                allowHover = false;
            }
        }
        if (this.wniWeatherTooltipRef) {
            this.wniWeatherTooltipRef.update(event, hoveredFeature);
        }
        if (this.lastHoveredFeature !== hoveredFeature) {
            this.lastHoveredFeature = hoveredFeature;
        }
        return allowHover;
    };

    handleMapClick = (event, clickedFeature) => {
        if ((!clickedFeature || clickedFeature.get('layerName') !== 'ports') && this.props.activePinPointId !== null) {
            this.props.setCustomCalculationPoint(normalizeCoordinates(convertCoordinates(event.coordinate)));
        }
        if (!clickedFeature) {
            this.props.closeRightSidebar();
            return false;
        }
        return true;
    };

    handleMapPostRender = () => {
        if (this.map) {
            this.getPorts();
            this.setVesselDetailsTooltipPosition();
        }
    };

    /* ports */
    getPorts(forceGetPorts = false) {
        if (forceGetPorts) {
            this.forceGetPorts = true;
        }
        if (!this.map) {
            return;
        }
        const relativeZoom = this.map.getRelativeZoom();
        const relativeZoomLevel = relativeZoom < 2 ? 1 : relativeZoom - 1;
        const importanceLevel = getImportanceByRelativeZoomLevel(relativeZoomLevel);

        if (this.forceGetPorts || importanceLevel !== this.currentImportanceLevel) {
            this.currentImportanceLevel = importanceLevel;
            this.props.getPorts({
                ImportanceLevel: importanceLevel,
                BBox: 'MULTIPOLYGON(((-180 -90,180 -90,180 90,-180 90,-180 -90)))'
            });
            this.forceGetPorts = false;
        }
    }

    getPortPrimaryColor = port => {
        if (port.selectedInVoyage && !!port.Atd) {
            return config.port.primaryColor;
        }
        return config.port.secondaryColor;
    };

    getPortSecondaryColor = (port, isHovered) => {
        if (isHovered || port.selectedInVoyage) {
            return config.port.secondaryColor;
        }
        return null;
    };

    getPortHidden = port => {
        return this.props.arePortsInvisible && !port.selectedInVoyage;
    };

    getPortLabel = port => {
        let label = '';
        if (port) {
            label = port.Name;
            if (port.selectedInVoyage && port.Activities && port.Activities.length > 0) {
                label += ` (${port.Activities.map(activity => activity.Code).join(',')})`;
            }
        }
        return label;
    };

    getPortHideLabel = (port, isHovered, zoomLevel) => {
        return !port.selectedInVoyage && !isHovered && config.port.labelMinZoomLevel > zoomLevel;
    };

    getPortOpacity = (port, isHovered) => {
        if (isHovered) {
            return 1;
        }
        if (port.selectedInVoyage) {
            return 0.8;
        }
        return 0.3;
    };

    getPortZIndex = (port, isHovered) => {
        const zIndex = port.ImportanceLevel || 0;
        if (isHovered) {
            return zIndex + 21;
        }
        if (port.selectedInVoyage) {
            return zIndex + 20;
        }
        return zIndex;
    };

    onPortClick = portFeature => {
        if (this.props.activePinPointId !== null) {
            return;
        }
        if (portFeature && portFeature.data && portFeature.data.Id) {
            this.props.setClickedPointData(portFeature.data.Id);
        }
    };
    /* /ports */

    /* pins */
    getPinOpacity = (pin, isHovered) => {
        if (pin && pin.pointId === this.props.activePinPointId) {
            return 0.3;
        }
        return isHovered ? 1 : 0.8;
    };

    onPinClick = pin => {
        this.props.setActivePinPointId(pin.data.pointId);
    };
    /* /pins */

    /* vessels */
    getVesselColor = vessel => {
        if (vessel.LatestAlert) {
            return config.vessel.alertColor;
        }
        if (vessel.RelationshipType === 'TimeCharter') {
            return config.vessel.quaternaryColor;
        }
        if (vessel.RelationshipType === 'CargoOwner' || vessel.IsDedicatedVessel) {
            return config.vessel.tertiaryColor;
        }
        if (vessel.IsVesselInUserScope) {
            return config.vessel.primaryColor;
        }
        return config.vessel.secondaryColor;
    };

    static getVesselFill(vessel) {
        return vessel.IsLaden === true;
    }

    static getTooltipContent(vessel) {
        const notificationProps = getNotificationProps(vessel.LatestAlert);
        return (
            <div className="flex-center flex-row">
                <div className="flex-shrink">
                    <span className="icon icon-exclamation-circle sten-map__tooltip-icon" />
                </div>
                <div className="flex-grow">
                    <div className="sten-map__tooltip-message text-danger">
                        {notificationProps.Message}
                    </div>
                    <h6>
                        {translate('MAP.TOOLTIPS.REPORTED')}
                        {notificationProps.Timestamp}
                    </h6>
                </div>
            </div>
        );
    }

    getVesselOverlay = vessel => {
        if (vessel.LatestAlert) {
            const hashKey = `${vessel.IMO}-${vessel.LatestAlert.NotificationId}`;
            if (!this.vesselOverlays[hashKey]) {
                this.vesselOverlays[hashKey] = ReactTestUtils.renderIntoDocument(
                    <div>
                        <Tooltip
                            alwaysVisible
                            contentClassName="sten-map__tooltip"
                            content={EmissionEstimatorMap.getTooltipContent(vessel)}
                        />
                    </div>
                );
            }
            return this.vesselOverlays[hashKey];
        }
        return null;
    };

    getVesselOverlayProps = (vessel) => {
        if (vessel.LatestAlert) {
            return {
                hidden: false,
                id: vessel.LatestAlert ? `vessel-alert-${vessel.LatestAlert.NotificationId}` : '',
                offset: [0, -15],
                positioning: 'top-center'
            };
        }
        return null;
    };

    setVesselDetailsTooltipPosition = () => {
        if (this.props.calculationVesselPosition) {
            const position = this.props.calculationVesselPosition.CurrentAis;
            const pixels = this.map.getPixelFromCoordinate([position.Longitude, position.Latitude]);
            const vesselPositionDetails = {
                pixels,
                zoomLevel: this.map.getZoom()
            };

            if (!fastDeepEqual(vesselPositionDetails, this.lastVesselPositionDetails)) {
                this.props.setVesselDetailsTooltipPosition(vesselPositionDetails);
            }

            this.lastVesselPositionDetails = vesselPositionDetails;
        }
    };
    /* /vessels */

    areasOfInterestElementProps = {
        lineColor: area => config.areaOfInterest[area.AreaTypeId]?.lineColor || config.areaOfInterest.lineColorDefault,
        lineWidth: config.areaOfInterest.lineWidth,
        label: 'Description',
        wkt: 'AreaDefinition'
    };

    pinsElementProps = {
        type: (pin) => config.pointTypesMap[pin.Type],
        primaryColor: config.pin.primaryColor,
        secondaryColor: config.pin.secondaryColor,
        coordinates: pin => wktParse(pin.Location),
        opacity: this.getPinOpacity,
        label: 'Name',
        hideLabel: this.getPortHideLabel,
        textOnTop: true,
        textStroke: true
    };

    isPortActive = (port) => port.selectedInVoyage;

    portsElementProps = {
        type: (port) => config.pointTypesMap[port.Type],
        primaryColor: this.getPortPrimaryColor,
        secondaryColor: this.getPortSecondaryColor,
        coordinates: port => wktParse(port.Location),
        hidden: this.getPortHidden,
        isActive: this.isPortActive,
        opacity: this.getPortOpacity,
        label: this.getPortLabel,
        hideLabel: this.getPortHideLabel,
        textStroke: true,
        textOnTop: true,
        zIndex: this.getPortZIndex
    };

    historicalBallastRouteElementProps = {
        color: config.route.ballast.color,
        width: config.route.ballast.width
    };

    historicalBallastApproxRouteElementProps = {
        color: config.route.ballastApprox.color,
        width: config.route.ballastApprox.width,
        lineDash: config.route.ballastApprox.lineDash,
        lineDashMaxZoom: config.route.ballastApprox.lineDashMaxZoom,
        zoomColor: config.route.ballastApprox.zoomColor,
        zoomWidth: config.route.ballastApprox.zoomWidth
    };

    historicalLadenRouteElementProps = {
        color: config.route.laden.color,
        width: config.route.laden.width
    };

    historicalLadenAproxRouteElementProps = {
        color: config.route.ladenApprox.color,
        width: config.route.ladenApprox.width,
        lineDash: config.route.ladenApprox.lineDash,
        lineDashMaxZoom: config.route.ladenApprox.lineDashMaxZoom,
        zoomColor: config.route.ladenApprox.zoomColor,
        zoomWidth: config.route.ladenApprox.zoomWidth
    };

    calculationRouteElementProps = {
        color: config.route.future.color,
        width: config.route.future.width,
        lineDash: config.route.future.lineDash,
        lineDashMaxZoom: config.route.future.lineDashMaxZoom
    };

    calculationVoyageRouteElementProps = {
        color: config.route.voyageFuture.color,
        width: config.route.voyageFuture.width,
        lineDash: config.route.voyageFuture.lineDash,
        lineDashMaxZoom: config.route.voyageFuture.lineDashMaxZoom
    };

    vesselsElementProps = {
        type: 'vessel',
        primaryColor: this.getVesselColor,
        coordinates: mapCurrentAisLonLat,
        fill: EmissionEstimatorMap.getVesselFill,
        isActive: true,
        label: vessel => (vessel.VesselName
            ? vessel.VesselName.toUpperCase()
            : translate('GLOBAL.NO_NAME')),
        hideLabel: true,
        opacity: 1,
        overlay: this.getVesselOverlay,
        overlayProps: this.getVesselOverlayProps,
        rotation: 'CurrentAis.Heading',
        textBold: vessel => !!vessel.LatestAlert,
        textStroke: true,
        textOffset: 24
    };

    saveWniWeatherTooltipRef = (c) => { this.wniWeatherTooltipRef = c; };

    render() {
        const isNauticalMapSelected = this.props.mapTypeId === 3;
        return (
            <div className="sten-map">
                {this.props.activePinPointId !== null && <MapLocationTooltip key="MapLocationTooltip" />}
                <VesselDetailsTooltip selectedVesselPosition={this.props.calculationVesselPosition} />
                <Ol.Map
                    ref={(c) => { this.map = c; }}
                    mapTypeId={this.props.mapTypeId}
                    mapViewProps={mapViewProps}
                    countryBordersNamesEnabled={this.props.countryBordersNamesEnabled}
                    zoomControlElement={this.state.mapZoomControlElement}
                    onHover={this.handleMapHover}
                    onPostRender={this.handleMapPostRender}
                    onClick={this.handleMapClick}
                    shouldResetToInitial
                >
                    {!isNauticalMapSelected && ConfigService.featureToggles.showWNIWeather && (
                        <WniWeather zIndex={config.weather.zIndex} tooltipRef={this.saveWniWeatherTooltipRef} />
                    )}
                    {!isNauticalMapSelected && ConfigService.featureToggles.showDTNWeather && (
                        <DtnWeather zIndex={config.weather.zIndex} />
                    )}
                    {ConfigService.featureToggles.showNauticalCharts
                        && !ConfigService.hiddenFeatures.emissionEstimatorNauticalCharts && (
                        <NauticalCharts zIndex={config.nautical.zIndex} showBaseMap={isNauticalMapSelected} />
                    )}
                    {this.props.selectedAreas && (
                        <Ol.Layer
                            name="areasOfInterest"
                            type="area"
                            elements={this.props.selectedAreas}
                            elementProps={this.areasOfInterestElementProps}
                            zIndex={config.areaOfInterest.zIndex}
                        />
                    )}
                    {this.props.calculationVoyageHistoricalRoute.ballast.length > 0 && (
                        <Ol.Layer
                            name="historicalBallastRoute"
                            type="multiLine"
                            elements={this.props.calculationVoyageHistoricalRoute.ballast}
                            elementProps={this.historicalBallastRouteElementProps}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    {this.props.calculationVoyageHistoricalRoute.ballastApprox.length > 0 && (
                        <Ol.Layer
                            name="historicalBallastApproxRoute"
                            type="multiLine"
                            elements={this.props.calculationVoyageHistoricalRoute.ballastApprox}
                            elementProps={this.historicalBallastApproxRouteElementProps}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    {this.props.calculationVoyageHistoricalRoute.laden.length > 0 && (
                        <Ol.Layer
                            name="historicalLadenRoute"
                            type="multiLine"
                            elements={this.props.calculationVoyageHistoricalRoute.laden}
                            elementProps={this.historicalLadenRouteElementProps}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    {this.props.calculationVoyageHistoricalRoute.ladenApprox.length > 0 && (
                        <Ol.Layer
                            name="historicalLadenApproxRoute"
                            type="multiLine"
                            elements={this.props.calculationVoyageHistoricalRoute.ladenApprox}
                            elementProps={this.historicalLadenAproxRouteElementProps}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    {this.props.calculationRoutePoints.length > 0 && (
                        <Ol.Layer
                            name="calculationRoute"
                            type="multiLine"
                            elements={this.props.calculationRoutePoints}
                            elementProps={this.calculationRouteElementProps}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    {this.props.calculationVoyageRoutePoints.length > 0 && (
                        <Ol.Layer
                            name="calculationVoyageRoute"
                            type="multiLine"
                            elements={this.props.calculationVoyageRoutePoints}
                            elementProps={this.calculationVoyageRouteElementProps}
                            zIndex={config.route.zIndex}
                        />
                    )}
                    <Ol.Layer
                        name="ports"
                        type="point"
                        elements={this.props.ports}
                        elementProps={this.portsElementProps}
                        observes={[
                            this.props.arePortsInvisible
                        ]}
                        zIndex={config.port.zIndex}
                        onClick={this.onPortClick}
                        onClickAway={this.onPortClick}
                    />
                    <Ol.Layer
                        name="pins"
                        type="point"
                        elements={this.props.pins}
                        elementProps={this.pinsElementProps}
                        observes={[
                            this.props.activePinPointId
                        ]}
                        onClick={this.onPinClick}
                        zIndex={config.pin.zIndex}
                    />
                    <Ol.Layer
                        name="vessels"
                        type="point"
                        elements={this.props.calculationVesselPosition
                            ? [this.props.calculationVesselPosition]
                            : []}
                        elementProps={this.vesselsElementProps}
                        observes={[
                            this.props.calculationVesselPosition
                        ]}
                        zIndex={config.vessel.zIndex}
                    />
                </Ol.Map>
            </div>
        );
    }
}

EmissionEstimatorMap.propTypes = {
    activePinPointId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    arePortsInvisible: PropTypes.bool.isRequired,
    calculationPoints: PropTypes.arrayOf(PropTypes.object).isRequired,
    calculationRoute: PropTypes.arrayOf(PropTypes.any).isRequired,
    calculationRoutePoints: PropTypes.arrayOf(PropTypes.any).isRequired,
    calculationVesselPosition: PropTypes.objectOf(PropTypes.any),
    calculationVoyageAisPoints: PropTypes.arrayOf(PropTypes.any).isRequired,
    calculationVoyageHistoricalRoute: PropTypes.objectOf(PropTypes.array).isRequired,
    calculationVoyageRoute: PropTypes.arrayOf(PropTypes.any).isRequired,
    calculationVoyageRoutePoints: PropTypes.arrayOf(PropTypes.any).isRequired,
    closeRightSidebar: PropTypes.func.isRequired,
    countryBordersNamesEnabled: PropTypes.bool.isRequired,
    getPorts: PropTypes.func.isRequired,
    getSelectedAreas: PropTypes.func.isRequired,
    isCalculatorFullScreen: PropTypes.bool.isRequired,
    mapTypeId: PropTypes.number.isRequired,
    pins: PropTypes.arrayOf(PropTypes.any).isRequired,
    ports: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedAreas: PropTypes.objectOf(PropTypes.any),
    setActivePinPointId: PropTypes.func.isRequired,
    setClickedPointData: PropTypes.func.isRequired,
    setCustomCalculationPoint: PropTypes.func.isRequired,
    setLocationTooltipCoordinates: PropTypes.func.isRequired,
    settingUpdated: PropTypes.objectOf(PropTypes.any),
    setVesselDetailsTooltipPosition: PropTypes.func.isRequired
};

EmissionEstimatorMap.defaultProps = {
    activePinPointId: null,
    calculationVesselPosition: null,
    selectedAreas: null,
    settingUpdated: null
};

function mapStateToProps(state) {
    return {
        activePinPointId: state.estimatorReducer.activePinPointId,
        arePortsInvisible: state.userReducer?.settings?.ArePortsInvisible,
        calculationPoints: getCalculationPoints(state),
        calculationRoute: getCalculationRoute(state),
        calculationRoutePoints: getCalculationRoutePoints(state),
        calculationVesselPosition: getCalculationVesselPosition(state),
        calculationVoyageAisPoints: getCalculationVoyageAisPoints(state),
        calculationVoyageHistoricalRoute: getHistoricalRoute(state),
        calculationVoyageRoute: getCalculationVoyageRoute(state),
        calculationVoyageRoutePoints: getCalculationVoyageRoutePoints(state),
        countryBordersNamesEnabled: state.userReducer?.settings?.MapOptionsCountryBordersNamesEnabled,
        isCalculatorFullScreen: state.emissionEstimatorPageReducer.isFullScreen,
        mapTypeId: ConfigService.hiddenFeatures.emissionEstimatorNauticalCharts
            ? state.userReducer?.settings?.MarketIntelMapType
            : state.userReducer?.settings?.MapOptionsMapType,
        pins: getPins(state),
        ports: getFetchedPorts(state),
        selectedAreas: state.mapOptionsReducer.selectedAreas,
        settingUpdated: state.userReducer.settingUpdated
    };
}

function mapDispatchToProps(dispatch) {
    return {
        closeRightSidebar: () => emptyActivePanels(dispatch),
        getPorts: params => getPorts(dispatch, params),
        getSelectedAreas: () => getSelectedAreas(dispatch),
        setActivePinPointId: index => setActivePinPointId(dispatch, index),
        setClickedPointData: portId => setClickedPointData(dispatch, portId),
        setCustomCalculationPoint: coordinates => setCustomCalculationPoint(dispatch, coordinates),
        setLocationTooltipCoordinates: (pixels, coordinates) =>
            setLocationTooltipCoordinates(dispatch, pixels, coordinates),
        setVesselDetailsTooltipPosition: position => setVesselDetailsTooltipPosition(dispatch, position)
    };
}

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(EmissionEstimatorMap);
