import { takeEvery, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* actions */
import { ActionTypes } from './admin-user-management-actions';
/* services */
import CompanyManagementService from 'services/core-api/company-management-service';
import ConfigService from 'services/config-api/config-service';
import UserManagementService from 'services/core-api/user-management-service';

function* getCompanies(action) {
    const companies = yield UserManagementService.getCompanies({
        Query: action.searchCriteria
    });
    if (companies) {
        yield put({ type: ActionTypes.ADMIN_USER_MANAGEMENT_SET_COMPANIES, companies });
    }
}

function* getDepartments() {
    const departments = yield CompanyManagementService.Departments.get();
    if (departments) {
        yield put({
            type: ActionTypes.ADMIN_USER_MANAGEMENT_SET_DEPARTMENTS,
            departments
        });
    }
}

function* setNewDepartment(action) {
    const departmentName = action.departmentName;
    const department = yield CompanyManagementService.Departments.add({ Name: departmentName });
    if (department) {
        yield getDepartments();
        // yield put({ type: UserManagementFormActions.ADMIN_USER_MANAGEMENT_FORM_ADD_DEPARTMENT, department });
        toast(
            translate('ADMIN.USER_MANAGEMENT.FORM.SUCCESSFULLY_ADDED_NEW_DEPARTMENT'), { type: toast.TYPE.SUCCESS }
        );
    }
}

const allowedRoles = {
    User: true,
    RestrictedUser: true,
    SuperAdministrator: true,
    Administrator: true
};

function* getOptions() {
    const options = yield all({
        statuses: UserManagementService.getStatuses(),
        roles: UserManagementService.getRoles(),
        departments: CompanyManagementService.Departments.get(),
        internalCompanies: UserManagementService.getCompanies({ IsStena: true })
    });

    if (options.statuses) {
        options.statusesById = {};
        options.statuses.forEach((status) => {
            options.statusesById[status.Id] = status;
        });
    }

    if (ConfigService.featureToggles.filterUserRoles) {
        options.roles = options.roles.filter(role => allowedRoles[role.Name]);
    }
    if (options) {
        yield put({ type: ActionTypes.ADMIN_USER_MANAGEMENT_SET_OPTIONS, options });
    }
}

function* getUsers(action) {
    const {
        selectedCompany,
        selectedRole,
        selectedDepartments,
        selectedStatuses,
        selectedAccountType,
        selectedContactListVisibility,
        emailSearchCriteria,
        sortProp,
        offset,
        limit,
        sortOrder
    } = action.appliedOptions;

    const params = {
        offset: action.overrideCurrent ? 0 : offset,
        limit,
        sortOrder,
        sortBy: sortProp
    };

    if (selectedCompany && selectedCompany.Id) {
        params.CompanyId = selectedCompany.Id;
    }

    if (selectedRole && selectedRole.Id) {
        params.RoleId = selectedRole.Id;
    }

    if (selectedDepartments) {
        params.DepartmentIds = selectedDepartments.map(department => department.Id);
    }

    if (selectedStatuses) {
        params.statuses = selectedStatuses.map(status => status.Id);
    }

    if (selectedAccountType) {
        params.LocalLoginEnabled = selectedAccountType.isLocalLoginEnabled;
    }

    if (selectedContactListVisibility) {
        params.IsVisibleInContactList = selectedContactListVisibility.isVisible;
    }

    if (emailSearchCriteria) {
        params.SearchTerm = emailSearchCriteria;
    }

    yield put({ type: ActionTypes.ADMIN_USER_MANAGEMENT_SET_FETCHING_PROGRESS, isFetchingData: true });
    const usersResponse = yield UserManagementService.get(params);
    if (usersResponse) {
        const users = usersResponse.PagedUsers;
        const currentPage = (usersResponse.Offset / usersResponse.Limit) + 1;
        const usersAllFetched = currentPage >= usersResponse.TotalPagesCount;
        yield put({
            type: ActionTypes.ADMIN_USER_MANAGEMENT_SET_USERS,
            users,
            usersAllFetched,
            overrideCurrent: action.overrideCurrent
        });
    } else {
        yield put({ type: ActionTypes.ADMIN_USER_MANAGEMENT_SET_FETCHING_PROGRESS, isFetchingData: false });
    }
}

export default function* adminUserManagementSaga() {
    yield takeEvery(ActionTypes.ADMIN_USER_MANAGEMENT_GET_OPTIONS, getOptions);
    yield takeEvery(ActionTypes.ADMIN_USER_MANAGEMENT_GET_COMPANIES, getCompanies);
    yield takeEvery(ActionTypes.ADMIN_USER_MANAGEMENT_GET_DEPARTMENTS, getDepartments);
    yield takeEvery(ActionTypes.ADMIN_USER_MANAGEMENT_SET_NEW_DEPARTMENT, setNewDepartment);
    yield takeEvery(ActionTypes.ADMIN_USER_MANAGEMENT_GET_USERS, getUsers);
}
