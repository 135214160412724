import React, { Fragment } from 'react';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import TimeHelper from 'utils/helpers/time-helper';
/* helpers */
import { formatNumber } from 'utils/helpers/info-helper';
import { sortByProp } from 'utils/helpers/array-helper';
import { isActivityCurrent } from 'components/itinerary/helpers';
/* components */
import DateTimePopup from 'components/date-time-popup/date-time-popup';
import Select from 'components/select/select';
import TagSelect from 'components/tag-select/tag-select';
import Validation from 'components/validation/validation';

const t = (key) => translate(`ITINERARY.${key}`);

export const rowConfigs = [
    {
        id: 0,
        label: t('ACTIVITY'),
        prop: 'Activity',
        render: (activity) => {
            let className = 'sten-panel__label sten-panel__label--value';
            if (isActivityCurrent(activity)) {
                className += ' text-active';
            }
            return (<label className={className}>{ activity.Activity || '-'}</label>);
        }
    },
    {
        id: 1,
        label: t('STEAM'),
        unit: translate('UNITS.STEAM'),
        render: data => (
            <label className="sten-panel__label sten-panel__label--value">
                {data.Steam
                    ? `${formatNumber(data.Steam / 24, 2)}${translate('UNITS.STEAM')}`
                    : '-'
                }
            </label>
        )
    },
    {
        id: 2,
        label: t('ETA'),
        prop: 'EtaState'
    },
    {
        id: 3,
        label: t('ETB'),
        prop: 'EtbState'
    },
    {
        id: 4,
        label: t('ETD'),
        prop: 'EtdState'
    },
    // {
    //     id: 5,
    //     label: t('AGENT'),
    //     prop: 'AgentName'
    // },
    {
        id: 6,
        label: t('TAGS'),
        prop: 'Tags',
        render: (activity) => {
            const tags = [...activity.Tags];
            return (
                <div className="sten-itinerary__badge-wrapper">
                    {tags.sort(sortByProp('Sequence')).map(tag => (
                        <div className="sten-itinerary__badge" key={tag.Name}>
                            <span className="sten-badge sten-badge--success" title={tag.Category}>
                                {tag.ShortName}
                            </span>
                        </div>
                    ))}
                </div>
            );
        }
    },
    {
        id: 7,
        label: t('ROB'),
        prop: 'Rob',
        render: (activity) => {
            const { LiquidGas, Fuel, Gas } = activity.BunkerROBsByRangeGroup;
            return (
                <Fragment>
                    <label className="sten-panel__label sten-panel__label--value">
                        {`${t('LG')}: ${LiquidGas ? `${formatNumber(LiquidGas)} ${translate('UNITS.ROB')}` : '-'}`}
                    </label>
                    <label className="sten-panel__label sten-panel__label--value">
                        {`${t('FO')}: ${Fuel ? `${formatNumber(Fuel)} ${translate('UNITS.ROB')}` : '-'}`}
                    </label>
                    <label className="sten-panel__label sten-panel__label--value">
                        {`${t('GO')}: ${Gas ? `${formatNumber(Gas)} ${translate('UNITS.ROB')}` : '-'}`}
                    </label>
                </Fragment>
            );
        }
    }
];

export const editRowConfigs = [
    {
        id: 0,
        rows: [
            {
                id: 0,
                render: (activity) => {
                    let className = 'label label--value';
                    if (isActivityCurrent(activity)) {
                        className += ' text-active';
                    }
                    return (
                        <div className="flex-row">
                            <div className="col-12">
                                <label className="label">{t('ACTIVITY')}</label>
                            </div>
                            <div className="col-12">
                                <label className={className}>{` ${activity.Activity || '-'}`}</label>
                            </div>
                        </div>
                    );
                }
            },
            {
                id: 1,
                render: (activity) => (
                    <div className="flex-row">
                        <div className="col-12">
                            <label className="label">{t('ETA')}</label>
                        </div>
                        <div className="col-12">
                            <label className="label label--value">{activity.EtaState || '-'}</label>
                        </div>
                    </div>
                )
            },
            {
                id: 2,
                render: (activity) => (
                    <div className="flex-row">
                        <div className="col-12">
                            <label className="label">{t('ETD')}</label>
                        </div>
                        <div className="col-12">
                            <label className="label label--value">{activity.EtdState || '-'}</label>
                        </div>
                    </div>
                )
            }
        ]
    },
    {
        id: 2,
        rows: [
            {
                id: 3,
                render: (data, onChange, options, pointIndex, activityIndex) => (
                    <div className="flex-row">
                        <div className="col-8">
                            <label className="label">{t('ETB_STATUS')}</label>
                            <Select
                                name={`EtbStatus.${pointIndex}.${activityIndex}`}
                                options={options.EtbStatuses}
                                value={data.EtbStatus}
                                valueKey="Id"
                                labelKey="ShortName"
                                onChange={onChange('EtbStatus', pointIndex, activityIndex)}
                            />
                        </div>
                        <div className="col-16">
                            <label className="label">{t('ETB_DATE')}</label>
                            <Validation.Wrapper
                                hintsOnHover
                                validations={{ dateLimits: { min: data.Eta, max: data.Etd, utc: true }, date: true }}
                            >
                                <DateTimePopup
                                    autoPlacement
                                    isClearable
                                    isUTC
                                    dateFormat={TimeHelper.getFormat()}
                                    name={`Etb.${pointIndex}.${activityIndex}`}
                                    value={data.Etb}
                                    onDateSelect={onChange('Etb', pointIndex, activityIndex)}
                                />
                            </Validation.Wrapper>
                        </div>
                    </div>
                )
            },
            {
                id: 4,
                render: (data, onChange, options, pointIndex, activityIndex) => (
                    <React.Fragment>
                        <label className="label">{t('TAGS')}</label>
                        <TagSelect
                            name={`Tags.${pointIndex}.${activityIndex}`}
                            options={options.LegTags}
                            onChange={onChange('Tags', pointIndex, activityIndex)}
                            value={data.Tags}
                        />
                    </React.Fragment>
                )
            }
        ]
    }
];
