/* utils */
import { translate } from 'utils/i18n/i18n-model';

const tu = (key) => translate(`UNITS.${key}`);

export const colProps = [
    { key: 'Imo', type: 'string' },
    { key: 'DWT', decimals: 0, type: 'number', unit: tu('DWT'), numFmt: '# ### ##0 "mt"' },
    { key: 'YearBuilt', type: 'date', dateOptions: { time: false, utc: true } },
    { key: 'LOA', decimals: 2, type: 'number', unit: tu('LOA'), numFmt: '# ### ##0.00 "m"' },
    { key: 'Beam', decimals: 2, type: 'number', unit: tu('BEAM'), numFmt: '# ### ##0.00 "m"' },
    { key: 'Draft', decimals: 1, type: 'number', unit: tu('DRAFT'), numFmt: '# ### ##0.0 "m"' },
    { key: 'NumberOfTanks', decimals: 0, type: 'number', numFmt: '# ### ##0' },
    { key: 'HasScrubber', type: 'bool' },
    { key: 'Coating', type: 'string' },
    { key: 'IceClass', type: 'string' },
    { key: 'Flag', type: 'string' }
];

export const colWidths = {
    VesselName: { web: 6, xls: 18, pdf: 6 },
    Imo: { web: 6, xls: 12, pdf: 6 },
    DWT: { web: 6, xls: 12, pdf: 6 },
    YearBuilt: { web: 6, xls: 12, pdf: 6 },
    LOA: { web: 6, xls: 9, pdf: 6 },
    Beam: { web: 6, xls: 8, pdf: 6 },
    Draft: { web: 6, xls: 8, pdf: 6 },
    NumberOfTanks: { web: 2, xls: 8, pdf: 2 },
    HasScrubber: { web: 2, xls: 8, pdf: 2 },
    Coating: { web: 8, xls: 24, pdf: 8 },
    IceClass: { web: 6, xls: 12, pdf: 6 },
    Flag: { web: 6, xls: 24, pdf: 6 }
};
