export const ActionTypes = {
    PORT_CALLS_ANALYTICS_RESET_ALL: 'PORT_CALLS_ANALYTICS_RESET_ALL',
    PORT_CALLS_ANALYTICS_SET_RANGE: 'PORT_CALLS_ANALYTICS_SET_RANGE',
    PORT_CALLS_ANALYTICS_SET_SIDEBAR: 'PORT_CALLS_ANALYTICS_SET_SIDEBAR',
    PORT_CALLS_ANALYTICS_ADD_PORTS: 'PORT_CALLS_ANALYTICS_ADD_PORTS',
    PORT_CALLS_ANALYTICS_ADD_VESSELS: 'PORT_CALLS_ANALYTICS_ADD_VESSELS',
    PORT_CALLS_ANALYTICS_SET_PORTS_AND_COUNTRIES: 'PORT_CALLS_ANALYTICS_SET_PORTS_AND_COUNTRIES',
    PORT_CALLS_ANALYTICS_GET_PORTS_AND_COUNTRIES: 'PORT_CALLS_ANALYTICS_GET_PORTS_AND_COUNTRIES',
    PORT_CALLS_ANALYTICS_CALCULATE_PORT_CALLS_ANALYTICS: 'PORT_CALLS_ANALYTICS_CALCULATE_PORT_CALLS_ANALYTICS',
    PORT_CALLS_ANALYTICS_SET_PORT_CALLS_ANALYTICS: 'PORT_CALLS_ANALYTICS_SET_PORT_CALLS_ANALYTICS',
    PORT_CALLS_ANALYTICS_GENERATE_XLSX: 'PORT_CALLS_ANALYTICS_GENERATE_XLSX',
    PORT_CALLS_ANALYTICS_SET_VESSELS: 'PORT_CALLS_ANALYTICS_SET_VESSELS',
    PORT_CALLS_ANALYTICS_VESSELS_SIDEBAR_GET_INITIAL_DATA: 'PORT_CALLS_ANALYTICS_VESSELS_SIDEBAR_GET_INITIAL_DATA',
    PORT_CALLS_ANALYTICS_VESSELS_SIDEBAR_SET_INITIAL_DATA: 'PORT_CALLS_ANALYTICS_VESSELS_SIDEBAR_SET_INITIAL_DATA'
};

export function calculateCallsAnalytics(dispatch) {
    dispatch({
        type: ActionTypes.PORT_CALLS_ANALYTICS_CALCULATE_PORT_CALLS_ANALYTICS
    });
}

export function generateXLSX(dispatch) {
    dispatch({
        type: ActionTypes.PORT_CALLS_ANALYTICS_GENERATE_XLSX
    });
}

export function updateDateFilter(dispatch, rangeStart, rangeEnd) {
    dispatch({
        type: ActionTypes.PORT_CALLS_ANALYTICS_SET_RANGE,
        rangeStart,
        rangeEnd
    });
}

export function setSidebar(dispatch, sidebar) {
    dispatch({
        type: ActionTypes.PORT_CALLS_ANALYTICS_SET_SIDEBAR,
        sidebar
    });
}

export function addPorts(dispatch, ports) {
    dispatch({
        type: ActionTypes.PORT_CALLS_ANALYTICS_ADD_PORTS,
        ports
    });
}

export function addVessels(dispatch, vessels) {
    dispatch({
        type: ActionTypes.PORT_CALLS_ANALYTICS_ADD_VESSELS,
        vessels
    });
}

export function getPortsAndCountries(dispatch) {
    dispatch({
        type: ActionTypes.PORT_CALLS_ANALYTICS_GET_PORTS_AND_COUNTRIES
    });
}

export function getVesselsSidebarInitialData(dispatch) {
    dispatch({
        type: ActionTypes.PORT_CALLS_ANALYTICS_VESSELS_SIDEBAR_GET_INITIAL_DATA
    });
}

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.PORT_CALLS_ANALYTICS_RESET_ALL
    });
}
