export const ActionTypes = {
    RAW_REPORTS_TABLE_SET_MOUNTED: 'RAW_REPORTS_TABLE_SET_MOUNTED',
    RAW_REPORTS_TABLE_GET_REPORTS: 'RAW_REPORTS_TABLE_GET_REPORTS',
    RAW_REPORTS_TABLE_SET_REPORTS: 'RAW_REPORTS_TABLE_SET_REPORTS',
    RAW_REPORTS_TABLE_SET_VISIBILITY: 'RAW_REPORTS_TABLE_SET_VISIBILITY',
    RAW_REPORTS_TABLE_SET_REPORT_DATA_TYPES: 'RAW_REPORTS_TABLE_SET_REPORT_DATA_TYPES',
    RAW_REPORTS_TABLE_RESET_UPDATED_REPORTS: 'RAW_REPORTS_TABLE_RESET_UPDATED_REPORTS',
    RAW_REPORTS_TABLE_RESET_ALL: 'RAW_REPORTS_TABLE_RESET_ALL'
};

export function setMounted(dispatch) {
    dispatch({
        type: ActionTypes.RAW_REPORTS_TABLE_SET_MOUNTED
    });
}

export function setVisibility(dispatch, visibility) {
    dispatch({
        type: ActionTypes.RAW_REPORTS_TABLE_SET_VISIBILITY,
        visibility
    });
}

export function getReports(
    dispatch,
    {
        getDataTypes,
        override,
        range,
        reportType,
        vesselImo,
        voyage,
        visibility,
        inclusions
    }
) {
    dispatch({
        type: ActionTypes.RAW_REPORTS_TABLE_GET_REPORTS,
        getDataTypes,
        override,
        range,
        reportType,
        vesselImo,
        voyage,
        visibility,
        inclusions
    });
}

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.RAW_REPORTS_TABLE_RESET_ALL
    });
}
