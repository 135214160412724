import React from 'react';
/* components */
import RightSideBar from './right-side-bar';

export const renderPanels = (activePanels, ...panelContainers) => {
    let panel;
    const containers = panelContainers.map(container => ({
        ...container,
        panels: activePanels.reduce((res, panelName) => {
            if (container.panelMap[panelName]) {
                panel = container.panelMap[panelName]();
                if (panel) {
                    res.push(panel);
                }
            }
            return res;
        }, [])
    }));
    return (
        <React.Fragment>
            {containers.map((container, index) => (
                <RightSideBar
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    buttonRenderer={container.buttons}
                    isCollapsed={container.panels.length === 0}
                    style={container.style}
                    enableTransitions
                >
                    {container.panels}
                </RightSideBar>
            ))}
        </React.Fragment>
    );
};
