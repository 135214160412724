import CoreApi from './core-api';

class MarketIntelService {
    constructor() {
        this.resourceUrl = 'TradingRoutes';
    }

    getRoutes(params = {}) {
        return CoreApi.get(this.resourceUrl, params)
            .then(response => response.data, () => null);
    }

    getBunkerPrices() {
        return CoreApi.get(`${this.resourceUrl}/Ports`)
            .then(response => response.data, () => null);
    }

    getRanges() {
        return CoreApi.get(`${this.resourceUrl}/Ranges`)
            .then(response => response.data, () => null);
    }

    getModels() {
        return CoreApi.get(`${this.resourceUrl}/MarketIntelModels`)
            .then(response => response.data, () => []);
    }

    getLatestPrediction(params) {
        return CoreApi.get(`${this.resourceUrl}/LatestPrediction`, params)
            .then(response => response.data, () => null);
    }

    getMarketIntelData(params) {
        return CoreApi.get(`${this.resourceUrl}/MarketIntel`, params)
            .then(response => response.data, () => null);
    }
}

export default new MarketIntelService();
