import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ScrollArea from 'components/scroll-area/scroll-area';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { formatNumber } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
import { splitSegments } from 'components/segment-select/segment-select-helper';
/* actions */
import { getPortCalls, getInitialData, updateFilter, reset } from './port-info-calls-actions';
/* components */
import Select from 'components/select/select';
import EmptyContent from 'components/empty-content/empty-content';
/* style */
import './port-info-calls.scss';

export class PortInfoCalls extends React.PureComponent {
    componentDidMount() {
        this.props.getInitialData(this.props.portId);
    }

    componentDidUpdate(prevProps) {
        if (this.props.portId && prevProps.portId !== this.props.portId) {
            this.props.reset();
            this.props.getInitialData(this.props.portId);
        }
        if (!this.props.isGettingData
            && (prevProps.selectedSegments !== this.props.selectedSegments
                || prevProps.selectedPortActivities !== this.props.selectedPortActivities)) {
            this.props.getPortCalls(this.props.portId, true);
        }
    }

    componentWillUnmount() {
        this.props.reset();
    }

    onFilterUpdate(filterType, filterData) {
        this.props.updateFilter(filterType, filterData);
    }

    onVesselClick = vessel => this.props.router.updateQueryParams({
        pathname: appRoutes.Map,
        add: {
            voyage: vessel.Voyage.VoyageId,
            vessel: vessel.Vessel.IMO
        }
    });

    getPortCalls() {
        return this.props.portCalls.map((call, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className="btn-section flex" onClick={this.onVesselClick.bind(this, call)}>
                <div className="flex-grow flex-row flex-center">
                    <div className="col-12">
                        <h3 className="text-uppercase">{call.Vessel.Title}</h3>
                        <h6 className="text-secondary">{call.Activity.PortActivityName}</h6>
                    </div>
                    <div className="col-12">
                        <ul className="sten-ul">
                            <li>
                                <span className="text-secondary">{`${t('PORT_INFO.PORT_CALLS.START')} `}</span>
                                {call.Eta
                                    ? TimeHelper.getFormatted(call.Eta, { utc: true, time: true, day: true })
                                    : t('GLOBAL.UNKNOWN')
                                }
                            </li>
                            <li>
                                <span className="text-secondary">{`${t('PORT_INFO.PORT_CALLS.END')} `}</span>
                                {call.Etd
                                    ? TimeHelper.getFormatted(call.Etd, { utc: true, time: true, day: true })
                                    : t('GLOBAL.UNKNOWN')
                                }
                            </li>
                            {typeof call.Cost === 'number' && (
                                <li>
                                    <span className="text-secondary">{`${t('PORT_INFO.PORT_CALLS.COST')} `}</span>
                                    {`${t('UNITS.COST')} ${formatNumber(call.Cost, 0)}`}
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        ));
    }

    saveRef = c => { this.scrollArea = c; };

    scrollHandler = value => {
        if (value.realHeight - value.topPosition < value.containerHeight + 100) {
            if (!this.props.isGettingData && !this.props.allFetched) {
                this.props.getPortCalls(this.props.portId, false);
            }
        }
    };

    render() {
        return (
            <div className="sten-port-info-calls">
                <div className="sten-port-info-calls__header">
                    <div className="sten-content__section">
                        <div className="row">
                            <div className="col-12">
                                <label className="label">{t('PORT_INFO.PORT_CALLS.FILTERS.ACTIVITY')}</label>
                                <Select
                                    multiple
                                    valueKey="PortActivityId"
                                    labelKey="PortActivityName"
                                    options={this.props.portActivities}
                                    placeholder={t('PORT_INFO.PORT_CALLS.FILTERS.SELECT_ACTIVITIES')}
                                    value={this.props.selectedPortActivities}
                                    onChange={this.onFilterUpdate.bind(this, 'activityType')}
                                />
                            </div>
                            <div className="col-12">
                                <label className="label">{t('PORT_INFO.PORT_CALLS.FILTERS.SEGMENTS')}</label>
                                <Select
                                    multiple
                                    valueKey="VesselTypeId"
                                    labelKey="VesselTypeName"
                                    options={this.props.segments}
                                    placeholder={t('PORT_INFO.PORT_CALLS.FILTERS.SELECT_SEGMENTS')}
                                    value={this.props.selectedSegments}
                                    onChange={this.onFilterUpdate.bind(this, 'segments')}
                                    splitOptionsIntoSections={splitSegments}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="sten-content__separator" />
                </div>
                {!this.props.portCalls.length > 0 && <EmptyContent />}
                {this.props.portCalls.length > 0 && (
                    <ScrollArea
                        className="sten-port-info-calls__content"
                        onScroll={this.scrollHandler}
                        ref={this.saveRef}
                    >
                        {this.getPortCalls()}
                    </ScrollArea>
                )}
            </div>
        );
    }
}

PortInfoCalls.propTypes = {
    allFetched: PropTypes.bool.isRequired,
    getInitialData: PropTypes.func.isRequired,
    getPortCalls: PropTypes.func.isRequired,
    isGettingData: PropTypes.bool.isRequired,
    portActivities: PropTypes.arrayOf(PropTypes.object).isRequired,
    portCalls: PropTypes.arrayOf(PropTypes.any).isRequired,
    portId: PropTypes.number,
    reset: PropTypes.func.isRequired,
    router: TRouter.isRequired,
    segments: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedPortActivities: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedSegments: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateFilter: PropTypes.func.isRequired
};

PortInfoCalls.defaultProps = {
    portId: null
};

function mapStateToProps(state) {
    return {
        allFetched: state.portInfoCallsReducer.allFetched,
        isGettingData: state.portInfoCallsReducer.isGettingData,
        portActivities: state.portInfoCallsReducer.portActivities,
        portCalls: state.portInfoCallsReducer.portCalls,
        segments: state.portInfoCallsReducer.segments,
        selectedPortActivities: state.portInfoCallsReducer.selectedPortActivities,
        selectedSegments: state.portInfoCallsReducer.selectedSegments
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getInitialData: (portId) => getInitialData(dispatch, portId),
        getPortCalls: (portId, overrideCurrent) => getPortCalls(dispatch, portId, overrideCurrent),
        reset: () => reset(dispatch),
        updateFilter: (filterType, filterData) => updateFilter(dispatch, filterType, filterData)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PortInfoCalls));
