import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import { getClassName } from 'utils/helpers/info-helper';
/* components */
import Input from 'components/input/input';
import Menu, { MenuItem } from 'components/menu/menu';
import Validation from 'components/validation/validation';
/* styles */
import './admin-company-management-editable-list.scss';

const t = (key, props, pluralize) => translate(`ADMIN.COMPANY_MANAGEMENT.EDITABLE_ITEM.${key}`, props, pluralize);

class AdminCompanyManagementEditableItem extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            activeMode: '',
            isActive: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        let newState = null;
        if (props.isActive !== state.isActive) {
            newState = { isActive: props.isActive };
            if (props.isActive === false) {
                newState = { ...newState, value: '', activeMode: '' };
            }
        }
        return newState;
    }

    toggleMode = mode => {
        if (this.props.onModeChange) {
            this.props.onModeChange(mode, this.props.item ? this.props.item : null, () => {
                this.setState({ value: this.props.item ? this.props.item[this.props.labelKey] : '', activeMode: mode });
            });
        } else {
            this.setState({ value: this.props.item ? this.props.item[this.props.labelKey] : '', activeMode: mode });
        }
    };

    toggleUpdateMode = this.toggleMode.bind(this, 'update');

    toggleRemoveMode = this.toggleMode.bind(this, 'remove');

    handleCancel = this.toggleMode.bind(this, '');

    handleSubmit = () => {
        const item = { ...this.props.item, [this.props.labelKey]: this.state.value };
        this.props.onUpdate(item);
        this.toggleMode('');
    };

    handleRemove = () => {
        this.props.onRemove(this.props.item);
        this.toggleMode('');
    };

    setValue = value => {
        this.setState({ value });
    };

    render() {
        const classNames = getClassName(
            'sten-admin-company-management-editable-list-item',
            this.props.className,
            { 'sten-admin-company-management-editable-list-item--updated': this.props.isUpdated }
        );
        const isSubmitDisabled = this.props.item && this.state.value === this.props.item[this.props.labelKey];
        return (
            <div className={classNames}>
                {!this.state.activeMode && !this.props.item && (
                    <div className="sten-admin-company-management-editable-list-item__content flex-row flex-center">
                        <div className="flex-grow">
                            <button
                                className="btn-link btn-link--inverted text-uppercase"
                                onClick={this.toggleUpdateMode}
                            >
                                <span className="btn__icon icon icon-plus" />{this.props.addBtnLabel}
                            </button>
                        </div>
                    </div>
                )}
                {!this.state.activeMode && this.props.item && (
                    <div className="sten-admin-company-management-editable-list-item__content flex-row flex-center">
                        {this.props.index !== null && (
                            <div className="flex-shrink">{this.props.index + 1}.</div>
                        )}
                        <div className="flex-grow text-ellipsis">
                            {this.props.item[this.props.labelKey]}
                        </div>
                        {(this.props.onUpdate || this.props.onRemove) && (
                            <div className="flex-shrink">
                                <Menu itemsClassName="sten-admin-company-management-editable-list-item__menu-items">
                                    {this.props.onUpdate && (
                                        <MenuItem onClick={this.toggleUpdateMode} icon="icon-edit">
                                            {translate('GLOBAL.EDIT')}
                                        </MenuItem>
                                    )}
                                    {this.props.onRemove && (
                                        <MenuItem onClick={this.toggleRemoveMode} icon="icon-delete">
                                            {translate('GLOBAL.DELETE')}
                                        </MenuItem>
                                    )}
                                </Menu>
                            </div>
                        )}
                    </div>
                )}
                {this.state.activeMode === 'update' && (
                    <div className="sten-admin-company-management-editable-list-item__form">
                        <Validation.Form
                            className="sten-admin-company-management-editable-list-item__content flex-row flex-center"
                            onSubmit={this.handleSubmit}
                        >
                            {this.props.index !== null && (
                                <div className="flex-shrink">{this.props.index + 1}.</div>
                            )}
                            <div className="flex-grow">
                                <Validation.Wrapper
                                    hintsOnHover
                                    hintsInset
                                    validations={this.props.validations}
                                >
                                    <Input
                                        name="departmentName"
                                        autoFocus
                                        autoComplete="off"
                                        value={this.state.value}
                                        onChange={this.setValue}
                                    />
                                </Validation.Wrapper>
                            </div>
                            <div className="flex-shrink">
                                <Validation.Button className="btn-link icon icon-check" disabled={isSubmitDisabled} />
                            </div>
                            <div className="flex-shrink">
                                <button
                                    type="button"
                                    className="btn-link icon icon-close"
                                    onClick={this.handleCancel}
                                />
                            </div>
                        </Validation.Form>
                    </div>
                )}
                {this.state.activeMode === 'remove' && (
                    <div className="sten-admin-company-management-editable-list-item__form">
                        <div className="sten-admin-company-management-editable-list-item__content flex-row flex-center">
                            <div className="flex-grow">
                                {this.props.removeConfirmMessage}
                            </div>
                            {!this.props.isRemovable && (
                                <div className="flex-shrink">
                                    <button className="btn-link icon icon-check" onClick={this.handleRemove} />
                                </div>
                            )}
                            <div className="flex-shrink">
                                <button className="btn-link icon icon-close" onClick={this.handleCancel} />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

AdminCompanyManagementEditableItem.propTypes = {
    addBtnLabel: PropTypes.string,
    className: PropTypes.string,
    item: PropTypes.objectOf(PropTypes.any),
    index: PropTypes.number,
    isActive: PropTypes.bool,
    isRemovable: PropTypes.bool,
    isUpdated: PropTypes.bool,
    labelKey: PropTypes.string,
    onModeChange: PropTypes.func,
    onRemove: PropTypes.func,
    onUpdate: PropTypes.func,
    removeConfirmMessage: PropTypes.node,
    validations: PropTypes.objectOf(PropTypes.any).isRequired
};

AdminCompanyManagementEditableItem.defaultProps = {
    addBtnLabel: translate('GLOBAL.ADD'),
    className: '',
    item: null,
    index: null,
    isActive: false,
    isRemovable: true,
    isUpdated: false,
    labelKey: 'Name',
    onModeChange: undefined,
    onRemove: undefined,
    onUpdate: undefined,
    removeConfirmMessage: t('DELETE_CONFIRM')
};

export default AdminCompanyManagementEditableItem;
