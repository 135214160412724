import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
/* constants */
import Accordion from 'components/accordion/accordion';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import getPanelRows from 'utils/helpers/template-helper';
import { getClassName } from 'utils/helpers/info-helper';
/* configs */
import { rowConfigs } from './operators-diary-fixture-constants';
/* styles */
import './operators-diary-fixture.scss';

const t = (key, value) => translate(`OPERATORS_DIARY.FIXTURES.${key}`, value);

class OperatorsDiaryFixture extends PureComponent {
    columnClasses = ['col-8', 'col-16'];

    render() {
        const { fixture } = this.props;
        const rows = rowConfigs.map(config => [
            <label className="sten-panel__label">{config.label}</label>,
            <label className="sten-panel__label sten-panel__label--value">
                { fixture[config.prop] ? fixture[config.prop] : '-' }
            </label>
        ]);

        const header = (
            <Fragment>
                <h4 className="flex-grow">{t('HEADER', { FixtureId: this.props.fixture.FixtureId })}</h4>
                <div className="sten-operators-diary__badge-wrapper">
                    {this.props.fixture.FixtureId && this.props.fixture.Tags.map(tag => (
                        <div
                            key={tag.Id}
                            className={getClassName('sten-operators-diary__badge',
                                { 'sten-operators-diary__badge--subs': tag.Id === 2 })}
                        >
                            <span className="sten-badge" title={tag.Category}>{tag.ShortName}</span>
                        </div>
                    ))}
                </div>
            </Fragment>
        );

        return (
            <Accordion className="sten-panel sten-operators-diary-fixture" header={header}>
                <div className="sten-panel__section">
                    {getPanelRows(this.columnClasses, rows)}
                </div>
            </Accordion>
        );
    }
}

OperatorsDiaryFixture.propTypes = {
    fixture: PropTypes.objectOf(PropTypes.any).isRequired
};

export default OperatorsDiaryFixture;
