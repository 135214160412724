import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { formatCoordinates, formatNumber } from 'utils/helpers/info-helper';

export default class GsmVesselDetailsTooltipContent extends React.PureComponent {
    render() {
        let coordinates;
        const { CurrentAis } = this.props.selectedVessel;
        if (CurrentAis) {
            coordinates = formatCoordinates(CurrentAis.Longitude, CurrentAis.Latitude);
        }
        const titleStyle = { color: this.props.selectedVessel.tooltipNameColor };
        return (
            <div className="sten-vessel-details-tooltip__wrapper">
                <div className="sten-vessel-details-tooltip__vessel-name" style={titleStyle}>
                    {this.props.selectedVessel.Title}
                </div>
                <table className="sten-vessel-details-tooltip__table">
                    <tbody>
                        {coordinates && (
                            <tr>
                                <td>{t('VESSEL_DETAILS_TOOLTIP.COORDINATES')}</td>
                                <td>{coordinates}</td>
                            </tr>
                        )}
                        {!isNaN(parseFloat(CurrentAis.Heading)) && (
                            <tr>
                                <td>{t('VESSEL_DETAILS_TOOLTIP.COURSE')}</td>
                                <td>{`${CurrentAis.Heading}${t('UNITS.COURSE')}`}</td>
                            </tr>
                        )}
                        {!isNaN(parseFloat(CurrentAis.SpeedOverGround)) && (
                            <tr>
                                <td>{t('VESSEL_DETAILS_TOOLTIP.SPEED')}</td>
                                <td>
                                    {formatNumber(CurrentAis.SpeedOverGround)}
                                    {t('UNITS.SPEED')}
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

GsmVesselDetailsTooltipContent.propTypes = {
    selectedVessel: PropTypes.objectOf(PropTypes.any).isRequired
};
