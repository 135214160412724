import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
/* router */
import { appRoutes, TRouter, withRouter } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* actions */
import { getCompanies, getPlatformStatistics } from './admin-company-management-actions';
/* selectors */
import { getPlatformStatisticsChartData } from './admin-company-management-selectors';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import Accordion from 'components/accordion/accordion';
import CompanyBox from './company-box/admin-company-management-company-box';
import CompanyForm from './company-form/admin-company-management-company-form';
import Departments from './departments/admin-company-management-departments';
import Fleets from './fleets/admin-company-management-fleets';
import RightSideBar from 'components/right-side-bar/right-side-bar';
import PlatformStatisticsBox from './platform-statistics-box/admin-company-management-platform-statistics-box';
import PlatformStatisticsChart from './platform-statistics-chart/admin-company-management-platform-statistics-chart';
import ScrollArea from 'components/scroll-area/scroll-area';
/* styles */
import './admin-company-management.scss';

export class AdminCompanyManagement extends React.PureComponent {
    state = {
        activeSidePanel: '',
        activeSidePanelResourceId: null
    };

    static getDerivedStateFromProps(props, state) {
        const activeSidePanel = props.router.location.pathname.includes('/company/') ? 'company' : '';
        if (!activeSidePanel) {
            if (state.activeSidePanel) {
                return { activeSidePanel: '', activeSidePanelResourceId: null };
            }
        } else if (props.router.location.pathname.includes('/company/')
            && (state.activeSidePanel !== 'company' || props.router.params.id !== state.activeSidePanelResourceId)) {
            return {
                activeSidePanel: 'company',
                activeSidePanelResourceId: Number(props.router.params.id) || null
            };
        } if (activeSidePanel !== state.activeSidePanel) {
            return {
                activeSidePanel: props.router.location.pathname,
                activeSidePanelResourceId: Number(props.router.params.id) || null
            };
        }
        return null;
    }

    componentDidMount() {
        this.props.getCompanies();
        this.props.getPlatformStatistics();
    }

    getActivePanel = () => {
        const panelsMap = {
            company: <CompanyForm id={this.state.activeSidePanelResourceId} />
        };
        return panelsMap[this.state.activeSidePanel] || null;
    };

    render() {
        const allowCompanyEdit = ConfigService.featureToggles.allowCompanyEdit;
        return (
            <div className="sten-admin-company-management">
                <div className="sten-content">
                    <div className="sten-content__header flex-row">
                        <div className="flex-grow">
                            <h1 className="sten-content__title">{t('ADMIN.COMPANY_MANAGEMENT.TITLE')}</h1>
                        </div>
                        {allowCompanyEdit && (
                            <div className="flex-shrink">
                                <Link
                                    disabled={this.state.activeSidePanel}
                                    className="btn btn--link btn--primary"
                                    to={`${appRoutes.Admin.CompanyManagement}/company/new`}
                                >
                                    <span className="btn__icon icon icon-plus" />
                                    {t('ADMIN.COMPANY_MANAGEMENT.ADD_COMPANY')}
                                </Link>
                            </div>
                        )}
                    </div>
                    <ScrollArea className="sten-content__body">
                        <div className="sten-content__section">
                            <div className="row row--fluid">
                                {this.props.companies.map(company => (
                                    <div key={company.Id} className="col-lg-6 col-md-8 col-sm-12 col-xs-24">
                                        <CompanyBox
                                            key={company.Id}
                                            company={company}
                                            isEditable={allowCompanyEdit}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="sten-content__section">
                            <div className="row row--fluid">
                                <div className="col-md-12 col-xs-24">
                                    <Departments />
                                </div>
                                <div className="col-md-12 col-xs-24">
                                    <Fleets />
                                </div>
                            </div>
                        </div>
                        <Accordion header={(
                            <h4 className="text-uppercase">
                                {t('ADMIN.COMPANY_MANAGEMENT.PLATFORM_STATISTICS.TITLE')}
                            </h4>
                        )}
                        >
                            <div className="sten-content__section">
                                <div className="flex-row">
                                    <div className="col-lg-4 col-xs-6">
                                        <PlatformStatisticsBox
                                            className="spread-v"
                                            totalUsersOnPlatform={this.props.platformStatistics?.TotalUsersOnPlatform}
                                        />
                                    </div>
                                    <div className="col-lg-20 col-xs-18">
                                        <PlatformStatisticsChart
                                            className="spread-v"
                                            chartData={this.props.chartData}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Accordion>
                    </ScrollArea>
                </div>
                <RightSideBar isCollapsed={!this.state.activeSidePanel}>
                    {this.getActivePanel()}
                </RightSideBar>
            </div>
        );
    }
}

AdminCompanyManagement.propTypes = {
    chartData: PropTypes.objectOf(PropTypes.any),
    companies: PropTypes.arrayOf(PropTypes.any).isRequired,
    getCompanies: PropTypes.func.isRequired,
    getPlatformStatistics: PropTypes.func.isRequired,
    platformStatistics: PropTypes.objectOf(PropTypes.any),
    router: TRouter.isRequired
};

AdminCompanyManagement.defaultProps = {
    chartData: null,
    platformStatistics: null
};

function mapStateToProps(state) {
    return {
        chartData: getPlatformStatisticsChartData(state),
        companies: state.adminCompanyManagementReducer.companies,
        platformStatistics: state.adminCompanyManagementReducer.platformStatistics
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCompanies: () => getCompanies(dispatch),
        getPlatformStatistics: () => getPlatformStatistics(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AdminCompanyManagement));
