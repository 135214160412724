import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* helpers */
import { fleetOptionRenderer } from 'utils/helpers/fleet-helper';
/* actions */
import {
    getCompanies,
    getFleets,
    getRegions,
    setSelectedPort,
    filterUpdated
} from './position-list-filters-actions';
import { updateUserSettings } from '../../user-actions';
/* selectors */
import { getRegionsSortedAlphabetically, getFiltersChanged } from '../position-list-selectors';
/* components */
import PortSelect from 'components/port-select/port-select';
import Select from 'components/select/select';
import Input from 'components/input/input';
import ValidationWrapper from 'components/validation/validation-wrapper';
import Validation from 'components/validation/validation';

const t = (key) => translate(`POSITION_LIST.FILTERS.${key}`);

const emptyArray = [];

class PositionListFilters extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedRegions: emptyArray,
            selectedCompanies: emptyArray,
            selectedFleets: emptyArray,
            selectedSpeed: '12.5',
            selectedPort: null,
            companies: emptyArray,
            fleets: emptyArray,
            regions: emptyArray
        };

        this.state = {
            ...this.state,
            ...PositionListFilters.getUpdatedState(props)
        };
    }

    componentDidMount() {
        this.props.getCompanies();
        this.props.getRegions();
        this.props.getFleets();
    }

    static getDerivedStateFromProps(props, state) {
        if (state.companies !== props.companies
            || state.fleets !== props.fleets
            || state.regions !== props.regions
            || state.selectedCompanies !== props.selectedCompanies
            || state.selectedRegions !== props.selectedRegions
            || state.selectedFleets !== props.selectedFleets
            || state.selectedSpeed !== props.selectedSpeed
            || state.selectedPort !== props.selectedPort) {
            const updatedState = PositionListFilters.getUpdatedState(props);
            return { updatedState };
        }
        return null;
    }

    onFilterUpdate(filterType, filterData) {
        switch (filterType) {
        case 'company':
            this.setState({
                selectedCompanies: filterData
            });
            break;
        case 'region':
            this.setState({
                selectedRegions: filterData
            });
            break;
        case 'port':
            this.setState({
                selectedPort: filterData
            });
            break;
        case 'fleet':
            this.setState({
                selectedFleets: filterData
            });
            break;
        default:
            this.setState({
                selectedSpeed: filterData
            });
        }
    }

    onFiltersReset = () => {
        this.setState({
            selectedFleets: emptyArray,
            selectedCompanies: emptyArray,
            selectedRegions: emptyArray,
            selectedSpeed: '12.5',
            selectedPort: null
        }, this.onFilterApply);
    };

    onFilterApply = () => {
        this.form.resetValidation();
        this.props.updateUserSettings({
            PositionListRegionsIds: this.state.selectedRegions.map(r => r.AreaId),
            PositionsListFilterSelectedCompanies: this.state.selectedCompanies.map(company => company.Id),
            PositionListSelectedVesselGroups: this.state.selectedFleets.map(f => f.VesselGroupId),
            VesselDefaultSpeed: parseFloat(this.state.selectedSpeed)
        }, 'PositionListFilters');
        this.props.setSelectedPort(this.state.selectedPort);
        this.props.filterUpdated();
    };

    static getUpdatedState = (props) => {
        return {
            selectedCompanies: PositionListFilters.filterObjectsByIds(
                props.companies,
                props.selectedCompanies,
                'Id'
            ),
            selectedFleets: PositionListFilters.filterObjectsByIds(props.fleets, props.selectedFleets, 'VesselGroupId'),
            selectedRegions: PositionListFilters.filterObjectsByIds(props.regions, props.selectedRegions, 'AreaId'),
            selectedSpeed: props.selectedSpeed.toString(),
            selectedPort: props.selectedPort,
            companies: props.companies,
            fleets: props.fleets,
            regions: props.regions
        };
    };

    static filterObjectsByIds(options, ids, idKey) {
        return options.length > 0 && ids.length > 0
            ? options.filter(option => ids.indexOf(option[idKey]) > -1)
            : emptyArray;
    }

    areFiltersChanged = () => {
        const companiesChanged = this.isArrayOfObjectsDifferent(
            this.state.selectedCompanies,
            this.props.selectedCompanies,
            'Id'
        );

        const fleetsChanged = this.isArrayOfObjectsDifferent(
            this.state.selectedFleets,
            this.props.selectedFleets,
            'VesselGroupId'
        );

        const regionsChanged = this.isArrayOfObjectsDifferent(
            this.state.selectedRegions,
            this.props.selectedRegions,
            'AreaId'
        );

        return this.state.selectedPort !== this.props.selectedPort
            || this.state.selectedSpeed !== this.props.selectedSpeed.toString()
            || companiesChanged
            || regionsChanged
            || fleetsChanged;
    };

    isArrayOfObjectsDifferent(arrA, arrB, idField) {
        return !(arrA.length === arrB.length
            && arrA.every(a => arrB.indexOf(a[idField]) !== -1)); // checks if IDs match
    }

    render() {
        const etaVisible = !!this.props.visibleColumns.find(column => column.ColumnPropertyName === 'EtaDate');
        return (
            <Validation.Form
                ref={(c) => { this.form = c; }}
                className="sten-position-list-filters sten-content__section flex-row flex-row--md flex-end"
                onSubmit={this.onFilterApply}
            >
                <div className="col-md-18 col-xs-24">
                    <div className="row">
                        <div className="col-md-6 col-xs-8">
                            <label className="label">{t('PORT')}</label>
                            <PortSelect
                                title={!etaVisible ? t('ETA_COLUMN_DISABLED') : ''}
                                excludePoints
                                clearable
                                disabled={!etaVisible}
                                placeholder={t('SEARCH_PORT')}
                                value={this.state.selectedPort}
                                onChange={this.onFilterUpdate.bind(this, 'port')}
                            />
                        </div>
                        <div className="col-md-3 col-xs-4">
                            <label className="label">{t('SPEED')}</label>
                            <ValidationWrapper
                                validate={etaVisible}
                                validations={{ required: true, numeric: { min: 10, max: 20 } }}
                                hintsOnHover
                            >
                                <Input
                                    title={!etaVisible ? t('ETA_COLUMN_DISABLED') : ''}
                                    disabled={!etaVisible}
                                    name="selectedSpeed"
                                    placeholder={t('SPEED')}
                                    onChange={this.onFilterUpdate.bind(this, 'speed')}
                                    value={this.state.selectedSpeed}
                                    suffix={translate('UNITS.SPEED')}
                                />
                            </ValidationWrapper>
                        </div>
                        <div className="col-md-5 col-xs-12">
                            <label className="label">{t('COMPANY_FLEET')}</label>
                            <Select
                                name="selectedCompanies"
                                searchable
                                multiple
                                clearable
                                valueKey="Id"
                                labelKey="Name"
                                options={this.props.companies}
                                placeholder={t('SELECT_COMPANY_FLEETS')}
                                value={this.state.selectedCompanies}
                                onChange={this.onFilterUpdate.bind(this, 'company')}
                            />
                        </div>
                        <div className="col-md-5 col-xs-12 col-pad--sm">
                            <label className="label">{t('REGION')}</label>
                            <Select
                                name="selectedRegions"
                                searchable
                                multiple
                                clearable
                                valueKey="AreaId"
                                labelKey="Name"
                                options={this.props.regions}
                                placeholder={t('SELECT_REGIONS')}
                                value={this.state.selectedRegions}
                                onChange={this.onFilterUpdate.bind(this, 'region')}
                            />
                        </div>
                        <div className="col-md-5 col-xs-12 col-pad--sm">
                            <label className="label">{t('FLEET')}</label>
                            <Select
                                name="selectedFleets"
                                searchable
                                multiple
                                clearable
                                valueKey="VesselGroupId"
                                labelKey="VesselGroupName"
                                options={this.props.fleets}
                                optionRenderer={fleetOptionRenderer}
                                placeholder={t('SELECT_FLEETS')}
                                value={this.state.selectedFleets}
                                onChange={this.onFilterUpdate.bind(this, 'fleet')}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-md-6 col-xs-24 col-pad--sm">
                    <div className="row">
                        <div className="col-xs-12">
                            <button
                                disabled={!this.props.filtersChanged}
                                type="button"
                                className="btn btn--secondary col-24"
                                onClick={this.onFiltersReset}
                            >
                                {t('RESET')}
                            </button>
                        </div>
                        <div className="col-xs-12">
                            <Validation.Button
                                className="btn btn--primary col-24"
                                disabled={!this.areFiltersChanged()}
                            >
                                {translate('GLOBAL.APPLY')}
                            </Validation.Button>
                        </div>
                    </div>
                </div>
            </Validation.Form>
        );
    }
}

PositionListFilters.propTypes = {
    companies: PropTypes.arrayOf(PropTypes.object).isRequired,
    filtersChanged: PropTypes.bool.isRequired,
    filterUpdated: PropTypes.func.isRequired,
    fleets: PropTypes.arrayOf(PropTypes.object).isRequired,
    getCompanies: PropTypes.func.isRequired,
    getFleets: PropTypes.func.isRequired,
    getRegions: PropTypes.func.isRequired,
    regions: PropTypes.arrayOf(PropTypes.object).isRequired,
    selectedCompanies: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedFleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedPort: PropTypes.objectOf(PropTypes.any),
    selectedRegions: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedSpeed: PropTypes.number.isRequired,
    setSelectedPort: PropTypes.func.isRequired,
    updateUserSettings: PropTypes.func.isRequired,
    visibleColumns: PropTypes.arrayOf(PropTypes.object).isRequired
};

PositionListFilters.defaultProps = {
    selectedPort: null
};

function mapStateToProps(state) {
    return {
        companies: state.positionListFiltersReducer.companies,
        filtersChanged: getFiltersChanged(state),
        fleets: state.positionListFiltersReducer.fleets,
        regions: getRegionsSortedAlphabetically(state),
        selectedCompanies: state.userReducer.settings
            ? state.userReducer.settings.PositionsListFilterSelectedCompanies
            : emptyArray,
        selectedFleets: state.userReducer.settings
            ? state.userReducer.settings.PositionListSelectedVesselGroups
            : emptyArray,
        selectedRegions: state.userReducer.settings
            ? state.userReducer.settings.PositionListRegionsIds
            : emptyArray,
        selectedPort: state.positionListFiltersReducer.selectedPort,
        selectedSpeed: state.userReducer.settings
            ? state.userReducer.settings.VesselDefaultSpeed
            : 12.5
    };
}

function mapDispatchToProps(dispatch) {
    return {
        filterUpdated: () => filterUpdated(dispatch),
        getFleets: () => getFleets(dispatch),
        getCompanies: () => getCompanies(dispatch),
        getRegions: () => getRegions(dispatch),
        setSelectedPort: selectedPort => setSelectedPort(dispatch, selectedPort),
        updateUserSettings: (settings, settingUpdated) => updateUserSettings(dispatch, settings, settingUpdated)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PositionListFilters);
