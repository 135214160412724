import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
/* utils */
import TimeHelper from 'utils/helpers/time-helper';
import { getClassName } from 'utils/helpers/info-helper';
/* style */
import './weather-routing-date-picker.scss';

const timeOptions = { time: true, day: true, utc: true };

class WeatherRoutingDatePicker extends PureComponent {
    handleValueChange = (interval) => {
        let newValue = TimeHelper.getDateFromDiff(this.props.value, interval);
        if (newValue > this.props.maxValue) {
            newValue = this.props.maxValue;
        }
        if (newValue < this.props.minValue) {
            newValue = this.props.minValue;
        }
        this.props.onChange(newValue);
    }

    handlePrevLongButtonClick = () => this.handleValueChange(-1 * this.props.intervalLong);

    handlePrevShortButtonClick = () => this.handleValueChange(-1 * this.props.intervalShort);

    handleNextShortButtonClick = () => this.handleValueChange(this.props.intervalShort);

    handleNextLongButtonClick = () => this.handleValueChange(this.props.intervalLong);

    render() {
        const {
            minValue,
            maxValue,
            value,
            intervalShortTitle,
            intervalLongTitle,
            isRightSideBarCollapsed
        } = this.props;
        const isPrevButtonDisabled = minValue && value <= minValue;
        const isNextButtonDisabled = maxValue && value >= maxValue;

        const className = getClassName('sten-weather-routing-date-picker', {
            'sten-weather-routing-date-picker--displaced': !isRightSideBarCollapsed
        });

        return (
            <div className={className}>
                <div className="sten-weather-routing-date-picker__wrapper">
                    <button
                        className="sten-weather-routing-date-picker__btn"
                        disabled={isPrevButtonDisabled}
                        onClick={this.handlePrevLongButtonClick}
                        title={`-${intervalLongTitle}`}
                    >
                        <span className="icon icon-angle-double-left" />
                    </button>
                    <button
                        className="sten-weather-routing-date-picker__btn"
                        disabled={isPrevButtonDisabled}
                        onClick={this.handlePrevShortButtonClick}
                        title={`-${intervalShortTitle}`}
                    >
                        <span className="icon icon-angle-left" />
                    </button>
                    <div className="sten-weather-routing-date-picker__date">
                        <p>{TimeHelper.getFormatted(value, timeOptions)}</p>
                    </div>
                    <button
                        className="sten-weather-routing-date-picker__btn"
                        disabled={isNextButtonDisabled}
                        onClick={this.handleNextShortButtonClick}
                    >
                        <span className="icon icon-angle-right" />
                    </button>
                    <button
                        className="sten-weather-routing-date-picker__btn"
                        disabled={isNextButtonDisabled}
                        onClick={this.handleNextLongButtonClick}
                    >
                        <span className="icon icon-angle-double-right" />
                    </button>
                </div>
            </div>
        );
    }
}

WeatherRoutingDatePicker.propTypes = {
    intervalLong: PropTypes.number,
    intervalLongTitle: PropTypes.string,
    intervalShort: PropTypes.number,
    intervalShortTitle: PropTypes.string,
    isRightSideBarCollapsed: PropTypes.bool,
    maxValue: PropTypes.string,
    minValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
};

WeatherRoutingDatePicker.defaultProps = {
    intervalLong: 24 * 3600 * 1000,
    intervalLongTitle: '1 day',
    intervalShort: 3600 * 1000,
    intervalShortTitle: '1 hour',
    isRightSideBarCollapsed: true,
    maxValue: '',
    minValue: '',
    value: ''
};

export default WeatherRoutingDatePicker;
