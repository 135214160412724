export default {
    SulphurContent: {
        PreviousFuelTypeQuantities: [
            {
                Quantity: 0.0125,
                Id: 7,
                Name: 'VLSFO',
                Sequence: 3
            },
            {
                Quantity: 0.23425,
                Id: 4,
                Name: 'LSMGO',
                Sequence: 7
            },
            {
                Quantity: 1.09175,
                Id: 2,
                Name: 'HSFO',
                Sequence: 1
            },
            {
                Quantity: 0.0,
                Id: 10,
                Name: 'BIOFO',
                Sequence: 8
            }
        ],
        FuelTypeQuantities: [
            {
                Quantity: 1.17,
                Id: 2,
                Name: 'HSFO',
                Sequence: 1
            },
            {
                Quantity: 0.129090909090909,
                Id: 4,
                Name: 'LSMGO',
                Sequence: 7
            },
            {
                Quantity: 0.429166666666667,
                Id: 7,
                Name: 'VLSFO',
                Sequence: 3
            },
            {
                Quantity: 0.0,
                Id: 10,
                Name: 'BIOFO',
                Sequence: 8
            }
        ]
    },
    BunkerConsumptionPropulsion: {
        PreviousBallast: 30.136469086469084,
        PreviousLaden: 32.706460206460207,
        PreviousPort: 8.6416666666666657,
        Ballast: 29.584462962962967,
        Laden: 35.07492711982016,
        Port: 13.687878787878789
    },
    BunkerConsumptionAuxilary: {
        PreviousBallast: 5.5246510434010423,
        PreviousLaden: 5.3045856920856913,
        PreviousPort: 126.03625,
        Ballast: 4.3207698412698416,
        Laden: 4.1219326454887959,
        Port: 28.569696969696967
    },
    BunkerConsumptionPropulsionAuxilary: {
        PreviousBallast: 35.661120129870135,
        PreviousLaden: 38.0110458985459,
        PreviousPort: 134.67791666666668,
        Ballast: 33.9052328042328,
        Laden: 39.196859765308957,
        Port: 42.257575757575758
    },
    BudgetConsumptionPropultionAuxilary: {
        PreviousBallast: null,
        PreviousLaden: null,
        PreviousPort: null,
        Ballast: null,
        Laden: null,
        Port: null
    },
    Draft: {
        PreviousBallast: 7.0954166666666669,
        PreviousLaden: 10.100000000000001,
        Ballast: 7.1,
        Laden: 10.148333333333337
    },
    Trim: {
        PreviousBallast: 0.40416666666666856,
        PreviousLaden: 0.0625,
        Ballast: 0.43333333333333268,
        Laden: 0.0
    },
    WindSpeed: {
        PreviousValue: 4.0174198509206152,
        Value: 4.2360307452249888
    },
    WniWindSpeed: {
        PreviousValue: 3.3454600471007585,
        Value: 3.65772201935995
    },
    TotalLoadDisch: {
        PreviousValue: null,
        Value: null
    },
    TotalCargoHeating: {
        PreviousValue: null,
        Value: null
    },
    TotalTankCleaning: {
        PreviousValue: 6.7,
        Value: null
    },
    TotalCargoCirculation: {
        PreviousValue: null,
        Value: null
    },
    TotalBallastExchange: {
        PreviousValue: null,
        Value: null
    },
    TotalIg: {
        PreviousValue: 254.70000000000002,
        Value: 128.0
    },
    BallastSpeed: {
        PreviousValue: 12.877775002775003,
        Value: 12.597381868131869
    },
    LadenSpeed: {
        PreviousValue: 12.501608114108114,
        Value: 12.549418598335711
    },
    LastPropPolish: {
        PreviousValue: null,
        Value: null
    },
    LastHullClean: {
        PreviousValue: null,
        Value: null
    },
    Sfoc: {
        PreviousValue: null,
        Value: null
    },
    AuxUtilization: {
        PreviousValue: 37.561688311688307,
        Value: 36.246704321523076
    },
    BoilerUtilization: {
        PreviousValue: 2.3363844488844494,
        Value: 2.3247524997524991
    }
};
