export default {
    PreviousPeriod: '13-24 months',
    Period: '1-12 months',
    Items: [
        {
            Id: 16,
            Title: 'Intermediate',
            TotalCargoPrevious: 629816.31024882288043599718160,
            TotalCargo: 288943.19958748775468643433501,
            TotalDistancePrevious: 83382.0,
            TotalDistance: 84773.0,
            NumberOfDaysAtSeaPrevious: 288.0,
            NumberOfDaysAtSea: 300.0,
            BallastLadenPrevious: {
                BallastPercent: 42.0,
                LadenPercent: 58.0
            },
            BallastLaden: {
                BallastPercent: 34.0,
                LadenPercent: 66.0
            },
            BallastAverageSpeedPrevious: 12.00044246031746,
            BallastAverageSpeed: 12.015651568589648,
            BallastAverageInstructedSpeedPrevious: 12.145337301587302,
            BallastAverageInstructedSpeed: 12.193877345135085,
            LadenAverageSpeedPrevious: 12.175512504244123,
            LadenAverageSpeed: 11.879389830077329,
            LadenAverageInstructedSpeedPrevious: 12.13821680996926,
            LadenAverageInstructedSpeed: 12.555710724460724,
            GoodTrimBallastPrevious: 1.2443939393939383,
            GoodTrimBallast: 1.4128571428571428,
            GoodTrimLadenPrevious: 0.46458022097727891,
            GoodTrimLaden: 0.33686082436082376,
            AuxiliaryRunningHoursPerDayPrevious: 32.706616654609917,
            AuxiliaryRunningHoursPerDay: 29.954997976876484,
            BoilerRunningHoursPerDayPrevious: 7.1775134298571812,
            BoilerRunningHoursPerDay: 3.3132912641786896,
            BunkerPrevious: 150.351030019148,
            Bunker: 503.06063801752907,
            EnergyEfficiencyPrevious: 715.62882378513416,
            EnergyEfficiency: 823.59663284288388,
            TotalBunkerPropAuxConsumptionPrevious: 5930.9299999999994,
            TotalBunkerPropAuxConsumption: 6213.4499999999971,
            AverageBallastMtDayPrevious: 15.958347107438009,
            AverageBallastMtDay: 15.430097087378643,
            AverageLadenMtDayPrevious: 16.825748502994013,
            AverageLadenMtDay: 16.692639593908634,
            AverageBallastMTPerKnmPrevious: 55.535231521426518,
            AverageBallastMTPerKnm: 54.207169412326472,
            AverageLadenMTPerKnmPrevious: 57.802600181025255,
            AverageLadenMTPerKnm: 59.30050131640639,
            AverageAmountOfCargoPrevious: 11501.148104376283,
            AverageAmountOfCargo: 11387.045458860121,
            FleetAverageAgePrevious: 12.254871763280562,
            FleetAverageAge: 13.256945727838353
        },
        {
            Id: 22,
            Title: 'Medium Range',
            TotalCargoPrevious: 13344370.703800375629355275250,
            TotalCargo: 11024688.592425644314571993999,
            TotalDistancePrevious: 2474578.0,
            TotalDistance: 2035232.0,
            NumberOfDaysAtSeaPrevious: 7975.0,
            NumberOfDaysAtSea: 6965.0,
            BallastLadenPrevious: {
                BallastPercent: 22.0,
                LadenPercent: 78.0
            },
            BallastLaden: {
                BallastPercent: 26.0,
                LadenPercent: 74.0
            },
            BallastAverageSpeedPrevious: 12.285610842421436,
            BallastAverageSpeed: 12.166534054130153,
            BallastAverageInstructedSpeedPrevious: 12.423292265760681,
            BallastAverageInstructedSpeed: 12.225013970907115,
            LadenAverageSpeedPrevious: 12.286547293737696,
            LadenAverageSpeed: 12.363647141594662,
            LadenAverageInstructedSpeedPrevious: 12.922100366190998,
            LadenAverageInstructedSpeed: 12.940605551989016,
            GoodTrimBallastPrevious: 1.3341859714273943,
            GoodTrimBallast: 1.4113819010987427,
            GoodTrimLadenPrevious: 0.23211994803142133,
            GoodTrimLaden: 0.21257602298067191,
            AuxiliaryRunningHoursPerDayPrevious: 29.896511894669711,
            AuxiliaryRunningHoursPerDay: 32.201697129053933,
            BoilerRunningHoursPerDayPrevious: 7.8985830910557651,
            BoilerRunningHoursPerDay: 7.8395227159130405,
            BunkerPrevious: 6.6052329319067526,
            Bunker: 6.19922659257325,
            EnergyEfficiencyPrevious: 1793.2085978893667,
            EnergyEfficiency: 1675.6256129177232,
            TotalBunkerPropAuxConsumptionPrevious: 203464.40459999981,
            TotalBunkerPropAuxConsumption: 179747.03000000064,
            AverageBallastMtDayPrevious: 19.183220823798592,
            AverageBallastMtDay: 19.118008973639977,
            AverageLadenMtDayPrevious: 22.802343022322226,
            AverageLadenMtDay: 22.793307603242,
            AverageBallastMTPerKnmPrevious: 63.753198851645337,
            AverageBallastMTPerKnm: 66.604811386009189,
            AverageLadenMTPerKnmPrevious: 72.867498234637239,
            AverageLadenMTPerKnm: 77.531412337555778,
            AverageAmountOfCargoPrevious: 35835.017114591523,
            AverageAmountOfCargo: 35758.793695141067,
            FleetAverageAgePrevious: 6.1716562634159509,
            FleetAverageAge: 6.9746534141674656
        },
        {
            Id: 17,
            Title: 'P-MAX',
            TotalCargoPrevious: 2860737.4901840454047035673695,
            TotalCargo: 2439366.5451303327015654439943,
            TotalDistancePrevious: 461186.0,
            TotalDistance: 418913.0,
            NumberOfDaysAtSeaPrevious: 1568.0,
            NumberOfDaysAtSea: 1427.0,
            BallastLadenPrevious: {
                BallastPercent: 47.0,
                LadenPercent: 53.0
            },
            BallastLaden: {
                BallastPercent: 37.0,
                LadenPercent: 63.0
            },
            BallastAverageSpeedPrevious: 11.960649676579328,
            BallastAverageSpeed: 12.022975831180259,
            BallastAverageInstructedSpeedPrevious: 11.989873182509259,
            BallastAverageInstructedSpeed: 12.17143198958982,
            LadenAverageSpeedPrevious: 12.654039081539159,
            LadenAverageSpeed: 12.46961797122365,
            LadenAverageInstructedSpeedPrevious: 13.006433189075912,
            LadenAverageInstructedSpeed: 12.843561265799499,
            GoodTrimBallastPrevious: 0.58465698653198572,
            GoodTrimBallast: 0.59672619047619246,
            GoodTrimLadenPrevious: 0.025162337662337109,
            GoodTrimLaden: 0.048673027544349878,
            AuxiliaryRunningHoursPerDayPrevious: 34.395246935850722,
            AuxiliaryRunningHoursPerDay: 34.593283751422142,
            BoilerRunningHoursPerDayPrevious: 3.652808342357317,
            BoilerRunningHoursPerDay: 2.794234231908395,
            BunkerPrevious: 30.761691191702827,
            Bunker: 27.058082428646571,
            EnergyEfficiencyPrevious: 1363.9928474236922,
            EnergyEfficiency: 1242.7715461597547,
            TotalBunkerPropAuxConsumptionPrevious: 62687.700000000048,
            TotalBunkerPropAuxConsumption: 58566.500000000022,
            AverageBallastMtDayPrevious: 29.760162601626018,
            AverageBallastMtDay: 29.448411214953289,
            AverageLadenMtDayPrevious: 37.035180722891575,
            AverageLadenMtDay: 36.64887892376678,
            AverageBallastMTPerKnmPrevious: 103.78901007504298,
            AverageBallastMTPerKnm: 103.07695327322925,
            AverageLadenMTPerKnmPrevious: 123.16667601593115,
            AverageLadenMTPerKnm: 122.86679670909957,
            AverageAmountOfCargoPrevious: 44411.859645671968,
            AverageAmountOfCargo: 42804.682902708846,
            FleetAverageAgePrevious: 11.640485430912342,
            FleetAverageAge: 12.642559395470135
        },
        {
            Id: 1,
            Title: 'Suezmax',
            TotalCargoPrevious: 22044170.148741265860831416141,
            TotalCargo: 15962171.466477414016900511573,
            TotalDistancePrevious: 1327350.0,
            TotalDistance: 1191491.0,
            NumberOfDaysAtSeaPrevious: 4514.0,
            NumberOfDaysAtSea: 4120.0,
            BallastLadenPrevious: {
                BallastPercent: 36.0,
                LadenPercent: 64.0
            },
            BallastLaden: {
                BallastPercent: 38.0,
                LadenPercent: 62.0
            },
            BallastAverageSpeedPrevious: 12.181858371007586,
            BallastAverageSpeed: 12.029100183555384,
            BallastAverageInstructedSpeedPrevious: 12.441656646734197,
            BallastAverageInstructedSpeed: 12.298216794924612,
            LadenAverageSpeedPrevious: 12.33479641634576,
            LadenAverageSpeed: 12.175185857342361,
            LadenAverageInstructedSpeedPrevious: 12.872479896455017,
            LadenAverageInstructedSpeed: 12.807920676763237,
            GoodTrimBallastPrevious: 2.8612699918219837,
            GoodTrimBallast: 2.8290099043086743,
            GoodTrimLadenPrevious: 0.094634076431249881,
            GoodTrimLaden: 0.2525223531258014,
            AuxiliaryRunningHoursPerDayPrevious: 28.494931101092252,
            AuxiliaryRunningHoursPerDay: 29.068647501591418,
            BoilerRunningHoursPerDayPrevious: 2.1681599418796269,
            BoilerRunningHoursPerDay: 2.4477429653527274,
            BunkerPrevious: 5.97100604970968,
            Bunker: 7.9033030793529306,
            EnergyEfficiencyPrevious: 3322.48646822676,
            EnergyEfficiency: 3322.1534248457024,
            TotalBunkerPropAuxConsumptionPrevious: 196055.97000000047,
            TotalBunkerPropAuxConsumption: 183003.27000000043,
            AverageBallastMtDayPrevious: 34.428084714548795,
            AverageBallastMtDay: 34.802178594046829,
            AverageLadenMtDayPrevious: 42.882481802426376,
            AverageLadenMtDay: 44.100157418339172,
            AverageBallastMTPerKnmPrevious: 117.07372824568982,
            AverageBallastMTPerKnm: 120.45074250643847,
            AverageLadenMTPerKnmPrevious: 145.83866454007804,
            AverageLadenMTPerKnm: 152.40538798203642,
            AverageAmountOfCargoPrevious: 130282.49332557335,
            AverageAmountOfCargo: 132049.17734983648,
            FleetAverageAgePrevious: 9.151803119791353,
            FleetAverageAge: 10.153877084349144
        }
    ],
    Title: null,
    TotalCargoPrevious: 38879094.652974509775326255943,
    TotalCargo: 29715169.803620878787724383900,
    TotalDistancePrevious: 4346496.0,
    TotalDistance: 3730409.0,
    NumberOfDaysAtSeaPrevious: 14345.0,
    NumberOfDaysAtSea: 12812.0,
    BallastLadenPrevious: {
        BallastPercent: 30.0,
        LadenPercent: 70.0
    },
    BallastLaden: {
        BallastPercent: 31.0,
        LadenPercent: 69.0
    },
    BallastAverageSpeedPrevious: 12.192683457384843,
    BallastAverageSpeed: 12.095371198377867,
    BallastAverageInstructedSpeedPrevious: 12.351165024859611,
    BallastAverageInstructedSpeed: 12.256705738980308,
    LadenAverageSpeedPrevious: 12.327609376616067,
    LadenAverageSpeed: 12.313183346259189,
    LadenAverageInstructedSpeedPrevious: 12.90635514429095,
    LadenAverageInstructedSpeed: 12.88513689001009,
    GoodTrimBallastPrevious: 1.6699746418952994,
    GoodTrimBallast: 1.7076104027482737,
    GoodTrimLadenPrevious: 0.20563634546250142,
    GoodTrimLaden: 0.20931734432550009,
    AuxiliaryRunningHoursPerDayPrevious: 29.954773995383668,
    AuxiliaryRunningHoursPerDay: 31.375087698776781,
    BoilerRunningHoursPerDayPrevious: 5.5024270344927517,
    BoilerRunningHoursPerDay: 5.3953125200191874,
    BunkerPrevious: 2.6347361348628677,
    Bunker: 2.9553438365263314,
    EnergyEfficiencyPrevious: 1326.9991619358727,
    EnergyEfficiency: 2271.2594002483597,
    TotalBunkerPropAuxConsumptionPrevious: 468139.0045999983,
    TotalBunkerPropAuxConsumption: 427530.24999999895,
    AverageBallastMtDayPrevious: 26.796406987724286,
    AverageBallastMtDay: 26.596062499999967,
    AverageLadenMtDayPrevious: 29.602853892570892,
    AverageLadenMtDay: 30.203435088515711,
    AverageBallastMTPerKnmPrevious: 90.706435617850474,
    AverageBallastMTPerKnm: 92.49388789194,
    AverageLadenMTPerKnmPrevious: 96.6867478638012,
    AverageLadenMTPerKnm: 103.150634070644,
    AverageAmountOfCargoPrevious: 62302.553726942431,
    AverageAmountOfCargo: 64036.291298824581,
    FleetAverageAgePrevious: 9.8047041443500511,
    FleetAverageAge: 10.757008905456274
};
