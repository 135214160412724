import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* router */
import { appRoutes } from 'app-router';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { joinArrayByProp } from 'utils/helpers/array-helper';
/* helpers */
import { formatNumber, getClassName } from 'utils/helpers/info-helper';
/* components */
import Checkbox from 'components/checkbox/checkbox';
import Menu, { MenuItem, SubMenu } from 'components/menu/menu';
import TextHighlight from 'components/text-highlight/text-highlight';
/* actions */
import { addToMyFleet, removeFromMyFleet } from '../fleet-management-actions';
import { openAssignToFleetModal } from 'components/assign-to-fleet-modal/assign-to-fleet-modal-actions';

class VesselListRow extends React.PureComponent {
    gerTechnicalDetails = (vessel) => {
        const technicalDetails = [];
        if (vessel.IMO) {
            technicalDetails.push(`${t('FLEET_MANAGEMENT.VESSEL_TABLE.IMO')} ${vessel.IMO}`);
        }
        if (vessel.DWT) {
            technicalDetails.push(
                `${t('FLEET_MANAGEMENT.VESSEL_TABLE.DWT')} ${formatNumber(vessel.DWT, 0)} ${t('UNITS.DWT')}`
            );
        }
        if (vessel.TotalCubicCapacity) {
            technicalDetails.push(`${
                t('FLEET_MANAGEMENT.VESSEL_TABLE.CAPACITY')
            } ${formatNumber(vessel.TotalCubicCapacity, 0)} ${t('UNITS.CUBIC_CAPACITY')}`);
        }
        if (vessel.IMOClass) {
            technicalDetails.push(`${
                t('FLEET_MANAGEMENT.VESSEL_TABLE.IMO_CLASS')
            } ${vessel.IMOClass && vessel.IMOClass.split(',').join('/')}`);
        }
        return technicalDetails.join(', ');
    };

    removeFromMyFleet = this.props.removeFromMyFleet.bind(this, this.props.vessel.IMO);

    addToMyFleet = this.props.addToMyFleet.bind(this, this.props.vessel.IMO);

    renderContextMenu = vessel => (
        <Menu>
            <MenuItem to={{ pathname: appRoutes.Map, search: `vessel=${vessel.IMO}` }}>
                {t('FLEET_MANAGEMENT.VESSEL_TABLE.LOCATE_ON_MAP')}
            </MenuItem>
            {vessel.IsInMyFleet
                ? (
                    <MenuItem onClick={this.removeFromMyFleet}>
                        {t('FLEET_MANAGEMENT.VESSEL_TABLE.REMOVE_FROM_MY_FLEET')}
                    </MenuItem>
                )
                : (
                    <MenuItem onClick={this.addToMyFleet}>
                        {t('FLEET_MANAGEMENT.VESSEL_TABLE.ADD_TO_MY_FLEET')}
                    </MenuItem>
                )
            }
            {this.props.permissions?.ShowVesselManagement && (
                <React.Fragment>
                    <MenuItem to={`${appRoutes.Admin.VesselManagement}/edit/${vessel.IMO}`}>
                        {t('FLEET_MANAGEMENT.VESSEL_TABLE.EDIT_VESSEL')}
                    </MenuItem>
                    <SubMenu text={t('FLEET_MANAGEMENT.VESSEL_TABLE.ASSIGN_TO_FLEET')}>
                        {this.props.companyFleets.map((fleet) => (
                            <MenuItem
                                onClick={this.props.openAssignToFleetModal.bind(this, vessel, fleet)}
                                key={fleet.Id}
                            >
                                {fleet.Name}
                            </MenuItem>
                        ))}
                    </SubMenu>
                </React.Fragment>
            )}
        </Menu>
    );

    handleSelectionChange = this.props.onSelectionChange.bind(this, this.props.vessel);

    render() {
        const { isSelected, isEditing, searchCriteria, vessel } = this.props;
        const vesselClassName = getClassName('text-uppercase', {
            'text-primary': vessel.IsVesselInUserScope
        });

        return (
            <tr>
                <td>
                    <div className="flex-row flex-center">
                        {isEditing && (
                            <div className="flex-shrink">
                                <Checkbox isChecked={isSelected} onChange={this.handleSelectionChange} />
                            </div>
                        )}
                        <div className="flex-grow">
                            <h3 className={vesselClassName}>
                                <TextHighlight input={vessel.Title} highlight={searchCriteria} />
                            </h3>
                            <p className="text-secondary">{this.gerTechnicalDetails(vessel)}</p>
                        </div>
                    </div>
                </td>
                <td>{vessel.CommercialOperator ? vessel.CommercialOperator.Name : '-'}</td>
                <td>
                    {vessel.CompanyFleets
                        ? joinArrayByProp(vessel.CompanyFleets, 'Name', ', ', '-')
                        : '-'}
                </td>
                <td>{vessel.Segment?.Name || '-'}</td>
                <td>{vessel.CoatingType || '-'}</td>
                {!isEditing && (
                    <td className="text-center">{this.renderContextMenu(vessel)}</td>
                )}
            </tr>
        );
    }
}

VesselListRow.propTypes = {
    addToMyFleet: PropTypes.func.isRequired,
    companyFleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    isEditing: PropTypes.bool,
    isSelected: PropTypes.bool,
    onSelectionChange: PropTypes.func.isRequired,
    openAssignToFleetModal: PropTypes.func.isRequired,
    permissions: PropTypes.objectOf(PropTypes.any).isRequired,
    removeFromMyFleet: PropTypes.func.isRequired,
    searchCriteria: PropTypes.string,
    vessel: PropTypes.objectOf(PropTypes.any).isRequired
};

VesselListRow.defaultProps = {
    isEditing: false,
    isSelected: false,
    searchCriteria: ''
};

function mapStateToProps(state) {
    return {
        companyFleets: state.fleetManagementReducer.options.companyFleets,
        permissions: state.userReducer.permissions
    };
}

function mapDispatchToProps(dispatch) {
    return {
        addToMyFleet: imo => addToMyFleet(dispatch, imo),
        openAssignToFleetModal: (vessel, fleet) => openAssignToFleetModal(dispatch, vessel, fleet),
        removeFromMyFleet: imo => removeFromMyFleet(dispatch, imo)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(VesselListRow);
