import React from 'react';
import PropTypes from 'prop-types';
/* helpers */
import { isBetween } from 'utils/helpers/info-helper';
/* styles */
import './gauge.scss';

class Gauge extends React.PureComponent {
    getWedgeClass(value, breakpoints) {
        this.classes = {
            1: ['low', 'high'],
            2: ['low', 'mid', 'high']
        };
        let colorClass = 'sten-gauge__meter-wedge';
        const classes = this.classes[breakpoints.length];
        let min;
        let max;
        if (classes) {
            for (let i = 0; i < classes.length; i++) {
                min = breakpoints[i - 1];
                max = breakpoints[i];
                if (i === 0) {
                    min = -Infinity;
                } else if (i === classes.length - 1) {
                    max = Infinity;
                }
                if (isBetween(value, min, max)) {
                    colorClass += ` sten-gauge__meter-wedge--${classes[i]}`;
                    break;
                }
            }
        }
        return colorClass;
    }

    render() {
        let gaugeClass = 'sten-gauge';
        if (this.props.className) {
            gaugeClass += ` ${this.props.className}`;
        }
        let degreesValue = -180;
        let wedgeStyle = {
            transform: `rotate(${degreesValue}deg)`
        };
        const value = this.props.breakpoints ? this.props.value : (this.props.value / this.props.max) * 100;
        if (typeof this.props.value === 'number' && this.props.value > 0) {
            degreesValue += Math.round((this.props.value / this.props.max) * 180);
            if (degreesValue > 0) {
                degreesValue = 0;
            }
            wedgeStyle = {
                transform: `rotate(${degreesValue}deg)`
            };
        }
        return (
            <div className={gaugeClass} title={this.props.tooltipTitle}>
                <div className="sten-gauge__meter">
                    <div className={this.getWedgeClass(value, this.props.breakpoints)} style={wedgeStyle} />
                </div>
                <div className="sten-gauge__value">{typeof this.props.value === 'number' ? this.props.value : '-'}</div>
                {this.props.valueUnit && <div className="sten-gauge__unit">{this.props.valueUnit}</div>}
            </div>
        );
    }
}

Gauge.propTypes = {
    breakpoints: PropTypes.arrayOf(PropTypes.any),
    className: PropTypes.string,
    max: PropTypes.number,
    tooltipTitle: PropTypes.string,
    value: PropTypes.number,
    valueUnit: PropTypes.string
};

Gauge.defaultProps = {
    breakpoints: [33, 66],
    className: '',
    max: 100,
    tooltipTitle: '',
    value: null,
    valueUnit: ''
};

export default Gauge;
