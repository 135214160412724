import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* components */
import IncludedVesselsSection from '../included-vessels-section';
import ScrollArea from 'components/scroll-area/scroll-area';
import Validation from 'components/validation/validation';
/* actions */
import { updateFilters } from '../../reports-actions';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.SIDEBAR.${key}`);

class FleetListReportInclusions extends React.PureComponent {
    handleFormSubmit = () => {
        const filters = {
            selectedFleetListVessels: this.includedVesselsSectionRef.state.selectedVessels
        };
        this.props.updateFilters(filters);

        if (this.props.onSubmit) {
            this.props.onSubmit(filters);
        }
    }

    renderFooter = () => (
        <footer className="sten-content__footer flex-row">
            <div className="col-24">
                <Validation.Button className="btn btn--primary col-24">
                    {t('CALCULATE')}
                </Validation.Button>
            </div>
        </footer>
    );

    render() {
        return (
            <Validation.Form
                className="sten-energy-management-reports-sidebar-inclusions"
                onSubmit={this.handleFormSubmit}
            >
                <ScrollArea
                    className="sten-content__body"
                >
                    <IncludedVesselsSection
                        showAdditionalVesselFilters
                        ref={(r) => { this.includedVesselsSectionRef = r; }}
                        segments={this.props.segments}
                        fleets={this.props.fleets}
                        customFleets={this.props.customFleets}
                        vessels={this.props.vesselsForFleetList}
                        vesselsByCustomFleet={this.props.vesselsByCustomFleet}
                        vesselsByFleet={this.props.vesselsByFleet}
                        vesselsBySegment={this.props.vesselsBySegment}
                        initialSelectedVessels={this.props.selectedFleetListVessels}
                    />
                </ScrollArea>
                {this.renderFooter()}
            </Validation.Form>
        );
    }
}

FleetListReportInclusions.propTypes = {
    customFleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    fleets: PropTypes.arrayOf(PropTypes.any).isRequired,
    onSubmit: PropTypes.func.isRequired,
    segments: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedFleetListVessels: PropTypes.objectOf(PropTypes.any).isRequired,
    updateFilters: PropTypes.func.isRequired,
    vesselsForFleetList: PropTypes.arrayOf(PropTypes.any).isRequired,
    vesselsByCustomFleet: PropTypes.objectOf(PropTypes.any).isRequired,
    vesselsByFleet: PropTypes.objectOf(PropTypes.any).isRequired,
    vesselsBySegment: PropTypes.objectOf(PropTypes.any).isRequired
};

function mapStateToProps(state) {
    return {
        customFleets: state.energyManagementReportsReducer.customFleets,
        fleets: state.energyManagementReportsReducer.fleets,
        segments: state.energyManagementReportsReducer.segments,
        selectedFleetListVessels: state.energyManagementReportsReducer.filters.selectedFleetListVessels,
        vesselsForFleetList: state.energyManagementReportsReducer.vesselsForFleetList
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateFilters: filters => updateFilters(dispatch, filters)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FleetListReportInclusions);
