import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* Components */
import Select from 'components/select/select';
import Input from 'components/input/input';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* actions */
import { getOptions, setValue } from './contact-list-filters-actions';

class ContactListFilters extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            searchCriteria: this.props.searchCriteria ? this.props.searchCriteria : ''
        };
    }

    componentDidMount() {
        this.props.getOptions();
    }

    handleValueChange = (key, value) => this.props.setValue(key, value);

    resetFilters = () => {
        this.setState(
            { searchCriteria: '' },
            this.handleValueChange('searchCriteria', ''),
            this.handleValueChange('selectedCompanies', []),
            this.handleValueChange('selectedDepartments', [])
        );
    };

    handleSearchCriteriaUpdate = value => {
        this.setState({
            searchCriteria: value
        });
        if (value.length >= 3 || !value) {
            if (this.debounce) {
                clearTimeout(this.debounce);
            }
            this.debounce = setTimeout(() => {
                this.handleValueChange('searchCriteria', value);
            }, 500);
        }
    };

    render() {
        const isResetBtnDisabled = this.state.searchCriteria === ''
            && this.props.selectedCompanies.length === 0 && this.props.selectedDepartments.length === 0;
        return (
            <div className="sten-contact-list-filters sten-content__section flex-row flex-end">
                <div className="col-lg-12 col-md-16 col-sm-16 col-xs-24">
                    <div className="row">
                        <div className="col-sm-8">
                            <label className="label">
                                {translate('CONTACT_LIST.FILTERS.SEARCH')}
                            </label>
                            <Input
                                suffixIcon="icon-search"
                                onChange={this.handleSearchCriteriaUpdate.bind(this)}
                                value={this.state.searchCriteria}
                                placeholder={translate('CONTACT_LIST.FILTERS.SEARCH_CRITERIA_PLACEHOLDER')}
                            />
                        </div>
                        <div className="col-sm-8 col-pad--xs">
                            <label className="label">
                                {translate('CONTACT_LIST.FILTERS.DEPARTMENT')}
                            </label>
                            <Select
                                name="selectedDepartments"
                                searchable
                                multiple
                                clearable
                                valueKey="Id"
                                labelKey="Name"
                                options={this.props.departments}
                                placeholder={translate('CONTACT_LIST.FILTERS.SELECT_DEPARTMENTS')}
                                value={this.props.selectedDepartments}
                                onChange={this.handleValueChange.bind(this, 'selectedDepartments')}
                            />
                        </div>
                        <div className="col-sm-8 col-pad--xs">
                            <label className="label">
                                {translate('CONTACT_LIST.FILTERS.COMPANY')}
                            </label>
                            <Select
                                name="selectedCompanies"
                                searchable
                                multiple
                                clearable
                                valueKey="Id"
                                labelKey="Name"
                                options={this.props.companies}
                                placeholder={translate('CONTACT_LIST.FILTERS.SELECT_COMPANIES')}
                                value={this.props.selectedCompanies}
                                onChange={this.handleValueChange.bind(this, 'selectedCompanies')}
                            />
                        </div>
                        <div className="show-xs col-xs-24 col-pad--xs">
                            <div className="row">
                                <div className="col-24">
                                    <button
                                        disabled={isResetBtnDisabled}
                                        type="button"
                                        className="btn btn--secondary col-24"
                                        onClick={this.resetFilters}
                                    >
                                        {translate('CONTACT_LIST.FILTERS.RESET_FILTERS')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hide-xs col-md-6 col-lg-3">
                    <div className="row">
                        <div className="col-24">
                            <button
                                disabled={isResetBtnDisabled}
                                type="button"
                                className="btn btn--secondary col-24"
                                onClick={this.resetFilters}
                            >
                                {translate('CONTACT_LIST.FILTERS.RESET_FILTERS')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ContactListFilters.propTypes = {
    companies: PropTypes.arrayOf(PropTypes.object).isRequired,
    departments: PropTypes.arrayOf(PropTypes.object).isRequired,
    getOptions: PropTypes.func.isRequired,
    searchCriteria: PropTypes.string.isRequired,
    selectedCompanies: PropTypes.arrayOf(PropTypes.any).isRequired,
    selectedDepartments: PropTypes.arrayOf(PropTypes.any).isRequired,
    setValue: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        companies: state.contactListFiltersReducer.companies,
        departments: state.contactListFiltersReducer.departments,
        searchCriteria: state.contactListFiltersReducer.searchCriteria,
        selectedCompanies: state.contactListFiltersReducer.selectedCompanies,
        selectedDepartments: state.contactListFiltersReducer.selectedDepartments
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setValue: (key, value) => setValue(dispatch, key, value),
        getOptions: () => getOptions(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactListFilters);
