import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
/* constants */
import { LEGEND_TYPES, legendValuesMap } from '../dtn-weather-constants';
/* selectors */
import { getSelectedLayerOptions } from '../dtn-weather-selectors';
/* styles */
import './dtn-weather-map-legend.scss';

class DtnWeatherMapLegend extends React.PureComponent {
    valueMap = {
        [LEGEND_TYPES.ICON]: (value) => <img src={value} alt="legend-icon" />,
        [LEGEND_TYPES.COLOR]: (value) => (
            <div
                className="sten-dtn-weather-map-legend__item-color"
                style={{ backgroundColor: value }}
            />
        )
    };

    renderLegendSection = ({ LegendType, LegendValues, label, id, Unit }) => {
        const keysWithValues = Object.keys(LegendValues).filter(key => (
            LegendValues[key] !== null && LegendValues[key] !== 'transparent'
        ));

        return (
            <div key={id} className="sten-dtn-weather-map-legend__section">
                <div className="sten-content__section-sub-header">{`${label}${Unit ? ` (${Unit})` : ''}`}</div>
                <div className="sten-dtn-weather-map-legend__section-body flex flex--wrap">
                    {keysWithValues.map(key => {
                        return (
                            <div key={key} className="sten-dtn-weather-map-legend__item">
                                {this.valueMap[LegendType](LegendValues[key])}
                                <p>{key}</p>
                            </div>
                        );
                    })}
                </div>
                <div className="sten-content__separator" />
            </div>
        );
    };

    render() {
        const { userSettings, selectedOptions } = this.props;
        const selectedColorOptions = [];
        const selectedIconOptions = [];
        selectedOptions.forEach(op => {
            if (legendValuesMap[op.id]) {
                if (legendValuesMap[op.id].LegendType === LEGEND_TYPES.COLOR) {
                    selectedColorOptions.push({ ...op, ...legendValuesMap[op.id] });
                }
                if (legendValuesMap[op.id].LegendType === LEGEND_TYPES.ICON) {
                    selectedIconOptions.push({ ...op, ...legendValuesMap[op.id] });
                }
            }
        });
        if (!userSettings.MapOptionsWeatherLayerEnabled
            || (selectedColorOptions.length === 0 && selectedIconOptions.length === 0)) {
            return null;
        }

        return (
            <div className="sten-dtn-weather-map-legend">
                {selectedColorOptions.map(this.renderLegendSection)}
                {selectedIconOptions.map(this.renderLegendSection)}
            </div>
        );
    }
}

DtnWeatherMapLegend.propTypes = {
    selectedOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    userSettings: PropTypes.objectOf(PropTypes.any).isRequired
};

function mapStateToProps(state) {
    return {
        selectedOptions: getSelectedLayerOptions(state),
        userSettings: state.userReducer.settings
    };
}

export default connect(mapStateToProps)(DtnWeatherMapLegend);
