import ApiHelper from './gsm-api';

class GsmPortService {
    constructor() {
        this.resourceUrl = 'Ports';
    }

    get(params = {}) {
        return ApiHelper.get(`${this.resourceUrl}`, params, true)
            .then(response => response.data, () => null);
    }
}

export default new GsmPortService();
