export const ActionTypes = {
    USER_GET: 'USER_GET',
    USER_SET: 'USER_SET',
    USER_SETTINGS_SET: 'USER_SETTINGS_SET',
    USER_SETTINGS_UPDATE: 'USER_SETTINGS_UPDATE',
    USER_SETTINGS_TOGGLE: 'USER_SETTINGS_TOGGLE',
    USER_RESOURCE_UPDATED: 'USER_RESOURCE_UPDATED'
};

export function getUser(dispatch) {
    dispatch({
        type: ActionTypes.USER_GET
    });
}

export function updateUserSettings(dispatch, settings, settingUpdated) {
    dispatch({
        type: ActionTypes.USER_SETTINGS_UPDATE,
        settings,
        settingUpdated
    });
}

export function setResourceUpdated(dispatch, resourceName) {
    dispatch({
        type: ActionTypes.USER_RESOURCE_UPDATED,
        resourceName
    });
}

export function toggleUserSetting(dispatch, settingName, value, settingUpdated) {
    dispatch({
        type: ActionTypes.USER_SETTINGS_TOGGLE,
        settingName,
        value,
        settingUpdated
    });
}
