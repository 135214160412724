import CoreApi from './core-api';

class QuestionnaireService {
    resourceUrl = 'Questionnaires';

    get(imo) {
        return CoreApi.get(this.resourceUrl, { imo })
            .then(response => response.data, () => null);
    }

    getById(id) {
        return CoreApi.get(`${this.resourceUrl}/${id}`)
            .then(response => response.data, () => null);
    }

    update(id, data) {
        return CoreApi.put(`${this.resourceUrl}/${id}`, data)
            .then(response => response.data, () => null);
    }

    remove(id) {
        return CoreApi.remove(`${this.resourceUrl}/${id}`)
            .then(() => true, () => false);
    }

    getBunkerTypes() {
        return CoreApi.get(`${this.resourceUrl}/BunkerTypes`)
            .then(response => response.data, () => null);
    }
}

export default new QuestionnaireService();
