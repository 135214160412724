import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ScrollArea from 'components/scroll-area/scroll-area';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* actions */
import { getMapOptions } from './map-options-actions';
import { updateUserSettings } from 'pages/user-pages/user-actions';
/* services */
import ConfigService from 'services/config-api/config-service';
/* components */
import Accordion from 'components/accordion/accordion';
import AreasOfInterest from './areas-of-interest/areas-of-interest';
import Checkbox from 'components/checkbox/checkbox';
import DtnWeatherMapOptions from 'components/dtn-weather/map-options/dtn-weather-map-options';
import ImageButton from 'components/image-button/image-button';
import MapLegend from './map-legend/map-legend';
import WniWeatherMapOptions from 'components/wni-weather/map-options/wni-weather-map-options';
/* image button src */
import imageButtonClassicSrc from 'assets/images/map-options/btn_classic_map.png';
import imageButtonSatelliteSrc from 'assets/images/map-options/btn_satelite_map.png';
import imageButtonNauticalSrc from 'assets/images/map-options/btn_nautical_map.png';

const mapImagesMap = {
    1: imageButtonClassicSrc,
    2: imageButtonSatelliteSrc,
    3: imageButtonNauticalSrc
};

export class MapOptions extends React.PureComponent {
    componentDidMount() {
        this.props.getMapOptions();
    }

    setMapType(mapTypeId) {
        this.props.updateUserSettings({
            [this.props.mapTypeSettingName]: mapTypeId
        });
    }

    userSettingToggleHandlers = {};

    getUserSettingToggleHandler = (name) => {
        if (!this.userSettingToggleHandlers[name]) {
            this.userSettingToggleHandlers[name] = () => {
                this.props.updateUserSettings({ [name]: !this.props.userSettings[name] });
            };
        }
        return this.userSettingToggleHandlers[name];
    };

    resetAll = () => {
        this.props.updateUserSettings({
            ArePortsInvisible: false,
            [this.props.mapTypeSettingName]: 1,
            WeatherLayerType: -1,
            MapOptionsCountryBordersNamesEnabled: false,
            ShowVesselNames: false,
            MapOptionsAreasOfInterestEnabled: false,
            MapOptionsWeatherLayerEnabled: false
        });
    };

    render() {
        const {
            showDTNWeather,
            showWNIWeather,
            showNauticalCharts,
            showNauticalLayers
        } = ConfigService.featureToggles;
        const { areNauticalChartsHidden, mapTypes, mapTypeSettingName, onCloseClick, userSettings } = this.props;
        const filteredMapTypes = areNauticalChartsHidden
            ? mapTypes.filter(mapType => mapType.Id !== 3)
            : mapTypes;
        const isNauticalMapSelected = userSettings[mapTypeSettingName] === 3;
        return (
            <div className="sten-map-options sten-content sten-content--has-footer">
                <div className="sten-content__header">
                    <h1 className="sten-content__title flex-grow">
                        {t('MAP_OPTIONS.TITLE')}
                    </h1>
                    <div className="flex-shrink">
                        {onCloseClick && (
                            <button className="btn-link icon icon-close" onClick={onCloseClick} />
                        )}
                    </div>
                </div>
                <ScrollArea className="sten-content__body">
                    <div className="sten-content__section">
                        <div className="flex-row">
                            {filteredMapTypes && filteredMapTypes.map(mapType => (
                                <div className="col-8" key={mapType.Id}>
                                    <ImageButton
                                        key={mapType.Id}
                                        imageSrc={mapImagesMap[mapType.Id]}
                                        text={mapType.Title}
                                        onClick={this.setMapType.bind(this, mapType.Id)}
                                        isActive={userSettings[mapTypeSettingName] === mapType.Id}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="sten-content__section">
                        {!isNauticalMapSelected && (
                            <div className="form-row">
                                <Checkbox
                                    className="text-uppercase"
                                    onChange={this.getUserSettingToggleHandler('MapOptionsCountryBordersNamesEnabled')}
                                    isChecked={userSettings.MapOptionsCountryBordersNamesEnabled}
                                >
                                    {t('MAP_OPTIONS.COUNTRY_NAMES_AND_BORDERS')}
                                </Checkbox>
                            </div>
                        )}
                        <div className="form-row">
                            <Checkbox
                                className="text-uppercase"
                                onChange={this.getUserSettingToggleHandler('ArePortsInvisible')}
                                isChecked={!userSettings.ArePortsInvisible}
                            >
                                {t('MAP_OPTIONS.PORTS')}
                            </Checkbox>
                        </div>
                        <div className="form-row">
                            <Checkbox
                                className="text-uppercase"
                                onChange={this.getUserSettingToggleHandler('ShowVesselNames')}
                                isChecked={userSettings.ShowVesselNames}
                            >
                                {t('MAP_OPTIONS.ALWAYS_SHOW_NAMES_FOR_VESSELS')}
                            </Checkbox>
                        </div>
                    </div>
                    <Accordion header={<h4 className="text-uppercase">{t('MAP_OPTIONS.LAYERS')}</h4>}>
                        <div className="sten-content__section">
                            <Checkbox
                                className="text-uppercase"
                                onChange={this.getUserSettingToggleHandler('MapOptionsAreasOfInterestEnabled')}
                                isChecked={userSettings.MapOptionsAreasOfInterestEnabled}
                            >
                                {t('MAP_OPTIONS.AREAS_OF_INTEREST')}
                            </Checkbox>
                            <AreasOfInterest />
                            {!isNauticalMapSelected && (showDTNWeather || showWNIWeather) && (
                                <Checkbox
                                    className="text-uppercase"
                                    onChange={this.getUserSettingToggleHandler('MapOptionsWeatherLayerEnabled')}
                                    isChecked={userSettings.MapOptionsWeatherLayerEnabled}
                                >
                                    {t('MAP_OPTIONS.WEATHER_CONDITIONS')}
                                </Checkbox>
                            )}
                            {!isNauticalMapSelected && showWNIWeather && <WniWeatherMapOptions />}
                            {!isNauticalMapSelected && showDTNWeather && <DtnWeatherMapOptions />}
                            {showNauticalCharts
                                && showNauticalLayers
                                && !isNauticalMapSelected
                                && !areNauticalChartsHidden && (
                                <Checkbox
                                    className="text-uppercase"
                                    onChange={this.getUserSettingToggleHandler('MapOptionsNauticalLayersEnabled')}
                                    isChecked={userSettings.MapOptionsNauticalLayersEnabled}
                                >
                                    {t('MAP_OPTIONS.NAVIGATIONAL_LAYERS')}
                                </Checkbox>
                            )}
                        </div>
                    </Accordion>
                    <Accordion header={<h4 className="text-uppercase">{t('MAP_OPTIONS.LEGEND.TITLE')}</h4>}>
                        <MapLegend isWeatherLegendHidden={isNauticalMapSelected} />
                    </Accordion>
                </ScrollArea>
                <footer className="sten-content__footer">
                    <div className="flex-grow text-right">
                        <button className="btn btn--secondary" onClick={this.resetAll}>
                            <span className="btn__icon icon icon-reload" />
                            {t('MAP_OPTIONS.RESET_ALL')}
                        </button>
                    </div>
                </footer>
            </div>
        );
    }
}

MapOptions.propTypes = {
    areNauticalChartsHidden: PropTypes.bool,
    areaTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    getMapOptions: PropTypes.func.isRequired,
    mapTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
    mapTypeSettingName: PropTypes.string,
    onCloseClick: PropTypes.func.isRequired,
    updateUserSettings: PropTypes.func.isRequired,
    userSettings: PropTypes.objectOf(PropTypes.any).isRequired
};

MapOptions.defaultProps = {
    areNauticalChartsHidden: false,
    mapTypeSettingName: 'MapOptionsMapType'
};

function mapStateToProps(state) {
    return {
        areaTypes: state.mapOptionsReducer.areaTypes,
        mapTypes: state.mapOptionsReducer.mapTypes,
        userSettings: state.userReducer.settings
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getMapOptions: () => getMapOptions(dispatch),
        updateUserSettings: settings => updateUserSettings(dispatch, settings, 'mapOptions')
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MapOptions);
