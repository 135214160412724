import { createSelector } from 'reselect';
/* helpers */
import { convertFixture, convertVoyageItinerary } from '../operators-diary-helpers';

export const getConvertedItinerary = createSelector(
    state => state.voyageInfoReducer.itinerary,
    convertVoyageItinerary
);

export const getConvertedVoyage = createSelector(
    state => state.voyageInfoReducer.voyage,
    (voyage) => (voyage ? ({
        ...voyage,
        Fixtures: voyage.Fixtures.map(convertFixture)
    }) : null)
);
