import { createSelector } from 'reselect';
/* helpers */
import { extractItemFuelData } from 'components/vessel-report/vessel-report-helpers';
/* selectors */
import { getAvailableRawReportTypes } from 'components/raw-reports-table/raw-reports-table-selectors';
/* services */
import ConfigService from 'services/config-api/config-service';

export const getSummary = createSelector(
    (state) => state.energyManagementReportsVoyageReportReducer.summaryReport,
    (summary) => {
        if (!summary) { return null; }
        const transformedSummary = { ...summary };
        if (summary.BallastReport) {
            transformedSummary.BallastReport = {
                ...summary.BallastReport,
                ...extractItemFuelData(summary.BallastReport.LoadingConsumptions, summary.Footprint.AvailableFuelTypes)
            };
        }
        if (summary.LadenReport) {
            transformedSummary.LadenReport = {
                ...summary.LadenReport,
                ...extractItemFuelData(
                    summary.LadenReport.DischargingConsumptions, summary.Footprint.AvailableFuelTypes
                )
            };
        }
        if (summary.Footprint) {
            transformedSummary.Footprint = {
                ...summary.Footprint,
                ...extractItemFuelData(summary.Footprint?.Consumptions, summary.Footprint.AvailableFuelTypes)
            };
        }

        return transformedSummary;
    }
);

export const getReportProps = createSelector(
    state => state.energyManagementReportsVoyageReportReducer.counts,
    getAvailableRawReportTypes,
    (counts, availableRawReportTypes) => {
        const reportProps = {};
        availableRawReportTypes.forEach((rt) => {
            reportProps[rt.type] = {
                ...rt,
                total: (counts?.[rt.key]) || 0,
                included: (counts?.[`Included${rt.key}`]) || 0,
                excluded: (counts?.[`Excluded${rt.key}`]) || 0,
                missing: (counts?.[`Missing${rt.key}`]) || 0,
                suspicious: (counts?.[`Suspicious${rt.key}`]) || 0,
                suspiciousLimitReached: counts?.SuspiciousValuesReportLimitReached || false,
                veracitySyncError: (ConfigService.featureToggles.showSyncVesselWithVeracityToggle
                    && counts?.[`VeracityError${rt.key}`]) || 0
            };
        });
        return reportProps;
    }
);

export default null;
