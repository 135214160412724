import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* helpers */
import { formatValue } from 'utils/helpers/info-helper';
/* components */
import EmptyContent from 'components/empty-content/empty-content';

const allowanceRowConfig = {
    key: 'Allowance',
    type: 'number',
    decimals: 1,
    unit: t('UNITS.PERCENTAGE')
};

const consumptionRowConfig = {
    key: 'Consumption',
    type: 'number',
    decimals: 1,
    unit: t('UNITS.CONSUMPTION')
};

class WeatherPerformanceReportCharterpartyTable extends React.PureComponent {
    formCharterpartyRows = (fuelRestrictions) => (fuelRestrictions.map(f => ({
        key: f.FuelType.Id,
        label: f.FuelType.DisplayName.toUpperCase(),
        allowance: `${this.props.allowanceType} ${formatValue(f, allowanceRowConfig)}`,
        consumption: formatValue(f, consumptionRowConfig)
    })))

    getRows = (data) => {
        const charterpartyTableRows = this.formCharterpartyRows(data);
        return charterpartyTableRows.map(row => (
            <tr key={row.key}>
                <td>{row.label}</td>
                <td>{row.consumption}</td>
                <td>{row.allowance}</td>
            </tr>
        ));
    };

    render() {
        return (
            <React.Fragment>
                {!this.props.data.length ? (<EmptyContent className="flex-grow" />) : (
                    <table className="sten-table sten-table--xs sten-table--no-border-bottom">
                        <thead>
                            <tr>
                                <th className="text-left text-secondary">
                                    {/* eslint-disable-next-line max-len */}
                                    {t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTERPARTY_PANEL.FUEL')}
                                </th>
                                <th className="text-left text-secondary">
                                    {/* eslint-disable-next-line max-len */}
                                    {t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTERPARTY_PANEL.CONSUMPTION')}
                                </th>
                                <th className="text-left text-secondary">
                                    {/* eslint-disable-next-line max-len */}
                                    {t('ENERGY_MANAGEMENT.REPORTS.PREVIEW.WEATHER_PERFORMANCE_REPORT.CHARTERPARTY_PANEL.DEVIATION')}
                                </th>
                            </tr>
                        </thead>
                        <tbody>{this.getRows(this.props.data)}</tbody>
                    </table>
                )}
            </React.Fragment>
        );
    }
}

WeatherPerformanceReportCharterpartyTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.any),
    allowanceType: PropTypes.string
};

WeatherPerformanceReportCharterpartyTable.defaultProps = {
    allowanceType: null,
    data: null
};

export default WeatherPerformanceReportCharterpartyTable;
