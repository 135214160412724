const toKebabCase = (string) => {
    return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();
};

const convertClass = (key, object, prefix) => {
    const values = [];
    Object.keys(object).forEach(styleKey => {
        values.push(` ${toKebabCase(styleKey)}: ${object[styleKey]};\n`);
    });
    return `${`${prefix !== '' ? `${prefix}-` : ''}${toKebabCase(key)}`} {\n${values.join('')}}\n`;
};

export const convertJSObjectToCssString = (style, prefix = '') => {
    const css = [];
    Object.keys(style).forEach((classKey) => {
        css.push(convertClass(classKey, style[classKey], prefix));
    });
    return css.join('');
};
