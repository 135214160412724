export const ActionTypes = {
    FLEET_RANGE_GET_VESSELS: 'FLEET_RANGE_GET_VESSELS',
    FLEET_RANGE_ADD_VESSELS: 'FLEET_RANGE_ADD_VESSELS',
    FLEET_RANGE_RESET_ALL: 'FLEET_RANGE_RESET_ALL',
    FLEET_RANGE_GENERATE_XLSX: 'FLEET_RANGE_GENERATE_XLSX'
};

export function getVessels(dispatch, params, overrideCurrent) {
    dispatch({
        type: ActionTypes.FLEET_RANGE_GET_VESSELS,
        params,
        overrideCurrent
    });
}

export function resetAll(dispatch) {
    dispatch({
        type: ActionTypes.FLEET_RANGE_RESET_ALL
    });
}
export function generateXLSX(dispatch) {
    dispatch({
        type: ActionTypes.FLEET_RANGE_GENERATE_XLSX
    });
}
