/* helpers */
import { getVesselTechnicalDetails } from '../../shared/get-vessel-technical-details';
import TimeHelper from 'utils/helpers/time-helper';
import { getSignature } from './position-list-compose-email-signature';
/* services */
import ConfigService from 'services/config-api/config-service';

/* eslint-disable max-len */
function getHeaderImage(signature, contentId) {
    if (signature && signature.id && signature.image) {
        return `<img src="${contentId ? `cid:${contentId}` : `data:image/png;base64,${signature.image}`}" alt="${signature.title}" style="max-height: 100px;padding-bottom: 0;display: inline !important;vertical-align: bottom;border: 0;outline: none;text-decoration: none;-ms-interpolation-mode: bicubic;" class="mcnImage" />`;
    }
    return '<p style="margin: 0;padding-top: 0;padding-right: 8px;padding-bottom: 4px;padding-left: 8px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #0f3646;font-size: 14px;line-height: 100%;text-align: left;">Please select a signature</p>';
}

function getMessage(message) {
    return message.split(/\n/).map(row => `<p style="font-size:14px">${row}</p>`).join('');
}

function getTableHeader(visibleColumns) {
    const colWidthMap = {
        1: 120, // vessel name
        2: 80, // open date
        3: 80, // next open
        4: 120, // selected port eta
        5: 220, // remaining itinerary
        6: 90, // last sire
        7: 160, // last cargoes
        8: 150, // note
        9: 60, // fixture status,
        10: 70, // coating,
        12: 90 // load ready date
    };
    let headerRow = '<tr>';
    visibleColumns.forEach((col) => {
        headerRow += `<th style="width: ${colWidthMap[col.ColumnId]}px;padding-bottom: 4px;padding-top: 4px;padding-left: 4px;padding-right: 4px;border: 1px solid #0e5370; background-color: #0f2e39; color: #fff;">${col.ColumnDisplayName}</th>`;
    });
    headerRow += '</tr>';
    return headerRow;
}

function getVesselNameCell(vessel, selectedExportVesselInfoConfig, vesselInfoConfigurableProperties) {
    let nameCell = `<div style="text-transform:uppercase;"><strong>${vessel.VesselName}</strong></div>`;
    nameCell = getVesselTechnicalDetails(vessel, selectedExportVesselInfoConfig, vesselInfoConfigurableProperties)
        .reduce((res, item) => `${res}<div>${item}</div>`, nameCell);
    return nameCell;
}

function getListCell(prop, vessel) {
    if (vessel[prop]) {
        let cell = '<ul style="padding-left:5px;margin:0;">';
        vessel[prop].split(/\n/).forEach((row) => {
            cell += `<li style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%; list-style: none;">${row}</li>`;
        });
        cell += '</ul>';
        return cell;
    }
    return '';
}

function getTableRow(vessel, index, visibleColumns, selectedExportVesselInfoConfig, vesselInfoConfigurableProperties) {
    const colValueMap = {
        1: getVesselNameCell(vessel, selectedExportVesselInfoConfig, vesselInfoConfigurableProperties),
        2: vessel.OpenDate ? TimeHelper.getFormatted(vessel.OpenDate, { utc: true }) : '',
        3: vessel.NextOpen,
        4: vessel.EtaDate ? TimeHelper.getFormatted(vessel.EtaDate, { utc: true }) : '',
        5: getListCell('RemainingItinerary', vessel),
        6: vessel.LastSireDate ? TimeHelper.getFormatted(vessel.LastSireDate, { utc: true }) : '',
        7: getListCell('LastCargoes', vessel),
        8: vessel.Note,
        9: vessel.FixtureStatus.FixtureStatusDisplayName,
        10: vessel.Coating ? vessel.Coating : '',
        12: vessel.LoadReadyDate ? TimeHelper.getFormatted(vessel.LoadReadyDate, { utc: true }) : ''
    };
    let tableRow = '<tr>';
    visibleColumns.forEach((col) => {
        tableRow += `<td style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;padding-bottom: 4px;padding-top: 4px;padding-left: 4px;padding-right: 4px;border: 1px solid #0e5370;">${colValueMap[col.ColumnId]}</td>`;
    });
    tableRow += '</tr>';
    return tableRow;
}

function getTable(vesselGroups, visibleColumns, selectedExportVesselInfoConfig, vesselInfoConfigurableProperties) {
    let table = '<table id="sten-table" style="color: #323747;min-width: 400px;max-width: 100%;font-size: 12px;border-collapse: collapse;mso-table-lspace: 0;mso-table-rspace: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">';
    table += getTableHeader(visibleColumns);
    Object.keys(vesselGroups).forEach((key) => {
        const group = vesselGroups[key];
        table += `<tr><td colSpan="${visibleColumns.length}" style="background-color: #0e5370; color: #fff; mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;padding-bottom: 4px;padding-top: 4px;padding-left: 4px;padding-right: 4px;border: 1px solid #0e5370;"><strong>${group.title} ${key}</strong></td></tr>`;
        group.forEach((vessel, index) => {
            table += getTableRow(
                vessel,
                index,
                visibleColumns,
                selectedExportVesselInfoConfig,
                vesselInfoConfigurableProperties
            );
        });
    });

    table += '</table>';
    return table;
}

/* eslint-disable indent */

function getHeader(signature, contentId) {
    return `
        <tr>
            <td align="center" valign="top" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                <table border="0" cellpadding="0" cellspacing="0" width="100%" id="templateHeader" style="border-collapse: collapse;mso-table-lspace: 0;mso-table-rspace: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #EDF1F3;border-top: 0;border-bottom: 0;">
                    <tr>
                        <td align="center" valign="top" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0;mso-table-rspace: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                <tr>
                                    <td valign="top" class="headerContainer" style="padding-top: 9px;padding-bottom: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                        <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnImageBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0;mso-table-rspace: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                            <tbody class="mcnImageBlockOuter">
                                                <tr>
                                                    <td valign="top" style="padding: 4px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" class="mcnImageBlockInner">
                                                        <table align="left" width="100%" border="0" cellpadding="0" cellspacing="0" class="mcnImageContentContainer" style="text-align: left!important;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0;mso-table-rspace: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                            <tbody>
                                                            <tr>
                                                                <td class="mcnImageContent" valign="top" style="padding-right: 9px;padding-left: 9px;padding-top: 0;padding-bottom: 0;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                    ${getHeaderImage(signature, contentId)}
                                                                </td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    `;
}

export function getEmailTemplate(
    message,
    vesselGroups,
    visibleColumns,
    signature,
    selectedPort,
    contentId,
    selectedExportVesselInfoConfig,
    vesselInfoConfigurableProperties
) {
    return `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN""http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
    <!-- NAME: SOFT -->
    <!--[if gte mso 15]>
    <xml>
        <o:OfficeDocumentSettings>
            <o:AllowPNG/>
            <o:PixelsPerInch>96</o:PixelsPerInch>
        </o:OfficeDocumentSettings>
    </xml>
    <![endif]-->
    <meta charset="UTF-8">
    <meta http-equiv="x-ua-compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <title>Position List Export 9-Oct-17</title>

    <style type="text/css">
        body, table, td {font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif !important;}
        #sten-table {
            max-width: 100%;
            min-width: 400px;
        }
        #sten-table th {
            padding-bottom: 4px;
            padding-top: 4px;
            padding-left: 4px;
            padding-right: 4px;
            border: 1px solid #0e5370;
        }
        .gs li {
            margin-left: 0px!important;
        }
        #sten-table td {
            padding-bottom: 4px;
            padding-top: 4px;
            padding-left: 4px;
            padding-right: 4px;
            border: 1px solid #0e5370;
        }

        #sten-table2 th {
            font-size: 14px;
            padding-bottom: 9px;
            padding-top: 9px;
            padding-left: 9px;
            padding-right: 9px;
            border: 1px solid #0e5370;
        }

        #sten-table2 td {
            font-size: 14px;
            padding-bottom: 9px;
            padding-top: 9px;
            padding-left: 9px;
            padding-right: 9px;
            border: 1px solid #0e5370;
        }

        p {
            margin: 10px 0;
            padding: 0;
        }

        table {
            border-collapse: collapse;
        }

        h1, h2, h3, h4, h5, h6 {
            display: block;
            margin: 0;
            padding: 0;
        }

        img, a img {
            border: 0;
            height: auto;
            outline: none;
            text-decoration: none;
        }

        body, #bodyTable, #bodyCell {
            height: 100%;
            margin: 0;
            padding: 0;
            width: 100%;
        }

        .mcnPreviewText {
            display: none !important;
        }

        #outlook a {
            padding: 0;
        }

        img {
            -ms-interpolation-mode: bicubic;
        }

        table {
            mso-table-lspace: 0;
            mso-table-rspace: 0;
        }

        .ReadMsgBody {
            width: 100%;
        }

        .ExternalClass {
            width: 100%;
        }

        p, a, li, td, blockquote {
            mso-line-height-rule: exactly;
        }

        a[href^=tel], a[href^=sms] {
            color: inherit;
            cursor: default;
            text-decoration: none;
        }

        p, a, li, td, body, table, blockquote {
            -ms-text-size-adjust: 100%;
            -webkit-text-size-adjust: 100%;
        }

        .ExternalClass, .ExternalClass p, .ExternalClass td, .ExternalClass div, .ExternalClass span, .ExternalClass font {
            line-height: 100%;
        }

        a[x-apple-data-detectors] {
            color: inherit !important;
            text-decoration: none !important;
            font-size: inherit !important;
            font-family: inherit !important;
            font-weight: inherit !important;
            line-height: inherit !important;
        }

        a.mcnButton {
            display: block;
        }

        .mcnImage {
            vertical-align: bottom;
        }

        .mcnTextContent {
            word-break: break-word;
        }

        .mcnTextContent img {
            height: auto !important;
        }

        .mcnDividerBlock {
            table-layout: fixed !important;
        }

        /*
        @tab Page
        @section background style
        @tip Set the background color and top border for your email. You may want to choose colors that match your company's branding.
        */
        body, #bodyTable, #templateFooter {
            /*@editable*/
            background-color: #EDF1F3;
        }

        /*
        @tab Page
        @section background style
        @tip Set the background color and top border for your email. You may want to choose colors that match your company's branding.
        */
        #bodyCell {
            /*@editable*/
            border-top: 0;
        }

        /*
        @tab Page
        @section heading 1
        @tip Set the styling for all first-level headings in your emails. These should be the largest of your headings.
        @style heading 1
        */
        h1 {
            /*@editable*/
            color: #00535E !important;
            /*@editable*/
            font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
            /*@editable*/
            font-size: 26px;
            /*@editable*/
            font-style: normal;
            /*@editable*/
            font-weight: normal;
            /*@editable*/
            line-height: 100%;
            /*@editable*/
            letter-spacing: normal;
            /*@editable*/
            text-align: left;
        }

        /*
        @tab Page
        @section heading 2
        @tip Set the styling for all second-level headings in your emails.
        @style heading 2
        */
        h2 {
            /*@editable*/
            color: #404448 !important;
            /*@editable*/
            font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
            /*@editable*/
            font-size: 20px;
            /*@editable*/
            font-style: normal;
            /*@editable*/
            font-weight: bold;
            /*@editable*/
            line-height: 100%;
            /*@editable*/
            letter-spacing: normal;
            /*@editable*/
            text-align: left;
        }

        /*
        @tab Page
        @section heading 3
        @tip Set the styling for all third-level headings in your emails.
        @style heading 3
        */
        h3 {
            /*@editable*/
            color: #404448 !important;
            /*@editable*/
            font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
            /*@editable*/
            font-size: 18px;
            /*@editable*/
            font-style: normal;
            /*@editable*/
            font-weight: bold;
            /*@editable*/
            line-height: 100%;
            /*@editable*/
            letter-spacing: normal;
            /*@editable*/
            text-align: left;
        }

        /*
        @tab Page
        @section heading 4
        @tip Set the styling for all fourth-level headings in your emails. These should be the smallest of your headings.
        @style heading 4
        */
        h4 {
            /*@editable*/
            color: #404448 !important;
            /*@editable*/
            font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
            /*@editable*/
            font-size: 16px;
            /*@editable*/
            font-style: normal;
            /*@editable*/
            font-weight: bold;
            /*@editable*/
            line-height: 100%;
            /*@editable*/
            letter-spacing: normal;
            /*@editable*/
            text-align: left;
        }

        /*
        @tab Preheader
        @section preheader style
        @tip Set the background color and borders for your email's preheader area.
        */
        #templatePreheader {
            /*@editable*/
            background-color: #FFFFFF;
            /*@editable*/
            border-top: 0;
            /*@editable*/
            border-bottom: 1px solid #E3E5E6;
        }

        /*
        @tab Preheader
        @section preheader text
        @tip Set the styling for your email's preheader text. Choose a size and color that is easy to read.
        */
        .preheaderContainer .mcnTextContent, .preheaderContainer .mcnTextContent p {
            /*@editable*/
            color: #0f3747;
            /*@editable*/
            font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
            /*@editable*/
            font-size: 12px;
            /*@editable*/
            line-height: 125%;
            /*@editable*/
            text-align: left;
        }

        /*
        @tab Preheader
        @section preheader link
        @tip Set the styling for your email's header links. Choose a color that helps them stand out from your text.
        */
        .preheaderContainer .mcnTextContent a {
            /*@editable*/
            color: #0f3646;
            /*@editable*/
            font-weight: normal;
            /*@editable*/
            text-decoration: underline;
        }

        /*
        @tab Header
        @section header style
        @tip Set the background color and borders for your email's header area.
        */
        #templateHeader {
            /*@editable*/
            background-color: #EDF1F3;
            /*@editable*/
            border-top: 0;
            /*@editable*/
            border-bottom: 0;
        }

        /*
        @tab Header
        @section header text
        @tip Set the styling for your email's header text. Choose a size and color that is easy to read.
        */
        .headerContainer .mcnTextContent, .headerContainer .mcnTextContent p {
            /*@editable*/
            color: #0f3646;
            /*@editable*/
            font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
            /*@editable*/
            font-size: 16px;
            /*@editable*/
            line-height: 100%;
            /*@editable*/
            text-align: center;
        }

        /*
        @tab Header
        @section header link
        @tip Set the styling for your email's header links. Choose a color that helps them stand out from your text.
        */
        .headerContainer .mcnTextContent a {
            /*@editable*/
            color: #0f3646;
            /*@editable*/
            font-weight: normal;
            /*@editable*/
            text-decoration: underline;
        }

        /*
        @tab Body
        @section body style
        @tip Set the background color and borders for your email's body area.
        */
        #templateBody {
            /*@editable*/
            background-color: #EDF1F3;
            /*@editable*/
            border-top: 0;
            /*@editable*/
            border-bottom: 0;
        }

        /*
        @tab Body
        @section body container
        @tip Set the background color and border radius for your email's body container. [Note: Border radius is not supported in all email clients.]
        */
        #bodyBackground {
            /*@tab Body
@section body container
@tip Set the background color and border radius for your email's body container. [Note: Border radius is not supported in all email clients.]*/
            border-collapse: separate !important;
            /*@editable*/
            background-color: #FFFFFF;
            /*@editable*/
            border: 1px solid #E3E5E6;
            /*@editable*/
            border-radius: 4px;
        }

        /*
        @tab Body
        @section body text
        @tip Set the styling for your email's body text. Choose a size and color that is easy to read.
        */
        .bodyContainer .mcnTextContent, .bodyContainer .mcnTextContent p {
            /*@editable*/
            color: #0f3646;
            /*@editable*/
            font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
            /*@editable*/
            font-size: 18px;
            /*@editable*/
            line-height: 100%;
            /*@editable*/
            text-align: left;
        }

        /*
        @tab Body
        @section body link
        @tip Set the styling for your email's body links. Choose a color that helps them stand out from your text.
        */
        .bodyContainer .mcnTextContent a {
            /*@editable*/
            color: #404448;
            /*@editable*/
            font-weight: normal;
            /*@editable*/
            text-decoration: underline;
        }

        /*
        @tab Footer
        @section footer text
        @tip Set the styling for your email's footer text. Choose a size and color that is easy to read.
        */
        .footerContainer .mcnTextContent, .footerContainer .mcnTextContent p {
            /*@editable*/
            color: #0f3646;
            /*@editable*/
            font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;
            /*@editable*/
            font-size: 12px;
            /*@editable*/
            line-height: 125%;
            /*@editable*/
            text-align: center;
        }

        /*
        @tab Footer
        @section footer link
        @tip Set the styling for your email's footer links. Choose a color that helps them stand out from your text.
        */
        .footerContainer .mcnTextContent a {
            /*@editable*/
            color: #0f3646;
            /*@editable*/
            font-weight: normal;
            /*@editable*/
            text-decoration: underline;
        }

        @media only screen and (max-width: 480px) {
            body, table, td, p, a, li, blockquote {
                -webkit-text-size-adjust: none !important;
            }

        }

        @media only screen and (max-width: 480px) {
            body {
                /* width: 100% !important; */
                min-width: 100% !important;
            }

        }

        @media only screen and (max-width: 480px) {
            .templateContainer {
                width: 100% !important;
            }

        }

        @media only screen and (max-width: 480px) {
            .mcnImage {
                height: auto !important;
                width: 100% !important;
            }

        }

        @media only screen and (max-width: 480px) {
            .mcnCartContainer, .mcnCaptionTopContent, .mcnRecContentContainer, .mcnCaptionBottomContent, .mcnTextContentContainer, .mcnBoxedTextContentContainer, .mcnImageGroupContentContainer, .mcnCaptionLeftTextContentContainer, .mcnCaptionRightTextContentContainer, .mcnCaptionLeftImageContentContainer, .mcnCaptionRightImageContentContainer, .mcnImageCardLeftTextContentContainer, .mcnImageCardRightTextContentContainer {
                max-width: 100% !important;
                width: 100% !important;
            }

        }

        @media only screen and (max-width: 480px) {
            .mcnBoxedTextContentContainer {
                min-width: 100% !important;
            }

        }

        @media only screen and (max-width: 480px) {
            .mcnImageGroupContent {
                padding: 9px !important;
            }

        }

        @media only screen and (max-width: 480px) {
            .mcnCaptionLeftContentOuter .mcnTextContent, .mcnCaptionRightContentOuter .mcnTextContent {
                padding-top: 9px !important;
            }

        }

        @media only screen and (max-width: 480px) {
            .mcnImageCardTopImageContent, .mcnCaptionBlockInner .mcnCaptionTopContent:last-child .mcnTextContent {
                padding-top: 18px !important;
            }

        }

        @media only screen and (max-width: 480px) {
            .mcnImageCardBottomImageContent {
                padding-bottom: 9px !important;
            }

        }

        @media only screen and (max-width: 480px) {
            .mcnImageGroupBlockInner {
                padding-top: 0 !important;
                padding-bottom: 0 !important;
            }

        }

        @media only screen and (max-width: 480px) {
            .mcnImageGroupBlockOuter {
                padding-top: 9px !important;
                padding-bottom: 9px !important;
            }

        }

        @media only screen and (max-width: 480px) {
            .mcnTextContent, .mcnBoxedTextContentColumn {
                padding-right: 5px !important;
                padding-left: 5px !important;
            }

        }

        @media only screen and (max-width: 480px) {
            .mcnImageCardLeftImageContent, .mcnImageCardRightImageContent {
                padding-right: 5px !important;
                padding-bottom: 0 !important;
                padding-left: 5px !important;
            }

        }

        @media only screen and (max-width: 480px) {
            .mcpreview-image-uploader {
                display: none !important;
                width: 100% !important;
            }

        }

        @media only screen and (max-width: 480px) {
            /*
            @tab Mobile Styles
            @section heading 1
            @tip Make the first-level headings larger in size for better readability on small screens.
            */
            h1 {
                /*@editable*/
                font-size: 24px !important;
                /*@editable*/
                line-height: 125% !important;
            }

        }

        @media only screen and (max-width: 480px) {
            /*
            @tab Mobile Styles
            @section heading 2
            @tip Make the second-level headings larger in size for better readability on small screens.
            */
            h2 {
                /*@editable*/
                font-size: 20px !important;
                /*@editable*/
                line-height: 125% !important;
            }

        }

        @media only screen and (max-width: 480px) {
            /*
            @tab Mobile Styles
            @section heading 3
            @tip Make the third-level headings larger in size for better readability on small screens.
            */
            h3 {
                /*@editable*/
                font-size: 18px !important;
                /*@editable*/
                line-height: 125% !important;
            }

        }

        @media only screen and (max-width: 480px) {
            /*
            @tab Mobile Styles
            @section heading 4
            @tip Make the fourth-level headings larger in size for better readability on small screens.
            */
            h4 {
                /*@editable*/
                font-size: 16px !important;
                /*@editable*/
                line-height: 125% !important;
            }

        }

        @media only screen and (max-width: 480px) {
            /*
            @tab Mobile Styles
            @section Boxed Text
            @tip Make the boxed text larger in size for better readability on small screens. We recommend a font size of at least 16px.
            */
            .mcnBoxedTextContentContainer .mcnTextContent, .mcnBoxedTextContentContainer .mcnTextContent p {
                /*@editable*/
                font-size: 18px !important;
                /*@editable*/
                line-height: 125% !important;
            }

        }

        @media only screen and (max-width: 480px) {
            /*
            @tab Mobile Styles
            @section Preheader Visibility
            @tip Set the visibility of the email's preheader on small screens. You can hide it to save space.
            */
            #templatePreheader {
                /*@editable*/
                display: block !important;
            }

        }

        @media only screen and (max-width: 480px) {
            /*
            @tab Mobile Styles
            @section Preheader Text
            @tip Make the preheader text larger in size for better readability on small screens.
            */
            .preheaderContainer .mcnTextContent, .preheaderContainer .mcnTextContent p {
                /*@editable*/
                font-size: 14px !important;
                /*@editable*/
                line-height: 115% !important;
            }

        }

        @media only screen and (max-width: 480px) {
            /*
            @tab Mobile Styles
            @section Header Text
            @tip Make the header text larger in size for better readability on small screens.
            */
            .headerContainer .mcnTextContent, .headerContainer .mcnTextContent p {
                /*@editable*/
                font-size: 18px !important;
                /*@editable*/
                line-height: 125% !important;
            }

        }

        @media only screen and (max-width: 480px) {
            /*
            @tab Mobile Styles
            @section Body Text
            @tip Make the body text larger in size for better readability on small screens. We recommend a font size of at least 16px.
            */
            .bodyContainer .mcnTextContent, .bodyContainer .mcnTextContent p {
                /*@editable*/
                font-size: 18px !important;
                /*@editable*/
                line-height: 125% !important;
            }

        }

        @media only screen and (max-width: 480px) {
            /*
            @tab Mobile Styles
            @section footer text
            @tip Make the body content text larger in size for better readability on small screens.
            */
            .footerContainer .mcnTextContent, .footerContainer .mcnTextContent p {
                /*@editable*/
                font-size: 14px !important;
                /*@editable*/
                line-height: 115% !important;
            }

        }</style>
</head>
<body leftmargin="0" marginwidth="0" topmargin="0" marginheight="0" offset="0" style="height: 100%;margin: 0;padding: 0;width: 100%;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #EDF1F3;">
<center>
    <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" id="bodyTable" style="height: 100%;border-collapse: collapse;mso-table-lspace: 0;mso-table-rspace: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;margin: 0;padding: 0;background-color: #EDF1F3;">
        <tr>
            <td align="center" valign="top" id="bodyCell" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;height: 100%;margin: 0;padding: 0;width: 100%;border-top: 0;">
                <!-- BEGIN TEMPLATE // -->
                <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;mso-table-lspace: 0;mso-table-rspace: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                    <!-- BEGIN HEADER // -->
                    ${ConfigService.featureToggles.showPositionListSignature && signature.image ? getHeader(signature, contentId) : ''}
                    <!-- // END HEADER -->
                    <tr>
                        <td align="center" valign="top" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                            <!-- BEGIN BODY // -->
                            <table border="0" cellpadding="0" cellspacing="0" width="100%" id="templateBody" style="border-collapse: collapse;mso-table-lspace: 0;mso-table-rspace: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #EDF1F3;border-top: 0;border-bottom: 0;">
                                <tr>
                                    <td align="center" valign="top" class="mobilePaddingRL5" style="padding-top: 9px;padding-bottom: 9px;padding-left:9px; padding-right:9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                        <table border="0" cellpadding="0" cellspacing="0" width="100%" id="bodyBackground" class="templateContainer" style="border-collapse: separate !important;mso-table-lspace: 0;mso-table-rspace: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #FFFFFF;border: 1px solid #E3E5E6;border-radius: 4px;">
                                            <tr>
                                                <td align="center" valign="top" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                    <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;mso-table-lspace: 0;mso-table-rspace: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                        <tr>
                                                            <td valign="top" class="bodyContainer" style="padding-top: 9px;padding-bottom: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                <table border="0" cellpadding="0" cellspacing="0" width="1000" class="mcnTextBlock" style="min-width: 1000px;border-collapse: collapse;mso-table-lspace: 0;mso-table-rspace: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                    <tbody class="mcnTextBlockOuter">
                                                                    <tr>
                                                                        <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                            <!--[if mso]>
                                                                            <table align="left" border="0"
                                                                                   cellspacing="0" cellpadding="0"
                                                                                   width="100%" style="width:100%;">
                                                                                <tr>
                                                                            <![endif]-->
                                                                            <!--[if mso]>
                                                                            <td valign="top" width="598"
                                                                                style="width:598px;">
                                                                            <![endif]-->
                                                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0;mso-table-rspace: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 9px;padding-bottom: 9px;padding-left: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #0f3646;font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;font-size: 18px;line-height: 100%;text-align: left;">
                                                                                        ${getMessage(message)}
                                                                                    </td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <!--[if mso]>
                                                                            </td>
                                                                            <![endif]-->
                                                                            <!--[if mso]>
                                                                            </tr>
                                                                            </table>
                                                                            <![endif]-->
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" class="mcnTextBlock" style="min-width: 100%;border-collapse: collapse;mso-table-lspace: 0;mso-table-rspace: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                    <tbody class="mcnTextBlockOuter">
                                                                    <tr>
                                                                        <td valign="top" class="mcnTextBlockInner" style="padding-top: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                                                            <!--[if mso]>
                                                                            <table align="left" border="0"
                                                                                   cellspacing="0" cellpadding="0"
                                                                                   width="100%" style="width:100%;">
                                                                                <tr>
                                                                            <![endif]-->
                                                                            <!--[if mso]>
                                                                            <td valign="top" width="598"
                                                                                style="width:598px;">
                                                                            <![endif]-->
                                                                            ${selectedPort && selectedPort.Name
                                                                                ? (
                                                                                    `<table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0;mso-table-rspace: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                                                                                        <tbody>
                                                                                            <tr>
                                                                                                <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 9px;padding-bottom: 9px;padding-left: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #0f3646;font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;font-size: 14px;line-height: 100%;text-align: left;">
                                                                                                    Selected Port: <b>${selectedPort.Name}</b>
                                                                                                </td>
                                                                                            </tr>
                                                                                        </tbody>
                                                                                    </table>`
                                                                                ) : ''
                                                                            }
                                                                            <table align="left" border="0" cellpadding="0" cellspacing="0" style="max-width: 100%;min-width: 100%;border-collapse: collapse;mso-table-lspace: 0;mso-table-rspace: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;" width="100%" class="mcnTextContentContainer">
                                                                                <tbody>
                                                                                <tr>
                                                                                    <td valign="top" class="mcnTextContent" style="padding-top: 0;padding-right: 9px;padding-bottom: 9px;padding-left: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;word-break: break-word;color: #0f3646;font-family: 'Helvetica Neue', Helvetica, Arial, Verdana, sans-serif;font-size: 18px;line-height: 100%;text-align: left;">
                                                                                        ${getTable(
                                                                                            vesselGroups,
                                                                                            visibleColumns,
                                                                                            selectedExportVesselInfoConfig,
                                                                                            vesselInfoConfigurableProperties
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            <!--[if mso]>
                                                                            </td>
                                                                            <![endif]-->
                                                                            <!--[if mso]>
                                                                            </tr>
                                                                            </table>
                                                                            <![endif]-->
                                                                        </td>
                                                                    </tr>
                                                                    </tbody>
                                                                </table>
                                                                ${ConfigService.featureToggles.showPositionListSignature
                                                                    ? getSignature(signature, contentId)
                                                                    : ''}
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                            <!-- // END BODY -->
                        </td>
                    </tr>
                    <tr>
                        <td align="center" valign="top" style="padding-bottom: 40px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                            <!-- BEGIN FOOTER // -->
                            <table border="0" cellpadding="0" cellspacing="0" width="100%" id="templateFooter" style="border-collapse: collapse;mso-table-lspace: 0;mso-table-rspace: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;background-color: #EDF1F3;">
                                <tr>
                                    <td align="center" valign="top" style="mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                        <table border="0" cellpadding="0" cellspacing="0" width="100%" class="templateContainer" style="border-collapse: collapse;mso-table-lspace: 0;mso-table-rspace: 0;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;">
                                            <tr>
                                                <td valign="top" class="footerContainer" style="padding-top: 9px;padding-bottom: 9px;mso-line-height-rule: exactly;-ms-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;"></td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                            <!-- // END FOOTER -->
                        </td>
                    </tr>
                </table>
                <!-- // END TEMPLATE -->
            </td>
        </tr>
    </table>
</center>
</body>
</html>
`;
}

/* eslint-enable max-len */

export default getEmailTemplate;
