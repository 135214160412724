/* common */
import { t, tu, commonFields, commonData } from '../vessel-report-common-fields';

export const fieldMap = {

    // Basic info

    Vessel: commonFields.Vessel(),
    CallSign: commonFields.CallSign(),
    VoyageNumber: commonFields.VoyageNumber(),
    CargoStatus: commonFields.CargoStatus(),
    VesselStatus: commonFields.VesselStatus(),

    // Since last event

    TimeElapsedSinceLastEvent: commonFields.String('TimeElapsedSinceLastEvent', t('TIME_SINCE_LAST_EVENT')),
    TimeAtAnchorSinceLastEvent: commonFields.String('TimeAtAnchorSinceLastEvent', t('TIME_AT_ANCHOR_SINCE_LAST_EVENT')),
    HoursSteamedSinceLastEvent: commonFields.String('HoursSteamedSinceLastEvent', t('HOURS_STEAMED_SINCE_LAST_EVENT')),
    DistanceSinceLastEvent: commonFields.Number(
        'DistanceSinceLastEvent',
        t('DISTANCE_SINCE_LAST_EVENT'),
        2,
        tu('DISTANCE')
    ),
    WindForceSinceLastEvent: commonFields.WindForce('WindForceSinceLastEvent', t('WIND_FORCE_SINCE_LAST_EVENT')),
    TotalCargoSinceLastEvent: commonFields.Number(
        'TotalCargoSinceLastEvent',
        t('TOTAL_CARGO_ONBOARD'),
        0,
        tu('CARGO')
    ),

    // Port
    PreviousPort: commonFields.Point('PreviousPort', t('PREVIOUS_PORT')),
    NextPort: commonFields.Point('NextPort', t('NEXT_PORT')),

    // Event

    ReportDate: commonFields.ReportDate(t('EVENT_DATE')),
    EventType: commonFields.Select('EventType', t('EVENT_TYPE'), 'editingOptions.eventTypes'),
    SpecialArea: {
        ...commonFields.Select('SpecialArea', t('SPECIAL_AREA'), 'editingOptions.specialAreas', 'Id', 'DisplayName'),
        condition: (item) => (item.EventType && (item.EventType.Id === 8 || item.EventType.Id === 9))
    },
    Position: commonFields.Position(),
    InstructedSpeed: commonFields.Speed('InstructedSpeed', t('INSTRUCTED_SPEED')),
    TotalDistanceSteamedInArea: {
        condition: (item) =>
            (item.EventType && (item.EventType.Id === 9 || item.EventType.Id === 10 || item.EventType.Id === 11)),
        ...commonFields.Number(
            'TotalDistanceSteamedInArea',
            t('TOTAL_DISTANCE_STEAMED_IN_AREA'),
            2,
            tu('DISTANCE')
        )
    },
    TotalTimeSpentInArea: {
        condition: (item) =>
            (item.EventType && (item.EventType.Id === 9 || item.EventType.Id === 10 || item.EventType.Id === 11)),
        ...commonFields.String('TotalTimeSpentInArea', t('TOTAL_TIME_SPENT_IN_AREA'))
    },

    // Remarks

    Remarks: commonFields.Remarks()
};

export const dataMap = {
    BunkerData: commonData.BunkerFuelData('BunkerData'),
    ConsumptionFromNoonToEvent: commonData.ConsumptionFuelData('ConsumptionFromNoonToEvent', 2),
    ConsumptionDuringStoppage: commonData.ConsumptionFuelData('ConsumptionDuringStoppage', 3)
};

export default { fieldMap, dataMap };
