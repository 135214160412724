export const ActionTypes = {
    CONTACT_LIST_FILTERS_GET_OPTIONS: 'CONTACT_LIST_FILTERS_GET_OPTIONS',
    CONTACT_LIST_FILTERS_SET_OPTIONS: 'CONTACT_LIST_FILTERS_SET_OPTIONS',
    CONTACT_LIST_FILTERS_SET_VALUE: 'CONTACT_LIST_FILTERS_SET_VALUE',
    CONTACT_LIST_FILTERS_UPDATE_LOADED: 'CONTACT_LIST_FILTERS_UPDATE_LOADED'
};

export function getOptions(dispatch) {
    dispatch({
        type: ActionTypes.CONTACT_LIST_FILTERS_GET_OPTIONS
    });
}

export function setValue(dispatch, key, value) {
    dispatch({
        type: ActionTypes.CONTACT_LIST_FILTERS_SET_VALUE,
        key,
        value
    });
}

export function filterUpdateLoaded(dispatch) {
    dispatch({
        type: ActionTypes.CONTACT_LIST_FILTERS_UPDATE_LOADED
    });
}
