import { takeEvery, put, select, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
/* utils */
import { t } from 'utils/i18n/i18n-model';
import { hasValue } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* actions */
import { ActionTypes } from './charterparty-form-actions';
/* services */
import CharterpartyService from 'services/core-api/charterparty-service';
import LocalStorageService from 'services/local-storage-service';
import VoyageService from 'services/core-api/voyage-service';
/* mocks */
import { getMockedData } from './charterparty-form-test-utils';

function* getOptions(permissions) {
    const { areOptionsFetched } = yield select(state => state.charterpartyFormReducer);
    if (!areOptionsFetched && permissions.GetCharterpartyConfiguration) {
        const res = yield CharterpartyService.getOptions();
        yield put({ type: ActionTypes.CHARTERPARTY_FORM_SET_OPTIONS, ...res });
    }
}

function* getData(action) {
    const { permissions } = yield select(state => state.userReducer);
    yield getOptions(permissions);
    if (action.voyageId && permissions.GetCharterparties) {
        const res = yield all({
            charterpartyData: LocalStorageService.isDebugModeActive()
                ? getMockedData(action.voyageId)
                : CharterpartyService.getByVoyageId(action.voyageId),
            voyage: VoyageService.getById(action.voyageId)
        });
        res.charterpartyData = res.charterpartyData.map(cp => ({
            ...cp,
            DateEffective: TimeHelper.getDateTime(cp.DateEffective),
            DateEffectiveOffset: TimeHelper.getDateOffset(cp.DateEffective)
        }));
        yield put({ type: ActionTypes.CHARTERPARTY_FORM_SET_DATA, ...res });
    }
}

const transformDateAndOffset = (date, dateOffset) => {
    const offsetMinutes = TimeHelper.getMinutesFromTimeSpan(dateOffset);
    const offset = TimeHelper.getTimeSpanFromMinutes(offsetMinutes, true);
    return `${date.slice(0, -1)}${offset}`;
};

function* updateData(action) {
    const { permissions } = yield select(state => state.userReducer);
    if (action.voyageId && action.data && permissions.EditCharterparty) {
        const { voyageId } = action;
        const data = {
            Charterparties: action.data.map(item => ({
                Id: item.Id || null,
                DateEffective: transformDateAndOffset(item.DateEffective, item.DateEffectiveOffset),
                Speed: parseFloat(item.Speed),
                SpeedAllowance: parseFloat(item.SpeedAllowance),
                WindSpeed: hasValue(item.WindSpeed) ? parseFloat(item.WindSpeed) : null,
                WaveHeight: hasValue(item.WaveHeight) ? parseFloat(item.WaveHeight) : null,
                CurrentTypeId: item.CurrentType ? item.CurrentType.Id : null,
                AllowanceTypeId: item.AllowanceType.Id,
                FuelRestrictions: item.FuelRestrictions
                    .filter(fr => hasValue(fr.Consumption) || hasValue(fr.Allowance))
                    .map(fr => ({
                        Id: fr.Id || null,
                        FuelTypeId: fr.FuelType.Id,
                        Allowance: hasValue(fr.Allowance) ? parseFloat(fr.Allowance) : null,
                        Consumption: hasValue(fr.Consumption) ? parseFloat(fr.Consumption) : null
                    }))
            }))
        };
        const res = yield CharterpartyService.setByVoyageId(voyageId, data);
        if (res) {
            toast(t('CHARTERPARTY_FORM.TOASTS.VOYAGE_DATA_UPDATED'), { type: toast.TYPE.SUCCESS });
            yield put({
                type: ActionTypes.CHARTERPARTY_FORM_VOYAGE_UPDATED,
                voyageId,
                data: res.map(cp => ({
                    ...cp,
                    DateEffective: TimeHelper.getDateTime(cp.DateEffective),
                    DateEffectiveOffset: TimeHelper.getDateOffset(cp.DateEffective)
                }))
            });
        }
    }
}

export default function* charterpartyFormSaga() {
    yield takeEvery(ActionTypes.CHARTERPARTY_FORM_GET_DATA, getData);
    yield takeEvery(ActionTypes.CHARTERPARTY_FORM_VOYAGE_UPDATE, updateData);
}
