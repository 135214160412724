import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ScrollArea from 'components/scroll-area/scroll-area';
/* router */
import { TRouter, withRouter } from 'app-router';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { formatNumber } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
import { getPanelRows } from 'utils/helpers/template-helper';
/* components */
import Accordion from 'components/accordion/accordion';
/* styles */
import './employed-vessels-list.scss';

export class EmployedVesselsList extends React.PureComponent {
    getTitleLink = (imo) => {
        const { router } = this.props;
        if (imo && (!router.query.vessel || router.query.vessel !== imo.toString())) {
            return router.getLinkTo({
                remove: [
                    'port',
                    'reportId',
                    'reportType',
                    'reportEdit',
                    'questionnaireId',
                    'questionnaireEdit',
                    'voyage',
                    'terminal',
                    'berth',
                    'terminalUpdate',
                    'berthUpdate',
                    'terminalsShown'
                ],
                add: { vessel: imo }
            });
        }
        return router.location;
    }

    getVesselDetails(employedVessel) {
        const rows = [];
        const columnClasses = ['col-8', 'col-16'];
        rows.push([
            <label className="sten-panel__label">{translate('EMPLOYED_VESSELS.DELIVERY_PORT')}</label>,
            <label className="sten-panel__label sten-panel__label--value">
                {employedVessel.DeliveryPort || '-'}
            </label>
        ]);
        rows.push([
            <label className="sten-panel__label">{translate('EMPLOYED_VESSELS.REDELIVERY_PORT')}</label>,
            <label className="sten-panel__label sten-panel__label--value">
                {employedVessel.RedeliveryPort || '-'}
            </label>
        ]);
        rows.push([
            <label className="sten-panel__label">{translate('EMPLOYED_VESSELS.DURATION')}</label>,
            <label className="sten-panel__label sten-panel__label--value">
                {`${TimeHelper.getStringFromDuration(employedVessel.Duration, ['y', 'M', 'd', 'h']) || '-'}`}
            </label>
        ]);
        return (
            <div className="sten-panel__section" key={employedVessel.IMO}>
                {getPanelRows(columnClasses, rows)}
            </div>
        );
    }

    getEmployedVessels() {
        return this.props.employedVessels.map((employedVessel) => {
            const subtitleInfoArray = [];
            if (employedVessel.VesselTypeName) {
                subtitleInfoArray.push(employedVessel.VesselTypeName);
            }
            if (employedVessel.VesselTypeName === 'LNG' && employedVessel.TotalCubicCapacity) {
                subtitleInfoArray.push(
                    `${translate('EMPLOYED_VESSELS.CAPACITY')} `
                    + `${formatNumber(employedVessel.TotalCubicCapacity, 0)} ${translate('UNITS.CUBIC_CAPACITY')}`
                );
            } else if (employedVessel.DWT) {
                subtitleInfoArray.push(
                    `${translate('EMPLOYED_VESSELS.DWT')}
                    ${formatNumber(employedVessel.DWT, 0)} ${translate('UNITS.DWT')}`
                );
            }
            if (employedVessel.IMOClass) {
                subtitleInfoArray.push(`${translate('EMPLOYED_VESSELS.IMO_CLASS')} ${employedVessel.IMOClass}`);
            }
            const header = (
                <div className="flex-grow">
                    <Link
                        className="sten-h4 text-uppercase sten-title-link"
                        to={this.getTitleLink(employedVessel.IMO)}
                    >
                        {employedVessel.Title}
                    </Link>
                    <h6 className="text-secondary">
                        {subtitleInfoArray.join(', ')}
                    </h6>
                </div>
            );
            return (
                <Accordion
                    className="sten-panel sten-employed-vessels-list__item"
                    key={employedVessel.IMO}
                    header={header}
                    chevronRight
                >
                    {this.getVesselDetails(employedVessel)}
                </Accordion>
            );
        });
    }

    render() {
        return (
            <ScrollArea>
                <div className="sten-content__section">
                    {this.props.employedVessels.length === 0
                        ? <p className="text-secondary">{translate('EMPLOYED_VESSELS.NO_VESSELS')}</p>
                        : this.getEmployedVessels()
                    }
                </div>
            </ScrollArea>
        );
    }
}

EmployedVesselsList.propTypes = {
    employedVessels: PropTypes.arrayOf(PropTypes.object).isRequired,
    router: TRouter.isRequired
};

export default withRouter(EmployedVesselsList);
