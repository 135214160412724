const getTitle = (fsm) => ({
    style: {
        color: 'white',
        fontSize: '0.75rem',
        fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
        whiteSpace: 'nowrap',
        textTransform: 'uppercase',
        fontWeight: 700
    },
    useHTML: true,
    align: 'high',
    rotation: 0,
    margin: 20 * fsm,
    reserveSpace: false,
    y: -10 * fsm,
    textAlign: 'left'
});

export default getTitle;
