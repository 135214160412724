import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
/* selectors */
import { getTableData, getQuarterFromRange } from './quarterly-report-selectors';
/* actions */
import { generateXLSX } from './quarterly-report-actions';
/* components */
import EmptyContent from 'components/empty-content/empty-content';
import ContentHeader from '../../shared/content-header';
import QuarterlyReportTable from './quarterly-report-table';
/* styles */
import './quarterly-report.scss';

const t = (key) => translate(`ENERGY_MANAGEMENT.REPORTS.PREVIEW.${key}`);

class QuarterlyReport extends React.PureComponent {
    render() {
        const { selectedQuarter, tableData, generateXLSX } = this.props;
        const subtitle = selectedQuarter ? `${selectedQuarter.year}, ${selectedQuarter.quarter.Name}` : '';
        return (
            <div className="sten-content sten-content--has-footer">
                <ContentHeader subtitle={subtitle} />
                {!tableData || !selectedQuarter ? <EmptyContent>{t('EMPTY_REPORT')}</EmptyContent> : (
                    <React.Fragment>
                        <div className="sten-energy-management-reports-preview sten-content__body">
                            <QuarterlyReportTable data={tableData} selectedQuarter={selectedQuarter} />
                        </div>
                        <footer className="sten-content__footer flex-row">
                            <div className="flex-grow hide-xs" />
                            <div className="flex-shrink">
                                <button onClick={generateXLSX} className="btn btn--primary">
                                    {t('EXPORT_XLSX')}
                                </button>
                            </div>
                        </footer>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

QuarterlyReport.propTypes = {
    selectedQuarter: PropTypes.shape({
        quarter: PropTypes.objectOf(PropTypes.any),
        year: PropTypes.number
    }),
    tableData: PropTypes.shape({
        columns: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        fuelTypes: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
        parsedData: PropTypes.objectOf(PropTypes.any)
    }),
    generateXLSX: PropTypes.func.isRequired
};

QuarterlyReport.defaultProps = {
    selectedQuarter: null,
    tableData: null
};

function mapStateToProps(state) {
    return {
        selectedQuarter: getQuarterFromRange(state),
        tableData: getTableData(state)
    };
}

function mapDispatchToProps(dispatch) {
    return {
        generateXLSX: () => generateXLSX(dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(QuarterlyReport);
