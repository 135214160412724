import CoreApi from './core-api';

class WeatherRoutingService {
    constructor() {
        this.coreResourceUrl = 'WeatherRoutingV2';
    }

    getRequest = (requestId) => {
        return CoreApi.get(`${this.coreResourceUrl}/Requests/${requestId}`)
            .then(response => response.data, () => null);
    };

    createRequest = (data) => {
        return CoreApi.post(`${this.coreResourceUrl}/Requests`, data)
            .then(response => response.data, () => null);
    };

    getConfiguration = () => {
        return CoreApi.get(`${this.coreResourceUrl}/Configuration`)
            .then(response => response.data, () => null);
    };

    getConditionalAreas = () => {
        return CoreApi.get(`${this.coreResourceUrl}/ConditionalAreas/External`)
            .then(response => response.data, () => null);
    };

    getVoyages = (params) => {
        return CoreApi.get(`${this.coreResourceUrl}/Voyages`, params)
            .then(response => response.data, () => null);
    };

    getLegById = (legId, preventLoader) => {
        return CoreApi.get(`${this.coreResourceUrl}/Legs/${legId}`, null, preventLoader)
            .then(response => response.data, () => null);
    };

    getRequestsByLegId = (legId, preventLoader) => {
        return CoreApi.get(`${this.coreResourceUrl}/Legs/${legId}/Requests`, null, preventLoader)
            .then(response => response.data, () => null);
    };

    getSposRoutesByLegId = (legId, preventLoader) => {
        return CoreApi.get(`${this.coreResourceUrl}/Legs/${legId}/SposRoutes`, null, preventLoader)
            .then(response => response.data, () => null);
    };

    setRequestIntended = (requestId) => {
        return CoreApi.patch(`${this.coreResourceUrl}/Requests/${requestId}/Intention`)
            .then(() => true, () => false);
    };

    deleteRequest = (requestId) => {
        return CoreApi.remove(`${this.coreResourceUrl}/Requests/${requestId}`)
            .then(() => true, () => false);
    };

    deleteLeg = (legId) => {
        return CoreApi.remove(`${this.coreResourceUrl}/Legs/${legId}`)
            .then(() => true, () => false);
    };

    getTemplate = (params) => {
        return CoreApi.get(`${this.coreResourceUrl}/Template`, params)
            .then(response => response.data, () => null);
    };

    stopRequest = (requestId) => {
        return CoreApi.patch(`${this.coreResourceUrl}/Requests/${requestId}/Stop`)
            .then(response => response.data, () => null);
    }

    refreshRequest = (requestId) => {
        return CoreApi.patch(`${this.coreResourceUrl}/Requests/${requestId}/Refresh`)
            .then(response => response.data, () => null);
    }
}

export default new WeatherRoutingService();
