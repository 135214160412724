import { ActionTypes } from './vessel-report-actions';
import { defaultValidationsByType } from './vessel-report-validations';
/* utils */
import { tankOptions } from 'components/vessel-report/vessel-report-helpers';

const emptyArray = [];

const initialState = {
    report: null,
    editingOptions: {
        beaufortScale: emptyArray,
        cardinalDirections: emptyArray,
        cargoActivities: emptyArray,
        cargoActivityTypes: null,
        cargoStatuses: emptyArray,
        consumptionTypesAndGroups: null,
        consumptionTypesConfig: null,
        bunkerTypes: null,
        bunkerTypesConfig: null,
        eventTypes: emptyArray,
        navigationTypes: emptyArray,
        specialAreas: emptyArray,
        speedInstructionTypes: emptyArray,
        tugReasons: emptyArray,
        vesselStatusTypes: emptyArray
    },
    areEditingOptionsFetched: false,
    noOfWingsOptions: new Array(20).fill().map((item, index) => ({ Value: index + 1 })),
    cargoTankStateOptions: [
        tankOptions.Empty,
        tankOptions.Cargo
    ],
    slopTankStateOptions: [
        tankOptions.Empty,
        tankOptions.Cargo,
        tankOptions.Slop
    ],
    fuelTypes: null,
    consumptionTypes: null,
    bunkerTypes: emptyArray,
    validationRules: defaultValidationsByType,
    visibleTypesAndFields: emptyArray,
    editableReports: emptyArray
};

export default function vesselReportReducer(state = initialState, action) {
    switch (action.type) {
    case ActionTypes.VESSEL_REPORT_SET: {
        if (action.validations && action.reportType) {
            return {
                ...state,
                report: action.report,
                validationRules: {
                    ...state.validationRules,
                    [action.reportType]: action.validations
                }
            };
        }
        return {
            ...state,
            report: action.report
        };
    }
    case ActionTypes.VESSEL_REPORT_SET_EDITING_OPTIONS: {
        let cargoActivityTypes = null;
        if (action.cargoActivityTypes) {
            cargoActivityTypes = {};
            action.cargoActivityTypes.forEach(source => {
                cargoActivityTypes[source.DataSourceId] = source.ActivityTypes;
            });
        }

        return {
            ...state,
            editingOptions: {
                beaufortScale: action.beaufortScale || emptyArray,
                cardinalDirections: action.cardinalDirections || emptyArray,
                cargoActivities: action.cargoActivities || emptyArray,
                cargoActivityTypes,
                cargoStatuses: action.cargoStatuses || emptyArray,
                consumptionTypesConfig: action.consumptionTypesConfig || null,
                consumptionTypesAndGroups: action.consumptionTypesAndGroups || null,
                bunkerTypes: action.bunkerTypes || null,
                bunkerTypesConfig: action.bunkerTypesConfig || null,
                eventTypes: action.eventTypes || emptyArray,
                navigationTypes: action.navigationTypes || emptyArray,
                specialAreas: action.specialAreas || emptyArray,
                speedInstructionTypes: action.speedInstructionTypes || emptyArray,
                tugReasons: action.tugReasons || emptyArray,
                vesselStatusTypes: action.vesselStatusTypes || emptyArray
            },
            areEditingOptionsFetched: true
        };
    }
    case ActionTypes.VESSEL_REPORT_SET_FUEL_TYPES: {
        return {
            ...state,
            fuelTypes: action.fuelTypes
        };
    }
    case ActionTypes.VESSEL_REPORT_SET_CONSUMPTION_TYPES: {
        return {
            ...state,
            consumptionTypes: action.consumptionTypes || emptyArray
        };
    }
    case ActionTypes.VESSEL_REPORT_SET_BUNKER_TYPES: {
        return {
            ...state,
            bunkerTypes: action.bunkerTypes
        };
    }
    case ActionTypes.VESSEL_REPORT_SET_CONFIGS: {
        return {
            ...state,
            visibleTypesAndFields: action.visibleTypesAndFields || emptyArray,
            editableReports: action.editableReports || emptyArray
        };
    }
    case ActionTypes.VESSEL_REPORT_RESET_ALL: {
        return {
            ...initialState,
            editingOptions: state.editingOptions,
            fuelTypes: state.fuelTypes,
            areEditingOptionsFetched: state.areEditingOptionsFetched,
            visibleTypesAndFields: state.visibleTypesAndFields,
            editableReports: state.editableReports
        };
    }
    default:
        return state;
    }
}
