/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import { formatNumber } from 'utils/helpers/info-helper';
/* components */
import Input from 'components/input/input';
import Validation from 'components/validation/validation';
import FixedHeaderTable from 'components/fixed-header-table/fixed-header-table';
/* styles */
import './budget-consumption-table.scss';

const t = (key) => translate(`ADMIN.VESSEL_MANAGEMENT.FORM.${key}`);

const consumptionEmptyRow = {
    BallastConsumption: null,
    LadenConsumption: null,
    Speed: null
};

const speedValidation = {
    required: true,
    numeric: { min: 0, max: 30, decimals: 2 },
    custom: {
        rule: (value, params, components, name) => {
            return !Object.keys(components)
                .some(key => key.indexOf('Speed') === 0 && key !== name
                    && parseFloat(components[key].state.value) === parseFloat(value));
        },
        hint: () => 'Must be a unique value in list'
    }
};

const valueIsValid = (value) => (!!(value || value === 0));

const ballastLadenValidation = {
    numeric: { min: 0, max: 100, decimals: 2 },
    custom: {
        rule: (value, params, components, name) => {
            let relativeName = name.includes('BallastConsumption') ? 'LadenConsumption' : 'BallastConsumption';
            relativeName += `.${name.split('.')[1]}`;
            const relativeValueIsValid = components[relativeName] && valueIsValid(components[relativeName].state.value);
            return valueIsValid(value) || relativeValueIsValid;
        },
        hint: () => 'Ballast or Laden consumption is required'
    }
};

class BudgetConsumptionTable extends React.Component {
    validations = {
        Speed: { ...speedValidation },
        BallastConsumption: { ...ballastLadenValidation },
        LadenConsumption: { ...ballastLadenValidation }
    }

    handleChange = (type, index, value) => {
        const budget = [...this.props.data];
        budget[index][type] = value && value.trim().length !== 0 && !isNaN(value) ? parseFloat(value) : value;
        this.props.onChange(budget);
    }

    addRow = () => {
        const budget = [...this.props.data];
        budget.push({ ...consumptionEmptyRow });
        this.props.onChange(budget);
    }

    removeRow = (index) => {
        const budget = [...this.props.data];
        budget.splice(index, 1);
        this.props.onChange(budget);
    }

    renderInput = (value, key, index) => {
        return (
            <Validation.Wrapper
                hintsOnHover
                validations={this.validations[key]}
            >
                <Input
                    className="sten-input--sm"
                    value={value[key]}
                    name={`${key}.${index}`}
                    onChange={this.handleChange.bind(this, key, index)}
                />
            </Validation.Wrapper>
        );
    }

    render() {
        const { editable, data } = this.props;
        return (
            <React.Fragment>
                <FixedHeaderTable
                    className="budget-consumption-table"
                    contentClassName="budget-consumption-table__content"
                >
                    <table className="sten-table sten-table--xs">
                        <thead>
                            <tr>
                                <th className="text-secondary">{t('SPEED')}</th>
                                <th className="text-secondary">{t('BALLAST')}</th>
                                <th className="text-secondary">{t('LADEN')}</th>
                                <th className="text-secondary" />
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.length !== 0 && data.map((val, index) => (
                                <tr key={`tr${index}`}>
                                    <td className={editable ? 'sten-table__td--has-input' : ''}>
                                        {editable ? this.renderInput(val, 'Speed', index) : val.Speed}
                                    </td>
                                    <td className={editable ? 'sten-table__td--has-input' : ''}>
                                        {editable
                                            ? this.renderInput(val, 'BallastConsumption', index)
                                            : formatNumber(val.BallastConsumption, 2)}
                                    </td>
                                    <td className={editable ? 'sten-table__td--has-input' : ''}>
                                        {editable
                                            ? this.renderInput(val, 'LadenConsumption', index)
                                            : formatNumber(val.LadenConsumption, 2)}
                                    </td>
                                    <td>
                                        <div className="flex-shrink">
                                            {editable && (
                                                <button
                                                    onClick={this.removeRow.bind(this, index)}
                                                    className="icon icon-delete btn-link"
                                                    type="button"
                                                />
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </FixedHeaderTable>
                {editable && (
                    <div className="sten-content__section">
                        <button
                            type="button"
                            className="btn-link btn-link--inverted text-uppercase"
                            onClick={this.addRow.bind(this)}
                        >
                            <span className="btn__icon icon icon-plus" />{t('ADD_VALUES')}
                        </button>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

BudgetConsumptionTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.any),
    onChange: PropTypes.func,
    editable: PropTypes.bool
};

BudgetConsumptionTable.defaultProps = {
    data: null,
    onChange: undefined,
    editable: false
};

export default BudgetConsumptionTable;
