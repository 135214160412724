import React from 'react';
/* utils */
import { t } from 'utils/i18n/i18n-model';
/* components */
import Tooltip from 'components/tooltip/tooltip';
/* styles */
import './wni-weather-tooltip.scss';

const tooltipTranslateMap = {
    pswh: 'MAP_WEATHER_TOOLTIP.SWELL_WAVE_HEIGHT',
    icep: 'MAP_WEATHER_TOOLTIP.ICE_PACK'
};

const emptyArray = [];

class WniWeatherTooltip extends React.PureComponent {
    state = {
        pixels: emptyArray,
        values: null
    };

    isWeatherTooltipActive = false;

    tooltipDebounce = null;

    componentWillUnmount() {
        if (this.tooltipDebounce) {
            clearTimeout(this.tooltipDebounce);
        }
    }

    update(event, hoveredFeature) {
        if (this.tooltipDebounce) {
            clearTimeout(this.tooltipDebounce);
        }
        if (hoveredFeature?.get('layerName') === ('wni-weather')) {
            const pixels = event.pixel;
            const values = hoveredFeature.getProperties();
            if (pixels !== this.state.pixels || values !== this.state.values) {
                if (this.isWeatherTooltipActive) {
                    this.setState({ pixels, values });
                } else {
                    this.tooltipDebounce = setTimeout(() => {
                        this.isWeatherTooltipActive = true;
                        this.setState({ pixels, values });
                    }, 200);
                }
            }
        } else if (this.state.values) {
            this.isWeatherTooltipActive = false;
            this.setState({ pixels: emptyArray, values: null });
        }
    }

    renderContent = (values) => (
        <div>
            <p>
                {tooltipTranslateMap[values.layerType] ? t(tooltipTranslateMap[values.layerType]) : ''}
                {` ${values.Value} m`}
            </p>
        </div>
    );

    render() {
        const { pixels, values } = this.state;
        if (!pixels.length || !values) {
            return null;
        }
        return (
            <Tooltip
                contentClassName="sten-wni-weather-tooltip"
                alwaysVisible
                content={this.renderContent(values)}
                style={{ top: `${pixels[1]}px`, left: `${pixels[0]}px` }}
            />
        );
    }
}

export default WniWeatherTooltip;
