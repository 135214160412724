import React, { ChangeEvent, ReactNode, RefObject } from 'react';
/* utils */
import { getClassName } from 'utils/helpers/info-helper';
/* styles */
import './checkbox.scss';

type CheckboxProps = {
    children?: ReactNode;
    className?: string;
    color?: string;
    id?: string | number;
    isChecked?: boolean;
    isDisabled?: boolean;
    name?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>, props: CheckboxProps) => void;
    onClick?: () => void;
    title?: string;
    value?: string;
}

interface CheckboxState {
    isFocused: boolean;
}

class Checkbox extends React.PureComponent<CheckboxProps, CheckboxState> {
    static defaultProps: CheckboxProps = {
        children: null,
        className: '',
        color: '',
        id: undefined,
        isChecked: false,
        isDisabled: false,
        name: '',
        onChange: undefined,
        onClick: undefined,
        title: '',
        value: ''
    };

    inputRef: RefObject<HTMLInputElement> = React.createRef<HTMLInputElement>();

    state: CheckboxState = {
        isFocused: false
    };

    handleFocus = () => {
        this.setState({ isFocused: true });
    };

    handleBlur = () => {
        this.setState({ isFocused: false });
    };

    handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(e, this.props);
        }
    };

    render() {
        const { children, className, id, isChecked, isDisabled, title, onClick, color, name, value } = this.props;
        const checkboxClass = getClassName('sten-checkbox', className, {
            'sten-checkbox--checked': isChecked,
            'sten-checkbox--disabled': isDisabled,
            'sten-checkbox--no-label': !children,
            'sten-checkbox--focused': this.state.isFocused
        });
        return (
            <label className={checkboxClass} htmlFor={id?.toString()} title={title} onClick={onClick}>
                <div className="sten-checkbox__box" style={color ? { borderColor: color } : undefined} />
                <div className="sten-checkbox__tick" style={color ? { backgroundColor: color } : undefined} />
                <input
                    ref={this.inputRef}
                    type="checkbox"
                    id={id?.toString()}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    name={name || (id?.toString() ?? '')}
                    defaultChecked={isChecked}
                    disabled={isDisabled}
                    value={value}
                />
                {children}
            </label>
        );
    }
}

export default Checkbox;
