import { createSelector } from 'reselect';
/* helpers */
import { getOrdinal } from 'utils/helpers/info-helper';
import TimeHelper from 'utils/helpers/time-helper';
/* utils */
import { translate } from 'utils/i18n/i18n-model';
import createByIdSelector from 'utils/helpers/selector-helper';

function getRemainingItinerary(vessel) {
    return vessel.NextPorts.reduce((res, port, index) => (
        `${res}${index > 0 ? '\n' : ''}${port.Name} - ${translate('POSITION_LIST.ETA')}`
        + `${TimeHelper.getFormatted(port.ETA, { utc: true, time: true })}`
    ), '');
}

function getCommodities(cargoes) {
    return cargoes && cargoes.length > 0
        ? cargoes.reduce((res, commodity, index) =>
            `${res}${commodity.Commodity}${index < cargoes.length - 1 ? ', ' : ''}`, '')
        : translate('GLOBAL.REQUEST_INFO');
}

function getLastCargoes(vessel) {
    const lastCargoes = vessel.Cargoes && vessel.Cargoes.length ? vessel.Cargoes : [];
    return lastCargoes.reduce((res, cargo, index) => (
        `${res}${index > 0 ? '\n' : ''}${
            index === 0 ? 'Last' : `${`${getOrdinal(index + 1, true)} last`}`} - ${getCommodities(cargo.VoyageCargoes)
        }`
    ), '');
}

function getNextOpen(vessel) {
    let nextOpen;
    if (vessel.OpenPort && vessel.OpenPort.Name) {
        nextOpen = `${vessel.OpenPort.Name}${vessel.OpenPort.Country
            ? ` (${vessel.OpenPort.Country.CountryCode})`
            : ''}`;
    } else if (!vessel.OpenPort && vessel.AreaName) {
        nextOpen = `${translate('POSITION_LIST.REGION')} ${vessel.AreaName}`;
    } else {
        nextOpen = translate('GLOBAL.UNAVAILABLE');
    }

    return nextOpen;
}

function getUpdatedVessel(updatedVessel, vessel, fixtureStatuses, visibleColumns) {
    const result = {
        ...vessel,
        NextOpen: getNextOpen(vessel),
        RemainingItinerary: getRemainingItinerary(vessel),
        LastCargoes: getLastCargoes(vessel),
        Note: vessel.Note ? vessel.Note.Text : '',
        LoadReadyDate: vessel.LoadReadyDate ? vessel.LoadReadyDate.LoadReadyDate : ''
    };
    if (updatedVessel && updatedVessel.editedColumns && updatedVessel.editedColumns.length) {
        const propsMap = {
            3: 'NextOpen',
            5: 'RemainingItinerary',
            7: 'LastCargoes',
            4: 'EtaDate',
            8: 'Note',
            12: 'LoadReadyDate'
        };

        let isResettable = false;

        updatedVessel.editedColumns.forEach((column) => {
            if (visibleColumns.includes(column.ColumnId)) {
                isResettable = true;
                if (column.ColumnId === 9) {
                    result[column.ColumnName] = fixtureStatuses[column.Value];
                } else if (propsMap[column.ColumnId]) {
                    result[propsMap[column.ColumnId]] = column.Value;
                } else {
                    result[column.ColumnName] = column.Value;
                }
            }
        });
        if (isResettable) {
            result.Updated = updatedVessel;
        }
    }
    return result;
}

const fixtureStatusesById = createByIdSelector(
    state => state.positionListReducer.fixtureStatuses,
    'FixtureStatusId'
);

const getUpdatedVessels = createSelector(
    state => state.positionListReducer.vessels,
    state => state.positionListReducer.selectedVessels,
    state => state.positionListComposeReducer.updatedVessels,
    fixtureStatusesById,
    state => state.userReducer.settings.PositionsListExportColumns,
    (vessels, selectedVessels, updatedVessels, fixtureStatuses, visibleColumns) => (
        vessels.reduce((res, vessel) => {
            const temp = res.slice();
            if (selectedVessels.indexOf(vessel.IMO) > -1) {
                temp.push(getUpdatedVessel(updatedVessels[vessel.IMO], vessel, fixtureStatuses, visibleColumns));
            }
            return temp;
        }, [])
    )
);

export default getUpdatedVessels;
