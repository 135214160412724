import React from 'react';
import PropTypes from 'prop-types';
import ScrollBar from './scroll-bar';
import { findDOMNode, warnAboutFunctionChild, warnAboutElementChild, positiveOrZero } from './utils';
import lineHeight from 'line-height';
import PureWrapper from './pure-wrapper';
/* styles */
import './scroll-area.scss';

const eventTypes = {
    wheel: 'wheel',
    api: 'api',
    touch: 'touch',
    touchEnd: 'touchEnd',
    mousemove: 'mousemove',
    keyPress: 'keypress'
};

const autoScrollNodeNames = {
    INPUT: true,
    TEXTAREA: true
};

export default class ScrollArea extends React.Component {
    static disableScrollCount = 0;

    constructor(props) {
        super(props);
        this.state = {
            topPosition: 0,
            leftPosition: 0,
            realHeight: 0,
            containerHeight: 0,
            realWidth: 0,
            containerWidth: 0,
            useCSS3Translate: this.props.useCSS3Translate
        };

        this.scrollArea = {
            refresh: () => this.setSizesToStateIfTheyAreDifferent,
            scrollTop: () => {
                this.scrollTop();
            },
            scrollBottom: () => {
                this.scrollBottom();
            },
            scrollYTo: (position) => {
                this.scrollYTo(position, true);
            },
            scrollLeft: () => {
                this.scrollLeft();
            },
            scrollRight: () => {
                this.scrollRight();
            },
            scrollXTo: (position) => {
                this.scrollXTo(position, true);
            }
        };

        this.eventPreviousValues = {
            clientX: 0,
            clientY: 0,
            deltaX: 0,
            deltaY: 0
        };
        this.outerContentWrapperDimensions = {};
        this.sizes = {};
        this.calledComputeSizesInThisIteration = false;
        this.refresh = this.setSizesToStateIfTheyAreDifferent.bind(this);
    }

    getChildContext() {
        return {
            scrollArea: this.scrollArea
        };
    }

    componentDidMount() {
        if (this.props.contentWindow) {
            this.props.contentWindow.addEventListener('resize', this.handleWindowResize, { passive: true });
        }
        window.requestAnimationFrame(() => {
            if (this.contentWrapperRef) {
                this.lineHeightPx = lineHeight(findDOMNode(this.contentWrapperRef));
                this.setSizesToStateIfTheyAreDifferent();
                this.calledComputeSizesInThisIteration = false;
            }
        });
        // this.contentWrapperNode = findDOMNode(this.contentWrapperRef);
        if (this.props.fixRelativeScroll) {
            this.contentWrapperRef.scrollIntoView();
        }
    }

    componentDidUpdate(prevProps) {
        this.setSizesToStateIfTheyAreDifferent();
        if (prevProps.dragMode === false && this.props.dragMode === true) {
            this.outerContentWrapperDimensions = this.outerContentWrapper.getBoundingClientRect();
        }
    }

    isScrollScheduled = false;

    scheduleScrollUpdateIfNeeded = () => {
        if (this.props.dragMode && !this.isScrollScheduled) {
            window.requestAnimationFrame(this.updateScrollPosition);
            this.isScrollScheduled = true;
        }
    };

    updateScrollPosition = () => {
        this.isScrollScheduled = false;
        this.scrollIfNeeded();
        this.scheduleScrollUpdateIfNeeded();
    };

    componentWillUnmount() {
        if (this.props.contentWindow) {
            this.props.contentWindow.removeEventListener('resize', this.handleWindowResize);
        }
        if (this.animateTimeout) {
            clearTimeout(this.animateTimeout);
        }
        if (this.computeSizesTimeout) {
            clearTimeout(this.computeSizesTimeout);
        }
    }

    getModifiedPositionsIfNeeded(newState) {
        const newPositions = { ...newState };
        let changed = false;
        const maxTopPosition = newState.realHeight - newState.containerHeight;
        if (this.state.topPosition > maxTopPosition) {
            newPositions.topPosition = this.canScrollY(newState) ? positiveOrZero(maxTopPosition) : 0;
            changed = true;
        }

        const maxLeftPosition = newState.realWidth - newState.containerWidth;
        if (this.state.leftPosition > maxLeftPosition) {
            newPositions.leftPosition = this.canScrollX(newState) ? positiveOrZero(maxLeftPosition) : 0;
            changed = true;
        }
        if (changed) {
            return newPositions;
        }
        return newState;
    }

    setSizesToStateIfTheyAreDifferent() {
        const newState = this.computeSizes();
        if (this.props.fixRelativeScroll) {
            this.outerContentWrapper.scrollTop = 0;
            this.outerContentWrapper.scrollLeft = 0;
        }

        if (newState.realHeight !== this.state.realHeight
            || newState.realWidth !== this.state.realWidth
            || newState.containerHeight !== this.state.containerHeight) {
            this.setStateFromEvent(this.getModifiedPositionsIfNeeded(newState));
        }
    }

    static disableScroll() {
        ScrollArea.disableScrollCount++;
    }

    static enableScroll() {
        if (ScrollArea.disableScrollCount > 0) {
            ScrollArea.disableScrollCount--;
        }
    }

    static isScrollDisabled() {
        return ScrollArea.disableScrollCount > 0;
    }

    setStateFromEvent(newState, eventType, event, animate) {
        if (this.props.smoothScrolling || (animate && this.props.animateScrollTo)) {
            this.animateScroll();
        }
        if (event) {
            if ((newState.topPosition && this.state.topPosition !== newState.topPosition)
                || (newState.leftPosition && this.state.leftPosition !== newState.leftPosition)) {
                // event.preventDefault();
                event.stopPropagation();
            }
            if (this.props.stopScrollPropagation) {
                event.stopPropagation();
            }
        }

        if (!ScrollArea.isScrollDisabled()) {
            this.setState({ ...newState, eventType }, () => {
                if (this.props.onScroll) {
                    this.props.onScroll(this.state);
                }
                if (this.props.shouldDispatchEvent) {
                    const scrollChangeEvent = new CustomEvent('scrollChanged', { detail: { state: this.state } });
                    document.dispatchEvent(scrollChangeEvent);
                }
            });
        }
    }

    canScrollX(state = this.state) {
        const scrollableX = state.realWidth > state.containerWidth;
        return scrollableX && this.props.horizontal;
    }

    canScrollY(state = this.state) {
        const scrollableY = state.realHeight > state.containerHeight;
        return scrollableY && this.props.vertical;
    }

    composeNewState(deltaX, deltaY) {
        const newState = this.computeSizes();

        if (this.canScrollY(newState)) {
            newState.topPosition = Math.floor(this.computeTopPosition(deltaY, newState));
        } else {
            newState.topPosition = 0;
        }
        if (this.canScrollX(newState)) {
            newState.leftPosition = Math.floor(this.computeLeftPosition(deltaX, newState));
        }
        return newState;
    }

    computeLeftPosition(deltaX, sizes) {
        const newLeftPosition = this.state.leftPosition - deltaX;
        return ScrollArea.normalizeLeftPosition(newLeftPosition, sizes);
    }

    computeSizes() {
        if (this.sizes
            && !this.calledComputeSizesInThisIteration
            && this.contentWrapperRef
            && this.outerContentWrapper) {
            this.sizes = {
                realHeight: this.contentWrapperRef.offsetHeight,
                containerHeight: this.outerContentWrapper.offsetHeight,
                realWidth: this.contentWrapperRef.offsetWidth,
                containerWidth: this.outerContentWrapper.offsetWidth
            };
            this.calledComputeSizesInThisIteration = true;
            if (this.computeSizesTimeout) {
                clearTimeout(this.computeSizesTimeout);
            }
            this.computeSizesTimeout = setTimeout(() => {
                this.calledComputeSizesInThisIteration = false;
            }, 200);
        }

        return this.sizes;
    }

    computeTopPosition(deltaY, sizes) {
        const newTopPosition = this.state.topPosition - deltaY;
        return ScrollArea.normalizeTopPosition(newTopPosition, sizes);
    }

    handleKeyDown = e => {
        // only handle if scroll area is in focus
        if (e.target.tagName.toLowerCase() !== 'input' && e.target.tagName.toLowerCase() !== 'textarea') {
            let deltaY = 0;
            let deltaX = 0;
            const lh = this.lineHeightPx ? this.lineHeightPx : 10;

            switch (e.keyCode) {
            case 33: // page up
                deltaY = this.state.containerHeight - lh;
                break;
            case 34: // page down
                deltaY = -this.state.containerHeight + lh;
                break;
            case 37: // left
                deltaX = lh;
                break;
            case 38: // up
                deltaY = lh;
                break;
            case 39: // right
                deltaX = -lh;
                break;
            case 40: // down
                deltaY = -lh;
                break;
            default:
                break;
            }

            // only compose new state if key code matches those above
            if (deltaY !== 0 || deltaX !== 0) {
                const newState = this.composeNewState(deltaX, deltaY);
                this.setStateFromEvent(newState, eventTypes.keyPress, e);
            }
        }
    };

    handleScrollbarMove = (deltaY, deltaX) => {
        this.setStateFromEvent(this.composeNewState(deltaX, deltaY));
    };

    handleScrollbarXPositionChange = position => {
        this.scrollXTo(position);
    };

    handleScrollbarYPositionChange = position => {
        this.scrollYTo(position);
    };

    handleTouchEnd = (e) => {
        let { deltaX, deltaY } = this.eventPreviousValues;
        if (typeof deltaX === 'undefined') deltaX = 0;
        if (typeof deltaY === 'undefined') deltaY = 0;
        if (Date.now() - this.eventPreviousValues.timestamp < 200) {
            this.setStateFromEvent(this.composeNewState(-deltaX * 10, -deltaY * 10), eventTypes.touchEnd, e);
        }

        this.eventPreviousValues = {
            ...this.eventPreviousValues,
            deltaY: 0,
            deltaX: 0
        };
    };

    handleTouchMove = e => {
        e.preventDefault();
        // e.stopPropagation();

        const { touches } = e;
        if (touches.length === 1) {
            const { clientX, clientY } = touches[0];

            const deltaY = this.eventPreviousValues.clientY - clientY;
            const deltaX = this.eventPreviousValues.clientX - clientX;

            this.eventPreviousValues = {
                ...this.eventPreviousValues,
                deltaY,
                deltaX,
                clientY,
                clientX,
                timestamp: Date.now()
            };

            this.setStateFromEvent(this.composeNewState(-deltaX, -deltaY), null, e);
        }
    };

    handleTouchStart = e => {
        const { touches } = e;
        if (touches.length === 1) {
            const { clientX, clientY } = touches[0];
            this.eventPreviousValues = {
                ...this.eventPreviousValues,
                clientY,
                clientX,
                timestamp: Date.now()
            };
        }
    };

    handleWheel = e => {
        let deltaY = e.deltaY;
        let deltaX = e.deltaX;

        if (e.shiftKey || this.props.swapWheelAxes) {
            [deltaY, deltaX] = [deltaX, deltaY];
        }

        /*
         * WheelEvent.deltaMode can differ between browsers and must be normalized
         * e.deltaMode === 0: The delta values are specified in pixels
         * e.deltaMode === 1: The delta values are specified in lines
         * https://developer.mozilla.org/en-US/docs/Web/API/WheelEvent/deltaMode
         */
        if (e.deltaMode === 1) {
            deltaY *= this.lineHeightPx;
            // deltaX *= this.lineHeightPx;
        }

        deltaY *= this.props.speed;
        deltaX *= this.props.speed;

        const newState = this.composeNewState(-deltaX, -deltaY);

        this.setStateFromEvent(newState, eventTypes.wheel, e);
    };

    handleWindowResize = () => {
        let newState = this.computeSizes();
        newState = this.getModifiedPositionsIfNeeded({ ...newState, leftPosition: 0, topPosition: 0 });
        this.setStateFromEvent(newState);
    };

    static normalizeLeftPosition(leftPosition, sizes) {
        let newLeftPosition = leftPosition;
        if (newLeftPosition > sizes.realWidth - sizes.containerWidth) {
            newLeftPosition = sizes.realWidth - sizes.containerWidth;
        } else if (newLeftPosition < 0) {
            newLeftPosition = 0;
        }

        return newLeftPosition;
    }

    static normalizeTopPosition(topPosition, sizes) {
        let newTopPosition = topPosition;
        if (newTopPosition > sizes.realHeight - sizes.containerHeight) {
            newTopPosition = sizes.realHeight - sizes.containerHeight;
        }
        if (newTopPosition < 0) {
            newTopPosition = 0;
        }
        return newTopPosition;
    }

    saveContentWrapperRef = ref => {
        this.contentWrapperRef = ref;
    };

    saveOuterContentWrapperRef = ref => {
        this.outerContentWrapper = ref;
    };

    scrollBottom() {
        this.scrollYTo((this.state.realHeight - this.state.containerHeight), true);
    }

    scrollLeft() {
        this.scrollXTo(0, true);
    }

    scrollRight() {
        this.scrollXTo((this.state.realWidth - this.state.containerWidth), true);
    }

    scrollTop() {
        this.scrollYTo(0, true);
    }

    scrollXTo(leftPosition, animate) {
        if (this.canScrollX()) {
            const position = this.clonePositionsFromState();
            position.leftPosition = ScrollArea.normalizeLeftPosition(leftPosition, this.computeSizes());
            this.setStateFromEvent(position, eventTypes.api, null, animate);
        }
    }

    clonePositionsFromState() {
        return {
            topPosition: this.state.topPosition,
            leftPosition: this.state.leftPosition,
            realHeight: this.state.realHeight,
            containerHeight: this.state.containerHeight,
            realWidth: this.state.realWidth,
            containerWidth: this.state.containerWidth
        };
    }

    scrollYTo(topPosition, animate) {
        if (this.canScrollY()) {
            const position = this.clonePositionsFromState();
            position.topPosition = ScrollArea.normalizeTopPosition(topPosition, this.computeSizes());
            this.setStateFromEvent(position, eventTypes.api, null, animate);
        }
    }

    animateScroll() {
        if (this.animateTimeout) {
            clearTimeout(this.animateTimeout);
        }
        if (this.props.useCSS3Translate) {
            this.contentWrapperRef.style.transition = 'transform .5s';
        } else {
            this.contentWrapperRef.style.transition = 'margin-top .5s, margin-left .5s';
        }
        this.animateTimeout = setTimeout(() => {
            this.contentWrapperRef.style.transition = '';
        }, 1000);
    }

    renderScrollbarX() {
        return (
            <ScrollBar
                offsetStart={this.props.offset ? this.props.offset.left : 0}
                offsetEnd={this.props.offset ? this.props.offset.right : 0}
                ownerDocument={this.props.ownerDocument}
                realSize={this.state.realWidth}
                containerSize={this.state.containerWidth}
                position={this.state.leftPosition}
                onMove={this.handleScrollbarMove}
                onPositionChange={this.handleScrollbarXPositionChange}
                containerStyle={this.props.horizontalContainerStyle}
                scrollbarStyle={this.props.horizontalScrollbarStyle}
                minScrollSize={this.props.minScrollSize}
                type="horizontal"
            />
        );
    }

    renderScrollbarY() {
        return (
            <ScrollBar
                offsetStart={this.props.offset ? this.props.offset.top : 0}
                offsetEnd={this.props.offset ? this.props.offset.bottom : 0}
                ownerDocument={this.props.ownerDocument}
                realSize={this.state.realHeight}
                containerSize={this.state.containerHeight}
                position={this.state.topPosition}
                onMove={this.handleScrollbarMove}
                onPositionChange={this.handleScrollbarYPositionChange}
                containerStyle={this.props.verticalContainerStyle}
                scrollbarStyle={this.props.verticalScrollbarStyle}
                minScrollSize={this.props.minScrollSize}
                type="vertical"
            />
        );
    }

    scrollIfNeeded = () => {
        if (this.props.dragMode) {
            const verticalThreshold = this.outerContentWrapperDimensions.height * 0.15;
            let delta = 0;
            const { y } = this.mousePosition;

            if (y < this.outerContentWrapperDimensions.top + verticalThreshold) {
                delta -= verticalThreshold - (y - this.outerContentWrapperDimensions.top);
            } else if (y > this.outerContentWrapperDimensions.bottom - verticalThreshold) {
                delta = verticalThreshold - (this.outerContentWrapperDimensions.bottom - y);
            }

            if (delta !== 0) {
                this.scrollYTo(this.state.topPosition + delta);
            }
        }
    };

    mousePosition = {
        x: 0,
        y: 0
    };

    saveMousePosition = (e) => {
        const domEvent = e.nativeEvent;
        this.mousePosition.x = domEvent.x;
        this.mousePosition.y = domEvent.y;
        this.scheduleScrollUpdateIfNeeded();
    };

    handleScroll = (e) => {
        if (e.target === this.outerContentWrapper && (e.target.scrollTop !== 0 || e.target.scrollLeft !== 0)) {
            if (this.props.shouldScrollToActiveElement
                && document.activeElement
                && document.activeElement.nodeName
                && autoScrollNodeNames[document.activeElement.nodeName]) {
                this.setStateFromEvent({
                    topPosition: this.state.topPosition + e.target.scrollTop,
                    leftPosition: this.state.leftPosition + e.target.scrollLeft
                });
            }
            e.target.scrollTop = 0;
            e.target.scrollLeft = 0;
        }
    };

    render() {
        let { children } = this.props;

        if (typeof children === 'function') {
            warnAboutFunctionChild();
            children = children();
        } else {
            warnAboutElementChild();
        }

        const classes = `scrollarea ${this.props.className}`;
        const contentClasses = `scrollarea-content ${this.props.contentClassName}`;

        const contentStyle = this.state.useCSS3Translate ? {
            top: 0,
            left: 0,
            transform: `translate(${-this.state.leftPosition}px, ${-this.state.topPosition}px)`,
            willChange: 'transform'
        } : {
            marginTop: -this.state.topPosition,
            marginLeft: -this.state.leftPosition
        };

        return (
            <div
                ref={this.saveOuterContentWrapperRef}
                className={classes}
                style={this.props.style}
                onScroll={this.handleScroll}
                onWheel={this.handleWheel}
                onClick={this.props.onClick}
                onMouseMove={this.saveMousePosition}
            >
                <div
                    ref={this.saveContentWrapperRef}
                    style={{ ...this.props.contentStyle, ...contentStyle }}
                    className={contentClasses}
                    onTouchStart={this.handleTouchStart}
                    onTouchMove={this.handleTouchMove}
                    onTouchEnd={this.handleTouchEnd}
                    onKeyDown={this.handleKeyDown}
                    tabIndex={this.props.focusableTabIndex}
                >
                    {this.props.isImpure ? children : <PureWrapper>{children}</PureWrapper>}
                </div>
                {this.canScrollY() && this.renderScrollbarY()}
                {this.canScrollX() && this.renderScrollbarX()}
            </div>
        );
    }
}

ScrollArea.childContextTypes = {
    scrollArea: PropTypes.objectOf(PropTypes.any)
};

ScrollArea.propTypes = {
    animateScrollTo: PropTypes.bool,
    children: PropTypes.node,
    className: PropTypes.string,
    contentClassName: PropTypes.string,
    contentStyle: PropTypes.objectOf(PropTypes.any),
    contentWindow: PropTypes.objectOf(PropTypes.any),
    dragMode: PropTypes.bool,
    fixRelativeScroll: PropTypes.bool,
    focusableTabIndex: PropTypes.number,
    horizontal: PropTypes.bool,
    horizontalContainerStyle: PropTypes.objectOf(PropTypes.any),
    horizontalScrollbarStyle: PropTypes.objectOf(PropTypes.any),
    isImpure: PropTypes.bool,
    minScrollSize: PropTypes.number,
    offset: PropTypes.shape({
        top: PropTypes.number,
        bottom: PropTypes.number,
        left: PropTypes.number,
        right: PropTypes.number
    }),
    onClick: PropTypes.func,
    onScroll: PropTypes.func,
    ownerDocument: PropTypes.objectOf(PropTypes.any),
    shouldDispatchEvent: PropTypes.bool,
    shouldScrollToActiveElement: PropTypes.bool,
    smoothScrolling: PropTypes.bool,
    speed: PropTypes.number,
    stopScrollPropagation: PropTypes.bool,
    style: PropTypes.objectOf(PropTypes.any),
    swapWheelAxes: PropTypes.bool,
    useCSS3Translate: PropTypes.bool,
    vertical: PropTypes.bool,
    verticalContainerStyle: PropTypes.objectOf(PropTypes.any),
    verticalScrollbarStyle: PropTypes.objectOf(PropTypes.any)
};

ScrollArea.defaultProps = {
    animateScrollTo: true,
    children: null,
    className: '',
    contentClassName: '',
    contentStyle: null,
    contentWindow: (typeof window === 'object') ? window : undefined,
    dragMode: false,
    fixRelativeScroll: false,
    focusableTabIndex: 1,
    horizontal: true,
    horizontalContainerStyle: null,
    horizontalScrollbarStyle: null,
    isImpure: false,
    minScrollSize: 0,
    offset: null,
    onClick: undefined,
    onScroll: undefined,
    ownerDocument: (typeof document === 'object') ? document : undefined,
    shouldDispatchEvent: true,
    shouldScrollToActiveElement: false,
    smoothScrolling: false,
    speed: 1,
    stopScrollPropagation: false,
    style: null,
    swapWheelAxes: false,
    useCSS3Translate: true,
    vertical: true,
    verticalContainerStyle: null,
    verticalScrollbarStyle: null
};
