import React from 'react';
import PropTypes from 'prop-types';
/* styles */
import './html-preview.scss';

class HtmlPreview extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            height: '100%',
            width: '100%'
        };
    }

    componentDidMount() {
        this.setPreviewContents();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.content !== this.props.content) {
            this.setPreviewContents(this.props);
        }
    }

    componentWillUnmount() {
        if (this.previewDebounce) {
            clearTimeout(this.previewDebounce);
        }
        if (this.heightDebounce) {
            clearTimeout(this.previewDebounce);
        }
    }

    setPreviewContents(props = this.props) {
        if (this.iframe) {
            if (this.previewDebounce) {
                clearTimeout(this.previewDebounce);
            }
            if (this.heightDebounce) {
                clearTimeout(this.previewDebounce);
            }
            this.previewDebounce = setTimeout(() => {
                const doc = this.iframe.contentWindow.document;
                doc.open();
                doc.write(props.content);
                doc.close();
                this.heightDebounce = setTimeout(() => {
                    this.setState({
                        height: doc.getElementsByTagName('body')[0].scrollHeight
                    });
                }, 500);
            }, this.props.debounce);
        }
    }

    render() {
        let className = 'sten-html-preview';
        if (this.props.className) {
            className += ` ${this.props.className}`;
        }
        return (
            <iframe
                className={className}
                ref={(c) => { this.iframe = c; }}
                width={this.props.width || this.state.width}
                height={this.props.height || this.state.height}
            />
        );
    }
}

HtmlPreview.propTypes = {
    className: PropTypes.string,
    content: PropTypes.string.isRequired,
    debounce: PropTypes.number,
    height: PropTypes.string,
    width: PropTypes.string
};

HtmlPreview.defaultProps = {
    className: '',
    debounce: 500,
    height: '',
    width: '100%'
};

export default HtmlPreview;
