import { t } from 'utils/i18n/i18n-model';
/* services */
import ConfigService from 'services/config-api/config-service';

export const sectionsAndFields = [
    {
        fields: ['Vessel', 'Voyage', 'TemplateData']
    },
    {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('WEATHER_ROUTING.REQUEST.OPTIMIZATION'),
        sections: [
            {
                fields: [
                    'Source',
                    'VesselCondition',
                    'OptimizationType',
                    'VesselDailyCosts',
                    'BunkerCostHFO',
                    'BunkerCostHFOLS',
                    'RouteCalculationType',
                    'InstructedSpeed',
                    'InstructedSpeedRange'
                ]
            }
        ]
    },
    {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('WEATHER_ROUTING.REQUEST.RESTRICTIONS'),
        sections: [
            {
                fields: [
                    'Draft',
                    'ConditionalAreas'
                ]
            }
        ]
    },
    //  {
    //     type: 'accordion',
    //     accordionProps: { isCollapsedBodyRendered: true },
    //     title: t('WEATHER_ROUTING.REQUEST.WEATHER_LIMITS'),
    //     sections: [
    //         {
    //             fields: [
    //                 'IcingRisk',
    //                 'Visibility',
    //                 'WaveHeight',
    //                 'BeamWavesHeight',
    //                 'FollowingWavesHeight',
    //                 'HeadWavesHeight',
    //                 'RiskWaveHeight',
    //                 'WindSpeed10m',
    //                 'WindSpeed50m',
    //                 'WindGusts10m',
    //                 'WindGusts50m',
    //                 'HeadWindSpeed',
    //                 'BeamWindSpeed',
    //                 'FollowingWindSpeed',
    //                 'RiskWindSpeed',
    //                 'IceConcentration',
    //                 'HurricaneDistance',
    //                 'TropicalStormDistance',
    //                 'TropicalDepressionDistance',
    //                 'BeamWavesPeriod',
    //                 'FollowingWavesPeriod',
    //                 'HeadWavesPeriod'
    //             ]
    //         }
    //     ]
    // },
    {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        condition: (params) => params.data.ConsumptionBudget,
        title: t('WEATHER_ROUTING.REQUEST.BUDGETED_CONSUMPTION'),
        sections: [{ data: ['ConsumptionBudget'] }]
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('WEATHER_ROUTING.REQUEST.PERFORMANCE'),
        condition: (params) => ConfigService.featureToggles.showWeatherRoutingRequestPerformance
            && (!params.data.RouteCalculationType || params.data.RouteCalculationType.Name !== 'SpeedRange')
            && (!params.data.Source || params.data.Source.Name !== 'AutomaticWeatherApi'),
        sections: [{ fields: ['RequestType'] }]
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('WEATHER_ROUTING.REQUEST.ITINERARY'),
        sections: [{ data: 'Itinerary' }]
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        title: t('WEATHER_ROUTING.REQUEST.ROUTING_EMAILS'),
        sections: [{ fields: ['RoutingEmailsVessel', 'RoutingEmailsOperations', 'RoutingEmailsOther'], inline: false }]
    }, {
        type: 'accordion',
        accordionProps: { isCollapsedBodyRendered: true },
        condition: (params) => !params.data.Source || params.data.Source.Name !== 'AutomaticWeatherApi',
        title: t('WEATHER_ROUTING.REQUEST.ADDITIONAL_INFO'),
        sections: [{ fields: ['PerformanceEmails', 'PvarEmails', 'Remarks'], inline: false }]
    }
];
