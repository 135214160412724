import getFontSize from 'utils/helpers/cached-font-size';

const chartColors = ['#ffffff', '#28ffca', '#48a6f3'];
const colorChartPlotBg = '#0f2e39';
const colorChartBg = '#0f2e39';
const colorYAxisLabel = 'rgba(255, 255, 255, 0.9)';
const colorEEOILine = '#4caf50';
const colorSegmentEEOI = 'rgba(76, 175, 80, 0.6)';
const colorAERLine = '#ff9800';

export const getYAxis = (fsm) => ({
    tickWidth: fsm,
    tickLength: 5 * fsm,
    lineWidth: fsm,
    minorGridLineWidth: fsm,
    gridLineWidth: 0,
    tickAmount: 4,
    labels: {
        style: {
            color: colorYAxisLabel,
            fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
            fontSize: '0.75rem'
        },
        y: 3 * fsm
    },
    title: {
        style: {
            color: '#7da2b3',
            fontSize: '0.75rem',
            fontFamily: 'Roboto, Arial, Helvetica, sans-serif',
            whiteSpace: 'nowrap'
        },
        useHTML: true,
        align: 'high',
        rotation: 0,
        margin: 40 * fsm,
        reserveSpace: false,
        y: -15 * fsm
    }
});

export const getKpiChartConfig = (rem = getFontSize()) => {
    const fsm = rem / 16;

    return {
        colors: chartColors,
        plotOptions: {
            series: {
                events: {
                    /* eslint-disable */
                    legendItemClick: function() {
                        if (!this.chart.series.some((set) => set !== this && set.visible)) {
                            return false;
                        }
                    }
                }
            }
        },
        chart: {
            backgroundColor: colorChartBg,
            plotBackgroundColor: colorChartPlotBg,
            spacing: [40 * fsm, 2 * rem, rem, 2 * rem],
            zoomType: false,
            panning: false,
            dynamicExtremes: false
        },
        xAxis: {
            minRange: 5,
            type: 'category',
            tickInterval: null,
            tickPositioner: null,
            startOnTick: true,
            endOnTick: false,
            labels: { formatter: null }
        },
        yAxis: getYAxis(fsm),
        tooltip: {
            valueDecimals: 1
        }
    };
};

export const getConsumptionCargoChartConfig = () => {
    const rem = getFontSize();
    const fsm = rem / 16;
    const yAxis = getYAxis(fsm);

    return {
        ...getKpiChartConfig(rem),
        legend: {
            enabled: false
        },
        yAxis: [
            {
                ...yAxis,
                id: 'cargo',
                minTickInterval: 2,
                title: {
                    ...yAxis.title,
                    text: 'Cargo (kMt)',
                    textAlign: 'right'
                },
                opposite: true
            },
            {
                ...yAxis,
                id: 'consumption',
                minTickInterval: 2,
                title: {
                    ...yAxis.title,
                    text: 'Consumption (mt/kNm)',
                    textAlign: 'left'
                }
            }
        ],
        series: [
            {
                name: 'Ballast consumption',
                type: 'line',
                lineWidth: 2,
                yAxis: 'consumption',
                data: []
            },
            {
                name: 'Laden consumption',
                type: 'line',
                lineWidth: 2,
                yAxis: 'consumption',
                data: []
            },
            {
                name: 'Average cargo',
                type: 'line',
                lineWidth: 2,
                yAxis: 'cargo',
                data: []
            }
        ]
    };
};

export const getEnergyEfficiencyChartConfig = () => {
    const rem = getFontSize();
    const fsm = rem / 16;
    const yAxis = getYAxis(fsm);

    return {
        ...getKpiChartConfig(rem),
        legend: {
            enabled: false
        },
        yAxis: [
            {
                ...yAxis,
                tickAmount: 4,
                id: 'efficiency',
                title: {
                    ...yAxis.title,
                    text: 'Energy Efficiency gCO₂/tnm',
                    textAlign: 'left'
                }
            },
            {
                id: 'segment',
                visible: false
            }
        ],
        plotOptions: {
            series: {
                connectNulls: true
            }
        },
        series: [
            {
                name: 'EEOI',
                type: 'line',
                lineWidth: 2,
                yAxis: 'efficiency',
                data: [],
                color: colorEEOILine,
                zIndex: 2
            },
            {
                name: 'Suezmax EEOI',
                type: 'line',
                lineWidth: 2,
                yAxis: 'segment',
                data: [],
                lineColor: 'transparent',
                color: colorSegmentEEOI,
                marker: {
                    fillColor: 'transparent',
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            {
                name: 'P-MAX EEOI',
                type: 'line',
                lineWidth: 2,
                yAxis: 'segment',
                data: [],
                lineColor: 'transparent',
                color: colorSegmentEEOI,
                marker: {
                    fillColor: 'transparent',
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            {
                name: 'Intermediate EEOI',
                type: 'line',
                lineWidth: 2,
                yAxis: 'segment',
                data: [],
                lineColor: 'transparent',
                color: colorSegmentEEOI,
                marker: {
                    fillColor: 'transparent',
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                }
            },
            {
                name: 'Medium Range EEOI',
                type: 'line',
                lineWidth: 2,
                yAxis: 'segment',
                data: [],
                lineColor: 'transparent',
                color: colorSegmentEEOI,
                marker: {
                    fillColor: 'transparent',
                    states: {
                        hover: {
                            enabled: false
                        }
                    }
                },
                tooltip: {
                    pointFormat:
                        '<div class="sten-chart-tooltip__point" style="color: {series.color}; margin-bottom: 0.5rem;">{series.name}: {point.y}</div>'
                }
            },
            {
                name: 'AER',
                type: 'line',
                lineWidth: 2,
                yAxis: 'efficiency',
                data: [],
                color: colorAERLine,
                zIndex: 2
            }
        ],
        tooltip: {
            valueDecimals: 1,
            headerFormat: '<div class="sten-chart-tooltip__header">Energy Efficiency for {point.key}</div>',
        }
    };
};

export default getKpiChartConfig;
